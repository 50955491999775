import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { getVariableBudgetConfigs } from './amoebaIndex';
import { download } from '@/redux/modules/minio';
import { getMoment } from '@/utils/datetime';
import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    planDtls: [], //变动费计划列表
    mainPlans: [], //变动费计划列表
};



export const setVariablePlanDtls = createAction('@@xcloud/amoeba/variable/setPlanDtls');
export const setMainVariablePlans = createAction('@@xcloud/amoeba/variable/setMainPlans');

export const initVariablePlan = createAction('@@xcloud/amoeba/variable/initVariablePlan',
    (companyId, companyIds, dptIds, year, callback) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(getVariableBudgetConfigs(companyId)),
            dispatch(getVariablePlanDtls(companyIds, dptIds, year)),
        ]).then(data => {
            if (data && callback) {
                callback(data)
                return data
            }
        }).catch(err => {
            console.log(err)
            let msg = '初始化变动费计划页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        })
    });
export const getVariablePlanDtls = createAction('@@xcloud/amoeba/variable/getPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/variable/plans/dtls'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setVariablePlanDtls(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取变动费计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateVariablePlanDtl = createAction('@@xcloud/amoeba/variable/planDtl/update',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/variable/plans/dtls/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新变动费计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新变动费计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateVariablePlan = createAction('@@xcloud/amoeba/variable/updatePlan',
    (planId, planDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/variable/plans/${planId}`, planDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新变动费计划成功"));
                }
            }).catch(err => {
                let msg = `更新变动费计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const addAndUpdateVariablePlanDtls = createAction('@@xcloud/amoeba/variable/addPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/variable/plans/dtls`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "修改变动费计划明细成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `修改变动费计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const exportVariablePlanTemplate = createAction('@@xcloud/amoeba/variable/exportVariablePlanTemplate',
    (year, companyId) => (dispatch, getState, httpClient) => {
        const paramYear = year ? year : getMoment()().year();
        const paramCompanyId = companyId ? companyId : getState().auth.currentUserInfo.companyId;
        let url = porxyApi + `/amoeba/variable/plans/export/dtls?`;
        const query = [];
        query.push("year=" + paramYear);
        query.push("companyId=" + paramCompanyId);
        return httpClient.get(url + query.join('&'), { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let tenantId = getState().auth.userProfile.roles[0].tenantId
                        let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                        dispatch(download(
                            res.data.docName,
                            mybucket
                        ))
                    }
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '导出变动费计划失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const importVariablePlanDtls = createAction('@@xcloud/amoeba/importVariablePlanDtls',
    (dto, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(porxyApi + `/amoeba/variable/plans/import/dtls`, dto, { headers: { need_loading: true } }).then(
                (res) => {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "导入变动费计划成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `导入变动费计划失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const amoebaVariablePlanReducer = handleActions(
    {
        [setVariablePlanDtls]: (state, { payload: data }) => {
            return {
                ...state,
                planDtls: data
            }
        },
        [setMainVariablePlans]: (state, { payload: data }) => {
            return {
                ...state,
                mainPlans: data
            }
        },
    },
    initialState
)

export default amoebaVariablePlanReducer
