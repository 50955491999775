import React from 'react';
import withStyles from './styles';
import withConnectStyles from './connectStyles';

import PropTypes from 'prop-types';
import {
    StepConnector,
    Grid,
} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import * as _ from 'lodash';
import { getProcessStatusNamebyCode } from "../../utils/constant";
import QontoStepIcon from "./QontoStepIcon";


const ProcessLine = ({
    classes,
    statusCodes,
    statusCode,
    constant
}) => {

    const QontoConnector = withConnectStyles(StepConnector)

    const getActiveStep = () => {
        return _.findIndex(statusCodes, s => { return s === statusCode })
    }

    return statusCodes && statusCodes.length > 2 ? (
        <div>
            <Grid style={{ width: statusCodes.length * 150 }}>
                <Stepper warp='nowrap' className={classes.stepper} activeStep={getActiveStep()} connector={<QontoConnector />} >
                    {statusCodes.map((label, index) => {
                        const stepProps = {};
                        return (
                            <Step key={label + index} {...stepProps} className={classes.step}>
                                <StepLabel StepIconComponent={QontoStepIcon} >{getProcessStatusNamebyCode(constant, label)}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>

        </div>
    ) : '';
};
ProcessLine.propTypes = {
    classes: PropTypes.object.isRequired,
};

ProcessLine.defaultProps = {

}

export default withStyles(ProcessLine);