import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  tabs: {
    minHeight: theme.spacing(4),
    borderBottom: '1px solid #e7e7e7',
    color: '#2979ff',
  },
  tab: {
    fontSize: 14,
    minWidth: 'auto',
    minHeight: theme.spacing(4),
    padding: '6px 18px',
  },
});

export default withStyles(styles);
