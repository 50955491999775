import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChecklistItems from "../../../components/InspectionManagement/Checklist/ChecklistItems";
import PropTypes from "prop-types";
import {
    initCreate,
    initChecklistHeader,
    getChecklists,
    resetCklSearch,
    initUpdate,
    setCklSearch,
    changeChecklistStatus,
    backToInventoryList,
    importChecklistItems,
} from '@/redux/modules/inspection/inspection';
import { INSPECTION_CHECKLIST_TEMPLATE } from '@/utils/inspection';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { upload, download } from '@/redux/modules/minio';
import { getNowTimestamp } from "@/utils/datetime";
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import _ from 'lodash';

class ChecklistItemsContainer extends Component {

    static propTypes = {
        checklists: PropTypes.object,
        currentChecklist: PropTypes.object
    };
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        const { location, match } = this.props;
        const inventoryId = match.params.inventoryId;
        const isBack = location ? location.state : false;
        this.props.onhandleInitChecklistHeader(inventoryId, isBack);
    }

    handleSearch() {
        this.props.onhandleSearch({
            ...this.props.search,
            offset: 0
        });
        const newSearch = {
            ...this.props.search,
            page: 0
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleClearSearch() {
        const { inventoryId } = this.props.currentInventory;
        this.props.onhandleClearSarch(inventoryId);
        setTimeout(() => {
            this.handleSearch();
        }, 100)
    }

    handleDownloadTemp() {
        const { onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'uat';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'uat';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.inspection.checklist-template', '{tenant_id}', tenantId);
        const fileName = INSPECTION_CHECKLIST_TEMPLATE;
        onhandleDownloadFile(fileName, mybucket);
    }

    handleSelect = (value, field) => {
        const newValue = value.target.value;
        let newSearch;
        switch (field) {
            case 'company':
                newSearch = {
                    ...this.props.search,
                    company: newValue,
                    dptId: 0,
                    roleId: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'department':
                let findDpt = _.find(this.props.departments, { 'dptId': _.toInteger(newValue) });
                newSearch = {
                    ...this.props.search,
                    dptId: newValue,
                    company: findDpt ? findDpt.companyId : undefined,
                    roleId: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'role':
                newSearch = {
                    ...this.props.search,
                    roleId: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'listType':
                newSearch = {
                    ...this.props.search,
                    listType: newValue,
                    catCode: ''
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'category':
                newSearch = {
                    ...this.props.search,
                    catCode: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'severity':
                newSearch = {
                    ...this.props.search,
                    severity: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'size':
                newSearch = {
                    ...this.props.search,
                    size: newValue,
                    offset: 0,
                    page: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
        setTimeout(() => {
            this.handleSearch();
        }, 100)
    }

    handleCreate(event) {
        if (this.props.onhandleCreate) {
            this.props.onhandleCreate(this.props.currentInventory.inventoryId)
        }
    }

    handleEdit(event, id, editable) {
        this.props.onhandleEdit(id, editable, this.props.currentInventory.inventoryId);
    }

    handleDelete = (event, id) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(id, 'inactive');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除此检查表吗?', changeStatus, null, false);
    };

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '导入检查项',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        const { currentInventory, companies } = this.props;
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'uat';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.inspection.checklist.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let pCompany = _.find(companies, o => !o.pid);
            let checklistCompId = 0;
            if (!currentInventory.companyId || currentInventory.companyId === '') {
                checklistCompId = pCompany.companyId;
            } else {
                checklistCompId = currentInventory.companyId;
            }
            let doc = {
                docName: offset + files[0].name,
                // 当前角色所在公司Id
                companyId: checklistCompId
            };
            this.props.onhandleCreateDoc(currentInventory.inventoryId, doc, () => {
                this.props.onhandleSearch({
                    ...this.props.search,
                    offset: 0
                });
            });
            this.props.onhandleModalClose();
        });
    }

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            page: value,
            offset: value * this.props.search.size
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            departments,
            roleList,
            inspectionDict
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyAlias : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldId }) ? _.find(departments, { 'dptId': fieldId }).companyDptName : '不限';
                break;
            case 'role':
                value = _.find(roleList, { 'code': fieldId }) ? _.find(roleList, { 'code': fieldId }).name : '不限';
                break;
            case 'listType':
            case 'category':
            case 'severity':
            case 'rootCause':
            case 'discipline':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '-';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleBack = (event) => {
        this.props.onhandleBackToInventoryList();
    };
 
    render() {
        return (
            <ChecklistItems
                currentInventory={this.props.currentInventory}
                currentChecklist={this.props.currentChecklist}
                lists={this.props.checklists}
                inspectionDptMapping={this.props.inspectionDptMapping}
                userProfile={this.props.userProfile}
                currentUserInfo={this.props.currentUserInfo}
                companyList={this.props.companies}
                departmentList={this.props.departments}
                listTypeList={this.props.listTypeList}
                categoryList={this.props.categoryList}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleListMapping={this.handleListMapping.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onEdit={this.handleEdit.bind(this)}
                onDelete={this.handleDelete.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                // onhandleSearch={this.handleSearch.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandleDownloadTemp={this.handleDownloadTemp.bind(this)}
                onhandleUpload={this.handleUpload.bind(this)}
                getChecklists={this.props.onhandleGetChecklistItems}
                roleList={this.props.roleList}
                search={this.props.search}
                onhandlePageChange={this.handlePageChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.inspection.cklSearch,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        roleList: state.inspection.roleList,
        currentChecklist: state.inspection.currentChecklist,
        currentInventory: state.inspection.currentInventory,
        checklists: state.inspection.checklists,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitChecklistHeader: (inventoryId, isBack) => {
            dispatch(initChecklistHeader(inventoryId, isBack));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setCklSearch(search));
        },
        onhandleSearch: (query) => {
            dispatch(getChecklists(query));
        },
        onhandleClearSarch: (id) => {
            dispatch(resetCklSearch(id));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateDoc: (inventoryId, doc, callback) => {
            dispatch(importChecklistItems(inventoryId, doc, callback));
        },
        onhandleDownloadFile: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleGetChecklistItems: (query) => {
            dispatch(getChecklists(query))
        },
        onhandleCreate: (inventoryId) => {
            dispatch(initCreate(inventoryId));
        },
        onhandleEdit: (id, editable, inventoryId) => {
            dispatch(initUpdate(id, editable, inventoryId));
        },
        onhandleChangeStatus: (id, status) => {
            dispatch(changeChecklistStatus(id, status));
        },
        onhandleBackToInventoryList: () => {
            dispatch(backToInventoryList());
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistItemsContainer)
