import React, {Component} from 'react';
import PredefinedProcessComponent from '../components/PredefinedProcess';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    getPredefinedProcess,
    savePredefinedProcess,
    updatePredefinedProcess,
    savePredefinedProcessSteps,
    setProcessPageSize,
    copyPredefinedProcess
} from '../redux/modules/predefinedProcess';
import {createMessage} from '../redux/modules/message';
import {createmodal} from '../redux/modules/modal';
import {arrayToTree, treeToArray} from '../utils/constant';
import * as _ from 'lodash';

class PredefinedProcessContainer extends Component {

    static propTypes = {
        isloading: PropTypes.bool,
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            search: {
                companyId: 0,
                text: '',
                ptype: 'ALL',
                pscope: 'ALL',
            },
            page: 0,
            processPageSize: 5
        };
    }

    componentWillMount() {
        this.handleSearch()
        // this.props.onhandleGetPredefinedProcess({ userId: this.props.currentUserInfo.userId });
    }

    handlePageChange = (pageNum, processPageSize) => {
        // alert("获取当前页:"+pageNum+","+processPageSize)
        this.setState({page: pageNum})
        this.setState({processPageSize: processPageSize})
    };

    handleSave(dto) {
        let newdto = {
            pname: dto.pname,
            ptype: dto.ptype,
            pscope: dto.pscope,
            creator: this.props.currentUserInfo.userId,
            tenantId: this.props.currentUserInfo.tenantId,
            companyId: this.props.currentUserInfo.companyId,


        }

        this.props.onhandleSavePredefinedProcess(newdto, () => this.handleSearch(null, null, this.state.page * this.state.processPageSize));
    }

    handleChange(event, field) {
        var newSearch = {...this.state.search}
        newSearch[field] = event.target.value
        this.setState({search: newSearch})
    }

    handleSearch(event, searchView, offset) {
        // alert("nihao")
        // alert(offset)


        let query = {
            ...this.state.search
        }
        if (query.text && query !== '') {
            query.search = query.text
        }
        if (query.ptype === 'ALL') {
            query['ptype'] = undefined
        }
        if (query.pscope === 'ALL') {
            query['pscope'] = undefined
            query['userId'] = this.props.currentUserInfo.userId
            query['companyId'] = this.props.currentUserInfo.companyId
        } else if (query.pscope === 'USER') {
            query['userId'] = this.props.currentUserInfo.userId
            query['companyId'] = this.props.currentUserInfo.companyId
        } else if (query.pscope === 'COMPANY') {
            query['companyId'] = this.props.currentUserInfo.companyId
            query['userId'] = undefined
        } else if (query.pscope === 'TENANT') {
            query['userId'] = undefined
            query['companyId'] = undefined
        }

        if (offset) {
            query.offset = offset
        } else {
            query.offset = 0
        }

        this.props.onhandleGetPredefinedProcess(query);
    }

    handleUpdatePredefinedProcess(dto, action) {
        let newdto = {
            pname: dto.pname,
            inventoryId: dto.inventoryId,
        }
        if (action === 'delete') {
            newdto.status = 'inactive'
        } else if (action === 'start') {
            newdto.status = 'active'
        } else if (action === 'stop') {
            newdto.status = 'stoped'
        }

        this.props.onhandleUpdatePredefinedProcess(newdto, () => this.handleSearch(null, null, this.state.page * this.state.processPageSize));
    }

    handleCopyProcessFlow(id) {
        let selectedProcess = _.find(this.props.predefinedProcess.list, {inventoryId: id})
        let userInStep = false;
        if (selectedProcess && selectedProcess.processSteps && selectedProcess.processSteps.length > 0) {
            let checkUserStep = _.filter(selectedProcess.processSteps, {userId: this.props.currentUserInfo.userId});
            if (checkUserStep && checkUserStep.length > 0) {
                userInStep = true;
            }
        }
        if (userInStep) {
            this.props.onhadleMassgae('您出现在拷贝对象流程中。该流程无法复制。请手动创建。')
        } else {
            this.props.onhandleCopyPredefinedProcess(id, this.props.currentUserInfo.userId, this.props.currentUserInfo.roleId, () => this.handleSearch())
        }
    }

    handleProcessFlow(id) {
        let companys = []
        this.props.companyList.forEach(company => {
            companys.push(company.companyId)
        })
        companys = _.uniq(companys);

        let selectedProcess = _.find(this.props.predefinedProcess.list, {inventoryId: id})
        let Approverslist = []
        if (selectedProcess && selectedProcess.processSteps && selectedProcess.processSteps.length > 0) {
            _.cloneDeep(selectedProcess.processSteps).forEach(processStep => {
                processStep.id = processStep.stepId;
                Approverslist.push(processStep);
            })
            Approverslist = arrayToTree(Approverslist, 'id', 'preId');
        }

        let param = {
            companyId: companys.length > 0 ? companys : null,
            processTree: Approverslist,
            processNotices: selectedProcess.processNoticeTargets,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.processesline && this.props.processesline.length > 0) {
                        this.props.onhandleSavePredefinedProcessSteps(id, treeToArray(_.cloneDeep(this.props.processesline)), this.props.processNotices,
                            () => this.handleSearch())
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleCreateProcess(param);
    }

//给comp赋值
    render() {
        return (
            <PredefinedProcessComponent
                predefinedProcess={this.props.predefinedProcess}
                page={this.state.page}
                processPageSize={this.props.processPageSize}
                processTypes={this.props.processTypes}
                userId={this.props.currentUserInfo.userId}
                search={this.state.search}
                searchText={this.state.searchText}
                searchView={this.state.searchView}
                onhandleSave={this.handleSave.bind(this)}
                onhandleUpdatePredefinedProcess={this.handleUpdatePredefinedProcess.bind(this)}
                onhandleProcessFlow={this.handleProcessFlow.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSearch={this.handleSearch.bind(this)}
                onhandleSetProcessPageSize={this.props.onhandleSetProcessPageSize.bind(this)}
                onhandleCopyProcessFlow={this.handleCopyProcessFlow.bind(this)}
                onhandlePageChange={this.handlePageChange.bind(this)}
            />
        );
    }

}

//从redux里拿出来，给containers赋值
const mapStateToProps = (state) => {
    return {
        predefinedProcess: state.predefinedProcess.predefinedProcess,
        processPageSize: state.predefinedProcess.processPageSize,
        currentUserInfo: state.auth.currentUserInfo,
        processesline: state.processlinewithuser.processesline,
        processNotices: state.processlinewithuser.processNotices,
        companyList: state.constant.companies,
        processTypes: state.constant.processTypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetPredefinedProcess: (query) => {
            dispatch(getPredefinedProcess(query));
        },
        onhandleSavePredefinedProcess: (dto, callback) => {
            dispatch(savePredefinedProcess(dto, callback));
        },
        onhandleUpdatePredefinedProcess: (dto, callback) => {
            dispatch(updatePredefinedProcess(dto, callback));
        },
        onhandleCreateProcess: (parm) => {
            dispatch(createmodal('createProcessLineApproval', parm, 'big'));
        },
        onhandleSavePredefinedProcessSteps: (id, dtos, notices, callback) => {
            dispatch(savePredefinedProcessSteps(id, dtos, notices, callback));
        },
        onhandleSetProcessPageSize: (data) => {
            dispatch(setProcessPageSize(data));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleCopyPredefinedProcess: (inventoryId, userId, roleId, callback) => {
            dispatch(copyPredefinedProcess(inventoryId, userId, roleId, callback));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PredefinedProcessContainer);