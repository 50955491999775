import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorkOrderProcess from '../../components/OperationManagement/WorkOrder/WorkOrderList/WorkOrderProcess';
import AssignmentPanelComponent from '../../components/OperationManagement/WorkOrder/WorkOrderList/AssignmentPanel';
import {
    initWorkOrderProcess, routeToWorkOrderList, createSubWorkOrder, updateWorkOrder,
    createWorkOrderExecutor, updateWorkOrderExecutor, saveWorkOrderApprovers, updateWorkOrderApprover
} from "../../redux/modules/operation";
import { accessoryReplace, getAccessoryByWorkOrder, modifyAccessory } from "../../redux/modules/accessory";
import { getCompanyId, getDepartmentsByRole } from "../../utils/userUtils";
import { openConfirmDialog } from '../../redux/modules/confirmDialog';
import { createMessage } from '../../redux/modules/message';
import { createmodal } from '../../redux/modules/modal';
import { arrayToTree, treeToArray, findSameLevel } from '../../utils/constant';
import { openApprovePannelWithDoc } from "../../redux/modules/approvePannel";
import ApprovePannel from "../LeadershipManagement/ApprovePannel";
import ApprovePannelWithDoc from "@/containers/ApprovePannelWithDoc";
import { getNowTimestamp } from '@/utils/datetime';
import { upload, downloadURL } from '@/redux/modules/minio';
import { FilePreviewer } from '@/components/SimpleComponents';
import { getEnvCode } from '@/utils/exportfile'
import { getFileExt } from '@/utils/fileUtils'

import * as _ from 'lodash';
import PropTypes from "prop-types";

class WorkOrderProcessContainer extends Component {

    static propTypes = {
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
        optCategories: PropTypes.array,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            assignmentTableOpen: false,
            currentWOE: {},
            id: null,
            globalStatus: null,
            actions: [
            ],
            routeType: '',
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let routeType = this.props.match.params.routeType;
        this.setState({
            id: _.toInteger(id),
            routeType: routeType
        })
        this.props.onhandleInitWorkOrderProcess(id, () => this.callback());
    }

    checkPermission() {
        let permission = _.find(this.props.userProfile.roles[0].routes, { 'route': 'operation_workorder_list' })
        return permission && permission.action === 'W'
    }

    checkPermissionSpecChecker() {//机关工单审核权限
        let permission = _.find(this.props.userProfile.roles[0].routes, { 'route': 'spec_order_check' })
        return permission && permission.action === 'W'
    }

    checkStartActive() {

        let rtn = false
        const { userId, roleId } = this.props.currentUserInfo;

        if (this.props.workOrderApproversLast && this.props.workOrderApproversLast.length > 0) {
            let startRecord = _.find(this.props.workOrderApproversLast, approver => {
                return (approver.userId && approver.userId === userId) || (!approver.userId && approver.roleId === roleId)
            })
            if (startRecord) {
                if (!startRecord.confirm && !startRecord.preId) {
                    rtn = true;
                }
            }
        }
        return rtn;
    }

    checkShowCreate() {
        let rtn = false;
        if (this.checkPermission()) {
            // if (this.props.workOrderApprovers && this.props.workOrderApprovers.length > 0) {
            //     rtn = false;
            // } else {
            rtn = true;
            // }
        }
        return rtn;
    }

    checkStepActive() {
        let rtn = false;
        const { userId, roleId } = this.props.currentUserInfo
        if (this.props.workOrderApproversLast && this.props.workOrderApproversLast.length > 0) {
            //审批人或则审批角色符合
            let approver = _.find(this.props.workOrderApproversLast, approver => {
                return (approver.userId && approver.userId === userId && !approver.confirm) ||
                    (!approver.userId && approver.roleId === roleId && !approver.confirm)
            })
            if (approver) {
                let fatherSameLevelList = findSameLevel(_.cloneDeep(this.props.workOrderApproversLast), approver.preId, 'aprvId', 'preId');
                if (fatherSameLevelList && fatherSameLevelList.length > 0) {
                    if (_.filter(fatherSameLevelList, function (o) { return !o.confirmTime }).length > 0) {
                        rtn = false;
                    } else {
                        rtn = true;
                    }
                }
            }
        }
        return rtn;
    }

    checkMainStartUser() {
        let rtn = false;
        let userId = this.props.userProfile.userId;
        let dptId = getDepartmentsByRole(this.props.currentUserInfo.roleId, this.props.userProfile)[0].dptId;
        if (this.props.workOrderApproversLast && this.props.workOrderApproversLast.length > 0) {
            let userApprover = _.find(this.props.workOrderApproversLast, { userId: userId });
            if (userApprover) {
                if (!userApprover.confirm) {
                    if (!userApprover.preId) {
                        rtn = true;
                    }
                }
            }
        } else if (_.filter(this.props.workOrderList.list, { dptId: dptId }).length > 0) {
            rtn = true;
        }
        return rtn;
    }

    callback() {
        let workOrders = this.props.workOrderList.list;
        let actions = [];
        if (workOrders[0].status === 'new' || workOrders[0].status === 'rejected') {
            let actions = [];
            if (this.checkShowCreate()) {
                actions.push({
                    statusCode: "preNew",
                    actionName: this.props.workOrderApproversLast && this.props.workOrderApproversLast.length > 0 ? '修改审批流程' : '创建审批流程'
                })
            }
            if (this.checkStartActive()) {
                actions.push({ statusCode: 'new', actionName: '提交' })
            }
            this.setState({
                globalStatus: 'new',
                actions: actions,
            })
        } else if (workOrders[0].status === 'submitted') {
            if (this.checkStepActive()) {
                actions = [{ statusCode: "Rejected", actionName: "驳回" },
                { statusCode: "Approved", actionName: "通过审批" }]
            }
            this.setState({
                globalStatus: 'submitted',
                actions: actions,
            })
        } else if (workOrders[0].status === 'approved') {
            this.setState({
                globalStatus: 'approved',
                actions: actions,
            })
        } else {
            this.setState({
                globalStatus: workOrders[0].status,
                actions: actions,
            })
        }
    }

    handleRowUpdate(data) {
        this.props.onUpdateWorkOrder(data, () => {
            this.props.onhandleInitWorkOrderProcess(this.state.id)
        })
    }

    handleDelete(data) {
        data.status = 'inactive';
        this.props.onUpdateWorkOrder(data, () => {
            this.props.onhandleInitWorkOrderProcess(this.state.id)
        })
    }

    handleCreateSub(event, rowData) {
        let data = _.clone(rowData);
        data.masterOrder = data.orderId;
        data.orderId = null;
        let id = this.props.match.params.id;
        this.props.createSubWorkOrder(data, () => this.props.onhandleInitWorkOrderProcess(id));
    }

    handleAssignHead(event, row) {
        let companys = []
        let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
        if (mainCompany) {
            companys.push(mainCompany.companyId)
        }
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        companys.push(companyId)
        companys = _.uniq(companys);
        let param = {
            company: true,
            companyId: companys.length > 0 ? companys : null,
            group: true,
            role: true,
            department: true,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('请选择一个负责人')
                        return reject('callbackFunc Error')
                    } else {
                        let userId = this.props.selectedUsers[0].userId
                        row.orderHead = userId;
                        this.props.onUpdateWorkOrder(row, () => {
                            this.props.onhandleInitWorkOrderProcess(this.state.id)
                        })
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectUser(param);
    }

    handleAssign(event, row) {
        if (row.executors.length > 0) {
            this.setState({
                assignmentTableOpen: true,
                currentWOE: _.clone(row.executors[0])
            });
        } else {
            this.setState({
                assignmentTableOpen: true,
                currentWOE: {
                    orderId: row.orderId,
                    internalCompany: 0,
                    internalDpt: 0,
                    internalCompanyName: '',
                    internalDptName: '',
                    supplierId: 0,
                    supplierName: '',
                }
            });
        }

    }

    handleClose() {
        this.setState({
            assignmentTableOpen: false,
            currentWOE: {}
        });
    }

    handleUpdate() {
        let currentWOE = this.state.currentWOE;
        if (currentWOE.woeId) {
            this.props.onUpdateWorkOrderExecutor(currentWOE, (data) => {
                this.setState({
                    currentWOE: data
                })
                this.handleClose();
                this.props.onhandleInitWorkOrderProcess(this.state.id);
            })
        } else {
            this.props.onCreateWorkOrderExecutor(currentWOE, (data) => {
                this.setState({
                    currentWOE: data
                })
                this.handleClose();
                this.props.onhandleInitWorkOrderProcess(this.state.id);
            })
        }
    }

    onhandleSelectItem = (event, field) => {
        let param = {
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedSupplier.length !== 1) {
                        this.props.onhandleSelectSupplierCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let selectedSupplier = _.cloneDeep(this.props.selectedSupplier[0])
                        let newCurrentWOE = _.clone(this.state.currentWOE);
                        newCurrentWOE.internalCompany = 0;
                        newCurrentWOE.internalDpt = 0;
                        newCurrentWOE.supplierId = selectedSupplier.supplierId;
                        newCurrentWOE.supplierName = selectedSupplier.supplierName;
                        this.setState({
                            currentWOE: newCurrentWOE
                        });
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectSupplier(param);
    }

    onhandleChangeItem = (event, field) => {
        console.log(event.target.value, field)
        let newCurrentWOE = _.clone(this.state.currentWOE);
        switch (field) {
            case 'companyId':
                newCurrentWOE.internalCompany = event.target.value;
                newCurrentWOE.internalDpt = 0;
                newCurrentWOE.supplierId = 0;
                newCurrentWOE.supplierName = null;
                this.setState({
                    currentWOE: newCurrentWOE
                });
                break;
            case 'dpt':
                newCurrentWOE.internalDpt = event.target.value;
                newCurrentWOE.supplierId = 0;
                newCurrentWOE.supplierName = null;
                this.setState({
                    currentWOE: newCurrentWOE
                });
                break;
            case 'supplierId':
                newCurrentWOE.internalCompany = 0;
                newCurrentWOE.internalDpt = 0;
                newCurrentWOE.supplierId = 0;
                newCurrentWOE.supplierName = '';
                this.setState({
                    currentWOE: newCurrentWOE
                });
                break;
            case 'supplierType':
                if (event.target.value === 'external') {
                    newCurrentWOE.internalCompany = 0;
                    newCurrentWOE.internalDpt = 0;
                    newCurrentWOE.supplierType = 'external'
                } else {
                    newCurrentWOE.supplierId = 0;
                    newCurrentWOE.supplierName = null;
                    newCurrentWOE.supplierType = 'internal'
                }
                this.setState({
                    currentWOE: newCurrentWOE
                });

                break;
            default:
            // do nothing
        }
    }

    subSubmitCheck(datas) {
        if (_.filter(datas, function (o) { return !o.orderHead }).length > 0) {
            return false
        }
        return true;
    }

    subApproveCheck(datas) {
        if (_.filter(datas, function (o) { return !o.orderHead }).length > 0) {
            return false
        }

        if (_.filter(datas, function (o) { return o.executors && o.executors.length === 0 }).length > 0) {
            return false
        }

        if (_.filter(datas, function (o) { return !_.isNumber(o.orderBudget) }).length > 0) {
            return false
        }
        return true;
    }

    handleProcessFlowEdit() {
        if (this.handleSpecCheckerExist()) {
            let companys = []
            let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
            if (mainCompany) {
                companys.push(mainCompany.companyId)
            }
            let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
            companys.push(companyId)
            companys = _.uniq(companys);

            let workOrderApproverslist = []
            if (this.props.workOrderApproversLast && this.props.workOrderApproversLast.length > 0) {
                _.cloneDeep(this.props.workOrderApproversLast).forEach(workOrderApprover => {
                    workOrderApprover.id = workOrderApprover.aprvId;
                    workOrderApproverslist.push(workOrderApprover);
                })
                workOrderApproverslist = arrayToTree(workOrderApproverslist, 'id', 'preId');
            }

            let param = {
                companyId: companys.length > 0 ? companys : null,
                processTree: workOrderApproverslist,
                checkWorkOrderProcess: true,
                haveProcessSelect: true,
                processNotices: this.props.workOrderNotices,
                callback: () => {
                    return new Promise((resolve, reject) => {
                        if (this.props.processesline && this.props.processesline.length > 0) {
                            this.props.onhandleSaveWorkOrderApprovers(this.state.id, treeToArray(_.cloneDeep(this.props.processesline)), this.props.processNotices,
                                () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
                            return resolve('callbackFunc success')
                            // this.props.onhadleWarnningMassgae('审批流程种缺少机关相关负责人（例如：压力表、压力容器、安全阀等的机关负责人）');
                            // this.props.onhandleSaveWorkOrderApprovers(this.state.id, treeToArray(_.cloneDeep(this.props.processesline)), this.props.processNotices,
                            //     () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
                            // return resolve('callbackFunc success')
                        }
                    })
                }
            }
            this.props.onhandleCreateProcess(param);
        }
    }

    handleSpecCheckerExist() {
        let rtn = true;
        if (this.props.roleList.list.length === 0) {
            this.props.onhadleMassgae('系统中机关相关业务负责人未设定。请联系管理员设定后再创建');
            rtn = false;
        }
        return rtn
    }

    handleSpecCheckerSelected() {
        let rtn = false;
        this.props.roleList.list.forEach(role => {
            let roleId = role.roleId
            if (_.filter(this.props.workOrderApproversLast, { roleId: roleId }).length > 0) {
                rtn = true;
                return rtn;
            }
        })
        return rtn
    }

    handleProcess(action) {
        if (action.statusCode === 'new') {
            let datas = this.props.workOrderList.list;
            if (this.subSubmitCheck(datas)) {
                if (this.handleSpecCheckerSelected()) {
                    this.handleApprove();
                } else {
                    if (this.subApproveCheck(datas)) {
                        this.handleApprove();
                    } else {
                        this.props.onhadleMassgae('由于审批流程种缺少机关相关负责人，工单信息的[承包商、预算]需要编辑，请确认后再提交');
                    }
                }
            } else {
                this.props.onhadleMassgae('工单信息未编辑完善[负责人]，请确认后再提交');
            }
        } else if (action.statusCode === 'preNew') {
            // 从流程业务中分离出来
            this.handleProcessFlowEdit();
        } else if (action.statusCode === 'Rejected') {
            this.props.openApprovePannelWithDoc(action.actionName, '', true, this.handleReject.bind(this, action))
        } else if (action.statusCode === 'Approved') {
            if (this.checkPermissionSpecChecker() && this.checkStepActive()) {
                let datas = this.props.workOrderList.list;
                if (this.subApproveCheck(datas)) {
                    this.props.openApprovePannelWithDoc(action.actionName, '同意', true, this.handleApprove.bind(this, action))
                } else {
                    this.props.onhadleMassgae('工单信息未编辑完善[承包商、预算]，请确认后再提交');
                }
            } else {
                this.props.openApprovePannelWithDoc(action.actionName, '同意', true, this.handleApprove.bind(this, action))
                // this.handleApprove();
            }
        }
    }

    handleApprove(action, comment, docInfo) {

        const { currentUserInfo, onhandleMinioUpload } = this.props;

        const userId = currentUserInfo.userId;
        const roleId = currentUserInfo.roleId;
        let approverRecord = _.find(this.props.workOrderApproversLast, approver => {
            return (approver.userId && approver.userId === userId) || (!approver.userId && approver.roleId === roleId)
        })
        if (approverRecord) {
            approverRecord.userId = userId;
            approverRecord.confirm = true;
            approverRecord.opentext = comment || '';
            if (docInfo && docInfo.name) {
                let tenantId = currentUserInfo.tenantId;

                let envCode = getEnvCode()
                let mybucket = _.replace(envCode + '.{tenant_id}.operation.process-doc', '{tenant_id}', tenantId);
                let offset = getNowTimestamp();

                onhandleMinioUpload([docInfo], mybucket, offset, () => {
                    let doc = {
                        docId: 0,
                        docName: offset + docInfo.name,
                        docLink: mybucket,
                        docType: 'DOC_FILE',
                        tenantId: tenantId
                    };
                    approverRecord.orderApprovalDoc = doc;
                    this.props.onhandleUpdateWorkOrderApprover(this.state.id, approverRecord,
                        () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
                })

            } else {
                this.props.onhandleUpdateWorkOrderApprover(this.state.id, approverRecord,
                    () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
            }

        }
    }

    handleReject(action, comment, docInfo) {

        const {
            currentUserInfo,
            onhandleMinioUpload,
        } = this.props;

        const userId = currentUserInfo.userId;
        const roleId = currentUserInfo.roleId;
        let retjectRecord = _.find(this.props.workOrderApproversLast, approver => {
            return (approver.userId && approver.userId === userId) || (!approver.userId && approver.roleId === roleId)
        })
        if (retjectRecord) {
            retjectRecord.userId = userId;
            retjectRecord.confirm = false;
            retjectRecord.opentext = comment || '';
            if (docInfo && docInfo.name) {
                let tenantId = currentUserInfo.tenantId;

                let envCode = getEnvCode()
                let mybucket = _.replace(envCode + '.{tenant_id}.operation.process-doc', '{tenant_id}', tenantId);
                let offset = getNowTimestamp();

                onhandleMinioUpload([docInfo], mybucket, offset, () => {
                    let doc = {
                        docId: 0,
                        docName: docInfo.name,
                        docLink: offset + docInfo.name,
                        docType: 'DOC_FILE',
                        mybucket: mybucket,
                        tenantId: tenantId
                    };
                    retjectRecord.orderApprovalDoc = doc;
                    this.props.onhandleUpdateWorkOrderApprover(this.state.id, retjectRecord,
                        () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
                })

            } else {
                this.props.onhandleUpdateWorkOrderApprover(this.state.id, retjectRecord,
                    () => this.props.onhandleInitWorkOrderProcess(this.state.id, () => this.callback()))
            }
        }
    }

    handleRouteToWorkOrderList() {
        this.props.routeToWorkOrderList(this.state.routeType);
    }


    async handlePreview(data, docCate) { //文档种类类

        let docs = data
        if (!_.isArray(data)) {
            docs = [data]
        }
        if (docs && docs.length > 0) {
            const doc = docs[0]
            return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: getFileExt(doc.docName),
                            fileDesc: doc.docDesc,
                            docs: docs,
                            fileIndex: 0,
                            fileCount: docs.length,
                        }
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
        }
    }

    // return this.props.onhandleDownloadUrl(data.docName, data.docLink).then(res => {
    //     if (res && res.payload) {
    //         this.setState({
    //             previewFile: {
    //                 open: true,
    //                 fileURL: res.payload,
    //                 fileType: getFileExt(data.docName)
    //             }
    //         })
    //         return res.payload;
    //     } else {
    //         // 不做什么
    //     }
    // });
    // }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    handlePreDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, false)
    }
    handleNextDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, true)
    }

    handleSwitchDoc = (fileIndex, isNext) => {
        const oldPreviewFile = this.state.previewFile
        var newIndex = fileIndex - 1
        if (isNext) {
            newIndex = fileIndex + 1
        }
        const doc = oldPreviewFile.docs[newIndex]
        return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        ...oldPreviewFile,
                        fileURL: res.payload,
                        fileType: getFileExt(doc.docName),
                        fileDesc: doc.docDesc,
                        fileIndex: newIndex,
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }

    handleReplaceAccessory(event) {

        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: false,
            statusValue: 'new',
            company: false,
            deviceSearch: false,
            companyId: null,
            amount: true,
            libSelect: true,
            callback: () => {
                return new Promise(async (resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccessoryCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let wo = _.first(_.sortBy(this.props.workOrderList.list, 'orderId'))
                        let planDetail = _.first(this.props.planDetails.list)
                        if (!planDetail || !planDetail.accessory) {
                            return reject('callbackFunc Error')
                        }

                        let param = {
                            deviceId: planDetail.accessory.deviceId,
                            orginAccId: planDetail.accessory.accId,
                            newAccId: this.props.selectedAccessory[0].accId,
                            dtlId: planDetail.dtlId,
                            orderId: wo.orderId,
                        }
                        await this.props.onhandleReplace(param);
                        this.props.onhandleGetAccessoryByWorkOrder(wo.orderId);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleAccessorySelectDialog(param);
    }

    handleDeleteAccessory = async (accessory) => {
        let wo = _.first(_.sortBy(this.props.workOrderList.list, 'orderId'))
        let planDetail = _.first(this.props.planDetails.list)

        await this.props.onhandleUpdateAccessory({
            accId: accessory.accId,
            orderId: wo.orderId,
            dtlId: planDetail.dtlId,
            status: 'inactive'
        })

        this.props.onhandleGetAccessoryByWorkOrder(wo.orderId);
    }

    handleUpdateAccessory = async (accessory) => {

        await this.props.onhandleUpdateAccessory({
            accId: accessory.accId,
            amount: Number(accessory.amount)
        })
        let wo = _.first(_.sortBy(this.props.workOrderList.list, 'orderId'))
        this.props.onhandleGetAccessoryByWorkOrder(wo.orderId);
    }

    render() {
        let AssignmentPanel = AssignmentPanelComponent
        const { previewFile } = this.state;
        return (
            <div>
                <WorkOrderProcess
                    planDetails={this.props.planDetails.list}
                    orderTypeList={this.props.orderTypeList}
                    departmentList={this.props.departmentList}
                    companyList={this.props.companyList}
                    onhandleBack={this.handleRouteToWorkOrderList.bind(this)}
                    optCategories={this.props.optCategories}
                    catList={this.props.catList}
                    workOrderList={this.props.workOrderList}
                    onhandleUpdate={this.handleRowUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    accCatList={this.props.accCatList}
                    onCreateSub={this.handleCreateSub.bind(this)}
                    onhandleAssignHead={this.handleAssignHead.bind(this)}
                    onhandleAssign={this.handleAssign.bind(this)}
                    actions={this.state.actions}
                    constant={this.props.constant}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onhandleProcessFlowEdit={this.handleProcessFlowEdit.bind(this)}
                    workOrderApprovers={this.props.workOrderApprovers}
                    workOrderApproversLast={this.props.workOrderApproversLast}
                    workOrderNotices={this.props.workOrderNotices}
                    userProfile={this.props.userProfile}
                    globalStatus={this.state.globalStatus}
                    permission={this.checkPermission()}
                    specChecker={this.checkPermissionSpecChecker()}
                    currentProcessActiveUser={this.checkStepActive()}
                    mainStartUser={this.checkMainStartUser()}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    routeType={this.state.routeType}
                    onhandleReplaceAccessory={this.handleReplaceAccessory.bind(this)}
                    accessoryList={this.props.workorderAccessoryList}
                    onhandleDeleteAccessory={this.handleDeleteAccessory.bind(this)}
                    onhandleUpdateAccessory={this.handleUpdateAccessory.bind(this)}
                />
                <ApprovePannelWithDoc />
                <ApprovePannel />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    fileDesc={previewFile.fileDesc}
                    fileIndex={previewFile.fileIndex}
                    fileCount={previewFile.fileCount}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onPre={this.handlePreDoc}
                    onNext={this.handleNextDoc}
                />
                {
                    this.state.assignmentTableOpen && <AssignmentPanel
                        open={this.state.assignmentTableOpen}
                        onhandleClose={this.handleClose.bind(this)}
                        departmentList={this.props.departmentList}
                        companyList={this.props.companyList}
                        // currentWorkOrder={this.state.currentWorkOrder}
                        currentWOE={this.state.currentWOE}
                        onhandleChangeItem={this.onhandleChangeItem.bind(this)}
                        onhandleUpdate={this.handleUpdate.bind(this)}
                        onhandleSelectItem={this.onhandleSelectItem.bind(this)}

                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        orderTypeList: state.operation.orderTypeList,
        departmentList: state.constant.departments,
        companyList: state.constant.companies,
        planDetails: state.operationPlan.planDetails,
        optCategories: state.operationRule.optCategories,
        catList: state.trees.deviceCategoryList,
        accCatList: state.accessory.catList,
        workOrderList: state.operation.workOrderList,
        selectedUsers: state.userSelect.selectedUsers,
        constant: state.constant,
        workOrderApprovers: state.operation.workOrderApprovers,
        workOrderApproversLast: state.operation.workOrderApproversLast,
        workOrderNotices: state.operation.workOrderNotices,
        processesline: state.processlinewithuser.processesline,
        processNotices: state.processlinewithuser.processNotices,
        roleList: state.role.roleList,
        selectedSupplier: state.supplier.selectedSupplier,
        //更换部件相关
        selectedAccessory: state.accessorySelect.selectedAccessory,
        workorderAccessoryList: state.accessory.workorderAccessoryList,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitWorkOrderProcess: (data, callback) => {
            dispatch(initWorkOrderProcess(data, callback))
        },
        routeToWorkOrderList: (routeType) => {
            dispatch(routeToWorkOrderList(routeType))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        createSubWorkOrder: (data, callback) => {
            dispatch(createSubWorkOrder(data, callback))
        },
        onhandleSelectUser: (parm) => {
            dispatch(createmodal('userSelect', parm, 'medium'));
        },
        onhandleSelectUserCheck: () => {
            dispatch(createMessage('error', `请选择一个负责人`));
        },
        onUpdateWorkOrder: (data, callback) => {
            dispatch(updateWorkOrder(data, callback));
        },
        onCreateWorkOrderExecutor: (data, callback) => {
            dispatch(createWorkOrderExecutor(data, callback));
        },
        onUpdateWorkOrderExecutor: (data, callback) => {
            dispatch(updateWorkOrderExecutor(data, callback));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhadleWarnningMassgae: (message) => {
            dispatch(createMessage('warning', message));
        },
        onhandleCreateProcess: (parm) => {
            dispatch(createmodal('createProcessLineApproval', parm, 'large'));
        },
        onhandleSaveWorkOrderApprovers: (orderId, datas, processNotices, callback) => {
            dispatch(saveWorkOrderApprovers(orderId, datas, processNotices, callback))
        },
        onhandleUpdateWorkOrderApprover: (orderId, data, callback) => {
            dispatch(updateWorkOrderApprover(orderId, data, callback))
        },
        // openApprovePannel: (title, confirmCallback, CloseCallback) => {
        //     dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        // },
        openApprovePannelWithDoc: (title, defalutComment, withDoc, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannelWithDoc(title, defalutComment, withDoc, confirmCallback, CloseCallback))
        },
        onhandleSelectSupplierCheck: () => {
            dispatch(createMessage('error', `请选择一个承包商`));
        },
        onhandleSelectSupplier: (parm) => {
            dispatch(createmodal('supplierSelect', parm, 'medium'));
        },
        onhandleMinioUpload: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },

        onhandleAccessorySelectDialog: (param) => {
            dispatch(createmodal('accessorySelect', param, 'medium'))
        },
        onhandleSelectAccessoryCheck: () => {
            dispatch(createMessage('error', `请选择一个部件`));
        },
        onhandleReplace: (param) => {
            return dispatch(accessoryReplace(param));
        },
        onhandleGetAccessoryByWorkOrder: (orderId) => {
            dispatch(getAccessoryByWorkOrder(orderId));
        },
        onhandleUpdateAccessory: (accssoryDto) => {
            return dispatch(modifyAccessory(accssoryDto));
        },


    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrderProcessContainer)
