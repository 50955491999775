import Konva from 'konva';
import * as _ from 'lodash';

export function init(props, bx, by, id, _getDevice) {
    const state = {
        color: "black",
        width: 100,
        height: 0,
        strokeWidth: 2
    };
    // 从deviceStore获取最新的一个device
    // const thisDevice = props.deviceStore[props.deviceStore.length - 1];
    const thisDevice = _.find(props.deviceStore, { 'id': id });
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: thisDevice.id
    });
    // 生成管道隐藏背景
    const background = new Konva.Rect({
        x: 0,
        y: 0,
        width: state.width,
        height: 20
    });
    group.add(background);

    // 法兰连接主线，可延伸
    const scaleLine = new Konva.Line({
        points: [0, 0, 100, 0],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        name: 'scale_line'
    })
    group.add(scaleLine);

    // 左竖线
    const leftLine = new Konva.Line({
        points: [45, -10, 45, 10],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(leftLine);

    // 右竖线
    const rightLine = new Konva.Line({
        points: [55, -10, 55, 10],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(rightLine);

    // const text = new Konva.Text({
    //     text: thisDevice.name,
    //     x: 30,
    //     y: 15,
    //     fill: state.color
    // })
    // group.add(text);


    // 输入连接点
    const input = new Konva.Arrow({
        x: -5,
        y: -5,
        points: [0, 5, 5, 5],
        pointerLength: 5,
        pointerWidth: 5,
        fill: 'deeppink',
        stroke: 'deeppink',
        strokeWidth: state.strokeWidth,
        name: "input",
        id: `${id}_input`,
        visible: false
    });

    input.on('click', function (e) {
        let pos = this.getAbsolutePosition();
        const { selectLink } = props;
        // 设置连接起点
        selectLink({ id: this.id(), beginX: pos.x, beginY: pos.y + 5, type: "input" });
        let scaleLine = this.getParent().findOne('.scale_line');
        if (scaleLine.points().length > 4) {
            const { deviceStore } = _getDevice();
            let thisObject;
            for (let i = 0; i < deviceStore.length; i++) {
                // console.log(deviceStore[i].id);
                if (deviceStore[i].id === this.getParent().id()) {
                    thisObject = deviceStore[i];
                    break;
                }
            }
            // console.log(thisObject);
            if (thisObject.input && thisObject.input[this.id()]) {
                //如果当前连接点已经连接到其他设备，就先移除
                scaleLine.points(scaleLine.points().slice(4));
                thisObject.input[this.id()] = null;
            }
        }
    })

    group.add(input);

    // 输出连接点
    const output = new Konva.Arrow({
        x: state.width,
        y: -5,
        points: [0, 5, 5, 5],
        pointerLength: 5,
        pointerWidth: 5,
        fill: 'deeppink',
        stroke: 'deeppink',
        strokeWidth: state.strokeWidth,
        name: "output",
        id: "falanlianjie_output",
        visible: false
    });
    group.add(output);

    output.on('click', function (e) {
        let pos = this.getAbsolutePosition();
        const { selectLink } = props;
        // 设置连接起点
        selectLink({ id: this.id(), beginX: pos.x, beginY: pos.y + 5, type: "output" });
        let scaleLine = this.getParent().findOne('.scale_line');
        if (scaleLine.points().length > 4) {
            const { deviceStore } = _getDevice();
            let thisObject;
            for (let i = 0; i < deviceStore.length; i++) {
                // console.log(deviceStore[i].id);
                if (deviceStore[i].id === this.getParent().id()) {
                    thisObject = deviceStore[i];
                    break;
                }
            }
            // console.log(thisObject);
            if (thisObject.output && thisObject.output[this.id()]) {
                scaleLine.points(scaleLine.points().slice(0, scaleLine.points().length - 4));
                thisObject.output[this.id()] = null;
            }
        }
    })

    group.on('transformend', function (e) {
        console.log('transform end');
        let { updateDevice } = props;
        updateDevice(e.target.attrs);
    });

    group.on('dblclick', function () {
        const { linkDevice, selectedLink } = _getDevice();
        const { selectLinkDevice } = props;
        if (!selectedLink) {
            // 如果链接点没有被选择，没有延伸线出现点情况
            if (linkDevice && linkDevice === this.id()) {
                // 发起连接点设备不是当前设备
                // this.draggable(true);
                selectLinkDevice(null);
                // 隐藏输入输出连接点
                this.find('.output').forEach(element => {
                    element.hide();
                });
                this.find('.input').forEach(element => {
                    element.hide();
                });
                this.getParent().draw();
            } else {
                // 当前device发起连接
                // this.draggable(false);
                selectLinkDevice(this.id());
                // 显示输入输出连接点
                this.find('.input').forEach(element => {
                    element.show();
                })
                this.find('.output').forEach(element => {
                    element.show();
                })
                this.getParent().draw();
            }
        }
    })

    group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
        // 拖拽开始，组件变红色
        this.find('Line').forEach(element => {
            element.stroke('red');
        });
        // this.find('Text').forEach(element => {
        //     element.fill('red');
        // });
        this.draw();
    })

    group.on('mouseout', function () {
        document.body.style.cursor = 'default';
        // 拖拽结束，组件恢复黑色
        this.find('Line').forEach(element => {
            element.stroke('black');
        });
        // this.find('Text').forEach(element => {
        //     element.fill('black');
        // });
        this.draw();
    })

    group.on('dragstart', function () {
        this.find('Line').forEach(element => {
            element.stroke('red');
        });
        // this.find('Text').forEach(element => {
        //     element.fill('red');
        // });
        this.draw();
    })

    group.on('dragend', function () {
        // 拖拽结束，重新设定当前设备点起始x和y
        let pos = this.getAbsolutePosition();
        thisDevice.beginX = pos.x;
        thisDevice.beginY = pos.y;
    })

    group.on('dragstart', function () {
        let { cleanContentMenu } = props;
        cleanContentMenu();
    })

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let pos = this.getAbsolutePosition(); // 当前设备的绝对位置
        let { createContentMenu } = props;
        createContentMenu(pos.y, pos.x, { id: this.id() });
    });

    return group;
}

