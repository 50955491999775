import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
    TextField,
    MenuItem,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';

const AddStaffSharingComponent = props => {
    const { classes,
        open,
        userId,
        users,
        onhandleChangeUser,
        onhandleClose,
        onhandleAdd,
    } = props;

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>增加分摊人员</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        value={userId || ''}
                                        onChange={onhandleChangeUser}
                                    >
                                        {users.map(u => <MenuItem key={u.userId} value={u.userId}> {u.lastName + u.firstName}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleAdd} color="primary"> 增加
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AddStaffSharingComponent.propTypes = {
    classes: PropTypes.object.isRequired,

};

AddStaffSharingComponent.defaultProps = {
    departments: []
};

export default withStyles(AddStaffSharingComponent);