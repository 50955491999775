import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceAccessoryList from '../../../components/AccessoryManagement/AccessoryInfo/DeviceAccessoryList';
import PropTypes from "prop-types";
import {
    // getAccessoryList,
    setSearch,
    initAccessoryList,
    changeAccessoryStatus,
    initUpdate,
    initView,
    initCreate,
    backToDeviceList,
    accessoryReplace,
    accessoryRelevance
} from '../../../redux/modules/accessory';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';
import * as _ from 'lodash';

class DeviceAccessoryListContainer extends Component {
    static propTypes = {
        search: PropTypes.object,
        userProfile: PropTypes.object,
        accessoryList: PropTypes.object,
        catList: PropTypes.array,
        // 当前设备
        currentDevice: PropTypes.object,
    };

    componentWillMount() {
        this.props.onhandleInitAccessoryList();
    }

    handleEdit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleView(event, id) {
        this.props.onhandleView(id);
    }

    handleDelete = (event, rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData, 'inactive');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除部件[' + rowData.accNo + ']吗?', changeStatus, null, false);
    };

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }
    handleCreate(event) {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: false,
            statusValue: 'new',
            company: false,
            deviceSearch: false,
            companyId: null,
            amount: true,
            libSelect: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccessoryCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let param = _.clone(this.props.selectedAccessory[0])
                        param.deviceId = this.props.accessoryDeviceId;
                        this.props.onhandleRelevance(param);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleReplace = (event, row) => {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: false,
            statusValue: 'new',
            company: false,
            deviceSearch: false,
            companyId: null,
            amount: true,
            libSelect: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccessoryCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let param = {
                            deviceId: this.props.accessoryDeviceId,
                            orginAccId: row.accId,
                            newAccId: this.props.selectedAccessory[0].accId
                        }
                        this.props.onhandleReplace(param);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    render() {
        return (
            <DeviceAccessoryList
                tabValue={this.props.tabValue}
                index={this.props.index}
                userProfile={this.props.userProfile}
                onEdit={this.handleEdit.bind(this)}
                // getAccessoryList={this.props.onhandleGetAccessoryList}
                onDelete={this.handleDelete.bind(this)}
                deviceAccessoryList={this.props.deviceAccessoryList}
                catList={this.props.catList}
                onSizeChange={this.handleListSize.bind(this)}
                onView={this.handleView.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onReplace={this.handleReplace.bind(this)}
                // 当前设备
                currentDevice={this.props.currentDevice}
                isView={this.props.isview}
                isDeviceEdit={this.props.isDeviceEdit}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.accessory.search,
        userProfile: state.auth.userProfile,
        deviceAccessoryList: state.accessory.deviceAccessoryList,
        selectedAccessory: state.accessorySelect.selectedAccessory,
        catList: state.trees.deviceCategoryList,
        currentUserInfo: state.auth.currentUserInfo.roleId,
        accessoryDeviceId: state.devices.accessoryDeviceId,
        // 当前设备
        currentDevice: state.devices.currentDevice,
        isDeviceEdit: state.devices.deviceEdit,
        isview: state.devices.view,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitAccessoryList: () => {
            dispatch(initAccessoryList());
        },
        // onhandleGetAccessoryList: (offset, size, sort, cat, search, companyId, isCommon, status) => {
        //     dispatch(getAccessoryList(offset, size, sort, cat, search, companyId, isCommon, status))
        // },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleView: (id) => {
            dispatch(initView(id));
        },
        onhandleChangeStatus: (rowData, status) => {
            dispatch(changeAccessoryStatus(rowData, status));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleBackToList: () => {
            dispatch(backToDeviceList())
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('accessorySelect', param, 'medium'))
        },
        onhandleSelectAccessoryCheck: () => {
            dispatch(createMessage('error', `请选择一部件`));
        },
        onhandleReplace: (param) => {
            dispatch(accessoryReplace(param));
        },
        onhandleRelevance: (param) => {
            dispatch(accessoryRelevance(param));
        }
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceAccessoryListContainer);