import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Card,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Tooltip,
  IconButton
} from "@material-ui/core";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { getMoment } from '@/utils/datetime'
import _ from 'lodash';

function OnsiteInspectionPlanReport(props) {
  const {
    classes,
    inspectionDictionaries,
    routeList,
    departmentList,
    companyTree,
    companyList,
    search,
    onhandleSelectChange,
    onhandleLoad,
    isLoad,
    onhandleExportCsv
  } = props;

  // console.log('===== search ====== ')
  // console.log(search)

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }
    return false
  }

  // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
  const noOptionDisabled = (optionList) => {
    if (optionList && optionList.length > 0) {
      return false
    }
    return true
  }

  const getInspectionTypeList = () => {
    let result = [];
    _.map(inspectionDictionaries, t => {
      if (t.type === "OITYPE" && t.code !== 'OIT05') {
        result.push({ label: t.name, value: t.code })
      }
    })
    return result;
  };

  // const getOnsiteInspectionItemList = () => {
  //   let result = [];
  //   _.map(inspectionDictionaries, t => {
  //     if (t.type === "OITYPE" && t.code !== 'OIT05') {
  //       result.push({ label: t.name, value: t.code })
  //     }
  //   })
  //   return result;
  // };

  const getStatusData = () => {
    let result = []
    result.push({ label: '未开始', value: 'new' })
    result.push({ label: '进行中', value: 'active' })
    result.push({ label: '已提交', value: 'pending' })
    result.push({ label: '已完成', value: 'done' })
    result.push({ label: '已补录', value: 'expired' })

    return result
  }

  const getDptData = (companyId) => {
    let company = _.isObject(companyId) ? companyId.value : companyId

    let item = _.filter(departmentList, function (o) { return _.toInteger(company) === o.companyId })
    let result = []
    if (item && item.length) {
      _.map(item, t => {
        result.push({ label: t.dptName, value: t.dptId })
      })
    }
    return result
  }

  const getRouteData = (companyId) => {
    let company = _.isObject(companyId) ? companyId.value : companyId

    let item = _.filter(routeList, function (o) { return _.toInteger(company) === o.companyId })
    let result = []
    if (item && item.length) {
      _.map(item, t => {
        result.push({ label: t.routeName, value: t.routeId });
      })
    }
    return result;
  }

  const getintervalDate = () => {
    if (search.intervalDate.view === 'day') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY/MM/DD")
    } else if (search.intervalDate.view === 'week') {
      let start = getMoment()(search.intervalDate.fromDate).format("MM/DD")
      let end = getMoment()(search.intervalDate.toDate).format("MM/DD")
      return start + '~' + end
    } else if (search.intervalDate.view === 'month') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY/MM")
    } else if (search.intervalDate.view === 'year') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY")
    }
  };

  const goToFrontDate = () => {
    if (search.intervalDate.view === 'day') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'day')
      onhandleSelectChange(day, 'day')
    } else if (search.intervalDate.view === 'week') {
      let day = getMoment()(search.intervalDate.fromDate).add(-7, 'days')
      onhandleSelectChange(day, 'week')
    } else if (search.intervalDate.view === 'month') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'month')
      onhandleSelectChange(day, 'month')
    } else if (search.intervalDate.view === 'year') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'year')
      onhandleSelectChange(day, 'year')
    }
  }

  const goToNextDate = () => {
    if (search.intervalDate.view === 'day') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'day')
      onhandleSelectChange(day, 'day')
    } else if (search.intervalDate.view === 'week') {
      let day = getMoment()(search.intervalDate.fromDate).add(7, 'days')
      onhandleSelectChange(day, 'week')
    } else if (search.intervalDate.view === 'month') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'month')
      onhandleSelectChange(day, 'month')
    } else if (search.intervalDate.view === 'year') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'year')
      onhandleSelectChange(day, 'year')
    }
  }

  const nextDisabled = () => {

    let end = _.cloneDeep(search.intervalDate.toDate)
    let now = getMoment()().format("YYYY/MM/DD")
    if (end >= now) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      <Card className={classes.card}>
        <Grid container alignItems="center">
          <Grid container className={classes.title}>
            <Typography variant="h3" className={classes.plantname}>{'巡检报表'}</Typography>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'所属单位'}</Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.companyId || 0}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="standard"
              disabled={companyDisabled()}
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'巡检类型'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.inspectionType || 0}
              onChange={(event) => onhandleSelectChange(event, 'inspectionType')}
              variant="standard"
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'巡检路线'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.routeId || 0}
              onChange={(event) => onhandleSelectChange(event, 'routeId')}
              variant="standard"
              disabled={noOptionDisabled(getRouteData(search.companyId))}
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(getRouteData(search.companyId), 'routeId').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'执行班组'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.dptId || 0}
              onChange={(event) => onhandleSelectChange(event, 'dptId')}
              variant="standard"
              disabled={noOptionDisabled(getDptData(search.companyId))}
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(getDptData(search.companyId), 'dptId').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'巡检设备'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.deviceId || 0}
              onChange={(event) => onhandleSelectChange(event, 'deviceId')}
              variant="standard"
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(deviceList, 'deviceId').map((option) => (
                <MenuItem key={option.deviceId} value={option.deviceId}>
                  {option.deviceName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'巡检项目'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.genItem || 0}
              onChange={(event) => onhandleSelectChange(event, 'genItem')}
              variant="standard"
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(genItemList, 'genItem').map((option) => (
                <MenuItem key={option.genItemId} value={option.genItemId}>
                  {option.genItemName}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              {'计划状态'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              style={{ width: '90%' }}
              select
              value={search.status || 0}
              onChange={(event) => onhandleSelectChange(event, 'status')}
              variant="standard"
            >
              <MenuItem key={1} value={0}>{'不限'}</MenuItem>
              {_.sortBy(getStatusData(), 'status').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={4}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="视图" name="view"
                defaultValue="day"
                value={search.intervalDate.view || ''}
                onChange={(event) => onhandleSelectChange(event, 'view')}
                style={{ width: '100%' }}
              >
                <FormControlLabel value="year" control={<Radio />} label="年视图" />
                <FormControlLabel value="month" control={<Radio />} label="月视图" />
                <FormControlLabel value="week" control={<Radio />} label="周视图" />
                <FormControlLabel value="day" control={<Radio />} label="日视图" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item className={classes.toolbarButton}>
            <Button onClick={event => goToFrontDate(event)} size="small" >
              <ArrowBackIos size="small" />
            </Button>
          </Grid>
          <Grid item className={classes.toolbarLabel}>
            <Typography className={classes.label}>{getintervalDate()}</Typography>
          </Grid>
          <Grid item className={classes.toolbarButton} >
            <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
          <Grid item className={classes.toolbarButton}>
            <Tooltip title="导出报告">
              <IconButton aria-label="delete" onClick={event => onhandleExportCsv(event)} size="small" disabled={isLoad}>
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.card}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            {isLoad ? onhandleLoad() : ''}
            <div id="main" style={{ width: '100%', height: 500 }}></div>
          </Grid>
        </Grid>
      </Card>
    </div >
  );
}

OnsiteInspectionPlanReport.propTypes = {
  classes: PropTypes.object.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  // deviceList: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleLoad: PropTypes.func.isRequired,
  isLoad: PropTypes.bool.isRequired,
  onhandleExportCsv: PropTypes.func.isRequired,
};

export default withStyles(OnsiteInspectionPlanReport);
