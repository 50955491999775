import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
    Link,
    Tooltip
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import ProcessLine from '@/ProcessLine';
// import { PLAN_VALUATION_TYPE, getCodeListByType, getCodeName } from '@/utils/manufactureUtils';
import { formatOnlyMonth } from '@/utils/datetime'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';

const MAnnualPlanComponent = props => {
    const { classes,
        plans,
        companyId,
        subCompanyList,
        selectedDate,
        year,
        roleFlag,
        isMainCompany,
        onhandleEditAnnualPopUp,
        onhandleDateChange,
        onhandleCompanyChange,
        onhandleDptPopUp,
        onhandleAppendServciePopUp,
        planDtls,
    } = props;


    // const tableOptions = {
    //     ...options,
    //     paging: false,
    // }

    const tableStyle = {
        ...style,
    }

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,
        fixedColumns: {
            left: 3,
        },

    }
    const getCompanyName = (companyId) => {
        const company = _.find(subCompanyList, { 'companyId': companyId });
        return company === undefined ? '' : company.companyName
    }

    const assemblyDetailColumns = () => {

        const columns = [
            {
                title: '服务',
                field: 'dtlName',
                width: 150,
                render: row => <Typography>{row.service ? row.service.serviceName : ''}</Typography>,
                editable: 'never'
            },
            {
                title: '数量',
                field: 'output',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>
                    <Link
                        color="primary"
                        underline="always"
                        target="_blank"
                        component="button"
                        variant="body2"
                        display="inline"
                        onClick={() => onhandleEditAnnualPopUp(row)}>
                        {row.output ? row.output.toFixed(2) : row.output}
                        {Math.abs(row.output - row.monthSum) > 0.01 && row.monthSum
                            && <Tooltip title={"月度总和为" + row.monthSum.toFixed(2) + ",与年度相差" + (row.monthSum - row.output).toFixed(2)} >
                                <ErrorOutlineIcon fontSize="small" color="error" />
                            </Tooltip>} </Link>
                </Typography>,

            }, {
                title: '单位',
                field: 'outputUnit',
                width: 100,
                render: row => <Typography>{row.service ? row.service.unit : ''}</Typography>,
                editable: 'never'
            },
        ]
        const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        for (let index = 0; index < 12; index++) {
            let column = {
                title: months[index],
                field: 'outputDtl' + index,
                width: 100,
                align: 'right',
                render: row => <Typography>
                    {<Link
                        key={index}
                        color="primary"
                        underline="always"
                        target="_blank"
                        component="button"
                        variant="body2"
                        display="inline"
                        onClick={() => onhandleDptPopUp(row, index + 1)}>
                        {row['output_' + Number(index + 1)] ? row['output_' + Number(index + 1)].toFixed(2) : 0}
                    </Link>}
                </Typography>,
            }
            columns.push(column)
        }
        return columns
    }

    const assemblyDetailData = () => {
        let rows = plans.length > 0 ? plans[0].manufacturePlanDtls : []
        _.forEach(rows, row => {
            let monthSum = 0
            let dtls = _.filter(planDtls, { 'serviceId': row.serviceId });
            let planDtlsNew = dtls.map((item, index, array) => {
                if (formatOnlyMonth(item.startTime) === formatOnlyMonth(item.endTime)) {
                    let month = formatOnlyMonth(item.startTime);
                    item['month'] = month.substring(0, 1) === '0' ? parseInt(month.substring(1, 2)) : parseInt(month);
                } else {
                    item['month'] = 13;
                }
                return item;
            })
            planDtlsNew.forEach((item, index) => {
                if (item.month === 13) {
                    planDtlsNew.splice(index, 1);
                } else {
                    row['dtlId_' + item.month] = item.dtlId
                    row['output_' + item.month] = item.output
                    monthSum += item.output
                }
            })
            row['monthSum'] = monthSum
        })
        return rows
    }

    // const monthassemblycolumns = () => {

    //     const columns = [
    //         {
    //             title: '月份',
    //             field: 'month',
    //             align: 'center',
    //             width: 120,
    //             render: row => <Typography>{row.month}</Typography>,
    //             editable: 'never'
    //         },
    //         {
    //             title: '数量',
    //             field: 'output',
    //             type: 'numeric',
    //             align: 'left',
    //             width: 50,
    //             render: row => <Typography>{row.output}</Typography>
    //         },
    //         {
    //             title: '单位',
    //             field: 'outputUnit',
    //             width: 50,
    //             render: row => <Typography>{row.outputUnit}</Typography>,
    //             editable: 'never'
    //         },
    //     ]
    //     return columns
    // }

    // const monthassemblyData = (dtl, i) => {
    //     let dtls = _.filter(planDtls, { 'serviceId': dtl.serviceId });
    //     let planDtlsNew = dtls.map((item, index, array) => {
    //         if (formatOnlyMonth(item.startTime) === formatOnlyMonth(item.endTime)) {
    //             let month = formatOnlyMonth(item.startTime);
    //             item['month'] = month.substring(0, 1) === '0' ? parseInt(month.substring(1, 2)) : parseInt(month);
    //         } else {
    //             item['month'] = 13;
    //         }
    //         return item;
    //     })
    //     planDtlsNew.forEach((item, index) => {
    //         if (item.month === 13) {
    //             planDtlsNew.splice(index, 1);
    //         }
    //     })
    //     let plandtls = plans.length > 0 ? plans[0].manufacturePlanDtls : []
    //     const plandtl = planDtlsNew.find(p => p.month === i)
    //     const currDtl = plandtls.find(pd => pd.dtlId === dtl.dtlId)
    //     if (plandtl && currDtl) {
    //         currDtl['dtlId_' + i] = plandtl.dtlId
    //     }
    //     return planDtlsNew.find(p => p.month === i)
    // }


    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>

                </Grid>
                <Grid container>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                views={['year']}
                                format="yyyy"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item className={classes.companySelect}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblyDetailColumns()}
                        data={assemblyDetailData()}
                        options={detailTableOptions}
                        localization={detailTableLocalization}
                        style={tableStyle}
                        actions={roleFlag ? [
                            {
                                icon: 'add',
                                tooltip: '新增服务到年度计划',
                                isFreeAction: true,
                                onClick: (event) => onhandleAppendServciePopUp(event)
                            }
                        ] : []}
                        title={<Typography variant='h3'>{year}年{getCompanyName(companyId)}生产运行计划明细</Typography>}
                    ></MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

MAnnualPlanComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    plans: PropTypes.any,
    subCompanyList: PropTypes.array,
    selectedDate: PropTypes.object,
    year: PropTypes.any,
    onhandleDateChange: PropTypes.func,
    onhandleRowAdd: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
    onhandleRowDtlAdd: PropTypes.func,
    onhandleRowDtlUpdate: PropTypes.func,
    onhandleRowDtlDelete: PropTypes.func,
};

MAnnualPlanComponent.defaultProps = {
};

export default withStyles(MAnnualPlanComponent);