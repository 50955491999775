import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import { TextField, Button, Grid, Typography, MenuItem } from "@material-ui/core";
import { ExpandMore, ExpandLess, Add } from '@material-ui/icons';
import _ from 'lodash';

const AccessoryHeader = props => {
  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    search,
    onhandleSelectChange,
    onhandleChange,
    catTree,
    // 设备类别
    devCatTree,
    companyTree,
    companyList,
    userProfile,
    onhandleCreate
  } = props;

  const [expand, setExpand] = useState(false);

  const checkPermission = () => {
    let permission = _.find(userProfile.roles[0].routes, { 'route': 'accessory_list' })
    return permission && permission.action === 'W'
  }

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }

  const isCommonData = () => {
    return [
      { label: "是", value: true, pcode: null, children: null },
      { label: "否", value: false, pcode: null, children: null }
    ]
  }

  return (
    <div>
      <Grid container alignItems="center" className={classes.title}>
        <Typography variant="h3">
          {'部件列表'}
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container spacing={0} className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={6}>

          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              单&emsp;&emsp;位
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              id="filled-select-company"
              select
              value={search.companyId ? search.companyId : ''}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="outlined"
              disabled={companyDisabled()}
            >
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.companyId} value={option.companyId}>
                  {option.companyAlias}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              部件名称
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              value={search.search || ''}
              variant="outlined"
              placeholder="部件编号，名称，型号"
              onChange={event => onhandleChange(event, 'search')}
            />
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              部件类别
            </Typography>
            <TreeSelect
              className={classes.textFieldInput}
              transitionName="rc-tree-select-dropdown-slide-up"
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
              allowClear
              treeLine
              value={search.cat}
              labelInValue
              treeData={catTree}
              treeNodeFilterProp="label"
              filterTreeNode={false}
              onChange={(value) => onhandleSelectChange(value, 'cat')}
            />
          </Grid>
          {Boolean(expand) &&
            <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  通用部件
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.isCommon}
                  labelInValue
                  treeData={isCommonData()}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'isCommon')}
                />
              </Grid>

              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  设备类型
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.devCat}
                  labelInValue
                  treeData={devCatTree}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'devCat')}
                />
              </Grid>

              {/* 
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography  className={classes.label}>
              使用状态
            </Typography>
            <TreeSelect
              className={classes.textFieldInput}
              transitionName="rc-tree-select-dropdown-slide-up"
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
              allowClear
              treeLine
              value={search.status}
              labelInValue
              treeData={useStatusData()}
              treeNodeFilterProp="label"
              filterTreeNode={false}
              onChange={(value) => onhandleSelectChange(value, 'status')}
            />
          </Grid> */}
            </React.Fragment>}
        </Grid>
        {/* 按钮区 */}
        <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>

          {/* 搜索区 */}
          <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >
              搜索
            </Button>
            <Button variant="outlined" style={{ marginLeft: '10px' }}
              onClick={onhandleClearSearch}

            >
              重置
            </Button>
            <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => setExpand(!expand)}
              endIcon={expand ? <ExpandLess /> : <ExpandMore />}
            >
              {expand ? '收起' : '展开'}
            </Button>
          </Grid>
          {/* 表格工具条区 */}
          <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
              onClick={onhandleCreate}
            >
              新建
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

AccessoryHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  catTree: PropTypes.array.isRequired,
  // 设备类别
  devCatTree: PropTypes.array.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
};

export default withStyles(AccessoryHeader);
