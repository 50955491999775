import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  MenuItem,
  TextField,
  Button,
  Grid
} from '@material-ui/core';
import {
  ExpandMore,
  ExpandLess
} from '@material-ui/icons';
import TreeSelect from 'rc-tree-select';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';
import { WORKORDER_STATUS_ALL } from '@/utils/operationUtils'

import _ from 'lodash';

const WorkOrderHeader = props => {
  const {
    classes,
    getWorkOrderList,
    onhandleClearSearch,
    search,
    routeType,
    onhandleSelectChange,
    onhandleCheck,
    companyTree,
    companyList,
    orderTypeTree,
    sourceList,
  } = props;

  const [expand, setExpand] = useState(false);

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }

  const getWorkOrderStatusList = () => {
    let statusList = _.map(_.keys(WORKORDER_STATUS_ALL), (k) => {
      return {
        code: k,
        name: WORKORDER_STATUS_ALL[k]
      }
    })
    return statusList
  }

  return (
    <div>
      <Grid container alignItems="center" className={classes.title}>
        <Typography variant="h3">
          {'工单列表'}
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container alignItems="center" className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={6}>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              单&emsp;&emsp;位
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              id="filled-select-company"
              select
              value={search.companyId}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="outlined"
              disabled={companyDisabled()}
            >
              <MenuItem aria-label="None" value={''}></MenuItem>
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.companyId} value={option.companyId}>
                  {option.companyAlias}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={1}>
              <Typography className={classes.label}>
                部门
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{ width: '90%' }}
                id="filled-select-company"
                select
                value={search.dptId}
                onChange={(event) => onhandleSelectChange(event, 'dptId')}
                variant="outlined"
                disabled={!search.companyId || search.companyId === ''}
              >
                <MenuItem aria-label="None" value={''}></MenuItem>
                {departmentList ?
                  _.sortBy(_.filter(departmentList, { companyId: search.companyId }), 'dptId').map(data => (
                    <MenuItem value={data.dptId} key={data.dptId}>{data.dptName}</MenuItem>
                  )) : null
                }
              </TextField>
            </Grid> */}
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              工单类型
            </Typography>
            <TreeSelect
              className={classes.textFieldInput}
              transitionName="rc-tree-select-dropdown-slide-up"
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
              allowClear
              treeLine
              value={search.orderType}
              labelInValue
              treeData={orderTypeTree}
              treeNodeFilterProp="label"
              filterTreeNode={false}
              onChange={(value) => onhandleSelectChange(value, 'orderType')}
            />
          </Grid>

          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              设备名称
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              value={search.device || ''}
              variant="outlined"
              placeholder="编码，位号，型号，名称"
              onChange={event => onhandleSelectChange(event, 'device')}
            />
          </Grid>
          {Boolean(expand) &&
            <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  工单来源
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  id="filled-select-company"
                  select
                  size={'small'}
                  value={search.source}
                  onChange={(event) => onhandleSelectChange(event, 'source')}
                  variant="outlined"
                >
                  <MenuItem aria-label="None" value={''}></MenuItem>
                  {_.sortBy(sourceList, 'code').map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {routeType === 'all' &&
                <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                  <Typography className={classes.label}>
                    工单状态
                  </Typography>
                  <TextField
                    className={classes.textFieldInput}
                    id="filled-select-company"
                    select
                    size={'small'}
                    value={search.filterStatus ? search.filterStatus : ''}
                    onChange={(event) => onhandleSelectChange(event, 'filterStatus')}
                    variant="outlined"
                  >
                    <MenuItem aria-label="None" value={''}>{'全部'}</MenuItem>
                    {getWorkOrderStatusList().map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              }
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography color='textPrimary' className={classes.label}>
                  工单编号
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  value={search.orderNo}
                  variant="outlined"
                  placeholder="工单编号"
                  onChange={event => onhandleSelectChange(event, 'orderNo')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography color='textPrimary' className={classes.label}>
                  计划编号
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  value={search.optNo}
                  variant="outlined"
                  placeholder="计划编号"
                  onChange={event => onhandleSelectChange(event, 'optNo')}
                />
              </Grid>
            </React.Fragment>}
        </Grid>
        {/* 按钮区 */}
        <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 3 : 6}>
          {/* 搜索区 */}
          <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            <Button variant="contained" color="primary"
              onClick={getWorkOrderList}
            >
              搜索
            </Button>
            <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
              onClick={onhandleClearSearch}

            >
              重置
            </Button>
            <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => setExpand(!expand)}
              endIcon={expand ? <ExpandLess /> : <ExpandMore />}
            >
              {expand ? '收起' : '展开'}
            </Button>
            <FormControlLabel
              className={classes.switch}
              label={<Typography noWrap style={search.onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的工单</Typography>} control={
                <Checkbox size={'small'}
                  checked={search.onlyMe}
                  onChange={event => onhandleCheck(event, 'onlyMe')} />
              } />
          </Grid>
        </Grid>
      </Grid>
    </div >
  );
};

WorkOrderHeader.propTypes = {
  getWorkOrderList: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  orderTypeTree: PropTypes.array.isRequired,
  sourceList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
};

export default withStyles(WorkOrderHeader);
