import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  nav: {
    backgroundColor: theme.palette.white,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  navlink: {
    color: colors.grey
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  dragCard: {
    cursor: 'grab',
    padding: theme.spacing(2)
  },
  dragCard_grabbing: {
    cursor: 'grabbing',
    padding: theme.spacing(2)
  }
});

export default withStyles(styles);
