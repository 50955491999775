import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import WbSunny from '@material-ui/icons/WbSunny';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as _ from 'lodash';
import { SAFETY_ACTIVITY_ICONS, getHolidayLabel } from '../../../utils/constant';
import { formatWeekday, formatMonth, getMoment } from '../../../utils/datetime'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

let allViews = ['month'] //Object.keys(Views).map(k => Views[k]
const localizer = momentLocalizer(getMoment())

const MyCalendarComponent = ({
    classes,
    activityList,
    constant,
    currentUserInfo,
    onhandleOpenActivity,
    onhandleOpenActivityList,
    onhandleChangeDate,
}) => {

    const events = _.map(activityList, a => {
        return {
            ...a, id: a.actId,
            title: a.activity,
            start: a.activityTime,
            end: a.activityTime,
        }
    })

    const formats = {
        weekdayFormat: (date) => formatWeekday(date),
        monthHeaderFormat: (date) => formatMonth(date)
    }

    const handleSelectEvent = event => {
        if (event) onhandleOpenActivity(event)
    }
    const handleSelectSlot = event => {
        if (event) onhandleOpenActivityList(event)
    }

    const CustomToolbar = (props) => {

        const goToLastMonth = () => {
            let m = moment(props.date)
            m.add(-1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('prev', props.date)
            onhandleChangeDate(props.date)
        }

        const goToNextMonth = () => {
            let m = moment(props.date)
            m.add(1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('next', props.date)
            onhandleChangeDate(props.date)
        }

        return (
            <Grid container className={classes.toolbar}>
                <Grid item>
                    <Button className={classes.toolbarButton} onClick={goToLastMonth}>
                        <ArrowBackIos />
                    </Button>
                </Grid>
                <Grid item className={classes.toolbarLabel}>
                    <Typography className={classes.toolbarLabelText} variant='h5'>{props.label}</Typography>
                </Grid>
                <Grid item className={classes.toolbarButton} onClick={goToNextMonth}>
                    <Button><ArrowForwardIos /></Button>
                </Grid>
            </Grid>
        )
    }

    const CustomMonthHeader = (props) => {
        return (
            <div style={{
                backgroundColor: '#FFF',
                margin: '-1px -10px',
                lineHeight: '45px',
            }}
                onClick={() => console.log('clicked here')} > {props.label} </div>
        )
    };
    const Event = (event) => {

        const eventData = event.event
        let icon = SAFETY_ACTIVITY_ICONS[eventData.activityType]
        if (eventData.title.substr(eventData.title.length - 1, 1) === '白') {
            icon = <WbSunny fontSize="small" />
        }
        return (
            <div className={classes.calendarEvent}>
                <Grid container className={classes.calendarEventContainer}>
                    <Grid item className={classes.calendarEventContainerIcon}>{icon} </Grid>
                    <Grid item>
                        <Typography >{eventData.title}</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: 'rgba(0,0,0,0)',
        };
        return {
            style: style
        };
    }

    const customDayPropGetter = value => {

    }

    const dateHeader = ({ date, label }) => {

        const todayHolidays = constant.holidays.filter((item) => {
            return moment(date).isBetween(moment(item.startTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'), moment(item.endTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'))
        })

        let headerLabel = ''
        if (todayHolidays.length > 0) {
            for (const h of todayHolidays) {
                headerLabel = headerLabel + ' ' + getHolidayLabel(h)
            }
        }
        return (
            <div>{headerLabel + ' ' + moment(date).format('D')}</div>
        );
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Calendar
                selectable
                formats={formats}
                localizer={localizer}
                events={events}
                views={allViews}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onShowMore={handleSelectSlot}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={eventStyleGetter}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 650 }}
                //toolbar={false}
                components={{
                    toolbar: props => (<CustomToolbar  {...props} />),
                    month: {
                        header: CustomMonthHeader,
                        dateHeader: ({ date, label }) => dateHeader({ date, label })
                    },
                    event: Event
                }}
                messages={{
                    showMore: (count) => ('+ ' + count + ' 查看更多'),
                }}
            />
        </div>
    );
};
MyCalendarComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

MyCalendarComponent.defaultProps = {
    activityList: [],
    constant: {},
    currentUserInfo: {},
}

export default withRouter(withStyles(MyCalendarComponent));