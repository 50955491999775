import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  gridContainer: {
    paddingBottom: theme.spacing(2),
  },
  gridItem: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  label: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
    width: 90,
    textAlign: 'right'
  },
  formContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 90px)'
  },
  textField: {
    width: '90%'
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  processline: {
    flexGrow: 1
  },
  attention: {
    // marginLeft: theme.spacing(4),
    //marginRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    background: 'rgba(255,80,0,0.05)',
    border: '1px solid rgba(255,80,0,1)'
  },
  attentiontitle: {
    fontWeight: 500,
    color: '#FF5000FF'
  },
  attentioncontent: {
    color: '#FF5000FF'
  },
  detailtable: {
    padding: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  tabs: {
    background: 'none',
    color: '#2979ff',
    '&:hover': {
      color: '#2979ff',
      opacity: 1
    },
    '&:focus': {
      color: '#2979ff'
    }
  },
  required: {
    display: 'inline-block',
    color: theme.palette.error.main,
    marginRight: 2,
  },
});

export default withStyles(styles);