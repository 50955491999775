import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getRuleList,
    initUpdate,
    deleteRule,
    initView,
    initCreate,
    initRuleHeader,
    setSearch
} from "@/redux/modules/operationRule";
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { openApprovePannel } from "@/redux/modules/approvePannel";
import RuleListComponent from "@/components/DeviceManagement/OperationRule/RuleListComponent"
import PropTypes from "prop-types";

class RuleListContainer extends Component {
    static propTypes = {
        rules: PropTypes.object,
        userProfile: PropTypes.object,
        currentUserInfo: PropTypes.object,
        constant: PropTypes.object,
        optCategories: PropTypes.array
    };

    componentDidMount() {
        this.props.onhandleInitRuleHeader();
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    getRules(query) {
        this.props.onhandleGetRuleList(query);
    }

    delete(event, id, name) {
        let doDelete = () => {
            this.props.onhandleDelete(id, name);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除设备运维规则[' + name + ']吗?', doDelete, null, false);
    }

    edit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleView(event, id) {
        this.props.onhandleView(id);
    }

    handleRestart() {
        this.props.onhandleRestartRule();
    }

    handlePageChange(current, pageSize) {

        let newSearch = {
            ...this.props.search,
            page: current,
            size: pageSize
        };
        this.props.onhandleUpdateEditSearch(newSearch)
        this.props.onhandleGetRuleList({
            optCategory: newSearch.optCategory,
            size: newSearch.size,
            offset: newSearch.page * newSearch.size - newSearch.size
        });
    }

    handleSelect = (event, field) => {
        let newSearch;
        switch (field) {
            case 'optCategory':
                newSearch = {
                    ...this.props.search,
                    offset: 0,
                    optCategory: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                this.props.onhandleGetRuleList({
                    optCategory: newSearch.optCategory,
                    size: newSearch.size,
                    offset: newSearch.page * newSearch.size - newSearch.size
                });
                break;
            default:
            // do nothing
        }
    }

    render() {
        return (
            <div>
                <RuleListComponent
                    rules={this.props.rules}
                    onDelete={this.delete.bind(this)}
                    onEdit={this.edit.bind(this)}
                    onView={this.handleView.bind(this)}
                    getRuleList={this.getRules.bind(this)}
                    onhandleCreate={this.handleCreate.bind(this)}
                    constant={this.props.constant}
                    optCategories={this.props.optCategories}
                    userProfile={this.props.userProfile}
                    onhandleRestart={this.handleRestart.bind(this)}
                    search={this.props.search}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onhandlePageChange={this.handlePageChange.bind(this)}

                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rules: state.operationRule.rules,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        constant: state.constant,
        optCategories: state.operationRule.optCategories,
        search: state.operationRule.search,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleInitRuleHeader: () => {
            dispatch(initRuleHeader())
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleGetRuleList: (query) => {
            dispatch(getRuleList(query))
        },
        onhandleDelete: (id, name) => {
            dispatch(deleteRule(id, name));
        },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleView: (id) => {
            dispatch(initView(id));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleListContainer)
