import { connect } from 'react-redux';
import { cleanContentMenu } from '../redux/modules/contentmenu';
import MyCanvas from '../components/MyCanvas';
import { createDialog, cleanDialog } from '../redux/modules/dialog';
import { createMapDevice, createMapUnit, createMapArea, deleteMapDevice, deleteMapUnit, deleteMapArea, createMapDeviceGroup, updAutoSave } from '../redux/modules/installationRelation'
import { initDeviceTreeData, changeExistTreeData } from '../redux/modules/deviceTree';

const mapStateToProps = (state) => {
    return {
        ...state.setting,
        ...state.installation,
        ...state.installationRelation,
        ...state.deviceTree,
        ...state.devices,
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanContentMenu: () => {
            dispath(cleanContentMenu());
        },
        createDialog: (children, parameters, showIcon) => {
            dispath(createDialog(children, parameters, showIcon))
        },
        cleanDialog: () => {
            dispath(cleanDialog())
        },
        createMapDevice: () => {
            dispath(createMapDevice())
        },
        createMapDeviceGroup: () => {
            dispath(createMapDeviceGroup())
        },
        deleteMapDevice: () => {
            dispath(deleteMapDevice())
        },
        createMapUnit: () => {
            dispath(createMapUnit())
        },
        createMapArea: () => {
            dispath(createMapArea())
        },
        initDeviceTreeData: () => {
            dispath(initDeviceTreeData())
        },
        changeExistTreeData: (value) => {
            dispath(changeExistTreeData(value))
        },
        deleteMapUnit: () => {
            dispath(deleteMapUnit())
        },
        deleteMapArea: () => {
            dispath(deleteMapArea())
        },
        updAutoSave: (value) => {
            dispath(updAutoSave(value))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(MyCanvas);