import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dialogContent: {
        minWidth: 600
    },
    action: {
        padding: 16,
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
});

export default withStyles(styles);