import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WorkRecordPanel from '@/components/OperationManagement/Appointment/AppointmentDetail/WorkRecordPanel';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { download } from '@/redux/modules/minio';
// import { getMoment as moment } from "@/utils/datetime";
import _ from 'lodash';

class WorkRecordPanelContainer extends Component {
    static propTypes = {
        userProfile: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            recordError: {
                content: '',
                result: '',
                workTime: '',
                file: '',
            },
            open: false,
            record: {},
            confirmCallback: null,
            closeCallback: null,
        };
    }
    componentDidUpdate = (oldProps) => {
        if (oldProps.record !== this.props.record) {
            this.setState({
                recordError: {
                    content: '',
                    result: '',
                    workTime: '',
                    file: '',
                },
                open: this.props.open,
                record: this.props.record,
                confirmCallback: this.props.confirmCallback,
                closeCallback: this.props.closeCallback,
            })
        }

    }


    handleConfirm() {
        const { confirmCallback, record } = this.state;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(record)
            // if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { closeCallBack } = this.props;
        // close action
        if (closeCallBack) {
            closeCallBack()
        } else {
            this.setState({
                open: false
            })
        }

    }

    handleClearDevice() {
        let newRecord = {
            ...this.state.record
        }
        newRecord.deviceId = 0;
        newRecord.deviceName = '';
        this.setState({
            record: newRecord
        })
    }

    handleDeleteFile(e) {
        let newRecord = {
            ...this.state.record
        }
        newRecord.docsBack = this.state.record.docs
        newRecord.docs = [];
        this.setState({
            record: newRecord
        })
        setTimeout(() => {
            this.handleCheck(null, null);
        }, 100)
    }

    handleDownloadFile() {
        const { onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.work-record-doc', '{tenant_id}', tenantId);
        const fileName = this.state.solution.solutionDoc.docLink;
        onhandleDownloadFile(fileName, mybucket);
    }

    handleChange(event) {
        let newRecord = {
            ...this.state.record
        }
        const field = event.target.name;
        newRecord[field] = event.target.value;

        this.setState({
            record: newRecord
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleUploadConfirm(files, setUploadStatus) {
        let newRecord = {
            ...this.state.record
        }
        newRecord.file = files;
        this.setState({
            record: newRecord
        })
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
        setTimeout(() => {
            this.handleCheck(null, 'file');
        }, 100)
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: true,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field || field === 'content') {
            if (_.trim(this.state.record.content) === '') {
                err['content'] = '记录描述不能为空';
            } else {
                err['content'] = '';
            }
        }

        if (!field || field === 'result') {
            if (_.trim(this.state.record.result) === '') {
                err['result'] = '记录结果不能为空';
            } else {
                err['result'] = '';
            }
        }

        if (!field || field === 'workTime') {
            if (_.trim(this.state.record.workTime) === '') {
                err['workTime'] = '工作用时不能为空';
            } else {
                err['workTime'] = '';
            }
        }

        if (!field || field === 'file') {
            // 检测文件是否上传
            if (!this.state.record.file || !this.state.record.file.length || this.state.record.file.length === 0
            ) {
                err['file'] = '请上传记录文档';
            } else {
                err['file'] = '';
            }
        }

        let newError = {
            ...this.state.recordError,
            ...err
        }
        this.setState({
            recordError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    handleSelectDevice = (event) => {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.state.record.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let selectedDevice = _.cloneDeep(this.props.selectedDevices[0])
                        let newRecord = {
                            ...this.state.record
                        }
                        newRecord.deviceId = selectedDevice.deviceId;
                        newRecord.deviceName = selectedDevice.deviceName;
                        this.setState({
                            record: newRecord
                        })
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);
    }

    render() {
        return (
            <WorkRecordPanel
                open={this.props.open}
                record={this.state.record}
                recordError={this.state.recordError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleDeleteFile={this.handleDeleteFile.bind(this)}
                onhandleDownloadFile={this.handleDownloadFile.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
                onhandleClearDevice={this.handleClearDevice.bind(this)}
                onhandleSelectDevice={this.handleSelectDevice.bind(this)}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        selectedDevices: state.deviceSelect.selectedDevices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleDownloadFile: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkRecordPanelContainer);