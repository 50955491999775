import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getOppoById } from './customerOppo';
import { removeObject } from './minio';


const porxyApi = '/api'

const initialState = {
    search: {
        companyId: undefined,
        dangerLevel: undefined,
        search: undefined,
        statusTree: [],

    },
    pageSize: 5,
    dangerLevelList: [],
    oppolist: {
        total: 0,
        list: []
    },
    statusList: [],
    customerDoc: {}
}

export const changeCustomerDocStatus = createAction(
    '@@xcloud/customerdoc/changeCustomerDocStatus',
    (customerDocDto, mybucket) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.docId = customerDocDto.docId
        dto.tenantId = customerDocDto.tenantId
        dto.status = '0';

        return httpClient
            .patch(porxyApi + `/customers/documents/${dto.docId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    //重新检索列表
                    dispatch(removeObject(mybucket, customerDocDto.docLink));
                    dispatch(getOppoById(customerDocDto.oid));
                    dispatch(createhttpMessage({ status: 200 }, '删除文档成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `删除文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createCustomerDoc = createAction(
    '@@xcloud/customerdoc/createCustomerDoc',
    (doc) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(porxyApi + `/customers/documents`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    //dispatch(setCustomerDoc(res.data))
                    dispatch(getOppoById(doc.oid));
                    dispatch(createhttpMessage({ status: 200 }, '上传客戶文档成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `上传客戶文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);



export const oppoReducer = handleActions(
    {

    },
    initialState
);

export default oppoReducer;