import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    shiftRuleList: [], //班组排班列表
    shiftRule: {}, //班组排班
};

export const setShiftRuleList = createAction('@@xcloud/manufacture/setShiftRuleList');
export const setShiftRule = createAction('@@xcloud/manufacture/setShiftRule');

export const getShiftRules = createAction('@@xcloud/manufacture/getShiftRules',
    (companyId, dptId, startTime, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/shiftrules'
        let strs = []
        if (companyId || dptId || startTime || status) {
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            if (startTime) strs.push('startTime=' + startTime)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setShiftRuleList(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取班组排班列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getShiftRule = createAction('@@xcloud/manufacture/getShiftRule',
    (ruleId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/shiftrules/' + ruleId
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setShiftRule(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取班组排班失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addShiftRule = createAction('@@xcloud/manufacture/addShiftRule',
    (shiftRuleDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/shiftrules', shiftRuleDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加班组排班成功"));
                }
            }).catch(err => {
                let msg = `增加班组排班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateShiftRule = createAction('@@xcloud/manufacture/updateShiftRule',
    (ruleId, shiftRuleDto) => (dispatch, getState, httpClient) => {
        const userInfo = getState().auth.currentUserInfo;
        shiftRuleDto.tenantId = userInfo.tenantId;
        return httpClient.patch(porxyApi + '/manufacture/shiftrules/' + ruleId, shiftRuleDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新班组排班成功"));
                }
            }).catch(err => {
                let msg = `更新班组排班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteShiftRule = createAction('@@xcloud/manufacture/deleteShiftRule',
    (ruleId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/shiftrules/' + ruleId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除班组排班成功"));
                }
            }).catch(err => {
                let msg = `删除班组排班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const createManufactureShift = createAction('@@xcloud/manufacture/createManufactureShift',
    (companyId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/shiftrules/shift/' + companyId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "生成排班成功"));
                }
            }).catch(err => {
                let msg = `生成排班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });



export const manufactureShiftRuleReducer = handleActions(
    {
        [setShiftRuleList]: (state, { payload: data }) => {
            return {
                ...state,
                shiftRuleList: data
            }
        },

        [setShiftRule]: (state, { payload: data }) => {
            return {
                ...state,
                shiftRule: data,
            }
        },
    },
    initialState
)

export default manufactureShiftRuleReducer
