import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    regulations: [],
    open: false,
    regulation: {},
    confirmCallback: null,
    closeCallback: null,
    search: {
        unitId: '',
        search: '',
    }
};

export const closePannel = createAction('@@xcloud/regulation/closepannel');

export const openPannel = createAction('@@xcloud/regulation/openpannel',
    (
        confirmCallback, closeCallback
    ) => ({
        confirmCallback, closeCallback
    })
);
export const changeRegulation = createAction('@@xcloud/regulation/changeregulation');

export const setRegulations = createAction('@@xcloud/regulation/setregulations')

export const getRegulations = createAction('@@xcloud/regulation/getregulations',
    (query) => (dispatch, getState, httpClient) => {
        let url = '/regulations';
        let queries = [];
        if (query.unitId && query.unitId !== '') {
            queries.push('unitId=' + query.unitId);
        }
        if (query.search && query.search !== '') {
            queries.push("search=" + encodeURIComponent(_.trim(query.search)));
        }
        if (query.sort && query.sort !== "") {
            queries.push("sort=" + query.sort);
        } else {
            queries.push("&sort=reg_name");
        }
        if (queries.length > 0) {
            url += '?' + queries.join('&');
        }
        httpClient.get(porxyApi + url, {
            headers: {
                need_loading: 'true'
            }
        })
            .then((res) => {
                if (res) {
                    dispatch(setRegulations(res.data))
                }
            })
            .catch(err => {
                let msg = `获取规章制度失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const initRegulationHeader = createAction('@@xcloud/regulation/initRegulationHeader',
    (query, reload) => (dispatch, getState) => {
        Promise.all([
            dispatch(resetSearch()),
            dispatch(getRegulations(query, reload))
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化设备搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });

export const resetSearch = createAction('@@xcloud/regulation/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().regulation.search;
        let newSearch = {
            ...search,
            unitId: '',
            search: '',
        }
        dispatch(setSearch(newSearch));
    });

export const getMandatoryRegulations = createAction('@@xcloud/regulation/getmandatoryregulations',
    (callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/mandatoryregulations')
            .then((res) => {
                if (res) {
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `获取强制性规章制度失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addRegulation = createAction('@@xcloud/regulation/addregulation',
    (regulation, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulations', regulation)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加规章制度成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `增加规章制度失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteRegulation = createAction('@@xcloud/regulation/deleteregulation',
    (regulation, regId, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/regulations/' + regId, regulation)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除规章制度成功"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `删除规章制度失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateRegulation = createAction('@@xcloud/regulation/updateregulation',
    (regulation, regId, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/regulations/' + regId, regulation)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新规章制度成功"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `更新规章制度失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getRegulationVersions = createAction(
    '@@xcloud/regulation/getregulationversions',
    (regId, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulations/' + regId)
            .then((res) => {
                if (res) {
                    // console.log(res)
                    //dispatch(createhttpMessage(res, "获取version成功"));
                    if (callback)
                        callback(res.data.versions)
                }
            })
            .catch(err => {
                let msg = '获取规章制度失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);
export const createRegulationVersion = createAction(
    '@@xcloud/regulation/createregulationversion',
    (doc, callback) => (dispatch, getState, httpClient) => {
        let currentUserInfo = getState().auth.currentUserInfo;
        doc.creator = currentUserInfo.userId;
        doc.revisor = currentUserInfo.userId;
        doc.tenantId = currentUserInfo.tenantId;
        //dispatch(startLoading());
        return httpClient
            .post(porxyApi + '/regulations/versions', doc)
            .then((res) => {
                dispatch(createhttpMessage(res, "上传文档成功"));
                if (callback) callback();
            })
            .catch(err => {
                let msg = "文档上传失败";
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const updateRegulationVersion = createAction(
    '@@xcloud/regulation/updateregulationversion',
    (versionId, regulationVersion) => (dispatch, getState, httpClient) => {
        //dispatch(startLoading());
        return httpClient
            .patch(porxyApi + '/regulations/versions/' + versionId, regulationVersion)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新文档信息成功"));
            })
            .catch(err => {
                let msg = "更新文档信息失败";
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const setSearch = createAction('@@xcloud/devices/setSearch');

export const RegulationReducer = handleActions(
    {
        [setRegulations]: (state, { payload: data }) => {
            return {
                ...state,
                regulations: data
            }
        },

        [openPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
            }
        },
        [closePannel]: (state) => {
            return {
                ...state,
                open: false,
                regulation: {},
                changeCallback: null,
                closeCallback: null,
            }
        },
        [changeRegulation]: (state, { payload: data }) => {
            return {
                ...state,
                regulation: data,
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
    },
    initialState
)

export default RegulationReducer
