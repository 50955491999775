import React, { Component } from 'react';
import { connect } from 'react-redux';
import AreaEditComponent from '@/components/InstallationManagement/InstallationArea'
import {
    getInstallationAreas,
    routeToInstallationList,
    updInstallationArea,
    createInstallationArea,
} from "@/redux/modules/installation";
import {
    getDevicesbyArea,
    connectDevice2Area,
    disconnectDevice2Area
} from "@/redux/modules/devices";

import PropTypes from "prop-types";
import * as _ from 'lodash';
import AreaInfoEditPannel from '@/components/InstallationManagement/InstallationArea/AreaInfoEditPannel';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';

import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';



class AreaEditContainer extends Component {
    static propTypes = {
        editInstallation: PropTypes.object,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
        editAreas: PropTypes.array,
        userProfile: PropTypes.object,
        currentUserInfo: PropTypes.object,
    };

    constructor(props) {
        super(props)
        this.state = {
            search: {
                page: 1,
                size: 10,
            },
            openAreaEdit: false, //open edit area dailog
            selectedArea: {},
            editingArea: {},
            selectedDeviceKeys: [],
        }
    }

    componentDidMount() {
        const { editInstallation, onhandleGetAreas } = this.props
        if (editInstallation.installId) {
            onhandleGetAreas(editInstallation.installId)
        }
    }

    backToList() {
        this.props.onhandleBackToList();
    }

    handleOpenEditAreaDailog(areaId, parentAreaId) {

        const { editAreas } = this.props
        let editingArea = {}
        if (areaId) {
            let area = _.find(editAreas, { 'areaId': areaId })
            editingArea = _.clone(area)
        }
        if (parentAreaId) {
            editingArea['parentArea'] = parentAreaId
        }
        this.setState({
            openAreaEdit: true,
            editingArea: editingArea
        })

    }

    handleCloseEditAreaDailog() {

        // const { editAreas } = this.props
        // const { editingArea } = this.state

        // let orginSelectedArea = {}
        // if (editingArea.areaId) {
        //     let area = _.find(editAreas, { 'areaId': editingArea.areaId })
        //     orginSelectedArea = _.clone(area)
        // }
        this.setState({
            openAreaEdit: false,
            editingArea: {}
            // selectedArea: orginSelectedArea
        })
    }

    async handleSaveArea() {
        const { editingArea } = this.state
        const { editInstallation,
            onhandleGetAreas,
            onhandleUpdateInstallationArea,
            onhandleCreateInstallationArea } = this.props
        if (editingArea.areaId) {
            //update
            let dto = {
                areaId: editingArea.areaId,
                areaName: editingArea.areaName,
                installId: editInstallation.installId,
            }
            if (editingArea.parentArea) {
                dto['parentArea'] = editingArea.parentArea
            }

            await onhandleUpdateInstallationArea(dto)
        } else {
            //create

            let dto = {
                areaName: editingArea.areaName,
                installId: editInstallation.installId,
            }
            if (editingArea.parentArea) {
                dto['parentArea'] = editingArea.parentArea
            }
            await onhandleCreateInstallationArea(dto)
        }

        await onhandleGetAreas(editInstallation.installId)
        this.setState({
            openAreaEdit: false,
            editingArea: {}
        })
        if (editingArea.areaId) {
            this.handleSelectArea(null, editingArea.areaId)
        }
    }

    handleChangeArea(event) {
        let newArea = { ...this.state.editingArea }
        if (event.target.name === 'areaName') {
            newArea['areaName'] = event.target.value
        }

        this.setState({
            editingArea: newArea
        })
    }

    onhandleOpenConfirm() {
        const { selectedArea } = this.state
        const { editInstallation, onhandleDeleteInstallationArea, onhandleGetAreas, onhandleOpenConfirmDialog } = this.props

        onhandleOpenConfirmDialog(
            '删除区域确认对话框',
            '你确定要删除【' + selectedArea.areaName + '】吗?',
            async () => {
                await onhandleDeleteInstallationArea({
                    areaId: selectedArea.areaId,
                    areaName: selectedArea.areaName,
                    installId: editInstallation.installId
                })
                await onhandleGetAreas(editInstallation.installId)
                this.setState({
                    selectedArea: {}
                })
            },
            null,
        )
    }

    handleSelectArea(event, areaId) {
        const { editAreas, onhandleGetAreaDevices } = this.props
        let selectedArea = {}
        if (areaId) {
            let area = _.find(editAreas, { 'areaId': areaId })
            selectedArea = _.clone(area)
        } else {
            return
        }
        let size = this.state.search.size
        this.setState({
            selectedArea: selectedArea,
            search: {
                page: 1,
                size: size,
            }
        })
        onhandleGetAreaDevices(selectedArea.installId, selectedArea.areaId, 0, size)
    }

    handlePageChange(page, size) {

        const { selectedArea } = this.state
        const { onhandleGetAreaDevices } = this.props
        this.setState({
            search: {
                page: page,
                size: size,
            }
        })
        onhandleGetAreaDevices(selectedArea.installId, selectedArea.areaId, (page - 1) * size, size)
    }

    handleSelectDeviceKeys(keys) {
        this.setState({
            selectedDeviceKeys: keys
        })
    }

    handleOpenDeviceSearch() {
        const { selectedArea, search, } = this.state

        const { currentUserInfo,
            editInstallation,
            onhandleSelectDeviceCheck,
            onhandleConnectDevice2Area,
            onhandleGetAreaDevices,
        } = this.props

        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            install: true,
            installId: editInstallation.installId,
            company: true,
            companyId: currentUserInfo.companyId,
            callback: (selectedDevices) => {
                return new Promise((resolve, reject) => {
                    if (selectedDevices.length === 0) {
                        onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let deviceAreas = []
                        for (let device of selectedDevices) {
                            deviceAreas.push({
                                deviceId: device.deviceId,
                                areaId: selectedArea.areaId,
                                installId: selectedArea.installId,
                            })
                        }
                        onhandleConnectDevice2Area(deviceAreas).then(
                            async () => {
                                await onhandleGetAreaDevices(selectedArea.installId,
                                    selectedArea.areaId,
                                    (search.page - 1) * search.size,
                                    search.size)
                                this.setState({
                                    selectedDeviceKeys: []
                                })
                            }
                        )
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);

    }

    async handleDisconnectDevice2Area() {

        const { selectedDeviceKeys, selectedArea, search } = this.state
        const { onhandleDisconnectDevice2Area, onhandleGetAreaDevices, onhandleOpenConfirmDialog } = this.props

        onhandleOpenConfirmDialog(
            '确认对话框',
            '你确定要删除这【' + selectedDeviceKeys.length + '】项设备与区域【' + selectedArea.areaName + '】吗?',
            async () => {

                let deviceAreas = []
                for (let deviceId of selectedDeviceKeys) {
                    deviceAreas.push({
                        deviceId: deviceId,
                        areaId: selectedArea.areaId
                    })
                }

                await onhandleDisconnectDevice2Area(deviceAreas)
                await onhandleGetAreaDevices(selectedArea.installId,
                    selectedArea.areaId,
                    (search.page - 1) * search.size,
                    search.size)
                this.setState({
                    selectedDeviceKeys: []
                })
            },
            null,
        )
    }

    render() {
        console.log(this.props.editInstallation)
        console.log(this.props.editAreas)
        console.log(this.state.selectedArea)

        return (
            <React.Fragment>
                <AreaEditComponent
                    editInstallation={this.props.editInstallation}
                    editAreas={this.props.editAreas}
                    editAreaDevices={this.props.editAreaDevices}
                    selectedArea={this.state.selectedArea}
                    selectedDeviceKeys={this.state.selectedDeviceKeys}
                    companies={this.props.companies}
                    search={this.state.search}
                    departments={this.props.departments}
                    onBackToList={this.backToList.bind(this)}
                    onhandleSelectArea={this.handleSelectArea.bind(this)}
                    onhandleOpenEditAreaDailog={this.handleOpenEditAreaDailog.bind(this)}
                    onhandleDeleteArea={this.onhandleOpenConfirm.bind(this)}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                    onhandleSelectDeviceKeys={this.handleSelectDeviceKeys.bind(this)}
                    onhandleDisconnectDevice2Area={this.handleDisconnectDevice2Area.bind(this)}
                    onhandleOpenDeviceSearch={this.handleOpenDeviceSearch.bind(this)}
                />
                <AreaInfoEditPannel
                    area={this.state.editingArea}
                    open={this.state.openAreaEdit}
                    onhandleClose={this.handleCloseEditAreaDailog.bind(this)}
                    onhandleConfirm={this.handleSaveArea.bind(this)}
                    onhandleChangeArea={this.handleChangeArea.bind(this)}
                />
            </React.Fragment>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        editInstallation: state.installation.editInstallation,
        editAreas: state.installation.editAreas,
        editAreaDevices: state.devices.areaDeviceList,
        companies: state.constant.companies,
        departments: state.constant.departments,
        currentUserInfo: state.auth.currentUserInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateInstallationArea: (areaDto) => {
            return dispatch(updInstallationArea(areaDto))
        },
        onhandleDeleteInstallationArea: (areaDto) => {
            return dispatch(updInstallationArea({
                ...areaDto,
                status: 'inactive'
            }))
        },
        onhandleCreateInstallationArea: (areaDto) => {
            return dispatch(createInstallationArea(areaDto))
        },
        onhandleGetAreas: (installId) => {
            return dispatch(getInstallationAreas(installId))
        },
        onhandleBackToList: () => {
            return dispatch(routeToInstallationList());
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            return dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
        onhandleGetAreaDevices: (installId, areaId, offset, size) => {
            return dispatch(getDevicesbyArea(installId, areaId, offset, size));
        },
        onhandleConnectDevice2Area: (deviceAreas) => {
            return dispatch(connectDevice2Area(deviceAreas));
        },
        onhandleDisconnectDevice2Area: (deviceAreas) => {
            return dispatch(disconnectDevice2Area(deviceAreas));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaEditContainer)
