import { connect } from 'react-redux';
import { FillDeviceInfo } from '../components/DialogCommon/FillDeviceInfo';
import { createDeviceMap, createUnitMap, createAreaMap } from '../redux/modules/installationRelation';
import { cleanDialog } from '../redux/modules/dialog';
import { addExistDeviceToMap } from '../redux/modules/installationRelation';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.installation,
        ...state.installationPlug,
        ...state.devices,
        ...state.trees
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanDialog: () => {
            dispath(cleanDialog());
        },
        createDeviceMap: (value) => {
            dispath(createDeviceMap(value));
        },
        createUnitMap: (value) => {
            dispath(createUnitMap(value));
        },
        createAreaMap: (value) => {
            dispath(createAreaMap(value));
        },
        addExistDeviceToMap: (value) => {
            dispath(addExistDeviceToMap(value))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(FillDeviceInfo);

