import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import NoticeConfigList from "../../../components/SystemManagement/NoticeManagement";
import PropTypes from "prop-types";
import {
    getConfigList, getConfigById, createConfig, updateConfig, setConfig, setConfigState, setEditDialogOpen
} from '../../../redux/modules/notice';
import * as _ from 'lodash';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';

class NoticeConfigListContainer extends Component {

    static propTypes = {
        configList: PropTypes.array,
        isloading: PropTypes.bool,
        configState: PropTypes.object
    };

    componentDidMount() {
        this.handleGetConfigList();
    }

    handleGetConfigList() {
        this.props.onHandleGetConfigList(this.props.configState)
    }

    handleChange(event, field) {
        const { configState, onHandleSetState } = this.props
        let newState
        if (field === 'noticeName') {
            newState = { ...configState, name: event.target.value }
            onHandleSetState(newState)
        } else if (field === 'noticeCode') {
            newState = { ...configState, code: event.target.value }
            onHandleSetState(newState)
        } else if (field === 'noticeChannel') {
            newState = { ...configState, channel: event.target.value }
            onHandleSetState(newState)
        }
    }

    handleEditChange(event, field) {
        const { noticeConfig, onHandleSetCurrentConfig } = this.props
        let newData
        if (field === 'noticeName') {
            newData = { ...noticeConfig, noticeName: _.trim(event.target.value) }
            onHandleSetCurrentConfig(newData)
        } else if (field === 'noticeCode') {
            newData = { ...noticeConfig, noticeCode: _.trim(event.target.value) }
            onHandleSetCurrentConfig(newData)
        } else if (field === 'noticeChannel') {
            newData = { ...noticeConfig, noticeChannel: event.target.value }
            onHandleSetCurrentConfig(newData)
        } else if (field === 'noticeDesc') {
            newData = { ...noticeConfig, noticeDesc: _.trim(event.target.value) }
            onHandleSetCurrentConfig(newData)
        }
    }

    handleClearSearch() {
        let newState = {
            ...this.props.configState,
            name: '',
            code: '',
            channel: 0
        }
        this.props.onHandleSetState(newState)
    }

    handlePageSizeChange(pageSize, current) {
        console.log(pageSize)
        console.log(current)
    }

    handlePageChange(current) {
        console.log(current)
    }

    handleCreateConfig() {
        // open confifg dialog
        this.props.onHandleSetCurrentConfig({
            noticeId: 0,
            noticeName: '',
            noticeCode: '',
            noticeChannel: 0,
            noticeDesc: ''
        })
        this.props.onHandleSetDialogOpen(true)
    }

    handleUpdateConfig(data, type) {
        if (type === 'delete') {
            data.status = 'inactive'
            this.props.onHandleSetCurrentConfig(data)
            this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除本通知吗?', () => {
                this.props.onhandleUpdate(this.props.noticeConfig.noticeId, this.props.noticeConfig).then(() => {
                    this.props.onHandleGetConfigList(this.props.configState)
                });
            })
        } else if (type === 'update') {
            this.props.onHandleSetCurrentConfig(data)
            this.props.onHandleSetDialogOpen(true)
        } else if (type === 'config') {
            this.props.onHandleUpdateTarget(data)
        }

    }

    handleCloseDialog() {
        this.props.onHandleSetDialogOpen(false)
    }

    handleSaveConfig() {
        if (this.props.noticeConfig.noticeId === 0) {
            //新增
            this.props.onhandleCreate(this.props.noticeConfig).then(() => {
                this.props.onHandleSetDialogOpen(false)
                this.props.onHandleGetConfigList(this.props.configState)
            });
        } else {
            //修改
            this.props.onhandleUpdate(this.props.noticeConfig.noticeId, this.props.noticeConfig).then(() => {
                this.props.onHandleSetDialogOpen(false)
                this.props.onHandleGetConfigList(this.props.configState)
            });
        }
    }

    render() {
        return (
            <NoticeConfigList
                configState={this.props.configState}
                configList={this.props.noticeConfigList}
                currentConfig={this.props.noticeConfig}
                isloading={this.props.isloading}
                editDialogOpen={this.props.editDialogOpen}
                onHandleGetConfigList={this.handleGetConfigList.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandlePageSizeChange={this.handlePageSizeChange.bind(this)}
                onhandlePageChange={this.handlePageChange.bind(this)}
                onhandleCreateConfig={this.handleCreateConfig.bind(this)}
                onhandleEditConfig={this.handleUpdateConfig.bind(this)}
                onCancle={this.handleCloseDialog.bind(this)}
                onhandleEditChange={this.handleEditChange.bind(this)}
                onSaveConfig={this.handleSaveConfig.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        editDialogOpen: state.notice.editDialogOpen,
        configState: state.notice.configState,
        noticeConfig: state.notice.noticeConfig,
        noticeConfigList: state.notice.noticeConfigList,
        isloading: state.notice.isloading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onHandleGetConfigList: (query) => {
            return dispatch(getConfigList(query))
        },
        onHandleSetState: (data) => {
            dispatch(setConfigState(data))
        },
        onHandleSetDialogOpen: (data) => {
            dispatch(setEditDialogOpen(data))
        },
        onHandleSetCurrentConfig: (data) => {
            return dispatch(setConfig(data))
        },
        onhandleCreate: (data) => {
            return dispatch(createConfig(data))
        },
        onhandleUpdate: (id, data) => {
            return dispatch(updateConfig(id, data))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
        onHandleUpdateTarget: (data) => {
            dispatch(getConfigById(data.noticeId)).then(() => {
                dispatch(push('/noticeManagement/target'))
            })
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoticeConfigListContainer)