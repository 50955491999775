import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ProcessLine from '../../../ProcessLine';
import Divider from '@material-ui/core/Divider';
import { getMoment as moment } from '@/utils/datetime';
import * as _ from 'lodash';

const OppoInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        currentOppo,
        oppoChange,
        users,
        customerList,
        statusList,
        onDownLoadDoc,
        onhandleDeleteDoc,
        isEditable,
    } = props;
        
    const minStartDate = moment()().startOf('year');

    const minEndDate = currentOppo.oppoTime ? moment()(currentOppo.oppoTime).endOf('day') : minStartDate.endOf('day');

    const getStatuCodes = () => {
        const result =  statusList.map(function(status){
            return status.code;
        })

        //console.log('getStatuCodes', result)
        return result;
        
    }       

    const getStatuCode = (seq) =>{
        const result = statusList.filter(function(status){
            return status.seq === _.toInteger(seq);
        });

        return result[0].code;
    }

    const getConstants = () =>{
        let processStatus = [];
        statusList.forEach((item,index)=>{
            let map = {};
            map.name = item.name;
            map.code = item.code;
            processStatus.push(map);
        })
        console.log('processStatus', processStatus)
        let result = {"processStatus" : processStatus};
        return result;
    }

    const getUserName = (userId) => {
        let user = users.find(ele => ele.userId === userId);
        if (user) {
            return user.lastName + user.firstName
        }
        return ''
    }

    
    return (
        <div>
                <Grid container >
                    <Grid item className={classes.processline}>
                        <ProcessLine
                            statusCodes={getStatuCodes()}
                            statusCode={getStatuCode(currentOppo.status)}
                            constant={getConstants()}
                        />
                    </Grid>
                </Grid> 
               
            <Card className={classes.card}>
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Typography variant="h3" gutterBottom>
                        {currentOppo.oid ? "机会详情" : "新建客户机会"}
                    </Typography>
                </Grid>
                
                <Grid item xs={9}></Grid>

                <Grid item xs={2} algin="right">
                    <Button variant="contained" color="primary" size="small" style={{margin:2}}
                        onClick={event => onhandleSave(isEditable)}
                    >
                        {isEditable ? "保存" : "编辑"}
                </Button>
                {currentOppo.oid ? (
                    <Button variant="contained" color="primary" size="small" style={{margin:2}}>
                            通过
                    </Button>
                 ): null}
                 {currentOppo.oid ? (
                    <Button variant="contained" color="primary" size="small" style={{margin:2}}>
                            驳回
                    </Button>
                 ): null}
                    <Button variant="contained" color="primary" size="small" style={{margin:2}}
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                </Button>
                </Grid>

            </Grid>
            <Prompt message="数据未保存,确定要离开？"  when={oppoChange}/>

               <Divider  />
               
                {currentOppo.oid ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                            机会编号
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {currentOppo.oid}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        机会名称
                            </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={currentOppo.oppoName || ''}
                            variant="standard"  disabled={!isEditable} 
                            onChange={event => onhandleChange(event, 'oppoName')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                       
                    </Grid>

                 {currentOppo.oid ? (
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        客户活动
                            </Typography>
                    </Grid>
                    ) : null}
                     {currentOppo.oid ? (
                    <Grid item xs={2}>
                    <Typography  gutterBottom>
                            <Link href="#"  >
                                客户活动1
                            </Link>
                        </Typography>
                    </Grid>) : null}
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                    <Typography gutterBottom>
                      客户
                    </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    options={customerList}
                                    getOptionSelected={(option, value) => {
                                        return option.cid === value.cid
                                    }}
                                    onChange={(event, newValue) => onhandleChange(newValue, 'cid')}
                                    getOptionLabel={(option) => { return option.cstName || '' }}
                                    style={{
                                        minWidth: 120,
                                    }}
                                    disabled={!isEditable}
                                    value={_.find(customerList, { 'cid': currentOppo.cid }) || null}
                                    renderInput={(params) => <TextField {...params} placeholder="输入客户名查询" variant="standard" />}
                                />
                  </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        机会来源
                            </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={currentOppo.source || ''}
                            variant="standard"
                            disabled={!isEditable}
                            onChange={event => onhandleChange(event, 'source')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            可能性
                        </Typography>
                </Grid>
                <Grid item xs={1}>
                        <TextField
                            value={currentOppo.possibility || ''}
                            type="number"
                            disabled={!isEditable}
                            onChange={value => onhandleChange(value, 'possibility')}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}>
                        </TextField>
                </Grid>
                <Grid item xs={1}>
                        <Typography color='textPrimary' >
                            %
                        </Typography>
                </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            预期金额
                        </Typography>
                </Grid>
                <Grid item xs={1}>
                        <TextField
                            value={currentOppo.expectedAmount || ''}
                            type="number"
                            disabled={!isEditable}
                            onChange={value => onhandleChange(value, 'expectedAmount')}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}>
                        </TextField>
                </Grid>
                <Grid item xs={1}>
                        <Typography color='textPrimary' >
                            元
                        </Typography>
                </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        目标产品
                            </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={currentOppo.product || ''}
                            variant="standard"
                            disabled={!isEditable}
                            onChange={event => onhandleChange(event, 'product')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                      <Typography gutterBottom>
                        {'发现时间'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container wrap={'nowrap'}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN}>
                        <KeyboardDatePicker
                          className={classes.dateField}
                          InputProps={{ readOnly: true }}
                          disableToolbar
                          autoOk
                          variant='inline'
                          format='yyyy/MM/dd'
                          margin="none"
                          disabled={!isEditable}
                          value={currentOppo.oppoTime || null}
                          onChange={date => onhandleChange(date, 'oppoTime')}
                          KeyboardButtonProps={{
                            'aria-label': '修改时间',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                      <Typography gutterBottom>
                        {'过期时间'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container wrap={'nowrap'}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN}>
                        <KeyboardDatePicker
                          className={classes.dateField}
                          InputProps={{ readOnly: true }}
                          disableToolbar
                          autoOk
                          variant='inline'
                          format='yyyy/MM/dd'
                          margin="none"
                          disabled={!isEditable}
                          value={currentOppo.expiredDate || null}
                          minDate={minEndDate}
                          minDateMessage={<Typography>不能早于发现时间</Typography>}
                          onChange={date => onhandleChange(date, 'expiredDate')}
                          KeyboardButtonProps={{
                            'aria-label': '修改时间',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                    <Typography gutterBottom>
                      发现人
                    </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                        id="combo-box-developer"
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    options={users}
                                    getOptionSelected={(option, value) => {
                                        return option.userId === value.userId
                                    }}
                                    getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                    style={{
                                        minWidth: 120,
                                    }}
                                    noOptionsText='无可选人员'
                                    disabled={!isEditable}
                                    onChange={(event, newValue) => onhandleChange(newValue, 'developer')}
                                    //onClose={(event, reason) => onhandleNameClose(event, reason)}
                                    //onChange={(event, value) => onhandleCustomerChange(event, value)}
                                    value={_.find(users, { 'userId': currentOppo.developer }) || null}
                                    renderInput={(params) => <TextField {...params} placeholder="输入用户名查询" variant="standard" />}
                                />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        机会备注
                            </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentOppo.opentext || ''}
                            disabled={!isEditable}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                 </Grid>

                 { (isEditable && currentOppo.oid) ? ( 
                 <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={2}>
                        <Typography  gutterBottom>
                        活动相关文档
                            </Typography>
                    </Grid>
                  
                   {/* <Grid item xs={10}>
                        <Button variant="contained" color="primary"
                        disabled={!isEditable} size="small" 
                        onClick={event => onOpenUploadModal(event)}
                         >
                        上传
                         </Button>
                    </Grid> */}
                 </Grid>
                 ) : null }

                 
                 {currentOppo.customDocs ? currentOppo.customDocs.map((doc) => (
                    <Grid container spacing={1} className={classes.gridContainer} key={doc.docId}>
                        <Grid item xs={2}>
                        <Typography  gutterBottom>
                            <Link href="#" onClick={event => onDownLoadDoc(doc.docLink)} >
                                {doc.docName || ''}
                            </Link>
                        </Typography>
                        </Grid>

                    
                        <Grid item xs={2}>
                            <Typography  gutterBottom>
                                {getDateByTimestamp(doc.createTime,true) || ''}
                            </Typography>
                        </Grid>

                        { (isEditable && currentOppo.oid) ? ( <Grid item xs={8}>
                        <Button variant="contained" color="primary"
                        disabled={!isEditable} size="small"
                        onClick={event => onhandleDeleteDoc(doc)}
                         >
                            删除
                         </Button>
                        </Grid>
                        ) : null}
                    </Grid>
                 )) : null}
                 

                {currentOppo.oid ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {getDateByTimestamp(currentOppo.createTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentOppo.oid ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                            {getUserName(currentOppo.creator)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentOppo.oid && currentOppo.updateTime ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                更新时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {getDateByTimestamp(currentOppo.updateTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentOppo.oid && currentOppo.revisor ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                更新人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                            {getUserName(currentOppo.revisor)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

               

            </Card>

        </div>
    );
};

OppoInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    currentOppo: PropTypes.object.isRequired,
    oppoChange: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired
};

export default withStyles(OppoInfo);
