import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    MenuItem
} from '@material-ui/core';
import withStyles from './styles';
import { getMoment } from '../../../../utils/datetime'
import _ from 'lodash'


const SchedulePanel = ({
    classes,
    currentPlan,
    open,
    leaders,
    departmentList,
    routeList,
    onhandleChange,
    onhandleClose,
    onhandlePanelSave,
    onhandlePanelDelete,
    userProfile,
    planError,
    onhandleCheck
}) => {


    const renderTime = (startTime) => {
        return startTime ? getMoment()(startTime).format("YYYY-MM-DDTHH:mm") : ''
    }

    const renderDpt = (currentPlan) => {
        let dptIds = ''
        if (currentPlan.onsitePlanDpt && currentPlan.onsitePlanDpt.length) {
            dptIds = currentPlan.onsitePlanDpt[0].dptId;
        }
        let dptId = currentPlan.dptId ? currentPlan.dptId : dptIds
        let label = '';
        let value = _.find(departmentList, function (o) { return o.dptId === dptId })
        if (value) {
            label = value.dptName
        }

        return label;
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_schedule' })
        return permission && permission.action === 'W'
    }

    const validateAction = (currentPlan) => {
        if (currentPlan.planId) {
            let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
            let tomorrowTime = new Date(tomorrow).getTime();
            let startTime = new Date(currentPlan.startTime).getTime()

            if (currentPlan.ruleId === null && tomorrowTime < startTime && currentPlan.status === 'new') {
                return false
            }

            return true
        } else {
            return false
        }
    }

    return (
        <div alignitems="center" >
            {
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open}
                    onClose={onhandleClose}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                >
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>管理巡检排班</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.inspector !== ''}
                                    helperText={planError.inspector || ''}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输选择管理人员"
                                    label="管理人员"
                                    select
                                    disabled={!checkPermission()}
                                    value={currentPlan.inspector ? currentPlan.inspector : ''}
                                    name='inspector'
                                    onChange={event => onhandleChange(event, 'inspector')}
                                    onBlur={event => onhandleCheck(event, 'inspector')}>
                                    {leaders.map((item) => {
                                        return <MenuItem key={item.userId} value={item.userId}>
                                            {item.lastName + item.firstName}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.dptId !== ''}
                                    helperText={planError.dptId || ''}
                                    fullWidth
                                    variant="outlined"
                                    label="所属部门"
                                    name='dptId'
                                    disabled={!checkPermission()}
                                    value={renderDpt(currentPlan)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.routeId !== ''}
                                    helperText={planError.routeId || ''}
                                    fullWidth
                                    select
                                    disabled={!checkPermission()}
                                    variant="outlined"
                                    placeholder="请输选择巡检路线"
                                    label="巡检路线"
                                    name='routeId'
                                    value={currentPlan.routeId}
                                    onChange={event => onhandleChange(event, 'routeId')}
                                    onBlur={event => onhandleCheck(event, 'routeId')}>
                                    {routeList.map((item) => {
                                        return <MenuItem key={item.routeId} value={item.routeId}>
                                            {item.routeName}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.startTime !== ''}
                                    helperText={planError.startTime || ''}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输选择开始时间"
                                    label="开始时间"
                                    type="datetime-local"
                                    disabled={!checkPermission()}
                                    value={currentPlan.startTime ? renderTime(currentPlan.startTime) : ''}
                                    name='startTime'
                                    onChange={event => onhandleChange(event, 'startTime')}
                                    onBlur={event => onhandleCheck(event, 'startTime')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" hidden={!checkPermission() || !currentPlan.planId || validateAction(currentPlan)} color="primary" onClick={event => onhandlePanelDelete(currentPlan)}>
                            删除</Button>
                        <Button variant="text" color="primary" onClick={event => onhandleClose()}>
                            关闭</Button>
                        <Button variant="contained" hidden={!checkPermission() || validateAction(currentPlan)} color="primary" onClick={event => onhandlePanelSave(currentPlan)}>
                            保存</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

SchedulePanel.propTypes = {
    classes: PropTypes.object.isRequired,
    currentPlan: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    leaders: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    routeList: PropTypes.array.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandlePanelSave: PropTypes.func.isRequired,
    onhandlePanelDelete: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    planError: PropTypes.object.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
};

export default withStyles(SchedulePanel);