import Konva from 'konva';
import * as _ from 'lodash';

export function init(props, bx, by, id, _getDevice) {
    const state = {
        color: "black",
        width: 60,
        height: 100,
        offsetLeft: 10,
        offsetRight: 10,
        strokeWidth: 2
    };
    // const thisDevice = props.deviceStore[props.deviceStore.length - 1];
    const thisDevice = _.find(props.deviceStore, { 'id': id });
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: thisDevice.id
    });

    const background = new Konva.Rect({
        x: 0,
        y: 0,
        width: state.width,
        height: state.height
        // ,fill:'red'
    });
    group.add(background);
    // 上弧线
    const topArc = new Konva.Line({
        points: [10, 10, 30, 0, 50, 10],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        tension: 0.9
    })
    group.add(topArc);
    // 下弧线
    const bottomArc = new Konva.Line({
        points: [10, 70, 30, 80, 50, 70],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        tension: 0.9
    })
    group.add(bottomArc);

    // 左边竖线
    const leftLine = new Konva.Line({
        points: [10, 10, 10, 70],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(leftLine);

    // 右边竖线
    const rightLine = new Konva.Line({
        points: [50, 10, 50, 70],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(rightLine);

    // 底部左斜线
    const leftBottomSlash = new Konva.Line({
        points: [8, 100, 20, 80],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(leftBottomSlash);

    // 底部右斜线
    const rightBottomSlash = new Konva.Line({
        points: [52, 100, 40, 80],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(rightBottomSlash);

    // 底部横线
    const bottomLine = new Konva.Line({
        points: [0, 100, 60, 100],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(bottomLine);

    // 上圆
    const upCircle = new Konva.Circle({
        x: 30,
        y: 25,
        width: 20,
        height: 20,
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(upCircle);

    // 下圆
    const downCircle = new Konva.Circle({
        x: 30,
        y: 60,
        width: 20,
        height: 20,
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(downCircle);


    const text = new Konva.Text({
        text: thisDevice.name,
        x: 20,
        y: 110,
        fill: state.color
    })
    group.add(text);

    group.on('click', function (e) {
        let { linkDevice, selectedLink, deviceStore } = _getDevice();
        console.log('beng start')
        console.log(selectedLink)
        console.log('beng end')
        if (selectedLink) {
            let pos = this.getAbsolutePosition(); // 当前设备的绝对位置
            let layer = this.getParent();
            if (selectedLink.beginX > pos.x + state.width) {
                console.log("连接起点位于当前设备的右边");
                let startDevice = layer.findOne('#' + linkDevice);
                let scaleLine = startDevice.findOne('.scale_line');
                let startPos = startDevice.getAbsolutePosition();
                let points = scaleLine.points();
                if (selectedLink.type === 'input') {
                    points = [pos.x + state.width - state.offsetRight - startPos.x, e.evt.offsetY - startPos.y, points[0], e.evt.offsetY - startPos.y, ...points];
                    // 添加链接点信息
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['input'] = deviceStore[i]['input'] ? deviceStore[i]['input'] : {};
                            deviceStore[i]['input'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                } else {
                    points = [...points, points[points.length - 2], e.evt.offsetY - startPos.y, pos.x + state.width - state.offsetRight - startPos.x, e.evt.offsetY - startPos.y];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['output'] = deviceStore[i]['output'] ? deviceStore[i]['output'] : {};
                            deviceStore[i]['output'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                }
                // console.log(points);
                scaleLine.points(points);
            } else if (selectedLink.beginX < pos.x) {
                console.log("连接起点位于当前设备的左边");
                let startDevice = layer.findOne('#' + linkDevice);
                let scaleLine = startDevice.findOne('.scale_line');
                let startPos = startDevice.getAbsolutePosition();
                let points = scaleLine.points();
                console.log(selectedLink.type)
                if (selectedLink.type === 'input') {
                    points = [pos.x + state.offsetLeft - startPos.x, e.evt.offsetY - startPos.y, points[0], e.evt.offsetY - startPos.y, ...points];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['input'] = deviceStore[i]['input'] ? deviceStore[i]['input'] : {};
                            deviceStore[i]['input'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                } else {
                    points = [...points, points[points.length - 2], e.evt.offsetY - startPos.y, pos.x + state.offsetLeft - startPos.x, e.evt.offsetY - startPos.y];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['output'] = deviceStore[i]['output'] ? deviceStore[i]['output'] : {};
                            deviceStore[i]['output'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                }
                console.log(points);
                scaleLine.points(points);
            } else if (selectedLink.beginX >= pos.x
                && selectedLink.beginX <= pos.x + state.width
                && selectedLink.beginY < pos.y) {
                console.log("连接起点位于当前设备的上边");
                let startDevice = layer.findOne('#' + linkDevice);
                let scaleLine = startDevice.findOne('.scale_line');
                let startPos = startDevice.getAbsolutePosition();
                let points = scaleLine.points();
                if (selectedLink.type === 'input') {
                    points = [pos.x + state.width / 2 - startPos.x, pos.y - startPos.y, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y, ...points];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['input'] = deviceStore[i]['input'] ? deviceStore[i]['input'] : {};
                            deviceStore[i]['input'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                } else {
                    points = [...points, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['output'] = deviceStore[i]['output'] ? deviceStore[i]['output'] : {};
                            deviceStore[i]['output'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                }
                // console.log(points);
                scaleLine.points(points);
            } else if (selectedLink.beginX >= pos.x
                && selectedLink.beginX <= pos.x + state.width
                && selectedLink.beginY > pos.y + state.height) {
                console.log("连接起点位于当前设备的下边");
                let startDevice = layer.findOne('#' + linkDevice);
                let scaleLine = startDevice.findOne('.scale_line');
                let startPos = startDevice.getAbsolutePosition();
                let points = scaleLine.points();
                if (selectedLink.type === 'input') {
                    points = [pos.x + state.width / 2 - startPos.x, pos.y - startPos.y + state.height, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y + state.height, ...points];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['input'] = deviceStore[i]['input'] ? deviceStore[i]['input'] : {};
                            deviceStore[i]['input'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                } else {
                    points = [...points, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y + state.height, pos.x + state.width / 2 - startPos.x, pos.y - startPos.y + state.height];
                    for (let i = 0; i < deviceStore.length; i++) {
                        // console.log(deviceStore[i].id);
                        if (deviceStore[i].id === linkDevice) {
                            deviceStore[i]['output'] = deviceStore[i]['output'] ? deviceStore[i]['output'] : {};
                            deviceStore[i]['output'][selectedLink.id] = this.id();
                            break;
                        }
                    }
                }
                // console.log(points);
                scaleLine.points(points);
            } else {
                alert("连接起点不应该位于当前设备内部");
            }
            let { selectLink, selectLinkDevice } = props;
            selectLink(null);
            selectLinkDevice(null);
            layer.find('.output').forEach(element => {
                element.hide();
            });
            layer.find('.input').forEach(element => {
                element.hide();
            });
            layer.draw();
            // console.log(selectedLink);
            // console.log(linkDevice);
            // console.log(e);
            // console.log("x: " + e.evt.offsetX + " y:" + e.evt.offsetY);
            // console.log(this.getAbsolutePosition());
        }
    })


    group.on('mouseout', function () {
        document.body.style.cursor = 'default';
        this.find('Line').forEach(element => {
            element.stroke('black');
        });
        this.find('Circle').forEach(element => {
            element.stroke('black');
        });

        this.draw();
    })

    group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
        this.find('Line').forEach(element => {
            element.stroke('red');
        });
        this.find('Circle').forEach(element => {
            element.stroke('red');
        });
        this.draw();
    })

    group.on('dragend', function () {
        let pos = this.getAbsolutePosition();
        thisDevice.beginX = pos.x;
        thisDevice.beginY = pos.y;
    })

    group.on('dragstart', function () {
        let { cleanContentMenu } = props;
        cleanContentMenu();
    })

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let pos = this.getAbsolutePosition(); // 当前设备的绝对位置
        let { createContentMenu } = props;
        createContentMenu(pos.y + 4, pos.x + 4, { id: this.id() });
    });

    group.on('transformend', function (e) {
        console.log('transform end');
        let { updateDevice } = props;
        updateDevice(e.target.attrs);
    });

    return group;
}

