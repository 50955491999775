import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    List,
    Typography,
} from '@material-ui/core';

import { getProcessStatusNamebyCode, findBuNameById } from "../../../../utils/constant";
import ProcessLine from '../../../ProcessLine'

import MaterialTable from 'material-table';
import { localization, options } from "../../../../utils/mtable";
import Block from '@material-ui/icons/Block';


import * as _ from 'lodash';

const GoalPlan = ({
    classes,
    goalPlan,
    constant,
    businessUnits,
    currentProcessStatus,
    goalDetails,
    companyName,
    onhandleUpdate,
    onhandleProcess,
}) => {

    const checkEditable = () => {
        // let record = getCurrentRecord()
        if (currentProcessStatus.statusCode !== 'Drafting' && currentProcessStatus.statusCode !== 'Rejected')
            return false
        return true
    }

    const getValFormat = (detail) => {
        if (!detail.vals)
            return ''
        const vals = JSON.parse(detail.vals)
        let str = detail.opentext
        for (let key in vals) {
            /* eslint-disable no-useless-escape */
            let re = new RegExp("\{" + key.replace('$', '\\$') + "\=[^\{]*\}", "gm")
            str = str.replace(re, vals[key])
        }
        return str
    }
    const getEditValFormat = (detail) => {
        if (!detail.vals)
            return ''
        const vals = JSON.parse(detail.vals)
        return vals.$target
    }


    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                width: 72,
                readonly: true,
                render: row => row.center && (<Typography>{findBuNameById(constant, row.unitId)}</Typography>),
                editable: 'never',
                cellStyle: (value, rowData) => {
                    if (rowData.unitDuplicated) {
                        return { border: ' 0px solid black', }
                    }
                    return {}
                },
            }, {
                title: '目标项',
                editable: 'never',
                field: 'tempName',
                width: 160,
                render: row => <Typography>{row.tempName}</Typography>
            }, {
                title: '目标值',
                field: 'edit_vals',
                render: row => <Typography>{getValFormat(row)}</Typography>,
                width: 112,
            }]

        //下级单位
        const distributedActions = _.filter(currentProcessStatus.actions, (action) => { return action.distributed })
        if (goalDetails && goalDetails.length > 0 && goalDetails[0].extras && goalDetails[0].extras.length > 0) {
            //下级单位目标值
            for (let extra of goalDetails[0].extras) {
                let col = {
                    title: extra.companyAlias,
                    width: 96,
                    editable: 'never',
                    field: 'vals_' + extra.companyId,
                    render: row => <Typography>{row['vals_' + extra.companyId]}</Typography>
                }
                columns.push(col)

                //下级单位行为
                for (const da of distributedActions) {
                    da.companyId = extra.companyId
                    const subRecord = _.find(da.subRecords, (sub) => {
                        return sub.companyId === extra.companyId
                            && sub.currentStage === sub.mergeStage
                    })
                    const buttonDisabled = subRecord ? false : true
                    let buttonColumn = {
                        title: '',
                        width: 104,
                        editable: 'never',
                        cellStyle: (value, rowData) => {
                            if (rowData.unitDuplicated) {
                                return { border: ' 0px solid black', }
                            }
                            return {}
                        },
                        render: row => _.find(businessUnits, function (o) { return o.unitId === row.unitId })
                            && row.center ? (
                                <Button key={da.actionId}
                                    aria-label={da.actionName}
                                    disabled={buttonDisabled}
                                    color="primary"
                                    variant="outlined"
                                    onClick={onhandleProcess.bind(this, {
                                        ...da,
                                        companyId: extra.companyId
                                    })}
                                    className={classes.button}>
                                    <Block />
                                </Button>

                                // < Button key={da.actionId}
                                //     disabled={buttonDisabled}
                                //     color="primary"
                                //     className={classes.buttonintable}
                                //     onClick={onhandleProcess.bind(this, da)}>
                                //     {da.actionName}
                                // </Button>
                            ) : (''),
                    }
                    columns.push(buttonColumn)
                }
            }
        }

        return columns
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        toolbar: false,
        // fixedColumns: {
        //     left: 4,
        //     right: 0
        // }
    }
    const tableLocalization = {
        ...localization,
    }

    let fixedStatusCodes = []
    let fixedStatusCode = currentProcessStatus.statusCode
    if (currentProcessStatus && currentProcessStatus.statusCodes) {
        for (let sc of currentProcessStatus.statusCodes) {
            if (sc === 'ToBeInvited' || sc === 'InTrial' || sc === 'ToBeSigned') {
                fixedStatusCodes.push('Trial')
            } else {
                fixedStatusCodes.push(sc)
            }
        }
    }

    fixedStatusCodes = _.uniq(fixedStatusCodes)

    if (fixedStatusCode === 'ToBeInvited' || fixedStatusCode === 'InTrial' || fixedStatusCode === 'ToBeSigned') {
        fixedStatusCode = 'Trial'
    }

    const assemblyData = () => {

        if (!goalDetails || goalDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let goalDetailsSort = _.sortBy(goalDetails, ['unitId', 'content'])


        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in goalDetailsSort) {
            let row = {
                ...goalDetailsSort[idx],
                num: goalDetailsSort.length - Number(idx),
                edit_vals: getEditValFormat(goalDetailsSort[idx])  //JSON中允许编辑的字段默认为$target
            }
            if (goalDetailsSort[idx].extras && goalDetailsSort[idx].extras.length > 0) {
                for (let gd of goalDetailsSort[idx].extras) {
                    row['vals_' + gd.companyId] = getValFormat(gd)
                }
            }

            if (preUnitId === goalDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = goalDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true

        return assemblyData.reverse()
    }

    const renderActions = () => {
        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0)
            return
        const actions = _.filter(currentProcessStatus.actions, (action) => { return !action.distributed })
        //const actions = currentProcessStatus.actions
        //console.log(actions)
        return actions.map((action, idx) => (
            <Button
                key={action.actionId}
                disabled={action.disabled || false}
                variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                color="primary"
                className={classes.button}
                onClick={onhandleProcess.bind(this, action)}
            >
                {action.actionName}
            </Button>
        ))
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid container className={classes.title}>
                {/* <Grid className={classes.goal}>
                    <PlanTooltip plan={goalPlan}>
                    </PlanTooltip>
                </Grid>
                <Grid item> <Divider orientation="vertical" className={classes.divider} /></Grid> */}
                <Grid item><Typography variant='h4'>{companyName}</Typography></Grid>
                <Grid item>
                    <Typography className={classes.status}>{getProcessStatusNamebyCode(constant, currentProcessStatus.statusCode)}</Typography>
                </Grid>
                <Grid item className={classes.flexgrow} ></Grid>
            </Grid>
            <Grid>
                <Grid container>
                    <Grid item className={classes.processLine}>
                        <ProcessLine
                            statusCodes={fixedStatusCodes}
                            statusCode={fixedStatusCode}
                            constant={constant}
                        />
                    </Grid>
                    <Grid item>
                        <List >
                            {renderActions()}
                        </List>
                    </Grid>
                </Grid>
                {currentProcessStatus.rejectComment && (<Grid className={classes.attention} >
                    {currentProcessStatus.rejectComment.split('\n').map((line, index) => (
                        <Typography key={index} className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
                    ))}
                </Grid>)}

                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        // components={{
                        //     Header: props => (
                        //         <MTableHeader {...props} />
                        //     ),
                        // }}
                        editable={!checkEditable() ? {} : {
                            isEditable: rowData => rowData.status !== 'Submitted'
                                && _.find(businessUnits, function (o) { return o.unitId === rowData.unitId }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    onhandleUpdate(newData)
                                    resolve()
                                }),
                        }}
                        localization={tableLocalization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                    >
                    </MaterialTable>
                </Grid>
                <Grid>
                </Grid>
            </Grid>

        </div>

    );
};
GoalPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    goalPlan: PropTypes.object,
    constant: PropTypes.object,
    businessUnits: PropTypes.array,
    currentProcessStatus: PropTypes.object,
    goalDetails: PropTypes.array,
    onhandleEdit: PropTypes.func,
    onhandleUpdate: PropTypes.func,
    onhandleProcess: PropTypes.func,
};

GoalPlan.defaultProps = {
    goalPlan: {},
    constant: {},
    businessUnits: [],
    currentProcessStatus: {},
    goalDetails: [],
}

export default withStyles(GoalPlan);