export default {
  root: {
    minWidth: 70,
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.9)"
    },
  }
};
