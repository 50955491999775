import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExceptionAcceptPannel from '../../../components/OnsiteInspectionManagement/ExceptionList/ExceptionAcceptPannel';
import { closeExceptionAcceptPannel, changeException } from '@/redux/modules/onsiteInspectionExceptionAccept';

import _ from 'lodash';

class ExceptionAcceptPannelContainer extends Component {
    static propTypes = {
        exception: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            exceptionError: {
                'handleResult': '',
                'acceptResult': '',
                'handleType': ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(this.props.exception)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, exception } = this.props
        let newEntity = {
            ...exception
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {

        const {
            exception
        } = this.props;

        let err = {};

        if (!field || field === 'handleType') {
            if (_.trim(exception.handleType) === '') {
                err['handleType'] = '请选择异常处置类型';
            } else {
                err['handleType'] = '';
            }
        }

        if (!field || field === 'handleResult') {
            if (_.trim(exception.handleResult) === '') {
                err['handleResult'] = '请填写处置说明';
            } else {
                err['handleResult'] = '';
            }
        }

        let newError = {
            ...this.state.exceptionError,
            ...err
        }
        this.setState({
            exceptionError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <ExceptionAcceptPannel
                open={this.props.open}
                exception={this.props.exception}
                exceptionError={this.state.exceptionError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.onsiteInspectionExceptionAccept.open,
        exception: state.onsiteInspectionExceptionAccept.exception,
        confirmCallback: state.onsiteInspectionExceptionAccept.confirmCallback,
        closeCallback: state.onsiteInspectionExceptionAccept.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (exception) => {
            dispatch(changeException(exception));
        },
        onhandleClose: () => {
            dispatch(closeExceptionAcceptPannel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExceptionAcceptPannelContainer);