import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(0),
        padding: 20
    },
    label: {
        marginTop: 7
    },
    gridContainer: {
        margin: 10
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    detailtable: {
        // padding: theme.spacing(4)
    },
    materialtable: {
        boxShadow: 'none'
    },
    textField: {
        width: '90%',
    },
    pending: {
        width: 60,
        backgroundColor: '#f4511e',
        color: theme.palette.white,
    },
    invalidRecord: {
        width: 60,
        backgroundColor: '#848484',
        color: theme.palette.white,
    }
});

export default withStyles(styles);