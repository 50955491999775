import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import { getTimeUnitByCode, getDateByTimestamp } from "../../../../../utils/datetime";
import { findBuNameById } from "../../../../../utils/constant";
import MaterialTable, { } from 'material-table';
import { localization, options, style } from "../../../../../utils/mtable"

import * as _ from 'lodash';

const TemporaryRegulationPlanExecution = ({
    classes,
    businessUnits,
    plan,
    currentPlanDetails,
    constant,
    onhandleUpdate,
    onhandleDelete,
    onhandleBack,
    onhandleOpenPlanDetailAddPannel
}) => {

    const tableOptions = {
        ...options,
        tableLayout: 'fixed',
        addRowPosition: 'first',
        showTitle: true,
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                render: row => row && <Typography>{findBuNameById(constant, row.unitId)}</Typography>,
                editable: 'never',
                cellStyle: (value, rowData) => {
                    if (rowData && rowData.unitDuplicated) {
                        return {
                            border: ' 0px solid black',
                        }
                    }
                    return {}
                },
                align: 'center',
            },
            {
                title: '公司规章制度',
                field: 'regName',
                render: row => row && <Typography>{row.regName}</Typography>,
                editable: 'onAdd',
                align: 'left'
            },
            {
                title: '修订周期',
                align: 'center',
                render: row => row && <Typography>{row ? row.revisionCycle + getTimeUnitByCode(row.revisionUnit) : ''}</Typography>,
                //render: (rowData) => { return rowData ? rowData.revisionCycle + getTimeUnitByCode(rowData.revisionUnit) : '' },
            },
            {
                title: '上次修订时间',
                field: 'updateTime',
                render: row => row && <Typography>{getDateByTimestamp(row.updateTime)}</Typography>,
                type: 'date',
                editable: 'never',
                align: 'left'
            },
            {
                title: '修改建议',
                field: 'content',
                render: row => row && <Typography>{row.content}</Typography>,
                align: 'left'
            },
            {
                width: '10%',
                title: '修订类型',
                field: 'planType',
                lookup: {
                    Add: '新增',
                    Update: '修改',
                    Delete: '废止'
                },
                render: row => row && <Typography>{row.planType}</Typography>,
                editable: 'onAdd',
                align: 'center'
            },
            {
                title: '计划完成时间',
                field: 'targetDate',
                render: row => row && <Typography>{getDateByTimestamp(row.targetDate)}</Typography>,
                type: 'date',
                align: 'left'
            },
        ]

        return columns
    }

    const assemblyData = () => {

        if (!currentPlanDetails || currentPlanDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(currentPlanDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }

    return (
        <div alignitems="center" className={classes.root}>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title={<Typography variant='h3' className={classes.tableTitle}>计划明细</Typography>}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={localization}
                        style={style}
                    >
                    </MaterialTable>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        完成
                </Button>
                </Grid>

            </Grid>
            </div>
        </div >

    );
};
TemporaryRegulationPlanExecution.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    regulations: PropTypes.array.isRequired,
    currentPlanDetails: PropTypes.array.isRequired
};

TemporaryRegulationPlanExecution.defaultProps = {
    constant: {},
    departments: [],
    businessUnits: [],
    currentProcessStatus: {},
}

export default withStyles(TemporaryRegulationPlanExecution);