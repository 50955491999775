import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    FormHelperText,
    Typography,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    Chip,
    Checkbox,
    Switch,
    Radio,
    RadioGroup,
    FormControlLabel,
    ListItemText,
    // Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inventoryValid } from '@/utils/inspection';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getMoment as moment, getDateByString } from '@/utils/datetime';
import * as _ from 'lodash';


const AddFiveSTempDetailPanel = ({
    classes,
    open,
    intervalTime,
    currentUserInfo,
    userProfile,
    frequencyDate,
    // checklistRole,
    // users,
    leaders,
    inventoryList,
    checkCompanyRelation,
    planDetailError,
    planDateError,
    planDetail,
    companyList,
    defaultDpt,
    departmentList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleInspectionHeadChange,
    onhandleDateChange,
    onhandleDptUserSelectChange,
    onhandleListMapping
}) => {

    // console.log('==== ==== ==== ==== ==== ==== ==== ====')
    // console.log(planDetail)
    // console.log(planDetailError)
    // console.log(planDateError)
    // console.log(userProfile);

    // 分厂人员
    const isBranchComp = Boolean(_.find(companyList, o => o.companyId === userProfile.roles[0].companyId).pid);

    const minStartDate = moment()().startOf('year');

    const minEndDate = planDetail.startTime ? moment()(planDetail.startTime).endOf('day') : minStartDate.endOf('day');

    const filterDptList = () => {
        let filtered = [];
        if (departmentList) filtered = departmentList;
        return filtered;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick maxWidth={'lg'}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"新建" + onhandleListMapping(planDetail.inspectType, 'inspectType') + "临时计划"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='检查表'
                                        error={planDetailError.inventoryId !== ''}
                                        helperText={planDetailError.inventoryId}
                                        select
                                        value={planDetail.inventoryId || ''}
                                        name='inventoryId'
                                        onChange={onhandleChange}>
                                        {
                                            inventoryList.map((ivt) => (
                                                <MenuItem dense key={ivt.inventoryId} value={ivt.inventoryId} disabled={!inventoryValid(ivt)}>
                                                    {ivt.inventoryName + (!inventoryValid(ivt) ? '(未生效)' : '')}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="受检单位"
                                        error={planDetailError.inspectionCompanyIdList !== ''}
                                        helperText={planDetailError.inspectionCompanyIdList}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue: () => (
                                                <div className={classes.chips}>
                                                    {
                                                        planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.map((value) =>
                                                            <Chip size={'small'} key={value} label={onhandleListMapping(value, 'company')} className={classes.chip} />
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                        value={planDetail.inspectionCompanyIdList ? planDetail.inspectionCompanyIdList : []}
                                        name='inspectionCompanyIdList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            companyList.map(
                                                company => (
                                                    <MenuItem dense key={company.companyId} value={company.companyId} disabled={isBranchComp && company.companyId !== currentUserInfo.companyId}>
                                                        <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.indexOf(company.companyId) > -1} />
                                                        <ListItemText primary={company.companyAlias} />
                                                    </MenuItem>)
                                            )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="检查部门"
                                        error={planDetailError.inspectionDptUserList !== ''}
                                        helperText={planDetailError.inspectionDptUserList}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue:
                                                () => (<div className={classes.chips}>
                                                    {
                                                        planDetail.inspectionDptUserList && planDetail.inspectionDptUserList.map(dpt =>
                                                            <Chip size={'small'} key={dpt.dptId} label={onhandleListMapping(dpt.dptId, 'departmentEdit')} className={classes.chip} />
                                                        )
                                                    }
                                                </div>),
                                        }}
                                        value={
                                            filterDptList().filter(dpt => (
                                                planDetail.inspectionDptUserList
                                                    .map(e => e.dptId)
                                                    .includes(dpt.dptId)
                                            )) || []
                                        }
                                        name='inspectionDptUserList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            filterDptList().filter(dpt => checkCompanyRelation(dpt))
                                                .sort((a, b) => b.companyPid ? -1 : 1)
                                                .map(dpt => (
                                                    <MenuItem dense key={dpt.dptId} value={dpt} disabled={defaultDpt.includes(dpt.dptId)}
                                                    >
                                                        <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionDptUserList && _.findIndex(planDetail.inspectionDptUserList, { 'dptId': dpt.dptId }) > -1} />
                                                        <ListItemText className={classes.menuText} primary={dpt.companyDptName} />
                                                    </MenuItem>)
                                                )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        multiline
                                        label={"排查内容"}
                                        variant='outlined'
                                        className={classes.textField}
                                        value={planDetail.opentext || ''}
                                        rows={6}
                                        name='opentext'
                                        onChange={event => onhandleChange(event, 'opentext')}
                                    // onBlur={event => onhandleCheck(event, 'opentext')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item className={classes.col}>
                            <Grid item className={classes.itemGrid}>
                                <Autocomplete
                                    className={classes.textField}
                                    options={leaders}
                                    onChange={(e, newValue) => onhandleInspectionHeadChange(e, newValue)}
                                    onClose={event => onhandleCheck(event, 'inspectHead')}
                                    getOptionLabel={(option) => (option.lastName + option.firstName || '')}
                                    getOptionSelected={(option, value) => (option.userId === value.userId)}
                                    noOptionsText='无可选人员'
                                    value={_.find(leaders, { 'userId': planDetail.inspectHead }) || null}
                                    renderOption={option => (
                                        <Grid container justify={'space-between'}>
                                            <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                            <Typography variant={'subtitle2'}>{option.roleName || ''}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => <TextField {...params}
                                        label='负责人'
                                        placeholder="搜索并选择负责人"
                                        variant="outlined"
                                        error={planDetailError.inspectHead !== ''}
                                        helperText={planDetailError.inspectHead}
                                    />}
                                />
                            </Grid>
                            <Grid container className={classes.contentGrid}>
                                {
                                    planDetail.inspectionDptUserList.map(dpt => (
                                        <Grid item key={dpt.dptId} className={classes.itemDptUserGrid}>
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                disableClearable
                                                filterSelectedOptions
                                                className={classes.textField}
                                                openText='展开选项'
                                                noOptionsText='无可选人员'
                                                options={leaders.filter(user => user.dptId === dpt.dptId)}
                                                onChange={(e, newValue, reason) => onhandleDptUserSelectChange(newValue, dpt.dptId, reason)}
                                                onClose={event => onhandleCheck(event, 'dptUser')}
                                                getOptionLabel={option => (option.lastName + option.firstName || '')}
                                                getOptionSelected={(option, value) => (option.userId === value.userId)}
                                                value={dpt.dptUser || []}
                                                renderOption={option => (
                                                    <Grid container justify={'space-between'}>
                                                        <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                                        <Typography variant={'subtitle2'}>{option.roleName || ''}</Typography>
                                                    </Grid>
                                                )}
                                                renderTags={(value, getTagProps) => (
                                                    value.map((option, index) => (
                                                        <Chip size={'small'}
                                                            label={onhandleListMapping(option.userId, 'user')}
                                                            {...getTagProps({ index })} />
                                                    ))
                                                )}
                                                renderInput={params =>
                                                    <TextField {...params} size={'small'} variant="outlined" placeholder={"选择检查人"}
                                                        label={`检查人 - ${dpt.companyDptName}`}
                                                        error={planDetailError.dptUser !== '' && (!dpt.dptUser || dpt.dptUser.length < 1)}
                                                    />}
                                            />
                                        </Grid>
                                    ))
                                }
                                {planDetailError.dptUser !== '' ?
                                    <Grid item className={classes.itemDptUserGrid}>
                                        <FormHelperText error={true} >
                                            {planDetailError.dptUser}
                                        </FormHelperText>
                                    </Grid> : null
                                }
                            </Grid>
                        </Grid>
                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemLabelGrid}>
                                    <FormControlLabel label={<Typography variant="h5" style={planDetail.frequencyDate ? { fontWeight: 600 } : { color: 'gray' }}>按频次创建排查</Typography>} control={
                                        <Switch size="small" checked={planDetail.frequencyDate} onChange={onhandleChange} name="frequencyDate" />
                                    } />
                                </Grid>

                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <RadioGroup row name="frequencyType"
                                        value={planDetail.frequencyType}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'day'} control={<Radio size={'small'} />} label="每日" />
                                        <FormControlLabel value={'workday'} control={<Radio size={'small'} />} label="每工作日" />
                                    </RadioGroup>
                                    <RadioGroup row name="frequencyType"
                                        value={planDetail.frequencyType}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'week'} control={<Radio size={'small'} />} label="每周" />
                                        <FormControlLabel value={'fortnight'} control={<Radio size={'small'} />} label="每2周" />
                                        <FormControlLabel value={'month'} control={<Radio size={'small'} />} label="每月" />
                                    </RadioGroup>
                                </Grid>}

                                {!frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <RadioGroup row aria-label="排查时间类型（单日/区间）" name="intervalTime"
                                        value={planDetail.intervalTime}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'false'} control={<Radio size={'small'} />} label="单日排查" />
                                        <FormControlLabel value={'true'} control={<Radio size={'small'} />} label="多日排查" />
                                    </RadioGroup>
                                </Grid>}

                                <Grid item container className={classes.itemDateGrid} wrap={'nowrap'}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={intervalTime ? classes.x : classes.textField}
                                            error={planDateError.startTime !== ''}
                                            helperText={planDateError.startTime}
                                            InputProps={{ readOnly: true }}
                                            views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                            openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                            disableToolbar={planDetail.frequencyType !== 'month'}
                                            disablePast
                                            autoOk
                                            variant='inline'
                                            inputVariant='outlined'
                                            minDate={minStartDate}
                                            minDateMessage={<Typography>不能早于当前日期</Typography>}
                                            format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                            name='startTime'
                                            label={intervalTime ? "开始日期" : "检查日期"}
                                            value={planDetail.startTime || null}
                                            onChange={date => onhandleDateChange(date, 'startTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {intervalTime &&
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            locale={zhCN}>
                                            <KeyboardDatePicker
                                                className={classes.textFieldInline}
                                                error={planDateError.inspectTime !== ''}
                                                helperText={planDateError.inspectTime}
                                                InputProps={{ readOnly: true }}
                                                views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                                openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                                disableToolbar={planDetail.frequencyType !== 'month'}
                                                disablePast
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                minDate={minEndDate}
                                                minDateMessage={<Typography>不能早于开始日期</Typography>}
                                                format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                                name='inspectTime'
                                                label={"结束日期"}
                                                value={planDetail.inspectTime || null}
                                                onChange={date => onhandleDateChange(date, 'inspectTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': '修改时间',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    }
                                </Grid>
                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    {Boolean(planDateError.inspectTime === '' && planDetail.planDates && planDetail.planDates.length > 0) &&
                                        <>
                                            <Typography display={'block'}>{'将创建以下【' + planDetail.planDates.length + '】种时间段的临时计划：'}</Typography>
                                            {
                                                planDetail.planDates.map((planDate, index) => {
                                                    return (
                                                        <Typography display={'block'} key={index}>
                                                            <span>{
                                                                '【' + (index + 1) + '】'
                                                                + getDateByString(planDate.startTime, true)
                                                                + (
                                                                    moment()(planDate.startTime).isSame(planDate.inspectTime, 'day') ? ''
                                                                        : ' ~ ' + getDateByString(planDate.inspectTime, true)
                                                                )
                                                            }</span>
                                                            {/* tip未调试完成 */}
                                                            {/* {
                                                                planDate.hasTip ?
                                                                    <Tooltip title="该时间段不满一个周期，请谨慎创建">< ErrorOutlineIcon color={'error'} fontSize={'small'} /></Tooltip>
                                                                    : null
                                                            } */}
                                                        </Typography>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </Grid>}
                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <Typography display={'block'}>
                                        <span className={classes.required}> *</span>
                                        {' 起止时间段如果不足每周/每2周/每月，也会作为排查时间段加入，请谨慎选择。'}
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >{'新增'}</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
AddFiveSTempDetailPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    planDetail: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    leaders: PropTypes.array.isRequired,
    listTypeList: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

AddFiveSTempDetailPanel.defaultProps = {

}

export default withStyles(AddFiveSTempDetailPanel);