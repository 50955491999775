import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import OrgChart from "../../../containers/SystemManagement/OrgManagement/OrgChart";

const OrgManagement = props => {

    const {classes} = props;

    return (
        <div alignitems="center" className={classes.root}>
            <OrgChart/>
        </div>
    );
};
OrgManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(OrgManagement);
