import React, { Component } from 'react';
import { connect } from 'react-redux';
import OilPricingComponent from '@/components/AmoebaManagement/BudgetPricing/OilPricing';
import PropTypes from 'prop-types';
import { getMoment, getYear, formatYear } from '@/utils/datetime';
import { getServiceBudgets, updateServiceBudget, getServiceBudgetHistories } from '@/redux/modules/amoeba/serviceBudget';
import { judgeMainCompany } from '@/utils/manufactureUtils'
import { getUser } from '@/redux/modules/usercard';
import { getRole } from '@/utils/userUtils';
import ServiceHistory from '@/components/AmoebaManagement/ServiceBudget/BudgetHistory'
import * as _ from 'lodash';

class OilPricingContainer extends Component {
    static propTypes = {
        companyList: PropTypes.any,
        companyId: PropTypes.number
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            year: getYear(),
            companyId: this.props.currentUserInfo.companyId,
            subCompanyList: [],
            isMainCompany: judgeMainCompany(this.props.companyList, this.props.currentUserInfo.companyId),
            gVariableUnitPrice: null,
            variableUnitPrice: null,
            open: false,
            text: null,
        }
    }

    getpid = (companyId) => {
        const company = _.find(this.props.companyList, { 'companyId': companyId });
        return company.pid === null ? null : company.companyId
    }

    componentDidMount() {
        const { companyList, companyId } = this.props;
        const { year } = this.state;
        let subCompanyList = companyList.filter((ele) => ele.companyId === companyId);
        let selectCompanyId = companyId;
        if (this.state.isMainCompany) {
            subCompanyList = companyList.filter((ele) => ele.pid === companyId);
            if (subCompanyList && subCompanyList[0]) {
                selectCompanyId = subCompanyList[0].companyId;
            }
        }
        this.props.getUser(this.props.currentUserInfo.userId);
        this.props.getServiceBudget(selectCompanyId, year)
        this.setState({
            companyId: selectCompanyId,
            subCompanyList: subCompanyList
        });
    }

    handleDateChange = (date) => {
        const newYear = formatYear(date)
        this.setState({
            selectedDate: date,
            year: newYear
        })
        this.props.getServiceBudget(this.state.companyId, newYear)
    }


    handleRuleUpdate = (newData, oldData) => {
        const { companyId, year } = this.state
        if (newData.ratio !== oldData.ratio) {
            let data = {}
            data.budgetId = newData.budgetId
            data.ratio = newData.ratio
            data.cate = 'VARIABLE_PRICE'
            this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(companyId, year)
            )
        }
        if (newData.variableUnitPrice !== oldData.variableUnitPrice) {
            let data = {}
            data.budgetId = newData.budgetId
            data.variableUnitPrice = newData.variableUnitPrice
            data.cate = 'VARIABLE_PRICE'
            this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(companyId, year)
            )
        }
        if (newData.gRatio !== oldData.gRatio) {
            let data = {}
            data.budgetId = newData.gBudgetId
            data.ratio = newData.gRatio
            data.cate = 'VARIABLE_PRICE'
            this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(companyId, year)
            )
        }
        if (newData.gVariableUnitPrice !== oldData.gVariableUnitPrice) {
            let data = {}
            data.budgetId = newData.gBudgetId
            data.variableUnitPrice = newData.gVariableUnitPrice
            data.cate = 'VARIABLE_PRICE'
            this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(companyId, year)
            )
        }
        return Promise.resolve(true)
    }

    handleCompanyChange = (event) => {
        const { year } = this.state;
        const companyId = event.target.value;
        this.props.getServiceBudget(companyId, year)
        this.setState({
            companyId: companyId
        })
    }

    handleViewBlur = (event) => {
        const { serviceBudgets } = this.props;
        if ('gVariableUnitPrice' === event.target.name) {
            const gBudgets = _.filter(serviceBudgets, { 'subAlias': '汽油' })
            const ids = gBudgets.map(g => g.budgetId)
            const idString = ids.join(',');
            let data = {}
            data.budgetIds = idString
            data.budgetId = 0
            data.variableUnit = event.target.value
            data.cate = 'VARIABLE_PRICE'
            return this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(this.props.currentUserInfo.companyId, this.props.year)
            )
        } else if ('variableUnitPrice' === event.target.name) {
            const dBudgets = _.filter(serviceBudgets, { 'subAlias': '柴油' })
            const ids = dBudgets.map(g => g.budgetId)
            const idString = ids.join(',');
            let data = {}
            data.budgetIds = idString
            data.budgetId = 0
            data.variableUnit = event.target.value
            data.cate = 'VARIABLE_PRICE'
            this.setState({
                open: false,
            })
            return this.props.updateServiceBudget(data, null).then(
                () => this.props.getServiceBudget(this.props.currentUserInfo.companyId, this.props.year)
            )
        }
    }

    handleViewChange = (event) => {
        if ('gVariableUnitPrice' === event.target.name) {
            this.setState({
                gVariableUnitPrice: event.target.value,
            })
        } else if ('variableUnitPrice' === event.target.name) {
            this.setState({
                variableUnitPrice: event.target.value,
            })
        }
    }

    handleShowHistory = (event, rowData) => {
        this.props.getServiceBudgetHistories(rowData.serviceId, rowData.variableSub);
        this.setState({
            serviceName: rowData.serviceName,
            serviceUnit: rowData.unit,
            openFlag: true,
        })
    }

    handleShowHistoryG = (event, rowData) => {
        this.props.getServiceBudgetHistories(rowData.serviceId, rowData.gVariableSub);
        this.setState({
            serviceName: rowData.serviceName,
            serviceUnit: rowData.unit,
            openFlag: true,
        })
    }

    handleClose = () => {
        this.setState({
            openFlag: false,
        })
    }

    render() {
        let roleFlag = false
        const roleRoute = this.props.roleRoutes.find(ele => ele.route === 'amoeba_plan_variable_unify')
        if (roleRoute && roleRoute.action === 'W') {
            roleFlag = true
        }
        return (
            <div>
                <OilPricingComponent
                    selectedDate={this.state.selectedDate}
                    subCompanyList={this.state.subCompanyList}
                    companyId={this.state.companyId}
                    serviceBudgets={this.props.serviceBudgets}
                    isMainCompany={this.state.isMainCompany}
                    gVariableUnitPrice={this.state.gVariableUnitPrice}
                    variableUnitPrice={this.state.variableUnitPrice}
                    currentUserInfo={this.props.currentUserInfo}
                    department={this.props.department}
                    userProfile={this.props.userProfile}
                    roleFlag={roleFlag}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleRuleUpdate={this.handleRuleUpdate.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleViewBlur={this.handleViewBlur.bind(this)}
                    onhandleViewChange={this.handleViewChange.bind(this)}
                    onhandleShowHistory={this.handleShowHistory.bind(this)}
                    onhandleShowHistoryG={this.handleShowHistoryG.bind(this)}
                />
                <ServiceHistory
                    open={this.state.openFlag}
                    serviceName={this.state.serviceName}
                    serviceUnit={this.state.serviceUnit}
                    manufactureDicts={this.props.manufactureDicts}
                    subFlg={0}
                    users={this.props.users}
                    indexes={this.props.indexes}
                    serviceHistories={this.props.budgetHistories}
                    onhandleClose={this.handleClose.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serviceBudgets: state.serviceBudget.serviceBudgets,
        companyList: state.company.companyList.list,
        departments: state.constant.departments,
        department: state.constant.departments.find(d => d.dptName === '机动部'),
        currentUserInfo: state.auth.currentUserInfo,
        companyId: state.auth.currentUserInfo.companyId,
        userProfile: state.auth.userProfile,
        users: state.usercard.users,
        budgetHistories: state.serviceBudget.budgetHistories,
        manufactureDicts: state.constant.manufactureDicts,
        indexes: state.amoebaIndex.indexes,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceBudget: (companyId, year) => {
            return dispatch(getServiceBudgets(null, companyId, null, 2));
        },
        getUser: (userId) => {
            dispatch(getUser(userId));
        },
        getServiceBudgetHistories: (serviceId, variableSub) => {
            return dispatch(getServiceBudgetHistories(serviceId, variableSub, 'VARIABLE_PRICE'))
        },
        updateServiceBudget: (updateDto, callBack) => {
            return dispatch(updateServiceBudget(updateDto, callBack));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OilPricingContainer);
