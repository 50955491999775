import { colors } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFFFF',
    padding: theme.spacing(2)
  },
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20
  },
  textField: {
    minWidth: 300,
    fontSize: '14px',
  },
  cycleField: {
    width: 100
  },
  gridContainer: {
    marginTop: 10,
    alignItems: 'center'
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  selectError: {
    color: '#e53935',
    margin: 0,
    fontSize: '11px',
    marginTop: '3px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeight: '400',
    lineHeight: '13px',
    letterSpacing: '0.33px'
  },
  radioLabel: {
    marginBottom: 0
  },
  flexgrow: {
    paddingLeft: theme.spacing(25),
    flexGrow: 1
  },
  gridBody: {
    // justifyContent: 'space-between'
  },
  statusGrid: {
    justifyContent: 'center'
  },
  pendingColor: {
    color: colors.red[400]
  }
});

export default withStyles(styles);
