import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
// import { findCompanyNameById, findDptNameById } from '@/utils/constant';

const LaborerManageComponent = props => {
    const { classes,
        projectName,
        laborers,
        showFlag,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
        onhandleLaborerClose,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        tableLayout: 'fixed',
    }

    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '',
                width: 10,
                render: row => <Typography></Typography>
            },
            {
                title: '劳务人员',
                field: 'laborer',
                width: 150,
                render: row => <Typography>{row.laborer}</Typography>,
                editable: 'never',
            },
            {
                title: '所属劳务公司',
                field: 'laborCompany',
                width: 150,
                render: row => <Typography>{row.laborCompany}</Typography>,
            },
            {
                title: '日工资',
                field: 'dailySalary',
                width: 120,
                render: row => <Typography>{row.dailySalary}</Typography>
            },
            {
                title: '保险费',
                field: 'insurance',
                width: 120,
                render: row => <Typography>{row.insurance}</Typography>
            },
            {
                title: '餐饮费',
                field: 'meal',
                width: 120,
                render: row => <Typography>{row.meal}</Typography>
            },
            {
                title: '管理费',
                field: 'manageFee',
                width: 120,
                render: row => <Typography>{row.manageFee}</Typography>
            },
            {
                title: '其他费用',
                field: 'otherFee',
                width: 120,
                render: row => <Typography>{row.otherFee}</Typography>
            },
            // {
            //     title: '需方分厂',
            //     field: 'ownCompany',
            //     width: 120,
            //     render: row => <Typography>{findCompanyNameById(constant, row.ownCompany)}</Typography>,
            // },
            // {
            //     title: '需方班组',
            //     field: 'ownDpt',
            //     width: 130,
            //     render: row => <Typography>{findDptNameById(constant, row.ownDpt)}</Typography>,
            // },
        ]
        return columns
    }

    const assemblyData = () => {
        return laborers;
    }

    return (
        <Dialog fullScreen open={showFlag}>
            <DialogTitle>
                <Typography component={'span'} variant='h3'>{projectName || ''}</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid className={classes.container}>
                    <Grid>
                        <MaterialTable
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            localization={tableLocalization}
                            style={tableStyle}
                            title={<Typography variant="h4">{'劳务管理列表'}</Typography>}
                            editable={
                                {
                                    onRowDelete: (oldData) => onhandleDelete(oldData),
                                }
                            }
                            actions={[
                                {
                                    icon: 'add',
                                    isFreeAction: true,
                                    tooltip: '增加',
                                    onClick: (event) => onhandleAdd()
                                },
                                {
                                    icon: 'edit',
                                    tooltip: '修改',
                                    onClick: (event, rowData) => {
                                        onhandleUpdate(rowData)
                                    }
                                },
                            ]}
                        >
                        </MaterialTable>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onhandleLaborerClose} color="primary"> {'关闭'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LaborerManageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    laborers: PropTypes.array,
    companyList: PropTypes.array,
    departmentList: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleAdd: PropTypes.func,
};

LaborerManageComponent.defaultProps = {
    projects: [],
    projectServiceList: [],
    serviceDptList: [],
    showFlag: true,
};

export default withStyles(LaborerManageComponent);