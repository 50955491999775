import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateAppointment from '../../components/OperationManagement/Appointment/CreateAppointment';
import { getCompanyId } from "../../utils/userUtils";
import {
    initAppointmentCreate, setAppointmentDetails, updateAppointmentDetail,
    routeToWorkOrderList, setNewAppointmentChange, deleteAppointmentDetail, viewAppointmentDetail,
    stepProcess, createWorkPermitUser, setWorkOrderCost, updateWorkOrderFinalCost,
    changeWorkPermitStatus, updateWorkPermitUser, updateWorkOrder, updateAppointmentDetailStatus,
    setWorkOrderOpentext, createSolution, changeSolutionStatus, updateSolution, createWorkPermitDoc, changeWorkPermitDocStatus
} from '../../redux/modules/operation';
import {
    getOperationPlanDocs
} from '../../redux/modules/operationPlanDocs';
import {
    setOperationDetailCost
} from '../../redux/modules/operationPlan';
import { openApprovePannel } from "../../redux/modules/approvePannel";
import { openConfirmDialog } from '../../redux/modules/confirmDialog';
import ConfirmPanel from '../../components/OperationManagement/Appointment/CreateAppointment/ConfirmPanel';
import ApprovePannel from "../LeadershipManagement/ApprovePannel";
import SolutionPanel from "./SolutionPanel";
import WorkPermitDocPanel from "./WorkPermitDocPanel";
import { arrayToTree, treeToArray, findSameLevel } from '../../utils/constant';
import { createmodal, cleanmodal } from '../../redux/modules/modal';
import { createMessage } from '../../redux/modules/message';
import { STATUS_VALUE } from '../../utils/constant';
import { v4 as uuidv4 } from 'uuid';
import { getNowTimestamp } from '@/utils/datetime';
import { download, upload, removeObject, downloadURL } from '@/redux/modules/minio';
import * as _ from 'lodash';
import { FilePreviewer } from '@/components/SimpleComponents'
import { getOperationTypeDocClass } from '@/utils/operationUtils'

class CreateAppointmentContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        companyList: PropTypes.array,
        appointmentDetails: PropTypes.array,
        specCodeList: PropTypes.array,
        userProfile: PropTypes.object,
        newAppointmentChange: PropTypes.bool,
        currentWorkOrder: PropTypes.object,
        orderTypeList: PropTypes.array,
    }

    constructor(props) {
        super(props);
        this.state = {
            statusCode: "",
            actions: [
            ],
            confirmTableOpen: false,
            routeType: '',
            orderHeadAction: false,

            solutionPanelOpen: false,
            currentSolution: {},

            docPanelOpen: false,
            docContentType: null,
            docStatus: null,
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            doc: {}
        };
    }

    // checkPermission = () => {
    //     let permission = _.find(this.props.userProfile.roles[0].routes, { 'route': 'operation_workorder_appointment' })
    //     return permission && permission.action === 'W'
    // }

    checkOrderHead = () => {
        return (this.props.masterWorkOrder.orderHead && this.props.userProfile.userId === this.props.masterWorkOrder.orderHead)

    }

    checkAddRight = () => {
        let rtn = false;
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let dptId = this.props.userProfile.roles[0].departments[0].dptId
        if (this.checkOrderHead()) {
            rtn = true;
        } else {
            if (_.filter(this.props.currentWorkOrder.executors, function (o) { return o.internalCompany }).length > 0) {
                if (_.filter(this.props.currentWorkOrder.executors,
                    function (o) {
                        return o.internalCompany === userCompanyId
                            && o.internalDpt === dptId
                    }).length > 0) {
                    rtn = true;
                }
            }
        }
        console.log(rtn)
        return rtn
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let routeType = this.props.match.params.routeType;
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        this.setState({
            routeType: routeType
        })
        this.props.onhandleInitAppointmentCreate(id, companyId, () => this.callback());
    }

    // checkEditRight = () => {
    //     let rtn = false;
    //     let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
    //     if (this.checkPermission()) {
    //         let targetWoe = this.props.currentWorkOrder.executors[0]
    //         if (!targetWoe) {
    //             rtn = false
    //         } else {
    //             if (targetWoe.internalCompany) {
    //                 if (targetWoe.internalCompany === userCompanyId) {
    //                     rtn = true
    //                 }
    //             } else {
    //                 if (this.props.currentWorkOrder.companyId === userCompanyId) {
    //                     rtn = true
    //                 }
    //             }
    //         }
    //     } else {
    //         rtn = false
    //     }
    //     return rtn
    // }

    callback() {
        let userId = this.props.userProfile.userId;
        if (this.state.routeType === 'needAppointment') {
            let orderId = this.props.currentWorkOrder.orderId;
            let currentAppointment = _.filter(this.props.appointmentDetails, { orderId: orderId });
            if (currentAppointment && currentAppointment.length > 0) {
                if (_.filter(currentAppointment, { status: 'new' }).length > 0) {
                    if (this.checkAddRight()) {
                        setTimeout(() => {
                            this.setState({
                                orderHeadAction: false,
                                statusCode: 'new',
                                actions: [
                                    { statusCode: "new", actionName: "作业预约编辑完成" }
                                ],
                            })
                        }, 100)
                    } else {
                        setTimeout(() => {
                            this.setState({
                                orderHeadAction: false,
                                statusCode: 'new',
                                actions: undefined
                            })
                        }, 100)
                    }
                } else if (_.filter(this.props.appointmentDetails, { status: 'submitted' }).length === this.props.appointmentDetails.length) {
                    if (userId === this.props.masterWorkOrder.orderHead) {
                        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
                            // if (this.props.bigPermit.permitUsersHistory && this.props.bigPermit.permitUsersHistory.length > 0) {
                            //     setTimeout(() => {
                            //         this.setState({
                            //             orderHeadAction: true,
                            //             statusCode: 'processInit',
                            //             actions: [
                            //                 { statusCode: "submitted", actionName: '提交审批' }
                            //             ],
                            //         })
                            //     }, 100)
                            // } else {
                            setTimeout(() => {
                                this.setState({
                                    orderHeadAction: true,
                                    statusCode: 'processInit',
                                    actions: [
                                        { statusCode: "processInit", actionName: '修改预约审批流程' },
                                        { statusCode: "submitted", actionName: '提交审批' }
                                    ],
                                })
                            }, 100)
                            // }
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    orderHeadAction: true,
                                    statusCode: 'processInit',
                                    actions: [
                                        { statusCode: "processInit", actionName: '创建预约审批流程' },
                                    ],
                                })
                            }, 100)
                        }
                    } else {
                        setTimeout(() => {
                            this.setState({
                                orderHeadAction: false,
                                statusCode: 'view',
                                actions: undefined
                            })
                        }, 100)
                    }
                } else if (_.filter(this.props.appointmentDetails, { status: 'reported' }).length === this.props.appointmentDetails.length) {
                    let actions = [];
                    if (this.checkStepActive()) {
                        if (this.checkLastStep()) {
                            actions = [{ statusCode: "Rejected", actionName: "驳回" },
                            { statusCode: "Approved", actionName: "审批通过" }]
                        } else {
                            actions = [{ statusCode: "Rejected", actionName: "驳回" },
                            { statusCode: "Approved", actionName: "审核通过" }]
                        }
                    }
                    setTimeout(() => {
                        this.setState({
                            orderHeadAction: false,
                            statusCode: 'submitted',
                            actions: actions,
                        })
                    }, 100)
                } else {
                    setTimeout(() => {
                        this.setState({
                            orderHeadAction: false,
                            statusCode: 'view',
                            actions: undefined
                        })
                    }, 100)
                }
            }
        } else if (this.state.routeType === 'needWork') {
            if (this.props.currentWorkOrder.status === 'finalized') {
                if (userId === this.props.masterWorkOrder.orderHead) {
                    setTimeout(() => {
                        this.setState({
                            orderHeadAction: false,
                            statusCode: 'view',
                            actions: [{ statusCode: "finished", actionName: "关闭大许可" }]
                        })
                    }, 100)
                }
            }
        } else if (this.state.routeType === 'needCount') {
            if (this.props.currentWorkOrder.status !== 'done') {
                if (userId === this.props.masterWorkOrder.orderHead) {
                    setTimeout(() => {
                        this.setState({
                            orderHeadAction: false,
                            statusCode: 'view',
                            actions: [{ statusCode: "Published", actionName: "验收" }]
                        })
                    }, 100)
                }
            } else {
                setTimeout(() => {
                    this.setState({
                        orderHeadAction: false,
                        statusCode: 'view',
                        actions: undefined
                    })
                }, 100)
            }
        } else {
            setTimeout(() => {
                this.setState({
                    orderHeadAction: false,
                    statusCode: 'view',
                    actions: undefined
                })
            }, 100)
        }
    }

    checkStepActive() {
        let rtn = false;
        let userId = this.props.userProfile.userId;
        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
            let userApprover = _.find(this.props.bigPermit.permitUsers, { userId: userId });
            if (userApprover) {
                if (!userApprover.confirm) {
                    let fatherSameLevelList = findSameLevel(_.cloneDeep(this.props.bigPermit.permitUsers), userApprover.preId, 'puId', 'preId');
                    if (fatherSameLevelList && fatherSameLevelList.length > 0) {
                        if (_.filter(fatherSameLevelList, function (o) { return !o.confirmTime }).length > 0) {
                            rtn = false;
                        } else {
                            rtn = true;
                        }
                    }
                }
            }
        }
        return rtn;
    }

    checkLastStep() {
        let rtn = true;
        let userId = this.props.userProfile.userId;
        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
            let userApprover = _.find(this.props.bigPermit.permitUsers, { userId: userId });
            if (userApprover) {
                if (!userApprover.confirm) {
                    let fatherSameLevelList = findSameLevel(_.cloneDeep(this.props.bigPermit.permitUsers), userApprover.puId, 'puId', 'preId');
                    if (fatherSameLevelList && fatherSameLevelList.length > 0) {
                        fatherSameLevelList.forEach(fsl => {
                            if (_.filter(this.props.bigPermit.permitUsers, { preId: fsl.puId }.length > 0)) {
                                return false
                            }
                        })
                    }
                }
            }
        }
        return rtn;
    }

    //添加新的预约
    handleAddDetail(event, isTerminated) {
        let mainWork = _.find(this.props.appointmentDetails, { orderId: this.props.currentWorkOrder.orderId, mainWork: true });
        let appointmentDetails =
        {
            status: isTerminated ? 'terminated' : 'new',
            orderId: mainWork.orderId,
            mainWork: false,
            location: null,
            estimateStartTime: null,
            estimateEndTime: null,
            woeId: this.props.currentWorkOrder.executors[0].woeId,
            orderNo: this.props.currentWorkOrder.orderNo,
            appContent: null,
            preApp: null,
            _id: uuidv4(),
            locationCompany: mainWork.locationCompany,
            workOrderExecutor: this.props.currentWorkOrder.executors[0],
            tableData: {
                editing: 'update'
            }
        }
        let newAppointmentDetails = _.clone(this.props.appointmentDetails);
        newAppointmentDetails.push(appointmentDetails);
        this.props.setAppointmentDetails(newAppointmentDetails);
        this.props.setNewAppointmentChange(true);
    }

    //重启暂停的作业
    handleRestartWork(event, rowData) {
        if (rowData.status === STATUS_VALUE.STATUS_PAUSED || rowData.status === STATUS_VALUE.STATUS_TERMINATED) {
            let newStatus = STATUS_VALUE.STATUS_SUBMITTED;
            if (!rowData.specCode) {
                newStatus = STATUS_VALUE.STATUS_APPROVED;
            }
            let changeStatus = () => {
                this.props.updateAppointmentDetailStatus(rowData.appId, newStatus, () => {
                    let id = this.props.match.params.id;
                    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
                    this.props.onhandleInitAppointmentCreate(id, companyId, () => this.callback());
                });
            }
            this.props.onhandleOpenConfirmDialog(`作业确认`, `是否重启本作业?`, changeStatus, null, false);
        }
    }

    //预约负责人指定
    handleOpenUserSelectModal(event, rowData) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let companys = []
        let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
        if (mainCompany) {
            companys.push(mainCompany.companyId)
        }
        companys.push(userCompanyId);
        if (rowData.workOrderExecutor) {
            if (rowData.workOrderExecutor.internalCompany) {
                companys.push(rowData.workOrderExecutor.internalCompany);
            }
        }
        companys = _.uniq(companys);
        let param = {
            company: true,
            group: true,
            role: true,
            department: true,
            companyId: companys.length > 0 ? companys : null,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('只能选择一个负责人');
                        return reject('callbackFunc Error')
                    } else {
                        let userId = this.props.selectedUsers[0].userId
                        rowData.appHead = userId;
                        this.props.updateAppointmentDetail(rowData, rowData, () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback()))

                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    //检查是否指定完所有预约的负责人
    subSubmitCheck(datas) {
        let rtn = true;
        if (_.filter(datas, function (o) { return !o.appHead }).length > 0) {
            rtn = false
        }
        return rtn
    }

    //检查前置作业是否设定完成
    submitCheck(datas) {
        let rtn = true;
        if (_.filter(datas, function (o) { return !o.preApp }).length > 1) {
            rtn = false
        }
        return rtn
    }

    handleProcess(action) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let orderId = this.props.currentWorkOrder.orderId;
        if (action.statusCode === 'new') {
            let currentappointmentDetails = _.filter(this.props.appointmentDetails, { orderId: orderId });
            if (currentappointmentDetails && currentappointmentDetails.length > 0) {
                if (this.subSubmitCheck(currentappointmentDetails)) {
                    this.props.stepProcess(orderId, () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback()));
                } else {
                    this.props.onhadleMassgae('作业预约缺少负责人，请确认后再提交');
                }
            }
        } else if (action.statusCode === 'processInit') {
            if (this.props.appointmentDetails && this.props.appointmentDetails.length > 0) {
                // if (_.filter(this.props.appointmentDetails, function (o) { return o.specCode }).length > 0) {
                //     if (this.props.masterJSADocs && this.props.masterJSADocs.length > 0) {
                //         // if (!(this.props.masterBigpermitDocs && this.props.masterBigpermitDocs.length > 0)) {
                //         //     this.props.onhadleMassgae('请上传大许可文档');
                //         //     return
                //         // }
                //     } else {
                //         this.props.onhadleMassgae('存在特殊作业请上传JSA分析文档');
                //         return
                //     }
                // }
                // else {
                //     if (!(this.props.masterBigpermitDocs && this.props.masterBigpermitDocs.length > 0)) {
                //         this.props.onhadleMassgae('请上传大许可文档');
                //         return
                //     }
                // }
            }

            let companys = []
            let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
            if (mainCompany) {
                companys.push(mainCompany.companyId)
            }
            let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
            companys.push(companyId)
            companys = _.uniq(companys);

            let permitUsers = []
            if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
                _.cloneDeep(this.props.bigPermit.permitUsers).forEach(permitUser => {
                    permitUser.id = permitUser.puId;
                    permitUsers.push(permitUser);
                })
                permitUsers = arrayToTree(permitUsers, 'id', 'preId');
            }

            let param = {
                companyId: companys.length > 0 ? companys : null,
                processTree: permitUsers,
                haveProcessSelect: true,
                processNotices: this.props.bigPermit.permitNotices ? _.cloneDeep(this.props.bigPermit.permitNotices) : [],
                callback: () => {
                    return new Promise((resolve, reject) => {
                        if (this.props.processesline && this.props.processesline.length > 0) {
                            let processesline = treeToArray(_.cloneDeep(this.props.processesline));
                            processesline.forEach(pl => {
                                pl.ptwId = this.props.bigPermit.ptwId
                            })

                            let processNotices = _.cloneDeep(this.props.processNotices);
                            processNotices.forEach(pn => {
                                pn.ptwId = this.props.bigPermit.ptwId
                            })
                            this.props.onhandleCreateWorkPermitUser(this.props.bigPermit.ptwId, processesline, processNotices,
                                () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback()))
                            return resolve('callbackFunc success')
                        }
                    })
                }
            }
            this.props.onhandleCreateProcess(param);

        } else if (action.statusCode === 'submitted') {
            // if (this.submitCheck(this.props.appointmentDetails)) {
            //     this.handleSubmitPermit();
            // } else {
            //     this.props.onhadleMassgae('多个作业的时。只能允许一个作业没有前置作业。');
            // }
            this.handleSubmitPermit();
        } else if (action.statusCode === 'Rejected') {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else if (action.statusCode === 'Approved') {
            this.props.openApprovePannel(action.actionName, this.handleApproveConfirm.bind(this, action))
            // this.handleApproveConfirm();
        } else if (action.statusCode === 'finished') {
            this.setState({
                docStatus: 'done'
            })
            this.handleAddBigPermitDoc(null, 'BigPermit');
        } else if (action.statusCode === 'Published') {
            console.log(this.props.masterWorkOrder)
            if (getOperationTypeDocClass(this.props.masterWorkOrder.workRecordFlg) !== 'N') {
                this.props.onhandleGetOperationPlanDocs({ size: 0, offset: 0, orderId: this.props.masterWorkOrder.orderId })
                    .then(() => {
                        let operationPlanDocsList = _.clone(this.props.operationPlanDocsList.list);
                        if (_.filter(operationPlanDocsList, function (o) { return !o.operationPlanDoc }).length > 0 ||
                            _.filter(operationPlanDocsList, function (o) { return o.operationPlanDoc && o.operationPlanDoc.status !== 'active' }).length > 0) {
                            this.props.onhadleMassgae('请前往运维计划报告页面上传当前工单的报告');
                            return
                        } else {
                            this.setState({
                                confirmTableOpen: true
                            })
                        }
                    })
            } else {
                this.setState({
                    confirmTableOpen: true
                })
            }
        }
    }

    handleApproveConfirm(action, comment) {
        // 更新大许可提交状态
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let userId = this.props.userProfile.userId;

        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
            let userData = _.find(this.props.bigPermit.permitUsers, { userId: userId });
            userData.confirm = true;
            userData.opentext = comment || '';
            let changeStatus = () => {
                this.props.updateWorkPermitUser(userData,
                    () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                );
            }
            changeStatus();
            // this.props.onhandleOpenConfirmDialog(`批准确认`, `是否批准当前预约?`, changeStatus, null, false);
        }
    }

    handleProcessConfirm(action, comment) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let userId = this.props.userProfile.userId;

        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
            let userData = _.find(this.props.bigPermit.permitUsers, { userId: userId });
            userData.confirm = false;
            userData.opentext = comment || '';
            this.props.updateWorkPermitUser(userData,
                () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
            );
        }
    }

    handleSubmitPermit() {
        // 更新大许可提交状态
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let userId = this.props.userProfile.userId;

        if (this.props.bigPermit.permitUsers && this.props.bigPermit.permitUsers.length > 0) {
            let userData = _.find(this.props.bigPermit.permitUsers, { userId: userId });
            userData.confirm = true;
            userData.opentext = '提交预约审批申请'
            let changeStatus = () => {
                this.props.updateWorkPermitUser(userData,
                    () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                );
            }
            this.props.onhandleOpenConfirmDialog(`提交确认`, `是否开始预约审批?`, changeStatus, null, false);
        }
    }

    //更新预约详细
    onUpdateAppointmentDetail(value, oldData) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        this.props.updateAppointmentDetail(value, oldData, () => this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback()))
    }

    handleClose() {
        this.setState({
            confirmTableOpen: false
        })
    }

    handleChangeItem(id, value, field) {
        if (field === 'orderCost') {
            this.props.onhandleSetWorkOrderCost(id, value);
        }
        if (field === 'opentext') {
            this.props.onhandleSetWorkOrderOpentext(id, value);
        }
    }

    handleChangeDetailItem(id, value) {
        this.props.onhandleSetOperationDetailCost(id, value)
    }

    handleUpdate() {
        let newWorkOrders = [];
        let workOrderTotal = 0;
        let oldWorkOrders = _.cloneDeep(this.props.workorderWithBigPermitList);
        oldWorkOrders.forEach(workorder => {
            workOrderTotal = _.add(_.toNumber(workOrderTotal), _.toNumber(workorder.orderCost))
            newWorkOrders.push({
                orderId: workorder.orderId,
                orderCost: workorder.orderCost,
                status: 'done'
            })
        })

        let oldPlanDetails = _.cloneDeep(this.props.planDetails.list);
        let newPlanDetails = [];
        let planDetailTotal = 0;
        oldPlanDetails.forEach(oldPlanDetail => {
            oldPlanDetail.finalCost = _.add(_.toNumber(oldPlanDetail.finalCost), _.toNumber(oldPlanDetail.onefinalCost));
            planDetailTotal = _.add(_.toNumber(planDetailTotal), _.toNumber(oldPlanDetail.onefinalCost))
            newPlanDetails.push({
                dtlId: oldPlanDetail.dtlId,
                finalCost: oldPlanDetail.finalCost,
                status: 'done'
            })
        })

        if (_.toNumber(workOrderTotal) !== _.toNumber(planDetailTotal)) {
            this.props.onhadleMassgae('工单决算总计和计划决算总计存在偏差');
            return
        }

        let data = {
            workOrders: newWorkOrders,
            operationPlanDetails: newPlanDetails
        }
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let saveData = () => {
            this.props.onhandleUpdateWorkOrderFinalCost(data, () =>
                this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
            )
            this.setState({
                confirmTableOpen: false
            })
        }

        if (_.filter(oldPlanDetails, function (o) { return _.toNumber(o.finalCost) !== _.toNumber(o.budget) }).length > 0) {
            this.props.onhandleOpenConfirmDialog(`决算确认`, `存在一部分计划的决算金额和预算有偏差,是否依旧保存?`, saveData, null, false);
        } else {
            this.props.onhandleOpenConfirmDialog(`决算确认`, `是否保存?`, saveData, null, false);
        }
    }

    handleRouteToWorkOrderList() {
        this.props.routeToWorkOrderList(this.state.routeType);
    }

    handleViewAppointmentDetail(data, workType) {
        if (workType === 'view') {
            this.props.viewAppointmentDetail(this.props.match.params.id, data.appId, this.state.routeType, workType);
        } else {
            if (!(this.props.masterBigpermitDocs && this.props.masterBigpermitDocs.length > 0
                && _.filter(this.props.masterBigpermitDocs, { status: 'approved' }).length > 0)) {
                this.props.onhadleMassgae('请上传大许可文档');
                return
            } else {
                this.props.viewAppointmentDetail(this.props.match.params.id, data.appId, this.state.routeType, workType);
            }
        }
    }

    //Solution Area------------------------------------------------------------------------------------------
    handleDownload() {
        let doc = this.state.doc;
        let tenantId = this.props.userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        this.props.onhandleDownload(doc.docLink, mybucket);
    }

    async handlePreview(doc) {
        let tenantId = this.props.userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        return this.props.onhandleDownloadUrl(doc.docLink, mybucket)
            .then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: doc.docLink.split('.')[1]
                        },
                        doc: doc

                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
    }
    handleUpdateSolution(solution) {
        this.setState({
            solutionPanelOpen: true,
            currentSolution: solution,
        })
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    handleDeleteSolution(solution) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let changeStatus = () => {
            this.props.onhandleChangeSolutionStatus(solution, 'inactive', () => {
                if (solution.solutionDoc && solution.solutionDoc.docLink) {
                    let tenantId = this.props.userProfile.roles[0].tenantId;

                    let envCode = 'dev';
                    if (window.location.host.indexOf('3050') > -1) {
                        envCode = 'dev';
                    } else if (window.location.host.indexOf('3060') > -1) {
                        envCode = 'uat';
                    } else if (process.env.NODE_ENV === 'production') {
                        envCode = 'prod';
                    }
                    let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
                    this.props.onhandleRemoveMinioObject(mybucket, solution.solutionDoc.docLink)
                }
                this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                this.setState({
                    solutionPanelOpen: false,
                    currentSolution: {},
                })
            })
        }
        this.props.onhandleOpenConfirmDialog(`删除确认`, `是否删除许可办理?`, changeStatus, null, false);
    }

    handleAddSolution() {
        this.setState({
            solutionPanelOpen: true,
            currentSolution: {},
        })
    }

    handleSolutionClose() {
        this.setState({
            solutionPanelOpen: false,
            currentSolution: {},
        })
    }

    handleSolutionConfirm(solution) {
        const {
            userProfile,
            onhandleCreateSolution,
            onhandleUpdateSolution,
            onhandleMinioUpload,
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;

        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();
        let newSolution = {
            orderId: this.props.masterWorkOrder.orderId,
            solutionName: solution.solutionName,
            tenantId: tenantId
        }
        if (solution.solutionContent && solution.solutionContent !== '') {
            newSolution.solutionContent = solution.solutionContent
        }
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        if (!solution.solutionId) {
            if (solution.file) {
                onhandleMinioUpload(solution.file, mybucket, offset, () => {
                    let doc = {
                        docId: 0,
                        docName: solution.file[0].name,
                        docLink: offset + solution.file[0].name,
                        docType: 'DOC_FILE',
                        mybucket: mybucket,
                        tenantId: tenantId
                    };
                    newSolution.solutionDoc = doc;
                    onhandleCreateSolution(this.props.masterWorkOrder.orderId, newSolution,
                        () => {
                            this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                            this.setState({
                                solutionPanelOpen: false,
                                currentSolution: {},
                            })
                        }
                    );
                })
            } else {
                onhandleCreateSolution(this.props.masterWorkOrder.orderId, newSolution,
                    () => {
                        this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                        this.setState({
                            solutionPanelOpen: false,
                            currentSolution: {},
                        })
                    }
                );
            }

        } else {
            newSolution.solutionId = solution.solutionId;
            newSolution.status = STATUS_VALUE.STATUS_NEW;
            // 判断是否有重传文档操作
            const hasRemovedDocs = Boolean(solution.solutionDocBack)
            const hasNewFile = solution.file && solution.file.length && solution.file.length > 0;
            if (hasRemovedDocs) {
                if (hasNewFile) {
                    onhandleMinioUpload(solution.file, mybucket, offset, () => {
                        let doc = {
                            docId: solution.solutionDocBack.docId,
                            docName: solution.file[0].name,
                            docLink: offset + solution.file[0].name,
                            docType: 'DOC_FILE',
                            mybucket: mybucket,
                            tenantId: tenantId
                        };
                        newSolution.solutionDoc = doc;
                        onhandleUpdateSolution(this.props.masterWorkOrder.orderId, newSolution,
                            () => {
                                let tenantId = this.props.userProfile.roles[0].tenantId;

                                let envCode = 'dev';
                                if (window.location.host.indexOf('3050') > -1) {
                                    envCode = 'dev';
                                } else if (window.location.host.indexOf('3060') > -1) {
                                    envCode = 'uat';
                                } else if (process.env.NODE_ENV === 'production') {
                                    envCode = 'prod';
                                }
                                let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
                                this.props.onhandleRemoveMinioObject(mybucket, solution.solutionDocBack.docLink)
                                this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                                this.setState({
                                    solutionPanelOpen: false,
                                    currentSolution: {},
                                })
                            }
                        );
                    })
                } else {

                    let doc = {
                        docId: solution.solutionDocBack.docId,
                        status: 'inactive'
                    };
                    newSolution.solutionDoc = doc;
                    onhandleUpdateSolution(this.props.masterWorkOrder.orderId, newSolution,
                        () => {
                            let tenantId = this.props.userProfile.roles[0].tenantId;

                            let envCode = 'dev';
                            if (window.location.host.indexOf('3050') > -1) {
                                envCode = 'dev';
                            } else if (window.location.host.indexOf('3060') > -1) {
                                envCode = 'uat';
                            } else if (process.env.NODE_ENV === 'production') {
                                envCode = 'prod';
                            }
                            let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
                            this.props.onhandleRemoveMinioObject(mybucket, solution.solutionDocBack.docLink)
                            this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                            this.setState({
                                solutionPanelOpen: false,
                                currentSolution: {},
                            })
                        }
                    );
                }
            } else {
                if (hasNewFile) {
                    onhandleMinioUpload(solution.file, mybucket, offset, () => {
                        let doc = {
                            docName: solution.file[0].name,
                            docLink: offset + solution.file[0].name,
                            docType: 'DOC_FILE',
                            mybucket: mybucket,
                            tenantId: tenantId
                        };
                        newSolution.solutionDoc = doc;
                        onhandleUpdateSolution(this.props.masterWorkOrder.orderId, newSolution,
                            () => {
                                this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                                this.setState({
                                    solutionPanelOpen: false,
                                    currentSolution: {},
                                })
                            }
                        );
                    })

                } else {
                    onhandleUpdateSolution(this.props.masterWorkOrder.orderId, newSolution,
                        () => {
                            this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                            this.setState({
                                solutionPanelOpen: false,
                                currentSolution: {},
                            })
                        }
                    );
                }
            }
        }
    }

    //-------------------------------------------------------------------------------------------------------
    //JSA&BigPerimit DOC-------------------------------------------------------------------------------------
    handleAddBigPermitDoc(event, type) {
        if (type !== 'JSA') {
            if (_.filter(this.props.appointmentDetails, function (o) { return o.specCode }).length > 0) {
                if (this.props.masterJSADocs && this.props.masterJSADocs.length > 0) {
                    this.setState({
                        docPanelOpen: true,
                        docContentType: type,
                    })
                } else {
                    this.props.onhadleMassgae('存在特殊作业请先上传JSA分析文档');
                    return
                }
            } else {
                this.setState({
                    docPanelOpen: true,
                    docContentType: type,
                })
            }
        } else {
            this.setState({
                docPanelOpen: true,
                docContentType: type,
            })
        }
    }

    handleDocPanelClose() {
        this.setState({
            docPanelOpen: false,
            docContentType: null,
            docStatus: null
        })
    }

    handleDocPanelConfirm(WPdoc) {
        const {
            userProfile,
            onhandleCreateWorkPermitDoc,
            onhandleMinioUpload,
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;

        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        onhandleMinioUpload(WPdoc.file, mybucket, offset, () => {
            let doc = {
                docId: 0,
                ptwId: this.props.bigPermit.ptwId,
                docName: WPdoc.docName,
                docLink: offset + WPdoc.file[0].name,
                docType: 'DOC_FILE',
                mybucket: mybucket,
                contentType: this.state.docContentType === 'JSA' ? 1 : 2,
                tenantId: tenantId
            };
            if (this.state.docStatus) {
                doc.status = this.state.docStatus;
            }
            onhandleCreateWorkPermitDoc(doc, this.state.docContentType,
                () => {
                    this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
                    this.setState({
                        docPanelOpen: false,
                        docContentType: null,
                        docStatus: null,
                    })
                }
            );
        })
    }

    handleDeleteWorkPermitDoc(type, doc) {
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let changeStatus = () => {
            this.props.onhandleChangeWorkPermitDocStatus(doc, 'inactive', type, () => {
                let tenantId = this.props.userProfile.roles[0].tenantId;

                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
                this.props.onhandleRemoveMinioObject(mybucket, doc.docLink)
                this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
            })
        }
        this.props.onhandleOpenConfirmDialog(`删除确认`, `是否删除${type === 'JSA' ? 'JSA文档' : '大许可文档'}?`, changeStatus, null, false);
    }

    handleApproveWorkPermitDoc() {
        let doc = _.find(this.props.masterBigpermitDocs, { status: 'active' });
        let userCompanyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        this.props.onhandleChangeWorkPermitDocStatus(doc, 'approved', 'BigPermit', () => {
            this.props.onhandleInitAppointmentCreate(this.props.currentWorkOrder.orderId, userCompanyId, () => this.callback())
        })
    }
    //-------------------------------------------------------------------------------------------------------

    render() {
        const { previewFile } = this.state;
        return (
            <div>
                <CreateAppointment
                    userCompanyId={getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)}
                    companyList={this.props.companyList}
                    currentWorkOrder={this.props.currentWorkOrder}
                    appointmentDetails={this.props.appointmentDetails}
                    newAppointmentChange={this.props.newAppointmentChange}
                    userProfile={this.props.userProfile}
                    specCodeList={this.props.specCodeList}
                    orderTypeList={this.props.orderTypeList}
                    onhandleUpdate={this.onUpdateAppointmentDetail.bind(this)}
                    onhandleDelete={this.props.deleteAppointmentDetail.bind(this)}
                    onhandleView={this.handleViewAppointmentDetail.bind(this)}
                    onhandleBack={this.handleRouteToWorkOrderList.bind(this)}
                    onhandleHeadClick={this.handleOpenUserSelectModal.bind(this)}
                    onhandleAddDetail={this.handleAddDetail.bind(this)}
                    onhandleRestartWork={this.handleRestartWork.bind(this)}
                    constant={this.props.constant}
                    statusCode={this.state.statusCode}
                    actions={this.state.actions}
                    onhandleProcess={this.handleProcess.bind(this)}
                    bigPermit={this.props.bigPermit}
                    workorderWithBigPermitList={this.props.workorderWithBigPermitList}
                    orderHeadAction={this.state.orderHeadAction}
                    masterWorkOrder={this.props.masterWorkOrder}
                    routeType={this.state.routeType}
                    onhandleAddSolution={this.handleAddSolution.bind(this)}
                    onhandleDownload={this.handleDownload.bind(this)}
                    onhandleUpdateSolution={this.handleUpdateSolution.bind(this)}
                    onhandleDeleteSolution={this.handleDeleteSolution.bind(this)}
                    onhandleAddBigPermitDoc={this.handleAddBigPermitDoc.bind(this)}
                    masterJSADocs={this.props.masterJSADocs}
                    masterBigpermitDocs={this.props.masterBigpermitDocs}
                    onhandleDeleteWorkPermitDoc={this.handleDeleteWorkPermitDoc.bind(this)}
                    onhandleApprovePermitDoc={this.handleApproveWorkPermitDoc.bind(this)}
                />
                <ApprovePannel />
                <SolutionPanel
                    open={this.state.solutionPanelOpen}
                    solution={this.state.currentSolution}
                    closeCallBack={this.handleSolutionClose.bind(this)}
                    confirmCallback={this.handleSolutionConfirm.bind(this)}
                />
                <WorkPermitDocPanel
                    open={this.state.docPanelOpen}
                    type={this.state.docContentType}
                    closeCallBack={this.handleDocPanelClose.bind(this)}
                    confirmCallback={this.handleDocPanelConfirm.bind(this)}
                />
                {this.state.confirmTableOpen && <ConfirmPanel
                    open={this.state.confirmTableOpen}
                    onhandleClose={this.handleClose.bind(this)}
                    workorderWithBigPermitList={this.props.workorderWithBigPermitList}
                    planDetails={this.props.planDetails.list}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleChangeItem={this.handleChangeItem.bind(this)}
                    onhandleChangeDetailItem={this.handleChangeDetailItem.bind(this)}
                />}

                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onDownload={this.handleDownload.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companyList: state.constant.companies,
        orderTypeList: state.operation.orderTypeList,
        appointmentDetails: state.operation.appointmentDetails,
        newAppointmentChange: state.operation.newAppointmentChange,
        userProfile: state.auth.userProfile,
        currentWorkOrder: state.operation.currentWorkOrder,
        specCodeList: state.operation.specCodeList,
        selectedUsers: state.userSelect.selectedUsers,
        constant: state.constant,
        bigPermit: state.operation.masterBigPermit,
        masterWorkOrder: state.operation.masterWorkOrder,
        workorderWithBigPermitList: state.operation.workorderWithBigPermitList,
        processesline: state.processlinewithuser.processesline,
        processNotices: state.processlinewithuser.processNotices,
        masterJSADocs: state.operation.masterJSADocs,
        masterBigpermitDocs: state.operation.masterBigpermitDocs,
        planDetails: state.operationPlan.planDetails,
        operationPlanDocsList: state.operationPlanDocs.operationPlanDocsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSetWorkOrderCost: (id, cost) => {
            dispatch(setWorkOrderCost(id, cost))
        },
        onhandleSetWorkOrderOpentext: (id, opentext) => {
            dispatch(setWorkOrderOpentext(id, opentext))
        },
        onhandleSetOperationDetailCost: (id, cost) => {
            dispatch(setOperationDetailCost(id, cost))
        },
        onhandleInitAppointmentCreate: (id, companyId, callback) => {
            dispatch(initAppointmentCreate(id, companyId, callback))
        },
        setAppointmentDetails: (value) => {
            dispatch(setAppointmentDetails(value))
        },
        updateAppointmentDetail: (value, oldData, callback) => {
            dispatch(updateAppointmentDetail(value, oldData, callback))
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhandeModalclose: () => {
            dispatch(cleanmodal());
        },
        routeToWorkOrderList: (routeType) => {
            dispatch(routeToWorkOrderList(routeType))
        },
        setNewAppointmentChange: (value) => {
            dispatch(setNewAppointmentChange(value))
        },
        deleteAppointmentDetail: (value) => {
            dispatch(deleteAppointmentDetail(value))
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        viewAppointmentDetail: (orderId, appId, routeType, workType) => {
            dispatch(viewAppointmentDetail(orderId, appId, routeType, workType))
        },
        stepProcess: (value, callback) => {
            dispatch(stepProcess(value, callback))
        },
        onhandleCreateWorkPermitUser: (ptwId, users, processNotices, callback) => {
            dispatch(createWorkPermitUser(ptwId, users, processNotices, callback, 'big'))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleChangeWorkPermitStatus: (ptwId, status, callback) => {
            dispatch(changeWorkPermitStatus(ptwId, status, callback))
        },
        updateWorkPermitUser: (permitUser, callback) => {
            dispatch(updateWorkPermitUser(permitUser, callback, 'big'))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onUpdateWorkOrder: (data, callback) => {
            dispatch(updateWorkOrder(data, callback));
        },
        updateAppointmentDetailStatus: (appId, status, callback) => {
            dispatch(updateAppointmentDetailStatus(appId, status, callback))
        },
        onhandleCreateProcess: (parm) => {
            dispatch(createmodal('createProcessLineApproval', parm, 'big'));
        },
        onhandleUpdateWorkOrderFinalCost: (data, callback) => {
            dispatch(updateWorkOrderFinalCost(data, callback));
        },
        onhandleMinioUpload: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateSolution: (orderId, data, callback) => {
            dispatch(createSolution(orderId, data, callback));
        },
        onhandleUpdateSolution: (orderId, data, callback) => {
            dispatch(updateSolution(orderId, data, callback));
        },
        onhandleDownload: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleRemoveMinioObject: (mybucket, docName) => {
            dispatch(removeObject(mybucket, docName));
        },
        onhandleChangeSolutionStatus: (data, status, callback) => {
            dispatch(changeSolutionStatus(data, status, callback));
        },
        onhandleCreateWorkPermitDoc: (doc, type, callback) => {
            dispatch(createWorkPermitDoc(doc, type, callback));
        },
        onhandleChangeWorkPermitDocStatus: (doc, status, type, callback) => {
            dispatch(changeWorkPermitDocStatus(doc, status, type, callback));
        },
        onhandleGetOperationPlanDocs: (query) => {
            return dispatch(getOperationPlanDocs(query));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAppointmentContainer);