import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Button,
    Card
} from '@material-ui/core';
import {
    Add,
    FileCopy,
} from '@material-ui/icons'
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';
import clsx from 'clsx';


const DevicePartListComponent = props => {

    const {
        classes,
        tabValue,
        index,
        deviceParts,
        userProfile,
        // getAccessoryList,
        // onEdit,
        onDetail,
        catList,
        // onSizeChange,
        // onView,
        onhandleCreate,
        onhandleCreateByAccessory,
        onhandleBack,
        onDelete,
        // 当前设备
        currentDevice,
        // isView,
        isDeviceEdit,
        onhandleOpenAccessoryList,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_accessory_list' })
        return permission && permission.action === 'W'
    }
    //  不受变更单影响：部件信息
    // const devEditable = checkPermission() && !isView && !isDeviceEdit
    const devEditable = checkPermission() && isDeviceEdit

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }


    // const renderIsCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === false) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    const renderIsSpare = (row) => {
        if (row.isSpare === true) {
            return '是'
        } else if (row.isSpare === false) {
            return '否'
        } else {
            return ''
        }
    }

    // const renderStatus = (row) => {
    //     if (row.status === 'active') {
    //         return '在用'
    //     } else if (row.status === 'new') {
    //         return '备用'
    //     } else if (row.status === 'inactive') {
    //         return '弃用'
    //     } else {
    //         return ''
    //     }
    // }

    // const renderTime = (row) => {
    //     return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    // }

    const assemblycolumns = () => {
        let columns = [
            {
                title: '部件编号',
                field: 'partNo',
                render: row => row ? <Typography>{row.partNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件名称',
                field: 'partName',
                render: row => row ? <Typography>{row.partName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            },
            {
                title: '规格',
                field: 'spec',
                render: row => row ? <Typography>{row.spec}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件类别',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
            },
            // {
            //     title: '通用部件',
            //     field: 'isCommon',
            //     render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
            //     editable: 'never'
            // },
            // 缺陷 部件页面 使用状态删除 SBGL-274
            // {
            //     title: '使用状态',
            //     field: 'status',
            //     render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            //     editable: 'never'
            // },

            // {
            //     title: '更新时间',
            //     field: 'update_time',
            //     type: 'date',
            //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '数量（个）',
                field: 'amount',
                type: 'number',
                align: 'right',
                render: row => row ? <Typography>{row.amount}</Typography> : '',
                editable: 'never'
            },
            {
                title: '需要备件',
                field: 'isSpare',
                type: 'date',
                width: '100px',
                render: row => row ? <Typography>{renderIsSpare(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <div>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'设备部件列表'}
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                设备编号
                            </Typography>
                            <Typography gutterBottom>
                                {currentDevice.deviceNo ? currentDevice.deviceNo : '-'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                设备名称
                            </Typography>
                            <Typography gutterBottom>
                                {currentDevice.deviceName ? currentDevice.deviceName : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <MTableToolbar {...props} />
            </div>
        )
    }

    return (
        <Card alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={
                clsx(classes.detailtable, {
                    [classes.tableToolbarEditable]: devEditable,
                })
            }>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={deviceParts}
                    options={{
                        ...options,
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: devEditable,
                    }}
                    components={tabValue ? null : components}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    actions={devEditable ?
                        [rowData => ({
                            icon: 'edit',
                            tooltip: '部件详情',
                            onClick: (event, rowData) => {
                                onDetail(event, rowData)
                            }
                        }),
                        {
                            icon: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}>
                                新增
                            </Button>,
                            tooltip: '新增',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleCreate()
                            }
                        },
                        {
                            icon: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<FileCopy />}>
                                复制
                            </Button>,
                            tooltip: '复制',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleCreateByAccessory()
                            }
                        },
                        rowData => ({
                            icon: 'wifi_tethering',
                            tooltip: '更换记录',
                            onClick: (event, rowData) => {
                                onhandleOpenAccessoryList(event, rowData)
                            }
                        }),
                        rowData => ({
                            icon: 'delete_outline',
                            tooltip: '删除部件',
                            onClick: (event, rowData) => {
                                onDelete(event, rowData)
                            }
                        })
                        ] : [
                            rowData => ({
                                icon: 'description',
                                tooltip: '部件详情',
                                onClick: (event, rowData) => {
                                    onDetail(event, rowData)
                                }
                            }),
                            rowData => ({
                                icon: 'settings',
                                tooltip: '关联部件',
                                onClick: (event, rowData) => {
                                    onhandleOpenAccessoryList(event, rowData)
                                }
                            }),
                        ]}
                >
                </MaterialTable>
            </Grid>
            <div className={classes.card}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="primary"
                            onClick={event => onhandleBack(event)}
                        >
                            返回
                        </Button>
                    </Grid>

                </Grid>
            </div>
        </Card>
    );
};

DevicePartListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    deviceParts: PropTypes.array.isRequired,
    getAccessoryList: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    catList: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onReplace: PropTypes.func.isRequired,
    // 当前设备
    currentDevice: PropTypes.object.isRequired,
};

export default (withStyles(DevicePartListComponent));