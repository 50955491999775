import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addLaborer, updateLaborer, delLaborer, getLaborers } from '@/redux/modules/manufacture/laborerManage';
import { createActionMessage } from '@/redux/modules/message';
import LaborerManageComponent from '@/components/ManufactureManagement/LaborerManage/LaborerManageComponent';
import LaborerManageInfoComponent from '@/components/ManufactureManagement/LaborerManage/LaborerInfo/LaborerManageInfoComponent';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

class LaborerManageContainer extends Component {

    static propTypes = {
        projectId: PropTypes.number,
        projectName: PropTypes.string,
        openLaborerFlag: PropTypes.bool,
        onhandleLaborerClose: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = {
            openFlag: false,
            laborerInfo: {},
            departmentList: []
        }
    }

    componentDidMount() {
        this.getLaborers();
    }

    getLaborers() {
        const { projectId, getLaborers } = this.props;
        console.log(projectId);
        console.log(this.props.projectName);
        getLaborers(projectId);
    }

    handleAdd = () => {
        let laborerInfo = {};
        this.setState({
            openFlag: true,
            laborerInfo: laborerInfo,
        })
    }

    handleDelete = (rowData) => {
        const { delLaborer } = this.props;
        return delLaborer(rowData.laborerId, () => {
            this.handleClose();
            this.getLaborers();
        });
    }

    handleUpdate = (rowData) => {
        if (rowData.ownCompany) {
            this.getDepartmentList(rowData.ownCompany);
        }
        this.setState({
            openFlag: true,
            laborerInfo: rowData,
        })
    }

    handleSave = () => {
        const laborerInfo = this.state.laborerInfo;
        const { projectId, addLaborer, updateLaborer } = this.props;
        if (laborerInfo) {
            let newLaborerInfo = {}
            if (laborerInfo.laborer) {
                newLaborerInfo.laborer = laborerInfo.laborer;
            }
            if (laborerInfo.laborCompany) {
                newLaborerInfo.laborCompany = laborerInfo.laborCompany;
            }
            if (laborerInfo.insurance) {
                newLaborerInfo.insurance = parseFloat(laborerInfo.insurance);
            } else {
                newLaborerInfo.insurance = 0.0;
            }
            if (laborerInfo.meal) {
                newLaborerInfo.meal = parseFloat(laborerInfo.meal);
            } else {
                newLaborerInfo.meal = 0.0;
            }
            if (laborerInfo.manageFee) {
                newLaborerInfo.manageFee = parseFloat(laborerInfo.manageFee);
            } else {
                newLaborerInfo.manageFee = 0.0;
            }
            if (laborerInfo.otherFee) {
                newLaborerInfo.otherFee = parseFloat(laborerInfo.otherFee);
            } else {
                newLaborerInfo.otherFee = 0.0;
            }
            if (laborerInfo.dailySalary) {
                newLaborerInfo.dailySalary = parseFloat(laborerInfo.dailySalary);
            } else {
                newLaborerInfo.dailySalary = 0.0;
            }
            if (laborerInfo.ownCompany) {
                newLaborerInfo.ownCompany = laborerInfo.ownCompany;
            }
            if (laborerInfo.ownDpt) {
                newLaborerInfo.ownDpt = laborerInfo.ownDpt;
            }
            newLaborerInfo.projectId = projectId;

            if (laborerInfo.laborerId) {
                newLaborerInfo.laborerId = laborerInfo.laborerId;
                //更新
                updateLaborer(newLaborerInfo, () => {
                    this.handleClose();
                    this.getLaborers();
                });
            } else {
                //新增
                addLaborer(newLaborerInfo, () => {
                    this.handleClose();
                    this.getLaborers();
                });
            }
        }
    }

    handleClose = () => {
        this.setState({
            openFlag: false,
            laborerInfo: {},
        })
    }

    handleTextChange = (event, laboreInfo) => {
        if (event.target.name === 'ownCompany') {
            laboreInfo.ownCompany = event.target.value;
            laboreInfo.ownDpt = null;
            this.getDepartmentList(event.target.value);
        } else if (event.target.name === 'ownDpt') {
            laboreInfo.ownDpt = event.target.value;
        }
        this.setState({ laboreInfo: laboreInfo });
    }

    handleChange = (event, laboreInfo) => {
        if (event.target.name === 'otherFee') {
            laboreInfo.otherFee = event.target.value;
        } else if (event.target.name === 'manageFee') {
            laboreInfo.manageFee = event.target.value;
        } else if (event.target.name === 'meal') {
            laboreInfo.meal = event.target.value;
        } else if (event.target.name === 'insurance') {
            laboreInfo.insurance = event.target.value;
        } else if (event.target.name === 'dailySalary') {
            laboreInfo.dailySalary = event.target.value;
        } else if (event.target.name === 'laborCompany') {
            laboreInfo.laborCompany = event.target.value;
        } else if (event.target.name === 'laborer') {
            laboreInfo.laborer = event.target.value;
        }
        this.setState({ laboreInfo: laboreInfo });
    }

    getDepartmentList = (companyId) => {
        const { departmentList } = this.props;
        const dpts = _.filter(departmentList, item => {
            return item.companyId === companyId;
        })
        this.setState({ departmentList: dpts });
    }

    render() {
        return (
            <div>
                <LaborerManageComponent
                    projectName={this.props.projectName}
                    laborers={this.props.laborers}
                    openLaborerFlag={this.props.openLaborerFlag}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleLaborerClose={this.props.onhandleLaborerClose.bind(this)}
                />
                {this.state.openFlag &&
                    <LaborerManageInfoComponent
                        openFlag={this.state.openFlag}
                        companyList={this.props.companyList}
                        laborerInfo={this.state.laborerInfo}
                        departmentList={this.state.departmentList}
                        onhandleClose={this.handleClose.bind(this)}
                        onhandleSave={this.handleSave.bind(this)}
                        onhandleTextChange={this.handleTextChange.bind(this)}
                        onhandleChange={this.handleChange.bind(this)}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        companyList: state.company.companyList.list,
        departmentList: state.department.departments.list,
        currentUserInfo: state.auth.currentUserInfo,
        laborers: state.laborerManage.laborers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addLaborer: (data, callback) => {
            dispatch(addLaborer(data, callback));
        },
        updateLaborer: (updateDto, callback) => {
            dispatch(updateLaborer(updateDto, callback));
        },
        delLaborer: (laborerId, callback) => {
            return dispatch(delLaborer(laborerId, callback));
        },
        getLaborers: (projectId) => {
            dispatch(getLaborers(null, null, projectId));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LaborerManageContainer);
