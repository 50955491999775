import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    gridcontainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        //margin: theme.spacing(2),
        //minHeight:300,
        justifyContent: 'space-around',
    },
    carousel: {

    },
    carouselsilde: {

    },
    slidecard: {

    },
    gridlist: {
        flexWrap: 'nowrap',
        //width: '95%',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    containercard: {
        display: 'flex',
    },
    newscard: {
        maxWidth: 400,
        display: 'flex',
        border: 'none',
        margin: theme.spacing(1),
        boxShadow: 'none',
        borderRadius: 0,
        //maxWidth: `{'md'}`,
        //Width:300,
    },
    newcardimage: {
        width: '40%'
    },

    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
    pagebutton: {
        minWidth: 16,
        margin: theme.spacing(1),
        borderRadius: 0,
    },
    pagetext: {
        marginRight: theme.spacing(3),
        alignItems: 'center',
        display: 'inline-flex',

    }


});


export default withStyles(styles);