import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';

const ProcessDiagram = props => {

    const {
        classes,
        tabValue,
        index
    } = props;

    return (
        <div className={classes.root} hidden={tabValue !== index}>
            <div id="bpmn_canvas" className={classes.canvas}>
                <div id="prop_view" className={classes.propView} style={{ height: window.innerHeight - 160 }}></div>
                <div id="bpmn_view" className={classes.bpmnView}></div>
            </div>
        </div>
    );
};

ProcessDiagram.propTypes = {
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
};

export default withStyles(ProcessDiagram);
