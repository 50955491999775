import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initOppoHeader, setSearch, resetSearch, getOppo, onCreateOppo } from '../../../redux/modules/customerOppo';
import OppoHeader from "../../../components/Crm/Oppo/OppoHeader";
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';
import {
  getMoment as moment
} from '@/utils/datetime';

class OppoHeaderContainer extends Component {

  static propTypes = {
    companyTree: PropTypes.array,
    search: PropTypes.object,
    userProfile: PropTypes.object,
    companyList: PropTypes.array,
    statusList: PropTypes.array,
  };

  componentDidMount() {
    //console.log('componentDidMountcomponentDidMountcomponentDidMountcomponentDidMount')
    this.props.onhandleInitOppoHeader(this.props.query, true);
  }

  handleSearch(event) {
    this.props.onhandleSearch({ offset: 0, size: 5, sort: "-update_time" });
  }

  handleSelect = (value, field) => {
    let newSearch;
    console.log('status[]:', value.target.value);
    switch (field) {
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value.target.value ? value.target.value === '' ? undefined : value.target.value : undefined
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleClearSearch() {
    this.props.onhandleClearSearch();
  }

  handleCreateOppo() {
    this.props.onCreateOppo();
  }

  handleChange = (event, field) => {
    let newSearch = { ...this.props.search };
    switch (field) {
      case 'search':
        newSearch.search = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'source':
        newSearch.source = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'fromAmount':
        newSearch.fromAmount = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'toAmount':
        newSearch.toAmount = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'fromPossibility':
        newSearch.fromPossibility = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'toPossibility':
        newSearch.toPossibility = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleDateChange(value, field) {
    let newSearch = { ...this.props.search };

    const date = moment()(value);
    switch (field) {
      case 'fromDate':
        newSearch.fromDate = date.startOf('day').format('YYYY-MM-DD');
        break;
      case 'toDate':
        if (
          date.isBefore(moment()(newSearch.fromDate))
        ) {
          newSearch.toDate = moment()(newSearch.fromDate).endOf('day').format('YYYY-MM-DD');
        } else {
          newSearch.toDate = date.endOf('day').format('YYYY-MM-DD');
        }
        break;
      case 'expiredFromDate':
        newSearch.expiredFromDate = date.startOf('day').format('YYYY-MM-DD');
        break;
      case 'expiredToDate':
        if (
          date.isBefore(moment()(newSearch.expiredFromDate))
        ) {
          newSearch.expiredToDate = moment()(newSearch.expiredFromDate).endOf('day').format('YYYY-MM-DD');
        } else {
          newSearch.expiredToDate = date.endOf('day').format('YYYY-MM-DD');
        }
        break;
      default:
      // do nothing
    }
    this.props.onhandleUpdateEditSearch(newSearch);
  }

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <div>
        <OppoHeader
          userProfile={this.props.userProfile}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          onhandleChange={this.handleChange.bind(this)}
          onhandleDateChange={this.handleDateChange.bind(this)}
          search={this.props.search}
          statusList={this.props.statusList}
          onhandleCreateOppo={this.handleCreateOppo.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    search: state.customerOppo.search,
    statusList: state.customerOppo.statusList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitOppoHeader: (query, reload) => {
      dispatch(initOppoHeader(query, reload));
    },
    onhandleUpdateEditSearch: (value) => {
      dispatch(setSearch(value));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: (query) => {
      dispatch(getOppo(query));
    },
    onCreateOppo: () => {
      dispatch(onCreateOppo());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OppoHeaderContainer)
