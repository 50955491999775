import React, { useState } from 'react';
import {
    Card,
    Tabs,
    Tab,
    // Avatar,
    Button,
    CssBaseline,
    TextField,
    AppBar,
    // Checkbox,
    Link,
    // Grid,
    Typography,
    Container,
    InputAdornment,
    IconButton,
    // Box,
    FormHelperText,
    Grid
} from '@material-ui/core';
import {
    PersonOutline,
    Lock
} from '@material-ui/icons';
// import clsx from 'clsx';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Cancel from '@material-ui/icons/Cancel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Copyright from './Copyright';
import PropTypes from 'prop-types';
import withStyles from './styles';
import { withRouter } from 'react-router-dom';

const Signin = props => {

    const { classes,
        onhandleSignIn,
        onhandleChange,
        onhandleClearTextField,
        onhandleCheck,
        onhandleKeyDown,
        loginInfo,
    } = props;

    const [tabValue, setTabValue] = useState(0);
    const handleTabeChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [formState, setFormState] = useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setFormState({ ...formState, showPassword: !formState.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={classes.appBar}
                style={{ zIndex: 1000 }}
            >
                <div className={classes.toolbar}>
                    <Button disabled={true}
                        className={classes.logoButton}
                    >
                        <img className={classes.logoimage} src='/images/logos/u104.svg' alt='祥云' />
                        <Typography variant="h1" className={classes.logo} noWrap>
                            祥云
                        </Typography>
                    </Button>
                    <Typography className={classes.title} noWrap>
                        登录
                    </Typography>
                </div>
            </AppBar>
            <Container className={classes.container}>
                <CssBaseline />
                <Card className={classes.card}>
                    {/* <Avatar className={classes.avatar}
                        variant="rounded"
                        src='/images/logos/logo.png'
                        imgProps={{
                            className: classes.logo
                        }}
                        alt='祥云'
                    /> */}
                    <Tabs className={classes.tabs}
                        value={tabValue}
                        onChange={handleTabeChange}
                        aria-label="login option tabs"
                    // variant="scrollable"
                    // scrollButtons="auto"
                    >
                        <Tab default component={() =>
                            <Typography variant="h3" className={classes.tabLabel}>
                                用户登录
                            </Typography>
                        }>
                        </Tab>
                    </Tabs>
                    <form className={classes.form} noValidate>
                        {/* <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="tenantcode"
                        label="租户代码"
                        name="tenantcode"
                        value={loginInfo.tenantAlias || ''}
                        onChange={event => onhandleChange(event, 'tenantcode')}
                        onBlur={event => onhandleCheck(event, 'tenantcode')}
                        onKeyDown={event => onhandleKeyDown(event)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loginInfo.tenantAlias ? <IconButton
                                        edge="end"
                                        aria-label="toggle delete tenantcode"
                                        onClick={event => onhandleClearTextField(event, 'tenantcode')}
                                    >
                                        <Cancel />
                                    </IconButton>
                                        : ''}
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            // label="用户名/邮箱/手机号码"
                            placeholder='输入用户名/邮箱/手机号码'
                            name="username"
                            value={loginInfo.usernameOrEmail || ''}
                            autoFocus
                            onChange={event => onhandleChange(event, 'username')}
                            onBlur={event => onhandleCheck(event, 'username')}
                            onKeyDown={event => onhandleKeyDown(event)}
                            InputProps={{
                                className: classes.textInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutline />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {loginInfo.usernameOrEmail ? <IconButton
                                            edge="end"
                                            aria-label="toggle delete username"
                                            onClick={event => onhandleClearTextField(event, 'username')}
                                        >
                                            <Cancel />
                                        </IconButton>
                                            : ''}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            // label="密码"
                            placeholder='输入密码'
                            value={loginInfo.password || ''}
                            type={formState.showPassword ? 'text' : 'password'}
                            id="password"
                            onChange={event => onhandleChange(event, 'password')}
                            onBlur={event => onhandleCheck(event, 'password')}
                            onKeyDown={event => onhandleKeyDown(event)}
                            InputProps={{
                                className: classes.textInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="记住我"
                    /> */}
                        <FormHelperText error={loginInfo.errorMessage !== ''}>
                            {loginInfo.errorMessage}</FormHelperText>
                        <Button
                            className={classes.submit}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onhandleSignIn}>
                            登录
                        </Button>
                    </form>
                    <Grid container>
                        <Grid item xs>
                            <Link href="forgotPassword" className={classes.userLink}>
                                忘记密码?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="signup" className={classes.userLink}>
                                注册用户
                            </Link>
                        </Grid>
                    </Grid>
                    {/* <Grid container>
                        <Grid item xs>
                            <Typography variant={'body1'}>
                                其他方式
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link className={classes.userLink}>
                                注册用户
                            </Link>
                        </Grid>
                    </Grid> */}
                </Card>
            </Container >
            <Grid className={classes.footer}>
                <Copyright />
            </Grid>
        </div >
    );
};

Signin.propTypes = {
    classes: PropTypes.object.isRequired,
    loginInfo: PropTypes.any.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleKeyDown: PropTypes.func.isRequired,
    onhandleClearTextField: PropTypes.func.isRequired,
    onhandleSignIn: PropTypes.func.isRequired,
};

export default withRouter(withStyles(Signin));