import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
    open: false,
    inventory: {
        inventoryId: 0,
        inventoryName: '',
        companyId: '',
        listType: '',
        catCode: '',
        validDate: false,
        validStartTime: '',
        validEndTime: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openInventoryEditPanel = createAction('@@xcloud/checklistInventoryEdit/open',
    (
        confirmCallback, closeCallback, inventory, companyId
    ) => ({
        confirmCallback, closeCallback, inventory, companyId
    })
);

export const closeInventoryEditPanel = createAction('@@xcloud/checklistInventoryEdit/close');

export const changeInventory = createAction('@@xcloud/checklistInventoryEdit/changeInventory');

// Actions

export const ChecklistInventoryEditReducer = handleActions(
    {
        [openInventoryEditPanel]: (state, { payload: data }) => {
            let inventory = data.inventory ? _.cloneDeep(data.inventory) : null;
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                inventory: {
                    inventoryId: inventory ? inventory.inventoryId : 0,
                    inventoryName: inventory ? inventory.inventoryName : '',
                    companyId: inventory ? inventory.companyId : data.companyId,
                    listType: inventory ? inventory.listType : '',
                    catCode: inventory ? inventory.catCode : '',
                    validDate: inventory && (inventory.validStartTime || inventory.validEndTime) ? true : false,
                    validStartTime: inventory && inventory.validStartTime ? inventory.validStartTime : '',
                    validEndTime: inventory && inventory.validEndTime ? inventory.validEndTime : '',
                },
            };
        },
        [closeInventoryEditPanel]: (state,) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeInventory]: (state, { payload: data }) => {
            return {
                ...state,
                inventory: data,
            };
        }
    },
    initialState
);

export default ChecklistInventoryEditReducer;
