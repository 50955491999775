import React, { Component } from 'react';
import { connect } from 'react-redux';
import FiveSPlanRecords from "../../../components/InspectionManagement/FiveSPlan/FiveSPlanRecords";
import PropTypes from "prop-types";
import {
    getPlanRecords,
    backToFiveSPlanList,
    getPlanRecordsForCsv
} from '@/redux/modules/inspection/inspectionPlanMonthly';
import {
    initFiveSRecords
} from '@/redux/modules/inspection/inspectionPlan';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { createmodal } from "../../../redux/modules/modal";
import { downloadURL } from '../../../redux/modules/minio'
import {
    getDateByTimestamp,
    getMoment as moment,
} from '@/utils/datetime';
import { csv } from '@/utils/exportfile';
import { createMessage } from '@/redux/modules/message';

import _ from 'lodash';

class FiveSPlanRecordsContainer extends Component {

    static propTypes = {
        checklists: PropTypes.object
    };

    constructor(props) {
        super(props)
        this.state = {
            search: {
                dtlId: undefined,
                userId: undefined,
                dptId: undefined,
                result: 'false', // 分值情况下, 1次以上即为不合格
                offset: 0,
                size: 5,
                sort: "-record_time"
            }
        }
    }

    componentDidMount() {
        const { match } = this.props;
        let dtlId = match.params.dtlId;
        this.setState({
            search: {
                ...this.state.search,
                dtlId: dtlId
            }
        });
        this.props.onhandleInitShowRecords(dtlId);
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'userId':
                newSearch = {
                    ...this.state.search,
                    userId: value.target.value
                };
                break;
            case 'dptId':
                newSearch = {
                    ...this.state.search,
                    dptId: value.target.value
                };
                break;
            case 'result':
                newSearch = {
                    ...this.state.search,
                    result: value.target.value
                };
                break;
            default:
            // do nothing
        }
        this.setState({ search: newSearch });
        this.props.onhandleSearch({ ...newSearch });
    }

    handlePageChange = (value) => {
        let newSearch = {
            ...this.state.search,
            ...value
        };
        this.setState({ search: newSearch });
    };

    handleListMapping = (fieldValue, field) => {
        let value = fieldValue;
        const {
            companies,
            departments,
            inspectionDict,
            checkUserList
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldValue }) ? _.find(companies, { 'companyId': fieldValue }).companyName : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldValue }) ? _.find(departments, { 'dptId': fieldValue }).companyDptName : '';
                break;
            case 'listType':
            case 'category':
            case 'severity':
            case 'rootCause':
            case 'discipline':
                value = _.find(inspectionDict, { 'code': fieldValue }) ? _.find(inspectionDict, { 'code': fieldValue }).name : '';
                break;
            case 'user':
                const user = _.find(checkUserList, { 'userId': fieldValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'result':
                value = '0次'
                if (fieldValue && fieldValue !== '') {
                    value = fieldValue + '次'
                }
                break;
            case 'status':
                if (fieldValue === 'done') {
                    value = '已完成'
                } else if (fieldValue === 'active') {
                    value = '进行中'
                } else if (fieldValue === 'finalized') {
                    value = '未启动'
                } else if (fieldValue === 'invalid') {
                    value = '过期'
                } else {
                    value = '失效'
                }
                break;
            case 'recordStatus':
                if (!fieldValue.totalCount || fieldValue.totalCount === 0) {
                    value = '无效记录';
                    break;
                }
                if (fieldValue.status === 'done' && fieldValue.recordedCount === fieldValue.totalCount) {
                    value = '已完成'
                } else if (fieldValue.status !== 'done' && fieldValue.recordedCount === fieldValue.totalCount) {
                    value = '待结束'
                } else if (fieldValue.status === 'new' && fieldValue.recordedCount !== fieldValue.totalCount) {
                    // 个人进度未满时 不显示任何信息
                    // value = '进行中'
                    value = ''
                }
                break;
            default:
            // do nothing
        }
        return value;
    }

    getUsersByPlanResults = (userList) => {
        const { planResults } = this.props;
        if (planResults && planResults.length) {
            return _.filter(userList, (o) => {
                return _.findIndex(planResults, { 'userId': o.userId }) > -1;
            })
        }
        return userList;
    }

    getDepartmentByPlanCompany = (dptList) => {
        const { currentPlanDetail } = this.props;
        const companyId = currentPlanDetail.companyId;
        if (companyId) {
            return _.filter(dptList, (dpt) => {
                return dpt.companyId === companyId && dpt.executable === false && !dpt.pid && dpt.amoebaType !== 'OTHER'
            })
        }
        return dptList;
    }

    getDepartmentByPlanDetail = (dptList) => {
        const { currentPlanDetail } = this.props;
        const planDpts = currentPlanDetail.inspectionDptList;
        if (planDpts && planDpts.length) {
            return _.filter(dptList, (o) => {
                return _.findIndex(planDpts, { 'dptId': o.dptId }) > -1;
            })
        }
        return dptList;
    }

    handleBack = (event) => {
        this.props.onhandleBackToFiveSPlanList();
    };

    handleDownload = (event, inspectRecord) => {
        const {
            currentPlanDetail,
            onhandleOpenHazardDocs,
            inspectionDict
        } = this.props;
        const {
            docs
        } = inspectRecord;
        if (docs && docs.length) {
            const promiseArr = [];
            docs.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.inspection.hazard-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docs.forEach((doc, index) => {
                    doc.url = res[index].payload;
                    let planName = currentPlanDetail.inspectionPlan.planName;
                    if (currentPlanDetail.inspectionPlan.planType === 'TEMP') {
                        planName = currentPlanDetail.inspectionPlan.year + planName;
                    }
                    let inspectTypeStr = this.handleListMapping(currentPlanDetail.listType, 'listType');
                    let categoryStr = null;
                    let inspectFullStr = inspectTypeStr;
                    if (_.find(inspectionDict, { 'pcode': this.props.currentPlanDetail.listType })) {
                        categoryStr = this.handleListMapping(currentPlanDetail.inspectType, 'listType');
                        inspectFullStr = inspectTypeStr + '(' + categoryStr + ')';
                    }
                    let serialNo = inspectRecord.checklist.serialNo;
                    let recordTimeStr = getDateByTimestamp(inspectRecord.recordTime);
                    doc.downloadName = [
                        planName,
                        inspectFullStr,
                        serialNo,
                        this.handleListMapping(inspectRecord.inspectionResult.dptId, 'department'),
                        this.handleListMapping(inspectRecord.inspectionResult.userId, 'user'),
                    ].join('_') + '_' + recordTimeStr
                        + '.' + doc.docName.split('.').pop().toLowerCase(); // 后缀名
                })
                onhandleOpenHazardDocs({
                    docs: docs,
                    title: '隐患记录附件'
                });
            }).catch(err => {

            });
        }
    }

    onhandleExportDptCsv = () => {
        this.props.onhandleGetPlanRecordsForCsv({
            dtlId: this.state.search.dtlId,
            result: 'false',
            offset: 0,
            size: 0,
            sort: "-record_time"
        }, res => {
            this.generateDptCsvData(res);
        });
    }

    generateDptCsvData = (planRecords) => {
        const {
            currentPlanDetail,
            exportCsv,
            onhandleCreateMessage
        } = this.props;
        const scoreDepartments = this.getDepartmentByPlanCompany(this.props.departments)

        if (currentPlanDetail && Object.keys(currentPlanDetail).length > 0
            && planRecords && Object.keys(planRecords).length > 0 && planRecords.list.length > 0) {

            const startTimeStr = currentPlanDetail.startTime ? moment()((currentPlanDetail.startTime)).format('YYYYMMDD') : '';
            let fileName = ['职能部门月度考核达标部室分值统计'].join('_') + '_' + startTimeStr;
            let columns = ['部门', '5S检查考核统计', '考勤管理考核统计', '综合得分'];
            let dataList = [];

            if (scoreDepartments && scoreDepartments.length) {
                scoreDepartments.forEach(department => {
                    let score = 100;
                    let fiveSScore = 0;
                    let attendanceCount = 0;
                    planRecords.list.forEach(record => {
                        if (record.dptId === department.dptId) {
                            if (record.result && record.result !== '' && record.result !== '0') {
                                let count = _.toSafeInteger(record.result);
                                if (count > 0 && record.checklist) {
                                    const deduction = record.checklist.score * count;
                                    score = score - deduction;
                                    if (record.checklist.resultType === 'SCORE') fiveSScore += deduction;
                                    if (record.checklist.resultType === 'SUM_SCORE') attendanceCount += deduction;
                                }
                            }
                        }
                    })
                    if (score < 0) score = 0;

                    let data = [
                        department.dptName,
                        fiveSScore > 0 ? '-' + fiveSScore : 0,
                        attendanceCount > 0 ? '-' + attendanceCount : 0,
                        score
                    ];
                    dataList.push(data);
                })
                exportCsv(fileName, columns, dataList);
                onhandleCreateMessage('success', '导出检查记录成功');
            }
        }
    }

    onhandleExportRecordCsv = () => {
        this.props.onhandleGetPlanRecordsForCsv({
            dtlId: this.state.search.dtlId,
            result: 'false',
            offset: 0,
            size: 0,
            sort: "-record_time"
        }, res => {
            this.generateRecordCsvData(res);
        });
    }

    generateRecordCsvData = (planRecords) => {
        const {
            currentPlanDetail,
            exportCsv,
            onhandleCreateMessage
        } = this.props;

        if (currentPlanDetail && Object.keys(currentPlanDetail).length > 0
            && planRecords && Object.keys(planRecords).length > 0 && planRecords.list.length > 0) {

            let planName = currentPlanDetail.inspectionPlan.planName;
            if (currentPlanDetail.inspectionPlan.planType === 'TEMP') {
                planName = currentPlanDetail.inspectionPlan.year + planName;
            }

            const startTimeStr = currentPlanDetail.startTime ? moment()((currentPlanDetail.startTime)).format('YYYYMMDD') : '';
            let fileName = ['5S排查实施记录'].join('_') + '_' + startTimeStr;
            let columns = ['序号', '计划名称', '记录时间', '受检单位', '发生部门', '发现人', '违规内容', '发现次数', '备注'];
            let dataList = [];
            planRecords.list.forEach(planRecord => {
                let recordTimeStr = planRecord.recordTime ? getDateByTimestamp(planRecord.recordTime, true) : '';
                let data = [
                    planRecord.checklist.serialNo,
                    planName,
                    recordTimeStr,
                    this.handleListMapping(currentPlanDetail.companyId, 'company'),
                    this.handleListMapping(planRecord.dptId, 'department'),
                    planRecord.inspectionResult.userName || '-',
                    planRecord.checklist.problem,
                    this.handleListMapping(planRecord.result, 'result'),
                    planRecord.opentext
                ];
                dataList.push(data);
            });
            exportCsv(fileName, columns, dataList);
            onhandleCreateMessage('success', '导出检查记录成功');
        }
    }

    render() {
        return (
            <FiveSPlanRecords
                currentPlanDetail={this.props.currentPlanDetail}
                lists={this.props.planRecords}
                planResults={this.props.planResults}
                checkUserList={this.getUsersByPlanResults(this.props.checkUserList)}
                scoreDepartments={this.getDepartmentByPlanCompany(this.props.departments)}
                departmentList={this.getDepartmentByPlanDetail(this.props.departments)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onDownload={this.handleDownload.bind(this)}
                onhandleGetPlanRecords={this.props.onhandleGetPlanRecords}
                onhandleListMapping={this.handleListMapping.bind(this)}
                search={this.state.search}
                exportDptCsv={this.onhandleExportDptCsv.bind(this)}
                exportRecordCsv={this.onhandleExportRecordCsv.bind(this)}
                onhandlePageChange={this.handlePageChange.bind(this)}
            />
        )
    }
}

// const mapPlanRecords = (planResults) => {
//     const records = [];
//     if(planResults.list) {
//         planResults.list.forEach(planResult => {
//             planResult.
//         })
//     }
// }

const mapStateToProps = (state) => {
    return {
        users: state.user.leaders,
        categoryList: state.inspection.categoryList,
        companies: state.constant.companies,
        departments: state.constant.departments,
        inspectionDict: state.constant.inspectionDictionaries,
        currentPlanDetail: state.inspectionPlan.currentPlanDetail,
        checkUserList: state.inspectionPlanMonthly.checkUserList,
        planResults: state.inspectionPlanMonthly.planResults,
        planRecords: state.inspectionPlanMonthly.planRecords,

        userProfile: state.auth.userProfile
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSearch: (query) => {
            dispatch(getPlanRecords(query));
        },
        onhandleInitShowRecords: (dtlId) => {
            dispatch(initFiveSRecords(dtlId));
        },
        onhandleGetPlanRecords: (query) => {
            dispatch(getPlanRecords(query))
        },
        onhandleGetPlanRecordsForCsv: (query, callback) => {
            dispatch(getPlanRecordsForCsv(query, callback))
        },
        exportCsv: (fileName, columns, data) => {
            dispatch(csv(fileName, columns, data))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleBackToFiveSPlanList: () => {
            dispatch(backToFiveSPlanList())
        },
        onhandleOpenHazardDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiveSPlanRecordsContainer)
