import React, { Component } from 'react';
import { connect } from 'react-redux';
import HWInstockComponent from '@/components/ManufactureManagement/HWInstock';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getHwList } from '@/redux/modules/manufacture/manufactureHWInstock';

class HWInstockContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const inDate = formatDate(getMoment()());
        this.props.getHwList(inDate, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const inDate = formatDate(date);
            this.props.getHwList(inDate, undefined);
        })
    }

    render() {
        return (
            <div>
                <HWInstockComponent
                    selectedDate={this.state.selectedDate}
                    constant={this.props.constant}
                    hwList={this.props.hwList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        hwList: state.manufactureHWInstock.hwList,
        userInfo: state.auth.currentUserInfo,
        constant: state.constant,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getHwList: (inDate, status) => {
            dispatch(getHwList(inDate, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HWInstockContainer);
