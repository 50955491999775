import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Card,
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import { Add, DeleteOutlined, Edit } from "@material-ui/icons";
import { getNameByTypeAndCode } from '../../../../utils/constant';
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';

const BuList = props => {
    const {
        classes,
        bus,
        getBus,
        onDeleteInfo,
        onDetailedInfo,
        constants,
        userProfile,
        onhandleCreate
    } = props;

    const [selectedRow, setSelectedRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [page, setPage] = useState(0);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'bu_management' })
        return permission && permission.action === 'W'
    }

    const renderTime = (data) => {
        return data.updateTime ? getDateByTimestamp(data.updateTime, true) : getDateByTimestamp(data.createTime, true)
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'unitName',
                render: row => row ? <Typography>{row.unitName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{getNameByTypeAndCode(constants, 'STATUS', row.status)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <Card className={classes.card}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'业务分类列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={7}>



                </Grid>

                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={5}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={5}>
                        {/**/}
                    </Grid>

                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={7}>
                        {checkPermission() && <React.Fragment>
                            <Button variant='contained'
                                color='primary'
                                onClick={(event) => onhandleCreate(event)}
                                startIcon={<Add />}
                            >
                                新建
                            </Button>
                        </React.Fragment>

                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={bus.list}
                    options={{
                        ...options,
                        pageSize: rowsPerPage,
                        tableLayout: 'auto',
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor: selectedRow === rowData.unitId ? '#EEEEEE' : 'FFFFFF'
                        })
                    }}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[15, 30, 50]}
                                rowsPerPage={rowsPerPage}
                                count={bus.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getBus({
                                        offset: pageNum * rowsPerPage,
                                        size: rowsPerPage
                                    })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setRowsPerPage(event.target.value);
                                    setPage(0);
                                    getBus({
                                        offset: 0,
                                        size: event.target.value
                                    })
                                }}
                            />
                        ),
                    }}
                    actions={checkPermission() ?
                        [rowData => ({
                            icon: () => <Edit color={'primary'} />,
                            tooltip: '编辑',
                            onClick: (event, rowData) => {
                                onDetailedInfo(event, rowData.unitId)
                            }
                        }),
                        rowData => ({
                            icon: () => <DeleteOutlined className={classes.menuIcon} />,
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onDeleteInfo(event, rowData.unitId, rowData.unitName)
                            }
                        }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '添加业务分类',
                            //     isFreeAction: true,
                            //     onClick: (event) => onhandleCreate(event)
                            // }
                        ] : [
                            rowData => ({
                                icon: 'description',
                                tooltip: '查看',
                                onClick: (event, rowData) => {
                                    // onView(event, rowData.userId)
                                }
                            })
                        ]}
                >
                </MaterialTable>
            </Grid>
        </Card>
    );
};

BuList.propTypes = {
    classes: PropTypes.object.isRequired,
    bus: PropTypes.object.isRequired,
    getBus: PropTypes.func.isRequired,
    onDeleteInfo: PropTypes.func.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired
};

export default withStyles(BuList);

