import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message'


const porxyApi = '/api'

const initialState = {
    goal: {},
    goalList: [],
    dptGoalDetails: [],
    isEditableDptGoalDetails: false,
    isEditableGoal: false,
    dptGoalDetailSelectedObject: {},
    childCompanies: [],
    childDepartments: [],
    ancestorCompanies: [],
    currentGoal: { goal: {}, goalDetails: [] },
    currentGoalPlan: { goal: {}, goalDetails: [] },
};


export const cleanGoal = createAction('@@xcloud/goals/cleanGoal');

export const setDptGoalDetails = createAction('@@xcloud/dptgoals/setdetails');
export const setIsEditableDptGoalDetails = createAction('@@xcloud/dptgoals/setiseditable');
export const setDptGoalDetailSelected = createAction('@@xcloud/dptgoals/setselectedobject');
export const setIsEditableUnitGoalDetail = createAction('@@xcloud/goal/setiseditableunitgoaldetail')

export const cleanGoalDetails = createAction('@@xcloud/goals/cleangoaldetails');
export const setGoalDetails = createAction('@@xcloud/goals/setgoaldetails')
export const setUnitGoalDetailSelected = createAction('@@xcloud/goals/setunitgoaldetailselected');

export const setGoalList = createAction('@@xcloud/goals/setgoallist')

export const setEditGoal = createAction('@@xcloud/goal/seteditgoal')
export const setIsEditableGoalDetail = createAction('@@xcloud/goal/setiseditableGoalDetail')

export const setGoal = createAction('@@xcloud/goal/setgoal')
export const setCurrentGoal = createAction('@@xcloud/goal/setcurrentgoal')
export const setCurrentGoalPlan = createAction('@@xcloud/goal/setcurrentgoalplan')

export const setChildCompanies = createAction('@@xcloud/setchildcompanies')
export const setChildDepartments = createAction('@@xcloud/setchilddepartments')
export const setAncestorCompanies = createAction('@@xcloud/setancestorcompanies')


export const executeProcess = createAction('@@xcloud/goal/excuteprocess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/goal/process', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行安全生产目标流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行安全生产目标流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const goalApprove = createAction('@@xcloud/goal/approve',
    (goalId) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/goals/'+goalId+'/approve', {}, { headers: { need_loading: true } })
            .then((res) => {
                if (res) dispatch(createhttpMessage(res, "企业目标审批成功"))
            }).catch(err => {
                let msg = `企业目标审批失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getGoalList = createAction('@@xcloud/goals/goallist',
    (year, status, callback) => (dispatch, getState, httpClient) => {
        let query = ''
        if (year || status) {
            let strs = []
            if (year)
                strs.push('year=' + year)
            if (status)
                strs.push('status=' + status)
            query = '?' + strs.join('&')
        }
        httpClient.get(porxyApi + '/goals' + query)
            .then((res) => {
                if (res) {
                    dispatch(setGoalList(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `安全生产目标列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });


export const setGoalAttribute = createAction('@@xcloud/goal/setgoal',
    (attr, attrValue) => (dispatch, getState, httpClient) => {
        // console.log(attr + '+' + attrValue)
        // console.log(getState())
        let g = getState().leadershipGoal.goal
        g[attr] = attrValue
        return g
        // let goalTemp = getState().leadershipGoal.goal
        // goalTemp[name] = value
        //dispatch(setGoal(goalTemp))
    });

export const addGoal = createAction('@@xcloud/goals/addgoal',
    (goal, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/goals', goal, { headers: { need_loading: true } })
            .then((res) => {
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `增加安全生产目标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateGoal = createAction('@@xcloud/goals/updategoal',
    (goal, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/goals', goal)
            .then((res) => {
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新安全生产目标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getGoal = createAction('@@xcloud/goals/getgoal',
    (goalId, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/goals/' + goalId)
            .then((res) => {
                if (res) {
                    dispatch(setGoal(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `安全生产目标列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getCurrentGoal = createAction('@@xcloud/goals/getcurrrentgoal',
    (callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/goals/current')
            .then((res) => {
                if (res) {
                    dispatch(setCurrentGoal(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `当前安全生产目标获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getCurrentGoalPlan = createAction('@@xcloud/goals/getcurrrentgoalplan',
    (callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/goals/currentplan')
            .then((res) => {
                if (res) {
                    dispatch(setCurrentGoalPlan(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `当前安全生产目标获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const addDptGoalDetails = createAction('@@xcloud/dptgoals/details',
    (dptGoalDetails, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/dptgoals/details', dptGoalDetails)
            .then((res) => {
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新部门安全生产目标明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateDptGoalDetails = createAction('@@xcloud/dptgoals/details',
    (dptGoalDetails, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/dptgoals/details', dptGoalDetails)
            .then((res) => {
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新部门安全生产目标明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getDptGoalDetails = createAction('@@xcloud/dptgoals/details',
    (goalId, dptId, unitId, callback) => (dispatch, getState, httpClient) => {
        if (!dptId && !unitId)
            return
        let url = porxyApi + '/goals/' + goalId + '/dptgoals/details?'
        if (dptId)
            url = url + 'dptId=' + dptId
        if (unitId) {
            if (dptId)
                url = url + '&unitId=' + unitId
            else
                url = url + 'unitId=' + unitId
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setDptGoalDetails(res.data))
                    if (callback) {
                        callback(res.data)
                    }
                }
            })
            .catch(err => {
                let msg = `部门安全生产目标详细列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addGoalDetails = createAction('@@xcloud/goals/addgoaldetails',
    () => (dispatch, getState, httpClient) => {


    })

export const deleteGoalDetail = createAction('@@xcloud/goals/deletegoaldetail',
    (dtlId) => (dispatch, getState, httpClient) => {

    })

export const updateGoalDetails = createAction('@@xcloud/goals/updategoaldetails',
    (goalDetails, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/goals/details', goalDetails)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新企业生产目标明细成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新企业生产目标明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })

    })
export const getGoalDetails = createAction('@@xcloud/goals/getgoaldetails',
    (goalId, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/goals/' + goalId + '/details')
            .then((res) => {
                if (res)
                    dispatch(setGoalDetails(res.data))
                if (callback)
                    callback(res.data)
            })
            .catch(err => {
                let msg = `安全生产目标详细信息获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getChildCompanies = createAction('@@xcloud/getchildcompanies',
    () => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/childcompanies?childType=ALL')
            .then((res) => {
                if (res)
                    dispatch(setChildCompanies(res.data))
                // if (callback)
                //     callback(res.data)
            })
            .catch(err => {
                let msg = `公司下级部门获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getChildDepartments = createAction('@@xcloud/getchilddepartments',
    (childType, executable) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/childdepartments?childType=' + childType + '&executable=' + executable)
            .then((res) => {
                if (res)
                    dispatch(setChildDepartments(res.data))
                // if (callback)
                //     callback(res.data)
            })
            .catch(err => {
                let msg = `公司下级部门获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getAncestorCompanies = createAction('@@xcloud/getancerstorcompanies',
    () => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/ancestorcompanies?ancestorType=ROOT')
            .then((res) => {
                if (res)
                    dispatch(setAncestorCompanies(res.data))
                // if (callback)
                //     callback(res.data)
            })
            .catch(err => {
                let msg = `上级公司获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });



export const LeadershipGoalReducer = handleActions(
    {
        [cleanGoal]: (state) => {
            return {
                ...state,
                goal: {}
            }
        },
        [setGoalList]: (state, { payload: data }) => {
            return {
                ...state,
                goalList: data
            }
        },

        [setEditGoal]: (state, { payload: b }) => {
            return {
                ...state,
                isEditableGoal: b
            }
        },
        [setIsEditableGoalDetail]: (state, { payload: b }) => {
            return {
                ...state,
                isEditableGoalDetail: b
            }
        },
        [setIsEditableUnitGoalDetail]: (state, { payload: b }) => {
            return {
                ...state,
                isEditableUnitGoalDetail: b
            }
        },
        [setGoal]: (state, { payload: goal }) => {
            return {
                ...state,
                goal: goal
            }
        },

        [setCurrentGoal]: (state, { payload: goal }) => {
            return {
                ...state,
                currentGoal: goal
            }
        },

        [setCurrentGoalPlan]: (state, { payload: plan }) => {
            return {
                ...state,
                currentGoalPlan: plan
            }
        },

        [setGoalAttribute]: (state, { payload: goal }) => {
            return {
                ...state,
                goal: goal
            }
        },
        [cleanGoalDetails]: (state) => {
            return {
                ...state,
                goalDetails: []
            }
        },
        [setGoalDetails]: (state, { payload: goalDetails }) => {
            return {
                ...state,
                goalDetails: goalDetails
            }
        },

        [setDptGoalDetails]: (state, { payload: dptGoalDetails }) => {
            return {
                ...state,
                dptGoalDetails: dptGoalDetails
            }
        },
        [setIsEditableDptGoalDetails]: (state, { payload: b }) => {
            return {
                ...state,
                isEditableDptGoalDetails: b
            }
        },
        [setDptGoalDetailSelected]: (state, { payload: selectedObject }) => {
            return {
                ...state,
                dptGoalDetailSelectedObject: selectedObject
            }
        },
        [setUnitGoalDetailSelected]: (state, { payload: selectedObject }) => {
            return {
                ...state,
                unitGoalDetailSelectedUnitId: selectedObject
            }
        },

        [setChildDepartments]: (state, { payload: data }) => {
            return {
                ...state,
                childDepartments: data
            }
        },

        [setChildCompanies]: (state, { payload: data }) => {
            return {
                ...state,
                childCompanies: data
            }
        },
        [setAncestorCompanies]: (state, { payload: data }) => {
            return {
                ...state,
                ancestorCompanies: data
            }
        },



        // [initMenu]: (state, { payload: data }) => {
        //     return {
        //         ...state,
        //         menuNaviList: data
        //     }
        // }

    },
    initialState
)

export default LeadershipGoalReducer

