import React, { Component } from 'react';
import { connect } from 'react-redux';
import FiveSPlanEdit from '../../../components/InspectionManagement/FiveSPlan/FiveSPlanEdit'
import {
    backToFiveSPlanList,
    getPlanResults,
    addPlanResult,
    deletePlanResult,
    clearPlanResults
} from "@/redux/modules/inspection/inspectionPlanMonthly";
import {
    startPlanDetails,
    getPlanDetailById,
    initFiveSDetailEdit,
    updatePlanDetails,
    setCurrentPlanDetail,
    setPlanDetailError,
    setPlanDetailChange
} from "@/redux/modules/inspection/inspectionPlan";

import { getMoment as moment } from '@/utils/datetime';
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { createMessage } from '@/redux/modules/message';

class FiveSPlanEditContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    static propTypes = {
        currentPlanDetail: PropTypes.object,
        planDetailError: PropTypes.object,
        planDetailChange: PropTypes.bool,
        companyTree: PropTypes.array,
        departmentList: PropTypes.array,
        listTypeList: PropTypes.array,
        categoryList: PropTypes.array,
        severityList: PropTypes.array,
        rootCauseList: PropTypes.array,
        unitList: PropTypes.array,
        methodList: PropTypes.array,
        disciplineList: PropTypes.array,
        resultTypeList: PropTypes.array
    };

    UNSAFE_componentWillMount() {
        const { match } = this.props;
        let dtlId = match.params.dtlId;

        this.props.onhandleInit(dtlId);
    }

    // 手动启动计划
    handleStart = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.planDetailError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandlePlanDetailChange(false);
            if (!this.props.planResults || !this.props.planResults.length || this.props.planResults.length <= 0) {
                this.props.onhandleErrorMessage('请指定部门检查人');
                return;
            }
            if (this.props.currentPlanDetail.dtlId) {
                this.props.onhandleStartPlanDetails({ dtlId: this.props.currentPlanDetail.dtlId }, () => {
                    this.props.onhandleGetPlanDetailById(this.props.currentPlanDetail.dtlId);
                });
            }
        }
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.planDetailError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandlePlanDetailChange(false);
            if (this.props.currentPlanDetail.dtlId) {
                const currentPlanDetail = { ...this.props.currentPlanDetail };
                this.props.onhandleUpdatePlanDetail(currentPlanDetail, () => {
                    this.props.onhandleGetPlanDetailById(this.props.currentPlanDetail.dtlId);
                });
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandlePlanDetailChange(true);
        let newPlanDetail;
        switch (field) {
            case 'opentext':
                newPlanDetail = {
                    ...this.props.currentPlanDetail,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditPlanDetail(newPlanDetail);
                break;
            default:
            // do nothing
        }
    };

    handleSelect = (value, field) => {
        let newSelect;
        // 排查类型、公司和部门在月度计划中的变更没有必要
        switch (field) {
            case 'listType':
                // newSelect = {
                //     ...this.props.currentPlanDetail,
                //     listType: value.target.value,
                //     inspectType: value.target.value,
                //     catCode: ''
                // };
                // this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'category':
                // if (value.target.value !== '') {
                //     newSelect = {
                //         ...this.props.currentPlanDetail,
                //         catCode: value.target.value,
                //         inspectType: value.target.value
                //     };
                //     this.props.onhandleUpdateEditPlanDetail(newSelect);
                // }
                break;
            case 'company':
                // newSelect = {
                //     ...this.props.currentPlanDetail,
                //     companyId: parseInt(value.target.value),
                //     inspectionDptStrList: [],
                //     inspectionDptList: []
                // };
                // this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'department':
                // const inspectionDptList = [];
                // value.target.value.forEach(item => {
                //     inspectionDptList.push({ dptId: item, dtlId: this.props.currentPlanDetail.dtlId })
                // })
                // newSelect = {
                //     ...this.props.currentPlanDetail,
                //     inspectionDptStrList: value.target.value,
                //     inspectionDptList: inspectionDptList
                // };
                // this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'startTime':
                this.props.onhandlePlanDetailChange(true);
                newSelect = {
                    ...this.props.currentPlanDetail,
                    startTime: moment()(value).startOf('day').valueOf()
                };
                this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'inspectTime':
                this.props.onhandlePlanDetailChange(true);
                newSelect = {
                    ...this.props.currentPlanDetail,
                    inspectTime: moment()(value).endOf('day').valueOf()
                };
                this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'inspectHead':
                this.props.onhandlePlanDetailChange(true);
                newSelect = {
                    ...this.props.currentPlanDetail,
                    inspectHead: value ? value.userId : null
                };
                this.props.onhandleUpdateEditPlanDetail(newSelect);
                break;
            case 'responsibleUser':
                // 独立处理部门检查人
                const {
                    users,
                    dptId,
                    reason
                } = value;
                const {
                    currentPlanDetail,
                    planResults,
                    onhandleAddPlanResult,
                    onhandleClearPlanResult,
                    // onhandleAddActivity,
                    onhandleGetPlanResults
                } = this.props;
                if (reason === 'select-option') {
                    // insert last option
                    if (users.length > 0) {
                        const newUser = users[users.length - 1];
                        const result = _.find(planResults, { 'dptId': dptId, 'userId': newUser.userId })
                        if (!result) {
                            onhandleAddPlanResult({
                                dtlId: currentPlanDetail.dtlId,
                                userId: newUser.userId,
                                dptId: dptId
                            }, () => {
                                onhandleGetPlanResults(currentPlanDetail.dtlId)
                            });
                        }
                    }
                } else if (reason === 'clear') {
                    onhandleClearPlanResult(currentPlanDetail.dtlId, dptId, () => { onhandleGetPlanResults(currentPlanDetail.dtlId) });
                } else if (reason === 'remove-option') {
                    // see 'handleTagDelete()'
                }
                break;
            default:
            // do nothing
        }
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleTagDelete = (dptId, userId) => {
        const {
            planResults,
            currentPlanDetail,
            onhandleGetPlanResults,
            onhandleDeletePlanResult
        } = this.props;
        if (planResults) {
            const deleteResult = _.find(planResults, { dptId: dptId, userId: userId })
            if (deleteResult) {
                onhandleDeletePlanResult(deleteResult.resultId, () => {
                    onhandleGetPlanResults(currentPlanDetail.dtlId)
                })
            } else {

            }
        }
    }

    handleBack = (event) => {
        this.props.onhandleBackToFiveSPlanList();
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'inspectHead') {
            if (!this.props.currentPlanDetail.inspectHead) {
                err['inspectHead'] = '负责人不能为空';
            } else {
                err['inspectHead'] = '';
            }
        }

        if (!field || field === 'startTime') {
            if (!this.props.currentPlanDetail.startTime || this.props.currentPlanDetail.startTime === '') {
                err['startTime'] = '开始时间不能为空';
            } else {
                const { startTime } = this.props.currentPlanDetail;
                if (moment()(startTime).isBefore(moment()().startOf('day'))) {
                    err['startTime'] = '不能早于当前日期';
                } else {
                    err['startTime'] = '';
                }
            }
        }

        if (!field || field === 'inspectTime') {
            if (!this.props.currentPlanDetail.inspectTime || this.props.currentPlanDetail.inspectTime === '') {
                err['inspectTime'] = '截止时间不能为空';
            } else {
                const { inspectTime } = this.props.currentPlanDetail;
                if (moment()(inspectTime).isBefore(moment()().endOf('day'))) {
                    err['inspectTime'] = '不能早于当前日期';
                } else {
                    err['inspectTime'] = '';
                }
            }
        }

        if (this.props.currentPlanDetail.startTime && this.props.currentPlanDetail.inspectTime) {
            if (
                moment()(this.props.currentPlanDetail.inspectTime).isBefore(moment()(this.props.currentPlanDetail.startTime))
            ) {
                err['inspectTime'] = '不能早于开始日期';
            } else {
                err['inspectTime'] = '';
            }
        }

        let newError = {
            ...this.props.planDetailError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            departments,
            bUnits,
            inspectionDict,
            users,
            leaders
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyAlias : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldId }) ? _.find(departments, { 'dptId': fieldId }).companyDptName : '';
                break;
            case 'unit':
                value = _.find(bUnits, { 'unitId': fieldId }) ? _.find(bUnits, { 'unitId': fieldId }).unitName : '';
                break;
            case 'listType':
            case 'category':
            case 'severity':
            case 'rootCause':
            case 'method':
            case 'discipline':
            case 'resultType':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '';
                break;
            case 'user':
                value = '';
                const user = _.find(users.list, { 'userId': parseInt(fieldId) });
                if (user) {
                    value = user.lastName + user.firstName;
                }
                break;
            case 'userShow':
                value = '无'
                const valueList = [];
                fieldId.forEach(fieldValue => {
                    const user = _.find(users.list, { 'userId': parseInt(fieldValue) });
                    if (user) {
                        valueList.push(user.lastName + user.firstName);
                    }
                })
                if (valueList.length > 0)
                    value = valueList.join(', ');
                break;
            case 'leader':
                value = '';
                const leader = _.find(leaders, { 'userId': parseInt(fieldId) });
                if (leader) {
                    value = leader.lastName + leader.firstName;
                }
                break;
            default:
            // do nothing
        }
        return value;
    }

    render() {
        const { currentPlanDetail, inspectionDict, inspectionDptMapping } = this.props;
        let showCategory = false;
        if (currentPlanDetail && _.find(inspectionDict, { 'pcode': currentPlanDetail.listType })) {
            showCategory = true;
        }
        let planStarted = ['active', 'done', 'invalid'].includes(currentPlanDetail.status);
        let isEditor = _.findIndex(inspectionDptMapping, { 'inspectionCode': currentPlanDetail.inspectType && currentPlanDetail.inspectType.substr(0, 5) }) > -1 || this.props.currentPlanDetail.inspectHead === this.props.userProfile.userId;

        return (
            <FiveSPlanEdit
                readOnly={planStarted || !isEditor}
                showCategory={showCategory}
                onhandleSave={this.handleSave.bind(this)}
                onhandleStart={this.handleStart.bind(this)}
                currentPlanDetail={this.props.currentPlanDetail}
                currentPlanResults={this.props.planResults}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleTagDelete={this.handleTagDelete.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                planDetailError={this.props.planDetailError}
                planDetailChange={this.props.planDetailChange}
                users={this.props.users}
                leaders={this.props.leaders}
                companyList={this.props.companyList}
                departments={this.props.departments}
                departmentList={this.props.departmentList}
                listTypeList={this.props.listTypeList}
                categoryList={this.props.categoryList}
                onhandleListMapping={this.handleListMapping.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        leaders: state.user.leaders,
        users: state.user.users,
        companies: state.constant.companies,
        departments: state.constant.departments,
        userProfile: state.auth.userProfile,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        inspectionDict: state.constant.inspectionDictionaries,
        planResults: state.inspectionPlanMonthly.planResults,
        companyList: state.inspectionPlanMonthly.companyList,
        currentPlanDetail: state.inspectionPlan.currentPlanDetail,
        planDetailError: state.inspectionPlan.planDetailError,
        planDetailChange: state.inspectionPlan.planDetailChange,
        departmentList: state.inspection.departmentList,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInit: (dtlId) => {
            dispatch(initFiveSDetailEdit(dtlId))
        },
        onhandleUpdatePlanDetail: (entity, callback) => {
            dispatch(updatePlanDetails(entity.dtlId, entity, callback))
        },
        onhandleStartPlanDetails: (query, callback) => {
            dispatch(startPlanDetails(query, callback))
        },
        onhandleUpdateEditPlanDetail: (entity) => {
            dispatch(setCurrentPlanDetail(entity))
        },
        onhandleGetPlanDetailById: (dtlId) => {
            dispatch(getPlanDetailById(dtlId))
        },
        onhandleGetPlanResults: (dtlId) => {
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            })
            )
        },
        onhandleAddPlanResult: (planResult, callback) => {
            dispatch(addPlanResult(planResult, callback))
        },
        onhandleDeletePlanResult: (resultId, callback) => {
            dispatch(deletePlanResult(resultId, callback))
        },
        onhandleClearPlanResult: (dtlId, dptId, callback) => {
            dispatch(clearPlanResults(dtlId, dptId, callback))
        },
        onhandleBackToFiveSPlanList: () => {
            dispatch(backToFiveSPlanList())
        },
        onhandleSetError: (err) => {
            dispatch(setPlanDetailError(err))
        },
        onhandlePlanDetailChange: (isChanged) => {
            dispatch(setPlanDetailChange(isChanged))
        },
        onhandleErrorMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiveSPlanEditContainer)
