import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import { formSelectTree } from '../../utils/constant';
import { getTreePartById } from '../../utils/constant';
import useStatusColor from '../../device/src/redux/modules/data/useStatusColor.json';
import * as _ from 'lodash';
const porxyApi = '/api'

const initialState = {
    search: {
        companyId: undefined
    },
    useStatus: [],
    useStatusTree: [],
    useStatusGroup: [],
    load: false,
    useStatusColor: useStatusColor
}

export const initDeviceUseStatus = createAction('@@xcloud/deviceUseStatus/initDeviceUseStatus',
    (myChart) => (dispatch, getState) => {
        Promise.all([
            dispatch(resetSearch()),
            dispatch(getDeviceUseStatus()),
        ]).then((data) => {
            dispatch(getDeviceUseStatusGroup())
        }).catch(err => {
            let msg = '初始化设备状态统计搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getDeviceUseStatus = createAction('@@xcloud/deviceUseStatus/getDeviceUseStatus',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=USESTATUS";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备使用状况获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceUseStatusGroup = createAction(
    '@@xcloud/deviceUseStatus/getDeviceUseStatusGroup',
    (companyId) => (dispatch, getState, httpClient) => {
        let url = "/devices/usestatusgroup"
        if (companyId && companyId !== "") {
            url += "?companyId=" + companyId
        }
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setDeviceUseStatusGroup(res.data));
                    dispatch(setLoad(true));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取设备状态统计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const resetSearch = createAction('@@xcloud/deviceUseStatus/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompantId = null
        let companylist = getState().constant.companies;
        let companyTree = getState().constant.companyTree;
        if (companylist && companylist.length > 0) {
            let selectedCompany = _.find(companylist, ['companyId', company])
            if (selectedCompany) {
                newCompantId = { label: selectedCompany.companyAlias, value: selectedCompany.companyId }
            }
        }
        let tree = getTreePartById(company, companyTree);
        if (!(tree.length === 1 && (!tree[0].children || tree[0].children.length === 0))) {
            newCompantId = undefined
        }

        let search = getState().deviceUseStatus.search;
        let newSearch = {
            ...search,
            companyId: newCompantId ? newCompantId : undefined,
        }

        dispatch(setSearch(newSearch));
    }
);

export const setSearch = createAction('@@xcloud/deviceUseStatus/setSearch');

export const setDeviceUseStatusGroup = createAction('@@xcloud/deviceUseStatus/setDeviceUseStatusGroup');

export const setLoad = createAction('@@xcloud/deviceUseStatus/setLoad');

export const deviceUseStatusReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [getDeviceUseStatus]: (state, { payload: value }) => {
            return {
                ...state,
                useStatus: value.list,
                useStatusTree: value.tree
            };
        },
        [setDeviceUseStatusGroup]: (state, { payload: value }) => {
            return {
                ...state,
                useStatusGroup: value
            };
        },
        [setLoad]: (state, { payload: value }) => {
            return {
                ...state,
                load: value
            };
        },
    },
    initialState
);

export default deviceUseStatusReducer;