import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {
    setPageSize, getWorkOrderList, initWorkList,
    routeToAppointmentCreate, routeBaseOnStatus,
    editWorkOrders,
} from '../../redux/modules/operation';
import { getCompanyId } from "../../utils/userUtils";
import WorkOrderFooterForPopup from '@/components/OperationManagement/WorkOrder/WorkOrderList/WorkOrderFooterForPopup';
import { cleanmodal } from '../../redux/modules/modal';
import WorkOrderListForPopup from '@/components/OperationManagement/WorkOrder/WorkOrderList/WorkOrderListForPopup';

class WorkOrderListForPopupContainer extends Component {
    static propTypes = {
        initListData: PropTypes.object,
        userProfile: PropTypes.object,
        pageSize: PropTypes.number,
        isLoading: PropTypes.bool,
        sourceList: PropTypes.array,
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            routeType: '',
        }
    }

    // 页面渲染完成后......
    componentDidMount() {
        let routeType = '';
        // if (this.props.location.pathname.indexOf("/workorderlist/all") !== -1) {
        //     routeType = 'all';
        // } else if (this.props.location.pathname.indexOf("/workorderlist/needProcess") !== -1) {
        //     routeType = 'needProcess';
        // } else if (this.props.location.pathname.indexOf("/workorderlist/needAppointment") !== -1) {
        //     routeType = 'needAppointment';
        // } else {
        //     routeType = 'popUp';
        // }

        routeType = 'popUp';
        this.setState({
            routeType: routeType
        })

        // 当前运维计划相关联工单 - 初始化
        this.props.onInitWorkList(routeType, this.props.parameters.operationPlan.dtlId);
    }

    handleEditWorkOrders(id) {
        this.props.editWorkOrders(id, this.state.routeType);
    }

    handleRouteToAppointmentCreate(id) {
        this.props.onRouteToAppointmentCreate(id, this.state.routeType);
    }

    handleRouteBaseOnStatus(status) {
        this.props.onhandleRouteBaseOnStatus(status);
    }

    render() {
        return (
            <div>
                <WorkOrderListForPopup
                    initListData={this.props.initListData}
                    companyId={getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)}
                    userProfile={this.props.userProfile}
                    // pageSize={this.props.pageSize}
                    search={this.props.search}
                    setPageSize={this.props.onSetPageSize.bind(this)}
                    getWorkOrderList={this.props.onGetWorkOrderList.bind(this)}
                    isLoading={this.props.isLoading}
                    sourceList={this.props.sourceList}
                    orderTypeList={this.props.orderTypeList}
                    departmentList={this.props.departmentList}
                    companyList={this.props.companyList}
                    onhandleRouteToAppointmentCreate={this.handleRouteToAppointmentCreate.bind(this)}
                    onEditWorkOrders={this.handleEditWorkOrders.bind(this)}
                    routeType={this.state.routeType}
                    onhandleRouteBaseOnStatus={this.handleRouteBaseOnStatus.bind(this)}
                />

                <WorkOrderFooterForPopup
                    onhandleCleanmodal={this.props.onhandleCleanmodal.bind(this)}
                />
            </div >
        )
    }
}

const mapStateToProps = (state) => {

    return {
        initListData: state.operation.workOrderList,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        // pageSize: state.operation.pageSize,
        sourceList: state.operation.sourceList,
        orderTypeList: state.operation.orderTypeList,
        isLoading: state.loading.globalLoading,
        departmentList: state.constant.departments,
        companyList: state.constant.companies,
        search: state.operation.search,
        // 当前运维计划详细
        parameters: state.modal.parameters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetWorkOrderList: (query) => {
            dispatch(getWorkOrderList(query));
        },
        onSetPageSize: (num) => {
            dispatch(setPageSize(num))
        },
        // 当前运维计划关联工单列表 - 初始化
        onInitWorkList: (routeType, sourceId) => {
            dispatch(initWorkList(routeType, sourceId));
        },
        onRouteToAppointmentCreate: (id, routeType) => {
            dispatch(routeToAppointmentCreate(id, routeType));
        },
        editWorkOrders: (id, routeType) => {
            dispatch(editWorkOrders(id, routeType));
        },
        // 关闭弹窗
        onhandleCleanmodal: () => {
            dispatch(cleanmodal());
        },
        // 根据工单状态路由到不同地址
        onhandleRouteBaseOnStatus: (status) => {
            dispatch(routeBaseOnStatus(status));
            // 关闭弹窗
            dispatch(cleanmodal());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrderListForPopupContainer)
