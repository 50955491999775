import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
// import * as _ from 'lodash';
const porxyApi = '/api'

const initialState = {
    deviceGroup: null
}
// Actions
export const getDeviceGroup = createAction(
    '@@xcloud/deviceGroup/getDeviceGroup',
    (id) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devicegroups/${id}`)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取设备组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updateDeviceGroup = createAction(
    '@@xcloud/deviceGroup/updateDeviceGroup',
    (deviceGroupDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "groupId": deviceGroupDto.groupId,
            "groupName": deviceGroupDto.groupName,
            "installId": deviceGroupDto.installId,
            "status": deviceGroupDto.status,
            "tagNo": deviceGroupDto.tagNo,
            "data2d": deviceGroupDto.data2d ? deviceGroupDto.data2d : ''
        }
        return httpClient
            .patch(porxyApi + `/devicegroups/${deviceGroupDto.groupId}`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updateDeviceGroups = createAction(
    '@@xcloud/deviceGroup/updateDeviceGroups',
    (deviceGroupDtos) => (dispatch, getState, httpClient) => {
        dispatch(clearDeviceGroup());
        let dtos = [];
        deviceGroupDtos.forEach(deviceGroupDto => {
            let dto = {
                "groupId": deviceGroupDto.groupId,
                "groupName": deviceGroupDto.groupName,
                "installId": deviceGroupDto.installId,
                "status": deviceGroupDto.status,
                "tagNo": deviceGroupDto.tagNo,
                "data2d": deviceGroupDto.data2d ? deviceGroupDto.data2d : '',
            }
            dtos.push(dto);
        })

        return httpClient
            .patch(porxyApi + `/devicegroups`, dtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const clearDeviceGroup = createAction(
    '@@xcloud/deviceGroup/clearDeviceGroup')
export const deviceGroupReducer = handleActions(
    {
        [getDeviceGroup]: (state, { payload: value }) => {
            return {
                ...state,
                deviceGroup: value
            };
        },
        [clearDeviceGroup]: (state) => {
            return {
                ...state,
                deviceGroup: null
            };
        },
    },
    initialState
);

export default deviceGroupReducer;