import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    TextField,
    TablePagination,
    Card,
    Avatar,
    FormHelperText,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    // FormControlLabel, Checkbox,
    // Select,
} from '@material-ui/core';
import {
    // Add, BlurCircular, BlurOn, Dns, ExpandLess, ExpandMore, 
    PostAdd
} from '@material-ui/icons';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import MaterialTable from 'material-table';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { localization, options, style } from "../../../../utils/mtable";
import { getDateByTimestamp, getDateByString } from '../../../../utils/datetime';
import _ from 'lodash';
import { formatRoleGroupTreeForOperationRole } from '../../../../utils/orgchart';
import TreeSelect from 'rc-tree-select';
import ShowCard from '../ShowCard';
// import CreateButton from './CreateButton';

const CreateButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Button variant='contained'
                color='primary'
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <PostAdd />创建计划明细
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={props.classes.menuroot}
            >
                <MenuItem
                    className={props.classes.tabs}
                    onClick={(event) => {
                        props.routeToOperationGoalPlanCreate('DEV');
                    }}
                >
                    <ListItemIcon>
                        <BlurCircularIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="设备计划明细" />
                </MenuItem>
                <MenuItem
                    className={props.classes.tabs}
                    onClick={(event) => {
                        props.routeToOperationGoalPlanCreate('ACCTYPE');
                    }}
                >
                    <ListItemIcon>
                        <BlurLinearIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="部件分类计划明细" />
                </MenuItem>
                <MenuItem
                    className={props.classes.tabs}
                    onClick={(event) => {
                        props.routeToOperationGoalPlanCreate('ACC');
                    }}
                >
                    <ListItemIcon>
                        <BlurOnIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="部件计划明细" />
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
};

const OperationGoalPlanDetailNew = ({
    classes,
    operationPlan,
    companyId,
    companyTree,
    isLoading,
    optCategories,
    operationPlanDetails,
    procat,
    catList,
    accCatList,
    roleList,
    roleTree,
    companyList,
    planDetailPageSize,
    isViewMode,
    onhandleRouterToList,
    onhandleUpdate,
    onhandleDelete,
    setPlanDetailPageSize,
    onhanleGetPlanDetailList,
    onhanleSearchPlanDetailList,
    onhandleRouteToOperationGoalPlanCreate,
    userProfile,
    currentUserInfo,
    currentProcessStatus,
    onhandleChangeItem,
    onhandleClearSearch,
    // 运维类别 （检索条件）
    optType,
    // 执行时间 - 开始 （检索条件）
    targetDateStart,
    // 执行时间 - 结束 （检索条件）
    targetDateEnd,
    // 下工单 （检索条件）
    optNo,
    routeType,
    rules,
    ruleId
}) => {

    const [page, setPage] = useState(0);

    const tableRef = React.createRef();

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'leadership_operation_setup' })
        return permission && permission.action === 'W' && currentProcessStatus.statusCode && currentProcessStatus.actions
            && currentProcessStatus.actions.length > 0 && currentProcessStatus.actions[0].statusCode === 'Pending' &&
            operationPlan.companyId === currentUserInfo.companyId
    }

    // const renderTime = (detail) => {
    //     return detail.updateTime ? getDateByTimestamp(detail.updateTime, true) : getDateByTimestamp(detail.createTime, true)
    // }

    const renderDate = (detail) => {
        return detail.targetDate ? getDateByString(detail.targetDate) : getDateByTimestamp(detail.targetDate, true)
    }

    const renderStatus = (plan) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效' };
        return mapping[plan.status]
    }

    // const renderProCat = (device) => {
    //     if (procat && procat.length > 0 && device.proCat) {
    //         let cat = _.find(procat, { 'code': device.proCat });
    //         return cat.name ? cat.name : "";
    //     } else {
    //         return ""
    //     }
    // }

    // const renderCat = (device) => {
    //     if (catList && catList.length > 0 && device.cat) {
    //         let cat = _.find(catList, { 'code': device.cat });
    //         return cat.name ? cat.name : "";
    //     } else {
    //         return ""
    //     }
    // }

    // const renderAccCat = (acc) => {
    //     let label = '';
    //     if (accCatList && accCatList.length > 0) {
    //         let value = _.find(accCatList, function (o) { return o.code === acc.cat })
    //         if (value) {
    //             label = value.name
    //         }
    //     }
    //     return label;
    // }

    // const renderIsCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    const renderLabel = (row) => {
        let rtn = null;
        switch (row.target) {
            case 'DEV':
                rtn = <ShowCard currentInfo={row.device} target={row.target}
                    child={
                        < Avatar className={classes.pic1}> 设备</Avatar>}
                />
                break;
            case 'ACC':
                rtn = <ShowCard currentInfo={row.accessory} target={row.target}
                    child={
                        <Avatar className={classes.pic2}>部件</Avatar>}
                />
                break;
            case 'ACCTYPE':
                rtn = <ShowCard currentInfo={row.dictionary} target={row.target}
                    child={
                        <Avatar className={classes.pic3}>配类</Avatar>}
                />
                break;
            case 'EXTTYPE':
                rtn = <ShowCard currentInfo={row.dictionary} target={row.target}
                    child={
                        <Avatar className={classes.pic3}>扩类</Avatar>}
                />
                break;
            case 'DEVTYPE':
                rtn = <ShowCard currentInfo={row.dictionary} target={row.target}
                    child={
                        <Avatar className={classes.pic3}>设类</Avatar>}
                />
                break;
            default:
        }
        return rtn;
    }
    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }
        return false
    }
    const renderAccMsg = (plan) => {
        let renderAccMsg = ''
        if (plan && plan.target === 'DEV') {
            renderAccMsg = plan.device.deviceNo + " " + plan.device.deviceName
        }
        if (plan && plan.target === 'ACC') {
            renderAccMsg = plan.accessory.accNo + " " + plan.accessory.accName
        }
        return renderAccMsg
    }


    const renderCompany = (plan) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) {
                return o.companyId === plan.companyId
            })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderRoles = (roles) => {
        const list = [];
        roles.forEach(role => {
            let roleStr = '';
            roleStr = _.find(roleList, { 'roleId': role.roleId }) ? _.find(roleList, { 'roleId': role.roleId }).roleName : '';
            list.push(roleStr);
        })
        return list.join(', ');
    }

    const renderRoleTree = (row) => {
        let newRoleTree = _.find(roleTree, ['companyId', row.companyId])
        let newTree = newRoleTree.roleGroups
        return formatRoleGroupTreeForOperationRole(newTree)
    }

    const renderRoleList = (roles) => {
        let list = []
        roles.forEach(role => {
            role.value = role.value ? role.value : role.roleId;
            role.label = role.label ? role.label : role.roleName;
            role.roleId = role.roleId ? role.roleId : role.value;
            role.roleName = role.roleName ? role.roleName : role.label;
            list.push(role)
        })
        return list
    }

    const columns = [
        // {
        //     title: '对象',
        //     field: 'group',
        //     defaultGroupOrder: 0,
        //     defaultGroupSort: 'desc'
        // },
        {
            title: '类型',
            field: 'type',
            render: row => row ? renderLabel(row) : '',
            width: '2em',
            editable: 'never'
        },
        {
            title: '计划编号',
            field: 'optNo',
            width: '150px',
            render: row => row ? <Typography>{row.optNo}</Typography> : '',
            editable: 'never'
        },
        {
            title: '设备名称',
            field: 'accessory',
            render: row => row ? renderAccMsg(row) : '',
            editable: 'never'
        },
        {
            title: '单位',
            field: 'companyId',
            render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
            editable: 'never'
        },
        {
            title: '规则名称',
            field: 'ruleName',
            render: row => row && row.rule && row.rule.ruleName ? <Typography>{row.rule.ruleName}</Typography> : '',
            editable: 'never'
        },
        {
            title: '负责角色',
            field: 'roles',
            render: row => row ? <Typography>{renderRoles(row.roles)}</Typography> : '',
            align: 'left',
            width: '100px',
            validate: rowData => (!rowData.roles || rowData.roles.length === 0) ? {
                isValid: false,
                helperText: '负责角色不能为空'
            } : true,
            editComponent: (props) => (
                <div>
                    <TreeSelect
                        showArrow
                        className={classes.select}
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        allowClear
                        treeLine
                        value={renderRoleList(props.rowData.roles)}
                        labelInValue
                        treeData={renderRoleTree(props.rowData)}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        multiple={true}
                        onChange={(value) => {
                            let newRoleList = [];

                            value.forEach(selected => {
                                newRoleList.push({
                                    roleId: selected.value,
                                    roleName: selected.label,
                                    value: selected.value,
                                    label: selected.label
                                })
                            });
                            props.rowData.roles = newRoleList;
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }
                        }
                    />
                    {props.helperText !== '' ? <FormHelperText
                        error={true}
                    >
                        {props.helperText}
                    </FormHelperText> : null}
                </div>
            )
        },
        {
            title: '执行时间',
            field: 'targetDate',
            render: row => row ? <Typography>{renderDate(row)}</Typography> : '',
            editable: 'onUpdate',
            width: '100px',
            validate: rowData => (!rowData.targetDate || rowData.targetDate === '') ? {
                isValid: false,
                helperText: '计划执行时间不能为空'
            } : true,
            editComponent: (props) => (
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN}>
                    <KeyboardDatePicker
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        autoOk
                        InputProps={{ readOnly: true, }}
                        className={classes.textField}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        name='tenabletimeselect'
                        value={props.rowData.targetDate}
                        onChange={value => props.onChange(value)}
                        KeyboardButtonProps={{
                            'aria-label': '修改时间',
                        }}
                        minDate={new Date(`${operationPlan.year}-01-01`)}
                        maxDate={new Date(`${operationPlan.year}-12-31`)}
                    />
                </MuiPickersUtilsProvider>
            )
        },
        {
            title: '预算(元)',
            field: 'budget',
            render: row => row ? <Typography>{row.budget}</Typography> : '',
            editable: 'onUpdate',
            validate: rowData => (!rowData.budget || rowData.budget === '') ? {
                isValid: false,
                helperText: '预算不能为空'
            } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    value={props.rowData.budget}
                    type="number"
                    onChange={e => props.onChange(e.target.value)}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField>
            )
        },
        {
            title: '描述',
            field: 'opentext',
            editable: 'onUpdate',
            render: row => row ? <Typography>{row.opentext}</Typography> : '',
        },
        // {
        //     title: '更新时间',
        //     field: 'updateTime',
        //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
        //     editable: 'never'
        // },
        {
            title: '状态',
            field: 'status',
            render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            width: '60px',
            align: 'center',
            editable: 'never'
        }
    ]

    const tableOptions = {
        ...options,
        showTitle: false,
        pageSize: planDetailPageSize ? planDetailPageSize : 5,
        grouping: false,
        draggable: false,
        defaultExpanded: true,
        sorting: false,
    }
    const tableStyle = {
        ...style
    }

    const components = {
        Toolbar: props => (
            <div>
                {/*<Card className={classes.root}>*/}
                <Grid container alignItems="center" className={classes.title}>
                    <Typography variant="h3" >
                        {'年度运维计划明细'}
                    </Typography>
                </Grid>
                {/* 整个搜索功能区域 */}
                <Grid container alignItems="center" className={classes.gridContainer}>
                    {/* 搜索项 */}
                    <Grid container item alignItems="center" sm="auto" md="auto" lg="auto">
                        <Grid container item className={classes.gridItem} sm={3} md={2} lg={2}>
                            <Typography className={classes.label}>
                                单&emsp;位
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                select
                                variant="outlined"
                                disabled={companyDisabled()}
                                value={companyId ? companyId : ''}
                                onChange={(event) => onhandleChangeItem(event, 'companyId')}
                            >
                                <MenuItem aria-label="None" value="">{'全部'}</MenuItem>
                                {Boolean(companyList) &&
                                    _.sortBy(companyList, 'companyId').map(data => (
                                        <MenuItem value={data.companyId}
                                            key={data.companyId}>{data.companyAlias}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={3} md={2} lg={2}>
                            <Typography className={classes.label}>
                                计划编号
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                value={optNo || ''}
                                variant="outlined"
                                placeholder="计划编号"
                                onChange={value => onhandleChangeItem(value, 'optNo')}
                            />
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={4} md={3} lg={3}>
                            <Typography className={classes.label}>
                                执行时间
                            </Typography>
                            <Grid container item className={classes.gridSubItem}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        className={classes.textFieldGroupItem}
                                        InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        name='tenabletimeselect'
                                        value={targetDateStart}
                                        onChange={value => onhandleChangeItem(value, 'targetDateStart')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <Typography className={classes.connectLabel}>
                                    至
                                </Typography>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        className={classes.textFieldGroupItem}
                                        InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        name='tenabletimeselect'
                                        value={targetDateEnd}
                                        onChange={value => onhandleChangeItem(value, 'targetDateEnd')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    // minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                                    // maxDate={new Date(getLastDayInYear(getYear()))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={3} md={2} lg={2}>
                            <Typography className={classes.label}>
                                规则名称
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-company"
                                select
                                value={ruleId ? ruleId : ''}
                                onChange={(event) => onhandleChangeItem(event, 'ruleId')}
                                variant="outlined"
                            >
                                {_.sortBy(rules.list, 'ruleId').map((option) => (
                                    <MenuItem key={option.ruleId} value={option.ruleId}>
                                        {option.ruleName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item className={classes.flexgrow}> </Grid>
                        <Grid container item className={classes.gridItemSearchButtons} sm={5} md={3} lg={3}>
                            <Grid item>
                                <Button variant="contained" color="primary"
                                    onClick={onhanleSearchPlanDetailList}
                                >
                                    搜索
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                                    onClick={onhandleClearSearch}

                                >
                                    重置
                                </Button>
                                {
                                    checkPermission() ?
                                        <CreateButton key={`custom_button_2`} classes={classes}
                                            routeToOperationGoalPlanCreate={onhandleRouteToOperationGoalPlanCreate} />
                                        : null
                                }

                                <Button key={`custom_button_1`} variant="outlined" color="primary" style={{ margin: 10 }}
                                    onClick={onhandleRouterToList}>
                                    返回
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={planDetailPageSize ? planDetailPageSize : 5}
                count={operationPlanDetails.total}
                page={page}
                onChangePage={(event, pageNum) => {
                    setPage(pageNum);
                    onhanleGetPlanDetailList({
                        offset: pageNum * (planDetailPageSize ? planDetailPageSize : 5),
                        planId: operationPlan.planId,
                        companyId: companyId,
                        optNo: optNo,
                        ruleId: ruleId,
                        targetDateStart: targetDateStart,
                        targetDateEnd: targetDateEnd,
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    setPlanDetailPageSize(event.target.value);
                    setPage(0);
                    onhanleGetPlanDetailList({
                        offset: 0,
                        planId: operationPlan.planId,
                        companyId: companyId,
                        optNo: optNo,
                        ruleId: ruleId,
                        targetDateStart: targetDateStart,
                        targetDateEnd: targetDateEnd,
                    })
                }}

            />
        )
    }

    // const detailPannel = (row) => {
    //     if (row.device && row.device.deviceId) {
    //         return <MaterialTable
    //             options={detailOptions}
    //             columns={rendorDeviceColumns}
    //             data={[row.device]}
    //             style={tableStyle}
    //         />
    //     } else if (row.dictionary && row.dictionary.code) {
    //         return <MaterialTable
    //             options={detailOptions}
    //             columns={rendorAccTypeColumns}
    //             data={[row.dictionary]}
    //             style={tableStyle}
    //         />
    //     } else if (row.accessory && row.accessory.accId) {
    //         return <MaterialTable
    //             options={detailOptions}
    //             columns={rendorAccColumns}
    //             data={[row.accessory]}
    //             style={tableStyle}
    //         />
    //     }
    // }

    // const rendorAccTypeColumns = [
    //     {
    //         title: '',
    //         width: '4em',
    //         render: row => <Typography></Typography>
    //     },
    //     {
    //         title: '部件类别名称',
    //         field: 'name',
    //         render: row => row ? <Typography>{row.name}</Typography> : '',
    //         editable: 'never'
    //     }
    // ];

    // const rendorAccColumns = [
    //     {
    //         title: '',
    //         width: '4em',
    //         render: row => <Typography></Typography>
    //     },
    //     {
    //         title: '部件编号',
    //         field: 'accNo',
    //         render: row => row ? <Typography>{row.accNo}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '部件名称',
    //         field: 'deviceName',
    //         render: row => row ? <Typography>{row.accName}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '型号',
    //         field: 'model',
    //         render: row => row ? <Typography>{row.model}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '部件类别',
    //         field: 'cat',
    //         render: row => row ? <Typography>{renderAccCat(row)}</Typography> : '',
    //         editable: 'never',
    //     },
    //     {
    //         title: '通用部件',
    //         field: 'isCommon',
    //         render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
    //         editable: 'never'
    //     }
    // ];

    // const rendorDeviceColumns = [
    //     {
    //         title: '',
    //         width: '4em',
    //         render: row => <Typography></Typography>
    //     },
    //     {
    //         title: '设备编号',
    //         field: 'deviceNo',
    //         render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '设备名称',
    //         field: 'deviceName',
    //         render: row => row ? <Typography>{row.deviceName}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '设备位号',
    //         field: 'tagNo',
    //         render: row => row ? <Typography>{row.tagNo}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '类别',
    //         field: 'cat',
    //         render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
    //         editable: 'never'
    //     },
    //     {
    //         title: '专业类别',
    //         field: 'proCat',
    //         render: row => row ? <Typography>{renderProCat(row)}</Typography> : '',
    //         editable: 'never'
    //     },
    // ];

    // const detailOptions = {
    //     ...options,
    //     toolbar: false,
    //     paging: false,
    //     showTitle: false,
    // }

    return (
        <Card alignitems="center" className={classes.root}>
            {isLoading ?
                '' :
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        tableRef={tableRef}
                        className={classes.materialtable}
                        title=''
                        columns={columns}
                        data={operationPlanDetails.list}
                        options={tableOptions}
                        components={components}
                        editable={checkPermission() ? {
                            onRowUpdate: (newData) =>
                                new Promise((resolve, reject) => {
                                    resolve()
                                    onhandleUpdate(newData)
                                })
                        } : null}
                        localization={localization}
                        style={tableStyle}
                        // detailPanel={detailPannel}
                        actions={checkPermission() ? [
                            rowData => ({
                                icon: 'delete_outlined',
                                tooltip: '删除',
                                onClick: (event, rowData) => {
                                    onhandleDelete(rowData)
                                },
                            })
                        ] : null}
                    >
                    </MaterialTable>
                </Grid>
            }
        </Card>
    );

};
OperationGoalPlanDetailNew.propTypes = {
    classes: PropTypes.object.isRequired,
    operationPlan: PropTypes.object.isRequired,
    operationPlanDetails: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    optCategories: PropTypes.array.isRequired,
    procat: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    accCatList: PropTypes.array.isRequired,
    roleTree: PropTypes.array.isRequired,
    roleList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    planDetailPageSize: PropTypes.number.isRequired,
    isViewMode: PropTypes.bool.isRequired,
    onhandleRouterToList: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    setPlanDetailPageSize: PropTypes.func.isRequired,
    onhanleGetPlanDetailList: PropTypes.func.isRequired,
    onhandleRouteToOperationGoalPlanCreate: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
};

export default (withStyles(OperationGoalPlanDetailNew));
