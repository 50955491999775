import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    stepper: {
        alignItems: 'center',
        padding: 16,
        background: 'none',
        lineHeight: '36px'
    },
    line: {
        display: 'inline-block',
        width: '10%',
        height: 0,
        borderTop: '2px solid',
        borderTopColor: 'rgba(0, 0, 0, 0.3)',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    step: {
        display: 'grid'
    },
    todoUser: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    todoRole: {
        color: theme.palette.info.dark,
        borderColor: theme.palette.info.dark,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    pendingUser: {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    pendingRole: {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    rejectedUser: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    approvedUser: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    forwardUser: {
        color: theme.palette.white,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.main,
        margin: theme.spacing(0.5),
    },

});

export default withStyles(styles);