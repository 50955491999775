import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { getCustomers } from './customer';
import { getOppo } from './customerOppo';
import { push } from 'connected-react-router';
import { getUsersWithCallback } from '@/redux/modules/user';
import * as _ from 'lodash';


const porxyApi = '/api'

const initialState = {
    search: {
        search: undefined,
    },
    activityList: [],
    userList: [],
    customerList: [],
    pageSize: 0,
    currentActivity: {},
    activityTask: {},
    activityTaskOpen: false
}

export const initActivityHeader = createAction('@@xcloud/customerActivities/initActivityHeader',
    () => (dispatch, getState) => {

        Promise.all([
            dispatch(resetSearch(true)),
            //dispatch(getActivityList())
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化客户活动搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const resetSearch = createAction('@@xcloud/customerActivities/resetSearch',
    (firstLoadFlag) => (dispatch, getState, httpClient) => {
        let showFilters = getState().customerActivities.search.showFilters;
        let search = getState().customerActivities.search.search;
        let currentActivity = getState().customerActivities.currentActivity;
        // console.log('showFilters', showFilters);


        // //console.log('searchCond' ,searchCond);
        // let newSearch = { ...getState().customerActivities.search };
        // if(!newSearch.cid){
        //     newSearch.cid = getState().customerActivities.currentActivity.cid;
        // }
        // console.log('newSearch', newSearch);
        // dispatch(setSearch(newSearch));
        // let search = getState().customerOppo.search;
        let newSearch = {
            // ...search,
            //companyId: newCompanyId ? newCompanyId : undefined,
            search: !showFilters && !firstLoadFlag ? search : undefined,
            showFilters: firstLoadFlag ? false : !showFilters,
            cid: currentActivity.cid
        }
        dispatch(setSearch(newSearch));
    }
);

export const getActivityById = createAction('@@xcloud/customerActivities/getActivityById',
    (actId, onOpenActivity) => (dispatch, getState, httpClient) => {

        dispatch(startLoading());
        let url = '/crmactivities/' + actId;
        //console.log('url',url)
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCurrentActivity(res.data));
                    dispatch(setHiddenActivitiesInfo(false));
                    if (onOpenActivity) {
                        dispatch(setActivityList([res.data]));
                    }

                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '客户活动获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const onCreateActivity = createAction(
    '@@xcloud/customerActivities/onCreateActivity',
    (oid) => (dispatch, getState, httpClient) => {
        dispatch(startLoading());
        let cActivity = getState().customerActivities.currentActivity;
        let currentActivity = {
            "status": "1",
            "cid": cActivity.cid,
            "actCustomer": cActivity.actCustomer
        }
        dispatch(setHiddenActivitiesInfo(false));
        dispatch(setCurrentActivity(currentActivity));
        dispatch(getOppo());
        dispatch(setIsEditable(true));
    }
);

export const changeActivityStatus = createAction(
    '@@xcloud/customerActivities/changeActivityStatus',
    (actDto, status) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.actId = actDto.actId
        dto.tenantId = actDto.tenantId
        dto.status = status
        return httpClient
            .patch(porxyApi + `/crmactivities/${dto.actId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除活动成功'))
                    // 重新检索列表
                    dispatch(backToList());
                    //return res.data;
                }
            }).catch(err => {
                let msg = `删除客户活动失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createActivity = createAction('@@xcloud/customerActivities/createActivity',
    () => (dispatch, getState, httpClient) => {

        let url = '/crmactivities';
        let cActivity = getState().customerActivities.currentActivity;
        cActivity.budget = cActivity.budget ? cActivity.budget : 0;
        cActivity.actTime = new Date(cActivity.actTime);

        //cActivity.status="1";
        console.log('cActivity', cActivity);
        return httpClient.post(porxyApi + url, cActivity)
            .then((res) => {
                console.log('res.data', res.data);
                dispatch(setActivityChange(false));
                dispatch(setCurrentActivity(res.data));
                dispatch(createMessage('success', '创建客户活动[' + cActivity.actName + ']成功'));
                dispatch(backToList());
                //return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建客户机会失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const createActivityTask = createAction('@@xcloud/customerActivities/createActivityTask',
    () => (dispatch, getState, httpClient) => {

        let url = '/crmactivities/tasks';
        let cActivity = getState().customerActivities.currentActivity;
        let cActivityTask = getState().customerActivities.activityTask;
        cActivityTask.actId = cActivity.actId;
        //cActivityTask.status="1";
        console.log('cActivityTask', cActivityTask);

        //cActivity.status="1";

        return httpClient.post(porxyApi + url, cActivityTask)
            .then((res) => {
                console.log('res.data', res.data);
                dispatch(setActivityTaskOpen(false));
                dispatch(backToList());
                //return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建客户机会失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });


export const updateActivity = createAction('@@xcloud/customerActivities/updateActivity',
    () => (dispatch, getState, httpClient) => {
        let updateActivity = getState().customerActivities.currentActivity;
        let url = '/crmactivities/' + updateActivity.actId;
        console.log('updateActivity', updateActivity);
        let to = {
            actId: updateActivity.actId,
            actName: updateActivity.actName,
            actType: updateActivity.actType,
            cid: updateActivity.cid,
            actTime: new Date(updateActivity.actTime),
        }
        if (updateActivity && updateActivity.actLocation) {
            to.actLocation = updateActivity.actLocation;
        }
        if (updateActivity && updateActivity.budget) {
            to.budget = updateActivity.budget;
        }
        if (updateActivity && updateActivity.actHead) {
            to.actHead = updateActivity.actHead;
        }
        if (updateActivity && updateActivity.customer) {
            to.customer = updateActivity.customer;
        }
        if (updateActivity && updateActivity.attendees.length > 0) {
            to.attendees = updateActivity.attendees;
        }
        if (updateActivity && updateActivity.oid) {
            to.oid = updateActivity.oid;
        }
        if (updateActivity && updateActivity.feedback) {
            to.feedback = updateActivity.feedback;
        }
        return httpClient.patch(porxyApi + url, to)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setActivityChange(false));
                    dispatch(setCurrentActivity(res.data));
                    dispatch(createMessage('success', '更新客户机会[' + updateActivity.actName + ']成功'));
                    dispatch(openActivity(updateActivity.actId));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '更新客户机会[' + updateActivity.actName + ']失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const openActivity = createAction('@@xcloud/customerActivities/openActivity',
    (actId) => (dispatch, getState) => {
        //only keep one record for act list on open Activity
        return Promise.all([
            dispatch(backToList(actId, true)),
        ]).then()
            .catch(err => {
                let msg = '获取客户机会失败';
                dispatch(createMessage('error', msg));
            });
    });

export const backToList = createAction('@@xcloud/customerActivities/backToList',
    (actId, onOpenActivity) => (dispatch, getState) => {
        const users = getState().user.users;
        if (!users || !users.list || users.list <= 0) {
            dispatch(getUsersWithCallback({ offset: 0, size: 0 }))
        }

        console.log('actId', actId)

        if (actId) {
            return Promise.all([
                dispatch(setHiddenActivitiesInfo(false)),
                dispatch(getActivityById(actId, onOpenActivity)),
                dispatch(setIsEditable(false)),
            ]).then(dispatch(push('/crm/activities')))
                .catch(err => {
                    let msg = '获取客户机会失败';
                    dispatch(createMessage('error', msg));
                });
        } else {
            return Promise.all([
                dispatch(getActivityList()),
                //dispatch(setHiddenActivitiesInfo(true)),
                dispatch(setIsEditable(false)),
            ]).then(dispatch(push('/crm/activities')))
                .catch(err => {
                    let msg = '获取客户机会失败';
                    dispatch(createMessage('error', msg));
                });
        }

    });

export const initActivityInfo = createAction('@@xcloud/customerActivities/initActivityInfo',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getCustomers()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化客户列表失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);


export const getActivityList = createAction('@@xcloud/customerActivities/activitylist',
    (query, callback) => (dispatch, getState, httpClient) => {
        let url = "/crmactivities"

        let searchCond = getState().customerActivities.search;

        let pageSize = getState().customerActivities.pageSize
        if (query && query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }

        if (query && query.search && query.search !== "") {
            url += "&search=" + query.search
        } else if (searchCond.search && searchCond.search !== "") {
            url += "&search=" + searchCond.search
        }

        if (query && query.cid && query.cid !== "") {
            url += "&cid=" + query.cid
        } else if (searchCond.cid && searchCond.cid !== "") {
            url += "&cid=" + searchCond.cid
        }

        if (query && query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        } else if (searchCond.fromDate && searchCond.fromDate !== "") {
            url += "&fromDate=" + searchCond.fromDate
        }

        if (query && query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        } else if (searchCond.toDate && searchCond.toDate !== "") {
            url += "&toDate=" + searchCond.toDate
        }

        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 0)
        }

        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }

        //console.log('search url:', url);
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setActivityList(res.data.list));
                    dispatch(setHiddenActivitiesInfo(true));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取得客户机会列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getCustomerList = createAction('@@xcloud/customerActivities/customerList',
    (query, callback) => (dispatch, getState, httpClient) => {

        console.log('getCustomerList', getCustomers());
        dispatch(getCustomers());

        return {}
    });

export const setSearch = createAction('@@xcloud/customerActivities/setSearch');
export const setActivityChange = createAction('@@xcloud/customerActivities/setActivityChange');
export const setCurrentActivity = createAction('@@xcloud/customerActivities/setCurrentActivity');
export const setActivityList = createAction('@@xcloud/customerActivities/setactivitylist')
export const setCustomerList = createAction('@@xcloud/customerActivities/setCustomerList')
export const startLoading = createAction('@@xcloud/customerActivities/startLoading');
export const stopLoading = createAction('@@xcloud/customerActivities/stopLoading');
export const setIsEditable = createAction('@@xcloud/customerActivities/setIsEditable');
export const setHiddenActivitiesInfo = createAction('@@xcloud/customerActivities/setHiddenActivitiesInfo');
export const setActivityTaskOpen = createAction('@@xcloud/customerActivities/setActivityTaskOpen');
export const setActivityTask = createAction('@@xcloud/customerActivities/setActivityTask');

export const customerActivitiesReducer = handleActions(
    {
        [setActivityList]: (state, { payload: data }) => {
            return {
                ...state,
                activityList: data
            }
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setCurrentActivity]: (state, { payload: value }) => {
            return {
                ...state,
                currentActivity: value
            };
        },
        [startLoading]: (state) => {
            return {
                ...state,
                isloading: true
            };
        },
        [stopLoading]: (state) => {
            return {
                ...state,
                isloading: false
            };
        },
        [setActivityChange]: (state, { payload: value }) => {
            return {
                ...state,
                activityChange: value
            };
        },
        [setIsEditable]: (state, { payload: value }) => {
            return {
                ...state,
                isEditable: value
            };
        },
        [setHiddenActivitiesInfo]: (state, { payload: value }) => {
            return {
                ...state,
                hiddenActivitiesInfo: value
            };
        },
        [setCustomerList]: (state, { payload: data }) => {
            return {
                ...state,
                customerList: data
            }
        },
        [setActivityTaskOpen]: (state, { payload: value }) => {
            return {
                ...state,
                activityTaskOpen: value
            };
        },
        [setActivityTask]: (state, { payload: value }) => {
            return {
                ...state,
                activityTask: value
            };
        },
    },
    initialState
);

export default customerActivitiesReducer;