import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    TablePagination,
    List,
    Select
} from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
// import { getDateByTimestamp } from '../../../../utils/datetime';
import ProcessLine from '../../../ProcessLine';
import * as _ from 'lodash';


const CreateOperationGoalPlan = ({
    classes,
    companyId,
    companyTree,
    companyList,
    year,
    createPlanPageSize,
    isViewMode,
    userProfile,
    onhandleUpdate,
    operationPlan,
    onhandleChangeItem,
    onhandleAddPlan,
    onhandelRouteToOperationPlanDetail,
    setCreatePlanPageSize,
    onhanleGetPlanList,
    currentProcessStatus,
    currentRecord,
    constant,
    onhandleProcess,
    currentUserInfo,
    onhandleCompanyRejectProcess,
}) => {
    const [page, setPage] = useState(0);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'leadership_operation_setup' })
        return permission && permission.action === 'W'
    }

    const renderRowActions = (companyId) => {
        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0 || isViewMode) {
        } else {
            if (companyId !== currentUserInfo.companyId) {
                const actions = _.filter(currentProcessStatus.actions, (action) => {
                    return action.distributed
                })
                let buttons = []
                actions.forEach((action, idx) => {
                    let subRecords = action.subRecords ? action.subRecords : null;
                    let subRecord = null;
                    if (subRecords) {
                        subRecord = _.find(subRecords, { companyId: companyId });
                    }

                    buttons.push(<Button
                        key={`rule_button_${idx}`}
                        disabled={action.disabled || (subRecord && subRecord.statusCode === 'Rejected') || false}
                        variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                        color="primary"
                        className={classes.button}
                        onClick={onhandleCompanyRejectProcess.bind(this, companyId, action)}
                    >
                        {action.actionName}
                    </Button>)
                })
                return buttons
            }
        }
        return null;
    }

    const renderActions = () => {
        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0 || isViewMode) {
        } else {
            const actions = _.filter(currentProcessStatus.actions, (action) => {
                return !action.distributed
            })
            let buttons = actions.map((action, idx) => (
                <Button
                    key={`rule_button_${idx}`}
                    disabled={action.disabled || false}
                    variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                    color="primary"
                    className={classes.button}
                    onClick={onhandleProcess.bind(this, action)}
                >
                    {action.actionName}
                </Button>
            ))
            return buttons
        }
    }

    const renderAttention = () => {
        let record = currentRecord
        if (record.statusCode !== 'Rejected')
            return ''
        if (!record.opentext)
            return ''
        return (<Grid className={classes.attention}>
            {record.opentext.split('\n').map((line, index) => (
                <Typography key={index}
                    className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
            ))}
        </Grid>)
    }

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    // const renderTime = (plan) => {
    //     return plan.updateTime ? getDateByTimestamp(plan.updateTime, true) : getDateByTimestamp(plan.createTime, true)
    // }

    const renderStatus = (plan) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效' };
        return mapping[plan.status]
    }

    const renderCompany = (plan) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) {
                return o.companyId === plan.companyId
            })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const companyOptions = () => (
        <React.Fragment>
            {companyList ?
                _.sortBy(companyList, 'companyId').map(data => (
                    <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
                )) : null
            }
        </React.Fragment>
    )


    const columns = [
        {
            title: '单位',
            field: 'companyId',
            render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
            editable: 'never'
        },
        {
            title: '计划名称',
            field: 'planName',
            render: row => row ? <Typography>{row.planName}</Typography> : '',
        },
        {
            title: '年度',
            field: 'year',
            render: row => row ? <Typography>{row.year}</Typography> : '',
            editable: 'never'
        },
        {
            title: '描述',
            field: 'opentext',
            render: row => row ? <Typography>{row.opentext}</Typography> : '',
        },
        // {
        //     title: '更新时间',
        //     field: 'updateTime',
        //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
        //     editable: 'never'
        // },
        {
            title: '状态',
            field: 'status',
            render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            editable: 'never'
        },
        {
            title: '',
            field: 'button',
            width: 50,
            render: row => row ? renderRowActions(row.companyId) : '',
            editable: 'never'
        },
    ]

    const tableOptions = {
        ...options,
        showTitle: false,
        pageSize: createPlanPageSize ? createPlanPageSize : 5,
    }
    const tableStyle = {
        ...style
    }

    const components = {
        Toolbar: props => (
            <React.Fragment>
                <Grid container alignItems="center" justify={'space-between'}>
                    {
                        !isViewMode ?
                            <React.Fragment>
                                <Grid item className={classes.action}>
                                    <Grid container>
                                        <Grid item className={classes.processline}>
                                            <ProcessLine
                                                statusCodes={currentProcessStatus.statusCodes}
                                                statusCode={currentProcessStatus.statusCode}
                                                constant={constant}
                                            />
                                        </Grid>
                                        <Grid item className={classes.flexgrow}> </Grid>
                                        <Grid item>
                                            <List>
                                                {renderActions()}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            : null
                    }

                    {!isViewMode ? renderAttention() : null}

                    {isViewMode ?
                        (
                            <React.Fragment>
                                <Grid item className={classes.action}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className={classes.label}>
                                                单位
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                disabled={companyDisabled()}
                                                className={classes.yearSelect}
                                                native
                                                value={companyId}
                                                onChange={(event) => onhandleChangeItem(event, 'companyId')}
                                            >
                                                <option aria-label="None" value="" />
                                                {companyOptions()}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        : null}
                    <Grid item className={classes.action}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    年份
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        InputProps={{ readOnly: true, }}
                                        className={classes.textField}
                                        disableToolbar
                                        variant="inline"
                                        views={["year"]}
                                        name='year'
                                        value={new Date(year)}
                                        format="yyyy"
                                        // disabled={checkPermission() && ((!currentProcessStatus.statusCode) ||
                                        //     (currentProcessStatus.statusCode && currentProcessStatus.statusCode === 'Published')) ? false : true}
                                        minDate={new Date(`2020-01-01`)}
                                        onChange={value => onhandleChangeItem(value, 'year')}
                                        KeyboardButtonProps={{
                                            'aria-label': '年份',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                    </Grid>
                    {checkPermission() ?
                        (!currentProcessStatus.statusCode) ||
                            (currentProcessStatus.statusCode && currentProcessStatus.statusCode === 'Published') ?
                            !companyDisabled() ?
                                <Grid item className={classes.button}>
                                    <Button variant='contained'
                                        color='primary'
                                        onClick={onhandleAddPlan}
                                    // disabled={!companyId || (companyId && operationPlan.total > 0)}
                                    >
                                        <PostAdd />启动年度计划
                                    </Button>
                                </Grid>
                                : null : null : null
                    }

                </Grid>
            </React.Fragment>
        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={createPlanPageSize ? createPlanPageSize : 5}
                count={operationPlan.total}
                page={page}
                onChangePage={(event, pageNum) => {
                    setPage(pageNum);
                    onhanleGetPlanList({
                        offset: pageNum * (createPlanPageSize ? createPlanPageSize : 5),
                        sort: "-update_time",
                        status: isViewMode ? "active" : "new",
                        planType: "ANNU",
                        companyId: companyId ? companyId : null,
                        year: Number(year.substr(0, 4)),
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    setCreatePlanPageSize(event.target.value);
                    setPage(0);
                    onhanleGetPlanList({
                        offset: 0,
                        sort: "-update_time",
                        status: isViewMode ? "active" : "new",
                        planType: "ANNU",
                        companyId: companyId ? companyId : null,
                        year: Number(year.substr(0, 4)),
                    })
                }}

            />
        )
    }

    return (

        <div alignitems="center" className={classes.root}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={columns}
                    data={operationPlan.list}
                    options={tableOptions}
                    components={components}
                    editable={
                        !isViewMode ? (currentProcessStatus.statusCode && currentProcessStatus.actions
                            && currentProcessStatus.actions.length > 0 && (currentProcessStatus.actions[0].statusCode === 'Pending') && checkPermission() ? {
                            isEditable: (rowData) => rowData.companyId === currentUserInfo.companyId,
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    onhandleUpdate(newData)
                                    resolve()
                                }),
                        } : null) : null}
                    localization={localization}
                    style={tableStyle}
                    actions={
                        [
                            rowData => ({
                                icon: 'description',
                                tooltip: '查看',
                                onClick: (event, rowData) => {
                                    onhandelRouteToOperationPlanDetail(rowData.planId, 'edit')
                                }
                            })
                        ]
                    }
                >
                </MaterialTable>

            </Grid>
        </div>
    );

};
CreateOperationGoalPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    isViewMode: PropTypes.bool.isRequired,
    operationPlan: PropTypes.object.isRequired,
    createPlanPageSize: PropTypes.number.isRequired,
    userProfile: PropTypes.object.isRequired,
    onhandleAdd: PropTypes.func,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandelRouteToOperationPlanDetail: PropTypes.func.isRequired,
    setCreatePlanPageSize: PropTypes.func.isRequired,
    onhanleGetPlanList: PropTypes.func.isRequired,
    currentProcessStatus: PropTypes.object.isRequired,
    currentRecord: PropTypes.object.isRequired,
    constant: PropTypes.object.isRequired,
    onhandleProcess: PropTypes.func.isRequired,

};

export default (withStyles(CreateOperationGoalPlan));
