import { connect } from 'react-redux';
import ViewTopBar from '../components/ViewTopBar';
import { fullScreenSetting, exitFullScreenSetting, setScale, updateUnitView } from '../redux/modules/viewSetting';
import { createDialog, cleanDialog } from '../redux/modules/dialog';
import { addStoreySelect } from '../redux/modules/installationPlug';
import { initDeviceTreeData } from '../redux/modules/deviceTree';

const mapStateToProps = (state) => {
    return {
        ...state.viewSetting,
        ...state.installation,
        ...state.installationPlug,
        ...state.installationRelation,
        ...state.devices,
    }
}

const mapDispathToProps = (dispath) => {
    return {
        fullScreenSetting: () => {
            dispath(fullScreenSetting())
        },
        exitFullScreenSetting: () => {
            dispath(exitFullScreenSetting())
        },
        setScale: (value) => {
            dispath(setScale(value))
        },
        updateUnitView: () => {
            dispath(updateUnitView())
        },
        createDialog: (children, parameters, showIcon) => {
            dispath(createDialog(children, parameters, showIcon))
        },
        cleanDialog: () => {
            dispath(cleanDialog())
        },
        addStoreySelect: (value) => {
            dispath(addStoreySelect(value))
        },
        initDeviceTreeData: () => {
            dispath(initDeviceTreeData())
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ViewTopBar);