import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { downloadURL } from '../redux/modules/minio';
import AppQRCode from '../components/AppQRCode'
import { cleanmodal } from '../redux/modules/modal';

class AppQRCodeContainer extends Component {
    static propTypes = {
        open: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            url: '',
            iosUrl: '',
        }
    }


    componentWillMount() {
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }

        if (envCode === 'prod') {
            this.setState({
                url: "http://spark.appc02.com/3hbr",
                iosUrl: "http://spark.appc02.com/lp46"
            })
        } else if (envCode === 'uat') {
            this.setState({
                url: "http://d.zqapps.com/jp8a",
                iosUrl: "http://d.zqapps.com/h6cb"
            })
            // this.props.onhandleDownload('xcloud.apk', 'uat.app').then(
            //     (data) => {
            //         this.setState({
            //             url: data.payload
            //         })
            //     }
            // ).catch(err => {

            // })
        } else {
            this.props.onhandleDownload('xcloud.apk', 'dev.app').then(
                (data) => {
                    this.setState({
                        url: data.payload,
                        iosUrl: "http://d.firim.top/lp46"
                    })
                }
            ).catch(err => {

            })
        }
    }

    render() {
        return (
            <AppQRCode
                url={this.state.url}
                iosUrl={this.state.iosUrl}
                onhandleExitPage={this.props.onhandleCleanmodal.bind(this)}
            />

        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCleanmodal: () => {
            dispatch(cleanmodal());
        },
        onhandleDownload: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppQRCodeContainer);
