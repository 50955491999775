import Konva from 'konva';
import { getStore } from '../../../../../redux/store';
// import * as _ from 'lodash';
import KonvaUtil from "../util";
// import ActionUtil from '../actionUtil';
import { createContentMenu } from '../../../redux/modules/contentmenu';
const konvaUtil = new KonvaUtil();
// const actionUtil = new ActionUtil();



export function init(props, id, name, stage, previewStage) {
    let layer = stage.findOne('.workLayer');
    let group = layer.findOne('.newGroupTemp');
    group.id(id);
    let rectTemp = group.findOne('.backGround');
    group.draggable(false);
    rectTemp.fill('#81c784');
    rectTemp.opacity(1);
    group.moveToTop();
    rectTemp.moveToTop();
    // rectTemp.fill(null);
    // rectTemp.moveToBottom();

    // let containerRect = stage.container().getBoundingClientRect();
    const text = new Konva.Text({
        text: name,
        x: rectTemp.x() + 16,
        y: rectTemp.y() - 15
    })

    const rightCircle = new Konva.Circle({
        x: rectTemp.x() + 8,
        y: rectTemp.y() - 11,
        radius: 8,
        fill: '#616161',
        name: 'main'
    });

    group.name('DeviceGroup')
    group.add(text);
    group.add(rightCircle);
    group.draw();


    groupCommon(props, group, layer, stage, previewStage, 'edit');
    circleCommon(props, rightCircle, group, layer, previewStage);

    layer.batchDraw();
    konvaUtil.updatePreview(props, layer, previewStage);
}

export function mapping(props, group, layer, stage, previewStage, action) {
    groupCommon(props, group, layer, stage, previewStage, action);
    group.find('Circle').forEach(circle => {
        if (circle.name() === 'main') {
            circleCommon(props, circle, group, layer, previewStage)
        }
    })
}

function circleCommon(props, circle, group, layer, previewStage) {
    circle.moveToTop();
    circle.on("click", e => {
        let rectTemp = group.findOne('.backGround');
        if (!rectTemp.fill()) {
            rectTemp.fill('#81c784');
            rectTemp.opacity(1);
            group.moveToTop();
            rectTemp.moveToTop();
            circle.fill('#616161');
            circle.moveToTop();
            layer.draw();
            konvaUtil.updatePreview(props, layer, previewStage);
        } else {
            rectTemp.fill(null);
            group.moveToBottom();
            rectTemp.moveToBottom();
            circle.fill('green');
            circle.moveToTop();
            layer.draw();
            let units = layer.find('.Unit');
            if (units) {
                units.forEach(unit => {
                    unit.moveToBottom();
                })
            }
            let areas = layer.find('.Area');
            if (areas) {
                areas.forEach(area => {
                    area.moveToBottom();
                })
            }
            layer.draw();
            konvaUtil.updatePreview(props, layer, previewStage);
        }
    });
}

function groupCommon(props, group, layer, stage, previewStage, action) {
    const store = getStore();
    // let deviceGroup = [];
    let rectTemp = group.findOne('.backGround');
    let circle = group.findOne('.main');
    if (!rectTemp.fill()) {
        rectTemp.fill('#81c784');
        rectTemp.opacity(1);
        group.moveToTop();
        rectTemp.moveToTop();
        circle.fill('#616161');
        circle.moveToTop();
        layer.draw();
    }


    group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
        // 拖拽开始，组件变红色
        group.find('Rect').forEach(element => {
            if (element.name() === 'backGround') {
                element.stroke('red');
            }

        });
        // let deviceList = []
        // deviceGroup = [];
        // let ids = _.split(group.id(), '_');
        // if (ids && ids.length > 1) {
        //     let id = _.toInteger(ids[1]);
        //     let devices = _.filter(store.getState().devices.searchDevicelist.list, function (o) { return o.groupId === id; });
        //     devices.forEach(device => {
        //         console.log(device)
        //         let cat = device.cat;
        //         let type = _.find(store.getState().devices.deviceLegends, function (o) { return o.cat === cat; }).legend;
        //         deviceList.push({ id: type + "_" + device.deviceId })
        //     })
        // }

        // deviceGroup = deviceList;
        layer.draw();
    })

    group.on('mouseout', function () {
        document.body.style.cursor = 'default';
        // 拖拽结束，组件恢复黑色
        group.find('Rect').forEach(element => {
            if (element.name() === 'backGround') {
                element.stroke('#388e3c');
            }
        });
        layer.draw();
    })

    // group.on('dragstart', function () {
    //     store.dispatch(cleanContentMenu());
    //     console.log(deviceGroup);
    //     if (deviceGroup && deviceGroup.length > 0) {
    //         deviceGroup.forEach(device => {
    //             layer.findOne('#' + device.id).startDrag();
    //         })
    //     } else {
    //         group.stopDrag();
    //     }
    //     // layer.find('Group').forEach(targetGroup => {
    //     //     if (targetGroup.id() !== group.id()) {
    //     //         if (konvaUtil.shape1ContainShape2(group.getClientRect(), targetGroup.getClientRect())) {
    //     //             deviceGroup.push({ id: targetGroup.id() })
    //     //             targetGroup.startDrag();
    //     //         }
    //     //     }
    //     // })
    // })

    // group.on('dragend', function () {
    //     konvaUtil.updatePreview(props, layer, previewStage);
    //     actionUtil.markChange(group.id());
    // })
    if (action !== 'view') {
        group.on('contextmenu', function (e) {
            e.evt.preventDefault();
            let containerRect = stage.container().getBoundingClientRect();
            let top = containerRect.top + group.getClientRect().y;
            let left = containerRect.left + group.getClientRect().x + group.getClientRect().width;
            store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
        });
    }
}

