import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    MenuItem,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { getCodeName, ACTIVITY_TYPE } from '@/utils/manufactureUtils'

const ServiceActionComponent = props => {
    const { classes,
        openFlag,
        activityData,
        activityList,
        manufactureDicts,
        openType,
        commissionServices,
        onhandleClose,
        onhandleSave,
        onhandleChange,
        onhandleComServiceChange,
    } = props;

    const textFieldReadOnly = {
        readOnly: true,
    }

    const textFieldDefault = {
        style: {
            width: '185px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    const filterActivities = activityList.filter(ele => (ele.manufactureAttrs && ele.manufactureAttrs.length > 0));

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={openFlag} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>
                        {'活动添加'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid container direction="column" >
                            {openType === 2 &&
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        select
                                        label='委外服务'
                                        name='commissionService'
                                        variant="outlined"
                                        value={activityData.commissionService || ''}
                                        InputProps={textFieldDefault}
                                        onChange={onhandleComServiceChange}>
                                        {commissionServices.map((ele) => (
                                            <MenuItem key={ele.serviceId} value={ele.serviceId}>
                                                {ele.serviceName}
                                            </MenuItem>)
                                        )}
                                    </TextField>
                                </Grid>}
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    select
                                    label='活动名称'
                                    name='activityId'
                                    variant="outlined"
                                    value={activityData.activityId || ''}
                                    InputProps={textFieldDefault}
                                    onChange={onhandleChange}>
                                    {filterActivities.map((ele) => (
                                        <MenuItem key={ele.activityId} value={ele.activityId}>
                                            {ele.activityName}
                                        </MenuItem>)
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='活动类型'
                                    name='activityType'
                                    variant="outlined"
                                    InputProps={{ ...textFieldDefault, ...textFieldReadOnly }}
                                    value={getCodeName(manufactureDicts, ACTIVITY_TYPE, activityData.activityType)}
                                />
                            </Grid>

                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='说明'
                                    name='opentext'
                                    variant="outlined"
                                    InputProps={{ ...textFieldDefault, ...textFieldReadOnly }}
                                    value={activityData.opentext || ''}
                                />
                            </Grid>
                            <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary">添加
                    </Button>
                    <Button onClick={onhandleClose} color="primary">取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

ServiceActionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    activityData: PropTypes.object,
    activityList: PropTypes.array,
    openFlag: PropTypes.bool,
    openType: PropTypes.number,
    commissionServices: PropTypes.array,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleChange: PropTypes.func,
    onhandleComServiceChange: PropTypes.func,
};

ServiceActionComponent.defaultProps = {

};

export default withStyles(ServiceActionComponent);