import { createAction, handleActions } from 'redux-actions';
const initialState = {
  show: false,
  children: '',
  parameters: {},
  showIcon: true
};

export const cleanDialog = createAction('@@xcloud/dialog/cleanDialog');
export const createDialog = createAction('@@xcloud/dialog/createDialog',
  (children, parameters, showIcon) => ({ children, parameters, showIcon }));

// Actions

export const dialogReducer = handleActions(
  {
    [createDialog]: (state, { payload: dialogs }) => {
      return {
        ...state,
        show: true,
        children: dialogs.children,
        parameters: dialogs.parameters,
        showIcon: dialogs.showIcon,
      };
    },
    [cleanDialog]: (state) => {
      return {
        ...state,
        show: false,
        children: '',
        parameters: {},
        showIcon: true
      };
    }
  },
  initialState
);

export default dialogReducer