import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { getMoment } from '../../utils/datetime'
import { formSelectTree } from '../../utils/constant';
import {formatDate} from '../../utils/datetime';
import * as _ from 'lodash';
import { getDeviceCategoryTree } from './trees';
import { getDeviceUseStatus } from './devices';

const porxyApi = '/api'

const initialState = {
    deviceList: {
        total: 0,
        list: []
    },
    intervalDate: {
        day: getMoment()().startOf('day'),
        weekStart: getMoment()().startOf('week'),
        weekEnd: getMoment()().startOf('week').add(6, 'day'),
        month: getMoment()().startOf('month'),
        startDay: getMoment()().startOf('week'),
        endDay: getMoment()().startOf('week').add(6, 'day'),
        view: 'day',
    },
    selectedDevices: [],
    currentData: [],
    currentDescription: [],
    // 当前设备基本信息
    currentDevice: {},
    intervalData: [],
    intervalDescription: [],
    unit: [],
    unitTree: [],
    deviceCurrentRecords: [],
    deviceIntervalRecords: []
}

export const initCurrentData = createAction('@@xcloud/onsiteInspectionStatistics/initCurrentData',
    (deviceId) => (dispatch, getState) => {
        Promise.all([
            dispatch(resetIntervalDate()),
            dispatch(getDeviceUnit()),
            dispatch(getDeviceCurrentRecords(deviceId)),
            dispatch(getDeviceIntervalRecords(deviceId)),
            // 根据设备ID获取设备基本信息
            dispatch(getDeviceBasicInfo(deviceId)),
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
        ]).then((data) => {
        }).catch(err => {
            let msg = '初始化设备搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getDeviceUnit = createAction('@@xcloud/onsiteInspectionStatistics/getDeviceUnit',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '设备运行参数单位获取失败'));
                return [];
            });
    });

export const getDeviceCurrentRecords = createAction(
    '@@xcloud/onsiteInspectionStatistics/getDeviceCurrentRecords',
    (deviceId) => (dispatch, getState, httpClient) => {
        let parameters = getState().modal.parameters
        let data = deviceId ? deviceId : parameters.deviceId
        let url = "/onsiteinspections/devicecurrentrecords?deviceId=" + data
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                if (res.status === 200) {
                    let currentData = []
                    let currentDescription = []
                    console.log(res.data)
                    for (let i = 0; i < res.data.length; i++) {
                        let dataCell = {}
                        if (res.data[i].itemName) {
                            dataCell.paramName = res.data[i].itemName
                            dataCell.val = res.data[i].val
                            dataCell.stdVal = res.data[i].stdVal
                            dataCell.unit = res.data[i].unit
                            dataCell.updateTime = res.data[i].updateTime
                        } else {
                            dataCell.paramName = res.data[i].param
                            dataCell.val = res.data[i].val
                            dataCell.stdVal = res.data[i].vals
                            dataCell.unit = res.data[i].unit
                            dataCell.updateTime = res.data[i].updateTime
                        }
                        if (res.data[i].resultType === 'STATUS') {
                            currentDescription.push(dataCell)
                            continue
                        }
                        if (res.data[i].resultType === 'NUMBER') {
                            currentData.push(dataCell)
                            continue
                        }
                    }
                    dispatch(setCurrentData(currentData))
                    dispatch(setCurrentDescription(currentDescription))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `按照设备ID查找当前巡检记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

/* Added by ldp 20201231 ---- 追加显示设备基本信息 */
export const getDeviceBasicInfo = createAction(
    '@@xcloud/onsiteInspectionStatistics/getDeviceBasicInfo',
    (deviceId) => (dispatch, getState, httpClient) => {
        let parameters = getState().modal.parameters
        let data = deviceId ? deviceId : parameters.deviceId
        let url = "/devices/" + data
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCurrentDevice(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `根据deviceId获取设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDeviceIntervalRecords = createAction(
    '@@xcloud/onsiteInspectionStatistics/getDeviceIntervalRecords',
    (deviceId) => (dispatch, getState, httpClient) => {
        let parameters = getState().modal.parameters
        let intervalDate = getState().onsiteInspectionStatistics.intervalDate
        let data = deviceId ? deviceId : parameters.deviceId
        let fromDate = ''
        let toDate = ''
        if (intervalDate.view === 'day') {
            fromDate = formatDate(getMoment()(intervalDate.day))
            let tod = _.cloneDeep(getMoment()(intervalDate.day))
            tod.add(1, 'day')
            toDate = formatDate(tod)
        } else if (intervalDate.view === 'week') {
            fromDate = formatDate(getMoment()(intervalDate.weekStart))
            let tod  = _.cloneDeep(getMoment()(intervalDate.weekEnd))
            tod.add(1, 'day')
            toDate = formatDate(tod)
        } else if (intervalDate.view === 'month') {
            fromDate = formatDate(getMoment()(intervalDate.month))
            let tod  = _.cloneDeep(getMoment()(intervalDate.month))
            tod.add(1, 'months')
            toDate = formatDate(tod)
        } else if (intervalDate.view === 'custom') {
            fromDate = getMoment()(intervalDate.startDay)
            toDate = getMoment()(intervalDate.endDay)
            toDate.add(1, 'day')
            if (fromDate >= toDate) {
                dispatch(createMessage('error', '结束日期要大于开始日期'));
                return
            }
            fromDate = formatDate(fromDate)
            toDate = formatDate(toDate)
        }

        let url = "/onsiteinspections/deviceIntervalrecords?deviceId=" + data
        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + fromDate
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + toDate
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                if (res.status === 200) {
                    let intervalMap = {}
                    let intervalData = []
                    let intervalDescription = []
                    for (let i = 0; i < res.data.length; i++) {
                        let dataCell = {}
                        if (res.data[i].itemName) {
                            dataCell.paramName = res.data[i].itemName
                            dataCell.val = res.data[i].val
                            dataCell.stdVal = res.data[i].stdVal
                            dataCell.unit = res.data[i].unit
                            dataCell.updateTime = res.data[i].updateTime
                        } else {
                            dataCell.paramName = res.data[i].param
                            dataCell.val = res.data[i].val
                            dataCell.stdVal = res.data[i].vals
                            dataCell.unit = res.data[i].unit
                            dataCell.updateTime = res.data[i].updateTime
                        }

                        if (res.data[i].resultType === 'STATUS') {
                            intervalDescription.push(dataCell)
                            continue
                        }
                        if (res.data[i].resultType === 'NUMBER') {
                            let key = res.data[i].itemId
                            if (!intervalMap[key]) {
                                intervalMap[key] = {
                                    unit: dataCell.unit,
                                    x: [],
                                    y: [],
                                    param: dataCell.paramName,
                                    stdVal: dataCell.stdVal,
                                }
                            }

                            intervalMap[key].x.push(getMoment()(dataCell.updateTime).format('DD日HH时'))
                            intervalMap[key].y.push(dataCell.val)
                            continue
                        }
                    }

                    for (let j in intervalMap) {
                        intervalData.push(intervalMap[j])
                    }
                    dispatch(setIntervalData(intervalData))
                    dispatch(setIntervalDescription(intervalDescription))
                    return res.data;
                }
            }).catch(err => {
                let msg = `按照设备ID查找区间巡检记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const resetIntervalDate = createAction('@@xcloud/onsiteInspectionStatistics/resetIntervalDate',
    () => (dispatch, getState, httpClient) => {
        let intervalDate = {
            day: getMoment()().startOf('day'),
            weekStart: getMoment()().startOf('week'),
            weekEnd: getMoment()().startOf('week').add(6, 'day'),
            month: getMoment()().startOf('month'),
            startDay: getMoment()().startOf('week'),
            endDay: getMoment()().startOf('week').add(6, 'day'),
            view: 'day'
        }

        dispatch(setIntervalDate(intervalDate));
    }
);

export const setCurrentData = createAction('@@xcloud/onsiteInspectionStatistics/setCurrentData');
export const setCurrentDescription = createAction('@@xcloud/onsiteInspectionStatistics/setCurrentDescription');
// 当前设备基本信息
export const setCurrentDevice = createAction('@@xcloud/onsiteInspectionStatistics/setCurrentDevice');
export const setIntervalData = createAction('@@xcloud/onsiteInspectionStatistics/setIntervalData');
export const setIntervalDescription = createAction('@@xcloud/onsiteInspectionStatistics/setIntervalDescription');
export const setIntervalDate = createAction('@@xcloud/onsiteInspectionStatistics/setIntervalDate');

export const onsiteInspectionStatisticsReducer = handleActions(
    {
        [setCurrentData]: (state, { payload: value }) => {
            return {
                ...state,
                currentData: value
            };
        },
        [setCurrentDescription]: (state, { payload: value }) => {
            return {
                ...state,
                currentDescription: value
            };
        },
        // 当前设备基本信息
        [setCurrentDevice]: (state, { payload: value }) => {
            return {
                ...state,
                currentDevice: value
            };
        },
        [setIntervalData]: (state, { payload: value }) => {
            return {
                ...state,
                intervalData: value
            };
        },
        [setIntervalDescription]: (state, { payload: value }) => {
            return {
                ...state,
                intervalDescription: value
            };
        },
        [setIntervalDate]: (state, { payload: value }) => {
            return {
                ...state,
                intervalDate: value
            };
        },
        [getDeviceUnit]: (state, { payload: value }) => {
            return {
                ...state,
                unit: value.list,
                unitTree: value.tree
            };
        },
        [getDeviceCurrentRecords]: (state, { payload: value }) => {
            return {
                ...state,
                deviceCurrentRecords: value
            };
        },
        [getDeviceIntervalRecords]: (state, { payload: value }) => {
            return {
                ...state,
                deviceIntervalRecords: value
            };
        },

    },
    initialState
);

export default onsiteInspectionStatisticsReducer;