export const defaultColors = [
    '#002c53',
    '#ffa510',
    '#0c84c6',
    '#ffbd66',
    '#f74d4d',
    '#2455a4',
    '#fc8452',
    '#41b7ac',
    '#ea7ccc'
];

export const echartsV5Colors = [
    '#5470c6',
    '#91cc75',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#fc8452',
    '#9a60b4',
    '#ea7ccc',
]

export const antDBasicColors = [
    '#5B8FF9',
    '#61DDAA',
    '#65789B',
    '#F6BD16',
    '#7262fd',
    '#78D3F8',
    '#9661BC',
    '#F6903D',
    '#008685',
    '#F08BB4',
]

export const antDContrastingColors = [
    '#2FB8FC',
    '#1AAF8B',
    '#406C85',
    '#F6BD16',
    '#B40F0F',
    '#4435FF',
    '#FF5CA2',
    '#BBE800',
    '#FE8A26',
    '#946DFF',
    '#6C3E00',
    '#6193FF',
    '#FF988E',
    '#36BCCB',
    '#004988',
    '#FFCF9D',
    '#CCDC8A',
    '#8D00A1',
    '#1CC25E',
]