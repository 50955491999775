import React, { Component } from 'react';
import { connect } from 'react-redux';
import TemporaryRegulationPlanList from "../../../../components/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanList";
import PropTypes from "prop-types";
import { initCreate, getPlans, initTempPlan } from '../../../../redux/modules/leadershipRegulationPlan';

class TemporaryRegulationPlanListContainer extends Component {
    static propTypes = {
        tempPlans: PropTypes.array,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleGetPlans();
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    detailedInfo(event, planId) {
        this.props.onhandleOpen(planId);
    }

    render() {
        return (
            <TemporaryRegulationPlanList
                tempPlans={this.props.tempPlans}
                onDetailedInfo={this.detailedInfo.bind(this)}
                getPlans={this.props.onhandleGetPlans.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                constants={this.props.constants}
                userProfile={this.props.userProfile}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tempPlans: state.leadershipRegulationPlan.plans,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetPlans: () => {
            dispatch(getPlans());
        },
        onhandleOpen: (planId) => {
            dispatch(initTempPlan(planId));
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemporaryRegulationPlanListContainer)