import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initMajorHazardHeader, setSearch, resetSearch, getMajorHazard } from '../../../redux/modules/majorHazard';
import MajorHazardHeader from "../../../components/MajorHazardSources/MajorHazardInfo/MajorHazardHeader";
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';

class MajorHazardHeaderContainer extends Component {

  static propTypes = {
    companyTree: PropTypes.array,
    search: PropTypes.object,
    userProfile: PropTypes.object,
    companyList: PropTypes.array,
    dangerLevelList: PropTypes.array,
  };

  componentDidMount() {
    this.props.onhandleInitMajorHazardHeader('ALL');
  }

  handleSearch(event) {
    this.props.onhandleSearch({ offset: 0, size: 5, sort: "-update_time" });
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value ? value.target.value === '' ? undefined : value.target.value : undefined
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'dangerLevel':
        newSearch = {
          ...this.props.search,
          dangerLevel: value.target.value ? value.target.value === '' ? undefined : value.target.value : undefined
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleClearSearch() {
    this.props.onhandleClearSearch();
  }

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <div>
        <MajorHazardHeader
          companyTree={this.trimCompanyTree()}
          companyList={this.props.companyList}
          userProfile={this.props.userProfile}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          dangerLevelList={this.props.dangerLevelList}
          search={this.props.search}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    dangerLevelList: state.majorHazard.dangerLevelList,
    search: state.majorHazard.search,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitMajorHazardHeader: (data) => {
      dispatch(initMajorHazardHeader(data));
    },
    onhandleUpdateEditSearch: (value) => {
      dispatch(setSearch(value));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: (query) => {
      dispatch(getMajorHazard(query));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MajorHazardHeaderContainer)
