import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InspectionPlanCurrentYear from "../../../components/InspectionManagement/AnnualPlan/InspectionPlanCurrentYear";
import {
    // getInspections,
    getListTypeList,
    getCategoryList,
} from "@/redux/modules/inspection/inspection";
import {
    getPlanDetails,
    clearPlanDetails,
    getExecutionPlan,
} from "@/redux/modules/inspection/inspectionPlan";
import {
    getCurrentProcess,
    getProcessHistories,
    getCompanyAllProcessStatus
} from "../../../redux/modules/process";
import { getRecordByUnitId, isUnitBranch, getMasterRecord } from "../../../utils/processUtils";
import { getBusinessUnitsByRole, getDepartmentsByRole } from "../../../utils/userUtils";

import ApprovePannel from "../ApprovePannel";

import { openApprovePannel } from "../../../redux/modules/approvePannel";

import * as _ from 'lodash';

const mapCurrentProcess = (processes, currentUserInfo, userProfile) => {

    if (!processes) {
        return {}
    }
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecord(processes, currentUserInfo.companyId)

    if (isUnitBranch(processes)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(processes, bu.unitId)
        }
    }

    let process = {}
    if (record && Object.keys(record.length > 0)) {
        process = _.find(processes, (process) => {
            return _.find(process.records, (rec) => {
                return rec.recordId === record.recordId
            })
        })
    }
    return process
}
const mapCurrentRecord = (processes, currentUserInfo, userProfile) => {

    if (!processes) {
        return {}
    }
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecord(processes, currentUserInfo.companyId)

    if (isUnitBranch(processes)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(processes, bu.unitId)
        }
    }
    return record || {}
}



class InspectionPlanCurrentYearContainer extends Component {
    static propTypes = {
        plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        userProfile: PropTypes.object,
        onhandleReviewStatus: PropTypes.func,
        onhandleReturnPlans: PropTypes.func,
        onhandleReturnPlan: PropTypes.func,
        onhandleOpenPlanDetails: PropTypes.func,
        onhandleChange: PropTypes.func,
        listTypeList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 'INS01',
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue
        })
    }

    componentDidMount() {
        const {
            onhandleGetExecutionPlan,
            onhandleGetPlanDetails,
            onhandleClearPlanDetails,
        } = this.props

        onhandleClearPlanDetails();
        onhandleGetExecutionPlan((plan) => {
            if (!plan || Object.keys(plan).length === 0)
                return
            onhandleGetPlanDetails(plan.planId)
        })
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyAlias : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            case 'inspectType':
                value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
                break;
            case 'user':
                const user = _.find(users.list, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'status':
                switch (colValue) {
                    // case 'drafting':
                    //     value = '草稿'
                    //     break;
                    // case 'rejected':
                    //     value = '驳回'
                    //     break;
                    // case 'submitted':
                    //     value = '已提交'
                    //     break;
                    // case 'pending':
                    //     value = '待审批'
                    //     break;
                    case 'finalized':
                        value = '未开始'
                        break;
                    case 'active':
                        value = '进行中'
                        break;
                    case 'done':
                        value = '已完成'
                        break;
                    default:
                        value = '失效'
                }
                break;
            default:
            // do nothing
        }
        return value;
    }

    render() {
        return (
            <div>
                <InspectionPlanCurrentYear
                    plan={this.props.plan}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    listTypeList={this.props.listTypeList}
                    categoryList={this.props.categoryList}
                    departments={this.props.departments}
                    currentRecord={this.props.currentRecord}
                    currentProcess={this.props.currentProcess}
                    currentPlanDetails={this.props.planDetails}
                    onhandleListMapping={this.handleListMapping.bind(this)}

                    tabValue={this.state.tabValue}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
                <ApprovePannel />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        plan: state.inspectionPlan.executionPlan,
        planDetails: state.inspectionPlan.planDetails,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        currentRecord: mapCurrentRecord(
            state.process.processes.InspectionPlanExecution,
            state.auth.currentUserInfo,
            state.auth.userProfile
        ),
        currentProcess: mapCurrentProcess(
            state.process.processes.InspectionPlanExecution,
            state.auth.currentUserInfo,
            state.auth.userProfile
        ),
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        companyAllProcessStatus: state.process.companyAllProcessStatus.InspectionPlanExecution || [],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetExecutionPlan: (callback) => {
            dispatch(getExecutionPlan(callback))
        },
        onhandleGetCurrentProcess: (callback) => {
            dispatch(getCurrentProcess('InspectionPlanExecution', callback))
        },
        onhandleGetProcessHistories: (recordId) => {
            dispatch(getProcessHistories(recordId, 'InspectionPlanExecution'))
        },
        onhandleGetCompanyAllProcessStatus: () => {
            dispatch(getCompanyAllProcessStatus('InspectionPlanExecution'))
        },
        onhandleClearPlanDetails: () => {
            dispatch(clearPlanDetails())
        },
        onhandleGetPlanDetails: (planId) => {
            dispatch(getPlanDetails({
                planId: planId
            }))
        },
        onhandleGetListTypeList: () => {
            dispatch(getListTypeList())
        },
        onhandleGetCategoryList: () => {
            dispatch(getCategoryList())
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionPlanCurrentYearContainer)