import React, { Component } from 'react';
import { connect } from 'react-redux';
import PhoneBillComponent from '@/components/AmoebaManagement/PhoneBill';
import { getMoment, getNowTimestamp, convertToMoment } from '@/utils/datetime';
import { getPhoneBills, importPhoneBills, updatePhoneBill } from '@/redux/modules/amoeba/phoneBill';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import * as _ from 'lodash';
import { createMessage } from '@/redux/modules/message';
import { upload } from '@/redux/modules/minio';
import { judgeMainCompany } from '@/utils/manufactureUtils'

class PhoneBillContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            openFlg: false,
            companyId: null,
            dptId: null,
            companies: [],
            isMainCompany: null,
            departments: []
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const { companies, userInfo } = this.props
        // 查看是否机关
        const isMainCompany = judgeMainCompany(companies, userInfo.companyId);
        let companys = []
        let dpts = [];
        if (isMainCompany) {
            companys = this.props.companies
            dpts = _.filter(this.props.departments, dpt => dpt.companyId === userInfo.companyId);
        } else {
            companys.push(_.find(companies, company => company.companyId === userInfo.companyId))
            dpts = this.props.departments;
        }
        this.setState({
            companyId: userInfo.companyId,
            companies: companys,
            isMainCompany: isMainCompany,
            departments: dpts
        })
        this.props.getPhoneBills(null, userInfo.companyId, null, null, this.state.selectedDate.year(), this.state.selectedDate.month());
    }

    handleDateChange = (date) => {
        let d = convertToMoment(date);
        this.props.getPhoneBills(null, this.state.companyId, this.state.dptId, null, d.year(), d.month());
        this.setState({
            selectedDate: d
        })
    }

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'dev';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.phone.bill.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                docName: offset + files[0].name
            };
            this.props.onhandleCreateDoc(doc, () => {
                this.props.getPhoneBills(null, this.state.companyId, this.state.dptId, null, this.state.selectedDate.year(), this.state.selectedDate.month());
            });
            this.props.onhandleModalClose();
        });
    }

    handleUpdate = (data) => {

        let item1 = parseFloat(data.item1);
        let item2 = parseFloat(data.item2);
        let item3 = parseFloat(data.item3);
        let item4 = parseFloat(data.item4);
        let item5 = parseFloat(data.item5);
        let item6 = parseFloat(data.item6);
        let item7 = parseFloat(data.item7);
        let newRecord = {
            'recordId': data.recordId,
            'phoneNo': data.phoneNo,
            'actPaid': parseFloat(item1 + item2 + item3 + item4 + item5 + item6 + item7),
            'item1': item1,
            'item2': item2,
            'item3': item3,
            'item4': item4,
            'item5': item5,
            'item6': item6,
            'item7': item7,
        }
        this.props.onhandleUpdateRecord(newRecord, () => {
            this.props.getPhoneBills(null, this.state.companyId, this.state.dptId, null, this.state.selectedDate.year(), this.state.selectedDate.month());
        });
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        let dpts = _.filter(this.props.departments, dpt => dpt.companyId === companyId);
        this.props.getPhoneBills(null, companyId, null, null, this.state.selectedDate.year(), this.state.selectedDate.month());
        this.setState({
            companyId: companyId,
            departments: dpts,
            dptId: null
        })
    }


    handleDptChange = (event) => {
        const dptId = event.target.value;
        this.props.getPhoneBills(null, this.state.companyId, dptId, null, this.state.selectedDate.year(), this.state.selectedDate.month());
        this.setState({
            dptId: dptId
        })
    }

    render() {
        return (
            <div>
                <PhoneBillComponent
                    selectedDate={this.state.selectedDate}
                    companyId={this.state.companyId}
                    companies={this.state.companies}
                    departments={this.state.departments}
                    dptId={this.state.dptId}
                    isMainCompany={this.state.isMainCompany}
                    phoneBillList={this.props.phoneBillList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleUpload={this.handleUpload.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleDptChange={this.handleDptChange.bind(this)}
                    userProfile={this.props.userProfile}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        phoneBillList: state.phoneBill.records,
        userProfile: state.auth.userProfile,
        userInfo: state.auth.currentUserInfo,
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPhoneBills: (phone, companyId, dptId, userId, year, month) => {
            dispatch(getPhoneBills(phone, companyId, dptId, userId, year, month + 1));
        },
        onhandleUploadPre: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(importPhoneBills(doc, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updatePhoneBill(record, callback));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhoneBillContainer);
