import React, { Component } from 'react';
import Activities from "../../../components/Crm/Activities/Activities";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ActivitiesContainer extends Component {
  static propTypes = {
    hiddenActivitiesInfo: PropTypes.bool,
  };

  render() {
    return (
      <div>
        <Activities
          hiddenActivitiesInfo={this.props.hiddenActivitiesInfo} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hiddenActivitiesInfo: state.customerActivities.hiddenActivitiesInfo,
  }
};


export default connect(
  mapStateToProps
)(ActivitiesContainer)
