import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { push } from 'connected-react-router';
import { getCompanyId } from "../../utils/userUtils";
import _ from 'lodash';
import { getTreePartById } from '../../utils/constant';
import { csv } from '../../utils/exportfile';
import { getDateByTimestamp, getMoment as moment } from '../../utils/datetime';
import { formSelectTree } from '../../utils/constant';
import { getPointList, setIndoorInspectionItemList } from '../../redux/modules/onsiteInspection';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment } from '@/utils/datetime';

const defaultState = {
    planList: {
        total: 0,
        list: []
    },
    search: {
        companyId: "",
        inspectionType: "",
        routeId: "",
        dptId: "",
        status: "",
        userId: null,
        fromDate: null,
        toDate: null,
        isCurrent: false,
        offset: 0,
        size: 15
    },
    routeList: [],
    departmentList: [],
    users: {
        total: 0,
        list: []
    },
    currentPlan: {
        onsitePlanDpt: [{ dptId: '' }],
    },
    recordListSize: 0,
    recordList: {
        total: 0,
        list: []
    },
    recordHistoryList: {},
    planPanelOpen: false,
    leaders: [],
    planError: {},
    searchUrl: '',
    unit: [],
    unitTree: [],
};

export const getAllLeaders = createAction('@@xcloud/onsiteInspectionSchedule/getAllLeaders',
    (companyId, callback) => (dispatch, getState, httpClient) => {
        return httpClient.get('/api/users/leaders')
            .then((res) => {
                if (res) {
                    return res.data
                }
            }).catch(err => {
                let msg = `获取所有领导层失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getUsers = createAction('@@xcloud/onsiteInspectionPlan/getUsers',
    () => (dispatch, getState, httpClient) => {
        let url = '/api/users?offset=0&size=0&status=active';
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
                return res;
            }).catch(err => {
                let msg = '巡检人列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            });
    });

export const getDepartment = createAction('@@xcloud/onsiteInspectionPlan/getDepartment',
    () => (dispatch, getState, httpClient) => {
        let url = `/api/departments?offset=0&size=0&status=active&executable=true`;
        return httpClient
            .get(url)
            .then((res) => {
                return res.data.list
            })
            .catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '获取班组信息失败'));
                return []
            });
    });


export const getRouteList = createAction('@@xcloud/onsiteInspectionPlan/getRouteList',
    () => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.data) {
                    return res.data.list;
                } else {
                    return []
                }
            }).catch(err => {
                dispatch(cleanloading());
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);


export const getPlanList = createAction('@@xcloud/onsiteInspectionPlan/getPlanList',
    (data) => (dispatch, getState, httpClient) => {
        data = data ? data : {};
        // if (reload) {
        //     let search = getState().onsiteInspectionPlan.search;
        //     let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        //     let newSearch = {
        //         ...search,
        //         companyId: data && data.companyId ? data.companyId : companyId,
        //         inspectionType: data.inspectionType ? data.inspectionType : "",
        //         routeId: data.routeId ? _.toInteger(data.routeId) : "",
        //         dptId: data.dptId ? _.toInteger(data.dptId) : "",
        //         status: data.status ? data.status : "",
        //         userId: data.userId ? _.toInteger(data.userId.userId) : null,
        //         // fromDate: data.fromDate ? new Date(_.toInteger(data.fromDate)) : null,
        //         // toDate: data.toDate ? new Date(_.toInteger(data.toDate)) : null,
        //         fromDate: data.fromDate ? data.fromDate.getTime() : null,
        //         toDate: data.toDate ? data.toDate.getTime() : null,
        //         size: data.size ? _.toInteger(data.size) : 15,

        //     }
        //     dispatch(setSearch(newSearch));
        // }

        let searchCond = getState().onsiteInspectionPlan.search;
        let params = {}
        // let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        params.companyId = searchCond.companyId ? searchCond.companyId : ''
        params.inspectionType = searchCond.inspectionType ? searchCond.inspectionType : ''
        params.routeId = searchCond.routeId ? searchCond.routeId : ''
        params.dptId = searchCond.dptId ? searchCond.dptId : ''
        params.fromDate = searchCond.fromDate ? searchCond.fromDate.getTime() : searchCond.fromDate
        params.toDate = searchCond.toDate ? searchCond.toDate.getTime() : searchCond.toDate
        params.status = searchCond.status ? searchCond.status : ''
        params.userId = searchCond.userId ? searchCond.userId.userId : searchCond.userId
        params.size = searchCond.size ? searchCond.size : 15

        // 定制搜索：近期内操巡检
        params.isCurrent = data.isCurrent ? data.isCurrent : false

        // let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        // data.companyId = data && data.companyId ? data.companyId : company

        let query = {}
        _.assign(query, data, params)

        let path = '/api/onsiteinspections/plans';

        let url = ""
        if (query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (_.isInteger(query.size)) {
            url += "&size=" + query.size
        }
        if (query.companyId && query.companyId !== "") {
            url += "&companyId=" + query.companyId
        }
        if (query.inspectionType && query.inspectionType !== "") {
            url += "&inspectionType=" + query.inspectionType
        }
        if (query.routeId && query.routeId !== "") {
            url += "&routeId=" + query.routeId
        }
        if (query.dptId && query.dptId !== "") {
            url += "&dptId=" + query.dptId
        }
        if (query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        }
        if (query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        }
        if (query.isCurrent && query.isCurrent !== "") {
            url += "&isCurrent=" + query.isCurrent
        }
        if (query.status && query.status !== "") {
            url += "&status=" + query.status
        }
        if (query.userId && query.userId !== "") {
            url += "&userId=" + query.userId
        }
        if (query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-start_time"
        }
        // 必取巡检异常信息
        url += "&checkExceptionExists=true"

        // dispatch(setSearchUrl(url));
        dispatch(createloading());
        return httpClient
            .get(path + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(cleanloading());
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检计划获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

/**
 * @offset
 * @size
 * @sort
 * @planId
 * @enableLoading 请求时启用loading动画
 */
export const getRecordList = createAction('@@xcloud/onsiteInspectionPlan/getRecordList',
    (offset, size, sort, planId, enableLoading) => (dispatch, getState, httpClient) => {
        let recordListSize = getState().onsiteInspectionPlan.recordListSize;

        size = recordListSize ? recordListSize : size

        let url = '/api/onsiteinspections/records';

        if (offset && offset !== "") {
            url += "?offset=" + offset
        } else {
            url += "?offset=0"
        }
        if (_.isInteger(size)) {
            url += "&size=" + size
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=update_time"
        }
        if (planId && planId !== "") {
            url += "&planId=" + planId
        }

        return httpClient
            .get(url, { headers: { need_loading: enableLoading } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(cleanloading());
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检记录获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const getHistoryRecordList = createAction('@@xcloud/onsiteInspectionPlan/getHistoryRecordList',
    (size, routeId, nowTime, fromDate, toDate) => (dispatch, getState, httpClient) => {

        let url = `/api/onsiteinspections/routes/${routeId}/records/history?`;

        if (_.isInteger(size)) {
            url += "&size=" + size
        }
        // url += "&sort=start_time"
        if (nowTime && nowTime !== "") {
            url += "&nowTime=" + moment()(nowTime).valueOf();
        }
        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + moment()(fromDate).valueOf();
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + moment()(toDate).valueOf();
        }

        return httpClient
            .get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    const list = {};
                    res.data
                        // .sort((a, b) => (
                        //     moment()(a.startTime).isSameOrBefore(moment()(b.startTime))
                        // ))
                        .forEach(item => {
                            if (item.status !== 'invalid') { // 排除"不涉及"记录, 不采用filter是因为可能有显示出“不涉及”条目的需求
                                // 空记录则0
                                let itemId = item.itemId ? item.itemId : 0;
                                let itemKey = 0;
                                if (item.paramId) {
                                    itemKey = item.newDeviceId + 'p' + item.paramId // 运行参数key
                                } else if (item.genItem) {
                                    itemKey = item.newDeviceId + 'g' + item.genItem // 通用参数key
                                } else {
                                    itemKey = itemId
                                }

                                const timeKey = moment()(item.startTime).format('YYYY-MM-DD HH:mm')
                                if (list[timeKey]) {
                                    if (list[timeKey][itemKey]) {
                                        list[timeKey][itemKey].value = item.val
                                        list[timeKey][itemKey].inspector = item.inspectorName
                                        list[timeKey][itemKey].histInsUseStatus = item.histInsUseStatus // 记录发生时的装置状态
                                    } else {
                                        list[timeKey][itemKey] = {
                                            value: item.val,
                                            inspector: item.inspectorName,
                                            histInsUseStatus: item.histInsUseStatus
                                        }
                                    }
                                } else {
                                    list[timeKey] = {
                                        [itemKey]: {
                                            value: item.val,
                                            inspector: item.inspectorName,
                                            histInsUseStatus: item.histInsUseStatus
                                        }
                                    }
                                }
                            }
                        })
                    dispatch(setRecordHistoryList(list));
                } else {
                    dispatch(setRecordHistoryList({}));
                }
            }).catch(err => {
                debugger
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检历史记录获取失败'));
                return {};
            });
    }
);


export const exportRecordList = createAction('@@xcloud/onsiteInspectionPlan/exportRecordList',
    (planId) => (dispatch, getState, httpClient) => {

        let url = '/api/onsiteinspections/records?offset=0&size=0&sort=update_time&planId=' + planId;
        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {

                let fileName = '巡检记录_' + new Date().getTime()
                let columns = ['装置', '设备编号', '设备名称', '单元', '参数', '值', '单位', '时间']
                let data = []
                if (res.status === 200 && res.data && res.data.list.length > 0) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        let updateTime = res.data.list[i].updateTime ? getDateByTimestamp(res.data.list[i].updateTime, true) : ''
                        let cell = [
                            res.data.list[i].apparatusName,
                            res.data.list[i].deviceNo,
                            res.data.list[i].deviceName,
                            res.data.list[i].unitName,
                            res.data.list[i].itemName,
                            res.data.list[i].val,
                            res.data.list[i].itemUnit,
                            updateTime
                        ];
                        data.push(cell)
                    }
                }
                dispatch(csv(fileName, columns, data));

            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检记录获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);


const _renderLogic = (template) => {
    if (template.indexOf('|') > -1) {
        return ''
    } else {
        let logic = template.split('{')[0]
        return logic
    }
};

const _renderValues = (standardValue,) => {
    if (standardValue.indexOf(',') > -1) {
        let value = standardValue.split(',').join(' ~ ');
        return value
    } else {
        let value = standardValue
        return value
    }
};


export const exportRecordListCollect = createAction('@@xcloud/onsiteInspectionPlan/exportRecordListCollect',
    (data) => (dispatch, getState, httpClient) => {
        data = data ? data : {};

        let searchCond = getState().onsiteInspectionPlan.search;
        let params = {}
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        params.companyId = searchCond.companyId ? searchCond.companyId : companyId
        params.inspectionType = searchCond.inspectionType ? searchCond.inspectionType : ''
        params.routeId = searchCond.routeId ? searchCond.routeId : ''
        params.dptId = searchCond.dptId ? searchCond.dptId : ''
        params.fromDate = searchCond.fromDate ? searchCond.fromDate.getTime() : searchCond.fromDate
        params.toDate = searchCond.toDate ? searchCond.toDate.getTime() + 1000 : searchCond.toDate
        params.status = searchCond.status ? searchCond.status : ''
        params.userId = searchCond.userId ? searchCond.userId.userId : searchCond.userId

        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        data.companyId = data && data.companyId ? data.companyId : company

        let query = {}
        _.assign(query, data, params)

        let path = '/api/onsiteinspections/records';

        let url = "?offset=0&size=0"
        if (query.companyId && query.companyId !== "") {
            url += "&companyId=" + query.companyId
        }
        if (query.inspectionType && query.inspectionType !== "") {
            url += "&inspectionType=" + query.inspectionType
        }
        if (query.routeId && query.routeId !== "") {
            url += "&routeId=" + query.routeId
        }
        if (query.dptId && query.dptId !== "") {
            url += "&dptId=" + query.dptId
        }
        if (query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        }
        if (query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        }
        // if (query.status && query.status !== "") {
        //     url += "&status=" + query.status
        // }
        if (query.userId && query.userId !== "") {
            url += "&userId=" + query.userId
        }
        if (query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-start_time"
        }

        return httpClient
            .get(path + url, { headers: { need_loading: true } })
            .then((res) => {

                let fileName = '巡检记录_' + new Date().getTime()
                let columns = ['装置', '设备编号', '设备名称', '单元', '参数', '标准值', '值', '单位', '时间', '巡检人']
                let data = []
                if (res.status === 200 && res.data && res.data.list.length > 0) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        let updateTime = res.data.list[i].updateTime ? getDateByTimestamp(res.data.list[i].updateTime, true) : ''
                        let cell = [
                            res.data.list[i].apparatusName,
                            res.data.list[i].deviceNo,
                            res.data.list[i].deviceName,
                            res.data.list[i].unitName,
                            res.data.list[i].itemName,
                            _renderLogic(res.data.list[i].template) + _renderValues(res.data.list[i].standardValue),
                            res.data.list[i].val,
                            res.data.list[i].itemUnit,
                            updateTime,
                            res.data.list[i].creatorName
                        ];
                        data.push(cell)
                    }
                }
                dispatch(csv(fileName, columns, data));

            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检记录获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const initRecordListPage = createAction('@@xcloud/onsiteInspectionPlan/initRecordListPage',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getDeviceUnit()),
        ]).then(() => {
            let planId = getState().onsiteInspectionPlan.currentPlan.planId;
            dispatch(getRecordList(0, 15, "update_time", planId, true))

        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化巡检路线搜索失败'));
            return [];
        });
    });


/**
 * 初始化路线列表页面
 */
export const initPlanListPage = createAction('@@xcloud/onsiteInspectionPlan/initPlanListPage',
    (query, reload) => (dispatch, getState) => {

        const promiseArr = [
            //初始化页面头部
            dispatch(getDepartment()),
            dispatch(getRouteList()),
            dispatch(getUsers()),
            dispatch(setPlanPanelOpen(false))
        ]
        if (!reload) {
            promiseArr.push(
                dispatch(resetSearch())
            )
        }

        Promise.all(promiseArr).then(() => {
            dispatch(getPlanList(query))
        }).catch(err => {
            debugger
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化巡检计划搜索失败'));
            return [];
        });
    });

export const resetSearch = createAction('@@xcloud/onsiteInspectionPlan/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().deviceUseStatus.search;

        // let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        const companyId = getState().auth.currentUserInfo.companyId;
        const company = _.find(getState().constant.companies, { 'companyId': companyId });
        const hasPcomp = company && company.pid;

        let newSearch = {
            ...search,
            companyId: hasPcomp ? companyId : 0,
            inspectionType: "",
            routeId: "",
            dptId: "",
            status: "",
            userId: null,
            fromDate: moment()().startOf('week').toDate(),
            toDate: moment()().toDate(),
            isCurrent: false,
            offset: 0,
            size: 15
        }

        dispatch(setSearch(newSearch));
        dispatch(setSearchUrl(''));
        dispatch(backToList(''));
    }
);

export const insertPlan = createAction('@@xcloud/onsiteInspectionPlan/insertPlan',
    (data) => (dispatch, getState, httpClient) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let dto = {
            planId: 0,
            dptId: data.onsitePlanDpt[0].dptId,
            inspectionType: data.inspectionType,
            routeId: data.routeId,
            companyId: companyId,
            expiredAt: data.expiredAt ? _.toInteger(data.expiredAt) : undefined,
            startTime: new Date(data.startTime),
        }
        let url = `/api/onsiteinspections/plans`
        return httpClient
            .post(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 201) {
                    dispatch(setPlanPanelOpen(false))
                    dispatch(getPlanList({ offset: 0, size: 15, sort: "-start_time" }))
                    dispatch(createMessage('success', '创建巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '创建巡检路线失败'));
                return [];
            });
    }
);


export const modifyPlan = createAction('@@xcloud/onsiteInspectionPlan/modifyPlan',
    (data, rule) => (dispatch, getState, httpClient) => {
        //let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        let dto = {
            planId: data.planId,
            tenantId: data.tenantId,
            dptId: data.dptId,
            inspectionType: data.inspectionType,
            routeId: data.routeId,
            expiredAt: data.expiredAt ? _.toInteger(data.expiredAt) : undefined,
            startTime: new Date(data.startTime),
        }
        let url = `/api/onsiteinspections/plans/${data.planId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setPlanPanelOpen(false))
                    dispatch(getPlanList({ offset: 0, size: 15, sort: "-start_time" }))
                    dispatch(createMessage('success', '更新巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新巡检计划失败'));
                return [];
            });
    }
);

export const deletePlan = createAction('@@xcloud/onsiteInspectionPlan/deletePlan',
    (data) => (dispatch, getState, httpClient) => {
        //let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let dto = {
            planId: data.planId,
            status: "inactive",
            tenantId: data.tenantId
        }
        let url = `/api/onsiteinspections/plans/${data.planId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setPlanPanelOpen(false))
                    dispatch(getPlanList({ offset: 0, size: 15, sort: "-start_time" }))
                    dispatch(createMessage('success', '删除巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '删除巡检计划失败'));
                return [];
            });
    }
);

export const getDeviceUnit = createAction('@@xcloud/onsiteInspectionPlan/getDeviceUnit',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '设备运行参数单位获取失败'));
                return [];
            });
    });

export const setSearch = createAction('@@xcloud/onsiteInspectionPlan/setSearch');

export const setCurrentPlan = createAction('@@xcloud/onsiteInspectionPlan/setCurrentPlan');

export const setRecordListSize = createAction('@@xcloud/onsiteInspectionPlan/setRecordListSize');

export const setPlanPanelOpen = createAction('@@xcloud/onsiteInspectionPlan/setPlanPanelOpen');

export const setPlanError = createAction('@@xcloud/onsiteInspectionPlan/setPlanError');

export const initView = createAction('@@xcloud/onsiteInspectionPlan/initView',
    (data) => (dispatch, getState) => {
        let plans = getState().onsiteInspectionPlan.planList;
        let plan = _.find(plans.list, ['planId', data.planId])
        dispatch(setCurrentPlan(plan));
        dispatch(push('/qc/record/' + plan.planId));
    }
);

export const initIndoorRecordPage = createAction('@@xcloud/onsiteInspectionPlan/initIndoorRecordPage',
    (planId) => (dispatch, getState) => {
        dispatch(createloading());
        let plan = getState().onsiteInspectionPlan.currentPlan;
        const isExpired = getMoment()().isAfter(getMoment()(plan.startTime).add(plan.expiredAt, 'minutes'));
        const isPlanEditable = !['done', 'expired', 'pending'].includes(plan.status);
        const defaultOpentext = isExpired && isPlanEditable ? "超时" : "";
        plan.opentext = plan.opentext ? plan.opentext : defaultOpentext;
        Promise.all([
            dispatch(setRecordHistoryList([])),
            dispatch(setIndoorInspectionItemList([])),
            dispatch(getRecordList(0, 0, "update_time", plan.planId, false)),
            dispatch(getHistoryRecordList(15, plan.routeId, plan.startTime)),
            dispatch(getPointList(plan.routeId, false)),
            dispatch(getDeviceUnit())
        ]).then(() => {
            const inspectionItemList = getState().onsiteInspection.inspectionItemList;
            const recordList = getState().onsiteInspectionPlan.recordList.list;
            const recordHistoryList = getState().onsiteInspectionPlan.recordHistoryList;

            const timeout = moment()().isAfter(moment()(plan.startTime).add(plan.expiredAt, 'minutes'));

            // 巡检计划多次修改时装置状态发生变动，以第一次巡检发生时的状态为准，没巡检则按实时状态为准（前提是默认所有修改和补录都是针在对彼时彼刻的巡检）
            const histInsUseStatus = recordList && recordList.length && recordList.length > 0 ? recordList[0].histInsUseStatus : undefined;
            const newList = [];
            if (inspectionItemList && inspectionItemList.length) {
                // 组装item <-> record <-> history
                inspectionItemList.forEach(item => {
                    if (item.genItem) {
                        return // 内操遇到定性内容就continue
                    }
                    const {
                        deviceUseStatus,
                        installUseStatus,
                        ignoreInstall
                    } = item;

                    const record = _.find(recordList, { 'itemId': item.itemId });
                    // 计划时间内填写
                    const editInsInUseStatus = histInsUseStatus ? histInsUseStatus : installUseStatus
                    if (['active', 'new'].includes(plan.status) && !timeout) {
                        // 排除掉装置及巡检点为offuse的记录，保留常检记录
                        if (deviceUseStatus === 'INUSE' && (editInsInUseStatus === 'INUSE' || ignoreInstall)) {
                            const newItem = _.cloneDeep(item);

                            newItem['err'] = '';
                            newItem['recordId'] = record && record.recordId ? record.recordId : 0;
                            newItem['histInsUseStatus'] = record && record.histInsUseStatus ? record.histInsUseStatus : undefined;

                            let recentVal = '';
                            Object.keys(recordHistoryList).forEach((key, index) => {
                                const history = recordHistoryList[key];
                                newItem[key] = history[item.itemKey];
                                // 最后一条历史记录作为默认值填充
                                if (index === Object.keys(recordHistoryList).length - 1) {
                                    recentVal = history[item.itemKey] ? history[item.itemKey].value : undefined;
                                }
                            })
                            // 编辑用值
                            newItem['val'] = record && record.val ? record.val : recentVal;
                            // 显示用值
                            newItem['valBackup'] = record && record.val ? record.val : '';
                            newItem['status'] = record && record.status ? record.status : 'active';
                            newList.push(newItem);
                        }
                    } else {
                        // 计划时间外没有限制
                        const newItem = _.cloneDeep(item);

                        newItem['err'] = '';
                        newItem['recordId'] = record && record.recordId ? record.recordId : 0;

                        let recentVal = '';
                        Object.keys(recordHistoryList).forEach((key, index) => {
                            const history = recordHistoryList[key];
                            newItem[key] = history[item.itemKey];
                            // 最后一条历史记录作为默认值填充
                            if (index === Object.keys(recordHistoryList).length - 1) {
                                recentVal = history[item.itemKey] ? history[item.itemKey].value : undefined;
                            }
                        })
                        // 编辑用值
                        newItem['val'] = record && record.val ? record.val : recentVal;
                        // 显示用值
                        newItem['valBackup'] = record && record.val ? record.val : '';
                        newItem['status'] = record && record.status ? record.status : 'active';
                        newList.push(newItem);
                    }
                })
            }
            Promise.all([
                dispatch(setIndoorInspectionItemList(newList)),
                dispatch(setCurrentPlan(plan))
            ]).then(res => {
                dispatch(cleanloading())
            })
        }).catch(err => {
            dispatch(cleanloading());
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化内操巡检记录失败'));
            return [];
        });
    });

export const initRecord = createAction('@@xcloud/onsiteInspectionPlan/initRecord',
    (plan) => (dispatch, getState, httpClient) => {
        // let plans = getState().onsiteInspectionPlan.planList;
        // let plan = _.find(plans.list, { 'planId': data.planId });
        let userProfile = getState().auth.userProfile;
        const promiseArray = [];
        if (!plan.inspector) {
            // 如果没有inspector则设置inspector并开始该计划
            let newPlan = {
                planId: plan.planId,
                tenantId: plan.tenantId,
                inspector: userProfile.userId,
                status: 'active'
            }
            let url = `/api/onsiteinspections/plans/${plan.planId}`
            promiseArray.push(
                httpClient
                    .patch(url, newPlan)
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setCurrentPlan(res.data));
                            dispatch(createMessage('success', '认领内操记录成功'));
                        }
                        return res.data;
                    }).catch(err => {
                        dispatch(createhttpMessage(err.response ? err.response : '', '认领内操记录失败'));
                        return [];
                    })
            )
        } else {
            promiseArray.push(
                dispatch(setCurrentPlan(plan))
            );
        }
        Promise.all(
            promiseArray
        ).then(() => {
            dispatch(push('/qc/indoor/' + plan.planId));
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化内操巡检页面失败'));
            return [];
        });
    }
);

export const submitInspectionRecords = createAction('@@xcloud/onsiteInspectionPlan/submitInspectionRecords',
    (planId, records, submitStatus) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let currentPlan = getState().onsiteInspectionPlan.currentPlan;
        let url = `/api/onsiteinspections/plans/${planId}/records/submitstatus/${submitStatus}`
        return httpClient
            .post(url, records)
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 201) {
                    dispatch(updatePlanOpentext(currentPlan));
                    // dispatch(getPlanList({ offset: 0, size: 15, sort: "-start_time" }))
                    if (submitStatus !== currentPlan.status && ['pending', 'done'].includes(submitStatus)) {
                        dispatch(backToList(''));
                    } else {
                        dispatch(initIndoorRecordPage());
                    }
                    dispatch(createMessage('success', '保存内操记录成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(cleanloading());
                debugger
                dispatch(createhttpMessage(err.response ? err.response : '', '保存内操记录失败'));
                return [];
            });
    }
);


export const updatePlanOpentext = createAction('@@xcloud/onsiteInspectionPlan/setPlanOpentext',
    (data) => (dispatch, getState, httpClient) => {
        //let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        let dto = {
            planId: data.planId,
            tenantId: data.tenantId,
            dptId: data.dptId,
            inspectionType: data.inspectionType,
            routeId: data.routeId,
            opentext: data.opentext,
        }
        let url = `/api/onsiteinspections/plans/${data.planId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {

                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新巡检计划失败'));
                return [];
            });
    }
);


export const backToList = createAction('@@xcloud/onsiteInspectionPlan/backToList',
    (id) => (dispatch, getState) => {
        // let searchUrl = getState().onsiteInspectionPlan.searchUrl;
        // dispatch(push('/qc/plan' + searchUrl, { isBack: true }));
        dispatch(push('/qc/plan', { isBack: true }));
    }
);

export const setSearchUrl = createAction('@@xcloud/onsiteInspectionPlan/setSearchUrl');

export const setRecordHistoryList = createAction('@@xcloud/onsiteInspectionPlan/setRecordHistoryList');


/********************* onsiteInspectionPlan Reducer *********************/

export const planListReducer = handleActions(
    {
        [getPlanList]: (state, { payload: value }) => {
            return {
                ...state,
                planList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setCurrentPlan]: (state, { payload: value }) => {
            return {
                ...state,
                currentPlan: value
            };
        },
        [setRecordListSize]: (state, { payload: value }) => {
            return {
                ...state,
                recordListSize: value
            };
        },
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getDepartment]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
        [getUsers]: (state, { payload: value }) => {
            return {
                ...state,
                users: value
            };
        },
        [getRecordList]: (state, { payload: value }) => {
            return {
                ...state,
                recordList: value
            };
        },
        [setRecordHistoryList]: (state, { payload: value }) => {
            return {
                ...state,
                recordHistoryList: value
            };
        },
        [setPlanPanelOpen]: (state, { payload: value }) => {
            return {
                ...state,
                planPanelOpen: value
            };
        },
        [getAllLeaders]: (state, { payload: value }) => {
            return {
                ...state,
                leaders: value
            };
        },
        [setPlanError]: (state, { payload: value }) => {
            return {
                ...state,
                planError: value
            };
        },
        [setSearchUrl]: (state, { payload: value }) => {
            return {
                ...state,
                searchUrl: value
            };
        },
        [getDeviceUnit]: (state, { payload: value }) => {
            return {
                ...state,
                unit: value.list,
                unitTree: value.tree
            };
        },
    },
    defaultState
);

export default planListReducer;