import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { localization } from "../../../utils/mtable";
import { getDateByTimestamp } from '../../../utils/datetime';
import _ from 'lodash';
import {
    Grid,
    Button,
    Typography,
    TablePagination,
    Card,
    Divider,
    Chip
} from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';

const RecordListComponent = props => {
    const {
        classes,
        //userProfile,
        companyList,
        inspectionDictionaries,
        recordList,
        getRecordList,
        onSizeChange,
        currentPlan,
        onhandleBack,
        onhandleExport,
        onhandleDownload,
        users,
        departmentList,
        unit
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route' })
    //     return permission && permission.action === 'W'
    // };

    const renderCompany = (companyId) => {
        let item = _.filter(companyList, function (o) { return companyId === o.value })
        if (item && item.length) {
            return item[0].label
        } else {
            return ''
        }
    };

    const renderDepName = (inspector) => {
        let item = _.filter(users.list, function (o) { return inspector === o.userId })
        if (item && item.length) {
            let cell = _.filter(departmentList, function (o) { return item[0].dptId === o.dptId })
            if (cell && cell.length) {
                return cell[0].dptName
            } else {
                return ''
            }
            //return item[0].label
        } else {
            return ''
        }
    };

    // 校验巡检数值
    const validateVal = (row) => {
        // console.log("XXXXXXXXXXXX ---- row", row)

        let validResult = true
        let val = row.val

        if (val === "不合格" || val === "未知") {
            return false
        }

        if (val === "合格") {
            return true
        }

        let decimalVal = _.toNumber(val)

        let operatorArray = row.template.split("|")
        let stdValArry = row.standardValue.split(",")

        operatorArray.forEach((operator, idx) => {
            let decimalStdVal = _.toNumber(stdValArry[idx])
            let subOperator = operator.substring(0, 2)

            // console.log("XXXXXXXXXXXXX ---- subOperator", subOperator)
            // console.log("XXXXXXXXXXX --- decimalStdVal", decimalStdVal)
            // console.log("XXXXXXXXXXXXX ---- decimalVal", decimalVal)

            switch (subOperator) {
                case '<{':
                    validResult = (validResult && decimalVal < decimalStdVal)
                    break;
                case '<=':
                    validResult = (validResult && decimalVal <= decimalStdVal)
                    break;
                case '={':
                    validResult = (validResult && decimalVal === decimalStdVal)
                    break;
                case '>{':
                    validResult = (validResult && decimalVal > decimalStdVal)
                    break;
                case '>=':
                    validResult = (validResult && decimalVal >= decimalStdVal)
                    break;
                default:
                    break;
            }
        })

        return validResult
    }

    const assemblycolumns = () => {
        return [
            {
                title: '装置',
                field: 'apparatusName',
                render: row => row ? <Typography>{row.apparatusName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单元',
                field: 'unitName',
                render: row => row ? <Typography>{row.unitName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备组',
                field: 'deviceGroupName',
                render: row => row ? <Typography>{row.deviceGroupName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '参数',
                field: 'itemName',
                render: row => row ? <Typography>{row.itemName}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '标准值',
            //     field: 'standardValue',
            //     render: row => row ? <Typography>{row.standardValue}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: 'template',
            //     field: 'template',
            //     render: row => row ? <Typography>{row.template}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '值',
                field: 'val',
                //render: row => row ? <Typography>{row.val}</Typography> : '',
                render: row => {
                    if (row.status === 'invalid') {
                        return <Chip size="small" className={classes.invalidRecord} label={'不涉及'} />
                    } else {
                        return !validateVal(row) ? <Chip size="small" className={classes.pending} label={row.val} /> : <Typography>{row.val}</Typography>
                    }
                    // return row.val !== row.standardValue ? <Chip size="small" className={classes.pending} label={row.val} /> : <Typography>{row.val}</Typography>
                },
                editable: 'never'
            },
            {
                title: '单位',
                field: 'itemUnit',
                render: row => row ? <Typography>{renderUnit(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '时间',
                field: 'updateTime',
                render: row => row ? <Typography>{renderRowTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述信息',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            }
        ];
    };

    const renderInspectionType = (inspectionType) => {
        let item = _.filter(inspectionDictionaries, function (o) { return inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    };

    const renderTime = (time) => {
        return time ? getDateByTimestamp(time, true) : ''
    }

    const renderRowTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }

    const renderUnit = (row) => {
        let label = '';
        if (unit && unit.length > 0) {
            let value = _.find(unit, function (o) { return o.code === row.itemUnit })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <div>
                    <div>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            巡检记录
                        </Typography>
                    </div>
                    <div>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    所属单位
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderCompany(currentPlan.companyId)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    巡检类型
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderInspectionType(currentPlan.inspectionType)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    巡检路线
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {currentPlan.routeName}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    执行班组
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderDepName(currentPlan.inspector)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    巡检人
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom className={classes.label}>
                                    {currentPlan.inspectorName}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    开始时间
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderTime(currentPlan.startTime)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    结束时间
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderTime(currentPlan.endTime)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid container spacing={0} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        所属单位
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {renderCompany(currentPlan.companyId)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        巡检类型
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {renderInspectionType(currentPlan.inspectionType)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        巡检路线
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {currentPlan.routeName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        执行班组
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {currentPlan.dptName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        巡检人
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {currentPlan.inspectorName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        开始时间
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {renderTime(currentPlan.startTime)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color='textPrimary' className={classes.label}>
                                        结束时间
                                    </Typography>
                                    <Grid item xs={11}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {renderTime(currentPlan.endTime)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </div>
                    <Divider variant="middle" />
                </div>

                <div alignitems="center" className={classes.planitemroot}>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            columns={assemblycolumns()}
                            data={recordList.list}
                            options={{
                                pageSize: rowsPerPage,
                                actionsColumnIndex: -1,
                                search: false,
                            }}
                            localization={localization}
                            style={{
                                border: '0px solid black',
                                boxShadow: 'none'
                            }}
                            components={{
                                Pagination: props => (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowsPerPage={rowsPerPage}
                                        count={recordList.total}
                                        page={page}
                                        onChangePage={(event, pageNum) => {
                                            setPage(pageNum);
                                            getRecordList(pageNum * rowsPerPage, rowsPerPage, "update_time", currentPlan.planId)

                                        }}
                                        onChangeRowsPerPage={(event) => {
                                            props.onChangeRowsPerPage(event);
                                            setRowsPerPage(event.target.value);
                                            setPage(0);
                                            onSizeChange(event.target.value)
                                            getRecordList(0, event.target.value, "update_time", currentPlan.planId)
                                        }}
                                    />
                                )
                            }}
                            actions={[
                                rowData => ({
                                    icon: AttachFile,
                                    tooltip: '查看附件',
                                    disabled: !rowData.docs.length && rowData.docs.length === 0,
                                    onClick: (event, rowData) => {
                                        onhandleDownload(event, rowData.docs, false)
                                    }
                                }),
                                {
                                    icon: 'save_alt',
                                    tooltip: '导出',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        onhandleExport(currentPlan.planId)
                                    }
                                }
                            ]}
                        >
                        </MaterialTable>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={onhandleBack}
                            >
                                返回
                                </Button>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div >
    );
};

RecordListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    //userProfile: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    recordList: PropTypes.object.isRequired,
    getRecordList: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    currentPlan: PropTypes.object.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleExport: PropTypes.func.isRequired,
    onhandleDownload: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
    departmentList: PropTypes.array.isRequired,
    unit: PropTypes.array.isRequired,
};

export default withRouter(withStyles(RecordListComponent));