import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initActivityHeader, setSearch, resetSearch, getActivityList, onCreateActivity, getActivityById, setActivityTaskOpen, setActivityTask, createActivityTask } from '../../../redux/modules/customerActivities';
import ActivitiesList from "../../../components/Crm/Activities/ActivitiesList/ActivitiesList";
import ActivityPanelComponent from '../../../components/Crm/Activities/ActivityPanel';
import _ from 'lodash';

import {
  getMoment as moment
} from '@/utils/datetime';

class ActivitiesListContainer extends Component {

  static propTypes = {
    search: PropTypes.object,
    userProfile: PropTypes.object,
    activityTask: PropTypes.object,
    activityTaskOpen: PropTypes.bool,
  };

  componentDidMount() {
    this.props.onhandleInitActivityHeader()
  }

  handleSearch(event) {
    this.props.onhandleSearch();
  }

  handleClearSearch() {
    this.props.onhandleClearSearch();
  }

  handleCreateActivity() {
    this.props.onCreateActivity();
  }

  handleSelectAct(actId) {
    this.props.activityList.map((activity) => {
      return activity.selected = activity.actId === actId;
    });
    //console.log(this.props.activityList)
    this.props.onSelectActivity(actId);
  }

  handleChange = (event, field) => {
    let newSearch = { ...this.props.search };
    switch (field) {
      case 'actName':
        newSearch.actName = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'actHead':
        newSearch.actHead = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'search':
        newSearch.search = event.target.value;
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleDateChange(value, field) {
    let newSearch = { ...this.props.search };

    const date = moment()(value);
    switch (field) {
      case 'fromDate':
        newSearch.fromDate = date.startOf('day').format('YYYY-MM-DD');
        break;
      case 'toDate':
        if (
          date.isBefore(moment()(newSearch.fromDate))
        ) {
          newSearch.toDate = moment()(newSearch.fromDate).endOf('day').format('YYYY-MM-DD');
        } else {
          newSearch.toDate = date.endOf('day').format('YYYY-MM-DD');
        }
        break;
      default:
      // do nothing
    }
    this.props.onhandleUpdateEditSearch(newSearch);
  }

  handleActivityTaskChange = (event, field) => {
    let newActivityTask;
    switch (field) {
      case 'taskName':
        newActivityTask = {
          ...this.props.activityTask,
          taskName: event.target.value
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      case 'taskType':
        newActivityTask = {
          ...this.props.activityTask,
          taskType: event.target.value
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      case 'taskTime':
        newActivityTask = {
          ...this.props.activityTask,
          taskTime: event
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      case 'taskLocation':
        newActivityTask = {
          ...this.props.activityTask,
          taskLocation: event.target.value
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      case 'budget':
        newActivityTask = {
          ...this.props.activityTask,
          budget: _.toNumber(event.target.value)
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      case 'taskHead':
        newActivityTask = {
          ...this.props.activityTask,
          taskHead: event ? event.userId : null
        };
        this.props.onhandleUpdateActivityTask(newActivityTask);
        break;
      default:
      // do nothing
    }
  };

  handleCloseActivityTask() {
    this.props.onhandleActivityTaskOpen(false);
  }

  handleOpenActivityTask() {
    this.props.onhandleActivityTaskOpen(true);
  }

  handleSaveActivityTask() {
    this.props.onCreateActivityTask();
  }

  render() {
    let ActivityPannel = ActivityPanelComponent
    return (
      <div>
        <ActivitiesList
          userProfile={this.props.userProfile}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          onhandleChange={this.handleChange.bind(this)}
          onhandleDateChange={this.handleDateChange.bind(this)}
          onSelectAct={this.handleSelectAct.bind(this)}
          search={this.props.search}
          activityList={this.props.activityList}
          onhandleCreateActivity={this.handleCreateActivity.bind(this)}
          onhandleOnCreateActivityTask={this.handleOpenActivityTask.bind(this)} />

        <ActivityPannel
          constant={this.props.constant}
          open={this.props.activityTaskOpen}
          activityTask={this.props.activityTask}
          onhandleClose={this.handleCloseActivityTask.bind(this)}
          onhandleActivityTaskChange={this.handleActivityTaskChange.bind(this)}
          onhandleSave={this.handleSaveActivityTask.bind(this)}
          users={this.props.users}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    search: state.customerActivities.search,
    activityList: state.customerActivities.activityList,
    activityTaskOpen: state.customerActivities.activityTaskOpen,
    activityTask: state.customerActivities.activityTask,
    users: state.user.users.list,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitActivityHeader: (query, reload) => {
      dispatch(initActivityHeader(query, reload));
    },
    onhandleUpdateEditSearch: (value) => {
      dispatch(setSearch(value));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: (query) => {
      dispatch(getActivityList(query));
    },
    onCreateActivity: () => {
      dispatch(onCreateActivity());
    },
    onSelectActivity: (actId) => {
      dispatch(getActivityById(actId));
    },
    onhandleActivityTaskOpen: (flag) => {
      dispatch(setActivityTaskOpen(flag));
    },
    onhandleUpdateActivityTask: (activityTask) => {
      dispatch(setActivityTask(activityTask));
    },
    onCreateActivityTask: () => {
      dispatch(createActivityTask());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesListContainer)
