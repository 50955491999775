import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ApprovePannelWithDoc from '../components/ApprovePannelWithDoc';
import { closeApprovePannel, changeComment, changeDocInfo } from '../redux/modules/approvePannel';
import { createmodal, cleanmodal } from '@/redux/modules/modal';

class ApprovePannelContainer extends Component {
    static propTypes = {
        open: PropTypes.bool,
        title: PropTypes.string,
        comment: PropTypes.string,
        withDoc: PropTypes.bool,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    handleConfirm() {
        const { confirmCallback, closeApprovePannel, comment, docInfo } = this.props;
        // final execute function from other component or redux-action
        if (confirmCallback) confirmCallback(comment, docInfo);
        // close action
        if (closeApprovePannel) closeApprovePannel();
    }

    handleClose() {
        const { closeApprovePannel, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (closeApprovePannel) closeApprovePannel();
        // close action
        if (closeCallBack) closeCallBack();
    }
    handleChangeComment(event) {
        const { changeComment } = this.props;
        if (changeComment) changeComment(event.target.value);
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleUploadConfirm(files, setUploadStatus) {
        const { docInfo, changeDocInfo } = this.props;
        let newDoc = {
            ...docInfo
        }
        newDoc = files[0];

        changeDocInfo(newDoc);

        this.props.onhandleCloseFileUpload();
    }

    render() {
        return (
            <ApprovePannelWithDoc
                open={this.props.open}
                title={this.props.title}
                docInfo={this.props.docInfo}
                comment={this.props.comment}
                withDoc={this.props.withDoc}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
                onhandleChangeComment={this.handleChangeComment.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.approvePannel.openWithDoc,
        title: state.approvePannel.title,
        comment: state.approvePannel.comment,
        docInfo: state.approvePannel.docInfo,
        docError: state.approvePannel.docError,
        withDoc: state.approvePannel.withDoc,
        confirmCallback: state.approvePannel.confirmCallback,
        closeCallback: state.approvePannel.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeApprovePannel: () => {
            dispatch(closeApprovePannel());
        },
        changeComment: (comment) => {
            dispatch(changeComment(comment));
        },
        changeDocInfo: (doc) => {
            dispatch(changeDocInfo(doc));
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovePannelContainer);