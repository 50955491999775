import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  label: {
    marginLeft: theme.spacing(1),
    wrap : 'nowrap'
  },
  content: {
    marginTop: theme.spacing(0)
  }
});

export default withStyles(styles);
