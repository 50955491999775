import { createAction, handleActions } from 'redux-actions';
import { getRoleTree } from './role';
import { createhttpMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import { cleanmodal } from './modal';
import { createloading, cleanloading } from '@/redux/modules/loading';
// import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    userList: {
        total: 0,
        list: []
    },
    search: {
        companyId: undefined,
        groupId: undefined,
        roleId: undefined,
        dptId: undefined,
        unitId: undefined,
        userName: "",
        name: "",
        roleTree: [],
        groupList: [],
        roleList: [],
        // fullroleList: [],
        fulldepartmentList: []
    },
    selectedUsers: [],
}

export const initUserSelectList = createAction('@@xcloud/userSelect/initUserSelectList',
    () => (dispatch, getState) => {
        dispatch(createloading())
        Promise.all([
            dispatch(getRoleTree()),
            dispatch(resetSearch()),
            dispatch(setSelectedUsers([])),
            dispatch(setUserList({ total: 0, list: [] }))
        ]).then((data) => {
            let { companyId } = getState().userSelect.search;
            let roles = getState().role.roleTree;
            let groupList = [];
            let roleList = [];
            roles
                .filter(element => {
                    if (companyId) return element.companyId === companyId
                    return true
                })
                .forEach(element => {
                    const rolePrefix = element.companyAlias.substring(0, 2);
                    if (element.roleGroups) {
                        element.roleGroups.forEach(group => {
                            groupList.push({
                                cname: group.groupName.includes(rolePrefix) ? group.groupName : rolePrefix + group.groupName,
                                name: group.groupName,
                                code: group.groupId
                            });
                            if (group.roleList) {
                                group.roleList.forEach(role => {
                                    roleList.push({
                                        cname: role.roleName.includes(rolePrefix) ? role.roleName : rolePrefix + role.roleName,
                                        name: role.roleName,
                                        code: role.roleId
                                    });
                                })
                            }
                        })
                    }
                })
            dispatch(setGroupList(groupList));
            dispatch(setRoleList(roleList));
            dispatch(setFullGroupList(groupList));
            // dispatch(setFullRoleList(roleList));
            let departments = getState().constant.departments;

            let departmentList = [];
            departments
                .filter(dpt => {
                    if (companyId) return dpt.companyId === companyId
                    return true
                })
                .forEach(dpt => {
                    departmentList.push({
                        cname: dpt.companyDptName,
                        name: dpt.dptName,
                        code: dpt.dptId
                    })
                })
            dispatch(setDepartmentList(departmentList));
            dispatch(cleanloading());
        })
            .catch(err => {
                dispatch(cleanloading());
                let msg = '初始化用户搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const getUserList = createAction(
    '@@xcloud/userSelect/getUserList',
    (sort, companyId, groupId, roleId, dptId, unitId, userName, name
    ) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let search = getState().userSelect.search;
        //条件检索
        companyId = search.companyId ? search.companyId : companyId
        groupId = search.groupId ? search.groupId : groupId
        roleId = search.roleId ? search.roleId : roleId
        dptId = search.dptId ? search.dptId : dptId
        unitId = search.unitId ? search.unitId : unitId
        userName = search.userName ? search.userName : userName
        name = search.name ? search.name : name

        let url = "/users?offset=0&size=0"

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (groupId && groupId !== "") {
            url += "&groupId=" + groupId
        }
        if (roleId && roleId !== "") {
            url += "&roleId=" + roleId
        }
        if (dptId && dptId !== "") {
            url += "&dptId=" + dptId
        }
        if (unitId && unitId !== "") {
            url += "&unitId=" + unitId
        }
        if (userName && userName !== "") {
            url += "&userName=" + userName
        }
        if (name && name !== "") {
            url += "&name=" + name
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=-update_time"
        }
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200) {
                    dispatch(setUserList(res.data));
                    return res.data;
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `用户列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const resetSearch = createAction('@@xcloud/userSelect/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let parameters = getState().modal.parameters;
        if (Array.isArray(parameters.companyId)) {
            // companyId = parameters.companyId[0]
        } else if (!isNaN(parameters.companyId)) {
            companyId = parameters.companyId
        }
        let search = getState().userSelect.search;
        let newSearch = {
            ...search,
            // companyId: undefined,
            companyId: companyId ? companyId : undefined,
            groupId: undefined,
            roleId: undefined,
            dptId: undefined,
            unitId: undefined,
            userName: "",
            name: ""
        }
        return dispatch(setSearch(newSearch));
    }
);

export const resetSearchField = createAction('@@xcloud/userSelect/resetSearchField',
    (value, field) => (dispatch, getState, httpClient) => {
        if (field === 'company') {
            if (!value || value === '') {
                //reset role group
                let roles = getState().role.roleTree;
                let groupList = [];
                let roleList = [];
                roles.forEach(element => {
                    if (element.roleGroups) {
                        const rolePrefix = element.companyAlias.substring(0, 2);
                        element.roleGroups.forEach(group => {
                            groupList.push({
                                cname: group.groupName.includes(rolePrefix) ? group.groupName : rolePrefix + group.groupName,
                                name: group.groupName,
                                code: group.groupId
                            });
                            if (group.roleList) {
                                group.roleList.forEach(role => {
                                    roleList.push({
                                        cname: role.roleName.includes(rolePrefix) ? role.roleName : rolePrefix + role.roleName,
                                        name: role.roleName,
                                        code: role.roleId
                                    });
                                })
                            }
                        })
                    }
                })
                dispatch(setGroupList(groupList));
                dispatch(setRoleList(roleList));
                //reset department
                let departments = getState().constant.departments;
                let departmentList = [];
                departments.forEach(dpt => {
                    departmentList.push({
                        cname: dpt.companyDptName,
                        name: dpt.dptName,
                        code: dpt.dptId
                    })
                })
                dispatch(setDepartmentList(departmentList));
            } else {
                let roles = getState().role.roleTree;
                let groupList = [];
                let roleList = [];
                roles
                    .filter(element => { return element.companyId === value })
                    .forEach(element => {
                        const rolePrefix = '';
                        if (element.companyAlias !== null) element.companyAlias.substring(0, 2);
                        if (element.roleGroups && element.companyId === value) {
                            element.roleGroups.forEach(group => {
                                groupList.push({
                                    cname: group.groupName.includes(rolePrefix) ? group.groupName : rolePrefix + group.groupName,
                                    name: group.groupName,
                                    code: group.groupId
                                });
                                if (group.roleList) {
                                    group.roleList.forEach(role => {
                                        roleList.push({
                                            cname: role.roleName.includes(rolePrefix) ? role.roleName : rolePrefix + role.roleName,
                                            name: role.roleName,
                                            code: role.roleId
                                        });
                                    })
                                }
                            })
                        }
                    })
                dispatch(setGroupList(groupList));
                dispatch(setRoleList(roleList));
                let departments = getState().constant.departments;
                let departmentList = [];
                departments
                    .filter(dpt => { return dpt.companyId === value })
                    .forEach(dpt => {
                        if (dpt.companyId === value) {
                            departmentList.push({
                                cname: dpt.companyDptName,
                                name: dpt.dptName,
                                code: dpt.dptId
                            })
                        }
                    })
                dispatch(setDepartmentList(departmentList));
                let search = getState().userSelect.search;
                let newSearch = {
                    ...search,
                    dptId: '',
                    groupId: '',
                    roleId: ''
                }
                dispatch(setSearch(newSearch));
            }
        } else if (field === 'roleGroup') {
            let roles = getState().role.roleTree;
            let { companyId } = getState().userSelect.search;
            let roleList = [];
            roles
                .filter(role => {
                    if (companyId) return role.companyId === companyId
                    return true
                })
                .forEach(element => {
                    if (element.roleGroups) {
                        const rolePrefix = element.companyAlias.substring(0, 2);
                        element.roleGroups.forEach(group => {
                            if (value === "" || (group.groupId === value && group.roleList)) {
                                group.roleList.forEach(role => {
                                    roleList.push({
                                        cname: role.roleName.includes(rolePrefix) ? role.roleName : rolePrefix + role.roleName,
                                        name: role.roleName,
                                        code: role.roleId
                                    });
                                })
                            }
                        })
                    }
                })
            dispatch(setRoleList(roleList));
            let search = getState().userSelect.search;
            let newSearch = {
                ...search,
                roleId: ''
            }
            dispatch(setSearch(newSearch));
        }
    });

export const setSearch = createAction('@@xcloud/userSelect/setSearch');

export const setUserList = createAction('@@xcloud/userSelect/setUserList');
export const setSelectedUsers = createAction('@@xcloud/userSelect/selectedUsers');
export const setGroupList = createAction('@@xcloud/userSelect/setGroupList');
export const setRoleList = createAction('@@xcloud/userSelect/setRoleList');
export const setDepartmentList = createAction('@@xcloud/userSelect/setDepartmentList');
export const setRoleTree = createAction('@@xcloud/userSelect/setRoleTree');
export const setDepartmentTree = createAction('@@xcloud/userSelect/setDepartmentTree');

export const setFullGroupList = createAction('@@xcloud/user/setFullGroupList');
// export const setFullRoleList = createAction('@@xcloud/user/setFullRoleList');

export const exitSelected = createAction('@@xcloud/userSelect/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedUsers([]))
        dispatch(cleanmodal())
    }
);

export const userSelectReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setUserList]: (state, { payload: value }) => {
            return {
                ...state,
                userList: value
            };
        },
        [setSelectedUsers]: (state, { payload: value }) => {
            return {
                ...state,
                selectedUsers: value
            };
        },
        [setGroupList]: (state, { payload: value }) => {
            return {
                ...state,
                groupList: value
            };
        },
        [setRoleList]: (state, { payload: value }) => {
            return {
                ...state,
                roleList: value
            };
        },
        [setFullGroupList]: (state, { payload: value }) => {
            return {
                ...state,
                fullgroupList: value
            };
        },
        // [setFullRoleList]: (state, { payload: value }) => {
        //     return {
        //         ...state,
        //         fullRoleList: value
        //     };
        // },
        [setRoleTree]: (state, { payload: value }) => {
            return {
                ...state,
                roleTree: value
            };
        },
        [setDepartmentTree]: (state, { payload: value }) => {
            return {
                ...state,
                departmentTree: value
            };
        },
        [setDepartmentList]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
    },
    initialState
);

export default userSelectReducer;