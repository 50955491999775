import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message'
import { push } from 'connected-react-router';
import { getRoleTree } from './role';

const porxyApi = '/api'

const initialState = {
  currentProcessStatus: {},
  processes: [],
  processHistories: {},
  startProcesses: [],
  companyAllProcessStatus: {},
  isloading: false,
  processList: {
    total: 0,
    list: []
  },
  currentProcess: {},
  processError: {},
  diagramChange: false,
  processChange: false,
};

export const setProcessStart = createAction("@@xcloud/process/setprocessstart")
export const setStartProcesses = createAction("@@xcloud/process/setstartprocesses")

export const setProcess = createAction("@@xcloud/process/setprocess")
export const setProcessHistories = createAction("@@xcloud/process/setprocesshistories")
export const setCompanyAllProcessStatus = createAction("@@xcloud/process/setcompanyallprocessstatus")
export const setCurrentProcessStatus = createAction("@@xcloud/process/setcurrentprocessstatus")

export const setCurrentProcessStatusW = createAction("@@xcloud/process/setcurrentprocessstatusw")

export const getFirstProcess = createAction("@@xcloud/process/getfirstprocess",
  (processCode, stageOrder) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/processes/' + processCode + '/stages/' + stageOrder + '/actions/roles')
      .then((res) => {
        if (res) {
          dispatch(setProcessStart(res.data))
        }
      }).catch(err => {
        let msg = `获取流程控制[流程起点]失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });

export const getCurrentProcess = createAction("@@xcloud/process/getcurrentprocess",
  (processCode, callback) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/processes/' + processCode + '/records/stages/actions/roles')
      .then((res) => {
        if (res) {
          dispatch(setProcess({ data: res.data, code: processCode }))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取流程控制中的当前节点失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });


export const getProcessHistories = createAction("@@xcloud/process/getprocesshistories",
  (recordId, processCode, callback) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/processes/records/' + recordId + '/histories')
      .then((res) => {
        if (res) {
          dispatch(setProcessHistories({ data: res.data, code: processCode }))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取流程历史操作记录失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });

export const getCompanyAllProcessStatus = createAction("@@xcloud/process/getcompanyallprocessstatus",
  (processCode) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/processes/' + processCode + '/stages/actions/roles')
      .then((res) => {
        if (res) {
          dispatch(setCompanyAllProcessStatus({ status: res.data, code: processCode }))
        }
      }).catch(err => {
        let msg = `获取流程控制[流程起点]失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });

export const getCurrentProcessStatus = createAction("@@xcloud/process/getcurrentprocessstatus",
  (processCode, recordId, callback) => (dispatch, getState, httpClient) => {
    let url = porxyApi + '/processes/' + processCode + '/processStatus'
    if (recordId)
      url = url + '?recordId=' + recordId
    httpClient.get(url)
      .then((res) => {
        if (res) {
          dispatch(setCurrentProcessStatus({ processStatus: res.data, code: processCode }))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取流程控制当前状态`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });


export const getCurrentProcessStatusR = createAction("@@xcloud/process/getcurrentprocessstatusr",
  //获取规章制度计划状态，8个规章制度计划流程共用一个code
  (processCode, shareCode, recordId, callback) => (dispatch, getState, httpClient) => {
    let url = porxyApi + '/processes/' + processCode + '/processStatus'
    if (recordId)
      url = url + '?recordId=' + recordId
    httpClient.get(url)
      .then((res) => {
        if (res) {
          dispatch(setCurrentProcessStatus({ processStatus: res.data, code: shareCode }))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取流程控制当前状态`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });

// export const getCurrentProcessStatusByRecordId = createAction("@@xcloud/process/getcurrentprocessstatusbyrecordid",
//   (processCode, recordId, callback) => (dispatch, getState, httpClient) => {
//     httpClient.get(porxyApi + '/processes/' + processCode + '/processStatus/' + recordId)
//       .then((res) => {
//         if (res) {
//           dispatch(setCurrentProcessStatus({ processStatus: res.data, code: processCode }))
//           if (callback)
//             callback(res.data)
//         }
//       }).catch(err => {
//         let msg = `获取流程控制当前状态`;
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//       })
//   });

export const getProcessByRoleId = createAction("@@xcloud/process/getprocessbyroleid",
  (roleId, stageOrder) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/roles/' + roleId + '/processes?stageOrder=' + stageOrder, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res) {
          dispatch(setStartProcesses(res.data))
        }
      }).catch(err => {
        let msg = `获取流程控制[流程起点]失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
      })
  });

export const backToProcessList = createAction('@@xcloud/process/backToProcessList',
  () => (dispatch, getState) => {
    dispatch(push('/processManagement'));
  });

export const getProcesses = createAction('@@xcloud/process/getProcesses',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(setProcessChange(false));
    dispatch(setDiagramChange(false));
    let url = '/api/processes';
    if (query !== undefined) {
      if (query.offset !== undefined) {
        url = url + '?offset=' + query.offset;
      } else {
        url = url + '?offset=0';
      }
      if (query.size !== undefined) {
        url = url + '&size=' + query.size;
      }
      if (query.sort !== undefined) {
        url = url + '&sort=' + query.sort;
      }
    }

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.data) {
          dispatch(setProcessList(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '流程列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createProcess = createAction('@@xcloud/process/createProcess',
  (xml) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/processes';
    let cprocess = getState().process.currentProcess;
    cprocess.bpmn = xml;
    return httpClient.post(url, cprocess)
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentProcess(res.data));
          dispatch(stopLoading());
          dispatch(createMessage('success', '创建新流程成功'));
          dispatch(backToProcessList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        dispatch(createhttpMessage(err.response ? err.response : '', '创建新流程失败'));
        return null;
      });
  });

export const setProcessStatus = createAction('@@xcloud/process/setProcessStatus',
  (status) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let cprocess = getState().process.currentProcess;
    let url = '/api/processes/' + cprocess.processId;
    let to = {
      processId: cprocess.processId,
      status: status
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(stopLoading());
          dispatch(createMessage('success', '更新流程状态成功'));
          dispatch(initUpdate(cprocess.processId));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        dispatch(createhttpMessage(err.response ? err.response : '', '更新流程状态失败'));
        return null;
      });
  });

export const deleteProcess = createAction('@@xcloud/process/deleteProcess',
  (id, name) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/processes/' + id;
    let to = {
      processId: id,
      bpmn: '',
      status: 'inactive'
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(stopLoading());
          dispatch(createMessage('success', '删除流程[' + name + ']成功'));
          dispatch(getProcesses({
            offset: 0,
            size: 5,
            sort: '-update_time'
          }));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        dispatch(createhttpMessage(err.response ? err.response : '', '删除流程[' + name + ']失败'));
        return null;
      });
  });

export const updateProcess = createAction('@@xcloud/process/updateProcess',
  (xml) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let updatedProcess = getState().process.currentProcess;
    let diagramChange = getState().process.diagramChange;
    if (!diagramChange) {
      xml = '';
    }
    let url = '/api/processes/' + updatedProcess.processId;
    let to = {
      processId: updatedProcess.processId,
      processName: updatedProcess.processName,
      processType: updatedProcess.processType,
      opentext: updatedProcess.opentext || '',
      bpmn: xml || ''
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentProcess(res.data));
          dispatch(stopLoading());
          dispatch(createMessage('success', '更新流程成功'));
          dispatch(backToProcessList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        dispatch(createhttpMessage(err.response ? err.response : '', '更新流程失败'));
        return null;
      });
  });

export const setProcessList = createAction('@@xcloud/process/setProcessList');

export const setCurrentProcess = createAction('@@xcloud/process/setCurrentProcess');

export const setProcessError = createAction('@@xcloud/process/setProcessError');

export const setDiagramChange = createAction('@@xcloud/process/setDiagramChange');

export const setProcessChange = createAction('@@xcloud/process/setProcessChange');

export const stopLoading = createAction('@@xcloud/process/stopLoading');

export const startLoading = createAction('@@xcloud/process/startLoading');
export const initCreate = createAction(
  '@@xcloud/process/initCreate',
  () => (dispatch) => {
    let newProcess = {
      "processId": 0,
      "processName": '',
      "processType": '',
      "processCode": '',
      "bpmn": '',
      "status": "draft",
      "opentext": ''
    };
    dispatch(setCurrentProcess(newProcess));
    return dispatch(initProcess()).catch(err => {
      dispatch(stopLoading());
      let msg = '初始化创建流程失败';
      dispatch(createMessage('error', msg));
    });
  }
);

export const initUpdate = createAction(
  '@@xcloud/process/initUpdate',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/processes/' + id;
    httpClient.get(url)
      .then((res) => {
        if (res.data) {
          dispatch(setCurrentProcess(res.data));
        }
        return res;
      })
      .then(() => {
        return dispatch(initProcess());
      })
      .catch(err => {
        dispatch(stopLoading());
        let msg = '初始化更新流程失败';
        dispatch(createMessage('error', msg));
      });
  }
);

export const initProcess = createAction(
  '@@xcloud/process/initProcess',
  () => (dispatch, getState, httpClient) => {
    return dispatch(getRoleTree())
      .then(
        () => {
          let errorInfo = {
            processName: '',
            processType: '',
            processCode: ''
          };
          dispatch(setProcessError(errorInfo));
          dispatch(setProcessChange(false));
          dispatch(setDiagramChange(false));
          dispatch(push('/processManagement/info'));
          dispatch(stopLoading());
        }
      );
  }
);
export const ProcessReducer = handleActions(
  {
    [setProcessStart]: (state, { payload: data }) => {
      return {
        ...state,
        processStart: data
      }
    },
    [setProcess]: (state, { payload: { data, code } }) => {
      let processes = {
        ...state.processes
      }
      processes[code] = data
      return {
        ...state,
        processes: processes
      }
    },
    [setProcessHistories]: (state, { payload: { data, code } }) => {
      let processHistories = {
        ...state.processHistories,
      }
      processHistories[code] = data
      return {
        ...state,
        processHistories: processHistories
      }
    },
    [setCompanyAllProcessStatus]: (state, { payload: { status, code } }) => {
      let allProcessStatus = {
        ...state.companyAllProcessStatus
      }
      allProcessStatus[code] = status
      return {
        ...state,
        companyAllProcessStatus: allProcessStatus
      }
    },
    [setCurrentProcessStatus]: (state, { payload: { processStatus, code } }) => {
      let allProcessStatus = {
        ...state.currentProcessStatus
      }
      allProcessStatus[code] = processStatus
      return {
        ...state,
        currentProcessStatus: allProcessStatus
      }
    },
    [setStartProcesses]: (state, { payload: data }) => {
      return {
        ...state,
        startProcesses: data
      }
    },
    [setProcessList]: (state, { payload: value }) => {
      return {
        ...state,
        processList: value
      };
    },
    [setDiagramChange]: (state, { payload: value }) => {
      return {
        ...state,
        diagramChange: value
      };
    },
    [setProcessChange]: (state, { payload: value }) => {
      return {
        ...state,
        processChange: value
      };
    },
    [setProcessError]: (state, { payload: value }) => {
      return {
        ...state,
        processError: value
      };
    },
    [setCurrentProcess]: (state, { payload: value }) => {
      return {
        ...state,
        currentProcess: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    }
  },
  initialState
)

export default ProcessReducer