import React, { Component } from 'react'
import { Stage } from 'react-konva';
import Konva from 'konva';
import "./mycanvasview.css";
import * as _ from 'lodash';
import ContentMenuView from '../../containers/ContentMenuView';
import LeftSildeBarView from '../../containers/LeftSlideBarView';
import ViewTopBar from '../../containers/ViewTopBar';
import Dialog from '../../containers/Dialog';
import * as device from '../devices/core';
import KonvaUtil from "../devices/util";
import MapViewActionMapping from "../devices/mapViewActionMapping";
const konvaUtil = new KonvaUtil();
const mapViewActionMapping = new MapViewActionMapping();

export default class MyCanvas extends Component {

    componentDidMount() {
        this.props.cleanDialog();
        this.stage = this.refs.stage;
        let selectedStorey = _.minBy(this.props.installation.storeys, 'altitudeDatum');
        var layer = Konva.Layer.create(JSON.parse(selectedStorey.data2d));
        this.stage.add(layer);
        this.previewStage = this.refs.previewStage;
        mapViewActionMapping.layerMapping(this.props, layer, this.stage, this.previewStage);
        let previewLayer = layer.clone({ listening: false });
        previewLayer.name('previewLayer');
        this.previewStage.add(previewLayer);
        device.initPreviewRect(this.refs.previewStage, this.refs.konvaDiv);
        konvaUtil.updateView(this.props, layer, this.previewStage, 'view');
    }

    // componentWillMount() {
    //     this.props.getNewTreeData();
    // }

    _getStage() {
        // console.log(this.refs.stage);
        return this.refs.stage;
    }

    _getPreviewStage() {
        return this.refs.previewStage;
    }

    handleScroll(e) {
        konvaUtil.updatePreviewRect(this.refs.previewStage, e.target.scrollLeft, (e.target.scrollWidth - e.target.clientWidth), e.target.scrollTop, (e.target.scrollHeight - e.target.clientHeight))
    }

    render() {
        const { canvasViewWidth, canvasViewHeight, konvaWidth, konvaHeight, canvasWidth, canvasHeight, isFullScreen, existTreeData, editInstallation } = this.props;
        return (
            <div id='fullTag' style={{ width: isFullScreen ? canvasWidth : canvasViewWidth, height: isFullScreen ? canvasHeight : canvasViewHeight, backgroundColor: "white" }}>
                {(editInstallation.storeys && editInstallation.storeys.length > 0)
                    ?
                    <ViewTopBar getStage={() => this._getStage()}
                        getPreviewStage={() => this._getPreviewStage()}
                        installation={this.props.installation}
                        canvasViewWidth={isFullScreen ? canvasWidth - 400 : canvasViewWidth - 400}
                    />
                    : null}
                {(existTreeData.children && existTreeData.children.length > 0)
                    ?
                    <LeftSildeBarView getStage={() => this._getStage()} height={isFullScreen ? canvasHeight : canvasViewHeight} />
                    : null}
                <Dialog style={{
                    height: isFullScreen ? canvasHeight - 100 : canvasViewHeight - 100,
                    left: isFullScreen ? canvasWidth - 200 : canvasViewWidth + 24 - 200,
                    border: '1px solid #212529'
                }}
                    getStage={() => this._getStage()}
                    getPreviewStage={() => this._getPreviewStage()}
                />
                <ContentMenuView getStage={() => this._getStage()}
                    getPreviewStage={() => this._getPreviewStage()}
                />
                <div id='konvaDiv' style={{
                    width: isFullScreen ? canvasWidth - 400 : canvasViewWidth - 400,
                    height: isFullScreen ? canvasHeight - 46 : canvasViewHeight - 46,
                    left: isFullScreen ? 200 : 224,
                    top: isFullScreen ? 46 : 160
                }}
                    className="konvaViewArea"
                    onScroll={(e) => this.handleScroll(e)}
                    ref="konvaDiv"
                >
                    <Stage
                        ref="stage"
                        width={konvaWidth}
                        height={konvaHeight}
                        scaleX={1}
                        scaleY={1}
                    />
                </div>
                <div className="preview2"
                    style={{
                        left: isFullScreen ? canvasWidth - 200 : canvasViewWidth - 200 + 24,
                        top: isFullScreen ? canvasHeight - 100 : canvasViewHeight + 188 - 144
                    }}
                >
                    <Stage
                        ref="previewStage"
                        width={200}
                        height={100}
                        scaleX={1 / 30}
                        scaleY={1 / 30}
                    >
                    </Stage>
                </div>
            </div>
        );

    }
}
