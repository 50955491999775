import { createAction, handleActions } from 'redux-actions';
// import { createloading, cleanloading } from '@/redux/modules/loading';
import { createhttpMessage } from './message';
import { push } from 'connected-react-router';

// import * as _ from 'lodash';

const porxyApi = '/api'

const defaultState = {
    todos: []
};

export const openTodo = createAction('@@xcloud/todo/openTodo',
    (path) => (dispatch, getState, httpClient) => {
        dispatch(push(path))
    });


export const getTodoList = createAction('@@xcloud/todo/getTodoList',
    (userId, todoPriority, todoType, startDate, endDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + "/todos"
        if (userId || todoPriority || todoType || startDate || endDate || status) {
            url = url + '?'
            let strs = []
            if (userId)
                strs.push('userId=' + userId)
            if (todoPriority)
                strs.push('todoPriority=' + todoPriority)
            if (todoType)
                strs.push('todoType=' + todoType)
            if (startDate)
                strs.push('startDate=' + startDate)
            if (endDate)
                strs.push('endDate=' + endDate)
            if (status)
                strs.push('status=' + status)
            url = url + strs.join('&')
        }
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setTodoList(res.data));
                }
                return res.data;
            }).catch(err => {
                let msg = `待办事项列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            });
    });

export const setTodoList = createAction('@@xcloud/todo/setTodoList');


export const todoReducer = handleActions(
    {
        [setTodoList]: (state, { payload: value }) => {
            return {
                ...state,
                todos: value
            };
        },
    },
    defaultState
);

export default todoReducer;