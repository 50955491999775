import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
// import { Card } from '@material-ui/core';
import WorkOrderHeaderForPopup from './WorkOrderHeaderForPopup';
import WorkOrderList from './WorkOrderList';

const WorkOrderListForPopup = props => {
    const {
        // classes,
        initListData,
        search,
        // setPageSize,
        getWorkOrderList,
        isLoading,
        orderTypeList,
        departmentList,
        companyList,
        userProfile,
        onEditWorkOrders,
        routeType,
        companyId,
        onhandleRouteToAppointmentCreate,
        sourceList,
        onhandleRouteBaseOnStatus
    } = props;

    return (
        <>
            <WorkOrderHeaderForPopup />
            <WorkOrderList
                initListData={initListData}
                companyId={companyId}
                userProfile={userProfile}
                search={search}
                getWorkOrderList={getWorkOrderList}
                isLoading={isLoading}
                sourceList={sourceList}
                orderTypeList={orderTypeList}
                departmentList={departmentList}
                companyList={companyList}
                onhandleRouteToAppointmentCreate={onhandleRouteToAppointmentCreate}
                onEditWorkOrders={onEditWorkOrders}
                routeType={routeType}
                onhandleRouteBaseOnStatus={onhandleRouteBaseOnStatus}
            />
        </>
    );
};

WorkOrderListForPopup.propTypes = {
    classes: PropTypes.object.isRequired,
    initListData: PropTypes.object.isRequired,
    setPageSize: PropTypes.func.isRequired,
    getWorkOrderList: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    orderTypeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleRouteToAppointmentCreate: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEditWorkOrders: PropTypes.func.isRequired,
    sourceList: PropTypes.array.isRequired,
    onhandleRouteBaseOnStatus: PropTypes.func.isRequired,
};

export default withStyles(WorkOrderListForPopup);
