import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/EnergyPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDepartmentsByRole } from "@/utils/userUtils";
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import * as _ from 'lodash';

const OilPricingComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        onhandleRuleUpdate,
        onhandleCompanyChange,
        serviceBudgets,
        // onhandleViewBlur,
        // onhandleViewChange,
        // gVariableUnitPrice,
        // variableUnitPrice,
        currentUserInfo,
        department,
        userProfile,
        onhandleShowHistory,
        onhandleShowHistoryG,
        roleFlag,
    } = props;

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,

    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }

    const oilassemblyDetailColumns = () => {
        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '汽油单耗',
                field: 'gRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.gRatio}</Typography>,
            },
            // {
            //     title: '汽油单价',
            //     field: 'gVariableUnitPrice',
            //     type: 'numeric',
            //     align: 'right',
            //     width: 120,
            //     render: row => <Typography>{row.gVariableUnitPrice}</Typography>,
            //     editable: 'never'
            // }, 
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>升/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryG(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            }
            ,
            {
                title: '柴油单耗',
                field: 'ratio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.ratio}</Typography>,
            },
            // {
            //     title: '柴油单价',
            //     field: 'variableUnitPrice',
            //     type: 'numeric',
            //     align: 'right',
            //     width: 120,
            //     render: row => <Typography>{row.variableUnitPrice}</Typography>,
            //     editable: 'never'
            // }, 
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>升/{row.unit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const oilassemblyData = () => {
        const dBudgets = _.filter(serviceBudgets, { 'subAlias': '柴油' })
        const gBudgets = _.filter(serviceBudgets, { 'subAlias': '汽油' })
        _.forEach(dBudgets, value => {
            _.forEach(gBudgets, g => {
                if (value.serviceId === g.serviceId) {
                    value.gBudgetId = g.budgetId
                    value.gVariableUnitPrice = g.variableUnitPrice
                    value.gRatio = g.ratio
                    value.gVariableSub = g.variableSub
                }
            })
        })
        return dBudgets
    }

    const checkDpt = () => {
        const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
        return dptId === department.dptId || roleFlag ? true : false;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    // const textField = {
    //     style: {
    //         width: '160px',
    //     },
    //     startAdornment: (
    //         <InputAdornment position="start">
    //             <p></p>
    //         </InputAdornment>),
    //     disabled: !checkDpt()
    // }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/* <Grid container>
                        <Grid container className={classes.topflex}>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name='gVariableUnitPrice'
                                    label="汽油单价"
                                    InputProps={textField}
                                    onBlur={event => onhandleViewBlur(event)}
                                    onChange={event => onhandleViewChange(event)}
                                    value={gVariableUnitPrice ? gVariableUnitPrice : serviceBudgets.length !== 0 ? _.find(serviceBudgets, { 'subAlias': '汽油' }).variableUnitPrice : 0}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name='variableUnitPrice'
                                    label="柴油单价"
                                    InputProps={textField}
                                    onBlur={event => onhandleViewBlur(event)}
                                    onChange={event => onhandleViewChange(event)}
                                    value={variableUnitPrice ? variableUnitPrice : serviceBudgets.length !== 0 ? _.find(serviceBudgets, { 'subAlias': '柴油' }).variableUnitPrice : 0}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid > */}
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            columns={oilassemblyDetailColumns()}
                            data={oilassemblyData()}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={detailTableStyle}
                            editable={checkDpt() ? {
                                onRowUpdate: (newData, oldData) => onhandleRuleUpdate(newData, oldData)
                            } : {}}
                            actions={
                                [
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '汽油修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistoryG(event, rowData)
                                    //     }
                                    // },
                                    {
                                        icon: () => { return <HistoryIcon /> },
                                        tooltip: '柴油修改历史',
                                        onClick: (event, rowData) => {
                                            onhandleShowHistory(event, rowData)
                                        }
                                    },
                                ]
                            }
                            title={<Typography variant='h3'>油耗预算规则</Typography>}
                        // components={components}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

OilPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    subCompanyList: PropTypes.array,
    varFeeRuleList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,

};

OilPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(OilPricingComponent);