import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    handoverList: [] //交接班列表
};

export const setHandovers = createAction('@@xcloud/manufacture/setHandovers');

export const addManufactureHandover = createAction('@@xcloud/manufacture/addManufactureHandover',
    (handoverDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/handover', handoverDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加交接班成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加交接班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManufactureHandover = createAction('@@xcloud/manufacture/updateManufactureHandover',
    (handoverId, handoverDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/handover/' + handoverId, handoverDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新交接班成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新交接班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delManufactureHandover = createAction('@@xcloud/manufacture/delManufactureHandover',
    (handoverId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/handover/' + handoverId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除交接班成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除交接班失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getHandovers = createAction('@@xcloud/manufacture/getHandovers',
    (fromShift, toShift, opentext, companyId, fromDate, toDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/handover'
        let strs = []
        if (fromShift || toShift || opentext || companyId || fromDate || toDate || status) {
            if (fromShift) strs.push('fromShift=' + fromShift)
            if (toShift) strs.push('toShift=' + toShift)
            if (opentext) strs.push('opentext=' + opentext)
            if (companyId) strs.push('companyId=' + companyId)
            if (fromDate) strs.push('fromDate=' + fromDate)
            if (toDate) strs.push('toDate=' + toDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setHandovers(res.data['list']))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取交接班列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getInitHandover = createAction('@@xcloud/manufacture/getInitHandover',
    (handoverId, dptId, companyId, status, shiftUser) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/inithandover'
        let strs = []
        if (handoverId || dptId || companyId || status || shiftUser) {
            if (handoverId) strs.push('handoverId=' + handoverId)
            if (dptId) strs.push('dptId=' + dptId)
            if (companyId) strs.push('companyId=' + companyId)
            if (shiftUser) strs.push('shiftUser=' + shiftUser)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                return res.data;
            }
            return res.status;
        }).catch(err => {
            let msg = `获取初始交接班详情失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getHandover = createAction('@@xcloud/manufacture/getHandover',
    (handoverId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/handover/' + handoverId
        return httpClient.get(url).then((res) => {
            if (res) {
                return res.data;
            }
        }).catch(err => {
            let msg = `获取交接班失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const manufactureHandoverReducer = handleActions(
    {
        [setHandovers]: (state, { payload: data }) => {
            return {
                ...state,
                handoverList: data
            }
        },
    },
    initialState
)

export default manufactureHandoverReducer
