import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';


const CreateTaskComponent = props => {
    const { classes,
        open,
        openType,
        task,
        orders,
        members,
        service,
        departments,
        onhandleClose,
        onhandleSave,
        onhandleChange,
        onhandleDateChange
    } = props;

    const orderNoList = [...orders]

    const textFieldDefault = {
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    const getDptName = (dptId) => {
        const department = departments.find(item => {
            return item.dptId === dptId
        });
        return department === undefined ? '' : department.dptName
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>工作任务</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>

                    <Grid container direction="column" >
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                placeholder=""
                                label='关联工单号'
                                name='orderId'
                                InputProps={{
                                    ...textFieldDefault,
                                    readOnly: openType === 1 ? false : true
                                }}
                                value={task.orderId || ''}
                                onChange={onhandleChange} >
                                {orderNoList.map(i => (<MenuItem key={i.orderId} value={i.orderId}>{i.orderNo}</MenuItem>))}
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='服务名称'
                                name='serviceName'
                                InputProps={{ ...textFieldDefault, readOnly: true }}
                                value={task.serviceName}>
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                label='工作(活动)任务'
                                name='activityId'
                                InputProps={textFieldDefault}
                                value={task.activityId || ''}
                                onChange={onhandleChange}
                            >
                                {service.activities.map(c => (
                                    <MenuItem key={c.activityId} value={c.activityId}>{c.activityName}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='班组'
                                name='dptId'
                                InputProps={{ ...textFieldDefault, readOnly: true }}
                                value={getDptName(task.dptId) || ''}
                            >
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                label='执行人'
                                name='executor'
                                InputProps={textFieldDefault}
                                value={task.executor || ''}
                                onChange={onhandleChange}
                            >
                                {members.map(e => (
                                    <MenuItem key={e.userId} value={e.userId}>{e.lastName + e.firstName}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                <KeyboardDatePicker
                                    fullWidth
                                    autoOk
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    id="date-selector"
                                    margin="normal"
                                    label='开始时间'
                                    InputProps={textFieldDefault}
                                    value={task.startTime}
                                    onChange={onhandleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

CreateTaskComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    task: PropTypes.object,
    orders: PropTypes.array,
    members: PropTypes.array,
    service: PropTypes.object,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleChange: PropTypes.func,
    onhandleDateChange: PropTypes.func,
};

CreateTaskComponent.defaultProps = {
    service: {
        activities: []
    },
    orders: [],
    open: false,
    task: {},
    members: [],
};

export default withStyles(CreateTaskComponent);