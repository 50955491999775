import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Grid,
  Select,
  TextField
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment as moment } from '@/utils/datetime';
// import * as _ from 'lodash';

const MonthlyPlanHeader = props => {
  const {
    classes,
    search,
    companyList,
    statusList,
    onhandleSelectChange,
    onhandleSearch,
    onhandleClearSearch,
    onhandleInspectHeadClick

  } = props;


  const companyOptions = () => (
    <React.Fragment>
      {companyList ?
        companyList.map(data => (
          <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
        )) : null
      }
    </React.Fragment>
  )

  const statusOptions = () => (
    <React.Fragment>
      {statusList ?
        statusList.map(data => (
          <option value={data.code} key={data.code}>{data.name}</option>
        )) : null
      }
    </React.Fragment>
  )



  return (
    <div>
      <div>
        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
          风险研判计划
        </Typography>
      </div>
      <div>
        <Grid container spacing={0} className={classes.gridContainer}>

          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              受检单位
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.companyId || ''}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
            >
              <option aria-label="None" value="" />
              {companyOptions()}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              检查月份
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={zhCN}>
              <KeyboardDatePicker
                className={classes.dateSelect}
                views={['year', 'month']}
                variant="inline"
                autoOk
                openTo={'month'}
                maxDateMessage={<Typography>日期不能超过计划年度</Typography>}
                format="yyyy/MM"
                margin="none"
                name='yearMonth'
                InputProps={{ readOnly: true }}
                value={search.yearMonth || moment()()}
                onChange={(event) => onhandleSelectChange(event, 'yearMonth')}
                KeyboardButtonProps={{
                  'aria-label': '修改时间',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              状态
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.status}
              onChange={(event) => onhandleSelectChange(event, 'status')}
            >
              <option aria-label="None" value="" />
              {statusOptions()}
            </Select>
          </Grid>
          {/* 增加负责人选项 */}
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              负责人
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              style={{ width: '90%' }}
              className={classes.textField}
              value={search.creatorName || ''}
              name='inspectHead'
              InputProps={{ readOnly: true, }}
              onClick={event => onhandleInspectHeadClick(event)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >{'搜索'}</Button>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleClearSearch}

            >{'重置'}</Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

MonthlyPlanHeader.propTypes = {
  search: PropTypes.object.isRequired,
  companyList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
  listTypeList: PropTypes.array.isRequired,
  categoryList: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired
};

export default withStyles(MonthlyPlanHeader);
