import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
// import * as _ from 'lodash';

const MServiceDeleteComponent = props => {
    const {
        classes,
        open,
        service,
        onhandleDelete,
        onhandleClose,
        onhandleDateChange,
    } = props;

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{'删除服务：' + service.serviceName}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <Grid className={classes.detailtable}>
                            <Grid item container className={classes.itemGrid}>
                                <Grid item className={classes.itemLabel} ><Typography>{'有效开始日期'}</Typography> </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider key={'startDate'} utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            invalidDateMessage={'时间格式错误'}
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            name='startTime'
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={service.startDate || ''}
                                        />
                                    </MuiPickersUtilsProvider></Grid>
                                <Grid item className={classes.itemLabel}> <Typography>{'有效结束日期'}</Typography></Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider key={'endDate'} utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            invalidDateMessage={''}
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            name='endTime'
                                            value={service.endDate || ''}
                                            onChange={(date, value) => onhandleDateChange(date, 'endDate')}
                                        />
                                    </MuiPickersUtilsProvider> </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleDelete}  className={classes.deleteButton}> 删除</Button>
                    <Button onClick={onhandleClose} color="primary"> 关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

MServiceDeleteComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    service: PropTypes.object.isRequired,
};

MServiceDeleteComponent.defaultProps = {
    open: [],
    service: '',
}

export default withStyles(MServiceDeleteComponent);