import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserInfo from '../../../components/SystemManagement/UserManagement/UserInfo'
import {
    createUser,
    updateUser,
    setCurrentUser,
    backToUserList,
    setUserError,
    // initCreateAdmin,
    setUserChange
} from "../../../redux/modules/user";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { cellphoneRegx, emailRegx, integerRegx } from '../../../utils/regex';

class UserEditContainer extends Component {

    static propTypes = {
        currentUser: PropTypes.object,
        userError: PropTypes.object,
        userChange: PropTypes.bool
    };

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.userError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleUserChange(false);
            if (this.props.currentUser.userId) {
                this.props.onhandleUpdateUser(this.props.currentUser);
            } else {
                this.props.onhandleCreateUser(this.props.currentUser);
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleUserChange(true);
        let newUser;
        switch (field) {
            case 'userName':
                newUser = {
                    ...this.props.currentUser,
                    userName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'userType':
                newUser = {
                    ...this.props.currentUser,
                    userType: parseInt(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'firstName':
                newUser = {
                    ...this.props.currentUser,
                    firstName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'lastName':
                newUser = {
                    ...this.props.currentUser,
                    lastName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'email':
                newUser = {
                    ...this.props.currentUser,
                    email: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'phone':
                newUser = {
                    ...this.props.currentUser,
                    phone: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'opentext1':
                newUser = {
                    ...this.props.currentUser,
                    opentext1: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'dptId':
                newUser = {
                    ...this.props.currentUser,
                    dptId: event.target.value
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'password':
                newUser = {
                    ...this.props.currentUser,
                    password: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            case 'confirmPassword':
                newUser = {
                    ...this.props.currentUser,
                    confirmPassword: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditUser(newUser);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToUserList();
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'userName') {
            if (_.trim(this.props.currentUser.userName) === '') {
                err['userName'] = '用户名不能为空';
            } else {
                err['userName'] = '';
            }
        }

        if (!field || field === 'firstName') {
            if (_.trim(this.props.currentUser.firstName) === '') {
                err['firstName'] = '名不能为空';
            } else {
                err['firstName'] = '';
            }
        }

        if (!field || field === 'lastName') {
            if (_.trim(this.props.currentUser.lastName) === '') {
                err['lastName'] = '姓不能为空';
            } else {
                err['lastName'] = '';
            }
        }

        if (!field || field === 'password') {
            if (this.props.currentUser.password && this.props.currentUser.password.length < 8) {
                err['password'] = '密码不能少于8位字符';
            } else {
                err['password'] = '';
            }
        }

        if (!field || field === 'confirmPassword') {
            if (this.props.currentUser.password !== this.props.currentUser.confirmPassword) {
                err['confirmPassword'] = '确认密码不正确';
            } else {
                err['confirmPassword'] = '';
            }
        }

        if (!field || field === 'email') {
            let emailStr = _.trim(this.props.currentUser.email);
            if (emailStr !== '' && !emailRegx.test(emailStr)) {
                err['email'] = '请输入有效的邮箱地址';
            } else {
                err['email'] = '';
            }
        }

        if (!field || field === 'opentext1') {
            let op1 = _.trim(this.props.currentUser.opentext1);
            err['opentext1'] = '';
            if (op1 !== '') {
                let op1s = op1.split(",");
                for (let i in op1s) {
                    if (op1s[i] !== '' && (!integerRegx.test(op1s[i]) || op1s[i].length !== 4)) {
                        err['opentext1'] = '请输入有效的4位座机号码,多个号码请用逗号间隔';
                        break;
                    }
                }
            }
        }

        if (!field || field === 'phone') {
            let phoneStr = _.trim(this.props.currentUser.phone);
            if (phoneStr !== '') {
                if (!cellphoneRegx.test(phoneStr)) {
                    err['phone'] = '请输入有效的手机号码';
                } else {
                    err['phone'] = '';
                }
            } else {
                err['phone'] = '手机号码不能为空';
            }
        }

        let newError = {
            ...this.props.userError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <UserInfo
                onhandleSave={this.handleSave.bind(this)}
                currentUser={this.props.currentUser}
                departmentList={this.props.departmentList}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                userError={this.props.userError}
                firstUserAdmin={this.props.firstUserAdmin}
                userChange={this.props.userChange}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.user.currentUser,
        userError: state.user.userError,
        userChange: state.user.userChange,
        departmentList: state.user.departmentList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateUser: (entity) => {
            dispatch(createUser(entity))
        },
        onhandleUpdateUser: (entity) => {
            dispatch(updateUser(entity))
        },
        onhandleUpdateEditUser: (entity) => {
            dispatch(setCurrentUser(entity))
        },
        onhandleBackToUserList: () => {
            dispatch(backToUserList())
        },
        onhandleSetError: (err) => {
            dispatch(setUserError(err))
        },
        onhandleUserChange: (isChanged) => {
            dispatch(setUserChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditContainer)
