import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getCodeName, OIL_STATION, OIL_TYPE, getCodeListByType } from '@/utils/manufactureUtils';
import { getDateByString, formatDate_hms } from '@/utils/datetime';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';


const OilPriceComponent = props => {
    const { classes,
        oilPrices,
        selectedDate,
        dicts,
        onhandleDateChange,
        onhandleUpdate,
        onhandleAdd,
        onhandleDelete,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }


    const tableStyle = {
        ...style
    }

    const renderDate = (date) => {
        return date ? getDateByString(date) : ''
    }

    const renderUnitPrice = (row) => {
        if (row.unitPrice) {
            return <Typography>{Number(row.unitPrice).toFixed(3)}</Typography>
        } else {
            return ''
        }
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '油站',
                field: 'station',
                render: row => <Typography>{getCodeName(dicts, OIL_STATION, row.station)}</Typography>,
                lookup: _.chain(getCodeListByType(dicts, OIL_STATION)).keyBy('code').mapValues('name').value(),
                validate: rowData => {
                    if (!rowData.station || rowData.station === '') {
                        return { isValid: false, helperText: '油站不能为空' }
                    } else {
                        return true
                    }
                },

            }, {
                title: '油品',
                field: 'oilType',
                render: row => <Typography>{getCodeName(dicts, OIL_TYPE, row.oilType)}</Typography>,
                lookup: _.chain(getCodeListByType(dicts, OIL_TYPE)).keyBy('code').mapValues('name').value(),
                validate: rowData => {
                    if (!rowData.oilType || rowData.oilType === '') {
                        return { isValid: false, helperText: '油品不能为空' }
                    } else {
                        return true
                    }
                },
            }, {
                title: '价格(元)',
                field: 'unitPrice',
                render: row => renderUnitPrice(row),
                validate: rowData => {
                    if (!rowData.unitPrice || rowData.unitPrice === '') {
                        return { isValid: false, helperText: '油品不能为空' }
                    } else {
                        return true
                    }
                },
            },
            {
                title: '有效开始时间',
                field: 'startDate',
                render: row => <Typography>{renderDate(row.startDate)}</Typography>,
                validate: rowData =>
                    (!rowData.startDate || rowData.startDate === '')
                        ? { isValid: false, helperText: '有效开始时间不能为空' }
                        : (
                            (rowData.endDate && rowData.endDate !== '' && rowData.startDate > rowData.endDate)
                                ? { isValid: false, helperText: '有效开始时间不能晚于结束时间' }
                                : true
                        )
                ,
                editComponent: (props) => (
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN} >
                        <KeyboardDatePicker
                            error={Boolean(props.helperText)}
                            helperText={props.helperText || ''}
                            autoOk
                            InputProps={{ readOnly: true, }}
                            className={classes.textField}
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            name='tenabletimeselect'
                            value={props.rowData.startDate}
                            onChange={value => props.onChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': '修改时间',
                            }}
                        // minDate={new Date(`${getYear() + 1}-01-01`)}
                        // maxDate={new Date(getLastDayInYear(getYear() + 1))}
                        />
                    </MuiPickersUtilsProvider>)
            },
            {
                title: '有效结束时间',
                field: 'endDate',
                render: row => <Typography>{renderDate(row.endDate)}</Typography>,
                validate: rowData => (!rowData.endDate || rowData.endDate === '')
                    ?
                    { isValid: false, helperText: '预计结束时间不能为空' } : (
                        (rowData.endDate && rowData.endDate !== '' && rowData.startDate > rowData.endDate)
                            ? { isValid: false, helperText: '预计结束时间不能早于开始时间' }
                            : true
                    ),
                editComponent: (props) => (
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN} >
                        <KeyboardDatePicker
                            error={Boolean(props.helperText)}
                            helperText={props.helperText || ''}
                            autoOk
                            InputProps={{ readOnly: true, }}
                            className={classes.textField}
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            name='tenabletimeselect'
                            value={props.rowData.endDate}
                            onChange={value => props.onChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': '修改时间',
                            }}
                        // minDate={new Date(`${getYear() + 1}-01-01`)}
                        // maxDate={new Date(getLastDayInYear(getYear() + 1))}
                        />
                    </MuiPickersUtilsProvider>)

            },
            {
                title: '修改日期',
                field: 'updateTime',
                render: row => <Typography>{formatDate_hms(row.updateTime)}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }


    const assemblyData = () => {
        return oilPrices
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                views={['month']}
                                variant="inline"
                                format="yyyy/MM"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        title={<Typography variant="h3">油品价格设置</Typography>}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        editable={
                            {
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleAdd(newData)
                                        resolve()
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleUpdate(newData)
                                        resolve()
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleDelete(oldData)
                                        resolve()
                                    }),
                            }
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

OilPriceComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    oilPrices: PropTypes.array.isRequired,
};

OilPriceComponent.defaultProps = {
    oilPrices: []
};

export default withStyles(OilPriceComponent);