import React, { Component } from 'react';
import { connect } from 'react-redux';
import InstallationInfo from '../../components/InstallationManagement/InstallationInfo'
import { getCompanyId } from "../../utils/userUtils";
import { getTreePartById } from '../../utils/constant';
import {
    createInstallation,
    updateEditInstallation,
    setEditInstallationError,
    routeToInstallationList,
    setInstallationChange,
    updateInstallation,
    initInstallationInfo,
    initInstallationInfoForCreate,
    setDepartmentList
} from "../../redux/modules/installation";
import PropTypes from "prop-types";
import * as _ from 'lodash';


class InfoEditContainer extends Component {
    static propTypes = {
        editInstallation: PropTypes.object,
        editInstallationError: PropTypes.object,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
        companyTree: PropTypes.array,
        installationChange: PropTypes.bool,
        userProfile: PropTypes.object,
        currentUserInfo: PropTypes.object,
    };

    componentWillMount() {
        if (this.props.editInstallation && this.props.editInstallation.companyId) {
            let dpts = _.filter(this.props.fullDepartmentList, (d) => { return d.companyId === this.props.editInstallation.companyId && d.executable });
            this.props.onhandleSetDepartmentList(dpts);
        }
    }

    handeSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.editInstallationError), function (o) { return o !== ''; }) < 0) {
            let entity = _.clone(this.props.editInstallation)
            entity.productivity = _.toNumber(entity.productivity);
            entity.staffNo = _.toInteger(entity.staffNo);
            entity.companyId = _.toInteger(entity.companyId);
            if (this.props.editInstallation.installId) {
                this.props.onhandleUpdateInstallation(entity);
            } else {
                this.props.onhandleCreateInstallation(entity);
            }
        }
    }

    handleSelect = (value, field) => {
        this.props.onhandleInstallationChange(true);
        let newEditInstallation = {
            ...this.props.editInstallation,
            companyId: value ? value.target.value : ''
        };
        this.props.onhandleUpdateEditInstallation(newEditInstallation);
        this.handleCompanyIdCheck(newEditInstallation.companyId);
    }

    handleChange = (event, field) => {
        this.props.onhandleInstallationChange(true);
        let newEditInstallation;
        switch (field) {
            case 'installName':
                newEditInstallation = {
                    ...this.props.editInstallation,
                    installName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditInstallation(newEditInstallation);
                break;
            case 'installCode':
                newEditInstallation = {
                    ...this.props.editInstallation,
                    installCode: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditInstallation(newEditInstallation);
                break;
            case 'enName':
                newEditInstallation = {
                    ...this.props.editInstallation,
                    enName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditInstallation(newEditInstallation);
                break;
            case 'productivity':
                newEditInstallation = {
                    ...this.props.editInstallation,
                    productivity: _.toNumber(event.target.value)
                };
                this.props.onhandleUpdateEditInstallation(newEditInstallation);
                break;
            case 'staffNo':
                newEditInstallation = {
                    ...this.props.editInstallation,
                    staffNo: _.toInteger(event.target.value)
                };
                this.props.onhandleUpdateEditInstallation(newEditInstallation);
                break;
            default:
            // do nothing
        }
    };

    handleCompanyIdCheck = (value) => {
        let err = {};
        if (value === '') {
            err['companyId'] = '请选择所属公司/分厂';
        } else {
            err['companyId'] = '';
        }

        let newError = {
            ...this.props.editInstallationError,
            ...err
        }

        this.props.onhandleEditInstallation(newError);
    };

    handleCheckChange = (event, value) => {
        this.props.onhandleInstallationChange(true);

        // 缺陷 SBGL-292 : 设备管理 -> 装置档案 -> + -> 装置信息 -> 基本信息 -> 负责班组 * 复选框选中报错
        // let newDpts = [...this.props.editInstallation.dpts];
        let newDpts = [];
        if (this.props.editInstallation.dpts) {
            newDpts = [...this.props.editInstallation.dpts];
        }

        if (_.findIndex(newDpts, (d) => { return d.dptId === value }) > -1) {
            _.remove(newDpts, (d) => { return d.dptId === value });
        } else {
            newDpts.push({
                installId: this.props.editInstallation.installId,
                dptId: value
            })
        }
        let newEditInstallation = {
            ...this.props.editInstallation,
            dpts: newDpts
        };
        this.props.onhandleUpdateEditInstallation(newEditInstallation);
        let err = {};
        if (newDpts.length === 0) {
            err['dpts'] = '请选择负责班组';
        } else {
            err['dpts'] = '';
        }
        let newError = {
            ...this.props.editInstallationError,
            ...err
        }

        this.props.onhandleEditInstallation(newError);
    }

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'installName') {
            if (_.trim(this.props.editInstallation.installName) === '') {
                err['installName'] = '装置名称不能为空';
            } else {
                err['installName'] = '';
            }
        }

        if (!field || field === 'installCode') {
            if (_.trim(this.props.editInstallation.installCode) === '') {
                err['installCode'] = '装置编号不能为空';
            } else {
                err['installCode'] = '';
            }
        }

        if (!field || field === 'productivity') {
            if (this.props.editInstallation.productivity === 0) {
                err['productivity'] = '设计生产能力不能为0';
            } else {
                err['productivity'] = '';
            }
        }

        if (!field || field === 'staffNo') {
            if (this.props.editInstallation.staffNo === 0) {
                err['staffNo'] = '定员不能为0';
            } else if (!_.isInteger(this.props.editInstallation.staffNo)) {
                err['staffNo'] = '定员必须为整数';
            } else {
                err['staffNo'] = '';
            }
        }

        if (!field || field === 'companyId') {
            if (this.props.editInstallation.companyId === '') {
                err['companyId'] = '请选择所属单位';
            } else {
                err['companyId'] = '';
            }
        }

        let newError = {
            ...this.props.editInstallationError,
            ...err
        }

        this.props.onhandleEditInstallation(newError);
    };

    backToList() {
        this.props.onhandleBackToList();
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    render() {
        return (
            <InstallationInfo
                onhandleSave={this.handeSave.bind(this)}
                editInstallation={this.props.editInstallation}
                editInstallationError={this.props.editInstallationError}
                installationChange={this.props.installationChange}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheckChange={this.handleCheckChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onBackToList={this.backToList.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                companyList={this.props.companyList}
                departmentList={this.props.departmentList}
                companyTree={this.trimCompanyTree()}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        editInstallation: state.installation.editInstallation,
        editInstallationError: state.installation.editInstallationError,
        installationChange: state.installation.installationChange,
        companyList: state.constant.companies,
        fullDepartmentList: state.constant.departments,
        departmentList: state.installation.departmentList,
        companyTree: state.constant.companyTree,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateInstallation: (entity) => {
            dispatch(createInstallation(entity))
        },
        onhandleUpdateInstallation: (entity) => {
            dispatch(updateInstallation(entity))
        },
        onhandleUpdateEditInstallation: (installation) => {
            dispatch(updateEditInstallation(installation))
        },
        onhandleInstallationChange: (value) => {
            dispatch(setInstallationChange(value))
        },
        onhandleBackToList: () => {
            dispatch(routeToInstallationList());
        },
        onhandleEditInstallation: (err) => {
            dispatch(setEditInstallationError(err))
        },
        onhandleSetDepartmentList: (value) => {
            dispatch(setDepartmentList(value))
        },
        onhandleInitEditInstallation: (id) => {
            dispatch(initInstallationInfo(id));
        },
        onhandleInitEditInstallationCreate: (id) => {
            dispatch(initInstallationInfoForCreate(id));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InfoEditContainer)
