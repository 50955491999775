import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    Card,
    CardContent,
    Chip,
    Avatar,
    Tabs,
    Tab,
    // InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import ShowCard from '../../../../DeviceManagement/OperationAnnuaPlan/ShowCard';
import * as _ from 'lodash';


const ConfirmPanel = ({
    classes,
    open,
    onhandleClose,
    onhandleUpdate,
    onhandleChangeItem,
    workorderWithBigPermitList,
    planDetails,
    onhandleChangeDetailItem
}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTotal = (data) => {
        return data.finalCost
            ? data.onefinalCost
                ? _.add(_.toNumber(data.finalCost), _.toNumber(data.onefinalCost))
                : _.toNumber(data.finalCost)
            : data.onefinalCost
                ? _.toNumber(data.onefinalCost)
                : 0
    };

    return (
        <div alignitems="center" >
            <Dialog
                open={open}
                onClose={onhandleClose}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
                scroll={'body'}
                fullWidth={true}
                maxWidth={'md'}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="title">
                    <Typography className={classes.title} component={'span'} variant='h3'>工单验收</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="工单决算" />
                        <Tab label="计划决算" disabled={Boolean(_.filter(workorderWithBigPermitList, function (o) { return !o.orderCost && o.orderCost !== 0 }).length > 0)} />
                    </Tabs>
                    <Card className={classes.root} spacing={1}>
                        {value === 0 && <CardContent className={classes.dialogCardContent}>
                            {
                                workorderWithBigPermitList.map(data => (
                                    <Grid container alignItems="center" key={data.orderId}>
                                        <Grid item className={classes.action} xs={1}>
                                            {data.masterOrder ?
                                                <Avatar className={classes.pic2}>从</Avatar>
                                                : <Avatar className={classes.pic1}>主</Avatar>
                                            }
                                        </Grid>
                                        <Grid item className={classes.action} xs={3}>
                                            {data.masterOrder ?
                                                <Chip
                                                    size="small"
                                                    className={classes.pic21}
                                                    label={data.orderNo}
                                                /> : <Chip
                                                    size="small"
                                                    className={classes.pic11}
                                                    label={data.orderNo} />
                                            }
                                        </Grid>
                                        <Grid item className={classes.action} xs={4}>
                                            <TextField
                                                fullWidth
                                                value={data.orderBudget}
                                                type="number"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                label="预算价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={4}>
                                            <TextField
                                                fullWidth
                                                value={data.orderCost ? data.orderCost : ''}
                                                type="number"
                                                onChange={e => onhandleChangeItem(data.orderId, _.toNumber(e.target.value), 'orderCost')}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="决算价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={12}>
                                            <TextField
                                                fullWidth
                                                value={data.opentext ? data.opentext : ''}
                                                onChange={e => onhandleChangeItem(data.orderId, e.target.value, 'opentext')}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                label="运维内容"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={12}>
                                            <TextField
                                                fullWidth
                                                value={data.opentext1 ? data.opentext1 : ''}
                                                onChange={e => onhandleChangeItem(data.orderId, e.target.value, 'opentext1')}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                label="故障原因"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={12}>
                                            <TextField
                                                fullWidth
                                                value={data.opentext2 ? data.opentext2 : ''}
                                                onChange={e => onhandleChangeItem(data.orderId, e.target.value, 'opentext2')}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                label="避免措施"
                                                className={classes.textfield}
                                            />
                                        </Grid>

                                    </Grid>
                                ))
                            }
                        </CardContent>
                        }
                        {value === 1 && <CardContent className={classes.dialogCardContent}>
                            {
                                planDetails.map(data => (
                                    <Grid container alignItems="center" className={classes.gridContainer} key={data.dtlId}>
                                        <Grid item className={classes.action} xs={2}>
                                            {data ? data.device && data.device.deviceId ?
                                                <ShowCard currentInfo={data.device} target={data.target}
                                                    child={
                                                        < Avatar className={classes.pic1} >设备</Avatar >}
                                                />
                                                :
                                                < ShowCard currentInfo={data.accessory} target={data.target}
                                                    child={
                                                        < Avatar className={classes.pic2} >部件</Avatar >}
                                                />
                                                : null
                                            }
                                        </Grid>
                                        <Grid item className={classes.action} xs={2}>
                                            <Chip
                                                size="small"
                                                className={classes.pic11}
                                                label={data.optNo} />
                                        </Grid>
                                        <Grid item className={classes.action} xs={2}>
                                            <TextField
                                                fullWidth
                                                value={data.budget}
                                                type="number"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                label="预算价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={2}>
                                            <TextField
                                                fullWidth
                                                value={data.finalCost ? data.finalCost : 0}
                                                type="number"
                                                disabled
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="已决算价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={2}>
                                            <TextField
                                                fullWidth
                                                value={data.onefinalCost ? data.onefinalCost : 0}
                                                type="number"
                                                onChange={e => onhandleChangeDetailItem(data.dtlId, _.toNumber(e.target.value))}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="决算价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                        <Grid item className={classes.action} xs={2}>
                                            <TextField
                                                fullWidth
                                                value={getTotal(data)}
                                                error={Boolean(_.toNumber(data.budget) !== getTotal(data))}
                                                type="number"
                                                disabled
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="决算总计价格（元）"
                                                className={classes.textfield}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                                )
                            }
                        </CardContent>
                        }
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={onhandleClose}>
                        关闭</Button>
                    <Button variant="contained" color="primary" onClick={onhandleUpdate}
                        disabled={_.filter(workorderWithBigPermitList, function (o) { return !_.isNumber(o.orderCost) }).length > 0}>
                        保存</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
}

ConfirmPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleChangeItem: PropTypes.func.isRequired,
};
ConfirmPanel.defaultProps = {

}


export default withStyles(ConfirmPanel);