import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    detailtable: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    action: {
        padding: 16,
    },
    button: {
        marginRight: theme.spacing(2)
    },
    flexgrow: {
        flexGrow: 1
    },
    card: {
        width: '100%',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    gridContainer: {
        // padding: theme.spacing(4)
    },
    tableToolbarEditable: {
        paddingTop: 0,
        marginTop: 0,
    },
    cellButton: {
        marginLeft: -10,
    },
    tableFreeButton: {
        // 表格工具栏样式覆写无效，故设置如下按钮假样式
        position: 'relative',
        top: 8,
        margin: theme.spacing(-1.5),
    },
});

export default withStyles(styles);