import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Button,
    MenuItem,
    Card,
    Link,
    Tooltip,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment } from '@/utils/datetime'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import zhCN from 'date-fns/locale/zh-CN';

const MMonthlyPlanComponent = props => {
    const { classes,
        companyId,
        companyList,
        selectedDate,
        isMainCompany,
        onhandleMonthChange,
        onhandleCompanyChange,
        onhandleGenerate,
        onhandleEditHrplan,
        //onhandleAddPlans,
        plans,
    } = props;

    const tableStyle = {
        ...style,
    }

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        toolbar: false,
        paging: false,
        fixedColumns: {
            left: 3,
        },

    }

    const assemblyDetailColumns = () => {

        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '数量',
                field: 'amount',
                type: 'numeric',
                width: 100,
                render: row => <Typography>{row.amount ? row.amount.toFixed(2) : 0}
                    {Math.abs(row.amount - row.monthAmount) > 0.01 && row.monthAmount
                        && <Tooltip title={"月度为" + row.monthAmount.toFixed(2) + ",与月度总和相差" + (row.amount - row.monthAmount).toFixed(2)} >
                            <ErrorOutlineIcon fontSize="small" color="error" />
                        </Tooltip>}</Typography>,
                editable: 'never'
            }, {
                title: '单位',
                field: 'unit',
                width: 100,
                render: row => <Typography>{row.unit}</Typography>,
                editable: 'never'
            },
        ]
        let lastDay = selectedDate.endOf('month').daysInMonth();
        for (let index = 1; index <= lastDay; index++) {
            let column = {
                title: <Link color="primary" underline="always" target="_blank" component="button" variant="body2" display="inline" onClick={event => onhandleEditHrplan(event, index)}>{index.toString()}</Link>,
                type: 'numeric',
                width: 100,
                field: 'allocation' + index,
                render: row => <Typography>{row['allocation' + index] ? row['allocation' + index].toFixed(2) : 0}</Typography>,
                editable: 'never'
            }
            columns.push(column)
        }
        return columns
    }

    const assemblyDetailData = () => {
        console.log(plans)
        return plans
    }

    const getNextMonth = () => {
        let nextMonth = getMoment()().add(1, 'months');
        nextMonth = nextMonth.endOf('month')
        return new Date(nextMonth.valueOf());
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <div>
                    <div>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            月度计划
                        </Typography>
                    </div>
                    <div>
                        <Grid container direction="row"
                            justify="space-between" className={classes.gridContainer}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        maxDate={getNextMonth()}
                                        minDate={new Date('2010-01-01')}
                                        variant="inline"
                                        views={["year", "month"]}
                                        format="yyyy/MM"
                                        id="date-selector"
                                        margin="normal"
                                        value={selectedDate}
                                        onChange={(e) => { }}
                                        onMonthChange={onhandleMonthChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item className={classes.companySelect}>
                                <TextField
                                    fullWidth
                                    select
                                    name='companyId'
                                    InputProps={textFieldDefault}
                                    onChange={onhandleCompanyChange}
                                    value={companyId}
                                >
                                    {companyList.map(ele => (
                                        <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item className={classes.flexGrow}></Grid>
                            <Grid item >
                                {/* <Button variant='contained' color='primary' onClick={onhandleAddPlans} >
                                    日计划生成
                                    </Button> */}
                            </Grid>
                            <Grid item >
                                <Button variant='contained' color='primary' onClick={onhandleGenerate} >
                                    月度工单生成
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={classes.detailTable}>
                    <MaterialTable
                        columns={assemblyDetailColumns()}
                        data={assemblyDetailData()}
                        options={detailTableOptions}
                        localization={detailTableLocalization}
                        style={tableStyle}
                    ></MaterialTable>
                </div>
            </Card>
        </div >
    )
}

MMonthlyPlanComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    rangeOrders: PropTypes.any,
    companyList: PropTypes.array,
    plans: PropTypes.array,
    selectedDate: PropTypes.object,
    onhandleMonthChange: PropTypes.func,
    onhandleCompanyChange: PropTypes.func
};

MMonthlyPlanComponent.defaultProps = {
};

export default withStyles(MMonthlyPlanComponent);