import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActivitiesHeader from "../../../components/Crm/Activities/ActivitiesHeader/ActivitiesHeader";

class ActivitiesHeaderContainer extends Component {
  static propTypes = {
    currentActivity: PropTypes.object,
  };

  render() {

    return (
      <div>
        <ActivitiesHeader
          currentActivity={this.props.currentActivity}
          users={this.props.users}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentActivity: state.customerActivities.currentActivity,
    users: state.user.users.list,
  }
};

export default connect(
  mapStateToProps
)(ActivitiesHeaderContainer)
