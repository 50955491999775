import React, { Component } from 'react';
import "./index.css";
import Konva from 'konva';
import * as _ from 'lodash';
import KonvaUtil from "../devices/util";
const konvaUtil = new KonvaUtil();

export default class ContentMenu extends Component {
    constructor(props) {
        super(props)
        this._addTransformer = this._addTransformer.bind(this);
        this._showDetail = this._showDetail.bind(this);
        this._deleteDevice = this._deleteDevice.bind(this);
        this._copyDevice = this._copyDevice.bind(this);
        this._colseMenu = this._colseMenu.bind(this);
        this._rotationDevice = this._rotationDevice.bind(this);
        this._removeDeviceFromDevice = this._removeDeviceFromDevice.bind(this);
    }

    _rotationDevice() {
        const { parameters, cleanContentMenu, updMapChanged } = this.props;
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        layer.find('Transformer').destroy();
        let group = layer.findOne('#' + parameters.id);
        // let text = group.findOne('Text');
        // let name = text.text();
        // text.remove();
        // layer.draw();
        let width = group.getClientRect().width;
        let height = group.getClientRect().height;
        switch (group.rotation()) {
            case 0:
                group.rotation(90)
                group.x(group.x() + (height / 2 + width / 2));
                group.y(group.y() + (height / 2 - width / 2));

                // const text = new Konva.Text({
                //     text: name,
                //     x: group.getClientRect().x,
                //     y: group.getClientRect().y + width + 10,
                //     fill: 'black'
                // })
                // group.add(text);
                break;
            case 90:
                group.x(group.x() - (width / 2 - height / 2));
                group.y(group.y() + (height / 2 + width / 2));
                group.rotation(180)
                break;
            case 180:
                group.x(group.x() - (height / 2 + width / 2));
                group.y(group.y() - (height / 2 - width / 2));
                group.rotation(-90)
                break;
            case -90:
                group.x(group.x() + (width / 2 - height / 2));
                group.y(group.y() - (width / 2 + height / 2));
                group.rotation(0)
                break;
            default:
            // do nothing
        }
        layer.draw();
        updMapChanged(true);
        cleanContentMenu();
    }

    _addTransformer() {
        const { parameters, cleanContentMenu } = this.props;
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        layer.find('Transformer').destroy();
        let ids = _.split(parameters.id, '_');
        if (ids && ids.length > 1) {
            // let id = _.toInteger(ids[1]);
            let type = ids[0];
            if (type === 'Unit' || type === 'Area') {
                var trFree = new Konva.Transformer({
                    rotateEnabled: false,
                    keepRatio: true,
                    ignoreStroke: true,
                });
                layer.add(trFree);

                let group = layer.findOne('#' + parameters.id);
                trFree.attachTo(group);
                layer.draw();
            } else {
                var tr = new Konva.Transformer({
                    rotateEnabled: false,
                    keepRatio: true,
                    ignoreStroke: true,
                    centeredScaling: true,
                    enabledAnchors: [
                        'top-left',
                        'top-right',
                        'bottom-left',
                        'bottom-right',
                    ],
                });
                layer.add(tr);

                let group = layer.findOne('#' + parameters.id);
                tr.attachTo(group);
                layer.draw();
            }
        }
        cleanContentMenu();
    }

    _showDetail() {
        const { parameters, createDialog, cleanContentMenu } = this.props;
        let ids = _.split(parameters.id, '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let type = ids[0]
            if (type === 'DeviceGroup') {
                createDialog('deviceGroupDetail', { type: 'edit', data: { id: id, x: parameters.x, y: parameters.y, time: Date.now() } }, true);
            } else {
                createDialog('deviceDetail', { type: 'edit', data: { id: parameters.id, x: parameters.x, y: parameters.y } }, true);
            }
        }
        cleanContentMenu();
    }

    _copyDevice() {
        const { parameters, searchDevicelist, createDialog, cleanContentMenu } = this.props;
        let ids = _.split(parameters.id, '_');

        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let device = _.find(searchDevicelist.list, function (o) { return o.deviceId === id; });
            createDialog('deviceInfo', { type: device.cat, medium: device.medium, deviceName: device.deviceName }, true);
        }
        cleanContentMenu();
    }

    _deleteDevice() {
        const { parameters, cleanContentMenu, dialog, cleanDialog, createMessage, mapChanged, searchDevicelist, selectedId, deleteDeviceMap, updMapChanged, deleteUnitMap, editInstallation, deleteAreaMap } = this.props;
        if (mapChanged) {
            createMessage('warning', '当前装置图未保存，删除设备前请先保存！');
            cleanContentMenu();
        } else {
            let stage = this.props.getStage();
            let layer = stage.findOne('.workLayer');
            let ids = _.split(parameters.id, '_');
            if (ids && ids.length > 1) {
                let id = _.toInteger(ids[1]);
                let type = ids[0];
                if (type === 'Unit') {
                    let rtnData = {}
                    let unit = _.find(editInstallation.units, function (o) { return o.unitId === id; });
                    // console.log(unit);
                    if (unit) {
                        let list = _.clone(searchDevicelist.list);
                        let tergetList = _.filter(list, function (o) { return o.unitId === id; });
                        if (tergetList && tergetList.length > 0) {
                            tergetList.forEach(device => {
                                device.unitId = 0;
                            })
                            rtnData.devices = tergetList;

                        } else {
                            rtnData.devices = [];
                        }
                        unit.installId = 0;
                        unit.status = 'inactive';
                        unit.data2d = '';
                        unit.type = type;
                        rtnData.unit = unit;
                        deleteUnitMap(rtnData);
                        cleanContentMenu();
                    } else {
                        let targetUnit = layer.findOne('#' + parameters.id);
                        if (dialog && dialog.parameters && dialog.parameters.id === parameters.id) {
                            cleanDialog();
                        }
                        targetUnit.destroy();
                        layer.draw();
                        cleanContentMenu();
                        konvaUtil.updatePreview(this.props, layer, this.props.getPreviewStage());
                        updMapChanged(true);
                    }

                } else if (type === 'Area') {
                    let rtnData = {}
                    let areaDevices = []
                    let area = _.find(editInstallation.areas, function (o) { return o.areaId === id; });
                    // console.log(area);
                    if (area) {
                        let list = _.clone(searchDevicelist.list);
                        let tergetList = _.filter(list, function (o) { return _.filter(o.areas, function (p) { return p.areaId = id }).length > 0; });
                        if (tergetList && tergetList.length > 0) {
                            tergetList.forEach(device => {
                                areaDevices.push({ areaId: id, deviceId: device.deviceId });
                            })
                            rtnData.areaDevices = areaDevices;

                        } else {
                            rtnData.devices = [];
                        }
                        area.installId = 0;
                        area.status = 'inactive';
                        area.data2d = '';
                        area.type = type;
                        rtnData.area = area;
                        deleteAreaMap(rtnData);
                        cleanContentMenu();
                    } else {
                        let targetUnit = layer.findOne('#' + parameters.id);
                        if (dialog && dialog.parameters && dialog.parameters.id === parameters.id) {
                            cleanDialog();
                        }
                        targetUnit.destroy();
                        layer.draw();
                        cleanContentMenu();
                        konvaUtil.updatePreview(this.props, layer, this.props.getPreviewStage());
                        updMapChanged(true);
                    }
                } else {
                    let device = _.find(searchDevicelist.list, function (o) { return o.deviceId === id; });
                    if (device) {
                        device.type = type;
                        let rtnData = {}
                        if (device.storeies && device.storeies.length > 1) { //在多个层中出现
                            rtnData.storeyDevice = { deviceId: device.deviceId, storeyId: selectedId }
                            rtnData.deviceGroup = {}
                        } else { //在1个层中出现
                            rtnData.deviceGroup = { groupId: device.groupId };
                            rtnData.storeyDevice = { deviceId: device.deviceId, storeyId: selectedId }
                            device.data2d = '';
                            device.groupId = 0
                            if (device.unitId) {
                                device.unitId = 0;
                            }
                        }
                        let areaDevices = []
                        if (device.areas && device.areas.length > 0) {
                            device.areas.forEach(area => {
                                if (layer.findOne('#Area_' + area.areaId)) {
                                    areaDevices.push(area);
                                }
                            })
                        }
                        rtnData.areaDevices = areaDevices;
                        rtnData.device = device;
                        if (dialog && dialog.parameters && dialog.parameters.id === parameters.id) {
                            cleanDialog();
                        }
                        deleteDeviceMap(rtnData);
                        cleanContentMenu();
                    } else {
                        let targetDevice = layer.findOne('#' + parameters.id);
                        if (dialog && dialog.parameters && dialog.parameters.id === parameters.id) {
                            cleanDialog();
                        }
                        targetDevice.destroy();
                        layer.draw();
                        cleanContentMenu();
                        konvaUtil.updatePreview(this.props, layer, this.props.getPreviewStage());
                        updMapChanged(true);
                    }
                }
            }

        }
    }
    _colseMenu() {
        const { cleanContentMenu } = this.props;
        cleanContentMenu();
    }

    _removeDeviceFromDevice() {
        const { parameters, cleanContentMenu, removeFromGroup, createMessage, mapChanged, searchDevicelist } = this.props;
        if (mapChanged) {
            createMessage('warning', '当前装置图未保存，删除设备前请先保存！');
            cleanContentMenu();
        } else {
            let ids = _.split(parameters.id, '_');
            if (ids && ids.length > 1) {
                let id = _.toInteger(ids[1]);
                let device = _.find(searchDevicelist.list, function (o) { return o.deviceId === id; });
                removeFromGroup(device);
                cleanContentMenu();
            }
        }
    }

    _checkAreaOrUnit() {
        let ids = _.split(this.props.parameters.id, '_');
        if (ids[0] === 'Unit' || ids[0] === 'Area') {
            return true;
        }
        return false;
    }

    _checkGroup() {
        let ids = _.split(this.props.parameters.id, '_');
        if (ids && ids.length > 1) {
            let type = ids[0];
            if (type === 'DeviceGroup') {
                return true;
            }
        }
        return false;
    }

    _checkGroupInMap() {
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        let ids = _.split(this.props.parameters.id, '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let type = ids[0];
            if (type !== 'DeviceGroup' && type !== 'Unit' && type !== 'Area') {
                let device = _.find(this.props.searchDevicelist.list, function (o) { return o.deviceId === id; });
                if (device) {
                    let deviceGroupId = device.groupId;
                    let deviceGroup = layer.findOne('#DeviceGroup_' + deviceGroupId)
                    if (deviceGroup) {
                        if (_.isInteger(device.masterId)) {
                            return 2;
                        } else {
                            return 1;
                        }
                    }
                }
            } else {
                return 3;
            }
        }
        return 0;
    }

    _mainContent = () => {
        if (this.props.show) {
            return (
                <div className="menu" style={{ top: this.props.top, left: this.props.left }}>
                    <div>
                        {this._checkAreaOrUnit() ? null : <button className="normal-button" onClick={this._showDetail.bind(this)}>基本信息</button>}
                        {/* <button className="control-button" onClick={this._addTransformer.bind(this)}>置于顶层</button>
                        <button className="control-button" onClick={this._addTransformer.bind(this)}>置于底层</button> */}
                        {this._checkGroup() || this._checkGroupInMap() === 1 ? null :
                            (this._checkGroupInMap() === 3 ?
                                <React.Fragment>
                                    <button className="control-button" onClick={this._rotationDevice.bind(this)}>旋转90度</button>
                                    <button className="control-button" onClick={this._addTransformer.bind(this)}>图形编辑</button>
                                    <button className="control-button" onClick={this._deleteDevice.bind(this)}>删除</button>
                                </React.Fragment>
                                : this._checkGroupInMap() === 0
                                    ? <React.Fragment>
                                        <button className="control-button" onClick={this._copyDevice.bind(this)}>拷贝新建</button>
                                        <button className="control-button" onClick={this._rotationDevice.bind(this)}>旋转90度</button>
                                        <button className="control-button" onClick={this._addTransformer.bind(this)}>图形编辑</button>
                                        <button className="control-button" onClick={this._deleteDevice.bind(this)}>删除</button>
                                    </React.Fragment>
                                    : <button className="control-button" onClick={this._removeDeviceFromDevice.bind(this)}> 从设备组中移除</button>
                            )
                        }
                        <button className="close-button" onClick={this._colseMenu.bind(this)}>关闭</button>
                    </div>
                </div >
            );
        }

        return null;
    };

    render() {
        return (
            this._mainContent()
        );
    }
}