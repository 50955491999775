import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Typography,
  MenuItem,
  TextField,
  Button,
  Grid,
  // IconButton
} from '@material-ui/core';
import {
  Add,
  SaveAlt,
  ExpandMore,
  ExpandLess
} from '@material-ui/icons';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';

const DeviceHeader = props => {
  const {
    classes,
    userProfile,
    onhandleSearch,
    onhandleClearSearch,
    search,
    onhandleSelectChange,
    onhandleChange,
    catTree,
    companyTree,
    companyList,
    useStatusTree,
    procatTree,
    installationList,
    installationUnitList,
    installationAreaList,
    extcatTree,
    exportCsv,
    onhandleCreate,
  } = props;

  const [expand, setExpand] = useState(false);

  const checkPermission = () => {
    let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_list' })
    return permission && permission.action === 'W'
  }
  // const installOptions = () => (
  //   <React.Fragment>
  //     {installationList ?
  //       installationList.map(data => (
  //         <option value={data.installId} key={data.installId}>{data.installName}</option>
  //       )) : null
  //     }
  //   </React.Fragment>
  // )

  // const installUnitOptions = () => (
  //   <React.Fragment>
  //     {installationUnitList ?
  //       installationUnitList.map(data => (
  //         <option value={data.unitId} key={data.unitId}>{data.unitName}</option>
  //       )) : null
  //     }
  //   </React.Fragment>
  // )

  // const installAreaOptions = () => (
  //   <React.Fragment>
  //     {installationAreaList ?
  //       installationAreaList.map(data => (
  //         <option value={data.areaId} key={data.areaId}>{data.areaName}</option>
  //       )) : null
  //     }
  //   </React.Fragment>
  // )

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }

  const installUnitAndAreaDisabled = () => {
    if (search.installId === null || search.installId === '') {
      return true
    }

    return false
  }


  return (
    <div>
      <Grid container alignItems="center" className={classes.title}>
        <Typography variant="h3">
          {'设备列表'}
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container alignItems="center" className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={expand ? 9 : 6}>
          <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
            <Typography className={classes.label}>
              单&emsp;&emsp;位
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              id="filled-select-company"
              select
              value={search.companyId ? search.companyId : ''}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="outlined"
              disabled={companyDisabled()}
            >
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.companyId} value={option.companyId}>
                  {option.companyAlias}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
            <Typography className={classes.label}>
              设备名称
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              value={search.search || ''}
              variant="outlined"
              placeholder="编码，位号，型号，名称"
              onChange={event => onhandleChange(event, 'search')}
            />
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
            <Typography className={classes.label}>
              设备类型
            </Typography>
            <TreeSelect
              className={classes.textFieldInput}
              transitionName="rc-tree-select-dropdown-slide-up"
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
              allowClear
              treeLine
              value={search.cat}
              labelInValue
              treeData={catTree}
              treeNodeFilterProp="label"
              filterTreeNode={false}
              onChange={(value) => onhandleSelectChange(value, 'cat')}
            />
          </Grid>
          {Boolean(expand) &&
            <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  专业类别
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.procat}
                  labelInValue
                  treeData={procatTree}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'procat')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  扩展类别
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.extcat}
                  labelInValue
                  treeData={extcatTree}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'extcat')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  设备状态
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.useStatus}
                  labelInValue
                  treeData={useStatusTree}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'useStatus')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  资料完整度
                </Typography>
                <TreeSelect
                  className={classes.textFieldInput}
                  transitionName="rc-tree-select-dropdown-slide-up"
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                  allowClear
                  treeLine
                  value={search.status}
                  labelInValue
                  treeData={[{ value: 'new', label: '不完整' }, { value: 'active', label: '完整' }]}
                  treeNodeFilterProp="label"
                  filterTreeNode={false}
                  onChange={(value) => onhandleSelectChange(value, 'status')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  所属装置
                </Typography>
                <TextField
                  select
                  className={classes.textFieldInput}
                  size={'small'}
                  variant="outlined"
                  // value={search.installId}
                  value={search.installId ? search.installId : ''}
                  onChange={(event) => onhandleSelectChange(event, 'installId')}
                >
                  <MenuItem aria-label="None" value="">-</MenuItem>
                  {installationList ?
                    installationList.map(data => (
                      <MenuItem value={data.installId} key={data.installId}>{data.installName}</MenuItem>
                    )) : null
                  }
                </TextField>
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  装置单元
                </Typography>
                <TextField
                  select
                  className={classes.textFieldInput}
                  size={'small'}
                  variant="outlined"
                  value={search.unitId ? search.unitId : ''}
                  onChange={(event) => onhandleSelectChange(event, 'unitId')}
                  disabled={installUnitAndAreaDisabled()}
                >
                  <MenuItem aria-label="None" value="">-</MenuItem>
                  {installationUnitList ?
                    installationUnitList.map(data => (
                      <MenuItem value={data.unitId} key={data.unitId}>{data.unitName}</MenuItem>
                    )) : null
                  }
                </TextField>
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  装置区域
                </Typography>
                <TextField
                  select
                  className={classes.textFieldInput}
                  size={'small'}
                  variant="outlined"
                  value={search.areaId ? search.areaId : ''}
                  onChange={(event) => onhandleSelectChange(event, 'areaId')}
                  disabled={installUnitAndAreaDisabled()}
                >
                  <MenuItem aria-label="None" value="">-</MenuItem>
                  {installationAreaList ?
                    installationAreaList.map(data => (
                      <MenuItem value={data.areaId} key={data.areaId}>{data.areaName}</MenuItem>
                    )) : null
                  }
                </TextField>
              </Grid>
            </React.Fragment>
          }
        </Grid>
        {/* 按钮区 */}
        <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 3 : 6}>
          {/* 搜索区 */}
          <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >
              搜索
            </Button>
            <Button variant="outlined" style={{ marginLeft: '10px' }}
              onClick={onhandleClearSearch}
            >
              重置
            </Button>
            <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => setExpand(!expand)}
              endIcon={expand ? <ExpandLess /> : <ExpandMore />}
            >
              {expand ? '收起' : '展开'}
            </Button>
          </Grid>
          {/* 表格工具条区 */}
          <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
              onClick={onhandleCreate}
            >
              新建
            </Button>}
            <Button variant='contained' color='primary' startIcon={<SaveAlt />} style={{ marginLeft: '10px' }}
              onClick={exportCsv}
            >
              导出
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

};

DeviceHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  catTree: PropTypes.array.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  useStatusTree: PropTypes.array.isRequired,
  procatTree: PropTypes.array.isRequired,
  extcatTree: PropTypes.array.isRequired,
  exportCsv: PropTypes.func.isRequired,
  onhandleCreate: PropTypes.func.isRequired,
};

export default withStyles(DeviceHeader);
