import { createAction, handleActions } from 'redux-actions';
import { formSelectTree } from '../../utils/constant';
import { createhttpMessage } from './message';
// import * as _ from 'lodash';

const defaultState = {
  companyList: [],
  companyTree: [], // 公司车间树
  deviceCategoryList: [],
  deviceCategoryTree: [], // 设备类别树
};

export const getCompanyTree = createAction('@@xcloud/trees/getCompanyTree',
  () => (dispatch, getState, httpClient) => {
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = "/api/companies?tenantId=" + currentUserInfo.tenantId + "&offset=0&size=0";
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          return { companyList: res.data.list, companyTree: formSelectTree(res.data.list, false, 'companyName', 'companyId', 'pid') };
        }
        return res;
      }).catch(err => {
        let msg = '公司/车间层级结构获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getDeviceCategoryTree = createAction('@@xcloud/trees/getDeviceCategoryTree',
  () => (dispatch, getState, httpClient) => {
    let url = "/api/unprotected/utils/device/dictionaries?type=DEVCAT";
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(setDeviceCategoryList(res.data))
          return formSelectTree(res.data, false);
        }
        return res;
      }).catch(err => {
        let msg = '设备类别层级结构获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const setDeviceCategoryList = createAction('@@xcloud/trees/setDeviceCategoryList');

export const treesReducer = handleActions(
  {
    [getCompanyTree]: (state, { payload: value }) => {
      return {
        ...state,
        companyList: value.companyList ? value.companyList : [],
        companyTree: value.companyTree ? value.companyTree : [],
      };
    },
    [getDeviceCategoryTree]: (state, { payload: value }) => {
      return {
        ...state,
        deviceCategoryTree: value
      };
    },
    [setDeviceCategoryList]: (state, { payload: value }) => {
      return {
        ...state,
        deviceCategoryList: value
      };
    },
  },
  defaultState
);

export default treesReducer;