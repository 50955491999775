import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InventoryEditPanel from '@/components/InspectionManagement/Checklist/InventoryEditPanel';
import { closeInventoryEditPanel, changeInventory } from '@/redux/modules/inspection/checklistInventoryEdit';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import {
    getListTypeList,
    getCategoryList
} from "@/redux/modules/inspection/inspection";
import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash';

class InventoryEditPanelContainer extends Component {

    static propTypes = {
        inventory: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            inventoryError: {
                'listType': '',
                'catCode': '',
                // 'companyId': '',
                'inventoryName': ''
            },
            dateError: {
                validStartTime: '',
                validEndTime: ''
            }
        };
    }

    componentDidMount() {
        const {
            onhandleGetListTypeList,
            onhandleGetCategoryList
        } = this.props;
        onhandleGetListTypeList();
        onhandleGetCategoryList();
    }

    getDptInspectTypes(listTypeList) {
        const {
            inspectionDptMapping
        } = this.props;

        // 过滤排查列表
        const filtedTypeList = _.filter(listTypeList, (o) => {
            if (_.findIndex(inspectionDptMapping, { 'inspectionCode': o.code }) > -1)
                return true;
        })
        return filtedTypeList;
    }

    handleConfirm() {
        const { confirmCallback, inventory, onhandleClose } = this.props;
        const fieldChecked = this.handleCheck(null, null);
        const datesChecked = this.handleDateCheck(null, null);
        if (fieldChecked && datesChecked) {
            if (confirmCallback) confirmCallback(inventory)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, inventory } = this.props;
        let newInventory = {
            ...inventory
        }

        const fieldName = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);
        // 获取checkbox值只能取checked
        const checkboxValue = _.cloneDeep(event.target.checked);
        newInventory[fieldName] = fieldValue;

        // 调整相关域
        if (fieldName === 'listType') {
            newInventory.catCode = '';
        }
        // 变更检查时间类型时，调整结束时间inspectTime
        if (fieldName === 'validDate') {
            if (checkboxValue === true) {
                newInventory.validDate = true;
                // newInventory.validStartTime = moment()().startOf('day').valueOf();
            }
            if (checkboxValue === false) {
                newInventory.validDate = false;
                newInventory.validStartTime = '';
                newInventory.validEndTime = '';
            }
        }

        onhandleChange(newInventory);

        // 变更时间设置类型时，不用check
        if (fieldName === 'validDate') return;
        setTimeout(() => {
            this.handleCheck(null, fieldName);
        }, 100)
    }

    handleDateChange(date, fieldName) {
        const { onhandleChange, inventory } = this.props;

        let newInventory = {
            ...inventory
        }

        const selectedDate = moment()(date);
        // 设开始时间
        if (fieldName === 'validStartTime') {
            newInventory.validStartTime = selectedDate.startOf('day').valueOf();
        }

        // 设截止时间
        if (fieldName === 'validEndTime') {
            newInventory.validEndTime = selectedDate.endOf('day').valueOf();
        }

        onhandleChange(newInventory)

        setTimeout(() => {
            if (this.handleDateCheck(null, fieldName)) {
                onhandleChange(newInventory);
            }
        }, 100);
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyName : '';
                break;
            case 'department':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'listType':
                value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
                break;
            case 'user':
                const user = _.find(users, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleCheck = (event, field) => {

        const {
            inventory,
            categoryList
        } = this.props;

        let err = {};

        if (!field || field === 'inventoryName') {
            if (_.trim(inventory.inventoryName) === '') {
                err['inventoryName'] = '名称不能为空';
            } else {
                err['inventoryName'] = '';
            }
        }

        if (!field || field === 'listType') {
            if (_.trim(inventory.listType) === '') {
                err['listType'] = '排查分类不能为空';
            } else {
                err['listType'] = '';
            }
        }

        if (!field || field === 'catCode') {
            if (_.find(categoryList, { 'pcode': inventory.listType }) && inventory.catCode === '') {
                err['catCode'] = '子分类不能为空';
            } else {
                err['catCode'] = '';
            }
        }

        let newError = {
            ...this.state.inventoryError,
            ...err
        }
        this.setState({
            inventoryError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    handleDateCheck = (event, field) => {

        const {
            inventory
        } = this.props;

        let err = {};

        if (inventory.validDate) {

            // if (!field || field === 'validStartTime') {
            //     if (!inventory.validStartTime) {
            //         err['validStartTime'] = '日期不能为空';
            //     } else {
            //         err['validStartTime'] = '';
            //     }
            // }

            // if (!field || field === 'validEndTime') {
            //     if (!inventory.validEndTime) {
            //         err['validEndTime'] = '日期不能为空';
            //     } else {
            //         err['validEndTime'] = '';
            //     }
            // }
            if (inventory.validStartTime && inventory.validEndTime) {

                const startMoment = moment()(inventory.validStartTime);
                const endMoment = moment()(inventory.validEndTime);

                if (endMoment.isBefore(startMoment)) {
                    err['validEndTime'] = '不能早于开始日期';
                } else {
                    err['validEndTime'] = '';
                }
            } else if (!inventory.validStartTime && !inventory.validEndTime) {
                err['validStartTime'] = '至少填写一项日期';
            } else {
                err['validStartTime'] = '';
                err['validEndTime'] = '';
            }
        } else {
            err['validStartTime'] = '';
            err['validEndTime'] = '';
        }

        let newError = {
            ...this.state.dateError,
            ...err
        }
        this.setState({
            dateError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    // "不限"选项是否显示
    handleShowNoSpecifyOption() {
        const company = _.find(this.props.companies, { 'companyId': this.props.currentUserInfo.companyId });
        return company ? !company['pid'] : false
    }

    render() {
        return (
            <InventoryEditPanel
                open={this.props.open}
                showNoSpecify={this.handleShowNoSpecifyOption()}
                inspectionDptMapping={this.props.inspectionDptMapping}
                companyList={this.props.constant.companies}
                departmentList={this.props.departmentList}
                inventory={this.props.inventory}
                currentUserInfo={this.props.currentUserInfo}
                userProfile={this.props.userProfile}
                leaders={this.props.leaders}
                users={this.props.users}
                roleList={this.props.roleList}
                listTypeList={this.getDptInspectTypes(this.props.listTypeList)}
                categoryList={this.props.categoryList}
                inventoryError={this.state.inventoryError}
                dateError={this.state.dateError}

                onhandleListMapping={this.handleListMapping.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}

                onhandleCheck={this.handleCheck.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.checklistInventoryEdit.open,
        inventory: state.checklistInventoryEdit.inventory,
        constant: state.constant,
        companies: getActualCompany(state.constant.companies),
        departmentList: getManageDpt(state.constant.departments),
        leaders: state.user.leaders,
        users: state.user.users.list,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        roleList: state.inspection.roleList,
        checklistCount: state.inspection.checklistCount,
        confirmCallback: state.checklistInventoryEdit.confirmCallback,
        closeCallback: state.checklistInventoryEdit.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (inventory) => {
            dispatch(changeInventory(inventory));
        },
        onhandleClose: () => {
            dispatch(closeInventoryEditPanel());
        },
        onhandleGetListTypeList: () => {
            dispatch(getListTypeList())
        },
        onhandleGetCategoryList: () => {
            dispatch(getCategoryList())
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryEditPanelContainer);