import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography
} from '@material-ui/core';

import { findBuNameById } from "../../../utils/constant";
import { getTimeUnitByCode, getDateByTimestamp } from "../../../utils/datetime";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../utils/mtable"

import * as _ from 'lodash';

const RegulationPlanCurrentYear = ({
    classes,
    currentPlanDetails,
    constant,
}) => {

    /*const [expanded, setExpanded] = useState(true)
    const onhandleExpanded = () => {
        setExpanded(!expanded)
    }*/

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        showTitle: false,
        //filtering: true,
        toolbar: true,
        search: true
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                render: row => row && <Typography>{findBuNameById(constant, row.unitId)}</Typography>,
                cellStyle: (value, rowData) => {
                    if (rowData && rowData.unitDuplicated) {
                        return { border: ' 0px solid black', }
                    }
                    return {}
                },
            },
            {
                title: '公司规章制度',
                field: 'regName',
                render: row => <Typography>{row.regName}</Typography>
            },
            {
                title: '修订周期',
                render: (rowData) => <Typography>{rowData ? rowData.revisionCycle + getTimeUnitByCode(rowData.revisionUnit) : ''}</Typography>,
            },
            {
                title: '上次修订时间',
                field: 'updateTime',
                render: (row) => 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    views={['year', 'month']}
                    format="yyyy/MM"
                    id="date-selector"
                    margin="normal"
                    value={getDateByTimestamp(row.updateTime)}
                />
            </MuiPickersUtilsProvider>,
                type: 'date'
            },
            {
                title: '修改建议',
                field: 'content',
                render: (row) => <Typography>{row.content}</Typography>
            },
            {
                title: '修订类型',
                field: 'planType',
                lookup: {
                    Add: '新增',
                    Update: '修改',
                    Delete: '废止'
                },
                cellStyle: { fontSize: 12 }
            },
            {
                title: '计划完成时间',
                field: 'targetDate',
                render: (row) => <Typography>{getDateByTimestamp(row.targetDate)}</Typography>,
                type: 'date'
            },
        ]

        return columns
    }

    const assemblyData = () => {

        if (!currentPlanDetails || currentPlanDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(currentPlanDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }

    return (
        <div alignitems="center" className={classes.root}>

            <div alignitems="center" className={classes.planitemroot}>

                <Grid>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            editable={{}}
                            localization={localization}
                            style={style}
                            components={{}}
                        >
                        </MaterialTable>
                    </Grid>
                    <Grid>
                    </Grid>
                </Grid>
            </div>
        </div >

    );
};
RegulationPlanCurrentYear.propTypes = {
    classes: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    currentPlanDetails: PropTypes.array.isRequired,
};

RegulationPlanCurrentYear.defaultProps = {
    currentPlanDetails: [],
    constant: {},
    businessUnits: [],
}

export default withStyles(RegulationPlanCurrentYear);