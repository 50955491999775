import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Card,
  Typography,
  Button,
  Grid,
  Checkbox
} from "@material-ui/core";
import MaterialTable from 'material-table';
import { localization, options, style } from "../../utils/mtable";

function AcctypeSelectPage(props) {
  const { classes,
    onhandleSelectAccCategory,
    onhandleExitSelected,
    onhandleConfirm,
    accCatList,
  } = props;

  const tableStyle = {
    ...style
  }

  const [selectedRow, setSelectedRow] = useState(null);

  const tableHeight = (window.innerHeight - 400) / window.innerHeight * 100;

  return (
    <div>
      <Card className={classes.card}>
        <Typography variant="h3" gutterBottom>
          部件类别选择
        </Typography>
      </Card>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: '',
                field: 'route',
                width: 50,
                render: row => row ? <Checkbox
                  checked={row.action ? true : false}
                  onChange={(event) => onhandleSelectAccCategory(event, row)}
                /> : ''
              },
              {
                title: '部件名称',
                field: 'name',
                render: row => row ? <Typography>{row.name}</Typography> : '',
                editable: 'never'
              },
            ]}
            data={accCatList}
            options={{
              ...options,
              showTitle: false,
              toolbar: false,
              search: false,
              paging: false,
              maxBodyHeight: `${tableHeight}vh`,
              minBodyHeight: `${tableHeight}vh`,
              rowStyle: rowData => ({
                backgroundColor: selectedRow === rowData.processId ? '#EEEEEE' : 'FFFFFF'
              })
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            parentChildData={(row, rows) => rows.find(a => a.code === row.pcode)}
            style={tableStyle}
            localization={localization}
          >
          </MaterialTable>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Grid item xs={1}>
          <Button variant="contained" color="primary"
            onClick={event => onhandleConfirm()}
          >
            确定
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="primary"
            onClick={event => onhandleExitSelected()}
          >
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

AcctypeSelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onhandleSelectAccCategory: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
  accCatList: PropTypes.array.isRequired,
};

export default withStyles(AcctypeSelectPage);
