import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MonthlyPlanHeader from "../../../containers/InspectionManagement/MonthlyPlan/MonthlyPlanHeader";
import MonthlyPlanList from "../../../containers/InspectionManagement/MonthlyPlan/MonthlyPlanList";
// import ChecklistSetPannel from "../../../containers/InspectionManagement/MonthlyPlan/ChecklistSetPannel";
import { Card } from '@material-ui/core';

const MonthlyPlan = props => {

    const { classes, location } = props;
    const isBack = location ? location.state : false;

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <MonthlyPlanHeader isBack={isBack} />
                <MonthlyPlanList />
            </Card>
            {/* <ChecklistSetPannel /> */}
        </div>
    );
};
MonthlyPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(MonthlyPlan);
