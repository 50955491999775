import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api';
const basePath = porxyApi + '/amoeba/refuelrecords';
const initialState = {
    refuelRecords: [],//加油记录List
};

export const setRefuelRecords = createAction('@@xcloud/amoeba/setRefuelRecords');

export const getRefuelRecords = createAction('@@xcloud/amoeba/getRefuelRecords',
    (station, companyId, oilType, startDate, endDate) =>
        (dispatch, getState, httpClient) => {
            let url = basePath;
            if (station || companyId || oilType || startDate || endDate) {
                let strs = [];
                if (station) strs.push('station=' + station)
                if (companyId) strs.push('companyId=' + companyId)
                if (oilType) strs.push('oilType=' + oilType)
                if (startDate) strs.push('startDate=' + startDate)
                if (endDate) strs.push('endDate=' + endDate)
                url = url + '?' + strs.join('&')
            }

            url += '&offset=0&size=0';

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setRefuelRecords(res.data.list))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取加油记录列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const createRefuelRecords = createAction('@@xcloud/amoeba/createRefuelRecords',
    (docInfo, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(basePath + "/import", docInfo).then(
                (res) => {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "导入加油记录成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `导入加油记录失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const updateRefuelRecord = createAction('@@xcloud/amoeba/updateRefuelRecord',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.patch(basePath + "/" + record.recordId, record).then(
                (res) => {
                    if (res && callback)
                        callback(res.data)
                    dispatch(createhttpMessage(res, "更新加油记录成功"))
                    return res.status;
                }).catch(err => {
                    let msg = `更新加油记录失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const refuelRecordReducer = handleActions(
    {
        [setRefuelRecords]: (state, { payload: data }) => {
            return {
                ...state,
                refuelRecords: data
            }
        },
    },
    initialState
)

export default refuelRecordReducer
