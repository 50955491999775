import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initFiveSPlanHeader, setSearch, resetSearch } from '@/redux/modules/inspection/fiveSPlan';
import FiveSPlanHeader from "@/components/InspectionManagement/FiveSPlan/FiveSPlanHeader";
import {
  getPlanDetails
} from "@/redux/modules/inspection/inspectionPlan";

class FiveSPlanHeaderContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  static propTypes = {
    companyList: PropTypes.array,
    departmentList: PropTypes.array,
    statusList: PropTypes.array,
    search: PropTypes.object
  };

  componentDidMount() {
    const { isBack } = this.props;
    this.props.onhandleInitFiveSPlanHeader(isBack);
  }

  handleSearch() {
    const { search } = this.props;
    this.props.onhandleSearch(search);
  }

  handleClearSearch() {
    this.props.onhandleClearSarch();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value
        };
        break;
      case 'listType':
        newSearch = {
          ...this.props.search,
          listType: value.target.value,
          catCode: ''
        };
        break;
      case 'category':
        newSearch = {
          ...this.props.search,
          catCode: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'yearMonth': // 年-月模糊匹配详情记录 startTime
        newSearch = {
          ...this.props.search,
          yearMonth: value
        };
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value.target.value
        };
        break;
      default:
      // do nothing
    }
    this.props.onhandleUpdateEditSearch(newSearch);
  }

  render() {
    return <FiveSPlanHeader
      search={this.props.search}
      companyList={this.props.companyList}
      departmentList={this.props.departmentList}
      severityList={this.props.severityList}
      rootCauseList={this.props.rootCauseList}
      statusList={this.props.statusList}
      onhandleSelectChange={this.handleSelect.bind(this)}
      onhandleSearch={this.handleSearch.bind(this)}
      onhandleClearSearch={this.handleClearSearch.bind(this)}
    />
  }
}

const mapStateToProps = (state) => {

  return {
    search: state.fiveSPlan.search,
    statusList: state.fiveSPlan.statusList,
    companyList: state.fiveSPlan.companyList,
    departmentList: state.inspection.departmentList,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitFiveSPlanHeader: (isBack) => {
      dispatch(initFiveSPlanHeader(isBack));
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleSearch: (query) => {
      dispatch(getPlanDetails(query));
    },
    onhandleClearSarch: () => {
      dispatch(resetSearch());
    },
  }
};

FiveSPlanHeaderContainer.defaultProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiveSPlanHeaderContainer)
