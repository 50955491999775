import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ApprovePannel from '../../components/InspectionManagement/ApprovePannel';
import { closeApprovePannel, changeComment } from '../../redux/modules/approvePannel';

class ApprovePannelContainer extends Component {
    static propTypes = {
        open: PropTypes.bool,
        title: PropTypes.string,
        comment: PropTypes.string,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    handleConfirm() {
        const { confirmCallback, closeApprovePannel, comment } = this.props;
        // final execute function from other component or redux-action
        if (confirmCallback) confirmCallback(comment);
        // close action
        if (closeApprovePannel) closeApprovePannel();
    }

    handleClose() {
        const { closeApprovePannel, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (closeApprovePannel) closeApprovePannel();
        // close action
        if (closeCallBack) closeCallBack();
    }
    handleChangeComment(event) {
        const { changeComment } = this.props;
        if (changeComment) changeComment(event.target.value);
    }

    render() {
        return (
            <ApprovePannel
                open={this.props.open}
                title={this.props.title}
                comment={this.props.comment}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
                onhandleChangeComment={this.handleChangeComment.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.approvePannel.open,
        title: state.approvePannel.title,
        comment: state.approvePannel.comment,
        confirmCallback: state.approvePannel.confirmCallback,
        closeCallback: state.approvePannel.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeApprovePannel: () => {
            dispatch(closeApprovePannel());
        },
        changeComment: (comment) => {
            dispatch(changeComment(comment));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovePannelContainer);