import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import InspectionPlan from '../../../containers/InspectionManagement/AnuualPlan/InspectionPlan';
import InspectionPlanCurrentYear from '../../../containers/InspectionManagement/AnuualPlan/InspectionPlanCurrentYear';

import { Route, withRouter } from 'react-router';
import {
    Tabs,
    Tab,
    Grid,
    Card
} from '@material-ui/core';
import { getMoment as moment, getYear } from '@/utils/datetime';
// import moment from 'moment';

const AnnualPlan = ({
    classes,
    onhandleChangeTab,
    tabValue,
}) => {

    return (
        <Card alignitems="center" className={classes.root}>

            <Tabs
                className={classes.tab}
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={getYear() + "年隐患排查年度计划"} />

                <Tab label={"拟定" + moment()().add(1, 'year').year() + "年隐患排查年度计划"} />
            </Tabs>
            <Grid>
                {tabValue === 0 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/inspection' exact component={InspectionPlanCurrentYear}></Route>
                        </Grid>
                    </Grid>}
                {tabValue === 1 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/inspection' exact component={InspectionPlan}></Route>
                        </Grid>
                    </Grid>}
            </Grid>
        </Card>

    );
};
AnnualPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

AnnualPlan.defaultProps = {
}


export default withRouter(withStyles(AnnualPlan));