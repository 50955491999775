import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Card,
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { getNameByTypeAndCode } from '../../../../utils/constant';
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { localization, options } from "../../../../utils/mtable";
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';

const JobList = props => {
    const {
        classes,
        jobs,
        onDelete,
        onEdit,
        getJobList,
        onhandleSelectionChange,
        companySelection,
        companyTree,
        userProfile,
        constants,
        onhandleCreate,
        pageSize,
        setPageSize
    } = props;

    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(0);

    console.debug(selectedRow)

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'job_management' })
        return permission && permission.action === 'W'
    }

    const renderTime = (data) => {
        return data.updateTime ? getDateByTimestamp(data.updateTime, true) : getDateByTimestamp(data.createTime, true)
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'jobName',
                render: row => row ? <Typography>{row.jobName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{getNameByTypeAndCode(constants, 'STATUS', row.status)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <Card alignitems="center" className={classes.planitemroot}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    职务列表
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={8}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            所属单位
                        </Typography>
                        <TreeSelect
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                            className={classes.textFieldInput}
                            allowClear
                            treeLine
                            value={companySelection}
                            labelInValue
                            treeData={companyTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => onhandleSelectionChange(value)}
                        />
                    </Grid>
                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={4}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        <Button variant='contained' color='primary' startIcon={<Add />} onClick={(event) => onhandleCreate(event)}
                            hidden={!checkPermission()}
                        >
                            添加职务
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={jobs.list}
                    options={{
                        ...options,
                        pageSize: pageSize ? pageSize : 5,
                        tableLayout: 'auto',
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                    }}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                rowsPerPage={pageSize ? pageSize : 10}
                                count={jobs.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getJobList({
                                        offset: pageNum * (pageSize ? pageSize : 10)
                                    })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPageSize(event.target.value);
                                    setPage(0);
                                    getJobList({
                                        offset: 0
                                    })
                                }}
                            />
                        ),
                    }}
                    actions={checkPermission() ?
                        [rowData => ({
                            icon: 'edit',
                            tooltip: '编辑',
                            onClick: (event, rowData) => {
                                onEdit(event, rowData.jobId)
                            },
                            disabled: rowData.status === 'inactive'
                        }),
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onDelete(event, rowData.jobId, rowData.jobName)
                            },
                            disabled: rowData.status === 'inactive'
                        }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '添加职务',
                            //     isFreeAction: true,
                            //     onClick: (event) => onhandleCreate(event),
                            //     hidden: companySelection === undefined
                            // }
                        ] : [
                            rowData => ({
                                icon: 'description',
                                tooltip: '查看',
                                onClick: (event, rowData) => {
                                    // onView(event, rowData.userId)
                                }
                            })
                        ]}
                >
                </MaterialTable>
            </Grid>
        </Card >
    );
};

JobList.propTypes = {
    classes: PropTypes.object.isRequired,
    jobs: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    getJobList: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    companySelection: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired
};

export default withStyles(JobList);

