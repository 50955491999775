import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";
import {
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    LinearProgress,
    Select,
    TablePagination,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ListItemText
} from "@material-ui/core";
import {
    AttachFile,
    SaveAlt,
    DoneAll,
    Subject
} from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';

const FiveSPlanRecords = props => {
    const {
        classes,
        currentPlanDetail,
        lists,
        // planResults,
        search,
        exportDptCsv,
        exportRecordCsv,
        checkUserList,
        // departmentList,
        scoreDepartments,
        onDownload,
        onhandleGetPlanRecords,
        onhandleSelectChange,
        onhandlePageChange,
        onhandleListMapping,
        onhandleBack
    } = props;

    // console.log('currentPlanDetail =======')
    // console.log(currentPlanDetail)

    // console.log('lists.list =======')
    // console.log(lists.list)

    // console.log('scoreDepartments =======')
    // console.log(scoreDepartments)

    const [exportAnchorEl, setExportAnchorEl] = React.useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const getDepartmentScore = (dpt) => {
        if (!lists || !lists.list || !lists.list.length) {
            return 100
        }
        let score = 100;
        lists.list.forEach(({
            dptId,
            result,
            checklist
        }) => {
            if (dptId === dpt.dptId) {
                if (result && result !== '' && result !== '0') {
                    let count = _.toSafeInteger(result);
                    if (count > 0 && checklist) {
                        score = score - checklist.score * count;
                    }
                }
            }
        })
        if (score >= 0) {
            return score;
        } else {
            return 0
        }
    }

    const isScoreFailed = (score) => {
        // 合格分
        const standardScore = 80;
        if (score < standardScore) {
            return true
        } else {
            return false
        }
    }

    const userOptions = () => (
        <React.Fragment>
            {checkUserList ?
                checkUserList.map(data => (
                    <option value={data.userId} key={data.userId}>{data.lastName + data.firstName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const departmentOptions = () => (
        <React.Fragment>
            {scoreDepartments ?
                scoreDepartments.map(data => (
                    <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const assemblycolumns = () => {

        let columns = [
            {
                title: '序号',
                field: 'serialNo',
                render: row => row ? <Typography>{row.checklist.serialNo}</Typography> : '',
                width: '10%',
                editable: 'never'
            },
            {
                title: '发生部门',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.dptId, 'department')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发现人',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{row.inspectionResult.userName || ''}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查项',
                field: 'problem',
                render: row => row ? <Typography>{row.checklist.problem}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发现次数',
                field: 'result',
                width: '10%',
                render: row => {
                    if (row.checklist.resultType === 'SCORE') {
                        return row.result
                    } else {
                        return '-'
                    }
                },
                editable: 'never'
            },
            {
                title: '扣分',
                field: 'result',
                width: '10%',
                render: row => {
                    let resultStyle = classes.pending;
                    if (!row.result || Number.parseInt(row.result) === 0) resultStyle = classes.passed;
                    if (row.result && Number.parseInt(row.result) > 0) resultStyle = classes.denied;
                    return <Chip size="small" className={resultStyle} label={row.result * row.checklist.score} />
                },
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{truncate(row.opentext, 30)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    const components = {
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={rowsPerPage}
                count={lists.total}
                page={page}
                onChangePage={(event, pageNum) => {
                    setPage(pageNum);
                    onhandleGetPlanRecords({
                        ...search,
                        offset: pageNum * rowsPerPage,
                        size: rowsPerPage,
                        sort: "-record_time",
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    setRowsPerPage(event.target.value);
                    setPage(0);
                    onhandlePageChange({
                        size: event.target.value,
                    });
                    onhandleGetPlanRecords({
                        ...search,
                        offset: 0,
                        size: event.target.value,
                        sort: "-record_time"
                    })
                }}

            />
        )
    }

    const exportMenu = () => <>
        <IconButton
            aria-controls="simple-menu" aria-haspopup="true"
            style={{ marginRight: 10 }}
            onClick={(e) => { setExportAnchorEl(e.currentTarget) }}>
            <SaveAlt />
        </IconButton>
        <Menu
            id="simple-menu"
            anchorEl={exportAnchorEl}
            keepMounted
            open={Boolean(exportAnchorEl)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => { setExportAnchorEl(null) }}
        >
            <MenuItem onClick={() => { exportDptCsv(); setExportAnchorEl(null) }}>
                <DoneAll fontSize={'small'} style={{ marginRight: 5 }} />
                <ListItemText primary={"考核统分"} />
            </MenuItem>
            <MenuItem onClick={() => { exportRecordCsv(); setExportAnchorEl(null) }}>
                <Subject fontSize={'small'} style={{ marginRight: 5 }} />
                <ListItemText primary={"违规记录"} /></MenuItem>
        </Menu>
    </>

    return (
        <Card className={classes.card}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    5S检查记录情况</Typography>
            </div>
            <div>
                <Grid container spacing={1} className={classes.gridContainer}>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            受检单位</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {onhandleListMapping(currentPlanDetail.companyId, 'company')}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            负责人</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {currentPlanDetail.revisorLastName + currentPlanDetail.revisorFirstName || ''}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            排查状态</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {currentPlanDetail.status ? onhandleListMapping(currentPlanDetail.status, 'status') : '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            完成时间</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {currentPlanDetail.endTime ? moment.utc(currentPlanDetail.endTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    Boolean(scoreDepartments && scoreDepartments.length) && scoreDepartments.map(
                        department =>
                            <Grid container key={department.dptId} spacing={1} className={classes.gridContainerSub}>
                                <Grid item xs={1}>
                                    <Typography gutterBottom>
                                        {department.dptName}</Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Box className={classes.expandable} display="flex" alignItems="center">
                                        <Box width="90%" mr={1}>
                                            <LinearProgress variant="determinate"
                                                className={classes.progressMain}
                                                classes={{
                                                    colorPrimary: isScoreFailed(getDepartmentScore(department)) ? classes.progressBarFailBackgroundColor : classes.progressBarBackgroundColor,
                                                    barColorPrimary: isScoreFailed(getDepartmentScore(department)) ? classes.progressBarFailColor : classes.progressBarColor
                                                }}
                                                value={getDepartmentScore(department) || 0} />
                                        </Box>
                                        <Box>
                                            <Typography>{`${getDepartmentScore(department)} 分`}</Typography>
                                        </Box>
                                        <Box>
                                            {/* <Typography>{onhandleListMapping(currentPlanDetail.status, 'status')}</Typography> */}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                    )
                }
            </div>
            <Divider variant="middle" />
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                        检查人</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Select
                        style={{ width: '90%' }}
                        native
                        value={search.userId}
                        onChange={(event) => onhandleSelectChange(event, 'userId')}
                    >
                        <option aria-label="None" value="">所有人</option>
                        {userOptions()}
                    </Select>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>
                        发生部门</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Select
                        style={{ width: '90%' }}
                        native
                        value={search.dptId}
                        onChange={(event) => onhandleSelectChange(event, 'dptId')}
                    >
                        <option aria-label="None" value="">所有部门</option>
                        {departmentOptions()}
                    </Select>
                </Grid>
                <Grid item xs={4} container justify={'flex-end'}>
                    {exportMenu()}
                </Grid>
            </Grid>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={{
                            ...options,
                            pageSize: rowsPerPage,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: false,
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.listId ? '#EEEEEE' : 'FFFFFF'
                            })
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        localization={localization}
                        style={style}
                        components={components}
                        actions={
                            [
                                rowData => ({
                                    icon: AttachFile,
                                    tooltip: '查看附件',
                                    disabled: !rowData.docs.length && rowData.docs.length === 0,
                                    onClick: (event, rowData) => {
                                        onDownload(event, rowData, false)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary" onClick={event => onhandleBack(event)}>
                        {'返回'}</Button>
                </Grid>

            </Grid>
        </Card >
    );
};

FiveSPlanRecords.propTypes = {
    classes: PropTypes.object.isRequired,
    checkUserList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    lists: PropTypes.object.isRequired,
    planResults: PropTypes.array.isRequired,
    onDownload: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    exportCsv: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

export default withStyles(FiveSPlanRecords);
