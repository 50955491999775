import { createAction, handleActions } from 'redux-actions';
import { getRoleGroups, getRoleTree } from './role';
import { createhttpMessage } from './message';
import { cleanmodal } from './modal';
import { createloading, cleanloading } from '@/redux/modules/loading';
// import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    RoleList: {
        total: 0,
        list: []
    },
    search: {
        companyId: undefined,
        groupId: undefined,
        groupName: undefined,
        roleName: undefined,
    },
    selectedRoles: [],
}

export const initRoleSelect = createAction('@@xcloud/roleSelect/initRoleSelect',
    () => (dispatch, getState) => {
        dispatch(createloading())
        Promise.all([
            dispatch(getRoleGroups()),
            dispatch(getRoleTree()),
            dispatch(resetSearch()),
            dispatch(setRoleList({ total: 0, list: [] }))
        ]).then((data) => {
            if (data[0] && data[0].payload) {
                dispatch(setGroupList(data[0].payload.list))
            }
            dispatch(setSearch({
                ...getState().roleSelect.search,
                companyId: getState().auth.currentUserInfo.companyId,
            }))

            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = '初始化用户搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getRoleList = createAction(
    '@@xcloud/roleSelect/getRoleList',
    (sort, companyId, groupId, roleId, roleName, groupName
    ) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let search = getState().roleSelect.search;
        //条件检索
        companyId = search.companyId ? search.companyId : companyId
        groupId = search.groupId ? search.groupId : groupId
        roleId = search.roleId ? search.roleId : roleId
        roleName = search.roleName ? search.roleName : roleName
        groupName = search.groupName ? search.groupName : groupName

        let url = "/roles?offset=0&size=0"

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (groupId && groupId !== "") {
            url += "&groupId=" + groupId
        }
        if (roleId && roleId !== "") {
            url += "&roleId=" + roleId
        }
        if (groupName && groupName !== "") {
            url += "&groupName=" + groupName
        }
        if (roleName && roleName !== "") {
            url += "&roleName=" + roleName
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=-update_time"
        }
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200) {
                    dispatch(setRoleList(res.data));
                    return res.data;
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `角色列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const resetSearch = createAction('@@xcloud/roleSelect/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().roleSelect.search;
        let newSearch = {
            ...search,
            companyId: undefined,
            groupId: undefined,
            roleId: undefined,
            roleName: "",
            groupName: "",
        }
        return dispatch(setSearch(newSearch));
    }
);

export const resetSearchField = createAction('@@xcloud/roleSelect/resetSearchField',
    (value, field) => (dispatch, getState, httpClient) => {
        if (field === 'company') {

        } else if (field === 'roleGroup') {
            let roles = getState().role.roleTree;
            let { companyId } = getState().roleSelect.search;
            let roleList = [];
            roles
                .filter(role => {
                    if (companyId) return role.companyId === companyId
                    return true
                })
                .forEach(element => {
                    if (element.roleGroups) {
                        const rolePrefix = element.companyAlias.substring(0, 2);
                        element.roleGroups.forEach(group => {
                            if (value === "" || (group.groupId === value && group.roleList)) {
                                group.roleList.forEach(role => {
                                    roleList.push({
                                        cname: role.roleName.includes(rolePrefix) ? role.roleName : rolePrefix + role.roleName,
                                        name: role.roleName,
                                        code: role.roleId
                                    });
                                })
                            }
                        })
                    }
                })
            dispatch(setRoleList(roleList));
            let search = getState().roleSelect.search;
            let newSearch = {
                ...search,
                roleId: ''
            }
            dispatch(setSearch(newSearch));
        }
    });

export const setSearch = createAction('@@xcloud/roleSelect/setSearch');
export const setSelectedRoles = createAction('@@xcloud/roleSelect/setSelectedRoles');
export const setRoleList = createAction('@@xcloud/roleSelect/setRoleList');
export const setRoleTree = createAction('@@xcloud/roleSelect/setRoleTree');
export const setGroupList = createAction('@@xcloud/roleSelect/setGroupList');

export const exitSelected = createAction('@@xcloud/roleSelect/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedRoles([]))
        dispatch(cleanmodal())
    }
);

export const roleSelectReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setGroupList]: (state, { payload: value }) => {
            return {
                ...state,
                groupList: value
            };
        },
        [setSelectedRoles]: (state, { payload: value }) => {
            return {
                ...state,
                selectedRoles: value
            };
        },
        [setRoleList]: (state, { payload: value }) => {
            return {
                ...state,
                roleList: value
            };
        },
        [setRoleTree]: (state, { payload: value }) => {
            return {
                ...state,
                roleTree: value
            };
        },
    },
    initialState
);

export default roleSelectReducer;