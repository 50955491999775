import * as _ from 'lodash';

//字典类型 待补充


// 特殊作业类型
/** 动火作业 */ export const OPERATION_SPECCODE01 = 'SPECCODE01';
/** 进入受限空间作业 */ export const OPERATION_SPECCODE02 = 'SPECCODE02';
/** 盲板抽堵作业 */ export const OPERATION_SPECCODE03 = 'SPECCODE03';
/** 高处作业 */ export const OPERATION_SPECCODE04 = 'SPECCODE04';
/** 吊装作业 */ export const OPERATION_SPECCODE05 = 'SPECCODE05';
/** 临时用电作业 */ export const OPERATION_SPECCODE06 = 'SPECCODE06';
/** 动土作业 */ export const OPERATION_SPECCODE07 = 'SPECCODE07';
/** 断路作业 */ export const OPERATION_SPECCODE08 = 'SPECCODE08';
// /** 其他作业 */ export const OPERATION_SPECCODE09 = 'SPECCODE09';

// 作业许可类型
/** 动火作业 */ export const OPERATION_PERMITTYPE01 = 'PERMITTYPE01';
/** 进入受限空间作业 */ export const OPERATION_PERMITTYPE02 = 'PERMITTYPE02';
/** 盲板抽堵作业 */ export const OPERATION_PERMITTYPE03 = 'PERMITTYPE03';
/** 高处作业 */ export const OPERATION_PERMITTYPE04 = 'PERMITTYPE04';
/** 吊装作业 */ export const OPERATION_PERMITTYPE05 = 'PERMITTYPE05';
/** 临时用电作业 */ export const OPERATION_PERMITTYPE06 = 'PERMITTYPE06';
/** 动土作业 */ export const OPERATION_PERMITTYPE07 = 'PERMITTYPE07';
/** 断路作业 */ export const OPERATION_PERMITTYPE08 = 'PERMITTYPE08';
// /** 其他作业 */ export const OPERATION_PERMITTYPE09 = 'PERMITTYPE09';

/**
 * 特殊作业类型 => 作业许可类型
 * @param {*} specType 
 */
export const mappingSpecCodeToPermitCode = (specType) => {
    if (specType === OPERATION_SPECCODE01) return OPERATION_PERMITTYPE01;
    if (specType === OPERATION_SPECCODE02) return OPERATION_PERMITTYPE02;
    if (specType === OPERATION_SPECCODE03) return OPERATION_PERMITTYPE03;
    if (specType === OPERATION_SPECCODE04) return OPERATION_PERMITTYPE04;
    if (specType === OPERATION_SPECCODE05) return OPERATION_PERMITTYPE05;
    if (specType === OPERATION_SPECCODE06) return OPERATION_PERMITTYPE06;
    if (specType === OPERATION_SPECCODE07) return OPERATION_PERMITTYPE07;
    if (specType === OPERATION_SPECCODE08) return OPERATION_PERMITTYPE08;
    return null;
}

/** 
 * 从特殊作业类型获取模板文件名
 * @param {*} specType 作业许可类型
 */
export const getWorkPermitTemplateName = (permitType) => {
    if (permitType === OPERATION_PERMITTYPE01) return 'A1.动火作业安全许可证.docx';
    if (permitType === OPERATION_PERMITTYPE02) return 'A2.受限空间作业安全许可证.docx';
    if (permitType === OPERATION_PERMITTYPE03) return 'A3.盲板抽堵作业安全许可证.docx';
    if (permitType === OPERATION_PERMITTYPE04) return 'A4.高处安全作业证.docx';
    if (permitType === OPERATION_PERMITTYPE05) return 'A5.吊装安全作业证.docx';
    if (permitType === OPERATION_PERMITTYPE06) return 'A6.临时用电安全作业证.docx';
    if (permitType === OPERATION_PERMITTYPE07) return 'A7.动土安全作业证.docx';
    if (permitType === OPERATION_PERMITTYPE08) return 'A8.断路安全作业证.docx';
    return 'A9.作业安全许可证.docx';
}

/**
 * 
 * @param {*} optTypeOpentext 
 * @returns 【N】不需要上传文档 【D】普通文档 【T】检验检测文档
 */
export const getOperationTypeDocClass = (optTypeOpentext) => {

    if (!optTypeOpentext) {
        return 'N'
    }
    var params = _.split(optTypeOpentext, ',')
    if (params.length < 2) {
        return 'N'
    } else {
        return params[1]
    }
}

/**
 * 
 * @param {*} optTypeOpentext 
 * @returns 【N】不需要作业 【Y】需要作业
 */
export const getOperationWorkRecordFlg = (optTypeOpentext) => {

    if (!optTypeOpentext) {
        return 'N'
    }
    var params = _.split(optTypeOpentext, ',')
    if (params.length < 1) {
        return 'N'
    } else {
        return params[0]
    }
}

export const WORKORDER_STATUS_ALL = {
    'new': '新建',
    'active': '有效',
    'inactive': '无效',
    'submitted': '审核中',
    'reporting': '提交报告',
    'approved': '核准',
    'published': '可作业',
    'finalized': '待验收',
    'finished': '待决算',
    'done': '完成',
    'invalid': '已作废',
    'rejected': '驳回'
}

export const getWorkOrderStatusByRouteType = (routeType) => {
    let status = null
    switch (routeType) {
        case 'all':
            status = 'done,new,rejected,submitted,approved,published,finalized,finished,invalid';
            break;
        case 'needProcess':
            status = 'new,rejected,submitted';
            break;
        case 'needAppointment':
            status = 'approved';
            break;
        case 'needWork':
            status = 'published,finalized';
            break;
        case 'needCount':
            status = 'finished';
            break;
        default:
        // do nothing
    }
    return status;
}