import { connect } from 'react-redux';
import ContentMenuView from '../components/ContentMenuView';
import { cleanContentMenu } from '../redux/modules/contentmenuview';
import { createmodal } from '../../../redux/modules/modal';

const mapStateToProps = (state) => {
    return {
        ...state.contentmenuview,
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanContentMenu: () => {
            dispath(cleanContentMenu());
        },
        handleCharts: (param) => {
            dispath(createmodal('onsiteInspectionStatistics', param, 'medium'))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ContentMenuView);
