import React from 'react';
import withStyles from './styles';
import {
  Typography,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";



const TenantHeader = props => {
  const {
      onhandleOpen,
  } = props;


  return (
      <div>
        <Typography variant="h3" color='textPrimary' style={{display:'inline-block'}}>
          租户列表
        </Typography>
        <Button variant="contained" color="primary" style={{margin:20}}   onClick={onhandleOpen} >
          新建租户
        </Button>
      </div>
  );
};

TenantHeader.propTypes = {

};

export default withStyles(TenantHeader);
