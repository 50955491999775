import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccessoryDetail from '../../../components/AccessoryManagement/AccessoryInfo/AccessoryDetail'
import {
    setAccessoryChange,
    initAccessoryEdit,
    setAccessoryError,
    setCurrentAccessory,
    backToList,
    insertAccessory,
    modifyAccessory
} from '../../../redux/modules/accessory';
import * as _ from 'lodash';
import { integerRegx } from '../../../utils/regex';
import { getTreePartById } from '../../../utils/constant';
import { getCompanyId } from "../../../utils/userUtils";
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';

class AccessoryEditContainer extends Component {

    componentDidMount() {
        this.props.onhandleInitAccessoryEdit();
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.accessoryError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleAccessoryChange(false);
            if (this.props.currentAccessory.accId) {
                this.props.onhandleUpdateAccessory(this.props.currentAccessory);
            } else {
                this.props.onhandleCreateAccessory(this.props.currentAccessory);
            }
        }
    }

    handleChange = (event, field) => {
        let newAccessory;
        switch (field) {
            case 'accNo':
                newAccessory = {
                    ...this.props.currentAccessory,
                    accNo: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'accName':
                newAccessory = {
                    ...this.props.currentAccessory,
                    accName: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'specs':
                newAccessory = {
                    ...this.props.currentAccessory,
                    specs: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'model':
                newAccessory = {
                    ...this.props.currentAccessory,
                    model: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'material':
                newAccessory = {
                    ...this.props.currentAccessory,
                    material: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'manufacturer':
                newAccessory = {
                    ...this.props.currentAccessory,
                    manufacturer: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'serviceLife':
                newAccessory = {
                    ...this.props.currentAccessory,
                    serviceLife: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'drawing':
                newAccessory = {
                    ...this.props.currentAccessory,
                    drawing: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'weight':
                newAccessory = {
                    ...this.props.currentAccessory,
                    weight: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'amount':
                newAccessory = {
                    ...this.props.currentAccessory,
                    amount: event.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            default:
            // do nothing
        }
    };

    handleDateChange = (value, field) => {
        let newAccessory;
        switch (field) {
            case 'enableTime':
                newAccessory = {
                    ...this.props.currentAccessory,
                    enableTime: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            default:
            // do nothing
        }
    };

    handleSelectChange = (value, field) => {
        let newAccessory;
        switch (field) {
            case 'cat':
                newAccessory = {
                    ...this.props.currentAccessory,
                    cat: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'companyId':
                newAccessory = {
                    ...this.props.currentAccessory,
                    companyId: value.target.value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'isVulnerable':
                newAccessory = {
                    ...this.props.currentAccessory,
                    isVulnerable: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'isCommon':
                newAccessory = {
                    ...this.props.currentAccessory,
                    isCommon: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'posType':
                newAccessory = {
                    ...this.props.currentAccessory,
                    posType: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            case 'installPos':
                newAccessory = {
                    ...this.props.currentAccessory,
                    installPos: value
                };
                this.props.onhandleAccessoryChange(true);
                this.props.onhandleUpdateEditAccessory(newAccessory);
                break;
            default:
            // do nothing
        }
    };


    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'accName') {
            if (_.trim(this.props.currentAccessory.accName) === '') {
                err['accName'] = '部件名称不能为空';
            } else {
                err['accName'] = '';
            }
        }

        if (!field || field === 'cat') {
            if (this.props.currentAccessory.cat) {
                err['cat'] = '';
            } else {
                err['cat'] = '请选择设备类型';
            }
        }

        if (!field || field === 'companyId') {
            if (this.props.currentAccessory.companyId) {
                err['companyId'] = '';
            } else {
                err['companyId'] = '请选择所属公司';
            }
        }
        if (!field || field === 'isCommon') {
            if (this.props.currentAccessory.isCommon === false || this.props.currentAccessory.isCommon) {
                err['isCommon'] = '';
            } else {
                err['isCommon'] = '请选择是否通用部件';
            }
        }

        if (!field || field === 'isVulnerable') {
            if (this.props.currentAccessory.isVulnerable === false || this.props.currentAccessory.isVulnerable) {
                err['isVulnerable'] = '';
            } else {
                err['isVulnerable'] = '请选择是否易损件';
            }
        }

        if (!field || field === 'accNo') {
            if (this.props.currentAccessory.accNo) {
                err['accNo'] = '';
            } else {
                err['accNo'] = '请输入部件编号';
            }
        }

        if (!field || field === 'model') {
            if (this.props.currentAccessory.model) {
                err['model'] = '';
            } else {
                err['model'] = '请输入型号';
            }
        }

        if (!field || field === 'material') {
            if (this.props.currentAccessory.material) {
                err['material'] = '';
            } else {
                err['material'] = '请输入材质';
            }
        }

        if (!field || field === 'manufacturer') {
            if (this.props.currentAccessory.manufacturer) {
                err['manufacturer'] = '';
            } else {
                err['manufacturer'] = '请输入制造商';
            }
        }

        if (!field || field === 'serviceLife') {
            if (!integerRegx.test(this.props.currentAccessory.serviceLife)) {
                err['serviceLife'] = '请输入整数';
            } else {
                err['serviceLife'] = '';
            }
        }

        if (!field || field === 'weight') {
            if (this.props.currentAccessory.weight !== '' && _.isNaN(_.toNumber(this.props.currentAccessory.weight))) {
                err['weight'] = '请输入数值';
            } else {
                err['weight'] = '';
            }
        }

        if (!field || field === 'amount') {
            if (!integerRegx.test(this.props.currentAccessory.amount)) {
                err['amount'] = '请输入整数';
            } else {
                err['amount'] = '';
            }
        }

        let newError = {
            ...this.props.accessoryError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    handleDialog = (event) => {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            //companyId: this.props.currentDevice.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let deviceName = this.props.selectedDevices[0].deviceName
                        let deviceId = this.props.selectedDevices[0].deviceId
                        let newAccessory = {
                            ...this.props.currentAccessory,
                            deviceName: deviceName,
                            deviceId: deviceId
                        };
                        this.props.onhandleAccessoryChange(true);
                        this.props.onhandleUpdateEditAccessory(newAccessory);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    render() {
        return (
            <div>
                <AccessoryDetail
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    currentAccessory={this.props.currentAccessory}
                    accessoryError={this.props.accessoryError}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleDialog={this.handleDialog.bind(this)}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.props.companyList}
                    catTree={this.props.catTree}
                    accessoryChange={this.props.accessoryChange}
                    posTypeTree={this.props.posTypeTree}
                    posKeyTypeTree={this.props.posKeyTypeTree}
                    isView={this.props.isview}
                    userProfile={this.props.userProfile}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        accessoryChange: state.accessory.accessoryChange,
        currentAccessory: state.accessory.currentAccessory,
        accessoryError: state.accessory.accessoryError,
        catTree: state.accessory.catTree,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        userProfile: state.auth.userProfile,
        posTypeTree: state.accessory.posTypeTree,
        posKeyTypeTree: state.accessory.posKeyTypeTree,
        isview: state.accessory.view,
        currentUserInfo: state.auth.currentUserInfo,
        selectedDevices: state.deviceSelect.selectedDevices
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateAccessory: (entity) => {
            dispatch(insertAccessory(entity))
        },
        onhandleUpdateAccessory: (entity) => {
            dispatch(modifyAccessory(entity))
        },
        onhandleUpdateEditAccessory: (entity) => {
            dispatch(setCurrentAccessory(entity))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleSetError: (err) => {
            dispatch(setAccessoryError(err))
        },
        onhandleAccessoryChange: (isChanged) => {
            dispatch(setAccessoryChange(isChanged))
        },
        onhandleInitAccessoryEdit: () => {
            dispatch(initAccessoryEdit());
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccessoryEditContainer)
