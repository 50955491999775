import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dialogContent: {
        minWidth: 400
    },
    contentGrid: {
        flexDirection: 'column'
    },
    itemGrid: {
        padding: theme.spacing(2),
    }
});


export default withStyles(styles);