import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegulationPlan from "../../../components/LeadershipManagement/RegulationPlan";
import {
    getPlan,
    addPlans,
    updatePlans,
    getPlanDetailsP,
    updatePlanDetails,
    addPlanDetail,
    deletePlanDetail,
    getCurrentPlan,
    executeProcessByRecordId,
    backToPlanList
} from "../../../redux/modules/leadershipRegulationPlan";
import { getProcessByRoleId, getCurrentProcessStatusR } from "../../../redux/modules/process";
import { getBusinessUnitsByRole, getDepartmentsByRole, getRole } from "../../../utils/userUtils";
import { getTimeStampByString, getNowTimestamp } from "../../../utils/datetime";
import { getRegulations } from "../../../redux/modules/regulation"

import ApprovePannel from "../ApprovePannel";
import RegulationPlanDetailAddPannel from "./RegulationPlanDetailAddPannel"

import { openApprovePannel } from "../../../redux/modules/approvePannel";
import { openPannel as openRegulationPannel } from "../../../redux/modules/regulation";
import { openPlanDetailAddPannel } from "../../../redux/modules/leadershipPlanDetailAdd";


import * as _ from 'lodash';
class RegulationPlansContainer extends Component {
    static propTypes = {
        // plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        userProfile: PropTypes.object,
        onhandleOpenPlanDetails: PropTypes.func,
        onhandleProcess: PropTypes.func,
    };

    componentDidMount() {
        const {
            currentUserInfo, 
            onhandleGetNewPlans,
            onhandleGetPlanDetails,
            onhandleGetPlan,
            onhandleGetCurrentProcessStatus,
            onhandleGetRegulations,
            currentPlanS
            //userProfile
        } = this.props


        onhandleGetNewPlans(currentUserInfo.roleId)
        //let unitId = userProfile.roles[0].units[0].unitId;
        //let planCode = 'RegulationPlan_' + unitId;
        onhandleGetPlan(currentPlanS.planId, (plan) => {
            if (!plan || Object.keys(plan).length === 0)
                return
                onhandleGetCurrentProcessStatus(plan.planCode, plan.recordId, (data) => {
                    if(data.statusCode === 'Published'){
                        onhandleGetPlanDetails('Drafting', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                    else{
                        onhandleGetPlanDetails('new', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                })
        })
            
        /*onhandleGetCurrentPlan((plan) => {
            if (!plan || Object.keys(plan).length === 0)
                return
                onhandleGetCurrentProcessStatus((data) => {
                    if(data.statusCode === 'Published'){
                        onhandleGetPlanDetails('Drafting', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                    else{
                        onhandleGetPlanDetails('new', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                })
        })*/
        onhandleGetRegulations()
    }

    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected' || action.review) {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleNewPlan(planId, newYear) {
        const {
            onhandleSavePlan,
            onhandleAddPlan,
            onhandleGetCurrentPlan,
            onhandleGetCurrentProcessStatus,
            onhandleGetPlanDetails
        } = this.props
        let oldPlan = {
            planId: planId,
            status: 'finalized'
        }
        onhandleSavePlan(oldPlan, (callback)=>{
            let newPlan = {
                planName: newYear + "年度规章制度完善计划",
                status: "active",
                planType: "ANNU",
                year: newYear,
                planId: 0,
                createTime: 0,
                finalTime: 0
            }
            onhandleAddPlan(newPlan)
            onhandleGetCurrentPlan((plan) => {
                if (!plan || Object.keys(plan).length === 0)
                    return
                    onhandleGetCurrentProcessStatus((data) => {
                        if(data.statusCode === 'Published'){
                            onhandleGetPlanDetails('Drafting', '1', plan.planId, (data) => {
                                console.log(data)
                            })
                        }
                        else{
                            onhandleGetPlanDetails('new', '1', plan.planId, (data) => {
                                console.log(data)
                            })
                        }
                    })
            })
        }) 
    }

    handleExecuteProcess(action) {
        const {
            currentUserInfo,
            planDetails,
            businessUnits,
            currentPlanS,
            currentProcessStatus,
            onhandleProcess,
            onhandleGetPlanDetails,
            onhandleGetCurrentProcessStatus,
            onhandleBackToPlanList
        } = this.props
        let actionParams = []
        actionParams.push({
            recordId: currentPlanS.recordId,
            planCode: currentPlanS.planCode,
            planId: currentPlanS.planId
        })
        if (!action.distributed) {

            let planDetailFilter = null
            if (action.statusCode === 'Rejected') {//驳回本级，全业务线
                planDetailFilter = _.filter(planDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId
                })
            } else if (currentProcessStatus.statusCode === 'Rejected' || currentProcessStatus.statusCode === 'Drafting') { // 不是驳回，是本级提交，分业务线。
                planDetailFilter = _.filter(planDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId && _.find(businessUnits, function (o) {
                        return o.unitId === detail.unitId
                    })
                })
            }
            if (planDetailFilter) {
                actionParams.push({
                    handle: "updateGoalDetails",
                    planDetails: planDetailFilter,
                    status: action.statusCode === 'Rejected' ? 'Rejected' : 'Submitted'
                })
            }
        }

        if (action.review) { //增加会审记录
            actionParams.push({
                handle: "addPlanReview",
                planId: currentPlanS.planId,
                comment: action.comment,
            })

        }

        let processParams = {
            action: action,
            actionParams: actionParams
        }

        onhandleProcess(processParams, (processData) => {
            console.log(processData)
            if(processData.comment === '发布'){
                onhandleGetPlanDetails('Drafting', '1', currentPlanS.planId, (data) => {
                    console.log(data)
                })
            }   
            else{
                onhandleGetPlanDetails('new', '1', currentPlanS.planId, (data) => {
                    console.log(data)
                })
            }
            onhandleGetCurrentProcessStatus(currentPlanS.planCode, currentPlanS.recordId)
            onhandleBackToPlanList()
        })
    }

    handleAdd(planDetail) {
        const { currentPlanS, onhandleAdd, onhandleGetPlanDetails, regulations, businessUnits } = this.props
        if (planDetail.planType && planDetail.planType !== 'Add') {
            planDetail.regId = Number(planDetail.regId)
            let reg = _.find(regulations, (r) => { return r.regId === planDetail.regId })
            planDetail['unitId'] = reg.unitId
        } else {
            planDetail['planType'] = 'Add'
            planDetail['unitId'] = businessUnits[0].unitId
            if (!planDetail.revisionUnit) {
                planDetail['revisionUnit'] = 'YEAR'
            }
            planDetail.revisionCycle = Number(planDetail.revisionCycle)
        }
        planDetail['planId'] = currentPlanS.planId
        if (planDetail.targetDate) {
            planDetail['targetDate'] = getTimeStampByString(planDetail.targetDate)
        } else {
            planDetail['targetDate'] = getNowTimestamp()
        }
        onhandleAdd(planDetail, () => {
            onhandleGetPlanDetails('new', '1', currentPlanS.planId, (data) => {
                console.log(data)
            })
        })
    }

    async handleUpdate(planDetail) {
        const { onhandleUpdate, onhandleGetPlanDetails } = this.props
        let updateDetail = {
            dtlId: planDetail.dtlId,
            content: planDetail.content,
            targetDate: getTimeStampByString(planDetail.targetDate)
        }
        await onhandleUpdate([updateDetail])
        onhandleGetPlanDetails('new', '1', '', (data) => {
            console.log(data)
        })
    }

    handleDelete(planDetail) {
        const { onhandleDelete, onhandleGetPlanDetails, currentPlanS } = this.props
        onhandleDelete(planDetail.dtlId, () => {
            onhandleGetPlanDetails('new', '1', currentPlanS.planId, (data) => {
                console.log(data)
            })
        })
    }

    handleOpenRegulation() {
        const { onhandleOpenRegulation } = this.props
        onhandleOpenRegulation()
    }

    handleBack = (event) => {
        this.props.onhandleBackToPlanList();
    };

    handleOpenPlanDetailAddPannel() {
        const { onhandleOpenPlanDetailAddPannel } = this.props
        onhandleOpenPlanDetailAddPannel(this.handleAdd.bind(this))
    }

    render() {
        let createPermission = _.find(this.props.startProcesses, plan => { return plan.processCode === 'RegulationPlan' })
        createPermission = createPermission ? true : false

        return (
            <div>
                <RegulationPlan
                    createPermission={createPermission}
                    plan={this.props.currentPlanS}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    departments={this.props.departments}
                    currentPlanDetails={this.props.planDetails}
                    regulations={this.props.regulations}
                    onhandleOpenPlanDetailAddPannel={this.handleOpenPlanDetailAddPannel.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onhandleNewPlan={this.handleNewPlan.bind(this)}
                    currentProcessStatus={this.props.currentProcessStatus}
                    onhandleBack={this.handleBack.bind(this)}
                />
                <ApprovePannel />
                <RegulationPlanDetailAddPannel />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.currentPlan,
        planDetails: state.leadershipRegulationPlan.planDetails,
        currentPlanS: state.leadershipRegulationPlan.currentPlanS,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        regulations: state.regulation.regulations,
        currentProcessStatus: state.process.currentProcessStatus.RegulationPlan || {},
        startProcesses: state.process.startProcesses,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetNewPlans: (roleId) => {
            dispatch(getProcessByRoleId(roleId, 1))
        },
        onhandleGetCurrentProcessStatus: (planCode, recordId,callback) => {
            dispatch(getCurrentProcessStatusR(planCode, 'RegulationPlan', recordId, callback))
        },
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getCurrentPlan(callback))
        },
        onhandleGetRegulations: () => {
            dispatch(getRegulations())
        },

        onhandleGetPlan: (planId, callback) => {
            dispatch(getPlan(planId, callback))
        },
        onhandleGetPlanDetails: (status,type,planId,callback) => {
            dispatch(getPlanDetailsP(status,type,planId,callback))
        },
        onhandleSavePlan: (plan, callback) => {
            dispatch(updatePlans(plan, callback))
        },
        onhandleAddPlan: (plan) => {
            dispatch(addPlans(plan))
        },
        onhandleUpdate: (planDetails, callback) => {
            dispatch(updatePlanDetails(planDetails, callback))
        },
        onhandleAdd: (planDetail, callback) => {
            dispatch(addPlanDetail(planDetail, callback))
        },
        onhandleDelete: (dtlId, callback) => {
            dispatch(deletePlanDetail(dtlId, callback))
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeProcessByRecordId(actionParams, callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleOpenRegulation: (changeCallback, closeCallback) => {
            dispatch(openRegulationPannel(changeCallback, closeCallback))
        },
        onhandleOpenPlanDetailAddPannel: (confirmCallback, closeCallback) => {
            dispatch(openPlanDetailAddPannel(confirmCallback, closeCallback))
        },
        onhandleBackToPlanList: () => {
            dispatch(backToPlanList())
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlansContainer)