import React, { Component } from 'react';
import { connect } from 'react-redux';
import InstallActionRecord from '@/components/ManufactureManagement/InstallAction/InstallActionRecord';
import PropTypes from 'prop-types';
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { getMoment, formatDate, convertToMoment } from '@/utils/datetime';
import { getRecords } from '@/redux/modules/installActionRecord';
import { getInstallationList } from '@/redux/modules/installation';
import * as _ from 'lodash'

class InstallActionRecordContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            startDate: getMoment()(),
            endDate: getMoment()(),
            selectedCompanyId: 0,
            companies: [],
            installations: [],
            selectedInstallId: 0,
            selectedDeviceId: 0,
            deviceName: "",
            allInstalls: []
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        this.props.getInstallationList().then(() => {
            const { currentUserInfo, companies } = this.props

            const pid = this.getPid();

            if (pid) {
                const findCompanies = _.filter(companies, item => {
                    return currentUserInfo.companyId === item.companyId
                });
                this.setState({ companies: findCompanies, selectedCompanyId: currentUserInfo.companyId, installations: this.props.installationList.list, allInstalls: this.props.installationList.list});
            } else {
                const findCompanies = _.filter(companies, item => {
                    return currentUserInfo.companyId === item.pid
                });
                const cpy = { companyId: 0, companyName: "所有分厂" };
                findCompanies.unshift(cpy);
                this.setState({ companies: findCompanies, installations: this.props.installationList.list, allInstalls: this.props.installationList.list });
            }
        });
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }

    handleChangeCompany(event) {
        var installs = Number(event.target.value) === 0 ? this.state.allInstalls : _.filter(this.state.allInstalls, item => {
            return item.companyId === Number(event.target.value)
        })
        this.setState({ selectedCompanyId: Number(event.target.value), installations: installs,selectedInstallId:0, selectedDeviceId: 0, deviceName: "" })
    }

    handleChangeInstall(event) {
        this.setState({ selectedInstallId: event.target.value, selectedDeviceId: 0, deviceName: "" })
    }

    handleDateChange = (date, dateType) => {
        if (dateType === 'startDate') {
            this.setState({
                startDate: convertToMoment(date)
            })
        } else {
            this.setState({
                endDate: convertToMoment(date)
            })
        }
    }

    handleSelectDevice = () => {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.state.selectedCompanyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        this.setState({
                            deviceName: this.props.selectedDevices[0].deviceName,
                            selectedDeviceId: this.props.selectedDevices[0].deviceId
                        });
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);
    }

    handleSearch = (event) => {
        let ed = convertToMoment(this.state.endDate);

        this.props.getRecords(this.state.selectedCompanyId, this.state.selectedInstallId, this.state.selectedDeviceId,
            formatDate(this.state.startDate), formatDate(ed.add(1, 'days')));
    }

    handleClearSearch = (event) => {
        this.setState({
            startDate: getMoment()(),
            endDate: getMoment()(),
            selectedCompanyId: 0,
            installations: this.state.allInstalls,
            selectedInstallId: 0,
            selectedDeviceId: 0,
            deviceName: ""
        })
    }

    render() {
        return (
            <InstallActionRecord
                companies={this.state.companies}
                selectedCompanyId={this.state.selectedCompanyId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                constant={this.props.constant}
                records={this.props.records}
                deviceName={this.state.deviceName}
                selectedInstallId={this.state.selectedInstallId}
                onhandleChangeInstall={this.handleChangeInstall.bind(this)}
                installations={this.state.installations}
                onhandleChangeCompany={this.handleChangeCompany.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleSelectDevice={this.handleSelectDevice.bind(this)}
                onhandleSearch={this.handleSearch.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.installActionRecord.records,
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.company.companyList.list,
        constant: state.constant,
        installationList: state.installation.installationList,
        selectedDevices: state.deviceSelect.selectedDevices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecords: (companyId, installId, deviceId, startDate, endDate) => {
            return dispatch(getRecords(undefined, installId, undefined, deviceId, undefined, companyId, undefined, undefined, startDate, endDate));
        },
        getInstallationList: () => {
            return dispatch(getInstallationList({ offset: 0, size: 0 }))
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallActionRecordContainer);
