

/**
 * 按照enumValues的元素顺序进行排序
 * @param {Array} enumValues 
 * @param {Array} list 
 * @param {any} field 
 * @returns 
 */
export function enumSort(enumValues = [], list = [], field) {
    const sorted = [];
    // const others = [];
    enumValues.forEach(enumValue => {
        if (!field) {
            // 基本类型数组
            list.forEach(item => {
                if (enumValue === item) {
                    sorted.push(item)
                }
            })
        } else {
            // 对象数组
            list.forEach(item => {
                if (enumValue === item[field]) {
                    sorted.push(item)
                }
            })
        }
    })
    // 末尾追加 others
    // list.forEach(item => {
    //     if (!field) {
    //         if (!enumValues.includes(item)) {
    //             others.push(item)
    //         }
    //     } else {
    //         if (!enumValues.includes(item[field])) {
    //             others.push(item)
    //         }
    //     }
    // })
    // return [...sorted, ...others];
    return sorted;
}

/**
 * 表格每页size变更时，计算新的当前页（页码从0计）
 * TODO: 以保持list当前页起始内容不变为前提，调整页码
 * @param {*} newSize 变更后size
 * @param {*} currentSize 变更前size
 * @param {*} currentPage 当前页
 * @param {*} listTotal 表格记录总数
 * @returns 
 */
export function getPageNumBySizeChanged(newSize = 0, currentSize = 0, currentPage = 0, listTotal = 0) {

    let newPage = 0;
    // let maxPage = Math.ceil(listTotal / newSize) - 1;
    // Math.ceil(currentSize * (currentPage) / newSize);
    // newPage = newPage > maxPage ? maxPage : newPage;
    // newPage = newPage < 0 ? 0 : newPage;

    return newPage;
}