import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Link,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

const ApprovePannel = ({
    classes,
    open,
    title,
    comment,
    withDoc,
    onhandleClose,
    onhandleConfirm,
    onhandleChangeComment,
    docInfo,
    onhandleUpload,
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">
                    <Typography component={'span'} variant='h3'>{title}确认对话框</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField className={classes.textfield} multiline
                        label="建议"
                        variant='outlined'
                        rows="4"
                        value={comment || ''}
                        name='opentext'
                        onChange={onhandleChangeComment}
                    />
                    {withDoc ? <Grid container className={classes.contentGrid}>
                        {
                            docInfo && docInfo.name ?
                                <Grid item className={classes.itemGrid} alignItems={'flex-end'} container>
                                    <Typography align={'right'}>已选择文件（保存时上传）：</Typography>
                                    <Link underline='always' component="button" onClick={onhandleUpload}>{docInfo.name}</Link>
                                </Grid>
                                : <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={onhandleUpload}
                                    startIcon={<CloudUpload />}
                                >
                                    {'上传文档'}
                                </Button>
                        }
                    </Grid> : <Grid></Grid>}

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>
                        确定</Button>
                    <Button variant="contained" color="primary" onClick={onhandleClose}>
                        关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
ApprovePannel.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onhandleConfirm: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleChangeComment: PropTypes.func.isRequired,
};

ApprovePannel.defaultProps = {
    config: {},
}

export default (withStyles(ApprovePannel));