import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import GoalNew from "../../../components/LeadershipManagement/AnnualGoal/GoalNew";
import { getCurrentGoal } from "../../../redux/modules/leadershipGoal";

// import { getBusinessUnitsByRole, getDepartmentsByRole, } from "../../../utils/userUtils";
import { withRouter } from 'react-router-dom';
import GoalTable from '../../../components/LeadershipManagement/AnnualGoal/GoalTable/GoalTable';

// import * as _ from 'lodash';

class GoalContainer extends Component {
    static propTypes = {
        goal: PropTypes.object,
        constant: PropTypes.object,
        process: PropTypes.array,
        goalDetails: PropTypes.array,
    };

    componentDidMount() {
        this.props.onhandleGetCurrentGoal((goal) => {
            if (!goal || Object.keys(goal).length === 0) //当前没currentgoal
                return
        })
    }


    render() {
        return (
            <div>
                <GoalTable
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    goal={this.props.goal}
                    goalDetails={this.props.goalDetails}
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        goal: state.leadershipGoal.currentGoal.goal,
        goalDetails: state.leadershipGoal.currentGoal.goalDetails,
        constant: state.constant,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCurrentGoal: (callback) => {
            dispatch(getCurrentGoal(callback))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GoalContainer))