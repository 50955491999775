import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { getDateByTimestamp } from '../../../../../utils/datetime';
// import ComponentSelectionComponent from '../../../CompanySelection/CompanySelectionComponent';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Tooltip,
    MenuItem
} from '@material-ui/core';
import {
    Info
} from '@material-ui/icons'
import * as _ from 'lodash';


const RouteInfoWriteComponent = props => {
    const {
        classes,
        currentUserInfo,
        //userProfile,
        // companyTree,
        companyList,
        confirmRoleList,
        inspectionDictionaries,
        currentRoute,
        isRouteEditMode,
        //isRouteChange,
        onhandleSelect,
        onhandleChange,
        onhandleChangeRecordConfirm,
        routeError,
        users
    } = props;

    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE" && t.code !== 'OIT05') {
                result.push({ label: t.name, value: t.code })
            }
        })

        return result;
    };

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
    //     return permission && permission.action === 'W'
    // };


    const renderUser = (id) => {
        let item = _.filter(users.list, function (o) { return id === o.userId })
        if (item && item.length) {
            return item[0].lastName + item[0].firstName
        }
        return ''
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid item xs={12} className={classes.content}>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            {/* 巡检路线 */}
                            {'路线编码'}
                            <Tooltip
                                title={'* 如果填写的编码与其他[已发布]或[废止]路线的编码相同，则本路线会被视为同编码路线的新版本'}
                                classes={{ tooltip: classes.tooltip }}
                                placement={'top'}

                            >
                                <Info />
                            </Tooltip>

                        </Typography>
                        <Grid item xs={11}>
                            <TextField
                                className={classes.textField}
                                variant="standard"
                                disabled={!isRouteEditMode}
                                value={currentRoute.routeCode || ''}
                                error={routeError.routeCode !== ''}
                                helperText={routeError.routeCode}
                                onChange={(event) => onhandleChange(event, "routeCode")}
                            ></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            创建时间
                        </Typography>
                        <Grid item xs={11}>
                            <Typography variant="h5" className={classes.label}>{getDateByTimestamp(currentRoute.createTime ? currentRoute.createTime : new Date(), true)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            巡检类型 *
                        </Typography>
                        <Grid item xs={11}>
                            <TextField
                                style={{ width: '90%' }}
                                id="filled-select-inspectiontype"
                                select
                                value={currentRoute.inspectionType}
                                error={routeError.inspectionType !== ''}
                                helperText={routeError.inspectionType}
                                onChange={(event) => onhandleSelect(event, 'inspectionType')}
                                variant="standard"
                                disabled={!isRouteEditMode}
                                className={classes.select}
                            >
                                {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* <TreeSelect
                                disabled={!isRouteEditMode}
                                className={classes.select}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentRoute.inspectionType}
                                labelInValue
                                treeData={getInspectionTypeList()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                onChange={(value) => onhandleSelect(value, 'inspectionType')}
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            创建人
                        </Typography>
                        <Grid item xs={11}>
                            <Typography variant="h5" className={classes.label}>{(currentRoute && currentRoute.routeId !== 0) ? renderUser(currentRoute.creator) : (currentUserInfo.lastName + currentUserInfo.firstName)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            路线名称
                        </Typography>
                        <Grid item xs={11}>
                            <TextField
                                disabled={!isRouteEditMode}
                                className={classes.textField}
                                variant="standard"
                                value={currentRoute.routeName || ''}
                                error={routeError.routeName !== ''}
                                helperText={routeError.routeName}
                                onChange={(event) => onhandleChange(event, "routeName")}
                            ></TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            更新时间
                        </Typography>
                        <Grid item xs={11}>
                            <Typography variant="h5" className={classes.label}>{getDateByTimestamp(currentRoute.updateTime ? currentRoute.updateTime : new Date(), true)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            所属单位 *
                        </Typography>
                        <Grid item xs={11}>
                            <TextField
                                style={{ width: '90%' }}
                                id="filled-select-company"
                                select
                                value={currentRoute.companyId}
                                error={Boolean(routeError.companyId)}
                                helperText={routeError.companyId}
                                onChange={(event) => onhandleSelect(event, 'companyId')}
                                variant="standard"
                                disabled={!!currentRoute.routeId && !!currentRoute.companyId}
                                className={classes.select}
                            >
                                {_.sortBy(companyList, 'companyId').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <TreeSelect
                                disabled={currentRoute.routeId && currentRoute.companyId}
                                className={classes.select}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentRoute.companyId}
                                labelInValue
                                treeData={companyList}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                onChange={(value) => onhandleSelect(value, 'companyId')}
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.label}>
                            更新人
                        </Typography>
                        <Grid item xs={11}>
                            <Typography variant="h5" className={classes.label}>{(currentRoute && currentRoute.routeId !== 0) ? renderUser(currentRoute.revisor) : (currentUserInfo.lastName + currentUserInfo.firstName)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentRoute.recordConfirm || false}
                                    disabled={!isRouteEditMode}
                                    name='recordConfirm'
                                    onChange={e => onhandleChangeRecordConfirm(e.target.checked)}
                                />
                            }
                            style={{ marginLeft: '0px' }}
                            labelPlacement="start"
                            label={
                                <Typography variant="h5" className={classes.label}>
                                    {currentRoute.recordConfirm ? "巡检复核角色" : "启用巡检复核"}
                                </Typography>}
                        />
                        <Grid item container xs={11}>
                            {
                                Boolean(currentRoute.recordConfirm) &&
                                <TextField
                                    style={{ width: '90%' }}
                                    select
                                    value={currentRoute.confirmRole || 0}
                                    error={Boolean(routeError.confirmRole)}
                                    helperText={routeError.confirmRole}
                                    onChange={(event) => onhandleSelect(event, 'confirmRole')}
                                    variant="standard"
                                    disabled={!isRouteEditMode}
                                    className={classes.select}
                                >
                                    {_.sortBy(confirmRoleList, 'confirmRole').filter(option => (option.companyId === currentRoute.companyId)).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>

            </Grid>
        </div >
    );
};
RouteInfoWriteComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    //userProfile: PropTypes.object.isRequired,
    // companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    currentRoute: PropTypes.object.isRequired,
    isRouteEditMode: PropTypes.bool.isRequired,
    //isRouteChange: PropTypes.bool.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    routeError: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
};

export default withStyles(RouteInfoWriteComponent);