import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  infoGrid: {
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textAlign: 'right',
    width: 80,
    paddingRight: theme.spacing(2),
  },
  content: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    width: 'calc(100% - 80px)'
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  basicInfoContainer: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  tag: {
    marginLeft: 20,
    fontWeight: 400,
    color: theme.palette.white,
    backgroundColor: theme.palette.text.emphasize
  },
  title: {
    // padding: theme.spacing(2)
  },
  subTitle: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    borderLeft: '2px solid ' + theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  // button: {
  //   padding: 16,
  //   paddingTop: 24,
  // },
  flexgrow: {
    height: '100%'
  },
  pic1: {
    width: 20,
    height: 20,
    backgroundColor: colors.blue[500],
    fontSize: '12px',
  },
  pic2: {
    width: 20,
    height: 20,
    backgroundColor: colors.teal[400],
    fontSize: '12px',
  },
  processline: {
    flexGrow: 1
  },
  processUser: {
    paddingLeft: theme.spacing(2),
    display: 'contents'
  },
  attention: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    background: 'rgba(255,80,0,0.05)',
    border: '1px solid rgba(255,80,0,1)'
  },
  attentiontitle: {
    fontWeight: 500,
    color: '#FF5000FF'
  },
  attentioncontent: {
    color: '#FF5000FF'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inlineButton: {
    margin: theme.spacing(0)
  },
  pendingUser: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginLeft: theme.spacing(1),
  },
  rejectedUser: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginLeft: theme.spacing(1)
  },
  approvedUser: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginLeft: theme.spacing(1)
  },
  historyList: {
    // height: '30vh',
    overflowY: 'auto',
    width: '100%'
  },
  alert: {
    margin: theme.spacing(1),
    height: '100%',
  },
  permitBar: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
  },
  new: {
    width: 60,
    backgroundColor: colors.grey[400],
    color: theme.palette.white,
  },
  submitted: {
    width: 60,
    backgroundColor: colors.yellow[400],
    color: theme.palette.black,
  },
  approved: {
    width: 60,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  finalized: {
    width: 60,
    backgroundColor: colors.yellow[500],
    color: theme.palette.black,
  },
  published: {
    width: 60,
    backgroundColor: colors.teal[500],
    color: theme.palette.white,
  },
  done: {
    width: 60,
    backgroundColor: colors.blue[400],
    color: theme.palette.white,
  },
  rejected: {
    width: 60,
    backgroundColor: colors.orange[400],
    color: theme.palette.white,
  },
  default: {
    width: 60,
    backgroundColor: colors.grey[300],
    color: theme.palette.white,
  },
  permitHistory: {
    paddingLeft: '4em'
  },
  permitHistoryItem: {
    margin: 0,
    padding: 0,
    paddingLeft: '1em'
  },
  confirmTrue: {
    color: theme.palette.success.main
  },
  confirmFalse: {
    color: theme.palette.error.main
  },
  detailtable: {
    padding: theme.spacing(1),
  },
  mtHeader: {
    backgroundColor: theme.palette.table.header.backgroundColor,
  },
  longContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  processActionGrid: {
  },
  processAction: {
    padding: 0,
  },
});

export default withStyles(styles);
