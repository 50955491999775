import moment from "moment";
import _ from "lodash";
require('moment/locale/zh-cn');
moment.locale('zh-cn')

export const getDateTimeAfterSomeMins = (ts, someMins, fullmode) => {
    return fullmode ? moment(ts).add(someMins, 'minutes').format('YYYY/MM/DD HH:mm') : moment(ts).format('YYYY/MM/DD')
}

export const convertToMoment = date => {
    return moment(date)
}

export const getMoment = () => {
    moment.locale('zh-cn')
    return moment
}
export const formatUTC = (time, format) => {
    try {
        return moment.utc(time).format(format);
    } catch (e) {
        return null;
    }
}

export const getDateByString = (timeStr, showWeekday) => {
    let time = new Date(timeStr)
    return moment(parseInt(time.getTime())).format(showWeekday ? 'YYYY/MM/DD(ddd)' : 'YYYY/MM/DD');
}

export const getTimeStampByString = (timeStr) => {
    let time = new Date(timeStr)
    return moment(parseInt(time.getTime())).valueOf()
}

export const getDateByTimestamp = (ts, fullmode) => {
    return fullmode ? moment(ts).format('YYYY/MM/DD HH:mm') : moment(ts).format('YYYY/MM/DD')
}

export const getToday = () => {
    return moment().format("YYYY-MM-DD'T'00:00:00")
}
export const getPreDate = () => {
    return moment().subtract(1, 'days').date()
}
export const getDate = () => {
    return moment().date()
}
export const getYear = () => {
    return moment().year()
}
export const getMonth = () => {
    return moment().month()
}
export const getYearMonth = () => {
    return moment().format('YYYY/MM')
}

export const getLastDayInYear = (year) => {
    let d = year + "-12-31";
    return moment(d).format("YYYY-MM-DD")
}

export const getNowUTC = () => { //2019-12-24T14:25:54.458Z
    //return moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    return moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z'
}

export const getNowTimestamp = () => {
    return moment().valueOf()
}

export const isLaterThan = (val, toCompare) => {
    if (toCompare) {
        return moment(val).valueOf() > moment(toCompare).valueOf()
    } else {
        return moment(val).valueOf() > moment().valueOf()
    }
}

export const getUTCByString = (timeStr) => {
    let time = new Date(timeStr)
    //return moment(parseInt(time.getTime())).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return moment(parseInt(time.getTime())).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z';
}

export const getTimeUnitByCode = (code) => {
    const unitMap = {
        YEAR: '年',
        MONTH: '月',
        DAY: '天',
        HOUR: '小时',
        MINUTE: '分',
        SECEND: '秒'
    }
    return unitMap[code] || code
}

export const dateCompare = (dt1, dt2) => {
    moment.locale('zh-cn')
    return moment(dt1).format('YYYYMMDD') === moment(dt2).format('YYYYMMDD');
}

export const getEveryWeekdayInMonth = function (year, month, weekday, steps) {

    moment.locale('zh-cn')
    var days = []
    // get first weekday
    var day = moment([year, month, 1])
    day.weekday(weekday)
    if (day.month() < month)
        day.weekday(7)

    while (day.month() === month) {
        days.push(day.valueOf())
        day.weekday(7 * steps)
    }
    return days
}

export const formatYear = date => {
    return moment(date).format('YYYY')
}

export const formatMonth = date => {
    return moment(date).format('YYYY/MM')
}

export const formatDay = date => {
    return moment(date).format('YYYY/MM/DD')
}

export const formatOnlyMonth = date => {
    return moment(date).format('MM')
}

export const formatOnlyDay = date => {
    return moment(date).format('DD')
}

export const formatDate = date => {
    return moment(date).format('YYYY-MM-DD')
}

export const formatDate_hms = date => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatOnlyHM = date => {
    return moment(date).format('HH:mm')
}

export const formatWeekday = date => {
    switch (moment(date).format('d')) {
        case '0':
            return '日'
        case '1':
            return '一'
        case '2':
            return '二'
        case '3':
            return '三'
        case '4':
            return '四'
        case '5':
            return '五'
        case '6':
            return '六'
        default:
            return ''
    }
}
/**
 * 给定时间区间，按频次类型在区间内划分时间段(隐患排查计划)
 * @param frequencyType 
 * @param startTime 
 * @param inspectTime 
 * @param holidays
 * @returns [ {startTime1, inspectTime1}, {startTime2, inspectTime2}, ...{startTimeN, inspectTimeN} ]
 */
export const separateDates = (frequencyType, startTime, inspectTime, holidays) => {
    if (!startTime || !inspectTime) return [];

    moment.locale('zh-cn')

    const startMoment = moment(startTime);
    const inspectMoment = moment(inspectTime);

    /**
     * 设定所需启止时刻区间不满足Moment的周/月时间段定义，故进行以下处理：
     * 1. 添加第一天到下一次完整周期前的时刻
     * 2. 截取下一次完整周期以及之后每个完整周期的启止时刻
     * 3. （如有剩余时间）添加最后一次周期结束到截止日期的时刻
     */
    let dates = [];

    if (frequencyType === 'day') {
        dates.push({
            startTime: startMoment.valueOf(),
            inspectTime: startMoment.endOf('day').valueOf()
        })
        // 不clone会爆炸
        let indexMoment = startMoment.clone().add(1, 'days');
        while (indexMoment.endOf('day').isBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('day').valueOf(),
                inspectTime: indexMoment.endOf('day').valueOf()
            })
            indexMoment = indexMoment.add(1, 'days');
        }
        if (indexMoment.startOf('day').isSameOrBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('day').valueOf(),
                inspectTime: inspectMoment.valueOf()
            })
        }
    }

    if (frequencyType === 'workday') {
        dates.push({
            startTime: startMoment.valueOf(),
            inspectTime: startMoment.endOf('day').valueOf()
        })
        let indexMoment = startMoment.clone().add(1, 'days');
        while (indexMoment.endOf('day').isBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('day').valueOf(),
                inspectTime: indexMoment.endOf('day').valueOf()
            })
            indexMoment = indexMoment.add(1, 'days');
        }
        if (indexMoment.startOf('day').isSameOrBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('day').valueOf(),
                inspectTime: inspectMoment.valueOf()
            })
        }
        dates = _.filter(dates, date => {
            const dateMoment = moment(date.startTime);
            const matchedHoliday = _.find(holidays, holiday => {
                const holidayStart = moment(holiday.startTime);
                const holidayEnd = moment(holiday.endTime);
                return dateMoment.isSameOrAfter(holidayStart) && dateMoment.isSameOrBefore(holidayEnd);
            });
            if (matchedHoliday) {
                if (matchedHoliday.holidayType === 'COM') {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (dateMoment.weekday() !== 6 && dateMoment.weekday() !== 5) {
                    return true;
                }
            }
            return false;
        });
    }

    if (frequencyType === 'week') {
        dates.push({
            startTime: startMoment.valueOf(),
            inspectTime: startMoment.endOf('week').valueOf(),
            hasTip: startMoment.endOf('week').diff(startMoment, 'days') < 6
        })
        let indexMoment = startMoment.clone().add(1, 'weeks');
        while (indexMoment.endOf('week').isBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('week').valueOf(),
                inspectTime: indexMoment.endOf('week').valueOf()
            })
            indexMoment = indexMoment.add(1, 'weeks');
        }
        if (indexMoment.startOf('week').isSameOrBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('week').valueOf(),
                inspectTime: inspectMoment.valueOf(),
                hasTip: inspectMoment.diff(indexMoment.startOf('week'), 'days') < 6
            })
        }
    }

    if (frequencyType === 'fortnight') {
        dates.push({
            startTime: startMoment.valueOf(),
            inspectTime: startMoment.clone().add(1, 'weeks').endOf('week').valueOf(),
            hasTip: startMoment.clone().add(1, 'weeks').endOf('week').diff(startMoment, 'days') < 13
        })
        let indexMoment = startMoment.clone().add(2, 'weeks');
        while (indexMoment.clone().add(1, 'weeks').endOf('week').isBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('week').valueOf(),
                inspectTime: indexMoment.clone().add(1, 'weeks').endOf('week').valueOf()
            })
            indexMoment = indexMoment.add(2, 'weeks');
        }
        if (indexMoment.clone().startOf('week').isSameOrBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('week').valueOf(),
                inspectTime: inspectMoment.valueOf(),
                hasTip: inspectMoment.diff(indexMoment.startOf('week'), 'days') < 13
            })
        }
    }

    if (frequencyType === 'month') {
        dates.push({
            startTime: startMoment.valueOf(),
            inspectTime: startMoment.endOf('month').valueOf(),
            hasTip: startMoment.clone().endOf('month').add(1, 'days').startOf('day').diff(startMoment, 'months') < 1
        })
        let indexMoment = startMoment.add(1, 'months');
        while (indexMoment.endOf('month').isBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('month').valueOf(),
                inspectTime: indexMoment.endOf('month').valueOf()
            })
            indexMoment = indexMoment.add(1, 'months');
        }
        if (indexMoment.startOf('month').isSameOrBefore(inspectMoment)) {
            dates.push({
                startTime: indexMoment.startOf('month').valueOf(),
                inspectTime: inspectMoment.endOf('month').valueOf()
            })
        }
    }
    return dates;
}

/**
 * 返回包含时间段的有效状态文本
 * 
 */
export function renderValidInfo(startTime, endTime) {
    if (startTime && endTime) {
        if (getMoment()(startTime).isSame(endTime, 'day')) {
            return getDateByString(startTime) + '当日'
        }
        return getDateByString(startTime) + ' ~ ' + getDateByString(endTime) + '生效';
    }
    if (startTime && !endTime) {
        return getDateByString(startTime) + '生效';
    }
    if (!startTime && endTime) {
        return getDateByString(endTime) + '后失效';
    }
    return '长期有效'
}