import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RoleSelectPage from '@/components/ModalPage/RoleSelect';
import {
  setSearch,
  resetSearch,
  getRoleList,
  setSelectedRoles,
  exitSelected,
  resetSearchField,
  initRoleSelect
} from '@/redux/modules/roleSelect';
import { cleanmodal } from '@/redux/modules/modal';
import _ from 'lodash';

class RoleSelectPageContainer extends Component {
  static propTypes = {
    roleList: PropTypes.object,
    search: PropTypes.object,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    companyTree: PropTypes.array,
    companyList: PropTypes.array,
    userProfile: PropTypes.object,
    currentUserInfo: PropTypes.object,
  }

  componentDidMount() {
    this.props.onhandleInit();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        if (value && this.props.search.companyId !== value) {
          newSearch = {
            ...this.props.search,
            companyId: value.target.value,
          };
          this.props.onhandleUpdateEditSearch(newSearch);
          this.props.onhandleResetSearchField(value.target.value, 'company');
        } else {
        }
        break;
      case 'groupId':
        newSearch = {
          ...this.props.search,
          groupId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        this.props.onhandleResetSearchField(value.target.value, 'groupId');
        break;

      default:
      // do nothing
    }
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'roleName':
        newSearch = {
          ...this.props.search,
          roleName: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleInnerConfirm = (row) => {
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    if (parameters.callback) {
      parameters.callback(row)
    }
  };

  handleConfirm = () => {
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    if (parameters.callback) {
      parameters.callback()
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          // console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };


  filterCompany(companyList) {
    const { search } = this.props;
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    const filtedList = companyList
      .filter(company => {
        if (Array.isArray(parameters.companyId) && parameters.companyId.length > 0) {
          return parameters.companyId.includes(company.companyId);
        } else if (!isNaN(parameters.companyId)) {
          return company.companyId === parameters.companyId
        }
        return true;
      })
    // 检查搜索项是否在选项内
    if (filtedList.length > 0 && search.companyId &&
      _.findIndex(filtedList, { 'companyId': search.companyId }) < 0) {
      const newSearch = {
        ...this.props.search,
        companyId: filtedList[0].companyId,
        dptId: ''
      };
      this.props.onhandleUpdateEditSearch(newSearch);
    }
    return filtedList;
  }

  render() {
    return (
      <RoleSelectPage
        search={this.props.search}
        parameters={this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters}
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleChange={this.handleChange.bind(this)}
        onhandleClearSearch={this.props.onhandleClearSearch.bind(this)}
        roleList={this.props.roleList}
        groupList={this.props.groupList}
        companyList={this.filterCompany(this.props.companyList)}
        onhandleSearch={this.props.onhandleSearch.bind(this)}
        onhandleSetSelected={this.props.onhandleSetSelected.bind(this)}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        onhandleConfirm={this.handleConfirm.bind(this)}
        onhandleInnerConfirm={this.handleInnerConfirm.bind(this)}
        currentUserInfo={this.props.currentUserInfo}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRoles: state.roleSelect.selectedRoles,
    roleList: state.roleSelect.roleList,
    search: state.roleSelect.search,
    parameters: state.modal.parameters,
    companyList: state.constant.companies,
    groupList: state.roleSelect.groupList,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInit: () => {
      dispatch(initRoleSelect());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: () => {
      dispatch(getRoleList());
    },
    onhandleResetSearchField: (value, field) => {
      dispatch(resetSearchField(value, field));
    },
    onhandleSetSelected: (value) => {
      dispatch(setSelectedRoles(value));
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleSelectPageContainer);
