import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationPermitUserPanel from '@/components/OperationManagement/Appointment/OperationPermitUserPannel';
import { closePermitUserPanel, changePermitUser } from '@/redux/modules/operationPermitUser';

import _ from 'lodash';

class OperationPermitUserPanelContainer extends Component {
    static propTypes = {
        permitUser: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            permitUserError: {
                'opentext': ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(this.props.permitUser)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, permitUser } = this.props
        let newEntity = {
            ...permitUser
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {

        const {
            permitUser
        } = this.props;

        let err = {};

        if (!field || field === 'opentext') {
            if (_.trim(permitUser.opentext) === '') {
                err['opentext'] = '请填写驳回理由';
            } else {
                err['opentext'] = '';
            }
        }

        let newError = {
            ...this.state.permitUserError,
            ...err
        }
        this.setState({
            permitUserError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <OperationPermitUserPanel
                open={this.props.open}
                permitUser={this.props.permitUser}
                permitUserError={this.state.permitUserError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.operationPermitUser.open,
        permitUser: state.operationPermitUser.permitUser,
        confirmCallback: state.operationPermitUser.confirmCallback,
        closeCallback: state.operationPermitUser.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (permitUser) => {
            dispatch(changePermitUser(permitUser));
        },
        onhandleClose: () => {
            dispatch(closePermitUserPanel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPermitUserPanelContainer);