import React from 'react';
import {
    Link,
    Typography,
    Container,
} from '@material-ui/core';
import withStyles from './styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import beianIcon from '@/assets/备案图标.png';

const Signin = props => {

    const { classes,
    } = props;

    return (
        <Container className={classes.container}>
            <Typography className={classes.copyrights} variant="body2" color="textSecondary" align="left">
            </Typography>
            <Typography className={classes.copyrights} variant="body2" color="textSecondary" align="left">
                {'© 2020-'}
                {new Date().getFullYear()}
                <Link color="inherit" href="https://anfenginfo.com/">
                    {' 大连荣鹄信息科技有限公司'}
                </Link>
                {' 版权所有 IPC证：'}
                <Link color="inherit" href="http://beian.miit.gov.cn">
                    {'辽ICP备2021011663号-1'}
                </Link>
            </Typography>
            <Typography className={classes.secureRecords} variant="body2" color="textSecondary" align="left">
                <Link className={classes.secureRecordsText} color="inherit" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21029602000629">
                    <img className={classes.beianIcon} src={beianIcon} alt={'公安备案图标'} />
                    {' 辽公网安备 21029602000629号'}
                </Link>
            </Typography>
        </Container>
    );
};

Signin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(Signin));