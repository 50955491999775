import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
} from '@material-ui/core';
import FileUpload from '../../../FileUpload/FileUpload';



const RegulationUploadPannel = ({
    classes,
    open,
    regulations,
    planDetail,
    constant,
    businessUnits,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleDateChange
}) => {

    // const isAddAction = function () {
    //     return !planDetail.planType || planDetail.planType === 'Add'
    // }
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">上传规章制度</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container className={classes.contentContainer}>
                        <Grid item className={classes.contentItem}>
                            <TextField className={classes.textfield}
                                label="规章制度名称"
                                value={planDetail.regName ? planDetail.regName : ''}
                                name='regName'
                                disabled="true">
                            </TextField>
                        </Grid>
                        <Grid item className={classes.contentItem}>
                            <FileUpload />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>
                        确认</Button>
                    <Button variant="contained" color="primary" onClick={onhandleClose}>
                        取消</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
RegulationUploadPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};

RegulationUploadPannel.defaultProps = {

}

export default withStyles(RegulationUploadPannel);