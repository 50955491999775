import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { push } from 'connected-react-router';
import { getCompanyId } from "../../utils/userUtils";
import * as _ from 'lodash';
import { getMoment } from '../../utils/datetime';
import { getTreePartById } from '../../utils/constant';

const defaultState = {
    planList: {
        total: 0,
        list: []
    },
    search: {
        companyId: "",
        inspectionType: 'OIT04',
        selectedDate: getMoment()(),
        selecteDay: getMoment()(),
    },
    routeList: [],
    departmentList: [],
    currentPlan: {},
    planTableOpen: false,
    planPanelOpen: false,
    leaders: [],
    planError: {},
};

export const getAllLeaders = createAction('@@xcloud/onsiteInspectionSchedule/getAllLeaders',
    (companyId, callback) => (dispatch, getState, httpClient) => {
        return httpClient.get('/api/users/leaders')
            .then((res) => {
                if (res) {
                    return res.data
                }
            }).catch(err => {
                let msg = `获取所有领导层失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getDepartment = createAction('@@xcloud/onsiteInspectionSchedule/getDepartment',
    () => (dispatch, getState, httpClient) => {
        let url = `/api/departments?offset=0&size=0&status=active`;
        return httpClient
            .get(url)
            .then((res) => {
                return res.data.list
            })
            .catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '获取部门信息失败'));
                return []
            });
    });


export const getRouteList = createAction('@@xcloud/onsiteInspectionSchedule/getRouteList',
    () => (dispatch, getState, httpClient) => {
        let search = getState().onsiteInspectionSchedule.search;
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        url += "&inspectionType=" + search.inspectionType

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data.list;
                } else {
                    return []
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);


export const getPlanList = createAction('@@xcloud/onsiteInspectionSchedule/getPlanList',
    (companyId) => (dispatch, getState, httpClient) => {

        let search = getState().onsiteInspectionSchedule.search;
        companyId = search.companyId ? search.companyId : companyId
        let date = _.cloneDeep(search.selectedDate)
        let fromDate = date.startOf('month').valueOf();
        let toDate = date.add(1, 'months').startOf('month').valueOf();

        let url = '/api/onsiteinspections/plans';

        url += "?offset=0"

        url += "&size=0"

        url += "&sort=update_time"

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        url += "&inspectionType=" + search.inspectionType

        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + fromDate
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + toDate
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '管理巡检计划获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const deletePlan = createAction('@@xcloud/onsiteInspectionSchedule/deletePlan',
    (data) => (dispatch, getState, httpClient) => {
        let dto = {
            planId: data.planId,
            status: "inactive",
            tenantId: data.tenantId
        }
        let url = `/api/onsiteinspections/plans/${data.planId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(getPlanList())
                    dispatch(createMessage('success', '删除管理巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '删除管理巡检计划失败'));
                return [];
            });
    }
);

export const updatePlan = createAction('@@xcloud/onsiteInspectionSchedule/deletePlan',
    (data) => (dispatch, getState, httpClient) => {

        let dto = {
            planId: data.planId,
            tenantId: data.tenantId,
            dptId: data.dptId,
            inspector: data.inspector,
            routeId: data.routeId,
            startTime: new Date(data.startTime),
        }
        let url = `/api/onsiteinspections/plans/${data.planId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(getPlanList())
                    dispatch(setPlanPanelOpen(false))
                    dispatch(createMessage('success', '更新管理巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新管理巡检计划失败'));
                return [];
            });
    }
);

export const createPlan = createAction('@@xcloud/onsiteInspectionSchedule/deletePlan',
    (data) => (dispatch, getState, httpClient) => {
        let search = getState().onsiteInspectionSchedule.search;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let dto = {
            planId: 0,
            dptId: data.dptId,
            inspectionType: search.inspectionType,
            inspector: data.inspector,
            routeId: data.routeId,
            companyId: companyId,
            startTime: new Date(data.startTime),
        }
        let url = `/api/onsiteinspections/plans`
        return httpClient
            .post(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 201) {
                    dispatch(getPlanList())
                    dispatch(setPlanPanelOpen(false))
                    dispatch(createMessage('success', '创建管理巡检计划成功'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '创建管理巡检计划失败'));
                return [];
            });
    }
);

/**
 * 初始化路线列表页面
 */
export const initSchedulePage = createAction('@@xcloud/onsiteInspectionSchedule/initSchedulePage',
    () => (dispatch, getState) => {
        Promise.all([
            //初始化页面头部
            dispatch(getAllLeaders()),
            dispatch(resetSearch()),
            dispatch(getDepartment()),
            dispatch(getRouteList()),
            dispatch(setPlanTableOpen(false)),
            dispatch(setPlanPanelOpen(false)),
        ]).then(() => {
            let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            dispatch(getPlanList(companyId))

        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化管理巡检计划失败'));
            return [];
        });
    });

export const resetSearch = createAction('@@xcloud/onsiteInspectionPlan/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompantId = null
        let companylist = getState().constant.companies;
        if (companylist && companylist.length > 0) {
            let selectedCompany = _.find(companylist, ['companyId', company])
            if (selectedCompany) {
                newCompantId = selectedCompany.companyId
            }
        }

        let search = getState().deviceUseStatus.search;
        let newSearch = {
            ...search,
            companyId: newCompantId ? newCompantId : "",
            inspectionType: 'OIT04',
            selectedDate: getMoment()(),
            selecteDay: getMoment()(),
        }

        dispatch(setSearch(newSearch));
    });

export const setSearch = createAction('@@xcloud/onsiteInspectionSchedule/setSearch');

export const setCurrentPlan = createAction('@@xcloud/onsiteInspectionSchedule/setCurrentPlan');

export const setPlanTableOpen = createAction('@@xcloud/onsiteInspectionSchedule/setPlanTableOpen');

export const setPlanPanelOpen = createAction('@@xcloud/onsiteInspectionSchedule/setPlanPanelOpen');

export const setPlanError = createAction('@@xcloud/onsiteInspectionPlan/setPlanError');

export const initView = createAction('@@xcloud/onsiteInspectionSchedule/initView',
    (data) => (dispatch, getState) => {
        let plans = getState().onsiteInspectionSchedule.planList;
        let plan = _.find(plans.list, ['planId', data.planId])
        dispatch(setCurrentPlan(plan));
        dispatch(push('/qc/record/' + plan.planId));
    }
);

export const backToList = createAction('@@xcloud/onsiteInspectionSchedule/backToList',
    (id) => (dispatch, getState) => {
        dispatch(push('/qc/plan'));
    }
);

/********************* onsiteInspectionSchedule Reducer *********************/

export const planListReducer = handleActions(
    {
        [getPlanList]: (state, { payload: value }) => {
            return {
                ...state,
                planList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setCurrentPlan]: (state, { payload: value }) => {
            return {
                ...state,
                currentPlan: value
            };
        },
        [setPlanTableOpen]: (state, { payload: value }) => {
            return {
                ...state,
                planTableOpen: value
            };
        },
        [setPlanPanelOpen]: (state, { payload: value }) => {
            return {
                ...state,
                planPanelOpen: value
            };
        },
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getDepartment]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
        [getAllLeaders]: (state, { payload: value }) => {
            return {
                ...state,
                leaders: value
            };
        },
        [setPlanError]: (state, { payload: value }) => {
            return {
                ...state,
                planError: value
            };
        },
    },
    defaultState
);

export default planListReducer;