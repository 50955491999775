import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ServiceBudget from '@/components/AmoebaManagement/ServiceBudget/ServiceBudgetComponent'
import ServiceHistory from '@/components/AmoebaManagement/ServiceBudget/BudgetHistory'
import { getServiceBudgets, updateServiceBudget, getServiceBudgetHistories } from '@/redux/modules/amoeba/serviceBudget';
import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { judgeMainCompany } from '@/utils/manufactureUtils'

/**
 * 服务预算
 */
class ServiceBudgetContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            companyId: this.props.companyId,
            subCompanyList: [],
            isMainCompany: judgeMainCompany(this.props.companyList, this.props.companyId),
        }
    }

    componentDidMount() {
        const { getServiceBudgets, companyId, companyList, getBusinessGroupList } = this.props;

        const isMainCompany = judgeMainCompany(companyList, companyId);
        const subCompanyList = companyList.filter(ele => {
            return isMainCompany ? ele.pid === companyId : ele.companyId === companyId
        });
        const selectCmpanyId = subCompanyList[0].companyId;
        getServiceBudgets(selectCmpanyId);
        // 取得指标列表
        getBusinessGroupList();
        this.setState({
            isMainCompany: isMainCompany,
            subCompanyList: subCompanyList,
            companyId: selectCmpanyId
        })
    }

    handleCompanyChange = (event) => {
        const { getServiceBudgets } = this.props;
        const companyId = event.target.value;
        const year = this.state.year;
        getServiceBudgets(companyId, year);

        this.setState({
            companyId: companyId
        })
    }

    handleRowUpdate = (newData, oldData) => {
        const { handleRowUpdate, getServiceBudgets } = this.props;
        const { companyId, year } = this.state;
        let updateDto = newData;
        updateDto.budgetId = oldData.budgetId;
        updateDto.cate = 'SERVICE_PRICE';
        return handleRowUpdate(updateDto,
            () => getServiceBudgets(companyId, year)
        );
    }

    handleShowHistory = (event, rowData) => {
        this.props.getServiceBudgetHistories(rowData.serviceId);
        this.setState({
            serviceName: rowData.serviceName,
            serviceUnit: rowData.unit,
            openFlag: true,
        })
    }

    handleClose = () => {
        this.setState({
            openFlag: false,
        })
    }

    render() {
        return (
            <div>
                <ServiceBudget
                    serviceBudgets={this.props.serviceBudgets}
                    businessGroupList={this.props.businessGroupList}
                    companyId={this.state.companyId}
                    companyList={this.state.subCompanyList}
                    isMainCompany={this.state.isMainCompany}
                    manufactureDicts={this.props.manufactureDicts}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleRowUpdate={this.handleRowUpdate.bind(this)}
                    onhandleShowHistory={this.handleShowHistory.bind(this)}
                />
                {this.state.openFlag &&
                    <ServiceHistory
                        open={this.state.openFlag}
                        serviceName={this.state.serviceName}
                        serviceUnit={this.state.serviceUnit}
                        manufactureDicts={this.props.manufactureDicts}
                        serviceHistories={this.props.budgetHistories}
                        onhandleClose={this.handleClose.bind(this)}
                    />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyId: state.auth.currentUserInfo.companyId,
        companyList: state.company.companyList.list,
        manufactureDicts: state.constant.manufactureDicts,
        serviceBudgets: state.serviceBudget.serviceBudgets,
        businessGroupList: state.businessGroup.businessGroupList,
        budgetHistories: state.serviceBudget.budgetHistories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceBudgets: (companyId) => {
            return dispatch(getServiceBudgets(null, companyId, null, 1))
        },
        getServiceBudgetHistories: (serviceId) => {
            return dispatch(getServiceBudgetHistories(serviceId,null,'SERVICE_PRICE'))
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
        handleRowUpdate: (updateDto, callBack) => {
            return dispatch(updateServiceBudget(updateDto, callBack))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceBudgetContainer);
