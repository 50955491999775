import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    root: {
        backgroundColor: '#FFFFFFFF',
    },
    action: {
        padding: 16,
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
    contentGrid:{
        flexDirection:'column'
    },
    itemGrid:{
        paddingBottom: theme.spacing(2),
    }

});

export default withStyles(styles);