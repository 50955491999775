import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    detailTitle: {
        paddingTop: 24,
        paddingLeft: 16,
        alignItems: 'center'
    },
    action: {
        padding: 16,
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
    selectedItem: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    container: {
        padding: '8px',
        backgroundColor: 'white',
    },
    toolbarContainer: {
        paddingLeft: theme.spacing(2)
    },
    companySelect: {
        paddingTop: theme.spacing(2),
    }
});

export default withStyles(styles);