import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import { stripedTableStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    stripedTable: stripedTableStyle,
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    action: {
        padding: 16,
    },
    title: {
        padding: theme.spacing(2),
    },
    switch: {
        marginBottom: 0,
        marginLeft: 0
    },
    button: {
        padding: 8,
    },
    flexgrow: {
        flexGrow: 1
    },
    root: {
        padding: theme.spacing(0)
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridSubItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: 'calc(100% - 60px)',
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    connectLabel: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        marginLeft: theme.spacing(1),
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    tableTextFieldInput: {
        marginTop: 0,
        marginBottom: 0,
        width: '100%'
    },
    textFieldGroupItem: {
        marginLeft: theme.spacing(1),
        width: '100%',
    },
    pic1: {
        width: 20,
        height: 20,
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: 20,
        height: 20,
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    menuroot: {
        '& .MuiMenu-paper': {
            border: '1px solid #d3d4d5',
        },
    },
    menuIcon: {
        marginRight: 5,
    },
    tabs: {
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.contrastText,
            },
            backgroundColor: theme.palette.primary.main
        }
    },
    new: {
        width: 60,
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    active: {
        width: 60,
        backgroundColor: colors.green[400],
        color: theme.palette.white,
    },
    finalized: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    done: {
        width: 60,
        backgroundColor: colors.blue[400],
        color: theme.palette.white,
    },
    default: {
        width: 60,
        backgroundColor: colors.grey[300],
        color: theme.palette.white,
    },
    interval: {
        textAlign: 'center'
    },
    actionCell: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
    },
    menuItemCritical: {
        color: theme.palette.error.main
    },
});

export default withStyles(styles);