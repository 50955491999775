import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import withStyles from './styles';
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoadingPage(props) {
  const { classes, open } = props;

  return (
    <Modal
      aria-labelledby="adduser-modal-title"
      aria-describedby="adduser-modal-description"
      open={open}
      className={classes.modal}
    >
      <div className={classes.globalpaper}>
        <Grid container spacing={0} alignItems="center" wrap="nowrap">
          <CircularProgress size='6rem'/>
        </Grid>
      </div>
    </Modal>
  );
}

LoadingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool
};

export default withStyles(LoadingPage);
