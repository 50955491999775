import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import InstallActionListComponent from '@/components/ManufactureManagement/InstallAction/InstallActionList';
import CreateInstallActionComponent from '@/components/ManufactureManagement/InstallAction/CreateInstallAction';
import {
    getManipulates,
    getManipulate,
    addManipulate,
    updateManipulate,
    deleteManipulate,
    getInstallationsInManipulate,
    getUnitDeviceList,
    getUsersByCompanyId
} from '@/redux/modules/manipulate';
import * as _ from 'lodash';

class InstallActionContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            createOpen: false,
            selectedAttributeIndex: -1,
            manipulate: {
                manipulateName: '',
                installId: null,
                unitId: null,
                deviceId: null,
                deviceName: '',
                owners: [],
                constantError: {}
            },
            selectType: 'device'
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const { userInfo, } = this.props;
        this.props.getInstallationsInManipulate(userInfo.companyId);
        this.handleGetManipulates();
        this.props.getUsersByCompanyId({
            company: userInfo.companyId,
            roleLevel: 2,
            size: 0
        });

    }

    handleUpdate = (event, rowData) => {
        return this.props.getManipulate(rowData.manipulateId).then((res) => {
            if (res) {
                this.setState({
                    manipulate: res.payload.data,
                    selectType: res.payload.data['deviceId'] ? 'device' : 'install',
                    createOpen: true, componentTitle: "编辑装置操作"
                });
            } else {
                this.setState({ createOpen: true, componentTitle: "编辑装置操作" });
            }

        });
    }

    handleGetManipulates = () => {
        const { onhandleGetManipulates, userInfo, companies } = this.props
        const myCompany = _.find(companies, { 'companyId': userInfo.companyId })
        if (myCompany.pid) {
            onhandleGetManipulates(userInfo.companyId)
        } else {
            onhandleGetManipulates()
        }
    }

    handleDelete = (rowdata) => {
        return this.props.deleteManipulate(rowdata.manipulateId).then((res) => {
            if (res) {
                this.handleGetManipulates();
            }
        });
    }

    handleAdd = () => {
        this.setState({ createOpen: true, componentTitle: "新增装置操作" })
    }

    handleCloseCreate = () => {
        this.setState({ createOpen: false });
        this.setState({
            manipulate: {
                manipulateName: '',
                installId: null,
                unitId: null,
                deviceId: null,
                deviceName: '',
                owners: []
            }, constantError: {}
        });
    }

    handleSave = (event) => {
        const newmanipulate = {};
        const manipulate = this.state.manipulate;

        if (manipulate.manipulateName) {
            newmanipulate.manipulateName = manipulate.manipulateName;
        }

        if (manipulate.unitId) {
            newmanipulate.unitId = manipulate.unitId;
        }
        if (this.state.selectType === 'device') {
            if (manipulate.installId) {
                newmanipulate.installId = manipulate.installId;
            }
            if (manipulate.deviceId) {
                newmanipulate.deviceId = manipulate.deviceId;
            } else {
                this.setState({
                    constantError: {
                        deviceErr: "设备必须选一个"
                    }
                })
                return
            }
        } else {
            if (manipulate.installId) {
                newmanipulate.installId = manipulate.installId;
            } else {
                this.setState({
                    constantError: {
                        installErr: "装置必须选一个"
                    }
                })
                return
            }
        }
        if (manipulate.manipulateId) {
            newmanipulate.manipulateId = manipulate.manipulateId;
        }
        if (manipulate.owners != null) {
            const owners = [];
            for (let dex in manipulate.owners) {
                owners.push(manipulate.owners[dex].userId);
            }
            newmanipulate.owners = owners;
        }
        if (manipulate.actions != null) {
            const actions = [];
            for (let de in manipulate.actions) {
                const action = { actionName: manipulate.actions[de].actionName, actionId: manipulate.actions[de].actionId }
                actions.push(action);
            }
            newmanipulate.actions = actions;
        }

        if (manipulate && manipulate.manipulateId) {
            this.props.updateManipulate(manipulate.manipulateId, newmanipulate).then((data) => {
                this.handleCloseCreate();
                this.handleGetManipulates();
            });
        } else {
            this.props.addManipulate(newmanipulate).then((data) => {
                this.handleCloseCreate();
                this.handleGetManipulates();
            });
        }

    }

    handleTextFieldChange = (event) => {
        const manipulate = this.state.manipulate;
        if (event.target.name === "manipulateName") {
            manipulate.manipulateName = event.target.value;
        }
        this.setState({ manipulate: manipulate });
    }

    handleChangeUser = (event, users) => {
        let manipulate = this.state.manipulate;
        manipulate.owners = users;
        this.setState({ manipulate: manipulate });
    }

    handleDelActionRow = (oldData) => {
        let list = this.state.manipulate.actions;
        if (!list) {
            list = [];
        }
        let manipulate = this.state.manipulate;
        list.splice(oldData.tableData.id, 1);
        manipulate.actions = list;
        this.setState({
            manipulate: manipulate
        });
    }

    handleAddActionRow = (newData) => {
        let list = this.state.manipulate.actions;
        if (!list) {
            list = [];
        }
        let manipulate = this.state.manipulate;
        list.push(newData);
        manipulate.actions = list;
        this.setState({
            manipulate: manipulate
        });
    }

    handleUpdateActionRow = (newData, oldData) => {
        let list = this.state.manipulate.actions;
        list[oldData.tableData.id] = newData;
        let manipulate = this.state.manipulate;
        manipulate.actions = list;
        this.setState({
            manipulate: manipulate
        });
    }


    handleSelectMaster = (event) => {

        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.props.userInfo.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        const manipulate = this.state.manipulate;
                        if (this.props.selectedDevices[0].deviceGroup) {
                            manipulate.installId = this.props.selectedDevices[0].deviceGroup.installId
                        }
                        if (this.props.selectedDevices[0].unitId) {
                            manipulate.unitId = this.props.selectedDevices[0].unitId
                        }
                        manipulate.deviceId = this.props.selectedDevices[0].deviceId
                        manipulate.deviceName = this.props.selectedDevices[0].deviceName
                        this.setState({ manipulate: manipulate });

                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);

    }

    handleSelectChange = (event, field) => {
        if (field === 'selectType') {
            const manipulate = this.state.manipulate;
            manipulate.installId = null;
            manipulate.unitId = null;
            manipulate.deviceId = null;
            manipulate.deviceName = '';
            this.setState({
                selectType: event.target.value,
                manipulate: manipulate
            })
        } else if (field === 'install') {
            const manipulate = this.state.manipulate;
            manipulate.installId = event.target.value;
            this.setState({ manipulate: manipulate });
        }
    }

    render() {
        return (
            <div>
                <InstallActionListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    constant={this.props.constant}
                    manipulates={this.props.manipulates}
                />
                <CreateInstallActionComponent
                    open={this.state.createOpen}
                    users={this.props.users}
                    componentTitle={this.state.componentTitle}
                    onhandleSelectMaster={this.handleSelectMaster.bind(this)}
                    constant={this.props.constant}
                    constantError={this.state.constantError}
                    installationList={this.props.installationList}
                    selectedAttributeIndex={this.state.selectedAttributeIndex}
                    manipulate={this.state.manipulate}
                    selectType={this.state.selectType}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleTextFieldChange={this.handleTextFieldChange.bind(this)}
                    onhandleChangeUser={this.handleChangeUser.bind(this)}
                    onhandleDelActionRow={this.handleDelActionRow.bind(this)}
                    onhandleAddActionRow={this.handleAddActionRow.bind(this)}
                    onhandleUpdateActionRow={this.handleUpdateActionRow.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        manipulates: state.manipulate.manipulates,
        users: state.manipulate.users,
        userInfo: state.auth.currentUserInfo,
        constant: state.constant,
        companies: state.company.companyList.list,
        selectedDevices: state.deviceSelect.selectedDevices,
        installationList: state.installation.installationList.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetManipulates: (companyId, installId, unitId, deviceId, manipulateName, status) => {
            dispatch(getManipulates(companyId, installId, unitId, deviceId, manipulateName, status))
        },
        getManipulate: (manipulateId) => {
            return dispatch(getManipulate(manipulateId))
        },
        addManipulate: (manipulate) => {
            return dispatch(addManipulate(manipulate));
        },
        updateManipulate: (manipulateId, manipulate) => {
            return dispatch(updateManipulate(manipulateId, manipulate));
        },
        deleteManipulate: (manipulateId) => {
            return dispatch(deleteManipulate(manipulateId));
        },
        getInstallationsInManipulate: (companyId) => {
            return dispatch(getInstallationsInManipulate(companyId));
        },
        getUnitDeviceList: (unitId) => {
            return dispatch(getUnitDeviceList(unitId));
        },
        getUsersByCompanyId: (query) => {
            return dispatch(getUsersByCompanyId(query));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallActionContainer);
