import React from 'react';
import {
    Dialog,
    Typography,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';

const ConfirmChildCheck = props => {
    const { classes,
        open,
        subList,
        addParentId,
        onhandleChange,
        onhandleConfirm,
    } = props;

    const textFieldDefault = {
        style: {
            width: '180px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    return (
        <Dialog
            open={open}
            maxWidth={'md'}
        >
            <DialogTitle>
                <Typography component={'span'} variant='h3'>
                    {'指标确认'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'添加的指标是否为子指标？是的话请选择父指标'}
                </DialogContentText>
                <Grid className={classes.itemGrid}>
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        label='父级指标'
                        InputProps={textFieldDefault}
                        onChange={onhandleChange}
                        value={addParentId}
                    >
                        {subList.map(e => (
                            <MenuItem key={e.id} value={e.id.substr(1)}>{e.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    className={classes.confirmButton}
                    onClick={() => onhandleConfirm('yes')}
                >是</Button>
                <Button
                    color="primary"
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onhandleConfirm('no')}
                    autoFocus
                >否</Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmChildCheck.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    subList: PropTypes.array,
    addParentId: PropTypes.number,
    onhandleChange: PropTypes.func,
    onhandleConfirm: PropTypes.func,
};

ConfirmChildCheck.defaultProps = {

};

export default withStyles(ConfirmChildCheck);