import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization } from "../../../../utils/mtable";
import _ from 'lodash';

const OppoList = props => {
    const {
        classes,
        oppolist,
        handleGetList,
        statusList,
        onSizeChange,
        pageSize,
        onDelete,
        onView,
    } = props;

    const [page, setPage] = useState(0);

    const renderUseStatus = (oppo) => {
        let label = ''
        if (statusList && statusList.length > 0) {
            let value = _.find(statusList, function (o) { return o.seq === Number.parseInt(oppo.status) })
            if (value) {
                label = value.name
            }
        }
        return label
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '机会名称',
                field: 'oppoName',
                render: row => row ? <Typography>{row.oppoName}</Typography> : '',
                editable: 'never',
                width: '15%'
            },
            {
                title: '发现人',
                field: 'developerName',
                render: row => row ? <Typography>{row.developerName}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            {
                title: '客户',
                field: 'cname',
                render: row => row ? <Typography>{row.cname}</Typography> : '',
                editable: 'never',
                width: '15%'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{renderUseStatus(row)}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            {
                title: '预期金额(元)',
                field: 'expectedAmount',
                render: row => row ? <Typography>{row.expectedAmount}</Typography> : '',
                editable: 'never',
                align: 'right',
                width: '10%'
            },
            {
                title: '可能性(%)',
                field: 'possibility',
                render: row => row ? <Typography>{row.possibility}</Typography> : '',
                editable: 'never',
                align: 'right',
                width: '10%'
            }
        ]

        return columns
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={oppolist.list}
                    options={{
                        pageSize: _.toInteger(pageSize),
                        tableLayout: 'auto',
                        actionsColumnIndex: -1,
                        search: false,
                        defaultExpanded: true,
                        toolbar: false,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}

                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[5, 10, 20]}
                                rowsPerPage={_.toInteger(pageSize)}
                                count={oppolist.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    handleGetList({ offset: pageNum * pageSize, size: pageSize, sort: "-update_time" })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    handleGetList({ offset: 0, size: event.target.value, sort: "-update_time" })
                                }}

                            />
                        )
                    }}
                    actions={[
                        rowData => ({
                            icon: 'description',
                            tooltip: '查看',
                            onClick: (event, rowData) => {
                                onView(event, rowData.oid)
                            },
                        }),
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onDelete(event, rowData)
                            },
                            disabled: rowData.status === 'inactive'
                        }),
                    ]}
                >
                </MaterialTable>
            </Grid>
        </div >
    );
};

OppoList.propTypes = {
    classes: PropTypes.object.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    handleGetList: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default withStyles(OppoList);
