import React, { Component } from 'react';
import { connect } from 'react-redux';
import RoleGroupInfo from '../../../components/SystemManagement/RoleManagement/RoleGroupInfo'
import {
    createRoleGroup,
    updateRoleGroup,
    setCurrentRoleGroup,
    backToRoleList,
    setRoleGroupError,
    setRoleGroupChange
} from "../../../redux/modules/role";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class RoleGroupEditContainer extends Component {

    static propTypes = {
        currentRoleGroup: PropTypes.object,
        roleGroupError: PropTypes.object,
        roleGroupChange: PropTypes.bool
    };

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.roleGroupError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleRoleGroupChange(false);
            if (this.props.currentRoleGroup.groupId) {
                this.props.onhandleUpdateRoleGroup();
            } else {
                this.props.onhandleCreateRoleGroup();
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleRoleGroupChange(true);
        let newRoleGroup;
        switch (field) {
            case 'groupName':
                newRoleGroup = {
                    ...this.props.currentRoleGroup,
                    groupName: event.target.value
                };
                this.props.onhandleUpdateEntity(newRoleGroup);
                break;
            case 'opentext':
                newRoleGroup = {
                    ...this.props.currentRoleGroup,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEntity(newRoleGroup);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToRoleList();
    };

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'groupName') {
            if (_.trim(this.props.currentRoleGroup.groupName) === '') {
                err['groupName'] = '用户组名称不能为空';
            } else {
                err['groupName'] = '';
            }
        }
        let newError = {
            ...this.props.roleGroupError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <RoleGroupInfo
                onhandleSave={this.handleSave.bind(this)}
                currentRoleGroup={this.props.currentRoleGroup}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                roleGroupError={this.props.roleGroupError}
                roleGroupChange={this.props.roleGroupChange}
                companySelection={this.props.companySelection}
                roleGroupList={this.props.roleGroupList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentRoleGroup: state.role.currentRoleGroup,
        roleGroupError: state.role.roleGroupError,
        roleGroupChange: state.role.roleGroupChange,
        companySelection: state.role.companySelection,
        roleGroupList: state.role.roleGroupList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateRoleGroup: () => {
            dispatch(createRoleGroup())
        },
        onhandleUpdateRoleGroup: () => {
            dispatch(updateRoleGroup())
        },
        onhandleUpdateEntity: (entity) => {
            dispatch(setCurrentRoleGroup(entity))
        },
        onhandleBackToRoleList: () => {
            dispatch(backToRoleList())
        },
        onhandleSetError: (err) => {
            dispatch(setRoleGroupError(err))
        },
        onhandleRoleGroupChange: (isChanged) => {
            dispatch(setRoleGroupChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleGroupEditContainer)
