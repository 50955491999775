import React from 'react';
import {
    Grid, Typography, TextField, MenuItem
} from '@material-ui/core';
import withStyles from '@/components/SystemManagement/LabourPayment/styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash'

const LabourPaymentComponent = props => {
    const { classes,
        selectedDate,
        records,
        companyList,
        departments,
        onhandleChange,
        disableCompany,
        userName,
        onhandleCreate,
        onhandleUpdate,
        onhandleDelete,
        onhandleUpload,
        userProfile,
        companyId,
        onhandleSearch,
        onhandleClearSearch,
        onhandleSelectUser,
        exportCsv,
        paymentTargets,
        onhadleMassgae
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbarButtonAlignment: 'left',
        tableLayout: 'fixed'
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'labour_payment' })
        return permission && permission.action === 'W'
    }

    const renderCompany = (row) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === row.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDpt = (row) => {
        let dptName = ''
        if (departments && departments.length > 0) {
            let value = _.find(departments, function (o) { return o.dptId === row.dptId })
            if (value) {
                dptName = value.dptName
            }
        }
        return dptName
    }

    const renderUserList = () => {
        if (disableCompany) {
            return paymentTargets.map(ele => (
                <MenuItem key={ele.userId} value={ele.userId}>{ele.lastName + ele.firstName}</MenuItem>
            ))
        } else {
            let targets = _.filter(paymentTargets, (o) => { return o.companyId === companyId })
            return targets.map(ele => (
                <MenuItem key={ele.userId} value={ele.userId}>{ele.lastName + ele.firstName}</MenuItem>
            ))
        }
    }

    const renderActPaid = (row) =>{
        let res = 0;

        if (row.item1){
            res += row.item1;
        }

        if (row.item2){
            res += row.item2;
        }

        if (row.item3){
            res += row.item3;
        }

        if (row.item4){
            res += row.item4;
        }

        return res.toFixed(2)

    } 

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单位',
                field: 'companyName',
                editable: 'never',
                width: 100,
                render: row => <Typography>{renderCompany(row)}</Typography>
            }, {
                title: '班组',
                field: 'dptName',
                editable: 'never',
                width: 150,
                render: row => <Typography>{renderDpt(row)}</Typography>
            },
            {
                title: '姓名',
                field: 'userId',
                width: 100,
                render: row => <Typography>{row.userName}</Typography>,
                editComponent: (props) => (
                    <TextField
                        fullWidth
                        select
                        name='userId'
                        onChange={e => props.onChange(e.target.value)}
                        value={props.rowData.userId}
                    >
                        {renderUserList()}
                    </TextField>
                )
            },
            {
                title: '年',
                field: 'paidYear',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{row.paidYear}</Typography>,
                validate: rowData => (rowData.paidYear < 2000 || rowData.paidYear > 2100) ? { isValid: false, helperText: '年范围为2000-2100' } : true,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"   
                        defaultValue={selectedDate.year()}
                        value={props.rowData.paidYear}
                        name='paidYear'
                        onChange={e => props.onChange(e.target.value.replace('.',''))}>
                    </TextField>
                )
            },
            {
                title: '月',
                field: 'paidMonth',
                width: 80,
                type: 'numeric',
                render: row => <Typography>{row.paidMonth}</Typography>,
                validate: rowData => (rowData.paidMonth < 1 || rowData.paidMonth > 12) ? { isValid: false, helperText: '月范围为1-12' } : true,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        defaultValue={selectedDate.month()+1}
                        value={props.rowData.paidMonth}
                        name='paidMonth'
                        onChange={e => props.onChange(e.target.value.replace('.',''))}>
                    </TextField>
                )
            },
            {
                title: '日',
                field: 'paidDay',
                width: 80,
                type: 'numeric',
                render: row => <Typography>{row.paidDay}</Typography>,
                validate: rowData => (rowData.paidDay < 1 || rowData.paidDay > 31) ? { isValid: false, helperText: '日范围为1-31' } : true,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        defaultValue={selectedDate.day()}
                        value={props.rowData.paidDay}
                        name='paidDay'
                        onChange={e => props.onChange(e.target.value.replace('.',''))}>
                    </TextField>
                )
            },
            {
                title: '日工资',
                field: 'item1',
                width: 120,
                type: 'numeric',
                validate: rowData => (!rowData.item1 || rowData.item1 === 0) ? { isValid: false, helperText: '日工资不能为0' } : true,
                render: row => <Typography>{row.item1.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        value={props.rowData.item1 ? props.rowData.item1 : 0}
                        name='item1'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '保险费用',
                field: 'item2',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item2 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item2 ? props.rowData.item2 : 0}
                        name='item2'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '餐补',
                field: 'item3',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item3 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item3 ? props.rowData.item3 : 0}
                        name='item3'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '管理费用',
                field: 'item4',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item4 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item4 ? props.rowData.item4 : 0}
                        name='item4'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '应付金额',
                field: 'actPaid',
                width: 120,
                type: 'numeric',
                editable: 'never',
                render: row => <Typography>{renderActPaid(row)}</Typography>
            }

        ]

        return columns

    }

    const assemblyData = () => {
        return records;
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>、

                <MaterialTable
                className={classes.materialtable}
                columns={assemblycolumns()}
                data={assemblyData()}
                options={tableOptions}
                localization={tableLocalization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                title={''}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h3">
                                劳务人员工资记录
                                    </Typography>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        单位:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='companyId'
                                        InputProps={{ readOnly: disableCompany }}
                                        onChange={event => onhandleChange(event, 'company')}
                                        value={companyId}
                                    >
                                        {companyList.map(ele => (
                                            <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        工资月份:
                                 </Typography>
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={classes.gridSelect}
                                            views={['year', 'month']}
                                            variant="inline"
                                            autoOk
                                            openTo={'month'}
                                            format="yyyy/MM"
                                            margin="none"
                                            name='yearMonth'
                                            InputProps={{ readOnly: true }}
                                            value={selectedDate || moment()()}
                                            onChange={(event) => onhandleChange(event, 'yearMonth')}
                                            KeyboardButtonProps={{
                                                'aria-label': '工资月份',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        劳务人员:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onClick={event => onhandleSelectUser(event)}
                                        value={userName || ''}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                actions={[
                    {
                        icon: 'search',
                        tooltip: '搜索记录',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleSearch()
                        }
                    },
                    {
                        icon: 'clear_all',
                        tooltip: '清空搜索条件',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleClearSearch()
                        }
                    },
                    {
                        icon: 'cloud_download',
                        tooltip: '下载工资记录模板',
                        hidden: !checkPermission(),
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            exportCsv()
                        }
                    },
                    {
                        icon: 'backup',
                        tooltip: '导入工资记录',
                        hidden: !checkPermission(),
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleUpload()
                        }
                    }
                ]}
                editable={checkPermission() ?
                    {
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                if (_.find(records,(o)=>{return o.paidDay === newData.paidDay && o.userId === newData.userId})){
                                    onhadleMassgae('同一天内不能包含多条同一人的工资记录');
                                    reject();
                                }else{
                                    resolve()
                                    onhandleCreate(newData)
                                }
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                if (_.find(records,(o)=>{return o.paidDay === newData.paidDay && o.userId === newData.userId && o.payId !== newData.payId})){
                                    onhadleMassgae('同一天内不能包含多条同一人的工资记录');
                                    reject();
                                }else{
                                    resolve()
                                    onhandleUpdate(newData)
                                }
                            }),
                        onRowDelete: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleDelete(newData)
                            })
                    } : {}
                }
            >
            </MaterialTable>

        </div >
    )
}

LabourPaymentComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

LabourPaymentComponent.defaultProps = {
    paymentRecordList: []
};

export default withStyles(LabourPaymentComponent);