import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@material-ui/core';

const ApprovePannel = ({
    classes,
    open,
    title,
    comment,
    onhandleClose,
    onhandleConfirm,
    onhandleChangeComment,
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">确认对话框</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>{title}</DialogContentText>
                    <TextField className={classes.textfield} multiline
                        label="建议"
                        rows="4"
                        value={comment || ''}
                        name='opentext'
                        onChange={onhandleChangeComment}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>
                        确定</Button>
                    <Button variant="contained" color="primary" onClick={onhandleClose}>
                        关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
ApprovePannel.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onhandleConfirm: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleChangeComment: PropTypes.func.isRequired,
};

ApprovePannel.defaultProps = {
    config: {},
}

export default (withStyles(ApprovePannel));