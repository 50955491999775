import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TemporaryRegulationPlanExecution from "../../../../components/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanExecution";
import {
    getPlan,
    //getPlanDetailsP
} from "../../../../redux/modules/leadershipRegulationPlan";
import { getBusinessUnitsByRole, getDepartmentsByRole } from "../../../../utils/userUtils";



// import * as _ from 'lodash';
class TemporaryRegulationPlanExecutionContainer extends Component {
    static propTypes = {
        plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        userProfile: PropTypes.object,
        onhandleOpenPlanDetails: PropTypes.func,
        //onhandleProcess: PropTypes.func,
    };

    componentDidMount() {
        /*const { 
            onhandleGetPlan,
            planDetails
        } = this.props
        onhandleGetPlan(planDetails.planId)*/
        /*const { 
            onhandleGetPlanDetails,
            //plan
        } = this.props
        //onhandleGetPlanDetails(plan.planId)
        onhandleGetPlanDetails('Drafting', '1', '', (data) => {
            console.log(data)
        })*/
    }

    render() {
        return (
            <div>
                <TemporaryRegulationPlanExecution
                    plan={this.props.plan}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    departments={this.props.departments}
                    currentPlanDetails={this.props.planDetails}
                    regulations={this.props.regulations}
                />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.currentTrp,
        planDetails: state.leadershipRegulationPlan.planDetails,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        regulations: state.regulation.regulations,
        //currentProcessStatus: state.process.currentProcessStatus.RegulationPlan || {},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetPlan: (planId) => {
            dispatch(getPlan(planId))
        },
        /*onhandleGetPlanDetails: (status,type,planId,callback) => {
            dispatch(getPlanDetailsP(status,type,planId,callback))
        }*/
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemporaryRegulationPlanExecutionContainer)