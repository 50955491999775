import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { formSelectTree } from '../../utils/constant';
import { getDeviceCategoryTree } from './trees';
import { getInstallations, getInstallation } from './installation';
import { getCompanyId } from "../../utils/userUtils";
import { removeObject } from './minio';
import { download } from '@/redux/modules/minio';
import { getDateByTimestamp, getMoment } from '../../utils/datetime';
import { push } from 'connected-react-router';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentProcess, getCurrentProcessStatus } from "./process";
import { getDeviceAccessorylist } from "./accessory";
import { getDeviceOpsRecordList } from "./deviceOpsRecord"
import { resetGoingDeviceForm } from "./deviceForm"
// import { formatDate_hms } from '../../utils/datetime';

const porxyApi = '/api'

const initialState = {
    isLoading: false,
    devicelist: {
        total: 0,
        list: []
    },
    searchDevicelist: [],
    useStatus: [],
    useStatusTree: [],
    mediumTree: [],
    medium: [],
    unit: [],
    unitTree: [],
    erpsrc: [],
    erpsrcTree: [],
    erpunit: [],
    erpunitTree: [],
    erpcat: [],
    erpcatTree: [],
    erpstatus: [],
    erpstatusTree: [],
    erpdisposal: [],
    erpdisposalTree: [],
    logic: [],
    logicTree: [],
    valtype: [],
    valtypeTree: [],
    doctype: [],
    doctypeTree: [],
    deviceLegends: [],
    search: {
        cat: undefined,
        companyId: undefined,
        installId: "",
        procat: undefined,
        extcat: undefined,
        search: "",
        useStatus: undefined,
        size: 15,
        status: undefined,
    },
    procatTree: [],
    procat: [],
    mgtcat: [],
    mgtcatTree: [],
    extcat: [],
    extcatTree: [],
    deviceBaseChange: false,
    deviceRunParamChange: false,
    deviceTechParamChange: false,
    deviceAssetChange: false,
    currentDevice: {},
    currentDeviceProcess: {},
    deviceError: {},
    techParamTemp: [],
    runParamTemp: [],
    techParam: [],
    runParam: [],
    view: false,
    tableTechParam: [],
    tableRunParam: [],
    techParamWarning: false,
    runParamWarning: false,
    deviceAsset: {},
    deviceAssetError: {},
    deviceBuild: {},
    deviceBuildError: {},
    deviceBuildChange: false,
    deviceDesign: {},
    deviceDesignError: {},
    deviceDesignChange: false,
    deviceDoc: [],
    deviceDocPage: {},
    deviceDocPageError: {},
    /**
     * 控制不受设备表单影响运行、技术参数、部件和文档等【日常维护】模块的状态
     */
    deviceEdit: true,
    accessoryDeviceId: 0,
    deviceCatChange: false,
    deviceVehicleInfo: {},
    deviceVehicleInfoError: {},
    deviceVehicleInfoChange: false,
    searchUrl: '',
    // 检测报告列表
    maintainReportList: {
        total: 0,
        list: []
    },
    // 检测报告列表状态 是否发生变化 
    maintainReportListChangeFlg: false,
    // 可用于关联检测报告的工单列表
    availableWorkOrderList: [],
    // 检测报告列表 - 分页条数
    pageSize: 15,
    // 可用于关联检测报告的设备文档列表
    availableDeviceDocList: [],
    // 班组列表
    departmentList: [],
    // 根据设备类别决定的“位号”显示名称
    tagNoTitle: '位号',
    //是否现实调拨对话框
    transferOpen: false,

    //某个单元的设备列表
    unitDeviceList: {
        total: 0,
        list: []
    },

    //某个区域的设备列表
    areaDeviceList: {
        total: 0,
        list: []
    },
}

export const initCreate = createAction(
    '@@xcloud/devices/initCreate',
    () => (dispatch, getState) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let device = {
            deviceId: 0,
            deviceName: "",
            deviceNo: "",
            tagNo: "",
            cat: undefined,
            proCat: undefined,
            mgtCat: undefined,
            extCat: undefined,
            specs: "",
            drawingCode: "",
            opentext: "",
            model: "",
            useStatus: undefined,
            // 与建设信息合并
            // enableTime: null,
            // idleTime: null,
            // downTime: null,
            // abandonTime: null,
            devicePic: "",
            platePic: "",
            unitId: "",
            companyId: companyId,
            medium: [],
            media: [],
            deviceNoEdit: true,
            status: 'new'
        }
        let deviceError = {
            deviceName: '',
            tagNo: '',
            cat: '',
            useStatus: '',
            companyId: '',
            drawingCode: '',
            deviceNo: '',
            idleTime: '',
            downTime: '',
            abandonTime: '',
        };
        let deviceAsset = {
            assetId: 0,
            faNet: '',
            maintCost: '',
            faVal: '',
            faCode: '',
            dptId: undefined,
            source: undefined,
            invoiceNo: '',
            countUnit: undefined,
            assetCat: undefined,
            assetStatus: undefined,
            disposalMode: undefined,
        }
        let deviceAssetError = {
            faCode: '',
            source: '',
            dptId: '',
            faNet: '',
            maintCost: '',
            faVal: '',
        };
        let deviceBuild = {
            infoId: 0,
            supervisor: '',
            startTime: null,
            endTime: null,
            constructor: '',
            acceptDoc: undefined,
        }
        let deviceBuildError = {
            supervisor: '',
            startTime: '',
            endTime: '',
            constructor: '',
            acceptDoc: '',
            // 与建设信息合并
            // 基本信息
            idleTime: null,
            downTime: null,
            abandonTime: null,
            // 设计制造信息
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
            designLife: '',
        };
        let deviceDesign = {
            infoId: 0,
            designer: '',
            serialNo: '',
            deliveryTime: null,
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: undefined,
        }
        let deviceDesignError = {
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
        };
        dispatch(setCurrentDevice(device))
        dispatch(setDeviceError(deviceError));
        dispatch(setDeviceAsset(deviceAsset))
        dispatch(setDeviceAssetError(deviceAssetError));
        dispatch(setDeviceBaseChange(false));
        dispatch(setDeviceRunParamChange(false));
        dispatch(setDeviceTechParamChange(false));
        dispatch(setDeviceView(false));
        dispatch(setTechParamTemp([]))
        dispatch(setRunParamTemp([]))
        dispatch(setTechParam([]))
        dispatch(setRunParam([]))
        dispatch(setTableTechParam([]))
        dispatch(setTableRunParam([]))
        dispatch(setTechParamWarning(false))
        dispatch(setRunParamWarning(false))
        dispatch(setDeviceAssetChange(false))
        dispatch(setDeviceBuild(deviceBuild))
        dispatch(setDeviceBuildError(deviceBuildError));
        dispatch(setDeviceBuildChange(false))
        dispatch(setDeviceDesign(deviceDesign))
        dispatch(setDeviceDesignError(deviceDesignError));
        dispatch(setDeviceDesignChange(false))
        dispatch(setDeviceEdit(false))
        dispatch(setDeviceCatChange(false))
        dispatch(setCurrentDeviceProcess({}))
        dispatch(push('/deviceManagement/info'));
    }
);


export const initUpdate = createAction(
    '@@xcloud/devices/initUpdate',
    (id) => (dispatch, getState) => {
        let devices = getState().devices.devicelist;
        let device = _.find(devices.list, ['deviceId', id])
        let deviceError = {
            deviceName: '',
            tagNo: '',
            cat: '',
            useStatus: '',
            companyId: '',
            drawingCode: '',
            deviceNo: '',
            idleTime: '',
            downTime: '',
            abandonTime: '',
        };
        if (!device.proCat) {
            device.proCat = undefined
        }
        if (!device.mgtCat) {
            device.mgtCat = undefined
        }
        if (!device.extCat) {
            device.extCat = undefined
        }
        if (!device.drawingCode) {
            device.drawingCode = ''
        }
        if (device.deviceNo) {
            device.deviceNoEdit = false;
        } else {
            device.deviceNoEdit = true;
        }

        // if (!device.groupId) {
        dispatch(setCurrentDevice(device))
        dispatch(setDeviceError(deviceError));
        dispatch(setDeviceBaseChange(false));
        dispatch(setDeviceRunParamChange(false));
        dispatch(setDeviceTechParamChange(false));
        dispatch(setDeviceView(false));
        dispatch(setTechParamTemp([]))
        dispatch(setRunParamTemp([]))
        dispatch(setTechParam([]))
        dispatch(setRunParam([]))
        dispatch(setTableTechParam([]))
        dispatch(setTableRunParam([]))
        dispatch(setTechParamWarning(false))
        dispatch(setRunParamWarning(false))
        dispatch(setDeviceAssetChange(false))
        dispatch(setDeviceBuildChange(false))
        dispatch(setDeviceEdit(true))
        dispatch(setDeviceCatChange(false))
        // dispatch(getDeviceProcess(device.deviceId, device.companyId));
        dispatch(getDeviceOpsRecordList({
            deviceId: device.deviceId,
            fromDate: getMoment()('2020-01-01').toDate(),
            toDate: getMoment()('2099-01-01').toDate()
        }))
        dispatch(push('/deviceManagement/info'));
        // } else {
        //     dispatch(createMessage('error', '请从装置移除后，再更新设备'));
        // }
    }
);
export const routeToDeviceInfo = createAction(
    '@@xcloud/devices/routeToDeviceInfo',
    () => (dispatch) => {
        dispatch(push(`/deviceManagement/info`))
    }
);

export const copyInitEdit = createAction(
    '@@xcloud/devices/copyInitEdit',
    (device) => (dispatch, getState) => {
        device.copyDeviceId = device.deviceId
        device.deviceId = 0
        device.deviceNo = ''
        if (device.groupId) {
            device.groupId = undefined
        }
        if (device.downTime) {
            device.downTime = null
        }
        if (device.enableTime) {
            device.enableTime = null
        }
        if (device.idleTime) {
            device.idleTime = null
        }
        if (device.abandonTime) {
            device.abandonTime = null
        }
        if (device.useStatus) {
            device.useStatus = undefined
        }
        if (!device.proCat) {
            device.proCat = undefined
        }
        if (!device.mgtCat) {
            device.mgtCat = undefined
        }
        if (!device.extCat) {
            device.extCat = undefined
        }
        if (!device.drawingCode) {
            device.drawingCode = ''
        }
        if (device.deviceNo) {
            device.deviceNoEdit = false;
        } else {
            device.deviceNoEdit = true;
        }
        let deviceError = {
            deviceName: '',
            tagNo: '',
            cat: '',
            useStatus: '',
            companyId: '',
            drawingCode: '',
            deviceNo: '',
            idleTime: '',
            downTime: '',
            abandonTime: '',
        };
        let deviceAsset = {
            assetId: 0,
            faNet: '',
            maintCost: '',
            faVal: '',
            faCode: '',
            dptId: undefined,
            source: undefined,
            invoiceNo: '',
            countUnit: undefined,
            assetCat: undefined,
            assetStatus: undefined,
            disposalMode: undefined,
        }
        let deviceAssetError = {
            faCode: '',
            source: '',
            dptId: '',
            faNet: '',
            maintCost: '',
            faVal: '',
        };
        let deviceBuild = {
            infoId: 0,
            supervisor: '',
            startTime: null,
            endTime: null,
            constructor: '',
            acceptDoc: undefined,
        }
        let deviceBuildError = {
            supervisor: '',
            startTime: '',
            endTime: '',
            constructor: '',
            acceptDoc: '',
            // 与建设信息合并
            // 基本信息
            idleTime: null,
            downTime: null,
            abandonTime: null,
            // 设计制造信息
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
            designLife: '',
        };
        let deviceDesign = {
            infoId: 0,
            designer: '',
            serialNo: '',
            deliveryTime: null,
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: undefined,
        }
        let deviceDesignError = {
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
        };

        dispatch(setCurrentDevice(device))
        dispatch(setDeviceError(deviceError));
        dispatch(setDeviceAsset(deviceAsset))
        dispatch(setDeviceAssetError(deviceAssetError));
        dispatch(setDeviceBaseChange(false));
        dispatch(setDeviceRunParamChange(false));
        dispatch(setDeviceTechParamChange(false));
        dispatch(setDeviceView(false));
        dispatch(setTechParamTemp([]))
        dispatch(setRunParamTemp([]))
        dispatch(setTechParam([]))
        dispatch(setRunParam([]))
        dispatch(setTableTechParam([]))
        dispatch(setTableRunParam([]))
        dispatch(setTechParamWarning(false))
        dispatch(setRunParamWarning(false))
        dispatch(setDeviceAssetChange(false))
        dispatch(setDeviceBuild(deviceBuild))
        dispatch(setDeviceBuildError(deviceBuildError));
        dispatch(setDeviceBuildChange(false))
        dispatch(setDeviceDesign(deviceDesign))
        dispatch(setDeviceDesignError(deviceDesignError));
        dispatch(setDeviceDesignChange(false))
        dispatch(setDeviceEdit(false))
        dispatch(setDeviceCatChange(false))
        dispatch(setCurrentDeviceProcess({}))
        dispatch(push('/deviceManagement/info'));
    }
);

export const initView = createAction(
    '@@xcloud/devices/initView',
    (id) => (dispatch, getState) => {

        let permission = _.find(getState().auth.userProfile.roles[0].routes, { 'route': 'device_info' })
        let devices = getState().devices.devicelist;
        let device = _.find(devices.list, ['deviceId', id])
        let deviceError = {
            deviceName: '',
            tagNo: '',
            cat: '',
            useStatus: '',
            companyId: '',
            drawingCode: '',
            deviceNo: '',
            idleTime: '',
            downTime: '',
            abandonTime: '',
        };
        if (!device.proCat) {
            device.proCat = undefined
        }
        if (!device.mgtCat) {
            device.mgtCat = undefined
        }
        if (!device.extCat) {
            device.extCat = undefined
        }
        if (!device.drawingCode) {
            device.drawingCode = ''
        }
        dispatch(setCurrentDevice(device))
        dispatch(setDeviceError(deviceError));
        dispatch(setDeviceBaseChange(false));
        dispatch(setDeviceRunParamChange(false));
        dispatch(setDeviceTechParamChange(false));
        dispatch(setDeviceView(true));
        dispatch(setTechParamTemp([]))
        dispatch(setRunParamTemp([]))
        dispatch(setTechParam([]))
        dispatch(setRunParam([]))
        dispatch(setTableTechParam([]))
        dispatch(setTableRunParam([]))
        dispatch(setTechParamWarning(false))
        dispatch(setRunParamWarning(false))
        dispatch(setDeviceAssetChange(false))
        dispatch(setDeviceBuildChange(false))
        dispatch(setDeviceCatChange(false))
        dispatch(setDeviceEdit(true))
        // console.log(permission)
        if (permission && permission.action === 'R') {
            // dispatch(getDeviceProcess(device.deviceId, device.companyId));
        }
        dispatch(push('/deviceManagement/info'));
    }
);

export const initBrowse = createAction(
    '@@xcloud/devices/initBrowse',
    (id) => (dispatch, getState) => {
        // 从设备列表检索当前设备
        let devices = getState().devices.devicelist;
        let device = _.find(devices.list, ['deviceId', id])
        // if (!device.proCat) {
        //     device.proCat = undefined
        // }
        // if (!device.mgtCat) {
        //     device.mgtCat = undefined
        // }
        // if (!device.extCat) {
        //     device.extCat = undefined
        // }
        // if (!device.drawingCode) {
        //     device.drawingCode = ''
        // }

        // 设置状态 - 当前设备
        dispatch(setCurrentDevice(device))

        dispatch(setAccessoryDeviceId(id))
        dispatch(getDeviceAccessorylist(id));
        dispatch(push('/deviceAccessoryList/info'));
    }
);

// Actions
export const getDevicesbyCat = createAction(
    '@@xcloud/devices/getDevicesbyCat',
    (cat, companyId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices?cat=${cat}&recursive=true&offset=0&size=0&companyId=${companyId}`)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `按照设备类别查找设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const getDevicesbyUint = createAction(
    '@@xcloud/devices/getDevicesbyUint',
    (installId, unitId, offset, size) => (dispatch, getState, httpClient) => {
        dispatch(getDevicesbyInstallUintArea(installId, unitId, null, offset, size)).then(
            (data) => {
                dispatch(setUnitDeviceList(data.payload))
            }
        )
    }
);

export const getDevicesbyArea = createAction(
    '@@xcloud/devices/getDevicesbyUint',
    (installId, areaId, offset, size) => (dispatch, getState, httpClient) => {
        dispatch(getDevicesbyInstallUintArea(installId, null, areaId, offset, size)).then(
            (data) => {
                dispatch(setAreaDeviceList(data.payload))
            }
        )
    }
);

export const getDevicesbyInstallUintArea = createAction(
    '@@xcloud/devices/getDevicesbyInstallUintArea',
    (installId, unitId, areaId, offset, size) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/devices?&offset=${offset}&size=${size}`
        if (installId) {
            url = url + `&installId=${installId}`
        }
        if (unitId) {
            url = url + `&unitId=${unitId}`
        }
        if (areaId) {
            url = url + `&areaId=${areaId}`
        }
        return httpClient
            .get(url)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查找设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const initDeviceTechParam = createAction('@@xcloud/devices/initDeviceTechParam',
    () => (dispatch, getState) => {
        let catObj = getState().devices.currentDevice.cat
        let cat = _.isObject(catObj) ? catObj.value : catObj
        let copyDeviceId = getState().devices.currentDevice.copyDeviceId;
        //如果是复制的情况下就复制的设备ID
        let deviceId = copyDeviceId ? copyDeviceId : getState().devices.currentDevice.deviceId
        Promise.all([
            dispatch(getDevicesTechParam(deviceId)),
            dispatch(getDevicesTechParamTemp(cat))
        ]).then(() => {
            let techParam = getState().devices.techParam
            let techParamTemp = getState().devices.techParamTemp
            if (techParam.length === 0 && techParamTemp.length === 0) {
                if (!getState().devices.view) {
                    dispatch(setTableTechParam([]))
                    dispatch(createMessage('warning', '当前设备类别没有结构参数模板数据,请联系管理员添加'));
                }
                dispatch(setTechParamWarning(false))
            } else if (techParam.length === 0) {
                let tableData = _.map(techParamTemp, t => {
                    let logic = t.template.split('{')[0]
                    let target = /\$target=\d+/.exec(t.template)
                    let vals = ''
                    if (target) {
                        vals = target[0].split('=')[1]
                    }
                    //表格操作统一id
                    let _id = uuidv4()
                    return {
                        ...t,
                        logic: logic,
                        vals: vals,
                        _id: _id,
                        deviceId: getState().devices.currentDevice.deviceId
                    }
                })
                dispatch(setTableTechParam(tableData))
                //dispatch(setDeviceTechParamChange(true));
            } else {
                let tableData = _.map(techParam, t => {
                    let logic = t.template.split('{')[0]
                    let _id = uuidv4()
                    return {
                        ...t,
                        logic: logic,
                        _id: _id,
                        deviceId: getState().devices.currentDevice.deviceId
                    }
                })
                dispatch(setTableTechParam(tableData))
            }
        }).catch(err => {
            let msg = '初始化设备结构参数失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });

export const initDeviceRunParam = createAction('@@xcloud/devices/initDeviceRunParam',
    () => (dispatch, getState) => {
        let catObj = getState().devices.currentDevice.cat
        let cat = _.isObject(catObj) ? catObj.value : catObj
        let copyDeviceId = getState().devices.currentDevice.copyDeviceId;
        //如果是复制的情况下就复制的设备ID
        let deviceId = copyDeviceId ? copyDeviceId : getState().devices.currentDevice.deviceId
        Promise.all([
            dispatch(getDevicesRunParam(deviceId)),
            dispatch(getDevicesRunParamTemp(cat))
        ]).then(() => {
            let runParam = getState().devices.runParam
            let runParamTemp = getState().devices.runParamTemp
            if (runParam.length === 0 && runParamTemp.length === 0) {
                if (!getState().devices.view) {
                    dispatch(setTableRunParam([]))
                    dispatch(createMessage('warning', '当前设备类别没有运行参数模板数据,请联系管理员添加'));
                }
                dispatch(setRunParamWarning(false))
            } else if (runParam.length === 0) {
                let tableData = _.map(runParamTemp, t => {
                    let logic1 = '';
                    let logic2 = '';
                    let vals1 = '';
                    let vals2 = '';
                    if (t.template.indexOf('|') > -1) {
                        let temp1 = t.template.split('|')[0];
                        logic1 = temp1.split('{')[0]
                        let target = /\$target=\d+/.exec(temp1)
                        if (target) {
                            vals1 = target[0].split('=')[1]
                        }
                        let temp2 = t.template.split('|')[1];
                        logic2 = temp2.split('{')[0]
                        let target2 = /\$target=\d+/.exec(temp2)
                        if (target2) {
                            vals2 = target2[0].split('=')[1]
                        }
                    } else {
                        logic1 = t.template.split('{')[0]
                        let target = /\$target=\d+/.exec(t.template)
                        if (target) {
                            vals1 = target[0].split('=')[1]
                        }
                    }

                    let offLogic1 = '';
                    let offLogic2 = '';
                    let offVals1 = '';
                    let offVals2 = '';
                    if (t.offTemplate) {
                        if (t.offTemplate.indexOf('|') > -1) {
                            let offTemp1 = t.offTemplate.split('|')[0];
                            offLogic1 = offTemp1.split('{')[0]
                            let target = /\$target=\d+/.exec(offTemp1)
                            if (target) {
                                offVals1 = target[0].split('=')[1]
                            }
                            let offTemp2 = t.offTemplate.split('|')[1];
                            offLogic2 = offTemp2.split('{')[0]
                            let target2 = /\$target=\d+/.exec(offTemp2)
                            if (target2) {
                                offVals2 = target2[0].split('=')[1]
                            }
                        } else {
                            offLogic1 = t.offTemplate.split('{')[0]
                            let target = /\$target=\d+/.exec(t.offTemplate)
                            if (target) {
                                offVals1 = target[0].split('=')[1]
                            }
                        }
                    }

                    //表格操作统一id
                    let _id = uuidv4()
                    return {
                        ...t,
                        logic1: logic1,
                        vals1: vals1,
                        logic2: logic2,
                        vals2: vals2,
                        offLogic1: offLogic1,
                        offVals1: offVals1,
                        offLogic2: offLogic2,
                        offVals2: offVals2,
                        _id: _id,
                        deviceId: getState().devices.currentDevice.deviceId
                    }
                })
                dispatch(setTableRunParam(tableData))
                //dispatch(setDeviceRunParamChange(true));
            } else {
                let tableData = _.map(runParam, t => {
                    let logic1 = '';
                    let logic2 = '';
                    let vals1 = '';
                    let vals2 = '';
                    if (t.template.indexOf('|') > -1) {
                        let temp1 = t.template.split('|')[0];
                        logic1 = temp1.split('{')[0]
                        let temp2 = t.template.split('|')[1];
                        logic2 = temp2.split('{')[0]
                        vals1 = t.vals.split(',')[0];
                        vals2 = t.vals.split(',')[1];
                    } else {
                        logic1 = t.template.split('{')[0]
                        vals1 = t.vals;
                    }

                    let offLogic1 = '';
                    let offLogic2 = '';
                    let offVals1 = '';
                    let offVals2 = '';
                    if (t.offTemplate) {
                        if (t.offTemplate.indexOf('|') > -1) {
                            let offTemp1 = t.offTemplate.split('|')[0];
                            offLogic1 = offTemp1.split('{')[0]
                            let offTemp2 = t.offTemplate.split('|')[1];
                            offLogic2 = offTemp2.split('{')[0]
                            offVals1 = t.offVals.split(',')[0];
                            offVals2 = t.offVals.split(',')[1];
                        } else {
                            offLogic1 = t.offTemplate.split('{')[0]
                            offVals1 = t.offVals;
                        }
                    }
                    let _id = uuidv4()
                    return {
                        ...t,
                        logic1: logic1,
                        vals1: vals1,
                        logic2: logic2,
                        vals2: vals2,
                        offLogic1: offLogic1,
                        offVals1: offVals1,
                        offLogic2: offLogic2,
                        offVals2: offVals2,
                        _id: _id,
                        deviceId: getState().devices.currentDevice.deviceId
                    }
                })
                dispatch(setTableRunParam(tableData))
            }
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                console.error(err)
                let msg = '初始化设备运行参数失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

export const initDeviceHeader = createAction('@@xcloud/devices/initDeviceHeader',
    (query, reload) => (dispatch, getState) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        Promise.all([
            dispatch(resetSearch()),
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
            dispatch(getDeviceProCat()),
            dispatch(getDeviceExtCat()),
            dispatch(getInstallations({ companyId: company, size: 100 })),
            dispatch(getDeviceList(query, reload))
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化设备搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });

export const initDeviceList = createAction('@@xcloud/devices/initDeviceList',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
            dispatch(getDeviceMedium()),
            dispatch(getDeviceMediumPhase()),
            dispatch(getDeviceProCat()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化设备搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });

export const initDeviceEdit = createAction('@@xcloud/devices/initDeviceEdit',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
            dispatch(getDeviceProCat()),
            dispatch(getDeviceMedium()),
            dispatch(getDeviceMediumPhase()),
            dispatch(getDeviceUnit()),
            dispatch(getDeviceLogic()),
            dispatch(getDeviceValtype()),
            dispatch(getDeviceDoctype()),
            dispatch(getDeviceMgtCat()),
            dispatch(getDeviceExtCat()),
            dispatch(getDeviceErpSrc()),
            dispatch(getDeviceErpUnit()),
            dispatch(getDeviceErpCat()),
            dispatch(getDeviceErpStatus()),
            dispatch(getDeviceErpDisposal()),
            dispatch(resetGoingDeviceForm()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化设备详情失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    });

export const initDeviceDesign = createAction('@@xcloud/devices/initDeviceDesign',
    () => (dispatch, getState) => {
        let deviceDesign = {
            infoId: 0,
            designer: '',
            serialNo: '',
            deliveryTime: null,
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: undefined,
            // 设计寿命
            designLife: '',
        }
        let deviceDesignError = {
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
            // 设计寿命
            designLife: '',
        };
        dispatch(setDeviceDesign(deviceDesign))
        dispatch(setDeviceDesignError(deviceDesignError));
        Promise.all([
            dispatch(getDevicesDesign(getState().devices.currentDevice.deviceId))
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备设计信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

export const initDeviceAsset = createAction('@@xcloud/devices/initDeviceAsset',
    () => (dispatch, getState) => {
        let deviceAsset = {
            assetId: '',
            faNet: '',
            maintCost: '',
            faVal: '',
            faCode: '',
            dptId: undefined,
            source: undefined,
            invoiceNo: '',
            countUnit: undefined,
            assetCat: undefined,
            assetStatus: undefined,
            disposalMode: undefined,
        }
        let deviceAssetError = {
            faCode: '',
            source: '',
            dptId: '',
            faNet: '',
            maintCost: '',
            faVal: '',
        };
        dispatch(setDeviceAsset(deviceAsset))
        dispatch(setDeviceAssetError(deviceAssetError));
        Promise.all([
            dispatch(getDevicesAsset(getState().devices.currentDevice.deviceId))
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备资产信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

export const initDeviceVehicleInfo = createAction('@@xcloud/devices/initDeviceVehicleInfo',
    () => (dispatch, getState) => {
        let deviceVehicleInfo = {
            infoId: '',
            vtype: '',
            engineCode: '',
            frameCode: '',
            buyTime: null,
            vweight: undefined,
            vheight: undefined,
            vlength: undefined,
            vwidth: undefined,
            registTime: null,
            vcolor: '',
            loadNo: '',
            licenseNo: '',
            emissionLevel: '',
            insCycle: undefined,
            vphoto: undefined,
        }
        let deviceVehicleInfoError = {
            licenseNo: '',
            engineCode: '',
            frameCode: '',
        };
        dispatch(setDeviceVehicleInfo(deviceVehicleInfo))
        dispatch(setDeviceVehicleInfoError(deviceVehicleInfoError));
        dispatch(setDeviceVehicleInfoChange(false));
        Promise.all([
            dispatch(initDeviceDoc()),
            dispatch(getDevicesVehicleInfo(getState().devices.currentDevice.deviceId))
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备车辆信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    }
);

export const initDeviceBuild = createAction('@@xcloud/devices/initDeviceBuild',
    () => (dispatch, getState) => {
        let deviceBuild = {
            infoId: 0,
            supervisor: '',
            startTime: null,
            endTime: null,
            constructor: '',
            acceptDoc: undefined,
        }
        let deviceBuildError = {
            supervisor: '',
            startTime: '',
            endTime: '',
            constructor: '',
            // 与建设信息合并
            // 基本信息
            idleTime: null,
            downTime: null,
            abandonTime: null,
            // 设计制造信息
            designer: '',
            serialNo: '',
            deliveryTime: '',
            specCode: '',
            manufacturer: '',
            permitNo: '',
            permitDoc: '',
            designLife: '',
        };
        dispatch(setDeviceBuild(deviceBuild))
        dispatch(setDeviceBuildError(deviceBuildError));
        Promise.all([
            dispatch(getDevicesBuild(getState().devices.currentDevice.deviceId)),
            dispatch(initDeviceDoc())
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备建设信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

export const initDeviceOpsReport = createAction('@@xcloud/devices/initDeviceOpsReport',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getDeviceOpsRecordList({
                deviceId: getState().devices.currentDevice.deviceId,
                fromDate: getMoment()('2020-01-01').toDate(),
                toDate: getMoment()('2099-01-01').toDate()
            }))
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备建设信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

export const initDeviceDocPage = createAction('@@xcloud/devices/initDeviceDocPage',
    () => (dispatch, getState) => {
        let deviceDocPage = {
            docName: undefined,
            docType: undefined,
            docDesc: undefined,
        }
        let deviceDocPageError = {
            docName: '',
            docType: '',
            docDesc: '',
        };
        dispatch(setDeviceDocPage(deviceDocPage))
        dispatch(setDeviceDocPageError(deviceDocPageError));
    });

export const initDeviceDoc = createAction('@@xcloud/devices/initDeviceDoc',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getDevicesDoc(getState().devices.currentDevice.deviceId))
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化设备文档信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

// export const executeProcess = createAction('@@xcloud/devices/executeProcess',
//     (params) => (dispatch, getState, httpClient) => {
//         let deviceId = getState().devices.currentDevice.deviceId;
//         let recordId = getState().devices.currentDeviceProcess.recordId;
//         let companyId = getState().devices.currentDevice.companyId;
//         let url = porxyApi + `/devices/${deviceId}/companies/${companyId}/records/${recordId}/process`;
//         return httpClient.post(url, params, { headers: { need_loading: true } })
//             .then((res) => {
//                 if (res.status === 200) {
//                     dispatch(startLoading())
//                     return dispatch(getCurrentProcessStatus(`DeviceRegistration_${companyId}`, recordId, () => {
//                         let currentProcessStatus = getState().process.currentProcessStatus[`DeviceRegistration_${companyId}`]
//                         if (currentProcessStatus.statusCode && currentProcessStatus.actions
//                             && currentProcessStatus.actions.length > 0 && currentProcessStatus.actions[0].statusCode === 'Pending') {
//                             dispatch(setDevice Edit(false))
//                         }
//                         dispatch(stopLoading())
//                     }));
//                 }
//             })
//             .catch(err => {
//                 let msg = '执行设备注册审批流程失败';
//                 dispatch(createhttpMessage(err.response ? err.response : '', msg));
//                 return [];
//             });
//     });

export const transferDevice = createAction('@@xcloud/devices/transferDevice',
    (params) => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId;
        let url = porxyApi + `/devices/${deviceId}/transfer`;
        return httpClient.patch(url, params, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage({ status: 200 }, '设备调拨成功'))
                    return res.data
                }

            })
            .catch(err => {
                let msg = '设备调拨失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const resetSearch = createAction('@@xcloud/devices/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dispatch(getInstallations({ companyId: companyId, size: 100 }))
        let search = getState().devices.search;
        let newSearch = {
            ...search,
            companyId: companyId,
            installId: '',
            unitId: '',
            areaId: '',
            cat: undefined,
            procat: undefined,
            extcat: undefined,
            search: "",
            useStatus: undefined,
            status: undefined,
            size: 15,
            tagNoTitle: '位号'
        }
        dispatch(setSearch(newSearch));
        dispatch(setSearchUrl(''));
        dispatch(backToList(''));
    });

export const backToList = createAction('@@xcloud/devices/backToList',
    () => (dispatch, getState) => {
        let searchUrl = getState().devices.searchUrl;
        dispatch(push('/deviceList' + searchUrl));
    });

export const resetSearchField = createAction('@@xcloud/devices/resetSearchField',
    (value, field) => (dispatch, getState, httpClient) => {
        if (field === 'companyId') {
            if (value) {
                dispatch(getInstallations({ companyId: value, size: 100 }))
                let search = getState().devices.search;
                let newSearch = {
                    ...search,
                    installId: '',
                    unitId: '',
                    areaId: '',
                }
                dispatch(setSearch(newSearch));
            } else {
                let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

                dispatch(getInstallations({ companyId: company, size: 100 }))
                let search = getState().devices.search;
                let newSearch = {
                    ...search,
                    installId: '',
                    unitId: '',
                    areaId: '',
                }
                dispatch(setSearch(newSearch));
            }
        } else if (field === 'installId') {
            dispatch(getInstallation(value))
            let search = getState().devices.search;
            let newSearch = {
                ...search,
                unitId: '',
                areaId: '',
            }
            dispatch(setSearch(newSearch));
        }
    });

export const getDevices = createAction(
    '@@xcloud/devices/getDevices',
    (offset, size, sort, cat, recursive, extcat, mgtcat, procat,
        search, companyId, installId, status, useStatus, areaId, unitId, storeyId) => (dispatch, getState, httpClient) => {
            let url = "/devices"
            if (offset && offset !== "") {
                url += "?offset=" + offset
            } else {
                url += "?offset=0"
            }
            if (cat && cat !== "") {
                url += "&cat=" + cat
            }
            if (recursive) {
                url += "&recursive=" + recursive
            }
            if (extcat && extcat !== "") {
                url += "&extcat=" + extcat
            }
            if (mgtcat && mgtcat !== "") {
                url += "&mgtcat=" + mgtcat
            }
            if (procat && procat !== "") {
                url += "&procat=" + procat
            }
            if (search && search !== "") {
                url += "&search=" + encodeURIComponent(_.trim(search));
            }
            if (companyId && companyId !== "") {
                url += "&companyId=" + companyId
            }
            if (installId && installId !== "") {
                url += "&installId=" + installId
            }
            if (_.isInteger(size)) {
                url += "&size=" + size
            }
            if (sort && sort !== "") {
                url += "&sort=" + sort
            } else {
                url += "&sort=-update_time"
            }
            if (status && status !== "") {
                url += "&status=" + status
            }
            if (useStatus && useStatus !== "") {
                url += "&useStatus=" + useStatus
            }
            if (areaId && areaId !== "") {
                url += "&areaId=" + areaId
            }
            if (unitId && unitId !== "") {
                url += "&unitId=" + unitId
            }
            if (storeyId && storeyId !== "") {
                url += "&storeyId=" + storeyId
            }
            return httpClient
                .get(porxyApi + url)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setDeviceList(res.data));
                        return res.data;
                    }
                })
                .catch(err => {
                    let msg = `按照设备类别查找设备失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return [];
                })
        }
);


export const createDeviceDoc = createAction(
    '@@xcloud/devices/createDeviceDoc',
    (doc) => (dispatch, getState, httpClient) => {

        return httpClient
            .post(porxyApi + `/devices/doc`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(setDeviceDoc(res.data))
                    dispatch(createhttpMessage({ status: 200 }, '上传设备文档成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `上传设备文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const setDeviceState = createAction(
    '@@xcloud/devices/setDeviceState',
    () => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let companyId = getState().devices.currentDevice.companyId
        let param = {
            deviceId: deviceId,
            companyId: companyId,
            status: 'new'
        }
        return httpClient
            .patch(porxyApi + `/devices/state`, param, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(startLoading())
                    dispatch(createhttpMessage({ status: 200 }, '修改设备状态成功'))
                    dispatch(setCurrentDeviceProcess(res.data))
                    dispatch(getCurrentProcess(`DeviceRegistration_${companyId}`))
                    dispatch(getCurrentProcessStatus(`DeviceRegistration_${companyId}`, res.data.recordId, () => {
                        dispatch(stopLoading())
                    }))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `修改设备状态失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// “位号” 在页面的显示Title取得
export const getTagNoTitle = createAction(
    '@@xcloud/devices/getTagNoTitle',
    (cat) => (dispatch, getState, httpClient) => {

        // // 检索条件取得
        // let search = getState().devices.search;

        let tagNoTitle = '位号';

        // 缺陷 车辆的位号显示改为车牌号 SBGL-290
        if (cat && cat.startsWith('D1706')) {
            tagNoTitle = '车牌号';
        }

        // 缺陷 [实施发现]管道的位号要变成管段号 SBGL-239
        if (cat && cat.startsWith('D06')) {
            tagNoTitle = '管段号';
        }

        // 设置状态： “位号” 在页面的显示Title
        dispatch(setTagNoTitle(tagNoTitle));

        return tagNoTitle;
    }
)

export const getDeviceList = createAction(
    '@@xcloud/devices/getDeviceList',
    (data, reload) => (dispatch, getState, httpClient) => {
        // let search = getState().devices.search;
        // if (reload) {
        //     let newSearch = {
        //         ...search,
        //         companyId: data.companyId ? data.companyId : und,
        //         installId: data.installId ? data.installId : '',
        //         unitId: data.unitId ? data.unitId : '',
        //         areaId: data.areaId ? data.areaId : '',
        //         cat: data.cat ? data.cat : undefined,
        //         procat: data.procat ? data.procat : undefined,
        //         extcat: data.extcat ? data.extcat : undefined,
        //         search: data.search ? decodeURIComponent(data.search) : '',
        //         useStatus: data.useStatus ? data.useStatus : undefined,
        //         status: data.status ? data.status : undefined,
        //         size: data.size ? _.toInteger(data.size) : 15,
        //     }
        //     dispatch(setSearch(newSearch));
        // }

        let searchCond = getState().devices.search;
        let params = {}
        //条件检索
        params.cat = _.isObject(searchCond.cat) ? searchCond.cat.value : searchCond.cat
        // params.companyId = searchCond.companyId ? searchCond.companyId : undefined
        params.procat = _.isObject(searchCond.procat) ? searchCond.procat.value : searchCond.procat
        params.extcat = _.isObject(searchCond.extcat) ? searchCond.extcat.value : searchCond.extcat
        params.search = searchCond.search ? searchCond.search : ''
        params.installId = searchCond.installId ? searchCond.installId : ''
        params.unitId = searchCond.unitId ? searchCond.unitId : ''
        params.areaId = searchCond.areaId ? searchCond.areaId : ''
        params.useStatus = _.isObject(searchCond.useStatus) ? searchCond.useStatus.value : searchCond.useStatus
        params.status = _.isObject(searchCond.status) ? searchCond.status.value : searchCond.status
        params.size = searchCond.size ? searchCond.size : 15
        if (searchCond.companyId) {
            params.companyId = searchCond.companyId
        }
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        data.companyId = data.companyId ? data.companyId : company

        let query = {}
        _.assign(query, data, params)

        // 设置 - “位号” 在页面的显示Title
        dispatch(getTagNoTitle(params.cat));


        let path = "/devices"
        let url = ""
        let recursive = false
        if (query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }

        if (query.cat && query.cat !== "") {
            url += "&cat=" + query.cat
            recursive = true
        }
        if (query.extcat && query.extcat !== "") {
            url += "&extcat=" + query.extcat
            recursive = true
        }
        if (query.mgtcat && query.mgtcat !== "") {
            url += "&mgtcat=" + query.mgtcat
        }
        if (query.procat && query.procat !== "") {
            url += "&procat=" + query.procat
            recursive = true
        }
        if (query.search && query.search !== "") {
            url += "&search=" + encodeURIComponent(_.trim(query.search));
        }
        if (query.companyId && query.companyId !== "") {
            url += "&companyId=" + query.companyId
        }
        if (query.installId && query.installId !== "") {
            url += "&installId=" + query.installId
        }
        if (query.unitId && query.unitId !== "") {
            url += "&unitId=" + query.unitId
        }
        if (query.areaId && query.areaId !== "") {
            url += "&areaId=" + query.areaId
        }
        if (_.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=15"
        }
        if (query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }
        if (query.useStatus && query.useStatus !== "") {
            url += "&useStatus=" + query.useStatus
        }
        if (query.status && query.status !== "") {
            url += "&status=" + query.status
        }
        if (recursive) {
            url += "&recursive=" + recursive
        }

        dispatch(setSearchUrl(url));
        return httpClient
            .get(porxyApi + path + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        let list = res.data.list
                        for (let i = 0; i < list.length; i++) {
                            list[i].listTime = list[i].updateTime ? getDateByTimestamp(list[i].updateTime, true) : getDateByTimestamp(list[i].createTime, true)
                        }
                    }
                    dispatch(setDeviceList(res.data));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `按照设备类别查找设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const exportCsv = createAction(
    '@@xcloud/devices/exportCsv',
    () => (dispatch, getState, httpClient) => {
        let searchCond = getState().devices.search;
        //条件检索
        let cat = _.isObject(searchCond.cat) ? searchCond.cat.value : searchCond.cat
        let extcat = _.isObject(searchCond.extcat) ? searchCond.extcat.value : searchCond.extcat
        let mgtcat = _.isObject(searchCond.mgtcat) ? searchCond.mgtcat.value : searchCond.mgtcat
        let procat = _.isObject(searchCond.procat) ? searchCond.procat.value : searchCond.procat
        let search = searchCond.search
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyId = searchCond.companyId ? searchCond.companyId : company
        let installId = searchCond.installId ? searchCond.installId : ''
        let unitId = searchCond.unitId ? searchCond.unitId : ''
        let areaId = searchCond.areaId ? searchCond.areaId : ''
        let useStatus = _.isObject(searchCond.status) ? searchCond.status.value : searchCond.status

        let url = "/devices?offset=0&size=0&isExport=true&companyId=" + companyId
        // let url = "/devices/export?companyId=" + companyId
        let recursive = false
        if (cat && cat !== "") {
            url += "&cat=" + cat
            recursive = true
        }
        if (extcat && extcat !== "") {
            url += "&extcat=" + extcat
            recursive = true
        }
        if (mgtcat && mgtcat !== "") {
            url += "&mgtcat=" + mgtcat
        }
        if (procat && procat !== "") {
            url += "&procat=" + procat
            recursive = true
        }
        if (search && search !== "") {
            url += "&search=" + encodeURIComponent(_.trim(search));
        }
        if (installId && installId !== "") {
            url += "&installId=" + installId
        }
        if (unitId && unitId !== "") {
            url += "&unitId=" + unitId
        }
        if (areaId && areaId !== "") {
            url += "&areaId=" + areaId
        }
        if (useStatus && useStatus !== "") {
            url += "&useStatus=" + useStatus
        }
        if (recursive) {
            url += "&recursive=" + recursive
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    let tenantId = getState().auth.currentUserInfo.tenantId
                    let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                    dispatch(download(
                        res.data.docName,
                        mybucket
                    ))
                }
            })
            .catch(err => {
                let msg = `按照设备类别查找设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const getDeviceUseStatus = createAction('@@xcloud/devices/getDeviceUseStatus',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=USESTATUS";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备使用状况获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const getDeviceUnit = createAction('@@xcloud/devices/getDeviceUnit',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备结构参数单位获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceErpSrc = createAction('@@xcloud/devices/getDeviceErpSrc',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ERP_SRC";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备资产来源获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });


export const getDeviceErpUnit = createAction('@@xcloud/devices/getDeviceErpUnit',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ERP_UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备计量单位获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });


export const getDeviceErpCat = createAction('@@xcloud/devices/getDeviceErpCat',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ERP_CAT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备资产类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceErpStatus = createAction('@@xcloud/devices/getDeviceErpStatus',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ERP_STATUS";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备资产状态获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceErpDisposal = createAction('@@xcloud/devices/getDeviceErpDisposal',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ERP_DISPOSAL";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备处置方式获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceValtype = createAction('@@xcloud/devices/getDeviceValtype',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=VALTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备结构参数单位获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const getDeviceDoctype = createAction('@@xcloud/devices/getDeviceDoctype',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=DOCTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备文档类型获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

// 旧流程
// export const getDeviceProcess = createAction(
//     '@@xcloud/devices/getDeviceProcess',
//     (deviceId, companyId) => (dispatch, getState, httpClient) => {
//         return httpClient
//             .get(porxyApi + `/devices/process?deviceId=${deviceId}&companyId=${companyId}`, { headers: { need_loading: true } })
//             .then((res) => {
//                 if (res.data) {
//                     dispatch(startLoading())
//                     dispatch(setCurrentDeviceProcess(res.data))
//                     dispatch(getCurrentProcess(`DeviceRegistration_${companyId}`))
//                     dispatch(getCurrentProcessStatus(`DeviceRegistration_${companyId}`, res.data.recordId, () => {
//                         let currentProcessStatus = getState().process.currentProcessStatus[`DeviceRegistration_${companyId}`]
//                         if (currentProcessStatus.statusCode && currentProcessStatus.actions
//                             && currentProcessStatus.actions.length > 0 && currentProcessStatus.actions[0].statusCode === 'Pending') {
//                             dispatch(setDevice Edit(false))
//                         } else {
//                             dispatch(setDevice Edit(true))
//                         }
//                         dispatch(stopLoading())
//                     }))
//                     return res.data;
//                 }
//             })
//             .catch(err => {
//                 let msg = `查询设备流程记录信息失败`;
//                 dispatch(createhttpMessage(err.response ? err.response : '', msg));
//                 return [];
//             })
//     }
// );





export const getDeviceLogic = createAction('@@xcloud/devices/getDeviceLogic',
    () => (dispatch, getState, httpClient) => {
        //临时先用UNIT的值填充,页面已经手动填写,之后再从字典里面获取
        let url = "/api/unprotected/utils/device/dictionaries?type=UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备结构参数逻辑获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceMedium = createAction('@@xcloud/devices/getDeviceMedium',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=MEDIUM";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备使用状况获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceMediumPhase = createAction('@@xcloud/devices/getDeviceMediumPhase',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=PHASE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data
                }
                return res;
            }).catch(err => {
                let msg = '介质相位获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceProCat = createAction('@@xcloud/devices/getDeviceProCat',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=PROCAT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备专业类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const getDeviceMgtCat = createAction('@@xcloud/devices/getDeviceMgtCat',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=DEVMGTCAT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备管理类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDeviceExtCat = createAction('@@xcloud/devices/getDeviceExtCat',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=DEVEXTCAT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '设备扩展类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const getDeviceLegends = createAction(
    '@@xcloud/devices/getDeviceLegends',
    (cat) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/legends`)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备图例失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createDevice = createAction(
    '@@xcloud/devices/createDevice',
    (deviceDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "deviceName": deviceDto.deviceName,
            "cat": deviceDto.cat,
            "useStatus": deviceDto.useStatus,
            "companyId": deviceDto.companyId,
            "groupId": deviceDto.groupId
        }
        if (deviceDto.media) {
            dto.media = deviceDto.media
        }
        if (deviceDto.tagNo && deviceDto.tagNo !== '') {
            dto.tagNo = deviceDto.tagNo
        }
        return httpClient
            .post(porxyApi + `/devices`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const insertDevice = createAction(
    '@@xcloud/devices/insertDevice',
    (deviceDto) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.deviceName = deviceDto.deviceName ? deviceDto.deviceName : undefined
        dto.deviceNo = deviceDto.deviceNo ? deviceDto.deviceNo : undefined
        dto.tagNo = deviceDto.tagNo ? deviceDto.tagNo : undefined
        dto.cat = _.isObject(deviceDto.cat) ? deviceDto.cat.value : deviceDto.cat
        dto.proCat = _.isObject(deviceDto.proCat) ? deviceDto.proCat.value : deviceDto.proCat
        dto.extCat = _.isObject(deviceDto.extCat) ? deviceDto.extCat.value : deviceDto.extCat
        dto.mgtCat = _.isObject(deviceDto.mgtCat) ? deviceDto.mgtCat.value : deviceDto.mgtCat
        dto.specs = deviceDto.specs ? deviceDto.specs : undefined
        dto.drawingCode = deviceDto.drawingCode ? parseInt(deviceDto.drawingCode) : undefined
        dto.model = deviceDto.model ? deviceDto.model : undefined
        dto.useStatus = _.isObject(deviceDto.useStatus) ? deviceDto.useStatus.value : deviceDto.useStatus
        dto.enableTime = deviceDto.enableTime ? new Date(deviceDto.enableTime) : undefined
        dto.idleTime = deviceDto.idleTime ? new Date(deviceDto.idleTime) : undefined
        dto.downTime = deviceDto.downTime ? new Date(deviceDto.downTime) : undefined
        dto.abandonTime = deviceDto.abandonTime ? new Date(deviceDto.abandonTime) : undefined
        dto.devicePic = deviceDto.devicePic ? deviceDto.devicePic : undefined
        dto.platePic = deviceDto.platePic ? deviceDto.platePic : undefined
        dto.companyId = deviceDto.companyId ? deviceDto.companyId : undefined
        dto.opentext = deviceDto.opentext ? deviceDto.opentext : undefined
        dto.process = true
        dto.media = deviceDto.media ? deviceDto.media : []
        // 负责班组信息
        dto.dpts = deviceDto.dpts
        return httpClient
            .post(porxyApi + `/devices`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.currentDevice,
                        deviceId: res.data.deviceId,
                        tenantId: res.data.tenantId,
                        unitId: res.data.unitId,
                        deviceNo: res.data.deviceNo ? res.data.deviceNo : '',
                        deviceNoEdit: getState().devices.currentDevice.deviceNo ? false : true,
                    };
                    dispatch(setCurrentDevice(newDevice))
                    dispatch(initDeviceTechParam())
                    dispatch(initDeviceRunParam())
                    dispatch(initDeviceDoc())
                    // dispatch(getDeviceProcess(res.data.deviceId, res.data.companyId))
                    dispatch(createhttpMessage({ status: 200 }, '创建设备成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const insertDeviceAsset = createAction(
    '@@xcloud/devices/insertDeviceAsset',
    (deviceAsset) => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.assetId = 0
        dto.deviceId = deviceId
        dto.faNet = deviceAsset.faNet ? _.toNumber(deviceAsset.faNet) : 0
        dto.maintCost = deviceAsset.maintCost ? _.toNumber(deviceAsset.maintCost) : 0
        dto.faVal = deviceAsset.faVal ? _.toNumber(deviceAsset.faVal) : 0
        dto.faCode = deviceAsset.faCode ? deviceAsset.faCode : undefined
        dto.dptId = deviceAsset.dptId ? deviceAsset.dptId.value : undefined

        dto.source = _.isObject(deviceAsset.source) ? deviceAsset.source.value : deviceAsset.source
        dto.countUnit = _.isObject(deviceAsset.countUnit) ? deviceAsset.countUnit.value : deviceAsset.countUnit
        dto.assetCat = _.isObject(deviceAsset.assetCat) ? deviceAsset.assetCat.value : deviceAsset.assetCat
        dto.invoiceNo = deviceAsset.invoiceNo ? deviceAsset.invoiceNo : undefined
        dto.assetStatus = _.isObject(deviceAsset.assetStatus) ? deviceAsset.assetStatus.value : deviceAsset.assetStatus
        dto.disposalMode = _.isObject(deviceAsset.disposalMode) ? deviceAsset.disposalMode.value : deviceAsset.disposalMode

        return httpClient
            .post(porxyApi + `/devices/asset`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.deviceAsset,
                        assetId: res.data.assetId,
                    };
                    dispatch(setDeviceAsset(newDevice))
                    dispatch(createhttpMessage({ status: 200 }, '创建设备资产信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备资产信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const insertDeviceDesign = createAction(
    '@@xcloud/devices/insertDeviceDesign',
    (deviceDesign) => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.infoId = 0
        dto.deviceId = deviceId
        dto.designer = deviceDesign.designer ? deviceDesign.designer : undefined
        dto.serialNo = deviceDesign.serialNo ? deviceDesign.serialNo : undefined
        dto.deliveryTime = deviceDesign.deliveryTime ? deviceDesign.deliveryTime : undefined
        dto.manufacturer = deviceDesign.manufacturer ? deviceDesign.manufacturer : undefined
        dto.specCode = deviceDesign.specCode ? deviceDesign.specCode : undefined
        dto.permitNo = deviceDesign.permitNo ? deviceDesign.permitNo : undefined
        dto.permitDoc = deviceDesign.permitDoc ? deviceDesign.permitDoc.value : undefined
        // 设计寿命
        dto.designLife = deviceDesign.designLife ? _.toInteger(deviceDesign.designLife) : undefined

        return httpClient
            .post(porxyApi + `/devices/designinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.deviceDesign,
                        infoId: res.data.infoId,
                    };
                    dispatch(setDeviceDesign(newDevice))
                    dispatch(createhttpMessage({ status: 200 }, '创建设备设计信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备设计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const insertDeviceBuild = createAction(
    '@@xcloud/devices/insertDeviceBuild',
    (deviceBuild) => (dispatch, getState, httpClient) => {

        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.deviceId = deviceId
        dto.infoId = 0
        dto.supervisor = deviceBuild.supervisor ? deviceBuild.supervisor : undefined
        dto.constructor = deviceBuild.constructor ? deviceBuild.constructor : undefined
        dto.startTime = deviceBuild.startTime ? deviceBuild.startTime : undefined
        dto.endTime = deviceBuild.endTime ? deviceBuild.endTime : undefined
        dto.acceptDoc = deviceBuild.acceptDoc ? deviceBuild.acceptDoc.value : undefined

        return httpClient
            .post(porxyApi + `/devices/buildinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.deviceBuild,
                        infoId: res.data.infoId,
                    };
                    dispatch(setDeviceBuild(newDevice))
                    dispatch(createhttpMessage({ status: 200 }, '创建设备建设信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备建设信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDevice = createAction(
    '@@xcloud/devices/modifyDevice',
    (deviceDto) => (dispatch, getState, httpClient) => {
        let deviceCatChange = getState().devices.deviceCatChange
        let dto = {}
        dto.deviceNo = deviceDto.deviceNo ? deviceDto.deviceNo : undefined
        dto.deviceName = deviceDto.deviceName ? deviceDto.deviceName : undefined
        dto.tagNo = deviceDto.tagNo ? deviceDto.tagNo : undefined
        dto.cat = _.isObject(deviceDto.cat) ? deviceDto.cat.value : deviceDto.cat
        dto.proCat = _.isObject(deviceDto.proCat) ? deviceDto.proCat.value : deviceDto.proCat
        dto.extCat = _.isObject(deviceDto.extCat) ? deviceDto.extCat.value : deviceDto.extCat
        dto.mgtCat = _.isObject(deviceDto.mgtCat) ? deviceDto.mgtCat.value : deviceDto.mgtCat
        dto.specs = deviceDto.specs ? deviceDto.specs : undefined
        dto.drawingCode = deviceDto.drawingCode ? parseInt(deviceDto.drawingCode) : undefined
        dto.model = deviceDto.model ? deviceDto.model : undefined
        dto.useStatus = _.isObject(deviceDto.useStatus) ? deviceDto.useStatus.value : deviceDto.useStatus
        dto.enableTime = deviceDto.enableTime ? new Date(deviceDto.enableTime) : new Date('1000-01-01')
        dto.idleTime = deviceDto.idleTime ? new Date(deviceDto.idleTime) : new Date('1000-01-01')
        dto.downTime = deviceDto.downTime ? new Date(deviceDto.downTime) : new Date('1000-01-01')
        dto.abandonTime = deviceDto.abandonTime ? new Date(deviceDto.abandonTime) : new Date('1000-01-01')
        dto.devicePic = deviceDto.devicePic ? deviceDto.devicePic : undefined
        dto.platePic = deviceDto.platePic ? deviceDto.platePic : undefined
        dto.deviceId = deviceDto.deviceId
        dto.tenantId = deviceDto.tenantId
        dto.deviceCatChange = deviceCatChange
        dto.masterId = deviceDto.masterId ? deviceDto.masterId : undefined
        dto.opentext = deviceDto.opentext ? deviceDto.opentext : undefined
        dto.media = deviceDto.media ? deviceDto.media : []
        // 负责班组信息
        dto.allocations = deviceDto.allocations;
        return httpClient
            .patch(porxyApi + `/devices/${deviceDto.deviceId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.currentDevice,
                        deviceNo: res.data.deviceNo ? res.data.deviceNo : '',
                        deviceNoEdit: getState().devices.currentDevice.deviceNo ? false : true,
                    };
                    dispatch(setCurrentDevice(newDevice))
                    if (deviceCatChange) {
                        dispatch(initDeviceTechParam())
                        dispatch(initDeviceRunParam())
                        dispatch(setDeviceCatChange(false))
                    }
                    dispatch(createhttpMessage({ status: 200 }, '更新设备成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceAsset = createAction(
    '@@xcloud/devices/modifyDeviceAsset',
    (deviceAsset) => (dispatch, getState, httpClient) => {

        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.deviceId = deviceId
        dto.assetId = deviceAsset.assetId
        dto.faNet = deviceAsset.faNet ? _.toNumber(deviceAsset.faNet) : 0
        dto.maintCost = deviceAsset.maintCost ? _.toNumber(deviceAsset.maintCost) : 0
        dto.faVal = deviceAsset.faVal ? _.toNumber(deviceAsset.faVal) : 0
        dto.faCode = deviceAsset.faCode ? deviceAsset.faCode : undefined
        dto.dptId = deviceAsset.dptId ? deviceAsset.dptId.value : undefined

        dto.source = _.isObject(deviceAsset.source) ? deviceAsset.source.value : deviceAsset.source ? deviceAsset.source : ''
        dto.countUnit = _.isObject(deviceAsset.countUnit) ? deviceAsset.countUnit.value : deviceAsset.countUnit ? deviceAsset.countUnit : ''
        dto.assetCat = _.isObject(deviceAsset.assetCat) ? deviceAsset.assetCat.value : deviceAsset.assetCat ? deviceAsset.assetCat : ''
        dto.invoiceNo = deviceAsset.invoiceNo ? deviceAsset.invoiceNo : undefined
        dto.assetStatus = _.isObject(deviceAsset.assetStatus) ? deviceAsset.assetStatus.value : deviceAsset.assetStatus ? deviceAsset.assetStatus : ''
        dto.disposalMode = _.isObject(deviceAsset.disposalMode) ? deviceAsset.disposalMode.value : deviceAsset.disposalMode ? deviceAsset.disposalMode : ''

        return httpClient
            .patch(porxyApi + `/devices/asset`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备资产信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备资产信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceDesign = createAction(
    '@@xcloud/devices/modifyDeviceDesign',
    (deviceDesign) => (dispatch, getState, httpClient) => {


        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.infoId = deviceDesign.infoId
        dto.deviceId = deviceId
        dto.designer = deviceDesign.designer ? deviceDesign.designer : undefined
        dto.serialNo = deviceDesign.serialNo ? deviceDesign.serialNo : undefined
        dto.deliveryTime = deviceDesign.deliveryTime ? new Date(deviceDesign.deliveryTime) : new Date('1000-01-01');
        dto.manufacturer = deviceDesign.manufacturer ? deviceDesign.manufacturer : undefined
        dto.specCode = deviceDesign.specCode ? deviceDesign.specCode : undefined
        dto.permitNo = deviceDesign.permitNo ? deviceDesign.permitNo : undefined
        dto.permitDoc = deviceDesign.permitDoc ? deviceDesign.permitDoc.value : 0
        // 设计寿命
        dto.designLife = deviceDesign.designLife ? _.toInteger(deviceDesign.designLife) : undefined
        return httpClient
            .patch(porxyApi + `/devices/designinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备设计信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备设计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceBuild = createAction(
    '@@xcloud/devices/modifyDeviceBuild',
    (deviceBuild) => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.deviceId = deviceId
        dto.infoId = deviceBuild.infoId
        dto.supervisor = deviceBuild.supervisor ? deviceBuild.supervisor : undefined
        dto.constructor = deviceBuild.constructor ? deviceBuild.constructor : undefined
        dto.startTime = deviceBuild.startTime ? new Date(deviceBuild.startTime) : new Date('1000-01-01')
        dto.endTime = deviceBuild.endTime ? new Date(deviceBuild.endTime) : new Date('1000-01-01')
        dto.acceptDoc = deviceBuild.acceptDoc ? deviceBuild.acceptDoc.value : 0

        return httpClient
            .patch(porxyApi + `/devices/buildinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备建设信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备建设信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceTechParam = createAction(
    '@@xcloud/devices/modifyDeviceTechParam',
    () => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let techParam = getState().devices.techParam
        let tableTechParam = getState().devices.tableTechParam
        let array = [];
        for (let i = 0; i < tableTechParam.length; i++) {
            let cell = {
                param: tableTechParam[i].param,
                // alias: tableTechParam[i].alias,
                unit: tableTechParam[i].unit ? tableTechParam[i].unit : undefined,
                template: tableTechParam[i].template,
                vals: tableTechParam[i].vals,
                paramType: tableTechParam[i].paramType,
            }
            if (tableTechParam[i].alias && tableTechParam[i].alias !== '') {
                cell.alias = tableTechParam[i].alias
            }
            if (tableTechParam[i].paramId) {
                cell.paramId = tableTechParam[i].paramId
                cell.model = 'update'
            } else {
                cell.paramId = 0
                cell.model = 'insert'
            }
            array.push(cell)
        }

        for (let j = 0; j < techParam.length; j++) {
            let has = false;
            for (let z = 0; z < tableTechParam.length; z++) {
                if (tableTechParam[z].paramId === techParam[j].paramId) {
                    has = true
                    break;
                }
            }
            if (!has) {
                let cell = {
                    paramId: techParam[j].paramId,
                    // alias: techParam[j].alias,
                    param: techParam[j].param,
                    unit: techParam[j].unit ? techParam[j].unit : undefined,
                    template: techParam[j].template,
                    vals: techParam[j].vals,
                    paramType: techParam[j].paramType,
                    model: 'delete'
                }
                if (techParam[j].alias && techParam[j].alias !== '') {
                    cell.alias = techParam[j].alias
                }
                array.push(cell)
            }
        }

        return httpClient
            .patch(porxyApi + `/devices/techparams?deviceId=${deviceId}`, array, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备结构参数成功'))
                    let tableData = _.map(res.data, t => {
                        let logic = t.template.split('{')[0]
                        let _id = uuidv4()
                        return {
                            ...t,
                            logic: logic,
                            _id: _id
                        }
                    })
                    dispatch(setTechParam(res.data))
                    dispatch(setTableTechParam(tableData))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备结构参数成功`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceRunParam = createAction(
    '@@xcloud/devices/modifyDeviceRunParam',
    () => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let runParam = getState().devices.runParam
        let tableRunParam = getState().devices.tableRunParam
        let array = [];
        for (let i = 0; i < tableRunParam.length; i++) {
            let cell = {
                param: tableRunParam[i].param,
                unit: tableRunParam[i].unit ? tableRunParam[i].unit : undefined,
                template: tableRunParam[i].template,
                offTemplate: tableRunParam[i].offTemplate,
                paramType: tableRunParam[i].paramType,
                sourceId: tableRunParam[i].sourceId ? tableRunParam[i].sourceId : 0,
            }
            if (tableRunParam[i].alias && tableRunParam[i].alias !== '') {
                cell.alias = tableRunParam[i].alias;
            }
            if (tableRunParam[i].vals1 && tableRunParam[i].vals2) {
                cell.vals = tableRunParam[i].vals1 + ',' + tableRunParam[i].vals2
            } else {
                cell.vals = tableRunParam[i].vals1
            }
            if (tableRunParam[i].offVals1 && tableRunParam[i].offVals2) {
                cell.offVals = tableRunParam[i].offVals1 + ',' + tableRunParam[i].offVals2
            } else {
                cell.offVals = tableRunParam[i].offVals1
            }
            if (tableRunParam[i].paramId) {
                cell.paramId = tableRunParam[i].paramId
                cell.model = 'update'
            } else {
                cell.paramId = 0
                cell.model = 'insert'
            }
            array.push(cell)
        }

        for (let j = 0; j < runParam.length; j++) {
            let has = false;
            for (let z = 0; z < tableRunParam.length; z++) {
                if (tableRunParam[z].paramId === runParam[j].paramId) {
                    has = true
                    break;
                }
            }
            if (!has) {
                let cell = {
                    paramId: runParam[j].paramId,
                    // alias: runParam[j].alias,
                    param: runParam[j].param,
                    unit: runParam[j].unit ? runParam[j].unit : undefined,
                    template: runParam[j].template,
                    vals: runParam[j].vals,
                    offTemplate: runParam[j].offTemplate,
                    offVals: runParam[j].offVals,
                    paramType: runParam[j].paramType,
                    sourceId: runParam[j].sourceId ? runParam[j].sourceId : 0,
                    model: 'delete'
                }
                if (runParam[j].alias && runParam[j].alias !== '') {
                    cell.alias = runParam[j].alias;
                }
                array.push(cell)
            }
        }

        return httpClient
            .patch(porxyApi + `/devices/runparams?deviceId=${deviceId}`, array, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备运行参数成功'))
                    let tableData = _.map(res.data, t => {
                        let logic1 = '';
                        let logic2 = '';
                        let vals1 = '';
                        let vals2 = '';
                        if (t.template.indexOf('|') > -1) {
                            let temp1 = t.template.split('|')[0];
                            logic1 = temp1.split('{')[0]
                            let temp2 = t.template.split('|')[1];
                            logic2 = temp2.split('{')[0]
                            vals1 = t.vals.split(',')[0];
                            vals2 = t.vals.split(',')[1];
                        } else {
                            logic1 = t.template.split('{')[0]
                            vals1 = t.vals;
                        }

                        let offLogic1 = '';
                        let offLogic2 = '';
                        let offVals1 = '';
                        let offVals2 = '';
                        if (t.offTemplate) {

                            if (t.offTemplate.indexOf('|') > -1) {
                                let offTemp1 = t.offTemplate.split('|')[0];
                                offLogic1 = offTemp1.split('{')[0]
                                let offTemp2 = t.offTemplate.split('|')[1];
                                offLogic2 = offTemp2.split('{')[0]
                                offVals1 = t.offVals.split(',')[0];
                                offVals2 = t.offVals.split(',')[1];
                            } else {
                                offLogic1 = t.offTemplate.split('{')[0];
                                offVals1 = t.offVals;
                            }

                        }

                        let _id = uuidv4()
                        return {
                            ...t,
                            logic1: logic1,
                            vals1: vals1,
                            logic2: logic2,
                            vals2: vals2,
                            offLogic1: offLogic1,
                            offVals1: offVals1,
                            offLogic2: offLogic2,
                            offVals2: offVals2,
                            _id: _id,
                            deviceId: getState().devices.currentDevice.deviceId
                        }
                    })
                    dispatch(setRunParam(res.data))
                    dispatch(setTableRunParam(tableData))
                    return res.data;
                }
            })
            .catch(err => {
                debugger
                let msg = `更新设备运行参数失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const changeDeviceStatus = createAction(
    '@@xcloud/devices/changeDeviceStatus',
    (deviceDto) => (dispatch, getState, httpClient) => {

        let dto = {}
        dto.deviceId = deviceDto.deviceId
        dto.tenantId = deviceDto.tenantId
        dto.deviceName = deviceDto.deviceName
        dto.cat = deviceDto.cat
        dto.useStatus = deviceDto.useStatus
        dto.status = 'inactive'
        return httpClient
            .patch(porxyApi + `/devices/${deviceDto.deviceId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除设备成功'))
                    //重新检索列表
                    dispatch(getDeviceList({ offset: 0, size: 15, sort: "-update_time" }))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `删除设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const copyDevice = createAction(
    '@@xcloud/devices/copyDevice',
    (deviceDto) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(porxyApi + `/devices/copy`, deviceDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '复制设备成功'))
                    if (res.data.deviceId) {
                        dispatch(copyInitEdit(res.data))
                    } else {
                        dispatch(getDeviceList({ offset: 0, size: 15, sort: "-update_time" }))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `复制设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const changeDeviceDocStatus = createAction(
    '@@xcloud/devices/changeDeviceDocStatus',
    (deviceDocDto, mybucket) => (dispatch, getState, httpClient) => {
        let dto = _.cloneDeep(deviceDocDto);
        delete dto.updateTime;
        delete dto.revisor;
        if (!dto.docDesc) {
            delete dto.docDesc
        }
        dto.status = 'inactive'

        return httpClient
            .patch(porxyApi + `/devices/doc`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除设备文档成功'))
                    //重新检索列表
                    dispatch(removeObject(mybucket, deviceDocDto.docName));
                    dispatch(getDevicesDoc(getState().devices.currentDevice.deviceId))

                    return res.data;
                } else {
                    dispatch(createMessage('warning', '该文档在建设信息或设计制造信息中已使用,请解除后再删除'));
                }
            })
            .catch(err => {
                let msg = `删除设备文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesTechParamTemp = createAction(
    '@@xcloud/devices/getDevicesTechParamTemp',
    (cat) => (dispatch, getState, httpClient) => {
        let url = "/devices/techparamtemps?deviceCat=" + cat
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                if (res.data) {
                    let result = _.filter(res.data, function (o) { return cat === o.deviceCat; });
                    if (result && result.length) {
                        dispatch(setTechParamTemp(result))
                    } else {
                        dispatch(setTechParamTemp(res.data))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备结构参数模板失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesRunParamTemp = createAction(
    '@@xcloud/devices/getDevicesRunParamTemp',
    (cat) => (dispatch, getState, httpClient) => {
        let url = "/devices/runparamtemps?deviceCat=" + cat
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                if (res.data) {
                    let result = _.filter(res.data, function (o) { return cat === o.deviceCat; });
                    if (result && result.length) {
                        dispatch(setRunParamTemp(result))
                    } else {
                        dispatch(setRunParamTemp(res.data))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备运行参数模板失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesTechParam = createAction(
    '@@xcloud/devices/getDevicesTechParam',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/techparams?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setTechParam(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备结构参数失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesRunParam = createAction(
    '@@xcloud/devices/getDevicesRunParam',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/runparams?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setRunParam(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备运行参数失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesAsset = createAction(
    '@@xcloud/devices/getDevicesAsset',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/asset?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    //当结果为{}时不刷新
                    if (Object.keys(res.data).length > 0) {
                        let newData = _.cloneDeep(res.data)
                        if (!newData.dptId) {
                            newData.dptId = undefined
                        }
                        dispatch(setDeviceAsset(newData))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备资产信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesVehicleInfo = createAction(
    '@@xcloud/devices/getDevicesVehicleInfo',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/vehicleinfo?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    //当结果为{}时不刷新
                    if (Object.keys(res.data).length > 0) {
                        let newData = _.cloneDeep(res.data)
                        if (!newData.dptId) {
                            newData.dptId = undefined
                        }
                        dispatch(setDeviceVehicleInfo(newData))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备车辆信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesBuild = createAction(
    '@@xcloud/devices/getDevicesBuild',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/buildinfo?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    //当结果为{}时不刷新
                    if (Object.keys(res.data).length > 0) {
                        let newData = _.cloneDeep(res.data)
                        newData.acceptDoc = newData.acceptDoc ? newData.acceptDoc : undefined
                        dispatch(setDeviceBuild(newData))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备建设信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesDoc = createAction(
    '@@xcloud/devices/getDevicesDoc',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/doc?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setDeviceDoc(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备建设信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getDevicesDesign = createAction(
    '@@xcloud/devices/getDevicesDesign',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/designinfo?deviceId=${deviceId}`)
            .then((res) => {
                if (res.data) {
                    //当结果为{}时不刷新
                    if (Object.keys(res.data).length > 0) {
                        let newData = _.cloneDeep(res.data)
                        newData.permitDoc = newData.permitDoc ? newData.permitDoc : undefined
                        dispatch(setDeviceDesign(newData))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备设计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const updateDevice = createAction(
    '@@xcloud/devices/updateDevice',
    (deviceDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "cat": deviceDto.cat,
            "companyId": deviceDto.companyId,
            "data2d": deviceDto.data2d ? deviceDto.data2d : '',
            "deviceId": deviceDto.deviceId,
            "deviceName": deviceDto.deviceName,
            "status": deviceDto.status,
            "tenantId": deviceDto.tenantId,
            "useStatus": deviceDto.useStatus,
            "groupId": _.isInteger(deviceDto.groupId) ? deviceDto.groupId : 0,
            "unitId": _.isInteger(deviceDto.unitId) ? deviceDto.unitId : 0,
            "masterId": _.isInteger(deviceDto.masterId) ? deviceDto.masterId : 0,
        }
        if (deviceDto.groupId) {
            dto.groupId = deviceDto.groupId
        }
        if (deviceDto.masterId) {
            dto.masterId = deviceDto.masterId
        }
        if (deviceDto.media) {
            dto.media = deviceDto.media
        }
        if (deviceDto.dangerLevel || deviceDto.dangerLevel === '') {
            dto.dangerLevel = deviceDto.dangerLevel
        }
        if (deviceDto.tagNo && deviceDto.tagNo !== '') {
            dto.tagNo = deviceDto.tagNo
        }

        return httpClient
            .patch(porxyApi + `/devices/${deviceDto.deviceId}`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updateDevices = createAction(
    '@@xcloud/devices/updateDevices',
    (deviceDtos) => (dispatch, getState, httpClient) => {
        let dtos = [];
        deviceDtos.forEach(deviceDto => {
            let dto = {
                "cat": deviceDto.cat,
                "companyId": deviceDto.companyId,
                "data2d": deviceDto.data2d ? deviceDto.data2d : '',
                "deviceId": deviceDto.deviceId,
                "deviceName": deviceDto.deviceName,
                "status": deviceDto.status,
                "tenantId": deviceDto.tenantId,
                "useStatus": deviceDto.useStatus,
                "groupId": _.isInteger(deviceDto.groupId) ? deviceDto.groupId : 0,
                "unitId": _.isInteger(deviceDto.unitId) ? deviceDto.unitId : 0,
                "masterId": _.isInteger(deviceDto.masterId) ? deviceDto.masterId : 0,
            }
            if (deviceDto.groupId) {
                dto.groupId = deviceDto.groupId
            }
            if (deviceDto.media) {
                dto.media = deviceDto.media
            }
            dtos.push(dto);
        })

        return httpClient
            .patch(porxyApi + `/devices`, dtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createDeviceGroup = createAction(
    '@@xcloud/devices/createDeviceGroup',
    (deviceGroupDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "groupName": deviceGroupDto.groupName,
            "installId": deviceGroupDto.installId,
            "tagNo": deviceGroupDto.tagNo
        }
        return httpClient
            .post(porxyApi + `/devicegroups`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const connectDevice2Storey = createAction(
    '@@xcloud/devices/connectDevice2Storey',
    (deviceStoreyDtos) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(porxyApi + `/devices/storeys`, deviceStoreyDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `关联层和设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const disconnectDevice2Storey = createAction(
    '@@xcloud/devices/disconnectDevice2Storey',
    (deviceStoreyDtos) => (dispatch, getState, httpClient) => {
        return httpClient
            .patch(porxyApi + `/devices/storeys`, deviceStoreyDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取消层和设备关联失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const connectDevice2Area = createAction(
    '@@xcloud/devices/connectDevice2Area',
    (deviceAreaDtos) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(porxyApi + `/devices/workareas`, deviceAreaDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `关联区域和设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const disconnectDevice2Area = createAction(
    '@@xcloud/devices/disconnectDevice2Area',
    (deviceAreaDtos) => (dispatch, getState, httpClient) => {
        // console.log(deviceAreaDtos)
        return httpClient
            .patch(porxyApi + `/devices/workareas`, deviceAreaDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取消区域和设备关联失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const connectDevice2Unit = createAction(
    '@@xcloud/devices/connectDevice2Unit',
    (deviceUnitDtos) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(porxyApi + `/devices/units`, deviceUnitDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `关联单元和设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const disconnectDevice2Unit = createAction(
    '@@xcloud/devices/disconnectDevice2Unit',
    (deviceUnitDtos) => (dispatch, getState, httpClient) => {
        return httpClient
            .patch(porxyApi + `/devices/units`, deviceUnitDtos)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取消单元和设备关联失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 检索 - 可用于关联检测报告的工单列表
export const getAvailableWorkOrderList = createAction('@@xcloud/devices/getAvailableWorkOrderList',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/workorders?offset=0&size=0&source=ORD_DEV&sourceId=${deviceId}&attachedWithMaintainReport=${false}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setAvailableWorkOrderList(res.data.list))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询工单信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 检索 - 可用于关联检测报告的文档列表
export const getAvailableDeviceDocList = createAction('@@xcloud/devices/getAvailableDeviceDocList',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/devices/maintainreport?deviceId=${deviceId}&offset=0&size=0`)
            .then((res) => {

                // 已关联检测报告的设备文档ID列表
                let attachedMaintainReportDocIdArray = []
                // 未关联检测报告的设备文档列表
                let availableDeviceDocList = []
                // 设备文档列表（全部）
                let deviceDocList = getState().devices.deviceDoc

                if (res.data) {
                    res.data.list.forEach(mr => {
                        attachedMaintainReportDocIdArray.push(mr.docId)
                    })

                    deviceDocList.forEach(dd => {
                        if (!attachedMaintainReportDocIdArray.includes(dd.docId)) {
                            availableDeviceDocList.push(dd)
                        }
                    })

                    dispatch(setAvailableDeviceDocList(availableDeviceDocList))

                    return availableDeviceDocList;
                }
            })
            .catch(err => {
                let msg = `查询检测报告信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 检索 - 检测报告列表
export const getMaintainReportList = createAction('@@xcloud/devices/getMaintainReportList',
    // (deviceId) => (dispatch, getState, httpClient) => {
    (query) => (dispatch, getState, httpClient) => {
        // 请求路径
        let url = "/devices/maintainreport"
        // 分页条数
        let pageSize = getState().devices.pageSize

        // 分页
        if (query && query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 15)
        }

        // 排序
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }

        // 当前设备ID
        if (query && query.deviceId && query.deviceId !== "") {
            url += "&deviceId=" + query.deviceId
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(setMaintainReportList(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询设备检测报告信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 更新 - 检测报告
export const updateMaintainReport = createAction(
    '@@xcloud/devices/updateMaintainReport',
    (maintainReportDto) => (dispatch, getState, httpClient) => {

        /* DTO 参数变换 */
        let tmpDto = _.cloneDeep(maintainReportDto)
        // 创建时间
        tmpDto.createTime = maintainReportDto.createTime ? new Date(maintainReportDto.createTime) : undefined;
        // 下次检测时间
        tmpDto.nextTime = maintainReportDto.nextTime ? new Date(maintainReportDto.nextTime) : undefined;
        // 备注
        tmpDto.opentext = maintainReportDto.opentext ? maintainReportDto.opentext : undefined;
        // 工单ID
        tmpDto.orderId = maintainReportDto.orderId ? _.toInteger(maintainReportDto.orderId) : undefined;
        // 更新时间
        tmpDto.updateTime = maintainReportDto.updateTime ? new Date(maintainReportDto.updateTime) : undefined;

        let action = (tmpDto.status === "inactive" ? "删除" : "更新");

        return httpClient
            .patch(porxyApi + `/devices/maintainreport`, tmpDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    // 更新成功后......
                    dispatch(createhttpMessage({ status: 200 }, action + '检测报告成功'))
                    // 重新检索 - 检测报告列表
                    dispatch(getMaintainReportList({ deviceId: getState().devices.currentDevice.deviceId }))
                    // 重新检索 - 可用于关联检测报告的工单列表
                    dispatch(getAvailableWorkOrderList(getState().devices.currentDevice.deviceId))
                    // 重新检索 - 可用于关联检测报告的文档列表
                    dispatch(getAvailableDeviceDocList(getState().devices.currentDevice.deviceId))
                    // 返回更新结果
                    return res.data;
                }
            })
            .catch(err => {
                let msg = action + `检测报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 插入 - 检测报告
export const addMaintainReport = createAction(
    '@@xcloud/devices/updateMaintainReport',
    (maintainReportDto) => (dispatch, getState, httpClient) => {

        /* DTO 参数变换 */
        let tmpDto = _.cloneDeep(maintainReportDto);
        // 创建时间
        tmpDto.createTime = maintainReportDto.createTime ? new Date(maintainReportDto.createTime) : undefined;
        // 关联设备ID
        tmpDto.deviceId = getState().devices.currentDevice.deviceId;
        // 关联设备文档ID
        tmpDto.docId = maintainReportDto.docId.value ? maintainReportDto.docId.value : undefined;
        // 下次检测时间
        tmpDto.nextTime = maintainReportDto.nextTime ? new Date(maintainReportDto.nextTime) : undefined;
        // 备注
        tmpDto.opentext = maintainReportDto.opentext ? maintainReportDto.opentext : undefined;
        // 工单ID
        tmpDto.orderId = maintainReportDto.orderId ? _.toInteger(maintainReportDto.orderId) : undefined;
        // 更新时间
        tmpDto.updateTime = maintainReportDto.updateTime ? new Date(maintainReportDto.updateTime) : undefined;

        return httpClient
            .post(porxyApi + `/devices/maintainreport`, tmpDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    // 插入成功后......
                    dispatch(createhttpMessage({ status: 200 }, '创建检测报告成功'))
                    // 重新检索列表
                    dispatch(getMaintainReportList({ deviceId: getState().devices.currentDevice.deviceId }))
                    // 重新检索 - 可用于关联检测报告的工单列表
                    dispatch(getAvailableWorkOrderList(getState().devices.currentDevice.deviceId))
                    // 重新检索 - 可用于关联检测报告的文档列表
                    dispatch(getAvailableDeviceDocList(getState().devices.currentDevice.deviceId))
                    // 返回更新结果
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建检测报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

// 下拉列表选项初始化 -- 关联工单
export const initAvailableWorkOrderList = createAction('@@xcloud/devices/initAvailableWorkOrderList',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getAvailableWorkOrderList(getState().devices.currentDevice.deviceId))
        ]).then(() => {
        })
            .catch(err => {
                let msg = '初始化关联工单列表信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

// 页面初始化 -- 获取检测报告列表
export const initMaintainReportList = createAction('@@xcloud/devices/initMaintainReportList',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getMaintainReportList({ deviceId: getState().devices.currentDevice.deviceId }))
        ]).then(() => {
        })
            .catch(err => {
                let msg = '初始化设备检测报告信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    });

// 页面行更新：检测报告
export const updateMaintainReportRow = createAction('@@xcloud/devices/updateMaintinReportRow',
    (rowData) => (dispatch, getState) => {
        dispatch(updateMaintainReport(rowData))
    }
);

// 页面行追加：检测报告
export const addMaintainReportRow = createAction('@@xcloud/devices/addMaintainReportRow',
    (rowData) => (dispatch, getState) => {
        dispatch(addMaintainReport(rowData))
    }
);

// 页面行删除：检测报告（逻辑删除）
export const deleteMaintainReportRow = createAction('@@xcloud/devices/updateMaintinReportRow',
    (rowData) => (dispatch, getState) => {
        // 逻辑删除 处理
        let tmpDto = _.cloneDeep(rowData)
        tmpDto.status = 'inactive'
        dispatch(updateMaintainReport(tmpDto))
    }
);


/*** △ 需求 设备检测报告 SBGL-280 △ ***/

export const setDeviceList = createAction('@@xcloud/devices/setDeviceList');

export const setUnitDeviceList = createAction('@@xcloud/devices/setUnitDeviceList');

export const setAreaDeviceList = createAction('@@xcloud/devices/setAreaDeviceList');

export const setSearch = createAction('@@xcloud/devices/setSearch');

export const setDeviceBaseChange = createAction('@@xcloud/devices/setDeviceBaseChange');

export const setDeviceRunParamChange = createAction('@@xcloud/devices/setDeviceRunParamChange');

export const setDeviceTechParamChange = createAction('@@xcloud/devices/setDeviceTechParamChange');

export const setCurrentDevice = createAction('@@xcloud/devices/setCurrentDevice');

export const setCurrentDeviceProcess = createAction('@@xcloud/devices/setCurrentDeviceProcess');

export const setDeviceAsset = createAction('@@xcloud/devices/setDeviceAsset');

export const setDeviceView = createAction('@@xcloud/devices/setDeviceView');

export const setDeviceError = createAction('@@xcloud/devices/setDeviceError');

export const setDeviceAssetError = createAction('@@xcloud/devices/setDeviceAssetError');

export const setTechParamTemp = createAction('@@xcloud/devices/setTechParamTemp');

export const setTechParam = createAction('@@xcloud/devices/setTechParam');

export const setRunParamTemp = createAction('@@xcloud/devices/setRunParamTemp');

export const setRunParam = createAction('@@xcloud/devices/setRunParam');

export const setTableTechParam = createAction('@@xcloud/devices/setTableTechParam');

export const setTableRunParam = createAction('@@xcloud/devices/setTableRunParam');

export const setTechParamWarning = createAction('@@xcloud/devices/setTechParamWarning');

export const setRunParamWarning = createAction('@@xcloud/devices/setRunParamWarning');

export const setDeviceAssetChange = createAction('@@xcloud/devices/setDeviceAssetChange');

export const setDeviceBuild = createAction('@@xcloud/devices/setDeviceBuild');

export const setDeviceBuildError = createAction('@@xcloud/devices/setDeviceBuildError');

export const setDeviceBuildChange = createAction('@@xcloud/devices/setDeviceBuildChange');

export const setDeviceDesign = createAction('@@xcloud/devices/setDeviceDesign');

export const setDeviceDesignError = createAction('@@xcloud/devices/setDeviceDesignError');

export const setDeviceDesignChange = createAction('@@xcloud/devices/setDeviceDesignChange');

export const setDeviceDoc = createAction('@@xcloud/devices/setDeviceDoc');

export const setDeviceDocPage = createAction('@@xcloud/devices/setDeviceDocPage');

export const setDeviceDocPageError = createAction('@@xcloud/devices/setDeviceDocPageError');

/**
 * 控制不受设备表单影响运行、技术参数、部件和文档等【日常维护】模块的状态
 */
export const setDeviceEdit = createAction('@@xcloud/devices/setDeviceEdit');

export const stopLoading = createAction('@@xcloud/devices/stopLoading');

export const startLoading = createAction('@@xcloud/devices/startLoading');

export const setAccessoryDeviceId = createAction('@@xcloud/devices/setAccessoryDeviceId');

export const setDeviceCatChange = createAction('@@xcloud/devices/setDeviceCatChange');

export const setSearchUrl = createAction('@@xcloud/devices/setSearchUrl');

// 设置 - 检测报告列表
export const setMaintainReportList = createAction('@@xcloud/devices/setMaintainReportList');

// 设置 - 检测报告列表 Changed Flag
export const setMaintainReportListChangeFlg = createAction('@@xcloud/devices/setMaintainReportListChangeFlg');

// 设置 - 可用于关联检测报告的工单列表
export const setAvailableWorkOrderList = createAction('@@xcloud/devices/setAvailableWorkOrderList');

// 设置 - 检测报告列表分页条数
export const setPageSize = createAction('@@xcloud/devices/setPageSize');

// 设置 - 可用于关联检测报告的设备文档列表
export const setAvailableDeviceDocList = createAction('@@xcloud/devices/setAvailableDeviceDocList');

// 设置 - 班组列表
export const setDepartmentList = createAction('@@xcloud/devices/setDepartmentList');

// 设置 - “位号” 在页面的显示Title
export const setTagNoTitle = createAction('@@xcloud/devices/setTagNoTitle');

export const setDeviceVehicleInfo = createAction('@@xcloud/devices/setDeviceVehicleInfo');
export const setDeviceVehicleInfoError = createAction('@@xcloud/devices/setDeviceVehicleInfoError');
export const setDeviceVehicleInfoChange = createAction('@@xcloud/devices/setDeviceVehicleInfoChange');

export const setTransferOpen = createAction('@@xcloud/devices/setTransferOpen');
export const setMediumOpen = createAction('@@xcloud/devices/setMediumOpen');
export const setCurrentMedia = createAction('@@xcloud/devices/setCurrentMedia');



export const insertDeviceVehicleInfo = createAction(
    '@@xcloud/devices/insertDeviceVehicleInfo',
    (deviceVehicleInfo) => (dispatch, getState, httpClient) => {
        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.infoId = 0
        dto.deviceId = deviceId
        dto.vtype = deviceVehicleInfo.vtype ? deviceVehicleInfo.vtype : undefined
        dto.engineCode = deviceVehicleInfo.engineCode ? deviceVehicleInfo.engineCode : undefined
        dto.frameCode = deviceVehicleInfo.frameCode ? deviceVehicleInfo.frameCode : undefined
        dto.buyTime = deviceVehicleInfo.buyTime ? deviceVehicleInfo.buyTime : undefined
        dto.vweight = deviceVehicleInfo.vweight ? _.toNumber(deviceVehicleInfo.vweight) : undefined
        dto.vheight = deviceVehicleInfo.vheight ? _.toNumber(deviceVehicleInfo.vheight) : undefined
        dto.vlength = deviceVehicleInfo.vlength ? _.toNumber(deviceVehicleInfo.vlength) : undefined
        dto.vwidth = deviceVehicleInfo.vwidth ? _.toNumber(deviceVehicleInfo.vwidth) : undefined
        dto.registTime = deviceVehicleInfo.registTime ? deviceVehicleInfo.registTime : undefined
        dto.vcolor = deviceVehicleInfo.vcolor ? deviceVehicleInfo.vcolor : undefined
        dto.loadNo = deviceVehicleInfo.loadNo ? _.toInteger(deviceVehicleInfo.loadNo) : undefined
        dto.licenseNo = deviceVehicleInfo.licenseNo ? deviceVehicleInfo.licenseNo : undefined
        dto.emissionLevel = deviceVehicleInfo.emissionLevel ? deviceVehicleInfo.emissionLevel : undefined
        dto.insCycle = deviceVehicleInfo.insCycle ? _.toInteger(deviceVehicleInfo.insCycle) : undefined
        dto.vphoto = deviceVehicleInfo.vphoto ? deviceVehicleInfo.vphoto.value : undefined

        return httpClient
            .post(porxyApi + `/devices/vehicleinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newDevice = {
                        ...getState().devices.deviceVehicleInfo,
                        assetId: res.data.infoId,
                    };
                    dispatch(setDeviceVehicleInfo(newDevice))
                    dispatch(createhttpMessage({ status: 200 }, '创建设备车辆信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建设备车辆信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyDeviceVehicleInfo = createAction(
    '@@xcloud/devices/modifyDeviceVehicleInfo',
    (deviceVehicleInfo) => (dispatch, getState, httpClient) => {

        let deviceId = getState().devices.currentDevice.deviceId
        let dto = {}
        dto.deviceId = deviceId
        dto.infoId = deviceVehicleInfo.infoId
        dto.vtype = deviceVehicleInfo.vtype ? deviceVehicleInfo.vtype : undefined
        dto.engineCode = deviceVehicleInfo.engineCode ? deviceVehicleInfo.engineCode : undefined
        dto.frameCode = deviceVehicleInfo.frameCode ? deviceVehicleInfo.frameCode : undefined
        dto.buyTime = deviceVehicleInfo.buyTime ? new Date(deviceVehicleInfo.buyTime) : undefined
        dto.vweight = deviceVehicleInfo.vweight ? _.toNumber(deviceVehicleInfo.vweight) : undefined
        dto.vheight = deviceVehicleInfo.vheight ? _.toNumber(deviceVehicleInfo.vheight) : undefined
        dto.vlength = deviceVehicleInfo.vlength ? _.toNumber(deviceVehicleInfo.vlength) : undefined
        dto.vwidth = deviceVehicleInfo.vwidth ? _.toNumber(deviceVehicleInfo.vwidth) : undefined
        dto.registTime = deviceVehicleInfo.registTime ? new Date(deviceVehicleInfo.registTime) : undefined
        dto.vcolor = deviceVehicleInfo.vcolor ? deviceVehicleInfo.vcolor : undefined
        dto.loadNo = deviceVehicleInfo.loadNo ? _.toInteger(deviceVehicleInfo.loadNo) : undefined
        dto.licenseNo = deviceVehicleInfo.licenseNo ? deviceVehicleInfo.licenseNo : undefined
        dto.emissionLevel = deviceVehicleInfo.emissionLevel ? deviceVehicleInfo.emissionLevel : undefined
        dto.insCycle = deviceVehicleInfo.insCycle ? _.toInteger(deviceVehicleInfo.insCycle) : undefined
        dto.vphoto = deviceVehicleInfo.vphoto ? deviceVehicleInfo.vphoto.value : undefined

        return httpClient
            .patch(porxyApi + `/devices/vehicleinfo`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新设备车辆信息成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新设备车辆信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const devicesReducer = handleActions(
    {
        [getDeviceUseStatus]: (state, { payload: value }) => {
            return {
                ...state,
                useStatus: value.list,
                useStatusTree: value.tree
            };
        },

        [getDeviceValtype]: (state, { payload: value }) => {
            return {
                ...state,
                valtype: value.list,
                valtypeTree: value.tree
            };
        },
        [getDeviceDoctype]: (state, { payload: value }) => {
            return {
                ...state,
                doctype: value.list,
                doctypeTree: value.tree
            };
        },
        [getDeviceUnit]: (state, { payload: value }) => {
            return {
                ...state,
                unit: value.list,
                unitTree: value.tree
            };
        },
        [getDeviceErpSrc]: (state, { payload: value }) => {
            return {
                ...state,
                erpsrc: value.list,
                erpsrcTree: value.tree
            };
        },
        [getDeviceErpUnit]: (state, { payload: value }) => {
            return {
                ...state,
                erpunit: value.list,
                erpunitTree: value.tree
            };
        },
        [getDeviceErpCat]: (state, { payload: value }) => {
            return {
                ...state,
                erpcat: value.list,
                erpcatTree: value.tree
            };
        },
        [getDeviceErpStatus]: (state, { payload: value }) => {
            return {
                ...state,
                erpstatus: value.list,
                erpstatusTree: value.tree
            };
        },
        [getDeviceErpDisposal]: (state, { payload: value }) => {
            return {
                ...state,
                erpdisposal: value.list,
                erpdisposalTree: value.tree
            };
        },
        [getDeviceLogic]: (state, { payload: value }) => {
            return {
                ...state,
                logic: value.list,
                logicTree: value.tree
            };
        },
        [getDeviceMedium]: (state, { payload: value }) => {
            return {
                ...state,
                medium: value.list,
                mediumTree: value.tree
            };
        },
        [getDeviceMediumPhase]: (state, { payload: value }) => {
            return {
                ...state,
                mediumPhase: value
            };
        },
        [getDeviceProCat]: (state, { payload: value }) => {
            return {
                ...state,
                procat: value.list,
                procatTree: value.tree
            };
        },
        [getDeviceMgtCat]: (state, { payload: value }) => {
            return {
                ...state,
                mgtcat: value.list,
                mgtcatTree: value.tree
            };
        },
        [getDeviceExtCat]: (state, { payload: value }) => {
            return {
                ...state,
                extcat: value.list,
                extcatTree: value.tree
            };
        },

        [setDeviceList]: (state, { payload: value }) => {
            return {
                ...state,
                devicelist: value
            };
        },

        [setUnitDeviceList]: (state, { payload: value }) => {
            return {
                ...state,
                unitDeviceList: value
            };
        },

        [setAreaDeviceList]: (state, { payload: value }) => {
            return {
                ...state,
                areaDeviceList: value
            };
        },

        [getDeviceList]: (state, { payload: value }) => {
            return {
                ...state,
                searchDevicelist: value
            };
        },
        [getDevices]: (state, { payload: value }) => {
            return {
                ...state,
                searchDevicelist: value
            };
        },
        [getDeviceLegends]: (state, { payload: value }) => {
            return {
                ...state,
                deviceLegends: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setDeviceBaseChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceBaseChange: value
            };
        },
        [setDeviceRunParamChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceRunParamChange: value
            };
        },
        [setDeviceTechParamChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceTechParamChange: value
            };
        },
        [setCurrentDevice]: (state, { payload: value }) => {
            return {
                ...state,
                currentDevice: value
            };
        },
        [setCurrentDeviceProcess]: (state, { payload: value }) => {
            return {
                ...state,
                currentDeviceProcess: value
            };
        },
        [setDeviceAsset]: (state, { payload: value }) => {
            return {
                ...state,
                deviceAsset: value
            };
        },
        [setDeviceView]: (state, { payload: value }) => {
            return {
                ...state,
                view: value
            };
        },
        [setDeviceError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceError: value
            };
        },
        [setDeviceAssetError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceAssetError: value
            };
        },
        [setTechParamTemp]: (state, { payload: value }) => {
            return {
                ...state,
                techParamTemp: value
            };
        },
        [setRunParamTemp]: (state, { payload: value }) => {
            return {
                ...state,
                runParamTemp: value
            };
        },
        [setTechParam]: (state, { payload: value }) => {
            return {
                ...state,
                techParam: value
            };
        },
        [setRunParam]: (state, { payload: value }) => {
            return {
                ...state,
                runParam: value
            };
        },
        [setTableTechParam]: (state, { payload: value }) => {
            return {
                ...state,
                tableTechParam: value
            };
        },
        [setTableRunParam]: (state, { payload: value }) => {
            return {
                ...state,
                tableRunParam: value
            };
        },
        [setTechParamWarning]: (state, { payload: value }) => {
            return {
                ...state,
                techParamWarning: value
            };
        },
        [setRunParamWarning]: (state, { payload: value }) => {
            return {
                ...state,
                runParamWarning: value
            };
        },
        [setDeviceAssetChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceAssetChange: value
            };
        },
        [setDeviceBuild]: (state, { payload: value }) => {
            return {
                ...state,
                deviceBuild: value
            };
        },
        [setDeviceBuildError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceBuildError: value
            };
        },
        [setDeviceBuildChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceBuildChange: value
            };
        },
        [setDeviceDesign]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDesign: value
            };
        },
        [setDeviceDesignError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDesignError: value
            };
        },
        [setDeviceDesignChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDesignChange: value
            };
        },
        [setDeviceDoc]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDoc: value
            };
        },
        [setDeviceDocPage]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDocPage: value
            };
        },
        [setDeviceDocPageError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceDocPageError: value
            };
        },
        [setDeviceEdit]: (state, { payload: value }) => {
            return {
                ...state,
                deviceEdit: value
            };
        },
        [startLoading]: (state) => {
            return {
                ...state,
                isLoading: true
            };
        },
        [stopLoading]: (state) => {
            return {
                ...state,
                isLoading: false
            };
        },
        [setAccessoryDeviceId]: (state, { payload: value }) => {
            return {
                ...state,
                accessoryDeviceId: value
            };
        },
        [setDeviceCatChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceCatChange: value
            };
        },
        [setSearchUrl]: (state, { payload: value }) => {
            return {
                ...state,
                searchUrl: value
            };
        },
        // 设置 - 检测报告列表
        [setMaintainReportList]: (state, { payload: value }) => {
            return {
                ...state,
                maintainReportList: value
            };
        },
        // 设置 - 检测报告列表Changed Flag
        [setMaintainReportListChangeFlg]: (state, { payload: value }) => {
            return {
                ...state,
                maintainReportListChangeFlg: value
            };
        },
        // 设置 - 可用于关联检测报告的工单列表
        [setAvailableWorkOrderList]: (state, { payload: value }) => {
            return {
                ...state,
                availableWorkOrderList: value
            };
        },
        // 设置 - 检测报告列表分页条数
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        // 设置 - 可用于关联检测报告的设备列表
        [setAvailableDeviceDocList]: (state, { payload: value }) => {
            return {
                ...state,
                availableDeviceDocList: value
            };
        },
        // 设置 - 班组列表
        [setDepartmentList]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
        // 设置 - “位号” 在页面的显示Title
        [setTagNoTitle]: (state, { payload: value }) => {
            return {
                ...state,
                tagNoTitle: value
            };
        },
        [setDeviceVehicleInfo]: (state, { payload: value }) => {
            return {
                ...state,
                deviceVehicleInfo: value
            };
        },
        [setDeviceVehicleInfoError]: (state, { payload: value }) => {
            return {
                ...state,
                deviceVehicleInfoError: value
            };
        },
        [setDeviceVehicleInfoChange]: (state, { payload: value }) => {
            return {
                ...state,
                deviceVehicleInfoChange: value
            };
        },
        [setTransferOpen]: (state, { payload: value }) => {
            return {
                ...state,
                transferOpen: value
            };
        },

        [setMediumOpen]: (state, { payload: value }) => {
            return {
                ...state,
                mediumOpen: value
            };
        },
        [setCurrentMedia]: (state, { payload: value }) => {
            return {
                ...state,
                currentMedia: value
            };
        },

    },
    initialState
);

export default devicesReducer;