import React from 'react';
import {
    Grid,
    Typography,
    TextField,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import TreeSelect from 'rc-tree-select';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';


const AmoebaReportFixedComponent = props => {
    const { classes,
        reportFixeds,
        selectedDate,
        companies,
        onhandleDateChange,
        onhandleUpdate,
        onhandleDel,
        onhandlePopAdd,
        departments,
        currentUserInfo,
        // indexes,
        subTree,
    } = props;
    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }


    const tableStyle = {
        ...style
    }

    const getCompanyName = (companyId) => {
        const company = _.find(companies, c => c.companyId === companyId)
        return company ? company.companyName : ''
    }

    const getDptName = (dptId) => {
        const dpt = _.find(departments, d => d.dptId === dptId)
        return dpt ? dpt.dptName : ''
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '公司',
                field: 'companyId',
                width: 100,
                render: row => <Typography>{getCompanyName(row.companyId)}</Typography>,
                editable: 'never',
                emptyValue: getCompanyName(currentUserInfo.companyId),
            },
            {
                title: '班组',
                field: 'dptId',
                width: 100,
                render: row => <Typography>{getDptName(row.dptId)}</Typography>,
                validate: rowData => {
                    if (!rowData.dptId || rowData.dptId === '') {
                        return { isValid: false, helperText: '班组不能为空' }
                    } else {
                        return true
                    }
                },
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.dptId ? props.rowData.dptId : ''}
                        name='dptId'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {
                            _.filter(departments, (d) => {
                                return d.companyId === currentUserInfo.companyId
                            }).map(data => (
                                <option value={data.dptId} key={data.dptId}>{data.dptName}</option>
                            ))
                        }
                    </TextField>
                )
            },
            {
                title: '指标',
                field: 'sub',
                width: 150,
                render: row => <Typography>{row.sub.label || ''}</Typography>,
                validate: rowData => {
                    if (!rowData.sub || !rowData.sub.value || rowData.sub.value === '') {
                        return { isValid: false, helperText: '指标不能为空' }
                    } else {
                        return true
                    }
                },
                editComponent: (props) => (
                    <TreeSelect
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                        treeLine
                        value={props.rowData.sub ? props.rowData.sub : { label: '', value: '' }}
                        style={{
                            marginTop: 5,
                            width: 180,
                        }}
                        labelInValue
                        treeData={subTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={(value) => props.onChange(value)}
                    />
                )
            },
            {
                title: '偏差（元）',
                field: 'delta',
                width: 100,
                render: row => <Typography>{row.delta + '元'}</Typography>,
                validate: rowData => {
                    if (!rowData.delta || rowData.delta === '') {
                        return { isValid: false, helperText: '偏差不能为空' }
                    } else {
                        return true
                    }
                },
            },
            {
                title: '备注',
                field: 'opentext',
                align: 'center',
                width: 350,
                render: row => <Typography>{row.opentext}</Typography>,
            },
        ]
        return columns

    }

    const assemblyData = () => {
        console.log(reportFixeds)
        return reportFixeds
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer} item xs={12}>
                    <Grid className={classes.button} item xs={3} >
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    id="date-selector"
                                    margin="normal"
                                    value={selectedDate}
                                    onChange={onhandleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        editable={
                            {
                                onRowUpdate: (newData, oldData) => onhandleUpdate(newData, oldData),
                                onRowDelete: (oldData) => onhandleDel(oldData.fixedId),
                                onRowAdd: (rowData) => onhandlePopAdd(rowData),
                            }
                        }
                        title={<Typography variant="h3">阿米巴偏差报表</Typography>}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

AmoebaReportFixedComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companies: PropTypes.array,
};

AmoebaReportFixedComponent.defaultProps = {
    reports: [],
    reportFixeds: [],
};

export default withStyles(AmoebaReportFixedComponent);