import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    InputAdornment,
    MenuItem,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';

const CreateAActivityRecordComponent = props => {
    const {
        classes,

        isShow,
        componentTitle,
        open,
        mactivityRecord,
        companies,
        payDpts,
        payDisable,
        isediting,
        onhandleClose,
        onhandleSave,
        onhandleTextFieldChange,
        onhandleUpdateActionRow,
        onhandleDateChange,
    } = props;

    const tableLocalization = {
        ...localization,
        pagination: {},
        toolbar: {}
    }

    const tableOptions = {
        ...options,
        minBodyHeight: 120,
        maxBodyHeight: 250,
        pageSize: 5,
        pageSizeOptions: [5],
        pagination: false,
    }
    const tableStyle = {
        ...style,
        border: '1px solid rgb(183,183,183)',
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'attrName',
                render: row => <Typography>{row.attrName}</Typography>,
                editable: 'never'
            },
            {
                title: '别名',
                field: 'attrAlias',
                render: row => <Typography>{row.attrAlias}</Typography>
            },
            {
                title: '属性值',
                field: 'attrValue',
                render: row => <Typography>{row.attrValue}</Typography>
            },
            {
                title: '单位',
                field: 'attrUnit',
                render: row => <Typography>{row.attrUnit}</Typography>
            },

        ]
        return columns
    }

    const assemblyData = () => {
        return mactivityRecord.recordAttrs;
    }

    const textFieldDefault = {
        style: {
            width: '180px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{componentTitle}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column" spacing={35}>
                        <Grid>
                            <Grid container>
                                {/* <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={isActivityEditing}
                                        variant="outlined"
                                        placeholder=""
                                        label='任务活动'
                                        name='taskId'
                                        InputProps={textFieldDefault}
                                        value={mactivityRecord.taskId}
                                        onChange={onhandleTextFieldChange} >
                                        {userTasks.map(i => (<MenuItem key={i.taskId} value={i.taskId}>{i.activityName}</MenuItem>))}
                                    </TextField>
                                </Grid> */}
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                        label='关联服务'
                                        disabled
                                        name='serviceName'
                                        InputProps={textFieldDefault}
                                        value={mactivityRecord.manufactureService.serviceName}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                        label='关联工单号'
                                        disabled
                                        name='orderNo'
                                        InputProps={textFieldDefault}
                                        value={mactivityRecord.manufactureOrder.orderNo}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        placeholder=""
                                        label='需方'
                                        disabled={isediting || isShow}
                                        name='payCompany'
                                        value={mactivityRecord.payCompany}
                                        InputProps={textFieldDefault}
                                        onChange={onhandleTextFieldChange} >
                                        {companies.map(i => (<MenuItem key={i.companyId} value={i.companyId}>{i.companyName}</MenuItem>))}
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        placeholder=""
                                        label='需方班组'
                                        name='payDpt'
                                        disabled={payDisable || isShow}
                                        value={mactivityRecord.payDpt}
                                        InputProps={textFieldDefault}
                                        onChange={onhandleTextFieldChange} >
                                        {payDpts.map(i => (<MenuItem key={i.dptId} value={i.dptId}>{i.dptName}</MenuItem>))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid container>
                                <Grid className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDateTimePicker
                                            fullWidth
                                            autoOk
                                            ampm={false}
                                            inputVariant="outlined"
                                            format="yyyy/MM/dd HH:mm"
                                            id="date-selector"
                                            margin="normal"
                                            label='执行时间'
                                            disabled={isediting || isShow}
                                            InputProps={textFieldDefault}
                                            value={mactivityRecord.triggerTime}
                                            onChange={onhandleDateChange}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            name='triggerTime'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid>
                            <Grid container>
                                <Grid item xs={12}> <Divider /></Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="column" >
                                        <Grid item className={classes.itemGrid}>
                                            <MaterialTable
                                                columns={assemblycolumns()}
                                                data={assemblyData()}
                                                options={tableOptions}
                                                localization={tableLocalization}
                                                style={tableStyle}
                                                title='活动属性列表'
                                                editable={
                                                    {
                                                        isEditHidden: rowData => isShow || rowData.attrPermission === 'R',
                                                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {

                                                            if (oldData.attrPermission !== 'R') {
                                                                onhandleUpdateActionRow(newData, oldData);
                                                            }
                                                            resolve()
                                                        })
                                                    }
                                                }
                                            >
                                            </MaterialTable>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                                <Grid item xs={5} >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !isShow && <Button onClick={onhandleSave} color="primary"> 保存
                        </Button>
                    }
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreateAActivityRecordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateAActivityRecordComponent.defaultProps = {
    open: false,
    payDisable: true,
    isediting: true,
    isShow: false,
    recevieDisable: true,
    participateDisable: true,
    installs: [],
    companies: [],
    payDpts: [],
    userTasks: [],
    mactivityRecord: {},
    manufactureDicts: []
};

export default withStyles(CreateAActivityRecordComponent);