import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    Radio,
    Button,
    MenuItem,
    Card,
    RadioGroup,
    InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getDateByTimestamp } from '@/utils/datetime'
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';

const DepreciationRuleComponent = props => {
    const { classes,
        companies,
        installations,
        userProfile,
        rules,
        companyId,
        editRule,
        isEditMode,
        diableCompany,
        dicts,
        onhandleCompanyChange,
        onhandleInstallChange,
        onEdit,
        onCreate,
        onDelete,
        onSave,
        onClose,
        onSelectDevice,
        onhandleTargetChange,
        onSelectRule,
        onhandleChange,
        onDateChange
    } = props;

    const tableStyle = {
        ...style,
    }

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        toolbar: true,
        sorting: true
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'amoeba_depreciation_rule' })
        return permission && permission.action === 'W'
    }

    const renderRuleCode = (code) => {
        let element = _.find(dicts, (o) => {
            return o['type'] === 'DEP_RULE' && o['code'] === code
        })
        return element !== undefined ? element['name'] : '';
    }

    const renderTarget = (target) => {
        if (target === 'DEV') {
            return "设备";
        } else if (target === 'INSTALL') {
            return "装置"
        } else {
            return "其他"
        }
    }

    const renderRule = (data) => {
        if (data.ruleCode === 'DEP_AVG') {
            return "总折算周期: " + data.cycle + "月, 按原值/总周期进行平均折旧计算"
        } else if (data.ruleCode === 'DEP_HOUR') {
            return "每小时折旧金额为" + data.val1 + "元, 一天按" + data.val2 + "小时计算"
        } else if (data.ruleCode === 'DEP_VOLUME') {
            return "每" + data.val1 + "单位生产量折旧" + data.val2 + "元"
        }
        return '';
    }

    const renderCompany = (cid) => {
        var c = _.find(companies, (o) => { return o.companyId === cid });
        return c ? c.companyAlias : ''
    }

    const assemblyDetailColumns = () => {

        const columns = [
            {
                title: '所属单位',
                field: 'companyId',
                render: row => <Typography>{renderCompany(row.companyId)}</Typography>,
                editable: 'never'
            },
            {
                title: '资产类型',
                field: 'target',
                render: row => <Typography>{renderTarget(row.target)}</Typography>,
                editable: 'never'
            },
            {
                title: '资产名称',
                field: 'targetName',
                render: row => <Typography>{row.targetName}</Typography>,
                editable: 'never'
            },
            {
                title: '折旧方式',
                field: 'ruleCode',
                width: 150,
                render: row => <Typography>{renderRuleCode(row.ruleCode)}</Typography>,
                editable: 'never'
            },
            {
                title: '原值(元)',
                field: 'faVal',
                type: 'numeric',
                render: row => <Typography>{row.faVal ? row.faVal.toFixed(2) : 0}</Typography>,
                editable: 'never'
            },
            {
                title: '净值(元)',
                field: 'faNet',
                type: 'numeric',
                render: row => <Typography>{row.faNet ? row.faNet.toFixed(2) : 0}</Typography>,
                editable: 'never'
            },
            {
                title: '计算规则描述',
                field: 'cycle',
                width: 360,
                render: row => <Typography>{renderRule(row)}</Typography>,
                editable: 'never'
            }, {
                title: '折旧开始时间',
                field: 'startTime',
                render: row => <Typography>{getDateByTimestamp(row.startTime, false)}</Typography>,
                editable: 'never'
            }, {
                title: '更新时间',
                field: 'createTime',
                render: row => <Typography>{getDateByTimestamp(row.createTime, false)}</Typography>,
                editable: 'never'
            }, {
                title: '更新人',
                field: 'creatorName',
                render: row => <Typography>{row.creatorName}</Typography>,
                editable: 'never'
            },
        ]

        return columns
    }

    const renderTargetName = () => {
        if (isEditMode === 1) {
            if (editRule.target === 'DEV') {
                return (<TextField
                    style={{ width: '90%' }}
                    value={editRule.targetName || ''}
                    variant="standard"
                    placeholder="点击选择设备"
                    onClick={event => onSelectDevice(event)}
                />);
            } else if (editRule.target === 'INSTALL') {
                return (<TextField
                    style={{ width: '90%' }}
                    select
                    name='targetName'
                    placeholder="点击选择装置"
                    onChange={onhandleInstallChange}
                    value={editRule.targetId}
                >
                    {_.filter(installations, (i) => { return i.companyId === companyId }).map(ele => (
                        <MenuItem key={ele.installId} value={ele.installId}>{ele.installName}</MenuItem>
                    ))}
                </TextField>);
            } else {
                return (
                    <TextField
                        style={{ width: '90%' }}
                        variant="standard"
                        placeholder="输入资产名称"
                        value={editRule.targetName || ''}
                        name='targetName'
                        onChange={event => onhandleChange(event, 'targetName')}
                    />
                );
            }
        } else {
            return (<Typography >
                {editRule.targetName}
            </Typography>);
        }
    }

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                {isEditMode === 0 ? <div> <div>{!diableCompany ?
                    <Grid container className={classes.gridContainer}>
                        <Grid item className={classes.companySelect}>
                            <TextField
                                fullWidth
                                select
                                name='companyId'
                                onChange={onhandleCompanyChange}
                                value={companyId}
                            >
                                {companies.map(ele => (
                                    <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid> : null
                }
                </div>

                    <div className={classes.detailTable}>
                        <MaterialTable
                            columns={assemblyDetailColumns()}
                            data={rules}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={tableStyle}
                            title={<Typography variant='h3'>资产折旧规则</Typography>}
                            actions={checkPermission() ? [
                                {
                                    icon: 'add',
                                    tooltip: '新建资产折旧规则',
                                    isFreeAction: true,
                                    onClick: (event, rowData) => {
                                        onCreate()
                                    }
                                },
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: '编辑',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData)
                                    }
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除',
                                    onClick: (event, rowData) => {
                                        onDelete(event, rowData)
                                    }
                                })
                            ] : []}
                        >
                        </MaterialTable>
                    </div></div>
                    : <div>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <Typography component={'span'} variant='h3'>资产折旧规则配置</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography >
                                    资产类型:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {isEditMode !== 2 ? <TextField
                                    style={{ width: '90%' }}
                                    select
                                    name='target'
                                    onChange={onhandleTargetChange}
                                    value={editRule.target}
                                >
                                    {[{ 'value': 'DEV', 'name': '设备' }, { 'value': 'INSTALL', 'name': '装置' }, { 'value': 'OTHER', 'name': '其他' }].map(ele => (
                                        <MenuItem key={ele.value} value={ele.value}>{ele.name}</MenuItem>
                                    ))}
                                </TextField> : <Typography >
                                    {_.find([{ 'value': 'DEV', 'name': '设备' }, { 'value': 'INSTALL', 'name': '装置' }, { 'value': 'OTHER', 'name': '其他' }]
                                        , (o) => { return o.value === editRule.target })['name']}
                                </Typography>}

                            </Grid>
                            <Grid item xs={1}>
                                <Typography >
                                    资产名称:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {renderTargetName()}
                            </Grid>
                            <Grid item xs={1}>
                                <Typography >
                                    原值:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {
                                    editRule.target === 'DEV' ? <Typography >
                                        {editRule.faVal ? editRule.faVal + "元" : ''}
                                    </Typography> : <TextField className={classes.textfield}
                                        variant="standard"
                                        style={{ width: '90%' }}
                                        value={editRule.faVal || 0}
                                        type="number"
                                        name='faVal'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">元</InputAdornment>,
                                        }}
                                        onChange={event => onhandleChange(event, 'faVal')}
                                    />
                                }
                            </Grid>
                            <Grid item xs={1}>
                                <Typography >
                                    净值:
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {
                                    editRule.target === 'DEV' ? <Typography >
                                        {editRule.faNet ? editRule.faNet + "元" : ''}
                                    </Typography> : <TextField className={classes.textfield}
                                        variant="standard"
                                        style={{ width: '90%' }}
                                        value={editRule.faNet || 0}
                                        type="number"
                                        name='faNet'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">元</InputAdornment>,
                                        }}
                                        onChange={event => onhandleChange(event, 'faNet')}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={0} alignItems="center" className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography >
                                    折旧开始时间:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        id="date-selector"
                                        margin="normal"
                                        value={editRule.startTime}
                                        onChange={onDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <RadioGroup aria-label="gender" name="gender1" value={editRule.ruleCode} onChange={onSelectRule}>
                            <Grid container direction="row" spacing={1} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Radio
                                        checked={editRule.ruleCode === 'DEP_AVG'}
                                        value="DEP_AVG"
                                        name="radio-dep-avg"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        按平均年限折旧:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography >总折算周期
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.textfield}
                                        variant="standard"
                                        disabled={editRule.ruleCode !== 'DEP_AVG'}
                                        value={editRule.cycle || 0}
                                        type="number"
                                        name='cycle'
                                        onChange={event => onhandleChange(event, 'cycle')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >月, 按原值/总周期进行平均折旧计算
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Radio
                                        checked={editRule.ruleCode === 'DEP_HOUR'}
                                        value="DEP_HOUR"
                                        name="radio-dep-hour"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        按小时折旧:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography >每小时折旧金额为
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.textfield}
                                        variant="standard"
                                        disabled={editRule.ruleCode !== 'DEP_HOUR'}
                                        value={editRule.val1 || 0}
                                        type="number"
                                        name='val1'
                                        onChange={event => onhandleChange(event, 'val1')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >元, 一天按
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.textfield}
                                        variant="standard"
                                        disabled={editRule.ruleCode !== 'DEP_HOUR'}
                                        value={editRule.val2 || 0}
                                        type="number"
                                        name='val2'
                                        onChange={event => onhandleChange(event, 'val2')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >小时计算
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid container direction="row" spacing={1} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Radio
                                        checked={editRule.ruleCode === 'DEP_VOLUME'}
                                        value="DEP_VOLUME"
                                        name="radio-dep-volume"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        按生产量折旧:
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography >每
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.textfield}
                                        variant="standard"
                                        value={editRule.val1 || 0}
                                        disabled={editRule.ruleCode !== 'DEP_VOLUME'}
                                        type="number"
                                        name='val1'
                                        onChange={event => onhandleChange(event, 'val1')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >单位生产量折旧
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.textfield}
                                        variant="standard"
                                        disabled={editRule.ruleCode !== 'DEP_VOLUME'}
                                        value={editRule.val2 || 0}
                                        type="number"
                                        name='val2'
                                        onChange={event => onhandleChange(event, 'val2')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography >元
                                </Typography>
                                </Grid>
                            </Grid> */}
                        </RadioGroup>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={2}>
                                <Button onClick={onSave} color="primary"> {'保存'}
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={onClose} color="primary"> {'关闭'}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>}
            </Card>
        </div >
    )
}

DepreciationRuleComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleCompanyChange: PropTypes.func
};

DepreciationRuleComponent.defaultProps = {
};

export default withStyles(DepreciationRuleComponent);