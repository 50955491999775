import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    detailtable: {
        padding: theme.spacing(2)
    },
    flexTextField: {
        margin: theme.spacing(2),
    },
    flexLine: {
        flexGrow: 1,
    },
});

export default withStyles(styles);