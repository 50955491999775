import React, { useState } from "react";
import {
    TextField
} from "@material-ui/core";
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { getStore } from '../../redux/store';
import { createmodal } from '../../redux/modules/modal';
import * as _ from 'lodash';

const MyDialog = props => {
    const { classes, } = props

    const [success, setSuccess] = useState(true)
    const [companyId, setCompanyId] = useState('')
    const [company, setCompany] = useState(true)
    const [group, setGroup] = useState(true)
    const [role, setRole] = useState(true)
    const [department, setDepartment] = useState(true)
    const [userName, setUserName] = useState(true)
    const [name, setName] = useState(true)

    const onhandleChange = (event) => {
        setCompanyId(_.toInteger(event.target.value))
    }

    const handlePanelChange = (type) => {
        switch (type) {
            case 'success':
                setSuccess(!success)
                break;
            case 'company':
                setCompany(!company)
                break;
            case 'group':
                setGroup(!group)
                break;
            case 'role':
                setRole(!role)
                break;
            case 'department':
                setDepartment(!department)
                break;
            case 'userName':
                setUserName(!userName)
                break;
            case 'name':
                setName(!name)
                break;
            default:
            // do nothing
        }
    };

    const onhandleOpenUserSelect = () => {
        let store = getStore();
        let param = {}

        if (companyId && companyId !== '') { param.companyId = companyId }
        if (company) { param.company = company }
        if (group) { param.group = group }
        if (role) { param.role = role }
        if (department) { param.department = department }
        if (userName) { param.userName = userName }
        if (name) { param.name = name }
        if (success) {
            param.callback = (res) => {
                return new Promise((resolve, reject) => {
                    console.log('callback success', res)
                    return resolve(true)
                })
            };
        } else {
            param.callback = () => {
                return new Promise((resolve, reject) => {
                    console.log('callback fail')
                    return reject('callbackFunc Error')
                })
            };
        }
        store.dispatch(createmodal('userSelect', param, 'medium'))
    }

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">选择需要添加的条件</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox size='small' checked={success} onChange={() => handlePanelChange('success')} name="success" />}
                        label="callback success"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={company} onChange={() => handlePanelChange('company')} name="company" />}
                        label="company"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={group} onChange={() => handlePanelChange('group')} name="group" />}
                        label="group"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={role} onChange={() => handlePanelChange('role')} name="role" />}
                        label="role"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={department} onChange={() => handlePanelChange('department')} name="department" />}
                        label="department"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={userName} onChange={() => handlePanelChange('userName')} name="userName" />}
                        label="userName"
                    />
                    <FormControlLabel
                        control={<Checkbox size='small' checked={name} onChange={() => handlePanelChange('name')} name="name" />}
                        label="name"
                    />
                </FormGroup>
            </FormControl>
            <div>
                <TextField
                    label="Company Id"
                    value={companyId}
                    placeholder="121 ~ 126"
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    onChange={event => onhandleChange(event)}
                />
            </div>
            <div>
                <Button onClick={onhandleOpenUserSelect} variant='contained'>
                    {'Open Userselect'}
                </Button>
            </div>
        </div >
    )
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(MyDialog);