import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'

const porxyApi = '/api/manufacture'

const initialState = {
    servicePlanConfig: {},
    servicePlanConfigs: [],
    openTempPlanConfig: false,
};


export const setServicePlanConfig = createAction('@@xcloud/manufacture/setServicePlanConfig');

export const setServicePlanConfigs = createAction('@@xcloud/manufacture/setServicePlanConfigs');

export const setOpenTempPlanConfig = createAction('@@xcloud/manufacture/setOpenTempPlanConfig');


export const initServicePlanConfig = createAction('@@xcloud/manufacture/initServicePlanConfig',
    (companyId) => (dispatch, getState, httpClient) => {
        return Promise.all([
            dispatch(getServicePlanConfigs(companyId, null, 'TEMP', null, null))
        ]).then((data) => {
            return data;
        }).catch(err => {
            let msg = '初始化化服务计划配置页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    });

export const createServicePlanConfig = createAction('@@xcloud/manufacture/createServicePlanConfig',
    (record) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/serviceplanconfigs', record)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加服务计划配置成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加服务计划配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateServicePlanConfig = createAction('@@xcloud/manufacture/updateServicePlanConfig',
    (config) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/serviceplanconfigs/' + config.configId, config)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新服务计划配置成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新服务计划配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteServicePlanConfig = createAction('@@xcloud/manufacture/deleteServicePlanConfig',
    (configId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/serviceplanconfigs/' + configId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除服务计划配置成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除服务计划配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getServicePlanConfigs = createAction('@@xcloud/manufacture/getServicePlanConfigs',
    (companyId, serviceId, planType, applyScope, status) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/serviceplanconfigs'
            let strs = []
            if (companyId || serviceId || planType || applyScope || status) {
                if (companyId) strs.push('companyId=' + companyId)
                if (serviceId) strs.push('serviceId=' + serviceId)
                if (planType) strs.push('planType=' + planType)
                if (applyScope) strs.push('applyScope=' + applyScope)
                if (status) strs.push('status=' + status)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setServicePlanConfigs(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取服务计划配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getServicePlanConfig = createAction('@@xcloud/manufacture/getServicePlanConfig',
    (configId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/serviceplanconfigs/' + configId
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setServicePlanConfig(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取服务计划配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const ServicePlanConfigReducer = handleActions(
    {
        [setServicePlanConfig]: (state, { payload: data }) => {
            return {
                ...state,
                servicePlanConfig: data
            }
        },
        [setServicePlanConfigs]: (state, { payload: data }) => {
            return {
                ...state,
                servicePlanConfigs: data
            }
        },
        [setOpenTempPlanConfig]: (state, { payload: data }) => {
            return {
                ...state,
                openTempPlanConfig: data
            }
        },

    },
    initialState
)

export default ServicePlanConfigReducer
