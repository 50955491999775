import React from 'react';
import {
    Grid, TextField, DialogActions, Button, Typography, Dialog, DialogTitle, DialogContent, Tabs,
    Tab,
    // InputAdornment,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { formatDate } from '@/utils/datetime';

// import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import zhCN from 'date-fns/locale/zh-CN';

const UpdatePickComponent = props => {
    const { classes,
        isShow,
        tabValue,
        pickInfo,
        componentTitle,
        onhandleSave,
        onhandleClose
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,

    }
    const tableStyle = {
        ...style
    }

    const pickDtlsColumns = () => {

        const columns = [
            {
                title: '物料',
                field: 'materialName',
                render: row => <Typography>{row.material.materialName}</Typography>

            }, {
                title: '规格',
                field: 'specification',
                render: row => <Typography>{row.material.specification}</Typography>
            },
            {
                title: '申请数量',
                field: 'appQty',
                align: 'right',
                render: row => <Typography>{row.appQty}</Typography>
            },
            {
                title: '退料数量',
                field: 'returnQty',
                align: 'right',
                render: row => <Typography>{row.returnQty}</Typography>
            },
            {
                title: '实发数量',
                field: 'actualQty',
                align: 'right',
                render: row => <Typography>{row.actualQty}</Typography>
            },
            // {
            //     title: '超发数量',
            //     field: 'allowoveQty',
            //     align: 'right',
            //     render: row => <Typography>{row.allowoveQty}</Typography>
            // },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit ? row.unit.unitName : ""}</Typography>
            },
            {
                title: '金额（元）',
                field: 'amount',
                render: row => <Typography>{row.amount ? row.amount : ""}</Typography>
            }
        ]

        return columns

    }


    // const textFieldDefault = {
    //     style: {
    //         width: '180px',
    //     },
    //     startAdornment: (
    //         <InputAdornment position="start">
    //             <p></p>
    //         </InputAdornment>)
    // }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={true} onClose={onhandleClose} className={classes.dialog}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{componentTitle}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='单据号'
                                        name='billNo'
                                        value={pickInfo.billNo}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='领料分厂'
                                        name='pickCompanyName'
                                        value={pickInfo.pickCompanyName}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='领料班组'
                                        name='pickDptName'
                                        value={pickInfo.pickDptName}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='领料时间'
                                        name='pickDate'
                                        value={pickInfo.pickDate ? formatDate(pickInfo.pickDate) : ''}
                                    >
                                    </TextField>
                                </Grid>

                                {/* <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            ampm={false}
                                            InputProps={{
                                                readOnly: true,
                                                style: {
                                                    padding: 'none',
                                                    marginTop: 0,
                                                },
                                            }}
                                            inputVariant="outlined"
                                            format="yyyy/MM/dd"
                                            id="date-selector"
                                            margin="normal"
                                            label='领料时间'
                                            value={pickInfo.pickDate}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            name='pickDate'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label={"领料明细"} className={classes.tab} />
                            </Tabs>
                        </Grid>
                        <MaterialTable
                            columns={pickDtlsColumns()}
                            data={pickInfo.pickDtls}
                            options={tableOptions}
                            localization={tableLocalization}
                            style={tableStyle}
                            title={<Typography variant='h4'>{"领料明细列表"}</Typography>}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !isShow && <Button onClick={onhandleSave} color="primary"> 保存
                        </Button>
                    }
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

UpdatePickComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

UpdatePickComponent.defaultProps = {
    tabValue: 0,
    componentTitle: "",
    pickInfo: {},
    orders: [],
    isShow: false
};

export default withStyles(UpdatePickComponent);