import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { getYear } from '../../../utils/datetime';
import CreateOperationGoalPlan from '../../../containers/DeviceManagement/AnnualPlan/CreateOperationGoalPlan';
import * as _ from 'lodash';

import { withRouter } from 'react-router';
import {
    Tabs,
    Tab,
    Grid,
    Card
} from '@material-ui/core';

const OperationAnnuaPlan = ({
    classes,
    onhandleChangeTab,
    tabValue,
    userProfile,
    pageLoading,
}) => {

    const checkPermission = () => {
        let rtn = false
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'leadership_operation_setup' })
        if (permission && permission.action) {
            rtn = true
        }
        return rtn
    }

    return (
        <Card alignitems="center" className={classes.root}>
            {pageLoading ? null :
                <React.Fragment>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={onhandleChangeTab}
                    >
                        <Tab label={"设备运维年度计划"} />
                        {checkPermission() ? <Tab label="拟定设备运维年度计划" /> : null}
                    </Tabs>
                    <Grid>
                        {tabValue === 0  &&
                            <Grid>
                                <Grid className={classes.planItem}>
                                    <CreateOperationGoalPlan year={getYear()} viewMode={true} />
                                </Grid>
                            </Grid>}
                        {tabValue === 1 &&
                            <Grid>
                                <Grid className={classes.planItem}>
                                    {/* TODO */}
                                    <CreateOperationGoalPlan year={getYear()} viewMode={false} />
                                </Grid>
                            </Grid>}
                    </Grid>
                </React.Fragment>
            }
        </Card>

    );
};
OperationAnnuaPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    pageLoading: PropTypes.bool.isRequired
};

OperationAnnuaPlan.defaultProps = {
}


export default withRouter(withStyles(OperationAnnuaPlan));
