import React from 'react';
import withStyles from './styles';
import {
  Grid,
  Button
} from '@material-ui/core';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';
import PropTypes from 'prop-types';

const WorkOrderFooterForPopup = props => {
  const {
    classes,
    onhandleCleanmodal
  } = props;

  return (
    <div>
      <Grid container spacing={0} className={classes.gridContainerForPopup} justify="flex-end">
        {/* <Button variant="contained" color="primary"
                onClick={event => onhandleConfirm()}
            >
                确定
             </Button> */}
        <Button variant="contained" color="primary"
          onClick={event => onhandleCleanmodal()}
        >
          关闭
        </Button>
      </Grid>
    </div>
  );
};

WorkOrderFooterForPopup.propTypes = {
  onhandleCleanmodal: PropTypes.func.isRequired
};

export default withStyles(WorkOrderFooterForPopup);
