import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateProcessLineApprovalComponent from '@/components/ProcessLineApproval/CreateProcessLineApproval';
import { initProcessLineWithUser, addStep, removeStep, changeUser, exitPage, setProcessesline, addNotice, removeNotice, setProcessesNotices } from '@/redux/modules/processlinewithuser';
import { getPredefinedProcess } from '@/redux/modules/predefinedProcess';
import { treeToArray, arrayToTree, getTreePartByIdUseKey } from '@/utils/constant';
import { cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import * as _ from 'lodash';
class CreateProcessLineApprovalContainer extends Component {
  static propTypes = {
    parameters: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      showSelect: false,
      selectType: 'USER',
      callback: null,
      companyId: [],
      useCompanyId: [],
      checkWorkOrderProcess: false,
      haveProcessSelect: false,
      showProcessList: false,
      selectNotice: false,
    }
  }

  componentWillMount() {
    if (this.props.parameters.companyId && this.props.parameters.companyId.length > 0) {
      this.setState({
        companyId: this.props.parameters.companyId,
        useCompanyId: this.props.parameters.companyId,
      })
    }
    if (this.props.parameters.checkWorkOrderProcess) {
      this.setState({
        checkWorkOrderProcess: true
      })
    }
    if (this.props.parameters.haveProcessSelect) {
      this.setState({
        haveProcessSelect: true
      })
    }
    this.props.onInitProcessLineWithUser(this.props.parameters.processTree ? this.props.parameters.processTree : [],
      this.props.parameters.processNotices ? this.props.parameters.processNotices : [], this.props.parameters.type ? this.props.parameters.type : 'list');
  }

  handleConfirm = () => {
    if (_.filter(treeToArray(_.cloneDeep(this.props.processesline)), function (o) { return !o.roleId }).length > 0) {
      this.props.onhadleMassgae('某些步骤还未指定人员或角色，无法保存')
    } else if (treeToArray(_.cloneDeep(this.props.processesline)).length === 1) {
      this.props.onhadleMassgae('审批流程不能只有一个审批节点，无法保存')
    } else {
      if (this.props.parameters.callback) {
        this.props.parameters.callback()
          .then(() => {
            // console.log('Promise sucess')
            this.props.onhandleCleanmodal()
          })
          .catch((e) => {
            // console.log(e)
          })
      } else {
        this.props.onhandleCleanmodal()
      }
    }
  };

  handleAddNotice = () => {

    this.setState({
      showSelect: true,
      selectNotice: true,
      callback: (data) => {
        this.props.onhandleAddnotice(data, this.callback)
      }
    })
  }

  handleAdd = (id) => {
    this.props.onhandleAddStep(id, this.callback)
  };

  handleRemove = (id, pid) => {
    this.props.onhandleRemoveStep(id, pid, this.callback)
  };

  handleRemoveNotice = (userId) => {
    this.props.onhandleRemoveNotice(userId, this.callback)
  };


  handleChangeUser = (id) => {
    const node = getTreePartByIdUseKey(id, this.props.processesline, 'id')
    this.setState({
      selectType: (node && node.length > 0 && node[0].roleId && !node[0].userId) ? 'ROLE' : 'USER',
      showSelect: true,
      selectNotice: false,
      callback: (data) => {
        this.props.onhandleChangeUser(id, data, this.checkWorkOrderProcess, this.callback)
      }
    })
  };

  handleShowProcessList = () => {

    const { currentUserInfo, onhandleGetPredefinedProcess } = this.props
    let query = {
      userId: currentUserInfo.userId,
      companyId: currentUserInfo.companyId
    }

    onhandleGetPredefinedProcess(query)

    this.setState({
      showProcessList: true,
    })
  }

  handleCloseProcessSelect = () => {
    this.setState({
      showProcessList: false,
      selectNotice: false,
    })
  }

  callback = () => {
    this.setState({
      showSelect: false,
      selectNotice: false,
    })
  }

  handleExit = () => {
    this.props.onhandleExit();
  }

  handleSelectProcess = (data) => {
    let treeData = [];
    if (data.processSteps && data.processSteps.length > 0) {
      _.cloneDeep(data.processSteps).forEach(processStep => {
        processStep.id = processStep.stepId;
        treeData.push(processStep);
      })
      treeData = arrayToTree(treeData, 'id', 'preId');
    }
    this.props.onhandleSetProcessesline(treeData);
    this.props.onhandleSetProcessesNotices(_.cloneDeep(data.processNoticeTargets));
    this.setState({
      showProcessList: false,
    })
  }

  handleChangeSelectType = (event) => {
    this.setState({
      selectType: event.target.value,
    })

  }

  render() {
    return (
      <CreateProcessLineApprovalComponent
        selectNotice={this.state.selectNotice}
        onhandleConfirm={this.handleConfirm.bind(this)}
        processesline={this.props.processesline}
        processNotices={this.props.processNotices}
        companyId={this.state.useCompanyId}
        onhandleAdd={this.handleAdd.bind(this)}
        onhandleRemove={this.handleRemove.bind(this)}
        showSelect={this.state.showSelect}
        selectType={this.state.selectType}
        showProcessList={this.state.showProcessList}
        haveProcessSelect={this.state.haveProcessSelect}
        predefinedProcess={this.props.predefinedProcess}
        onhandleCallback={this.state.callback}
        onhandleChange={this.handleChangeUser.bind(this)}
        onhandleExit={this.handleExit.bind(this)}
        onhandleShowProcessList={this.handleShowProcessList.bind(this)}
        onhandleCloseProcessSelect={this.handleCloseProcessSelect.bind(this)}
        onhandleSelectProcess={this.handleSelectProcess.bind(this)}
        onhandleAddNotice={this.handleAddNotice.bind(this)}
        onhandleRemoveNotice={this.handleRemoveNotice.bind(this)}
        onhandleChangeSelectType={this.handleChangeSelectType.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predefinedProcess: state.predefinedProcess.predefinedProcess,
    processesline: state.processlinewithuser.processesline,
    parameters: state.modal.parameters,
    companyList: state.constant.companies,
    processNotices: state.processlinewithuser.processNotices,
    currentUserInfo: state.auth.currentUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitProcessLineWithUser: (data, notices) => {
      dispatch(initProcessLineWithUser(data, notices));
    },
    onhandleSetProcessesline: (data) => {
      dispatch(setProcessesline(data));
    },
    onhandleSetProcessesNotices: (data) => {
      dispatch(setProcessesNotices(data));
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
    onhandleAddStep: (id, callback) => {
      dispatch(addStep(id, callback));
    },
    onhandleRemoveStep: (id, pid, callback) => {
      dispatch(removeStep(id, pid, callback));
    },
    onhandleChangeUser: (id, data, callback) => {
      dispatch(changeUser(id, data, callback));
    },
    onhandleExit: () => {
      dispatch(exitPage());
    },
    onhadleMassgae: (message) => {
      dispatch(createMessage('error', message));
    },
    onhandleAddnotice: (id, callback) => {
      dispatch(addNotice(id, callback));
    },
    onhandleRemoveNotice: (userId, callback) => {
      dispatch(removeNotice(userId, callback));
    },
    onhandleGetPredefinedProcess: (query) => {
      dispatch(getPredefinedProcess(query));
    },

  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProcessLineApprovalContainer);
