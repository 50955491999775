import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  gridItem: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  formContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 90px)'
  },
  gridContainer: {
    paddingBottom: theme.spacing(2),
  },
  label: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
    width: 70,
    textAlign: 'right'
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 70px)'
  },
  readOnly: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
