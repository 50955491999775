import React ,{ useRef }from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from './styles';
import OrganizationChart from "@dabeng/react-orgchart";
import OrgNode from './OrgNode';
import "./chartStyle.css";

const OrgChart = props => {
    const { classes,
        companyTree,
        onSetCurrentNode
    } = props;

    const handleClickNode = (node) =>{
        onSetCurrentNode(node);
    }

    const orgchart = useRef(); // the reference of org chart

    const renderOrgChart = () => (
        <React.Fragment>
            <OrganizationChart
                ref={orgchart}
                datasource={companyTree}
                NodeTemplate={OrgNode}
                onClickNode={handleClickNode}
                zoom={true}
                pan={true}
            />
        </React.Fragment>
    )

    return (
        <div className={classes.root}>
            {renderOrgChart()}
        </div>
    );
};

OrgChart.propTypes = {
    classes: PropTypes.object.isRequired,
    companyTree: PropTypes.object.isRequired,
    onSetCurrentNode: PropTypes.func.isRequired
};

export default withRouter(withStyles(OrgChart));
