import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    MenuItem,
    Card,
    Divider,
    Grid,
    TextField,
    Typography,
    Tabs,
    Tab,
    // FormControl,
    // RadioGroup,
    // Radio,
    // FormControlLabel,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
// import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
    Error,
    NotificationImportant,
    Warning,
    HowToVote
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
// import { getMoment } from '@/utils/datetime'
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import withStyles from './styles';

const HazardReport = props => {
    const {
        classes,
        currentUserInfo,
        companies,
        // departments,
        // departmentList,
        // rootCauseList,
        severityList,
        // dictionary,
        hazardReport,
        search,
        tabValue,
        onhandleChangeTab,
        onhandleSelectChange,
        onhandleDateChange,
        onhandleStatusOption,
        onhandleCountOption,
        onhandleRootCauseOption,
        onhandleSeverityOption,
        onhandleRatioOption,
        onhandleTreatmentOption,
        onhandlePlanCompOption
    } = props;


    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_hazard_report' })
    //     return permission && permission.action === 'W'
    // }
    const rootCompany = _.find(companies, { pid: null })

    const companyDisabled = () => {
        const pcompany = _.find(companies, o => !o.pid);
        return pcompany.companyId !== currentUserInfo.companyId;
    }

    const companyCharts = () => {

        const pcompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pcompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                return companies.filter(o => o.companyId === Number.parseInt(search.companyId));
                // return companies.filter(o => o.companyId !== pcompany.companyId && o.companyId === Number.parseInt(search.companyId));
            }
            return companies.filter(o => true);
            // return companies.filter(o => o.companyId !== pcompany.companyId);
        } else {
            return [userCompany];
        }
    }

    const getSeverityColorClass = (severity) => {
        let colorClass = null
        if (severity === 'LOW') colorClass = classes.lowSeverityColor
        if (severity === 'MEDIUM') colorClass = classes.mediumSeverityColor
        if (severity === 'HIGH') colorClass = classes.highSeverityColor
        return colorClass
    }

    const getHazardTotal = (severity, showUndone) => {
        let count = 0;
        hazardReport.forEach(report => {
            if (showUndone) {
                if (!['done', 'invalid'].includes(report.status)) {
                    if (severity) {
                        if (report.severity === severity) {
                            count += report.total
                        }
                    } else {
                        count += report.total
                    }
                }
            } else {
                if (severity) {
                    if (report.severity === severity) {
                        count += report.total
                    }
                } else {
                    count += report.total
                }
            }
        })
        return count;
    }

    const showToolbar = () => {
        if (search.intervalDate.view === 'day') { return false }
        if (search.intervalDate.view === 'week') { return false }
        if (search.intervalDate.view === 'month') { return true }
        if (search.intervalDate.view === 'year') { return false }
    }

    const getPickerView = () => {
        if (search.intervalDate.view === 'day') { return ['date'] }
        if (search.intervalDate.view === 'week') { return ['date'] }
        if (search.intervalDate.view === 'month') { return ['year', 'month'] }
        if (search.intervalDate.view === 'year') { return ['year'] }
        return ['date']
    }

    const getPickerFormat = () => {
        if (search.intervalDate.view === 'day') { return 'yyyy/MM/dd' }
        if (search.intervalDate.view === 'week') { return 'yyyy/MM/dd' }
        if (search.intervalDate.view === 'month') { return 'yyyy/MM' }
        if (search.intervalDate.view === 'year') { return 'yyyy' }
        return 'yyyy/MM/dd'
    }

    const getPickerOpenTo = () => {
        if (search.intervalDate.view === 'month') { return 'month' }
        return undefined
    }

    return (
        <>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {"隐患报表"}</Typography>
            </div>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                        {/* <Grid item xs={1}>
                            <Typography gutterBottom className={classes.label}>
                                {'属地单位'}
                            </Typography>
                        </Grid> */}
                        <Grid item xs={6} container wrap={'nowrap'} alignItems={'center'}>
                            {/* <FormControl component="fieldset">
                                <RadioGroup row aria-label="视图" name="view"
                                    defaultValue="week"
                                    value={search.intervalDate.view || ''}
                                    onChange={(event) => onhandleSelectChange(event, 'view')}
                                    style={{ width: '100%' }}
                                >
                                    <FormControlLabel value="year" control={<Radio size={'small'} />} label="年视图" />
                                    <FormControlLabel value="month" control={<Radio size={'small'} />} label="月视图" />
                                    <FormControlLabel value="week" control={<Radio size={'small'} />} label="周视图" />
                                </RadioGroup>
                            </FormControl> */}
                            <Typography gutterBottom className={classes.label} style={{ margin: '0 10px' }}>
                                {'视图'}
                            </Typography>
                            <ButtonGroup size="small" style={{ height: 35, marginRight: 15 }}>
                                <Button variant={search.intervalDate.view === 'year' ? 'contained' : null} color={search.intervalDate.view === 'year' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'year' }
                                        }, 'view')
                                    }}>{'年'}</Button>
                                <Button variant={search.intervalDate.view === 'month' ? 'contained' : null} color={search.intervalDate.view === 'month' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'month' }
                                        }, 'view')
                                    }}>{'月'}</Button>
                                <Button variant={search.intervalDate.view === 'week' ? 'contained' : null} color={search.intervalDate.view === 'week' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'week' }
                                        }, 'view')
                                    }}>{'周'}</Button>
                            </ButtonGroup>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN}>
                                <KeyboardDatePicker
                                    className={classes.dateField}
                                    InputProps={{ readOnly: true }}
                                    disableToolbar={!showToolbar()}
                                    autoOk
                                    variant='inline'
                                    inputVariant='outlined'
                                    size={'small'}
                                    views={getPickerView()}
                                    openTo={getPickerOpenTo()}
                                    format={getPickerFormat()}
                                    margin="none"
                                    value={search.intervalDate.startDate || null}
                                    onChange={date => onhandleDateChange(date, 'startDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Typography gutterBottom className={classes.label} style={{ margin: '0 10px' }}>
                                {' 至 '}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN}>
                                <KeyboardDatePicker
                                    className={classes.dateField}
                                    InputProps={{ readOnly: true }}
                                    disableToolbar={!showToolbar()}
                                    autoOk
                                    variant="inline"
                                    inputVariant='outlined'
                                    size={'small'}
                                    views={getPickerView()}
                                    openTo={getPickerOpenTo()}
                                    format={getPickerFormat()}
                                    margin="none"
                                    value={search.intervalDate.endDate || null}
                                    onChange={date => onhandleDateChange(date, 'endDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                variant={'outlined'}
                                style={{ width: 150, height: 35 }}
                                size={'small'}
                                value={search.companyId || 0}
                                disabled={companyDisabled()}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: { vertical: "top", horizontal: "center" },
                                        transformOrigin: { vertical: "top", horizontal: "center" },
                                        getContentAnchorEl: null
                                    }
                                }}
                                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                            >
                                <MenuItem dense value={0} >{(rootCompany && rootCompany.companyName) ? rootCompany.companyName : '全体单位'}</MenuItem>
                                {companies ?
                                    companies.map(data => (
                                        <MenuItem dense value={data.companyId} key={'company_' + data.companyId}>{data.companyAlias}</MenuItem>
                                    )) : null
                                }
                            </TextField>
                        </Grid>
                    </Grid>

                    {/* <Grid container spacing={0} className={classes.gridContainer} direction={'row-reverse'}> */}
                    {/* <FormControl component="fieldset">
                            <RadioGroup row aria-label="视图" name="view"
                                defaultValue="week"
                                value={search.intervalDate.view || ''}
                                onChange={(event) => onhandleSelectChange(event, 'view')}
                                style={{ width: '100%' }}
                            > */}
                    {/* <FormControlLabel value="year" control={<Radio size={'small'} />} label="年视图" /> */}
                    {/* <FormControlLabel value="month" control={<Radio size={'small'} />} label="月视图" /> */}
                    {/* <FormControlLabel value="week" control={<Radio size={'small'} />} label="周视图" /> */}
                    {/* <FormControlLabel value="DAY" control={<Radio size={'small'} />} label="日视图" /> */}
                    {/* </RadioGroup>
                        </FormControl> */}
                    {/* </Grid> */}
                    {/* <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item className={classes.toolbarButton}>
                            <Button onClick={event => goToFrontDate(event)} size="small" >
                                <ArrowBackIos size="small" />
                            </Button>
                        </Grid>
                        <Grid item className={classes.toolbarLabel}>
                            <Typography className={classes.label}>{getintervalDate()}</Typography>
                        </Grid>
                        <Grid item className={classes.toolbarButton} >
                            <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
                        </Grid>
                        <Grid item className={classes.flexgrow}></Grid>
                        <Grid item className={classes.toolbarButton}>
                            <Tooltip title="导出报告">
                                <IconButton aria-label="delete" onClick={event => onhandleExportCsv(event)} size="small" disabled={isLoad}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={0} className={classes.titleContainer}>
                        <Grid item xs={12}>
                            {/* <Card className={classes.titleCard}> */}
                            <Grid container justify={'space-between'}>
                                <Grid item className={classes.summaryGrid}>
                                    {
                                        severityList.map(({ code, name }) => <>
                                            <Grid item container direction={'column'} key={'severity_' + code} className={classes.summaryGrid}>
                                                <Grid item container justify={'center'} alignItems={'center'}>
                                                    <Grid className={classes.icongrid}>
                                                        {code === 'LOW' && <Warning className={getSeverityColorClass(code)} />}
                                                        {code === 'MEDIUM' && <Error className={getSeverityColorClass(code)} />}
                                                        {code === 'HIGH' && <NotificationImportant className={getSeverityColorClass(code)} />}
                                                    </Grid>
                                                    <Grid>
                                                        <Typography className={classes.notifiText + ' ' + getSeverityColorClass(code)} noWrap={true}>
                                                            {name + '隐患'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container justify={'center'}>
                                                    <Grid container justify={'center'}>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h1'}>{getHazardTotal(code, true)}</Typography>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h2'}>{'/'}</Typography>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h2'}>{getHazardTotal(code, false)}</Typography>
                                                    </Grid>
                                                    <Grid container justify={'center'}>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h5'}>{'待治理'}</Typography>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h5'}>{'/'}</Typography>
                                                        <Typography className={classes.notifiSummary + ' ' + getSeverityColorClass(code)} variant={'h5'}>{'发现'}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider orientation="vertical" flexItem key={'severity_divider_' + code} />
                                        </>)
                                    }
                                </Grid>
                                <Grid item className={classes.summaryGrid}>
                                    <Grid item container direction={'column'} className={classes.summaryGrid}>

                                        <Grid item container justify={'center'} alignItems={'center'}>
                                            <Grid className={classes.icongrid}>
                                                <HowToVote className={getSeverityColorClass()} />
                                            </Grid>
                                            <Grid>
                                                <Typography className={classes.notifiText + ' ' + getSeverityColorClass()} noWrap={true}>
                                                    {'上报隐患'}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item container justify={'center'}>
                                            <Grid container justify={'center'}>
                                                <Typography className={classes.notifiSummary} variant={'h1'}>{getHazardTotal(null, false)}</Typography>
                                                {/* <Typography className={classes.notifiSummary} variant={'h2'}>{'/'}</Typography>
                                                <Typography className={classes.notifiSummary} variant={'h2'}>{getHazardTotal(null, false)}</Typography> */}
                                            </Grid>
                                            {/* <Grid container justify={'center'}>
                                                <Typography className={classes.notifiSummary} variant={'h5'}>{'待治理'}</Typography>
                                                <Typography className={classes.notifiSummary} variant={'h5'}>{'/'}</Typography>
                                                <Typography className={classes.notifiSummary} variant={'h5'}>{'发现'}</Typography>
                                            </Grid> */}
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleSeverityOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleRootCauseOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer} justify="center">
                        <Tabs
                            classes={{ fixed: classes.tabs, indicator: classes.indicator }}
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            // centered
                            onChange={onhandleChangeTab}
                        >
                            <Tab label={"隐患治理状态"} className={classes.tab} />
                            <Tab label={"隐患上报历史"} className={classes.tab} />
                            <Tab label={"分厂/机关排查比"} className={classes.tab} />
                            <Tab label={"隐患治理完成率"} className={classes.tab} />
                            <Tab label={"计划完成率"} className={classes.tab} />
                        </Tabs>
                    </Grid>
                    {tabValue === 0 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleStatusOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 1 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleCountOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 2 && <Grid container spacing={0} className={classes.gridContainer}>
                        {
                            companyCharts().map(company => {
                                return <Grid item xs={6} key={'company_' + company.companyId}>
                                    <Card className={classes.card}>
                                        <ReactEcharts option={onhandleRatioOption(company)} style={{ height: 300, width: '100%' }} />
                                    </Card>
                                </Grid>
                            })
                        }
                    </Grid>}
                    {tabValue === 3 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleTreatmentOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 4 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandlePlanCompOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                </Grid>
            </div>
        </>
    );
};

HazardReport.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    dictionary: PropTypes.array.isRequired,
    // rootCauseList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
};

export default withStyles(HazardReport);
