import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
    Grid, TextField, Button,
    Typography, FormHelperText
    , MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import * as _ from 'lodash';

const DevicePartEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleClose,
        onhandleCheck,
        currentDevicePart,
        devicePartError,
        devicePartChange,
        companyTree,
        companyList,
        onhandleSelectChange,
        onhandleDateChange,
        catTree,
        posTypeTree,
        posKeyTypeTree,
        isView,
        userProfile,
        open,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_management' })
        return permission && permission.action === 'W'
    }

    const isCommonData = () => {
        return [
            { label: "是", value: true, pcode: null, children: null },
            { label: "否", value: false, pcode: null, children: null }
        ]
    }

    const isVulnerableData = () => {
        return [
            { label: "是", value: true, pcode: null, children: null },
            { label: "否", value: false, pcode: null, children: null }
        ]
    }

    const isSpareData = () => {
        return [
            { label: "是", value: true, pcode: null, children: null },
            { label: "否", value: false, pcode: null, children: null }
        ]
    }


    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    return (
        <div>

            <Dialog open={open} onClose={onhandleClose} fullWidth maxWidth={'md'}>
                <DialogTitle>
                    <Grid container className={classes.gridContainer}>
                        <Typography variant="h3">
                            {currentDevicePart.partId ? "编辑部件" : "新建部件"}
                        </Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1} className={classes.gridContainer} sm={12} md={12}>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                单位
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    id="filled-select-company"
                                    select
                                    value={currentDevicePart.companyId}
                                    onChange={(event) => onhandleSelectChange(event, 'companyId')}
                                    variant="outlined"
                                    size="small"
                                    error={devicePartError.companyId !== ''}
                                    onBlur={event => onhandleCheck(event, 'productivity')}
                                    helperText={devicePartError.companyId || ''}
                                    disabled={currentDevicePart.partId || companyDisabled()}
                                >
                                    {_.sortBy(companyList, 'companyId').map((option) => (
                                        <MenuItem key={option.companyId} value={option.companyId}>
                                            {option.companyAlias}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                部件编号
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.partNo !== ''}
                                    helperText={devicePartError.partNo || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.partNo || ''}
                                    variant="outlined"
                                    size="small"
                                    // disabled={(currentDevicePart.partId && currentDevicePart.partNo) || isView || !checkPermission()}
                                    disabled={true}
                                    onChange={event => onhandleChange(event, 'partNo')}
                                    onBlur={event => onhandleCheck(event, 'partNo')}
                                />
                                {/* )} */}
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                部件名称
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.partName !== ''}
                                    helperText={devicePartError.partName || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.partName || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'partName')}
                                    onBlur={event => onhandleCheck(event, 'partName')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                型号
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.model !== ''}
                                    helperText={devicePartError.model || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.model || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'model')}
                                    onBlur={event => onhandleCheck(event, 'model')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                规格
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    value={currentDevicePart.specs || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'specs')}
                                    onBlur={event => onhandleCheck(event, 'specs')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                部件类别
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    className={classes.textField}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.cat}
                                    labelInValue
                                    treeData={catTree}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'cat')}
                                />
                                {devicePartError.cat !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {devicePartError.cat}
                                </FormHelperText> : null}
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                是否通用部件
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    className={classes.textField}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.isCommon}
                                    labelInValue
                                    treeData={isCommonData()}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'isCommon')}
                                />
                                {devicePartError.isCommon !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {devicePartError.isCommon}
                                </FormHelperText> : null}
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                是否易损件
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    className={classes.textField}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.isVulnerable}
                                    labelInValue
                                    treeData={isVulnerableData()}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'isVulnerable')}
                                />
                                {devicePartError.isVulnerable !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {devicePartError.isVulnerable}
                                </FormHelperText> : null}
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                需要备件
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    className={classes.textField}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.isSpare}
                                    labelInValue
                                    treeData={isSpareData()}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'isSpare')}
                                />
                                {devicePartError.isSpare !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {devicePartError.isSpare}
                                </FormHelperText> : null}
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                安装部位
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    showArrow
                                    className={classes.textField}
                                    choiceTransitionName="rc-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.installPos}
                                    labelInValue
                                    treeData={posTypeTree}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    // multiple={true}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'installPos')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                安装部位关键类别
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TreeSelect
                                    showArrow
                                    className={classes.textField}
                                    choiceTransitionName="rc-select-selection__choice-zoom"
                                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                                    allowClear
                                    treeLine
                                    value={currentDevicePart.posType}
                                    labelInValue
                                    treeData={posKeyTypeTree}
                                    treeNodeFilterProp="label"
                                    filterTreeNode={false}
                                    // multiple={true}
                                    disabled={isView || !checkPermission()}
                                    onChange={(value) => onhandleSelectChange(value, 'posType')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                材质
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.material !== ''}
                                    helperText={devicePartError.material || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.material || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'material')}
                                    onBlur={event => onhandleCheck(event, 'material')}
                                />
                            </Grid>
                        </Grid>


                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                制造商
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.manufacturer !== ''}
                                    helperText={devicePartError.manufacturer || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.manufacturer || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'manufacturer')}
                                    onBlur={event => onhandleCheck(event, 'manufacturer')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                图纸编号
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    value={currentDevicePart.drawing || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'drawing')}
                                    onBlur={event => onhandleCheck(event, 'drawing')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                使用寿命(小时)
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.serviceLife !== ''}
                                    helperText={devicePartError.serviceLife || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.serviceLife || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'serviceLife')}
                                    onBlur={event => onhandleCheck(event, 'serviceLife')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                重量(KG)
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.weight !== ''}
                                    helperText={devicePartError.weight || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.weight || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'weight')}
                                    onBlur={event => onhandleCheck(event, 'weight')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                <span className={classes.required}>*</span>
                                数量
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={devicePartError.amount !== ''}
                                    helperText={devicePartError.amount || ''}
                                    className={classes.textField}
                                    value={currentDevicePart.amount || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    onChange={event => onhandleChange(event, 'amount')}
                                    onBlur={event => onhandleCheck(event, 'amount')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                投用时间
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        autoOk
                                        className={classes.textField}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        name='downTimeselect'
                                        value={currentDevicePart.enableTime}
                                        disabled={isView || !checkPermission()}
                                        onChange={value => onhandleDateChange(value, 'enableTime')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            style: { paddingRight: 0 },
                                            startAdornment:
                                                (
                                                    <IconButton
                                                        style={{ order: 1, display: (isView || !checkPermission()) ? 'none' : '' }}
                                                        onClick={() => onhandleDateChange(null, 'enableTime')}
                                                        disabled={Boolean(!currentDevicePart.enableTime)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                        }}
                                        InputAdornmentProps={{
                                            position: "end",
                                            style: { order: 2, marginLeft: 0 }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        {/* <Grid item container className={classes.gridItem} sm={6} md={4}>
        <Typography  className={classes.label}>
            关联设备
            </Typography>
        <Grid item container className={classes.formContainer}>
            <TextField
                className={classes.textField}
                value={currentDevicePart.deviceName || ''}
                variant="outlined"
                size="small"
                disabled={isView || !checkPermission()}
                InputProps={{
                    readOnly: true,
                }}
                onClick={event => onhandleDialog(event)}
            />
        </Grid>
    </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" color="primary" className={classes.button} hidden={!checkPermission() || isView}
                        disabled={!devicePartChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleClose(event)}
                    >
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
            <Prompt message="数据未保存,确定要离开？" when={devicePartChange} />
        </div>
    );
};

DevicePartEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentDevicePart: PropTypes.object.isRequired,
    devicePartError: PropTypes.object.isRequired,
    devicePartChange: PropTypes.bool.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    catTree: PropTypes.array.isRequired,
    posTypeTree: PropTypes.array.isRequired,
    posKeyTypeTree: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    //onhandleDialog: PropTypes.func.isRequired,
};

DevicePartEdit.defaultProps = {
    currentDevicePart: {},
    devicePartError: {},
}

export default withStyles(DevicePartEdit);
