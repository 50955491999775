import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '@/redux/modules/message'
import StaffSharingComponent from '@/components/AmoebaManagement/SharingCost/StaffSharing/StaffSharingComponent';
import AddStaffSharingComponent from '@/components/AmoebaManagement/SharingCost/StaffSharing/AddStaffSharing/AddStaffSharingComponent';

import {
    getSharingTakeHistories, initStaffSharing, setSelectedSharer, addCostSharing
    , getSharingTakeById, addSharingTake, updateSharingTake
    , getUsers
} from '@/redux/modules/amoeba/amoebaCostSharing';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import * as _ from 'lodash';
import SharingTakeHistory from '@/components/AmoebaManagement/SharingCost/SharingTakeHistory';

/**
 * 服务半径-装置
 */
class StaffSharingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            addSharingTakeOpen: false,
            sharingTakeHistoryOpen: false,
            userId: 0,
            userlist: [],
            userNews: [],
        }
    }

    componentWillMount() {
        const { users, costSharinglist, currentUserInfo, roleRoutes } = this.props
        const roleR = roleRoutes.find(ele => ele.route === 'amoeba_other_sharing').action === 'R';
        this.props.onInitPage(roleR ? 0 : currentUserInfo.companyId);
        this.props.getUsers(roleR ? 0 : currentUserInfo.companyId)
        let userlist = _.filter(users, (u) => {
            return _.find(costSharinglist, (o) => { return o.sharer === u.userId }) !== undefined
        })
        this.setState({ userlist: userlist })
    }

    handleSelectChange = (event) => {
        this.props.onhandleSelectChange(event.target.value)
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.sharer === parseInt(event.target.value)
        })
        if (cs !== undefined) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        }
    }

    handleAdd = (value) => {
        const { costSharinglist, selectedSharer } = this.props
        let cs = _.find(costSharinglist, (c) => { return c.sharer === parseInt(selectedSharer) })
        if (cs !== undefined) {
            value['sharingId'] = cs.sharingId
            value['percentage'] = parseFloat(value['percentage'])
            value['takerCompany'] = parseInt(value['takerCompany'])
            value['takerDpt'] = parseInt(value['takerDpt'])
            value['fixed'] = parseFloat(value['fixed'])
            this.props.onhandleAdd(value);
        }
    }

    handleDelete = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'status': 'inactive'
        }
        this.props.onhandleUpdate(newData);
    }

    handleUpdate = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'takerCompany': parseInt(value['takerCompany']),
            'takerDpt': parseInt(value['takerDpt']),
            'percentage': parseFloat(value['percentage']),
            'fixed': parseFloat(value['fixed']),
            'valuationType': value['valuationType'],
        }
        this.props.onhandleUpdate(newData);
    }

    handleOpenAdd = () => {
        const { users } = this.props
        const { userlist } = this.state
        let userNews = _.filter(users, (u) => {
            return _.find(userlist, (l) => { return l.userId === u.userId }) === undefined
        })
        this.setState({ addSharingTakeOpen: true, userNews: userNews })
    }

    handleChangeUser = (event) => {
        this.setState({ userId: event.target.value })
    }

    handleClose = () => {
        this.setState({ addSharingTakeOpen: false })
    }

    handleAddCostSharing = () => {
        const { currentUserInfo, users } = this.props
        const { userId } = this.state
        const u = _.find(users, u => u.userId === userId)
        let newData = {
            'sharer': userId,
            'sharerDpt': u.dptId,
            'sharerCompany': currentUserInfo.companyId,
        }
        this.props.onhandleAddCostSharing(newData, 'SHARING_STAFF',).then(
            () => {
                this.props.onInitPage(currentUserInfo.companyId).then(
                    () => {
                        const { users, costSharinglist } = this.props
                        let userlist = _.filter(users, (u) => {
                            return _.find(costSharinglist, (o) => { return o.sharer === u.userId }) !== undefined
                        })
                        this.setState({ userlist: userlist })
                    }
                )
            }
        )
        this.setState({ addSharingTakeOpen: false, userId: 0 })
    }

    handleOpenHistory = (value) => {
        this.setState({ sharingTakeHistoryOpen: true })
        this.props.onhandleGetSharingTakeHistories(value['sharingId'], value['takeId'])
    }

    handleCloseHistory = () => {
        this.setState({ sharingTakeHistoryOpen: false })
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_other_sharing').action === 'W';
        let departments = _.filter(this.props.departments, d => {
            return d.companyId === this.props.currentUserInfo.companyId
                && d.amoebaType === 'COST'
                && !_.find(this.props.costSharinglist, c => { return c.sharerDpt === d.dptId })
        })
        return (
            <React.Fragment>
                <StaffSharingComponent
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    valuations={this.props.valuations}
                    companies={this.props.companies}
                    sharingTakelist={this.props.sharingTakelist}
                    costSharinglist={this.props.costSharinglist}
                    selectedSharer={this.props.selectedSharer}
                    roleFlag={roleFlag}
                    userlist={this.state.userlist}
                    onhandleMessage={this.props.onhandleMessage}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleOpenAdd={this.handleOpenAdd.bind(this)}
                    onhandleOpenHistory={this.handleOpenHistory.bind(this)}
                />
                {roleFlag && <AddStaffSharingComponent
                    open={this.state.addSharingTakeOpen}
                    userId={this.state.userId}
                    departments={departments}
                    users={this.state.userNews}
                    onhandleChangeUser={this.handleChangeUser.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleAdd={this.handleAddCostSharing.bind(this)}
                />}
                <SharingTakeHistory
                    open={this.state.sharingTakeHistoryOpen}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    histories={this.props.sharingTakeHistories}
                    users={this.props.modifyUsers}
                    onhandleClose={this.handleCloseHistory.bind(this)}
                    valuations={this.props.valuations}
                />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        companies: state.constant.companies,
        costSharinglist: state.amoebaCostSharing.costSharinglist,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
        selectedSharer: state.amoebaCostSharing.selectedSharer,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        valuations: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.MANAGE_SHARE_TYPE
        }),
        users: state.amoebaCostSharing.users.list,
        sharingTakeHistories: state.amoebaCostSharing.sharingTakeHistories,
        modifyUsers: state.usercard.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (companyId) => {
            return dispatch(initStaffSharing(companyId));
        },
        onhandleSelectChange: (userId) => {
            dispatch(setSelectedSharer(userId));
        },
        onhandleGetSharingTakeList: (sid) => {
            dispatch(getSharingTakeById(sid));
        },
        onhandleAdd: (value) => {
            dispatch(addSharingTake(value, 'TYPE_DEPARTMENT'));
        },
        onhandleAddCostSharing: (value, type) => {
            return dispatch(addCostSharing(value, type));
        },
        onhandleUpdate: (value) => {
            dispatch(updateSharingTake(value.takeId, value));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        },
        getUsers: (companyId) => {
            return dispatch(getUsers(companyId))
        },
        onhandleGetSharingTakeHistories: (sid, takeId, status) => {
            dispatch(getSharingTakeHistories(sid, takeId, status));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffSharingContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}