import { withStyles } from '@material-ui/styles';

const styles = theme => ({
      planitemroot: {
        backgroundColor: '#FFFFFFFF',
        padding: theme.spacing(4),
        overflow: 'auto'
      },
      detailroot: {
        backgroundColor: '#FFFFFFFF',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        overflowX: 'auto'
      },
      materialtable: {
        boxShadow: 'none'
      },
      gridContainer: {
        marginTop: 30
      },
      detailGrid: {
        marginTop: 15
      },
      gridSelect: {
        width: 200
      },
      gridLongSelect: {
        width: 300
      },
      gridItem: {
        width: 200
      }
});


export default withStyles(styles);