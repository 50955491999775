import React, { Component } from 'react';
import { connect } from 'react-redux';
import MOrderListComponent from "@/components/ManufactureManagement/ManufactureOrder/MOrderList";
import PropTypes from "prop-types";
import CreateMOrderComponent from '@/components/ManufactureManagement/ManufactureOrder/CreateMOrder';
import GenerateMOrderComponent from '@/components/ManufactureManagement/ManufactureOrder/GenerateMOrder';
import MGenerateFixContainer from './MGenerateFixContainer'
import {
    getOrders
    , addOrder
    , updateOrder
    , deleteOrder
} from '@/redux/modules/manufacture/manufactureOrder';
import { getManufactureServiceById } from '@/redux/modules/manufacture/manufactureService'
import {
    getManufactureTasks, delManufactureTask, updateManufactureTask, addManufactureTask, addManufactureTasks
} from '@/redux/modules/manufacture/manufactureTask';
import { getManipulates, getInstallationsInManipulate } from '@/redux/modules/manipulate';
import { getPlans } from '@/redux/modules/manufacture/manufacturePlan';
import { getNowTimestamp, getMoment, formatDate, getYearMonth, formatMonth } from '@/utils/datetime'
import moment from "moment";
import { judgeMainCompany } from '@/utils/manufactureUtils';
import CreateTaskComponent from '@/components/ManufactureManagement/Task/CreateTask/CreateTaskComponent';
import { getManufactureServices } from '@/redux/modules/manufacture/manufactureService';
import { SERVICE_TYPE_ALL } from '@/utils/manufactureUtils'
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { createActionMessage } from '@/redux/modules/message';
import { getUsers, setSearch } from '@/redux/modules/user';
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import _ from 'lodash';
import MTaskReportContainer from './MTaskReportContainer';
import { openReport, getTasksReports } from '@/redux/modules/manufacture/manufactureTask';
// import { getCompanyId } from "@/utils/userUtils";

class MOrderListContainer extends Component {
    static propTypes = {
        departmentList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            fixAmountOpen: false,
            createOpen: false,
            createOrder: false,
            generateOpen: false,
            search: {
                fromDate: getMoment()(),
                toDate: getMoment()(),
                companyId: 0,
                dptId: 0,
            },
            generateDate: getMoment()(),
            day: formatDate(getMoment()()),
            manufactureOrder: {},
            planDtls: [],
            yearMonth: getYearMonth(),
            dtls: [],
            orderDptList: [],
            isMainCompany: false,
            defaultEndTime: formatDate(moment(getNowTimestamp()).add(1, 'd')),
            openType: 1,//画面打开形式
            service: {
                serviceId: 1,
                serviceName: '',
                activities: []
            },
            task: {
                serviceName: '',
                activityName: '',
                excuetor: '',
                startTime: formatDate(getNowTimestamp()),
                orderId: 0,
                orderNo: ''
            }
        }
    }

    handleUpdate = async (event, rowData) => {
        const order = this.getOrder(rowData.orderId)
        let service = await this.props.getManufactureServiceById(order.manufactureService.serviceId);
        service = service.payload
        rowData.serviceName = service.serviceName;
        this.getOrderDptList(order);
        if (order.dptId) {
            this.getDptMembers(order.dptId)
        }
        this.setState({
            createOpen: true,
            openType: 2,
            task: { ...rowData },
            service: service
        })
    }

    handleDelete = (oldData) => {
        return this.props.delManufactureTask(oldData.taskId).then(
            () => { this.props.getManufactureTasks(this.props.userCompanyId, this.state.day, this.state.defaultEndTime, this.state.search.dptId); }
        );
    }


    handleDateChangeTask = (date) => {
        let newTask = { ...this.state.task };
        newTask.startTime = date;
        this.setState({
            task: newTask
        })
    }

    handleTaskAdd = () => {
        this.setState({
            createOpen: true,
            openType: 1
        })
    }

    handleOpenFixAmount = () => {
        this.props.getServices(this.getCompanyId(), this.getPid())
        this.setState({ fixAmountOpen: true })
    }

    handleCloseFixAmount = () => {
        this.setState({ fixAmountOpen: false })
    }

    handleCloseCreate = () => {
        this.setState({
            createOpen: false,
            createOrder: false,
            generateOpen: false,
            manufactureOrder: {},
            dtls: [],
            task: {
                serviceName: '',
                activityName: '',
                excuetor: '',
                startTime: formatDate(getNowTimestamp()),
                orderId: '',
            }
        })
    }

    handleSave = (event) => {
        const { task, openType, day, defaultEndTime } = this.state;
        const { tasks, addManufactureTask, updateManufactureTask, handleSelect, getManufactureTasks, createActionMessage } = this.props;
        let checkFlag = true;
        tasks.forEach(element => {
            if (task.orderId === element.orderId
                && task.activityId === element.activityId
                && task.executor === element.executor) {
                //更新的属性在活动中存在
                let message = (openType === 1 ?
                    '增加任务失败，该任务活动已分配人员' : '未更新任务或该任务已分配，请重新选择')
                createActionMessage('warning', message);
                checkFlag = false;
                return;
            }
        });
        if (checkFlag) {
            if (openType === 1) {
                // 任务增加
                addManufactureTask(task).then(
                    () => {
                        handleSelect(day, this.state.search.companyId, defaultEndTime, this.state.search.dptId)
                        getManufactureTasks(this.state.search.companyId, day, defaultEndTime, this.state.search.dptId)
                    }
                );
            } else {
                // 任务更新
                updateManufactureTask(task).then(
                    () => {
                        handleSelect(day, this.state.search.companyId, defaultEndTime, this.state.search.dptId)
                        getManufactureTasks(this.state.search.companyId, day, defaultEndTime, this.state.search.dptId)
                    }
                );
            }
            this.setState({
                createOpen: false,
                task: {
                    serviceName: '',
                    activityName: '',
                    excuetor: '',
                    startTime: formatDate(getNowTimestamp()),
                    orderId: '',
                }
            })
        }
    }

    getDptMembers(dptId) {
        const { setUserSearch, getSubMembers } = this.props;

        //获取班组操作人员
        let query = {
            size: 10
        };
        let search = {
            dptId: dptId,
        }
        setUserSearch(search)
        getSubMembers(query);
    }

    getOrder(orderId) {
        if (this.props.manufactureOrders && this.props.manufactureOrders.length > 0) {
            const order = this.props.manufactureOrders.find(element => element.orderId === orderId);
            return order;
        }
        return { manufactureService: {} };
    }

    getOrderDptList(order) {
        const { departmentList } = this.props;
        const service = order.manufactureService;
        let dptList = [];
        if (service.serviceDpts && service.serviceDpts.length > 0) {
            service.serviceDpts.forEach(ele => {
                const dpt = departmentList.find(dpt => dpt.dptId === ele.dptId);
                if (dpt) dptList.push(dpt);
            });
        }
        this.setState({
            orderDptList: dptList,
        })
    }

    handleChange = async (event) => {
        let newTask = this.state.task;
        const { name, value } = event.target;
        if (name === 'orderId') {
            const order = this.getOrder(value);
            let service = await this.props.getManufactureServiceById(order.manufactureService.serviceId);
            service = service.payload
            if (service) {
                newTask.serviceName = service.serviceName;
                newTask.serviceId = service.serviceId;
                newTask[name] = value;
            }
            this.setState({
                task: newTask,
                service: service,
            })
        } else if (name === 'executor') {
            newTask[name] = value;
            this.setState({ task: newTask });
        } else if (name === 'dptId') {
            this.getDptMembers(value);
            newTask[name] = value;
            this.setState({ task: newTask });
        } else {
            newTask[name] = value;
            this.setState({ task: newTask });
        }
    }

    handleGenerateTask = () => {
        const { userCompanyId, manufactureOrders, getManufactureTasks, addManufactureTasks, createActionMessage } = this.props;
        const { day, defaultEndTime, search } = this.state;
        let taskList = [];
        if (manufactureOrders && manufactureOrders.length > 0) {
            manufactureOrders.forEach(order => {
                const manufactureService = order.manufactureService;
                const activities = order.manufactureService.activities;
                if (activities && activities.length > 0) {
                    activities.forEach(activity => {
                        // if (activity.activityType !== 'ERP') {
                        // 日常活动
                        let task = {};
                        task.orderId = order.orderId;
                        task.serviceId = manufactureService.serviceId;
                        task.activityId = activity.activityId;
                        task.companyId = userCompanyId;
                        task.startTime = day;
                        // 工单指派部门
                        task.dptId = order.dptId;
                        taskList.push(task);
                        // }
                    });
                }
            });
        }
        console.log(taskList);
        if (taskList.length === 0) {
            let message = day + '的任务已生成，无需生成任务';
            createActionMessage('warning', message);
        } else {
            addManufactureTasks(taskList, () => {
                const { userCompanyId, day } = this.state;
                // 获取公司生产任务
                getManufactureTasks(userCompanyId, day, defaultEndTime, search.dptId);
            });
        }
    }

    getCompanyId = () => {
        const company = this.props.companyList.find(item => {
            return item.companyId === this.props.userCompanyId
        });
        return !company || company.pid === null ? null : company.companyId
    }

    getPid = () => {
        const company = this.props.companyList.find(item => {
            return item.companyId === this.props.userCompanyId
        });
        return company.pid
    }

    componentDidMount() {
        const { currentUserInfo, companyList } = this.props

        let isMainCompany = judgeMainCompany(companyList, currentUserInfo.companyId);
        let companyId = isMainCompany ? 0 : currentUserInfo.companyId;

        // 获取公司生产任务
        this.props.handleSelect(this.state.day, companyId, this.state.defaultEndTime, this.state.search.dptId).then(
            () => this.props.getManufactureTasks(companyId, this.state.day, this.state.defaultEndTime, this.state.search.dptId)
        )

        this.props.getAmoebaIndexes()
        this.setState({
            search: {
                ...this.state.search,
                companyId: companyId,
            },
            userCompanyId: companyId,
            isMainCompany: isMainCompany
        })
        // this.props.handleGetStatus();

        if (isMainCompany) {
            this.props.getManipulates()
        } else {
            this.props.getManipulates(currentUserInfo.companyId)
        }

        this.props.getInstallationsInManipulate(currentUserInfo.companyId)

    }

    handleDateChange = (date, field) => {
        switch (field) {
            case 'fromDate':
                this.setState({
                    search: {
                        ...this.state.search,
                        fromDate: getMoment()(date),
                    },
                    day: formatDate(date),
                })
                this.props.handleSelect(formatDate(date), this.state.search.companyId, this.state.defaultEndTime, this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, formatDate(date), this.state.defaultEndTime, this.state.search.dptId)
                )
                break;
            case 'toDate':
                this.setState({
                    search: {
                        ...this.state.search,
                        toDate: getMoment()(date),
                    },
                    defaultEndTime: formatDate(moment(date).add(1, 'd'))
                })
                this.props.handleSelect(this.state.day, this.state.search.companyId, formatDate(moment(date).add(1, 'd')), this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, this.state.day, formatDate(moment(date).add(1, 'd')), this.state.search.dptId)
                )
                break;
            case 'prev':
                date = this.state.search.fromDate.clone().add(-1, 'd')
                this.setState({
                    search: {
                        ...this.state.search,
                        fromDate: date.clone(),
                        toDate: date.clone(),
                    },
                    day: formatDate(date),
                    defaultEndTime: formatDate(date.clone().add(1, 'd'))
                })
                this.props.handleSelect(formatDate(date), this.state.search.companyId, formatDate(date.add(1, 'd')), this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, formatDate(date), formatDate(date.add(1, 'd')), this.state.search.dptId)
                )
                break;
            case 'next':
                date = this.state.search.fromDate.clone().add(1, 'd')
                this.setState({
                    search: {
                        ...this.state.search,
                        fromDate: date.clone(),
                        toDate: date.clone(),
                    },
                    day: formatDate(date),
                    defaultEndTime: formatDate(date.clone().add(1, 'd'))
                })
                this.props.handleSelect(formatDate(date), this.state.search.companyId, formatDate(date.clone().add(1, 'd')), this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, formatDate(date), formatDate(date.clone().add(1, 'd')), this.state.search.dptId)
                )
                break;
            case 'curr':
                date = getMoment()()
                this.setState({
                    search: {
                        ...this.state.search,
                        fromDate: date.clone(),
                        toDate: date.clone(),
                    },
                    day: formatDate(date),
                    defaultEndTime: formatDate(date.clone().add(1, 'd'))
                })
                this.props.handleSelect(formatDate(date), this.state.search.companyId, formatDate(date.clone().add(1, 'd')), this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, formatDate(date), formatDate(date.clone().add(1, 'd')), this.state.search.dptId)
                )
                break;
            default:
            // do nothing
        }
    }

    handleSelect = (value, field) => {
        switch (field) {
            case 'companyId':
                const companyId = value.target.value;
                this.setState({
                    search: {
                        ...this.state.search,
                        companyId: companyId,
                        dptId: 0,
                    }
                })
                this.props.handleSelect(this.state.day, companyId, this.state.defaultEndTime, this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(companyId, this.state.day, this.state.defaultEndTime, 0)
                )
                break;
            case 'dptId':
                const dptId = value.target.value;
                this.setState({
                    search: {
                        ...this.state.search,
                        dptId: dptId
                    }
                })
                this.props.handleSelect(this.state.day, this.state.search.companyId, this.state.defaultEndTime, dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, this.state.day, this.state.defaultEndTime, dptId)
                )
                break;
            default:
            // do nothing
        }
    }

    // handleAdd = () => {
    //     let newData = {};
    //     newData['startTime'] = this.state.day;
    //     newData['companyId'] = this.props.userCompanyId;
    //     return this.props.handleAdd(newData).then(
    //         (res) => {
    //             const dateTime = res.payload.startTime;
    //             this.setState({
    //                 selectedDate: getMoment()(dateTime),
    //                 day: formatDate(dateTime),
    //                 defaultEndTime: formatDate(moment(dateTime).add(1, 'd')),
    //             })
    //             this.props.handleSelect(formatDate(dateTime), this.getCompanyId())
    //             this.props.getManufactureTasks(this.getCompanyId(), formatDate(dateTime), formatDate(moment(dateTime).add(1, 'd')));
    //         }
    //     );
    // }

    onhandleRowUpdate = (newData, oldData) => {
        return this.props.handleRowUpdate(oldData.orderId, newData).then(
            (res) => {
                let tasks = _.filter(this.props.tasks, { 'orderId': newData.orderId })
                console.log(tasks);
                _.forEach(tasks, (value) => {
                    value.dptId = newData.dptId
                    console.log(value);
                    this.props.updateManufactureTask(value).then(
                        (res) => {
                            this.props.getManufactureTasks(this.state.search.companyId, this.state.day, this.state.defaultEndTime, this.state.search.dptId);
                            this.props.handleSelect(this.state.day, this.state.search.companyId, this.state.defaultEndTime, this.state.search.dptId)
                        }
                    );
                })

            }
        );
    }

    onhandleRowDelete = (oldData) => {
        return this.props.handleRowDelete(oldData.orderId).then(
            (res) => this.props.handleSelect(this.state.day, this.state.search.companyId, this.state.defaultEndTime, this.state.search.dptId)
        );
    }

    // handleGeneratePop = () => {

    //     this.setState({
    //         generateOpen: true,
    //     })

    //     this.getMonthAndTempPlanDtls(this.state.yearMonth)
    // }

    // getMonthAndTempPlanDtls = async (yearMonth) => {

    //     const { handleGetPlan } = this.props;
    //     const year = yearMonth.substring(0, 4);
    //     const month = yearMonth.substring(5, 7);
    //     const monthPlans = await handleGetPlan(this.getCompanyId(), 'MONTH', year, month)
    //     const tempPlans = await handleGetPlan(this.getCompanyId(), 'TEMP', year, month)

    //     let dtls = []
    //     _.map(monthPlans.payload, p => { dtls = dtls.concat(p.manufacturePlanDtls) })
    //     _.map(tempPlans.payload, p => { dtls = dtls.concat(p.manufacturePlanDtls) })
    //     this.setState({ dtls: dtls })

    // }

    handlePopAdd = (event) => {
        let manufactureOrder = { ...this.state.manufactureOrder };
        manufactureOrder['startTime'] = formatDate(getMoment()());
        this.props.getServices(this.getCompanyId(), this.getPid())
        console.log(this.props.services)
        this.setState({
            createOrder: true,
            manufactureOrder: manufactureOrder,
        })
    }

    // handleCloseCreate = () => {
    //     this.setState({
    //         createOpen: false,
    //         generateOpen: false,
    //         manufactureOrder: {},
    //         dtls: [],
    //     })
    // }

    // handleSave = (event) => {
    //     let manufactureOrder = { ...this.state.manufactureOrder };
    //     manufactureOrder['companyId'] = this.props.userCompanyId;
    //     console.log(this.state.manufactureOrder)
    //     this.setState({
    //         manufactureOrder: manufactureOrder,
    //     })
    //     return this.props.handleAdd(manufactureOrder).then(
    //         (res) => {
    //             const dateTime = res.payload.startTime;
    //             this.setState({
    //                 createOpen: false,
    //                 selectedDate: dateTime,
    //                 day: formatDate(dateTime),
    //                 generateDate: getMoment()(),
    //             })
    //             this.props.handleSelect(formatDate(dateTime), this.getCompanyId())
    //         }
    //     );
    // }

    handleGenerate = (event) => {
        let newData = {};
        newData['startTime'] = formatDate(getMoment()())
        newData['companyId'] = this.getCompanyId();
        console.log(newData.startTime);
        return this.props.handleAdd(newData).then(
            (res) => {
                const dateTime = res.payload.startTime;
                this.setState({
                    generateOpen: false,
                    fromDate: getMoment()(dateTime),
                    toDate: getMoment()(dateTime),
                    day: formatDate(dateTime),
                    defaultEndTime: formatDate(moment(dateTime).add(1, 'd')),
                    manufactureOrder: {},
                })
                this.props.handleSelect(formatDate(dateTime), this.state.search.companyId, formatDate(moment(dateTime).add(1, 'd')), this.state.search.dptId).then((res) => {
                    // this.handleGenerateTask();
                    this.props.getManufactureTasks(this.state.search.companyId, formatDate(dateTime), formatDate(moment(dateTime).add(1, 'd')), this.state.search.dptId);
                })
            }
        );
    }

    handlePopDateChange = (date) => {
        let manufactureOrder = { ...this.state.manufactureOrder };
        manufactureOrder['startTime'] = formatDate(date);
        this.setState({
            manufactureOrder: manufactureOrder,
        })
    }

    handleGenerateDateChange = (date) => {
        this.setState({
            generateDate: date,
            yearMonth: formatMonth(date),
        })
        this.getMonthAndTempPlanDtls(formatMonth(date))
    }

    handleChangeOrder = (event) => {
        if (event.target.name === 'serviceId') {
            const service = this.props.services.find(item => { return item.serviceId === event.target.value });
            let manufactureOrder = { ...this.state.manufactureOrder };
            manufactureOrder[event.target.name] = event.target.value;
            manufactureOrder.serviceName = service.serviceName
            if (service.amoebaIndex !== this.props.indexes.subId) {
                this.setState({
                    planDtls: service.serviceDpts,
                    manufactureOrder: manufactureOrder,
                })
            } else {
                const serviceDpts = this.getCompanyId() ? this.props.departments.filter(d => d.companyId === this.getCompanyId()) : this.props.departments
                this.setState({
                    planDtls: serviceDpts,
                    manufactureOrder: manufactureOrder,
                })
            }
        } else if (event.target.name === 'dptId') {
            let manufactureOrder = { ...this.state.manufactureOrder };
            manufactureOrder[event.target.name] = event.target.value;
            this.setState({
                manufactureOrder: manufactureOrder,
            })
        } else if (event.target.name === 'allocation'
            || event.target.name === 'installId'
            || event.target.name === 'manipulateId') {
            let manufactureOrder = { ...this.state.manufactureOrder };
            manufactureOrder[event.target.name] = Number(event.target.value);
            this.setState({
                manufactureOrder: manufactureOrder,
            })
        } else {
            let manufactureOrder = { ...this.state.manufactureOrder };
            manufactureOrder[event.target.name] = event.target.value;
            this.setState({
                manufactureOrder: manufactureOrder,
            })
        }
    }

    handleSaveOrder = (event) => {
        let manufactureOrder = { ...this.state.manufactureOrder };
        manufactureOrder['companyId'] = this.props.userCompanyId;
        manufactureOrder['planId'] = 0
        console.log(this.state.manufactureOrder)
        return this.props.handleAdd(manufactureOrder).then(
            (res) => {
                this.setState({
                    createOrder: false,
                    manufactureOrder: [],
                    planDtls: [],
                    generateDate: getMoment()(),
                })
                this.props.handleSelect(this.state.day, this.state.search.companyId, this.state.defaultEndTime, this.state.search.dptId).then(
                    () => this.props.getManufactureTasks(this.state.search.companyId, this.state.day, this.state.defaultEndTime, this.state.search.dptId)
                )
            }
        );
    }

    handleOpenReport = async (event) => {

        const startTime = this.state.search.fromDate.format('YYYY-MM-DD')
        // const endTime = this.state.search.toDate.format('YYYY-MM-DD')
        let companyId = this.props.currentUserInfo.companyId
        if (this.state.isMainCompany) {
            companyId = undefined
        }
        await this.props.onhandleGetGTasksReport(companyId, undefined, startTime, this.state.defaultEndTime)
        this.props.onhandleOpenReport()

    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    render() {
        return (
            <div>
                <MOrderListComponent
                    manufactureOrders={this.props.manufactureOrders}
                    search={this.state.search}
                    departments={this.props.departments}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.props.companyList}
                    manufactureDicts={this.props.manufactureDicts}
                    indexes={this.props.indexes}
                    onhandleOpenFixAmount={this.handleOpenFixAmount.bind(this)}
                    onhandleGenerate={this.handleGenerate.bind(this)}
                    onhandleRowUpdate={this.onhandleRowUpdate.bind(this)}
                    onhandleRowDelete={this.onhandleRowDelete.bind(this)}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandlePopAdd={this.handlePopAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleTaskAdd.bind(this)}
                    onhandleOpenReport={this.handleOpenReport.bind(this)}
                    isMainCompany={this.state.isMainCompany}
                    tasks={this.props.tasks}
                    members={this.props.members}
                />
                <CreateTaskComponent
                    open={this.state.createOpen}
                    constant={this.props.constant}
                    task={this.state.task}
                    orders={this.props.manufactureOrders}
                    members={this.props.members}
                    service={this.state.service}
                    openType={this.state.openType}
                    departments={this.props.departments}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleDateChange={this.handleDateChangeTask.bind(this)}
                />
                <CreateMOrderComponent
                    plans={this.props.plans}
                    manufactureOrder={this.state.manufactureOrder}
                    planDtls={this.state.planDtls}
                    open={this.state.createOrder}
                    services={this.props.services}
                    companyId={this.props.userCompanyId}
                    departments={this.props.departments}
                    manipulates={this.props.manipulates}
                    installs={this.props.installs}
                    onhandleSave={this.handleSaveOrder.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleChange={this.handleChangeOrder.bind(this)}
                    onhandlePopDateChange={this.handlePopDateChange.bind(this)}
                />
                <GenerateMOrderComponent
                    generateDate={this.state.generateDate}
                    yearMonth={this.state.yearMonth}
                    dtls={this.state.dtls}
                    open={this.state.generateOpen}
                    onhandleGenerate={this.handleGenerate.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleGenerateDateChange={this.handleGenerateDateChange.bind(this)}
                />
                <MGenerateFixContainer
                    open={this.state.fixAmountOpen}
                    onhandleCloseFixAmount={this.handleCloseFixAmount.bind(this)}
                />
                <MTaskReportContainer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        manufactureOrders: state.manufactureOrder.orders,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        departments: state.department.departments.list,
        manufactureDicts: state.constant.manufactureDicts,
        userCompanyId: state.auth.currentUserInfo.companyId,
        plans: state.manufacturePlan.plans,
        tasks: state.manufactureTask.tasks,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        members: state.user.users.list,
        departmentList: state.department.departments.list,
        services: state.manufactureService.services,
        indexes: state.amoebaIndex.indexes.filter(index => {
            return index.subCode === 'ENGINEERINCOME'
        }),
        manipulates: state.manipulate.manipulates,
        installs: state.installation.installationList ? state.installation.installationList.list : [],

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getManufactureTasks: (companyId, startTime, endTime, dptId) => {
            dispatch(getManufactureTasks(companyId, startTime, endTime, dptId));
        },
        handleSelect: (startTime, companyId, endTime, dptId) => {
            // dispatch(getPlans(companyId, 'TEMP', getYear(startTime), null));
            return dispatch(getOrders(startTime, companyId, endTime, dptId));
        },
        getServices: (companyId, pid) => {
            return dispatch(getManufactureServices(companyId, SERVICE_TYPE_ALL, null, null, pid, null));
        },


        // handleGetStatus: () => {
        //     dispatch(getManufactureDictionaries());
        // },
        handleGetPlan: (companyId, planType, year, month) => {
            // const year = yearMonth.substring(0, 4);
            // const month = yearMonth.substring(5, 7);
            return dispatch(getPlans(companyId, planType, year, month));
        },

        handleGetTempPlan: (yearMonth, companyId) => {
            const year = yearMonth.substring(0, 4);
            const month = yearMonth.substring(5, 7);
            return dispatch(getPlans(companyId, 'TEMP', year, month));
        },
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, null, null, null, null, null, null));
        },
        handleRowUpdate: (orderId, newData) => {
            return dispatch(updateOrder(orderId, newData));
        },
        handleRowDelete: (orderId) => {
            return dispatch(deleteOrder(orderId));
        },
        handleAdd: (newData) => {
            return dispatch(addOrder(newData));
        },
        // getManufactureDictionaries: () => {
        //     dispatch(getManufactureDictionaries());
        // },
        getSubMembers: (query) => {
            dispatch(getUsers(query));
        },
        setUserSearch: (search) => {
            dispatch(setSearch(search));
        },
        delManufactureTask: (taskId) => {
            return dispatch(delManufactureTask(taskId));
        },
        addManufactureTask: (data) => {
            return dispatch(addManufactureTask(data));
        },
        updateManufactureTask: (data) => {
            return dispatch(updateManufactureTask(data));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        addManufactureTasks: (data, callback) => {
            return dispatch(addManufactureTasks(data, callback));
        },
        getManufactureServiceById: (serviceId) => {
            return dispatch(getManufactureServiceById(serviceId));
        },
        getManipulates: (companyId, installId, unitId, deviceId, manipulateName, status) => {
            return dispatch(getManipulates(companyId, installId, unitId, deviceId, manipulateName, status));
        },

        getInstallationsInManipulate: (companyId) => {
            return dispatch(getInstallationsInManipulate(companyId));
        },

        onhandleOpenReport: () => {
            return dispatch(openReport());
        },
        onhandleGetGTasksReport: (companyId, dptId, startTime, endTime) => {
            return dispatch(getTasksReports(companyId, dptId, startTime, endTime));
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MOrderListContainer)
