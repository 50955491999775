import React, { Component } from 'react';
import { connect } from 'react-redux';
import DepartmentInfo from '../../../components/SystemManagement/OrgManagement/DepartmentInfo'
import {
    createDepartment,
    updateDepartment,
    setDepartmentError,
    initDepartmentInfo,
    setCurrentDepartment,
    setDepartmentChange
} from "../../../redux/modules/department";
import { cleanmodal } from "../../../redux/modules/modal";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { deleteDepartment } from '../../../redux/modules/department';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { createMessage } from '@/redux/modules/message'
// import { findPath } from '../../utils/constant';

class DepartmentInfoContainer extends Component {

    static propTypes = {
        currentDepartment: PropTypes.object,
        departmentError: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleInitDepartmentInfo(this.props.parameters.type);
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (this.props.parameters.type === 'delete') {
            if (_.findIndex(Object.values(this.props.departmentError), function (o) { return o !== ''; }) < 0) {
                let doDelete = () => {
                    this.props.onhandleDepartmentChange(false);
                    this.props.ohandleDeleteDepartment().then(res => {
                        if (res && res.payload && res.payload.status === 200) {
                            this.props.onhandleCreateMessage('warning', '请同步更新配置：【管理分摊】【服务分摊】等。')
                        }
                    });
                }
                this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除部门[' + this.props.currentDepartment.dptName + ']吗?', doDelete, null, false);
            }
        } else {
            if (_.findIndex(Object.values(this.props.departmentError), function (o) { return o !== ''; }) < 0) {
                this.props.onhandleDepartmentChange(false);
                if (this.props.currentDepartment.dptId) {
                    this.props.onhandleUpdateDepartment();
                } else {
                    this.props.onhandleCreateDepartment().then(res => {
                        if (res && res.payload && res.payload.status === 201) {
                            this.props.onhandleCreateMessage('warning', '请同步更新配置：【管理分摊】【服务分摊】等。')
                        }
                    });
                }
            }
        }
    }

    handleSwitch = (event, field) => {
        this.props.onhandleDepartmentChange(true);
        // console.log(event.target.checked, field)
        let newDepartment;
        if (field === 'executable') {
            newDepartment = {
                ...this.props.currentDepartment,
                executable: event.target.checked
            };
            this.props.onhandleUpdateEditDepartment(newDepartment);
        }
    };

    handleChange = (event, field) => {
        this.props.onhandleDepartmentChange(true);
        let newDepartment;
        switch (field) {
            case 'dptName':
                newDepartment = {
                    ...this.props.currentDepartment,
                    dptName: event.target.value
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            case 'opentext':
                newDepartment = {
                    ...this.props.currentDepartment,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            case 'amoebaType':
                newDepartment = {
                    ...this.props.currentDepartment,
                    amoebaType: event.target.value
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            case 'amoebaLevel':
                newDepartment = {
                    ...this.props.currentDepartment,
                    amoebaLevel: parseInt(event.target.value)
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            case 'startDate':
                newDepartment = {
                    ...this.props.currentDepartment,
                    startDate: event
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            case 'endDate':
                newDepartment = {
                    ...this.props.currentDepartment,
                    endDate: event
                };
                this.props.onhandleUpdateEditDepartment(newDepartment);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        if (this.props.departmentChange) {
            if (window.confirm("数据未保存,确定要离开?")) {
                this.props.onhandleCancelToOrgChart();
            }
        } else {
            this.props.onhandleCancelToOrgChart();
        }
    };

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'dptName') {
            if (_.trim(this.props.currentDepartment.dptName) === '') {
                err['dptName'] = '名称不能为空'
            } else {
                err['dptName'] = ''
            }
        }

        if (this.props.parameters.type === 'delete') {
            if (!this.props.currentDepartment.endDate) {
                err['endDate'] = '有效结束时间不能为空'
            } else {
                if (this.props.currentDepartment.startDate) {
                    let startDate = new Date(this.props.currentDepartment.startDate)
                    let endDate = new Date(this.props.currentDepartment.endDate)
                    if (startDate > endDate) {
                        err['endDate'] = '有效结束时间要晚于有效开始时间';
                    } else {
                        err['endDate'] = '';
                    }
                } else {
                    err['endDate'] = '';
                }
            }
        } else {
            if (!this.props.currentDepartment.startDate) {
                err['startDate'] = '有效开始时间不能为空'
            } else {
                err['startDate'] = ''
            }
        }

        let newError = {
            ...this.props.departmentError,
            ...err
        }

        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <DepartmentInfo
                onhandleSave={this.handleSave.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCancel={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                departmentError={this.props.departmentError}
                currentDepartment={this.props.currentDepartment}
                parentNode={this.props.parentNode}
                onhandleSwitchChange={this.handleSwitch.bind(this)}
                departmentChange={this.props.departmentChange}
                operationType={this.props.parameters.type}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentDepartment: state.department.currentDepartment,
        departmentError: state.department.departmentError,
        parentNode: state.company.parentNode,
        departmentChange: state.department.departmentChange,
        parameters: state.modal.parameters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateDepartment: () => {
            return dispatch(createDepartment())
        },
        onhandleUpdateDepartment: () => {
            dispatch(updateDepartment())
        },
        onhandleCancelToOrgChart: () => {
            dispatch(cleanmodal())
        },
        onhandleSetError: (err) => {
            dispatch(setDepartmentError(err))
        },
        onhandleInitDepartmentInfo: (type) => {
            dispatch(initDepartmentInfo(type))
        },
        onhandleUpdateEditDepartment: (entity) => {
            dispatch(setCurrentDepartment(entity))
        },
        onhandleDepartmentChange: (isChanged) => {
            dispatch(setDepartmentChange(isChanged))
        },
        ohandleDeleteDepartment: () => {
            return dispatch(deleteDepartment())
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentInfoContainer)
