import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  content: {
    display: 'flex',
    height: '70%'
  },
  title:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: '24px'
  },
  subtitle: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  detail:{
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  spacer: {
    flexGrow: 1
  },
  divider: {
    margin: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  confirmButton: {
    marginRight: theme.spacing(1)
  },
  item: {
    padding: 0
  },
  inner: {
    minWidth: '100%'
  },
  card: {
    width: '100%'
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading:{
    display: 'block',
    textAlign: 'center'
  },
  select: {
    width: '50%',
    marginTop: 7
  }
});

export default withStyles(styles);
