import React, { Component } from 'react';
import { connect } from 'react-redux';
import MonthlyPlanList from "@/components/InspectionManagement/RiskInvestigation/RiskInvestigationList";
import PropTypes from "prop-types";
import {
    setSearch,
    getPlanResults
} from '@/redux/modules/inspection/riskInvestigationPlanMonthly';
import {
    startPlanDetails,
    getPlanDetails,
    changePlanStatus,
    changePlanDetailStatus,
    addPlanDetail,
    routeToDetailEdit,
    routeToShowRecords,
    setCurrentPlanDetail
} from '@/redux/modules/inspection/riskInvestigation';
import InspectionTempPlanDetailAddPannel from "./AddTempDetailPannel";

import { openTempPlanDetailAddPannel } from "@/redux/modules/inspection/riskInspectionTempPlanDetailAdd";
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { createMessage } from '@/redux/modules/message';
import { enumSort } from '@/utils/listUtils';
import _ from 'lodash';

class MonthlyPlanListContainer extends Component {
    static propTypes = {
        checklists: PropTypes.object
    };

    componentDidMount() {
        
    }

    handleEdit(event, id) {
        this.props.onhandleOpenEdit(id);
    }

    handleDelete = (event, dtl) => {
        const {
            onhandleChangePlanStatus,
            onhandleChangePlanDtlStatus,
            onhandleOpenConfirmDialog,
            onhandleGetPlanDetails,
            search
        } = this.props;
        let changeStatus = () => {
            // 软删除临时计划详情记录
            onhandleChangePlanDtlStatus(dtl.dtlId, 'inactive', () => {
                // 软删除对应计划记录
                onhandleChangePlanStatus(dtl.inspectionPlan.planId, 'inactive');
                onhandleGetPlanDetails(search);
            });
        }
        onhandleOpenConfirmDialog('删除确认', '确定要删除风险研判计划吗?', changeStatus, null, false);
    };

    handleStart = (event, dtl) => {
        const {
            onhandleGetPlanResults,
            onhandleStartPlanDetails,
            onhandleErrorMessage,
            onhandleGetPlanDetails,
            onhandleOpenConfirmDialog,
            search
        } = this.props;

        onhandleGetPlanResults(dtl.dtlId, (res) => {
            if (!res.list || !res.list.length || res.list.length <= 0) {
                onhandleErrorMessage('请先设置各部门检查人');
                return;
            }
            let hints = '';
            if (dtl && dtl.inspectionDptList && dtl.inspectionDptList.length) {
                const dpts = dtl.inspectionDptList.map(i => ({ dptId: i.dptId, count: 0 }));
                dpts.forEach(dpt => {
                    for (const result of res.list) {
                        if (dpt.dptId === result.dptId) {
                            dpt.count++
                        }
                    }
                })
                if (_.findIndex(dpts, dpt => (dpt.count <= 0)) >= 0) {
                    hints = '部分部门未设置检查人，';
                }
            }
            let startPlan = () => {
                onhandleStartPlanDetails({ dtlId: dtl.dtlId }, () => {
                    onhandleGetPlanDetails(search);
                });
            }
            onhandleOpenConfirmDialog('启动确认', hints + '确定启动计划吗?', startPlan, null, false);
        });
    }

    handleView = (event, planDetail) => {
        const {
            onhandleShowRecords,
            onhandleSetCurrentPlanDetail
        } = this.props;
        onhandleSetCurrentPlanDetail(planDetail);
        onhandleShowRecords(planDetail);
    };

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            ...value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleAdd(planDetail) {
        const { onhandleCreateTempPlan, onhandleGetPlanDetails, categoryList, search } = this.props;
        if (!planDetail || !planDetail.inspectionCompanyIdList || !planDetail.inspectionCompanyIdList.length) {
            return;
        }
        // 包装 临时计划 请求对象
        let addDetailList = [];
        for (const companyId of planDetail.inspectionCompanyIdList) {
            const dptUserList = [];
            planDetail.inspectionDptUserList.forEach(department => {
                const wrappedDpt = {
                    dptId: department.dptId,
                    dptUser: department.dptUser ? department.dptUser.map(u => u.userId) : []
                };
                // 需要为公司分配部门：机关部门全分配，基层部门只分配给对应单位
                if (!department.companyPid) {
                    dptUserList.push(wrappedDpt);
                }
                if (Boolean(department.companyPid) && companyId === department.companyId) {
                    dptUserList.push(wrappedDpt);
                }
            });
            const addDetail = {
                companyId: companyId,
                inventoryId: planDetail.inventoryId,
                inspectHead: planDetail.inspectHead,
                startTime: planDetail.startTime,
                inspectTime: planDetail.inspectTime,
                inspectType: planDetail.inspectType,
                inspectionDptUserList: dptUserList,
                opentext: planDetail.opentext
            }
            if (planDetail.targetId && planDetail.targetId !== '') {
                addDetail['targetId'] = planDetail.targetId;
            }
            if (_.find(categoryList, { 'pcode': planDetail.inspectType }) && planDetail.inspectTypeCat !== '') {
                addDetail.inspectType = planDetail.inspectTypeCat;
            }
            addDetailList.push(addDetail);
        }
        // 按时间频次排查
        if (planDetail.frequencyDate && planDetail.planDates && planDetail.planDates.length && planDetail.planDates.length > 0) {
            let freqDetailList = [];
            planDetail.planDates.forEach(planDate => {
                addDetailList.forEach(detail => {
                    const addDetail = {
                        ...detail,
                        startTime: planDate.startTime,
                        inspectTime: planDate.inspectTime
                    }
                    freqDetailList.push(addDetail);
                });
            });
            addDetailList = freqDetailList;
        }

        onhandleCreateTempPlan(addDetailList, () => {
            onhandleGetPlanDetails(search)
        })
    }

    handleOpenTempPlanAddPannel() {
        const { onhandleOpenTempPlanDetailAddPannel } = this.props
        onhandleOpenTempPlanDetailAddPannel(this.handleAdd.bind(this), null)
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users,
            categoryList
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyName : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            case 'inspectType':
                const listTypeObj = _.find(constant.inspectionDictionaries, { 'code': colValue.substr(0, 5) });
                let listTypeStr = listTypeObj ? listTypeObj.name : '';
                let catCodeStr = '';
                let fullTypeStr = listTypeStr;
                if (listTypeStr) {
                    if (_.find(constant.inspectionDictionaries, { 'pcode': colValue.substr(0, 5) })) {
                        catCodeStr = _.find(constant.inspectionDictionaries, { 'code': colValue });
                        fullTypeStr = listTypeStr + '（' + catCodeStr.name + '）';
                    }
                }
                value = fullTypeStr ? fullTypeStr : '';
                break;
            case 'category':
                value = '-'
                const mapping = _.find(constant.inspectionDictionaries, { 'code': colValue });
                if (mapping && _.find(categoryList, { 'code': mapping.code })) {
                    value = mapping.name;
                }
                break;
            case 'user':
                const user = _.find(users.list, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'status':
                if (colValue === 'done') {
                    value = '已完成'
                } else if (colValue === 'expired') {
                    value = '已确认'
                } else if (colValue === 'active') {
                    value = '进行中'
                } else if (colValue === 'finalized') {
                    value = '未启动'
                } else if (colValue === 'invalid') {
                    value = '已过期'
                } else {
                    value = '无效'
                }
                break;
            default:
            // do nothing
        }
        return value;
    };
    handleSelect = (value, field) => {
        let newSearch = { ...this.props.search };
        switch (field) {
            case 'size':
                newSearch.size = value.target.value
                newSearch.page = 0
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            page: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    render() {
        return (
            <>
                <MonthlyPlanList
                    lists={this.props.planDetails}
                    inspectionDptMapping={this.props.inspectionDptMapping}
                    userProfile={this.props.userProfile}
                    onhandleListMapping={this.handleListMapping.bind(this)}
                    // onSetup={this.handleSetup.bind(this)}
                    onOpenTempPlanAddPannel={this.handleOpenTempPlanAddPannel.bind(this)}
                    onStart={this.handleStart.bind(this)}
                    onView={this.handleView.bind(this)}
                    onEdit={this.handleEdit.bind(this)}
                    onDelete={this.handleDelete.bind(this)}
                    search={this.props.search}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                />
                <InspectionTempPlanDetailAddPannel />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        users: state.user.users,
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        categoryList: state.inspection.categoryList,
        planDetails: enumSort(['active', 'finalized', 'done', 'expired', 'invalid'], state.inspectionPlan.planDetails, 'status'),
        currentPlanResults: state.inspectionPlanMonthly.planResults,
        currentPlanDetail: state.inspectionPlan.currentPlanDetail,
        // search: state.inspection.search,
        search: state.inspectionPlanMonthly.search,
        userProfile: state.auth.userProfile
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        // onhandleGetUserList: (query) => {
        //     dispatch(getUsers(query))
        // },
        onhandleGetPlanDetails: (query) => {
            dispatch(getPlanDetails(query))
        },
        onhandleOpenEdit: (id) => {
            dispatch(routeToDetailEdit(id));
        },
        onhandleShowRecords: (detail) => {
            dispatch(routeToShowRecords(detail));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleSetCurrentPlanDetail: (entity) => {
            dispatch(setCurrentPlanDetail(entity))
        },
        onhandleOpenTempPlanDetailAddPannel: (confirmCallback, closeCallback) => {
            dispatch(openTempPlanDetailAddPannel(confirmCallback, closeCallback))
        },
        onhandleCreateTempPlan: (planDetail, callback) => {
            // true => 新建临时计划 & 临时计划详情
            dispatch(addPlanDetail(planDetail, callback, true))
        },
        onhandleChangePlanStatus: (id, status, callback) => {
            dispatch(changePlanStatus(id, status, callback));
        },
        onhandleChangePlanDtlStatus: (id, status, callback) => {
            dispatch(changePlanDetailStatus(id, status, callback));
        },
        onhandleGetPlanResults: (dtlId, callback) => {
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            }, callback)
            )
        },
        onhandleStartPlanDetails: (query, callback) => {
            dispatch(startPlanDetails(query, callback))
        },
        onhandleErrorMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyPlanListContainer)
