import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Avatar,
    FormHelperText
} from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { getDateByTimestamp, getDateByString, getLastDayInYear, getYear } from '../../../../utils/datetime';
import * as _ from 'lodash';
import { formatRoleGroupTreeForOperationRole } from '../../../../utils/orgchart';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';


const OperationGoalPlanDetailEdit = ({
    classes,
    companyList,
    operationPlanDetails,
    procat,
    userProfile,
    roleList,
    roleTree,
    newPlanChange,
    onhandleSeletTarget,
    onhandleUpdate,
    onhandleDelete,
    onhandleBack,
    onhandleSave,
    catList,
    accCatList,
    target
}) => {

    const tableRef = React.createRef();

    const renderCompany = (plan) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === plan.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDate = (detail) => {
        return detail.targetDate ? getDateByString(detail.targetDate) : getDateByTimestamp(detail.targetDate, true)
    }

    const renderProCat = (device) => {
        if (procat && procat.length > 0 && device.proCat) {
            let cat = _.find(procat, { 'code': device.proCat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderCat = (device) => {
        if (catList && catList.length > 0 && device.cat) {
            let cat = _.find(catList, { 'code': device.cat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderAccCat = (acc) => {
        let label = '';
        if (accCatList && accCatList.length > 0) {
            let value = _.find(accCatList, function (o) { return o.code === acc.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // const renderIsCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    const renderRoles = (roles) => {
        const list = [];
        roles.forEach(role => {
            let roleStr = '';
            roleStr = _.find(roleList, { 'roleId': role.roleId }) ? _.find(roleList, { 'roleId': role.roleId }).roleName : '';
            list.push(roleStr);
        })
        return list.join(', ');
    }

    const renderRoleTree = (row) => {
        let newRoleTree = _.find(roleTree, ['companyId', row.companyId])
        let newTree = newRoleTree.roleGroups
        return formatRoleGroupTreeForOperationRole(newTree)
    }

    const renderRoleList = (roles) => {
        let list = []
        roles.forEach(role => {
            role.value = role.value ? role.value : role.roleId;
            role.label = role.label ? role.label : role.roleName;
            role.roleId = role.roleId ? role.roleId : role.value;
            role.roleName = role.roleName ? role.roleName : role.label;
            list.push(role)
        })
        return list
    }

    const columns = [
        {
            field: 'type',
            render: row => row ? row.device && row.device.deviceId ? <Avatar className={classes.pic1}>设备</Avatar> : <Avatar className={classes.pic2}>部件</Avatar> : '',
            width: '2em',
            editable: 'never'
        },
        {
            title: '单位',
            field: 'companyId',
            render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
            editable: 'never'
        },
        {
            title: '负责角色',
            field: 'roles',
            render: row => row ? <Typography>{renderRoles(row.roles)}</Typography> : '',
            align: 'left',
            width: '200px',
            validate: rowData => (!rowData.roles || rowData.roles.length === 0) ? { isValid: false, helperText: '负责角色不能为空' } : true,
            editComponent: (props) => (
                <div>
                    <TreeSelect
                        showArrow
                        className={classes.select}
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        allowClear
                        treeLine
                        value={renderRoleList(props.rowData.roles)}
                        labelInValue
                        treeData={renderRoleTree(props.rowData)}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        multiple={true}
                        onChange={(value) => {
                            let newRoleList = [];

                            value.forEach(selected => {
                                newRoleList.push({ roleId: selected.value, roleName: selected.label, value: selected.value, label: selected.label })
                            });
                            props.rowData.roles = newRoleList;
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }
                        }
                    />
                    {props.helperText !== '' ? <FormHelperText
                        error={true}
                    >
                        {props.helperText}
                    </FormHelperText> : null}
                </div>
            )
        },
        {
            title: '执行时间',
            field: 'targetDate',
            editable: 'onUpdate',
            validate: rowData => (!rowData.targetDate || rowData.targetDate === '') ? { isValid: false, helperText: '计划执行时间不能为空' } : true,
            render: row => row ? <Typography>{renderDate(row)}</Typography> : '',
            editComponent: (props) => (
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        autoOk
                        InputProps={{ readOnly: true, }}
                        className={classes.textField}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        name='tenabletimeselect'
                        value={props.rowData.targetDate}
                        onChange={value => props.onChange(value)}
                        KeyboardButtonProps={{
                            'aria-label': '修改时间',
                        }}
                        minDate={new Date(`${getYear() + 1}-01-01`)}
                        maxDate={new Date(getLastDayInYear(getYear() + 1))}
                    />
                </MuiPickersUtilsProvider>
            )
        },
        {
            title: '预算(元)',
            field: 'budget',
            editable: 'onUpdate',
            render: row => row ? <Typography>{row.budget}</Typography> : '',
            validate: rowData => (!(rowData && _.isNumber(rowData.budget))) ? { isValid: false, helperText: '预算格式不正确' } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    value={props.rowData.budget}
                    type="number"
                    onChange={e => props.onChange(_.toNumber(e.target.value))}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField>
            )
        },
        {
            title: '描述',
            field: 'opentext',
            editable: 'onUpdate',
            render: row => row ? <Typography>{row.opentext}</Typography> : '',
        }
    ]

    const tableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
    }
    const tableStyle = {
        ...style
    }

    const components = {
        Toolbar: props => (
            <div>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'新建年度运维计划明细'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <MTableToolbar {...props} />
            </div>
        )
    }

    const detailPannel = (row) => {
        if (row.device && row.device.deviceId) {
            return <MaterialTable
                options={detailOptions}
                columns={rendorDeviceColumns}
                data={[row.device]}
                style={tableStyle}
            />
        } else if (row.dictionary && row.dictionary.code) {
            return <MaterialTable
                options={detailOptions}
                columns={rendorAccTypeColumns}
                data={[row.dictionary]}
                style={tableStyle}
            />
        } else if (row.accessory && row.accessory.accId) {
            return <MaterialTable
                options={detailOptions}
                columns={rendorAccColumns}
                data={[row.accessory]}
                style={tableStyle}
            />
        }
    }

    const rendorDeviceColumns = [
        {
            title: '',
            width: '4em',
            render: row => <Typography></Typography>
        },
        {
            title: '设备编号',
            field: 'deviceNo',
            render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
            editable: 'never'
        },
        {
            title: '设备名称',
            field: 'deviceName',
            render: row => row ? <Typography>{row.deviceName}</Typography> : '',
            editable: 'never'
        },
        {
            title: '设备位号',
            field: 'tagNo',
            render: row => row ? <Typography>{row.tagNo}</Typography> : '',
            editable: 'never'
        },
        {
            title: '类别',
            field: 'cat',
            render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
            editable: 'never'
        },
        {
            title: '专业类别',
            field: 'proCat',
            render: row => row ? <Typography>{renderProCat(row)}</Typography> : '',
            editable: 'never'
        },
    ];

    const rendorAccColumns = [
        {
            title: '',
            width: '4em',
            render: row => <Typography></Typography>
        },
        {
            title: '部件编号',
            field: 'accNo',
            render: row => row ? <Typography>{row.accNo}</Typography> : '',
            editable: 'never'
        },
        {
            title: '部件名称',
            field: 'deviceName',
            render: row => row ? <Typography>{row.accName}</Typography> : '',
            editable: 'never'
        },
        {
            title: '型号',
            field: 'model',
            render: row => row ? <Typography>{row.model}</Typography> : '',
            editable: 'never'
        },
        {
            title: '部件类别',
            field: 'cat',
            render: row => row ? <Typography>{renderAccCat(row)}</Typography> : '',
            editable: 'never',
        },
        // {
        //     title: '通用部件',
        //     field: 'isCommon',
        //     render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
        //     editable: 'never'
        // }
    ];

    const rendorAccTypeColumns = [
        {
            title: '',
            width: '4em',
            render: row => <Typography></Typography>
        },
        {
            title: '部件类别名称',
            field: 'name',
            render: row => row ? <Typography>{row.name}</Typography> : '',
            editable: 'never'
        }
    ];

    const detailOptions = {
        ...options,
        toolbar: false,
        paging: false,
        showTitle: false,
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid >
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialtable}
                    title=''
                    columns={columns}
                    data={operationPlanDetails}
                    options={tableOptions}
                    components={components}
                    localization={localization}
                    style={tableStyle}
                    detailPanel={detailPannel}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleUpdate(newData)
                                resolve()
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDelete(oldData)
                                resolve()
                            }),
                    }}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: '新建',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleSeletTarget(event)
                            },
                        }
                    ]
                    }
                >
                </MaterialTable>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={!newPlanChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>
                </Grid>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={newPlanChange} />
        </div >
    );

};
OperationGoalPlanDetailEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired,
    procat: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    roleList: PropTypes.array.isRequired,
    roleTree: PropTypes.array.isRequired,
    newPlanChange: PropTypes.bool.isRequired,
    operationPlanDetails: PropTypes.array.isRequired,
    onhandleSeletTarget: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired,
    accCatList: PropTypes.array.isRequired,
};

export default (withStyles(OperationGoalPlanDetailEdit));