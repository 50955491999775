import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MajorHazardHeader from "../../../containers/MajorHazardSources/MajorHazardInfo/MajorHazardHeader";
import MajorHazardList from "../../../containers/MajorHazardSources/MajorHazardInfo/MajorHazardList";

const MajorHazardInfo = props => {

    const { classes } = props;

    return (
        <div className={classes.root}>
            <MajorHazardHeader />
            <div className={classes.content}>
                <MajorHazardList />
            </div>
        </div>
    );
};
MajorHazardInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(MajorHazardInfo);
