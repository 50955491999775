import React from 'react';
import {
    Grid, Typography, TextField, MenuItem
} from '@material-ui/core';
import withStyles from '@/components/SystemManagement/LabourPayment/styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "@/utils/mtable";
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import zhCN from 'date-fns/locale/zh-CN';
// import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash'

const LabourPaymentConfigComponent = props => {
    const { classes,
        records,
        companyList,
        currentDpts,
        onhandleChange,
        disableCompany,
        userName,
        onhandleUpdate,
        onhandleDelete,
        onhandleUpload,
        userProfile,
        companyId,
        onhandleSearch,
        onhandleClearSearch,
        onhandleSelectUser,
        exportCsv,
        dptId
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbarButtonAlignment: 'left',
        tableLayout: 'fixed'
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'labour_payment_config' })
        return permission && permission.action === 'W'
    }

    const renderCompany = () => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单位',
                field: 'companyName',
                editable: 'never',
                width: 100,
                render: row => <Typography>{renderCompany()}</Typography>
            }, 
            {
                title: '班组',
                field: 'dptName',
                editable: 'never',
                width: 150,
                render: row => <Typography>{row.dptName}</Typography>
            },
            {
                title: '姓名',
                field: 'userId',
                editable: 'never',
                width: 100,
                render: row => <Typography>{row.userName}</Typography>
            },
            {
                title: '日工资',
                field: 'item1',
                width: 120,
                type: 'numeric',
                validate: rowData => (!rowData.item1 || rowData.item1 === 0) ? { isValid: false, helperText: '日工资不能为0' } : true,
                render: row => <Typography>{row.item1.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        value={props.rowData.item1 ? props.rowData.item1 : 0}
                        name='item1'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '保险费用',
                field: 'item2',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item2 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item2 ? props.rowData.item2 : 0}
                        name='item2'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '餐补',
                field: 'item3',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item3 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item3 ? props.rowData.item3 : 0}
                        name='item3'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '管理费用',
                field: 'item4',
                width: 120,
                type: 'numeric',
                render: row => <Typography>{(row.item4 || 0).toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        type="number"
                        value={props.rowData.item4 ? props.rowData.item4 : 0}
                        name='item4'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            }

        ]

        return columns

    }

    const assemblyData = () => {
        return records;
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>、

                <MaterialTable
                className={classes.materialtable}
                columns={assemblycolumns()}
                data={assemblyData()}
                options={tableOptions}
                localization={tableLocalization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                title={''}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h3">
                                劳务人员工资配置
                                    </Typography>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        单位:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='companyId'
                                        InputProps={{ readOnly: disableCompany }}
                                        onChange={event => onhandleChange(event, 'company')}
                                        value={companyId}
                                    >
                                        {companyList.map(ele => (
                                            <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        部门:
                                 </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='dptId'
                                        onChange={event => onhandleChange(event, 'dptId')}
                                        value={dptId}
                                    >
                                        {currentDpts.map(ele => (
                                            <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        劳务人员:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onClick={event => onhandleSelectUser(event)}
                                        value={userName || ''}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                actions={[
                    {
                        icon: 'search',
                        tooltip: '搜索记录',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleSearch()
                        }
                    },
                    {
                        icon: 'clear_all',
                        tooltip: '清空搜索条件',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleClearSearch()
                        }
                    },
                    {
                        icon: 'cloud_download',
                        tooltip: '下载工资配置模板',
                        hidden: !checkPermission(),
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            exportCsv()
                        }
                    },
                    {
                        icon: 'backup',
                        tooltip: '导入工资配置',
                        hidden: !checkPermission(),
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleUpload()
                        }
                    }
                ]}
                editable={checkPermission() ?
                    {
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleUpdate(newData)
                            }),
                        onRowDelete: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleDelete(newData)
                            })
                    } : {}
                }
            >
            </MaterialTable>

        </div >
    )
}

LabourPaymentConfigComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

LabourPaymentConfigComponent.defaultProps = {
    records: []
};

export default withStyles(LabourPaymentConfigComponent);