import React, { Component } from 'react';
import { connect } from 'react-redux';
import ManufactureMonthlyPlan from '@/components/ManufactureManagement/ManufacturePlan/MMonthlyPlanComponent';
import { addOrder } from '@/redux/modules/manufacture/manufactureOrder';
import MOrderDtlListComponent from "@/components/ManufactureManagement/ManufactureOrder/MOrderDtlList";
import { addDayPlans, getPlans, getRangerOrders } from '@/redux/modules/manufacture/manufacturePlan';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { judgeMainCompany } from '@/utils/manufactureUtils'
import { getManufactureTasks } from '@/redux/modules/manufacture/manufactureTask';
import {
    getMoment, convertToMoment, formatDate, formatYear
    , formatOnlyMonth, formatOnlyDay, getDateByString, formatMonth
} from '@/utils/datetime';
import { updateOrder } from '@/redux/modules/manufacture/manufactureOrder';
import moment from "moment";
import PropTypes from 'prop-types';
import { getOrders } from '@/redux/modules/manufacture/manufactureOrder';
import { filterAmoebaCompany } from '@/utils/constant';
import * as _ from 'lodash';

class MMonthlyPlanContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            defualtEndTime: '',
            day: '',
            companyId: this.props.currentUserInfo.companyId,
            isOpen: false,
            rows: [],
        }
    }

    componentWillMount() {
        this.getPlans(this.state.selectedDate, this.state.companyId)
    }

    getPlans(date, companyId) {
        this.props.getPlans(companyId, 'DAY', formatYear(date),
            formatOnlyMonth(date)).then(
                (res) => {
                    console.log(res.payload)
                    const plans = res.payload
                    let rows = []
                    if (plans.length > 0) {
                        console.log(plans)
                        const planFirst = _.find(plans, plan => { return plan.day === 1 })
                        _.forEach(planFirst.manufacturePlanDtls, data => {
                            let row = {
                                'serviceName': data.service.serviceName,
                                'amount': 0,
                                'allocation1': data.output,
                                'serviceId': data.serviceId,
                                'unit': data.service.unit,
                            }
                            rows.push(row)
                        })
                        _.forEach(rows, row => {
                            _.forEach(plans, plan => {
                                _.forEach(plan.manufacturePlanDtls, data => {
                                    if (data.serviceId === row.serviceId) {
                                        row['allocation' + plan.day] = data.output
                                        row.amount += data.output
                                    }
                                })
                            })
                        })
                        this.setState({ rows: rows })
                        console.log(rows)
                    } else {
                        Promise.all([
                            this.props.getPlans(companyId, 'MONTH', formatYear(date), formatOnlyMonth(date)),
                            this.props.onhandleGetMonthlyPlan(companyId, date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD'))]
                        ).then(res => {
                            let rows = []
                            let monthPlanDtls = []
                            if (res[0].payload && res[0].payload.length > 0) {
                                monthPlanDtls = res[0].payload[0].manufacturePlanDtls
                            }
                            const rangeOrders = res[1].payload
                            if (rangeOrders !== undefined) {
                                let seviceId = 0
                                let row = {}
                                _.forEach(rangeOrders, data => {
                                    if (seviceId !== data.serviceId) {
                                        // 新起一行
                                        if (seviceId !== 0) {
                                            rows.push(row)
                                        }
                                        let service = data['manufactureService']
                                        row = {
                                            'serviceName': service['serviceName'],
                                            'amount': 0,
                                            'unit': service['unit']
                                        }
                                        let lastDay = date.endOf('month').daysInMonth();
                                        for (let index = 1; index <= lastDay; index++) {
                                            row['allocation' + index] = 0
                                        }
                                        seviceId = data.serviceId;
                                        const monthService = _.find(monthPlanDtls, i => { return i.serviceId === seviceId })
                                        if (monthService) row['monthAmount'] = monthService.output
                                    }
                                    row['amount'] += data['allocation']
                                    row['allocation' + parseInt(formatOnlyDay(data.startTime))] += data['allocation']
                                });
                                rows.push(row)
                                this.setState({ rows: rows })
                            }
                        }
                        )
                    }
                }
            )
    }


    handleMonthChange(date) {
        let newDate = convertToMoment(date)
        this.setState({
            selectedDate: newDate
        })
        this.getPlans(newDate, this.state.companyId)
        // this.props.onhandleGetMonthlyPlan(
        //     this.state.companyId,
        //     newDate.startOf('month').format('YYYY-MM-DD'),
        //     newDate.endOf('month').format('YYYY-MM-DD')
        // )
    }

    handleCompanyChange = (event) => {
        this.setState({
            companyId: event.target.value
        })
        this.getPlans(this.state.selectedDate, event.target.value)
        // this.props.onhandleGetMonthlyPlan(
        //     event.target.value,
        //     this.state.selectedDate.startOf('month').format('YYYY-MM-DD'),
        //     this.state.selectedDate.endOf('month').format('YYYY-MM-DD')
        // )
    }

    getCompanyId = () => {
        const company = this.props.companyList.find(item => {
            return item.companyId === this.props.currentUserInfo.companyId
        });
        return company.pid === null ? null : company.companyId
    }

    handleGenerate = (event) => {
        this.props.createloading();
        let newData = {};
        newData['startTime'] = formatDate(moment(this.state.selectedDate).add(-1, 'months'));
        //newData['startTime'] = '2021-01-28'
        newData['companyId'] = this.getCompanyId();
        console.log(newData);
        return this.props.handleAdd(newData).then(
            () => this.props.cleanloading()
        )
    }

    handleAddPlans = (event) => {
        let newData = {};
        newData['startTime'] = formatDate(getMoment()())
        newData['companyId'] = this.getCompanyId();
        console.log(newData);
        return this.props.handleAddPlans(newData)
    }

    handleEditHrplan(event, index) {
        let day = formatMonth(this.state.selectedDate);
        day = getDateByString(day + '/' + index);
        day = day.replaceAll('/', '-')
        const endDate = formatDate(moment(day).add(1, 'd'));
        console.log(day)
        console.log(endDate)
        this.setState({ isOpen: true, day: day, defualtEndTime: endDate })
        // 获取公司生产任务
        this.props.getOrders(day, this.getCompanyId()).then(
            () => this.props.getManufactureTasks(this.getCompanyId(), day, endDate)
        )
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    onhandleRowUpdate = (newData, oldData) => {
        return this.props.handleRowUpdate(oldData.orderId, newData).then(
            (res) => {
                this.props.getManufactureTasks(this.getCompanyId(), this.state.day, this.state.defualtEndTime);
                this.props.getOrders(this.state.day, this.getCompanyId())
            }
        );
    }

    render() {
        const { companyList, currentUserInfo } = this.props;
        const isMainCompany = judgeMainCompany(companyList, currentUserInfo.companyId);
        return (
            <div>
                <ManufactureMonthlyPlan
                    rangeOrders={this.props.rangeOrders}
                    selectedDate={this.state.selectedDate}
                    companyId={this.state.companyId}
                    plans={this.state.rows}
                    companyList={this.props.companyList}
                    isMainCompany={isMainCompany}
                    onhandleMonthChange={this.handleMonthChange.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleGenerate={this.handleGenerate.bind(this)}
                    onhandleAddPlans={this.handleAddPlans.bind(this)}
                    onhandleEditHrplan={this.handleEditHrplan.bind(this)}
                />
                <MOrderDtlListComponent
                    open={this.state.isOpen}
                    manufactureOrders={this.props.manufactureOrders}
                    tasks={this.props.tasks}
                    manufactureDicts={this.props.manufactureDicts}
                    departments={this.props.departments}
                    indexes={this.props.indexes}
                    day={this.state.day}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleRowUpdate={this.onhandleRowUpdate.bind(this)}
                ></MOrderDtlListComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyList: filterAmoebaCompany(state.company.companyList.list),
        currentUserInfo: state.auth.currentUserInfo,
        rangeOrders: state.manufacturePlan.rangeOrders,
        plans: state.manufacturePlan.plans,
        monthlyPlanDay: state.manufacturePlan.monthlyPlanDay,
        manufactureOrders: state.manufactureOrder.orders,
        tasks: state.manufactureTask.tasks,
        manufactureDicts: state.constant.manufactureDicts,
        departments: state.department.departments.list,
        indexes: state.amoebaIndex.subs.filter(index => {
            return index.subCode === 'ENGINEERINCOME'
        }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetMonthlyPlan: (companyId, startDate, endDate) => {
            return dispatch(getRangerOrders(companyId, startDate, endDate));
        },
        getPlans: (companyId, planType, year, month) => {
            return dispatch(getPlans(companyId, planType, year, month));
        },
        handleAdd: (newData) => {
            return dispatch(addOrder(newData));
        },
        handleAddPlans: (newData) => {
            return dispatch(addDayPlans(newData));
        },
        getOrders: (startTime, companyId) => {
            return dispatch(getOrders(startTime, companyId));
        },
        getManufactureTasks: (companyId, startTime, endTime) => {
            dispatch(getManufactureTasks(companyId, startTime, endTime));
        },
        handleRowUpdate: (orderId, newData) => {
            return dispatch(updateOrder(orderId, newData));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MMonthlyPlanContainer);
