import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate, formatDate_hms } from '@/utils/datetime';
import * as _ from 'lodash'


const WarehousingComponent = props => {
    const { classes,
        selectedDate,
        // onhandleOpen,
        onhandleDateChange,
        instockList,
        companies,
        departments,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: true,
    }
    const tableStyle = {
        ...style
    }

    const renderDepartment = (dptId) => {

        if (departments) {
            const department = _.find(departments, { 'dptId': dptId })
            if (department) return department.dptName
        }
        return dptId
    }

    const renderCompany = (companyId) => {
        if (companies) {
            const company = _.find(companies, { 'companyId': companyId })
            if (company) return company.companyAlias
        }
        return companyId
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单据号',
                field: 'billNo',
                render: row => <Typography>{row.billNo}</Typography>
            },
            {
                title: '入库分厂',
                field: 'instockCompany',
                render: row => <Typography>{renderCompany(row.instockCompany)}</Typography>
            },
            {
                title: '入库班组',
                field: 'instockDpt',
                render: row => <Typography>{renderDepartment(row.instockDpt)}</Typography>
            },
            {
                title: '产品',
                field: 'materialName',
                render: row => <Typography>{row.material.materialName}</Typography>

            },
            // {
            //     title: '规格',
            //     field: 'specification',
            //     render: row => <Typography>{row.material.specification}</Typography>

            // },
            {
                title: '应收数量',
                field: 'mustQty',
                align: 'right',
                render: row => <Typography>{row.mustQty}</Typography>
            },
            {
                title: '实收数量',
                field: 'realQty',
                align: 'right',
                render: row => <Typography>{row.realQty}</Typography>
            },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit ? row.unit.unitName : ""}</Typography>
            },
            {
                title: '入库时间',
                field: 'instockDate',
                render: row => <Typography>{formatDate(row.instockDate)}</Typography>
            },
            {
                title: '创建时间',
                field: 'createTime',
                render: row => <Typography>{formatDate_hms(row.createTime)}</Typography>
            }
        ]

        return columns

    }

    const assemblyData = () => {

        let instockDtls = []
        if (instockList) {
            for (const instock of instockList) {
                for (let dtl of instock.instockDtls) {
                    dtl.billNo = instock.billNo
                    dtl.instockDate = instock.instockDate
                    instockDtls.push(dtl)
                }
            }
        }
        return instockDtls
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>生产入库列表</Typography>}
                        actions={[
                            // rowData => (
                            //     {
                            //         icon: 'description',
                            //         tooltip: '查看',
                            //         onClick: (event, rowData) => {
                            //             onhandleOpen(rowData)
                            //         }
                            //     }
                            // )

                        ]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

WarehousingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

WarehousingComponent.defaultProps = {
    instockList: []
};

export default withStyles(WarehousingComponent);