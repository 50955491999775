import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProcessList from "../../../components/SystemManagement/ProcessManagement/ProcessList";
import PropTypes from "prop-types";
import { initCreate, getProcesses, initUpdate, deleteProcess } from '../../../redux/modules/process';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
class ProcessListContainer extends Component {
    static propTypes = {
        processs: PropTypes.object,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleInitProcessList({
            offset: 0,
            size: 5,
            sort: '-update_time'
        });
    }

    detailedInfo(event, id) {
        this.props.onhandleOpen(id);
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    deleteProcess(event, id, name) {
        let doDelete = () => {
            this.props.onhandleDeleteProcess(id, name);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除流程[' + name + ']吗?', doDelete, null, false);
    }

    render() {
        return (
            <ProcessList
                processes={this.props.processes}
                onDetailedInfo={this.detailedInfo.bind(this)}
                onDelete={this.deleteProcess.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                userProfile={this.props.userProfile}
                getProcessList={this.props.onhandleInitProcessList}
                constants={this.props.constants}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        processes: state.process.processList,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitProcessList: (query) => {
            dispatch(getProcesses(query))
        },
        onhandleOpen: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleDeleteProcess: (id, name) => {
            dispatch(deleteProcess(id, name));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProcessListContainer)
