import React from 'react';
import {
    TextField,
    Grid,
    MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import withStyles from './styles';

const MajorHazardDocPage = (props) => {
    const {
        classes,
        onhandleChange,
        onhandleCheck,
        majorHazardDocPage,
        majorHazardDocPageError,
        doctype,
    } = props;

    return (<div>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    error={majorHazardDocPageError.docName !== ''}
                    helperText={majorHazardDocPageError.docName || ''}
                    className={classes.textField}
                    value={majorHazardDocPage.docName || ''}
                    variant="outlined"
                    label="文档名称"
                    margin="normal"
                    onChange={event => onhandleChange(event, 'docName')}
                    onBlur={event => onhandleCheck(event, 'docName')}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    error={majorHazardDocPageError.docType !== ''}
                    helperText={majorHazardDocPageError.docType || ''}
                    className={classes.textField}
                    select
                    value={majorHazardDocPage.docType || ''}
                    variant="outlined"
                    label="文档类型"
                    margin="normal"
                    onChange={event => onhandleChange(event, 'docType')}>
                    {doctype.map((item) => {
                        return <MenuItem key={item.code} value={item.code}>
                            {item.name}
                        </MenuItem>
                    })}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    className={classes.textField}
                    value={majorHazardDocPage.docDesc || ''}
                    variant="outlined"
                    label="文档描述"
                    margin="normal"
                    onChange={event => onhandleChange(event, 'docDesc')}
                    onBlur={event => onhandleCheck(event, 'docDesc')}
                />
            </Grid>
        </Grid>
    </div >)
}

MajorHazardDocPage.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    majorHazardDocPage: PropTypes.object.isRequired,
    majorHazardDocPageError: PropTypes.object.isRequired,
    doctype: PropTypes.array.isRequired,
};

export default withStyles(MajorHazardDocPage);
