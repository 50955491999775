import React, { Component } from 'react';
import { connect } from 'react-redux';
import OperationPlanOrderList from "@/components/DeviceManagement/OperationPlanDocs/OperationPlanOrderList";
import OperationPlanDocsHeader from "@/components/DeviceManagement/OperationPlanDocs/OperationPlanDocsHeader";
import PropTypes from "prop-types";
import {
    setSearch, resetSearch, getOperationPlanOrders, initOperationPlanOrders, createOperationPlanDoc,
    updOperationPlanDoc, updateOperationPlanOrder
} from '@/redux/modules/operationPlanDocs';
import { upload, downloadURL } from '@/redux/modules/minio';
import { setDeviceDocPageError } from '@/redux/modules/devices';
import { cleanmodal, createmodal } from '@/redux/modules/modal';
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import { getNowTimestamp } from "@/utils/datetime";
import { createMessage } from '@/redux/modules/message';
import { FilePreviewer } from '@/components/SimpleComponents';
import OperationPlanDocPanel from "./OperationPlanDocPanel";
import { getEnvCode } from '@/utils/exportfile'
import { getDocType, getFileExt } from '@/utils/fileUtils'

import * as _ from 'lodash';

class OperationPlanDocsListContainer extends Component {
    static propTypes = {
        userProfile: PropTypes.object,
        pageSize: PropTypes.number,
        isLoading: PropTypes.bool,
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            routeType: '',
            selectedData: null,
            docPanelOpen: false,
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        }
    }

    componentDidMount() {
        this.setState({
            routeType: this.props.routeType
        })
        this.props.onInitOperationPlanOrders(this.props.routeType);
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value,
                    dptId: ''
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'optNo':
                newSearch = {
                    ...this.props.search,
                    optNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'orderNo':
                newSearch = {
                    ...this.props.search,
                    orderNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'deviceNo':
                newSearch = {
                    ...this.props.search,
                    deviceNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'accNo':
                newSearch = {
                    ...this.props.search,
                    accNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleCheck = (value, field) => {
        let newSearch;
        switch (field) {
            case 'onlyMe':
                newSearch = {
                    ...this.props.search,
                    onlyMe: value.target.checked
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    openUploadModal(rowData) {
        this.setState({
            selectedData: rowData,
            docPanelOpen: true
        })
    }

    handleDocPanelClose() {
        this.setState({
            docPanelOpen: false,
        })
    }

    async handleDocPanelConfirm(OPdoc) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = getEnvCode()
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.operationdoc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        if (!OPdoc.skipDoc) {
            await this.props.onhandleUploadDoc(OPdoc.file, mybucket, offset);
        }

        let doc = {
            dtlId: this.state.selectedData.dtlId,
            orderId: this.state.selectedData.orderId,
            operationPlanDoc: {
                docId: 0,
                docName: OPdoc.skipDoc ? '' : (offset + OPdoc.file[0].name),
                maintainResult: OPdoc.maintainResult,
                maintainDate: OPdoc.maintainDate,
                nextMaintainDate: OPdoc.nextMaintainDate,
                testDate: OPdoc.testDate,
                nextTestDate: OPdoc.nextTestDate,
                docCate: OPdoc.docCate,
                docDesc: OPdoc.docDesc,
                docLink: OPdoc.skipDoc ? '' : mybucket,
                docType: OPdoc.skipDoc ? '' : getDocType(OPdoc.file[0].name),
            }
        };
        this.props.onhandleCreateDoc(doc);
        this.setState({
            docPanelOpen: false,
        })
    }

    handleDelete(rowData) {
        let doc = {
            docId: rowData.docId,
            status: 'inactive'
        }
        this.props.onUpdOperationPlanDoc(doc);
    }

    handlePublish(rowData) {
        let param = {
            orderId: rowData.orderId,
            dtlId: rowData.dtlId,
            status: 'active'
        }
        this.props.onhandleUpdateOperationPlanOrder(param);
    }

    handleRevert(rowData) {
        let param = {
            orderId: rowData.orderId,
            dtlId: rowData.dtlId,
            status: 'new'
        }
        this.props.onhandleUpdateOperationPlanOrder(param);
    }

    async handlePreview(rowData) {

        return this.props.onhandleDownloadUrl(rowData.docName, rowData.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        open: true,
                        fileURL: res.payload,
                        fileType: getFileExt(rowData.docName)
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    handleViewWorkorderWithOperationPlan = (row) => {
        let param = {
            orderId: row.orderId
        }

        this.props.onhandleWorkorderWithOperationPlan(param);
    }

    async handlePageChange(current, pageSize) {
        let newSearch = {
            ...this.props.search,
            page: current,
            size: pageSize
        };
        await this.props.onhandleUpdateEditSearch(newSearch)
        this.props.onGetOperationPlanOrders()

    }

    async handleSearch() {
        let newSearch = {
            ...this.props.search,
            page: 1,
        };
        await this.props.onhandleUpdateEditSearch(newSearch)
        this.props.onGetOperationPlanOrders()
    }

    render() {
        const { previewFile } = this.state;
        return (
            <div>

                <OperationPlanDocsHeader
                    companyTree={this.trimCompanyTree()}
                    companyList={this.props.companyList}
                    onhandleCheck={this.handleCheck.bind(this)}
                    currentCompany={this.props.currentCompany}
                    departmentList={this.props.departmentList}
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    search={this.props.search}
                    orderTypeList={this.props.orderTypeList}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onhandleClearSearch={this.props.onResetSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                />
                <OperationPlanOrderList
                    initListData={this.props.initListData}
                    companyId={getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)}
                    userProfile={this.props.userProfile}
                    search={this.props.search}
                    getOperationPlanOrders={this.props.onGetOperationPlanOrders.bind(this)}
                    isLoading={this.props.isLoading}
                    departmentList={this.props.departmentList}
                    companyList={this.props.companyList}
                    routeType={this.state.routeType}
                    orderTypeList={this.props.orderTypeList}
                    onOpenUploadModal={this.openUploadModal.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandlePublish={this.handlePublish.bind(this)}
                    onhandleRevert={this.handleRevert.bind(this)}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    onhandleViewWorkorderWithOperationPlan={this.handleViewWorkorderWithOperationPlan.bind(this)}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                />
                <OperationPlanDocPanel
                    open={this.state.docPanelOpen}
                    docRecord={this.state.selectedData}
                    closeCallBack={this.handleDocPanelClose.bind(this)}
                    confirmCallback={this.handleDocPanelConfirm.bind(this)}
                />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        initListData: state.operationPlanDocs.operationPlanOrders,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        pageSize: state.operationPlanDocs.pageSize,
        isLoading: state.loading.globalLoading,
        departmentList: state.constant.departments,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        search: state.operationPlanDocs.search,
        deviceDocPage: state.devices.deviceDocPage,
        doctype: state.devices.doctype,
        deviceDocPageError: state.devices.deviceDocPageError,
        currentCompany: state.company.currentCompany,
        orderTypeList: state.operation.orderTypeList,
        appendixResult: state.operationPlanDocs.appendixResult,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateEditSearch: (search) => {
            return dispatch(setSearch(search));
        },
        onResetSearch: () => {
            return dispatch(resetSearch());
        },
        onGetOperationPlanOrders: (query) => {
            return dispatch(getOperationPlanOrders(query))
        },
        onInitOperationPlanOrders: (routeType) => {
            return dispatch(initOperationPlanOrders(routeType));
        },
        // onhandleOpenFileUpload: (config) => {
        //     dispatch(createmodal('commonFileUpload', config));
        // },
        onhandleModalClose: () => {
            return dispatch(cleanmodal());
        },
        onhandleSetError: (err) => {
            return dispatch(setDeviceDocPageError(err))
        },
        onhandleUploadPre: () => {
            return dispatch(createMessage('error', '上传的文件格式不支持'));
        },
        onhandleCreateDoc: (doc) => {
            return dispatch(createOperationPlanDoc(doc));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            return dispatch(upload(files, mybucket, offset, callback))
        },
        onUpdOperationPlanDoc: (doc) => {
            return dispatch(updOperationPlanDoc(doc));
        },
        onhandleUpdateOperationPlanOrder: (param) => {
            return dispatch(updateOperationPlanOrder(param));
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleWorkorderWithOperationPlan: (parm) => {
            return dispatch(createmodal('workOrderWithOperationPlan', parm, 'large'));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanDocsListContainer)
