import React, { Component } from 'react';
import { connect } from 'react-redux';
import HazardClosePanel from './HazardClosePanel';
import {
    getHazardList,
    getHazardListForCsv,
    getTreatmentScheme,
    routeToHazardInfo,
    initHazardList,
    setSearch,
    resetSearch,
    updateHazard,
    changeHazardStatus,
    exportHazardFileById
} from '@/redux/modules/inspection/hazard';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { openHazardClosePanel } from '@/redux/modules/inspection/inspectionHazardClose';
import HazardListTable from "../../../components/InspectionManagement/HazardList/HazardListTable";
import { INSPECTION_DICT_TYPES, getOptionList } from '@/utils/constant';
import { csv } from '@/utils/exportfile';
import {
    getMoment as moment,
    getDateByTimestamp
} from '@/utils/datetime';
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import PropTypes from "prop-types";
// import store from '@/redux/store';
import _ from 'lodash';

class HazardListTableContainer extends Component {

    static propTypes = {
        hazardList: PropTypes.object
    };


    constructor(props) {
        super(props);

        this.state = {
            creatorName: false
        }

    }

    UNSAFE_componentWillMount() {
        const { isBack, routeType } = this.props;
        this.props.onhandleInit(isBack, routeType);
    }
    // componentDidMount() {
    // }

    handleSearch() {
        this.props.onhandleSearch({
            ...this.props.search,
            offset: 0,
            page: 0
        });
        const newSearch = {
            ...this.props.search,
            offset: 0,
            page: 0
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleClearSearch() {
        this.setState({
            creatorName: false
        })
        this.props.onhandleClearSarch();
    }

    onhandleExportCsv = () => {
        this.props.onhandleGetHazardListForCsv({
            ...this.props.search,
            offset: 0,
            size: 0,
            sort: "-update_time"
        }, res => {
            this.generateCsvData(res);
        });
    }

    findName = (code, type) => {
        let obj = _.find(this.props.inspectionDict, function (o) { return o.code === code && o.type === type })
        return obj ? obj.name : null;
    }

    renderCompany = (cid) => {
        let com = _.find(this.props.companies, function (o) { return o.companyId === cid });
        return com ? com.companyAlias : null;
    }

    renderDepartment = (c) => {
        let dpt = _.find(this.props.departments, function (o) { return o.dptId === c.dptId })
        return dpt ? dpt.companyDptName : null;
    }

    renderStatus = (s) => {
        // 'accepting': '待验收'    一条隐患可能出现多条方案，此状态不适用
        let status = {
            'active': '待审定', 'evaluated': '待治理', 'treating': '治理中',
            'done': '已治理', 'invalid': '已销项', 'inactive': '无效'
        };
        return status[s]
    }

    generateCsvData = (hazardList) => {
        const {
            inspectionDict,
            exportCsv,
            onhandleCreateMessage
        } = this.props;

        if (hazardList && Object.keys(hazardList).length > 0 && hazardList.list.length > 0) {
            const {
                onhandleGetTreatmentScheme,
                treatmentScheme
            } = this.props;
            let columns = ['编号', '产生时间', '检查类型', '来源事件', '检查子分类', '属地单位', '监管部门', '根因', '隐患级别', '危害程度', '安全生产要素', '问题描述', '记录人', '状态', '整改预算', '整改措施', '管控措施', '整改负责人', '验收负责人', '整改完成时间', '备注'];
            // let columns = ['编号', '产生时间', '检查类型', '来源事件', '检查子分类', '属地单位', '监管部门', '根因', '隐患级别', '危害程度', '安全生产要素', '问题描述', '记录人', '状态'];
            let dataList = [];

            onhandleGetTreatmentScheme('');
            let tmptreatmentSchemeList = treatmentScheme.list;

            hazardList.list.forEach(hazard => {
                let inspectTypeStr = '随机检查';
                let categoryStr = '-';
                let eventName = '-';
                let dptName = '-';
                let rootCause = this.findName(hazard.rootCause, 'ROOTCAUSE');
                let severity = this.findName(hazard.severity, "SEVERITY");
                let impactLevel = this.findName(hazard.impactLevel, "IMPACTLEVEL");
                let factor = this.findName(hazard.factor, "FACTORTYPE");
                let tmptreatmentScheme = _.find(tmptreatmentSchemeList, { 'hid': hazard.hid });

                // console.log('hazard.hid1', hazard.hid);
                // console.log('tmptreatmentScheme', tmptreatmentScheme);

                if (hazard.recordId) {
                    inspectTypeStr = this.findName(hazard.inspectType.substr(0, 5), 'INSTYPE');
                    if (_.find(inspectionDict, { 'pcode': hazard.inspectType.substr(0, 5) })) {
                        categoryStr = this.findName(hazard.inspectType, 'INSTYPE');
                    }
                    eventName = this.findName(hazard.inspectType, "INSTYPE") + "-" + hazard.planName;
                    dptName = this.renderDepartment(hazard.checklist);
                    rootCause = this.findName(hazard.checklist.rootCause, 'ROOTCAUSE');
                    severity = this.findName(hazard.checklist.severity, "SEVERITY");
                }
                if (!tmptreatmentScheme) {
                    tmptreatmentScheme = {
                        cost: '',
                        measure: '',
                        pretreatment: '',
                        headName: '',
                        accepterName: '',
                        targetDate: '',
                        opentext: ''
                    };
                }

                let recordTimeStr = getDateByTimestamp(hazard.createTime, true);
                let data = [
                    hazard.serialNo,
                    recordTimeStr,
                    inspectTypeStr,
                    eventName,
                    categoryStr,
                    this.renderCompany(hazard.companyId),
                    dptName,
                    rootCause || '-',
                    severity || '-',
                    impactLevel || '-',
                    factor || '-',
                    hazard.opentext,
                    hazard.recorder || '',
                    this.renderStatus(hazard.status),
                    tmptreatmentScheme.cost,
                    tmptreatmentScheme.measure,
                    tmptreatmentScheme.pretreatment,
                    tmptreatmentScheme.headName,
                    tmptreatmentScheme.accepterName,
                    moment()(tmptreatmentScheme.targetDate).format('YYYYMMDD'),
                    tmptreatmentScheme.opentext

                ];
                dataList.push(data);
            });
            let fileName = ['隐患台账'].join('_') + '_' + moment()((new Date())).format('YYYYMMDD');

            exportCsv(fileName, columns, dataList);
            onhandleCreateMessage('success', '导出隐患台账成功');
        }
    }

    handleEdit = (event, rowData, editable) => {
        this.props.onhandleInfoInit(rowData.hid, editable);
    }

    // handleGetHazardListById = (hid) => {
    //     this.props.onhandleGetHazardListById(hid);
    // }

    handleDelete = (event, rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData.hid, 'inactive', () => {
                this.props.onhandleGetHazardList({
                    ...this.props.search,
                    offset: 0
                });
            });
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除此隐患条目吗?', changeStatus, null, false);
    }

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            page: value,
            offset: value * this.props.search.size
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleSelect = (value, field) => {
        let newSearch = { ...this.props.search };
        switch (field) {
            case 'company':
                newSearch.company = value.target.value
                break;
            case 'department':
                newSearch.dptId = value.target.value
                break;
            case 'rootCause':
                newSearch.rootCause = value.target.value
                break;
            case 'severity':
                newSearch.severity = value.target.value
                break;
            case 'impactLevel':
                newSearch.impactLevel = value.target.value
                break;
            case 'factor':
                newSearch.factor = value.target.value
                break;
            case 'status':
                newSearch.status = value.target.value
                break;
            case 'size':
                newSearch.size = value.target.value
                newSearch.offset = 0
                newSearch.page = 0
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            // case 'size':
            //     newSearch.size = value.target.value
            //     break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleDateChange(value, field) {
        let newSearch = { ...this.props.search };

        const date = moment()(value);
        switch (field) {
            case 'createTime':
                newSearch.createTime = date.startOf('day').format('YYYY/MM/DD');
                break;
            case 'createTimeEnd':
                if (
                    date.isBefore(moment()(newSearch.createTime))
                ) {
                    newSearch.createTimeEnd = moment()(newSearch.createTime).endOf('day').format('YYYY/MM/DD');
                } else {
                    newSearch.createTimeEnd = date.endOf('day').format('YYYY/MM/DD');
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    // 查询记录人
    handleOpenUserSelectModal(event) {
        let companys = []
        let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
        if (mainCompany) {
            companys.push(mainCompany.companyId)
        }
        let param = {
            company: true,
            group: true,
            role: true,
            department: true,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('只能选择一个检查人');
                        return reject('callbackFunc Error')
                    } else {
                        const user = this.props.selectedUsers[0];
                        let newSearch = { ...this.props.search };

                        newSearch.creator = user.userId;
                        this.setState({
                            creatorName: user.lastName + user.firstName || ''
                        })

                        this.props.onhandleUpdateEditSearch(newSearch);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    handleOpenCloseHazardModal = (hazard) => {
        const {
            onhandleOpenClosePanel
        } = this.props;
        onhandleOpenClosePanel(this.handleCloseHazard.bind(this), null, hazard.opentext, hazard.hid);
    }

    handleCloseHazard(hazard) {
        const {
            onhandleUpdateHazardList
        } = this.props;

        const newHazard = {
            hid: hazard.hid,
            reason: hazard.reason,
            status: 'invalid'
        };
        onhandleUpdateHazardList(newHazard);
    }

    render() {
        return (<>
            <HazardListTable
                routeType={this.props.currentRouteType}

                hazardList={this.props.hazardList}
                userProfile={this.props.userProfile}
                companies={this.props.companies}
                departments={this.props.departments}
                departmentList={this.props.departmentList}
                dictionary={this.props.inspectionDict}
                getHazardList={this.props.onhandleGetHazardList}
                currentHazard={this.props.currentHazard}
                treatmentScheme={this.props.treatmentScheme}

                rootCauseList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.ROOTCAUSE)}
                severityList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.SEVERITY)}
                impactLevelList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.IMPACTLEVEL)}
                factorList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.FACTORTYPE)}

                search={this.props.search}
                exportCsv={this.onhandleExportCsv.bind(this)}
                onhandlePageChange={this.handlePageChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSearch={this.handleSearch.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandleExportFile={this.props.onhandleExportHazardFileById.bind(this)}
                onEdit={this.handleEdit.bind(this)}
                // onGetHazardListById={this.handleGetHazardListById.bind(this)}
                // onDelete={this.handleDelete.bind(this)}
                onDelete={this.handleOpenCloseHazardModal.bind(this)}
                onhandleCreatorClick={this.handleOpenUserSelectModal.bind(this)}

                creatorName={this.state.creatorName}
            />
            <HazardClosePanel />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        companies: getActualCompany(state.constant.companies),
        departments: state.constant.departments,
        userProfile: state.auth.userProfile,
        departmentList: getManageDpt(state.constant.departments),
        hazardList: state.hazard.hazardList,
        currentRouteType: state.hazard.currentRouteType,
        inspectionDict: state.constant.inspectionDictionaries,
        search: state.hazard.search,
        selectedUsers: state.userSelect.selectedUsers,
        treatmentScheme: state.hazard.treatmentScheme
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetHazardList: (query) => {
            dispatch(getHazardList(query))
        },
        onhandleInit: (isBack, routeType) => {
            dispatch(initHazardList(isBack, routeType))
        },
        onhandleGetTreatmentScheme: (hid) => {
            dispatch(getTreatmentScheme({ hid: hid }))
        },
        onhandleInfoInit: (hid, editable) => {
            dispatch(routeToHazardInfo(hid, editable));
        },
        onhandleChangeStatus: (id, status, callback) => {
            dispatch(changeHazardStatus(id, status, callback));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleGetHazardListForCsv: (query, callback) => {
            dispatch(getHazardListForCsv(query, callback))
        },
        exportCsv: (fileName, columns, data) => {
            dispatch(csv(fileName, columns, data))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        },
        onhandleSearch: (query) => {
            dispatch(getHazardList(query));
        },
        onhandleClearSarch: () => {
            dispatch(resetSearch());
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleExportHazardFileById: (hid) => {
            dispatch(exportHazardFileById(hid));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhandleUpdateHazardList: (entity) => {
            dispatch(updateHazard(entity));
        },
        onhandleOpenClosePanel: (confirmCallback, closeCallback, opentext, hid) => {
            dispatch(openHazardClosePanel(confirmCallback, closeCallback, opentext, hid))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HazardListTableContainer)
