import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message'


const porxyApi = '/api'

const initialState = {
    templates: [],
    open: false,
    cat: '',
};

export const setTemplates = createAction('@@xcloud/leadershiptemplate/settemplates');
export const closePannel = createAction('@@xcloud/leadershiptemplate/closepannel');


export const openPannel = createAction('@@xcloud/leadershiptemplate/openpannel',
    (query, changeCallback, closeCallback) => (dispatch, getState, httpClient) => {
        dispatch(getTemplates(query))
        return { query, changeCallback, closeCallback }
    }
);

export const addTemplate = createAction('@@xcloud/leadership/addtemplate',
    (template) => (dispatch, getState, httpClient) => {

        return httpClient.post(porxyApi + '/goaltemps', template)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "新增模板列表成功"));
                    return (res.data)
                }
            }).catch(err => {
                let msg = `创建模板列表`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const deleteTemplate = createAction('@@xcloud/leadership/deletetemplate',
    (tempId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/goaltemps/' + tempId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除模板列表成功"));
                    return (res.data)
                }
            }).catch(err => {
                let msg = `删除模板列表`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateTemplate = createAction('@@xcloud/leadership/updatetemplate',
    (tempId, template) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/goaltemps/' + tempId, template)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "创建模板列表成功"));
                    return res.data
                }
            }).catch(err => {
                let msg = `创建模板列表`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getTemplates = createAction('@@xcloud/leadership/gettemplates',
    (query, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/goaltemps'

        if (query.cat || query.companyId || query.unitId) {
            let strs = []
            if (query.cat) strs.push('cat=' + query.cat)
            if (query.companyId) strs.push('companyId=' + query.companyId)
            if (query.unitId) strs.push('unitId=' + query.unitId)
            url = url + '?' + strs.join('&')
        }

        httpClient.get(url, callback)
            .then((res) => {
                if (res) {
                    dispatch(setTemplates(res.data))
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `获取模板列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const LeadershipReducer = handleActions(
    {
        [openPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                cat: data.cat,
                changeCallback: data.changeCallback,
                closeCallback: data.closeCallback,
            }
        },
        [setTemplates]: (state, { payload: data }) => {
            return {
                ...state,
                templates: data
            }
        },
        [closePannel]: (state) => {
            return {
                ...state,
                open: false,
                templates: [],
                cat: '',
                changeCallback: null,
                closeCallback: null,
            }
        },


    },
    initialState
)

export default LeadershipReducer

