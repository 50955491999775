import React, { Component } from 'react';
import { connect } from 'react-redux';
import HolidayList from "../../../components/SystemManagement/HolidayManagement";
import PropTypes from "prop-types";
import { getHolidays, deleteHoliday, createHoliday, updateHoliday, changeCompany } from '../../../redux/modules/constant';
import { getYear } from '../../../utils/datetime';
import { findPath } from '../../../utils/constant';

class HolidayListContainer extends Component {
    static propTypes = {
        bus: PropTypes.object,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleGetHolidays(getYear());
    }

    handleSelectionChange(year, value) {
        if (!value) {
            this.props.onhandleSetCompanySelection(year, undefined);
            return;
        }
        let path = findPath(value.value, this.props.companyTree)
            .map(i => i.label)
            .reverse()
            .join(' > ');
        this.props.onhandleSetCompanySelection(year, {
            label: path,
            value: value.value
        });
    }

    render() {
        return (
            <HolidayList
                holidays={this.props.holidays}
                onDelete={this.props.onhandleDelete.bind(this)}
                onCreate={this.props.onhandleCreate.bind(this)}
                onEdit={this.props.onhandleEdit.bind(this)}
                onhandleSelectionChange={this.handleSelectionChange.bind(this)}
                companySelection={this.props.companySelection}
                getHolidays={this.props.onhandleGetHolidays.bind(this)}
                userProfile={this.props.userProfile}
                companyTree={this.props.companyTree}
                constants={this.props.constants}
                companies={this.props.companies}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        holidays: state.constant.holidays,
        userProfile: state.auth.userProfile,
        companySelection: state.constant.companySelection,
        companyTree: state.constant.companyTree,
        constants: state.constant.constants,
        companies: state.constant.companies
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetHolidays: (year, companyId, type) => {
            dispatch(getHolidays(year, companyId, type))
        },
        onhandleDelete: (entity) => {
            dispatch(deleteHoliday(entity));
        },
        onhandleCreate: (entity) => {
            dispatch(createHoliday(entity));
        },
        onhandleEdit: (entity) => {
            dispatch(updateHoliday(entity));
        },
        onhandleSetCompanySelection: (year, selection) => {
            dispatch(changeCompany(year, selection))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HolidayListContainer)