import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(0)
  },
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
    overflowX: 'auto'
  },
  detailtable: {
    padding: theme.spacing(4),
  },
  materialtable: {
    boxShadow: 'none'
  },
  toolbarLabel: {
    padding: '8px'
  },
  gridContainer: {
    marginTop: 30
  },
  gridSelect: {
    width: 300
  },
  gridItem: {
    width: 200
  },
  flexgrow: {
    flexGrow: 1
  },
});


export default withStyles(styles);