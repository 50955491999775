import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    InputAdornment,
    MenuItem,
    Checkbox
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDateByTimestamp } from '@/utils/datetime';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import _ from 'lodash';


const OperationPlanDocPanel = ({
    classes,
    open,
    docError,
    doc,
    orderTypeConfig,
    operationPlanDocs,
    docRecord,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleUpload,
    onhandleDateChange,
}) => {

    const docCateSelect = () => { //v
        var selectList = []
        if (orderTypeConfig.needTest === 1) {
            var testDoc = _.find(operationPlanDocs, { 'docCate': 'TEST_DOC' })
            if (!testDoc) {
                selectList.push({
                    name: '检验检测报告',
                    code: 'TEST_DOC'
                })
            }
        } else {
            var maintainDoc = _.find(operationPlanDocs, { 'docCate': 'MAINTAIN_DOC' })
            if (!maintainDoc) {
                selectList.push({
                    name: (docRecord && docRecord.optType === 'OPT_ACC_UPD') ? '部件更换报告' : '维修报告',
                    code: 'MAINTAIN_DOC'
                })
            }
        }
        selectList.push({
            name: '现场图片',
            code: 'OTHER_DOC'
        })

        return selectList

    }

    const renderFile = () => {
        if (doc.file && doc.file.length && doc.file.length > 0) {
            return doc.file[0].name
        } else {
            return ''
        }
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={'md'}
                fullWidth
                className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">
                        {'报告上传'}
                    </Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.gridContainer} spacing={2}>
                        <Grid item container className={classes.gridItem} md={6}>
                            <Typography className={classes.label}>
                                <span className={classes.justify}>报告类型</span>
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant='outlined'
                                    select
                                    size={'small'}
                                    value={doc.docCate}
                                    error={Boolean(docError.docCate)}
                                    helperText={docError.docCate || ''}
                                    name='docCate'
                                    onChange={event => onhandleChange(event, 'docCate')}>
                                    {docCateSelect().map(item => (<MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>))}
                                </TextField >
                            </Grid>
                        </Grid>
                        {doc.docCate === 'TEST_DOC' && <Grid item container className={classes.gridItem} md={6}>
                            <Typography className={classes.label}>
                                <span className={classes.justify}>检测结果</span>
                            </Typography>
                            <Grid item className={classes.formContainer}><TextField
                                error={Boolean(docError.maintainResult)}
                                helperText={docError.maintainResult || ''}
                                className={classes.textField}
                                variant='outlined'
                                select
                                size={'small'}
                                value={doc.maintainResult}
                                name='maintainResult'
                                onChange={event => onhandleChange(event, 'maintainResult')}
                                onBlur={event => onhandleCheck(event, 'maintainResult')}>
                                <MenuItem key={'qualified'} value={'qualified'}>
                                    合格
                                </MenuItem>
                                <MenuItem key={'unqualified'} value={'unqualified'}>
                                    不合格
                                </MenuItem>
                            </TextField >
                            </Grid></Grid>}

                        {doc.docCate === 'TEST_DOC' &&
                            <Grid item container className={classes.gridItem} md={6}>
                                <Typography className={classes.label}>
                                    <span className={classes.justify}>本次检测时间</span>
                                </Typography>
                                <Grid item className={classes.formContainer}><MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        size='small'
                                        inputVariant='outlined'
                                        variant="inline"
                                        error={Boolean(docError.testDate)}
                                        helperText={docError.testDate || ''}
                                        autoOk
                                        InputProps={{ readOnly: true, }}
                                        className={classes.textField}
                                        disableToolbar
                                        format="yyyy/MM/dd"
                                        name='testDate'
                                        value={doc.testDate ? doc.testDate : null}
                                        onChange={value => onhandleDateChange(value, 'testDate')}
                                        onBlur={event => onhandleCheck(event, 'testDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                </Grid></Grid>}
                        {doc.docCate === 'TEST_DOC' && <Grid item container className={classes.gridItem} md={6}>
                            <Typography className={classes.label}>
                                <span className={classes.justify}>下次检测时间</span>
                            </Typography>
                            <Grid item className={classes.formContainer}><MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    size='small'
                                    inputVariant='outlined'
                                    variant="inline"
                                    error={Boolean(docError.nextTestDate)}
                                    helperText={docError.nextTestDate || ''}
                                    autoOk
                                    InputProps={{ readOnly: true, }}
                                    className={classes.textField}
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    disableToolbar
                                    format="yyyy/MM/dd"
                                    name='nextTestDate'
                                    value={doc.nextTestDate ? doc.nextTestDate : null}
                                    onChange={value => onhandleDateChange(value, 'nextTestDate')}
                                    onBlur={event => onhandleCheck(event, 'nextTestDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    minDate={getDateByTimestamp(doc.testDate, true)}
                                />
                            </MuiPickersUtilsProvider>
                            </Grid></Grid>}
                        {doc.docCate === 'MAINTAIN_DOC' && <Grid item container className={classes.gridItem} md={6}>

                            <Typography className={classes.label}>
                                <span className={classes.justify}>{docRecord && docRecord.optType === 'OPT_ACC_UPD' ? '部件投用时间' : '本次维修时间'}</span>
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        size='small'
                                        inputVariant='outlined'
                                        variant="inline"
                                        error={Boolean(docError.maintainDate)}
                                        helperText={docError.maintainDate || ''}
                                        autoOk
                                        InputProps={{ readOnly: true, }}
                                        className={classes.textField}
                                        disableToolbar
                                        format="yyyy/MM/dd"
                                        name='maintainDate'
                                        value={doc.maintainDate ? doc.maintainDate : null}
                                        onChange={value => onhandleDateChange(value, 'maintainDate')}
                                        onBlur={event => onhandleCheck(event, 'maintainDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>}
                        {doc.docCate === 'MAINTAIN_DOC' && docRecord.optType !== 'OPT_ACC_UPD' && <Grid item container className={classes.gridItem} md={6}>

                            <Typography className={classes.label}>
                                <span className={classes.justify}>下次维修时间</span>
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        size='small'
                                        inputVariant='outlined'
                                        variant="inline"
                                        error={Boolean(docError.nextMaintainDate)}
                                        helperText={docError.nextMaintainDate || ''}
                                        autoOk
                                        InputProps={{ readOnly: true, }}
                                        className={classes.textField}
                                        openTo="year"
                                        views={["year", "month", "date"]}
                                        disableToolbar
                                        format="yyyy/MM/dd"
                                        name='nextMaintainDate'
                                        value={doc.nextMaintainDate ? doc.nextMaintainDate : null}
                                        onChange={value => onhandleDateChange(value, 'nextMaintainDate')}
                                        onBlur={event => onhandleCheck(event, 'nextMaintainDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                        minDate={getDateByTimestamp(doc.maintainDate, true)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid></Grid>}
                        <Grid item container className={classes.gridItem} sm={12} md={12}>
                            <Typography className={classes.label}>
                                <span className={classes.justify}>文件描述</span>
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    variant='outlined'
                                    multiline
                                    rows={4}
                                    className={classes.textField}
                                    error={Boolean(docError.docDesc)}
                                    helperText={docError.docDesc}
                                    value={doc.docDesc || ''}
                                    name='docDesc'
                                    onChange={event => onhandleChange(event, 'docDesc')}
                                    onBlur={event => onhandleCheck(event, 'docDesc')}
                                />
                            </Grid>
                        </Grid>
                        {!doc.skipDoc ?
                            <Grid item container className={classes.gridItem} md={6}>
                                <Typography className={classes.label}>
                                    <span className={classes.justify}>上传文件</span>
                                </Typography>
                                <Grid item className={classes.formContainer}>
                                    <TextField
                                        className={classes.textField}
                                        variant='outlined'
                                        size={'small'}
                                        value={renderFile()}
                                        name='file'
                                        placeholder="请点击上传文件"
                                        error={Boolean(docError.file)}
                                        helperText={docError.file}
                                        onClick={onhandleUpload}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end"><CloudUploadIcon /></InputAdornment>,
                                        }}
                                    >
                                    </TextField >
                                </Grid>
                            </Grid> : <Grid item container className={classes.gridItem} md={6}></Grid>}
                        <Grid item container className={classes.gridItem} md={6}>
                            <Grid item >
                                <Checkbox
                                    checked={doc.skipDoc}
                                    onChange={event => onhandleChange(event, 'skipDoc')}
                                    name="skipDoc"
                                    color="primary"
                                />
                            </Grid>
                            <Grid item className={classes.labelValue}>
                                <Typography >无文档</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {'保存'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
OperationPlanDocPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    orderTypeConfig: PropTypes.object.isRequired,
};

OperationPlanDocPanel.defaultProps = {
    doc: {},
    orderTypeConfig: {}
}

export default withStyles(OperationPlanDocPanel);