import React from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar, IconButton, Grid, Typography, Tooltip, Avatar, Badge, Paper, MenuList, MenuItem, Drawer, Button, Tabs, Tab
} from '@material-ui/core';
import { Error, Info, Warning, Close } from '@material-ui/icons';
import {
  QrcodeOutlined,
  BellOutlined,
  PoweroffOutlined
} from '@ant-design/icons';
// import { ReactComponent as APPIcon } from '../../assets/APP.svg';
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import withStyles from './styles';
import { getRole, getCompanyName } from '../../utils/userUtils'
import { truncate } from '../../utils/string'
import ClearAllIcon from '@material-ui/icons/ClearAll';
import _ from 'lodash';

const Topbar = ({
  classes,
  openSidebar,
  userProfile,
  currentUser,
  onhandeSignout,
  onhandeOpenMessage,
  onhandleClearMessage,
  onhandeOpenQrcode,
  handleDrawer,
  messages,
  openNotification,
  onhandeSetOpenNotification,
}) => {

  // const [openNotification, setOpenNotification] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);

  // console.log('============ tabValue =============')
  // console.log(tabValue)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      onhandeSetOpenNotification(null);
    } else {
      onhandeSetOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    onhandeSetOpenNotification(null);
  };

  const handleClickNotification = event => {
    handleCloseNotification()
    onhandeOpenMessage(event.currentTarget.id)
  }

  const renderIcon = type => {
    if (type === 'error') {
      return <Error fontSize="small" color="error" />
    } else if (type === 'warning') {
      return <Warning fontSize="small" style={{ color: "orange" }} />
    } else {
      return <Info fontSize="small" color="primary" />
    }
  }

  const onhandleClearClick = (value) => {
    if (_.isMap(messages)) {
      if (value === 8) {
        messages.forEach((message, key) => {
          onhandleClearMessage(key);
        });
      } else {
        messages.forEach((message, key) => {
          let m = JSON.parse(message.body);
          if (listBelongToTab(value, m)) {
            onhandleClearMessage(key);
          }
        });
      }
    }
  }

  const renderMessges = (value) => {
    let messageList = [];
    if (_.isMap(messages)) {
      messages.forEach((message, key) => {
        let m = JSON.parse(message.body);
        let list = <MenuItem id={key}
          key={key}
          onClick={handleClickNotification}
        >
          <Grid container>
            <Grid item container>
              {renderIcon(m.type)}
              <Typography style={{ marginLeft: 5 }}>{truncate(m.content, 300)}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title={'忽略本条'}>
              <IconButton className={classes.closeButton} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onhandleClearMessage(key);
              }}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </MenuItem>
        if (listBelongToTab(value, m)) {
          messageList.push(list)
        }
      })
    }
    if (messageList.length === 0) {
      return <MenuItem id={`key-${value}`}
        key={`key-${value}`}
      // onClick={handleClickNotification}
      >
        <Typography style={{ marginLeft: 5 }}>{'当前模块没有消息！'}</Typography>
      </MenuItem>
    }
    return messageList.map((msg) => {
      return msg
    })
  }

  const countList = (tabValue) => {
    let count = 0;
    if (_.isMap(messages)) {
      messages.forEach((message, key) => {
        let m = JSON.parse(message.body);
        if (listBelongToTab(tabValue, m)) {
          count += 1;
        }
      })
    }
    return count;
  }

  const checkPermission = (tabValue) => {
    const routes = userProfile.roles[0].routes;
    if (!routes) return false;

    switch (tabValue) {
      case 0:
        return true
      case 1:
        //企业领导力
        return Boolean(_.find(routes, { 'route': 'leadership' }))
      case 2:
        //设备
        return Boolean(
          _.find(routes, { 'route': 'device_management' }) ||
          _.find(routes, { 'route': 'installation' }) ||
          _.find(routes, { 'route': 'operation_management' })
        )
      case 3:
        //重大危险源
        return Boolean(_.find(routes, { 'route': 'majorhazard_management' }))
      case 4:
        //隐患
        return Boolean(_.find(routes, { 'route': 'inspection' }))
      case 5:
        //巡检
        return Boolean(_.find(routes, { 'route': 'qc' }))
      case 6:
        //生产
        return Boolean(_.find(routes, { 'route': 'manufacture' }))
      case 7:
        //阿米巴
        return Boolean(_.find(routes, { 'route': 'amoeba' }))
      // case 8:
      //   //作业
      //   return Boolean(_.find(routes, { 'route': 'operation_management' }))
      default:
        return false
    }
  }

  const listBelongToTab = (tabValue, m) => {
    switch (tabValue) {
      case 0:
        return true
      case 1:
        //企业领导力
        if ((m.path && m.path.indexOf('/leadership/') > -1)) {
          return true
        }
        break;
      case 2:
        //设备
        if ((m.path && m.path.indexOf('/installationManagement/') > -1) ||
          (m.path && m.path.indexOf('/deviceManagement/') > -1) ||
          (m.path && m.path.indexOf('/accessoryList/') > -1) ||
          (m.path && m.path.indexOf('/accessoryManagement/') > -1) ||
          (m.path && m.path.indexOf('/deviceAccessoryList/') > -1) ||
          (m.path && m.path.indexOf('/operationRule/') > -1) ||
          (m.path && m.path.indexOf('/operationManagement/') > -1) ||
          (m.path && m.path.indexOf('/devices/') > -1)) {
          return true
        }
        break;
      case 3:
        //重大危险源
        if ((m.path && m.path.indexOf('/majorhazardsources/') > -1)) {
          return true
        }
        break;
      case 4:
        //隐患
        if ((m.path && m.path.indexOf('/inspectionManagement/') > -1) ||
          (m.path && m.path.indexOf('/inspection/') > -1) ||
          (m.path && m.path.indexOf('/danagerchecklistManagement/') > -1) ||
          (m.path && m.path.indexOf('/checklistManagement/') > -1) ||
          (m.path && m.path.indexOf('/hazardReport/') > -1)) {
          return true
        }
        break;
      case 5:
        //巡检
        if ((m.path && m.path.indexOf('/qc/') > -1)) {
          return true
        }
        break;
      case 6:
        //生产
        if ((m.path && m.path.indexOf('/manufacture/') > -1)) {
          return true
        }
        break;
      case 7:
        //阿米巴
        if ((m.path && m.path.indexOf('/amoeba/') > -1)) {
          return true
        }
        break;
      // case 8:
      //   //作业
      //   if ((m.path && m.path.indexOf('/operationManagement/') > -1)) {
      //     return true
      //   }
      //   break;
      default:
        return false
    }
  }

  return (
    <Toolbar className={classes.root}>
      {/* 顶栏占整行时样式 */}
      {/* <Grid>
        <div className={classes.toolbar}>
          <Button disabled={true}
            className={classes.logoButton}
            onClick={handleDrawer}>
            <img className={classes.logoimage} src='/images/logos/u104.svg' alt='祥云' />
            <Typography variant="h1" className={classes.logo} noWrap>
              祥云
            </Typography>
          </Button>
        </div>
      </Grid> */}
      <Grid container className={classes.grid}>
        <Grid item className={classes.flexGrow} ></Grid>
        {/* <Grid item className={classes.griditem}>
          <Tooltip title="我的工作日历">
            <Link href={'/schedule'} >
            <IconButton
              className={classes.iconButton}
              color="inherit"
            >
              <CalendarToday />
            </IconButton>
            </Link>
          </Tooltip>
        </Grid> */}
        <Grid item className={classes.griditem}>
          <Avatar className={classes.avater} >{currentUser && currentUser.firstName ? currentUser.firstName.toUpperCase()[0] : 'W'}</Avatar>
        </Grid>
        <Grid item className={classes.griditem}>
          <Typography noWrap>
            {currentUser.roleId ? `${currentUser.lastName}${currentUser.firstName} - ` : ''}
            {currentUser.roleId ? getRole(currentUser.roleId, userProfile).roleName : ''}
            {currentUser.roleId ? ' - ' + getCompanyName(currentUser.roleId, userProfile) : ''}
          </Typography>
        </Grid>
        <Grid item className={classes.griditem}>
          <Badge badgeContent={messages.size} color="error"
            className={classes.badge}>
            <IconButton
              className={classes.iconButton}
              size={'small'}
              color="inherit"
              onClick={handleOpenNotification}
            >
              <BellOutlined />
            </IconButton>
            {/* <Popper
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList role="menu">
                        {renderMessges()}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}
          </Badge>
        </Grid>
        <Grid item className={classes.griditem}>
          <Tooltip title="手机APP二维码">
            <IconButton
              className={classes.iconButton}
              size={'small'}
              color="inherit"
              onClick={onhandeOpenQrcode}
            >
              <QrcodeOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item className={classes.griditem}>
          <Tooltip title="退出登录">
            <IconButton
              className={classes.iconButton}
              size={'small'}
              color="inherit"
              onClick={onhandeSignout}
            >
              <PoweroffOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Drawer anchor={'bottom'} open={Boolean(openNotification)} variant='persistent' classes={{
        paperAnchorBottom: classes.drawer
      }}>
        <Paper square>
          <Grid container className={classes.grid}>
            <Grid className={classes.titleMessage}>
              <Typography variant="h3" className={classes.plantname}>
                {'待办事项与通知'}
              </Typography>
            </Grid>
            <Grid item className={classes.flexGrow} ></Grid>
            <Grid className={classes.titleMessage}>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseNotification}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="tabs"
            variant="scrollable"
          >
            {checkPermission(1) &&
              <Tab value={1} label={<Badge badgeContent={countList(1)} color="error" className={classes.badge}>{'企业领导力'}</Badge>} />
            }
            {checkPermission(2) &&
              <Tab value={2} label={<Badge badgeContent={countList(2)} color="error" className={classes.badge}>{'设备'}</Badge>} />
            }
            {checkPermission(3) &&
              <Tab value={3} label={<Badge badgeContent={countList(3)} color="error" className={classes.badge}>{'重大危险源'}</Badge>} />
            }
            {checkPermission(4) &&
              <Tab value={4} label={<Badge badgeContent={countList(4)} color="error" className={classes.badge}>{'隐患'}</Badge>} />
            }
            {checkPermission(5) &&
              <Tab value={5} label={<Badge badgeContent={countList(5)} color="error" className={classes.badge}>{'巡检'}</Badge>} />
            }
            {checkPermission(6) &&
              <Tab value={6} label={<Badge badgeContent={countList(6)} color="error" className={classes.badge}>{'生产'}</Badge>} />
            }
            {checkPermission(7) &&
              <Tab value={7} label={<Badge badgeContent={countList(7)} color="error" className={classes.badge}>{'阿米巴'}</Badge>} />
            }
            {/* {checkPermission(8) &&
              <Tab value={8} label={<Badge badgeContent={countList(8)} color="error" className={classes.badge}>{'作业'}</Badge>} />
            } */}
            <Tab value={0} label={<Badge badgeContent={countList(0)} color="error" className={classes.badge}>{'全部'}</Badge>} />
            {/* <Tab label="所有" /> */}
          </Tabs>
        </Paper>
        <Paper square style={{ overflow: 'auto' }}>
          <Grid container direction="row" alignItems="flex-end" justify="flex-end" style={{ padding: '5px 20px' }}>
            <Grid item>
              <Button onClick={() => { onhandleClearClick(tabValue) }}>
                <ClearAllIcon />清除全部消息
              </Button>
            </Grid>
          </Grid>
          <MenuList role="menu">
            {renderMessges(tabValue)}
          </MenuList>
        </Paper>
      </Drawer >
    </Toolbar >
  );
};

Topbar.propTypes = {
  openSidebar: PropTypes.bool,
  currentUser: PropTypes.object,
  onhandeSignout: PropTypes.func,
  onhandeOpenMessage: PropTypes.func,
  onhandeOpenQrcode: PropTypes.func,
  messages: PropTypes.object
};

export default withStyles(Topbar);
