import { createAction, handleActions } from 'redux-actions';
// import _ from 'lodash';

const initialState = {
    open: false,
    acceptance: {
        status: '',
        terminateType: '',
        appId: '',
        opentext: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openAcceptancePanel = createAction('@@xcloud/operationWorkAcceptance/open',
    (
        confirmCallback, closeCallback, status
    ) => ({
        confirmCallback, closeCallback, status
    })
);

export const closeAcceptancePanel = createAction('@@xcloud/operationWorkAcceptance/close');

export const changeAcceptance = createAction('@@xcloud/operationWorkAcceptance/changeAcceptance');

// Actions

export const Reducer = handleActions(
    {
        [openAcceptancePanel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                acceptance: {
                    status: data.status || '',
                    terminateType: '',
                    appId: '',
                    opentext: ''
                },
            };
        },
        [closeAcceptancePanel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeAcceptance]: (state, { payload: data }) => {
            return {
                ...state,
                acceptance: data,
            };
        }
    },
    initialState
);

export default Reducer;
