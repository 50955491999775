import { withStyles } from '@material-ui/styles';
import { stripedTableStyle, tableStyle, default as antdCustomStyle } from '@/utils/antdCustomStyle';
// import { colors } from '@material-ui/core';

const styles = theme => ({
  stripedTable: stripedTableStyle,
  normalTable: tableStyle,
  oddRow: antdCustomStyle.oddRow,
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  detailtable: {
    padding: theme.spacing(4)
  },
  materialtable: {
    boxShadow: 'none'
  },
  card: {
    width: '100%',
    padding: 5,
    margin: 5
  },
  actions: {
    padding: theme.spacing(1, 3)
  },
  tableToolbarEditable: {
    paddingTop: 0,
    marginTop: 0,
  },
  tableFreeButton: {
    // 表格工具栏样式覆写无效，故设置如下按钮假样式
    position: 'relative',
    top: 8,
    margin: theme.spacing(-1.5),
  },
});

export default withStyles(styles);
