import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    InputAdornment,
    Typography,
    Button,
    TextField,
    TextareaAutosize,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider
} from "@material-ui/core";
import { Prompt } from 'react-router-dom';
import ProcessApprovalComponent from '@/components/ProcessApproval'
import RuleCatSetup from '@/containers/DeviceManagement/OperationRule/RuleCatSetup';
import RuleDeviceSetup from '@/containers/DeviceManagement/OperationRule/RuleDeviceSetup';
// import * as _ from 'lodash';

const RuleEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        // onhandleSetup,
        onhandleSelectionChange,
        currentRule,
        ruleError,
        ruleChange,
        ruleTargetChanged,
        readonly,
        handlePermission,
        optCategories,
        ruleApprovals,
        onhandleRuleApproval
    } = props;

    const getRadioValue = (rule) => {
        if (!rule.cyclical)
            return "none";

        if (rule.formula && rule.formula !== "")
            return "lifecycle";

        return "custom";
    }

    const renderStatus = (status) => {
        let mapping = { 'new': '起草中', 'active': '有效', 'inactive': '无效', 'submitted': '审核中' };

        return <Typography >{mapping[status]}</Typography>

        // if (status === 'pending') {
        //     return <Typography className={classes.pendingColor}>{mapping[status]}</Typography>
        // } else {
        // }
    }
    return (
        <Grid className={classes.root}>
            <Grid>
                <Grid container direction='column'>
                    <Grid item >
                        <Typography variant="h3" gutterBottom>
                            {currentRule.ruleId ? "编辑设备运维规则" : "新建设备运维规则"}
                        </Typography>
                    </Grid>
                    <Grid> <ProcessApprovalComponent
                        title='运维规则审批'
                        approvals={ruleApprovals}
                        handlePermission={handlePermission}
                        onhandleSetup={(event) => onhandleRuleApproval(event, 'SETUP')}
                        onhandleSubmit={(event) => onhandleRuleApproval(event, 'SUBMIT')}
                        onhandleApprove={(event) => onhandleRuleApproval(event, 'APPROVE')}
                        onhandleReject={(event) => onhandleRuleApproval(event, 'REJECT')}
                        onhandleRestart={(event) => onhandleRuleApproval(event, 'RESTART')}
                        onhanldeDownloadDoc={(event) => onhandleRuleApproval(event, 'DOWNLOADDOC')}
                    />   </Grid>
                    <Grid container spacing={2} className={classes.gridBody}>
                        <Grid item >
                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item >
                                    <Typography >
                                        规则编码
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        inputProps={{
                                            readOnly: readonly
                                        }}
                                        error={ruleError.ruleCode != null && ruleError.ruleCode !== ''}
                                        helperText={ruleError.ruleCode || ''}
                                        className={classes.textField}
                                        value={currentRule.ruleCode || ''}
                                        variant="outlined"
                                        size="small"
                                        onChange={event => onhandleChange(event, 'ruleCode')}
                                        onBlur={event => onhandleCheck(event, 'ruleCode')}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        规则名称
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        inputProps={{
                                            readOnly: readonly
                                        }}
                                        error={ruleError.ruleName != null && ruleError.ruleName !== ''}
                                        helperText={ruleError.ruleName || ''}
                                        className={classes.textField}
                                        value={currentRule.ruleName || ''}
                                        variant="outlined"
                                        size="small"
                                        onChange={event => onhandleChange(event, 'ruleName')}
                                        onBlur={event => onhandleCheck(event, 'ruleName')}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item >
                                    <Typography >
                                        运维类别
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        className={classes.textField}
                                        select
                                        variant="outlined"
                                        size="small"
                                        value={currentRule.cat}
                                        onChange={(event) => onhandleSelectionChange(event, 'cat')}
                                        onBlur={event => onhandleCheck(event, 'cat')}
                                        inputProps={{
                                            readOnly: readonly
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {optCategories.map((data, index) => (
                                            <option value={data.code} key={`ruleEdit_${index}`}>{data.name}</option>
                                        ))}
                                    </TextField>
                                    <p className={classes.selectError} hidden={ruleError.cat === ''}>{ruleError.cat}</p>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item >
                                    <Typography>
                                        执行对象
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup row aria-label="执行对象" name="targetRadio"
                                        value={currentRule.target}
                                        onChange={event => onhandleChange(event, 'target')} style={{ width: '100%', }}>
                                        <Grid container>
                                            <FormControlLabel className={classes.radioLabel} value="DEVTYPE" control={<Radio size="small" />} label="设备类型" />
                                            <FormControlLabel className={classes.radioLabel} value="EXTTYPE" control={<Radio size="small" />} label="设备扩展类别" />
                                            <FormControlLabel className={classes.radioLabel} value="DEV" control={<Radio size="small" />} label="设备集合" />
                                        </Grid>
                                        <Grid container>
                                            <FormControlLabel className={classes.radioLabel} value="ACCTYPE" control={<Radio size="small" />} label="部件类别" />
                                        </Grid>

                                        {/* <Grid item xs={1}>
                                        <FormControlLabel value="PROTYPE" control={<Radio size="small" disabled={readonly} />} label="专业类别" />
                                    </Grid> */}
                                        {/* <Grid container style={{ flexDirection: 'row', display: 'flex' }}>
                                </Grid> */}
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item >
                                    <Typography>
                                        执行周期
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup aria-label="执行周期" name="cycleRadio"
                                        value={getRadioValue(currentRule)}
                                        onChange={event => onhandleChange(event, 'cycleRadio')} style={{ width: '100%' }}>
                                        <FormControlLabel className={classes.radioLabel} value="lifecycle" control={<Radio size="small" />} label="检验报告规定日期" />
                                        {/* <FormControlLabel className={classes.radioLabel}  value="lifecycle" control={<Radio size="small" />} label="组件使用寿命" /> */}
                                        <FormControlLabel className={classes.radioLabel} value="custom" control={<Radio size="small" />} label="自定义周期" />
                                        {getRadioValue(currentRule) === "custom" ? (
                                            <TextField
                                                inputProps={{
                                                    readOnly: readonly
                                                }}
                                                error={ruleError.cycle !== ''}
                                                helperText={ruleError.cycle || ''}
                                                className={classes.cycleField}
                                                value={currentRule.cycle || ''}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                onChange={event => onhandleChange(event, 'cycle')}
                                                onBlur={event => onhandleCheck(event, 'cycle')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            天
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        ) : null}
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item >
                                    <Typography >
                                        规则描述
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <TextareaAutosize
                                        disabled={readonly}
                                        className={classes.textField}
                                        value={currentRule.opentext || ''}
                                        rowsMin={5}
                                        onChange={event => onhandleChange(event, 'opentext')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        规则状态
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Grid container className={classes.statusGrid}>
                                        <Grid item >
                                            {renderStatus(currentRule.status)}
                                        </Grid>
                                        <Grid item className={classes.flexgrow}>
                                        </Grid>
                                        <Grid item >
                                            {/* {!readonly && <Button className={currentRule.status === 'pending' ? "" : classes.pendingColor}
                                                onClick={event => onhandleChange(event, 'status')}
                                            >{currentRule.status === 'pending' ? '启用规则' : '中止规则'}</Button>} */}

                                        </Grid></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Divider orientation="vertical" />
                        </Grid>
                        {currentRule.ruleId ? <Grid item>
                            {(currentRule.target === 'DEVTYPE') ? <RuleCatSetup /> : <Grid></Grid>}
                            {(currentRule.target === 'EXTTYPE') ? <RuleCatSetup /> : <Grid></Grid>}
                            {(currentRule.target === 'DEV') ? <RuleDeviceSetup /> : <Grid></Grid>}
                            {(currentRule.target === 'ACCTYPE') ? <RuleCatSetup /> : <Grid></Grid>}
                        </Grid> : <Grid></Grid>}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item >
                    <Button variant="contained" color="primary"
                        disabled={!ruleChange && !ruleTargetChanged}
                        onClick={event => onhandleSave(event)}
                    >
                        {currentRule.ruleId ? '保存' : '配置规则对象'}
                    </Button>
                </Grid>
                {/* {currentRule.cyclical ? (
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            disabled={!_.isInteger(currentRule.ruleId) || currentRule.ruleId === 0}
                            onClick={event => onhandleSetup(event, currentRule.target)}
                        >
                            规则对象
                        </Button>
                    </Grid>) : null
                } */}
                <Grid item>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>
                </Grid>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={ruleChange} />
        </Grid >
    );
};

RuleEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSetup: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    currentRule: PropTypes.object.isRequired,
    ruleError: PropTypes.object.isRequired,
    ruleChange: PropTypes.bool.isRequired,
    readonly: PropTypes.bool.isRequired,
    optCategories: PropTypes.array.isRequired
};

export default withStyles(RuleEdit);
