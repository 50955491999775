import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    detailTitle: {
        paddingTop: 24,
        paddingLeft: 16,
        alignItems: 'center'
    },
    action: {
        padding: 16,
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
    card: {
        width: '100%',
        padding: 20
    },
    gridContainer: {
        marginTop: 10
    },
});

export default withStyles(styles);