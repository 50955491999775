import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { push } from 'connected-react-router';
import { getCurrentProcessStatus, getCurrentProcess } from './process'
import { getCompanyId } from "../../utils/userUtils";
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getRuleListByRouteId } from './onsiteInspectionRule';
import { formSelectTree } from '../../utils/constant';
import { createloading, cleanloading } from '../modules/loading';
import { getTreePartById } from '@/utils/constant';
import { getRoleTree } from "@/redux/modules/role";

const defaultState = {

    isLoading: false,
    routeList: {
        total: 0,
        list: []
    },
    search: {
        companyId: "",
        inspectionType: "",
        keyword: '',
        routeId: "",
        // 巡检路线审批流程状态
        statusCode: '',
        offset: 0,
        size: 15
    },
    // 页面搜索请求URL中的参数部分（“？”开始）
    searchUrl: '',
    // 巡检路线 > 分页列表：查询参数－巡检类型
    inspectionTypeList: [],
    inspectionrRouteList: {},
    // 巡检路线 > 详情页：当前巡检路线对象
    currentRoute: {},
    currentRouteOrigin: {},
    currentRouteProcess: {},
    setRouteError: {},
    isRouteEditMode: false,
    isRouteChange: false,
    generalItemList: [], // 一般巡检参数
    pointList: [],
    inspectionItemList: [],
    unit: [],
    unitTree: [],
    pointEditMode: {},
    users: {
        total: 0,
        list: []
    },
    confirmRoleList: [],
    processList: [],
    currentRecordHistoryList: [],
    // 审批流程是否需要向前走一步
    isProcessNeedMoveOneStep: false,
};

/********************* OnsiteInspection List Page *********************/

export const getUsers = createAction('@@xcloud/onsiteInspection/getUsers',
    () => (dispatch, getState, httpClient) => {
        let url = '/api/users?offset=0&size=0&status=active';
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
                return res;
            }).catch(err => {
                let msg = '巡检人列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            });
    });


export const getConfirmRoles = createAction('@@xcloud/onsiteInspection/getConfirmRoles',
    (companyId) => (dispatch, getState, httpClient) => {

        Promise.all([
            dispatch(getRoleTree())
        ]).then(() => {
            let roles = getState().role.roleTree;
            let roleList = [];
            roles.forEach(element => {
                if (element.roleGroups) {
                    element.roleGroups.forEach(group => {
                        if (group.roleList) {
                            group.roleList.forEach(role => {
                                roleList.push({
                                    companyId: element.companyId,
                                    roleName: role.roleName,
                                    roleId: role.roleId
                                });
                            })
                        }
                    })
                }
            })
            dispatch(setConfirmRoleList(roleList));
        })
    });

/**
 * 分页查询巡检路线
 */
export const getRouteList = createAction('@@xcloud/onsiteInspection/getRouteList',
    (data, reload) => (dispatch, getState, httpClient) => {
        dispatch(createloading())
        if (reload) {
            let search = getState().onsiteInspectionPlan.search;
            let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

            let newSearch = {
                ...search,
                companyId: data.companyId ? data.companyId : companyId,
                inspectionType: data.inspectionType ? data.inspectionType : "",
                keyword: data.keyword ? data.keyword : "",
                // 巡检路线审批流程状态
                statusCode: data.statusCode ? data.statusCode : "",
                size: data.size ? _.toInteger(data.size) : 15,
                routeId: data.routeId ? data.routeId : ""

            }
            dispatch(setSearch(newSearch));
        }

        let searchCond = getState().onsiteInspection.search;
        let params = {}
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        params.companyId = searchCond.companyId ? searchCond.companyId : companyId
        params.inspectionType = searchCond.inspectionType ? searchCond.inspectionType : ''
        params.keyword = searchCond.keyword ? searchCond.keyword : ''
        // 巡检路线审批流程状态
        params.statusCode = searchCond.statusCode ? searchCond.statusCode : ''
        params.size = searchCond.size ? searchCond.size : 15
        params.routeId = searchCond.routeId ? searchCond.routeId : ''


        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        data.companyId = data.companyId ? data.companyId : company

        let query = {}
        _.assign(query, data, params)

        let path = '/api/onsiteinspections/routes';

        let url = ""

        if (query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (_.isInteger(query.size)) {
            url += "&size=" + query.size
        }
        if (query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=update_time"
        }
        if (query.companyId && query.companyId !== "") {
            url += "&companyId=" + query.companyId
        }
        if (query.inspectionType && query.inspectionType !== "") {
            url += "&inspectionType=" + query.inspectionType
        }
        if (query.keyword && query.keyword !== "") {
            url += "&keyword=" + encodeURIComponent(query.keyword)
        }
        if (query.routeId && query.routeId !== "") {
            url += "&routeId=" + query.routeId
        }
        // 巡检路线审批流程状态
        if (query.statusCode && query.statusCode !== "") {
            url += "&statusCode=" + query.statusCode
        }

        // 记录请求 
        dispatch(setSearchUrl(url));

        return httpClient
            .get(path + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading())
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(cleanloading())
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const setRouteList = createAction('@@xcloud/onsiteInspection/setRouteList');


export const getInspectionRouteList = createAction('@@xcloud/onsiteInspectionRecordReport/getRouteList',
    () => (dispatch, getState, httpClient) => {
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    return res.data.list;
                } else {
                    return []
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);

/**
 * 初始化路线列表页面
 */
export const initRouteListPage = createAction('@@xcloud/onsiteInspection/initRouteListPage',
    (query, reload) => (dispatch, getState) => {
        Promise.all([
            //初始化页面头部
            // dispatch(resetSearch()),
            dispatch(getDeviceUnit()),
            dispatch(getUsers()),

        ]).then(() => {
            // let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            dispatch(getRouteList(query, reload))
            dispatch(getInspectionRouteList(query, reload))

        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化巡检路线搜索失败'));
            return [];
        });
    });

export const resetSearch = createAction('@@xcloud/onsiteInspection/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompantId = null
        let companylist = getState().constant.companies;
        if (companylist && companylist.length > 0) {
            let selectedCompany = _.find(companylist, ['companyId', company])
            if (selectedCompany) {
                newCompantId = selectedCompany.companyId
            }
        }

        let search = getState().deviceUseStatus.search;
        let newSearch = {
            ...search,
            companyId: newCompantId ? newCompantId : "",
            inspectionType: "",
            keyword: '',
            // 状态（路线相关联的流程）
            statusCode: '',
            offset: 0,
            size: 15
        }

        dispatch(setSearch(newSearch));
        dispatch(setSearchUrl(''));
        dispatch(backToList(''));
    }
);

/**
 * 删除巡检路线
 */
export const deleteRoute = createAction('@@xcloud/onsiteInspection/deleteRoute',
    (data) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(getRuleListByRouteId(data.routeId)),
        ]).then(() => {
            let ruleList = getState().onsiteInspectionRule.ruleListByRouteId
            if (!ruleList.list.length > 0) {
                let dto = {
                    "routeId": data.routeId,
                    "status": "inactive",
                    "tenantId": data.tenantId
                }
                let url = `/api/onsiteinspections/routes/${data.routeId}`
                return httpClient
                    .patch(url, dto, { headers: { need_loading: true } })
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(getRouteList({ offset: 0, size: 15, sort: "-update_time" }))
                        }
                        return res.data;
                    }).catch(err => {
                        dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线操作失败'));
                        return [];
                    });
            } else {
                dispatch(createMessage('error', '该路线已计划配置，请先删除计划配置后再操作'));
            }
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线操作失败'));
        });
    }
);

// 暂不实现，仅实现复制功能
export const routeRestart = createAction('@@xcloud/onsiteInspection/routeRestart',
    (data) => (dispatch, getState, httpClient) => {
        let routeId = getState().onsiteInspection.currentRoute.routeId
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile);

        let url = `/api/onsiteinspections/routes/${routeId}/restart`

        return httpClient
            .patch(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(startLoading())
                    dispatch(createhttpMessage(res, "重新起草巡检路线成功"));
                    dispatch(setCurrentRouteProcess(res.data))
                    dispatch(getCurrentProcess(`OnsiteInspectionRoute_${companyId}`))
                    dispatch(getCurrentProcessStatus(`OnsiteInspectionRoute_${companyId}`, res.data.recordId, () => {
                        dispatch(stopLoading())
                    }))
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '重新起草巡检路线失败'));
                return [];
            });
    }
);

//FIXME:
export const startLoading = createAction('@@xcloud/onsiteInspection/startLoading');
//FIXME:
export const stopLoading = createAction('@@xcloud/onsiteInspection/stopLoading');

export const setSearch = createAction('@@xcloud/onsiteInspection/setSearch');

export const setConfirmRoleList = createAction('@@xcloud/onsiteInspection/setConfirmRoleList');

export const startProcessOfRoute = createAction('@@xcloud/onsiteInspection/startProcessOfRoute',
    (route) => (dispatch, getState, httpClient) => {
        return httpClient.post('/api/onsiteinspections/routes/start', route)
            .then((res) => {
                dispatch(createhttpMessage(res, "启动创建巡检路线流程成功"));
                if (res)
                    return (res.data)
            }).catch(err => {
                let msg = `启动创建巡检路线流程成功`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            });
    }
);

export const insertRoute = createAction('@@xcloud/onsiteInspection/insertRoute',
    (route) => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/routes';
        let dto = {
            routeId: 0,
            companyId: _.isObject(route.companyId) ? route.companyId.value : route.companyId,
            routeCode: route.routeCode,
            routeName: route.routeName,
            inspectionType: _.isObject(route.inspectionType) ? route.inspectionType.value : route.inspectionType,
            recordConfirm: route.recordConfirm ? route.recordConfirm : false,
            confirmRole: route.confirmRole ? route.confirmRole : undefined,
        }

        return httpClient.post(url, dto, { headers: { need_loading: 'true' } }).then((res) => {
            if (res.status === 201) {
                let newRoute = {
                    ...getState().onsiteInspection.currentRoute,
                    routeId: res.data.routeId,
                    creator: res.data.creator,
                    revisor: res.data.revisor,
                    // “状态” 回填
                    status: res.data.status
                };
                dispatch(setCurrentRoute(newRoute))
                dispatch(setCurrentRouteOrigin(_.cloneDeep(newRoute)))
                dispatch(getRouteProcess(res.data.routeId, res.data.companyId))
                dispatch(push('/qc/route/details/' + res.data.routeId));
                dispatch(createMessage('success', '创建巡检路线基本信息完成'));
            }
            return res;
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '创建巡检路线基本信息创建失败'));
            return null;
        });
    }
);


export const executeProcess = createAction('@@xcloud/onsiteInspection/executeProcess',
    (params) => (dispatch, getState, httpClient) => {
        let route = getState().onsiteInspection.currentRoute
        let routeId = route.routeId;
        let recordId = getState().onsiteInspection.currentRouteProcess.recordId;
        let companyId = _.isObject(route.companyId) ? route.companyId.value : route.companyId
        let url = `/api/onsiteinspections/routes/${routeId}/companies/${companyId}/records/${recordId}/process`;
        return httpClient.post(url, params, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(startLoading())
                    return dispatch(getCurrentProcessStatus(`OnsiteInspectionRoute_${companyId}`, recordId, () => {
                        let currentProcessStatus = getState().process.currentProcessStatus[`OnsiteInspectionRoute_${companyId}`]
                        if (currentProcessStatus.statusCode
                            && (currentProcessStatus.statusCode === 'Drafting' ||
                                // currentProcessStatus.statusCode === 'Submitted' ||
                                currentProcessStatus.statusCode === 'Rejected')) {
                            dispatch(setIsRouteEditMode(true))
                        } else {
                            dispatch(setIsRouteEditMode(false))
                        }
                        dispatch(stopLoading())
                    }));
                }
            })
            .catch(err => {
                let msg = '执行巡检路线审批流程失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getRouteProcess = createAction(
    '@@xcloud/onsiteInspection/getRouteProcess',
    (routeId, companyId) => (dispatch, getState, httpClient) => {
        let company = _.isObject(companyId) ? companyId.value : companyId;
        return httpClient
            .get(`/api/onsiteinspections/route/process?routeId=${routeId}&companyId=${company}`, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(startLoading())
                    dispatch(setCurrentRouteProcess(res.data))
                    // 获取当前Record相关历史信息
                    if (res.data.recordId) {
                        dispatch(getCurrentRecordHistoryList(res.data.recordId, `OnsiteInspectionRoute_${company}`))
                    } else {
                        dispatch(createMessage('warning', '当前Record无相关历史信息'));
                    }

                    dispatch(getCurrentProcess(`OnsiteInspectionRoute_${company}`))
                    dispatch(getCurrentProcessStatus(`OnsiteInspectionRoute_${company}`, res.data.recordId, () => {
                        let currentProcessStatus = getState().process.currentProcessStatus[`OnsiteInspectionRoute_${company}`]
                        if (currentProcessStatus.statusCode
                            && (currentProcessStatus.statusCode === 'Drafting' ||
                                // currentProcessStatus.statusCode === 'Submitted' ||
                                currentProcessStatus.statusCode === 'Rejected')) {
                            dispatch(setIsRouteEditMode(true))
                        } else {
                            dispatch(setIsRouteEditMode(false))
                        }

                        // 任务 巡检路线 流程混淆 XJGL-167
                        if (currentProcessStatus.statusCode && (currentProcessStatus.statusCode === 'Drafting')) {
                            dispatch(setIsProcessNeedMoveOneStep(true))
                        }

                        dispatch(stopLoading())
                    }))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询巡检路线流程记录信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyRoute = createAction('@@xcloud/onsiteInspection/modifyRoute',
    (route) => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/routes';
        url += '/' + route.routeId;

        let dto = {
            routeId: route.routeId,
            companyId: _.isObject(route.companyId) ? route.companyId.value : route.companyId,
            routeCode: route.routeCode,
            routeName: route.routeName,
            inspectionType: _.isObject(route.inspectionType) ? route.inspectionType.value : route.inspectionType,
            status: route.status,
            recordConfirm: route.recordConfirm ? route.recordConfirm : false,
            confirmRole: route.confirmRole ? route.confirmRole : undefined,
        }

        return httpClient.patch(url, dto, { headers: { need_loading: 'true' } }).then((res) => {
            if (res.status === 200) {
                let newRoute = {
                    ...getState().onsiteInspection.currentRoute,
                    revisor: res.data.revisor
                };
                dispatch(setCurrentRoute(newRoute))
                dispatch(setCurrentRouteOrigin(_.cloneDeep(newRoute)))
                dispatch(createMessage('success', '更新巡检路线基本信息完成'));
            }
            return res;
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线详情更新失败'));
            return null;
        });


    }
);

export const setCurrentRoute = createAction('@@xcloud/onsiteInspection/setCurrentRoute');

export const setCurrentRouteOrigin = createAction('@@xcloud/onsiteInspection/setCurrentRouteOrigin');

export const setRouteError = createAction('@@xcloud/onsiteInspection/setRouteError');

export const setIsRouteEditMode = createAction('@@xcloud/onsiteInspection/setIsRouteEditMode');

export const setPointEditMode = createAction('@@xcloud/onsiteInspection/setPointEditMode');

export const setIsRouteChange = createAction('@@xcloud/onsiteInspection/setIsRouteChange');

/********************* 巡检项目、巡检点 *********************/

export const setPointSeq = createAction('@@xcloud/onsiteInspection/setPointSeq',
    (value, id) => (dispatch, getState, httpClient) => {
        let pointList = getState().onsiteInspection.pointList;
        let newPointList = _.cloneDeep(pointList);
        _.map(newPointList, t => {
            if (t.id === id) {
                t.seq = value
            }
        })

        // 缺陷 巡检项目列表应以项目顺序进行排序 XJGL-176
        let sortedNewPointList = newPointList.sort((a, b) => { return a.seq - b.seq })
        dispatch(setPointList(sortedNewPointList));
    });

export const setIgnoreInstall = createAction('@@xcloud/onsiteInspection/setIgnoreInstall',
    (value, id) => (dispatch, getState, httpClient) => {
        let pointList = getState().onsiteInspection.pointList;
        let newPointList = _.cloneDeep(pointList);
        _.map(newPointList, t => {
            if (t.id === id) {
                t.ignoreInstall = value
            }
        })
        dispatch(setPointList(newPointList));
    });
/**
 * @param routeId id
 * @param enableLoading 请求过程中使用loading动画
 */
export const getPointList = createAction('@@xcloud/onsiteInspection/getPointList',
    (routeId, enableLoading) => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/routes/' + routeId + '/points';
        url += '?offset=' + 0;
        url += '&size=' + 0;
        url += "&sort=seq";
        return httpClient.get(url, { headers: { need_loading: enableLoading } })
            .then((res) => {
                if (res.status === 200) {
                    let newPointList = [];
                    _.map(res.data.list, t => {
                        t.id = uuidv4()
                        t.runParamItems = []
                        t.tableItems = []
                        _.map(t.items, i => {
                            i.id = uuidv4()
                            i.parentId = t.idid
                            t.tableItems.push(i)
                        })
                        t.tableItems.sort((a, b) => {
                            return a.showOrder - b.showOrder
                        })
                        t.edit = false
                        newPointList.push(t)
                    })

                    const itemsForIndoorInspection = []
                    newPointList.forEach(point => {
                        const {
                            deviceId,
                            deviceNo,
                            deviceName,
                            useStatus,
                            installUseStatus,
                            ignoreInstall,
                            items: inspectionItems,
                        } = point
                        if (inspectionItems && inspectionItems.length) {
                            inspectionItems.forEach(inspectionItem => {
                                // 巡检统计模块需要显定性内容
                                // if (!inspectionItem.genItem) {
                                let itemId = inspectionItem.itemId ? inspectionItem.itemId : 0;
                                let itemKey = 0;
                                if (inspectionItem.paramId) {
                                    itemKey = deviceId + 'p' + inspectionItem.paramId
                                } else if (inspectionItem.genItem) {
                                    itemKey = deviceId + 'g' + inspectionItem.genItem
                                } else {
                                    itemKey = itemId
                                }
                                itemsForIndoorInspection.push({
                                    ...inspectionItem,
                                    deviceId: deviceId,
                                    deviceNo: deviceNo,
                                    deviceName: deviceName,
                                    deviceUseStatus: useStatus,
                                    installUseStatus: installUseStatus,
                                    ignoreInstall: ignoreInstall,
                                    itemKey: itemKey
                                })
                                // }
                            })
                        }
                    })
                    itemsForIndoorInspection.sort((a, b) => {
                        return a.showOrder - b.showOrder
                    })

                    dispatch(setPointList(newPointList));
                    dispatch(setIndoorInspectionItemList(itemsForIndoorInspection));
                    dispatch(createhttpMessage(res, "获取巡检项目成功"));
                }
            }).catch(err => {
                let msg = `获取巡检项目失败`;
                dispatch(setPointList([]));
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            });
    }
);

export const addPoint = createAction('@@xcloud/onsiteInspection/addPoint',
    (data) => (dispatch, getState) => {
        let pointList = getState().onsiteInspection.pointList;
        let currentRoute = getState().onsiteInspection.currentRoute;
        let newPointEditMode = _.cloneDeep(getState().onsiteInspection.pointEditMode);
        //let generalItemList = getState().onsiteInspection.generalItemList;

        let point = {
            id: uuidv4(),
            no: 0,
            pointId: 0,
            routeId: currentRoute.routeId,
            apparatusName: data[0].installTitle,
            unitName: data[0].unitName,
            deviceGroupName: data[0].groupName,
            deviceNo: data[0].deviceNo,
            deviceId: data[0].deviceId,
            deviceName: data[0].deviceName,
            seq: '',
            runParamItems: [],
            items: [],
            tableItems: [],
            edit: true,
            ignoreInstall: false
            //selectItemIds: [],
        };

        let newPointList = _.cloneDeep(pointList);
        newPointList.unshift(point);
        newPointEditMode[point.id] = true
        dispatch(setPointEditMode(newPointEditMode));
        dispatch(setPointList(newPointList));
        //加载设备运行参数
        dispatch(enrichDeviceRunParams(data[0].deviceId, point.id))
    }
);

export const updateItem = createAction('@@xcloud/onsiteInspection/updateItem',
    (data) => (dispatch, getState) => {
        let pointList = getState().onsiteInspection.pointList;
        let point = _.filter(pointList, function (o) { return o.id === data.parentId })
        if (point && point.length > 0) {
            _.map(point[0].tableItems, t => {
                if (t.id === data.id) {
                    t.method = data.method
                    t.opentext = data.opentext
                    t.resultType = data.resultType
                    //消除行编辑模式
                    if (t.tableData) {
                        t.tableData.editing = undefined
                    }
                }
            })
        }
        let newPointList = _.cloneDeep(pointList);
        dispatch(setPointList(newPointList));
    }
);

export const selectItem = createAction('@@xcloud/onsiteInspection/selectItem',
    (data, point) => (dispatch, getState) => {
        // let pointList = getState().onsiteInspection.pointList;
        // let selected = []
        // _.map(data, d => {
        //     if (d) {
        //         selected.push(d.id)
        //     }
        // })
        // _.map(pointList, p => {
        //     if (p.id === point.id) {
        //         p.selectItemIds = selected
        //     }
        // })
        // let newPointList = _.cloneDeep(pointList);
        // dispatch(setPointList(newPointList));
    }
);

export const enrichDeviceRunParams = createAction('@@xcloud/onsiteInspection/enrichDeviceRunParams',
    (deviceId, id) => (dispatch, getState, httpClient) => {
        let generalItemList = getState().onsiteInspection.generalItemList;
        let url = '/api/devices/runparams';
        return httpClient.get(url + '?deviceId=' + deviceId, { headers: { need_loading: 'true' } }).then(res => {
            let runParams = [];
            if (res.status === 200 && res.data) {
                runParams = _.map(res.data, t => {
                    return {
                        id: uuidv4(),
                        parentId: id,
                        itemId: 0,
                        pointId: 0,
                        genItem: -1,
                        paramId: t.paramId,
                        itemName: t.param,
                        itemUnit: t.unit,
                        standardValue: t.vals,
                        paramType: t.paramType,
                        deviceName: t.sourceName,
                        template: t.template,
                        status: 'active',
                        //tableData: { id: 0, checked: true }
                        // 结果类型
                        resultType: 'NUMBER',
                        // 检查手段 -- 仪器
                        method: 'OCHK02',
                    }
                })
            }
            let generalItems = _.map(generalItemList, t => {
                return {
                    id: uuidv4(),
                    parentId: id,
                    itemId: 0,
                    pointId: 0,
                    paramId: -1,
                    genItem: t.itemId,
                    itemName: t.itemName,
                    resultType: "STATUS",
                    standardValue: t.stdVal,
                    status: 'active',
                    //tableData: { id: 1, checked: true }
                    // 检查手段 -- 目测
                    method: 'OCHK01',
                }
            })
            let pointList = getState().onsiteInspection.pointList;
            _.map(pointList, t => {
                if (t.id === id) {
                    t.tableItems = _.concat(t.tableItems, generalItems, runParams)
                    t.runParamItems = _.concat(t.runParamItems, runParams)
                }
            })

            let newPointList = _.cloneDeep(pointList);
            dispatch(setPointList(newPointList));
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', `查询设备运行参数失败`));
            return [];
        });
    }
);

export const getGeneralItemList = createAction('@@xcloud/onsiteInspection/getGeneralItemList',
    () => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/genitems';
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            return res;
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '获取巡检点通用项目'));
            return null;
        });

    }
);

export const insertPoint = createAction('@@xcloud/onsiteInspection/insertPoint',
    (point) => (dispatch, getState, httpClient) => {
        let dto = {};
        dto.routeId = point.routeId
        dto.deviceId = point.deviceId
        dto.seq = _.toInteger(point.seq)
        dto.status = point.status
        dto.ignoreInstall = point.ignoreInstall
        dto.items = []

        _.map(point.tableItems, t => {
            if (t.tableData.checked) {
                let item = {
                    paramId: t.paramId,
                    genItem: t.genItem,
                    status: t.status,
                    method: t.method,
                    opentext: t.opentext,
                    resultType: t.resultType,
                    showOrder: 0,
                }
                dto.items.push(item)
            }
        })

        let url = '/api/onsiteinspections/routes/' + point.routeId + '/points';
        return httpClient.post(url, dto, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 201) {
                    let pointList = getState().onsiteInspection.pointList;
                    _.map(pointList, t => {
                        if (t.id === point.id) {
                            t.pointId = res.data.pointId
                            t.tenantId = res.data.tenantId
                            t.items = res.data.items
                            t.edit = false
                            t.tableItems = []
                            _.map(res.data.items, i => {
                                i.id = uuidv4()
                                i.parentId = t.id
                                t.tableItems.push(i)
                            })
                            t.tableItems.sort((a, b) => {
                                return a.showOrder - b.showOrder
                            })
                        }
                    })

                    const items = []
                    pointList.forEach(point => {
                        const {
                            deviceId,
                            deviceNo,
                            deviceName,
                            items: inspectionItems,
                        } = point
                        if (inspectionItems && inspectionItems.length) {
                            inspectionItems.forEach(inspectionItem => {
                                if (!inspectionItem.genItem) {
                                    items.push({
                                        ...inspectionItem,
                                        deviceId: deviceId,
                                        deviceNo: deviceNo,
                                        deviceName: deviceName,
                                    })
                                }
                            })
                        }
                    })
                    items.sort((a, b) => {
                        return a.showOrder - b.showOrder
                    })
                    dispatch(setIndoorInspectionItemList(items));

                    let newPointList = _.cloneDeep(pointList);
                    dispatch(setPointList(newPointList));

                    dispatch(createMessage('success', '保存巡检项目成功'));
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '保存巡检项目失败'));
                return null;
            });
    }
);


export const updatePointModel = createAction('@@xcloud/onsiteInspection/updatePointModel',
    (point) => (dispatch, getState, httpClient) => {
        let pointList = getState().onsiteInspection.pointList;
        let newPointEditMode = _.cloneDeep(getState().onsiteInspection.pointEditMode);
        let generalItemList = getState().onsiteInspection.generalItemList;
        let url = '/api/devices/runparams';
        return httpClient.get(url + '?deviceId=' + point.deviceId, { headers: { need_loading: 'true' } }).then(res => {
            let runParams = [];
            if (res.status === 200 && res.data) {
                runParams = _.map(res.data, t => {
                    return {
                        id: uuidv4(),
                        parentId: point.id,
                        itemId: 0,
                        pointId: point.pointId,
                        genItem: -1,
                        paramId: t.paramId,
                        itemName: t.param,
                        itemUnit: t.unit,
                        standardValue: t.vals,
                        paramType: t.paramType,
                        deviceName: t.sourceName,
                        template: t.template,
                        status: 'active',
                        //tableData: { id: 0, checked: true }
                    }
                })
            }

            let generalItems = _.map(generalItemList, t => {
                return {
                    id: uuidv4(),
                    parentId: point.id,
                    itemId: 0,
                    pointId: point.pointId,
                    paramId: -1,
                    genItem: t.itemId,
                    itemName: t.itemName,
                    standardValue: t.stdVal,
                    resultType: "STATUS",
                    status: 'active',
                    //tableData: { id: 1, checked: true }
                }
            })
            let index = 0;
            _.map(pointList, t => {
                if (t.id === point.id) {
                    t.edit = true
                    t.tableItems = []
                    t.runParamItems = runParams
                    t.tableItems = _.concat(t.tableItems, generalItems, runParams)
                    _.map(t.tableItems, e => {
                        e.tableData = { id: ++index }
                        _.map(t.items, i => {
                            if (i.genItem === e.genItem || i.paramId === e.paramId) {
                                e.tableData.checked = true
                                e.itemId = i.itemId
                                e.opentext = i.opentext
                                e.resultType = i.resultType
                                e.method = i.method
                            }
                        })
                    })
                }
            })
            let newPointList = _.cloneDeep(pointList);
            dispatch(setPointList(newPointList));
            newPointEditMode[point.id] = true
            dispatch(setPointEditMode(newPointEditMode));
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', `查询设备运行参数失败`));
        });
    }
);

export const updatePoint = createAction('@@xcloud/onsiteInspection/updatePoint',
    (point) => (dispatch, getState, httpClient) => {
        let dto = {};
        dto.pointId = point.pointId
        dto.routeId = point.routeId
        dto.deviceId = point.deviceId
        dto.seq = _.toInteger(point.seq)
        dto.status = point.status
        dto.ignoreInstall = point.ignoreInstall
        dto.tenantId = point.tenantId
        dto.items = []
        _.map(point.tableItems, t => {
            if (t.tableData.checked) {
                let item = {
                    paramId: t.paramId && t.paramId !== -1 ? t.paramId : undefined,
                    genItem: t.genItem && t.genItem !== -1 ? t.genItem : undefined,
                    status: t.status,
                    method: t.method,
                    opentext: t.opentext ? t.opentext : '',
                    resultType: t.resultType,
                    showOrder: t.showOrder || 0,
                    pointId: point.pointId,
                    tenantId: point.tenantId
                }
                _.map(point.items, p => {
                    if (p.genItem === t.genItem || p.paramId === t.paramId) {
                        item.itemId = p.itemId
                    }
                })
                dto.items.push(item)
            } else {
                _.map(point.items, p => {
                    if (p.genItem === t.genItem || p.paramId === t.paramId) {
                        let item = {
                            itemId: p.itemId,
                            pointId: p.pointId,
                            paramId: p.paramId && p.paramId !== -1 ? p.paramId : undefined,
                            genItem: p.genItem && p.genItem !== -1 ? p.genItem : undefined,
                            opentext: p.opentext ? p.opentext : '',
                            resultType: p.resultType,
                            method: p.method,
                            status: 'inactive',
                            tenantId: 78,
                        }
                        dto.items.push(item)
                    }
                })
            }
        })
        console.log('dtodtodtodto', dto)
        let url = '/api/onsiteinspections/routes/' + point.routeId + '/points/' + point.pointId;
        return httpClient.patch(url, dto, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    let pointList = getState().onsiteInspection.pointList;
                    _.map(pointList, t => {
                        if (t.id === point.id) {
                            t.items = res.data.items
                            t.edit = false
                            t.tableItems = []
                            _.map(res.data.items, i => {
                                i.id = uuidv4()
                                i.parentId = t.id
                                t.tableItems.push(i)
                            })
                            t.tableItems.sort((a, b) => {
                                return a.showOrder - b.showOrder
                            })
                        }
                    })

                    const items = []
                    pointList.forEach(point => {
                        const {
                            deviceId,
                            deviceNo,
                            deviceName,
                            items: inspectionItems,
                        } = point
                        if (inspectionItems && inspectionItems.length) {
                            inspectionItems.forEach(inspectionItem => {
                                if (!inspectionItem.genItem) {
                                    items.push({
                                        ...inspectionItem,
                                        deviceId: deviceId,
                                        deviceNo: deviceNo,
                                        deviceName: deviceName,
                                    })
                                }
                            })
                        }
                    })
                    items.sort((a, b) => {
                        return a.showOrder - b.showOrder
                    })
                    dispatch(setIndoorInspectionItemList(items));

                    let newPointList = _.cloneDeep(pointList);
                    dispatch(setPointList(newPointList));
                    dispatch(createMessage('success', '更新巡检项目成功'));
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新巡检项目失败'));
                return null;
            });
    }
);

export const deletePoint = createAction('@@xcloud/onsiteInspection/deletePoint',
    (point) => (dispatch, getState, httpClient) => {
        let pointList = getState().onsiteInspection.pointList;
        let newPointList = [];
        _.map(pointList, t => {
            if (t.id !== point.id) {
                newPointList.push(t)
            }
        })

        if (point.pointId) {
            let dto = {}
            dto.status = 'inactive'
            dto.pointId = point.pointId
            dto.routeId = point.routeId
            dto.tenantId = point.tenantId
            dto.items = []
            _.map(point.items, t => {
                let cell = {
                    itemId: t.itemId,
                    pointId: t.pointId,
                    status: 'inactive',
                    tenantId: t.tenantId,
                }
                dto.items.push(cell)
            })
            let url = '/api/onsiteinspections/routes/' + point.routeId + '/points/' + point.pointId;
            return httpClient.patch(url, dto, { headers: { need_loading: 'true' } })
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setPointList(newPointList));
                        dispatch(createMessage('success', '删除巡检项目成功'));
                    }
                    return res;
                }).catch(err => {
                    dispatch(createhttpMessage(err.response ? err.response : '', '删除巡检项目失败'));
                    return null;
                });
        } else {
            dispatch(setPointList(newPointList));
            dispatch(createMessage('success', '删除巡检项目成功'));
        }
    }
);

export const updateInspectionItemList = createAction('@@xcloud/onsiteInspection/updateInspectionItemList',
    (items) => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/items';
        return httpClient.patch(url, items, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    const route = getState().onsiteInspection.currentRoute;
                    dispatch(getPointList(route.routeId, true))
                    dispatch(createMessage('success', '保存项目排序成功'));
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '保存项目排序失败'));
                return null;
            });
    }
);

export const setPointList = createAction('@@xcloud/onsiteInspection/setPointList');

export const setIndoorInspectionItemList = createAction('@@xcloud/onsiteInspection/setIndoorInspectionItemList');

export const setCurrentRouteProcess = createAction('@@xcloud/onsiteInspection/setCurrentRouteProcess');

// 保存流程列表数据
export const setProcessList = createAction('@@xcloud/onsiteInspection/setProcessList');

// processHistoryList
export const setCurrentRecordHistoryList = createAction('@@xcloud/onsiteInspection/setCurrentRecordHistoryList');

// 根据process code读取流程列表数据
export const getProcesses = createAction('@@xcloud/onsiteInspection/getProcesses',
    (processCode) => (dispatch, getState, httpClient) => {
        let url = '/api/processes?offset=0&size=0';
        if (processCode !== undefined) {
            url = url + '&code=' + processCode;
        }

        return httpClient.get(url, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.data) {
                    dispatch(setProcessList(res.data.list));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '流程列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getCurrentRecordHistoryList = createAction("@@xcloud/onsiteInspection/getCurrentRecordHistoryList",
    (recordId, processCode, callback) => (dispatch, getState, httpClient) => {
        httpClient.get('/api/processes/records/' + recordId + '/histories')
            .then((res) => {
                if (res) {
                    dispatch(setCurrentRecordHistoryList(res.data))
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `获取流程历史操作记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

/********************* Commmon Utils *********************/
export const initCreate = createAction('@@xcloud/onsiteInspection/initCreate',
    () => (dispatch, getState) => {

        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompanyId = null
        let companylist = getState().constant.companies;
        if (companylist && companylist.length > 0) {
            let selectedCompany = _.find(companylist, ['companyId', company])
            if (selectedCompany) {
                newCompanyId = selectedCompany.companyId
                // newCompantId = { label: selectedCompany.companyName, value: selectedCompany.companyId }
            }
        }


        let route = {
            companyId: newCompanyId,
            inspectionType: undefined,
            routeCode: "",
            routeId: 0,
            routeName: "",
            recordConfirm: false,
            confirmRole: 0
        }
        let routeError = {
            routeCode: '',
            inspectionType: '',
            routeName: '',
            companyId: '',
            confirmRole: ''
        };


        dispatch(setCurrentRoute(route))
        dispatch(setCurrentRouteOrigin(_.cloneDeep(route)))
        dispatch(setRouteError(routeError))
        dispatch(setIsRouteEditMode(true))
        dispatch(setIsRouteChange(false))
        //设置巡检点
        dispatch(setPointList([]))
        dispatch(setIndoorInspectionItemList([]))
        dispatch(getGeneralItemList())
        dispatch(getConfirmRoles())
        // 根据process code读取流程列表数据
        dispatch(getProcesses(`OnsiteInspectionRoute_${company}`))
        // 任务 巡检路线 流程混淆 XJGL-167
        dispatch(setIsProcessNeedMoveOneStep(false))
        dispatch(push('/qc/route/details/' + route.routeId));
    }
);

export const initEdit = createAction('@@xcloud/onsiteInspection/initEdit',
    (data) => (dispatch, getState) => {
        let routes = getState().onsiteInspection.routeList;
        let route = _.find(routes.list, ['routeId', data.routeId])
        let routeError = {
            routeCode: '',
            inspectionType: '',
            routeName: '',
            companyId: '',
            confirmRole: ''
        };
        dispatch(setCurrentRoute(route))
        dispatch(setCurrentRouteOrigin(_.cloneDeep(route)))
        dispatch(setRouteError(routeError))
        dispatch(setIsRouteEditMode(false))
        dispatch(setIsRouteChange(false))
        dispatch(setPointEditMode({}))
        //设置巡检点
        dispatch(getGeneralItemList())
        dispatch(getPointList(route.routeId, true))
        dispatch(getConfirmRoles())
        // 根据process code读取流程列表数据
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dispatch(getProcesses(`OnsiteInspectionRoute_${company}`))
        dispatch(getRouteProcess(route.routeId, route.companyId))
        dispatch(push('/qc/route/details/' + route.routeId));
    }
);

export const copyInitEdit = createAction('@@xcloud/onsiteInspection/copyInitEdit',
    (route, createNew) => (dispatch, getState, httpClient) => {
        const to = {
            routeId: parseInt(route.routeId),
            inspectionType: route.inspectionType,
            routeCode: route.routeCode,
            routeName: route.routeName,
            companyId: route.companyId,
            // status: 'active',
            // tenantId: 1,
            // confirmRole: 126,
            // recordConfirm: true,
            // companyName: '白土加工厂',
            // itemCount: 19,
            // statusCode: 'Published'
        }
        let url = '/api/onsiteinspections/routes/' + to.routeId + '/copy';
        url += "?createNew=" + Boolean(createNew)
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch();
                    dispatch(initRouteListPage(getState().router.location.query, true));
                    dispatch(createMessage('success', '拷贝新建路线成功'));
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '拷贝新建路线失败'));
                return null;
            });
    }
);

export const initView = createAction('@@xcloud/onsiteInspection/initView',
    (data) => (dispatch, getState) => {
        //let permission = _.find(getState().auth.userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
        let routes = getState().onsiteInspection.routeList;
        let route = _.find(routes.list, ['routeId', data.routeId])
        let routeError = {
            routeCode: '',
            inspectionType: '',
            routeName: '',
            companyId: '',
            confirmRole: ''
        };
        dispatch(setCurrentRoute(route))
        dispatch(setCurrentRouteOrigin(_.cloneDeep(route)))
        dispatch(setRouteError(routeError))
        dispatch(setIsRouteEditMode(false))
        dispatch(setIsRouteChange(false))
        dispatch(setPointEditMode({}))
        //设置巡检点
        dispatch(getGeneralItemList())
        dispatch(getPointList(route.routeId, true))
        dispatch(getConfirmRoles())
        // 根据process code读取流程列表数据
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dispatch(getProcesses(`OnsiteInspectionRoute_${company}`))
        dispatch(getRouteProcess(route.routeId, route.companyId))

        dispatch(push('/qc/route/details/' + route.routeId));
    }
);

export const getDeviceUnit = createAction('@@xcloud/onsiteInspection/getDeviceUnit',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=UNIT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '设备运行参数单位获取失败'));
                return [];
            });
    });

export const backToList = createAction('@@xcloud/onsiteInspection/backToList',
    (id) => (dispatch, getState) => {
        let searchUrl = getState().onsiteInspection.searchUrl;
        dispatch(push('/qc/route' + searchUrl));
    }
);

export const setSearchUrl = createAction('@@xcloud/onsiteInspection/setSearchUrl');
// 审批流程是否需要向前走一步
export const setIsProcessNeedMoveOneStep = createAction('@@xcloud/onsiteInspection/setIsProcessNeedMoveOneStep');


/********************* OnsiteInspection Reducer *********************/

export const routeListReducer = handleActions(
    {
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getInspectionRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                inspectionrRouteList: value
            };
        },
        [setRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [startLoading]: (state) => {
            return {
                ...state,
                isLoading: true
            };
        },
        [stopLoading]: (state) => {
            return {
                ...state,
                isLoading: false
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setConfirmRoleList]: (state, { payload: value }) => {
            return {
                ...state,
                confirmRoleList: value
            };
        },
        [setCurrentRoute]: (state, { payload: value }) => {
            return {
                ...state,
                currentRoute: value
            };
        },
        [setCurrentRouteOrigin]: (state, { payload: value }) => {
            return {
                ...state,
                currentRouteOrigin: value
            };
        },
        [setRouteError]: (state, { payload: value }) => {
            return {
                ...state,
                routeError: value
            };
        },
        [setIsRouteEditMode]: (state, { payload: value }) => {
            return {
                ...state,
                isRouteEditMode: value
            };
        },
        [setIsRouteChange]: (state, { payload: value }) => {
            return {
                ...state,
                isRouteChange: value
            };
        },
        [setPointList]: (state, { payload: value }) => {
            return {
                ...state,
                pointList: value
            };
        },
        [setIndoorInspectionItemList]: (state, { payload: value }) => {
            return {
                ...state,
                inspectionItemList: value
            };
        },
        [getGeneralItemList]: (state, { payload: value }) => {
            return {
                ...state,
                generalItemList: value
            };
        },
        [setCurrentRouteProcess]: (state, { payload: value }) => {
            return {
                ...state,
                currentRouteProcess: value
            };
        },
        [getDeviceUnit]: (state, { payload: value }) => {
            return {
                ...state,
                unit: value.list,
                unitTree: value.tree
            };
        },
        [setPointEditMode]: (state, { payload: value }) => {
            return {
                ...state,
                pointEditMode: value
            };
        },
        [getUsers]: (state, { payload: value }) => {
            return {
                ...state,
                users: value
            };
        },
        [setProcessList]: (state, { payload: value }) => {
            return {
                ...state,
                processList: value
            };
        },
        [setCurrentRecordHistoryList]: (state, { payload: value }) => {
            return {
                ...state,
                currentRecordHistoryList: value
            };
        },
        [setSearchUrl]: (state, { payload: value }) => {
            return {
                ...state,
                searchUrl: value
            };
        },
        // 审批流程是否需要向前走一步
        [setIsProcessNeedMoveOneStep]: (state, { payload: value }) => {
            return {
                ...state,
                isProcessNeedMoveOneStep: value
            };
        },
    },
    defaultState
);

export default routeListReducer;