import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  title: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formGridContainer: {

  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
});

export default withStyles(styles);
