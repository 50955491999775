import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  infoGrid: {
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textAlign: 'right',
    width: 80,
    paddingRight: theme.spacing(2),
  },
  content: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    width: 'calc(100% - 80px)'
  },
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  basicInfoContainer: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  select: {
    width: '100%'
  },
  detailTitle: {
    paddingTop: 24,
    paddingLeft: 16,
    alignItems: 'center'
  },
  action: {
    padding: 16,
  },
  title: {
    padding: theme.spacing(0)
  },
  subTitle: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    borderLeft: '2px solid ' + theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  tableFreeButton: {
    // 表格工具栏样式覆写无效，故设置如下按钮假样式
    position: 'relative',
    top: 8,
    margin: theme.spacing(-1.5),
  },
  flexgrow: {
    flexGrow: 1
  },
  pic1: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.blue[500],
    fontSize: '12px',
  },
  pic2: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.teal[400],
    fontSize: '12px',
  },
  longContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles);
