import React from 'react';
import { withRouter } from 'react-router';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Tabs, Tab, Grid, Card } from '@material-ui/core';
import Goal from '../../../containers/LeadershipManagement/AnnualGoal/Goal';
import GoalPlan from '../../../containers/LeadershipManagement/AnnualGoal/GoalPlan';
import { getYear } from '../../../utils/datetime';
import CreateGoalPlan from '../../../containers/LeadershipManagement/AnnualGoal/CreateGoalPlan';
const AnnualGoal = ({
    classes,
    createPermission,
    hasPlan,
    onhandleChangeTab,
    tabValue,
}) => {
    return (
        <Card alignitems="center" className={classes.root}>

            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={getYear() + "年企业年度目标"} className={classes.tab} value={0} />
                {hasPlan && <Tab label={"拟定" + (getYear() + 1) + "年企业年度目标"} value={1} />}
                {createPermission && <Tab label={"启动企业年度目标"} value={2}></Tab>}
            </Tabs>
            <Grid>
                {tabValue === 0 && <Goal />}
                {tabValue === 1 && <GoalPlan />}
                {createPermission && tabValue === 2 && <CreateGoalPlan />}
            </Grid>
        </Card>

    );
};
AnnualGoal.propTypes = {
    classes: PropTypes.object.isRequired
};

AnnualGoal.defaultProps = {
    createPermission: false
}


export default withRouter(withStyles(AnnualGoal));