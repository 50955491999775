import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';

const porxyApi = '/api'

const initialState = {
    records: [], //装置操作记录列表
};

export const setRecords = createAction('@@xcloud/manufacture/setRecords');

export const getRecords = createAction('@@xcloud/manufacture/getRecords',
    (actionTime,installId,unitId,deviceId,manipulator,companyId,status,orderIds, startDate, endDate) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/manipulates/records'
        let strs = []
        if (installId || unitId || deviceId || manipulator || actionTime || companyId || status || orderIds || startDate || endDate) {
            if (installId) strs.push('installId=' + installId)
            if (unitId) strs.push('unitId=' + unitId)
            if (deviceId) strs.push('deviceId=' + deviceId)
            if (manipulator) strs.push('manipulator=' + manipulator)
            if (actionTime) strs.push('actionTime=' + actionTime)
            if (companyId) strs.push('companyId=' + companyId)
            if (orderIds) strs.push('orderIds=' + orderIds)
            if (status) strs.push('status=' + status)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            url = url + '?' + strs.join('&')
        }
        
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setRecords(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取装置操作记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });


export const installActionRecordReducer = handleActions(
    {
        [setRecords]: (state, { payload: data }) => {
            return {
                ...state,
                records: data,
            }
        },
    },
    initialState
)

export default installActionRecordReducer
