import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from '@/redux/modules/message';
import { getRegulations } from "@/redux/modules/regulation";
import { getRoleTree } from "@/redux/modules/role";
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash';

const defaultState = {
  checklists: {
    total: 0,
    list: []
  },
  editable: false,
  currentChecklist: {},
  checklistError: {},
  checklistChange: false,
  cklSearch: {
    inventoryId: 0,
    // company: undefined,
    dptId: 0,
    roleId: 0,
    // listType: '',
    // catCode: '',
    // severity: '',
    // rootCause: '',
    // status: '',
    page: 0,
    size: 10,
    offset: 0,
  },
  inventories: {
    total: 0,
    list: []
  },
  currentInventory: {},
  ivtSearch: {
    company: 0,
    dptId: 0,
    roleId: 0,
    listType: '',
    catCode: '',
    severity: '',
    rootCause: '',
    status: '',
    page: 0,
    size: 10,
    offset: 0,
  },
  checklistCount: {},
  checklistRole: {},
  departmentList: [],
  listTypeList: [],
  categoryList: [],
  severityList: [],
  rootCauseList: [],
  statusList: [],
  buList: [],
  methodList: [],
  disciplineList: [],
  roleList: [],
  resultTypeList: [],
  regListWithActiveVersion: []
};

export const getListTypeList = createAction('@@xcloud/inspection/getListTypeList',
  () => (dispatch, getState, httpClient) => {
    let { inspectionDictionaries } = getState().constant;

    const inspectionTypeList = _.filter(inspectionDictionaries, { 'type': 'INSTYPE', 'pcode': null });
    const tmpInspectionTypeList = _.filter(inspectionTypeList, (item) => { return item.code !== 'INS11' })
    dispatch(setListTypeList(tmpInspectionTypeList));
  });

export const getCategoryList = createAction('@@xcloud/inspection/getCategoryList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict,
      (item) => (item.type === 'INSTYPE' && item.pcode !== null)
    );
    dispatch(setCategoryList(list));
  });

export const getSeverityList = createAction('@@xcloud/inspection/getSeverityList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict, { 'type': 'SEVERITY' });
    dispatch(setSeverityList(list));
  });

export const getRootCauseList = createAction('@@xcloud/inspection/getRootCauseList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict, { 'type': 'ROOTCAUSE' });
    dispatch(setRootCauseList(list));
  });

export const getUnitList = createAction('@@xcloud/inspection/getUnitList',
  () => (dispatch, getState, httpClient) => {
    let bus = getState().constant.businessunits;
    let buList = [];

    if (bus !== undefined) {
      bus.forEach(bu => {
        buList.push({
          name: bu.unitName,
          code: bu.unitId
        })
      })
    }
    dispatch(setBuList(buList));
  });

export const getMethodList = createAction('@@xcloud/inspection/getMethodList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict, { 'type': 'CHKTYPE' });
    dispatch(setMethodList(list));
  });

export const getDisciplineList = createAction('@@xcloud/inspection/getDisciplineList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict, { 'type': 'DISCIPLINE' });
    dispatch(setDisciplineList(list));
  });

export const getResultTypeList = createAction('@@xcloud/inspection/getResultTypeList',
  () => (dispatch, getState, httpClient) => {
    let inspectionDict = getState().constant.inspectionDictionaries;
    const list = _.filter(inspectionDict, { 'type': 'RESULTTYPE' });
    dispatch(setResultTypeList(list));
  });

export const resetCklSearch = createAction('@@xcloud/checklist/resetCklSearch',
  (inventoryId) => (dispatch, getState, httpClient) => {
    dispatch(setCklSearch({
      inventoryId: inventoryId ? inventoryId : 0,
      // company: getState().auth.currentUserInfo.companyId,
      dptId: 0,
      roleId: 0,
      // listType: '',
      // catCode: '',
      // severity: '',
      // rootCause: '',
      // status: '',
      page: 0,
      size: 10,
      offset: 0,
      // sort: '-update_time'
    }));
    let bus = getState().constant.businessunits;
    let buList = [];
    if (bus !== undefined) {
      bus.forEach(bu => {
        buList.push({
          name: bu.unitName,
          code: bu.unitId
        })
      })
    }
    dispatch(setBuList(buList));
  });

export const initChecklistHeader = createAction('@@xcloud/checklist/initChecklistHeader',
  (inventoryId, isBack) => (dispatch, getState) => {
    const promiseArray = [];

    if (!isBack) {
      promiseArray.push(
        dispatch(resetCklSearch(inventoryId))
      )
    }

    Promise.all([
      ...promiseArray,
      dispatch(getRoleTree()),
      dispatch(getInventoryById(inventoryId)),
      // dispatch(getListTypeList()),
      // dispatch(getCategoryList()),
      // dispatch(getSeverityList()),
      // dispatch(getRootCauseList()),
      // dispatch(getUnitList()),
      // dispatch(getMethodList()),
      // dispatch(getDisciplineList()),
      // dispatch(getResultTypeList())
    ]).then(() => {
      let roles = getState().role.roleTree;
      let roleList = [];
      roles.forEach(element => {
        if (element.roleGroups) {
          element.roleGroups.forEach(group => {
            if (group.roleList) {
              group.roleList.forEach(role => {
                roleList.push({
                  companyId: element.companyId,
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          })
        }
      })
      dispatch(setRoleList(roleList));

      dispatch(getChecklists({
        ...getState().inspection.cklSearch,
        inventoryId: inventoryId
      }))
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化检查表明细失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const backToInventoryList = createAction('@@xcloud/checklist/backToInventoryList',
  () => (dispatch, getState) => {
    dispatch(push('/checklistManagement', { isBack: true }));
  });

export const backToChecklistTable = createAction('@@xcloud/checklist/backToChecklistTable',
  (id) => (dispatch, getState) => {
    dispatch(push('/checklistManagement/inventory/' + id, { isBack: true }));
  });

export const goToRegulation = createAction('@@xcloud/checklist/goToRegulation',
  (regId) => (dispatch, getState) => {
    if (regId) {
      // 跳转规章列表
      dispatch(push('/leadership/regulation'));
    } else {
      dispatch(createMessage('warning', '规章制度不存在'));
    }
  });

export const getChecklistCount = createAction('@@xcloud/checklist/getChecklistCount',
  () => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/checklists/count';
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          // // 整合count数据，转为计划明细的inspectType形式
          // const countMapping = {};
          // // 整合role数据
          // const roleMapping = {};

          // countMapping['all'] = {};
          // roleMapping['all'] = {};

          // for (const item of res.data) {
          //   const { listType, catCode, dptId, roleId: roleIdOri, count } = item;

          //   // 0代表不限部门/角色
          //   let dptKey = dptId ? dptId : 0;
          //   let countNum = count ? count : 0;
          //   let roleId = roleIdOri ? roleIdOri : 0;

          //   let inspectType = listType;
          //   if (catCode) inspectType = catCode;

          //   // 按部门统计
          //   if (countMapping['all'][dptKey]) {
          //     countMapping['all'][dptKey] += countNum;
          //   } else {
          //     countMapping['all'][dptKey] = countNum;
          //   }
          //   if (roleMapping['all'][dptKey]) {
          //     if (!roleMapping['all'][dptKey].includes(roleId)) roleMapping['all'][dptKey].push(roleId);
          //   } else {
          //     roleMapping['all'][dptKey] = [roleId];
          //   }

          //   // 统计含子类别的主类别总个数
          //   if (catCode) {

          //     if (countMapping[listType]) {
          //       if (countMapping[listType][dptKey]) {
          //         countMapping[listType][dptKey] += countNum;
          //       } else {
          //         countMapping[listType][dptKey] = countNum;
          //       }
          //     } else {
          //       countMapping[listType] = { [dptKey]: countNum };
          //     }

          //     if (roleMapping[listType]) {
          //       if (roleMapping[listType][dptKey]) {
          //         if (!roleMapping[listType][dptKey].includes(roleId)) roleMapping[listType][dptKey].push(roleId);
          //       } else {
          //         roleMapping[listType][dptKey] = [roleId];
          //       }
          //     } else {
          //       roleMapping[listType] = { [dptKey]: [roleId] };
          //     }

          //   }
          //   // 统计不含子类别的主类别个数；统计子类别个数
          //   if (countMapping[inspectType]) {
          //     if (countMapping[inspectType][dptKey]) {
          //       countMapping[inspectType][dptKey] += countNum;
          //     } else {
          //       countMapping[inspectType][dptKey] = countNum;
          //     }
          //   } else {
          //     countMapping[inspectType] = { [dptKey]: countNum };
          //   }
          //   if (roleMapping[inspectType]) {
          //     if (roleMapping[inspectType][dptKey]) {
          //       if (!roleMapping[inspectType][dptKey].includes(roleId)) roleMapping[inspectType][dptKey].push(roleId);
          //     } else {
          //       roleMapping[inspectType][dptKey] = [roleId];
          //     }
          //   } else {
          //     roleMapping[inspectType] = { [dptKey]: [roleId] };
          //   }
          // }
          // 整合count数据，转为层级形式
          const countMapping = {};
          // 整合role数据
          const roleMapping = {};

          countMapping['all'] = {};
          roleMapping['all'] = {};

          for (const item of res.data) {
            const { inventoryId, listType, catCode, dptId, roleId: roleIdOri, count } = item;

            // 0代表不限部门/角色
            let dptKey = dptId ? dptId : 0;
            let countNum = count ? count : 0;
            let roleId = roleIdOri ? roleIdOri : 0;

            let inspectType = listType;
            if (catCode) inspectType = catCode;

            // 不区分表，统计总量时，按部门统计检查项数/角色列表
            if (countMapping['all'][dptKey]) {
              countMapping['all'][dptKey] += countNum;
            } else {
              countMapping['all'][dptKey] = countNum;
            }
            if (roleMapping['all'][dptKey]) {
              if (!roleMapping['all'][dptKey].includes(roleId)) roleMapping['all'][dptKey].push(roleId);
            } else {
              roleMapping['all'][dptKey] = [roleId];
            }

            // 统计含子类别总类别的检查项总个数/总角色列表
            if (catCode) {
              if (countMapping[listType]) {
                if (countMapping[listType][dptKey]) {
                  countMapping[listType][dptKey] += countNum;
                } else {
                  countMapping[listType][dptKey] = countNum;
                }
              } else {
                countMapping[listType] = { [dptKey]: countNum };
              }

              if (roleMapping[listType]) {
                if (roleMapping[listType][dptKey]) {
                  if (!roleMapping[listType][dptKey].includes(roleId)) roleMapping[listType][dptKey].push(roleId);
                } else {
                  roleMapping[listType][dptKey] = [roleId];
                }
              } else {
                roleMapping[listType] = { [dptKey]: [roleId] };
              }

            }
            // 统计各表部门项目数/角色列表
            if (countMapping[inventoryId]) {
              if (countMapping[inventoryId]['dptCount'][dptKey]) {
                countMapping[inventoryId]['dptCount'][dptKey] += countNum;
              } else {
                countMapping[inventoryId]['dptCount'][dptKey] = countNum;
              }
            } else {
              countMapping[inventoryId] = {
                'inspectType': inspectType,
                'dptCount': { [dptKey]: countNum }
              };
            }
            if (roleMapping[inventoryId]) {
              if (roleMapping[inventoryId]['roles'][dptKey]) {
                if (!roleMapping[inventoryId]['roles'][dptKey].includes(roleId)) roleMapping[inventoryId]['roles'][dptKey].push(roleId);
              } else {
                roleMapping[inventoryId]['roles'][dptKey] = [roleId];
              }
            } else {
              roleMapping[inventoryId] = {
                'inspectType': inspectType,
                'roles': { [dptKey]: [roleId] }
              };
            }
          }

          dispatch(setChecklistCount(countMapping));
          dispatch(setChecklistRole(roleMapping));
          dispatch(cleanloading());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '检查表统计信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getChecklists = createAction('@@xcloud/checklist/getChecklists',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    dispatch(setChecklistChange(false));
    let url = '/api/checklists';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=10";
    }
    if (query.sort !== undefined) {
      url += "&sort=" + query.sort;
    } else {
      url += "&sort=-create_time";
    }
    if (query.inventoryId)
      url += "&inventoryId=" + query.inventoryId;
    if (query.company)
      url += "&companyId=" + query.company;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.roleId)
      url += "&roleId=" + query.roleId;
    if (query.listType)
      url += "&listType=" + query.listType;
    if (query.catCode)
      url += "&catCode=" + query.catCode;
    if (query.severity)
      url += "&severity=" + query.severity;
    if (query.rootCause)
      url += "&rootCause=" + query.rootCause;
    if (query.status)
      url += "&status=" + query.status;
    console.log('getChecklists.......', url)
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setChecklists(res.data));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '检查表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getChecklistById = createAction('@@xcloud/checklist/getChecklistById',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/checklists/' + id;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentChecklist(res.data));
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '检查表信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const importChecklistItems = createAction('@@xcloud/checklist/importChecklistItems',
  (inventoryId, doc, callback) =>
    (dispatch, getState, httpClient) => {
      dispatch(createloading());
      return httpClient.post('/api/inventories/' + inventoryId + '/checklist/import', doc).then(
        (res) => {
          dispatch(cleanloading());
          if (callback) {
            callback();
          }
          dispatch(createhttpMessage(res, "导入检查项成功"));
          return res.status;
        }).catch(err => {
          dispatch(cleanloading());
          let msg = `导入检查项失败`;
          dispatch(createhttpMessage(err.response ? err.response : '', msg));
          return []
        })
    });

export const createChecklist = createAction('@@xcloud/checklist/createChecklist',
  (newChecklist) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = '/api/checklists';
    newChecklist.companyId = newChecklist.companyId ? parseInt(newChecklist.companyId) : undefined;
    newChecklist.score = newChecklist.score ? parseFloat(newChecklist.score) : undefined;
    newChecklist.creator = currentUserInfo.userId;
    if (newChecklist.dptId && newChecklist.dptId !== '') {
      newChecklist.dptId = parseInt(newChecklist.dptId)
    } else {
      newChecklist.dptId = 0;
    }
    if (newChecklist.roleId && newChecklist.roleId !== '') {
      newChecklist.roleId = parseInt(newChecklist.roleId)
    } else {
      newChecklist.roleId = 0;
    }
    return httpClient.post(url, newChecklist)
      .then((res) => {
        if (res.status === 201) {
          dispatch(getChecklists(getState().inspection.cklSearch))
          dispatch(backToChecklistTable(newChecklist.inventoryId));
          dispatch(createMessage('success', '创建检查项完成'));
          dispatch(setCurrentChecklist(res.data));
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '创建检查项失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const changeChecklistStatus = createAction('@@xcloud/checklist/changeChecklistStatus',
  (id, status) => (dispatch, getState, httpClient) => {
    let url = '/api/checklists/' + id;
    let to = {
      listId: id,
      status: status
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新检查表状态完成'));
          dispatch(getChecklists(getState().inspection.cklSearch))
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新检查表状态失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateChecklist = createAction('@@xcloud/checklist/updateChecklist',
  (updatedChecklist) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let checklist = getState().inspection.currentChecklist;
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = '/api/checklists/' + checklist.listId;
    let to = {
      inventoryId: updatedChecklist.inventoryId,
      listId: updatedChecklist.listId,
      serialNo: updatedChecklist.serialNo,
      companyId: updatedChecklist.companyId ? parseInt(updatedChecklist.companyId) : undefined,
      listType: updatedChecklist.listType,
      catCode: updatedChecklist.catCode ? updatedChecklist.catCode : '',
      score: updatedChecklist.score ? parseFloat(updatedChecklist.score) : undefined,
      severity: updatedChecklist.severity ? updatedChecklist.severity : '',
      rootCause: updatedChecklist.rootCause ? updatedChecklist.rootCause : '',
      reference: updatedChecklist.reference ? updatedChecklist.reference : '',
      opentext: updatedChecklist.opentext ? updatedChecklist.opentext : '',
      status: updatedChecklist.status,
      discipline: updatedChecklist.discipline ? updatedChecklist.discipline : '',
      method: updatedChecklist.method ? updatedChecklist.method : '',
      attr1: updatedChecklist.attr1 ? updatedChecklist.attr1 : '',
      attr2: updatedChecklist.attr2 ? updatedChecklist.attr2 : '',
      problem: updatedChecklist.problem,
      resultType: updatedChecklist.resultType ? updatedChecklist.resultType : '',
      regId: updatedChecklist.regId ? updatedChecklist.regId : undefined,
      revisor: currentUserInfo.userId,
      descRequired: updatedChecklist.descRequired,
      imageRequired: updatedChecklist.imageRequired,
    }
    if (updatedChecklist.dptId && updatedChecklist.dptId !== '') {
      to.dptId = parseInt(updatedChecklist.dptId)
    } else {
      to.dptId = 0
    }
    if (updatedChecklist.roleId && updatedChecklist.roleId !== '') {
      to.roleId = parseInt(updatedChecklist.roleId)
    } else {
      to.roleId = 0
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          dispatch(getChecklists(getState().inspection.cklSearch))
          dispatch(backToChecklistTable(updatedChecklist.inventoryId));
          dispatch(createMessage('success', '更新检查表信息完成'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新检查表失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const resetIvtSearch = createAction('@@xcloud/checklist/resetIvtSearch',
  () => (dispatch, getState, httpClient) => {

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;
    dispatch(setIvtSearch({
      company: hasPcomp ? companyId : 0,
      dptId: 0,
      roleId: 0,
      listType: '',
      catCode: '',
      status: '',
      page: 0,
      size: 10,
      offset: 0,
      // sort: '-update_time'
    }));
    let bus = getState().constant.businessunits;
    let buList = [];

    if (bus !== undefined) {
      bus.forEach(bu => {
        buList.push({
          name: bu.unitName,
          code: bu.unitId
        })
      })
    }
    dispatch(setBuList(buList));
  });

export const initInventoryHeader = createAction('@@xcloud/checklist/initInventoryHeader',
  (isBack) => (dispatch, getState) => {
    dispatch(createloading());

    if (!isBack) {
      dispatch(resetIvtSearch())
    }
    Promise.all([
      dispatch(getRoleTree()),

      dispatch(getListTypeList()),
      dispatch(getCategoryList()),
      dispatch(getSeverityList()),
      dispatch(getRootCauseList()),
      dispatch(getUnitList()),
      dispatch(getMethodList()),
      dispatch(getDisciplineList()),
      dispatch(getResultTypeList())
    ]).then(() => {
      let roles = getState().role.roleTree;
      let roleList = [];
      roles.forEach(element => {
        if (element.roleGroups) {
          element.roleGroups.forEach(group => {
            if (group.roleList) {
              group.roleList.forEach(role => {
                roleList.push({
                  companyId: element.companyId,
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          })
        }
      })
      dispatch(setRoleList(roleList));

      dispatch(getInventories(getState().inspection.ivtSearch))
      dispatch(cleanloading());
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化检查表搜索失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getInventories = createAction('@@xcloud/checklist/getInventories',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    // dispatch(setChecklistChange(false));
    let url = '/api/inventories';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=10";
    }
    if (query.sort !== undefined) {
      url += "&sort=" + query.sort;
    } else {
      url += "&sort=-create_time";
    }
    if (query.company)
      url += "&companyId=" + query.company;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.roleId)
      url += "&roleId=" + query.roleId;
    if (query.listType)
      url += "&listType=" + query.listType;
    if (query.catCode)
      url += "&catCode=" + query.catCode;
    if (query.status)
      url += "&status=" + query.status;
    return httpClient.get(url)
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (res.data) {

            const tmpData = res.data
            const tmpList = _.filter(tmpData.list, (o) => { return o.listType !== 'INS11' })
            tmpData.list = tmpList
            dispatch(setInventories(tmpData));
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '检查表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getInventoryById = createAction('@@xcloud/checklist/getInventoryById',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/inventories/' + id;
    return httpClient.get(url)
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          dispatch(setCurrentInventory(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '检查表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createInventory = createAction('@@xcloud/checklist/createInventory',
  (newIvt, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = '/api/inventories';
    newIvt.companyId = newIvt.companyId ? parseInt(newIvt.companyId) : undefined;
    newIvt.creator = currentUserInfo.userId;
    return httpClient.post(url, newIvt)
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 201) {
          dispatch(getInventories(getState().inspection.ivtSearch))
          dispatch(createMessage('success', '创建检查表完成'));
          // dispatch(setCurrentChecklist(res.data));
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '创建新检查表失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const changeInventoryStatus = createAction('@@xcloud/checklist/changeInventoryStatus',
  (id, status, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/inventories/' + id;
    let to = {
      inventoryId: id,
      status: status
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新检查表状态完成'));
          dispatch(getInventories(getState().inspection.ivtSearch));
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新检查表状态失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateInventory = createAction('@@xcloud/checklist/updateInventory',
  (updatedInventory, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = '/api/inventories/' + updatedInventory.inventoryId;
    let to = {
      inventoryId: parseInt(updatedInventory.inventoryId),
      inventoryName: updatedInventory.inventoryName,
      companyId: updatedInventory.companyId ? parseInt(updatedInventory.companyId) : 0,
      listType: updatedInventory.listType,
      catCode: updatedInventory.catCode ? updatedInventory.catCode : '',
      validStartTime: updatedInventory.validStartTime ? moment()(updatedInventory.validStartTime).startOf('day').valueOf() : undefined,
      validEndTime: updatedInventory.validEndTime ? moment()(updatedInventory.validEndTime).startOf('day').valueOf() : undefined,
      opentext: updatedInventory.opentext ? updatedInventory.opentext : '',
      status: updatedInventory.status,
      revisor: currentUserInfo.userId
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          dispatch(getInventories(getState().inspection.ivtSearch))
          dispatch(createMessage('success', '更新检查表信息完成'));
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新检查表失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const routeToInventoryInfo = createAction('@@xcloud/checklist/routeToInventoryInfo',
  (inventoryId) => (dispatch) => {
    dispatch(push('/checklistManagement/inventory/' + inventoryId));
  }
);

export const initInventoryDetail = createAction(
  '@@xcloud/checklist/initInventoryDetail',
  (inventoryId, editable) => (dispatch, getState) => {
    return Promise.all([
      dispatch(getInventoryById(inventoryId))
    ]).then(
      () => {
        dispatch(getListTypeList())
        dispatch(getCategoryList())
        dispatch(getSeverityList())
        dispatch(getRootCauseList())
        dispatch(getUnitList())
        dispatch(getMethodList())
        dispatch(getDisciplineList())
        dispatch(getResultTypeList())
      }
    ).then(() => {
      dispatch(push('/checklistManagement/inventory/' + inventoryId));
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化详情页失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  }
);

export const setChecklists = createAction('@@xcloud/checklist/setChecklists');

export const setCklSearch = createAction('@@xcloud/checklist/setCklSearch');

export const setInventories = createAction('@@xcloud/checklist/setInventories');

export const setIvtSearch = createAction('@@xcloud/checklist/setIvtSearch');

export const setListTypeList = createAction('@@xcloud/checklist/setListTypeList');

export const setCategoryList = createAction('@@xcloud/checklist/setCategoryList');

export const setSeverityList = createAction('@@xcloud/checklist/setSeverityList');

export const setRootCauseList = createAction('@@xcloud/checklist/setRootCauseList');

export const setMethodList = createAction('@@xcloud/checklist/setMethodList');

export const setDisciplineList = createAction('@@xcloud/checklist/setDisciplineList');

export const setResultTypeList = createAction('@@xcloud/checklist/setResultTypeList');

export const setBuList = createAction('@@xcloud/checklist/setBuList');

export const setCurrentChecklist = createAction('@@xcloud/checklist/setCurrentChecklist');

export const setChecklistError = createAction('@@xcloud/checklist/setChecklistError');

export const setChecklistChange = createAction('@@xcloud/checklist/setChecklistChange');

export const setCurrentInventory = createAction('@@xcloud/checklist/setCurrentInventory');

export const setInventoryError = createAction('@@xcloud/checklist/setInventoryError');

export const setInventoryChange = createAction('@@xcloud/checklist/setInventoryChange');

export const setChecklistCount = createAction('@@xcloud/checklist/setChecklistCount');

export const setChecklistRole = createAction('@@xcloud/checklist/setChecklistRole');

export const setLeaders = createAction('@@xcloud/checklist/setleaders');

export const setEditable = createAction('@@xcloud/checklist/setEditable');

export const setRoleList = createAction('@@xcloud/userSelect/setRoleList');

export const initCreate = createAction(
  '@@xcloud/checklist/initCreate',
  (inventoryId) => (dispatch, getState) => {
    Promise.all([
      dispatch(getInventoryById(inventoryId)),
      dispatch(getRegulations()),
      dispatch(setEditable(true)),
    ]).then(
      () => {
        dispatch(getListTypeList())
        dispatch(getCategoryList())
        dispatch(getSeverityList())
        dispatch(getRootCauseList())
        dispatch(getUnitList())
        dispatch(getMethodList())
        dispatch(getDisciplineList())
        dispatch(getResultTypeList())
      }
    ).then(() => {
      dispatch(initChecklist(true));
      dispatch(push('/checklistManagement/info'));
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        console.error(err)
        dispatch(cleanloading());
        let msg = '初始化详情页失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  }
);

export const initUpdate = createAction(
  '@@xcloud/checklist/initUpdate',
  (tid, editable, inventoryId) => (dispatch, getState) => {
    return Promise.all([
      dispatch(getInventoryById(inventoryId)),
      dispatch(getRegulations()),
      dispatch(getChecklistById(tid)),
      dispatch(setEditable(editable))
    ]).then(
      () => {
        dispatch(getListTypeList())
        dispatch(getCategoryList())
        dispatch(getSeverityList())
        dispatch(getRootCauseList())
        dispatch(getUnitList())
        dispatch(getMethodList())
        dispatch(getDisciplineList())
        dispatch(getResultTypeList())
      }
    ).then(() => {
      dispatch(initChecklist(false));
      dispatch(push('/checklistManagement/info'));
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化详情页失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  }
);

export const initChecklist = createAction(
  '@@xcloud/checklist/initChecklist',
  (isCreate) => (dispatch, getState) => {
    // 分厂人员，默认选择本公司本部门
    const {
      listType,
      catCode,
      inventoryId
    } = getState().inspection.currentInventory;
    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const departments = _.filter(getState().constant.departments, (o) => { return o.companyId === companyId && o.executable === false });
    const hasPcomp = company && company.pid;
    let checklist = isCreate ? {
      "inventoryId": inventoryId,
      "listId": 0,
      "companyId": hasPcomp ? companyId : 0,
      "dptId": hasPcomp && departments[0] ? departments[0].dptId : 0,
      "listType": listType || '',
      "catCode": catCode || '',
      "severity": '',
      "method": '',
      "attr1": '',
      "attr2": '',
      "problem": '',
      "rootCause": '',
      "discipline": '',
      "resultType": 'BOOL',
      "regId": 0,
      "reference": '',
      "opentext": '',
      "status": "active"
    } : getState().inspection.currentChecklist;
    let terror = {
      "inventoryId": '',
      "listType": '',
      "catCode": '',
      "company": '',
      "department": '',
      "role": '',
      "problem": '',
      "resultType": '',
      "score": ''
    };
    dispatch(setCurrentChecklist(checklist));
    dispatch(setChecklistError(terror));
    dispatch(setChecklistChange(false));
  }
);

export const checklistReducer = handleActions(
  {
    [setInventories]: (state, { payload: value }) => {
      return {
        ...state,
        inventories: value
      };
    },
    [setChecklists]: (state, { payload: value }) => {
      return {
        ...state,
        checklists: value
      };
    },
    [setListTypeList]: (state, { payload: value }) => {
      return {
        ...state,
        listTypeList: value
      };
    },
    [setCategoryList]: (state, { payload: value }) => {
      return {
        ...state,
        categoryList: value
      };
    },
    [setSeverityList]: (state, { payload: value }) => {
      return {
        ...state,
        severityList: value
      };
    },
    [setRootCauseList]: (state, { payload: value }) => {
      return {
        ...state,
        rootCauseList: value
      };
    },
    [setMethodList]: (state, { payload: value }) => {
      return {
        ...state,
        methodList: value
      };
    },
    [setDisciplineList]: (state, { payload: value }) => {
      return {
        ...state,
        disciplineList: value
      };
    },
    [setResultTypeList]: (state, { payload: value }) => {
      return {
        ...state,
        resultTypeList: value
      };
    },
    [setIvtSearch]: (state, { payload: value }) => {
      return {
        ...state,
        ivtSearch: value
      };
    },
    [setCklSearch]: (state, { payload: value }) => {
      return {
        ...state,
        cklSearch: value
      };
    },
    [setBuList]: (state, { payload: value }) => {
      return {
        ...state,
        buList: value
      };
    },
    [setChecklistError]: (state, { payload: value }) => {
      return {
        ...state,
        checklistError: value
      };
    },
    [setInventoryError]: (state, { payload: value }) => {
      return {
        ...state,
        inventoryError: value
      };
    },
    [setChecklistChange]: (state, { payload: value }) => {
      return {
        ...state,
        checklistChange: value
      };
    },
    [setInventoryChange]: (state, { payload: value }) => {
      return {
        ...state,
        inventoryChange: value
      };
    },
    [setChecklistCount]: (state, { payload: value }) => {
      return {
        ...state,
        checklistCount: value
      };
    },
    [setChecklistRole]: (state, { payload: value }) => {
      return {
        ...state,
        checklistRole: value
      };
    },
    [setCurrentChecklist]: (state, { payload: value }) => {
      return {
        ...state,
        currentChecklist: value
      };
    },
    [setCurrentInventory]: (state, { payload: value }) => {
      return {
        ...state,
        currentInventory: value
      };
    },
    [setLeaders]: (state, { payload: data }) => {
      return {
        ...state,
        leaders: data
      }
    },
    [setEditable]: (state, { payload: data }) => {
      return {
        ...state,
        editable: data
      }
    },
    [setRoleList]: (state, { payload: value }) => {
      return {
        ...state,
        roleList: value
      };
    }
  },
  defaultState
);

export default checklistReducer;
