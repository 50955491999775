import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SchemeAcceptPannel from '../../../components/InspectionManagement/HazardList/SchemeAcceptPannel';
import { closeTreatmentSchemeAcceptPannel, changeTreatmentScheme } from '@/redux/modules/inspection/inspectionSchemeAccept';

import _ from 'lodash';

class SchemeAcceptPannelContainer extends Component {
    static propTypes = {
        treatmentScheme: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            treatmentSchemeError: {
                'acceptResult': '',
                'status': ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(this.props.treatmentScheme)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, treatmentScheme } = this.props
        let newEntity = {
            ...treatmentScheme
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        switch (field) {
            case 'status':
                const status = { 'done': '已治理', 'invalid': '整改' };
                value = status[colValue]
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleCheck = (event, field) => {

        const {
            treatmentScheme
        } = this.props;

        let err = {};

        if (!field || field === 'status') {
            if (_.trim(treatmentScheme.status) === '') {
                err['status'] = '请选择验收结果';
            } else {
                err['status'] = '';
            }
        }

        if (!field || field === 'acceptResult') {
            if (_.trim(treatmentScheme.acceptResult) === '') {
                err['acceptResult'] = '请填写验收评价';
            } else {
                err['acceptResult'] = '';
            }
        }

        let newError = {
            ...this.state.treatmentSchemeError,
            ...err
        }
        this.setState({
            treatmentSchemeError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <SchemeAcceptPannel
                open={this.props.open}
                treatmentScheme={this.props.treatmentScheme}
                treatmentSchemeError={this.state.treatmentSchemeError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.inspectionSchemeAccept.open,
        treatmentScheme: state.inspectionSchemeAccept.treatmentScheme,
        confirmCallback: state.inspectionSchemeAccept.confirmCallback,
        closeCallback: state.inspectionSchemeAccept.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (treatmentScheme) => {
            dispatch(changeTreatmentScheme(treatmentScheme));
        },
        onhandleClose: () => {
            dispatch(closeTreatmentSchemeAcceptPannel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchemeAcceptPannelContainer);