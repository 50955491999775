import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const ExceptionAcceptPannel = ({
    classes,
    open,

    exceptionError,
    exception,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck
}) => {
    // console.log('exception comp')
    // console.log(exception)

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{exception.status === 'pending' ? "异常处置确认" : "异常处置"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <FormControl error={exceptionError.handleType !== ''} className={classes.formControl}>
                                <RadioGroup row aria-label="处置类型" name="handleType"
                                    value={exception.handleType}
                                    onChange={onhandleChange}
                                    defaultValue={'HDT01'}>
                                    <FormControlLabel value={'HDT01'} control={<Radio size={'small'} />} label="暂不处置，观察运行" />
                                    <FormControlLabel value={'HDT02'} control={<Radio size={'small'} />} label="错报" />
                                    <FormControlLabel value={'HDT03'} control={<Radio size={'small'} />} label="已处置" />
                                </RadioGroup>
                                <FormHelperText>{exceptionError.handleType}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"处置说明"}
                                variant='outlined'
                                error={exceptionError.handleResult !== ''}
                                helperText={exceptionError.handleResult}
                                className={classes.textField}
                                value={exception.handleResult || ''}
                                rows={2}
                                name='handleResult'
                                onChange={event => onhandleChange(event)}
                                onBlur={event => onhandleCheck(event)}
                            />
                        </Grid>
                        {exception.status !== 'new' &&
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    multiline
                                    label={"确认说明"}
                                    variant='outlined'
                                    // error={exceptionError.acceptResult !== ''}
                                    helperText={exceptionError.acceptResult}
                                    className={classes.textField}
                                    value={exception.acceptResult || ''}
                                    rows={2}
                                    name='acceptResult'
                                    onChange={event => onhandleChange(event)}
                                    onBlur={event => onhandleCheck(event)}
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        确定</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
ExceptionAcceptPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    exceptionError: PropTypes.object.isRequired,
    exception: PropTypes.object.isRequired
};

ExceptionAcceptPannel.defaultProps = {

}

export default withStyles(ExceptionAcceptPannel);