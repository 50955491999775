import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  initAccessoryHeader,
  getAccessoryList,
  resetSearch,
  setSearch,
  initCreate,
} from '../../../redux/modules/accessory';
import AccessoryHeader from "../../../components/AccessoryManagement/AccessoryInfo/AccessoryHeader";
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';

class AccessoryHeaderContainer extends Component {

  static propTypes = {
    companyTree: PropTypes.array,
    catTree: PropTypes.array,
    search: PropTypes.object,
    userProfile: PropTypes.object,
    companyList: PropTypes.array,
  };

  componentDidMount() {
    this.props.onhandleInitAccessoryHeader();
  }

  handleSearch(event) {
    this.props.onhandleSearch(0, 10, "-update_time", "", "", "", "", "", "", "", "", "", "");
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'cat':
        newSearch = {
          ...this.props.search,
          cat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'devCat':
        // 设备类别
        newSearch = {
          ...this.props.search,
          devCat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'isCommon':
        newSearch = {
          ...this.props.search,
          isCommon: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleClearSearch() {
    this.props.onhandleClearSearch();
  }

  handleCheckPermission(route) {
    this.props.onhandleCheckPermission(route)
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'search':
        newSearch = {
          ...this.props.search,
          search: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleCreate(event) {
    this.props.onhandleCreate()
  }

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <div>
        <AccessoryHeader
          companyTree={this.trimCompanyTree()}
          companyList={this.props.companyList}
          catTree={this.props.catTree}
          // 设备类别
          devCatTree={this.props.devCatTree}
          search={this.props.search}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleChange={this.handleChange.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          onhandleCreate={this.handleCreate.bind(this)}

          userProfile={this.props.userProfile}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    search: state.accessory.search,
    userProfile: state.auth.userProfile,
    catTree: state.accessory.catTree,
    // 设备类别
    devCatTree: state.trees.deviceCategoryTree,
    currentUserInfo: state.auth.currentUserInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitAccessoryHeader: () => {
      dispatch(initAccessoryHeader());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleSearch: (offset, size, sort, cat, search, companyId, isCommon, status) => {
      dispatch(getAccessoryList(offset, size, sort, cat, search, companyId, isCommon, status));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleCreate: () => {
      dispatch(initCreate());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessoryHeaderContainer)
