import { createAction, handleActions } from 'redux-actions';


const initialState = {
  globalLoading: false,
  type:'',
};

export const cleanloading = createAction('@@xcloud/loading/cleanloading');

export const createloading = createAction('@@xcloud/loading/createloading');


// Actions

export const loadingReducer = handleActions(
  {
    [createloading]: (state,{payload: value}) => {
      return {
        ...state,
        globalLoading: true,
        type:value
      };
    },
    [cleanloading]: (state) => {
      return {
        ...state,
        globalLoading: false,
        type:''
      };
    }
  },
  initialState
);

export default loadingReducer;
