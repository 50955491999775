import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  detailtable: {
    padding: theme.spacing(4)
  },
  materialtable: {
    boxShadow: 'none'
  }
});

export default withStyles(styles);