import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
// import TreeSelect from 'rc-tree-select';
import { localization, options, style } from '@/utils/mtable';
import { formatDate } from '@/utils/datetime'
import { getCodeName, TYPE_ATTR, ACTIVITY_TYPE } from '@/utils/manufactureUtils'
// import _ from 'lodash'

const MActivityListComponent = props => {
    const { classes,
        activities,
        manufactureDicts,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
        onhandleAttrAdd,
        onhandleAttrUpdate,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    // const activityTypeList = [
    //     {
    //         id: 1,
    //         isDaily: '自动'
    //     },
    //     {
    //         id: 2,
    //         isDaily: '手动'
    //     },
    // ]

    const assemblycolumns = () => {

        const columns = [
            {
                title: '活动名称',
                field: 'activityName',
                render: row => <Typography>{row.activityName}</Typography>
            },
            {
                title: '活动类型',
                field: 'activityType',
                render: row => <Typography>{getCodeName(manufactureDicts, ACTIVITY_TYPE, row.activityType)}</Typography>,
                editable: 'never'
                // lookup: _.chain(getCodeListByType(manufactureDicts, ACTIVITY_TYPE)).keyBy('code').mapValues('name').value()
            },
            // {
            //     title: '调度方式',
            //     field: 'isDaily',
            //     render: row => <Typography>{activityTypeList.find(ele => ele.id === row.isDaily).isDaily || '-'}</Typography>,
            //     lookup: _.chain(activityTypeList).keyBy('id').mapValues('isDaily').value()
            // },
            {
                title: '说明',
                field: 'opentext',
                render: row => <Typography>{row.opentext}</Typography>
            },
            {
                title: '创建时间',
                field: 'createTime',
                render: row => <Typography>{formatDate(row.createTime)}</Typography>,
                editable: 'never',
            },
        ]
        return columns
    }

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,
    }
    const detailTableStyle = {
        ...style
    }

    const assemblyDetailColumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'attrName',
                render: row => <Typography>{row.attrName}</Typography>,
            }, {
                title: '类型',
                field: 'attrType',
                render: row => <Typography>{getCodeName(manufactureDicts, TYPE_ATTR, row.attrType)}</Typography>,
            },
            {
                title: '说明',
                field: 'opentext',
                render: row => <Typography>{row.opentext}</Typography>,
            },
        ]
        return columns
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={activities}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>生产活动列表</Typography>}
                        detailPanel={activity => (
                            <Grid className={classes.detailTable}>
                                <MaterialTable
                                    columns={assemblyDetailColumns()}
                                    data={activity.manufactureAttrs}
                                    options={detailTableOptions}
                                    localization={detailTableLocalization}
                                    style={detailTableStyle}
                                    title={<Typography variant='h4'>活动属性列表</Typography>}
                                    editable={
                                        {
                                            onRowDelete: (rowdata) => onhandleUpdate(rowdata, activity),
                                        }
                                    }
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: '新增',
                                            isFreeAction: true,
                                            onClick: () => {
                                                onhandleAttrAdd({}, activity)
                                            }
                                        },
                                        {
                                            icon: 'edit',
                                            tooltip: '修改',
                                            onClick: (event, rowData) => {
                                                onhandleAttrUpdate(rowData, activity)
                                            }
                                        }
                                        ,
                                    ]}
                                ></MaterialTable>
                            </Grid>
                        )}
                        editable={
                            {
                                isEditHidden: (rowData) => rowData.activityType === 'PREDEFINED',
                                isDeleteHidden: (rowData) => rowData.activityType === 'PREDEFINED',
                                onRowAdd: (rowData) => onhandleAdd(rowData),
                                onRowUpdate: (rowData) => onhandleUpdate(rowData),
                                onRowDelete: (rowData) => onhandleDelete(rowData),
                            }
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

MActivityListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    activities: PropTypes.array,
    manufactureDicts: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleAdd: PropTypes.func,
    onhandleAttrAdd: PropTypes.func,
    onhandleAttrUpdate: PropTypes.func,
};

MActivityListComponent.defaultProps = {

};

export default withStyles(MActivityListComponent);