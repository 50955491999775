import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import OperationPlanDocsList from '@/containers/DeviceManagement/OperationPlanDocs/OperationPlanDocsList';
import { withRouter } from 'react-router';
import {
    Tabs,
    Tab,
    Grid,
    Card,
    Typography
} from '@material-ui/core';

const OperationPlanDocs = ({
    classes,
    onhandleChangeTab,
    tabValue,
    roleW,
}) => {

    return (
        <Card alignitems="center" className={classes.root}>
            <div>
                <Typography variant="h3">
                    工单运维报告
                </Typography>
            </div>
            <React.Fragment>
                <Tabs
                    className={classes.tabs}
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={onhandleChangeTab}
                >
                    {roleW ? <Tab className={classes.tab} label="未完成报告" /> : null}
                    <Tab className={classes.tab} label={"已完成报告"} />
                </Tabs>
                <Grid>
                    {tabValue === 0 &&
                        <Grid>
                            <Grid className={classes.planItem}>
                                <OperationPlanDocsList routeType={"NEW"} />
                            </Grid>
                        </Grid>}
                    {tabValue === 1 &&
                        <Grid>
                            <Grid className={classes.planItem}>
                                <OperationPlanDocsList routeType={"DONE"} />
                            </Grid>
                        </Grid>}

                </Grid>
            </React.Fragment>
        </Card>

    );
};
OperationPlanDocs.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    tabValue: PropTypes.number.isRequired,
    onhandleChangeTab: PropTypes.func.isRequired,
};

OperationPlanDocs.defaultProps = {
}


export default withRouter(withStyles(OperationPlanDocs));