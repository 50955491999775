import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  // card: {
  //   width: '100%',
  //   padding: 20
  // },
  selectField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  // select: {
  //   width: '100%'
  // },
  detailTitle: {
    paddingTop: 24,
    paddingLeft: 16,
    alignItems: 'center'
  },
  action: {
    padding: 16,
  },
  // title: {
  //   padding: theme.spacing(2)
  // },
  button: {
    padding: 16,
    paddingTop: 24,
  },
  flexgrow: {
    flexGrow: 1
  },


  content: {
    display: 'flex',
    height: '70%'
  },
  textField: {
    width: '50%',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: '24px'
  },
  subtitle: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  detail: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  spacer: {
    flexGrow: 1
  },
  divider: {
    margin: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  confirmButton: {
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: '100%'
  },
  // card: {
  //   width: '100%'
  // },
  card: {
    width: '100%',
    padding: 5,
    margin: 5
  },
  select: {
    width: '50%',
    marginTop: 7
  },
  new: {
    width: 60,
    backgroundColor: colors.grey[400],
    color: theme.palette.white,
  },
  active: {
    width: 60,
    backgroundColor: colors.green[400],
    color: theme.palette.white,
  },
  finalized: {
    width: 60,
    backgroundColor: colors.yellow[400],
    color: theme.palette.black,
  },
  done: {
    width: 60,
    backgroundColor: colors.blue[400],
    color: theme.palette.white,
  },
  default: {
    width: 60,
    backgroundColor: colors.grey[300],
    color: theme.palette.white,
  },
});

export default withStyles(styles);
