import React, { Component } from 'react';
import { connect } from 'react-redux';
import SalOutStockComponent from '@/components/AmoebaManagement/SalOutStock';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getSalOutStockList } from '@/redux/modules/amoeba/amoebaSalOutStock';

class SalOutStockContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const outstockDate = formatDate(getMoment()());
        this.props.getSalOutStockList(outstockDate, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const outstockDate = formatDate(date);
            this.props.getSalOutStockList(outstockDate, undefined);
        })
    }

    render() {
        return (
            <div>
                <SalOutStockComponent
                    selectedDate={this.state.selectedDate}
                    salOutStockList={this.props.salOutStockList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        salOutStockList: state.amoebaSalOutStock.salOutStockList,
        userInfo: state.auth.currentUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSalOutStockList: (outstockDate, status) => {
            dispatch(getSalOutStockList(outstockDate, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalOutStockContainer);
