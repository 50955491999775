import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceDetail from '@/components/DeviceManagement/DeviceInfo/DeviceDetail'
import {
    backToList,
    setDeviceBaseChange,
    setDeviceRunParamChange,
    setDeviceTechParamChange,
    setCurrentDevice,
    setDeviceError,
    initDeviceEdit,
    insertDevice,
    modifyDevice,
    // executeProcess,
    setDeviceEdit,
    setDeviceState,
    setDeviceCatChange,
    setDepartmentList,
    setTransferOpen,
    setMediumOpen,
    setCurrentMedia,
} from "@/redux/modules/devices";
import { routeToFormDetail, setGoingForm, setGoingFormDtls } from "@/redux/modules/deviceForm"
import { setDeviceFormApprovalInstance, } from '@/redux/modules/processApproval';
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import { integerRegx } from '@/utils/regex';
import { createMessage } from '@/redux/modules/message';
import { createmodal } from '@/redux/modules/modal';
import { getBusinessUnitsByRole, getRole } from "@/utils/userUtils";
import { getRecordByUnitId, isUnitBranch, getMasterRecordByRecordId } from "@/utils/processUtils";
import { openApprovePannel } from "@/redux/modules/approvePannel";
import ApprovePannel from "@/containers/LeadershipManagement/ApprovePannel";

const mapCurrentRecord = (processes, currentUserInfo, userProfile, currentDeviceProcess) => {
    if (!processes) {
        return {}
    }
    let recordId = currentDeviceProcess.recordId;
    let newProcess = _.filter(processes, function (o) { return _.find(o.records, function (p) { return p.recordId === recordId }) });
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecordByRecordId(newProcess, recordId)

    if (isUnitBranch(newProcess)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(newProcess, bu.unitId)
        }
    }
    return record || {}
}

const getProcessCode = (device) => {
    // let companyId = getCompanyId(currentUserInfo.roleId, userProfile);
    let companyId = device.companyId
    if (companyId) {
        return `DeviceRegistration_${companyId}`;
    } else {
        return `DeviceRegistration`;
    }
}

class DeviceEditContainer extends Component {

    static propTypes = {
        deviceError: PropTypes.object,
        deviceBaseChange: PropTypes.bool,
        currentDevice: PropTypes.object,
        companyTree: PropTypes.array,
        catTree: PropTypes.array,
        useStatusTree: PropTypes.array,
        procatTree: PropTypes.array,
        mediumTree: PropTypes.array,
        isView: PropTypes.bool,
        isDeviceEdit: PropTypes.bool,
        currentUserInfo: PropTypes.object,
        companyList: PropTypes.array,
        // 负责班组列表
        departmentList: PropTypes.array,
        // 负责班组 Checkbox 选择发生变化 处理函数
        onhandleCheckChange: PropTypes.func
    };

    // 组件渲染前... ...
    UNSAFE_componentWillMount() {
        // 初始化班组信息
        if (this.props.currentDevice && this.props.currentDevice.companyId) {
            let dpts = _.filter(this.props.fullDepartmentList, (d) => { return d.companyId === this.props.currentDevice.companyId });
            this.props.onhandleSetDepartmentList(dpts);
        }
    }

    // 组件渲染后... ...
    componentDidMount() {
        this.props.onhandleInitDeviceEdit();
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.deviceError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleDeviceBaseChange(false);
            if (this.props.currentDevice.deviceId) {
                if (this.props.currentDevice.unitId) {
                    this.props.onhandleUpdateDevicePre()
                } else {
                    this.props.onhandleUpdateDevice(this.props.currentDevice);
                }
            } else {
                this.props.onhandleCreateDevice(this.props.currentDevice);
            }
        }
    }

    handleChange = (event, field) => {
        let newDevice;
        switch (field) {
            case 'deviceNo':
                newDevice = {
                    ...this.props.currentDevice,
                    deviceNo: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'deviceName':
                newDevice = {
                    ...this.props.currentDevice,
                    deviceName: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'tagNo':
                newDevice = {
                    ...this.props.currentDevice,
                    tagNo: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'specs':
                newDevice = {
                    ...this.props.currentDevice,
                    specs: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'drawingCode':
                newDevice = {
                    ...this.props.currentDevice,
                    drawingCode: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'model':
                newDevice = {
                    ...this.props.currentDevice,
                    model: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'opentext':
                newDevice = {
                    ...this.props.currentDevice,
                    opentext: event.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleDateChange = (value, field) => {
        let newDevice;
        switch (field) {
            case 'enableTime':
                newDevice = {
                    ...this.props.currentDevice,
                    enableTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'idleTime':
                newDevice = {
                    ...this.props.currentDevice,
                    idleTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'downTime':
                newDevice = {
                    ...this.props.currentDevice,
                    downTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'abandonTime':
                newDevice = {
                    ...this.props.currentDevice,
                    abandonTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleSelectChange = (value, field) => {
        let newDevice;
        switch (field) {
            case 'cat':
                newDevice = {
                    ...this.props.currentDevice,
                    cat: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                //设备已经存在修改修改设备类别弹出警告
                if (this.props.currentDevice.deviceId && !this.props.deviceCatChange) {
                    this.props.onhandleDeviceCatChange(true)
                    this.props.onhandleDeviceCatChangePrompt()
                }
                break;
            case 'companyId':
                newDevice = {
                    ...this.props.currentDevice,
                    companyId: value.target.value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'proCat':
                newDevice = {
                    ...this.props.currentDevice,
                    proCat: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'mgtCat':
                newDevice = {
                    ...this.props.currentDevice,
                    mgtCat: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'extCat':
                newDevice = {
                    ...this.props.currentDevice,
                    extCat: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'useStatus':
                newDevice = {
                    ...this.props.currentDevice,
                    useStatus: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'medium':
                newDevice = {
                    ...this.props.currentDevice,
                    medium: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            default:
            // do nothing
        }
    };


    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleCheck = (event, field) => {

        let err = {};

        // if (!field || field === 'deviceNo') {
        //     if (_.trim(this.props.currentDevice.deviceNo) === '') {
        //         err['deviceNo'] = '设备编号不能为空';
        //     } else {
        //         err['deviceNo'] = '';
        //     }
        // }

        if (!field || field === 'deviceName') {
            if (_.trim(this.props.currentDevice.deviceName) === '') {
                err['deviceName'] = '设备名称不能为空';
            } else {
                err['deviceName'] = '';
            }
        }

        // if (!field || field === 'tagNo') {
        //     if (_.trim(this.props.currentDevice.tagNo) === '') {
        //         err['tagNo'] = '设备位号不能为空';
        //     } else {
        //         err['tagNo'] = '';
        //     }
        // }

        if (!field || field === 'cat') {
            if (this.props.currentDevice.cat) {
                err['cat'] = '';
            } else {
                err['cat'] = '请选择设备类型';
            }
        }

        if (!field || field === 'drawingCode') {
            if (this.props.currentDevice.drawingCode !== '' && !integerRegx.test(this.props.currentDevice.drawingCode)) {
                err['drawingCode'] = '请输入数值';
            } else {
                err['drawingCode'] = '';
            }
        }

        if (!field || field === 'companyId') {
            if (this.props.currentDevice.companyId) {
                err['companyId'] = '';
            } else {
                err['companyId'] = '请选择所属公司';
            }
        }

        if (!field || field === 'useStatus') {
            if (this.props.currentDevice.useStatus) {
                err['useStatus'] = '';
            } else {
                err['useStatus'] = '请选择使用状态';
            }
        }

        if (!field || field === 'idleTime') {
            if (this.props.currentDevice.idleTime && this.props.currentDevice.downTime) {
                let idleTime = new Date(this.props.currentDevice.idleTime)
                let downTime = new Date(this.props.currentDevice.downTime)
                if (idleTime > downTime) {
                    err['idleTime'] = '';
                } else {
                    err['idleTime'] = '闲置时间要晚于停用时间';
                }
            } else {
                err['idleTime'] = '';
            }
        }

        if (!field || field === 'downTime') {
            if (this.props.currentDevice.downTime && this.props.currentDevice.enableTime) {
                let enableTime = new Date(this.props.currentDevice.enableTime)
                let downTime = new Date(this.props.currentDevice.downTime)
                if (downTime > enableTime) {
                    err['downTime'] = '';
                } else {
                    err['downTime'] = '停用时间要晚于投用时间';
                }
            } else {
                err['downTime'] = '';
            }
        }

        if (!field || field === 'abandonTime') {
            if (this.props.currentDevice.abandonTime && this.props.currentDevice.idleTime) {
                let abandonTime = new Date(this.props.currentDevice.abandonTime)
                let idleTime = new Date(this.props.currentDevice.idleTime)
                if (abandonTime > idleTime) {
                    err['abandonTime'] = ''
                } else {
                    err['abandonTime'] = '报废时间要晚于闲置时间';
                }

            } else {
                err['abandonTime'] = '';
            }
        }



        let newError = {
            ...this.props.deviceError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleState(event) {
        this.props.onhandleState()
    }
    handleDeviceEdit(status) {
        this.props.onhandleDeviceEdit(status)
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected') {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleProcessConfirm(action, comment) {
        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    // handleExecuteProcess(action) {
    //     let params = {
    //         action: action,
    //         actionParams: {}
    //     }
    //     this.props.onhandleDeviceEdit(true)
    //     this.props.onhandleProcess(params)
    // }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    // 负责班组 Checkbox 选择发生变化 处理函数
    handleCheckChange = (event, value) => {

        // 设置Flag - 设备基本信息已改变 （True）
        this.props.onhandleDeviceBaseChange(true);

        // 拼装负责班组信息
        let newAllocations = [];
        if (this.props.currentDevice.allocations) {
            newAllocations = [...this.props.currentDevice.allocations]
        }

        if (_.findIndex(newAllocations, (na) => { return na.dptId === value }) > -1) {
            _.remove(newAllocations, (na) => { return na.dptId === value });
        } else {
            newAllocations.push({
                deviceId: this.props.currentDevice.deviceId,
                dptId: value,
            })
        }

        // 更新负责班组信息
        let newDevice = {
            ...this.props.currentDevice,
            allocations: newAllocations
        };

        // 更新当前设备状态
        this.props.onhandleUpdateEditDevice(newDevice);

        // 设置校验信息
        let err = {};
        if (newAllocations.length === 0) {
            err['allocations'] = '请选择负责班组';
        } else {
            err['allocations'] = '';
        }
        let newError = {
            ...this.props.deviceError,
            ...err
        }
        this.props.onhandleSetError(newError);
    }

    handleSelectMaster = (event) => {

        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.props.currentUserInfo.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let newDevice = {
                            ...this.props.currentDevice,
                            masterId: this.props.selectedDevices[0].deviceId,
                            masterDevice: this.props.selectedDevices[0].deviceName
                        };
                        this.props.onhandleDeviceBaseChange(true);
                        // 更新当前设备状态
                        this.props.onhandleUpdateEditDevice(newDevice);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);

    }

    handleOpenTransfer = () => {
        this.props.onhandleSetTransferOpen(true)
    }

    handleOpenMedium = (media) => {
        this.props.onhandleSetMediumOpen(true, media)
    }

    handleGenerateForm = async () => {
        let form = {
            companyId: this.props.currentDevice.companyId,
            deviceId: this.props.currentDevice.deviceId,
            deviceNo: this.props.currentDevice.deviceNo,
            deviceName: this.props.currentDevice.deviceName,
            targetType: 'DEV',
            status: 'new'
        }

        if (this.props.currentDevice.status === 'new') { //设备注册
            form.formType = 'REGISTER_FORM'
            form.formName = this.props.currentDevice.deviceName + '注册'
        } else if (this.props.currentDevice.status === 'active') { //设备变更
            form.formType = 'INFO_UPDATE_FORM'
            form.formName = this.props.currentDevice.deviceName + '信息变更'

            if (this.props.goingFormDtls.length === 0) {
                this.props.onhandleDeviceUpdateFormCheck()
                return
            } else {
                let scrapDtl = _.find(this.props.goingFormDtls, { 'attrName': 'useStatus', 'attrNewValue': 'SCRAP' })
                if (scrapDtl) {
                    form.formType = 'SCRAP_FORM'
                    form.formName = this.props.currentDevice.deviceName + '报废'
                    this.props.onhanldeSetGoingFormDtls([scrapDtl])
                }
            }
        }

        await this.props.onhanldeSetGoingForm(form)
        await this.props.onhandleSetDeviceFormApprovalInstance([])
        this.props.onhandleGenerateForm(0)
    }

    render() {
        return (
            <div>
                <DeviceDetail
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    currentDevice={this.props.currentDevice}
                    deviceError={this.props.deviceError}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleSelectMaster={this.handleSelectMaster.bind(this)}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.props.companyList}
                    catTree={this.props.catTree}
                    useStatusTree={this.props.useStatusTree}
                    procatTree={this.props.procatTree}
                    mgtcatTree={this.props.mgtcatTree}
                    extcatTree={this.props.extcatTree}
                    deviceBaseChange={this.props.deviceBaseChange}
                    mediumTree={this.props.mediumTree}
                    mediumPhase={this.props.mediumPhase}
                    isView={this.props.isview}
                    isDeviceEdit={this.props.isDeviceEdit}
                    currentProcessStatus={this.props.currentProcessStatus}
                    onhandleState={this.handleState.bind(this)}
                    onhandleDeviceEdit={this.handleDeviceEdit.bind(this)}
                    // onhandleProcess={this.handleProcess.bind(this)}
                    constant={this.props.constant}
                    currentRecord={this.props.currentRecord}
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    isLoading={this.props.isLoading}
                    deviceCatChange={this.props.deviceCatChange}
                    // 负责班组列表
                    departmentList={this.props.departmentList}
                    // 负责班组 Checkbox 选择发生变化 处理函数
                    onhandleCheckChange={this.handleCheckChange.bind(this)}
                    onhandleOpenTransfer={this.handleOpenTransfer.bind(this)}
                    onhandleOpenMedium={this.handleOpenMedium.bind(this)}
                    onhandleGenerateForm={this.handleGenerateForm.bind(this)}
                />
                <ApprovePannel />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.devices.isLoading,
        deviceBaseChange: state.devices.deviceBaseChange,
        currentDevice: state.devices.currentDevice,
        deviceError: state.devices.deviceError,
        catTree: state.trees.deviceCategoryTree,
        useStatusTree: state.devices.useStatusTree,
        procatTree: state.devices.procatTree,
        mgtcatTree: state.devices.mgtcatTree,
        extcatTree: state.devices.extcatTree,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        currentDeviceProcess: state.devices.currentDeviceProcess,
        userProfile: state.auth.userProfile,
        selectedDevices: state.deviceSelect.selectedDevices,
        mediumTree: state.devices.mediumTree,
        mediumPhase: state.devices.mediumPhase,
        isview: state.devices.view,
        isDeviceEdit: state.devices.deviceEdit,
        deviceCatChange: state.devices.deviceCatChange,
        constant: state.constant,
        currentRecord: mapCurrentRecord(
            state.process.processes[getProcessCode(state.devices.currentDevice)],
            state.auth.currentUserInfo,
            state.auth.userProfile,
            state.devices.currentDeviceProcess
        ),
        currentProcessStatus: state.process.currentProcessStatus
            && state.process.currentProcessStatus[getProcessCode(state.devices.currentDevice)]
            ? state.process.currentProcessStatus[getProcessCode(state.devices.currentDevice)]
            : {},
        // 班组列表
        departmentList: state.devices.departmentList,
        // 全部班组（部门）列表
        fullDepartmentList: state.constant.departments,
        goingFormDtls: state.deviceForm.goingFormDtls,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateDevice: (entity) => {
            dispatch(insertDevice(entity))
        },
        onhandleUpdateDevice: (entity) => {
            dispatch(modifyDevice(entity))
        },
        onhandleUpdateEditDevice: (entity) => {
            dispatch(setCurrentDevice(entity))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceError(err))
        },
        onhandleDeviceBaseChange: (isChanged) => {
            dispatch(setDeviceBaseChange(isChanged))
        },
        onhandleDeviceRunParamChange: (isChanged) => {
            dispatch(setDeviceRunParamChange(isChanged))
        },
        onhandleDeviceTechParamChange: (isChanged) => {
            dispatch(setDeviceTechParamChange(isChanged))
        },
        onhandleInitDeviceEdit: () => {
            dispatch(initDeviceEdit());
        },
        onhandleUpdateDevicePre: () => {
            dispatch(createMessage('error', '请从装置移除后，再更新设备'));
        },
        // onhandleProcess: (params) => {
        //     dispatch(executeProcess(params))
        // },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleDeviceEdit: (status) => {
            dispatch(setDeviceEdit(status))
        },
        onhandleState: () => {
            dispatch(setDeviceState())
        },
        onhandleDeviceCatChange: (data) => {
            dispatch(setDeviceCatChange(data))
        },
        onhandleDeviceCatChangePrompt: () => {
            dispatch(createMessage('warning', '修改设备类型将会变更结构和运行参数,未保存前其他信息将锁定'));
        },
        onhandleDeviceUpdateFormCheck: () => {
            dispatch(createMessage('warning', `设备信息未变更，不会生成变更单`));
        },
        // 设置班组列表
        onhandleSetDepartmentList: (value) => {
            dispatch(setDepartmentList(value))
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
        onhandleSetTransferOpen: (isOpen) => {
            dispatch(setTransferOpen(isOpen))
        },

        onhandleSetMediumOpen: (isOpen, media) => {
            dispatch(setMediumOpen(isOpen, media))
            dispatch(setCurrentMedia(media))
        },

        onhanldeSetGoingForm: (form) => {
            return dispatch(setGoingForm(form))
        },
        onhanldeSetGoingFormDtls: (form) => {
            return dispatch(setGoingFormDtls(form))
        },
        onhandleGenerateForm: () => {
            return dispatch(routeToFormDetail(0))
        },
        onhandleSetDeviceFormApprovalInstance: (approvals) => {
            return dispatch(setDeviceFormApprovalInstance(approvals));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceEditContainer)
