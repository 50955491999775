import React from 'react';
import {
    Grid, Typography, Card, TextField, MenuItem, Button, Tooltip, IconButton,
    Dialog,
    // DialogTitle,
    DialogContent,
    DialogActions,
    TextareaAutosize
} from '@material-ui/core';
// import MaterialTable from 'material-table';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
// import * as _ from 'lodash';
import { getDateByTimestamp } from '@/utils/datetime';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';
import { Add } from "@material-ui/icons";

const NoticeConfigList = props => {
    const { classes,
        configState,
        configList,
        currentConfig,
        // isloading,
        editDialogOpen,
        onHandleGetConfigList,
        onhandleChange,
        onhandleClearSearch,
        // onhandlePageSizeChange,
        // onhandlePageChange,
        onhandleCreateConfig,
        onhandleEditConfig,
        onhandleEditChange,
        onSaveConfig,
        onCancle
    } = props;

    // const loadingpage = () => (
    //     <div style={{ position: 'absolute', zIndex: 999, width: '100%', height: '100%' }}>
    //         <Grid className={classes.loading} container spacing={0} alignItems="center" wrap="nowrap">
    //             <CircularProgress className={classes.progress} />
    //         </Grid>
    //     </div>
    // );

    const renderChannel = (row) => {
        if (row.noticeChannel === 1) {
            return '网页端';
        } else if (row.noticeChannel === 2) {
            return '手机端';
        } else {
            return '全部';
        }
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '通知名称',
                dataIndex: 'noticeName',
                key: 'noticeName',
                width: '20%',
                render: (text, row, index) => <Typography noWrap>{row.noticeName}</Typography>,
                fixed: 'left',
                ellipsis: false
            },
            {
                title: '通知编码',
                dataIndex: 'noticeCode',
                key: 'noticeCode',
                width: '20%',
                render: (text, row, index) => <Typography noWrap>{row.noticeCode}</Typography>,
                fixed: 'left',
                ellipsis: false
            },
            {
                title: '渠道',
                dataIndex: 'noticeChannel',
                key: 'noticeChannel',
                width: '10%',
                render: (text, row, index) => row ? <Typography>{renderChannel(row)}</Typography> : '',
                fixed: 'left',
            },
            {
                title: '描述',
                dataIndex: 'template',
                key: 'template',
                align: 'left',
                render: (text, row, index) => <Typography>{row.noticeDesc}</Typography>,
                width: '30%',
                fixed: 'left',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                align: 'left',
                width: '10%',
                render: (text, row, index) => <Typography>{getDateByTimestamp(row.updateTime, true)}</Typography>,
                fixed: 'left',
            },
            {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: '10%',
                render: (text, row, index) => renderActions(row),
            }
        ];

        return columns;
    }

    // const [expand, setExpand] = useState(false);
    const renderActions = (row) => {
        return <Grid container alignItems='center'>
            <Grid item><Tooltip title='修改'><IconButton variant='text' onClick={() => onhandleEditConfig(row, 'update')}> <EditIcon fontSize='small' /></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title='配置通知对象'><IconButton variant='text' onClick={() => onhandleEditConfig(row, 'config')}> <SettingsIcon fontSize='small' /></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => onhandleEditConfig(row, 'delete')}> <DeleteIcon fontSize='small' /></IconButton></Tooltip></Grid>
        </Grid>
    }

    const showEditDialog = () => (
        <React.Fragment>
            <Dialog maxWidth="xs" fullWidth={true} open={editDialogOpen} aria-labelledby="form-dialog-title">
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                        {currentConfig.noticeId === 0 ? '创建通知配置' : '编辑通知配置'}
                    </Typography>
                </Grid>
                <DialogContent>
                    <Grid container className={classes.gridContainer}>
                        <Typography className={classes.label}>
                            通知名称
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            className={classes.textField}
                            size="small"
                            name='noticeName'
                            value={currentConfig.noticeName || ''}
                            onChange={event => onhandleEditChange(event, 'noticeName')}
                        >
                        </TextField>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                        <Typography className={classes.label}>
                            通知编码
                        </Typography>
                        <TextField
                            fullWidth
                            disabled={currentConfig.noticeId !== 0}
                            variant="outlined"
                            className={classes.textField}
                            size="small"
                            name='noticeCode'
                            value={currentConfig.noticeCode || ''}
                            onChange={event => onhandleEditChange(event, 'noticeCode')}
                        >
                        </TextField>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                        <Typography className={classes.label}>
                            通知渠道
                        </Typography>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            className={classes.textField}
                            size="small"
                            name='noticeChannel'
                            value={currentConfig.noticeChannel || 0}
                            onChange={event => onhandleEditChange(event, 'noticeChannel')}
                        >
                            <MenuItem key={0} value={0}>{'全部'}</MenuItem>
                            <MenuItem key={1} value={1}>
                                网页端
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                手机端
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                        <Typography className={classes.label}>
                            描述(可选)
                        </Typography>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentConfig.noticeDesc || ''}
                            rowsMin={5}
                            onChange={event => onhandleEditChange(event, 'noticeDesc')}
                        />
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={event => onCancle(event)} >取消</Button>
                    <Button variant="contained" color="primary" onClick={event => onSaveConfig(event)} >保存</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    return (
        <Card className={classes.card}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3" >
                    {'通知配置'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container spacing={0} className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            名&emsp;称
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="notice_name"
                            value={configState.name || ''}
                            onChange={(event) => onhandleChange(event, 'noticeName')}
                            variant="outlined"
                        >
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            编&emsp;码
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            variant="outlined"
                            id="notice_code"
                            value={configState.code || ''}
                            onChange={(event) => onhandleChange(event, 'noticeCode')}
                        >
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            渠&emsp;道
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            variant="outlined"
                            select
                            value={configState.channel}
                            onChange={(event) => onhandleChange(event, 'noticeChannel')}
                        >
                            <MenuItem key={0} value={0}>{'全部'}</MenuItem>
                            <MenuItem key={1} value={1}>
                                网页端
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                手机端
                            </MenuItem>
                        </TextField>
                    </Grid>

                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>

                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                        <Button variant="contained" color="primary"
                            onClick={onHandleGetConfigList}
                        >搜索</Button>
                        <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >重置</Button>
                    </Grid>

                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        <Button variant="contained" color="primary" startIcon={<Add />}
                            onClick={onhandleCreateConfig}
                        >新建</Button>
                    </Grid>
                </Grid>
            </Grid>


            <Grid className={classes.detailtable}>
                <Table
                    className={classes.stripedTable}
                    rowKey={record => (record.noticeId + '_' + Date.now())} // 保持刷新
                    columns={assemblycolumns()}
                    dataSource={configList.list}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: () => `共${configList.total}条`,
                        pageSizeOptions: ['10', '20', '30'],
                        // current: Math.round(configState.offset / configState.size) + 1,
                        // total: configList.total,
                        // onShowSizeChange: (current, pageSize) => onhandlePageSizeChange(pageSize, current),
                        // onChange: current => onhandlePageChange(current)
                    }}
                    size={'small'}
                    tableLayout={'fixed'}
                />
            </Grid>
            {/* {isloading ? loadingpage() : null} */}
            {showEditDialog()}
        </Card >
    );
}

NoticeConfigList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(NoticeConfigList);