import React, { Component } from 'react';
import { connect } from 'react-redux';
import AmoebaReportFixedComponent from '@/components/AmoebaManagement/Report/AmoebaReportFixed';
import PropTypes from 'prop-types';
import { getMoment, formatDay } from '@/utils/datetime';
import { createloading, cleanloading } from '@/redux/modules/loading';
import {
    getAmoebaReports, generateAmoebaReport, getReportFixeds
    , addReportFixed, updateReportFixed, delReportFixed
} from '@/redux/modules/amoeba/amoebaReport';
import { getRole } from '@/utils/userUtils';
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { getCompanyName, getDepartmentsByRole } from '@/utils/userUtils';
import { formSelectTree } from '@/utils/constant';

import * as _ from 'lodash'

class AmoebaReportFixedContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedCompanyId: 0,
            dptIds: '',
            selectedDate: getMoment()(),
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const date = getMoment()();
        const day = formatDay(date);
        let dpts = _.filter(this.props.departments, { 'companyId': this.props.currentUserInfo.companyId })
        const dptIds = _.map(dpts, 'dptId').join(',')
        this.props.getReportFixeds(day, this.props.currentUserInfo.companyId, dptIds)
        this.props.getAmoebaIndexes()
        this.setState({
            dptIds: dptIds,
        })
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            this.props.getReportFixeds(formatDay(date), this.props.currentUserInfo.companyId, this.state.dptIds)
        });
    }

    handlePopAdd = (rowData) => {
        const day = formatDay(this.state.selectedDate)
        const [year, month, date] = day.split('/');
        rowData.year = year
        rowData.month = month
        rowData.day = date
        rowData.companyId = this.props.currentUserInfo.companyId
        rowData.subId = rowData.sub.value
        return this.props.addReportFixed(rowData).then(
            () => this.props.getReportFixeds(formatDay(this.state.selectedDate), this.props.currentUserInfo.companyId, this.state.dptIds)
        )
    }

    handleUpdate = (newData, oldData) => {
        newData.subId = newData.sub.value
        return this.props.updateReportFixed(newData).then(
            () => this.props.getReportFixeds(formatDay(this.state.selectedDate), this.props.currentUserInfo.companyId, this.state.dptIds)
        )
    }

    handleDel = (fixedId) => {
        return this.props.delReportFixed(fixedId).then(
            () => this.props.getReportFixeds(formatDay(this.state.selectedDate), this.props.currentUserInfo.companyId, this.state.dptIds)
        )
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_other_sharing').action === 'W';
        const subTree = formSelectTree(this.props.indexes, true, 'subName', 'subId', 'subParentId')
        let fixeds = this.props.reportFixeds
        for (let fixed of fixeds) {
            fixed.sub = { label: fixed.subName, value: fixed.subId }
        }
        return (
            <AmoebaReportFixedComponent
                subTree={subTree}
                selectedDate={this.state.selectedDate}
                companies={this.props.companies}
                departments={this.props.departments}
                currentUserInfo={this.props.currentUserInfo}
                roleFlag={roleFlag}
                indexes={this.props.indexes}
                selectedCompanyId={this.state.selectedCompanyId}
                reportFixeds={fixeds}
                constant={this.props.constant}
                userDepartment={this.props.userDepartment}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandlePopAdd={this.handlePopAdd.bind(this)}
                onhandleUpdate={this.handleUpdate.bind(this)}
                onhandleDel={this.handleDel.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        userDepartment: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile)[0],
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        companyName: getCompanyName(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        reports: state.amoebaReport.reports,
        reportFixeds: state.amoebaReport.reportFixeds,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.company.companyList.list,
        departments: state.department.departments.list,
        indexes: state.amoebaIndex.indexes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, null, null));
        },
        getAmoebaReports: (day, companyIds, dptIds) => {
            const [year, month, date] = day.split('/');
            return dispatch(getAmoebaReports(null, year, month, date, null, companyIds, dptIds, null));
        },

        getReportFixeds: (day, companyIds, dptIds) => {
            const [year, month, date] = day.split('/');
            return dispatch(getReportFixeds(year, month, date, null, companyIds, dptIds, '0'));
        },
        addReportFixed: (fixDto) => {
            return dispatch(addReportFixed(fixDto));
        },
        updateReportFixed: (fixDto) => {
            return dispatch(updateReportFixed(fixDto));
        },
        delReportFixed: (fixedId) => {
            return dispatch(delReportFixed(fixedId));
        },
        generateAmoebaReport: (param, callback) => {
            return dispatch(generateAmoebaReport(param, callback));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AmoebaReportFixedContainer);
