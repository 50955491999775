import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Divider, Typography, DialogContent, Dialog, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';
import { getDepartmentsByRole, getRole } from '@/utils/userUtils'
import CommonFileUpload from '@/components/CommonFileUpload';
import Archive from '@material-ui/icons/Archive';

const TeamActivityPannel = ({
    classes,
    activity,
    currentUserInfo,
    userProfile,
    leaders,
    editDisabled,
    companyTopology,
    onhandleClose,
    onhandleChangeLeader,
    onhandleChangeActivityTime,
    onhandleChangeActivityAttr,
    onhandleUpdateActivity,
    onhandleUploadFileSelected,
    onhandleUploadConfirm
}) => {


    let readOnly = true
    const company = _.find(companyTopology, c => { return c.companyId === activity.companyId })
    if (company)
        activity.companyName = company.companyName

    if (activity.dpts && activity.dpts.length > 0) {
        activity.dptName = activity.dpts[0].dptName

        if (currentUserInfo.roleId
            && getRole(currentUserInfo.roleId, userProfile).roleName === '班长') {
            const departments = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
            let theDpt = _.intersectionBy(departments, activity.dpts, 'dptId')
            if (theDpt && theDpt.length > 0) {
                readOnly = false
            }
        }
    }

    let activityLeaders = activity.users
    if (activity.users) {
        activityLeaders = _.filter(activity.users, u => { return u.roleType === 2 })
    }

    const config = {
        title: '附件',
        note: '请拖拽文件至此处',
        fileType: '.jpeg,.jpg,.png,.doc,.docx,.pdf,.ppt,pptx',
        maxSize: '1024MB',
        multiple: false,
        showStyle: 'inline',
        onFileSelected: onhandleUploadFileSelected.bind(this),
        onConfirm: onhandleUploadConfirm.bind(this),
    }

    return (
        <div alignitems="center" >
            {!activity || !activity.actId ? '' :
                <Dialog open={activity && activity.actId ? true : false} onClose={onhandleClose} aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                    maxWidth='lg'>
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>{activity.companyName + ' ' + activity.dptName} 班组安全活动</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <Grid container >
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item className={classes.item}>
                                        <TextField
                                            fullWidth
                                            className={classes.itemText}
                                            variant="outlined"
                                            placeholder="请输入"
                                            label="班组"
                                            name='dptName'
                                            defaultValue={activity.dptName}
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <Autocomplete
                                            variant="outlined"
                                            disabled={editDisabled}
                                            multiple
                                            filterSelectedOptions
                                            id={"users-standard"}
                                            options={leaders}
                                            getOptionLabel={option => option.lastName + '' + option.firstName}
                                            defaultValue={activityLeaders}
                                            onChange={onhandleChangeLeader}
                                            renderInput={params => (
                                                <TextField className={classes.itemText}
                                                    variant="outlined"
                                                    {...params}
                                                    id={"users-standard-text"}
                                                    label="领导"
                                                    placeholder="选择人员"
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="请输入"
                                            label="地点"
                                            name='location'
                                            defaultValue={activity.location}
                                            onChange={onhandleChangeActivityAttr}
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            placeholder="请输入"
                                            label="学习内容"
                                            name='opentext'
                                            defaultValue={activity.opentext}
                                            onChange={onhandleChangeActivityAttr}
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="请输入"
                                            label="培训人"
                                            name='lecturer'
                                            defaultValue={activity.lecturer}
                                            onChange={onhandleChangeActivityAttr}
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                            <KeyboardDateTimePicker
                                                autoOk
                                                fullWidth
                                                ampm={false}
                                                // disableToolbar
                                                inputVariant='outlined'
                                                format="yyyy/MM/dd HH:mm"
                                                id="date-selector"
                                                margin="normal"
                                                value={activity.activityTime}
                                                onChange={onhandleChangeActivityTime}
                                                InputProps={{
                                                    readOnly: readOnly,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item><Divider orientation="vertical" /></Grid>
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item className={classes.record}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="请输入"
                                            label={"活动记录"}
                                            fullWidth
                                            multiline
                                            value={activity.recordContent}
                                            rows={29}
                                            name='recordContent'
                                            onChange={onhandleChangeActivityAttr}
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item><Divider orientation="vertical" /></Grid>
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item>
                                        <Grid container><Archive /><Typography>文件名</Typography> </Grid></Grid>
                                    <Grid item>
                                        <CommonFileUpload
                                            title={config.title}
                                            fileType={config.fileType}
                                            note={config.note}
                                            maxSize={config.maxSize}
                                            multiple={config.multiple}
                                            component={config.component}
                                            children={config.children}
                                            showStyle={config.showStyle}
                                            onFileSelected={config.onFileSelected}
                                            onConfirm={config.onConfirm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={onhandleUpdateActivity}>
                            保存</Button>
                        <Button variant="text" color="primary" onClick={onhandleClose}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

TeamActivityPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};
TeamActivityPannel.defaultProps = {
    leaders: []
}


export default withStyles(TeamActivityPannel);