import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialog: {
        width: '100%',
        padding: 20
    },
    uploadErr: {
        boxShadow: '0 0 2px 0.1rem ' + theme.palette.error.main
    },
    uploadErrText: {
        color: theme.palette.error.main
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    textFieldInline: {
        marginLeft: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 100,
    },
    chip: {
        margin: '1px'
    },
    checkbox: {
        padding: 0,
        marginRight: 5
    },
    gridContainer: {
        marginTop: 10
    },
    item: {
        padding: 0
    },
    downloadTemplate: {
        marginLeft: 10
    },
    title: {
        size: theme.typography.h3
    },
    contentGrid: {
        // flexDirection: 'column'
    },
    itemGrid: {
        paddingBottom: theme.spacing(2),
    },

    gridItem: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2)
    },

    formContainer: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 80px)'
    },
    justify: {
        display: 'inline-block',
        textAlign: 'justify',
        textAlignLast: 'justify',
        width: 70,
    },
    label: {
        paddingTop: theme.spacing(0.8),
        whiteSpace: 'nowrap',
        width: 80,
        textAlign: 'right'
    },
    labelValue: {
        paddingTop: theme.spacing(1.25),
    },
    confirmButton: {
        marginRight: theme.spacing(0.5),
    },
    flexgrow: {
        flexGrow: 1,
    },
    dialogActions: {
        padding: theme.spacing(4),
    }
});

export default withStyles(styles);