import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { createhttpMessage } from './message';

const defaultState = {
    isloading: false,
    devices: [],
    currentDevice: {},
}

export const setLoading = createAction('@@xcloud/devicecard/setLoading');

export const setCurrentDevice = createAction('@@xcloud/devicecard/setCurrentDevice');

export const setDevices = createAction('@@xcloud/devicecard/setDevices');

export const getDevice = createAction('@@xcloud/devicecard/getDevice',
    (deviceId) => (dispatch, getState, httpClient) => {
        dispatch(setLoading(true));
        let devices = getState().devicecard.devices;
        let device = _.find(devices, ['deviceId', deviceId]);
        if (device) {
            dispatch(setCurrentDevice(device));
            dispatch(setLoading(false));
        } else {
            httpClient.get('/api/devices/' + deviceId)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setCurrentDevice(res.data));
                        dispatch(setDevices(res.data));
                        dispatch(setLoading(false));
                    }
                }).catch(err => {
                    let msg = `获取设备信息失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    dispatch(setLoading(false));
                    return []
                })
        }
    }
);

export const devicecardReducer = handleActions(
    {
        [setLoading]: (state, { payload: value }) => {
            return {
                ...state,
                isloading: value
            };
        },
        [setCurrentDevice]: (state, { payload: value }) => {
            return {
                ...state,
                currentDevice: value
            };
        },
        [setDevices]: (state, { payload: value }) => {
            return {
                ...state,
                devices: [...state.devices, value]
            };
        },
    },
    defaultState
)

export default devicecardReducer;