import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceBuild from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceBuild';
import {
    backToList,
    setDeviceBuildError,
    // 设备基本信息
    setDeviceBaseChange,
    setCurrentDevice,
    modifyDevice,
    // 建设信息
    initDeviceBuild,
    setDeviceBuildChange,
    setDeviceBuild,
    insertDeviceBuild,
    modifyDeviceBuild,
    // 设计制造信息
    initDeviceDesign,
    setDeviceDesignChange,
    setDeviceDesign,
    insertDeviceDesign,
    modifyDeviceDesign
} from "../../../redux/modules/devices";
import _ from 'lodash';
import PropTypes from "prop-types";
import { download } from '../../../redux/modules/minio';

class DeviceBuildContainer extends Component {

    static propTypes = {
        deviceBuildError: PropTypes.object,
        deviceBuildChange: PropTypes.bool,
        deviceBuild: PropTypes.object,
        isView: PropTypes.bool,
        currentDevice: PropTypes.object,
        deviceDoc: PropTypes.array,
    };

    componentDidMount() {
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitdeviceBuild();
        }
    }

    handleSave = async (event) => {
        // 分开处理3个实体
        await this.handleCheck(event, null);
        console.log(this.props.deviceBuildError)
        if (_.findIndex(Object.values(this.props.deviceBuildError), function (o) { return o !== ''; }) < 0) {

            this.props.onhandledeviceBuildChange(false);
            this.props.onhandleDeviceDesignChange(false);
            this.props.onhandleDeviceBaseChange(false);

            debugger
            // 基础信息
            if (this.props.currentDevice.deviceId) {
                this.props.onhandleUpdateDevice(this.props.currentDevice);
            } else {
                this.props.onhandleCreateDevice(this.props.currentDevice);
            }
            // 建设信息
            if (this.props.deviceBuild.infoId) {
                this.props.onhandleUpdatedeviceBuild(this.props.deviceBuild);
            } else {
                this.props.onhandleCreatedeviceBuild(this.props.deviceBuild);
            }
            // 设计制造信息
            if (this.props.deviceDesign.infoId) {
                this.props.onhandleUpdateDeviceDesign(this.props.deviceDesign);
            } else {
                this.props.onhandleCreateDeviceDesign(this.props.deviceDesign);
            }
        }
    }

    handleChange = (event, field) => {
        let newDevice;
        switch (field) {
            // 设备基本信息

            // 建设信息
            case 'supervisor':
                newDevice = {
                    ...this.props.deviceBuild,
                    supervisor: event.target.value
                };
                this.props.onhandledeviceBuildChange(true);
                this.props.onhandleUpdateEditdeviceBuild(newDevice);
                break;
            case 'constructor':
                newDevice = {
                    ...this.props.deviceBuild,
                    constructor: event.target.value
                };
                this.props.onhandledeviceBuildChange(true);
                this.props.onhandleUpdateEditdeviceBuild(newDevice);
                break;
            // 设计制造信息
            case 'serialNo':
                newDevice = {
                    ...this.props.deviceDesign,
                    serialNo: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            case 'designer':
                newDevice = {
                    ...this.props.deviceDesign,
                    designer: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            case 'specCode':
                newDevice = {
                    ...this.props.deviceDesign,
                    specCode: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            case 'manufacturer':
                newDevice = {
                    ...this.props.deviceDesign,
                    manufacturer: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            case 'permitNo':
                newDevice = {
                    ...this.props.deviceDesign,
                    permitNo: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            case 'designLife':
                newDevice = {
                    ...this.props.deviceDesign,
                    designLife: event.target.value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            default:
            // do nothing
        }
    };


    handleSelectChange = (value, field) => {
        let newDevice;
        switch (field) {
            // 设备基本信息

            // 建设信息
            case 'acceptDoc':
                newDevice = {
                    ...this.props.deviceBuild,
                    acceptDoc: value
                };
                this.props.onhandledeviceBuildChange(true);
                this.props.onhandleUpdateEditdeviceBuild(newDevice);
                break;
            // 设计制造信息
            case 'permitDoc':
                newDevice = {
                    ...this.props.deviceDesign,
                    permitDoc: value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleDateChange = (value, field) => {
        let newDevice;
        switch (field) {
            // 设备基本信息
            case 'enableTime':
                newDevice = {
                    ...this.props.currentDevice,
                    enableTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'idleTime':
                newDevice = {
                    ...this.props.currentDevice,
                    idleTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'downTime':
                newDevice = {
                    ...this.props.currentDevice,
                    downTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;
            case 'abandonTime':
                newDevice = {
                    ...this.props.currentDevice,
                    abandonTime: value
                };
                this.props.onhandleDeviceBaseChange(true);
                this.props.onhandleUpdateEditDevice(newDevice);
                break;

            // 建设信息
            case 'startTime':
                newDevice = {
                    ...this.props.deviceBuild,
                    startTime: value
                };
                this.props.onhandledeviceBuildChange(true);
                this.props.onhandleUpdateEditdeviceBuild(newDevice);
                this.handleCheckDate(value, 'startTime');
                break;
            case 'endTime':
                newDevice = {
                    ...this.props.deviceBuild,
                    endTime: value
                };
                this.props.onhandledeviceBuildChange(true);
                this.props.onhandleUpdateEditdeviceBuild(newDevice);
                this.handleCheckDate(value, 'endTime');
                break;
            // 设计制造信息
            case 'deliveryTime':
                newDevice = {
                    ...this.props.deviceDesign,
                    deliveryTime: value
                };
                this.props.onhandleDeviceDesignChange(true);
                this.props.onhandleUpdateEditDeviceDesign(newDevice);
                this.handleCheckDate(value, 'deliveryTime')
                break;
            default:
            // do nothing
        }
    };

    handleCheckDate = (value, field) => {

        let err = {};
        // 设备基本信息

        // 建设信息
        if (!field || field === 'startTime') {
            if (_.trim(value) === '') {
                err['startTime'] = '开工时间不能为空';
            } else {
                err['startTime'] = '';
            }
        }

        if (!field || field === 'endTime') {
            if (_.trim(value) === '') {
                err['endTime'] = '竣工时间不能为空';
            } else {
                err['endTime'] = '';
            }
        }

        // 设计制造信息
        if (!field || field === 'deliveryTime') {
            if (_.trim(value) === '') {
                err['deliveryTime'] = '出厂时间不能为空';
            } else {
                err['deliveryTime'] = '';
            }
        }

        let newError = {
            ...this.props.deviceBuildError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleCheck = (event, field) => {

        let err = {};
        // 基本信息

        if (!field || field === 'idleTime') {
            if (this.props.currentDevice.idleTime && this.props.currentDevice.downTime) {
                let idleTime = new Date(this.props.currentDevice.idleTime)
                let downTime = new Date(this.props.currentDevice.downTime)
                if (idleTime > downTime) {
                    err['idleTime'] = '';
                } else {
                    err['idleTime'] = '闲置时间要晚于停用时间';
                }
            } else {
                err['idleTime'] = '';
            }
        }

        if (!field || field === 'downTime') {
            if (this.props.currentDevice.downTime && this.props.currentDevice.enableTime) {
                let enableTime = new Date(this.props.currentDevice.enableTime)
                let downTime = new Date(this.props.currentDevice.downTime)
                if (downTime > enableTime) {
                    err['downTime'] = '';
                } else {
                    err['downTime'] = '停用时间要晚于投用时间';
                }
            } else {
                err['downTime'] = '';
            }
        }

        if (!field || field === 'abandonTime') {
            if (this.props.currentDevice.abandonTime && this.props.currentDevice.idleTime) {
                let abandonTime = new Date(this.props.currentDevice.abandonTime)
                let idleTime = new Date(this.props.currentDevice.idleTime)
                if (abandonTime > idleTime) {
                    err['abandonTime'] = ''
                } else {
                    err['abandonTime'] = '报废时间要晚于闲置时间';
                }
            } else {
                err['abandonTime'] = '';
            }
        }

        // 建设信息信息
        if (!field || field === 'supervisor') {
            if (_.trim(this.props.deviceBuild.supervisor) === '') {
                err['supervisor'] = '监理单位不能为空';
            } else {
                err['supervisor'] = '';
            }
        }

        if (!field || field === 'constructor') {
            if (_.trim(this.props.deviceBuild.constructor) === '') {
                err['constructor'] = '施工单位不能为空';
            } else {
                err['constructor'] = '';
            }
        }

        if (!field || field === 'startTime') {
            if (_.trim(this.props.deviceBuild.startTime) === '') {
                err['startTime'] = '开工时间不能为空';
            } else {
                err['startTime'] = '';
            }
        }

        if (!field || field === 'endTime') {
            if (_.trim(this.props.deviceBuild.endTime) === '') {
                err['endTime'] = '竣工时间不能为空';
            } else {
                err['endTime'] = '';
            }
        }

        // 设计制造信息
        if (!field || field === 'designer') {
            if (_.trim(this.props.deviceDesign.designer) === '') {
                err['designer'] = '设计单位不能为空';
            } else {
                err['designer'] = '';
            }
        }

        if (!field || field === 'serialNo') {
            if (_.trim(this.props.deviceDesign.serialNo) === '') {
                err['serialNo'] = '出厂编号不能为空';
            } else {
                err['serialNo'] = '';
            }
        }

        if (!field || field === 'deliveryTime') {
            if (_.trim(this.props.deviceDesign.deliveryTime) === '') {
                err['deliveryTime'] = '出厂时间不能为空';
            } else {
                err['deliveryTime'] = '';
            }
        }

        if (!field || field === 'manufacturer') {
            if (_.trim(this.props.deviceDesign.manufacturer) === '') {
                err['manufacturer'] = '制造商不能为空';
            } else {
                err['manufacturer'] = '';
            }
        }

        if (!field || field === 'designLife') {
            if (_.toInteger(_.trim(this.props.deviceDesign.designLife)) < 0) {
                err['designLife'] = '设计寿命不能为负数';
            } else {
                err['designLife'] = '';
            }
        }

        let newError = {
            ...this.props.deviceBuildError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleDownload = (docId) => {
        if (docId) {
            let tenantId = this.props.userProfile.roles[0].tenantId
            let envCode = 'dev';
            if (window.location.host.indexOf('3050') > -1) {
                envCode = 'dev';
            } else if (window.location.host.indexOf('3060') > -1) {
                envCode = 'uat';
            } else if (process.env.NODE_ENV === 'production') {
                envCode = 'prod';
            }
            let mybucket = _.replace(envCode + '.{tenant_id}.device.devicedoc', '{tenant_id}', tenantId);
            let doc = _.find(this.props.deviceDoc, ['docId', docId.value])
            this.props.onhandleDownload(doc.docLink, mybucket);
        }
    }

    render() {
        return (
            <DeviceBuild
                onhandleSave={this.handleSave.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                deviceBuildError={this.props.deviceBuildError}
                // 基础信息
                currentDevice={this.props.currentDevice}
                deviceBaseChange={this.props.deviceBaseChange}
                // 建造信息
                deviceBuild={this.props.deviceBuild}
                deviceBuildChange={this.props.deviceBuildChange}
                // 设计制造信息
                deviceDesign={this.props.deviceDesign}
                deviceDesignChange={this.props.deviceDesignChange}

                isView={this.props.isview}
                tabValue={this.props.tabValue}
                index={this.props.index}
                deviceDoc={this.props.deviceDoc}
                onhandleSelectChange={this.handleSelectChange.bind(this)}
                userProfile={this.props.userProfile}
                isDeviceEdit={this.props.isDeviceEdit}
                onhandleDownload={this.handleDownload.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isDeviceEdit: state.devices.deviceEdit,
        userProfile: state.auth.userProfile,
        deviceBuildError: state.devices.deviceBuildError,
        // 基本信息
        currentDevice: state.devices.currentDevice,
        deviceBaseChange: state.devices.deviceBaseChange,

        // 建造信息
        deviceBuildChange: state.devices.deviceBuildChange,
        deviceBuild: state.devices.deviceBuild,
        // 设计制造信息
        deviceDesignChange: state.devices.deviceDesignChange,
        deviceDesign: state.devices.deviceDesign,

        isview: state.devices.view,
        deviceDoc: state.devices.deviceDoc,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // 设备基本信息
        onhandleDeviceBaseChange: (isChanged) => {
            dispatch(setDeviceBaseChange(isChanged))
        },
        onhandleUpdateEditDevice: (entity) => {
            dispatch(setCurrentDevice(entity))
        },
        onhandleUpdateDevice: (entity) => {
            dispatch(modifyDevice(entity))
        },
        // 建设信息
        onhandleCreatedeviceBuild: (entity) => {
            dispatch(insertDeviceBuild(entity))
        },
        onhandleUpdatedeviceBuild: (entity) => {
            dispatch(modifyDeviceBuild(entity))
        },
        onhandleUpdateEditdeviceBuild: (entity) => {
            dispatch(setDeviceBuild(entity))
        },
        onhandledeviceBuildChange: (isChanged) => {
            dispatch(setDeviceBuildChange(isChanged))
        },
        // 设计制造信息
        onhandleCreateDeviceDesign: (entity) => {
            dispatch(insertDeviceDesign(entity))
        },
        onhandleUpdateDeviceDesign: (entity) => {
            dispatch(modifyDeviceDesign(entity))
        },
        onhandleUpdateEditDeviceDesign: (entity) => {
            dispatch(setDeviceDesign(entity))
        },
        onhandleDeviceDesignChange: (isChanged) => {
            dispatch(setDeviceDesignChange(isChanged))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceBuildError(err))
        },
        onhandleInitdeviceBuild: () => {
            // 基本信息   不需要初始化
            // 建设信息
            dispatch(initDeviceBuild());
            // 设计制造信息
            dispatch(initDeviceDesign());
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceBuildContainer)
