import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { localization, options, style } from "@/utils/mtable";

const IndexComponent = props => {
    const { classes,
        indexes,
        onhandleRowAdd,
        onhandleRowUpdate,
        // onhandleRowDelete,
        onhandleClick,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'subName',
                render: row => <Typography>{row.subName}</Typography>,
                cellStyle: (value, rowData) => {
                    if (rowData)
                        if (rowData.subLevel === 2) {
                            return { paddingLeft: '30px' }
                        } else if (rowData.subLevel === 3) {
                            return { paddingLeft: '40px' }
                        }
                    return {}
                },
            },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit}</Typography>
            },
            {
                title: '指标公式',
                field: 'valuationFormulaStr',
                render: row => <Typography>{row.valuationFormulaStr}</Typography>,
                editable: 'never',
            },
            {
                title: '指标说明',
                field: 'opentext',
                render: row => <Typography>{row.opentext}</Typography>,
            }
        ]
        return columns
    }

    const assemblyData = () => {
        return indexes
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        title={<Typography variant='h3'>阿米巴指标列表</Typography>}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        parentChildData={(row, rows) => rows.find(ele => ele.subId === row.subParentId)}
                        editable={{
                            onRowAdd: (newData) => onhandleRowAdd(newData),
                            onRowUpdate: (newData, oldData) => onhandleRowUpdate(newData, oldData),
                            // onRowDelete: oldData => onhandleRowDelete(oldData),
                        }}
                        actions={[
                            {
                                icon: () => { return <AttachMoneyIcon /> },
                                tooltip: '修改计价公式',
                                onClick: (event, rowData) => {
                                    onhandleClick(event, rowData)
                                }
                            },
                        ]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

IndexComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    indexes: PropTypes.any,
    onhandleRowAdd: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
    onhandleClick: PropTypes.func,
};

IndexComponent.defaultProps = {
};

export default withStyles(IndexComponent);