import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceTransfer from '@/components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceTransfer'
import {
    transferDevice,
    setTransferOpen,
    setCurrentDevice,
} from "@/redux/modules/devices";
import {
    appendFormDtls,
    setGoingForm,
    setGoingFormDtls,
    routeToFormDetail,
} from "@/redux/modules/deviceForm";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { DEVICE_ATTR_FIELDS } from '@/utils/device';
import { setDeviceFormApprovalInstance } from '@/redux/modules/processApproval';

class DeviceTransferContainer extends Component {
    static propTypes = {
        currentDevice: PropTypes.object,
        currentUserInfo: PropTypes.object,
        companies: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            selectedCompanyId: this.props.currentDevice.companyId,
        }
    }


    // 组件渲染后... ...
    componentDidMount() {

    }

    handleChange = (event) => {
        if (event.target.name === 'selectedCompanyId') {
            this.setState({
                selectedCompanyId: Number(event.target.value)
            })

        }
    };

    getCompanyName = (companyId) => {

        let company = _.find(this.props.companies, { 'companyId': companyId })
        if (company) {
            return company.companyAlias
        }
        return companyId


    }


    handleGenerateForm = async () => {

        const { currentDevice,
            onhandleSetGoingForm,
            onhandleSetGoingFormDtls,
            onhandleAppendFormDtl,
            onhandleSetDeviceFormApprovalInstance,
            onhandleGenerateForm,
        } = this.props

        const { selectedCompanyId } = this.state

        let form = {
            companyId: currentDevice.companyId,
            deviceId: currentDevice.deviceId,
            deviceNo: currentDevice.deviceNo,
            deviceName: currentDevice.deviceName,
            targetType: 'DEV',
            formType: 'TRANSFER_FORM',
            formName: currentDevice.deviceName + '调拨',
            status: 'new',
        }
        let dtl = {
            ...DEVICE_ATTR_FIELDS['companyId'],
            attrName: 'companyId',
            attrNewValue: selectedCompanyId,
            attrNewName: this.getCompanyName(selectedCompanyId),
            attrOldValue: currentDevice.companyId,
            attrOldName: this.getCompanyName(currentDevice.companyId)
        }
        await onhandleSetGoingForm(form)
        await onhandleSetGoingFormDtls([])
        await onhandleAppendFormDtl(dtl)
        await onhandleSetDeviceFormApprovalInstance([])
        this.handleClose()
        onhandleGenerateForm(0)
    }


    handleTransfer = (event) => {
        this.props.onhandleTransferDevice(
            {
                tenantId: this.props.currentUserInfo.tenantId,
                deviceId: this.props.currentDevice.deviceId,
                oldCompanyId: this.props.currentDevice.companyId,
                newCompanyId: this.state.selectedCompanyId,
            }
        ).then(res => {
            if (res && res.payload && res.payload.deviceId) {
                let newDevice = {
                    ...this.props.currentDevice,
                    companyId: this.state.selectedCompanyId,
                };
                this.props.onhandleUpdateEditDevice(newDevice);
                this.handleClose()
            }
        })
    }

    handleClose = (event) => {
        this.props.onhandleSetTransferOpen(false)
    }

    render() {
        return (
            <div>
                <DeviceTransfer
                    open={this.props.transferOpen}
                    currentDevice={this.props.currentDevice}
                    companies={this.props.companies}
                    selectedCompanyId={this.state.selectedCompanyId}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleTransfer={this.handleGenerateForm.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        transferOpen: state.devices.transferOpen,
        currentDevice: state.devices.currentDevice,
        companies: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSetTransferOpen: (isOpen) => {
            return dispatch(setTransferOpen(isOpen))
        },
        onhandleTransferDevice: (param) => {
            return dispatch(transferDevice(param))
        },
        onhandleUpdateEditDevice: (entity) => {
            return dispatch(setCurrentDevice(entity))
        },
        onhandleAppendFormDtl: (dtl) => {
            return dispatch(appendFormDtls(dtl))
        },
        onhandleSetGoingForm: (form) => {
            return dispatch(setGoingForm(form))
        },
        onhandleSetGoingFormDtls: (dtls) => {
            return dispatch(setGoingFormDtls(dtls))
        },
        onhandleSetDeviceFormApprovalInstance: (dtls) => {
            return dispatch(setDeviceFormApprovalInstance(dtls))
        },
        onhandleGenerateForm: () => {
            return dispatch(routeToFormDetail(0))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceTransferContainer)
