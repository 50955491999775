import React, { Component } from 'react';
import { connect } from 'react-redux';
import DevicePartList from '@/components/AccessoryManagement/AccessoryInfo/DevicePartList';
import PropTypes from "prop-types";
import {
    // getAccessoryList,
    setSearch,
    initDevicePartList,
    updateDevicePart,
    changeAccessoryStatus,
    initUpdate,
    initView,
    initCreate,
    backToDeviceList,
    accessoryReplace,
    accessoryRelevance,
    setCurrentDevicePart,
    setDevicePartEditOpen,
    partRelevanceAccessory,
    getDevicePartList,
} from '../../../redux/modules/accessory';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';
import * as _ from 'lodash';
import DevicePartEdit from './DevicePartEdit';
import DevicePartAccessoryList from '@/components/AccessoryManagement/AccessoryInfo/DevicePartAccessoryList';

class DevicePartListContainer extends Component {
    static propTypes = {
        search: PropTypes.object,
        userProfile: PropTypes.object,
        accessoryList: PropTypes.object,
        catList: PropTypes.array,
        // 当前设备
        currentDevice: PropTypes.object,
    };

    constructor(props) {
        super(props)
        this.state = {
            devicePart: {},
            devicePartAccessoryOpen: false,
        }
    }

    componentWillMount() {
        this.props.onhandleInitDevicePartList();
    }

    handleEdit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleView(event, id) {
        this.props.onhandleView(id);
    }

    handleDelete = (event, rowData) => {
        let deleteCallback = async () => {
            await this.props.onhandleUpdateDevicePart({
                'partId': rowData.partId,
                'status': 'inactive'
            });

            this.props.onhandleGetDevicePartList(this.props.currentDevice.deviceId)
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除部件[' + rowData.partNo + ']吗?', deleteCallback, null, false);
    };

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    handleCreate(event) {
        this.props.onhandleOpenDevicePartDetail({
            companyId: this.props.currentDevice.companyId,
            deviceId: this.props.currentDevice.deviceId,
            partNo: '自动生成',
        });
    }

    handleCreateByAccessory(event) {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: false,
            statusValue: 'new',
            company: false,
            deviceSearch: false,
            companyId: null,
            amount: true,
            libSelect: true,
            callback: () => {
                return new Promise(async (resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccessoryCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let param = _.clone(this.props.selectedAccessory[0])
                        param.deviceId = this.props.currentDevice.deviceId
                        await this.props.onhandlePartRelevanceAccessory(param.deviceId, 0, param.accId, param)
                        this.props.onhandleGetDevicePartList(param.deviceId)
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleOpenDetail = (event, row) => {
        this.props.onhandleOpenDevicePartDetail({ ...row });
    }

    handleCloseAccessoryList = () => {
        this.setState({
            devicePart: [],
            devicePartAccessoryOpen: false
        })
    }

    handleOpenAccessoryList = (event, row) => {
        this.setState({
            devicePart: row,
            devicePartAccessoryOpen: true
        })
    }
    handleReplace = (event, row) => {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: false,
            statusValue: 'new',
            company: false,
            deviceSearch: false,
            companyId: null,
            amount: true,
            libSelect: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccessoryCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let param = {
                            deviceId: this.props.accessoryDeviceId,
                            orginAccId: row.accId,
                            newAccId: this.props.selectedAccessory[0].accId
                        }
                        this.props.onhandleReplace(param);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    render() {
        return (
            <React.Fragment>
                <DevicePartList
                    tabValue={this.props.tabValue}
                    index={this.props.index}
                    userProfile={this.props.userProfile}
                    onEdit={this.handleEdit.bind(this)}
                    // getAccessoryList={this.props.onhandleGetAccessoryList}
                    onDelete={this.handleDelete.bind(this)}
                    deviceParts={this.props.deviceParts}
                    catList={this.props.catList}
                    onSizeChange={this.handleListSize.bind(this)}
                    onView={this.handleView.bind(this)}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleCreateByAccessory={this.handleCreateByAccessory.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onDetail={this.handleOpenDetail.bind(this)}
                    onhandleOpenAccessoryList={this.handleOpenAccessoryList.bind(this)}
                    // 当前设备
                    currentDevice={this.props.currentDevice}
                    isView={this.props.isview}
                    isDeviceEdit={this.props.isDeviceEdit}
                />
                {this.props.devicePartEditOpen && <DevicePartEdit />}
                {this.state.devicePartAccessoryOpen && <DevicePartAccessoryList
                    open={this.state.devicePartAccessoryOpen}
                    devicePart={this.state.devicePart}
                    catList={this.props.catList}
                    onhandleClose={this.handleCloseAccessoryList.bind(this)}
                />}
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.accessory.search,
        userProfile: state.auth.userProfile,
        deviceAccessoryList: state.accessory.deviceAccessoryList,
        deviceParts: state.accessory.deviceParts,
        selectedAccessory: state.accessorySelect.selectedAccessory,
        catList: state.accessory.catList,
        currentUserInfo: state.auth.currentUserInfo.roleId,
        accessoryDeviceId: state.devices.accessoryDeviceId,
        devicePartEditOpen: state.accessory.devicePartEditOpen,
        // 当前设备
        currentDevice: state.devices.currentDevice,
        isDeviceEdit: state.devices.deviceEdit,
        isview: state.devices.view,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitDevicePartList: () => {
            dispatch(initDevicePartList());
        },
        onhandleUpdateDevicePart: (partDto) => {
            dispatch(updateDevicePart(partDto));
        },

        onhandleOpenDevicePartDetail: (devicePart) => {
            dispatch(setCurrentDevicePart(devicePart));
            dispatch(setDevicePartEditOpen(true));
        },
        // onhandleGetAccessoryList: (offset, size, sort, cat, search, companyId, isCommon, status) => {
        //     dispatch(getAccessoryList(offset, size, sort, cat, search, companyId, isCommon, status))
        // },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleView: (id) => {
            dispatch(initView(id));
        },
        onhandleChangeStatus: (rowData, status) => {
            dispatch(changeAccessoryStatus(rowData, status));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleBackToList: () => {
            dispatch(backToDeviceList())
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('accessorySelect', param, 'medium'))
        },
        onhandleSelectAccessoryCheck: () => {
            dispatch(createMessage('error', `请选择一个部件`));
        },
        onhandleReplace: (param) => {
            dispatch(accessoryReplace(param));
        },
        onhandleRelevance: (param) => {
            dispatch(accessoryRelevance(param));
        },
        onhandlePartRelevanceAccessory: (deviceId, partId, accId, accDto) => {
            return dispatch(partRelevanceAccessory(deviceId, partId, accId, accDto));
        },
        onhandleGetDevicePartList: (deviceId) => {
            dispatch(getDevicePartList({ 'deviceId': deviceId }))
        },
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DevicePartListContainer);