import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField, Button, Typography, FormHelperText, Card, MenuItem, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';

const AccessoryEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        currentAccessory,
        accessoryError,
        accessoryChange,
        companyTree,
        companyList,
        onhandleSelectChange,
        onhandleDateChange,
        catTree,
        posTypeTree,
        // posKeyTypeTree,
        isView,
        userProfile,
        //onhandleDialog
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_management' })
        return permission && permission.action === 'W'
    }

    const isCommonData = () => {
        return [
            { label: "是", value: true, pcode: null, children: null },
            { label: "否", value: false, pcode: null, children: null }
        ]
    }

    const vulnerableData = () => {
        return [
            { label: "是", value: true, pcode: null, children: null },
            { label: "否", value: false, pcode: null, children: null }
        ]
    }

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    return (
        <div>
            <Card className={classes.card}>

                <Grid container className={classes.gridContainer}>
                    <Typography variant="h3">
                        {currentAccessory.accId ? "编辑部件" : "新建部件"}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={8}>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            单位
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                id="filled-select-company"
                                select
                                value={currentAccessory.companyId}
                                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                                variant="outlined"
                                size="small"
                                error={accessoryError.companyId !== ''}
                                onBlur={event => onhandleCheck(event, 'productivity')}
                                helperText={accessoryError.companyId || ''}
                                disabled={currentAccessory.accId || companyDisabled()}
                            >
                                {_.sortBy(companyList, 'companyId').map((option) => (
                                    <MenuItem key={option.companyId} value={option.companyId}>
                                        {option.companyAlias}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            部件编号
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            {/* {currentAccessory.accId && currentAccessory.accNo ? (
                                <Typography>
                                    {currentAccessory.accNo}
                                </Typography>
                            ) : ( */}
                            <TextField
                                error={accessoryError.accNo !== ''}
                                helperText={accessoryError.accNo || ''}
                                className={classes.textField}
                                value={currentAccessory.accNo || ''}
                                variant="outlined"
                                size="small"
                                disabled={(currentAccessory.accId && currentAccessory.accNo) || isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'accNo')}
                                onBlur={event => onhandleCheck(event, 'accNo')}
                            />
                            {/* )} */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            部件名称
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.accName !== ''}
                                helperText={accessoryError.accName || ''}
                                className={classes.textField}
                                value={currentAccessory.accName || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'accName')}
                                onBlur={event => onhandleCheck(event, 'accName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            型号
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.model !== ''}
                                helperText={accessoryError.model || ''}
                                className={classes.textField}
                                value={currentAccessory.model || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'model')}
                                onBlur={event => onhandleCheck(event, 'model')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            规格
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentAccessory.specs || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'specs')}
                                onBlur={event => onhandleCheck(event, 'specs')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            部件类别
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentAccessory.cat}
                                labelInValue
                                treeData={catTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || !checkPermission()}
                                onChange={(value) => onhandleSelectChange(value, 'cat')}
                            />
                            {accessoryError.cat !== '' ? <FormHelperText
                                error={true}
                            >
                                {accessoryError.cat}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            是否通用部件
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentAccessory.isCommon}
                                labelInValue
                                treeData={isCommonData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || !checkPermission()}
                                onChange={(value) => onhandleSelectChange(value, 'isCommon')}
                            />
                            {accessoryError.isCommon !== '' ? <FormHelperText
                                error={true}
                            >
                                {accessoryError.isCommon}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            是否易损件
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentAccessory.isVulnerable}
                                labelInValue
                                treeData={vulnerableData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || !checkPermission()}
                                onChange={(value) => onhandleSelectChange(value, 'isVulnerable')}
                            />
                            {accessoryError.isVulnerable !== '' ? <FormHelperText
                                error={true}
                            >
                                {accessoryError.isVulnerable}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            安装部位
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                showArrow
                                className={classes.textField}
                                choiceTransitionName="rc-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                                allowClear
                                treeLine
                                value={currentAccessory.installPos}
                                labelInValue
                                treeData={posTypeTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                // multiple={true}
                                disabled={isView || !checkPermission()}
                                onChange={(value) => onhandleSelectChange(value, 'installPos')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            安装部位关键类别
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                showArrow
                                className={classes.textField}
                                choiceTransitionName="rc-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                                allowClear
                                treeLine
                                value={currentAccessory.posType}
                                labelInValue
                                treeData={posTypeTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                // multiple={true}
                                disabled={isView || !checkPermission()}
                                onChange={(value) => onhandleSelectChange(value, 'posType')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            材质
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.material !== ''}
                                helperText={accessoryError.material || ''}
                                className={classes.textField}
                                value={currentAccessory.material || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'material')}
                                onBlur={event => onhandleCheck(event, 'material')}
                            />
                        </Grid>
                    </Grid>


                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            制造商
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.manufacturer !== ''}
                                helperText={accessoryError.manufacturer || ''}
                                className={classes.textField}
                                value={currentAccessory.manufacturer || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'manufacturer')}
                                onBlur={event => onhandleCheck(event, 'manufacturer')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            图纸编号
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentAccessory.drawing || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'drawing')}
                                onBlur={event => onhandleCheck(event, 'drawing')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            使用寿命(小时)
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.serviceLife !== ''}
                                helperText={accessoryError.serviceLife || ''}
                                className={classes.textField}
                                value={currentAccessory.serviceLife || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'serviceLife')}
                                onBlur={event => onhandleCheck(event, 'serviceLife')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            重量(KG)
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.weight !== ''}
                                helperText={accessoryError.weight || ''}
                                className={classes.textField}
                                value={currentAccessory.weight || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'weight')}
                                onBlur={event => onhandleCheck(event, 'weight')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            数量
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={accessoryError.amount !== ''}
                                helperText={accessoryError.amount || ''}
                                className={classes.textField}
                                value={currentAccessory.amount || ''}
                                variant="outlined"
                                size="small"
                                disabled={isView || !checkPermission()}
                                onChange={event => onhandleChange(event, 'amount')}
                                onBlur={event => onhandleCheck(event, 'amount')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            投用时间
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='downTimeselect'
                                    value={currentAccessory.enableTime}
                                    disabled={isView || !checkPermission()}
                                    onChange={value => onhandleDateChange(value, 'enableTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || !checkPermission()) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'enableTime')}
                                                    disabled={Boolean(!currentAccessory.enableTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    {/* <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                关联设备
                                </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    value={currentAccessory.deviceName || ''}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView || !checkPermission()}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    onClick={event => onhandleDialog(event)}
                                />
                            </Grid>
                        </Grid> */}
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button} hidden={!checkPermission() || isView}
                        disabled={!accessoryChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>
                </Grid>

            </Card>
            <Prompt message="数据未保存,确定要离开？" when={accessoryChange} />
        </div>
    );
};

AccessoryEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentAccessory: PropTypes.object.isRequired,
    accessoryError: PropTypes.object.isRequired,
    accessoryChange: PropTypes.bool.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    catTree: PropTypes.array.isRequired,
    posTypeTree: PropTypes.array.isRequired,
    // posKeyTypeTree: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    //onhandleDialog: PropTypes.func.isRequired,
};

export default withStyles(AccessoryEdit);
