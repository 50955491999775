import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization } from "../../../utils/mtable";
import Button from "@material-ui/core/Button";
import { getDateByTimestamp } from '../../../utils/datetime';
import * as _ from 'lodash';



const MajorHazard = props => {

    const {
        classes,
        onhandleDelete,
        userProfile,
        onhandleBack,
        majorHazardDoc,
        onOpenUploadModal,
        doctype,
        onDownLoadDoc,
        majorHazard,
        dangerLevelList,
        routeType
    } = props;

    const renderTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }
    const renderDocType = (row) => {
        let label = ''
        if (doctype && doctype.length > 0) {
            let value = _.find(doctype, function (o) { return o.code === row.docType })
            if (value) {
                label = value.name
            }
        }
        return label
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '文档名称',
                field: 'docName',
                align: 'left',
                render: row => row ? <Typography>{row.docName}</Typography> : '',
            },
            {
                title: '文档类型',
                field: 'docType',
                align: 'left',
                render: row => row ? <Typography>{renderDocType(row)}</Typography> : '',
            },
            {
                title: '文档描述',
                field: 'docDesc',
                align: 'left',
                render: row => row ? <Typography>{row.docDesc}</Typography> : '',
            },
            {
                title: '上传时间',
                field: 'createTime',
                align: 'left',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            }
        ]

        return columns
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'majorHazard_list' })
        return permission && permission.action === 'W'
    }

    const renderDangerLevel = (data) => {
        let label = '';
        if (dangerLevelList && dangerLevelList.length > 0) {
            let value = _.find(dangerLevelList, function (o) { return o.code === data.dangerLevel })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const components = {
        Toolbar: props => (
            <div>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'重大危险源评估报告'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item className={classes.action} xs={3}>
                        <Grid item xs={4}>
                            <Typography className={classes.label}>
                                名称
                                    </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={classes.label}>
                                {majorHazard.hazardName ? majorHazard.hazardName : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.action} xs={3}>
                        <Grid item xs={4}>
                            <Typography className={classes.label}>
                                危险级别
                                    </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={classes.label}>
                                {renderDangerLevel(majorHazard)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <MTableToolbar {...props} />
            </div>
        )
    }

    const renderActions = () => {
        let actions = [];
        switch (routeType) {
            case 'list':
                if (checkPermission()) {
                    actions = [rowData => ({
                        icon: 'archive',
                        tooltip: '下载',
                        onClick: (event, rowData) => {
                            onDownLoadDoc(rowData)
                        }
                    }),
                    {
                        icon: 'add',
                        tooltip: '上传文档',
                        isFreeAction: true,
                        onClick: (event) => {
                            onOpenUploadModal()
                        },
                    },
                    rowData => ({
                        icon: 'delete_outlined',
                        tooltip: '删除',
                        onClick: (event, rowData) => {
                            onhandleDelete(rowData)
                        },
                    })
                    ]
                } else {
                    actions = [rowData => ({
                        icon: 'archive',
                        tooltip: '下载',
                        onClick: (event, rowData) => {
                            onDownLoadDoc(rowData)
                        }
                    })]
                }
                break;
            case 'detection':
                actions = [rowData => ({
                    icon: 'archive',
                    tooltip: '下载',
                    onClick: (event, rowData) => {
                        onDownLoadDoc(rowData)
                    }
                })]
                break;
            default:
            // do nothing
        }
        return actions;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblycolumns()}
                    data={majorHazardDoc}
                    components={components}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    actions={renderActions()}
                >
                </MaterialTable>
            </Grid>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                </Button>
                </Grid>

            </Grid>
        </div >
    );
};
MajorHazard.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    majorHazardDoc: PropTypes.array.isRequired,
    onOpenUploadModal: PropTypes.func.isRequired,
    doctype: PropTypes.array.isRequired,
    onDownLoadDoc: PropTypes.func.isRequired,
};

export default (withStyles(MajorHazard));