import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    detailtable: {
        paddingLeft: theme.spacing(2),
    },
    new: {
        width: 60,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main
    },
    doing: {
        width: 60,
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
    final: {
        width: 60,
        color: theme.palette.white,
        // backgroundColor: theme.palette.error.main
    },
    gridSelect: {
        width: 150
      },
      gridItem: {
        width: 120
      },
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
        padding: theme.spacing(4),
        overflowX: 'auto'
      },
      gridContainer: {
        marginTop: 30
      },
});


export default withStyles(styles);