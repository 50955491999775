import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProcessInfo from './ProcessInfo';
import ProcessDiagram from './ProcessDiagram';
import { Prompt } from 'react-router-dom';

const ProcessEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleActivate,
        onhandleDeactivate,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        currentProcess,
        processError,
        onhandleSelectionChange,
        isloading,
        constants,
        processChange,
        diagramChange
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const handleTabeChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" className={classes.tabs}>
                <Tabs value={tabValue} onChange={handleTabeChange} aria-label="process tabs">
                    <Tab label="基本信息" />
                    <Tab label="流程图" />
                </Tabs>
            </AppBar>
            <ProcessInfo
                tabValue={tabValue}
                index={0}
                onhandleSave={onhandleSave}
                onhandleActivate={onhandleActivate}
                onhandleDeactivate={onhandleDeactivate}
                onhandleChange={onhandleChange}
                onhandleBack={onhandleBack}
                onhandleCheck={onhandleCheck}
                currentProcess={currentProcess}
                processError={processError}
                onhandleSelectionChange={onhandleSelectionChange}
                isloading={isloading}
                constants={constants}
                processChange={processChange}
                diagramChange={diagramChange}
            >
            </ProcessInfo>
            <ProcessDiagram
                tabValue={tabValue}
                index={1}
            >
            </ProcessDiagram>
            <Prompt message="数据未保存,确定要离开？"  when={processChange || diagramChange}/>
        </div>
    );
};

ProcessEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleActivate: PropTypes.func.isRequired,
    onhandleDeactivate: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    currentProcess: PropTypes.object.isRequired,
    processError: PropTypes.object.isRequired,
    isloading: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired,
    processChange: PropTypes.bool.isRequired,
    diagramChange: PropTypes.bool.isRequired
};

export default withStyles(ProcessEdit);
