import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IndexComponent from '@/components/AmoebaManagement/Index/IndexComponent';
import ConfirmChildCheck from '@/components/AmoebaManagement/Index/ConfirmChildCheck/ConfirmChildCheck';
import ValuationFormulaPop from '@/containers/Common/ValuationFormulaPop/ValuationFormulaPopContainer'
import {
    getAmoebaIndexes, addAmoebaIndex, updateAmoebaIndex, delAmoebaIndex
} from '@/redux/modules/amoeba/amoebaIndex';

/**
 * 阿米巴指标
 */
class IndexContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            openFlag: false,
            indexList: [],
            subList: [],
            addParentId: 0,
            amoebaIndex: {},
            amoebaIndexAttrList: [],
            anchorEl: null,
            attrList: [],
            valuationFormula: '',
            valuationFormulaStr: '',
            valuationVars: [],
            // whether: [
            //     {
            //         code: true,
            //         name: '是'
            //     },
            //     {
            //         code: false,
            //         name: '否'
            //     },
            // ]
        }
    }

    componentDidMount() {
        this.getAmoebaIndexList();

    }

    setAmoebaIndexAttrList = () => {
        let subList = [];
        const indexes = this.props.indexes;

        indexes.forEach(element => {
            let sub = {};
            sub.id = '_' + element.subId;
            sub.name = element.subName;
            subList.push(sub);
        });

        indexes.forEach(element => {
            let valuationFormulaStr = (element.valuationFormula ? element.valuationFormula : '') + '';
            let valuationVars = JSON.parse(element.valuationVars);

            if (valuationVars) {
                valuationVars.forEach(element => {
                    let result = subList.find(ele => ele.id === element);
                    if (result) {
                        valuationFormulaStr = valuationFormulaStr.replace(element, '{' + result.name + '}');
                    } else {
                        valuationFormulaStr = valuationFormulaStr.replace(element, '未定义');
                    }
                })
            }
            element.valuationFormulaStr = valuationFormulaStr;
        })

        this.setState({
            subList: subList,
            indexList: indexes,
        });

    }

    getAmoebaIndexList = () => {
        this.props.getAmoebaIndexes().then(() => {
            this.setAmoebaIndexAttrList();
        });
    }

    handleRowAdd = (rowData) => {
        // 数据编集
        rowData.version = 1;
        rowData.isPlan = true;
        rowData.valuationFormula = '';
        rowData.valuationVars = '[]';
        this.props.addAmoebaIndex(rowData).then((res) => {
            this.getAmoebaIndexList();
        });
    }


    handleRowUpdate = (newData, oldData) => {
        const rowIndex = oldData.tableData.id;
        // version更新 +1
        newData.version = newData.version + 1;
        return this.props.updateAmoebaIndex(newData).then((res) => {
            let indexList = this.state.indexList;
            indexList[rowIndex] = res.payload;
            this.setState({
                indexList: indexList
            })
        });
    }

    handleRowDelete = (rowData) => {
        return this.props.delAmoebaIndex(rowData.subId).then(() => {
            this.getAmoebaIndexList();
        });
    }

    handleFirmCheck = (rowData) => {
        rowData.subLevel = 1;
        this.setState({
            openFlag: true,
            addData: rowData
        })
        return Promise.resolve(true);
    }

    handleChange = (event) => {
        this.setState({
            addParentId: event.target.value * 1,
        });
    }

    handleConfirm(check) {
        let addData = this.state.addData;
        if (check === 'yes') {
            const parentId = this.state.addParentId;
            const indexList = this.state.indexList;
            addData.subParentId = parentId;
            let parentDto = indexList.find((ele) => ele.subId === parentId)
            addData.subLevel = parentDto.subLevel * 1 + 1;
        } else {
            addData.subLevel = 1;
            addData.subParentId = 0;
        }

        addData.isPlan = addData.isPlan === 'true' ? true : false;
        this.handleRowAdd(addData);
        this.setState({
            openFlag: false
        })
    }

    handleClick = (event, rowData) => {
        const attrList = this.state.subList;
        const valuationVars = JSON.parse(rowData.valuationVars);

        this.setState({
            amoebaIndex: rowData,
            attrList: attrList,
            anchorEl: event.currentTarget,
            valuationFormulaStr: rowData.valuationFormulaStr || '',
            valuationFormula: rowData.valuationFormula || '',
            valuationVars: valuationVars === null ? [] : valuationVars
        })
    }

    handlePopClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleUpdFormula = (valuationFormula, valuationFormulaStr, valuationVars) => {
        let amoebaIndex = this.state.amoebaIndex;
        amoebaIndex.valuationFormula = valuationFormula;
        amoebaIndex.valuationFormulaStr = valuationFormulaStr;
        amoebaIndex.valuationVars = valuationVars;
        if (valuationFormula.indexOf('+') > 0) {
            amoebaIndex.gatherType = 'SUMMAY';
        } else if (valuationFormula.indexOf('/') > 0) {
            amoebaIndex.gatherType = 'AVERAGE';
        } else {
            amoebaIndex.gatherType = '';
        }

        this.props.updateAmoebaIndex(amoebaIndex).then(() => {
            this.getAmoebaIndexList();
            this.setState({
                amoebaIndex: {},
                anchorEl: null
            })
        });
    }

    render() {
        return (
            <div>
                <IndexComponent
                    indexes={this.state.indexList}
                    onhandleRowAdd={this.handleFirmCheck.bind(this)}
                    onhandleRowUpdate={this.handleRowUpdate.bind(this)}
                    onhandleRowDelete={this.handleRowDelete.bind(this)}
                    onhandleClick={this.handleClick.bind(this)}
                />
                <ConfirmChildCheck
                    open={this.state.openFlag}
                    subList={this.state.subList}
                    addParentId={this.state.addParentId}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleConfirm={this.handleConfirm.bind(this)}
                />
                <ValuationFormulaPop
                    anchorEl={this.state.anchorEl}
                    attrList={this.state.attrList}
                    valuationFormula={this.state.valuationFormula}
                    valuationVars={this.state.valuationVars}
                    serviceName={this.state.amoebaIndex.subName}
                    valuationFormulaStr={this.state.valuationFormulaStr}
                    handlePopClose={this.handlePopClose.bind(this)}
                    handleUpdFormula={this.handleUpdFormula.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        indexes: state.amoebaIndex.indexes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes());
        },
        addAmoebaIndex: (subDto) => {
            return dispatch(addAmoebaIndex(subDto));
        },
        updateAmoebaIndex: (subDto) => {
            return dispatch(updateAmoebaIndex(subDto));
        },
        delAmoebaIndex: (subId) => {
            return dispatch(delAmoebaIndex(subId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexContainer);
