import { withStyles } from '@material-ui/styles';
import { stripedTableStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    dialogContent: {
        minWidth: 500,
    },
    contentGrid: {
        flexDirection: 'column'
    },
    itemGrid: {
        // padding: theme.spacing(2),
    },
    flexgrow: {
        flexGrow: 1
    },
    stripedTable: stripedTableStyle,
});


export default withStyles(styles);