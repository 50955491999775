import React, { Component } from 'react';
import { connect } from 'react-redux';
import InstallationDetail from "../../components/InstallationManagement/InstallationDetail";
import {
  initInstallationInfo,
  initInstallationInfoForCreate,
  routeToInstallationMap
} from "../../redux/modules/installation";
import PropTypes from "prop-types";

class InstallationDetailContainer extends Component {
  static propTypes = {
    globalLoading: PropTypes.bool,
  };

  componentWillMount() {
    let id = this.props.match.params.id
    if (id !== 'create') {
      this.props.onhandleInitEditInstallation(id);
    } else {
      this.props.onhandleInitEditInstallationCreate(id);
    }
  }

  detailedMapEidt() {
    let id = this.props.match.params.id
    this.props.onRouteToMapEdit(id)
  }


  render() {
    return (
      <div>
        <InstallationDetail
          parmId={this.props.match.params.id}
          globalLoading={this.props.globalLoading}
          routeToMapEdit={this.detailedMapEidt.bind(this)}
          editInstallation={this.props.editInstallation}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    globalLoading: state.loading.globalLoading,
    editInstallation: state.installation.editInstallation,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitEditInstallation: (id) => {
      dispatch(initInstallationInfo(id));
    },
    onhandleInitEditInstallationCreate: (id) => {
      dispatch(initInstallationInfoForCreate(id));
    },
    onRouteToMapEdit: (id) => {
      dispatch(routeToInstallationMap(id));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationDetailContainer)