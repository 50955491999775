import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationGoalPlanDetailEdit from '../../../components/DeviceManagement/OperationAnnuaPlan/OperationGoalPlanDetailEdit';
import {
    initCreateGoalPlanDetail,
    addNewPlanDetail,
    updateCreatePlanData,
    deleteCreatePlanData,
    routeToOperationPlanDetail,
    addOperationPlanDetailAnnu
} from "../../../redux/modules/operationPlan";
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';
import { getYear } from '../../../utils/datetime';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
class OperationGoalPlanDetailEditContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        companyList: PropTypes.array,
        operationPlanDetails: PropTypes.array,
        procat: PropTypes.array,
        userProfile: PropTypes.object,
        newPlanChange: PropTypes.bool,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
        roleTree: PropTypes.array,
        roleList: PropTypes.array,
        operationPlan: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            target: 'DEV',
            planId: ''
        }
    }

    componentDidMount() {
        let target = this.props.match.params.target;
        let planId = this.props.match.params.planId;
        if (target) {
            this.setState({ target: target, planId: planId })
        }
        this.props.onhandleInitCreateGoalPlanDetail(planId, target);
    }

    onhandleSeletTarget() {
        if (this.state.target === 'DEV') {
            this.handleSeletDevice();
        } else if (this.state.target === 'ACC') {
            this.handleSeletAcc();
        } else if (this.state.target === 'ACCTYPE') {
            this.handleSeletAccType();
        }
    }

    handleSeletAcc() {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: true,
            company: true,
            companyId: this.props.operationPlan.companyId,
            deviceSearch: true,
            connectedFlg: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let planDetail = {};
                        planDetail.target = this.state.target;
                        planDetail.acc = _.cloneDeep(this.props.selectedAccessory[0])
                        planDetail.companyId = this.props.operationPlan.companyId
                        planDetail._id = uuidv4();
                        planDetail.targetDate = `${getYear() + 1}-01-01`;
                        planDetail.budget = 0;
                        planDetail.roles = [];
                        planDetail.planId = this.props.operationPlan.planId
                        planDetail.opentext = '';
                        planDetail.tableData = {
                            // id: this.props.operationPlanDetails.length,
                            editing: 'update'
                        }
                        this.props.onhandleAddNewPlanDetail(planDetail);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectAcc(param);
    }

    handleSeletDevice() {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.props.operationPlan.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let planDetail = {};
                        planDetail.target = this.state.target;
                        planDetail.device = _.cloneDeep(this.props.selectedDevices[0])
                        planDetail.companyId = this.props.operationPlan.companyId
                        planDetail._id = uuidv4();
                        planDetail.targetDate = `${getYear() + 1}-01-01`;
                        planDetail.budget = 0;
                        planDetail.roles = [];
                        planDetail.planId = this.props.operationPlan.planId
                        planDetail.opentext = '';
                        planDetail.tableData = {
                            // id: this.props.operationPlanDetails.length,
                            editing: 'update'
                        }
                        this.props.onhandleAddNewPlanDetail(planDetail);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);
    }

    handleSeletAccType() {
        let param = {
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAcctype.length !== 1) {
                        this.props.onhandleSelectAccTypeCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let planDetail = {};
                        planDetail.target = this.state.target;
                        planDetail.dictionary = _.cloneDeep(this.props.selectedAcctype[0])
                        planDetail.companyId = this.props.operationPlan.companyId
                        planDetail._id = uuidv4();
                        planDetail.targetDate = `${getYear() + 1}-01-01`;
                        planDetail.budget = 0;
                        planDetail.roles = [];
                        planDetail.planId = this.props.operationPlan.planId
                        planDetail.opentext = '';
                        planDetail.tableData = {
                            // id: this.props.operationPlanDetails.length,
                            editing: 'update'
                        }
                        this.props.onhandleAddNewPlanDetail(planDetail);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectAccType(param);
    }

    handleSave() {
        this.props.onhandleSave(this.state.target, () => this.handleBack());
    }

    handleBack() {
        this.props.onhandleBack(this.state.planId);
    }

    render() {
        return (
            <div>
                <OperationGoalPlanDetailEdit
                    companyList={this.props.companyList}
                    operationPlanDetails={this.props.operationPlanDetails}
                    procat={this.props.procat}
                    catList={this.props.catList}
                    accCatList={this.props.accCatList}
                    userProfile={this.props.userProfile}
                    newPlanChange={this.props.newPlanChange}
                    roleTree={this.props.roleTree}
                    roleList={this.props.roleList}
                    target={this.state.target}
                    onhandleSeletTarget={this.onhandleSeletTarget.bind(this)}
                    onhandleUpdate={this.props.onhandleUpdateCreatePlanData.bind(this)}
                    onhandleDelete={this.props.onhandleDeleteCreatePlanData.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        operationPlan: state.operationPlan.currentPlan,
        companyList: state.constant.companies,
        operationPlanDetails: state.operationPlan.newPlanDetailList,
        procat: state.devices.procat,
        userProfile: state.auth.userProfile,
        selectedDevices: state.deviceSelect.selectedDevices,
        selectedAccessory: state.accessorySelect.selectedAccessory,
        selectedAcctype: state.acctypeSelect.selectedAcctype,
        newPlanChange: state.operationPlan.newPlanChange,
        catList: state.trees.deviceCategoryList,
        accCatList: state.accessory.catList,
        roleTree: state.role.roleTree,
        roleList: state.role.roleList.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitCreateGoalPlanDetail: (planId, target) => {
            dispatch(initCreateGoalPlanDetail(planId, target));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
        onhandleSelectAcc: (parm) => {
            dispatch(createmodal('accessorySelect', parm, 'medium'));
        },
        onhandleSelectAccCheck: () => {
            dispatch(createMessage('error', `请选择一个部件`));
        },
        onhandleSelectAccType: (parm) => {
            dispatch(createmodal('acctypeSelect', parm, 'medium'));
        },
        onhandleSelectAccTypeCheck: () => {
            dispatch(createMessage('error', `请选择一个部件分类`));
        },
        onhandleAddNewPlanDetail: (value) => {
            dispatch(addNewPlanDetail(value));
        },
        onhandleUpdateCreatePlanData: (value) => {
            dispatch(updateCreatePlanData(value));
        },
        onhandleDeleteCreatePlanData: (value) => {
            dispatch(deleteCreatePlanData(value));
        },
        onhandleBack: (id) => {
            dispatch(routeToOperationPlanDetail(id));
        },
        onhandleSave: (target, callback) => {
            dispatch(addOperationPlanDetailAnnu(target, callback));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationGoalPlanDetailEditContainer);