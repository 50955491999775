import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    toolbarContainer: {
        paddingTop: theme.spacing(2)
    },
    flexgrow: {
        flexGrow: 1
    },
    sumText: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
});


export default withStyles(styles);