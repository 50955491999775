import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GoalFixedPlan from "@/components/AmoebaManagement/FixedPlan/GoalFixedPlan/GoalFixedPlan";
// import DptFixedPlan from "@/components/AmoebaManagement/FixedPlan/DptFixedPlan/DptFixedPlan";
import {
    getFixedPlans,
    updateFixedPlan,
    addFixedPlan,
    exportFixedPlanTemplate,
    importFixedPlan
} from '@/redux/modules/amoeba/amoebaFixedPlan';
import { formatYear, getMoment, getNowTimestamp } from '@/utils/datetime';
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { upload } from '@/redux/modules/minio';
import { getBusinessUnitsByRole, getRole, getCompanyName } from "@/utils/userUtils";
import { filterAmoebaCompany } from "@/utils/constant";

import _ from 'lodash';
class GoalFixedPlanContainer extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isMonthOpen: false,
            selectedDate: getMoment()(),
            month: null,
            rows: [],
            sortSubs: [],
            dpts: [],
            companies: [],
            pidFlg: null,
            dptId: 0,
            selectedCompanyId: 0,
        }
    }

    componentDidMount() {
        let dpts = _.filter(this.props.departments,
            dpt => dpt.companyId === this.props.currentUserInfo.companyId && (dpt.amoebaType === 'COST' || dpt.amoebaType === 'PROFIT'))

        let companies = this.props.companies
        if (this.getPid()) {
            companies = _.filter(this.props.companies, { companyId: this.props.currentUserInfo.companyId })
        }

        const sortSubs = _.filter(this.props.subs, { subType: 'HR' })

        this.setState({
            dpts: dpts,
            dptId: dpts[0].dptId,
            selectedCompanyId: this.props.currentUserInfo.companyId,
            companies: companies,
            pidFlg: this.getPid(),
            sortSubs: sortSubs,
        })
        this.getFixedPlans(formatYear(this.state.selectedDate), this.props.currentUserInfo.companyId, dpts[0].dptId);
    }

    handleUpdate(newData, oldData) {
        console.log(newData)
        const { addAmoebaFixedPlan } = this.props
        const { dptId, selectedCompanyId, selectedDate } = this.state
        let datas = []
        for (let index = 1; index <= 12; index++) {
            if (newData['amount_' + index] !== oldData['amount_' + index]) {
                let data = {
                    dtlId: newData['dptId_' + index],
                    fixedPlanId: newData.fixedPlanId,
                    companyId: selectedCompanyId,
                    dptId: dptId,
                    subId: newData.subId,
                    amount: newData.unit === '万元' ? newData['amount_' + index] * 10000 : newData['amount_' + index],
                    year: formatYear(selectedDate),
                    month: index,
                }
                datas.push(data)
            }
        }
        console.log(datas)
        return addAmoebaFixedPlan(datas).then((data) => {
            this.getFixedPlans(formatYear(selectedDate), selectedCompanyId, dptId, true);
        });
    }

    handleEditFixedPlan(event, ele) {
        //请求数据
        this.setState({
            isOpen: true,
            showCompanyId: ele.id,
            showCompanyName: ele.name
        })
    }

    async getFixedPlans(date, companyId, dptId, isRefresh) {
        const {
            getAmoebaFixedPlans,
        } = this.props

        let planDtls = this.props.planDtls

        if (companyId !== this.state.selectedCompanyId || isRefresh) {
            const res = await getAmoebaFixedPlans(companyId, null, date)
            planDtls = res.payload
        }

        if (!planDtls || planDtls.length === 0) {

        } else {
            let rows = []
            _.forEach(this.state.sortSubs, sub => {
                let row = {
                    subId: sub.subId,
                    subName: sub.subName,
                    unit: sub.unit,
                    subLevel: sub.subLevel,
                    subParentId: sub.subParentId,
                }
                var month = 1;
                for (month; month <= 12; month++) {

                    // eslint-disable-next-line no-loop-func
                    const dtl = _.filter(planDtls, dtl => dtl.subId === sub.subId && dtl.month === month && (dptId ? dtl.dptId === dptId : true))
                    if (dtl.length > 0) {
                        console.log(dtl)
                        row['amount_' + month] = sub.unit === '万元' ? dtl[0].amount / 10000 : dtl[0].amount
                        row['dptId_' + month] = dtl[0].dtlId
                    } else {
                        row['amount_' + month] = 0
                        row['dptId_' + month] = 0
                    }
                }
                row['fixedPlanId'] = planDtls[0].fixedPlanId
                rows.push(row)
            })
            console.log(rows)
            this.setState({ rows: rows })
        }
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }


    handleClose() {
        this.setState({ isOpen: false, isMonthOpen: false });
    }

    getUserRole = () => {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_hr_plan')?.action === 'W';
        return roleFlag;
    }

    handleDateChange = (date) => {
        this.setState({ selectedDate: date, year: formatYear(date) }, function () {
            this.getFixedPlans(formatYear(date), this.state.selectedCompanyId, this.state.dptId, true);
        });
    }

    handleDptChange = (event) => {
        this.setState({ dptId: event.target.value })
        this.getFixedPlans(formatYear(this.state.selectedDate), this.state.selectedCompanyId, event.target.value);
    }

    refresh() {
        this.getFixedPlans(formatYear(this.state.selectedDate), this.state.selectedCompanyId, this.state.dptId, true)
    }

    handleCompanyChange = (event) => {

        let selectedCompanyId = Number(event.target.value)
        let dpts = _.filter(this.props.departments, dpt => dpt.companyId === selectedCompanyId && (dpt.amoebaType === 'COST' || dpt.amoebaType === 'PROFIT'))

        if (dpts.length > 0) {
            this.setState({
                dptId: dpts[0].dptId,
                dpts: dpts,
                selectedCompanyId: selectedCompanyId,
            })
            this.getFixedPlans(formatYear(this.state.selectedDate), selectedCompanyId, dpts[0].dptId);
        }
    }

    handleExport = () => {
        const { onhandleDownloadFile, currentUserInfo } = this.props;

        const year = parseInt(formatYear(this.state.selectedDate));
        const companyId = currentUserInfo.companyId;

        onhandleDownloadFile(year, companyId);
    }

    handleImport = () => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'uat';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.fixed.plan.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                companyId: this.props.currentUserInfo.companyId,
                year: parseInt(formatYear(this.state.selectedDate)),
                docName: offset + files[0].name
            };
            this.props.onhandleCreateDoc(doc, () => {
                this.refresh();
            });
            this.props.onhandleModalClose();
        });
    }


    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_hr_plan')?.action === 'W';
        return (
            <div>
                <GoalFixedPlan
                    planDetails={this.state.rows}
                    selectedDate={this.state.selectedDate}
                    dptId={this.state.dptId}
                    dpts={this.state.dpts}
                    selectedCompanyId={this.state.selectedCompanyId}
                    companyies={this.state.companies}
                    roleFlag={roleFlag}
                    pidFlg={this.state.pidFlg}
                    constant={this.props.constant}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleDptChange={this.handleDptChange.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleImport={this.handleImport.bind(this)}
                />
                {/* <DptFixedPlan
                    open={this.state.isMonthOpen}
                    planDetails={this.state.popRows}
                    dpts={this.state.dpts}
                    companies={this.state.companies}
                    month={this.state.month}
                    pidFlg={this.state.pidFlg}
                    onhandleClose={this.handleClose.bind(this)}
                ></DptFixedPlan> */}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companies: filterAmoebaCompany(state.company.companyList.list),
        constant: state.constant,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        companyName: getCompanyName(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        currentProcessStatus: state.process.currentProcessStatus.SafetyGoal || {},
        companyId: state.auth.currentUserInfo.companyId,
        planDtls: state.amoebaFixedPlan.plans,
        departmentList: state.department.departments.list,
        subs: state.amoebaIndex.subs,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        departments: state.constant.departments,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, true, null, 'HR'));
        },
        getAmoebaFixedPlans: (companyIds, dptIds, year) => {
            return dispatch(getFixedPlans(companyIds, dptIds, year))
        },
        updateFixedPlan: (planId, planDto) => {
            return dispatch(updateFixedPlan(planId, planDto))
        },
        addFixedPlan: (planDtos) => {
            return dispatch(addFixedPlan(planDtos))
        },
        addAmoebaFixedPlan: (plan) => {
            return dispatch(addFixedPlan(plan));
        },
        onhandleDownloadFile: (year, companyId) => {
            return dispatch(exportFixedPlanTemplate(year, companyId))
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(importFixedPlan(doc, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GoalFixedPlanContainer))