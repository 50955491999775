import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getCodeName, TASK_STATUS } from '@/utils/manufactureUtils';
import { formatDate } from '@/utils/datetime'
import * as _ from 'lodash';

const MTaskReportComponent = props => {
    const {
        classes,
        departments,
        companies,
        taskReports,
        tasks,
        manufactureDicts,
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
    }

    const detailOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    const getDptName = (dptId) => {
        const department = departments.find(item => {
            return item.dptId === dptId
        });
        return department === undefined ? '' : department.dptName
    }

    const getCompanyName = (companyId) => {
        const company = companies.find(item => {
            return item.companyId === companyId
        });
        return company === undefined ? '' : company.companyAlias
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '分厂',
                field: 'companyId',
                render: row => row ? <Typography>{getCompanyName(row.companyId)}</Typography> : '',
                editable: 'never',
            },
            {
                title: '班组',
                field: 'dptName',
                render: row => row ? <Typography>{getDptName(row.dptId)}</Typography> : '',
            },
            // {
            //     title: '任务总数',
            //     field: 'taskCount',
            //     render: row => <Typography>{row.taskCount ? row.taskCount : 0}</Typography>,
            // },
            {
                title: '任务总数',
                field: 'manualTaskCount',
                render: row => <Typography>{row.manualTaskCount ? row.manualTaskCount : 0}</Typography>,
            },
            // {
            //     title: '已完成',
            //     field: 'doneTaskCount',
            //     render: row => <Typography>{row.doneTaskCount ? row.doneTaskCount : 0}</Typography>,
            // },
            {
                title: '未完成',
                field: 'todoTaskCount',
                render: row => <Typography>{row.todoTaskCount ? row.todoTaskCount : 0}</Typography>,
            },
            {
                title: '异常',
                field: 'issueTaskCount',
                render: row => <Typography>{row.issueTaskCount ? row.issueTaskCount : 0}</Typography>,
            },
        ]

        return columns
    }

    const assemblyData = () => {
        return taskReports
    }


    const assemblyDetailData = (row) => {
        console.log(tasks)
        return _.filter(tasks, { 'dptId': row.dptId, 'status': 'new' })
    }


    const assemblyDetailColumns = () => {

        const columns = [
            // {
            //     title: '分厂',
            //     field: 'companyName',
            //     render: row => <Typography>{row.companyName}</Typography>,
            // }, 
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{row.serviceName}</Typography>,
            },
            {
                title: '活动任务',
                field: 'activityName',
                render: row => <Typography>{row.activityName}</Typography>,
            },
            {
                title: '开始时间',
                field: 'startTime',
                render: row => <Typography>{formatDate(row.startTime)}</Typography>,
            },
            {
                title: '状态',
                field: 'status',
                render: row => <Typography>{getCodeName(manufactureDicts, TASK_STATUS, 'new')}</Typography>,
            },
        ]
        return columns
    }


    const taskDetailPannel = (row) => {
        if (row && row.todoTaskCount > 0) {
            return <MaterialTable
                title={<Typography variant="h4">未完成任务明细</Typography>}
                options={detailOptions}
                localization={tableLocalization}
                columns={assemblyDetailColumns()}
                data={assemblyDetailData(row)}
                style={tableStyle}
            />
        }
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid container className={classes.toolbarContainer}>
            </Grid>
            <Grid className={classes.table}>
                <MaterialTable
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={tableStyle}
                    title={<Typography variant="h3">生产任务填写统计</Typography>}
                    detailPanel={rowData => taskDetailPannel(rowData)}
                >
                </MaterialTable>
            </Grid>

        </div >
    );
};

MTaskReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    manufactureOrders: PropTypes.array,
    companyList: PropTypes.any,
    onhandleDateChange: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
};

export default withStyles(MTaskReportComponent);
