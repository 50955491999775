import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getCompleteRates,
    getProducts,
    getSubReports,
} from '@/redux/modules/amoeba/amoebaAnalysis';
import { getMoment, formatMonth, formatDay, formatYear } from '@/utils/datetime';
import { getBudgets } from '@/redux/modules/amoeba/amoebaPlan';
import { getAmoebaReports } from '@/redux/modules/amoeba/amoebaReport';
import AmoebaAnalysisReport from "@/components/AmoebaManagement/Report/AmoebaAnalysisReport";
import { getDepartmentsByRole } from '@/utils/userUtils';
import { createMessage } from '@/redux/modules/message';
import { filterAmoebaCompany, getManageDpt } from '@/utils/constant';
import { defaultColors } from '@/utils/echartUtils';

import _ from 'lodash';

class AmoebaAnalysisReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            dateView: 'MONTH',
            selectedCompanyId: 0, //选择单位
            selectedDptId: 0,//选择部门
            selectedDate: getMoment()(),
            companySelects: [],
            dptSelects: [],
            sortSubs: [],
        }
    }


    componentDidMount() {
        const { currentUserInfo, companies, departments, userDepartment } = this.props
        let newState = { ...this.state }
        newState['selectedDate'] = getMoment()()
        const pid = this.getPid();
        const roleW = this.getRoleW()
        if (roleW && !pid) { //机关的用户有所有查看权限
            let companySelects = _.cloneDeep(companies)
            const rootCom = { companyId: 0, companyName: "工程公司", companyAlias: "工程公司" }
            companySelects.unshift(rootCom)
            newState['companySelects'] = companySelects
            newState['selectedCompanyId'] = 0
        } else {
            newState['companySelects'] = _.filter(companies, { companyId: currentUserInfo.companyId })
            newState['selectedCompanyId'] = currentUserInfo.companyId
            if (roleW) {
                let activeDpts = this.getActiveDpts(newState.selectedDate, newState.selectedCompanyId)
                activeDpts.unshift({ dptName: '所有', dptId: 0 })
                newState['dptSelects'] = activeDpts
                newState['selectedDptId'] = 0;
            } else {
                newState['dptSelects'] = _.filter(departments, { dptId: userDepartment.dptId })
                newState['selectedDptId'] = userDepartment.dptId
            }
        }
        this.setState({
            ...newState
        }, () => {
            this.refreshReport()
        })
    }

    getRoleW() {
        const { roleRoutes } = this.props
        const role = roleRoutes.find(ele => ele.route === 'amoeba_report_analysis')
        if (role && role.action === 'W') {
            return true
        }
        return false
    }

    getActiveDpts(date, companyId) { //获取公司在那天的有效班组
        const { departments } = this.props;
        let dpts = [];
        const startDay = getMoment()(date).startOf('month').format("YYYY/MM/DD");
        const endDay = getMoment()(date).endOf('month').format("YYYY/MM/DD");
        dpts = _.filter(departments, d => {
            return d.companyId === companyId
                && d.amoebaType !== 'OTHER'
        })
        //添加部门inactive的判断
        dpts = _.filter(dpts, d => {
            return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= endDay)) ||
                (!d.endDate || (formatDay(d.startDate) <= endDay && formatDay(d.endDate) >= startDay))
        })
        return dpts;
    }

    getReportViewType() {
        const { selectedCompanyId, selectedDptId } = this.state;
        if (selectedCompanyId) {
            if (selectedDptId) {
                return 'DEPARTMENT'
            } else {
                return 'SUBCOMPANY'
            }
        } else {
            return 'ALLCOMPANY'
        }
    }

    getSortAmoebaIndexes(length) {
        const { subs } = this.props;
        if (length === 0) {
            this.setState({
                sortSubs: [],
            })
        } else {
            let sortSubs = [];
            const parentSubs = _.filter(subs, { 'subParentId': 0 });
            _.forEach(parentSubs, value => {
                sortSubs.push(value)
                const subSubs = _.filter(subs, { 'subParentId': value.subId });
                subSubs.forEach(sub => {
                    sortSubs.push(sub)
                    const subSubSubs = _.filter(subs, { 'subParentId': sub.subId });
                    if (subSubSubs.length !== 0) sortSubs = sortSubs.concat(subSubSubs)
                })
            })
            this.setState({
                sortSubs: sortSubs,
            })
        }
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = _.find(this.props.companies, { 'companyId': companyId })
        this.refreshReport({
            selectedCompanyId: companyId,
            pid: company ? company.pid : 0,
        })
    }

    getGetBudgetsParams = () => {
        let query = {}
        const viewType = this.getReportViewType()
        const { selectedCompanyId, selectedDptId, selectedDate, dateView } = this.state
        query['companyId'] = viewType === 'ALLCOMPANY' ? 0 : selectedCompanyId
        query['dptId'] = viewType === 'DEPARTMENT' ? selectedDptId : null
        query['year'] = selectedDate.year()
        if (dateView === 'YEAR') {
            query['planType'] = 'ANNU'
        } else if (dateView === 'MONTH') {
            query['month'] = selectedDate.month() + 1
            query['planType'] = 'MONTH'
        } else if (dateView === 'DAY') {
            query['month'] = selectedDate.month() + 1
            query['day'] = selectedDate.date()
            query['planType'] = 'DAY'
        }
        return query
    }


    getAmoebaReportsParams = () => {
        let query = {}
        const viewType = this.getReportViewType()
        const { selectedCompanyId, selectedDate, dateView } = this.state
        query['companyIds'] = viewType === 'ALLCOMPANY' ? null : selectedCompanyId
        let dpts = this.getActiveDpts(selectedDate, selectedCompanyId);
        let dptIds = _.map(dpts, 'dptId').join(',')
        query['dptIds'] = viewType === 'ALLCOMPANY' ? null : dptIds
        if (dateView === 'YEAR') {
            query['day'] = formatYear(selectedDate)
        } else if (dateView === 'MONTH') {
            query['day'] = formatMonth(selectedDate)
        } else if (dateView === 'DAY') {
            query['day'] = formatDay(selectedDate)
        }
        return query
    }

    refreshReport = () => {
        const { onhandleGetBudgets,
            getAmoebaReports,
            onhandleGetCompleteRates,
            onhandleGetProducts,
            onhandleGetSubReports } = this.props

        const { selectedCompanyId, selectedDptId, selectedDate, dateView } = this.state

        const viewType = this.getReportViewType()


        if (viewType === 'SUBCOMPANY' || viewType === 'DEPARTMENT') {
            onhandleGetBudgets(this.getGetBudgetsParams())
            getAmoebaReports(this.getAmoebaReportsParams()).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        } else {
            onhandleGetBudgets(this.getGetBudgetsParams())
            getAmoebaReports(this.getAmoebaReportsParams()).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        }

        onhandleGetCompleteRates(dateView, selectedDate.year(), selectedDate.month() + 1, selectedDate.date(), viewType, selectedCompanyId, selectedDptId)
        onhandleGetProducts(dateView, selectedDate.year(), selectedDate.month() + 1, selectedDate.date(), viewType, selectedCompanyId, selectedDptId)
        onhandleGetSubReports(dateView, selectedDate.year(), selectedDate.month() + 1, selectedDate.date(), viewType, selectedCompanyId, selectedDptId)

    }

    handleSelect = (event, field) => {
        let newState = { ...this.state }
        switch (field) {
            case 'selectedCompanyId':
                newState['selectedCompanyId'] = Number(event.target.value)
                let activeDpts = this.getActiveDpts(newState.selectedDate, newState.selectedCompanyId)
                activeDpts.unshift({ dptName: '所有', dptId: 0 })
                newState['dptSelects'] = activeDpts
                newState['selectedDptId'] = 0;
                break;
            case 'selectedDptId':
                newState['selectedDptId'] = Number(event.target.value)
                break;
            case 'view':
                newState['dateView'] = event.target.value
                break;
            default:
            // do nothing
        }
        this.setState({ ...newState }, () => this.refreshReport())
    }

    handleDateChange(value) {
        this.setState({ selectedDate: getMoment()(value) }, () => this.refreshReport())
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    // 全年完成率
    handlePlanFinishRateOption = (subId) => {

        // 图配置逻辑
        const { completeRates } = this.props
        const { dateView } = this.state
        const completeRate = _.find(completeRates, { 'subId': subId })


        // 构造series, legend
        const series = [];

        let pieData = [(completeRate.allAmount - completeRate.completeAmount).toFixed(2), (completeRate.completeAmount).toFixed(2)]
        if (completeRate.completeRate <= 0) {
            pieData = [1, 0]
        } else if (completeRate.completeRate >= 1) {
            pieData = [0, 1]
        }

        // legendData.push(name)

        series.push({
            label: {
                show: true,
                position: 'center'
            },
            type: 'pie',
            radius: ['65%', '90%'],
            data: pieData
        })
        let chartTitle = '年度完成率'
        if (dateView === 'MONTH') {
            chartTitle = '月度完成率'
        } else if (dateView === 'MONTH') {
            chartTitle = '日完成率'
        }
        return {
            // title: { text: chartTitle, left: 'center' },
            label: {
                formatter: ((completeRate.completeRate * 100).toFixed(2) + '%' || '-') + '\n' + chartTitle,
                fontSize: 16,
                color: "#000"
            },
            series: series,
            grid: {
                containLabel: true,
                y: 5
            },
            color: defaultColors
        }
    }

    // 生产状况
    handleManufactureOption = () => {

        // 图配置逻辑
        const { products } = this.props;

        const sourceData = _.chain(products).filter({ unit: '吨' })
            .reject({ allOutput: 0, completeOutput: 0 })
            .sortBy(['serviceName']).map((d) => {
                return {
                    ...d,
                    allOutput: Number(d.allOutput).toFixed(2),
                    completeOutput: Number(d.completeOutput).toFixed(2)
                }
            }).value()

        const dataset = {
            dimensions: ['serviceName', 'allOutput', 'completeOutput'],
            source: sourceData,
        }
        return {
            title: { text: '图表', left: 20 },
            dataset: dataset,
            series: [{
                type: 'bar',
                name: '计划量',
                label: {
                    position: 'top',
                    show: true,
                }
            }, {
                type: 'bar',
                name: '产量(吨)',
                label: {
                    position: 'top',
                    show: true,
                }
            }],
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '服务',
            },
            yAxis: { name: '产量（吨）', },
            color: defaultColors
        }
    }

    // 收入分析
    handleIncomeOption = () => {

        const { products } = this.props;

        const sourceData = _.chain(products)
            .reject({ allAmount: 0, completeAmount: 0 })
            .sortBy(['completeAmount']).reverse().map((d) => {
                return {
                    ...d,
                    allAmount: Number(d.allAmount).toFixed(2),
                    completeAmount: Number(d.completeAmount).toFixed(2)
                }
            }).value()

        // let reports = [
        //     {
        //         serviceName: '碳酸钠调和',
        //         planAmount: 14.7,
        //         amount: 17.8,
        //         unit: '万元'
        //     },
        //     {
        //         serviceName: '调和碱',
        //         planAmount: 37.5,
        //         amount: 38.5,
        //         unit: '万元'
        //     },
        //     {
        //         serviceName: '二氧化碳',
        //         planAmount: 51.2,
        //         amount: 54.2,
        //         unit: '万元'
        //     },
        //     {
        //         serviceName: '废碱液',
        //         planAmount: 155.8,
        //         amount: 150.2,
        //         unit: '万元'
        //     },
        //     {
        //         serviceName: '炼白土',
        //         planAmount: 38.9,
        //         amount: 34.0,
        //         unit: '万元'
        //     },
        // ]

        const dataset = [{
            dimensions: ['serviceName', 'allAmount', 'completeAmount'],
            source: sourceData,
        }]


        return {
            title: { text: '', left: 20 },
            dataset: dataset,
            series: [{
                type: 'bar',
                name: '预算',
                label: {
                    position: 'top',
                    show: true,
                }
            }, {
                type: 'bar',
                name: '实际',
                label: {
                    position: 'top',
                    show: true,
                }
            }],
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '业务',
            },
            yAxis: { name: '收入（万元）', },
            color: defaultColors
        }
    }

    // 成本分析(x时间轴 折线图)
    handleCostOption = () => {

        const { budgets } = this.props;
        const reports = this.assemblyData(this.state.sortSubs)

        _.filter(reports, { dptId: null })

        let dataSource = []

        _.map(reports, r => {
            dataSource.push({
                subName: r.subName,
                subId: r.subId,
                subParentId: r.subParentId,
                sum: Number(r.sum0)
            })
        })

        _.map(_.filter(budgets, { dptId: null }), (b => {
            let ds = _.find(dataSource, { subId: b.subId })
            if (ds) {
                ds['amount'] = b.amount
            } else {
            }
        }))

        dataSource = _.chain(dataSource).reject({ sum: 0, amount: 0 })
            .sortBy(['sum']).reverse().map(d => {
                return {
                    subName: d.subName,
                    subId: d.subId,
                    subParentId: d.subParentId,
                    sum: (d.sum / 10000).toFixed(2),
                    amount: (d.amount / 10000).toFixed(2),
                }
            }).value()



        return {
            title: { text: '', left: 20 },
            dataset: [{
                dimensions: ['subName', 'amount', 'sum'],
                source: _.filter(dataSource, { subParentId: 3 })
            }, {
                dimensions: ['subName', 'amount', 'sum'],
                source: _.filter(dataSource, { subParentId: 6 })
            }],
            series: [{
                type: 'bar',
                name: '预算',
                label: {
                    position: 'top',
                    show: true,
                },
            }, {
                type: 'bar',
                name: '实际',
                label: {
                    position: 'top',
                    show: true,
                },
            },
            {
                type: 'bar',
                name: '预算',
                xAxisIndex: 1,
                yAxisIndex: 1,
                datasetIndex: 1,
                label: {
                    position: 'top',
                    show: true,
                }
            }, {
                type: 'bar',
                name: '实际',
                xAxisIndex: 1,
                yAxisIndex: 1,
                datasetIndex: 1,
                label: {
                    position: 'top',
                    show: true,
                }
            }],
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: [{
                containLabel: true,
                left: '5%',
                top: '5%',
                height: '40%'
            }, {
                containLabel: true,
                left: '5%',
                bottom: '5%',
                height: '40%'
            }],
            xAxis: [{
                type: 'category',
                name: '指标',
                gridIndex: 0
            }, {
                type: 'category',
                name: '指标',
                gridIndex: 1
            }],
            yAxis: [{ name: '变动费（万元）', gridIndex: 0 },
            { name: '固定费（万元）', gridIndex: 1 }],
            color: defaultColors
        }
    }

    getDptName(dptId, departments) {
        const dpt = _.find(departments, { dptId: dptId })
        if (dpt) {
            return dpt.dptName
        }
        return ''
    }
    getCompanyAlias(companyId, companies) {
        const company = _.find(companies, { companyId: companyId })
        if (company) {
            return company.companyAlias
        }
        return ''
    }


    // 利润分析(x时间轴 柱形图)
    handleProfitOption = () => {
        // 图配置逻辑
        const { subReports, departments, companies } = this.props;
        const viewType = this.getReportViewType()

        const sourceData = _.chain(subReports).filter({ subId: 7 })
            .sortBy(['amount']).reverse().map((d) => {
                return {
                    ...d,
                    amount: Number(d.amount / 10000).toFixed(2),
                    name: viewType === 'ALLCOMPANY' ? this.getCompanyAlias(d.companyId, companies) : this.getDptName(d.dptId, departments)
                }
            }).value()

        return {
            title: { text: '', left: 20 },
            dataset: {
                dimensions: ['name', 'amount'],
                source: sourceData
            },
            series: [{
                type: 'bar',
                name: '利润',
                label: {
                    position: 'top',
                    show: true,
                }
            }],
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '班组',
            },
            yAxis: { name: '利润（万元）', },
            color: _.drop(defaultColors)
        }

    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
                break;
            default:
            // do nothing
        }
    };
    sub6Cal = (id, isCompany, sub, sub6, sub37, sub38) => {
        if (isNaN(sub6['sum' + id])) { sub6['sum' + id] = 0 }
        if (isNaN(sub37['sum' + id])) { sub37['sum' + id] = 0 }
        if (isNaN(sub38['sum' + id])) { sub38['sum' + id] = 0 }
        if (isCompany) {
            sub['sum' + id] = Number(sub6['sum' + id]) - Number(sub38['sum' + id])
        } else {
            sub['sum' + id] = Number(sub6['sum' + id]) - Number(sub38['sum' + id]) - Number(sub37['sum' + id])
        }
    }

    sub7Cal = (id, isCompany, sub, sub7, sub37, sub38) => {
        if (isNaN(sub38['sum' + id])) {
            sub38['sum' + id] = 0
        }
        if (isCompany) {
            sub['sum' + id] = Number(sub7['sum' + id]) + Number(sub38['sum' + id])
        } else {
            sub['sum' + id] = Number(sub7['sum' + id]) + Number(sub37['sum' + id]) + Number(sub38['sum' + id])
        }
    }

    assemblyData = (sortSubs) => {
        const { reports } = this.props;
        const { selectedDate } = this.state;
        if (reports.length === 0 || !selectedDate) {
            return [];
        }

        const viewType = this.getReportViewType()

        if (viewType === 'SUBCOMPANY') {
            _.forEach(sortSubs, value => {
                let subs = _.filter(this.props.reports, { 'subId': value.subId });
                let sum = 0;
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        value['sum' + report.dptId] = report.sum.toFixed(2);
                        sum = sum + report.sum
                    }
                })
                if (sum !== null) {
                    value['sum0'] = sum.toFixed(2)
                }
            })
        } else if (viewType === 'DEPARTMENT') {
            _.forEach(sortSubs, value => {
                let subs = _.filter(this.props.reports, { 'subId': value.subId, 'dptId': this.props.userDepartment.dptId });
                let sum = null;
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        value['sum' + report.dptId] = report.sum.toFixed(2);
                        sum = sum + report.sum
                    }
                })
                if (sum !== null) {
                    value['sum0'] = sum.toFixed(2)
                }
            })
        } else {
            _.forEach(sortSubs, value => {
                let subs = _.filter(this.props.reports, { 'subId': value.subId });
                let sum = 0;
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        if (value.subCode === 'FIXEDFEE' || value.subCode === 'PROFIT') {
                            const workspacesortSub = _.find(sortSubs, { subCode: 'WORKSPACESHARING' })
                            if (workspacesortSub) {
                                const workspacesortReport = _.filter(this.props.reports, { 'subId': workspacesortSub.subId, companyId: report.companyId });
                                if (workspacesortReport.length > 0) {
                                    if (value.subCode === 'FIXEDFEE') {
                                        value['sum' + report.companyId] = (report.sum - workspacesortReport[0].sum).toFixed(2)
                                    } else {
                                        value['sum' + report.companyId] = (report.sum + workspacesortReport[0].sum).toFixed(2)
                                    }
                                }
                            }
                        } else {
                            value['sum' + report.companyId] = report.sum.toFixed(2);
                        }
                        sum = sum + report.sum
                    }
                })
                if (sum !== null) {
                    value['sum0'] = sum.toFixed(2)
                }
            })
        }
        // let sub2 = _.find(sortSubs, sub => sub.subId === 2);//收入
        // let sub3 = _.find(sortSubs, sub => sub.subId === 3);//变动费
        let sub6 = _.find(sortSubs, sub => sub.subId === 6);//固定费
        let sub7 = _.find(sortSubs, sub => sub.subId === 7); //利润
        // let sub32 = _.find(sortSubs, sub => sub.subId === 32);//工作时间
        let sub37 = _.find(sortSubs, sub => sub.subId === 37); //公司分摊
        let sub38 = _.find(sortSubs, sub => sub.subId === 38); //分厂分摊
        _.forEach(sortSubs, sub => {
            if (sub.subId === 6) {
                if (viewType === 'SUBCOMPANY') {
                    this.sub6Cal(0, true, sub, sub6, sub37, sub38)
                } else if (viewType === 'DEPARTMENT') {
                } else {
                    this.sub6Cal(0, false, sub, sub6, sub37, sub38)
                }
            }
            if (sub.subId === 7) {
                if (viewType === 'SUBCOMPANY') {
                    this.sub7Cal(0, true, sub, sub7, sub37, sub38)
                } else if (viewType === 'DEPARTMENT') {
                } else {
                    this.sub7Cal(0, false, sub, sub7, sub37, sub38)
                }
            }
            if (sub.subId === 5) {
                // if (viewType === 'COMPANY') {
                //     _.forEach(dpts, value => {
                //         sub5Cal(value.dptId, sub, sub2, sub3)
                //     })
                // } else if (viewType === 'DEPARTMENT') {
                //     sub5Cal(userDepartment.dptId, sub, sub2, sub3)
                // } else {
                //     _.forEach(companies, value => {
                //         sub5Cal(value.companyId, sub, sub2, sub3)
                //     })
                // }
            } else if (sub.subId === 8) {
                // if (viewType === 'COMPANY') {
                //     _.forEach(dpts, value => {
                //         sub8Cal(value.dptId, sub, sub2, sub3, sub6, sub32)
                //     })
                // } else if (viewType === 'DEPARTMENT') {
                //     sub8Cal(userDepartment.dptId, sub, sub2, sub3, sub6, sub32)
                // } else {
                //     _.forEach(companies, value => {
                //         sub8Cal(value.companyId, sub, sub2, sub3, sub6, sub32)
                //     })
                // }
            } else if (sub.subId === 9) {
                // if (viewType === 'COMPANY') {
                //     _.forEach(dpts, value => {
                //         sub9Cal(value.dptId, sub, sub2, sub3, sub6)
                //     })
                // } else if (viewType === 'DEPARTMENT') {
                //     sub9Cal(userDepartment.dptId, sub, sub2, sub3, sub6)
                // } else {
                //     _.forEach(companies, value => {
                //         sub9Cal(value.companyId, sub, sub2, sub3, sub6)
                //     })
                // }
            }
        })
        return sortSubs
        // return _.filter(sortSubs, { 'subParentId': 0 })
    }

    render() {
        return (
            <AmoebaAnalysisReport
                reports={this.props.reports}
                budgets={this.props.budgets}
                viewType={this.getReportViewType()}
                tabValue={this.state.tabValue}
                selectedDate={this.state.selectedDate}
                sortSubs={this.assemblyData(this.state.sortSubs)}
                selectedCompanyId={this.state.selectedCompanyId}
                selectedDptId={this.state.selectedDptId}
                companySelects={this.state.companySelects}
                dptSelects={this.state.dptSelects}
                dateView={this.state.dateView}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleManufactureOption={this.handleManufactureOption}
                onhandleCostOption={this.handleCostOption}
                onhandlePlanFinishRateOption={this.handlePlanFinishRateOption}
                onhandleProfitOption={this.handleProfitOption.bind(this)}
                onhandleIncomeOption={this.handleIncomeOption.bind(this)}
                onhandleChangeTab={this.handleChangeTab.bind(this)}
            />
        )
    }
}

const getUserDpt = (roleId, userProfile, departments) => {
    const userDpts = getDepartmentsByRole(roleId, userProfile)
    if (userDpts.length > 0 && departments && departments.length > 0) {
        return _.find(departments, { dptId: userDpts[0].dptId })
    } else {
        return {}
    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.amoebaReport.reports,
        budgets: state.amoebaPlan.budgets,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companies: filterAmoebaCompany(state.constant.companies),
        departments: state.constant.departments,
        userDepartment: getUserDpt(state.auth.currentUserInfo.roleId, state.auth.userProfile, state.department.departments.list),
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        departmentList: getManageDpt(state.constant.departments),
        subs: state.amoebaIndex.subs,
        completeRates: state.amoebaAnalysis.completeRates,
        products: state.amoebaAnalysis.products,
        subReports: state.amoebaAnalysis.subReports,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        },
        getAmoebaReports: (query) => {
            const year = query.day.split("/")[0];
            const month = query.day.split("/")[1];
            const date = query.day.split("/")[2];
            return dispatch(getAmoebaReports(null, year, month, date, null, query.companyIds, query.dptIds, null));
        },
        onhandleGetBudgets: (query) => {
            return dispatch(getBudgets(query.companyId, query.dptId, query.year, query.month, query.day, query.planType))
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleGetCompleteRates: (timeDim, year, month, day, orgDim, companyId, dptId) => {
            dispatch(getCompleteRates(timeDim, year, month, day, orgDim, companyId, dptId));
        },
        onhandleGetProducts: (timeDim, year, month, day, orgDim, companyId, dptId) => {
            dispatch(getProducts(timeDim, year, month, day, orgDim, companyId, dptId));
        },
        onhandleGetSubReports: (timeDim, year, month, day, orgDim, companyId, dptId) => {
            dispatch(getSubReports(timeDim, year, month, day, orgDim, companyId, dptId));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AmoebaAnalysisReportContainer)

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}