import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  button: {
    margin: theme.spacing(1)

  },
  iconDisabled: {
    color: colors.grey[500]
  },
  root: {
  },
  grid: {
  },
});

export default withStyles(styles);
