import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Card,
  Typography,
  Button,
  Grid,
  Tabs,
  Tab,
  CardHeader,
  CardContent,
  // CardActions,
  Collapse,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField
} from "@material-ui/core";
import * as _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { getMoment, getDateByTimestamp } from '../../utils/datetime'
import ReactEcharts from 'echarts-for-react';

function OnsiteInspectionStatistics(props) {
  const {
    classes,
    currentData,
    currentDescription,
    // 当前设备基本信息
    currentDevice,
    catList,
    useStatusTree,

    intervalData,
    intervalDescription,
    intervalDate,
    onhandleChange,
    onhandleCleanmodal,
    onhandleGetIntervalData,
    getGaugeOption,
    getLineOption
    // unit
  } = props;

  const [tabValue, setTabValue] = useState(0);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, field) => {
    onhandleChange(event, field)
    setExpanded(false)
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleTabeChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getIntervalDate = () => {
    if (intervalDate.view === 'day') {
      return getMoment()(intervalDate.day).format("MM/DD")
    } else if (intervalDate.view === 'week') {
      let start = getMoment()(intervalDate.weekStart).format("MM/DD")
      let end = getMoment()(intervalDate.weekEnd).format("MM/DD")
      return start + '~' + end
    } else if (intervalDate.view === 'month') {
      return getMoment()(intervalDate.month).format("YYYY/MM")
    } else if (intervalDate.view === 'custom') {
      return ''
    }
  };

  const goToFrontDate = () => {
    if (intervalDate.view === 'day') {
      let day = getMoment()(intervalDate.day).add(-1, 'day')
      onhandleChange(day, 'day')
    } else if (intervalDate.view === 'week') {
      let start = getMoment()(intervalDate.weekStart).add(-7, 'day')
      let end = getMoment()(intervalDate.weekEnd).add(-7, 'day')
      let date = {
        start: start,
        end: end
      }
      onhandleChange(date, 'week')
    } else if (intervalDate.view === 'month') {
      let month = getMoment()(intervalDate.month).add(-1, 'months')
      onhandleChange(month, 'month')
    }
    onhandleGetIntervalData()
    setExpanded(false)
  }

  const goToNextDate = () => {
    if (intervalDate.view === 'day') {
      let day = getMoment()(intervalDate.day).add(1, 'day')
      onhandleChange(day, 'day')
    } else if (intervalDate.view === 'week') {
      let start = getMoment()(intervalDate.weekStart).add(7, 'day')
      let end = getMoment()(intervalDate.weekEnd).add(7, 'day')
      let date = {
        start: start,
        end: end
      }
      onhandleChange(date, 'week')
    } else if (intervalDate.view === 'month') {
      let month = getMoment()(intervalDate.month).add(1, 'months')
      onhandleChange(month, 'month')
    }
    onhandleGetIntervalData()
    setExpanded(false)
  }

  // disabled={isView || !checkPermission()}

  const nextDisabled = () => {
    if (intervalDate.view === 'day') {

    } else if (intervalDate.view === 'week') {
      let end = _.cloneDeep(getMoment()(intervalDate.weekEnd))
      let now = getMoment()()
      if (end >= now) {
        return true
      }
    } else if (intervalDate.view === 'month') {
      let month = _.cloneDeep(getMoment()(intervalDate.month))
      month.add(1, 'month')

      let now = getMoment()()
      if (month >= now) {
        return true
      }
    }

    return false
  }

  const customHidden = () => {
    if (intervalDate.view === 'custom') {
      return true
    } else {
      return false
    }
  }

  const renderTime = (day) => {
    return day ? getMoment()(day).format("YYYY-MM-DD") : ''
  }

  const renderCat = (device) => {
    let label = '';
    if (catList && catList.length > 0) {
      let value = _.find(catList, function (o) { return o.code === device.cat })
      if (value) {
        label = value.name
      }
    }
    return label;
  }

  const renderUseStatus = (device) => {
    let label = ''
    if (useStatusTree && useStatusTree.length > 0) {
      let value = _.find(useStatusTree, function (o) { return o.value === device.useStatus })
      if (value) {
        label = value.label
      }
    }
    return label
  }

  return (
    <div>
      {/* <Card className={classes.card}>
        <Typography variant="h3" gutterBottom>
          {"设备巡检统计"}
        </Typography>
      </Card> */}
      <Typography variant="h3" gutterBottom>
        {"设备巡检记录"}
      </Typography>

      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              设备编号
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.deviceNo}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              设备名称
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.deviceName}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              所属装置
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.installTitle}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              型号
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.model}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              设备类型
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={renderCat(currentDevice)}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              位号
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.tagNo}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              使用状态
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={renderUseStatus(currentDevice)}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={4}>
            <Typography className={classes.label}>
              更新时间
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              value={currentDevice.listTime}
              variant="standard"
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Tabs className={classes.tabs}
        value={tabValue}
        onChange={handleTabeChange}
        aria-label="process tabs"

        centered
      >
        <Tab label="最新情况" />
        <Tab label="历史曲线" />
      </Tabs>
      <Grid container spacing={1} className={classes.gridContainer} hidden={tabValue !== 0}>
        {/** 加载图表的div */}
        {currentData.map((cell, index) => {
          return <Grid key={index} item xs={4}>
            <Card>
              <ReactEcharts option={getGaugeOption(cell)} opts={{ renderer: 'svg' }} style={{ height: 300, width: '100%' }} className='react_for_echarts' />
            </Card>
          </Grid>

        })}
        {/** 加载图表的数据 */}

        {currentDescription.length === 0 ? '' : <Grid item xs={12}>
          <Card style={{ width: '100%', height: 150 }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>定性参数</Typography>
              <Grid container spacing={1} >
                <Grid item xs={4}>
                  <Typography className={classes.label}>{'参数名'}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.label}>{'标准值'}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.label}>{'巡检值'}</Typography>
                </Grid>
              </Grid>

              {currentDescription.map((item, index) => {
                return <Grid key={index} container spacing={1} >
                  <Grid item xs={4}>
                    <Typography className={classes.TextField}>{item.paramName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.TextField}>{item.stdVal}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {item.val === item.stdVal ? <Typography className={classes.TextField}>{item.val}</Typography> : <Typography className={classes.warn}>{item.val}</Typography>}
                  </Grid>
                </Grid>
              })}
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
      <Grid container className={classes.toolbar} hidden={tabValue !== 1}>
        <Grid hidden={customHidden()} item className={classes.toolbarButton}>
          <Button onClick={event => goToFrontDate(event)} size="small" >
            <ArrowBackIos size="small" />
          </Button>
        </Grid>
        <Grid hidden={customHidden()} item className={classes.toolbarLabel}>
          <Typography className={classes.label}>{getIntervalDate()}</Typography>
        </Grid>
        <Grid hidden={customHidden()} item className={classes.toolbarButton} >
          <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
        </Grid>
        <Grid hidden={!customHidden()} container item xs={6} wrap="nowrap">
          <TextField
            type="date"
            className={classes.textField}
            size={'small'}
            variant={'outlined'}
            onChange={event => handleChange(event, 'startDay')}
            value={renderTime(intervalDate.startDay)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography className={classes.timeInterval} variant='h5'>{'~'}</Typography>
          <TextField
            type="date"
            className={classes.textField}
            size={'small'}
            variant={'outlined'}
            onChange={event => handleChange(event, 'endDay')}
            value={renderTime(intervalDate.endDay)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item className={classes.flexgrow}></Grid>

        <Grid item xs={4} container justify="flex-end">
          <FormControl component="fieldset">
            <RadioGroup row aria-label="视图" name="view"
              defaultValue="week"
              value={intervalDate.view}
              onChange={event => handleChange(event, 'view')} style={{ width: '100%' }}
            >
              <FormControlLabel value="day" control={<Radio />} label="日视图" />
              <FormControlLabel value="week" control={<Radio />} label="周视图" />
              {/* <FormControlLabel value="month" control={<Radio />} label="月视图" /> */}
              <FormControlLabel value="custom" control={<Radio />} label="自定义" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.intervalGrid}>
        {/** 加载图表 */}
        {intervalData.map((cell, index) => {
          return <Grid key={index} item xs={12}>
            <Card>
              <CardHeader
                className={classes.item}
                // title={cell.param}
                component={
                  () => <Grid container justify={'space-between'}>
                    <Typography variant={'h4'}>{cell.param}</Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>}
              />
              {/* <CardActions disableSpacing>
              </CardActions> */}
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {/* <div id={"interval" + index} style={{ width: '100%', height: 300 }}></div> */}
                <ReactEcharts option={getLineOption(cell)} style={{ height: 250, width: '100%' }} className='react_for_echarts' />
              </Collapse>
            </Card>
          </Grid>

        })}

        {intervalDescription.length === 0 ? '' : <Grid item xs={12}>
          <Card style={{ width: '100%', minHeight: 150 }}>
            <CardContent>
              <Typography variant="h3">定性参数</Typography>
              <Grid container spacing={1} >
                <Grid item xs={3}>
                  <Typography >{'参数名'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography >{'日期'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography >{'标准值'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography >{'巡检值'}</Typography>
                </Grid>
              </Grid>
              {intervalDescription.map((item, index) => {
                return <Grid key={index} container spacing={1} >
                  <Grid item xs={3}>
                    <Typography >{item.paramName}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography >{getDateByTimestamp(item.updateTime, true)}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography >{item.stdVal}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {item.val === item.stdVal ? <Typography >{item.val}</Typography> : <Typography className={classes.warn} >{item.val}</Typography>}
                  </Grid>
                </Grid>
              })}
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        {/* <Button variant="contained" color="primary"
            onClick={event => onhandleConfirm()}
          >
            确定
          </Button> */}
        <Button variant="contained" color="primary"
          onClick={event => onhandleCleanmodal()}
        >
          取消
        </Button>
      </Grid>
    </div >
  );
}

OnsiteInspectionStatistics.propTypes = {
  classes: PropTypes.object.isRequired,
  currentData: PropTypes.array.isRequired,
  currentDescription: PropTypes.array.isRequired,
  // 当前设备基本信息
  currentDevice: PropTypes.object.isRequired,
  catList: PropTypes.array.isRequired,
  useStatusTree: PropTypes.array.isRequired,

  intervalData: PropTypes.array.isRequired,
  intervalDescription: PropTypes.array.isRequired,
  intervalDate: PropTypes.object.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleCleanmodal: PropTypes.func.isRequired,
  //unit: PropTypes.array.isRequired,
  onhandleGetIntervalData: PropTypes.func.isRequired,
  getGaugeOption: PropTypes.func.isRequired,
  getLineOption: PropTypes.func.isRequired,
};

export default withStyles(OnsiteInspectionStatistics);
