import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { createhttpMessage, createMessage } from './message';

const defaultState = {
    processApprovals: [],
    deviceFormApprovalInstance: [],
}

export const setDeviceFormApprovalInstance = createAction('@@xcloud/processApproval/setDeviceFormApprovalInstance');
export const getDeviceFormApprovalInstance = createAction('@@xcloud/processApproval/getDeviceFormApprovalInstance',
    (formType, formId) => (dispatch, getState, httpClient) => {
        let url = `/api/approvals/instances/${formType}/${formId}`;
        return httpClient.get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setDeviceFormApprovalInstance(res.data));
                    return res.data
                }
            }).catch(err => {
                let msg = `获取设备表单流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const updateApprovalInstance = createAction('@@xcloud/processApproval/updateApprovalInstance',
    (formType, formId, approvals, callback) => (dispatch, getState, httpClient) => {
        let url = `/api/approvals/instances/${formType}/${formId}`
        approvals = _.chain(approvals).map(d => {
            let approval = _.chain(d).pickBy().value()
            if (_.isString(approval.preId)) {
                approval = _.omit(approval, ['preId']) //删除preId是string类型的值
            }
            return approval
        }).value()
        let dto = {
            'formType': formType,
            'formId': formId,
            'approvalDTOList': approvals,
        }
        return httpClient.patch(url, dto)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '更新流程实例成功'));
                    if (callback)
                        callback()
                    return res.data
                }
            }
            ).catch(err => {
                let msg = `更新流程实例失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);


export const saveApprovalInstance = createAction('@@xcloud/saveApprovalInstance',
    (formType, formId, approvals, callback) => (dispatch, getState, httpClient) => {
        approvals = _.map(approvals, d => { return _.pickBy(d) })
        let dto = {
            'formType': formType,
            'formId': formId,
            'approvalDTOList': approvals,
        }
        return httpClient.post(`/api/approvals/instances`, dto)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建用户流程成功'));
                    if (callback)
                        callback()
                    return res.data
                }

            }
            ).catch(err => {
                let msg = `创建用户流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const predefinedProcessReducer = handleActions(
    {
        [setDeviceFormApprovalInstance]: (state, { payload: value }) => {
            return {
                ...state,
                deviceFormApprovalInstance: value
            };
        },
    },
    defaultState
)

export default predefinedProcessReducer;