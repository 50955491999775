export default {
  root: {
    '&$focused': {
      backgroundColor: 'none'
    }
  },
  content: {
    margin: 0
  }
};
