import palette from '../palette';

export default {
  root: {
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    height: 32
  },
  contained: {
    minWidth: 40,
    paddingTop: 6,
    paddingBottom: 6,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: palette.primary.light
    },
  },
  containedPrimary: {
    minWidth: 40,
    paddingTop: 6,
    paddingBottom: 6,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: palette.primary.light
    },
  },
  outlined: {
    minWidth: 40,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      borderColor: palette.primary.light
    },
  },
  outlinedPrimary: {
    minWidth: 40,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      borderColor: palette.primary.light
    },
  }

};
