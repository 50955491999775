import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Divider,
    Button,
    TextField,
    MenuItem,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Table } from 'antd';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import _ from "lodash";

const AmoebaBudgetComponent = props => {
    const {
        classes,
        tableHeaders,
        tableData,
        viewType,
        params,
        roleW,
        companyList,
        departmentList,
        currentUserInfo,
        // onhandleUpdate,
        onhandleClick,
        onhandleAddPlan,
        onhandleViewChange,
        selectedDate,
        selectedCompanyId,
        selectedDptId,
        onhandleDateChange,
        onhandleOrgChange,
        userProfile,
    } = props;

    const isRootCompany = () => {
        const rootCompany = _.find(companyList, (c) => {
            return c.companyId === currentUserInfo.companyId && !c.pid
        })
        return rootCompany ? true : false
    }

    const getCompanySelects = () => {
        let companySelects = _.cloneDeep(companyList)
        companySelects.unshift({
            companyId: 0,
            companyAlias: '全公司',
        })
        return companySelects
    }
    const getDptSelects = () => {
        let dptSelects = _.filter(departmentList, d => {
            return d.companyId === selectedCompanyId && d.amoebaType !== 'OTHER'
        })
        dptSelects.unshift({
            dptId: 0,
            dptName: '所有部门',
        })
        return dptSelects
    }

    const getOrgName = () => {
        let orgName = ''
        if (selectedCompanyId) {
            const selectedCompany = _.find(companyList, { 'companyId': selectedCompanyId })
            orgName = orgName + (selectedCompany ? selectedCompany.companyAlias : '')
            if (selectedDptId) {
                const selectedDpt = _.find(departmentList, { 'dptId': selectedDptId })
                orgName = orgName + (selectedDpt ? selectedDpt.dptName : '')
            }
        }
        return orgName
    }

    const assemblycolumns = () => {
        let columns = [
            {
                title: '指标名称',
                dataIndex: 'subName',
                key: 'subName',
                width: 180,
                fixed: 'left',
                render: (text, row, index) => <Typography>{row.subName + '(' + row.unit + ')'}</Typography>,
                editable: 'never',
            }]

        _.map(tableHeaders, h => {
            columns.push({
                title: h.title,
                width: 90,
                type: 'numeric',
                align: 'right',
                dataIndex: h.key,
                key: h.key,
                render: (text, row, index) => renderBudget(row, h.key),
            })
        })
        return columns
    }

    const renderBudget = (row, key) => {
        let value = row[key]
        if (!value) return value = 0
        value = Number(value)
        if (row.unit === '万元') {
            value = value / 10000
        }
        return <Typography>{value.toFixed(2)}</Typography>
    }

    const assemblyData = () => {
        return tableData
    }



    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid>
                <Grid>
                    <Grid container>
                        <Grid container className={classes.topflex}>
                            <Grid item><Typography variant='h3'>{getOrgName() + params.dateString + '阿米巴预算'}</Typography></Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                        </Grid>
                        <Grid container className={classes.topflex}>
                            <Grid >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        views={params.dateViews}
                                        format={params.dateFormat}
                                        id="date-selector"
                                        margin="normal"
                                        value={selectedDate}
                                        onChange={onhandleDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                            {roleW && isRootCompany() && <Grid item className={classes.selectbox}>
                                <TextField
                                    fullWidth
                                    select
                                    name='companyId'
                                    InputProps={{
                                        style: {
                                            height: '36.5px'
                                        }
                                    }}
                                    label='分厂'
                                    variant="outlined"
                                    onChange={onhandleOrgChange}
                                    value={selectedCompanyId}
                                >
                                    {getCompanySelects().map(c => (
                                        <MenuItem key={c.companyId} value={c.companyId}>{c.companyAlias}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            }
                            {(roleW && selectedCompanyId) ? <Grid item className={classes.selectbox}>
                                <TextField
                                    fullWidth
                                    select
                                    name='dptId'
                                    InputProps={{
                                        style: {
                                            height: '36.5px'
                                        }
                                    }}
                                    label='班组|部门'
                                    variant="outlined"
                                    onChange={onhandleOrgChange}
                                    value={selectedDptId}
                                >
                                    {getDptSelects().map(d => (
                                        <MenuItem key={d.dptId} value={d.dptId}>{d.dptName}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid> : ''}
                            {roleW && <Grid item className={classes.selectbox}>
                                <TextField
                                    fullWidth
                                    select
                                    name='viewType'
                                    InputProps={{
                                        style: {
                                            // width: '160px',
                                            height: '36.5px'
                                        }
                                    }}
                                    label='视图'
                                    variant="outlined"
                                    onChange={onhandleViewChange}
                                    value={viewType}
                                >
                                    {params.viewTypeList.map(ele => (
                                        <MenuItem key={ele.code} value={ele.code}>{ele.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            }
                            {userProfile.opentext2 === '总经理' && roleW && params.isAnnualPlan && <Grid className={classes.button}>
                                <Button variant='contained' color='primary' onClick={onhandleAddPlan}>
                                    <Add />{'启动' + params.dateString + '阿米巴计划'}
                                </Button>
                            </Grid>
                            }
                            {roleW && <Grid className={classes.button}>
                                <Button variant='contained' color='primary' onClick={onhandleClick} >
                                    <AutorenewIcon />{'预算' + params.dateString}
                                </Button>
                            </Grid>
                            }
                        </Grid>
                        <Divider />
                    </Grid >
                    <Table
                        className={classes.stripedTable}
                        rowKey={'subId'} // 保持刷新
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        // pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: '100%', y: 'calc(100vh - 310px)' }}
                        size={'small'}
                        pagination={{ pageSize: 11 }}
                    />
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        </div >

    );
};

AmoebaBudgetComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companyName: PropTypes.string,
    planDetails: PropTypes.array,
    subDataList: PropTypes.array,
    year: PropTypes.number,
    isDpt: PropTypes.bool,
    planType: PropTypes.string,
    role: PropTypes.object,
    // onhandleUpdate: PropTypes.func,
    onhandleClick: PropTypes.func,
    onhandleAddPlan: PropTypes.func,
    onhandleViewChange: PropTypes.func,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
};

AmoebaBudgetComponent.defaultProps = {
    currentProcessStatus: {},
    planDetails: [],
    roleFlag: false,
    companyName: '',
    companyList: []
}

export default withStyles(AmoebaBudgetComponent);