import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getUserCountInDepartment
} from '@/redux/modules/user';
import {
    setResetSharingParam,
    resetSharingTake,
    initOfficeSharing,
    initCompanySharing,
} from '@/redux/modules/amoeba/amoebaCostSharing';
import ResetSharingComponent from '@/components/AmoebaManagement/SharingCost/ResetSharing';
import { getRole } from '@/utils/userUtils'
import * as _ from 'lodash';
/**
 * 
 */
class ResetSharingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            resetType: 'HEADCOUNT',
            resetScope: 'CURRENT_DEPARTMENT',
            takes: []
        }
    }

    componentWillMount() {

        const { onhandleGetUserCountInDepartment, currentUserInfo } = this.props

        onhandleGetUserCountInDepartment(currentUserInfo.companyId, '0,1').then(res => {
            if (res && res.payload) {
                this.setState({
                    takes: this.generateUserCountPercentage(res.payload)
                })
            }
        })
    }

    generateUserCountPercentage(userCounts) {

        const { param, departments } = this.props

        let headCounts = userCounts ? userCounts : this.props.userCounts

        if (param && param.sharingType === 'SHARING_COMPANY') {//车间经费分摊
            headCounts = _.filter(headCounts, c => {
                return _.find(departments, d => {
                    return d.dptId === c.dptId && d.amoebaType === 'PROFIT'
                })
            })
        }

        const totalCount = _.reduce(headCounts, function (sum, uc) {
            return sum + uc.userCount;
        }, 0)

        let takes = _.map(headCounts, uc => {
            const originTake = _.find(param.originTakes, { 'takerDpt': uc.dptId })
            return {
                ...uc,
                percentage: Number(100 * uc.userCount / totalCount),
                originPercentage: originTake ? originTake.percentage : undefined,
                takeId: originTake ? originTake.takeId : undefined,
                changeType: originTake ? 'UPDATE' : "ADD"
            }
        })

        _.map(param.originTakes, ot => {

            let newTake = _.find(takes, { 'dptId': ot.takerDpt })
            if (!newTake) {
                newTake = {
                    ...ot,
                    originPercentage: ot.percentage,
                    changeType: 'DELETE'
                }
                takes.push(newTake)
            }
        })

        return takes
    }

    handleUpdatePercentage(newDate) {

        let newTakes = this.state.takes
        for (let take of newTakes) {
            if (take.dptId === newDate.dptId) {
                take.percentage = newDate.percentage
            }
        }
        this.setState({ takes: newTakes })
    }

    handleClose() {
        let param = {
            selectedDptId: 0,
            open: false,
        }
        this.props.onhandleSetResetSharingParam(param)
    }

    handleChangeScope(event) {
        this.setState({ resetScope: event.target.value })
    }

    handleResetSave() {
        const { param, currentUserInfo, companies, departments, onhanleresetSharingTake, onInitOfficeSharingPage, onInitCompanySharingPage } = this.props
        let myCompany = _.find(companies, { 'companyId': currentUserInfo.companyId })
        let resetParam = {}
        resetParam["resetSharingType"] = param.sharingType
        resetParam["resetTakeType"] = 'TYPE_DEPARTMENT'
        resetParam["takerCompany"] = currentUserInfo.companyId
        if (param.sharingType === 'SHARING_OFFICE') {  //机关分摊
            resetParam["sharerCompany"] = myCompany.pid
        } else {//车间分摊
            resetParam["sharerCompany"] = currentUserInfo.companyId
        }

        if (this.state.resetScope === 'CURRENT_DEPARTMENT') { //当前分摊部门
            resetParam["shareDpts"] = [param.selectedDptId]
        } else { //所有部门
            if (param.sharingType === 'SHARING_OFFICE') {  //机关分摊
                resetParam["shareDpts"] = _.map(_.filter(departments, { 'companyId': myCompany.pid, 'amoebaType': 'COST', 'pid': null }), d => { return d.dptId })
            } else {//车间分摊
                resetParam["shareDpts"] = _.map(_.filter(departments, { 'companyId': myCompany.companyId, 'amoebaType': 'COST' }), d => { return d.dptId })
            }
        }
        resetParam["takerDpts"] = _.map(_.filter(this.state.takes, t => { return t.changeType !== 'DELETE' }), tt => { return { 'takerDpt': tt.dptId, 'percentage': Number(tt.percentage) } })

        onhanleresetSharingTake(resetParam).then(res => {
            this.handleClose()
            if (param.sharingType === 'SHARING_OFFICE') {
                onInitOfficeSharingPage(param.selectedDptId)
            } else {
                onInitCompanySharingPage(myCompany.companyId)
            }

        })

    }



    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_manager_sharing').action === 'W';
        return (
            <React.Fragment>
                {this.props.param && this.props.param.open && <ResetSharingComponent
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    companies={this.props.companies}
                    takes={this.state.takes}
                    resetType={this.state.resetType}
                    resetScope={this.state.resetScope}
                    param={this.props.param}
                    roleFlag={roleFlag}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleUpdate={this.handleUpdatePercentage.bind(this)}
                    onhandleChangeScope={this.handleChangeScope.bind(this)}
                    onhandleResetSave={this.handleResetSave.bind(this)}
                />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        companies: state.constant.companies,
        userCounts: state.user.userCounts,
        param: state.amoebaCostSharing.resetSharingParam,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetUserCountInDepartment: (companyId, userTypes, status) => {
            return dispatch(getUserCountInDepartment(companyId, userTypes, status));
        },
        onhandleSetResetSharingParam: (param) => {
            return dispatch(setResetSharingParam(param))
        },
        onhanleresetSharingTake: (resetParam) => {
            return dispatch(resetSharingTake(resetParam))
        },
        onInitOfficeSharingPage: (dptId) => {
            return dispatch(initOfficeSharing(dptId));
        },
        onInitCompanySharingPage: (companyId) => {
            return dispatch(initCompanySharing(companyId));
        },

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetSharingContainer);