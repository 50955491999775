import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyInfo from '../../../components/SystemManagement/OrgManagement/CompanyInfo'
import {
    createCompany,
    updateCompany,
    setCompanyError,
    initCompanyInfo,
    setCurrentCompany,
    setIndustrySelection,
    setAreaSelection,
    setCompanyChange
} from "../../../redux/modules/company";
import { cleanmodal } from "../../../redux/modules/modal";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { phoneRegx, emailRegx } from '../../../utils/regex';
import { findPath } from '../../../utils/constant';

class CompanyInfoContainer extends Component {

    static propTypes = {
        currentCompany: PropTypes.object,
        parentNode: PropTypes.object,
        companyError: PropTypes.object,
        areaTree: PropTypes.array,
        industryTree: PropTypes.array,
        industrySelection: PropTypes.object,
        areaSelection: PropTypes.object,
        companySizes: PropTypes.array
    };

    componentDidMount() {
        this.props.onhandleInitCompanyInfo();
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.companyError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleCompanyChange(false);
            if (this.props.currentCompany.companyId) {
                this.props.onhandleUpdateCompany();
            } else {
                this.props.onhandleCreateCompany();
            }
        }
    }

    handleSwitch = (event, field) => {
        this.props.onhandleCompanyChange(true);
        // console.log(event.target.checked, field)
        let newCompany;
        if (field === 'independentLegalEntity') {
            newCompany = {
                ...this.props.currentCompany,
                independentLegalEntity: event.target.checked
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        }
    };

    handleSelect = (value, field) => {
        this.props.onhandleCompanyChange(true);
        let newCompany;
        if (field === 'areaCode') {
            if (!value) {
                this.props.onhandleSetAreaSelection(undefined);
                return;
            }
            let path = findPath(value.value, this.props.areaTree)
                .map(i => i.label)
                .reverse()
                .join(' > ');
            this.props.onhandleSetAreaSelection({
                label: path,
                value: value.value
            });
            newCompany = {
                ...this.props.currentCompany,
                areaCode: value.value
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        } else if (field === 'industryCode') {
            if (!value) {
                this.props.onhandleSetIndustrySelection(undefined);
                return;
            }
            let path = findPath(value.value, this.props.industryTree)
                .map(i => i.label)
                .reverse()
                .join(' > ');
            this.props.onhandleSetIndustrySelection({
                label: path,
                value: value.value
            });
            newCompany = {
                ...this.props.currentCompany,
                industryCode: value.value
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        } else if (field === 'sizeCode') {
            newCompany = {
                ...this.props.currentCompany,
                sizeCode: value.target.value
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        } else if (field === 'amoebaType') {
            newCompany = {
                ...this.props.currentCompany,
                amoebaType: value.target.value
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        } else if (field === 'amoebaLevel') {
            newCompany = {
                ...this.props.currentCompany,
                amoebaLevel: parseInt(value.target.value)
            };
            this.props.onhandleUpdateEditCompany(newCompany);
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleCompanyChange(true);
        let newCompany;
        switch (field) {
            case 'companyName':
                newCompany = {
                    ...this.props.currentCompany,
                    companyName: event.target.value
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            case 'companyAlias':
                newCompany = {
                    ...this.props.currentCompany,
                    companyAlias: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            case 'email':
                newCompany = {
                    ...this.props.currentCompany,
                    email: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            case 'phone':
                newCompany = {
                    ...this.props.currentCompany,
                    phone: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            case 'opentext':
                newCompany = {
                    ...this.props.currentCompany,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            case 'address':
                newCompany = {
                    ...this.props.currentCompany,
                    address: event.target.value
                };
                this.props.onhandleUpdateEditCompany(newCompany);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        if (this.props.companyChange){
            if (window.confirm("数据未保存,确定要离开?")) {
                this.props.onhandleCancelToOrgChart();
            }
        }else{
            this.props.onhandleCancelToOrgChart();
        }
    };

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'companyName') {
            if (_.trim(this.props.currentCompany.companyName) === '') {
                err['companyName'] = '公司名称不能为空'
            } else {
                err['companyName'] = ''
            }
        }

        if (!field || field === 'email') {
            let emailStr = _.trim(this.props.currentCompany.email);
            if (emailStr !== '') {
                if (!emailRegx.test(emailStr)) {
                    err['email'] = '请输入有效的邮箱地址'
                } else {
                    err['email'] = ''
                }
            }
        }

        if (!field || field === 'phone') {
            let phoneStr = _.trim(this.props.currentCompany.phone);
            if (phoneStr !== '') {
                if (!phoneRegx.test(phoneStr)) {
                    err['phone'] = '请输入有效的联系电话或手机号码'
                } else {
                    err['phone'] = ''
                }
            }
        }

        let newError = {
            ...this.props.companyError,
            ...err
        }

        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <CompanyInfo
                onhandleSave={this.handleSave.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCancel={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleSelectionChange={this.handleSelect.bind(this)}
                companyError={this.props.companyError}
                currentCompany={this.props.currentCompany}
                parentNode={this.props.parentNode}
                areaTree={this.props.areaTree}
                industryTree={this.props.industryTree}
                industrySelection={this.props.industrySelection}
                areaSelection={this.props.areaSelection}
                companySizes={this.props.companySizes}
                onhandleSwitchChange={this.handleSwitch.bind(this)}
                companyChange={this.props.companyChange}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentCompany: state.company.currentCompany,
        companyError: state.company.companyError,
        parentNode: state.company.parentNode,
        areaTree: state.constant.areas,
        industryTree: state.constant.industry,
        industrySelection: state.company.industrySelection,
        areaSelection: state.company.areaSelection,
        companySizes: state.constant.constants.COMPANY_SIZE,
        companyChange: state.company.companyChange
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateCompany: () => {
            dispatch(createCompany())
        },
        onhandleUpdateCompany: () => {
            dispatch(updateCompany())
        },
        onhandleCancelToOrgChart: () => {
            dispatch(cleanmodal())
        },
        onhandleSetError: (err) => {
            dispatch(setCompanyError(err))
        },
        onhandleInitCompanyInfo: () => {
            dispatch(initCompanyInfo())
        },
        onhandleUpdateEditCompany: (entity) => {
            dispatch(setCurrentCompany(entity))
        },
        onhandleSetAreaSelection: (obj) => {
            dispatch(setAreaSelection(obj))
        },
        onhandleSetIndustrySelection: (obj) => {
            dispatch(setIndustrySelection(obj))
        },
        onhandleCompanyChange: (isChanged) => {
            dispatch(setCompanyChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyInfoContainer)
