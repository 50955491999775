import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization } from "../../../../utils/mtable";
import { getDateByTimestamp } from '../../../../utils/datetime';
import * as _ from 'lodash';

const MajorHazardDetectionList = props => {
    const {
        classes,
        majorHazardlist,
        handleGetList,
        catList,
        useStatusList,
        dangerLevelList,
        onSizeChange,
        pageSize,
        onCharts,
        onhandleInspection,
        onOpsRecords,
        extcatList,
        mediumList,
        handleViewDoc,
    } = props;

    const [page, setPage] = useState(0);

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderExtCat = (device) => {
        let label = '';
        if (extcatList && extcatList.length > 0) {
            let value = _.find(extcatList, function (o) { return o.code === device.extCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderTarget = (data) => {
        let mapping = { 'AREA': '区域', 'UNIT': '单元', 'INST': '装置' };
        return mapping[data.target]
    }

    const renderDangerLevel = (device) => {
        let label = '';
        if (dangerLevelList && dangerLevelList.length > 0) {
            let value = _.find(dangerLevelList, function (o) { return o.code === device.dangerLevel })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderUseStatus = (device) => {
        let label = ''
        if (useStatusList && useStatusList.length > 0) {
            let value = _.find(useStatusList, function (o) { return o.code === device.useStatus })
            if (value) {
                label = value.name
            }
        }
        return label
    }

    const renderTime = (device) => {
        return device.updateTime ? getDateByTimestamp(device.updateTime, true) : getDateByTimestamp(device.createTime, true)
    }

    const renderMedium = (data) => {
        let medium = ''
        if (_.isString(data.medium)) {
            let arr = data.medium.split(',')
            let vals = []
            for (let j = 0; j < arr.length; j++) {
                if (mediumList && mediumList.length > 0) {
                    let mediumValue = _.find(mediumList, function (o) { return o.code === arr[j] })
                    if (mediumValue) {
                        vals.push(mediumValue.name)
                    }
                }
            }
            medium = vals.join(',')
        }
        return medium
    }

    const assembleRelationDevicePanel = () => {
        return [{
            tooltip: '展开关联设备',
            render: rowData => {
                return renderItems(rowData);
            }
        }];
    }

    const assemblyRelationDeviceColumns = () => {

        let columns = [
            {
                field: 'type',
                width: '2em',
                editable: 'never'
            },
            {
                title: '设备组',
                field: 'groupName',
                defaultGroupOrder: 1,
                defaultGroupSort: 'asc'
            },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '所属装置',
                field: 'installTitle',
                render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                editable: 'never'
            },
            {
                title: '扩展类别',
                field: 'extCat',
                render: row => row ? <Typography>{renderExtCat(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备类型',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '位号',
                field: 'tagNo',
                render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '介质',
                field: 'medium',
                render: row => row ? <Typography>{renderMedium(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '使用状态',
                field: 'useStatus',
                render: row => row ? <Typography>{renderUseStatus(row)}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '更新时间',
            //     field: 'updateTime',
            //     type: 'date',
            //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            //     editable: 'never'
            // },
        ]

        return columns
    }

    const renderItems = (inData) => (

        < React.Fragment >
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblyRelationDeviceColumns()}
                    data={inData.majorHazardDevices ? inData.majorHazardDevices : []}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: false,
                        defaultExpanded: true,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    actions={[
                        rowData => ({
                            icon: 'track_changes',
                            tooltip: '查看巡检记录',
                            onClick: (event, rowData) => {
                                onCharts(event, rowData.deviceId)
                            },
                        }),
                        rowData => ({
                            icon: 'wifi_tethering',
                            tooltip: '查看运维记录',
                            onClick: (event, rowData) => {
                                onOpsRecords(event, { deviceId: rowData.deviceId, deviceNo: rowData.deviceNo, deviceName: rowData.deviceName })
                            }
                        }),
                    ]}
                >
                </MaterialTable>
            </Grid>
        </React.Fragment >
    );


    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'hazardName',
                render: row => row ? <Typography>{row.hazardName}</Typography> : '',
            },
            {
                title: '危险级别',
                field: 'dangerLevel',
                render: row => row ? <Typography>{renderDangerLevel(row)}</Typography> : '',
            },
            // {
            //     title: '负责人',
            //     field: 'hazardHead',
            //     render: row => row && row.hazardHeadLastName && row.hazardHeadFirstName ? <UserCard userId={row.hazardHead} child={<Typography>{row.hazardHeadLastName + row.hazardHeadFirstName}</Typography>} /> : row.hazardHeadFirstName ? <UserCard userId={row.hazardHead} child={<Typography>{row.hazardHeadFirstName}</Typography>} /> : '',

            // },
            {
                title: '所属装置',
                field: 'installName',
                render: row => row ? <Typography>{row.installName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单元类别',
                field: 'target',
                render: row => row ? <Typography>{renderTarget(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单元名称',
                field: 'targetName',
                render: row => row ? <Typography>{row.targetName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={majorHazardlist.list}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        pageSize: _.toInteger(pageSize),
                        tableLayout: 'auto',
                        actionsColumnIndex: -1,
                        search: false,
                        // defaultExpanded: true,
                        toolbar: false,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    detailPanel={assembleRelationDevicePanel()}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[5, 10, 20]}
                                rowsPerPage={_.toInteger(pageSize)}
                                count={majorHazardlist.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    handleGetList({ offset: pageNum * pageSize, size: pageSize, sort: "-update_time" })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    handleGetList({ offset: 0, size: event.target.value, sort: "-update_time" })
                                }}

                            />
                        )
                    }}
                    actions={[
                        rowData => ({
                            icon: 'cloud_circle',
                            tooltip: '查看评估报告',
                            onClick: (event, rowData) => {
                                handleViewDoc(rowData)
                            },
                        }),
                        rowData => ({
                            icon: 'update',
                            tooltip: '查看隐患记录',
                            onClick: (event, rowData) => {
                                onhandleInspection(event, rowData.hazardId)
                            },
                        }),
                    ]}
                >
                </MaterialTable>
            </Grid>
        </div >
    );
};

MajorHazardDetectionList.propTypes = {
    classes: PropTypes.object.isRequired,
    catList: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    handleGetList: PropTypes.func.isRequired,
    onCharts: PropTypes.func.isRequired,
    onhandleInspection: PropTypes.func.isRequired,
};

export default withStyles(MajorHazardDetectionList);
