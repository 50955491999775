import React, { useState } from "react";
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Typography,
  MenuItem,
  TextField,
  // Button,
  Grid,
  // IconButton,
  // Radio,
  // Tooltip,
  // FormControl,
  // RadioGroup,
  FormControlLabel,
  // Card
} from '@material-ui/core';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';
// import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
  SaveAlt,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';
// import { getMoment } from '../../../../utils/datetime'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';

const DeviceOpsRecordHeader = props => {
  const {
    classes,
    getDeviceOpsRecordList,
    onhandleClearSearch,
    search,
    catTree,
    extcatTree,
    useStatusTree,
    onhandleChange,
    onhandleCheck,
    onhandleSelectChange,
    onhandleDateChange,
    companyTree,
    companyList,
    installationList,
    onhandleExport,
    isLoad,
    parameters,
  } = props;

  const [expand, setExpand] = useState(false);

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }

  // const goToFrontDate = () => {
  //   if (search.intervalDate.view === 'day') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(-1, 'day')
  //     onhandleSelectChange(day, 'day')
  //   } else if (search.intervalDate.view === 'week') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(-7, 'days')
  //     onhandleSelectChange(day, 'week')
  //   } else if (search.intervalDate.view === 'month') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(-1, 'month')
  //     onhandleSelectChange(day, 'month')
  //   } else if (search.intervalDate.view === 'year') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(-1, 'year')
  //     onhandleSelectChange(day, 'year')
  //   }

  //   // 如果Popup画面调用：触发检索
  //   if (parameters && parameters.deviceId) {
  //     getDeviceOpsRecordList()
  //   }
  // }

  // const getintervalDate = () => {
  //   if (search.intervalDate.view === 'day') {
  //     return getMoment()(search.intervalDate.fromDate).format("YYYY/MM/DD")
  //   } else if (search.intervalDate.view === 'week') {
  //     let start = getMoment()(search.intervalDate.fromDate).format("MM/DD")
  //     let end = getMoment()(search.intervalDate.toDate).format("MM/DD")
  //     return start + '~' + end
  //   } else if (search.intervalDate.view === 'month') {
  //     return getMoment()(search.intervalDate.fromDate).format("YYYY/MM")
  //   } else if (search.intervalDate.view === 'year') {
  //     return getMoment()(search.intervalDate.fromDate).format("YYYY")
  //   }
  // };

  // const nextDisabled = () => {

  //   let end = _.cloneDeep(search.intervalDate.toDate)
  //   let now = getMoment()().format("YYYY/MM/DD")
  //   if (end >= now) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // const goToNextDate = () => {
  //   if (search.intervalDate.view === 'day') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(1, 'day')
  //     onhandleSelectChange(day, 'day')
  //   } else if (search.intervalDate.view === 'week') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(7, 'days')
  //     onhandleSelectChange(day, 'week')
  //   } else if (search.intervalDate.view === 'month') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(1, 'month')
  //     onhandleSelectChange(day, 'month')
  //   } else if (search.intervalDate.view === 'year') {
  //     let day = getMoment()(search.intervalDate.fromDate).add(1, 'year')
  //     onhandleSelectChange(day, 'year')
  //   }

  //   // 如果Popup画面调用：触发检索
  //   if (parameters && parameters.deviceId) {
  //     getDeviceOpsRecordList()
  //   }
  // }

  return (
    <div>
      <Grid container className={classes.title}>
        <Typography variant="h3">
          {
            `设备运维记录 `
            + (Boolean(parameters && parameters.deviceId) ? `${parameters.deviceNo} - ${parameters.deviceName})` : ``)
          }
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container alignItems="center" className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={expand ? 9 : 6}>
          {
            !Boolean(parameters && parameters.deviceId) && <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  单&emsp;&emsp;位
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  id="filled-select-company"
                  select
                  value={search.companyId}
                  onChange={(event) => onhandleSelectChange(event, 'companyId')}
                  variant="outlined"
                  disabled={companyDisabled()}
                >
                  <MenuItem aria-label="None" value={''}></MenuItem>
                  {_.sortBy(companyList, 'companyId').map((option) => (
                    <MenuItem key={option.companyId} value={option.companyId}>
                      {option.companyAlias}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={6} lg={expand ? 6 : 8}>
                <Typography className={classes.label}>
                  {'运维日期'}
                </Typography>
                <Grid container item className={classes.gridSubItem}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                      autoOk
                      className={classes.textFieldGroupItem}
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      ampm={false}
                      format="yyyy/MM/dd"
                      name='fromDate'
                      value={search.fromDate}
                      onChange={value => onhandleDateChange(value, 'fromDate')}
                      KeyboardButtonProps={{
                        'aria-label': '开始时间',
                      }}
                      InputProps={{
                        readOnly: true, style: { paddingRight: 0 },
                      }}
                      InputAdornmentProps={{
                        position: "end",
                        style: { order: 2, marginLeft: 0 }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography className={classes.connectLabel}>
                    {'至'}
                  </Typography>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      className={classes.textFieldGroupItem}
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      ampm={false}
                      format="yyyy/MM/dd"
                      name='toDate'
                      value={search.toDate}
                      onChange={value => onhandleDateChange(value, 'toDate')}
                      KeyboardButtonProps={{
                        'aria-label': '结束时间',
                      }}
                      InputProps={{
                        readOnly: true
                      }}
                      InputAdornmentProps={{
                        position: "end",
                        style: { order: 2, marginLeft: 0 }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {Boolean(expand) && <React.Fragment>
                <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                  <Typography className={classes.label}>
                    所属装置
                  </Typography>
                  <TextField
                    className={classes.textFieldInput}
                    size={'small'}
                    id="filled-select-company"
                    select
                    value={search.installId}
                    onChange={(event) => onhandleSelectChange(event, 'installId')}
                    variant="outlined"
                  >
                    <MenuItem aria-label="None" value={''}></MenuItem>
                    {_.sortBy(installationList, 'installId').map((option) => (
                      <MenuItem key={option.installId} value={option.installId}>
                        {option.installName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                  <Typography className={classes.label}>
                    设备名称
                  </Typography>
                  <TextField
                    className={classes.textFieldInput}
                    size={'small'}
                    value={search.search || ''}
                    variant="outlined"
                    placeholder="编码，位号，型号，名称"
                    onChange={event => onhandleChange(event, 'search')}
                  />
                </Grid>
                <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                  <Typography className={classes.label}>
                    设备类型
                  </Typography>
                  <TreeSelect
                    className={classes.textFieldInput}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                    allowClear
                    treeLine
                    value={search.cat}
                    labelInValue
                    treeData={catTree}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => onhandleSelectChange(value, 'cat')}
                  />
                </Grid>
                <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                  <Typography className={classes.label}>
                    扩展类别
                  </Typography>
                  <TreeSelect
                    className={classes.textFieldInput}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                    allowClear
                    treeLine
                    value={search.extcat}
                    labelInValue
                    treeData={extcatTree}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => onhandleSelectChange(value, 'extcat')}
                  />
                </Grid>
                <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                  <Typography className={classes.label}>
                    设备状态
                  </Typography>
                  <TreeSelect
                    className={classes.textFieldInput}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                    allowClear
                    treeLine
                    value={search.useStatus}
                    labelInValue
                    treeData={useStatusTree}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => onhandleSelectChange(value, 'useStatus')}
                  />
                </Grid>
              </React.Fragment>}
            </React.Fragment>
          }

        </Grid>
        {/* {!Boolean(parameters && parameters.deviceId) &&
          <Grid container spacing={0}>
            <Grid item className={classes.toolbarButton}>
                  <Button onClick={event => goToFrontDate(event)} size="small" >
                    <ArrowBackIos size="small" />
                  </Button>
                </Grid>
                <Grid item className={classes.toolbarLabel}>
                  <Typography className={classes.label}>{getintervalDate()}</Typography>
                </Grid>
                <Grid item className={classes.toolbarButton} >
                  <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
                </Grid>
          </Grid>
        }
      </Grid> */}
        {/* <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={4}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="视图" name="view"
                defaultValue="week"
                value={search.intervalDate.view}
                onChange={(event) => onhandleSelectChange(event, 'view')}
                style={{ width: '100%' }}
              >
                <FormControlLabel value="year" control={<Radio />} label="年视图" />
                <FormControlLabel value="month" control={<Radio />} label="月" />
                <FormControlLabel value="week" control={<Radio />} label="周" />
                <FormControlLabel value="day" control={<Radio />} label="日视图" />
              </RadioGroup>
            </FormControl>
          </Grid>  */}

        {!Boolean(parameters && parameters.deviceId) &&
          <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 3 : 6}>
            {/* 按钮区 */}
            {/* 搜索区 */}
            <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
              <Button variant="contained" color="primary"
                onClick={getDeviceOpsRecordList}
                disabled={isLoad}
              >
                搜索
              </Button>
              <Button variant="outlined" style={{ marginLeft: '10px' }}
                onClick={onhandleClearSearch}
                disabled={isLoad}
              >
                重置
              </Button>
              <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                onClick={() => setExpand(!expand)}
                endIcon={expand ? <ExpandLess /> : <ExpandMore />}
              >
                {expand ? '收起' : '展开'}
              </Button>
              <FormControlLabel
                className={classes.switch}
                label={
                  <Typography noWrap style={search.onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的记录</Typography>
                }
                control={
                  <Checkbox size={'small'}
                    checked={search.onlyMe}
                    color={'primary'}
                    disabled={isLoad}
                    onChange={event => onhandleCheck(event, 'onlyMe')} />
                } />
            </Grid>
            {/* 表格工具条区 */}
            <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
              <Button variant='contained' color='primary' startIcon={<SaveAlt />} style={{ marginLeft: '10px' }}
                onClick={event => onhandleExport(event)} disabled={isLoad}
              >
                导出
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
    </div>
  );
};

DeviceOpsRecordHeader.propTypes = {
  getDeviceOpsRecordList: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  installationList: PropTypes.array.isRequired,
  onhandleExport: PropTypes.func.isRequired,
  isLoad: PropTypes.bool.isRequired,
  parameters: PropTypes.object.isRequired,
};

export default withStyles(DeviceOpsRecordHeader);
