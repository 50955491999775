import React, { useState } from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
    Tooltip,
    IconButton
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import UserSelectPage from '@/containers/Utils/UserSelectPage';


import * as _ from 'lodash';

const MTempPlanDetailComponent = props => {
    const {
        classes,
        departments,
        openFlag,
        installId,
        rows,
        installationList,
        onhandleClose,
        onhandleSave,
        onhandleChange,
    } = props;

    const [showUserSelect, setShowUserSelect] = useState(false)
    const [rowsUpdate, setRowsUpdate] = useState(false)


    const addRow = (user) => {
        const maxIdDtl = _.maxBy(rows, 'id')

        const existRow = _.find(rows, { 'userId': user.userId })
        if (!existRow) {
            let row = {
                id: maxIdDtl ? (maxIdDtl.id + 1) : 1,
                installId: installId,
                userId: user.userId,
                dptId: user.dptId,
                userFullName: user.lastName + user.firstName,
            }
            onhandleChange(row, 'add')

            setRowsUpdate(true)
        }

    }

    const save = () => {
        onhandleSave()
        setShowUserSelect(false)
        setRowsUpdate(false)

    }

    const close = () => {
        onhandleClose()
        setShowUserSelect(false)
        setRowsUpdate(false)
    }

    const deleteRow = (row) => {
        onhandleChange(row, 'delete')
        setRowsUpdate(true)
    }

    const openUserSelect = () => {
        setShowUserSelect(true)
    }

    const renderInstall = (installId) => {

        const install = _.find(installationList, { 'installId': installId })

        return <Typography>{install ? install.installName : ''}</Typography>

    }

    const renderDpt = (dptId) => {

        const dpt = _.find(departments, { 'dptId': dptId })

        return <Typography>{dpt ? dpt.dptName : ''}</Typography>

    }

    const renderActions = (row) => {
        return <Grid container alignItems='center'>
            <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => deleteRow(row)}> <DeleteIcon fontSize='small' /></IconButton></Tooltip></Grid>
        </Grid>
    }



    const assemblyColumns = () => {
        return [
            {
                title: '装置',
                dataIndex: 'installId',
                key: 'installId',
                render: (text, row, index) => renderInstall(row.installId),
            }, {
                title: '班组',
                dataIndex: 'dptId',
                key: 'dptId',
                render: (text, row, index) => renderDpt(row.dptId),
            },
            {
                title: '人员',
                dataIndex: 'userId',
                key: 'userId',
                render: (text, row, index) => <Typography>{row.userFullName}</Typography>,
            }, {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: '90px',
                render: (text, row, index) => renderActions(row),
            },]
    }



    return (
        <div alignitems="center" className={classes.root}>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openFlag}
                onClose={onhandleClose}>
                <DialogTitle>
                    <Grid container >
                        <Grid item ><Typography component={'span'} variant='h3'>{'装置相关人员列表'}</Typography></Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2} >
                        <Grid container item xs={12} direction="column">
                            <Grid item className={classes.planDetails}>
                                <Grid container alignItems='flex-end'>
                                    <Grid item className={classes.flexgrow}> </Grid>
                                    {<Grid item><Tooltip title='新增'><IconButton variant='text' onClick={() => openUserSelect()}> <AddIcon fontSize='small' /></IconButton></Tooltip></Grid>}
                                </Grid>
                                <Table
                                    size='small'
                                    className={classes.stripedTable}
                                    rowKey={record => record.id}
                                    dataSource={rows}
                                    columns={assemblyColumns()}
                                ></Table>
                            </Grid>
                            {showUserSelect && <Grid item  >
                                <UserSelectPage innerParam={{
                                    company: true,
                                    group: false,
                                    role: false,
                                    department: true,
                                    userName: false,
                                    name: true,
                                    innerSelect: true,
                                    callback: (data) => addRow(data)
                                }} />
                            </Grid>}
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => save()}
                        variant={rowsUpdate ? "contained" : "outlined"}
                        disabled={rowsUpdate ? false : true}
                        color="primary"> 保存
                    </Button>
                    <Button onClick={() => close()}
                        variant="outlined"
                        color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MTempPlanDetailComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

MTempPlanDetailComponent.defaultProps = {
    plan: {},
    openFlag: false,

};

export default withStyles(MTempPlanDetailComponent);