import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccessoryList from "../../../components/AccessoryManagement/AccessoryInfo/AccessoryList";
import PropTypes from "prop-types";
import {
    getAccessoryList,
    setSearch,
    // initAccessoryList,
    changeAccessoryStatus,
    initUpdate,
    initView,
    initCreate,
    copyInitEdit
} from '../../../redux/modules/accessory';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';

class AccessoryListContainer extends Component {
    static propTypes = {
        search: PropTypes.object,
        userProfile: PropTypes.object,
        accessoryList: PropTypes.object,
        catList: PropTypes.array,
    };

    componentWillMount() {
        this.props.onhandleInitAccessoryList();
    }

    handleEdit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleView(event, id) {
        this.props.onhandleView(id);
    }

    handleCopy(event, id) {
        this.props.onhandleCopy(id);
    }

    handleDelete = (event, rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData, 'inactive');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除部件[' + rowData.accNo + ']吗?', changeStatus, null, false);
    };

    handleActivate = (event, rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData, 'active');
        }
        this.props.onhandleOpenConfirmDialog('激活确认', '确定要激活部件[' + rowData.accNo + ']吗?', changeStatus, null, false);
    };



    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }
    handleCreate(event) {
        this.props.onhandleCreate()
    }

    render() {
        return (
            <AccessoryList
                userProfile={this.props.userProfile}
                onEdit={this.handleEdit.bind(this)}
                getAccessoryList={this.props.onhandleGetAccessoryList}
                onDelete={this.handleDelete.bind(this)}
                onActivate={this.handleActivate.bind(this)}
                accessoryList={this.props.accessoryList}
                catList={this.props.catList}
                onSizeChange={this.handleListSize.bind(this)}
                onView={this.handleView.bind(this)}
                onCopy={this.handleCopy.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.accessory.search,
        userProfile: state.auth.userProfile,
        accessoryList: state.accessory.searchAccessorylist,
        catList: state.accessory.catList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitAccessoryList: () => {
            // dispatch(initAccessoryList());
        },
        onhandleGetAccessoryList: (offset, size, sort, cat, search, companyId, isCommon, status) => {
            dispatch(getAccessoryList(offset, size, sort, cat, search, companyId, isCommon, status))
        },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleView: (id) => {
            dispatch(initView(id));
        },
        onhandleCopy: (id) => {
            dispatch(copyInitEdit(id));
        },
        onhandleChangeStatus: (rowData, status) => {
            dispatch(changeAccessoryStatus(rowData, status));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccessoryListContainer)
