import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { setValuationFormulaStr, SERVICE_TYPE_COMMISSION } from '@/utils/manufactureUtils'

const porxyApi = '/api'

const initialState = {
    services: [],//生产服务列表
    servicePrices: [],//服务定价列表
    serviceHistories: [],//服务定价历史
    projectServices: [],//工程相关服务
    serviceCustomers: [],//服务关联客户列表
};

export const setServices = createAction('@@xcloud/manufacture/setServices');
export const setCommissionServices = createAction('@@xcloud/manufacture/setCommissionServices');
export const setServicePrices = createAction('@@xcloud/manufacture/setServicePrices');
export const setServiceHistories = createAction('@@xcloud/manufacture/setServiceHistories');
export const setProjectServices = createAction('@@xcloud/manufacture/setProjectServices');
export const setServiceCustomers = createAction('@@xcloud/manufacture/setServiceCustomers');

export const addManufactureService = createAction('@@xcloud/manufacture/addService',
    (service) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/services', service)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加生产服务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加生产服务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManufactureService = createAction('@@xcloud/manufacture/updateService',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/services/' + updateDto.serviceId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新生产服务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产服务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delManufactureService = createAction('@@xcloud/manufacture/deleteService',
    (serviceId, endDate) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/services/' + serviceId + '/enddate/' + endDate)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除生产服务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除生产服务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getManufactureServiceById = createAction('@@xcloud/manufacture/getServiceById',
    (serviceId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/services/' + serviceId
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                //dispatch(setServices([res.data]))
            }
            return res.data;
        }).catch(err => {
            let msg = `获取生产服务失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getManufactureServices = createAction('@@xcloud/manufacture/getServices',
    (requireCompany, serviceType, serviceCompany, status, reqMainCompany, callback) => (dispatch, getState, httpClient) => {
        if (serviceType === SERVICE_TYPE_COMMISSION) {
            dispatch(setCommissionServices([]))
        } else {
            dispatch(setServices([]))
        }
        let url = porxyApi + '/manufacture/services'
        let strs = []
        // 服务类型为ALL时,为全类型服务查询,需方公司(requireCompany)为需方和服务公司(requireCompany,serviceCompany)
        if (requireCompany || serviceType || serviceCompany || reqMainCompany || status) {
            if (serviceType) strs.push('serviceType=' + serviceType)//服务类型
            if (serviceCompany) strs.push('serviceCompany=' + serviceCompany)// 服务公司
            if (status) strs.push('status=' + status)
            //需方公司和需方总公司的参数绑定,二者需一起传参
            if (requireCompany && reqMainCompany) strs.push('requireCompany=' + requireCompany)//需方公司
            if (reqMainCompany && reqMainCompany) strs.push('reqMainCompany=' + reqMainCompany)//需方总公司
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                if (serviceType === SERVICE_TYPE_COMMISSION) {
                    dispatch(setCommissionServices(res.data))
                } else {
                    dispatch(setServices(res.data))
                }

                if (callback) {
                    callback(res.data);
                }
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产服务列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getServicesPrices = createAction('@@xcloud/manufacture/getServicesPrices',
    (requireCompany, serviceType, serviceCompany, status, reqMainCompany, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/services'
        let strs = []
        // 服务类型为ALL时,为全类型服务查询,需方公司(requireCompany)为需方和服务公司(requireCompany,serviceCompany)
        if (requireCompany || serviceType || serviceCompany || reqMainCompany || status) {
            if (serviceType) strs.push('serviceType=' + serviceType)//服务类型
            if (serviceCompany) strs.push('serviceCompany=' + serviceCompany)// 服务公司
            if (status) strs.push('status=' + status)
            //需方公司和需方总公司的参数绑定,二者需一起传参
            if (requireCompany && reqMainCompany) strs.push('requireCompany=' + requireCompany)//需方公司
            if (reqMainCompany && reqMainCompany) strs.push('reqMainCompany=' + reqMainCompany)//需方总公司
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setServicePrices(setValuationFormulaStr(res.data)))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产服务列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateServicePrice = createAction('@@xcloud/manufacture/getServices',
    (service, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/services/' + service.serviceId + '/price', service)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "更新生产服务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产服务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const getServiceHistories = createAction('@@xcloud/manufacture/getServicesPrices',
    (serviceId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/services/history'
        let strs = []
        if (serviceId) {
            if (serviceId) strs.push('serviceId=' + serviceId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setServiceHistories(res.data));
            }
            return res.status;
        }).catch(err => {
            let msg = `获取服务历史记录失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getProjectServices = createAction('@@xcloud/manufacture/getProjectServices',
    (companyId, mainCompanyId, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/services/project'
        let strs = []
        if (companyId || mainCompanyId) {
            if (companyId) strs.push('companyId=' + companyId)
            if (mainCompanyId) strs.push('mainCompanyId=' + mainCompanyId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setProjectServices(res.data))
                if (callback) {
                    callback(res.data);
                }
            }
            return res.status;
        }).catch(err => {
            let msg = `获取工程相关服务失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });


export const getServiceCustomerById = createAction('@@xcloud/manufacture/getServiceCustomerById',
    (scid) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/services/customers/' + scid
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                return res.data;
            }

        }).catch(err => {
            let msg = `获取生产服务客服`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });
export const getServiceCustomers = createAction('@@xcloud/manufacture/getServiceCustomers',
    (query, callback) => (dispatch, getState, httpClient) => {
        // dispatch(setServiceCustomers([]))
        let url = porxyApi + '/manufacture/services/customers'
        // 服务类型为ALL时,为全类型服务查询,需方公司(requireCompany)为需方和服务公司(requireCompany,serviceCompany)
        if (query.companyId || query.serviceId || query.cid || query.customerGroup || query.material || query.materialGroup
            || query.product || query.productGroup || query.status) {
            let strs = []
            if (query.companyId) strs.push('companyId=' + query.companyId)
            if (query.serviceId) strs.push('serviceId=' + query.serviceId)
            if (query.cid) strs.push('cid=' + query.cid)
            if (query.customerGroup) strs.push('customerGroup=' + query.customerGroup)
            if (query.material) strs.push('material=' + query.material)
            if (query.materialGroup) strs.push('materialGroup=' + query.materialGroup)
            if (query.product) strs.push('product=' + query.product)
            if (query.productGroup) strs.push('productGroup=' + query.productGroup)
            if (query.status) strs.push('status=' + query.status)
            url = url + '?' + strs.join('&')
        }

        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setServiceCustomers(res.data))
                if (callback) {
                    callback(res.data);
                }
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产服务客户列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addServiceCustomer = createAction('@@xcloud/manufacture/addServiceCustomer',
    (serviceCustomerDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/services/customers', serviceCustomerDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加生产客户成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加生产服务客户失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateServiceCustomer = createAction('@@xcloud/manufacture/updateServiceCustomer',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/services/customers/' + updateDto.scid, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新生产服务客户成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产服务客户失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteServiceCustomer = createAction('@@xcloud/manufacture/deleteServiceCustomer',
    (scid) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/services/customers/' + scid)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除生产服务客户成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除生产服务客户失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const manufactureServiceReducer = handleActions(
    {
        [setServices]: (state, { payload: data }) => {
            return {
                ...state,
                services: data
            }
        },
        [setCommissionServices]: (state, { payload: data }) => {
            return {
                ...state,
                commissionServices: data
            }
        },
        [setServicePrices]: (state, { payload: data }) => {
            return {
                ...state,
                servicePrices: data
            }
        },
        [setServiceHistories]: (state, { payload: data }) => {
            return {
                ...state,
                serviceHistories: data
            }
        },
        [setProjectServices]: (state, { payload: data }) => {
            return {
                ...state,
                projectServices: data
            }
        },
        [setServiceCustomers]: (state, { payload: data }) => {
            return {
                ...state,
                serviceCustomers: data
            }
        },
    },
    initialState
)

export default manufactureServiceReducer
