import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import BuList from "../../../containers/SystemManagement/BuManagement/BuList";

const BuManagement = props => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <BuList/>
            </div>
        </div>
    );
};
BuManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(BuManagement);
