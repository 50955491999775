import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationStatus from '../../components/OperationManagement/OperationStatus';
import {
  initOperationStatus,
  updSearchData,
  resetInstallation,
  setLoad,
  exportCsv
} from '../../redux/modules/operationStatus';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import { getCompanyId } from "../../utils/userUtils";
import { getTreePartById } from '../../utils/constant';
import { getMoment } from '../../utils/datetime'
import * as _ from 'lodash';

let myChart = null;

class OperationStatusContainer extends Component {
  static propTypes = {
    recordList: PropTypes.array,
    installationList: PropTypes.array,
  }

  componentDidMount() {
    myChart = echarts.init(document.getElementById('main'));
    this.props.onhandleInitOperationStatus();
  }

  handleLoad = () => {
    if (myChart) {
      if (this.props.search.companyId) {
        if (this.props.search.intervalDate.view === 'day') {
          myChart.setOption(this.getBarOption(), true);
        } else {
          myChart.setOption(this.getBarOptionDateBase(), true);
        }
      } else {
        if (this.props.search.intervalDate.view === 'day') {
          myChart.setOption(this.getBarOption(), true);
        } else {
          myChart.setOption(this.getBarOptionDateBase(), true);
        }
      }
      this.props.onhandleLoad()
    }
  }

  getBarOptionDateBase = () => {
    let result = [['date']];
    let date = [];
    switch (this.props.search.intervalDate.view) {
      case 'week':
        let weekStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: weekStartDate,
            name: getMoment()(weekStartDate).format("ddd")
          })
          weekStartDate = getMoment()(weekStartDate).add(1, 'day').format("YYYY/MM/DD")
        } while (weekStartDate <= this.props.search.intervalDate.toDate)
        break;
      case 'month':
        let monthStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: monthStartDate,
            name: getMoment()(monthStartDate).format("Do")
          })
          monthStartDate = getMoment()(monthStartDate).add(1, 'day').format("YYYY/MM/DD")
        } while (monthStartDate <= this.props.search.intervalDate.toDate)
        break;
      case 'year':
        let yearStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: getMoment()(yearStartDate).format("YYYY/MM"),
            name: getMoment()(yearStartDate).format("MMM")
          })
          yearStartDate = getMoment()(yearStartDate).add(1, 'month').format("YYYY/MM/DD")
        } while (yearStartDate <= this.props.search.intervalDate.toDate)
        break;
      default:
    }

    let seriesData = [];
    let specCodeList = _.clone(this.props.specCodeList);
    specCodeList.push({ code: '-', name: '普通作业' })
    let installList = [];
    this.props.workOrderStatus.forEach((item) => {
      installList.push({
        installId: item.installId,
        installName: item.installName,
        companyId: item.companyId,
        companyName: item.companyName,
      })
    })
    installList = _.uniqWith(installList, _.isEqual);
    let companyList = [];
    if (this.props.search.companyId) {
      companyList.push(this.props.search.companyId)
    } else {
      companyList = _.map(this.props.workOrderStatus, 'companyId');
    }
    companyList = _.uniq(companyList)
    companyList.forEach(company => {
      _.filter(installList, { companyId: company }).forEach(
        install => {
          specCodeList.forEach(specCode => {
            let newStatus = []
            if (specCode.code === '-') {
              newStatus = _.filter(this.props.workOrderStatus, function (o) { return o.companyId === company && o.installId === install.installId && !o.code })
            } else {
              newStatus = _.filter(this.props.workOrderStatus, { companyId: company, installId: install.installId, code: specCode.code })
            }
            if (newStatus && newStatus.length > 0) {
              let data = []
              let name = ''
              name = install.companyName + '（' + install.installName + '）（' + specCode.name + '）';
              date.forEach(d => {
                let count = 0
                newStatus.forEach(status => {
                  if (d.date === status.countDate) {
                    count = status.total
                  }
                })
                data.push(count)
              }
              )
              // seriesData.push({ name: name, type: 'line', data: data })
              seriesData.push({
                name: name, type: 'bar', data: data, stack: '总量',
                label: {
                  show: true,
                  position: 'insideTop',
                  formatter: function (params) {
                    if (params.value === 0) {  //为0时不显示
                      return ''
                    } else {
                      return params.value
                    }
                  }
                },
              })
            }
          })
        }
      )

    })

    let option = {
      title: { text: '作业统计' },
      legend: {
        bottom: 0,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      dataset: {
        source: result
      },
      xAxis: {
        type: 'category',
        data: _.map(date, 'name')
      },
      yAxis: { type: 'value', minInterval: 1, },
      series: seriesData,
    }
    return option

  }

  getBarOption = () => {
    let result = [['installId']];
    // let total = ['总数']
    let seriesData = [];
    let specCodeList = _.clone(this.props.specCodeList);
    specCodeList.push({ code: '-', name: '普通作业' })
    let installList = [];
    this.props.workOrderStatus.forEach((item) => {
      installList.push({
        installId: item.installId,
        installName: item.installName,
        companyId: item.companyId,
        companyName: item.companyName,
      })
    })
    installList = _.uniqWith(installList, _.isEqual);
    let companyList = [];
    if (this.props.search.companyId) {
      companyList.push(this.props.search.companyId)
    } else {
      companyList = _.map(this.props.workOrderStatus, 'companyId');
    }
    companyList = _.uniq(companyList)
    _.map(specCodeList, x => {
      let data = []
      data.push(x.name)
      _.map(companyList, c => {
        _.map(_.filter(installList, { companyId: c }), y => {
          let count = 0
          _.map(this.props.workOrderStatus, z => {
            if (!z.code && x.code === '-' && y.installId === z.installId && c === z.companyId) {
              count = z.total
            } else if (z.code === x.code && y.installId === z.installId && c === z.companyId) {
              count = z.total
            }
          })
          data.push(count)
        })
      })
      result.push(data)
    })
    _.map(installList, y => {
      result[0].push(y.companyName + '（' + y.installName + '）')
      seriesData.push({
        type: 'bar', itemStyle: {
          normal: {
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                color: 'black',
                fontSize: 10
              }
            }
          }
        }
      })
    });
    // for (let j = 1; j < result[0].length; j++) {
    //   let count = 0;
    //   for (let i = 1; i < result.length; i++) {
    //     if (result[i][j]) {
    //       count = count + result[i][j]
    //     }
    //   }
    //   total.push(count)
    // }

    // result.push(total)

    let option = {
      title: { text: '作业统计' },
      legend: {
        bottom: 0,
        left: 'center'
      },
      tooltip: {},
      dataset: {
        source: result
      },
      xAxis: { type: 'category' },
      yAxis: { type: 'value', minInterval: 1, },
      series: seriesData,
    }
    return option
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        let companyId = value.target.value === 0 ? null : value.target.value;
        newSearch = {
          ...this.props.search,
          companyId: companyId,
          installId: null
        };
        this.props.onhandleUpdateSearch(newSearch);
        this.props.onhandleResetInstallation(companyId);
        break;
      case 'installId':
        let installId = value.target.value === 0 ? null : value.target.value;
        newSearch = {
          ...this.props.search,
          installId: installId
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'sepecCode': {
        let specCode = value.target.value === '' ? '' : value.target.value;
        newSearch = {
          ...this.props.search,
          specCode: specCode
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      }
      case 'view':
        let fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
        let toDate = getMoment()().endOf('day').format("YYYY/MM/DD");
        switch (value.target.value) {
          case 'year':
            fromDate = getMoment()().startOf('year').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('year').format("YYYY/MM/DD");
            break;
          case 'month':
            fromDate = getMoment()().startOf('month').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('month').format("YYYY/MM/DD");
            break;
          case 'week':
            fromDate = getMoment()().startOf('week').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('week').format("YYYY/MM/DD");
            break;
          case 'day':
            fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            toDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            break;
          default:
        }
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: value.target.value,
            fromDate: fromDate,
            toDate: toDate
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'day':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('day').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('day').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'week':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('week').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('week').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'month':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('month').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('month').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'year':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('year').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('year').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      default:
    }
  }

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <div>
        <OperationStatus
          companyTree={this.trimCompanyTree()}
          companyList={this.props.companyList}
          search={this.props.search}
          onhandleSelectChange={this.handleSelect.bind(this)}
          isLoad={this.props.isLoad}
          onhandleLoad={this.handleLoad.bind(this)}
          installationList={this.props.installationList}
          specCodeList={this.props.specCodeList}
          onhandleExportCsv={this.props.onhandleExportCsv.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    search: state.operationStatus.search,
    workOrderStatus: state.operationStatus.workOrderStatus,
    isLoad: state.operationStatus.load,
    specsColor: state.operationStatus.specsColor,
    installationList: state.installation.installationList ? state.installation.installationList.list : [],
    specCodeList: state.operation.specCodeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitOperationStatus: () => {
      dispatch(initOperationStatus());
    },
    onhandleUpdateSearch: (search) => {
      dispatch(updSearchData(search));
    },
    onhandleResetInstallation: (value) => {
      dispatch(resetInstallation(value));
    },
    onhandleLoad: () => {
      dispatch(setLoad(false));
    },
    onhandleExportCsv: () => {
      dispatch(exportCsv());
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationStatusContainer);
