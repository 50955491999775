import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/EnergyPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import TreeSelect from 'rc-tree-select';
import { findParent } from '@/utils/constant';

import * as _ from 'lodash';

const OilPricingComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        serviceCustomers,
        customerGroups,
        customers,
        roleFlag,
        services,
        materialGroupTree,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
        onhandleCompanyChange,
    } = props;
    const tableRef = React.createRef();
    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        paging: false,
    }

    const tableStyle = {
        ...style,
        padding: '10px',
    }

    const renderService = (row) => {
        if (row && row.service) {
            return row.service.serviceName
        }
        return ''
    }

    const renderCustomerGroup = (row) => {
        return row ? row.customerGroupName : ''
    }

    const renderCustomer = (row) => {
        return row && row.customer ? row.customer.cstName : ''
    }

    const renderMaterial = (row) => {
        let text = ''
        if (row && row.materialGroupName) {
            text = row.materialGroupName
            if (row.materialName) {
                text += '/' + row.materialName
            }
        }
        return text
    }

    const renderProduct = (row) => {
        let text = ''
        if (row && row.productGroupName) {
            text = row.productGroupName
            if (row.productName) {
                text += '/' + row.productName
            }
        }
        return text
    }

    const renderUnitPrice = (row) => {
        if (row && row.unitPrice) {
            return Number(row.unitPrice).toFixed(2)
        }
        return 0.00
    }
    const materialTreeValue = (row) => {
        if (row.material) {
            return { label: row.materialName, value: '_' + row.material }

        } else if (row.materialGroup) {
            return { label: row.materialGroupName, value: row.materialGroup }
        }
        return ''
    }

    const productTreeValue = (row) => {
        if (row.product) {
            return { label: row.productName, value: '_' + row.product }
        } else if (row.productGroup) {
            return { label: row.productGroupName, value: row.productGroup }
        }
        return ''
    }


    const assemblyColumns = () => {
        const columns = [
            {
                title: '服务名称',
                field: 'serviceId',
                width: 180,
                render: row => <Typography>{renderService(row)}</Typography>,
                validate: rowData => (!rowData.serviceId) ? { isValid: false, helperText: '服务不能为空' } : true,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}

                        value={props.rowData.serviceId ? props.rowData.serviceId : ''}
                        name='serviceId'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {services ?
                            _.filter(services, (c) => { return c.serviceCompany === companyId }).map(data => (
                                <option value={data.serviceId} key={data.serviceId}>{data.serviceName}</option>
                            )) : null
                        }
                    </TextField>
                )
            },
            {
                title: '客户分组',
                field: 'customerGroup',
                width: 120,
                render: row => <Typography>{renderCustomerGroup(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.customerGroup ? props.rowData.customerGroup : ''}
                        name='customerGroup'
                        onChange={e => props.onChange(e.target.value)} >
                        <option value={''} key={0}>{''}</option>
                        {
                            customerGroups ? customerGroups.map(data => (
                                <option value={data.groupId} key={data.groupId}>{data.groupName}</option>
                            )) : null}
                    </TextField >
                )
            },
            {
                title: '客户名称',
                field: 'cid',
                width: 120,
                render: row => <Typography>{renderCustomer(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.cid ? props.rowData.cid : 0}
                        name='cid'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {customers ? _.filter(customers, c => {
                            return (_.find(c.customerGroups, g => { return g.groupId + '' === props.rowData.customerGroup }))
                        }).map(data => (
                            <option value={data.cid} key={data.cid}>{data.cstName}</option>
                        )) : null}
                    </TextField>
                )
            }, {
                title: '主要材材',
                field: 'material',
                width: 120,
                render: row => <Typography>{renderMaterial(row)}</Typography>,
                editComponent: (props) => (
                    <TreeSelect
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                        treeLine
                        value={materialTreeValue(props.rowData)}
                        style={{
                            marginTop: 5,
                            width: 180,
                        }}
                        labelInValue
                        treeData={materialGroupTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={e => {
                            if (e.value && typeof (e.value) === 'string' && e.value.indexOf('_') > -1) {
                                let mg = findParent(e.value, 'value', materialGroupTree)
                                props.rowData.materialGroup = mg.pcode
                                props.rowData.materialGroupName = mg.name
                                props.rowData.material = Number(e.value.slice(1))
                                props.rowData.materialName = e.label

                            } else {
                                props.rowData.materialGroup = e.value
                                props.rowData.materialGroupName = e.label
                                props.rowData.material = undefined
                                props.rowData.materialName = undefined
                            }
                            tableRef.current.forceUpdate();
                        }}
                    />
                )
                // }, {
                //     title: '产品分组',
                //     field: 'productGroup',
                //     width: 120,
                //     render: row => <Typography>{renderProductGroup(row)}</Typography>,
            }, {
                title: '产品',
                field: 'product',
                width: 120,
                render: row => <Typography>{renderProduct(row)}</Typography>,
                editComponent: (props) => (
                    <TreeSelect
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                        treeLine
                        value={productTreeValue(props.rowData)}
                        style={{
                            marginTop: 5,
                            width: 180,
                        }}
                        labelInValue
                        treeData={materialGroupTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={e => {
                            if (e.value && typeof (e.value) === 'string' && e.value.indexOf('_') > -1) {
                                let mg = findParent(e.value, 'value', materialGroupTree)
                                props.rowData.productGroup = mg.pcode
                                props.rowData.productGroupName = mg.name
                                props.rowData.product = Number(e.value.slice(1))
                                props.rowData.productName = e.label
                            } else {
                                props.rowData.productGroup = e.value
                                props.rowData.productGroupName = e.label
                                props.rowData.product = undefined
                                props.rowData.productName = undefined
                            }
                            tableRef.current.forceUpdate();
                        }}
                    />
                )
            }, {
                title: '销售单价（元）',
                field: 'unitPrice',
                type: 'numeric',
                width: 100,
                render: row => <Typography>{renderUnitPrice(row)}</Typography>,
            }
        ]
        return columns
    }

    const assemblyData = () => {
        return serviceCustomers
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={assemblyColumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            localization={tableLocalization}
                            style={tableStyle}
                            editable={roleFlag ? {
                                onRowAdd: (newData) => new Promise((resolve, reject) => {
                                    onhandleAdd(newData)
                                    resolve()
                                }),
                                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                    onhandleUpdate(newData, oldData)
                                    resolve()
                                }),
                                onRowDelete: (oldData) => new Promise((resolve, reject) => {
                                    onhandleDelete(oldData)
                                    resolve()
                                })
                            } : {}}
                            actions={[]}
                            title={<Typography variant='h3'>服务客户定价</Typography>}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

OilPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    subCompanyList: PropTypes.array,
    varFeeRuleList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,

};

OilPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(OilPricingComponent);