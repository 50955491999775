import React, { Component } from 'react';
import "./index.css";
import "../../../resource/rc-select.css";
import Konva from 'konva';
import { ReactComponent as SaveIcon } from '../../../resource/save.svg';
import { ReactComponent as ExportIcon } from '../../../resource/export.svg';
import { ReactComponent as FullScreenIcon } from '../../../resource/fullscreen.svg';
import { ReactComponent as FullScreenExitIcon } from '../../../resource/fullscreen-exit.svg';
// import { ReactComponent as ZoomInIcon } from '../../../resource/zoom in.svg';
// import { ReactComponent as ZoomOutIcon } from '../../../resource/zoom out.svg';
// import { ReactComponent as FitScreenIcon } from '../../../resource/fitscreen.svg';
import { ReactComponent as MoveUpIcon } from '../../../resource/MoveUp.svg';
import { ReactComponent as MoveDownIcon } from '../../../resource/MoveDown.svg';
import { ReactComponent as MoveRightIcon } from '../../../resource/MoveRight.svg';
import { ReactComponent as MoveLeftIcon } from '../../../resource/MoveLeft.svg';
import { ReactComponent as ShowIcon } from '../../../resource/show.svg';
import { ReactComponent as HideIcon } from '../../../resource/hide.svg';
// import Select, { Option } from 'rc-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Switch from 'rc-switch';
import * as _ from 'lodash';
import KonvaUtil from "../devices/util";
import MapEditActionMapping from "../devices/mapEditActionMapping";
const konvaUtil = new KonvaUtil();
const mapEditActionMapping = new MapEditActionMapping();

export default class TopBar extends Component {
    constructor(props) {
        super(props)
        this._exportImg = this._exportImg.bind(this);
        this._fullscreen = this._fullscreen.bind(this);
        // this._reCalGrid = this._reCalGrid.bind(this);
        this._requestFullScreen = this._requestFullScreen.bind(this);//进入全屏
        this._exitFullscreen = this._exitFullscreen.bind(this);//退出全屏
        this._watchFullScreen = this._watchFullScreen.bind(this);//监听fullScreen事件
        this._changeGrideSize = this._changeGrideSize.bind(this);
        this._zoomAction = this._zoomAction.bind(this);
        this._moveAction = this._moveAction.bind(this);
        this._ceateLayer = this._createLayer.bind(this);
        this._changeUnitView = this._changeUnitView.bind(this);
        this._saveMap = this._saveMap.bind(this);
        this._deviceGroupShow = this._deviceGroupShow.bind(this);
        this.state = {
            isFullScreen: false,
            layerName: "",
            layerDatum: 0,
            isShow: false
        }
    }

    componentWillMount = () => {
        this._watchFullScreen();
        let selectedStorey = _.minBy(this.props.editInstallation.storeys, 'altitudeDatum').storeyId;
        this.props.addStoreySelect(selectedStorey)
    }

    componentDidUpdate(oldProps) {
        if (oldProps.autoSave !== this.props.autoSave && this.props.autoSave) {
            // console.log(this.props.autoSave)
            this._saveMap();
        }
    }

    _watchFullScreen = () => {
        const _self = this;
        document.addEventListener(
            "webkitfullscreenchange",
            function () {
                console.log("webkitfullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                } else {
                    fullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                    layerName: _self.state.layerName,
                    layerDatum: _self.state.layerDatum
                });
            },
            false
        );

        document.addEventListener(
            "fullscreenchange",
            function () {
                console.log("fullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                } else {
                    fullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                    layerName: _self.state.layerName,
                    layerDatum: _self.state.layerDatum
                });
            },
            false
        );

        document.addEventListener(
            "mozfullscreenchange",
            function () {
                console.log("mozfullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                } else {
                    fullScreenSetting();
                    // _self._reCalGrid(_self.props.gridSize);
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                    layerName: _self.state.layerName,
                    layerDatum: _self.state.layerDatum
                });
            },
            false
        );
    };

    // _reCalGrid(gridSize) {
    //     let stage = this.props.getStage();
    //     let layer = stage.findOne('#basicLayer');
    //     layer.destroyChildren()
    //     var points = []
    //     for (var ix = 0; ix * gridSize < this.props.konvaWidth * 2; ix++) {
    //         for (var iy = 0; iy * gridSize < this.props.konvaHeight * 2; iy++) {
    //             points.push({
    //                 x: ix * gridSize,
    //                 y: iy * gridSize
    //             })
    //         }
    //     }
    //     points.forEach((point) => {
    //         var circle = new Konva.Circle({
    //             x: point.x,
    //             y: point.y,
    //             radius: 1,
    //             fill: 'black',
    //             opacity: 0.2
    //         });
    //         layer.add(circle);
    //     })
    //     layer.draw();
    // }

    _changeGrideSize(evt) {
        this.props.updGridSize(evt);
        // this._reCalGrid(evt);
    }

    _zoomAction(action) {
        konvaUtil.zoomAction(action, this.props)
    }

    _moveAction(action) {
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');

        layer.find('Group').forEach(group => {
            if (group.id()) {
                if (action === 'u') {
                    group.y(group.y() - 10)
                } else if (action === 'd') {
                    group.y(group.y() + 10)
                } else if (action === 'l') {
                    group.x(group.x() - 10)
                } else if (action === 'r') {
                    group.x(group.x() + 10)
                }
            }
        })
        layer.batchDraw();
        konvaUtil.updateView(this.props, layer, this.props.getPreviewStage());
        this.props.updMapChanged(true);
    }

    _exportImg() {
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        let dataURL = layer.toDataURL({ pixelRatio: 3 });
        let link = document.createElement('a');
        let selectValue = this.props.selectedId
        let storey = _.find(this.props.editInstallation.storeys, function (o) { return o.storeyId === selectValue; });
        link.download = `${this.props.editInstallation.installName}_${storey.storeyName}.png`;
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    }

    _fullscreen() {
        let isFullScreen = this.state.isFullScreen;
        if (!this.state.isFullScreen) {
            this._requestFullScreen();
        } else {
            this._exitFullscreen();
        }
        this.setState({
            isFullScreen: !isFullScreen,
            layerName: this.state.layerName,
            layerDatum: this.state.layerDatum
        })
    }

    _requestFullScreen() {
        var dom = document.getElementById('fullTag');//绑定想要全屏的组件
        if (dom.requestFullscreen) {
            dom.requestFullscreen();
        } else if (dom.mozRequestFullScreen) {
            dom.mozRequestFullScreen();
        } else if (dom.webkitRequestFullScreen) {
            dom.webkitRequestFullScreen();
        }
    };

    _exitFullscreen() {
        var dom = document;
        if (dom.exitFullscreen) {
            dom.exitFullscreen();
        } else if (dom.mozCancelFullScreen) {
            dom.mozCancelFullScreen();
        } else if (dom.webkitCancelFullScreen) {
            dom.webkitCancelFullScreen();
        }
    };

    _renderFullScreen() {
        if (this.state.isFullScreen) {
            return (
                <React.Fragment>
                    <FullScreenExitIcon className="top-control-btn"
                        onClick={this._fullscreen.bind(this)} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <FullScreenIcon className="top-control-btn"
                        onClick={this._fullscreen.bind(this)} />
                </React.Fragment>
            );
        }
    }

    _changeUnitView() {
        let worklayer = this.props.getStage().findOne('.workLayer');
        this.props.updateUnitView();
        konvaUtil.updateView(this.props, worklayer, this.props.getPreviewStage(), 'edit');
    }

    changeData = (e, type) => {
        if (type === 'name') {
            this.setState({
                isFullScreen: this.state.isFullScreen,
                layerName: e.target.value,
                layerDatum: this.state.layerDatum
            })
        } else {
            this.setState({
                isFullScreen: this.state.isFullScreen,
                layerName: this.state.layerName,
                layerDatum: _.toInteger(e.target.value)
            })
        }

    }

    _createLayer() {
        this.props.createInstallationStoreys(this.props.editInstallation.installId, this.state.layerName, this.state.layerDatum);
        this.setState({
            isFullScreen: this.state.isFullScreen,
            layerName: "",
            layerDatum: 0
        })
    }

    _saveMap() {
        this.props.updAutoSave(false);
        if (this.props.mapChanged) {
            let data = {}
            let stage = this.props.getStage();
            let layer = stage.findOne('.workLayer');
            let storeyJson = layer.toJSON();
            let selectedId = this.props.selectedId;
            let storey = _.find(this.props.editInstallation.storeys, function (o) { return o.storeyId === selectedId; });
            storey.installId = this.props.editInstallation.installId;
            storey.tenantId = this.props.editInstallation.tenantId;
            storey.data2d = JSON.stringify(storeyJson);
            data.storey = storey;
            let devices = [];
            if (this.props.modifyDevices && this.props.modifyDevices.length > 0) {
                this.props.modifyDevices.forEach(device => {
                    let group = layer.findOne(`#${device.type}_${device.deviceId}`);
                    if (group) {
                        let group2d = group.toJSON();
                        device.data2d = JSON.stringify(group2d)
                        devices.push(device)
                    }
                })
            }
            data.devices = devices;

            let units = [];
            if (this.props.modifyUnits && this.props.modifyUnits.length > 0) {
                this.props.modifyUnits.forEach(unit => {
                    let group = layer.findOne(`#Unit_${unit.unitId}`);
                    if (group) {
                        let group2d = group.toJSON();
                        unit.data2d = JSON.stringify(group2d)
                        unit.installId = this.props.editInstallation.installId;
                        unit.tenantId = this.props.editInstallation.tenantId;
                        units.push(unit)
                    }
                })
            }
            data.units = units;

            let areas = [];
            if (this.props.modifyAreas && this.props.modifyAreas.length > 0) {
                this.props.modifyAreas.forEach(area => {
                    let group = layer.findOne(`#Area_${area.areaId}`);
                    if (group) {
                        let group2d = group.toJSON();
                        area.data2d = JSON.stringify(group2d)
                        area.installId = this.props.editInstallation.installId;
                        area.tenantId = this.props.editInstallation.tenantId;
                        areas.push(area)
                    }
                })
            }
            data.areas = areas;

            let deviceGroups = [];
            if (this.props.modifyDeviceGroup && this.props.modifyDeviceGroup.length > 0) {
                this.props.modifyDeviceGroup.forEach(deviceGroup => {
                    let group = layer.findOne(`#${deviceGroup.type}_${deviceGroup.groupId}`);
                    if (group) {
                        let group2d = group.toJSON();
                        deviceGroup.data2d = JSON.stringify(group2d);
                        deviceGroups.push(deviceGroup);
                    } else {
                        deviceGroup.data2d = '';
                        deviceGroups.push(deviceGroup);
                    }
                })
            }
            data.deviceGroups = deviceGroups;
            // console.log(data)
            this.props.saveMap(data);
        } else {
            this.props.createMessage('info', '当前数据没有任何改动无需保存！')
        }
    }

    onSelect = (value, option) => {
        this.props.cleanContentMenu();
        if (this.props.mapChanged) {
            this.props.createMessage('warning', '当前数据未保存！请点击保存按钮，保存后再切换层。')
        } else {
            this.props.addStoreySelect(value)

            let stage = this.props.getStage();
            let delworklayer = stage.findOne('.workLayer');
            if (delworklayer) {
                delworklayer.destroy();
            }
            let storey = _.find(this.props.editInstallation.storeys, function (o) { return o.storeyId === value; });
            if (storey.data2d) {

                let worklayer = Konva.Layer.create(JSON.parse(storey.data2d));
                worklayer.name('workLayer');
                stage.add(worklayer);
                mapEditActionMapping.layerMapping(this.props, worklayer, stage, this.props.getPreviewStage());
            } else {
                let worklayer = new Konva.Layer({
                    name: 'workLayer'
                })
                stage.add(worklayer);
            }
            konvaUtil.updateView(this.props, stage.findOne('.workLayer'), this.props.getPreviewStage());
            this.props.initDeviceTreeData();
        }
    };

    _getAltitudeDatum = () => {
        let id = this.props.selectedId;
        let storey = _.find(this.props.editInstallation.storeys, function (o) { return o.storeyId === id; });
        if (storey) {
            return storey.altitudeDatum;
        } else {
            return '';
        }

    }

    _deviceGroupShow = () => {
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        let deviceGroups = layer.find('.DeviceGroup');
        deviceGroups.forEach(deviceGroup => {
            let rectTemp = deviceGroup.findOne('.backGround');
            let circle = deviceGroup.findOne('.main');
            if (this.state.isShow) {
                if (!rectTemp.fill()) {
                    rectTemp.fill('#81c784');
                    rectTemp.opacity(1);
                    deviceGroup.moveToTop();
                    rectTemp.moveToTop();
                    circle.fill('#616161');
                    circle.moveToTop();
                    layer.draw();
                }
            } else {
                if (rectTemp.fill()) {
                    rectTemp.fill(null);
                    deviceGroup.moveToBottom();
                    rectTemp.moveToBottom();
                    circle.fill('green');
                    circle.moveToTop();
                    circle.moveToTop();
                    layer.draw();
                    let units = layer.find('.Unit');
                    if (units) {
                        units.forEach(unit => {
                            unit.moveToBottom();
                        })
                    }
                    let areas = layer.find('.Area');
                    if (areas) {
                        areas.forEach(area => {
                            area.moveToBottom();
                        })
                    }
                    layer.draw();
                }
            }
        })
        konvaUtil.updateView(this.props, layer, this.props.getPreviewStage());
        this.setState({
            isShow: !this.state.isShow,
        })

    }

    render() {
        const { canvasWidth, isFullScreen } = this.props;
        return (
            <div style={{ width: canvasWidth - 400, left: isFullScreen ? 200 : 224 }} className="topbar">
                <div className="controls">

                    <div className="control">
                        <SaveIcon className="top-control-btn"
                            onClick={this._saveMap.bind(this)} />
                        <ExportIcon className="top-control-btn"
                            onClick={this._exportImg.bind(this)} />
                    </div>
                    <div className="divider"> </div>
                    {/* <Select style={{ width: 200 }}
                    value={this.props.selectedId}
                    dropdownRender={menu => (
                        <React.Fragment>
                            {menu}
                            <div>
                                <input type="text" style={{ width: 80 }} placeholder="层名称" title='层名称' value={this.state.layerName} onChange={(e) => this.changeData(e, 'name')} />
                                <input type="number" style={{ width: 70 }} placeholder="高度基准" title='高度基准' value={this.state.layerDatum} onChange={(e) => this.changeData(e, 'datum')} />
                                <button type="button" disabled={this.state.layerName === "" || this.state.layerDatum === ""} onClick={() => { this._createLayer(this) }}>创建</button>
                            </div>
                        </React.Fragment>
                    )}
                    onSelect={this.onSelect}
                >
                    {_.sortBy(this.props.editInstallation.storeys, 'storeyId').map(
                        storeyItem => (
                            <Option value={storeyItem.storeyId} key={storeyItem.storeyId}>
                                {storeyItem.storeyName}
                            </Option>
                        )
                    )}
                </Select>
                <div className="slideText">高度基准:{this._getAltitudeDatum()}</div>
                <div className="divider"> </div> */}
                    <div className="slideText">视图:</div>
                    <Switch
                        onChange={this._changeUnitView.bind(this)}
                        className="customeSwitch"
                        defaultChecked={this.props.unitView}
                        checkedChildren="单元"
                        unCheckedChildren="区域"
                    />
                    <div className="divider"> </div>
                    <div className="control">
                        {this.state.isShow
                            ?
                            <HideIcon className="top-control-btn"
                                onClick={this._deviceGroupShow.bind(this)} />
                            :
                            <ShowIcon className="top-control-btn"
                                onClick={this._deviceGroupShow.bind(this)} />
                        }
                    </div>
                </div>
                <div className="adjectControl">
                    <div className="slideText">网格尺寸:</div>
                    <div className="slideBar">
                        <Slider
                            defaultValue={this.props.gridSize}
                            min={10}
                            max={50}
                            step={10}
                            marks={{ 10: 10, 20: 20, 30: 30, 40: 40, 50: 50 }}
                            onChange={this._changeGrideSize.bind(this)}
                        />
                    </div>
                    <div className="divider"> </div>
                    <MoveUpIcon className="top-control-btn" onClick={() => { this._moveAction("u") }} />
                    <MoveDownIcon className="top-control-btn" onClick={() => { this._moveAction("d") }} />
                    <MoveLeftIcon className="top-control-btn" onClick={() => { this._moveAction("l") }} />
                    <MoveRightIcon className="top-control-btn" onClick={() => { this._moveAction("r") }} />
                    {/* <ZoomOutIcon className="top-control-btn" onClick={() => { this._zoomAction("Out") }} />
                    <ZoomInIcon className="top-control-btn" onClick={() => { this._zoomAction("In") }} />
                    <FitScreenIcon className="top-control-btn" onClick={() => { this._zoomAction("Reset") }} /> */}

                    <div className="divider"> </div>
                    {this._renderFullScreen()}
                </div>
            </div>
        );
    }
}