import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    planDetail:{
        task: ''
    },
    confirmCallback: null,
    closeCallback: null,
};

export const openPlanChecklistSetPannel = createAction('@@xcloud/inspectionPlanChecklistSet/open',
    (
        confirmCallback, closeCallback
    ) => ({
        confirmCallback, closeCallback
    })
);

export const closePlanChecklistSetPannel = createAction('@@xcloud/inspectionPlanChecklistSet/close');

export const changePlanDetail = createAction('@@xcloud/inspectionPlanChecklistSet/changeplanDetail');

// Actions

export const InspectionPlanChecklistSetReducer = handleActions(
    {
        [openPlanChecklistSetPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
            };
        },
        [closePlanChecklistSetPannel]: (state, ) => {
            return {
                ...state,
                open: false,
                planDetail:{},
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changePlanDetail]: (state,{ payload: data }) => {
            return {
                ...state,
                 planDetail: data,
            };
        }
    },
    initialState
);

export default InspectionPlanChecklistSetReducer;
