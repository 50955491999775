import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { getCodeName, VALUATION_TYPE } from '@/utils/manufactureUtils';
import { formatDate_hms } from '@/utils/datetime';
import { localization, options } from "@/utils/mtable";

const BudgetHistoryComponent = props => {
    const {
        classes,
        open,
        serviceName,
        serviceUnit,
        manufactureDicts,
        serviceHistories,
        onhandleClose,
        subFlg,
        indexes,
    } = props;


    const getSubAlias = (row) => {
        let index = indexes.find(ele => ele.subId === row.variableSub);
        if (index) {
            return index.subAlias
        }
        return ''
    }

    const assemblycolumns = () => {
        let columns = [
            {
                field: 'updateTime',
                title: '更新时间',
                width: 150,
                render: row => <Typography>{formatDate_hms(row.updateTime) || ''}</Typography>,
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
            },
            {
                field: 'unitPrice',
                title: '单价',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.unitPrice}</Typography>,
            },
            {
                field: 'unit',
                title: '单位',
                align: 'left',
                width: 120,
                render: row => <Typography>{serviceUnit}</Typography>,
            },
            {
                field: 'creatorName',
                title: '修改人',
                render: row => <Typography>{row.creatorName}</Typography>,
            },
        ]
        return columns
    }

    const assemblycolumnsNoUnit = () => {
        let columns = [
            {
                field: 'updateTime',
                title: '更新时间',
                width: 150,
                render: row => <Typography>{formatDate_hms(row.updateTime) || ''}</Typography>,
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
            },
            {
                title: '别名',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getSubAlias(row)}</Typography>,
            },
            {
                field: 'ratio',
                title: '单耗',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.ratio}</Typography>,
            },
            {
                field: 'creatorName',
                title: '修改人',
                render: row => <Typography>{row.creatorName}</Typography>,
            },
        ]
        return columns
    }

    const assemblycolumnsUnit = () => {
        let columns = [
            {
                field: 'updateTime',
                title: '更新时间',
                width: 150,
                render: row => <Typography>{formatDate_hms(row.updateTime) || ''}</Typography>,
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
            },
            {
                title: '别名',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getSubAlias(row)}</Typography>,
            },
            {
                field: 'ratio',
                title: '单耗',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.ratio}</Typography>,
            },
            {
                field: 'unitPrice',
                title: '单价',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.variableUnitPrice}</Typography>,
            },
            {
                field: 'unit',
                title: '单位',
                align: 'left',
                width: 120,
                render: row => <Typography>{serviceUnit}</Typography>,
            },
            {
                field: 'creatorName',
                title: '修改人',
                render: row => <Typography>{row.creatorName}</Typography>,
            },
        ]
        return columns
    }

    const assemblycolumnsNoRatio = () => {
        let columns = [
            {
                field: 'updateTime',
                title: '更新时间',
                width: 150,
                render: row => <Typography>{formatDate_hms(row.updateTime) || ''}</Typography>,
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
            },
            {
                title: '别名',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getSubAlias(row)}</Typography>,
            },
            {
                field: 'unitPrice',
                title: '单价',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.variableUnitPrice}</Typography>,
            },
            {
                field: 'unit',
                title: '单位',
                align: 'left',
                width: 120,
                render: row => <Typography>{serviceUnit}</Typography>,
            },
            {
                field: 'creatorName',
                title: '修改人',
                render: row => <Typography>{row.creatorName}</Typography>,
            },
        ]
        return columns
    }

    const getColumns = () => {
        if (subFlg === 0) {
            return assemblycolumnsNoUnit()
        } else if (subFlg === 1) {
            return assemblycolumnsUnit()
        } else if (subFlg === 2) {
            return assemblycolumnsNoRatio()
        } else {
            return assemblycolumns()
        }
    }

    const tableOptions = {
        ...options,
        // toolBar: false,
        pageSize: 8,
        pageSizeOptions: [20, 30]
    }

    const tableLocalization = {
        ...localization,
    }

    const assemblyData = () => {
        return serviceHistories
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>
                        {'服务名：' + serviceName}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <Grid className={classes.detailtable}>
                            <MaterialTable
                                title={<Typography variant='h4'>{'定价历史记录'}</Typography>}
                                columns={getColumns()}
                                data={assemblyData()}
                                options={tableOptions}
                                localization={tableLocalization}
                                style={{
                                    border: '0px solid black',
                                    boxShadow: 'none'
                                }}
                            >
                            </MaterialTable>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

BudgetHistoryComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    serviceHistories: PropTypes.array,
    onhandleClose: PropTypes.func,
};

BudgetHistoryComponent.defaultProps = {
    serviceHistories: [],
    serviceName: '',
    serviceUnit: '',
    indexes: [],
}

export default withStyles(BudgetHistoryComponent);