
import React, { useState } from 'react';
import {
    Box,
    Card,
    Grid,
    CardContent,
    Typography,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Badge,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { SentimentSatisfiedAlt, ExpandLess, ExpandMore, NavigateBefore, NavigateNext } from '@material-ui/icons'
import * as _ from 'lodash';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { formatDay } from '@/utils/datetime';

const TodoPage = props => {
    const { classes,
        currentUserInfo,
        todos,
        selectedDate,
        onhandleNextWeek,
        onhandleLastWeek,
        onhandleOpenTodo,
    } = props;

    const [itemExtend, setItemExtend] = useState(1);

    const onhandleClick = (e) => {

        let clickItem = Number(e.currentTarget.dataset.item)

        if (clickItem === itemExtend) {
            setItemExtend(0)
        } else {
            setItemExtend(clickItem)
        }
    };

    const getActiveTodoCount = () => {

        if (!todos) {
            return 0
        }
        return _.filter(todos, { status: 'active' }).length
    }

    const getDoneTodoCount = () => {
        if (!todos) {
            return 0
        }
        return _.filter(todos, { status: 'done' }).length
    }

    const renderActiveTodo = () => {
        const activeTodos = _.filter(todos, { status: 'active' })
        return <List component="div" disablePadding>
            {activeTodos.map((todo, idx) => (
                <ListItem key={todo.todoId} button className={classes.nested}
                    onClick={(event) => onhandleOpenTodo(todo.path)}>
                    <ListItemText primary={(idx + 1) + ' ' + todo.headline}
                        secondary={todo.opentext} />
                </ListItem>
            ))}
        </List>
    }
    const renderDoneTodo = () => {
        const activeTodos = _.filter(todos, { status: 'done' })
        return <List component="div" disablePadding>
            {activeTodos.map((todo, idx) => (
                <ListItem key={todo.todoId} button className={classes.nested}
                    onClick={(event) => onhandleOpenTodo(todo.path)}
                >
                    <ListItemText primary={(idx + 1) + ' ' + todo.headline}
                        secondary={todo.opentext}
                    />
                    <Typography>{formatDay(todo.updateTime)}</Typography>
                </ListItem>
            ))}
        </List>
    }

    const renderWeek = () => {

        if (selectedDate) {
            return selectedDate.startOf('week').format('MM/DD')
                + '-' + selectedDate.endOf('week').format('MM/DD')
        }

    }

    return (
        <div alignitems="center" className={classes.root}>
            <Card>
                <CardContent>
                    <Box className={classes.week}>
                        <Grid container>
                            <Grid item >
                                <Link className={classes.weeklink} onClick={(event) => onhandleLastWeek(event)}>上周</Link>
                            </Grid>
                            <Grid item >
                                <NavigateBefore />
                            </Grid>
                            <Grid item >
                                <Typography className={classes.weekcurrent}>{renderWeek()}</Typography>
                            </Grid>
                            <Grid item >
                                <NavigateNext />
                            </Grid>
                            <Grid item>
                                <Link className={classes.weeklink} onClick={(event) => onhandleNextWeek(event)}>下周</Link>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={classes.tasksummary}>
                        {/* <Card className={classes.card}>
                    <CardContent> */}
                        <Grid container >
                            <Grid item>
                                <SentimentSatisfiedAlt className={classes.mood} />
                            </Grid>
                            <Grid item >
                                <Typography className={classes.hello}> 你好，{currentUserInfo.lastName + currentUserInfo.firstName} ~</Typography>
                                <Typography className={classes.summary}> 您本周共有 <b className={classes.taskcount}>{getActiveTodoCount()}</b> 待办需要完成。</Typography>
                            </Grid>
                        </Grid>
                        {/* </CardContent>
                </Card> */}
                    </Box>

                    <Box className={classes.detail}>

                        <List >
                            <ListItem button data-item={1} onClick={onhandleClick}>
                                <ListItemIcon className={classes.listitemicon}>
                                    <PlaylistPlayIcon />
                                </ListItemIcon>
                                <ListItemText primary="本周待办事项 /" />
                                <Badge badgeContent={getActiveTodoCount()} color='secondary' className={classes.todobadge}>
                                </Badge>
                                {itemExtend === 1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={itemExtend === 1} timeout="auto" unmountOnExit>
                                {renderActiveTodo()}
                            </Collapse>
                            {/* <ListItem button data-item={2} onClick={onhandleClick}>
                                <ListItemText primary="本周在进行中 / " />
                                {itemExtend === 2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem> */}
                            {/* <Collapse in={itemExtend === 2} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Starred" />
                                    </ListItem>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Starred" />
                                    </ListItem>
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Starred" />
                                    </ListItem>

                                </List>
                            </Collapse> */}

                            <ListItem button data-item={3} onClick={onhandleClick}>
                                <ListItemIcon className={classes.listitemicon}>
                                    <PlaylistAddCheckIcon />
                                </ListItemIcon>
                                <ListItemText primary="本周完成任务 / " />
                                <Badge badgeContent={getDoneTodoCount()} color={'primary'}
                                    className={classes.donebadge}>
                                </Badge>
                                {itemExtend === 3 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={itemExtend === 3} timeout="auto" unmountOnExit>
                                {renderDoneTodo()}
                            </Collapse>
                        </List>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}
TodoPage.propTypes = {
    title: PropTypes.string,

};

TodoPage.defaultProps = {
    title: '待办事项',
};

export default withStyles(TodoPage);



