import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    salorderList: [] //销售记录列表
};

export const setSalorderList = createAction('@@xcloud/amoeba/setSalorderList');

export const getSalorderList = createAction('@@xcloud/amoeba/getSalorderList',
    (customerName, salesmanId, orderDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/salorders'
        let strs = []
        if (customerName || salesmanId || orderDate || status) {
            if (customerName) strs.push('customerName=' + customerName)
            if (salesmanId) strs.push('salesmanId=' + salesmanId)
            if (orderDate) strs.push('orderDate=' + orderDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setSalorderList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取销售记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const AmoebaSalOrderReducer = handleActions(
    {
        [setSalorderList]: (state, { payload: data }) => {
            return {
                ...state,
                salorderList: data
            }
        },
    },
    initialState
)

export default AmoebaSalOrderReducer
