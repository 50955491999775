import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    permitBar: {
        width: "100%"
    },
    processline: {
        flexGrow: 1
    },

    permitHistory: {
        paddingLeft: '4em'
    },
    permitHistoryItem: {
        margin: 0,
        padding: 0,
        paddingLeft: '1em'
    },
    confirmTrue: {
        color: theme.palette.success.main
    },
    confirmFalse: {
        color: theme.palette.error.main
    },
    processUser: {
        paddingLeft: theme.spacing(2),
        display: 'contents'
    },
    historyList: {
        height: '80px',
        overflowY: 'scroll',
        width: '100%'
    },
    button: {
        marginLeft: theme.spacing(2),
    }

});


export default withStyles(styles);