import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'

const porxyApi = '/api';
const basePath = porxyApi + '/amoeba/phonebills';
const initialState = {
    records: [],//电话费List
};

export const setPhoneBills = createAction('@@xcloud/amoeba/setPhoneBills');

export const getPhoneBills = createAction('@@xcloud/amoeba/getPhoneBills',
    (phone, companyId, dptId, userId, year, month) =>
        (dispatch, getState, httpClient) => {
            let url = basePath;
            if (phone || companyId || dptId || userId || year || month) {
                let strs = [];
                if (phone) strs.push('phone=' + phone)
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                if (userId) strs.push('userId=' + userId)
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setPhoneBills(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取电话费列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const importPhoneBills = createAction('@@xcloud/amoeba/importPhoneBills',
    (dto, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(basePath + "/import", dto).then(
                (res) => {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "导入电话费成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `导入电话费失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const updatePhoneBill = createAction('@@xcloud/amoeba/updatePhoneBill',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.patch(basePath + "/" + record.recordId, record).then(
                (res) => {
                    if (res && callback)
                    callback(res.data)
                    dispatch(createhttpMessage(res, "更新电话费成功"))
                    return res.status;
                }).catch(err => {
                    let msg = `更新电话费失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const phoneBillReducer = handleActions(
    {
        [setPhoneBills]: (state, { payload: data }) => {
            return {
                ...state,
                records: data
            }
        },
    },
    initialState
)

export default phoneBillReducer
