import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination, Button, Card
} from '@material-ui/core';
import { getNameByTypeAndCode } from '../../../../utils/constant';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';
import { Add, DeleteOutlined, Edit, Description } from "@material-ui/icons";

const ProcessList = props => {
    const {
        classes,
        processes,
        userProfile,
        onDetailedInfo,
        onhandleCreate,
        onDelete,
        getProcessList,
        constants
    } = props;

    const [selectedRow, setSelectedRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'process_management' })
        return permission && permission.action === 'W'
    }

    const renderTime = (data) => {
        return data.updateTime ? getDateByTimestamp(data.updateTime, true) : getDateByTimestamp(data.createTime, true)
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'processName',
                render: row => row ? <Typography>{row.processName}</Typography> : '',
                editable: 'never'
            }
            ,
            {
                title: '类型',
                field: 'processType',
                render: row => row ?
                    <Typography>{getNameByTypeAndCode(constants, 'PROCESS', row.processType)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '流程编码',
                field: 'processCode',
                render: row => row ? <Typography>{row.processCode}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ?
                    <Typography>{getNameByTypeAndCode(constants, 'STATUS', row.status)}</Typography> : '',
                editable: 'never'
            }
        ]
        return columns
    }

    // return (
    //     <div alignitems="center" className={classes.planitemroot}>
    //         <Grid className={classes.detailtable}>
    //             <MaterialTable
    //                 className={classes.materialtable}
    //                 title=''
    //                 columns={assemblycolumns()}
    //                 data={processes && processes.list}
    //                 options={{
    //                     pageSize: rowsPerPage,
    //                     tableLayout: 'auto',
    //                     actionsColumnIndex: -1,
    //                     search: false,
    //                     rowStyle: rowData => ({
    //                         backgroundColor: selectedRow === rowData.processId ? '#EEEEEE' : 'FFFFFF'
    //                     })
    //                 }}
    //                 onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
    //                 localization={localization}
    //                 style={{
    //                     border: '0px solid black',
    //                     boxShadow: 'none'
    //                 }}
    //                 components={{
    //                     Pagination: props => (
    //                         <TablePagination
    //                             {...props}
    //                             rowsPerPageOptions={[5, 10, 20]}
    //                             rowsPerPage={rowsPerPage}
    //                             count={processes && processes.total}
    //                             page={page}
    //                             onChangePage={(event, pageNum) => {
    //                                 setPage(pageNum);
    //                                 getProcessList({
    //                                     offset: pageNum * rowsPerPage,
    //                                     size: rowsPerPage
    //                                 })
    //                             }}
    //                             onChangeRowsPerPage={(event) => {
    //                                 props.onChangeRowsPerPage(event);
    //                                 setRowsPerPage(event.target.value);
    //                                 setPage(0);
    //                                 getProcessList({
    //                                     offset: 0,
    //                                     size: event.target.value
    //                                 })
    //                             }}
    //                         />
    //                     ),
    //                     Toolbar: props => (
    //                         <div>
    //                             <Grid container alignItems="center" className={classes.title}>
    //                                 <Typography variant="h3">
    //                                     {'流程列表'}
    //                                 </Typography>
    //                             </Grid>
    //                             <MTableToolbar {...props} />
    //                         </div>
    //                     )
    //                 }}
    //                 actions={checkPermission() ?
    //                     [rowData => ({
    //                         icon: 'edit',
    //                         tooltip: '编辑',
    //                         onClick: (event, rowData) => {
    //                             onDetailedInfo(event, rowData.processId)
    //                         }
    //                     }),
    //                     rowData => ({
    //                         icon: 'delete_outlined',
    //                         tooltip: '删除',
    //                         onClick: (event, rowData) => {
    //                             onDelete(event, rowData.processId, rowData.processName)
    //                         }
    //                     }),
    //                     {
    //                         icon: 'add',
    //                         tooltip: '添加流程',
    //                         isFreeAction: true,
    //                         onClick: (event) => onhandleCreate(event)
    //                     }
    //                     ] : [
    //                         rowData => ({
    //                             icon: 'description',
    //                             tooltip: '查看',
    //                             onClick: (event, rowData) => {
    //                                 // onView(event, rowData.userId)
    //                             }
    //                         })
    //                     ]}
    //             >
    //             </MaterialTable>
    //         </Grid>
    //     </div>
    // );
    return (
        <Card className={classes.card}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'流程列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={7}>
                    {/**/}
                </Grid>

                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={5}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={5}>
                        {/**/}
                    </Grid>

                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={7}>
                        {checkPermission() && <React.Fragment>
                            <Button variant='contained'
                                color='primary'
                                onClick={(event) => onhandleCreate(event)}
                                startIcon={<Add />}
                            >
                                新建
                            </Button>
                        </React.Fragment>

                        }
                    </Grid>
                </Grid>
            </Grid>

            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={processes && processes.list}
                        options={{
                            ...options,
                            pageSize: rowsPerPage,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: false,
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.processId ? '#EEEEEE' : 'FFFFFF'
                            })
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    rowsPerPage={rowsPerPage}
                                    count={processes && processes.total}
                                    page={page}
                                    onChangePage={(event, pageNum) => {
                                        setPage(pageNum);
                                        getProcessList({
                                            offset: pageNum * rowsPerPage,
                                            size: rowsPerPage
                                        })
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        setRowsPerPage(event.target.value);
                                        setPage(0);
                                        getProcessList({
                                            offset: 0,
                                            size: event.target.value
                                        })
                                    }}
                                />
                            ),
                            Toolbar: props => (
                                <div>

                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }}
                        actions={checkPermission() ?
                            [rowData => ({
                                icon: () => <Edit color={'primary'} />,
                                tooltip: '编辑',
                                onClick: (event, rowData) => {
                                    onDetailedInfo(event, rowData.processId)
                                }
                            }),
                            rowData => ({
                                icon: () => <DeleteOutlined className={classes.menuIcon} />,
                                tooltip: '删除',
                                onClick: (event, rowData) => {
                                    onDelete(event, rowData.processId, rowData.processName)
                                }
                            }),
                                // {
                                //     icon: 'add',
                                //     tooltip: '添加流程',
                                //     isFreeAction: true,
                                //     onClick: (event) => onhandleCreate(event)
                                // }
                            ] : [
                                rowData => ({
                                    icon: () => <Description className={classes.menuIcon} />,
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        // onView(event, rowData.userId)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>
        </Card>
    );
};

ProcessList.propTypes = {
    classes: PropTypes.object.isRequired,
    processes: PropTypes.object.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    getProcessList: PropTypes.func.isRequired,
    constants: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired
};

export default withStyles(ProcessList);
