import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';
import * as _ from 'lodash';

const JobEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        companySelection,
        currentJob,
        jobError,
        jobChange,
        companyList
    } = props;

    const renderCompany = (id) => {
        let company = _.find(companyList,{companyId:id});
        return company.companyName
    }

    return (
        <div>
            <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                <Typography variant="h3" >
                    {currentJob.jobId ? "编辑职务" : "新建职务"}
                </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={8}>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                所属单位
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                                {companySelection? renderCompany(companySelection.value): renderCompany(currentJob.companyId)}
                            </Typography>
                        </Grid>
                    </Grid>

                {currentJob.jobId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                职务编号
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                                {currentJob.jobId}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography  className={classes.label}>
                            职务名称
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                        <TextField
                            error={jobError.jobName && jobError.jobName !== ''}
                            helperText={jobError.jobName || ''}
                            className={classes.textField}
                            value={currentJob.jobName || ''}
                            variant="outlined"
                            size="small"
                            onChange={event => onhandleChange(event, 'jobName')}
                            onBlur={event => onhandleCheck(event, 'jobName')}
                        />
                    </Grid>
                </Grid>

                {currentJob.jobId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                创建时间
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                                {getDateByTimestamp(currentJob.createTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentJob.jobId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                创建人
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                            {currentJob.creatorLastName + currentJob.creatorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentJob.jobId && currentJob.updateTime ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                修改时间
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                                {getDateByTimestamp(currentJob.updateTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentJob.jobId && currentJob.revisor ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography  className={classes.label}>
                                修改人
                            </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography  className={classes.label}>
                            {currentJob.revisorLastName + currentJob.revisorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography  className={classes.label}>
                            职务简介
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentJob.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>
              </Grid>


            <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        disabled={!jobChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                </Button>
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                </Button>

            </Grid>
            </Card>
            <Prompt message="数据未保存,确定要离开？"  when={jobChange}/>
        </div>
    );
};

JobEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentJob: PropTypes.object.isRequired,
    jobError: PropTypes.object.isRequired,
    jobChange: PropTypes.bool.isRequired,
    companySelection: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired
};

export default withStyles(JobEdit);
