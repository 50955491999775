import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';
import { getNameByTypeAndCode } from '../../../../utils/constant';

const BuEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        currentBu,
        buError,
        buChange,
        constants
    } = props;

    return (
        <div>
            <Card className={classes.card}>

                <Typography variant="h3" gutterBottom>
                    {currentBu.unitId ? "编辑业务分类" : "新建业务分类"}
                </Typography>

                {currentBu.unitId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                            编号
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {currentBu.unitId}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        名称
                            </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            error={buError.unitName && buError.unitName !== ''}
                            helperText={buError.unitName || ''}
                            className={classes.textField}
                            value={currentBu.unitName || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'unitName')}
                            onBlur={event => onhandleCheck(event, 'unitName')}
                        />
                    </Grid>
                </Grid>

                 {currentBu.unitId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  className={classes.label}>
                                状态
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  className={classes.label}>
                                {getNameByTypeAndCode(constants, 'STATUS', currentBu.status)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentBu.unitId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {getDateByTimestamp(currentBu.createTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentBu.unitId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                            {currentBu.creatorLastName + currentBu.creatorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentBu.unitId && currentBu.updateTime ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                修改时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                                {getDateByTimestamp(currentBu.updateTime,true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentBu.unitId && currentBu.revisor ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography  gutterBottom>
                                修改人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography  gutterBottom>
                            {currentBu.revisorLastName + currentBu.revisorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom>
                        简介
                            </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentBu.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={!buChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                </Button>
                </Grid>

            </Grid>
            <Prompt message="数据未保存,确定要离开？"  when={buChange}/>
        </div>
    );
};

BuEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentBu: PropTypes.object.isRequired,
    buError: PropTypes.object.isRequired,
    buChange: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired
};

export default withStyles(BuEdit);
