import { withStyles } from '@material-ui/styles'

const styles = theme => ({

    title: {
        size: theme.typography.h3
    },
    contentGrid:{
        flexDirection:'column'
    },
    itemGrid:{
        paddingBottom: theme.spacing(2),
    }

})

export default withStyles(styles);
