import React, { Component } from 'react';
import { connect } from 'react-redux';
import TemporaryRegulationPlanInfo from '../../../../components/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanInfo';
import {
    initTrp,
    createTrp,
    setCurrentTrp,
    backToTrpList,
    setTrpError,
    setTrpChange,
    createTrpProcessRecord//,
    //createTrpProcess
} from "../../../../redux/modules/leadershipRegulationPlan";
import { clearSchemeMap, routeToHazardInfo } from "@/redux/modules/inspection/hazard";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import moment from 'moment';

const currentYear = parseInt(moment().format("YYYY"));
class TemporaryRegulationPlanInfoContainer extends Component {

    static propTypes = {
        currentTrp: PropTypes.object,
        TrpError: PropTypes.object,
        //constants: PropTypes.object,
        TrpChange: PropTypes.bool,
        TrpProcessRecord: PropTypes.object
    };

    componentDidMount() {
        // 初始化表单数据
        this.props.onhandleInitTrp();
    }

    handleSave = async (event) => {
        const {
            onhandleTrpChange,
            onhandleCreateTrp,
            TrpError,
            currentTrp,
            schemeMap,
            userProfile
        } = this.props
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(TrpError), function (o) { return o !== ''; }) < 0) {

            onhandleTrpChange(false);
            let unitId = userProfile.roles[0].units[0].unitId;
            let planCode = 'RegulationPlan_' + unitId
            let tempRegulationPlan = {
                ...currentTrp,
                planCode: planCode,
                planType: schemeMap && schemeMap.schemeType && schemeMap.schemeType === "annual" ? "SCHEME" : "TEMP",
                year: currentYear,
                planId: 0
            }
            onhandleCreateTrp(tempRegulationPlan, (resData) => {
            })
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleTrpChange(true);
        let newTrp;
        switch (field) {
            case 'planName':
                newTrp = {
                    ...this.props.currentTrp,
                    planName: event.target.value
                };
                this.props.onhandleUpdateEditTrp(newTrp);
                break;
            case 'opentext':
                newTrp = {
                    ...this.props.currentTrp,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditTrp(newTrp);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        if (this.props.schemeMap && this.props.schemeMap.hid) {
            this.props.onhandleBackToHazardList(this.props.schemeMap.hid);
        } else {
            this.props.onhandleBackToTrpList();
        }
    };

    handleCheck = (event, field) => {
        if (!field || field === 'planName') {
            if (_.trim(this.props.currentTrp.planName) === '') {
                let err = {
                    ...this.props.TrpError,
                    planName: '临时计划名称不能为空'
                };
                this.props.onhandleSetError(err);
            } else {
                let err = {
                    ...this.props.TrpError,
                    planName: ''
                };
                this.props.onhandleSetError(err);
            }
        }
    };

    render() {
        return (
            <TemporaryRegulationPlanInfo
                onhandleSave={this.handleSave.bind(this)}
                currentTrp={this.props.currentTrp}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                TrpError={this.props.TrpError}
                TrpChange={this.props.TrpChange}
                schemeMap={this.props.schemeMap}
                constant={this.props.constant}
            />
        )
    }

}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentTrp: state.leadershipRegulationPlan.currentTrp,
        TrpError: state.leadershipRegulationPlan.TrpError,
        TrpChange: state.leadershipRegulationPlan.TrpChange,
        TrpProcessRecord: state.leadershipRegulationPlan.TrpProcessRecord,
        schemeMap: state.hazard.schemeMap
        //constant: state.constant
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitTrp: () => {
            dispatch(initTrp(true));
        },
        onhandleCreateTrp: (TemporaryRegulationPlan, callback) => {
            dispatch(createTrp(TemporaryRegulationPlan, callback))
        },//createTrpProcessRecord
        onhandleCreateTrpProcessRecord: (TrpProcessRecord, callback) => {
            dispatch(createTrpProcessRecord(TrpProcessRecord, callback))
        },//createTrpProcess
        //onhandleCreateTrpProcess: (trpProcess) => {
        //   dispatch(createTrpProcess(trpProcess))
        //},
        onhandleUpdateEditTrp: (entity) => {
            dispatch(setCurrentTrp(entity))
        },
        onhandleBackToTrpList: () => {
            dispatch(backToTrpList())
        },
        onhandleBackToHazardList: (hid) => {
            dispatch(routeToHazardInfo(hid, 'edit'));
        },
        onhandleSetError: (err) => {
            dispatch(setTrpError(err))
        },
        onhandleTrpChange: (isChanged) => {
            dispatch(setTrpChange(isChanged))
        },
        onhandleClearSchemeMap: () => {
            dispatch(clearSchemeMap());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemporaryRegulationPlanInfoContainer)
