import React, { Component } from 'react';
import { connect } from 'react-redux';
import RoleInfo from '../../../components/SystemManagement/RoleManagement/RoleInfo'
import {
    createRole,
    updateRole,
    setCurrentRole,
    backToRoleList,
    setRoleError,
    setRoleChange,
    initRoutePermission,
    setRoleRouteChange,
    setRoutes,
    saveRoleRoutes
} from "../../../redux/modules/role";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class RoleEditContainer extends Component {

    static propTypes = {
        currentRole: PropTypes.object,
        roleError: PropTypes.object,
        roleChange: PropTypes.bool,
        roleRouteChange: PropTypes.bool
    };

    handleRouteTypeSelectChange = (event, data)=>{
        this.props.onhandleRoleRouteChange(true);
        let newRoleRoute = [...this.props.routes];
        // console.log(data)
        newRoleRoute.forEach(element=>{
            if (element.routeId === data.routeId){
                // 设置action
                element['action'] = event.target.value
                // 如果记录原来不存在recordType就设置为C，否则为U
                element['recordType'] = element.exist?'U':'C'
                return
            }
        })
        this.props.onhandleUpdateRoutes(newRoleRoute);
    }

    handleRouteSelectionChange = (event, data)=>{
        this.props.onhandleRoleRouteChange(true);
        let newRoleRoute = [...this.props.routes];
        newRoleRoute.forEach(element=>{
            if (element.routeId === data.routeId){
                if (event.target.checked){
                    if (!element.exist){
                        if (!element.action){
                            element['action'] = 'V'
                        }
                        element['recordType'] = 'C'
                    }else{
                        element['recordType'] = 'U'
                    }
                }else{
                    element['action'] = undefined
                    if (element.exist){
                        element['recordType'] = 'D'
                    }else{
                        element['recordType'] = undefined
                    }
                }
                return
            }
        });
        this.props.onhandleUpdateRoutes(newRoleRoute);
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.roleError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleRoleChange(false);
            if (this.props.currentRole.roleId) {
                this.props.onhandleUpdateRole();
            } else {
                this.props.onhandleCreateRole();
            }
        }
    }

    handleSelect = (value, field) => {
        this.props.onhandleRoleChange(true);
        let newRole;
        if (field === 'roleLevel') {
            newRole = {
                ...this.props.currentRole,
                roleLevel: parseInt(value.target.value)
            };
            this.props.onhandleUpdateEntity(newRole);
        }else if (field === 'jobId') {
            newRole = {
                ...this.props.currentRole,
                jobId: parseInt(value.target.value)
            };
            this.props.onhandleUpdateEntity(newRole);
        }else if (field === 'group') {
            newRole = {
                ...this.props.currentRole,
                groupId: parseInt(value.target.value)
            };
            this.props.onhandleUpdateEntity(newRole);
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleRoleChange(true);
        let newRole;
        switch (field) {
            case 'roleName':
                newRole = {
                    ...this.props.currentRole,
                    roleName: event.target.value
                };
                this.props.onhandleUpdateEntity(newRole);
                break;
            case 'opentext':
                newRole = {
                    ...this.props.currentRole,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEntity(newRole);
                break;
            default:
            // do nothing
        }
    };

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'roleName') {
            if (_.trim(this.props.currentRole.roleName) === '') {
                err['roleName'] = '角色名称不能为空';
            } else {
                err['roleName'] = '';
            }
        }
        let newError = {
            ...this.props.roleError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <RoleInfo
                onhandleSave={this.handleSave.bind(this)}
                onhandleSaveRoleRoute={this.props.onhandleSaveRoleRoute.bind(this)}
                currentRole={this.props.currentRole}
                jobs={this.props.jobs}
                routes={this.props.routes}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.props.onhandleBackToRoleList.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleSelectionChange={this.handleSelect.bind(this)}
                roleError={this.props.roleError}
                roleChange={this.props.roleChange}
                roleRouteChange={this.props.roleRouteChange}
                companySelection={this.props.companySelection}
                roleGroupList={this.props.roleGroupList}
                onhandleInitRoutePermission={this.props.onhandleInitRoutePermission.bind(this)}
                onhandleRouteTypeSelectChange={this.handleRouteTypeSelectChange.bind(this)}
                onhandleRouteSelectionChange={this.handleRouteSelectionChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentRole: state.role.currentRole,
        roleError: state.role.roleError,
        roleChange: state.role.roleChange,
        companySelection: state.role.companySelection,
        roleGroupList: state.role.roleGroupList,
        jobs: state.constant.jobs,
        routes: state.role.routes,
        roleRouteChange: state.role.roleRouteChange
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateRole: () => {
            dispatch(createRole())
        },
        onhandleUpdateRole: () => {
            dispatch(updateRole())
        },
        onhandleUpdateEntity: (entity) => {
            dispatch(setCurrentRole(entity))
        },
        onhandleBackToRoleList: () => {
            dispatch(backToRoleList())
        },
        onhandleSetError: (err) => {
            dispatch(setRoleError(err))
        },
        onhandleRoleChange: (isChanged) => {
            dispatch(setRoleChange(isChanged))
        },
        onhandleInitRoutePermission: ()=> {
            dispatch(initRoutePermission())
        },
        onhandleRoleRouteChange: (isChanged) => {
            dispatch(setRoleRouteChange(isChanged))
        },
        onhandleUpdateRoutes: (entity) => {
            dispatch(setRoutes(entity))
        },
        onhandleSaveRoleRoute: ()=>{
            dispatch(saveRoleRoutes())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleEditContainer)
