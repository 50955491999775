import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { createhttpMessage } from './message';

const defaultState = {
    isloading: false,
    users: [],
    currentUser: {},
}

export const setLoading = createAction('@@xcloud/usercard/setLoading');

export const setCurrentUser = createAction('@@xcloud/usercard/setCurrentUser');

export const setUsers = createAction('@@xcloud/usercard/setUsers');

export const getUser = createAction('@@xcloud/usercard/getUser',
    (userId) => (dispatch, getState, httpClient) => {
        dispatch(setLoading(true));
        let users = getState().usercard.users;
        let user = _.find(users, ['userId', userId]);
        if (user) {
            dispatch(setCurrentUser(user));
            dispatch(setLoading(false));
        } else {
            httpClient.get('/api/users/' + userId)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setCurrentUser(res.data));
                        dispatch(setUsers(res.data));
                        dispatch(setLoading(false));
                    }
                }).catch(err => {
                    let msg = `获取用户信息失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    dispatch(setLoading(false));
                    return []
                })
        }
    }
);

export const usercardReducer = handleActions(
    {
        [setLoading]: (state, { payload: value }) => {
            return {
                ...state,
                isloading: value
            };
        },
        [setCurrentUser]: (state, { payload: value }) => {
            return {
                ...state,
                currentUser: value
            };
        },
        [setUsers]: (state, { payload: value }) => {
            return {
                ...state,
                users: [...state.users, value]
            };
        },
    },
    defaultState
)

export default usercardReducer;