// import React, { useState } from 'react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  // Breadcrumbs,
  // Link,
} from '@material-ui/core';
import withStyles from './styles';
// import TodoPage from './Todo';
import NewsPage from '../NewsPage';
import Dashboard from '../Dashboard';
// import AlarmPannel from '../AlarmPannel';
// import Draggable from 'react-draggable';
import TodoContainer from '@/containers/Home/TodoContainer';

const Home = props => {
  const { classes
  } = props;

  // const [todoCount] = useState(5);
  // const [dragCard_grabbing, setDragCard_grabbing] = useState(false)

  function generateSubpage() {
    let pathname = props.location.pathname
    if (pathname === '/News')
      return (<NewsPage title='new page' />)
    else if (pathname === '/Dashboard')
      return (<Dashboard title='dashboard page' />)
    else
      return (<TodoContainer title='todo page' />)
  }

  // function handleStart(e) {
  //   setDragCard_grabbing(true)
  // }
  // function handleStop(e) {
  //   setDragCard_grabbing(false)
  // }

  return (
    <div alignitems="center" className={classes.root}>
      <Grid container >
        <Grid item xs={12}>
          {/* <Breadcrumbs separator="|" aria-label="breadcrumb" className={classes.nav}>
            <Link href="/home" className={classes.navlink} activeclassname={classes.active}>
              待办事项
              {todoCount !== 0 ? "(" + todoCount + ")" : ""} 
            </Link>
            <Link href="/Dashboard" className={classes.navlink} activeclassname={classes.active} >
              Dashboard
            </Link>
            <Link href="/News" className={classes.navlink} activeclassname={classes.active} >
              新闻公告
            </Link> 
          </Breadcrumbs> */}
        </Grid>

        <Grid item xs={9}>
          {generateSubpage()}
        </Grid>

        <Grid item xs={3}>
          {/* <Draggable onStart={handleStart} onStop={handleStop}>
            <div className={dragCard_grabbing ? classes.dragCard_grabbing : classes.dragCard}><AlarmPannel /></div>
          </Draggable> */}
        </Grid>
      </Grid>
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(Home));
