import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProdServiceReportDetailPanel from '@/components/ManufactureManagement/ProdReport/ProdServiceReportDetailPanel';
import PropTypes from 'prop-types';
import { getMoment, formatDay } from '@/utils/datetime';
import { getAllDepartments } from '@/redux/modules/department'
import _ from 'lodash'

class ProdServiceReportDetailPanelContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    // 页面初期化获取数据
    componentDidMount() {
        this.props.getAllDepartments({ offset: 0, size: 0 })
    }

    constructor(props) {
        super(props)
        this.state = {
            titleDataList: []
        }

    }

    getOrgName = () => {
        const { companyId, companies } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === companyId
        });
        if (!company) {
            return ''
        }
        if (company && company.pid) {
            return company.companyAlias
        }
        return (company && company.companyName) ? company.companyName : '全体单位'
    }

    // 动态表头
    getHeadDataList = () => {
        const { timeDim, date, companyId, companies } = this.props;
        let headList = [];
        if (Boolean(companyId)) {
            // 部门
            headList = this.getDpts(timeDim, date, companyId);
            const com = _.find(companies, { companyId: companyId })
            headList.unshift({ key: 0, name: com ? com.companyAlias : '总计' })
        } else {
            // 分厂
            headList = companies.map(
                company => ({ key: company.companyId, name: company.companyAlias })
            )
            const rootCompany = _.find(companies, { pid: null })
            headList.unshift({ key: 0, name: rootCompany ? rootCompany.companyName : '总计' })
        }
        return headList;
    }

    getDpts(timeDim, date, companyId) {
        let dpts = [];
        if (!date) {
            date = getMoment()();
        }
        if (!companyId) {
            companyId = this.props.currentUserInfo.companyId;
        }
        dpts = _.filter(this.props.departments, d => {
            return d.companyId === companyId
                && d.amoebaType !== 'OTHER'
        })

        //添加部门inactive的判断
        if (timeDim === 'day') {
            const day = formatDay(date);
            dpts = _.filter(dpts, d => {
                return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= day)) ||
                    (!d.endDate || (formatDay(d.startDate) <= day && formatDay(d.endDate) >= day))
            })
        }
        if (timeDim === 'month') {
            const startDay = getMoment()(date).startOf('month').format("YYYY/MM/DD");
            const endDay = getMoment()(date).endOf('month').format("YYYY/MM/DD");
            dpts = _.filter(dpts, d => {
                return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= endDay)) ||
                    (!d.endDate || (formatDay(d.startDate) <= endDay && formatDay(d.endDate) >= startDay))
            })
        }
        if (timeDim === 'year') {
            const startDay = getMoment()(date).startOf('year').format("YYYY/MM/DD");
            const endDay = getMoment()(date).endOf('year').format("YYYY/MM/DD");
            dpts = _.filter(dpts, d => {
                return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= endDay)) ||
                    (!d.endDate || (formatDay(d.startDate) <= endDay && formatDay(d.endDate) >= startDay))
            })
        }
        return dpts.map(dpt => ({ key: dpt.dptId, name: dpt.dptName }));
    }

    handleClose = () => {
        this.props.onhandleClose();
    }

    render() {
        return (
            <ProdServiceReportDetailPanel
                date={this.props.date}
                orgName={this.getOrgName()}
                headDataList={this.getHeadDataList()}
                prodReportServiceOrgList={this.props.prodReportServiceOrgList}
                outputCheckList={this.props.outputCheckList}
                isShowService={this.props.isShowService}
                timeDim={this.props.timeDim}
                prodReportList={this.props.prodReportList}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // prodReportServiceOrgList: state.manufactureProdReport.prodReportServiceOrgList,
        companies: state.constant.companies,
        departments: state.department.allDepartments.list,
        currentUserInfo: state.auth.currentUserInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllDepartments: (query) => {
            return dispatch(getAllDepartments(query));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProdServiceReportDetailPanelContainer);
