import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RouteListComponent from '../../../../components/OnsiteInspectionManagement/RouteList/RouteListComponent';
import {
    getRouteList,
    deleteRoute,
    initRouteListPage,
    setSearch,
    resetSearch,
    initCreate,
    initEdit,
    copyInitEdit,
    initView
} from '../../../../redux/modules/onsiteInspection';
import { openConfirmDialog } from '../../../../redux/modules/confirmDialog';
import { getCompanyId } from "../../../../utils/userUtils";
import { getTreePartById } from '../../../../utils/constant';
import * as _ from 'lodash';
// import { LeakAddTwoTone } from '_@material-ui_icons@4.9.1@@material-ui/icons';

class OnsiteInspectionRouteListContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    UNSAFE_componentWillMount() {
        this.props.onhandleInitPage(this.props.query, true);
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'inspectionType':
                newSearch = {
                    ...this.props.search,
                    inspectionType: value.target.value,
                    routeId: ""
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'routeId':
                newSearch = {
                    ...this.props.search,
                    routeId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'statusCode':
                newSearch = {
                    ...this.props.search,
                    statusCode: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSearch(event) {
        this.props.onhandleSearch({ offset: 0, size: 15, sort: "-update_time" });
    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'keyword':
                newSearch = {
                    ...this.props.search,
                    keyword: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    handleDeleteRoute = (event, route) => {
        const { routeName, statusCode } = route;

        let actionTitle = '删除确认';
        let actionMsg = '确定要删除巡检路线[' + routeName + ']吗?';

        if (statusCode && statusCode === 'Published') {
            actionTitle = '废止确认';
            actionMsg = '确定要废止巡检路线 [' + routeName + '] 吗？';
        }

        let changeStatus = () => {
            this.props.onhandleDeleteRoute(route);
        }
        this.props.onhandleOpenConfirmDialog(actionTitle, actionMsg, changeStatus, null, false);
    }

    handleCreate(event) {
        this.props.onhandleCreate();
    }

    handleEdit(event, route) {
        this.props.onhandleEdit(route);
    }

    handleView(event, route) {
        this.props.onhandleView(route);
    }

    handleCopy(event, route) {
        this.props.onhandleCopy(route);
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    render() {
        return (
            <div>
                <RouteListComponent
                    userProfile={this.props.userProfile}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    routeList={this.props.routeList}
                    inspectionRouteList={this.props.inspectionRouteList}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    search={this.props.search}
                    onhandleDeleteRoute={this.handleDeleteRoute.bind(this)}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                    getRouteList={this.props.onhandleSearch}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleEdit={this.handleEdit.bind(this)}
                    onhandleView={this.handleView.bind(this)}
                    onhandleCopy={this.handleCopy.bind(this)}
                    onSizeChange={this.handleListSize.bind(this)}
                    processStatus={this.props.processStatus}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        routeList: state.onsiteInspection.routeList,
        inspectionRouteList: state.onsiteInspection.inspectionrRouteList,
        search: state.onsiteInspection.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        processStatus: state.constant.processStatus,
        query: state.router.location.query,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleDeleteRoute: (data) => {
            dispatch(deleteRoute(data));
        },
        onhandleInitPage: (query, reload) => {
            dispatch(initRouteListPage(query, reload));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSearch: () => {
            dispatch(resetSearch());
        },
        onhandleSearch: (query) => {
            dispatch(getRouteList(query));
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleEdit: (route) => {
            dispatch(initEdit(route));
        },
        onhandleView: (route) => {
            dispatch(initView(route));
        },
        onhandleCopy: (rowData) => {
            dispatch(copyInitEdit(rowData));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionRouteListContainer);