import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AmoebaBudgetComponent from "@/components/AmoebaManagement/Plan/Budget";
import { createloading, cleanloading } from '@/redux/modules/loading';
import { addPlan, getBudgets, updateDailyPlanBatch } from '@/redux/modules/amoeba/amoebaPlan';
import { getYear, getMonth, getMoment } from '@/utils/datetime';
import { getRole, getDepartmentsByRole } from "@/utils/userUtils";
import { MONTH_PLAN_VIEW_ORGVIEW, MONTH_PLAN_VIEW_DAYVIEW } from '@/utils/amoebaUtils';
import { arrayToTree, filterAmoebaCompany } from "@/utils/constant";

import * as _ from 'lodash';

class MonthBudgetContainer extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props)
        this.state = {
            viewType: MONTH_PLAN_VIEW_ORGVIEW,
            selectedDate: getMoment()(),
            selectedCompanyId: 0,
            selectedDptId: 0,
            year: getYear(),
            month: getMonth() + 1,
            tableHeaders: [],
            tableData: [],
            roleW: false,
            myDepartment: {},
        }
    }


    componentDidMount() {
        const { roleRoutes, currentUserInfo, userProfile } = this.props
        const { year, month } = this.state
        const route = _.find(roleRoutes, { 'route': 'amoeba_plan' })
        const roleW = route ? route.action === 'W' : false
        const viewType = roleW ? MONTH_PLAN_VIEW_ORGVIEW : MONTH_PLAN_VIEW_DAYVIEW
        const myDepartments = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
        const myDepartment = myDepartments && !_.isEmpty(myDepartments) ? myDepartments[0] : {}
        this.setState({
            selectedCompanyId: roleW && this.isRootCompany(currentUserInfo.companyId) ? 0 : currentUserInfo.companyId,
            selectedDptId: roleW ? 0 : myDepartment.dptId,
            roleW: roleW,
            viewType: viewType,
            myDepartment: myDepartments && !_.isEmpty(myDepartments) ? myDepartments[0] : {}
        }, () => {
            this.handleFreshView(year, month, roleW)
        })

    }

    isRootCompany(companyId) {
        const rootCompany = _.find(this.props.companyList, (c) => {
            return c.companyId === companyId && !c.pid
        })
        return rootCompany ? true : false
    }


    handleFreshView(year, month, roleW) {
        const { onhandleGetBudgets } = this.props
        const { selectedCompanyId, selectedDptId, viewType } = this.state

        const planType = viewType === MONTH_PLAN_VIEW_ORGVIEW ? 'DAYMONTH' : 'DAY'
        if (roleW && !selectedDptId) {
            onhandleGetBudgets(selectedCompanyId, null, year, month, planType).then((data) => {
                this.setState({
                    tableHeaders: this.getTableHeaders(),
                    tableData: this.getAssemblyData(),
                })
            })
        } else {
            onhandleGetBudgets(selectedCompanyId, selectedDptId, year, month, planType).then((data) => {
                this.setState({
                    tableHeaders: this.getTableHeaders(),
                    tableData: this.getAssemblyData(),
                })
            })
        }
    }

    getTableHeaders() {
        //每天header的key的格式为：companyId_dptId_year_month_day
        let headers = []
        const { roleRoutes, departmentList, companyList } = this.props
        const { viewType, year, month, selectedDate, selectedCompanyId, selectedDptId } = this.state
        const route = _.find(roleRoutes, { 'route': 'amoeba_plan' })
        const dayCount = selectedDate.daysInMonth()
        if (route && route.action === 'W' && !selectedDptId) {
            let selectedCompany = _.find(companyList, { 'companyId': selectedCompanyId });
            let secondHeader = {//第二列汇总列
                key: 'null_null_' + year + '_' + month + '_null',
                title: '工程公司'
            }
            if (selectedCompany) {
                secondHeader.key = selectedCompany.companyId + '_null_' + year + '_' + month + '_null'
                secondHeader.title = selectedCompany.companyAlias
            }
            headers.push(
                secondHeader
            )
            if (viewType === MONTH_PLAN_VIEW_ORGVIEW) { //部门视图
                if (selectedCompanyId === 0) { //总公司
                    const clildCompanies = companyList //各分厂和机关
                    _.map(clildCompanies, c => {
                        headers.push({
                            key: c.companyId + '_null_' + year + '_' + month + '_null',
                            title: c.companyAlias,
                        })
                    })
                } else { //分公司
                    const clildDepartment = _.filter(departmentList, d => {
                        return d.companyId === selectedCompanyId && d.amoebaType !== 'OTHER'
                    })
                    _.map(clildDepartment, d => {
                        headers.push({
                            key: d.companyId + '_' + d.dptId + '_' + year + '_' + month + '_null',
                            title: d.dptName,
                        })
                    })
                }
            } else { //月视图

                for (let i = 1; i <= dayCount; i++) {
                    headers.push({
                        key: (selectedCompanyId ? selectedCompanyId : 'null') + '_null_' + year + '_' + month + '_' + i,
                        title: i + '日'
                    })
                }
            }
        } else { //单部门
            if (selectedDptId) { //第二列汇总列
                const selectDpt = _.find(departmentList, { 'dptId': selectedDptId })
                headers.push({
                    key: selectedCompanyId + '_' + selectedDptId + '_' + year + '_' + month + '_null',
                    title: selectDpt ? selectDpt.dptName : '',
                })
            }

            //只有月视图
            for (let i = 1; i <= dayCount; i++) {
                headers.push({
                    key: selectedCompanyId + '_' + selectedDptId + '_' + year + '_' + month + '_' + i,
                    title: i + '日'
                })
            }
        }

        return headers
    }

    getAssemblyData() {
        const { subs, budgets } = this.props;

        if (_.isEmpty(budgets) || _.isEmpty(subs)) {
            return []
        }

        let data = _.cloneDeep(_.filter(subs, { 'isPlan': true }));

        for (const budget of budgets) {
            let subItem = _.find(data, { 'subId': budget.subId })
            let key = budget.companyId + '_' + budget.dptId + '_' + budget.year + '_' + budget.month + '_' + budget.day
            subItem[key] = budget.amount
        }

        return arrayToTree(data, 'subId', 'subParentId')
    }

    handleBudgetClick() {
        const { updateDailyPlanBatch, currentUserInfo } = this.props;
        const { year, month, roleW } = this.state;

        let planParam = {
            companyId: currentUserInfo.companyId,
            year: year,
            month: month,
        }

        updateDailyPlanBatch(year, month, planParam).then(() => {
            this.handleFreshView(year, month, roleW)
        })
    }

    handleViewChange(event) {
        const { roleW, year, month } = this.state;
        const viewType = event.target.value;
        this.setState({ 'viewType': viewType }, () => {
            this.handleFreshView(year, month, roleW)
        })
    }

    handleDateChange = (date) => {
        const { roleW, year, month } = this.state;
        const selectDate = getMoment()(date)
        if (year !== selectDate.year() && (month === selectDate.month() + 1)) {
            return
        }
        this.setState({
            selectedDate: selectDate,
            year: selectDate.year(),
            month: selectDate.month() + 1
        }, () => {
            this.handleFreshView(this.state.year, this.state.month, roleW)
        })
    }
    handleOrgChange = (event) => {
        const { roleW, year, month } = this.state;
        let changeState = {}
        if (event.target.name === 'companyId') {
            changeState.selectedCompanyId = Number(event.target.value)
            changeState.selectedDptId = 0
        } else if (event.target.name === 'dptId') {
            changeState.selectedDptId = Number(event.target.value)
        }
        this.setState(changeState, () => {
            this.handleFreshView(year, month, roleW)
        })
    }

    handleClose = () => {
        this.setState({
            openFlag: false
        })
    }

    render() {

        const params = {
            dateViews: ['year', 'month'],
            dateFormat: 'yyyy/MM',
            dateString: this.state.year + '年' + this.state.month + '月',
            viewTypeList: [
                {
                    code: MONTH_PLAN_VIEW_ORGVIEW,
                    name: '部门视图',
                },
                {
                    code: MONTH_PLAN_VIEW_DAYVIEW,
                    name: '日视图'
                },
            ]
        }

        return (
            <AmoebaBudgetComponent
                userProfile={this.props.userProfile}
                year={this.state.year}
                roleW={this.state.roleW}
                selectedDate={this.state.selectedDate}
                params={params}
                selectedCompanyId={this.state.selectedCompanyId}
                selectedDptId={this.state.selectedDptId}
                viewType={this.state.viewType}
                tableHeaders={this.state.tableHeaders}
                companyList={this.props.companyList}
                departmentList={this.props.departmentList}
                tableData={this.state.tableData}
                currentUserInfo={this.props.currentUserInfo}
                onhandleClick={this.handleBudgetClick.bind(this)}
                onhandleViewChange={this.handleViewChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleOrgChange={this.handleOrgChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companyList: filterAmoebaCompany(state.company.companyList.list),
        departmentList: state.department.departments.list,
        subs: state.amoebaIndex.subs,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        userProfile: state.auth.userProfile,
        budgets: state.amoebaPlan.budgets,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAmoebaPlan: (plan) => {
            dispatch(addPlan(plan));
        },
        onhandleGetBudgets: (companyId, dptId, year, month, planType) => {
            return dispatch(getBudgets(companyId, dptId, year, month, null, planType))
        },
        updateDailyPlanBatch: (year, month, planParam) => {
            return dispatch(updateDailyPlanBatch(year, month, planParam));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MonthBudgetContainer))