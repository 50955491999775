import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getTrends,
    setStartDate,
    setEndDate
} from '@/redux/modules/amoeba/reportTrend';
import TrendsComponent from '@/components/AmoebaManagement/Report/Trends/TrendsComponent';
import { closeTrends } from '@/redux/modules/amoeba/reportTrend';
import { getMoment as moment } from '@/utils/datetime';
import { filterAmoebaCompany } from "@/utils/constant";
import _ from 'lodash'

import PropTypes from 'prop-types';

class ReportTrendsContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    renderTitle = () => {
        const {
            timeDim,
            subInfo
        } = this.props;
        let subName = subInfo.subName || '指标'

        let timeDimStr = '趋势图';
        if (timeDim === 'DAY') { timeDimStr = '日趋势图' }
        if (timeDim === 'MONTH') { timeDimStr = '月趋势图' }
        if (timeDim === 'YEAR') { timeDimStr = '年趋势图' }
        return subName + ' - ' + timeDimStr
    }

    handleEchartOption = () => {
        // 图配置逻辑
        const {
            orgDim,
            companyIds,
            timeDim,
            trends,
            subInfo,
            companies,
            departments,
            startDate,
            endDate,
        } = this.props;
        // let subName = subInfo.subName || '指标'

        let xAxisData = [];
        const indexMoment = moment()(startDate);
        const endMoment = moment()(endDate);
        while (indexMoment.isSameOrBefore(endMoment)) {
            let xAxisCoord = '';
            if (timeDim === 'DAY') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'days');
            }
            if (timeDim === 'MONTH') {
                xAxisCoord = indexMoment.format('YYYY-MM');
                indexMoment.add(1, 'months');
            }
            if (timeDim === 'YEAR') {
                xAxisCoord = indexMoment.format('YYYY');
                indexMoment.add(1, 'years');
            }
            xAxisData.push(xAxisCoord);
        }
        xAxisData = _.uniq(xAxisData);

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 加总
        series.push({
            seriesId: 0,
            name: '总计',
            type: 'line',
            data: [...Array(xAxisData.length)].map(_ => 0),
            emphasis: emphasis,
        })
        legendData.push('总计')
        // 子组织(组织标识符seriesId)
        if (orgDim === 'ALLCOMPANY') {
            companies.forEach(comp => {
                series.push({
                    seriesId: comp.companyId,
                    name: comp.companyAlias,
                    type: 'line',
                    data: [...Array(xAxisData.length)].map(_ => 0),
                    emphasis: emphasis,
                })
                legendData.push(comp.companyAlias)
            })
        }
        if (orgDim === 'COMPANY') {
            departments.forEach(dpt => {
                if (String(companyIds) && String(companyIds).includes(dpt.companyId)) {
                    series.push({
                        seriesId: dpt.dptId,
                        name: dpt.companyDptName,
                        type: 'line',
                        data: [...Array(xAxisData.length)].map(_ => 0),
                        emphasis: emphasis,
                    })
                    legendData.push(dpt.companyDptName)
                }
            })
        }

        if (trends && trends.length) {
            trends.forEach(trend => {
                const strYear = String(trend.year).padStart(4, '0');
                const strMonth = String(trend.month).padStart(2, '0');
                const strDay = String(trend.day).padStart(2, '0');
                // 当前数据所属横坐标
                let xAxisCoord = '';
                if (timeDim === 'DAY') { xAxisCoord = [strYear, strMonth, strDay].join('-') }
                if (timeDim === 'MONTH') { xAxisCoord = [strYear, strMonth].join('-') }
                if (timeDim === 'YEAR') { xAxisCoord = strYear }
                const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
                // 加总的series
                const sumSerie = _.find(series, { seriesId: 0 });
                sumSerie.data[axisIndex] = sumSerie.data[axisIndex] + this.formatNumber(trend.sum, subInfo.unit)
                // 子项目series
                if (orgDim === 'ALLCOMPANY' && trend.companies && trend.companies.length) {
                    trend.companies.forEach(company => {
                        const serie = _.find(series, { seriesId: company.companyId });
                        if (serie) serie.data[axisIndex] = serie.data[axisIndex] + this.formatNumber(company.sum, subInfo.unit)
                    })
                }
                if (orgDim === 'COMPANY' && trend.departments && trend.departments.length) {
                    trend.departments.forEach(dpt => {
                        const serie = _.find(series, { seriesId: dpt.dptId });
                        if (serie) serie.data[axisIndex] = serie.data[axisIndex] + this.formatNumber(dpt.sum, subInfo.unit)
                    })
                }
            })

        }

        return {
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: 0,
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // Use axis to trigger tooltip
                    type: 'shadow'
                },
                // formatter: (params, ticket, callback) => {
                //     const { marker, seriesName, name, data } = params;
                //     return `${marker}${seriesName}<br /> ${name}<br /> ${data} ${subInfo.unit} `
                // }
            },
            grid: {
                containLabel: true
            },
            xAxis: {
                type: 'category',
                name: '日期',
                boundaryGap: false,
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: subInfo.unit,
            },
        }
    }

    formatNumber = (num, unit) => {
        if (!num || isNaN(num)) return 0
        let normNum = 0;

        if (unit && unit.includes('万')) {
            normNum = num / 10000
        } else {
            normNum = num
        }

        if (String(normNum).indexOf('.') > -1) {
            return Number(normNum.toFixed(2))
        } else {
            return Number(normNum.toFixed(0))
        }
    }


    renderCompany = (cid) => {
        let com = _.find(this.props.companies, function (o) { return o.companyId === cid });
        return com ? com.companyAlias : null;
    }

    renderDepartment = (c) => {
        let dpt = _.find(this.props.departments, function (o) { return o.dptId === c.dptId })
        return dpt ? dpt.companyDptName : '-';
    }

    handleDateChange(value, field) {

        const date = moment()(value);

        let {
            orgDim,
            companyIds,
            dptIds,
            subIds,
            startDate,
            endDate,
            timeDim,
            showDpts
        } = this.props;

        let newStartDate = startDate;
        let newEndDate = endDate;

        let dateType = 'day';
        if (timeDim === 'DAY') { dateType = 'day' }
        if (timeDim === 'MONTH') { dateType = 'month' }
        if (timeDim === 'YEAR') { dateType = 'year' }

        switch (field) {
            case 'startDate':
                newStartDate = date.startOf(dateType).format('yyyy-MM-DD');
                this.props.onhandleUpdateStartDate(newStartDate);
                break;
            case 'endDate':
                if (date.isBefore(moment()(startDate))) {
                    newEndDate = moment()(startDate).endOf(dateType).format('yyyy-MM-DD');
                } else {
                    newEndDate = date.endOf(dateType).format('yyyy-MM-DD');
                }
                this.props.onhandleUpdateEndDate(newEndDate);
                break;
            default:
            // do nothing
        }
        this.props.onhandleGetTrends(orgDim, companyIds, dptIds, subIds, timeDim, newStartDate, newEndDate, showDpts);
    }

    handleCloseTrend = () => {
        this.props.onhandleCloseTrend()
    }

    render() {
        return (
            <TrendsComponent
                open={this.props.open}
                timeDim={this.props.timeDim}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                companies={this.props.companies}
                departments={this.props.departments}
                trends={this.props.trends}

                title={this.renderTitle()}
                onhandleEchartOption={this.handleEchartOption()}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleClose={this.handleCloseTrend.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.reportTrend.open,
        timeDim: state.reportTrend.timeDim,
        orgDim: state.reportTrend.orgDim,
        companyIds: state.reportTrend.companyIds,
        dptIds: state.reportTrend.dptIds,
        subIds: state.reportTrend.subIds,
        startDate: state.reportTrend.startDate,
        endDate: state.reportTrend.endDate,
        subInfo: state.reportTrend.subInfo,
        trends: state.reportTrend.trends,
        showDpts: state.reportTrend.showDpts,

        companies: filterAmoebaCompany(state.constant.companies),
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCloseTrend: () => {
            return dispatch(closeTrends());
        },
        onhandleUpdateStartDate: (date) => {
            return dispatch(setStartDate(date));
        },
        onhandleUpdateEndDate: (date) => {
            return dispatch(setEndDate(date));
        },
        onhandleGetTrends: (orgDim, companyIds, dptIds, subIds, timeDim, startDate, endDate, showDpts) => {
            return dispatch(getTrends(orgDim, companyIds, dptIds, subIds, timeDim, startDate, endDate, showDpts));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportTrendsContainer);
