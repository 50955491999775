import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateTeamActivityPlan from '../../../components/LeadershipManagement/TeamActivity/CreateTeamActivityPlan';
import { startProcessTeamActivity, setTeamActivityCreateOpen, getRules, updateRule } from '../../../redux/modules/safetyActivity';
import { getMoment } from '../../../utils/datetime';
import * as _ from 'lodash';
// import { assign } from 'lodash';
// import { isThisSecond } from 'date-fns';

class CreateTeamActivityPlanContainer extends Component {

    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedDate: getMoment()().add(1, 'months'),
            planName: '班组安全活动',
            ruleChanged: false,
            rules: []
        }
    }

    componentDidMount() {
        this.handleGetRules()
    }

    handleGetRules() {

        const { onhandleGetRules } = this.props
        onhandleGetRules((activityRules) => {
            const teamActivityRules = _.filter(activityRules, r => { return r.activityType === '2' })
            let rules = _.map(teamActivityRules, r => {
                let [value, unit] = r.rule.split('/')
                return {
                    ruleId: r.ruleId,
                    ruleName: r.ruleName,
                    ruleValue: value,
                    ruleUnit: '次/月',
                    unit: unit
                }
            })
            this.setState({ rules: rules })
        })

    }

    handleChangePlan(event) {

        if (event.target.name === 'planName')
            this.setState({ planName: event.target.value })
    }

    handleChangeDate = (date) => {
        this.setState({ selectedDate: getMoment()(date) })
    }

    handleAddTeamActivityPlan = async () => {
        const { onhandleAddTeamActivityPlan, currentUserInfo, onhandleClose } = this.props;
        let plan = {
            companyId: currentUserInfo.companyId,
            year: this.state.selectedDate.year(),
            month: this.state.selectedDate.month() + 1,
            planName: this.state.planName,
            activityType: 2 //班组活动
        }
        await onhandleAddTeamActivityPlan(plan)
        onhandleClose()
    }

    handleChangeRule(rule, event) {

        let newRules = _.map(this.state.rules, r => {
            if (r.ruleId === rule.ruleId)
                return { ...r, ruleValue: event.target.value, changed: true }
            else return { ...r }
        })

        this.setState({
            rules: newRules,
            ruleChanged: true
        })
        //onhandleUpdateRule({})
    }
    handleSaveRule() {
        const { onhandleSaveRule } = this.props
        _.map(this.state.rules, r => {
            if (r.changed) {
                onhandleSaveRule({
                    ruleId: r.ruleId,
                    rule: r.ruleValue + '/' + r.unit
                })
            }
        })
        this.setState({ ruleChanged: false })
    }

    handleClose() {
        const { onhandleClose } = this.props
        onhandleClose()
    }

    render() {
        return (
            <div>
                <CreateTeamActivityPlan
                    open={this.props.open}
                    selectedDate={this.state.selectedDate}
                    planName={this.state.planName}
                    ruleChanged={this.state.ruleChanged}
                    rules={this.state.rules}
                    onhandleChangePlan={this.handleChangePlan.bind(this)}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                    onhandleChangeRule={this.handleChangeRule.bind(this)}
                    onhandleSaveRule={this.handleSaveRule.bind(this)}
                    onhandleAddTeamActivityPlan={this.handleAddTeamActivityPlan.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        open: state.safetyActivity.teamActivityCreateOpen,
        teamActivityRules: _.filter(state.safetyActivity.activityRules, r => { return r.activityType === '2' })
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleAddTeamActivityPlan: (plan) => {
            return dispatch(startProcessTeamActivity(plan))
        },
        onhandleGetRules: (callback) => {
            dispatch(getRules(callback))
        },
        onhandleSaveRule: (rule, callback) => {
            dispatch(updateRule(rule, callback))
        },
        onhandleClose: () => {
            dispatch(setTeamActivityCreateOpen(false))
        },

    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTeamActivityPlanContainer);