import { connect } from 'react-redux';
import LeftSlideBarView from '../components/LeftSlideBarView';
import { getNewTreeDeviceData } from '../redux/modules/deviceTree';

const mapStateToProps = (state) => {
    return {
        ...state.device,
        ...state.setting,
        ...state.deviceTree,
        ...state.devices,
        ...state.installationPlug
    }
}

const mapDispathToProps = (dispath) => {
    return {
        getNewTreeDeviceData: (index, action) => {
            dispath(getNewTreeDeviceData(index, action))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(LeftSlideBarView);