import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    detailtable: {
        padding: theme.spacing(4)
    },
    action: {
        padding: 16,
    },
    button: {
        margin: theme.spacing(1)
    },
    flexgrow: {
        flexGrow: 1
    },
    yearSelect: {
        marginLeft: theme.spacing(1),
        width: '90%'
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    processline: {
        flexGrow: 1
    },
    attention: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
});

export default withStyles(styles);
