import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeReport } from '@/redux/modules/manufacture/manufactureTask';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import MTaskReportComponent from '@/components/ManufactureManagement/ManufactureOrder/MTaskReport';

/**
 * 生产工单
 */
class MTaskReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    handleOpenReport(event) {
        this.props.onhandleCloseReport()
    }


    render() {
        return (
            <div>
                <Drawer anchor={'right'} open={this.props.openReport}

                    onClose={this.handleOpenReport.bind(this)}>
                    <MTaskReportComponent
                        departments={this.props.departments}
                        companies={this.props.companies}
                        taskReports={this.props.taskReports}
                        tasks={this.props.tasks}
                        manufactureDicts={this.props.manufactureDicts}
                    ></MTaskReportComponent>
                </Drawer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companies: state.company.companyList.list,
        departments: state.department.departments.list,
        taskReports: state.manufactureTask.taskReports,
        openReport: state.manufactureTask.openReport,
        currentUserInfo: state.auth.currentUserInfo,
        tasks: state.manufactureTask.tasks,
        manufactureDicts: state.constant.manufactureDicts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCloseReport: () => {
            return dispatch(closeReport());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MTaskReportContainer);
