import React, { useEffect, useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Grid,
    Typography,
    Tooltip
} from '@material-ui/core';
import {
    Add,
    GroupAdd,
    PersonAdd,
    Description,
    DeleteOutlined,
    Edit,
} from '@material-ui/icons';
import RoleGroupIcon from '@material-ui/icons/SupervisorAccount';
import RoleIcon from '@material-ui/icons/PermIdentity';
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../utils/datetime';
import { localization, options } from "../../../utils/mtable";
import TreeSelect from 'rc-tree-select';
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';

const RoleManagement = props => {

    const {
        classes,
        roleFullList,
        companySelection,
        companyTree,
        onhandleSelectionChange,
        userProfile,
        jobs,
        onCreateRoleGroup,
        onEditRoleGroup,
        onDeleteRoleGroup,
        onCreateRole,
        onEditRole
    } = props;

    // const [selectedRow, setSelectedRow] = useState(null);
    const [tableHeight, setTableHeight] = useState(null);
    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 160  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'role_management' })
        return permission && permission.action === 'W'
    }

    const renderTime = (data) => {
        return data.updateTime ? getDateByTimestamp(data.updateTime, true) : getDateByTimestamp(data.createTime, true)
    }

    const renderRoleType = (data) => {
        if (data.roleLevel === 0) {
            return "系统用户"
        } else if (data.roleLevel === 2) {
            return "管理人员"
        } else {
            return "普通员工"
        }
    }

    const renderJob = (data) => {
        let job = _.find(jobs, function (o) { return o.jobId === data.jobId });
        return job ? job.jobName : '';
    }

    const renderIcon = (data) => {
        return data.roleId ? (<Tooltip title="角色"><RoleIcon /></Tooltip>) : (<Tooltip title="用户组"><RoleGroupIcon /></Tooltip>)
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '',
                field: 'createTime',
                render: row => row ? <div>{renderIcon(row)}</div> : '',
                width: 40,
                editable: 'never'
            },
            {
                title: '名称',
                field: 'createTime',
                render: row => row ? <Typography>{row.roleId ? row.roleName : row.groupName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '角色类别',
                field: 'createTime',
                render: row => row ? <Typography>{row.roleId ? renderRoleType(row) : ''}</Typography> : '',
                editable: 'never'
            },
            {
                title: '职务',
                field: 'jobId',
                render: row => row ? <Typography>{row.roleId ? renderJob(row) : ''}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <Card className={classes.root}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'角色列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={6} lg={6}>
                        <Typography className={classes.label}>
                            单&emsp;&emsp;位
                        </Typography>
                        <TreeSelect
                            className={classes.textFieldInput}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                            placeholder={<i>请选择所属单位</i>}
                            allowClear={true}
                            treeLine
                            value={companySelection}
                            labelInValue={true}
                            treeData={companyTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => onhandleSelectionChange(value)}
                        />
                    </Grid>
                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {checkPermission() && companySelection !== undefined && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={onCreateRoleGroup}
                        >
                            添加用户组
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    columns={assemblycolumns()}
                    data={roleFullList}
                    options={{
                        ...options,
                        showTitle: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: false,
                        maxBodyHeight: `${tableHeight}px`,
                        minBodyHeight: `${tableHeight}px`,
                    }}
                    // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    parentChildData={(row, rows) => rows.find(a => {
                        if (row.roleId) {
                            // 角色
                            return a.roleId === undefined && a.groupId === row.groupId
                        } else {
                            // 用户组
                            return a.roleId === undefined && a.groupId === row.parentGroup
                        }
                    })}
                    actions={checkPermission() ?
                        [rowData => ({
                            icon: () => <GroupAdd color={'primary'} />,
                            tooltip: '添加子用户组',
                            onClick: (event, rowData) => {
                                onCreateRoleGroup(event, rowData.groupId)
                            },
                            hidden: rowData.roleId || rowData.groupName === '默认用户组'
                        }),
                        rowData => ({
                            icon: () => <PersonAdd color={'primary'} />,
                            tooltip: '添加角色',
                            onClick: (event, rowData) => {
                                onCreateRole(event, rowData.groupId)
                            },
                            hidden: rowData.roleId || rowData.groupName === '默认用户组'
                        }),
                        rowData => ({
                            icon: () => <Edit color={'primary'} />,
                            tooltip: '编辑',
                            onClick: (event, rowData) => {
                                if (rowData.roleId) {
                                    onEditRole(event, rowData.roleId)
                                } else {
                                    onEditRoleGroup(event, rowData.groupId)
                                }
                            },
                            hidden: rowData.roleLevel === 0 || rowData.groupName === '默认用户组'
                        }),
                        rowData => ({
                            icon: () => <DeleteOutlined className={classes.menuIcon} />,
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onDeleteRoleGroup(event, rowData.groupId, rowData.groupName)
                            },
                            hidden: rowData.roleLevel === 0 || rowData.groupName === '默认用户组'
                        }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '添加用户组',
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onCreateRoleGroup(event)
                            //     },
                            //     hidden: companySelection === undefined 
                            // }
                        ] : [
                            rowData => ({
                                icon: () => <Description className={classes.menuIcon} />,
                                tooltip: '查看',
                                onClick: (event, rowData) => {
                                    // onView(event, rowData.userId)
                                }
                            })
                        ]}
                >
                </MaterialTable>
            </Grid>
        </Card>
    );
};
RoleManagement.propTypes = {
    classes: PropTypes.object.isRequired,
    roleFullList: PropTypes.array,
    userProfile: PropTypes.object.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    companySelection: PropTypes.object,
    companyTree: PropTypes.array.isRequired,
    onCreateRoleGroup: PropTypes.func.isRequired,
    onEditRoleGroup: PropTypes.func.isRequired,
    onDeleteRoleGroup: PropTypes.func.isRequired,
    jobs: PropTypes.array.isRequired,
    onCreateRole: PropTypes.func.isRequired,
    onEditRole: PropTypes.func.isRequired
};

export default withStyles(RoleManagement);
