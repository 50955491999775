import React, { Component } from 'react';
import { connect } from 'react-redux';
import MisdeliveryComponent from '@/components/ManufactureManagement/Misdelivery';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getMisdeliveryList } from '@/redux/modules/manufacture/manufactureMisdelivery';
import * as _ from 'lodash'
class MisdeliveryContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const deliveryDate = formatDate(getMoment()());
        this.props.getMisdeliveryList(this.getCompany(), deliveryDate, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const deliveryDate = formatDate(date);
            this.props.getMisdeliveryList(this.getCompany(), deliveryDate, undefined);
        })
    }

    getCompany = () => {
        const { companies } = this.props;
        const company = _.find(companies, item => {
            return item.pid && this.props.userInfo.companyId === item.companyId
        });
        return company ? company.companyId : undefined
    }

    render() {
        return (
            <div>
                <MisdeliveryComponent
                    selectedDate={this.state.selectedDate}
                    constant={this.props.constant}
                    misdeliveryList={this.props.misdeliveryList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        misdeliveryList: state.manufactureMisdelivery.misdeliveryList,
        userInfo: state.auth.currentUserInfo,
        constant: state.constant,
        companies: state.company.companyList.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMisdeliveryList: (companyId, deliveryDate, status) => {
            dispatch(getMisdeliveryList(companyId, deliveryDate, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MisdeliveryContainer);
