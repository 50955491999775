import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecordReportComponent from '@/components/OnsiteInspectionManagement/RecordReport';
// import PlanPanel from '@/components/OnsiteInspectionManagement/PlanPanel';
import {
    getRecordReport,
    initRecordReportPage,
    setSearch,
    resetSearch,
    // initView,
    setPlanPanelOpen,
    exportRecordListCollect
} from '@/redux/modules/onsiteInspectionRecordReport';
import { createMessage } from '@/redux/modules/message';
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import _ from 'lodash';
import { getMoment } from '@/utils/datetime'
import { openConfirmDialog } from '@/redux/modules/confirmDialog';

class OnsiteInspectionRecordReportContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    componentWillMount() {
        this.props.onhandleInitPage();
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value,
                    routeId: undefined,
                    dptId: undefined,
                    userId: null
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'inspectionType':
                newSearch = {
                    ...this.props.search,
                    inspectionType: value.target.value,
                    routeId: '',
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'routeId':
                newSearch = {
                    ...this.props.search,
                    routeId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'status':
                newSearch = {
                    ...this.props.search,
                    status: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'userId':
                newSearch = {
                    ...this.props.search,
                    userId: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSearch(event) {
        this.props.onhandleSearch(this.props.search);
    }

    handleDateChange = (value, field) => {
        let newSearch;
        switch (field) {
            case 'fromDate':
                newSearch = {
                    ...this.props.search,
                    fromDate: value
                };
                if (
                    getMoment()(value).add(1, 'months').isSameOrBefore(getMoment()(this.props.search.toDate))
                ) {
                    newSearch.toDate = getMoment()(value).add(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ');
                    this.props.onhadleMassge('统计时间不能超过一月', 'warning')
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'toDate':
                newSearch = {
                    ...this.props.search,
                    toDate: value
                };
                if (
                    getMoment()(this.props.search.fromDate).add(1, 'months').isSameOrBefore(getMoment()(value))
                ) {
                    newSearch.fromDate = getMoment()(value).subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ');
                    this.props.onhadleMassge('统计时间不能超过一月', 'warning')
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    handleChange(value, field) {
        let newPlan;
        switch (field) {
            case 'dptId':
                let newDptId = [{ dptId: value.target.value }];
                newPlan = {
                    ...this.props.currentPlan,
                    onsitePlanDpt: newDptId
                };
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'routeId':
                newPlan = {
                    ...this.props.currentPlan,
                    routeId: value.target.value
                };
                let obj = _.find(this.props.routeList, function (o) { return o.routeId === value.target.value })
                if (obj) {
                    newPlan.inspectionType = obj.inspectionType
                }
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'startTime':
                newPlan = {
                    ...this.props.currentPlan,
                    startTime: value.target.value,
                };
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'expiredAt':
                newPlan = {
                    ...this.props.currentPlan,
                    expiredAt: value.target.value
                };
                this.props.onhandleSelectedPlan(newPlan)
                break
            default:
            // do nothing
        }
    }

    handleClosePlanPanel() {
        this.props.onhandlePlanPanelOpen(false)
    }

    handleExport = () => {
        this.props.onhandleExport();
    }

    // TODO: 将来或需支持列点击跳转
    handleView(event, plan) {
        this.props.onhandleView(plan);
    }
    handleRecord(event, plan) {
        this.props.onhandleRecord(plan);
    }


    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    trimDepartmentList() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let result = _.filter(this.props.departmentList, (o) => {
            const mngFlag = o.executable === false && !o.pid && o.amoebaType === 'COST';
            return companyId === o.companyId && (o.executable === true || mngFlag)
        })
        return result
    }

    trimRouteList() {
        let result = _.filter(this.props.routeList, function (o) { return 'OIT05' !== o.inspectionType; })
        return result
    }

    render() {
        return (
            <div>
                <RecordReportComponent
                    isLoading={this.props.isLoading}
                    userProfile={this.props.userProfile}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    // recordReportList={this.props.recordReportList}
                    inspectionItemList={this.props.inspectionItemList}
                    recordHistoryList={this.props.recordHistoryList}
                    search={this.props.search}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                    routeList={this.props.routeList}
                    departmentList={this.props.departmentList}
                    users={this.props.users}
                    onhandleExport={this.handleExport.bind(this)}
                    unit={this.props.unit}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // recordReportList: state.onsiteInspectionRecordReport.recordReportList,
        recordHistoryList: state.onsiteInspectionPlan.recordHistoryList,
        inspectionItemList: state.onsiteInspection.inspectionItemList, // 共用items
        search: state.onsiteInspectionRecordReport.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        routeList: state.onsiteInspectionRecordReport.routeList,
        departmentList: state.constant.departments,
        unit: state.onsiteInspectionPlan.unit,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        isLoading: state.loading.globalLoading,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitPage: () => {
            dispatch(initRecordReportPage());
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSearch: () => {
            dispatch(resetSearch());
        },
        onhandleSearch: (query) => {
            dispatch(getRecordReport(query));
        },
        // onhandleView: (plan) => {
        //     dispatch(initView(plan));
        // },
        onhadleMassge: (message, type) => {
            dispatch(createMessage(type, message));
        },
        onhandlePlanPanelOpen: (data) => {
            dispatch(setPlanPanelOpen(data));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleExport: () => {
            dispatch(exportRecordListCollect());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionRecordReportContainer);



