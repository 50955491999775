import { createAction, handleActions } from 'redux-actions';
//import routes from "../../routes"


const initialState = {
    expandedMenus: {},
    menuOpen:true,
    focusNode: null,
};

export const setExpandedMenus = createAction('@@xcloud/menu/setexpandedMenus')
export const setFocusNode = createAction('@@xcloud/menu/setFocusNode')



export const setExpandedMenu = createAction('@@xcloud/menu/setexpandedMenu',
    (menu) => (dispatch, getState, httpClient) => {
        let newMenus = { ...(getState().menu.expandedMenus) }
        newMenus[menu.title] = !newMenus[menu.title]
        dispatch(setExpandedMenus(newMenus))
    })


export const updateMenuOpen = createAction('@@xcloud/menu/updateMenuOpen')


export const menuReducer = handleActions(
    {
        [setExpandedMenus]: (state, { payload: menus }) => {
            return {
                ...state,
                expandedMenus: menus
            }
        },
        [updateMenuOpen]: (state, { payload: value }) => {
            return {
                ...state,
                menuOpen: value
            }
        },
        [setFocusNode]: (state, { payload: node }) => {
            return {
                ...state,
                focusNode: node
            }
        },
    },
    initialState
)

export default menuReducer
