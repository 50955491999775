import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  // Card,
  Typography,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import {
  PersonAdd
} from "@material-ui/icons";
import _ from 'lodash';
// import TreeSelect from 'rc-tree-select';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import '@/assets/rc-tree.css';
import '@/assets/rc-tree-select-tree.css';

function UserSelectPage(props) {
  const { classes,
    parameters,
    // companyTree,
    companyList,
    departmentList,
    groupList,
    roleList,
    fullDepartmentList,
    // fullRoleList,
    userList,
    search,
    onhandleSelectChange,
    onhandleChange,
    onhandleClearSearch,
    onhandleSearch,
    onhandleSetSelected,
    onhandleExitSelected,
    onhandleConfirm,
    onhandleInnerConfirm
  } = props;

  const [selectedRow, setSelectedRow] = useState(null);
  // const tableHeight = parameters.innerSelect ? (window.innerHeight - 750) / window.innerHeight * 100 : (window.innerHeight - 600) / window.innerHeight * 100;
  // const tableHeight = parameters.innerSelect ? (window.innerHeight - 750) / window.innerHeight * 100 : undefined;
  const companyOptions = () => {
    return companyList &&
      _.sortBy(companyList, 'companyId')
        .map(data => (
          <MenuItem dense key={data.companyId} value={data.companyId}>{data.companyAlias}</MenuItem>
        ))
  }

  const departmentOptions = () => {
    return departmentList &&
      departmentList.map(data => (
        <MenuItem dense key={data.code} value={data.code}>{data.cname}</MenuItem>
      ))
  }

  const roleGroupOptions = () => {
    return groupList &&
      groupList.map(data => (
        <MenuItem dense key={data.code} value={data.code}>{data.cname}</MenuItem>
      ))
  }

  const roleOptions = () => {
    return roleList &&
      roleList.map(data => (
        <MenuItem dense key={data.code} value={data.code}>{data.cname}</MenuItem>
      ))
  }

  const companyDisabled = () => {
    if (parameters.companyId
      && !isNaN(parameters.companyId)
      // || (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0))
    ) {
      return true
    }
    return false
  }

  const getLocalization = () => {
    let newLocal = _.cloneDeep(localization);
    newLocal.body.emptyDataSourceMessage = '请输入检索条件检索数据';
    return newLocal;
  }

  const checkParameters = () => {
    if (parameters && _.keys(parameters).length > 0) {
      if (_.keys(parameters).length === 1 && (parameters.companyId || parameters.callback)) {
        return false
      } else if (_.keys(parameters).length === 2 && parameters.companyId && parameters.callback) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const renderName = (user) => {
    return user.lastName + user.firstName;
  }

  const renderRole = (user) => {
    // if (user.roleId) {
    //   let role = _.find(fullRoleList, { 'code': user.roleId })
    //   return role ? role.name : null
    // } else {
    //   return null
    // }
    return user.roleName || '-'
  }

  const renderCompany = (user) => {
    let companyName = ''
    if (companyList && companyList.length > 0) {
      let value = _.find(companyList, function (o) { return o.companyId === user.companyId })
      if (value) {
        companyName = value.companyAlias
      }
    }
    return companyName
  }

  const renderDepartment = (user) => {
    if (user.dptId) {
      let dpt = _.find(fullDepartmentList, { 'dptId': user.dptId })
      return dpt ? dpt.dptName : null
    } else {
      return null
    }
  }

  const columns = [
    {
      title: '用户名/ID',
      field: 'userName',
      render: row => row ? <Typography>{row.userName}</Typography> : '',
      editable: 'never'
    },
    {
      title: '姓名',
      field: 'userId',
      render: row => row ? <Typography>{renderName(row)}</Typography> : '',
      editable: 'never'
    },
    {
      title: '手机',
      field: 'phone',
      render: row => row ? <Typography>{row.phone}</Typography> : '',
      editable: 'never'
    },
    {
      title: '短号',
      field: 'opentext1',
      render: row => row ? <Typography>{row.opentext1}</Typography> : '',
      editable: 'never'
    },
    {
      title: '单位',
      field: 'companyId',
      render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
      editable: 'never'
    },
    {
      title: '部门',
      field: 'dptId',
      render: row => row ? (<Typography>{renderDepartment(row)}</Typography>) : '',
      editable: 'never'
    },
    {
      title: '角色',
      field: 'roleId',
      render: row => row ? (<Typography>{renderRole(row)}</Typography>) : '',
      editable: 'never'
    }
  ]

  return (
    <div>
      <Grid className={classes.gridContainer}>
        <Typography variant="h3" gutterBottom>
          {parameters.title ? parameters.title : "用户选择"}
        </Typography>
        {checkParameters() && <>
          <div className={classes.planitemroot}>
            <Grid container spacing={0} className={classes.gridContainer}>
              {parameters.company &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'单位'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {/* <TreeSelect
                      className={classes.select}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.company}
                      labelInValue
                      treeData={companyTree}
                      disabled={companyDisabled()}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'company')}
                    /> */}
                    <TextField
                      select
                      style={{ width: '90%' }}
                      value={search.companyId || 0}
                      onChange={(event) => onhandleSelectChange(event, 'company')}
                      variant="standard"
                      disabled={companyDisabled()}
                    >
                      {!parameters.companyId ? <MenuItem key={0} value={''}>-</MenuItem> : null}
                      {companyOptions()}
                    </TextField>
                  </Grid>
                </>
              }
              {parameters.group &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'用户组'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      style={{ width: '90%' }}
                      value={search.groupId || ''}
                      onChange={(event) => onhandleSelectChange(event, 'roleGroup')}
                      variant="standard"
                    >
                      <MenuItem dense key={0} value={''}>-</MenuItem>
                      {roleGroupOptions()}
                    </Select>
                  </Grid>
                </>
              }
              {parameters.role &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'角色'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      style={{ width: '90%' }}
                      value={search.roleId || ''}
                      onChange={(event) => onhandleSelectChange(event, 'role')}
                      variant="standard"
                    >
                      <MenuItem dense key={0} value={''}>-</MenuItem>
                      {roleOptions()}
                    </Select>
                  </Grid>
                </>
              }
              {parameters.department &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'部门'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      style={{ width: '90%' }}
                      value={search.dptId || ''}
                      onChange={(event) => onhandleSelectChange(event, 'department')}
                      variant="standard"
                    >
                      <MenuItem dense key={0} value={''}>-</MenuItem>
                      {departmentOptions()}
                    </Select>
                  </Grid>
                </>
              }
              {parameters.userName &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'用户名/ID'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      value={search.userName || ''}
                      variant="standard"
                      onChange={event => onhandleChange(event, 'userName')}
                      placeholder=""
                    />
                  </Grid>
                </>
              }
              {parameters.name &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'姓名'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      value={search.name || ''}
                      variant="standard"
                      onChange={event => onhandleChange(event, 'name')}
                      placeholder=""
                    />
                  </Grid>
                </>
              }
            </Grid>
          </div>
          <div>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleSearch}
                >{'搜索'}</Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleClearSearch}
                >{'重置'}</Button>
              </Grid>
            </Grid>
          </div>

        </>}
      </Grid>

      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12}>
          <MaterialTable
            columns={columns}
            data={userList.list}
            options={{
              ...options,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20],
              // maxBodyHeight: `${tableHeight}vh`,
              // minBodyHeight: `${tableHeight}vh`,
              actionsColumnIndex: -1,
              search: false,
              showTextRowsSelected: false,
              toolbar: false,
              selection: parameters.innerSelect ? false : true,
              rowStyle: rowData => ({
                backgroundColor: selectedRow === rowData.userId ? '#EEEEEE' : 'FFFFFF'
              })
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            style={{
              ...style,
              border: '0px solid black',
              boxShadow: 'none'
            }}
            localization={getLocalization()}
            onSelectionChange={(rows) => onhandleSetSelected(rows)}
            actions={parameters.innerSelect ? [
              rowData => ({
                icon: () => <PersonAdd color={'primary'} />,
                tooltip: '选择用户',
                onClick: (event, rowData) => {
                  onhandleInnerConfirm(rowData);
                }
              }),
            ] : []
            }
          >
          </MaterialTable>
        </Grid>
      </Grid>

      {parameters.innerSelect ? null :
        <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
          <Button variant="outlined" color="primary" style={{ marginRight: 16 }}
            onClick={event => onhandleExitSelected()}
          >
            {'取消'}
          </Button>
          <Button variant="contained" color="primary"
            onClick={event => onhandleConfirm()}
          >
            {'确定'}
          </Button>
        </Grid>

      }

    </div >
  );
}

UserSelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  userList: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  // companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  onhandleSetSelected: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
  onhandleInnerConfirm: PropTypes.func.isRequired,
};

export default withStyles(UserSelectPage);
