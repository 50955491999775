import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    IconButton,
    Typography,
    InputAdornment,
    Button,
    TextField,
    Grid,
    Tooltip,
} from "@material-ui/core";
import { Prompt } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import ArchiveIcon from '@material-ui/icons/Archive';
import TreeSelect from 'rc-tree-select';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';

const DeviceVehicleInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleCheck,
        deviceVehicleInfo,
        deviceVehicleInfoError,
        deviceVehicleInfoChange,
        tabValue,
        index,
        onhandleDateChange,
        onhandleSelectChange,
        isView,
        isDeviceEdit,
        userProfile,
        deviceDoc,
        onhandleDownload
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }

    const getDeviceDoc = () => {
        if (deviceDoc && deviceDoc.length !== 0) {
            let data = _.map(deviceDoc, t => {
                return {
                    value: t.docId,
                    label: t.docName,
                }
            })
            return data
        } else {
            return []
        }
    }

    return (
        <div>
            <div className={classes.card} hidden={tabValue !== index}>
                <Grid container item spacing={1} className={classes.gridContainer} sm={12} lg={8}>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>行驶证号码</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceVehicleInfoError.licenseNo !== ''}
                                helperText={deviceVehicleInfoError.licenseNo || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.licenseNo || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'licenseNo')}
                                onBlur={event => onhandleCheck(event, 'licenseNo')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>车架号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceVehicleInfoError.frameCode !== ''}
                                helperText={deviceVehicleInfoError.frameCode || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.frameCode || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'frameCode')}
                                onBlur={event => onhandleCheck(event, 'frameCode')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>发动机号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceVehicleInfoError.engineCode !== ''}
                                helperText={deviceVehicleInfoError.engineCode || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.engineCode || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'engineCode')}
                                onBlur={event => onhandleCheck(event, 'engineCode')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>车辆类型</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vtype !== ''}
                                // helperText={deviceVehicleInfoError.vtype || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vtype || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vtype')}
                                onBlur={event => onhandleCheck(event, 'vtype')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>颜色</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vcolor !== ''}
                                // helperText={deviceVehicleInfoError.vcolor || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vcolor || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vcolor')}
                                onBlur={event => onhandleCheck(event, 'vcolor')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify} style={{ width: 72 }}>废气排放等级</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.emissionLevel !== ''}
                                // helperText={deviceVehicleInfoError.emissionLevel || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.emissionLevel || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'emissionLevel')}
                                onBlur={event => onhandleCheck(event, 'emissionLevel')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>长度</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vlength !== ''}
                                // helperText={deviceVehicleInfoError.vlength || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vlength || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vlength')}
                                onBlur={event => onhandleCheck(event, 'vlength')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            米(m)
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>宽度</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vwidth !== ''}
                                // helperText={deviceVehicleInfoError.vwidth || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vwidth || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vwidth')}
                                onBlur={event => onhandleCheck(event, 'vwidth')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            米(m)
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>高度</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vheight !== ''}
                                // helperText={deviceVehicleInfoError.vheight || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vheight || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vheight')}
                                onBlur={event => onhandleCheck(event, 'vheight')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            米(m)
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>重量(kg)</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.vweight !== ''}
                                // helperText={deviceVehicleInfoError.vweight || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.vweight || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'vweight')}
                                onBlur={event => onhandleCheck(event, 'vweight')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            千克(kg)
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>核载人数</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.loadNo !== ''}
                                // helperText={deviceVehicleInfoError.loadNo || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.loadNo || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'loadNo')}
                                onBlur={event => onhandleCheck(event, 'loadNo')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}></Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>投用时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='enabletimeselect'
                                    value={deviceVehicleInfo.buyTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'buyTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'buyTime')}
                                                    disabled={Boolean(!deviceVehicleInfo.buyTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>登记时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='enabletimeselect'
                                    value={deviceVehicleInfo.registTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'registTime')}

                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'registTime')}
                                                    disabled={Boolean(!deviceVehicleInfo.registTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>保险周期</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                // error={deviceVehicleInfoError.insCycle !== ''}
                                // helperText={deviceVehicleInfoError.insCycle || ''}
                                className={classes.textField}
                                value={deviceVehicleInfo.insCycle || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'insCycle')}
                                onBlur={event => onhandleCheck(event, 'insCycle')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            月
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={12} md={8}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>照片</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceVehicleInfo.vphoto}
                                labelInValue
                                treeData={getDeviceDoc()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit || getDeviceDoc().length === 0}
                                onChange={(value) => onhandleSelectChange(value, 'vphoto')}
                            />
                            <Tooltip title={'下载'} >
                                <IconButton onClick={event => onhandleDownload(deviceVehicleInfo.vphoto)} hidden={_.isEmpty(deviceVehicleInfo.vphoto)} aria-label="archive" style={{ marginLeft: 2 }}>
                                    <ArchiveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

            </div>

            <div className={classes.card} hidden={tabValue !== index}>

                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        hidden={!checkPermission() || isView || isDeviceEdit}
                        disabled={!deviceVehicleInfoChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
                <Grid></Grid>
            </div>
            <Prompt message="数据未保存,确定要离开？" when={deviceVehicleInfoChange} />
        </div >
    );
};

DeviceVehicleInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    deviceVehicleInfo: PropTypes.object.isRequired,
    deviceVehicleInfoError: PropTypes.object.isRequired,
    deviceVehicleInfoChange: PropTypes.bool.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    isView: PropTypes.bool.isRequired,
    isDeviceEdit: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
}
export default withStyles(DeviceVehicleInfo);
