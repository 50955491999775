import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateOperationGoalPlan from '../../../components/DeviceManagement/OperationAnnuaPlan/CreateOperationGoalPlanNew';
import { getYear, formatDate } from '../../../utils/datetime';
import { getTreePartById } from '../../../utils/constant';
import { getCompanyId } from "../../../utils/userUtils";
import {
    addOperationPlan,
    getPlanList,
    updateOperationPlan,
    routeToOperationPlanDetail,
    setCreatePlanPageSize,
    setViewMode,
    initOperationGoalPlan,
    executeProcess
} from "../../../redux/modules/operationPlan";
import * as _ from 'lodash';
import { getBusinessUnitsByRole, getRole } from "../../../utils/userUtils";
import { getRecordByUnitId, isUnitBranch, getRecordByCompanyId } from "../../../utils/processUtils";
import ApprovePannel from "../../LeadershipManagement/ApprovePannel";
import { openApprovePannel } from "../../../redux/modules/approvePannel";

const mapCurrentRecord = (processes, currentUserInfo, userProfile) => {
    if (!processes) {
        return {}
    }
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getRecordByCompanyId(processes, currentUserInfo.companyId)
    if (isUnitBranch(processes)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(processes, bu.unitId)
        }
    }
    return record || {}
}

class CreateOperationGoalPlanContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        userProfile: PropTypes.object,
        companyTree: PropTypes.array,
        companyList: PropTypes.array,
        currentUserInfo: PropTypes.object,
        operationPlan: PropTypes.object,
        createPlanPageSize: PropTypes.number,
        isViewMode: PropTypes.bool,
        constant: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            year: this.props.viewMode ? `${getYear()}-01-01` : `${getYear() + 1}-01-01`,
            companyId: "",
            filterCompanyTree: []
        }
    }

    componentDidMount() {
        let year = `${this.props.year}-01-01`;
        this.setState({ year: year })

        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            this.setState({ companyId: companyId, filterCompanyTree: tree })
        } else {
            companyId = "";
            this.setState({ filterCompanyTree: tree })
        }
        if (this.props.viewMode) {
            this.props.setViewMode(this.props.viewMode)
        } else {
            this.props.setViewMode(false)
        }

        this.props.onhandleInitOperationGoalPlan({
            offset: 0,
            sort: "-update_time",
            status: this.props.viewMode ? "active" : "new",
            planType: "ANNU",
            companyId: companyId ? companyId : null,
            year: Number(year.substr(0, 4)),
        })
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected') {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleCompanyRejectProcess(companyId, action) {
        if (action.statusCode === 'Rejected') {
            action.companyId = companyId
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleProcessConfirm(action, comment) {
        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleExecuteProcess(action) {
        let params = {
            action: action,
            actionParams: {}
        }
        this.props.onhandleProcess(Number(this.state.year.substr(0, 4)), params)
    }

    handleChangeItem = (value, field) => {
        const { onhanleGetPlanList } = this.props;
        let query = {
            offset: 0,
            sort: "-update_time",
            status: this.props.isViewMode ? "active" : "new",
            planType: "ANNU",
            year: Number(this.state.year.substr(0, 4)),
        }
        if (field === 'year') {
            this.setState({ year: formatDate(value) })
            if (this.state.companyId) {
                query.companyId = this.state.companyId
            }
            query.year = Number(formatDate(value).substr(0, 4))

            onhanleGetPlanList(query)
        } else if (field === 'companyId') {
            this.setState({ companyId: value.target.value })
            query.year = Number(this.state.year.substr(0, 4))

            if (value && value.target.value) {
                query.companyId = value.target.value
            }
            onhanleGetPlanList(query);
        }

    }

    handleAddPlan() {
        const { onhanleAddOperationPlan } = this.props;
        let operationPlan = {
            year: Number(this.state.year.substr(0, 4)),
            planName: `${Number(this.state.year.substr(0, 4))}年度设备运维计划`,
            planType: 'ANNU'
        }
        onhanleAddOperationPlan(operationPlan)
    }

    handleUpdate(operationPlan) {
        const { onhandleUpdate, onhanleGetPlanList } = this.props;
        // final execute function from other component or redux-action
        onhandleUpdate(operationPlan, operationPlan.planId, () => {
            onhanleGetPlanList({
                offset: 0,
                sort: "-update_time",
                status: "new",
                planType: "ANNU",
                companyId: this.state.companyId ? _.toInteger(this.state.companyId) : null,
                year: Number(this.state.year.substr(0, 4)),
            })
        })
    }

    render() {
        return (
            <div>
                <CreateOperationGoalPlan
                    companyTree={this.state.filterCompanyTree}
                    companyList={this.props.companyList}
                    operationPlan={this.props.operationPlan}
                    year={this.state.year}
                    companyId={this.state.companyId}
                    createPlanPageSize={this.props.createPlanPageSize}
                    isViewMode={this.props.isViewMode}
                    userProfile={this.props.userProfile}
                    onhandleChangeItem={this.handleChangeItem.bind(this)}
                    onhandleAddPlan={this.handleAddPlan.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandelRouteToOperationPlanDetail={this.props.onhandelRouteToOperationPlanDetail.bind(this)}
                    setCreatePlanPageSize={this.props.setCreatePlanPageSize.bind(this)}
                    onhanleGetPlanList={this.props.onhanleGetPlanList.bind(this)}
                    currentProcessStatus={this.props.currentProcessStatus}
                    constant={this.props.constant}
                    currentRecord={this.props.currentRecord}
                    onhandleProcess={this.handleProcess.bind(this)}
                    currentUserInfo={this.props.currentUserInfo}
                    onhandleCompanyRejectProcess={this.handleCompanyRejectProcess.bind(this)}
                />
                <ApprovePannel />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        userProfile: state.auth.userProfile,
        operationPlan: state.operationPlan.plans,
        createPlanPageSize: state.operationPlan.createPlanPageSize,
        isViewMode: state.operationPlan.isViewMode,
        currentRecord: mapCurrentRecord(
            state.process.processes["OPERATIONPLAN"],
            state.auth.currentUserInfo,
            state.auth.userProfile
        ),
        currentProcessStatus: state.process.currentProcessStatus && state.process.currentProcessStatus['OPERATIONPLAN'] ? state.process.currentProcessStatus['OPERATIONPLAN'] : {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitOperationGoalPlan: (param) => {
            dispatch(initOperationGoalPlan(param));
        },
        onhanleAddOperationPlan: (operationPlan) => {
            dispatch(addOperationPlan(operationPlan));
        },
        onhanleGetPlanList: (query) => {
            dispatch(getPlanList(query));
        },
        onhandleUpdate: (operationPlan, planId, callback) => {
            dispatch(updateOperationPlan(operationPlan, planId, callback));
        },
        onhandelRouteToOperationPlanDetail: (id) => {
            dispatch(routeToOperationPlanDetail(id));
        },
        setCreatePlanPageSize: (value) => {
            dispatch(setCreatePlanPageSize(value));
        },
        setViewMode: (value) => {
            dispatch(setViewMode(value));
        },
        onhandleProcess: (year, params) => {
            dispatch(executeProcess(year, params))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOperationGoalPlanContainer);
