import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    padding: theme.spacing(2, 2, 2, 2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  imageGrid: {
  },
  flexgrow: {
    flexGrow: 1
  }
});

export default withStyles(styles);
