import React, { Component } from 'react';
import { connect } from 'react-redux';
import LabourPaymentConfigComponent from '@/components/AmoebaManagement/LabourPaymentConfig';
// import { getMoment, convertToMoment } from '@/utils/datetime';
import { getConfigs, importConfigs, updateConfig, exportConfigTemplate } from '@/redux/modules/amoeba/labour';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import * as _ from 'lodash';
import { createMessage } from '@/redux/modules/message';
import { upload } from '@/redux/modules/minio';
import { getNowTimestamp } from "@/utils/datetime";

class LabourPaymentConfigContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: this.props.userInfo,
            disableCompany: false,
            companyId: this.props.userInfo.companyId,
            userId: undefined,
            dptId: undefined,
            userName: undefined,
            dpts: []
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        // 查看是否机关
        let com = _.find(this.props.companies, (c) => { return c.companyId === this.state.userInfo.companyId })
        if (com.pid) {
            let dpts = _.filter(this.props.departments, (d) => { return d.companyId === this.state.userInfo.companyId })
            this.setState({ disableCompany: true, dpts: dpts })
        }
        this.props.getConfigs(this.state.userInfo.companyId, undefined, undefined);
    }

    handleChange = (event, field) => {
        if (field === 'company') {
            let dpts = _.filter(this.props.departments, (d) => { return d.companyId === parseInt(event.target.value) })
            this.setState({
                companyId: parseInt(event.target.value),
                dpts: dpts
            })
            this.props.getConfigs(parseInt(event.target.value), undefined, this.state.userId);
        } else if (field === 'dptId') {
            this.setState({
                dptId: parseInt(event.target.value)
            })
            this.props.getConfigs(this.state.companyId, parseInt(event.target.value), this.state.userId);
        }
    }

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传工资配置文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.csv',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.csv') {
            return files;
        } else {
            this.props.onhadleMassgae('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'dev';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.payment.record.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                docName: offset + files[0].name
            };
            this.props.onhandleImportConfigs(doc, () => {
                this.props.getConfigs(this.state.companyId, this.state.dptId, this.state.userId);
            });
            this.props.onhandleModalClose();
        });
    }

    handleUpdate = (data) => {

        let newRecord = {
            'configId': data.configId,
            'item1': data.item1 ? parseFloat(data.item1) : 0,
            'item2': data.item2 ? parseFloat(data.item2) : 0,
            'item3': data.item3 ? parseFloat(data.item3) : 0,
            'item4': data.item4 ? parseFloat(data.item4) : 0,
        }
        this.props.onhandleUpdateRecord(newRecord, () => {
            this.props.getConfigs(this.state.companyId, this.state.dptId, this.state.userId);
        });
    }

    handleDelete = (data) => {
        let nd = {
            configId: data.configId,
            status: 'inactive'
        }
        this.props.onhandleUpdateRecord(nd, () => {
            this.props.getConfigs(this.state.companyId, this.state.dptId, this.state.userId);
        });
    }

    handleSearch = () => {
        this.props.getConfigs(this.state.companyId, this.state.dptId, this.state.userId);
    }

    handleClearSearch = () => {
        if (this.state.disableCompany) {
            this.setState({
                dptId: undefined,
                userId: undefined,
                userName: undefined
            })
        } else {
            this.setState({
                companyId: this.props.userInfo.companyId,
                dptId: undefined,
                userId: undefined,
                userName: undefined
            })
        }

    }

    handleSelectUser = (event) => {
        let param = {
            companyId: this.state.companyId,
            company: !this.state.disableCompany,
            department: !this.state.dptId,
            dptId: this.state.dptId,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('只能选择一个劳务人员');
                        return reject('callbackFunc Error')
                    } else {
                        const user = this.props.selectedUsers[0];
                        if (user.userType !== 3) {
                            this.props.onhadleMassgae('只能选择劳务人员');
                            return reject('callbackFunc Error')
                        }
                        this.setState({
                            userName: user.lastName + user.firstName || '',
                            userId: user.userId
                        })
                        this.props.getConfigs(this.state.companyId, this.state.dptId, user.userId);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    handleExportCsv = () => {
        this.props.onhandleExportTemplate(this.state.companyId);
    }

    render() {
        return (
            <div>
                <LabourPaymentConfigComponent
                    companyList={this.props.companies}
                    disableCompany={this.state.disableCompany}
                    records={this.props.records}
                    currentDpts={this.state.dpts}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleUpload={this.handleUpload.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    exportCsv={this.handleExportCsv.bind(this)}
                    userName={this.state.userName}
                    companyId={this.state.companyId}
                    dptId={this.state.dptId}
                    userProfile={this.props.userProfile}
                    onhadleMassgae={this.props.onhadleMassgae}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSelectUser={this.handleSelectUser.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.labour.records,
        userProfile: state.auth.userProfile,
        userInfo: state.auth.currentUserInfo,
        selectedUsers: state.userSelect.selectedUsers,
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getConfigs: (companyId, dptId, userId) => {
            dispatch(getConfigs(companyId, dptId, userId));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleImportConfigs: (record, callback) => {
            dispatch(importConfigs(record, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updateConfig(record, callback));
        },
        onhandleExportTemplate: (companyId) => {
            dispatch(exportConfigTemplate(companyId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabourPaymentConfigContainer);
