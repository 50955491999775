import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import InfoEdit from "@/containers/InstallationManagement/InfoEdit";
import {
    Card,
    Typography,
    Tab,
    Tabs,
    Grid,
} from '@material-ui/core';
import UnitEdit from '@/containers/InstallationManagement/UnitEdit';
import AreaEdit from '@/containers/InstallationManagement/AreaEdit';

const InstallationDetail = props => {
    const {
        classes,
        parmId,
        // globalLoading,
        editInstallation,
        routeToMapEdit
    } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === '3') {
            routeToMapEdit();
        }
    };

    const displayContainer = () => {

        if (value === "0") {
            return <InfoEdit index={0} />
        } else if (value === "1") {
            return <UnitEdit index={1} />

        } else if (value === "2") {
            return <AreaEdit index={2} />
        }
        return <Grid></Grid>

    }

    const renderTitle = () => {
        if (editInstallation && editInstallation.installId && editInstallation.installNameOrigin) {
            return <Typography variant='h3'>{'装置详情 - ' + editInstallation.installNameOrigin}</Typography>
        }
        return <Typography variant='h3'>{'装置详情'}</Typography>
    }

    return (
        <Card>
            <Grid container className={classes.title}>
                <Grid item>
                    {renderTitle()}
                </Grid>
            </Grid>
            <Grid>
                <Tabs className={classes.tabs} value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    // indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab className={classes.tab} label="装置信息" value="0" />
                    <Tab className={classes.tab} label="单元配置" value="1" />
                    <Tab className={classes.tab} label="区域配置" value="2" />
                    <Tab className={classes.tab} label="装置图" value="3" disabled={parmId === 'create'} />
                </Tabs>
            </Grid>
            {displayContainer()}
        </Card>
    );
};

InstallationDetail.propTypes = {
    parmId: PropTypes.string.isRequired,
    globalLoading: PropTypes.bool.isRequired,
    routeToMapEdit: PropTypes.func.isRequired,
};

export default withStyles(InstallationDetail);
