import React, { Component } from 'react';
import { connect } from 'react-redux';
import MajorHazardList from "../../../components/MajorHazardSources/MajorHazardInfo/MajorHazardList";
import TargetSelect from "../../../components/MajorHazardSources/MajorHazardInfo/TargetSelect";
import { getCompanyId } from "../../../utils/userUtils";
import { initMajorHazardList, setPageSize, getMajorHazard, addNewMajorHazard, createMajorHazard, updateMajorHazard, getArea, routeToMajorHazardDoc, updMajorHazardDevices } from '../../../redux/modules/majorHazard';
import { createMessage } from '../../../redux/modules/message';
import { createmodal } from '../../../redux/modules/modal';
import PropTypes from "prop-types";
import * as _ from 'lodash';


class MajorHazardListContainer extends Component {
    static propTypes = {
        users: PropTypes.object,
        search: PropTypes.object,
        userProfile: PropTypes.object,
        majorHazardlist: PropTypes.object,
        catList: PropTypes.array,
        useStatusList: PropTypes.array,
        dangerLevelList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            installId: "",
            unitId: "",
            areaId: "",
            target: 'INST'
        }
    }

    componentWillMount() {
        this.setState({
            open: false,
            installId: "",
            unitId: "",
            areaId: "",
            target: 'INST'
        })
        this.props.onhandleInitMajorHazardList('ALL');
    }

    // handleLinkDevice(inData) {
    //     let param = {
    //         search: true,
    //         cat: true,
    //         procat: true,
    //         useStatus: true,
    //         company: true,
    //         install: true,
    //         callback: () => {
    //             return new Promise((resolve, reject) => {
    //                 if (this.props.selectedDevices.length !== 1) {
    //                     this.props.onhandleSelectDeviceError(`请选择一个设备`)
    //                     return reject('callbackFunc Error')
    //                 } else {
    //                     let device = {};
    //                     device = _.cloneDeep(this.props.selectedDevices[0])
    //                     if (device.dangerLevel) {
    //                         this.props.onhandleSelectDeviceError(`该设备是重大危险源无法作为部件存在`)
    //                         return reject('callbackFunc Error')
    //                     } else if (device.groupId) {
    //                         this.props.onhandleSelectDeviceError(`该设备已在其他设备组中无法关联`)
    //                         return reject('callbackFunc Error')
    //                     } else if (device.installTitle) {
    //                         this.props.onhandleSelectDeviceError(`该设备在装置中，请在装置图中创建关联`)
    //                         return reject('callbackFunc Error')
    //                     } else {
    //                         device.groupId = inData.groupId;
    //                         this.props.onhandleCreateMajorHazard(device, () => this.props.onhandleGetList())
    //                         return resolve('callbackFunc success')
    //                     }
    //                 }
    //             })
    //         }
    //     }
    //     this.props.onhandleSelectDevice(param);
    // }

    handleSeletDevice() {
        this.setState({
            open: true,
            installId: "",
            unitId: "",
            areaId: "",
            target: 'INST'
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }


    handleUpdate(data) {
        if (data.hazardId) {
            this.props.onhandleUpdateMajorHazard(data, () => this.props.onhandleGetList({ searchType: 'ALL' }))
        } else {
            this.props.onhandleCreateMajorHazard(data, () => this.props.onhandleGetList({ searchType: 'ALL' }))
        }
    }

    handleDelete(data) {
        data.status = 'inactive'
        this.props.onhandleUpdateMajorHazard(data, () => this.props.onhandleGetList({ searchType: 'ALL' }))
    }

    handleUpload(data) {
        this.props.onhandleRouteToMajorHazardDoc(data.hazardId)
    }

    handleSelect = (value, field) => {
        switch (field) {
            case 'target':
                this.setState({
                    target: value.target.value,
                    unitId: "",
                    areaId: "",
                })
                break;
            case 'installId':
                this.setState({
                    installId: value.target.value,
                    unitId: "",
                    areaId: "",
                })
                this.props.onhandleGetArea(value.target.value);
                break;
            case 'unitId':
                this.setState({
                    unitId: value.target.value
                })
                break;
            case 'areaId':
                this.setState({
                    areaId: value.target.value
                })
                break;
            default:
            // do nothing
        }
    }

    addRecord() {
        let dto = {}
        dto.target = this.state.target;
        switch (this.state.target) {
            case 'INST':
                dto.targetId = _.toInteger(this.state.installId);
                dto.targetName = _.find(this.props.installationList, { installId: _.toInteger(this.state.installId) }).intallName;
                // if (this.props.majorHazardlist && this.props.majorHazardlist.list && _.filter(this.props.majorHazardlist.list, { targetId: _.toInteger(this.state.installId) }).length > 0) {
                //     this.props.onhadleMassgae('对象已经在重大危险源列表中')
                // } else {
                //     this.props.onhandleAddNewMajorHazard(dto);
                //     this.setState({
                //         open: false
                //     })
                // }
                break;
            case 'UNIT':
                dto.targetId = _.toInteger(this.state.unitId);
                dto.targetName = _.find(this.props.installationUnitList, { unitId: _.toInteger(this.state.unitId) }).unitName;
                // if (this.props.majorHazardlist && this.props.majorHazardlist.list && _.filter(this.props.majorHazardlist.list, { targetId: _.toInteger(this.state.unitId) }).length > 0) {
                //     this.props.onhadleMassgae('对象已经在重大危险源列表中')
                // } else {
                //     this.props.onhandleAddNewMajorHazard(dto);
                //     this.setState({
                //         open: false
                //     })
                // }
                break;
            case 'AREA':
                dto.targetId = _.toInteger(this.state.areaId);
                dto.targetName = _.find(this.props.installationAreaList, { areaId: _.toInteger(this.state.areaId) }).areaName;
                // if (this.props.majorHazardlist && this.props.majorHazardlist.list && _.filter(this.props.majorHazardlist.list, { targetId: _.toInteger(this.state.areaId) }).length > 0) {
                //     this.props.onhadleMassgae('对象已经在重大危险源列表中')
                // } else {
                //     this.props.onhandleAddNewMajorHazard(dto);
                //     this.setState({
                //         open: false
                //     })
                // }
                break;
            default:
        }

        this.props.onhandleAddNewMajorHazard(dto);
        this.setState({
            open: false
        })
    }

    handleSelectUser(event, newCallback) {
        let companys = []
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        companys.push(companyId)
        let param = {
            company: true,
            companyId: companys.length > 0 ? companys : null,
            group: true,
            role: true,
            department: true,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('请选择一个负责人')
                        return reject('callbackFunc Error')
                    } else {
                        let user = this.props.selectedUsers[0];
                        newCallback(user);
                        return resolve('callbackFunc sucess')
                    }
                })
            }
        }
        this.props.onhandleSelectUser(param);
    }

    handleUpdMajorHazardDevices(event, datas) {
        this.props.onhandleUpdMajorHazardDevices(datas, () => this.props.onhandleGetList({ searchType: 'ALL' }))
    }

    render() {
        return (
            <div>
                <TargetSelect
                    installationList={this.props.installationList}
                    installationAreaList={this.props.installationAreaList}
                    installationUnitList={this.props.installationUnitList}
                    open={this.state.open}
                    installId={this.state.installId}
                    areaId={this.state.areaId}
                    unitId={this.state.unitId}
                    target={this.state.target}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleUpdate={this.addRecord.bind(this)}
                />
                <MajorHazardList
                    userProfile={this.props.userProfile}
                    majorHazardlist={this.props.majorHazardlist}
                    catList={this.props.catList}
                    useStatusList={this.props.useStatusList}
                    extcatList={this.props.extcatList}
                    mediumList={this.props.mediumList}
                    dangerLevelList={this.props.dangerLevelList}
                    onSizeChange={this.props.onhandleListSize.bind(this)}
                    pageSize={this.props.pageSize}
                    handleGetList={this.props.onhandleGetList.bind(this)}
                    handleSeletDevice={this.handleSeletDevice.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                    handleSelectUser={this.handleSelectUser.bind(this)}
                    handleUpload={this.handleUpload.bind(this)}
                    handleUpdMajorHazardDevices={this.handleUpdMajorHazardDevices.bind(this)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.devices.search,
        userProfile: state.auth.userProfile,
        majorHazardlist: state.majorHazard.majorHazardlist,
        catList: state.trees.deviceCategoryList,
        useStatusList: state.devices.useStatus,
        dangerLevelList: state.majorHazard.dangerLevelList,
        pageSize: state.majorHazard.pageSize,
        selectedDevices: state.deviceSelect.selectedDevices,
        installationList: state.installation.installationList ? state.installation.installationList.list : [],
        installationAreaList: state.installation.installationAreaList,
        installationUnitList: state.installation.installationUnitList,
        selectedUsers: state.userSelect.selectedUsers,
        currentUserInfo: state.auth.currentUserInfo,
        extcatList: state.devices.extcat,
        mediumList: state.devices.medium
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitMajorHazardList: (data) => {
            dispatch(initMajorHazardList(data));
        },
        onhandleListSize: (value) => {
            dispatch(setPageSize(value));
        },
        onhandleGetList: (query) => {
            dispatch(getMajorHazard(query));
        },
        onhadleMassgae: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleAddNewMajorHazard: (data) => {
            dispatch(addNewMajorHazard(data));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleCreateMajorHazard: (data, callback) => {
            dispatch(createMajorHazard(data, callback))
        },
        onhandleUpdateMajorHazard: (data, callback) => {
            dispatch(updateMajorHazard(data, callback))
        },
        onhandleGetArea: (data) => {
            dispatch(getArea(data));
        },
        onhandleSelectUser: (parm) => {
            dispatch(createmodal('userSelect', parm, 'medium'));
        },
        onhandleRouteToMajorHazardDoc: (id) => {
            dispatch(routeToMajorHazardDoc('list', id));
        },
        onhandleUpdMajorHazardDevices: (devices, callback) => {
            dispatch(updMajorHazardDevices(devices, callback));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MajorHazardListContainer)
