import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
    },
    routeInfoContainer: {
        paddingTop: 15
    }
});

export default withStyles(styles);