export const getDepartmentsByRole = (roleId, userProfile) => {

    if (!userProfile) {
        return []
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role.departments
    }
    return []

}

export const getBusinessUnitsByRole = (roleId, userProfile) => {

    if (!userProfile) {
        return []
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role.units
    }
    return []

}

// export const getUnitsWithDepartments = (roleId, userProfile) => {

//     if (!userProfile) {
//         return ''
//     }
//     for (let role of userProfile.roles) {
//         if (role.roleId === roleId)
//         {
//             let units = [] 
//             for(let department of role.departments){

//                 for( let newUnit of department.units)
//                 {
//                     let isExist =  false
//                     for(let unit of units){

//                         if(unit.unitId === newUnit.unitId){
//                             unit.departments.push({
//                                 dptId: department.dptId,
//                                 dptName:department.dptName,
//                             })
//                             isExist =  true
//                         }
//                     }
//                     if(!isExist){
//                         units.push({
//                             ...newUnit,
//                             departments:[{
//                                 dptId: department.dptId,
//                                 dptName:department.dptName,
//                             }]
//                         })
//                     }
//                 }
//             }
//             return units
//         }

//     }
//     return []

// }

export const getCompanyName = (roleId, userProfile) => {
    if (!userProfile) {
        return ''
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role.companyName
    }
    return ''
}

export const getCompanyId = (roleId, userProfile) => {
    if (!userProfile) {
        return ''
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role.companyId
    }
    return ''
}

export const getRole = (roleId, userProfile) => {

    if (!userProfile || !userProfile.roles) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}