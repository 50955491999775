import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message'
import { push } from 'connected-react-router';
import * as _ from 'lodash';

const defaultState = {
  roleTree: [],
  roleGroupList: { total: 0, list: [] },
  roleList: { total: 0, list: [] },
  roleFullList: [],
  companySelection: undefined,
  currentRoleGroup: {},
  roleGroupError: {},
  roleGroupChange: false,
  currentRole: {},
  roleError: {},
  roleChange: false,
  permissionGroup: [],
  permissions: [],
  routes: [],
  roleRoutes: [],
  roleRouteChange: false
};

export const saveRoleRoutes = createAction('@@xcloud/role/saveRoleRoutes',
  () => (dispatch, getState, httpClient) => {
    let routes = getState().role.routes
    let role = getState().role.currentRole;
    let dto = [];
    routes.forEach(element => {
      if (element.recordType !== undefined) {
        dto.push({
          action: element.action,
          recordType: element.recordType,
          routeId: element.routeId,
          roleId: role.roleId
        })
      }
    })
    let url = `/api/roles/${role.roleId}/routes`;
    return httpClient.post(url, dto)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '设置角色页面权限成功'));
          dispatch(initRoutePermission())
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '设置角色页面权限失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });

  });

export const initRoutePermission = createAction('@@xcloud/role/initRoutePermission',
  () => (dispatch, getState, httpClient) => {
    dispatch(setRoleRouteChange(false))
    dispatch(getRoutes({ offset: 0, size: 0 }))
      .then(() => {
        return dispatch(getRoleRoutes());
      }).then(() => {
        let roleRoutes = getState().role.roleRoutes
        let routes = _.clone(getState().role.routes)
        routes.forEach(element => {
          roleRoutes.forEach(route => {
            if (route.routeId === element.routeId) {
              element['action'] = route.action
              element['exist'] = true //是否存在数据库记录
              return
            }
          })
        })
        dispatch(setRoutes(routes));
      })
  });

export const getRoleRoutes = createAction('@@xcloud/role/getRoleRoutes',
  () => (dispatch, getState, httpClient) => {
    let role = getState().role.currentRole;
    let url = `/api/roles/${role.roleId}/routes`;

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRoleRoutes(res.data));
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '角色页面权限获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const getRoutes = createAction('@@xcloud/role/getRoutes',
  (query) => (dispatch, getState, httpClient) => {
    let url = '/api/routes';
    if (query) {
      if (query.offset !== undefined) {
        url += "?offset=" + query.offset
      } else {
        url += "?offset=0"
      }
      if (query.size !== undefined) {
        url += "&size=" + query.size
      }
      if (query.sort !== undefined) {
        url += "&sort=" + query.sort
      }
    }
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRoutes(res.data.list));
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '页面权限列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const getRoleGroupById = createAction('@@xcloud/role/getRoleGroupById',
  (id) => (dispatch, getState, httpClient) => {
    let url = '/api/groups/' + id;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentRoleGroup(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '用户组信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const createRoleGroup = createAction('@@xcloud/role/createRoleGroup',
  () => (dispatch, getState, httpClient) => {

    let url = '/api/groups';
    let dto = getState().role.currentRoleGroup;

    return httpClient.post(url, dto, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentRoleGroup(res.data));
          dispatch(createMessage('success', '创建用户组[' + dto.groupName + ']成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '创建新用户组失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateRoleGroup = createAction('@@xcloud/role/updateRoleGroup',
  () => (dispatch, getState, httpClient) => {
    let group = getState().role.currentRoleGroup;
    let url = '/api/groups/' + group.groupId;
    let to = {
      groupId: group.groupId,
      companyId: group.companyId,
      groupName: group.groupName,
      opentext: group.opentext
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentRoleGroup(res.data));
          dispatch(createMessage('success', '更新用户组[' + group.groupName + ']成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新用户组[' + group.groupName + ']失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const deleteRoleGroup = createAction('@@xcloud/role/deleteRoleGroup',
  (id, name) => (dispatch, getState, httpClient) => {

    let groupList = getState().role.roleGroupList.list;
    if (_.find(groupList, function (o) { return o.parentGroup === id })) {
      dispatch(createMessage('error', `请先删除属于用户组[${name}]的子用户组`));
      return
    }
    let roleList = getState().role.roleList.list;
    if (_.find(roleList, function (o) { return o.group === id })) {
      dispatch(createMessage('error', `请先删除属于用户组[${name}]的角色`));
      return
    }
    let to = {
      groupId: id,
      status: 'inactive'
    }
    let url = '/api/groups/' + id;
    httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '用户组[' + name + ']删除成功'));
          dispatch(getRoleWithGroup(getState().role.companySelection));
        }
        return res.data;
      })
      .catch(err => {
        let msg = '用户组[' + name + ']删除失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      })
  }
);

export const getRoleById = createAction('@@xcloud/role/getRoleById',
  (id) => (dispatch, getState, httpClient) => {
    let url = '/api/roles/' + id;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentRole(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '角色信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const createRole = createAction('@@xcloud/role/createRole',
  () => (dispatch, getState, httpClient) => {

    let url = '/api/roles';
    let dto = getState().role.currentRole;

    return httpClient.post(url, dto, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentRole(res.data));
          dispatch(createMessage('success', '创建角色[' + dto.roleName + ']成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '创建角色失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateRole = createAction('@@xcloud/role/updateRole',
  () => (dispatch, getState, httpClient) => {
    let record = getState().role.currentRole;
    let url = '/api/roles/' + record.roleId;
    let to = {
      roleId: record.roleId,
      groupId: record.groupId,
      roleName: record.roleName,
      roleLevel: record.roleLevel
    }
    if (record.opentext) {
      to["opentext"] = record.opentext
    }
    if (record.jobId) {
      to["jobId"] = record.jobId
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentRole(res.data));
          dispatch(createMessage('success', '更新角色[' + record.roleName + ']成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新角色[' + record.roleName + ']失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const deleteRole = createAction('@@xcloud/role/deleteRole',
  (id, name) => (dispatch, getState, httpClient) => {

    let to = {
      roleId: id,
      status: 'inactive'
    }
    let url = '/api/roles/' + id;
    httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '角色[' + name + ']删除成功'));
          dispatch(getRoleWithGroup(getState().role.companySelection));
        }
        return res.data;
      })
      .catch(err => {
        let msg = '角色[' + name + ']删除失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      })
  }
);

export const backToRoleList = createAction('@@xcloud/role/backToRoleList',
  () => (dispatch, getState) => {
    dispatch(push('/roleManagement'));
  });

export const changeCompany = createAction('@@xcloud/role/changeCompany',
  (companySelection) => (dispatch, getState, httpClient) => {
    dispatch(setCompanySelection(companySelection));
    dispatch(getRoleWithGroup(companySelection))
  }
);

export const getRoleWithGroup = createAction('@@xcloud/role/getRoleWithGroup',
  (companySelection) => (dispatch, getState, httpClient) => {

    if (!companySelection && getState().constant.companyTree && getState().constant.companyTree.length > 0) {
      companySelection = {
        label: getState().constant.companyTree[0].label,
        value: getState().constant.companyTree[0].value
      }
      dispatch(setCompanySelection(companySelection));
    }
    return dispatch(getRoleGroups(0, 0, undefined, companySelection.value))
      .then((res) => {
        return dispatch(getRoles(0, 0, undefined, undefined, companySelection.value))
      })
      .then(res => {
        let fullList = [...getState().role.roleGroupList.list];
        res.payload.list.forEach(role => {
          fullList.forEach((group, i) => {
            if (!group.roleId && group.groupId === role.groupId) {
              fullList.splice(i + 1, 0, role)
              return
            }
          })
        })
        dispatch(setRoleFullList(fullList))

      })
  });

export const getRoleGroups = createAction('@@xcloud/role/getRoleGroups',
  (offset, size, sort, companyId, groupName) => (dispatch, getState, httpClient) => {
    let url = "/api/groups?status=active";
    url += "&offset=" + (offset !== undefined ? offset : 0)
    url += "&size=" + (size !== undefined ? size : 0)
    if (sort !== undefined) {
      url += "&sort=" + sort
    }
    if (companyId !== undefined) {
      url += "&companyId=" + companyId
    }
    if (groupName !== undefined) {
      url += "&groupName=" + groupName
    }

    return httpClient.get(url)
      .then((res) => {
        if (res.data) {
          dispatch(setRoleGroupList(res.data));
        }
        return res.data;
      })// eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '用户组获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getRoles = createAction('@@xcloud/role/getRoles',
  (offset, size, sort, status, companyId, groupId, roleName, roleLevel, roleType, route) => (dispatch, getState, httpClient) => {

    let url = "/api/roles";
    url += "?offset=" + (offset !== undefined ? offset : 0)
    url += "&size=" + (size !== undefined ? size : 0)
    if (sort !== undefined) {
      url += "&sort=" + sort
    }
    if (status !== undefined) {
      url += "&status=" + status
    } else {
      url += "&status=active"
    }
    if (companyId !== undefined) {
      url += "&companyId=" + companyId
    }
    if (groupId !== undefined) {
      url += "&groupId=" + groupId
    }
    if (roleName !== undefined) {
      url += "&roleName=" + roleName
    }
    if (roleLevel !== undefined) {
      url += "&roleLevel=" + roleLevel
    }
    if (roleType !== undefined) {
      url += "&roleType=" + roleType
    }
    if (route !== undefined) {
      url += "&route=" + route
    }
    return httpClient.get(url)
      .then((res) => {
        if (res.data) {
          dispatch(setRoleList(res.data));
        }
        return res.data;
      })// eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '角色获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getRoleTree = createAction('@@xcloud/role/getRoleTree',
  () => (dispatch, getState, httpClient) => {
    return httpClient.get("/api/companies/groups/roles", { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.data) {
          dispatch(setRoles(res.data));
        }
        return res;
      })
  });

export const initCreateRoleGroup = createAction('@@xcloud/role/initCreateRoleGroup',
  (pid) => (dispatch, getState, httpClient) => {
    let companyId = getState().role.companySelection.value
    let newRoleGroup = {
      "groupId": 0,
      "groupName": '',
      "status": "active",
      "opentext": '',
      "companyId": companyId
    };
    if (pid) {
      newRoleGroup['parentGroup'] = pid
    }
    dispatch(setCurrentRoleGroup(newRoleGroup));
    let errorInfo = {
      groupName: ''
    };
    dispatch(setRoleGroupError(errorInfo));
    dispatch(setRoleGroupChange(false));
    dispatch(push('/roleManagement/groupInfo'));
  });

export const initUpdateRoleGroup = createAction('@@xcloud/role/initUpdateRoleGroup',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(getRoleGroupById(id)).then(() => {
      let errorInfo = {
        groupName: ''
      };
      dispatch(setRoleGroupError(errorInfo));
      dispatch(setRoleGroupChange(false));
      dispatch(push('/roleManagement/groupInfo'));
    })
  });


export const initCreateRole = createAction('@@xcloud/role/initCreateRole',
  (gid) => (dispatch, getState, httpClient) => {
    let newRole = {
      "roleId": 0,
      "groupId": gid,
      "roleName": '',
      "roleLevel": 1,
      "status": "active",
      "opentext": ''
    };
    dispatch(setCurrentRole(newRole));
    let errorInfo = {
      roleName: ''
    };
    dispatch(setRoleError(errorInfo));
    dispatch(setRoleChange(false));
    dispatch(push('/roleManagement/roleInfo'));
  });

export const initUpdateRole = createAction('@@xcloud/role/initUpdateRole',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(getRoleById(id)).then(() => {
      let errorInfo = {
        roleName: ''
      };
      dispatch(setRoleError(errorInfo));
      dispatch(setRoleChange(false));
      dispatch(push('/roleManagement/roleInfo'));
    })
  });

export const setRoles = createAction('@@xcloud/role/setRoles');

export const setRoleGroupList = createAction('@@xcloud/role/setRoleGroupList');

export const setRoleList = createAction('@@xcloud/role/setRoleList');

export const setRoleFullList = createAction('@@xcloud/role/setRoleFullList');

export const setCompanySelection = createAction('@@xcloud/role/setCompanySelection');

export const setCurrentRoleGroup = createAction('@@xcloud/role/setCurrentRoleGroup');

export const setRoleGroupError = createAction('@@xcloud/role/setRoleGroupError');

export const setRoleGroupChange = createAction('@@xcloud/role/setRoleGroupChange');

export const setCurrentRole = createAction('@@xcloud/role/setCurrentRole');

export const setRoleError = createAction('@@xcloud/role/setRoleError');

export const setRoleChange = createAction('@@xcloud/role/setRoleChange');

export const setRoleRouteChange = createAction('@@xcloud/role/setRoleRouteChange');

export const setRoutes = createAction('@@xcloud/role/setRoutes');

export const setRoleRoutes = createAction('@@xcloud/role/setRoleRoutes');

export const roleReducer = handleActions(
  {
    [setRoleRoutes]: (state, { payload: value }) => {
      return {
        ...state,
        roleRoutes: value
      };
    },
    [setRoutes]: (state, { payload: value }) => {
      return {
        ...state,
        routes: value
      };
    },
    [setCurrentRoleGroup]: (state, { payload: value }) => {
      return {
        ...state,
        currentRoleGroup: value
      };
    },
    [setRoleGroupError]: (state, { payload: value }) => {
      return {
        ...state,
        roleGroupError: value
      };
    },
    [setRoleGroupChange]: (state, { payload: value }) => {
      return {
        ...state,
        roleGroupChange: value
      };
    },
    [setRoleRouteChange]: (state, { payload: value }) => {
      return {
        ...state,
        roleRouteChange: value
      };
    },
    [setCurrentRole]: (state, { payload: value }) => {
      return {
        ...state,
        currentRole: value
      };
    },
    [setRoleError]: (state, { payload: value }) => {
      return {
        ...state,
        roleError: value
      };
    },
    [setRoleChange]: (state, { payload: value }) => {
      return {
        ...state,
        roleChange: value
      };
    },
    [setCompanySelection]: (state, { payload: value }) => {
      return {
        ...state,
        companySelection: value
      };
    },
    [setRoles]: (state, { payload: value }) => {
      return {
        ...state,
        roleTree: value
      };
    },
    [setRoleGroupList]: (state, { payload: value }) => {
      return {
        ...state,
        roleGroupList: value
      };
    },
    [setRoleFullList]: (state, { payload: value }) => {
      return {
        ...state,
        roleFullList: value
      };
    },
    [setRoleList]: (state, { payload: value }) => {
      return {
        ...state,
        roleList: value
      };
    }
  },
  defaultState
);

export default roleReducer;
