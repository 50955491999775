import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { createloading, cleanloading } from './loading';
import { getCompanyTree } from './trees';
import { getCompanyId } from "../../utils/userUtils";
import { getDeviceCategoryTree } from './trees';
import { getInstallations, getInstallation } from './installation';
import { getDeviceUseStatus, getDeviceExtCat } from './devices';
import _ from 'lodash';
// import { getDateByTimestamp } from '../../utils/datetime';
// import { csv } from '../../utils/exportfile';
import { download } from '@/redux/modules/minio';
import { getMoment } from '../../utils/datetime'

const porxyApi = '/api'

const initialState = {
    oriDeviceOpsRecordList: {
        total: 0,
        list: []
    },
    deviceOpsRecordList: {
        total: 0,
        list: []
    },
    pageSize: 5,
    search: {
        companyId: '',
        installId: "",
        cat: undefined,
        extcat: undefined,
        useStatus: undefined,
        search: "",
        onlyMe: false,
    }
}

export const setSearch = createAction('@@xcloud/deviceOpsRecord/setSearch');

export const setDeviceOpsRecordList = createAction('@@xcloud/deviceOpsRecord/setDeviceOpsRecordList');

export const setOriDeviceOpsRecordList = createAction('@@xcloud/deviceOpsRecord/setOriDeviceOpsRecordList');

export const setPageSize = createAction('@@xcloud/deviceOpsRecord/setPageSize');

export const resetSearch = createAction('@@xcloud/deviceOpsRecord/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let companyList = getState().constant.companies;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let company = _.find(companyList, { companyId: companyId })
        dispatch(getInstallations({ companyId: (company && company.pid ? companyId : ''), size: 100 }))

        let search = getState().deviceOpsRecord.search;
        let newSearch = {
            ...search,
            companyId: company && company.pid ? companyId : '',
            installId: "",
            cat: undefined,
            extcat: undefined,
            useStatus: undefined,
            search: "",
            fromDate: getMoment()().startOf('week').format("YYYY/MM/DD"),
            toDate: getMoment()().endOf('week').format("YYYY/MM/DD"),
            onlyMe: false,
        }

        dispatch(setSearch(newSearch));
    });

export const resetSearchField = createAction('@@xcloud/deviceOpsRecord/resetSearchField',
    (value, field) => (dispatch, getState, httpClient) => {
        if (field === 'companyId') {
            if (value) {
                dispatch(getInstallations({ companyId: value, size: 100 }))
                let search = getState().deviceOpsRecord.search;
                let newSearch = {
                    ...search,
                    installId: '',
                }
                dispatch(setSearch(newSearch));
            } else {
                let companyList = getState().constant.companies;
                let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
                let company = _.find(companyList, { companyId: companyId })
                dispatch(getInstallations({ companyId: (company && company.pid ? companyId : ''), size: 100 }))
                let search = getState().deviceOpsRecord.search;
                let newSearch = {
                    ...search,
                    installId: '',
                }
                dispatch(setSearch(newSearch));
            }
        } else if (field === 'installId') {
            dispatch(getInstallation(value))
        }
    });

// 检索设备运维记录(支持分页)
export const getDeviceOpsRecordList = createAction('@@xcloud/deviceOpsRecord/getDeviceOpsRecordList',
    (query) => (dispatch, getState, httpClient) => {
        let searchCond = getState().deviceOpsRecord.search;
        let currentUserInfo = getState().auth.currentUserInfo;
        // let pageSize = getState().deviceOpsRecord.pageSize;
        let pageSize = 0;
        let modalPageParams = getState().modal.parameters;
        let url = "/deviceopsrecords";
        // 分页
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 0)
        }
        // 排序
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }
        // 单位
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        } else if (searchCond && _.isInteger(searchCond.companyId)) {
            url += "&companyId=" + searchCond.companyId
        }
        // 装置
        if (query && _.isInteger(query.installId)) {
            url += "&installId=" + query.installId
        } else if (searchCond && _.isInteger(searchCond.installId)) {
            url += "&installId=" + searchCond.installId
        }
        // 设备类别
        if (query && query.cat && query.cat !== "") {
            url += "&cat=" + query.cat
        } else if (searchCond && searchCond.cat && searchCond.cat !== "") {
            url += "&cat=" + searchCond.cat.value
        }
        // 设备扩展类别
        if (query && query.extcat && query.extcat !== "") {
            url += "&extcat=" + query.extcat
        } else if (searchCond && searchCond.extcat && searchCond.extcat !== "") {
            url += "&extcat=" + searchCond.extcat.value
        }
        // 使用状态
        if (query && query.useStatus && query.useStatus !== "") {
            url += "&useStatus=" + query.useStatus
        } else if (searchCond && searchCond.useStatus && searchCond.useStatus !== "") {
            url += "&useStatus=" + searchCond.useStatus.value
        }
        // 检索文本
        if (query && query.search && query.search !== "") {
            url += "&search=" + encodeURIComponent(_.trim(query.search));
        } else if (searchCond && searchCond.search && searchCond.search !== "") {
            url += "&search=" + encodeURIComponent(_.trim(searchCond.search));
        }
        // 设备运维记录检索时间区间(结束时间) From ~ To
        if (query && query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + (query.fromDate ? new Date(_.toInteger(query.fromDate)).getTime() : null)
        } else if (searchCond && searchCond && searchCond.fromDate && searchCond.fromDate !== "") {
            url += "&fromDate=" + (searchCond.fromDate ? new Date(searchCond.fromDate).getTime() : null)
        }
        if (query && query.toDate && query.toDate !== "") {
            url += "&toDate=" + (query.toDate ? new Date(_.toInteger(query.toDate)).getTime() : null)
        } else if (searchCond && searchCond && searchCond.toDate && searchCond.toDate !== "") {
            url += "&toDate=" + (searchCond.toDate ? new Date(searchCond.toDate).getTime() : null)
        }
        //只看自己
        if (query && query.onlyMe && currentUserInfo) {
            url += "&userId=" + currentUserInfo.userId
        } else if (searchCond && searchCond.onlyMe) {
            url += "&userId=" + currentUserInfo.userId
        }

        // 如果是Popup页面调用： 追加检索条件(设备ID)
        if (modalPageParams && modalPageParams.deviceId) {
            url += "&deviceId=" + modalPageParams.deviceId
        } else if (query && query.deviceId) {
            url += "&deviceId=" + query.deviceId
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: 'true' } })
            .then((res) => {
                let newResData = { total: res.data.total, list: [] }
                let groupArray = []

                // 保存原始数据（用于弹窗显示）
                dispatch(setOriDeviceOpsRecordList(res.data))
                // DB检索结果分组处理，用于页面显示
                if (res.data.list && res.data.list.length > 0) {
                    res.data.list.forEach(data => {

                        // if (data.gbDeviceId) {
                        //     data.group = `设备 ${data.installName ? data.installName : ''} ${data.deviceName}`;
                        // } else {
                        //     data.group = '';
                        // }

                        // 对检索结果按设备聚合
                        if (!groupArray.includes(data.gbDeviceId)) {
                            groupArray.push(data.gbDeviceId)

                            newResData.list.push({
                                group: data.group,
                                companyName: data.companyName,
                                gbInstallId: data.gbInstallId,
                                installCode: data.installCode,
                                installName: data.installName,
                                gbDeviceId: data.gbDeviceId,
                                deviceNo: data.deviceNo,
                                deviceName: data.deviceName,
                                deviceTagNo: data.deviceTagNo,
                                // maintainResult: data.maintainResult,
                                // maintainDate: data.maintainDate,
                                // nextMaintainDate: data.nextMaintainDate,
                                totalOrderBudget: data.orderBudget || 0.0,
                                totalOrderCost: data.orderCost || 0.0,
                                opsRcords: []
                            })
                        } else {
                            newResData.list.forEach(n => {
                                if (data.gbDeviceId === n.gbDeviceId) {
                                    n.totalOrderBudget += (data.orderBudget ? data.orderBudget : 0);
                                    n.totalOrderCost += (data.orderCost ? data.orderCost : 0);
                                }
                            })
                        }
                        newResData.list.forEach(n => {
                            if (data.gbDeviceId === n.gbDeviceId) {
                                n.opsRcords.push(data)
                            }
                        })
                    })
                }
                dispatch(setDeviceOpsRecordList(newResData))

                return res.data;
            })
            .catch(err => {
                let msg = `查询设备运维记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const exportDeviceOpsRecordList = createAction('@@xcloud/deviceOpsRecord/exportDeviceOpsRecordList',
    () => (dispatch, getState, httpClient) => {

        let searchCond = getState().deviceOpsRecord.search;
        let url = "/deviceopsrecords/export?";
        // 排序
        url += "&sort=-update_time"
        // 单位
        if (searchCond && _.isInteger(searchCond.companyId)) {
            url += "&companyId=" + searchCond.companyId
        }
        // 装置
        if (searchCond && _.isInteger(searchCond.installId)) {
            url += "&installId=" + searchCond.installId
        }
        // 设备类别
        if (searchCond && searchCond.cat && searchCond.cat !== "") {
            url += "&cat=" + searchCond.cat
        }
        // 设备扩展类别
        if (searchCond && searchCond.extcat && searchCond.extcat !== "") {
            url += "&extcat=" + searchCond.extcat
        }
        // 使用状态
        if (searchCond && searchCond.useStatus && searchCond.useStatus !== "") {
            url += "&useStatus=" + searchCond.useStatus
        }
        // 使用状态
        if (searchCond && searchCond.useStatus && searchCond.useStatus !== "") {
            url += "&useStatus=" + searchCond.useStatus
        }
        // 检索文本
        if (searchCond && searchCond.search && searchCond.search !== "") {
            url += "&search=" + encodeURIComponent(searchCond.search);
        }
        // 设备运维记录检索时间区间(结束时间) From ~ To
        if (searchCond && searchCond.fromDate && searchCond.fromDate !== "") {
            url += "&fromDate=" + (searchCond.fromDate ? new Date(searchCond.fromDate).getTime() : null)
        }
        if (searchCond && searchCond.toDate && searchCond.toDate !== "") {
            url += "&toDate=" + (searchCond.toDate ? new Date(searchCond.toDate).getTime() : null)
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let tenantId = getState().auth.userProfile.roles[0].tenantId
                        let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                        dispatch(download(
                            res.data.docName,
                            mybucket
                        ))
                    }
                }
                return res;
            })
            .catch(err => {
                let msg = `导出设备运维记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })

        // let search = getState().deviceOpsRecord.search;
        // let deviceOpsRecordList = getState().deviceOpsRecord.oriDeviceOpsRecordList.list;
        // let fileName = '设备运维记录'
        // if (search.companyId) {
        //     let companyList = getState().constant.companies;
        //     let companyInfo = _.find(companyList, { companyId: search.companyId })
        //     fileName = fileName + '_' + companyInfo.companyAlias
        // }
        // if (search.installId) {
        //     let installList = getState().installation.installationList.list;
        //     let installInfo = _.find(installList, { installId: search.installId })
        //     fileName = fileName + '_' + installInfo.installName
        // }
        // switch (search.intervalDate.view) {
        //     case 'day':
        //         fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD")
        //         break;
        //     case 'week':
        //         fileName = fileName + '_[' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD") + '-' + getMoment()(search.intervalDate.toDate).format("YYYYMMDD") + ']';
        //         break;
        //     case 'month':
        //         fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMM");
        //         break;
        //     case 'year':
        //         fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYY");
        //         break;
        //     default:
        // }

        // fileName = fileName + '_' + new Date().getTime()

        // let columns = ['单位', '设备编号', '设备名称', '装置编号', '装置名称', '工单号', '运维内容', '承包商', '运维开始时间', '运维结束时间', '运维预算(元)', '运维决算(元)']
        // let data = []
        // deviceOpsRecordList.forEach(r => {
        //     let cell = [
        //         r.companyName,
        //         "'".concat(r.deviceNo),
        //         r.deviceName,
        //         r.installCode ? "'".concat(r.installCode) : '-',
        //         r.installName ? r.installName : '-',
        //         r.orderNo,
        //         r.content,
        //         r.executor,
        //         getDateByTimestamp(r.startTime, true),
        //         getDateByTimestamp(r.endTime, true),
        //         r.orderBudget,
        //         r.orderCost
        //     ];
        //     data.push(cell)
        // })
        // dispatch(csv(fileName, columns, data));

    }
);

export const initDeviceOpsRecordList = createAction('@@xcloud/deviceOpsRecord/initDeviceOpsRecordList',
    () => (dispatch, getState, httpClient) => {
        let companyList = getState().constant.companies;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let company = _.find(companyList, { companyId: companyId })
        // let pageSize = getState().deviceOpsRecord.pageSize
        let pageSize = 0
        dispatch(createloading());
        Promise.all([
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
            dispatch(getDeviceExtCat()),
            dispatch(getInstallations({ companyId: (company && company.pid ? companyId : ''), size: 100 })),
            // dispatch(setPageSize(5)),
            dispatch(resetSearch()),
            dispatch(getCompanyTree()),
            dispatch(getDeviceOpsRecordList({ offset: 0, size: pageSize })),
        ]).then((res) => {
            dispatch(cleanloading());
        })
    }
);

export const DeviceOpsRecordReducer = handleActions(
    {
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [setOriDeviceOpsRecordList]: (state, { payload: value }) => {
            return {
                ...state,
                oriDeviceOpsRecordList: value
            };
        },
        [setDeviceOpsRecordList]: (state, { payload: value }) => {
            return {
                ...state,
                deviceOpsRecordList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
    },

    initialState
);

export default DeviceOpsRecordReducer