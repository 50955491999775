import React, {Component} from 'react';
import {connect} from 'react-redux';
import RoleManagement from "../../../components/SystemManagement/RoleManagement";
import PropTypes from "prop-types";
import {
    getRoleWithGroup, changeCompany,
    initCreateRoleGroup, initUpdateRoleGroup,
    deleteRoleGroup, initCreateRole, initUpdateRole
} from '../../../redux/modules/role';
import {openConfirmDialog} from '../../../redux/modules/confirmDialog';
import {findPath} from '../../../utils/constant';
import {setCompanySelection} from "@/redux/modules/role";

class RoleListContainer extends Component {
    static propTypes = {
        roleFullList: PropTypes.array,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleGetRoleList(this.props.companySelection);
    }

    handleSelectionChange(value) {
        if (!value) {
            this.props.onhandleSetCompanySelection(undefined);
            return;
        }

        let path = findPath(value.value, this.props.companyTree)
            .map(i => i.label)
            .reverse()
            .join(' > ');
        let newCompanySelection = {
            ...this.props.companySelection,
            label: path,
            value: value.value
        };
        this.props.onhandleUpdateEditSearch(newCompanySelection);
        this.props.onhandleSetCompanySelection({
            label: path,
            value: value.value
        });
    }

    handleCreateRoleGroup(event, pid) {
        this.props.onhandleCreateRoleGroup(pid)
    }

    handleEditRoleGroup(event, id) {
        this.props.onhandleEditRoleGroup(id)
    }

    handleDeleteRoleGroup(event, id, name) {
        let doDelete = () => {
            this.props.onhandleDeleteRoleGroup(id, name);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除用户组[' + name + ']吗?', doDelete, null, false);
    }

    handleCreateRole(event, gid) {
        this.props.onhandleCreateRole(gid)
    }

    handleUpdateRole(event, id) {
        this.props.onhandleEditRole(id)
    }

    render() {
        return (
            <RoleManagement
                jobs={this.props.jobs}
                roleGroupList={this.props.roleGroupList}
                roleFullList={this.props.roleFullList}
                onDeleteRoleGroup={this.handleDeleteRoleGroup.bind(this)}
                onEditRoleGroup={this.handleEditRoleGroup.bind(this)}
                onCreateRoleGroup={this.handleCreateRoleGroup.bind(this)}
                onCreateRole={this.handleCreateRole.bind(this)}
                onEditRole={this.handleUpdateRole.bind(this)}
                userProfile={this.props.userProfile}
                companyTree={this.props.companyTree}
                companySelection={this.props.companySelection}

                onhandleSelectionChange={this.handleSelectionChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleFullList: state.role.roleFullList,
        companyTree: state.constant.allCompanyTree,
        companySelection: state.role.companySelection,
        userProfile: state.auth.userProfile,
        jobs: state.constant.jobs
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateEditSearch: (companySelection) => {
            dispatch(setCompanySelection(companySelection));
        },
        onhandleGetRoleList: (value) => {
            dispatch(getRoleWithGroup(value))
        },
        onhandleDeleteRoleGroup: (id, name) => {
            dispatch(deleteRoleGroup(id, name));
        },
        onhandleEditRoleGroup: (id) => {
            dispatch(initUpdateRoleGroup(id));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreateRoleGroup: (pid) => {
            dispatch(initCreateRoleGroup(pid));
        },
        onhandleSetCompanySelection: (selection) => {
            dispatch(changeCompany(selection))
        },
        onhandleCreateRole: (gid) => {
            dispatch(initCreateRole(gid));
        },
        onhandleEditRole: (id) => {
            dispatch(initUpdateRole(id));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleListContainer)