import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Chip } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import CancelIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from './styles';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const FileUpload = (props) => {
  const { classes, title, fileType, maxSize, multiple, docName, paraA, paraB, paraC, paraD } = props;
  const [isDraging, setDragging] = React.useState(false);
  const [isUploading, setUploadStatus] = React.useState(false);
  const [files, setFiles] = React.useState(null);
  const inputRef = React.createRef();
  const fileArea = classes.filearea + ' ' + (isDraging ? classes.dragging : '');

  // console.log(note)
  const [values, setValues] = React.useState({
    docName: docName,
    //uploadType: uploadType,
    expiredTime: '',
    paraA: paraA,
    paraB: paraB,
    paraC: paraC,
    paraD: paraD,
  });

  const handleChangeDocName = docNum => event => {
    setValues({ ...values, [docNum]: event.target.value });
  };

  const handleChangeExpiredTime = expiredTime => event => {
    setValues({ ...values, [expiredTime]: event.target.value });
  };

  const generateFileList = files => {
    const { classes } = props;
    const fileList = [];
    if (!!files && files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        if (i < 100) {
          fileList.push(
            <Chip key={'file' + i} label={files[i].name} className={classes.fileItem} />
          );
        } else {
          fileList.push(
            <span key={'fileCount'} className={classes.fileItem}>{' ... ' + files.length + ' files'}</span>
          );
          break;
        }
      }
    }
    return fileList;
  };

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.value = '';
    setFiles(null);
  };

  const handleCloseDialog = () => {
    props.onClose();
  };

  const handleUploadFile = e => {
    const { files } = e.target;
    const uploadFiles = props.onFileSelected(files);
    if (uploadFiles) {
      setFiles(uploadFiles);
    }
  };

  const handleUploadClick = (files, values, event) => {
    props.onConfirm(files, setUploadStatus, values);
  };

  const browserFile = () => {
    inputRef.current.click();
  };

  const preventEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    // check upload file
    const uploadFiles = props.onFileSelected(files);
    setDragging(false);
    setFiles(uploadFiles);
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.title}>
          {title || 'Upload'}
          <IconButton
            edge="end"
            aria-label="close dialog"
            className={classes.closeIcon}
            onClick={handleCloseDialog.bind(this)}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <div className={classes.content}>
          <form>
            <div className={fileArea}
              onDrag={preventEvent.bind(this)} onDragStart={preventEvent.bind(this)}
              onDragEnd={preventEvent.bind(this)} onDragEnter={preventEvent.bind(this)}
              onDragOver={onDragOver.bind(this)} onDragLeave={onDragLeave.bind(this)}
              onDrop={onDrop.bind(this)}>
              <div className={classes.filename}>
                <input type='file' name='file'
                  accept={fileType}
                  multiple={multiple}
                  ref={inputRef}
                  onChange={handleUploadFile.bind(this)}
                  className={classes.uploadfile} />
                {
                  files && <div className={classes.uploadname}>{generateFileList(files)}</div>
                }
                {
                  !files
                  && <div className={classes.dragDropHint}>
                    <CloudUploadIcon className={classes.uploadIcon} fontSize="large" />
                    <div>请拖拽文件至此处</div>
                    <div className={classes.browseFile} onClick={browserFile.bind(this)}>
                      <StorageIcon />
                      或者点击上传
                        </div>
                    <div className={classes.fileFormat}>
                      支持格式: <span style={{ fontWeight: 'bold' }}>{fileType}</span>
                      <br />
                      支持大小: <span style={{ fontWeight: 'bold' }}>{maxSize}</span>
                    </div>
                  </div>
                }
              </div>
              {/*<div className={classes.uploadHint}>*/}
              {/*<span className={classes.note}>*/}
              {/*<span className={classes.redStar}>*</span>*/}
              {/*&nbsp;提示:&nbsp;*/}
              {/*</span>*/}
              {/*{note}*/}
              {/*</div>*/}
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="制度名称"
                      className={classes.textField}
                      value={values.docName}
                      onChange={handleChangeDocName('docName')}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="版本"
                      className={classes.textField}
                      value={values.versionOrder}
                      disabled={true}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="date"
                      label="失效时间"
                      type="date"
                      variant="outlined"
                      margin="normal"
                      className={classes.textField}
                      onChange={handleChangeExpiredTime('expiredTime')}
                      value={values.expiredTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="参数A"
                      className={classes.textField}
                      value={values.paraA}
                      margin="normal"
                      variant="outlined"
                      hidden={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="参数B"
                      className={classes.textField}
                      value={values.paraB}
                      margin="normal"
                      variant="outlined"
                      hidden={true}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={classes.buttonarea}>
              {
                files && !isUploading
                && <Button
                  className={classes.buttons}
                  color='primary'
                  onClick={handleReset.bind(this)}>
                  清空文件
                </Button>
              }
              {
                files && !isUploading
                && <Button
                  className={classes.buttons}
                  variant="contained"
                  color='primary'
                  onClick={handleUploadClick.bind(this, files, values)}
                  style={{ width: '70px' }} >
                  上传
                </Button>
              }
              {
                !files && !isUploading
                && <Button
                  className={classes.buttons}
                  variant="contained"
                  color='primary'
                  disabled
                  style={{ width: '70px' }}>
                  上传
                </Button>
              }
              {
                files && isUploading
                && <Button className={classes.buttons} variant="contained" color='primary' disabled >
                  <CircularProgress size={24} className={classes.loading} />
                  上传中...
                </Button>
              }
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  docName: PropTypes.string,
  versionOrder: PropTypes.string,
  paraA: PropTypes.number,
  paraB: PropTypes.number,
  paraC: PropTypes.string,
  paraD: PropTypes.string,
};

FileUpload.defaultProps = {
  title: '上传文件',
  fileType: '.xls',
  note: 'Drag and drop or browse from your device for files',
  multiple: false
};

export default withStyles(FileUpload);
