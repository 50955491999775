import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DepreciationRule from '@/components/AmoebaManagement/DepreciationRule';
import { getDepreciationRules, createDepreciationRule, updateDepreciationRule, initDepreciationRule } from "@/redux/modules/amoeba/depreciation";
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import * as _ from 'lodash';

class DepreciationRuleContainer extends Component {
    static propTypes = {
        rules: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            companyId: this.props.userInfo.companyId,
            diableCompany: false,
            isEditMode: 0,
            data: { 'target': 'DEV' }
        }
    }

    componentDidMount() {
        let com = _.find(this.props.companies, (o) => {
            return o.companyId === this.props.userInfo.companyId
        })
        if (com.pid) {
            this.setState({ diableCompany: true })
        }
        this.props.onInitPage(this.props.userInfo.companyId, 'active');
    }

    handleCompanyChange = (event) => {
        this.setState({
            companyId: event.target.value
        })
        this.props.onhandleGetDepreciationRules(event.target.value, 'active');
    }

    handleCreate = () => {
        this.setState({
            isEditMode: 1,
            data: {
                ...this.state.data,
                startTime: new Date(),
                target: 'DEV'
            }
        })
    }

    handleDelete = (event, data) => {
        let nd = {
            ruleId: data.ruleId,
            status: 'inactive'
        }
        let doDelete = () => {
            this.props.onhandleUpdateRecord(nd, () => {
                this.props.onhandleGetDepreciationRules(this.state.companyId, 'active');
            });
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除该折旧规则吗?', doDelete, null, false);
    }

    handleEdit = (event, data) => {
        this.setState({
            isEditMode: 2,
            data: data
        })
    }

    handleSave = () => {
        if ((this.state.data.target === 'DEV' || this.state.data.target === 'INSTALL') && !this.state.data.targetId) {
            this.props.onhandleErrorMessage('请选择一个资产')
            return
        }
        if (this.state.data.target === 'OTHER' && (!this.state.data.targetName || this.state.data.targetName.trim() === '')) {
            this.props.onhandleErrorMessage('请输入资产名称')
            return
        }
        if ((this.state.data.target === 'OTHER' || this.state.data.target === 'INSTALL') && !this.state.data.faVal) {
            this.props.onhandleErrorMessage('请输入原值')
            return
        }
        if (!this.state.data.faVal && !this.state.data.faNet && this.state.data.faNet > this.state.data.faVal) {
            this.props.onhandleErrorMessage('净值不可以大于原值')
            return
        }
        if (!this.state.data.ruleCode) {
            this.props.onhandleErrorMessage('请选择一个折旧方式')
            return
        }
        if (!this.state.data.startTime) {
            this.props.onhandleErrorMessage('请选择资产折旧开始时间')
            return
        }
        if (this.state.data.ruleCode === 'DEP_AVG' && !this.state.data.cycle) {
            this.props.onhandleErrorMessage('请输入折旧总周期')
            return
        }
        if (this.state.data.ruleCode === 'DEP_HOUR' && !this.state.data.val1) {
            this.props.onhandleErrorMessage('请输入每小时折旧金额')
            return
        }
        if (this.state.data.ruleCode === 'DEP_HOUR' && !this.state.data.val2) {
            this.props.onhandleErrorMessage('请输入一天折旧小时数')
            return
        }
        if (this.state.data.ruleCode === 'DEP_VOLUME' && !this.state.data.val1) {
            this.props.onhandleErrorMessage('请输入生产量')
            return
        }
        if (this.state.data.ruleCode === 'DEP_VOLUME' && !this.state.data.val2) {
            this.props.onhandleErrorMessage('请输入折旧金额')
            return
        }
        let nd = {
            target: this.state.data.target,
            targetId: this.state.data.targetId,
            ruleCode: this.state.data.ruleCode,
            startTime: new Date(this.state.data.startTime),
            companyId: this.state.companyId
        }
        if (this.state.data.ruleId) {
            nd.ruleId = this.state.data.ruleId
        }
        if (this.state.data.cycle) {
            nd.cycle = this.state.data.cycle
        }
        if (this.state.data.val1) {
            nd.val1 = this.state.data.val1
        }
        if (this.state.data.val2) {
            nd.val2 = this.state.data.val2
        }
        if (this.state.data.targetName) {
            nd.targetName = this.state.data.targetName
        }
        if (this.state.data.faVal) {
            nd.faVal = this.state.data.faVal
        }
        if (this.state.data.faNet) {
            nd.faNet = this.state.data.faNet
        }
        this.props.onhandleCreateRecord(nd, () => {
            this.props.onhandleGetDepreciationRules(this.state.companyId, 'active');
            this.setState({
                isEditMode: 0,
                data: null
            })
        })
    }

    handleClose = () => {
        this.setState({
            isEditMode: 0,
            data: null
        })
    }

    handleSelectDevice = (event) => {
        let parm = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.state.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleErrorMessage('请只选择一个设备')
                        return reject('callbackFunc Error')
                    } else {
                        let newData = {
                            ...this.state.data,
                            'target': 'DEV',
                            'targetId': this.props.selectedDevices[0].deviceId,
                            'targetName': this.props.selectedDevices[0].deviceName
                        }
                        this.setState({
                            data: newData
                        })
                        return resolve('callbackFunc success')
                    }
                })
            }
        };
        this.props.onhandleSelectDevice(parm)
    }

    handleSelectRule = (event) => {
        let newData = {
            ...this.state.data,
            'ruleCode': event.target.value
        }
        this.setState({
            data: newData
        })
    }

    handleDateChange = (date) => {
        let newData = {
            ...this.state.data,
            'startTime': date
        }
        this.setState({
            data: newData
        })
    }

    handleTargetChange = (event) => {
        this.setState({
            data: { ...this.state.data, 'target': event.target.value, 'targetId': 0, 'targetName': '', 'faVal': 0, 'faNet': 0 }
        })
    }

    handleInstallChange = (event) => {
        let install = _.find(this.props.installations, (o) => { return o.installId === parseInt(event.target.value) })
        this.setState({
            data: { ...this.state.data, 'targetId': parseInt(event.target.value), 'targetName': install ? install.installName : '' }
        })
    }

    handleChange = (event, field) => {
        if (field === 'cycle') {
            let newData = {
                ...this.state.data,
                'cycle': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'val1') {
            let newData = {
                ...this.state.data,
                'val1': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'val2') {
            let newData = {
                ...this.state.data,
                'val2': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'faVal') {
            let newData = {
                ...this.state.data,
                'faVal': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'faNet') {
            let newData = {
                ...this.state.data,
                'faNet': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'val2') {
            let newData = {
                ...this.state.data,
                'val2': parseFloat(event.target.value)
            }
            this.setState({
                data: newData
            })
        } else if (field === 'targetName') {
            let newData = {
                ...this.state.data,
                'targetName': event.target.value
            }
            this.setState({
                data: newData
            })
        }
    }

    render() {
        return (
            <div>
                <DepreciationRule
                    companies={this.props.companies}
                    rules={this.props.rules}
                    userProfile={this.props.userProfile}
                    companyId={this.state.companyId}
                    diableCompany={this.state.diableCompany}
                    dicts={this.props.dicts}
                    isEditMode={this.state.isEditMode}
                    editRule={this.state.data}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onEdit={this.handleEdit.bind(this)}
                    onCreate={this.handleCreate.bind(this)}
                    onDelete={this.handleDelete.bind(this)}
                    onSave={this.handleSave.bind(this)}
                    onClose={this.handleClose.bind(this)}
                    onSelectDevice={this.handleSelectDevice.bind(this)}
                    onSelectRule={this.handleSelectRule.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onDateChange={this.handleDateChange.bind(this)}
                    onhandleTargetChange={this.handleTargetChange.bind(this)}
                    onhandleInstallChange={this.handleInstallChange.bind(this)}
                    installations={this.props.installations}
                />
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        companies: state.constant.companies,
        installations: state.installation.installationList ? state.installation.installationList.list : [],
        userInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        rules: state.depreciation.rules,
        dicts: state.constant.manufactureDicts,
        selectedDevices: state.deviceSelect.selectedDevices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (companyId, status) => {
            dispatch(initDepreciationRule(companyId, status));
        },
        onhandleGetDepreciationRules: (companyId, status) => {
            dispatch(getDepreciationRules(companyId, status));
        },
        onhandleSelectDevice: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
        onhandleErrorMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleCreateRecord: (record, callback) => {
            dispatch(createDepreciationRule(record, callback));
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updateDepreciationRule(record, callback));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepreciationRuleContainer);