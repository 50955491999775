import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import * as _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { localization, options, style } from "@/utils/mtable";
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';


const CreateMOrderComponent = props => {
    const { classes,
        open,
        generateDate,
        onhandleClose,
        onhandleGenerate,
        onhandleGenerateDateChange,
        dtls,
        yearMonth,
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'dtlName',
                render: row => <Typography>{row.dtlName}</Typography>,
                editable: 'never'
            },
            {
                title: '产品',
                field: 'product',
                render: row => <Typography>{row.product}</Typography>,
                editable: 'never'
            },
            {
                title: '数量',
                field: 'output',
                type: 'numeric',
                render: row => <Typography>{row.output}</Typography>
            },
            {
                title: '单位',
                field: 'outputUnit',
                render: row => <Typography>{row.outputUnit}</Typography>,
                editable: 'never'
            },
            // {
            //     title: '完成比例',
            //     field: 'completedOutput',
            //     render: row => <Typography>{row.profit + '%'}</Typography>
            // }
        ]
        return columns
    }

    const assemblyData = () => {
        return dtls
    }


    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>生成生产工单</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            inputVariant="outlined"
                                            id="date-selector"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            label='时间'
                                            value={generateDate}
                                            onChange={onhandleGenerateDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <MaterialTable
                                        columns={assemblycolumns()}
                                        data={assemblyData()}
                                        options={tableOptions}
                                        localization={tableLocalization}
                                        style={tableStyle}
                                        title={<Typography variant='h3'>{yearMonth.substring(0, 4)}年{yearMonth.substring(5, 7)}月生产运行计划明细列表</Typography>}
                                    >
                                    </MaterialTable>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleGenerate} color="primary"> 生成
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreateMOrderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateMOrderComponent.defaultProps = {
};

export default withStyles(CreateMOrderComponent);