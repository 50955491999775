import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initMonthlyPlanHeader, setSearch, resetSearch } from '@/redux/modules/inspection/inspectionPlanMonthly';
import MonthlyPlanHeader from "@/components/InspectionManagement/MonthlyPlan/MonthlyPlanHeader";
import { createmodal } from '@/redux/modules/modal';
import {
  getPlanDetails
} from "@/redux/modules/inspection/inspectionPlan";
import _ from 'lodash';


class MonthlyPlanHeaderContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }



  static propTypes = {
    companyList: PropTypes.array,
    departmentList: PropTypes.array,
    listTypeList: PropTypes.array,
    categoryList: PropTypes.array,
    statusList: PropTypes.array,
    search: PropTypes.object
  };

  componentDidMount() {
    const { isBack } = this.props;
    this.props.onhandleInitMonthlyPlanHeader(isBack);
  }

  handleSearch() {
    const { search } = this.props;
    search.inspectType = search.listType;
    if (_.find(this.props.categoryList, { 'pcode': search.listType })) {
      if (search.catCode && search.catCode !== '') {
        search.inspectType = search.catCode;
      }
    }
    this.props.onhandleSearch(search);
  }

  handleClearSearch() {
    this.props.onhandleClearSarch();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value
        };
        break;
      case 'listType':
        newSearch = {
          ...this.props.search,
          listType: value.target.value,
          catCode: ''
        };
        break;
      case 'category':
        newSearch = {
          ...this.props.search,
          catCode: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'yearMonth': // 年-月模糊匹配详情记录 startTime
        newSearch = {
          ...this.props.search,
          yearMonth: value
        };
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value.target.value
        };
        break;
      default:
      // do nothing
    }
    this.props.onhandleUpdateEditSearch(newSearch);
  }

  // 查询责任人
  handleOpenUserSelectModal(event) {
    let companys = []
    let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
    if (mainCompany) {
      companys.push(mainCompany.companyId)
    }
    let param = {
      company: true,
      group: true,
      role: true,
      department: true,
      userName: true,
      name: true,
      callback: () => {
        return new Promise((resolve, reject) => {
          if (this.props.selectedUsers.length !== 1) {
            this.props.onhadleMassgae('只能选择一个检查人');
            return reject('callbackFunc Error')
          } else {
            const user = this.props.selectedUsers[0];
            let newSearch = { ...this.props.search };

            newSearch.inspectHead = user.userId;
            newSearch.creatorName = user.lastName + user.firstName || ''

            this.props.onhandleUpdateEditSearch(newSearch);
            return resolve('callbackFunc success')
          }
        })
      }
    }
    this.props.openUserSelectModal(param);
  }

  render() {
    return (
      <div>
        <MonthlyPlanHeader
          search={this.props.search}
          companyList={this.props.companyList}
          departmentList={this.props.departmentList}
          listTypeList={this.props.listTypeList}
          categoryList={this.props.categoryList}
          severityList={this.props.severityList}
          rootCauseList={this.props.rootCauseList}
          statusList={this.props.statusList}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          onhandleInspectHeadClick={this.handleOpenUserSelectModal.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.inspectionPlanMonthly.search,
    statusList: state.inspectionPlanMonthly.statusList,
    companyList: state.constant.companies,
    departmentList: state.inspection.departmentList,
    listTypeList: state.inspection.listTypeList,
    categoryList: state.inspection.categoryList,
    selectedUsers: state.userSelect.selectedUsers
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitMonthlyPlanHeader: (isBack) => {
      dispatch(initMonthlyPlanHeader(isBack));
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleSearch: (query) => {
      dispatch(getPlanDetails(query));
    },
    onhandleClearSarch: () => {
      dispatch(resetSearch());
    },
    openUserSelectModal: (config) => {
      dispatch(createmodal('userSelect', config, 'medium'));
    }
  }
};

MonthlyPlanHeaderContainer.defaultProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyPlanHeaderContainer)
