
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'
import _ from 'lodash'

const porxyApi = '/api'

const initialState = {
    trends: [], //趋势信息
    open: false, //open
    orgDim: '',
    companyIds: '',
    dptIds: '',
    subIds: '',
    timeDim: '',
    startDate: '',
    endDate: '',
    subInfo: {},
    showDpts: []
};

export const closeTrends = createAction('@@xcloud/amoeba/report/trend/close');

export const openTrends = createAction('@@xcloud/amoeba/report/trend/open');

export const setTrends = createAction('@@xcloud/amoeba/report/trend/setTrends');

export const getTrends = createAction('@@xcloud/amoeba/report/trend/getTrends',
    (orgDim, companyIds, dptIds, subIds, timeDim, startDate, endDate, showDpts) =>
        (dispatch, getState, httpClient) => {
            dispatch(createloading());
            let url = porxyApi + '/amoeba/reports/trends'
            let strs = []
            if (orgDim || companyIds || dptIds || subIds || timeDim || startDate || endDate) {
                if (orgDim) strs.push('orgDim=' + orgDim)
                if (companyIds) strs.push('companyIds=' + companyIds)
                if (dptIds) strs.push('dptIds=' + dptIds)
                if (subIds) strs.push('subIds=' + subIds)
                if (timeDim) strs.push('timeDim=' + timeDim)
                if (startDate) strs.push('startDate=' + startDate)
                if (endDate) strs.push('endDate=' + endDate)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                dispatch(cleanloading());
                if (res.status === 200) {
                    dispatch(setTrends(_wrapData(res.data, orgDim, showDpts)))
                }
                return res.data;
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `获取阿米巴指标趋势信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const setStartDate = createAction('@@xcloud/amoeba/report/trend/setStartDate');

export const setEndDate = createAction('@@xcloud/amoeba/report/trend/setEndDate');


export const amoebaReportReducer = handleActions(
    {
        [setTrends]: (state, { payload: data }) => {
            return {
                ...state,
                trends: data
            }
        },
        [openTrends]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                subInfo: data.subInfo || {},
                orgDim: data.orgDim || '',
                companyIds: data.companyIds || '',
                dptIds: data.dptIds || '',
                subIds: data.subIds || '',
                timeDim: data.timeDim || '',
                startDate: data.startDate || '',
                endDate: data.endDate || '',
                showDpts: data.showDpts || []
            }
        },
        [closeTrends]: (state, { payload: data }) => {
            return {
                ...state,
                open: false,
                subInfo: {},
                orgDim: '',
                companyIds: '',
                dptIds: '',
                subIds: '',
                timeDim: '',
                startDate: '',
                endDate: '',
                showDpts: []
            }
        },
        [setStartDate]: (state, { payload: data }) => {
            return {
                ...state,
                startDate: data
            }
        },
        [setEndDate]: (state, { payload: data }) => {
            return {
                ...state,
                endDate: data
            }
        },
    },
    initialState
)

export default amoebaReportReducer

function _wrapData(resList, orgDim, showDpts) {
    const trends = [];
    // 根据orgDim做trend整合
    resList.filter(item => {
        if (orgDim === 'ALLCOMPANY') {
            return true
        }
        if (orgDim === 'COMPANY') {
            if (showDpts.includes(item.dptId)) {
                return true;
            }
        }
        if (orgDim === 'DEPARTMENT') {
            return true;
        }
        return false;
    }).forEach(item => {
        if (orgDim === 'ALLCOMPANY') {
            const dateIndex = _.findIndex(trends, { year: item.year, month: item.month, day: item.day });
            if (dateIndex < 0) {
                trends.push({
                    ...item,
                    companies: [
                        { companyId: item.companyId, sum: item.sum || 0 }
                    ]
                })
            } else {
                trends[dateIndex].sum = trends[dateIndex].sum + item.sum;
                trends[dateIndex].companies.push(
                    { companyId: item.companyId, sum: item.sum || 0 }
                )
            }
        }
        if (orgDim === 'COMPANY') {
            const dateCompanyIndex = _.findIndex(trends, { year: item.year, month: item.month, day: item.day, companyId: item.companyId });
            if (dateCompanyIndex < 0) {
                trends.push({
                    ...item,
                    departments: [
                        { dptId: item.dptId, sum: item.sum || 0 }
                    ]
                })
            } else {
                trends[dateCompanyIndex].sum = trends[dateCompanyIndex].sum + item.sum;
                trends[dateCompanyIndex].departments.push(
                    { dptId: item.dptId, sum: item.sum || 0 }
                )
            }
        }
    })
    return trends
}
