import React, { Component } from 'react';
import { connect } from 'react-redux';
import TenantList from "../../components/TenantManagement/TenantList";
import PropTypes from "prop-types";
import { getTenants, initUpdate } from '../../redux/modules/tenant';

class TenantListContainer extends Component {
    static propTypes = {
        tenants: PropTypes.object,
        isloading: PropTypes.bool
    };

    componentDidMount() {
        this.props.onhandleInitTenantList({
            offset: 0,
            size: 5,
            sort: '-update_time'
        });
    }

    detailedInfo(event, tid) {
        this.props.onhandleOpen(tid);
    }

    render() {
        return (
            <TenantList
                tenants={this.props.tenants}
                onDetailedInfo={this.detailedInfo.bind(this)}
                getTenantList={this.props.onhandleInitTenantList}
                isloading={this.props.isloading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tenants: state.tenant.tenants,
        isloading: state.tenant.isloading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitTenantList: (query) => {
            dispatch(getTenants(query))
        },
        onhandleOpen: (tid) => {
            dispatch(initUpdate(tid));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TenantListContainer)
