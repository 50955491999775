import React from 'react';
import withStyles from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Archive, Add, DeleteOutlined } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { localization, options } from "../../../../../utils/mtable";
import Button from "@material-ui/core/Button";
import { getDateByTimestamp } from '../../../../../utils/datetime';
import _ from 'lodash';



const DeviceDoc = props => {

    const {
        classes,
        onhandleDelete,
        userProfile,
        tabValue,
        index,
        // onhandleBack,
        deviceDoc,
        // isView,
        onOpenUploadModal,
        doctype,
        onDownLoadDoc,
        isDeviceEdit
    } = props;

    const renderTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }
    const renderDocType = (row) => {
        let label = ''
        if (doctype && doctype.length > 0) {
            let value = _.find(doctype, function (o) { return o.code === row.docType })
            if (value) {
                label = value.name
            }
        }
        return label
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '文档描述',
                field: 'docDesc',
                align: 'left',
                render: row => row ? <Typography>{row.docDesc}</Typography> : '',
            },
            {
                title: '文档类型',
                field: 'docType',
                align: 'left',
                render: row => row ? <Typography>{renderDocType(row)}</Typography> : '',
            },
            {
                title: '上传时间',
                field: 'createTime',
                align: 'left',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            }
        ]

        return columns
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }

    //  不受变更单影响：设备文档
    // const devEditable = checkPermission() && !isView && !isDeviceEdit;
    const devEditable = checkPermission() && isDeviceEdit;

    return (
        <div alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={classes.planitemroot}>
                <Grid className={
                    clsx(classes.detailtable, {
                        [classes.tableToolbarEditable]: devEditable,
                    })
                }>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        localization={localization}
                        columns={assemblycolumns()}
                        data={deviceDoc}
                        options={{
                            ...options,
                            showTitle: false,
                            padding: 'dense',
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            toolbar: devEditable,
                        }}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        actions={devEditable ?
                            [rowData => ({
                                // icon: 'archive',
                                icon: () => <Archive color={'primary'} />,
                                tooltip: '下载',
                                onClick: (event, rowData) => {
                                    onDownLoadDoc(rowData)
                                }
                            }),
                            {
                                icon: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}
                                    onClick={onOpenUploadModal}
                                >
                                    新建
                                </Button>,
                                tooltip: '上传文档',
                                isFreeAction: true,
                                onClick: (event) => {
                                    onOpenUploadModal()
                                },
                            },
                            rowData => ({
                                icon: () => <DeleteOutlined color={'error'} />,
                                tooltip: '删除',
                                onClick: (event, rowData) => {
                                    onhandleDelete(rowData)
                                },
                            })
                            ] : null}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    );
};
DeviceDoc.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    deviceDoc: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    onOpenUploadModal: PropTypes.func.isRequired,
    doctype: PropTypes.array.isRequired,
    onDownLoadDoc: PropTypes.func.isRequired,
    isDeviceEdit: PropTypes.bool.isRequired,
};

export default (withStyles(DeviceDoc));