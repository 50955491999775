import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorkOrderWithOperationPlanList from '../../components/OperationManagement/WorkOrder/WorkOrderList/WorkOrderWithOperationPlanList';
import {
    initWorkOrderWithOperationPlan
} from "../../redux/modules/operation";
import { cleanmodal } from '../../redux/modules/modal';
import PropTypes from "prop-types";

class WorkOrderWithOperationPlanListContainer extends Component {

    static propTypes = {
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
        optCategories: PropTypes.array,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
    };

    componentDidMount() {
        let id = this.props.parameters.orderId
        this.props.onhandleInitWorkOrderProcess(id);
    }

    render() {
        return (
            <div>
                <WorkOrderWithOperationPlanList
                    planDetails={this.props.planDetails.list}
                    orderTypeList={this.props.orderTypeList}
                    departmentList={this.props.departmentList}
                    companyList={this.props.companyList}
                    optCategories={this.props.optCategories}
                    catList={this.props.catList}
                    workOrderList={this.props.workOrderList}
                    accCatList={this.props.accCatList}
                    constant={this.props.constant}
                    userProfile={this.props.userProfile}
                    onhandleClose={this.props.onhandleCleanmodal.bind(this)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        orderTypeList: state.operation.orderTypeList,
        departmentList: state.constant.departments,
        companyList: state.constant.companies,
        planDetails: state.operationPlan.planDetails,
        optCategories: state.operationRule.optCategories,
        catList: state.trees.deviceCategoryList,
        accCatList: state.accessory.catList,
        workOrderList: state.operation.workOrderList,
        constant: state.constant,
        roleList: state.role.roleList,
        parameters: state.modal.parameters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitWorkOrderProcess: (data) => {
            dispatch(initWorkOrderWithOperationPlan(data))
        },
        // 关闭弹窗
        onhandleCleanmodal: () => {
            dispatch(cleanmodal());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrderWithOperationPlanListContainer)
