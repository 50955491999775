import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/EnergyPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDepartmentsByRole } from "@/utils/userUtils";
import * as _ from 'lodash';

const EnergyPricingComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        onhandleRuleUpdate,
        onhandleCompanyChange,
        serviceBudgets,
        currentUserInfo,
        department,
        userProfile,
        onhandleShowHistory,
        onhandleShowHistoryW,
        onhandleShowHistoryS,
        roleFlag,
    } = props;

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,
    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }

    const energyassemblyDetailColumns = () => {
        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '电费单耗',
                field: 'ratio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.ratio}</Typography>,
            },
            // {
            //     title: '电费单价',
            //     field: 'variableUnitPrice',
            //     type: 'numeric',
            //     align: 'right',
            //     width: 100,
            //     render: row => <Typography>{row.variableUnitPrice}</Typography>,
            // }, 
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>度/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistory(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '水费单耗',
                field: 'wRatio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.wRatio}</Typography>,
            },
            // {
            //     title: '水费单价',
            //     field: 'wVariableUnitPrice',
            //     type: 'numeric',
            //     align: 'right',
            //     width: 100,
            //     render: row => <Typography>{row.wVariableUnitPrice}</Typography>,
            // }, 
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>吨/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryW(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '蒸汽费单耗',
                field: 'sRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.sRatio}</Typography>,
            },
            // {
            //     title: '蒸汽费单价',
            //     field: 'sVariableUnitPrice',
            //     type: 'numeric',
            //     align: 'right',
            //     width: 120,
            //     render: row => <Typography>{row.sVariableUnitPrice}</Typography>,
            // }, 
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>立方/{row.unit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const energyassemblyData = () => {
        const eBudgets = _.filter(serviceBudgets, { 'subAlias': '电费' })
        const wBudgets = _.filter(serviceBudgets, { 'subAlias': '水费' })
        const sBudgets = _.filter(serviceBudgets, { 'subAlias': '蒸汽费' })
        _.forEach(eBudgets, value => {
            _.forEach(wBudgets, w => {
                if (value.serviceId === w.serviceId) {
                    value.wBudgetId = w.budgetId
                    value.wVariableUnitPrice = w.variableUnitPrice
                    value.wRatio = w.ratio
                    value.wVariableSub = w.variableSub
                }
            })
            _.forEach(sBudgets, s => {
                if (value.serviceId === s.serviceId) {
                    value.sBudgetId = s.budgetId
                    value.sVariableUnitPrice = s.variableUnitPrice
                    value.sRatio = s.ratio
                    value.sVariableSub = s.variableSub
                }
            })
        })
        return eBudgets
    }

    const checkDpt = () => {
        const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
        return dptId === department.dptId || roleFlag ? true : false;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item className={classes.processLine}>
                    </Grid>
                    <Grid>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            columns={energyassemblyDetailColumns()}
                            data={energyassemblyData()}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={detailTableStyle}
                            editable={checkDpt() ? {
                                onRowUpdate: (newData, oldData) => onhandleRuleUpdate(newData, oldData)
                            } : {}}
                            actions={
                                [
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '电费修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistory(event, rowData)
                                    //     }
                                    // },
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '水费修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistoryW(event, rowData)
                                    //     }
                                    // },
                                    {
                                        icon: () => { return <HistoryIcon /> },
                                        tooltip: '蒸汽费修改历史',
                                        onClick: (event, rowData) => {
                                            onhandleShowHistoryS(event, rowData)
                                        }
                                    },
                                ]
                            }
                            title={<Typography variant='h3'>能耗预算规则</Typography>}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

EnergyPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    subCompanyList: PropTypes.array,
    varFeeRuleList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,

};

EnergyPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(EnergyPricingComponent);