import React, { Component } from 'react';
import "./index.css";
import { ReactComponent as Close } from '../../../resource/closeBlack.svg';
import FillDeviceInfo from '../../containers/FillDeviceInfo';
import DeviceDetail from '../../containers/DeviceDetail';
import FillDeviceGroupInfo from '../../containers/FillDeviceGroupInfo';
import DeviceGroupDetail from '../../containers/DeviceGroupDetail ';
import MessageDetail from '../../containers/MessageDetail';
export class Dialog extends Component {
  constructor(props) {
    super(props)
    this._cleanDialog = this._cleanDialog.bind(this);
  }

  _cleanDialog() {
    const { cleanDialog } = this.props;
    cleanDialog();
  }

  _childcompant = () => {
    if (this.props.children === 'deviceInfo') {
      return (
        <React.Fragment>
          <FillDeviceInfo
            Stage={this.props.getStage()}
            PreviewStage={this.props.getPreviewStage()}
          />
        </React.Fragment>
      );
    } else if (this.props.children === 'deviceGroupInfo') {
      return (
        <React.Fragment>
          <FillDeviceGroupInfo
            Stage={this.props.getStage()}
            PreviewStage={this.props.getPreviewStage()} />
        </React.Fragment>
      );
    } else if (this.props.children === 'deviceDetail') {
      return (
        <React.Fragment>
          <DeviceDetail />
        </React.Fragment>
      );
    } else if (this.props.children === 'deviceGroupDetail') {
      return (
        <React.Fragment>
          <DeviceGroupDetail />
        </React.Fragment>
      );
    } else if (this.props.children === 'messageDetail') {
      return (
        <React.Fragment>
          <MessageDetail />
        </React.Fragment>
      );
    }

    return null;
  };
  _mainDialog = () => {
    return (
      <div className="dialog" style={this.props.style}>
        {this.props.show ?
          (
            <React.Fragment>
              {this.props.showIcon ?
                <span onClick={this._cleanDialog.bind(this)}>
                  <Close className="closeicon" />
                </span>
                : null
              }
              <div>
                {this._childcompant()}
              </div>
            </React.Fragment>
          )
          : null
        }
      </div>
    );
  };

  render() {
    return (
      this._mainDialog()
    );
  }
}