import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import FiveSPlanHeader from "@/containers/InspectionManagement/FiveSPlan/FiveSPlanHeader";
import FiveSPlanList from "@/containers/InspectionManagement/FiveSPlan/FiveSPlanList";
import { Card } from '@material-ui/core';

const FiveSPlan = props => {

    const { classes, location } = props;
    const isBack = location ? location.state : false;

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <FiveSPlanHeader isBack={isBack} />
                <FiveSPlanList />
            </Card>
        </div>
    );
};
FiveSPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(FiveSPlan);
