import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
    open: false,
    treatmentScheme: {
        hid: '',
        schemeCat: '',
        measure: '',
        dptId: '',
        companyId: '',
        head: '',
        headName: '',
        cost: 0,
        targetDate: '',
        pretreatment: '',
        sanction: '',
        accepter: '',
        accepterName: '',
        opentext: '',
        creator: '',
        createTime: '',
        revisor: '',
        updateTime: '',
        file: {}
    },
    isTreatmentHead: false,
    confirmCallback: null,
    closeCallback: null,
    editable: false
};

export const openTreatmentSchemePannel = createAction('@@xcloud/inspectionTreatmentScheme/open',
    (
        confirmCallback, closeCallback, hazard, treatmentScheme, isTreatmentHead, editable
    ) => ({
        confirmCallback, closeCallback, hazard, treatmentScheme, isTreatmentHead, editable
    })
);

export const closeTreatmentSchemePannel = createAction('@@xcloud/inspectionTreatmentScheme/close');

export const changeTreatmentScheme = createAction('@@xcloud/inspectionTreatmentScheme/changeTreatmentScheme');

// Actions

export const InspectionTreatmentSchemeReducer = handleActions(
    {
        [openTreatmentSchemePannel]: (state, { payload: data }) => {
            // pretreatmentDocsBack 用来判断更新时是否有新上传文件
            if (data.treatmentScheme) {
                const { pretreatmentDocs } = data.treatmentScheme;
                if (pretreatmentDocs && pretreatmentDocs.length && pretreatmentDocs.length > 0) {
                    data.treatmentScheme.pretreatmentDocsBack = _.cloneDeep(pretreatmentDocs);
                }
            }
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                treatmentScheme: data.treatmentScheme ?

                    {
                        ...data.treatmentScheme,
                        measure: data.treatmentScheme.measure ? data.treatmentScheme.measure : '',
                        cost: data.treatmentScheme.cost ? data.treatmentScheme.cost : 0,
                        pretreatment: data.treatmentScheme.pretreatment ? data.treatmentScheme.pretreatment : '',
                        file: data.treatmentScheme.file ? data.treatmentScheme.file : undefined
                    }
                    : {
                        hid: data.hazard.hid,
                        schemeCat: '',
                        measure: '',
                        dptId: '',
                        companyId: '',
                        head: '',
                        headName: '',
                        cost: 0,
                        targetDate: '',
                        pretreatment: '',
                        sanction: '',
                        accepter: data.hazard.recorderId || '',
                        accepterName: '',
                        file: undefined
                    },
                isTreatmentHead: data.isTreatmentHead ? data.isTreatmentHead : false,
                editable: data.editable ? data.editable : false
            };
        },
        [closeTreatmentSchemePannel]: (state,) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeTreatmentScheme]: (state, { payload: data }) => {
            return {
                ...state,
                treatmentScheme: data,
            };
        }
    },
    initialState
);

export default InspectionTreatmentSchemeReducer;