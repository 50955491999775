import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dialogContent: {
        minWidth: 500
    },
    itemGrid: {
        padding: theme.spacing(2),
    }
});


export default withStyles(styles);