import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(0),
        padding: 20
    },
    label: {
        marginTop: 7
    },
    gridContainer: {
        margin: 10
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    detailtable: {
        // padding: theme.spacing(4)
    },
    materialtable: {
        boxShadow: 'none'
    },
    textField: {
        width: '90%',
    },
    exception: {
        color: '#f4511e',
        fontWeight: 'bold'
    },
    invalidRecord: {
        color: '#848484'
    },
    /* 巡检计划的状态强调显示 */
    new: {
        width: 60,
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    active: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    expired: {
        width: 60,
        backgroundColor: colors.orange[400],
        color: theme.palette.white,
    },
    done: {
        width: 60,
        backgroundColor: colors.green[500],
        color: theme.palette.white,
    },
    expiredDone: {
        width: 60,
        backgroundColor: colors.green[300],
        color: theme.palette.white,
    },
    pending: {
        width: 60,
        backgroundColor: colors.lime[500],
        color: theme.palette.white,
    },
});

export default withStyles(styles);