import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Chip } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import CancelIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomerPage from '../ComponentsPannel/CustomerPage';
import DeviceDocPage from '../../containers/DeviceManagement/DeviceInfo/DeviceDocPage';
import MajorHazardDocPage from '../../containers/MajorHazardSources/MajorHazardDocPage';
import MaintainDocPage from '../../containers/DeviceManagement/MaintainDoc/MaintainDocPage';
import withStyles from './styles';

const CommonFileUpload = (props) => {
  const { classes, title, fileType, maxSize, multiple, children, showStyle, confirmText } = props;
  const [isDraging, setDragging] = React.useState(false);
  const [isUploading, setUploadStatus] = React.useState(false);
  const [files, setFiles] = React.useState(null);
  const inputRef = React.createRef();
  const fileArea = classes.filearea + ' ' + (isDraging ? classes.dragging : '');

  const childcompant = (children) => {
    // console.log(children)
    if (children === 'customer') {
      return (
        <React.Fragment>
          <CustomerPage />
        </React.Fragment>
      );
    } else if (children === 'DeviceDocPage') {
      return (
        <React.Fragment>
          <DeviceDocPage />
        </React.Fragment>
      );
    } else if (children === 'MaintainDocPage') {
      return (
        <React.Fragment>
          <MaintainDocPage />
        </React.Fragment>
      );
    } else if (children === 'MajorHazardDoc') {
      return (
        <React.Fragment>
          <MajorHazardDocPage />
        </React.Fragment>
      );
    }
    return children;
  };

  const generateFileList = files => {
    const { classes } = props;
    const fileList = [];

    if (!!files && files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        if (i < 100) {
          fileList.push(
            <Chip key={'file' + i} label={files[i].name} className={classes.fileItem} />
          );
        } else {
          fileList.push(
            <span key={'fileCount'} className={classes.fileItem}>{' ... ' + files.length + ' files'}</span>
          );
          break;
        }
      }
    }
    return fileList;
  };

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.value = '';
    setFiles(null);
  };

  const handleCloseDialog = () => {
    props.onClose();
  };

  const handleUploadFile = e => {
    const { files } = e.target;
    const uploadFiles = props.onFileSelected(files);
    if (uploadFiles) {
      setFiles(uploadFiles);
    }
  };

  const handleUploadClick = (files, values, event) => {
    props.onConfirm(files, setUploadStatus, values);
  };

  const browserFile = () => {
    inputRef.current.click();
  };

  const preventEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    // check upload file
    const uploadFiles = props.onFileSelected(files);
    setDragging(false);
    setFiles(uploadFiles);
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.title}>
          {title || 'Upload'}
          {showStyle !== 'inline' &&
            <IconButton
              edge="end"
              aria-label="close dialog"
              className={classes.closeIcon}
              onClick={handleCloseDialog.bind(this)}
            >
              <CancelIcon />
            </IconButton>}
        </div>
        <div className={classes.content}>
          <form>
            <div className={fileArea}
              onDrag={preventEvent.bind(this)} onDragStart={preventEvent.bind(this)}
              onDragEnd={preventEvent.bind(this)} onDragEnter={preventEvent.bind(this)}
              onDragOver={onDragOver.bind(this)} onDragLeave={onDragLeave.bind(this)}
              onDrop={onDrop.bind(this)}>
              <div className={classes.filename}>
                <input type='file' name='file'
                  accept={fileType}
                  multiple={multiple}
                  ref={inputRef}
                  onChange={handleUploadFile.bind(this)}
                  className={classes.uploadfile} />
                {
                  files && <div className={classes.uploadname}>{generateFileList(files)}</div>
                }
                {
                  !files
                  && <div className={classes.dragDropHint}>
                    <CloudUploadIcon className={classes.uploadIcon} fontSize="large" />
                    <div>请拖拽文件至此处</div>
                    <div className={classes.browseFile} onClick={browserFile.bind(this)}>
                      <StorageIcon />
                      或者点击上传
                        </div>
                    <div className={classes.fileFormat}>
                      支持格式: <span style={{ fontWeight: 'bold' }}>{fileType}</span>
                      <br />
                      支持大小: <span style={{ fontWeight: 'bold' }}>{maxSize}</span>
                    </div>
                  </div>
                }
              </div>
              <div>
                {childcompant(children)}
              </div>
            </div>
            <div className={classes.buttonarea}>
              {
                files && !isUploading
                && <Button
                  className={classes.buttons}
                  color='primary'
                  onClick={handleReset.bind(this)}>
                  清空文件
                </Button>
              }
              {
                files && !isUploading
                && <Button
                  className={classes.buttons}
                  variant="contained"
                  color='primary'
                  onClick={handleUploadClick.bind(this, files)}
                  style={{ width: '70px' }} >
                  {confirmText}
                </Button>
              }
              {
                !files && !isUploading
                && <Button
                  className={classes.buttons}
                  variant="contained"
                  color='primary'
                  disabled
                  style={{ width: '70px' }}>
                  {confirmText}
                </Button>
              }
              {
                files && isUploading
                && <Button className={classes.buttons} variant="contained" color='primary' disabled >
                  <CircularProgress size={24} className={classes.loading} />
                  {confirmText + '中...'}
                </Button>
              }
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

CommonFileUpload.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

CommonFileUpload.defaultProps = {
  title: '上传文件',
  confirmText: '上传',
  fileType: '.xls',
  note: 'Drag and drop or browse from your device for files',
  multiple: false
};

export default withStyles(CommonFileUpload);
