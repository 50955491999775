import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecordListComponent from '../../../../components/OnsiteInspectionManagement/RecordList/RecordListComponent';
import {
    getRecordList,
    initRecordListPage,
    backToList,
    setRecordListSize,
    exportRecordList
} from '../../../../redux/modules/onsiteInspectionPlan';
import { getCompanyId } from "../../../../utils/userUtils";
import * as _ from 'lodash';
import { createmodal } from "../../../../redux/modules/modal";
import { downloadURL } from '../../../../redux/modules/minio'

class OnsiteInspectionRecordListContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    componentWillMount() {
        this.props.onhandleInitPage();
    }

    handleListSize = (size) => {
        this.props.onhandleUpdateEditSize(size)
    }

    handleBack() {
        this.props.onhandleBack();
    }

    handleExport(planId) {
        this.props.onhandleExport(planId);
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    handleDownload = (event, docList) => {
        if (docList && docList.length) {
            const promiseArr = [];
            docList.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.onsite-inspection.onsite-inspection-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docList.forEach((doc, index) => {
                    doc.url = res[index].payload;
                })
                this.props.onhandleOpenAttachmentDocs({
                    docs: docList,
                    title: '巡检记录图像'
                });
            }).catch(err => {

            });
        }
    }

    render() {
        return (
            <div>
                <RecordListComponent
                    userProfile={this.props.userProfile}
                    currentPlan={this.props.currentPlan}
                    onhandleBack={this.handleBack.bind(this)}
                    getRecordList={this.props.onhandleSearch}
                    recordList={this.props.recordList}
                    companyList={this.trimCompanyList()}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    onSizeChange={this.handleListSize.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleDownload={this.handleDownload.bind(this)}
                    users={this.props.users}
                    departmentList={this.props.departmentList}
                    unit={this.props.unit}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recordList: state.onsiteInspectionPlan.recordList,
        userProfile: state.auth.userProfile,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        currentPlan: state.onsiteInspectionPlan.currentPlan,
        users: state.onsiteInspectionPlan.users,
        departmentList: state.onsiteInspectionPlan.departmentList,
        unit: state.onsiteInspectionPlan.unit,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitPage: () => {
            dispatch(initRecordListPage());
        },
        onhandleUpdateEditSize: (size) => {
            dispatch(setRecordListSize(size));
        },
        onhandleSearch: (offset, size, sort, planId) => {
            dispatch(getRecordList(offset, size, sort, planId, true));
        },
        onhandleBack: () => {
            dispatch(backToList());
        },
        onhandleExport: (planId) => {
            dispatch(exportRecordList(planId));
        },
        onhandleOpenAttachmentDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionRecordListContainer);