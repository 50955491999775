import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import GoalPlanNew from "../../../components/LeadershipManagement/AnnualGoal/GoalPlanNew";
import GoalPlan from "../../../components/LeadershipManagement/AnnualGoal/GoalPlan";
import { getCurrentGoalPlan, executeProcess, updateGoalDetails, goalApprove } from "../../../redux/modules/leadershipGoal";
import { getCurrentProcessStatus } from "../../../redux/modules/process";
import ApprovePannel from "../ApprovePannel";
import { openApprovePannel } from "../../../redux/modules/approvePannel";
import { getBusinessUnitsByRole, getRole, getCompanyName } from "../../../utils/userUtils";

import * as _ from 'lodash';

class GoalPlanContainer extends Component {
    static propTypes = {
        goal: PropTypes.object,
        constant: PropTypes.object,
        process: PropTypes.array,
        goalDetails: PropTypes.array,
    };

    componentDidMount() {
        const {
            onhandleGetCurrentGoalPlan,
            onhandleGetCurrentProcessStatus,
        } = this.props
        onhandleGetCurrentGoalPlan()
        onhandleGetCurrentProcessStatus((data) => {
            // console.log(data)
        })
    }

    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected' || action.review) {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleExecuteProcess(action) {
        //let record = getRecordByCompanyId(this.props.processes, this.props.currentUserInfo.companyId)
        const { goalDetails, currentUserInfo, businessUnits, goalPlan, currentProcessStatus,
            onhandleProcess, onhandleGetCurrentGoalPlan, onhandleGetCurrentProcessStatus } = this.props
        let actionParams = []
        if (!action.distributed) {
            let goalDetailFilter = null
            if (action.statusCode === 'Rejected') {//驳回本级，全业务线
                goalDetailFilter = _.filter(goalDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId
                })
            } else if (currentProcessStatus.statusCode === 'Rejected' || currentProcessStatus.statusCode === 'Drafting') { // 不是驳回，是本级提交，分业务线。
                goalDetailFilter = _.filter(goalDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId && _.find(businessUnits, function (o) {
                        return o.unitId === detail.unitId
                    })
                })
            }
            if (goalDetailFilter) {
                actionParams.push({
                    handle: "updateGoalDetails",
                    goalDetails: _.map(goalDetailFilter, gd => { return { dtlId: gd.dtlId } }), //只需要传ID
                    status: action.statusCode === 'Rejected' ? 'Rejected' : 'Submitted'
                })
            }
        }
        if (action.statusCode === 'Rejected' && action.distributed) {//驳回下级，分业务线 - 暂时不分业务线

            let childGoalDetails = _.map(goalDetails, gd => { return _.find(gd.extras, e => { return e.companyId === action.companyId }) })
            // let childGoalDetails = _.filter(goalDetails, (detail) => { //按company和unit过滤
            //     return detail.companyId === action.companyId
            //     //&& _.find(this.props.businessUnits, function (o) { return o.unitId === detail.unitId }) //暂时不分业务线
            // })
            if (childGoalDetails) {
                actionParams.push({
                    handle: "updateGoalDetails",
                    goalDetails: _.map(childGoalDetails, gd => { return { dtlId: gd.dtlId } }),
                    status: 'Rejected'
                })
            }

        }
        if (action.review) { //增加会审记录
            actionParams.push({
                handle: "addGoalReview",
                goalId: goalPlan.goalId,
                comment: action.comment,
            })
        }
        let processParams = {
            action: action,
            actionParams: actionParams
        }

        onhandleProcess(processParams, () => {
            onhandleGetCurrentGoalPlan()
            onhandleGetCurrentProcessStatus()
        })
    }

    handleUpdate(goalDetail, oldGoalDetail) {
        var details = []
        if (goalDetail.edit_vals !== oldGoalDetail.edit_vals) {
            let vals = goalDetail.vals
            vals = JSON.parse(vals)
            let newVals = {
                ...vals,
                $target: Number(goalDetail.edit_vals)
            }
            let detail = {
                dtlId: goalDetail.dtlId,
                vals: newVals
            }
            details.push(detail)
        }
        for (let e of goalDetail.extras) {
            if (goalDetail['edit_vals_' + e.companyId] !== oldGoalDetail['edit_vals_' + e.companyId]) {
                let vals = e.vals
                vals = JSON.parse(vals)
                let newVals = {
                    ...vals,
                    $target: Number(goalDetail['edit_vals_' + e.companyId])
                }
                let detail = {
                    dtlId: e.dtlId,
                    vals: newVals
                }
                details.push(detail)
            }
        }
        if (details.length > 0) {
            const { onhandleUpdate, onhandleGetCurrentGoalPlan } = this.props
            onhandleUpdate(details, () => {
                onhandleGetCurrentGoalPlan()
            })
        }
    }

    handleGoalApprove(goalId) {
        const { onhandleGoalApprove, onhandleGetCurrentGoalPlan } = this.props
        onhandleGoalApprove(goalId).then(
            () => onhandleGetCurrentGoalPlan()
        )
    }
    render() {
        return (
            <div>
                <GoalPlan
                    goalDetails={this.props.goalDetails}
                    goalPlan={this.props.goalPlan}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    currentProcessStatus={this.props.currentProcessStatus}
                    companyName={this.props.companyName}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onhandleGoalApprove={this.handleGoalApprove.bind(this)}

                />
                <ApprovePannel />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        goalPlan: state.leadershipGoal.currentGoalPlan.goalPlan || {},
        goalDetails: state.leadershipGoal.currentGoalPlan.goalDetails || [],
        constant: state.constant,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        companyName: getCompanyName(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        currentProcessStatus: state.process.currentProcessStatus.SafetyGoal || {},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('SafetyGoal', null, callback))
        },
        onhandleGetCurrentGoalPlan: (callback) => {
            dispatch(getCurrentGoalPlan(callback))
        },
        onhandleUpdate: (goalDetails, callback) => {
            dispatch(updateGoalDetails(goalDetails, callback))
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeProcess(actionParams, callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleGoalApprove: (goalId) => {
            return dispatch(goalApprove(goalId))
        }

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GoalPlanContainer))