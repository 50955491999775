import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import JobList from "../../../containers/SystemManagement/JobManagement/JobList";

const JobManagement = props => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <JobList/>
            </div>
        </div>
    );
};
JobManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(JobManagement);
