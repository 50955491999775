import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    MenuItem,
    DialogActions,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import * as _ from 'lodash';



const DeviceTransfer = props => {
    const {
        classes,
        open,
        selectedCompanyId,
        currentDevice,
        companies,
        onhandleClose,
        onhandleChange,
        onhandleTransfer,
    } = props;


    const renderOldCompany = () => {
        if (currentDevice || currentDevice.companyId) {

            const oldCompany = _.find(companies, { companyId: currentDevice.companyId })
            if (oldCompany) {
                return oldCompany.companyAlias
            }
        }
        return ''
    }

    const transferDisabled = () => {
        if (!selectedCompanyId) {
            return true
        }
        if (selectedCompanyId === currentDevice.companyId) {
            return true
        }
        return false
    }



    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} fullWidth maxWidth={'xs'}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{'设备调拨分厂'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid container direction="column">
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='原所属分厂'
                                    name='oldCompanyId'
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={renderOldCompany()}
                                />
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <Grid container justify='space-between'>
                                    <Grid item> </Grid>
                                    <Grid item> <ArrowDownwardIcon /></Grid>
                                    <Grid item> </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    select
                                    fullWidth
                                    label='新所属分厂'
                                    name='selectedCompanyId'
                                    variant="outlined"
                                    value={selectedCompanyId}
                                    onChange={onhandleChange}>
                                    {companies && companies.map((i) => (
                                        <MenuItem key={i.companyId} value={i.companyId}>
                                            {i.companyAlias}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                    <Button onClick={onhandleTransfer} variant="contained" color="primary" disabled={transferDisabled()}> 生成调拨单
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};


DeviceTransfer.propTypes = {
    classes: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(DeviceTransfer);
