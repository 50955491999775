import React, { Component } from 'react';
import { connect } from 'react-redux';
import RouteDetailComponent from '../../../../components/OnsiteInspectionManagement/RouteDetail/RouteDetailComponent';
import {
    insertRoute,
    modifyRoute,
    executeProcess,
    backToList,
    setCurrentRoute,
    setIsRouteChange,
    setRouteError,
    addPoint,
    deletePoint,
    updateItem,
    selectItem,
    insertPoint,
    updatePoint,
    updatePointModel,
    // routeRestart,
    setPointEditMode,
    setPointSeq,
    setIgnoreInstall,
    setIsRouteEditMode,
    setRouteList,
    setIsProcessNeedMoveOneStep,
    setPointList,
    setIndoorInspectionItemList,
    updateInspectionItemList
} from '../../../../redux/modules/onsiteInspection';
import * as _ from 'lodash';
import { getCompanyId } from "../../../../utils/userUtils";
import { getTreePartById } from '../../../../utils/constant';
import { createMessage } from '../../../../redux/modules/message';
import { createmodal } from '../../../../redux/modules/modal';
import { openConfirmDialog } from '../../../../redux/modules/confirmDialog';
import { getBusinessUnitsByRole, getRole } from "../../../../utils/userUtils";
import { getRecordByUnitId, isUnitBranch, getMasterRecordByRecordId } from "../../../../utils/processUtils";
import { openApprovePannel } from "../../../../redux/modules/approvePannel";
import ApprovePannel from "../../../LeadershipManagement/ApprovePannel";

const mapCurrentRecord = (processes, currentUserInfo, userProfile, currentRouteProcess) => {
    if (!processes) {
        return {}
    }
    let recordId = currentRouteProcess.recordId;
    let newProcess = _.filter(processes, function (o) { return _.find(o.records, function (p) { return p.recordId === recordId }) });
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecordByRecordId(newProcess, recordId)

    if (isUnitBranch(newProcess)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(newProcess, bu.unitId)
        }
    }
    return record || {}
}

const getProcessCode = (route) => {
    let companyId = _.isObject(route.companyId) ? route.companyId.value : route.companyId
    if (companyId) {
        return `OnsiteInspectionRoute_${companyId}`;
    } else {
        return `OnsiteInspectionRoute`;
    }
}
class OnsiteInspectionRouteDetailContainer extends Component {

    // componentWillMount() {
    //     //let id = this.props.match.params.id;
    // }

    // 任务 巡检路线 流程混淆 XJGL-167
    componentDidUpdate(prevProps) {
        if (this.props.isProcessNeedMoveOneStep) {
            this.props.onhandleSetIsProcessNeedMoveOneStep(false)
            if (this.props.currentProcessStatus.statusCode && this.props.currentProcessStatus.actions
                && this.props.currentProcessStatus.actions.length > 0) {
                if (this.props.currentProcessStatus.statusCode === 'Drafting') {
                    this.props.onhandleRouteEditMode(true)
                } else {
                    this.props.onhandleRouteEditMode(false)
                }

            }
            const actions = _.filter(this.props.currentProcessStatus.actions, (action) => { return action.actionName === "开始审批" || action.actionName === '开始起草' })
            if (actions && actions.length > 0) {
                this.handleProcess(actions[0])
            }
        }
    }

    handleSelect = (value, field) => {
        let newData;
        switch (field) {
            case 'companyId':
                newData = {
                    ...this.props.currentRoute,
                    companyId: value.target.value
                };
                this.props.onhandleUpdateCurrentRoute(newData);
                this.props.onhandleRouteChange(true)
                break;
            case 'inspectionType':
                newData = {
                    ...this.props.currentRoute,
                    inspectionType: value.target.value
                };
                this.props.onhandleUpdateCurrentRoute(newData);
                this.props.onhandleRouteChange(true)
                break;
            case 'confirmRole':
                newData = {
                    ...this.props.currentRoute,
                    confirmRole: value.target.value
                };
                this.props.onhandleUpdateCurrentRoute(newData);
                this.props.onhandleRouteChange(true)
                break;
            default:
            // do nothing
        }
    }

    handleChange = (event, field) => {
        let newData;
        switch (field) {
            case 'routeCode':
                newData = {
                    ...this.props.currentRoute,
                    routeCode: event.target.value
                };
                this.props.onhandleUpdateCurrentRoute(newData);
                this.props.onhandleRouteChange(true)
                break;
            case 'routeName':
                newData = {
                    ...this.props.currentRoute,
                    routeName: event.target.value
                };
                this.props.onhandleUpdateCurrentRoute(newData);
                this.props.onhandleRouteChange(true)
                break;
            default:
            // do nothing
        }
    };


    handleChangeSeq = (value, id) => {
        this.props.onhandleChangeSeq(value, id)
    };

    handleChangeIgnoreInstall = (value, id) => {
        this.props.onhandleChangeIgnoreInstall(value, id)
    };

    handleChangeRecordConfirm = (value) => {
        const newData = {
            ...this.props.currentRoute,
            recordConfirm: value
        };
        if (!value) {
            newData.confirmRole = undefined;
        }
        this.props.onhandleUpdateCurrentRoute(newData);
    };

    handleBack = () => {
        this.props.onhandleBack();
    }

    handleSave = async () => {
        await this.handleCheck(null);
        if (_.findIndex(Object.values(this.props.routeError), function (o) { return o !== ''; }) < 0) {
            // 巡检路线对应的审批流程是否存在？
            let corrProcessExistFlg = false

            this.props.processList.forEach(tmpProcess => {
                if (tmpProcess.status === 'active') {
                    corrProcessExistFlg = true
                }
            });

            if (corrProcessExistFlg) {
                if (this.props.currentRoute.routeId) {
                    this.props.onhandleModify(this.props.currentRoute);
                } else {
                    this.props.onhandleInsert(this.props.currentRoute);
                }
            } else {
                this.props.onhandleSaveWarn('巡检路线没有对应的审批流程,请先创建审批流程后再保存')
            }
        }
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected') {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleProcessConfirm(action, comment) {
        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleExecuteProcess(action) {
        let params = {
            action: action,
            actionParams: {}
        }
        this.props.onhandleProcess(params)
    }


    // handleRestart(event) {
    //     this.props.onhandleRestart()
    // }

    handleUpdatePoint = (point) => {
        this.props.onhandleUpdatePointModel(point);
    }

    handleDeletePoint = (point) => {

        let changeStatus = () => {
            this.props.onhandleDeletePoint(point);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要巡检项目[' + point.deviceName + ']吗?', changeStatus, null, false);
    }

    handleSavePoint = (point) => {
        if (!point.seq) {
            this.props.onhandleSaveCheck(`巡检点序号不能为空`)
            return
        }
        for (let i = 0; i < this.props.pointList.length; i++) {
            let t = this.props.pointList[i]
            if (t.id !== point.id && _.toInteger(point.seq) === _.toInteger(t.seq)) {
                this.props.onhandleSaveCheck(`巡检点序号不能重复`)
                return
            }
        }
        for (let i = 0; i < point.tableItems.length; i++) {
            let t = point.tableItems[i]

            if (!t.tableData) {
                this.props.onhandleSaveCheck(`请选择巡检项目`)
                return
            }
            if (t.tableData.checked && (_.isEmpty(t.method) || _.isEmpty(t.resultType))) {
                this.props.onhandleSaveCheck(`选择的巡检项目中未选定检查手段`)
                return
            }
        }

        let newPointEditMode = _.cloneDeep(this.props.pointEditMode)
        newPointEditMode[point.id] = false
        this.props.onhandlePointView(newPointEditMode)
        if (point.pointId) {
            this.props.onhandleUpdatePoint(point);
        } else {
            this.props.onhandleInsertPoint(point);
        }

        // 缺陷
        // XJGL-129
        // 巡检路线更新人一直显示 创建人
        // 重新渲染页面
        let routes = this.props.routeList
        let currentRoute = this.props.currentRoute
        routes.list.forEach(data => {
            if (data.routeId === currentRoute.routeId) {
                data.revisor = this.props.currentUserInfo.userId
            }
        })
        this.props.onHnadleUpdateRouteList(routes)
    }

    handleItemOrder(itemType, item, type) {

        const { pointList, onHandleSetPointList, onHandleSetIndoorInspectionItemList } = this.props;

        let itemList = [];
        let itemIndex = null;

        let point = null;
        let pointIndex = null;

        console.log(itemType, item, type)

        if (itemType === 'point') {
            pointIndex = _.findIndex(pointList, { 'pointId': item.pointId });
            if (pointIndex < 0) return;
            point = pointList[pointIndex];
            if (!point || !point.tableItems || !point.tableItems.length) return;
            itemList = point.tableItems;
        }
        if (itemType === 'item') {
            const { inspectionItemList } = this.props;
            if (!inspectionItemList || !inspectionItemList.length) return;
            itemList = inspectionItemList;
        }
        itemIndex = _.findIndex(itemList, { 'itemId': item.itemId });
        if (itemIndex < 0) {
            return;
        }
        let newArray = [];

        if (type === 'top') {
            if (itemIndex === 0) {
                return;
            }
            itemList.unshift(itemList.splice(itemIndex, 1)[0]);
        }
        if (type === 'up') {
            if (itemIndex < 1) {
                return;
            }
            itemList[itemIndex] = itemList.splice(itemIndex - 1, 1, itemList[itemIndex])[0];
        }
        if (type === 'down') {
            if (itemIndex >= itemList.length - 1) {
                return;
            }
            itemList[itemIndex] = itemList.splice(itemIndex + 1, 1, itemList[itemIndex])[0];
        }
        if (type === 'bottom') {
            if (itemIndex === itemList.length - 1) {
                return;
            }
            itemList.push(itemList.splice(itemIndex, 1)[0]);
        }
        newArray = [...itemList];

        if (itemType === 'point') {
            point.tableItems = newArray;
            point.order = true;
            pointList[pointIndex] = _.cloneDeep(point);
            const newPointList = _.cloneDeep(pointList);
            onHandleSetPointList(newPointList);
        }
        if (itemType === 'item') {
            onHandleSetIndoorInspectionItemList(newArray);
        }
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    trimConfirmRoleList() {
        return this.props.confirmRoleList
            .map(item => ({ label: item.roleName, value: item.roleId, companyId: item.companyId }))
    }

    handleCheck = (field) => {

        let err = {};

        if (!field || field === 'routeCode') {
            if (this.props.currentRoute.routeCode) {
                err['routeCode'] = '';
            } else {
                err['routeCode'] = '请填写路线编码';
            }
        }

        if (!field || field === 'inspectionType') {
            if (this.props.currentRoute.inspectionType) {
                err['inspectionType'] = '';
            } else {
                err['inspectionType'] = '请选择巡检类型';
            }
        }

        if (!field || field === 'routeName') {
            if (this.props.currentRoute.routeName) {
                err['routeName'] = '';
            } else {
                err['routeName'] = '请填写路线名称';
            }
        }

        if (!field || field === 'companyId') {
            if (this.props.currentRoute.companyId) {
                err['companyId'] = '';
            } else {
                err['companyId'] = '请选择所属单位';
            }
        }
        if (!field || field === 'confirmRole') {
            if (this.props.currentRoute.recordConfirm) {
                if (this.props.currentRoute.confirmRole) {
                    err['confirmRole'] = '';
                } else {
                    err['confirmRole'] = '请选择确认角色';
                }
            } else {
                err['confirmRole'] = '';
            }
        }

        let newError = {
            ...this.props.routeError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleAddPoint = () => {
        let companyId = _.isObject(this.props.currentRoute.companyId) ? this.props.currentRoute.companyId.value : this.props.currentRoute.companyId
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        //添加巡检点
                        this.props.onhandleAddPoint(this.props.selectedDevices)
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    handleSaveItemOrder = (inspectionItemList) => {
        const { onHandleUpdateInspectionItemList } = this.props;

        if (!inspectionItemList || !inspectionItemList.length) {
            return;
        }
        let newArray = [];
        inspectionItemList.forEach((item, index) => {
            // 更新一遍order
            let showOrder = index + 1;
            newArray.push({
                itemId: item.itemId,
                tenantId: item.tenantId,
                showOrder: showOrder
            })
        })
        onHandleUpdateInspectionItemList(newArray);

    }

    handleUpdateItem = (data) => {
        this.props.onhandleUpdateItem(data);
    }

    handleSelectItem = (data, point) => {
        this.props.onhandleSelectItem(data, point);
    }

    render() {
        return (
            <div>
                <RouteDetailComponent
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    routeId={this.props.match.params.id}
                    userProfile={this.props.userProfile}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    confirmRoleList={this.trimConfirmRoleList()}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    currentRoute={this.props.currentRoute}
                    currentRouteOrigin={this.props.currentRouteOrigin}
                    // 当前记录相关联历史信息
                    currentRecordHistoryList={this.props.currentRecordHistoryList}
                    isRouteEditMode={this.props.isRouteEditMode}
                    isRouteChange={this.props.isRouteChange}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    routeError={this.props.routeError}
                    onhandleAddPoint={this.handleAddPoint.bind(this)}
                    onhandleSaveItemOrder={this.handleSaveItemOrder.bind(this)}
                    pointList={this.props.pointList}
                    inspectionItemList={this.props.inspectionItemList}
                    onhandleUpdateItem={this.handleUpdateItem.bind(this)}
                    onhandleSelectItem={this.handleSelectItem.bind(this)}
                    onhandleSavePoint={this.handleSavePoint.bind(this)}
                    onhandleDeletePoint={this.handleDeletePoint.bind(this)}
                    onhandleUpdatePoint={this.handleUpdatePoint.bind(this)}
                    onhandleItemOrder={this.handleItemOrder.bind(this)}
                    isLoading={this.props.isLoading}
                    currentRecord={this.props.currentRecord}
                    currentProcessStatus={this.props.currentProcessStatus}
                    onhandleProcess={this.handleProcess.bind(this)}
                    // onhandleRestart={this.handleRestart.bind(this)} // 暂不实现
                    unit={this.props.unit}
                    pointEditMode={this.props.pointEditMode}
                    onhandleChangeSeq={this.handleChangeSeq.bind(this)}
                    onhandleChangeIgnoreInstall={this.handleChangeIgnoreInstall.bind(this)}
                    onhandleChangeRecordConfirm={this.handleChangeRecordConfirm.bind(this)}
                    users={this.props.users}
                    onhandleRouteEditMode={this.props.onhandleRouteEditMode.bind(this)}
                />
                <ApprovePannel />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        isRouteEditMode: state.onsiteInspection.isRouteEditMode,
        isRouteChange: state.onsiteInspection.isRouteChange,
        isLoading: state.onsiteInspection.isLoading,
        currentRoute: state.onsiteInspection.currentRoute,
        currentRouteOrigin: state.onsiteInspection.currentRouteOrigin,
        routeError: state.onsiteInspection.routeError,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        selectedDevices: state.deviceSelect.selectedDevices,
        pointList: state.onsiteInspection.pointList,
        inspectionItemList: state.onsiteInspection.inspectionItemList,
        unit: state.onsiteInspection.unit,
        pointEditMode: state.onsiteInspection.pointEditMode,
        users: state.onsiteInspection.users,
        confirmRoleList: state.onsiteInspection.confirmRoleList,
        processes: state.process.processes,
        // 用于存放根据ProcessCode查询得到的初始流程列表
        processList: state.onsiteInspection.processList,
        // 用于存放根据ProcessCode和RecordId查询得到的当前记录相关历史记录信息
        currentRecordHistoryList: state.onsiteInspection.currentRecordHistoryList,
        currentRecord: mapCurrentRecord(
            state.process.processes[getProcessCode(state.onsiteInspection.currentRoute)],
            state.auth.currentUserInfo,
            state.auth.userProfile,
            state.onsiteInspection.currentRouteProcess
        ),
        currentProcessStatus: state.process.currentProcessStatus
            && state.process.currentProcessStatus[getProcessCode(state.onsiteInspection.currentRoute)]
            ? state.process.currentProcessStatus[getProcessCode(state.onsiteInspection.currentRoute)]
            : {},
        routeList: state.onsiteInspection.routeList,
        // // 审批流程是否需要向前走一步
        isProcessNeedMoveOneStep: state.onsiteInspection.isProcessNeedMoveOneStep,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleBack: () => {
            dispatch(backToList());
        },
        onhandleInsert: (data) => {
            dispatch(insertRoute(data));
            dispatch(setIsRouteChange(false));
        },
        onhandleModify: (data) => {
            dispatch(modifyRoute(data));
            dispatch(setIsRouteChange(false));
        },
        onhandleProcess: (params) => {
            dispatch(executeProcess(params));
        },
        onhandleUpdatePoint: (point) => {
            dispatch(updatePoint(point));
        },
        onhandleDeletePoint: (point) => {
            dispatch(deletePoint(point));
        },
        onhandleInsertPoint: (point) => {
            dispatch(insertPoint(point));
        },
        onhandleUpdateCurrentRoute: (data) => {
            dispatch(setCurrentRoute(data));
        },
        onhandleRouteChange: (data) => {
            dispatch(setIsRouteChange(data));
        },
        onhandleSetError: (data) => {
            dispatch(setRouteError(data));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `只能选择一个设备`));
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
        onhandleAddPoint: (data) => {
            dispatch(addPoint(data))
        },
        onhandleUpdateItem: (data) => {
            dispatch(updateItem(data))
        },
        onhandleSelectItem: (data, point) => {
            dispatch(selectItem(data, point))
        },
        onhandleSaveCheck: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleSaveWarn: (msg) => {
            dispatch(createMessage('warning', msg));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleUpdatePointModel: (point) => {
            dispatch(updatePointModel(point))
        },
        // onhandleRestart: () => {
        //     dispatch(routeRestart())
        // },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandlePointView: (value) => {
            dispatch(setPointEditMode(value))
        },
        onhandleChangeSeq: (value, id) => {
            dispatch(setPointSeq(value, id))
        },
        onhandleChangeIgnoreInstall: (value, id) => {
            dispatch(setIgnoreInstall(value, id))
        },
        onhandleRouteEditMode: (value) => {
            dispatch(setIsRouteEditMode(value))
        },
        onHnadleUpdateRouteList: (routeList) => {
            dispatch(setRouteList(routeList))
        },
        onHandleSetPointList: (pointList) => {
            dispatch(setPointList(pointList))
        },

        onHandleSetIndoorInspectionItemList: (itemList) => {
            dispatch(setIndoorInspectionItemList(itemList))
        },
        onHandleUpdateInspectionItemList: (itemList) => {
            dispatch(updateInspectionItemList(itemList))
        },
        onhandleSetIsProcessNeedMoveOneStep: (value) => {
            dispatch(setIsProcessNeedMoveOneStep(value))
        }

    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionRouteDetailContainer);