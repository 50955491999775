import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Chip,
    Grid,
    Link,
    TextField,
    // InputAdornment,
    FormHelperText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { AttachFile, CloudUpload } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { truncate } from '@/utils/string';
import _ from 'lodash';

const OperationWorkPermitPanel = ({
    classes,
    open,
    permitError,
    permit,
    appointment,
    specCodeList,
    userList,
    onhandleClose,
    onhandleConfirm,
    onhandleApproverSelect,
    onhandleChange,
    onhandleCheck,
    onhandleDownloadTemp,
    onhandleUpload,
    onhandleDeleteFile,
    onhandleDownloadFile,
}) => {

    // console.log('=======panel permit========')
    // console.log(permit)

    const renderSpecCode = (appointment) => {
        let codeName = ''
        if (specCodeList && specCodeList.length > 0) {
            let value = _.find(specCodeList, function (o) { return appointment.specCode ? o.code === appointment.specCode : false })
            if (value) {
                codeName = value.name;
            }
        }
        return codeName;
    }

    // const renderUser = (userId) => {
    //     let userName = '-';
    //     if (userList && userList.length > 0) {
    //         let user = _.find(userList, function (o) { return o.userId === userId })
    //         if (user) {
    //             userName = user.lastName + user.firstName;
    //         }
    //     }
    //     return userName;
    // }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{
                        renderSpecCode(appointment) + "许可" + (permit.ptwId ? "编辑" : "申请")
                    }</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"许可名称"}
                                variant='outlined'
                                className={classes.textField}
                                error={permitError.ptwName !== ''}
                                helperText={permitError.ptwName}
                                value={permit.ptwName || ''}
                                name='ptwName'
                                onChange={event => onhandleChange(event, 'ptwName')}
                                onBlur={event => onhandleCheck(event, 'ptwName')}
                            />
                        </Grid>
                        {/* <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"有效期"}
                                variant='outlined'
                                className={classes.textField}
                                error={permitError.expiredTime !== ''}
                                helperText={permitError.expiredTime}
                                value={permit.expiredTime || ''}
                                name='expiredTime'
                                InputProps={{
                                    endAdornment: <InputAdornment position={'end'}>{'小时'}</InputAdornment>,
                                }}
                                onChange={event => onhandleChange(event, 'expiredTime')}
                                onBlur={event => onhandleCheck(event, 'expiredTime')}
                            />
                        </Grid> */}
                        {/* <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"属地监护人"}
                                variant='outlined'
                                className={classes.textField}
                                error={permitError.intGuarder !== ''}
                                helperText={permitError.intGuarder}
                                value={renderUser(permit.intGuarder) || ''}
                                name='intGuarder'
                                InputProps={{
                                    readOnly: true,
                                }}
                                onClick={event => onhandleApproverSelect(event)}
                            />
                        </Grid> */}
                        {/* <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"施工监护人"}
                                variant='outlined'
                                className={classes.textField}
                                error={permitError.extGuarder !== ''}
                                helperText={permitError.extGuarder}
                                value={permit.extGuarder || ''}
                                name='extGuarder'
                                onChange={event => onhandleChange(event, 'extGuarder')}
                                onBlur={event => onhandleCheck(event, 'extGuarder')}
                            />
                        </Grid> */}
                        {
                            permit.permitDocs && permit.permitDocs[0] &&
                            <Grid item className={classes.itemGrid}>
                                <Chip
                                    icon={<AttachFile />}
                                    onClick={onhandleDownloadFile}
                                    onDelete={onhandleDeleteFile}
                                    variant="outlined"
                                    title={permit.permitDocs[0].docName}
                                    label={truncate(permit.permitDocs[0].docName, 20)}
                                />
                            </Grid>
                        }
                        {
                            (!permit.permitDocs || !permit.permitDocs[0]) &&
                            <Grid item container className={classes.itemGrid} justify={'space-between'}>
                                {/* <Link component="button" onClick={onhandleDownloadTemp} className={classes.downloadTemplate}>{'下载许可证模板'}</Link> */}
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={Boolean(permitError.file) ? classes.uploadErr : null}
                                        onClick={onhandleUpload}
                                        startIcon={<CloudUpload />}
                                    >
                                        {(permit.permitDocs && permit.permitDocs[0]) ? '变更许可证' : '上传许可证'}
                                    </Button>
                                    <FormHelperText className={classes.uploadErrText}>{permitError.file}</FormHelperText>
                                </Grid>
                            </Grid>
                        }
                        {
                            permit.file && permit.file.length && permit.file.length > 0 &&
                            <Grid item className={classes.itemGrid} alignItems={'flex-end'} container>
                                <Typography align={'right'}>已选择文件（保存时上传）：</Typography>
                                <Link underline='always' component="button">{permit.file[0].name}</Link>
                            </Grid>
                        }
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"备注"}
                                variant='outlined'
                                className={classes.textField}
                                value={permit.opentext || ''}
                                rows={7}
                                name='opentext'
                                onChange={event => onhandleChange(event, 'opentext')}
                                onBlur={event => onhandleCheck(event, 'opentext')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {
                            permit.ptwId ? '保存' : '新增'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
OperationWorkPermitPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    permit: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    specCodeList: PropTypes.array.isRequired
};

OperationWorkPermitPanel.defaultProps = {

}

export default withStyles(OperationWorkPermitPanel);