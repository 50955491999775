import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Grid,
    Typography,
    Tabs,
    Tab
} from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import RoleInfo from './RoleInfo'
import RoleRoute from './RoleRoute'

const RoleEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        onhandleSelectionChange,
        companySelection,
        currentRole,
        roleError,
        roleChange,
        roleGroupList,
        jobs,
        onhandleInitRoutePermission,
        routes,
        roleRouteChange,
        onhandleSaveRoleRoute,
        onhandleRouteTypeSelectChange,
        onhandleRouteSelectionChange
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        // if (newValue === 1){
        //     // 加载接口权限 TODO
        // }else 
        if (newValue === 1) {
            // 加载页面权限
            onhandleInitRoutePermission()
        }
    };

    return (
        <Card>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'角色详情'}
                </Typography>
            </Grid>
            <Grid>
                <Tabs className={classes.tabs} value={tabValue} onChange={handleTabChange} aria-label="角色标签">
                    <Tab className={classes.tab} label="基本信息" />
                    {/* {currentRole.roleId?(<Tab label="接口权限" />):null}  */}
                    {currentRole.roleId ? (<Tab className={classes.tab} label="页面权限" />) : null}
                </Tabs>
            </Grid>
            <RoleInfo
                tabValue={tabValue}
                index={0}
                onhandleSave={onhandleSave}
                onhandleChange={onhandleChange}
                onhandleBack={onhandleBack}
                onhandleCheck={onhandleCheck}
                companySelection={companySelection}
                onhandleSelectionChange={onhandleSelectionChange}
                currentRole={currentRole}
                roleError={roleError}
                roleChange={roleChange}
                roleGroupList={roleGroupList}
                jobs={jobs}
            />
            <RoleRoute
                tabValue={tabValue}
                index={1}
                routes={routes}
                currentRole={currentRole}
                roleRouteChange={roleRouteChange}
                onhandleBack={onhandleBack}
                onhandleSaveRoleRoute={onhandleSaveRoleRoute}
                onhandleRouteTypeSelectChange={onhandleRouteTypeSelectChange}
                onhandleSelectionChange={onhandleRouteSelectionChange}
            />
            <Prompt message="数据未保存,确定要离开？" when={roleChange} />
        </Card>
    );
};

RoleEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    onhandleSaveRoleRoute: PropTypes.func.isRequired,
    onhandleRouteTypeSelectChange: PropTypes.func.isRequired,
    onhandleRouteSelectionChange: PropTypes.func.isRequired,
    currentRole: PropTypes.object.isRequired,
    roleError: PropTypes.object.isRequired,
    roleChange: PropTypes.bool.isRequired,
    companySelection: PropTypes.object.isRequired,
    roleGroupList: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired,
    routes: PropTypes.array.isRequired,
    roleRouteChange: PropTypes.bool.isRequired
};

export default withStyles(RoleEdit);
