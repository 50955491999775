import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Chip,
    Grid,
    Select,
    TextField,
    Typography,
    TablePagination,
    Tooltip
} from '@material-ui/core';

import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { truncate } from "../../../../utils/string";
import _ from 'lodash';
import { getDateByTimestamp } from '../../../../utils/datetime';
import withStyles from './styles';

const HazardList = props => {
    const {
        classes,
        routeType,
        userProfile,
        hazardList,
        companies,
        departments,
        departmentList,
        rootCauseList,
        severityList,
        // impactLevelList,
        // factorList,
        creatorName,
        dictionary,
        exportCsv,
        getHazardList,
        search,
        onhandleSelectChange,
        onhandleDateChange,
        onhandleChange,
        onhandleSearch,
        onhandleClearSearch,
        onhandlePageChange,
        onhandleExportFile,
        onhandleCreatorClick,
        onEdit,
        // treatmentScheme,
        onDelete

    } = props;

    // console.log('===== routeType =====')
    // console.log(search)
    // console.log(routeType)
    const getComponentTitle = () => {
        let title = '隐患审定';
        switch (routeType) {
            case 'beforeevaluated':
                title = '隐患审定';
                break;
            case 'afterevaluated':
                title = '隐患治理通知单';
                break;
            case 'treating':
                title = '隐患台账';
                break;
            case 'hisevaluated':
                title = '历史上报隐患';
                break;
            default:
            // do nothing
        }
        return title
    }
    const getDefaultStatusValue = () => {
        let status = '';
        switch (routeType) {
            case 'beforeevaluated':
                status = '';
                break;
            case 'afterevaluated':
                status = 'evaluated';
                break;
            case 'treating':
                status = 'treating,done';
                break;
            case 'hisevaluated':
                status = '';
                break;
            default:
            // do nothing
        }
        return status
    }

    const getIsDisplayStatus = () => {
        let isDisplay = '';
        switch (routeType) {
            case 'beforeevaluated':
                isDisplay = 'none';
                break;
            case 'afterevaluated':
                isDisplay = 'none';
                break;
            case 'treating':
                isDisplay = 'flex';
                break;
            case 'hisevaluated':
                isDisplay = 'flex';
                break;
            default:
            // do nothing
        }
        return isDisplay
    }


    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_hazardlist' })
        return permission && permission.action === 'W'
    }

    const companyOptions = () => (
        <React.Fragment>
            {companies ?
                companies.map(data => (
                    <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const departmentOptions = () => (
        <React.Fragment>
            {departmentList ?
                departmentList.map(data => (
                    <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const rootCauseOptions = () => (
        <React.Fragment>
            {rootCauseList ?
                rootCauseList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const severityOptions = () => (
        <React.Fragment>
            {severityList ?
                severityList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    // const impactLevelOptions = () => (
    //     <React.Fragment>
    //         {impactLevelList ?
    //             impactLevelList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // const factorOptions = () => (
    //     <React.Fragment>
    //         {factorList ?
    //             factorList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // { code: "accepting", name: "待验收" }   一条隐患可能出现多条方案，此状态不适用
    const statusOptions = () => (
        <React.Fragment>
            {[
                { code: "active", name: "待审定" }, { code: "evaluated", name: "待治理" }, { code: "treating", name: "治理中" },
                { code: "done", name: "已治理" }, { code: "invalid", name: "已销项" }
            ].filter(data => {
                switch (routeType) {
                    case 'beforeevaluated':
                        return ['active'].includes(data.code);
                    case 'afterevaluated':
                        return ['evaluated'].includes(data.code);
                    case 'treating':
                        return ['treating', 'done'].includes(data.code);
                    case 'hisevaluated':
                        return true;
                    default:
                        return true
                    // do nothing
                }
            })
                .map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                ))}
        </React.Fragment>
    )

    const findName = (code, type) => {
        let obj = _.find(dictionary, function (o) { return o.code === code && o.type === type })
        return obj ? obj.name : null;
    }

    const renderCompany = (cid) => {
        let com = _.find(companies, function (o) { return o.companyId === cid });
        return com ? com.companyName : null;
    }

    const renderDepartment = (c) => {
        let dpt = _.find(departments, function (o) { return o.dptId === c.dptId })
        return dpt ? dpt.companyDptName : '-';
    }

    const renderStatus = (s) => {
        let status = { 'active': '待审定', 'evaluated': '待治理', 'treating': '治理中', 'accepting': '待验收', 'done': '已治理', 'invalid': '已销项' };
        return status[s]
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '编号',
                field: 'serialNo',
                render: row => row ? <Typography>{row.serialNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '产生时间',
                field: 'createTime',
                render: row => row ? <Typography>{getDateByTimestamp(row.createTime, true)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '来源事件',
                field: 'planName',
                render: row => row && row.inspectType ?
                    <Typography>{findName(row.inspectType, "INSTYPE") + "-" + row.planName}</Typography>
                    : <Typography>{'随机排查'}</Typography>,
                editable: 'never'
            },
            {
                title: '属地单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '问题描述',
                field: 'opentext',
                render: row => row ? <Typography className={classes.heighlight} title={row.opentext}>{truncate(row.opentext, 15)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查部门',
                field: 'dptId',
                render: row => row && row.dptId ? <Typography>{renderDepartment(row)}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '监管部门',
                field: 'dptId',
                render: row => row && row.checklist ? <Typography>{renderDepartment(row.checklist)}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '根因',
                field: 'rootCause',
                render: row => row ? <Typography className={classes.heighlight}>{row ? findName(row.rootCause, "ROOTCAUSE") : null}</Typography> : '',
                editable: 'never'
            },
            {
                title: '隐患级别',
                field: 'severity',
                render: row => row ? <Typography className={classes.heighlight}>{row ? findName(row.severity, "SEVERITY") : null}</Typography> : '',
                editable: 'never'
            },
            {
                title: '记录人',
                field: 'recorder',
                render: row => row ? <Typography>{row ? row.recorder : null}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '危害程度',
            //     field: 'impactLevel',
            //     render: row => row ? <Typography>{row ? findName(row.impactLevel, "IMPACTLEVEL") : null}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '状态',
                field: 'status',
                render: row => {
                    let statusStyle = classes.active;
                    if (row.status === 'evaluated') statusStyle = classes.evaluated;
                    if (row.status === 'treating') statusStyle = classes.treating;
                    if (row.status === 'done') statusStyle = classes.done;
                    if (row.status === 'invalid') statusStyle = classes.invalid;
                    if (row.status === 'inactive') statusStyle = classes.inactive;
                    return <Tooltip title={row.opentext} >
                        <Chip size="small" className={statusStyle} label={renderStatus(row.status)} />
                    </Tooltip>
                },
                editable: 'never'
            }
        ]

        return columns
    }

    const renderEditText = (status) => {
        if (status === 'active') return '隐患审定';
        if (status === 'evaluated') return '隐患方案制定';
        if (status === 'treating') return '查看治理明细';
        return '查看明细';
    }

    const components = {
        Toolbar: props => (
            <>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'属地单位'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.company}
                            onChange={(event) => onhandleSelectChange(event, 'company')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {companyOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'监管部门'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.dptId}
                            onChange={(event) => onhandleSelectChange(event, 'department')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {departmentOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'产生时间'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container wrap={'nowrap'}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN}>
                            <KeyboardDatePicker
                                className={classes.dateField}
                                InputProps={{ readOnly: true }}
                                disableToolbar
                                autoOk
                                variant='inline'
                                format='yyyy/MM/dd'
                                margin="none"
                                value={search.createTime || null}
                                onChange={date => onhandleDateChange(date, 'createTime')}
                                KeyboardButtonProps={{
                                    'aria-label': '修改时间',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label} >
                            {'至'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container wrap={'nowrap'}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN}>
                            <KeyboardDatePicker
                                className={classes.dateField}
                                InputProps={{ readOnly: true }}
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="none"
                                value={search.createTimeEnd || null}
                                onChange={date => onhandleDateChange(date, 'createTimeEnd')}
                                KeyboardButtonProps={{
                                    'aria-label': '修改时间',
                                }}
                            />
                        </MuiPickersUtilsProvider>


                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'根因'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.rootCause}
                            onChange={(event) => onhandleSelectChange(event, 'rootCause')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {rootCauseOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'隐患级别'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.severity}
                            onChange={(event) => onhandleSelectChange(event, 'severity')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {severityOptions()}
                        </Select>
                    </Grid>
                    {/* <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'危害程度'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.impactLevel}
                            onChange={(event) => onhandleSelectChange(event, 'impactLevel')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {impactLevelOptions()}
                        </Select>
                    </Grid> */}
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'记录人'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            style={{ width: '90%' }}
                            className={classes.textField}
                            value={creatorName || ''}
                            name='guarder'
                            InputProps={{
                                readOnly: true,
                            }}
                            onClick={event => onhandleCreatorClick(event)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'隐患编号'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {/* <Select
                            style={{ width: '90%' }}
                            native
                            value={search.rootCause}
                            onChange={(event) => onhandleSelectChange(event, 'rootCause')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {rootCauseOptions()}
                        </Select> */}


                        <TextField
                            style={{ width: '90%' }}
                            value={search.search || ''}
                            variant="standard"
                            placeholder="隐患编号"
                            onChange={event => onhandleChange(event, 'search')}
                        />
                    </Grid>

                    {/* <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {'安全管理要素'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.factor}
                            onChange={(event) => onhandleSelectChange(event, 'factor')}
                        >
                            <option aria-label="None" value="" >{'不限'}</option>
                            {factorOptions()}
                        </Select>
                    </Grid> */}

                </Grid>
                <Grid container spacing={0} className={classes.gridContainer} style={{ display: getIsDisplayStatus(routeType) }}>
                    <Grid item xs={1} >
                        <Typography gutterBottom className={classes.label}>
                            {'隐患状态'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.status}
                            onChange={(event) => onhandleSelectChange(event, 'status')}
                        >
                            {Boolean(['beforeevaluated', 'treating', 'hisevaluated'].includes(routeType)) && <option aria-label="None" value={getDefaultStatusValue()} >{'不限'}</option>}
                            {statusOptions()}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={() => onhandleSearch()}
                        >{'搜索'}</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleClearSearch}

                        >{'重置'}</Button>
                    </Grid>
                </Grid>
                <MTableToolbar {...props} />
            </>
        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={search.size}
                count={hazardList.total}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum);
                    getHazardList({
                        ...search,
                        offset: pageNum * search.size,
                        size: search.size
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                    getHazardList({
                        ...search,
                        offset: 0,
                        size: event.target.value
                    })
                }}
            />
        )
    }

    return (
        <Card className={classes.card}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {getComponentTitle()}</Typography>
            </div>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={hazardList.list}
                        options={{
                            ...options,
                            pageSize: search.size,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: true
                        }}
                        localization={localization}
                        style={style}
                        components={components}
                        actions={checkPermission() ?
                            [
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: renderEditText(rowData.status),
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData, true)
                                    },
                                    disabled: rowData.status === 'inactive'
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '销项',
                                    onClick: (event, rowData) => {
                                        onDelete(rowData)
                                    },
                                    hidden: ['done', 'invalid'].includes(rowData.status),
                                    disabled: rowData.status === 'inactive'
                                }),
                                rowData => ({
                                    icon: 'save_alt',
                                    tooltip: '导出档案',
                                    onClick: (event, rowData) => {
                                        onhandleExportFile(rowData.hid)
                                    },
                                    hidden: rowData.status !== 'done'
                                }),
                                {
                                    icon: 'save_alt',
                                    tooltip: '导出台账',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        exportCsv()
                                    },
                                    hidden: !(getComponentTitle() === '隐患台账')
                                }
                            ] : [
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看详情',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData, false)
                                    }
                                }),
                                rowData => ({
                                    icon: 'save_alt',
                                    tooltip: '导出档案',
                                    onClick: (event, rowData) => {
                                        onhandleExportFile(rowData.hid)
                                    },
                                    hidden: rowData.status !== 'done'
                                }),
                                {
                                    icon: 'save_alt',
                                    tooltip: '导出台账',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        exportCsv()
                                    },
                                    hidden: !(getComponentTitle() === '隐患台账')
                                }
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>
        </Card>
    );
};

HazardList.propTypes = {
    classes: PropTypes.object.isRequired,
    hazardList: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    dictionary: PropTypes.array.isRequired,
    rootCauseList: PropTypes.array.isRequired,
    severityList: PropTypes.array.isRequired,
    impactLevelList: PropTypes.array.isRequired,
    factorList: PropTypes.array.isRequired,
    exportCsv: PropTypes.func.isRequired,
    getHazardList: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandlePageChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default withStyles(HazardList);
