import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    FormControlLabel,
    Grid,
    Typography,
    // TablePagination,
    Chip,
    TextField,
    Card,
    Avatar,
    // FormHelperText,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import {
    Add,
    BlurCircular,
    BlurOn,
    Edit,
    Dns,
    DeleteOutlined,
    ExpandMore,
    ExpandLess,
    Done,
    Close,
    MoreHoriz,
    HowToVote,
    ViewList,
    // NotInterested,
    // Link,
} from '@material-ui/icons';
import { Table } from 'antd';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { getDateByTimestamp, getDateByString, getMoment } from '../../../utils/datetime';
import { DEVICE_OPERATION_STATUS } from '@/utils/device';
import { formatRoleGroupTreeForOperationRole } from '../../../utils/orgchart';
import ShowCard from '../OperationAnnuaPlan/ShowCard';
import _ from 'lodash';
import TreeSelect from 'rc-tree-select';
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';

const OperationPlan = ({
    classes,
    companyId,
    companyTree,
    companyList,
    onlyMe,
    month,
    monthlyPlanPageSize,
    isLoading,
    operationPlanDetails,
    procat,
    catList,
    accCatList,
    optCategories,
    onhandleChangeItem,
    setMonthlyPlanPageSize,
    onhanleGetPlanDetailList,
    onhandleClearSearch,
    userProfile,
    roleList,
    roleTree,
    onhandleCheck,
    onhandleUpdate,
    onhandleDelete,
    onhandleCreateWorkOrder,
    onhandleRouteToOperationPlanCreate,
    // 查看已下工单列表
    onhandleViewOrderList,
    // 运维类别 （检索条件）
    optType,
    // 执行时间 - 开始 （检索条件）
    targetDateStart,
    // 执行时间 - 结束 （检索条件）
    targetDateEnd,
    // 预算（元） （检索条件）
    budgetStart,
    // 预算（元） （检索条件）
    budgetEnd,
    // 下工单 （检索条件）
    optNo,
    orderAssigned,
    routeType,
    onhandleCreateMergeWorkOrder,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tableAnchorEl, setTableAnchorEl] = useState(null);
    const [expand, setExpand] = useState(false);

    const [editingRow, setEditingRow] = useState({})
    // const [page, setPage] = useState(0);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openTableMenu = (event, row) => {
        let anchorElement = event.currentTarget;
        setEditingRow(row);
        setTableAnchorEl(anchorElement);
    }

    const closeTableMenu = () => {
        setTableAnchorEl(null);
    };

    const generateMenu = () => {
        const hasWorkOrder = Boolean(editingRow?.executeTime);
        return (
            <Menu
                keepMounted
                anchorEl={tableAnchorEl}
                open={Boolean(tableAnchorEl)}
                onClose={closeTableMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    horizontal: 'bottom',
                }}
            >
                {hasWorkOrder
                    ?
                    <MenuItem dense onClick={() => { onhandleViewOrderList(editingRow); closeTableMenu(); }}>
                        <ViewList className={classes.menuIcon} />{"查看工单"}
                    </MenuItem>
                    : null
                    // <MenuItem dense disabled>
                    //     <NotInterested className={classes.menuIcon} />{"工单未下达"}
                    // </MenuItem>
                }
                <MenuItem dense className={classes.menuItemCritical} onClick={() => { deleteRow(editingRow); closeTableMenu(); }}>
                    <DeleteOutlined className={classes.menuIcon} />{'删除'}
                </MenuItem>
            </Menu>
        );
    }


    const tableRef = React.createRef();

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_plan' })
        return permission && permission.action === 'W'
    }

    const checkEditPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_plan_edit' })
        return permission && permission.action === 'W'
    }

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    const renderCompany = (plan) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === plan.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    // const renderExecuteTime = (detail) => {
    //     return detail.executeTime ? getDateByTimestamp(detail.executeTime, true) : ''
    // }

    const renderDate = (detail) => {
        return detail.targetDate ? getDateByString(detail.targetDate) : getDateByTimestamp(detail.targetDate, true)
    }

    const renderWorkOrder = (plan) => {
        if (plan.executeTime) {
            return <React.Fragment>
                <Chip
                    size="small"
                    color="primary"
                    label="已下达"
                    title='已下达'
                />
                {/* <IconButton aria-label="查看已下工单列表"
                    onClick={event => onhandleViewOrderList(plan)}
                    component="span"
                    title='查看已下工单列表'
                    color="primary"
                >
                    <Link />
                </IconButton> */}
            </React.Fragment>
        } else {
            return <Chip
                size="small"
                label="未下达"
                title='未下达'
            />
        }
    }

    const renderOptCat = (row) => {
        if ((row.ruleId || row.optType) && optCategories && optCategories.length > 0) {
            let cat = _.find(optCategories, { 'code': (row.ruleId ? row.rule.cat : row.optType) });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    // const companyOptions = () => (
    //     <React.Fragment>
    //         {companyList ?
    //             _.sortBy(companyList, 'companyId').map(data => (
    //                 <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const renderRoles = (roles) => {
        const list = [];
        roles.forEach(role => {
            let roleStr = '';
            roleStr = _.find(roleList, { 'roleId': role.roleId }) ? _.find(roleList, { 'roleId': role.roleId }).roleName : '';
            list.push(roleStr);
        })
        return list.join(', ');
    }

    const renderRoleTree = (row) => {
        if (row.dtlId) {
            // console.log(roleTree)
            let newRoleTree = _.find(roleTree, ['companyId', row.companyId])
            let newTree = newRoleTree.roleGroups
            return formatRoleGroupTreeForOperationRole(newTree)
        } else {
            return [];
        }
    }

    const renderRoleList = (roles) => {
        let list = []
        if (roles) {
            roles.forEach(role => {
                role.value = role.value ? role.value : role.roleId;
                role.label = role.label ? role.label : role.roleName;
                role.roleId = role.roleId ? role.roleId : role.value;
                role.roleName = role.roleName ? role.roleName : role.label;
                list.push(role)
            })
        }
        return list
    }

    const renderStatus = (WorkOrder) => {
        // let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效', 'approved': '批准', 'finalized': '待验收', 'done': '完成' };
        return DEVICE_OPERATION_STATUS[WorkOrder.status]
    }

    // 运维类别 下拉框选项列表
    // const optCatOptions = () => (
    //     <React.Fragment>
    //         {optCategories ?
    //             _.sortBy(optCategories, 'seq').map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const renderColumns = () => {
        let columns = [];
        columns.push(
            {
                title: '选择',
                align: 'center',
                field: 'type',
                width: 50,
                editable: 'never',
                filtering: false,
                render: row => row ? <Checkbox size='small' className={classes.actionCell}
                    disabled={Boolean(!(checkPermission() && checkEditPermission() && routeType !== 'projectmanage')) || Boolean(row.executeTime)}
                    checked={row.selected}
                    // onChange={(event) => handleSetMajorHazardDevice(row)}
                    onChange={(event) => {
                        if (row.selected) {
                            row.selected = false;
                        } else {
                            row.selected = true;
                        }
                        // tableRef.current.forceUpdate();
                    }}

                /> : '',
                onCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '6px auto',
                    }
                }),
            },
            {
                title: '类型',
                align: 'center',
                field: 'type',
                width: 40,
                onCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '0px auto',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '6px auto',
                    }
                }),
                render: row => row ? row.rule && row.rule.ruleName ?
                    < Avatar className={classes.pic1}>年</Avatar >
                    :
                    < Avatar className={classes.pic1}>临</Avatar >
                    : '',
                editable: 'never',
            },
            // {
            //     title: '类型',
            //     field: 'type',
            //     render: row => row ? row.device && row.device.deviceId ?
            //         <ShowCard currentInfo={row.device} target={row.target}
            //             child={
            //                 < Avatar className={classes.pic1}>设</Avatar >}
            //         />
            //         :
            //         < ShowCard currentInfo={row.accessory} target={row.target}
            //             child={
            //                 < Avatar className={classes.pic2}>配</Avatar >}
            //         />
            //         //     < Avatar className = { classes.pic1 } > 设备</Avatar > :
            //         // <Avatar className={classes.pic2}>部件</Avatar> :
            //         : '',
            //     // width: '2em',
            //     editable: 'never'
            // },
            {
                title: '单位',
                field: 'companyId',
                // width: 100,
                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '对象名称',
                field: 'deviceName',
                // width: 150,
                render: row => row ? row.device && row.device.deviceId ?
                    <ShowCard currentInfo={row.device} target={row.target} child={<Typography>{row.device.deviceName}</Typography>} />
                    : <ShowCard currentInfo={row.accessory} target={row.target} child={<Typography>{row.accessory.accName}</Typography>} />
                    : '',
                editable: 'never'
            },
            {
                title: '运维类别',
                field: 'optType',
                width: 120,
                render: row => row.editing ? <div>
                    <TextField
                        className={classes.tableTextFieldInput}
                        size={'small'}
                        select
                        variant="outlined"
                        value={editingRow.optType}
                        onChange={(event) => {
                            let newData = { ...editingRow, optType: event.target.value }
                            setEditingRow(newData)
                        }}
                    >
                        {Boolean(optCategories) &&
                            _.sortBy(optCategories, 'seq').map(data => (
                                <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </TextField>
                </div> : <Typography>{renderOptCat(row)}</Typography>,
            },
            {
                title: '负责角色',
                field: 'roles',
                width: 150,
                render: row => row.editing ? <div>
                    <TreeSelect
                        showArrow
                        className={classes.tableTextFieldInput}
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        // allowClear
                        treeLine
                        value={renderRoleList(editingRow.roles)}
                        labelInValue
                        treeData={renderRoleTree(editingRow)}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        multiple={true}
                        onChange={(value) => {
                            if (value.length === 0) {
                                return
                            }
                            let newRoleList = [];
                            value.forEach(selected => {
                                newRoleList.push({ roleId: selected.value, roleName: selected.label, value: selected.value, label: selected.label })
                            });
                            let newData = { ...editingRow, roles: newRoleList }
                            setEditingRow(newData)
                        }
                        }
                    />
                </div> : <Typography>{renderRoles(row.roles)}</Typography>,
                align: 'left'

            },
            {
                title: '执行时间',
                field: 'targetDate',
                render: row => row.editing ? <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                        autoOk
                        className={classes.tableTextFieldInput}
                        InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="yyyy/MM/dd"
                        margin="normal"
                        name='tenabletimeselect'
                        value={editingRow.targetDate}
                        onChange={(value) => {
                            console.log(value)
                            let newData = { ...editingRow, targetDate: value }
                            setEditingRow(newData)
                        }
                        }
                        KeyboardButtonProps={{
                            'aria-label': '修改时间',
                        }}
                        InputAdornmentProps={{ position: 'end', variant: 'standard' }}
                        minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                        minDateMessage="不能早于当前时间"
                    />
                </MuiPickersUtilsProvider> : <Typography>{renderDate(row)}</Typography>,
            },
            {
                title: '预算(元)',
                field: 'budget',
                align: 'right',
                render: row => row.editing ? <TextField className={classes.tableTextFieldInput}
                    value={editingRow.budget}
                    type="number"
                    onChange={(e) => {
                        let val = e.target.value;
                        if (val === '') {
                            val = '0';
                        }
                        let newData = { ...editingRow, budget: _.toNumber(val) }
                        setEditingRow(newData)
                    }
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField> : <Typography>{row.budget}</Typography>
            }
        )
        if (routeType === 'projectmanage') {
            columns.push(
                {
                    title: '决算(元)',
                    field: 'cost',
                    align: 'right',
                    render: row => row ? <Typography>{row.cost}</Typography> : '',
                },
                // {
                //     title: '描述',
                //     field: 'opentext',
                //     render: row => row ? row.opentext ? <Typography>{row.opentext}</Typography> : row.rule && row.rule.ruleName ? <Typography>{row.rule.ruleName}</Typography> : '' : '',
                // }
            )
        } else {
            columns.push(
                // {
                //     title: '描述',
                //     field: 'opentext',
                //     render: row => row ? row.opentext ? <Typography>{row.opentext}</Typography> : row.rule && row.rule.ruleName ? <Typography>{row.rule.ruleName}</Typography> : '' : '',
                // },
                // {
                //     title: '下达时间',
                //     field: 'executeTime',
                //     editable: 'never',
                //     render: row => row ? <Typography>{renderExecuteTime(row)}</Typography> : '',
                // }
            )
        }

        columns.push(
            // {
            //     title: '计划编号',
            //     field: 'optNo',
            //     render: row => row ? <Typography>{row.optNo}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '工单',
                width: 80,
                align: 'center',
                field: 'workorder',
                render: row => row ? renderWorkOrder(row) : '',
                editable: 'never'
            },
            {
                title: '状态',
                align: 'center',
                width: 80,
                field: 'status',
                render: row => row ? <Chip size="small" className={classes[row.status]} label={renderStatus(row)} /> : '',
                editable: 'never',
                onCell: () => ({
                    style: {
                        width: 60,
                        maxWidth: 60,
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 60,
                        maxWidth: 60,
                        padding: '0px 0px',
                    }
                }),
            }
        );

        if (checkPermission() && checkEditPermission() && routeType !== 'projectmanage') {
            columns.push({
                title: '操作',
                align: 'center',
                width: 120,
                field: 'actions',
                render: (text, row, index) => renderActions(row),
                onCell: () => ({
                    style: {
                        width: 120,
                        maxWidth: 120,
                        padding: '0px 0px',
                    }
                }),
            })
        }

        return columns

    }


    const renderActions = (row) => {
        if (isEditing()) {
            if (editingRow.dtlId === row.dtlId) {
                return <Grid container alignItems='center' justify='center'>
                    <Grid item><Tooltip title='保存'><IconButton variant='text' onClick={() => saveEditRow(row)}> <Done fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                    <Grid item><Tooltip title='取消'><IconButton variant='text' onClick={() => cancelEditRow(row)}> <Close fontSize='small' color={'error'} /></IconButton></Tooltip></Grid>
                </Grid>
            } else {
                return <Grid container alignItems='center'></Grid>
            }
        } else {
            return <Grid container alignItems='center' justify='center'>
                <Grid item><Tooltip title='下工单'><IconButton variant='text' onClick={() => onhandleCreateWorkOrder(row)}> <HowToVote fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='编辑'><IconButton variant='text' onClick={() => editRow(row)}> <Edit fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='更多'><IconButton variant='text' onClick={(event) => openTableMenu(event, row)}> <MoreHoriz fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                {/* <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => deleteRow(row)}> <DeleteOutlined fontSize='small' color={'error'} /></IconButton></Tooltip></Grid> */}
            </Grid>
        }
    }

    const isEditing = () => {
        let editingRow = _.find(operationPlanDetails.list, { 'editing': true })
        return editingRow ? true : false
    }

    const editRow = (row) => {
        row.editing = true
        setEditingRow(row)
    }

    const saveEditRow = () => {
        let newData = {
            ...editingRow,
            targetDate: new Date(editingRow.targetDate),
            editing: false
        }
        delete newData.executeTime
        onhandleUpdate(newData);
        setEditingRow({})
    }

    const cancelEditRow = (row) => {
        row.editing = false
        setEditingRow({})
    }

    const deleteRow = (row) => {
        onhandleDelete(row)
        setEditingRow({})
    }

    return (
        <Card className={classes.root}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'运维计划'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={expand ? 8 : 6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            单&emsp;位
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            variant="outlined"
                            disabled={companyDisabled()}
                            value={companyId ? companyId : ''}
                            onChange={(event) => onhandleChangeItem(event, 'companyId')}
                        >
                            <MenuItem aria-label="None" value="" >{'全部'}</MenuItem>
                            {Boolean(companyList) &&
                                _.sortBy(companyList, 'companyId').map(data => (
                                    <MenuItem value={data.companyId} key={data.companyId}>{data.companyAlias}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            月&emsp;份
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDatePicker
                                className={classes.textFieldInput}
                                autoOk
                                InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                variant="inline"
                                inputVariant="outlined"
                                size="small"
                                views={["year", "month"]}
                                name='year'
                                openTo={'month'}
                                value={new Date(month)}
                                format="yyyy/MM"
                                minDate={new Date(`2020-01-01`)}
                                onChange={value => onhandleChangeItem(value, 'month')}
                                KeyboardButtonProps={{
                                    'aria-label': '月份',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            运维类别
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            variant="outlined"
                            value={optType || ''}
                            onChange={value => onhandleChangeItem(value, 'optType')}
                        >
                            <option aria-label="None" value="" />
                            {Boolean(optCategories) &&
                                _.sortBy(optCategories, 'seq').map(data => (
                                    <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>

                    {Boolean(expand) &&
                        <React.Fragment>
                            {routeType !== 'projectmanage' &&
                                <React.Fragment>

                                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                        <Typography className={classes.label}>
                                            工单下达
                                        </Typography>
                                        <TextField
                                            className={classes.textFieldInput}
                                            size={'small'}
                                            variant="outlined"
                                            select
                                            value={orderAssigned || ''}
                                            onChange={value => onhandleChangeItem(value, 'orderAssigned')}
                                        >
                                            <MenuItem value={''}>{'-'}</MenuItem>
                                            <MenuItem value={'Y'} key={'Y'}>{'已下达'}</MenuItem>
                                            <MenuItem value={'N'} key={'N'}>{'未下达'}</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                        <Typography className={classes.label}>
                                            执行时间
                                        </Typography>
                                        <Grid container item className={classes.gridSubItem}>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={zhCN} >
                                                <KeyboardDatePicker
                                                    autoOk
                                                    className={classes.textFieldGroupItem}
                                                    InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                                    disableToolbar
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    size="small"
                                                    format="yyyy/MM/dd"
                                                    name='tenabletimeselect'
                                                    value={targetDateStart}
                                                    onChange={value => onhandleChangeItem(value, 'targetDateStart')}
                                                    KeyboardButtonProps={{
                                                        'aria-label': '修改时间',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <Typography className={classes.connectLabel}>
                                                至
                                            </Typography>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={zhCN}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    className={classes.textFieldGroupItem}
                                                    InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                                    disableToolbar
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    size="small"
                                                    format="yyyy/MM/dd"
                                                    name='tenabletimeselect'
                                                    value={targetDateEnd}
                                                    onChange={value => onhandleChangeItem(value, 'targetDateEnd')}
                                                    KeyboardButtonProps={{
                                                        'aria-label': '修改时间',
                                                    }}
                                                // minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                                                // maxDate={new Date(getLastDayInYear(getYear()))}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                <Typography className={classes.label}>
                                    预算(元)
                                </Typography>
                                <Grid container item className={classes.gridSubItem}>
                                    <TextField
                                        className={classes.textFieldInput}
                                        size={'small'}
                                        value={budgetStart || ''}
                                        type="number"
                                        onChange={value => onhandleChangeItem(value, 'budgetStart')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}>
                                    </TextField>
                                    <Typography className={classes.connectLabel}>
                                        至
                                    </Typography>
                                    <TextField
                                        className={classes.textFieldInput}
                                        size={'small'}
                                        value={budgetEnd || ''}
                                        type="number"
                                        onChange={value => onhandleChangeItem(value, 'budgetEnd')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                <Typography className={classes.label}>
                                    计划编号
                                </Typography>
                                <TextField
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    value={optNo || ''}
                                    variant="outlined"
                                    placeholder="计划编号"
                                    onChange={value => onhandleChangeItem(value, 'optNo')}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>

                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 4 : 6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
                        <Button variant="contained" color="primary"
                            onClick={onhanleGetPlanDetailList}
                        >
                            搜索
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}

                        >
                            重置
                        </Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                        <FormControlLabel
                            className={classes.switch}
                            label={
                                <Typography noWrap style={onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的计划</Typography>
                            }
                            control={
                                <Checkbox size={'small'}
                                    checked={onlyMe}
                                    onChange={event => onhandleCheck(event, 'onlyMe')} />
                            } />
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
                        {checkPermission() && checkEditPermission() && routeType !== 'projectmanage' &&
                            <React.Fragment>
                                <Button variant='contained'
                                    color='primary'
                                    onClick={onhandleCreateMergeWorkOrder}
                                    startIcon={<Dns />}
                                >
                                    合并下工单
                                </Button>
                                <Button id="basic-button" variant='contained' style={{ marginLeft: '10px' }}
                                    color='primary'
                                    aria-controls="simple-menu"
                                    aria-haspopup="menu"
                                    onClick={handleClick}
                                    startIcon={<Add />}
                                // endIcon={<ExpandMore />}
                                >
                                    临时计划
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    elevation={0}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        // horizontal: 'bottom',
                                    }}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                        sx: { width: anchorEl && anchorEl.offsetWidth } // 尝试设置菜单与按钮等宽
                                    }}
                                    className={classes.menuroot}
                                >
                                    <MenuItem
                                        className={classes.tabs}
                                        onClick={(event) => {
                                            onhandleRouteToOperationPlanCreate('DEV')
                                        }}
                                    >
                                        <BlurCircular className={classes.menuIcon} />
                                        {"设备临时计划"}
                                    </MenuItem >
                                    <MenuItem
                                        className={classes.tabs}
                                        onClick={(event) => {
                                            onhandleRouteToOperationPlanCreate('ACC')
                                        }}
                                    >
                                        <BlurOn className={classes.menuIcon} />
                                        {"部件临时计划"}
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
                {/* <Grid container alignItems="center">
                    <Grid item className={classes.flexgrow}></Grid>
                    {checkPermission() && checkEditPermission() && routeType !== 'projectmanage' ?
                        <React.Fragment>
                            <Grid container item alignItems={'center'}>
                                <FormControlLabel
                                    className={classes.switch}
                                    label={
                                        <Typography style={onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的运维计划</Typography>
                                    }
                                    control={
                                        <Switch size={'small'}
                                            checked={onlyMe}
                                            color={'primary'}
                                            onChange={event => onhandleCheck(event, 'onlyMe')} />
                                    } />
                            </Grid>
                            <Grid item className={classes.button}>
                                <Button variant='contained'
                                    color='primary'
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={onhandleCreateMergeWorkOrder}
                                >
                                    <Dns />合并下工单
                                </Button>
                            </Grid>
                            <Grid item className={classes.button}>
                                <Button variant='contained'
                                    color='primary'
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <PostAdd />创建临时计划
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    elevation={0}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'end',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    className={classes.menuroot}
                                >
                                    <MenuItem
                                        className={classes.tabs}
                                        onClick={(event) => {
                                            onhandleRouteToOperationPlanCreate('DEV')
                                        }}
                                    >
                                        <ListItemIcon>
                                            <BlurCircularIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="设备临时计划" />
                                    </MenuItem >
                                    <MenuItem
                                        className={classes.tabs}
                                        onClick={(event) => {
                                            onhandleRouteToOperationPlanCreate('ACC')
                                        }}
                                    >
                                        <ListItemIcon>
                                            <BlurOnIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="部件临时计划" />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </React.Fragment>
                        :
                        <Grid item >
                            <FormControlLabel
                                className={classes.switch}
                                label={
                                    <Typography style={onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的运维计划</Typography>
                                }
                                control={
                                    <Switch size={'small'}
                                        checked={onlyMe}
                                        color={'primary'}
                                        onChange={event => onhandleCheck(event, 'onlyMe')} />
                                } />
                        </Grid>
                    }
                </Grid> */}
            </Grid>
            <Grid className={classes.detailtable}>
                <Table
                    tableRef={tableRef}
                    className={classes.stripedTable}
                    rowKey={'dtlId'}
                    columns={renderColumns()}
                    dataSource={operationPlanDetails.list}
                    size={'small'}
                    // tableLayout={'fixed'}
                    scroll={{ x: '100%', y: 'calc(100vh - 260px)' }}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: () => `共${operationPlanDetails.total}条`,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total: operationPlanDetails.total,
                    }}
                />
            </Grid>
            {generateMenu()}
        </Card>
    );

};
OperationPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    roleTree: PropTypes.array.isRequired,
    roleList: PropTypes.array.isRequired,
    optCategories: PropTypes.array.isRequired,
    procat: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    accCatList: PropTypes.array.isRequired,
    monthlyPlanPageSize: PropTypes.number.isRequired,
    operationPlanDetails: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleChangeItem: PropTypes.func.isRequired,
    setMonthlyPlanPageSize: PropTypes.func.isRequired,
    onhanleGetPlanDetailList: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onhandleCreateWorkOrder: PropTypes.func.isRequired,
    onhandleRouteToOperationPlanCreate: PropTypes.func.isRequired,
    // 查看已下工单列表 函数
    onhandleViewOrderList: PropTypes.func.isRequired,
    // // 运维类别 （检索条件）
    // optType: PropTypes.object.isRequired,
    // // 执行时间 - 开始 （检索条件）
    // targetDateStart: PropTypes.object.isRequired,
    // // 执行时间 - 结束 （检索条件）
    // targetDateEnd: PropTypes.object.isRequired,
    // // 预算（元） （检索条件）
    // budgetStart: PropTypes.object.isRequired,
    // // 预算（元） （检索条件）
    // budgetEnd: PropTypes.object.isRequired,
    // // 下工单 （检索条件）
    // orderAssigned: PropTypes.object.isRequired
};

export default (withStyles(OperationPlan));