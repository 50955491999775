import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    // Divider,
    TextField,
    Typography,
    // MenuItem,
    InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types'

const LaborerManageInfoComponent = props => {
    const { classes,
        openFlag,
        laborerInfo,
        // companyList,
        // departmentList,
        onhandleClose,
        onhandleSave,
        onhandleChange,
        // onhandleTextChange,
    } = props;

    const textFieldDefault = {
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    return (
        <div alignitems="center">
            <Dialog open={openFlag} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>
                        {'劳务人员详情'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column" >
                        <Grid container>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='劳务人员'
                                    variant="outlined"
                                    placeholder=""
                                    name='laborer'
                                    margin="normal"
                                    value={laborerInfo.laborer || ''}
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    style={{
                                        width: '160px',
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='所属劳务公司'
                                    variant="outlined"
                                    placeholder=""
                                    name='laborCompany'
                                    margin="normal"
                                    value={laborerInfo.laborCompany || ''}
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    style={{
                                        width: '160px',
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='日工资(元)'
                                    variant="outlined"
                                    placeholder=""
                                    name='dailySalary'
                                    type="number"
                                    value={laborerInfo.dailySalary || 0}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    InputProps={{ ...textFieldDefault }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='保险费(元)'
                                    variant="outlined"
                                    placeholder=""
                                    name='insurance'
                                    type="number"
                                    value={laborerInfo.insurance || 0}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    InputProps={{ ...textFieldDefault }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='餐饮费(元)'
                                    variant="outlined"
                                    placeholder=""
                                    name='meal'
                                    type="number"
                                    value={laborerInfo.meal || 0}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    InputProps={{ ...textFieldDefault }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='管理费(元)'
                                    variant="outlined"
                                    placeholder=""
                                    name='manageFee'
                                    type="number"
                                    value={laborerInfo.manageFee || 0}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    InputProps={{ ...textFieldDefault }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='其他费用(元)'
                                    variant="outlined"
                                    placeholder=""
                                    name='otherFee'
                                    type="number"
                                    value={laborerInfo.otherFee || 0}
                                    onChange={(event) => onhandleChange(event, laborerInfo)}
                                    InputProps={{ ...textFieldDefault }}
                                >
                                </TextField>
                            </Grid>

                        </Grid>
                        {/* <Grid container> */}
                        {/* <Grid className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='需方分厂'
                                    variant="outlined"
                                    name='ownCompany'
                                    select
                                    value={laborerInfo.ownCompany || 0}
                                    style={{
                                        width: '160px',
                                    }}
                                    onChange={(event) => onhandleTextChange(event, laborerInfo)}
                                >
                                    {companyList && companyList.map(ele => (
                                        <MenuItem key={ele.companyId} value={ele.companyId}>
                                            {ele.companyName}
                                        </MenuItem>)
                                    )}
                                </TextField>
                            </Grid>
                            <Grid className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    label='需方班组'
                                    name='ownDpt'
                                    variant="outlined"
                                    select
                                    value={laborerInfo.ownDpt || 0}
                                    style={{
                                        width: '160px',
                                    }}
                                    onChange={(event) => onhandleTextChange(event, laborerInfo)}
                                >
                                    {departmentList && departmentList.map(ele => (
                                        <MenuItem key={ele.dptId} value={ele.dptId}>
                                            {ele.dptName}
                                        </MenuItem>)
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}> <Divider orientation="vertical" /></Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

LaborerManageInfoComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    openFlag: PropTypes.bool,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleTextChange: PropTypes.func,
    onhandleChange: PropTypes.func,
};

LaborerManageInfoComponent.defaultProps = {

};

export default withStyles(LaborerManageInfoComponent);