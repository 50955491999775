import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  label: {
    marginTop: 7
  },
  gridContainer: {
    margin: 10
  },
  select: {
    width: '90%',
    marginTop: 7
  },
  dateSelect: {
    width: '90%'
  }
});

export default withStyles(styles);
