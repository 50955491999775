import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: theme.spacing(2)
  },
  textField: {
    minWidth: 50,
    width: '100%'
  }
});

export default withStyles(styles);
