import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import { getDeviceCategoryTree } from './trees';
import { getDeviceUseStatus, getDeviceExtCat, getDeviceMedium, getDeviceDoctype } from './devices';
import { removeObject } from './minio';
import { getInstallations, getInstallation } from './installation';
import { push } from 'connected-react-router';

import * as _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    search: {
        companyId: undefined,
        dangerLevel: undefined,
    },
    pageSize: 5,
    dangerLevelList: [],
    majorHazardlist: {
        total: 0,
        list: []
    },
    majorHazardDoc: [],
    majorHazardDocPage: {},
    majorHazardDocPageError: {},
    majorHazard: {},
}
export const routeToMajorHazardDoc = createAction('@@xcloud/majorHazard/routeToMajorHazardDoc',
    (routeType, id) => (dispatch, getState) => {
        if (routeType === 'list') {
            dispatch(push(`/majorhazardsources/list/${id}`));
        } else {
            dispatch(push(`/majorhazardsources/detection/${id}`));
        }
    }
);

export const backToList = createAction('@@xcloud/majorHazard/backToList',
    (routeType) => (dispatch, getState) => {
        if (routeType === 'list') {
            dispatch(push('/majorhazardsources/list'));
        } else {
            dispatch(push('/majorhazardsources/detection'));
        }

    }
);

export const setMajorHazard = createAction('@@xcloud/majorHazard/setMajorHazard');

export const setMajorHazardDoc = createAction('@@xcloud/majorHazard/setMajorHazardDoc');

export const setMajorHazardDocPage = createAction('@@xcloud/majorHazard/setMajorHazardDocPage');

export const setMajorHazardDocPageError = createAction('@@xcloud/majorHazard/setMajorHazardDocPageError');

export const getMajorHazardById = createAction(
    '@@xcloud/majorHazard/getMajorHazardById',
    (hazardId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/majorHazard/detail/${hazardId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setMajorHazard(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询重大危险源评估信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getMajorHazardDoc = createAction(
    '@@xcloud/majorHazard/getMajorHazardDoc',
    (hazardId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/majorHazard/doc?hazardId=${hazardId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setMajorHazardDoc(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查询重大危险源评估报告信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const initMajorHazardDocPage = createAction('@@xcloud/majorHazard/initMajorHazardDocPage',
    () => (dispatch, getState) => {
        let majorHazardDocPage = {
            docName: undefined,
            docType: undefined,
            docDesc: undefined,
        }
        let majorHazardDocPageError = {
            docName: '',
            docType: '',
            docDesc: '',
        };
        dispatch(setMajorHazardDocPage(majorHazardDocPage))
        dispatch(setMajorHazardDocPageError(majorHazardDocPageError));
    }
);

export const initMajorHazardDoc = createAction('@@xcloud/majorHazard/initMajorHazardDoc',
    (hazardId) => (dispatch, getState) => {
        Promise.all([
            dispatch(getMajorHazardById(hazardId)),
            dispatch(getMajorHazardDoc(hazardId)),
            dispatch(getDangerLevel()),
            dispatch(getDeviceDoctype())
        ]).then(() => {
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '初始化重大危险源评估报告信息';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });

    }
);

export const changeMajorHazardDocStatus = createAction(
    '@@xcloud/majorHazard/changeMajorHazardDocStatus',
    (majorhazardDto, mybucket, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            docId: majorhazardDto.docId,
            hazardId: majorhazardDto.hazardId,
            status: 'inactive'
        }

        return httpClient
            .patch(porxyApi + `/majorHazard/doc`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除重大危险源评估报告成功'))
                    //重新检索列表
                    dispatch(removeObject(mybucket, majorhazardDto.docLink));
                    if (callback) callback();

                    return res.data;
                }
            })
            .catch(err => {
                let msg = `删除重大危险源评估报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createMajorHazardDoc = createAction(
    '@@xcloud/majorHazard/createMajorHazardDoc',
    (doc, callback) => (dispatch, getState, httpClient) => {

        return httpClient
            .post(porxyApi + `/majorhazard/doc`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '上传重大危险源评估报告成功'))
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `上传重大危险源评估报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const initMajorHazardHeader = createAction('@@xcloud/majorHazard/initMajorHazardHeader',
    () => (dispatch, getState) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyList = getState().constant.companies;
        let companyInfo = _.find(companyList, { companyId: company })
        let taskArray = [
            dispatch(resetSearch()),
            dispatch(getDangerLevel()),
            dispatch(getDeviceExtCat()),
        ]
        if (!companyInfo.pid) {
            taskArray.push(dispatch(getInstallations({ size: 0, offset: 0 })))
        } else {
            taskArray.push(dispatch(getInstallations({ companyId: company, size: 0, offset: 0 })))
        }

        Promise.all(taskArray).then(() => {
        }).catch(err => {
            let msg = '初始化重大危险源搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const getArea = createAction('@@xcloud/majorHazard/getArea',
    (value) => (dispatch, getState, httpClient) => {
        dispatch(getInstallation(value))
    });

export const initMajorHazardList = createAction('@@xcloud/majorHazard/initMajorHazardList',
    (searchType) => (dispatch, getState) => {
        Promise.all([dispatch(setMajorHazardlist({
            total: 0,
            list: []
        }))
        ]).then(() => {
            Promise.all([
                dispatch(getDeviceCategoryTree()),
                dispatch(getDeviceUseStatus()),
                dispatch(getMajorHazard({ searchType: searchType })),
                dispatch(getDangerLevel()),
                dispatch(getDeviceMedium())
            ]).then(() => {
            }).catch(err => {
                let msg = '初始化重大危险源搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        }).catch(err => {
            let msg = '初始化重大危险源搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const createMajorHazard = createAction('@@xcloud/majorHazard/createMajorHazard',
    (data, callback) => (dispatch, getState, httpClient) => {
        let newMajorHazard = {
            hazardName: data.hazardName,
            dangerLevel: data.dangerLevel,
            // hazardHead: data.hazardHead,
            target: data.target,
            targetId: data.targetId,
            targetName: data.targetName,
        }

        return httpClient
            .post(porxyApi + `/majorhazard`, newMajorHazard, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = '指定重大危险源失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updateMajorHazard = createAction('@@xcloud/majorHazard/updateMajorHazard',
    (data, callback) => (dispatch, getState, httpClient) => {
        let newMajorHazard = {
            hazardId: data.hazardId,
            hazardName: data.hazardName,
            dangerLevel: data.dangerLevel,
            // hazardHead: data.hazardHead,
            status: data.status,
            target: data.target,
            tenantId: data.tenantId,
            targetId: data.targetId,
            targetName: data.targetName,
        }

        return httpClient
            .patch(porxyApi + `/majorhazard`, newMajorHazard, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = '指定重大危险源失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const addNewMajorHazard = createAction(
    '@@xcloud/majorHazard/addNewMajorHazard',
    (dto) => (dispatch, getState, httpClient) => {
        let newMajorHazard = {
            hazardName: '',
            dangerLevel: '',
            hazardHead: null,
            target: dto.target,
            targetId: dto.targetId,
            targetName: dto.targetName,
            tableData: {
                editing: 'update'
            }
        }
        let majorHazardlist = _.clone(getState().majorHazard.majorHazardlist);
        majorHazardlist.total = majorHazardlist.total + 1;
        if (majorHazardlist.list && majorHazardlist.list.length > 0) {
            majorHazardlist.list.unshift(newMajorHazard)
        } else {
            majorHazardlist.list = [newMajorHazard]
        }
        dispatch(setMajorHazardlist(majorHazardlist));
    }
);

export const updMajorHazardDevices = createAction(
    '@@xcloud/majorHazard/updMajorHazardDevices',
    (datas, callback) => (dispatch, getState, httpClient) => {
        let dtos = []
        datas.forEach(data => {
            if (data.dangerLevel && data.dangerLevel !== '') {
                dtos.push({
                    deviceId: data.deviceId,
                    dangerLevel: data.dangerLevel
                })
            }

        })

        return httpClient
            .patch(porxyApi + `/devices`, dtos)
            .then((res) => {
                if (res.data) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `选择重大危险源设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })

    }
)
export const getMajorHazard = createAction(
    '@@xcloud/majorHazard/getMajorHazard',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/majorhazard"
        let searchCond = getState().majorHazard.search;
        let pageSize = getState().majorHazard.pageSize
        if (query && query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }

        if (query && query.companyId && query.companyId !== "") {
            url += "&companyId=" + query.companyId
        } else if (searchCond.companyId && searchCond.companyId !== "") {
            url += "&companyId=" + searchCond.companyId
        }

        if (query && query.dangerLevel && query.dangerLevel !== "") {
            url += "&dangerLevel=" + query.dangerLevel
        } else if (searchCond.dangerLevel && searchCond.dangerLevel !== "") {
            url += "&dangerLevel=" + searchCond.dangerLevel
        }

        if (query && query.searchType && query.searchType !== "") {
            url += "&searchType=" + query.searchType
        }

        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }

        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data;
                    let mediumList = getState().devices.medium
                    if (data.list && data.list.length > 0) {
                        data.list.forEach(list => {
                            if (list.majorHazardDevices && list.majorHazardDevices.length > 0) {
                                list.majorHazardDevices.forEach(majorHazardDevice => {
                                    majorHazardDevice.hazardId = list.hazardId;
                                    if (_.isString(majorHazardDevice.medium)) {
                                        let arr = majorHazardDevice.medium.split(',')
                                        let vals = []
                                        for (let j = 0; j < arr.length; j++) {
                                            if (mediumList && mediumList.length > 0) {
                                                let mediumValue = _.find(mediumList, function (o) { return o.code === arr[j] })
                                                if (mediumValue) {
                                                    vals.push(mediumValue.name)
                                                }
                                            }
                                        }
                                        majorHazardDevice.mediumName = vals.join(',')
                                    }
                                })

                            }
                        })
                    }
                    dispatch(setMajorHazardlist(data));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取得重大危险源列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const resetSearch = createAction('@@xcloud/majorHazard/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompanyId = null
        let companyList = getState().constant.companies;
        let companyInfo = _.find(companyList, { companyId: company })
        if (!companyInfo || !companyInfo.pid) {
            newCompanyId = undefined
        } else {
            newCompanyId = company
        }
        // let search = getState().majorHazard.search;
        let newSearch = {
            // ...search,
            companyId: newCompanyId ? newCompanyId : undefined,
            dangerLevel: undefined,
        }
        dispatch(setSearch(newSearch));
    }
);

export const getDangerLevel = createAction('@@xcloud/majorHazard/getDangerLevel',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=DANGERLEVEL";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                }
                return res;
            }).catch(err => {
                let msg = '设备使用状况获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const setSearch = createAction('@@xcloud/majorHazard/setSearch');

export const setPageSize = createAction('@@xcloud/majorHazard/setPageSize');

export const setMajorHazardlist = createAction('@@xcloud/majorHazard/setMajorHazardlist');

export const majorHazardReducer = handleActions(
    {
        [getDangerLevel]: (state, { payload: value }) => {
            return {
                ...state,
                dangerLevelList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setMajorHazardlist]: (state, { payload: value }) => {
            return {
                ...state,
                majorHazardlist: value
            };
        },
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [setMajorHazard]: (state, { payload: value }) => {
            return {
                ...state,
                majorHazard: value
            };
        },
        [setMajorHazardDoc]: (state, { payload: value }) => {
            return {
                ...state,
                majorHazardDoc: value
            };
        },
        [setMajorHazardDocPage]: (state, { payload: value }) => {
            return {
                ...state,
                majorHazardDocPage: value
            };
        },
        [setMajorHazardDocPageError]: (state, { payload: value }) => {
            return {
                ...state,
                majorHazardDocPageError: value
            };
        },
    },
    initialState
);

export default majorHazardReducer;