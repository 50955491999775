import Konva from 'konva';
// import { v4 as uuidv4 } from 'uuid';

export function init(previewStage, konvaDiv) {
    const state = {
        color: "red",
        width: 800,
        height: 400,
        strokeWidth: 20
    };
    const controlLayer = new Konva.Layer({
        name: 'controlLayer'
    });
    previewStage.add(controlLayer)

    // 生成区域隐藏背景
    const group = new Konva.Rect({
        name: 'previewRect',
        draggable: true,
        zIndex: 2,
        x: 0,
        y: 0,
        width: state.width,
        height: state.height,
        stroke: state.color,
        strokeWidth: state.strokeWidth
    });

    group.on('dragmove', function (e) {
        if (group.x() < 0) {
            group.x(0)
        }
        if (group.y() < 0) {
            group.y(0)
        }
        if (group.x() + group.width() > previewStage.width() / previewStage.scaleX()) {
            group.x(previewStage.width() / previewStage.scaleX() - group.width())
        }
        if (group.y() + group.height() > previewStage.height() / previewStage.scaleY()) {
            group.y(previewStage.height() / previewStage.scaleY() - group.height())
        }
        let rateX = group.x() / (previewStage.width() / previewStage.scaleX() - group.x());
        let rateY = group.y() / (previewStage.height() / previewStage.scaleY() - group.y());
        konvaDiv.scrollTop = (konvaDiv.scrollHeight - konvaDiv.clientHeight) * rateY;
        konvaDiv.scrollLeft = (konvaDiv.scrollWidth - konvaDiv.clientWidth) * rateX;
    });

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
    });

    controlLayer.add(group);
    controlLayer.draw();
}

