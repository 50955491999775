import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    // Card,
    Typography,
    // Button,
    Grid,
    TablePagination
} from "@material-ui/core";
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import MaterialTable from 'material-table';
import { localization, options } from "../../../../utils/mtable";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import * as _ from 'lodash';

const RuleDeviceSetupComponent = props => {
    const {
        classes,
        // onhandleBack,
        onhandleSelectionChange,
        onhandleCheckChange,
        onhandleSelectDevices,
        onhandleGetRuleTargets,
        // currentRule,
        readonly,
        devCatTree,
        devCatSelection,
        devExtCatSelection,
        ruleTargets,
        companyList,
        devicelist,
        objectPageSize,
        objectExceptPageSize,
        onhandleSetObjectPageSize,
        onhandleSetObjectExceptPageSize,
        extcatTree
    } = props;

    // 备选设备表
    const [page1, setPage1] = useState(0);

    // 已选设备表
    const [page2, setPage2] = useState(0);

    const renderCompany = (device) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === device.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    return (
        <div>
            <Grid container direction='column' className={classes.gridContainer}>
                <Grid item >
                    <MaterialTable
                        // title={<Typography variant="h4" gutterBottom>
                        //     已选设备列表
                        // </Typography>}
                        columns={[
                            {
                                title: '单位',
                                field: 'companyId',
                                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                                editable: 'never'
                            },
                            {
                                title: '装置名称',
                                field: 'installTitle',
                                render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                                editable: 'never'
                            },
                            {
                                title: '设备编号',
                                field: 'deviceNo',
                                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                                editable: 'never'
                            },
                            {
                                title: '设备名称',
                                field: 'deviceName',
                                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                                editable: 'never'
                            },
                            {
                                title: '设备位号',
                                field: 'tagNo',
                                render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                                editable: 'never'
                            }]}
                        data={ruleTargets.list}
                        options={{
                            ...options,
                            pageSize: objectPageSize,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            showTitle: false,
                            selection: !readonly,
                            showTextRowsSelected: false
                        }}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        components={{
                            Pagination: props => {
                                if (page2 !== 0 && ruleTargets.total <= page2 * objectPageSize) {
                                    setPage2(0)
                                }
                                return <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    rowsPerPage={objectPageSize}
                                    count={ruleTargets.total}
                                    page={page2}
                                    onChangePage={(event, pageNum) => {
                                        // console.log(pageNum)
                                        setPage2(pageNum);
                                        onhandleGetRuleTargets({
                                            offset: pageNum * objectPageSize,
                                            size: objectPageSize
                                        })
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        onhandleSetObjectPageSize(event.target.value);
                                        setPage2(0);
                                        onhandleGetRuleTargets({
                                            offset: 0
                                        })
                                    }}
                                />
                            },
                            Toolbar: props => (
                                <div>
                                    {/* <MTableToolbar {...props} /> */}
                                    <div style={{ padding: '0 10px 10px' }}>
                                        <Grid container spacing={1} className={classes.gridContainer}>
                                            <Grid item>
                                                <Typography variant='h4'>
                                                    已选设备列表
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )
                        }}
                        actions={!readonly ? [
                            {
                                tooltip: '将所选项从已选设备中删除',
                                icon: () => (<DoubleArrowIcon style={{ transform: "rotate(90deg)" }} />),
                                onClick: (evt, data) => {
                                    onhandleCheckChange(data, false);
                                }
                            }
                        ] : []}
                    >
                    </MaterialTable>
                </Grid>
                {!readonly ? (
                    <Grid item >
                        <MaterialTable
                            // title={<Typography variant="h4" gutterBottom>
                            //     备选设备列表
                            // </Typography>}
                            columns={[
                                {
                                    title: '单位',
                                    field: 'companyId',
                                    render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                                    editable: 'never'
                                },
                                {
                                    title: '装置名称',
                                    field: 'installTitle',
                                    render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                                    editable: 'never'
                                },
                                {
                                    title: '设备编号',
                                    field: 'deviceNo',
                                    render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                                    editable: 'never'
                                },
                                {
                                    title: '设备名称',
                                    field: 'deviceName',
                                    render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                                    editable: 'never'
                                },
                                {
                                    title: '设备位号',
                                    field: 'tagNo',
                                    render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                                    editable: 'never'
                                }]}
                            data={devicelist.list}
                            options={{
                                ...options,
                                showTitle: false,
                                pageSize: objectExceptPageSize,
                                tableLayout: 'auto',
                                actionsColumnIndex: -1,
                                selection: !readonly,
                                showTextRowsSelected: false
                            }}
                            style={{
                                border: '0px solid black',
                                boxShadow: 'none'
                            }}
                            localization={localization}
                            actions={[
                                {
                                    tooltip: '将所选项加入到已选设备',
                                    icon: () => (<DoubleArrowIcon style={{ transform: "rotate(270deg)" }} />),
                                    onClick: (evt, data) => {
                                        onhandleCheckChange(data, true);
                                    }
                                }
                            ]}
                            components={{
                                Pagination: props => {
                                    if (page1 !== 0 && devicelist.total <= page1 * objectExceptPageSize) {
                                        setPage1(0)
                                    }
                                    return <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 20, 50]}
                                        rowsPerPage={objectExceptPageSize}
                                        count={devicelist.total}
                                        page={page1}
                                        onChangePage={(event, pageNum) => {
                                            setPage1(pageNum);
                                            onhandleSelectDevices({ offset: pageNum * objectExceptPageSize, size: objectExceptPageSize })
                                        }}
                                        onChangeRowsPerPage={(event) => {
                                            props.onChangeRowsPerPage(event);
                                            onhandleSetObjectExceptPageSize(event.target.value);
                                            setPage1(0);
                                            onhandleSelectDevices({ offset: 0 })
                                        }}

                                    />
                                },
                                Toolbar: props => (
                                    <div>
                                        {/* <MTableToolbar {...props} /> */}
                                        <div style={{ padding: '0 10px 10px' }}>
                                            <Typography variant='h4'>备选设备列表</Typography>
                                            <Grid container spacing={1} className={classes.gridContainer}>
                                                <Grid item xs={2}>
                                                    <Typography gutterBottom>
                                                        设备类型
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TreeSelect
                                                        style={{ width: '100%' }}
                                                        transitionName="rc-tree-select-dropdown-slide-up"
                                                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                                        dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                                        placeholder={<i>请下拉选择</i>}
                                                        allowClear
                                                        treeLine
                                                        value={devCatSelection}
                                                        labelInValue
                                                        treeData={devCatTree}
                                                        treeNodeFilterProp="label"
                                                        filterTreeNode={false}
                                                        onChange={(value) => onhandleSelectionChange(value, 'cat')}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography gutterBottom>
                                                        扩展类别
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TreeSelect
                                                        style={{ width: '100%' }}
                                                        transitionName="rc-tree-select-dropdown-slide-up"
                                                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                                        dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                                        placeholder={<i>请下拉选择</i>}
                                                        allowClear
                                                        treeLine
                                                        value={devExtCatSelection}
                                                        labelInValue
                                                        treeData={extcatTree}
                                                        treeNodeFilterProp="label"
                                                        filterTreeNode={false}
                                                        onChange={(value) => onhandleSelectionChange(value, 'extCat')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                ),
                            }}
                        >
                        </MaterialTable>
                    </Grid>
                ) : null}


            </Grid>
        </div>
    );
};

RuleDeviceSetupComponent.propTypes = {
    onhandleBack: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    onhandleCheckChange: PropTypes.func.isRequired,
    onhandleSelectDevices: PropTypes.func.isRequired,
    onhandleGetRuleTargets: PropTypes.func.isRequired,
    currentRule: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired,
    devCatTree: PropTypes.array.isRequired,
    devCatSelection: PropTypes.object,
    ruleTargets: PropTypes.object.isRequired,
    devicelist: PropTypes.object.isRequired,
    objectPageSize: PropTypes.number.isRequired,
    objectExceptPageSize: PropTypes.number.isRequired,
    onhandleSetObjectPageSize: PropTypes.func.isRequired,
    onhandleSetObjectExceptPageSize: PropTypes.func.isRequired,
    companyList: PropTypes.array.isRequired,
    extcatTree: PropTypes.array.isRequired,
};

export default withStyles(RuleDeviceSetupComponent);
