import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getDeviceOperationReport,
    // getDeviceListForCsv,
    initDeviceReport,
    updSearchData,
    resetSearch
} from '@/redux/modules/deviceReport';
import DeviceReport from "../../../components/DeviceManagement/DeviceReport";
import { getOptionList, INSPECTION_DICT_TYPES } from '@/utils/constant';
import { csv } from '@/utils/exportfile';
import {
    getMoment as moment,
} from '@/utils/datetime';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { getActualCompany, getManageDpt } from '@/utils/constant';
// import { DEVICE_OPERATION_STATUS } from '@/utils/device';
import { antDContrastingColors as defaultColors } from '@/utils/echartUtils';

import _ from 'lodash';

class DeviceReportContainer extends Component {


    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            creatorName: false,
            routeType: '',
            // statusList: {
            //     'active': '待审定', 'evaluated': '待治理', 'treating': '治理中',
            //     'done': '已治理', 'invalid': '已销次'
            // }
        }

    }

    UNSAFE_componentWillMount() {
        const { isBack, routeType } = this.props;
        this.setState({
            routeType: routeType
        })
        this.props.onhandleInit(isBack, routeType);
    }
    // componentDidMount() {
    // }

    handleClearSearch() {
        this.props.onhandleClearSarch();
    }

    // onhandleExportCsv = () => {
    //     this.props.onhandleGetDeviceOperationReportForCsv({
    //         ...this.props.search,
    //         offset: 0,
    //         size: 0,
    //         sort: "-update_time"
    //     }, res => {
    //         this.generateCsvData(res);
    //     });
    // }

    findName = (code, type) => {
        let obj = _.find(this.props.inspectionDict, function (o) { return o.code === code && o.type === type })
        return obj ? obj.name : null;
    }

    renderCompany = (cid) => {
        let com = _.find(this.props.companies, function (o) { return o.companyId === cid });
        return com ? com.companyAlias : null;
    }

    renderDepartment = (c) => {
        let dpt = _.find(this.props.departments, function (o) { return o.dptId === c.dptId })
        return dpt ? dpt.companyDptName : null;
    }

    renderStatus = (s) => {
        return this.state.statusList[s] ? this.state.statusList[s] : '未知'
    }

    handleSelect = (value, field) => {
        let newSearch = { ...this.props.search };
        switch (field) {
            case 'companyId':
                newSearch.companyId = value.target.value
                break;
            case 'rootCause':
                newSearch.rootCause = value.target.value
                break;
            case 'status':
                newSearch.status = value.target.value
                break;
            case 'view':
                let startDate = moment()().startOf('day').format("YYYY-MM-DD");
                let endDate = moment()().endOf('day').format("YYYY-MM-DD");

                switch (value.target.value) {
                    case 'year':
                        startDate = moment()().startOf('year').format("YYYY-MM-DD");
                        endDate = moment()().endOf('year').format("YYYY-MM-DD");
                        break;
                    case 'month':
                        startDate = moment()().startOf('year').format("YYYY-MM-DD");
                        endDate = moment()().endOf('month').format("YYYY-MM-DD");
                        break;
                    case 'week':
                        startDate = moment()().startOf('month').startOf('week').format("YYYY-MM-DD");
                        endDate = moment()().endOf('week').format("YYYY-MM-DD");
                        break;
                    case 'day':
                        startDate = moment()().startOf('day').format("YYYY-MM-DD");
                        endDate = moment()().startOf('day').format("YYYY-MM-DD");
                        break;
                    default:
                }
                newSearch = {
                    ...this.props.search,
                    intervalDate: {
                        view: value.target.value,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
    }

    handleDateChange(value, field) {
        const { search } = this.props;
        let newSearch = { ...search };

        const date = moment()(value);

        switch (field) {
            case 'startDate':
                newSearch.intervalDate.startDate = date.startOf(search.intervalDate.view).format('YYYY-MM-DD');
                break;
            case 'endDate':
                if (
                    date.isBefore(moment()(newSearch.intervalDate.startDate))
                ) {
                    newSearch.intervalDate.endDate = moment()(newSearch.intervalDate.startDate).endOf(search.intervalDate.view).format('YYYY-MM-DD');
                } else {
                    newSearch.intervalDate.endDate = date.endOf(search.intervalDate.view).format('YYYY-MM-DD');
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    // 类别饼图 运维计划分类统计
    handleDeviceCatOptPlanOption = () => {

        // 图配置逻辑
        const {
            deviceOperationReport,
            deviceCategoryList,
        } = this.props;

        // 构造series, legend
        const series = [];
        const legendData = [];

        deviceCategoryList && deviceCategoryList.forEach(cat => {
            // legendData.push(cat.pname ? cat.pname : cat.name)
            legendData.push(cat.name)
        })
        legendData.push('其他');
        legendData.push('未归类');

        const reportFormatted = [];
        if (deviceOperationReport && deviceOperationReport.length) {
            deviceOperationReport.forEach(report => {
                let deviceCat = report.deviceCat;
                // if (['active'].includes(report.status)) { deviceCat = null }
                let index = _.findIndex(reportFormatted, {
                    deviceCat: deviceCat,
                    // companyId: report.companyId
                })
                if (index > -1) {
                    reportFormatted[index].planCount += report.planCount;
                } else {
                    reportFormatted.push({
                        planCount: report.planCount,
                        deviceCat: deviceCat,
                        // companyId: report.companyId
                    });
                }
            })
        }

        series.push({
            type: 'pie',
            startAngle: 135,
            minShowLabelAngle: 2,
            percentPrecision: 1,
            stillShowZeroSum: false,
            label: {
                // alignTo: 'edge',
                // edgeDistance: 10,
                fontSize: 12,
                formatter: '{name|{b}} {time|{d}%}',
                minMargin: 5,
                lineHeight: 15,
                rich: {
                    time: {
                        // fontSize: 10,
                        color: '#999'
                    }
                }
            },
            labelLine: {
                length: 35,
                length2: 25,
                maxSurfaceAngle: 50
            },
            radius: ['0%', '50%'],
            data: legendData.map(str => ({ value: null, name: str })),
            avoidLabelOverlap: true,
        })

        series.forEach(serie => {
            reportFormatted.forEach(item => {
                const itemDeviceCat = _.find(deviceCategoryList, { code: item.deviceCat });
                let catName = itemDeviceCat ? itemDeviceCat.name : '未归类';
                // 根据阈值合并最末值
                // catName = (item.planCount / totalCount) > 0.01 ? catName : '其他';
                const dataIndex = _.findIndex(serie.data, o => catName === o.name);
                if (dataIndex > -1 && item.status !== 'invalid') {
                    serie.data[dataIndex].value += item.planCount;
                }
            })
            // 根据排名合并最末值
            serie.data.sort((a, b) => b.value - a.value);
            const topCatsNum = 5;
            let topCatsList = [];
            let restDataList = [];
            if (serie.data.length > 0 && serie.data.length > topCatsNum) {
                topCatsList = serie.data.splice(0, topCatsNum);
                restDataList = serie.data;
                let mergedList = [{ name: '其他', value: null }];
                restDataList.forEach(item => {
                    mergedList[0].value += item.value;
                });
                restDataList = mergedList;
            } else {
                topCatsList = serie.data;
            }
            serie.data = [...topCatsList, ...restDataList];
        })

        // console.log('============= deviceOperationReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)

        return {
            title: { text: '按类别统计', left: 'center', textStyle: { fontWeight: 'bold', fontSize: 14 } },
            series: series,
            tooltip: {
                formatter: '{b} {c}次<br/>{d}%',
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
            },
            color: defaultColors
        }
    }

    // 按时间统计运维计划数量
    handleDeviceTimeOptPlanOption = () => {
        // 图配置逻辑
        const {
            search,
            deviceOperationReport,
            companies,
            currentUserInfo,
            // departments,
        } = this.props;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];

        // 子组织(组织标识符seriesId)
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                series.push({
                    seriesId: searchCompany.companyId,
                    name: searchCompany.companyAlias,
                    stack: '叠',
                    type: 'bar',
                    data: [],
                    emphasis: emphasis,
                    barMaxWidth: '50'
                })
                legendData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    series.push({
                        seriesId: comp.companyId,
                        name: comp.companyAlias,
                        stack: '叠',
                        type: 'bar',
                        data: [],
                        emphasis: emphasis,
                        barMaxWidth: '50'
                    })
                    legendData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位统计
            series.push({
                seriesId: userCompany.companyId,
                name: userCompany.companyAlias,
                stack: '叠',
                type: 'bar',
                data: [],
                emphasis: emphasis,
                barMaxWidth: '50'
            })
            legendData.push(userCompany.companyAlias)
        }

        const reportFormatted = [];
        if (deviceOperationReport && deviceOperationReport.length) {
            if (search.intervalDate.view === 'week') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        week: report.week,
                        date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].planCount += report.planCount;
                    } else {
                        reportFormatted.push({
                            planCount: report.planCount,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year,
                            week: report.week,
                            date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        });
                    }
                })

            }
            if (search.intervalDate.view === 'month') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        month: report.month
                    })
                    if (index > -1) {
                        reportFormatted[index].planCount += report.planCount;
                    } else {
                        reportFormatted.push({
                            planCount: report.planCount,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year,
                            month: report.month
                        });
                    }
                })
            }
            if (search.intervalDate.view === 'year') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].planCount += report.planCount;
                    } else {
                        reportFormatted.push({
                            planCount: report.planCount,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year
                        });
                    }
                })
            }
        }

        let xAxisData = [];
        // reportFormatted.forEach(item => {
        //     let xAxisCoord = '';
        //     if (search.intervalDate.view === 'day') { xAxisCoord = [item.year, item.month, item.day].join('-') }
        //     if (search.intervalDate.view === 'week') { xAxisCoord = item.date }
        //     // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
        //     if (search.intervalDate.view === 'month') { xAxisCoord = [item.year, item.month].join('-') }
        //     if (search.intervalDate.view === 'year') { xAxisCoord = item.year }
        //     xAxisData.push(xAxisCoord);
        // })
        const indexMoment = moment()(search.intervalDate.startDate);
        const endMoment = moment()(search.intervalDate.endDate);
        while (indexMoment.isSameOrBefore(endMoment)) {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'days');
            }
            if (search.intervalDate.view === 'week') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'weeks');
            }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') {
                xAxisCoord = indexMoment.format('YYYY-MM');
                indexMoment.add(1, 'months');
            }
            if (search.intervalDate.view === 'year') {
                xAxisCoord = indexMoment.format('YYYY');
                indexMoment.add(1, 'years');
            }
            xAxisData.push(xAxisCoord);
        }
        xAxisData = _.uniq(xAxisData);

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);

        reportFormatted.forEach(item => {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
            if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
            if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }
            const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
            totalArray[axisIndex] += item.planCount;
        })
        // 各分厂数据
        series.forEach(serie => {
            serie.data = [...Array(xAxisData.length)].map(_ => 0);
            reportFormatted.forEach(item => {
                let xAxisCoord = '';
                if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
                if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
                // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
                if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
                if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }

                const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
                if (item.companyId === serie.seriesId || serie.seriesId === 0) {
                    if (axisIndex > -1) {
                        serie.data[axisIndex] += item.planCount;
                    }
                }
            })
        })
        // 总数据作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        return totalArray[param.dataIndex];
                        // console.log(param);
                    },
                }
            }
        })

        // console.log('============= deviceReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(deviceOperationReport)
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)
        // console.log(xAxisData)

        return {
            title: { text: '按发生时间统计', left: 'center', textStyle: { fontWeight: 'bold', fontSize: 14 } },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '次数',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 类别饼图 运维决算分类统计
    handleDeviceCatOptCostOption = () => {

        // 图配置逻辑
        const {
            deviceOperationReport,
            deviceCategoryList,
        } = this.props;

        // 构造series, legend
        const series = [];
        const legendData = [];

        deviceCategoryList && deviceCategoryList.forEach(cat => {
            // legendData.push(cat.pname ? cat.pname : cat.name)
            legendData.push(cat.name)
        })
        legendData.push('其他');
        legendData.push('未归类');

        const reportFormatted = [];
        if (deviceOperationReport && deviceOperationReport.length) {
            deviceOperationReport.forEach(report => {
                let deviceCat = report.deviceCat;
                // if (['active'].includes(report.status)) { deviceCat = null }
                let index = _.findIndex(reportFormatted, {
                    deviceCat: deviceCat,
                    // companyId: report.companyId
                })
                if (index > -1) {
                    reportFormatted[index].cost += report.cost;
                } else {
                    reportFormatted.push({
                        cost: report.cost,
                        deviceCat: deviceCat,
                        // companyId: report.companyId
                    });
                }
            })
        }

        series.push({
            type: 'pie',
            startAngle: 135,
            minShowLabelAngle: 2,
            percentPrecision: 1,
            stillShowZeroSum: false,
            label: {
                // alignTo: 'edge',
                // edgeDistance: 10,
                fontSize: 12,
                formatter: '{name|{b}} {time|{d}%}',
                minMargin: 5,
                lineHeight: 15,
                rich: {
                    time: {
                        // fontSize: 10,
                        color: '#999'
                    }
                }
            },
            labelLine: {
                length: 35,
                length2: 25,
                maxSurfaceAngle: 50
            },
            radius: ['0%', '50%'],
            data: legendData.map(str => ({ value: null, name: str })),
            avoidLabelOverlap: true,
        })

        series.forEach(serie => {
            reportFormatted.forEach(item => {
                const itemDeviceCat = _.find(deviceCategoryList, { code: item.deviceCat });
                const catName = itemDeviceCat ? itemDeviceCat.name : '未归类';
                // 根据阈值合并最末值
                // catName = (item.planCount / totalCount) > 0.01 ? catName : '其他';
                const dataIndex = _.findIndex(serie.data, o => catName === o.name);
                if (dataIndex > -1 && item.status !== 'invalid') {
                    serie.data[dataIndex].value += item.cost;
                }
            })
            // 根据排名合并最末值
            serie.data.sort((a, b) => b.value - a.value);
            const topCatsNum = 5;
            let topCatsList = [];
            let restDataList = [];
            if (serie.data.length > 0 && serie.data.length > topCatsNum) {
                topCatsList = serie.data.splice(0, topCatsNum);
                restDataList = serie.data;
                let mergedList = [{ name: '其他', value: null }];
                restDataList.forEach(item => {
                    mergedList[0].value += item.value;
                });
                restDataList = mergedList;
            } else {
                topCatsList = serie.data;
            }
            serie.data = [...topCatsList, ...restDataList];
        })

        // console.log('============= deviceOperationReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(reportFormatted)
        // console.log(series)

        return {
            title: { text: '按类别统计', left: 'center', textStyle: { fontWeight: 'bold', fontSize: 14 } },
            series: series,
            tooltip: {
                formatter: '{b} {c}￥<br/>{d}%',
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
            },
            color: defaultColors
        }
    }

    // 按时间统计运维计划决算
    handleDeviceTimeOptCostOption = () => {
        // 图配置逻辑
        const {
            search,
            deviceOperationReport,
            companies,
            currentUserInfo,
            // departments,
        } = this.props;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];

        // 子组织(组织标识符seriesId)
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                series.push({
                    seriesId: searchCompany.companyId,
                    name: searchCompany.companyAlias,
                    stack: '叠',
                    type: 'bar',
                    data: [],
                    emphasis: emphasis,
                    barMaxWidth: '50'
                })
                legendData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    series.push({
                        seriesId: comp.companyId,
                        name: comp.companyAlias,
                        stack: '叠',
                        type: 'bar',
                        data: [],
                        emphasis: emphasis,
                        barMaxWidth: '50'
                    })
                    legendData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位统计
            series.push({
                seriesId: userCompany.companyId,
                name: userCompany.companyAlias,
                stack: '叠',
                type: 'bar',
                data: [],
                emphasis: emphasis,
                barMaxWidth: '50'
            })
            legendData.push(userCompany.companyAlias)
        }

        const reportFormatted = [];
        if (deviceOperationReport && deviceOperationReport.length) {
            if (search.intervalDate.view === 'week') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        week: report.week,
                        date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].cost += report.cost;
                    } else {
                        reportFormatted.push({
                            cost: report.cost,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year,
                            week: report.week,
                            date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        });
                    }
                })

            }
            if (search.intervalDate.view === 'month') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        month: report.month
                    })
                    if (index > -1) {
                        reportFormatted[index].cost += report.cost;
                    } else {
                        reportFormatted.push({
                            cost: report.cost,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year,
                            month: report.month
                        });
                    }
                })
            }
            if (search.intervalDate.view === 'year') {
                deviceOperationReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        companyId: report.companyId,
                        companyName: report.companyName,
                        year: report.year,
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].cost += report.cost;
                    } else {
                        reportFormatted.push({
                            cost: report.cost,
                            companyId: report.companyId,
                            companyName: report.companyName,
                            year: report.year
                        });
                    }
                })
            }
        }

        let xAxisData = [];
        // reportFormatted.forEach(item => {
        //     let xAxisCoord = '';
        //     if (search.intervalDate.view === 'day') { xAxisCoord = [item.year, item.month, item.day].join('-') }
        //     if (search.intervalDate.view === 'week') { xAxisCoord = item.date }
        //     // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
        //     if (search.intervalDate.view === 'month') { xAxisCoord = [item.year, item.month].join('-') }
        //     if (search.intervalDate.view === 'year') { xAxisCoord = item.year }
        //     xAxisData.push(xAxisCoord);
        // })
        const indexMoment = moment()(search.intervalDate.startDate);
        const endMoment = moment()(search.intervalDate.endDate);
        while (indexMoment.isSameOrBefore(endMoment)) {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'days');
            }
            if (search.intervalDate.view === 'week') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'weeks');
            }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') {
                xAxisCoord = indexMoment.format('YYYY-MM');
                indexMoment.add(1, 'months');
            }
            if (search.intervalDate.view === 'year') {
                xAxisCoord = indexMoment.format('YYYY');
                indexMoment.add(1, 'years');
            }
            xAxisData.push(xAxisCoord);
        }
        xAxisData = _.uniq(xAxisData);

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);

        reportFormatted.forEach(item => {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
            if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
            if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }
            const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
            totalArray[axisIndex] += item.cost;
        })
        // 各分厂数据
        series.forEach(serie => {
            serie.data = [...Array(xAxisData.length)].map(_ => 0);
            reportFormatted.forEach(item => {
                let xAxisCoord = '';
                if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
                if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
                // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
                if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
                if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }

                const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
                if (item.companyId === serie.seriesId || serie.seriesId === 0) {
                    if (axisIndex > -1) {
                        serie.data[axisIndex] += item.cost;
                    }
                }
            })
        })
        // 总数据作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        return totalArray[param.dataIndex];
                        // console.log(param);
                    },
                }
            }
        })

        // console.log('============= deviceReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(deviceOperationReport)
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)
        // console.log(xAxisData)

        return {
            title: { text: '按发生时间统计', left: 'center', textStyle: { fontWeight: 'bold', fontSize: 14 } },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '金额(元)',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 运维计划完成率
    handleOperationPlanCompOption = () => {

        // 图配置逻辑
        const {
            deviceOperationReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 当前单位统计
        series.push({
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '50'
        })

        const reportFormatted = [];
        if (deviceOperationReport && deviceOperationReport.length) {
            deviceOperationReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    status: report.status,
                    companyId: report.companyId,
                    companyName: report.companyName
                })
                if (index > -1) {
                    reportFormatted[index].planCount += report.planCount;
                } else {
                    reportFormatted.push({
                        planCount: report.planCount,
                        status: report.status,
                        companyId: report.companyId,
                        companyName: report.companyName
                    });
                }
            })
        }

        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                totalArray[axisIndex] += item.planCount;
            }
        })
        // 已完成数
        series[0].data = [...Array(xAxisData.length)].map(_ => 0);
        const doneList = [...Array(xAxisData.length)].map(_ => 0);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1 && ['active'].includes(item.status)) {
                doneList[axisIndex] += item.planCount;
            }
        })
        doneList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = (item / total).toFixed(2)
            }
        })
        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalArray[param.dataIndex];
                        if (total <= 0) {
                            return '无运维计划'
                        } else {
                            return (param.data * 100).toFixed(1) + '%';
                        }
                    },
                }
            }
        })

        return {
            title: { text: '运维计划完成率', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '百分比',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 设备完好率
    handleDeviceServiceAbilityOption = () => {
        const {
            deviceServiceAbilityReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        // 图配置逻辑
        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 当前单位统计
        series.push({
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '50'
        })

        const reportFormatted = [];
        if (deviceServiceAbilityReport && deviceServiceAbilityReport.length) {
            deviceServiceAbilityReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    companyId: report.companyId,
                    companyName: report.companyName
                })
                if (index > -1) {
                    reportFormatted[index].deviceCount += report.deviceCount;
                    reportFormatted[index].exceptionCount += report.exceptionCount;
                    reportFormatted[index].exceptionDeviceCount += report.exceptionDeviceCount;
                    reportFormatted[index].serviceAbilityCount += (report.deviceCount - report.exceptionDeviceCount);
                } else {
                    reportFormatted.push({
                        deviceCount: report.deviceCount,
                        exceptionCount: report.exceptionCount,
                        exceptionDeviceCount: report.exceptionDeviceCount,
                        serviceAbilityCount: report.deviceCount - report.exceptionDeviceCount,
                        companyId: report.companyId,
                        companyName: report.companyName
                    });
                }
            })
        }

        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                totalArray[axisIndex] += item.deviceCount;
            }
        })
        // 完好(不含异常)数
        series[0].data = [...Array(xAxisData.length)].map(_ => 0);
        const serviceAbilityList = [...Array(xAxisData.length)].map(_ => 0);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                serviceAbilityList[axisIndex] += item.serviceAbilityCount;
            }
        })
        serviceAbilityList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = (item / total).toFixed(2)
            }
        })
        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalArray[param.dataIndex];
                        if (total <= 0) {
                            return '无设备'
                        } else {
                            return (param.data * 100).toFixed(1) + '%';
                        }
                    },
                }
            }
        })

        return {
            title: { text: '设备完好率', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '百分比',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 设备新度系数
    handleDeviceNewnessCoefficientOption = () => {
        const {
            deviceNewnessCoefficientReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        // 图配置逻辑
        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 当前单位统计
        series.push({
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '50'
        })

        const reportFormatted = [];
        if (deviceNewnessCoefficientReport && deviceNewnessCoefficientReport.length) {
            deviceNewnessCoefficientReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    companyId: report.companyId,
                    companyName: report.companyName
                })
                if (index > -1) {
                    reportFormatted[index].faNetSum += report.faNetSum;
                    reportFormatted[index].faValSum += report.faValSum;
                } else {
                    reportFormatted.push({
                        faNetSum: report.faNetSum,
                        faValSum: report.faValSum,
                        companyId: report.companyId,
                        companyName: report.companyName
                    });
                }
            })
        }

        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }

        // 原值
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                totalArray[axisIndex] += item.faValSum;
            }
        })
        // 净值
        series[0].data = [...Array(xAxisData.length)].map(_ => 0);
        const netList = [...Array(xAxisData.length)].map(_ => 0);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                netList[axisIndex] += item.faNetSum;
            }
        })
        netList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = (item / total).toFixed(2)
            }
        })
        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalArray[param.dataIndex];
                        if (total <= 0) {
                            return '无资产信息'
                        } else {
                            return param.data;
                        }
                    },
                }
            }
        })

        return {
            title: { text: '设备新度系数', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '系数',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    //  设备有效利用率
    handleDeviceUtilityOption = () => {
        const {
            deviceUtilizationReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        // 图配置逻辑
        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 当前单位统计
        series.push({
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '50'
        })

        const reportFormatted = [];
        if (deviceUtilizationReport && deviceUtilizationReport.length) {
            deviceUtilizationReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    companyId: report.companyId,
                    companyName: report.companyName
                })
                if (index > -1) {
                    reportFormatted[index].totalInterval += report.totalInterval;
                    reportFormatted[index].useInterval += report.useInterval;
                } else {
                    reportFormatted.push({
                        totalInterval: report.totalInterval,
                        useInterval: report.useInterval,
                        companyId: report.companyId,
                        companyName: report.companyName
                    });
                }
            })
        }

        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }

        // 总时长
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                totalArray[axisIndex] += item.totalInterval;
            }
        })
        // 启用时长
        series[0].data = [...Array(xAxisData.length)].map(_ => 0);
        const netList = [...Array(xAxisData.length)].map(_ => 0);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.companyName);
            if (axisIndex > -1) {
                netList[axisIndex] += item.useInterval;
            }
        })
        netList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = item / total;
            }
        })
        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalArray[param.dataIndex];
                        if (total <= 0) {
                            return '无生产信息'
                        } else {
                            return (param.data * 100).toFixed(3) + '%';
                        }
                    },
                }
            }
        })

        // console.log('============= deviceOperationReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(reportFormatted)
        // console.log(series)

        return {
            title: { text: '设备有效利用率', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '百分比',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
                break;
            default:
            // do nothing
        }
    };

    render() {
        return (
            <DeviceReport
                routeType={this.state.routeType}
                deviceOperationReport={this.props.deviceOperationReport}
                // deviceServiceAbilityReport={this.props.deviceServiceAbilityReport}
                // deviceNewnessCoefficientReport={this.props.deviceNewnessCoefficientReport}
                userProfile={this.props.userProfile}
                currentUserInfo={this.props.currentUserInfo}
                companies={this.props.companies}
                departments={this.props.departments}
                departmentList={this.props.departmentList}
                dictionary={this.props.inspectionDict}

                // rootCauseList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.ROOTCAUSE)}
                severityList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.SEVERITY)}
                // impactLevelList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.IMPACTLEVEL)}
                // factorList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.FACTORTYPE)}

                search={this.props.search}
                // exportCsv={this.onhandleExportCsv.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                // onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandleDeviceCatOptPlanOption={this.handleDeviceCatOptPlanOption}
                onhandleDeviceTimeOptPlanOption={this.handleDeviceTimeOptPlanOption}
                onhandleDeviceCatOptCostOption={this.handleDeviceCatOptCostOption}
                onhandleDeviceTimeOptCostOption={this.handleDeviceTimeOptCostOption}
                onhandleOperationPlanCompOption={this.handleOperationPlanCompOption.bind(this)}
                onhandleDeviceServiceAbilityOption={this.handleDeviceServiceAbilityOption.bind(this)}
                onhandleDeviceNewnessCoefficientOption={this.handleDeviceNewnessCoefficientOption.bind(this)}
                onhandleDeviceUtilityOption={this.handleDeviceUtilityOption.bind(this)}

                // onhandleRatioOption={this.handleRatioOption.bind(this)}
                // onhandlePlanCompOption={this.handlePlanCompOption.bind(this)}

                creatorName={this.state.creatorName}

                tabValue={this.state.tabValue}
                onhandleChangeTab={this.handleChangeTab.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companies: getActualCompany(state.constant.companies),
        departments: state.constant.departments,
        deviceCategoryList: state.deviceReport.deviceCategoryList,
        inspectionDict: state.constant.inspectionDictionaries,
        departmentList: getManageDpt(state.constant.departments),
        deviceOperationReport: state.deviceReport.deviceOperationReport,
        deviceServiceAbilityReport: state.deviceReport.deviceServiceAbilityReport,
        deviceNewnessCoefficientReport: state.deviceReport.deviceNewnessCoefficientReport,
        deviceUtilizationReport: state.deviceReport.deviceUtilizationReport,
        search: state.deviceReport.search,
        selectedUsers: state.userSelect.selectedUsers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetDeviceOperationReport: (query) => {
            dispatch(getDeviceOperationReport(query))
        },
        onhandleInit: () => {
            dispatch(initDeviceReport())
        },
        // onhandleGetDeviceOperationReportForCsv: (query, callback) => {
        //     dispatch(getDeviceListForCsv(query, callback))
        // },
        exportCsv: (fileName, columns, data) => {
            dispatch(csv(fileName, columns, data))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        },
        onhandleClearSarch: () => {
            dispatch(resetSearch());
        },
        onhandleUpdateEditSearch: (search, tabValue) => {
            dispatch(updSearchData(search, tabValue));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhandeModalclose: () => {
            dispatch(cleanmodal());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceReportContainer)