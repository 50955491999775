import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    // Card,
    Typography,
    // Button,
    Grid,
    Checkbox
} from "@material-ui/core";
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
// import SaveIcon from '@material-ui/icons/Save';

const RuleCatSetupComponent = props => {

    const {
        classes,
        // onhandleBack,
        // onhandleCheckChange,
        // currentRule,
        readonly,
        // ruleTargetChanged,
        catList,
        routeType,
        onhandleSelectionChange,
    } = props;

    const tableStyle = {
        ...style
    }

    const [selectedRow, setSelectedRow] = useState(null);

    // const renderLabel = (routeType) => {
    //     let mapping = { 'DEVTYPE': '设备类别列表', 'EXTTYPE': '设备扩展类别列表', 'ACCTYPE': '部件类别列表' };
    //     return mapping[routeType]
    // }

    const renderRowLabel = (routeType) => {
        let mapping = { 'DEVTYPE': '选择设备类别', 'EXTTYPE': '选择设备扩展类别', 'ACCTYPE': '选择部件类别' };
        return mapping[routeType]
    }

    return (
        <div>
            {/* <Card className={classes.card}>
                <Typography variant="h3" gutterBottom>
                    设置运维规则对象
                </Typography>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            运维规则名称
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography gutterBottom>
                            {currentRule.ruleName}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="primary"
                            onClick={event => onhandleBack(event)}
                        >
                            返回
                        </Button>
                    </Grid>
                </Grid>
            </Card> */}
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <MaterialTable
                        // title={renderLabel(routeType)}
                        columns={[
                            {
                                title: '',
                                field: 'route',
                                width: 50,
                                render: row => row ? <Checkbox
                                    className={classes.checkbox}
                                    disabled={readonly}
                                    size='small'
                                    indeterminate={(!row.action && row.indeterminate) ? true : false}
                                    checked={row.action || row.indeterminate ? true : false}
                                    onChange={(event) => onhandleSelectionChange(event, row)}
                                /> : ''
                            },
                            {
                                title: renderRowLabel(routeType),
                                field: 'name',
                                render: row => row ? <Typography>{row.name}</Typography> : '',
                                editable: 'never'
                            },
                        ]}
                        data={catList}
                        options={{
                            ...options,
                            showTitle: false,
                            toolbar: false,
                            search: false,
                            paging: false,
                            // rowStyle: rowData => ({
                            //     backgroundColor: !rowData.tableData.childRows ? '#E0F8F7' : 'FFFFFF'
                            // })
                            // filtering: true,
                            defaultExpanded: false,
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.processId ? '#EEEEEE' : 'FFFFFF'
                            })
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        parentChildData={(row, rows) => rows.find(a => a.code === row.pcode)}
                        style={tableStyle}
                        localization={localization}
                    // actions={!readonly ? [
                    //     {
                    //         tooltip: '将所选项保存',
                    //         icon: () => (<SaveIcon color='primary' />),
                    //         // disabled: Boolean(!ruleTargetChanged),
                    //         hidden: Boolean(!ruleTargetChanged),
                    //         isFreeAction: true,
                    //         onClick: () => {
                    //             onhandleCheckChange();
                    //         }
                    //     }
                    // ] : []}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    );
};

RuleCatSetupComponent.propTypes = {
    onhandleBack: PropTypes.func.isRequired,
    // onhandleCheckChange: PropTypes.func.isRequired,
    currentRule: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired,
    catList: PropTypes.array.isRequired,
    ruleTargetChanged: PropTypes.bool.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
};

export default withStyles(RuleCatSetupComponent);
