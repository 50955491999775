import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import zhCN from 'date-fns/locale/zh-CN';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as _ from 'lodash';

const AddDetailPannel = ({
    classes,
    open,
    regulations,
    planDetail,
    planDetailError,
    constant,
    businessUnits,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleDateChange
}) => {

    const isAddAction = function () {
        return !planDetail.planType || planDetail.planType === 'Add'
    }
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onhandleClose}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">增加规章制度完善计划明细</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                label='修订类型'
                                select
                                value={planDetail.planType ? planDetail.planType : 'Add'}
                                name='planType'
                                onChange={onhandleChange}>
                                <MenuItem key={1} value={'Add'}>新增</MenuItem>
                                <MenuItem key={2} value={'Update'}>修订</MenuItem>
                                <MenuItem key={3} value={'Delete'}>废止</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            {isAddAction() ?
                                <TextField className={classes.textField}
                                    error={planDetailError.regName && planDetailError.regName !== ''}
                                    helperText={planDetailError.regName && planDetailError.regName !== '' ? planDetailError.regName : ''}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="公司规章制度"
                                    value={planDetail.regName ? planDetail.regName : ''}
                                    name='regName'
                                    onChange={onhandleChange}
                                >
                                </TextField>
                                :
                                <TextField className={classes.textField}
                                    error={planDetailError.regId && planDetailError.regId !== ''}
                                    helperText={planDetailError.regId && planDetailError.regId !== '' ? planDetailError.regId : ''}
                                    variant="outlined"
                                    placeholder="请输入"
                                    select
                                    label="公司规章制度"
                                    value={planDetail.regId ? planDetail.regId : 0}
                                    name='regId'
                                    onChange={onhandleChange}
                                >
                                    {_.filter(regulations, (reg) => {
                                        return _.find(businessUnits, (bu) => {
                                            if (reg.status === 'active') {
                                                return bu.unitId === reg.unitId
                                            }
                                        })
                                    }).map((regulation) => (
                                        <MenuItem key={regulation.regId} value={regulation.regId}>
                                            {regulation.regName}
                                        </MenuItem>
                                    ))}
                                </TextField>}
                        </Grid>
                        {isAddAction() ?
                            <Grid item className={classes.itemGrid}>
                                <TextField className={classes.textField}
                                    error={planDetailError.revisionCycle && planDetailError.revisionCycle !== ''}
                                    helperText={planDetailError.revisionCycle && planDetailError.revisionCycle !== '' ? planDetailError.revisionCycle : ''}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="修订周期"
                                    type="number"
                                    value={planDetail.revisionCycle ? planDetail.revisionCycle : null}
                                    name='revisionCycle'
                                    onChange={onhandleChange}
                                >
                                </TextField>
                            </Grid> : ''}
                        {isAddAction() ?
                            <Grid item className={classes.itemGrid}>
                                <TextField className={classes.textField}
                                    variant="outlined"
                                    label="修订周期单位"
                                    select
                                    value={planDetail.revisionUnit ? planDetail.revisionUnit : 'YEAR'}
                                    name='revisionUnit'
                                    onChange={onhandleChange}>
                                    <MenuItem key={1} value={'YEAR'}>年</MenuItem>
                                    <MenuItem key={2} value={'MONTH'}>月</MenuItem>
                                    <MenuItem key={3} value={'WEEK'}>周</MenuItem>
                                    <MenuItem key={4} value={'DAY'}>日</MenuItem>
                                </TextField>
                            </Grid> : ''}

                        <Grid item className={classes.itemGrid}>
                            <TextField className={classes.textField}
                                error={planDetailError.content && planDetailError.content !== ''}
                                helperText={planDetailError.content && planDetailError.content !== '' ? planDetailError.content : ''}
                                variant="outlined"
                                placeholder="请输入"
                                multiline
                                label="修改建议"
                                rows="3"
                                value={planDetail.content ? planDetail.content : ''}
                                name='content'
                                onChange={onhandleChange}

                            >
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN}>
                                <KeyboardDatePicker
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="normal"
                                    name='targetdateselect'
                                    label="计划完成时间"
                                    InputProps={{ readOnly: true }}
                                    value={planDetail.targetDate}
                                    onChange={onhandleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>
                        新增</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
AddDetailPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};

AddDetailPannel.defaultProps = {

}

export default withStyles(AddDetailPannel);