import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    title: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formGridContainer: {

    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    menuItemNormal: {
        color: theme.palette.primary.main
    },
    menuItemCritical: {
        color: theme.palette.error.main
    },
    menuIcon: {
        marginRight: 5
    },
    active: {
        width: 60,
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
    paused: {
        width: 60,
        color: theme.palette.white,
        backgroundColor: theme.palette.warning.main
    },
    checkbox: {
        padding: 0,
        marginRight: 5
    },
    section: {
        marginTop: 20
    },
    customGrid: {
        marginTop: 20,
        marginLeft: 20
    },
    timeContainer: {
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
        marginBottom: 10

    },
    warnTitle: {
        display: 'inline-block',
        margin: 10,
        marginTop: 20,
    },
    warnContainer: {
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
        marginBottom: 10,
        marginLeft: 10
    },
    intervalInput: {
        marginLeft: 5,
        marginRight: 5,
        width: 90,
    },
    timeInput: {
        marginLeft: 5,
        marginRight: 5,
        width: 90,
    },
    customTime: {
        marginTop: 5,
        marginLeft: 20,
        width: 90,
    },
    root: {
        padding: theme.spacing(0)
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    materialtable: {
        boxShadow: 'none'
    },
    cellButton: {
        marginLeft: -10,
    }
});

export default withStyles(styles);