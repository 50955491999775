import React, { Component } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import "./index.css";
// import * as _ from 'lodash';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export class MessageDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [
                {
                    deviceId: 860,
                    cat: 'D0501',
                    deviceName: '蒸发式冷凝器E-308',
                    paramName: '电流',
                    time: '2021/01/23 11:09',
                    status: '待处理',
                    type: 'warning',
                },
                {
                    deviceId: 872,
                    cat: 'D1001',
                    deviceName: '装车泵P-301A',
                    paramName: '开/停状态',
                    time: '2021/01/23 10:20',
                    status: '处理中',
                    type: 'error',
                },
                {
                    deviceId: 879,
                    cat: 'D0201',
                    deviceName: '二氧化碳精馏塔C-301',
                    paramName: '压力',
                    time: '2021/01/23 13:15',
                    status: '处理中',
                    type: 'error',
                },
                {
                    deviceId: 873,
                    cat: 'D1001',
                    deviceName: '装车泵P-301B',
                    paramName: '泄露',
                    time: '2021/01/23 14:21',
                    status: '待处理',
                    type: 'warning',
                }
            ]
        }
    }
    componentDidMount() {
        // this.getDetail();
    }

    render() {
        return (
            <div className='fillarea'>
                {this.state.datas.map((item, idx) => (
                    <Alert style={{ marginTop: '5px' }} key={idx} severity={item.type}>{`${item.deviceName} 异常：${item.paramName} 发生时间：${item.time} 当前状态：${item.status}`}</Alert>)
                )
                }
            </div>
        )
    }
}
