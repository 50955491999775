import React, { Component } from 'react';
import { connect } from 'react-redux';
import OperationPlanDetail from '../../../components/DeviceManagement/OperationPlan/OperationPlanDetail';
import {
    initWorkOrderInfoForCreate
} from "../../../redux/modules/operation";
import {
    routeToOperationPlanList,
    setCurrentPlanDetailChangeFlg,
    setCurrentPlanDetail,
    updateOperationPlanDetail,
    getPlanDetailById
} from "../../../redux/modules/operationPlan";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { getMoment } from '../../../utils/datetime';
import { routerToCreateWorkOrder } from "../../../redux/modules/operationPlan";

class OperationPlanDetailContainer extends Component {

    static propTypes = {
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
        optCategories: PropTypes.array,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.onhandleInitWorkOrderInfo(id);
    }

    // 页面项目onChange事件处理
    handleChange = (event, field) => {

        this.props.onhandleCurrentPlanDetailChange(true);
        let newCurrentPlanDetail;
        switch (field) {
            // 运维类别
            case 'optType':
                newCurrentPlanDetail = {
                    ...this.props.currentPlanDetail,
                    optType: event.target.value
                };
                this.props.onhandleUpdateCurrentPlanDetail(newCurrentPlanDetail);
                break;

            // 负责角色
            case 'roles':
                let newRoleList = [];
                event.forEach(selected => {
                    newRoleList.push({ roleId: selected.value, roleName: selected.label, value: selected.value, label: selected.label })
                });
                newCurrentPlanDetail = {
                    ...this.props.currentPlanDetail,
                    roles: newRoleList
                };
                this.props.onhandleUpdateCurrentPlanDetail(newCurrentPlanDetail);
                break;

            // 执行时间
            case 'targetDate':
                newCurrentPlanDetail = {
                    ...this.props.currentPlanDetail,
                    targetDate: new Date(getMoment()(event).format("YYYY-MM-DD"))
                };
                this.props.onhandleUpdateCurrentPlanDetail(newCurrentPlanDetail);
                break;

            // 预算（元）
            case 'budget':
                newCurrentPlanDetail = {
                    ...this.props.currentPlanDetail,
                    budget: event.target.value
                };
                this.props.onhandleUpdateCurrentPlanDetail(newCurrentPlanDetail);
                break;

            // 描述
            case 'opentext':
                newCurrentPlanDetail = {
                    ...this.props.currentPlanDetail,
                    opentext: _.trim(event.target.value)
                };
                this.props.onhandleUpdateCurrentPlanDetail(newCurrentPlanDetail);
                break;
            default:
            // do nothing
        }
    };

    // “保存计划” 按钮处理
    handleSaveBtn = (event) => {
        const { onhandleUpdate, onhanleGetPlanDetail, onhandleCurrentPlanDetailChange } = this.props;
        // final execute function from other component or redux-action
        onhandleUpdate(
            this.props.currentPlanDetail,
            this.props.currentPlanDetail.dtlId,
            () => {
                onhanleGetPlanDetail(this.props.currentPlanDetail.dtlId);
                // 设置 - 当前运维计划变更状态 (False)
                onhandleCurrentPlanDetailChange(false);
            })


    }

    // “删除计划” 按钮处理
    handleDeleteBtn() {

        this.props.currentPlanDetail.status = 'inactive'
        // final execute function from other component or redux-action
        this.handleSaveBtn(this.props.currentPlanDetail);

    }

    // 工单下达 处理
    handleCreateWorkOrder(operationPlanDetail) {
        this.props.onhandleRouterToCreateWorkOrder(operationPlanDetail.dtlId);
    }

    render() {
        return (
            <OperationPlanDetail
                currentPlanDetail={this.props.currentPlanDetail}
                orderTypeList={this.props.orderTypeList}
                departmentList={this.props.departmentList}
                companyList={this.props.companyList}
                onhandleBack={this.props.routeToOperationPlanList.bind(this)}
                optCategories={this.props.optCategories}
                catList={this.props.catList}
                accCatList={this.props.accCatList}
                // 页面项目onChange处理
                onhandleChange={this.handleChange.bind(this)}
                // 当前运维计划是否被更改？
                currentPlanDetailChangeFlg={this.props.currentPlanDetailChangeFlg}
                // “保存计划” 按钮处理
                onhandleSaveBtn={this.handleSaveBtn.bind(this)}
                // “删除计划” 按钮处理
                onhandleDeleteBtn={this.handleDeleteBtn.bind(this)}
                // “下达工单” 按钮处理
                onhandleCreateWorkOrder={this.handleCreateWorkOrder.bind(this)}
                // 角色树形结构
                roleTree={this.props.roleTree}
                // 用户档案信息
                userProfile={this.props.userProfile}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        orderTypeList: state.operation.orderTypeList,
        departmentList: state.constant.departments,
        companyList: state.constant.companies,
        currentPlanDetail: state.operationPlan.currentPlanDetail,
        optCategories: state.operationRule.optCategories,
        catList: state.trees.deviceCategoryList,
        newWorkOrderChange: state.operation.newWorkOrderChange,
        accCatList: state.accessory.catList,
        // 当前运维计划是否被更改？
        currentPlanDetailChangeFlg: state.operationPlan.currentPlanDetailChangeFlg,
        // 角色树形结构
        roleTree: state.role.roleTree,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitWorkOrderInfo: (data) => {
            dispatch(initWorkOrderInfoForCreate(data))
            // 初始化Flag: 当前运维计划详细是否被改变 （False）
            dispatch(setCurrentPlanDetailChangeFlg(false))
        },
        routeToOperationPlanList: () => {
            dispatch(routeToOperationPlanList())
        },
        // 设置Flag: 当前运维计划详细是否被改变（True、False）
        onhandleCurrentPlanDetailChange: (value) => {
            dispatch(setCurrentPlanDetailChangeFlg(value))
        },
        // 保存（更新）状态 - 当前运维计划详细信息
        onhandleUpdateCurrentPlanDetail: (planDetail) => {
            dispatch(setCurrentPlanDetail(planDetail))
        },
        // 保存（更新）DB - 当前运维计划详细信息
        onhandleUpdate: (operationPlanDetal, dtlId, callback) => {
            dispatch(updateOperationPlanDetail(operationPlanDetal, dtlId, callback));
        },
        // 通过ID获取运维计划详细信息
        onhanleGetPlanDetail: (id) => {
            dispatch(getPlanDetailById(id));
        },
        // "下达工单" 按钮处理
        onhandleRouterToCreateWorkOrder: (value) => {
            dispatch(routerToCreateWorkOrder("fromdetailpage", value));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanDetailContainer)
