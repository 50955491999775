import * as _ from 'lodash';
import * as device from './core';

export default class MapEditActionMapping {

    layerMapping(props, layer, stage, previewStage) {
        let groupArray = layer.find('Group'); //在layer中找出所有的Group
        if (groupArray && groupArray.length > 0) {
            groupArray.forEach(element => {
                if (element.attrs && element.attrs.id) {
                    let ids = _.split(element.attrs.id, '_');
                    if (ids && ids.length > 1) {
                        switch (ids[0]) {
                            case "Unit":
                                device.mappingUnit(props, element, layer, stage, previewStage);
                                break;
                            case "Area":
                                device.mappingArea(props, element, layer, stage, previewStage);
                                break;
                            case "DeviceGroup":
                                device.mappingDeviceGroup(props, element, layer, stage, previewStage, 'edit');
                                break;
                            case "Guandao":
                                device.mappingGuandao(props, element, layer, stage, previewStage);
                                break;
                            case "Xian":
                                device.mappingXian(props, element, layer, stage, previewStage);
                                break;
                            case "Jiandingguan":
                                device.mappingJiandingguan(props, element, layer, stage, previewStage);
                                break;
                            case "Lishiguan":
                                device.mappingLishiguan(props, element, layer, stage, previewStage);
                                break;
                            case "Woshiguan":
                                device.mappingWoshiguan(props, element, layer, stage, previewStage);
                                break;
                            case "Qiuxingguan":
                                device.mappingQiuxingguan(props, element, layer, stage, previewStage);
                                break;
                            case "Qiping":
                                device.mappingQiping(props, element, layer, stage, previewStage);
                                break;
                            case "Qicang":
                                device.mappingQicang(props, element, layer, stage, previewStage);
                                break;
                            case "Tuoshuicang":
                                device.mappingTuoshuicang(props, element, layer, stage, previewStage);
                                break;
                            case "Jiezhifa":
                                device.mappingJiezhifa(props, element, layer, stage, previewStage);
                                break;
                            case "Zhafa":
                                device.mappingZhafa(props, element, layer, stage, previewStage);
                                break;
                            case "Qiufa":
                                device.mappingQiufa(props, element, layer, stage, previewStage);
                                break;
                            case "Diefa":
                                device.mappingDiefa(props, element, layer, stage, previewStage);
                                break;
                            case "Zhihuifa":
                                device.mappingZhihuifa(props, element, layer, stage, previewStage);
                                break;
                            case "Jianyafa":
                                device.mappingJianyafa(props, element, layer, stage, previewStage);
                                break;
                            case "Zhusaifa":
                                device.mappingZhusaifa(props, element, layer, stage, previewStage);
                                break;
                            case "Gemofa":
                                device.mappingGemofa(props, element, layer, stage, previewStage);
                                break;
                            case "Xuansaifa":
                                device.mappingXuansaifa(props, element, layer, stage, previewStage);
                                break;
                            case "Anquanfa":
                                device.mappingAnquanfa(props, element, layer, stage, previewStage);
                                break;
                            case "Lixinbeng":
                                device.mappingLixinbeng(props, element, layer, stage, previewStage);
                                break;
                            case "Jingliuta":
                                device.mappingJingliuta(props, element, layer, stage, previewStage);
                                break;
                            case "Lu":
                                device.mappingLu(props, element, layer, stage, previewStage);
                                break;
                            case "Fanyingqi":
                                device.mappingFanyingqi(props, element, layer, stage, previewStage);
                                break;
                            case "Huanreqi":
                                device.mappingHuanreqi(props, element, layer, stage, previewStage);
                                break;
                            case "Yibiao":
                                device.mappingYibiao(props, element, layer, stage, previewStage);
                                break;
                            case "Other":
                                device.mappingOther(props, element, layer, stage, previewStage);
                                break;
                            default:
                            // do nothing
                        }
                    }
                }
            });
        }
    }

}