import React, { Component } from 'react';
import { connect } from 'react-redux';
import MActivityRecord from '@/components/ManufactureManagement/ManufactureActivity/MActivityRecord/MActivityRecordList/MActivityRecordComponent';
import CreateMActivityRecord from '@/components/ManufactureManagement/ManufactureActivity/MActivityRecord/CreateMActivityRecord/CreateMActivityRecordComponent';
import PropTypes from 'prop-types';
import { formatDate, formatDate_hms, getMoment } from '@/utils/datetime';
import { getInstallationsInManipulate } from '@/redux/modules/manipulate';
import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { getRole } from '@/utils/userUtils';
import { getActivityRecords, getActivityRecord, addManufactureActivityRecord, delManufactureActivityRecord, updateManufactureActivityRecord, getServiceActivityAttrs } from '@/redux/modules/manufacture/manufactureActivityRecord';
import * as _ from 'lodash'

class MActivityRecordContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            companies: [],
            receiveDpts: [],
            payDpts: [],
            participateDpts: [],
            userTasks: [],
            isediting: true,
            mactivityRecord: {
                taskId: undefined,
                serviceId: undefined,
                manufactureService: {
                    serviceName: ''
                },
                orderId: undefined,
                manufactureOrder: {
                    orderNo: ''
                },
                activityId: undefined,
                installId: undefined,
                receiveCompany: undefined,
                receiveDpt: undefined,
                payCompany: undefined,
                payDpt: undefined,
                participateCompany: undefined,
                participateDpt: undefined,
                triggerTime: formatDate_hms(getMoment()()),
                amount: 0.0,
                recordAttrs: [],
            },
            dptvariable: {
                payaction: "payDpts",
                receiveaction: "receiveDpts",
                participateaction: "participateDpts",
            },
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const date = getMoment()();
        const triggerTime = formatDate(date);
        this.props.getActivityRecords(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.getCompany(), triggerTime, undefined, undefined);
        // this.props.getUserManufactureTasks();
        this.props.getInstallationsInManipulate(undefined);
        this.handleMakeCompanies();
        // 取得指标列表
        this.props.getBusinessGroupList();
    }

    handleDateChange = (date) => {

        this.setState({
            selectedDate: date
        })
        const triggerTime = formatDate(date);
        this.props.getActivityRecords(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.getCompany(), triggerTime, undefined, undefined);
    }

    handleTriggerDateChange = (date) => {
        const mactivityRecord = this.state.mactivityRecord;
        this.setState({
            mactivityRecord: { ...mactivityRecord, triggerTime: formatDate_hms(date) }
        });
    }

    handleUpdate = (event, rowData) => {
        this.getActivityRecord(rowData.recordId);
        this.setState({ isShow: false, componentTitle: "编辑生产活动记录" });
    }

    getActivityRecord = (recordId) => {
        this.props.getActivityRecord(recordId).then((res) => {
            if (res && res.payload && res.payload.data) {
                if (res.payload.data.payCompany) {
                    this.handleMakeDepartMent(res.payload.data.payCompany, this.state.dptvariable.payaction);
                }
                if (res.payload.data.receiveCompany) {
                    this.handleMakeDepartMent(res.payload.data.receiveCompany, this.state.dptvariable.receiveaction);
                }
                if (res.payload.data.participateCompany) {
                    this.handleMakeDepartMent(res.payload.data.participateCompany, this.state.dptvariable.participateaction);
                }
                this.setState({ mactivityRecord: res.payload.data, userTasks: [{ taskId: res.payload.data.taskId, activityName: res.payload.data.manufactureActivity ? res.payload.data.manufactureActivity.activityName : "" }] });
            }
            this.setState({ createOpen: true, isediting: false, isActivityEditing: true });
        });
    }

    handleDelete = (rowdata) => {
        return this.props.delManufactureActivityRecord(rowdata.recordId).then((res) => {
            if (res) {
                const triggerTime = formatDate(this.state.selectedDate);
                this.props.getActivityRecords(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.getCompany(), triggerTime, undefined, undefined);
            }
        });
    }

    handleAdd = () => {
        this.setState({ createOpen: true, isActivityEditing: false, componentTitle: "新增生产活动记录" });
        this.handleMakeUserTasks();
    }

    handleCloseCreate = () => {
        this.setState({ createOpen: false });
        this.handleClearmMactivityRecord();
    }

    getCompany = () => {
        const { companies } = this.props;
        const company = _.find(companies, item => {
            return item.pid && this.props.userInfo.companyId === item.companyId
        });
        return company ? company.companyId : undefined
    }

    handleSave = () => {
        const mactivityRecord = this.state.mactivityRecord;
        if (mactivityRecord) {
            const recordAttrs = mactivityRecord.recordAttrs;
            const newrecordAttrs = [];
            if (recordAttrs) {
                recordAttrs.forEach(element => {
                    const arrt = { configId: element.configId, attrValue: element.attrValue };
                    newrecordAttrs.push(arrt);
                });
            }

            const newRecord = {
                taskId: mactivityRecord.taskId,
                serviceId: mactivityRecord.serviceId,
                orderId: mactivityRecord.orderId,
                activityId: mactivityRecord.activityId,
                amount: mactivityRecord.amount && mactivityRecord.amount !== '' ? mactivityRecord.amount * 1.0 : 0.0,
                recordAttrs: newrecordAttrs
            };
            if (mactivityRecord.triggerTime) {
                newRecord.triggerTimeStr = formatDate_hms(mactivityRecord.triggerTime);
            }
            if (mactivityRecord.receiveCompany) {
                newRecord.receiveCompany = mactivityRecord.receiveCompany;
            }
            if (mactivityRecord.receiveDpt) {
                newRecord.receiveDpt = mactivityRecord.receiveDpt;
            }
            if (mactivityRecord.payCompany) {
                newRecord.payCompany = mactivityRecord.payCompany;
            }
            if (mactivityRecord.payDpt) {
                newRecord.payDpt = mactivityRecord.payDpt;
            }
            if (mactivityRecord.participateCompany) {
                newRecord.participateCompany = mactivityRecord.participateCompany;
            }
            if (mactivityRecord.participateDpt) {
                newRecord.participateDpt = mactivityRecord.participateDpt;
            }
            if (mactivityRecord.installId) {
                newRecord.installId = mactivityRecord.installId;
            }

            if (mactivityRecord.recordId) {
                newRecord.recordId = mactivityRecord.recordId;
                this.props.updateManufactureActivityRecord(mactivityRecord.recordId, newRecord).then((res) => {
                    if (res.payload === 200) {
                        const triggerTime = formatDate(this.state.selectedDate);
                        this.handleCloseCreate();
                        this.props.getActivityRecords(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.getCompany(), triggerTime, undefined, undefined);
                    }
                });
            } else {
                this.props.addManufactureActivityRecord(newRecord).then((res) => {
                    if (res.payload === 200) {
                        const triggerTime = formatDate(this.state.selectedDate);
                        this.handleCloseCreate();
                        this.props.getActivityRecords(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.getCompany(), triggerTime, undefined, undefined);
                    }
                });
            }
        }
    }

    handleClearmMactivityRecord = () => {
        const mactivityRecord = {
            taskId: undefined,
            serviceId: undefined,
            manufactureService: {
                serviceName: ''
            },
            orderId: undefined,
            manufactureOrder: {
                orderNo: ''
            },
            activityId: undefined,
            installId: undefined,
            receiveCompany: '',
            receiveDpt: undefined,
            payCompany: undefined,
            payDpt: undefined,
            participateCompany: '',
            participateDpt: undefined,
            triggerTime: formatDate_hms(getMoment()()),
            amount: 0.0,
            recordAttrs: [],
        }
        this.setState({ isediting: true, participateDisable: true, recevieDisable: true, payDisable: true, mactivityRecord: mactivityRecord });
    }

    handleTextFieldChange = (event) => {
        const { userInfo } = this.props;
        const mactivityRecord = this.state.mactivityRecord;
        if (event.target.name === "taskId") {
            mactivityRecord.taskId = event.target.value;
            const task = _.find(this.state.userTasks, item => {
                return item.taskId === event.target.value
            });
            mactivityRecord.activityId = task.activityId;
            mactivityRecord.serviceId = task.serviceId;
            mactivityRecord.orderId = task.orderId;
            mactivityRecord.receiveDpt = task.dptId;
            mactivityRecord.receiveCompany = userInfo.companyId;
            mactivityRecord.manufactureOrder.orderNo = task.orderNo;
            this.props.getServiceActivityAttrs(task.serviceId).then((res) => {
                if (res && res.payload && res.payload.payload.data) {
                    mactivityRecord.manufactureService = res.payload.payload.data
                    const activitys = res.payload.payload.data.activities;
                    mactivityRecord.manufactureService.serviceName = res.payload.payload.data.serviceName;
                    const activity = _.find(activitys, item => {
                        return item.activityId === task.activityId
                    });

                    if (activity) {
                        const attrs = activity.manufactureAttrs;
                        const recordAttrs = [];
                        if (attrs) {
                            attrs.forEach(element => {
                                const rAttr = { configId: element.serviceConfigId, attrValue: element.attrDefault ? element.attrDefault : '', attrName: element.attrName, attrPermission: element.attrPermission };
                                recordAttrs.push(rAttr);
                            });
                            mactivityRecord.recordAttrs = recordAttrs;
                        }
                    }
                    this.setState({ mactivityRecord: mactivityRecord });
                }
            });
        }
        else if (event.target.name === "installId") {
            mactivityRecord.installId = event.target.value;
        }
        else if (event.target.name === "payCompany") {
            mactivityRecord.payCompany = event.target.value;
            this.handleMakeDepartMent(event.target.value, this.state.dptvariable.payaction);
        }
        else if (event.target.name === "payDpt") {
            mactivityRecord.payDpt = event.target.value;
        }
        // else if (event.target.name === "receiveCompany") {
        //     mactivityRecord.receiveCompany = event.target.value;
        //     this.handleMakeDepartMent(event.target.value, this.state.dptvariable.receiveaction);
        // }
        // else if (event.target.name === "receiveDpt") {
        //     mactivityRecord.receiveDpt = event.target.value;
        // }
        else if (event.target.name === "participateCompany") {
            mactivityRecord.participateCompany = event.target.value;
            this.handleMakeDepartMent(event.target.value, this.state.dptvariable.participateaction);
        }
        else if (event.target.name === "participateDpt") {
            mactivityRecord.participateDpt = event.target.value;
        }
        else if (event.target.name === "amount") {
            mactivityRecord.amount = event.target.value;
        }
        this.setState({ mactivityRecord: mactivityRecord, isediting: false });
    }

    handleMakeCompanies = () => {
        const { companies } = this.props;
        const newcompanies = _.filter(companies, item => {
            return item.pid
        });
        this.setState({ companies: newcompanies });
    }

    handleMakeDepartMent = (companyId, eventName) => {
        const { departments } = this.props;
        const newdepartments = _.filter(departments, item => {
            return item.companyId === companyId;
        });

        if (this.state.dptvariable && eventName === this.state.dptvariable.payaction) {
            this.setState({ payDisable: false });
        } else if (this.state.dptvariable && eventName === this.state.dptvariable.receiveaction) {
            this.setState({ recevieDisable: false });
        } else {
            this.setState({ participateDisable: false });
        }
        this.setState({ [eventName]: newdepartments });
    }

    handleOpen = (rowData) => {
        this.getActivityRecord(rowData.recordId);
        this.setState({ isShow: true, componentTitle: "查看" + (rowData.manufactureActivity.activityName ? rowData.manufactureActivity.activityName : "") + "生产活动记录" });
    }

    handleUpdateAttrRow = (newData, oldData) => {
        const mactivityRecord = this.state.mactivityRecord;
        let list = mactivityRecord.recordAttrs;
        if (!list) {
            list = [];
        }
        list[oldData.tableData.id] = newData;
        this.setState({
            mactivityRecord: {
                ...mactivityRecord,
                recordAttrs: list
            }
        });
    }

    handleMakeUserTasks = () => {
        const { tasks, userInfo, userProfile } = this.props;

        const role = getRole(userInfo.roleId, userProfile)
        let userTasks = []
        if (role && role.roleName === '班长') {
            userTasks = _.filter(tasks, item => {
                return _.find(role.departments, d => { return d.dptId === item.dptId })
            })
        } else {
            userTasks = _.filter(tasks, item => {
                return item.executor === userInfo.userId
            })
        }
        if (userTasks) {
            this.setState({ userTasks: userTasks });
        }
    }

    filterCommissionRecords(records) {
        // return _.filter(records, r => {
        //     return r.manufactureService && r.manufactureService.serviceType !== 'COMMISSION'
        // })
        return records;
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'manufacture_activity_record').action === 'W';
        return (
            <div>
                <MActivityRecord
                    selectedDate={this.state.selectedDate}
                    constant={this.props.constant}
                    manufactureDicts={this.props.manufactureDicts}
                    records={this.filterCommissionRecords(this.props.records)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleOpen={this.handleOpen.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />

                <CreateMActivityRecord
                    open={this.state.createOpen}
                    userInfo={this.props.userInfo}
                    userProfile={this.props.userProfile}
                    installs={this.props.installs}
                    companies={this.state.companies}
                    isediting={this.state.isediting}
                    roleFlag={roleFlag}
                    componentTitle={this.state.componentTitle}
                    isShow={this.state.isShow}
                    isActivityEditing={this.state.isActivityEditing}
                    payDpts={this.state.payDpts}
                    receiveDpts={this.state.receiveDpts}
                    userTasks={this.state.userTasks}
                    participateDpts={this.state.participateDpts}
                    manufactureDicts={this.props.manufactureDicts}
                    payDisable={this.state.payDisable}
                    recevieDisable={this.state.recevieDisable}
                    participateDisable={this.state.participateDisable}
                    selectedAttributeIndex={this.state.selectedAttributeIndex}
                    mactivityRecord={this.state.mactivityRecord}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleTextFieldChange={this.handleTextFieldChange.bind(this)}
                    onhandleDateChange={this.handleTriggerDateChange.bind(this)}
                    onhandleUpdateActionRow={this.handleUpdateAttrRow.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.manufactureActivityRecord.recordList,
        installs: state.installation.installationList ? state.installation.installationList.list : [],
        companies: state.company.companyList.list,
        departments: state.constant.departments,
        tasks: state.manufactureTask.tasks,
        userInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        constant: state.constant,
        manufactureDicts: state.constant.manufactureDicts,
        businessGroupList: state.businessGroup.businessGroupList,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActivityRecords: (installId, payCompany, payDpt, receiveCompany, receiveDpt, participateCompany, participateDpt, orderId, serviceId, activityId, companyId, triggerTime, status, taskId) => {
            dispatch(getActivityRecords(installId, payCompany, payDpt, receiveCompany, receiveDpt, participateCompany, participateDpt, orderId, serviceId, activityId, companyId, triggerTime, status, taskId));
        },
        getActivityRecord: (activityRecordId) => {
            return dispatch(getActivityRecord(activityRecordId));
        },
        addManufactureActivityRecord: (activityRecordDto) => {
            return dispatch(addManufactureActivityRecord(activityRecordDto));
        },
        updateManufactureActivityRecord: (activityRecordId, activityRecordDto) => {
            return dispatch(updateManufactureActivityRecord(activityRecordId, activityRecordDto));
        },
        delManufactureActivityRecord: (activityRecordId) => {
            return dispatch(delManufactureActivityRecord(activityRecordId));
        },
        getInstallationsInManipulate: (companyId) => {
            return dispatch(getInstallationsInManipulate(companyId));
        },
        // getUserManufactureTasks: () => {
        //     dispatch(getUserManufactureTasks());
        // },
        getServiceActivityAttrs: (serviceId) => {
            return dispatch(getServiceActivityAttrs(serviceId));
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MActivityRecordContainer);
