import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Button
} from '@material-ui/core';
import MyCanvas from '../../../device/src/containers/MyCanvas';
// import { Prompt } from 'react-router-dom';
// import * as _ from 'lodash'

const InstallationMapEdit = props => {
    const {
        onRouterToInfo,
        installationInfo,
        globalLoading,
        // mapChanged
    } = props;
    return (
        <div>
            {globalLoading || !installationInfo.installId ? null :
                <React.Fragment>
                    <div>
                        <Typography variant="h3" color='textPrimary' style={{ display: 'inline-block' }}>
                            {installationInfo.installName}
                        </Typography>
                        <Button variant="outlined" color="primary" style={{ margin: 10 }} onClick={onRouterToInfo}>
                            返回
                        </Button>
                    </div>
                    <MyCanvas />
                    {/* <Prompt message="数据未保存,确定要离开？" when={mapChanged} /> */}
                </React.Fragment>
            }
        </div>

    );
};

InstallationMapEdit.propTypes = {
    installationInfo: PropTypes.object.isRequired,
    globalLoading: PropTypes.bool.isRequired,
    mapChanged: PropTypes.bool.isRequired
};

export default withStyles(InstallationMapEdit);
