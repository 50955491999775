import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceAsset from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceAsset';
import {
    backToList,
    setDeviceAssetChange,
    setDeviceAsset,
    setDeviceAssetError,
    initDeviceAsset,
    insertDeviceAsset,
    modifyDeviceAsset
} from "../../../redux/modules/devices";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class DeviceEditContainer extends Component {

    static propTypes = {
        deviceAssetError: PropTypes.object,
        deviceAssetChange: PropTypes.bool,
        deviceAsset: PropTypes.object,
        isView: PropTypes.bool,
        currentDevice: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceAsset();
        }
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.deviceAssetError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleDeviceAssetChange(false);
            if (this.props.deviceAsset.assetId) {
                this.props.onhandleUpdateDeviceAsset(this.props.deviceAsset);
            } else {
                this.props.onhandleCreateDeviceAsset(this.props.deviceAsset);
            }
        }
    }

    handleChange = (event, field) => {
        let newDevice;
        switch (field) {
            case 'faCode':
                newDevice = {
                    ...this.props.deviceAsset,
                    faCode: event.target.value
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            // case 'source':
            //     newDevice = {
            //         ...this.props.deviceAsset,
            //         source: event.target.value
            //     };
            //     this.props.onhandleDeviceAssetChange(true);
            //     this.props.onhandleUpdateEditDeviceAsset(newDevice);
            //     break;
            case 'faNet':
                newDevice = {
                    ...this.props.deviceAsset,
                    faNet: event.target.value
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            case 'faVal':
                newDevice = {
                    ...this.props.deviceAsset,
                    faVal: event.target.value
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            case 'maintCost':
                newDevice = {
                    ...this.props.deviceAsset,
                    maintCost: event.target.value
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            case 'invoiceNo':
                newDevice = {
                    ...this.props.deviceAsset,
                    invoiceNo: event.target.value
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleSelectChange = (vlaue, field) => {
        let newDevice;
        switch (field) {
            case 'dptId':
                newDevice = {
                    ...this.props.deviceAsset,
                    dptId: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;

            case 'source':
                newDevice = {
                    ...this.props.deviceAsset,
                    source: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;

            case 'countUnit':
                newDevice = {
                    ...this.props.deviceAsset,
                    countUnit: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;

            case 'assetCat':
                newDevice = {
                    ...this.props.deviceAsset,
                    assetCat: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;

            case 'assetStatus':
                newDevice = {
                    ...this.props.deviceAsset,
                    assetStatus: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            case 'disposalMode':
                newDevice = {
                    ...this.props.deviceAsset,
                    disposalMode: vlaue
                };
                this.props.onhandleDeviceAssetChange(true);
                this.props.onhandleUpdateEditDeviceAsset(newDevice);
                break;
            default:
            // do nothing
        }
    };


    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'faCode') {
            if (_.trim(this.props.deviceAsset.faCode) === '') {
                err['faCode'] = '固定资产编号不能为空';
            } else {
                err['faCode'] = '';
            }
        }

        if (!field || field === 'faNet') {
            if (this.props.deviceAsset.faNet !== '' && _.isNaN(_.toNumber(this.props.deviceAsset.faNet))) {
                err['faNet'] = '请输入数值';
            } else {
                err['faNet'] = '';
            }
        }

        if (!field || field === 'faVal') {
            if (this.props.deviceAsset.faVal !== '' && _.isNaN(_.toNumber(this.props.deviceAsset.faVal))) {
                err['faVal'] = '请输入数值';
            } else {
                err['faVal'] = '';
            }
        }

        if (!field || field === 'maintCost') {
            if (this.props.deviceAsset.maintCost !== '' && _.isNaN(_.toNumber(this.props.deviceAsset.maintCost))) {
                err['maintCost'] = '请输入数值';
            } else {
                err['maintCost'] = '';
            }
        }

        let newError = {
            ...this.props.deviceAssetError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    transformDepartmentList() {
        let list = _.map(this.props.departmentList, d => {
            let label = d.dptName
            let value = d.dptId
            return {
                label: label,
                value: value,
            }
        })
        return list
    }

    render() {
        return (
            <DeviceAsset
                onhandleSave={this.handleSave.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                deviceAsset={this.props.deviceAsset}
                deviceAssetError={this.props.deviceAssetError}
                onhandleSelectChange={this.handleSelectChange.bind(this)}
                deviceAssetChange={this.props.deviceAssetChange}
                isView={this.props.isview}
                isDeviceEdit={this.props.isDeviceEdit}
                userProfile={this.props.userProfile}
                tabValue={this.props.tabValue}
                index={this.props.index}
                departmentList={this.transformDepartmentList()}
                erpsrc={this.props.erpsrc}
                erpunit={this.props.erpunit}
                erpcat={this.props.erpcat}
                erpstatus={this.props.erpstatus}
                erpdisposal={this.props.erpdisposal}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        deviceAssetChange: state.devices.deviceAssetChange,
        deviceAsset: state.devices.deviceAsset,
        deviceAssetError: state.devices.deviceAssetError,
        isview: state.devices.view,
        departmentList: state.constant.departments,
        currentDevice: state.devices.currentDevice,
        userProfile: state.auth.userProfile,
        isDeviceEdit: state.devices.deviceEdit,
        erpsrc: state.devices.erpsrc,
        erpunit: state.devices.erpunit,
        erpcat: state.devices.erpcat,
        erpstatus: state.devices.erpstatus,
        erpdisposal: state.devices.erpdisposal,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateDeviceAsset: (entity) => {
            dispatch(insertDeviceAsset(entity))
        },
        onhandleUpdateDeviceAsset: (entity) => {
            dispatch(modifyDeviceAsset(entity))
        },
        onhandleUpdateEditDeviceAsset: (entity) => {
            dispatch(setDeviceAsset(entity))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceAssetError(err))
        },
        onhandleDeviceAssetChange: (isChanged) => {
            dispatch(setDeviceAssetChange(isChanged))
        },
        onhandleInitDeviceAsset: () => {
            dispatch(initDeviceAsset());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceEditContainer)
