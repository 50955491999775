
export const localization = {
    body: {
        emptyDataSourceMessage: '无记录',
        addTooltip: '新增',
        deleteTooltip: '删除',
        editTooltip: '编辑',
        filterRow: {
            filterPlaceHolder: '',
            filterTooltip: '过滤'
        },
        editRow: {
            deleteText: '你确定要删除本行？',
            cancelTooltip: '取消',
            saveTooltip: '保存'
        }
    },
    grouping: {
        placeholder: '拖动列',
        groupedBy: '分组'
    },
    header: {
        actions: '操作'
    },
    toolbar: {
        addRemoveColumns: '增加或删除列',
        nRowsSelected: '{0}行已选择',
        showColumnsTitle: '展开行',
        showColumnsAriaLabel: '展开行',
        exportTitle: '导出',
        exportAriaLabel: '导出',
        exportCSVName: '导出CSV格式',
        searchTooltip: '搜索',
        searchPlaceholder: '搜索',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} / {count}',
        labelRowsSelect: '行',
        labelRowsPerPage: '每页行数：',
        firstAriaLabel: '第一页',
        firstTooltip: '第一页',
        previousAriaLabel: '上一页',
        previousTooltip: '上一页',
        nextAriaLabel: '下一页',
        nextTooltip: '下一页',
        lastAriaLabel: '最后一页',
        lastTooltip: '最后一页',
    }
}

export const localizationPagination = {
    backIconButtonText: '上一页',
    nextIconButtonText: '下一页',
    labelRowsPerPage: '每页行数：',
    labelDisplayedRows: ({ from, to, count }) => (`${from}-${to}行 / 共${count}行`)
}

export const options = {
    search: false,
    padding: 'dense',
    actionsColumnIndex: -1,
    addRowPosition: 'first',
    emptyRowsWhenPaging: false,
    minBodyHeight: 150,
    pageSize: 15,
    sorting: false,
    pageSizeOptions: [15, 30, 50],
    headerStyle: {
        position: 'sticky',
        top: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#f5f5f5',
        fontWeight: 600,
        textAlign: 'center',
        lineHeight: '18px',
    },
    actionsCellStyle: {
        display: 'table-cell'
    },
    draggable: false

}
export const style = {
    border: '0px solid black',
    boxShadow: 'none'
}