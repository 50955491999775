import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import {
    Add,
    Edit,
    DeleteOutlined,
    Description,
} from '@material-ui/icons'
import MaterialTable from 'material-table';
import { options, localization } from "../../../../utils/mtable";
import { getDateByTimestamp } from '../../../../utils/datetime';
import _ from 'lodash';

const AccessoryList = props => {
    const {
        classes,
        accessoryList,
        userProfile,
        getAccessoryList,
        onEdit,
        onDelete,
        catList,
        onSizeChange,
        onView,
        onhandleCreate,
        // onCopy
        //onActivate
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'accessory_list' })
        return permission && permission.action === 'W'
    }

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // const renderisCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    const renderTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }

    // const renderStatus = (row) => {
    //     if (row.status === 'active') {
    //         return '在用'
    //     } else if (row.status === 'new') {
    //         return '备用'
    //     } else if (row.status === 'inactive') {
    //         return '弃用'
    //     } else {
    //         return ''
    //     }
    // }

    const assemblycolumns = () => {
        let columns = [
            {
                title: '部件编号',
                field: 'accNo',
                render: row => row ? <Typography>{row.accNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件名称',
                field: 'accName',
                render: row => row ? <Typography>{row.accName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件类别',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
            },
            // {
            //     title: '通用部件',
            //     field: 'isCommon',
            //     render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '关联设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never',
            },
            {
                title: '关联设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never',
            },
            // 缺陷 部件页面 使用状态删除 SBGL-274
            // {
            //     title: '使用状态',
            //     field: 'status',
            //     render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '更新时间',
                field: 'update_time',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <Grid className={classes.detailtable}>
            <MaterialTable
                className={classes.materialtable}
                title=''
                columns={assemblycolumns()}
                data={accessoryList.list}
                options={{
                    ...options,
                    pageSize: rowsPerPage,
                    tableLayout: 'auto',
                    actionsColumnIndex: -1,
                    search: false,
                    toolbar: false,
                    maxBodyHeight: `${tableHeight}px`,
                    minBodyHeight: `${tableHeight}px`,
                }}
                localization={localization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            rowsPerPage={rowsPerPage}
                            count={accessoryList.total}
                            page={page}
                            onChangePage={(event, pageNum) => {
                                setPage(pageNum);
                                getAccessoryList(pageNum * rowsPerPage, rowsPerPage, "-update_time", "", "", "", "", "")
                            }}
                            onChangeRowsPerPage={(event) => {
                                props.onChangeRowsPerPage(event);
                                setRowsPerPage(event.target.value);
                                setPage(0);
                                onSizeChange(event.target.value)
                                getAccessoryList(0, event.target.value, "-update_time", "", "", "", "", "")
                            }}
                        />
                    )
                }}
                actions={checkPermission() ?
                    [rowData => ({
                        icon: () => <Edit color={'primary'} />,
                        tooltip: '编辑',
                        hidden: rowData.status === 'inactive',
                        onClick: (event, rowData) => {
                            onEdit(event, rowData.accId)
                        }
                    }),
                    // rowData => ({
                    //     icon: 'adjust',
                    //     tooltip: '激活',
                    //     onClick: (event, rowData) => {
                    //         onActivate(event, rowData)
                    //     },
                    //     hidden: rowData.status !== 'new'
                    // }),
                    // rowData => ({
                    //     icon: 'post_add',
                    //     tooltip: '拷贝新建',
                    //     onClick: (event, rowData) => {
                    //         onCopy(event, rowData.accId)
                    //     },
                    //     hidden: rowData.status === 'inactive'
                    // }),
                    {
                        icon: () => <Add color={'primary'} />,
                        tooltip: '新建',
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleCreate()
                        }
                    },
                    rowData => ({
                        icon: () => <DeleteOutlined color={'error'} />,
                        tooltip: '弃用',
                        hidden: rowData.status === 'inactive',
                        onClick: (event, rowData) => {
                            onDelete(event, rowData)
                        },
                    })
                    ] : [
                        rowData => ({
                            icon: () => <Description color={'primary'} />,
                            tooltip: '查看',
                            onClick: (event, rowData) => {
                                onView(event, rowData.accId)
                            }
                        })
                    ]}
            >
            </MaterialTable>
        </Grid>
    );
};

AccessoryList.propTypes = {
    classes: PropTypes.object.isRequired,
    accessoryList: PropTypes.object.isRequired,
    getAccessoryList: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    catList: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    //onActivate: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default withStyles(AccessoryList);
