import React, { Component } from 'react';
import { connect } from 'react-redux';
import InsuranceEdit from '@/components/DeviceManagement/Insurances/InsuranceEdit';
import {
    initEdit,
    createInsurance,
    updateInsurance,
    setCurrentInsurance,
    backToInsuranceList,
    setInsuranceError,
    setInsuranceChange
} from "@/redux/modules/deviceInsurance";
import _ from 'lodash';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { getMoment as moment } from '@/utils/datetime';
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import PropTypes from "prop-types";

class InsuranceEditContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentWillMount() {
        let insId = this.props.match.params.insId;
        // let mode = this.props.match.params.mode;
        this.props.onhandleInit(Number(insId));
    }

    static propTypes = {
        currentInsurance: PropTypes.object,
        insuranceError: PropTypes.object,
        insuranceChange: PropTypes.bool,
        insTypeList: PropTypes.array,
    };

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.insuranceError), function (o) { return o !== ''; }) < 0) {

            const { currentInsurance } = this.props;
            const newtInsurance = {
                ...currentInsurance,
                insId: currentInsurance.insId ? _.toNumber(currentInsurance.insId) : undefined,
                insCompany: currentInsurance.insCompany ? currentInsurance.insCompany : '',
                insType: currentInsurance.insType ? currentInsurance.insType : '',
                startDate: currentInsurance.startDate ? moment()(currentInsurance.startDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : '',
                endDate: currentInsurance.endDate ? moment()(currentInsurance.endDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : '',
                deviceId: currentInsurance.deviceId ? _.toNumber(currentInsurance.deviceId) : undefined,
                fee1: currentInsurance.fee1 ? _.toNumber(currentInsurance.fee1) : undefined,
                fee2: currentInsurance.fee2 ? _.toNumber(currentInsurance.fee2) : undefined,
                fee3: currentInsurance.fee3 ? _.toNumber(currentInsurance.fee3) : undefined,
                fee4: currentInsurance.fee4 ? _.toNumber(currentInsurance.fee4) : undefined,
                fee5: currentInsurance.fee5 ? _.toNumber(currentInsurance.fee5) : undefined,
                fee6: currentInsurance.fee6 ? _.toNumber(currentInsurance.fee6) : undefined,
                fee7: currentInsurance.fee7 ? _.toNumber(currentInsurance.fee7) : undefined,
                fee8: currentInsurance.fee8 ? _.toNumber(currentInsurance.fee8) : undefined,
                fee9: currentInsurance.fee9 ? _.toNumber(currentInsurance.fee9) : undefined,
                fee10: currentInsurance.fee10 ? _.toNumber(currentInsurance.fee10) : undefined,
                feeSum: currentInsurance.feeSum ? _.toNumber(currentInsurance.feeSum) : undefined,
                policyHolder: currentInsurance.policyHolder ? currentInsurance.policyHolder : '',

            };
            this.props.onhandleInsuranceChange(false);
            if (newtInsurance.insId) {
                this.props.onhandleUpdateInsurance(newtInsurance);
            } else {
                this.props.onhandleCreateInsurance(newtInsurance);
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleInsuranceChange(true);
        let newInsurance = {
            ...this.props.currentInsurance
        };

        const fieldName = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);

        newInsurance[fieldName] = fieldValue;

        this.props.onhandleUpdateEditInsurance(newInsurance);
    };

    handleSelect = (value, field) => {
        this.props.onhandleInsuranceChange(true);
        let newSelect = { ...this.props.currentInsurance };
        switch (field) {
            case 'insType':
                newSelect.insType = value.target.value
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditInsurance(newSelect);
        // 等currentInsurance状态更新后再check select组件
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleDateChange(value, field) {
        this.props.onhandleInsuranceChange(true);
        let newSelect = { ...this.props.currentInsurance };

        const date = moment()(value);
        switch (field) {
            case 'startDate':
                newSelect.startDate = date.startOf('day').format("YYYY-MM-DD")
                newSelect.endDate = date.endOf('day').add('12', 'months').format("YYYY-MM-DD")
                break;
            case 'endDate':
                if (
                    date.isBefore(moment()(newSelect.startDate))
                ) {
                    newSelect.endDate = moment()(newSelect.startDate).endOf('day').add('12', 'months').format("YYYY-MM-DD")
                } else {
                    newSelect.endDate = date.endOf('day').format("YYYY-MM-DD")
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditInsurance(newSelect);
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field || field === 'insType') {
            if (_.trim(this.props.currentInsurance.insType) === '') {
                err['insType'] = '费用类型不能为空';
            } else {
                err['insType'] = '';
            }
        }

        // if (!field || field === 'insCompany') {
        //     if (_.trim(this.props.currentInsurance.insCompany) === '') {
        //         err['insCompany'] = '保险公司不能为空';
        //     } else {
        //         err['insCompany'] = '';
        //     }
        // }

        if (!field || field === 'startDate') {
            if (_.trim(this.props.currentInsurance.startDate) === '') {
                err['startDate'] = '开始日期不能为空';
            } else {
                err['startDate'] = '';
            }
        }

        if (!field || field === 'endDate') {
            if (_.trim(this.props.currentInsurance.endDate) === '') {
                err['endDate'] = '结束日期不能为空';
            } else {
                err['endDate'] = '';
            }
        }

        if (!field || field === 'feeSum') {
            if (_.trim(this.props.currentInsurance.feeSum) === '') {
                err['feeSum'] = '总费用不能为空';
            } else if (_.trim(this.props.currentInsurance.feeSum) !== '' && (_.isNaN(_.toNumber(this.props.currentInsurance.feeSum)) || _.toNumber(this.props.currentInsurance.feeSum) <= 0)) {
                err['feeSum'] = '请输入正确的数值';
            } else {
                err['feeSum'] = '';
            }
        }

        let newError = {
            ...this.props.insuranceError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            roleList,
            deviceDict,
        } = this.props;
        switch (field) {
            case 'companyId':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyName : '';
                break;
            case 'role':
                value = _.find(roleList, { 'code': fieldId }) ? _.find(roleList, { 'code': fieldId }).name : '-';
                break;
            case 'deviceDict':
                value = _.find(deviceDict, { 'code': fieldId }) ? _.find(deviceDict, { 'code': fieldId }).name : '-';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleAddVehicle = () => {
        const { companies, currentUserInfo } = this.props;
        const pcompany = _.find(companies, o => !o.pid);
        let companyId = null;
        if (currentUserInfo.companyId !== pcompany.companyId) {
            companyId = currentUserInfo.companyId
        }
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleCreateErrorMessage(`只能选择一个车辆`)
                        return reject('callbackFunc Error')
                    } else if (this.props.selectedDevices[0].cat && !(this.props.selectedDevices[0].cat.includes('D1706') || this.props.selectedDevices[0].cat === 'D170507')) {
                        this.props.onhandleCreateErrorMessage(`只能选择车辆设备`)
                        return reject('callbackFunc Error')
                    } else {
                        //添加车辆
                        this.setVehicleInfo(this.props.selectedDevices[0])
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    setVehicleInfo = (device) => {
        if (!device) return;

        const {
            currentInsurance,
            onhandleUpdateEditInsurance
        } = this.props;

        onhandleUpdateEditInsurance({
            ...currentInsurance,
            deviceId: device.deviceId,
            device: {
                deviceName: device.deviceName,
                tagNo: device.tagNo,
                companyId: device.companyId
            }
        })
    }

    handleBack = (event) => {
        this.props.onhandleBackToInsuranceList();
    };

    render() {
        return (
            <InsuranceEdit
                editable={this.props.editable}
                currentUserInfo={this.props.currentUserInfo}
                onhandleSave={this.handleSave.bind(this)}
                currentInsurance={this.props.currentInsurance}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleAddVehicle={this.handleAddVehicle.bind(this)}
                insuranceError={this.props.insuranceError}
                insuranceChange={this.props.insuranceChange}
                companyList={this.props.companyList}
                departmentList={this.props.departments}
                insTypeList={this.props.insTypeList}
                onhandleListMapping={this.handleListMapping.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        bUnits: state.constant.businessunits,
        deviceDict: state.constant.deviceDictionaries,
        editable: state.deviceInsurance.editable,
        currentInsurance: state.deviceInsurance.currentInsurance,
        insuranceError: state.deviceInsurance.insuranceError,
        insuranceChange: state.deviceInsurance.insuranceChange,
        companyList: state.constant.companies,
        insTypeList: state.deviceInsurance.insTypeList,

        selectedDevices: state.deviceSelect.selectedDevices,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInit: (insId) => {
            dispatch(initEdit(insId))
        },
        onhandleCreateInsurance: (entity) => {
            dispatch(createInsurance(entity))
        },
        onhandleUpdateInsurance: (entity) => {
            dispatch(updateInsurance(entity))
        },
        onhandleUpdateEditInsurance: (entity) => {
            dispatch(setCurrentInsurance(entity))
        },
        onhandleBackToInsuranceList: () => {
            dispatch(backToInsuranceList())
        },
        onhandleSetError: (err) => {
            dispatch(setInsuranceError(err))
        },
        onhandleInsuranceChange: (isChanged) => {
            dispatch(setInsuranceChange(isChanged))
        },
        onhandleCreateErrMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InsuranceEditContainer)
