import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";
import {
    Card,
    Select,
    Divider
} from "@material-ui/core";
import { renderValidInfo, getMoment as moment } from '@/utils/datetime';

import _ from 'lodash';

const ChecklistItems = props => {
    const {
        classes,
        currentInventory,
        currentChecklist,
        lists,
        search,
        userProfile,
        currentUserInfo,
        companyList,
        departmentList,
        // listTypeList,
        categoryList,
        roleList,
        onEdit,
        getChecklists,
        onhandleCreate,
        onhandleSelectChange,
        // onhandleSearch,
        onhandleClearSearch,
        onhandleDownloadTemp,
        onhandleUpload,
        onhandlePageChange,
        onhandleBack,
        onhandleListMapping,
        inspectionDptMapping,
        onDelete,
    } = props;

    // console.log('search')
    // console.log(search)
    const companyId = currentUserInfo.companyId;
    const company = _.find(companyList, { 'companyId': companyId });
    const hasPcomp = company && company.pid;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_checklist' })
        return permission && (permission.action === 'W' || permission.action === 'R')
    }

    const checklistEditable = () => {
        // check公司
        if (!currentInventory.companyId && hasPcomp) return false;
        if (hasPcomp && currentInventory.companyId !== currentUserInfo.companyId) return false;
        // 个人权限
        if (!checkPermission()) return false;
        // 同检查类型
        if (_.findIndex(inspectionDptMapping, { 'inspectionCode': currentInventory.listType }) < 0) return false;
        // 同检查子分类（如果需要）
        if (currentInventory.catCode && currentInventory.catCode !== '') {
            return _.findIndex(inspectionDptMapping, { 'inspectionCode': currentInventory.catCode }) >= 0
        }
        return true;
    }

    // const companyOptions = () => (
    //     <React.Fragment>
    //         {companyList ?
    //             companyList.map(data => (
    //                 <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
    //                 // <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const departmentOptions = () => (
        <React.Fragment>
            {departmentList ?
                departmentList
                    .filter(data => (currentInventory.companyId === data.companyId))
                    .map(data => (
                        <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
                    )) : null
            }
        </React.Fragment>
    )

    const selectedDpt = _.find(departmentList, { 'dptId': _.toInteger(search.dptId) });
    const showRoleSelect = selectedDpt && selectedDpt.companyPid && selectedDpt.companyPid !== '';
    const roleOptions = () => (
        <React.Fragment>
            {roleList ?
                roleList
                    .filter(item => (item.companyId === search.company))
                    .map(data => (
                        <option value={data.code} key={data.code}>{data.name}</option>
                    )) : null
            }
        </React.Fragment>
    )

    // const listTypeOptions = () => (
    //     <React.Fragment>
    //         {listTypeList ?
    //             listTypeList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const showCategory = _.find(categoryList, { 'pcode': currentInventory.listType });
    // const categoryOptions = () => (
    //     <React.Fragment>
    //         {categoryList ?
    //             categoryList.filter(item => (item.pcode === search.listType)).map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const tableOptions = {
        ...options,
        pageSize: search.size,
        tableLayout: 'auto',
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        toolbar: true
    }

    const tableStyle = {
        ...style,
        border: '0px solid black',
        boxShadow: 'none'
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '编号',
                field: 'serialNo',
                render: row => row ? <Typography>{row.serialNo}</Typography> : '',
                editable: 'never',
                width: '5%'
            },
            // {
            //     title: '排查类型',
            //     field: 'listType',
            //     render: row => row ? <Typography>{onhandleListMapping(row.listType, 'listType')}</Typography> : '',
            //     editable: 'never',
            //     width: '10%'
            // },
            // {
            //     title: '子分类',
            //     field: 'listType',
            //     render: row => row ? <Typography>{onhandleListMapping(row.catCode, 'listType')}</Typography> : '',
            //     editable: 'never',
            //     width: '10%'
            // },
            // {
            //     title: '监督单位',
            //     field: 'companyId',
            //     render: row => row ? <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography> : '',
            //     editable: 'never',
            //     width: '10%'
            // },
            {
                title: '检查部门',
                field: 'dptId',
                render: row => row ? <Typography>{onhandleListMapping(row.dptId, 'department')}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            {
                title: '责任角色',
                field: 'roleId',
                hidden: !(company && company.pid),
                render: row => row ? <Typography>{onhandleListMapping(row.roleId, 'role')}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            // {
            //     title: '根因',
            //     field: 'rootCause',
            //     render: row => row ? <Typography>{onhandleListMapping(row.rootCause, 'rootCause')}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '纪律类别',
            //     field: 'discipline',
            //     render: row => row ? <Typography>{onhandleListMapping(row.discipline, 'discipline')}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '检查内容',
                field: 'problem',
                render: row => row ? <Typography title={row.problem}>{truncate(row.problem, 30)}</Typography> : '',
                editable: 'never',
                width: '400px'
            }
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <>
                <Grid container spacing={1} className={classes.gridContainer}>
                    {/* <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            监督单位</Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <Select
                            style={{ width: '90%' }}
                            native
                            value={search.company}
                            onChange={(event) => onhandleSelectChange(event, 'company')}
                        >
                            <option aria-label="None" value="" />
                            {companyOptions()}
                        </Select>
                    </Grid> */}
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            检查部门</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.dptId}
                            onChange={(event) => onhandleSelectChange(event, 'department')}
                        >
                            <option aria-label="None" value="" >{'全部'}</option>
                            {departmentOptions()}
                        </Select>
                    </Grid>
                    {
                        showRoleSelect &&
                        <>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    基层责任角色</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={search.roleId}
                                    onChange={(event) => onhandleSelectChange(event, 'role')}
                                >
                                    <option aria-label="None" value="">{'全部'}</option>
                                    {roleOptions()}
                                </Select>
                            </Grid>
                        </>
                    }
                    {/* <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            排查类型</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.listType}
                            onChange={(event) => onhandleSelectChange(event, 'listType')}
                        >
                            <option aria-label="None" value="" />
                            {listTypeOptions()}
                        </Select>
                    </Grid>
                    {
                        showCategory && (
                            <>
                                <Grid item xs={1}>
                                    <Typography gutterBottom className={classes.label}>
                                        分类</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        style={{ width: '90%' }}
                                        native
                                        value={search.catCode}
                                        onChange={(event) => onhandleSelectChange(event, 'category')}
                                    >
                                        <option aria-label="None" value="" />
                                        {categoryOptions()}
                                    </Select>
                                </Grid>
                            </>
                        )
                    } */}
                </Grid>

                {/* <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={() => onhandleSearch()}
                        >{'搜索'}</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleClearSearch}

                        >{'重置'}</Button>
                    </Grid>
                </Grid> */}
                <MTableToolbar {...props} />
            </>

        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={search.size}
                count={lists.total}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum);
                    getChecklists({
                        ...search,
                        offset: pageNum * search.size,
                        size: search.size
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                    getChecklists({
                        ...search,
                        offset: 0,
                        size: event.target.value
                    })
                }}

            />
        )
    }

    return (
        <Card className={classes.card}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {currentInventory.inventoryName || '检查表明细'}</Typography>
            </div>

            <Divider variant="middle" />

            <Grid container spacing={1} className={classes.gridContainer}>
                <Typography variant="h3" color='textPrimary'>基本信息</Typography>
            </Grid>

            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                        监督单位</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        {currentInventory.companyId ? onhandleListMapping(currentInventory.companyId, 'company') : '不限'}
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>
                        排查类型</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom>
                        {currentInventory.listType ? onhandleListMapping(currentInventory.listType, 'listType') : '-'}
                    </Typography>
                </Grid>
                {
                    showCategory && (<>
                        <Grid item xs={1}>
                            <Typography gutterBottom className={classes.label}>
                                子分类</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {currentInventory.catCode ? onhandleListMapping(currentInventory.catCode, 'category') : '-'}
                            </Typography>
                        </Grid>
                    </>)
                }

                <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>
                        有效期</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom>
                        {renderValidInfo(currentInventory.validStartTime, currentInventory.validEndTime)}
                    </Typography>
                </Grid>

            </Grid>

            <Grid container spacing={1} className={classes.gridContainer}>
                {currentChecklist.listId ? (
                    <>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                {currentChecklist.creatorLastName + currentChecklist.creatorFirstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {moment().utc(currentChecklist.createTime).format('yyyy/MM/DD HH:mm:ss')}
                            </Typography>
                        </Grid>

                    </>) : null
                }

                {currentChecklist.listId && currentChecklist.updateTime ? (
                    <>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                更新人
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {currentChecklist.revisorLastName + currentChecklist.revisorFirstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                更新时间
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {moment().utc(currentChecklist.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                            </Typography>
                        </Grid>

                    </>) : null
                }
            </Grid>

            <Divider variant="middle" />

            <Grid container spacing={1} className={classes.gridContainer}>
                <Typography variant="h3" color='textPrimary'>检查项一览</Typography>
            </Grid>

            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={tableOptions}
                        localization={localization}
                        style={tableStyle}
                        components={components}
                        actions={checklistEditable() ?
                            [
                                {
                                    icon: 'settings_backup_restore',
                                    tooltip: '重置筛选',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleClearSearch() }
                                },
                                {
                                    icon: 'get_app',
                                    tooltip: '下载检查表模板',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleDownloadTemp() }
                                },
                                {
                                    icon: 'backup',
                                    tooltip: '导入检查项',
                                    isFreeAction: true,
                                    onClick: (event, rowData) => { onhandleUpload() }
                                },
                                {
                                    icon: 'add',
                                    tooltip: '新建检查项',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleCreate() }
                                },
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, false)
                                    }
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: '编辑',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, true)
                                    },
                                    disabled: !checklistEditable(rowData)
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除',
                                    onClick: (event, rowData) => {
                                        onDelete(event, rowData.listId)
                                    },
                                    disabled: !checklistEditable(rowData)
                                })
                            ] : [
                                {
                                    icon: 'settings_backup_restore',
                                    tooltip: '重置筛选',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleClearSearch() }
                                },
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, false)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="primary"
                            onClick={event => onhandleBack(event)}>
                            {'返回'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

ChecklistItems.propTypes = {
    classes: PropTypes.object.isRequired,
    lists: PropTypes.object.isRequired,
    inspectionDptMapping: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    getChecklists: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandlePageChange: PropTypes.func.isRequired
};

export default withStyles(ChecklistItems);
