import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConfirmPage } from '../components';
import { closeConfirmDialog } from '../redux/modules/confirmDialog';

class ConfirmPageContainer extends Component {
  static propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    confirmCallback: PropTypes.func,
    closeCallback: PropTypes.func,
    unsaved: PropTypes.bool,
  }

  handleConfirm() {
    const { confirmCallback, closeConfirmDialog,unsaved } = this.props;
    // final execute function from other component or redux-action
    if (confirmCallback) {
      if(unsaved){
        confirmCallback(true);
      } else {
        confirmCallback();
      }
    }
    // close action
    if (closeConfirmDialog) closeConfirmDialog();
  }

  handleClose() {
    const { closeConfirmDialog, closeCallBack } = this.props;
    // final execute function(from other component or redux-action) config by open action
    if (closeConfirmDialog) closeConfirmDialog();
    // close action
    if (closeCallBack) closeCallBack();
  }

  render() {
    return (
      <ConfirmPage
        open={this.props.open}
        title={this.props.title}
        text={this.props.text}
        onhandleConfirm={this.handleConfirm.bind(this)}
        onhandleClose={this.handleClose.bind(this)} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.confirmDialog.open,
    title: state.confirmDialog.title,
    text: state.confirmDialog.text,
    confirmCallback: state.confirmDialog.confirmCallback,
    closeCallback: state.confirmDialog.closeCallback,
    unsaved: state.confirmDialog.unsaved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeConfirmDialog: () => {
      dispatch(closeConfirmDialog());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPageContainer);
