import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message'
import { createloading, cleanloading } from './loading';
import {getOperationCategory, getRuleList} from './operationRule';
import { getDeviceCategoryTree } from './trees';
import { getDeviceProCat } from './devices';
import { getCurrentProcessStatus, getCurrentProcess } from './process';
import { getRoles, getRoleTree } from './role';
import { getMoment, formatDate } from '../../utils/datetime';
import { getAccessoryCategory } from './accessory';
import * as _ from 'lodash';

const porxyApi = '/api'

const defaultState = {
    operationPlanState: {
        year: (getMoment()().startOf('month').format("YYYY-MM-DD")),
        month: (getMoment()().startOf('month').format("YYYY-MM-DD")),
        companyId: "",
        filterCompanyTree: [],
        routeType: '',
        optNo: '',
    },
    plans: {
        total: 0,
        list: []
    },
    planDetails: {
        total: 0,
        list: []
    },
    currentPlan: {},
    createPlanPageSize: 10,
    planDetailPageSize: 10,
    monthlyPlanPageSize: 10,
    isViewMode: true,
    newPlanDetailList: [],
    newPlanChange: false,
    currentPlanDetail: {},
    // 当前运维计划是否被更改？
    currentPlanDetailChangeFlg: false,
};

// 设置 - 当前运维计划是否被更改？
export const setCurrentPlanDetailChangeFlg = createAction('@@xcloud/operationPlan/setCurrentPlanDetailChangeFlg');

export const setCurrentPlanDetail = createAction('@@xcloud/operationPlan/setCurrentPlanDetail');

export const setOperationPlanState = createAction('@@xcloud/operationPlan/setOperationPlanState');

export const getPlanDetailById = createAction('@@xcloud/operationPlan/getPlanDetailById',
    (id) => (dispatch, getState, httpClient) => {
        let url = '/operationplans/details/' + id;
        return httpClient.get(porxyApi + url)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setCurrentPlanDetail(res.data));
                    }
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '取得设备运维计划详细失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const setOperationDetailCost = createAction(
    '@@xcloud/operation/initAppointmentCreate',
    (dtlId, cost) => (dispatch, getState, httpCliene) => {
        let datas = _.clone(getState().operationPlan.planDetails);
        let data = _.find(datas.list, { dtlId: dtlId });
        data.onefinalCost = cost
        dispatch(setPlanDetails(datas))
    }
);

// 工单下达 处理
export const routerToCreateWorkOrder = createAction(
    '@@xcloud/operationPlan/routerToCreateWorkOrder',
    (requestSource, id) => (dispatch) => {
        // // 用于标识访问来源，“返回”按钮处理时引用
        // let requestSource = "fromdetailpage"

        dispatch(push(`/deviceManagement/operationplan/workorder/${requestSource}/${id}`))
    }
);

export const executeProcess = createAction('@@xcloud/operationPlan/executeProcess',
    (year, params) => (dispatch, getState, httpClient) => {
        let url = `/operationplans/${year}/process`;
        return httpClient.post(porxyApi + url, params)
            .then((res) => {
                if (res.status === 200) {
                    return dispatch(getCurrentProcessStatus("OPERATIONPLAN"));
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '执行设备运维计划审批流程失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const setViewMode = createAction('@@xcloud/operationPlan/setViewMode');
export const setCreatePlanPageSize = createAction('@@xcloud/operationPlan/setCreatePlanPageSize');
export const setPlanDetailPageSize = createAction('@@xcloud/operationPlan/setPlanDetailPageSize');
export const setMonthlyPlanPageSize = createAction('@@xcloud/operationPlan/setMonthlyPlanPageSize');

export const addOperationPlan = createAction('@@xcloud/operationPlan/addOperationPlan',
    (operationpPlan, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/operationplans', operationpPlan, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res) {
                    dispatch(getCurrentProcess("OPERATIONPLAN"));
                    dispatch(getCurrentProcessStatus("OPERATIONPLAN"));
                    dispatch(createMessage('success', '创建设备运维年度计划成功'));
                    dispatch(getPlanList({
                        offset: 0,
                        sort: "-update_time",
                        status: "new",
                        planType: "ANNU",
                        companyId: operationpPlan.companyId,
                        year: operationpPlan.year
                    }));
                }
            }
            ).catch(err => {
                let msg = `创建设备运维年度计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const getPlanList = createAction('@@xcloud/operationPlan/getPlanList',
    (query) => (dispatch, getState, httpClient) => {
        let pageSize = getState().operationPlan.createPlanPageSize
        let url = `/api/operationplans`;
        if (query) {
            if (query.offset) {
                url += "?offset=" + query.offset;
            } else {
                url += "?offset=0";
            }
            if (query && query.size) {
                url += "&size=" + query.size
            } else {
                url += "&size=" + (pageSize ? pageSize : 10)
            }
            if (query.sort) {
                url += "&sort=" + query.sort;
            }
            if (query.planType) {
                url += "&planType=" + query.planType;
            }
            if (query.companyId) {
                url += "&companyId=" + query.companyId;
            }
            if (query.status) {
                url += "&status=" + query.status;
            }
            if (query.year) {
                url += "&year=" + query.year;
            }
        }
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setPlanList(res.data));
                    }
                }
                return res.data;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '设备运维计划列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const getPlanById = createAction('@@xcloud/operationPlan/getPlanById',
    (id) => (dispatch, getState, httpClient) => {
        let url = `/api/operationplans/${id}`;
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setPlan(res.data));
                    }
                }
                return res.data;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '设备运维计划获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const getPlanDetailList = createAction('@@xcloud/operationPlan/getPlanDetailList',
    (query) => (dispatch, getState, httpClient) => {
        dispatch(setPlanDetails({
            total: 0,
            list: []
        }));
        let pageSize = getState().operationPlan.planDetailPageSize;
        let url = `/api/operationplans/details`;
        if (query) {
            if (query.offset) {
                url += "?offset=" + query.offset;
            } else {
                url += "?offset=0";
            }
            if (query && _.isInteger(query.size) && _.isInteger(query.size) !== 0) {
                url += "&size=" + query.size
            } else if (query && _.isInteger(pageSize) && _.isInteger(pageSize) !== 0) {
                url += "&size=" + (pageSize ? pageSize : 10)
            } else {
                url += "&size=0"
            }
            if (query.sort) {
                url += "&sort=" + query.sort;
            }
            if (query.planId) {
                url += "&planId=" + query.planId;
            }
            if (query.companyId) {
                url += "&companyId=" + query.companyId;
            }
            if (query.ruleId) {
                url += "&ruleId=" + query.ruleId;
            }
            if (query.target) {
                url += "&target=" + query.target;
            }
            if (query.fromDate) {
                url += "&fromDate=" + query.fromDate;
            }
            if (query.toDate) {
                url += "&toDate=" + query.toDate;
            }
            if (query.status) {
                url += "&status=" + query.status;
            }
            // 运维类别
            if (query.optType) {
                url += "&optType=" + query.optType;
            }
            // 执行时间 - 开始
            if (query.targetDateStart) {
                url += "&targetDateStart=" + query.targetDateStart;
            }
            // 执行时间 - 结束
            if (query.targetDateEnd) {
                url += "&targetDateEnd=" + query.targetDateEnd;
            }
            // 预算 - 开始
            if (query.budgetStart) {
                url += "&budgetStart=" + query.budgetStart;
            }
            // 预算 - 结束
            if (query.budgetEnd) {
                url += "&budgetEnd=" + query.budgetEnd;
            }
            // 工单下达状态
            if (query.orderAssigned) {
                url += "&orderAssigned=" + query.orderAssigned;
            }
            if (query.amoeba) {
                url += "&amoeba=" + query.amoeba;
            }
            if (query.orderId) {
                url += "&orderId=" + query.orderId;
            }
            if (query.optNo) {
                url += "&optNo=" + query.optNo;
            }
            if (query.userId) {
                url += "&userId=" + query.userId;
            }
        }

        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        if (res.data.list && res.data.list.length > 0) {
                            res.data.list.forEach(data => {
                                if (data.device && data.device.deviceId) {
                                    data.group = `设备 ${data.device.deviceNo} ${data.device.deviceName}`;
                                } else if (data.accessory && data.accessory.accId) {
                                    data.group = `部件 ${data.accessory.accNo} ${data.accessory.accName}`;
                                } else if (data.dictionary && data.dictionary.name) {
                                    data.group = `部件类别 ${data.dictionary.name}`;
                                } else {
                                    data.group = '';
                                }
                            })

                            res.data.list = _.sortBy(res.data.list, ['companyId', 'updateTime'], ['asc', 'desc']);
                        }
                        dispatch(setPlanDetails(res.data));
                    }
                }
                return res.data;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '设备运维计划详细列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const updateOperationPlanDetail = createAction('@@xcloud/operationPlan/updateOperationPlanDetail',
    (operationPlanDetail, dtlId, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            budget: operationPlanDetail.budget ? _.toNumber(operationPlanDetail.budget) : 0,
            tenantId: operationPlanDetail.tenantId,
            companyId: operationPlanDetail.companyId,
            opentext: operationPlanDetail.opentext ? operationPlanDetail.opentext : '',
            dtlId: operationPlanDetail.dtlId,
            planId: operationPlanDetail.planId,
            target: operationPlanDetail.target,
            targetDate: new Date(operationPlanDetail.targetDate),
            status: operationPlanDetail.status,
            roles: (operationPlanDetail.roles && operationPlanDetail.roles.length > 0) ? operationPlanDetail.roles.map(x => { return { roleId: x.roleId } }) : [],
        }
        // 运维类别
        if (operationPlanDetail.optType) {
            dto.optType = operationPlanDetail.optType
        }
        if (operationPlanDetail.targetId) {
            dto.targetId = operationPlanDetail.targetId;
        }
        if (operationPlanDetail.targetCat) {
            dto.targetCat = operationPlanDetail.targetCat;
        }

        if (operationPlanDetail.executeTime) {
            dto.executeTime = operationPlanDetail.executeTime
        }


        httpClient.patch(porxyApi + '/operationplans/details/' + dtlId, dto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新设备运维计划详细成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `更新设备运维计划详细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateOperationPlan = createAction('@@xcloud/operationPlan/updateOperationPlan',
    (operationPlan, planId, callback) => (dispatch, getState, httpClient) => {
        // console.log(callback)
        let dto = {
            companyId: operationPlan.companyId,
            opentext: operationPlan.opentext ? operationPlan.opentext : '',
            planId: operationPlan.planId,
            planName: operationPlan.planName,
            planType: operationPlan.planType,
            status: operationPlan.status,
            tenantId: operationPlan.tenantId,
            year: operationPlan.year
        }

        httpClient.patch(porxyApi + '/operationplans/' + planId, dto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新设备运维计划成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `更新设备运维计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const setPlanList = createAction('@@xcloud/operationPlan/setPlanList');
export const setPlan = createAction('@@xcloud/operationPlan/setPlan');
export const setPlanDetails = createAction('@@xcloud/operationPlan/setPlanDetails');
export const initPlanDetail = createAction('@@xcloud/operationPlan/getPlanById',
    (id) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        Promise.all([
            dispatch(getOperationCategory()),
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceProCat()),
            dispatch(getPlanById(id)),
            dispatch(getRoleTree({ offset: 0, size: 0 })),
            dispatch(getRoles()),
            dispatch(getPlanDetailList({ offset: 0, planId: id })),
            dispatch(getAccessoryCategory()),
            dispatch(getCurrentProcess("OPERATIONPLAN")),
            dispatch(getCurrentProcessStatus("OPERATIONPLAN")),
            dispatch(getRuleList({status:'active'}))
        ]).then((res) => {
            // let operationPlan = getState().operationPlan.currentPlan;
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化运维计划详情失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    }
);

export const initOperationGoalPlan = createAction('@@xcloud/operationPlan/initOperationGoalPlan',
    (param) => (dispatch, getState, httpClient) => {
        dispatch(createloading());

        Promise.all([
            dispatch(getPlanList(param)),
            dispatch(getCurrentProcess("OPERATIONPLAN")),
            dispatch(getCurrentProcessStatus("OPERATIONPLAN"))
        ]).then((res) => {
            // let operationPlan = getState().operationPlan.currentPlan;
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化年度运维计划失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    }
);

export const routeToOperationGoalPlanCreate = createAction(
    '@@xcloud/operationPlan/routeToOperationGoalPlanCreate',
    (planId, target) => (dispatch) => {
        console.log('routeToOperationGoalPlanCreate');
        console.log(planId, target);
        dispatch(push(`/leadership/plan/operation/create/${planId}/${target}`))
    }
);

export const initCreateGoalPlanDetail = createAction('@@xcloud/operationPlan/initCreateGoalPlanDetail',
    (planId, target) => (dispatch, getState, httpClient) => {
        let taskArray = [];
        if (target === 'DEV') {
            taskArray.push(dispatch(getDeviceProCat()));
            taskArray.push(dispatch(getDeviceCategoryTree()));
        } else if (target === 'ACC') {
            taskArray.push(dispatch(getAccessoryCategory()));
        }
        taskArray.push(dispatch(getPlanById(planId)))
        taskArray.push(dispatch(setNewPlanDetailList([])));
        taskArray.push(dispatch(getRoleTree({ offset: 0, size: 0 })));
        taskArray.push(dispatch(getRoles()));
        taskArray.push(dispatch(setNewPlanChange(false)));

        dispatch(createloading());


        Promise.all(taskArray).then((res) => {
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化年度运维计划明细创建失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    }
);

export const initCreatePlan = createAction('@@xcloud/operationPlan/initCreatePlan',
    (target) => (dispatch, getState, httpClient) => {
        let taskArray = [];
        if (target === 'DEV') {
            taskArray.push(dispatch(getDeviceProCat()));
            taskArray.push(dispatch(getDeviceCategoryTree()));
        } else {
            taskArray.push(dispatch(getAccessoryCategory()));
        }
        taskArray.push(dispatch(getOperationCategory()));
        taskArray.push(dispatch(setNewPlanDetailList([])));
        taskArray.push(dispatch(getRoleTree({ offset: 0, size: 0 })));
        taskArray.push(dispatch(getRoles()));
        taskArray.push(dispatch(setNewPlanChange(false)));

        dispatch(createloading());


        Promise.all(taskArray).then((res) => {
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化运维计划创建失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    }
);

export const initMonthlyPlan = createAction('@@xcloud/operationPlan/initMonthlyPlan',
    (companyId, fromDate, toDate, routeType) => (dispatch, getState, httpClient) => {
        // let pageSize = 0
        dispatch(createloading());
        Promise.all([
            dispatch(getAccessoryCategory()),
            dispatch(getOperationCategory()),
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceProCat()),
            dispatch(getRoleTree({ offset: 0, size: 0 })),
            dispatch(getRoles()),
            dispatch(getPlanDetailList({ offset: 0, companyId: companyId, fromDate: fromDate, toDate: toDate, size: 0, target: 'DEV,ACC', amoeba: routeType === 'projectmanage' ? true : null })),
        ]).then((res) => {
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化月度计划失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    }
);

export const setNewPlanDetailList = createAction('@@xcloud/operationPlan/setNewPlanDetailList');
export const setNewPlanChange = createAction('@@xcloud/operationPlan/setNewPlanChange');
export const addNewPlanDetail = createAction('@@xcloud/operationPlan/addNewPlanDetail',
    (value) => (dispatch, getState) => {
        let newList = _.cloneDeep(getState().operationPlan.newPlanDetailList);
        newList.push(value);
        dispatch(setNewPlanDetailList(newList));
        dispatch(setNewPlanChange(true));
    }
);

export const routeToOperationPlanDetail = createAction(
    '@@xcloud/operationPlan/routeToOperationPlanDetail',
    (id) => (dispatch) => {
        dispatch(push(`/leadership/plan/operation/${id}`))
    }
);

export const routeToOperationPlanCreate = createAction(
    '@@xcloud/operationPlan/routeToOperationPlanCreate',
    (target) => (dispatch) => {
        dispatch(push(`/deviceManagement/operationplan/create/${target}`))
    }
);

export const routeToOperationPlanList = createAction(
    '@@xcloud/operationPlan/routeToOperationPlanList',
    (id) => (dispatch) => {
        dispatch(push(`/deviceManagement/operationplan`))
    }
);

// 返回 运维计划详细页面
export const routeToOperationPlanDetailPage = createAction(
    '@@xcloud/operationPlan/routeToOperationPlanDetail',
    (id) => (dispatch) => {
        dispatch(push(`/deviceManagement/operationplan/${id}`))
    }
);

export const routerToList = createAction(
    '@@xcloud/operationPlan/routerToList',
    (id) => (dispatch) => {
        dispatch(push(`/leadership/plan/operation`))
    }
);

export const updateCreatePlanData = createAction(
    '@@xcloud/operationPlan/updateCreatePlanData',
    (data) => (dispatch, getState) => {
        let newList = _.cloneDeep(getState().operationPlan.newPlanDetailList);
        let id = data._id;
        let targetData = _.find(newList, function (o) { return o._id === id })
        targetData.targetDate = formatDate(data.targetDate);
        targetData.budget = data.budget;
        targetData.opentext = data.opentext ? data.opentext : '';
        targetData.roles = (data.roles && data.roles.length > 0) ? data.roles : [];
        // 运维类别
        targetData.optType = data.optType;
        delete targetData.tableData;
        dispatch(setNewPlanDetailList(newList));
        dispatch(setNewPlanChange(true));
    }
);

export const deleteCreatePlanData = createAction(
    '@@xcloud/operationPlan/deleteCreatePlanData',
    (data) => (dispatch, getState) => {
        let newList = _.cloneDeep(getState().operationPlan.newPlanDetailList);
        let id = data._id;
        newList = _.reject(newList, function (o) { return o._id === id })
        dispatch(setNewPlanDetailList(newList));
        if (newList.length > 0) {
            dispatch(setNewPlanChange(true));
        } else {
            dispatch(setNewPlanChange(false));
        }
    }
);

export const addOperationPlanDetailAnnu = createAction('@@xcloud/operationPlan/addOperationPlanDetailAnnu',
    (target, callback) => (dispatch, getState, httpClient) => {
        let detailList = getState().operationPlan.newPlanDetailList;
        let operationpPlanDetails = [];
        detailList.forEach(element => {
            let detail = {}
            detail.budget = element.budget;
            detail.opentext = element.opentext;
            detail.target = target;
            detail.companyId = element.companyId;
            if (target === "DEV") {
                detail.targetId = element.device.deviceId;
                detail.companyId = element.device.companyId;
            } else if (target === "ACC") {
                detail.targetId = element.acc.accId;
            } else if (target === "ACCTYPE") {
                detail.targetCat = element.dictionary.code;
            }
            detail.planId = element.planId || undefined;
            detail.targetDate = new Date(element.targetDate);
            detail.roles = (element.roles && element.roles.length > 0) ? element.roles.map(x => { return { roleId: x.roleId } }) : []
            operationpPlanDetails.push(detail);
        });

        httpClient.post(porxyApi + '/operationplans/annu', operationpPlanDetails)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建运维年度计划明细成功'));
                    dispatch(setNewPlanChange(false));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `创建运维年度计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const addOperationPlanTemporaries = createAction('@@xcloud/operationPlan/addOperationPlanTemporaries',
    (target, callback) => (dispatch, getState, httpClient) => {
        let detailList = getState().operationPlan.newPlanDetailList;
        let operationpPlanDetails = [];
        detailList.forEach(element => {
            let detail = {}
            detail.budget = element.budget;
            detail.opentext = element.opentext;
            detail.target = target;
            if (target === "DEV") {
                detail.targetId = element.device.deviceId;
                detail.companyId = element.device.companyId;
            } else {
                detail.targetId = element.acc.accId;
                detail.companyId = element.acc.companyId;
            }

            detail.targetDate = new Date(element.targetDate);
            detail.roles = (element.roles && element.roles.length > 0) ? element.roles.map(x => { return { roleId: x.roleId } }) : [];
            // 运维类别
            detail.optType = element.optType;
            operationpPlanDetails.push(detail);
        });

        httpClient.post(porxyApi + '/operationplans/temporaries', operationpPlanDetails)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建运维临时计划成功'));
                    dispatch(setNewPlanChange(false));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `创建运维临时计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const operationPlanReducer = handleActions(
    {
        [setOperationPlanState]: (state, { payload: value }) => {
            return {
                ...state,
                operationPlanState: value
            };
        },
        [setPlanList]: (state, { payload: value }) => {
            return {
                ...state,
                plans: value
            };
        },
        [setPlanDetails]: (state, { payload: value }) => {
            return {
                ...state,
                planDetails: value
            };
        },
        [setPlan]: (state, { payload: value }) => {
            return {
                ...state,
                currentPlan: value
            };
        },
        [setCreatePlanPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                createPlanPageSize: value
            };
        },
        [setPlanDetailPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                planDetailPageSize: value
            };
        },
        [setMonthlyPlanPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                monthlyPlanPageSize: value
            };
        },
        [setViewMode]: (state, { payload: value }) => {
            return {
                ...state,
                isViewMode: value
            };
        },
        [setNewPlanDetailList]: (state, { payload: value }) => {
            return {
                ...state,
                newPlanDetailList: value
            };
        },
        [setNewPlanChange]: (state, { payload: value }) => {
            return {
                ...state,
                newPlanChange: value
            };
        },
        [setCurrentPlanDetail]: (state, { payload: value }) => {
            return {
                ...state,
                currentPlanDetail: value
            };
        },
        // 设置 - 当前运维计划是否被更改？
        [setCurrentPlanDetailChangeFlg]: (state, { payload: value }) => {
            return {
                ...state,
                currentPlanDetailChangeFlg: value
            };
        },

    },
    defaultState
);

export default operationPlanReducer;
