import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination,
    Avatar,
    Menu,
    MenuItem,
} from '@material-ui/core';
import {
    Edit,
    Description,
    DeleteOutlined,
    MoreHoriz,
    PostAdd,
    // ImageAspectRatio,
    // ViewColumn,
    FilterList,
    WifiTethering,
} from '@material-ui/icons'
import MaterialTable from 'material-table';
import { options, localization } from "../../../../utils/mtable";
import _ from 'lodash';

const DeviceList = props => {
    const {
        classes,
        devices,
        userProfile,
        getDeviceList,
        onEdit,
        onCopy,
        onDelete,
        catList,
        useStatusTree,
        onSizeChange,
        onView,
        useStatusMap,
        catMap,
        // exportCsv,
        // onhandleCreate,
        // onBrowse,
        // onRecord,
        search,
        onCharts,
        onOpsRecords,
        // 根据设备类别决定的“位号”显示名称
        tagNoTitle
    } = props;

    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_list' })
        return permission && permission.action === 'W'
    }

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderUseStatus = (device) => {
        let label = ''
        if (useStatusTree && useStatusTree.length > 0) {
            let value = _.find(useStatusTree, function (o) { return o.value === device.useStatus })
            if (value) {
                label = value.label
            }
        }
        return label
    }

    const openMenu = (event, row) => {
        let anchorElement = event.currentTarget;
        setCurrentRow(row);
        setAnchorEl(anchorElement);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateMenu = () => {
        const hasPermission = checkPermission();
        return (
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            // transformOrigin={{
            //     horizontal: 'bottom',
            // }}
            >
                {hasPermission && <MenuItem dense onClick={() => { onCopy(null, currentRow) }}>
                    <PostAdd className={classes.menuIcon} />{"复制"}
                </MenuItem>}
                {/* 改tab中 */}
                {/* <MenuItem dense onClick={() => { onBrowse(null, currentRow.deviceId) }}>
                    <ImageAspectRatio className={classes.menuIcon} />{hasPermission ? "部件注册" : "设备部件"}
                </MenuItem> */}
                {/* <MenuItem dense onClick={() => { onRecord(null, currentRow.deviceId) }}>
                    <ViewColumn className={classes.menuIcon} />{"部件更新记录"}
                </MenuItem> */}
                <MenuItem dense onClick={() => { onCharts(null, currentRow.deviceId) }}>
                    <FilterList className={classes.menuIcon} />{"巡检图表"}
                </MenuItem>
                <MenuItem dense onClick={() => {
                    onOpsRecords(null, { deviceId: currentRow.deviceId, deviceNo: currentRow.deviceNo, deviceName: currentRow.deviceName })
                }}>
                    <WifiTethering className={classes.menuIcon} />{"运维记录"}
                </MenuItem>
                {hasPermission && <MenuItem dense className={classes.menuItemCritical} onClick={() => { onDelete(null, currentRow) }}>
                    <DeleteOutlined className={classes.menuIcon} />{'删除'}
                </MenuItem>}
            </Menu>
        );
    }

    const assemblycolumns = () => {

        let columns = [
            {
                field: 'type',
                render: row => row ? row.status === 'new' ? <Avatar className={classes.pic1}>未</Avatar> : <Avatar className={classes.pic2}>注</Avatar> : '',
                width: 26,
                editable: 'never',
                align: 'center',
                cellStyle: {
                    width: 26,
                    maxWidth: 26,
                    // color: '#FFF'
                    padding: '6px 2px',
                },
                headerStyle: {
                    width: 26,
                    maxWidth: 26,
                    padding: '6px 2px',
                },
            },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            }, {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            }, {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            }, {
                title: '设备类型',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
                lookup: catMap
            },
            {
                title: tagNoTitle,
                field: 'tagNo',
                render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                editable: 'never'
            }, {
                title: '所属装置',
                field: 'installTitle',
                render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                editable: 'never'
            }, {
                title: '使用状态',
                field: 'useStatus',
                render: row => row ? <Typography>{renderUseStatus(row)}</Typography> : '',
                editable: 'never',
                lookup: useStatusMap
            },
            // {
            //     title: '更新时间',
            //     field: 'listTime',
            //     type: 'date',
            //     render: row => row ? <Typography>{row.listTime}</Typography> : '',
            //     editable: 'never'
            // }
        ]

        return columns
    }

    return (
        <Grid className={classes.detailtable}>
            <MaterialTable
                className={classes.materialtable}
                title=''
                columns={assemblycolumns()}
                data={devices.list}
                options={{
                    ...options,
                    pageSize: _.toInteger(search.size),
                    tableLayout: 'auto',
                    actionsColumnIndex: -1,
                    search: false,
                    toolbar: false,
                    maxBodyHeight: `${tableHeight}px`,
                    minBodyHeight: `${tableHeight}px`,
                }}
                localization={localization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[15, 30, 50]}
                            rowsPerPage={_.toInteger(search.size)}
                            count={devices.total}
                            page={page}
                            onChangePage={(event, pageNum) => {
                                setPage(pageNum);
                                getDeviceList({ offset: pageNum * search.size, size: search.size, sort: "-update_time" })
                            }}
                            onChangeRowsPerPage={(event) => {
                                props.onChangeRowsPerPage(event);
                                setPage(0);
                                onSizeChange(event.target.value)
                                getDeviceList({ offset: 0, size: event.target.value, sort: "-update_time" })
                            }}
                        />
                    ),
                }}
                actions={checkPermission() ?
                    [rowData => ({
                        icon: () => <Edit color={'primary'} />,
                        tooltip: '编辑',
                        hidden: rowData.status === 'active',
                        onClick: (event, rowData) => {
                            onEdit(event, rowData.deviceId)
                        }
                    }),
                    rowData => ({
                        icon: () => <Description color={'primary'} />,
                        tooltip: '查看',
                        hidden: rowData.status !== 'active',
                        onClick: (event, rowData) => {
                            onView(event, rowData.deviceId)
                        }
                    }),
                    // rowData => ({
                    //     icon: () => <DeleteOutlined color={'error'} />,
                    //     tooltip: '删除',
                    //     hidden: rowData.groupId,
                    //     onClick: (event, rowData) => {
                    //         onDelete(event, rowData)
                    //     }
                    // }),
                    // rowData => ({
                    //     icon: 'post_add',
                    //     tooltip: '拷贝新建',
                    //     onClick: (event, rowData) => {
                    //         onCopy(event, rowData)
                    //     }
                    // }),
                    // rowData => ({
                    //     icon: 'engineering',
                    //     tooltip: '部件注册',
                    //     onClick: (event, rowData) => {
                    //         onBrowse(event, rowData.deviceId)
                    //     }
                    // }),
                    // rowData => ({
                    //     icon: 'view_column',
                    //     tooltip: '部件更新记录',
                    //     onClick: (event, rowData) => {
                    //         onRecord(event, rowData.deviceId)
                    //     }
                    // }),
                    // rowData => ({
                    //     icon: 'filter_list',
                    //     tooltip: '巡检图表',
                    //     onClick: (event, rowData) => {
                    //         onCharts(event, rowData.deviceId)
                    //     }
                    // }),
                    // rowData => ({
                    //     icon: 'wifi_tethering',
                    //     tooltip: '运维记录',
                    //     onClick: (event, rowData) => {
                    //         onOpsRecords(event,
                    //             {
                    //                 deviceId: rowData.deviceId,
                    //                 deviceNo: rowData.deviceNo,
                    //                 deviceName: rowData.deviceName
                    //             }
                    //         )
                    //     }
                    // }),
                    rowData => ({
                        icon: () => <MoreHoriz color={'primary'} />,
                        tooltip: '更多',
                        onClick: (event, rowData) => {
                            openMenu(event, rowData);
                        }
                    }),
                        // {
                        //     icon: () => <Button variant='contained' color='primary' startIcon={<Add />}>
                        //         新建
                        //     </Button>,
                        //     // tooltip: '新建',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         onhandleCreate()
                        //     }
                        // },
                        // {
                        //     icon: () => <Button variant='contained' color='primary' startIcon={<SaveAlt />}>
                        //         导出
                        //     </Button>,
                        //     // tooltip: '导出',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         exportCsv()
                        //     }
                        // }
                    ] : [
                        rowData => ({
                            icon: () => <Description color={'primary'} />,
                            tooltip: '查看',
                            onClick: (event, rowData) => {
                                onView(event, rowData.deviceId)
                            }
                        }),
                        rowData => ({
                            icon: () => <MoreHoriz color={'primary'} />,
                            tooltip: '更多',
                            onClick: (event, rowData) => {
                                openMenu(event, rowData);
                            }
                        }),
                        // rowData => ({
                        //     icon: 'engineering',
                        //     tooltip: '设备部件',
                        //     onClick: (event, rowData) => {
                        //         onBrowse(event, rowData.deviceId)
                        //     }
                        // }),
                        // rowData => ({
                        //     icon: 'view_column',
                        //     tooltip: '部件更新记录',
                        //     onClick: (event, rowData) => {
                        //         onRecord(event, rowData.deviceId)
                        //     }
                        // }),
                        // rowData => ({
                        //     icon: 'filter_list',
                        //     tooltip: '巡检图表',
                        //     onClick: (event, rowData) => {
                        //         onCharts(event, rowData.deviceId)
                        //     }
                        // }),
                        // rowData => ({
                        //     icon: 'wifi_tethering',
                        //     tooltip: '运维记录',
                        //     onClick: (event, rowData) => {
                        //         onOpsRecords(event,
                        //             {
                        //                 deviceId: rowData.deviceId,
                        //                 deviceNo: rowData.deviceNo,
                        //                 deviceName: rowData.deviceName
                        //             }
                        //         )
                        //     }
                        // }),
                        // {
                        //     icon: 'save_alt',
                        //     tooltip: '导出',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         exportCsv()
                        //     }
                        // },
                    ]}
            >
            </MaterialTable>
            {generateMenu()}
        </Grid>
    );
};

DeviceList.propTypes = {
    classes: PropTypes.object.isRequired,
    devices: PropTypes.object.isRequired,
    getDeviceList: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    catList: PropTypes.array.isRequired,
    useStatusTree: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    useStatusMap: PropTypes.object.isRequired,
    catMap: PropTypes.object.isRequired,
    // exportCsv: PropTypes.func.isRequired,
    // onhandleCreate: PropTypes.func.isRequired,
    onBrowse: PropTypes.func.isRequired,
    onRecord: PropTypes.func.isRequired,
    onCharts: PropTypes.func.isRequired,
    onOpsRecords: PropTypes.func.isRequired,
    // 根据设备类别决定的“位号”显示名称
    tagNoTitle: PropTypes.string.isRequired,
};

export default withStyles(DeviceList);
