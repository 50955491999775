import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { push } from 'connected-react-router';
import { getCompanyId } from "../../utils/userUtils";
import * as _ from 'lodash';
import { getTreePartById } from '../../utils/constant';
import { getDateByTimestamp } from '../../utils/datetime';
import { csv } from '../../utils/exportfile';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createMessage } from '@/redux/modules/message';
// import { currentUserInfo } from './auth';

const defaultState = {
    exceptionList: {
        total: 0,
        list: []
    },
    search: {
        companyId: "",
        status: "",
        fromDate: null,
        toDate: null,
        view: null,
        // ▽　XJGL-127 异常分析页面变更　▽
        inspectionType: "",
        routeId: "",
        installId: "",
        // △　XJGL-127 异常分析页面变更　△
        offset: 0,
        size: 15
    },
    currentException: {},
    // ▽　XJGL-127 异常分析页面变更　▽
    routeList: [],
    installationList: {
        total: 0,
        list: []
    },
    // △　XJGL-127 异常分析页面变更　△
    load: false,
    exceptionReport: [],

    // 巡检异常现象处置 XJGL-172  
    exceptionMap: {
        listId: null,
        planType: null,
        planId: null,
        deviceId: null
    }
};


export const getExceptionList = createAction('@@xcloud/onsiteInspectionException/getExceptionList',
    (offset, size, sort, companyId, status, inspectionType, routeId, installId, fromDate, toDate) => (dispatch, getState, httpClient) => {

        let search = getState().onsiteInspectionException.search;
        companyId = search.companyId ? search.companyId : companyId
        status = search.status ? search.status : status
        // ▽　XJGL-127 异常分析页面变更　▽
        inspectionType = search.inspectionType ? search.inspectionType : inspectionType
        routeId = search.routeId ? search.routeId : routeId
        installId = search.installId ? search.installId : installId
        fromDate = search.fromDate ? new Date(_.toInteger(search.fromDate)).getTime() : null
        toDate = search.toDate ? new Date(_.toInteger(search.toDate)).getTime() : null
        // △　XJGL-127 异常分析页面变更　△
        size = search.size ? search.size : size

        let url = '/api/onsiteinspections/exceptions';

        if (offset && offset !== "") {
            url += "?offset=" + offset
        } else {
            url += "?offset=0"
        }
        if (_.isInteger(size)) {
            url += "&size=" + size
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=-status,-create_time"
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (status && status !== "") {
            url += "&status=" + status
        }
        // ▽　XJGL-127 异常分析页面变更　▽
        if (inspectionType && inspectionType !== "") {
            url += "&inspectionType=" + inspectionType
        }
        if (routeId && routeId !== "") {
            url += "&routeId=" + routeId
        }
        if (installId && installId !== "") {
            url += "&installId=" + installId
        }
        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + fromDate
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + toDate
        }
        // △　XJGL-127 异常分析页面变更　△

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '异常列表获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

/**
 * 初始化路线列表页面
 */
export const initExceptionListPage = createAction('@@xcloud/onsiteInspectionException/initExceptionListPage',
    () => (dispatch, getState) => {
        Promise.all([
            //初始化页面头部
            dispatch(resetSearch()),
            dispatch(getRouteList()),
            dispatch(getInstallationList()),
        ]).then(() => {
            let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            dispatch(getExceptionList(0, 15, "-status,-create_time", companyId, "", "", "", "", "", "", ""))

        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化异常分析搜索失败'));
            return [];
        });
    });

export const resetSearch = createAction('@@xcloud/onsiteInspectionException/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().onsiteInspectionException.search;
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        let newSearch = {
            ...search,
            companyId: company,
            status: "",
            fromDate: null,
            toDate: null,
            view: null,
            inspectionType: "",
            routeId: "",
            installId: "",
            offset: 0,
            size: 15
        }

        dispatch(setSearch(newSearch));
    }
);

export const getRouteList = createAction('@@xcloud/onsiteInspectionException/getRouteList',
    () => (dispatch, getState, httpClient) => {
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(setRouteList(res.data.list))
                    return res.data.list;
                } else {
                    dispatch(setRouteList([]))
                    return []
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);

export const getInstallationList = createAction(
    '@@xcloud/onsiteInspectionException/getInstallationList',
    () => (dispatch, getState, httpClient) => {

        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/installs?offset=0&size=0&sort=-update_time';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(setInstallationList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const exportExceptionList = createAction('@@xcloud/onsiteInspectionException/exportRecordList',
    () => (dispatch, getState, httpClient) => {
        let search = getState().onsiteInspectionException.search;
        let companyId = search.companyId ? search.companyId : ''
        let status = search.status ? search.status : ''
        let inspectionType = search.inspectionType ? search.inspectionType : ''
        let routeId = search.routeId ? search.routeId : ''
        let installId = search.installId ? search.installId : ''
        let fromDate = search.fromDate ? new Date(_.toInteger(search.fromDate)).getTime() : null
        let toDate = search.toDate ? new Date(_.toInteger(search.toDate)).getTime() : null
        let sort = search.sort ? search.sort : ''

        let url = '/api/onsiteinspections/exceptions?offset=0&size=0';

        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=update_time"
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (status && status !== "") {
            url += "&status=" + status
        }
        if (inspectionType && inspectionType !== "") {
            url += "&inspectionType=" + inspectionType
        }
        if (routeId && routeId !== "") {
            url += "&routeId=" + routeId
        }
        if (installId && installId !== "") {
            url += "&installId=" + installId
        }
        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + fromDate
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + toDate
        }


        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {

                let inspectionDictionaries = getState().constant.inspectionDictionaries;

                let fileName = '巡检记录_' + new Date().getTime()
                let columns = ['设备编号', '设备名称', '异常内容', '标准范围', '所属装置', '巡检类型', '巡检路线', '发现人', '发生时间', '更新时间', '状态']
                let data = []
                if (res.status === 200 && res.data && res.data.list.length > 0) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        // 更新时间
                        let updateTime = res.data.list[i].updateTime ? getDateByTimestamp(res.data.list[i].updateTime) : ''
                        // 状态
                        let status = ''
                        switch (res.data.list[i].status) {
                            case 'active':
                                status = '治理'
                                break;
                            case 'new':
                                status = '未治理'
                                break;
                            default:
                                break;
                        }
                        // 巡检类型
                        let item = _.filter(inspectionDictionaries, function (o) { return res.data.list[i].inspectionType === o.code && o.type === "OITYPE" })
                        let inspectionType = item && item.length ? item[0].name : ''

                        let cell = [
                            res.data.list[i].deviceNo,
                            res.data.list[i].deviceName,
                            res.data.list[i].val,
                            res.data.list[i].standardValue,
                            res.data.list[i].installTitle,
                            inspectionType,
                            res.data.list[i].routeName,
                            res.data.list[i].inspectorName,
                            res.data.list[i].createTime,
                            updateTime,
                            status
                        ];
                        data.push(cell)
                    }
                }
                dispatch(csv(fileName, columns, data));

            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '异常列表获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const getExceptionReport = createAction('@@xcloud/onsiteInspectionException/getExceptionReport',
    (companyId, status, inspectionType, routeId, installId, fromDate, toDate, sort, view) => (dispatch, getState, httpClient) => {

        let search = getState().onsiteInspectionException.search;
        companyId = search.companyId ? search.companyId : companyId
        status = search.status ? search.status : status
        inspectionType = search.inspectionType ? search.inspectionType : inspectionType
        routeId = search.routeId ? search.routeId : routeId
        installId = search.installId ? search.installId : installId
        fromDate = search.fromDate ? new Date(search.fromDate).getTime() : null
        toDate = search.toDate ? new Date(search.toDate).getTime() : null
        view = search.view ? search.view : null

        let url = '/api/onsiteinspections/exceptionreports';

        if (sort !== undefined) {
            url += "?sort=" + sort;
        } else {
            url += "?sort=-create_time";
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (status && status !== "") {
            url += "&status=" + status
        }
        if (inspectionType && inspectionType !== "") {
            url += "&inspectionType=" + inspectionType
        }
        if (routeId && routeId !== "") {
            url += "&routeId=" + routeId
        }
        if (installId && installId !== "") {
            url += "&installId=" + installId
        }
        if (fromDate && fromDate !== "") {
            url += "&fromDate=" + fromDate
        }
        if (toDate && toDate !== "") {
            url += "&toDate=" + toDate
        }
        if (view && view === 'year') {
            url += "&type=year";
        }
        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setExceptionReport(res.data));
                    dispatch(setLoad(true));
                    return res.data;
                } else {
                    return [];
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '异常报表获取失败'));
                return [];
            });
    }
);

export const initExceptionReport = createAction('@@xcloud/onsiteInspectionException/initExceptionReport',
    () => (dispatch, getState) => {
        let search = getState().onsiteInspectionException.search;
        Promise.all([

        ]).then((data) => {
            dispatch(getExceptionReport(search));
        }).catch(err => {
            console.log(err)
            let msg = '初始化异常报表统计搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const updSearchData = createAction('@@xcloud/onsiteInspectionException/setSearch',
    (value) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(setSearch(value))
        ]).then(() => {
            if (value.view && value.view !== '')
                dispatch(getExceptionReport("", "", "", "", "", "", "", "", ""))
        }).catch(err => {
        });
    }
);

// 巡检异常现象处置 XJGL-172  
export const routeToDevicePlan = createAction('@@xcloud/onsiteInspectionException/routeToDevicePlan',
    (listId, planType, deviceId) => (dispatch, getState) => {
        dispatch(setExceptionMap({ listId: listId, planType: planType, deviceId: deviceId }));
        dispatch(push(`/deviceManagement/operationplan/create/DEV`));
    }
);
export const routeToExceptionList = createAction('@@xcloud/onsiteInspectionException/routeToExceptionList',
    (exceptionMap) => (dispatch) => {
        dispatch(push(`/qc/exception`))
    }
);
export const updateDevOpsPlanRelatedException = createAction('@@xcloud/onsiteInspectionException/updateExceptionStatus',
    (exceptionMap, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let to = {
            listId: _.toInteger(exceptionMap.listId),
            planId: _.toInteger(exceptionMap.planId),
            status: 'assigned',
            handleType: 'HDT04'
        }
        let url = `/api/onsiteinspections/exceptions/${to.listId}`;
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(createMessage('success', '更新巡检异常信息完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '更新巡检异常信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

// 任务 巡检的异常处理 立即整改功能 XJGL-193
export const handleException = createAction('@@xcloud/onsiteInspectionException/handleException',
    (exception, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        const currentUserInfo = getState().auth.currentUserInfo;
        let exceptionStatus = 'new';
        // switch (exception.handleType) {
        //     case 'HDT01':
        //         exceptionStatus = 'active';
        //         break;
        //     // 错报 => active
        //     case 'HDT02':
        //         exceptionStatus = 'active';
        //         break;
        //     // 已处置
        //     case 'HDT03':
        //         exceptionStatus = 'active';
        //         break;
        //     default:
        //     // do nothing
        // }
        let to = {
            listId: _.toInteger(exception.listId),
            handleType: exception.handleType,
            status: exceptionStatus,
            handleResult: exception.handleResult,
            acceptResult: exception.acceptResult
        }
        if (exception.status === 'new') {
            // 初次处置
            to.status = 'pending'
            to.handler = currentUserInfo.userId
        } else if (exception.status === 'pending') {
            // 确认
            to.status = 'active'
            to.accpeter = currentUserInfo.userId
        }
        let url = `/api/onsiteinspections/exceptions/${to.listId}`;
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(getExceptionList());
                    dispatch(createMessage('success', '更新巡检异常信息完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '更新巡检异常信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

// 任务 巡检的异常处理 立即整改功能 XJGL-193
export const handleExceptionBatch = createAction('@@xcloud/onsiteInspectionException/handleExceptionBatch',
    (exceptionList, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        // let currentUserInfo = getState().auth.currentUserInfo;
        let to = [];
        exceptionList.forEach(exception => {
            // let exceptionStatus = 'new';
            // switch (exception.handleType) {
            //     case 'HDT01':
            //         exceptionStatus = 'active';
            //         break;
            //     // 错报 => active
            //     case 'HDT02':
            //         exceptionStatus = 'active';
            //         break;
            //     // 已处置
            //     case 'HDT03':
            //         exceptionStatus = 'active';
            //         break;
            //     default:
            //     // do nothing
            // }
            to.push({
                listId: _.toInteger(exception.listId),
                handleType: exception.handleType,
                status: exception.status,
                handler: exception.handler,
                handleResult: exception.handleResult,
                accpeter: exception.accpeter,
                acceptResult: exception.acceptResult
            })
        })
        let url = `/api/onsiteinspections/batch/exceptions`;
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(getExceptionList());
                    dispatch(createMessage('success', '批量更新巡检异常信息完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '批量更新巡检异常信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

// 巡检异常现象处置 XJGL-172
export const setExceptionMap = createAction('@@xcloud/onsiteInspectionException/setExceptionMap');

export const setSearch = createAction('@@xcloud/onsiteInspectionException/setSearch');

export const setExceptionList = createAction('@@xcloud/onsiteInspectionException/setExceptionList');

export const setCurrentException = createAction('@@xcloud/onsiteInspectionException/setCurrentException');

export const setInstallationList = createAction('@@xcloud/onsiteInspectionException/setInstallationList');

export const setRouteList = createAction('@@xcloud/onsiteInspectionException/setRouteList');

export const setExceptionReport = createAction('@@xcloud/onsiteInspectionException/setExceptionReport');

export const setLoad = createAction('@@xcloud/onsiteInspectionPlanReport/setLoad');

export const clearExceptionMap = createAction('@@xcloud/onsiteInspectionException/clearExceptionMap')

// export const initView = createAction('@@xcloud/onsiteInspectionException/initView',
//     (data) => (dispatch, getState) => {
//         let plans = getState().onsiteInspectionException.planList;
//         let plan = _.find(plans.list, ['planId', data.planId])
//         dispatch(setCurrentPlan(plan));
//         dispatch(push('/qc/record/' + plan.planId));
//     }
// );

// export const backToList = createAction('@@xcloud/onsiteInspectionException/backToList',
//     (id) => (dispatch, getState) => {
//         dispatch(push('/qc/plan'));
//     }
// );

/********************* onsiteInspectionException Reducer *********************/

export const planListReducer = handleActions(
    {
        [getExceptionList]: (state, { payload: value }) => {
            return {
                ...state,
                exceptionList: value
            };
        },
        [setExceptionList]: (state, { payload: value }) => {
            return {
                ...state,
                exceptionList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setCurrentException]: (state, { payload: value }) => {
            return {
                ...state,
                currentException: value
            };
        },
        [setInstallationList]: (state, { payload: value }) => {
            return {
                ...state,
                installationList: value
            };
        },
        [setRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getInstallationList]: (state, { payload: value }) => {
            return {
                ...state,
                initListData: value
            };
        },
        [setExceptionReport]: (state, { payload: value }) => {
            return {
                ...state,
                exceptionReport: value,
            };
        },
        [setLoad]: (state, { payload: value }) => {
            return {
                ...state,
                load: value
            };
        },
        // 巡检异常现象处置 XJGL-172
        [setExceptionMap]: (state, { payload: value }) => {
            return {
                ...state,
                exceptionMap: {
                    listId: value.listId,
                    planType: value.planType,
                    planId: value.planId,
                    deviceId: value.deviceId
                }
            };
        },
        [clearExceptionMap]: (state, { payload: value }) => {
            return {
                ...state,
                exceptionMap: {
                    listId: null,
                    planType: null,
                    planId: null,
                    deviceId: null
                }
            };
        },
    },
    defaultState
);

export default planListReducer;