import React, { Component } from 'react';
import { connect } from 'react-redux';
import RuleDetailComponent from '@/components/DeviceManagement/OperationRule/RuleDetail'
import ApprovePannelWithDoc from "@/containers/ApprovePannelWithDoc";
import {
    createRule,
    updateRule,
    setCurrentRule,
    backToRuleList,
    setRuleError,
    setRuleChange,
    initSetup,
    updateOperationsRuleTargets,
    handleRuleApproval,
} from "@/redux/modules/operationRule";
import { openApprovePannelWithDoc } from "@/redux/modules/approvePannel";
import { createMessage } from '@/redux/modules/message';
import { createmodal } from '@/redux/modules/modal';
import { arrayToTree, treeToArray } from '@/utils/constant';

import * as _ from 'lodash';
import PropTypes from "prop-types";

class RuleDetailContainer extends Component {

    static propTypes = {
        currentRule: PropTypes.object,
        ruleError: PropTypes.object,
        ruleChange: PropTypes.bool,
        readonly: PropTypes.bool,
        optCategories: PropTypes.array
    };

    handleSave = async (event) => {

        if (this.props.ruleChange) {
            await this.handleCheck(event, null);
            if (_.findIndex(Object.values(this.props.ruleError), function (o) { return o !== ''; }) < 0) {
                this.props.onhandleRuleChange(false);
                if (this.props.currentRule.ruleId) {
                    this.props.onhandleUpdateRule();
                } else {
                    this.props.onhandleCreateRule();
                }
            }
        }
        if (this.props.ruleTargetChanged) {
            this.props.onhandleRuleTargetChange(this.props.currentRule.target)
        }

    }

    handleSetup(event, target) {
        this.props.onhandleSetup(target);
    }

    handleChange = (event, field) => {
        if (this.props.readonly) {
            return;
        }
        this.props.onhandleRuleChange(true);
        let newRule;
        switch (field) {
            case 'ruleCode':
                newRule = {
                    ...this.props.currentRule,
                    ruleCode: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditRule(newRule);
                break;
            case 'ruleName':
                newRule = {
                    ...this.props.currentRule,
                    ruleName: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditRule(newRule);
                break;
            case 'opentext':
                newRule = {
                    ...this.props.currentRule,
                    opentext: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditRule(newRule);
                break;
            case 'target':
                newRule = {
                    ...this.props.currentRule,
                    target: event.target.value
                };
                this.props.onhandleUpdateEditRule(newRule);
                this.props.onhandleSetup(newRule.target)
                break;
            case 'cycleRadio':
                // console.log(event.target.value);
                if (event.target.value === "none") {
                    newRule = {
                        ...this.props.currentRule,
                        cyclical: false,
                        cycle: 0,
                        unit: "",
                        formula: ""
                    };
                } else if (event.target.value === "lifecycle") {
                    newRule = {
                        ...this.props.currentRule,
                        cyclical: true,
                        cycle: 0,
                        unit: "",
                        formula: "lifecycle"
                    };
                } else {
                    newRule = {
                        ...this.props.currentRule,
                        cyclical: true,
                        cycle: 0,
                        unit: "D",
                        formula: ""
                    };
                }
                this.props.onhandleUpdateEditRule(newRule);
                break;
            case 'cycle':
                newRule = {
                    ...this.props.currentRule,
                    cycle: parseInt(event.target.value)
                };
                this.props.onhandleUpdateEditRule(newRule);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToRuleList();
    };

    checkProcessLine = () => {
        const { processesline, onhadleMassgae } = this.props

        if (!processesline || processesline.length === 0) {
            onhadleMassgae('error', '未设置审批流程')
            return false
        }
        const lineList = treeToArray(_.cloneDeep(processesline))
        for (const line of lineList) {
            if (!line.roleId) {
                onhadleMassgae('error', '流程中存在未设置用户或角色节点')
                return false
            }
        }
        return true
    }

    handleProcessApproval = (event, actionType) => {

        if (actionType === 'SUBMIT') {
            if (this.props.ruleChange || this.props.ruleTargetChanged) {
                this.props.onhadleMassgae('error', '运维规则未保存，请保存后提交。')
            } else {
                this.doProcessApproval(actionType)
            }


        } else if (actionType === 'APPROVE') {
            this.props.openApprovePannel('审核通过', '同意', false, this.doProcessApproval.bind(this, actionType))
        } else if (actionType === 'REJECT') {
            this.props.openApprovePannel('驳回', '', false, this.doProcessApproval.bind(this, actionType))
        } else if (actionType === 'SETUP') {

            const { ruleApprovals, currentUserInfo } = this.props;

            let activeApprovals = _.chain(ruleApprovals).filter({ 'status': 'active' }).map(r => {
                return { ...r, id: r.aprvId }
            }).value()

            let param = {
                companyId: [currentUserInfo.companyId],
                processTree: arrayToTree(activeApprovals, 'id', 'preId'),
                checkWorkOrderProcess: true,
                haveProcessSelect: true,
                processNotices: [],
                callback: () => {
                    return new Promise((resolve, reject) => {
                        const { processesline } = this.props
                        if (processesline && processesline.length > 0) {
                            if (this.checkProcessLine()) {
                                this.doProcessApproval('SETUP', '', treeToArray(_.cloneDeep(processesline)))
                                return resolve('callbackFunc success')
                            }
                        }
                    })
                }
            }
            this.props.onhandleCreateProcess(param);
        }
        else {
            this.doProcessApproval(actionType)
        }
    }

    doProcessApproval = async (actionType, comment, dtoList) => {
        const { currentUserInfo, currentRule, onhandleHandleRuleApproval, } = this.props
        const handlePermission = this.getHandlePermission()
        let dto = {
            actionType: actionType,
            approvalDTO: {},
            approvalDTOList: []
        }
        if (actionType === 'SUBMIT') {
            if (handlePermission.submit) {
                dto.approvalDTO = {
                    aprvId: handlePermission.submitApproval.aprvId,
                    userId: currentUserInfo.userId
                }
            } else {
                return;
            }
        } else if (actionType === 'APPROVE') {
            if (handlePermission.approve) {
                dto.approvalDTO = {
                    opentext: comment,
                    aprvId: handlePermission.approveApproval.aprvId,
                    userId: currentUserInfo.userId
                }
            } else {
                return
            }
        } else if (actionType === 'REJECT') {
            if (handlePermission.reject) {
                dto.approvalDTO = {
                    opentext: comment,
                    aprvId: handlePermission.rejectApproval.aprvId,
                    userId: currentUserInfo.userId
                }
            } else {
                return;
            }
        } else if (actionType === 'RESTART') {
            if (handlePermission.restart) {
                //dto data complete
            } else {
                return
            }
        } else if (actionType === 'SETUP') {
            if (handlePermission.setup) {

                dto.approvalDTOList = this.transformApprovelDTOList(dtoList)
            } else {
                return
            }
        }
        await onhandleHandleRuleApproval(currentRule.ruleId, dto)
    }

    transformApprovelDTOList = (dtoList) => {
        return _.map(dtoList, d => {
            let nd = {
                roleId: d.roleId,
                aprvIdString: _.toString(d.id),
            }
            if (d.userId) {
                nd['userId'] = d.userId
            }
            if (d.preId) {
                nd['preIdString'] = _.toString(d.preId)
            }
            return nd
        })
    }

    handleSelectionChange = (value, field) => {
        this.props.onhandleRuleChange(true);
        let newRule = {
            ...this.props.currentRule,
            cat: value.target.value
        };
        this.props.onhandleUpdateEditRule(newRule);
    }

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'ruleCode') {
            if (_.trim(this.props.currentRule.ruleCode) === '') {
                err["ruleCode"] = '规则编码不能为空';
            } else {
                err["ruleCode"] = '';
            }
        }

        if (!field || field === 'ruleName') {
            if (_.trim(this.props.currentRule.ruleName) === '') {
                err["ruleName"] = '规则名称不能为空';
            } else {
                err["ruleName"] = '';
            }
        }

        if (!field || field === 'cat') {
            if (_.trim(this.props.currentRule.cat) === '') {
                err["cat"] = '运维类别不能为空';
            } else {
                err["cat"] = '';
            }
        }

        if (!field || field === 'cycle') {
            if (this.props.currentRule.formula === ""
                && this.props.currentRule.cyclical
                && this.props.currentRule.cycle <= 0) {
                err["cycle"] = '执行周期不能小于等于0天';
            } else {
                err["cycle"] = '';
            }

        }

        let newEror = {
            ...this.props.ruleError,
            ...err
        }

        this.props.onhandleSetError(newEror);
    }

    getHandlePermission = () => {

        const { currentRule, currentUserInfo, ruleApprovals, userProfile } = this.props
        let handlePermission = {
            downloadDoc: true
        }
        if (currentRule.status === 'new') {
            if (currentRule.creator === currentUserInfo.userId) {
                handlePermission.setup = true
            }
            const firstApproval = _.find(ruleApprovals, { preId: null, status: 'active' })
            if (firstApproval) {
                if (firstApproval.userId === currentUserInfo.userId
                    || (firstApproval.userId === null && firstApproval.roleId === currentUserInfo.roleId)
                ) {
                    handlePermission.submit = true
                    handlePermission.submitApproval = firstApproval
                }
            }
        } else if (currentRule.status === 'submitted') {
            const pendingApprovals = _.filter(ruleApprovals, { status: 'active', confirmTime: null })
            if (pendingApprovals && pendingApprovals.length > 0) {
                const sortApprovals = _.sortBy(pendingApprovals, ['preId'])
                for (const pApprovals of pendingApprovals) {
                    if (pApprovals.preId === sortApprovals[0].preId) {

                        if (pApprovals.userId === currentUserInfo.userId
                            || (pApprovals.userId === null && pApprovals.roleId === currentUserInfo.roleId)
                        ) {
                            handlePermission.approve = true
                            handlePermission.approveApproval = pApprovals
                            handlePermission.reject = true
                            handlePermission.rejectApproval = pApprovals
                        }
                    }
                }
            }
        } else if (currentRule.status === 'active') {
            let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_rule_restart' })
            if (permission && permission.action === 'W') {
                handlePermission.restart = true
            }
        }
        return handlePermission

    }

    getReadOnly() {
        let readOnly = this.props.readonly
        if (!this.props.readonly) {
            if (this.props.currentRule.status !== 'new') {
                readOnly = true
            }
        }
        return readOnly
    }

    render() {
        return (
            <div>
                <RuleDetailComponent
                    onhandleSave={this.handleSave.bind(this)}
                    currentRule={this.props.currentRule}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    onhandleSelectionChange={this.handleSelectionChange.bind(this)}
                    onhandleRuleApproval={this.handleProcessApproval.bind(this)}
                    ruleError={this.props.ruleError}
                    ruleApprovals={this.props.ruleApprovals}
                    onhandleSetup={this.handleSetup.bind(this)}
                    ruleChange={this.props.ruleChange}
                    ruleTargetChanged={this.props.ruleTargetChanged}
                    readonly={this.getReadOnly()}
                    optCategories={this.props.optCategories}
                    handlePermission={this.getHandlePermission()}
                />
                <ApprovePannelWithDoc />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentRule: state.operationRule.currentRule,
        ruleError: state.operationRule.ruleError,
        ruleChange: state.operationRule.ruleChange,
        readonly: state.operationRule.readonly,
        optCategories: state.operationRule.optCategories,
        ruleTargetChanged: state.operationRule.ruleTargetChanged,
        ruleApprovals: state.operationRule.ruleApprovals,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        processesline: state.processlinewithuser.processesline,
        processNotices: state.processlinewithuser.processNotices,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateRule: () => {
            return dispatch(createRule())
        },
        onhandleUpdateRule: () => {
            return dispatch(updateRule())
        },
        onhandleSetup: (target) => {
            return dispatch(initSetup(target));
        },
        onhandleUpdateEditRule: (entity) => {
            return dispatch(setCurrentRule(entity))
        },
        onhandleBackToRuleList: () => {
            return dispatch(backToRuleList())
        },
        onhandleSetError: (err) => {
            return dispatch(setRuleError(err))
        },
        onhandleRuleChange: (isChanged) => {
            return dispatch(setRuleChange(isChanged))
        },
        onhandleRuleTargetChange: (type) => {
            return dispatch(updateOperationsRuleTargets(type))
        },
        onhandleHandleRuleApproval: (ruleId, dto) => {
            return dispatch(handleRuleApproval(ruleId, dto))
        },
        openApprovePannel: (title, defalutComment, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannelWithDoc(title, defalutComment, confirmCallback, CloseCallback))
        },
        onhandleCreateProcess: (parm) => {
            dispatch(createmodal('createProcessLineApproval', parm, 'big'));
        },
        onhadleMassgae: (type, message) => {
            return dispatch(createMessage(type, message));
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleDetailContainer)
