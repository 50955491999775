import { withStyles } from '@material-ui/styles';
import { stripedTableStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    checked: {
        fontSize: '20px',
        color: theme.palette.success.main,
    },
    highlight: {
        color: theme.palette.primary.main,
    },
    stripedTable: stripedTableStyle
});




export default withStyles(styles);