import React, { Component } from 'react';
import { connect } from 'react-redux';
import FiveSPlanList from "@/components/InspectionManagement/FiveSPlan/FiveSPlanList";
import PropTypes from "prop-types";
import {
    setSearch,
    getPlanResults
} from '@/redux/modules/inspection/fiveSPlan';
import {
    startPlanDetails,
    getPlanDetails,
    changePlanStatus,
    changePlanDetailStatus,
    addPlanDetail,
    routeToFiveSDetailEdit,
    routeToFiveSRecords
} from '@/redux/modules/inspection/inspectionPlan'; // 年度计划、月度计划、5S计划共用
// import { getUsers } from '@/redux/modules/user';
import { setCurrentPlanDetail } from '@/redux/modules/inspection/inspectionPlan';
import FiveSTempPlanDetailAddPanel from "./FiveSTempPlanDetailAddPanel";

import { openTempPlanDetailAddPanel } from "@/redux/modules/inspection/fiveSTempPlanDetailAdd";
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { createMessage } from '@/redux/modules/message';
import { enumSort } from '@/utils/listUtils';
import _ from 'lodash';

class FiveSPlanListContainer extends Component {
    static propTypes = {
        checklists: PropTypes.object
    };

    componentDidMount() {

    }

    handleEdit(event, id) {
        this.props.onhandleOpenEdit(id);
    }

    handleDelete = (event, dtl) => {
        const {
            onhandleChangePlanStatus,
            onhandleChangePlanDtlStatus,
            onhandleOpenConfirmDialog,
            onhandleGetPlanDetails,
            search
        } = this.props;
        let changeStatus = () => {
            // 软删除临时计划详情记录
            onhandleChangePlanDtlStatus(dtl.dtlId, 'inactive', () => {
                // 软删除对应计划记录
                onhandleChangePlanStatus(dtl.inspectionPlan.planId, 'inactive');
                onhandleGetPlanDetails(search);
            });
        }
        onhandleOpenConfirmDialog('删除确认', '确定要删除此计划吗?', changeStatus, null, false);
    };

    handleStart = (event, dtl) => {
        const {
            onhandleGetPlanResults,
            onhandleStartPlanDetails,
            onhandleErrorMessage,
            onhandleGetPlanDetails,
            search
        } = this.props;

        onhandleGetPlanResults(dtl.dtlId, (res) => {
            if (!res.list || !res.list.length || res.list.length <= 0) {
                onhandleErrorMessage('请先设置各部门检查人');
                return;
            }
            onhandleStartPlanDetails({ dtlId: dtl.dtlId }, () => {
                onhandleGetPlanDetails(search);
            });
        });
    }

    handleView = (event, planDetail) => {
        const {
            onhandleShowRecords,
            onhandleSetCurrentPlanDetail
        } = this.props;
        onhandleSetCurrentPlanDetail(planDetail);
        onhandleShowRecords(planDetail);
    };

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            ...value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleAdd(planDetail) {
        const { onhandleCreateTempPlan, onhandleGetPlanDetails, search } = this.props;
        if (!planDetail || !planDetail.inspectionCompanyIdList || !planDetail.inspectionCompanyIdList.length) {
            return;
        }
        // 包装 临时计划 请求对象
        let addDetailList = [];
        for (const companyId of planDetail.inspectionCompanyIdList) {
            const dptUserList = [];
            planDetail.inspectionDptUserList.forEach(department => {
                const wrappedDpt = {
                    dptId: department.dptId,
                    dptUser: department.dptUser.map(u => u.userId)
                };
                // 需要为公司分配部门：机关部门全分配，基层部门只分配给对应单位
                if (!department.companyPid) {
                    dptUserList.push(wrappedDpt);
                }
                if (Boolean(department.companyPid) && companyId === department.companyId) {
                    dptUserList.push(wrappedDpt);
                }
            });
            const addDetail = {
                companyId: companyId,
                inventoryId: planDetail.inventoryId,
                inspectHead: planDetail.inspectHead,
                startTime: planDetail.startTime,
                inspectTime: planDetail.inspectTime,
                inspectType: planDetail.inspectType,
                inspectionDptUserList: dptUserList,
                opentext: planDetail.opentext
            }
            addDetailList.push(addDetail);
        }
        // 按时间频次排查
        if (planDetail.frequencyDate && planDetail.planDates && planDetail.planDates.length && planDetail.planDates.length > 0) {
            let freqDetailList = [];
            planDetail.planDates.forEach(planDate => {
                addDetailList.forEach(detail => {
                    const addDetail = {
                        ...detail,
                        startTime: planDate.startTime,
                        inspectTime: planDate.inspectTime
                    }
                    freqDetailList.push(addDetail);
                });
            });
            addDetailList = freqDetailList;
        }

        onhandleCreateTempPlan(addDetailList, () => {
            onhandleGetPlanDetails(search)
        })
    }

    handleOpenTempPlanAddPannel() {
        const { onhandleOpenTempPlanDetailAddPanel, departments } = this.props
        // 初始化inspectionDptUserList 综管、人事
        const defaultDpts = _.cloneDeep(departments.filter(dpt => [1, 2].includes(dpt.dptId)));

        onhandleOpenTempPlanDetailAddPanel(this.handleAdd.bind(this), null, defaultDpts)
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users,
            // categoryList
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyAlias : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            // case 'inspectType':
            //     value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
            //     break;
            // case 'category':
            //     value = '-'
            //     const mapping = _.find(constant.inspectionDictionaries, { 'code': colValue });
            //     if (mapping && _.find(categoryList, { 'code': mapping.code })) {
            //         value = mapping.name;
            //     }
            //     break;
            case 'user':
                const user = _.find(users.list, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'status':
                if (colValue === 'done') {
                    value = '已完成'
                } else if (colValue === 'active') {
                    value = '进行中'
                } else if (colValue === 'finalized') {
                    value = '未启动'
                } else if (colValue === 'invalid') {
                    value = '已过期'
                } else {
                    value = '无效'
                }
                break;
            default:
            // do nothing
        }
        return value;
    };

    render() {
        return (
            <>
                <FiveSPlanList
                    lists={this.props.planDetails}
                    inspectionDptMapping={this.props.inspectionDptMapping}
                    userProfile={this.props.userProfile}
                    onhandleListMapping={this.handleListMapping.bind(this)}
                    onOpenTempPlanAddPannel={this.handleOpenTempPlanAddPannel.bind(this)}
                    onStart={this.handleStart.bind(this)}
                    onView={this.handleView.bind(this)}
                    onEdit={this.handleEdit.bind(this)}
                    onDelete={this.handleDelete.bind(this)}
                    search={this.props.search}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                />
                {<FiveSTempPlanDetailAddPanel />}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        detailPanelOpen: state.fiveSTempPlanDetailAdd,
        constant: state.constant,
        users: state.user.users,
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        // categoryList: state.inspection.categoryList,
        planDetails: enumSort(['active', 'finalized', 'done', 'invalid'], state.inspectionPlan.planDetails, 'status'),
        currentPlanDetail: state.inspectionPlan.currentPlanDetail,
        // search: state.inspection.search,
        search: state.fiveSPlan.search,
        userProfile: state.auth.userProfile
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        // onhandleGetUserList: (query) => {
        //     dispatch(getUsers(query))
        // },
        onhandleGetPlanDetails: (query) => {
            dispatch(getPlanDetails(query))
        },
        onhandleOpenEdit: (id) => {
            dispatch(routeToFiveSDetailEdit(id));
        },
        onhandleShowRecords: (detail) => {
            dispatch(routeToFiveSRecords(detail));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleSetCurrentPlanDetail: (entity) => {
            dispatch(setCurrentPlanDetail(entity))
        },
        onhandleOpenTempPlanDetailAddPanel: (confirmCallback, closeCallback, defaultDpts) => {
            dispatch(openTempPlanDetailAddPanel(confirmCallback, closeCallback, defaultDpts))
        },
        onhandleCreateTempPlan: (planDetail, callback) => {
            // true => 新建临时计划 & 临时计划详情
            dispatch(addPlanDetail(planDetail, callback, true))
        },
        onhandleChangePlanStatus: (id, status, callback) => {
            dispatch(changePlanStatus(id, status, callback));
        },
        onhandleChangePlanDtlStatus: (id, status, callback) => {
            dispatch(changePlanDetailStatus(id, status, callback));
        },
        onhandleGetPlanResults: (dtlId, callback) => {
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            }, callback))
        },
        onhandleStartPlanDetails: (query, callback) => {
            dispatch(startPlanDetails(query, callback))
        },
        onhandleErrorMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiveSPlanListContainer)
