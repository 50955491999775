import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Tooltip, Typography, CircularProgress
} from '@material-ui/core';
import withStyles from './styles';


const UserCard = props => {
  const {
    // classes,
    isloading,
    currentUser,
    child,
    onhandleOpen
  } = props;

  return (
    <div>
      <Tooltip
        title={
          isloading ? <CircularProgress color='inherit' size={20} />
            :
            <div>
              <Typography>公司:{currentUser.companyName ? currentUser.companyName : ''}</Typography>
              <Typography>部门:{currentUser.dptName ? currentUser.dptName : ''}</Typography>
              <Typography>角色:{currentUser.roleName ? currentUser.roleName : ''}</Typography>
              <Typography>分机:{currentUser.opentext1 ? currentUser.opentext1 : ''}</Typography>
              <Typography>手机:{currentUser.phone ? currentUser.phone : ''}</Typography>
            </div>
        }
        arrow
        placement="left"
        onOpen={(event) => { onhandleOpen() }}
      >
        {child}
      </Tooltip>
    </div >
  );
};

UserCard.propTypes = {
  // classes: PropTypes.object.isRequired,
  isloading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired,
  onhandleOpen: PropTypes.func.isRequired
};

export default withRouter(withStyles(UserCard));
