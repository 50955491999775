import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  childContent: {
    padding: theme.spacing(1, 2)
  },
  areaDetail: {
    // paddingTop: theme.spacing(5)
  },
  flexgrow: {
    flexGrow: 1
  },
  item: {
    padding: 0
  },
  treeView: {
    paddingTop: theme.spacing(1),
  },
  treeIcon: {
    display: 'none',
  },
  areaItem: {
    paddingLeft: theme.spacing(1),
    height: theme.spacing(5)
    // padding: theme.spacing(1)
  },
  errorColor: {
    color: theme.palette.error.main
  },
  card: {
    width: '100%',
    marginBottom: 15
  },
  button: {
    marginRight: theme.spacing(2)
  }
});

export default withStyles(styles);
