import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '80%'
  },
  textAreaField: {
    width: '100%'
  },
  gridContainer: {
    marginTop: 30
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});


export default withStyles(styles);