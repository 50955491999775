import { createAction, handleActions } from 'redux-actions';
import { getDeviceCategoryTree } from './trees';
import { getDeviceUseStatus, getDeviceProCat } from './devices';
import { getCompanyId } from "../../utils/userUtils";
import { createhttpMessage } from './message';
import { getInstallations } from './installation';
import { cleanmodal } from './modal';
import { createloading, cleanloading } from '@/redux/modules/loading';

const porxyApi = '/api'

const initialState = {
    deviceList: {
        total: 0,
        list: []
    },
    search: {
        cat: undefined,
        companyId: undefined,
        installId: "",
        procat: undefined,
        search: "",
        useStatus: undefined,
    },
    selectedDevices: [],
}

export const initDeviceSelectList = createAction('@@xcloud/deviceSelect/initDeviceSelectList',
    () => (dispatch, getState) => {
        dispatch(createloading())
        Promise.all([
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceUseStatus()),
            dispatch(getDeviceProCat()),
            dispatch(resetSearch()),
            dispatch(setSelectedDevices([])),
            dispatch(setDeviceList({ total: 0, list: [] }))
        ]).then((data) => {
            dispatch(cleanloading())
            // dispatch(getDeviceList("-update_time", "", "", "", "", "", "", "", "", "", ""))
        })
            .catch(err => {
                dispatch(cleanloading())
                let msg = '初始化设备搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const getDeviceList = createAction(
    '@@xcloud/deviceSelect/getDeviceList',
    (sort, cat, recursive, extcat, mgtcat, procat,
        search, companyId, installId, useStatus) => (dispatch, getState, httpClient) => {
            dispatch(createloading())
            let searchCond = getState().deviceSelect.search;
            //条件检索
            cat = searchCond.cat ? searchCond.cat.value : cat
            // extcat = searchCond.extcat ? searchCond.extcat : extcat
            // mgtcat = searchCond.mgtcat ? searchCond.mgtcat : mgtcat
            // let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            procat = searchCond.procat ? searchCond.procat.value : procat
            search = searchCond.search ? searchCond.search : search
            companyId = searchCond.companyId ? searchCond.companyId : null;
            installId = searchCond.installId ? searchCond.installId : installId
            useStatus = searchCond.useStatus ? searchCond.useStatus.value : useStatus

            let url = "/devices?offset=0&size=0"

            if (cat && cat !== "") {
                url += "&cat=" + cat
                url += "&recursive=" + true
            }
            if (recursive) {
                url += "&recursive=" + recursive
            }
            if (extcat && extcat !== "") {
                url += "&extcat=" + extcat
            }
            if (mgtcat && mgtcat !== "") {
                url += "&mgtcat=" + mgtcat
            }
            if (procat && procat !== "") {
                url += "&procat=" + procat
            }
            if (search && search !== "") {
                url += "&search=" + encodeURIComponent(search)
            }
            if (companyId && companyId !== "") {
                url += "&companyId=" + companyId
            }
            if (installId && installId !== "") {
                url += "&installId=" + installId
            }
            if (sort && sort !== "") {
                url += "&sort=" + sort
            } else {
                url += "&sort=-update_time"
            }
            if (useStatus && useStatus !== "") {
                url += "&useStatus=" + useStatus
            }

            return httpClient
                .get(porxyApi + url, { headers: { need_loading: true } })
                .then((res) => {
                    dispatch(cleanloading())
                    if (res.status === 200) {
                        dispatch(setDeviceList(res.data));
                        return res.data;
                    }
                })
                .catch(err => {
                    dispatch(cleanloading())
                    let msg = `按照设备类别查找设备失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return [];
                })
        }
);

export const getDeviceListById = createAction(
    '@@xcloud/deviceSelect/getDeviceListById',
    (deviceId) => (dispatch, getState, httpClient) => {
        dispatch(createloading())

        let url = `/devices/${deviceId}`

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading())
                if (res.status === 200) {
                    dispatch(setDeviceList({ total: 1, list: [res.data] }));
                    dispatch(setSelectedDevices([res.data]));
                    return res.data;
                }
            })
            .catch(err => {
                dispatch(cleanloading())
                let msg = `按照设备ID查找设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const resetSearch = createAction('@@xcloud/deviceSelect/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = null
        let parameters = getState().modal.parameters;
        if (parameters.companyId) {
            company = parameters.companyId
        } else {
            company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        }
        dispatch(getInstallations({ companyId: company, size: 0 }))
        let search = getState().deviceSelect.search;
        let newSearch = {
            ...search,
            companyId: company ? company : undefined,
            installId: '',
            cat: undefined,
            procat: undefined,
            search: "",
            useStatus: undefined,
        }
        dispatch(setSearch(newSearch));
    }
);

export const setSearch = createAction('@@xcloud/deviceSelect/setSearch');
export const setDeviceList = createAction('@@xcloud/deviceSelect/setDeviceList');
export const resetInstallation = createAction('@@xcloud/deviceSelect/resetInstallation',
    (companyId) => (dispatch, getState, httpClient) => {
        dispatch(getInstallations({ companyId: companyId, size: 0 }))
    }
);
export const setSelectedDevices = createAction('@@xcloud/deviceSelect/selectedDevices');
export const exitSelected = createAction('@@xcloud/deviceSelect/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedDevices([]))
        dispatch(cleanmodal())
    }
);

export const deviceSelectReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setDeviceList]: (state, { payload: value }) => {
            return {
                ...state,
                deviceList: value
            };
        },
        [setSelectedDevices]: (state, { payload: value }) => {
            return {
                ...state,
                selectedDevices: value
            };
        },
    },
    initialState
);

export default deviceSelectReducer;