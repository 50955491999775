import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api'

const initialState = {
    projects: [],
};

export const setProjects = createAction('@@xcloud/amoeba/setProjects');

export const addProject = createAction('@@xcloud/amoeba/addProject',
    (projectDto, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/projects', projectDto)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "增加工程成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加工程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateProject = createAction('@@xcloud/amoeba/updateProject',
    (updateDto, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/projects/' + updateDto.projectId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "更新工程成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `更新工程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delProject = createAction('@@xcloud/amoeba/delProject',
    (projectId, callback) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/projects/' + projectId)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "删除工程成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除工程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getProjects = createAction('@@xcloud/amoeba/getProjects',
    (projectId, startTime, endTime, ownCompany, serviceId, status) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/projects'
            let strs = []
            if (projectId || startTime || endTime || ownCompany || serviceId || status) {
                if (projectId) strs.push('projectId=' + projectId)
                if (startTime) strs.push('startTime=' + startTime)
                if (endTime) strs.push('endTime=' + endTime)
                if (ownCompany) strs.push('ownCompany=' + ownCompany)
                if (serviceId) strs.push('serviceId=' + serviceId)
                if (status) strs.push('status=' + status)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setProjects(res.data.list))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取工程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const projectPlanReducer = handleActions(
    {
        [setProjects]: (state, { payload: data }) => {
            return {
                ...state,
                projects: data
            }
        },
    },
    initialState
)

export default projectPlanReducer
