import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharingCostComponent from '@/components/AmoebaManagement/SharingCost';
import ResetSharingContainer from '@/containers/AmoebaManagement/SharingCost/ResetSharingContainer';

/**
 * 管理分摊
 */
class SharingCostContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_manager_sharing').action === 'W';
        return (
            <div>
                <SharingCostComponent
                    tabValue={this.state.tabValue}
                    roleFlag={roleFlag}
                    companies={this.props.companies}
                    currentUserInfo={this.props.currentUserInfo}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                ></SharingCostComponent>
                {this.props.param && this.props.param.open && <ResetSharingContainer>
                </ResetSharingContainer>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        companies: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        param: state.amoebaCostSharing.resetSharingParam,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharingCostContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}