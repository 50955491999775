import React from 'react';
import withStyles from './styles';
import { Card } from '@material-ui/core';
import PropTypes from 'prop-types';
import DeviceHeader from "../../../containers/DeviceManagement/DeviceInfo/DeviceHeader";
import DeviceList from "../../../containers/DeviceManagement/DeviceInfo/DeviceList";

const DeviceInfo = props => {

    const { classes } = props;

    return (
        <Card className={classes.root}>
            <DeviceHeader />
            <div className={classes.content}>
                <DeviceList />
            </div>
        </Card>
    );
};
DeviceInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(DeviceInfo);
