import React, { Component } from 'react';
import { connect } from 'react-redux';
import BuInfo from '../../../components/SystemManagement/BuManagement/BuInfo';
import {
    createBu,
    updateBu,
    setCurrentBu,
    backToBuList,
    setBuError,
    setBuChange
} from "../../../redux/modules/bu";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class BuEditContainer extends Component {

    static propTypes = {
        currentBu: PropTypes.object,
        buError: PropTypes.object,
        constants: PropTypes.object,
        buChange: PropTypes.bool
    };

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.buError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleBuChange(false);
            if (this.props.currentBu.unitId) {
                this.props.onhandleUpdateBu(this.props.currentBu);
            } else {
                this.props.onhandleCreateBu(this.props.currentBu);
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleBuChange(true);
        let newBu;
        switch (field) {
            case 'unitName':
                newBu = {
                    ...this.props.currentBu,
                    unitName: event.target.value
                };
                this.props.onhandleUpdateEditBu(newBu);
                break;
            case 'opentext':
                newBu = {
                    ...this.props.currentBu,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditBu(newBu);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToBuList();
    };

    handleCheck = (event, field) => {
        if (!field || field === 'unitName') {
            if (_.trim(this.props.currentBu.unitName) === '') {
                let err = {
                    ...this.props.buError,
                    unitName: '业务分类名称不能为空'
                };
                this.props.onhandleSetError(err);
            } else {
                let err = {
                    ...this.props.buError,
                    unitName: ''
                };
                this.props.onhandleSetError(err);
            }
        }
    };

    render() {
        return (
            <BuInfo
                onhandleSave={this.handleSave.bind(this)}
                currentBu={this.props.currentBu}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                buError={this.props.buError}
                buChange={this.props.buChange}
                constants={this.props.constants}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentBu: state.bu.currentBu,
        buError: state.bu.buError,
        buChange: state.bu.buChange,
        constants:  state.constant.constants
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateBu: (entity) => {
            dispatch(createBu(entity))
        },
        onhandleUpdateBu: (entity) => {
            dispatch(updateBu(entity))
        },
        onhandleUpdateEditBu: (entity) => {
            dispatch(setCurrentBu(entity))
        },
        onhandleBackToBuList: () => {
            dispatch(backToBuList())
        },
        onhandleSetError: (err) => {
            dispatch(setBuError(err))
        },
        onhandleBuChange: (isChanged) => {
            dispatch(setBuChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuEditContainer)
