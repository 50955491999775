import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  title: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  detailtable: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  materialtable: {
    boxShadow: 'none'
  }
});

export default withStyles(styles);
