import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActivitiesInfo from "../../../components/Crm/Activities/ActivitiesInfo/ActivitiesInfo";
import { createMessage } from '../../../redux/modules/message';
import { getNowTimestamp } from "../../../utils/datetime";
import { cleanmodal, createmodal } from '../../../redux/modules/modal';
import { upload, download } from '../../../redux/modules/minio';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import {
  createActivity,
  updateActivity,
  setCurrentActivity,
  setActivityChange,
  initActivityInfo,
  setIsEditable,
  changeActivityStatus,
  getActivityById,
  backToList
} from '../../../redux/modules/customerActivities';
import {
  createCustomerDoc, changeCustomerDocStatus
} from "../../../redux/modules/customerDoc";
import * as _ from 'lodash';
import {
  getMoment
} from '@/utils/datetime';

class ActivitiesInfoContainer extends Component {

  static propTypes = {
    currentActivity: PropTypes.object,
    activityError: PropTypes.object,
    constants: PropTypes.object,
    activityChange: PropTypes.bool,
    customerList: PropTypes.array,
    userProfile: PropTypes.object,
    isEditable: PropTypes.bool,
    statusList: PropTypes.array,
    oppoList: PropTypes.object,
  };

  componentWillMount() {
    this.props.onhandleInitActivityInfo();
  }

  getBucketName() {
    const bucketName = _.replace('dev.{tenant_id}.device.devicedoc', '{tenant_id}', this.props.userProfile.roles[0].tenantId);
    return bucketName;
  }

  checkFrequency() {
    let currentActivity = this.props.currentActivity;
    if (!currentActivity.actName) {
      //机会名称
      this.props.onhandleCheck('请输入活动名称')
      return true
    }
    if (!currentActivity.actType) {
      //机会名称
      this.props.onhandleCheck('请输入活动类型')
      return true
    }
    if (!currentActivity.actHead) {
      //机会名称
      this.props.onhandleCheck('请选择负责人')
      return true
    }

    return false
  }

  handleSave(isEditable) {
    if (!isEditable) {
      this.props.onhandleEditableChange(true);
      return;
    }
    let currentActivity = this.props.currentActivity
    if (this.checkFrequency()) {
      return
    }
    if (this.props.currentActivity.actId) {
      this.props.onhandleUpdateActivity(currentActivity);
    } else {
      this.props.onhandleCreateActivity();
    }
  }

  handleChange = (event, field) => {
    // console.log('handleChangehandleChangehandleChangehandleChange')
    this.props.onhandleActivityChange(true);
    let newActivity;
    switch (field) {
      case 'actName':
        newActivity = {
          ...this.props.currentActivity,
          actName: event.target.value
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'actType':
        newActivity = {
          ...this.props.currentActivity,
          actType: event.target.value
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'actTime':
        newActivity = {
          ...this.props.currentActivity,
          actTime: getMoment()(event).valueOf()
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'actLocation':
        newActivity = {
          ...this.props.currentActivity,
          actLocation: event.target.value
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'budget':
        newActivity = {
          ...this.props.currentActivity,
          budget: _.toNumber(event.target.value)
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'actHead':
        newActivity = {
          ...this.props.currentActivity,
          actHead: event ? event.userId : null
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'customer':
        newActivity = {
          ...this.props.currentActivity,
          customer: event.target.value
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'attendees':
        console.log('event', event);
        const result = event.map(function (user) {
          return user.userId;
        })
        console.log('result', result)
        newActivity = {
          ...this.props.currentActivity,
          attendees: result
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'oid':
        newActivity = {
          ...this.props.currentActivity,
          oid: event ? event.oid : null
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      case 'feedback':
        newActivity = {
          ...this.props.currentActivity,
          feedback: event.target.value
        };
        this.props.onhandleUpdateEditActivity(newActivity);
        break;
      default:
      // do nothing
    }
  };

  // eslint-disable-next-line class-methods-use-this
  onFileSelected(files) {
    // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
    let arr = files[0].name.split('.')
    let fileType = '.' + arr[arr.length - 1]
    let fileTypes = '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg'.split(',')
    let index = _.findIndex(fileTypes, function (o) { return o === fileType; });
    if (index !== -1) {
      return files;
    } else {
      this.props.onhandleUploadPre()
    }
  }

  handleDownload(docLink) {
    this.props.onhandleDownload(docLink, this.getBucketName());
  }

  handleDeleteDoc = async (rowData) => {
    let changeStatus = () => {
      let mybucket = this.getBucketName()
      this.props.onhandleChangeStatus(rowData, mybucket);
    }
    this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除文档[' + rowData.docName + ']吗?', changeStatus, null, false);
  }

  onConfirm(files, isUploadSetter, values) {
    isUploadSetter(true);
    // 开始自定义上传操作

    let offset = getNowTimestamp();
    this.props.onhandleUploadDoc(files, this.getBucketName(), offset, () => {
      let doc = {
        cid: this.props.currentActivity.cid,
        oid: this.props.currentActivity.oid,
        docName: files[0].name,
        docLink: offset + files[0].name,
        status: '1',
        actId: this.props.currentActivity.actId
      };
      this.props.onhandleCreateDoc(doc);
      this.props.onhandleModalClose();
      this.props.onSelectActivity(this.props.currentActivity.actId);
    });
  }

  openUploadModal() {
    // 调用上传组件示例
    this.props.onhandleOpenFileUpload({
      // 上传组件初始化配置
      title: '上传文件',
      note: '请拖拽文件至此处',
      // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
      fileType: '.xls,.xlsx,.doc,.docs',
      // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
      maxSize: '1024MB',
      // 是否支持多文件上传
      multiple: true,
      // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
      onFileSelected: this.onFileSelected.bind(this),
      // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
      onConfirm: this.onConfirm.bind(this),
    });
  }


  handleBack = (event) => {
    this.props.onhandleBackToActivityList();
  };

  handleDelete = (act) => {
    this.props.onhandleDelete(act, "0");
  };

  onEdit = (event) => {
    this.props.onhandleEditableChange(true);
  };

  render() {
    //console.log('currentActivity',this.currentActivity);
    return (
      <div>
        <ActivitiesInfo
          onhandleSave={this.handleSave.bind(this)}
          currentActivity={this.props.currentActivity}
          onhandleChange={this.handleChange.bind(this)}
          onhandleBack={this.handleBack.bind(this)}
          activityError={this.props.activityError}
          activityChange={this.props.activityChange}
          isEditable={this.props.isEditable}
          constants={this.props.constants}
          customerList={this.props.customerList}
          users={this.props.users}
          userProfile={this.props.userProfile}
          onDownLoadDoc={this.handleDownload.bind(this)}
          onhandleDeleteDoc={this.handleDeleteDoc.bind(this)}
          onhandleDelete={this.handleDelete.bind(this)}
          onOpenUploadModal={this.openUploadModal.bind(this)}
          onEdit={this.onEdit.bind(this)}
          statusList={this.props.statusList}
          oppoList={this.props.oppoList} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentActivity: state.customerActivities.currentActivity,
    activityError: state.customerActivities.activityError,
    activityChange: state.customerActivities.activityChange,
    isEditable: state.customerActivities.isEditable,
    constants: state.constant.constants,
    customerList: state.customerActivities.customerList,
    users: state.user.users.list,
    userProfile: state.auth.userProfile,
    statusList: state.customerActivities.bstatusList,
    oppoList: state.customerOppo.oppolist,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitActivityInfo: (query, reload) => {
      dispatch(initActivityInfo());
    },
    onhandleCreateActivity: (entity) => {
      dispatch(createActivity(entity))
    },
    onhandleUpdateActivity: (entity) => {
      dispatch(updateActivity(entity))
    },
    onhandleUpdateEditActivity: (entity) => {
      dispatch(setCurrentActivity(entity))
    },
    onhandleBackToActivityList: () => {
      dispatch(backToList())
    },
    onhandleActivityChange: (isChanged) => {
      dispatch(setActivityChange(isChanged))
    },
    onhandleEditableChange: (isEditable) => {
      dispatch(setIsEditable(isEditable))
    },
    onhandleCheck: (msg) => {
      dispatch(createMessage('error', msg));
    },
    onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
      dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
    },
    onhandleOpenFileUpload: (config) => {
      dispatch(createmodal('commonFileUpload', config));
    },
    onhandleUploadDoc: (files, mybucket, offset, callback) => {
      dispatch(upload(files, mybucket, offset, callback))
    },
    onhandleModalClose: () => {
      dispatch(cleanmodal());
    },
    onhandleCreateDoc: (doc) => {
      dispatch(createCustomerDoc(doc));
    },
    onhandleDownload: (docName, mybucket) => {
      dispatch(download(docName, mybucket))
    },
    onhandleUploadPre: () => {
      dispatch(createMessage('error', '上传的文件格式不支持'));
    },
    onhandleChangeStatus: (entity, mybucket) => {
      dispatch(changeCustomerDocStatus(entity, mybucket));
    },
    onhandleDelete: (act, status) => {
      dispatch(changeActivityStatus(act, status));
    },
    onSelectActivity: (actId) => {
      dispatch(getActivityById(actId));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesInfoContainer)
