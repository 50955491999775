import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import withStyles from './styles';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const MMonthlyDptPlanListComponent = props => {
    const { classes,
        openDptFlag,
        onhandleClose,
        planDpts,
        onhandlePopUpDptUpdate,
        plan,
        outputUnit,
        departments,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '班组',
                field: 'dptId',
                type: 'numeric',
                width: 50,
                render: row => <Typography>{_.find(departments, { 'dptId': row.dptId }).dptName}</Typography>,
                editable: 'never'
            }, {
                title: '数量',
                field: 'output',
                type: 'numeric',
                align: 'left',
                width: 50,
                render: row => <Typography>{row.output ? row.output.toFixed(2) : row.output}</Typography>
            },
            {
                title: '单位',
                field: 'outputUnit',
                width: 50,
                render: row => <Typography>{outputUnit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const assemblyData = () => {
        return planDpts
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={openDptFlag} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h2'>{plan.year}年月度班组计划列表</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <MaterialTable
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={{ ...options, paging: false, toolbar: plan.product ? true : false }}
                            localization={tableLocalization}
                            style={tableStyle}
                            cellEditable={{
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => onhandlePopUpDptUpdate(newValue, oldValue, rowData, columnDef)
                            }}
                            title={<Typography variant='h3'>{plan.product}</Typography>}
                        ></MaterialTable>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MMonthlyDptPlanListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    openFlag: PropTypes.bool,
    onhandleClose: PropTypes.func,
    plaDtls: PropTypes.any,
    onhandlePopUpUpdate: PropTypes.any,
    plan: PropTypes.object,
};

MMonthlyDptPlanListComponent.defaultProps = {
    planDtls: [],
};

export default withStyles(MMonthlyDptPlanListComponent);