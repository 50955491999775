import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Chip,
    Typography,
    Dialog,
    DialogTitle,
    // Card,
    DialogActions,
    IconButton,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import {
    Person,
    AddCircle,
    Settings,
    GroupAdd,
    Close,
} from '@material-ui/icons';
import UserSelectPage from '../../../containers/Utils/UserSelectPage';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../utils/mtable";
import ProcessLineWithUser from '../ProcessLineApprovalComponent';
import { arrayToTree } from "../../../utils/constant";
import * as _ from 'lodash';
import RoleSelectPage from '@/containers/Utils/RoleSelectPage';


const CreateProcessLineApprovalComponent = ({
    classes,
    selectNotice,
    processesline,
    onhandleAdd,
    onhandleRemove,
    showSelect,
    selectType,
    onhandleCallback,
    onhandleChange,
    companyId,
    onhandleConfirm,
    onhandleExit,
    haveProcessSelect,
    onhandleShowProcessList,
    showProcessList,
    predefinedProcess,
    onhandleCloseProcessSelect,
    onhandleSelectProcess,
    processNotices,
    onhandleAddNotice,
    onhandleRemoveNotice,
    onhandleChangeSelectType,
}) => {

    // const renderPermitChip = (nodes) => {
    //     let chip = {
    //         icon: undefined,
    //         style: classes.pendingUser
    //     }
    //     if (nodes.userId) {
    //         chip.style = classes.approvedUser;
    //     }
    //     return chip;
    // }

    const renderNoticeChip = (node) => {

        if (node) {
            if (node.userId) {
                return <Typography className={classes.label}>{node.lastName + node.firstName}</Typography>
            }
            if (node.roleId) {
                return <Typography className={classes.label}>{node.roleName}</Typography>
            }
        }
        return <Typography>{''}</Typography>

    }

    const renderNotices = (nodes) => (
        <React.Fragment>
            {
                nodes.map((node, index) => {
                    // let chip = renderPermitChip(node);
                    return <Grid key={`Foward_${node.id}`}>
                        <Chip
                            key={`user_chip_${node.id}`}
                            variant={'outlined'}
                            size="small"
                            className={classes.chip}
                            // className={chip.style}
                            // color={'primary'}
                            icon={<Person />}
                            label={renderNoticeChip(node)}
                            disabled={node.children && node.children.length > 0}
                            onDelete={event => onhandleRemoveNotice(node.userId)}
                        />
                        {/* <IconButton aria-label="删除"
                            component="span"
                            title='删除'
                            disabled={node.children && node.children.length > 0}
                            onClick={event => onhandleRemoveNotice(node.userId)}
                            color="primary"
                        >
                            <Remove />
                        </IconButton> */}
                    </Grid>
                })
            }
            <Grid>
                <IconButton
                    onClick={event => onhandleAddNotice()}
                    component="span"
                    color="primary"
                    variant="contained"
                    size="small"
                    title='添加'
                    style={{ marginLeft: 16 }}
                >
                    <AddCircle />
                </IconButton>
            </Grid>
        </React.Fragment >
    );

    const renderApproverChip = (node) => {

        if (node) {
            if (node.userId) {
                return <Typography className={classes.label}>{node.lastName + node.firstName}</Typography>
            }
            if (node.roleId) {
                return <Typography className={classes.label}>{node.roleName}</Typography>
            }
        }
        return <Typography>{'请点击选择用户'}</Typography>

    }

    const renderTree = (nodes) => (
        <React.Fragment>
            <Grid className={classes.step}>
                {_.sortBy(nodes, 'children').map((node, index) => {
                    // let chip = renderPermitChip(node);
                    return <Grid key={`Grid_${node.id}`}>
                        {/* {node.preId ?
                            <IconButton aria-label="删除"
                                component="span"
                                title='删除'
                                disabled={node.children && node.children.length > 0}
                                onClick={event => onhandleRemove(node.id, node.preId)}
                                color="primary"
                            >
                                <Remove />
                            </IconButton>
                            : null
                        } */}
                        <Chip
                            key={`user_chip_${node.id}`}
                            variant={'outlined'}
                            size="small"
                            className={classes.chip}
                            // className={chip.style}
                            // color={'primary'}
                            icon={<Person />}
                            label={renderApproverChip(node)}
                            onClick={event => onhandleChange(node.id)}
                            onDelete={node.preId && !(node.children && node.children.length > 0) ? event => onhandleRemove(node.id, node.preId) : null}
                        />
                        {index === 0 ?
                            <IconButton aria-label="添加"
                                onClick={event => onhandleAdd(node.id)}
                                component="span"
                                title='添加'
                                color="primary"
                            >
                                <AddCircle />
                            </IconButton>
                            : null
                        }
                    </Grid>
                })
                }
            </Grid>
            {_.find(nodes, function (o) { return o.children && o.children.length > 0 }) ?
                <React.Fragment>
                    <span className={classes.line}></span>
                    {renderTree(_.find(nodes, function (o) { return o.children }).children)}
                </React.Fragment>
                : null}
        </React.Fragment>
    );


    const renderColumns = () => {
        let columns = [];
        columns.push(
            {
                title: '流程名称',
                field: 'pname',
                render: row => row ? <Typography>{row.pname}</Typography> : '',
                editable: 'never',
                width: '15%'
            },
            {
                title: '流程步骤',
                field: 'processsteps',
                render: row => row ? <ProcessLineWithUser
                    idKey={'stepId'}
                    treeData={arrayToTree(_.cloneDeep(row.processSteps), 'stepId', 'preId')}
                    singleShow={true}
                    processNotices={_.cloneDeep(row.processNoticeTargets)}
                /> : '',
                editable: 'never'
            },
            // {
            //     title: '操作',
            //     align: 'center',
            //     field: 'actions',
            //     render: row => row ? <Button
            //         key={`rule_button_${row.pid}`}
            //         variant={'contained'}
            //         color="primary"
            //         size="small"
            //         startIcon={<Add />}
            //         onClick={() => { onhandleSelectProcess(row) }}
            //     >
            //         {'选择'}
            //     </Button> : '',
            //     editable: 'never',
            //     width: 80,
            // }
        )
        return columns
    }

    const tableOptions = {
        ...options,
        pageSize: 5,
        showTitle: false,
        toolbar: false,
        // selection: true
    }
    const tableStyle = {
        ...style
    }

    const childConfigModal = () => {

        return <Dialog
            open={showProcessList}
            className={classes.modal}
            scroll={'body'}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="customized-dialog-title" onClose={onhandleCloseProcessSelect} disableTypography className={classes.dialogTitle}>
                <Typography component={'span'} variant="h3">{"预设流程选择"}</Typography>
                {onhandleCloseProcessSelect ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleCloseProcessSelect}>
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <Grid className={classes.materialtable}>
                <MaterialTable
                    title=''
                    columns={renderColumns()}
                    data={predefinedProcess.list}
                    options={tableOptions}
                    localization={localization}
                    style={tableStyle}
                    actions={[
                        rowData => ({
                            icon: () => <AddCircle color={'primary'} />,
                            tooltip: '选择',
                            onClick: (event, rowData) => {
                                onhandleSelectProcess(rowData);
                            }
                        }),
                        //     {
                        //         icon: 'close',
                        //         tooltip: '关闭流程选择',
                        //         isFreeAction: true,
                        //         onClick: (event) => {
                        //             onhandleCloseProcessSelect(event);
                        //         },
                        //     }
                    ]}
                >
                </MaterialTable>
            </Grid>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onhandleCloseProcessSelect}
                >
                    取消</Button>
            </DialogActions>
        </Dialog>
    }

    return (<>
        {childConfigModal()}
        <div className={classes.card}>
            <Grid container justify='space-between'>
                <Typography variant="h3" gutterBottom>
                    {"创建流程"}
                </Typography>
                {Boolean(haveProcessSelect) &&
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleShowProcessList()}
                        startIcon={<GroupAdd />}
                    >
                        {'选择预设流程'}
                    </Button>
                }
            </Grid>
            <Grid>
                {Boolean(processesline && processesline.length > 0) && <>
                    {/* <Typography variant="h4" className={classes.subTitle}>
                        {'审批人员'}
                    </Typography> */}
                    <Grid container direction="row" className={classes.gridContainer}>
                        {renderTree(processesline)}
                    </Grid>
                </>}
            </Grid>
            <Grid className={classes.card}>
                <Grid container direction="row" alignItems='center' className={classes.gridContainer}>
                    <Typography variant="h4">
                        {'抄送: '}
                    </Typography>
                    {renderNotices(processNotices)}
                </Grid>
            </Grid>
            {
                !showSelect && <Grid>
                    <Box style={{ height: 450, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid><Typography display='block' variant={'h2'}><Settings color='disabled' className={classes.infoIcon} /></Typography></Grid>
                        <Grid><Typography display='block' variant={'h2'}>点击“+”号，新增审批节点并配置人员</Typography></Grid>
                        <Grid><Typography display='block' variant={'h2'}>或选择预设流程模板</Typography></Grid>
                    </Box>
                </Grid>
            }
            {showSelect && <Grid>
                <RadioGroup row name="selectType" value={selectType} onChange={onhandleChangeSelectType}>
                    <FormControlLabel value="USER" control={<Radio color="primary" />} label={selectNotice ? "抄送用户" : "用户审批"} />
                    <FormControlLabel value="ROLE" control={<Radio color="primary" />} label={selectNotice ? "抄送角色" : "角色审批"} />
                </RadioGroup>
            </Grid>}
            {showSelect && selectType === 'ROLE' ?
                <Grid>
                    <RoleSelectPage innerParam={{
                        company: true,
                        group: true,
                        role: true,
                        userRole: true,
                        roleName: true,
                        innerSelect: true,
                        companyId: companyId && companyId.length > 0 ? companyId : [],
                        callback: (data) => onhandleCallback(data)
                    }} />
                </Grid>
                : null
            }
            {showSelect && selectType === 'USER' ?
                <Grid>
                    <UserSelectPage innerParam={{
                        company: true,
                        group: true,
                        role: true,
                        department: true,
                        userName: true,
                        name: true,
                        innerSelect: true,
                        companyId: companyId && companyId.length > 0 ? companyId : [],
                        callback: (data) => onhandleCallback(data)
                    }} />
                </Grid>
                : null
            }
            <Grid container spacing={0} justify="flex-end">
                <Button variant="outlined" color="primary" style={{ marginRight: '16px' }}
                    onClick={event => onhandleExit()}
                >
                    取消
                </Button>
                <Button variant="contained" color="primary"
                    onClick={event => onhandleConfirm()}
                >
                    确定
                </Button>
            </Grid>
        </div>
    </>)
};
CreateProcessLineApprovalComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateProcessLineApprovalComponent.defaultProps = {

}

export default withStyles(CreateProcessLineApprovalComponent);