import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  detailtable: {
    padding: theme.spacing(0, 0, 2, 0),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  materialtable: {
    boxShadow: 'none'
  },
  item: {
    padding: 0
  },
  formContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 50px)'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  label: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
    width: 50,
    textAlign: 'right'
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  gridItem: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridContainer: {
    // paddingBottom: theme.spacing(1),
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  tabs: {
    minHeight: theme.spacing(4),
    borderBottom: '1px solid #e7e7e7',
    color: '#2979ff',
  },
  tab: {
    fontSize: 14,
    minWidth: 'auto',
    minHeight: theme.spacing(4),
    padding: '6px 18px',
  }
});

export default withStyles(styles);
