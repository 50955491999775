import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    attrList: [], //生产活动属性列表
    activities: [],//生产活动列表
};

export const setActivities = createAction('@@xcloud/manufacture/setActivities');

export const addManufactureActivity = createAction('@@xcloud/manufacture/addActivity',
    (activity) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/activities', activity)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加生产活动成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加生产活动失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManufactureActivity = createAction('@@xcloud/manufacture/updateActivity',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/activities/' + updateDto.activityId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新生产活动成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产活动失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delManufactureActivity = createAction('@@xcloud/manufacture/deleteActivity',
    (activityId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/activities/' + activityId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除生产活动成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除生产活动失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getActivities = createAction('@@xcloud/manufacture/getActivities',
    (activityName, activityType, companyId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/activities'
        let strs = []
        if (activityName || activityType || companyId || status) {
            if (activityName) strs.push('activityName=' + activityName)
            if (activityType) strs.push('activityType=' + activityType)
            if (companyId) strs.push('companyId=' + companyId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setActivities(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产活动列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const manufactureActivityReducer = handleActions(
    {
        [setActivities]: (state, { payload: data }) => {
            return {
                ...state,
                activities: data
            }
        },
    },
    initialState
)

export default manufactureActivityReducer
