import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Tooltip, Typography, CircularProgress
} from '@material-ui/core';
import withStyles from './styles';


const ShowCard = props => {
  const {
    // // classes,
    // isloading,
    currentInfo,
    target,
    child,
    // onhandleOpen
  } = props;

  return (
    <div>
      <Tooltip
        title={
          currentInfo ?
            target === 'DEV' ?
              <div>
                <Typography>名称:{currentInfo.deviceName ? currentInfo.deviceName : ''}</Typography>
                <Typography>编号:{currentInfo.deviceNo ? currentInfo.deviceNo : ''}</Typography>
                <Typography>位号:{currentInfo.tagNo ? currentInfo.tagNo : ''}</Typography>
                <Typography>所属装置:{currentInfo.installTitle ? currentInfo.installTitle : ''}</Typography>
              </div>
              :
              target === 'ACC' ?
                <div>
                  <Typography>名称:{currentInfo.accName ? currentInfo.accName : ''}</Typography>
                  <Typography>编号:{currentInfo.accNo ? currentInfo.accNo : ''}</Typography>
                  <Typography>所属设备名称:{currentInfo.deviceName ? currentInfo.deviceName : ''}</Typography>
                  <Typography>所属设备编号:{currentInfo.deviceNo ? currentInfo.deviceNo : ''}</Typography>
                </div>
                :
                <div>
                  <Typography>名称:{currentInfo.name ? currentInfo.name : ''}</Typography>
                </div>
            : <CircularProgress color='inherit' size={20} />
        }
        arrow
        placement="left"
      // onOpen={(event) => { onhandleOpen() }}
      >
        {child}
      </Tooltip>
    </div >
  );
};

ShowCard.propTypes = {
  // classes: PropTypes.object.isRequired,
  target: PropTypes.string.isRequired,
  currentInfo: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired,
  // onhandleOpen: PropTypes.func.isRequired
};

export default withRouter(withStyles(ShowCard));
