import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ShiftRuleListComponent from '@/components/ManufactureManagement/ManufactureShiftRule/ShiftRuleList/ShiftRuleListComponent';
import ShiftRuleInfoContainer from '../ManufactureShiftRule/ShiftRuleInfoContainer';
import { getShiftRules, getShiftRule, deleteShiftRule, createManufactureShift } from '@/redux/modules/manufacture/manufactureShiftRule';
import { judgeMainCompany } from '@/utils/manufactureUtils'
import { MANUFACTURE_DICT_TYPES, filterAmoebaCompany } from '@/utils/constant'

import * as _ from 'lodash';

class ShiftRuleListContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            isMainCompany: judgeMainCompany(this.props.companies, this.props.currentUserInfo.companyId),
            selectedCompanyId: 0,
            selectedCompanyName: "",
            companies: [],
            open: false,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        // const {currentUserInfo,companies} = this.props;
        // let flag = judgeMainCompany(companies, currentUserInfo.companyId);
        const companyList = this.getCompanyList();
        // if(flag){
        this.props.getShiftRules(companyList[0].companyId);
        this.setState({ selectedCompanyId: companyList[0].companyId, selectedCompanyName: companyList[0].companyName });
        // }else{
        //     this.setState({selectedCompanyId:currentUserInfo.companyId})
        //     this.props.getShiftRules(currentUserInfo.companyId);
        // }

    }

    getCompanyList = () => {
        const { companies } = this.props;
        let newcompanies = []
        if (this.state.isMainCompany) {
            newcompanies = _.filter(companies, item => {
                return item.pid
            });
        } else {
            newcompanies = _.filter(companies, item => {
                return item.companyId === this.props.currentUserInfo.companyId
            });
        }

        return newcompanies
    }

    handleUpdate = (newData) => {
        this.setState({
            shiftRule: newData
        }, function () {
            this.setState({ open: true });
        });
    }

    handleAdd = (newData) => {
        this.setState({
            open: true
        })
    }

    handleDelete = (oldData) => {
        return this.props.deleteShiftRule(oldData.ruleId).then((res) => {
            if (res) {
                this.props.getShiftRules(this.state.selectedCompanyId);
            }
        });
    }

    onhandleClose = () => {
        this.setState({
            open: false, shiftRule: {}
        })
        this.props.getShiftRules(this.state.selectedCompanyId);
    }

    handleChangeCompany(event) {
        const company = _.find(this.props.companies, item => {
            return item.companyId === event.target.value
        });
        this.setState({ selectedCompanyId: event.target.value, selectedCompanyName: company ? company.companyName : "" })
        this.props.getShiftRules(event.target.value);
    }

    handleCreateShift() {
        this.props.createShift(this.state.selectedCompanyId);
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'manufacture_shiftrule_config').action === 'W';
        return (
            <div>
                <ShiftRuleListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleChangeCompany={this.handleChangeCompany.bind(this)}
                    onhandleCreateShift={this.handleCreateShift.bind(this)}
                    constant={this.props.constant}
                    roleFlag={roleFlag}
                    currentUserInfo={this.props.currentUserInfo}
                    shiftRuleList={this.props.shiftRuleList}
                    isMainCompany={this.state.isMainCompany}
                    companies={this.getCompanyList()}
                    shiftTypeDicts={this.props.shiftTypeDicts}
                    selectedCompanyId={this.state.selectedCompanyId}
                    selectedCompanyName={this.state.selectedCompanyName}
                />
                {
                    this.state.open && <ShiftRuleInfoContainer
                        open={this.state.open}
                        selectedCompanyId={this.state.selectedCompanyId}
                        shiftRule={this.state.shiftRule}
                        onhandleClose={this.onhandleClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        shiftRuleList: state.manufactureShiftRule.shiftRuleList,
        constant: state.constant,
        companies: filterAmoebaCompany(state.company.companyList.list),
        shiftTypeDicts: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.SHIFT_TYPE
        }),
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getShiftRules: (companyId, dptId, startTime, status) => {
            dispatch(getShiftRules(companyId, dptId, startTime, status))
        },
        deleteShiftRule: (ruleId) => {
            return dispatch(deleteShiftRule(ruleId));
        },
        getShiftRule: (ruleId) => {
            return dispatch(getShiftRule(ruleId));
        },
        createShift: (companyId) => {
            return dispatch(createManufactureShift(companyId));
        }

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftRuleListContainer);

export const getRole = (roleId, userProfile) => {
    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}