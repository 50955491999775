import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import {
    AMOEBA_SUB_TYPE_INCOME,
    AMOEBA_SUB_TYPE_VARIABLEFEE,
    AMOEBA_SUB_TYPE_HR,
} from '@/utils/manufactureUtils'

const porxyApi = '/api'

const initialState = {
    indexes: [],//阿米巴指标列表
    subs: [],//阿米巴指标列表，登录时就获取
    incomeIndexes: [],
    variableFeeIndexes: [],
    hrIndexes: [],
    variableBudgetConfigs: [],
    variableBudgetConfig: {},
    budgetVariableSubs: [],//阿米巴预算变动费指标
};

export const setIndexes = createAction('@@xcloud/amoeba/setIndexes');
export const setSubs = createAction('@@xcloud/amoeba/setSubs');

export const setIncomeIndexes = createAction('@@xcloud/amoeba/setIncomeIndexes');
export const setVariableFeeIndexes = createAction('@@xcloud/amoeba/setVariableFeeIndexes');
export const setHrIndexes = createAction('@@xcloud/amoeba/setHrIndexes');

export const setVariableBudgetConfigs = createAction('@@xcloud/amoeba/setVariableBudgetConfigs');
export const setBudgetVariableSubs = createAction('@@xcloud/amoeba/setBudgetVariableSubs');

export const addAmoebaIndex = createAction('@@xcloud/amoeba/addIndex',
    (subDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/indexes', subDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加阿米巴指标成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加阿米巴指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateAmoebaIndex = createAction('@@xcloud/amoeba/updateIndex',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/indexes/' + updateDto.subId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新阿米巴指标成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `更新阿米巴指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delAmoebaIndex = createAction('@@xcloud/amoeba/deleteIndex',
    (subId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/indexes/' + subId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除阿米巴指标成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除阿米巴指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getAmoebaSubs = createAction('@@xcloud/amoeba/getIndexes',
    () => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/indexes'
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                dispatch(setSubs(res.data))
            }
            return res.data;
        }).catch(err => {
            let msg = `获取阿米巴指标列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getAmoebaIndexes = createAction('@@xcloud/amoeba/getIndexes',
    (subId, parentId, isPlan, subBu, subType, subLevel, gatherType, subCode) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/indexes'
            let strs = []
            if (subId || parentId || isPlan || subBu || subType || subLevel || gatherType || subCode) {
                if (subId) strs.push('subId=' + subId)
                if (parentId) strs.push('parentId=' + parentId)
                if (isPlan) strs.push('isPlan=' + isPlan)
                if (subBu) strs.push('subBu=' + subBu)
                if (subType) strs.push('subType=' + subType)
                if (subLevel) strs.push('subLevel=' + subLevel)
                if (gatherType) strs.push('gatherType=' + gatherType)
                if (subCode) strs.push('subCode=' + subCode)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setIndexes(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴指标列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getAmoebaIndexesByType = createAction('@@xcloud/amoeba/getIndexesByType',
    (type) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/indexes/type/' + type

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    if (type === AMOEBA_SUB_TYPE_INCOME) {
                        dispatch(setIncomeIndexes(res.data));
                    } else if (type === AMOEBA_SUB_TYPE_VARIABLEFEE) {
                        dispatch(setVariableFeeIndexes(res.data));
                    } else if (type === AMOEBA_SUB_TYPE_HR) {
                        dispatch(setHrIndexes(res.data));
                    }
                }
                return res.data;
            }).catch(err => {
                let msg = `通过类型获取阿米巴指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const getVariableBudgetConfigs = createAction('@@xcloud/amoeba/getVariableBudgetConfigs',
    (companyId, subId, budgetType, status) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/indexes/variable/budget/configs'
            let strs = []
            if (subId || companyId || budgetType || status) {
                if (subId) strs.push('subId=' + subId)
                if (companyId) strs.push('companyId=' + companyId)
                if (budgetType) strs.push('budgetType=' + budgetType)
                if (status) strs.push('status=' + status)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setVariableBudgetConfigs(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴指标变动费预算配置列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getBudgetVariableSubs = createAction('@@xcloud/amoeba/getBudgetVariableSubs',
    () => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/indexes/budget/variables'
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                dispatch(setBudgetVariableSubs(res.data))
            }
            return res.data;
        }).catch(err => {
            let msg = `获取阿米巴预算变动费指标失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });
export const getVariableBudgetConfig = createAction('@@xcloud/amoeba/getVariableBudgetConfig',
    (configId) => (dispatch, getState, httpClient) => {

        let url = porxyApi + '/amoeba/indexes/variable/budget/configs/' + configId

        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
            }
            return res.data;
        }).catch(err => {
            let msg = `获取阿米巴指标变动费预算配置列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addVariableBudgetConfig = createAction('@@xcloud/amoeba/addVariableBudgetConfig',
    (configDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/indexes/variable/budget/configs', configDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加变动费指标预算配置成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加阿米巴变动费指标预算配置指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const updateVariableBudgetConfig = createAction('@@xcloud/amoeba/updateIndex',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/indexes/variable/budget/configs/' + updateDto.configId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新变动费指标预算配置指标成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `更新变动费指标预算配置指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delVariableBudgetConfig = createAction('@@xcloud/amoeba/deleteVariableBudgetConfig',
    (configId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/indexes/variable/budget/configs/' + configId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除阿米巴变动费指标预算配置指标成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除阿米巴变动费指标预算配置指标失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const initVariableCofig = createAction('@@xcloud/amoeba/variable/initVariableConfig',
    (companyId, callback) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(getVariableBudgetConfigs(companyId)),
            dispatch(getBudgetVariableSubs()),
        ]).then(data => {
            if (data && callback) {
                callback(data)
                return data
            }
        }).catch(err => {
            console.log(err)
            let msg = '初始化变动费指标配置页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        })
    });

export const amoebaIndexReducer = handleActions(
    {
        [setSubs]: (state, { payload: data }) => {
            return {
                ...state,
                subs: data
            }
        },
        [setIndexes]: (state, { payload: data }) => {
            return {
                ...state,
                indexes: data
            }
        },
        [setIncomeIndexes]: (state, { payload: data }) => {
            return {
                ...state,
                incomeIndexes: data
            }
        },
        [setVariableFeeIndexes]: (state, { payload: data }) => {
            return {
                ...state,
                variableFeeIndexes: data
            }
        },
        [setHrIndexes]: (state, { payload: data }) => {
            return {
                ...state,
                hrIndexes: data
            }
        },

        [setVariableBudgetConfigs]: (state, { payload: data }) => {
            return {
                ...state,
                variableBudgetConfigs: data
            }
        },

        [setBudgetVariableSubs]: (state, { payload: data }) => {
            return {
                ...state,
                budgetVariableSubs: data
            }
        },



    },
    initialState
)

export default amoebaIndexReducer
