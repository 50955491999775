import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Link,
    Typography,
    Button,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    Grid
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getMoment as moment } from '@/utils/datetime';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

const ChecklistEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleSelectChange,
        onhandleBack,
        onhandleCheck,
        onGoToRegulation,
        showNoSpecify,
        // currentUserInfo,
        currentInventory,
        currentChecklist,
        // companyList,
        departmentList,
        // listTypeList,
        categoryList,
        rootCauseList,
        methodList,
        disciplineList,
        resultTypeList,
        regulationList,
        roleList,
        editable,
        checklistError,
        checklistChange,
        onhandleListMapping,
        // inspectionDptMapping,
    } = props;

    // console.log('currentChecklist currentInventory checklistError')
    // console.log(currentChecklist)
    // console.log(currentInventory)
    // console.log(checklistError)


    // const listTypeOptions = () => (
    //     <React.Fragment>
    //         {listTypeList ?
    //             listTypeList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const showCategory = _.find(categoryList, { 'pcode': currentChecklist.listType });
    // const categoryOptions = () => {
    //     // 过滤出当前用户可访问的子分类完整信息列表
    //     let filteredCategoryList = [];
    //     categoryList.filter(item => (item.pcode === currentChecklist.listType)).forEach((cat) => {
    //         inspectionDptMapping.forEach((accessbleCat) => {
    //             if (accessbleCat.inspectionCode === cat.code) {
    //                 filteredCategoryList.push(cat);
    //             }
    //         })
    //     });

    //     return (
    //         <React.Fragment>
    //             {filteredCategoryList ?
    //                 filteredCategoryList.map(data => (
    //                     <option value={data.code} key={data.code}>{data.name}</option>
    //                 )) : null
    //             }
    //         </React.Fragment>
    //     )
    // }

    // const companyOptions = () => (
    //     <React.Fragment>
    //         {companyList ?
    //             companyList.map(data => (
    //                 <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const departmentOptions = () => (
        <React.Fragment>
            {departmentList ?
                departmentList
                    .filter(data => (data.companyId === currentInventory.companyId || (showNoSpecify && !currentInventory.companyId)))
                    .sort((a, b) => b.companyPid ? -1 : 1)
                    .map(data => (
                        <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
                    )) : null
            }
        </React.Fragment>
    )

    const selectedDpt = _.find(departmentList, { 'dptId': _.toInteger(currentChecklist.dptId) });
    const showRoleSelect = selectedDpt && selectedDpt.companyPid && selectedDpt.companyPid !== '';
    const roleOptions = () => (
        <React.Fragment>
            {roleList ?
                roleList
                    .filter(item => (item.companyId === currentChecklist.companyId))
                    .map(data => (
                        <option value={data.code} key={data.code}>{data.name}</option>
                    )) : null
            }
        </React.Fragment>
    )

    const rootCauseOptions = () => (
        <React.Fragment>
            {rootCauseList ?
                rootCauseList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const methodOptions = () => (
        <React.Fragment>
            {methodList ?
                methodList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const disciplineOptions = () => (
        <React.Fragment>
            {disciplineList ?
                disciplineList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const showScoreField = Boolean(currentChecklist.resultType && currentChecklist.resultType === 'SCORE');
    const resultTypeOptions = () => (
        <React.Fragment>
            {resultTypeList ?
                resultTypeList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    return (
        <div>
            <Card className={classes.card}>

                <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                    <Grid item xs={6}>
                        <Typography variant="h3" gutterBottom>
                            {currentChecklist.listId ? "检查项详情" : "新建检查项"}
                            {currentInventory.inventoryName ? " - " + currentInventory.inventoryName : null}
                        </Typography>
                    </Grid>
                    {currentChecklist.listId ? (
                        <Grid item margin={0}>
                            <Typography variant="h5" style={{ display: 'inline-block' }}>
                                {'编号: ' + currentChecklist.serialNo}</Typography>
                        </Grid>) : null
                    }
                </Grid>

                {/* {currentChecklist.listId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                编号
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography gutterBottom>
                                {currentChecklist.serialNo}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                } */}

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            排查分类
                            {/* <span className={classes.required}> *</span> */}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography gutterBottom>
                            {onhandleListMapping(currentChecklist.listType, 'inspectionDict')}
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <FormControl className={classes.formControl} disabled={!editable}
                            error={checklistError.listType !== ''}
                        >
                            <Select
                                style={{ width: '90%' }}
                                native
                                value={currentChecklist.listType}
                                onChange={(event) => onhandleSelectChange(event, 'listType')}
                            >
                                <option aria-label="None" value="" />
                                {listTypeOptions()}
                            </Select>
                            <FormHelperText>{checklistError.listType}</FormHelperText>
                        </FormControl>
                    </Grid> */}
                    {showCategory ? (
                        <>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    子分类
                                    {/* <span className={classes.required}> *</span> */}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {onhandleListMapping(currentChecklist.catCode, 'inspectionDict')}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={3}>
                                    <FormControl className={classes.formControl} disabled={!editable}
                                        error={checklistError.catCode !== ''}
                                    >
                                        <Select
                                            style={{ width: '90%' }}
                                            native
                                            value={currentChecklist.catCode}
                                            onChange={(event) => onhandleSelectChange(event, 'category')}
                                        >
                                            <option aria-label="None" value="" />
                                            {categoryOptions()}
                                        </Select>
                                        <FormHelperText>{checklistError.catCode}</FormHelperText>
                                    </FormControl>
                                </Grid> */}
                        </>) : null
                    }
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            检查部门
                            <span className={classes.required}> *</span>
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.dptId, 'department')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}
                                error={checklistError.department !== ''}
                            >
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.dptId || 0}
                                    onChange={(event) => onhandleSelectChange(event, 'department')}
                                >
                                    {showNoSpecify && <option aria-label="None" label="不限" value={0} />}
                                    {departmentOptions()}
                                </Select>
                                <FormHelperText id="component-error-text">{(!currentChecklist.dptId || currentChecklist.dptId === '') && '注意：本检查项允许分发给所有部门/分厂'}</FormHelperText>
                            </FormControl>
                        </Grid>
                    )}
                    {showRoleSelect ? (
                        <>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    基层责任角色
                                    <span className={classes.required}> *</span>
                                </Typography>
                            </Grid>
                            {!editable ? (
                                <Grid item xs={3}>
                                    <Typography gutterBottom>
                                        {onhandleListMapping(currentChecklist.roleId, 'role')}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl} disabled={!editable}
                                        error={checklistError.role !== ''}
                                    >
                                        <Select
                                            style={{ width: '90%' }}
                                            native
                                            value={currentChecklist.roleId}
                                            onChange={(event) => onhandleSelectChange(event, 'role')}
                                        >
                                            <option aria-label="None" label="不限" value="" />
                                            {roleOptions()}
                                        </Select>
                                        <FormHelperText>{(!currentChecklist.roleId || currentChecklist.roleId === '') && '注意：本检查项允许分发给所有基层角色'}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                        </>) : null
                    }
                    {/* <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            所属单位
                                <span className={classes.required}> *</span>
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.companyId, 'company')}
                            </Typography>
                        </Grid>
                    ) : (
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl} disabled={!editable}
                                    error={checklistError.company !== ''}
                                >
                                    <Select
                                        style={{ width: '90%' }}
                                        native
                                        value={currentChecklist.companyId}
                                        onChange={(event) => onhandleSelectChange(event, 'company')}
                                    >
                                        <option aria-label="None" value="" />
                                        {companyOptions()}
                                    </Select>
                                    <FormHelperText>{checklistError.company}</FormHelperText>
                                </FormControl>
                            </Grid>
                        )} */}
                </Grid>

                {/* <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography  gutterBottom className={classes.label}>
                            业务线
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography  gutterBottom>
                                {onhandleListMapping(currentChecklist.unitId, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl} disabled={!editable}>
                                    <Select
                                        style={{ width: '90%' }}
                                        native
                                        value={currentChecklist.unitId}
                                        onChange={(event) => onhandleSelectChange(event, 'unit')}
                                    >
                                        <option aria-label="None" value="" />
                                        {unitOptions()}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                    <Grid item xs={1}>
                        <Typography  gutterBottom className={classes.label}>
                            潜在隐患级别
                            </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography  gutterBottom>
                                {onhandleListMapping(currentChecklist.severity, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl} disabled={!editable}
                                >
                                    <Select
                                        style={{ width: '90%' }}
                                        native
                                        value={currentChecklist.severity}
                                        onChange={(event) => onhandleSelectChange(event, 'severity')}
                                    >
                                        <option aria-label="None" value="" />
                                        {severityOptions()}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                </Grid> */}

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            根因
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.rootCause, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.rootCause || ''}
                                    onChange={(event) => onhandleSelectChange(event, 'rootCause')}
                                >
                                    <option aria-label="None" value="" />
                                    {rootCauseOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            纪律类别
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.discipline, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.discipline || ''}
                                    onChange={(event) => onhandleSelectChange(event, 'discipline')}
                                >
                                    <option aria-label="None" value="" />
                                    {disciplineOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>

                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            结果类型 <span className={classes.required}> *</span>
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.resultType, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}
                                error={checklistError.resultType !== ''}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.resultType}
                                    onChange={(event) => onhandleSelectChange(event, 'resultType')}
                                >
                                    {/* <option aria-label="None" value="" /> */}
                                    {resultTypeOptions()}
                                </Select>
                                <FormHelperText id="component-error-text">{checklistError.resultType}</FormHelperText>
                            </FormControl>
                        </Grid>
                    )}
                    {showScoreField && <>
                        <Grid item xs={1}>
                            <Typography gutterBottom className={classes.label}>
                                分值 <span className={classes.required}> *</span>
                            </Typography>
                        </Grid>
                        {!editable ? (
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {currentChecklist.score || '未设置'}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={3}>
                                <TextField
                                    variant='standard'
                                    type='number'
                                    className={classes.textarea}
                                    error={checklistError.score !== ''}
                                    helperText={checklistError.score || ''}
                                    value={currentChecklist.score || ''}
                                    onChange={event => onhandleChange(event, 'score')}
                                    onBlur={event => onhandleCheck(event, 'score')}
                                />
                            </Grid>
                        )}
                    </>}
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            需要描述？
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {currentChecklist.descRequired ? '是' : '否'}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.descRequired}
                                    onChange={(event) => onhandleSelectChange(event, 'descRequired')}
                                >
                                    <option value={false} key={'descRequired-false'}>{'否'}</option>
                                    <option value={true} key={'descRequired-true'}>{'是'}</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            需要拍照？
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {currentChecklist.imageRequired ? '是' : '否'}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.imageRequired}
                                    onChange={(event) => onhandleSelectChange(event, 'imageRequired')}
                                >
                                    <option value={false} key={'imageRequired-false'}>{'否'}</option>
                                    <option value={true} key={'imageRequired-true'}>{'是'}</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            检查手段
                        </Typography>
                    </Grid>
                    {!editable ? (
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {onhandleListMapping(currentChecklist.method, 'inspectionDict')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} disabled={!editable}>
                                <Select
                                    style={{ width: '90%' }}
                                    native
                                    value={currentChecklist.method}
                                    onChange={(event) => onhandleSelectChange(event, 'method')}
                                >
                                    <option aria-label="None" value="" />
                                    {methodOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            参考规章制度 </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} disabled={!editable} >
                            {!editable ? (
                                <Grid item xs={5}>
                                    <Link
                                        component="button"
                                        onClick={() => {
                                            console.info(" =go regulation page= ", currentChecklist.regId);
                                            onGoToRegulation(currentChecklist.regId)
                                        }}
                                    >{onhandleListMapping(currentChecklist.regId, 'regulation')}</Link>
                                </Grid>) : (
                                <Autocomplete
                                    style={{ width: '90%' }}
                                    disabled={!editable}
                                    options={regulationList}
                                    onChange={(event, newValue) => onhandleSelectChange(newValue, 'regulation')}
                                    getOptionLabel={(option) => { return option.regName || '' }}
                                    getOptionSelected={(option, value) => option.regId === value.regId}
                                    noOptionsText='无可选规章制度'
                                    value={_.find(regulationList, { 'regId': currentChecklist.regId }) || null}
                                    renderInput={(params) => <TextField {...params} placeholder="搜索并选择规章制度名称" variant="standard" />}
                                />
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            检查内容
                            <span className={classes.required}> *</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            multiline
                            variant='outlined'
                            error={checklistError.problem !== ''}
                            helperText={checklistError.problem || ''}
                            className={classes.textarea}
                            value={currentChecklist.problem || ''}
                            InputProps={{
                                readOnly: !editable
                            }}
                            rows={5}
                            onChange={event => onhandleChange(event, 'problem')}
                            onBlur={event => onhandleCheck(event, 'problem')}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            参考信息
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            multiline
                            variant='outlined'
                            InputProps={{
                                readOnly: !editable
                            }}
                            className={classes.textarea}
                            value={currentChecklist.reference || ''}
                            rows={5}
                            onChange={event => onhandleChange(event, 'reference')}
                            onBlur={event => onhandleCheck(event, 'reference')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    {
                        !editable && currentChecklist.attr1 && currentChecklist.attr1 !== '' &&
                        (<>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    内容范畴</Typography>
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom>
                                    {currentChecklist.attr1 + (currentChecklist.attr2 ? ' - ' + currentChecklist.attr2 : '')}
                                </Typography>
                            </Grid>
                        </>)
                    }
                    {
                        editable &&
                        (<>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    内容范畴</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant='standard'
                                    className={classes.textarea}
                                    value={currentChecklist.attr1 || ''}
                                    rows={5}
                                    onChange={event => onhandleChange(event, 'attr1')}
                                />
                            </Grid>

                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    内容子范畴</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant='standard'
                                    className={classes.textarea}
                                    value={currentChecklist.attr2 || ''}
                                    rows={5}
                                    onChange={event => onhandleChange(event, 'attr2')}
                                />
                            </Grid>
                        </>)
                    }
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            备注
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            multiline
                            variant='outlined'
                            InputProps={{
                                readOnly: !editable
                            }}
                            className={classes.textarea}
                            value={currentChecklist.opentext || ''}
                            rows={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                            onBlur={event => onhandleCheck(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

                {currentChecklist.listId ? (
                    <>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    创建人
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {currentChecklist.creatorLastName + currentChecklist.creatorFirstName}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    创建时间
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {moment().utc(currentChecklist.createTime).format('yyyy/MM/DD HH:mm:ss')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>) : null
                }

                {currentChecklist.listId && currentChecklist.updateTime ? (
                    <>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    更新人
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {currentChecklist.revisorLastName + currentChecklist.revisorFirstName}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    更新时间
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography gutterBottom>
                                    {moment().utc(currentChecklist.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>) : null
                }

                {currentChecklist.listId && currentChecklist.revisor ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                    </Grid>) : null
                }

            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}>
                        {editable && currentChecklist.listId ? '取消' : '返回'}
                    </Button>
                </Grid>
                {editable ? (
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleSave(event)}
                            disabled={!checklistChange}>
                            保存</Button>
                    </Grid>) : null
                }

            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={checklistChange} />
        </div>
    );
};

ChecklistEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onGoToRegulation: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    currentChecklist: PropTypes.object.isRequired,
    checklistError: PropTypes.object.isRequired,
    checklistChange: PropTypes.bool.isRequired,
    companyList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    listTypeList: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    rootCauseList: PropTypes.array.isRequired,
    methodList: PropTypes.array.isRequired,
    disciplineList: PropTypes.array.isRequired,
    resultTypeList: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired
};

export default withStyles(ChecklistEdit);
