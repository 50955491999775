import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';
import DeviceOpsRecord from "@/components/DeviceManagement/DeviceOpsRecord";
import DeviceOpsRecordHeader from "@/components/DeviceManagement/DeviceOpsRecord/DeviceOpsRecordHeader";
import PropTypes from "prop-types";
import {
    setPageSize, getDeviceOpsRecordList, initDeviceOpsRecordList,
    setSearch, resetSearch, resetSearchField, exportDeviceOpsRecordList
} from '@/redux/modules/deviceOpsRecord';
import { cleanmodal } from '@/redux/modules/modal';
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import { FilePreviewer } from '@/components/SimpleComponents';
import { getFileExt } from '@/utils/fileUtils';
import { downloadURL } from '@/redux/modules/minio';
import _ from 'lodash';


// import { getMoment } from '@/utils/datetime';
// import * as _ from 'lodash';

class DeviceOpsRecordContainer extends Component {
    static propTypes = {
        oriListData: PropTypes.object,
        initListData: PropTypes.object,
        userProfile: PropTypes.object,
        pageSize: PropTypes.number,
        isLoading: PropTypes.bool,
        companyList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            assignmentTableOpen: false,
            currentWorkOrder: {},
            currentWOE: [],
            previewFile: {
                open: false,
                fileType: '',
                fileURL: '',
            }
        }
    }

    // 页面初始化
    // componentDidMount() {
    //     this.props.onInitDeviceOpsRecordList();
    // }
    componentWillMount() {
        this.props.onInitDeviceOpsRecordList();
    }

    // 设备信息文本检索
    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    handleCheck = (value, field) => {
        let newSearch;
        switch (field) {
            case 'onlyMe':
                newSearch = {
                    ...this.props.search,
                    onlyMe: value.target.checked
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    // 时间选择
    handleDateChange = (value, field) => {
        let newSearch;
        switch (field) {
            case 'fromDate':
                newSearch = {
                    ...this.props.search,
                    fromDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                this.props.onGetDeviceOpsRecordList({ fromDate: value });
                break;
            case 'toDate':
                newSearch = {
                    ...this.props.search,
                    toDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                this.props.onGetDeviceOpsRecordList({ toDate: value });
                break;
            default:
            // do nothing
        }
    };

    // 检索条件（改变）
    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                //联动项目
                this.props.onhandleResetSearchField(value.target.value, 'companyId');
                break;
            case 'installId':
                newSearch = {
                    ...this.props.search,
                    installId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                //联动项目
                this.props.onhandleResetSearchField(value.target.value, 'installId');
                break;
            case 'cat':
                newSearch = {
                    ...this.props.search,
                    cat: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'useStatus':
                newSearch = {
                    ...this.props.search,
                    useStatus: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'extcat':
                newSearch = {
                    ...this.props.search,
                    extcat: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            // case 'view':
            //     let fromDate = getMoment()().startOf('week').format("YYYY/MM/DD");
            //     let toDate = getMoment()().endOf('week').format("YYYY/MM/DD");
            //     switch (value.target.value) {
            //         case 'year':
            //             fromDate = getMoment()().startOf('year').format("YYYY/MM/DD");
            //             toDate = getMoment()().endOf('year').format("YYYY/MM/DD");
            //             break;
            //         case 'month':
            //             fromDate = getMoment()().startOf('month').format("YYYY/MM/DD");
            //             toDate = getMoment()().endOf('month').format("YYYY/MM/DD");
            //             break;
            //         case 'week':
            //             fromDate = getMoment()().startOf('week').format("YYYY/MM/DD");
            //             toDate = getMoment()().endOf('week').format("YYYY/MM/DD");
            //             break;
            //         case 'day':
            //             fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            //             toDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            //             break;
            //         default:
            //     }
            //     newSearch = {
            //         ...this.props.search,
            //         intervalDate: {
            //             view: value.target.value,
            //             fromDate: fromDate,
            //             toDate: toDate
            //         }
            //     }
            //     this.props.onhandleUpdateEditSearch(newSearch);
            //     // 如果Popup画面调用：触发检索
            //     if (this.props.parameters && this.props.parameters.deviceId) {
            //         this.props.onGetDeviceOpsRecordList();
            //     }
            //     break;
            // case 'day':
            //     newSearch = {
            //         ...this.props.search,
            //         intervalDate: {
            //             view: this.props.search.intervalDate.view,
            //             fromDate: getMoment()(value).startOf('day').format("YYYY/MM/DD"),
            //             toDate: getMoment()(value).endOf('day').format("YYYY/MM/DD"),
            //         }
            //     }
            //     this.props.onhandleUpdateEditSearch(newSearch);
            //     break;
            // case 'week':
            //     newSearch = {
            //         ...this.props.search,
            //         intervalDate: {
            //             view: this.props.search.intervalDate.view,
            //             fromDate: getMoment()(value).startOf('week').format("YYYY/MM/DD"),
            //             toDate: getMoment()(value).endOf('week').format("YYYY/MM/DD"),
            //         }
            //     }
            //     this.props.onhandleUpdateEditSearch(newSearch);
            //     break;
            // case 'month':
            //     newSearch = {
            //         ...this.props.search,
            //         intervalDate: {
            //             view: this.props.search.intervalDate.view,
            //             fromDate: getMoment()(value).startOf('month').format("YYYY/MM/DD"),
            //             toDate: getMoment()(value).endOf('month').format("YYYY/MM/DD"),
            //         }
            //     }
            //     this.props.onhandleUpdateEditSearch(newSearch);
            //     break;
            // case 'year':
            //     newSearch = {
            //         ...this.props.search,
            //         intervalDate: {
            //             view: this.props.search.intervalDate.view,
            //             fromDate: getMoment()(value).startOf('year').format("YYYY/MM/DD"),
            //             toDate: getMoment()(value).endOf('year').format("YYYY/MM/DD"),
            //         }
            //     }
            //     this.props.onhandleUpdateEditSearch(newSearch);
            //     break;


            default:
            // do nothing
        }
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    // 下载导出
    handleExport() {
        this.props.onhandleExport();
    }

    handlPreviewDoc(allDocs) {
        const docs = _.filter(allDocs, d => {
            return d.docName && d.docLink
        })
        if (docs && docs.length > 0) {
            const doc = docs[0]
            return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: getFileExt(doc.docName),
                            fileDesc: doc.docDesc,
                            docs: docs,
                            fileIndex: 0,
                            fileCount: docs.length,
                        }
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
        }
    }

    handleSwitchDoc = (fileIndex, isNext) => {
        const oldPreviewFile = this.state.previewFile
        var newIndex = fileIndex - 1
        if (isNext) {
            newIndex = fileIndex + 1
        }
        const doc = oldPreviewFile.docs[newIndex]
        return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        ...oldPreviewFile,
                        fileURL: res.payload,
                        fileType: getFileExt(doc.docName),
                        fileDesc: doc.docDesc,
                        fileIndex: newIndex,
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }

    handlePreDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, false)
    }
    handleNextDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, true)
    }

    handleClosePreviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: '',
                fileDesc: '',
                docs: [],
                fileIndex: 0,
                fileCount: 0,
            }
        })
    }

    childComponents = () => <React.Fragment>
        <DeviceOpsRecordHeader
            companyTree={this.trimCompanyTree()}
            companyList={this.props.companyList}
            search={this.props.search}
            catTree={this.props.catTree}
            extcatTree={this.props.extcatTree}
            useStatusTree={this.props.useStatusTree}
            onhandleChange={this.handleChange.bind(this)}
            onhandleCheck={this.handleCheck.bind(this)}
            onhandleSelectChange={this.handleSelect.bind(this)}
            onhandleDateChange={this.handleDateChange.bind(this)}
            onhandleClearSearch={this.props.onResetSearch.bind(this)}
            getDeviceOpsRecordList={this.props.onGetDeviceOpsRecordList.bind(this)}
            installationList={this.props.installationList}
            onhandleExport={this.handleExport.bind(this)}
            isLoad={this.props.isLoading}
            parameters={this.props.parameters}
        />
        <DeviceOpsRecord
            oriListData={this.props.oriListData}
            initListData={this.props.initListData}
            // pageSize={this.props.pageSize}
            // setPageSize={this.props.onSetPageSize.bind(this)}
            // getDeviceOpsRecordList={this.props.onGetDeviceOpsRecordList.bind(this)}
            isLoading={this.props.isLoading}
            parameters={this.props.parameters}
            onhandleCleanmodal={this.props.onhandleCleanmodal.bind(this)}
            onhandlPreviewDoc={this.handlPreviewDoc.bind(this)}
        />
    </React.Fragment>

    render() {
        return (
            <React.Fragment>
                {
                    !(this.props.parameters && this.props.parameters.deviceId)
                        ? <Card style={{ padding: 0 }}>{this.childComponents()}</Card>
                        : <div style={{ padding: 0 }}>{this.childComponents()}</div>
                }
                <FilePreviewer
                    open={this.state.previewFile.open}
                    fileType={this.state.previewFile.fileType}
                    filePath={this.state.previewFile.fileURL}
                    fileDesc={this.state.previewFile.fileDesc}
                    fileIndex={this.state.previewFile.fileIndex}
                    fileCount={this.state.previewFile.fileCount}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleClosePreviewer}
                    onPre={this.handlePreDoc}
                    onNext={this.handleNextDoc}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        oriListData: state.deviceOpsRecord.oriDeviceOpsRecordList,
        initListData: state.deviceOpsRecord.deviceOpsRecordList,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        catTree: state.trees.deviceCategoryTree,
        extcatTree: state.devices.extcatTree,
        useStatusTree: state.devices.useStatusTree,
        // pageSize: state.deviceOpsRecord.pageSize,
        orderTypeTree: state.deviceOpsRecord.orderTypeTree,
        isLoading: state.loading.globalLoading,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        search: state.deviceOpsRecord.search,
        installationList: state.installation.installationList ? state.installation.installationList.list : [],
        parameters: state.modal.parameters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitDeviceOpsRecordList: () => {
            dispatch(initDeviceOpsRecordList());
        },
        onGetDeviceOpsRecordList: (query) => {
            dispatch(getDeviceOpsRecordList(query));
        },
        onSetPageSize: (num) => {
            dispatch(setPageSize(num))
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onResetSearch: () => {
            dispatch(resetSearch());
        },
        onhandleResetSearchField: (value, field) => {
            dispatch(resetSearchField(value, field));
        },
        onhandleExport: () => {
            dispatch(exportDeviceOpsRecordList());
        },
        onhandleCleanmodal: () => {
            dispatch(cleanmodal());
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceOpsRecordContainer)