import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
    Button,
    Divider,
    Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { findBuNameById } from "../../../../utils/constant";
import * as _ from 'lodash';

const CreateGoalPlan = ({
    classes,
    templates,
    constant,
    goalName,
    year,
    onhandleChangeGoal,
    onhandleAddGoal,
    onhandleAddTemplate,
    onhandleUpdateTemplate,
    onhandleDeleteTemplate,
}) => {

    let buMap = _.chain(constant.businessunits)
        .keyBy('unitId')
        .mapValues('unitName')
        .value();

    let tableData = []
    if (templates && templates.length > 0) {
        tableData = _.map(templates, t => {
            let logic = t.opentext.split('{')[0]
            //let target = t.opentext.match('\$target=\d+')[0].split('=')[1]
            let target = /\$target=\d+/.exec(t.opentext)[0].split('=')[1]
            /* eslint-disable no-useless-escape */
            let unit = /unit=[^\}]+/.exec(t.opentext)[0].split('=')[1]
            return {
                ...t,
                logic: logic,
                target: target,
                unit: unit
            }
        })
    }
    const columns = [
        {
            title: '模板名称',
            field: 'tempName'
        },
        {
            title: '符号',
            field: 'logic',
            width: '90px',
            align: 'right',
            validate: row => !row.logic || row.logic === '' ? { isValid: false, helperText: '符号不能为空' } : true,
            lookup: {
                '<=': '<=',
                '<': '<',
                '=': '=',
                '>=': '>=',
                '>': '>',
            }
        },
        {
            title: '默认值',
            field: 'target',
            width: '90px',
            align: 'center',
            validate: row => _.isEmpty(row.target) ? { isValid: false, helperText: '默认值不能为空' } : true,
        },
        {
            title: '单位',
            field: 'unit',
            width: '90px',
            align: 'left',
            validate: row => !row.unit || row.unit === '' ? { isValid: false, helperText: '单位不能为空' } : true,
        },
        {
            title: '业务分类',
            field: 'unitId',
            lookup: buMap,
            render: (row) => findBuNameById(constant, row.unitId)
        }
    ]
    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }
    const components = {
        Toolbar: props => (
            <Grid >
                <Grid container >
                    <Grid item className={classes.action}>
                        <TextField className={classes.textfield}
                            variant="outlined"
                            placeholder="请输入"
                            label="目标名称"
                            value={goalName}
                            name='goalName'
                            onChange={onhandleChangeGoal}
                        />
                    </Grid>
                    <Grid item className={classes.action}>
                        <TextField className={classes.textfield}
                            variant="outlined"
                            placeholder="请输入"
                            label="年份"
                            value={year}
                            type="number"
                            name='year'
                            onChange={onhandleChangeGoal}
                        />
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item className={classes.button}>
                        <Button variant='contained' color='primary' onClick={onhandleAddGoal}>
                            <Add />启动年度目标
                        </Button>
                    </Grid>
                </Grid>

                <Divider />
                <Grid container >
                    <Grid item className={classes.detailTitle}>
                        <Typography variant='h5'> 企业目标模板明细</Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item>
                        <MTableToolbar {...props} />
                    </Grid>

                </Grid>
            </Grid>

        )
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid>
                <Grid>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        localization={localization}
                        columns={columns}
                        data={tableData}
                        options={tableOptions}
                        style={tableStyle}
                        components={components}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    onhandleAddTemplate(newData)
                                    resolve()
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    onhandleUpdateTemplate(newData)
                                    resolve()
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    onhandleDeleteTemplate(oldData)
                                    resolve()
                                }),
                        }}
                    >
                    </MaterialTable>
                </Grid>

            </Grid>

        </div>
    );
};
CreateGoalPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    templates: PropTypes.array.isRequired,
    onhandleAdd: PropTypes.func,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
};

CreateGoalPlan.defaultProps = {
    open: false,
    templates: [],
    businessunits: [],
}

export default (withStyles(CreateGoalPlan));