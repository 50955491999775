import React from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Link,
    Typography,
    Container,
    Box,
    FormHelperText,
    Grid
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import withStyles from './styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import beianIcon from '@/assets/备案图标.png';

function Copyright() {
    return (
        <React.Fragment>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://anfenginfo.com/">
                    大连荣鹄信息科技有限公司
                </Link>
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link color="inherit" href="http://beian.miit.gov.cn">
                    辽ICP备2021011663号-1
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <img src={beianIcon} alt={'公安备案图标'} />
                <Link color="inherit" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21029602000629">
                    辽公网安备 21029602000629号
                </Link>
            </Typography>
        </React.Fragment>
    );
}

const Signup = props => {

    const { classes,
        onhandleSignup,
        onhandleChange,
        onhandleCheck,
        onhandleSmsCode,
        signupInfo,
        smsClock
    } = props;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountCircleOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    用户注册
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="tenantAlias"
                                label="租户代码"
                                name="tenantAlias"
                                autoFocus
                                value={signupInfo.tenantAlias || ''}
                                onChange={event => onhandleChange(event, 'tenantAlias')}
                                onBlur={event => onhandleCheck(event, 'tenantAlias')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="姓"
                                name="lastName"
                                value={signupInfo.lastName || ''}
                                onChange={event => onhandleChange(event, 'lastName')}
                                onBlur={event => onhandleCheck(event, 'lastName')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="名"
                                value={signupInfo.firstName || ''}
                                onChange={event => onhandleChange(event, 'firstName')}
                                onBlur={event => onhandleCheck(event, 'firstName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="userName"
                                label="用户名"
                                name="userName"
                                value={signupInfo.userName || ''}
                                onChange={event => onhandleChange(event, 'userName')}
                                onBlur={event => onhandleCheck(event, 'userName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                label="手机号码"
                                name="phone"
                                value={signupInfo.phone || ''}
                                onChange={event => onhandleChange(event, 'phone')}
                                onBlur={event => onhandleCheck(event, 'phone')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="smsCode"
                                label="手机验证码"
                                name="smsCode"
                                value={signupInfo.smsCode || ''}
                                onChange={event => onhandleChange(event, 'smsCode')}
                                onBlur={event => onhandleCheck(event, 'smsCode')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={smsClock && smsClock !== 180}
                                className={classes.sms}
                                onClick={onhandleSmsCode}>
                                {!smsClock || smsClock === 180 ? "获取验证码" : smsClock + "秒后重发"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="email"
                                label="邮箱地址"
                                name="email"
                                value={signupInfo.email || ''}
                                onChange={event => onhandleChange(event, 'email')}
                                onBlur={event => onhandleCheck(event, 'email')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="密码"
                                type="password"
                                id="password"
                                value={signupInfo.password || ''}
                                onChange={event => onhandleChange(event, 'password')}
                                onBlur={event => onhandleCheck(event, 'password')}
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText error={signupInfo.errorMessage !== ''}>
                        {signupInfo.errorMessage}</FormHelperText>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onhandleSignup}>
                        注册
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="login" variant="body2">
                                已经有账户了? 登录
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
    signupInfo: PropTypes.any.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSignup: PropTypes.func.isRequired,
    onhandleSmsCode: PropTypes.func.isRequired,
    smsClock: PropTypes.number.isRequired
};

export default withRouter(withStyles(Signup));