import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegulationPlanListAll from "../../../components/LeadershipManagement/RegulationPlanListAll";
import PropTypes from "prop-types";
import {
    backToTrpList,
    getPlans,
    getPlanDtlAll
} from '../../../redux/modules/leadershipRegulationPlan';
// import * as _ from 'lodash';

class RegulationPlanListAllContainer extends Component {
    static propTypes = {
        plans: PropTypes.array,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleGetPlans();
    }

    handleBack = (event) => {
        this.props.onhandleBackToTrpList();
    };

    detailedInfo(event, planId) {
        this.props.onhandleOpen(planId);
    }

    render() {
        return (
            <RegulationPlanListAll
                plans={this.props.plans}
                onDetailedInfo={this.detailedInfo.bind(this)}
                getPlans={this.props.onhandleGetPlans.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                constants={this.props.constants}
                userProfile={this.props.userProfile}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        plans: state.leadershipRegulationPlan.plans,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetPlans: () => {
            dispatch(getPlans('', '', 3));
        },
        onhandleOpen: (planId) => {
            dispatch(getPlanDtlAll(planId));
        },
        onhandleBackToTrpList: () => {
            dispatch(backToTrpList())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanListAllContainer)