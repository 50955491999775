import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import ProdServiceReportContainer from '@/containers/ManufactureManagement/ProdReport/ProdServiceReportContainer';

const ProductionReportComponent = props => {
    const { classes,
    } = props;
    return (
        <div alignitems="center" className={classes.root}>
            <Grid>
                {/* <DailyProdReportContainer /> */}
                <ProdServiceReportContainer />
            </Grid>
            {/* <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"日生产报表"} value={0} className={classes.tab} />
                <Tab label={"月生产报表"} value={1} className={classes.tab} />
                <Tab label={"年生产报表"} value={2} className={classes.tab} />
            </Tabs>
            <Grid>
                {tabValue === 0 && <DailyProdReportContainer />}
                {tabValue === 1 && <MonthlyProdReportContainer />}
                {tabValue === 2 && <AnnualProdReportContainer />}
            </Grid> */}
        </div>
    )
}

ProductionReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

ProductionReportComponent.defaultProps = {
};

export default withStyles(ProductionReportComponent);