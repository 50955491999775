import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import * as _ from 'lodash';


const MAttrListComponent = props => {
    const { classes,
        attrs,
        attrTypes,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        //toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'attrName',
                render: row => <Typography>{row.attrName}</Typography>
            },
            {
                title: '类型',
                field: 'attrType',
                render: row => <Typography>{getCodeName(row.attrType)}</Typography>,
                lookup: _.chain(attrTypes).keyBy('code').mapValues('name').value()
            },
            {
                title: '说明',
                field: 'opentext',
                render: row => <Typography>{row.opentext}</Typography>
            },
        ]
        return columns
    }

    const getCodeName = (code) => {
        let dic = _.find(attrTypes, item => {
            return item.code === code
        });
        if (!dic) {
            return '未定义';
        }
        return dic.name;
    }

    const assemblyData = () => {
        return attrs
    }


    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>生产活动属性列表</Typography>}
                        editable={
                            {
                                onRowDelete: (oldData) => onhandleDelete(oldData),
                                onRowAdd: (newData) => onhandleAdd(newData),
                                onRowUpdate: (newData, oldData) => onhandleUpdate(newData, oldData),
                            }
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

MAttrListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

MAttrListComponent.defaultProps = {
    attrs: [],
    attrTypes: []
};

export default withStyles(MAttrListComponent);