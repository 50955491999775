import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  content: {
    display: 'flex',
    height: '70%'
  },
});

export default withStyles(styles);
