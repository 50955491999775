import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        padding: 0,
        marginTop: 15
    },
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    // actions: {
    //     justifyContent: 'flex-end'
    // },
    // alignItem: {
    //     textAlign: 'center'
    // }
    detailtable: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(0)
    },
    table: {
        padding: theme.spacing(1)
    },

});

export default withStyles(styles);