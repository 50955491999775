import React from 'react';
import PropTypes from 'prop-types';
// import TreeSelect from 'rc-tree-select';
import withStyles from './styles';
import { Typography, Button, TextField, TextareaAutosize, Grid } from "@material-ui/core";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';

const RoleInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        onhandleSelectionChange,
        companySelection,
        currentRole,
        roleError,
        roleChange,
        roleGroupList,
        tabValue,
        index,
        jobs
    } = props;

    const groupOptions = () => (
        <React.Fragment>
            {
                roleGroupList.list
                    .filter(data => companySelection.value === data.companyId)
                    .map(data =>
                        <option value={data.groupId} key={data.groupName}>{data.groupName}</option>
                    )
            }
        </React.Fragment>
    )

    const jobOptions = () => (
        <React.Fragment>
            {
                jobs.map(data => (
                    <option value={data.jobId} key={data.jobId}>{data.jobName}</option>
                ))
            }
        </React.Fragment>
    )

    return (
        <div className={classes.card} key={currentRole.roleId} hidden={tabValue !== index}>
            <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={8}>
                <Grid item container className={classes.gridItem} sm={6} md={4}>
                    <Typography className={classes.label}>
                        所属单位
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextField
                            className={classes.textFieldInput}
                            value={companySelection.label || ''}
                            variant="outlined"
                            size='small'
                            disabled
                        />
                    </Grid>
                </Grid>

                <Grid item container className={classes.gridItem} sm={6} md={4}>
                    <Typography className={classes.label}>
                        用户组
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextField
                            select
                            className={classes.textFieldInput}
                            dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                            variant="outlined"
                            size="small"
                            native
                            value={currentRole.groupId}
                            onChange={(event) => onhandleSelectionChange(event, 'group')}
                        >
                            {groupOptions()}
                        </TextField>
                    </Grid>
                </Grid>

                {currentRole.roleId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            角色编号
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textFieldInput}
                                value={companySelection.roleId || ''}
                                variant="outlined"
                                size='small'
                                disabled
                            />
                        </Grid>
                    </Grid>) : null
                }

                <Grid item container className={classes.gridItem} sm={6} md={4}>
                    <Typography className={classes.label}>
                        角色名称
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextField
                            error={roleError.roleName && roleError.roleName !== ''}
                            helperText={roleError.roleName || ''}
                            className={classes.textFieldInput}
                            value={currentRole.roleName || ''}
                            variant="outlined"
                            size="small"
                            onChange={event => onhandleChange(event, 'roleName')}
                            onBlur={event => onhandleCheck(event, 'roleName')}
                        />
                    </Grid>
                </Grid>

                <Grid item container className={classes.gridItem} sm={6} md={4}>
                    <Typography className={classes.label}>
                        角色类别
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextField
                            select
                            className={classes.textFieldInput}
                            variant="outlined"
                            size="small"
                            value={currentRole.roleLevel}
                            onChange={(event) => onhandleSelectionChange(event, 'roleLevel')}
                        >
                            <option value="1" key="rolelvl1">普通员工</option>
                            <option value="2" key="rolelvl2">管理人员</option>
                        </TextField>
                    </Grid>
                </Grid>

                <Grid item container className={classes.gridItem} sm={6} md={4}>
                    <Typography className={classes.label}>
                        职务
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextField
                            select
                            className={classes.textFieldInput}
                            native
                            variant="outlined"
                            size="small"
                            value={currentRole.jobId}
                            onChange={(event) => onhandleSelectionChange(event, 'jobId')}
                        >
                            <option aria-label="None" value="" />
                            {jobOptions()}
                        </TextField>
                    </Grid>
                </Grid>

                <Grid item container className={classes.gridItem} sm={12} md={12}>
                    <Typography className={classes.label}>
                        角色简介
                    </Typography>
                    <Grid item container className={classes.formContainer}>
                        <TextareaAutosize
                            className={classes.textFieldInput}
                            value={currentRole.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

                {currentRole.roleId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            创建时间
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography className={classes.label}>
                                {getDateByTimestamp(currentRole.createTime, true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentRole.roleId ? (
                    <Grid item container className={classes.gridItem} sm={6} md={8}>
                        <Typography className={classes.label}>
                            创建人
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography className={classes.label}>
                                {currentRole.creatorLastName + currentRole.creatorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentRole.roleId && currentRole.updateTime ? (
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            修改时间
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography className={classes.label}>
                                {getDateByTimestamp(currentRole.updateTime, true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentRole.roleId && currentRole.revisor ? (
                    <Grid item container className={classes.gridItem} sm={6} md={8}>
                        <Typography className={classes.label}>
                            修改人
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <Typography className={classes.label}>
                                {currentRole.revisorLastName + currentRole.revisorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
                <Button variant="contained" color="primary" className={classes.button}
                    disabled={!roleChange}
                    onClick={event => onhandleSave(event)}
                >
                    保存
                </Button>
                <Button variant="contained" color="primary" className={classes.button}
                    onClick={event => onhandleBack(event)}
                >
                    返回
                </Button>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={roleChange} />
        </div>
    );
};

RoleInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    currentRole: PropTypes.object.isRequired,
    roleError: PropTypes.object.isRequired,
    roleChange: PropTypes.bool.isRequired,
    companySelection: PropTypes.object,
    roleGroupList: PropTypes.object.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
};

export default withStyles(RoleInfo);
