import React from 'react';
import withStyles from './styles';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';

const WorkOrderHeaderForPopup = props => {
  const {
    classes,
  } = props;

  return (
    <div>
      <div className={classes.planitemroot}>
        <Grid container alignItems="center">
          <Grid container className={classes.title}>
            <Typography variant="h3" className={classes.plantname}>
              {'已下工单列表'}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

WorkOrderHeaderForPopup.propTypes = {
};

export default withStyles(WorkOrderHeaderForPopup);
