import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Typography,
    Button,
} from '@material-ui/core';
import MyCanvasView from '../../../device/src/containers/MyCanvasView';
import * as _ from 'lodash';

const InstallationMapView = props => {
    const {
        onRouterToList,
        installationInfo,
        globalLoading
    } = props;

    const checkMapExist = () => {
        if (installationInfo && installationInfo.storeys) {
            if (_.filter(installationInfo.storeys, function (o) { return o.data2d; }).length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    };
    return (
        <div>
            {globalLoading ? null :
                <React.Fragment>
                    <div>
                        <Typography variant="h3" color='textPrimary' style={{ display: 'inline-block' }}>
                            {installationInfo.installName}
                        </Typography>
                        <Button variant="outlined" color="primary" style={{ margin: 10 }} onClick={onRouterToList}>
                            返回
                        </Button>
                    </div>
                    {checkMapExist() ? <MyCanvasView installation={installationInfo} canvasViewWidth={window.innerWidth - 48} canvasViewHeight={window.innerHeight - 108} /> :
                        <Typography gutterBottom>
                            当前装置的装置图不存在，请返回装置列表并点击编辑按钮新建装置图。
                        </Typography>
                    }
                </React.Fragment>
            }

        </div>
    );
};

InstallationMapView.propTypes = {
    installationInfo: PropTypes.object.isRequired,
    globalLoading: PropTypes.bool.isRequired
};

export default withStyles(InstallationMapView);
