import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit'; import HistoryIcon from '@material-ui/icons/History';
import MaterialTable from 'material-table';
import { localization, options } from "@/utils/mtable";
import { getCodeName, VALUATION_TYPE } from '@/utils/manufactureUtils'
import * as _ from 'lodash';

const ServiceBudget = props => {
    const {
        classes,
        serviceBudgets,
        businessGroupList,
        manufactureDicts,
        companyId,
        companyList,
        isMainCompany,
        onhandleCompanyChange,
        onhandleRowUpdate,
        onhandleShowHistory,
    } = props;

    const renderAmoebaIndex = (ind) => {
        let bg = _.find(businessGroupList, bg => { return bg.subId === ind })
        return bg ? bg.groupName : ''
    }

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '服务名称',
                field: 'serviceName',
                width: 140,
                render: row => <Typography>{row.serviceName}{renderServiceType(row.serviceType)}</Typography>,
                editable: 'never'
            },
            {
                title: '服务类型',
                field: 'amoebaIndex',
                width: 130,
                render: row => <Typography>{renderAmoebaIndex(row.amoebaIndex)}</Typography>,
                editable: 'never'
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
                editable: 'never'
            },
            {
                field: 'unitPrice',
                title: '单价',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.unitPrice > 0 ? row.unitPrice : '-'}</Typography>,
            },
            {
                field: 'unit',
                title: '单位',
                align: 'left',
                width: 120,
                render: row => <Typography>{row.unit ? '元/' + row.unit : ''}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const textFieldDefault = {
        style: {
            width: '160px',
            height: '36px'
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        toolbar: false,
    }
    const tableLocalization = {
        ...localization,
    }

    const assemblyData = () => {
        return serviceBudgets;
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid>
                <Grid container >
                    <Grid className={`${classes.flexLine}`}>
                    </Grid>
                    <Grid className={classes.flexTextField}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {companyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title={<Typography variant='h3'>服务预算</Typography>}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) => onhandleRowUpdate(newData, oldData),
                        }}
                        actions={
                            [
                                {
                                    icon: () => { return <HistoryIcon /> },
                                    tooltip: '服务修改历史',
                                    onClick: (event, rowData) => {
                                        onhandleShowHistory(event, rowData)
                                    }
                                },
                            ]
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    );
};

ServiceBudget.propTypes = {
    classes: PropTypes.object.isRequired,
    rules: PropTypes.array,
    subDataList: PropTypes.array,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
};

export default withStyles(ServiceBudget);