import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Grid,
    Typography,
    Button,
    IconButton,
    TextField,
    MenuItem
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { getNameByTypeAndCode } from '../../../utils/constant';
import MaterialTable from 'material-table';
import { getDateByTimestamp, getYear, isLaterThan, getLastDayInYear } from '../../../utils/datetime';
import { localization, options } from "../../../utils/mtable";
import TreeSelect from 'rc-tree-select';
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';

const HolidayList = props => {
    const {
        classes,
        holidays,
        onDelete,
        onEdit,
        onCreate,
        onhandleSelectionChange,
        companySelection,
        companyTree,
        userProfile,
        constants,
        companies,
        getHolidays
    } = props;

    // const addActionRef = React.useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentYear, setCurrentYear] = useState(getYear());
    const [dataError, setDataError] = useState({
        holidayName: '', holidayType: ''
    })
    console.debug(selectedRow)

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'holiday_management' })
        return permission && permission.action === 'W'
    }

    const renderCompany = (companyId) => {
        let company = _.find(companies, function (o) {
            return o.companyId === companyId
        })
        return company ? company.companyName : "所有"
    }

    const goToLastYear = () => {
        let lastYear = currentYear - 1;
        setCurrentYear(lastYear)
        getHolidays(lastYear, companySelection ? companySelection.value : undefined)
    }

    const goToNextYear = () => {
        let nextYear = currentYear + 1;
        setCurrentYear(nextYear)
        getHolidays(nextYear, companySelection ? companySelection.value : undefined)
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '年',
                field: 'year',
                render: row => row ? <Typography>{currentYear}</Typography> : '',
                editComponent: (props) => (
                    <Typography>{currentYear}</Typography>
                )
            },
            {
                title: '名称',
                field: 'holidayName',
                render: row => row ? <Typography>{row.holidayName}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textFieldInput}
                        size={'small'}
                        variant="outlined"
                        error={dataError.holidayName && dataError.holidayName !== ''}
                        helperText={dataError.holidayName || ''}
                        required
                        value={props.rowData.holidayName ? props.rowData.holidayName : ''}
                        name='holidayName'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '类型',
                field: 'holidayType',
                render: row => row ?
                    <Typography>{getNameByTypeAndCode(constants, 'HOLIDAYTYPE', row.holidayType)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textFieldInput}
                        size={'small'}
                        variant="outlined"
                        error={dataError.holidayType && dataError.holidayType !== ''}
                        helperText={dataError.holidayType || ''}
                        select
                        value={props.rowData.holidayType ? props.rowData.holidayType : ''}
                        name='holidayType'
                        onChange={e => props.onChange(e.target.value)}>
                        {constants.HOLIDAYTYPE.map((item) => (
                            (item.code !== 'VAC') &&
                            <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            },
            {
                title: '开始时间',
                field: 'startTime',
                type: 'date',
                render: row => row ? <Typography>{getDateByTimestamp(row.startTime)}</Typography> : '',
                editComponent: (props) => (
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN}>
                        <KeyboardDatePicker
                            className={classes.textField}
                            autoOk
                            disablePast
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            maxDate={getLastDayInYear(currentYear)}
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            name='startTime'
                            value={props.rowData.startTime}
                            onChange={value => props.onChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': '开始时间',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                )
            },
            {
                title: '结束时间',
                field: 'endTime',
                type: 'date',
                render: row => row ? <Typography>{getDateByTimestamp(row.endTime)}</Typography> : '',
                editComponent: (props) => (
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN}>
                        <KeyboardDatePicker
                            className={classes.textField}
                            autoOk
                            disablePast
                            inputVariant="outlined"
                            size="small"
                            minDate={getDateByTimestamp(props.rowData.startTime)}
                            maxDate={getLastDayInYear(currentYear)}
                            minDateMessage={"结束时间不能早于开始时间"}
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            name='endTime'
                            value={props.rowData.endTime}
                            onChange={value => props.onChange(value)}
                            KeyboardButtonProps={{
                                'aria-label': '结束时间',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                )
            },
            {
                title: '单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textFieldInput}
                        size={'small'}
                        variant="outlined"
                        select
                        value={props.rowData.companyId ? props.rowData.companyId : ''}
                        name='companyId'
                        onChange={e => props.onChange(e.target.value)}>
                        {companies.map((item) => (
                            <MenuItem key={item.companyId} value={item.companyId}>
                                {item.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            }
        ]

        return columns
    }

    return (
        <Card alignitems="center" className={classes.planitemroot}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'节假日列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={8}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            单&emsp;&emsp;位
                        </Typography>
                        <TreeSelect
                            className={classes.textFieldInput}
                            dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            allowClear
                            treeLine
                            value={companySelection}
                            labelInValue
                            treeData={companyTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => onhandleSelectionChange(currentYear, value)}
                        />
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            年&emsp;&emsp;份
                        </Typography>
                        <Grid container item className={classes.formContainer}>
                            <Grid item>
                                <IconButton onClick={goToLastYear}>
                                    <ChevronLeft />
                                </IconButton>
                            </Grid>
                            <Grid item className={classes.toolbarLabel}>
                                <Typography>{currentYear}</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={goToNextYear}>
                                    <ChevronRight />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={4}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {/* <Button variant='contained' color='primary' startIcon={<Add />} onClick={() => addActionRef.current.click()}>
                            新建
                        </Button> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={holidays}
                    options={{
                        ...options,
                        pageSize: 5,
                        tableLayout: 'auto',
                        addRowPosition: 'first',
                        actionsColumnIndex: -1,
                        search: false,
                    }}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    // components={{ 无法用外部按钮重载表格组件新增行的操作
                    //     Action: props => {
                    //         console.log("props, actions")
                    //         console.log(props)
                    //         console.log(addActionRef)
                    //         if (typeof props.action === typeof Function || props.action.tooltip !== 'Add') {
                    //             return <MTableAction {...props} />
                    //         } else {
                    //             return <div ref={addActionRef} onClick={props.action.onClick} />;
                    //         }
                    //     }
                    // }}
                    icons={{
                        Add: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}                        >
                            新建
                        </Button>,
                    }}
                    editable={checkPermission() && currentYear >= getYear() ? {
                        isEditHidden: rowData => !isLaterThan(rowData.startTime) || rowData.holidayType === 'VAC',
                        isDeleteHidden: rowData => !isLaterThan(rowData.startTime) || rowData.holidayType === 'VAC',
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                let err = {}
                                if (!newData['holidayName'] || _.trim(newData['holidayName']) === '') {
                                    err['holidayName'] = "节假日名称不能为空"
                                } else {
                                    err['holidayName'] = ""
                                }
                                if (!newData['holidayType'] || _.trim(newData['holidayType']) === '') {
                                    err['holidayType'] = "节假日类型不能为空"
                                } else {
                                    err['holidayType'] = ""
                                }
                                if (err.holidayType !== "" || err.holidayName !== "") {
                                    setDataError(err)
                                    reject()
                                } else {
                                    newData['year'] = currentYear
                                    if (newData['startTime'] === undefined) {
                                        newData['startTime'] = new Date();
                                    }
                                    if (newData['endTime'] === undefined) {
                                        newData['endTime'] = new Date();
                                    }
                                    resolve()
                                    onCreate(newData)
                                }

                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                let err = {}
                                if (!newData['holidayName'] || _.trim(newData['holidayName']) === '') {
                                    err['holidayName'] = "节假日名称不能为空"
                                } else {
                                    err['holidayName'] = ""
                                }
                                if (!newData['holidayType'] || _.trim(newData['holidayType']) === '') {
                                    err['holidayType'] = "节假日类型不能为空"
                                } else {
                                    err['holidayType'] = ""
                                }
                                if (err.holidayType !== "" || err.holidayName !== "") {
                                    setDataError(err)
                                    reject()
                                } else {
                                    resolve()
                                    onEdit(newData)
                                }
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onDelete(oldData)
                            })

                    } : null}
                >
                </MaterialTable>
            </Grid>
        </Card>
    );
};

HolidayList.propTypes = {
    classes: PropTypes.object.isRequired,
    holidays: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    companySelection: PropTypes.object,
    companyTree: PropTypes.array.isRequired,
    constants: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    getHolidays: PropTypes.func.isRequired
};

export default withStyles(HolidayList);

