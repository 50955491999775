import * as _ from 'lodash';
import moment from 'moment'
import { dateCompare } from './datetime'

export const getRecordByCompanyId = function (processes, companyId) {
    for (let process of processes) {
        for (let record of process.records) {
            if (record.companyId === companyId) {
                return record
            }
        }
    }
}

export const getRecordByUnitId = function (processes, unitId) {
    for (let process of processes) {
        for (let record of process.records) {
            if (record.unitId === unitId) {
                return record
            }
        }
    }
}

export const isUnitBranch = function (processes) {
    let mainProcess = _.find(processes, (process) => {
        return _.find(process.records, (rec) => { return !rec.pid })
    })
    if (mainProcess && mainProcess.branch === 'PBRANCH_UNIT') { return true }

    let unitProcess = _.find(processes, (process) => {
        return _.find(process.records, (rec) => {
            return rec.currentStage !== rec.mergeStage
                && rec.branch === 'PBRANCH_UNIT'
        })
    })
    if (unitProcess) { return true }

    return false
}

export const getMasterRecord = function (processes) {
    for (let process of processes) {
        for (let record of process.records) {
            if (!record.pid) {
                return record
            }
        }
    }
}

export const getMasterRecordByRecordId = function (processes, recordId) {
    for (let process of processes) {
        for (let record of process.records) {
            if (!record.pid && record.recordId === recordId) {
                return record
            }
        }
    }
}

export const getRecords = function (processes) {
    let records = []
    for (let process of processes) {
        records = records.concat(process.records)
    }
    return records
}

export const getProcessByCompanyId = function (processes, companyId) {
    for (let process of processes) {
        for (let record of process.records) {
            if (record.companyId === companyId) {
                return process
            }
        }
    }
}

export const checkActivityPlanByRole = function (activityList, holidays, loginUser) {
    let beginDate = moment().add(1, 'month').startOf('month')
    const endDate = moment().add(1, 'month').endOf('month')
    let inValidDays = []
    for (beginDate; beginDate <= endDate;) {
        const isWeekend = (moment(beginDate).day() === 0 || moment(beginDate).day() === 6)

        const holiday = holidays.filter((item) => {
            return moment(beginDate).isBetween(moment(item.startTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'), moment(item.endTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'))
        })
        // const holidayName = holiday.length > 0 ? holiday[0].holidayName : ''
        const holidayType = holiday.length > 0 ? holiday[0].holidayType : ''
        const isComVac = (holidayType === 'COM')
        const isVac = (holidayType === 'VAC')

        // 上班日和工作日需要安排1人值班
        // 周末以及节假日需要安排2人值班
        const correctPersonCount = isComVac || (!isVac && !isWeekend) ? 1 : 2

        let personCount = 0
        for (let activity of activityList) {
            if (loginUser.userId === activity.creator && dateCompare(moment(activity.activityTime).startOf('day'), moment(beginDate).startOf('day'))) {
                personCount = personCount + activity.users.length
            }
        }

        if (correctPersonCount !== personCount) {
            const date = moment(beginDate)
            inValidDays.push(date)
        }

        beginDate.add(1, 'days')
    }

    console.log('inValidDays:' + inValidDays)

    return { result: !(inValidDays.length > 0), inValidDays: inValidDays }
}

export const processLine2ProcessApproval = (lines) => {

    let approvals = [];
    lines.forEach(data => {
        let approval = {
            ...data,
            aprvIdString: _.isString(data.id) ? data.id : _.toString(data.id)
        }
        if (data.userId) {
            approval.userId = data.userId
        }
        if (data.preId) {
            approval.preIdString = _.isString(data.preId) ? data.preId : _.toString(data.preId)
        }
        approvals.push(approval)
    })

    return approvals
}


export const processApproval2ProcessLine = (approvals) => {

    let lines = [];
    approvals.forEach(data => {
        let approval = {
            ...data,
        }
        if (!data.id) {
            approval.id = data.aprvId ? data.aprvId : data.aprvIdString
        }
        if (data.preId) {
            approval.preId = (data.preId) ? data.preId : data.preIdString
        }
        lines.push(approval)
    })

    return lines
}

export const getProcessApprovalStatus = (approvals) => {
    if (approvals.length === 0) {
        return 'todo'
    }

    approvals = _.filter(approvals, { 'actionType': 'INIT' })
    // let todos = _.filter(approvals, { 'status': 'todo' })
    let donings = _.filter(approvals, { 'status': 'doing' })
    let dones = _.filter(approvals, { 'status': 'done' })

    if (dones.length > 0 && dones.length === approvals.length) {
        return 'done'
    }
    if (dones.length === 0 && donings.length === 1) {
        return 'todo'
    }
    if (dones.length > 0) {
        return 'doing'
    }
    return 'todo'
}

//获取用户相关当前能操作的节点
export const getActionerApproval = (approvals, roleId, userId) => {

    let appr = _.find(approvals, { 'roleId': roleId, 'userId': userId, 'status': 'doing' })
    if (appr) {
        return appr
    }

    let apprs = _.filter(approvals, (a) => {
        return !a.userId &&
            a.roleId === roleId
            && a.status === 'doing'
    })
    if (apprs.length > 0) {
        return apprs[0]
    }
    return null
}