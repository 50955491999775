import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddRegulationPannel from '../../../components/LeadershipManagement/Regulation/AddRegulationPannel';
import { closePannel, changeRegulation } from '../../../redux/modules/regulation';

class RegulationAddPannelContainer extends Component {
    static propTypes = {
        regulation: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    handleConfirm() {
        const { confirmCallback, regulation, onhandleClose } = this.props;
        if (confirmCallback) confirmCallback(regulation)
        if (onhandleClose) onhandleClose();

    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, regulation } = this.props
        // console.log(event)

        let newRegulation = {
            ...regulation,
        }
        newRegulation[event.target.name] = event.target.value
        onhandleChange(newRegulation)
    }

    render() {
        return (
            <AddRegulationPannel
                open={this.props.open}
                constant={this.props.constant}
                regulation={this.props.regulation}
                onhandleChange={this.handleChange.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.regulation.open,
        constant: state.constant,
        regulation: state.regulation.regulation,
        confirmCallback: state.regulation.confirmCallback,
        closeCallback: state.regulation.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (regulation) => {
            dispatch(changeRegulation(regulation));
        },
        onhandleClose: () => {
            dispatch(closePannel());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationAddPannelContainer);