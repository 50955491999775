import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceWorkOrder from '../../components/OperationManagement/WorkOrder/DeviceWorkOrder';
import {
    initWorkOrderInfoForCreate,
    setNewWorkOrderChange,
    setNewWorkOrders,
    createWorkOrder,
    editWorkOrders
} from "../../redux/modules/operation";
import { openConfirmDialog } from '../..//redux/modules/confirmDialog';
import { routeToOperationPlanList, routeToOperationPlanDetailPage } from "../../redux/modules/operationPlan";
import { getCompanyId, getDepartmentsByRole } from "../../utils/userUtils";
import { accAdd } from "../../utils/mathUtils";
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from "prop-types";

class DeviceWorkOrderContainer extends Component {

    static propTypes = {
        sourceList: PropTypes.array,
        orderTypeList: PropTypes.array,
        orderTypeLeafTree: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
        optCategories: PropTypes.array,
        catList: PropTypes.array,
        newWorkOrders: PropTypes.array,
        newWorkOrderChange: PropTypes.bool,
        accCatList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            id: "",
        };
    }


    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({ id: id });
        this.props.onhandleInitWorkOrderInfo(id);
    }

    handleSave = (event) => {
        let total = 0.0;
        let optDtlOrderKeies = [];
        if (this.state.id !== 'multiple') {
            total = this.props.currentPlanDetail.budget;
            optDtlOrderKeies = [{ dtlId: this.props.currentPlanDetail.dtlId }]
        } else {
            this.handledata().forEach(element => {
                total = accAdd(total, element.budget);
                optDtlOrderKeies.push({ dtlId: element.dtlId })
            });
        }
        let sum = 0.0;
        this.props.newWorkOrders.forEach(element => {
            sum = accAdd(sum, element.orderBudget);
        });

        let msg = ''
        if (total && _.isNumber(total)) {
            if (sum > total) {
                msg = `工单预算总和（${sum}）大于计划预算（${total}），确定要保存吗？`;
            } else if (sum < total) {
                msg = `工单预算总和（${sum}）小于计划预算（${total}），确定要保存吗？`;
            }
        } else {
            msg = `工单预算总和为（${sum}）但当前计划未设定预算，确定要保存吗？`;
        }

        // 创建工单后，如果只有一个工单则直接跳转至工单编辑页面
        const createAndRoute = () => {
            this.props.createWorkOrder(optDtlOrderKeies, (res) => {
                // 创建多个工单时，res对象是返回最后一条工单信息
                if (!res) { return }
                if (this.props.newWorkOrders.length !== 1) {
                    this.props.routeToOperationPlanList()
                } else {
                    this.props.editWorkOrders(res.orderId)
                }
            })

        }

        if (msg !== '') {
            this.props.onhandleOpenConfirmDialog('保存确认', msg, createAndRoute, null, false);
        } else {
            createAndRoute();
        }
    }



    handleDelete(data) {
        let newWorkOrders = _.clone(this.props.newWorkOrders);
        newWorkOrders = _.reject(newWorkOrders, { _id: data._id })
        this.props.onhandleSetNewWorkOrders(newWorkOrders);
        this.props.onhandleNewWorkOrderChange(true);
    }

    handleUpdate(data) {
        let newWorkOrders = _.clone(this.props.newWorkOrders);
        let workOrder = _.find(newWorkOrders, { _id: data._id });
        workOrder.orderType = data.orderType;
        workOrder.orderContent = data.orderContent;
        workOrder.orderBudget = data.orderBudget;
        this.props.onhandleSetNewWorkOrders(newWorkOrders);
        this.props.onhandleNewWorkOrderChange(true);
    }

    handleAddDetail() {
        let newWorkOrder = {
            companyId: getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile),
            dptId: getDepartmentsByRole(this.props.currentUserInfo.roleId, this.props.userProfile)[0].dptId,
            source: 'ORD_DEV',
            sourceId: this.props.currentPlanDetail.dtlId ? this.props.currentPlanDetail.dtlId : null,
            orderType: '',
            orderContent: '',
            orderBudget: null,
            orderHead: this.props.currentUserInfo.userId,
            _id: uuidv4(),
        };
        newWorkOrder.tableData = {
            editing: 'update'
        }
        let newWorkOrders = _.clone(this.props.newWorkOrders);
        newWorkOrders.push(newWorkOrder);
        this.props.onhandleSetNewWorkOrders(newWorkOrders);
        this.props.onhandleNewWorkOrderChange(true);
    }

    // 返回按钮 处理
    handleBack() {
        let source = this.props.match.params.source;
        let id = this.props.match.params.id;

        if (source && source === "fromdetailpage") {
            // 来自运维计划详细页面
            this.props.routeToOperationPlanDetailPage(id)
        } else {
            this.props.routeToOperationPlanList()
        }
    }

    handledata() {
        let data = _.filter(this.props.operationPlanDetails.list, { selected: true });
        return data;
    }

    render() {
        return (
            <DeviceWorkOrder
                currentPlanDetail={this.props.currentPlanDetail}
                sourceList={this.props.sourceList}
                orderTypeList={this.props.orderTypeList}
                orderTypeLeafTree={this.props.orderTypeLeafTree}
                departmentList={this.props.departmentList}
                companyList={this.props.companyList}
                // onhandleBack={this.props.routeToOperationPlanList.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleSave={this.handleSave.bind(this)}
                optCategories={this.props.optCategories}
                catList={this.props.catList}
                newWorkOrders={this.props.newWorkOrders}
                newWorkOrderChange={this.props.newWorkOrderChange}
                onhandleAddDetail={this.handleAddDetail.bind(this)}
                onhandleUpdate={this.handleUpdate.bind(this)}
                onhandleDelete={this.handleDelete.bind(this)}
                accCatList={this.props.accCatList}
                operationPlanDetails={this.handledata()}
                id={this.state.id}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        sourceList: state.operation.sourceList,
        orderTypeList: state.operation.orderTypeList,
        departmentList: state.constant.departments,
        companyList: state.constant.companies,
        currentPlanDetail: state.operationPlan.currentPlanDetail,
        optCategories: state.operationRule.optCategories,
        catList: state.trees.deviceCategoryList,
        newWorkOrders: state.operation.newWorkOrders,
        newWorkOrderChange: state.operation.newWorkOrderChange,
        accCatList: state.accessory.catList,
        operationPlanDetails: state.operationPlan.planDetails,
        orderTypeLeafTree: state.operation.orderTypeLeafTree,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitWorkOrderInfo: (data) => {
            dispatch(initWorkOrderInfoForCreate(data))
        },
        onhandleNewWorkOrderChange: (data) => {
            dispatch(setNewWorkOrderChange(data))
        },
        onhandleSetNewWorkOrders: (data) => {
            dispatch(setNewWorkOrders(data))
        },
        routeToOperationPlanList: () => {
            dispatch(routeToOperationPlanList())
        },
        routeToOperationPlanDetailPage: (id) => {
            dispatch(routeToOperationPlanDetailPage(id))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        createWorkOrder: (optDtlOrderKeies, callback) => {
            dispatch(createWorkOrder(optDtlOrderKeies, callback))
        },
        editWorkOrders: (id) => {
            dispatch(editWorkOrders(id, "needProcess"));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceWorkOrderContainer)
