import { createAction, handleActions } from 'redux-actions';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createhttpMessage, createMessage } from './message';
import { cleanmodal } from './modal';
import { getCompanyId } from "../../utils/userUtils";
import * as _ from 'lodash';
import { push } from "connected-react-router";
// import {
//     // backToSupplierList,
//     // getInsTypeList,
//     getSupplierById, getSuppliers,
//     initSupplier,
//     // setCurrentSupplier,
//     setSupplierChange,
//     setSupplierError,
//     setSuppliers
// } from "@/redux/modules/Supplier";
// import {getDeviceDictionaries} from "@/redux/modules/constant";
// import {backToInsuranceList} from "@/redux/modules/deviceInsurance";
// import {setEditable} from "@/redux/modules/supplier";


const defaultState = {
    supplierList: {
        total: 0,
        list: []
    },
    search: {
        companyId: "",
        supplierName: "",
        supplierNo: "",
        status: '',
        supplierGroupCode: '',
        page: 0,
        size: 15,
    },
    editable: false,
    currentSupplier: {},
    selectedSupplier: [],
    pageSize: 5,
    supplierGroupCode: [],
    supplierError: {},
    supplierChange: false,

};
// 初始化界面
export const initSupplierHeader = createAction('@@xcloud/supplier/initSupplierHeader',
    (isBack) => (dispatch, getState) => {

        const promiseArr = [];
        if (!isBack) {
            promiseArr.push(dispatch(resetSearch()))
        }
        Promise.all(promiseArr).then(() => {

            dispatch(searchSupplierList(getState().supplier.search))
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '初始化承包商列表搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getSupplierList = createAction('@@xcloud/supplier/getSupplierList',
    () => (dispatch, getState, httpClient) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let currentUserInfo = getState().auth.currentUserInfo;
        let url = "/api/suppliers?tenantId=" + currentUserInfo.tenantId + "&companyId=" + companyId + "&offset=0&size=0";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(setSupplierList(res.data));
                }
                return res.data;
            });
    });
export const changeSupplierStatus = createAction('@@xcloud/supplier/changeSupplierStatus',
    (id, status, callback) => (dispatch, getState, httpClient) => {
        let url = '/api/suppliers/' + id;
        let to = {
            supplierId: id,
            status: status
        }
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createMessage('success', '更新承包商状态完成'));
                    // dispatch(getInsurances(getState().deviceInsurance.search));
                    dispatch(backToSupplierList())
                    if (callback) callback(res.data);
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '更新承包商状态失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });
export const setEditable = createAction('@@xcloud/supplier/setEditable');

export const setSupplierList = createAction('@@xcloud/supplier/setSupplierList');
// 搜索承包商列表
export const searchSupplierList = createAction(
    '@@xcloud/supplier/searchSupplierList',
    (query) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        // let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let search = getState().supplier.search;
        let pageSize = getState().supplier.pageSize
        //条件检索
        let currentUserInfo = getState().auth.currentUserInfo;
        let url = "/api/suppliers?tenantId=" + currentUserInfo.tenantId;
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        } else {
            url += "&companyId=0"
        }
        if (query && query.supplierName) {
            url += "&supplierName=" + encodeURIComponent(query.supplierName)
        } else if (search && search.supplierName && search.supplierName !== "") {
            url += "&supplierName=" + encodeURIComponent(search.supplierName)
        }

        if (query && query.supplierNo) {
            url += "&supplierNo=" + encodeURIComponent(query.supplierNo)
        } else if (search && search.supplierNo && search.supplierNo !== "") {
            url += "&supplierNo=" + encodeURIComponent(search.supplierNo)
        }
        if (query && query.supplierGroupCode) {
            url += "&supplierGroupCode=" + query.supplierGroupCode
        } else if (search && search.supplierGroupCode && search.supplierGroupCode !== "") {
            url += "&supplierGroupCode=" + search.supplierGroupCode
        }
        if (query.status)
            url += "&status=" + query.status;
        if (query && _.isInteger(query.offset)) {
            url += "&offset=" + query.offset
        } else {
            url += "&offset=0"
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        }

        // url += "&companyId=" + companyId

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200) {
                    dispatch(setSupplierList(res.data));
                    return res.data;
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `承包商列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const resetSearch = createAction('@@xcloud/supplier/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().supplier.search;
        const companyId = getState().auth.currentUserInfo.companyId;
        const company = _.find(getState().constant.companies, { 'companyId': companyId });
        const hasPcomp = company && company.pid;
        let newSearch = {
            ...search,
            companyId: hasPcomp ? companyId : 0,
            supplierName: "",
            supplierNo: "",
            status: '',
            supplierGroupCode: "",
            page: 0,
            size: 15,
        }
        dispatch(setSearch(newSearch));
    }
);
export const initSupplierSelect = createAction('@@xcloud/supplier/initSupplierSelect',
    () => (dispatch, getState) => {
        dispatch(createloading())
        Promise.all([
            dispatch(getSupplierGroupCode()),
            dispatch(resetSearch()),
            dispatch(setSelectedSupplier([])),
            dispatch(setSupplierList({ total: 0, list: [] }))
        ]).then((data) => {
            dispatch(cleanloading())
        })
            .catch(err => {
                dispatch(cleanloading())
                let msg = '初始化承包商搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);


export const setSearch = createAction('@@xcloud/supplier/setSearch');
export const setPageSize = createAction('@@xcloud/supplier/setPageSize');
export const backToSupplierList = createAction('@@xcloud/supplier/backToSupplierList',
    () => (dispatch, getState) => {
        dispatch(push('/devices/suppliers', { isBack: true }));
    });
export const createSupplier = createAction('@@xcloud/supplier/createSupplier',
    (newIns, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = '/api/suppliers';

        return httpClient.post(url, newIns)
            .then((res) => {
                if (res.status === 201) {

                    dispatch(cleanloading());
                    dispatch(searchSupplierList(getState().supplier.search));
                    dispatch(createMessage('success', '创建承包商完成'));
                    dispatch(backToSupplierList());
                    // dispatch(setCurrentSupplier(res.data));
                    if (callback) callback(res.data);
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {

                dispatch(cleanloading());
                let msg = '创建承包商记录失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });
export const initEdit = createAction(
    '@@xcloud/supplier/initEdit',
    (supplierId) => (dispatch, getState) => {
        const promiseArr = [];
        let isCreate = false;
        if (!supplierId) {
            isCreate = true;
        } else {
            isCreate = false;
            promiseArr.push(dispatch(getSupplierById(supplierId)))
        }
        Promise.all(promiseArr).then((res) => {
            dispatch(initSupplier(isCreate))

        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                console.error(err)
                dispatch(cleanloading());
                let msg = '初始化详情页失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);
export const setCurrentSupplier = createAction('@@xcloud/supplier/setCurrentSupplier');

export const setSupplierError = createAction('@@xcloud/supplier/setSupplierError');

export const setSupplierChange = createAction('@@xcloud/supplier/setSupplierChange');
export const initSupplier = createAction(
    '@@xcloud/supplier/initSupplier',
    (isCreate) => (dispatch, getState) => {

        let supplier = isCreate ? {
            "createOrgid": 0,
            "createTime": '',
            "creator": 0,
            "documentStatus": '',
            "extId": 0,
            "revisor": 0,
            "supplierGroup": 0,
            "supplierGroupName": '',
            "supplierGroupCode": '',
            "supplierId": 0,
            "supplierName": '',
            "supplierNo": '',
            "supplierShortno": '',
            "tenantId": 0,
            "updateTime": '',
            "useOrgid": 0,
            "useOrgno": '',
            "status": 'active'
        } : getState().supplier.currentSupplier;
        let terror = {
            "supplierName": ''

        };
        dispatch(setCurrentSupplier(supplier));
        dispatch(setSupplierError(terror));
        dispatch(setSupplierChange(false));
    }
);
export const getSupplierById = createAction('@@xcloud/supplier/getSupplierById',
    (id) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = '/api/suppliers/' + id;
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCurrentSupplier(res.data));
                    dispatch(cleanloading());
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '承包商获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const updateSupplier = createAction('@@xcloud/supplier/updateSupplier',
    (updatedSupplier, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = '/api/suppliers/' + updatedSupplier.supplierId;
        let to = {
            ...updatedSupplier,
            supplierName: updatedSupplier.supplierName ? updatedSupplier.supplierName : undefined,
            supplierNo: updatedSupplier.supplierNo ? updatedSupplier.supplierNo : undefined,
            supplierId: updatedSupplier.supplierId ? _.toNumber(updatedSupplier.supplierId) : undefined,


        }
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {

                    dispatch(cleanloading());
                    dispatch(searchSupplierList(getState().supplier.search))
                    dispatch(createMessage('success', '更新承包商列表成功'));
                    if (callback) callback(res.data);
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '更新承包商列表失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });
export const setSelectedSupplier = createAction('@@xcloud/supplier/setSelectedSupplier');
export const exitSelected = createAction('@@xcloud/supplier/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedSupplier([]))
        dispatch(cleanmodal())
    }
);

export const getSupplierGroupCode = createAction('@@xcloud/supplier/getSupplierGroupCode',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/user/dictionaries?type=SGN";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                }
                return res;
            }).catch(err => {
                let msg = '承包商组别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const routeToSupplierInfo = createAction(
    '@@xcloud/supplier/routeToSupplierInfo',
    (supplierId, editable) => (dispatch, getState) => {
        console.log("===========redux sppid, editable");
        console.log(supplierId, editable);
        return Promise.all([
            dispatch(setEditable(editable))
        ]).then(() => {
            dispatch(push(`/devices/suppliers/info/${supplierId}`));
        })
    }
);


export const supplierReducer = handleActions(
    {
        [setSupplierList]: (state, { payload: value }) => {
            return {
                ...state,
                supplierList: value
            };
        },

        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setEditable]: (state, { payload: data }) => {
            return {
                ...state,
                editable: data
            }
        },
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [getSupplierGroupCode]: (state, { payload: value }) => {
            return {
                ...state,
                supplierGroupCodes: value
            };
        },


        // [searchSupplierList]: (state, { payload: value }) => {
        //     return {
        //         ...state,
        //         supplierList: value
        //     };
        // },


        [setSelectedSupplier]: (state, { payload: value }) => {
            return {
                ...state,
                selectedSupplier: value
            };
        },

        [initSupplierHeader]: (state, { payload: value }) => {
            return {
                ...state,
                supplierHeader: value
            };
        },

        // [setSuppliers]: (state, { payload: value }) => {
        //     return {
        //         ...state,
        //         Suppliers: value
        //     };
        // },

        [setSupplierError]: (state, { payload: value }) => {
            return {
                ...state,
                supplierError: value
            };
        },
        [setSupplierChange]: (state, { payload: value }) => {
            return {
                ...state,
                supplierChange: value
            };
        },
        [setCurrentSupplier]: (state, { payload: value }) => {
            return {
                ...state,
                currentSupplier: value
            };
        },

        // [backToSupplierList]: (state, { payload: value }) => {
        //     return {
        //         ...state,
        //         supplierList: value
        //     };
        // },


    },
    defaultState
);

export default supplierReducer;