import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Card,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Tooltip,
  IconButton
} from "@material-ui/core";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { getMoment } from '../../../utils/datetime'
import * as _ from 'lodash';

function OperationStatus(props) {
  const {
    classes,
    companyTree,
    companyList,
    installationList,
    search,
    onhandleSelectChange,
    onhandleLoad,
    isLoad,
    specCodeList,
    onhandleExportCsv
  } = props;

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }


  const getintervalDate = () => {
    if (search.intervalDate.view === 'day') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY/MM/DD")
    } else if (search.intervalDate.view === 'week') {
      let start = getMoment()(search.intervalDate.fromDate).format("MM/DD")
      let end = getMoment()(search.intervalDate.toDate).format("MM/DD")
      return start + '~' + end
    } else if (search.intervalDate.view === 'month') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY/MM")
    } else if (search.intervalDate.view === 'year') {
      return getMoment()(search.intervalDate.fromDate).format("YYYY")
    }
  };

  const goToFrontDate = () => {
    if (search.intervalDate.view === 'day') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'day')
      onhandleSelectChange(day, 'day')
    } else if (search.intervalDate.view === 'week') {
      let day = getMoment()(search.intervalDate.fromDate).add(-7, 'days')
      onhandleSelectChange(day, 'week')
    } else if (search.intervalDate.view === 'month') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'month')
      onhandleSelectChange(day, 'month')
    } else if (search.intervalDate.view === 'year') {
      let day = getMoment()(search.intervalDate.fromDate).add(-1, 'year')
      onhandleSelectChange(day, 'year')
    }
  }

  const goToNextDate = () => {
    if (search.intervalDate.view === 'day') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'day')
      onhandleSelectChange(day, 'day')
    } else if (search.intervalDate.view === 'week') {
      let day = getMoment()(search.intervalDate.fromDate).add(7, 'days')
      onhandleSelectChange(day, 'week')
    } else if (search.intervalDate.view === 'month') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'month')
      onhandleSelectChange(day, 'month')
    } else if (search.intervalDate.view === 'year') {
      let day = getMoment()(search.intervalDate.fromDate).add(1, 'year')
      onhandleSelectChange(day, 'year')
    }
  }

  // disabled={isView || !checkPermission()}

  const nextDisabled = () => {

    let end = _.cloneDeep(search.intervalDate.toDate)
    let now = getMoment()().format("YYYY/MM/DD")
    if (end >= now) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      <Card className={classes.card}>
        <Grid container alignItems="center">
          <Grid container className={classes.title}>
            <Typography variant="h3" className={classes.plantname}>
              {'作业报表'}
            </Typography>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              单位
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              style={{ width: '90%' }}
              id="filled-select-company"
              select
              value={search.companyId}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="standard"
              disabled={companyDisabled()}
            >
              <MenuItem key={1} value={0}>-</MenuItem>
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.companyId} value={option.companyId}>
                  {option.companyAlias}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              装置
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              style={{ width: '90%' }}
              id="filled-select-company"
              select
              value={search.installId}
              onChange={(event) => onhandleSelectChange(event, 'installId')}
              variant="standard"
            >
              <MenuItem key={1} value={0}>-</MenuItem>
              {_.sortBy(installationList, 'installId').map((option) => (
                <MenuItem key={option.installId} value={option.installId}>
                  {option.installName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              特殊作业类型
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              error={Boolean(props.helperText)}
              helperText={props.helperText || ''}
              fullWidth
              variant="standard"
              placeholder="请选择"
              name='specCode'
              value={search.specCode}
              onChange={(event) => onhandleSelectChange(event, 'sepecCode')}
              select
            >
              <MenuItem key={1} value={''}>-</MenuItem>
              {specCodeList ?
                _.sortBy(specCodeList, 'seq').map(data => (
                  <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                )) : null
              }
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={4}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="视图" name="view"
                defaultValue="day"
                value={search.intervalDate.view}
                onChange={(event) => onhandleSelectChange(event, 'view')}
                style={{ width: '100%' }}
              >
                <FormControlLabel value="year" control={<Radio />} label="年视图" />
                <FormControlLabel value="month" control={<Radio />} label="月视图" />
                <FormControlLabel value="week" control={<Radio />} label="周视图" />
                <FormControlLabel value="day" control={<Radio />} label="日视图" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item className={classes.toolbarButton}>
            <Button onClick={event => goToFrontDate(event)} size="small" >
              <ArrowBackIos size="small" />
            </Button>
          </Grid>
          <Grid item className={classes.toolbarLabel}>
            <Typography className={classes.label}>{getintervalDate()}</Typography>
          </Grid>
          <Grid item className={classes.toolbarButton} >
            <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
          <Grid item className={classes.toolbarButton}>
            <Tooltip title="导出报告">
              <IconButton aria-label="delete" onClick={event => onhandleExportCsv(event)} size="small" disabled={isLoad}>
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.card}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            {isLoad ? onhandleLoad() : ''}
            <div id="main" style={{ width: '100%', height: 500 }}></div>
          </Grid>
        </Grid>
      </Card>
    </div >
  );
}

OperationStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  installationList: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleLoad: PropTypes.func.isRequired,
  isLoad: PropTypes.bool.isRequired,
  specCodeList: PropTypes.array.isRequired,
  onhandleExportCsv: PropTypes.func.isRequired,
};

export default withStyles(OperationStatus);
