import { createAction, handleActions } from 'redux-actions';

const initialState = {
  open: false,
  title: '',
  text: '',
  confirmCallback: null,
  closeCallback: null,
  unsaved: false
  // parameters: {}
  // add loading status at original component, not this confirm dialog
};

export const openConfirmDialog = createAction('@@xcloud/confirm-dialog/open',
  (
    title, text, confirmCallback, closeCallback,unsaved
  ) => ({
    title, text, confirmCallback, closeCallback,unsaved
  })
);

export const closeConfirmDialog = createAction('@@xcloud/confirm-dialog/close');

// Actions

export const confirmDialogReducer = handleActions(
  {
    [openConfirmDialog]: (state, { payload: confirmDialog }) => {
      return {
        ...state,
        open: true,
        title: confirmDialog.title,
        text: confirmDialog.text,
        confirmCallback: confirmDialog.confirmCallback,
        closeCallback: confirmDialog.closeCallback,
        unsaved: confirmDialog.unsaved
        // parameters: confirmDialog.parameters
      };
    },
    [closeConfirmDialog]: (state) => {
      return {
        ...state,
        open: false,
        title: '',
        text: '',
        confirmCallback: null,
        closeCallback: null,
        unsaved: false
        // parameters: {}
      };
    }
  },
  initialState
);

export default confirmDialogReducer;
