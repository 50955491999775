import * as _ from 'lodash';
import React from 'react';
import GroupWork from '@material-ui/icons/GroupWork';
import NightsStay from '@material-ui/icons/NightsStay';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Class from '@material-ui/icons/Class';

export const COMPANY_AMOEBA_TYPE_OTHER = 'OTHER';
export const COMPANY_AMOEBA_TYPE_COST = 'COST';
export const COMPANY_AMOEBA_TYPE_PROFIT = 'PROFIT';

export const APP_MENU_MAIN = {
	MY_HOMEPAGE: '我的首页',
	MY_CALENDAR: '我的工作日历',
	LEADER_SHIP: '企业领导力',
	INSTALLATION_MANAGEMENT: '装置运行管理',
	DEVICE_MANAGEMENT: '设备管理',
	OPERATION_MANAGEMENT: '作业管理',
	MAJORHAZARD_MANAGEMENT: '重大危险源管理',
	INSPECTION_MANAGEMENT: '隐患排查治理',
	ONSITE_INSPECTION_MANAGEMENT: '巡检管理',
	MANUFACTURE_MANAGEMENT: '生产管理',
	AMOEBA_MANAGEMENT: '阿米巴经营管理',
	CRM_MANAGEMENT: '客户关系管理',
	CONFIGS: '通用配置',
}

export const SAFETY_ACTIVITY_TYPE =
	[
		{ name: '普通活动', value: 0 },
		{ name: '带班值班', value: 1 },
		{ name: '班组活动', value: 2 },
		{ name: '评审活动', value: 3 },
		{ name: '流程活动', value: 4 }
	]

export const SAFETY_ACTIVITY_COLORS =
{
	'active': '#cdeefd',
	'inactive': '#cdeefd',
	'new': '#cdeefd',
	'submitted': '#cdeefd',
	'rejected': '#cdeefd',
	'finalized': '#cdeefd',
	'signed': '#cdeefd',
	'passed': '#cdeefd',
	'reviewing': '#cdeefd',
	'draft': '#cdeefd',
	'default': '#cdeefd',
}

export const MANUFACTURE_DICT_TYPES =
{
	INSTALL_ACTION: 'INSTALL_ACTION',
	HANDOVER_STATUS: 'HANDOVER_STATUS',
	ACTIVITY_ATTR: 'ACTIVITY_ATTR',
	MANAGE_SHARE_TYPE: 'MANAGE_SHARE_TYPE',//管理分摊方式
	DEPRECIATION_SHARE_TYPE: 'DEPRECIATION_SHARE_TYPE',//资产折旧分摊方式
	OTHER_SHARE_TYPE: 'OTHER_SHARE_TYPE',//其他分摊方式
	SHIFT_TYPE: 'SHIFT_TYPE',//排班类型
}

export const INDEX_SUB_CODE =
{
	CODE_HUMEN: 'HUMEN',
	CODE_LABORFEE: 'LABORFEE',
	CODE_HEADCOUNT: 'HEADCOUNT',
	CODE_FELWARE: 'FELWARE',
	CODE_WORKTIME: 'WORKTIME',
	CODE_OFFICE: 'OFFICE',
	CODE_LABORPROTECT: 'LABORPROTECT',
	CODE_TRAVEL: 'TRAVEL',
}

export const OPT_CAT =
{
	OPT_UPT: 'OPT_UPT', //设备更新
	OPT_FIX: 'OPT_FIX',//检维修
	OPT_EXM: 'OPT_EXM', //检验检测
	OPT_ACC_UPD: 'OPT_ACC_UPD', //检验检测
	OPT_MTN: 'OPT_MTN', //检验检测
	OPT_STP: 'OPT_STP', //装置停检

}
// {
// 'active' : '#1a237e',
// 'inactive' : '#1a237e',
// 'new' : '#1a237e',
// 'submitted' : '#056f34',
// 'rejected' : '#f4511e',
// 'finalized' : '#835af5',
// 'signed' : '#1a237e',
// 'passed' : '#835af5',
// 'reviewing': '#f4511e',
// 'draft' : '#cdeefd',
// 'default' : '#cdeefd',
// }

export const INSPECTION_DICT_TYPES =
{
	ROOTCAUSE: 'ROOTCAUSE',
	SEVERITY: 'SEVERITY',
	DISCIPLINE: 'DISCIPLINE',
	OITYPE: 'OITYPE',
	ORESULTTYPE: 'ORESULTTYPE',
	FACTORTYPE: 'FACTORTYPE',
	RESULTTYPE: 'RESULTTYPE',
	INSTYPE: 'INSTYPE',
	CHKTYPE: 'CHKTYPE',
	PLANTYPE: 'PLANTYPE',
	DOCTYPE: 'DOCTYPE',
	OCHKTYPE: 'OCHKTYPE',
	IMPACTLEVEL: 'IMPACTLEVEL',
	SCHEMECAT: 'SCHEMECAT'
}

export const MANUFACTURE_SERVICE_TYPE = {
	COMMISSION: 'COMMISSION',
	OUTER: 'OUTER',
	INNER: 'INNER',
}

export const MANUFACTURE_SERVICE_REPORT_DIM = {
	ALL: 'ALL',
	PARENT_COMPANY: 'PARENT_COMPANY',
	SUB_COMPANY: 'SUB_COMPANY',
}

export const STATUS_VALUE =
{
	STATUS_ACTIVE: 'active',
	STATUS_DONE: 'done',
	STATUS_NEW: 'new',
	STATUS_REJECTED: 'rejected',
	STATUS_SUBMITTED: 'submitted',
	STATUS_APPROVED: 'approved',
	STATUS_FINALIZED: 'finalized',
	STATUS_INACTIVE: 'inactive',
	STATUS_ACCEPTANCED: 'acceptanced',

	STATUS_TERMINATED: 'terminated',
	STATUS_PAUSED: 'paused',
	STATUS_INVALID: 'invalid',
	STATUS_INVALID_ALL: 'invalidAll',
}


export const SAFETY_ACTIVITY_ICONS =
{
	0: <Class fontSize="small" />,
	1: <NightsStay fontSize="small" />,
	2: <GroupWork fontSize="small" />,
	3: <PlaylistAddCheck fontSize="small" />,
	4: <PlaylistAddCheck fontSize="small" />,
}

export const USED_MATERIAL_GROUPS = [
	1,//	能耗
	2,//	油耗
	106965,//	LNG
	106967,//	编织袋
	106968,//	纸箱
	106969,//	重载膜袋
	106971,//	编织袋
	107025,//	工业产品
	140537,//	重载膜卷
	142114,//	固废处理
	648758,//	聚乙烯
	648763,//	纸制品
	648764,//	白土原料
	648895,//	50Kg石蜡包装袋（外皮）
	648896,//	50Kg石蜡包装袋（内袋）
	648897,//	25Kg石蜡包装袋（外皮）
	648898,//	25Kg石蜡包装袋（内袋）
	106955,//   润滑油
]

export const RAW_MATERIAL_GROUPS = [
	1,//	能耗
	2,//	油耗
	106965,//	LNG
	106967,//	编织袋
	106968,//	纸箱
	106969,//	重载膜袋
	106971,//	编织袋
	107025,//	工业产品
	140537,//	重载膜卷
	142114,//	固废处理
	648758,//	聚乙烯
	648763,//	纸制品
	648764,//	白土原料
	648895,//	50Kg石蜡包装袋（外皮）
	648896,//	50Kg石蜡包装袋（内袋）
	648897,//	25Kg石蜡包装袋（外皮）
	648898,//	25Kg石蜡包装袋（内袋）
	106955,//   润滑油
]

// export const RAW_MATERIAL_GROUPS = [
// 	106996,
// 	648756,
// 	106960,
// 	648757,
// 	106980,
// 	107013,
// 	107004,
// 	133992,
// 	648017,
// 	106997,
// 	648013,
// 	131427,
// 	648776,
// 	107014,
// 	107001,
// 	107012,
// 	137039146,
// 	107019,
// 	107006,
// 	4448071,
// 	107018,
// 	106989,
// 	106964,
// 	648020,
// 	106986,
// 	106979,
// 	648767,
// 	648014,
// 	107008,
// 	106959,
// 	107021,
// 	106965,
// 	106956,
// 	648763,
// 	106969,
// 	106994,
// 	648765,
// 	106976,
// 	107009,
// 	4448070,
// 	648766,
// 	106991,
// 	4448212,
// 	117062,
// 	106984,
// 	117069,
// 	107010,
// 	106977,
// 	106985,
// 	106981,
// 	106988,
// 	106999,
// 	107022,
// 	648758,
// 	107023,
// 	106993,
// 	107003,
// 	106990,
// 	144876,
// 	107017,
// 	106998,
// 	107000,
// 	106992,
// 	648764,
// 	107005,
// 	106982,
// 	107025,
// 	106983,
// 	648768,
// 	106957,
// 	106978,
// 	136184,
// 	107015,
// 	106975,
// 	106968]

export const getNameByTypeAndCode = (constants, type, code) => {
	// only for non-hierarchical constants
	if (constants[type]) {
		for (let o of constants[type]) {
			if (o.code === code) {
				return o.name;
			}
		}
	}
	return null;
}

export const getActualCompany = (companyList) => {
	return companyList.filter(comp => comp.amoebaType === 'COST')
}

export const filterAmoebaCompany = (companyList) => {
	return _.filter(companyList, c => { return c.amoebaType !== COMPANY_AMOEBA_TYPE_OTHER })
}

export const getManageDpt = (dptList) => {
	return dptList.filter(dpt => (dpt.executable === false && !dpt.pid && dpt.amoebaType !== COMPANY_AMOEBA_TYPE_OTHER))
}

export const getExecuteDpt = (dptList) => {
	return dptList.filter(dpt => (dpt.executable === true && dpt.amoebaType !== COMPANY_AMOEBA_TYPE_OTHER))
}

/**
 * convert array to name-value array
 * @param list the original array
 * @param n the attribute to be the name
 * @param v the attribute to be the value
 */
export const convertToNameValue = (list, n, v) => {
	let newList = [];
	if (list) {
		list.forEach(element => {
			newList.push({
				name: element[n],
				value: element[v]
			});
		});
	}
	return newList;
}

/**
 * convert array to tree data
 * 
 * @param list array
 * @param leafOnly true: enable leaf node only, false: enable all nodes
 * @param name the label of array element
 * @param code the value of array element
 * @param pcode the parent identity of array element
 */
export const formSelectTree = (list, leafOnly, name, code, pcode) => {
	let treeList = [];
	name = name ? name : 'name';
	code = code ? code : 'code';
	pcode = pcode ? pcode : 'pcode';
	list.forEach(element => {
		treeList.push({
			label: element[name],
			value: element[code],
			pcode: element[pcode]
		});
	});

	treeList.forEach(element => {
		if (element.pcode) {
			treeList.forEach(item => {
				if (item.value === element.pcode) {
					if (!item.children) {
						item.children = [];
					}
					item.children.push(element);
					if (leafOnly) {
						item['disabled'] = true;
					}
				} else {
					if (!item.children) {
						item.children = [];
					}
				}
			});
		}
	});

	return _.filter(treeList, function (o) { return !o.pcode; });
}

/**
 * find the parent of target item
 * @param pid the parent id of target item
 * @param matchItem the parent attribute of tree data
 * @param data the tree data
 */
export const findParent = (pid, matchItem, data) => {
	let p;
	function ploop(val, children) {
		for (let i = 0; i < children.length; i++) {
			let item = children[i];
			if (val === item[matchItem]) {
				p = item;
				return;
			}
			if (item.children) {
				ploop(val, item.children);
			}
		}
	}
	ploop(pid, data);
	return p;
}

/**
 * find the full path to target node
 * @param value the value of target node
 * @param data the tree data
 */
export const getTreePartById = (value, tree) => {
	let sel = [];
	function loop(selected, children) {
		for (let i = 0; i < children.length; i++) {
			const item = children[i];
			if (selected === item.value) {
				sel.push(item);
				return;
			}
			if (item.children) {
				loop(selected, item.children);
			}
		}
	}
	loop(value, tree);
	return sel;
}

export const getTreePartByIdUseKey = (value, tree, key = 'value') => {
	let sel = [];
	function loop(selected, children) {
		for (let i = 0; i < children.length; i++) {
			const item = children[i];
			if (selected === item[key]) {
				sel.push(item);
				return;
			}
			if (item.children) {
				loop(selected, item.children);
			}
		}
	}
	loop(value, tree);
	return sel;
}

/**
 * find the full path to target node
 * @param value the value of target node
 * @param data the tree data
 */
export const findPath = (value, data) => {
	const sel = [];
	function loop(selected, children) {
		for (let i = 0; i < children.length; i++) {
			const item = children[i];
			if (selected === item.value) {
				sel.push(item);
				return;
			}
			if (item.children) {
				loop(selected, item.children);
				if (sel.length) {
					sel.push(item);
					return;
				}
			}
		}
	}
	loop(value, data);
	return sel;
}

export const findDptNameById = (constant, dptId) => {
	if (!constant || !constant.departments) return dptId
	const dpt = _.find(constant.departments, d => { return d.dptId === dptId })
	if (dpt)
		return dpt.dptName
	return dptId
}

export const findBuNameById = (constant, unitId) => {
	if (!constant || !constant.businessunits) return unitId
	const unit = _.find(constant.businessunits, b => { return b.unitId === unitId })
	if (unit)
		return unit.unitName
	return unitId
}

export const findCompanyNameById = (constant, companyId) => {
	if (!constant || !constant.companies) return companyId
	const company = _.find(constant.companies, com => { return com.companyId === companyId })
	if (company)
		return company.companyName
	return companyId
}

export const getManufactureTypeName = (constant, type, code) => {
	const dics = filterManufactureTypesByType(constant, type)
	if (!constant || !dics) return code
	const dic = _.find(dics, d => { return d.code === code })
	if (dic)
		return dic.name
	return code
}

export const filterManufactureTypesByType = (constant, type) => {
	if (!constant.manufactureDicts)
		return []
	return _.filter(constant.manufactureDicts, function (o) { return o.type === type })
}


export const getGoalStatusByCode = (status) => {

	if (!status)
		return ''
	if (status === 'new')
		return '起草中'
	if (status === 'submitted')
		return '已提交'
	if (status === 'rejected')
		return '已退回'
	if (status === 'reviewing')
		return '签字中'
	if (status === 'reviewed')
		return '签字完成'
	if (status === 'finalized')
		return '已完成'
	if (status === 'inactive')
		return '失效'

	return status

}

export const getGoalEditableByCode = (status) => {
	if (status === 'new' || status === 'rejected')
		return true
	return false
}

export const getPlanEditableByCode = (status) => {
	if (status === 'new' || status === 'rejected')
		return true
	return false
}

export const getPlanStatusByCode = (status) => {

	if (!status)
		return ''
	if (status === 'new')
		return '起草中'
	if (status === 'submitted')
		return '已提交'
	if (status === 'rejected')
		return '已退回'
	if (status === 'reviewing')
		return '签字中'
	if (status === 'finalized')
		return '已完成'
	if (status === 'inactive')
		return '失效'
	return status
}

export const filterLSDictByType = (constant, type) => {
	if (!constant.leadershipDictionaries)
		return []
	return _.filter(constant.leadershipDictionaries, function (o) { return o.type === type })

}

export const getProcessStatusNamebyCode = (constant, code) => {
	if (!code)
		return '未启动'
	if (!constant.processStatus)
		return code

	let status = _.find(constant.processStatus, (status) => { return status.code === code })

	if (status)
		return status.name
	return code
}

export const getPlanNamebyCode = (constant, code) => {
	if (!constant.planTitle)
		return code

	let pp = _.find(constant.planProcess, (o) => { return o.code === code })

	if (pp)
		return pp.name
	return code
}

export const getMandatoryRegulationNameById = (constant, mandaReg) => {

	if (!constant.mandatoryRegulations)
		return ''

	for (let mandatoryRegulation of constant.mandatoryRegulations) {
		if (mandatoryRegulation.regId === mandaReg)
			return mandatoryRegulation.regName
	}
	return mandaReg
}


const HOLIDAYTYPE = {
	'VAC': '',
	'COM': '补班',
	'CSM': '调休',
}
export const getHolidayLabel = (holiday) => {
	if (!holiday)
		return '';
	if (!HOLIDAYTYPE.hasOwnProperty(holiday.holidayType))
		return holiday.holidayName + '定制节假日'
	let typeName = HOLIDAYTYPE[holiday.holidayType]

	return holiday.holidayName + typeName
}

/**
 * 筛选常量字典，返回列表
 * 用于下拉列表(于hazardInfo实装)
 * 
 * @param {Array} dictionaries 字典
 * @param {String} type 类型
 * @param {Boolean|String} pcode 某类型下主类别码
 * 
 * @returns {Array} 筛选后的列表
 */
export function getOptionList(dictionaries, type, pcode) {

	// 取某个类型下所有主类别
	if (!pcode) {
		return _.filter(dictionaries, { 'type': type, 'pcode': null });
	}
	// 取某个类型下所有子类别
	if (pcode && !pcode.length) {
		return _.filter(dictionaries,
			(item) => (item.type === type && item.pcode !== null)
		);
	}
	// 取某个类型下某个主类别的所有子类别
	if (pcode && pcode.length && pcode.length > 0) {
		return _.filter(dictionaries, { 'type': type, 'pcode': pcode });
	}
}

export const sortByStepOrder = (inData) => {
	let outData = []

	let temp = _.filter(inData, function (o) { return !o.preApp; });

	function ploop(val, data, array) {
		for (let i = 0; i < data.length; i++) {
			let item = data[i];
			if (val === item.preApp) {
				array.push(item)
				ploop(item.appId, data, array);
			}
		}
		return
	}
	if (temp.length > 0) {
		temp.forEach(element => {
			outData.push(element);
			ploop(element.appId, inData, outData);
		});
	} else {
		outData = inData;
	}
	return outData;
}

export const arrayToTree = (list, idKey, pidkey) => {
	list.forEach(element => {
		if (element[pidkey]) {
			list.forEach(item => {
				if (item[idKey] === element[pidkey]) {
					if (!item.children) {
						item.children = [];
					}
					item.children.push(element);
				}
			});
		}
	});

	return _.filter(list, function (o) { return !o[pidkey]; });
}

export const treeToArray = (list, newArr = []) => {
	list.forEach((item) => {
		const tempItem = _.cloneDeep(item);
		const { children } = tempItem;
		if (children) {
			delete tempItem.children;

			if (children.length) {
				newArr.push(tempItem);
				return treeToArray(children, newArr);
			}
		}
		newArr.push(tempItem);
	});
	return newArr;
}

export const findSameLevel = (list, id, idKey, pidkey) => {
	let array = [];
	list.forEach(element => {
		if (element[idKey] === id) {
			array.push(element)
			if (element[pidkey]) {
				list.forEach(element1 => {
					if (element1[pidkey] === element[pidkey]) {
						array.push(element1)
					}
				})
			}
		}
	});

	array = _.uniq(array);

	return array;
}
