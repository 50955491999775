import { createAction, handleActions } from 'redux-actions';
// import { handleActions } from 'redux-actions';
// import * as _ from 'lodash'
import { changeExistTreeData } from './deviceTree'

const initialState = {
  canvasWidth: window.innerWidth - 48,
  canvasHeight: window.innerHeight - 108,
  konvaWidth: 6000,
  konvaHeight: 3000,
  zoom: 100,
  beginX: 100,
  beginY: 100,
  zIndex: 1,
  dialogshow: false,
  gridSize: 30,
  isFullScreen: false,
  scaleBy: 1.04,
  scale: 1,
  unitView: true
}

export const setSetting = createAction('@@xcloud/setting/setSetting');
export const updGridSize = createAction('@@xcloud/setting/updGridSize');
export const fullScreenSetting = createAction('@@xcloud/setting/fullScreenSetting');
export const exitFullScreenSetting = createAction('@@xcloud/setting/exitFullScreenSetting');
export const setScale = createAction('@@xcloud/setting/setScale');
export const updateUnitView = createAction('@@xcloud/setting/updateUnitView',
  () => (dispatch, getState) => {
    dispatch(changeExistTreeData(!getState().setting.unitView));
  });

export const settingReducer = handleActions(
  {
    [setSetting]: (state) => {
      return {
        ...state
      }
    },
    [fullScreenSetting]: (state) => {
      return {
        ...state,
        canvasWidth: window.innerWidth,
        canvasHeight: window.innerHeight,
        isFullScreen: true
      }
    },
    [exitFullScreenSetting]: (state) => {
      return {
        ...state,
        canvasWidth: window.innerWidth - 48,
        canvasHeight: window.innerHeight - 188,
        isFullScreen: false
      }
    },
    [updGridSize]: (state, { payload: value }) => {
      return {
        ...state,
        gridSize: value
      }
    },
    [setScale]: (state, { payload: scale }) => {
      return {
        ...state,
        scale: scale
      }
    },
    [updateUnitView]: (state) => {
      return {
        ...state,
        unitView: !state.unitView
      }
    },
  },
  initialState
)

export default settingReducer