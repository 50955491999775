import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    //DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const AddRegulationPannel = ({
    classes,
    open,
    regulation,
    constant,
    onhandleClose,
    onhandleConfirm,
    onhandleChange
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">新增规章制度</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                placeholder="请输入"
                                label='规章制度编号'
                                value={regulation.regNo ? regulation.regNo : ''}
                                name='regNo'
                                onChange={onhandleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                label='业务分类'
                                select
                                value={regulation.unitId ? regulation.unitId : ''}
                                name='unitId'
                                onChange={onhandleChange}>
                                {constant.businessunits.map((bu) => (
                                    <MenuItem key={bu.unitId} value={bu.unitId}>
                                        {bu.unitName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                placeholder="请输入"
                                label='规章制度名称'
                                value={regulation.regName ? regulation.regName : ''}
                                name='regName'
                                onChange={onhandleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                placeholder="请输入"
                                label='修订周期'
                                value={regulation.revisionCycle ? regulation.revisionCycle : ''}
                                name='revisionCycle'
                                type='numeric'
                                onChange={onhandleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                label='修订周期单位'
                                select
                                value={regulation.revisionUnit ? regulation.revisionUnit : ''}
                                name='revisionUnit'
                                onChange={onhandleChange}>
                                <MenuItem key={1} value={'YEAR'}>年</MenuItem>
                                <MenuItem key={2} value={'MONTH'}>月</MenuItem>
                                <MenuItem key={3} value={'WEEK'}>周</MenuItem>
                                <MenuItem key={4} value={'DAY'}>日</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                label='强制性规章制度'
                                select
                                value={regulation.mandaReg ? regulation.mandaReg : ''}
                                name='mandaReg'
                                onChange={onhandleChange}>
                                {constant.mandatoryRegulations.map((mr) => (
                                    <MenuItem key={mr.regId} value={mr.regId}>
                                        {mr.regName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.DialogActions}>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>
                        新增</Button>
                </DialogActions>
            </Dialog>
        </div >


    );
};
AddRegulationPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};

AddRegulationPannel.defaultProps = {

}

export default withStyles(AddRegulationPannel);