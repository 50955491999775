import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    detailtips: {
        paddingLeft: theme.spacing(2)
    },
    contentContainer:{
        flexDirection:'column'
    },
    textfield:{
        minWidth: 400
    }

});

export default withStyles(styles);