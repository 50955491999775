import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20
  },
  textField: {
    minWidth: 300
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading:{
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
