import React, { Component } from 'react';
import "./index.css";
// import Konva from 'konva';
import * as _ from 'lodash';
// import KonvaUtil from "../devices/util";
// const konvaUtil = new KonvaUtil();

export default class ContentMenuView extends Component {
    constructor(props) {
        super(props)
        this._colseMenu = this._colseMenu.bind(this);
    }

    _colseMenu() {
        const { cleanContentMenu } = this.props;
        cleanContentMenu();
    }


    _showChart() {
        const { parameters, cleanContentMenu, handleCharts } = this.props;
        let ids = _.split(parameters.id, '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            handleCharts({ deviceId: id });
            cleanContentMenu();
        }
    }



    _mainContent = () => {
        if (this.props.show) {
            return (
                <div className="menu" style={{ top: this.props.top, left: this.props.left }}>
                    <div>
                        <button className="control-button" onClick={this._showChart.bind(this)} > 运行状态</button>
                        {/* <button className="control-button" onClick={this._addTransformer.bind(this)}>置于顶层</button>
                        <button className="control-button" onClick={this._addTransformer.bind(this)}>置于底层</button> */}
                        <button className="close-button" onClick={this._colseMenu.bind(this)}>关闭</button>
                    </div>
                </div >
            );
        }

        return null;
    };

    render() {
        return (
            this._mainContent()
        );
    }
}