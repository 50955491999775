import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MGenerateFixComponent from '@/components/ManufactureManagement/ManufactureOrder/MGenerateFixComponent';
import { generateFixAmountOrders } from '@/redux/modules/manufacture/manufactureOrder';
import { getManufactureServiceById } from '@/redux/modules/manufacture/manufactureService'
import { createMessage } from '@/redux/modules/message'

import { getMoment } from '@/utils/datetime'
import * as _ from 'lodash';


/**
 * 生产工单
 */
class MGenerateFixContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            fixAmount: {
                fixDate: getMoment()().subtract(1, 'months'),
                serviceId: 0,
                allocation: 0,
                serviceCompanyId: 0,
                serviceDpts: [],
            },
            dptsSelect: []
        }
    }

    handleGenerateOrders = async () => {
        const { onhandleGenerateOrders, onhandleCloseFixAmount, onhandleMessage } = this.props
        const { fixAmount } = this.state

        if (fixAmount.allocation !== 0
            && fixAmount.companyId
            && fixAmount.dpts
            && fixAmount.dpts.length > 0
            && fixAmount.serviceId) {
            const fixAmountDto = {
                year: fixAmount.fixDate.year(),
                month: fixAmount.fixDate.month() + 1,
                serviceId: fixAmount.serviceId,
                //serviceCompanyId: fixAmount.serviceCompanyId,
                //serviceDptIds: fixAmount.serviceDpts,
                allocation: Number(fixAmount.allocation)
            }
            if (fixAmount.serviceType === 'COMMISSION') { //外委服务
                fixAmountDto.requireCompanyId = fixAmount.companyId
                fixAmountDto.requireDptIds = fixAmount.dpts
            } else {//对内对外服务
                fixAmountDto.serviceCompanyId = fixAmount.companyId
                fixAmountDto.serviceDptIds = fixAmount.dpts
            }

            const res = await onhandleGenerateOrders(fixAmountDto)
            if (res.payload) {
                this.setState({
                    fixAmount: {
                        fixDate: getMoment()().subtract(1, 'months'),
                        serviceId: 0,
                        serviceType: '',
                        allocation: 0,
                        companyId: 0,
                        dpts: [],
                    }
                })
                onhandleCloseFixAmount()
            }
        } else {
            onhandleMessage('请填写完整信息')
        }
    }

    handleChange = async (event) => {
        const { onhandleGetServiceById, currentUserInfo, departments } = this.props
        const { name, value } = event.target;
        let newFixAmount = { ...this.state.fixAmount }
        newFixAmount[name] = value;

        if (name === 'serviceId') {
            let service = await onhandleGetServiceById(value);
            service = service.payload
            let dptIds = []
            let dptsSelect = []
            if (service.serviceType === 'COMMISSION') { //外委服务
                dptIds = _.chain(service.serviceDpts)
                    .filter(sd => { return sd.dependType = 'REQUIRE' })
                    .map('dptId').value()
                if (dptIds && dptIds.length > 0) { //有需方班组
                    newFixAmount.dpts = dptIds
                    dptsSelect = dptIds
                } else { //无需方班组
                    dptIds = _.chain(departments)
                        .filter(d => { return d.companyId === currentUserInfo.companyId })
                        .map('dptId').value()
                    newFixAmount.dpts = []
                    dptsSelect = dptIds
                }
                newFixAmount.companyId = currentUserInfo.companyId
            } else { //对内对外服务
                dptIds = _.chain(service.serviceDpts).filter(sd => { return sd.dependType = 'SERVICE' }).map('dptId').value()
                newFixAmount.companyId = service.serviceCompany
                newFixAmount.dpts = dptIds
                dptsSelect = dptIds
            }
            newFixAmount.serviceType = service.serviceType
            newFixAmount.unit = service.unit
            this.setState({ dptsSelect: dptsSelect })
        }
        this.setState({ fixAmount: newFixAmount })
    }

    handleDateChange = (date) => {
        let newFixAmount = { ...this.state.fixAmount }
        newFixAmount.fixDate = getMoment()(date)
        this.setState({ fixAmount: newFixAmount })
    }

    handleServiceDptsChange = (event, dpts) => {
        let fixAmount = {
            ...this.state.fixAmount,
            dpts: dpts,
        }
        this.setState({ fixAmount: fixAmount })
    }

    handleClose = () => {

        const { onhandleCloseFixAmount } = this.props
        onhandleCloseFixAmount()
    }

    render() {
        return (
            <div>
                <MGenerateFixComponent
                    open={this.props.open}
                    fixAmount={this.state.fixAmount}
                    dptsSelect={this.state.dptsSelect}
                    services={this.props.services}
                    departments={this.props.departments}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleGenerate={this.handleGenerateOrders.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleServiceDptsChange={this.handleServiceDptsChange.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        services: state.manufactureService.services,
        departments: state.department.departments.list,
        currentUserInfo: state.auth.currentUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGenerateOrders: (data) => {
            return dispatch(generateFixAmountOrders(data));
        },
        onhandleGetServiceById: (serviceId) => {
            return dispatch(getManufactureServiceById(serviceId));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MGenerateFixContainer);
