import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    hazard: {
        hid: undefined,
        reason: '',
        opentextBkup: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openHazardClosePanel = createAction('@@xcloud/inspectionSchemeClose/open',
    (
        confirmCallback, closeCallback, opentext, hid
    ) => ({
        confirmCallback, closeCallback, opentext, hid
    })
);

export const closeHazardClosePanel = createAction('@@xcloud/inspectionSchemeClose/close');

export const changeHazard = createAction('@@xcloud/inspectionSchemeClose/changeHazard');

// Actions

export const InspectionHazardCloseReducer = handleActions(
    {
        [openHazardClosePanel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                hazard: {
                    hid: data.hid,
                    reason: '',
                    opentextBkup: data.opentext
                },
            };
        },
        [closeHazardClosePanel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeHazard]: (state, { payload: data }) => {
            return {
                ...state,
                hazard: data,
            };
        }
    },
    initialState
);

export default InspectionHazardCloseReducer;
