import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import ProcessList from "../../../containers/SystemManagement/ProcessManagement/ProcessList";

const ProcessManagement = props => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ProcessList/>
            </div>
        </div>
    );
};
ProcessManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(ProcessManagement);
