import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createMessage, createhttpMessage } from '@/redux/modules/message';
import {
    getListTypeList,
    getCategoryList
} from "@/redux/modules/inspection/riskInspection";
import {
    getPlanResults,
    getPlanRecords,
    setCheckUserList
} from "@/redux/modules/inspection/riskInvestigationPlanMonthly"
import { getAllLeaders, getUsersWithCallback } from '@/redux/modules/user';
import { createloading, cleanloading } from '@/redux/modules/loading';
import moment from 'moment';
import _ from 'lodash';


const porxyApi = '/api'

const currentYear = moment().format("YYYY");
const planYear = moment().add(1, 'year').format("YYYY");

const initialState = {
    plan: {}, // 特定id查到的计划
    plans: [],
    currentPlan: {}, // 制定中的来年计划
    planDetails: [],
    currentPlanDetail: {},
    planDetailChange: false,
    planDetailError: {},

    plansConfig: {},
    planCofig: {},
    planDetailConfig: {},
    planDetailTableConfig: {},

    isEditablePlan: false,
    isEditablePlanDetail: false,
};


export const setPlan = createAction('@@xcloud/inspectionplan/setplan')
export const setPlanConfig = createAction('@@xcloud/inspectionplan/setplanconfig')

export const setPlans = createAction('@@xcloud/inspectionplan/setplans')
export const setPlansConfig = createAction('@@xcloud/inspectionplan/setplansconfig')

export const setIsEditablePlan = createAction('@@xcloud/inspectionplan/setiseditableplan')

export const setCurrentPlan = createAction('@@xcloud/inspectionplan/setcurrentplan')
export const setExecutionPlan = createAction('@@xcloud/inspectionplan/setexecutionplan')

export const setPlanDetails = createAction('@@xcloud/inspectionplan/setplandetails')
export const setPlanDetailConfig = createAction('@@xcloud/inspectionplan/setplandetailsconfig')

export const setPlanDetailTableConfig = createAction('@@xcloud/inspectionplan/setplandetailsTableconfig')

export const setCurrentPlanDetail = createAction('@@xcloud/inspectionplan/setCurrentPlanDetail')
export const setPlanDetailChange = createAction('@@xcloud/inspectionplan/setPlanDetailChange')
export const setPlanDetailError = createAction('@@xcloud/inspectionplan/setPlanDetailError')

/**
 * Inspection Plan
 */
export const addPlans = createAction('@@xcloud/inspectionplan/addplans',
    (plan, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.post(porxyApi + '/inspectionplans', plan)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "创建风险研判计划成功"));
                    if (callback)
                        callback(res.data)
                }

            }).catch(err => {
                dispatch(cleanloading());
                let msg = `创建风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deletePlan = createAction('@@xcloud/inspectionplan/deleteplan',
    (planId, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.delete(porxyApi + '/inspectionplans/' + planId)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "删除风险研判计划成功"));
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `删除风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    })

export const updatePlans = createAction('@@xcloud/inspectionplan/updateplans',
    (plan, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.patch(porxyApi + '/inspectionplans', plan)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "更新风险研判计划成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `更新风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const changePlanStatus = createAction('@@xcloud/inspectionplan/changePlanStatus',
    (planId, status, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let to = {
            status: status.toLowerCase(),
            planId: planId
        }
        httpClient.patch(porxyApi + '/inspectionplans', to)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    // dispatch(createhttpMessage(res, "更新风险研判计划状态完成"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                dispatch(cleanloading());
                let msg = `风险研判计划变更失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getPlan = createAction('@@xcloud/inspectionplan/getplan',
    (planId) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.get(porxyApi + '/inspectionplans/' + planId)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(setPlan(res.data))
                }

            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `获取风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const getPlans = createAction('@@xcloud/inspectionplan/getplans',
    () => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.get(porxyApi + '/inspectionplans')
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(setPlans(res.data))
                    //dispatch(createhttpMessage(res, "获取风险研判计划列表成功"));
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `获取风险研判计划列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const getCurrentPlan = createAction('@@xcloud/inspectionplan/getcurrrentplan',
    (callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        // httpClient.get(porxyApi + '/inspectionplans/current')
        // 直接按年份获取来年计划
        httpClient.get(porxyApi + '/inspectionplans?year=' + planYear + '&planType=ANNU')
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(setCurrentPlan(res.data[0]))
                    //dispatch(createhttpMessage(res, "获取当前风险研判计划成功"));
                    if (callback)
                        callback(res.data[0])
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `获取当前风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getExecutionPlan = createAction('@@xcloud/inspectionplan/getexecutionplan',
    (callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.get(porxyApi + '/inspectionplans?year=' + currentYear + '&planType=ANNU')
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(setExecutionPlan(res.data[0]))
                    //dispatch(createhttpMessage(res, "获取风险研判计划列表成功"));
                    if (callback)
                        callback(res.data[0])
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `获取本年度风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

/**
* Inspection Temp Plan / Inspection Plan Detail
*/
export const addPlanDetail = createAction('@@xcloud/inspectionplan/addplandetail',
    (planDetail, callback, isTempPlan) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        // 临时计划+计划详情(当年) or 计划详情(来年)
        const url = isTempPlan ? '/inspectionplans/temporaries' : '/inspectionplans/details';
        httpClient.post(porxyApi + url, planDetail)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, isTempPlan ? '新建风险研判计划成功' : '增加风险研判计划详情成功'));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = isTempPlan ? '新建风险研判计划失败' : '增加风险研判计划详情失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deletePlanDetail = createAction('@@xcloud/inspectionplan/addplandetail',
    (planDetail, callback, isTempPlan) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        // 临时计划+计划详情(当年) or 计划详情(来年)
        const url = isTempPlan ? '/inspectionplans/temporaries' : '/inspectionplans/details';
        httpClient.post(porxyApi + url, planDetail)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, isTempPlan ? '新建风险研判计划成功' : '增加风险研判计划详情成功'));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = isTempPlan ? '新建风险研判计划失败' : '增加风险研判计划详情失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const updatePlanDetails = createAction('@@xcloud/inspectionplan/updateplandetails',
    (dtlId, planDetails, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.patch(porxyApi + '/inspectionplans/details/' + dtlId, planDetails)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "更新风险研判计划详情成功"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                dispatch(cleanloading());
                let msg = `更新风险研判计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const changePlanDetailStatus = createAction('@@xcloud/inspectionplan/changePlanDetailStatus',
    (dtlId, status, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let to = {
            status: status.toLowerCase(),
            dtlId: dtlId
        }
        httpClient.patch(porxyApi + '/inspectionplans/details/' + dtlId, to)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "风险研判计划详情变更完成"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                dispatch(cleanloading());
                let msg = `风险研判计划详情变更失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startPlanDetails = createAction('@@xcloud/inspectionplan/startPlanDetails',
    (query, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = porxyApi + '/inspectionplans/details/start?';
        if (query.planId)
            url += "planId=" + query.planId;
        if (query.dtlId)
            url += "&dtlId=" + query.dtlId;
        if (query.companyId)
            url += "&companyId=" + query.companyId;
        if (query.planType)
            url += "&planType=" + query.planType;
        if (query.listType)
            url += "&inspectType=" + query.listType;
        if (query.yearMonth)
            url += "&yearMonth=" + moment(query.yearMonth).format('yyyy-MM');
        httpClient.get(url)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createMessage('success', "启动风险研判计划成功"));
                    if (callback)
                        callback(res.data);
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `启动风险研判计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getPlanDetails = createAction('@@xcloud/inspectionplan/getplandetails',
    (query) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = porxyApi + '/inspectionplans/details?';
        if (query.planId)
            url += "planId=" + query.planId;
        if (query.dtlId)
            url += "&dtlId=" + query.dtlId;
        if (query.companyId)
            url += "&companyId=" + query.companyId;
        if (query.planType)
            url += "&planType=" + query.planType;
        if (query.inspectType){
            url += "&inspectType=" + query.inspectType;
        }else{
            url += "&inspectType=INS11"
        }
        if (query.yearMonth)
            url += "&yearMonth=" + moment(query.yearMonth).format('yyyy-MM');
        if (query.status)
            url += "&status=" + query.status;
        if (query.targetId)
            url += "&targetId=" + query.targetId;
        if (query.inspectHead)
            url += "&inspectHead=" + query.inspectHead;
        httpClient.get(url)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    //dispatch(createhttpMessage(res, "获取风险研判计划详情列表成功"));
                    dispatch(setPlanDetails(res.data))
                }
            })
            .catch(err => {
                dispatch(cleanloading());
                let msg = `获取风险研判计划详情列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const clearPlanDetails = createAction('@@xcloud/inspectionplan/clearPlanDetails',
    (query) => (dispatch, getState, httpClient) => {
        dispatch(setPlanDetails([]));
    });

export const getPlanDetailById = createAction('@@xcloud/inspectionplan/getPlanDetailById',
    (dtlId, callback) => (dispatch, getState, httpClient) => {
        const inspectionDict = getState().constant.inspectionDictionaries;
        let url = porxyApi + '/inspectionplans/details?';
        if (dtlId && dtlId !== '')
            url += "&dtlId=" + dtlId;
        httpClient.get(url)
            .then((res) => {
                if (res && res.data && res.data[0]) {
                    // 拆分排查类别，特殊排查子类别
                    res.data[0].listType = res.data[0].inspectType.substr(0, 5);
                    if (_.find(inspectionDict, { 'pcode': res.data[0].listType })) {
                        res.data[0].catCode = res.data[0].inspectType;
                    }
                    // 拆分部门对象数组用于UI显示部门检查人
                    res.data[0].inspectionDptUserList = [];
                    if (res.data[0].inspectionDptList) {
                        res.data[0].inspectionDptList.forEach(o => {
                            res.data[0].inspectionDptUserList.push(
                                { dptId: o.dptId, userList: [] }
                            );
                        })
                    }
                    dispatch(setCurrentPlanDetail(res.data[0]))

                    //dispatch(createhttpMessage(res, "获取风险研判计划详情列表成功"));
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `获取风险研判计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeProcess = createAction('@@xcloud/inspectionplan/excuteprocess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.post(porxyApi + '/inspectionplan/process', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "执行风险研判计划流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `执行风险研判计划流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startPlanExecution = createAction('@@xcloud/inspectionplan/startplanexecution',
    (plan, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.post(porxyApi + '/inspectionplans/execution/start', plan)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createMessage('success', "启动风险研判计划执行流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `启动风险研判计划执行流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const executeExecutionProcess = createAction('@@xcloud/inspectionplan/excuteexecutionprocess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        httpClient.post(porxyApi + '/inspectionplans/execution/process', actionParams)
            .then((res) => {
                dispatch(cleanloading());
                if (res) {
                    dispatch(createhttpMessage(res, "执行风险研判计划进展流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `执行风险研判计划进展流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

/*
* Monthly plan detail
*/

export const routeToShowRecords = createAction(
    '@@xcloud/inspectionplan/routeToShowRecords',
    (detail) => (dispatch, getState) => {
        dispatch(push('/inspection/riskinvestigation/record/' + detail.dtlId));
    }
);

export const initShowRecords = createAction(
    '@@xcloud/inspectionplan/initShowRecords',
    (dtlId) => (dispatch, getState) => {
        dispatch(createloading());
        return Promise.all([
            dispatch(getPlanDetailById(dtlId)),
            // 获取带有实施记录及详情
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            })),
            dispatch(getPlanRecords({
                offset: 0,
                size: 5,
                dtlId: dtlId,
            })),
            dispatch(getAllLeaders()),
        ]).then(() => {
            dispatch(getUsersWithCallback(
                {
                    offset: 0,
                    size: 0,
                    companyId: getState().inspectionPlan.currentPlanDetail.companyId
                },
                res => {
                    dispatch(setCheckUserList(res.list))
                }
            ))
        }).then(() => {
            dispatch(cleanloading());
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '初始化排查记录页失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);


export const routeToFiveSRecords = createAction(
    '@@xcloud/inspectionplan/routeToFiveSRecords',
    (detail) => (dispatch, getState) => {
        dispatch(push('/inspection/5splan/record/' + detail.dtlId));
    }
);

export const initFiveSRecords = createAction(
    '@@xcloud/inspectionplan/initFiveSRecords',
    (dtlId) => (dispatch, getState) => {
        dispatch(createloading());
        return Promise.all([
            dispatch(getPlanDetailById(dtlId)),
            // 获取带有实施记录及详情
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            })),
            dispatch(getPlanRecords({
                offset: 0,
                size: 0,
                dtlId: dtlId,
                result: 'false'
            })),
            // dispatch(getAllLeaders()),
        ]).then(() => {
            // dispatch(getUsersWithCallback(
            //     {
            //         offset: 0,
            //         size: 0,
            //         companyId: getState().inspectionPlan.currentPlanDetail.companyId
            //     },
            //     res => {
            //         dispatch(setCheckUserList(res.list))
            //     }
            // ))
        }).then(() => {
            dispatch(cleanloading());
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '初始化5S检查记录页失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const routeToDetailEdit = createAction(
    '@@xcloud/inspectionplan/routeToDetailEdit',
    (dtlId) => (dispatch, getState) => {
        dispatch(setCurrentPlanDetail({}));
        dispatch(push('/inspection/riskinvestigation/info/' + dtlId));
    }
)

export const initDetailEdit = createAction(
    '@@xcloud/inspectionplan/initDetailEdit',
    (dtlId) => (dispatch, getState) => {
        dispatch(createloading());

        const promiseList = [
            dispatch(getAllLeaders()),
            dispatch(getPlanDetailById(dtlId)),
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            }))
        ]
        const users = getState().user.users;
        if (!users || !users.list || users.list <= 0) {
            promiseList.push(
                dispatch(getUsersWithCallback({ offset: 0, size: 0 }))
            )
        }
        return Promise.all(promiseList).then(
            () => {
                dispatch(getListTypeList());
                dispatch(getCategoryList());
            }
        ).then(() => {
            dispatch(cleanloading());
            dispatch(initMonthlyPlan(false));
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                console.log(err)
                dispatch(cleanloading());
                let msg = '初始化详情页失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const initMonthlyPlan = createAction(
    '@@xcloud/inspectionplan/initMonthlyPlan',
    (isCreate) => (dispatch, getState) => {
        let terror = {
            "companyId": '',
            "department": '',
            "inspectType": '',
            "startTime": '',
            "inspectTime": '',
            "catCode": '',
            "inspectHead": '',
            "openText": '',
            "regId": '',
            "rootCause": ''
        };
        dispatch(setPlanDetailError(terror));
        dispatch(setPlanDetailChange(false));
    }
);

export const routeToFiveSDetailEdit = createAction(
    '@@xcloud/inspectionplan/routeToFiveSDetailEdit',
    (dtlId) => (dispatch, getState) => {
        dispatch(setCurrentPlanDetail({}));
        dispatch(push('/inspection/5splan/info/' + dtlId));
    }
)

export const initFiveSDetailEdit = createAction(
    '@@xcloud/inspectionplan/initFiveSDetailEdit',
    (dtlId) => (dispatch, getState) => {
        dispatch(createloading());

        const promiseList = [
            dispatch(getAllLeaders()),
            dispatch(getPlanDetailById(dtlId)),
            dispatch(getPlanResults({
                offset: 0,
                size: 0,
                dtlId: dtlId
            }))
        ]
        const users = getState().user.users;
        if (!users || !users.list || users.list <= 0) {
            promiseList.push(
                dispatch(getUsersWithCallback({ offset: 0, size: 0 }))
            )
        }
        return Promise.all(promiseList).then(
            () => {
                dispatch(getListTypeList());
                dispatch(getCategoryList());
            }
        ).then(() => {
            dispatch(cleanloading());
            dispatch(initMonthlyPlan(false));
        })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                console.log(err)
                dispatch(cleanloading());
                let msg = '初始化详情页失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const InspectionPlanReducer = handleActions(
    {
        [setPlan]: (state, { payload: data }) => {
            return {
                ...state,
                plan: data
            }
        },
        [setPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },

        [setPlanConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planConfig: data
            }
        },

        [setPlansConfig]: (state, { payload: data }) => {
            return {
                ...state,
                plansConfig: data
            }
        },


        [setIsEditablePlan]: (state, { payload: data }) => {
            return {
                ...state,
                isEditablePlan: data
            }
        },

        [setCurrentPlan]: (state, { payload: data }) => {
            return {
                ...state,
                currentPlan: data
            }
        },

        [setPlanDetailChange]: (state, { payload: value }) => {
            return {
                ...state,
                planDetailChange: value
            };
        },

        [setPlanDetailError]: (state, { payload: value }) => {
            return {
                ...state,
                planDetailError: value
            };
        },


        [setExecutionPlan]: (state, { payload: data }) => {
            return {
                ...state,
                executionPlan: data
            }
        },

        [setPlanDetails]: (state, { payload: data }) => {
            return {
                ...state,
                planDetails: data
            }
        },

        [setPlanDetailConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planDetailConfig: data
            }
        },

        [setPlanDetailTableConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planDetailTableConfig: data
            }
        },

        [setCurrentPlanDetail]: (state, { payload: data }) => {
            return {
                ...state,
                currentPlanDetail: data
            }
        }
    },
    initialState
)

export default InspectionPlanReducer

