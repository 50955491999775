import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CommonFileUpload } from '../components';
import { cleanmodal } from '../redux/modules/modal';

class CommonFileUploadPageContainer extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired
  }

  handeModalclose(event) {
    this.props.onModalclose();
  }

  render() {
    const { config } = this.props;

    return (
      <CommonFileUpload
        title={config.title}
        confirmText={config.confirmText}
        fileType={config.fileType}
        note={config.note}
        maxSize={config.maxSize}
        multiple={config.multiple}
        component={config.component}
        children={config.children}
        showStyle={config.showStyle}
        onClose={this.handeModalclose.bind(this)}
        onFileSelected={config.onFileSelected}
        onConfirm={config.onConfirm}
      />
    );
  }

  componentWillUnmount() {
    if (this.props.onModalclose) this.props.onModalclose();
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.modal.parameters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onModalclose: () => {
      dispatch(cleanmodal());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonFileUploadPageContainer);
