import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: 20,
    },
    toolbarTitle: {
        textAlign: 'center',
    },
    toobarOp: {
        position: 'absolute',
        right: '30px',
        bottom: '12px' 
    },
    toobarOpButton: {
        marginLeft: '20px',
        width: '80px'
    },
    activityDialogContent: {
        minHeight: '50vh',
        maxHeight: '85vh',
    }
});

export default withStyles(styles);