import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message'

const defaultState = {
  isloading: false,
  jobs: {
    total: 0,
    list: []
  },
  pageSzie: 5,
  currentJob: {},
  jobError: {},
  jobChange: false,
  companySelection: undefined,
};

export const backToJobList = createAction('@@xcloud/job/backToJobList',
  () => (dispatch, getState) => {
    dispatch(push('/jobManagement'));
  });

export const getJobs = createAction('@@xcloud/job/getJobs',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(setJobChange(false));
    let companySelection = getState().job.companySelection;
    dispatch(getJobList(query, companySelection))
  });

export const getJobList = createAction('@@xcloud/job/getJobList',
  (query, companySelection) => (dispatch, getState, httpClient) => {
    let pageSize = getState().job.pageSize;
    let url = `/api/jobs?status=active`;
    if (query) {
      if (query.offset !== undefined) {
        url += "&offset=" + query.offset;
      } else {
        url += "&offset=0";
      }
      if (query.size !== undefined) {
        url += "&size=" + query.size;
      } else {
        url += "&size=" + (pageSize ? pageSize : 5);
      }
      if (query.sort !== undefined)
        url += "&sort=" + query.sort;
    } else {
      url += "&offset=0&size=0";
    }
    if (companySelection) {
      url += "&companyId=" + companySelection.value;
    }
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setJobs(res.data));
          }
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '职务列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getJobById = createAction('@@xcloud/job/getJobById',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/jobs/' + id;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentJob(res.data));
        }
        dispatch(stopLoading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '职务信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createJob = createAction('@@xcloud/job/createJob',
  () => (dispatch, getState, httpClient) => {
    //dispatch(startLoading());
    let url = '/api/jobs';
    let job = getState().job.currentJob;
    job['companyId'] = getState().job.companySelection.value
    return httpClient.post(url, job)
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentJob(res.data));
          dispatch(createMessage('success', '创建职务[' + job.jobName + ']成功'));
          dispatch(backToJobList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '创建新职务失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateJob = createAction('@@xcloud/job/updateJob',
  () => (dispatch, getState, httpClient) => {
    let job = getState().job.currentJob;
    let url = '/api/jobs/' + job.jobId;
    let to = {
      jobId: job.jobId,
      companyId: job.companyId,
      jobName: job.jobName,
      status: job.status,
      opentext: job.opentext
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentJob(res.data));
          dispatch(createMessage('success', '更新职务[' + job.jobName + ']成功'));
          dispatch(backToJobList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新职务[' + job.jobName + ']失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const deleteJob = createAction('@@xcloud/job/deleteJob',
  (id, name) => (dispatch, getState, httpClient) => {
    let to = {
      jobId: id,
      status: 'inactive'
    }
    let url = '/api/jobs/' + id;
    httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '职务[' + name + ']删除成功'));
          dispatch(getJobs({ offset: 0 }));
        }
        return res;
      })
      .catch(err => {
        let msg = '职务[' + name + ']删除失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);

export const changeCompany = createAction('@@xcloud/job/changeCompany',
  (companySelection) => (dispatch, getState, httpClient) => {
    dispatch(setCompanySelection(companySelection));
    dispatch(getJobs({
      offset: 0
    }))
  }
);

export const setPageSize = createAction('@@xcloud/job/setPageSize');

export const setJobs = createAction('@@xcloud/job/setJobs');

export const setCurrentJob = createAction('@@xcloud/job/setCurrentJob');

export const setCompanySelection = createAction('@@xcloud/job/setCompanySelection');

export const setJobError = createAction('@@xcloud/job/setJobError');

export const setJobChange = createAction('@@xcloud/job/setJobChange');

export const stopLoading = createAction('@@xcloud/job/stopLoading');

export const startLoading = createAction('@@xcloud/job/startLoading');

export const initCreate = createAction(
  '@@xcloud/job/initCreate',
  () => (dispatch) => {
    dispatch(initJob(true));
    dispatch(push('/jobManagement/info'));
  }
);

export const initUpdate = createAction(
  '@@xcloud/job/initUpdate',
  (id, companyId) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    return Promise.all([
      dispatch(getJobById(id)),
      dispatch(initJob(false, companyId))
    ]).then(dispatch(push('/jobManagement/info')))
      .catch(err => {
        dispatch(stopLoading());
        let msg = '获取职务详细信息失败';
        dispatch(createMessage('error', msg));
      });
  }
);

export const initJob = createAction(
  '@@xcloud/job/initJob',
  (isCreate) => (dispatch, getState) => {
    if (isCreate) {
      let newJob = {
        "jobId": 0,
        "jobName": '',
        "status": "active",
        "opentext": '',
        "companyId": 0
      };
      dispatch(setCurrentJob(newJob));
    }
    let errorInfo = {
      jobName: ''
    };
    dispatch(setJobError(errorInfo));
    dispatch(setJobChange(false));
  }
);

export const jobReducer = handleActions(
  {
    [setPageSize]: (state, { payload: value }) => {
      return {
        ...state,
        pageSize: value
      };
    },
    [setJobs]: (state, { payload: value }) => {
      return {
        ...state,
        jobs: value
      };
    },
    [setJobError]: (state, { payload: value }) => {
      return {
        ...state,
        jobError: value
      };
    },
    [setCompanySelection]: (state, { payload: value }) => {
      return {
        ...state,
        companySelection: value
      };
    },
    [setJobChange]: (state, { payload: value }) => {
      return {
        ...state,
        jobChange: value
      };
    },
    [setCurrentJob]: (state, { payload: value }) => {
      return {
        ...state,
        currentJob: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    }
  },
  defaultState
);

export default jobReducer;