import React, { Component } from 'react';
import { connect } from 'react-redux';
import PricingComponent from '@/components/AmoebaManagement/BudgetPricing/PricingComponent';
import PropTypes from 'prop-types';

class PricingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    componentWillMount() {
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    render() {
        return (
            <PricingComponent
                tabValue={this.state.tabValue}
                onhandleChangeTab={this.handleChangeTab.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PricingContainer);
