import React from 'react';
import {
    Grid,
    List,
    Typography,
    Button,
    Link,
    ListItemIcon,
    ListItemText,
    ListItem,
} from '@material-ui/core';
import {
    Alert, AlertTitle
} from '@material-ui/lab';

import {
    Block,
    Done,
} from '@material-ui/icons';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { arrayToTree } from "@/utils/constant";
import { getDateByTimestamp } from '@/utils/datetime'
import ProcessLineWithUser from '@/components/ProcessLineApproval/ProcessLineApprovalComponent';
import * as _ from 'lodash';

const ProcessApprovalComponent = props => {
    const {
        classes,
        title,
        approvals,
        handlePermission,
        onhandleSetup,
        onhandleSubmit,
        onhandleApprove,
        onhandleReject,
        onhandleRestart,
        onhanldeDownloadDoc,
    } = props;

    const getActiveApprovals = () => {
        return _.filter(approvals, { 'status': 'active' })
    }

    const getHistoryApprovals = () => {
        return _.filter(approvals, a => {
            return a.confirmTime
        })
    }

    const renderActions = () => {

        let actions = []

        const activeApprovals = getActiveApprovals()

        const activeCount = activeApprovals.length

        const pendingCount = _.filter(activeApprovals, { 'confirmTime': null }).length

        if (activeCount === 0) {
            actions.push({
                code: 'SETUP',
                name: '流程修改',
                handle: onhandleSetup,
                display: handlePermission.setup
            })
        } else if (pendingCount === activeCount) {
            actions.push({
                code: 'SETUP',
                name: '流程修改',
                handle: onhandleSetup,
                negative: true,
                display: handlePermission.setup
            })
            actions.push({
                code: 'SUBMIT',
                name: '提交',
                handle: onhandleSubmit,
                display: handlePermission.submit
            })
        } else if (pendingCount === 0) {
            actions.push({
                code: 'RESTART',
                name: '重启',
                handle: onhandleRestart,
                display: handlePermission.restart
            })
        } else {
            actions.push({
                code: 'APPROVE',
                name: '同意',
                handle: onhandleApprove,
                display: handlePermission.approve
            })
            actions.push({
                code: 'REJECT',
                name: '驳回',
                handle: onhandleReject,
                negative: true,
                display: handlePermission.reject
            })
        }


        return <Grid>
            {_.filter(actions, { display: true }).map((action, idx) => (
                <Button
                    key={`rule_button_${idx}`}
                    variant={action.negative ? "outlined" : "contained"}
                    color="primary"
                    className={classes.button}
                    onClick={action.handle}
                >
                    {action.name}
                </Button>
            ))}
        </Grid >
    }


    const renderPermitPanel = () => {
        const lastApproval = _.maxBy(approvals, 'confirmTime')
        let bg = 'info';
        if (lastApproval) {
            if (lastApproval.confirmTime) {
                bg = lastApproval.confirm ? 'success' : 'error'
            } else {
                bg = 'warning'
            }
        }
        return bg;
    }


    const renderConfirm = (row) => {
        let style = null;
        let text = '开始审批';
        if (row.confirm && row.confirmTime) {
            style = classes.confirmTrue;
            if (row.preId) {
                text = '通过';
            } else {
                text = '提交审批';
            }
        }
        if (!row.confirm && row.confirmTime) {
            style = classes.confirmFalse;
            text = '驳回';
        }
        return <Typography display={'inline'} className={style}>{text}</Typography>
    }


    const renderHistoy = () => {

        //按时间顺序排序
        const historyApprovals = _.reverse(_.sortBy(getHistoryApprovals(), ['confirmTime']));

        if (historyApprovals && historyApprovals.length > 0) {
            let histoy = historyApprovals.map((approval, idx) => {

                return (
                    <ListItem key={`${approval.aprvId}`} className={classes.permitHistoryItem} dense >
                        <ListItemIcon style={{ minWidth: '2em' }}>
                            {approval.confirm ? <Done fontSize={'small'} className={classes.confirmTrue} />
                                : <Block fontSize={'small'} className={classes.confirmFalse} />}
                        </ListItemIcon>
                        <ListItemText
                            primary={<Grid container>
                                <Grid item>
                                    <Typography display={'inline'} color={'primary'}>{getDateByTimestamp(approval.confirmTime, true)}</Typography>
                                    <Typography display={'inline'} variant={'h5'}><strong>{' ' + approval.lastName + approval.firstName + ' '}</strong></Typography>
                                    {renderConfirm(approval)}
                                    <Typography display={'inline'} style={{ marginLeft: 20 }}>{approval.opentext}</Typography>
                                    {approval.approvalDoc ?
                                        <React.Fragment>
                                            <Typography display={'inline'} align={'right'}>添付文件：</Typography>
                                            <Link display={'inline'} underline='always' onClick={() => onhanldeDownloadDoc(approval.approvalDoc)} component="button">{approval.approvalDoc.docName}</Link>
                                        </React.Fragment>
                                        : null
                                    }
                                </Grid>
                            </Grid>}
                        />
                    </ListItem >
                )
            })
            return histoy
        } else {
            return
        }
    }

    return (
        <Grid alignitems="center" >

            <Alert
                classes={{ message: classes.permitBar }}
                severity={renderPermitPanel()}>
                <AlertTitle>
                    <Grid container justify={'space-between'}>
                        <Typography><strong>{title}</strong></Typography>
                    </Grid>
                </AlertTitle>
                <Grid container>
                    <Grid item className={classes.processline}>
                        {getActiveApprovals() &&
                            <ProcessLineWithUser idKey={'aprvId'} treeData={arrayToTree(_.cloneDeep(getActiveApprovals()), 'aprvId', 'preId')} processNotices={[]} />
                        }
                    </Grid>
                    <Grid item>
                        <List >
                            {renderActions()}
                        </List>
                    </Grid>
                </Grid>
                {getHistoryApprovals() ?
                    <Grid container >
                        <Grid item className={classes.processUser}>
                            <Typography className={classes.userlabel}>审批记录：</Typography>
                            <List className={classes.historyList}>
                                {renderHistoy()}
                            </List>
                        </Grid>
                    </Grid>
                    : null
                }
            </Alert>
        </Grid>
    )

}

ProcessApprovalComponent.propTypes = {
    title: PropTypes.string,
    approvals: PropTypes.array,
    onhandleSetup: PropTypes.func,
    onhandleSubmit: PropTypes.func,
    onhandleApprove: PropTypes.func,
    onhandleReject: PropTypes.func,
    onhandleRestart: PropTypes.func,
    onhanldeDownloadDoc: PropTypes.func,
};

ProcessApprovalComponent.defaultProps = {
    title: '',
    approvals: [],
    handlePermission: {
        setup: false,
        submit: false,
        approve: false,
        reject: false,
        restart: false,
        downloadDoc: false,
    }
};


export default withStyles(ProcessApprovalComponent);