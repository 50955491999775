export const clients = new Map()

export const getClient = (id) => {
    return clients.get(id)
}

export const setClient = (id, obj) =>{
    clients.set(id, obj)
}

export const deleteClient= (id) => {
    return clients.delete(id)
}

export const clearAll = ()=>{
    clients.clear()
}