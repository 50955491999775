import React, { Component } from 'react';
import DeviceCard from '../components/DeviceCard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDevice } from '../redux/modules/devicecard';

class DeviceCardContainer extends Component {

    static propTypes = {
        isloading: PropTypes.bool,
        currentDevice: PropTypes.object
    }

    handleOpen() {
        this.props.onhandleGetDevice(this.props.deviceId)
    }

    render() {
        return (
            <DeviceCard
                isloading={this.props.isloading}
                currentDevice={this.props.currentDevice}
                child={this.props.child}
                onhandleOpen={this.handleOpen.bind(this)}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isloading: state.devicecard.isloading,
        currentDevice: state.devicecard.currentDevice
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetDevice: (deviceId) => {
            dispatch(getDevice(deviceId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceCardContainer);