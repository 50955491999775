import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    permitUser: {
        // 暂仅用于驳回
        confirm: false,
        userId: '',
        ptwId: '',
        puId: '',
        opentext: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openPermitUserPanel = createAction('@@xcloud/opertionPermitUser/open',
    (
        confirmCallback, closeCallback, permitUser
    ) => ({
        confirmCallback, closeCallback, permitUser
    })
);

export const closePermitUserPanel = createAction('@@xcloud/opertionPermitUser/close');

export const changePermitUser = createAction('@@xcloud/opertionPermitUser/changePermitUser');

// Actions

export const Reducer = handleActions(
    {
        [openPermitUserPanel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                permitUser: {
                    confirm: false,
                    userId: data.permitUser.userId,
                    ptwId: data.permitUser.ptwId,
                    puId: data.permitUser.puId,
                    opentext: ''
                },
            };
        },
        [closePermitUserPanel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changePermitUser]: (state, { payload: data }) => {
            return {
                ...state,
                permitUser: data,
            };
        }
    },
    initialState
);

export default Reducer;
