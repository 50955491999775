export default {
  root: {
    minHeight: 32,
    '& fieldset': {
      borderRadius: 2,
    },
  },
  multiline: {
    height: 'auto'
  }

};
