import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
// import DeviceBase from './DeviceBase';
import DeviceBase from '../../../../containers/DeviceManagement/DeviceInfo/DeviceBase';
import DeviceRunParam from '../../../../containers/DeviceManagement/DeviceInfo/DeviceRunParam';
import DeviceTechParam from '../../../../containers/DeviceManagement/DeviceInfo/DeviceTechParam';
import DeviceAsset from '../../../../containers/DeviceManagement/DeviceInfo/DeviceAsset';
import DeviceBuild from '../../../../containers/DeviceManagement/DeviceInfo/DeviceBuild';
// import DeviceDesign from '../../../../containers/DeviceManagement/DeviceInfo/DeviceDesign'; 被合并至DeviceBuild
import DeviceDoc from '../../../../containers/DeviceManagement/DeviceInfo/DeviceDoc';
// import MaintainReport from '../../../../containers/DeviceManagement/DeviceInfo/MaintainReport';
import DeviceVehicleInfo from '../../../../containers/DeviceManagement/DeviceInfo/DeviceVehicleInfo';
import { Card, Grid, Tabs, Tab, Button, Typography } from '@material-ui/core';
// import * as _ from 'lodash';
import OpsReport from '@/containers/DeviceManagement/DeviceInfo/OpsReport';
import DevicePartList from "@/containers/AccessoryManagement/AccessoryInfo/DevicePartList";

const DeviceEdit = props => {
    const {
        classes,
        currentDevice,
        // currentProcessStatus,
        // constant,
        // currentRecord,
        // onhandleState,
        // onhandleProcess,
        // userProfile,
        // currentUserInfo,
        // isLoading,
        deviceCatChange,
        onhandleGenerateForm,
        onhandleBack,

    } = props;


    const [tabValue, setTabValue] = useState(0);

    const handleTabeChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const checkCar = () => {
        if (!currentDevice.cat) return false;

        let rtnValue = false;
        let cat = typeof currentDevice.cat === 'object' ? currentDevice.cat.value : currentDevice.cat;
        if (cat && (cat.indexOf('D1706') > -1 || cat.indexOf('D1701') > -1)) {
            rtnValue = true;
        }
        return rtnValue;
    }

    const renderTitle = () => {
        if (currentDevice && currentDevice.deviceId && currentDevice.deviceName) {
            return <Typography variant='h3'>{'设备详情 - ' + currentDevice.deviceName}</Typography>
        }
        return <Typography variant='h3'>{'设备详情'}</Typography>
    }

    return (
        <Card>
            <Grid container className={classes.title}>
                <Grid item>
                    {renderTitle()}
                </Grid>
                <Grid className={classes.flexgrow}></Grid>
                {currentDevice.deviceId && currentDevice.status !== 'registering' ? <Grid >
                    <Button className={classes.button} variant="contained" color="primary"
                        onClick={onhandleGenerateForm}>
                        {currentDevice.status === 'new' ? '生成注册单' : '生成变更单'}
                    </Button>
                </Grid> : <Grid >
                    {currentDevice.status === 'registering' &&
                        <Button className={classes.button} variant="contained" color="primary"
                            disabled>
                            {'注册中'}
                        </Button>
                    }
                </Grid>}
                <Button variant="outlined" color="primary" className={classes.button}
                    onClick={event => onhandleBack(event)}>
                    返回
                </Button>
            </Grid>

            {/* {currentDevice.deviceId === 0 || isLoading || checkPermission() ? '' : (<Grid container >
                <Grid item className={classes.processline}>
                    <ProcessLine
                        statusCodes={currentProcessStatus.statusCodes}
                        statusCode={currentProcessStatus.statusCode}
                        constant={constant}
                    />
                </Grid>
                <Grid item>
                    <List >
                        {renderActions()}
                    </List>
                </Grid>
            </Grid>)
            } */}
            {/* {currentDevice.deviceId === 0 || isLoading ? '' : renderAttention()} */}
            <Grid>
                {/* {isLoading ? showLoading() : ''} */}
                {/* 不受变更单影响：运行及操作参数 结构参数 部件信息 设备文档 */}
                <Tabs className={classes.tabs}
                    value={tabValue}
                    onChange={handleTabeChange}
                    aria-label="process tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab className={classes.tab} value={0} label="基本信息" />
                    {checkCar() ? <Tab className={classes.tab} value={1} label="车辆信息" disabled={currentDevice.deviceId === 0 || deviceCatChange} /> : null}
                    <Tab className={classes.tab} value={2} label="运行及操作参数" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={3} label="结构参数" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={4} label="资产信息" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={5} label="建设投用信息" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={6} label="部件信息" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={7} label="运维记录" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                    <Tab className={classes.tab} value={8} label="设备文档" disabled={currentDevice.deviceId === 0 || deviceCatChange} />
                </Tabs>
                <DeviceBase tabValue={tabValue} index={0} />
                {checkCar() ? <DeviceVehicleInfo tabValue={tabValue} index={1} /> : null}
                <DeviceRunParam tabValue={tabValue} index={2} />
                <DeviceTechParam tabValue={tabValue} index={3} />
                <DeviceAsset tabValue={tabValue} index={4} />
                <DeviceBuild tabValue={tabValue} index={5} />
                {/* <DeviceDesign tabValue={tabValue} index={6}/> */}
                <DevicePartList tabValue={tabValue} index={6} />
                <OpsReport tabValue={tabValue} index={7} />
                <DeviceDoc tabValue={tabValue} index={8} />
            </Grid>
        </Card>
    );
};

DeviceEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    deviceError: PropTypes.object.isRequired,
    currentDevice: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    catTree: PropTypes.array.isRequired,
    useStatusTree: PropTypes.array.isRequired,
    procatTree: PropTypes.array.isRequired,
    mgtcatTree: PropTypes.array.isRequired,
    extcatTree: PropTypes.array.isRequired,
    deviceBaseChange: PropTypes.bool.isRequired,
    mediumTree: PropTypes.array.isRequired,
    mediumPhase: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    currentProcessStatus: PropTypes.object,
    currentRecord: PropTypes.object,
    constant: PropTypes.object,
    onhandleProcess: PropTypes.func.isRequired,
    isDeviceEdit: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    //onhandleDeviceEdit: PropTypes.func.isRequired,
    onhandleState: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    deviceCatChange: PropTypes.bool.isRequired,
    // 负责班组列表
    departmentList: PropTypes.array.isRequired,
    // 负责班组 Checkbox 选择发生变化 处理函数
    onhandleCheckChange: PropTypes.func.isRequired
};

export default withStyles(DeviceEdit);
