export function PaletteProvider(palette, 
                                create, 
                                elementFactory,
                                globalConnect,handTool, translate,
                                editorActions) {
    this._create = create;
    this._elementFactory = elementFactory;
    this._globalConnect = globalConnect;
    this._handTool = handTool;
    this._translate = translate;
    this._editorActions = editorActions;
    palette.registerProvider(this);
}

PaletteProvider["$inject"] = [
    'palette',
    'create',
    'elementFactory',
    'globalConnect',
    'handTool',
    'translate',
    'editorActions'];

PaletteProvider.prototype.getPaletteEntries = function (element) {

    var actions = {},
        create = this._create,
        elementFactory = this._elementFactory,
        globalConnect = this._globalConnect,
        // handTool = this._handTool,
        translate = this._translate,
        editorActions = this._editorActions;


    function createAction(type, group, className, title = null, options = {}) {
        function createListener(event) {
            var shape = elementFactory.createShape(Object.assign({type: type}, options));
            if (options) {
                shape.businessObject.di.isExpanded = options.isExpanded;
            }
            create.start(event, shape);
        }

        return {
            group: group,
            className: className,
            // eslint-disable-next-line
            title: title || 'Create ' + type.replace(/^bpmn\:/, ''),
            action: {
                dragstart: createListener,
                click: createListener
            }
        };
    }

    function createGateway(type, group, className, title = null, options = {}) {
        function createListener(event) {
            var shape = elementFactory.createShape(Object.assign({type: type}, options));
            if (options) {
                shape.businessObject.di.isExpanded = options.isExpanded;
            }
            create.start(event, shape);
        }

        return {
            group: group,
            className: className,
            // eslint-disable-next-line
            title: title || 'Create ' + type.replace(/^bpmn\:/, ''),
            action: {
                dragstart: createListener,
                click: createListener
            }
        };
    }

    // function createParticipant(event, collapsed) {
    //     create.start(event, elementFactory.createParticipantShape(collapsed));
    // }

    Object.assign(actions, {
        // 'hand-tool': {
        //     group: 'tools',
        //     className: 'bpmn-icon-hand-tool',
        //     title: translate('Activate the hand tool'),
        //     action: {
        //       click: function(event) {
        //         handTool.activateHand(event);
        //       }
        //     }
        // },
        'undo': {
            group: 'tools',
            className: ['undo-icon'],
            title: translate('undo'),
            action: {
                click: () => editorActions.trigger('undo')
            }
        },
        'redo': {
            group: 'tools',
            className: ['redo-icon'],
            title: translate('redo'),
            action: {
                click: () => editorActions.trigger('redo')
            }
        },
        'tool-separator': {
            group: 'tools',
            separator: true
        },
        'create.start-event': createAction(
            'bpmn:StartEvent', 'event', 'bpmn-icon-start-event-none',
            translate('Create StartEvent')
        ),
        'create.end-event': createAction(
            'bpmn:EndEvent', 'activity', 'bpmn-icon-end-event-none',
            translate('Create EndEvent')
        ),
        'create.task': createAction(
            'bpmn:Task', 'activity', 'bpmn-icon-task', translate('Create Task')
        ),
        'create.gateway': createGateway(
            'bpmn:ExclusiveGateway', 'activity', 'bpmn-icon-gateway-none', translate('Create Gateway')
        ),
	    'global-connect-tool': {
            group: 'tools',
            className: 'bpmn-icon-connection-multi',
            title: translate('Activate the global connect tool'),
            action: {
              click: function(event) {
                globalConnect.toggle(event);
              }
            }
          },
    });


    return actions;
};
