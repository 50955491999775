import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    MenuItem,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import withStyles from './styles';
import PropTypes from 'prop-types'
import * as _ from 'lodash'

const UpdateProjectComponent = props => {
    const { classes,
        openFlag,
        orderData,
        companyList,
        departmentList,
        onhandleClose,
        onhandleAddOrder,
        onhandleDateChange,
        onhandleChange,
    } = props;

    const getServiceDptList = () => {
        return _.filter(departmentList, d => {
            return _.find(orderData.dptList, od => {
                return od.dependType === 'SERVICE' && od.dptId === d.dptId
            })
        })
    }

    const renderServiceSide = () => {
        if (!orderData) return ''
        if (orderData.serviceCompany) {
            const com = _.find(companyList, c => { return c.companyId === orderData.serviceCompany })
            return com.companyName
        }
        return orderData.serviceSide ? orderData.serviceSide : ''
    }
    return (
        <div alignitems="center">
            <Dialog open={openFlag} onClose={onhandleClose} className={classes.dialog}>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography component={'span'} variant='h3'>
                        {'增加工单'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='项目名称'
                                        value={orderData.projectName || ''}
                                        name='projectName'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='分厂'
                                        value={renderServiceSide()}
                                        name='serviceCompany'
                                        InputProps={{
                                            readOnly: true,
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        error={!orderData.dptId}
                                        variant="outlined"
                                        placeholder=""
                                        label='关联班组'
                                        select
                                        name='dptId'
                                        value={orderData.dptId || ''}
                                        onChange={onhandleChange}>
                                        {getServiceDptList() && getServiceDptList().map(i => (<MenuItem key={i.dptId} value={i.dptId}>{i.dptName}</MenuItem>))}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        error={!orderData.allocation}
                                        variant="outlined"
                                        placeholder=""
                                        label='计划完成进度'
                                        name='allocation'
                                        type="number"
                                        value={orderData.allocation}
                                        onChange={onhandleChange}
                                        InputProps={{
                                            endAdornment: <Typography> % </Typography>
                                        }}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            inputVariant="outlined"
                                            id="date-selector"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            label='时间'
                                            value={orderData.orderTime}
                                            onChange={onhandleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleAddOrder} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

UpdateProjectComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    openFlag: PropTypes.bool,
    openType: PropTypes.number,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleChange: PropTypes.func,
};

UpdateProjectComponent.defaultProps = {
    companyList: [],
    departmentList: [],
    projectServiceList: [],
    installs: [],
    manufactureDicts: [],
    dptsSelect: [],
};

export default withStyles(UpdateProjectComponent);