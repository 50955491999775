import React, { Component } from 'react';
import "./index.css";
import * as _ from 'lodash';
import { ReactComponent as DeviceIcon } from '../../../resource/device.svg';
import { ReactComponent as DeviceMainIcon } from '../../../resource/device_main.svg';

export class DeviceGroupDetail extends Component {
    componentDidMount() {
        this.props.getDeviceGroup(this.props.parameters.data.id);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.parameters.data.id !== this.props.parameters.data.id || oldProps.parameters.data.time !== this.props.parameters.data.time) {
            this.props.getDeviceGroup(this.props.parameters.data.id);
        }
    }

    render() {
        return (
            <div className='fillarea'>
                {this.props.deviceGroup ?
                    <React.Fragment>
                        <div className='title'>名称:</div>
                        <div className='showArea'>
                            {this.props.deviceGroup.groupName ? this.props.deviceGroup.groupName : ''}
                        </div>
                        <div className='title'>位号:</div>
                        <div className='showArea'>
                            {this.props.deviceGroup.tagNo ? this.props.deviceGroup.tagNo : ''}
                        </div>
                        <div className='title'>设备列表:</div>
                        {this.props.deviceGroup.devices ?
                            _.filter(this.props.deviceGroup.devices, function (item) {
                                return !_.isInteger(item.masterId);
                            }).map(data => (
                                <div className='list' key={data.deviceId}>
                                    <div>
                                        <DeviceMainIcon />
                                    </div>
                                    <span>
                                        {data.deviceName}
                                    </span>
                                </div>
                            )) : null
                        }
                        {this.props.deviceGroup.devices ?
                            _.sortBy(_.filter(this.props.deviceGroup.devices, function (item) {
                                return _.isInteger(item.masterId);
                            }), 'masterId').map(data => (
                                <div className='list' key={data.deviceId}>
                                    <div>
                                        <DeviceIcon />
                                    </div>
                                    <span>
                                        {data.deviceName}
                                    </span>
                                </div>
                            )) : null
                        }
                    </React.Fragment>
                    : null
                }
            </div>
        )
    }
}
