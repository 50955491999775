import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialog: {
        width: '100%',
        padding: 20
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    },
    textField: {
        width: '100%',
        minWidth: 300,
        maxWidth: 300,
    },
    textFieldInline: {
        marginLeft: theme.spacing(1)
    },
    contentGrid: {
        flexDirection: 'column'
    },
    col: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 100,
    },
    chip: {
        margin: '1px'
    },
    checkbox: {
        padding: 0,
        marginRight: 5
    },
    gridContainer: {
        marginTop: 10
    },
    item: {
        padding: 0
    },
    title: {
        size: theme.typography.h3
    },
    itemGrid: {
        paddingBottom: theme.spacing(2),
    },
    menuText: {
        fontWeight: '600'
    },
    menuSubText: {
        textAlign: 'end'
    },
});

export default withStyles(styles);