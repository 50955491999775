import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/EnergyPricing/styles';
import HistoryIcon from '@material-ui/icons/History';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
// import { getDepartmentsByRole } from "@/utils/userUtils";
import * as _ from 'lodash';

const UnifyPricingComponent = props => {
    const { classes,
        serviceBudgets,
        onhandleViewBlur,
        onhandleShowHistory,
    } = props;

    // const checkDpt = () => {
    //     const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
    //     return dptId === department.dptId ? true : false;
    // }

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,

    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }

    const mainassemblyDetailColumns = () => {
        const columns = [
            {
                title: '类别',
                field: 'subAlias',
                width: 100,
                render: row => <Typography>{row.subAlias}</Typography>,
                editable: 'never'
            }, {
                title: '单价',
                field: 'variableUnitPrice',
                type: 'numeric',
                align: 'right',
                width: 50,
                render: row => <Typography>{row.variableUnitPrice}</Typography>,
            }
        ]
        return columns
    }

    const mainassemblyData = (isEnergy) => {
        let rows = []
        const eBudgets = _.filter(serviceBudgets, { 'subAlias': '电费' })
        const wBudgets = _.filter(serviceBudgets, { 'subAlias': '水费' })
        const sBudgets = _.filter(serviceBudgets, { 'subAlias': '蒸汽费' })
        const dBudgets = _.filter(serviceBudgets, { 'subAlias': '柴油' })
        const gBudgets = _.filter(serviceBudgets, { 'subAlias': '汽油' })
        const lBudgets = _.filter(serviceBudgets, { 'subAlias': '石油液化气' })
        const wdBudgets = _.filter(serviceBudgets, { 'subAlias': '风' })
        const nBudgets = _.filter(serviceBudgets, { 'subAlias': '氮气' })
        if (eBudgets.length !== 0 && wBudgets.length !== 0 && sBudgets.length !== 0
            && dBudgets.length !== 0 && gBudgets.length !== 0) {
            let row = {
                'subAlias': '电费',
                'variableUnitPrice': eBudgets[0].variableUnitPrice,
                'variableSub': eBudgets[0].variableSub,
                'unit': eBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '水费',
                'variableUnitPrice': wBudgets[0].variableUnitPrice,
                'variableSub': wBudgets[0].variableSub,
                'unit': wBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '蒸汽费',
                'variableUnitPrice': sBudgets[0].variableUnitPrice,
                'variableSub': sBudgets[0].variableSub,
                'unit': sBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '柴油',
                'variableUnitPrice': dBudgets[0].variableUnitPrice,
                'variableSub': dBudgets[0].variableSub,
                'unit': dBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '汽油',
                'variableUnitPrice': gBudgets[0].variableUnitPrice,
                'variableSub': gBudgets[0].variableSub,
                'unit': gBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '石油液化气',
                'variableUnitPrice': lBudgets[0].variableUnitPrice,
                'variableSub': lBudgets[0].variableSub,
                'unit': lBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '风',
                'variableUnitPrice': wdBudgets[0].variableUnitPrice,
                'variableSub': wdBudgets[0].variableSub,
                'unit': wdBudgets[0].unit,
            }
            rows.push(row)
            row = {
                'subAlias': '氮气',
                'variableUnitPrice': nBudgets[0].variableUnitPrice,
                'variableSub': nBudgets[0].variableSub,
                'unit': nBudgets[0].unit,
            }
            rows.push(row)
        }

        if (isEnergy) {
            return _.filter(rows, r => { return _.find(['水费', '电费', '蒸汽费', '石油液化气', '风', '氮气'], e => { return r.subAlias === e }) })
        } else {
            return _.reject(rows, r => { return _.find(['水费', '电费', '蒸汽费', '石油液化气', '风', '氮气'], e => { return r.subAlias === e }) })
        }
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.detailTable}>
                <MaterialTable
                    columns={mainassemblyDetailColumns()}
                    data={mainassemblyData(true)}
                    options={detailTableOptions}
                    localization={detailTableLocalization}
                    style={detailTableStyle}
                    editable={{
                        onRowUpdate: (newData, oldData) => onhandleViewBlur(newData, oldData)
                    }}
                    actions={
                        [
                            {
                                icon: () => { return <HistoryIcon /> },
                                tooltip: '能耗油耗修改历史',
                                onClick: (event, rowData) => {
                                    onhandleShowHistory(event, rowData)
                                }
                            },
                        ]
                    }
                    title={<Typography variant='h3'>能耗预算规则</Typography>}
                ></MaterialTable>
            </Grid>

            <Grid className={classes.detailTable}>
                <MaterialTable
                    columns={mainassemblyDetailColumns()}
                    data={mainassemblyData(false)}
                    options={detailTableOptions}
                    localization={detailTableLocalization}
                    style={detailTableStyle}
                    editable={{
                        onRowUpdate: (newData, oldData) => onhandleViewBlur(newData, oldData)
                    }}
                    actions={
                        [
                            {
                                icon: () => { return <HistoryIcon /> },
                                tooltip: '能耗油耗修改历史',
                                onClick: (event, rowData) => {
                                    onhandleShowHistory(event, rowData)
                                }
                            },
                        ]
                    }
                    title={<Typography variant='h3'>油耗预算规则</Typography>}
                ></MaterialTable>
            </Grid>
        </div >
    )
}

UnifyPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    subCompanyList: PropTypes.array,
    varFeeRuleList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,

};

UnifyPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(UnifyPricingComponent);