import { createMessage, createhttpMessage } from './message'
import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { formCompanyTree } from '../../utils/orgchart';
import { findPath, findParent } from '../../utils/constant';
import { initIndustry, initArea } from './constant'
import { cleanmodal } from "./modal";

const defaultState = {
  isloading: false,
  companyTree: {},
  companyError: {},
  currentNode: {},
  parentNode: {},
  currentCompany: {},
  industrySelection: undefined,
  areaSelection: undefined,
  companyChange: false,
  companyList: [],
  companyAllList: [],
  allDepartments: [],
  companyTopology: []
};

export const createCompany = createAction('@@xcloud/company/createCompany',
  () => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let newCompany = getState().company.currentCompany;
    let url = '/api/companies';
    return httpClient.post(url, newCompany)
      .then((res) => {
        dispatch(stopLoading());
        if (res.data) {
          let pnode = getState().company.parentNode;
          if (!pnode.children) {
            pnode.children = [];
          }
          pnode.children.push(res.data);
          dispatch(setParentNode(pnode));
          let msg = newCompany.indivisible ? '创建车间成功' : '创建车间成功';
          dispatch(createMessage('success', msg));
          dispatch(cleanmodal());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = !newCompany.indivisible ? '创建新公司失败' : '创建新车间失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateCompany = createAction('@@xcloud/company/updateCompany',
  () => (dispatch, getState, httpClient) => {
    let company = getState().company.currentCompany;
    let url = '/api/companies/' + company.companyId;
    let to = {
      companyId: company.companyId,
      companyName: company.companyName,
      independentLegalEntity: company.independentLegalEntity,
      dutySupport: company.dutySupport,
      tenantId: company.tenantId
    }
    if (company.industryCode) {
      to.industryCode = company.industryCode
    }
    if (company.address) {
      to.address = company.address
    }
    if (company.opentext) {
      to.opentext = company.opentext
    }
    if (company.phone) {
      to.phone = company.phone
    }
    if (company.email) {
      to.email = company.email
    }
    if (company.companyAlias) {
      to.companyAlias = company.companyAlias
    }
    if (company.areaCode) {
      to.areaCode = company.areaCode
    }
    if (company.sizeCode) {
      to.sizeCode = company.sizeCode
    }
    if (company.amoebaType) {
      to.amoebaType = company.amoebaType
    }
    if (company.amoebaLevel) {
      to.amoebaLevel = company.amoebaLevel
    }
    return httpClient.patch(url, to)
      .then((res) => {
        dispatch(stopLoading());
        if (res.status === 200) {
          let msg = company.indivisible ? '更新车间成功' : '更新公司成功';
          dispatch(createMessage('success', msg));
          dispatch(setCurrentCompany(res.data));
          let cnode = getState().company.currentNode;
          cnode.companyName = res.data.companyName;
          cnode.companyName = res.data.companyName;
          cnode.companyAlias = res.data.companyAlias;
          cnode.email = res.data.email;
          cnode.phone = res.data.phone;
          cnode.opentext = res.data.opentext;
          cnode.address = res.data.address;
          cnode.sizeCode = res.data.sizeCode;
          cnode.industryCode = res.data.industryCode;
          cnode.independentLegalEntity = res.data.independentLegalEntity;
          cnode.areaCode = res.data.areaCode;
          cnode.revisor = res.data.revisor;
          cnode.dutySupport = res.data.dutySupport;
          cnode.amoebaLevel = res.data.amoebaLevel;
          cnode.amoebaType = res.data.amoebaType;
          cnode.creatorFirstName = res.data.creatorFirstName;
          cnode.creatorLastName = res.data.creatorLastName;
          cnode.revisorFirstName = res.data.revisorFirstName;
          cnode.revisorLastName = res.data.revisorLastName;
          dispatch(setCurrentNode(cnode));
          dispatch(cleanmodal());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = company.indivisible ? '更新车间失败' : '更新公司失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const deleteCompany = createAction('@@xcloud/department/deleteCompany',
  () => (dispatch, getState, httpClient) => {
    let cnode = getState().company.currentNode;
    if (!cnode.pid) {
      dispatch(createMessage('error', '不允许删除该公司'));
      return;
    } else if (cnode.children !== undefined && cnode.children.length > 0
      && _.find(cnode.children, function (o) { return o.companyId !== undefined })) {
      dispatch(createMessage('error', '请先删除下属子公司或车间'));
      return;
    }

    let companyTree = getState().company.companyTree;
    let url = `/api/companies/${cnode.companyId}`;
    let to = {
      companyId: cnode.companyId,
      status: 'inactive'
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '删除成功'));
          dispatch(setCurrentCompany({}));
          dispatch(setCurrentNode({}));
          let findCompany = (list, cid, pid) => {
            list.forEach(com => {
              if (com.companyId === pid) {
                _.remove(com.children, function (o) {
                  return o.companyId && o.companyId === cid
                })
              } else if (com.children && com.children > 0) {
                findCompany(com.children, cid, pid)
              }
            })
          }
          if (companyTree.companyId === cnode.pid) {
            _.remove(companyTree.children, function (o) {
              return o.companyId && o.companyId === cnode.companyId
            })
          } else {
            findCompany(companyTree.children, cnode.companyId, cnode.pid)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(createhttpMessage(err.response ? err.response : '', '删除失败'));
        return null;
      });
  });

export const initCompanyInfo = createAction('@@xcloud/company/initCompanyInfo',
  () => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    dispatch(setCompanyError({}));
    dispatch(setCompanyChange(false));
    let params = getState().modal.parameters;
    let cnode = getState().company.currentNode;
    Promise.all([
      dispatch(initIndustry()),
      dispatch(initArea())
    ]).then(
      res => {
        if (params.isEdit) {
          // edit mode
          let industryTree = getState().constant.industry;
          let areaTree = getState().constant.areas;
          if (cnode.industryCode && cnode.industryCode !== '') {
            let industryPath = findPath(cnode.industryCode, industryTree)
              .map(i => i.label)
              .reverse()
              .join(' > ');
            dispatch(setIndustrySelection({ value: cnode.industryCode, label: industryPath }));
          }
          if (cnode.areaCode && cnode.areaCode !== '') {
            let areaPath = findPath(cnode.areaCode, areaTree).map(i => i.label)
              .reverse()
              .join(' > ');
            dispatch(setAreaSelection({ value: cnode.areaCode, label: areaPath }));
          }
          dispatch(setCurrentCompany(cnode));
          if (!cnode.pid) {
            dispatch(setParentNode({}));
          } else {
            let companyTree = getState().company.companyTree;
            let pnode = findParent(cnode.pid, 'companyId', [companyTree]);
            dispatch(setParentNode(pnode));
          }
        } else {
          // create mode
          let newCompany = {
            "companyId": 0,
            "companyName": "",
            "companyAlias": "",
            "email": "",
            "phone": "",
            "creator": 0,
            "revisor": 0,
            "status": "active",
            "tenantId": cnode.tenantId,
            "opentext": "",
            "pid": cnode.companyId,
            "address": "",
            "sizeCode": "",
            "logo": "",
            "industryCode": "",
            "independentLegalEntity": false,
            "areaCode": "",
            "dutySupport": false,
            "indivisible": false,
            "amoebaLevel": 0,
            "amoebaType": ""
          }
          if (!params.isCompany) {
            newCompany.indivisible = true;
          }
          dispatch(setIndustrySelection(undefined));
          dispatch(setAreaSelection(undefined));
          dispatch(setParentNode(cnode));
          dispatch(setCurrentCompany(newCompany));
        }
        dispatch(stopLoading());
        return res;
      }
    ).catch(err => {
      dispatch(stopLoading());
      console.error(err);
      let msg = '初始化公司编辑页面失败';
      dispatch(createMessage('error', msg));
    });
  });

export const hideDepartment = createAction('@@xcloud/company/hideDepartment',
  () => (dispatch, getState, httpClient) => {
    let cnode = getState().company.currentNode;
    _.remove(cnode.children, function (o) {
      return o.dptId;
    });
    cnode.showDepartment = false;
    dispatch(setCurrentNode(cnode));
  });

export const getDepartment = createAction('@@xcloud/company/getDepartment',
  () => (dispatch, getState, httpClient) => {

    dispatch(startLoading());
    let cnode = getState().company.currentNode;
    let url = `/api/departments?offset=0&size=0&status=active&companyId=${cnode.companyId}`;
    return httpClient
      .get(url)
      .then((res) => {
        res.data.list.forEach(element => {
          if (element.pid) {
            res.data.list.forEach(item => {
              if (item.dptId === element.pid) {
                if (!item.children) {
                  item.children = [];
                }
                item.children.push(element);
              } else {
                if (!item.children) {
                  item.children = [];
                }
              }
            });
          }
        });
        let dlist = _.filter(res.data.list, function (o) { return !o.pid; });
        if (cnode.children) {
          _.remove(cnode.children, function (o) {
            return o.dptId;
          });
          cnode.children = [...cnode.children, ...dlist];
        } else {
          cnode.children = [...dlist];
        }
        cnode.showDepartment = true;
        dispatch(setCurrentNode(cnode));
        dispatch(stopLoading());
        return res;
      })
      .catch(err => {
        dispatch(stopLoading());
        console.error(err);
        let msg = '获取所属部门失败';
        dispatch(createMessage('error', msg));
      });
  });

export const getCompanyList = createAction('@@xcloud/company/getCompanyList',
  () => (dispatch, getState, httpClient) => {
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = "/api/companies?tenantId=" + currentUserInfo.tenantId + "&companyType=SUBCOMPANY&offset=0&size=0";
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(setCompanyList(res.data));
        }
        return res.data;
      });
  });

export const getCompanyALLList = createAction('@@xcloud/company/getCompanyList',
  () => (dispatch, getState, httpClient) => {
    let currentUserInfo = getState().auth.currentUserInfo;
    let url = "/api/companies?tenantId=" + currentUserInfo.tenantId + "&offset=0&size=0";
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(setCompanyAllList(res.data));
        }
        return res.data;
      });
  });

export const getCompanyTree = createAction('@@xcloud/company/getCompanyTree',
  () => (dispatch, getState) => {

    return dispatch(getCompanyList())
      .then((res) => {
        let newData = formCompanyTree(_.sortBy(res.payload.list, ['companyId']));
        dispatch(setCompanies(newData));
        return newData
      })
      .catch(err => {
        let msg = '获取公司树结构失败';
        dispatch(createMessage('error', msg));
      });
  });

export const getCompanyById = createAction('@@xcloud/company/getCompanyById',
  (id) => (dispatch, getState, httpClient) => {
    let url = `/api/companies/${id}`;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(setCurrentCompany(res.data));
        }
        return res.data;
      });
  });


export const getCompanyTopology = createAction('@@xcloud/company/getcompanytopology',
  (callback) => (dispatch, getState, httpClient) => {
    httpClient.get('/api/companytopology', callback)
      .then((res) => {
        if (res) {
          dispatch(setCompanyTopology(res.data))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取公司拓扑失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  })

export const getAllDepartments = createAction('@@xcloud/company/getalldepartments',
  (executable, callback) => (dispatch, getState, httpClient) => {
    httpClient.get('/api/alldepartments?executable=' + executable)
      .then((res) => {
        if (res)
          dispatch(setAllDepartments(res.data))
        if (callback)
          callback(res.data)
      })
      .catch(err => {
        let msg = `找公司所有部门`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const setCurrentNode = createAction('@@xcloud/company/setCurrentNode');

export const setCompanies = createAction('@@xcloud/company/setCompanies');

export const setCompanyList = createAction('@@xcloud/company/setCompanyList');

export const setCompanyAllList = createAction('@@xcloud/company/setCompanyAllList');

export const setCurrentCompany = createAction('@@xcloud/company/setCurrentCompany');

export const setParentNode = createAction('@@xcloud/company/setParentNode');

export const setIndustrySelection = createAction('@@xcloud/company/setIndustrySelection');

export const setAreaSelection = createAction('@@xcloud/company/setAreaSelection');

export const setCompanyError = createAction('@@xcloud/company/setCompanyError');

export const setCompanyChange = createAction('@@xcloud/process/setCompanyChange');

export const stopLoading = createAction('@@xcloud/company/stopLoading');

export const startLoading = createAction('@@xcloud/company/startLoading');

export const setCompanyTopology = createAction('@@xcloud/company/setcompanytopology');

export const setAllDepartments = createAction('@@xcloud/company/setalldepartments');

export const companyReducer = handleActions(
  {
    [setCompanies]: (state, { payload: value }) => {
      return {
        ...state,
        companyTree: value
      };
    },
    [setCompanyList]: (state, { payload: value }) => {
      return {
        ...state,
        companyList: value
      };
    },
    [setCompanyAllList]: (state, { payload: value }) => {
      return {
        ...state,
        companyAllList: value
      };
    },
    [setCurrentNode]: (state, { payload: value }) => {
      return {
        ...state,
        currentNode: value
      };
    },
    [setIndustrySelection]: (state, { payload: value }) => {
      return {
        ...state,
        industrySelection: value
      };
    },
    [setAreaSelection]: (state, { payload: value }) => {
      return {
        ...state,
        areaSelection: value
      };
    },
    [setCurrentCompany]: (state, { payload: value }) => {
      return {
        ...state,
        currentCompany: value
      };
    },
    [setParentNode]: (state, { payload: value }) => {
      return {
        ...state,
        parentNode: value
      };
    },
    [setCompanyError]: (state, { payload: value }) => {
      return {
        ...state,
        companyError: value
      };
    },
    [setCompanyChange]: (state, { payload: value }) => {
      return {
        ...state,
        companyChange: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    },
    [setCompanyTopology]: (state, { payload: data }) => {
      return {
        ...state,
        companyTopology: data
      }
    },
    [setAllDepartments]: (state, { payload: data }) => {
      return {
        ...state,
        allDepartments: data
      }
    },
  },
  defaultState
);

export default companyReducer;
