import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, DialogContent, Dialog, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getDepartmentsByRole } from '@/utils/userUtils'
import * as _ from 'lodash';

const OnDutyCurrentPannel = ({
    classes,
    activity,
    dutyRecords,
    leaders,
    userProfile,
    currentUserInfo,
    editDisabled,
    companyTopology,
    onhandleClosePannel,
    onhandleChangeLeader,
    onhandleChangeActivityTime,
    onhandleChangeActivityAttr,
    onhandleUpdateActivity,
}) => {

    const company = _.find(companyTopology, c => { return c.companyId === activity.companyId })
    if (company)
        activity.companyName = company.companyName

    let readOnlyLeader = true
    if (activity.dpts && activity.dpts.length > 0) {
        activity.dptName = activity.dpts[0].dptName
        if (!editDisabled) {
            if (activity.status === 'Drafting') {
                const departments = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
                let theDpt = _.intersectionBy(departments, activity.dpts, 'dptId')
                if (!theDpt || theDpt.length === 0) {
                    editDisabled = true
                }
            }
            if (activity.status === 'Submitted') {
                readOnlyLeader = false
            }
        }
    }


    console.log(activity)
    let activityLeaders = activity.users
    if (activity.users) {
        activityLeaders = _.filter(activity.users, u => { return u.roleType === 2 })
    }


    return (
        <div alignitems="center" >
            {!activity || !activity.actId ? '' :
                <Dialog open={activity && activity.actId ? true : false} onClose={onhandleClosePannel} aria-labelledby="form-dialog-title" className={classes.dialog}>
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>{activity.companyName + activity.dptName}班组安全活动</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            {/* <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="活动名称"
                                    name='activity'
                                    defaultValue={activity.activity}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    className={classes.itemText}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="分厂"
                                    name='companyName'
                                    defaultValue={activity.companyName}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid> */}
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    className={classes.itemText}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="班组"
                                    name='dptName'
                                    defaultValue={activity.dptName}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <Autocomplete
                                    disabled={readOnlyLeader}
                                    variant="outlined"
                                    multiple
                                    filterSelectedOptions
                                    id={"users-standard"}
                                    options={leaders}
                                    getOptionLabel={option => option.lastName + '' + option.firstName}
                                    defaultValue={activityLeaders}
                                    onChange={onhandleChangeLeader}
                                    renderInput={params => (
                                        <TextField className={classes.itemText}
                                            variant="outlined"
                                            {...params}
                                            id={"users-standard-text"}
                                            label="领导"
                                            placeholder="选择人员"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="地点"
                                    name='location'
                                    defaultValue={activity.location}
                                    onChange={onhandleChangeActivityAttr}
                                    InputProps={{
                                        readOnly: editDisabled,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="学习内容"
                                    name='opentext'
                                    defaultValue={activity.opentext}
                                    onChange={onhandleChangeActivityAttr}
                                    InputProps={{
                                        readOnly: editDisabled,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="培训人"
                                    name='lecturer'
                                    defaultValue={activity.lecturer}
                                    onChange={onhandleChangeActivityAttr}
                                    InputProps={{
                                        readOnly: editDisabled,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDateTimePicker
                                        autoOk
                                        fullWidth
                                        ampm={false}
                                        // disableToolbar
                                        inputVariant='outlined'
                                        format="yyyy/MM/dd HH:mm"
                                        id="date-selector"
                                        margin="normal"
                                        value={activity.activityTime}
                                        onChange={onhandleChangeActivityTime}
                                    />
                                </MuiPickersUtilsProvider>
                                {/* <TextField
                                    fullWidth
                                    className={classes.itemText}
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="日期"
                                    name='activityTime'
                                    defaultValue={getDateByTimestamp(activity.activityTime)}
                                    onChange={onhandleChangeActivityTime}
                                    InputProps={{
                                        readOnly: editDisabled,
                                    }} /> */}
                            </Grid>
                            {dutyRecords && dutyRecords.map((record, index) => (
                                <Grid item key={record.recordId} className={classes.item}>
                                    <TextField
                                        fullWidth
                                        className={classes.itemText}
                                        variant="outlined"
                                        placeholder="请输入"
                                        label={"安全活动记录"}
                                        multiline
                                        defaultValue={record.content}
                                        InputProps={{
                                            readOnly: true,
                                        }} />
                                </Grid>))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={onhandleUpdateActivity}>
                            保存</Button>
                        <Button variant="text" color="primary" onClick={onhandleClosePannel}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

OnDutyCurrentPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};
OnDutyCurrentPannel.defaultProps = {

}


export default withStyles(OnDutyCurrentPannel);