import React, { Suspense } from 'react';
import withStyles from './styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Canvas } from 'react-three-fiber'
// import DemoThree from './DemoThree'
// import Tank from './Tank'
import Pot from './Pot'


const ThreeD = props => {

    // const {
    //     classes
    // } = props;

    return (
        <div>
            <Canvas camera={{ fov: 50, position: [0, 0, 50] }}>
                <ambientLight />
                {/* <spotLight intensity={0.8} position={[300, 300, 400]} /> */}
                <spotLight intensity={0.5} position={[10, 10, 100]} />
                <Suspense fallback={null}>
                    {/* <DemoThree /> */}
                    {/* <Tank /> */}
                    <Pot />
                </Suspense>
            </Canvas>
        </div>
    );
};

ThreeD.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(ThreeD));