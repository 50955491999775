import * as _ from 'lodash';

export const formCompanyTree = (list) => {
    list.forEach(element => {
        if (element.pid) {
            list.forEach(item => {
                if (item.companyId === element.pid) {
                    if (!item.children) {
                        item.children = [];
                    }
                    item.children.push(element);
                }
            });
        }
    });
    return list[0];
}

export const formRoleGroupTree = (list) => {
    let sorted = _.orderBy(list, ['groupId'], ['asc']);
    sorted.forEach(element => {
        if (element.parentGroup) {
            sorted.forEach(item => {
                if (item.groupId === element.parentGroup) {
                    if (!item.children) {
                        item.children = [];
                    }
                    item.children.push(element);
                }
            });
        }
    });
    return _.filter(sorted, function (o) { return !o.parentGroup; });
}

export const formatRoleGroupTreeForOperationRole = (list) => {
    let newList = [];
    let sorted = _.orderBy(list, ['groupId'], ['asc']);
    sorted.forEach(element => {
        let newData = {}
        newData.value = 'group' + element.groupId;
        newData.label = element.groupName;
        newData.disabled = true;
        if (element.roleList) {
            element.roleList.forEach(item => {
                let newItem = {}
                newItem.value = item.roleId
                newItem.label = item.roleName
                if (!newData.children) {
                    newData.children = [];
                }
                newData.children.push(newItem);
            });
        }
        newList.push(newData)
    });
    return newList;
}