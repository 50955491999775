import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Typography,
    Tooltip,
} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import * as _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';

const INSTALL_MANIPULATE_SERVICE = '装置操作服务'
const CreateMOrderComponent = props => {
    const { classes,
        open,
        manufactureOrder,
        onhandleClose,
        onhandleSave,
        onhandleChange,
        onhandlePopDateChange,
        services,
        planDtls,
        manipulates,
        installs,
        departments,
        companyId,
    } = props;

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    const filterManipulates = () => {

        if (!manipulates) return []

        if (manufactureOrder && manufactureOrder.installId) {
            return _.filter(manipulates, { "installId": manufactureOrder.installId })
        }
        return manipulates
    }

    const getDpts = () => {
        if (!planDtls) {
            return planDtls && planDtls.map((i) => (
                <MenuItem key={i.dptId} value={i.dptId}>
                    {i.dptId ? departments.find(d => d.dptId === i.dptId).dptName : i.dptId}
                </MenuItem>
            ))
        } else {
            return departments && _.filter(departments, dpt => dpt.companyId === companyId).map((i) => (
                <MenuItem key={i.dptId} value={i.dptId}>
                    {i.dptName}
                </MenuItem>
            ))
        }

    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>创建生产工单</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='关联服务'
                                        select
                                        value={manufactureOrder.serviceId || ''}
                                        name='serviceId'
                                        onChange={onhandleChange}>
                                        {services && services.map((i) => (
                                            <MenuItem key={i.serviceId} value={i.serviceId}>
                                                {i.serviceName}{renderServiceType(i.serviceType)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                        label='关联班组'
                                        select
                                        name='dptId'
                                        value={manufactureOrder.dptId || ''}
                                        onChange={onhandleChange}>
                                        {getDpts()}
                                    </TextField>
                                </Grid>
                                {manufactureOrder && manufactureOrder.serviceName !== INSTALL_MANIPULATE_SERVICE
                                    && <Grid item className={classes.itemGrid}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder=""
                                            label='数量'
                                            name='allocation'
                                            value={manufactureOrder.allocation || ''}
                                            onChange={onhandleChange} >
                                        </TextField>
                                    </Grid>}

                                {manufactureOrder && manufactureOrder.serviceName === INSTALL_MANIPULATE_SERVICE
                                    && <Grid item className={classes.itemGrid}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="请输入"
                                            label='装置'
                                            select
                                            value={manufactureOrder.installId || ''}
                                            name='installId'
                                            onChange={onhandleChange}>
                                            {installs && installs.map((i) => (
                                                <MenuItem key={i.installId} value={i.installId}>
                                                    {i.installName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}

                                {manufactureOrder && manufactureOrder.serviceName === INSTALL_MANIPULATE_SERVICE
                                    && <Grid item className={classes.itemGrid}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="请输入"
                                            label='装置操作'
                                            select
                                            value={manufactureOrder.manipulateId || ''}
                                            name='manipulateId'
                                            onChange={onhandleChange}>
                                            {filterManipulates().map((i) => (
                                                <MenuItem key={i.manipulateId} value={i.manipulateId}>
                                                    {i.manipulateName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            inputVariant="outlined"
                                            id="date-selector"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            label='时间'
                                            value={manufactureOrder.startTime}
                                            onChange={onhandlePopDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreateMOrderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateMOrderComponent.defaultProps = {

};

export default withStyles(CreateMOrderComponent);