import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment as moment } from '@/utils/datetime';
import zhCN from 'date-fns/locale/zh-CN';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Typography,
  MenuItem,
  TextField,
  Button,
  Grid,
  Card,
} from '@material-ui/core';
import * as _ from 'lodash';


const OppoHeader = props => {
  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    onhandleChange,
    onhandleSelectChange,
    onhandleDateChange,
    search,
    statusList,
    onhandleCreateOppo
  } = props;
  console.log('search', search)
  const minStartDate = moment()().startOf('year');
  const minToDate = search.fromDate ? moment()(search.fromDate).endOf('day') : minStartDate.endOf('day');
  const minExpiredToDate = search.expiredFromDate ? moment()(search.expiredFromDate).endOf('day') : minStartDate.endOf('day');

  return (
    <div>
      <div className={classes.planitemroot}>
        <Grid container alignItems="center">
          <Grid container className={classes.title}>
            <Typography variant="h3" className={classes.plantname}>
              {'机会管理'}
            </Typography>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.gridContainer}>
          <Grid container item xs={12}>

            <Grid item xs={3}>
              <TextField
                style={{ width: '90%' }}
                value={search.search || ''}
                variant="standard"
                placeholder="客户/机会/发现人"
                onChange={event => onhandleChange(event, 'search')}
              />
            </Grid>

            <Grid item xs={4}>
              <Button variant="contained" color="primary" size="small"
                onClick={onhandleSearch}
              >
                搜索
              </Button>
              &emsp;
              <Button variant="contained" color="primary"
                size="small"
                onClick={onhandleClearSearch}
              >
                {search.showFilters ? '清除筛选条件' : '高级筛选'}</Button>
            </Grid>

            <Grid item xs={4} />

            <Grid item xs={1}>
              <Button variant="contained" color="primary" size="small"
                onClick={onhandleCreateOppo}
              >
                新建机会
              </Button>

            </Grid>

          </Grid>
        </Grid>

        {
          search.showFilters ?
            <Card className={classes.content} style={{ marginTop: 7, marginBottom: 7 }}>

              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label} >
                    状态
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    style={{ width: '90%' }}
                    id="filled-select-company"
                    select
                    value={search.status || ''}
                    onChange={(event) => onhandleSelectChange(event, 'status')}
                    variant="standard"
                  >
                    <MenuItem key={1} value={''}>-</MenuItem>
                    {_.sortBy(statusList, 'seq').map((option) => (
                      <MenuItem key={option.code} value={option.seq}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label} align={'center'}>
                    来源
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className={classes.textField}
                    value={search.source || ''}
                    variant="standard"
                    onChange={event => onhandleChange(event, 'source')}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label}>
                    预期金额(元)
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={search.fromAmount || ''}
                    type="number"
                    className={classes.textField}
                    onChange={value => onhandleChange(value, 'fromAmount')}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}>
                  </TextField>
                </Grid>

                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label} align={'center'}>
                    ~
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={search.toAmount || ''}
                    type="number"
                    className={classes.textField}
                    onChange={value => onhandleChange(value, 'toAmount')}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}>
                  </TextField>
                </Grid>


                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label}>
                    可能性(%)
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={search.fromPossibility || ''}
                    type="number"
                    className={classes.textField}
                    onChange={value => onhandleChange(value, 'fromPossibility')}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}>
                  </TextField>
                </Grid>
                <Grid item xs={1}>
                  <Typography color='textPrimary' className={classes.label} align={'center'}>
                    ~
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={search.toPossibility || ''}
                    type="number"
                    className={classes.textField}
                    onChange={value => onhandleChange(value, 'toPossibility')}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}>
                  </TextField>
                </Grid>
              </Grid>


              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                  <Typography gutterBottom className={classes.label}>
                    {'发现时间'}
                  </Typography>
                </Grid>
                <Grid item xs={2} container wrap={'nowrap'}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      InputProps={{ readOnly: true }}
                      disableToolbar
                      autoOk
                      variant='inline'
                      format='yyyy/MM/dd'
                      margin="none"
                      value={search.fromDate || null}
                      onChange={date => onhandleDateChange(date, 'fromDate')}
                      KeyboardButtonProps={{
                        'aria-label': '修改时间',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={1}>
                  <Typography gutterBottom className={classes.label} align={'center'}>
                    {'~'}
                  </Typography>
                </Grid>
                <Grid item xs={2} container wrap={'nowrap'}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      InputProps={{ readOnly: true }}
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="none"
                      value={search.toDate || null}
                      minDate={minToDate}
                      minDateMessage={<Typography>不能早于开始时间</Typography>}
                      onChange={date => onhandleDateChange(date, 'toDate')}
                      KeyboardButtonProps={{
                        'aria-label': '修改时间',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={1}>
                  <Typography gutterBottom className={classes.label}>
                    {'过期时间'}
                  </Typography>
                </Grid>
                <Grid item xs={2} container wrap={'nowrap'}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      InputProps={{ readOnly: true }}
                      disableToolbar
                      autoOk
                      variant='inline'
                      format='yyyy/MM/dd'
                      margin="none"
                      value={search.expiredFromDate || null}
                      onChange={date => onhandleDateChange(date, 'expiredFromDate')}
                      KeyboardButtonProps={{
                        'aria-label': '过期时间',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1}>
                  <Typography gutterBottom className={classes.label} align={'center'}>
                    {'~'}
                  </Typography>
                </Grid>
                <Grid item xs={2} container wrap={'nowrap'}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      InputProps={{ readOnly: true }}
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="none"
                      value={search.expiredToDate || null}
                      onChange={date => onhandleDateChange(date, 'expiredToDate')}
                      minDate={minExpiredToDate}
                      minDateMessage={<Typography>不能早于开始时间</Typography>}
                      KeyboardButtonProps={{
                        'aria-label': '过期时间',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

            </Card> : null
        }
      </div>

    </div>
  );
};

OppoHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default withStyles(OppoHeader);
