
/** 车辆保险模板名 */ export const INSURANCE_TEMPLATE = '车辆保险模板.xlsx';


export const DEVICE_ATTR_FIELDS = {
    'deviceNo': { name: '设备编号', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'deviceName': { name: '设备名称', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'tagNo': { name: '位号/车号', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'specs': { name: '规格', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'drawingCode': { name: '图纸编号', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'model': { name: '型号', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'opentext': { name: '备注', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'medium': { name: '介质', attrType: 'BASE', attrSubType: 'BASE_BASE', attrDataType: 'string' },
    'cat': { name: '设备类别', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'string' },
    'proCat': { name: '专业类别', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'string' },
    'mgtCat': { name: '管理类别', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'string' },
    'extCat': { name: '扩展类别', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'string' },
    'useStatus': { name: '使用状态', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'string' },
    'companyId': { name: '单位', attrType: 'BASE', attrSubType: 'BASE_MANAGMENT', attrDataType: 'integer' },
}

export const DEVICE_FROM_TYPES = [
    { 'code': 'REGISTER_FORM', 'name': '设备注册单' },
    { 'code': 'INFO_UPDATE_FORM', 'name': '设备变更单' },
    { 'code': 'TRANSFER_FORM', 'name': '设备调拨单' },
    { 'code': 'SCRAP_FORM', 'name': '设备报废单' },
]

export const DEVICE_FORM_TARGET_TYPES = [
    { 'code': 'DEV', 'name': '设备' },
    { 'code': 'ACC', 'name': '部件' },
]


export const DEVICE_FROM_STATUS = [
    { 'code': 'new', 'name': '起草中' },
    { 'code': 'active', 'name': '审批中' },
    { 'code': 'done', 'name': '已完成' },
]

export const DEVICE_ATTR_TYPES = {
    'BASE': '基本信息',
    'BASE_BASE': '基础信息',
    'BASE_MANAGMENT': '管理信息',
    'ASSET': '资产信息',
    'DESIGN': '设计信息',
    'BUILD': '建设信息'
}

export const DEVICE_OPERATION_STATUS = {
    'new': '新建',
    'active': '有效',
    'inactive': '无效',
    'approved': '批准',
    'finalized': '待验收',
    'done': '完成'
}