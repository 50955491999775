import React, { Component } from 'react';
import { connect } from 'react-redux';
import OperationRuleSetup from '../../../components/DeviceManagement/OperationRule/RuleCatSetup'
import {
    backToRuleEdit,
    selectCategory,
    // updateOperationsRuleTargets
} from "../../../redux/modules/operationRule";
import PropTypes from "prop-types";
class OperationRuleSetupContainer extends Component {

    static propTypes = {
        currentRule: PropTypes.object,
        catList: PropTypes.array,
        readonly: PropTypes.bool,
        ruleTargetChanged: PropTypes.bool,
    };

    constructor(props) {
        super(props)
        this.state = {
            routeType: '',
        }
    }

    componentDidMount = () => {
        let routeType = this.props.currentRule.target
        this.setState({
            routeType: routeType
        })
    }


    handleBack = (event) => {
        this.props.onhandleBackToRuleEdit();
    };


    // handleCheckChange = () => {
    //     this.props.onhandleCheckChange(this.state.routeType)
    // }

    handleSelectCategory = (event, value) => {
        this.props.onhandleSelectCategory(value);
    };


    render() {
        return (
            <OperationRuleSetup
                currentRule={this.props.currentRule}
                onhandleBack={this.handleBack.bind(this)}
                // onhandleCheckChange={this.handleCheckChange.bind(this)}
                catList={this.props.catList}
                readonly={this.props.readonly}
                // readonly={true}
                routeType={this.state.routeType}
                ruleTargetChanged={this.props.ruleTargetChanged}
                onhandleSelectionChange={this.handleSelectCategory.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentRule: state.operationRule.currentRule,
        catList: state.operationRule.showList,
        readonly: state.operationRule.readonly,
        ruleTargetChanged: state.operationRule.ruleTargetChanged,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleBackToRuleEdit: () => {
            dispatch(backToRuleEdit())
        },
        // onhandleCheckChange: (type) => {
        //     dispatch(updateOperationsRuleTargets(type))
        // },
        onhandleSelectCategory: (value) => {
            dispatch(selectCategory(value))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationRuleSetupContainer)
