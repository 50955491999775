import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    Grid,
    Typography,
    Button,
    TextField,
    MenuItem,
} from '@material-ui/core';
import { WbSunny, NightsStay } from '@material-ui/icons';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as _ from 'lodash';
import { getHolidayLabel } from '../../../utils/constant';
import { formatWeekday, formatMonth, getMoment } from '../../../utils/datetime'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
let allViews = ['month'] //Object.keys(Views).map(k => Views[k]
const localizer = momentLocalizer(getMoment())

const Schedule = ({
    classes,
    planList,
    constant,
    search,
    //currentUserInfo,
    onhandleOpenPlanPanel,
    onhandleOpenPlanList,
    onhandleChange,
    companyTree,
    companyList,
}) => {

    const renderTitle = (plan) => {
        let name = plan.inspectorName ? plan.inspectorName : ''
        let time = getMoment()(plan.startTime).format('HH:mm')

        return name + ' ' + time;
    }

    const events = _.map(planList, a => {
        return {
            ...a,
            id: a.planId,
            title: renderTitle(a),
            start: a.startTime,
            end: a.startTime,
        }
    })

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    const formats = {
        weekdayFormat: (date) => formatWeekday(date),
        monthHeaderFormat: (date) => formatMonth(date)
    }

    const handleSelectEvent = event => {
        if (event) onhandleOpenPlanPanel(event)
    }
    const handleSelectMore = event => {
        if (event) onhandleOpenPlanList(event)
    }

    const handleSelectSlot = event => {
        onhandleOpenPlanPanel({})
    }

    const CustomToolbar = (props) => {

        const goToLastMonth = () => {
            let m = moment(props.date)
            m.add(-1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('prev', props.date)
            onhandleChange(props.date, 'selectedDate')
        }

        const goToNextMonth = () => {
            let m = moment(props.date)
            m.add(1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('next', props.date)
            onhandleChange(props.date, 'selectedDate')
        }

        return (
            <Grid container className={classes.toolbar}>
                <Grid item>
                    <Button className={classes.toolbarButton} onClick={goToLastMonth}>
                        <ArrowBackIos />
                    </Button>
                </Grid>
                <Grid item className={classes.toolbarLabel}>
                    <Typography className={classes.toolbarLabelText} variant='h5'>{props.label}</Typography>
                </Grid>
                <Grid item className={classes.toolbarButton} onClick={goToNextMonth}>
                    <Button><ArrowForwardIos /></Button>
                </Grid>
                <Grid item className={classes.flexgrow}></Grid>

                <Grid item xs={2}>
                    <TextField
                        style={{ width: '90%' }}
                        id="filled-select-company"
                        select
                        value={search.companyId}
                        onChange={(value) => onhandleChange(value, 'companyId')}
                        variant="standard"
                        disabled={companyDisabled()}
                    >
                        {_.sortBy(companyList, 'companyId').map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>



        )
    }

    const CustomMonthHeader = (props) => {
        return (
            <div style={{
                backgroundColor: '#FFF',
                margin: '-1px -10px',
                lineHeight: '45px',
            }}
                onClick={() => console.log('clicked here')} > {props.label} </div>
        )
    };
    const Event = (event) => {
        const eventData = event.event
        let hour = _.toInteger(getMoment()(eventData.startTime).format('HH'))
        let icon = ''
        if (hour > 18 || hour < 5) {
            icon = <NightsStay fontSize="small" />
        } else {
            icon = <WbSunny fontSize="small" />
        }
        return (
            <div className={classes.calendarEvent}>
                <Grid container className={classes.calendarEventContainer}>
                    <Grid item className={classes.calendarEventContainerIcon}>{icon} </Grid>
                    <Grid item>
                        <Typography >{eventData.title}</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: 'rgba(0,0,0,0)',
        };
        return {
            style: style
        };
    }

    const customDayPropGetter = value => {

    }

    const dateHeader = ({ date, label }) => {

        const todayHolidays = constant.holidays.filter((item) => {
            return moment(date).isBetween(moment(item.startTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'), moment(item.endTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'))
        })

        let headerLabel = ''
        if (todayHolidays.length > 0) {
            for (const h of todayHolidays) {
                headerLabel = headerLabel + ' ' + getHolidayLabel(h)
            }
        }
        return (
            <div>{headerLabel + ' ' + moment(date).format('D')}</div>
        );
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Calendar
                selectable
                formats={formats}
                localizer={localizer}
                events={events}
                views={allViews}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onShowMore={handleSelectMore}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={eventStyleGetter}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 650 }}
                //toolbar={false}
                components={{
                    toolbar: props => (<CustomToolbar  {...props} />),
                    month: {
                        header: CustomMonthHeader,
                        dateHeader: ({ date, label }) => dateHeader({ date, label })
                    },
                    event: Event
                }}
                messages={{
                    showMore: (count) => ('+ ' + count + ' 查看更多'),
                }}
            />
        </div>
    );
};
Schedule.propTypes = {
    classes: PropTypes.object.isRequired,
    constant: PropTypes.object.isRequired,
    planList: PropTypes.array.isRequired,
    onhandleOpenPlanPanel: PropTypes.func.isRequired,
    onhandleOpenPlanList: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    //currentUserInfo: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    search: PropTypes.object.isRequired,
};

export default withRouter(withStyles(Schedule));