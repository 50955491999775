import React from 'react';
import {
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import * as _ from 'lodash';

const ResetSharingComponent = props => {
    const { classes,
        departments,
        takes,
        resetScope,
        resetType,
        param,
        onhandleUpdate,
        onhandleClose,
        onhandleChangeScope,
        onhandleResetSave,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: false,
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
    }

    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '部门',
                field: 'dptId',
                editable: 'never',
                with: '200',
                render: row => <Typography>{renderDpt(row.dptId)}</Typography>
            },
            {
                title: '现分摊比例',
                field: 'originPercentage',
                editable: 'never',
                type: 'numeric',
                render: row => <Typography>{row.originPercentage ? row.originPercentage + '%' : ''}</Typography>
            },
            {
                title: '人数',
                field: 'userCount',
                editable: 'never',
                type: 'numeric',
                render: row => <Typography>{row.userCount}</Typography>
            },
            {
                title: '新分摊比例',
                field: 'percentage',
                type: 'numeric',
                render: row => <Typography>{row.percentage ? Number(row.percentage).toFixed(2) + '%' : ''}</Typography>
            }
        ]

        return columns

    }

    const assemblyData = () => {
        return takes
    }

    const renderDpt = (dptId) => {
        let label = '';
        let value = _.find(departments, function (o) { return o.dptId === dptId })
        if (value) {
            label = value.dptName
        }
        return label;
    }

    const renderSum = () => {
        const total = _.reduce(takes, function (sum, take) {
            return take.percentage ? sum + Number(take.percentage) : sum
        }, 0);
        return Number(total).toFixed(2)
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={param.open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{'重置分摊比例'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid className={classes.container}>
                        <Grid container className={classes.toolbarContainer}>
                            <Grid item xs={4} >
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="分摊方式"
                                    className={classes.select}
                                    value={resetType}
                                    SelectProps={{
                                        native: true,
                                    }}

                                // onChange={onhandleChangeType}
                                >
                                    <option value={'HEADCOUNT'} key={'HEADCOUNT'}>{'按人员数量'}</option>
                                </TextField>
                            </Grid>
                            <Grid item xs={4} >
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="被分摊部门"
                                    value={resetScope}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChange={onhandleChangeScope}
                                >
                                    <option value={'CURRENT_DEPARTMENT'} key={'CURRENT_DEPARTMENT'}>{renderDpt(param.selectedDptId)}</option>
                                    <option value={'ALL_DEPARTMENT'} key={'ALL_DEPARTMENT'}>{'所有部门'}</option>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.toolbarLabel}>{'新分摊比例合计:' + renderSum() + '%'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.tableContainer}>
                            <MaterialTable
                                columns={assemblycolumns()}
                                data={assemblyData()}
                                options={tableOptions}
                                localization={tableLocalization}
                                style={tableStyle}
                                title={<Typography variant='h3'></Typography>}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            resolve()
                                            onhandleUpdate(newData)
                                        }),
                                }}
                            >
                            </MaterialTable>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleResetSave} variant="contained" color="primary"> 重置
                    </Button>
                    <Button onClick={onhandleClose} variant="outlined" color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

ResetSharingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    param: PropTypes.object.isRequired,
    takes: PropTypes.array.isRequired,
    resetType: PropTypes.string,
};

ResetSharingComponent.defaultProps = {
    param: {},
    resetType: 'USERCOUNT',
    takes: [],
};

export default withStyles(ResetSharingComponent);