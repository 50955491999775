import { createAction, handleActions } from 'redux-actions';
import { findParent, findPath } from '../../../../utils/constant';
import { getDevicesbyCat } from '../../../../redux/modules/devices';
import * as _ from 'lodash';
const initialState = {
    treedata: { label: '新建设备分类', value: 'newDevice' },
    existTreeData: { label: '设备分类/列表', value: 'existDevice' },
};

// Actions
export const initDeviceTreeData = createAction('@@xcloud/deviceTree/initDeviceTreeData',
    () => (dispatch, getState) => {
        let newtreedata = _.cloneDeep(getState().deviceTree.treedata);
        let existTreeData = _.cloneDeep(getState().deviceTree.existTreeData);
        let newtreedataMusthave = []
        if (getState().setting.unitView) {
            newtreedataMusthave.push({ "label": "单元", "value": "Unit" })
        } else {
            newtreedataMusthave.push({ "label": "区域", "value": "Area" })
        }
        newtreedata.children = _.concat(newtreedataMusthave, getState().trees.deviceCategoryTree);
        existTreeData.children = getState().trees.deviceCategoryTree;
        return { newtreedata: newtreedata, existTreeData: existTreeData }
    }
);

export const getNewTreeDeviceData = createAction('@@xcloud/deviceTree/getNewTreeDeviceData',
    (value, action) => (dispatch, getState) => {
        let treedata = _.cloneDeep(getState().deviceTree.existTreeData);
        dispatch(setloacalloading(value))
        return Promise.all([
            dispatch(getDeviceData(value, action))
        ]).then(
            (data) => {
                treedata.children = data[0].payload;
                let currentdata = findParent(value, 'value', treedata.children);
                currentdata.loading = false;
                return treedata
            }
        ).catch(err => {
            let treedata = _.cloneDeep(getState().deviceTree.treedata);
            let data = findParent(value, 'value', treedata.children);
            data.loading = false;
            return treedata
        })
    }
);

export const updateNewTreeData = createAction('@@xcloud/deviceTree/updateNewTreeData',
    (device) => (dispatch, getState) => {
        let treedata = _.cloneDeep(getState().deviceTree.existTreeData).children;
        let path = findPath(device.cat, treedata);
        if (path.length > 0) {
            if (_.filter(path, function (o) { return o.total }) > 0) {
                let addflg = false;
                path.forEach(element => {
                    if (element.total) {
                        if (addflg) {
                            element.total = element.total + 1
                        } else {
                            element.total = element.total + 1
                            device.value = _.toString(device.deviceId);
                            device.label = device.deviceName;
                            element.children.push(device)
                            addflg = true;
                        }
                    }
                });
            }
        }
        return treedata;
    }
);


export const getDeviceData = createAction('@@xcloud/deviceTree/getDeviceData',
    (value, action) => (dispatch, getState) => {
        dispatch(setloacalloading(value))
        let devicelists = getState().devices.searchDevicelist.list;
        let storeyId = getState().installationPlug.selectedId;
        let installId = getState().installation.editInstallation.installId;
        let tergetList = _.filter(devicelists, function (o) { return _.filter(o.storeies, function (p) { return p.storeyId === storeyId }).length > 0; });
        let treedata = _.cloneDeep(getState().deviceTree.existTreeData).children;
        let companyId = getState().installation.editInstallation.companyId;
        return Promise.all([
            dispatch(getDevicesbyCat(value, companyId)),
        ]).then(
            (data) => {
                let reqData = data[0].payload;
                if (reqData.total > 0) {
                    let path = findPath(value, treedata);
                    let reqlist = _.sortBy(reqData.list, 'deviceName');
                    if (action === 'view') {
                        if (tergetList && tergetList.length > 0) {
                            reqlist = _.reject(reqlist, function (o) { return !(o.deviceId && _.find(tergetList, function (p) { return p.deviceId === o.deviceId })); })
                        } else {
                            reqlist = [];
                        }
                    } else {
                        reqlist = _.reject(reqlist, function (o) { return (o.deviceGroup && o.deviceGroup.installId && o.deviceGroup.installId !== installId); });
                    }

                    if (reqlist && reqlist.length > 0) {
                        reqlist.forEach(element => {
                            let deviceId = element.deviceId
                            element.value = _.toString(deviceId);
                            if (element.tagNo && element.tagNo !== '') {
                                element.label = element.deviceName + `(${element.tagNo})`
                            } else {
                                element.label = element.deviceName
                            }

                        });
                        if (path.length > 0) {
                            path.forEach(element => {
                                if (element.value === value) {
                                    element.total = reqlist.length;
                                    element.children = _.concat(element.children, reqlist);
                                    element.loading = false;
                                } else {
                                    reqlist.forEach(item => {
                                        element.children = _.reject(element.children, function (o) { return o.value === item.value; })
                                    });
                                }
                            })
                        }
                    }
                } else {
                    let data = findParent(value, 'value', treedata);
                    data.loading = false;
                }
                return treedata;
            }
        ).catch(err => {
            let data = findParent(value, 'value', treedata);
            data.loading = false;
            return treedata;
        })
    }
);

export const setloacalloading = createAction('@@xcloud/deviceTree/setloacalloading',
    (id) => (dispatch, getState) => {
        let treedata = _.cloneDeep(getState().deviceTree.existTreeData);
        let data = findParent(id, 'value', treedata.children);
        data.loading = true;
        return treedata;
    }
);

export const changeExistTreeData = createAction('@@xcloud/deviceTree/changeExistTreeData')

export const deviceTreeReducer = handleActions(
    {
        [setloacalloading]: (state, { payload: value }) => {
            // state.treedata.children = value;
            return {
                ...state,
                existTreeData: value
            };
        },
        [initDeviceTreeData]: (state, { payload: value }) => {
            return {
                ...state,
                existTreeData: value.existTreeData,
                treedata: value.newtreedata
            };
        },
        [getNewTreeDeviceData]: (state, { payload: value }) => {
            return {
                ...state,
                existTreeData: value
            };
        },
        [updateNewTreeData]: (state, { payload: value }) => {
            state.existTreeData.children = value;
            return {
                ...state,
            };
        },
        [changeExistTreeData]: (state, { payload: value }) => {
            let area = _.find(state.treedata.children, o => (o.value === 'Area'));
            let unit = _.find(state.treedata.children, o => (o.value === 'Unit'));
            if (value) {
                if (area) {
                    state.treedata.children = _.reject(state.treedata.children, o => (o.value === 'Area'));
                }
                if (!unit) {
                    state.treedata.children.unshift({ label: "单元", value: "Unit" })
                }
            } else {
                if (unit) {
                    state.treedata.children = _.reject(state.treedata.children, o => (o.value === 'Unit'));
                }
                if (!area) {
                    state.treedata.children.unshift({ label: "区域", value: "Area" })
                }
            }
            return {
                ...state,
            };
        }
    },
    initialState
);

export default deviceTreeReducer