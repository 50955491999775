import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { createhttpMessage, createMessage } from './message';

const defaultState = {
    predefinedProcess: {
        total: 0,
        list: []
    },
    processPageSize: 5,
}

export const setPredefinedProcess = createAction('@@xcloud/predefinedprocesssetPredefinedProcess');
export const setProcessPageSize = createAction('@@xcloud/predefinedprocesssetProcessPageSize');

export const getPredefinedProcess = createAction('@@xcloud/predefinedprocessgetPredefinedProcess',
    (query) => (dispatch, getState, httpClient) => {
        let pageSize = getState().predefinedProcess.processPageSize
        let url = `/api/predefined/processes`;
        if (query && query.offset) {
            url += "?offset=" + query.offset;
        } else {
            url += "?offset=0";
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }
        if (query && query.sort) {
            url += "&sort=" + query.sort;
        }
        if (query && _.isInteger(query.userId)) {
            url += "&userId=" + query.userId;
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId;
        }
        if (query && query.search) {
            url += "&search=" + encodeURIComponent(query.search);
        }
        if (query && query.status) {
            url += "&status=" + query.status;
        }
        if (query && query.ptype) {
            url += "&ptype=" + query.ptype;
        }
        if (query && query.pscope) {
            url += "&pscope=" + query.pscope;
        }

        httpClient.get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setPredefinedProcess(res.data));
                }
            }).catch(err => {
                let msg = `获取用户流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const savePredefinedProcess = createAction('@@xcloud//savePredefinedProcess',
    (dto, callback) => (dispatch, getState, httpClient) => {
        httpClient.post('/api/predefined/processes', dto)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建用户流程成功'));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `创建用户流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const updatePredefinedProcess = createAction('@@xcloud//updatePredefinedProcess',
    (dto, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(`/api/predefined/processes/${dto.inventoryId}`, dto)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '更新用户流程成功'));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `更新用户流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const copyPredefinedProcess = createAction('@@xcloud//copyPredefinedProcess',
    (inventoryId, userId, roleId, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(`/api/predefined/processes/${inventoryId}/copyapprovers/${userId}/${roleId}`)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '拷贝用户流程成功'));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `拷贝用户流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const savePredefinedProcessSteps = createAction('@@xcloud/predefinedprocesssavePredefinedProcessSteps',
    (id, datas, notices, callback) => (dispatch, getState, httpClient) => {
        let dtos = [];
        datas.forEach(data => {
            let dto = {
                inventoryId: _.toInteger(id),
                roleId: data.roleId,
                stepIdString: _.isString(data.id) ? data.id : _.toString(data.id)
            }
            if (data.userId) {
                dto.userId = data.userId
            }
            if (data.preId) {
                dto.preIdString = _.isString(data.preId) ? data.preId : _.toString(data.preId)
            }

            dtos.push(dto)
        })

        httpClient.post(`/api/predefined/processes/${id}/approvers`, dtos)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建用户流程步骤成功'));
                    dispatch(savePredefinedProcessNotices(id, notices, callback));
                }
            }
            ).catch(err => {
                let msg = `创建用户流程步骤失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const savePredefinedProcessNotices = createAction('@@xcloud/operation/savePredefinedProcessNotices',
    (id, processNotices, callback) => (dispatch, getState, httpClient) => {
        let url = `/api/predefined/processes/${id}/notices`
        let dtos = [];
        processNotices.forEach(data => {
            let dto = {
                inventoryId: _.toInteger(id),
                userId: data.userId,
                roleId: data.roleId
            }
            dtos.push(dto)
        })

        return httpClient
            .post(url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建用户流程抄送列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const predefinedProcessReducer = handleActions(
    {
        [setPredefinedProcess]: (state, { payload: value }) => {
            return {
                ...state,
                predefinedProcess: value
            };
        },
        [setProcessPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                processPageSize: value
            };
        },
    },
    defaultState
)

export default predefinedProcessReducer;