import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { formatDate } from '@/utils/datetime'
import { getCodeName, TASK_STATUS } from '@/utils/manufactureUtils';
import * as _ from 'lodash';

const MOrderDtlListComponent = props => {
    const {
        classes,
        manufactureOrders,
        departments,
        open,
        onhandleClose,
        manufactureDicts,
        indexes,
        tasks,
        day,
        onhandleRowUpdate,
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,

    }
    const tableStyle = {
        ...style
    }

    const getStatus = (code) => {
        const status = manufactureDicts.find(item => {

            return item.type === 'ORDER_STATUS' && item.code === code
        });
        return status === undefined ? '' : status.name
    }

    const getDptName = (dptId) => {
        const department = departments.find(item => {
            return item.dptId === dptId
        });
        return department === undefined ? '' : department.dptName
    }

    const renderServiceName = (row) => {

        let order = _.find(tasks, { 'orderId': row.orderId })
        if (order) return order.serviceName
        return ''

    }

    const getAllocation = (row) => {
        if (indexes && indexes.length > 0 && row.manufactureService.amoebaIndex === indexes[0].subId && row.manufactureService.valuationType === 'BUDGET') {
            return row.allocation.toFixed(2) * 100
        } else if (row.manufactureService.serviceType === 'COMMISSION') {
            if (row.allocation) {
                return row.allocation.toFixed(2)
            } else {
                return ''
            }
        } else {
            return row.allocation.toFixed(2)
        }
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never',
            },
            {
                title: '班组',
                field: 'dptName',
                render: row => row.manufactureService ? <Typography>{getDptName(row.dptId)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        select
                        value={props.rowData.dptId ? props.rowData.dptId : ''}
                        name='dptId'
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.dptId = e.target.value;
                            props.onRowDataChange(data);
                        }}>
                        {props.rowData.manufactureService.serviceDpts.map((item) => {
                            return <MenuItem key={item.dptId} value={item.dptId}>
                                {getDptName(item.dptId)}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{renderServiceName(row)}</Typography>,
                editable: 'never'
            },
            {
                title: '计划数量',
                field: 'allocation',
                render: row => row ? <Typography>{getAllocation(row)}</Typography> : '',
                type: 'numeric',
            },
            {
                title: '单位',
                field: 'unit',
                render: row => row ? <Typography>{indexes.length > 0 && row.manufactureService.amoebaIndex === indexes[0].subId && row.manufactureService.valuationType === 'BUDGET' ? '%' : row.manufactureService.unit}</Typography> : '',
                type: 'numeric',
                editable: 'never',
            },
            {
                title: '时间',
                field: 'startTime',
                render: row => row ? <Typography>{formatDate(row.startTime)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{getStatus(row.status)}</Typography> : '',
                editable: 'never',
            }
        ]

        return columns
    }

    const assemblyData = () => {
        return manufactureOrders
    }

    const assemblycolumnsTask = () => {

        const columns = [
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{row.serviceName}</Typography>,
            },
            {
                title: '活动任务',
                field: 'activityName',
                render: row => <Typography>{row.activityName}</Typography>,
            },
            {
                title: '执行人',
                field: 'executor',
                render: row => <Typography>{row.userName}</Typography>,
            },
            {
                title: '开始时间',
                field: 'startTime',
                render: row => <Typography>{formatDate(row.startTime)}</Typography>,
            },
            {
                title: '状态',
                field: 'status',
                render: row => <Typography>{getCodeName(manufactureDicts, TASK_STATUS, row.status)}</Typography>,
            },
        ]
        return columns
    }

    const assemblyDataTask = (order) => {
        return _.filter(tasks, { 'orderId': order.orderId })
    }
    const getDay = () => {
        const days = day ? day.split('-') : '';
        return days.length === 3 ? days[0] + '年' + days[1] + '月' + days[2] + '日' : ''
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Dialog fullScreen open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{getDay()}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <Grid className={classes.detailtable}>
                            <MaterialTable
                                columns={assemblycolumns()}
                                data={assemblyData()}
                                options={tableOptions}
                                localization={tableLocalization}
                                style={tableStyle}
                                title={<Typography variant="h3">生产班组日工单</Typography>}
                                detailPanel={[{
                                    render: order => {
                                        return (
                                            <Grid className={classes.detailTable}
                                            >
                                                <MaterialTable
                                                    columns={assemblycolumnsTask()}
                                                    data={assemblyDataTask(order)}
                                                    options={{ ...options, paging: false }}
                                                    localization={tableLocalization}
                                                    style={tableStyle}
                                                    title={<Typography variant='h4'>工作任务调度列表</Typography>}
                                                >
                                                </MaterialTable>
                                            </Grid>
                                        )
                                    }
                                }]}
                                editable={{
                                    onRowUpdate: (newData, oldData) => onhandleRowUpdate(newData, oldData),
                                }}
                            >
                            </MaterialTable>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

MOrderDtlListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    manufactureOrders: PropTypes.array,
    companyList: PropTypes.any,
    onhandleDateChange: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
};

export default withStyles(MOrderDtlListComponent);
