import { withStyles } from '@material-ui/styles';
import { stripedTableStyle } from '@/utils/antdCustomStyle';
import { colors } from '@material-ui/core';

const styles = theme => ({
  container: {
    padding: '8px 8px',
    backgroundColor: 'white',
  },
  gridContainer: {
    margin: 10
  },
  toolbarContainer: {
    // paddingLeft: theme.spacing(2)
  },
  flexgrow: {
    flexGrow: 1,
  },
  checked: {
    fontSize: '15px',
    color: theme.palette.success.main,
  },
  unchecked: {
    fontSize: '15px',
    color: colors.grey[600],
    '&:hover': {
      color: theme.palette.success.main,
    }
  },
  highlight:{
    color:theme.palette.primary.main,
  },
  stripedTable: stripedTableStyle
});


export default withStyles(styles);