import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';
import * as _ from 'lodash';

const RoleGroupEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        companySelection,
        currentRoleGroup,
        roleGroupError,
        roleGroupChange,
        roleGroupList
    } = props;

    const renderParentGroup = (id) => {
        // console.log(roleGroupList)
        let entity = _.find(roleGroupList.list, { groupId: id });
        return entity.groupName
    }

    return (
        <div>
            <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h3" gutterBottom>
                        {currentRoleGroup.groupId ? "编辑用户组" : "新建用户组"}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={8}>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            所属单位
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textFieldInput}
                                value={companySelection.label || ''}
                                variant="outlined"
                                size='small'
                                disabled
                            />
                        </Grid>
                    </Grid>

                    {currentRoleGroup.parentGroup ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                上级用户组
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                {/* <Typography className={classes.readOnly}>
                                    {renderParentGroup(currentRoleGroup.parentGroup)}
                                </Typography> */}
                                <TextField
                                    className={classes.textFieldInput}
                                    value={renderParentGroup(currentRoleGroup.parentGroup) || ''}
                                    variant="outlined"
                                    size='small'
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    ) : null}

                    {currentRoleGroup.groupId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                用户组编号
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                {/* <Typography className={classes.readOnly}>
                                    {currentRoleGroup.groupId}
                                </Typography> */}
                                <TextField
                                    className={classes.textFieldInput}
                                    value={currentRoleGroup.groupId || ''}
                                    variant="outlined"
                                    size='small'
                                    disabled
                                />
                            </Grid>
                        </Grid>) : null
                    }

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            用户组名称
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={roleGroupError.groupName && roleGroupError.groupName !== ''}
                                helperText={roleGroupError.groupName || ''}
                                className={classes.textFieldInput}
                                value={currentRoleGroup.groupName || ''}
                                variant="outlined"
                                size="small"
                                onChange={event => onhandleChange(event, 'groupName')}
                                onBlur={event => onhandleCheck(event, 'groupName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={8}>
                        <Typography className={classes.label}>
                            用户组简介
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextareaAutosize
                                className={classes.textFieldInput}
                                value={currentRoleGroup.opentext || ''}
                                rowsMin={5}
                                onChange={event => onhandleChange(event, 'opentext')}
                            />
                        </Grid>
                    </Grid>

                    {currentRoleGroup.groupId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                创建时间
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {getDateByTimestamp(currentRoleGroup.createTime, true)}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }

                    {currentRoleGroup.groupId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={8}>
                            <Typography className={classes.label}>
                                创建人
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {currentRoleGroup.creatorLastName + currentRoleGroup.creatorFirstName}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }

                    {currentRoleGroup.groupId && currentRoleGroup.updateTime ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                修改时间
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {getDateByTimestamp(currentRoleGroup.updateTime, true)}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }

                    {currentRoleGroup.groupId && currentRoleGroup.revisor ? (
                        <Grid item container className={classes.gridItem} sm={6} md={8}>
                            <Typography className={classes.label}>
                                修改人
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {currentRoleGroup.revisorLastName + currentRoleGroup.revisorFirstName}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary"
                        className={classes.button}
                        disabled={!roleGroupChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>

                </Grid>

            </Card>
            <Prompt message="数据未保存,确定要离开？" when={roleGroupChange} />
        </div>
    );
};

RoleGroupEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentRoleGroup: PropTypes.object.isRequired,
    roleGroupError: PropTypes.object.isRequired,
    roleGroupChange: PropTypes.bool.isRequired,
    companySelection: PropTypes.object.isRequired,
    roleGroupList: PropTypes.array.isRequired
};

export default withStyles(RoleGroupEdit);
