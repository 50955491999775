import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import JssProvider from './providers/jss';
import ThemeProvider from './theme';
import configureStore, { history } from './redux/store';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import Signin from './containers/Signin'
import Signup from './containers/Signup'
import ForgotPassword from './containers/ForgotPassword'
import { setToken } from './redux/modules/auth';
import TenantSelect from './containers/TenantSelect';

const { store, persistor, isReady } = configureStore();

const target = document.getElementById('root');

const getRoutes = (routes) => {
  let allRoutes = []
  for (let r of routes) {
    allRoutes.push(r)
    if (r.children) {
      allRoutes = allRoutes.concat(getRoutes(r.children))
    }
  }
  return allRoutes
};

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <JssProvider>
          <ThemeProvider>
            <Switch>
              {getRoutes(Routes).map((item, index) => {
                return <Route key={index} path={item.href} exact render={props => (
                  store.getState().auth.jwt !== '' ? <item.rootcomponent {...props} /> : <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                  }} />
                )} />;
              })}
              <Route exact path="/login" component={Signin} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/signup" component={Signup} />
              <Route path="/tenantselect" exact render={props => (
                store.getState().auth.jwt !== '' ? <TenantSelect {...props} /> : <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />)
              }
              />
            </Switch>
          </ThemeProvider>
        </JssProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

let cleanup;
const render = () => {
  if (cleanup) cleanup();
  ReactDOM.render(app, target);
};

/**
 * Wait for persistor to be ready
 */
isReady.lastly(async () => {
  let BearerToken;
  if (!BearerToken) {
    BearerToken = store.getState().auth.jwt;
  }
  if (BearerToken) {
    store.dispatch(setToken(BearerToken));
  }

  return render();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
