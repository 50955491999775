import { createAction, handleActions } from 'redux-actions';
const initialState = {
  show: false,
  top: 0,
  left: 0,
  parameters: {}
};

export const cleanContentMenu = createAction('@@xcloud/contentmenu/cleanContentMenu');
export const createContentMenu = createAction('@@xcloud/contentmenu/createContentMenu',
  (top, left, parameters) => ({ top, left, parameters }));

// Actions

export const contentmenuReducer = handleActions(
  {
    [createContentMenu]: (state, { payload: contentmenus }) => {
      return {
        ...state,
        show: true,
        top: contentmenus.top + 'px',
        left: contentmenus.left + 'px',
        parameters: contentmenus.parameters
      };
    },
    [cleanContentMenu]: (state) => {
      return {
        ...state,
        show: false,
        top: 0,
        left: 0,
        parameters: {}
      };
    }
  },
  initialState
);

export default contentmenuReducer