import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20
  },
  dateLabel: {
    padding: '0 4px 0 0'
  },
  treeSelect: {
    width: '90%',
    marginTop: 7
  },
  formControl: {
    width: '90%'
  },
  dptName: {
    fontWeight: 'bold'
  },
  dptBlock: {
    marginBottom: 10
  },
  textField: {
    minWidth: 300,
    maxWidth: 300
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
