import React, { Component } from 'react';
import { connect } from 'react-redux';
import AmoebaReportComponent from '@/components/AmoebaManagement/Report/AmoebaReportComponent';
import { getAllDepartments } from '@/redux/modules/department'
import PropTypes from 'prop-types';

class AmoebaReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
        this.props.getAllDepartments({
            offset: 0,
            size: 0
        })
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    render() {
        return (
            <AmoebaReportComponent
                tabValue={this.state.tabValue}
                onhandleChangeTab={this.handleChangeTab.bind(this)}
                departments={this.props.departments}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        departments: state.department.allDepartments.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllDepartments: (query) => {
            return dispatch(getAllDepartments(query));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AmoebaReportContainer);
