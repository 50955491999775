import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    misdeliveryList: [] //其他领料列表
};

export const setMisdeliveryList = createAction('@@xcloud/manufacture/setMisdeliveryList');

export const getMisdeliveryList = createAction('@@xcloud/manufacture/getMisdeliveryList',
    (companyId, deliveryDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/misdeliverys'
        let strs = []
        if (companyId || deliveryDate || status) {
            if (companyId) strs.push('companyId=' + companyId)
            if (deliveryDate) strs.push('deliveryDate=' + deliveryDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setMisdeliveryList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取其他领料列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const ManufactureMisdeliveryReducer = handleActions(
    {
        [setMisdeliveryList]: (state, { payload: data }) => {
            return {
                ...state,
                misdeliveryList: data
            }
        },
    },
    initialState
)

export default ManufactureMisdeliveryReducer
