import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import {getBusinessObject, is} from 'bpmn-js/lib/util/ModelUtil';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { getStore } from '../../../../../redux/store';
import {setDiagramChange} from '../../../../../redux/modules/process';

function getSelect(element, node) {
    const bo = getBusinessObject(element);
    const selectedOption = bo.get(node);
    return selectedOption;
}

function setCheckBox(element, value, name) {
    const obj = {};
    obj[`${name}`] = value[name]? 'true': 'false';
    return obj;
}

export default function(group, element, bpmnFactory, translate) {
    if (!is(element, 'bpmn:SequenceFlow')) return;
    const store = getStore();
    const statusCode =  entryFactory.textField({
        id: 'statusCode',
        label: translate('状态代码'),
        modelProperty: 'statusCode',
        set(el, value) {
            let res = {}
            if (value['statusCode'] !== '') {
                res['statusCode'] = value['statusCode'];
              } else {
                res['statusCode'] = undefined;
              }
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateProperties(el, res);
        },
        validate: function(element, values) {
            let validationResult = {};

            if (values.statusCode && values.statusCode.length > 20) {
                validationResult.statusCode = '状态代码最多20个英文字母';
            }
            return validationResult;
        },
    })

    group.entries.push(statusCode);


    const review = entryFactory.checkbox({
        id: 'review',
        label: translate('需要评审'),
        modelProperty: 'review',
        get(el) {
            return {
                review: getSelect(el,'review') === 'true',
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setCheckBox(el, value,'review');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        }
    });

    group.entries.push(review);

    const distributed = entryFactory.checkbox({
        id: 'distributed',
        label: translate('需要针对下级分发任务'),
        modelProperty: 'distributed',
        get(el) {
            return {
                distributed: getSelect(el,'distributed') === 'true',
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setCheckBox(el, value,'distributed');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        }
    });

    group.entries.push(distributed);
}
