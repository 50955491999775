import { withStyles } from '@material-ui/styles';

const styles = theme => ({
      planitemroot: {
        backgroundColor: '#FFFFFFFF',
        padding: theme.spacing(4),
        overflowX: 'auto'
      },
      materialtable: {
        boxShadow: 'none'
      },
      gridContainer: {
        marginTop: 30
      },
      gridSelect: {
        width: 100
      },
      gridItem: {
        width: 200
      }
});


export default withStyles(styles);