import Konva from 'konva';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from '../../../../../../redux/store';
import { cleanContentMenu, createContentMenu } from '../../../../redux/modules/contentmenu';
import KonvaUtil from "../../util";
import ActionUtil from '../../actionUtil';
const konvaUtil = new KonvaUtil();
const actionUtil = new ActionUtil();


export function init(props, bx, by, id, name, stage, previewStage) {
    let layer = stage.findOne('.workLayer');
    const state = {
        color: "black",
        width: 100,
        height: 0,
        strokeWidth: 4
    };

    const dragPoints = [
        {
            x: 15,
            y: 0,
            name: 'top'
        },
        {
            x: 30,
            y: 15,
            name: 'right'
        },
        {
            x: 15,
            y: 30,
            name: 'bottom'
        },
        {
            x: 0,
            y: 15,
            name: 'left'
        }
    ];
    // 生成管道对象
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: id,
        zIndex: 5,
        name: "Yibiao",
    });

    const topCircle = new Konva.Circle({
        x: 15,
        y: 15,
        radius: 15,
        zindex: '6',
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        name: 'main'
    });

    group.add(topCircle)

    let leftX = Math.abs(15 - 10 * Math.cos(45));
    let leftY = Math.abs(15 + 10 * Math.sin(45));
    let rightX = Math.abs(15 + 12 * Math.cos(45));
    let rightY = Math.abs(15 - 12 * Math.sin(45));
    var middleArrow = new Konva.Arrow({
        points: [leftX, leftY, rightX, rightY],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        pointerLength: state.strokeWidth,
        pointerWidth: state.strokeWidth + 1,
    })
    group.add(middleArrow);

    const text = new Konva.Text({
        text: name,
        x: 0,
        y: 40,
        fill: state.color,
        name: "YibiaoText",
    })
    group.add(text);

    for (const dragPoint of dragPoints) {
        CreatedragPoints(props, group, layer, previewStage, dragPoint.name, dragPoint.x, dragPoint.y)
    }

    groupCommon(props, group, layer, stage, previewStage);

    layer.add(group);
    layer.batchDraw();
    konvaUtil.updatePreview(props, layer, previewStage);
}

export function mapping(props, group, layer, stage, previewStage) {
    groupCommon(props, group, layer, stage, previewStage);
    group.find('Circle').forEach(circle => {
        if (circle.name() !== 'main') {
            circleCommon(props, circle, layer, group, previewStage)
        }
    })
}

function CreatedragPoints(props, group, layer, previewStage, name, x, y, radius = 2, fill = "black") {
    const circle = new Konva.Circle({
        id: uuidv4(),
        x: x,
        y: y,
        fill: fill,
        radius: radius,
        draggable: true,
        zindex: '1',
        name: name
    });
    group.add(circle);
    layer.batchDraw();
    circleCommon(props, circle, layer, group, previewStage);
}

function circleCommon(props, circle, layer, orignalGroup, previewStage) {
    circle.on("dragmove", e => {
        console.log('circle move')
        updateObjects(props, orignalGroup, circle, layer, previewStage, 'drag');

        layer.children.each(function (group) {
            // do not check intersection with itself
            if (group === circle.getParent()) {
                return;
            }
            if (konvaUtil.haveIntersection(group.getClientRect(), circle.getClientRect())) {
                group.fire('mouseover')
            } else {
                group.fire('mouseout')
            }
        });
    });

    circle.on("dragend", e => {
        layer.children.each(function (group) {
            // do not check intersection with itself
            if (group === circle.getParent()) {
                return;
            }
            if (konvaUtil.haveIntersection(group.getClientRect(), circle.getClientRect())) {
                group.find('Circle').each(function (connectPoint) {
                    if (!connectPoint.name() || connectPoint.name() === '') {
                        if (konvaUtil.haveIntersection(connectPoint.getClientRect(), circle.getClientRect())) {
                            let x = group.x() + connectPoint.x() - circle.getParent().x();
                            let y = group.y() + connectPoint.y() - circle.getParent().y();
                            circle.x(x);
                            circle.y(y)
                            updateObjects(props, orignalGroup, circle, layer, previewStage, 'drag');
                        }
                    }
                })
            }
        });

        recoverStyle(e, orignalGroup, layer);
    });

    circle.on("dblclick", e => {
        updateObjects(props, orignalGroup, circle, layer, previewStage, 'dblclick');
    });
}

function updateObjects(props, orignalGroup, circle, layer, previewStage, action) {
    let lineTarget = null
    orignalGroup.find('Line').forEach(line => {
        if (line.name() === circle.name()) {
            lineTarget = line
        }
    })
    if (lineTarget) {
        lineTarget.destroy();
        layer.batchDraw();
    }

    let origX = 0;
    let origY = 0;
    switch (circle.name()) {
        case "top":
            origX = 15;
            origY = 0;
            break;
        case "right":
            origX = 30;
            origY = 15;
            break;
        case "bottom":
            origX = 15;
            origY = 30;
            break;
        case "left":
            origX = 0;
            origY = 15;
            break;
        default:
        // do nothing
    }

    if (action === 'drag') {
        var newLine = new Konva.Line({
            points: [origX, origY, circle.x(), circle.y()],
            stroke: 'balck',
            strokeWidth: 4,
            lineCap: 'round',
            lineJoin: 'round',
            name: circle.name()
        });
        orignalGroup.add(newLine);
    } else {
        circle.x(origX);
        circle.y(origY);
    }

    layer.batchDraw();
    konvaUtil.updatePreview(props, layer, previewStage);
    actionUtil.markChange(orignalGroup.id());
}

function updateStye(e, orignalGroup, layer) {
    orignalGroup.find('Line').forEach(line => {
        line.stroke('red');
    })
    orignalGroup.find('Arrow').forEach(line => {
        line.stroke('red');
    })
    orignalGroup.find('Circle').forEach(circle => {
        if (circle.name() === 'main') {
            circle.stroke('red');
        } else {
            circle.fill('blue');
            circle.radius(6);
        }
    })
    layer.batchDraw();
}

function recoverStyle(e, orignalGroup, layer) {
    orignalGroup.find('Line').forEach(line => {
        line.stroke('black');
    })
    orignalGroup.find('Arrow').forEach(line => {
        line.stroke('black');
    })
    orignalGroup.find('Circle').forEach(circle => {
        if (circle.name() === 'main') {
            circle.stroke('black');
        } else {
            circle.fill('black');
            circle.radius(2);
        }
    })
    layer.batchDraw();
}

function groupCommon(props, group, layer, stage, previewStage) {
    const store = getStore();
    group.on('transformend', function (e) {
        console.log('transform end');
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer);
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.markChange(group.id());
    });

    group.on('dragend', function () {
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.caculateDeviceIntersectionWithAreaOrUnit(group, layer);
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer, 'drag');
    });

    group.on('mouseover', function (e) {
        document.body.style.cursor = 'pointer';
        updateStye(e, group, layer);
    })

    group.on('mouseout', function (e) {
        document.body.style.cursor = 'default';
        recoverStyle(e, group, layer);
        layer.batchDraw();
    })

    group.on('dragstart', function () {
        store.dispatch(cleanContentMenu());
    });

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let circles = group.find('Circle')
        let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); });
        let containerRect = stage.container().getBoundingClientRect();
        let top = containerRect.top + newCircles[1].getClientRect().y + newCircles[1].getClientRect().height / 2;
        let left = containerRect.left + newCircles[1].getClientRect().x + newCircles[1].getClientRect().width / 2;
        store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
    });
}



