import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    MenuItem,
    InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import withStyles from './styles';
import PropTypes from 'prop-types'
import { getCodeName, PROJECT_VALUATION_TYPE, SERVICE_COST_SHARE_TYPE, VALUATION_TYPE_PRICE } from "@/utils/manufactureUtils";
import * as _ from 'lodash'

const UpdateProjectComponent = props => {
    const { classes,
        openProjectFlag,
        openType,
        projectData,
        companyList,
        projectServiceList,
        installs,
        manufactureDicts,
        dptsSelect,
        isMainCompany,
        onhandleClose,
        onhandleSave,
        onhandleDateChange,
        onhandleNameClose,
        onhandleCustomerChange,
        onhandleServiceChange,
        onhandleChange,
        onhandleDptsChange,
        onhandleServiceDptsChange,
    } = props;

    const getRequireDptList = () => {
        if (projectData.requireDptSelect) {
            return projectData.requireDptSelect ? projectData.requireDptSelect : dptsSelect
        }
        return [];
    }

    const getServiceDptList = () => {
        if (projectData.serviceDptSelect) {
            return projectData.serviceDptSelect ? projectData.serviceDptSelect : dptsSelect
        }
        return [];
    }

    const renderServiceSide = (project) => {
        if (!project) return ''
        if (project.serviceCompany) {
            const com = _.find(companyList, c => { return c.companyId === project.serviceCompany })
            return com.companyName
        }
        return project.serviceSide ? project.serviceSide : ''
    }
    return (
        <div alignitems="center">
            <Dialog open={openProjectFlag} onClose={onhandleClose} fullScreen className={classes.dialog}>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography component={'span'} variant='h3'>
                        {openType === 1 ? '新建工程计划' : '工程详情'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column" >
                        <Grid container>
                            <Grid item container xs={9} className={classes.itemGrid}>
                                <Typography>{'工程名称'}</Typography>
                                <TextField
                                    fullWidth
                                    name='projectName'
                                    margin="normal"
                                    value={projectData.projectName || ''}
                                    onChange={(event) => onhandleChange(event)}
                                // InputProps={{
                                //     readOnly: openType === 2,
                                // }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'外部工单号'}</Typography>
                                <TextField
                                    fullWidth
                                    name='ticketNo'
                                    margin="normal"
                                    value={projectData.ticketNo || ''}
                                    onChange={(event) => onhandleChange(event)}
                                // InputProps={{
                                //     readOnly: openType === 2,
                                // }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={6} className={classes.itemGrid}>
                                <Typography>{'工程服务'}</Typography>
                                <TextField
                                    name='serviceId'
                                    fullWidth
                                    margin="normal"
                                    select
                                    value={projectData.serviceId || 0}
                                    InputProps={{
                                        readOnly: openType === 2,
                                    }}
                                    onChange={(event) => onhandleServiceChange(event, projectData)}
                                >
                                    {projectServiceList && projectServiceList.map(ele => (
                                        <MenuItem key={ele.serviceId} value={ele.serviceId}>
                                            {ele.serviceName}
                                        </MenuItem>)
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'计价类型'}</Typography>
                                <TextField
                                    name='valuationType'
                                    fullWidth
                                    margin="normal"
                                    value={getCodeName(manufactureDicts, PROJECT_VALUATION_TYPE, projectData.valuationType) || ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            {projectData.serviceType !== 'OUTER' &&
                                <Grid item xs={3} className={classes.itemGrid}>
                                    <Typography>{'分摊类型'}</Typography>
                                    <TextField
                                        name='costSharing'
                                        fullWidth
                                        margin="normal"
                                        value={getCodeName(manufactureDicts, SERVICE_COST_SHARE_TYPE, projectData.costSharing) || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container>

                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'供方'}</Typography>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    options={(isMainCompany && openType === 1) ? companyList.map((ele) =>
                                        ele.companyName
                                    ) : []}
                                    style={{
                                        minWidth: 120,
                                    }}
                                    onClose={(event, reason) => onhandleNameClose(event, reason)}
                                    onChange={(event, value) => onhandleCustomerChange(event, value)}
                                    value={renderServiceSide(projectData)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                readOnly: (!isMainCompany || openType === 2),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {projectData.serviceCompany &&
                                <Grid item xs={3} key='provideDpts' className={classes.itemGrid}>
                                    <Typography gutterBottom>
                                        {'供方班组'}
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        ChipProps={{
                                            style: { maxHeight: 22 }
                                        }}
                                        style={{
                                            minWidth: 160,
                                            maxWidth: 360,
                                            marginTop: 16
                                        }}
                                        disabled={openType === 2}
                                        filterSelectedOptions
                                        id='provideDpts'
                                        options={getServiceDptList()}
                                        getOptionLabel={option => option.dptName}
                                        onChange={onhandleServiceDptsChange}
                                        value={projectData.provideDpts || []}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'需方'}</Typography>
                                {projectData.serviceType === 'OUTER' && <TextField
                                    name='requireSide'
                                    margin="normal"
                                    value={projectData.requireSide}
                                    fullWidth
                                    InputProps={{
                                        readOnly: openType === 2,
                                    }}
                                    onChange={(event, value) => onhandleChange(event, value)}
                                >
                                </TextField>}
                                {projectData.serviceType !== 'OUTER' && <TextField
                                    name='requireCompany'
                                    margin="normal"
                                    select
                                    fullWidth
                                    value={projectData.requireCompany || 0}
                                    InputProps={{
                                        readOnly: openType === 2,
                                    }}
                                    onChange={(event, value) => onhandleCustomerChange(event, value)}
                                >
                                    {projectData.requireCompanySelect && projectData.requireCompanySelect.map(ele => (
                                        <MenuItem key={ele.companyId} value={ele.companyId}>
                                            {ele.companyName}
                                        </MenuItem>)
                                    )}
                                </TextField>}
                            </Grid>
                            {projectData.requireCompany &&
                                <Grid item xs={3} key='requireDpts' className={classes.itemGrid}>
                                    <Typography gutterBottom>
                                        {'需方班组'}
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        ChipProps={{
                                            style: { maxHeight: 22 }
                                        }}
                                        style={{
                                            minWidth: 160,
                                            maxWidth: 360,
                                            marginTop: 16
                                        }}
                                        disabled={openType === 2}
                                        filterSelectedOptions
                                        id='requireDpts'
                                        options={getRequireDptList()}
                                        getOptionLabel={option => option.dptName}
                                        value={projectData.requireDpts || []}
                                        onChange={onhandleDptsChange}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Grid>}
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'装置'}</Typography>
                                <TextField
                                    hidden={true}
                                    margin="normal"
                                    select
                                    fullWidth
                                    name='installId'
                                    value={projectData.installId || ''}
                                    InputProps={{
                                        readOnly: openType === 2,
                                    }}
                                    onChange={onhandleChange} >
                                    {installs && installs.map(i => (<MenuItem key={i.installId} value={i.installId}>{i.installName}</MenuItem>))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'开始时间'}</Typography>
                                <MuiPickersUtilsProvider key={'startTime'} utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        invalidDateMessage={'时间格式错误'}
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='startTime'
                                        InputProps={{
                                            readOnly: projectData.rate > 0,
                                        }}
                                        value={projectData.startTime || ''}
                                        onChange={(date, value) => onhandleDateChange(value, 'startTime')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'结束时间'}</Typography>
                                <MuiPickersUtilsProvider key={'endTime'} utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        invalidDateMessage={'时间格式错误'}
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='endTime'
                                        InputProps={{
                                            readOnly: projectData.rate > 0,
                                        }}
                                        value={projectData.endTime || ''}
                                        onChange={(date, value) => onhandleDateChange(value, 'endTime')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'工期'}</Typography>
                                <TextField
                                    name='period'
                                    margin="normal"
                                    fullWidth
                                    value={projectData.period || 0}
                                    InputProps={{
                                        endAdornment: <InputAdornment >天</InputAdornment>,
                                        width: 100,
                                        readOnly: true,
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'材料总价'}</Typography>
                                <TextField
                                    name='materialFee'
                                    type="number"
                                    margin="normal"
                                    fullWidth
                                    value={projectData.materialFee || 0}
                                    onChange={(event) => onhandleChange(event)}
                                    InputProps={{
                                        endAdornment: <InputAdornment >元</InputAdornment>,
                                        readOnly: (projectData.rate > 0 || projectData.valuationType === VALUATION_TYPE_PRICE),
                                    }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'劳务工记录'}</Typography>
                                <TextField
                                    name='laborerType'
                                    type="number"
                                    fullWidth
                                    select
                                    margin="normal"
                                    value={projectData.laborerType || 'NONE'}
                                    onChange={(event) => onhandleChange(event)}
                                    InputProps={{
                                        readOnly: projectData.rate > 0,
                                    }}
                                >
                                    <MenuItem value='NONE'>{'不填写'}</MenuItem>
                                    <MenuItem value='WORKTIME'>{'填写工时'}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'预算金额'}</Typography>
                                <TextField
                                    name='budget'
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    value={projectData.budget || 0}
                                    onChange={(event) => onhandleChange(event)}
                                    InputProps={{
                                        endAdornment: <InputAdornment >元</InputAdornment>,
                                        readOnly: (projectData.rate > 0 || projectData.valuationType === VALUATION_TYPE_PRICE),
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'决算金额'}</Typography>
                                <TextField
                                    name='finalCost'
                                    type="number"
                                    margin="normal"
                                    fullWidth
                                    value={projectData.finalCost || 0}
                                    onChange={(event) => onhandleChange(event)}
                                    InputProps={{
                                        endAdornment: <InputAdornment >元</InputAdornment>,
                                        readOnly: projectData.rate < 1,
                                    }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

UpdateProjectComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    openProjectFlag: PropTypes.bool,
    openType: PropTypes.number,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleChange: PropTypes.func,
};

UpdateProjectComponent.defaultProps = {
    companyList: [],
    departmentList: [],
    projectServiceList: [],
    installs: [],
    manufactureDicts: [],
    dptsSelect: [],
};

export default withStyles(UpdateProjectComponent);