import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    marketingRuleList: [],//营销规则配置
};

export const setMarketingRules = createAction('@@xcloud/amoeba/setMarketingRules');

export const addMarketingRule = createAction('@@xcloud/amoeba/addMarketingRule',
    (ruleDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/marketingrules', ruleDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加营销规则配置成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加营销规则配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateMarketingRule = createAction('@@xcloud/amoeba/updateMarketingRule',
    (ruleDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/marketingrules/' + ruleDto.ruleId, ruleDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新营销规则配置成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `更新营销规则配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delMarketingRule = createAction('@@xcloud/amoeba/deleteMarketingRule',
    (ruleId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/marketingrules/' + ruleId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除营销规则配置成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除营销规则配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getMarketingRules = createAction('@@xcloud/amoeba/getMarketingRules',
    (ruleId, parentId, isPlan) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/marketingrules'
            let strs = []
            if (ruleId || parentId || isPlan) {
                if (ruleId) strs.push('ruleId=' + ruleId)
                if (parentId) strs.push('parentId=' + parentId)
                if (isPlan) strs.push('isPlan=' + isPlan)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setMarketingRules(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取营销规则配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const amoebaMarketingRuleReducer = handleActions(
    {
        [setMarketingRules]: (state, { payload: data }) => {
            return {
                ...state,
                marketingRuleList: data
            }
        },
    },
    initialState
)

export default amoebaMarketingRuleReducer
