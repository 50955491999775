import React, { useState } from 'react';
import {
    Box,
    Card,
    Grid,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Link,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    CardActionArea,

} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { StarBorder } from '@material-ui/icons'


const NewsPage = props => {
    const { classes
    } = props;

    function _formatTitle(news) {
        if (news.title.length > 16) {
            return news.title.substring(0, 15) + '...'
        } else {
            return news.title
        }
    }

    const onhandlenNewsClick = (e) => {

    }


    function _formatContent(news) {

        if (news.title.length > 8) {
            if (news.content.length > 28) {
                return news.content.substring(0, 27) + '...'
            } else {
                return news.content
            }
        } else {
            if (news.content.length > 42) {
                return news.content.substring(0, 41) + '...'
            } else {
                return news.content
            }
        }
    }

    const [bigNewsList] = useState([
        {
            title: '告公告公告公告公告公告',
            image: '/images/login.png'
        },
        {
            title: '告公告公告公告公告公告',
            image: '/images/login.png'
        },
        {
            title: '告公告公告公告公告公告',
            image: '/images/login.png'
        }

    ])


    const [newsList] = useState([
        {
            title: '公告',
            time: '19/10/02',
            author: '志麟',
            image: '/images/login.png',
            content: '这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里'
        },
        {
            title: '公告公告公告公告公告公告公告公告公告公告公告公告公告公告',
            time: '19/10/02',
            author: '志麟',
            image: '/images/login.png',
            content: '这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里'
        },
        {
            title: '公告',
            time: '19/10/02',
            author: '志麟',
            image: '/images/login.png',
            content: '这里描述摘要公告和简介这里描述摘要公告和简介这'
        },
        {
            title: '公告公告公告公告',
            time: '19/10/02',
            author: '志麟',
            image: '/images/login.png',
            content: '这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里描述摘要公告和简介这里'
        },
    ]);

    return (
        <div alignitems="center" className={classes.root}>

            <Card>
                <CardContent>
                    <Grid container className={classes.gridcontainer}>

                        {/* <Carousel className={classes.carousel}>
                            {bigNewsList.map((tile, index) => (
                                <CarouselSlide key={index} className={classes.carouselslide}>
                                    <Card className={classes.slidecard}>
                                        <CardMedia image={tile.image} title={tile.title}
                                        ></CardMedia>
                                    </Card>
                                </CarouselSlide>
                            ))}
                        </Carousel> */}

                        <GridList className={classes.gridlist} cols={1}>
                            {bigNewsList.map((tile, index) => (
                                <GridListTile key={index}>
                                    <img src={tile.image} alt={tile.title} width={'80%'} />
                                    <GridListTileBar
                                        title={tile.title}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <IconButton aria-label={`star ${tile.title}`}>
                                                <StarBorder className={classes.title} />
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </Grid>
                    <Grid container className={classes.containercard} >
                        {newsList.map((item, index) => (
                            <Card key={index} className={classes.newscard}>

                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    image='/images/login.png'
                                    title={item.title}
                                    className={classes.newcardimage}
                                />
                                <CardActionArea onClick={onhandlenNewsClick}>
                                    <CardContent >
                                        <Typography variant="h3"> {_formatTitle(item)}</Typography>
                                        <Typography variant="subtitle2">时间：{item.time} 作者：{item.author}</Typography>
                                        <Typography variant="body1">{_formatContent(item)}</Typography>
                                        <Link href="/news/1" >查看更多</Link>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                    </Grid>

                    <Box className={classes.pagination}>
                        <Typography variant="subtitle2" className={classes.pagetext}>1-10 / 85</Typography>
                        <Button variant="outlined" className={classes.pagebutton}>{'<'}</Button>
                        <Button variant="outlined" className={classes.pagebutton}> 1</Button>
                        <Button variant="outlined" className={classes.pagebutton}>2</Button>
                        <Button variant="outlined" className={classes.pagebutton}>3</Button>
                        <Button variant="outlined" className={classes.pagebutton}>4</Button>
                        <Button variant="outlined" className={classes.pagebutton}>{'>'}</Button>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )

}

NewsPage.propTypes = {
    title: PropTypes.string,
};

NewsPage.defaultProps = {
    title: '新闻公告',
};


export default withStyles(NewsPage);