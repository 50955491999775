import React from 'react';
import {
    Grid,
    IconButton,
    Typography,
    Switch,
    Button,
    TextField,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import palette from '@/theme/palette';
import * as _ from 'lodash';
import { getEnvCode } from '@/utils/exportfile';

const DailyProdReportComponent = props => {
    const { classes,
        reports,
        onhandleOpenTrack,
        reportFixeds,
        selectedDate,
        dpts,
        sortSubs,
        companies,
        budgets,
        onhandleDateChange,
        selectedCompanyId,
        viewType,
        userDepartment,
        roleFlag,
        planDisplay,
        onhandlePlanDisplay,
        onGenerateAmoebaReport,
        innerWidth,
        onhandleOpenTrends,
        onhandleCompanyChange,
    } = props;
    const tableLocalization = {
        ...localization,
    }

    const cellStyle = { padding: 6, whiteSpacing: 'nowrap' }

    let tableOptions = {
        ...options,
        toolbar: false,
        paging: false,
        fixedColumns: {
            left: 1,
        },
        rowStyle: rowData => ({
            backgroundColor: calculateBackgroudColor(rowData.tableData.path, rowData.tableData.id)
        }),
        headerStyle: {
            backgroundColor: palette.table.header.backgroundColor,
            whiteSpacing: 'nowrap',
            padding: '6px 6px',
        }
    }

    const calculateBackgroudColor = (path, id) => {
        let cnt = id
        if (path) {
            cnt = _.sum(path) + path.length - 1
        }
        return cnt % 2 ? palette.table.oddRow.backgroundColor : palette.table.evenRow.backgroundColor
    }


    const tableStyle = {
        ...style
    }

    const subLevelJudge = (rowData) => {
        if (rowData.subLevel === 1) {
            return { paddingLeft: '25px' }
        } else if (rowData.subLevel === 2) {
            return { paddingLeft: '35px' }
        } else if (rowData.subLevel === 3) {
            return { paddingLeft: '45px' }
        }
        return {}
    }

    const calculateWidth = (colNum, isDisplay) => {

        let width = (innerWidth - 440) / colNum
        if (isDisplay) {
            width = width / 2
        }
        if (width < 100) {
            width = 100
        }
        return width

    }

    const formatNumber = (num, unit) => {

        if (!num || isNaN(num)) return 0.0

        if (unit === '万元') {
            return Number(num / 10000).toFixed(2)
        } else {
            return Number(num).toFixed(2)
        }
    }

    const renderNumber = (row, value, field) => {

        if (row.subCode === 'HEADCOUNT' || row.subCode === 'ACTUALHEADCOUNT') {
            return <Typography>{Number(row['sum' + value[field]]).toFixed(0)}
            </Typography>
        } else {
            return <Typography
                className={classes.numberlabel}
                onClick={() => onhandleOpenTrack(row, value, field)}>
                {formatNumber(row['sum' + value[field]], row.unit)}
            </Typography>
        }
    }

    const getPlan = (subId, id, isCompany) => {
        let dtl = null;
        if (budgets) {
            if (isCompany) {
                if (id) {
                    if (id === 1) {
                    } else {
                        dtl = _.find(budgets, { 'subId': subId, 'companyId': id });
                    }
                } else {
                    dtl = _.find(budgets, { 'subId': subId, 'companyId': null });
                }
            } else {
                if (id) {
                    dtl = _.find(budgets, { 'subId': subId, 'dptId': id })
                } else {
                    dtl = _.find(budgets, { 'subId': subId, 'dptId': null })
                }
            }

        }
        return dtl ? dtl.amount : 0
    }

    const renderPlanCompare = (row, id, isCompany) => {

        if (row.subCode === 'HEADCOUNT' || row.subCode === 'ACTUALHEADCOUNT'
            || row.subCode === 'ADDITIONAL' || row.subCode === 'BALANCE'
            || row.subCode === 'MARGINALPROFITRATIO' || row.subCode === 'WORKTIME') {
            return <Typography>
            </Typography>
        } else {
            let amount = Number(row['sum' + id]) - getPlan(row.subId, id, isCompany)
            let planAmt = getPlan(row.subId, id, isCompany)
            if (row.unit === '万元') {
                amount = amount / 10000
                planAmt = planAmt / 10000
            }
            amount = amount.toFixed(2)
            planAmt = planAmt.toFixed(2)

            if (Math.abs(amount) < 0.01) {
                return <Typography>
                </Typography>
            }

            return <Tooltip title={'预算: ' + planAmt + row.unit}>
                <Typography style={{ color: amount > 0 ? 'red' : 'green' }}>
                    {amount}
                </Typography>
            </Tooltip>
        }
    }

    const assemblycolumns = () => {
        const columns = []
        let column = {
            title: '指标名称',
            field: 'subName',
            width: 200,
            render: row => <Grid container justify="space-between">
                <Tooltip title={row.legend}><Typography>{row.subName}{'(' + row.unit + ')'}</Typography></Tooltip>
                <IconButton size="small" onClick={() => { onhandleOpenTrends(row) }}>
                    <AssessmentIcon fontSize="inherit" />
                </IconButton>
            </Grid>,
            editable: 'never',
            cellStyle: (value, rowData) => {
                return subLevelJudge(rowData);
            }
        }
        columns.push(column);
        if (viewType === 'COMPANY') {
            // const dptlist = dpts.filter(dpt => dpt.amoebaType === 'PROFIT' || dpt.dptId === 0)
            _.forEach(dpts, value => {
                let column = {
                    title: value.dptName,
                    field: 'sum' + value.dptId,
                    align: 'right',
                    width: calculateWidth(dpts.length, planDisplay),
                    cellStyle: cellStyle,
                    render: row => renderNumber(row, value, 'dptId')
                };
                columns.push(column);
                let columnSpace = {
                    title: '',
                    field: 'sum' + value.dptId,
                    align: 'right',
                    hidden: !planDisplay,
                    width: calculateWidth(dpts.length, planDisplay),
                    cellStyle: cellStyle,
                    render: row => renderPlanCompare(row, value.dptId, false)
                }
                columns.push(columnSpace);
            })
        } else if (viewType === 'DEPARTMENT') {
            let column = {
                title: userDepartment.dptName,
                field: 'sum' + userDepartment.dptId,
                align: 'right',
                width: calculateWidth(1, planDisplay),
                cellStyle: cellStyle,
                render: row => renderNumber(row, userDepartment, 'dptId')
            };
            columns.push(column);
            let columnSpace = {
                title: '',
                field: 'sum' + userDepartment.dptId,
                align: 'right',
                hidden: !planDisplay,
                width: calculateWidth(1, planDisplay),
                cellStyle: cellStyle,
                render: row => renderPlanCompare(row, userDepartment.dptId, false)
            }
            columns.push(columnSpace);
        } else {
            _.forEach(companies, value => {
                let column = {
                    title: value.companyAlias || value.companyName,
                    field: 'sum' + value.companyId,
                    align: 'right',
                    width: calculateWidth(companies.length, planDisplay),
                    cellStyle: cellStyle,
                    render: row => renderNumber(row, value, 'companyId')
                };
                columns.push(column);
                let columnSpace = {
                    title: '',
                    field: 'sum' + value.companyId,
                    align: 'right',
                    hidden: !planDisplay,
                    width: calculateWidth(companies.length, planDisplay),
                    cellStyle: cellStyle,
                    render: row => renderPlanCompare(row, value.companyId, true)
                };
                columns.push(columnSpace);
            })
        }
        return columns
    }

    const sub6Cal = (id, isDpt, sub, sub6, sub37, sub38) => {
        if (isNaN(sub6['sum' + id])) { sub6['sum' + id] = 0 }
        if (isNaN(sub37['sum' + id])) { sub37['sum' + id] = 0 }
        if (isNaN(sub38['sum' + id])) { sub38['sum' + id] = 0 }
        if (isDpt) {
            sub['sum' + id] = Number(sub6['sum' + id]) - Number(sub38['sum' + id])
        } else {
            sub['sum' + id] = Number(sub6['sum' + id]) - Number(sub38['sum' + id]) - Number(sub37['sum' + id])
        }
    }

    const sub7Cal = (id, isCompany, sub, sub7, sub37, sub38) => {
        if (isNaN(sub38['sum' + id])) {
            sub38['sum' + id] = 0
        }
        if (isCompany) {
            sub['sum' + id] = Number(sub7['sum' + id]) + Number(sub38['sum' + id])
        } else {
            sub['sum' + id] = Number(sub7['sum' + id]) + Number(sub37['sum' + id]) + Number(sub38['sum' + id])
        }
    }

    const sub5Cal = (id, sub, sub2, sub3) => {
        if (isNaN(sub2['sum' + id])) {
            sub2['sum' + id] = 0
        }
        if (isNaN(sub3['sum' + id])) {
            sub3['sum' + id] = 0
        }
        if (Number(sub2['sum' + id])) {
            sub['sum' + id] = ((Number(sub2['sum' + id]) - Number(sub3['sum' + id])) / Number(sub2['sum' + id])).toFixed(2)
        } else {
            sub['sum' + id] = 0
        }
    }

    const sub8Cal = (id, sub, sub2, sub3, sub6, sub32) => {
        if (isNaN(sub2['sum' + id])) {
            sub2['sum' + id] = 0
        }
        if (isNaN(sub3['sum' + id])) {
            sub3['sum' + id] = 0
        }
        if (isNaN(sub6['sum' + id])) {
            sub6['sum' + id] = 0
        }
        if (isNaN(sub32['sum' + id])) {
            sub32['sum' + id] = 0
        }
        if (Number(sub32['sum' + id])) {
            sub['sum' + id] = ((Number(sub2['sum' + id]) - Number(sub3['sum' + id]) - Number(sub6['sum' + id])) / Number(sub32['sum' + id])).toFixed(2)
        } else {
            sub['sum' + id] = 0
        }
    }

    const sub9Cal = (id, sub, sub2, sub3, sub6) => {
        if (isNaN(sub2['sum' + id])) {
            sub2['sum' + id] = 0
        }
        if (isNaN(sub3['sum' + id])) {
            sub3['sum' + id] = 0
        }
        if (isNaN(sub6['sum' + id])) {
            sub6['sum' + id] = 0
        }
        if (Number(sub2['sum' + id]) - Number(sub3['sum' + id])) {
            sub['sum' + id] = ((Number(sub6['sum' + id]) * Number(sub2['sum' + id])) / (Number(sub2['sum' + id]) - Number(sub3['sum' + id]))).toFixed(2)
        } else {
            sub['sum' + id] = 0
        }
    }

    const assemblyData = () => {
        if (reports.length === 0) {
            return [];
        }
        if (viewType === 'COMPANY') {
            _.forEach(sortSubs, value => {
                let subs = _.filter(reports, { 'subId': value.subId });
                let sum = 0
                let sumFixed = 0
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        value['sum' + report.dptId] = Number(report.sum).toFixed(2)
                        sum = sum + report.sum
                        let sumFixedTemp = _.filter(reportFixeds, rf => { return rf.dptId === report.dptId && rf.subId === value.subId })
                        if (sumFixedTemp && sumFixedTemp.length) {
                            sumFixedTemp.forEach(fixed => {
                                value['sumFixed' + report.dptId] = fixed.delta
                                value['opentext' + report.dptId] = fixed.opentext
                                sumFixed = sumFixed + fixed.delta
                            })
                        }
                    }
                })
                if (sum !== null)
                    value['sum0'] = sum.toFixed(2);
                if (sumFixed !== null)
                    value['sumFixed0'] = sumFixed.toFixed(2);
            })
        } else if (viewType === 'DEPARTMENT') {
            _.forEach(sortSubs, value => {
                let subs = _.filter(reports, { 'subId': value.subId, 'dptId': userDepartment.dptId });
                let sum = null;
                let sumFixed = null
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        value['sum' + report.dptId] = report.sum.toFixed(2)
                        sum = sum + report.sum
                        let sumFixedTemp = _.filter(reportFixeds, rf => { return rf.dptId === report.dptId && rf.subId === value.subId })
                        if (sumFixedTemp && sumFixedTemp.length) {
                            sumFixedTemp.forEach(fixed => {
                                value['sumFixed' + report.dptId] = fixed.delta
                                value['opentext' + report.dptId] = fixed.opentext
                                sumFixed = sumFixed + fixed.delta
                            })
                        }
                    }
                })
                if (sum !== null)
                    value['sum0'] = sum.toFixed(2);
                if (sumFixed !== null)
                    value['sumFixed0'] = sumFixed.toFixed(2);
            })
        } else {
            _.forEach(sortSubs, value => {
                let subs = _.filter(reports, { 'subId': value.subId });
                let sum = 0;
                let sumFixed = null
                _.forEach(subs, report => {
                    if (report.sum != null) {
                        if (value.subCode === 'FIXEDFEE' || value.subCode === 'PROFIT') {
                            const workspacesortSub = _.find(sortSubs, { subCode: 'WORKSPACESHARING' })
                            if (workspacesortSub) {
                                const workspacesortReport = _.filter(reports, { 'subId': workspacesortSub.subId, companyId: report.companyId });
                                if (workspacesortReport.length > 0) {
                                    if (value.subCode === 'FIXEDFEE') {
                                        value['sum' + report.companyId] = (report.sum - workspacesortReport[0].sum).toFixed(2)
                                    } else {
                                        value['sum' + report.companyId] = (report.sum + workspacesortReport[0].sum).toFixed(2)
                                    }
                                }
                            }
                        } else {
                            value['sum' + report.companyId] = report.sum.toFixed(2);
                        }
                        sum = sum + report.sum
                        let sumFixedTemp = _.filter(reportFixeds, rf => { return rf.companyId === report.companyId && rf.subId === value.subId })
                        if (sumFixedTemp && sumFixedTemp.length) {
                            sumFixedTemp.forEach(fixed => {
                                value['sumFixed' + report.dptId] = fixed.delta
                                value['opentext' + report.dptId] = fixed.opentext
                                sumFixed = sumFixed + fixed.delta
                            })
                        }
                    }
                })
                if (sum !== null)
                    value['sum0'] = sum.toFixed(2);
                if (sumFixed !== null)
                    value['sumFixed0'] = sumFixed.toFixed(2);
            })
        }
        let sub2 = _.find(sortSubs, sub => sub.subId === 2);  //收入
        let sub3 = _.find(sortSubs, sub => sub.subId === 3);  //变动费
        let sub6 = _.find(sortSubs, sub => sub.subId === 6);  //固定费
        let sub7 = _.find(sortSubs, sub => sub.subId === 7); //利润
        let sub32 = _.find(sortSubs, sub => sub.subId === 32); //工作时间
        let sub37 = _.find(sortSubs, sub => sub.subId === 37); //公司分摊
        let sub38 = _.find(sortSubs, sub => sub.subId === 38); //分厂分摊
        _.forEach(sortSubs, sub => {
            if (sub.subId === 6) {
                if (viewType === 'COMPANY') {
                    sub6Cal(0, true, sub, sub6, sub37, sub38)
                } else if (viewType === 'DEPARTMENT') {
                } else {
                    sub6Cal(0, false, sub, sub6, sub37, sub38)
                }
            }
            if (sub.subId === 7) {
                if (viewType === 'COMPANY') {
                    sub7Cal(0, true, sub, sub7, sub37, sub38)
                } else if (viewType === 'DEPARTMENT') {
                } else {
                    sub7Cal(0, false, sub, sub7, sub37, sub38)
                }
            }
            if (sub.subId === 5) { //边界利润率
                if (viewType === 'COMPANY') {
                    _.forEach(dpts, value => {
                        sub5Cal(value.dptId, sub, sub2, sub3)
                    })
                } else if (viewType === 'DEPARTMENT') {
                    sub5Cal(userDepartment.dptId, sub, sub2, sub3)
                } else {
                    _.forEach(companies, value => {
                        sub5Cal(value.companyId, sub, sub2, sub3)
                    })
                }
            } else if (sub.subId === 8) { //单位时间附加
                if (viewType === 'COMPANY') {
                    _.forEach(dpts, value => {
                        sub8Cal(value.dptId, sub, sub2, sub3, sub6, sub32)
                    })
                } else if (viewType === 'DEPARTMENT') {
                    sub8Cal(userDepartment.dptId, sub, sub2, sub3, sub6, sub32)
                } else {
                    _.forEach(companies, value => {
                        sub8Cal(value.companyId, sub, sub2, sub3, sub6, sub32)
                    })
                }
            } else if (sub.subId === 9) { //盈亏平衡点
                if (viewType === 'COMPANY') {
                    _.forEach(dpts, value => {
                        sub9Cal(value.dptId, sub, sub2, sub3, sub6)
                    })
                } else if (viewType === 'DEPARTMENT') {
                    sub9Cal(userDepartment.dptId, sub, sub2, sub3, sub6)
                } else {
                    _.forEach(companies, value => {
                        sub9Cal(value.companyId, sub, sub2, sub3, sub6)
                    })
                }
            }
        })
        return sortSubs
        // return _.filter(sortSubs, { 'subParentId': 0 })
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer} item xs={12}>
                    <Grid className={classes.button} item xs={3} >
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    id="date-selector"
                                    margin="normal"
                                    value={selectedDate}
                                    onChange={onhandleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item className={classes.buttonlabel}><Typography>预算</Typography></Grid>
                    <Grid item className={classes.button}>
                        <Switch color='primary'
                            checked={planDisplay}
                            onClick={onhandlePlanDisplay} >
                        </Switch>
                    </Grid>
                    {(getEnvCode() !== 'prod' || selectedDate.format('YYYY-MM-DD') === selectedDate.clone().endOf('M').format('YYYY-MM-DD')) &&
                        <Grid item className={classes.button}>
                            <Button variant='contained' color='primary' onClick={onGenerateAmoebaReport} >
                                <AutorenewIcon />核算
                            </Button>
                        </Grid>
                    }
                    {roleFlag &&
                        <Grid item className={classes.button}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                label='分厂'
                                name='selectedCompany'
                                InputProps={{
                                    className: classes.input
                                }}
                                onChange={onhandleCompanyChange}
                                value={selectedCompanyId || 0}
                            >
                                {companies && companies.map(e => (
                                    <MenuItem key={e.companyId} value={e.companyId}>{e.companyAlias}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        parentChildData={(row, rows) => rows.find(ele => ele.subId === row.subParentId)}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

DailyProdReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companies: PropTypes.array,
};

DailyProdReportComponent.defaultProps = {
    reports: [],
};

export default withStyles(DailyProdReportComponent);