import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Button,
    List,
    Typography
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getDateByTimestamp, getYear } from '../../..//utils/datetime';
import { localization, options, style } from "../../../utils/mtable";
import * as _ from 'lodash';


const RegulationPlanList = props => {
    const {
        classes,
        plans,
        onDetailedInfo,
        onhandleNewPlanF,
        onhandleCreate,
        createPermission,
        // createPermissionR,//是否有启动权限
        onhandleNewPlan,
        annuPlan
    } = props;

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: false
    }

    const renderPlanType = (planType) => {
        let mapping = { 'TEMP': '临时', 'ANNU': '年度' };
        return mapping[planType]
    }


    const assemblycolumns = () => {

        let columns = [
            {
                title: '计划名称',
                field: 'planName',
                render: row => row ? <Typography>{row.planName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '计划类型',
                field: 'planType',
                render: row => row ? <Typography>{renderPlanType(row.planType)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发起时间',
                field: 'createTime',
                type: 'date',
                render: row => row ? <Typography>{getDateByTimestamp(row.createTime)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }
    const renderActions = () => {
        if (createPermission) {
            if (!annuPlan || annuPlan.length === 0) {
                let year = getYear();
                return (
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={onhandleNewPlanF.bind(this, year)}
                        >
                            发起{year}年度计划
                    </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={(event) => onhandleCreate(event)}
                        >
                            新建临时计划
                    </Button></div>

                );
            }
            else {
                let statusCode = annuPlan.statusCode;
                let planId = annuPlan.planId;
                let year = annuPlan.year;
                let newYear = year + 1;
                if (statusCode === 'Published') {
                    return (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={onhandleNewPlan.bind(this, planId, newYear)}
                            >
                                发起{newYear}年度计划
                        </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={(event) => onhandleCreate(event)}
                            >
                                新建临时计划
                        </Button></div>
                    );
                }
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={(event) => onhandleCreate(event)}
                    >
                        新建临时计划
                    </Button>
                );
            }
        }
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={(event) => onhandleCreate(event)}
                >
                    新建临时计划
                </Button>
            </div>
        );
    }
    const assemblyData = () => {

        if (!plans || plans.length === 0)
            return []

        //排序按业务线和指标排序
        let tempPlansSort = _.sortBy(plans, ['createTime'])

        let assemblyData = []
        let preCreateTime = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in tempPlansSort) {
            let row = {
                num: tempPlansSort.length - Number(idx),
                ...tempPlansSort[idx]
            }

            if (preCreateTime === tempPlansSort[idx].unitId) {
                row['createTimeDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['createTimeDuplicated'] = false
                preCreateTime = tempPlansSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }

    return (
        <div alignitems="center" className={classes.root}>

            <div alignitems="center" className={classes.planitemroot}>

                <Grid>
                    <Grid container >
                        <Grid item>
                            <List >
                                {renderActions()}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            editable={{}}
                            localization={localization}
                            style={style}
                            actions={
                                [rowData => ({
                                    icon: 'book',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onDetailedInfo(event, rowData.planId)
                                    }
                                })]}
                            components={{
                                Toolbar: props => (
                                    <div alignitems="right">
                                        <MTableToolbar {...props} />
                                        <div>

                                        </div>
                                    </div>
                                ),
                            }}
                        >
                        </MaterialTable>
                    </Grid>
                </Grid>
                <Grid>
                </Grid>
            </div>
        </div >

    );
};

RegulationPlanList.propTypes = {
    classes: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired
};
RegulationPlanList.defaultProps = {
    plans: [],
    userProfile: {},
    constant: {},
}
export default withStyles(RegulationPlanList);

