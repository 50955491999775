import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MajorHazardInspectionPage from '../../../components/MajorHazardSources/MajorHazardDetection/MajorHazardInspectionPage';
import {
  getPlanDetails
} from '../../../redux/modules/inspection/inspectionPlan';
import {
  getPlanRecords,
} from '../../../redux/modules/inspection/inspectionPlanMonthly';
import { cleanmodal } from '../../../redux/modules/modal';
import _ from 'lodash';
import { getMoment as moment } from "../../../utils/datetime";

class MajorHazardInspectionPageContainer extends Component {
  static propTypes = {
    deviceList: PropTypes.object,
    parameters: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      yearMonth: moment()().format("YYYY/MM"),
      detailShow: false,
      currentPlanDetail: {},
      rowPerPage: 5
    };
  }

  componentWillMount() {
    this.setState({
      yearMonth: moment()().format("YYYY/MM"),
      detailShow: false,
      currentPlanDetail: {},
      rowPerPage: 5
    })
    this.props.onhandleGetPlanDetails({ yearMonth: moment()().format("YYYY/MM"), targetId: this.props.parameters.targetId, inspectType: 'INS05', status: 'done' });
  }



  handleSelect = (event, field) => {
    switch (field) {
      case 'yearMonth': // 年-月模糊匹配详情记录 startTime
        this.setState({
          yearMonth: moment()(event).format("YYYY/MM")
        })
        this.props.onhandleGetPlanDetails({ yearMonth: moment()(event).format("YYYY/MM"), targetId: this.props.parameters.targetId, inspectType: 'INS05', status: 'done' });
        break;
      default:
      // do nothing
    }
  };

  handleView = (event, value) => {
    this.setState(
      {
        detailShow: true,
        currentPlanDetail: value,
        rowPerPage: 5
      }
    )
    this.props.onhandleGetPlanRecords({
      dtlId: value.dtlId,
      offset: 0,
      size: 5,
      sort: "-record_time"
    })

  }

  handleBack = (event) => {
    this.setState(
      {
        detailShow: false,
        currentPlanDetail: {},
        rowPerPage: 5
      }
    )
  }

  handleListMapping = (colValue, field) => {
    let value = colValue;
    const {
      constant,
      users,
      categoryList
    } = this.props;
    switch (field) {
      case 'company':
        value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyAlias : '';
        break;
      case 'departmentShow':
        const deptNameList = [];
        colValue.forEach(dept => {
          let deptStr = '';
          deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
          deptNameList.push(deptStr);
        })
        value = deptNameList.join(', ');
        break;
      case 'departmentEdit':
        value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
        break;
      case 'inspectType':
        value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
        break;
      case 'category':
        value = '-'
        const mapping = _.find(constant.inspectionDictionaries, { 'code': colValue });
        if (mapping && _.find(categoryList, { 'code': mapping.code })) {
          value = mapping.name;
        }
        break;
      case 'user':
        const user = _.find(users.list, { 'userId': colValue });
        value = user ? user.lastName + user.firstName : '';
        break;
      case 'status':
        if (colValue === 'done') {
          value = '已完成'
        } else if (colValue === 'active') {
          value = '进行中'
        } else if (colValue === 'finalized') {
          value = '未启动'
        } else if (colValue === 'invalid') {
          value = '过期'
        } else {
          value = '无效'
        }
        break;
      case 'result':
        value = '未排查'
        if (colValue && colValue !== '') {
          if (colValue === 'true') {
            value = '合格';
          } else if (colValue === 'false') {
            value = '不合格';
          } else if (colValue === 'none') {
            value = '不涉及';
          } else {
            value = colValue + '分'
          }
        }
        break;
      default:
      // do nothing
    }
    return value;
  };

  setRowPerPage = (value) => {
    this.setState({
      rowPerPage: value
    })
  }

  render() {
    return (
      <MajorHazardInspectionPage
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleExit={this.props.onhandleCleanmodal.bind(this)}
        onhandleBack={this.handleBack.bind(this)}
        onhandleListMapping={this.handleListMapping.bind(this)}
        lists={this.props.planDetails}
        yearMonth={this.state.yearMonth}
        detailShow={this.state.detailShow}
        onView={this.handleView.bind(this)}
        currentPlanDetail={this.state.currentPlanDetail}
        rowPerPage={this.state.rowPerPage}
        setRowPerPage={this.setRowPerPage.bind(this)}
        Recordlists={this.props.planRecords}
        onhandleGetPlanRecords={this.props.onhandleGetPlanRecords}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parameters: state.modal.parameters,
    constant: state.constant,
    users: state.user.users,
    categoryList: state.inspection.categoryList,
    planDetails: state.inspectionPlan.planDetails,
    planRecords: state.inspectionPlanMonthly.planRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleGetPlanDetails: (query) => {
      dispatch(getPlanDetails(query))
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
    onhandleGetPlanRecords: (query) => {
      dispatch(getPlanRecords(query))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MajorHazardInspectionPageContainer);
