import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Select from '@material-ui/core/Select';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { getNameByTypeAndCode } from '../../../../utils/constant';
import UserCard from '../../../../containers/UserCard';

const ProcessInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleActivate,
        onhandleDeactivate,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        onhandleSelectionChange,
        currentProcess,
        processError,
        tabValue,
        index,
        constants,
        diagramChange,
        processChange
    } = props;

    const typeOptions = () => (
        <React.Fragment>
            {
                constants.PROCESS && constants.PROCESS.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                ))
            }
        </React.Fragment>
    )

    return (
        <div key={currentProcess.processId}>
            <Card className={classes.card} hidden={tabValue !== index}>
                {currentProcess.processId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                流程编号
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography gutterBottom>
                                {currentProcess.processId}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            流程名称
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            error={processError.processName !== ''}
                            helperText={processError.processName || ''}
                            className={classes.textField}
                            value={currentProcess.processName || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'processName')}
                            onBlur={event => onhandleCheck(event, 'processName')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            流程类型
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Select
                            error={processError.processType !== ''}
                            className={classes.textField}
                            native
                            value={currentProcess.processType}
                            onChange={(event) => onhandleSelectionChange(event, 'processType')}
                            onBlur={event => onhandleCheck(event, 'processType')}
                        >
                            <option aria-label="None" value="" />
                            {typeOptions()}
                        </Select>
                        <p className={classes.selectError} hidden={processError.processType === ''}>{processError.processType}</p>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            流程编码
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        {currentProcess.processId ? (
                            <Typography gutterBottom>
                                {currentProcess.processCode}
                            </Typography>
                        ) : (<TextField
                            error={processError.processCode !== ''}
                            helperText={processError.processCode || ''}
                            className={classes.textField}
                            value={currentProcess.processCode || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'processCode')}
                            onBlur={event => onhandleCheck(event, 'processCode')}
                        />)
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            流程描述
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentProcess.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            流程状态
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography gutterBottom>
                            {getNameByTypeAndCode(constants, 'STATUS', currentProcess.status)}
                        </Typography>
                    </Grid>
                </Grid>

                {currentProcess.processId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentProcess.createTime, true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentProcess.processId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <UserCard userId={currentProcess.creator}
                                child={
                                    <Typography gutterBottom>
                                        {currentProcess.creatorLastName + currentProcess.creatorFirstName}
                                    </Typography>}
                            />
                        </Grid>
                    </Grid>) : null
                }

                {currentProcess.processId && currentProcess.updateTime ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改时间
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentProcess.updateTime, true)}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

                {currentProcess.processId && currentProcess.revisor ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改人
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <UserCard userId={currentProcess.revisor}
                                child={
                                    <Typography gutterBottom>
                                        {currentProcess.revisorLastName + currentProcess.revisorFirstName}
                                    </Typography>
                                }
                            />

                        </Grid>
                    </Grid>) : null
                }

            </Card>

            <Box hidden={tabValue !== index}>
                <Grid container spacing={0} className={classes.gridContainer} >
                    {currentProcess.status === 'draft' ? (<Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            disabled={!diagramChange && !processChange}
                            onClick={event => onhandleSave(event)}
                        >
                            保存
                        </Button>
                    </Grid>) : null}
                    {currentProcess.processId && currentProcess.status === 'draft' ? (
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                disabled={diagramChange || processChange}
                                onClick={event => onhandleActivate(event)}
                            >
                                激活
                            </Button>
                        </Grid>
                    ) : null}
                    {currentProcess.processId && currentProcess.status === 'active' ? (
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={event => onhandleDeactivate(event)}
                            >
                                终止有效
                            </Button>
                        </Grid>
                    ) : null}
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleBack(event)}

                        >
                            返回
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </div>
    );
};

ProcessInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleActivate: PropTypes.func.isRequired,
    onhandleDeactivate: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    currentProcess: PropTypes.object.isRequired,
    processError: PropTypes.object.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    isloading: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired,
    diagramChange: PropTypes.bool.isRequired,
    processChange: PropTypes.bool.isRequired
};

export default withStyles(ProcessInfo);
