import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash'
import {
    createDeviceGroup,
    createDevice,
    connectDevice2Storey,
    disconnectDevice2Storey,
    updateDevice,
    updateDevices,
    connectDevice2Area,
    disconnectDevice2Area
} from '../../../../redux/modules/devices';
import {
    updateDeviceGroup,
    updateDeviceGroups,
    getDeviceGroup
} from '../../../../redux/modules/deviceGroup';
import {
    updateInstallationStorey,
    createInstallationUnit,
    updInstallationUnit,
    createInstallationArea,
    updInstallationArea,
    initInstallationCommon
} from '../../../../redux/modules/installation';
import {
    updateNewTreeData
} from './deviceTree';
import mediumColor from './data/mediumColor.json';

const initialState = {
    newDevice: {},
    newUnit: {},
    newArea: {},
    newDeviceGroup: {},
    deleteDevices: {},
    deleteUnits: {},
    deleteAreas: {},
    modifyDevices: [],
    modifyUnits: [],
    modifyAreas: [],
    modifyDeviceGroup: [],
    mapChanged: false,
    connectDeviceArea: [],
    disconnectDeviceArea: [],
    recalDeviceGroup: {},
    autoSave: false,
    mediumColor: mediumColor
}
export const createMapDevice = createAction('@@xcloud/installationRelation/CreateMapDevice');
export const createMapUnit = createAction('@@xcloud/installationRelation/createMapUnit');
export const createMapArea = createAction('@@xcloud/installationRelation/createMapArea');
export const updMapChanged = createAction('@@xcloud/installationRelation/updMapChanged');
export const updAutoSave = createAction('@@xcloud/installationRelation/updAutoSave');

export const addExistDeviceToMap = createAction('@@xcloud/installationRelation/addExistDeviceToMap',
    (device) => (dispatch, getState) => {
        let dto = {
            "groupName": device.deviceName,
            "installId": getState().installation.editInstallation.installId,
            "tagNo": '',
        }
        return Promise.all([
            dispatch(createDeviceGroup(dto)),
        ]).then(
            (deviceGroupData) => {
                device.groupId = deviceGroupData[0].payload.groupId;
                return Promise.all([
                    dispatch(updateDevice(device)),
                ]).then(
                    (DeviceData) => {
                        let Dtos = [{
                            deviceId: DeviceData[0].payload.deviceId,
                            storeyId: getState().installationPlug.selectedId
                        }]
                        return Promise.all([
                            dispatch(connectDevice2Storey(Dtos))
                        ]).then(
                            (Data) => {
                                dispatch(initInstallationCommon(getState().installation.editInstallation.installId))
                                let rtnData = DeviceData[0].payload;
                                let type = _.find(getState().devices.deviceLegends, function (o) { return o.cat === rtnData.cat; }).legend;
                                rtnData.type = type;
                                dispatch(updMapChanged(true));
                                return rtnData;
                            }).catch(err => {
                                return {}
                            })
                    }
                ).catch(err => {
                    return {}
                })
            }
        ).catch(err => {
            return {}
        })
    });

export const createDeviceMap = createAction('@@xcloud/installationRelation/createDeviceMap',
    (device) => (dispatch, getState) => {
        let dto = {
            "groupName": device.deviceName,
            "installId": getState().installation.editInstallation.installId,
            "tagNo": '',
        }
        return Promise.all([
            dispatch(createDeviceGroup(dto)),
        ]).then(
            (deviceGroupData) => {
                device.companyId = getState().installation.editInstallation.companyId;
                device.groupId = deviceGroupData[0].payload.groupId;
                return Promise.all([
                    dispatch(createDevice(device)),
                ]).then(
                    (DeviceData) => {
                        let Dtos = [{
                            deviceId: DeviceData[0].payload.deviceId,
                            storeyId: getState().installationPlug.selectedId
                        }]
                        return Promise.all([
                            dispatch(connectDevice2Storey(Dtos))
                        ]).then(
                            (Data) => {
                                dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                                let rtnData = DeviceData[0].payload;
                                rtnData.type = device.type;
                                dispatch(updateNewTreeData(rtnData));
                                dispatch(updMapChanged(true));
                                return rtnData;
                            }).catch(err => {
                                return {}
                            })
                    }
                ).catch(err => {
                    return {}
                })
            }
        ).catch(err => {
            return {}
        })
    }
);
export const deleteMapDevice = createAction('@@xcloud/installationRelation/deleteMapDevice');
export const deleteDeviceMap = createAction('@@xcloud/installationRelation/deleteDeviceMap',
    (rtndata) => (dispatch, getState) => {
        console.log(rtndata);
        let storeyDevice = rtndata.storeyDevice;
        let device = rtndata.device;
        let areas = rtndata.areaDevices;
        let deviceGroup = rtndata.deviceGroup;
        let taskArray = []
        taskArray.push(dispatch(disconnectDevice2Storey([storeyDevice])));
        taskArray.push(dispatch(updateDevices([device])));
        if (areas && areas.length > 0) {
            taskArray.push(dispatch(disconnectDevice2Area(areas)));
        }
        if (deviceGroup && deviceGroup.groupId) {
            console.log(getState().installation.editInstallation.deviceGroups)
            let dGroup = _.find(getState().installation.editInstallation.deviceGroups, function (o) { return o.groupId === deviceGroup.groupId; })
            if (dGroup) {
                dGroup.status = 'inactive';
                taskArray.push(dispatch(updateDeviceGroup(dGroup)));
            }
        }
        console.log(taskArray);
        return Promise.all(taskArray).then(
            (Data) => {
                dispatch(initInstallationCommon(getState().installation.editInstallation.installId))
                dispatch(clearDataCache());
                dispatch(updMapChanged(false));
                return device.type + '_' + device.deviceId;
            }).catch(err => {
                return {}
            })
    });

export const deleteMapUnit = createAction('@@xcloud/installationRelation/deleteMapUnit');
export const deleteUnitMap = createAction('@@xcloud/installationRelation/deleteUnitMap',
    (rtndata) => (dispatch, getState) => {
        console.log(rtndata);
        let devices = rtndata.devices;
        let unit = rtndata.unit;
        let taskArray = []
        taskArray.push(dispatch(updInstallationUnit(unit)));
        if (devices && devices.length > 0) {
            taskArray.push(dispatch(updateDevices(devices)));
        }
        console.log(taskArray);
        return Promise.all(taskArray).then(
            (Data) => {
                dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                dispatch(clearDataCache());
                dispatch(updMapChanged(false));
                return unit.type + '_' + unit.unitId;
            }).catch(err => {
                return {}
            })
    });

export const createUnitMap = createAction('@@xcloud/installationRelation/createUnitMap',
    (unit) => (dispatch, getState) => {
        let dto = {
            "unitName": unit.unitName,
            "installId": getState().installation.editInstallation.installId,
        }
        return Promise.all([
            dispatch(createInstallationUnit(dto)),
        ]).then(
            (unitData) => {
                dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                let rtnData = unitData[0].payload;
                rtnData.type = unit.type;
                dispatch(updMapChanged(true));
                return rtnData;
            }
        ).catch(err => {
            return {}
        })
    });


export const deleteMapArea = createAction('@@xcloud/installationRelation/deleteMapArea');
export const deleteAreaMap = createAction('@@xcloud/installationRelation/deleteAreaMap',
    (rtndata) => (dispatch, getState) => {
        console.log(rtndata);
        let areas = rtndata.areaDevices;
        let area = rtndata.area;
        let taskArray = []
        taskArray.push(dispatch(disconnectDevice2Area(areas)));
        console.log(taskArray);
        return Promise.all(taskArray).then(
            (Data) => {
                return Promise.all([
                    dispatch(dispatch(updInstallationArea(area))),
                ]).then(
                    (areaData) => {
                        dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                        dispatch(clearDataCache());
                        dispatch(updMapChanged(false));
                        return area.type + '_' + area.areaId;
                    }
                ).catch(err => {
                    return {}
                })
            }).catch(err => {
                return {}
            })
    });
export const createAreaMap = createAction('@@xcloud/installationRelation/createAreaMap',
    (area) => (dispatch, getState) => {
        let dto = {
            "areaName": area.areaName,
            "installId": getState().installation.editInstallation.installId,
        }
        return Promise.all([
            dispatch(createInstallationArea(dto)),
        ]).then(
            (unitData) => {
                dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                let rtnData = unitData[0].payload;
                rtnData.type = area.type;
                dispatch(updMapChanged(true));
                return rtnData;
            }
        ).catch(err => {
            return {}
        })
    });
export const createMapDeviceGroup = createAction('@@xcloud/installationRelation/createMapDeviceGroup');
export const createDeviceGroupMap = createAction('@@xcloud/installationRelation/createDeviceGroupMap',
    (deviceGroup) => (dispatch, getState) => {
        let dto = {
            "groupName": deviceGroup.name,
            "installId": getState().installation.editInstallation.installId,
            "tagNo": deviceGroup.tagNo,
        }
        let selectedDevicelist = [];
        let updDeviceListGrouplist = [];
        if (deviceGroup.deviceList && deviceGroup.deviceList.length > 0) {
            deviceGroup.deviceList.forEach(device => {
                let newDevice = _.find(getState().devices.searchDevicelist.list, function (o) { return o.deviceId === _.toInteger(device.value); });
                console.log(newDevice);
                let updDeviceGroup = newDevice.deviceGroup;
                updDeviceGroup.status = 'inactive';
                updDeviceListGrouplist.push(updDeviceGroup);
                if (device.masterId) {
                    newDevice.masterId = _.toInteger(device.masterId);
                }
                selectedDevicelist.push(newDevice);
            })
        }

        return Promise.all([
            dispatch(createDeviceGroup(dto)),
        ]).then(
            (deviceGroupData) => {
                if (selectedDevicelist && selectedDevicelist.length > 0) {
                    selectedDevicelist.forEach(device => {
                        device.groupId = deviceGroupData[0].payload.groupId
                    })
                }
                return Promise.all([
                    dispatch(updateDevices(selectedDevicelist)),
                ]).then(
                    (DeviceData) => {
                        return Promise.all([
                            dispatch(updateDeviceGroups(updDeviceListGrouplist))
                        ]).then(
                            (Data) => {
                                dispatch(initInstallationCommon(getState().installation.editInstallation.installId));
                                let rtnData = deviceGroupData[0].payload;
                                rtnData.type = 'DeviceGroup';
                                dispatch(updMapChanged(true));
                                return rtnData;
                            }).catch(err => {
                                return {}
                            })
                    }
                ).catch(err => {
                    return {}
                })
            }
        ).catch(err => {
            return {}
        })
    });

export const saveMap = createAction('@@xcloud/installationRelation/saveMap',
    (device) => (dispatch, getState) => {
        // if (!getState().installationRelation.onSave) {
        //     dispatch(updOnSave(true))
        let storey = device.storey;
        let devices = device.devices;
        let units = device.units;
        let areas = device.areas;
        let deviceGroups = device.deviceGroups;
        let connectDeviceAreas = getState().installationRelation.connectDeviceArea;
        let disconnectDeviceAreas = getState().installationRelation.disconnectDeviceArea;
        let taskArray = []
        taskArray.push(dispatch(updateInstallationStorey(storey)));
        if (devices && devices.length > 0) {
            taskArray.push(dispatch(updateDevices(devices)));
        }
        if (units && units.length > 0) {
            units.forEach(unit => {
                taskArray.push(dispatch(updInstallationUnit(unit)));
            })
        }
        if (areas && areas.length > 0) {
            areas.forEach(area => {
                taskArray.push(dispatch(updInstallationArea(area)));
            })
        }
        if (deviceGroups && deviceGroups.length > 0) {
            taskArray.push(dispatch(updateDeviceGroups(deviceGroups)));
        }
        if (connectDeviceAreas && connectDeviceAreas.length > 0) {
            taskArray.push(dispatch(connectDevice2Area(connectDeviceAreas)));
        }
        if (disconnectDeviceAreas && disconnectDeviceAreas.length > 0) {
            taskArray.push(dispatch(disconnectDevice2Area(disconnectDeviceAreas)));
        }
        return Promise.all(taskArray).then(
            (Data) => {
                dispatch(initInstallationCommon(getState().installation.editInstallation.installId))
                let rtnData = Data[0].payload;
                dispatch(clearDataCache());
                dispatch(updMapChanged(false));
                return rtnData;
            }).catch(err => {
                return {}
            })
    }
    // }
);

export const removeFromGroup = createAction('@@xcloud/installationRelation/removeFromGroup',
    (device) => (dispatch, getState) => {
        let recalGroupId = device.groupId;
        let dto = {
            "groupName": device.deviceName,
            "installId": getState().installation.editInstallation.installId,
            "tagNo": '',
        }
        return Promise.all([
            dispatch(createDeviceGroup(dto)),
        ]).then(
            (deviceGroupData) => {
                device.groupId = deviceGroupData[0].payload.groupId;
                device.masterId = 0;
                return Promise.all([
                    dispatch(updateDevice(device)),
                ]).then(
                    (DeviceData) => {
                        dispatch(initInstallationCommon(getState().installation.editInstallation.installId))
                        return { id: recalGroupId };
                    }
                ).catch(err => {
                    return {}
                })
            }
        ).catch(err => {
            return {}
        })
    });

export const addToDeviceGroup = createAction('@@xcloud/installationRelation/addToDeviceGroup',
    (device, groupId) => (dispatch, getState) => {
        let oldGroupId = device.groupId;
        let oldGroup = _.find(getState().installation.editInstallation.deviceGroups, function (o) { return o.groupId === oldGroupId; });
        console.log(oldGroup);
        oldGroup.status = 'inactive';
        return Promise.all([
            dispatch(updateDeviceGroup(oldGroup)),
            dispatch(getDeviceGroup(groupId)),
        ]).then(
            (deviceGroupData) => {
                console.log(deviceGroupData)
                let devicelist = deviceGroupData[1].payload.devices;
                console.log(devicelist);
                let masterId = _.find(devicelist, function (o) { return !o.masterId; }).deviceId;
                device.groupId = groupId;
                device.masterId = masterId;
                return Promise.all([
                    dispatch(updateDevice(device)),
                ]).then(
                    (DeviceData) => {
                        return Promise.all([
                            dispatch(initInstallationCommon(getState().installation.editInstallation.installId))
                        ]).then(
                            (data) => {
                                return { id: groupId };
                            }
                        ).catch(err => {
                            return {}
                        })
                    }
                ).catch(err => {
                    return {}
                })
            }
        ).catch(err => {
            return {}
        })
    });

export const cleanRecalDeviceGroup = createAction('@@xcloud/installationRelation/cleanRecalDeviceGroup');
export const clearDataCache = createAction('@@xcloud/installationRelation/clearDataCache');
export const addConnectDeviceArea = createAction('@@xcloud/installationRelation/connectDeviceArea');
export const addDisconnectDeviceArea = createAction('@@xcloud/installationRelation/addDisconnectDeviceArea');
export const clearDeviceAreaRelation = createAction('@@xcloud/installationRelation/clearDeviceAreaRelation');
export const markChangeDevice = createAction('@@xcloud/installationRelation/markChangeDevice');
export const markChangeUnit = createAction('@@xcloud/installationRelation/markChangeUnit');
export const markChangeArea = createAction('@@xcloud/installationRelation/markChangeArea');
export const markChangeDeviceGroup = createAction('@@xcloud/installationRelation/markChangeDeviceGroup');
// Actions

export const installationRelationReducer = handleActions(
    {
        [clearDataCache]: (state, { payload: device }) => {
            return {
                ...state,
                newDevice: {},
                newUnit: {},
                newArea: {},
                newDeviceGroup: {},
                modifyDevices: [],
                modifyUnits: [],
                modifyAreas: [],
                modifyDeviceGroup: [],
                connectDeviceArea: [],
                disconnectDeviceArea: [],
                autoSave: false,
                onSave: false
            }
        },
        [addConnectDeviceArea]: (state, { payload: value }) => {
            return {
                ...state,
                connectDeviceArea: [...state.connectDeviceArea, value]
            }
        },
        [addDisconnectDeviceArea]: (state, { payload: value }) => {
            return {
                ...state,
                disconnectDeviceArea: [...state.disconnectDeviceArea, value]
            }
        },
        [clearDeviceAreaRelation]: (state, { payload: value }) => {
            let newConnectDeviceArea = _.reject(state.connectDeviceArea, function (o) { return (o.areaId === value.areaId && o.deviceId === value.deviceId); })
            let newdisConnectDeviceArea = _.reject(state.disconnectDeviceArea, function (o) { return (o.areaId === value.areaId && o.deviceId === value.deviceId); })
            return {
                ...state,
                connectDeviceArea: newConnectDeviceArea,
                disconnectDeviceArea: newdisConnectDeviceArea
            }
        },
        [createDeviceMap]: (state, { payload: device }) => {
            return {
                ...state,
                newDevice: device
            }
        },
        [createUnitMap]: (state, { payload: unit }) => {
            return {
                ...state,
                newUnit: unit
            }
        },
        [createAreaMap]: (state, { payload: area }) => {
            return {
                ...state,
                newArea: area
            }
        },
        [createDeviceGroupMap]: (state, { payload: deviceGroup }) => {
            return {
                ...state,
                newDeviceGroup: deviceGroup
            }
        },
        [addExistDeviceToMap]: (state, { payload: device }) => {
            return {
                ...state,
                newDevice: device
            }
        },
        [createMapDevice]: (state) => {
            return {
                ...state,
                modifyDevices: [...state.modifyDevices, state.newDevice],
                newDevice: {}
            }
        },
        [createMapUnit]: (state) => {
            return {
                ...state,
                modifyUnits: [...state.modifyUnits, state.newUnit],
                newUnit: {}
            }
        },
        [createMapArea]: (state) => {
            return {
                ...state,
                modifyAreas: [...state.modifyAreas, state.newArea],
                newArea: {}
            }
        },
        [createMapDeviceGroup]: (state) => {
            return {
                ...state,
                modifyDeviceGroup: [...state.modifyDeviceGroup, state.newDeviceGroup],
                newDeviceGroup: {}
            }
        },
        [updMapChanged]: (state, { payload: value }) => {
            return {
                ...state,
                mapChanged: value
            }
        },
        [markChangeDevice]: (state, { payload: value }) => {
            let newModifyDevices = _.reject(state.modifyDevices, function (o) { return o.deviceId === value.deviceId; })
            newModifyDevices.push(value)
            return {
                ...state,
                modifyDevices: newModifyDevices,
                mapChanged: true
            }
        },
        [markChangeUnit]: (state, { payload: value }) => {
            return {
                ...state,
                modifyUnits: [...state.modifyUnits, value],
                mapChanged: true
            }
        },
        [markChangeArea]: (state, { payload: value }) => {
            return {
                ...state,
                modifyAreas: [...state.modifyAreas, value],
                mapChanged: true
            }
        },
        [markChangeDeviceGroup]: (state, { payload: value }) => {
            let newModifyDeviceGroup = _.reject(state.modifyDeviceGroup, function (o) { return o.groupId === value.groupId; })
            newModifyDeviceGroup.push(value)
            return {
                ...state,
                modifyDeviceGroup: newModifyDeviceGroup,
                mapChanged: true
            }
        },
        [deleteDeviceMap]: (state, { payload: value }) => {
            return {
                ...state,
                deleteDevices: { deviceId: value }
            }
        },
        [deleteMapDevice]: (state) => {
            return {
                ...state,
                deleteDevices: {},
                mapChanged: true
            }
        },
        [deleteUnitMap]: (state, { payload: value }) => {
            return {
                ...state,
                deleteUnits: { unitId: value }
            }
        },
        [deleteMapUnit]: (state) => {
            return {
                ...state,
                deleteUnits: {},
                mapChanged: true
            }
        },
        [deleteAreaMap]: (state, { payload: value }) => {
            return {
                ...state,
                deleteAreas: { areaId: value }
            }
        },
        [deleteMapArea]: (state) => {
            return {
                ...state,
                deleteAreas: {},
                mapChanged: true
            }
        },
        [addToDeviceGroup]: (state, { payload: value }) => {
            return {
                ...state,
                recalDeviceGroup: value,
            }
        },
        [removeFromGroup]: (state, { payload: value }) => {
            return {
                ...state,
                recalDeviceGroup: value,
            }
        },
        [cleanRecalDeviceGroup]: (state) => {
            return {
                ...state,
                recalDeviceGroup: {},
                mapChanged: true
            }
        },
        [updAutoSave]: (state, { payload: value }) => {
            return {
                ...state,
                autoSave: value
            }
        },
    },
    initialState
)

export default installationRelationReducer