import React from 'react';
import {
    Grid, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { getCodeName, PLAN_STATUS } from '@/utils/manufactureUtils';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDay } from '@/utils/datetime';
import zhCN from 'date-fns/locale/zh-CN';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import * as _ from 'lodash';
import { Add } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';

const MTempPlanListComponent = props => {
    const { classes,
        plans,
        companyId,
        selectedDate,
        companies,
        // departments,
        manufactureDicts,
        onhandleDateChange,
        onhandleDeletePlan,
        ohhandleOpenDetail,
        onhandleAddOrder,
        ohhandleOpenTempPlanConfig,
    } = props;

    const getCompanyName = (companyId) => {
        const company = _.find(companies, { 'companyId': companyId });
        return company === undefined ? '' : company.companyName
    }

    const renderActions = (row) => {
        if (row.status === 'active') {//计划起草中
            return <Grid container>
                <Grid item><Tooltip title='启动'><IconButton variant='text' onClick={() => onhandleAddOrder(row)}> <PlayArrowIcon /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='修改'><IconButton variant='text' onClick={() => ohhandleOpenDetail(row, 'EDIT')}> <EditIcon /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => onhandleDeletePlan(row)}> <DeleteIcon /></IconButton></Tooltip></Grid>
            </Grid>
        }
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '分厂',
                dataIndex: 'companyId',
                render: (text, row, index) => <Typography>{getCompanyName(row.companyId)}</Typography>,
                editable: 'never',
                emptyValue: getCompanyName(companyId)
            },
            {
                title: '计划名称',
                dataIndex: 'planName',
                render: (text, row, index) => <Typography>{row.planName}</Typography>
            },
            {
                title: '计划类型',
                dataIndex: 'planType',
                render: (text, row, index) => <Typography>{row.planType === 'TEMP' ? '临时' : row.planType}</Typography>,
                initialEditValue: 'TEMP',
                lookup: { 'TEMP': '临时' },
            }, {
                title: '开始时间',
                dataIndex: 'startTime',
                render: (text, row, index) => <Typography>{row.startTime ? formatDay(row.startTime) : ''}</Typography>,
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                render: (text, row, index) => <Typography>{row.endTime ? formatDay(row.endTime) : ''}</Typography>,
            }, {
                title: '状态',
                dataIndex: 'status',
                render: (text, row, index) => <Typography>{getCodeName(manufactureDicts, PLAN_STATUS, row.status)}</Typography>,
            }, {
                title: '操作',
                dataIndex: 'actions',
                width: 150,
                render: (text, row, index) => renderActions(row),
            }
        ]
        return columns
    }

    // const renderDpt = (dpts) => {
    //     if (!dpts || dpts.length === 0) {
    //         return <Typography>{''}</Typography>
    //     } else {
    //         const dpt = departments.find(dpt => dpt.dptId === dpts[0].dptId)
    //         return <Typography>{dpt ? dpt.dptName : ''}</Typography>
    //     }
    // }

    const expandedRowRender = (record, index) => {

        const expandedColumns = [
            {
                title: '服务',
                dataIndex: 'dtlName',
                render: (text, row, index) => <Typography>{row.dtlName}</Typography>,
                editable: 'never'
            },
            // {
            //     title: '班组',
            //     dataIndex: 'dptId',
            //     type: 'numeric',
            //     render: (text, row, index) => renderDpt(row.dpts),
            //     editable: 'never'
            // },
            {
                title: '数量',
                dataIndex: 'output',
                type: 'numeric',
                render: (text, row, index) => <Typography>{row.output}</Typography>
            },
            {
                title: '单位',
                dataIndex: 'outputUnit',
                render: (text, row, index) => <Typography>{row.outputUnit}</Typography>,
            },

        ]
        const expandedData = record.manufacturePlanDtls

        return <Table columns={expandedColumns}
            dataSource={expandedData}
            pagination={false}
            rowKey={'dtlId'}
        ></Table>

    }


    const assemblyData = () => {
        return plans
    }

    const getRowClass = (record, index) => {
        return (index % 2 !== 0 ? classes.oddRow : null);
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container alignItems="center" className={classes.toolbarContainer}>
                    <Grid item >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                views={['year', 'month']}
                                format="yyyy/MM"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={(e) => { }}
                                onMonthChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item >
                        <IconButton
                            variant='text'
                            onClick={() => ohhandleOpenDetail(null, 'NEW')}
                        >
                            <Add />
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton
                            variant='text'
                            onClick={() => ohhandleOpenTempPlanConfig()}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid>
                    <Table
                        className={classes.normalTable}
                        rowClassName={getRowClass}
                        rowKey={'planId'}
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        expandable={{ expandedRowRender }}
                        size={'small'}
                    ></Table>

                </Grid>
            </Grid>
        </div>
    )
}

MTempPlanListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    plans: PropTypes.any,
    companies: PropTypes.any,
    selectedDate: PropTypes.object,
    year: PropTypes.any,
    onhandleDateChange: PropTypes.func,
    onhandleRowAdd: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
    onhandleRowDtlAdd: PropTypes.func,
    onhandleRowDtlUpdate: PropTypes.func,
    onhandleRowDtlDelete: PropTypes.func,
};

MTempPlanListComponent.defaultProps = {
    plans: [],
    companies: [],
};

export default withStyles(MTempPlanListComponent);