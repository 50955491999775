import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import ActivitiesHeader from "../../../containers/Crm/Activities/ActivitiesHeader";
import ActivitiesList from "../../../containers/Crm/Activities/ActivitiesList";
import ActivitiesInfo from "../../../containers/Crm/Activities/ActivitiesInfo";
import {
    Grid,
    Paper,
} from '@material-ui/core';

const Activities = props => {

    const { classes, hiddenActivitiesInfo } = props;
    return (
        <div className={classes.root}>
            <ActivitiesHeader />
            <div className={classes.content}>
                <Grid container spacing={0}>
                    <Grid item xs={ hiddenActivitiesInfo ? 12 : 8}>
                        <Paper className={classes.paper} >
                            {/* <div style={{ height: "calc(100% - 52px)", overflowY: "scroll" }}>
                            xs=6xs=6xs=6xs=6xs=6xs=6xs=6xs=6
                            </div> */}
                            <ActivitiesList />
                        </Paper>
                    </Grid>
                    
                    {
                        !hiddenActivitiesInfo ?  (
                            <Grid item xs={4}>
                                <Paper className={classes.rightpaper}>
                                    {/* <div style={{ height: "calc(100% - 52px)", overflowY: "scroll" }}>
                                    xs=6xs=6xs=6xs=6xs=6xs=6xs=6xs=6
                                    </div> */}
                                    <ActivitiesInfo />
                                </Paper>
                            </Grid>
                        ) :null
                    }
                    
                </Grid>
            </div>
        </div>
    );
};

Activities.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(Activities);
