import React, { useEffect, useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { options, localization } from "../../../utils/mtable";
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import { getDateByTimestamp } from '../../../utils/datetime';
import _ from 'lodash';
import {
    Menu,
    Grid,
    TextField,
    Button,
    Typography,
    TablePagination,
    Card,
    Chip,
    MenuItem
} from '@material-ui/core';
import {
    Add,
    // SaveAlt,
    ExpandMore,
    ExpandLess,
    PostAdd,
    HighlightOff,
    DeleteOutlined,
    MoreHoriz,
    Edit,
    Description,
} from '@material-ui/icons';

const RouteListComponent = props => {
    const {
        classes,
        search,
        onhandleDeleteRoute,
        userProfile,
        companyTree,
        companyList,
        inspectionDictionaries,
        routeList,
        // onhandleChange,
        onhandleSelect,
        onhandleClearSearch,
        onhandleSearch,
        getRouteList,
        onSizeChange,
        onhandleCreate,
        onhandleEdit,
        onhandleView,
        onhandleCopy,
        inspectionRouteList,
        processStatus
    } = props;

    console.log('=== search')
    console.log(search)

    const [expand, setExpand] = useState(false);
    const [tableHeight, setTableHeight] = useState(null);
    const [page, setPage] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const checkPermissionW = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route' })
        return permission && permission.action === 'W'
    };

    const checkPermissionR = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route' })
        return permission && permission.action === 'R'
    };

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const openMenu = (event, row) => {
        let anchorElement = event.currentTarget;
        setCurrentRow(row);
        setAnchorEl(anchorElement);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }
        return false
    }

    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE" && t.code !== 'OIT05') {
                result.push({ label: t.name, value: t.code })
            }
        })

        return result;
    };

    // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
    const noOptionDisabled = (optionList) => {
        // console.log('optionList.....',optionList)
        // console.log('serch.....',search)
        // console.log('inspectionRouteList-----',inspectionRouteList)
        if (optionList && optionList.length > 0) {
            return false
        }

        return true
    };

    const getRouteData = (companyId, inspectionType) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        const condition = { companyId: _.toInteger(company) }
        if (inspectionType) condition.inspectionType = inspectionType

        let item = _.filter(inspectionRouteList, condition)
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.routeName, value: t.routeId })
            })
        }
        return result
    }

    const generateMenu = () => {
        const hasPermissionW = checkPermissionW();
        return (
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            // transformOrigin={{
            //     horizontal: 'bottom',
            // }}
            >
                {currentRow && currentRow.statusCode === 'Published' &&
                    <MenuItem dense onClick={() => { onhandleCopy(null, currentRow) }}>
                        <PostAdd className={classes.menuIcon} />{"起草新版本"}
                    </MenuItem>}
                {currentRow && ['Drafting', 'Rejected', 'Published'].includes(currentRow.statusCode) &&
                    <MenuItem dense className={classes.menuItemCritical} disabled={!hasPermissionW} onClick={() => { onhandleDeleteRoute(null, currentRow) }}>
                        {currentRow && currentRow.statusCode === 'Published' ?
                            <><HighlightOff className={classes.menuIcon} />{'废止'}</>
                            :
                            <><DeleteOutlined className={classes.menuIcon} />{'删除'}</>
                        }
                    </MenuItem>}
            </Menu >
        );
    }

    const assemblycolumns = () => {
        return [
            {
                title: '巡检类型',
                field: 'inspectionType',
                render: row => row ? <Typography>{renderInspectionType(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '所属公司',
                field: 'companyName',
                render: row => row ? <Typography>{row.companyName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '路线编码',
                field: 'routeCode',
                render: row => row ? <Typography>{row.routeCode}</Typography> : '',
                editable: 'never'
            },
            {
                title: '路线名称',
                field: 'routeName',
                render: row => row ? <Typography>{row.routeName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检项目数量',
                field: 'itemCount',
                render: row => row ? <Typography>{row.itemCount}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'inspectionType',
                render: row => row ? <Chip size="small" className={renderRowStatusDisplay(row)} label={renderRowStatus(row)} /> : '',
                // classes.pending
                editable: 'never'
            }
        ];
    };

    const renderInspectionType = (row) => {
        let item = _.filter(inspectionDictionaries, function (o) { return row.inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    };

    const renderTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }

    const renderRowStatus = (row) => {
        let item = _.filter(processStatus, function (o) { return row.statusCode === o.code })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    };

    const renderRowStatusDisplay = (row) => {
        let status = renderRowStatus(row)
        let cssClassName = classes.default

        if (row.status === 'invalid') {
            status = '废止'
        }

        switch (status) {
            case '起草中':
                cssClassName = classes.drafting
                break;
            case '已提交':
                cssClassName = classes.submitted
                break;
            case '驳回':
                cssClassName = classes.rejected
                break;
            case '待审批':
                cssClassName = classes.waitingforapprove
                break;
            case '已发布':
                cssClassName = classes.published
                break;
            case '废止':
                cssClassName = classes.invalid
                break;
            default:
                cssClassName = classes.default
        }

        return cssClassName
    };

    const getStatusData = () => {
        let result = []

        result.push({ label: '起草中', value: 'Drafting' })
        result.push({ label: '会审待邀请', value: 'ToBeInvited' })
        result.push({ label: '会审中', value: 'InTrial' })
        result.push({ label: '驳回', value: 'Rejected' })
        result.push({ label: '待签字', value: 'ToBeSigned' })
        result.push({ label: '会审', value: 'Trial' })
        result.push({ label: '待审批', value: 'Pending' })
        result.push({ label: '已提交', value: 'Submitted' })
        result.push({ label: '已上报', value: 'Reported' })
        result.push({ label: '审批通过', value: 'Approved' })
        result.push({ label: '已发布', value: 'Published' })

        return result
    }

    return (
        <Card className={classes.content}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'巡检路线'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={4}>
                        <Typography className={classes.label}>
                            所属单位
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-companyid"
                            select
                            value={search.companyId}
                            onChange={(event) => onhandleSelect(event, 'companyId')}
                            variant="outlined"
                            disabled={companyDisabled()}
                        >
                            {_.sortBy(companyList, 'companyId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={4}>
                        <Typography className={classes.label}>
                            巡检类型
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-inspectiontype"
                            select
                            value={search.inspectionType}
                            SelectProps={{ displayEmpty: true }}
                            onChange={(event) => onhandleSelect(event, 'inspectionType')}
                            variant="outlined"
                        >
                            <MenuItem key={0} value={""}>{'不限'}</MenuItem>
                            {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={4}>
                        <Typography className={classes.label}>
                            巡检路线
                        </Typography>
                        {/* 文本模糊查询 <TextField
                                    id="keyword-input"
                                    className={classes.textField}
                                    variant="standard"
                                    value={search.keyword || ''}
                                    style={{ width: '90%' }}
                                    // placeholder="巡检路线，名称"
                                    placeholder="路线编码，名称"
                                    onChange={event => onhandleChange(event, 'keyword')}
                                ></TextField> */}
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            value={search.routeId}
                            SelectProps={{ displayEmpty: true }}
                            onChange={(event) => onhandleSelect(event, 'routeId')}
                            variant="outlined"
                            disabled={noOptionDisabled(getRouteData(search.companyId, search.inspectionType))}
                        >
                            <MenuItem key={0} value={""}>{'不限'}</MenuItem>
                            {_.sortBy(getRouteData(search.companyId, search.inspectionType), 'routeId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {Boolean(expand) && <React.Fragment>
                        <Grid container item className={classes.gridItem} sm={6} md={expand ? 3 : 4} lg={4}>
                            <Typography className={classes.label}>
                                状态
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-status"
                                select
                                SelectProps={{ displayEmpty: true }}
                                value={search.statusCode}
                                onChange={(event) => onhandleSelect(event, 'statusCode')}
                                variant="outlined"
                            >
                                <MenuItem key={0} value={""}>{'不限'}</MenuItem>
                                {getStatusData().map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </React.Fragment>}

                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                        >
                            搜索
                        </Button>
                        <Button variant="outlined" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >
                            重置
                        </Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {!checkPermissionR() && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={(event) => { onhandleCreate(event) }}
                        >
                            新建
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={routeList.list}
                    options={{
                        ...options,
                        pageSize: _.toInteger(search.size),
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        maxBodyHeight: `${tableHeight}px`,
                        minBodyHeight: `${tableHeight}px`,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[15, 30, 50]}
                                rowsPerPage={_.toInteger(search.size)}
                                count={routeList.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getRouteList({ offset: pageNum * search.size, size: search.size, sort: "-update_time" })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    // setRowsPerPage(event.target.value);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    getRouteList({ offset: 0, size: event.target.value, sort: "-update_time" })
                                }}
                            />
                        )
                    }}
                    actions={(checkPermissionW() || checkPermissionR()) ?
                        [rowData => ({
                            icon: () => <Edit color={'primary'} />,
                            tooltip: '编辑',
                            hidden: rowData.statusCode !== 'Drafting' && rowData.statusCode !== 'Rejected',
                            onClick: (event, rowData) => {
                                onhandleEdit(event, rowData)
                            },
                        }),
                        rowData => ({
                            icon: () => <Description color={'primary'} />,
                            tooltip: '查看',
                            hidden: rowData.statusCode === 'Drafting' || rowData.statusCode === 'Rejected',
                            onClick: (event, rowData) => {
                                onhandleView(event, rowData)
                            }
                        }),
                        rowData => ({
                            icon: () => <MoreHoriz color={'primary'} />,
                            tooltip: '更多',
                            onClick: (event, rowData) => {
                                openMenu(event, rowData);
                            }
                        }),
                            // rowData => ({
                            //     icon: 'post_add',
                            //     tooltip: '起草新版本',
                            //     hidden: rowData.statusCode !== 'Published',
                            //     onClick: (event, rowData) => {
                            //         onhandleCopy(event, rowData)
                            //     },
                            // }),
                            // rowData => ({
                            //     icon: rowData.statusCode === 'Published' ? 'highlight_off' : 'delete_outlined',
                            //     tooltip: rowData.statusCode === 'Published' ? '废止' : '删除',
                            //     hidden: !['Drafting', 'Rejected', 'Published'].includes(rowData.statusCode),
                            //     disabled: !checkPermissionW(),
                            //     onClick: (event, rowData) => {
                            //         onhandleDeleteRoute(event, rowData)
                            //     }
                            // }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '新建',
                            //     isFreeAction: true,
                            //     hidden: checkPermissionR(),
                            //     onClick: (event) => {
                            //         onhandleCreate(event)
                            //     }
                            // }
                        ] : [rowData => ({
                            icon: () => <Description color={'primary'} />,
                            tooltip: '查看',
                            onClick: (event, rowData) => {
                                onhandleView(event, rowData)
                            }
                        })
                        ]}
                >
                </MaterialTable>
                {generateMenu()}
            </Grid>

        </Card>
    );
};

RouteListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    onhandleDeleteRoute: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    routeList: PropTypes.object.isRequired,
    inspectionRouteList: PropTypes.array.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleClearSearch: PropTypes.func.isRequired,
    onhandleSearch: PropTypes.func.isRequired,
    getRouteList: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleEdit: PropTypes.func.isRequired,
    onhandleView: PropTypes.func.isRequired,
    processStatus: PropTypes.array.isRequired,
};

export default withRouter(withStyles(RouteListComponent));