import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: 0,
    },
    timeline: {
        flexGrow: 1
    },
    select: {
        // flexDirection: 'row-reverse'
        marginTop: 7,
        width: '90%',
    },
    button: {
        margin: theme.spacing(1)
    },
    flexgrow: {
        flexGrow: 1
    },
    tab: {
        flexDirection: 'row',
        minWidth: '100px'
    },
    buttonList: {
        padding: 0
    },
    toolbar: {
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: 8
    },
    toolbarLabel: {
        padding: '8px'
    },
    toolbarLabelText: {
    },
    toolbarButton: {
    },
    toobarCreateButton: {
        paddingLeft: 8
    },
    calendarHeader: {
        backgroundColor: '#FFF',
        margin: '-1px -10px',
        lineHeight: '45px',
    },

    calendarEvent: {
        padding: '0 0 0 2px',
        borderRadius: '2px',
        backgroundColor: '#cdeefd',
    },
    calendarEventContainer: {
        color: '#015880',
        flexDirection: 'row',
    },
    calendarEventContainerIcon: {
        flexGrow: 1
    },
    itemButton: {
        margin: 8,
    }
});

export default withStyles(styles);