import { createAction, handleActions } from 'redux-actions';
import { getCompanyId } from "../../utils/userUtils";
import { createhttpMessage } from './message';
import { createloading, cleanloading } from './loading';
import { getCompanyTree } from './trees';
import { getCompanyById } from './company';
import { getDeviceDoctype } from './devices';
import { getDicOrderType } from './operation';
import _ from 'lodash';
const porxyApi = '/api'

const initialState = {
    operationPlanDocsList: {
        total: 0,
        list: []
    },
    search: {
        size: 10,
        page: 1,
        companyId: '',
        dptId: '',
        optNo: '',
        orderNo: '',
        deviceNo: '',
        accNo: '',
        type: '',
        supplierId: '',
        onlyMe: false
    },
    appendixResult : []
}

export const createOperationPlanDoc = createAction(
    '@@xcloud/operationPlanDocs/createOperationPlanDoc',
    (doc) => (dispatch, getState, httpClient) => {

        return httpClient
            .post(porxyApi + `/operationplans/docs`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    console.log("createOperationPlanDoc ============" + res.data)
                    dispatch(createhttpMessage({ status: 200 }, '上传计划报告成功'))
                    dispatch(getOperationPlanOrders())
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `上传计划报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updOperationPlanDoc = createAction(
    '@@xcloud/operationPlanDocs/updOperationPlanDoc',
    (doc) => (dispatch, getState, httpClient) => {

        return httpClient
            .patch(porxyApi + `/operationplans/docs/${doc.docId}`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新计划报告成功'))
                    dispatch(getOperationPlanOrders())
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新计划报告失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const updateOperationPlanOrder = createAction(
    '@@xcloud/operationPlanDocs/updateOperationPlanOrder',
    (param) => (dispatch, getState, httpClient) => {
        return httpClient
            .patch(porxyApi + `/operationplans/orders/${param.orderId}/details/${param.dtlId}`, param, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新计划工单成功'))
                    dispatch(getOperationPlanOrders())
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新计划工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const initOperationPlanDocsList = createAction(
    '@@xcloud/operationPlanDocs/initOperationPlanDocsList',
    (routeType) => (dispatch, getState, httpClient) => {
        let search = getState().operationPlanDocs.search;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dispatch(createloading());
        dispatch(setOperationPlanDocsList({
            total: 0,
            list: []
        }))
        Promise.all([dispatch(getCompanyById(companyId))]).then(() => {
            Promise.all([
                dispatch(resetSearch(routeType)),
                dispatch(getCompanyTree()),
                dispatch(getDeviceDoctype()),
                dispatch(getOperationPlanDocsList({ offset: 0, size: search.size ? search.size : 10 })),
            ]).then((res) => {
                dispatch(cleanloading());
            })
        })

    }
);


export const initOperationPlanOrders = createAction(
    '@@xcloud/operationPlanDocs/initOperationPlanOrders',
    (routeType) => (dispatch, getState, httpClient) => {
        let search = getState().operationPlanDocs.search;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dispatch(createloading());
        dispatch(setOperationPlanOrders({
            total: 0,
            list: []
        }))
        Promise.all([dispatch(getCompanyById(companyId))]).then(() => {
            Promise.all([
                dispatch(resetSearch(routeType)),
                dispatch(getCompanyTree()),
                dispatch(getDicOrderType()),
                dispatch(getDeviceDoctype()),
                dispatch(getOperationPlanOrders({ offset: 0, size: search.size ? search.size : 10 })),
            ]).then((res) => {
                dispatch(cleanloading());
            })
        })

    }
);


export const getOperationPlanDocsList = createAction(
    '@@xcloud/operationPlanDocs/getOperationPlanDocsList',
    (query) => (dispatch, getState, httpClient) => {
        let searchCond = getState().operationPlanDocs.search;
        let currentUserInfo = getState().auth.currentUserInfo;
        let url = "/operationplans/docs"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        } else if (searchCond && _.isInteger(searchCond.companyId)) {
            url += "&companyId=" + searchCond.companyId
        }
        if (query && _.isInteger(query.dptId)) {
            url += "&dptId=" + query.dptId
        } else if (searchCond && _.isInteger(searchCond.dptId)) {
            url += "&dptId=" + searchCond.dptId
        }
        if (query && query.supplierId) {
            url += "&supplierId=" + query.supplierId
        } else if (searchCond && searchCond.supplierId) {
            url += "&supplierId=" + searchCond.supplierId
        }
        if (query && query.optNo) {
            url += "&optNo=" + query.optNo
        } else if (searchCond && searchCond.optNo && searchCond.optNo !== "") {
            url += "&optNo=" + searchCond.optNo
        }
        if (query && query.orderNo) {
            url += "&orderNo=" + query.orderNo
        } else if (searchCond && searchCond.orderNo && searchCond.orderNo !== "") {
            url += "&orderNo=" + searchCond.orderNo
        }
        if (query && query.deviceNo) {
            url += "&deviceNo=" + query.deviceNo
        } else if (searchCond && searchCond.deviceNo && searchCond.deviceNo !== "") {
            url += "&deviceNo=" + searchCond.deviceNo
        }
        if (query && query.accNo) {
            url += "&accNo=" + query.accNo
        } else if (searchCond && searchCond.accNo && searchCond.accNo !== "") {
            url += "&accNo=" + searchCond.accNo
        }
        if (query && query.type) {
            url += "&type=" + query.type
        } else if (searchCond && searchCond.type && searchCond.type !== "") {
            url += "&type=" + searchCond.type
        }
        if (query && query.onlyMe && currentUserInfo) {
            url += "&userId=" + currentUserInfo.userId
        } else if (searchCond && searchCond.onlyMe) {
            url += "&userId=" + currentUserInfo.userId
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (searchCond.size ? searchCond.size : 10)
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setOperationPlanDocsList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getOperationPlanDocs = createAction(
    '@@xcloud/operationPlanDocs/getOperationPlanDocs',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/operationplans/docs"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        }
        if (query && _.isInteger(query.dptId)) {
            url += "&dptId=" + query.dptId
        }
        if (query && query.supplierId) {
            url += "&supplierId=" + query.supplierId
        }
        if (query && _.isInteger(query.dtlId)) {
            url += "&dtlId=" + query.dtlId
        }
        if (query && _.isInteger(query.orderId)) {
            url += "&orderId=" + query.orderId
        }
        if (query && query.optNo) {
            url += "&optNo=" + query.optNo
        }
        if (query && query.orderNo) {
            url += "&orderNo=" + query.orderNo
        }
        if (query && query.deviceNo) {
            url += "&deviceNo=" + query.deviceNo
        }
        if (query && query.accNo) {
            url += "&accNo=" + query.accNo
        }
        if (query && query.type) {
            url += "&type=" + query.type
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=0"
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setOperationPlanDocsList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const setOperationPlanDocsList = createAction('@@xcloud/operationPlanDocs/setOperationPlanDocsList');

export const setSearch = createAction('@@xcloud/operationPlanDocs/setSearch');

export const resetSearch = createAction('@@xcloud/operationPlanDocs/resetSearch',
    (routeType) => (dispatch, getState, httpClient) => {
        let currentCompany = getState().company.currentCompany;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let company = {};
        let supplierId = null;
        if (currentCompany.companyType === 'SUPPLIER') {
            supplierId = currentCompany.supplier && currentCompany.supplier.length > 0 ? _.map(currentCompany.supplier, 'supplierId').join(',') : null;
        } else {
            let companyList = getState().constant.companies;
            company = _.find(companyList, { companyId: companyId })
        }

        // let departmentList = getState().constant.departments;
        // let dptId = getDepartmentsByRole(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)[0].dptId;
        // let dptInfo = _.find(departmentList, { dptId: dptId });
        // let dptSearch = null;
        // if (dptInfo && dptInfo.opentext === '外部承包商') {
        //     dptSearch = dptId;
        // }

        let search = getState().operationPlanDocs.search;

        let newSearch = {
            ...search,
            companyId: company && company.pid ? companyId : '',
            dptId: '',
            optNo: '',
            orderNo: '',
            deviceNo: '',
            accNo: '',
            type: routeType && routeType !== '' ? routeType : '',
            supplierId: supplierId ? supplierId : '',
            onlyMe: false
        }

        dispatch(setSearch(newSearch));
    });

export const setOperationPlanOrders = createAction('@@xcloud/operationPlanDocs/setOperationPlanOrders');

export const setAppendixParseResult = createAction('@@xcloud/operationPlanDocs/setAppendixParseResult');

export const getOperationPlanOrders = createAction(
    '@@xcloud/operationPlanDocs/getOperationPlanOrders',
    (query) => (dispatch, getState, httpClient) => {
        let searchCond = getState().operationPlanDocs.search;
        let currentUserInfo = getState().auth.currentUserInfo;
        let url = "/operationplans/orders"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=" + (searchCond.page * searchCond.size - searchCond.size)
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        } else if (searchCond && _.isInteger(searchCond.companyId)) {
            url += "&companyId=" + searchCond.companyId
        }
        if (query && _.isInteger(query.dptId)) {
            url += "&dptId=" + query.dptId
        } else if (searchCond && _.isInteger(searchCond.dptId)) {
            url += "&dptId=" + searchCond.dptId
        }
        if (query && query.supplierId) {
            url += "&supplierId=" + query.supplierId
        } else if (searchCond && searchCond.supplierId) {
            url += "&supplierId=" + searchCond.supplierId
        }
        if (query && query.optNo) {
            url += "&optNo=" + query.optNo
        } else if (searchCond && searchCond.optNo && searchCond.optNo !== "") {
            url += "&optNo=" + searchCond.optNo
        }
        if (query && query.orderNo) {
            url += "&orderNo=" + query.orderNo
        } else if (searchCond && searchCond.orderNo && searchCond.orderNo !== "") {
            url += "&orderNo=" + searchCond.orderNo
        }
        if (query && query.deviceNo) {
            url += "&deviceNo=" + query.deviceNo
        } else if (searchCond && searchCond.deviceNo && searchCond.deviceNo !== "") {
            url += "&deviceNo=" + searchCond.deviceNo
        }
        if (query && query.accNo) {
            url += "&accNo=" + query.accNo
        } else if (searchCond && searchCond.accNo && searchCond.accNo !== "") {
            url += "&accNo=" + searchCond.accNo
        }
        if (query && query.type) {
            url += "&type=" + query.type
        } else if (searchCond && searchCond.type && searchCond.type !== "") {
            url += "&type=" + searchCond.type
        }
        if (query && query.onlyMe && currentUserInfo) {
            url += "&userId=" + currentUserInfo.userId
        } else if (searchCond && searchCond.onlyMe) {
            url += "&userId=" + currentUserInfo.userId
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + searchCond.size
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setOperationPlanOrders(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getAppendixParseResult = createAction(
    '@@xcloud/operationPlanDocs/getAppendixParseResult',
    (query) => (dispatch, getState, httpClient) => { 
        let url = "/appendix"
     
        if (query && query.fileName) {
            url += "?fileName=" + query.fileName
        } 
        if (query && query.keys) {
            url += "&keys=" + query.keys
        } 

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                console.log("setAppendixParseResult", res);
                dispatch(setAppendixParseResult(res.data))
                return res;
            })
            .catch(err => {
                debugger;
                let msg = `智能解析超时`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return "";
            })
    }
);

export const operationPlanDocsReducer = handleActions(
    {
        [setOperationPlanDocsList]: (state, { payload: value }) => {
            return {
                ...state,
                operationPlanDocsList: value
            };
        },
        [setOperationPlanOrders]: (state, { payload: value }) => {
            return {
                ...state,
                operationPlanOrders: value
            };
        },
        [setAppendixParseResult]: (state, { payload: value }) => {
            return {
                ...state,
                appendixResult: value
            };
        },

        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
    },

    initialState
);

export default operationPlanDocsReducer