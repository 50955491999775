import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    rule: {
        immeEffect: 'false',
        opentext: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openRuleValidPanel = createAction('@@xcloud/onsiteInspectionRuleValid/open',
    (
        confirmCallback, closeCallback, rule
    ) => ({
        confirmCallback, closeCallback, rule
    })
);

export const closeRuleValidPanel = createAction('@@xcloud/onsiteInspectionRuleValid/close');

export const changeRuleStatus = createAction('@@xcloud/onsiteInspectionRuleValid/changeRuleStatus');

// Actions

export const InspectionTreatmentSchemeAcceptReducer = handleActions(
    {
        [openRuleValidPanel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                rule: data.rule ?
                    {
                        immeEffect: data.rule.immeEffect,
                        opentext: data.rule.opentext,
                    }
                    : {
                        immeEffect: 'false',
                        opentext: ''
                    },
            };
        },
        [closeRuleValidPanel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeRuleStatus]: (state, { payload: data }) => {
            return {
                ...state,
                rule: data,
            };
        }
    },
    initialState
);

export default InspectionTreatmentSchemeAcceptReducer;
