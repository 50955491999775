import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Typography,
  MenuItem,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import * as _ from 'lodash';


const MajorHazardDetectionHeader = props => {
  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    search,
    onhandleSelectChange,
    companyTree,
    companyList,
    dangerLevelList,
  } = props;

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    }

    return false
  }


  return (
    <div>
      <div className={classes.planitemroot}>
        <Grid container alignItems="center">
          <Grid container className={classes.title}>
            <Typography variant="h3" className={classes.plantname}>
              {'重大危险源监测'}
            </Typography>
          </Grid>
          <Grid item className={classes.flexgrow}></Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.gridContainer}>
          <Grid container item xs={12}>
            <Grid item xs={1}>
              <Typography className={classes.label}>
                单位
                  </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{ width: '90%' }}
                id="filled-select-company"
                select
                value={search.companyId ? search.companyId : ''}
                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                variant="standard"
                disabled={companyDisabled()}
              >
                <MenuItem key={1} value={''}>-</MenuItem>
                {_.sortBy(companyList, 'companyId').map((option) => (
                  <MenuItem key={option.companyId} value={option.companyId}>
                    {option.companyAlias}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1}>
              <Typography className={classes.label}>
                危险级别
                  </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{ width: '90%' }}
                id="filled-select-company"
                select
                value={search.dangerLevel ? search.dangerLevel : ''}
                onChange={(event) => onhandleSelectChange(event, 'dangerLevel')}
                variant="standard"
              >
                <MenuItem key={1} value={''}>-</MenuItem>
                {_.sortBy(dangerLevelList, 'seq').map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >
              搜索
                </Button>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleClearSearch}

            >
              重置
                </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

MajorHazardDetectionHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
};

export default withStyles(MajorHazardDetectionHeader);
