import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    useBatchSetting: 'true',
    exceptionBatch: {
        handleType: '',
        handleResult: '',
        acceptResult: '',
        status: ''
    },
    exceptionList: [],
    type: '',
    confirmCallback: null,
    closeCallback: null
};

export const openExceptionBatchAcceptPanel = createAction('@@xcloud/onsiteInspectionExceptionBatchAccept/open',
    (
        confirmCallback, closeCallback, exceptionList, type
    ) => ({
        confirmCallback, closeCallback, exceptionList, type
    })
);

export const closeExceptionBatchAcceptPanel = createAction('@@xcloud/onsiteInspectionExceptionBatchAccept/close');

export const changeExceptionBatch = createAction('@@xcloud/onsiteInspectionExceptionBatchAccept/changeExceptionBatch');
export const changeExceptions = createAction('@@xcloud/onsiteInspectionExceptionBatchAccept/changeExceptions');
export const changeUseBatchSetting = createAction('@@xcloud/onsiteInspectionExceptionBatchAccept/changeUseBatchSetting');

// Actions

export const onsiteInspectionExceptionBatchAcceptReducer = handleActions(
    {
        [openExceptionBatchAcceptPanel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                useBatchSetting: "true",
                exceptionBatch: {
                    handleType: '',
                    handleResult: '',
                    acceptResult: '',
                    status: ''
                },
                exceptionList: data.exceptionList ? data.exceptionList : [],
                type: data.type
            };
        },
        [closeExceptionBatchAcceptPanel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeExceptionBatch]: (state, { payload: data }) => {
            return {
                ...state,
                exceptionBatch: data
            };
        },
        [changeExceptions]: (state, { payload: data }) => {
            return {
                ...state,
                exceptionList: data
            };
        },
        [changeUseBatchSetting]: (state, { payload: data }) => {
            return {
                ...state,
                useBatchSetting: data
            };
        },

    },
    initialState
);

export default onsiteInspectionExceptionBatchAcceptReducer;
