import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  // modal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   overflow: 'scroll'
  // },
  paper: {
    // position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '4px',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
  // smallModal: {
  //   width: '40%',
  //   minHeight: '50%',
  //   height: 'auto'
  // },
  // mediumModal: {
  //   width: '60%',
  //   minHeight: '50%',
  //   height: 'auto'
  // },
  // bigModal: {
  //   width: '80%',
  //   height: '100%',
  //   overflow: 'auto'
  // }
});

export default withStyles(styles);
