import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    FormHelperText,
    Typography,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    Chip,
    Checkbox,
    Switch,
    Radio,
    RadioGroup,
    FormControlLabel,
    ListItemText,
    // Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getMoment as moment, getDateByString } from '@/utils/datetime';
import { inventoryValid } from '@/utils/inspection';
import _ from 'lodash';

const AddTempDetailPannel = ({
    classes,
    open,
    intervalTime,
    currentUserInfo,
    userProfile,
    frequencyDate,
    checklistRole,
    users,
    leaders,
    listTypeList,
    categoryList,
    inventoryList,
    majorHazardlist,
    // roleList,
    getDptChecklistCount,
    getDptUserHelperText,
    checkCompanyRelation,
    planDetailError,
    planDateError,
    planDetail,
    companyList,
    departmentList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleInspectionHeadChange,
    onhandleDateChange,
    onhandleDptUserSelectChange,
    onhandleListMapping,
    getChecklistDpts
}) => {

    const hasCatCode = Boolean(_.find(categoryList, { 'pcode': planDetail.inspectType }));

    // 重大危险源
    const isINS05 = Boolean(planDetail.inspectType && planDetail.inspectType === 'INS05');

    // 分厂人员
    const isBranchComp = Boolean(_.find(companyList, o => o.companyId === userProfile.roles[0].companyId).pid);

    // 用户所属部门
    // const userDptId = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0].dptId : null;

    const minStartDate = moment()().startOf('year');

    const minEndDate = planDetail.startTime ? moment()(planDetail.startTime).endOf('day') : minStartDate.endOf('day');

    const filterDptList = () => {
        let filtered = [];
        if (departmentList) filtered = departmentList;
        return filtered;
    }

    const getDisabledRole = (dpt, option) => {

        const { dptId, companyPid } = dpt;
        const optionRoleId = option.roleId;

        if (!dptId) return false;

        if (!companyPid || companyPid === '' || !checklistRole) {
            return false;
        }

        if (!planDetail.inventoryId) return false;

        // if (!checklistRole[planDetail.inspectType]) return false;
        if (!checklistRole[planDetail.inventoryId]) return false;

        // 存在“不限部门”的选项，pass
        // if (checklistRole[planDetail.inspectType]['0']) return false;
        if (checklistRole[planDetail.inventoryId]['roles']['0']) return false;

        // const roles = checklistRole[planDetail.inspectType][dptId] || [];
        const roles = checklistRole[planDetail.inventoryId]['roles'][dptId] || [];

        // 存在不限角色的选项，pass
        if (roles.includes(0)) return false;

        // roleList中不包含选项的角色，block
        if (!roles.includes(optionRoleId)) return true;

        return false;

    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick maxWidth={'lg'}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"新建风险研判计划"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='风险研判类型'
                                        error={planDetailError.inspectType !== ''}
                                        helperText={planDetailError.inspectType}
                                        select
                                        value={planDetail.inspectType || ''}
                                        name='inspectType'
                                        onChange={onhandleChange}
                                    >
                                        {/* <MenuItem key={1} value={0}></MenuItem> */}
                                        {listTypeList.map((cat) => (
                                            <MenuItem dense key={cat.code} value={cat.code}>
                                                {cat.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {hasCatCode &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label='子分类'
                                            error={planDetailError.inspectTypeCat !== ''}
                                            helperText={planDetailError.inspectTypeCat}
                                            select
                                            value={planDetail.inspectTypeCat || ''}
                                            name='inspectTypeCat'
                                            onChange={onhandleChange}>
                                            {categoryList.filter(item => (item.pcode === planDetail.inspectType)).map((cat) => (
                                                <MenuItem dense key={cat.code} value={cat.code}>
                                                    {cat.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='风险研判检查表'
                                        error={planDetailError.inventoryId !== ''}
                                        helperText={planDetailError.inventoryId}
                                        select
                                        value={planDetail.inventoryId || ''}
                                        name='inventoryId'
                                        onChange={onhandleChange}>
                                        {
                                            inventoryList.map((ivt) => (
                                                <MenuItem dense key={ivt.inventoryId} value={ivt.inventoryId} disabled={!inventoryValid(ivt)}>
                                                    {ivt.inventoryName + (!inventoryValid(ivt) ? '(未生效)' : '')}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                {isINS05 &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label='重大危险源'
                                            error={planDetailError.targetId !== ''}
                                            helperText={planDetailError.targetId}
                                            select
                                            value={planDetail.targetId || ''}
                                            name='targetId'
                                            onChange={onhandleChange}>
                                            {majorHazardlist.filter(item => (planDetail.companyId ? item.majorHazardDevices[0].companyId === planDetail.companyId : true)).map((mh) => (
                                                <MenuItem dense key={mh.hazardId} value={mh.hazardId}>
                                                    {mh.hazardName + ' - ' + mh.installName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="风险研判单位"
                                        error={planDetailError.inspectionCompanyIdList !== ''}
                                        helperText={planDetailError.inspectionCompanyIdList}
                                        select
                                        disabled={isINS05}
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue: () => (
                                                <div className={classes.chips}>
                                                    {
                                                        planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.map((value) =>
                                                            <Chip size={'small'} key={value} label={onhandleListMapping(value, 'company')} className={classes.chip} />
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                        value={planDetail.inspectionCompanyIdList ? planDetail.inspectionCompanyIdList : []}
                                        name='inspectionCompanyIdList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            companyList
                                                .filter(company => !isBranchComp || currentUserInfo.companyId === company.companyId)
                                                .map(
                                                    company => (
                                                        <MenuItem dense key={company.companyId} value={company.companyId} disabled={isBranchComp && company.companyId !== currentUserInfo.companyId}>
                                                            <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.indexOf(company.companyId) > -1} />
                                                            <ListItemText primary={company.companyName} />
                                                        </MenuItem>)
                                                )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="检查班组"
                                        error={planDetailError.inspectionDptUserList !== ''}
                                        helperText={planDetailError.inspectionDptUserList}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue:
                                                () => (<div className={classes.chips}>
                                                    {
                                                        planDetail.inspectionDptUserList && planDetail.inspectionDptUserList.map(dpt =>
                                                            <Chip size={'small'} key={dpt.dptId} label={onhandleListMapping(dpt.dptId, 'departmentEdit')} className={classes.chip} />
                                                        )
                                                    }
                                                </div>),
                                        }}
                                        value={
                                            filterDptList()
                                                .filter(dpt => (
                                                    planDetail.inspectionDptUserList
                                                        .map(e => e.dptId)
                                                        .includes(dpt.dptId)
                                                )) || []
                                        }
                                        name='inspectionDptUserList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            filterDptList()
                                                .sort((a, b) => b.companyPid ? -1 : 1)
                                                .filter(dpt => _.indexOf(getChecklistDpts(), dpt.dptId.toString()) > -1)
                                                .map(dpt => (
                                                    <MenuItem dense key={dpt.dptId} value={dpt} disabled={getDptChecklistCount(dpt) === 0}>
                                                        <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionDptUserList && _.findIndex(planDetail.inspectionDptUserList, { 'dptId': dpt.dptId }) > -1} />
                                                        <ListItemText className={classes.menuText} primary={dpt.companyDptName} />
                                                        <ListItemText className={classes.menuSubText} primary={
                                                            getDptChecklistCount(dpt) <= 0 ? '无排查项' : getDptChecklistCount(dpt) + '排查项'
                                                        } />
                                                    </MenuItem>)
                                                )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        multiline
                                        label={"排查内容"}
                                        variant='outlined'
                                        className={classes.textField}
                                        value={planDetail.opentext || ''}
                                        rows={6}
                                        name='opentext'
                                        onChange={event => onhandleChange(event, 'opentext')}
                                    // onBlur={event => onhandleCheck(event, 'opentext')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item className={classes.col}>
                            <Grid item className={classes.itemGrid}>
                                <Autocomplete
                                    className={classes.textField}
                                    options={leaders}
                                    onChange={(e, newValue) => onhandleInspectionHeadChange(e, newValue)}
                                    onClose={event => onhandleCheck(event, 'inspectHead')}
                                    getOptionLabel={(option) => (option.lastName + option.firstName || '')}
                                    getOptionSelected={(option, value) => (option.userId === value.userId)}
                                    noOptionsText='无可选人员'
                                    value={_.find(leaders, { 'userId': planDetail.inspectHead }) || null}
                                    renderOption={option => (
                                        <Grid container justify={'space-between'}>
                                            <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                            <Typography variant={'subtitle2'}>{option.roleName || ''}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => <TextField {...params}
                                        label='班长'
                                        placeholder="搜索并选择班长"
                                        variant="outlined"
                                        error={planDetailError.inspectHead !== ''}
                                        helperText={planDetailError.inspectHead}
                                    />}
                                />
                            </Grid>
                            <Grid container className={classes.contentGrid}>
                                {
                                    planDetail.inspectionDptUserList.map(dpt => (
                                        <Grid item key={dpt.dptId} className={classes.itemDptUserGrid}>
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                disableClearable
                                                filterSelectedOptions
                                                className={classes.textField}
                                                openText='展开选项'
                                                noOptionsText='无可选人员'
                                                options={users.filter(user => user.dptId === dpt.dptId)}
                                                getOptionDisabled={option => getDisabledRole(dpt, option)}
                                                onChange={(e, newValue, reason) => onhandleDptUserSelectChange(newValue, dpt.dptId, reason)}
                                                onClose={event => onhandleCheck(event, 'dptUser')}
                                                getOptionLabel={option => (option.lastName + option.firstName || '')}
                                                getOptionSelected={(option, value) => (option.userId === value.userId)}
                                                value={dpt.dptUser || []}
                                                renderOption={option => (
                                                    <Grid container justify={'space-between'}>
                                                        <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                                        <Typography variant={'subtitle2'}>{option.roleName || ''}</Typography>
                                                    </Grid>
                                                )}
                                                renderTags={(value, getTagProps) => (
                                                    value.map((option, index) => (
                                                        <Chip size={'small'}
                                                            label={onhandleListMapping(option.userId, 'user')}
                                                            {...getTagProps({ index })} />
                                                    ))
                                                )}
                                                renderInput={params =>
                                                    <TextField {...params} size={'small'} variant="outlined" placeholder={getDptUserHelperText(dpt) ? getDptUserHelperText(dpt) : "选择检查人"}
                                                        label={`检查人 - ${dpt.companyDptName}`}
                                                        // 2022.2.25 from 综管部长：初创建计划，不需要选检查人
                                                        // error={(planDetailError.dptUser !== '' && dpt.dptUser && dpt.dptUser.length < 1) || getDptUserHelperText(dpt) !== ''} 
                                                        helperText={getDptUserHelperText(dpt)}
                                                    />}
                                            />
                                        </Grid>
                                    ))
                                }
                                {planDetailError.dptUser !== '' ?
                                    <Grid item className={classes.itemDptUserGrid}>
                                        <FormHelperText error={true} >
                                            {planDetailError.dptUser}
                                        </FormHelperText>
                                    </Grid> : null
                                }
                            </Grid>
                        </Grid>
                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemLabelGrid}>
                                    <FormControlLabel label={<Typography variant="h5" style={planDetail.frequencyDate ? { fontWeight: 600 } : { color: 'gray' }}>按频次创建排查</Typography>} control={
                                        <Switch size="small" checked={planDetail.frequencyDate} onChange={onhandleChange} name="frequencyDate" />
                                    } />
                                </Grid>

                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <RadioGroup row name="frequencyType"
                                        value={planDetail.frequencyType}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'day'} control={<Radio size={'small'} />} label="每日" />
                                        <FormControlLabel value={'workday'} control={<Radio size={'small'} />} label="每工作日" />
                                    </RadioGroup>
                                    <RadioGroup row name="frequencyType"
                                        value={planDetail.frequencyType}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'week'} control={<Radio size={'small'} />} label="每周" />
                                        <FormControlLabel value={'fortnight'} control={<Radio size={'small'} />} label="每2周" />
                                        <FormControlLabel value={'month'} control={<Radio size={'small'} />} label="每月" />
                                    </RadioGroup>
                                </Grid>}

                                {!frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <RadioGroup row aria-label="排查时间类型（单日/区间）" name="intervalTime"
                                        value={planDetail.intervalTime}
                                        onChange={onhandleChange} >
                                        <FormControlLabel value={'false'} control={<Radio size={'small'} />} label="单日排查" />
                                        <FormControlLabel value={'true'} control={<Radio size={'small'} />} label="多日排查" />
                                    </RadioGroup>
                                </Grid>}

                                <Grid item container className={classes.itemDateGrid} wrap={'nowrap'}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={intervalTime ? null : classes.textField}
                                            error={planDateError.startTime !== ''}
                                            helperText={planDateError.startTime}
                                            InputProps={{ readOnly: true }}
                                            views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                            openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                            disableToolbar={planDetail.frequencyType !== 'month'}
                                            disablePast
                                            autoOk
                                            variant='inline'
                                            inputVariant='outlined'
                                            minDate={minStartDate}
                                            minDateMessage={<Typography>不能早于当前日期</Typography>}
                                            format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                            name='startTime'
                                            label={intervalTime ? "开始日期" : "检查日期"}
                                            value={planDetail.startTime || null}
                                            onChange={date => onhandleDateChange(date, 'startTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {intervalTime &&
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            locale={zhCN}>
                                            <KeyboardDatePicker
                                                className={classes.textFieldInline}
                                                error={planDateError.inspectTime !== ''}
                                                helperText={planDateError.inspectTime}
                                                InputProps={{ readOnly: true }}
                                                views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                                openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                                disableToolbar={planDetail.frequencyType !== 'month'}
                                                disablePast
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                minDate={minEndDate}
                                                minDateMessage={<Typography>不能早于开始日期</Typography>}
                                                format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                                name='inspectTime'
                                                label={"结束日期"}
                                                value={planDetail.inspectTime || null}
                                                onChange={date => onhandleDateChange(date, 'inspectTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': '修改时间',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    }
                                </Grid>
                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    {Boolean(planDateError.inspectTime === '' && planDetail.planDates && planDetail.planDates.length > 0) &&
                                        <>
                                            <Typography display={'block'}>{'将创建以下【' + planDetail.planDates.length + '】种时间段的风险研判计划：'}</Typography>
                                            {
                                                planDetail.planDates.map((planDate, index) => {
                                                    return (
                                                        <Typography display={'block'} key={index}>
                                                            <span>{
                                                                '【' + (index + 1) + '】'
                                                                + getDateByString(planDate.startTime, true)
                                                                + (
                                                                    moment()(planDate.startTime).isSame(planDate.inspectTime, 'day') ? ''
                                                                        : ' ~ ' + getDateByString(planDate.inspectTime, true)
                                                                )
                                                            }</span>
                                                            {/* tip未调试完成 */}
                                                            {/* {
                                                                planDate.hasTip ?
                                                                    <Tooltip title="该时间段不满一个周期，请谨慎创建">< ErrorOutlineIcon color={'error'} fontSize={'small'} /></Tooltip>
                                                                    : null
                                                            } */}
                                                        </Typography>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </Grid>}
                                {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                    <Typography display={'block'}>
                                        <span className={classes.required}> *</span>
                                        {' 起止时间段如果不足每周/每2周/每月，也会作为排查时间段加入，请谨慎选择。'}
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        新增</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
AddTempDetailPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    planDetail: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    leaders: PropTypes.array.isRequired,
    listTypeList: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

AddTempDetailPannel.defaultProps = {

}

export default withStyles(AddTempDetailPannel);