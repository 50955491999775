import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import { getTreePartByIdUseKey, findParent, treeToArray } from '../../utils/constant';
import { cleanmodal } from './modal';
import { createMessage } from './message'
import { v4 as uuidv4 } from 'uuid';
import { getRole } from '@/utils/userUtils';

const initialState = {
    processesline: [],
    processesLineChanged: false,
    processNotices: []
};
export const initProcessLineWithUser = createAction("@@xcloud/processlinewithuser/initProcessLineWithUser",
    (data, notices) => (dispatch, getState) => {
        dispatch(setProcessesline([]));
        dispatch(setProcessesNotices([]));
        if (data && data.length > 0) {
            dispatch(setProcessesline(data));
        } else {
            let user = getState().auth.currentUserInfo;
            let userProfile = getState().auth.userProfile;
            let role = getRole(user.roleId, userProfile);
            let newData = [
                {
                    id: uuidv4(),
                    userId: user.userId,
                    roleId: user.roleId,
                    preId: null,
                    roleName: role ? role.roleName : '',
                    firstName: user.firstName,
                    lastName: user.lastName,
                }
            ]
            dispatch(setProcessesline(newData));
        }
        if (notices && notices.length > 0) {
            dispatch(setProcessesNotices(notices));
        }
    }
);

export const addNotice = createAction("@@xcloud/processlinewithuser/addNotice",
    (userData, callback) => (dispatch, getState) => {
        console.log(userData)
        let data = _.clone(getState().processlinewithuser.processNotices);
        let processdata = _.clone(getState().processlinewithuser.processesline);
        let datalist = treeToArray(_.cloneDeep(processdata));
        if (_.find(datalist, { userId: userData.userId })) {
            dispatch(createMessage('error', '用户已经在流程中，请确认'))
        } else if (_.find(data, { userId: userData.userId })) {
            dispatch(createMessage('error', '用户已经在抄送列表中，请确认'))
        } else {

            let newData = {}
            newData.userId = userData.userId;
            newData.roleId = userData.roleId;
            newData.firstName = userData.firstName;
            newData.lastName = userData.lastName;
            data.push(newData);
            dispatch(setProcessesNotices(data))
            if (callback) callback();
        }
    }
);

export const addStep = createAction("@@xcloud/processlinewithuser/addStep",
    (id, callback) => (dispatch, getState) => {
        let data = _.clone(getState().processlinewithuser.processesline);
        let subData = getTreePartByIdUseKey(id, data, 'id');
        if (subData && subData.length > 0) {
            let subDataTree = subData[0]
            if (subDataTree.children && subDataTree.children.length > 0) {
                subDataTree.children.push({
                    id: uuidv4(),
                    userId: null,
                    roleId: null,
                    preId: id,
                    firstName: null,
                    lastName: null,
                })
            } else {
                subDataTree.children = [{
                    id: uuidv4(),
                    userId: null,
                    roleId: null,
                    preId: id,
                    firstName: null,
                    lastName: null,
                }]
            }
            dispatch(setProcessesline(data))
            if (callback) callback();
        }
    }
);

export const removeStep = createAction("@@xcloud/processlinewithuser/removeStep",
    (id, pid, callback) => (dispatch, getState) => {
        let data = _.clone(getState().processlinewithuser.processesline);
        let fatherData = findParent(pid, 'id', data);
        if (fatherData) {
            let subDataArray = fatherData.children;
            fatherData.children = _.reject(subDataArray, { id: id })
            dispatch(setProcessesline(data))
            if (callback) callback();
        }
    }
);
export const removeNotice = createAction("@@xcloud/processlinewithuser/removeNotice",
    (userId, callback) => (dispatch, getState) => {
        let data = _.clone(getState().processlinewithuser.processNotices);
        data = _.reject(data, { userId: userId })
        dispatch(setProcessesNotices(data))
        if (callback) callback();
    }
);
export const changeUser = createAction("@@xcloud/processlinewithuser/changeUser",
    (id, userData, callback) => (dispatch, getState) => {
        let data = _.clone(getState().processlinewithuser.processesline);
        let datalist = treeToArray(_.cloneDeep(data));
        let processNotices = _.clone(getState().processlinewithuser.processNotices);
        if (_.find(datalist, { userId: userData.userId, roleId: userData.roleId })) {
            dispatch(createMessage('error', '用户已经在流程中，请确认'))
        } else if (_.find(processNotices, { userId: userData.userId, roleId: userData.roleId })) {
            dispatch(createMessage('error', '用户已经在抄送列表中，请确认'))
        } else {
            let subData = getTreePartByIdUseKey(id, data, 'id');
            if (subData && subData.length > 0) {
                let subDataTree = subData[0]
                subDataTree.userId = userData.userId;
                subDataTree.roleId = userData.roleId;
                subDataTree.roleName = userData.roleName;
                subDataTree.firstName = userData.firstName;
                subDataTree.lastName = userData.lastName;
                dispatch(setProcessesline(data))
                if (callback) callback();
            }
        }
    }
);

export const exitPage = createAction('@@xcloud/processlinewithuser/exitPage',
    () => (dispatch, getState, httpClient) => {
        dispatch(setProcessesline([]))
        dispatch(cleanmodal())
    }
);

export const setProcessesline = createAction("@@xcloud/processlinewithuser/setProcessesline")
export const setProcessesNotices = createAction("@@xcloud/processlinewithuser/setProcessesNotices")
export const ProcesslinewithuserReducer = handleActions(
    {
        [setProcessesline]: (state, { payload: data }) => {
            return {
                ...state,
                processesline: data
            }
        },
        [setProcessesNotices]: (state, { payload: data }) => {
            return {
                ...state,
                processNotices: data
            }
        },
    },
    initialState
)

export default ProcesslinewithuserReducer