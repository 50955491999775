import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    dialogContent: {

    },
    textfield: {
        minWidth: 500
    },
    contentGrid: {
        marginTop: 10,
        flexDirection: 'column'
    },
    uploadErr: {
        boxShadow: '0 0 2px 0.1rem ' + theme.palette.error.main
    },
    uploadErrText: {
        color: theme.palette.error.main
    },


});

export default withStyles(styles);