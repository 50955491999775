import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api';
const basePath = porxyApi + '/amoeba/variablefeerules';
const initialState = {
    varFeeRules: [],//变动费规则列表
};

export const setVarFeeRules = createAction('@@xcloud/amoeba/setVarFeeRules');

export const addVarFeeRule = createAction('@@xcloud/amoeba/addVarFeeRule',
    (ruleDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(basePath, ruleDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加变动费规则成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加变动费规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addVarFeeRules = createAction('@@xcloud/amoeba/addVarFeeRules',
    (ruleDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(basePath + '/batch', ruleDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "规则生成成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `规则生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateVarFeeRules = createAction('@@xcloud/amoeba/updateVarFeeRule',
    (updateDto, callBack) => (dispatch, getState, httpClient) => {
        return httpClient.patch(basePath + '/' + updateDto.ruleId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新变动费规则成功"));
                    if (callBack)
                        callBack();
                }
                return res.status;
            }).catch(err => {
                let msg = `更新变动费规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateServiceBudget = createAction('@@xcloud/manufacture/updateServiceBudget',
    (updateDto, callBack) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/serviceBudgets/' + updateDto.budgetId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新服务预算成功"));
                    if (callBack)
                        callBack();
                }
                return res.status;
            }).catch(err => {
                let msg = `更新服务预算失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delVarFeeRule = createAction('@@xcloud/amoeba/delVarFeeRule',
    (ruleId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(basePath + '/' + ruleId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除变动费规则成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除变动费规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getVariableFeeRules = createAction('@@xcloud/amoeba/getVariableFeeRules',
    (ruleId, companyId, incomeSub, year) =>
        (dispatch, getState, httpClient) => {
            let url = basePath;
            let strs = []
            if (ruleId || companyId || incomeSub || year) {
                if (ruleId) strs.push('ruleId=' + ruleId)
                if (companyId) strs.push('companyId=' + companyId)
                if (incomeSub) strs.push('incomeSub=' + incomeSub)
                if (year) strs.push('year=' + year)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setVarFeeRules(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取变动费规则列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const variableFeeRuleReducer = handleActions(
    {
        [setVarFeeRules]: (state, { payload: data }) => {
            return {
                ...state,
                varFeeRules: data
            }
        },
    },
    initialState
)

export default variableFeeRuleReducer
