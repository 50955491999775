import React, { useState } from "react";
import withStyles from './styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getStore } from '../../../../redux/store';
import { openConfirmDialog } from '../../../../redux/modules/confirmDialog';
import {
  getDepartment, hideDepartment, deleteCompany
} from '../../../../redux/modules/company';
import { createmodal } from '../../../../redux/modules/modal';
import { createMessage } from '../../../../redux/modules/message';
import { truncate } from '../../../../utils/string';

const OrgNode = (node) => {

  var store = getStore();

  let datasource = node.nodeData;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCreateCompany = () => {
  //   setAnchorEl(null);
  //   store.dispatch(createmodal('companyInfo', { isEdit: false, isCompany: true }, 'big'));
  // }

  const handleCreateShop = () => {
    setAnchorEl(null);
    store.dispatch(createmodal('companyInfo', { isEdit: false, isCompany: false }, 'big'));
  }

  const handleCreateDepartment = () => {
    setAnchorEl(null);
    store.dispatch(createmodal('departmentInfo', { isEdit: false, type: 'add' }, 'big'));
  }

  const handleShowDepartment = () => {
    setAnchorEl(null);
    store.dispatch(getDepartment());
  }

  const handleHideDepartment = () => {
    setAnchorEl(null);
    store.dispatch(hideDepartment());
  }

  const handleEditCompany = () => {
    setAnchorEl(null);
    store.dispatch(createmodal('companyInfo', { isEdit: true, isCompany: true }, 'big'));
  }

  const handleEditShop = () => {
    setAnchorEl(null);
    store.dispatch(createmodal('companyInfo', { isEdit: true, isCompany: false }, 'big'));
  }

  const handleEditDepartment = () => {
    setAnchorEl(null);
    store.dispatch(createmodal('departmentInfo', { isEdit: true, type: 'update' }, 'big'));
  }

  const handleDeleteCompany = () => {
    setAnchorEl(null);
    let doDelete = () => {
      store.dispatch(deleteCompany());
    }
    store.dispatch(openConfirmDialog('删除确认', '确定要删除公司[' + datasource.companyName + ']吗?', doDelete, null, false))
  }

  const handleDeleteShop = () => {
    setAnchorEl(null);
    let doDelete = () => {
      store.dispatch(deleteCompany());
    }
    store.dispatch(openConfirmDialog('删除确认', '确定要删除车间[' + datasource.companyName + ']吗?', doDelete, null, false))
  }

  const handleDeleteDepartment = () => {
    setAnchorEl(null);
    if (datasource.children !== undefined && datasource.children.length > 0) {
      store.dispatch(createMessage('error', '请先删除下属部门'));
      return false;
    } else {
      store.dispatch(createmodal('departmentInfo', { isEdit: true, type: 'delete' }, 'big'));
    }
  }

  return (<div>{
    datasource.companyName ? (
      <div id={'company_' + datasource.companyId}>
        <div className="oc-heading">
          {datasource.pid ? (datasource.indivisible ? '车间' : '子公司') : '总公司'}
        </div>
        <div className="oc-content" title={datasource.companyName}>
          {truncate(datasource.companyName, 10)}
        </div>
        <div className="menu-icon">
          <IconButton
            aria-label="more"
            aria-controls="company-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="company-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {/* {datasource.indivisible ? null : (<MenuItem onClick={handleCreateCompany}>新建子公司</MenuItem>)} */}
            {datasource.indivisible ? null : (<MenuItem onClick={handleCreateShop}>新建车间</MenuItem>)}
            {datasource.showDepartment ? (<MenuItem onClick={handleCreateDepartment}>新建部门/班组</MenuItem>)
              : (<MenuItem onClick={handleShowDepartment}>显示部门</MenuItem>)}
            {datasource.showDepartment ? (<MenuItem onClick={handleHideDepartment}>隐藏部门</MenuItem>)
              : null}
            {datasource.indivisible ? (<MenuItem onClick={handleEditShop}>编辑车间</MenuItem>)
              : (<MenuItem onClick={handleEditCompany}>编辑公司</MenuItem>)}
            {datasource.pid && datasource.indivisible ? (<MenuItem onClick={handleDeleteShop}>删除车间</MenuItem>)
              : datasource.pid ? (<MenuItem onClick={handleDeleteCompany}>删除公司</MenuItem>) : null}
          </Menu>
        </div>
      </div>
    ) : (datasource.dptName ? (
      <div id={'department_' + datasource.dptId}>
        <div className="oc-heading oc-heading-dept">
          {datasource.executable ? '班组' : '部门'}
        </div>
        <div className="oc-content oc-content-dept" title={datasource.dptName}>
          {truncate(datasource.dptName, 6)}
        </div>
        <div className="menu-icon-dept">
          <IconButton
            aria-label="more"
            aria-controls="dpt-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="dpt-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleCreateDepartment}>新建子部门</MenuItem>
            <MenuItem onClick={handleEditDepartment}>编辑</MenuItem>
            <MenuItem onClick={handleDeleteDepartment}>删除</MenuItem>
          </Menu>
        </div>
      </div>
    ) : null)
  }</div>);
}

export default withRouter(withStyles(OrgNode));