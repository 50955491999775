import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    DialogActions,
    Button,
    Dialog,
    DialogContent,
    Typography,
} from '@material-ui/core';
import { localization, options, style } from "../../../../utils/mtable";
import { SAFETY_ACTIVITY_TYPE } from "../../../../utils/constant";
import MaterialTable from 'material-table'
import _ from 'lodash'
import Close from '@material-ui/icons/Close';

const ActivityTableComponent = ({
    classes,
    currentActivityList,
    constant,
    open,
    onhandleClose,
    onhandleAdd,
    onhandleUpdate,
    onhandleOpenActivity,
}) => {

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        //toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    const activityTypeDict = _.chain(SAFETY_ACTIVITY_TYPE).keyBy('value').mapValues('name').value()
    const activityStatusDict = _.chain(constant.activityStatus).keyBy('code').mapValues('name').value()
    // const priorityDict = { 0: '一般', 1: '紧急', 2: '特急' }
    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'activity',
                render: row => <Typography>{row.activity}</Typography>
            }, {
                title: '类型',
                field: 'activityType',
                render: row => <Typography>{activityTypeDict[row.activityType]}</Typography>,
                lookup: activityTypeDict,
                editable: 'never',
                emptyValue: '普通活动'
            }, {
                title: '状态',
                field: 'status',
                render: row => <Typography>{activityStatusDict[row.status]}</Typography>,
                lookup: activityStatusDict
            }
        ]
        return columns
    }

    const assemblyData = () => {
        return currentActivityList
    }

    return (

        <div alignitems="center" className={classes.root}>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onhandleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogContent dividers className={classes.activityDialogContent}>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>个人活动列表</Typography>}
                        editable={
                            {
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleAdd(newData)
                                        resolve()
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleUpdate(newData, oldData)
                                        resolve()
                                    }),
                            }
                        }
                        actions={
                            [
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onhandleOpenActivity(rowData)
                                    }
                                })
                            ]
                        }
                    >
                    </MaterialTable>
                </DialogContent>

                <DialogActions>
                    <Button variant='outlined' onClick={onhandleClose}><Close /> 关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
ActivityTableComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

ActivityTableComponent.defaultProps = {

}


export default withRouter(withStyles(ActivityTableComponent));