import { create } from 'jss';
import compose from 'jss-compose';
import { createGenerateClassName, jssPreset } from '@material-ui/styles';
import React from 'react';
import {JssProvider} from 'react-jss';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, compose()] });

// Custom Material-UI class name generator.
const generateClassName = createGenerateClassName({ productionPrefix: 'XCloud' });

export const JSSComposable = props => (
  <JssProvider Jss={jss} generateClassName={generateClassName}>
    {props.children}
  </JssProvider>
);

export default JSSComposable;
