import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(0)
  },
  label: {
    marginTop: 5
  },
  card: {
    border: 1,
    backgroundColor:"red"
  },
  title: {
    padding: theme.spacing(1)
  },
  gridContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  gridItem: {
    height: 5
  },
  select: {
    width: '90%',
    marginTop: 7
  },
  planitemroot: {
    backgroundColor: '#FFFFFFFF'
  },
  oppositeContent:{
    flex : 0.3
  }
});

export default withStyles(styles);
