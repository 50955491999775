import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InspectionPlan from "@/components/InspectionManagement/AnnualPlan/InspectionPlan";
import {
    addPlans,
    getPlanDetails,
    clearPlanDetails,
    updatePlanDetails,
    changePlanDetailStatus,
    addPlanDetail,
    getCurrentPlan,
    executeProcess
} from "@/redux/modules/inspection/inspectionPlan";
import {
    getCurrentProcess,
    getProcessHistories,
    getCompanyAllProcessStatus,
    getCurrentProcessStatus
} from "@/redux/modules/process";
import { getRecordByUnitId, isUnitBranch, getMasterRecord } from "@/utils/processUtils";
import { getBusinessUnitsByRole, getRole } from "@/utils/userUtils";
import {
    getChecklistCount,
    getListTypeList,
    getCategoryList,
    getInventories
} from "@/redux/modules/inspection/inspection";

import ApprovePannel from "../ApprovePannel";
import InspectionPlanDetailAddPannel from "./InspectionPlanDetailAddPannel"

import { openApprovePannel } from "@/redux/modules/approvePannel";
import { getAllLeaders } from '@/redux/modules/user';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { openPlanDetailAddPannel } from "@/redux/modules/inspection/inspectionPlanDetailAdd";
import { createMessage } from '@/redux/modules/message';

import { getActualCompany, getManageDpt } from '@/utils/constant';
import { getMoment as moment } from '@/utils/datetime';
import * as _ from 'lodash';

const mapCurrentProcess = (processes, currentUserInfo, userProfile) => {

    if (!processes) {
        return {}
    }
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecord(processes, currentUserInfo.companyId)

    if (isUnitBranch(processes)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(processes, bu.unitId)
        }
    }

    let process = {}
    if (record && Object.keys(record.length > 0)) {
        process = _.find(processes, (process) => {
            return _.find(process.records, (rec) => {
                return rec.recordId === record.recordId
            })
        })
    }
    return process
}
const mapCurrentRecord = (processes, currentUserInfo, userProfile) => {

    if (!processes) {
        return {}
    }
    let businessUnits = getBusinessUnitsByRole(currentUserInfo.roleId, userProfile)
    let record = getMasterRecord(processes, currentUserInfo.companyId)

    if (isUnitBranch(processes)) {
        for (let bu of businessUnits) {
            record = getRecordByUnitId(processes, bu.unitId)
        }
    }
    return record || {}
}

class InspectionPlansContainer extends Component {
    static propTypes = {
        plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        userProfile: PropTypes.object,
        onhandleReviewStatus: PropTypes.func,
        onhandleReturnPlans: PropTypes.func,
        onhandleReturnPlan: PropTypes.func,
        onhandleEdit: PropTypes.func,
        onhandleOpenPlanDetails: PropTypes.func,
        onhandleChange: PropTypes.func,
        onhandleProcess: PropTypes.func,
        listTypeList: PropTypes.array,
        categoryList: PropTypes.array,
        departmentList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 'INS01'
        }
    }

    componentDidMount() {
        const {
            onhandleGetChecklistCount,
            onhandleGetLeaderList,
            onhandleGetListTypeList,
            onhandleGetCategoryList,
            onhandleClearPlanDetails,
            onhandleGetInventoryList
        } = this.props;
        onhandleGetChecklistCount();
        onhandleClearPlanDetails();
        onhandleGetLeaderList();
        onhandleGetListTypeList();
        onhandleGetCategoryList();
        this.getPlanAndProcess();
        onhandleGetInventoryList();
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue
        })
    }

    getPlanAndProcess() {
        const {
            onhandleGetCurrentPlan,
            onhandleGetCurrentProcess,
            onhandleGetPlanDetails,
            onhandleGetProcessHistories,
            onhandleGetCompanyAllProcessStatus,
            currentUserInfo,
            userProfile,
            onhandleGetCurrentProcessStatus,
        } = this.props;

        onhandleGetCurrentPlan((plan) => {
            if (!plan || Object.keys(plan).length === 0) {
                return;
            }
            onhandleGetPlanDetails(plan.planId)
            onhandleGetCurrentProcess((processes) => {
                if (!processes || processes.length === 0)
                    return
                let record = mapCurrentRecord(processes, currentUserInfo, userProfile)
                onhandleGetProcessHistories(record.recordId)
                onhandleGetCompanyAllProcessStatus()
                onhandleGetCurrentProcessStatus((data) => {
                    // console.log('Current Process Status')
                    // console.log(data)
                })
            })
        })
    }

    handleAddPlan() {
        const { onhandleAddPlan } = this.props;
        const planYear = moment()().add(1, 'year').year();
        let plan = {
            year: planYear,
            planName: `${planYear}年隐患排查年度计划`,
            planType: 'ANNU'
        }
        onhandleAddPlan(plan, () => {
            this.getPlanAndProcess();
        })
    }

    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleProcess(action) {
        const { onhandleOpenConfirmDialog } = this.props;
        if (action.statusCode === 'Rejected' || action.review) {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else if (action.statusCode === 'Submitted') {
            const callBack = () => {
                this.handleExecuteProcess(action);
            }
            onhandleOpenConfirmDialog('提交确认', '确定提交本部门负责的计划吗？', callBack, null, false);
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleExecuteProcess(action) {
        const { currentUserInfo,
            planDetails,
            processes,
            // businessUnits,
            plan,
            userProfile } = this.props
        // let record = getRecordByCompanyId(processes, currentUserInfo.companyId)
        let record = getMasterRecord(processes);
        let actionParams = []
        if (!action.distributed) {
            /* 
            let planDetailFilter = null
            if (action.statusCode === 'Rejected') {//驳回本级，全业务线
                planDetailFilter = _.filter(planDetails, (detail) => {
                    // return detail.companyId === currentUserInfo.companyId
                    return true
                })
            } else if (record.statusCode === 'Rejected' || record.statusCode === 'Drafting') { // 不是驳回，是本级提交，分业务线(本业务中暂时不考虑分业务线)
                planDetailFilter = _.filter(planDetails, (detail) => {
                    // return detail.companyId === currentUserInfo.companyId
                    return true
                    // && _.find(businessUnits, function (o) {
                    //     return o.unitId === detail.unitId
                    // })
                })
            }
            */
            // 提交时  仅"提交"本用户操作权限内的计划详情
            let filtedPlanDetails = planDetails;
            // console.log('master process record')
            // console.log(record)
            if (record.statusCode === 'Drafting' || record.statusCode === 'Rejected') {
                filtedPlanDetails = this.getDptInspectDetails(planDetails);
                if (_.findIndex(filtedPlanDetails, { 'status': 'rejected' }) > -1) {
                    this.props.onhandleCreateMessage('error', '存在驳回中的计划详情，请修改后再提交');
                    return;
                }
                if (!filtedPlanDetails || !filtedPlanDetails.length || filtedPlanDetails.length <= 0) {
                    this.props.onhandleCreateMessage('error', '计划详情未添加，请添加后再提交');
                    return;
                }
            }

            // 改为每一步流程都更新计划详情的动态
            if (filtedPlanDetails && filtedPlanDetails.length && filtedPlanDetails.length > 0) {
                let targetStatus = action.statusCode.toLowerCase();
                // 最终审批后状态直接设为finalized
                if (targetStatus === 'published') {
                    targetStatus = 'finalized';
                }
                actionParams.push({
                    handle: "updatePlanDetails",
                    planDetails: filtedPlanDetails,
                    status: targetStatus
                })
            }
        }

        let processParams = {
            action: action,
            actionParams: actionParams
        }
        const { onhandleProcess, onhandleGetCurrentProcess, onhandleGetProcessHistories, onhandleGetPlanDetails, onhandleGetCurrentProcessStatus } = this.props

        onhandleProcess(processParams, () => {
            onhandleGetCurrentProcess((processes) => {
                if (!processes || processes.length === 0)
                    return
                let record = mapCurrentRecord(processes, currentUserInfo, userProfile)
                onhandleGetProcessHistories(record.recordId)
            })
            onhandleGetPlanDetails(plan.planId)
            onhandleGetCurrentProcessStatus()
        })
    }

    handleAddPlanDetail(planDetail) {
        const { plan, categoryList, onhandleAddPlanDetail, onhandleGetPlanDetails, departmentList } = this.props;

        if (!planDetail || !planDetail.inspectionCompanyIdList || !planDetail.inspectionCompanyIdList.length) {
            return;
        }
        // let addDetail = {
        //     planId: plan.planId,
        //     creator: currentUserInfo.userId,
        //     tenantId: currentUserInfo.tenantId,
        //     companyId: planDetail.companyId,
        //     inspectHead: planDetail.inspectHead,
        //     startTime: planDetail.startTime,
        //     inspectTime: planDetail.inspectTime,
        //     inspectType: planDetail.inspectType,
        //     inspectionDptStrList: planDetail.inspectionDptStrList
        // }
        // 包装 临时计划 请求对象
        let addDetailList = [];
        for (const companyId of planDetail.inspectionCompanyIdList) {
            const dptStrList = [];
            planDetail.inspectionDptStrList.forEach(dptId => {
                const department = _.find(departmentList, { 'dptId': dptId });
                // 需要为公司分配部门：机关部门全分配，基层部门只分配给对应单位
                if (!department.companyPid) {
                    dptStrList.push(dptId);
                }
                if (Boolean(department.companyPid) && companyId === department.companyId) {
                    dptStrList.push(dptId);
                }
            });
            const addDetail = {
                planId: plan.planId,
                companyId: companyId,
                inventoryId: planDetail.inventoryId,
                inspectHead: planDetail.inspectHead,
                startTime: planDetail.startTime,
                inspectTime: planDetail.inspectTime,
                inspectType: planDetail.inspectType,
                inspectionDptStrList: dptStrList,
                opentext: planDetail.opentext
            }
            if (planDetail.targetId && planDetail.targetId !== '') {
                addDetail['targetId'] = planDetail.targetId;
            }
            if (_.find(categoryList, { 'pcode': planDetail.inspectType }) && planDetail.inspectTypeCat !== '') {
                addDetail.inspectType = planDetail.inspectTypeCat;
            }
            addDetailList.push(addDetail);
        }
        // 按时间频次排查
        if (planDetail.frequencyDate && planDetail.planDates && planDetail.planDates.length && planDetail.planDates.length > 0) {
            let freqDetailList = [];
            planDetail.planDates.forEach(planDate => {
                addDetailList.forEach(detail => {
                    const addDetail = {
                        ...detail,
                        startTime: planDate.startTime,
                        inspectTime: planDate.inspectTime
                    }
                    freqDetailList.push(addDetail);
                });
            });
            addDetailList = freqDetailList;
        }

        onhandleAddPlanDetail(addDetailList, () => {
            onhandleGetPlanDetails(plan.planId)
        })
    }

    handleUpdate(planDetail) {
        const { onhandleUpdate, onhandleGetPlanDetails, plan, currentUserInfo } = this.props
        let updateDetail = {
            dtlId: planDetail.dtlId,
            revisor: currentUserInfo.userId,
            companyId: planDetail.companyId,
            inspectHead: planDetail.inspectHead,
            startTime: planDetail.startTime,
            inspectTime: planDetail.inspectTime,
            inspectType: planDetail.inspectType,
            inventoryId: planDetail.inventoryId,
            inspectionDptList: planDetail.inspectionDptList
        }
        // 重新编辑会使计划详情变回'草稿'状态
        updateDetail.status = 'drafting';
        onhandleUpdate(planDetail.dtlId, updateDetail, () => {
            onhandleGetPlanDetails(plan.planId)
        })
    }

    handleReject = (event, id) => {
        const { onhandleChangeStatus, onhandleGetPlanDetails, plan, onhandleOpenConfirmDialog } = this.props
        let changeStatus = () => {
            onhandleChangeStatus(id, 'rejected', () => {
                onhandleGetPlanDetails(plan.planId)
            });
        }
        onhandleOpenConfirmDialog('驳回确认', '确定要驳回此计划详情吗?', changeStatus, null, false);
    }

    handleDelete = (event, id) => {
        const { onhandleChangeStatus, onhandleGetPlanDetails, plan, onhandleOpenConfirmDialog } = this.props
        let changeStatus = () => {
            onhandleChangeStatus(id, 'inactive', () => {
                onhandleGetPlanDetails(plan.planId)
            });
        }
        onhandleOpenConfirmDialog('删除确认', '确定要删除此计划详情吗?', changeStatus, null, false);
    };

    handleActivate = (event, id) => {
        const { onhandleChangeStatus, onhandleGetPlanDetails, plan, onhandleOpenConfirmDialog } = this.props
        let changeStatus = () => {
            onhandleChangeStatus(id, 'active', () => {
                onhandleGetPlanDetails(plan.planId)
            });
        }
        onhandleOpenConfirmDialog('激活确认', '确定要激活此计划详情吗?', changeStatus, null, false);
    };

    handleOpenInspection() {
        const { onhandleOpenInspection } = this.props
        onhandleOpenInspection()
    }

    handleOpenPlanDetailAddPannel(tabValue) {
        const { onhandleOpenPlanDetailAddPannel } = this.props
        onhandleOpenPlanDetailAddPannel(this.handleAddPlanDetail.bind(this), null, tabValue)
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyAlias : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            case 'inspectType':
                value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
                break;
            case 'user':
                const user = _.find(users, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'status':
                switch (colValue) {
                    case 'drafting':
                        value = '草稿'
                        break;
                    case 'rejected':
                        value = '驳回'
                        break;
                    case 'submitted':
                        value = '已提交'
                        break;
                    case 'pending':
                        value = '待审批'
                        break;
                    case 'finalized':
                        value = '已发布'
                        break;
                    // case 'active':
                    //     value = '进行中'
                    //     break;
                    // case 'done':
                    //     value = '已完成'
                    //     break;
                    default:
                        value = '失效'
                }
                break;
            default:
            // do nothing
        }
        return value;
    }

    getDptInspectDetails(planDetails) {
        const {
            inspectionDptMapping
        } = this.props;

        // 过滤排查列表
        const filtedDetailList = _.filter(planDetails, (o) => {
            // 过滤当前用户可操作的详情
            if (_.findIndex(inspectionDptMapping, (mapping) => (o.inspectType.includes(mapping.inspectionCode))) > -1) {
                return true;
            }
        })
        return filtedDetailList;
    }

    render() {
        return (
            <div>
                <InspectionPlan
                    plan={this.props.plan}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    users={this.props.users}
                    companies={this.props.companies}
                    departmentList={this.props.departmentList}
                    currentRecord={this.props.currentRecord}
                    currentProcess={this.props.currentProcess}
                    processes={this.props.processes}
                    planDetails={this.props.planDetails}
                    processHistories={this.props.processHistories}
                    processSteps={this.props.companyAllProcessStatus}
                    onhandleOpenPlanDetailAddPannel={this.handleOpenPlanDetailAddPannel.bind(this)}
                    onhandleAddPlan={this.handleAddPlan.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleReject={this.handleReject.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleActivate={this.handleActivate.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    currentProcessStatus={this.props.currentProcessStatus}
                    listTypeList={this.props.listTypeList}
                    categoryList={this.props.categoryList}
                    inventoryList={this.props.inventoryList}
                    onhandleListMapping={this.handleListMapping.bind(this)}
                    inspectionDptMapping={this.props.inspectionDptMapping}

                    tabValue={this.state.tabValue}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
                <ApprovePannel />
                <InspectionPlanDetailAddPannel />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.user.leaders,
        companies: getActualCompany(state.constant.companies),
        departmentList: getManageDpt(state.constant.departments),
        inspectionDptMapping: state.constant.inspectionDptMapping,
        bUnits: state.constant.businessunits,
        plan: state.inspectionPlan.currentPlan,
        planDetails: state.inspectionPlan.planDetails,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        currentRecord: mapCurrentRecord(
            state.process.processes.InspectionPlan,
            state.auth.currentUserInfo,
            state.auth.userProfile
        ),
        currentProcess: mapCurrentProcess(
            state.process.processes.InspectionPlan,
            state.auth.currentUserInfo,
            state.auth.userProfile
        ),
        processes: state.process.processes.InspectionPlan || [],
        processHistories: state.process.processHistories.InspectionPlan || [],
        companyAllProcessStatus: state.process.companyAllProcessStatus.InspectionPlan || [],
        inspections: state.inspection.inspections,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        inventoryList: state.inspection.inventories.list,
        currentProcessStatus: state.process.currentProcessStatus.InspectionPlan || {},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('InspectionPlan', null, callback))
        },
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getCurrentPlan(callback))
        },
        onhandleGetCurrentProcess: (callback) => {
            dispatch(getCurrentProcess('InspectionPlan', callback))
        },
        onhandleGetProcessHistories: (recordId) => {
            dispatch(getProcessHistories(recordId, 'InspectionPlan'))
        },
        onhandleGetCompanyAllProcessStatus: () => {
            dispatch(getCompanyAllProcessStatus('InspectionPlan'))
        },
        onhandleGetChecklistCount: () => {
            dispatch(getChecklistCount())
        },
        onhandleGetLeaderList: () => {
            dispatch(getAllLeaders())
        },
        onhandleGetListTypeList: () => {
            dispatch(getListTypeList())
        },
        onhandleGetCategoryList: () => {
            dispatch(getCategoryList())
        },
        onhandleGetInventoryList: () => {
            dispatch(getInventories({ offset: 0, size: 0 }))
        },
        onhandleGetPlanDetails: (planId) => {
            dispatch(getPlanDetails({
                planId: planId
            }))
        },
        onhandleClearPlanDetails: () => {
            dispatch(clearPlanDetails())
        },
        onhandleAddPlan: (plan, callback) => {
            dispatch(addPlans(plan, callback))
        },
        onhandleUpdate: (dtlId, planDetail, callback) => {
            dispatch(updatePlanDetails(dtlId, planDetail, callback))
        },
        onhandleAddPlanDetail: (planDetailList, callback) => {
            dispatch(addPlanDetail(planDetailList, callback))
        },
        onhandleChangeStatus: (id, status, callback) => {
            dispatch(changePlanDetailStatus(id, status, callback));
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeProcess(actionParams, callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleOpenPlanDetailAddPannel: (confirmCallback, closeCallback, inspectType) => {
            dispatch(openPlanDetailAddPannel(confirmCallback, closeCallback, inspectType))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        }

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionPlansContainer)