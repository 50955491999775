// 本页恢复施工，仍存在废弃的可能
/* eslint-disable no-console */
/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
import withStyles from './styles';

const Action = props => {
  // 删除是直接点就删除还是点编辑之后才可以删除（防止误操作），我觉得后者比较靠谱
  // 不过这样一来的话就没有必要在还没点编辑之前就显示删除按钮
  // 怎样才好呢，因为需求上没写，所以我还没做决定
  console.log(props);
  return (
    <div>
      <button type="button" onClick={props.editCb}>
        {props.tr.edit ? '保存' : '编辑'}
      </button>
      <button type="button" onClick={props.tr.edit ? props.saveCb : Number}>
        {props.tr.edit ? '取消' : '删除'}
      </button>
    </div>
  );
};

const Material = props => {
  const { headers, keys } = props;

  return (
    <thead>
      <thead>
        <tr>
          {keys.map(key => (
            <th>{headers[key]}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {this.state.trs.map(function (tr) {
          return (
            <tr>
              {keys.map(function (key,) {
                return (
                  <td key={key}>
                    {key === 'action' ? (
                      <Action
                        tr={tr}
                        saveCb={this.onSave.bind(this, tr)}
                        editCb={this.onEdit.bind(this, tr)}
                      />
                    ) : (
                      tr[key]
                    )}
                  </td>
                );
              }, this)}
            </tr>
          );
        }, this)}
      </tbody>
    </thead>
  );
};

Material.propTypes = {
  name: PropTypes.any.isRequired,
  param: PropTypes.any,
  value: PropTypes.any,
  unit: PropTypes.any,
  action: PropTypes.any
};

export default withStyles(Material);
