import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
const porxyApi = '/api'

const initialState = {
    recordList: []
}

export const initAccessoryReplaceRecordList = createAction('@@xcloud/accessoryReplaceRecord/initAccessoryReplaceRecordList',
    () => (dispatch, getState) => {
        let parameters = getState().modal.parameters;
        Promise.all([
        ]).then((data) => {
            dispatch(getAccessoryReplaceRecordList(parameters.deviceId))
        }).catch(err => {
            let msg = '初始化部件更新记录搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getAccessoryReplaceRecordList = createAction(
    '@@xcloud/accessoryReplaceRecord/getAccessoryReplaceRecordList',
    (deviceId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/accessoryreplacerecord/${deviceId}`, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `查找设备部件更换记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const accessoryReplaceRecordReducer = handleActions(
    {
        [getAccessoryReplaceRecordList]: (state, { payload: value }) => {
            return {
                ...state,
                recordList: value
            };
        },
    },
    initialState
);

export default accessoryReplaceRecordReducer;