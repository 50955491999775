import * as _ from 'lodash';
//字典类型
export const TYPE_PERMISSION = 'PERMISSION';
export const TYPE_ATTR = 'ACTIVITY_ATTR';
export const ACTIVITY_TYPE = 'ACTIVITY_TYPE';
export const ORDER_STATUS = 'ORDER_STATUS';
export const TASK_STATUS = 'TASK_STATUS';
export const VALUATION_TYPE = 'VALUATION_TYPE';
export const PLAN_VALUATION_TYPE = 'PLAN_VALUATION_TYPE';
export const SERVICE_TYPE = 'SERVICE_TYPE';
export const PROJECT_VALUATION_TYPE = 'PROJECT_VALUATION_TYPE';
export const SERVICE_SHARE_TYPE = 'SERVICE_SHARE_TYPE';
export const SERVICE_COST_SHARE_TYPE = 'SERVICE_COST_SHARE_TYPE'
export const PROJECT_STATUS = 'PROJECT_STATUS';
export const OIL_STATION = 'OIL_STATION';
export const OIL_TYPE = 'OIL_TYPE';
export const PLAN_STATUS = 'PLAN_STATUS';

//阿米巴指标类型
export const AMOEBA_SUB_TYPE_INCOME = 'INCOME';//收入
export const AMOEBA_SUB_TYPE_VARIABLEFEE = 'VARIABLEFEE';//变动费用
export const AMOEBA_SUB_TYPE_FIXEDFEE = 'FIXEDFEE';//固定费用
export const AMOEBA_SUB_TYPE_PROFIT = 'PROFIT';//利润
export const AMOEBA_SUB_TYPE_HR = 'HR'; //人力资源

//服务类型
export const SERVICE_TYPE_COMMISSION = 'COMMISSION';
export const SERVICE_TYPE_OUTER = 'OUTER';
export const SERVICE_TYPE_INNER = 'INNER';
export const SERVICE_TYPE_ALL = 'ALL';

//计价方式
export const VALUATION_TYPE_BUDGET = 'BUDGET';
export const VALUATION_TYPE_PRICE = 'PRICE';

//服务费用分摊方式
export const COST_SHARE_TYPE_SHARE_NONE = 'SHARE_NONE';
export const COST_SHARE_TYPE_SHARE_SERVICE = 'SHARE_SERVICE';
export const COST_SHARE_TYPE_SHARE_RECORD_DPT = 'SHARE_RECORD_DPT';
export const COST_SHARE_TYPE_SHARE_INSTALL = 'SHARE_INSTALL';

/** 变动费模板基础名 */ export const VARIABLE_PLAN_DTL_TEMPLATE = '变动费模板.xlsx';
/** 人力资源模板基础名 */ export const FIXED_PLAN_DTL_TEMPLATE = '人力资源模板.xlsx';

/** 
 * 从字典根据类型(type)获取code名称
 * @param {*} dictionaries 
 * @param {*} type 
 * @param {*} code 
 */
export function getCodeName(dictionaries, type, code) {
    let dto = {};
    let typeList = [];
    if (dictionaries && dictionaries.length > 0) {
        typeList = dictionaries.filter(item => { return item.type === type });
    }

    dto = typeList.find(item => { return item.code === code });

    return dto === undefined ? '未设定' : dto.name;
}


/**
 * 从字典根据类型(type)获取codeList
 * @param {*} type 
 */
export function getCodeListByType(manufactureDicts, type) {
    return manufactureDicts.filter(item => { return item.type === type })
}


/**
 * 判断是否有子公司(2层结构)
 * @param {Array} companyList 
 * @param {number} companyId 
 */
export function judgeMainCompany(companyList, companyId) {
    
    // lzj added prtCompanyList,  fixed for level > 2; 20240701 
    let prtCompanyList = _.filter(companyList, (element) => { return element.pid === null && element.companyId == companyId});
    let subCompanyList = _.filter(companyList, (element) => { return element.pid === companyId });
    if (prtCompanyList && prtCompanyList.length > 0 && subCompanyList && subCompanyList.length > 0) {
        return true;
    } else {
        return false;
    }
}

/**
 * 解析服务公式用做显示
 * @param {*} services 
 */
export function setValuationFormulaStr(services) {
    if (services) {
        services.forEach(service => {
            if (service.taxRate && !isNaN(service.taxRate)) {
                service.taxRate = service.taxRate * 100
            } else {
                service.taxRate = 0
            }
            let attrList = [];
            if (service.valuationType === 'FIXED') {
                attrList.push({
                    id: 'unitPrice',
                    name: '月固定价'
                })
            } else if (service.valuationType === 'BUDGET') {
                attrList.push({
                    id: 'unitPrice',
                    name: '预算价'
                })
            } else if (service.valuationType === 'CUSTOMER') {
                attrList.push({
                    id: 'unitPrice',
                    name: '客户价'
                })
            } else {
                attrList.push({
                    id: 'unitPrice',
                    name: '单价'
                })
            }
            service.activities.forEach(element => {
                element.manufactureAttrs.forEach(ele => {
                    let node = {
                        id: '_' + ele.serviceConfigId,
                        name: element.activityName + "." + (ele.attrAlias ? ele.attrAlias : ele.attrName)
                    }
                    if (ele.attrType === "INTEGER" || ele.attrType === "DOUBLE" || ele.attrType === "STRING_LIST") {
                        attrList.push(node);
                    }
                })
            })
            let valuationFormulaStr = (service.valuationFormula ? service.valuationFormula : '') + '';
            let outputFormulaStr = (service.outputFormula ? service.outputFormula : '') + '';

            let valuationVars = JSON.parse(service.valuationVars);

            if (valuationVars) {
                valuationVars.forEach(element => {
                    let result = attrList.find(ele => ele.id === element);
                    if (result) {
                        valuationFormulaStr = valuationFormulaStr.replace(element, '{' + result.name + '}');
                        outputFormulaStr = outputFormulaStr.replace(element, '{' + result.name + '}');
                    } else {
                        valuationFormulaStr = valuationFormulaStr.replace(element, '未定义');
                        outputFormulaStr = outputFormulaStr.replace(element, '未定义');
                    }
                })
            }
            service.valuationFormulaStr = valuationFormulaStr
            service.outputFormulaStr = outputFormulaStr
        });
        return services
    }
    return []
}

