import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';

const ShiftRuleInfoComponent = props => {
    const { classes,
        open,
        shiftRule,
        dpts,
        shiftTypeDicts,
        onhandleClose,
        onhandleSave,
        onhandleTextChange,
        onhandleDateChange,
    } = props;

    const textFieldDefault = {
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{(!shiftRule || !shiftRule.ruleId) ? "新增班组排班设置" : "修改班组排班设置"}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column" >
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                placeholder=""
                                label='班组'
                                name='dptId'
                                value={shiftRule.dptId || ''}
                                onChange={onhandleTextChange} >
                                {dpts && dpts.map(i => (<MenuItem key={i.dptId} value={i.dptId}>{i.dptName}</MenuItem>))}
                            </TextField>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                placeholder=""
                                label='排班类型'
                                name='shiftType'
                                value={shiftRule.shiftType || ''}
                                onChange={onhandleTextChange} >
                                {shiftTypeDicts && shiftTypeDicts.map(i => (<MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>))}
                            </TextField>
                        </Grid>
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='上班天数'
                                    name='workDays'
                                    type="number"
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.workDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='休息天数'
                                    type="number"
                                    name='restDays'
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.restDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='上班天数'
                                    name='secondWorkDays'
                                    type="number"
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.secondWorkDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='休息天数'
                                    type="number"
                                    name='secondRestDays'
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.secondRestDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='上班天数'
                                    name='thirdWorkDays'
                                    type="number"
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.thirdWorkDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='休息天数'
                                    type="number"
                                    name='thirdRestDays'
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleTextChange}
                                    value={shiftRule.thirdRestDays || 0}>
                                </TextField>
                            </Grid>
                        }
                        {
                            shiftRule.shiftType && shiftRule.shiftType === "SHIFT_WORKDAY" && <Grid item className={classes.itemGrid}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        inputVariant="outlined"
                                        format="yyyy/MM/dd"
                                        id="date-selector"
                                        margin="normal"
                                        label='开始日期'
                                        InputProps={textFieldDefault}
                                        value={shiftRule.startTime}
                                        onChange={onhandleDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

ShiftRuleInfoComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    componentTitle: PropTypes.string,
    shiftRule: PropTypes.object,
    dpts: PropTypes.array,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleTextChange: PropTypes.func,
    onhandleDateChange: PropTypes.func,
};

ShiftRuleInfoComponent.defaultProps = {
    shiftRule: {},
    open: false,
    dpts: []
};

export default withStyles(ShiftRuleInfoComponent);