import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles'
import {
    MenuItem,
    FormHelperText,
    Typography,
    Button,
    TextField,
    Grid,
    // IconButton,
    TextareaAutosize,
    FormControlLabel,
    Checkbox,
    FormGroup
} from "@material-ui/core";
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '@/assets/rc-tree.css';
import '@/assets/rc-tree-select-tree.css';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import zhCN from 'date-fns/locale/zh-CN';
// import DateFnsUtils from '@date-io/date-fns';
// import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';
import { treeToArray } from '@/utils/constant';
import DeviceTransferContainer from '@/containers/DeviceManagement/DeviceInfo/DeviceTransfer'
import DeviceMediumContainer from '@/containers/DeviceManagement/DeviceInfo/DeviceMedium'

const DeviceBase = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleCheck,
        currentDevice,
        deviceError,
        deviceBaseChange,
        tabValue,
        index,
        companyTree,
        companyList,
        onhandleSelectChange,
        // onhandleDateChange,
        onhandleSelectMaster,
        onhandleOpenTransfer,
        onhandleOpenMedium,
        catTree,
        useStatusTree,
        procatTree,
        extcatTree,
        mgtcatTree,
        mediumTree,
        mediumPhase,
        // isView,
        // isDeviceEdit,
        // userProfile,
        // 负责班组列表
        departmentList,
        // 负责班组 Checkbox 选择发生变化 处理函数
        transferW,
        permissionW,
        onhandleCheckChange
    } = props;

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
    //     return permission && permission.action === 'W'
    // }

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    // 渲染 - “位号” 显示Title
    const renderTageNoTitle = () => {

        let tagNoTitle = "设备位号";

        // if (currentDevice && currentDevice.cat && currentDevice.cat.value.startsWith("D1706")) {
        //     tagNoTitle = "车牌号";
        // }

        // if (currentDevice && currentDevice.cat && currentDevice.cat.value.startsWith("D06")) {
        //     tagNoTitle = "管段号";
        // }

        if (currentDevice && currentDevice.cat) {
            if (currentDevice.cat.value) {
                if (currentDevice.cat.value.startsWith("D1706")) {
                    tagNoTitle = "车牌号";
                }

                if (currentDevice.cat.value.startsWith("D06")) {
                    tagNoTitle = "管段号";
                }
            } else {
                if (currentDevice.cat.startsWith("D1706")) {
                    tagNoTitle = "车牌号";
                }

                if (currentDevice.cat.startsWith("D06")) {
                    tagNoTitle = "管段号";
                }
            }
        }

        return tagNoTitle;
    }

    const renderMedia = () => {

        if (currentDevice.media) {
            let mediaStrList = []
            if (_.isArray(mediumTree)) {
                let mediumList = treeToArray(_.cloneDeep(mediumTree))
                for (let medium of currentDevice.media) {
                    let mediumWithLabel = _.find(mediumList, { value: medium.mediumName });
                    if (mediumWithLabel) {
                        let mp = _.find(mediumPhase, { code: medium.mediumPhase })
                        if (mp) {
                            mediaStrList.push(mediumWithLabel.label + ':' + mp.name);
                        } else {
                            mediaStrList.push(mediumWithLabel.label);
                        }
                    }
                }
                return mediaStrList.join(',')
            }
        }
        return ''

    }

    return (
        <div>
            <div className={classes.card} hidden={tabValue !== index}>
                <Grid container item spacing={1} className={classes.gridContainer} sm={12} lg={12}>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'基本信息'}
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                id="filled-select-company"
                                select
                                value={currentDevice.companyId}
                                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                                variant="outlined"
                                size='small'
                                error={deviceError.companyId !== ''}
                                onBlur={event => onhandleCheck(event, 'productivity')}
                                helperText={deviceError.companyId || ''}
                                disabled={Boolean(currentDevice.deviceId) || companyDisabled()}
                            >
                                {_.sortBy(companyList, 'companyId').map((option) => (
                                    <MenuItem key={option.companyId} value={option.companyId}>
                                        {option.companyAlias}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {transferW &&
                                <Button color="primary" variant="contained" style={{ marginLeft: 2 }}
                                    onClick={event => onhandleOpenTransfer(event)}
                                >调拨
                                </Button>
                            }
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>设备名称</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceError.deviceName !== ''}
                                helperText={deviceError.deviceName || ''}
                                className={classes.textField}
                                value={currentDevice.deviceName || ''}
                                variant="outlined"
                                size='small'
                                disabled={Boolean(!permissionW || currentDevice.groupId)}
                                onChange={event => onhandleChange(event, 'deviceName')}
                                onBlur={event => onhandleCheck(event, 'deviceName')}
                            />
                            {currentDevice.groupId && permissionW ? <FormHelperText className={classes.disableNotice}>
                                {'注:从装置中移除设备后才能修改该项目'}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设备编号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceError.deviceNo !== ''}
                                helperText={deviceError.deviceNo || ''}
                                className={classes.textField}
                                value={currentDevice.deviceNo || ''}
                                variant="outlined"
                                size='small'
                                disabled
                                onChange={event => onhandleChange(event, 'deviceNo')}
                                onBlur={event => onhandleCheck(event, 'deviceNo')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>
                                {renderTageNoTitle()}
                            </span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceError.tagNo !== ''}
                                helperText={deviceError.tagNo || ''}
                                className={classes.textField}
                                value={currentDevice.tagNo || ''}
                                variant="outlined"
                                size='small'
                                disabled={!permissionW}
                                onChange={event => onhandleChange(event, 'tagNo')}
                                onBlur={event => onhandleCheck(event, 'tagNo')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>型号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentDevice.model || ''}
                                variant="outlined"
                                size='small'
                                disabled={!permissionW}
                                onChange={event => onhandleChange(event, 'model')}
                                onBlur={event => onhandleCheck(event, 'model')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>规格</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentDevice.specs || ''}
                                variant="outlined"
                                size='small'
                                disabled={!permissionW}
                                onChange={event => onhandleChange(event, 'specs')}
                                onBlur={event => onhandleCheck(event, 'specs')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={12} md={12}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设备描述</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextareaAutosize
                                className={classes.textArea}
                                value={currentDevice.opentext || ''}
                                rowsMin={5}
                                onChange={event => onhandleChange(event, 'opentext')}
                                onBlur={event => onhandleCheck(event, 'opentext')}
                                disabled={!permissionW}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'管理信息'}
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>设备类别</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentDevice.cat}
                                labelInValue
                                treeData={catTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={!permissionW || currentDevice.groupId}
                                onChange={(value) => onhandleSelectChange(value, 'cat')}
                            />
                            {deviceError.cat !== '' ? <FormHelperText
                                error={true}
                            >
                                {deviceError.cat}
                            </FormHelperText> : (currentDevice.groupId && permissionW ? <FormHelperText className={classes.disableNotice}>
                                {'注:从装置中移除设备后才能修改该项目'}
                            </FormHelperText> : null)}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>专业类别</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentDevice.proCat}
                                labelInValue
                                treeData={procatTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={!permissionW}
                                onChange={(value) => onhandleSelectChange(value, 'procat')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>使用状态</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentDevice.useStatus}
                                labelInValue
                                treeData={useStatusTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={!permissionW}
                                onChange={(value) => onhandleSelectChange(value, 'useStatus')}
                            />
                            {deviceError.useStatus !== '' ? <FormHelperText
                                error={true}
                            >
                                {deviceError.useStatus}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>图纸编号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                error={deviceError.drawingCode !== ''}
                                helperText={deviceError.drawingCode || ''}
                                className={classes.textField}
                                value={currentDevice.drawingCode || ''}
                                variant="outlined"
                                size='small'
                                disabled={!permissionW}
                                onChange={event => onhandleChange(event, 'drawingCode')}
                                onBlur={event => onhandleCheck(event, 'drawingCode')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>管理类别</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentDevice.mgtCat}
                                labelInValue
                                treeData={mgtcatTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={!permissionW}
                                onChange={(value) => onhandleSelectChange(value, 'mgtcat')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>扩展类别</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={currentDevice.extCat}
                                labelInValue
                                treeData={extcatTree}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={!permissionW}
                                onChange={(value) => onhandleSelectChange(value, 'extcat')}
                            />
                        </Grid>
                    </Grid>



                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>介质</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={renderMedia()}
                                variant="outlined"
                                placeholder="点击选择"
                                size='small'
                                disabled={!permissionW}
                                onClick={event => onhandleOpenMedium(currentDevice.media)}
                            />
                            {currentDevice.groupId && permissionW ? <FormHelperText className={classes.disableNotice}>
                                {'注:从装置中移除设备后才能修改该项目'}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>所属主设备</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentDevice.masterDevice || ''}
                                variant="outlined"
                                placeholder="点击选择"
                                size='small'
                                disabled={!permissionW}
                                onClick={event => onhandleSelectMaster(event)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>金碟编号</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={currentDevice.extNo || ''}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'负责班组'}
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={12}>
                        {/* <Typography className={classes.checkboxLabel}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>负责班组</span>
                        </Typography> */}
                        <Grid item className={classes.formContainer}>
                            <FormGroup row className={classes.checkboxContainer}>
                                {
                                    departmentList ? departmentList.map((dpt) => (
                                        <FormControlLabel
                                            key={dpt.dptId}
                                            control={
                                                <Checkbox
                                                    name={"dptCheck" + dpt.dptId}
                                                    color="primary"
                                                    size={'small'}
                                                    checked={_.findIndex(currentDevice.allocations, (a) => { return a.dptId === dpt.dptId }) > -1}
                                                    onChange={(event) => onhandleCheckChange(event, dpt.dptId)}
                                                />
                                            }
                                            label={dpt.dptName}
                                        />
                                    )) : null
                                }
                            </FormGroup>
                            <FormHelperText error={deviceError.allocations !== ''}>
                                {deviceError.allocations || ''}</FormHelperText>
                        </Grid>
                    </Grid>

                </Grid>

            </div>

            <DeviceTransferContainer />
            <DeviceMediumContainer />
            <div className={classes.card} hidden={tabValue !== index}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        hidden={!permissionW || currentDevice.status !== 'new'}
                        disabled={!deviceBaseChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
            </div>
            <Prompt message="数据未保存,确定要离开？" when={deviceBaseChange && currentDevice.status === 'new'} />
        </div >
    );
};

DeviceBase.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentDevice: PropTypes.object.isRequired,
    deviceError: PropTypes.object.isRequired,
    deviceBaseChange: PropTypes.bool.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    catTree: PropTypes.array.isRequired,
    useStatusTree: PropTypes.array.isRequired,
    proCatTree: PropTypes.array.isRequired,
    extcatTree: PropTypes.array.isRequired,
    mgtcatTree: PropTypes.array.isRequired,
    mediumTree: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    // 负责班组列表
    departmentList: PropTypes.array.isRequired,
    // 负责班组 Checkbox 选择发生变化 处理函数
    onhandleCheckChange: PropTypes.func.isRequired
};

export default withStyles(DeviceBase);
