import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { STATUS_VALUE } from '@/utils/constant';
import CloseIcon from '@material-ui/icons/Close';


const OperationWorkAcceptancePannel = ({
    classes,
    open,
    acceptanceError,
    acceptance,
    terminateTypeList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck
}) => {
    // console.log('acceptance comp')
    // console.log(acceptance)

    const renderTitle = (status) => {
        if (!status || status === '') {
            return ''
        }
        if (status === STATUS_VALUE.STATUS_INVALID_ALL) return '全体作业废止';
        if (status === STATUS_VALUE.STATUS_TERMINATED) return '作业终止';
        if (status === STATUS_VALUE.STATUS_PAUSED) return '作业延期';
        if (status === STATUS_VALUE.STATUS_FINALIZED) return '作业结束';
        if (status === STATUS_VALUE.STATUS_REJECTED) return '作业不通过';
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{renderTitle(acceptance.status)}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        {
                            acceptance.status === STATUS_VALUE.STATUS_TERMINATED &&
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    label='终止类型'
                                    error={acceptanceError.terminateType !== ''}
                                    helperText={acceptanceError.terminateType}
                                    select
                                    value={acceptance.terminateType}
                                    name='terminateType'
                                    onChange={onhandleChange}>
                                    {
                                        terminateTypeList ?
                                            terminateTypeList.map(data => (
                                                <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                                            )) : null
                                    }
                                </TextField>
                            </Grid>
                        }
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"理由"}
                                variant='outlined'
                                error={acceptanceError.opentext !== ''}
                                helperText={acceptanceError.opentext}
                                className={classes.textField}
                                value={acceptance.opentext || ''}
                                rows={7}
                                name='opentext'
                                onChange={event => onhandleChange(event)}
                                onBlur={event => onhandleCheck(event)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        确定</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
OperationWorkAcceptancePannel.propTypes = {
    classes: PropTypes.object.isRequired,
    acceptanceError: PropTypes.object.isRequired,
    acceptance: PropTypes.object.isRequired
};

OperationWorkAcceptancePannel.defaultProps = {

}

export default withStyles(OperationWorkAcceptancePannel);