import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

// const whiteColor = "#FFF";

const styles = theme => ({
  root: {
    // boxShadow: 'none',
    minHeight: theme.spacing(6),
    height: theme.spacing(6),
    borderBottom: '1px solid rgba(0,0,0,0.1)'
  },
  drawer: {
    top: '48px'
  },
  logoButton: {
    outline: 'none'
  },
  logoimage: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    height: 26,
  },
  logo: {
    color: colors.blue[500],
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.48px',
    lineHeight: '30px'
  },
  toolbar: {
    display: 'flex',
    zIndex: 1500,
    '&:focus-visible': 'none',
    // ...theme.mixins.toolbar,
  },
  grid: {
    alignItems: 'center',
  },
  griditem: {
    padding: '0px 6px',
  },
  flexGrow: {
    flexGrow: 1
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  titleMessage: {
    padding: theme.spacing(4)
  },
  title: {
    color: theme.palette.white,
    fontSize: '24px'
  },
  avater: {
    backgroundColor: theme.palette.primary.dark,
    width: 25,
    height: 25
  },
  badge: {
    cursor: 'pointer'
  }
});

export default withStyles(styles);
