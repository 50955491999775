import React, { Component } from 'react';
import { connect } from 'react-redux';
import OppoList from "../../../components/Crm/Oppo/OppoList";
import { setPageSize, getOppo, changeOppoStatus, onView } from '../../../redux/modules/customerOppo';
import PropTypes from "prop-types";
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
// import * as _ from 'lodash';

class OppoListContainer extends Component {
    static propTypes = {
        users: PropTypes.array,
        search: PropTypes.object,
        oppolist: PropTypes.object,
        statusList: PropTypes.array,
    };

    // componentWillMount() {
    //     this.props.onhandleInitOppoList();
    // }

    handleDelete = (event, rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData, '0');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除该机会吗?', changeStatus, null, false);
    };

    handleView(event, id) {
        this.props.onhandleView(id);
    }

    render() {
        return (
            <OppoList
                oppolist={this.props.oppolist}
                statusList={this.props.statusList}
                onSizeChange={this.props.onhandleListSize.bind(this)}
                pageSize={this.props.pageSize}
                handleGetList={this.props.onhandleGetList.bind(this)}
                onDelete={this.handleDelete.bind(this)}
                onView={this.handleView.bind(this)}
                users={this.props.users}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.devices.search,
        oppolist: state.customerOppo.oppolist,
        catList: state.trees.deviceCategoryList,
        statusList: state.customerOppo.statusList,
        dangerLevelList: state.customerOppo.dangerLevelList,
        pageSize: state.customerOppo.pageSize,
        users: state.user.users.list,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onhandleInitOppoList: () => {
        //     dispatch(initOppoList());
        // },
        onhandleListSize: (value) => {
            dispatch(setPageSize(value));
        },
        onhandleGetList: (query) => {
            dispatch(getOppo(query));
        },
        onhandleChangeStatus: (rowData, status) => {
            dispatch(changeOppoStatus(rowData, status));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
        onhandleView: (id) => {
            dispatch(onView(id));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OppoListContainer)
