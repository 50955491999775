import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';


const styles = theme => ({
    dialogContent: {
        minWidth: 600
    },
    action: {
        padding: 16,
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
    itemLabel:{
        paddingTop:24,
        paddingLeft:4,
        paddingRight:4,
    },
    deleteButton:{
        color: colors.red[600],
    }
});

export default withStyles(styles);