import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment } from '@/utils/datetime';
import { getDateByTimestamp } from '../../../../utils/datetime';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import * as _ from 'lodash';
import {
    Typography,
    MenuItem,
    TextField,
    Button,
    Grid,
    Link,
    Divider
} from '@material-ui/core';

const ActivitiesInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleDelete,
        onhandleChange,
        onhandleBack,
        currentActivity,
        users,
        oppoList,
        onOpenUploadModal,
        onDownLoadDoc,
        onhandleDeleteDoc,
        isEditable } = props;

    currentActivity.actTime = currentActivity.actTime ? currentActivity.actTime :getMoment()().add(1, 'days').format('YYYY/MM/DD');
    //const minStartDate = moment()().startOf('year');

    //const minEndDate = currentActivity.actTime ? moment()(currentActivity.actTime).endOf('day') : minStartDate.endOf('day');
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const actTypes = [
        { "seq": 1, "name": "客户需求" },
        { "seq": 2, "name": "客户投诉" },
        { "seq": 3, "name": "客户满意度" },
        { "seq": 4, "name": "客户拜访" },
        { "seq": 5, "name": "除基础业务以外的其他技术服务" },
        { "seq": 6, "name": "客户建议" },
        { "seq": 7, "name": "机会商机" }
    ]

    const getActAttendees = () => {
        const results = [];

        _.find(users, function (o) {
            if (_.includes(currentActivity.attendees, o.userId)) {
                results.push(o)
            }
        });

        //console.log('getActAttendees', results)
        return results;
    }

    return (

        <div style={{ height: "calc(100% - 152px)" }} >
            {/* <Card className={classes.card}> */}
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <Typography variant="h3" gutterBottom>
                        {currentActivity.actId ? "活动详情" : "新建客户活动"}
                    </Typography>
                </Grid>

                <Grid item xs={6}></Grid>

                <Grid item xs={3}>
                    <Button variant="contained" color="primary" size="small" style={{ margin: 2 }}
                        onClick={event => onhandleSave(isEditable)}
                    >
                        {isEditable ? "保存" : "编辑"}
                    </Button>
                    {currentActivity.actId && !isEditable ? (
                        <Button variant="contained" color="primary" size="small" style={{ margin: 2 }}
                            onClick={event => onhandleDelete(currentActivity)}
                        >
                            删除
                        </Button>
                    ) : <Button variant="contained" color="primary" size="small" style={{ margin: 2 }}
                        onClick={event => onhandleBack(currentActivity)}
                        >
                            取消
                </Button>}
                </Grid>

            </Grid>
            <Divider />
            {currentActivity.actId ? (
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label} >
                            活动编号
                                </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography gutterBottom>
                            {currentActivity.actId}
                        </Typography>
                    </Grid>
                </Grid>) : null
            }

            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动名称
                        </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        value={currentActivity.actName || ''}
                        variant="standard" disabled={!isEditable}
                        onChange={event => onhandleChange(event, 'actName')}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.label} >
                        活动类型
                         </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        style={{ width: '90%' }}
                        id="filled-select-company"
                        select
                        value={currentActivity.actType}
                        disabled={!isEditable}
                        onChange={(event) => onhandleChange(event, 'actType')}
                        variant="standard"
                    >
                        <MenuItem key={1} value={''}>-</MenuItem>
                        {_.sortBy(actTypes, 'seq').map((option) => (
                            <MenuItem key={option.seq} value={option.seq}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动时间
                        </Typography>
                </Grid>
                <Grid item xs={5} container wrap={'nowrap'}>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={zhCN}>
                        <KeyboardDateTimePicker
                            className={classes.dateField}
                            InputProps={{ readOnly: true }}
                            ampm={false}
                            autoOk
                            variant='inline'
                            format="yyyy/MM/dd HH:mm"
                            margin="none"
                            disabled={!isEditable}
                            value={currentActivity.actTime}
                            onChange={date => onhandleChange(date, 'actTime')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动地点
                        </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        value={currentActivity.actLocation || ''}
                        variant="standard" disabled={!isEditable}
                        onChange={event => onhandleChange(event, 'actLocation')}
                    />
                </Grid>


                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动预算
                        </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        value={currentActivity.budget || '0'}
                        type="number"
                        disabled={!isEditable}
                        onChange={value => onhandleChange(value, 'budget')}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}>
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <Typography color='textPrimary' >
                        元
                        </Typography>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动负责人
                        </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        handleHomeEndKeys
                        options={users}
                        getOptionSelected={(option, value) => {
                            return option.userId === value.userId
                        }}
                        onChange={(event, newValue) => onhandleChange(newValue, 'actHead')}
                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                        style={{
                            minWidth: 120,
                        }}
                        disabled={!isEditable}
                        value={_.find(users, { 'userId': currentActivity.actHead }) || ''}
                        renderInput={(params) => <TextField {...params} placeholder="输入客户名查询" variant="standard" />}
                    />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        客户参与人
                        </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        value={currentActivity.customer || ''}
                        variant="standard" disabled={!isEditable}
                        onChange={event => onhandleChange(event, 'customer')}
                    />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        已方参与人
                        </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={users}
                        disableCloseOnSelect
                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                        getOptionSelected={(option, value) => {
                            // console.log('getOptionSelected={(option, value) ', option)
                            return option.userId === value.userId
                        }}
                        //value={ currentActivity.actAttendees || null}
                        value={getActAttendees()}
                        disabled={!isEditable}
                        onChange={(event, newValue) => onhandleChange(newValue, 'attendees')}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 2 }}
                                    checked={selected}
                                />
                                { option.lastName + option.firstName || ''}
                            </React.Fragment>
                        )}
                        style={{ width: 250 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="输入客户名查询" />
                        )}
                    />

                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        机会来源
                        </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        handleHomeEndKeys
                        options={oppoList.list}
                        getOptionSelected={(option, value) => {
                            return option.oid === value.oid
                        }}
                        onChange={(event, newValue) => onhandleChange(newValue, 'oid')}
                        getOptionLabel={(option) => { return option.oppoName || '' }}
                        style={{
                            minWidth: 120,
                        }}
                        disabled={!isEditable}
                        value={_.find(oppoList.list, { 'oid': currentActivity.oid }) || null}
                        renderInput={(params) => <TextField {...params} placeholder="输入机会名查询" variant="standard" />}
                    />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={3}>
                    <Typography gutterBottom className={classes.label}>
                        活动反馈
                        </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextareaAutosize
                        className={classes.textField}
                        value={currentActivity.feedback || ''}
                        disabled={!isEditable}
                        rowsMin={5}
                        onChange={event => onhandleChange(event, 'feedback')}
                    />
                </Grid>


                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label}>
                            活动相关文档
                            </Typography>
                    </Grid>
                    <Grid item xs={6} />
                    {(isEditable && currentActivity.cid) ? (
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary"
                                disabled={false} size="small"
                                onClick={event => onOpenUploadModal(event)}
                            >
                                上传
                         </Button>
                        </Grid>
                    ) : null}
                </Grid>


                {currentActivity.customDocs ? currentActivity.customDocs.map((doc) => (
                    <Grid container spacing={1} className={classes.gridContainer} key={doc.docId}>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                <Link href="#" onClick={event => onDownLoadDoc(doc.docLink)} className={classes.label}>
                                    {doc.docName || ''}
                                </Link>
                            </Typography>
                        </Grid>


                        <Grid item xs={4}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(doc.createTime, true) || ''}
                            </Typography>
                        </Grid>

                        { (isEditable && currentActivity.cid) ? (<Grid item xs={3}>
                            <Button variant="contained" color="primary"
                                disabled={!isEditable} size="small"
                                onClick={event => onhandleDeleteDoc(doc)}
                            >
                                删除
                         </Button>
                        </Grid>
                        ) : null}
                    </Grid>
                )) : null}
            </Grid>
            {/* </Card> */}
        </div>
    );
};
ActivitiesInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(ActivitiesInfo);
