import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import TenantSelect from '../components/TenantSelect';
import { confirmloginSelectedTenant, tenantChangeError } from '../redux/modules/auth';

class TenantSelectContainer extends Component {
    static propTypes = {
      loginUserTenants: PropTypes.array,
      loginSelectedTenant: PropTypes.any,
      tenantChangeErrorMessage: PropTypes.string,
      onhandeInit: PropTypes.func,
      isloading: PropTypes.bool,
      isProcessing: PropTypes.bool,
      currentUserInfo: PropTypes.any,
    }

    constructor(props) {
      super(props);
      this.state = { tenant: 0 };
    }

    componentWillMount() {
      // this.props.onhandeInit();
    }

    handleChange = event => {
      this.setState({
        tenant: event.target.value
      });
    };

    handleConfirm = () => {
      if (!this.props.isProcessing) {
        if (this.state.tenant === 0 || this.state.tenant === '0') {
          this.props.onhandetTenantChangeError();
        } else {
          const stateSelectTenantId = this.state.tenant;
          const selectedTenant = _.find(_.clone(this.props.loginUserTenants), function (o) { return o.tenantId.toString() === stateSelectTenantId; });
          this.props.onhandeConfirm(selectedTenant);
        }
      }
    };

    filter() {
      if (this.props.loginUserTenants) {
        // eslint-disable-next-line prefer-destructuring
        const loginUserTenants = this.props.loginUserTenants;
        return _.concat([{ tenantId: 0, tenantName: '', description: '' }], loginUserTenants);
      }
      return this.props.loginUserTenants;
    }

    render() {
      return (
        <TenantSelect
          tenantChangeErrorMessage={this.props.tenantChangeErrorMessage}
          loginUserTenants={this.filter()}
          tenantSelectValue={this.state.tenant}
          currentUserInfo={this.props.currentUserInfo}
          isloading={this.props.isloading}
          isProcessing={this.props.isProcessing}
          onhandleChange={this.handleChange.bind(this)}
          onhandeConfirm={this.handleConfirm.bind(this)}
          loginSelectedTenant={this.props.loginSelectedTenant}
        />
      );
    }
}

const mapStateToProps = (state) => {
  return {
    loginUserTenants: state.auth.loginUserTenants,
    loginSelectedTenant: state.auth.loginSelectedTenant,
    tenantChangeErrorMessage: state.auth.tenantChangeErrorMessage,
    isloading: state.auth.isloading,
    isProcessing: state.auth.isProcessing,
    currentUserInfo: state.auth.currentUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onhandeInit: () => {
    //   dispatch(initLoginTenantsList());
    // },
    onhandeConfirm: (tenant) => {
      dispatch(confirmloginSelectedTenant(tenant));
    },
    onhandetTenantChangeError: () => {
      dispatch(tenantChangeError('租户不能为空'));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantSelectContainer);
