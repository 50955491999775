import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    root: {
        
    },

    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },

    infocontainer: {
        padding: theme.spacing(2),
        flexDirection: 'column'
    },
    itemgrid: {
        alignItems: 'center',
    },
    textfield: {
        maxWidth: 400,
        minWidth: 200,
    },
    plantype: {
        //color: colors.deepOrange[600]
        //color: colors.deepOrange[600]
    },
    status: {
        backgroundColor: colors.deepOrange[600],
        color: '#FFFFFFFF',
        borderRadius: '2px',
        fontSize: '12px',
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5)
    },
    divider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.black,
    },
    title: {
        padding: theme.spacing(4)
    },
    tableTitle: {
        padding: theme.spacing(4),
        marginLeft: theme.spacing(1),
        maxWidth: 800,
        minWidth: 200,
        color:theme.palette.black,
        alignItems:'left'
    },
    button: {
        margin: theme.spacing(1)
    },
    timeline: {
        flexGrow: 1
    },
    processline: {
        flexGrow: 1
    },
    attention: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
    detailtable: {
        padding: theme.spacing(4)
    },
    materialtable: {
        boxShadow: 'none'
    },
    flexgrow: {
        flexGrow: 1
    },
    buttonintable: {
        margin: 0,
        padding: 0
    },
    addlink:{
        paddingTop: theme.spacing(3),
        alignItems:'center'
    }

});

export default withStyles(styles);