import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '@/redux/modules/message'
import OfficeSharingComponent from '@/components/AmoebaManagement/SharingCost/OfficeSharing/OfficeSharingComponent';
import {
    getSharingTakeHistories,
    initOfficeSharing,
    setSelectedOffice,
    getSharingTakeById,
    addSharingTake,
    updateSharingTake,
    setResetSharingParam,
} from '@/redux/modules/amoeba/amoebaCostSharing';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import { getDepartmentsByRole } from "@/utils/userUtils";
import SharingTakeHistory from '@/components/AmoebaManagement/SharingCost/SharingTakeHistory';

import * as _ from 'lodash';

class OfficeSharingContainer extends Component {
    static propTypes = {
        departments: PropTypes.array
    }

    constructor(props) {
        super(props)
        this.state = {
            sharingTakeHistoryOpen: false,
        }
    }


    componentWillMount() {
        const dptId = getDepartmentsByRole(this.props.currentUserInfo.roleId, this.props.userProfile)[0].dptId
        const myCompany = _.find(this.props.companies, c => { return c.companyId === this.props.currentUserInfo.companyId })
        this.props.onInitPage(myCompany.pid ? null : dptId);
    }

    handleSelectChange = (event) => {
        this.props.onhandleSelectChange(parseInt(event.target.value))
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.sharerDpt === parseInt(event.target.value)
        })
        if (cs !== undefined) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        }
    }

    handleAdd = (value) => {
        let cs = _.find(this.props.costSharinglist, (c) => { return c.sharerDpt === parseInt(this.props.selectedOffice) })
        let myCompany = _.find(this.props.companies, c => { return c.companyId === this.props.currentUserInfo.companyId })
        if (cs !== undefined) {
            value['sharingId'] = cs.sharingId
            if (value['percentage']) value['percentage'] = parseFloat(value['percentage'])
            if (value['takerCompany']) value['takerCompany'] = parseInt(value['takerCompany'])
            if (value['takerDpt']) value['takerDpt'] = parseInt(value['takerDpt'])
            if (value['takerCompany']) value['takerCompany'] = parseInt(value['takerCompany'])
            if (value['fixed']) value['fixed'] = parseFloat(value['fixed'])
            this.props.onhandleAdd(value, myCompany.pid ? 'TYPE_DEPARTMENT' : 'TYPE_COMPANY');
        }

    }

    handleDelete = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'status': 'inactive'
        }
        this.props.onhandleUpdate(newData);
    }

    handleUpdate = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'valuationType': value['valuationType'],
        }
        if (value['takerCompany']) newData['takerCompany'] = parseInt(value['takerCompany'])
        if (value['takerDpt']) newData['takerDpt'] = parseInt(value['takerDpt'])
        if (value['percentage']) newData['percentage'] = parseFloat(value['percentage'])
        if (value['fixed']) newData['fixed'] = parseFloat(value['fixed'])
        this.props.onhandleUpdate(newData);
    }

    handleOpenHistory = (value) => {
        this.setState({ sharingTakeHistoryOpen: true })
        this.props.onhandleGetSharingTakeHistories(value['sharingId'], value['takeId'])
    }

    handleCloseHistory = () => {
        this.setState({ sharingTakeHistoryOpen: false })
    }

    getsharingTakes = () => {

        const myCompany = _.find(this.props.companies, c => { return c.companyId === this.props.currentUserInfo.companyId })

        let sharingTakes = []
        if (myCompany.pid === null) {
            sharingTakes = _.filter(this.props.sharingTakelist, t => { return t.takeType === 'TYPE_COMPANY' })
        } else {
            sharingTakes = _.filter(this.props.sharingTakelist, t => { return t.takeType === 'TYPE_DEPARTMENT' && t.takerCompany === this.props.currentUserInfo.companyId })
        }
        return sharingTakes
    }


    handleOpenReset = () => {
        let param = {
            selectedDptId: this.props.selectedOffice,
            open: true,
            sharingType: 'SHARING_OFFICE',
            originTakes: this.getsharingTakes(),
        }

        this.props.onhandleSetResetSharingParam(param)
    }

    render() {
        const roleW = this.props.roleRoutes.find(ele => ele.route === 'amoeba_manager_sharing').action === 'W';
        const roleR = this.props.roleRoutes.find(ele => ele.route === 'amoeba_manager_sharing').action === 'R';
        return (
            <React.Fragment>
                <OfficeSharingComponent
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    valuations={this.props.valuations}
                    companies={this.props.companies}
                    sharingTakes={this.getsharingTakes()}
                    costSharinglist={this.props.costSharinglist}
                    selectedOffice={this.props.selectedOffice}
                    userProfile={this.props.userProfile}
                    roleW={roleW}
                    roleR={roleR}
                    onhandleMessage={this.props.onhandleMessage}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleOpenHistory={this.handleOpenHistory.bind(this)}
                    onhandleOpenReset={this.handleOpenReset.bind(this)}
                />
                <SharingTakeHistory
                    open={this.state.sharingTakeHistoryOpen}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    histories={this.props.sharingTakeHistories}
                    users={this.props.users}
                    onhandleClose={this.handleCloseHistory.bind(this)}
                    valuations={this.props.valuations}
                />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        companies: state.constant.companies,
        costSharinglist: state.amoebaCostSharing.costSharinglist,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
        selectedOffice: state.amoebaCostSharing.selectedOffice,
        userProfile: state.auth.userProfile,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        valuations: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.MANAGE_SHARE_TYPE
        }),
        sharingTakeHistories: state.amoebaCostSharing.sharingTakeHistories,
        users: state.usercard.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (dptId) => {
            dispatch(initOfficeSharing(dptId));
        },
        onhandleSelectChange: (dptId) => {
            dispatch(setSelectedOffice(dptId));
        },
        onhandleGetSharingTakeList: (sid) => {
            dispatch(getSharingTakeById(sid));
        },
        onhandleAdd: (value, type) => {
            dispatch(addSharingTake(value, type));
        },
        onhandleUpdate: (value) => {
            dispatch(updateSharingTake(value.takeId, value));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        },
        onhandleGetSharingTakeHistories: (sid, takeId, status) => {
            dispatch(getSharingTakeHistories(sid, takeId, status));
        },
        onhandleSetResetSharingParam: (param) => {
            dispatch(setResetSharingParam(param))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfficeSharingContainer);


export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}