import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {Table} from 'antd';
import * as _ from 'lodash';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

const DeviceFormList = props => {
    const {
        classes,
        // companies,
        forms,
        page,
        pageSize,
        formStatusList,
        formTypeList,
        onhandleSetPage,
        onhandleSetPageSize,
        onhandleDelete,
        onhandleEdit,
        onhandleSearch,

        // getDeviceOpsRecordList,
        // onhandleCleanmodal,
        // onhandlPreviewDoc
    } = props;

    // const [selectedRow, setSelectedRow] = useState(null);
    // const [page, setPage] = useState(0);

    // const renderTime = (time) => {
    //     return time ? getDateByTimestamp(time, false) : null;
    // }

    const renderFormType = (formType) => {
        let item = _.find(formTypeList, {'code': formType})
        if (item) {
            return item.name
        }
        return formType
    }

    const renderStatus = (status) => {
        let item = _.find(formStatusList, {'code': status})
        if (item) {
            return item.name
        }
        return status
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '表单编号',
                dataIndex: 'formNo',
                render: (text, row, index) => row ? <Typography>{row.formNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '表单名称',
                dataIndex: 'formName',
                render: (text, row, index) => row ? <Typography>{row.formName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '表单类型',
                dataIndex: 'formType',
                render: (text, row, index) => row && row.formType ?
                    <Typography>{renderFormType(row.formType)}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '设备',
                dataIndex: 'deviceName',
                render: (text, row, index) => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '部件名称',
            //     dataIndex: 'accName',
            //     render: (text, row, index) => row ? <Typography>{row.accName}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '备注',
                dataIndex: 'opentext',
                render: (text, row, index) => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (text, row, index) => row ? <Typography>{renderStatus(row.status)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '',
                dataIndex: 'actions',
                render: (text, row, index) => renderActions(row),
            }
        ]

        return columns
    }

    const renderActions = (row) => {

        return <Grid container>
            <Grid item>
                <Tooltip title='详情'>
                    <IconButton variant='text' onClick={() => onhandleEdit(row.formId)}> <Edit/></IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title='删除'>
                    <IconButton variant='text' onClick={() => onhandleDelete(row.formId)}> <Delete/></IconButton>
                </Tooltip>
            </Grid>
        </Grid>


    }

    const onhandlePageSizeChange = (pageSize, current) => {
        onhandleSetPageSize(pageSize);
        onhandleSetPage(0);
        // 这里会触发pageChange事件
    }

    const onhandlePageChange = (current) => {
        const page = current - 1;
        onhandleSetPage(page);
        onhandleSearch({
            ...forms,
            offset: page * (pageSize ? pageSize : 15)
        })
    }

    const getRowClass = (record, index) => {
        return (index % 2 !== 0 ? classes.oddRow : null);
    }

    return (
        <Grid className={classes.detailtable}>
            <Table
                className={classes.normalTable}
                rowClassName={getRowClass}
                rowKey={'formId'}
                columns={assemblycolumns()}
                dataSource={forms.list}
                // expandable={{ expandedRowRender }}
                size={'small'}
                // scroll={{ y: 'calc(100vh - 260px)' }}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    // showTotal: () => `共${initListData.total}条`,
                    pageSizeOptions: ['15', '30', '50', '100'],
                    pageSize: pageSize,
                    current: page + 1,
                    total: forms.total,
                    onShowSizeChange: (current, pageSize) => onhandlePageSizeChange(pageSize, current),
                    onChange: current => onhandlePageChange(current)
                }}
            />
        </Grid>
    )
};

DeviceFormList.propTypes = {
    classes: PropTypes.object.isRequired,
    forms: PropTypes.object.isRequired,
    pageSize: PropTypes.number.isRequired,
    onhandleSetPage: PropTypes.number.isRequired,
    onhandleSearch: PropTypes.object.isRequired
};
DeviceFormList.defaultProps = {
    pageSize: 15,
    page: 0,
    forms: {
        total: 0,
        list: []
    },
    companies: [],
};

export default withStyles(DeviceFormList);
