import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    detailTitle: {
        paddingTop: 24,
        paddingLeft: 16,
        alignItems: 'center'
    },
    card: {
        width: '100%',
        padding: 20,
        margin: 5
    },
    action: {
        padding: 16,
    },
    title: {
        padding: theme.spacing(2)
    },
    flexgrow: {
        flexGrow: 1
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    gridContainer: {
        marginTop: 10
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    pic11: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    pic21: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    pic4: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic5: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '6px',
    },
    pic3: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.red[400],
        fontSize: '6px',
    },
    pic41: {
        borderColor: colors.blue[500],
        fontSize: '12px',
    },
    pic51: {
        borderColor: colors.teal[400],
        fontSize: '12px',
    },
    pic31: {
        borderColor: colors.red[400],
        fontSize: '12px',
    },
    processline: {
        flexGrow: 1
    },
    processUser: {
        paddingLeft: theme.spacing(2),
        display: 'contents'
    },
    attention: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
    button: {
        margin: theme.spacing(1)
    },
    pendingUser: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        marginLeft: theme.spacing(1),
    },
    rejectedUser: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        marginLeft: theme.spacing(1)
    },
    approvedUser: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        marginLeft: theme.spacing(1)
    },
    historyList: {
        height: '80px',
        overflowY: 'scroll',
        width: '100%'
    },
    permitBar: {
        width: "100%"
    },
    new: {
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    reported: {
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    approved: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.white,
    },
    done: {
        backgroundColor: colors.blue[400],
        color: theme.palette.white,
    },
    default: {
        backgroundColor: colors.grey[300],
        color: theme.palette.white,
    },
    permitHistory: {
        paddingLeft: '4em'
    },
    permitHistoryItem: {
        margin: 0,
        padding: 0,
        paddingLeft: '1em'
    },
    confirmTrue: {
        color: theme.palette.success.main
    },
    confirmFalse: {
        color: theme.palette.error.main
    },
    pendingPermit: {
    },
    rejectedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.error.main
    },
    submittedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.info.main
    },
    finalizedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
    donePermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
});

export default withStyles(styles);