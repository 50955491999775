import React, { useState } from 'react';
import {
    IconButton,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {ZoomOutMap } from '@material-ui/icons'
import ReactEcharts from 'echarts-for-react';

const Dashboard = props => {
    const { classes
    } = props;

    const [options] = useState([
        {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line'
            }]
        },
        {
            angleAxis: {
                type: 'category',
                data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                z: 10
            },
            radiusAxis: {
            },
            polar: {
            },
            series: [{
                type: 'bar',
                data: [1, 2, 3, 4, 3, 5, 1],
                coordinateSystem: 'polar',
                name: 'A',
                stack: 'a'
            }, {
                type: 'bar',
                data: [2, 4, 6, 1, 3, 2, 1],
                coordinateSystem: 'polar',
                name: 'B',
                stack: 'a'
            }, {
                type: 'bar',
                data: [1, 2, 3, 4, 1, 2, 5],
                coordinateSystem: 'polar',
                name: 'C',
                stack: 'a'
            }],
            legend: {
                show: true,
                data: ['A', 'B', 'C']
            }
        }
    ])


    return (
        <div alignitems="center" className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h2'> 我的Dashboard</Typography>
                </Grid>
                {options.map((option, index) => (
                    <Grid item xs={6} key={index}>
                        <Card className={classes.card} >
                            <CardHeader
                                title='安全预警事件趋势图'
                                action={
                                    <IconButton aria-label="zoom">
                                        <ZoomOutMap />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <ReactEcharts
                                    option={option}
                                    style={{ height: '350px', width: '100%' }}
                                    className='react_for_echarts' />

                            </CardContent>
                        </Card>
                    </Grid>

                ))}




            </Grid>
        </div>
    );
}
Dashboard.propTypes = {
    title: PropTypes.string,

};

Dashboard.defaultProps = {
    title: 'Dashboard',
};

export default withStyles(Dashboard);