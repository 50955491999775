import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        padding: 20,
        // backgroundColor: 'rgba(247,247,250,1)'
    },
    gridContainer: {
        paddingTop: 20,
    },
    button: {
        marginLeft: 30
    },
    // root: {
    // },
    cardRoot: {
        padding: 0,
    },
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    title: {
        backgroundColor: theme.palette.title,
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: '0 24px 0 24px',
        height: '50px'
    },
    detail: {
        padding: '0 24px 0 24px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: '44px',
        '&:hover': {
            backgroundColor: theme.palette.divider
        }
    },
    progress: {
        margin: theme.spacing(2),
    },
    actions: {
        justifyContent: 'flex-end'
    },
    alignItem: {
        textAlign: 'center'
    },
    // textField: {
    //     minWidth: 300
    // },
    inputLabel: {
        marginRight: 15
    },
    ml10: {
        marginLeft: 10
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    processline: {
        flexGrow: 1
    },

    attention: {
        // marginLeft: theme.spacing(4),
        //marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
});

export default withStyles(styles);