import { createAction, handleActions } from 'redux-actions';
// import { createhttpMessage } from './message'


// const porxyApi = '/api'

const initialState = {
    newPlans: [],
    currentPlans: [],
};

export const setNewPlans = createAction('@@xcloud/leadership/setnewplans');
export const setCurrentPlans = createAction('@@xcloud/leadership/setcurrentplans');

export const getNewPlans = createAction('@@xcloud/leadership/getnewplans',
    (callback) => (dispatch, getState, httpClient) => {
        let newPlans = [{
            planCode: 'SafetyGoal',
            planName: '安全生产目标'
        }, {
            planCode: 'InsurancePlan',
            planName: '安保基金'
        }, {
            planCode: 'RegulationPlan',
            planName: '规章制度完善计划'
        }]
        dispatch(setNewPlans(newPlans))
        // httpClient.post(porxyApi + '/leadership/plans/new', callback)
        //     .then((res) => {
        //         if (res) {
        //             dispatch(setNewPlans(res.data))
        //             if (callback)
        //                 callback(res.data)
        //         }
        //     }).catch(err => {
        //         let msg = `获取创建计划列表失败`;
        //         dispatch(createhttpMessage(err.response ? err.response : '', msg));
        //         return []
        //     })
    });

export const getCurrentPlans = createAction('@@xcloud/leadership/getcurrentplans',
    (callback) => (dispatch, getState, httpClient) => {

        // httpClient.post(porxyApi + '/leadership/plans/current', callback)
        //     .then((res) => {
        //         if (res) {
        //             dispatch(setCurrentPlans(res.data))
        //             if (callback)
        //                 callback(res.data)
        //         }
        //     }).catch(err => {
        //         let msg = `获取进行中计划列表失败`;
        //         dispatch(createhttpMessage(err.response ? err.response : '', msg));
        //         return []
        //     })
    });

export const LeadershipReducer = handleActions(
    {
        [setNewPlans]: (state, { payload: data }) => {
            return {
                ...state,
                newPlans: data
            }
        },
        [setCurrentPlans]: (state, { payload: data }) => {
            return {
                ...state,
                currentPlans: data
            }
        },

    },
    initialState
)

export default LeadershipReducer

