import React, { Component } from 'react';
import { connect } from 'react-redux';
import SalOrderComponent from '@/components/AmoebaManagement/SalOrder';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getSalorderList } from '@/redux/modules/amoeba/amoebaSalOrder';

class SalOrderContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const orderDate = formatDate(getMoment()());
        this.props.getSalorderList(undefined, undefined, orderDate, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const orderDate = formatDate(date);
            this.props.getSalorderList(undefined, undefined, orderDate, undefined);
        })
    }

    render() {
        return (
            <div>
                <SalOrderComponent
                    selectedDate={this.state.selectedDate}
                    salorderList={this.props.salorderList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        salorderList: state.amoebaSalOrder.salorderList,
        userInfo: state.auth.currentUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSalorderList: (customerName, salesmanId, orderDate, status) => {
            dispatch(getSalorderList(customerName, salesmanId, orderDate, status));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalOrderContainer);
