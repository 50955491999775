import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    tab: {
        fontSize: '12px'
    },
    infocontainer: {
        padding: 20
    },
    itemgrid: {
        alignItems: 'center',
    },
    textField: {
        // maxWidth: '20%',
        // minWidth: '20%'
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 100
    },
    chip: {
        margin: '1px'
    },
    dateLabel: {
        padding: '0 4px 0 0'
    },
    dateHelperText: {
        marginTop: 5
    },
    plantype: {
        //color: colors.deepOrange[600]
        //color: colors.deepOrange[600]
    },
    status: {
        backgroundColor: colors.deepOrange[600],
        color: '#FFFFFFFF',
        borderRadius: '2px',
        fontSize: '12px',
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5)
    },
    divider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.black,
    },
    title: {
        padding: theme.spacing(4)
    },
    tableTitle: {
        padding: theme.spacing(4),
        marginLeft: theme.spacing(1),
        maxWidth: 800,
        minWidth: 200,
        color: theme.palette.black,
        alignItems: 'left'
    },
    button: {
        margin: theme.spacing(1)
    },
    timeline: {
        flexGrow: 1
    },
    processline: {
        flexGrow: 1
    },
    attention: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
    detailtable: {
        paddingTop: 10
    },
    materialtable: {
        boxShadow: 'none'
    },
    flexgrow: {
        flexGrow: 1
    },
    buttonintable: {
        margin: 0,
        padding: 0
    },
    checkbox: {
        padding: 0,
        marginRight: 5
    },
    addlink: {
        paddingTop: theme.spacing(3),
        alignItems: 'center'
    },
    treeSelect: {
        width: 'auto'
    }

});

export default withStyles(styles);