import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    padding: 0
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridSubItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  connectLabel: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    marginLeft: theme.spacing(1),
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  textFieldGroupItem: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  pending: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main
  },
  valid: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  invalid: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  detailtable: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  menuItemNormal: {
    color: theme.palette.error.primary
  },
  menuItemCritical: {
    color: theme.palette.error.main
  },
  menuIcon: {
    marginRight: 5
  },
});

export default withStyles(styles);
