import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import {
  getAllLeaders
} from '@/redux/modules/user';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createhttpMessage, createMessage } from '@/redux/modules/message';
// import { getUsersWithCallback } from '@/redux/modules/user';
import { download, removeObject } from '@/redux/modules/minio';
import _ from 'lodash';

const defaultState = {
  hazardList: {
    total: 0,
    list: []
  },
  currentHazard: {},
  // backup 用于检测治理方案编辑权限
  currentHazardBackup: {},
  // 隐患详情下对应的治理方案
  treatmentScheme: {
    total: 0,
    list: []
  },
  hazardChange: false,
  search: {
    company: '',
    dptId: 0,
    rootCause: '',
    severity: '',
    impactLevel: '',
    factor: '',
    createTime: '',
    createTimeEnd: '',
    status: '',
    creator: 0,
    page: 0,
    size: 10,
    offset: 0,
  },
  userList: [],
  schemeMap: {
    schemeId: null,
    schemeType: null,
    hid: null
  },
  currentRouteType: 'beforeevaluated'
}

export const resetSearch = createAction('@@xcloud/monthlyPlan/resetSearch',
  (isBack) => (dispatch, getState, httpClient) => {
    const currentRouteType = getState().hazard.currentRouteType;
    let status = '';
    switch (currentRouteType) {
      case 'beforeevaluated':
        status = 'active';
        break;
      case 'afterevaluated':
        status = 'evaluated';
        break;
      case 'treating':
        status = 'treating,done';
        break;
      case 'hisevaluated':
        status = '';
        break;
      default:
      // do nothing
    }

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;

    let newSearch = isBack ?
      {
        ...getState().hazard.search,
        status: status
      } : {
        company: hasPcomp ? getState().auth.currentUserInfo.companyId : '',
        dptId: 0,
        rootCause: '',
        severity: '',
        impactLevel: '',
        factor: '',
        createTime: '',
        createTimeEnd: '',
        creator: 0,
        status: status,
        page: 0,
        size: 10,
        offset: 0,
      }
    dispatch(setSearch(newSearch));
  });

export const initHazardList = createAction('@@xcloud/hazard/initHazardList',
  (isBack, routeType) => (dispatch, getState) => {

    const dispatchArray = [
      dispatch(createloading()),
      dispatch(setRouteType(routeType))
    ];
    Promise.all(dispatchArray)
      .then(() => {
        dispatch(resetSearch(isBack))
      })
      .then(() => {
        const search = getState().hazard.search;
        dispatch(getHazardList(search))
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化月度排查计划搜索失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getHazardList = createAction('@@xcloud/hazard/getHazardList',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/hazardlist';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=10";
    }
    if (query.sort !== undefined) {
      url += "&sort=" + query.sort;
    } else {
      url += "&sort=-create_time";
    }
    if (query.company)
      url += "&companyId=" + query.company;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.rootCause)
      url += "&rootCause=" + query.rootCause;
    if (query.severity)
      url += "&severity=" + query.severity;
    if (query.impactLevel)
      url += "&impactLevel=" + query.impactLevel;
    if (query.factor)
      url += "&factor=" + query.factor;
    if (query.createTime)
      url += "&createTime=" + query.createTime;
    if (query.createTimeEnd)
      url += "&createTimeEnd=" + query.createTimeEnd;
    if (query.creator)
      url += "&creator=" + query.creator;
    if (query.status)
      url += "&status=" + query.status;
    if (query.search && query.search !== "") {
      url += "&search=" + encodeURIComponent(query.search);
    }
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (res.data) {
            dispatch(setHazardList(res.data));
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '隐患台账获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getHazardListForCsv = createAction('@@xcloud/hazard/getHazardListForCsv',
  (query, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/hazardlist';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=10";
    }
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.company)
      url += "&companyId=" + query.company;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.rootCause)
      url += "&rootCause=" + query.rootCause;
    if (query.severity)
      url += "&severity=" + query.severity;
    if (query.impactLevel)
      url += "&impactLevel=" + query.impactLevel;
    if (query.factor)
      url += "&factor=" + query.factor;
    if (query.createTime)
      url += "&createTime=" + query.createTime;
    if (query.createTimeEnd)
      url += "&createTimeEnd=" + query.createTimeEnd;
    if (query.creator)
      url += "&creator=" + query.creator;
    if (query.status)
      url += "&status=" + query.status;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            callback(res.data)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '隐患台账获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getHazardListById = createAction('@@xcloud/hazard/getHazardListById',
  (hid) => (dispatch, getState, httpClient) => {
    let url = `/api/hazardlist?hid=${hid}`;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setCurrentHazard(res.data.list[0]));
            dispatch(setCurrentHazardBackup(_.cloneDeep(res.data.list[0])));
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '隐患台账获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const updateHazard = createAction('@@xcloud/hazard/updateHazard',
  (hazard) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = `/api/hazardlist/${hazard.hid}`;
    return httpClient.patch(url, hazard, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          dispatch(getHazardListById(hazard.hid));
          dispatch(createMessage('success', '更新隐患信息完成'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新隐患信息失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const changeHazardStatus = createAction('@@xcloud/hazard/changeHazardStatus',
  (hid, status, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let to = {
      hid: hid,
      status: status
    }
    let url = `/api/hazardlist/${hid}`;
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (callback) callback(res.data);
          dispatch(createMessage('success', '更新隐患信息完成'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新隐患信息失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const exportHazardFileById = createAction('@@xcloud/hazard/exportHazardFileById',
  (hid) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = `/api/hazardlist/${hid}/export`;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (res.data) {
            let tenantId = getState().auth.userProfile.roles[0].tenantId
            let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
            dispatch(download(
              res.data.docName,
              mybucket
            ))
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        debugger
        let msg = '导出隐患档案失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getTreatmentSchemeById = createAction('@@xcloud/hazard/getTreatmentSchemeById',
  (schemeId, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/treatmentscheme/' + schemeId;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            if (callback) callback(res.data);
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '隐患台账获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getTreatmentScheme = createAction('@@xcloud/hazard/getTreatmentScheme',
  (query, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/treatmentscheme';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=0";
    }
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.schemeId)
      url += "&schemeId=" + query.schemeId;
    if (query.hid)
      url += "&hid=" + query.hid;
    if (query.schemeCat)
      url += "&schemeCat=" + query.schemeCat;
    if (query.measure)
      url += "&measure=" + query.measure;
    if (query.head)
      url += "&head=" + query.head;
    if (query.accepter)
      url += "&accepter=" + query.accepter;
    if (query.acceptResult)
      url += "&acceptResult=" + query.acceptResult;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.companyId)
      url += "&companyId=" + query.companyId;
    if (query.status)
      url += "&status=" + query.status;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setTreatmentScheme(res.data));
            if (callback) callback(res.data);
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '治理方案获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const addTreatmentScheme = createAction('@@xcloud/hazard/addTreatmentScheme',
  (treatmentScheme, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    httpClient.post('/api/treatmentscheme', treatmentScheme)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          dispatch(createMessage('success', '新建隐患排查治理方案成功'));
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        dispatch(cleanloading());
        let msg = '新建隐患排查治理方案失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const updateTreatmentScheme = createAction('@@xcloud/hazard/updateTreatmentScheme',
  (treatmentscheme, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = `/api/treatmentscheme/${treatmentscheme.schemeId}`;
    return httpClient.patch(url, treatmentscheme, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (callback)
            callback(res.data)
          dispatch(createMessage('success', '更新治理方案完成'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        console.log(err)
        dispatch(cleanloading());
        let msg = '更新治理方案失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const changeTreatmentSchemeStatus = createAction('@@xcloud/hazard/changeTreatmentSchemeStatus',
  (schemeId, status, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let to = {
      schemeId: schemeId,
      status: status
    }
    let url = `/api/treatmentscheme/${schemeId}`;
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          if (callback) callback(res.data);
          dispatch(createMessage('success', '更新治理方案完成'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新治理方案失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createPretreatmentDoc = createAction('@@xcloud/hazard/createPretreatmentDoc',
  (doc) => (dispatch, getState, httpClient) => {

    return httpClient
      .post(`/api/pretreatmentdocs`, doc, { headers: { need_loading: true } })
      .then((res) => {
        if (res.data) {
          // dispatch(createhttpMessage({ status: 200 }, '上传应急预案文档成功'))
          return res.data;
        }
      })
      .catch(err => {
        let msg = `上传应急预案文档失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);

export const removePretreatmentDoc = createAction('@@xcloud/hazard/removePretreatmentDoc',
  (doc, mybucket) => (dispatch, getState, httpClient) => {
    let dto = _.cloneDeep(doc);
    delete dto.createTime;
    delete dto.updateTime;
    delete dto.revisor;
    dto.status = 'inactive'

    return httpClient
      .patch(`/api/pretreatmentdocs/${doc.docId}`, dto, { headers: { need_loading: true } })
      .then((res) => {
        if (res.data) {
          dispatch(createhttpMessage({ status: 200 }, '操作应急预案文档成功'))
          dispatch(removeObject(mybucket, doc.docLink));

          return res.data;
        } else {
          // dispatch(createMessage('warning', '该文档在...中已使用,请解除后再删除'));
        }
      })
      .catch(err => {
        let msg = `操作应急预案文档失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);

export const addSchemePlan = createAction('@@xcloud/hazard/addSchemePlan',
  (schemePlan, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    httpClient.post('/api/schemeplan', schemePlan)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          dispatch(createMessage('success', '关联隐患治理方案成功'));
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        dispatch(cleanloading());
        let msg = '关联隐患治理方案失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const deleteSchemePlan = createAction('@@xcloud/hazard/deleteSchemePlan',
  (deleteQuery, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/schemeplan?';
    const query = [];
    if (deleteQuery.schemeId)
      query.push("schemeId=" + deleteQuery.schemeId);
    if (deleteQuery.planType)
      query.push("planType=" + deleteQuery.planType);
    if (deleteQuery.planId)
      query.push("planId=" + deleteQuery.planId);
    if (!query.join('&').includes('&')) {
      return [];
    }
    return httpClient.delete(url + query.join('&'), { headers: { need_loading: 'true' } })
      .then((res) => {
        dispatch(cleanloading());
        if (res.status === 200) {
          console.log('schemeplan deleted')
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '删除隐患治理方案关联失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const backToHazardList = createAction('@@xcloud/hazard/backToHazardList',
  () => (dispatch, getState, httpClient) => {
    const currentRouteType = getState().hazard.currentRouteType;
    // let param = 'beforeevaluated';
    // switch (currentRouteType) {
    //   case 'beforeevaluated':
    //     param = 'beforeevaluated';
    //     break;
    //   case 'afterevaluated':
    //     param = 'afterevaluated';
    //     break;
    //   case 'treating':
    //     param = 'treating';
    //     break;
    //   case 'hisevaluated':
    //     param = 'hisevaluated';
    //     break;
    //   default:
    //   // do nothing
    // }
    dispatch(push('/inspection/hazardlist/' + currentRouteType, { isBack: true }));

  });

export const routeToHazardInfo = createAction('@@xcloud/hazard/routeToHazardInfo',
  (hid, editable) => (dispatch) => {
    dispatch(getAllLeaders());
    const mode = editable ? 'edit' : 'view';
    dispatch(push(`/inspection/hazardlist/info/${hid}/${mode}`))
  }
);

export const routeToDevicePlan = createAction('@@xcloud/hazard/routeToDevicePlan',
  (schemeId, schemeType, hid) => (dispatch) => {
    dispatch(setSchemeMap({ schemeId: schemeId, schemeType: schemeType, hid: hid }));
    dispatch(push(`/deviceManagement/operationplan/create/DEV`));
  }
);

export const routeToRegulationPlan = createAction('@@xcloud/hazard/routeToRegulationPlan',
  (schemeId, schemeType, hid) => (dispatch) => {
    dispatch(setSchemeMap({ schemeId: schemeId, schemeType: schemeType, hid: hid }));
    dispatch(push(`/leadership/temporaryplan/temporaryregulationplaninfo`));
  }
);

export const routeToDeviceList = createAction('@@xcloud/hazard/routeToDeviceList',
  (id) => (dispatch) => {
    dispatch(push(`/deviceManagement/operationplan`));
  }
);

export const routeToRegulationList = createAction('@@xcloud/hazard/routeToRegulationList',
  (id) => (dispatch) => {
    dispatch(push(`/leadership/plan/regulation`));
  }
);

export const setHazardList = createAction('@@xcloud/hazard/setHazardList');

export const setCurrentHazard = createAction('@@xcloud/hazard/setCurrentHazard');

export const setCurrentHazardBackup = createAction('@@xcloud/hazard/setCurrentHazardBackup');

export const setTreatmentScheme = createAction('@@xcloud/hazard/setTreatmentScheme');

export const setSearch = createAction('@@xcloud/hazard/setHazardsetSearchList');

export const setUserList = createAction('@@xcloud/hazard/setUserList');

export const setHazardChange = createAction('@@xcloud/hazard/setHazardChange');

export const setSchemeMap = createAction('@@xcloud/hazard/setSchemeMap');

export const clearSchemeMap = createAction('@@xcloud/hazard/clearSchemeMap');

export const setRouteType = createAction('@@xcloud/hazard/setRouteType');

export const hazardReducer = handleActions(
  {
    [setHazardList]: (state, { payload: value }) => {
      return {
        ...state,
        hazardList: value
      };
    },
    [setHazardChange]: (state, { payload: value }) => {
      return {
        ...state,
        hazardChange: value
      };
    },
    [setCurrentHazard]: (state, { payload: value }) => {
      return {
        ...state,
        currentHazard: value
      };
    },
    [setCurrentHazardBackup]: (state, { payload: value }) => {
      return {
        ...state,
        currentHazardBackup: value
      };
    },
    [setTreatmentScheme]: (state, { payload: value }) => {
      return {
        ...state,
        treatmentScheme: value
      };
    },
    [setUserList]: (state, { payload: value }) => {
      return {
        ...state,
        userList: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setSchemeMap]: (state, { payload: value }) => {
      return {
        ...state,
        schemeMap: {
          schemeId: value.schemeId,
          schemeType: value.schemeType,
          hid: value.hid
        }
      };
    },
    [clearSchemeMap]: (state, { payload: value }) => {
      return {
        ...state,
        schemeMap: {
          schemeId: null,
          schemeType: null,
          hid: null
        }
      };
    },
    [setRouteType]: (state, { payload: value }) => {
      return {
        ...state,
        currentRouteType: value
      };
    },
  },
  defaultState
);

export default hazardReducer;