import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization } from "../../../../utils/mtable";
import {
    Checkbox,
    Grid,
    Typography,
    TextField,
    MenuItem,
} from '@material-ui/core';
import * as _ from 'lodash';

const PointListComponent = props => {
    const {
        classes,
        //currentRoute,
        isIndoorInspection,
        onhandleDeletePoint,
        pointList,
        inspectionDictionaries,
        onhandleSaveItemOrder,
        onhandleUpdateItem,
        onhandleSelectItem,
        onhandleSavePoint,
        onhandleUpdatePoint,
        userProfile,
        isRouteEditMode,
        unit,
        pointEditMode,
        onhandleChangeSeq,
        onhandleChangeIgnoreInstall,
        onhandleItemOrder,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
        return permission && permission.action === 'W'
    };

    const renderNo = (row) => {
        if (pointEditMode[row.id]) {
            return <TextField
                error={props.helperText}
                helperText={props.helperText || ''}
                // select
                value={row.seq}
                type="number"
                name='seq'
                onChange={e => onhandleChangeSeq(e.target.value, row.id)}>
                InputLabelProps={{
                    shrink: true,
                }}
                {/* {pointList.map((item, index) => {
                    let no = index + 1
                    return <MenuItem key={no} value={no}>
                        {no}
                    </MenuItem>
                })} */}
            </TextField>

        } else {
            return <Typography>{row.seq}</Typography>
        }
    }

    const renderIgnoreInstall = (row) => {
        // pointEditMode[row.id]
        return <Checkbox
            checked={row.ignoreInstall || false}
            disabled={!pointEditMode[row.id]}
            name='ignoreInstall'
            onChange={e => onhandleChangeIgnoreInstall(e.target.checked, row.id)}
        />
        // return <TextField
        //     error={props.helperText}
        //     helperText={props.helperText || ''}
        //     // select
        //     value={row.seq}
        //     type="number"
        //     name='seq'
        //     onChange={e => onhandleChangeSeq(e.target.value, row.id)}>
        //     InputLabelProps={{
        //         shrink: true,
        //     }}
        // </TextField>
    }


    const assemblePointColumns = () => {
        return [{
            title: '序号',
            field: 'no',
            align: 'left',
            //render: (row) => <Typography>{row.no}</Typography>,
            render: renderNo,
            width: '10%'
        }, {
            title: '装置',
            field: 'apparatusName',
            align: 'left',
            render: (row) => <Typography>{row.apparatusName ? row.apparatusName : ''}</Typography>,
        }, {
            title: '单元',
            field: 'unitName',
            align: 'left',
            render: (row) => <Typography>{row.unitName ? row.unitName : ''}</Typography>,
        }, {
            title: '设备组',
            field: 'deviceGroupName',
            align: 'left',
            render: (row) => <Typography>{row.deviceGroupName ? row.deviceGroupName : ''}</Typography>,
        }, {
            title: '设备编号',
            field: 'deviceNo',
            align: 'left',
            render: (row) => <Typography>{row.deviceNo ? row.deviceNo : ''}</Typography>,
        }, {
            title: '设备名称',
            field: 'deviceName',
            align: 'left',
            render: (row) => <Typography>{row.deviceName ? row.deviceName : ''}</Typography>,
        }, {
            title: '常检设备',
            field: 'ignoreInstall',
            align: 'left',
            render: renderIgnoreInstall,
        }];
    };

    const renderRowMethod = (method) => {
        let data = getMethodData()
        let item = _.filter(data, (o) => { return o.code === method })
        if (item && item.length > 0) {
            return item[0].name
        } else {
            return ''
        }
    };

    const getMethodData = () => {
        let data = _.filter(inspectionDictionaries, function (o) { return o.type === 'OCHKTYPE' })
        if (data && data.length > 0) {
            return data
        } else {
            return []
        }
    }

    const getResultType = () => {
        let data = _.filter(inspectionDictionaries, function (o) { return o.type === 'ORESULTTYPE' })
        if (data && data.length > 0) {
            return data
        } else {
            return []
        }
    }


    const renderResultType = (resultType) => {
        let data = getResultType()
        let item = _.filter(data, (o) => { return o.code === resultType })
        if (item && item.length > 0) {
            return item[0].name
        } else {
            return ''
        }
    };

    // const renderLogic = (template, index) => {
    //     if (template.indexOf('|') > -1) {
    //         let temp = template.split('|')[index];
    //         let logic = temp.split('{')[0]
    //         return logic
    //     } else {
    //         if (index === 0) {
    //             let logic = template.split('{')[0]
    //             return logic
    //         } else {
    //             return null
    //         }
    //     }
    // };

    // const renderValues = (standardValue, index) => {
    //     if (standardValue.indexOf(',') > -1) {
    //         let value = standardValue.split(',')[index];
    //         return value
    //     } else {
    //         if (index === 0) {
    //             let value = standardValue
    //             return value
    //         } else {
    //             return null
    //         }
    //     }
    // };

    const renderstandardValues = (template, standardValue, itemUnit) => {
        // const {  } = row;
        if (!template || !standardValue) {
            return '-'
        }
        let unitLabel = '';
        if (unit && unit.length > 0) {
            let value = _.find(unit, function (o) { return o.code === itemUnit })
            if (value) {
                unitLabel = ' (' + value.name + ')'
            }
        }

        let output = '';
        if (template.indexOf('|') > -1 && standardValue.indexOf(',') > -1) {

            let logicList = template.split('|');
            let logic1 = logicList[0].split('{')[0];
            let logic2 = logicList[1].split('{')[0];

            let valueList = standardValue.split(',');
            let value1 = valueList[0];
            let value2 = valueList[1];

            output = logic1 + ' ' + value1 + ' 且 ' + logic2 + ' ' + value2
        } else {
            let logic = template.split('{')[0]
            let value = standardValue
            output = logic + value
        }

        return output + unitLabel
    }

    const validateResultType = (rows) => {
        if (!rows.resultType || rows.resultType === '') {
            return { isValid: false, helperText: '结果类型不能为空' }
        }

        if (rows.paramType !== 'VAL_NUMBER' && rows.resultType === 'NUMBER') {
            return { isValid: false, helperText: '标准值类型不是数值' }
        }

        return true
    };
    // const renderUnit = (row) => {
    //     let label = '';
    //     if (unit && unit.length > 0) {
    //         let value = _.find(unit, function (o) { return o.code === row.itemUnit })
    //         if (value) {
    //             label = value.name
    //         }
    //     }
    //     return label;
    // }

    const renderValue = (props) => {
        if (props.rowData.paramId !== -1) {
            return <TextField
                error={props.helperText}
                helperText={props.helperText || ''}
                select
                value={props.rowData.resultType ? props.rowData.resultType : ''}
                name='resultType'
                onChange={e => props.onChange(e.target.value)}>
                {getResultType().map((item) => {
                    return <MenuItem key={item.code} value={item.code}>
                        {item.name}
                    </MenuItem>
                })}
            </TextField>
        } else {
            return <Typography>{renderRowMethod(props.rowData.resultType)}</Typography>
        }
    }



    const assembleItemColumns = () => {
        return [{
            title: '巡检顺序',
            field: 'showOrder',
            align: 'left',
            render: (row) => <Typography>{row.showOrder ? row.showOrder : '-'}</Typography>,
            editable: 'never',
            width: '10%'
        },
        {
            title: '检查参数',
            field: 'itemName',
            align: 'left',
            render: (row) => <Typography>{row.itemNameAlias ? row.itemNameAlias : (row.itemName ? row.itemName : '')}</Typography>,
            editable: 'never'
        },
        {
            title: '运行参考值',
            field: 'template',
            align: 'left',
            render: (row) => <Typography>{row.template ? renderstandardValues(row.template, row.standardValue, row.itemUnit) : ''}</Typography>,
            editable: 'never'
        },
        {
            title: '停机参考值',
            field: 'template',
            align: 'left',
            render: (row) => <Typography>{row.template ? renderstandardValues(row.offTemplate, row.standardOffValue, row.itemUnit) : ''}</Typography>,
            editable: 'never'
        },
        // {
        //     // title: '运行条件1',
        //     title: '条件1',
        //     field: 'template',
        //     align: 'left',
        //     render: (row) => <Typography>{row.template ? renderLogic(row.template, 0) : ''}</Typography>,
        //     editable: 'never'
        // }, 
        // {
        //     title: '标准值1',
        //     field: 'standardValue',
        //     align: 'left',
        //     render: (row) => <Typography>{row.standardValue ? renderValues(row.standardValue, 0) : ''}</Typography>,
        //     editable: 'never'
        // },
        // {
        //     // title: '运行条件2',
        //     title: '条件2',
        //     field: 'template',
        //     align: 'left',
        //     render: (row) => <Typography>{row.template ? renderLogic(row.template, 1) : ''}</Typography>,
        //     editable: 'never'
        // },
        // {
        //     title: '标准值2',
        //     field: 'standardValue',
        //     align: 'left',
        //     render: (row) => <Typography>{row.standardValue ? renderValues(row.standardValue, 1) : ''}</Typography>,
        //     editable: 'never'
        // },
        {
            title: '读数来源',
            field: 'deviceName',
            align: 'left',
            render: (row) => <Typography>{row.deviceName ? row.deviceName : ''}</Typography>,
            editable: 'never'
        },
        // {
        //     title: '参数单位',
        //     field: 'itemUnit',
        //     align: 'left',
        //     render: (row) => <Typography>{renderUnit(row)}</Typography>,
        //     editable: 'never'
        // },
        {
            title: '检查手段',
            field: 'method',
            align: 'left',
            validate: rowData => (!rowData.method || rowData.method === '') ? { isValid: false, helperText: '检查手段不能为空' } : true,
            render: (row) => <Typography>{renderRowMethod(row.method)}</Typography>,
            editComponent: (props) => (
                <TextField
                    error={props.helperText}
                    helperText={props.helperText || ''}
                    select
                    value={props.rowData.method ? props.rowData.method : ''}
                    name='method'
                    onChange={e => props.onChange(e.target.value)}>
                    {getMethodData().map((item) => {
                        return <MenuItem key={item.code} value={item.code}>
                            {item.name}
                        </MenuItem>
                    })}
                </TextField>
            )
        }, {
            title: '结果类型',
            field: 'resultType',
            align: 'left',
            validate: validateResultType,
            render: (row) => <Typography>{renderResultType(row.resultType)}</Typography>,
            editComponent: renderValue
        }, {
            title: '参数描述',
            field: 'opentext',
            align: 'left',
            render: (row) => <Typography>{row.opentext ? row.opentext : ''}</Typography>,
        }];
    };

    const assemblePointDetailPanel = () => {
        return [{
            tooltip: '展开',
            render: rowData => {
                return renderItems(rowData);
            }
        }];

    }

    const renderItems = (point) => (

        < React.Fragment >
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    onSelectionChange={(rows) => onhandleSelectItem(rows, point)}
                    columns={assembleItemColumns()}
                    data={point.tableItems}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: false,
                        selection: point.edit,
                        rowStyle: rowData => ({
                            backgroundColor: rowData.tableData.checked ? '#EEE' : '#FFF'
                        })
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    editable={(point.edit && checkPermission()) && isRouteEditMode ? {
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                resolve();
                                onhandleUpdateItem(newData)
                            }),
                    } : {}}
                    actions={!isIndoorInspection && !point.edit && checkPermission() && isRouteEditMode ? [
                        rowData => ({
                            icon: 'vertical_align_top',
                            tooltip: '置顶',
                            // hidden: !rowData.edit,
                            disabled: _.findIndex(point.tableItems, { 'itemId': rowData.itemId }) === 0,
                            onClick: (event, rowData) => {
                                onhandleItemOrder(rowData, 'top')
                            }
                        }),
                        rowData => ({
                            icon: 'arrow_upward',
                            tooltip: '上移',
                            disabled: _.findIndex(point.tableItems, { 'itemId': rowData.itemId }) === 0,
                            onClick: (event, rowData) => {
                                onhandleItemOrder(rowData, 'up')
                            }
                        }),
                        rowData => ({
                            icon: 'arrow_downward',
                            tooltip: '下移',
                            // hidden: rowData.edit,
                            disabled: _.findIndex(point.tableItems, { 'itemId': rowData.itemId }) === point.tableItems.length - 1,
                            onClick: (event, rowData) => {
                                onhandleItemOrder(rowData, 'down')
                            }
                        }),
                        rowData => ({
                            icon: 'vertical_align_bottom',
                            tooltip: '置底',
                            // hidden: !rowData.edit,
                            disabled: _.findIndex(point.tableItems, { 'itemId': rowData.itemId }) === point.tableItems.length - 1,
                            onClick: (event, rowData) => {
                                onhandleItemOrder(rowData, 'bottom')
                            }
                        }),
                    ] : []}
                >
                </MaterialTable>
            </Grid>
        </React.Fragment >
    );

    return (

        <div className={classes.root}>
            <Grid className={classes.table}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblePointColumns()}
                    data={pointList}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        paging: false,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    detailPanel={assemblePointDetailPanel()}
                    actions={checkPermission() && isRouteEditMode ? [
                        rowData => ({
                            icon: 'save',
                            tooltip: '保存',
                            hidden: !rowData.edit,
                            onClick: (event, rowData) => {
                                onhandleSavePoint(rowData)
                            }
                        }),
                        rowData => ({
                            icon: 'edit',
                            tooltip: '编辑',
                            hidden: rowData.edit,
                            onClick: (event, rowData) => {
                                onhandleUpdatePoint(rowData)
                            }
                        }),
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '删除',
                            hidden: !rowData.routeId,
                            onClick: (event, rowData) => {
                                onhandleDeletePoint(rowData)
                            }
                        }),
                        rowData => ({
                            icon: 'format_line_spacing',
                            iconProps: { color: 'error' },
                            tooltip: '保存参数排序',
                            hidden: rowData.edit || !rowData.order,
                            onClick: (event, rowData) => {
                                onhandleSaveItemOrder(rowData.tableItems)
                            }
                        }),
                    ] : []}
                >
                </MaterialTable>
            </Grid>
        </div >
    );
};
PointListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    pointList: PropTypes.array.isRequired,
    onhandleDeletePoint: PropTypes.func.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    onhandleUpdateItem: PropTypes.func.isRequired,
    onhandleSelectItem: PropTypes.func.isRequired,
    onhandleSavePoint: PropTypes.func.isRequired,
    onhandleUpdatePoint: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    isRouteEditMode: PropTypes.bool.isRequired,
    unit: PropTypes.array.isRequired,
    pointEditMode: PropTypes.object.isRequired,
    onhandleChangeSeq: PropTypes.func.isRequired,
};

export default withStyles(PointListComponent);