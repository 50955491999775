import Konva from 'konva';
// import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from '../../../../../../redux/store';
import { cleanContentMenu, createContentMenu } from '../../../../redux/modules/contentmenu';
import KonvaUtil from "../../util";
import ActionUtil from '../../actionUtil';
const konvaUtil = new KonvaUtil();
const actionUtil = new ActionUtil();


export function init(props, bx, by, id, name, stage, previewStage) {
    let layer = stage.findOne('.workLayer');
    const state = {
        color: "black",
        width: 40,
        height: 60,
        strokeWidth: 4
    };

    var dragPoints = [
        {
            x: 0,
            y: 0
        },
        {
            x: 40,
            y: 20
        },
    ];
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: id,
        zIndex: 5,
        name: "Lixinbeng",
    });

    const background = new Konva.Rect({
        x: 0,
        y: 0,
        width: state.width,
        height: state.height
    });
    group.add(background);

    const topCircle = new Konva.Circle({
        x: 20,
        y: 20,
        radius: 20,
        zindex: '6',
        stroke: 'balck',
        strokeWidth: state.strokeWidth,
        name: 'main'
    });
    group.add(topCircle);

    let leftX = Math.abs(20 - 20 * Math.cos(45));
    let leftY = Math.abs(20 + 20 * Math.sin(45));
    let rightX = Math.abs(20 + 20 * Math.cos(45));
    let rightY = Math.abs(20 + 20 * Math.sin(45));
    var bottomLine = new Konva.Line({
        points: [leftX, leftY, 0, 50, 40, 50, rightX, rightY],
        stroke: 'balck',
        strokeWidth: state.strokeWidth,
        lineCap: 'round',
        lineJoin: 'round',
    });
    group.add(bottomLine);

    var leftLine = new Konva.Line({
        points: [0, 0, 0, 20],
        stroke: 'balck',
        strokeWidth: state.strokeWidth,
        lineCap: 'round',
        lineJoin: 'round',
    });
    group.add(leftLine);

    var middleLine = new Konva.Line({
        points: [20, 20, 40, 20],
        stroke: 'balck',
        strokeWidth: state.strokeWidth,
        lineCap: 'round',
        lineJoin: 'round',
    });
    group.add(middleLine);

    const text = new Konva.Text({
        text: name,
        x: 0,
        y: 55,
        fill: state.color,
    })
    group.add(text);

    for (const dragPoint of dragPoints) {
        CreatedragPoints(props, group, layer, previewStage, dragPoint.x, dragPoint.y)
    }

    groupCommon(props, group, layer, stage, previewStage);

    layer.add(group);
    layer.batchDraw();
    konvaUtil.updatePreview(props, layer, previewStage);
}

export function mapping(props, group, layer, stage, previewStage) {
    groupCommon(props, group, layer, stage, previewStage);
}

function CreatedragPoints(props, group, layer, previewStage, x, y, radius = 2, fill = "black") {
    const circle = new Konva.Circle({
        id: uuidv4(),
        x: x,
        y: y,
        fill: fill,
        radius: radius,
        draggable: false,
        zindex: '1'
    });
    group.add(circle);
    layer.batchDraw();
}

function updateStye(e, orignalGroup, layer) {
    orignalGroup.find('Line').forEach(line => {
        line.stroke('red');
    })
    orignalGroup.find('Circle').forEach(circle => {
        if (circle.name() === 'main') {
            circle.stroke('red');
        } else {
            circle.fill('blue');
            circle.radius(6);
        }
    })
    layer.batchDraw();
}

function recoverStyle(e, orignalGroup, layer) {
    orignalGroup.find('Line').forEach(line => {
        line.stroke('black');
    })
    orignalGroup.find('Circle').forEach(circle => {
        if (circle.name() === 'main') {
            circle.stroke('black');
        } else {
            circle.fill('black');
            circle.radius(1);
        }
    })
    layer.batchDraw();
}

function groupCommon(props, group, layer, stage, previewStage) {
    const store = getStore();
    group.on('transformend', function (e) {
        console.log('transform end');
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer);
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.markChange(group.id());
    });

    group.on('dragend', function () {
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.caculateDeviceIntersectionWithAreaOrUnit(group, layer);
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer, 'drag');
    });

    group.on('mouseover', function (e) {
        document.body.style.cursor = 'pointer';
        updateStye(e, group, layer);
    })

    group.on('mouseout', function (e) {
        document.body.style.cursor = 'default';
        recoverStyle(e, group, layer);
        layer.batchDraw();
    })

    group.on('dragstart', function () {
        store.dispatch(cleanContentMenu());
    });

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let containerRect = stage.container().getBoundingClientRect();
        let top = containerRect.top + group.getClientRect().y + group.getClientRect().height / 2;
        let left = containerRect.left + group.getClientRect().x + group.getClientRect().width / 2;
        store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
    });
}



