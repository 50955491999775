import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment, formatDate } from '@/utils/datetime';
import {
    getPlans
    , addTempPlan
    , updateTempPlan
    , closeEditPlan
} from '@/redux/modules/manufacture/manufacturePlan';
import MTempPlanDetailComponent from '@/components/ManufactureManagement/ManufacturePlan/MTempPlanComponent/MTempPlanDetail';
import { createActionMessage } from '@/redux/modules/message'
import { filterAmoebaCompany } from '@/utils/constant';
import * as _ from 'lodash';


const filterService = (services, myCompanyId) => {
    return _.reject(services, s => {
        return s.valuationType === 'BUDGET' || (s.valuationType === 'FIXED' && s.serviceCompany === myCompanyId)
    })

}
class MTempPlanDetailContainer extends Component {
    static propTypes = {
        companies: PropTypes.any,
        companyId: PropTypes.number,
        plan: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            listQueryParams: props.listQueryParams,
            plan: {},
            planDtls: [],
        }
    }


    componentDidMount() {

        const { plan } = this.props

        let id = 0;
        let planDtls = _.map(plan.manufacturePlanDtls, d => {
            ++id
            return { ...d, 'id': id }
        })

        this.setState({
            plan: { ...plan },
            planDtls: planDtls,
        })
    }

    getpid = (companyId) => {
        const company = this.props.companies.find(item => {
            return item.companyId === companyId
        });
        return company.pid === null ? null : company.companyId
    }

    handleChange = (event) => {
        let planDtl = { ...this.state.planDtl };
        const service = this.props.services.find(item => {
            return item.serviceId === event.target.value
        });
        this.setState({
            dtpFlg: service.serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION ? true : false
        })
        planDtl.serviceDpts = service.serviceDpts
        planDtl['serviceId'] = service.serviceId;
        planDtl['dtlName'] = service.serviceName;
        // planDtl['product'] = service.materialName;
        planDtl['outputUnit'] = service.unit;
        this.setState({ planDtl: planDtl });
    }

    handlePlanDateChange = (value, type) => {
        let newPlan = { ...this.state.plan }
        newPlan[type] = getMoment()(value)
        this.setState({ plan: newPlan })
    }

    getCompanyId = () => {
        const company = this.props.companies.find(item => {
            return item.companyId === this.props.companyId
        });
        return company.pid === null ? null : company.companyId
    }

    handleClose = () => {
        this.props.onhandleCloseTempPlan()
    }

    handlePlanChange = (event) => {
        let newPlan = {
            ...this.state.plan,
        }
        if (event.target.name === 'companyId') {
            newPlan[event.target.name] = Number(event.target.value)
        } else {
            newPlan[event.target.name] = event.target.value
        }
        this.setState({ plan: newPlan })
    }

    handlePlanDtlChange = (row, type) => {
        let newDtls = []
        if (type === 'add') {
            newDtls = _.cloneDeep(this.state.planDtls)
            newDtls.push(row)
        } else if (type === 'delete') {
            newDtls = _.cloneDeep(this.state.planDtls)
            _.remove(newDtls, d => { return d.id === row.id })
        }
        else {
            for (let d of this.state.planDtls) {
                if (d.id === row.id) {
                    d = { ...row }
                }
                newDtls.push(d)
            }
        }
        this.setState({
            planDtls: newDtls
        })
    }

    handleSave = () => {

        const { plan, planDtls } = this.state

        if (!plan || !plan.planName) {
            return this.props.createActionMessage('warning', '请输入计划名称')
        }

        if (plan.startTime.diff(plan.endTime, 'days') > 0) {
            return this.props.createActionMessage('warning', '开始应该早于结束时间')
        }
        for (const dtl of planDtls) {
            if (dtl.editing) {
                return this.props.createActionMessage('warning', '请完成服务编辑')
            }
            if (!dtl.serviceId) {
                return this.props.createActionMessage('warning', '请选择服务')
            }
        }

        if (!planDtls || planDtls.length === 0) {
            return this.props.createActionMessage('warning', '请添加关联的服务')
        }

        let newPlan = { ...plan }
        newPlan.year = getMoment()(newPlan.startTime).year()
        newPlan.month = getMoment()(newPlan.startTime).month() + 1
        newPlan.startTime = formatDate(newPlan.startTime)
        newPlan.endTime = formatDate(newPlan.endTime)
        newPlan['manufacturePlanDtls'] = planDtls;

        if (this.state.plan.planId) { //修改
            // handleRowAdd(this.state.plan)
            this.props.handleUpdateTempPlan(newPlan.planId, newPlan).then(res => {
                const { companyId, planType, year, month } = this.state.listQueryParams
                this.props.onhandleGetPlans(companyId, planType, year, month)
                this.props.onhandleCloseTempPlan()
            })
        } else {//新增
            this.props.handleAddTempPlan(newPlan).then(res => {
                const { companyId, planType, year, month } = this.state.listQueryParams
                this.props.onhandleGetPlans(companyId, planType, year, month)
                this.props.onhandleCloseTempPlan()
            })
        }

    }



    render() {
        return (
            <div>
                <MTempPlanDetailComponent
                    openFlag={this.props.openFlag}
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    companies={this.props.companies}
                    plan={this.state.plan}
                    planDtls={this.state.planDtls}
                    services={this.props.services}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleChange={this.handlePlanChange.bind(this)}
                    onhandlePlanDtlChange={this.handlePlanDtlChange.bind(this)}
                    onhandlePlanDateChange={this.handlePlanDateChange.bind(this)}
                ></MTempPlanDetailComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        openFlag: state.manufacturePlan.tempPlanOpen,
        plan: state.manufacturePlan.editPlan,
        companies: filterAmoebaCompany(state.company.companyList.list),
        companyId: state.auth.currentUserInfo.companyId,
        services: filterService(state.manufactureService.services, state.auth.currentUserInfo.companyId),
        departments: state.department.departments.list,
        currentUserInfo: state.auth.currentUserInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleAddTempPlan: (newData) => {
            return dispatch(addTempPlan(newData));
        },
        handleUpdateTempPlan: (planId, newData) => {
            return dispatch(updateTempPlan(planId, newData));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
        onhandleCloseTempPlan: () => {
            return dispatch(closeEditPlan());
        },
        onhandleGetPlans: (companyId, planType, year, month) => {
            dispatch(getPlans(companyId, planType, year, month));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MTempPlanDetailContainer);
