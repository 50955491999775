import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api'

const initialState = {
    incomes: [], //收入明细
    materialCosts:[], //材料相关费用明细
    open: false, //open
    trackType: '',
};

export const closeTrack = createAction('@@xcloud/amoeba/report/track/close');

export const openTrack = createAction('@@xcloud/amoeba/report/track/open');

export const setIncomes = createAction('@@xcloud/amoeba/report/track/setIncomes');

export const setMaterialCosts = createAction('@@xcloud/amoeba/report/track/setMaterialCosts');

export const getIncomes = createAction('@@xcloud/amoeba/report/track/getIncomes',
    (companyId, dptIds, subIds, startDate, endDate, offset, size, sort) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/tracks/incomes'
            let strs = []
            strs.push('offset=' + offset)
            strs.push('size=' + size)
            if (companyId) strs.push('companyId=' + companyId)
            if (dptIds) strs.push('dptIds=' + dptIds)
            if (subIds) strs.push('subIds=' + subIds)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (sort) strs.push('sort=' + sort)
            url = url + '?' + strs.join('&')
            
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setIncomes(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

        export const getMaterialCosts = createAction('@@xcloud/amoeba/report/track/getMaterialCosts',
        (companyId, dptIds, subIds, startDate, endDate, offset, size, sort) =>
            (dispatch, getState, httpClient) => {
                let url = porxyApi + '/amoeba/tracks/materials'
                let strs = []
                strs.push('offset=' + offset)
                strs.push('size=' + size)
                if (companyId) strs.push('companyId=' + companyId)
                if (dptIds) strs.push('dptIds=' + dptIds)
                if (subIds) strs.push('subIds=' + subIds)
                if (startDate) strs.push('startDate=' + startDate)
                if (endDate) strs.push('endDate=' + endDate)
                if (sort) strs.push('sort=' + sort)
                url = url + '?' + strs.join('&')
                
                return httpClient.get(url).then((res) => {
                    if (res.status === 200) {
                        dispatch(setMaterialCosts(res.data))
                    }
                    return res.data;
                }).catch(err => {
                    let msg = `获取阿米巴报表失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
            });


export const amoebaReportReducer = handleActions(
    {
        [setIncomes]: (state, { payload: data }) => {
            return {
                ...state,
                incomes: data
            }
        },
        [setMaterialCosts]: (state, { payload: data }) => {
            return {
                ...state,
                materialCosts: data
            }
        },
        [openTrack]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                trackType: data
            }
        },

        [closeTrack]: (state, { payload: data }) => {
            return {
                ...state,
                open: false,
                trackType: ''
            }
        },
    },
    initialState
)

export default amoebaReportReducer
