import { withStyles } from '@material-ui/styles';


const styles = theme => ({
    title:{
        paddingBottom: theme.spacing(2),
    },
    card:{
        borderRadius:0,
        margin: theme.spacing(1),
    },
});


export default withStyles(styles);