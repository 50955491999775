import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  subTitle: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    borderLeft: '2px solid ' + theme.palette.primary.main,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    // paddingTop: theme.spacing(2),
  },
  chipContainer: {
    paddingBottom: theme.spacing(1),
  },
  gridItem: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
    width: 80,
    textAlign: 'right'
  },
  checkboxLabel: {
    paddingTop: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: 80,
    textAlign: 'right'
  },
  formContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 80px)'
  },
  required: {
    // display: 'inline-block',
    color: theme.palette.error.main,
    paddingRight: 2,
  },
  justify: {
    display: 'inline-block',
    textAlign: 'justify',
    textAlignLast: 'justify',
    width: 50,
  },
  checkboxContainer: {
    width: '100%',
    display: 'inline-flex',
    margin: theme.spacing(0.8),
  },
  textField: {
    width: '100%'
  },
  textArea: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: '24px'
  },
  subtitle: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  detail: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  spacer: {
    flexGrow: 1
  },
  divider: {
    margin: theme.spacing(0)
  },
  button: {
    marginRight: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  confirmButton: {
    marginRight: theme.spacing(1)
  },
  item: {
    padding: 0
  },
  inner: {
    minWidth: '100%'
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  select: {
    width: '50%',
    marginTop: 7
  }
});

export default withStyles(styles);
