import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExceptionBatchAcceptPanel from '../../../components/OnsiteInspectionManagement/ExceptionList/ExceptionBatchAcceptPanel';
import { closeExceptionBatchAcceptPanel, changeExceptionBatch, changeExceptions, changeUseBatchSetting } from '@/redux/modules/onsiteInspectionExceptionBatchAccept';

import _ from 'lodash';

class ExceptionBatchAcceptPanelContainer extends Component {
    static propTypes = {
        exceptionBatch: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            exceptionBatchError: {
                'handleResult': '',
                'acceptResult': '',
                'handleType': ''
            }
        };
    }

    handleConfirm() {
        const { type, exceptionBatch, useBatchSetting, exceptionList, currentUserInfo, confirmCallback, onhandleClose } = this.props;
        debugger
        if (this.handleBatchCheck(null, null) && this.handleRowsCheck()) {
            exceptionList.forEach(item => {
                // 统一处置 or 逐项处置 or 复核/确认
                // 处置人/确认人
                if (type === 'handle') {
                    // 初次处置
                    item.status = 'pending'
                    item.handler = currentUserInfo.userId
                    // 统一处置
                    if (useBatchSetting === 'true') {
                        item.handleType = exceptionBatch.handleType
                        item.handleResult = exceptionBatch.handleResult
                    }
                } else if (type === 'confirm') {
                    // 确认
                    item.status = 'active'
                    item.accpeter = currentUserInfo.userId
                    // 统一确认
                    if (useBatchSetting === 'true') {
                        item.acceptResult = exceptionBatch.acceptResult
                    }
                }
            })
            if (confirmCallback) confirmCallback(exceptionList)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChangeUseBatchSetting(event) {
        const { onhandleChangeUseBatchSetting } = this.props
        // const field = event.target.name;
        const value = event.target.value;
        onhandleChangeUseBatchSetting(value);

        // setTimeout(() => {
        //     this.handleBatchCheck(null, field);
        // }, 100)
    }

    handleChangeExceptionBatch(event) {
        const { onhandleChangeExceptionBatch, exceptionBatch } = this.props
        let newEntity = {
            ...exceptionBatch
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChangeExceptionBatch(newEntity);

        setTimeout(() => {
            this.handleBatchCheck(null, field);
        }, 100)
    }

    handleRowChange = (event, row) => {
        const {
            exceptionList,
            onhandleChangeExceptionList
        } = this.props;
        const field = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);
        exceptionList.forEach(item => {
            if (row.listId === item.listId) {
                item[field] = fieldValue;
            }
        })
        onhandleChangeExceptionList([...exceptionList]);
    }

    handleBatchCheck = (event, field) => {

        const {
            type,
            useBatchSetting,
            exceptionBatch
        } = this.props;

        let err = {};

        if (!field || field === 'handleType') {
            if (!(useBatchSetting === 'false' || type === 'confirm') && _.trim(exceptionBatch.handleType) === '') {
                err['handleType'] = '请选择异常处置类型';
            } else {
                err['handleType'] = '';
            }
        }

        if (!field || field === 'handleResult') {
            if (!(useBatchSetting === 'false' || type === 'confirm') && _.trim(exceptionBatch.handleResult) === '') {
                err['handleResult'] = '请填写处置说明';
            } else {
                err['handleResult'] = '';
            }
        }

        let newError = {
            ...this.state.exceptionBatchError,
            ...err
        }
        this.setState({
            exceptionBatchError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    }

    handleRowsCheck = () => {
        const {
            exceptionList,
            useBatchSetting,
            onhandleChangeExceptionList
        } = this.props;

        let hasErr = false;
        exceptionList.forEach((item) => {
            const {
                handleResult,
                handleType,
            } = item;
            if (useBatchSetting === 'false') {

                if (handleResult === null || handleResult === '') {
                    item.handleResultError = '未填写';
                    hasErr = true;
                } else {
                    item.handleResultError = '';
                }

                if (handleType === null || handleType === '') {
                    item.handleTypeError = '未填写';
                    hasErr = true;
                } else {
                    item.handleTypeError = '';
                }

            } else {
                item.handleTypeError = '';
                item.handleResultError = '';
            }
        })
        onhandleChangeExceptionList([...exceptionList]);
        return !hasErr;
    }

    render() {
        return (
            <ExceptionBatchAcceptPanel
                open={this.props.open}
                type={this.props.type}
                useBatchSetting={this.props.useBatchSetting}
                exceptionBatch={this.props.exceptionBatch}
                exceptionBatchError={this.state.exceptionBatchError}
                exceptionList={this.props.exceptionList}
                inspectionDictionaries={this.props.inspectionDictionaries}

                onhandleChangeUseBatchSetting={this.handleChangeUseBatchSetting.bind(this)}
                onhandleChangeExceptionBatch={this.handleChangeExceptionBatch.bind(this)}
                onhandleBatchCheck={this.handleBatchCheck.bind(this)}
                onhandleRowChange={this.handleRowChange.bind(this)}
                // onhandleRowCheck={this.handleRowCheck.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.onsiteInspectionExceptionBatchAccept.open,
        currentUserInfo: state.auth.currentUserInfo,
        exceptionList: state.onsiteInspectionExceptionBatchAccept.exceptionList,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        useBatchSetting: state.onsiteInspectionExceptionBatchAccept.useBatchSetting,
        exceptionBatch: state.onsiteInspectionExceptionBatchAccept.exceptionBatch,
        type: state.onsiteInspectionExceptionBatchAccept.type,

        confirmCallback: state.onsiteInspectionExceptionBatchAccept.confirmCallback,
        closeCallback: state.onsiteInspectionExceptionBatchAccept.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChangeExceptionBatch: (data) => {
            dispatch(changeExceptionBatch(data));
        },
        onhandleChangeExceptionList: (exceptions) => {
            dispatch(changeExceptions(exceptions));
        },
        onhandleChangeUseBatchSetting: (flag) => {
            dispatch(changeUseBatchSetting(flag));
        },
        onhandleClose: () => {
            dispatch(closeExceptionBatchAcceptPanel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExceptionBatchAcceptPanelContainer);