import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegulationPlanList from "../../../components/LeadershipManagement/RegulationPlanList";
import PropTypes from "prop-types";
import { 
    initCreate, 
    getPlans, 
    getPlanDtl,
    addPlans,
    updatePlans, 
    getAnnuPlan//getProcessByRoleId
} from '../../../redux/modules/leadershipRegulationPlan';
import { 
    getProcessByRoleId
} from '../../../redux/modules/process';
import * as _ from 'lodash';

class RegulationPlanListContainer extends Component {
    static propTypes = {
        plans: PropTypes.array,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        let roleId = this.props.currentUserInfo.roleId;
        this.props.onhandleStartProcess(roleId);
        this.props.onhandleGetPlans();
        let unitId = this.props.userProfile.roles[0].units[0].unitId;
        let processCode = 'RegulationPlan_' + unitId;
        this.props.onhandleGetAnnuPlan(processCode);
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    detailedInfo(event, planId) {
        this.props.onhandleOpen(planId);
    }

    handleNewPlan(planId, newYear) {
        const {
            userProfile,
            onhandleSavePlan,
            onhandleAddPlan
        } = this.props
        let oldPlan = {
            planId: planId,
            status: 'finalized'
        }
        let unitName = userProfile.roles[0].units[0].unitName
        let unitId = this.props.userProfile.roles[0].units[0].unitId;
        let processCode = 'RegulationPlan_' + unitId;
        onhandleSavePlan(oldPlan, (callback)=>{
            let newPlan = {
                planName: newYear + "年度规章制度完善计划-" + unitName,
                planType: "ANNU",
                planCode: processCode,
                year: newYear,
                planId: 0
            }
            onhandleAddPlan(newPlan)
        }) 
    }

    handleNewPlanF(year) {
        const {
            userProfile,
            onhandleAddPlan
        } = this.props
        let unitName = userProfile.roles[0].units[0].unitName
        let unitId = this.props.userProfile.roles[0].units[0].unitId;
        let processCode = 'RegulationPlan_' + unitId;
        let newPlan = {
            planName: year + "年度规章制度完善计划-" + unitName,
            planType: "ANNU",
            planCode: processCode,
            year: year,
            planId: 0
        }
        onhandleAddPlan(newPlan)
    }

    render() {
        let unitId = this.props.userProfile.roles[0].units[0].unitId;
        let processCode = 'RegulationPlan_' + unitId;
        let createPermission = _.find(this.props.startProcesses, plan => { return plan.processCode === processCode })
        createPermission = createPermission ? true : false
        const createPermissionR = createPermission
        if(createPermission){
            if(this.props.plans || this.props.plans.length !== 0){
                let oldPlan = _.filter(this.props.plans,(plan)=>{
                    return plan.planType === 'ANNU'
                })
                if(oldPlan && oldPlan.length !== 0){
                    createPermission = false
                }
            }
        }
        return (
            <RegulationPlanList
                createPermission={createPermission}
                createPermissionR={createPermissionR}
                plans={this.props.plans}
                annuPlan={this.props.annuPlan}
                onDetailedInfo={this.detailedInfo.bind(this)}
                getPlans={this.props.onhandleGetPlans.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                constants={this.props.constants}
                userProfile={this.props.userProfile}
                onhandleNewPlan={this.handleNewPlan.bind(this)}
                onhandleNewPlanF={this.handleNewPlanF.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        plans: state.leadershipRegulationPlan.plans,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        startProcesses: state.process.startProcesses,
        annuPlan: state.leadershipRegulationPlan.annuPlan,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSavePlan: (plan, callback) => {
            dispatch(updatePlans(plan, callback))
        },
        onhandleAddPlan: (plan) => {
            dispatch(addPlans(plan))
        },
        onhandleGetPlans: () => {
            dispatch(getPlans('','',2));
        },
        onhandleGetAnnuPlan: (planCode) => {
            dispatch(getAnnuPlan(planCode));
        },
        onhandleOpen: (planId) => {
            dispatch(getPlanDtl(planId));
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleStartProcess: (roleId) => {
            dispatch(getProcessByRoleId(roleId,1))
        }
        //
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanListContainer)