import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(0),
        // padding: 20
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    title: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formGridContainer: {

    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    // detailtable: {
    //     // padding: theme.spacing(4)
    // },
    materialtable: {
        boxShadow: 'none'
    },
    pic1: {
        width: 20,
        height: 20,
        margin: 0,
        backgroundColor: colors.red[300],
        fontSize: '12px',
    },
    pic2: {
        width: 20,
        height: 20,
        margin: 0,
        backgroundColor: colors.indigo[300],
        fontSize: '12px',
    },
    pic3: {
        width: 20,
        height: 20,
        margin: 0,
        backgroundColor: colors.orange[300],
        fontSize: '12px',
    },
    // tabs: {
    //     height: 36,
    // },
    // tab: {
    //     minHeight: 36,
    //     fontSize: '12px'
    // },
    tabs: {
        minHeight: theme.spacing(4),
        borderBottom: '1px solid #e7e7e7',
        color: '#2979ff',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tab: {
        fontSize: 14,
        minWidth: 'auto',
        minHeight: theme.spacing(4),
        padding: '6px 18px',
    },
    flexgrow: {
        flexGrow: 1
    },
    hidden: {
        display: 'none'
    },
    /* 异常的状态强调显示 */
    active: {
        width: 60,
        backgroundColor: colors.green[400],
        color: theme.palette.white,
    },
    new: {
        width: 60,
        backgroundColor: colors.red[400],
        color: theme.palette.white,
    },
    assigned: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    pending: {
        width: 60,
        backgroundColor: colors.orange[400],
        color: theme.palette.white,
    },
    default: {
        width: 60,
        backgroundColor: colors.grey[300],
        color: theme.palette.white,
    },
});

export default withStyles(styles);