import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { Table } from 'antd';
import { getDateByTimestamp, getDateByString } from '@/utils/datetime';
import { Visibility } from '@material-ui/icons';


const DeviceOpsRecord = props => {
    const {
        classes,
        opsRecords,
        tabValue,
        index,
        onhandlPreviewDoc,
    } = props;


    const renderTime = (time) => {
        return time ? getDateByTimestamp(time, true) : null;
    }

    const renderActions = (row) => {
        if (row && row.planDocs && row.planDocs.length > 0) {
            if (row.planDocs[0].docName && row.planDocs[0].docLink) {
                return <Grid container>
                    <Grid item>
                        <Tooltip title='预览'><IconButton variant='text' onClick={() => onhandlPreviewDoc(row.planDocs)}> <Visibility /></IconButton></Tooltip></Grid>
                </Grid>
            }
        }
        return <Grid></Grid>

    }

    const assemblyRelationOpsRecordColumns = () => {

        let columns = [
            {
                title: '工单号',
                dataIndex: 'orderNo',
                render: (text, row, index) => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never'
            },

            {
                title: '运维预算(元)',
                align: 'right',
                field: 'orderBudget',
                render: (text, row, index) => row && row.orderBudget ? <Typography>{row.orderBudget}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '运维决算(元)',
                align: 'right',
                field: 'orderCost',
                render: (text, row, index) => row && row.orderCost ? <Typography>{row.orderCost}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '开始时间',
                field: 'startTime',
                render: (text, row, index) => row ? <Typography>{renderTime(row.startTime)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '结束时间',
                field: 'endTime',
                render: (text, row, index) => row ? <Typography>{renderTime(row.endTime)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '工单内容',
                field: 'content',
                render: (text, row, index) => row ? <Typography>{row.content}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维内容',
                field: 'opentext',
                render: (text, row, index) => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '故障原因',
                field: 'opentext1',
                render: (text, row, index) => row ? <Typography>{row.opentext1}</Typography> : '',
                editable: 'never'
            },
            {
                title: '避免措施',
                field: 'opentext2',
                render: (text, row, index) => row ? <Typography>{row.opentext2}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检测结果',
                field: 'executor',
                render: (text, row, index) => row && row.maintainResult ?
                    <Tooltip title={getMaintainResDate(row)}>
                        <Typography>{getMaintainResult(row.maintainResult)}</Typography>
                    </Tooltip >
                    :
                    '-',
                editable: 'never'
            },
            {
                title: '承包商',
                field: 'executor',
                render: (text, row, index) => row && row.executor ? <Typography>{row.executor}</Typography> : '-',
                editable: 'never'
            }, {
                title: '',
                dataIndex: 'actions',
                width: 50,
                render: (text, row, index) => renderActions(row),
            }

        ]

        return columns
    }

    const getMaintainResult = (maintainResult) => {
        if (maintainResult === "qualified") {
            return "合格"
        }
        if (maintainResult === "unqualified") {
            return "不合格"
        }
    }

    const getMaintainResDate = ({ maintainDate, nextMaintainDate }) => {
        if (!maintainDate && !nextMaintainDate) {
            return '长期有效';
        }
        if (maintainDate && nextMaintainDate) {
            return getDateByString(maintainDate) + ' 至 ' + getDateByString(nextMaintainDate) + ' 有效';
        }
        if (!maintainDate && nextMaintainDate) {
            return '结果于' + getDateByString(maintainDate) + '检测';
        }
        if (!maintainDate && nextMaintainDate) {
            return '结果于' + getDateByString(maintainDate) + '失效';
        }
    }



    return (
        <div alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <Table
                        className={opsRecords.list?.length > 0 ? classes.stripedTable : classes.normalTable}
                        rowKey={'orderNo'}
                        columns={assemblyRelationOpsRecordColumns()}
                        dataSource={opsRecords.list ? opsRecords.list : []}
                        size={'small'}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '20', '50'],
                        }}
                    />
                </Grid>
            </Grid>
        </div >
    );
};


DeviceOpsRecord.propTypes = {
    classes: PropTypes.object.isRequired,
    opsRecords: PropTypes.object.isRequired,

};

export default withStyles(DeviceOpsRecord);
