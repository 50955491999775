import React, { Component } from 'react';
import { connect } from 'react-redux';
import JobList from "../../../components/SystemManagement/JobManagement/JobList";
import PropTypes from "prop-types";
import { getJobs, initUpdate, deleteJob, initCreate, changeCompany, setPageSize } from '../../../redux/modules/job';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { findPath } from '../../../utils/constant';

class JobListContainer extends Component {
    static propTypes = {
        jobs: PropTypes.object,
        pageSize: PropTypes.number
    };

    componentDidMount() {
        this.props.onhandleGetJobList({
            offset: 0
        });
    }

    handleSelectionChange(value) {
        if (!value) {
            this.props.onhandleSetCompanySelection(undefined);
            return;
        }
        let path = findPath(value.value, this.props.companyTree)
            .map(i => i.label)
            .reverse()
            .join(' > ');
        this.props.onhandleSetCompanySelection({
            label: path,
            value: value.value
        });
    }

    getJobs(query) {
        this.props.onhandleGetJobList(query);
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    handleDelete(event, id, name) {
        let doDelete = () => {
            this.props.onhandleDelete(id, name);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除职务[' + name + ']吗?', doDelete, null, false);
    }

    handleEdit(event, id) {
        this.props.onhandleOpen(id);
    }

    render() {
        return (
            <JobList
                jobs={this.props.jobs}
                onDelete={this.handleDelete.bind(this)}
                onEdit={this.handleEdit.bind(this)}
                getJobList={this.getJobs.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                constants={this.props.constants}
                userProfile={this.props.userProfile}
                companyTree={this.props.companyTree}
                companySelection={this.props.companySelection}
                onhandleSelectionChange={this.handleSelectionChange.bind(this)}
                pageSize={this.props.pageSize}
                setPageSize={this.props.onSetPageSize.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        jobs: state.job.jobs,
        companyTree: state.constant.companyTree,
        companySelection: state.job.companySelection,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile,
        pageSize: state.job.pageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetJobList: (query) => {
            dispatch(getJobs(query))
        },
        onhandleDelete: (id, name) => {
            dispatch(deleteJob(id, name));
        },
        onhandleOpen: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        },
        onhandleSetCompanySelection: (selection) => {
            dispatch(changeCompany(selection))
        },
        onSetPageSize: (num) => {
            dispatch(setPageSize(num))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobListContainer)