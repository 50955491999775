import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { createloading, cleanloading } from './loading';
import { getDeviceUseStatus, getDevices, getDeviceLegends, getDeviceMedium } from './devices';
import { getCompanyId } from "../../utils/userUtils";
import { getDeviceCategoryTree } from './trees';
import { push } from "connected-react-router";
// import { createmodal } from './modal';
import { initDeviceTreeData } from '../../device/src/redux/modules/deviceTree'
import * as _ from 'lodash';
const porxyApi = '/api'

const initialState = {
    isloading: false,
    initListData: {
        total: 0,
        list: []
    },
    editInstallation: {
        units: [],
        areas: []
    },
    editInstallationError: {},
    departmentList: [],
    installationChange: false,
    pageSize: 5,
    installationList: {
        total: 0,
        list: []
    },
    installationUnitList: [],
    installationAreaList: [],
    installationUserList: [],
    editUnits: [],  //编辑装置units
    editAreas: [],  //编辑装置Areas
}

// Actions
//---------Start-------------------
export const startLoading = createAction('@@xcloud/installation/startLoading');

export const stopLoading = createAction('@@xcloud/installation/stopLoading');

export const startProcessing = createAction('@@xcloud/installation/startProcessing');

export const stopProcessing = createAction('@@xcloud/installation/stopProcessing');

export const updateEditInstallation = createAction(
    '@@xcloud/installation/updateEditInstallation'
);
export const initInstallationDetail = createAction(
    '@@xcloud/installation/initInstallationDetail',
    () => (dispatch, getState) => {

        dispatch(startLoading());
        Promise.all([
            dispatch(initEditInstallation()),
        ]).then(
            () => {
                dispatch(stopLoading());
            }
        )
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '初始化失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            });

    }
);

export const initEditInstallation = createAction(
    '@@xcloud/installation/initEditInstallation',
    (id, data) => (dispatch, getState, httpCliene) => {

        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        let newInstallation = (id === 'create') ? {
            "installCode": '', //装置编号
            "installName": '', //装置名称
            "enName": '', //装置英文名
            "productivity": 0, //设计生产能力
            "staffNo": 0, //定员
            "companyId": companyId ? companyId : '', // 公司id
            "installNameOrigin": ''
        } : { ...data, installNameOrigin: _.cloneDeep(data.installName) };

        let installatioError = {
            installCode: '',
            installName: '',
            productivity: '',
            staffNo: '',
            companyId: '',
        };
        dispatch(setInstallationChange(false));
        return { data: newInstallation, error: installatioError }
    }
);

export const createInstallation = createAction(
    '@@xcloud/installation/createInstallation',
    (entity) => (dispatch, getState, httpClient) => {
        //var tenantId = getState().auth.loginSelectedTenant.tenantId;
        dispatch(startLoading());
        return httpClient
            .post(porxyApi + `/installs`, entity, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    return Promise.all([
                        dispatch(createDefaultInstallationStoreys(res.data.installId)),
                    ]).then(
                        (data) => {
                            dispatch(setInstallationChange(false));
                            dispatch(createMessage('success', '新建装置成功'));
                            dispatch(push('/installationManagement'));
                        }
                    ).catch(err => {
                        let msg = `新建装置失败`;
                        dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    })
                }
            })
            .catch(err => {
                let msg = `新建装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    }
);

export const createDefaultInstallationStoreys = createAction(
    '@@xcloud/installation/createDefaultInstallationStoreys',
    (id) => (dispatch, getState, httpClient) => {
        let dto = {
            "installId": id,
            "storeyName": '第一层',
            "altitudeDatum": 0,
            "data2d": JSON.stringify({ "attrs": { "name": "workLayer" }, "className": "Layer", "children": [] })
        }
        return httpClient
            .post(porxyApi + `/installs/storeys`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `新建装置层失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);


export const getInstallationUnits = createAction(
    '@@xcloud/installation/getInstallationUnits',
    (installId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/installs/units?installId=${installId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setEditUnits(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取装置单元失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);

export const createInstallationUnit = createAction(
    '@@xcloud/installation/createInstallationUnit',
    (unitDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "unitName": unitDto.unitName,
            "installId": unitDto.installId,
        }
        return httpClient
            .post(porxyApi + `/installs/units`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `新建装置单元失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);

export const updInstallationUnit = createAction(
    '@@xcloud/installation/updInstallationUnit',
    (unitDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "unitId": unitDto.unitId,
            "tenantId": unitDto.tenantId,
        }

        if (unitDto.unitName) {
            dto['unitName'] = unitDto.unitName
        }
        if (unitDto.installId) {
            dto['installId'] = unitDto.installId
        }
        if (unitDto.status) {
            dto['status'] = unitDto.status
        }
        if (unitDto.data2d) {
            dto['data2d'] = unitDto.data2d
        }

        return httpClient
            .patch(porxyApi + `/installs/units`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新装置单元失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);


export const getInstallationAreas = createAction(
    '@@xcloud/installation/getInstallationAreas',
    (installId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/installs/workareas?installId=${installId}`)
            .then((res) => {
                if (res.data) {
                    dispatch(setEditAreas(res.data))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取装置区域失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);

export const createInstallationArea = createAction(
    '@@xcloud/installation/createInstallationArea',
    (areaDto) => (dispatch, getState, httpClient) => {
        let dto = {
            "areaName": areaDto.areaName,
            "installId": areaDto.installId,
        }
        if (areaDto.parentArea) {
            dto['parentArea'] = areaDto.parentArea
        }
        return httpClient
            .post(porxyApi + `/installs/workareas`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `新建装置区域失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);

export const updInstallationArea = createAction(
    '@@xcloud/installation/updInstallationArea',
    (areaDto) => (dispatch, getState, httpClient) => {

        let dto = {
            "areaId": areaDto.areaId,
            "tenantId": areaDto.tenantId,
        }

        if (areaDto.areaName) {
            dto['areaName'] = areaDto.areaName
        }
        if (areaDto.installId) {
            dto['installId'] = areaDto.installId
        }
        if (areaDto.status) {
            dto['status'] = areaDto.status
        }
        if (areaDto.data2d) {
            dto['data2d'] = areaDto.data2d
        }
        if (areaDto.parentArea) {
            dto['parentArea'] = areaDto.parentArea
        }

        return httpClient
            .patch(porxyApi + `/installs/workareas`, dto)
            .then((res) => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新装置区域失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            })
    }
);

export const createInstallationStoreys = createAction(
    '@@xcloud/installation/createInstallationStoreys',
    (id, name, datum) => (dispatch, getState, httpClient) => {
        var newEditInstallation = _.cloneDeep(getState().installation.editInstallation);
        let dto = {
            "installId": id,
            "storeyName": name,
            "altitudeDatum": datum,
            "data2d": JSON.stringify({ "attrs": { "name": "workLayer" }, "className": "Layer", "children": [{ "attrs": {}, "className": "Group", "children": [] }, { "attrs": {}, "className": "Group", "children": [] }] })
        }
        return httpClient
            .post(porxyApi + `/installs/storeys`, dto)
            .then((res) => {
                if (res.data) {
                    newEditInstallation.storeys = _.concat(newEditInstallation.storeys, res.data)
                    return newEditInstallation;
                }
            })
            .catch(err => {
                let msg = `新建装置层失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return newEditInstallation;
            })
    }
);

export const updateInstallation = createAction(
    '@@xcloud/installation/updateInstallation',
    (entity) => (dispatch, getState, httpClient) => {
        let dto = {
            "installId": entity.installId,
            "installCode": entity.installCode,
            "installName": entity.installName,
            "enName": entity.enName,
            "productivity": entity.productivity,
            "staffNo": entity.staffNo,
            "companyId": entity.companyId,
            "tenantId": entity.tenantId,
            "dpts": entity.dpts
        }
        return httpClient
            .patch(porxyApi + `/installs`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(setInstallationChange(false));
                    dispatch(createMessage('success', '更新装置成功'));
                    dispatch(push('/installationManagement'));
                }
            })
            .catch(err => {
                let msg = `更新装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    }
);

export const updateInstallationStorey = createAction(
    '@@xcloud/installation/updateInstallationStorey',
    (entity) => (dispatch, getState, httpClient) => {
        let dto = {
            "altitudeDatum": entity.altitudeDatum,
            "data2d": entity.data2d,
            "installId": entity.installId,
            "status": entity.status,
            "storeyId": entity.storeyId,
            "storeyName": entity.storeyName,
            "tenantId": entity.tenantId
        }
        return httpClient
            .patch(porxyApi + `/installs/storeys`, dto)
            .then((res) => {
                if (res.data) {
                    dispatch(createMessage('success', '更新装置层成功'));
                    return res.data
                }
            })
            .catch(err => {
                let msg = `更新装置层失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    }
);

export const getInstallationList = createAction(
    '@@xcloud/installation/getInstallationList',
    (query) => (dispatch, getState, httpClient) => {
        let pageSize = getState().installation.pageSize
        let url = "/installs"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-company_id,-update_time"
        }
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(setInstallationList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);


export const getInstallations = createAction(
    '@@xcloud/installation/getInstallations',
    (query) => (dispatch, getState, httpClient) => {
        let pageSize = getState().installation.pageSize
        let url = "/installs"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setInstallationList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getInstallation = createAction(
    '@@xcloud/installation/getInstallation',
    (installId) => (dispatch, getState, httpClient) => {
        return httpClient
            .get(porxyApi + `/installs/${installId}`)
            .then((res) => {
                if (res) {
                    dispatch(setInstallationUnitList(res.data.units))
                    dispatch(setInstallationAreaList(res.data.areas))
                    return res.data;
                }
                return []
            })
            .catch(err => {
                let msg = `查询装置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const routeToInstallationInfo = createAction(
    '@@xcloud/installation/routeToInstallationInfo',
    (id) => (dispatch) => {
        dispatch(push(`/installationManagement/info/${id}`))
    }
);

export const initInstallationInfo = createAction(
    '@@xcloud/installation/initInstallationInfo',
    (instId) => (dispatch, getState) => {
        dispatch(createloading('logic'))
        return Promise.all([
            dispatch(initInstallationCommon(instId)),
        ]).then(
            (data) => {
                dispatch(cleanloading());
            }
        ).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化装置信息失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
        })
    }
);


export const initInstallationCommon = createAction(
    '@@xcloud/installation/initInstallationCommon',
    (instId) => (dispatch) => {
        return Promise.all([
            dispatch(getInstallation(instId)),
        ]).then(
            (data) => {
                Promise.all([
                    dispatch(initEditInstallation(instId, data[0].payload)),
                    dispatch(getDeviceUseStatus()),
                    dispatch(getDevices(0, 0, "", "", "", "", "", "",
                        "", "", instId, "", "", "", "", ""))
                ]).then(
                    (data) => {
                        return data;
                    }
                )
            }
        ).catch(err => {
        })
    }
);

export const initInstallationInfoForCreate = createAction(
    '@@xcloud/installation/initInstallationInfoForCreate',
    (instId) => (dispatch) => {
        dispatch(createloading('logic'));
        return Promise.all([
            dispatch(initEditInstallation(instId, null)),
        ]).then(
            () => {
                dispatch(cleanloading());
            }
        ).catch(err => {
            dispatch(cleanloading());
            let msg = `初始化装置创建页失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
        })
    }
);



export const routeToInstallation3D = createAction(
    '@@xcloud/installation/routeToInstallation3D',
    (instId) => (dispatch) => {
        // dispatch(createmodal('threed', {}, 'medium'))
    }
);
export const routeToInstallationMap = createAction(
    '@@xcloud/installation/routeToInstallationMap',
    (instId) => (dispatch) => {
        dispatch(push(`/installationManagement/map/${instId}`))
    }
);
export const routeToInstallationMapView = createAction(
    '@@xcloud/installation/routeToInstallationMapView',
    (instId) => (dispatch) => {
        dispatch(push(`/installationManagement/mapview/${instId}`))
    }
);
export const routeToInstallationList = createAction(
    '@@xcloud/installation/routeToInstallationList',
    (instId) => (dispatch) => {
        dispatch(push('/installationManagement'))
    }
);
export const initInstallationMapEdit = createAction(
    '@@xcloud/installation/initInstallationMapEdit',
    (instId) => (dispatch, getState) => {
        dispatch(createloading('logic'))
        return Promise.all([
            dispatch(initInstallationCommon(instId)),
            dispatch(getDeviceLegends()),
            dispatch(getDeviceCategoryTree()),
            dispatch(getDeviceMedium())
        ]).then(
            (data) => {
                return Promise.all([
                    dispatch(initDeviceTreeData())
                ]).then(
                    (data1) => {
                        dispatch(cleanloading());
                    }
                ).catch(err => {
                    dispatch(cleanloading());
                    let msg = '装置图编辑初始化失败';
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                })
            }
        ).catch(err => {
            dispatch(cleanloading());
            let msg = '装置图编辑初始化失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
        })
    }
);

export const getInstallationUserList = createAction('@@xcloud/amoeba/getInstallationUserList',
    (installId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/installs/' + installId + '/users'
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                dispatch(setInstallationUserList(res.data))
            }
            return res.data;
        }).catch(err => {
            let msg = `获取装置用户列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addAndUpdateInstallationUserList = createAction('@@xcloud/amoeba/getInstallationUserList',
    (installId, dtos) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/installs/' + installId + '/users'
        return httpClient.patch(url, dtos).then((res) => {
            if (res.status === 200) {
                dispatch(setInstallationUserList(res.data))
                dispatch(createhttpMessage(res, "更新装置用户成功"));
            }
            return res.data;
        }).catch(err => {
            let msg = `更新装置用户列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });


export const setEditInstallationError = createAction(
    '@@xcloud/installation/setEditInstallationError'
);

export const setInstallationChange = createAction('@@xcloud/installation/setInstallationChange');

export const setInstallationList = createAction('@@xcloud/installation/setInstallationList');

export const setInstallationUnitList = createAction('@@xcloud/installation/setInstallationUnitList');

export const setInstallationAreaList = createAction('@@xcloud/installation/setInstallationAreaList');

export const setDepartmentList = createAction('@@xcloud/installation/setDepartmentList');

export const setPageSize = createAction('@@xcloud/installation/setPageSize');

export const setInstallationUserList = createAction('@@xcloud/installation/setInstallationUserList');

export const setEditUnits = createAction('@@xcloud/installation/setEditUnits');

export const setEditAreas = createAction('@@xcloud/installation/setEditAreas');

export const installaionReducer = handleActions(
    {
        [startLoading]: (state) => {
            return {
                ...state,
                isloading: true
            };
        },
        [stopLoading]: (state) => {
            return {
                ...state,
                isloading: false
            };
        },
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [updateEditInstallation]: (state, { payload: editInstallation }) => {
            return {
                ...state,
                editInstallation: editInstallation
            };
        },
        [createInstallationStoreys]: (state, { payload: editInstallation }) => {
            return {
                ...state,
                editInstallation: editInstallation
            };
        },
        [initEditInstallation]: (state, { payload: datas }) => {
            return {
                ...state,
                editInstallation: datas.data,
                editInstallationError: datas.error
            };
        },
        [setEditInstallationError]: (state, { payload: value }) => {
            return {
                ...state,
                editInstallationError: value
            };
        },
        [setInstallationChange]: (state, { payload: value }) => {
            return {
                ...state,
                installationChange: value
            };
        },
        [setDepartmentList]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
        [setInstallationList]: (state, { payload: value }) => {
            return {
                ...state,
                installationList: value
            };
        },
        [setInstallationUnitList]: (state, { payload: value }) => {
            return {
                ...state,
                installationUnitList: value
            };
        },
        [setInstallationAreaList]: (state, { payload: value }) => {
            return {
                ...state,
                installationAreaList: value
            };
        },
        [getInstallationList]: (state, { payload: value }) => {
            return {
                ...state,
                initListData: value
            };
        },
        [setInstallationUserList]: (state, { payload: value }) => {
            return {
                ...state,
                installationUserList: value
            };
        },
        [setEditAreas]: (state, { payload: value }) => {
            return {
                ...state,
                editAreas: value
            };
        },
        [setEditUnits]: (state, { payload: value }) => {
            return {
                ...state,
                editUnits: value
            };
        },
    },

    initialState
);

export default installaionReducer
