import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const OperationPermitUserPannel = ({
    classes,
    open,
    permitUserError,
    permitUser,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck
}) => {
    // console.log('permitUser comp')
    // console.log(permitUser)

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"驳回确认"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"驳回理由"}
                                variant='outlined'
                                error={permitUserError.opentext !== ''}
                                helperText={permitUserError.opentext}
                                className={classes.textField}
                                value={permitUser.opentext || ''}
                                rows={7}
                                name='opentext'
                                onChange={event => onhandleChange(event)}
                                onBlur={event => onhandleCheck(event)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        确定</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
OperationPermitUserPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    permitUserError: PropTypes.object.isRequired,
    permitUser: PropTypes.object.isRequired
};

OperationPermitUserPannel.defaultProps = {

}

export default withStyles(OperationPermitUserPannel);