import { withStyles } from '@material-ui/styles';


const styles = theme => ({

    grid: {
        width: 960,
        
        // maxWidth: '800',
        // minWidth: '800'
    },
    paper: {
        borderRadius:0,
        padding: theme.spacing(2),
    },

    title:{
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },

    imagecard: {
        paddingTop: theme.spacing(2),
        maxHeight: 400,
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
    },

    contentgrid: {
        paddingTop: theme.spacing(2),
    }

});


export default withStyles(styles);