import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'
import { getInstallationList } from '@/redux/modules/installation';
import * as _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    selectedInstallation: 0,
    selectedOffice: 0,
    selectedService: 0,
    selectedDpt: 0,
    selectedSharer: 0,
    costSharinglist: [],
    sharingTakelist: [],
    sharingTakeHistories: [],
    users: [],
    otherAssets: [],
    resetSharingParam: [],
    openInstallationUser: false,
};

export const getUsers = createAction('@@xcloud/onsiteInspectionPlan/getUsers',
    (companyId) => (dispatch, getState, httpClient) => {
        let url = `/api/users?offset=0&size=0&status=active`;
        if (companyId) {
            url = url + `&companyId=${companyId}`
        }
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setUsers(res.data))
                    return res.data
                }
                return res;
            }).catch(err => {
                let msg = '人员列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            });
    });

export const initInstallSharing = createAction('@@xcloud/amoebaCostSharing/initInstallSharing',
    (companyId) => (dispatch, getState) => {
        dispatch(setSharingTakelist([]));
        dispatch(setSelectedInstallation(0));
        Promise.all([
            dispatch(getInstallationList({ offset: 0, size: 0 })),
            dispatch(getCostSharingList()),
            dispatch(getOtherAssets(companyId)),
        ]).then((data) => {
            let olist = getState().amoebaCostSharing.costSharinglist;
            if (olist) {
                let newList = _.filter(olist, (o) => {
                    return o['shareType'] === 'SHARING_INSTALL'
                        || o['shareType'] === 'SHARING_OTHER'
                })
                dispatch(setCostSharinglist(newList))
            }
            return data
        }).catch(err => {
            console.log(err)
            let msg = '初始化装置折旧分摊页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getOtherAssets = createAction('@@xcloud/amoebaCostSharing/getOtherAssets',
    (cid) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/depreciationrules?status=active&target=OTHER'
        if (cid) {
            url = url + '&companyId=' + cid
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setOtherAssets(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取其他资产失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const initOfficeSharing = createAction('@@xcloud/amoebaCostSharing/initOfficeSharing',
    (dptId) => (dispatch, getState) => {
        return dispatch(getCostSharingList('SHARING_OFFICE')).then((data) => {
            // 设置默认选择装置
            if (data.payload.length <= 0) {
                return dispatch(setSharingTakelist([]))
            } else {
                if (dptId) {
                    const costSharing = _.find(data.payload, c => { return c.sharerDpt === dptId })
                    if (costSharing) {
                        dispatch(setSelectedOffice(costSharing.sharerDpt))
                        return dispatch(getSharingTakeById(costSharing.sharingId))
                    } else {
                        return dispatch(setSharingTakelist([]))
                    }
                } else {
                    dispatch(setSelectedOffice(data.payload[0].sharerDpt))
                    return dispatch(getSharingTakeById(data.payload[0].sharingId))
                }
            }
        }).catch(err => {
            let msg = '初始化机关管理分摊页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const initCompanySharing = createAction('@@xcloud/amoebaCostSharing/initCompanySharing',
    (companyId) => (dispatch, getState) => {
        return dispatch(getCostSharingList('SHARING_COMPANY')).then((data) => {
            // 设置默认选择装置
            if (data.payload.length > 0) {
                if (companyId && data.payload.length > 0) {
                    const pay = data.payload.find(s => {
                        return s.sharerCompany === companyId
                    })
                    dispatch(setSelectedDpt(pay.sharerDpt))
                    return dispatch(getSharingTakeById(pay.sharingId))
                }
            } else {
                dispatch(setSharingTakelist([]))
            }
        }).catch(err => {
            let msg = '初始化分厂管理分摊页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const initStaffSharing = createAction('@@xcloud/amoebaCostSharing/initStaffSharing',
    (companyId) => (dispatch, getState) => {
        return dispatch(getCostSharingList('SHARING_STAFF')).then((data) => {
            // 设置默认选择装置
            if (data.payload.length > 0) {
                if (data.payload.length > 0) {
                    let pay = data.payload[0]
                    if (companyId) {
                        pay = data.payload.find(s => {
                            return s.sharerCompany === companyId
                        })
                    }
                    if (pay && pay.sharer) {
                        dispatch(setSelectedSharer(pay.sharer))
                        return dispatch(getSharingTakeById(pay.sharingId))
                    } else {
                        dispatch(setSharingTakelist([]))
                    }
                }
            } else {
                dispatch(setSharingTakelist([]))
            }
        }).catch(err => {
            let msg = '初始化分厂管理分摊页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const initOtherSharing = createAction('@@xcloud/amoebaCostSharing/initOtherSharing',
    () => (dispatch, getState) => {
        return dispatch(getCostSharingList('SHARING_DPT')).then((data) => {
            // 设置默认选择装置
            if (data.payload.length > 0) {
                dispatch(setSelectedDpt(data.payload[0].sharerDpt))
                return dispatch(getSharingTakeById(data.payload[0].sharingId))
            } else {
                dispatch(setSharingTakelist([]))
            }
        }).catch(err => {
            let msg = '初始化其他内部结算页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const initServiceSharing = createAction('@@xcloud/amoebaCostSharing/initOtherSharing',
    () => (dispatch, getState) => {
        return dispatch(getCostSharingList('SHARING_SERVICE')).then((data) => {
            // 设置默认选择装置
            if (data.payload.length > 0) {
                dispatch(setSelectedService(data.payload[0].shareService))
                return dispatch(getSharingTakeById(data.payload[0].sharingId))
            }
        }).catch(err => {
            let msg = '初始化其他内部结算页面失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const setSelectedInstallation = createAction('@@xcloud/amoebaCostSharing/setSelectedInstallation');
export const setSelectedOffice = createAction('@@xcloud/amoebaCostSharing/setSelectedOffice');
export const setSelectedDpt = createAction('@@xcloud/amoebaCostSharing/setSelectedDpt');
export const setSelectedSharer = createAction('@@xcloud/amoebaCostSharing/setSelectedSharer');
export const setSelectedService = createAction('@@xcloud/amoebaCostSharing/setSelectedService');
export const setCostSharinglist = createAction('@@xcloud/amoebaCostSharing/setCostSharinglist');
export const setSharingTakelist = createAction('@@xcloud/amoebaCostSharing/setSharingTakelist');
export const setUsers = createAction('@@xcloud/amoebaCostSharing/setUsers');
export const setOtherAssets = createAction('@@xcloud/amoebaCostSharing/setOtherAssets');
export const setSharingTakeHistories = createAction('@@xcloud/amoebaCostSharing/setSharingTakeHistories');
export const setResetSharingParam = createAction('@@xcloud/amoebaCostSharing/setResetSharingParam');
export const setOpenInstallationUser = createAction('@@xcloud/amoebaCostSharing/setOpenInstallationUser');

export const getCostSharingList = createAction('@@xcloud/amoebaCostSharing/getCostSharingList',
    (type) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/costsharing?status=active'
        if (type !== undefined) {
            url = url + '&shareType=' + type
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setCostSharinglist(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取分摊对象列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getSharingTakeById = createAction('@@xcloud/amoebaCostSharing/getSharingTakeById',
    (sid) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/sharingtakes?status=active'
        if (sid !== undefined) {
            url = url + '&sharingId=' + sid
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setSharingTakelist(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取分摊对象列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateSharingTake = createAction('@@xcloud/amoebaCostSharing/updateSharingTake',
    (takeId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/sharingtakes/${takeId}`, newData, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, newData.status === 'inactive' ? "删除分摊配置成功" : "更新分摊配置成功"));
                    return dispatch(getSharingTakeById(newData.sharingId));
                }
            }).catch(err => {
                let msg = newData.status === 'inactive' ? "删除分摊配置成功" : `更新分摊配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addCostSharing = createAction('@@xcloud/amoebaCostSharing/addCostSharing',
    (newData, type) => (dispatch, getState, httpClient) => {
        newData['shareType'] = type
        return httpClient.post(porxyApi + `/amoeba/costsharing`, newData, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "添加分摊成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `添加分摊失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addSharingTake = createAction('@@xcloud/amoebaCostSharing/addSharingTake',
    (newData, type) => (dispatch, getState, httpClient) => {
        newData['takeType'] = type
        return httpClient.post(porxyApi + `/amoeba/sharingtakes`, newData, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "分摊成功"));
                    return dispatch(getSharingTakeById(newData.sharingId));
                }
            }).catch(err => {
                let msg = `添加分摊失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getSharingTakeHistories = createAction('@@xcloud/amoebaCostSharing/getSharingTakeHistory',
    (sharingId, takeId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/sharingtakes/histories'
        let strs = []
        if (sharingId || takeId || status) {
            if (sharingId) strs.push('sharingId=' + sharingId)
            if (takeId) strs.push('takeId=' + takeId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setSharingTakeHistories(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取分摊对象列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const resetSharingTake = createAction('@@xcloud/amoebaCostSharing/resetSharingTake',
    (resetParam) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/sharingtakes/reset`, resetParam, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "分摊重置成功"));
                    return;
                }
            }).catch(err => {
                let msg = `分摊重置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const amoebaInstallSharingReducer = handleActions(
    {
        [setSelectedInstallation]: (state, { payload: data }) => {
            return {
                ...state,
                selectedInstallation: data
            }
        },
        [setSelectedOffice]: (state, { payload: data }) => {
            return {
                ...state,
                selectedOffice: data
            }
        },
        [setSelectedDpt]: (state, { payload: data }) => {
            return {
                ...state,
                selectedDpt: data
            }
        },
        [setSelectedSharer]: (state, { payload: data }) => {
            return {
                ...state,
                selectedSharer: data
            }
        },
        [setSelectedService]: (state, { payload: data }) => {
            return {
                ...state,
                selectedService: data
            }
        },
        [setCostSharinglist]: (state, { payload: data }) => {
            return {
                ...state,
                costSharinglist: data
            }
        },
        [setSharingTakelist]: (state, { payload: data }) => {
            return {
                ...state,
                sharingTakelist: data
            }
        },
        [setSharingTakeHistories]: (state, { payload: data }) => {
            return {
                ...state,
                sharingTakeHistories: data
            }
        },

        [setUsers]: (state, { payload: value }) => {
            return {
                ...state,
                users: value
            };
        },
        [setOtherAssets]: (state, { payload: value }) => {
            return {
                ...state,
                otherAssets: value
            };
        },

        [setResetSharingParam]: (state, { payload: value }) => {
            return {
                ...state,
                resetSharingParam: value
            };
        },
        [setOpenInstallationUser]: (state, { payload: value }) => {
            return {
                ...state,
                openInstallationUser: value
            };
        },
    },
    initialState
)

export default amoebaInstallSharingReducer
