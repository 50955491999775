import React from "react";
import PropTypes from "prop-types";
import withStyles from "./styles";
import {
  Card,
  Typography,
  Button,
  TextField,
  Grid,
  Chip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import zhCN from "date-fns/locale/zh-CN";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { getMoment as moment, getDateByTimestamp } from "@/utils/datetime";
import _ from "lodash";
import { Prompt } from "react-router-dom";

const MonthlyPlanEdit = (props) => {
  const {
    classes,
    planStarted,
    isLeader,
    isAvailableDpt,
    isHead,
    userDptId,
    onhandleSave,
    onhandleStart,
    onhandleChange,
    onhandleSelectChange,
    onhandleTagDelete,
    onhandleBack,
    onhandleCheck,
    currentPlanDetail,
    currentPlanResults,
    leaders,
    users,
    showCategory,
    getDptUserHelperText,
    planDetailError,
    planDetailChange,
    onhandleListMapping,
  } = props;

  const isSupervisor = isAvailableDpt && isLeader;
  const editableUser = isSupervisor || isHead;

  const maxStartDate = moment()().endOf("year");
  const minStartDate = moment()().startOf("year");

  const maxEndDate = _.cloneDeep(maxStartDate);

  const getUserValue = (dptId, currentPlanResults) => {
    const values = [];
    if (currentPlanResults && currentPlanResults.length) {
      currentPlanResults.forEach((item) => {
        if (dptId === item.dptId) {
          values.push(item.userId);
        }
      });
    }
    return values;
  };

  return (
    <div>
      <Card className={classes.card}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={9}>
            <Typography variant="h3" gutterBottom>
              {"排查计划"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom>排查类型</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="textPrimary" className={classes.label}>
              {onhandleListMapping(
                currentPlanDetail.listType &&
                  currentPlanDetail.listType.substr(0, 5),
                "listType"
              )}
            </Typography>
          </Grid>

          {showCategory ? (
            <>
              <Grid item xs={1}>
                <Typography gutterBottom className={classes.label}>
                  子分类{" "}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography color="textPrimary" className={classes.label}>
                  {onhandleListMapping(currentPlanDetail.catCode, "category")}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              检查表{" "}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textPrimary" className={classes.label}>
              {currentPlanDetail.inventoryName || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color="textPrimary" className={classes.label}>
              受检单位
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textPrimary" className={classes.label}>
              {onhandleListMapping(currentPlanDetail.companyId, "company")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color="textPrimary" className={classes.label}>
              检查时间
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!editableUser || planStarted ? (
              <Typography color="textPrimary" className={classes.label}>
                {
                  // moment().utc(currentPlanDetail.startTime).format('yyyy/MM/DD')
                  moment()(currentPlanDetail.startTime).isSame(
                    currentPlanDetail.inspectTime,
                    "day"
                  )
                    ? getDateByTimestamp(currentPlanDetail.startTime)
                    : getDateByTimestamp(currentPlanDetail.startTime) +
                      " 至 " +
                      getDateByTimestamp(currentPlanDetail.inspectTime)
                }
              </Typography>
            ) : (
              <>
                <Grid container spacing={0} alignItems="flex-end">
                  <Grid item>
                    <Typography className={classes.dateLabel}>从</Typography>
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                      <KeyboardDatePicker
                        className={classes.textField}
                        disabled={!editableUser || planStarted}
                        disableToolbar
                        // disablePast
                        autoOk
                        variant={"inline"}
                        invalidDateMessage={"时间格式错误"}
                        error={planDetailError.startTime !== ""}
                        helperText={planDetailError.startTime}
                        maxDate={maxStartDate}
                        minDate={minStartDate}
                        InputProps={{ readOnly: true }}
                        format="yyyy/MM/dd"
                        margin="none"
                        name="startTime"
                        value={
                          currentPlanDetail.startTime
                            ? moment().utc(currentPlanDetail.startTime)
                            : null
                        }
                        onChange={(event) =>
                          onhandleSelectChange(event, "startTime")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "修改时间",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="flex-end">
                  <Grid item>
                    <Typography className={classes.dateLabel}>至</Typography>
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                      <KeyboardDatePicker
                        className={classes.textField}
                        disableToolbar
                        autoOk
                        error={planDetailError.inspectTime !== ""}
                        helperText={planDetailError.inspectTime}
                        InputProps={{ readOnly: true }}
                        variant={"inline"}
                        maxDate={maxEndDate}
                        maxDateMessage={
                          <Typography>日期不能超过计划年度</Typography>
                        }
                        minDateMessage={
                          <Typography>不能早于当前日期</Typography>
                        }
                        format="yyyy/MM/dd"
                        margin="none"
                        name="inspectTime"
                        value={
                          currentPlanDetail.inspectTime
                            ? moment().utc(currentPlanDetail.inspectTime)
                            : null
                        }
                        onChange={(event) =>
                          onhandleSelectChange(event, "inspectTime")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "修改时间",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color="textPrimary" className={classes.label}>
              负责人
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!editableUser || planStarted ? (
              <Grid item xs={2}>
                <Typography>
                  {onhandleListMapping(currentPlanDetail.inspectHead, "user")}
                </Typography>
              </Grid>
            ) : (
              <Autocomplete
                className={classes.textField}
                options={leaders}
                getOptionLabel={(option) =>
                  option.lastName + option.firstName || ""
                }
                getOptionSelected={(option, value) =>
                  option.userId === value.userId
                }
                noOptionsText="无可选人员"
                value={
                  _.find(leaders, { userId: currentPlanDetail.inspectHead }) ||
                  null
                }
                onChange={(e, newValue) => {
                  onhandleSelectChange(newValue, "inspectHead");
                }}
                renderOption={(option) => (
                  <Grid container justify={"space-between"}>
                    <Typography>
                      {option.lastName + option.firstName ||
                        `ID用户${option.userId}`}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                      {option.roleName || ""}
                    </Typography>
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="搜索并选择负责人"
                    error={planDetailError.inspectHead !== ""}
                    helperText={planDetailError.inspectHead}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              部门检查人员
            </Typography>
          </Grid>
          <Grid item xs={5}>
            {currentPlanDetail.inspectionDptUserList &&
              currentPlanDetail.inspectionDptUserList.map((item) => (
                <div className={classes.dptBlock} key={item.dptId}>
                  <Grid item xs={2}>
                    <Typography className={classes.dptName}>
                      {onhandleListMapping(item.dptId, "department")}
                    </Typography>
                  </Grid>
                  {planStarted ||
                  (!editableUser && (userDptId !== item.dptId || !isLeader)) ? (
                    <Grid item xs={2}>
                      <Typography>
                        {onhandleListMapping(
                          getUserValue(item.dptId, currentPlanResults),
                          "userShow"
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={5}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disableClearable
                        filterSelectedOptions
                        className={classes.textField}
                        disabled={
                          planStarted ||
                          (!editableUser &&
                            (userDptId !== item.dptId || !isLeader))
                        }
                        // options={item.userList}
                        options={users.list.filter(
                          (user) => user.dptId === item.dptId
                        )}
                        onChange={(e, newValue, reason) =>
                          onhandleSelectChange(
                            {
                              users: newValue,
                              dptId: item.dptId,
                              reason: reason,
                            },
                            "responsibleUser"
                          )
                        }
                        getOptionLabel={(option) =>
                          option.lastName + option.firstName || ""
                        }
                        getOptionSelected={(option, value) =>
                          option.userId === value
                        }
                        noOptionsText="无可选人员"
                        value={getUserValue(item.dptId, currentPlanResults)}
                        renderOption={(option) => (
                          <Grid container justify={"space-between"}>
                            <Typography>
                              {option.lastName + option.firstName ||
                                `ID用户${option.userId}`}
                            </Typography>
                            <Typography variant={"subtitle2"}>
                              {option.roleName || ""}
                            </Typography>
                          </Grid>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={onhandleListMapping(option, "user")}
                              onDelete={() =>
                                onhandleTagDelete(item.dptId, option)
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) => {
                                    if (!props.disabled) {
                                      event.stopPropagation();
                                      onhandleTagDelete(item.dptId, option);
                                    }
                                  }}
                                />
                              }
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              getDptUserHelperText(item)
                                ? getDptUserHelperText(item)
                                : "选择检查人"
                            }
                            error={
                              (planDetailError.dptUser !== "" &&
                                !getUserValue(
                                  item.dptId,
                                  currentPlanResults
                                )) ||
                              getDptUserHelperText(item) !== ""
                            }
                            helperText={getDptUserHelperText(item)}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </div>
              ))}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              排查内容
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              multiline
              variant="outlined"
              InputProps={{
                readOnly: !editableUser || planStarted,
              }}
              className={classes.textField}
              value={currentPlanDetail.opentext || ""}
              rows={5}
              onChange={(event) => onhandleChange(event, "opentext")}
              onBlur={(event) => onhandleCheck(event, "opentext")}
            />
          </Grid>
        </Grid>

        {currentPlanDetail.dtlId ? (
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={1}>
              <Typography gutterBottom>创建人 </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom>
                {currentPlanDetail.creatorLastName +
                  currentPlanDetail.creatorFirstName}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography gutterBottom>创建时间 </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom>
                {moment()
                  .utc(currentPlanDetail.createTime)
                  .format("yyyy/MM/DD HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {currentPlanDetail.dtlId ? (
          <Grid container spacing={1} className={classes.gridContainer}></Grid>
        ) : null}

        {currentPlanDetail.dtlId && currentPlanDetail.updateTime ? (
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={1}>
              <Typography gutterBottom>更新人</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom>
                {currentPlanDetail.revisorLastName +
                  currentPlanDetail.revisorFirstName}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography gutterBottom>更新时间</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom>
                {moment()
                  .utc(currentPlanDetail.updateTime)
                  .format("yyyy/MM/DD HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Card>

      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => onhandleBack(event)}
          >
            返回
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => onhandleSave(event)}
            disabled={!planDetailChange || !editableUser || planStarted}
          >
            保存
          </Button>
        </Grid>
        <Grid item xs={1}>
          {!planStarted && (
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => onhandleStart(event)}
              disabled={!editableUser || planStarted}
            >
              启动计划
            </Button>
          )}
        </Grid>
      </Grid>
      <Prompt message="数据未保存,确定要离开？" when={planDetailChange} />
    </div>
  );
};

MonthlyPlanEdit.propTypes = {
  onhandleSave: PropTypes.func.isRequired,
  onhandleStart: PropTypes.func.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleBack: PropTypes.func.isRequired,
  onhandleCheck: PropTypes.func.isRequired,
  onhandleTagDelete: PropTypes.func.isRequired,
  onhandleListMapping: PropTypes.func.isRequired,
  currentPlanDetail: PropTypes.object.isRequired,
  currentPlanResults: PropTypes.array.isRequired,
  planDetailError: PropTypes.object.isRequired,
  planDetailChange: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  showCategory: PropTypes.bool.isRequired,
};

export default withStyles(MonthlyPlanEdit);
