import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { getMoment } from '@/utils/datetime';
import { download } from '@/redux/modules/minio';
import { MANUFACTURE_SERVICE_REPORT_DIM } from '@/utils/constant';
import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    prodReportList: [], //生产报表(旧)

    dateHeadDataList: [], // 报表日期附加表头

    prodReportServiceList: [], // 总生产报表

    prodReportServiceOrgList: [], // 划分组织报表

    prodReportServiceDetailList: [], //单个服务报表

    outputCheckList: [], //产量确认
};

export const setProdReportList = createAction('@@xcloud/manufacture/setProdReportList');

export const setProdReportServiceList = createAction('@@xcloud/manufacture/setProdReportServiceList');

export const setProdReportServiceOrgList = createAction('@@xcloud/manufacture/setProdReportServiceOrgList');

export const setProdReportServiceDetailList = createAction('@@xcloud/manufacture/setProdReportServiceDetailList');

export const setDateHeadDataList = createAction('@@xcloud/manufacture/setDateHeadDataList');

export const setOutPutCheckList = createAction('@@xcloud/manufacture/setOutPutCheckList');


export const getProdReportList = createAction('@@xcloud/manufacture/getProdReportList',
    (companyId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/prodreports'
        let strs = []
        if (companyId || status) {
            if (companyId) strs.push('companyId=' + companyId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setProdReportList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

// 没有划分分厂或部门
export const getProdReportServiceList = createAction('@@xcloud/manufacture/getProdReportServiceList',
    (companyId, timeDim, startDate, endDate, serviceId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/prodreports/service'
        let strs = []
        strs.push('orgDim=' + MANUFACTURE_SERVICE_REPORT_DIM.ALL)
        timeDim = timeDim.toUpperCase();
        if (timeDim || startDate || endDate || companyId || serviceId) {
            if (serviceId) strs.push('serviceId=' + serviceId)
            if (timeDim) strs.push('timeDim=' + timeDim)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (companyId) strs.push('companyId=' + companyId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                const headDataList = _getDateHeadDataList(timeDim, startDate, endDate);
                dispatch(setDateHeadDataList(headDataList));
                dispatch(setProdReportServiceList(_.orderBy(res.data, ['serviceName'], ['asc'])));
                if (timeDim === 'DAY' && companyId) {
                    dispatch(getManufactureOutputCheckList(companyId, startDate, endDate))
                }
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

// 划分了分厂或部门(无OrgDimension)
export const getProdReportServiceOrgList = createAction('@@xcloud/manufacture/getProdReportServiceOrgList',
    (companyId, timeDim, startDate, endDate, serviceId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/manufacture/prodreports/service`
        let strs = []
        timeDim = timeDim.toUpperCase();
        if (!companyId) {
            strs.push('orgDim=' + MANUFACTURE_SERVICE_REPORT_DIM.PARENT_COMPANY)
        } else {
            strs.push('orgDim=' + MANUFACTURE_SERVICE_REPORT_DIM.SUB_COMPANY)
        }
        if (timeDim || startDate || endDate || companyId || serviceId) {
            if (serviceId) strs.push('serviceId=' + serviceId)
            if (timeDim) strs.push('timeDim=' + timeDim)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (companyId) strs.push('companyId=' + companyId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setProdReportServiceOrgList(_.orderBy(res.data, ['serviceName'], ['asc'])));
            }
            return res.status;
        }).catch(err => {
            let msg = `获取单个日期生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const exportProdServiceReport = createAction('@@xcloud/manufacture/exportProdServiceReport',
    (startDate, endDate, companyId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/manufacture/prodreports/export?`;
        const query = [];
        if (startDate) query.push('startDate=' + startDate)
        if (endDate) query.push('endDate=' + endDate)
        query.push("companyId=" + companyId);
        return httpClient.get(url + query.join('&'), { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let tenantId = getState().auth.userProfile.roles[0].tenantId
                        let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                        dispatch(download(
                            res.data.docName,
                            mybucket
                        ))
                    }
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '导出年度生产报表失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

// 某项服务单独统计（未实现）
export const getProdReportServiceDetailList = createAction('@@xcloud/manufacture/getProdReportServiceDetailList',
    (timeDim, startDate, endDate, companyId, serviceId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/manufacture/prodreports/service/${{ serviceId }}`
        let strs = []
        if (timeDim || startDate || endDate || companyId || status) {
            if (timeDim) strs.push('timeDim=' + timeDim)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (companyId) strs.push('companyId=' + companyId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                const list = _assemblyDetailReportData(res.data, companyId);
                dispatch(setProdReportServiceDetailList(list))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取单个服务生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const checkManufactureOutput = createAction('@@xcloud/manufacture/addService',
    (checkDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/prodreports/checks', checkDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "确认产量成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `确认产量失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

// 查询产量确认记录
export const getManufactureOutputCheckList = createAction('@@xcloud/manufacture/getManufactureOutputCheckList',
    (companyId, startDate, endDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/prodreports/checks'
        let strs = []
        if (startDate || endDate || companyId || status) {
            if (status) strs.push('status=' + status)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (companyId) strs.push('companyId=' + companyId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setOutPutCheckList(res.data));
            }
            return res.data;
        }).catch(err => {
            let msg = `获取生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const manufactureProdReportReducer = handleActions(
    {
        [setProdReportList]: (state, { payload: data }) => {
            return {
                ...state,
                prodReportList: data
            }
        },
        [setDateHeadDataList]: (state, { payload: data }) => {
            return {
                ...state,
                dateHeadDataList: data
            }
        },
        [setProdReportServiceList]: (state, { payload: data }) => {
            return {
                ...state,
                prodReportServiceList: data
            }
        },
        [setProdReportServiceOrgList]: (state, { payload: data }) => {
            return {
                ...state,
                prodReportServiceOrgList: data
            }
        },
        [setProdReportServiceDetailList]: (state, { payload: data }) => {
            return {
                ...state,
                prodReportServiceDetailList: data
            }
        },
        [setOutPutCheckList]: (state, { payload: data }) => {
            return {
                ...state,
                outputCheckList: data
            }
        },


    },
    initialState
)

const _getDateHeadDataList = (timeDim, startDate, endDate) => {
    const headList = [];
    let format = '';
    if (timeDim === 'YEAR') {
        format = 'yyyy'
    } else if (timeDim === 'MONTH') {
        format = 'yyyy/MM'
    } else if (timeDim === 'DAY') {
        format = 'yyyy/MM/DD'
    }
    let indexMoment = getMoment()(startDate);
    // const startMoment = getMoment()(startDate);
    const endMoment = getMoment()(endDate);
    while (indexMoment.isSameOrBefore(endMoment)) {
        const title = {
            key: indexMoment.format(format),
            name: indexMoment.format(format) // 按需调整显示用format
        }
        headList.push(title);
        indexMoment.add(1, timeDim);
    }
    return _.uniqWith(headList, _.isEqual);
}

const _assemblyDetailReportData = (prodReportServiceList, companyId) => {
    const serviceList = [];
    if (prodReportServiceList && prodReportServiceList.length > 0) {
        prodReportServiceList.forEach(item => {
            const {
                serviceId,
                serviceName,
                unit,
                ymd,
                companyId,
                dptId,
                output
            } = item;
            // 组装服务列表
            if (_.findIndex(serviceList, { 'serviceId': serviceId }) < 0) {
                // 新service
                const service = {
                    serviceId: serviceId,
                    serviceName: serviceName,
                    unit: unit,
                    companyId: companyId
                };
                // 分厂
                if (companyId !== 0) {
                    service['dptId'] = dptId
                }
                service[ymd] = output
                serviceList.push(service);
            } else {
                const currentService = _.find(serviceList, { 'serviceId': serviceId });
                if (!currentService[ymd]) {
                    // 为已有服务填充数值和日期信息
                    currentService[ymd] = output;
                } else {
                    // 已有数值进行累加
                    currentService[ymd] = currentService[ymd] + output;
                }
            }
        })

    }
    return _.orderBy(serviceList, ['serviceName'], ['asc'])
}

export default manufactureProdReportReducer
