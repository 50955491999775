import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    plans: [], //人力资源计划列表
    mainPlans: [], //人力资源计划列表
};

export const setHrPlans = createAction('@@xcloud/amoeba/setHrPlans');
export const setMainHrPlans = createAction('@@xcloud/amoeba/setMainHrPlans');

export const getHrPlans = createAction('@@xcloud/amoeba/getHrPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/hrplans'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setHrPlans(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取人力资源计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getMainHrPlans = createAction('@@xcloud/amoeba/getMainHrPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/hrplans'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setMainHrPlans(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取人力资源计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateHrPlanDtl = createAction('@@xcloud/amoeba/updateHrPlanDtl',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/hrplans/dtls/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新人力资源计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新人力资源计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateHrPlan = createAction('@@xcloud/amoeba/updateHrPlan',
    (planId, planDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/hrplans/${planId}`, planDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新人力资源计划成功"));
                }
            }).catch(err => {
                let msg = `更新人力资源计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const addHrPlan = createAction('@@xcloud/amoeba/addHrPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/hrplans`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动人力资源计划成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `启动人力资源计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const amoebaHrPlanReducer = handleActions(
    {
        [setHrPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setMainHrPlans]: (state, { payload: data }) => {
            return {
                ...state,
                mainPlans: data
            }
        },
    },
    initialState
)

export default amoebaHrPlanReducer
