import React, { useState } from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Typography,
    Tooltip,
    IconButton,
    ListItemText,
    Checkbox
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import { Table } from 'antd';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import * as _ from 'lodash';

const ServiceTempPlanConfigComponent = props => {
    const {
        classes,
        currentUserInfo,
        departments,
        // companies,
        openFlag,
        editingConfigs,
        services,
        onhandleClose,
        onhandleChange,
    } = props;

    const getRows = () => {
        return editingConfigs
    }

    const [editingRow, setEditingRow] = useState({})

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon fontSize='small' /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon fontSize='small' /></Tooltip>
        }
        return ''
    }

    const applySocpeList = [{
        'code': 'ALL',
        'name': '所有班组'
    }, {
        'code': 'CUSTOM',
        'name': '指定班组'
    }]


    // const getCompanySelect = () => {
    //     const myCompany = _.find(companies, { 'companyId': currentUserInfo.companyId })
    //     if (!myCompany.pid) { //可选子公司
    //         return _.filter(companies, { 'pid': myCompany.companyId })
    //     }
    //     return [myCompany]
    // }

    const renderDpts = (row) => {
        if (row.editing && editingRow && editingRow.applyScope === 'ALL') {
            return ''
        }
        if (row.applyDpts) {
            let dptIds = _.map(_.split(row.applyDpts, ','), d => Number(d))
            return _.chain(departments)
                .filter(d => { return _.indexOf(dptIds, d.dptId) > -1 })
                .map('dptName')
                .join(',')
                .value()
        } else {
            return ''
        }
    }

    const renderApplyScope = (applyScope) => {
        let item = _.find(applySocpeList, { 'code': applyScope })
        if (item) {
            return item.name
        } else { return applyScope }
    }

    const renderEditDpts = (dptIds) => {
        if (dptIds && dptIds.length > 0) {
            return _.chain(departments)
                .filter(d => { return _.indexOf(dptIds, d.dptId) > -1 })
                .map('dptName')
                .join(',')
                .value()
        } else {
            return ''
        }
    }

    const dptSelect = () => {
        if (editingRow.serviceId) {
            const service = _.find(services, { 'serviceId': editingRow.serviceId })
            if (service && service.serviceDpts && service.serviceDpts.length > 0) {
                return _.chain(departments)
                    .filter(d => { return _.find(service.serviceDpts, { 'dptId': d.dptId }) })
                    .value()
            }
        }
        return _.chain(departments).filter({ 'companyId': currentUserInfo.companyId })
            .value()
    }

    const editRow = (row) => {
        row.editing = true
        row.dptIds = _.map(_.split(row.applyDpts, ','), (d) => (Number(d)))
        onhandleChange(row, 'update')
        setEditingRow(row)
    }

    const addRow = () => {
        const maxIdDtl = _.maxBy(getRows(), 'id')
        let row = {
            id: maxIdDtl ? (maxIdDtl.id + 1) : 1,
            applyDpts: '',
            planType: 'TEMP',
            applyScope: 'ALL',
            serviceId: 0,
            editing: true,
        }
        onhandleChange(row, 'add')
        setEditingRow(row)
    }


    const updateRow = (event) => {
        let dtl = { ...editingRow }
        if (event.target.name === 'output') {
            dtl['output'] = Number(event.target.value)
        } else if (event.target.name === 'serviceId') {
            const service = services.find(item => {
                return item.serviceId === Number(event.target.value)
            })
            dtl['serviceDpts'] = service.serviceDpts
            dtl['serviceId'] = service.serviceId;
            dtl['dptIds'] = []
        } else if (event.target.name === 'dptIds') {
            dtl['dptIds'] = event.target.value
        } else {
            dtl[event.target.name] = event.target.value
        }
        setEditingRow(dtl)
    }

    const saveEditRow = () => {
        let newDtl = {
            ...editingRow,
            applyDpts: editingRow.applyScope === 'CUSTOM' ? _.join(editingRow.dptIds, ',') : '',
            editing: false
        }
        onhandleChange(newDtl, 'save')
        setEditingRow({})
    }

    const cancelEditRow = (row) => {

        let newDtl = { ...row, editing: false }
        onhandleChange(newDtl, 'cancel')
        setEditingRow({})
    }

    const deleteRow = (row) => {
        onhandleChange(row, 'delete')
        setEditingRow({})
    }

    const assemblyColumns = () => {
        return [
            {
                title: '服务',
                dataIndex: 'serviceName',
                key: 'serviceName',
                render: (text, row, index) => row.editing ? <TextField
                    select
                    size='small'
                    name='serviceId'
                    variant="outlined"
                    error={editingRow.serviceId ? false : true}
                    value={editingRow.serviceId || ''}
                    onChange={(event) => updateRow(event)}>
                    {services && services.map((i) => (
                        <MenuItem key={i.serviceId} value={i.serviceId}>
                            {i.serviceName}{renderServiceType(i.serviceType)}
                        </MenuItem>
                    ))}
                </TextField> : <Typography>{row.serviceName}</Typography>,
            },
            {
                title: '应用范围',
                dataIndex: 'applyScope',
                key: 'applyScope',
                type: 'string',
                render: (text, row, index) => row.editing ? <Grid item className={classes.itemGrid}>
                    <TextField
                        size='small'
                        select
                        name='applyScope'
                        variant="outlined"
                        onChange={(event) => updateRow(event)}
                        value={editingRow.applyScope || ''}
                    >
                        {applySocpeList.map((i) => (
                            <MenuItem key={i.code} value={i.code}>
                                {i.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> : <Typography>{renderApplyScope(row.applyScope)}</Typography>,
            },
            {
                title: '班组',
                dataIndex: 'applyDpts',
                key: 'applyDpts',
                type: 'string',
                render: (text, row, index) => (row.editing && editingRow.applyScope === 'CUSTOM') ?
                    <TextField
                        select
                        SelectProps={{
                            multiple: true,
                            renderValue: () => renderEditDpts(editingRow.dptIds)
                        }}
                        fullWidth
                        size='small'
                        name='dptIds'
                        variant="outlined"
                        value={editingRow.dptIds || []}
                        onChange={(event) => updateRow(event)}
                    >
                        {dptSelect() && dptSelect().map((i) => (
                            <MenuItem key={i.dptId} value={i.dptId}>
                                <Checkbox className={classes.checkbox}
                                    size={'small'}
                                    checked={editingRow.dptIds && _.indexOf(editingRow.dptIds, i.dptId) > -1} />
                                <ListItemText primary={i.dptName} />
                            </MenuItem>
                        ))}
                    </TextField> : <Typography>{renderDpts(row)}</Typography>,
            },
            {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: '90px',
                render: (text, row, index) => renderActions(row),
            },]
    }

    const renderActions = (row) => {
        if (isEditing()) {
            if (editingRow.id === row.id) {
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='保存'><IconButton variant='text' onClick={() => saveEditRow(row)}> <SaveIcon fontSize='small' /></IconButton></Tooltip></Grid>
                    <Grid item><Tooltip title='取消'><IconButton variant='text' onClick={() => cancelEditRow(row)}> <CancelIcon fontSize='small' /></IconButton></Tooltip></Grid>
                </Grid>
            } else {
                return <Grid container alignItems='center'></Grid>
            }
        } else {
            return <Grid container alignItems='center'>
                <Grid item><Tooltip title='修改'><IconButton variant='text' onClick={() => editRow(row)}> <EditIcon fontSize='small' /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => deleteRow(row)}> <DeleteIcon fontSize='small' /></IconButton></Tooltip></Grid>
            </Grid>
        }
    }

    const isEditing = () => {
        let editingRow = _.find(getRows(), { 'editing': true })
        return editingRow ? true : false
    }


    return (
        <div alignitems="center" className={classes.root}>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openFlag}
                onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{'自动临时计划配置'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item className={classes.planDetails}>
                            <Grid container alignItems='flex-end'>
                                <Grid item className={classes.flexgrow}> </Grid>
                                {isEditing() ? '' :
                                    <Grid item><Tooltip title='新增'><IconButton variant='text' onClick={() => addRow()}> <AddIcon fontSize='small' /></IconButton></Tooltip></Grid>}
                            </Grid>
                            <Table
                                size='small'
                                className={classes.stripedTable}
                                rowKey={record => record.id}
                                dataSource={getRows()}
                                columns={assemblyColumns()}
                            ></Table>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

ServiceTempPlanConfigComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

ServiceTempPlanConfigComponent.defaultProps = {
    plan: {},
    openFlag: false,

};

export default withStyles(ServiceTempPlanConfigComponent);