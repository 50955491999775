import { createAction, handleActions } from 'redux-actions';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createhttpMessage } from '@/redux/modules/message';
import { getMoment } from '@/utils/datetime';
import _ from 'lodash';

const defaultState = {
  hazardReport: [],
  planReport: [],
  tableValue: 0,
  search: {
    companyId: "",
    rootCause: "",
    status: '',
    intervalDate: {
      view: 'week',
      startDate: '',
      endDate: ''
    }
  },
}

export const resetSearch = createAction('@@xcloud/hazardReport/resetSearch',
  () => (dispatch, getState, httpClient) => {

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;

    let newSearch = {
      companyId: hasPcomp ? getState().auth.currentUserInfo.companyId : '',
      rootCause: '',
      status: '',
      tableValue: 0,
      intervalDate: {
        view: 'week',
        startDate: getMoment()().startOf('month').startOf('week').format("YYYY-MM-DD"),
        endDate: getMoment()().endOf('week').format("YYYY-MM-DD")
      }
    }
    dispatch(setSearch(newSearch));
  });

export const initHazardReport = createAction('@@xcloud/hazardReport/initHazardReport',
  () => (dispatch, getState) => {

    dispatch(createloading());

    const dispatchArray = [];
    dispatchArray.push(
      dispatch(resetSearch())
    )
    Promise.all(dispatchArray)
      .then(() => {
        const search = getState().hazardReport.search;
        dispatch(getHazardReport(search));
        dispatch(getPlanReport(search));
      })
      .then(() => {
        dispatch(cleanloading());
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化隐患报表失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

// export const getHazardReport = createAction('@@xcloud/hazardReport/getHazardReport',
//   (query) => (dispatch, getState, httpClient) => {
//     dispatch(createloading());
//     let url = '/api/hazardlist/report';
//     let param = [];

//     if (query.companyId || query.rootCause || query.startDate || query.endDate || query.status)
//       param.push("&companyIds=" + query.companyId);
//     if (query.rootCause)
//       param.push("&rootCause=" + query.rootCause);
//     if (query.intervalDate.startDate)
//       param.push("&startDate=" + query.intervalDate.startDate);
//     if (query.intervalDate.endDate)
//       param.push("&endDate=" + query.intervalDate.endDate);
//     if (query.status)
//       param.push("&status=" + query.status);
//     url = url + '?' + param.join('&')
//     console.log('hazard url',url)
//     console.log('tabValue',query.tableValue)
//     return httpClient.get(url, { headers: { need_loading: 'true' } })
//       .then((res) => {
//         if (res.status === 200) {
//           if (res.data) {
//             dispatch(setHazardReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
//           }
//         }
//         dispatch(cleanloading());
//         return res;
//       })
//       // eslint-disable-next-line no-unused-vars
//       .catch(err => {
//         dispatch(cleanloading());
//         let msg = '隐患报表数据获取失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//   });

export const getHazardReport = createAction('@@xcloud/hazardReport/getHazardReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/hazardlist/report';
    let param = [];
    if (query.companyId || query.rootCause || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.rootCause)
      param.push("&rootCause=" + query.rootCause);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')
    // console.log('hazard url', url)

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setHazardReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '隐患报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getPlanReport = createAction('@@xcloud/hazardReport/getHazardReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/inspectionplans/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setPlanReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '计划报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });
// export const updSearchData = createAction('@@xcloud/hazardReport/updSearchData',
//   (value) => (dispatch, getState, httpClient) => {
//       console.log('')
//       Promise.all([
//         dispatch(setSearch(value))
//       ]).then(() => {
//         dispatch(getHazardReport(value))
//       }).catch(err => {
//         dispatch(cleanloading());
//         let msg = '隐患报表更新失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//     }
// );

export const updSearchData = createAction('@@xcloud/hazardReport/updSearchData',
  (value, tabValue) => (dispatch, getState, httpClient) => {
    console.log('updSearchData   ......1')
    Promise.all([
      dispatch(setSearch(value))
    ]).then(() => {
      // if (tabValue === 4) {
      dispatch(getPlanReport(value))
      // } else {
      dispatch(getHazardReport(value))
      // }
    }).catch(err => {
      // dispatch(cleanloading());
      // let msg = '隐患报表更新失败';
      // dispatch(createhttpMessage(err.response ? err.response : '', msg));
      // return [];
    });
  }
);

// export const getHazardListForCsv = createAction('@@xcloud/hazardReport/getHazardListForCsv',
//   (query, callback) => (dispatch, getState, httpClient) => {
//     let url = '/api/hazardlist';
//     if (query.offset !== undefined) {
//       url += "?offset=" + query.offset;
//     } else {
//       url += "?offset=0";
//     }
//     if (query.size !== undefined) {
//       url += "&size=" + query.size;
//     } else {
//       url += "&size=10";
//     }
//     if (query.sort !== undefined)
//       url += "&sort=" + query.sort;
//     if (query.company)
//       url += "&companyId=" + query.company;
//     if (query.dptId)
//       url += "&dptId=" + query.dptId;
//     if (query.rootCause)
//       url += "&rootCause=" + query.rootCause;
//     if (query.severity)
//       url += "&severity=" + query.severity;
//     if (query.impactLevel)
//       url += "&impactLevel=" + query.impactLevel;
//     if (query.factor)
//       url += "&factor=" + query.factor;
//     if (query.createTime)
//       url += "&createTime=" + query.createTime;
//     if (query.createTimeEnd)
//       url += "&createTimeEnd=" + query.createTimeEnd;
//     if (query.creator)
//       url += "&creator=" + query.creator;
//     if (query.status)
//       url += "&status=" + query.status;
//     return httpClient.get(url, { headers: { need_loading: 'true' } })
//       .then((res) => {
//         if (res.status === 200) {
//           if (res.data) {
//             callback(res.data)
//           }
//         }
//         return res;
//       })
//       // eslint-disable-next-line no-unused-vars
//       .catch(err => {
//         let msg = '隐患台账获取失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//   });


// export const backToHazardList = createAction('@@xcloud/hazardReport/backToHazardList',
//   (status) => (dispatch, getState, httpClient) => {
//     let param = 'beforeevaluated';
//     switch (status) {
//       case 'active':
//         param = 'beforeevaluated';
//         break;
//       case 'evaluated':
//         param = 'afterevaluated';
//         break;
//       case 'treating':
//       case 'done':
//         param = 'treating';
//         break;
//       default:
//       // do nothing
//     }
//     dispatch(push('/inspection/hazardlist/' + param, { isBack: true }));
//   });

export const setHazardReport = createAction('@@xcloud/hazardReport/setHazardReport');
export const setPlanReport = createAction('@@xcloud/hazardReport/setPlanReport');

export const setSearch = createAction('@@xcloud/hazardReport/setHazardsetSearchList');

export const setUserList = createAction('@@xcloud/hazardReport/setUserList');

export const hazardReducer = handleActions(
  {
    [setHazardReport]: (state, { payload: value }) => {
      return {
        ...state,
        hazardReport: value
      };
    },
    [setPlanReport]: (state, { payload: value }) => {
      return {
        ...state,
        planReport: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
  },
  defaultState
);

export default hazardReducer;