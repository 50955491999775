import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Chip,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { BackspaceOutlined, Cast, CloudUpload, DeleteOutlined, Link, AttachFile } from '@material-ui/icons';
import { getMoment } from '@/utils/datetime';
import { WORKORDER_STATUS_ALL } from '@/utils/operationUtils';

import _ from 'lodash';
import { Table } from 'antd';

const OperationPlanOrderList = props => {
    const {
        classes,
        initListData,
        // pageSize,
        // setPageSize,
        // getOperationPlanOrders,
        orderTypeList,
        userProfile,
        isLoading,
        routeType,
        onOpenUploadModal,
        onhandleDelete,
        onhandlePublish,
        onhandleRevert,
        onDownLoadDoc,
        onhandleViewWorkorderWithOperationPlan,
        onhandlePageChange,
        search,
    } = props;

    // const [page, setPage] = useState(0);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_plan_doc' })
        return permission && permission.action === 'W'
    }

    const renderStatus = (row) => {

        if (row.workOrder && row.workOrder.status) {
            return <Chip size="small" className={classes[row.workOrder.status]} label={renderStatusLabel(row.workOrder.status)} />
        }
        return <Typography>{''}</Typography>
    }

    const renderDocStatus = (status) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效' };
        return mapping[status]
    }

    const renderStatusLabel = (status) => {
        return WORKORDER_STATUS_ALL[status]
    }


    const renderDate = (time) => {
        return time ? getMoment()(time).format("YYYY-MM-DD") : '-'
    }

    const renderMaintainResult = (maintainResult) => {
        if (maintainResult === "qualified") {
            return "合格"
        }
        if (maintainResult === "unqualified") {
            return "不合格"
        }
        return '-'
    }

    // const renderWorkOrder = (row) => {
    //     return <React.Fragment>
    //         <IconButton aria-label="查看计划工单列表"
    //             onClick={event => onhandleViewWorkorderWithOperationPlan(row)}
    //             component="span"
    //             title='查看计划工单列表'
    //             color="primary"
    //         >
    //             <Link />
    //         </IconButton>
    //     </React.Fragment>
    // }

    const renderTarget = (row) => {

        if (row.target === 'DEV') {
            return <Typography className={classes.longContent} title={row.deviceName + (row.tagNo ? row.tagNo : '')}>{row.deviceName + (row.tagNo ? row.tagNo : '')}</Typography>
        } else if (row.target === 'ACC') {
            return <Typography className={classes.longContent} title={row.accName}>{row.accName}</Typography>
        }
        return <Typography>{''}</Typography>
    }

    const renderOrderNo = (row) => {
        if (row.workOrder && row.workOrder.orderNo) {
            return <Typography>{row.workOrder.orderNo}</Typography>
        }
        return <Typography>{''}</Typography>
    }

    const renderOrderType = (row) => {
        if (row.workOrder && row.workOrder.orderType) {
            let ot = _.find(orderTypeList, { code: row.workOrder.orderType });
            if (ot) {
                return <Typography>{ot.name}</Typography>
            }
        }
        return <Typography>{''}</Typography>
    }

    const renderOrderContent = (row) => {
        if (row.workOrder && row.workOrder.orderContent) {
            return <Typography className={classes.longContent} title={row.workOrder.orderContent} >{row.workOrder.orderContent}</Typography>
        }
        return <Typography>{''}</Typography>
    }

    const renderOrderActions = (row) => {

        let actions = [];

        actions.push({
            title: '工单详情',
            icon: <Link color={'primary'} />,
            handler: onhandleViewWorkorderWithOperationPlan
        });

        if (routeType === 'DONE') {

            if (checkPermission() && row.workOrder && (
                row.workOrder.status === 'approved' ||
                row.workOrder.status === 'finished'
            )) {
                actions.push({
                    title: '撤回文档',
                    icon: <BackspaceOutlined color={'error'} />,
                    handler: onhandleRevert
                })
            }

        } else if (routeType === 'NEW') {

            actions.push({
                title: '上传文档',
                icon: <CloudUpload color={'primary'} />,
                handler: onOpenUploadModal
            })
            if (row.operationPlanDocs && row.operationPlanDocs.length > 0) {
                if (row.orderTypeConfig && row.orderTypeConfig.needTest === 1) {
                    var testDoc = _.find(row.operationPlanDocs, { 'docCate': 'TEST_DOC' })
                    if (testDoc) {
                        actions.push({
                            title: '发布',
                            icon: <Cast color={'primary'} />,
                            handler: onhandlePublish
                        })
                    }
                } else {
                    var maintainDoc = _.find(row.operationPlanDocs, { 'docCate': 'MAINTAIN_DOC' })
                    if (maintainDoc) {
                        actions.push({
                            title: '发布',
                            icon: <Cast color={'primary'} />,
                            handler: onhandlePublish
                        })
                    }
                }

            }
        }

        var actionComponents = _.map(actions, action => {
            return <Grid item key={action.title}><Tooltip title={action.title}><IconButton variant='text' onClick={() => action.handler(row)}> {action.icon}</IconButton></Tooltip></Grid>
        })

        return <Grid container>
            {actionComponents}
        </Grid>
    }

    const assemblycolumns = () => {
        let columns = [
            // {
            //     title: '工单详细',
            //     dataIndex: 'orderId',
            //     render: (text, row, index) => row ? renderWorkOrder(row) : '',
            //     editable: 'never'
            // },
            {
                title: '工单编号',
                dataIndex: 'orderNo',
                width: 150,
                render: (text, row, index) => row ? renderOrderNo(row) : '',
                editable: 'never'
            }, {
                title: '工单类别',
                dataIndex: 'orderType',
                width: 150,
                render: (text, row, index) => row ? renderOrderType(row) : '',
                editable: 'never'
            },
            {
                title: '运维对象',
                dataIndex: 'target',
                render: (text, row, index) => row ? renderTarget(row) : '',
                editable: 'never'
            },
            {
                title: '运维内容',
                dataIndex: 'target',
                render: (text, row, index) => row ? renderOrderContent(row) : '',
                editable: 'never'
            },
            {
                title: '工单状态',
                dataIndex: 'orderStatus',
                render: (text, row, index) => row ? renderStatus(row) : '',
                width: 90,
                editable: 'never',
                onCell: () => ({
                    style: {
                        width: 90,
                        maxWidth: 90,
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 90,
                        maxWidth: 90,
                        padding: '6px auto',
                    }
                }),
            }, {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: 120,
                render: (text, row, index) => renderOrderActions(row),
                onCell: () => ({
                    style: {
                        width: 120,
                        maxWidth: 120,
                        padding: '0px 0px',
                    }
                }),
            },
        ]

        return columns
    }

    const assemblyData = () => {
        return initListData.list
    }


    const renderDocCate = (docCate) => {
        let mapping = { 'TEST_DOC': '检验检测报告', 'MAINTAIN_DOC': '运维报告', 'OTHER_DOC': '现场图片' };
        return mapping[docCate]
    }
    const renderDocActions = (row) => {
        let actions = [];
        if (row.docLink) {
            actions.push({
                title: '预览',
                icon: <AttachFile color={'primary'} />,
                handler: onDownLoadDoc
            })
        }
        if (routeType === 'DONE') {

        } else if (routeType === 'NEW') {
            actions.push({
                title: '删除',
                icon: <DeleteOutlined color={'error'} />,
                handler: onhandleDelete
            })
        }

        var actionComponents = _.map(actions, action => {
            return <Grid item key={action.title}><Tooltip title={action.title}><IconButton variant='text' onClick={() => action.handler(row)}> {action.icon}</IconButton></Tooltip></Grid>
        })

        return <Grid container>
            {actionComponents}
        </Grid>
    }

    const expandedRowRender = (record, index) => {

        const expandedData = record.operationPlanDocs

        const expandedColumns = [
            {
                title: '文档类别',
                dataIndex: 'docCate',
                width: '8%',
                render: (text, row, index) => row && row ? <Typography>{renderDocCate(row.docCate)}</Typography> : '',
            },
            {
                title: '文档描述',
                dataIndex: 'docDesc',
                width: '30%',
                render: (text, row, index) => row && row ? <Typography>{row.docDesc}</Typography> : '',
            },

            {
                title: '运维日期',
                dataIndex: 'maintainDate',
                render: (text, row, index) => row && row ? <Typography>{renderDate(row.maintainDate)}</Typography> : '',
            },
            {
                title: '下次运维',
                dataIndex: 'nextMaintainDate',
                render: (text, row, index) => row && row ? <Typography>{renderDate(row.nextMaintainDate)}</Typography> : '',
            },
            {
                title: '检测结果',
                dataIndex: 'maintainResult',
                render: (text, row, index) => row && row ? <Typography>{renderMaintainResult(row.maintainResult)}</Typography> : '',
            },
            {
                title: '检测日期',
                dataIndex: 'testDate',
                render: (text, row, index) => row && row ? <Typography>{renderDate(row.testDate)}</Typography> : '',
            },
            {
                title: '下次检测',
                dataIndex: 'nextTestDate',
                render: (text, row, index) => row && row ? <Typography>{renderDate(row.nextTestDate)}</Typography> : '',
            },
            // {
            //     title: '上传时间',
            //     dataIndex: 'updateTime',
            //     align: 'left',
            //     render: (text, row, index) => row && row ? <Typography>{renderDate(row.updateTime)}</Typography> : '',
            // },
            {
                title: '文档状态',
                dataIndex: 'status',
                render: (text, row, index) => row && row ? <Typography>{renderDocStatus(row.status)}</Typography> : '',
            }, {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, row, index) => renderDocActions(row),
            },
        ]

        return <Table columns={expandedColumns}
            dataSource={expandedData}
            className={classes.normalTable}
            rowClassName={getRowClass}
            pagination={false}
            size={'small'}
            rowKey={'docId'}
        // scroll={{ x: true }}
        ></Table>
    }

    const getRowClass = (record, index) => {
        return (index % 2 !== 0 ? classes.oddRow : null);
    }


    return (
        <div alignitems="center" className={classes.planitemroot}>
            {isLoading ? null :
                <Grid className={classes.detailtable}>
                    <Table
                        className={classes.stripedTable}
                        rowKey={record => record.orderId + '_' + record.dtlId}
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        expandable={{ expandedRowRender }}
                        size={'small'}
                        scroll={{ y: 'calc(100vh - 295px)' }}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: () => `共${initListData.total}条`,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            pageSize: search.size,
                            current: search.page,
                            total: initListData.total,
                            onChange: (current, pageSize) => onhandlePageChange(current, pageSize)
                        }}
                    // scroll={{ x: '100%' }}

                    ></Table>
                    {/* <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={initListData.list}
                        options={workOrderOptions()}
                        localization={localization}
                        style={tableStyle}
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    rowsPerPage={pageSize ? pageSize : 5}
                                    count={initListData.total}
                                    page={page}
                                    onChangePage={(event, pageNum) => {
                                        setPage(pageNum);
                                        getOperationPlanOrders({
                                            offset: pageNum * (pageSize ? pageSize : 5)
                                        })
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        setPageSize(event.target.value);
                                        setPage(0);
                                        getOperationPlanOrders({
                                            offset: 0
                                        })
                                    }}

                                />
                            )
                        }}
                        actions={renderActions()}
                    >
                    </MaterialTable> */}
                </Grid>
            }
        </div>
    );
};

OperationPlanOrderList.propTypes = {
    classes: PropTypes.object.isRequired,
    initListData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    getOperationPlanOrders: PropTypes.func.isRequired,
};

OperationPlanOrderList.defaultProps = {
    initListData: {
        list: []
    },
    search: { page: 1, size: 10 },
    isLoading: false,
    departmentList: [],
    companyList: [],
    userProfile: {},
}

export default withStyles(OperationPlanOrderList);
