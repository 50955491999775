import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    Chip,
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    ListItemText,
    Switch
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inventoryValid } from '@/utils/inspection';
import { getMoment as moment, getDateByString } from '@/utils/datetime';
import _ from 'lodash';


const AddDetailPannel = ({
    classes,
    open,
    intervalTime,
    currentUserInfo,
    userProfile,
    frequencyDate,
    leaders,
    categoryList,
    inventoryList,
    majorHazardlist,
    getDptChecklistCount,
    checkCompanyRelation,
    planDetailError,
    planDateError,
    plan,
    planDetail,
    companyList,
    departmentList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleInspectionHeadChange,
    onhandleDateChange,
    onhandleListMapping
}) => {

    const hasCatCode = Boolean(_.find(categoryList, { 'pcode': planDetail.inspectType }));

    // 重大危险源
    const isINS05 = Boolean(planDetail.inspectType && planDetail.inspectType === 'INS05');

    // 分厂人员
    const isBranchComp = Boolean(_.find(companyList, o => o.companyId === userProfile.roles[0].companyId).pid);

    // 用户所属部门
    const userDptId = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0].dptId : null;


    const maxStartDate = plan ? moment()(plan.year + '0101', 'YYYYMMDD').endOf('year') : moment()().add(1, 'year').endOf('year');
    const minStartDate = plan ? moment()(plan.year + '0101', 'YYYYMMDD').startOf('year') : moment()().add(1, 'year').startOf('year');

    const maxEndDate = _.cloneDeep(maxStartDate);
    const minEndDate = planDetail.startTime ? moment()(planDetail.startTime).endOf('day') : minStartDate.endOf('day');

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick maxWidth={'lg'}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"新增计划明细 - " +
                        onhandleListMapping(planDetail.inspectType, 'inspectType')}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                {hasCatCode &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label='子分类'
                                            error={planDetailError.inspectTypeCat !== ''}
                                            helperText={planDetailError.inspectTypeCat}
                                            select
                                            value={planDetail.inspectTypeCat || ''}
                                            name='inspectTypeCat'
                                            onChange={onhandleChange}>
                                            {categoryList.filter(item => (item.pcode === planDetail.inspectType)).map((cat) => (
                                                <MenuItem dense key={cat.code} value={cat.code}>
                                                    {cat.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='检查表'
                                        error={planDetailError.inventoryId !== ''}
                                        helperText={planDetailError.inventoryId}
                                        select
                                        value={planDetail.inventoryId || ''}
                                        name='inventoryId'
                                        onChange={onhandleChange}>
                                        {
                                            inventoryList.map((ivt) => (
                                                <MenuItem dense key={ivt.inventoryId} value={ivt.inventoryId} disabled={!inventoryValid(ivt)}>
                                                    {ivt.inventoryName + (!inventoryValid(ivt) ? '(未生效)' : '')}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                {isINS05 &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label='重大危险源'
                                            error={planDetailError.targetId !== ''}
                                            helperText={planDetailError.targetId}
                                            select
                                            value={planDetail.targetId || ''}
                                            name='targetId'
                                            onChange={onhandleChange}>
                                            {majorHazardlist.filter(item => (planDetail.companyId ? item.majorHazardDevices[0].companyId === planDetail.companyId : true)).map((mh) => (
                                                <MenuItem dense key={mh.hazardId} value={mh.hazardId}>
                                                    {mh.hazardName + ' - ' + mh.installName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="受检单位"
                                        error={planDetailError.inspectionCompanyIdList !== ''}
                                        helperText={planDetailError.inspectionCompanyIdList}
                                        select
                                        disabled={isINS05}
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue: () => (
                                                <div className={classes.chips}>
                                                    {
                                                        planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.map((value) =>
                                                            <Chip size={'small'} key={value} label={onhandleListMapping(value, 'company')} className={classes.chip} />
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                        value={planDetail.inspectionCompanyIdList ? planDetail.inspectionCompanyIdList : []}
                                        name='inspectionCompanyIdList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            companyList.map(
                                                company => (
                                                    <MenuItem dense key={company.companyId} value={company.companyId} disabled={isBranchComp && company.companyId !== currentUserInfo.companyId}>
                                                        <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionCompanyIdList && planDetail.inspectionCompanyIdList.indexOf(company.companyId) > -1} />
                                                        <ListItemText primary={company.companyAlias} />
                                                    </MenuItem>)
                                            )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField className={classes.textField}
                                        variant="outlined"
                                        label="检查部门"
                                        error={planDetailError.inspectionDptStrList !== ''}
                                        helperText={planDetailError.inspectionDptStrList}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            MenuProps: {
                                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                                transformOrigin: { vertical: "center", horizontal: "center" },
                                                getContentAnchorEl: null
                                            },
                                            renderValue:
                                                (selected) => (
                                                    <div className={classes.chips}>
                                                        {
                                                            planDetail.inspectionDptStrList && planDetail.inspectionDptStrList.map(value =>
                                                                <Chip size={'small'} key={value} label={onhandleListMapping(value, 'departmentEdit')} className={classes.chip} />
                                                            )
                                                        }
                                                    </div>),
                                        }}
                                        value={planDetail.inspectionDptStrList ? planDetail.inspectionDptStrList : []}
                                        name='inspectionDptStrList'
                                        onChange={onhandleChange}
                                    >
                                        {
                                            departmentList
                                                .filter(dpt => checkCompanyRelation(dpt))
                                                .sort((a, b) => b.companyPid ? -1 : 1)
                                                .map(dpt => (
                                                    <MenuItem dense key={dpt.dptId} value={dpt.dptId} disabled={getDptChecklistCount(dpt) === 0 || (isBranchComp && userDptId !== dpt.dptId)}>
                                                        <Checkbox className={classes.checkbox} size={'small'} checked={planDetail.inspectionDptStrList && planDetail.inspectionDptStrList.indexOf(dpt.dptId) > -1} />
                                                        <ListItemText className={classes.menuText} primary={dpt.companyDptName} />
                                                        <ListItemText className={classes.menuSubText} primary={
                                                            getDptChecklistCount(dpt) <= 0 ? '无排查项' : getDptChecklistCount(dpt) + '排查项'
                                                        } />
                                                    </MenuItem>)
                                                )
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <Autocomplete
                                        className={classes.textField}
                                        options={leaders}
                                        onChange={(e, newValue) => onhandleInspectionHeadChange(e, newValue)}
                                        onClose={event => onhandleCheck(event, 'inspectHead')}
                                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                        getOptionSelected={(option, value) => {
                                            return option.userId === value.userId
                                        }}
                                        noOptionsText='无可选人员'
                                        value={_.find(leaders, { 'userId': planDetail.inspectHead }) || null}
                                        renderOption={option => (
                                            <Grid container justify={'space-between'}>
                                                <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                                <Typography variant={'subtitle2'}>{option.roleName || ''}</Typography>
                                            </Grid>
                                        )}
                                        renderInput={(params) => <TextField {...params}
                                            label='负责人'
                                            placeholder="搜索并选择负责人"
                                            variant="outlined"
                                            error={planDetailError.inspectHead !== ''}
                                            helperText={planDetailError.inspectHead}
                                        />}
                                    />
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        multiline
                                        label={"排查内容"}
                                        variant='outlined'
                                        className={classes.textField}
                                        value={planDetail.opentext || ''}
                                        rows={6}
                                        name='opentext'
                                        onChange={event => onhandleChange(event, 'opentext')}
                                    // onBlur={event => onhandleCheck(event, 'opentext')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item className={classes.col}>
                            <Grid item className={classes.itemLabelGrid}>
                                <FormControlLabel label={<Typography variant="h5" style={planDetail.frequencyDate ? { fontWeight: 600 } : { color: 'gray' }}>按频次创建排查</Typography>} control={
                                    // <Checkbox size={'small'} name="frequencyDate"
                                    //     checked={planDetail.frequencyDate}
                                    //     onChange={onhandleChange} />
                                    <Switch size="small" checked={planDetail.frequencyDate} onChange={onhandleChange} name="frequencyDate" />
                                } />
                            </Grid>

                            {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                <RadioGroup row name="frequencyType"
                                    value={planDetail.frequencyType}
                                    onChange={onhandleChange} >
                                    <FormControlLabel value={'day'} control={<Radio size={'small'} />} label="每日" />
                                    <FormControlLabel value={'workday'} control={<Radio size={'small'} />} label="每工作日" />
                                </RadioGroup>
                                <RadioGroup row name="frequencyType"
                                    value={planDetail.frequencyType}
                                    onChange={onhandleChange} >
                                    <FormControlLabel value={'week'} control={<Radio size={'small'} />} label="每周" />
                                    <FormControlLabel value={'fortnight'} control={<Radio size={'small'} />} label="每2周" />
                                    <FormControlLabel value={'month'} control={<Radio size={'small'} />} label="每月" />
                                </RadioGroup>
                            </Grid>}

                            {!frequencyDate && <Grid item className={classes.itemDateGrid}>
                                <RadioGroup row aria-label="排查时间类型（单日/区间）" name="intervalTime"
                                    value={planDetail.intervalTime}
                                    onChange={onhandleChange} >
                                    <FormControlLabel value={'false'} control={<Radio size={'small'} />} label="单日排查" />
                                    <FormControlLabel value={'true'} control={<Radio size={'small'} />} label="多日排查" />
                                </RadioGroup>
                            </Grid>}

                            <Grid item container className={classes.itemDateGrid} wrap={'nowrap'}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={intervalTime ? null : classes.textField}
                                        error={planDateError.startTime !== ''}
                                        helperText={planDateError.startTime}
                                        InputProps={{ readOnly: true }}
                                        views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                        openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                        disableToolbar={planDetail.frequencyType !== 'month'}
                                        disablePast
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        maxDate={maxStartDate}
                                        minDate={minStartDate}
                                        maxDateMessage={<Typography>不能超过计划年度</Typography>}
                                        minDateMessage={<Typography>不能早于计划年度</Typography>}
                                        format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                        name='startTime'
                                        label={intervalTime ? "开始日期" : "检查日期"}
                                        value={planDetail.startTime || null}
                                        onChange={date => onhandleDateChange(date, 'startTime')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                {intervalTime &&
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={classes.textFieldInline}
                                            error={planDateError.inspectTime !== ''}
                                            helperText={planDateError.inspectTime}
                                            InputProps={{ readOnly: true }}
                                            views={planDetail.frequencyType === 'month' ? ['year', 'month'] : ['date']}
                                            openTo={planDetail.frequencyType === 'month' ? 'month' : undefined}
                                            disableToolbar={planDetail.frequencyType !== 'month'}
                                            disablePast
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            maxDate={maxEndDate}
                                            minDate={minEndDate}
                                            maxDateMessage={<Typography>不能超过计划年度</Typography>}
                                            minDateMessage={<Typography>不能早于开始日期</Typography>}
                                            format={planDetail.frequencyType === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                                            name='inspectTime'
                                            label={"截止日期"}
                                            value={planDetail.inspectTime || null}
                                            onChange={date => onhandleDateChange(date, 'inspectTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                }
                            </Grid>
                            {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                {Boolean(planDateError.inspectTime === '' && planDetail.planDates && planDetail.planDates.length > 0) &&
                                    <>
                                        <Typography display={'block'}>{'将创建以下【' + planDetail.planDates.length + '】种时间段的临时计划：'}</Typography>
                                        {
                                            planDetail.planDates.map((planDate, index) => {
                                                return (
                                                    <Typography display={'block'} key={index}>
                                                        <span>{
                                                            '【' + (index + 1) + '】'
                                                            + getDateByString(planDate.startTime, true)
                                                            + (
                                                                moment()(planDate.startTime).isSame(planDate.inspectTime, 'day') ? ''
                                                                    : ' ~ ' + getDateByString(planDate.inspectTime, true)
                                                            )
                                                        }</span>
                                                        {/* tip未调试完成 */}
                                                        {/* {
                                                                planDate.hasTip ?
                                                                    <Tooltip title="该时间段不满一个周期，请谨慎创建">< ErrorOutlineIcon color={'error'} fontSize={'small'} /></Tooltip>
                                                                    : null
                                                            } */}
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </Grid>}
                            {frequencyDate && <Grid item className={classes.itemDateGrid}>
                                <Typography display={'block'}>
                                    <span className={classes.required}> *</span>
                                    {' 起止时间段如果不足每周/每2周/每月，也会作为排查时间段加入，请谨慎选择。'}
                                </Typography>
                            </Grid>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        新增</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
AddDetailPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    planDetail: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    leaders: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    intervalTime: PropTypes.bool.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

AddDetailPannel.defaultProps = {

}

export default withStyles(AddDetailPannel);