import React from "react";
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  List,
} from '@material-ui/core';
import {
  Alert, AlertTitle
} from '@material-ui/lab';
import _ from 'lodash';
import { arrayToTree } from "@/utils/constant";
import {
  getActionerApproval
} from '@/utils/processUtils';
import ApprovalInstanceLine from "@/components/ApprovalInstanceLine";

import Done from '@material-ui/icons/Done';
import Block from '@material-ui/icons/Block';
import { getDateByString } from '@/utils/datetime';

const DeviceFromApproval = props => {
  const {
    classes,
    open,
    processApprovalStatus,
    workOrderNotices,
    deviceFormApprovalInstance,
    currentUserInfo,
    isEditApprovalInstance,
    onhandleSave,
    onhandleDrawerClose,
    onhandleProcessApprovalEdit,
    onhandleHandleProcessApproval,
  } = props;


  const renderDate = (date) => {
    return date ? getDateByString(date) : ''
  }

  const renderPermitPanel = () => {
    let bg = 'info';

    let lastestRecord = _.chain(deviceFormApprovalInstance)
      .reject({ actionType: 'INIT' })
      .orderBy(['confirmTime'], ['desc'])
      .head().value()

    if (lastestRecord) {
      if (lastestRecord.actionType === 'APPROVE') {
        bg = 'success'
      } else if (lastestRecord.actionType === 'REJECT') {
        bg = 'error'
      } else {
        bg = 'warning'
      }
    }
    return bg;
  }

  const renderHistoy = () => {
    return (
      _.chain(deviceFormApprovalInstance)
        .reject({ actionType: 'INIT' })
        .filter((item) => { return item.aprvId })
        .orderBy(['confirmTime'], ['desc']).value().map((record, idx) => {
          return <ListItem key={`${record.aprvId}`} className={classes.permitHistoryItem} dense >
            <ListItemIcon style={{ minWidth: '2em' }}>
              {record.actionType === 'SUBMIT' && <Done fontSize={'small'} className={classes.confirmTrue} />}
              {record.actionType === 'APPROVE' && <Done fontSize={'small'} className={classes.confirmTrue} />}
              {record.actionType === 'REJECT' && <Block fontSize={'small'} className={classes.confirmFalse} />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Grid container>
                  <Grid item>
                    <Typography display={'inline'} color={'primary'}>{renderDate(record.confirmTime ? record.confirmTime : record.createTime)}</Typography>
                    <Typography display={'inline'} variant={'h5'} style={{ display: 'inline-block', minWidth: 64, marginLeft: 16 }}><strong>{' ' + record.lastName + record.firstName + ' '}</strong></Typography>
                    {renderConfirm(record)}
                    <Typography display={'inline'} style={{ display: 'inline-block', minWidth: 48, marginLeft: 16 }}>{Boolean(record.opentext) ? record.opentext : null}</Typography>
                  </Grid>
                </Grid>
              }
            />
          </ListItem >
        })
    )
  }
  const renderConfirm = (row) => {
    let style = null;
    let text = '开始审批';
    if (row.actionType === 'SUBMIT') {
      style = classes.confirmTrue;
      text = '提交审批';
    } else if (row.actionType === 'APPROVE') {
      style = classes.confirmTrue;
      text = '通过';
    } else if (row.actionType === 'REJECT') {
      style = classes.confirmFalse;
      text = '驳回';
    }
    return <Typography display={'inline'} className={style} style={{ display: 'inline-block', minWidth: 48, marginLeft: 16 }}>{text}</Typography>
  }
  const renderActions = () => {
    let myApproval = getActionerApproval(deviceFormApprovalInstance, currentUserInfo.roleId, currentUserInfo.userId)
    return <List className={classes.processAction}>
      {processApprovalStatus === 'todo' && <Button
        variant={"contained"}
        color="primary"
        className={classes.button}
        onClick={onhandleProcessApprovalEdit}
      >{'配置流程'}
      </Button>
      }
      {isEditApprovalInstance &&
        <Button className={classes.button} variant="contained" color='primary'
          onClick={onhandleSave}>保存
        </Button>}
      {myApproval && !myApproval.preId &&
        <Button
          variant={"contained"}
          color="primary"
          className={classes.button}
          onClick={() => onhandleHandleProcessApproval(myApproval, { code: 'SUBMIT', name: '提交' })}
        >{'提交'}
        </Button>}
      {myApproval && myApproval.preId &&
        <Button
          variant={"contained"}
          color="primary"
          className={classes.button}
          onClick={() => onhandleHandleProcessApproval(myApproval, { code: 'APPROVE', name: '通过' })}
        >{'通过'}
        </Button>}
      {myApproval && myApproval.preId &&
        <Button
          variant={"outlined"}
          color="error"
          className={classes.button}
          onClick={() => onhandleHandleProcessApproval(myApproval, { code: 'REJECT', name: '驳回' })}
        >{'驳回'}
        </Button>}
    </List>
  }


  const renderProcessLineWithUser = () => {

    if (deviceFormApprovalInstance && deviceFormApprovalInstance.length > 0) {

      if (deviceFormApprovalInstance[0].aprvId) { //数据库返回审批实例
        return <ApprovalInstanceLine idKey={'aprvId'}
          approvalTree={arrayToTree(_.cloneDeep(_.filter(deviceFormApprovalInstance, { actionType: 'INIT' })), 'aprvId', 'preId')}
          approvalRecords={_.reject(deviceFormApprovalInstance, { actionType: 'INIT' })}
          processNotices={workOrderNotices} />
      } else { //新建审批实例
        return <ApprovalInstanceLine idKey={'aprvIdString'}
          approvalTree={arrayToTree(_.cloneDeep(deviceFormApprovalInstance,), 'aprvIdString', 'preIdString')}
          approvalRecords={[]}
          singleShow={true}
          processNotices={workOrderNotices} />
      }
    }
    return <Grid></Grid>
  }

  return (
    <Drawer anchor={'right'} open={open} PaperProps={{ style: { minWidth: '60%' } }}
      onClose={onhandleDrawerClose}>
      <Alert
        className={classes.alert}
        classes={{
          message: classes.permitBar,
        }}
        severity={renderPermitPanel()}>
        <AlertTitle>
          <Grid container justify={'space-between'}>
            <Typography variant={'h4'}>{'审批状态'}</Typography>
          </Grid>
        </AlertTitle>
        <Grid container>
          <Grid item className={classes.processline}>
            {renderProcessLineWithUser()}
          </Grid>
        </Grid>
        {_.reject(deviceFormApprovalInstance, { 'actionType': 'INIT' }).length > 0 ?
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item className={classes.processUser}>
              <Typography variant={'h4'}>审批记录</Typography>
              <List className={classes.historyList}>
                {renderHistoy()}
              </List>
            </Grid>
          </Grid>
          : null
        }
        <Grid item style={{ height: '100%' }} ></Grid>
        <Grid item container justify={'flex-end'} className={classes.processActionGrid}>
          {renderActions()}
        </Grid>
      </Alert>
    </Drawer>);
};

DeviceFromApproval.propTypes = {
  deviceFormApprovalInstance: PropTypes.array.isRequired,
};

DeviceFromApproval.defaultProps = {
  deviceFormApprovalInstance: [],
};

export default withStyles(DeviceFromApproval);
