// import * as _ from "lodash";
import { getStore } from '../../../../redux/store';
import {
    markChangeDevice,
    markChangeUnit,
    markChangeArea,
    markChangeDeviceGroup,
    addConnectDeviceArea,
    addDisconnectDeviceArea,
    clearDeviceAreaRelation,
    cleanRecalDeviceGroup,
    addToDeviceGroup,
    updAutoSave
} from '../../redux/modules/installationRelation'
import * as _ from 'lodash';
import KonvaUtil from "./util";
const konvaUtil = new KonvaUtil();
export default class ActionUtil {

    markChange(id) {
        let store = getStore();
        let ids = _.split(id, '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let type = ids[0];
            if (type === 'Unit') {
                let units = store.getState().installationRelation.modifyUnits;
                if (!_.find(units, function (o) { return o.unitId === id; })) {
                    let unit = _.find(store.getState().installation.editInstallation.units, function (o) { return o.unitId === id; })
                    store.dispatch(markChangeUnit(unit));
                }
            } else if (type === 'Area') {
                let areas = store.getState().installationRelation.modifyAreas;
                if (!_.find(areas, function (o) { return o.areaId === id; })) {
                    let unit = _.find(store.getState().installation.editInstallation.areas, function (o) { return o.areaId === id; })
                    store.dispatch(markChangeArea(unit));
                }
            } else if (type === 'DeviceGroup') {
                let deviceGroups = store.getState().installationRelation.modifyDeviceGroup;
                if (!_.find(deviceGroups, function (o) { return o.groupId === id; })) {
                    let deviceGroup = _.find(store.getState().installation.editInstallation.deviceGroups, function (o) { return o.groupId === id; })
                    store.dispatch(markChangeDeviceGroup(deviceGroup));
                }
            } else {
                let devices = store.getState().installationRelation.modifyDevices;
                if (!_.find(devices, function (o) { return o.deviceId === id; })) {
                    let device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; });
                    device.type = type;
                    store.dispatch(markChangeDevice(device));
                }
            }
        }
    }

    caculateDeviceIntersectionWithAreaOrUnit(shape, layer) {
        let store = getStore();
        let ids = _.split(shape.id(), '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let type = ids[0];
            let units = layer.find('.Unit');
            if (units && units.length > 0) {
                let unitFlg = false;
                units.forEach(unit => {
                    if (konvaUtil.haveIntersection(unit.getClientRect(), shape.getClientRect())) {
                        let unitIntersection = false;
                        if (type === 'Guandao' || type === 'Xian') {
                            let startPoint = shape.findOne('.start')
                            if (konvaUtil.haveIntersection(unit.getClientRect(), startPoint.getClientRect())) {
                                unitIntersection = true;
                            } else {
                                unitIntersection = false;
                            }
                        } else {
                            unitIntersection = true;
                        }
                        if (unitIntersection) {
                            unitFlg = true;
                            let unitIds = _.split(unit.id(), '_');
                            if (unitIds && unitIds.length > 1) {
                                let unitId = _.toInteger(unitIds[1]);
                                let devices = store.getState().installationRelation.modifyDevices;
                                let device = _.find(devices, function (o) { return o.deviceId === id; });
                                if (!device) {
                                    device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; })
                                }
                                device.unitId = unitId
                                device.type = type;
                                store.dispatch(markChangeDevice(device));
                            }
                        }
                    }
                })
                if (!unitFlg) {
                    let devices = store.getState().installationRelation.modifyDevices;
                    let device = _.find(devices, function (o) { return o.deviceId === id; });
                    if (!device) {
                        device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; })
                    }
                    device.unitId = 0;
                    device.type = type;
                    store.dispatch(markChangeDevice(device));
                }
            }
            let areas = layer.find('.Area');
            if (areas && areas.length > 0) {
                areas.forEach(area => {
                    let areaIds = _.split(area.id(), '_');
                    if (areaIds && areaIds.length > 1) {
                        let areaId = _.toInteger(areaIds[1]);
                        let areaIntersection = false;
                        if (konvaUtil.haveIntersection(area.getClientRect(), shape.getClientRect())) {
                            if (type === 'Guandao' || type === 'Xian') {
                                let startPoint = shape.findOne('.start')
                                if (konvaUtil.haveIntersection(area.getClientRect(), startPoint.getClientRect())) {
                                    areaIntersection = true;
                                } else {
                                    areaIntersection = false;
                                }
                            } else {
                                areaIntersection = true;
                            }
                        }

                        if (areaIntersection) {
                            let devices = store.getState().installationRelation.modifyDevices;
                            let device = _.find(devices, function (o) { return o.deviceId === id; });
                            if (!device) {
                                device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; })
                            }
                            store.dispatch(clearDeviceAreaRelation({ areaId: areaId, deviceId: id }));
                            if (device.areas && device.areas.length > 0) {
                                if (!_.find(device.areas, function (o) { return o.areaId === areaId; })) {
                                    store.dispatch(addConnectDeviceArea({ areaId: areaId, deviceId: id }));
                                }
                            } else {
                                store.dispatch(addConnectDeviceArea({ areaId: areaId, deviceId: id }));
                            }
                            device.type = type;
                            store.dispatch(markChangeDevice(device));
                        } else {
                            let devices = store.getState().installationRelation.modifyDevices;
                            let device = _.find(devices, function (o) { return o.deviceId === id; });
                            if (!device) {
                                device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; })
                            }
                            store.dispatch(clearDeviceAreaRelation({ areaId: areaId, deviceId: id }));
                            if (device.areas && device.areas.length > 0) {
                                if (_.find(device.areas, function (o) { return o.areaId === areaId; })) {
                                    store.dispatch(addDisconnectDeviceArea({ areaId: areaId, deviceId: id }));
                                    device.type = type;
                                    store.dispatch(markChangeDevice(device));
                                }
                            }
                        }
                    }
                })
            }
            let devices = store.getState().installationRelation.modifyDevices;
            let device = _.find(devices, function (o) { return o.deviceId === id; });
            if (!device) {
                this.markChange(shape.id());
            }
        }
    }

    caculateUnitIntersectionWithDevice(unit, layer) {
        let store = getStore();
        let ids = _.split(unit.id(), '_');
        if (ids && ids.length > 1) {
            let unitId = _.toInteger(ids[1]);
            let groupArray = layer.find('Group');
            let deviceArray = _.reject(groupArray, function (o) { return (!o.name() || o.name() === 'Unit' || o.name() === 'Area' || o.name() === 'DeviceGroup'); });
            // console.log(deviceArray);
            if (deviceArray && deviceArray.length > 0) {
                deviceArray.forEach(deviceSignal => {
                    let deviceIds = _.split(deviceSignal.id(), '_');
                    if (deviceIds && deviceIds.length > 1) {
                        let deviceId = _.toInteger(deviceIds[1]);
                        let type = deviceIds[0];
                        // console.log(deviceId)
                        let devices = store.getState().installationRelation.modifyDevices;
                        let device = _.find(devices, function (o) { return o.deviceId === deviceId; });
                        if (!device) {
                            device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === deviceId; })
                        }
                        let deviceIntersection = false;
                        if (konvaUtil.haveIntersection(unit.getClientRect(), deviceSignal.getClientRect())) {
                            if (type === 'Guandao' || type === 'Xian') {
                                let startPoint = deviceSignal.findOne('.start')
                                if (konvaUtil.haveIntersection(unit.getClientRect(), startPoint.getClientRect())) {
                                    deviceIntersection = true;
                                } else {
                                    deviceIntersection = false;
                                }
                            } else {
                                deviceIntersection = true;
                            }
                        }

                        if (deviceIntersection) {
                            if (device.unitId !== unitId) {
                                device.unitId = unitId;
                                device.type = type;
                                store.dispatch(markChangeDevice(device));
                            }
                        } else {
                            if (device.unitId === unitId) {
                                device.unitId = 0;
                                device.type = type;
                                store.dispatch(markChangeDevice(device));
                            }
                        }
                    }
                })
            }
        }
    }

    caculateAreaIntersectionWithDevice(area, layer) {
        let store = getStore();
        let ids = _.split(area.id(), '_');
        if (ids && ids.length > 1) {
            let areaId = _.toInteger(ids[1]);
            let groupArray = layer.find('Group');
            let deviceArray = _.reject(groupArray, function (o) { return (!o.name() || o.name() === 'Unit' || o.name() === 'Area' || o.name() === 'DeviceGroup'); });
            // console.log(deviceArray);
            if (deviceArray && deviceArray.length > 0) {
                deviceArray.forEach(deviceSignal => {
                    let deviceIds = _.split(deviceSignal.id(), '_');
                    if (deviceIds && deviceIds.length > 1) {
                        let deviceId = _.toInteger(deviceIds[1]);
                        let type = deviceIds[0];
                        let devices = store.getState().installationRelation.modifyDevices;
                        let device = _.find(devices, function (o) { return o.deviceId === deviceId; });
                        if (!device) {
                            device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === deviceId; })
                        }
                        store.dispatch(clearDeviceAreaRelation({ areaId: areaId, deviceId: deviceId }));
                        let deviceIntersection = false;
                        if (konvaUtil.haveIntersection(area.getClientRect(), deviceSignal.getClientRect())) {
                            if (type === 'Guandao' || type === 'Xian') {
                                let startPoint = deviceSignal.findOne('.start')
                                if (konvaUtil.haveIntersection(area.getClientRect(), startPoint.getClientRect())) {
                                    deviceIntersection = true;
                                } else {
                                    deviceIntersection = false;
                                }
                            } else {
                                deviceIntersection = true;
                            }
                        }

                        if (deviceIntersection) {
                            // console.log('caculateAreaIntersectionWithDevice1:',device)
                            if (device.areas && device.areas.length > 0) {
                                if (!_.find(device.areas, function (o) { return o.areaId === areaId; })) {
                                    store.dispatch(addConnectDeviceArea({ areaId: areaId, deviceId: deviceId }));
                                    device.type = type;
                                    store.dispatch(markChangeDevice(device));
                                }
                            } else {
                                store.dispatch(addConnectDeviceArea({ areaId: areaId, deviceId: deviceId }));
                                device.type = type;
                                store.dispatch(markChangeDevice(device));
                            }
                        } else {
                            // console.log('caculateAreaIntersectionWithDevice2:',device)
                            if (device.areas && device.areas.length > 0) {
                                if (_.find(device.areas, function (o) { return o.areaId === areaId; })) {
                                    store.dispatch(addDisconnectDeviceArea({ areaId: areaId, deviceId: deviceId }));
                                    device.type = type;
                                    store.dispatch(markChangeDevice(device));
                                }
                            }
                        }
                    }
                })
            }
        }
    }

    caculateDeviceIntersectionWithDeviceGroup(shape, layer, action = 'tranformer') {
        let store = getStore();
        let ids = _.split(shape.id(), '_');
        if (ids && ids.length > 1) {
            let id = _.toInteger(ids[1]);
            let type = ids[0];
            let device = _.find(store.getState().installationRelation.modifyDevices, function (o) { return o.deviceId === id; });
            if (!device) {
                device = _.find(store.getState().devices.searchDevicelist.list, function (o) { return o.deviceId === id; })
            }
            let deviceGroupId = device.groupId;
            let deviceGroup = layer.findOne('#DeviceGroup_' + deviceGroupId)
            if (deviceGroup) {
                let devices = _.filter(store.getState().devices.searchDevicelist.list, function (o) { return o.groupId === deviceGroupId; });
                let mapDeviceList = []
                devices.forEach(innerDevice => {
                    let cat = innerDevice.cat
                    let device_type = _.find(store.getState().devices.deviceLegends, function (o) { return o.cat === cat; }).legend;
                    let mapDevice = layer.findOne('#' + device_type + '_' + innerDevice.deviceId);
                    mapDeviceList.push({
                        id: mapDevice.id(),
                        name: mapDevice.find('Text')[0].text(),
                        x1: mapDevice.getClientRect().x,
                        y1: mapDevice.getClientRect().y,
                        x2: mapDevice.getClientRect().x + mapDevice.getClientRect().width,
                        y2: mapDevice.getClientRect().y + mapDevice.getClientRect().height,
                    });
                })

                let x = Math.min.apply(Math, mapDeviceList.map(function (o) { return o.x1 }));
                let y = Math.min.apply(Math, mapDeviceList.map(function (o) { return o.y1 }));
                let width = Math.max.apply(Math, mapDeviceList.map(function (o) { return o.x2 })) - x;
                let height = Math.max.apply(Math, mapDeviceList.map(function (o) { return o.y2 })) - y;
                let rectTemp = deviceGroup.findOne('.backGround');
                rectTemp.x(x);
                rectTemp.y(y);
                rectTemp.width(width);
                rectTemp.height(height);
                layer.draw();
                let textLabel = deviceGroup.find('Text')[0];
                textLabel.x(rectTemp.x() + 16);
                textLabel.y(rectTemp.y() - 15);
                let circleControl = deviceGroup.findOne('.main');
                circleControl.x(rectTemp.x() + 8);
                circleControl.y(rectTemp.y() - 11);
                layer.draw();

                device.type = type;
                store.dispatch(markChangeDevice(device));
                device.deviceGroup.type = 'DeviceGroup'
                store.dispatch(markChangeDeviceGroup(device.deviceGroup));
            } else if (action === 'drag') {
                let deviceGroups = layer.find('.DeviceGroup');
                if (deviceGroups && deviceGroups.length > 0) {
                    deviceGroups.forEach(deviceGroup => {
                        let groupIds = _.split(deviceGroup.id(), '_');
                        if (groupIds && groupIds.length > 1) {
                            let groupId = _.toInteger(groupIds[1]);
                            let isIntersection = false;
                            if (type === 'Guandao' || type === 'Xian') {
                                if (konvaUtil.shape1ContainShape2(deviceGroup.getClientRect(), shape.getClientRect())) {
                                    isIntersection = true;
                                }
                            } else {
                                if (konvaUtil.haveIntersection(deviceGroup.getClientRect(), shape.getClientRect())) {
                                    isIntersection = true;
                                }
                            }
                            if (isIntersection) {
                                store.dispatch(addToDeviceGroup(device, groupId));
                                store.dispatch(markChangeDevice(device));
                            }
                        }
                    })
                }
            }
        }
    }
}

export function recaculateDeviceGroup(props, id, layer, previewStage) {
    let store = getStore();
    let deviceGroup = layer.findOne('#DeviceGroup_' + id)
    if (deviceGroup) {
        let devices = _.filter(store.getState().devices.searchDevicelist.list, function (o) { return o.groupId === id; });
        if (devices && devices.length > 1) {
            let deviceGroupData = {}
            let mapDeviceList = []
            devices.forEach(device => {
                deviceGroupData = device.deviceGroup;
                let cat = device.cat
                let device_type = _.find(store.getState().devices.deviceLegends, function (o) { return o.cat === cat; }).legend;
                let mapDevice = layer.findOne('#' + device_type + '_' + device.deviceId);
                mapDeviceList.push({
                    id: mapDevice.id(),
                    name: mapDevice.find('Text')[0].text(),
                    x1: mapDevice.getClientRect().x,
                    y1: mapDevice.getClientRect().y,
                    x2: mapDevice.getClientRect().x + mapDevice.getClientRect().width,
                    y2: mapDevice.getClientRect().y + mapDevice.getClientRect().height,
                });
            })
            let x = Math.min.apply(Math, mapDeviceList.map(function (o) { return o.x1 }));
            let y = Math.min.apply(Math, mapDeviceList.map(function (o) { return o.y1 }));
            let width = Math.max.apply(Math, mapDeviceList.map(function (o) { return o.x2 })) - x;
            let height = Math.max.apply(Math, mapDeviceList.map(function (o) { return o.y2 })) - y;
            let rectTemp = deviceGroup.findOne('.backGround');
            rectTemp.x(x);
            rectTemp.y(y);
            rectTemp.width(width);
            rectTemp.height(height);
            if (!rectTemp.fill()) {
                rectTemp.moveToTop();
            } else {
                rectTemp.moveToBottom();
            }
            layer.draw();
            let textLabel = deviceGroup.find('Text')[0];
            textLabel.x(rectTemp.x() + 16);
            textLabel.y(rectTemp.y() - 15);
            let circleControl = deviceGroup.findOne('.main');
            circleControl.x(rectTemp.x() + 8);
            circleControl.y(rectTemp.y() - 11);
            layer.draw();

            let units = layer.find('.Unit');
            if (units) {
                units.forEach(unit => {
                    unit.moveToBottom();
                })
            }
            let areas = layer.find('.Area');
            if (areas) {
                areas.forEach(area => {
                    area.moveToBottom();
                })
            }
            layer.draw();

            deviceGroupData.type = 'DeviceGroup'
            store.dispatch(markChangeDeviceGroup(deviceGroupData));
            store.dispatch(cleanRecalDeviceGroup());
            konvaUtil.updateView(props, layer, previewStage);
            store.dispatch(updAutoSave(true));
        } else {
            let deviceGroupData = devices[0].deviceGroup;
            deviceGroup.destroy();
            layer.draw();
            deviceGroupData.type = 'DeviceGroup';
            store.dispatch(markChangeDeviceGroup(deviceGroupData));
            store.dispatch(cleanRecalDeviceGroup());
            konvaUtil.updateView(props, layer, previewStage);
        }
    }
}