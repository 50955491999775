import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ValuationFormulaPop from '@/components/CommonPage/ValuationFormulaPop'

/**
 * 服务定价
 */
class ValuationFormulaPopContainer extends Component {
    static propTypes = {
        attrList: PropTypes.array,//可选公式属性列表
        anchorEl: PropTypes.object,//选择的节点
        serviceName: PropTypes.string,//选择行服务名称
        valuationFormulaStr: PropTypes.string,//公式显示名称
        valuationFormula: PropTypes.string,//公式实际值
        valuationVars: PropTypes.array,//公式关键字内容
        handlePopClose: PropTypes.func,//关闭弹框事件
        handleUpdFormula: PropTypes.func,//更新公式事件
    }

    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            valuationFormula: '',
            outputFormula: '',
            valuationVars: [],
            servicName: '',
            valuationFormulaStr: '',
            outputFormulaStr: '',
            oldPopChangeValue: '',
            attrList: [{ id: 1, name: 'new' }],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            anchorEl: nextProps.anchorEl,
            attrList: nextProps.attrList,
            formulaType: nextProps.formulaType,
            valuationFormula: nextProps.valuationFormula,
            outputFormula: nextProps.outputFormula,
            serviceName: nextProps.serviceName || '',
            valuationVars: nextProps.valuationVars,
            valuationFormulaStr: nextProps.valuationFormulaStr,
            outputFormulaStr: nextProps.outputFormulaStr,
            oldPopChangeValue: '',
        });
    }

    /**
     * 公式输入框change事件
     * 筛选入力值,仅允许增加字符
     * @param {*} event 
     */
    handlePopChange = (event) => {
        let valuationFormula = this.state.valuationFormula;
        let valuationFormulaStr = this.state.valuationFormulaStr;
        let outputFormula = this.state.outputFormula;
        let outputFormulaStr = this.state.outputFormulaStr;
        let oldPopChangeValue = this.state.oldPopChangeValue;
        let targetValue = event.target.value;
        let changeValue = targetValue.substr(targetValue.length - 1, 1);
        const reg = new RegExp(/[0-9()/.+*/->=<]/);

        if (oldPopChangeValue.length < targetValue.length && changeValue.match(reg)) {
            if (this.state.formulaType === 'OUTPUT') {
                outputFormula = outputFormula + changeValue;
                outputFormulaStr = targetValue;
                this.setState({
                    outputFormula: outputFormula,
                    outputFormulaStr: outputFormulaStr,
                    oldPopChangeValue: targetValue
                });
            } else {
                valuationFormula = valuationFormula + changeValue;
                valuationFormulaStr = targetValue;
                this.setState({
                    valuationFormula: valuationFormula,
                    valuationFormulaStr: valuationFormulaStr,
                    oldPopChangeValue: targetValue
                });
            }

        }
    }

    /**
     * 
     * [重设]按钮点击事件</br>
     * 清空公式值
     */
    handleResetClick = () => {
        if (this.state.formulaType === 'OUTPUT') {
            this.setState({
                outputFormula: '',
                outputFormulaStr: '',
                oldPopChangeValue: '',
            })
        } else {
            this.setState({
                valuationFormula: '',
                valuationFormulaStr: '',
                valuationVars: [],
                oldPopChangeValue: '',
            })
        }

    }

    /**
     * 
     * [设定]按钮点击事件</br>
     * 设定公式传回父画面</br>
     */
    handleSetClick = () => {
        const valuationFormula = this.state.valuationFormula;
        const valuationFormulaStr = this.state.valuationFormulaStr;
        const outputFormula = this.state.outputFormula
        const outputFormulaStr = this.state.outputFormulaStr
        const valuationVars = JSON.stringify(this.state.valuationVars);
        this.props.handleUpdFormula(valuationFormula, valuationFormulaStr, outputFormula, outputFormulaStr, valuationVars);
    }

    /**
     * 公式可选属性点击事件
     * 增加属性至公式
     * @param {*} data 
     */
    handleAttrClick = (data) => {
        if (this.state.formulaType === 'OUTPUT') {
            let outputFormula = this.state.outputFormula;
            let outputFormulaStr = this.state.outputFormulaStr;
            outputFormulaStr = outputFormulaStr + '{' + data.name + '}';
            outputFormula = outputFormula + data.id;
            this.setState({
                outputFormulaStr: outputFormulaStr,
                outputFormula: outputFormula,
            })
        } else {
            let valuationFormula = this.state.valuationFormula;
            let valuationFormulaStr = this.state.valuationFormulaStr;
            valuationFormulaStr = valuationFormulaStr + '{' + data.name + '}';
            valuationFormula = valuationFormula + data.id;
            let valuationVars = this.state.valuationVars ? this.state.valuationVars : [];
            valuationVars.push(data.id)
            this.setState({
                valuationFormulaStr: valuationFormulaStr,
                valuationFormula: valuationFormula,
                valuationVars: valuationVars
            })
        }

    }

    /**
     * 关闭Pop画面
     */
    handlePopClose = () => {
        this.props.handlePopClose();
    }

    render() {
        return (
            <ValuationFormulaPop
                anchorEl={this.state.anchorEl}
                attrList={this.state.attrList}
                serviceName={this.state.serviceName}
                formulaType={this.state.formulaType}
                valuationFormulaStr={this.state.valuationFormulaStr}
                outputFormulaStr={this.state.outputFormulaStr}
                onhandlePopClose={this.handlePopClose.bind(this)}
                onhandlePopChange={this.handlePopChange.bind(this)}
                onhandleResetClick={this.handleResetClick.bind(this)}
                onhandleSetClick={this.handleSetClick.bind(this)}
                onhandleAttrClick={this.handleAttrClick.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValuationFormulaPopContainer);
