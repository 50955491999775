import React, { Component } from 'react';
import { connect } from 'react-redux';
import WarehousingComponent from '@/components/ManufactureManagement/Warehousing';
import UpdateInstockComponent from '@/components/ManufactureManagement/Warehousing/UpdateInstock/UpdateInstockComponent';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getInstock, getInstocks, updateManufactureInstock } from '@/redux/modules/manufacture/manufactureInstock';
import { getOrders } from '@/redux/modules/manufacture/manufactureOrder';
import * as _ from 'lodash'

class WarehousingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            isShow: false,
            isediting: false,
            instockInfo: {},
            componentTitle: "",
            openFlg: 0,
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const instockTime = formatDate(getMoment()());
        this.props.getInstocks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, instockTime, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const instockTime = formatDate(date);
            this.props.getInstocks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, instockTime, undefined);
        })
    }

    handleEdit = (rowData) => {
        this.setState({ componentTitle: "编辑生产入库" });
        this.getInfo(rowData.instockId, false);
    }

    handleOpen = (rowData) => {
        this.setState({ componentTitle: "查看生产入库" });
        this.getInfo(rowData.instockId, true);
    }

    getInfo = (infoId, isShow) => {
        this.props.getInstock(infoId).then((data) => {
            this.setState({
                instockInfo: data && data.payload ? data.payload : {},
                openFlg: 1,
                isShow: isShow
            }, function () {
                // this.props.getOrders(undefined,this.state.instockInfo.instockCompany);
            })
        });
    }

    getCompany = () => {
        const { companies } = this.props;
        const company = _.find(companies, item => {
            return item.pid && this.props.userInfo.companyId === item.companyId
        });
        return company ? company.companyId : undefined
    }

    handleSave = () => {
        const instockInfo = this.state.instockInfo;
        const newInstockInfo = {
            instockId: instockInfo.instockId,
            orderId: instockInfo.orderId,
        }
        this.props.updateManufactureInstock(instockInfo.instockId, newInstockInfo).then((data) => {
            this.handleClose();
        });
    }

    handleClose = () => {
        this.setState({
            openFlg: 0
        })
        const instockTime = formatDate(this.state.selectedDate);
        this.props.getInstocks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, instockTime, undefined);
    }

    handleTextFieldChange = (event) => {
        const instockInfo = this.state.instockInfo;
        if (event.target.name === 'orderId') {
            this.setState({
                instockInfo: {
                    ...instockInfo,
                    orderId: event.target.value
                }
            });
        }
    }

    render() {
        return (
            <div>
                <WarehousingComponent
                    selectedDate={this.state.selectedDate}
                    instockList={this.props.instockList}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    onhandleOpen={this.handleOpen.bind(this)}
                    onhandleEdit={this.handleEdit.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
                {
                    this.state.openFlg === 1 && <UpdateInstockComponent
                        isediting={this.state.isediting}
                        isShow={this.state.isShow}
                        instockInfo={this.state.instockInfo}
                        orders={this.props.orders}
                        companies={this.props.companies}
                        departments={this.props.departments}
                        componentTitle={this.state.componentTitle}
                        onhandleSave={this.handleSave.bind(this)}
                        onhandleClose={this.handleClose.bind(this)}
                        onhandleTextFieldChange={this.handleTextFieldChange.bind(this)}
                    />
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        instockList: state.manufactureInstock.instockList,
        userInfo: state.auth.currentUserInfo,
        orders: state.manufactureOrder.orders,
        companies: state.company.companyList.list,
        departments: state.department.departments.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInstocks: (instockDpt, stockDpt, instockCompany, stockCompany, orderId, status, instockTime, orderIds) => {
            dispatch(getInstocks(instockDpt, stockDpt, instockCompany, stockCompany, orderId, status, instockTime, orderIds));
        },
        getInstock: (instockId) => {
            return dispatch(getInstock(instockId));
        },
        updateManufactureInstock: (instockId, instockDto) => {
            return dispatch(updateManufactureInstock(instockId, instockDto));
        },
        getOrders: (startTime, companyId) => {
            dispatch(getOrders(startTime, companyId));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarehousingContainer);
