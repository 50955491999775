import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMoment } from '../../../utils/datetime'
import Schedule from '../../../components/OnsiteInspectionManagement/Schedule';
import ScheduleList from '../../../components/OnsiteInspectionManagement/Schedule/ScheduleList';
import _ from 'lodash'
import SchedulePanel from '../../../components/OnsiteInspectionManagement/Schedule/SchedulePanel';
import {
    getPlanList,
    initSchedulePage,
    setSearch,
    setPlanTableOpen,
    setPlanPanelOpen,
    deletePlan,
    updatePlan,
    setCurrentPlan,
    createPlan,
    setPlanError
} from '../../../redux/modules/onsiteInspectionSchedule';
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';
import { createMessage } from '../../../redux/modules/message';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';

class ScheduleContainer extends Component {

    componentDidMount() {
        this.props.onhandleInitPage()
    }

    handleGetPlanList() {
        this.props.onhandleSearch()
    }

    handleChange(value, field) {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                this.handleGetPlanList()
                break;
            case 'selectedDate':
                newSearch = {
                    ...this.props.search,
                    selectedDate: getMoment()(value)
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                this.handleGetPlanList()
                break;
            case 'inspector':
                newSearch = {
                    ...this.props.currentPlan,
                    inspector: value.target.value
                };
                //重新获取人员的部门ID
                let obj = _.find(this.props.leaders, function (o) { return o.userId === value.target.value })
                if (obj) {
                    newSearch.dptId = obj.dptId
                }
                this.props.onhandleSelectedPlan(newSearch)
                break;
            case 'routeId':
                newSearch = {
                    ...this.props.currentPlan,
                    routeId: value.target.value
                };
                this.props.onhandleSelectedPlan(newSearch)
                break;
            case 'startTime':
                newSearch = {
                    ...this.props.currentPlan,
                    startTime: value.target.value,
                    oldStartTime: this.props.currentPlan.startTime
                };
                this.props.onhandleSelectedPlan(newSearch)
                break;
            default:
            // do nothing
        }
    }

    handleOpenPlanPanel(event) {
        this.props.onhandlePlanPanelOpen(true)
        this.props.onhandleSelectedPlan(event)
        let planError = {
            dptId: '',
            inspector: '',
            routeId: '',
            startTime: '',
        }
        this.props.onhandleSetError(planError)

    }
    handleClosePlanPanel() {
        this.props.onhandlePlanPanelOpen(false)
    }

    handleAdd(data) {
        let obj = _.find(this.props.leaders, function (o) { return o.userId === data.inspector })
        if (obj) {
            data.dptId = obj.dptId
        }
        this.props.onhandleCreate(data)
    }

    handleUpdate(data) {
        //重新获取人员的部门ID
        let value = _.find(this.props.leaders, function (o) { return o.userId === data.inspector })
        if (value) {
            data.dptId = value.dptId
        }
        this.props.onhandleUpdate(data)
    }

    handleDelete(data) {
        this.props.onhandleDelete(data)
    }

    handlePanelDelete(data) {
        let changeStatus = () => {
            this.props.onhandleDelete(data)
            this.props.onhandlePlanPanelOpen(false)
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除路线为[' + data.routeName + ']的管理巡检计划吗?', changeStatus, null, false);
    }

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'inspector') {
            if (_.trim(this.props.currentPlan.inspector) === '') {
                err['inspector'] = '管理人不能为空';
            } else {
                err['inspector'] = '';
            }
        }

        if (!field || field === 'routeId') {
            if (_.trim(this.props.currentPlan.routeId) === '') {
                err['routeId'] = '巡检线路不能为空';
            } else {
                err['routeId'] = '';
            }
        }

        if (!field || field === 'startTime') {
            if (_.trim(this.props.currentPlan.startTime) === '') {
                err['startTime'] = '开始时间不能为空';
            } else {
                let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
                let tomorrowTime = new Date(tomorrow).getTime();
                let startTime = new Date(this.props.currentPlan.startTime).getTime()
                if (tomorrowTime > startTime) {
                    err['startTime'] = '开始时间不能早于明天';
                } else {
                    err['startTime'] = '';
                }
            }
        }
        let newError = {
            ...this.props.planError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handlePanelSave = async (data) => {
        await this.handleCheck(data, null);
        if (_.findIndex(Object.values(this.props.planError), function (o) { return o !== ''; }) < 0) {
            if (this.props.currentPlan.planId) {
                this.props.onhandleUpdate(data)
            } else {
                this.props.onhandleCreate(data)
            }
        }
        // if (!this.validateStartTime(this.props.currentPlan)) {
        //     this.props.onhandleErrorStartTime()
        // } else {
        //     this.props.onhandleUpdate(data)
        //     this.props.onhandlePlanPanelOpen(false)
        // }
    }

    handleClosePlanTable() {
        this.props.onhandlePlanTableOpen(false)
    }

    handleOpenPlanTable(event) {
        this.props.onhandlePlanTableOpen(true)
        let newSearch = {
            ...this.props.search,
        };
        if (event && event.length > 0) {
            newSearch.selecteDay = getMoment()(event[0].start)
        } else {
            newSearch.selecteDay = getMoment()()
        }

        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleErrorStartTime() {
        this.props.onhandleErrorStartTime();
    }

    filterCurrentDayPlanList() {
        const selecteDay = getMoment()(this.props.search.selecteDay)
        let reuslt = _.filter(this.props.planList, a => {
            return selecteDay.startOf('day').diff(getMoment()(a.startTime).startOf('day'), 'days') === 0
        })

        return reuslt;
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    trimLeadersList() {
        let companyTree = this.trimCompanyTree()
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
            let result = _.filter(this.props.leaders, function (o) { return companyId === o.companyId; })
            return result
        } else {
            return this.props.leaders
        }
    }

    render() {
        return (
            <div>
                <Schedule
                    constant={this.props.constant}
                    planList={this.props.planList}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    search={this.props.search}
                    currentUserInfo={this.props.currentUserInfo}
                    onhandleOpenPlanPanel={this.handleOpenPlanPanel.bind(this)}
                    onhandleOpenPlanList={this.handleOpenPlanTable.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                />
                {this.props.planTableOpen &&
                    <ScheduleList
                        currentDayPlanList={this.filterCurrentDayPlanList()}
                        userProfile={this.props.userProfile}
                        open={this.props.planTableOpen}
                        leaders={this.trimLeadersList()}
                        departmentList={this.props.departmentList}
                        routeList={this.props.routeList}
                        onhandleClose={this.handleClosePlanTable.bind(this)}
                        onhandleOpenPlanPanel={this.handleOpenPlanPanel.bind(this)}
                        onhandleAdd={this.handleAdd.bind(this)}
                        onhandleUpdate={this.handleUpdate.bind(this)}
                        onhandleDelete={this.handleDelete.bind(this)}
                        onhandleErrorStartTime={this.handleErrorStartTime.bind(this)}
                        onhandlePanelDelete={this.handlePanelDelete.bind(this)}
                    />}
                {this.props.planPanelOpen && <SchedulePanel
                    userProfile={this.props.userProfile}
                    open={this.props.planPanelOpen}
                    leaders={this.trimLeadersList()}
                    departmentList={this.props.departmentList}
                    routeList={this.props.routeList}
                    currentPlan={this.props.currentPlan}
                    onhandleClose={this.handleClosePlanPanel.bind(this)}
                    onhandlePanelSave={this.handlePanelSave.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandlePanelDelete={this.handlePanelDelete.bind(this)}
                    planError={this.props.planError}
                    onhandleCheck={this.handleCheck.bind(this)}
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        currentPlan: state.onsiteInspectionSchedule.currentPlan,
        planTableOpen: state.onsiteInspectionSchedule.planTableOpen,
        planPanelOpen: state.onsiteInspectionSchedule.planPanelOpen,
        planList: state.onsiteInspectionSchedule.planList.list,
        search: state.onsiteInspectionSchedule.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        leaders: state.onsiteInspectionSchedule.leaders,
        departmentList: state.onsiteInspectionSchedule.departmentList,
        routeList: state.onsiteInspectionSchedule.routeList,
        planError: state.onsiteInspectionSchedule.planError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSearch: () => {
            dispatch(getPlanList());
        },
        onhandleInitPage: () => {
            dispatch(initSchedulePage());
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandlePlanTableOpen: (data) => {
            dispatch(setPlanTableOpen(data));
        },
        onhandlePlanPanelOpen: (data) => {
            dispatch(setPlanPanelOpen(data));
        },
        onhandleUpdate: (data) => {
            dispatch(updatePlan(data))
        },
        onhandleDelete: (data) => {
            dispatch(deletePlan(data))
        },
        onhandleSelectedPlan: (data) => {
            dispatch(setCurrentPlan(data))
        },
        onhandleErrorStartTime: () => {
            dispatch(createMessage('error', '开始时间不能早于明天'));
        },
        onhandleCreate: (data) => {
            dispatch(createPlan(data))
        },
        onhandleSetError: (err) => {
            dispatch(setPlanError(err))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleContainer);