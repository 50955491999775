import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    MenuItem,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { formatDate_hms } from '@/utils/datetime';


const CreateMActivityRecordComponent = props => {
    const {
        classes,
        componentTitle,
        open,
        mactivityRecord,
        companies,
        payDpts,
        onhandleClose,
        onhandleSave,
        onhandleTextFieldChange,
        onhandleUpdateActionRow,
        roleFlag,
    } = props;

    const tableLocalization = {
        ...localization,
        pagination: {},
        toolbar: {}
    }

    const tableOptions = {
        ...options,
        minBodyHeight: 120,
        maxBodyHeight: 250,
        pageSize: 5,
        pageSizeOptions: [5],
        pagination: false,
    }
    const tableStyle = {
        ...style,
        border: '1px solid rgb(183,183,183)',
    }

    const renderAttrValue = (row) => {
        if (!row.attrValue) {
            return ''
        }
        let attrValue = Number(row.attrValue)
        if (isNaN(attrValue)) {
            attrValue = row.attrValue
        } else {
            attrValue = attrValue.toFixed(2)
        }

        return <Typography>{attrValue}</Typography>
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'attrName',
                render: row => <Typography>{row.attrAlias ? row.attrAlias : row.attrName}</Typography>,
                editable: 'never'
            },
            // {
            //     title: '别名',
            //     field: 'attrAlias',
            //     render: row => <Typography>{row.attrAlias}</Typography>
            // },
            {
                title: '属性值',
                field: 'attrValue',
                render: row => renderAttrValue(row)
            },
            {
                title: '单位',
                field: 'attrUnit',
                render: row => <Typography>{row.attrUnit}</Typography>,
                editable: 'never'
            },

        ]
        return columns
    }

    const assemblyData = () => {
        console.log(mactivityRecord.recordAttrs)
        return mactivityRecord.recordAttrs;
    }

    // const textFieldDefault = {
    //     style: {
    //         width: '180px',
    //     },
    //     startAdornment: (
    //         <InputAdornment position="start">
    //             <p></p>
    //         </InputAdornment>)
    // }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{componentTitle}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid>
                            <Grid container>
                                {/* <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={isActivityEditing}
                                        variant="outlined"
                                        placeholder=""
                                        label='任务活动'
                                        name='taskId'
                                        InputProps={textFieldDefault}
                                        value={mactivityRecord.taskId}
                                        onChange={onhandleTextFieldChange} >
                                        {userTasks.map(i => (<MenuItem key={i.taskId} value={i.taskId}>{i.activityName}</MenuItem>))}
                                    </TextField>
                                </Grid> */}
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='关联服务'
                                        name='serviceName'
                                        value={mactivityRecord.manufactureService.serviceName}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                        label='关联工单号'
                                        name='orderNo'
                                        value={mactivityRecord.manufactureOrder.orderNo}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        placeholder=""
                                        label='需方'
                                        name='payCompany'
                                        value={mactivityRecord.payCompany}
                                        InputProps={{
                                            readOnly: true,
                                            style: {
                                                width: '180px',
                                            }
                                        }} onChange={onhandleTextFieldChange} >
                                        {companies.map(i => (<MenuItem key={i.companyId} value={i.companyId}>{i.companyName}</MenuItem>))}
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        placeholder=""
                                        label='需方班组'
                                        name='payDpt'
                                        value={mactivityRecord.payDpt}
                                        InputProps={{
                                            readOnly: true,
                                            style: {
                                                width: '180px',
                                            }
                                        }} onChange={onhandleTextFieldChange} >
                                        {payDpts.map(i => (<MenuItem key={i.dptId} value={i.dptId}>{i.dptName}</MenuItem>))}
                                    </TextField>
                                </Grid>

                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='执行时间'
                                        name='triggerTime'
                                        value={mactivityRecord.triggerTime ? formatDate_hms(mactivityRecord.triggerTime) : ''}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            {/* <Grid container>
                                <Grid className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDateTimePicker
                                            fullWidth
                                            autoOk
                                            ampm={false}
                                            inputVariant="outlined"
                                            format="yyyy/MM/dd HH:mm"
                                            id="date-selector"
                                            margin="normal"
                                            label='执行时间'
                                            disabled={isediting || isShow}
                                            InputProps={textFieldDefault}
                                            value={mactivityRecord.triggerTime}
                                            onChange={onhandleDateChange}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            name='triggerTime'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid> */}
                        </Grid>

                        <Grid>
                            <Grid container>
                                <Grid item xs={12}> <Divider /></Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="column" >
                                        <Grid item className={classes.itemGrid}>
                                            <MaterialTable
                                                columns={assemblycolumns()}
                                                data={assemblyData()}
                                                options={tableOptions}
                                                localization={tableLocalization}
                                                style={tableStyle}
                                                title='活动属性列表'
                                                editable={
                                                    {
                                                        isEditHidden: () => !roleFlag,
                                                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {

                                                            if (oldData.attrPermission !== 'R') {
                                                                onhandleUpdateActionRow(newData, oldData);
                                                            }
                                                            resolve()
                                                        })
                                                    }
                                                }
                                            >
                                            </MaterialTable>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                                <Grid item xs={5} >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        <Button onClick={onhandleSave} color="primary"> 保存
                        </Button>
                    }
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreateMActivityRecordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateMActivityRecordComponent.defaultProps = {
    open: false,
    payDisable: true,
    isediting: true,
    isShow: false,
    recevieDisable: true,
    participateDisable: true,
    installs: [],
    companies: [],
    payDpts: [],
    userTasks: [],
    mactivityRecord: {},
    manufactureDicts: []
};

export default withStyles(CreateMActivityRecordComponent);