import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const styles = theme => ({
  root: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    color: theme.palette.grey[500],
  },
  androidIcon: {
    display: 'block',
    margin: '0 auto',
    color: colors.green[500]
  },
  appleIcon: {
    display: 'block',
    margin: '0 auto',
    color: colors.red[500]
  },
  qrCode: {
    display: 'block',
    margin: '0 auto',
  },
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20,
    margin: 5
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  label: {
    marginTop: 5
  },
  gridItem: {
    height: 24
  },
  select: {
    width: '90%',
    marginTop: 7
  },
});

export default withStyles(styles);

