import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import TemporaryRegulationPlanList from "../../../../containers/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanList";

const TemporaryRegulationPlan = props => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <TemporaryRegulationPlanList/>
            </div>
        </div>
    );
};
TemporaryRegulationPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(TemporaryRegulationPlan);
