import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20
  },
  canvas: {
    width: '100%',
    height: '100%'
  },
  propView: {
    width: '25%',
    float: 'right',
    overflowX: 'auto',
    marginTop: '5px',
    overflowY: 'auto'
  },
  bpmnView: {
    width: '75%',
    maxHeight: '800px',
    height: '550px',
    float: 'left'
  },
  textField: {
    minWidth: 300
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  tabs: {
    background: 'none',
    color: '#2979ff',
    '&:hover': {
      color: '#2979ff',
      opacity: 1
    },
    '&:focus': {
      color: '#2979ff'
    }
  },
  selectError: {
    color: '#e53935',
    margin: 0,
    fontSize: '11px',
    marginTop: '3px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeight: '400',
    lineHeight: '13px',
    letterSpacing: '0.33px'
  }
});

export default withStyles(styles);
