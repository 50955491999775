import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  childContent: {
    padding: theme.spacing(1, 2)
  },
  unitDetail: {
    // paddingTop: theme.spacing(5)
  },
  flexgrow: {
    flexGrow: 1
  },
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    // padding: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  errorColor: {
    color: theme.palette.error.main
  },
  listItem: {
    height: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.hover2
    }
  },
  selected: {
    backgroundColor: theme.palette.selected + '!important'
  }
});

export default withStyles(styles);
