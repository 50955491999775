import React, { Component } from 'react';
import UserCard from '../components/UserCard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from '../redux/modules/usercard';

class UserCardContainer extends Component {

    static propTypes = {
        isloading: PropTypes.bool,
        currentUser: PropTypes.object
    }

    handleOpen() {
        this.props.onhandleGetUser(this.props.userId)
    }

    render() {
        return (
            <UserCard
                isloading={this.props.isloading}
                currentUser={this.props.currentUser}
                child={this.props.child}
                onhandleOpen={this.handleOpen.bind(this)}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isloading: state.usercard.isloading,
        currentUser: state.usercard.currentUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetUser: (userId) => {
            dispatch(getUser(userId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCardContainer);