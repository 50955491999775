import React, { Component } from 'react';
import { connect } from 'react-redux';
import UnitEditComponent from '@/components/InstallationManagement/InstallationUnit'
import {
    createInstallationUnit,
    updInstallationUnit,
    getInstallationUnits,
    routeToInstallationList,
} from "@/redux/modules/installation";
import {
    getDevicesbyUint,
    connectDevice2Unit,
    disconnectDevice2Unit
} from "@/redux/modules/devices";

import PropTypes from "prop-types";
import * as _ from 'lodash';
import UnitInfoEditPannel from '@/components/InstallationManagement/InstallationUnit/UnitInfoEditPannel';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';

import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';



class InfoEditContainer extends Component {
    static propTypes = {
        editInstallation: PropTypes.object,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
        editUnits: PropTypes.array,
        userProfile: PropTypes.object,
        currentUserInfo: PropTypes.object,
    };

    constructor(props) {
        super(props)
        this.state = {
            search: {
                page: 1,
                size: 10,
            },
            openUnitEdit: false, //open edit unit dailog
            selectedUnit: {},
            selectedDeviceKeys: [],
        }
    }

    componentDidMount() {
        const { editInstallation, onhandleGetUnits } = this.props
        if (editInstallation.installId) {
            onhandleGetUnits(editInstallation.installId)
        }
    }

    backToList() {
        this.props.onhandleBackToList();
    }

    handleOpenEditUnitDailog(unitId) {

        const { editUnits } = this.props
        let selectedUnit = {}
        if (unitId) {
            let unit = _.find(editUnits, { 'unitId': unitId })
            selectedUnit = _.clone(unit)
        }
        this.setState({
            openUnitEdit: true,
            selectedUnit: selectedUnit
        })

    }

    handleCloseEditUnitDailog() {

        const { editUnits } = this.props
        const { selectedUnit } = this.state

        let orginSelectedUnit = {}
        if (selectedUnit.unitId) {
            let unit = _.find(editUnits, { 'unitId': selectedUnit.unitId })
            orginSelectedUnit = _.clone(unit)
        }
        this.setState({
            openUnitEdit: false,
            selectedUnit: orginSelectedUnit
        })
    }

    async handleSaveUnit() {
        const { selectedUnit } = this.state
        const { editInstallation,
            onhandleGetUnits,
            onhandleUpdateInstallationUnit,
            onhandleCreateInstallationUnit } = this.props
        if (selectedUnit.unitId) {
            //update
            let dto = {
                unitId: selectedUnit.unitId,
                unitName: selectedUnit.unitName,
                installId: editInstallation.installId,
            }
            await onhandleUpdateInstallationUnit(dto)
        } else {
            //create

            let dto = {
                unitName: selectedUnit.unitName,
                installId: editInstallation.installId,
                // creator: currentUserInfo.userId,
            }
            await onhandleCreateInstallationUnit(dto)
        }

        await onhandleGetUnits(editInstallation.installId)
        this.setState({
            openUnitEdit: false
        })
    }

    handleChangeUnit(event) {
        let newUnit = { ...this.state.selectedUnit }
        if (event.target.name === 'unitName') {
            newUnit['unitName'] = event.target.value
        }

        this.setState({
            selectedUnit: newUnit
        })
    }

    onhandleOpenConfirm() {
        const { selectedUnit } = this.state
        const { editInstallation, onhandleDeleteInstallationUnit, onhandleGetUnits, onhandleOpenConfirmDialog } = this.props

        onhandleOpenConfirmDialog(
            '删除单元确认对话框',
            '你确定要删除【' + selectedUnit.unitName + '】吗?',
            async () => {
                await onhandleDeleteInstallationUnit({
                    unitId: selectedUnit.unitId,
                    unitName: selectedUnit.unitName,
                    installId: editInstallation.installId
                })
                await onhandleGetUnits(editInstallation.installId)
                this.setState({
                    selectedUnit: {}
                })
            },
            null,
        )
    }

    handleSelectUnit(unitId) {
        const { editUnits, onhandleGetUnitDevices } = this.props
        let selectedUnit = {}
        if (unitId) {
            let unit = _.find(editUnits, { 'unitId': unitId })
            selectedUnit = _.clone(unit)
        }
        let size = this.state.search.size
        this.setState({
            selectedUnit: selectedUnit,
            search: {
                page: 1,
                size: size,
            }
        })
        onhandleGetUnitDevices(selectedUnit.installId, selectedUnit.unitId, 0, size)
    }

    handlePageChange(page, size) {

        const { selectedUnit } = this.state
        const { onhandleGetUnitDevices } = this.props
        this.setState({
            search: {
                page: page,
                size: size,
            }
        })
        onhandleGetUnitDevices(selectedUnit.installId, selectedUnit.unitId, (page - 1) * size, size)
    }

    handleSelectDeviceKeys(keys) {
        this.setState({
            selectedDeviceKeys: keys
        })
    }

    handleOpenDeviceSearch() {
        const { selectedUnit, search, } = this.state

        const { currentUserInfo,
            editInstallation,
            onhandleSelectDeviceCheck,
            onhandleConnectDevice2Unit,
            onhandleGetUnitDevices,
        } = this.props

        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            install: true,
            installId: editInstallation.installId,
            company: true,
            companyId: currentUserInfo.companyId,
            callback: (selectedDevices) => {
                return new Promise((resolve, reject) => {
                    if (selectedDevices.length === 0) {
                        onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let deviceUnits = []
                        for (let device of selectedDevices) {
                            deviceUnits.push({
                                deviceId: device.deviceId,
                                unitId: selectedUnit.unitId,
                                installId: selectedUnit.installId,
                            })
                        }
                        onhandleConnectDevice2Unit(deviceUnits).then(
                            async () => {
                                await onhandleGetUnitDevices(selectedUnit.installId,
                                    selectedUnit.unitId,
                                    (search.page - 1) * search.size,
                                    search.size)
                                this.setState({
                                    selectedDeviceKeys: []
                                })
                            }
                        )
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);

    }

    async handleDisconnectDevice2Unit() {

        const { selectedDeviceKeys, selectedUnit, search } = this.state
        const { onhandleDisconnectDevice2Unit, onhandleGetUnitDevices, onhandleOpenConfirmDialog } = this.props

        onhandleOpenConfirmDialog(
            '确认对话框',
            '你确定要删除这【' + selectedDeviceKeys.length + '】项设备与单元【' + selectedUnit.unitName + '】吗?',
            async () => {

                let deviceUnits = []
                for (let deviceId of selectedDeviceKeys) {
                    deviceUnits.push({
                        deviceId: deviceId,
                        unitId: selectedUnit.unitId
                    })
                }

                await onhandleDisconnectDevice2Unit(deviceUnits)
                await onhandleGetUnitDevices(selectedUnit.installId,
                    selectedUnit.unitId,
                    (search.page - 1) * search.size,
                    search.size)
                this.setState({
                    selectedDeviceKeys: []
                })
            },
            null,
        )
    }

    render() {

        return (
            <React.Fragment>
                <UnitEditComponent
                    editInstallation={this.props.editInstallation}
                    editUnits={this.props.editUnits}
                    editUnitDevices={this.props.editUnitDevices}
                    selectedUnit={this.state.selectedUnit}
                    selectedDeviceKeys={this.state.selectedDeviceKeys}
                    companies={this.props.companies}
                    search={this.state.search}
                    departments={this.props.departments}
                    onBackToList={this.backToList.bind(this)}
                    onhandleSelectUnit={this.handleSelectUnit.bind(this)}
                    onhandleOpenEditUnitDailog={this.handleOpenEditUnitDailog.bind(this)}
                    onhandleDeleteUnit={this.onhandleOpenConfirm.bind(this)}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                    onhandleSelectDeviceKeys={this.handleSelectDeviceKeys.bind(this)}
                    onhandleDisconnectDevice2Unit={this.handleDisconnectDevice2Unit.bind(this)}
                    onhandleOpenDeviceSearch={this.handleOpenDeviceSearch.bind(this)}
                />
                <UnitInfoEditPannel
                    unit={this.state.selectedUnit}
                    open={this.state.openUnitEdit}
                    onhandleClose={this.handleCloseEditUnitDailog.bind(this)}
                    onhandleConfirm={this.handleSaveUnit.bind(this)}
                    onhandleChangeUnit={this.handleChangeUnit.bind(this)}
                />
            </React.Fragment>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        editInstallation: state.installation.editInstallation,
        editUnits: state.installation.editUnits,
        editUnitDevices: state.devices.unitDeviceList,
        companies: state.constant.companies,
        departments: state.constant.departments,
        currentUserInfo: state.auth.currentUserInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateInstallationUnit: (unitDto) => {
            return dispatch(updInstallationUnit(unitDto))
        },
        onhandleDeleteInstallationUnit: (unitDto) => {
            return dispatch(updInstallationUnit({
                ...unitDto,
                status: 'inactive'
            }))
        },
        onhandleCreateInstallationUnit: (unitDto) => {
            return dispatch(createInstallationUnit(unitDto))
        },
        onhandleGetUnits: (installId) => {
            return dispatch(getInstallationUnits(installId))
        },
        onhandleBackToList: () => {
            return dispatch(routeToInstallationList());
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            return dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
        onhandleGetUnitDevices: (installId, unitId, offset, size) => {
            return dispatch(getDevicesbyUint(installId, unitId, offset, size));
        },
        onhandleConnectDevice2Unit: (deviceUnits) => {
            return dispatch(connectDevice2Unit(deviceUnits));
        },
        onhandleDisconnectDevice2Unit: (deviceUnits) => {
            return dispatch(disconnectDevice2Unit(deviceUnits));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InfoEditContainer)
