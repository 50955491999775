import React from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Link,
    Typography,
    Container,
    Box,
    FormHelperText,
    Grid
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import withStyles from './styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import beianIcon from '@/assets/备案图标.png';

function Copyright() {
    return (
        <React.Fragment>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://anfenginfo.com/">
                    大连荣鹄信息科技有限公司
                </Link>
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link color="inherit" href="http://beian.miit.gov.cn">
                    辽ICP备2021011663号-1
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <img src={beianIcon} alt={'公安备案图标'} />
                <Link color="inherit" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21029602000629">
                    辽公网安备 21029602000629号
                </Link>
            </Typography>
        </React.Fragment>
    );
}

const ForgotPassword = props => {

    const { classes,
        onhandleResetPassword,
        onhandleChange,
        onhandleCheck,
        onhandleSmsCode,
        resetPasswordInfo,
        smsClock
    } = props;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountCircleOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    重置密码
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                label="手机号码"
                                name="phone"
                                value={resetPasswordInfo.phone || ''}
                                onChange={event => onhandleChange(event, 'phone')}
                                onBlur={event => onhandleCheck(event, 'phone')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="smsCode"
                                label="手机验证码"
                                name="smsCode"
                                value={resetPasswordInfo.smsCode || ''}
                                onChange={event => onhandleChange(event, 'smsCode')}
                                onBlur={event => onhandleCheck(event, 'smsCode')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={smsClock && smsClock !== 180}
                                className={classes.sms}
                                onClick={onhandleSmsCode}>
                                {!smsClock || smsClock === 180 ? "获取验证码" : smsClock + "秒后重发"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="newPwd"
                                label="新密码"
                                type="password"
                                id="newPwd"
                                value={resetPasswordInfo.newPwd || ''}
                                onChange={event => onhandleChange(event, 'newPwd')}
                                onBlur={event => onhandleCheck(event, 'newPwd')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirmPwd"
                                label="确认密码"
                                type="password"
                                id="confirmPwd"
                                value={resetPasswordInfo.confirmPwd || ''}
                                onChange={event => onhandleChange(event, 'confirmPwd')}
                                onBlur={event => onhandleCheck(event, 'confirmPwd')}
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText error={resetPasswordInfo.errorMessage !== ''}>
                        {resetPasswordInfo.errorMessage}</FormHelperText>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onhandleResetPassword}>
                        重置密码
                    </Button>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    resetPasswordInfo: PropTypes.any.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleResetPassword: PropTypes.func.isRequired,
    onhandleSmsCode: PropTypes.func.isRequired,
    smsClock: PropTypes.number.isRequired
};

export default withRouter(withStyles(ForgotPassword));