import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  titleContainer: {
    padding: 10,
    marginRight: 5,
    marginBottom: 5
  },
  label: {
    whiteSpace: 'no-wrap'
  },
  card: {
    paddingTop: 20,
    marginRight: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between'
  },
  summaryGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  notifiSummary: {
    margin: '2px',
    lineHeight: '30px',
  },
  icongrid: {
    display: 'flex',
    alignItems: 'center'
  },
  lowSeverityColor: {
    color: theme.palette.warning.main,
  },
  mediumSeverityColor: {
    color: colors.orange[700],
  },
  highSeverityColor: {
    color: theme.palette.error.main,
  },
  notifiText: {
    margin: 10,
    fontSize: theme.typography.h3.fontSize,
  },
  tabs: {
    background: 'none',
    color: '#2979ff',
    '&:hover': {
      color: '#2979ff',
      opacity: 1
    },
    '&:focus': {
      color: '#2979ff'
    }
  },
  indicator: {

  },
  active: {
    width: 60,
  },
  evaluated: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.warning.main
  },
  treating: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main
  },
  done: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  invalid: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  inactive: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  heighlight: {
    fontWeight: '600'
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '0 24px 0 24px',
    height: '50px',
    // textAlign: 'center'
  },
  dateField: {
    width: 150,
    height: 35
  },
  icon: {
    color: theme.palette.primary.main
  },
  detail: {
    padding: '0 24px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
