import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addGoal } from '../../../redux/modules/leadershipGoal';
import CreateGoalPlan from '../../../components/LeadershipManagement/AnnualGoal/CreateGoalPlan';
import { addTemplate, deleteTemplate, updateTemplate, getTemplates } from '../../../redux/modules/leadershipTemplate';
import { getYear } from '../../../utils/datetime';

class CreateGoalPlanContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            year: (getYear() + 1),
            goalName: '企业年度目标'
        }
    }

    componentDidMount() {
        const { onhandleGetTemlates } = this.props
        onhandleGetTemlates()
    }

    handleChangeGoal(event) {
        if (event.target.name === 'year')
            this.setState({ year: event.target.value })
        else if (event.target.name === 'goalName')
            this.setState({ goalName: event.target.value })
    }

    handleAddGoal() {
        const { onhanleAddGoal, currentUserInfo } = this.props;
        let goal = {
            companyId: currentUserInfo.companyId,
            year: Number(this.state.year),
            goalName: this.state.goalName
        }
        onhanleAddGoal(goal)
    }

    _generateOpentext = (template)=>{
        return template.logic + '{$target='+template.target+'}{unit='+template.unit+'}'
    }

    handleAddTemplate(template) {
        const { onhandleAddTemplate, onhandleGetTemlates } = this.props;
        const newTemplate = {
            tempName: template.tempName,
            opentext: this._generateOpentext(template),
            unitId: Number(template.unitId),
        }
        if (onhandleAddTemplate) {
            onhandleAddTemplate(newTemplate)
            onhandleGetTemlates()
        }
    }

    handleUpdateTemplate = async (template) => {
        const { onhandleUpdateTemplate, onhandleGetTemlates } = this.props;
        // console.log(template)
        const newTemplate = {
            tempId: template.tempId,
            tempName: template.tempName,
            opentext: this._generateOpentext(template),
            unitId: Number(template.unitId),
        }
        if (onhandleUpdateTemplate) {
            await onhandleUpdateTemplate(newTemplate.tempId, newTemplate)
            onhandleGetTemlates()
        }
    }

    handleDeleteTemplate = async (template) => {
        const { onhandleDeleteTemplate, onhandleGetTemlates } = this.props;
        if (onhandleDeleteTemplate) {
            await onhandleDeleteTemplate(template.tempId)
            onhandleGetTemlates()

        }
    }

    render() {
        return (
            <div>
                <CreateGoalPlan
                    constant={this.props.constant}
                    templates={this.props.templates}
                    year={this.state.year}
                    goalName={this.state.goalName}
                    onhandleChangeGoal={this.handleChangeGoal.bind(this)}
                    onhandleAddGoal={this.handleAddGoal.bind(this)}
                    onhandleAddTemplate={this.handleAddTemplate.bind(this)}
                    onhandleUpdateTemplate={this.handleUpdateTemplate.bind(this)}
                    onhandleDeleteTemplate={this.handleDeleteTemplate.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        templates: state.leadershipTemplate.templates,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetTemlates: () => {
            const query = { cat: 'GoalDetail' }
            dispatch(getTemplates(query))
        },
        onhanleAddGoal: (goal) => {
            dispatch(addGoal(goal))
        },
        onhandleAddTemplate: (template) => {
            template.cat = 'GoalDetail'
            return dispatch(addTemplate(template));
        },
        onhandleUpdateTemplate: (tempId, template) => {
            return dispatch(updateTemplate(tempId, template));
        },
        onhandleDeleteTemplate: (tempId) => {
            return dispatch(deleteTemplate(tempId));
        },

    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateGoalPlanContainer);