import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Grid,
  Select
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment as moment } from '@/utils/datetime';

const FiveSPlanHeader = props => {
  const {
    classes,
    search,
    companyList,
    statusList,
    onhandleSelectChange,
    onhandleSearch,
    onhandleClearSearch,

  } = props;

  const companyOptions = () => (
    <React.Fragment>
      {companyList ?
        companyList.map(data => (
          <option value={data.code} key={data.code}>{data.name}</option>
        )) : null
      }
    </React.Fragment>
  )

  const statusOptions = () => (
    <React.Fragment>
      {statusList ?
        statusList.map(data => (
          <option value={data.code} key={data.code}>{data.name}</option>
        )) : null
      }
    </React.Fragment>
  )

  return (
    <div>
      <div>
        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
          5S检查计划
        </Typography>
      </div>
      <div>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              受检单位
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.companyId || ''}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
            >
              <option aria-label="None" value="" />
              {companyOptions()}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              检查月份
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={zhCN}>
              <KeyboardDatePicker
                className={classes.dateSelect}
                views={['year', 'month']}
                variant="inline"
                autoOk
                openTo={'month'}
                maxDateMessage={<Typography>日期不能超过计划年度</Typography>}
                format="yyyy/MM"
                margin="none"
                name='yearMonth'
                InputProps={{ readOnly: true }}
                value={search.yearMonth || moment()()}
                onChange={(event) => onhandleSelectChange(event, 'yearMonth')}
                KeyboardButtonProps={{
                  'aria-label': '修改时间',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid item xs={1}>
            <Typography  gutterBottom className={classes.label}>
              部门
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.dptId}
              onChange={(event) => onhandleSelectChange(event, 'department')}
            >
              <option aria-label="None" value="" />
              {departmentOptions()}
            </Select>
          </Grid> */}
          <Grid item xs={1}>
            <Typography color='textPrimary' className={classes.label}>
              状态
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.status}
              onChange={(event) => onhandleSelectChange(event, 'status')}
            >
              <option aria-label="None" value="" />
              {statusOptions()}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >{'搜索'}</Button>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="primary"
              onClick={onhandleClearSearch}

            >{'重置'}</Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

FiveSPlanHeader.propTypes = {
  search: PropTypes.object.isRequired,
  companyList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired
};

export default withStyles(FiveSPlanHeader);
