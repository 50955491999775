import React from 'react';
import withStyles from './styles';

import PropTypes from 'prop-types';
import {
    Grid,
    Chip,
    Typography
} from '@material-ui/core';
import {
    AccessTime,
    Block,
    Done,
    Person,
    // Forward
} from '@material-ui/icons';
import UserCard from '../../containers/UserCard';
import _ from 'lodash';


const ProcessLineApprovalComponent = ({
    classes,
    treeData,
    singleShow, //单独显示
    idKey,
    processNotices
}) => {

    const renderPermitChip = (nodes) => {
        let chip = {
            icon: undefined,
            style: classes.pendingUser
        }
        if (singleShow) {
            if (nodes.userId) {
                chip.icon = <Person className={classes.pendingUser} />
                chip.style = classes.pendingUser
            } else {
                chip.icon = <Person className={classes.pendingRole} />
                chip.style = classes.pendingRole
            }
        } else {
            if (nodes.confirm) {
                if (nodes.confirmTime) {
                    chip.icon = <Done className={classes.approvedUser} />
                    chip.style = classes.approvedUser
                }
            } else {
                if (nodes.confirmTime) {
                    chip.icon = <Block className={classes.rejectedUser} />
                    chip.style = classes.rejectedUser
                } else if (nodes.userId) {
                    chip.icon = <AccessTime color='primary' className={classes.pendingUser} />
                    chip.style = classes.pendingUser
                } else {
                    chip.icon = <AccessTime className={classes.pendingRole} />
                    chip.style = classes.pendingRole
                }
            }
        }
        return chip;
    }


    const renderApproverChip = (node) => {

        if (node) {
            if (node.userId) {
                return <UserCard userId={node.userId} child={<Typography className={classes.label}>{node.lastName + node.firstName}</Typography>} />
            }
            if (node.roleId) {
                return <Typography className={classes.label}>{node.roleName}</Typography>
            }
        }
        return <Typography>{'请点击选择用户'}</Typography>

    }

    const renderTree = (nodes, singleShow) => (
        <React.Fragment>
            <Grid className={classes.step}>
                {_.sortBy(nodes, 'children').map((node, index) => {
                    let chip = renderPermitChip(node, singleShow);
                    return <Grid key={`Grid_${node[idKey]}`}>
                        <Chip
                            key={`user_chip_${node[idKey]}`}
                            variant={'outlined'}
                            size="small"
                            className={chip.style}
                            icon={chip.icon}
                            label={renderApproverChip(node)}
                        />
                    </Grid>
                })
                }
            </Grid>
            {_.find(nodes, function (o) { return o.children }) ?
                <React.Fragment>
                    <span className={classes.line}></span>
                    {renderTree(_.find(nodes, function (o) { return o.children }).children)}
                </React.Fragment>
                : null}
        </React.Fragment>
    );


    const renderNoticeChip = (node) => {

        if (node) {
            if (node.userId) {
                return <UserCard userId={node.userId} child={<Typography className={classes.label}>{node.lastName + node.firstName}</Typography>} />
            }
            if (node.roleId) {
                return <Typography className={classes.label}>{node.roleName}</Typography>
            }
        }
        return <Typography>{''}</Typography>

    }

    const renderNotices = (nodes) => (
        <React.Fragment>
            {/* <Grid>
                <Chip
                    variant={'outlined'}
                    size="small"
                    icon={<Forward className={classes.forwardUser} />}
                    className={classes.forwardUser}
                    label={<Typography className={classes.label}>{'抄送:'}</Typography>}
                />
            </Grid> */}
            <Typography variant="h4">
                {'抄送'}
            </Typography>
            {
                nodes.map((node, index) => {
                    return <Grid key={`Foward_${node.id}`}>
                        <Chip
                            key={`user_chip_${node.id}`}
                            variant={'outlined'}
                            size="small"
                            icon={<Person className={classes.forwardUser} />}
                            className={classes.forwardUser}
                            label={renderNoticeChip(node)}
                        />
                    </Grid>
                })
            }
        </React.Fragment >
    );

    return (
        <div>
            <Grid container direction="row" alignItems={'center'}>
                {renderTree(treeData, singleShow)}
            </Grid>
            {
                processNotices && processNotices.length > 0 ?
                    <Grid container alignItems={'center'}>
                        {renderNotices(processNotices)}
                    </Grid>
                    : null
            }

        </div>
    )
};
ProcessLineApprovalComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

ProcessLineApprovalComponent.defaultProps = {

}

export default withStyles(ProcessLineApprovalComponent);