/* eslint-disable prefer-destructuring */
import { handleActions, createAction } from 'redux-actions';
import omit from 'lodash/omit';
import decodeJWT from 'jwt-decode';
import { push } from 'connected-react-router';
import { initConstantLogin } from './constant';
// import localforage from 'localforage';
import _ from 'lodash';
import { cleanloading, createloading } from './loading';

/**
 * Internal data shape
 * @type {Object}
 */
const defaultState = {
  jwt: '',
  currentUserInfo: {},
  loginInfo: {},
  signupInfo: {
    tenantAlias: '',
    lastName: '',
    firstName: '',
    userName: '',
    email: '',
    phone: '',
    password: '',
    smsCode: ''
  },
  loginUserTenants: [],
  loginSelectedTenant: {},
  tenantChangeErrorMessage: '',
  isloading: false,
  isProcessing: false,
  userProfile: {},
  smsClock: 180,
  resetPasswordInfo: {}
};

export const currentUserInfo = createAction(
  '@@xcloud/auth/currentUserInfo'
);

export const setToken = createAction(
  '@@xcloud/auth/set',
  token => (dispatch, getState, axios) => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      let currentUser = decodeJWT(token)
      if (currentUser) {
        dispatch(currentUserInfo(currentUser))

      } else {
        dispatch(currentUserInfo(''))
        // dispatch(cleanConstant())
      }
    } else {
      axios.defaults.headers.common = omit(axios.defaults.headers.common, [
        'Authorization'
      ]);
    }

    return token;
  }
);

export const loginError = createAction('@@xcloud/auth/loginError');

export const setLogoutDialog = createAction('@@xcloud/auth/setLogoutDialog',
  (message) => (dispatch, getState) => {
    const state = getState().auth.loginInfo;
    state.errorMessage = message;
    state.password = '';
    dispatch(loginError(state));
    dispatch(push('/login'));
  });

export const updateloginInfo = createAction('@@xcloud/auth/updateloginInfo');

export const updateSignupInfo = createAction('@@xcloud/auth/updateSignupInfo');

export const updateResetPasswordInfo = createAction('@@xcloud/auth/updateResetPasswordInfo');

export const setloginSelectedTenant = createAction('@@xcloud/auth/setloginSelectedTenant');

export const tenantChangeError = createAction('@@xcloud/auth/tenantChangeError');

export const startLoading = createAction('@@xcloud/auth/startLoading');

export const startProcessing = createAction('@@xcloud/auth/startProcessing');

export const stopProcessing = createAction('@@xcloud/auth/stopProcessing');

export const signup = createAction('@@xcloud/auth/signup',
  // eslint-disable-next-line no-unused-vars
  () => (dispatch, getState, httpClient) => {
    const signupInfo = getState().auth.signupInfo;
    return httpClient
      .post('api/signup/', signupInfo)
      .then((res) => {
        const newInfo = {
          ...signupInfo,
          errorMessage: ''
        };
        dispatch(updateSignupInfo(newInfo));
        dispatch(push('/login'));
        return res;
      })
      .catch(err => {
        let errMsg = '注册失败';
        if (err.response) {
          errMsg = err.response.data.message
        }
        const newInfo = {
          ...signupInfo,
          errorMessage: errMsg
        };
        dispatch(updateSignupInfo(newInfo));
      });
  });

export const resetPassword = createAction('@@xcloud/auth/resetPassword',
  // eslint-disable-next-line no-unused-vars
  () => (dispatch, getState, httpClient) => {
    const resetPasswordInfo = getState().auth.resetPasswordInfo;
    let postInfo = {
      phone: resetPasswordInfo.phone,
      password: resetPasswordInfo.newPwd,
      smsCode: resetPasswordInfo.smsCode
    }
    return httpClient
      .post('api/resetpassword/', postInfo)
      .then((res) => {
        const newInfo = {
          ...resetPasswordInfo,
          errorMessage: ''
        };
        dispatch(updateResetPasswordInfo(newInfo));
        dispatch(push('/login'));
        return res;
      })
      .catch(err => {
        console.error(err);
        let errMsg = '重置密码失败';
        if (err.response) {
          errMsg = err.response.data.message
        }
        const newInfo = {
          ...resetPasswordInfo,
          errorMessage: errMsg
        };
        dispatch(updateResetPasswordInfo(newInfo));
      });
  });

export const sendSmsCode = createAction('@@xcloud/auth/sendSmsCode',
  (phoneNum, source) => (dispatch, getState, httpClient) => {
    let sms = {
      phone: phoneNum,
      type: source
    }
    let info = source === 'xcloud_signup' ? getState().auth.signupInfo : getState().auth.resetPasswordInfo;
    return httpClient
      .post('api/unprotected/sms', sms)
      .then((res) => {
        if (res.data.Code && res.data.Code === 'OK') {
          dispatch(startSmsClock());
        } else {
          const newInfo = {
            ...info,
            errorMessage: res.Message
          };
          if (source === 'xcloud_signup') {
            dispatch(updateSignupInfo(newInfo));
          } else {
            dispatch(updateResetPasswordInfo(newInfo));
          }
        }
        return res;
      })
      .catch(err => {
        let errMsg = '发送验证码失败';
        if (err.response) {
          errMsg = err.response.data.message
        }
        const newInfo = {
          ...info,
          errorMessage: errMsg
        };
        if (source === 'xcloud_signup') {
          dispatch(updateSignupInfo(newInfo));
        } else {
          dispatch(updateResetPasswordInfo(newInfo));
        }
      });
  });


export const setSmsClock = createAction("@@xcloud/auth/setSmsClock")

export const startSmsClock = createAction('@@xcloud/auth/startSmsClock',
  () => (dispatch, getState) => {
    const smsClock = getState().auth.smsClock;
    if (smsClock === 1) {
      dispatch(setSmsClock(180));
    } else {
      let count = smsClock - 1;
      dispatch(setSmsClock(count));
      setTimeout(() => dispatch(startSmsClock()), 1000);
    }
  });

export const signin = createAction('@@xcloud/auth/signin',
  // eslint-disable-next-line no-unused-vars
  (directTo) => (dispatch, getState, httpClient) => {
    dispatch(createloading())
    const state = getState().auth.loginInfo;
    state.usernameOrEmail = _.trim(state.usernameOrEmail);
    return httpClient
      .post('api/signin/', state)
      .then((res) => {
        state.errorMessage = '';
        Promise.all([
          dispatch(setUserProfile(res.data)), //set user profile when sign in
          dispatch(initConstantLogin()),
          dispatch(loginError(state)),
          dispatch(initLoginTenantsList()),
        ])
          .then((res) => {
            dispatch(cleanloading())
            dispatch(push(directTo));
          })
        // const currentUserInfo = getState().auth.currentUserInfo;
        // if (currentUserInfo.roleName === '系统管理员') {
        //   dispatch(setloginSelectedTenant({}));
        // } else {
        //   dispatch(push('/tenantselect'));
        // }
        return res;
      })
      .catch(err => {
        dispatch(cleanloading())
        let errMsg = '登录失败';
        if (err.response) {
          errMsg = err.response.data.message
        }
        const newInfo = {
          ...state,
          errorMessage: errMsg
        };
        dispatch(loginError(newInfo));

      });
  });

export const signout = createAction('@@xcloud/auth/signout',
  () => (dispatch, getState) => {
    const state = getState().auth.loginInfo;
    state.errorMessage = '';
    state.password = '';
    dispatch(loginError(state));
    window.location.href = "/login"
  });

export const initLoginTenantsList = createAction('@@xcloud/auth/initLoginTenantsList',
  () => (dispatch, getState, httpClient) => {
    const currentUserInfo = getState().auth.currentUserInfo;
    return httpClient
      .get(`api/users/${currentUserInfo.userId}/tenants`)
      .then((datas) => {
        if (datas) {
          dispatch(tenantChangeError(''));
          if (datas.data.length !== 0) {
            dispatch(setloginSelectedTenant(datas.data[0]));
            dispatch(push('/'));
          }
          return datas.data;
        }
        return [];
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(tenantChangeError(`用户${currentUserInfo.lastName} ${currentUserInfo.firstName}的租户信息获取失败`));
        return [];
      });
  });

export const shiftTenant = createAction('@@xcloud/auth/confirmloginSelectedTenant',
  (selectedTenant) => (dispatch, getState, httpClient) => {
    const currentUserInfo = getState().auth.currentUserInfo;
    return httpClient
      .patch(`api/users/${currentUserInfo.userId}/tenants/${selectedTenant.tenantId}?roleType=admin`)
      .then((datas) => {
        if (datas) {
          dispatch(setloginSelectedTenant(selectedTenant));
          dispatch(push('/'));
          dispatch(stopProcessing());
        }
      })
      .catch(err => {
        const msg = '租户切换失败';
        dispatch(tenantChangeError(err.response ? err.response.data.message : msg));
        dispatch(stopProcessing());
      });
  });

export const confirmloginSelectedTenant = createAction('@@xcloud/auth/confirmloginSelectedTenant',
  (selectedTenant) => (dispatch, getState) => {
    dispatch(startProcessing());
    const currentUserInfo = getState().auth.currentUserInfo;
    const loginSelectedTenant = getState().auth.loginSelectedTenant;
    dispatch(tenantChangeError(''));
    if (loginSelectedTenant && loginSelectedTenant.tenantId) {
      if (loginSelectedTenant.tenantId === selectedTenant.tenantId) {
        dispatch(push('/'));
      } else {
        dispatch(shiftTenant(selectedTenant));
      }
    } else if (currentUserInfo.tenantId === selectedTenant.tenantId) {
      dispatch(setloginSelectedTenant(selectedTenant));
      dispatch(push('/'));
    } else {
      dispatch(shiftTenant(selectedTenant));
    }
    dispatch(stopProcessing());
  }
);

export const setUserProfile = createAction("@@xcloud/auth/setuserprofile")


const authReducer = handleActions(
  {
    [setToken]: (state, { payload: token }) => ({
      ...state,
      jwt: token
    }),
    [currentUserInfo]: (state, { payload }) => ({
      ...state,
      currentUserInfo: payload
    }),
    [updateloginInfo]: (state, { payload: value }) => ({
      ...state,
      loginInfo: value
    }),
    [updateSignupInfo]: (state, { payload: value }) => ({
      ...state,
      signupInfo: value
    }),
    [updateResetPasswordInfo]: (state, { payload: value }) => ({
      ...state,
      resetPasswordInfo: value
    }),
    [loginError]: (state, { payload: value }) => ({
      ...state,
      loginInfo: value
    }),
    [setSmsClock]: (state, { payload: value }) => ({
      ...state,
      smsClock: value
    }),
    // eslint-disable-next-line no-unused-vars
    [signout]: (state, { payload: value }) => ({
      ...state,
      jwt: '',
      currentUserInfo: '',
      loginInfo: {},
      signupInfo: {},
      loginUserTenants: [],
      loginSelectedTenant: {},
      tenantChangeErrorMessage: '',
      userProfile: {}
    }),
    [tenantChangeError]: (state, { payload: value }) => ({
      ...state,
      tenantChangeErrorMessage: value
    }),
    [initLoginTenantsList]: (state, { payload: value }) => ({
      ...state,
      loginUserTenants: value,
      isloading: false,
      isProcessing: false
    }),
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [startProcessing]: (state) => {
      return {
        ...state,
        isProcessing: true
      };
    },
    [stopProcessing]: (state) => {
      return {
        ...state,
        isProcessing: false
      };
    },
    [setloginSelectedTenant]: (state, { payload: value }) => {
      return {
        ...state,
        loginSelectedTenant: value
      };
    },
    [setUserProfile]: (state, { payload: value }) => {
      return {
        ...state,
        userProfile: value
      }
    }
  },
  defaultState
);

export default authReducer;
