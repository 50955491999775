import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Button,
    Grid,
} from '@material-ui/core';

const ActivitiesHeader = props => {

    const { classes, currentActivity,users } = props;
   
    const getUserName = (userId) => {
        //console.log('userId', userId);
        let user = users.find(ele => ele.userId === userId);
        if (user) {
            return user.lastName + user.firstName
        }
        return ''
    }

    const customerType = [
        { "key": "NORMAL", "name": "普通" },
        { "key": "VIP", "name": "VIP" },
    ]

    const getCusType = (key) => {
        //console.log('userId', userId);
        let cust = customerType.find(ele => ele.key === key);
        if (cust) {
            return cust.name
        }
        return ''
    }

    return (
        <div className={classes.root}>
            <Grid container alignItems="center" className={classes.gridContainer}>
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label}>
                            {currentActivity.actCustomer?.cstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} />
                    <Grid item xs={2} align="right">
                        <Button variant="contained" color="primary" size="small">
                            编辑客户数据
                        </Button>
                        
                       &nbsp;
                        <Button variant="contained" color="primary"
                            size="small"
                            >
                            返回
                        </Button>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            客户类型：
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {getCusType(currentActivity.actCustomer?.cstType)} 
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            客户状态：
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            {currentActivity.actCustomer?.status}                       
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            负责人：
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            { getUserName(currentActivity.actCustomer?.cstHead) }
                        </Typography>
                    </Grid>

                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>

        </div>
    );
};
ActivitiesHeader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(ActivitiesHeader);
