import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import message from './message';
import modal from './modal';
import auth from './auth';
import notice from './notice';
import installationdoc from './installationDoc'
import installation from './installation'
import confirmDialog from './confirmDialog';
import approvePannel from './approvePannel';
import createPlanPannel from './createPlanPannel';
import menu from './menu';
import leadership from './leadership';
import regulation from './regulation';
import leadershipGoal from './leadershipGoal';
import leadershipRegulationPlan from './leadershipRegulationPlan';
import leadershipTemplate from './leadershipTemplate';
import leadershipPlanDetailAdd from './leadershipPlanDetailAdd';
import safetyActivity from './safetyActivity';
import process from './process';
import processApproval from './processApproval';
import processlinewithuser from './processlinewithuser';
import setting from '../../device/src/redux/modules/setting';
import viewSetting from '../../device/src/redux/modules/viewSetting';
import installationRelation from '../../device/src/redux/modules/installationRelation';
import dialog from '../../device/src/redux/modules/dialog';
import installationPlug from '../../device/src/redux/modules/installationPlug';
import contentmenu from '../../device/src/redux/modules/contentmenu';
import contentmenuview from '../../device/src/redux/modules/contentmenuview';
import deviceTree from '../../device/src/redux/modules/deviceTree';
import tenant from './tenant';
import company from './company';
import constant from './constant';
import bu from './bu';
import job from './job';
import role from './role';
import user from './user';
import department from './department';
import loading from './loading';
import regulationUpload from './regulationUpload';
import operationRule from './operationRule';
import operationPlan from './operationPlan';
import trees from './trees';
import devices from './devices';
import deviceReport from './deviceReport';
import deviceGroup from './deviceGroup';
import websocket from './websocket';
import operation from './operation';
import operationWorkPermit from './operationWorkPermit';
import operationPermitUser from './operationPermitUser';
import operationWorkAcceptance from './operationWorkAcceptance';
import majorHazard from './majorHazard';
import customer from './customer';
import customerOppo from './customerOppo';
import customerActivities from './customerActivities';
import {
  inspection,
  inspectionPlan,
  inspectionPlanMonthly,
  inspectionPlanDetailAdd,
  inspectionTempPlanDetailAdd,
  riskInspectionTempPlanDetailAdd,
  inspectionPlanChecklistSet,
  inspectionTreatmentScheme,
  inspectionSchemeAccept,
  inspectionHazardClose,
  checklistInventoryEdit,
  dangerChecklistInventoryEdit,
  hazard,
  hazardReport,
  fiveSPlan,
  fiveSTempPlanDetailAdd,
} from './inspection';
import deviceSelect from './deviceSelect';
import userSelect from './userSelect';
import roleSelect from './roleSelect';
import accessorySelect from './accessorySelect';
import accessoryReplaceRecord from './accessoryReplaceRecord';
import deviceUseStatus from './deviceUseStatus';
import accessory from './accessory';
import manipulate from './manipulate';
import installActionRecord from './installActionRecord';
import usercard from './usercard';
import devicecard from './devicecard';
import deviceForm from './deviceForm';
import onsiteInspection from './onsiteInspection';
import onsiteInspectionRule from './onsiteInspectionRule';
import onsiteInspectionPlan from './onsiteInspectionPlan';
import onsiteInspectionException from './onsiteInspectionException';
import onsiteInspectionSchedule from './onsiteInspectionSchedule';
import onsiteInspectionPlanReport from './onsiteInspectionPlanReport';
import onsiteInspectionRecordReport from './onsiteInspectionRecordReport';
import onsiteInspectionStatistics from './onsiteInspectionStatistics';
import onsiteInspectionExceptionAccept from './onsiteInspectionExceptionAccept';
import onsiteInspectionExceptionBatchAccept from './onsiteInspectionExceptionBatchAccept';
import onsiteInspectionRuleValid from './onsiteInspectionRuleValid';
import acctypeSelect from './acctypeSelect';
import material from './material';
import todo from './todo';
import {
  manufactureActivity,
  manufactureService,
  businessGroup,
  assay,
  manufactureTask,
  projectPlan,
  laborerManage,
  manufactureCustomer,
  manufactureHandover,
  manufacturePick,
  manufactureInstock,
  manufactureProdReport,
  manufactureServiceReport,
  manufactureShift,
  manufactureShiftRule,
  manufactureHWInstock,
  manufactureMisdelivery,
  manufacturePlan,
  manufactureAttr,
  manufactureActivityRecord,
  manufactureOrder,
  servicePlanConfig,
} from './manufacture';
import {
  variableFeeRule,
  serviceBudget,
  amoebaReport,
  amoebaDepreciationPlan,
  refuelRecord,
  phoneBill,
  labour,
  depreciation,
  reportTrack,
  reportTrend,
  oilPrice,
  amoebaIndex,
  amoebaCostSharing,
  amoebaPlan,
  amoebaHrPlan,
  amoebaSalOrder,
  amoebaSalOutStock,
  amoebaFixedPlan,
  amoebaVariablePlan,
  energyDevice,
  energyAssignRule,
  energyParamRecord,
  energyRuleRecord,
  amoebaAnalysis
} from './amoeba';
import operationStatus from './operationStatus';
import deviceOpsRecord from './deviceOpsRecord';
import deviceInsurance from './deviceInsurance';
import payment from './payment'
import predefinedProcess from './predefinedProcess';
import operationPlanDocs from './operationPlanDocs';
import supplier from './supplier';

export default (history) => combineReducers({
  router: connectRouter(history),
  accessory,
  accessoryReplaceRecord,
  accessorySelect,
  acctypeSelect,
  amoebaAnalysis,
  amoebaCostSharing,
  amoebaDepreciationPlan,
  amoebaFixedPlan,
  amoebaHrPlan,
  amoebaIndex,
  amoebaPlan,
  amoebaReport,
  amoebaSalOrder,
  amoebaSalOutStock,
  amoebaVariablePlan,
  approvePannel,
  assay,
  auth,
  bu,
  businessGroup,
  checklistInventoryEdit,
  dangerChecklistInventoryEdit,
  company,
  confirmDialog,
  constant,
  contentmenu,
  contentmenuview,
  createPlanPannel,
  customer,
  customerActivities,
  customerOppo,
  department,
  depreciation,
  devicecard,
  deviceForm,
  deviceGroup,
  deviceInsurance,
  deviceOpsRecord,
  deviceReport,
  devices,
  deviceSelect,
  deviceTree,
  deviceUseStatus,
  dialog,
  energyAssignRule,
  energyDevice,
  energyParamRecord,
  energyRuleRecord,
  fiveSPlan,
  fiveSTempPlanDetailAdd,
  hazard,
  hazardReport,
  inspection,
  inspectionHazardClose,
  inspectionPlan,
  inspectionPlanChecklistSet,
  inspectionPlanDetailAdd,
  inspectionPlanMonthly,
  inspectionSchemeAccept,
  inspectionTempPlanDetailAdd,
  riskInspectionTempPlanDetailAdd,
  inspectionTreatmentScheme,
  installActionRecord,
  installation,
  installationdoc,
  installationPlug,
  installationRelation,
  job,
  laborerManage,
  labour,
  leadership,
  leadershipGoal,
  leadershipPlanDetailAdd,
  leadershipRegulationPlan,
  leadershipTemplate,
  loading,
  majorHazard,
  manipulate,
  manufactureActivity,
  manufactureActivityRecord,
  manufactureAttr,
  manufactureCustomer,
  manufactureHandover,
  manufactureHWInstock,
  manufactureInstock,
  manufactureMisdelivery,
  manufactureOrder,
  manufacturePick,
  manufacturePlan,
  manufactureProdReport,
  manufactureService,
  manufactureServiceReport,
  manufactureShift,
  manufactureShiftRule,
  manufactureTask,
  material,
  menu,
  message,
  modal,
  notice,
  oilPrice,
  onsiteInspection,
  onsiteInspectionException,
  onsiteInspectionExceptionAccept,
  onsiteInspectionExceptionBatchAccept,
  onsiteInspectionPlan,
  onsiteInspectionPlanReport,
  onsiteInspectionRecordReport,
  onsiteInspectionRule,
  onsiteInspectionRuleValid,
  onsiteInspectionSchedule,
  onsiteInspectionStatistics,
  operation,
  operationPermitUser,
  operationPlan,
  operationPlanDocs,
  operationRule,
  operationStatus,
  operationWorkAcceptance,
  operationWorkPermit,
  payment,
  phoneBill,
  predefinedProcess,
  process,
  processApproval,
  processlinewithuser,
  projectPlan,
  refuelRecord,
  regulation,
  regulationUpload,
  reportTrack,
  reportTrend,
  role,
  roleSelect,
  safetyActivity,
  serviceBudget,
  servicePlanConfig,
  setting,
  supplier,
  tenant,
  todo,
  trees,
  user,
  usercard,
  userSelect,
  variableFeeRule,
  viewSetting,
  websocket,
});
