import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    rulerecords: [], //能耗分摊记录列表
};

export const setRulerecords = createAction('@@xcloud/amoeba/setRulerecords');

export const getRulerecords = createAction('@@xcloud/amoeba/getRulerecords',
    (year, month, energyType) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/rulerecords'
        let strs = []
        if (year || month || energyType) {
            if (year) strs.push('year=' + year)
            if (month) strs.push('month=' + month)
            if (energyType) strs.push('energyType=' + energyType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setRulerecords(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取能耗分摊记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addRulerecord = createAction('@@xcloud/amoeba/addRulerecord',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/rulerecords`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "能耗分摊记录生成成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `能耗分摊记录生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateRulerecord = createAction('@@xcloud/amoeba/updateRulerecord',
    (recordId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/rulerecords/${recordId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新能耗分摊记录成功"));
                }
            }).catch(err => {
                let msg = `更新能耗分摊记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteRulerecord = createAction('@@xcloud/amoeba/deleteRulerecord',
    (recordId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/amoeba/rulerecords/${recordId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除能耗分摊记录成功"));
                }
            }).catch(err => {
                let msg = `删除能耗分摊记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const amoebaRulerecordReducer = handleActions(
    {
        [setRulerecords]: (state, { payload: data }) => {
            return {
                ...state,
                rulerecords: data
            }
        },
    },
    initialState
)

export default amoebaRulerecordReducer
