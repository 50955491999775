import palette from '@/theme/palette';

export default {
  header: {
    backgroundColor: palette.table.header.backgroundColor
  },
  oddRow: {
    backgroundColor: palette.table.oddRow.backgroundColor
  },
  evenRow: {
    backgroundColor: palette.table.evenRow.backgroundColor
  },
};

// 默认条纹表格样式，如果涉及expandable等影响表格布局的功能，请不要使用；改用js控制
export const stripedTableStyle = {
  '& .ant-table-thead tr th': {
    backgroundColor: palette.table.header.backgroundColor
  },
  '& .ant-table-tbody tr:nth-child(odd) td': {
    backgroundColor: palette.table.oddRow.backgroundColor
  }
}

export const tableStyle = {
  '& .ant-table-thead tr th': {
    backgroundColor: palette.table.header.backgroundColor
  }
}
