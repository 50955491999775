import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dialogContent: {
        minWidth: 400
    },
    itemGrid: {
        padding: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    },
});


export default withStyles(styles);