import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  expandPanel: {
    root: {
      boxShadow: 'none',
      '&:before': {
        boxShadow: 'none'
      }
    },

  },
  formControl: {
    width: '90%'
  },
  textField: {
    minWidth: 300,
    maxWidth: 300
  },
  inlineButton: {
    whiteSpace: 'nowrap'
  },
  button: {
    marginRight: 10
  },
  terminateBtn: {
    marginRight: 10,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.error.dark
    }
  },
  passed: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  denied: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  card: {
    width: '100%',
    padding: 20
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    margin: "10px 10px 2px"
  },
  gridContainerSub: {
    margin: "2px 10px"
  },
  infoBlock: {
    paddingRight: '60px'
  },
  subAlign: {
    textAlign: 'end'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '0 24px 0 24px',
    height: '50px',
    // textAlign: 'center'
  },
  expandable: {
    cursor: 'pointer'
  },
  attachmentBtn: {
    padding: 0,
    marginTop: '-2px'
  },
  progressMain: {
    height: 10,
    borderRadius: 5,
    color: 'gray'
  },
  progressSub: {
    height: 6,
    borderRadius: 2
  },
  icon: {
    color: theme.palette.primary.main
  },
  iconRotate: {
    transform: 'rotate(180deg)'
  },
  detail: {
    padding: '0 24px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  detailTable: {
    paddingLeft: theme.spacing(4)
  }
});

export default withStyles(styles);
