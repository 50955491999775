import { createAction, handleActions } from 'redux-actions';
import { getAccessoryCategory } from './accessory';
import { getCompanyId } from "../../utils/userUtils";
import { createhttpMessage } from './message';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { cleanmodal } from './modal';

const porxyApi = '/api'

const initialState = {
    accessoryList: [],
    search: {
        cat: undefined,
        companyId: undefined,
        search: "",
        status: undefined,
        isCommon: undefined,
        deviceSearch: "",
    },
    selectedAccessory: [],
}

export const initAccessorySelectList = createAction('@@xcloud/accessorySelect/initAccessorySelectList',
    () => (dispatch, getState) => {
        dispatch(createloading());
        Promise.all([
            dispatch(getAccessoryCategory()),
            dispatch(resetSearch()),
            dispatch(setSelectedAccessory([])),
            dispatch(setAccessoryList([]))
        ]).then((data) => {
            dispatch(cleanloading());
            // dispatch(getDeviceList("-update_time", "", "", "", "", "", "", "", "", "", ""))
        }).catch(err => {
            dispatch(cleanloading());
            let msg = '初始化部件搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getAccessoryList = createAction(
    '@@xcloud/accessorySelect/getAccessoryList',
    (sort, cat, search, deviceSearch, companyId, isCommon, status) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let searchCond = getState().accessorySelect.search;
        console.log('searchCondsearchCondsearchCond', searchCond)
        let parameters = getState().modal.parameters;
        //条件检索
        cat = searchCond.cat ? searchCond.cat.value : cat
        isCommon = searchCond.isCommon ? searchCond.isCommon.value : isCommon
        search = searchCond.search ? searchCond.search : search
        deviceSearch = searchCond.deviceSearch ? searchCond.deviceSearch : deviceSearch
        companyId = searchCond.companyId ? searchCond.companyId : null;
        status = searchCond.status ? searchCond.status.value : status
        //
        status = status ? status : parameters.statusValue
        let url = "/device/accessorys?offset=0"
        if (cat && cat !== "") {
            url += "&cat=" + cat
        }
        if (search && search !== "") {
            url += "&search=" + encodeURIComponent(search)
        }
        if (deviceSearch && deviceSearch !== "") {
            url += "&deviceSearch=" + encodeURIComponent(deviceSearch)
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (isCommon === true || isCommon === false) {
            url += "&isCommon=" + isCommon
        }
        if (status && status !== "") {
            url += "&status=" + status
        }
        if (parameters.amount) {
            url += "&amount=" + parameters.amount
        }
        if (parameters.connectedFlg) {
            url += "&connectedFlg=" + parameters.connectedFlg
        }


        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200) {
                    dispatch(setAccessoryList(res.data));
                    return res.data;
                }
            }).catch(err => {
                dispatch(cleanloading());
                let msg = `查找部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const resetSearch = createAction('@@xcloud/accessorySelect/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = null
        let parameters = getState().modal.parameters;
        if (parameters.companyId) {
            company = parameters.companyId
        } else {
            if (parameters.company) {
                company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            }
        }
        let search = getState().accessorySelect.search;
        let newSearch = {
            ...search,
            companyId: company ? company : undefined,
            cat: undefined,
            search: "",
            status: undefined,
            isCommon: undefined,
            deviceSearch: "",
        }
        dispatch(setSearch(newSearch));
    }
);

export const setSearch = createAction('@@xcloud/accessorySelect/setSearch');
export const setAccessoryList = createAction('@@xcloud/accessorySelect/setAccessoryList');
export const setSelectedAccessory = createAction('@@xcloud/accessorySelect/setSelectedAccessory');
export const exitSelected = createAction('@@xcloud/accessorySelect/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedAccessory([]))
        dispatch(cleanmodal())
    }
);

export const accessorySelectReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setAccessoryList]: (state, { payload: value }) => {
            return {
                ...state,
                accessoryList: value
            };
        },
        [setSelectedAccessory]: (state, { payload: value }) => {
            return {
                ...state,
                selectedAccessory: value
            };
        },
    },
    initialState
);

export default accessorySelectReducer;