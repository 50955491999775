
import _ from 'lodash'

const excelTypes = ['XLS, XLSX'];
const imageTypes = ['BMP', 'DIB', 'PCP', 'DIF', 'WMF', 'GIF', 'JPG', 'JPEG', 'TIF', 'EPS', 'PSD', 'CDR', 'IFF', 'TGA', 'PCD', 'MPT', 'PNG']
const vedioTypes = ['MPG', 'MPEG', 'AVI', 'RM', 'RMVB', 'MOV', 'WMV', 'ASF', 'DAT', 'MP4', 'ASX', 'WVX', 'MPE', 'MPA']

export const excelType = 'DOC_EXCEL'
export const imageType = 'DOC_IMAGE'
export const vedioType = 'DOC_VEDIO'
export const otherType = 'DOC_FILE'

export function getDocType(fileName) {
    if (!fileName || typeof (fileName) !== 'string' || fileName.length <= 1) {
        return ''
    }

    let pointIndex = fileName.lastIndexOf(".");

    if (_.indexOf(excelTypes, _.upperCase(fileName.substring(pointIndex + 1))) > -1) {
        return excelType
    }

    if (_.indexOf(imageTypes, _.upperCase(fileName.substring(pointIndex + 1))) > -1) {
        return imageType
    }
    if (_.indexOf(vedioTypes, _.upperCase(fileName.substring(pointIndex + 1))) > -1) {
        return vedioType
    }
    return otherType
}

export function getFileExt(fileName) {
    if (!fileName || typeof (fileName) !== 'string' || fileName.length <= 1) {
        return ''
    }

    let pointIndex = fileName.lastIndexOf(".");
    if (pointIndex < 0) {
        return ''
    }
    return fileName.substring(pointIndex + 1)

}