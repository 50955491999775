import { createAction, handleActions } from 'redux-actions';
import { createMessage, createhttpMessage } from './message'
// import * as _ from 'lodash';
import { findParent } from '../../utils/constant';
import { cleanmodal } from "./modal";
import { setCurrentNode, setParentNode } from "./company";
import * as _ from 'lodash';

const defaultState = {
  isloading: false,
  currentDepartment: {},
  departmentChange: false,
  departmentError: {},
  departments: {
    total: 0,
    list: []
  },
  allDepartments: {
    total: 0,
    list: []
  }
};

export const createDepartment = createAction('@@xcloud/department/createDepartment',
  () => (dispatch, getState, httpClient) => {
    let newDepartment = getState().department.currentDepartment;
    //let company = getState().company.currentCompany;
    delete newDepartment.endDate;
    let url = `/api/departments`;
    return httpClient.post(url, newDepartment)
      .then((res) => {
        if (res.data) {
          let pnode = getState().company.parentNode;
          if (!pnode.children) {
            pnode.children = [];
          }
          pnode.children.push(res.data);
          dispatch(setParentNode(pnode));
          let msg = !newDepartment.executable ? '创建部门成功' : '创建班组成功';
          dispatch(createMessage('success', msg));
          dispatch(cleanmodal());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = !newDepartment.executable ? '创建新部门失败' : '创建新班组失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateDepartment = createAction('@@xcloud/department/updateDepartment',
  () => (dispatch, getState, httpClient) => {
    let department = getState().department.currentDepartment;
    let userId = getState().auth.currentUserInfo.userId;
    let url = `/api/departments/${department.dptId}`;
    let to = {
      dptId: department.dptId,
      dptName: department.dptName,
      opentext: department.opentext,
      executable: department.executable,
      amoebaLevel: department.amoebaLevel,
      startDate: department.startDate,
      revisor: userId
    }
    if (department.amoebaType) {
      to.amoebaType = department.amoebaType
    }

    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          let msg = department.executable ? '更新班组成功' : '更新部门成功';
          dispatch(createMessage('success', msg));
          dispatch(setCurrentDepartment(res.data));
          let cnode = getState().company.currentNode;
          cnode.companyId = res.data.companyId;
          cnode.dptId = res.data.dptId;
          cnode.dptName = res.data.dptName;
          cnode.opentext = res.data.opentext;
          cnode.executable = res.data.executable;
          cnode.amoebaLevel = res.data.amoebaLevel;
          cnode.amoebaType = res.data.amoebaType;
          cnode.revisor = res.data.revisor;
          dispatch(setCurrentNode(cnode));
          dispatch(cleanmodal());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = department.executable ? '更新班组失败' : '更新部门失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const deleteDepartment = createAction('@@xcloud/department/deleteDepartment',
  () => (dispatch, getState, httpClient) => {
    let department = getState().department.currentDepartment;

    let companyTree = getState().company.companyTree;
    let url = `/api/departments/${department.dptId}`;
    let to = {
      dptId: department.dptId,
      endDate: department.endDate,
      status: 'inactive'
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '删除成功'));
          dispatch(cleanmodal());
          dispatch(setCurrentDepartment({}));
          dispatch(setCurrentNode({}));
          let findDepartment = (list, did, pid) => {
            list.forEach(com => {
              if (com.dptId && com.dptId === pid) {
                _.remove(com.children, function (o) {
                  return o.dptId && o.dptId === did
                })
              } else if (!com.companyId && com.children && com.children > 0) {
                findDepartment(com.children, did, pid)
              }
            })
          }
          let findCompany = (list, cid, did, pid) => {
            list.forEach(com => {
              if (com.companyId === cid) {
                if (!pid) {
                  _.remove(com.children, function (o) {
                    return o.dptId && o.dptId === did
                  })
                } else {
                  findDepartment(com.children, did, pid)
                }
              } else if (com.children && com.children > 0) {
                findCompany(com.children, cid, did, pid)
              }
            })
          }
          if (companyTree.companyId === department.companyId) {
            _.remove(companyTree.children, function (o) {
              return o.dptId && o.dptId === department.dptId
            })
          } else {
            findCompany(companyTree.children, department.companyId, department.dptId, department.pid)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(createhttpMessage(err.response ? err.response : '', '删除失败'));
        return null;
      });
  });

export const initDepartmentInfo = createAction('@@xcloud/department/initDepartmentInfo',
  (type) => (dispatch, getState, httpClient) => {
    dispatch(setDepartmentError({}));
    if (type !== 'delete') {
      dispatch(setDepartmentChange(false));
    } else {
      dispatch(setDepartmentChange(true));
    }
    let params = getState().modal.parameters;
    let cnode = getState().company.currentNode;
    if (params.isEdit) {
      // edit mode
      dispatch(getDepartmentById(cnode.dptId, type))
        .then(() => {
          if (!cnode.companyId) {
            dispatch(setParentNode({}));
          } else {
            let companyTree = getState().company.companyTree;
            let pnode = findParent(cnode.companyId, 'companyId', [companyTree]);
            dispatch(setParentNode(pnode));
          }
        });

    } else {
      // create mode
      let userId = getState().auth.currentUserInfo.userId;
      let newDepartment = {
        "dptId": 0,
        "dptName": "",
        "creator": userId,
        "revisor": userId,
        "status": "active",
        "opentext": "",
        "companyId": cnode.companyId,
        "executable": false,
        "startDate": new Date(),
        "endDate": null
      }
      if (cnode.dptId) {
        newDepartment["pid"] = cnode.dptId;
      }
      dispatch(setParentNode(cnode));
      dispatch(setCurrentDepartment(newDepartment));
    }
  });

export const getDepartments = createAction('@@xcloud/department/getDepartments',
  (query) => (dispatch, getState, httpClient) => {

    let url = '/api/departments?status=active';
    if (query.offset !== undefined)
      url += "&offset=" + query.offset;
    if (query.size !== undefined)
      url += "&size=" + query.size;
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.companyId !== undefined)
      url += "&companyId=" + query.companyId;

    return httpClient.get(url)
      .then((res) => {
        if (res.data) {
          dispatch(setDepartments(res.data));
        }
        return res.data;
      })
  });


export const getDepartmentById = createAction('@@xcloud/department/getDepartmentById',
  (id, type) => (dispatch, getState, httpClient) => {

    let url = '/api/departments/' + id;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.data) {
          let data = res.data;
          if (type === 'delete') {
            data.endDate = new Date();
          }
          dispatch(setCurrentDepartment(data));
        }
        return res;
      })
  });

export const setCurrentDepartment = createAction('@@xcloud/department/setCurrentDepartment');

export const setDepartments = createAction('@@xcloud/department/setDepartments');

export const setDepartmentError = createAction('@@xcloud/department/setDepartmentError');

export const setDepartmentChange = createAction('@@xcloud/process/setDepartmentChange');

export const stopLoading = createAction('@@xcloud/department/stopLoading');

export const startLoading = createAction('@@xcloud/department/startLoading');

export const getAllDepartments = createAction('@@xcloud/department/getAllDepartments',
  (query) => (dispatch, getState, httpClient) => {

    let url = '/api/departments';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0"
    }
    if (query.size !== undefined)
      url += "&size=" + query.size;
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;

    return httpClient.get(url)
      .then((res) => {
        if (res.data) {
          dispatch(setAllDepartments(res.data));
        }
        return res.data;
      })
  });

export const setAllDepartments = createAction('@@xcloud/department/setAllDepartments');

export const departmentReducer = handleActions(
  {
    [setCurrentDepartment]: (state, { payload: value }) => {
      return {
        ...state,
        currentDepartment: value
      };
    },
    [setDepartments]: (state, { payload: value }) => {
      return {
        ...state,
        departments: value
      };
    },
    [setDepartmentError]: (state, { payload: value }) => {
      return {
        ...state,
        departmentError: value
      };
    },
    [setDepartmentChange]: (state, { payload: value }) => {
      return {
        ...state,
        departmentChange: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    },
    [setAllDepartments]: (state, { payload: value }) => {
      return {
        ...state,
        allDepartments: value
      };
    },
  },
  defaultState
);

export default departmentReducer;