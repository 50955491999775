import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssayRecordSummaryComponent from '@/components/ManufactureManagement/AssayRecordSummary';
import { getMoment, formatDate } from '@/utils/datetime';
import { initAssay, getAssayRecordSummary } from '@/redux/modules/manufacture/assay';
import { createMessage } from '@/redux/modules/message'
import { csv } from '@/utils/exportfile';

class AssayRecordSummaryContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: 0,
            dptId: 0,
            installId: 0,
            tempId: 0,
            testTarget: undefined,
            startDate: getMoment()(),
            endDate: getMoment()(),
            offset: 0,
            size: 0
        }
    }

    // 页面初期化获取数据
    componentWillMount() {
        this.props.initAssay();
    }

    handleChange = (event, field) => {
        if (field === 'companyId') {
            this.setState({
                companyId: parseInt(event.target.value),
                dptId: 0,
                installId: 0
            })
        } else if (field === 'dptId') {
            this.setState({
                dptId: parseInt(event.target.value)
            })
        } else if (field === 'installId') {
            this.setState({
                installId: parseInt(event.target.value)
            })
        } else if (field === 'tempId') {
            this.setState({
                tempId: parseInt(event.target.value)
            })
        }
    }

    handleDateChange = (date, field) => {
        if (field === 'startDate') {
            this.setState({
                startDate: date
            })
        } else if (field === 'endDate') {
            this.setState({
                endDate: date
            })
        }
    }

    handleExport = () => {
        if (!this.props.summary || this.props.summary.length === 0){
            return;
        }

        let fileName = '化验次数统计'
        let summary = this.props.summary;
        let data = [];
        let columns = ['化验记录', '物料名称', '化验项目', '化验次数']
        for (let i = 0; i < summary.length; i++) {
                        let cell = [
                            summary[i].tempName,
                            summary[i].testTarget,
                            summary[i].itemName,
                            summary[i].recordCount
                        ];
                        data.push(cell)
                    }
         csv(fileName, columns, data);

    }

    handleSearch = () => {
        this.props.getAssayRecordSummary(this.state.companyId, this.state.dptId, this.state.installId,
            undefined, undefined, undefined, this.state.tempId, undefined, formatDate(this.state.startDate),
            formatDate(this.state.endDate));
    }

    handleClearSearch = () => {
        this.setState({
            companyId: 0,
            dptId: 0,
            installId: 0,
            tempId: 0,
            startDate: getMoment()(),
            endDate: getMoment()()
        })
    }

    render() {
        return (
            <div>
                <AssayRecordSummaryComponent
                    companyList={this.props.companies}
                    installList={this.props.installations}
                    temps={this.props.temps}
                    records={this.props.summary}
                    departments={this.props.departments}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    exportCsv={this.handleExport.bind(this)}
                    testTarget={this.state.testTarget}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    companyId={this.state.companyId}
                    dptId={this.state.dptId}
                    installId={this.state.installId}
                    tempId={this.state.tempId}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        summary: state.assay.assayRecordSummary,
        temps: state.assay.assayTemps,
        installations: state.installation.installationList ? state.installation.installationList.list : [],
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initAssay: () => {
            dispatch(initAssay());
        },
        getAssayRecordSummary: (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate) => {
            dispatch(getAssayRecordSummary(companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('warn', message))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssayRecordSummaryContainer);
