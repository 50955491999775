import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceFormDetail from '@/components/DeviceManagement/DeviceFormList/DeviceFormDetail';
import {
    getForm,
    setForm,
    routeToFormList,
    createDeviceForm,
    updateDeviceForm,
    handleDeviceFormProcess,
    routeToFormInfo,
} from "@/redux/modules/deviceForm";
import { openApprovePannelWithDoc } from "@/redux/modules/approvePannel";
import { routeToDeviceInfo } from "@/redux/modules/devices";
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { treeToArray, arrayToTree } from "@/utils/constant";
import {
    processLine2ProcessApproval,
    processApproval2ProcessLine,
    getProcessApprovalStatus
} from '@/utils/processUtils';
import ApprovePannelWithDoc from "@/containers/ApprovePannelWithDoc";
import { createmodal } from '@/redux/modules/modal';

import PropTypes from "prop-types";
import * as _ from 'lodash';
import {
    DEVICE_FROM_TYPES,
    DEVICE_FORM_TARGET_TYPES,
    DEVICE_FROM_STATUS,
    DEVICE_ATTR_TYPES,
    DEVICE_ATTR_FIELDS,
} from '@/utils/device';
import DeviceFormApproval from '@/components/DeviceManagement/DeviceFormList/DeviceFormApproval';
import {
    setDeviceFormApprovalInstance,
    getDeviceFormApprovalInstance,
    updateApprovalInstance
} from '@/redux/modules/processApproval';

class DeviceFromDetailContainer extends Component {
    static propTypes = {
        currentUserInfo: PropTypes.object,
        forms: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            form: {},
            openProcessApproval: false,
            isEditForm: false,
            isEditApprovalInstance: false,
        }
    }

    // 组件渲染后... ...
    componentDidMount() {

        let {
            match,
            onhandleGetForm,
            goingForm,
            goingFormDtls,
            onhandleGetDeviceFormApprovalInstance,
        } = this.props

        let formId = Number(match.params.formId)
        if (formId) { //修改
            onhandleGetForm(formId).then(() => {
                let { form } = this.props
                this.setState({ form: _.clone(form) })
                onhandleGetDeviceFormApprovalInstance(form.formType, form.formId)
            })

        } else { //新建
            let newForm = _.clone(goingForm)
            if (newForm.formType === 'REGISTER_FORM') {
                newForm['formDtlList'] = []
            } else {
                newForm['formDtlList'] = _.clone(goingFormDtls)
            }
            this.setState({
                form: newForm
            })
        }
    }

    handleChange = (event, name) => {
        let { form } = this.state
        let newForm = {
            ...form,
        }
        newForm[name] = event.target.value
        this.setState({
            'form': newForm,
            'isEditForm': true
        })
    }

    // handleSelectChange = (event, name) => {
    //     let { searchCondition,
    //         onhandleSetSearchCondition
    //     } = this.props
    //     let newSearch = {
    //         ...searchCondition,
    //     }
    //     newSearch[name] = event.target.value
    //     onhandleSetSearchCondition(newSearch)
    // }

    handleCheck = (event, name) => {

    }

    /**
     * 1）修改表单信息
     * 2）修改审批流程
     * 3）新建表单信息
     * 4）
     */
    handleSave = async () => {
        const { form, isEditForm, isEditApprovalInstance } = this.state
        const { deviceFormApprovalInstance,
            onhandleUpdateDeviceForm,
            onhandleCreateDeviceForm,
            onhandleUpdateApprovalInstance,
            onhandleGetDeviceFormApprovalInstance,
            onhandleRouteToFormInfo,
        } = this.props

        let res = {}
        let lastestForm = form
        if (form.formId) { //修改表单信息
            if (isEditForm) { //修改表单
                res = await onhandleUpdateDeviceForm(form.formId, form)
                if (res && res.payload) {
                    lastestForm = res.payload
                    this.setState({ 'form': lastestForm, 'isEditForm': false })
                }
            }
            if (isEditApprovalInstance) { //修改审批流程
                res = await onhandleUpdateApprovalInstance(lastestForm.formType, lastestForm.formId, deviceFormApprovalInstance)
                if (res && res.payload) {
                    await onhandleGetDeviceFormApprovalInstance(lastestForm.formType, lastestForm.formId)
                    this.setState({ 'isEditApprovalInstance': false })
                }
            }
        } else { //新建表单信息
            res = await onhandleCreateDeviceForm(form)
            if (res && res.payload) {
                lastestForm = res.payload
                this.setState({ 'form': lastestForm, 'isEditForm': false })
                if (isEditApprovalInstance) { //修改审批流程
                    res = await onhandleUpdateApprovalInstance(lastestForm.formType, lastestForm.formId, deviceFormApprovalInstance)
                    await onhandleGetDeviceFormApprovalInstance(lastestForm.formType, lastestForm.formId)
                    if (res && res.payload) {
                        this.setState({ 'isEditApprovalInstance': false })
                    }
                }
                onhandleRouteToFormInfo(lastestForm.formId)
            }
        }
    }

    handleNavigateBack = () => {
        if (this.state.form.formId) {
            this.props.onhandleRouteToFormList()
        } else {
            this.props.onhandleRouteToDeviceInfo()
        }
    }

    handleOpenProcessApproval = () => {
        this.setState({ openProcessApproval: true })
    }
    handleDrawerClose = () => {
        this.setState({ openProcessApproval: false })
    }

    handleProcessApprovalEdit = () => {
        const { companies,
            currentUserInfo,
            deviceFormApprovalInstance,
            onhandleSetDeviceFormApprovalInstance } = this.props
        let companyIds = [currentUserInfo.companyId]
        const mainCompany = _.find(companies, (c) => { return !c.pid })
        if (mainCompany.companyId !== currentUserInfo.companyId) {
            companyIds.push(mainCompany.companyId)
        }
        let param = {
            companyId: companyIds,
            processTree: arrayToTree(processApproval2ProcessLine(_.cloneDeep(deviceFormApprovalInstance)), 'id', 'preId'),
            haveProcessSelect: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    const { processesline } = this.props
                    if (processesline && processesline.length > 0) {
                        let approvals = treeToArray(_.cloneDeep(processesline))
                        onhandleSetDeviceFormApprovalInstance(processLine2ProcessApproval(approvals))
                        this.setState({ 'isEditApprovalInstance': true })
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleCreateProcess(param);
    }

    handleAction = async (approval, comment, docInfo) => {
        const { form } = this.state
        const { currentUserInfo,
            onhandleHandleDeviceFormProcess,
            onhandleGetDeviceFormApprovalInstance } = this.props

        approval.opentext = comment
        let dto = {
            actionType: approval['actionType'],
            actionRole: currentUserInfo.roleId,
            actionUser: currentUserInfo.userId,
            approvalDTO: approval
        }
        let res = await onhandleHandleDeviceFormProcess(form.deviceId, form.formType, form.formId, dto)
        if (res && res.payload) {
            onhandleGetDeviceFormApprovalInstance(form.formType, form.formId)
        }
    }


    handleHandleProcessApproval = (approval, action) => {
        approval.actionType = action.code
        this.props.onhandleOpenApprovePannelWithDoc(action.name, '', false, this.handleAction.bind(this, approval))
    }

    render() {
        return (
            <React.Fragment>
                <DeviceFormDetail
                    formTypeList={DEVICE_FROM_TYPES}
                    targetTypeList={DEVICE_FORM_TARGET_TYPES}
                    formStatusList={DEVICE_FROM_STATUS}
                    attrTypes={DEVICE_ATTR_TYPES}
                    attrFields={DEVICE_ATTR_FIELDS}
                    isLoading={this.props.isLoading}
                    companies={this.props.companies}
                    form={this.state.form}
                    isEditForm={this.state.isEditForm}
                    isEditApprovalInstance={this.state.isEditApprovalInstance}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    // onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleNavigateBack={this.handleNavigateBack.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleOpenProcessApproval={this.handleOpenProcessApproval.bind(this)}
                />
                <DeviceFormApproval
                    currentUserInfo={this.props.currentUserInfo}
                    open={this.state.openProcessApproval}
                    deviceFormApprovalInstance={this.props.deviceFormApprovalInstance}
                    isEditApprovalInstance={this.state.isEditApprovalInstance}
                    processApprovalStatus={getProcessApprovalStatus(this.props.deviceFormApprovalInstance)}
                    onhandleDrawerClose={this.handleDrawerClose.bind(this)}
                    onhandleProcessApprovalEdit={this.handleProcessApprovalEdit.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleHandleProcessApproval={this.handleHandleProcessApproval.bind(this)}
                />
                {<ApprovePannelWithDoc />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        form: state.deviceForm.form,
        goingForm: state.deviceForm.goingForm,
        goingFormDtls: state.deviceForm.goingFormDtls,
        currentUserInfo: state.auth.currentUserInfo,
        isLoading: state.loading.globalLoading,
        companies: state.constant.companies,
        //流程相关
        deviceFormApprovalInstance: state.processApproval.deviceFormApprovalInstance,
        processesline: state.processlinewithuser.processesline,
        processNotices: state.processlinewithuser.processNotices,
        openWithDoc: state.approvePannel.openWithDoc,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetForm: (formId) => {
            return dispatch(getForm(formId))
        },
        onhandleSetForm: (form) => {
            return dispatch(setForm(form))
        },
        onhandleCreateDeviceForm: (form) => {
            return dispatch(createDeviceForm(form))
        },

        onhandleUpdateDeviceForm: (formId, form) => {
            return dispatch(updateDeviceForm(formId, form))
        },

        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            return dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleRouteToFormList: (form) => {
            return dispatch(routeToFormList(form))
        },
        onhandleRouteToDeviceInfo: (form) => {
            return dispatch(routeToDeviceInfo(form))
        },
        onhandleRouteToFormInfo: (formId) => {
            return dispatch(routeToFormInfo(formId))
        },

        //审批流程
        onhandleCreateProcess: (parms) => {
            return dispatch(createmodal('createProcessLineApproval', parms, 'large'));
        },
        onhandleSetDeviceFormApprovalInstance: (approvals) => {
            return dispatch(setDeviceFormApprovalInstance(approvals));
        },
        onhandleUpdateApprovalInstance: (formType, formId, approvals) => {
            return dispatch(updateApprovalInstance(formType, formId, approvals));
        },
        onhandleGetDeviceFormApprovalInstance: (formType, formId, approvals) => {
            return dispatch(getDeviceFormApprovalInstance(formType, formId, approvals));
        },
        onhandleHandleDeviceFormProcess: (deviceId, formType, formId, dto) => {
            return dispatch(handleDeviceFormProcess(deviceId, formType, formId, dto));
        },
        onhandleOpenApprovePannelWithDoc: (title, defalutComment, withDoc, confirmCallback, CloseCallback) => {
            return dispatch(openApprovePannelWithDoc(title, defalutComment, withDoc, confirmCallback, CloseCallback))
        },






    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceFromDetailContainer)
