import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { csv } from '../../utils/exportfile';
import _ from 'lodash';
// import { PhoneCallback } from '@material-ui/icons';
// import { checkActivityPlanByRole } from '../../utils/processUtils'

const porxyApi = '/api'

const initialState = {
    activityList: [],
    activityRules: [],
    userList: [],
    dutyRecords: [],
    openDate: null,
    teamActivityMaps: [],
    onDutyCreateOpen: false,
    teamActivityCreateOpen: false,
};


export const setTeamActivityMaps = createAction('@@anfenguserportal/personal/setTeamActivityMaps')

export const getTeamActivityMaps = createAction('@@anfenguserportal/personal/getTeamActivityMaps',
    () => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/teamactivitymaps'
        return httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setTeamActivityMaps(res.data))
                    return (res.data)
                }
            })
            .catch(err => {
                let msg = `班组安全活动获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    });


export const setTeamActivityPlan = createAction('@@anfenguserportal/personal/setTeamActivityPlan')

export const getTeamActivityPlan = createAction('@@anfenguserportal/personal/getTeamActivityPlan',
    (year, month) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/teamactivityplan'
        let query = ''
        if (year || month) {
            let strs = []
            if (year)
                strs.push('year=' + year)
            if (month)
                strs.push('month=' + month)
            query = '?' + strs.join('&')
        }
        return httpClient.get(url + query)
            .then((res) => {
                if (res) {
                    dispatch(setTeamActivityPlan(res.data))
                    return (res.data)
                }
            })
            .catch(err => {
                let msg = `班组安全活动获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    });

export const setOnDutyPlan = createAction('@@anfenguserportal/personal/setOnDutyPlan')

export const getOnDutyPlan = createAction('@@anfenguserportal/personal/getOnDutyPlan',
    (year, month) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/ondutyplan'
        let query = ''
        if (year || month) {
            let strs = []
            if (year)
                strs.push('year=' + year)
            if (month)
                strs.push('month=' + month)
            query = '?' + strs.join('&')
        }
        return httpClient.get(url + query)
            .then((res) => {
                if (res) {
                    dispatch(setOnDutyPlan(res.data))
                    return (res.data)
                }
            })
            .catch(err => {
                let msg = `班组安全活动获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    });
export const getRules = createAction('@@anfenguserportal/personal/getrules',
    (callback) => (dispatch, getState, httpClient) => {

        let url = porxyApi + '/rules'

        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setRules(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `个人安全行动规则列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const getActivityList = createAction('@@anfenguserportal/personal/personalactivitylist',
    (query, callback) => (dispatch, getState, httpClient) => {

        let url = porxyApi + '/safetyactivitis'
        if (Object.keys(query).length > 0) {
            let strs = []
            if (query.companyId) strs.push('companyId=' + query.companyId)
            if (query.dptId) strs.push('dptId=' + query.dptId)
            if (query.fromDate) strs.push('fromDate=' + query.fromDate)
            if (query.priority) strs.push('priority=' + query.priority)
            if (query.toDate) strs.push('toDate=' + query.toDate)
            if (query.type) strs.push('type=' + query.type)
            if (query.userId) strs.push('userId=' + query.userId)
            url = url + '?' + strs.join('&')
        }

        httpClient.get(url, { headers: { need_loading: 'true' } }).then((res) => {
            if (res) {
                dispatch(setActivityList(res.data))
                if (callback)
                    callback(res.data)
            }
        }).catch(err => {
            let msg = `个人安全行动计划列表获取失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
        return {}
    });

export const exportSafeActivityList = createAction('@@anfenguserportal/personalactivitylist/exportSafeActivityList',
    (companyId) => (dispatch, getState, httpClient) => {
        //let search = getState().deviceOpsRecord.search;
        let safeActivityList = getState().safetyActivity.onDutyActivities;
        safeActivityList = _.filter(safeActivityList, (activity) => { return activity.companyId === companyId })
        let fileName = '带班值班'
        let companyList = getState().constant.companies;
        let companyInfo = _.find(companyList, { companyId: companyId })
        fileName = fileName + '_' + companyInfo.companyAlias

        fileName = fileName + '_' + new Date().getTime()

        let columns = ['班次', '单位', '姓名']
        let data = []
        safeActivityList.forEach(r => {
            let cell = [
                r.activity,
                companyInfo.companyAlias,
                r.users[0].lastName.concat(r.users[0].firstName)
            ];
            data.push(cell)
        })
        dispatch(csv(fileName, columns, data));

    }
);

export const getDutyRecords = createAction('@@anfenguserportal/personal/getDutyRecords',
    (activityId, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/safetyactivitis/' + activityId + '/dutyrecords'

        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setDutyRecords(res.data))
                    if (callback)
                        callback(res.data)

                }
            })
            .catch(err => {
                let msg = `值班记录列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const getChildusers = createAction('@@anfenguserportal/personal/getChildusers',
    (level, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/childusers?level=' + level
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setChildusers(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `用户列表获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const getUserDepartments = createAction('@@anfenguserportal/personal/getUserDepartments',
    (companyId, userId, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/users/' + userId + '/departments?companyId=' + companyId

        httpClient.get(url)
            .then((res) => {
                if (res) {
                    // dispatch(setChildusers(res.data))
                    if (callback)
                        callback(res.data)

                }
            })
            .catch(err => {
                let msg = `用户部门获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const setActivityList = createAction('@@anfenguserportal/personalactivitylist/setactivitylist')
export const setRules = createAction('@@anfenguserportal/personalactivitylist/setrules')
export const setDutyRecords = createAction('@@anfenguserportal/personalactivitylist/setdutyrecords')
export const setChildusers = createAction('@@anfenguserportal/personalactivitylist/setChildusers')

export const addActivity = createAction('@@anfenguserportal/personalactivitylist/addactivity',
    (activity, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/safetyactivitis', activity)
            .then((res) => {
                dispatch(createhttpMessage(res, "增加个人安全活动计划成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `增加个人安全活动计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const addRule = createAction('@@anfenguserportal/personalactivitylist/addrule',
    (rule, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/rules', rule)
            .then((res) => {
                dispatch(createhttpMessage(res, "增加安全活动规则成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `增加安全活动规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const addDutyRecord = createAction('@@anfenguserportal/personalactivitylist/addrecord',
    (record, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/users/dutyrecords', record)
            .then((res) => {
                dispatch(createhttpMessage(res, "增加记录成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `增加记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const updateActivity = createAction('@@anfenguserportal/personalactivitylist/updateactivity',
    (activity, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/safetyactivitis/' + activity.actId, activity)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新个人安全活动计划成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新个人安全活动计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateRule = createAction('@@anfenguserportal/personalactivitylist/updaterule',
    (rule, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/rules/' + rule.ruleId, rule)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新个人安全活动规则成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新个人安全活动规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateDutyRecord = createAction('@@anfenguserportal/personalactivitylist/updatedutyrecord',
    (record, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/dutyrecords/' + record.recordId, record)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新记录成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteActivity = createAction('@@anfenguserportal/personalactivitylist/deleteActivity',
    (activity, callback) => (dispatch, getState, httpClient) => {
        httpClient.delete(porxyApi + '/safetyactivitis/' + activity.actId, activity)
            .then((res) => {
                dispatch(createhttpMessage(res, "删除个人安全活动计划成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `删除个人安全活动计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startProcessAnnualCalendar = createAction('@@anfenguserportal/annualcalendar/start',
    (plan, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/annualcalendar/start', plan)
            .then((res) => {
                dispatch(createhttpMessage(res, "启动年度日历流程"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `启动年度日历流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeProcessAnnualCalendar = createAction('@@anfenguserportal/annualcalendar/execute',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/annualcalendar/process', actionParams)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行年度日历流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行年度日历流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startProcessMonthlyOnDuty = createAction('@@anfenguserportal/monthlyonduty/start',
    (plan) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/onduty/start', plan, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(createhttpMessage(res, "启动月度带班值班流程成功"));
                if (res)
                    return (res.data)
            }
            ).catch(err => {
                let msg = `启动月度带班值班流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const setInvalidDays = createAction('@@anfenguserportal/safteyactivity/setInvalidDays')
export const executeProcessMonthlyOnDuty = createAction('@@anfenguserportal/monthlyonduty/execute',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/onduty/process', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行月度带班值班流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行月度带班值班流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startProcessTeamActivity = createAction('@@anfenguserportal/teamactivity/start',
    (plan, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/teamactivity/start', plan, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(createhttpMessage(res, "启动月度班组活动流程成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `启动月度班组活动流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const executeProcessTeamActivity = createAction('@@anfenguserportal/teamactivity/execute',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/teamactivity/process', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行月度班组活动流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行月度班组活动流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateTeamActivity = createAction('@@anfenguserportal/personalactivitylist/updateteamactivity',
    (activity, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/teamactivity/' + activity.actId, activity)
            .then((res) => {
                dispatch(createhttpMessage(res, "更新班组活动成功"));
                if (res && callback)
                    callback(res.data)
            }
            ).catch(err => {
                let msg = `更新班组活动失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const setSelectedUser = createAction('@@anfenguserportal/safteyactivity/selecteduser')
export const setSelectedDate = createAction('@@anfenguserportal/safteyactivity/selected')
export const setOpenDate = createAction('@@anfenguserportal/safteyactivity/opendate')
export const setOpenMonth = createAction('@@anfenguserportal/safteyactivity/openMonth')
export const setViewType = createAction('@@anfenguserportal/safteyactivity/viewType')

const _getActivities = createAction('@@anfenguserportal/personal/getactivitylist',
    (query, callback) => (dispatch, getState, httpClient) => {

        let url = porxyApi + '/safetyactivitis'
        if (Object.keys(query).length > 0) {
            let strs = []
            if (query.companyId) strs.push('companyId=' + query.companyId)
            if (query.dptId) strs.push('dptId=' + query.dptId)
            if (query.fromDate) strs.push('fromDate=' + query.fromDate)
            if (query.priority) strs.push('priority=' + query.priority)
            if (query.toDate) strs.push('toDate=' + query.toDate)
            if (query.type) strs.push('type=' + query.type)
            if (query.userId) strs.push('userId=' + query.userId)
            url = url + '?' + strs.join('&')
        }

        httpClient.get(url, { headers: { need_loading: 'true' } }).then((res) => {
            if (res && callback) {
                callback(res.data)
            }
        }).catch(err => {
            let msg = `安全活动列表获取失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
        return {}
    });
export const setOnDutyActivities = createAction('@@anfenguserportal/safteyactivity/setondutyactivities')
export const setTeamActivities = createAction('@@anfenguserportal/safteyactivity/setteamactivities')
export const setOnDutyCreateOpen = createAction('@@anfenguserportal/safteyactivity/setondutycreateopen')
export const setTeamActivityCreateOpen = createAction('@@anfenguserportal/safteyactivity/setteamactivitycreateopen')

export const getOnDutyActivities = createAction('@@anfenguserportal/personal/getondutyactivities',
    (query, callback) => (dispatch, getState, httpClient) => {
        dispatch(_getActivities(query, (list) => {
            dispatch(setOnDutyActivities(list))
            callback && callback(list)
        }))
    });

export const getTeamActivities = createAction('@@anfenguserportal/personal/getteamactivities',
    (query, callback) => (dispatch, getState, httpClient) => {
        dispatch(_getActivities(query, (list) => {
            dispatch(setTeamActivities(list))
            callback && callback(list)
        }))
    });


export const PersonalActibityReducer = handleActions(
    {
        [setTeamActivityMaps]: (state, { payload: data }) => {
            return {
                ...state,
                teamActivityMaps: data
            }
        },
        [setOnDutyPlan]: (state, { payload: data }) => {
            return {
                ...state,
                onDutyPlan: data
            }
        },
        [setTeamActivityPlan]: (state, { payload: data }) => {
            return {
                ...state,
                teamActivityPlan: data
            }
        },
        [setOnDutyCreateOpen]: (state, { payload: data }) => {
            return {
                ...state,
                onDutyCreateOpen: data
            }
        },
        [setTeamActivityCreateOpen]: (state, { payload: data }) => {
            return {
                ...state,
                teamActivityCreateOpen: data
            }
        },
        [setActivityList]: (state, { payload: data }) => {
            return {
                ...state,
                activityList: data
            }
        },
        [setOnDutyActivities]: (state, { payload: data }) => {
            return {
                ...state,
                onDutyActivities: data
            }
        },
        [setTeamActivities]: (state, { payload: data }) => {
            return {
                ...state,
                teamActivities: data
            }
        },
        [setRules]: (state, { payload: data }) => {
            return {
                ...state,
                activityRules: data
            }
        },
        [setChildusers]: (state, { payload: data }) => {
            return {
                ...state,
                userList: data
            }
        },
        [setDutyRecords]: (state, { payload: data }) => {
            return {
                ...state,
                dutyRecords: data
            }
        },
        [setSelectedDate]: (state, { payload: data }) => {
            return {
                ...state,
                selectedDate: data
            }
        },

        [setOpenDate]: (state, { payload: data }) => {
            return {
                ...state,
                openDate: data
            }
        },
        [setSelectedUser]: (state, { payload: data }) => {
            return {
                ...state,
                selectedUser: data
            }
        },
        [setOpenMonth]: (state, { payload: data }) => {
            return {
                ...state,
                openMonth: data
            }
        },
        [setViewType]: (state, { payload: data }) => {
            return {
                ...state,
                viewType: data
            }
        },
        [setInvalidDays]: (state, { payload: data }) => {
            return {
                ...state,
                inValidDays: data
            }
        },
    },
    initialState
)

export default PersonalActibityReducer

