import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalPage from '../components/ModalPage';

class ModalPageContainer extends Component {
  static propTypes = {
    open: PropTypes.bool,
    children: PropTypes.string,
    size: PropTypes.string
  }

  render() {
    return (
      <ModalPage
        open={this.props.open}
        children={this.props.children}
        size={this.props.size} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.modal.open,
    children: state.modal.children,
    size: state.modal.size,
  };
};


export default connect(
  mapStateToProps
)(ModalPageContainer);
