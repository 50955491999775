import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {},
    button: {
        marginLeft: theme.spacing(2)
    },
    divider: {
        marginBottom: theme.spacing(2)
    },
    dateField: {
        width: '90%'
    },
    required: {
        display: 'inline-block',
        color: theme.palette.error.main,
        marginRight: 2,
    },
    item: {
        padding: 0
    },
    card: {
        width: '100%',
        padding: theme.spacing(2),
    },
    gridItem: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2)
    },
    gridHolder: {
        margin: 0,
        bottom: 0,
    },
    label: {
        paddingTop: theme.spacing(0.8),
        whiteSpace: 'nowrap',
        width: 80,
        textAlign: 'right'
    },
    formContainer: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 80px)'
    },
    infoLabel: {
        // fontSize: 14,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        width: 80,
        textAlign: 'right',
    },
    infoContent: {
        // fontSize: 14,
        paddingLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        width: 'calc(100% - 80px)',
    },
    textField: {
        width: '100%'
    },
    textarea: {
        width: '80%'
    },
    gridContainer: {
        paddingBottom: theme.spacing(1),
    },
    bottomButtonContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    infoGrid: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    subTitle: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        borderLeft: '2px solid ' + theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

export default withStyles(styles);
