import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { download } from '@/redux/modules/minio';
import { getMoment } from '@/utils/datetime';
import _ from 'lodash';

const porxyApi = '/api'

const initialState = {
    plans: [], //人力资源计划列表
    mainPlans: [], //人力资源计划列表
};

export const setFixedPlans = createAction('@@xcloud/amoeba/setFixedPlans');
export const setMainFixedPlans = createAction('@@xcloud/amoeba/setMainFixedPlans');

export const getFixedPlans = createAction('@@xcloud/amoeba/getFixedPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/fixedplans'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setFixedPlans(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取人力资源计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateFixedPlanDtl = createAction('@@xcloud/amoeba/updateFixedPlanDtl',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/fixedplans/dtls/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新人力资源计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新人力资源计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateFixedPlan = createAction('@@xcloud/amoeba/updateFixedPlan',
    (planId, planDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/fixedplans/${planId}`, planDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新人力资源计划成功"));
                }
            }).catch(err => {
                let msg = `更新人力资源计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const addFixedPlan = createAction('@@xcloud/amoeba/addFixedPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/fixedplans`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "修改人力资源计划成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `修改人力资源计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const exportFixedPlanTemplate = createAction('@@xcloud/amoeba/exportFixedPlanTemplate',
    (year, companyId) => (dispatch, getState, httpClient) => {
        const paramYear = year ? year : getMoment()().year();
        const paramCompanyId = companyId ? companyId : getState().auth.currentUserInfo.companyId;
        let url = porxyApi + `/amoeba/export/fixedplans?`;
        const query = [];
        query.push("year=" + paramYear);
        query.push("companyId=" + paramCompanyId);
        return httpClient.get(url + query.join('&'), { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let tenantId = getState().auth.userProfile.roles[0].tenantId
                        let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                        dispatch(download(
                            res.data.docName,
                            mybucket
                        ))
                    }
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '导出人力资源计划失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const importFixedPlan = createAction('@@xcloud/amoeba/importFixedPlan',
    (dto, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(porxyApi + `/amoeba/import/fixedplans`, dto, { headers: { need_loading: true } }).then(
                (res) => {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "导入人力资源计划成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `导入人力资源计划失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const amoebaFixedPlanReducer = handleActions(
    {
        [setFixedPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setMainFixedPlans]: (state, { payload: data }) => {
            return {
                ...state,
                mainPlans: data
            }
        },
    },
    initialState
)

export default amoebaFixedPlanReducer
