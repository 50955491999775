import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import AppointmentWorkPermit from './AppointmentWorkPermit';
import {
    Grid,
    Typography,
    Button,
    Chip,
    Tooltip,
    Card
} from '@material-ui/core';
import {
    AttachFile,
} from '@material-ui/icons';
import { Prompt } from 'react-router-dom';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { STATUS_VALUE } from '@/utils/constant';
import _ from 'lodash';

const AppointmentDetail = ({
    classes,
    companyList,
    specCodeList,
    currentWorkOrder,
    currentAppointment,
    currentPermit,
    workRecordList,
    newAppointmentChange,
    userList,
    userProfile,
    onhandleBack,
    onhandlePermit,
    onhandleDeletePermit,
    onhandleSelectApprover,
    onhandleSubmitPermit,
    onhandleRejectPermit,
    onhandleApprovePermit,
    onhandleDownload,
    onhandleAttachment,
    onhandleAcceptance,
    onhandleAddDetail,
    onhandleDonePermit,
    workType
}) => {

    // console.log('========= currentAppointment ==========')
    // console.log(currentAppointment)
    // console.log('========= currentPermit ==========')
    // console.log(currentPermit)
    // console.log('========= workRecordList ==========')
    // console.log(workRecordList)


    let currentUserId = userProfile.userId;

    /** 当前作业为特殊作业 */
    const isSpecWork = Boolean(currentAppointment.specCode);

    /** 当前作业完成许可 */
    // const appApproved = Boolean(![STATUS_VALUE.STATUS_NEW, STATUS_VALUE.STATUS_ACTIVE, STATUS_VALUE.STATUS_SUBMITTED].includes(currentAppointment.status));

    /** 当前作业只读 */
    const appReadOnly = Boolean([STATUS_VALUE.STATUS_INVALID, STATUS_VALUE.STATUS_TERMINATED, STATUS_VALUE.STATUS_PAUSED, STATUS_VALUE.STATUS_FINALIZED, STATUS_VALUE.STATUS_ACCEPTANCED, STATUS_VALUE.STATUS_DONE].includes(currentAppointment.status));

    const viewMode = Boolean(workType === 'view');
    /** 当前作业结束 */
    const appFinalized = Boolean([STATUS_VALUE.STATUS_FINALIZED].includes(currentAppointment.status));

    /** 当前作业验收 */
    const appAcceptanced = Boolean([STATUS_VALUE.STATUS_ACCEPTANCED].includes(currentAppointment.status));

    /** 当前作业验收 */
    const appApproved = Boolean([STATUS_VALUE.STATUS_APPROVED].includes(currentAppointment.status));

    const appDone = Boolean([STATUS_VALUE.STATUS_DONE].includes(currentAppointment.status));
    /** 当前作业完成排查 */
    // const appInspected =
    //     isSpecWork ?
    //         (Boolean(appFinalized && currentAppointment.inspectionDetail && currentAppointment.inspectionDetail.status && currentAppointment.inspectionDetail.status === STATUS_VALUE.STATUS_DONE))
    //         :
    //         (Boolean(appFinalized));

    /** 当前用户是许可负责人 */
    const isAppHead = Boolean(currentAppointment.appHead && currentUserId === currentAppointment.appHead);

    /** 当前用户是工单负责人 */
    const isOrderHead = Boolean(currentWorkOrder.orderHead && currentUserId === currentWorkOrder.orderHead);

    /** 当前作业完成提交 */
    // const appSubmitted = Boolean(![STATUS_VALUE.STATUS_NEW, STATUS_VALUE.STATUS_ACTIVE].includes(currentAppointment.status));

    /** 当前作业终止 */
    // const appTerminated = Boolean([STATUS_VALUE.STATUS_TERMINATED].includes(currentAppointment.status));

    /** 当前作业暂停（回到提交状态且清空时间） */
    // const appPaused = Boolean(
    //     (isSpecWork && [STATUS_VALUE.STATUS_SUBMITTED].includes(currentAppointment.status))
    //     || (!isSpecWork && [STATUS_VALUE.STATUS_APPROVED].includes(currentAppointment.status))
    // );

    /** 当前作业暂停（暂停状态，此时可继续或延期） */
    // const appPaused = Boolean([STATUS_VALUE.STATUS_PAUSED].includes(currentAppointment.status));

    /** 当前作业废止 */
    // const appInvalid = Boolean([STATUS_VALUE.STATUS_TERMINATED].includes(currentAppointment.status));

    /** 当前作业发生记录 */
    const hasWorkRecord = Boolean(workRecordList && workRecordList.length && workRecordList.length > 0);

    const tableRef = React.createRef();

    const renderCompany = (appointment) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === appointment.locationCompany })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName;
    }

    const renderUser = (userId) => {
        let userName = '-';
        if (userList && userList.length > 0) {
            let user = _.find(userList, function (o) { return o.userId === userId })
            if (user) {
                userName = user.lastName + user.firstName;
            }
        }
        return userName;
    }

    const renderSpecCode = (appointment) => {
        let codeName = ''
        if (specCodeList && specCodeList.length > 0) {
            let value = _.find(specCodeList, function (o) { return o.code === appointment.specCode })
            if (value) {
                codeName = value.name
            }
        }
        return codeName
    }

    const renderDate = (date) => {
        return date ? getDateByTimestamp(date, true) : ''
    }

    const workRecordColumns = [
        {
            title: '作业设备',
            field: 'specCode',
            render: row => row ? <Typography>{row.deviceName}</Typography> : '',
        },
        {
            title: '记录描述',
            field: 'content',
            render: row => row ? <Typography>{row.content}</Typography> : '',
            align: 'left',
            width: '400px',
        },
        {
            title: '记录结果',
            field: 'result',
            render: row => row ? <Typography>{row.result}</Typography> : '',
        },
        {
            title: '工作用时',
            field: 'workTime',
            render: row => row ? <Typography>{row.workTime + '分钟'}</Typography> : '',
        },
        {
            title: '填报人',
            field: 'appHead',
            render: row => row ? <Typography>{row.creatorLastName + row.creatorFirstName}</Typography> : '',
        },
        {
            title: '填报时间',
            field: 'startTime',
            render: row => row ? <Typography>{renderDate(row.createTime)}</Typography> : '',
        },
        {
            title: '修改时间',
            field: 'endTime',
            render: row => row ? <Typography>{renderDate(row.updateTime)}</Typography> : '',
        },
    ]

    const tableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
        toolbar: true,
    }

    const tableStyle = {
        ...style
    }

    const renderOrderNo = (currentWorkOrder) => {
        return <Chip
            size="small"
            className={currentWorkOrder.masterOrder ? classes.pic21 : classes.pic11}
            label={currentWorkOrder.orderNo} />
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid container alignItems="center">
                <Grid container className={classes.title}>
                    <Typography variant="h3" className={classes.plantname}>
                        {'作业详情'}
                    </Typography>
                </Grid>
                <Grid item className={classes.flexgrow}></Grid>
            </Grid>
            {Boolean(currentAppointment) &&
                <React.Fragment>
                    <Grid container alignItems="center">
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    工单编号
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {renderOrderNo(currentWorkOrder)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {currentWorkOrder.accName ?
                            <React.Fragment>
                                <Grid item className={classes.action} xs={3}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.label}>
                                            部件
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label}>
                                            {currentWorkOrder.accName ? currentWorkOrder.accName : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.action} xs={3}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.label}>
                                            （装置）设备
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label}>
                                            {currentWorkOrder.installName ? `（${currentWorkOrder.installName}）` : '（-）'}{currentWorkOrder.deviceName ? currentWorkOrder.deviceName : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item className={classes.action} xs={3}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.label}>
                                            装置
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label}>
                                            {currentWorkOrder.installName ? currentWorkOrder.installName : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.action} xs={3}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.label}>
                                            设备
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label}>
                                            {currentWorkOrder.deviceName ? currentWorkOrder.deviceName : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'作业编号'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {
                                        currentAppointment.mainWork ?
                                            <Chip
                                                size="small"
                                                variant={'outlined'}
                                                className={classes.pic41}
                                                label={currentAppointment.serialNo}
                                            />
                                            : currentAppointment.specCode ? <Chip
                                                size="small"
                                                variant={'outlined'}
                                                className={classes.pic31}
                                                label={currentAppointment.serialNo}
                                            /> : <Chip
                                                size="small"
                                                variant={'outlined'}
                                                className={classes.pic51}
                                                label={currentAppointment.serialNo}
                                            />
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'特殊作业类型'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {currentAppointment.specCode ? renderSpecCode(currentAppointment) : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'作业位置所在单位'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {currentAppointment.locationCompany ? renderCompany(currentAppointment) : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'作业具体位置'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {currentAppointment.location ? currentAppointment.location : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item className={classes.action} xs={3}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'作业负责人'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {renderUser(currentAppointment.appHead)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.action} xs={6}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>
                                    {'作业内容'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.label}>
                                    {currentAppointment.appContent ? currentAppointment.appContent : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Card className={classes.card}>
                        <Grid container alignItems="center">
                            <Grid container className={classes.title}>
                                <Typography variant="h5" className={classes.plantname}>
                                    <strong>{'特殊许可办理记录'}</strong>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                        </Grid>
                        <AppointmentWorkPermit
                            {...{
                                classes,
                                isSpecWork,
                                isAppHead,
                                isOrderHead,
                                appReadOnly,
                                currentAppointment,
                                currentPermit,
                                userList,
                                userProfile,
                                onhandlePermit,
                                onhandleDeletePermit,
                                onhandleSelectApprover,
                                onhandleSubmitPermit,
                                onhandleRejectPermit,
                                onhandleApprovePermit,
                                onhandleDownload,
                                onhandleDonePermit,
                                viewMode,
                                showtype: 'new'
                            }}
                        />
                    </Card>
                </React.Fragment>
            }
            <Grid>
                <Card className={classes.card}>
                    <Grid container className={classes.title}>
                        <Typography variant="h5" className={classes.plantname}>
                            <strong>{'作业记录'}</strong>
                        </Typography>
                    </Grid>
                    <MaterialTable
                        tableRef={tableRef}
                        className={classes.materialtable}
                        title='作业记录'
                        columns={workRecordColumns}
                        data={workRecordList}
                        options={tableOptions}
                        localization={localization}
                        style={tableStyle}
                        actions={[
                            rowData => ({
                                icon: AttachFile,
                                tooltip: '查看附件',
                                disabled: !rowData.docs.length && rowData.docs.length === 0,
                                onClick: (event, rowData) => {
                                    onhandleAttachment(event, rowData, false)
                                }
                            }),
                            // rowData => ({
                            //     icon: 'delete_outlined',
                            //     tooltip: '删除',
                            //     onClick: (event, rowData) => {
                            //         onhandleDelete(rowData)
                            //     },
                            //     hidden: rowData.mainWork
                            // }),
                            // rowData => ({
                            //     icon: 'description',
                            //     tooltip: '查看作业',
                            //     onClick: (event, rowData) => {
                            //         onhandleView(rowData)
                            //     },
                            //     // hidden: rowData.mainWork
                            // }),
                            {
                                icon: 'add',
                                hidden: Boolean(!((isAppHead || isOrderHead) && appApproved)),
                                tooltip: '新建',
                                isFreeAction: true,
                                onClick: (event) => {
                                    onhandleAddDetail(event)
                                },
                            }
                        ]
                        }
                    >
                    </MaterialTable>
                </Card>
            </Grid>
            <Grid>
                {(appAcceptanced || appDone) && isSpecWork
                    ? <Card className={classes.card}>
                        <Grid container alignItems="center">
                            <Grid container className={classes.title}>
                                <Typography variant="h5" className={classes.plantname}>
                                    <strong>{'特殊许可关闭记录'}</strong>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                        </Grid>
                        <AppointmentWorkPermit
                            {...{
                                classes,
                                isSpecWork,
                                isAppHead,
                                isOrderHead,
                                appReadOnly,
                                currentAppointment,
                                currentPermit,
                                userList,
                                userProfile,
                                onhandlePermit,
                                onhandleDeletePermit,
                                onhandleSelectApprover,
                                onhandleSubmitPermit,
                                onhandleRejectPermit,
                                onhandleApprovePermit,
                                onhandleDownload,
                                onhandleDonePermit,
                                viewMode,
                                showtype: 'close'
                            }}
                        />
                    </Card>
                    : null
                }
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
                <Button variant="outlined" color="primary" className={classes.button}
                    onClick={event => onhandleBack(event)}
                >
                    {'返回'}
                </Button>
                {/*
                    !viewMode && !appReadOnly && isAppHead &&
                    <Button variant="contained" color={'primary'} className={classes.inactiveBtn}
                        onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_INVALID_ALL)} >
                        {'全体作业废止'}
                    </Button>
                */}
                {/*
                    !viewMode && !appReadOnly && isAppHead &&
                    <Button variant="contained" className={classes.terminateBtn}
                        onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_TERMINATED)} >
                        {'作业终止'}
                    </Button>
                */}
                {/*
                    !viewMode && !appReadOnly && isAppHead &&
                    <Button variant="contained" className={classes.pauseBtn}
                        onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_PAUSED)} >
                        {'作业延期'}
                    </Button>
                */}
                {
                    !viewMode && !appReadOnly && (isAppHead || isOrderHead) && hasWorkRecord &&
                    <Button variant="contained" color={'primary'} className={classes.finishBtn}
                        onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_FINALIZED)} >
                        {'作业结束'}
                    </Button>
                }
                {
                    isOrderHead && appFinalized &&
                    <Tooltip title={'验收不通过'}>
                        <span>
                            <Button variant="contained" color={'primary'} className={classes.inactiveBtn} disabled={!appFinalized}
                                onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_REJECTED)} >
                                {'验收不通过'}
                            </Button>
                        </span>
                    </Tooltip>
                }
                {
                    isOrderHead && appFinalized &&
                    <Tooltip title={'验收通过'}>
                        <span>
                            <Button variant="contained" color={'primary'} className={classes.finishBtn} disabled={!appFinalized}
                                onClick={event => onhandleAcceptance(isSpecWork ? STATUS_VALUE.STATUS_ACCEPTANCED : STATUS_VALUE.STATUS_DONE)} >
                                {'验收通过'}
                            </Button>
                        </span>
                    </Tooltip>
                }
                {
                    isOrderHead && appAcceptanced && isSpecWork &&
                    <Tooltip title={'关闭特殊许可'}>
                        <span>
                            <Button variant="contained" color={'primary'} className={classes.finishBtn} disabled={!appAcceptanced}
                                onClick={event => onhandleAcceptance(STATUS_VALUE.STATUS_DONE)} >
                                {'关闭特殊许可'}
                            </Button>
                        </span>
                    </Tooltip>
                }
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={newAppointmentChange} />
        </div >
    );

};

AppointmentDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired,
    specCodeList: PropTypes.array.isRequired,
    newAppointmentChange: PropTypes.bool.isRequired,
    currentAppointment: PropTypes.object.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandlePermit: PropTypes.func.isRequired,
    onhandleDownload: PropTypes.func.isRequired,
};

export default (withStyles(AppointmentDetail));