import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoticeConfigTarget from "../../../components/SystemManagement/NoticeManagement/NoticeConfigTarget";
import PropTypes from "prop-types";
import { updateConfigTargets, initTarget } from '../../../redux/modules/notice';
import * as _ from 'lodash';
import { push } from 'connected-react-router';
import { createMessage } from '@/redux/modules/message';
import { createmodal } from '@/redux/modules/modal';

class NoticeConfigTargetContainer extends Component {

    static propTypes = {
        currentConfig: PropTypes.object
    };

    constructor(props) {
        super(props)
        this.state = {
            targets: [],
            dptTree: [],
            roleTree: [],
            roleList: [],
            editingRow: {}
        }
    }

    componentWillMount() {
        this.props.onhandleInitTarget();
    }

    componentDidMount() {
        const { noticeConfig } = this.props;
        let data = [];
        let i = 0;
        if (noticeConfig.companies) {
            _.forEach(noticeConfig.companies, val => {
                i++;
                data.push({
                    rowId: i,
                    targetLvl: 1,
                    targetId: val.companyId,
                    targetName: val.companyName
                })
            })
        }
        if (noticeConfig.dpts) {
            _.forEach(noticeConfig.dpts, val => {
                i++;
                data.push({
                    rowId: i,
                    targetLvl: 2,
                    targetId: val.dptId,
                    targetName: val.dptName
                })
            })
        }
        if (noticeConfig.roles) {
            _.forEach(noticeConfig.roles, val => {
                i++;
                data.push({
                    rowId: i,
                    targetLvl: 4,
                    targetId: val.roleId,
                    targetName: val.roleName
                })
            })
        }
        if (noticeConfig.users) {
            _.forEach(noticeConfig.users, val => {
                i++;
                data.push({
                    rowId: i,
                    targetLvl: 6,
                    targetId: val.userId,
                    targetName: val.lastName + val.firstName
                })
            })
        }

        let companies = this.props.companies
        let departments = this.props.departments
        let dptTree = [];
        companies.forEach(com => {
            let c = {
                label: com['companyName'],
                value: "c" + com['companyId'],
                selectable: false,
                children: []
            }
            departments.forEach(dpt => {
                if (dpt.companyId === com.companyId) {
                    c.children.push({
                        label: dpt['dptName'],
                        value: dpt['dptId']
                    })
                }
            })
            dptTree.push(c)
        })

        let roleTree = []
        let roleList = [];
        let roles = this.props.roleTree
        roles.forEach(element => {
            let rt = {
                label: element['companyName'],
                value: 'c' + element['companyId'],
                selectable: false,
                children: []
            }
            if (element['roleGroups']) {
                element['roleGroups'].forEach(rg => {
                    let rgroup = {
                        label: rg['groupName'],
                        value: 'g' + rg['groupId'],
                        selectable: false,
                        children: []
                    }
                    if (rg['roleList']) {
                        rg['roleList'].forEach(item => {
                            let rr = {
                                label: item['roleName'],
                                value: item['roleId']
                            }
                            rgroup.children.push(rr)
                            roleList.push({
                                name: item.roleName,
                                code: item.roleId
                            });
                        })
                    }
                    rt.children.push(rgroup)
                })
            }
            roleTree.push(rt)
        })

        this.setState({
            targets: data,
            dptTree: dptTree,
            roleTree: roleTree,
            roleList: roleList
        })
    }


    handleSelectUser = (event) => {
        let param = {
            company: true,
            department: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('一次只能选择一个用户');
                        return reject('callbackFunc Error')
                    } else {
                        const user = this.props.selectedUsers[0];
                        this.setState({
                            editingRow: {
                                ...this.state.editingRow,
                                targetId: user.userId,
                                targetName: user.lastName + user.firstName || ''
                            }
                        })
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    handleCreateTarget(data) {
        let newData = [data, ...this.state.targets]
        this.setState({
            targets: newData
        })
    }

    handleDelete(row) {
        let newTargets = []
        for (var i = 0; i < this.state.targets.length; i++) {
            if (row.rowId !== this.state.targets[i].rowId) {
                newTargets.push(this.state.targets[i])
            }
        }
        this.setState({
            targets: newTargets
        })
    }

    getCompanyByRoleId(roleId) {
        var cid;
        for (var i = 0; i < this.state.roleTree.length; i++) {
            var exist = false;
            for (var j = 0; j < this.state.roleTree[i].children.length; j++) {
                for (var k = 0; k < this.state.roleTree[i].children[j].children.length; k++) {
                    if (this.state.roleTree[i].children[j].children[k].value === roleId) {
                        exist = true;
                        break;
                    }
                }
                if (exist) {
                    break;
                }
            }
            if (exist) {
                cid = this.state.roleTree[i].value.replace("c", "");
                break;
            }
        }
        return cid ? parseInt(cid) : 0;
    }

    handleSaveTarget() {
        let rootCom = _.find(this.props.companies, function (j) { return j.pid == null; });
        console.log(rootCom)
        console.log(this.state.roleTree)
        console.log(this.props.departments)
        let data = [];
        _.forEach(this.state.targets, val => {
            if (val.targetLvl === 2) {
                // 部门
                var d = _.find(this.props.departments, function (d) { return d.dptId === val.targetId; });
                if (d.companyId !== rootCom.companyId) {
                    data.push({
                        noticeId: this.props.noticeConfig.noticeId,
                        targetLvl: val.targetLvl,
                        targetId: val.targetId,
                        companyId: d.companyId
                    })
                } else {
                    data.push({
                        noticeId: this.props.noticeConfig.noticeId,
                        targetLvl: val.targetLvl,
                        targetId: val.targetId
                    })
                }
            } else if (val.targetLvl === 4) {
                // 角色
                var cid = this.getCompanyByRoleId(val.targetId);
                if (cid !== 0 && cid !== rootCom.companyId) {
                    data.push({
                        noticeId: this.props.noticeConfig.noticeId,
                        targetLvl: val.targetLvl,
                        targetId: val.targetId,
                        companyId: cid
                    })
                } else {
                    data.push({
                        noticeId: this.props.noticeConfig.noticeId,
                        targetLvl: val.targetLvl,
                        targetId: val.targetId
                    })
                }
            } else {
                data.push({
                    noticeId: this.props.noticeConfig.noticeId,
                    targetLvl: val.targetLvl,
                    targetId: val.targetId
                })
            }

        })
        this.props.onHandleUpdateTargets(this.props.noticeConfig.noticeId, data).then(() => {
            this.props.onhandleBack()
        })

    }

    handleBack() {
        this.props.onhandleBack()
    }

    saveEditRow(row) {
        let newTargets = _.cloneDeep(this.state.targets)
        for (var i = 0; i < newTargets.length; i++) {
            if (row.rowId === newTargets[i].rowId) {
                newTargets[i].targetLvl = row.targetLvl
                newTargets[i].targetId = row.targetId
                newTargets[i].targetName = row.targetName
                newTargets[i].editable = false
                break;
            }
        }
        this.setState({
            editingRow: {},
            targets: newTargets
        })
    }

    cancelEditRow(row) {
        let newTargets = []
        for (var i = 0; i < this.state.targets.length; i++) {
            if (row.rowId !== this.state.targets[i].rowId) {
                newTargets.push(this.state.targets[i])
            }
        }
        this.setState({
            editingRow: {},
            targets: newTargets
        })
    }

    setEditingRow(data) {
        this.setState({
            editingRow: data
        })
    }

    render() {
        return (
            <NoticeConfigTarget
                currentConfig={this.props.noticeConfig}
                targets={this.state.targets}
                companies={this.props.companies}
                roleTree={this.state.roleTree}
                roleList={this.state.roleList}
                departmentTree={this.state.dptTree}
                departmentList={this.props.departments}
                onhandleCreateTarget={this.handleCreateTarget.bind(this)}
                editingRow={this.state.editingRow}
                setEditingRow={this.setEditingRow.bind(this)}
                onhandleDelete={this.handleDelete.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleSelectUser={this.handleSelectUser.bind(this)}
                onhandleSaveTarget={this.handleSaveTarget.bind(this)}
                saveEditRow={this.saveEditRow.bind(this)}
                cancelEditRow={this.cancelEditRow.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        noticeConfig: state.notice.noticeConfig,
        companies: state.constant.companies,
        departments: state.constant.departments,
        roleTree: state.role.roleTree,
        selectedUsers: state.userSelect.selectedUsers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onHandleUpdateTargets: (id, data) => {
            return dispatch(updateConfigTargets(id, data))
        },
        onhandleBack: () => {
            dispatch(push('/noticeManagement'))
        },
        onhandleInitTarget: () => {
            dispatch(initTarget())
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoticeConfigTargetContainer)