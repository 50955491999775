import React from 'react';
import withStyles from './styles';
import { Card } from '@material-ui/core';
import PropTypes from 'prop-types';
import AccessoryHeader from "../../../containers/AccessoryManagement/AccessoryInfo/AccessoryHeader";
import AccessoryList from "../../../containers/AccessoryManagement/AccessoryInfo/AccessoryList";

const AccessoryInfo = props => {

    const { classes } = props;

    return (
        <Card className={classes.root}>
            <AccessoryHeader />
            <div className={classes.content}>
                <AccessoryList />
            </div>
        </Card>
    );
};
AccessoryInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(AccessoryInfo);
