import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getVariableBudgetConfigs,
    updateVariableBudgetConfig,
    addVariableBudgetConfig,
    delVariableBudgetConfig,
    initVariableCofig
} from '@/redux/modules/amoeba/amoebaIndex';
import { getRole } from "@/utils/userUtils";
import * as _ from 'lodash';
import VariableConfigComponent from '@/components/AmoebaManagement/VariableConfig';
class VariableConfigContainer extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props)
        this.state = {
            companyId: this.props.currentUserInfo.companyId,
        }
    }

    async componentDidMount() {
        if (!this.getPid()) {
            const company = _.first(this.props.companies)
            if (company) {
                this.props.onInitPage(company.companyId)
                this.setState({ companyId: company.companyId })
            }
        } else {
            this.props.onInitPage(this.props.companyId)
        }
    }


    async handleUpdate(newData, oldData) {
        console.log(newData)
        if (newData.configId) {
            let config = {
                configId: newData.configId,
                budgetType: newData.budgetType
            }
            await this.props.onhandleUpdateConfig(config)
        } else {
            let config = {
                budgetType: newData.budgetType,
                subId: newData.subId,
                companyId: this.state.companyId,
            }
            await this.props.onhandleAddConfig(config)
        }
        this.props.onhandleGetConfigs(this.state.companyId);
    }

    handleAdd(newData) {

    }

    handleDelete(oldData) {

    }

    handleCompanyChange(event) {
        const companyId = event.target.value
        this.setState({ companyId: companyId }, () => {
            this.props.onhandleGetConfigs(companyId)
        })
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }


    getUserRole = () => {
        const roleRoute = this.props.roleRoutes.find(ele => ele.route === 'amoeba_plan_variable_config')
        if (roleRoute) {
            return roleRoute.action === 'W'
        }
        return false;
    }

    render() {
        const roleFlag = this.getUserRole()
        let selectCompanies = this.props.companies
        if (this.getPid()) {
            selectCompanies = _.filter(this.props.companies, { companyId: this.props.currentUserInfo.companyId })
        }
        return (
            <div>
                <VariableConfigComponent
                    variableBudgetConfigs={this.props.variableBudgetConfigs}
                    budgetVariableSubs={this.props.budgetVariableSubs}
                    currentUserInfo={this.props.currentUserInfo}
                    selectCompanies={selectCompanies}
                    companyId={this.state.companyId}
                    roleFlag={roleFlag}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.constant.companies,
        budgetVariableSubs: state.amoebaIndex.budgetVariableSubs,
        variableBudgetConfigs: state.amoebaIndex.variableBudgetConfigs,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (companyId, callback) => {
            return dispatch(initVariableCofig(companyId, callback))
        },
        onhandleGetConfigs: (companyId) => {
            return dispatch(getVariableBudgetConfigs(companyId))
        },
        onhandleUpdateConfig: (configDto) => {
            return dispatch(updateVariableBudgetConfig(configDto))
        },
        onhandleAddConfig: (configDto) => {
            return dispatch(addVariableBudgetConfig(configDto))
        },
        onhandleDeleteConfig: (configId) => {
            return dispatch(delVariableBudgetConfig(configId))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VariableConfigContainer))