import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@material-ui/core';

const AreaInfoEditPannel = ({
    classes,
    area,
    open,
    onhandleClose,
    onhandleConfirm,
    onhandleChangeArea,
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">{area.areaId ? '修改装置区域' : '新增装置区域'}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {/* <DialogContentText>{title}</DialogContentText> */}
                    <TextField className={classes.textfield}
                        label="区域名称"
                        value={area.areaName || ''}
                        name='areaName'
                        onChange={onhandleChangeArea}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onhandleClose}> 关闭</Button>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>确定</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
AreaInfoEditPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    area: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onhandleConfirm: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleChangeArea: PropTypes.func.isRequired,
};

AreaInfoEditPannel.defaultProps = {
    area: {},
    open: false,
}

export default (withStyles(AreaInfoEditPannel));