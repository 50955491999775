import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaintainDocPage from '../../../components/DeviceManagement/MaintainDoc/MaintainDocPage';
import {
    setDeviceDocPage,
    setDeviceDocPageError,
    initDeviceDocPage
} from "../../../redux/modules/devices";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class MaintainDocPageContainer extends Component {

    static propTypes = {
        deviceDocPageError: PropTypes.object,
        deviceDocPageChange: PropTypes.bool,
        deviceDocPage: PropTypes.object,
    };

    componentWillMount() {
        this.props.onhandleInit()
    }

    handleChange = (event, field) => {
        let newDevice;
        switch (field) {
            case 'docName':
                newDevice = {
                    ...this.props.deviceDocPage,
                    docName: event.target.value
                };
                this.props.onhandleUpdateEditdeviceDocPage(newDevice);
                break;
            case 'docDesc':
                newDevice = {
                    ...this.props.deviceDocPage,
                    docDesc: event.target.value
                };
                this.props.onhandleUpdateEditdeviceDocPage(newDevice);
                break;
            case 'docType':
                newDevice = {
                    ...this.props.deviceDocPage,
                    docType: event.target.value
                };
                this.props.onhandleUpdateEditdeviceDocPage(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'docName') {
            if (_.trim(this.props.deviceDocPage.docName) === '') {
                err['docName'] = '文档名称不能为空';
            } else {
                err['docName'] = '';
            }
        }

        if (!field || field === 'docType') {
            if (_.trim(this.props.deviceDocPage.docType) === '') {
                err['docType'] = '文档类型不能为空';
            } else {
                err['docType'] = '';
            }
        }

        let newError = {
            ...this.props.deviceDocPageError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    render() {
        return (
            <MaintainDocPage
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                deviceDocPage={this.props.deviceDocPage}
                deviceDocPageError={this.props.deviceDocPageError}
                doctype={this.props.doctype}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        deviceDocPage: state.devices.deviceDocPage,
        deviceDocPageError: state.devices.deviceDocPageError,
        doctype: state.devices.doctype,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateEditdeviceDocPage: (entity) => {
            dispatch(setDeviceDocPage(entity))
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceDocPageError(err))
        },
        onhandleInit: (err) => {
            dispatch(initDeviceDocPage())
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaintainDocPageContainer)
