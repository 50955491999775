import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Card,
    TextField,
    TextareaAutosize,
    Button,
} from '@material-ui/core';
import { Table } from 'antd';
import * as _ from 'lodash';
import { formatDate } from '@/utils/datetime';


const DeviceFormDetail = props => {
    const {
        classes,
        companies,
        form,
        // pageSize,
        // page,
        formTypeList,
        // targetTypeList,
        formStatusList,
        attrTypes,
        attrFields,
        // isLoading,
        isEditForm,
        isEditApprovalInstance,
        // onhandleDelete,
        // onhandleEdit,
        onhandleCheck,
        onhandleChange,
        onhandleNavigateBack,
        onhandleOpenProcessApproval,
        // onhandleSelectChange,
        onhandleSave,
        // onhandleSetPageSize,
        // onhandleSetPage,
        // getDeviceOpsRecordList,
        // onhandleCleanmodal,
        // onhandlPreviewDoc
    } = props;

    // const [selectedRow, setSelectedRow] = useState(null);
    // const [page, setPage] = useState(0);


    const renderCompany = (companyId) => {
        let c = _.find(companies, { 'companyId': companyId })
        if (c) {
            return c.companyAlias
        }
        return ''
    }

    const renderStatus = (status) => {
        let item = _.find(formStatusList, { 'code': status })
        if (item) {
            return item.name
        }
        return '-'
    }

    const renderFormType = (formType) => {
        let item = _.find(formTypeList, { 'code': formType })
        if (item) {
            return item.name
        }
        return '-'
    }

    const renderAttrName = (attrName) => {
        let field = attrFields[attrName]
        if (field) {
            return field['name']
        }
        return attrName
    }

    const renderDate = (date) => {
        if (date) {
            return formatDate(date)
        }
        return date
    }


    const assemblycolumns = () => {

        let columns = [
            {
                title: '类别',
                dataIndex: 'attrType',
                render: (text, row, index) => row ? <Typography>{attrTypes[row.attrType] ? attrTypes[row.attrType] : row.attrType}</Typography> : '',
                editable: 'never'
            },
            {
                title: '子类别',
                dataIndex: 'attrSubType',
                render: (text, row, index) => row ? <Typography>{attrTypes[row.attrSubType] ? attrTypes[row.attrSubType] : row.attrSubType}</Typography> : '',
                editable: 'never'
            },
            {
                title: '名称',
                dataIndex: 'attrName',
                render: (text, row, index) => row ? <Typography>{renderAttrName(row.attrName)}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '原值',
                dataIndex: 'attrOldName',
                render: (text, row, index) => row ? <Typography>{row.attrOldName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '新值',
                dataIndex: 'attrNewName',
                render: (text, row, index) => row ? <Typography>{row.attrNewName}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '备注',
            //     dataIndex: 'opentext',
            //     render: (text, row, index) => row ? <Typography>{row.opentext}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '',
            //     dataIndex: 'actions',
            //     width: '100px',
            //     render: (text, row, index) => renderActions(row),
            // }
        ]

        return columns
    }

    // const renderActions = (row) => {

    //     return <Grid container>
    //         <Grid item>
    //             <Tooltip title='详情'>
    //                 <IconButton variant='text' onClick={() => onhandleEdit(row.formId)}> <Edit color={'primary'} /></IconButton>
    //             </Tooltip>
    //         </Grid>
    //         <Grid item>
    //             <Tooltip title='删除'>
    //                 <IconButton variant='text' onClick={() => onhandleDelete(row.formId)}> <Delete color={'error'} /></IconButton>
    //             </Tooltip>
    //         </Grid>
    //     </Grid>
    // }

    // const onhandlePageSizeChange = (pageSize, current) => {
    //     onhandleSetPageSize(pageSize);
    //     onhandleSetPage(0);
    //     // 这里会触发pageChange事件
    // }

    // const onhandlePageChange = (current) => {
    //     const page = current - 1;
    //     onhandleSetPage(page);
    //     onhandleSearch({
    //         offset: page * (pageSize ? pageSize : 5)
    //     })
    // }

    const getRowClass = (record, index) => {
        return (index % 2 !== 0 ? classes.oddRow : null);
    }

    const getFormDtls = () => {
        if (form && form.formDtlList) {
            return form.formDtlList
        }
        return []
    }

    const disableSaveButton = () => {
        if (!form.formId) {
            return false
        }
        if (isEditForm || isEditApprovalInstance) {
            return false
        }
        return true
    }

    return (
        <Card alignitems="center" className={classes.card}>
            <Grid container>
                <Grid item>
                    <Typography variant="h3">
                        {'设备表单详情' + (form.formId ? '' : '-创建表单')}
                    </Typography>
                </Grid>
                <Grid item className={classes.flexgrow}>
                </Grid>
                <Grid>
                    {Boolean(form.formId) &&
                        <Button className={classes.button} variant="contained" color='primary'
                            onClick={onhandleOpenProcessApproval}>流程审批
                        </Button>
                    }
                </Grid>
                <Grid >
                    <Button className={classes.button} variant="contained" color='primary'
                        disabled={disableSaveButton()}
                        onClick={onhandleSave}>保存

                    </Button>
                </Grid>
                <Grid >
                    <Button className={classes.button} variant="outlined"
                        onClick={onhandleNavigateBack}>返回
                    </Button>
                </Grid>
            </Grid>

            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'表单信息'}
                    </Typography>
                </Grid>
                {/* <Grid container className={classes.basicInfoContainer}>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            单&emsp;&emsp;位
                        </Typography>
                        <Typography className={classes.content}>
                            {form.companyId ? renderCompany(form.companyId) : '-'}
                        </Typography>
                    </Grid>

                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            单据编号
                        </Typography>
                        <Typography className={classes.content}>
                            {form.formNo ? form.formNo : '-'}
                        </Typography>
                    </Grid>

                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            表单名称
                        </Typography>
                        <Typography className={classes.content}>
                            {form.formName ? form.formName : '-'}
                        </Typography>
                    </Grid>

                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            表单类型
                        </Typography>
                        <Typography className={classes.content}>
                            {form.formType ? form.formType : '-'}
                        </Typography>
                    </Grid>

                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            设备
                        </Typography>
                        <Typography className={classes.content}>
                            {form.deviceName ? form.deviceName : '-'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            表单状态
                        </Typography>
                        <Typography className={classes.content}>
                            {renderStatus(form.status)}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={12} md={8} lg={6}>
                        <Typography className={classes.label}>
                            表单描述
                        </Typography>
                        <Typography className={classes.content}>
                            {form.opentext ? form.opentext : '-'}
                        </Typography>
                    </Grid>
                </Grid> */}

                <Grid container item spacing={1} className={classes.gridContainer} sm={12} md={12}>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>单&emsp;&emsp;位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.serialNo !== ''}
                                // helperText={deviceDesignError.serialNo || ''}
                                className={classes.textField}
                                value={form.companyId ? renderCompany(form.companyId) : '-'}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            // onChange={event => onhandleChange(event, 'companyId')}
                            // onBlur={event => onhandleCheck(event, 'companyId')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>单据编号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.designer !== ''}
                                // helperText={deviceDesignError.designer || ''}
                                className={classes.textField}
                                value={form.formNo || '-'}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            // onChange={event => onhandleChange(event, 'formNo')}
                            // onBlur={event => onhandleCheck(event, 'formNo')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>表单类型</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={renderFormType(form.formType)}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            // onChange={event => onhandleChange(event, 'formType')}
                            // onBlur={event => onhandleCheck(event, 'formType')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>创建时间</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={renderDate(form.createTime) || ''}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>表单名称</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={form.formName || ''}
                                variant="outlined"
                                size='small'
                                disabled={false}
                                onChange={event => onhandleChange(event, 'formName')}
                            // onBlur={event => onhandleCheck(event, 'formName')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设备编号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={form.deviceNo || '-'}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            // onChange={event => onhandleChange(event, 'deviceName')}
                            // onBlur={event => onhandleCheck(event, 'deviceName')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设备名称</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={form.deviceName || '-'}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            // onChange={event => onhandleChange(event, 'deviceName')}
                            // onBlur={event => onhandleCheck(event, 'deviceName')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={3}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>表单状态</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                className={classes.textField}
                                value={renderStatus(form.status)}
                                variant="outlined"
                                size='small'
                                disabled={true}
                            />
                        </Grid>
                    </Grid>


                    <Grid item container className={classes.gridItem} sm={12} md={12}>
                        <Typography className={classes.labelarea}>
                            <span className={classes.justify}>表单说明</span>
                        </Typography>
                        <Grid item container className={classes.textAreaContainer} >
                            <TextareaAutosize
                                // error={deviceDesignError.manufacturer !== ''}
                                // helperText={deviceDesignError.manufacturer || ''}
                                multiline={'true'}
                                rowsMin={3}
                                className={classes.textArea}
                                value={form.opentext || ''}
                                variant="outlined"
                                size='small'
                                onChange={event => onhandleChange(event, 'opentext')}
                                onBlur={event => onhandleCheck(event, 'opentext')}
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
            <div className={classes.content}>
                <Grid item md={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'表单明细'}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Table
                        className={classes.normalTable}
                        rowClassName={getRowClass}
                        rowKey={'dtlId'}
                        columns={assemblycolumns()}
                        dataSource={getFormDtls()}
                        // expandable={{ expandedRowRender }}
                        size={'small'}
                    // scroll={{ y: 'calc(100vh - 260px)' }}
                    // pagination={{
                    //     showSizeChanger: true,
                    //     showQuickJumper: true,
                    //     // showTotal: () => `共${initListData.total}条`,
                    //     pageSizeOptions: ['10', '20', '50'],
                    //     pageSize: pageSize,
                    //     current: page + 1,
                    //     total: getFormDtls().length,
                    //     onShowSizeChange: (current, pageSize) => onhandlePageSizeChange(pageSize, current),
                    //     onChange: current => onhandlePageChange(current)
                    // }}
                    />
                </Grid>
            </div>
        </Card>
    )
};

DeviceFormDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,

};
DeviceFormDetail.defaultProps = {
    form: {},
};

export default withStyles(DeviceFormDetail);
