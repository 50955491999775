import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductionReportComponent from '@/components/ManufactureManagement/ProdReport';
import PropTypes from 'prop-types';

class ProdReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    render() {
        return (
            <ProductionReportComponent
                tabValue={this.state.tabValue}
                onhandleChangeTab={this.handleChangeTab.bind(this)}

            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProdReportContainer);
