import React, { Component } from 'react';
import "./index.css";
import "../../../resource/rc-select.css"
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';

export class FillDeviceInfo extends Component {
    constructor(props) {
        super(props)
        this._createDevice = this._createDevice.bind(this);
        this._handelChange = this._handelChange.bind(this);
        this.state = {
            inpValue: '',
            tagNo: '',
            selectValue: [],
            disableValue: '',
            deviceCat: '',
            deviceCatName: '',
            deviceType: '',
            selectUseStatus: '',
            selectMedium: []
        }
    }

    componentDidMount = () => {
        this.setData();
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.parameters !== this.props.parameters) {
            this.setData();
        }

    }

    setData = () => {
        let newObj = {
            inpValue: '',
            tagNo: '',
            selectValue: [],
            disableValue: '',
            deviceCat: '',
            deviceCatName: '',
            deviceType: '',
            selectUseStatus: '',
            selectMedium: [],
        }
        if (this.props.parameters.deviceId) {
            this.props.parameters.typeName = this.props.parameters.deviceName;
            console.log(this.props.parameters.tagNo);
            let cat = this.props.parameters.cat
            let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
            let catName = _.find(this.props.deviceCategoryList, function (o) { return o.code === cat; }).name;
            newObj.inpValue = this.state.inpValue;
            newObj.tagNo = this.props.parameters.tagNo ? this.props.parameters.tagNo : '';
            newObj.selectValue = [this.props.selectedId];
            newObj.disableValue = this.props.selectedId;
            newObj.deviceCat = cat;
            newObj.deviceCatName = catName;
            newObj.deviceType = type;
            newObj.selectUseStatus = this.props.parameters.useStatus;

            if (this.props.parameters.medium) {
                let mediumArray = _.split(this.props.parameters.medium, ',');
                if (mediumArray && mediumArray.length > 0) {
                    let mediumValues = [];
                    mediumArray.forEach(medium => {
                        mediumValues.push({ value: medium })
                    })
                    newObj.selectMedium = mediumValues
                } else {
                    newObj.selectMedium = []
                }
            }
        } else {
            if (this.props.parameters.type === 'Unit' || this.props.parameters.type === 'Area') {
                newObj.selectValue = [this.props.selectedId];
                newObj.disableValue = this.props.selectedId;
                newObj.deviceCat = this.props.parameters.type;
                newObj.deviceCatName = this.props.parameters.typeName;
                newObj.deviceType = this.props.parameters.type;
            } else {
                let cat = this.props.parameters.type
                let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
                let catName = _.find(this.props.deviceCategoryList, function (o) { return o.code === cat; }).name;
                newObj.selectValue = [this.props.selectedId];
                newObj.disableValue = this.props.selectedId;
                newObj.deviceCat = this.props.parameters.type;
                newObj.deviceCatName = catName;
                newObj.deviceType = type;
                if (this.props.parameters.medium) {
                    let mediumArray = _.split(this.props.parameters.medium, ',');
                    if (mediumArray && mediumArray.length > 0) {
                        let mediumValues = [];
                        mediumArray.forEach(medium => {
                            mediumValues.push({ value: medium })
                        })
                        newObj.selectMedium = mediumValues
                    } else {
                        newObj.selectMedium = []
                    }
                }
            }
        }

        this.setState({
            inpValue: newObj.inpValue,
            tagNo: newObj.tagNo,
            selectValue: newObj.selectValue,
            disableValue: newObj.disableValue,
            deviceCat: newObj.deviceCat,
            deviceCatName: newObj.deviceCatName,
            deviceType: newObj.deviceType,
            selectUseStatus: newObj.selectUseStatus,
            selectMedium: newObj.selectMedium
        });

        this.setState({ loading: false })
    }

    _handelChange(e) {
        this.setState({
            inpValue: e.target.value,
        })
    }

    _handelChangeTagNo(e) {
        this.setState({
            tagNo: e.target.value,
        })
    }

    _createDevice() {
        const { createDeviceMap, createUnitMap, createAreaMap, cleanDialog, addExistDeviceToMap } = this.props;
        if (this.props.parameters.deviceId) {
            let device = {};
            device = this.props.parameters;
            if (this.state.selectMedium && this.state.selectMedium.length > 0) {
                let arr = _.map(this.state.selectMedium, 'value');
                device.medium = arr.join(',');
            }
            if (this.state.tagNo && this.state.tagNo !== '') {
                device.tagNo = this.state.tagNo;
            }
            addExistDeviceToMap(device);
            cleanDialog();
        } else {
            if (this.props.parameters.type === 'Unit') {
                let unit = {}
                unit.unitName = this.state.inpValue;
                unit.type = this.props.parameters.type;
                createUnitMap(unit);
                cleanDialog();
            } else if (this.props.parameters.type === 'Area') {
                let area = {}
                area.areaName = this.state.inpValue;
                area.type = this.props.parameters.type;
                createAreaMap(area);
                cleanDialog();
            } else {
                let device = {};
                device.deviceName = this.state.inpValue;
                device.tagNo = this.state.tagNo;
                device.cat = this.state.deviceCat;
                device.useStatus = this.state.selectUseStatus.value;
                device.type = this.state.deviceType;
                if (this.state.selectMedium && this.state.selectMedium.length > 0) {
                    let arr = _.map(this.state.selectMedium, 'value');
                    device.medium = arr.join(',');
                }
                createDeviceMap(device);
                cleanDialog();
            }
        }
    }

    onSelectStatusChange = (value) => {
        this.setState({
            selectUseStatus: value
        });
    }

    onSelectMediumChange = (value) => {
        this.setState({
            selectMedium: value
        });
    }

    _disableCheck = () => {
        if (this.props.parameters.deviceId) {
            if (!(this.state.deviceCat.indexOf("D06") !== -1 && this.state.selectMedium.length === 0)) {
                return false;
            }
        } else {
            if (this.props.parameters.type === 'Unit' || this.props.parameters.type === 'Area') {
                if (!(this.state.inpValue === '')) {
                    return false;
                }
            } else {
                if (!(this.state.inpValue === '' || this.state.selectDeviceType === '' || this.state.selectUseStatus === '' || this.state.selectMedium.length === 0)) {
                    return false;
                }
            }
        }
        return true;
    }

    render() {
        return (
            <div className='fillarea'>
                {this.props.parameters.deviceId ?
                    <React.Fragment>
                        <div className='title'>设备编号:</div>
                        <input className='inputarea' type="text" disabled placeholder={this.props.parameters.deviceNo} />
                        <div className='title'>设备名称:</div>
                        <input className='inputarea' type="text" disabled placeholder={this.props.parameters.deviceName} />
                    </React.Fragment>
                    : <React.Fragment>
                        <div className='title'>请输入{this.props.parameters.typeName}名称:</div>
                        <input className='inputarea' type="text" onChange={this._handelChange.bind(this)} placeholder={this.props.parameters.typeName} />
                    </React.Fragment>
                }

                {(this.props.parameters.type === 'Unit' || this.props.parameters.type === 'Area') ? null :
                    <React.Fragment>
                        <div className='title'>请输入位号:</div>
                        <input className='inputarea' type="text" onChange={this._handelChangeTagNo.bind(this)} placeholder={'位号'} value={this.state.tagNo} />
                        <div className='title'>类型:</div>
                        <input className='inputarea' type="text" disabled placeholder={this.state.deviceCatName} />
                        <div className='title'>请选择设备使用状态:</div>
                        <TreeSelect
                            disabled={Boolean(this.props.parameters.deviceId)}
                            style={{ width: '100%', margin: '5px 0px' }}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                            allowClear
                            treeLine
                            value={this.state.selectUseStatus}
                            labelInValue
                            treeData={this.props.useStatusTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => this.onSelectStatusChange(value)}
                        />
                        <div className='title'>请选择设备介质:</div>
                        <TreeSelect
                            showArrow
                            style={{ width: '100%', margin: '5px 0px' }}
                            choiceTransitionName="rc-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                            allowClear
                            treeLine
                            value={this.state.selectMedium}
                            labelInValue
                            treeData={this.props.mediumTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            multiple={true}
                            onChange={(value) => this.onSelectMediumChange(value)}
                        />
                    </React.Fragment>
                }
                <button className='confirm-btn' onClick={this._createDevice.bind(this)} disabled={this._disableCheck()}>确定</button>
            </div>
        )
    }
}
