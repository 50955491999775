import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {},
    item: {
        padding: 0
    },
    card: {
        width: '100%',
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingBottom: theme.spacing(2),
    },
    gridItem: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2)
    },
    label: {
        paddingTop: theme.spacing(0.8),
        whiteSpace: 'nowrap',
        width: 100,
        textAlign: 'right'
    },
    formContainer: {
        flexWrap: 'nowrap',
        // alignItems: 'center',
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 100px)'
    },
    required: {
        display: 'inline-block',
        color: theme.palette.error.main,
        marginRight: 2,
    },
    justify: {
        display: 'inline-block',
        textAlign: 'justify',
        textAlignLast: 'justify',
        width: 72,
    },
    checkboxContainer: {
        width: '100%',
        display: 'inline-flex'
    },
    textField: {
        width: '100%'
    },
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    button: {
        marginRight: theme.spacing(2)
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        borderLeft: '2px solid ' + theme.palette.primary.main,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
});

export default withStyles(styles);