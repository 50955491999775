import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  connectLabel: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    marginLeft: theme.spacing(1),
  },
  title: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridContainerForPopup: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridSubItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  switch: {
    marginBottom: 0,
    marginLeft: 10
  },
  toolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  textFieldGroupItem: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  processline: {
    flexGrow: 1
  },
  processUser: {
    paddingLeft: theme.spacing(2),
    display: 'contents'
  },
  alert: {
    margin: theme.spacing(1),
    height: '100%',
  },
  permitBar: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
  },
  new: {
    width: 60,
    backgroundColor: colors.grey[400],
    color: theme.palette.white,
  },
  submitted: {
    width: 60,
    backgroundColor: colors.yellow[400],
    color: theme.palette.black,
  },
  approved: {
    width: 60,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  finalized: {
    width: 60,
    backgroundColor: colors.yellow[500],
    color: theme.palette.black,
  },
  published: {
    width: 60,
    backgroundColor: colors.teal[500],
    color: theme.palette.white,
  },
  done: {
    width: 60,
    backgroundColor: colors.blue[400],
    color: theme.palette.white,
  },
  rejected: {
    width: 60,
    backgroundColor: colors.orange[400],
    color: theme.palette.white,
  },
  default: {
    width: 60,
    backgroundColor: colors.grey[300],
    color: theme.palette.white,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  confirmTrue: {
    color: theme.palette.success.main
  },
  confirmFalse: {
    color: theme.palette.error.main
  },
});

export default withStyles(styles);
