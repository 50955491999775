import Axios from 'axios';
import Promise from 'bluebird';
import { createStore, compose, applyMiddleware } from 'redux';
import localforage from 'localforage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import set from 'lodash/set';
import thunk from 'redux-thunk-fsa';
import { v4 as uuidv4 } from 'uuid';
import promiseMiddleware from '../utils/redux-promise';
import storeIndex from './modules';
import { setLogoutDialog, setToken, setloginSelectedTenant } from './modules/auth';
import { createloading, cleanloading } from './modules/loading';
import { openConfirmDialog } from "./modules/confirmDialog";
/**
 * 持久化对象设定开始
*/
// 把drivers设置成websql & localstorage避免indexedDB溢出
localforage.config({
  driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE]
});
// 设定持久化对象外内容
const blacklist = [
  'message',
  'modal',
  'confirmDialog',
  'contentmenu',
  'deviceTree',
  'installationPlug',
  'dialog',
  'installationRelation',
  'loading'
];

const reduxPersistConfig = {
  key: 'xcloud/root/400',
  version: 1,
  timeout: 35000,
  storage: localforage,
  // transforms,
  blacklist
};

/**
 * 持久化对象设定结束
*/
export const history = createHistory({
  getUserConfirmation(message, callback) {
    // console.log(history)
    store.dispatch(openConfirmDialog('注意',
      message,
      callback,
      null,
      true
    ));
  }
});
const appReducer = storeIndex(history);
const rootReducer = (state, action) => {
  if (action.type === '@@xcloud/auth/signout') {
    localforage.removeItem('persist:xcloud/root/400');
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const reducer = persistReducer(reduxPersistConfig, rootReducer);

const httpClient = Axios.create({
  headers: { Pragma: 'no-cache' },
  baseURL: process.env.REACT_APP_API_URL
});

const middleware = [
  thunk.withOpts({
    interrupt: true,
    next: true,
    extraArgument: httpClient
  }),
  promiseMiddleware,
  routerMiddleware(history)
];

const enhancers = [
];

if (
  process.env.NODE_ENV === 'development'
  || process.env.NODE_ENV === 'storybook'
) {
  const { __REDUX_DEVTOOLS_EXTENSION__, devToolsExtension } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  } else if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

var store;

export default function configureStore(initialState = {}, persist = true) {
  let done;
  const isReady = Promise.fromCallback(next => {
    done = next;
  });
  store = createStore(reducer, initialState, composedEnhancers);
  const persistor = persist ? persistStore(store, null, done) : null;

  /**
   * Initiates success request interceptor
   * https://github.com/axios/axios#response-schema
   */
  const pluckJWT = response => {
    if (response.headers['x-refresh-token']) {
      // update token
      store.dispatch(setToken(response.headers['x-refresh-token']));
    }
    if (store.getState().loading.type === 'http') {
      store.dispatch(cleanloading());
    }
    return response;
  };

  /**
       * Global HTTP 403 handler
       * Resets JWT token & passes error through
       */
  const handleErrors = error => {
    if (store.getState().loading.type === 'http') {
      store.dispatch(cleanloading());
    }
    if (error.response.status === 401) {
      // Bearer token is invalid -> reset
      store.dispatch(setToken(''));
      store.dispatch(setloginSelectedTenant({}));
      store.dispatch(setLogoutDialog('会话过期，请重新登录'));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  };

  /* generic interceptor for response */
  httpClient.interceptors.response.use(pluckJWT, handleErrors);

  httpClient.interceptors.request.use(config => {
    set(config, 'headers.x-trace-id', uuidv4());
    return config;
  });

  httpClient.interceptors.request.use(config => {
    set(config, 'headers.Accept-Language', 'zh-cn,zh;q=0.5');
    return config;
  });

  httpClient.interceptors.request.use(config => {
    if (config.headers['need_loading']) {
      store.dispatch(createloading('http'));
      delete config.headers['need_loading']
    }
    return config;
  });

  return {
    isReady,
    store,
    persistor,
    httpClient
  };
}

export function getStore() {
  return store;
}
