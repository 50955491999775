import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@material-ui/core';

const UnitInfoEditPannel = ({
    classes,
    unit,
    open,
    onhandleClose,
    onhandleConfirm,
    onhandleChangeUnit,
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">{unit.unitId ? '修改装置单元' : '新增装置单元'}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {/* <DialogContentText>{title}</DialogContentText> */}
                    <TextField className={classes.textfield}
                        label="单元名称"
                        value={unit.unitName || ''}
                        name='unitName'
                        onChange={onhandleChangeUnit}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onhandleClose}> 关闭</Button>
                    <Button variant="contained" color="primary" onClick={onhandleConfirm}>确定</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
UnitInfoEditPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onhandleConfirm: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleChangeUnit: PropTypes.func.isRequired,
};

UnitInfoEditPannel.defaultProps = {
    config: {},
}

export default (withStyles(UnitInfoEditPannel));