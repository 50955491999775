import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Tabs,
    Tab,
    Grid,
    List,
    TextField,
    MenuItem,
    Button,
    Typography,
    ListItemText,
    Chip,
    Checkbox,
    FormControl,
    FormHelperText,
    Popper
} from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { getDateByTimestamp } from "@/utils/datetime";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { isUnitBranch } from "@/utils/processUtils";
import ProcessLine from '../../../ProcessLine';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { inventoryValid } from '@/utils/inspection';
import { getMoment as moment } from '@/utils/datetime';
// import { getDepartmentsByRole } from "@/utils/userUtils";

import _ from 'lodash';

const InspectionPlan = ({
    classes,
    currentUserInfo,
    users,
    userProfile,
    plan,
    planDetails,
    constant,
    processes,
    currentProcess,
    inventoryList,
    currentRecord,
    processHistories,
    processSteps,
    onhandleAddPlan,
    onhandleUpdate,
    onhandleDelete,
    onhandleReject,
    onhandleActivate,
    onhandleProcess,
    onhandleOpenRegulation,
    onhandleOpenPlanDetailAddPannel,
    currentProcessStatus,
    onhandleListMapping,
    listTypeList,
    inspectionDptMapping,
    categoryList,
    departmentList,
    departmentsByRole,
    tabValue,
    onhandleChangeTab
}) => {

    // console.log("inspectionDptMapping tabValue")
    // console.log(inspectionDptMapping)
    // console.log(tabValue)
    const needCategory = !!_.find(categoryList, { 'pcode': tabValue });

    const planEditableStatus =
        [
            // 'active',
            // 'inactive',
            // 'new',
            // 'submitted',
            'rejected',
            // 'finalized',
            // 'signed',
            // 'passed',
            // 'reviewing',
            'drafting'
        ]

    const tableRef = React.createRef();

    const checkStartPlanPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_plan_setup' })
        return permission && permission.action === 'W'
    }

    const checkEditable = () => {
        let record = currentRecord
        let process = currentProcess
        if (!record) return false
        if (!process.actions || process.actions.length === 0) return false
        // 计划不需要做公司区分
        // if (record.companyId !== currentUserInfo.companyId) 
        //     return false
        if (record.statusCode !== 'Drafting' && record.statusCode !== 'Rejected')
            return false
        // 04.21 只要用户符合部门mapping均可编辑
        // if (!_.find(process.roles, function (o) { return o.roleId === currentUserInfo.roleId }))
        //     return false
        // if (currentRecord.currentStage === currentRecord.mergeStage)
        //     return false
        // if (buttonDisabled(process.actions[0]))
        //     return false
        return true
    }

    const displayAddpanel = (currentRecord, currentProcess) => {
        let record = currentRecord
        let process = currentProcess
        if (!record) return false
        if (!process.actions || process.actions.length === 0)
            return false
        if (record.statusCode !== 'Drafting' && record.statusCode !== 'Rejected')
            return false
        // 04.21 只要符合部门mapping均可编辑
        // if (!_.find(process.roles, function (o) { return o.roleId === currentUserInfo.roleId }))
        //     return false
        // if (currentRecord.currentStage === currentRecord.mergeStage)
        //     return false
        // if (buttonDisabled(process.actions[0]))
        //     return false
        return true
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        showTitle: false,
        sorting: false
    }

    const checkPlanDetailDates = (rowData) => {
        if (!rowData.startTime || rowData.startTime === '' || !rowData.inspectTime || rowData.inspectTime === '') {
            return { isValid: false, helperText: '检查日期不能为空' };
        } else if (
            moment()(rowData.inspectTime).isBefore(moment()(rowData.startTime))
        ) {
            return { isValid: false, helperText: '截止日期不能早于开始日期' };
        }
        return true;
    }

    const assembleSubCatSelectOptions = (rowData) => {
        // 过滤出当前用户可访问的子分类完整信息列表
        let filteredCategoryList = [];
        categoryList.filter(item => (item.pcode === rowData.inspectType.substr(0, 5))).forEach((cat) => {
            inspectionDptMapping.forEach((accessbleCat) => {
                if (accessbleCat.inspectionCode === cat.code) {
                    filteredCategoryList.push(cat);
                }
            })
        });

        // 当前用户可访问的子分类选项列表
        let menuItemList = []
        filteredCategoryList.map((cat) => (
            menuItemList.push(
                <MenuItem dense key={cat.code} value={cat.code}>
                    {cat.name}
                </MenuItem>
            )
        ))

        // 返回当前用户可访问的子分类选项列表
        return menuItemList
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '检查日期',
                field: 'startTime',
                validate: rowData => checkPlanDetailDates(rowData),
                render: (row) => row && <Typography>{
                    moment()(row.startTime).isSame(row.inspectTime, 'day') ?
                        getDateByTimestamp(row.startTime)
                        :
                        (getDateByTimestamp(row.startTime) + ' 至 ' + getDateByTimestamp(row.inspectTime))
                }</Typography>,
                type: 'date',
                align: 'left',
                editComponent: (props) => (
                    <FormControl className={classes.textField} error={Boolean(props.helperText)}>
                        <Grid container spacing={0} alignItems="flex-end">
                            <Grid item xs={1} mr={1}>
                                <Typography className={classes.dateLabel} >从</Typography>
                            </Grid>
                            <Grid item xs>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        // label={'开始日期'}
                                        className={classes.textField}
                                        disableToolbar
                                        autoOk
                                        size={'small'}
                                        error={Boolean(props.helperText)}
                                        // helperText={props.helperText || ''}
                                        InputProps={{ readOnly: true }}
                                        variant={'inline'}
                                        maxDate={plan ? moment()(plan.year + '0101').endOf('year') : moment()().add(1, 'year').endOf('year')}
                                        minDate={plan ? moment()(plan.year + '0101').startOf('year') : moment()().add(1, 'year').startOf('year')}
                                        maxDateMessage={<Typography>日期不能超过计划年度</Typography>}
                                        minDateMessage={<Typography>不能早于当前日期</Typography>}
                                        format="yyyy/MM/dd"
                                        margin="none"
                                        name='startTime'
                                        value={props.rowData.startTime}
                                        onChange={(date) => {
                                            var data = { ...props.rowData };
                                            data.startTime = moment()(date).startOf('day').valueOf();
                                            props.onRowDataChange(data);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="flex-end">
                            <Grid item xs={1} mr={1}>
                                <Typography className={classes.dateLabel} >至</Typography>
                            </Grid>
                            <Grid item xs>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        // label={'截止日期'}
                                        className={classes.textField}
                                        disableToolbar
                                        autoOk
                                        error={Boolean(props.helperText)}
                                        // helperText={props.helperText || ''}
                                        InputProps={{ readOnly: true }}
                                        variant={'inline'}
                                        maxDate={plan ? moment()(plan.year + '0101').endOf('year') : moment()().add(1, 'year').endOf('year')}
                                        minDate={props.rowData.startTime ? moment()(props.rowData.startTime).endOf('day') : moment()().add(1, 'year').startOf('year')}
                                        // minDate={plan ? moment()(plan.year + '0101').startOf('year') : moment()().add(1, 'year').startOf('year')}
                                        maxDateMessage={<Typography>日期不能超过计划年度</Typography>}
                                        minDateMessage={<Typography>不能早于开始日期</Typography>}
                                        format="yyyy/MM/dd"
                                        margin="none"
                                        name='inspectTime'
                                        value={props.rowData.inspectTime}
                                        onChange={date => {
                                            var data = { ...props.rowData };
                                            data.inspectTime = moment()(date).endOf('day').valueOf();
                                            props.onRowDataChange(data);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <FormHelperText className={classes.dateHelperText} >{props.helperText || '* 若开始日期与截止日期设置为同一天，则检查时间限定为当天'}</FormHelperText>
                    </FormControl>
                )
            },
            {
                title: '受检单位',
                field: 'companyId',
                render: row => row && <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography>,
                align: 'left',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        select
                        value={props.rowData.companyId ? props.rowData.companyId : ''}
                        name='companyId'
                        onChange={
                            e => {
                                // modify the temp-'rowData' in selected 'dptId' field
                                var data = { ...props.rowData };
                                data.companyId = e.target.value;
                                props.onRowDataChange(data);
                            }
                        }
                    >
                        {constant.companies.map((comp) => (
                            <MenuItem dense key={comp.companyId} value={comp.companyId}>
                                {comp.companyAlias}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            },
            {
                title: '子分类',
                field: 'inspectType',
                hidden: !needCategory,
                width: '10%',
                validate: rowData => (needCategory && rowData.inspectType === '') ? { isValid: false, helperText: '子分类不能为空' } : true,
                render: row => row && <Typography>{onhandleListMapping(row.inspectType, 'inspectType')}</Typography>,
                align: 'left',
                editComponent: (props) => (
                    <TextField
                        select
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        value={props.rowData.inspectType ? props.rowData.inspectType : ''}
                        name='inspectTypeCat'
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.inspectType = e.target.value;
                            data.inventoryId = '';
                            props.onRowDataChange(data);
                        }}
                    >
                        {
                            assembleSubCatSelectOptions(props.rowData)
                        }
                    </TextField>
                )
            },
            {
                title: '检查表',
                field: 'inventoryId',
                render: row => row && <Typography>{row.inventoryName}</Typography>,
                validate: rowData => (!rowData.inventoryId || rowData.inventoryId === '') ? { isValid: false, helperText: '检查表不能为空' } : true,
                align: 'left',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        select
                        value={props.rowData.inventoryId ? props.rowData.inventoryId : ''}
                        name='inventoryId'
                        onChange={
                            e => {
                                // modify the temp-'rowData' in selected 'dptId' field
                                var data = { ...props.rowData };
                                data.inventoryId = e.target.value;
                                props.onRowDataChange(data);
                            }
                        }
                    >
                        {inventoryList
                            .filter((ivt) => { return !ivt.companyId || ivt.companyId === userProfile.roles[0].companyId; }).filter((ivt) => {
                                const data = { ...props.rowData }
                                if (data.inspectType) {
                                    let codeMatch = ivt.listType === data.inspectType
                                    if (ivt.catCode) {
                                        codeMatch = ivt.catCode === data.inspectType
                                    }
                                    return codeMatch
                                }
                                return true
                            })
                            .map((ivt) => (
                                <MenuItem dense key={ivt.inventoryId} value={ivt.inventoryId} disabled={!inventoryValid(ivt)}>
                                    {ivt.inventoryName + (!inventoryValid(ivt) ? '(未生效)' : '')}
                                </MenuItem>
                            ))}
                    </TextField>
                )
            },
            {
                title: '检查部门',
                field: 'dptId',
                width: '20%',
                render: row => row && <Typography>{onhandleListMapping(row.inspectionDptList, 'departmentShow')}</Typography>,
                validate: rowData => (!rowData.inspectionDptList || !rowData.inspectionDptList.length || rowData.inspectionDptList.length === 0) ? { isValid: false, helperText: '检查部门不能为空' } : true,
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        select
                        native={'true'}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        SelectProps={{
                            multiple: true,
                            MenuProps: {
                                anchorOrigin: { vertical: "center", horizontal: "center" },
                                transformOrigin: { vertical: "center", horizontal: "center" },
                                getContentAnchorEl: null
                            },
                            renderValue:
                                (selected) => (
                                    <div className={classes.chips}>
                                        {
                                            selected.map(value => (
                                                <Chip size={'small'} key={value} label={onhandleListMapping(value, 'departmentEdit')} className={classes.chip} />
                                            ))
                                        }
                                    </div>
                                ),
                            onChange: event => {
                                const { value } = event.target;
                                const newInspectionDptList = [];
                                value.forEach(selectedId => {
                                    newInspectionDptList.push({ dptId: selectedId, dtlId: props.rowData.dtlId })
                                });
                                props.rowData.inspectionDptList = newInspectionDptList;
                                tableRef.current.forceUpdate();
                            }
                        }}
                        value={props.rowData.inspectionDptList ? props.rowData.inspectionDptList.map(item => (item.dptId)) : []}
                        name='dptId'
                        onChange={
                            e => {
                                if (e) {
                                    props.onChange(e.target.value)
                                } else {
                                    props.onChange(undefined)
                                }
                            }
                        }
                    >
                        {/* 全选组件调试中 */}
                        {/* <MenuItem dense value={'全选'} onClick={this.selectAll}>
                            <Checkbox className={classes.checkbox} size={'small'} checked={props.rowData.inspectionDptList.length === departmentList.length} />
                            <ListItemText primary={'全选'} />
                        </MenuItem> */}
                        {
                            departmentList.map(
                                dpt => (
                                    <MenuItem dense key={dpt.dptId} value={dpt.dptId}>
                                        <Checkbox className={classes.checkbox} size={'small'} checked={_.findIndex(props.rowData.inspectionDptList, { 'dptId': dpt.dptId }) > -1} />
                                        <ListItemText primary={dpt.companyDptName} />
                                    </MenuItem>)
                            )
                        }
                    </TextField>
                ),
                align: 'left',
            },
            {
                title: '负责人',
                field: 'inspectHead',
                align: 'left',
                validate: rowData => (!rowData.inspectHead) ? { isValid: false, helperText: '负责人不能为空' } : true,
                render: row => row && <Typography>{onhandleListMapping(row.inspectHead, 'user')}</Typography>,
                editComponent: (props) => (
                    <Autocomplete
                        options={users}
                        onChange={(e, newValue, reason) => {
                            props.rowData.inspectHead = newValue ? newValue.userId : null;
                            tableRef.current.forceUpdate()
                        }}
                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                        getOptionSelected={(option, value) => {
                            return option.userId === value.userId
                        }}
                        noOptionsText='无可选人员'
                        value={_.find(users, { 'userId': props.rowData.inspectHead }) || null}
                        renderOption={option => (
                            <Grid container justify={'space-between'}>
                                <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                <Typography variant={'subtitle2'} style={{ marginLeft: 20 }}>{option.roleName || ''}</Typography>
                            </Grid>
                        )}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={Boolean(props.helperText)}
                                helperText={props.helperText || ''}
                                placeholder="搜索并选择负责人"
                                variant="standard" />
                        }
                        PopperComponent={(props) => <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />}
                    />
                )
            },
            {
                width: '10%',
                title: '状态',
                field: 'status', // currentProcessStatus.statusCode
                render: row => row && <Typography>{onhandleListMapping(row.status, 'status')}</Typography>,
                editable: 'never',
                align: 'left'
            }
        ]

        return columns
    }

    const assemblyData = (tabValue) => {

        if (!planDetails || planDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(planDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true

        // return _.filter(assemblyData, {'inspectType': tabValue}).reverse();
        return _.filter(
            assemblyData,
            (item) => { return item.inspectType.includes(tabValue); }
        ).reverse();
    }

    const buttonDisabled = (action, actions) => {
        // console.log(action,
        //     departmentsByRole)
        //check sub records completed
        // let allRcords = getRecords(processes)
        // let record = _.find(allRcords, (o) => { return o.companyId === currentUserInfo.companyId })

        if (!action) return true
        let record = currentRecord

        //check history action
        let histories = _.filter(processHistories, (o) => {
            return o.recordOrder === record.recordOrder
                && o.recordId === record.recordId
                && o.roleId === currentUserInfo.roleId
        })

        if (!record.pid && isUnitBranch(processes))
            return true

        if (record.currentStage === record.mergeStage) {
            return true
        }

        if (_.find(histories, (h) => { return action.actionId === h.actionId })) {
            return true
        }

        // if (planDetails && planDetails.length > 0) {
        //     if (_.find(planDetails, (h) => { return h.status === 'Rejected' })) {
        //         return true
        //     }
        // }
        // has Rejected action history
        const rejectedHistory = _.findIndex(actions, (o) => {
            return buttonHistory(o) && o.statusCode === 'Rejected'
        }) > -1
        // has Submitted action history
        const submittedHistory = _.findIndex(actions, (o) => {
            return buttonHistory(o) && o.statusCode === 'Submitted'
        }) > -1
        if (rejectedHistory && action.statusCode === 'Submitted') {
            return true;
        }
        if (submittedHistory && action.statusCode === 'Rejected') {
            return true;
        }
        return false
    }

    const buttonHistory = (action) => {

        // let allRcords = getRecords(processes)
        // let record = _.find(allRcords, (o) => { return o.companyId === currentUserInfo.companyId })

        let record = currentRecord

        //check history action
        let histories = _.filter(processHistories, (o) => {
            return o.recordOrder === record.recordOrder
                && o.recordId === record.recordId
                && o.roleId === currentUserInfo.roleId
        })

        if (_.find(histories, (h) => { return action.actionId === h.actionId }))
            return true

        return false

    }

    const renderActions = () => {

        // console.log('render process actions')
        // console.log(currentProcess)

        let process = currentProcess

        if (!process)
            return ''

        if (!process.actions)
            return ''
        if (process.actions.length === 0)
            return ''

        //check role
        if (!_.find(process.roles, function (o) { return o.roleId === currentUserInfo.roleId }))
            return ''

        if (currentRecord.currentStage === currentRecord.mergeStage)
            return ''

        return process.actions.map((action, idx) => (
            < Button key={idx}
                disabled={buttonDisabled(action, process.actions)}
                variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                color="primary"
                className={classes.button}
                onClick={onhandleProcess.bind(this, action)}
                startIcon={buttonHistory(action) ? (action.statusCode === 'Rejected'
                    ? <CloseIcon /> : <DoneIcon />) : ''}
            >
                {action.actionName}
            </Button>))
    }

    const renderAttention = () => {

        let record = currentRecord
        if (record.statusCode !== 'Rejected')
            return ''
        if (!record.opentext)
            return ''
        return (<Grid className={classes.attention} >
            {record.opentext.split('\n').map((line, index) => (
                <Typography key={index} className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
            ))}
        </Grid>)
    }

    let fixedStatusCodes = []
    let fixedStatusCode = currentProcessStatus.statusCode
    if (currentProcessStatus && currentProcessStatus.statusCodes) {
        for (let sc of currentProcessStatus.statusCodes) {
            if (sc === 'ToBeInvited' || sc === 'InTrial' || sc === 'ToBeSigned') {
                fixedStatusCodes.push('Trial')
            } else {
                fixedStatusCodes.push(sc)
            }
            // Warning: 暂时为厂长显示一个'已提交'节点
            if (fixedStatusCode === 'Submitted' && sc === 'Drafting') {
                fixedStatusCodes.push('Submitted')
            }
        }
    }

    fixedStatusCodes = _.uniq(fixedStatusCodes)

    if (fixedStatusCode === 'ToBeInvited' || fixedStatusCode === 'InTrial' || fixedStatusCode === 'ToBeSigned') {
        fixedStatusCode = 'Trial'
    }

    return (
        <div alignitems="center" className={classes.root}>

            <div alignitems="center" className={classes.planitemroot}>
                {
                    (!plan || Object.keys(plan).length === 0) && (
                        checkStartPlanPermission() ?
                            <Grid container className={classes.title}>
                                <Grid item>
                                    <Grid item className={classes.button}>
                                        <Button variant='contained'
                                            color='primary'
                                            onClick={onhandleAddPlan}
                                            disabled={plan && Object.keys(plan).length > 0}
                                        >
                                            <PostAdd />启动年度计划
                                </Button>
                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid container className={classes.title}>
                                <Grid item>
                                    <Grid item className={classes.button}>
                                        <Button variant='outlined'
                                            color='primary'
                                            disableElevation
                                            onClick={onhandleAddPlan}
                                            disabled
                                        >
                                            <PostAdd />年度计划待启动
                                </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                    )
                }
                {plan && Object.keys(plan).length > 0 &&
                    <Grid className={classes.infocontainer}>
                        <Grid container >
                            <Grid item className={classes.processline}>
                                <ProcessLine
                                    statusCodes={fixedStatusCodes}
                                    statusCode={fixedStatusCode}
                                    constant={constant}
                                />
                            </Grid>
                            <Grid item>
                                <List >
                                    {renderActions()}
                                </List>
                            </Grid>
                        </Grid>

                        {renderAttention()}

                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={onhandleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {listTypeList ?
                                listTypeList.filter(
                                    // 特殊排查INS07不纳入年度计划
                                    (data) => (data.code !== 'INS07')
                                ).map(data => (
                                    <Tab className={classes.tab} value={data.code} key={data.code} label={data.name} />
                                )) : null
                            }

                        </Tabs>

                        <Grid className={classes.detailtable}>
                            <MaterialTable
                                tableRef={tableRef}
                                className={classes.materialtable}
                                title={<Typography variant='h3' className={classes.tableTitle}>{plan.planName}</Typography>}
                                columns={assemblycolumns()}
                                data={assemblyData(tabValue)}
                                options={tableOptions}
                                editable={
                                    !checkEditable() ? {} :
                                        {
                                            isEditable: rowData => (planEditableStatus.includes(rowData.status.toLowerCase()) && _.findIndex(inspectionDptMapping, { 'inspectionCode': tabValue }) > -1),
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    onhandleUpdate(newData)
                                                    resolve()
                                                }),
                                            // onRowDelete: (oldData) =>
                                            //     new Promise((resolve, reject) => {
                                            //         onhandleDelete(oldData)
                                            //         resolve()
                                            //     }),
                                        }}
                                localization={localization}
                                style={style}
                                actions={
                                    !displayAddpanel(currentRecord, currentProcess) ? [] :
                                        [
                                            {
                                                icon: 'add',
                                                tooltip: '新增计划详情',
                                                isFreeAction: true,
                                                onClick: (event) => { onhandleOpenPlanDetailAddPannel(tabValue) },
                                                hidden: _.findIndex(inspectionDptMapping, { 'inspectionCode': tabValue }) < 0,
                                            },
                                            // 暂不实现单条驳回操作
                                            // rowData => ({
                                            //     icon: 'block',
                                            //     tooltip: '驳回',
                                            //     onClick: (event, rowData) => {
                                            //         onhandleReject(event, rowData.dtlId)
                                            //     },
                                            //     disabled: rowData.status === 'Rejected'
                                            // }),
                                            rowData => ({
                                                icon: 'delete_outlined',
                                                tooltip: '删除',
                                                onClick: (event, rowData) => {
                                                    onhandleDelete(event, rowData.dtlId)
                                                },
                                                disabled: !planEditableStatus.includes(rowData.status.toLowerCase()) || _.findIndex(inspectionDptMapping, { 'inspectionCode': tabValue }) < 0
                                            })
                                        ]
                                }
                            >
                            </MaterialTable>
                        </Grid>
                    </Grid>}
            </div>
        </div >

    );
};
InspectionPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    departmentList: PropTypes.array.isRequired,
    departmentsByRole: PropTypes.array.isRequired,
    listTypeList: PropTypes.array.isRequired,
    inspectionDptMapping: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    planDetails: PropTypes.array.isRequired,
    processes: PropTypes.array.isRequired,
    processHistories: PropTypes.array.isRequired,
    processSteps: PropTypes.array.isRequired,
    onhandleAddPlan: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleReject: PropTypes.func.isRequired,
    onhandleActivate: PropTypes.func.isRequired,
    onhandleProcess: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandleOpenPlanDetailAddPannel: PropTypes.func.isRequired
};

InspectionPlan.defaultProps = {
    listTypeList: [],
    inspectionDptMapping: [],
    categoryList: [],
    plan: {},
    planDetails: [],
    currentUserInfo: {},
    userProfile: {},
    users: [],
    constant: {},
    departmentList: [],
    departmentsByRole: [],
    processes: [],
    processHistories: [],
    processSteps: [],
    currentProcessStatus: {},
}

export default withStyles(InspectionPlan);