import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import TenantHeader from "../../containers/TenantManagement/TenantHeader";
import TenantList from "../../containers/TenantManagement/TenantList";

const TenantManagement = props => {

    const {classes} = props;

    return (
        <div className={classes.root}>
            <TenantHeader/>
            <div className={classes.content}>
                <TenantList/>
            </div>
        </div>
    );
};
TenantManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(TenantManagement);
