import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import MaterialTable from 'material-table';
import { localization, options } from "../../utils/mtable";
import '../../assets/rc-tree.css';
import '../../assets/rc-tree-select-tree.css';
import { getDateByTimestamp } from '../../utils/datetime';

function AccessoryReplaceRecord(props) {
  const {
    classes,
    recordList,
    onhandleCleanmodal,
  } = props;

  const [pageSize] = useState(5);

  const renderTime = (row) => {
    return row.replaceTime ? getDateByTimestamp(row.replaceTime, true) : ''
  }

  return (
    <div>
      <Grid className={classes.card}>
        <Typography variant="h3">
          {"部件更新记录"}
        </Typography>
      </Grid>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
              },
              {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
              },
              {
                title: '原部件',
                field: 'orginAccName',
                render: row => row ? <Typography>{row.orginAccName}</Typography> : '',
                editable: 'never'
              },
              {
                title: '新部件',
                field: 'newAccName',
                render: row => row ? <Typography>{row.newAccName}</Typography> : '',
                editable: 'never'
              },
              {
                title: '更换人',
                field: 'replacerName',
                render: row => row && row.replacerLastName && row.replacerFirstName ? <Typography>{row.replacerLastName + row.replacerFirstName}</Typography> : row.replacerFirstName ? <Typography>{row.replacerFirstName}</Typography> : '',
                editable: 'never'
              },
              {
                title: '更换时间',
                field: 'replaceTime',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
              }]}
            data={recordList}
            options={{
              ...options,
              pageSize: pageSize,
              tableLayout: 'auto',
              actionsColumnIndex: -1,
              search: false,
              showTextRowsSelected: false,
              toolbar: false,
              // selection: true
            }}
            style={{
              border: '0px solid black',
              boxShadow: 'none'
            }}
            localization={localization}
          >
          </MaterialTable>
        </Grid>
      </Grid>


      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Button variant="contained" color="primary"
          onClick={event => onhandleCleanmodal()}
        >
          关闭
        </Button>
      </Grid>
    </div >
  );
}

AccessoryReplaceRecord.propTypes = {
  classes: PropTypes.object.isRequired,
  recordList: PropTypes.array.isRequired,
  onhandleCleanmodal: PropTypes.func.isRequired,
};

export default withStyles(AccessoryReplaceRecord);
