import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage } from './message';
import { createloading, cleanloading } from './loading';
import { formSelectTree, sortByStepOrder } from '../../utils/constant';
import { getCompanyTree } from './trees';
import { cleanmodal } from './modal';
import { getUsersWithCallback } from '@/redux/modules/user';
import { getCompanyId } from "../../utils/userUtils";
import { v4 as uuidv4 } from 'uuid';
import { getPlanDetailById, getPlanDetailList, setPlanDetails } from './operationPlan';
import { getRoles } from './role';
import { getOperationCategory } from './operationRule';
// import { routeToOperationPlanList } from "./operationPlan";
import { getAccessoryByWorkOrder, getAccessoryCategory } from './accessory';
import { createMessage } from '@/redux/modules/message';
import { STATUS_VALUE } from '@/utils/constant';
import { removeObject } from './minio';
import _ from 'lodash';
import { getWorkOrderStatusByRouteType } from '@/utils/operationUtils';
const porxyApi = '/api'

const initialState = {
    workOrderList: {
        total: 0,
        list: []
    },
    newWorkOrders: [],
    newWorkOrderChange: false,
    workOrderChange: false,
    pageSize: 10,
    sourceTree: [],
    sourceList: [],
    orderTypeTree: [],
    orderTypeList: [],
    orderTypeLeafTree: [],
    specCodeTree: [],
    specCodeList: [],
    terminateTypeList: [],
    search: {
        page: 1,
        size: 10,
        orderType: undefined,
        source: '',
        companyId: '',
        dptId: '',
        status: '',
        device: '',
        optNo: '',
        orderNo: '',
        onlyMe: false
    },
    appointmentList: {
        total: 0,
        list: []
    },
    appointmentDetails: [],
    newAppointmentChange: false,
    currentWorkOrder: {},
    currentAppointment: {},
    currentPermit: {},
    workRecordList: [],
    workorderWithBigPermitList: [],
    masterBigPermit: {},
    masterWorkOrder: {},
    workOrderApprovers: [],
    workOrderApproversLast: [],
    workOrderNotices: [],
    masterJSADocs: [],
    masterBigpermitDocs: []
}

export const setWorkOrderApprovers = createAction('@@xcloud/operation/setWorkOrderApprovers');

export const getWorkOrderApprovers = createAction('@@xcloud/operation/getWorkOrderApprovers',
    (id) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/approvers`
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkOrderApprovers(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询作业审批记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const setWorkOrderNotices = createAction('@@xcloud/operation/setWorkOrderNotices');

export const getWorkOrderNotices = createAction('@@xcloud/operation/getWorkOrderNotices',
    (id) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/notices`
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkOrderNotices(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询作业审批抄送者列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);


export const setWorkOrderApproversLast = createAction('@@xcloud/operation/setWorkOrderApproversLast');

export const getWorkOrderApproversLast = createAction('@@xcloud/operation/getWorkOrderApproversLast',
    (id) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/approvers/last`
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkOrderApproversLast(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询作业审批记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);


export const updateWorkOrderApprover = createAction('@@xcloud/operation/updateWorkOrderApprover',
    (id, data, callback) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/approvers`
        let dto = {
            aprvId: data.aprvId,
            orderId: data.orderId,
            confirm: data.confirm,
            userId: data.userId,
        }
        if (data.orderApprovalDoc) {
            dto.orderApprovalDoc = data.orderApprovalDoc
        }
        if (data.preId && _.isInteger(data.preId)) {
            dto.preId = data.preId
        }
        if (data.opentext && data.opentext !== '') {
            dto.opentext = data.opentext
        }

        return httpClient
            .patch(porxyApi + url, dto)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新作业审批记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const saveWorkOrderApprovers = createAction('@@xcloud/operation/saveWorkOrderApprovers',
    (id, datas, processNotices, callback) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/approvers/init`
        let dtos = [];
        datas.forEach(data => {
            let dto = {
                orderId: _.toInteger(id),
                roleId: data.roleId,
                aprvIdString: _.isString(data.id) ? data.id : _.toString(data.id)
            }
            if (data.userId) {
                dto.userId = data.userId
            }
            if (data.preId) {
                dto.preIdString = _.isString(data.preId) ? data.preId : _.toString(data.preId)
            }
            dtos.push(dto)
        })

        return httpClient
            .post(porxyApi + url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(saveWorkOrderNotices(id, processNotices, callback));
                }
            })
            .catch(err => {
                let msg = `保存作业审批记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const saveWorkOrderNotices = createAction('@@xcloud/operation/saveWorkOrderNotices',
    (id, processNotices, callback) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${id}/notices`
        let dtos = [];
        processNotices.forEach(data => {
            let dto = {
                orderId: _.toInteger(id),
                userId: data.userId,
                roleId: data.roleId
            }
            dtos.push(dto)
        })

        return httpClient
            .post(porxyApi + url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `保存作业审批抄送列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const routeToAppointmentCreate = createAction(
    '@@xcloud/operation/routeToAppointmentCreate',
    (orderId, routeType) => (dispatch) => {
        dispatch(push(`/operationManagement/workorder/${routeType}/appointment/${orderId}`))
    }
);

// 根据工单状态，路由到不同路径
export const routeBaseOnStatus = createAction(
    '@@xcloud/operation/routeBaseOnStatus',
    (status) => (dispatch) => {
        switch (status) {
            case 'done':
                dispatch(push(`/operationManagement/workorderlist/all`))
                break;

            case 'new':
            case 'submitted':
                dispatch(push(`/operationManagement/workorderlist/needProcess`))
                break;

            case 'approved':
                dispatch(push(`/operationManagement/workorderlist/needAppointment`))
                break;

            case 'published':
                dispatch(push(`/operationManagement/workorderlist/needWork`))
                break;

            case 'finished':
                dispatch(push(`/operationManagement/workorderlist/needCount`))
                break;

            default:
            // do nothing
        }
    }
);

export const setAppointmentDetails = createAction('@@xcloud/operation/setAppointmentDetails',
    (data) => () => {
        let sortData = _.sortBy(data, ['estimateStartTime', 'estimateEndTime']);
        let newData = sortByStepOrder(sortData);
        return newData
    }
);

export const getWorkOrderWithBigPermit = createAction('@@xcloud/operation/getWorkOrderWithBigPermit',
    (id) => (dispatch, getState, httpClient) => {
        let url = `/workOrders/${id}/workpermit`
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkorderWithBigPermitList(res.data))
                let currentWorkOrder = _.find(res.data, { orderId: id });
                let masterWorkOrder = _.find(res.data, function (o) { return !o.masterOrder });
                if (currentWorkOrder) {
                    dispatch(setCurrentWorkOrder(currentWorkOrder));
                    dispatch(setMasterWorkOrder(masterWorkOrder))
                    dispatch(setMasterBigPermit(masterWorkOrder.workPermit && masterWorkOrder.workPermit.length > 0 ? masterWorkOrder.workPermit[0] : {}));

                }
                if (masterWorkOrder && masterWorkOrder.workPermit.length > 0
                    && masterWorkOrder.workPermit[0].permitDocs && masterWorkOrder.workPermit[0].permitDocs.length > 0) {
                    let masterJSADocs = _.filter(masterWorkOrder.workPermit[0].permitDocs, { contentType: 1, status: 'active' });
                    let masterBigpermitDocs = _.filter(masterWorkOrder.workPermit[0].permitDocs, { contentType: 2 });
                    dispatch(setMasterJSADocs(masterJSADocs))
                    dispatch(setMasterBigpermitDocs(masterBigpermitDocs))
                } else {
                    dispatch(setMasterJSADocs([]))
                    dispatch(setMasterBigpermitDocs([]))
                }
                return res.data;
            })
            .catch(err => {
                let msg = `查询大许可失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {}
            })
    }
);

export const setMasterBigPermit = createAction('@@xcloud/operation/setMasterBigPermit');
export const setMasterWorkOrder = createAction('@@xcloud/operation/setMasterWorkOrder');
export const setWorkorderWithBigPermitList = createAction('@@xcloud/operation/setWorkorderWithBigPermitList');
export const setMasterJSADocs = createAction('@@xcloud/operation/setMasterJSADocs');
export const setMasterBigpermitDocs = createAction('@@xcloud/operation/setMasterBigpermitDocs');

export const stepProcess = createAction('@@xcloud/operation/stepProcess',
    (orderId, callback) => (dispatch, getState, httpClient) => {

        httpClient.patch(porxyApi + `/appointments/${orderId}/stepProcess`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "作业预约编辑完成通知处理成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `作业预约编辑完成通知处理失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const setNewAppointmentChange = createAction('@@xcloud/operation/setNewAppointmentChange');
export const setCurrentWorkOrder = createAction('@@xcloud/operation/setCurrentWorkOrder');
export const setNewWorkOrders = createAction('@@xcloud/operation/setNewWorkOrders');
export const setNewWorkOrderChange = createAction('@@xcloud/operation/setNewWorkOrderChange');
export const setAppointmentList = createAction('@@xcloud/operation/setAppointmentList');
export const getWorkOrder = createAction(
    '@@xcloud/operation/getWorkOrder',
    (orderId) => (dispatch, getState, httpClient) => {
        let url = `/workorders/${orderId}`
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setCurrentWorkOrder(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getSpecCode = createAction('@@xcloud/operation/getSpecCode',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/operation/dictionaries?type=SPECCODE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '特殊作业类型获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getTerminateType = createAction('@@xcloud/operation/getTerminateType',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/operation/dictionaries?type=TERMTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data };
                }
                return res;
            }).catch(err => {
                let msg = '终止类型获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const setWorkOrderCost = createAction(
    '@@xcloud/operation/initAppointmentCreate',
    (orderId, cost) => (dispatch, getState, httpCliene) => {
        let datas = _.clone(getState().operation.workorderWithBigPermitList);
        let data = _.find(datas, { orderId: orderId });
        data.orderCost = cost
        dispatch(setWorkorderWithBigPermitList(datas))


        if (_.filter(datas, function (o) { return !o.orderCost && o.orderCost !== 0 }).length === 0) {
            let dtlDatas = _.cloneDeep(getState().operationPlan.planDetails)
            let total = 0;
            datas.forEach((woData) => {
                total = _.add(_.toNumber(woData.orderCost), _.toNumber(total))
            })
            let dtlCount = dtlDatas.list.length;
            if (dtlDatas.list.length === 1) {
                dtlDatas.list[0].onefinalCost = total
            } else {
                let subcount = 0
                dtlDatas.list.forEach((dtlData, index) => {
                    if (index === dtlDatas.length - 1) {
                        dtlData.onefinalCost = _.subtract(total, subcount);
                    } else {
                        dtlData.onefinalCost = _.floor(_.divide(total, dtlCount));
                        subcount = _.add(_.toNumber(dtlData.onefinalCost), _.toNumber(subcount))
                    }
                })
            }
            dispatch(setPlanDetails(dtlDatas))
        }
    }
);


export const setWorkOrderOpentext = createAction(
    '@@xcloud/operation/setWorkOrderOpentext',
    (orderId, opentext) => (dispatch, getState, httpCliene) => {
        let datas = _.clone(getState().operation.workorderWithBigPermitList);
        let data = _.find(datas, { orderId: orderId });
        data.opentext = opentext
        dispatch(setWorkorderWithBigPermitList(datas))
    }
);

export const updateWorkOrderFinalCost = createAction('@@xcloud/operation/updateWorkOrderFinalCost',
    (data, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/workorders/finalcost', data, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "工单验收成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `工单验收失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);


export const updateWorkOrderInvalid = createAction('@@xcloud/operation/updateWorkOrderInvalid',
    (data, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/workorders/invalid', data, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "工单作废成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `工单作废成功`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);
export const initAppointmentCreate = createAction(
    '@@xcloud/operation/initAppointmentCreate',
    (orderId, companyId, callback) => (dispatch, getState, httpCliene) => {
        dispatch(createloading('logic'));
        return Promise.all([
            dispatch(setAppointmentDetails([])),
            dispatch(setWorkorderWithBigPermitList([])),
            dispatch(setMasterBigPermit({})),
            dispatch(setNewAppointmentChange(false)),
            dispatch(getSpecCode()),
            dispatch(getDicOrderType()),
            dispatch(getWorkOrderWithBigPermit(_.toInteger(orderId))),
            dispatch(getAppointmentList({ sort: 0, size: 0, orderId: _.toInteger(orderId) })),
            dispatch(getPlanDetailList({ size: 0, offset: 0, orderId: orderId }))
        ]).then(
            () => {
                let appointmentDetails = getState().operation.appointmentDetails;
                let currentOrderAppointmentDetails = _.filter(appointmentDetails, { orderId: _.toInteger(orderId) });
                if (currentOrderAppointmentDetails.length === 0) {
                    let currentWorkOrder = getState().operation.currentWorkOrder;

                    let newAppointmentDetails = [
                        {
                            status: 'new',
                            orderId: _.toInteger(orderId),
                            orderNo: currentWorkOrder.orderNo,
                            mainWork: true,
                            location: null,
                            estimateStartTime: null,
                            estimateEndTime: null,
                            appContent: null,
                            preApp: null,
                            woeId: currentWorkOrder.executors[0].woeId,
                            _id: uuidv4(),
                            locationCompany: currentWorkOrder.companyId,
                            workOrderExecutor: currentWorkOrder.executors[0],
                            tableData: {
                                editing: 'update'
                            }
                        }]

                    dispatch(setAppointmentDetails(_.concat(newAppointmentDetails, appointmentDetails))).then(
                        () => {
                            dispatch(setNewAppointmentChange(true));
                            if (callback) callback();
                            dispatch(cleanloading());
                        }
                    )
                } else {
                    if (callback) callback();
                    dispatch(cleanloading());
                }
            }
        ).catch(err => {
            dispatch(cleanloading());
        })
    }
);

export const updateAppointmentDetail = createAction(
    '@@xcloud/operation/updateAppointmentDetail',
    (newData, oldData, callback) => (dispatch, getState) => {
        if (!newData.appId) {
            dispatch(createAppointment(newData, callback));
        } else {
            if (newData.estimateStartTime === oldData.estimateStartTime) {
                delete newData.estimateStartTime;
            }
            if (newData.estimateEndTime === oldData.estimateEndTime) {
                delete newData.estimateEndTime;
            }
            // if (newData.status === STATUS_VALUE.STATUS_PAUSED || newData.status === STATUS_VALUE.STATUS_TERMINATED) {
            //     if (!newData.specCode) {
            //         newData.status = STATUS_VALUE.STATUS_APPROVED;
            //     } else {
            //         newData.status = STATUS_VALUE.STATUS_SUBMITTED;
            //     }
            // }
            dispatch(updateAppointment(newData, callback));
        }
    }
);

export const updateAppointmentDetailStatus = createAction('@@xcloud/operation/updateAppointmentDetailStatus',
    (appId, status, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let to = {
            appId: appId,
            status: status
        }
        let url = porxyApi + '/appointments';
        return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(createMessage('success', '更新作业预约完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '更新作业预约失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const deleteAppointmentDetail = createAction(
    '@@xcloud/operation/deleteAppointmentDetail',
    (data) => (dispatch, getState) => {
        if (!data.appId) {
            let appointmentDetails = _.clone(getState().operation.appointmentDetails);
            appointmentDetails = _.reject(appointmentDetails, { _id: data._id });
            dispatch(setAppointmentDetails(appointmentDetails))
            dispatch(setNewAppointmentChange(false))
        } else {
            delete data.startTime;
            delete data.endTime;
            data.status = 'inactive';
            dispatch(updateAppointment(data));
        }
    }
);

export const getAppointmentList = createAction(
    '@@xcloud/operation/getAppointmentList',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/appointments"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.orderId)) {
            url += "&orderId=" + query.orderId
        }
        if (query && _.isInteger(query.status)) {
            url += "&status=" + query.status
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setAppointmentList(res.data))
                dispatch(setAppointmentDetails(res.data.list))
                return res.data;
            })
            .catch(err => {
                let msg = `查询作业预约列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                dispatch(setAppointmentDetails([]))
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getAppointmentById = createAction(
    '@@xcloud/operation/getAppointmentById',
    (appId) => (dispatch, getState, httpClient) => {
        let url = `/appointments/${appId}`;
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setCurrentAppointment(res.data))
                return res.data;
            })
            .catch(err => {
                console.log(err)
                let msg = `查询作业失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                dispatch(setCurrentAppointment({}))
                return {};
            })
    }
);

export const createAppointment = createAction('@@xcloud/operation/createAppointment',
    (data, callback) => (dispatch, getState, httpClient) => {
        let url = '/api/appointments';
        let dto = {
            estimateStartTime: data.estimateStartTime ? data.estimateStartTime : null,
            estimateEndTime: data.estimateEndTime ? data.estimateEndTime : null,
            location: data.location ? data.location : null,
            locationCompany: data.locationCompany ? data.locationCompany : null,
            mainWork: data.mainWork,
            appContent: data.appContent,
            specCode: data.specCode,
            orderId: data.orderId,
            status: data.status,
            woeId: data.woeId,
        }
        if (_.isInteger(data.appHead)) {
            dto.appHead = data.appHead
        }
        if (_.isInteger(data.preApp)) {
            dto.preApp = data.preApp
        }

        return httpClient.post(url, dto)
            .then((res) => {
                if (res.status === 201) {
                    Promise.all([
                        dispatch(setNewAppointmentChange(false))
                    ]).then((res) => {
                        if (callback) callback();
                        dispatch(cleanmodal());
                    })
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建作业预约失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateAppointment = createAction('@@xcloud/operation/updateAppointment',
    (data, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            tenantId: data.tenantId,
            location: data.location ? data.location : undefined,
            mainWork: data.mainWork,
            appContent: data.appContent,
            orderId: data.orderId,
            status: data.status,
        }
        if (data.opentext) {
            dto.opentext = data.opentext
        }
        if (data.startTime) {
            dto.startTime = data.startTime
        }
        if (data.endTime) {
            dto.endTime = data.endTime
        }
        if (data.estimateStartTime) {
            dto.estimateStartTime = data.estimateStartTime
        }
        if (data.estimateEndTime) {
            dto.estimateEndTime = data.estimateEndTime
        }
        if (data.specCode) {
            dto.specCode = data.specCode
        }
        if (data.terminateType) {
            dto.terminateType = data.terminateType
        }
        if (data.inspectionPlan) {
            dto.inspectionPlan = data.inspectionPlan
        }
        if (_.isInteger(data.locationCompany)) {
            dto.locationCompany = data.locationCompany
        }
        if (_.isInteger(data.appId)) {
            dto.appId = data.appId
        }
        if (_.isInteger(data.appHead)) {
            dto.appHead = data.appHead
        }
        if (_.isInteger(data.preApp)) {
            dto.preApp = data.preApp
        }

        httpClient.patch(porxyApi + '/appointments', dto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新作业预约成功"));
                    Promise.all([
                        dispatch(setNewAppointmentChange(false)),
                        // dispatch(getAppointmentList({ sort: 0, size: 0, orderId: _.toInteger(data.orderId) }))
                    ]).then((res) => {
                        if (callback) callback();
                        dispatch(cleanmodal());
                    })
                }
            }
            ).catch(err => {
                let msg = `更新作业预约失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const setSearch = createAction('@@xcloud/operation/setSearch');

export const resetSearch = createAction('@@xcloud/operation/resetSearch',
    (routeType) => (dispatch, getState, httpClient) => {
        let companyList = getState().constant.companies;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let company = _.find(companyList, { companyId: companyId })
        let search = getState().operation.search;
        let status = getWorkOrderStatusByRouteType(routeType);

        let newSearch = {
            ...search,
            orderType: undefined,
            source: '',
            companyId: company && company.pid ? companyId : '',
            dptId: '',
            status: status ? status : null,
            filterStatus: '',
            optNo: '',
            orderNo: '',
            device: '',
            onlyMe: false,
        }

        dispatch(setSearch(newSearch));
    });

export const initWorkOrderWithOperationPlan = createAction(
    '@@xcloud/operation/initWorkOrderWithOperationPlan',
    (id) => (dispatch) => {
        return Promise.all([
            dispatch(getPlanDetailList({ size: 0, offset: 0, orderId: id })),
            dispatch(getDicSource()),
            dispatch(getDicOrderType()),
            dispatch(getOperationCategory()),
            dispatch(getAccessoryCategory()),
            dispatch(getWorkOrderListForEdit({ orderId: _.toInteger(id), mainOrder: true })),
        ]).then(
        ).catch(err => {
        })
    }
);

export const initWorkOrderProcess = createAction(
    '@@xcloud/operation/initWorkOrderProcess',
    (id, callback) => (dispatch) => {
        dispatch(createloading('logic'));
        return Promise.all([
            dispatch(getRoles(0, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'spec_order_check')),
            dispatch(getPlanDetailList({ size: 0, offset: 0, orderId: id })),
            dispatch(getDicSource()),
            dispatch(getDicOrderType()),
            dispatch(getOperationCategory()),
            dispatch(getAccessoryCategory()),
            dispatch(getWorkOrderListForEdit({ orderId: _.toInteger(id), mainOrder: true })),
            dispatch(getWorkOrderApprovers(_.toInteger(id))),
            dispatch(getWorkOrderApproversLast(_.toInteger(id))),
            dispatch(getWorkOrderNotices(_.toInteger(id))),
            dispatch(getAccessoryByWorkOrder(_.toInteger(id)))
        ]).then(
            () => {
                if (callback) callback();
                dispatch(cleanloading());
            }
        ).catch(err => {
            dispatch(cleanloading());
        })
    }
);

export const initWorkOrderInfoForCreate = createAction(
    '@@xcloud/operation/initWorkOrderInfoForCreate',
    (id) => (dispatch) => {
        dispatch(createloading('logic'));
        let task = [
            dispatch(getDicSource()),
            dispatch(getDicOrderType()),
            dispatch(getOperationCategory()),
            dispatch(getAccessoryCategory()),
            dispatch(setNewWorkOrders([])),
            dispatch(setNewWorkOrderChange(false))
        ]
        if (id !== 'multiple') {
            task.push(dispatch(getPlanDetailById(id)))
        }

        return Promise.all(task).then(
            () => {
                dispatch(cleanloading());
            }
        ).catch(err => {
            dispatch(cleanloading());
        })
    }
);

export const createSubWorkOrder = createAction('@@xcloud/operation/createSubWorkOrder',
    (data, callback) => (dispatch, getState, httpClient) => {
        let url = '/api/workorders';
        // let datas = getState().operation.newWorkOrders;
        let dtos = []
        let dto = {
            companyId: data.companyId ? data.companyId : null,
            dptId: data.dptId ? data.dptId : null,
            source: data.source ? data.source : null,
            // sourceId: data.sourceId ? data.sourceId : null,
            orderType: data.orderType,
            orderContent: data.orderContent,
            orderBudget: _.toNumber(data.orderBudget),
            masterOrder: data.masterOrder ? data.masterOrder : null,
            optDtlOrderKeies: data.optDtlOrderKeies
        }

        if (data.orderHead) {
            dto.orderHead = data.orderHead
        }

        dtos.push(dto)

        return httpClient.post(url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    if (callback) callback()
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建子工单失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const createWorkOrder = createAction('@@xcloud/operation/createWorkOrder',
    (optDtlOrderKeies, callback) => (dispatch, getState, httpClient) => {
        let url = '/api/workorders';
        let datas = getState().operation.newWorkOrders;
        let dtos = []
        datas.forEach(data => {
            let dto = {
                companyId: data.companyId ? data.companyId : null,
                dptId: data.dptId ? data.dptId : null,
                source: data.source ? data.source : null,
                // sourceId: data.sourceId ? data.sourceId : null,
                orderType: data.orderType,
                orderContent: data.orderContent,
                orderBudget: _.toNumber(data.orderBudget),
                optDtlOrderKeies: optDtlOrderKeies,
                orderHead: data.orderHead ? data.orderHead : null
            }
            dtos.push(dto)
        })

        return httpClient.post(url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(setNewWorkOrderChange(false));
                    // dispatch(routeToOperationPlanList());
                    callback(res.data);
                }
                return res.data;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '下达工单失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateWorkOrder = createAction('@@xcloud/operation/updateWorkOrder',
    (workorder, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            orderId: workorder.orderId,
        }
        if (workorder.orderHead && _.isInteger(workorder.orderHead)) {
            dto.orderHead = workorder.orderHead
        }
        if (workorder.orderContent) {
            dto.orderContent = workorder.orderContent
        }
        if (_.isNumber(workorder.orderBudget)) {
            dto.orderBudget = workorder.orderBudget
        }
        if (workorder.status) {
            dto.status = workorder.status
        }
        if (_.isNumber(workorder.orderCost)) {
            dto.orderCost = workorder.orderCost
        }

        httpClient.patch(porxyApi + '/workorders', dto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新工单成功"));
                    if (callback)
                        callback()
                }
            }
            ).catch(err => {
                let msg = `更新工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const createWorkOrderExecutor = createAction('@@xcloud/operation/createWorkOrderExecutor',
    (workOrderExecutor, callback) => (dispatch, getState, httpClient) => {
        let url = '/api/workorderexecutors';
        let dto = {
            orderId: workOrderExecutor.orderId,
        }

        if (workOrderExecutor.internalCompany) {
            dto.internalCompany = workOrderExecutor.internalCompany
        }

        if (workOrderExecutor.internalDpt) {
            dto.internalDpt = workOrderExecutor.internalDpt
        }

        if (workOrderExecutor.supplierId) {
            dto.supplierId = workOrderExecutor.supplierId
        }

        return httpClient.post(url, dto)
            .then((res) => {
                if (res.status === 201) {
                    if (res) {
                        if (callback)
                            callback(res.data)
                    }
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '指派承包商失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateWorkOrderExecutor = createAction('@@xcloud/operation/updateWorkOrderExecutor',
    (workOrderExecutor, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            orderId: workOrderExecutor.orderId,
            woeId: workOrderExecutor.woeId,
        }

        if (_.isInteger(workOrderExecutor.internalCompany)) {
            dto.internalCompany = workOrderExecutor.internalCompany
        }

        if (_.isInteger(workOrderExecutor.internalDpt)) {
            dto.internalDpt = workOrderExecutor.internalDpt
        }

        if (_.isInteger(workOrderExecutor.supplierId)) {
            dto.supplierId = workOrderExecutor.supplierId
        }

        if (workOrderExecutor.status && workOrderExecutor.status !== '') {
            dto.status = workOrderExecutor.status
        }

        httpClient.patch(porxyApi + '/workorderexecutors', dto)
            .then((res) => {
                if (res) {
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `更新承包商失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const getDicSource = createAction('@@xcloud/operation/getDicSource',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/operation/dictionaries?type=ORDSRC";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '工单来源获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getDicOrderType = createAction('@@xcloud/operation/getDicOrderType',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/operation/dictionaries?type=ORDTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    let orderTypes = _.reject(res.data, { opentext: 'HIDDEN' })
                    return { list: orderTypes, tree: formSelectTree(orderTypes, false), leafTree: formSelectTree(orderTypes, true) };
                }
                return res;
            }).catch(err => {
                let msg = '工单类型获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getWorkOrderListForEdit = createAction(
    '@@xcloud/operation/getWorkOrderListForEdit',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/workorders"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        }
        if (query && _.isInteger(query.dptId)) {
            url += "&dptId=" + query.dptId
        }
        if (query && query.source) {
            url += "&source=" + query.source
        }
        if (query && _.isInteger(query.sourceId)) {
            url += "&sourceId=" + query.sourceId
        }
        if (query && _.isInteger(query.orderId)) {
            url += "&orderId=" + query.orderId
        }
        if (query && query.mainOrder) {
            url += "&mainOrder=" + query.mainOrder
        }
        if (query && query.orderType) {
            url += "&orderType=" + query.orderType
        }
        if (query && query.status) {
            url += "&status=" + query.status
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + 0
        }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        }

        if (query && query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        }

        if (query && query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkOrderList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const routeToWorkOrderList = createAction(
    '@@xcloud/operation/routeToWorkOrderList',
    (routeType) => (dispatch, getState) => {
        let path = '';
        if (routeType && routeType !== "") {
            path = `/operationManagement/workorderlist/${routeType}`;
        }
        dispatch(push(path));
    }
);

export const getWorkOrderList = createAction(
    '@@xcloud/operation/getWorkOrderList',
    (query) => (dispatch, getState, httpClient) => {
        let searchCond = getState().operation.search;
        let currentUserInfo = getState().auth.currentUserInfo;

        let url = "/workorders"
        if (query && _.isInteger(query.offset)) {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=" + (searchCond.page * searchCond.size - searchCond.size)
        }

        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + searchCond.size
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId
        } else if (searchCond && _.isInteger(searchCond.companyId)) {
            url += "&companyId=" + searchCond.companyId
        }
        if (query && _.isInteger(query.dptId)) {
            url += "&dptId=" + query.dptId
        } else if (searchCond && _.isInteger(searchCond.dptId)) {
            url += "&dptId=" + searchCond.dptId
        }
        if (query && query.source) {
            url += "&source=" + query.source
        } else if (searchCond && searchCond.source && searchCond.source !== "") {
            url += "&source=" + searchCond.source
        }
        // 来源Id
        if (query && query.sourceId) {
            url += "&sourceId=" + query.sourceId
        } else if (searchCond && searchCond.sourceId && searchCond.sourceId !== "") {
            url += "&sourceId=" + searchCond.sourceId
        }
        if (query && query.orderType) {
            url += "&orderType=" + query.orderType
        } else if (searchCond && searchCond.orderType && searchCond.orderType.value) {
            url += "&orderType=" + searchCond.orderType.value
        }
        if (query && query.status) {
            url += "&status=" + query.status
        } else if (searchCond && searchCond.status && searchCond.status !== "") {
            url += "&status=" + searchCond.status
        }
        if (query && query.device) {
            url += "&device=" + encodeURIComponent(_.trim(query.device))
        } else if (searchCond && searchCond.device && searchCond.device !== "") {
            url += "&device=" + encodeURIComponent(_.trim(searchCond.device))
        }
        if (query && query.optNo) {
            url += "&optNo=" + encodeURIComponent(_.trim(query.optNo))
        } else if (searchCond && searchCond.optNo && searchCond.optNo !== "") {
            url += "&optNo=" + encodeURIComponent(_.trim(searchCond.optNo))
        }
        if (query && query.orderNo) {
            url += "&orderNo=" + encodeURIComponent(_.trim(query.orderNo))
        } else if (searchCond && searchCond.orderNo && searchCond.orderNo !== "") {
            url += "&orderNo=" + encodeURIComponent(_.trim(searchCond.orderNo))
        }


        if (query && query.onlyMe && currentUserInfo) {
            url += "&userId=" + currentUserInfo.userId
        } else if (searchCond && searchCond.onlyMe) {
            url += "&userId=" + currentUserInfo.userId
        }

        if (query && query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        }

        if (query && query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        }
        if (query && query.sort) {
            url += "&sort=" + query.sort
        } else if (searchCond && searchCond.sort && searchCond.sort !== "") {
            url += "&sort=" + searchCond.sort
        } else {
            url += "&sort=-create_time"
        }

        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkOrderList(res.data))
                return res.data;
            })
            .catch(err => {
                let msg = `查询工单列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const initWorkList = createAction('@@xcloud/operation/initWorkList',
    (routeType, sourceId) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        dispatch(setWorkOrderList({
            total: 0,
            list: []
        }))
        let search = getState().operation.search;
        let status = getWorkOrderStatusByRouteType(routeType);

        if (status !== search.status) {
            let newSearch = {
                ...search,
                page: 1,
                size: 10
            }
            dispatch(setSearch(newSearch));
            dispatch(resetSearch(routeType))
        } else {
            let newSearch = {
                ...search,
                status: status ? status : null,
            }
            dispatch(setSearch(newSearch));
        }

        Promise.all([
            dispatch(getCompanyTree()),
            dispatch(getDicSource()),
            dispatch(getDicOrderType()),
            // 获取工单列表
            dispatch(getWorkOrderList(sourceId !== undefined ? { sourceId: sourceId, size: 0, offset: 0 } : undefined)),
        ]).then((res) => {
            dispatch(cleanloading());
        })
    }
);

export const editWorkOrders = createAction(
    '@@xcloud/operation/editWorkOrders',
    (id, routeType) => (dispatch, getState) => {
        let routeTypeParam = 'all'
        if (routeType && routeType !== '') {
            routeTypeParam = routeType
        }
        if (id) {
            dispatch(push(`/operationManagement/workorderlist/${routeTypeParam}/edit/${id}`))
        }
    }
);


export const viewAppointmentDetail = createAction(
    '@@xcloud/operation/viewAppointmentDetail',
    (orderId, appId, routeType, workType) => (dispatch, getState) => {
        dispatch(push(`/operationManagement/workorder/${routeType}/${orderId}/appointment/${appId}/${workType}`))
    }
);

export const initAppointmentDetail = createAction(
    '@@xcloud/operation/initAppointmentDetail',
    (appId, orderId) => (dispatch, getState, httpCliene) => {
        dispatch(createloading('logic'));
        return Promise.all([
            dispatch(setCurrentAppointment({})),
            dispatch(getUsersWithCallback({ size: 0 })),
            dispatch(getAppointmentById(_.toInteger(appId))),
            dispatch(getSpecCode()),
            dispatch(getDicOrderType()),
        ]).then(
            () => {
                const currentAppointment = getState().operation.currentAppointment;
                if (!currentAppointment.permits) return {};
                let permit = {};
                const result = _.find(currentAppointment.permits, o => {
                    return [STATUS_VALUE.STATUS_NEW, STATUS_VALUE.STATUS_SUBMITTED, STATUS_VALUE.STATUS_APPROVED]
                        .includes(o.status);
                })
                if (result) permit = result
                dispatch(setCurrentPermit(permit));
                dispatch(cleanloading());
            }
        ).catch(err => {
            dispatch(cleanloading());
        })
    }
);

export const createWorkPermit = createAction('@@xcloud/operation/createWorkPermit',
    (data) => (dispatch, getState, httpClient) => {
        let url = '/api/workpermits';

        return httpClient.post(url, data)
            .then((res) => {
                if (res.status === 201) {
                    Promise.all([
                        dispatch(setNewAppointmentChange(false)),
                        dispatch(initAppointmentDetail(data.appId))
                    ]).then((res) => {
                        dispatch(cleanmodal());
                    })
                } else {
                    // 删除minio中文件
                    dispatch(removeObject(data.permitDoc.mybucket, data.permitDoc.docLink));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建作业许可失败';
                // 删除minio中文件
                dispatch(removeObject(data.permitDoc.mybucket, data.permitDoc.docLink));
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateWorkPermit = createAction('@@xcloud/operation/updateWorkPermit',
    (data, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let url = `/api/workpermits/${data.ptwId}`;
        return httpClient.patch(url, data, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback)
                        callback(res.data)
                    dispatch(createMessage('success', '更新许可信息完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                console.log(err)
                dispatch(cleanloading());
                let msg = '更新许可信息失败';
                // 删除minio中文件

                if (data.permitDoc) {
                    dispatch(removeObject(data.permitDoc.mybucket, data.permitDoc.docLink));
                }
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const changeWorkPermitStatus = createAction('@@xcloud/operation/changeWorkPermitStatus',
    (ptwId, status, callback) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let to = {
            ptwId: ptwId,
            status: status
        }
        let url = `/api/workpermits/${ptwId}`;
        return httpClient.patch(url, to)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(createMessage('success', '更新许可信息完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(cleanloading());
                let msg = '更新许可信息失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const createWorkPermitDoc = createAction('@@xcloud/operation/createWorkPermitDoc',
    (doc, type, callback) => (dispatch, getState, httpClient) => {
        console.log(doc)
        return httpClient
            .post(`/api/permitdocs`, doc, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `保存${type === 'JSA' ? 'JSA' : '大许可'}文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const changeWorkPermitDocStatus = createAction('@@xcloud/operation/removeWorkPermitDoc',
    (doc, status, type, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            docId: doc.docId,
            tenantId: doc.tenantId,
            status: status
        }

        return httpClient
            .patch(`/api/permitdocs/${doc.docId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    if (callback) callback();
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新${type === 'JSA' ? 'JSA' : '大许可'}文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const removeWorkPermitDoc = createAction('@@xcloud/operation/removeWorkPermitDoc',
    (doc, mybucket) => (dispatch, getState, httpClient) => {
        let dto = _.cloneDeep(doc);
        delete dto.createTime;
        delete dto.updateTime;
        delete dto.revisor;
        dto.status = 'inactive'

        return httpClient
            .patch(`/api/permitdocs/${doc.docId}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(removeObject(mybucket, doc.docLink));

                    return res.data;
                } else {
                    // dispatch(createMessage('warning', '该文档在...中已使用,请解除后再删除'));
                }
            })
            .catch(err => {
                let msg = `删除许可文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createWorkPermitUser = createAction('@@xcloud/operation/createWorkPermitUser',
    (ptwId, permitUserArray, processNotices, callback, type) => (dispatch, getState, httpClient) => {
        let url = `/api/workpermits/${ptwId}/approvers/type/${type}`;

        let dtos = [];
        permitUserArray.forEach(data => {
            let dto = {
                ptwId: data.ptwId,
                userId: data.userId,
                roleId: data.roleId,
                puIdString: _.isString(data.id) ? data.id : _.toString(data.id)
            }
            if (data.preId) {
                dto.preIdString = _.isString(data.preId) ? data.preId : _.toString(data.preId)
            }
            dtos.push(dto)
        })
        return httpClient.post(url, dtos)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(createWorkPermitNotice(ptwId, processNotices, callback));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建作业许可审批记录失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    }
);

export const createWorkPermitNotice = createAction('@@xcloud/operation/createWorkPermitNotice',
    (ptwId, processNotices, callback) => (dispatch, getState, httpClient) => {
        let url = `/api/workpermits/${ptwId}/notices`;
        const appId = getState().operation.currentAppointment.appId;
        return httpClient.post(url, processNotices)
            .then((res) => {
                if (res.status === 201) {
                    if (callback) {
                        callback()
                    } else {
                        dispatch(initAppointmentDetail(appId));
                    }
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建作业许可审批抄送列表失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateWorkPermitUser = createAction('@@xcloud/operation/updateWorkPermitUser',
    (permitUser, callback, type) => (dispatch, getState, httpClient) => {
        const appId = getState().operation.currentAppointment.appId;
        let dto = {
            ptwId: _.toInteger(permitUser.ptwId),
            puId: _.toInteger(permitUser.puId),
            userId: permitUser.userId,
            appId: appId,
            confirm: permitUser.confirm,
        }

        if (permitUser.preId && _.isInteger(permitUser.preId)) {
            dto.preId = permitUser.preId
        }
        if (permitUser.opentext && permitUser.opentext !== '') {
            dto.opentext = permitUser.opentext
        }
        return httpClient
            .patch(`/api/workpermits/${dto.ptwId}/approvers/${dto.puId}/type/${type}`, dto)
            .then((res) => {
                if (res.data) {
                    if (callback) {
                        callback()
                    } else {
                        dispatch(initAppointmentDetail(appId))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新审批记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const removeWorkPermitUser = createAction('@@xcloud/operation/removeWorkPermitUser',
    (permitUser, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            ptwId: _.toInteger(permitUser.ptwId),
            puId: _.toInteger(permitUser.puId),
            status: 'inactive'
        }

        const appId = getState().operation.currentAppointment.appId;

        return httpClient
            .patch(`/api/workpermits/${dto.ptwId}/approvers/${dto.puId}/type/spec`, dto)
            .then((res) => {
                if (res.data) {
                    if (callback) {
                        callback()
                    } else {
                        dispatch(initAppointmentDetail(appId))
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `删除批准人失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const createWorkRecord = createAction('@@xcloud/operation/createWorkRecord',
    (dto, callback) => (dispatch, getState, httpClient) => {
        return httpClient
            .post(`/api/workrecords`, dto)
            .then((res) => {
                if (res.data) {
                    if (callback) {
                        callback()
                    }
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建作业记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getWorkRecordList = createAction('@@xcloud/operation/getWorkRecordList',
    (query) => (dispatch, getState, httpClient) => {
        // let searchCond = getState().operation.search;
        // let pageSize = getState().operation.pageSize
        let url = "/workrecords"
        // if (query && _.isInteger(query.offset)) {
        //     url += "?offset=" + query.offset
        // } else {
        //     url += "?offset=0"
        // }

        url += "?offset=0"
        if (query && _.isInteger(query.appId)) {
            url += "&appId=" + query.appId
        }
        // else if (searchCond && _.isInteger(searchCond.appId)) {
        //     url += "&appId=" + searchCond.appId
        // }
        if (query && _.isInteger(query.orderId)) {
            url += "&orderId=" + query.orderId
        }
        // else if (searchCond && _.isInteger(searchCond.orderId)) {
        //     url += "&orderId=" + searchCond.orderId
        // }
        if (query && _.isInteger(query.userId)) {
            url += "&userId=" + query.userId
        }
        // else if (searchCond && _.isInteger(searchCond.userId)) {
        //     url += "&userId=" + searchCond.userId
        // }
        // if (query && query.status) {
        //     url += "&status=" + query.status
        // } else if (searchCond && searchCond.status && searchCond.status !== "") {
        //     url += "&status=" + searchCond.status
        // }
        url += "&size=" + 0
        // if (query && _.isInteger(query.size)) {
        //     url += "&size=" + query.size
        // } else {
        //     url += "&size=" + (pageSize ? pageSize : 10)
        // }
        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }
        return httpClient
            .get(porxyApi + url)
            .then((res) => {
                dispatch(setWorkRecordList(res.data.list))
                return res.data;
            })
            .catch(err => {
                let msg = `查询作业记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const setCurrentAppointment = createAction('@@xcloud/operation/setCurrentAppointment');

export const setCurrentPermit = createAction('@@xcloud/operation/setCurrentPermit');

export const setWorkOrderList = createAction('@@xcloud/operation/setWorkOrderList');

export const setWorkRecordList = createAction('@@xcloud/operation/setWorkRecordList');

export const setPageSize = createAction('@@xcloud/operation/setPageSize');

export const setWorkOrderChange = createAction('@@xcloud/operation/setWorkOrderChange');


export const createSolution = createAction('@@xcloud/operation/createSolution',
    (orderId, data, callback) => (dispatch, getState, httpClient) => {
        let url = `/api/workorders/${orderId}/solutions`;

        return httpClient.post(url, data)
            .then((res) => {
                if (res.status === 201) {
                    if (callback) callback();
                } else {
                    // 删除minio中文件
                    Promise.all([
                        dispatch(removeObject(data.solutionDoc.mybucket, data.solutionDoc.docLink))
                    ]).then((res) => {
                        if (callback) callback();
                    })
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建作业方案失败';
                // 删除minio中文件
                dispatch(removeObject(data.solutionDoc.mybucket, data.solutionDoc.docLink));
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const changeSolutionStatus = createAction('@@xcloud/operation/changeSolutionStatus',
    (solution, status, callback) => (dispatch, getState, httpClient) => {
        let dto = {
            solutionId: solution.solutionId,
            orderId: solution.orderId,
            status: status,
        }
        if (solution.solutionDoc && solution.solutionDoc.docId) {
            dto.solutionDoc = {
                docId: solution.solutionDoc.docId,
                status: status,
            }
        }

        let url = `/api/workorders/${solution.orderId}/solutions`;;
        return httpClient.patch(url, dto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(createMessage('success', '更新作业方案完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '更新作业方案失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const updateSolution = createAction('@@xcloud/operation/changeSolutionStatus',
    (orderId, data, callback) => (dispatch, getState, httpClient) => {
        let url = `/api/workorders/${orderId}/solutions`;;
        return httpClient.patch(url, data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(cleanloading());
                    if (callback) callback(res.data);
                    dispatch(createMessage('success', '更新作业方案完成'));
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '更新作业方案失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    }
);

export const installaionReducer = handleActions(
    {
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [setWorkOrderList]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderList: value
            };
        },
        [setWorkRecordList]: (state, { payload: value }) => {
            return {
                ...state,
                workRecordList: value
            };
        },
        [getDicSource]: (state, { payload: value }) => {
            return {
                ...state,
                sourceList: value.list,
                sourceTree: value.tree
            };
        },
        [getDicOrderType]: (state, { payload: value }) => {
            return {
                ...state,
                orderTypeList: value.list,
                orderTypeTree: value.tree,
                orderTypeLeafTree: value.leafTree
            };
        },
        [setWorkOrderChange]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderChange: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setNewWorkOrders]: (state, { payload: value }) => {
            return {
                ...state,
                newWorkOrders: value
            };
        },
        [setAppointmentDetails]: (state, { payload: value }) => {
            return {
                ...state,
                appointmentDetails: value
            };
        },
        [setNewWorkOrderChange]: (state, { payload: value }) => {
            return {
                ...state,
                newWorkOrderChange: value
            };
        },
        [setNewAppointmentChange]: (state, { payload: value }) => {
            return {
                ...state,
                newAppointmentChange: value
            };
        },
        [setCurrentWorkOrder]: (state, { payload: value }) => {
            return {
                ...state,
                currentWorkOrder: value
            };
        },
        [getSpecCode]: (state, { payload: value }) => {
            return {
                ...state,
                specCodeList: value.list,
                specCodeTree: value.tree
            };
        },
        [getTerminateType]: (state, { payload: value }) => {
            return {
                ...state,
                terminateTypeList: value.list
            };
        },

        [setAppointmentList]: (state, { payload: value }) => {
            return {
                ...state,
                appointmentList: value
            };
        },
        [setCurrentAppointment]: (state, { payload: value }) => {
            return {
                ...state,
                currentAppointment: value
            };
        },
        [setCurrentPermit]: (state, { payload: value }) => {
            return {
                ...state,
                currentPermit: value
            };
        },
        [setMasterBigPermit]: (state, { payload: value }) => {
            return {
                ...state,
                masterBigPermit: value
            };
        },
        [setMasterWorkOrder]: (state, { payload: value }) => {
            return {
                ...state,
                masterWorkOrder: value
            };
        },
        [setWorkorderWithBigPermitList]: (state, { payload: value }) => {
            return {
                ...state,
                workorderWithBigPermitList: value
            };
        },
        [setWorkOrderApprovers]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderApprovers: value
            };
        },
        [setWorkOrderApproversLast]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderApproversLast: value
            };
        },
        [setMasterJSADocs]: (state, { payload: value }) => {
            return {
                ...state,
                masterJSADocs: value
            };
        },
        [setMasterBigpermitDocs]: (state, { payload: value }) => {
            return {
                ...state,
                masterBigpermitDocs: value
            };
        },
        [setWorkOrderNotices]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderNotices: value
            };
        },
    },

    initialState
);

export default installaionReducer