import palette from '../palette';
// import typography from '../typography';

export default {
  root: {
    '& .MuiTab-wrapper': {
      color: palette.text.secondary,
    },
    '& .MuiTab-wrapper:hover': {
      color: palette.primary.main,
      opacity: 1,
    },
    "&$selected .MuiTab-wrapper": {
      color: palette.primary.main,
      fontWeight: 600,
    },
  },
  // selected: {
  //   color: palette.primary.main,
  //   fontWeight: 600
  // },
  wrapper: {
    color: palette.text.secondary,
  }

};
