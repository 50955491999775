import { getMoment as moment } from '@/utils/datetime';

/** 检查表模板名 */ export const INSPECTION_CHECKLIST_TEMPLATE = '检查表模板.xlsx';

/**
 * 当前检查表是否有效（根据有效期时间段判断）
 * @param {*} ivt 检查表
 * @returns 检查表有效
 */
export function inventoryValid(ivt) {
    const nowMoment = moment()();
    let valid = true;
    if (ivt.startTime && moment()(ivt.startTime).isAfter(nowMoment, 'day')) {
        valid = false;
    }
    if (ivt.endTime && moment()(ivt.endTime).isBefore(nowMoment, 'day')) {
        valid = false;
    }
    return valid;
}