import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { backToList, initMajorHazardDoc, createMajorHazardDoc, changeMajorHazardDocStatus, setMajorHazardDocPageError } from '../../redux/modules/majorHazard';
import MajorHazardDoc from '../../components/MajorHazardSources/MajorHazardDoc';
import { cleanmodal, createmodal } from '../../redux/modules/modal';
import { getNowTimestamp } from "../../utils/datetime";
import { upload, download, downloadURL } from '../../redux/modules/minio';
import { openConfirmDialog } from '../../redux/modules/confirmDialog';
import * as _ from 'lodash';
import { createMessage } from '../../redux/modules/message';
import { FilePreviewer } from '@/components/SimpleComponents'

class MajorHazardDocContainer extends Component {
    static propTypes = {
        userProfile: PropTypes.object,
        majorHazardDoc: PropTypes.array,
        majorHazardDocPage: PropTypes.object,
        doctype: PropTypes.array,

    }

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            routeType: null,
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            rowData: {}
        }
    }

    componentWillMount() {
        let id = _.toInteger(this.props.match.params.id);
        let routeType = '';
        if (this.props.location.pathname.indexOf("/majorhazardsources/list") !== -1) {
            routeType = 'list';
        } else if (this.props.location.pathname.indexOf("/majorhazardsources/detection") !== -1) {
            routeType = 'detection';
        }
        this.setState({
            id: id,
            routeType: routeType
        })
        this.props.onhandleInitMajorHazardDoc(id)
    }

    handleDelete = async (rowData) => {
        let changeStatus = () => {
            let tenantId = this.props.userProfile.roles[0].tenantId
            let envCode = 'dev';
            if (window.location.host.indexOf('3050') > -1) {
                envCode = 'dev';
            } else if (window.location.host.indexOf('3060') > -1) {
                envCode = 'uat';
            } else if (process.env.NODE_ENV === 'production') {
                envCode = 'prod';
            }
            let mybucket = _.replace(envCode + '.{tenant_id}.majorhazard.majorhazarddoc', '{tenant_id}', tenantId);
            this.props.onhandleChangeStatus(rowData, mybucket, () => this.props.onhandleInitMajorHazardDoc(this.state.id));
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除评估报告[' + rowData.docName + ']吗?', changeStatus, null, false);
    }

    handleBack = (event) => {
        this.props.onhandleBackToList(this.state.routeType);
    };

    trimUnitList() {
        let data = _.filter(this.props.unitList, function (unit) {
            return unit.pcode !== null
        });
        return data
    }

    // eslint-disable-next-line class-methods-use-this
    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        let fileTypes = '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg'.split(',')
        let index = _.findIndex(fileTypes, function (o) { return o === fileType; });
        if (index !== -1) {
            return files;
        } else {
            this.props.onhandleUploadPre()
        }
    }

    handleCheck = () => {

        let err = {};

        if (_.trim(this.props.majorHazardDocPage.docName) === '') {
            err['docName'] = '文档名称不能为空';
        } else {
            err['docName'] = '';
        }

        if (_.trim(this.props.majorHazardDocPage.docType) === '') {
            err['docType'] = '文档类型不能为空';
        } else {
            err['docType'] = '';
        }

        let newError = {
            ...this.props.majorHazardDocPageError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    async onConfirm(files, isUploadSetter, values) {
        await this.handleCheck();
        if (_.findIndex(Object.values(this.props.majorHazardDocPageError), function (o) { return o !== ''; }) < 0) {
            let tenantId = this.props.userProfile.roles[0].tenantId
            isUploadSetter(true);
            let envCode = 'dev';
            if (window.location.host.indexOf('3050') > -1) {
                envCode = 'dev';
            } else if (window.location.host.indexOf('3060') > -1) {
                envCode = 'uat';
            } else if (process.env.NODE_ENV === 'production') {
                envCode = 'prod';
            }
            let mybucket = _.replace(envCode + '.{tenant_id}.majorhazard.majorhazarddoc', '{tenant_id}', tenantId);
            let offset = getNowTimestamp();

            this.props.onhandleUploadDoc(files, mybucket, offset, () => {
                let doc = {
                    // docId: 0,
                    hazardId: this.state.id,
                    docName: this.props.majorHazardDocPage.docName,
                    docDesc: this.props.majorHazardDocPage.docDesc ? this.props.majorHazardDocPage.docDesc : undefined,
                    docLink: offset + files[0].name,
                    docType: this.props.majorHazardDocPage.docType,
                };
                this.props.onhandleCreateDoc(doc, () => this.props.onhandleInitMajorHazardDoc(this.state.id));
                this.props.onhandleModalClose();
            });
        }
    }

    // handleDownload(rowData) {
    //     let tenantId = this.props.userProfile.roles[0].tenantId
    //     let envCode = 'dev';
    //     if (window.location.host.indexOf('3050') > -1) {
    //         envCode = 'dev';
    //     } else if (window.location.host.indexOf('3060') > -1) {
    //         envCode = 'uat';
    //     } else if (process.env.NODE_ENV === 'production') {
    //         envCode = 'prod';
    //     }
    //     let mybucket = _.replace(envCode + '.{tenant_id}.majorhazard.majorhazarddoc', '{tenant_id}', tenantId);
    //     this.props.onhandleDownload(rowData.docLink, mybucket);
    // }

    handleDownload() {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = 'dev';
        let rowData = this.state.rowData;
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.majorhazard.majorhazarddoc', '{tenant_id}', tenantId);
        this.props.onhandleDownload(rowData.docLink, mybucket);
    }

    async handlePreview(rowData) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.majorhazard.majorhazarddoc', '{tenant_id}', tenantId);
        return this.props.onhandleDownloadURL(rowData.docLink, mybucket)
            .then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: rowData.docLink.split('.')[1]
                        },
                        rowData: rowData
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
            children: 'MajorHazardDoc'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    render() {
        const { previewFile } = this.state;
        return (
            <div>
                <MajorHazardDoc
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    userProfile={this.props.userProfile}
                    majorHazardDoc={this.props.majorHazardDoc}
                    majorHazard={this.props.majorHazard}
                    dangerLevelList={this.props.dangerLevelList}
                    onOpenUploadModal={this.openUploadModal.bind(this)}
                    doctype={this.props.doctype}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    routeType={this.state.routeType}
                />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onDownload={this.handleDownload.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        majorHazardDoc: state.majorHazard.majorHazardDoc,
        majorHazardDocPage: state.majorHazard.majorHazardDocPage,
        doctype: state.devices.doctype,
        majorHazardDocPageError: state.majorHazard.majorHazardDocPageError,
        majorHazard: state.majorHazard.majorHazard,
        dangerLevelList: state.majorHazard.dangerLevelList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleBackToList: (routeType) => {
            dispatch(backToList(routeType))
        },
        onhandleInitMajorHazardDoc: (id) => {
            dispatch(initMajorHazardDoc(id))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(createMajorHazardDoc(doc, callback));
        },
        onhandleChangeStatus: (entity, mybucket, callback) => {
            dispatch(changeMajorHazardDocStatus(entity, mybucket, callback));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleUploadPre: () => {
            dispatch(createMessage('error', '上传的文件格式不支持'));
        },
        onhandleSetError: (err) => {
            dispatch(setMajorHazardDocPageError(err))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MajorHazardDocContainer);