import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Regulation from '../../../components/LeadershipManagement/Regulation';
import RegulationAddPannel from "./RegulationAddPannel";
import {
    openPannel,
    initRegulationHeader,
    setSearch,
    resetSearch,
    getRegulations,
    addRegulation,
    deleteRegulation,
    updateRegulation,
    closePannel,
    getRegulationVersions,
    createRegulationVersion,
    updateRegulationVersion
} from '../../../redux/modules/regulation';
import { getBusinessUnitsByRole, getDepartmentsByRole } from "../../../utils/userUtils";
import { getNowTimestamp } from "../../../utils/datetime";
import { cleanmodal, createmodal } from '../../../redux/modules/modal';
import { upload, download, downloadURL } from '../../../redux/modules/minio';
import _ from 'lodash';
import { FilePreviewer } from '@/components/SimpleComponents';
import { getEnvCode } from "../../../utils/exportfile";
import { getRole } from "@/utils/userUtils"
//import { openRegulationUploadPannel } from "../../redux/modules/regulationUpload";
class RegulationPannelContainer extends Component {
    static propTypes = {
        regulations: PropTypes.array,
        currentUserInfo: PropTypes.object,
        changeCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }
    constructor(props) {
        super(props);
        this.state = {
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            regId: ''
        };

    };

    componentDidMount() {
        const { search, onhandleInitRegulationHeader } = this.props
        onhandleInitRegulationHeader(search);
    }

    getBucketName() {
        const { currentUserInfo } = this.props
        const bucketName = _.replace(getEnvCode() + '.{tenant_id}.leadership.regulation-doc', '{tenant_id}', currentUserInfo.tenantId);
        return bucketName
    }

    handleAdd(regulation) {
        const { currentUserInfo, onhandleAdd, onhandleGetRegulations } = this.props;
        // final execute function from other component or redux-action
        regulation['companyId'] = currentUserInfo.companyId;
        regulation['tenantId'] = currentUserInfo.tenantId;
        regulation['creator'] = currentUserInfo.userId;
        regulation['regId'] = 0;
        regulation['revisionCycle'] = parseInt(regulation.revisionCycle);
        onhandleAdd(regulation, (res) => {
            // let newRegulation = {
            //     companyId: res.companyId,
            //     tenantId: res.tenantId,
            //     regId: res.regId,
            //     status: "active",
            //     revisor: currentUserInfo.userId
            // }
            onhandleGetRegulations(this.props.search)
            // onhandleUpdate(newRegulation, res.regId, () => {
            // })
        })
    }

    handleUpdate(regulation) {
        const { currentUserInfo, onhandleUpdate, onhandleGetRegulations } = this.props;
        // final execute function from other component or redux-action
        let newRegulation = {
            companyId: regulation.companyId,
            tenantId: regulation.tenantId,
            revisor: currentUserInfo.userId,
            regId: regulation.regId,
            regName: regulation.regName,
            revisionCycle: regulation.revisionCycle,
            revisionUnit: regulation.revisionUnit,
            unitId: regulation.unitId,
            mandaReg: regulation.mandaReg,
            regNo: regulation.regNo
        }
        onhandleUpdate(newRegulation, regulation.regId, () => {
            onhandleGetRegulations(this.props.search)
        })
    }

    handleDelete(regulation) {
        const { currentUserInfo, onhandleDelete, onhandleGetRegulations } = this.props
        let newRegulation = {
            companyId: regulation.companyId,
            tenantId: regulation.tenantId,
            regId: regulation.regId,
            status: "inactive",
            revisor: currentUserInfo.userId
        }
        onhandleDelete(newRegulation, regulation.regId, () => {
            onhandleGetRegulations(this.props.search)
        })
    }
    handleOpenRegulationAddPannel() {
        const { onhandleOpenRegulationAddPannel } = this.props
        onhandleOpenRegulationAddPannel(this.handleAdd.bind(this))
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }
    // eslint-disable-next-line class-methods-use-this
    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        return files;
    }

    handleDownload() {
        let regId = this.state.regId
        const { onhandleGetRegulationVersion, onhandleDownloadRegulation } = this.props
        onhandleGetRegulationVersion(regId, (versions) => {
            if (versions) {
                let versionArr = versions.filter((version) => {
                    return version.status === 'new'
                })
                if (versionArr && versionArr.length !== 0) {
                    let fileName = versionArr[0].docLink;
                    onhandleDownloadRegulation(fileName, this.getBucketName());
                }
            }
        })

    }


    async handlePreview(regId) {
        const { onhandleGetRegulationVersion } = this.props
        onhandleGetRegulationVersion(regId, (versions) => {
            if (versions) {
                let versionArr = versions.filter((version) => {
                    return version.status === 'new'
                })
                if (versionArr && versionArr.length !== 0) {
                    let fileName = versionArr[0].docLink;
                    return this.props.onhandleDownloadUrl(fileName, this.getBucketName()).then(res => {
                        if (res && res.payload) {
                            console.log(' res.payload', res.payload);
                            this.setState({
                                previewFile: {
                                    open: true,
                                    fileURL: res.payload,
                                    fileType: fileName.split('.')[1]
                                },
                                regId: regId
                            })
                            return res.payload;
                        } else {
                            // 不做什么
                        }
                    });
                }
            }
        })
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    onConfirm(files, isUploadSetter, values) {
        isUploadSetter(true);
        // 开始自定义上传操作

        const that = this;
        const {
            onhandleGetRegulationVersion,
            onhandleUpdateVersion,
            onhandleUploadRegulation
        } = that.props
        let offset = getNowTimestamp();
        onhandleUploadRegulation(files, this.getBucketName(), offset, () => {
            onhandleGetRegulationVersion(values.paraA, (versions) => {
                if (versions) {
                    let versionArr = versions.filter((version) => {
                        return version.status === 'new'
                    })
                    if (versionArr && versionArr.length !== 0) {
                        let updateVersion = {
                            "versionId": versionArr[0].versionId,
                            "status": "inactive",
                            "tenantId": versionArr[0].tenantId,
                            "versionOrder": 3
                        }
                        onhandleUpdateVersion(versionArr[0].versionId, updateVersion)
                    }
                }
                //let arr = files[0].name.split('.');
                let doc = {
                    "versionId": 0,
                    "regId": values.paraA,
                    "versionOrder": 1,
                    "docLink": offset + files[0].name
                };
                that.props.onhandleCreateDoc(doc, () => {
                    this.props.onhandleGetRegulations(this.props.search)
                });
                that.props.onhandleModalClose();
            })
        });
    }
    openUploadModal(regId, regName) {
        // 调用上传组件示例
        this.props.onhandleOpenFileUpload({
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.jpeg,.jpg,.png,.docx,.xlsx,.pdf',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: true,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
            docName: regName,
            versionOrder: 1,
            paraA: regId
        });
    }


    handleSearch(event) {
        this.props.onhandleGetRegulations({ ...this.props.search });
    }

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'unitId':
                newSearch = {
                    ...this.props.search,
                    unitId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    render() {
        const { previewFile } = this.state;
        const roleW = this.props.roleRoutes.find(ele => ele.route === 'regulation').action === 'W';

        return (
            <div>
                <Regulation
                    open={this.props.open}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    departments={this.props.departments}
                    regulations={this.props.regulations}
                    roleW={roleW}
                    search={this.props.search}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    onOpenUploadModal={this.openUploadModal.bind(this)}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    onhandleOpenRegulationAddPannel={this.handleOpenRegulationAddPannel.bind(this)}
                />
                <RegulationAddPannel />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onDownload={this.handleDownload.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.regulation.open,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        search: state.regulation.search,
        regulations: state.regulation.regulations,
        confirmCallback: state.regulation.confirmCallback,
        changeCallback: state.regulation.changeCallback,
        closeCallback: state.regulation.closeCallback,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        onhandleGetRegulations: (query) => {
            dispatch(getRegulations(query))
        },
        onhandleInitRegulationHeader: (query, reload) => {
            dispatch(initRegulationHeader(query, reload));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSearch: () => {
            dispatch(resetSearch());
        },
        onhandleAdd: (regulation, callback) => {
            dispatch(addRegulation(regulation, callback));
        },
        onhandleUpdate: (regulation, regId, callback) => {
            dispatch(updateRegulation(regulation, regId, callback));
        },
        onhandleDelete: (regulation, regId, callback) => {
            dispatch(deleteRegulation(regulation, regId, callback));
        },
        onhandleClose: () => {
            dispatch(closePannel());
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('fileUpload', config));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(createRegulationVersion(doc, callback));
        },
        onhandleGetRegulationVersion: (regId, callback) => {
            dispatch(getRegulationVersions(regId, callback))
        },
        onhandleUpdateVersion: (versionId, regulationVersion) => {
            dispatch(updateRegulationVersion(versionId, regulationVersion));
        },
        onhandleUploadRegulation: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleDownloadRegulation: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleOpenRegulationAddPannel: (confirmCallback, closeCallback) => {
            dispatch(openPannel(confirmCallback, closeCallback))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPannelContainer);