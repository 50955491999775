import React from 'react';
import {
    Grid, Typography,  Card, TextField, MenuItem, Button, Tooltip, IconButton
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import * as _ from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import TreeSelect from 'rc-tree-select';
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';

const NoticeConfigTarget = props => {
    const { classes,
        currentConfig,
        targets,
        departmentTree,
        departmentList,
        editingRow,
        setEditingRow,
        roleTree,
        roleList,
        companies,
        onhandleDelete,
        onhandleCreateTarget,
        onhandleBack,
        onhandleSaveTarget,
        onhandleSelectUser,
        saveEditRow,
        cancelEditRow
    } = props;
    
    const renderChannel = (row) => {
        if (row.noticeChannel === 1) {
            return '网页端';
        } else if (row.noticeChannel === 2) {
            return '手机端';
        } else {
            return '全部';
        }
    }

    const assemblyData = () => {
        return targets;
    }

    const renderLvl = (lvl) => {
        if (lvl === 1) {
            return '单位'
        } else if (lvl === 2) {
            return '部门'
        } else if (lvl === 4) {
            return '角色'
        } else if (lvl === 6) {
            return '用户'
        }
    }

    const renderDepartmentTree = (row) => {
        if (row.targetId) {
            let dpt = _.find(departmentList, { 'dptId': row.targetId })
            return dpt ? { label: dpt.dptName, value: dpt.dptId } : undefined
        } else {
            return undefined
        }
    }

    const renderRoleTree = (row) => {
        if (row.targetId) {
            let role = _.find(roleList, { 'code': row.targetId })
            return role ? { label: role.name, value: row.targetId } : undefined
        } else {
            return undefined
        }
    }

    const renderTargetEdit = (row) => {
        if (row.targetLvl === 1) {
            return (
                <TextField
                    style={{ width: '90%' }}
                    select
                    value={editingRow.targetId || 0}
                    onChange={(event) => {
                        let com = _.find(companies,{'companyId': event.target.value})
                        let newData = {
                            ...editingRow,
                            targetId: event.target.value,
                            targetName: com.companyName
                        }
                        setEditingRow(newData)
                    }}
                    variant="standard"
                >
                    {companies.map(ele => (
                        <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                    ))}
                </TextField>
            )
        } else if (row.targetLvl === 2) {
            return (
                <TreeSelect
                    showArrow
                    style={{ width: '90%' }}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                    allowClear
                    treeLine
                    value={renderDepartmentTree(editingRow)}
                    labelInValue
                    treeData={departmentTree}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => {
                        let newData = {
                            ...editingRow,
                            targetId: value.value,
                            targetName: value.label
                        }
                        setEditingRow(newData)
                    }}
                />
            )
        } else if (row.targetLvl === 4) {
            return (
                <TreeSelect
                    showArrow
                    style={{ width: '90%' }}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                    allowClear
                    treeLine
                    value={renderRoleTree(editingRow)}
                    labelInValue
                    treeData={roleTree}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => {
                        let newData = {
                            ...editingRow,
                            targetId: value.value,
                            targetName: value.label
                        }
                        setEditingRow(newData)
                    }}
                />
            )
        } else if (row.targetLvl === 6) {
            return (
                <TextField
                    style={{ width: '90%' }}
                    variant="standard"
                    InputProps={{
                        readOnly: true,
                    }}
                    onClick={event => onhandleSelectUser(event)}
                    value={editingRow.targetName || ''}
                >
                </TextField>
            )
        }
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '对象类型',
                dataIndex: 'targetLvl',
                key: 'targetLvl',
                width: '45%',
                render: (text, row, index) => row.editable ? <TextField
                    style={{ width: '90%' }}
                    select
                    value={editingRow.targetLvl || 1}
                    onChange={(event) => {
                        let newData = {
                            ...editingRow,
                            targetLvl: event.target.value,
                            targetId: 0,
                            targetName: ''
                        }
                        setEditingRow(newData)
                    }}
                    variant="standard"
                >
                    <MenuItem key={1} value={1}>{'单位'}</MenuItem>
                    <MenuItem key={2} value={2}>{'部门'}</MenuItem>
                    <MenuItem key={4} value={4}>
                        {'角色'}
                    </MenuItem>
                    <MenuItem key={6} value={6}>
                        {'用户'}
                    </MenuItem>
                </TextField> : <Typography noWrap>{renderLvl(row.targetLvl)}</Typography>,
                fixed: 'left',
                ellipsis: false
            },
            {
                title: '对象名称',
                dataIndex: 'target',
                key: 'target',
                width: '45%',
                render: (text, row, index) => row.editable ? renderTargetEdit(editingRow) : <Typography noWrap>{row.targetName}</Typography>,
                fixed: 'left',
                ellipsis: false
            },
            {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: '10%',
                render: (text, row, index) => renderActions(row),
            }
        ];

        return columns;
    }

    const renderActions = (row) => {
        if (editingRow.targetLvl !== undefined) {
            if (editingRow.rowId === row.rowId) {
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='保存'><IconButton variant='text' onClick={() => saveEditRow(editingRow)}> <SaveIcon fontSize='small' /></IconButton></Tooltip></Grid>
                    <Grid item><Tooltip title='取消'><IconButton variant='text' onClick={() => cancelEditRow(editingRow)}> <CancelIcon fontSize='small' /></IconButton></Tooltip></Grid>
                </Grid>
            } else {
                return <Grid container alignItems='center'></Grid>
            }
        } else {
            return <Grid container alignItems='center'>
                <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => onhandleDelete(row)}> <DeleteIcon fontSize='small' /></IconButton></Tooltip></Grid>
            </Grid>
        }

    }

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <div>
                    <div>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            {'通知对象'}
                        </Typography>
                    </div>
                    <div>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {'名称'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {currentConfig.noticeName || ''}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {'编码'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {currentConfig.noticeCode || ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {'渠道'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderChannel(currentConfig)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {'描述'}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {currentConfig.noticeDesc || ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container spacing={0} className={classes.gridContainer} alignItems={'center'}>
                            <Grid item>
                                <Button variant="contained" color="primary" style={{ marginRight: 20 }}
                                    onClick={() => {
                                        let newData = {
                                            rowId: targets.length === 0 ? 0 : _.maxBy(targets, 'rowId').rowId + 1,
                                            targetLvl: 1,
                                            targetId: 0,
                                            targetName: '',
                                            editable: true
                                        }
                                        setEditingRow(newData)
                                        onhandleCreateTarget(newData)
                                    }}
                                >{'新增'}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" style={{ marginRight: 20 }}
                                    onClick={onhandleSaveTarget}
                                >{'保存'}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary"
                                    onClick={onhandleBack}
                                >{'返回'}</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <Grid className={classes.detailtable}>
                    <Table
                        className={classes.stripedTable}
                        // rowKey={record => (record.noticeId + '_' + Date.now())} // 保持刷新
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        pagination={false}
                        size={'small'}
                        tableLayout={'fixed'}
                    />
                </Grid>
            </Card>
        </div >
    );
}

NoticeConfigTarget.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(NoticeConfigTarget);