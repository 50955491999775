import React, { Component } from 'react';
import { connect } from 'react-redux';
import RuleSetup from '../../../components/DeviceManagement/OperationRule/RuleDeviceSetup'
import {
    backToRuleEdit,
    setDeviceCatSelection,
    setDeviceExtCatSelection,
    updateRuleTargets,
    getRuleTargets,
    getRuleTargetExcepts,
    setObjectPageSize,
    setObjectExceptPageSize
} from "../../../redux/modules/operationRule";
import PropTypes from "prop-types";
import { findPath } from '../../../utils/constant';

class RuleSetupContainer extends Component {

    static propTypes = {
        currentRule: PropTypes.object,
        devCatSelection: PropTypes.object,
        devCatTree: PropTypes.array,
        ruleTargets: PropTypes.object,
        devicelist: PropTypes.object,
        readonly: PropTypes.bool,
        objectPageSize: PropTypes.number,
        objectExceptPageSize: PropTypes.number,
        companyList: PropTypes.array,
        extcatTree: PropTypes.array,
    };

    handleBack = (event) => {
        this.props.onhandleBackToRuleEdit();
    };

    handleSelectionChange(value, type) {
        switch (type) {
            case 'cat':
                if (!value) {
                    this.props.onhandleSetDevCatSelection(undefined);
                    return;
                }
                let path = findPath(value.value, this.props.devCatTree)
                    .map(i => i.label)
                    .reverse()
                    .join(' > ');
                this.props.onhandleSetDevCatSelection({
                    label: path,
                    value: value.value
                });
                this.props.onhandleSelectDevices({ offset: 0 });
                break;
            case 'extCat':
                if (!value) {
                    this.props.onhandleSetDeviceExtCatSelection(undefined);
                    return;
                }
                let newPath = findPath(value.value, this.props.extcatTree)
                    .map(i => i.label)
                    .reverse()
                    .join(' > ');
                this.props.onhandleSetDeviceExtCatSelection({
                    label: newPath,
                    value: value.value
                });
                this.props.onhandleSelectDevices({ offset: 0 });
                break;
            default:
            // do nothing
        }
    }

    handleCheckChange = (value, isAdd) => {
        this.props.onhandleCheckChange(value, isAdd)
    }

    handleSelectDevices = (query) => {
        this.props.onhandleSelectDevices(query)
    }

    render() {
        return (
            <RuleSetup
                currentRule={this.props.currentRule}
                onhandleBack={this.handleBack.bind(this)}
                onhandleSelectionChange={this.handleSelectionChange.bind(this)}
                onhandleSelectDevices={this.handleSelectDevices.bind(this)}
                onhandleCheckChange={this.handleCheckChange.bind(this)}
                onhandleGetRuleTargets={this.props.onhandleGetRuleTargets.bind(this)}
                devCatTree={this.props.devCatTree}
                devCatSelection={this.props.devCatSelection}
                devExtCatSelection={this.props.devExtCatSelection}
                ruleTargets={this.props.ruleTargets}
                devicelist={this.props.devicelist}
                readonly={this.props.readonly}
                objectPageSize={this.props.objectPageSize}
                objectExceptPageSize={this.props.objectExceptPageSize}
                onhandleSetObjectPageSize={this.props.onhandleSetObjectPageSize.bind(this)}
                onhandleSetObjectExceptPageSize={this.props.onhandleSetObjectExceptPageSize.bind(this)}
                companyList={this.props.companyList}
                extcatTree={this.props.extcatTree}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentRule: state.operationRule.currentRule,
        devCatSelection: state.operationRule.devCatSelection,
        devCatTree: state.trees.deviceCategoryTree,
        ruleTargets: state.operationRule.ruleTargets,
        devicelist: state.operationRule.ruleTargetExcepts,
        readonly: state.operationRule.readonly,
        objectPageSize: state.operationRule.objectPageSize,
        objectExceptPageSize: state.operationRule.objectExceptPageSize,
        companyList: state.constant.companies,
        extcatTree: state.devices.extcatTree,
        devExtCatSelection: state.operationRule.devExtCatSelection,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSetDevCatSelection: (val) => {
            dispatch(setDeviceCatSelection(val))
        },
        onhandleSetDeviceExtCatSelection: (val) => {
            dispatch(setDeviceExtCatSelection(val))
        },
        onhandleBackToRuleEdit: () => {
            dispatch(backToRuleEdit())
        },
        onhandleGetRuleTargets: (query) => {
            dispatch(getRuleTargets(query))
        },
        onhandleSelectDevices: (query) => {
            dispatch(getRuleTargetExcepts(query))
        },
        onhandleCheckChange: (value, isAdd) => {
            dispatch(updateRuleTargets(value, isAdd))
        },
        onhandleSetObjectPageSize: (value) => {
            dispatch(setObjectPageSize(value))
        },
        onhandleSetObjectExceptPageSize: (value) => {
            dispatch(setObjectExceptPageSize(value))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleSetupContainer)
