import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Typography,
    InputAdornment,
    Button,
    IconButton,
    TextField,
    Grid,
    FormHelperText,

} from "@material-ui/core";
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Prompt } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import TreeSelect from 'rc-tree-select';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';

const DeviceBuild = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        // onhandleBack,
        onhandleCheck,
        currentDevice,
        deviceBuild,
        deviceDesign,
        deviceBuildError,
        deviceBuildChange,
        deviceDesignChange,
        tabValue,
        index,
        isView,
        onhandleDateChange,
        deviceDoc,
        onhandleSelectChange,
        isDeviceEdit,
        userProfile,
        onhandleDownload
    } = props;

    const getDeviceDoc = () => {
        if (deviceDoc && deviceDoc.length !== 0) {
            let data = _.map(deviceDoc, t => {
                return {
                    value: t.docId,
                    label: t.docName,
                }
            })
            return data
        } else {
            return []
        }
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }

    return (
        <div>
            <div className={classes.card} hidden={tabValue !== index}>

                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'投用信息'}
                    </Typography>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={12}>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>投用时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='enabletimeselect'
                                    value={currentDevice.enableTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'enableTime')}

                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'enableTime')}
                                                    disabled={Boolean(!currentDevice.enableTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>闲置时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='idleTimeselect'
                                    value={currentDevice.idleTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'idleTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'idleTime')}
                                                    disabled={Boolean(!currentDevice.idleTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {deviceBuildError.idleTime !== '' ? <FormHelperText
                                error={true}
                            >
                                {deviceBuildError.idleTime}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>停用时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='downTimeselect'
                                    value={currentDevice.downTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'downTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'downTime')}
                                                    disabled={Boolean(!currentDevice.downTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {deviceBuildError.downTime !== '' ? <FormHelperText
                                error={true}
                            >
                                {deviceBuildError.downTime}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>报废时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='abandonTimeselect'
                                    value={currentDevice.abandonTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'abandonTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'abandonTime')}
                                                    disabled={Boolean(!currentDevice.abandonTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {deviceBuildError.abandonTime !== '' ? <FormHelperText
                                error={true}
                            >
                                {deviceBuildError.abandonTime}
                            </FormHelperText> : null}
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>下次维修时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='abandonTimeselect'
                                    value={currentDevice.nextMaintainDate}
                                    disabled={true}
                                    onChange={value => onhandleDateChange(value, 'nextMaintainDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'nextMaintainDate')}
                                                    disabled={Boolean(!currentDevice.nextMaintainDate)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>下次检测时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='abandonTimeselect'
                                    value={currentDevice.nextTestDate}
                                    disabled={true}
                                    onChange={value => onhandleDateChange(value, 'nextTestDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'nextTestDate')}
                                                    disabled={Boolean(!currentDevice.nextTestDate)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'施工信息'}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={12}>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>监理单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.supervisor !== ''}
                                helperText={deviceBuildError.supervisor || ''}
                                className={classes.textField}
                                value={deviceBuild.supervisor || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'supervisor')}
                                onBlur={event => onhandleCheck(event, 'supervisor')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>施工单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.constructor !== ''}
                                helperText={deviceBuildError.constructor || ''}
                                className={classes.textField}
                                value={deviceBuild.constructor || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'constructor')}
                                onBlur={event => onhandleCheck(event, 'constructor')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>开工时间</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    error={deviceBuildError.startTime !== ''}
                                    helperText={deviceBuildError.startTime || ''}
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='tenabletimeselect'
                                    value={deviceBuild.startTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'startTime')}
                                    onBlur={event => onhandleCheck(event, 'startTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'startTime')}
                                                    disabled={Boolean(!deviceBuild.startTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>


                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>竣工时间</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    error={deviceBuildError.endTime !== ''}
                                    helperText={deviceBuildError.endTime || ''}
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='tenabletimeselect'
                                    value={deviceBuild.endTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'endTime')}
                                    onBlur={event => onhandleCheck(event, 'endTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'endTime')}
                                                    disabled={Boolean(!deviceBuild.endTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={12} md={8}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>安装文档</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                inputVariant="outlined"
                                size="small"
                                value={deviceBuild.acceptDoc}
                                labelInValue
                                treeData={getDeviceDoc()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit || getDeviceDoc().length === 0}
                                onChange={(value) => onhandleSelectChange(value, 'acceptDoc')}
                            />
                            <IconButton onClick={event => onhandleDownload(deviceBuild.acceptDoc)} hidden={_.isEmpty(deviceBuild.acceptDoc)} aria-label="archive">
                                <ArchiveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>



                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'制造信息'}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={12}>



                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>制造商</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.manufacturer !== ''}
                                helperText={deviceBuildError.manufacturer || ''}
                                className={classes.textField}
                                value={deviceDesign.manufacturer || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'manufacturer')}
                                onBlur={event => onhandleCheck(event, 'manufacturer')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>出厂时间</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    error={deviceBuildError.deliveryTime !== ''}
                                    helperText={deviceBuildError.deliveryTime || ''}
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='tenabletimeselect'
                                    value={deviceDesign.deliveryTime}
                                    disabled={isView || isDeviceEdit}
                                    onChange={value => onhandleDateChange(value, 'deliveryTime')}
                                    onBlur={event => onhandleCheck(event, 'deliveryTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'deliveryTime')}
                                                    disabled={Boolean(!deviceDesign.deliveryTime)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>出厂编号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.serialNo !== ''}
                                helperText={deviceBuildError.serialNo || ''}
                                className={classes.textField}
                                value={deviceDesign.serialNo || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'serialNo')}
                                onBlur={event => onhandleCheck(event, 'serialNo')}
                            />
                            {/* {deviceDesign.infoId && deviceDesign.serialNo !== '' ? (
                                    <Typography className={classes.label}>
                                        {deviceDesign.serialNo}
                                    </Typography>
                                ) : (<TextField
                                    error={deviceBuildError.serialNo !== ''}
                                    helperText={deviceBuildError.serialNo || ''}
                                    className={classes.textField}
                                    value={deviceDesign.serialNo || ''}
                                    variant="standard"
                                    disabled={isView || isDeviceEdit}
                                    onChange={event => onhandleChange(event, 'serialNo')}
                                    onBlur={event => onhandleCheck(event, 'serialNo')}
                                />)
                                } */}
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify} title={'特种设备制造许可证编号'}>
                                制造许可证号
                            </span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.permitNo !== ''}
                                helperText={deviceBuildError.permitNo || ''}
                                className={classes.textField}
                                value={deviceDesign.permitNo || ''}
                                placeholder="特种设备制造许可证编号"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'permitNo')}
                                onBlur={event => onhandleCheck(event, 'permitNo')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>特种设备代码</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.specCode !== ''}
                                helperText={deviceBuildError.specCode || ''}
                                className={classes.textField}
                                value={deviceDesign.specCode || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'specCode')}
                                onBlur={event => onhandleCheck(event, 'specCode')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={12} md={8}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>制造文档</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceDesign.permitDoc}
                                labelInValue
                                treeData={getDeviceDoc()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit || getDeviceDoc().length === 0}
                                onChange={(value) => onhandleSelectChange(value, 'permitDoc')}
                            />
                            <IconButton onClick={event => onhandleDownload(deviceDesign.permitDoc)} hidden={_.isEmpty(deviceDesign.permitDoc)} aria-label="archive">
                                <ArchiveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'设计信息'}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={12}>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>设计单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.designer !== ''}
                                helperText={deviceBuildError.designer || ''}
                                className={classes.textField}
                                value={deviceDesign.designer || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'designer')}
                                onBlur={event => onhandleCheck(event, 'designer')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}></span>
                            <span className={classes.justify}>设计资质</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                // error={deviceBuildError.designer !== ''}
                                // helperText={deviceBuildError.designer || ''}
                                className={classes.textField}
                                // value={deviceDesign.designer || ''}
                                variant="outlined"
                                size='small'
                            // disabled={isView || isDeviceEdit}
                            // onChange={event => onhandleChange(event, 'designer')}
                            // onBlur={event => onhandleCheck(event, 'designer')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设计时间</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDatePicker
                                    autoOk
                                    className={classes.textField}
                                    disableToolbar
                                    openTo="year"
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    inputVariant="outlined"
                                    size="small"
                                    format="yyyy/MM/dd"
                                    name='abandonTimeselect'
                                    value={currentDevice.designDate}
                                    disabled={true}
                                    onChange={value => onhandleDateChange(value, 'designDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: (isView || isDeviceEdit) ? 'none' : '' }}
                                                    onClick={() => onhandleDateChange(null, 'designDate')}
                                                    disabled={Boolean(!currentDevice.designDate)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设计寿命</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceBuildError.designLife !== ''}
                                helperText={deviceBuildError.designLife || ''}
                                className={classes.textField}
                                value={deviceDesign.designLife || ''}
                                type="number"
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'designLife')}
                                onBlur={event => onhandleCheck(event, 'designLife')} InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            小时
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={12} md={8}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>设计文档</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceDesign.permitDoc}
                                labelInValue
                                treeData={getDeviceDoc()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit || getDeviceDoc().length === 0}
                                onChange={(value) => onhandleSelectChange(value, 'permitDoc')}
                            />
                            <IconButton onClick={event => onhandleDownload(deviceDesign.permitDoc)} hidden={_.isEmpty(deviceDesign.permitDoc)} aria-label="archive">
                                <ArchiveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

            </div>

            <div className={classes.card} hidden={tabValue !== index}>
                <Button variant="contained" color="primary" className={classes.button}
                    hidden={!checkPermission() || isView || isDeviceEdit}
                    disabled={!(deviceBuildChange || deviceDesignChange)}
                    onClick={event => onhandleSave(event)}
                >
                    保存
                </Button>
            </div>
            <Prompt message="数据未保存,确定要离开？" when={deviceBuildChange || deviceDesignChange} />
        </div >
    );
};

DeviceBuild.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    deviceBuild: PropTypes.object.isRequired,
    deviceBuildError: PropTypes.object.isRequired,
    deviceBuildChange: PropTypes.bool.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    isView: PropTypes.bool.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    deviceDoc: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onhandleDownload: PropTypes.func.isRequired,
};

export default withStyles(DeviceBuild);
