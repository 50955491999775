import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Fab,
    GridList,
    Grid,
    Typography,
    // CardMedia,
    // CardActions,
    GridListTile,
    GridListTileBar,
    IconButton,
    Dialog
} from '@material-ui/core';
import {
    GetApp,
    NavigateBefore,
    NavigateNext
} from "@material-ui/icons";

const AttachmentDocs = props => {
    const {
        classes,
        docs,
        onDownload,
        onhandleCancel,
        title
    } = props;

    const [currentDoc, setCurrentDoc] = useState(null);
    const [currentIndex, setcurrentIndex] = useState(null);
    const [open, setOpen] = useState(null);

    return (
        <>
            <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                {title}</Typography>

            <Grid container spacing={0} className={classes.gridContainer}>

                <GridList className={classes.gridList}>
                    {docs && docs.map((doc, index) => {
                        return (
                            <GridListTile key={doc.docId} className={classes.gridListTile} onClick={() => { setCurrentDoc(doc); setcurrentIndex(index); setOpen(true) }}>
                                {doc.docType === 'DOC_VIDEO' ?
                                    (
                                        // <video controller='true'>
                                        //     <source src={onhandleGetBlob(doc.url)}
                                        //         type="video/mp4"></source>
                                        // </video>
                                        <Grid container alignItems={'center'} justify={'center'} alignContent={'center'}>
                                            <grid item>
                                                <Typography variant='h1' align='center'>{'视频记录'}</Typography>
                                                <Typography variant='h2' align='center'>{'请下载后查看'}</Typography>
                                            </grid>
                                        </Grid>
                                    ) : (
                                        <img src={doc.url} alt={doc.downloadName ? doc.downloadName : doc.docName} style={{ width: '100%', height: '100%' }} />
                                    )
                                }

                                <GridListTileBar
                                    title={doc.downloadName ? doc.downloadName : doc.docName}
                                    alt={doc.downloadName ? doc.downloadName : doc.docName}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <IconButton className={classes.icon} onClick={() => { onDownload(doc) }}>
                                            <GetApp />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        )
                    })
                    }
                </GridList>
            </Grid >

            <Grid container spacing={1} className={classes.gridContainer} justify="flex-end">
                <Grid item>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleCancel(event)}
                    >
                        {'取消'}
                    </Button>
                </Grid>
            </Grid>

            {Boolean(currentDoc) &&
                <Dialog onClose={() => { setOpen(false) }} open={open} >
                    <Grid>
                        {currentIndex > 0 &&
                            <Fab size={'large'} className={classes.beforeButton} onClick={() => { setCurrentDoc(docs[currentIndex - 1]); setcurrentIndex(currentIndex - 1); }}>
                                <NavigateBefore />
                            </Fab>}
                        {currentDoc.docType === 'DOC_VIDEO' ?
                            (
                                // <video controller='true'>
                                //     <source src={onhandleGetBlob(doc.url)}
                                //         type="video/mp4"></source>
                                // </video>
                                <Grid container alignItems={'center'} justify={'center'} alignContent={'center'}>
                                    <grid item>
                                        <Typography variant='h1' align='center'>{'视频记录'}</Typography>
                                        <Typography variant='h2' align='center'>{'请下载后查看'}</Typography>
                                    </grid>
                                </Grid>
                            ) : (
                                <Grid style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                                    <img src={currentDoc.url} alt={currentDoc.downloadName ? currentDoc.downloadName : currentDoc.docName} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                            )
                        }
                        {currentIndex < docs.length - 1 &&
                            <Fab size={'large'} className={classes.nextButton} onClick={() => { setCurrentDoc(docs[currentIndex + 1]); setcurrentIndex(currentIndex + 1); }}>
                                <NavigateNext />
                            </Fab>}
                        <GridListTileBar
                            title={currentDoc.downloadName ? currentDoc.downloadName : currentDoc.docName}
                            alt={currentDoc.downloadName ? currentDoc.downloadName : currentDoc.docName}
                            className={classes.detailDownloadButton}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                <IconButton className={classes.icon} onClick={() => { onDownload(currentDoc) }}>
                                    <GetApp />
                                </IconButton>
                            }
                        />
                    </Grid>
                </Dialog>
            }
        </>
    );
};

AttachmentDocs.propTypes = {
    classes: PropTypes.object.isRequired,
    docs: PropTypes.array.isRequired,
    onDownload: PropTypes.func.isRequired,
    onhandleGetBlob: PropTypes.func.isRequired,
    onhandleCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(AttachmentDocs);
