import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTableRunParam, setDeviceRunParamChange, backToList, modifyDeviceRunParam, initDeviceRunParam } from '../../../redux/modules/devices';
import DeviceRunParam from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceRunParam';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';

class DeviceRunParamContainer extends Component {
    static propTypes = {
        unitList: PropTypes.array,
        logicList: PropTypes.array,
        runParam: PropTypes.array,
        currentDevice: PropTypes.object,
        valtypeList: PropTypes.array,
        tableRunParam: PropTypes.array,
        userProfile: PropTypes.object,
        deviceRunParamChange: PropTypes.bool,
        runParamTemp: PropTypes.array,
        isview: PropTypes.bool,
        runParamWarning: PropTypes.bool,

    }

    componentWillMount() {
        //获取设备的技术参数
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceRunParam()
        }
    }

    _generateTemplate = (template) => {
        if (template.logic1 && template.logic2) {
            return template.logic1 + '{$target=0}|' + template.logic2 + '{$target=0}'
        } else if (template.logic1) {
            return template.logic1 + '{$target=0}'
        } else {
            return null;
        }
    }

    _generateOffTemplate = (template) => {
        if (template.offLogic1 && template.offLogic2) {
            return template.offLogic1 + '{$target=0}|' + template.offLogic2 + '{$target=0}'
        } else if (template.offLogic1) {
            return template.offLogic1 + '{$target=0}'
        } else {
            return null;
        }
    }

    _generateValue = (template) => {
        if (template.vals1 && template.vals2) {
            return template.vals1 + ',' + template.vals2
        } else if (template.vals1) {
            return template.vals1
        } else {
            return null;
        }
    }

    _generateOffValue = (template) => {
        if (template.offVals1 && template.offVals2) {
            return template.offVals1 + ',' + template.offVals2
        } else if (template.offVals1) {
            return template.offVals1
        } else {
            return null;
        }
    }

    handleAdd(newData) {
        newData._id = uuidv4()
        newData.template = this._generateTemplate(newData)
        newData.vals = this._generateValue(newData)
        newData.offTemplate = this._generateOffTemplate(newData)
        newData.offVals = this._generateOffValue(newData)

        let value = _.cloneDeep(this.props.tableRunParam)
        value.push(newData)
        this.props.onhandleAdd(value)
        this.props.onhandleDeviceRunParamChange(true)
    }

    handleUpdate = async (newData) => {
        newData.template = this._generateTemplate(newData)
        newData.vals = this._generateValue(newData)
        newData.offTemplate = this._generateOffTemplate(newData)
        newData.offVals = this._generateOffValue(newData)

        let index = newData._id
        let value = _.cloneDeep(this.props.tableRunParam)
        for (let i = 0; i < value.length; i++) {
            if (value[i]._id === index) {
                value[i] = newData
            }
        }

        this.props.onhandleUpdate(value)
        this.props.onhandleDeviceRunParamChange(true)
    }

    handleDelete = async (newData) => {
        newData.template = this._generateTemplate(newData)
        newData.vals = this._generateValue(newData)
        newData.offTemplate = this._generateOffTemplate(newData)
        newData.offVals = this._generateOffValue(newData)
        let index = newData._id
        let value = _.cloneDeep(this.props.tableRunParam)
        let list = _.remove(value, function (n) {
            return n._id !== index;
        });
        this.props.onhandleDelete(list)
        this.props.onhandleDeviceRunParamChange(true)
    }

    handleSave = async (event) => {
        this.props.onhandleDeviceRunParamChange(false);
        this.props.onhandleUpdateDeviceRunParam()
    }

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleDialog = (event, row) => {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: this.props.currentDevice.companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let deviceName = this.props.selectedDevices[0].deviceName
                        let deviceId = this.props.selectedDevices[0].deviceId
                        row.sourceId = deviceId
                        row.sourceName = deviceName
                        let value = _.cloneDeep(this.props.tableRunParam)
                        this.props.onhandleUpdate(value)
                        this.props.onhandleDeviceRunParamChange(true)
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    render() {
        return (
            <div>
                <DeviceRunParam
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    tabValue={this.props.tabValue}
                    index={this.props.index}
                    unitList={this.props.unitList}
                    logicList={this.props.logicList}
                    runParam={this.props.runParam}
                    runParamTemp={this.props.runParamTemp}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleDialog={this.handleDialog.bind(this)}
                    userProfile={this.props.userProfile}
                    deviceRunParamChange={this.props.deviceRunParamChange}
                    tableRunParam={this.props.tableRunParam}
                    valtypeList={this.props.valtypeList}
                    isView={this.props.isview}
                    runParamWarning={this.props.runParamWarning}
                    isDeviceEdit={this.props.isDeviceEdit}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDeviceEdit: state.devices.deviceEdit,
        userProfile: state.auth.userProfile,
        unitList: state.devices.unit,
        logicList: state.devices.logic,
        valtypeList: state.devices.valtype,
        deviceRunParamChange: state.devices.deviceRunParamChange,
        currentDevice: state.devices.currentDevice,
        runParamTemp: state.devices.runParamTemp,
        runParam: state.devices.runParam,
        tableRunParam: state.devices.tableRunParam,
        isview: state.devices.view,
        runParamWarning: state.devices.runParamWarning,
        selectedDevices: state.deviceSelect.selectedDevices
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdate: (entity) => {
            dispatch(setTableRunParam(entity))
        },
        onhandleDelete: (entity) => {
            dispatch(setTableRunParam(entity))
        },
        onhandleAdd: (entity) => {
            dispatch(setTableRunParam(entity))
        },
        onhandleDeviceRunParamChange: (isChanged) => {
            dispatch(setDeviceRunParamChange(isChanged))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleUpdateDeviceRunParam: (entity) => {
            dispatch(modifyDeviceRunParam(entity))
        },
        onhandleInitDeviceRunParam: () => {
            dispatch(initDeviceRunParam())
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceRunParamContainer);