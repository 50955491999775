import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    popContent: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: '350px',
        padding: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    button: {
        margin: theme.spacing(0.5),
    },
});


export default withStyles(styles);