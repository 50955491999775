import React, { Component } from 'react';
import { connect } from 'react-redux';
import OnsiteInspectionPlanReport from '@/components/OnsiteInspectionManagement/Report/OnsiteInspectionPlanReport';
import {
  initPlanReport,
  updSearchData,
  resetInstallation,
  setLoad,
  exportCsv
} from '@/redux/modules/onsiteInspectionPlanReport';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import { enumSort } from "@/utils/listUtils";
import { getCompanyId } from "@/utils/userUtils";
import { getTreePartById } from '@/utils/constant';
import { getMoment } from '@/utils/datetime';
import { colors } from '@material-ui/core';

import palette from '@/theme/palette';
import _ from 'lodash';

let myChart = null;

class OnsiteInspectionPlanReportContainer extends Component {

  componentDidMount() {
    myChart = echarts.init(document.getElementById('main'));
    this.props.onhandleInitPlanReport();
  }

  handleLoad = () => {
    if (myChart) {
      if (this.props.search.companyId) {
        if (this.props.search.intervalDate.view === 'day') {
          myChart.setOption(this.getPieOption(), true);
        } else {
          myChart.setOption(this.getBarOption(), true);
        }
      } else {
        if (this.props.search.intervalDate.view === 'day') {
          myChart.setOption(this.getPieOption(), true);
        } else {
          myChart.setOption(this.getBarOption(), true);
        }
      }
      this.props.onhandleLoad()
    }
  }

  getBarOption = () => {
    let result = [['date']];
    let date = [];
    switch (this.props.search.intervalDate.view) {
      case 'week':
        let weekStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: weekStartDate,
            name: getMoment()(weekStartDate).format("ddd")
          })
          weekStartDate = getMoment()(weekStartDate).add(1, 'day').format("YYYY/MM/DD")
        } while (weekStartDate <= this.props.search.intervalDate.toDate)
        break;
      case 'month':
        let monthStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: monthStartDate,
            name: getMoment()(monthStartDate).format("Do")
          })
          monthStartDate = getMoment()(monthStartDate).add(1, 'day').format("YYYY/MM/DD")
        } while (monthStartDate <= this.props.search.intervalDate.toDate)
        break;
      case 'year':
        let yearStartDate = this.props.search.intervalDate.fromDate;
        do {
          date.push({
            date: getMoment()(yearStartDate).format("YYYY/MM"),
            name: getMoment()(yearStartDate).format("MMM")
          })
          yearStartDate = getMoment()(yearStartDate).add(1, 'month').format("YYYY/MM/DD")
        } while (yearStartDate <= this.props.search.intervalDate.toDate)
        break;
      default:
    }

    let seriesData = [];
    // let inspectionTypeList = this.getInspectionTypeList();

    let companyList = [];
    if (this.props.search.companyId) {
      companyList.push(this.props.search.companyId)
    } else {
      companyList = _.map(this.props.planReport, 'companyId');
    }
    companyList = _.uniq(companyList);

    let inspectionTypeList = [];
    if (this.props.search.inspectionType) {
      inspectionTypeList.push(this.props.search.inspectionType)
    } else {
      inspectionTypeList = _.map(this.props.planReport, 'inspectionType');
    }
    inspectionTypeList = _.uniq(inspectionTypeList);

    let statusList = [];
    if (this.props.search.status) {
      statusList.push(this.props.search.status)
    } else {
      statusList = _.map(this.props.planReport, 'status');
    }
    statusList = enumSort(['new', 'active', 'pending', 'done', 'expired'], _.uniq(statusList));

    let planReportList = [];
    this.props.planReport.forEach((item) => {
      planReportList.push({
        total: item.total,
        countDate: item.countDate,
        inspectionType: item.inspectionType,
        inspectionName: item.inspectionName,
        routeCode: item.routeCode,
        routeName: item.routeName,
        companyId: item.companyId,
        companyName: item.companyName,
        status: item.status,
        statusName: this.renderStatus(item.status)
      })
    })
    // planReportList = _.uniqWith(planReportList, _.isEqual);

    // date.forEach(countDate => {
    statusList.forEach(status => {
      let seriesName = this.renderStatus(status);

      // 按x轴分组匹配柱形数据
      const data = [];
      date.forEach(countDate => {
        let count = 0;
        _.filter(planReportList, { 'countDate': countDate.date }).forEach(planReportItem => {
          if (planReportItem.status === status) {
            count += planReportItem.total;
          }
        });
        data.push(count);
      });
      // 构造series
      seriesData.push({
        name: seriesName,
        type: 'bar', data: data,
        stack: 'status',
        itemStyle: {
          normal: {
            color: this.getStatusColor(status),
            label: {
              show: true, //开启显示
              position: 'insideTop', //在上方显示
              formatter: function (params) {
                if (params.value === 0) {  //为0时不显示
                  return ''
                } else {
                  return params.value
                }
              },
              textStyle: { color: 'black', fontSize: 10 }
            }
          }
        }
      })

    })
    // })

    let option = {
      title: { text: '巡检统计' },
      legend: {
        bottom: 0,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      dataset: {
        source: result
      },
      xAxis: {
        type: 'category',
        data: _.map(date, 'name')
      },
      yAxis: { type: 'value', minInterval: 1, },
      series: seriesData,
    }
    return option

  }

  getPieOption = () => {
    let result = [['company']];
    let seriesData = [];
    let titles = [{ text: '巡检统计' }];

    let planReportList = [];
    this.props.planReport.forEach((item) => {
      planReportList.push({
        total: item.total,
        countDate: item.countDate,
        inspectionType: item.inspectionType,
        inspectionName: item.inspectionName,
        routeCode: item.routeCode,
        routeName: item.routeName,
        companyId: item.companyId,
        companyName: item.companyName,
        status: item.status,
        statusName: this.renderStatus(item.status)
      })
    })
    // planReportList = _.uniqWith(planReportList, _.isEqual);

    let companyList = [];
    if (this.props.search.companyId) {
      companyList.push(this.props.search.companyId)
    } else {
      companyList = _.map(this.props.planReport, 'companyId');
    }
    companyList = _.uniq(companyList);

    let inspectionTypeList = [];
    if (this.props.search.inspectionType) {
      inspectionTypeList.push(this.props.search.inspectionType)
    } else {
      inspectionTypeList = _.map(this.props.planReport, 'inspectionType');
    }
    inspectionTypeList = _.uniq(inspectionTypeList);

    let statusList = [];
    if (this.props.search.status) {
      statusList.push(this.props.search.status)
    } else {
      statusList = _.map(this.props.planReport, 'status');
    }
    statusList = enumSort(['new', 'active', 'pending', 'done', 'expired'], _.uniq(statusList));

    // 按status分配
    const data = [];
    // companyList.forEach(companyId => {
    // titles.push({
    //   subtext: this.renderCompany(companyId),
    //   // left: '',
    //   top: '75%',
    //   textAlign: 'center'
    // })
    statusList.forEach(status => {
      let count = 0;
      planReportList.forEach(planReportItem => {
        if (planReportItem.status === status) {
          count += planReportItem.total;
        }
      });
      data.push({ value: count, name: this.renderStatus(status), status: status });
    });

    // 按各公司构造series
    seriesData.push({
      name: '巡检统计',
      type: 'pie',
      data: data,
      radius: [40, 90],
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        normal: {
          color: (param) => {
            return this.getStatusColor(param.data.status)
          },
        }
      },
      label: {
        alignTo: 'edge',
        formatter: '{name|{b}}\n{time|{c} 项}',
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          time: {
            fontSize: 10,
            color: '#999'
          }
        }
      },
      labelLine: {
        length: 5,
        length2: 5,
        maxSurfaceAngle: 50
      },
      // labelLayout: function (params) {
      //   var isLeft = params.labelRect.x < myChart.getWidth() / 3;
      //   var points = params.labelLinePoints;
      //   // Update the end point.
      //   points[2][0] = isLeft
      //     ? params.labelRect.x
      //     : params.labelRect.x + params.labelRect.width;

      //   return {
      //     labelLinePoints: points
      //   };
      // },
      // tooltip: {},
    })

    // });
    let option = {
      title: titles,
      legend: {
        bottom: 0,
        left: 'center'
      },
      tooltip: {
        // trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      dataset: {
        source: result
      },
      // xAxis: { type: 'category', data: companyList.map(c => this.renderCompany(c)) },
      // yAxis: { type: 'value', minInterval: 1, },
      series: seriesData,
    }
    return option
  }

  getInspectionTypeList = () => {
    let result = [];
    _.map(this.props.inspectionDictionaries, t => {
      if (t.type === "OITYPE" && t.code !== 'OIT05') {
        result.push({ label: t.name, value: t.code })
      }
    })
    return result;
  };

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        let companyId = value.target.value === 0 ? null : value.target.value;
        newSearch = {
          ...this.props.search,
          companyId: companyId,
          routeId: undefined,
          dptId: undefined,
          deviceId: undefined
        };
        this.props.onhandleUpdateSearch(newSearch);
        // this.props.onhandleResetInstallation(companyId);
        break;
      case 'inspectionType':
        newSearch = {
          ...this.props.search,
          inspectionType: value.target.value
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;

      case 'routeId':
        newSearch = {
          ...this.props.search,
          routeId: value.target.value
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'dptId':
        newSearch = {
          ...this.props.search,
          dptId: value.target.value
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'deviceId':
        let deviceId = value.target.value === 0 ? null : value.target.value;
        newSearch = {
          ...this.props.search,
          deviceId: deviceId
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'genItem':
        newSearch = {
          ...this.props.search,
          genItem: value.target.value
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value.target.value
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'specCode': {
        let specCode = value.target.value === '' ? '' : value.target.value;
        newSearch = {
          ...this.props.search,
          specCode: specCode
        };
        this.props.onhandleUpdateSearch(newSearch);
        break;
      }
      case 'view':
        let fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
        let toDate = getMoment()().endOf('day').format("YYYY/MM/DD");
        switch (value.target.value) {
          case 'year':
            fromDate = getMoment()().startOf('year').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('year').format("YYYY/MM/DD");
            break;
          case 'month':
            fromDate = getMoment()().startOf('month').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('month').format("YYYY/MM/DD");
            break;
          case 'week':
            fromDate = getMoment()().startOf('week').format("YYYY/MM/DD");
            toDate = getMoment()().endOf('week').format("YYYY/MM/DD");
            break;
          case 'day':
            fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            toDate = getMoment()().startOf('day').format("YYYY/MM/DD");
            break;
          default:
        }
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: value.target.value,
            fromDate: fromDate,
            toDate: toDate
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'day':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('day').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('day').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'week':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('week').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('week').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'month':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('month').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('month').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      case 'year':
        newSearch = {
          ...this.props.search,
          intervalDate: {
            view: this.props.search.intervalDate.view,
            fromDate: getMoment()(value).startOf('year').format("YYYY/MM/DD"),
            toDate: getMoment()(value).endOf('year').format("YYYY/MM/DD"),
          }
        }
        this.props.onhandleUpdateSearch(newSearch);
        break;
      default:
    }
  }

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  trimCompanyList() {
    const that = this
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
    let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
    let list = _.concat(companyList, subCompanyList)

    function loop(array) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
        list = _.concat(list, sub)
        loop(sub);
      }
    }

    loop(subCompanyList);

    let result = _.map(list, t => {
      return { label: t.companyName, value: t.companyId }
    })

    return result
  }


  renderCompany = (companyId) => {
    let item = _.filter(this.props.companyList, o => companyId === o.companyId)
    if (item && item.length) {
      return item[0].companyAlias
    } else {
      return ''
    }
  };

  renderInspectionType = (inspectionType) => {
    let item = _.filter(this.props.inspectionDictionaries, o => (inspectionType === o.code && o.type === "OITYPE"))
    if (item && item.length) {
      return item[0].name
    } else {
      return ''
    }
  };

  renderStatus = (status) => {
    let text = ''
    switch (status) {
      case 'new':
        text = '未开始'
        break;
      case 'active':
        text = '进行中'
        break;
      case 'pending':
        text = '已提交'
        break;
      case 'done':
        text = '已完成'
        break;
      case 'expired':
        text = '已补录'
        break;
      default:
        break;
    }
    return text
  }

  getStatusColor = (status) => {
    let color = undefined
    switch (status) {
      case 'new':
        color = palette.error.main
        break;
      case 'active':
        color = palette.warning.main
        break;
      case 'pending':
        color = colors.lime[500]
        break;
      case 'done':
        color = colors.green[500]
        break;
      case 'expired':
        color = colors.green[300]
        break;
      default:
        break;
    }
    return color

  }

  render() {
    return (
      <OnsiteInspectionPlanReport
        departmentList={this.props.departmentList}
        routeList={this.props.routeList}
        companyTree={this.trimCompanyTree()}
        companyList={this.trimCompanyList()}
        inspectionDictionaries={this.props.inspectionDictionaries}
        search={this.props.search}
        onhandleSelectChange={this.handleSelect.bind(this)}
        isLoad={this.props.isLoad}
        onhandleLoad={this.handleLoad.bind(this)}
        onhandleExportCsv={this.props.onhandleExportCsv.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    inspectionDictionaries: state.constant.inspectionDictionaries,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    search: state.onsiteInspectionPlanReport.search,
    planReport: state.onsiteInspectionPlanReport.planReport,
    isLoad: state.onsiteInspectionPlanReport.load,
    routeList: state.onsiteInspectionPlan.routeList,
    departmentList: state.onsiteInspectionPlan.departmentList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitPlanReport: () => {
      dispatch(initPlanReport());
    },
    onhandleUpdateSearch: (search) => {
      dispatch(updSearchData(search));
    },
    onhandleResetInstallation: (value) => {
      dispatch(resetInstallation(value));
    },
    onhandleLoad: () => {
      dispatch(setLoad(false));
    },
    onhandleExportCsv: () => {
      dispatch(exportCsv());
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnsiteInspectionPlanReportContainer);
