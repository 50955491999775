import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Grid,
    Typography,
    TablePagination,
    Chip
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { truncate } from "@/utils/string";
import {
    Card,
    Select,
    Tooltip
} from "@material-ui/core";
import { getMoment as moment, renderValidInfo } from '@/utils/datetime';
import _ from 'lodash';

const ChecklistInventory = props => {
    const {
        classes,
        lists,
        search,
        currentUserInfo,
        companyList,
        // departmentList, 非直接关联Inventory，但有扩展的可能
        userProfile,
        onInventoryDetail,
        getInventories,
        onhandleEdit,
        onhandleSelectChange,
        onhandleSearch,
        onhandleClearSearch,
        onhandlePageChange,
        onhandleListMapping,
        inspectionDptMapping,
        onDelete,
    } = props;


    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_checklist' })
        return permission && (permission.action === 'W' || permission.action === 'R')
    }

    const inventoryEditable = (rowData) => {
        // check公司
        const companyId = currentUserInfo.companyId;
        const company = _.find(companyList, { 'companyId': companyId });
        const hasPcomp = Boolean(company && company.pid);
        if (!rowData.companyId && hasPcomp) return false;
        if (hasPcomp && rowData.companyId !== currentUserInfo.companyId) return false;
        // 同检查类型
        if (_.findIndex(inspectionDptMapping, { 'inspectionCode': rowData.listType }) < 0) return false;
        // 同检查子分类（如果需要）
        if (rowData.catCode && rowData.catCode !== '') {
            return _.findIndex(inspectionDptMapping, { 'inspectionCode': rowData.catCode }) >= 0
        }
        return true;
    }

    const renderValidStatus = (rowData) => {
        const nowMoment = moment()();
        let label = '有效';
        let statusStyle = classes.valid;
        if (rowData.validStartTime && moment()(rowData.validStartTime).isAfter(nowMoment, 'day')) {
            label = '未生效';
            statusStyle = classes.pending;
        }
        if (rowData.validEndTime && moment()(rowData.validEndTime).isBefore(nowMoment, 'day')) {
            label = '已失效';
            statusStyle = classes.invalid;
        }
        return <Tooltip aria-label="{row.opentext}" title={renderValidInfo(rowData.validStartTime, rowData.validEndTime)} >
            <Chip size="small" className={statusStyle} label={label} />
        </Tooltip>

    }

    const companyOptions = () => (
        <React.Fragment>
            {companyList ?
                companyList.map(data => (
                    <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
                )) : null
            }
        </React.Fragment>
    )


    const tableOptions = {
        ...options,
        pageSize: search.size,
        tableLayout: 'auto',
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        toolbar: true
    }

    const tableStyle = {
        ...style,
        border: '0px solid black',
        boxShadow: 'none'
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '排查类型',
                field: 'listType',
                render: row => row ? <Typography>{onhandleListMapping(row.listType, 'listType')}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            {
                title: '综合劳务分公司',
                field: 'companyId',
                render: row => row ? <Typography>{row.companyId ? onhandleListMapping(row.companyId, 'company') : "不限"}</Typography> : "",
                editable: 'never',
                width: '10%'
            },
            {
                title: '检查表',
                field: 'inventoryName',
                render: row => row ? <Typography title={row.inventoryName}>{truncate(row.inventoryName, 30)}</Typography> : '',
                editable: 'never',
                width: '400px'
            },
            {
                title: '有效期',
                field: '?',
                render: row => row ? renderValidStatus(row) : '',
                editable: 'never',
                width: '15%'
            },
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={() => onhandleSearch()}
                        >{'搜索'}</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleClearSearch}

                        >{'重置'}</Button>
                    </Grid>
                </Grid>
                <MTableToolbar {...props} />
            </>

        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={search.size}
                count={lists.total}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum);
                    getInventories({
                        ...search,
                        offset: pageNum * search.size,
                        size: search.size
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                    getInventories({
                        ...search,
                        offset: 0,
                        size: event.target.value
                    })
                }}

            />
        )
    }

    return (
        <Card className={classes.card}>
            <div className={classes.cardtitle}>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    基层班组风险研判列表</Typography>
                <Grid container spacing={1} className={classes.cardtr}>
                    <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            分厂</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.company}
                            onChange={(event) => onhandleSelectChange(event, 'company')}
                        >
                            <option aria-label="None" value="" />
                            {companyOptions()}
                        </Select>
                    </Grid>
                </Grid>
            </div>

            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={tableOptions}
                        localization={localization}
                        style={tableStyle}
                        components={components}
                        actions={checkPermission() ?
                            [
                                {
                                    icon: 'add',
                                    tooltip: '新建',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleEdit(null) }
                                },
                                rowData => ({
                                    icon: 'subject',
                                    tooltip: '风险研判明细',
                                    onClick: (event, rowData) => {
                                        onInventoryDetail(rowData.inventoryId)
                                    }
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: '修改信息',
                                    onClick: (event, rowData) => {
                                        onhandleEdit(rowData)
                                    },
                                    disabled: !inventoryEditable(rowData)
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除',
                                    onClick: (event, rowData) => {
                                        onDelete(event, rowData.inventoryId)
                                    },
                                    disabled: !inventoryEditable(rowData)
                                })
                            ] : [
                                rowData => ({
                                    icon: 'subject',
                                    tooltip: '风险研判明细',
                                    onClick: (event, rowData) => {
                                        onInventoryDetail(rowData.inventoryId, false)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>
        </Card>
    );
};

ChecklistInventory.propTypes = {
    classes: PropTypes.object.isRequired,
    lists: PropTypes.object.isRequired,
    inspectionDptMapping: PropTypes.array.isRequired,
    getInventories: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleEdit: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandlePageChange: PropTypes.func.isRequired
};

export default withStyles(ChecklistInventory);
