import React, { useState, useEffect } from 'react';
import withStyles from './styles';
import {
    Grid,
    Button,
    Typography,
    Checkbox,
    TextField,
    MenuItem
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, } from "../../../../utils/mtable";
import { Prompt } from 'react-router-dom';

const RoleRoute = props => {

    const {
        classes,
        currentRole,
        tabValue,
        index,
        roleRouteChange,
        routes,
        onhandleBack,
        onhandleSaveRoleRoute,
        onhandleRouteTypeSelectChange,
        onhandleSelectionChange
    } = props;

    const [tableHeight, setTableHeight] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 290;
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const routeTypes = { 1: '系统页面', 2: '管理页面', 3: '普通页面' };

    const renderRouteType = (type) => {
        return routeTypes[type];
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '',
                field: 'route',
                width: 50,
                align: 'center',
                cellStyle: {
                    padding: '2px 2px',
                },
                headerStyle: {
                    padding: '2px 2px',
                },
                render: row => row ? <Checkbox size='small'
                    checked={row.action ? true : false}
                    onChange={(event) => onhandleSelectionChange(event, row)}
                /> : ''
            },
            {
                title: '页面ID',
                field: 'route',
                render: row => row ? <Typography>{row.route}</Typography> : ''
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : ''
            },
            {
                title: '页面类型',
                field: 'routeType',
                render: row => row ? <Typography>{renderRouteType(row.routeType)}</Typography> : ''
            },
            {
                title: '权限',
                field: 'routeType',
                align: 'center',
                width: 130,
                cellStyle: {
                    padding: '2px 2px',
                },
                headerStyle: {
                    padding: '2px 2px',
                },
                render: row => row ? <TextField
                    select
                    size='small'
                    style={{ width: '80%' }}
                    variant={'outlined'}
                    value={row.action ? row.action : "V"}
                    onChange={(event) => onhandleRouteTypeSelectChange(event, row)}
                >
                    <MenuItem value="V">只读</MenuItem>
                    <MenuItem value="W">可编辑</MenuItem>
                    <MenuItem value="R">可审阅</MenuItem>
                </TextField> : null

                // <FormControlLabel
                //                         control={
                //                             <Switch checked={row.action === 'W'?true:false}
                //                             onChange={(event) => onhandleSwitchChange(event, row)}
                //                             name="actionCheck" /> 
                //                         }
                //                         label="可编辑"
                //                         />: '',
            }
        ]

        return columns
    }

    return (
        <div className={classes.card} key={currentRole.roleId} hidden={tabValue !== index}>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        columns={assemblycolumns()}
                        data={routes}
                        options={{
                            ...options,
                            showTitle: false,
                            padding: 'dense',
                            search: true,
                            toolbar: true,
                            pageSize: 15,
                            pageSizeOptions: [15, 30, 50],
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.processId ? '#EEEEEE' : 'FFFFFF'
                            }),
                            maxBodyHeight: `${tableHeight}px`,
                            minBodyHeight: `${tableHeight}px`,
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                    >
                    </MaterialTable>
                </Grid>
            </div >
            <Grid container spacing={0} className={classes.gridContainer}>
                <Button variant="contained" color="primary"
                    disabled={!roleRouteChange}
                    onClick={event => onhandleSaveRoleRoute(event)}
                    className={classes.button}
                >
                    保存
                </Button>
                <Button variant="contained" color="primary" className={classes.button}
                    onClick={event => onhandleBack(event)}
                >
                    返回
                </Button>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={roleRouteChange} />
        </div>
    );
};

RoleRoute.propTypes = {
};

export default withStyles(RoleRoute);
