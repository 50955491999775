import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    top: {
        minHeight: '60px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'justify',
    },
    filterEle: {
        marginLeft: theme.spacing(1),
    },
    flexgrow: {
        flexGrow: 1
    },

});


export default withStyles(styles);