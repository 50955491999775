import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Avatar,
    MenuItem,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Card,
    Link
} from '@material-ui/core';
import {
    PersonAdd,
    Block,
    Done,
    WhereToVote,
} from '@material-ui/icons';
import {
    Alert, AlertTitle
} from '@material-ui/lab';
import { Prompt } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import UserCard from '../../../../containers/UserCard';
import { getDateByString, getMoment } from '../../../../utils/datetime';
import ProcessLineWithUser from '../../../ProcessLineApproval/ProcessLineApprovalComponent';
import { arrayToTree } from "../../../../utils/constant";
import * as _ from 'lodash';


const CreateAppointment = ({
    classes,
    companyList,
    specCodeList,
    currentWorkOrder,
    appointmentDetails,
    orderTypeList,
    newAppointmentChange,
    onhandleAddDetail,
    onhandleUpdate,
    onhandleDelete,
    onhandleView,
    onhandleBack,
    onhandleHeadClick,
    onhandleRestartWork,
    userCompanyId,
    userProfile,
    statusCode,
    actions,
    onhandleProcess,
    bigPermit,
    workorderWithBigPermitList,
    orderHeadAction,
    masterWorkOrder,
    routeType,
    onhandleAddSolution,
    onhandleDownload,
    onhandleUpdateSolution,
    onhandleDeleteSolution,
    onhandleAddBigPermitDoc,
    masterJSADocs,
    masterBigpermitDocs,
    onhandleDeleteWorkPermitDoc,
    onhandleApprovePermitDoc
}) => {

    const tableRef = React.createRef();

    //权限流程
    const isPausedRow = (row) => {
        return row.status === 'paused' && row.appHead === userProfile.userId;
    }

    const isTerminatedRow = (row) => {
        return row.status === 'terminated' && row.appHead === userProfile.userId;
    }

    // const hasTerminatedAppEditor = () => {
    //     const temp = _.filter(appointmentDetails, { 'status': 'terminated', 'appHead': userProfile.userId });
    //     if (temp && temp.length && temp.length > 0) {
    //         return true;
    //     }
    //     return false;
    // }

    const hasTerminatedRows = _.findIndex(appointmentDetails, { 'status': 'terminated' }) > -1;

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_workorder_appointment' })
    //     return !((permission && permission.action === 'W' && (statusCode === 'new' || !statusCode)) || orderHeadAction)
    // }

    const checkOrderHead = () => {
        return ((masterWorkOrder.orderHead && userProfile.userId === masterWorkOrder.orderHead && (statusCode === 'new' || !statusCode)) || orderHeadAction)

    }

    const checkOrderHeadForBigPermit = () => {
        return ((masterWorkOrder.orderHead && userProfile.userId === masterWorkOrder.orderHead) || orderHeadAction)

    }

    const checkHaveMasterAppointment = () => {
        let rtn = false
        if (!currentWorkOrder.masterOrder) {
            rtn = true
        } else {
            if (_.filter(appointmentDetails, { orderId: currentWorkOrder.masterOrder }).length > 0) {
                rtn = true
            }
        }
        return rtn
    }

    const checkAddRight = () => {
        let rtn = false;
        if (checkOrderHead()) {
            rtn = true;
        } else {
            if (_.filter(currentWorkOrder.executors, function (o) { return o.internalCompany }).length > 0) {
                if (_.filter(currentWorkOrder.executors, function (o) { return o.internalCompany === userCompanyId && o.internalDpt === userProfile.roles[0].departments[0].dptId }).length > 0) {
                    if (statusCode === 'new' || !statusCode) {
                        rtn = true;
                    }
                }
            }
        }
        // if (!checkPermission()) {
        //     if (currentWorkOrder.executors && currentWorkOrder.executors.length > 0) {
        //         let targetWoe = currentWorkOrder.executors[0]
        //         if (!targetWoe) {
        //             rtn = false
        //         } else {
        //             if (targetWoe.internalCompany) {
        //                 if (targetWoe.internalCompany === userCompanyId) {
        //                     rtn = true
        //                 }
        //             } else {
        //                 if (currentWorkOrder.companyId === userCompanyId) {
        //                     rtn = true
        //                 }
        //             }
        //         }
        //     }
        // } else {
        //     rtn = false
        // }
        return rtn
    }

    // const checkNewAddRight = () => {
    //     let rtn = false;
    //     let targetWoe = currentWorkOrder.executors[0]
    //     if (!targetWoe) {
    //         rtn = false
    //     } else {
    //         if (targetWoe.internalCompany) {
    //             if (targetWoe.internalCompany === userCompanyId) {
    //                 rtn = true
    //             }
    //         } else {
    //             if (currentWorkOrder.companyId === userCompanyId) {
    //                 rtn = true
    //             }
    //         }
    //     }
    //     return rtn
    // }

    const checkWorkRight = (data) => {
        let rtn = false;
        if (masterWorkOrder.orderHead && userProfile.userId === masterWorkOrder.orderHead) {
            rtn = true
        } else if (data.appHead && userProfile.userId === data.appHead) {
            rtn = true
        }
        return rtn;
    }

    const checkRowEditRight = (rowData) => {
        let rtn = false;

        if (orderHeadAction) {
            if (rowData.status === 'submitted') {
                rtn = false
            }
        } else {
            if (rowData.orderId !== currentWorkOrder.orderId) return true;
            if (bigPermit.status !== 'new') return true;
            if (checkAddRight()) {
                if (rowData.status !== 'new') {
                    rtn = true
                } else {
                    rtn = false
                }
            } else {
                rtn = true
            }
        }
        return rtn
    }

    // const checkViewWorkDetailRight = (row) => {
    //     if (row.status === 'done') {
    //         return true;
    //     }
    //     const preApp = _.find(appointmentDetails, { 'appId': row.preApp });
    //     if (preApp) {
    //         if (preApp.status === 'done') {
    //             return true;
    //         }
    //     } else if (bigPermit && bigPermit.status && bigPermit.status === 'approved') {
    //         return true;
    //     }
    //     return false;
    // }

    //r页面项目渲染
    const renderCompany = (appointment) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === appointment.locationCompany })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderSpecCodeList = (appointment) => {
        let text = ''
        if (appointment.mainWork) {
            text = '主作业';
        } else {
            if (!appointment.specCode) {
                text = '一般作业';
            } else {
                let value = _.find(specCodeList, function (o) { return o.code === appointment.specCode })
                if (value) {
                    text = value.name;
                }
            }
        }
        return text;
    }

    const renderSpecCode = (appointment) => {
        if (appointment.mainWork) {
            return <Chip
                size="small"
                variant={'outlined'}
                className={classes.pic41}
                avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic4}>
                    主
                </Avatar>}
                label={'主作业'}
            />
        } else {
            if (!appointment.specCode) {
                return <Chip
                    size="small"
                    variant={'outlined'}
                    className={classes.pic51}
                    avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic5}>
                        一般
                    </Avatar>}
                    label={'一般作业'}
                />
            } else {
                let value = _.find(specCodeList, function (o) { return o.code === appointment.specCode })
                if (value) {
                    return <Chip
                        size="small"
                        variant={'outlined'}
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic3}>
                            特
                        </Avatar>}
                        className={classes.pic31}
                        label={value.name}
                    />
                } else {
                    return null
                }

            }
        }
    }

    const renderOrderType = (WorkOrder) => {
        let label = '';
        if (orderTypeList && orderTypeList.length > 0) {
            let value = _.find(orderTypeList, function (o) { return o.code === WorkOrder.orderType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderDate = (date) => {
        return date ? getDateByString(date) : ''
    }

    const renderPreApp = (appointment) => {
        let newAppointmentDetails = _.filter(appointmentDetails, function (o) { return o.appId })
        newAppointmentDetails = _.filter(newAppointmentDetails, function (o) { return o.appId !== appointment.appId })
        if (newAppointmentDetails && newAppointmentDetails.length > 0) {
            return newAppointmentDetails
        } else {
            return null
        }
    }

    const chcekPreApp = (appointment) => {
        let rtn = false;
        let newAppointmentDetails = _.filter(appointmentDetails, function (o) { return o.appId });
        newAppointmentDetails = _.filter(newAppointmentDetails, function (o) { return o.preApp === appointment.appId });
        if (newAppointmentDetails && newAppointmentDetails.length > 0) {
            newAppointmentDetails = _.filter(newAppointmentDetails, function (o) { return o.appId === appointment.preApp });
            if (newAppointmentDetails && newAppointmentDetails.length > 0) {
                rtn = true;
            }
        }
        return rtn;
    }

    const checkPreAppTime = (appointment) => {
        let rtn = false;
        let preAppointmentDetail = _.find(appointmentDetails, function (o) { return o.appId === appointment.preApp });
        if (preAppointmentDetail) {
            if (getMoment()(preAppointmentDetail.estimateEndTime).format("YYYY/MM/DD") > getMoment()(appointment.estimateStartTime).format("YYYY/MM/DD")) {
                rtn = true;
            }
        }
        return rtn;
    }

    const renderWoe = (row) => {
        let rtn = null
        let targetWoe = row.workOrderExecutor
        if (targetWoe) {
            if (targetWoe.supplierId) {
                rtn = <Chip
                    avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic2}>
                        外
                    </Avatar>}
                    size="small"
                    label={`${targetWoe.supplierId}`}
                />
            } else {
                rtn = <Chip
                    size="small"
                    avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic1}>
                        内
                    </Avatar>}
                    label={`${targetWoe.internalCompanyName} > ${targetWoe.internalDptName}`}
                />
            }
        }
        return rtn;
    }

    const renderWorkOrderStatus = (WorkOrder) => {
        let text = '';
        let mapping = { 'new': '预约未审批', 'reported': '预约审批中', 'submitted': '预约审批中', 'approved': '预约审批通过', 'finalized': '预约审批通过', 'done': '已完成' };
        if (WorkOrder.workPermit && WorkOrder.workPermit.length > 0) {
            text = mapping[WorkOrder.workPermit[0].status]
        } else {
            text = '未进行预约'
        }
        return text
    }

    const renderStatus = (WorkOrder) => {
        let mapping = { 'new': '预约', 'active': '有效', 'inactive': '无效', 'submitted': '编辑完成', 'reported': '编辑完成', 'approved': '审核通过', 'paused': '延期', 'terminated': '终止', 'finalized': '待验收', 'acceptanced': '已验收许可待关闭', 'done': '已完成' };
        return mapping[WorkOrder.status]
    }

    const renderPermitStatus = (status) => {
        let value = ''
        const permitStatus = { 'active': '有效', 'rejected': '驳回', 'submitted': '审核中', 'approved': '审核通过', 'inactive': '废止', 'done': '关闭' };
        value = permitStatus[status]
        return value;
    }

    const renderPermitStatusStyle = (status) => {
        let resultStyle = classes.pendingPermit;
        if (status === 'inactive') resultStyle = classes.inactivePermit;
        if (status === 'approved') resultStyle = classes.submittedPermit;
        if (status === 'done') resultStyle = classes.donePermit;
        if (status === 'active') resultStyle = classes.finalizedPermit;
        // if (permitRejected) resultStyle = classes.rejectedPermit;
        return resultStyle;
    }

    const renderOrderNo = (orderNo) => {
        let workOrder = _.find(workorderWithBigPermitList, { orderNo: orderNo });
        if (workOrder) {
            return <Chip
                size="small"
                className={workOrder.masterOrder ? classes.pic21 : classes.pic11}
                label={orderNo} />
        } else {
            return null;
        }
    }

    const renderContractor = (workOrder) => {
        if (workOrder.executors && workOrder.executors.length > 0) {
            return workOrder.executors.map(ele => {
                return ele.internalCompanyName && ele.internalDptName ?
                    <Chip
                        key={ele.woeId}
                        size="small"
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic1}>
                            内
                        </Avatar>}
                        label={`${ele.internalCompanyName} > ${ele.internalDptName}`}
                    />
                    :
                    <Chip
                        key={ele.woeId}
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic2}>
                            外
                        </Avatar>}
                        size="small"
                        label={`${ele.supplierName}`}
                    />
            }
            )
        } else {
            return null;
        }
    }

    const columns = [
        {
            title: '工单编号',
            field: 'orderNo',
            render: row => row ? renderOrderNo(row.orderNo) : '',
            // defaultGroupOrder: 0,
            // defaultGroupSort: 'asc',
            editable: 'never'
        },
        {
            title: '作业编号',
            field: 'serialNo',
            editable: 'never',
            render: row => row && row.serialNo ?
                row.mainWork ?
                    <Chip
                        size="small"
                        variant={'outlined'}
                        className={classes.pic41}
                        label={row.serialNo}
                    />
                    : row.specCode ? <Chip
                        size="small"
                        variant={'outlined'}
                        className={classes.pic31}
                        label={row.serialNo}
                    /> : <Chip
                        size="small"
                        variant={'outlined'}
                        className={classes.pic51}
                        label={row.serialNo}
                    />
                : '',
        },
        {
            title: '前置作业',
            field: 'preApp',
            render: row => row && _.isInteger(row.preApp) ? <Chip
                size="small"
                label={_.find(appointmentDetails, { appId: row.preApp }) ? _.find(appointmentDetails, { appId: row.preApp }).serialNo : ''}
            /> : '',
            validate: rowData => chcekPreApp(rowData) ? { isValid: false, helperText: '不能和其它作业互为前置作业' } : checkPreAppTime(rowData) ? { isValid: false, helperText: '前置作业的预计结束时间不能晚于当前预计开始时间' } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    disabled={(
                        Boolean(!(renderPreApp(props.rowData) && renderPreApp(props.rowData).length > 0)) || Boolean(userProfile.userId !== masterWorkOrder.orderHead) || isPausedRow(props.rowData)
                    ) && !isTerminatedRow(props.rowData)
                    }
                    fullWidth
                    variant="standard"
                    placeholder="请选择"
                    name='preApp'
                    value={props.rowData.preApp}
                    onChange={e => props.onChange(e.target.value)}
                    select
                >
                    <MenuItem key={1} value={0}>-</MenuItem>
                    {renderPreApp(props.rowData) ?

                        renderPreApp(props.rowData).map(data => (
                            <MenuItem value={data.appId} key={data.appId}>{`${data.serialNo} ${renderSpecCodeList(data)}`}</MenuItem>
                        ))
                        : null
                    }
                </TextField>
            )

        },
        {
            title: '作业类型',
            field: 'specCode',
            editable: 'onUpdate',
            render: row => row ? renderSpecCode(row) : '',
            editComponent: (props) => (
                props.rowData.mainWork
                    ? renderSpecCode(props.rowData) :
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        fullWidth
                        variant="standard"
                        placeholder="请选择"
                        name='specCode'
                        value={props.rowData.specCode}
                        onChange={e => props.onChange(e.target.value)}
                        // disabled={Boolean(!isTerminatedRow(props.rowData)) || isPausedRow(props.rowData)}
                        select
                    >
                        {specCodeList ?
                            _.sortBy(specCodeList, 'seq').map(data => (
                                <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                            )) : null
                        }
                    </TextField>
            )
        },
        {
            title: '作业场所(单位)',
            field: 'locationCompany',
            editable: 'onUpdate',
            render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
            editComponent: (props) => (
                <TextField
                    fullWidth
                    variant="standard"
                    placeholder="请选择"
                    name='company'
                    value={props.rowData.locationCompany}
                    onChange={e => props.onChange(e.target.value)}
                    select
                // disabled={Boolean(!isTerminatedRow(props.rowData)) || isPausedRow(props.rowData)}
                >
                    <MenuItem key={1} value={0}>-</MenuItem>
                    {companyList ?
                        _.sortBy(companyList, 'companyId').map(data => (
                            <MenuItem value={data.companyId} key={data.companyId}>{data.companyAlias}</MenuItem>
                        )) : null
                    }
                </TextField>
            )
        },
        {
            title: '作业场所(位置)',
            field: 'location',
            editable: 'onUpdate',
            render: row => row ? <Typography>{row.location}</Typography> : '',
            validate: rowData => (!rowData.location || rowData.location === '') ? { isValid: false, helperText: '作业具体位置不能为空' } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    value={props.rowData.location}
                    onChange={e => props.onChange(e.target.value)}
                    variant="standard"
                    fullWidth
                    // disabled={Boolean(!isTerminatedRow(props.rowData) || isPausedRow(props.rowData))}
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField>
            )
        },
        {
            title: '作业内容',
            field: 'appContent',
            render: row => row ? <Typography>{row.appContent}</Typography> : '',
            align: 'left',
            width: '200px',
            editable: 'onUpdate',
            validate: rowData => (!rowData.appContent || rowData.appContent === '') ? { isValid: false, helperText: '作业内容不能为空' } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    value={props.rowData.appContent}
                    onChange={e => props.onChange(e.target.value)}
                    variant="standard"
                    fullWidth
                    // disabled={Boolean(userProfile.userId === masterWorkOrder.orderHead && !isTerminatedRow(props.rowData)) || isPausedRow(props.rowData)}
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField>
            )
        },
        {
            title: '承包商',
            field: 'woeId',
            editable: 'never',
            render: row => row ? renderWoe(row) : '',
        },
        {
            title: '负责人',
            field: 'appHead',
            editable: 'never',
            render: row => row && row.appHeadLastName && row.appHeadFirstName ? <UserCard userId={row.appHead} child={<Typography>{row.appHeadLastName + row.appHeadFirstName}</Typography>} /> : row.appHeadFirstName ? <UserCard userId={row.appHead} child={<Typography>{row.appHeadFirstName}</Typography>} /> : '',
        },
        {
            title: '预计开始时间',
            field: 'estimateStartTime',
            editable: 'onUpdate',
            width: '180px',
            validate: rowData =>
                (!rowData.estimateStartTime || rowData.estimateStartTime === '')
                    ? { isValid: false, helperText: '预计开始时间不能为空' }
                    : (
                        (rowData.estimateEndTime && rowData.estimateEndTime !== '' && rowData.estimateStartTime > rowData.estimateEndTime)
                            ? { isValid: false, helperText: '预计开始时间不能晚于结束时间' }
                            : true
                    )
            ,
            render: row => row ? <Typography>{renderDate(row.estimateStartTime)}</Typography> : '',
            editComponent: (props) => (
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        autoOk
                        InputProps={{ readOnly: true, }}
                        className={classes.textField}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        name='tenabletimeselect'
                        value={props.rowData.estimateStartTime}
                        onChange={value => props.onChange(value)}
                        KeyboardButtonProps={{
                            'aria-label': '修改时间',
                        }}
                        minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                    />
                </MuiPickersUtilsProvider>
            )
        },
        {
            title: '预计结束时间',
            field: 'estimateEndTime',
            editable: 'onUpdate',
            width: '180px',
            validate: rowData => (!rowData.estimateEndTime || rowData.estimateEndTime === '')
                ?
                { isValid: false, helperText: '预计结束时间不能为空' } : (
                    (rowData.estimateStartTime && rowData.estimateStartTime !== '' && rowData.estimateStartTime > rowData.estimateEndTime)
                        ? { isValid: false, helperText: '预计结束时间不能早于开始时间' }
                        : true
                ),
            render: row => row ? <Typography>{renderDate(row.estimateEndTime)}</Typography> : '',
            editComponent: (props) => (
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={zhCN} >
                    <KeyboardDatePicker
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        autoOk
                        InputProps={{ readOnly: true, }}
                        className={classes.textField}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        name='tenabletimeselect'
                        value={props.rowData.estimateEndTime}
                        onChange={value => props.onChange(value)}
                        KeyboardButtonProps={{
                            'aria-label': '修改时间',
                        }}
                        minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                    />
                </MuiPickersUtilsProvider>
            )
        },
        {
            title: '状态',
            field: 'status',
            render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            editable: 'never'
        }
    ]

    const tableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
        defaultExpanded: true,
        rowStyle: rowData => ({
            backgroundColor: currentWorkOrder.orderId === rowData.orderId ? '#fbe9e7' : '#EEEEEE'
        }),
        groupTitle: group => renderOrderNo(group.value),
        headerStyle: {
            backgroundColor: '#eeeeee',
            fontWeight: 'bold'
        }
    }

    const workorderTableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
        toolbar: false,
        rowStyle: rowData => ({
            backgroundColor: currentWorkOrder.orderId === rowData.orderId ? '#fbe9e7' : '#EEEEEE'
        }),
        headerStyle: {
            backgroundColor: '#eeeeee',
            fontWeight: 'bold'
        }
    }

    const tableStyle = {
        ...style
    }

    const assemblyDOCColumns = (type) => {

        let DOCColumns = [
            {
                title: '名称',
                field: 'docName',
                render: row => row ? <Typography>{row.docName}</Typography> : '',
                editable: 'never',
                width: '25%'
            },
            // {
            //     title: '类型',
            //     field: 'docType',
            //     render: row => row ? <Typography>{row.docType}</Typography> : '',
            //     editable: 'never',
            //     width: '25%'
            // },
            {
                title: '创建时间',
                field: 'createTime',
                render: row => row ? <Typography>{renderDate(row.createTime)}</Typography> : '',
                width: '25%'
            },
            {
                title: '文档',
                field: 'doc',
                width: '25%',
                render: row => row ?
                    <Link
                        component="button"
                        onClick={() => { onhandleDownload(row) }}
                        className={classes.downloadPermit}>
                        {row.docName}
                    </Link> : '',
            },
        ]
        if (type === 'BigPermit') {
            DOCColumns.push({
                title: '状态',
                field: 'status',
                render: row => {
                    return <Chip size="small" className={renderPermitStatusStyle(row.status)} label={renderPermitStatus(row.status, 'permitStatus')} />
                },
                width: '25%'
            })
        }
        return DOCColumns;
    }

    const solutionColumns = [
        {
            title: '方案名称',
            field: 'solutionName',
            render: row => row ? <Typography>{row.solutionName}</Typography> : '',
            editable: 'never'
        },
        {
            title: '方案内容',
            field: 'solutionContent',
            render: row => row && row.solutionContent ?
                <TextField
                    variant='standard'
                    disabled
                    fullWidth
                    value={row.solutionContent || ''}
                    name='solutionContent'
                    rows={4}
                    multiline
                /> : '',
            editable: 'never'
        },
        {
            title: '创建时间',
            field: 'createTime',
            render: row => row ? <Typography>{renderDate(row.createTime)}</Typography> : '',
        },
        {
            title: '方案文档',
            field: 'doc',
            render: row => row && row.solutionDoc ?
                <Link
                    component="button"
                    onClick={() => { onhandleDownload(row.solutionDoc) }}
                    className={classes.downloadPermit}>
                    {row.solutionDoc.docName}
                </Link> : '',
        },
    ];

    const assemblyWorkOrdercolumns = () => {
        let columns = [];

        let commonColumns = [
            {
                field: 'selected',
                render: row => row ?
                    currentWorkOrder.orderId === row.orderId ?
                        <WhereToVote style={{
                            color: '#FF4A4A'
                        }} />
                        : null
                    : null,
                width: '2em',
                editable: 'never'
            },
            {
                field: 'type',
                render: row => row ?
                    row.masterOrder ?
                        <Avatar className={classes.pic2}>从</Avatar>
                        : <Avatar className={classes.pic1}>主</Avatar>
                    : null,
                width: '2em',
                editable: 'never'
            },
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ?
                    row.masterOrder ?
                        <Chip
                            size="small"
                            className={classes.pic21}
                            label={row.orderNo}
                        /> : <Chip
                            size="small"
                            className={classes.pic11}
                            label={row.orderNo} /> : null,
                editable: 'never'
            },
            {
                title: '主工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.masterOrderNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '工单内容',
                field: 'orderContent',
                render: row => row ? <Typography>{row.orderContent}</Typography> : '',
                editable: 'never'
            },
            {
                title: '承包商',
                field: 'contractor',
                render: row => row ? renderContractor(row) : '',
                editable: 'never'
            },
            {
                title: '负责人',
                field: 'orderHead',
                render: row => row && row.orderHeadLastName && row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadLastName + row.orderHeadFirstName}</Typography>} /> : row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadFirstName}</Typography>} /> : '',
                editable: 'never'
            },
            {
                title: '作业状态',
                field: 'status',
                render: row => row ? <Chip size="small" className={classes[row.workPermit[0] && row.workPermit[0].status ? row.workPermit[0].status : null]} label={renderWorkOrderStatus(row)} /> : '',
                editable: 'never'
            }
        ]

        columns = _.concat(columns, commonColumns);

        return columns
    }
    const components = {
        Toolbar: props => (
            <div>
                {Boolean(!checkAddRight() || statusCode === 'Reported' || statusCode === 'Published') ? null :
                    <MTableToolbar {...props} />
                }
                {Boolean(!hasTerminatedRows || statusCode === 'done') ? null :
                    <MTableToolbar {...props} />
                }
            </div>
        )
    }

    //页面审批区域渲染
    const renderActions = () => {
        if (!actions || actions.length === 0) {
            return
        } else {
            let buttons = actions.map((action, idx) => (
                <Button
                    key={`rule_button_${idx}`}
                    variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                    color="primary"
                    className={classes.button}
                    onClick={onhandleProcess.bind(this, action)}
                >
                    {action.actionName}
                </Button>
            ))
            return buttons
        }
    }

    const renderPermitPanel = () => {
        let bg = 'info';
        if (bigPermit.permitUsers && bigPermit.permitUsers.length > 0) {
            if (bigPermit.permitUsers[0].preId) {
                if (bigPermit.permitUsers[0].confirm && bigPermit.permitUsers[0].confirmTime) bg = 'success';
                if (!bigPermit.permitUsers[0].confirm && bigPermit.permitUsers[0].confirmTime) bg = 'error';
            } else {
                if (bigPermit.permitUsers[0].confirm && bigPermit.permitUsers[0].confirmTime) bg = 'warning';
            }
        }
        return bg;
    }

    const renderConfirm = (row) => {
        let style = null;
        let text = '开始审批';
        if (row.confirm && row.confirmTime) {
            style = classes.confirmTrue;
            if (row.preId) {
                text = '通过';
            } else {
                text = '提交';
            }
        }
        if (!row.confirm && row.confirmTime) {
            style = classes.confirmFalse;
            text = '驳回';
        }
        return <Typography display={'inline'} className={style}>{text}</Typography>
    }

    const renderHistoy = () => {
        if (bigPermit.permitUsersHistory && bigPermit.permitUsersHistory.length > 0) {
            let histoy = bigPermit.permitUsersHistory.map((permitUserHistory, idx) => {
                const noConfirm = Boolean(!permitUserHistory.confirm && !permitUserHistory.confirmTime);
                const trueConfirm = Boolean(permitUserHistory.confirm && permitUserHistory.confirmTime);
                const falseConfirm = Boolean(!permitUserHistory.confirm && permitUserHistory.confirmTime);
                return (
                    <ListItem key={`${permitUserHistory.puId}`} className={classes.permitHistoryItem} dense >
                        <ListItemIcon style={{ minWidth: '2em' }}>
                            {noConfirm ? <PersonAdd fontSize={'small'} /> : null}
                            {trueConfirm ? <Done fontSize={'small'} className={classes.confirmTrue} /> : null}
                            {falseConfirm ? <Block fontSize={'small'} className={classes.confirmFalse} /> : null}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Grid container>
                                    <Grid item>
                                        <Typography display={'inline'} color={'primary'}>{renderDate(permitUserHistory.confirmTime ? permitUserHistory.confirmTime : permitUserHistory.createTime)}</Typography>
                                        <Typography display={'inline'} variant={'h5'}><strong>{' ' + permitUserHistory.lastName + permitUserHistory.firstName + ' '}</strong></Typography>
                                        {renderConfirm(permitUserHistory)}
                                        <Typography display={'inline'} style={{ marginLeft: 20 }}>{Boolean(permitUserHistory.opentext) ? permitUserHistory.opentext : null}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem >
                )
            })
            return histoy
        } else {
            return
        }
    }

    //Render Action
    const renderDetailActions = () => {
        let actions = [];
        switch (routeType) {
            case 'all':
                actions = [
                    rowData => ({
                        icon: 'business_center',
                        tooltip: '查看作业',
                        onClick: (event, rowData) => {
                            onhandleView(rowData, 'view')
                        }
                    }),
                ]
                break;
            case 'needAppointment':
                actions = [
                    rowData => ({
                        icon: 'assignment_ind',
                        tooltip: '指派负责人',
                        onClick: (event, rowData) => {
                            onhandleHeadClick(event, rowData)
                        },
                        hidden: checkRowEditRight(rowData)
                    }),
                    {
                        icon: 'add',
                        hidden: !checkAddRight(),
                        disabled: Boolean(newAppointmentChange),
                        tooltip: '添加预约',
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleAddDetail(event)
                        },
                    },
                    rowData => ({
                        icon: 'delete_outlined',
                        tooltip: '删除',
                        onClick: (event, rowData) => {
                            onhandleDelete(rowData)
                        },
                        hidden: (rowData.mainWork || checkRowEditRight(rowData))
                    }),
                ]
                break;
            case 'needWork':
                actions = [
                    rowData => ({
                        icon: rowData.status === 'done' ? 'business_center' : checkWorkRight(rowData) ? 'work' : 'business_center',
                        tooltip: rowData.status === 'done' ? '查看作业' : checkWorkRight(rowData) ? '作业' : '查看作业',
                        onClick: (event, rowData) => {
                            onhandleView(rowData, rowData.status === 'done' ? 'view' : checkWorkRight(rowData) ? 'work' : 'view')
                        },
                        hidden: (isPausedRow(rowData) || isTerminatedRow(rowData))
                    }),
                    // // 终止时显示的指派按钮
                    // rowData => ({
                    //     icon: 'assignment_ind',
                    //     tooltip: '指派负责人',
                    //     onClick: (event, rowData) => {
                    //         onhandleHeadClick(event, rowData)
                    //     },
                    //     hidden: !(isPausedRow(rowData) || isTerminatedRow(rowData))
                    // }),
                    // // 终止时显示的启动按钮
                    // rowData => ({
                    //     icon: 'play_circle_outline',
                    //     tooltip: '作业再启动',
                    //     onClick: (event, rowData) => {
                    //         onhandleRestartWork(event, rowData)
                    //     },
                    //     hidden: !(isPausedRow(rowData) || isTerminatedRow(rowData))
                    // }),
                    // // 终止时显示的新建按钮
                    // {
                    //     icon: 'add',
                    //     hidden: Boolean(!checkAddRight()) || !Boolean(hasTerminatedAppEditor()),
                    //     disabled: Boolean(newAppointmentChange),
                    //     tooltip: '添加预约',
                    //     isFreeAction: true,
                    //     onClick: (event) => {
                    //         onhandleAddDetail(event, Boolean(hasTerminatedAppEditor()))
                    //     },
                    // },
                    // // 终止时显示的删除按钮
                    // rowData => ({
                    //     icon: 'delete_outlined',
                    //     tooltip: '删除',
                    //     onClick: (event, rowData) => {
                    //         onhandleDelete(rowData)
                    //     },
                    //     hidden: (rowData.mainWork || isPausedRow(rowData)) || !isTerminatedRow(rowData)
                    // }),
                ]
                break;
            case 'needCount':
                actions = [
                    rowData => ({
                        icon: 'business_center',
                        tooltip: '查看作业',
                        onClick: (event, rowData) => {
                            onhandleView(rowData, 'view')
                        }
                    }),
                ]
                break;
            default:
            // do nothing
        }
        return actions;
    }

    //Render Action
    const renderSolutionActions = () => {
        let actions = [];
        switch (routeType) {
            case 'needAppointment':
                if (checkOrderHead() && !currentWorkOrder.masterOrder) {
                    actions = [
                        {
                            icon: 'add',
                            // disabled: Boolean(newAppointmentChange),
                            tooltip: '添加方案',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleAddSolution(event)
                            },
                        },
                        rowData => ({
                            icon: 'edit',
                            tooltip: '编辑方案',
                            onClick: (event, rowData) => {
                                onhandleUpdateSolution(rowData)
                            },
                        }),
                        // rowData => ({
                        //     icon: 'delete_outlined',
                        //     tooltip: '删除方案',
                        //     onClick: (event, rowData) => {
                        //         onhandleDeleteSolution(rowData)
                        //     },
                        // })
                    ]
                }
                break;
            default:
            // do nothing
        }
        return actions;
    }

    //Render Action
    const renderSolutionOptions = () => {
        let solutionOptions = {
            ...options,
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
            defaultExpanded: true,
            headerStyle: {
                backgroundColor: '#eeeeee',
                fontWeight: 'bold'
            }
        };
        switch (routeType) {
            case 'needAppointment':
                if (checkOrderHead() && !currentWorkOrder.masterOrder) {
                    solutionOptions.toolbar = true;
                }
                break;
            default:
            // do nothing
        }
        return solutionOptions;
    }

    //Render Action
    const renderDOCActions = (type) => {
        let actions = [];
        switch (routeType) {
            // case 'needAppointment':
            //     break;
            case 'needWork':
                if (checkOrderHeadForBigPermit()
                    && !currentWorkOrder.masterOrder && type !== 'JSA'
                    && _.filter(masterBigpermitDocs, { status: 'approved' }).length === 0
                    && _.filter(masterBigpermitDocs, { status: 'done' }).length === 0) {
                    actions = [
                        {
                            icon: 'add',
                            hidden: Boolean(_.filter(masterBigpermitDocs, { status: 'active' }).length > 0),
                            tooltip: type === 'JSA' ? '上传JSA文档' : '上传大许可文档',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleAddBigPermitDoc(event, type)
                            },
                        },
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: type === 'JSA' ? '删除JSA文档' : '删除大许可文档',
                            onClick: (event, rowData) => {
                                onhandleDeleteWorkPermitDoc(type, rowData)
                            },
                        })
                    ]
                }
                if (checkOrderHeadForBigPermit() && !currentWorkOrder.masterOrder && type === 'JSA') {
                    actions = [
                        {
                            icon: 'add',
                            disabled: Boolean(newAppointmentChange),
                            tooltip: type === 'JSA' ? '上传JSA文档' : '上传大许可文档',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleAddBigPermitDoc(event, type)
                            },
                        },
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: type === 'JSA' ? '删除JSA文档' : '删除大许可文档',
                            onClick: (event, rowData) => {
                                onhandleDeleteWorkPermitDoc(type, rowData)
                            },
                        })
                    ]
                }
                break;
            default:
            // do nothing
        }
        return actions;
    }

    const DOCcomponents = {
        Toolbar: props => (
            <div>
                <Grid container alignItems="center">
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid container justify="flex-end" className={classes.title}>
                        {_.filter(masterBigpermitDocs, { status: 'active' }).length > 0 &&
                            <Button variant={'contained'} color={'primary'} disableElevation style={{ marginBottom: 10 }}
                                onClick={() => { onhandleApprovePermitDoc() }}
                            >
                                {'大许可上传完成'}
                            </Button>
                        }
                    </Grid>
                </Grid>
                <MTableToolbar {...props} />
            </div>
        )
    }

    const renderDOCOptions = (type) => {
        let solutionOptions = {
            ...options,
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
            defaultExpanded: true,
            headerStyle: {
                backgroundColor: '#eeeeee',
                fontWeight: 'bold'
            }
        };
        switch (routeType) {
            // case 'needAppointment':

            //     break;
            case 'needWork':
                if (checkOrderHeadForBigPermit() && !currentWorkOrder.masterOrder && type !== 'JSA'
                    && _.filter(masterBigpermitDocs, { status: 'approved' }).length === 0
                    && _.filter(masterBigpermitDocs, { status: 'done' }).length === 0) {
                    solutionOptions.toolbar = true;
                }
                if (checkOrderHead() && !currentWorkOrder.masterOrder && type === 'JSA') {
                    solutionOptions.toolbar = true;
                }
                break;
            default:
            // do nothing
        }
        return solutionOptions;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Card className={classes.card}>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'作业列表'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                {statusCode && statusCode === 'new' ?
                    <Grid container>
                        <Grid item className={classes.flexgrow}></Grid>
                        <Grid item>
                            <List >
                                {renderActions()}
                            </List>
                        </Grid>
                    </Grid>
                    :
                    <Alert
                        classes={{
                            message: classes.permitBar,
                        }}
                        severity={renderPermitPanel()}>
                        <AlertTitle>
                            <Grid container justify={'space-between'}>
                                <Typography><strong>{'作业预约审批'}</strong></Typography>
                            </Grid>
                        </AlertTitle>
                        <Grid container>
                            <Grid item className={classes.processline}>
                                {bigPermit.permitUsers && bigPermit.permitUsers.length > 0 ?
                                    <ProcessLineWithUser idKey={'puId'}
                                        treeData={arrayToTree(_.cloneDeep(bigPermit.permitUsers), 'puId', 'preId')}
                                        processNotices={bigPermit.permitNotices ? _.cloneDeep(bigPermit.permitNotices) : []} />
                                    : null
                                }
                            </Grid>
                            <Grid item>
                                <List >
                                    {renderActions()}
                                </List>
                            </Grid>
                        </Grid>
                        {bigPermit.permitUsersHistory && bigPermit.permitUsersHistory.length > 0 ?
                            <Grid container >
                                <Grid item className={classes.processUser}>
                                    <Typography className={classes.userlabel}>审批记录：</Typography>
                                    <List className={classes.historyList}>
                                        {renderHistoy()}
                                    </List>
                                </Grid>
                            </Grid>
                            : null
                        }
                    </Alert>
                }
                <Grid container alignItems="center">
                    <Grid item className={classes.action} xs={3}>
                        <Grid item xs={4}>
                            <Typography className={classes.label}>
                                工单类型
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={classes.label}>
                                {renderOrderType(currentWorkOrder)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {currentWorkOrder.accName ?
                        <React.Fragment>
                            <Grid item className={classes.action} xs={3}>
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>
                                        部件
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>
                                        {currentWorkOrder.accName ? currentWorkOrder.accName : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.action} xs={3}>
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>
                                        （装置）设备
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>
                                        {currentWorkOrder.installName ? `（${currentWorkOrder.installName}）` : '（-）'}{currentWorkOrder.deviceName ? currentWorkOrder.deviceName : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item className={classes.action} xs={3}>
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>
                                        装置
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>
                                        {currentWorkOrder.installName ? currentWorkOrder.installName : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.action} xs={3}>
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>
                                        设备
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>
                                        {currentWorkOrder.deviceName ? currentWorkOrder.deviceName : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }


                </Grid>
                <Grid>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblyWorkOrdercolumns()}
                        data={workorderWithBigPermitList}
                        options={workorderTableOptions}
                        localization={localization}
                        style={tableStyle}
                    >
                    </MaterialTable>
                </Grid>
            </Card>
            <Card className={classes.card}>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'作业方案'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        className={classes.materialtable}
                        columns={solutionColumns}
                        data={masterWorkOrder.solutions}
                        options={renderSolutionOptions()}
                        localization={localization}
                        style={tableStyle}
                        actions={renderSolutionActions()}
                    />
                </Grid>
            </Card>
            {masterWorkOrder.solutions && masterWorkOrder.solutions.length > 0
                ? checkHaveMasterAppointment() ?
                    <Card className={classes.card}>
                        <Grid container alignItems="center">
                            <Grid container className={classes.title}>
                                <Typography variant="h3" className={classes.plantname}>
                                    {'作业预约'}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                        </Grid>
                        <Grid >
                            <MaterialTable
                                tableRef={tableRef}
                                className={classes.materialtable}
                                title=''
                                columns={columns}
                                data={appointmentDetails}
                                options={tableOptions}
                                components={components}
                                localization={localization}
                                style={tableStyle}
                                editable={{
                                    isEditHidden: rowData => checkRowEditRight(rowData) && !(isPausedRow(rowData) || isTerminatedRow(rowData)),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            onhandleUpdate(newData, oldData)
                                            resolve()
                                        }),
                                }}
                                actions={renderDetailActions()}
                            >
                            </MaterialTable>
                        </Grid>
                    </Card>
                    : <Card className={classes.card}>
                        <Grid container alignItems="center">
                            <Grid container className={classes.title}>
                                <Typography variant="h3" className={classes.plantname}>
                                    {'作业预约'}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.flexgrow}></Grid>
                        </Grid>
                        <Alert
                            classes={{
                                message: classes.permitBar,
                            }}
                            severity='warning'>
                            <AlertTitle>
                                <Grid container justify={'space-between'}>
                                    <Typography><strong>{'主工单预约完成后才能添加从工单的预约.'}</strong></Typography>
                                </Grid>
                            </AlertTitle>
                        </Alert>
                    </Card>
                : ''
            }
            {
                routeType === 'needWork' ?
                    <React.Fragment>
                        <Card className={classes.card}>
                            <Grid container alignItems="center">
                                <Grid container className={classes.title}>
                                    <Typography variant="h3" className={classes.plantname}>
                                        {'大许可'}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.flexgrow}></Grid>
                            </Grid>
                            {/* <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
                            {
                                _.filter(masterBigpermitDocs, { status: 'active' }).length > 0 &&
                                <Button variant={'contained'} color={'primary'} disableElevation style={{ marginBottom: 10 }}
                                // onClick={() => { onhandleDonePermit(null, showtype) }}
                                >
                                    {'大许可上传完成'}
                                </Button>
                            }
                        </Grid> */}
                            <Grid >
                                <MaterialTable
                                    className={classes.materialtable}
                                    components={DOCcomponents}
                                    columns={assemblyDOCColumns('BigPermit')}
                                    data={masterBigpermitDocs}
                                    options={renderDOCOptions('BigPermit')}
                                    localization={localization}
                                    style={tableStyle}
                                    actions={renderDOCActions('BigPermit')}
                                />
                            </Grid>
                        </Card>
                        {/* {_.filter(appointmentDetails, function (o) { return o.appId }).length > 0 ? */}
                        <Card className={classes.card}>
                            <Grid container alignItems="center">
                                <Grid container className={classes.title}>
                                    <Typography variant="h3" className={classes.plantname}>
                                        {'JSA分析'}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.flexgrow}></Grid>
                            </Grid>
                            <Grid >
                                <MaterialTable
                                    className={classes.materialtable}
                                    columns={assemblyDOCColumns('JSA')}
                                    data={masterJSADocs}
                                    options={renderDOCOptions('JSA')}
                                    localization={localization}
                                    style={tableStyle}
                                    actions={renderDOCActions('JSA')}
                                />
                            </Grid>
                        </Card>
                        {/* : null
                        } */}
                    </React.Fragment>
                    : null
            }
            <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>
                </Grid>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={newAppointmentChange} />
        </div >
    );

};
CreateAppointment.propTypes = {
    classes: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired,
    orderTypeList: PropTypes.array.isRequired,
    specCodeList: PropTypes.array.isRequired,
    newAppointmentChange: PropTypes.bool.isRequired,
    currentWorkOrder: PropTypes.object.isRequired,
    onhandleAddDetail: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleView: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleHeadClick: PropTypes.func.isRequired,
    userCompanyId: PropTypes.number.isRequired,
    userProfile: PropTypes.object.isRequired,
    statusCode: PropTypes.string.isRequired,
    actions: PropTypes.array,
    onhandleProcess: PropTypes.func.isRequired,
    bigPermit: PropTypes.object.isRequired,
    workorderWithBigPermitList: PropTypes.array.isRequired,
};

export default (withStyles(CreateAppointment));