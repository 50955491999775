import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination,
    TextField,
    MenuItem,
    Checkbox
    // Input,
    // InputAdornment,
    // IconButton,
    // FormHelperText
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options } from "../../../../utils/mtable";
// import UserCard from '../../../../containers/UserCard';
import { getDateByTimestamp } from '../../../../utils/datetime';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
import * as _ from 'lodash';

const MajorHazardList = props => {
    const {
        classes,
        majorHazardlist,
        userProfile,
        handleGetList,
        catList,
        useStatusList,
        dangerLevelList,
        onSizeChange,
        pageSize,
        handleSeletDevice,
        handleUpdate,
        handleDelete,
        extcatList,
        // mediumList,
        handleUpload,
        // handleSelectUser
        handleUpdMajorHazardDevices
    } = props;
    const tableRef = React.createRef();
    const [page, setPage] = useState(0);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'majorHazard_list' })
        return permission && permission.action === 'W'
    }

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // let catMap = _.chain(catList)
    //     .keyBy('code')
    //     .mapValues('name')
    //     .value();

    const renderExtCat = (device) => {
        let label = '';
        if (extcatList && extcatList.length > 0) {
            let value = _.find(extcatList, function (o) { return o.code === device.extCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    let extCatMap = _.chain(extcatList)
        .keyBy('code')
        .mapValues('name')
        .value();

    const renderDangerLevel = (device) => {
        let label = '';
        if (dangerLevelList && dangerLevelList.length > 0) {
            let value = _.find(dangerLevelList, function (o) { return o.code === device.dangerLevel })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderUseStatus = (device) => {
        let label = ''
        if (useStatusList && useStatusList.length > 0) {
            let value = _.find(useStatusList, function (o) { return o.code === device.useStatus })
            if (value) {
                label = value.name
            }
        }
        return label
    }

    let useStatusMap = _.chain(useStatusList)
        .keyBy('code')
        .mapValues('name')
        .value();

    // let mediumMap = _.chain(mediumList)
    //     .keyBy('code')
    //     .mapValues('name')
    //     .value();

    const renderTime = (device) => {
        return device.updateTime ? getDateByTimestamp(device.updateTime, true) : getDateByTimestamp(device.createTime, true)
    }

    const renderTarget = (data) => {
        let mapping = { 'AREA': '区域', 'UNIT': '单元', 'INST': '装置' };
        return mapping[data.target]
    }

    // const renderMedium = (data) => {
    //     let medium = ''
    //     if (_.isString(data.medium)) {
    //         let arr = data.medium.split(',')
    //         let vals = []
    //         for (let j = 0; j < arr.length; j++) {
    //             if (mediumList && mediumList.length > 0) {
    //                 let mediumValue = _.find(mediumList, function (o) { return o.code === arr[j] })
    //                 if (mediumValue) {
    //                     vals.push(mediumValue.name)
    //                 }
    //             }
    //         }
    //         medium = vals.join(',')
    //     }
    //     return medium
    // }

    const assembleRelationDevicePanel = () => {
        return [{
            tooltip: '展开关联设备',
            render: rowData => {
                return renderItems(rowData);
            }
        }];
    }

    const assemblyRelationDeviceColumns = () => {

        let columns = [
            {
                field: 'type',
                width: '2em',
                editable: 'never',
                filtering: false,
                render: row => row ? <Checkbox
                    disabled={Boolean(!checkPermission())}
                    checked={row.dangerLevel === 'Y' ? true : false}
                    // onChange={(event) => handleSetMajorHazardDevice(row)}
                    onChange={(event) => {
                        if (row.dangerLevel === 'Y') {
                            row.dangerLevel = 'N';
                        } else {
                            row.dangerLevel = 'Y';
                        }

                        tableRef.current.forceUpdate();
                    }}

                /> : ''
            },
            {
                title: '设备组',
                field: 'groupName',
                defaultGroupOrder: 1,
                defaultGroupSort: 'asc',
                filtering: false
            },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '扩展类别',
                field: 'extCat',
                render: row => row ? <Typography>{renderExtCat(row)}</Typography> : '',
                editable: 'never',
                lookup: extCatMap,
            },
            {
                title: '设备类型',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
                filtering: false
            },
            {
                title: '位号',
                field: 'tagNo',
                render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '介质',
                field: 'mediumName',
                render: row => row ? <Typography>{row.mediumName}</Typography> : '',
                editable: 'never',
            },
            {
                title: '使用状态',
                field: 'useStatus',
                render: row => row ? <Typography>{renderUseStatus(row)}</Typography> : '',
                editable: 'never',
                lookup: useStatusMap,
            },
        ]

        return columns
    }

    const renderItems = (inData) => (

        < React.Fragment >
            <Grid className={classes.detailtable}>
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblyRelationDeviceColumns()}
                    data={inData.majorHazardDevices ? inData.majorHazardDevices : []}
                    options={{
                        ...options,
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        defaultExpanded: true,
                        search: false,
                        paging: false,
                        toolbar: true,
                        filtering: true,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    actions={checkPermission() ? [
                        {
                            icon: 'save',
                            tooltip: '保存设备组的指定',
                            isFreeAction: true,
                            onClick: (event) => {
                                handleUpdMajorHazardDevices(event, inData.majorHazardDevices)
                            },
                        },
                    ] : []}
                >
                </MaterialTable>
            </Grid>
        </React.Fragment >
    );


    const assemblycolumns = () => {

        let columns = [
            {
                title: '名称',
                field: 'hazardName',
                render: row => row ? <Typography>{row.hazardName}</Typography> : '',
                validate: rowData => (!(rowData && rowData.hazardName)) ? { isValid: false, helperText: '名称必须输入' } : true,
                editComponent: (props) => (
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        value={props.rowData.hazardName}
                        type="text"
                        onChange={e => props.onChange(e.target.value)}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}>
                    </TextField>
                )
            },
            {
                title: '危险级别',
                field: 'dangerLevel',
                render: row => row ? <Typography>{renderDangerLevel(row)}</Typography> : '',
                validate: rowData => (!rowData.dangerLevel || rowData.dangerLevel === '') ? { isValid: false, helperText: '危险级别不能为空' } : true,
                editComponent: (props) => (
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        fullWidth
                        variant="standard"
                        placeholder="请选择"
                        name='dangerLevel'
                        value={props.rowData.dangerLevel}
                        onChange={e => props.onChange(e.target.value)}
                        select
                    >
                        {_.sortBy(dangerLevelList, 'seq').map((option) => (
                            <MenuItem key={option.code} value={option.code}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            },
            // {
            //     title: '负责人',
            //     field: 'hazardHead',
            //     render: row => row && row.hazardHeadLastName && row.hazardHeadFirstName ? <UserCard userId={row.hazardHead} child={<Typography>{row.hazardHeadLastName + row.hazardHeadFirstName}</Typography>} /> : row.hazardHeadFirstName ? <UserCard userId={row.hazardHead} child={<Typography>{row.hazardHeadFirstName}</Typography>} /> : '',
            //     validate: rowData => (!(rowData && rowData.hazardHead)) ? { isValid: false, helperText: '负责人必须选择' } : true,
            //     editComponent: (props) => (
            //         <div>
            //             <Input
            //                 id="standard-adornment-hazardHead"
            //                 type={'text'}
            //                 value={props.rowData.hazardHeadLastName && props.rowData.hazardHeadFirstName ? props.rowData.hazardHeadLastName + props.rowData.hazardHeadFirstName : props.rowData.hazardHeadFirstName ? props.rowData.hazardHeadFirstName : ''}
            //                 // onChange={handleChange('password')}
            //                 disabled={true}
            //                 error={Boolean(props.helperText)}
            //                 endAdornment={
            //                     <InputAdornment position="end">
            //                         <IconButton
            //                             aria-label="toggle password hazardHead"
            //                             onClick={event => handleSelectUser(event, (user) => {
            //                                 props.rowData.hazardHead = user.userId;
            //                                 props.rowData.hazardHeadLastName = user.lastName;
            //                                 props.rowData.hazardHeadFirstName = user.firstName;
            //                                 tableRef.current.forceUpdate();
            //                             })}
            //                         >
            //                             <AccountBoxIcon />
            //                         </IconButton>
            //                     </InputAdornment>
            //                 }
            //             />
            //             {props.helperText ? <FormHelperText error={Boolean(props.helperText)} id="component-error-text">{props.helperText}</FormHelperText> : null}
            //         </div>
            //     )
            // },
            {
                title: '所属装置',
                field: 'installName',
                render: row => row ? <Typography>{row.installName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单元类别',
                field: 'target',
                render: row => row ? <Typography>{renderTarget(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单元名称',
                field: 'targetName',
                render: row => row ? <Typography>{row.targetName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={majorHazardlist.list}
                    options={{
                        ...options,
                        pageSize: _.toInteger(pageSize),
                        tableLayout: 'auto',
                        actionsColumnIndex: -1,
                        search: false,
                        defaultExpanded: true,
                        toolbar: checkPermission() ? true : false,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    detailPanel={assembleRelationDevicePanel()}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[5, 10, 20]}
                                rowsPerPage={_.toInteger(pageSize)}
                                count={majorHazardlist.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    handleGetList({ searchType: 'ALL', offset: pageNum * pageSize, size: pageSize, sort: "-update_time" })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    handleGetList({ searchType: 'ALL', offset: 0, size: event.target.value, sort: "-update_time" })
                                }}

                            />
                        )
                    }}
                    editable={{
                        isEditHidden: rowData => Boolean(!checkPermission()),
                        isDeleteHidden: rowData => Boolean(!checkPermission()),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleUpdate(newData)
                                resolve()
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                handleDelete(oldData)
                                resolve()
                            }),
                    }}
                    actions={checkPermission() ? [
                        rowData => ({
                            icon: 'cloud_upload',
                            tooltip: '上传评估报告',
                            hidden: Boolean(!(rowData.hazardId && rowData.hazardId !== '')),
                            onClick: (event, rowData) => {
                                handleUpload(rowData)
                            },
                        }),
                        {
                            icon: 'add',
                            tooltip: '指定重大危险源',
                            isFreeAction: true,
                            onClick: (event) => {
                                handleSeletDevice()
                            },
                            disabled: Boolean(_.filter(majorHazardlist.list, function (o) { return o.tableData && o.tableData.editing }).length > 0)
                        },
                    ] : []}
                >
                </MaterialTable>
            </Grid>
        </div >
    );
};

MajorHazardList.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    catList: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    handleGetList: PropTypes.func.isRequired,
    handleSeletDevice: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default withStyles(MajorHazardList);
