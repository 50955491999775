import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationPlanDocs from '@/components/DeviceManagement/OperationPlanDocs';
import * as _ from 'lodash';

class OperationPlanDocsContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        userProfile: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: this.getRoleW() ? 0 : 1
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue
        })
    }

    getRoleW = () => {
        let permission = _.find(this.props.userProfile.roles[0].routes, { 'route': 'operation_plan_doc' })
        return permission && permission.action === 'W'
    }

    render() {


        return (
            <div>
                <OperationPlanDocs
                    tabValue={this.state.tabValue}
                    userProfile={this.props.userProfile}
                    roleW={this.getRoleW()}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanDocsContainer);