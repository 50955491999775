import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { formatDate } from "@/utils/datetime"
import * as _ from 'lodash';

const TrackMaterialCostComponent = props => {
    const { classes,
        materialCosts,
        departments,
        companies,
        subs,
    } = props;

    const getCompanyName = (companyId) => {
        const company = _.find(companies, c => c.companyId === companyId)
        return company ? company.companyAlias : ''
    }

    const getDptName = (dptId) => {
        const dpt = _.find(departments, d => d.dptId === dptId)
        return dpt ? dpt.dptName : ''
    }

    const getSubName = (subId) => {
        const sub = _.find(subs, d => d.subId === subId)
        return sub ? sub.subName : ''
    }

    const formatNumber = (num) => {
        if (!num || isNaN(num)) return 0.0
        return Number(num).toFixed(2)
    }

    const assemblycolumns = () => {

        const commonColumns = [
            {
                title: '公司',
                dataIndex: 'companyId',
                key: 'companyId',
                render: (text, row, index) => <Typography>{getCompanyName(row.companyId)}</Typography>,
            },
            {
                title: '班组',
                dataIndex: 'dptId',
                key: 'dptId',
                render: (text, row, index) => <Typography>{getDptName(row.dptId)}</Typography>,
            },
            // {
            //     title: '指标',
            //     dataIndex: 'subId',
            //     key: 'subId',
            //     render: (text, row, index) => <Typography>{getSubName(row.subId)}</Typography>,
            // },
            {
                title: '日期',
                dataIndex: 'actionDate',
                key: 'actionDate',
                render: (text, row, index) => <Typography>{formatDate(row.actionDate)}</Typography>,
            },
            {
                title: '单据',
                dataIndex: 'billNo',
                key: 'billNo',
                render: (text, row, index) => <Typography>{row.billNo}</Typography>,
            },
            {
                title: '物料',
                dataIndex: 'materialName',
                key: 'materialName',
                render: (text, row, index) => <Typography>{row.materialName}</Typography>,
            },
            {
                title: '规格',
                dataIndex: 'specification',
                key: 'specification',
                render: (text, row, index) => <Typography>{row.specification}</Typography>,
            }, {
                title: '数量',
                dataIndex: 'qty',
                key: 'qty',
                render: (text, row, index) => <Typography>{formatNumber(row.qty) + row.unitName}</Typography>,
            }, {
                title: '价格',
                dataIndex: 'unitPrice',
                key: 'unitPrice',
                render: (text, row, index) => <Typography>{formatNumber(row.unitPrice)}</Typography>,
            }, {
                title: '修正价格',
                dataIndex: 'fixedPrice',
                key: 'fixedPrice',
                render: (text, row, index) => <Typography>{formatNumber(row.fixedPrice)}</Typography>,
            }, {
                title: '金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, row, index) => <Typography>{row.unitPrice ? formatNumber(row.amount) : formatNumber(row.fixedPrice * row.qty)}</Typography>,
            }
        ]

        return commonColumns

    }

    const assemblyData = () => {
        return materialCosts
    }

    const renderTitle = () => {
        if (!materialCosts || materialCosts.length === 0) {
            return '';
        } else {
            return getSubName(materialCosts[0].subId)
        }

    }




    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid><Typography variant='h3'>{renderTitle()}</Typography></Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <Grid>
                    <Table
                        className={classes.stripedTable}
                        rowKey={record => record.billNo + record.dtlId}
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        // pagination={{ hideOnSinglePage: true }}
                        // scroll={{ x: '100%', y: 'calc(100vh - 310px)' }}
                        size={'small'}
                        pagination={{ pageSize: 10 }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

TrackMaterialCostComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    materialCosts: PropTypes.array,
    departments: PropTypes.array,
    companies: PropTypes.array,
};

TrackMaterialCostComponent.defaultProps = {
    materialCosts: [],
    departments: [],
    companies: [],
};

export default withStyles(TrackMaterialCostComponent);