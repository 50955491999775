import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorkPermitDocPanel from '@/components/OperationManagement/Appointment/CreateAppointment/WorkPermitDocPanel';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import _ from 'lodash';

class WorkPermitDocPanelContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docError: {
                docName: '',
                file: '',
            },
            open: false,
            type: null,
            doc: {},
            confirmCallback: null,
            closeCallback: null,
        };
    }
    componentDidUpdate = (oldProps) => {
        if (oldProps.open !== this.props.open) {
            this.setState({
                docError: {
                    docName: '',
                    file: '',
                },
                open: this.props.open,
                doc: {},
                type: this.props.type,
                confirmCallback: this.props.confirmCallback,
                closeCallback: this.props.closeCallback,
            })
        }

    }

    handleConfirm() {
        const { confirmCallback, doc } = this.state;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(doc)
            // if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { closeCallBack } = this.props;
        // close action
        if (closeCallBack) {
            closeCallBack()
        } else {
            this.setState({
                open: false
            })
        }

    }

    handleChange(event) {
        let newDoc = {
            ...this.state.doc
        }
        const field = event.target.name;
        newDoc[field] = event.target.value;

        this.setState({
            doc: newDoc
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleUploadConfirm(files, setUploadStatus) {
        let newDoc = {
            ...this.state.doc
        }
        newDoc.file = files;

        this.setState({
            doc: newDoc
        })
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
        setTimeout(() => {
            this.handleCheck(null, 'file');
        }, 100)
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field || field === 'docName') {
            if (_.trim(this.state.doc.docName) === '') {
                err['docName'] = '名称不能为空';
            } else {
                err['docName'] = '';
            }
        }

        if (!field || field === 'file') {
            // 检测文件是否上传
            if (!this.state.doc.file || !this.state.doc.file.length || this.state.doc.file.length === 0
            ) {
                err['file'] = '请上传方案文档';
            } else {
                err['file'] = '';
            }
        }

        let newError = {
            ...this.state.docError,
            ...err
        }
        this.setState({
            docError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <WorkPermitDocPanel
                open={this.props.open}
                doc={this.state.doc}
                type={this.state.type}
                docError={this.state.docError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkPermitDocPanelContainer);