import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const HazardClosePannel = ({
    classes,
    open,
    hazardError,
    hazard,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck
}) => {
    // console.log('hazard comp')
    // console.log(hazard)

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"隐患销项"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        {Boolean(hazard.opentext) &&
                            <Grid item className={classes.itemGrid}>
                                <Typography component={'span'}><strong>{'隐患描述：'}</strong></Typography>
                                <Typography component={'span'}>{hazard.opentext}</Typography>
                            </Grid>
                        }
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"销项理由"}
                                variant='outlined'
                                error={hazardError.reason !== ''}
                                helperText={hazardError.reason}
                                className={classes.textField}
                                value={hazard.reason || ''}
                                rows={7}
                                name='reason'
                                onChange={event => onhandleChange(event)}
                                onBlur={event => onhandleCheck(event)}
                            />
                            <Typography component={'span'} variant="overline">
                                <span className={classes.required}>* </span>
                                {"销项后，本条目将不再被视为隐患，且无需进行后续治理。"}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        确定</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
HazardClosePannel.propTypes = {
    classes: PropTypes.object.isRequired,
    hazardError: PropTypes.object.isRequired,
    hazard: PropTypes.object.isRequired
};

HazardClosePannel.defaultProps = {

}

export default withStyles(HazardClosePannel);