import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FileUpload } from '../components';
import { cleanmodal } from '../redux/modules/modal';

class FileUploadPageContainer extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired
  }

  handeModalclose(event) {
    this.props.onModalclose();
  }

  render() {
    const { config } = this.props;
    return (
      <FileUpload
        title={config.title}
        fileType={config.fileType}
        note={config.note}
        maxSize={config.maxSize}
        multiple={config.multiple}
        onClose={this.handeModalclose.bind(this)}
        onFileSelected={config.onFileSelected}
        onConfirm={config.onConfirm}
        docName={config.docName}
        versionOrder={config.versionOrder}
        paraA={config.paraA}
        paraB={config.paraB}
        paraC={config.paraC}
        paraD={config.paraD}
      />
    );
  }

  componentWillUnmount() {
    if (this.props.onModalclose) this.props.onModalclose();
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.modal.parameters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onModalclose: () => {
      dispatch(cleanmodal());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadPageContainer);
