import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Card,
    Grid,
    TextField,
    Typography,
    TablePagination,
    Chip,
    Menu,
    MenuItem,
    //提示框
    Tooltip
} from '@material-ui/core';
import {
    Add,
    // Backup,
    Description,
    Edit,
    ExpandLess,
    ExpandMore,
    // GetApp,
    MoreHoriz,
    // EventBusy,
    // EventAvailable,
    DeleteOutlined
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
// import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
// import zhCN from 'date-fns/locale/zh-CN';
// import DateFnsUtils from '@date-io/date-fns';
// import { truncate } from "../../../../utils/string";
import { getMoment as moment, getDateByTimestamp, renderValidInfo } from '@/utils/datetime';
import _ from 'lodash';

const SupplierList = props => {
    const {
        classes,
        lists,
        search,
        currentUserInfo,
        companyList,
        userProfile,
        getSuppliers,
        onhandleEdit,
        onhandleChange,
        onhandleSelectChange,
        // onhandleDateChange,
        onhandleSearch,
        onhandleClearSearch,
        // onhandleDownloadTemp,
        // onhandleUpload,
        onhandlePageChange,
        onhandleListMapping,
        // onSetExpire,
        onDelete,
    } = props;

    console.log('lists')
    console.log(lists)
    const [expand, setExpand] = useState(false);
    const [tableHeight, setTableHeight] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215;
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'supplier_list' })
        return permission && (permission.action === 'W' || permission.action === 'R')
    }

    const supplierEditable = (rowData) => {
        if (!rowData) return false;
        // check公司
        const companyId = currentUserInfo.companyId;
        const company = _.find(companyList, { 'companyId': companyId });
        const hasPcomp = Boolean(company && company.pid);

        if (!hasPcomp && company.companyId !== currentUserInfo.companyId) return false;
        if (hasPcomp && rowData.device && rowData.device.companyId !== currentUserInfo.companyId) return false;
        return true;
    }

    const renderValidStatus = (rowData) => {
        const nowMoment = moment()();
        let label = '有效';
        let statusStyle = classes.valid;
        if (rowData.status === 'active') {
            label = '有效';
            statusStyle = classes.valid;
            if (rowData.startDate && moment()(rowData.startDate).isAfter(nowMoment, 'day')) {
                label = '未生效';
                statusStyle = classes.pending;
            }
        }
        return <Tooltip aria-label="{row.opentext}" title={renderValidInfo(rowData.startDate, rowData.endDate)}>
            <Chip size="small" className={statusStyle} label={label} />
        </Tooltip>

    }

    const openMenu = (event, row) => {
        let anchorElement = event.currentTarget;
        setCurrentRow(row);
        setAnchorEl(anchorElement);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const generateMenu = () => {


        // rowData => ({
        //     icon: rowData.status === 'active' ? 'event_busy' : 'event_available',
        //     tooltip: rowData.status === 'active' ? '失效' : '恢复生效',
        //     onClick: (event, rowData) => {
        //         onSetExpire(event, rowData)
        //     },
        //     disabled: !supplierEditable(rowData)
        // }),
        // rowData => ({
        //     icon: 'delete_outlined',
        //     tooltip: '删除',
        //     onClick: (event, rowData) => {
        //         onDelete(event, rowData.insId)
        //     },
        //     disabled: !supplierEditable(rowData)
        // }),
        return (
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    horizontal: 'bottom',
                }}
            >

                {/*<MenuItem dense*/}
                {/*    className={currentRow && currentRow.status === 'active' ? classes.menuItemCritical : classes.menuItemNormal}*/}
                {/*    disabled={!supplierEditable(currentRow)} onClick={() => { onSetExpire(null, currentRow) }}>*/}
                {/*    {currentRow && currentRow.status === 'active' ? <EventBusy className={classes.menuIcon} /> : <EventAvailable className={classes.menuIcon} />}{currentRow && currentRow.status === 'active' ? '删除' : '恢复生效'}*/}
                {/*</MenuItem>*/}
                <MenuItem dense className={classes.menuItemCritical} disabled={!supplierEditable(currentRow)}
                    onClick={() => {
                        onDelete(null, currentRow.supplierId)
                    }}>
                    <DeleteOutlined className={classes.menuIcon} />{"删除"}
                </MenuItem>
            </Menu>
        );
    }

    // const companyOptions = () => (
    //     <React.Fragment>
    //         {companyList ?
    //             companyList.map(data => (
    //                 <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
    //                 // <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // const statusOptions = () => {
    //     return <React.Fragment>
    //         {
    //             [{ code: "active", name: "有效" }, { code: "expired", name: "过期" }].map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             ))
    //         }
    //     </React.Fragment>
    // }

    const tableOptions = {
        ...options,
        pageSize: search.size,
        tableLayout: 'auto',
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        toolbar: false,
        maxBodyHeight: `${tableHeight}px`,
        minBodyHeight: `${tableHeight}px`,
    }

    const tableStyle = {
        ...style,
        border: '0px solid black',
        boxShadow: 'none'
    }

    const assemblycolumns = () => {

        let columns = [
            // {
            //     title: '承包商序列号',
            //     field: 'supplierId',
            //     render: row => row ? <Typography>{row && row.supplierId ? onhandleListMapping(row.supplierId, 'supplierId') : "-"}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '编号',
            //     field: 'deviceNo',
            //     render: row => row ? <Typography>{row.device ? row.device.deviceNo : '-'}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '承包商名称',
                field: 'supplierName',
                render: row => row ?
                    <Typography>{row && row.supplierName ? onhandleListMapping(row.supplierName, 'supplierName') : "-"}</Typography> : '',
                // width: 300,
                editable: 'never'
            },
            {
                title: '承包商编号',
                field: 'supplierNo',
                render: row => row ?
                    <Typography>{row && row.supplierNo ? onhandleListMapping(row.supplierNo, 'supplierNo') : "-"}</Typography> : '',
                width: 100,
                editable: 'never'
            },

            {
                title: '分组名称',
                field: 'supplierGroupCode',
                render: row => row ? <Typography>{row.supplierGroupName || ""}</Typography> : '',
                width: 100,
                align: "center",
                editable: 'never'
            },
            // {
            //     title: '创建人姓',
            //     field: 'creatorLastName',
            //     render: row => row ? <Typography>{row.creatorLastName || '-'}</Typography> : '-',
            //     editable: 'never'
            // },
            // {
            //     title: '创建人名称',
            //     field: 'creatorFirstName',
            //     render: row => row ? <Typography>{row.creatorFirstName || '-'}</Typography> : '-',
            //     editable: 'never'
            // },
            // {
            //     title: '修改人姓',
            //     field: 'revisorLastName',
            //     render: row => row ? <Typography>{row.revisorLastName || '-'}</Typography> : '-',
            //     editable: 'never'
            // },
            // {
            //     title: '修改人名称',
            //     field: 'revisorFirstName',
            //     render: row => row ? <Typography>{row.revisorFirstName || '-'}</Typography> : '-',
            //     editable: 'never'
            // },

            {
                title: '关联公司',
                field: 'companyName',
                render: row => row ? <Typography>{row.companyName || ""}</Typography> : '',
                width: 200,
                align: "center",
                editable: 'never'

            },
            {
                title: '创建日期',
                field: 'createTime',
                render: row => row ?
                    <Typography title={row.createTime}>{getDateByTimestamp(row.createTime, false)}</Typography> : '',
                width: 100,
                editable: 'never'
            },
            {
                title: '修改日期',
                field: 'updateTim',
                render: row => row ?
                    <Typography title={row.updateTime}>{getDateByTimestamp(row.updateTim, false)}</Typography> : '',
                editable: 'never',
                width: 100,
            },
            {
                title: '状态',
                field: '?',
                render: row => row ? renderValidStatus(row) : '-',
                width: 100,
                align: 'center',
                editable: 'never'
            }
            // {
            //     title: '公司id',
            //     field: 'companyId',
            //     render: row => row ? <Typography>{row.device && row.companyId ? onhandleListMapping(row.device.companyId, '-') : "-"}</Typography> : '',
            //     editable: 'never'
            // }
        ]

        return columns
    }

    const components = {
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[15, 30, 50]}
                rowsPerPage={search.size}
                count={lists.total}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum, 'page');
                    getSuppliers({
                        ...search,
                        offset: pageNum * search.size,
                        size: search.size
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                    // onhandlePageChange(0, 'page')
                    getSuppliers({
                        ...search,
                        offset: 0,
                        size: event.target.value
                    })
                }}

            />
        )
    }

    return (
        <Card className={classes.card}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'承包商管理'}</Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={7}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            {'单位'}</Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            variant="outlined"
                            value={search.companyId || 0}
                            onChange={(event) => onhandleSelectChange(event, 'companyId')}
                        >
                            <MenuItem aria-label="None" value={0}>{'全部'}</MenuItem>
                            {companyList ?
                                companyList.map(data => (
                                    <MenuItem value={data.companyId} key={data.companyId}>{data.companyAlias}</MenuItem>
                                    // <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
                                )) : null
                            }
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            {'承包商名称'}
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            value={search.supplierName || ''}
                            size={'small'}
                            variant="outlined"
                            placeholder="请输入承包商名称"
                            onChange={event => onhandleChange(event, 'supplierName')}
                        />

                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={6} lg={4}>
                        <Typography className={classes.label}>
                            {'承包商编号'}
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            value={search.supplierNo || ''}
                            size={'small'}
                            variant="outlined"
                            placeholder="承包商编号"
                            onChange={event => onhandleChange(event, 'supplierNo')}
                        />
                    </Grid>

                    {Boolean(expand) && <React.Fragment>
                        <Grid container item className={classes.gridItem} sm={6} md={6} lg={4}>
                            <Typography className={classes.label}>
                                {'分组号'}
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                value={search.supplierGroupCode || ''}
                                size={'small'}
                                variant="outlined"
                                placeholder="分组编号"
                                onChange={event => onhandleChange(event, 'supplierGroupCode')}
                            />
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                状态</Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                variant="outlined"
                                select
                                value={search.status || 0}
                                onChange={(event) => onhandleSelectChange(event, 'status')}
                            >
                                <MenuItem aria-label="None" value={0}>{'全部'}</MenuItem>
                                {
                                    [{ code: "active", name: "有效" }, { code: "expired", name: "过期" }].map(data => (
                                        <MenuItem value={data.code} key={data.code}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </React.Fragment>}
                </Grid>

                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={5}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 5}>
                        <Button variant="contained" color="primary"
                            onClick={() => onhandleSearch()}
                        >{'搜索'}</Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}

                        >{'重置'}</Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 7}>
                        {checkPermission() && <React.Fragment>

                            <Button variant='contained'
                                color='primary'
                                onClick={() => {
                                    onhandleEdit(null, true)
                                }}
                                startIcon={<Add />}
                            >
                                新建
                            </Button>
                            {/*<Button variant='contained' style={{ marginLeft: '10px' }}*/}
                            {/*    color='primary'*/}
                            {/*    onClick={() => { onhandleDownloadTemp() }}*/}
                            {/*    startIcon={<GetApp />}*/}
                            {/*>*/}
                            {/*    下载模板*/}
                            {/*</Button>*/}
                            {/*<Button variant='contained' style={{ marginLeft: '10px' }}*/}
                            {/*    color='primary'*/}
                            {/*    onClick={() => { onhandleUpload() }}*/}
                            {/*    startIcon={<Backup />}*/}
                            {/*>*/}
                            {/*    记录导入*/}
                            {/*</Button>*/}
                        </React.Fragment>

                        }
                    </Grid>
                </Grid>
            </Grid>
            <div alignitems="center">
                <Grid className={classes.detailtable}>
                    {generateMenu()}
                    {/*表格組件*/}
                    <MaterialTable
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={tableOptions}
                        localization={localization}
                        style={tableStyle}
                        components={components}
                        actions={checkPermission() ?
                            [
                                // {
                                //     icon: 'add',
                                //     tooltip: '新建',
                                //     isFreeAction: true,
                                //     onClick: (event) => { onhandleEdit(null, true) }
                                // },
                                // {
                                //     icon: 'get_app',
                                //     tooltip: '下载保险模板',
                                //     isFreeAction: true,
                                //     onClick: (event) => { onhandleDownloadTemp() }
                                // },
                                // {
                                //     icon: 'backup',
                                //     tooltip: '导入保险记录',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => { onhandleUpload() }
                                // },
                                rowData => ({
                                    icon: () => <Edit color={!supplierEditable(rowData) ? 'disabled' : 'primary'} />,
                                    tooltip: '编辑',
                                    onClick: (event, rowData) => {
                                        onhandleEdit(rowData.supplierId, true)
                                    },
                                    disabled: !supplierEditable(rowData)
                                }),
                                rowData => ({
                                    icon: () => <Description color={'primary'} />,
                                    tooltip: '明细',
                                    onClick: (event, rowData) => {
                                        onhandleEdit(rowData.supplierId, false)
                                    }
                                }),
                                // rowData => ({
                                //     icon: rowData.status === 'active' ? 'event_busy' : 'event_available',
                                //     tooltip: rowData.status === 'active' ? '失效' : '恢复生效',
                                //     onClick: (event, rowData) => {
                                //         onSetExpire(event, rowData)
                                //     },
                                //     disabled: !supplierEditable(rowData)
                                // }),
                                // rowData => ({
                                //     icon: 'delete_outlined',
                                //     tooltip: '删除',
                                //     onClick: (event, rowData) => {
                                //         onDelete(event, rowData.insId)
                                //     },
                                //     disabled: !supplierEditable(rowData)
                                // }),
                                rowData => ({
                                    icon: () => <MoreHoriz
                                        color={!supplierEditable(rowData) ? 'disabled' : 'primary'} />,
                                    tooltip: '更多',
                                    onClick: (event, rowData) => {
                                        openMenu(event, rowData);
                                    },
                                    disabled: !supplierEditable(rowData)
                                }),
                            ] : [
                                rowData => ({
                                    icon: () => <Description color={'primary'} />,
                                    tooltip: '明细',
                                    onClick: (event, rowData) => {
                                        onhandleEdit(rowData.supplierId, false)
                                    }
                                }),
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>
        </Card>
    );
};

SupplierList.propTypes = {
    classes: PropTypes.object.isRequired,
    lists: PropTypes.object.isRequired,
    getSuppliers: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleEdit: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandlePageChange: PropTypes.func.isRequired
};

export default withStyles(SupplierList);
