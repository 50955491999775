import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefuelRecordComponent from '@/components/AmoebaManagement/RefuelRecord';
import { getMoment, convertToMoment } from '@/utils/datetime';
import { getRefuelRecords, createRefuelRecords, updateRefuelRecord } from '@/redux/modules/amoeba/refuelRecord';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import * as _ from 'lodash';
import { createMessage } from '@/redux/modules/message';
import { upload, download } from '@/redux/modules/minio';
import { judgeMainCompany } from '@/utils/manufactureUtils'
import { getEnvCode } from '@/utils/exportfile'
import { getNowTimestamp, formatDate } from "@/utils/datetime";

class RefuelRecordContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: getMoment()(),
            endDate: getMoment()(),
            openFlg: false,
            companyId: null,
            companies: [],
            isMainCompany: null,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const { companies, userInfo } = this.props
        // 查看是否机关
        const isMainCompany = judgeMainCompany(companies, userInfo.companyId);
        let companys = []
        if (isMainCompany) {
            companys = this.props.companies
        } else {
            companys.push(_.find(companies, company => company.companyId === userInfo.companyId))
        }
        this.setState({
            companyId: userInfo.companyId,
            companies: companys,
            isMainCompany: isMainCompany,
        })
        this.props.getRefuelRecords(null, userInfo.companyId, null, formatDate(this.state.startDate), formatDate(this.state.endDate));
    }

    handleDateChange = (date, dateType) => {
        if (dateType === 'startDate') {
            this.props.getRefuelRecords(null, this.state.companyId, null, formatDate(convertToMoment(date)), formatDate(this.state.endDate));
            this.setState({
                startDate: convertToMoment(date)
            })
        } else {
            this.props.getRefuelRecords(null, this.state.companyId, null, formatDate(this.state.startDate), formatDate(convertToMoment(date)));
            this.setState({
                endDate: convertToMoment(date)
            })
        }
    }

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }


    handleDownload() {
        let tenantId = this.props.userProfile.roles[0].tenantId;
        let envCode = getEnvCode()
        let mybucket = _.replace(envCode + '.{tenant_id}.refuel.record.doc', '{tenant_id}', tenantId);
        this.props.onhandleDownload('加油记录模板_' + this.props.userInfo.companyId + '.xlsx', mybucket);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'dev';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.refuel.record.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                docName: offset + files[0].name,
                docType: '',
            };
            this.props.onhandleCreateDoc(doc, () => {
                this.props.getRefuelRecords(null, this.state.companyId, null, formatDate(this.state.startDate), formatDate(this.state.endDate));
            });
            this.props.onhandleModalClose();
        });
    }

    handleUpdate = (data) => {

        let newRecord = {
            'recordId': data.recordId,
            'carNo': data.carNo,
            'amount': parseFloat(data.amount),
            'quantity': parseFloat(data.quantity),
            'unitPrice': parseFloat(data.unitPrice)
        }
        this.props.onhandleUpdateRecord(newRecord, () => {
            this.props.getRefuelRecords(null, this.state.companyId, null, formatDate(this.state.startDate), formatDate(this.state.endDate));
        });
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        this.props.getRefuelRecords(null, companyId, null, formatDate(this.state.startDate), formatDate(this.state.endDate));
        this.setState({
            companyId: companyId
        })
    }

    render() {
        return (
            <div>
                <RefuelRecordComponent
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    companyId={this.state.companyId}
                    companies={this.state.companies}
                    isMainCompany={this.state.isMainCompany}
                    refuelRecordList={this.props.refuelRecordList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleUpload={this.handleUpload.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDownload={this.handleDownload.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    dicts={this.props.dicts}
                    userProfile={this.props.userProfile}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        refuelRecordList: state.refuelRecord.refuelRecords,
        userProfile: state.auth.userProfile,
        userInfo: state.auth.currentUserInfo,
        orders: state.manufactureOrder.orders,
        dicts: state.constant.manufactureDicts,
        companies: state.constant.companies,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRefuelRecords: (station, companyId, oilType, startDate, endDate) => {
            dispatch(getRefuelRecords(station, companyId, oilType, startDate, endDate));
        },
        onhandleUploadPre: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(createRefuelRecords(doc, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updateRefuelRecord(record, callback));
        },
        onhandleDownload: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefuelRecordContainer);
