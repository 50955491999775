import { getStore } from '../../../../redux/store';
import { createDialog } from '../../redux/modules/dialog';
import { createContentMenu } from '../../redux/modules/contentmenuview';
import * as device from './core';
import * as _ from 'lodash';
export default class MapViewActionMapping {

    layerMapping(props, layer, stage, previewStage) {
        let groupArray = layer.find('Group'); //在layer中找出所有的Group
        if (groupArray && groupArray.length > 0) {

            groupArray.forEach(element => {
                if (element.id().indexOf('DeviceGroup') !== -1) {
                    this.deviceGroupClick(props, element, layer);
                    device.mappingDeviceGroup(props, element, layer, stage, previewStage, 'view');
                } else {
                    //For UAT add below lines
                    // let line = element.findOne('Arrow');
                    // if (line) {
                    //     line.stroke('black');
                    // }
                    //For UAT
                    this.groupHover(element, layer, stage);
                    this.groupClick(props, element, layer);
                }
            });
        }
    }

    groupHover(group, layer, stage) {
        group.draggable(false);
        let lineColor = 'black';
        //For UAT Comment below lines
        if (group.name() === 'Guandao') {
            group.find('Arrow').forEach(line => {
                lineColor = line.stroke();
            })
        }
        if (group.name() === 'Xian') {
            group.find('Line').forEach(line => {
                lineColor = line.stroke();
            })
        }
        //For UAT

        group.find('Circle').forEach(element => {
            element.draggable(false);
        });
        layer.batchDraw();

        group.on('contextmenu', function (e) {
            // prevent default behavior
            e.evt.preventDefault();
            if (group.name() !== 'Area' && group.name() !== 'Unit') {
                let containerRect = stage.container().getBoundingClientRect();
                let top = containerRect.top + group.getClientRect().y;
                let left = containerRect.left + group.getClientRect().x + group.getClientRect().width / 2;
                let store = getStore();
                store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
            }
        });

        group.on('mouseover', function () {
            group.find('Line').forEach(element => {
                element.stroke('red');
            });
            group.find('Arc').forEach(element => {
                element.stroke('red');
            });
            if (group.name() === 'Area' || group.name() === 'Unit') {
                group.find('Rect').forEach(element => {
                    element.stroke('red');
                });
            }
            group.find('Arrow').forEach(element => {
                element.stroke('red');
            });
            group.find('Circle').forEach(element => {
                if (element.name() === 'main') {
                    element.stroke('red');
                } else {
                    element.fill('blue');
                    element.radius(6);
                }
            });
            layer.batchDraw();
        })

        group.on('mouseout', function () {
            group.find('Line').forEach(element => {
                element.stroke(lineColor);
            });
            group.find('Arc').forEach(element => {
                element.stroke(lineColor);
            });
            if (group.name() === 'Area' || group.name() === 'Unit') {
                group.find('Rect').forEach(element => {
                    element.stroke(lineColor);
                });
            }
            group.find('Arrow').forEach(element => {
                element.stroke(lineColor);
            });
            group.find('Circle').forEach(element => {
                if (element.name() === 'main') {
                    element.stroke(lineColor);
                } else {
                    element.fill(lineColor);
                    element.radius(1);
                }
            });
            layer.batchDraw();
        })
    }

    groupClick(props, group, layer) {
        let store = getStore();
        if (group.name() !== 'Unit' && group.name() !== 'Area') {
            group.on('click', function () {
                store.dispatch(createDialog('deviceDetail', { type: 'view', data: { id: group.id(), x: group.x(), y: group.y() } }, true));
            })
        }
    }

    deviceGroupClick(props, group, layer) {
        let store = getStore();
        group.on('click', function () {
            let ids = _.split(group.id(), '_');
            if (ids && ids.length > 1) {
                let id = _.toInteger(ids[1]);
                store.dispatch(createDialog('deviceGroupDetail', { type: 'view', data: { id: id, x: group.x(), y: group.y() } }, true));
            }
        })
    }
}