import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import specsColor from './data/specsColor.json';
import { getInstallations } from './installation';
import { getCompanyTree } from './trees';
import { getSpecCode } from './operation';
import { csv } from '../../utils/exportfile';
import * as _ from 'lodash';
import { getMoment } from '../../utils/datetime'
const porxyApi = '/api'

const initialState = {
    search: {
        companyId: undefined,
        installId: undefined,
        specCode: '',
        intervalDate: {}
    },
    load: false,
    specsColor: specsColor,
    workOrderStatus: [],
}

export const exportCsv = createAction(
    '@@xcloud/operationStatus/exportCsv',
    () => (dispatch, getState, httpClient) => {
        let search = getState().operationStatus.search;
        let workOrderStatus = getState().operationStatus.workOrderStatus;
        let fileName = '作业报告'
        if (search.companyId) {
            let companyList = getState().constant.companies;
            let companyInfo = _.find(companyList, { companyId: search.companyId })
            fileName = fileName + '_' + companyInfo.companyAlias
        }
        if (search.installId) {
            let installList = getState().installation.installationList.list;
            let installInfo = _.find(installList, { installId: search.installId })
            fileName = fileName + '_' + installInfo.installName
        }
        if (search.specCode && search.specCode !== '') {
            let specCodeList = getState().operation.specCodeList;
            let specCodeInfo = _.find(specCodeList, { code: search.specCode })
            fileName = fileName + '_' + specCodeInfo.name
        }
        switch (search.intervalDate.view) {
            case 'day':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD")
                break;
            case 'week':
                fileName = fileName + '_[' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD") + '-' + getMoment()(search.intervalDate.toDate).format("YYYYMMDD") + ']';
                break;
            case 'month':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMM");
                break;
            case 'year':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYY");
                break;
            default:
        }

        fileName = fileName + '_' + new Date().getTime()

        let columns = ['单位', '装置', '作业类型', '日期', '作业数']
        let data = []
        workOrderStatus.forEach(status => {
            let cell = [
                status.companyName,
                status.installName,
                status.codeName ? status.codeName : '普通作业',
                status.countDate,
                status.total,
            ];
            data.push(cell)
        })
        dispatch(csv(fileName, columns, data));
    }
);

export const initOperationStatus = createAction('@@xcloud/operationStatus/initOperationStatus',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getCompanyTree()),
        ]).then((data) => {
            Promise.all([
                dispatch(getCompanyTree()),
                dispatch(resetSearch()),
                dispatch(getSpecCode()),
            ]).then((data) => {
                dispatch(getWorkOrderStatus())
            }).catch(err => {
                let msg = '初始化设备状态统计搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        }).catch(err => {
            let msg = '初始化设备状态统计搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getWorkOrderStatus = createAction(
    '@@xcloud/operationStatus/getWorkOrderStatus',
    () => (dispatch, getState, httpClient) => {
        let url = "/workorderreport"
        let searchCond = getState().operationStatus.search;

        if (searchCond.companyId) {
            if (url.indexOf('?') > -1) {
                url += "&companyId=" + searchCond.companyId
            } else {
                url += "?companyId=" + searchCond.companyId
            }
        }

        if (searchCond.installId) {
            if (url.indexOf('?') > -1) {
                url += "&installId=" + searchCond.installId
            } else {
                url += "?installId=" + searchCond.installId
            }
        }

        if (searchCond.specCode && searchCond.specCode !== '') {
            if (url.indexOf('?') > -1) {
                url += "&specCode=" + searchCond.specCode
            } else {
                url += "?specCode=" + searchCond.specCode
            }

        }

        if (searchCond.intervalDate && searchCond.intervalDate.view) {
            if (searchCond.intervalDate.view === 'year') {
                if (url.indexOf('?') > -1) {
                    url += "&type=year"
                } else {
                    url += "?type=year"
                }
            }
            if (url.indexOf('?') > -1) {
                url += "&fromDate=" + searchCond.intervalDate.fromDate + "&toDate=" + searchCond.intervalDate.toDate
            } else {
                url += "?fromDate=" + searchCond.intervalDate.fromDate + "&toDate=" + searchCond.intervalDate.toDate
            }
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setworkOrderStatus(res.data));
                    dispatch(setLoad(true));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取作业统计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const setworkOrderStatus = createAction('@@xcloud/operationStatus/setworkOrderStatus');

export const resetSearch = createAction('@@xcloud/operationStatus/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompantId = null
        let companyList = getState().constant.companies;
        let companyInfo = _.find(companyList, { companyId: company })
        if (!companyInfo.pid) {
            newCompantId = undefined
            dispatch(resetInstallation())
        } else {
            newCompantId = company
            dispatch(resetInstallation(company))
        }

        let search = getState().operationStatus.search;
        let newSearch = {
            ...search,
            companyId: newCompantId ? newCompantId : undefined,
            installId: undefined,
            specCode: '',
            intervalDate: {
                view: 'day',
                fromDate: getMoment()().startOf('day').format("YYYY/MM/DD"),
                toDate: getMoment()().startOf('day').format("YYYY/MM/DD")
            }
        }
        dispatch(setSearch(newSearch));
    }
);

export const resetInstallation = createAction('@@xcloud/operationStatus/resetInstallation',
    (companyId) => (dispatch, getState, httpClient) => {
        dispatch(getInstallations({ companyId: companyId, size: 0 }))
    }
);

export const updSearchData = createAction('@@xcloud/operationStatus/updSearchData',
    (value) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(setSearch(value))
        ]).then(() => {
            dispatch(getWorkOrderStatus(value))
        }).catch(err => {
        });
    }
);

export const setSearch = createAction('@@xcloud/operationStatus/setSearch');

export const setLoad = createAction('@@xcloud/operationStatus/setLoad');

export const operationStatusReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setworkOrderStatus]: (state, { payload: value }) => {
            return {
                ...state,
                workOrderStatus: value,
            };
        },
        [setLoad]: (state, { payload: value }) => {
            return {
                ...state,
                load: value
            };
        },
    },
    initialState
);

export default operationStatusReducer;