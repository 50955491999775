import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message'

const initialState = {
};

const porxyApi = "/api"

export const upload = createAction('@@xcloud/minio/upload',
    (files, bucketName, offset, callback) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/unprotected/utils/docs/uploadurl'
            url += "?prefix=" + offset + "&fileName=" + files[0].name + "&bucketName=" + bucketName;
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(uploadDoc(res.data.presignedUrl, files[0], callback))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取上传链接失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const uploadDoc = createAction('@@xcloud/minio/uploadDoc',
    (presignedUrl, file, callback) =>
        (dispatch, getState, httpClient) => {
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': ''
                }
            };
            return httpClient.put(presignedUrl, file, config).then((res) => {
                if (res.status === 200) {
                    // 后台上传
                    if (callback)
                        callback(res)
                }
                return res.status;
            }).catch(err => {
                let msg = `上传文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const download = createAction('@@xcloud/minio/download',
    (docName, mybucket) =>
        (dispatch, getState, httpClient) => {
            return dispatch(downloadURL(docName, mybucket)).then(res => {
                if (res && res.payload) {
                    window.location = res.payload;
                } else {
                    throw new Error();
                }
            }).catch(err => {
                let msg = `下载失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null
            })
        });

export const downloadURL = createAction('@@xcloud/minio/downloadURL',
    (docName, mybucket) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/unprotected/utils/docs/downloadurl'
            url += "?fileName=" + docName + "&bucketName=" + mybucket;
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    return res.data.presignedUrl;
                } else {
                    return null
                }
            }).catch(err => {
                let msg = `获取下载链接失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null
            })
        });


export const removeObject = createAction('@@xcloud/minio/removeObject',
    (bucketName, fileName) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/unprotected/utils/docs'
            url += "?fileName=" + fileName + "&bucketName=" + bucketName;
            return httpClient.delete(url).then((res) => {
                if (res.status === 200) {
                    return res.status;
                } else {
                    let msg = `删除文档失败`;
                    dispatch(createhttpMessage(msg));
                    return null
                }
            }).catch(err => {
                let msg = `删除文档失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null
            })
        });
export const minioReducer = handleActions(
    {
    },
    initialState
)

export default minioReducer