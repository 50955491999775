import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  select: {
    width: '90%'
  },
  planitemroot: {
    backgroundColor: '#FFFFFFFF'
  },
  title: {
    padding: theme.spacing(2)
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  }
});

export default withStyles(styles);
