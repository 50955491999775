import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import {
    Add,
    Edit,
    Layers,
} from '@material-ui/icons'
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../utils/datetime';
import { options, localization } from "../../../utils/mtable";
import * as _ from 'lodash';

const InstallationList = props => {
    const {
        classes,
        initListData,
        onDetailedInfo,
        onDetailedMap,
        onDetailedCreate,
        pageSize,
        setPageSize,
        userProfile,
        companyId,
        companyList,
        getInstallationList
    } = props;

    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'installation_management' })
        return permission && permission.action === 'W'
    }

    const renderTime = (install) => {
        return install.updateTime ? getDateByTimestamp(install.updateTime, true) : getDateByTimestamp(install.createTime, true)
    }

    const renderUseStatus = (install) => {
        let mapping = { 'INUSE': '运行', 'OFFUSE': '停用' };
        return mapping[install.useStatus]
    }

    const renderStatus = (install) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效' };
        return mapping[install.status]
    }

    const renderCompany = (plan) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === plan.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '装置编号',
                field: 'installCode',
                render: row => row ? <Typography>{row.installCode}</Typography> : '',
                editable: 'never'
            }
            ,
            {
                title: '装置名称',
                field: 'installName',
                render: row => row ? <Typography>{row.installName}</Typography> : '',
                editable: 'never'
            },
            {
                title: ' 生产能力（万吨/年）',
                field: 'productivity',
                render: row => row ? <Typography>{row.productivity}</Typography> : '',
                editable: 'never'
            },
            {
                title: '定员（人）',
                field: 'staffNo',
                render: row => row ? <Typography>{row.staffNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '装置状态',
                field: 'status',
                render: row => row ? <Typography>{renderUseStatus(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '档案状态',
                field: 'status',
                render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'装置列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>

                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}></Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}></Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={onDetailedCreate}
                        >
                            新建
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={initListData.list}
                    options={{
                        ...options,
                        pageSize: pageSize ? pageSize : 5,
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor: selectedRow === rowData.installId ? '#EEEEEE' : 'FFFFFF'
                        }),
                        maxBodyHeight: `${tableHeight}px`,
                        minBodyHeight: `${tableHeight}px`,
                    }}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        // Toolbar: props => (
                        //     <div>
                        //         <MTableToolbar {...props} />
                        //     </div>
                        // ),
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[5, 10, 20]}
                                rowsPerPage={pageSize ? pageSize : 5}
                                count={initListData.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getInstallationList({
                                        companyId: companyId ? companyId : null,
                                        offset: pageNum * (pageSize ? pageSize : 5)
                                    })
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPageSize(event.target.value);
                                    setPage(0);
                                    getInstallationList({
                                        companyId: companyId ? companyId : null,
                                        offset: 0
                                    })
                                }}

                            />
                        )
                    }}
                    actions={checkPermission() ?
                        [
                            rowData => ({
                                icon: () => <Edit color={'primary'} />,
                                // iconProps: { color: 'primary' },
                                tooltip: '编辑',
                                onClick: (event, rowData) => {
                                    onDetailedInfo(event, rowData.installId)
                                },
                                disabled: rowData.status === 'inactive'
                            }),
                            rowData => ({
                                icon: () => <Layers color={'primary'} />,
                                // iconProps: { color: 'primary' },
                                tooltip: '查看装置图',
                                onClick: (event, rowData) => {
                                    onDetailedMap(event, rowData.installId)
                                }
                            }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '新建装置',
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onDetailedCreate(event)
                            //     },
                            // }
                        ] : [
                            rowData => ({
                                icon: 'layers',
                                tooltip: '查看装置图',
                                onClick: (event, rowData) => {
                                    onDetailedMap(event, rowData.installId)
                                }
                            })
                        ]}
                >
                </MaterialTable>
            </Grid>
        </div>
    );
};

InstallationList.propTypes = {
    classes: PropTypes.object.isRequired,
    initListData: PropTypes.object.isRequired,
    pageSize: PropTypes.number.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    onDetailedMap: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    setPageSize: PropTypes.func.isRequired,
    getInstallationList: PropTypes.func.isRequired,
    companyId: PropTypes.number,
    companyList: PropTypes.array.isRequired,
    onDetailedCreate: PropTypes.func.isRequired,
};

export default withStyles(InstallationList);
