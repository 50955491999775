import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import backgroundImage from '@/assets/bg01.jpeg';

const styles = theme => ({
    root: {
        paddingTop: 56,
        height: '100vh',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        },
        // backgroundImage: `url(${backgroundImage})`
        background: `url(${backgroundImage}) no-repeat center center fixed`,
        '-webkit-background-size': 'cover',
        '-moz-background-size': 'cover',
        '-o-background-size': 'cover',
        'background-size': 'cover',
    },
    appBar: {
        display: 'flex',
        justifyContent: 'center',
        height: 65,
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        boxShadow: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        zIndex: 1500,
    },
    logoButton: {
        width: 'auto',
        outline: 'none'
    },
    logoimage: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        height: 26,
    },
    logo: {
        color: colors.blue[500],
        fontWeight: 600,
        fontSize: 26,
        letterSpacing: '0.48px',
        lineHeight: '30px'
    },
    title: {
        paddingLeft: theme.spacing(1),
        fontSize: 16,
    },
    card: {
        position: 'absolute',
        top: 'calc(50vh - 250px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // 抄阿里云
        width: 420,
        padding: theme.spacing(5, 6, 5, 6),
        // 原型
        // width: 600,
        // padding: theme.spacing(6, 14, 6, 14),
        '&:last-child': {
            paddingBottom: theme.spacing(6),
        },
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        height: theme.spacing(5),
        margin: theme.spacing(6, 0, 1),
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    tabs: {
        width: '100%',
        minHeight: 28,
        height: 28,
    },
    tabLabel: {
        fontWeight: 500,
        fontSize: '18px',
    },
    textInput: {
        height: 40,
        width: 320,
        fontSize: 14,
        boderBottom: '1px solid #d7d8d9',
    },
    userLink: {
        color: theme.palette.grey[500],
        '&:hover': {
            textDecoration: 'none'
        }
    },
    footer: {
        width: '100%',
        margin: '0 auto',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: 'absolute',
        bottom: 0,
        backgroundColor: theme.palette.grey[100],
    }
});

export default withStyles(styles);