import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading:{
    display: 'block',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  }
});

export default withStyles(styles);
