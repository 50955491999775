import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    DialogActions,
    Button,
    Dialog,
    DialogContent,
    Typography,
} from '@material-ui/core';
import { localization, options, style } from "@/utils/mtable";
import { SAFETY_ACTIVITY_TYPE } from "@/utils/constant";
import MaterialTable from 'material-table'
import _ from 'lodash'
import Close from '@material-ui/icons/Close';
// import { getDateByString } from '@/utils/datetime';


const OnDtuyTableComponent = ({
    classes,
    currentActivityList,
    constant,
    open,
    onhandleClose,
    leaders,
    onhandleOpenActivity,
    selectedMoment,
}) => {

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        //toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    const activityTypeDict = _.chain(SAFETY_ACTIVITY_TYPE).keyBy('value').mapValues('name').value()
    const activityStatusDict = _.chain(constant.activityStatus).keyBy('code').mapValues('name').value()

    const renderLeaders = (users) => {

        const theLeaders = _.filter(leaders, l => { return _.find(users, u => { return u.userId === l.userId }) })
        const names = _.map(theLeaders, t => { return t.lastName + t.firstName })
        return names.join(',')

    }
    const assemblycolumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'activity',
                render: row => <Typography>{row.activity}</Typography>
            }, {
                title: '类型',
                field: 'activityType',
                render: row => <Typography>{activityTypeDict[row.activityType]}</Typography>,
                lookup: activityTypeDict,
                editable: 'never',
            }, {
                title: '领导',
                field: 'users',
                render: row => <Typography>{renderLeaders(row.users)}</Typography>,
            }, {
                //     title: '时间',
                //     field: 'activityTime',
                //     render: row => <Typography>{getDateByString(row.activityTime)}</Typography>,
                // }, {
                title: '状态',
                field: 'status',
                render: row => <Typography>{activityStatusDict[row.status]}</Typography>,
                lookup: activityStatusDict
            }
        ]
        return columns
    }

    const assemblyData = () => {
        return currentActivityList
    }

    return (

        <div alignitems="center" className={classes.root}>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onhandleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogContent dividers className={classes.activityDialogContent}>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>{selectedMoment.format('YYYY/MM/DD')}带班值班列表</Typography>}
                        actions={
                            [
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onhandleOpenActivity(rowData)
                                    }
                                })
                            ]
                        }
                    >
                    </MaterialTable>
                </DialogContent>

                <DialogActions>
                    <Button variant='outlined' onClick={onhandleClose}><Close /> 关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
OnDtuyTableComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

OnDtuyTableComponent.defaultProps = {

}


export default withRouter(withStyles(OnDtuyTableComponent));