import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
    open: false,
    permit: {
        serialNo: '',
        ptwName: '',
        expiredTime: '',
        opentext: '',
        intGuarder: '',
        extGuarder: '',
        file: undefined
    },
    appointment: {},
    confirmCallback: null,
    closeCallback: null,
    showtype: '',
};

export const openWorkPermitPanel = createAction('@@xcloud/operationWorkPermit/open',
    (
        confirmCallback, closeCallback, permit, appointment, showtype
    ) => ({
        confirmCallback, closeCallback, permit, appointment, showtype
    })
);

export const closeWorkPermitPannel = createAction('@@xcloud/operationWorkPermit/close');

export const changePermit = createAction('@@xcloud/operationWorkPermit/changePermit');

export const changeGuarder = createAction('@@xcloud/operationWorkPermit/changeGuarder');

// Actions
export const OperationWorkPermitReducer = handleActions(
    {
        [openWorkPermitPanel]: (state, { payload: data }) => {
            // permitDocsBack 用来判断更新时是否有新上传文件
            if (data.permit) {
                const { permitDocs } = data.permit;
                if (permitDocs && permitDocs.length && permitDocs.length > 0) {
                    data.permit.permitDocsBack = _.cloneDeep(permitDocs);
                }
            }
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                permit: data.permit ?
                    {
                        ...data.permit
                    }
                    : {
                        serialNo: '',
                        ptwName: '',
                        expiredTime: '',
                        opentext: '',
                        intGuarder: '',
                        extGuarder: '',
                        file: undefined
                    },
                appointment: data.appointment ? data.appointment : {},
                showtype: data.showtype ? data.showtype : ''
            };
        },
        [closeWorkPermitPannel]: (state,) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changePermit]: (state, { payload: data }) => {
            return {
                ...state,
                permit: data,
            };
        }
    },
    initialState
);

export default OperationWorkPermitReducer;
