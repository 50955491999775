import React, { useState } from 'react';
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    TextField
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore
} from '@material-ui/icons';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getManufactureTypeName, MANUFACTURE_DICT_TYPES } from "@/utils/constant";
import { formatDate_hms } from '@/utils/datetime';

const InstallActionRecordComponent = props => {
    const { classes,
        startDate,
        endDate,
        constant,
        onhandleDateChange,
        records,
        companies,
        installations,
        deviceName,
        selectedInstallId,
        onhandleChangeInstall,
        onhandleChangeCompany,
        onhandleSelectDevice,
        selectedCompanyId,
        onhandleSearch,
        onhandleClearSearch
    } = props;
    const [expand, setExpand] = useState(false);

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: false,
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '分厂',
                field: 'companyName',
                render: row => <Typography>{row.companyName}</Typography>
            },
            {
                title: '装置',
                field: 'installName',
                render: row => <Typography>{row.installName}</Typography>

            }, {
                title: '单元',
                field: 'unitName',
                render: row => <Typography>{row.unitName}</Typography>
            }, {
                title: '设备',
                field: 'deviceName',
                render: row => <Typography>{row.deviceName}</Typography>
                // }, {
                //     title: '操作项',
                //     field: 'manipulateName',
                //     render: row => <Typography>{row.manipulateName}</Typography>
            }, {
                title: '操作',
                field: 'actionName',
                render: row => <Typography>{getManufactureTypeName(constant, MANUFACTURE_DICT_TYPES.INSTALL_ACTION, row.actionName)}</Typography>
            }, {
                title: '操作人',
                field: 'manipulator',
                render: row => <Typography>{row.userName}</Typography>
            }, {
                title: '操作时间',
                field: 'actionTime',
                render: row => <Typography>{formatDate_hms(row.actionTime)}</Typography>
            },
        ]

        return columns

    }

    const assemblyData = () => {
        return records
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'装置操作记录'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={expand ? 8 : 8}>
                    <Grid container item className={classes.gridItem} sm={6} md={3} lg={3}>
                        <Typography className={classes.label}>
                            单&emsp;位
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            variant="outlined"
                            value={selectedCompanyId}
                            onChange={onhandleChangeCompany}
                        >
                            {companies.map((c) => (
                                <MenuItem key={c.companyId} value={c.companyId}> {c.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={3} lg={3}>
                        <Typography className={classes.label}>
                            装&emsp;置
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            variant="outlined"
                            value={selectedInstallId}
                            onChange={onhandleChangeInstall}
                        >
                            {installations.map((c) => (
                                <MenuItem key={c.installId} value={c.installId}> {c.installName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={3} lg={3}>
                        <Typography className={classes.label}>
                            开始日期
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                className={classes.textFieldInput}
                                size={'small'}
                                autoOk
                                disableToolbar
                                InputProps={{
                                    // readOnly: true, 
                                    style: { paddingRight: 0 },
                                }}
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy/MM/dd"
                                value={startDate}
                                onChange={(event) => onhandleDateChange(event, 'startDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={3} lg={3}>
                        <Typography className={classes.label}>
                            结束日期
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                className={classes.textFieldInput}
                                size={'small'}
                                disableToolbar
                                InputProps={{
                                    // readOnly: true, 
                                    style: { paddingRight: 0 },
                                }}
                                variant="inline"
                                inputVariant="outlined"
                                format="yyyy/MM/dd"
                                value={endDate}
                                onChange={(event) => onhandleDateChange(event, 'endDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {Boolean(expand) &&
                        <React.Fragment>
                            <Grid container item className={classes.gridItem} sm={6} md={3} lg={3}>
                                <Typography className={classes.label}>
                                    设&emsp;备
                                </Typography>
                                <TextField
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    variant="outlined"
                                    required={false}
                                    name='deviceId'
                                    value={deviceName || ''}
                                    onClick={event => onhandleSelectDevice(event)}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>


                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 4 : 4}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                        >
                            搜索
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}

                        >
                            重置
                        </Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
                    </Grid>
                </Grid>

            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    title={''}
                // actions={[
                //     {
                //         icon: 'search',
                //         tooltip: '搜索记录',
                //         isFreeAction: true,
                //         onClick: (event, rowData) => {
                //             onhandleSearch()
                //         }
                //     },
                //     {
                //         icon: 'clear_all',
                //         tooltip: '清空搜索条件',
                //         isFreeAction: true,
                //         onClick: (event, rowData) => {
                //             onhandleClearSearch()
                //         }
                //     }

                // ]}


                >
                </MaterialTable>
            </Grid>

        </div >
    )
}

InstallActionRecordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    records: PropTypes.array,
    companies: PropTypes.array,
};

InstallActionRecordComponent.defaultProps = {
    records: [],
    companies: []
};

export default withStyles(InstallActionRecordComponent);