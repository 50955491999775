import React, { useEffect, useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { formatDay } from '@/utils/datetime';
// import ProcessLine from '@/ProcessLine';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import _ from 'lodash';
import {
    Card,
    Grid,
    Typography,
    Button,
    IconButton,
    Tooltip,
    TextField,
    MenuItem
} from '@material-ui/core';

const RuleListComponent = (props) => {

    const {
        classes,
        rules,
        onEdit,
        onDelete,
        onView,
        // getRuleList,
        onhandleCreate,
        // constant,
        optCategories,
        userProfile,
        search,
        onhandleSelectChange,
        onhandlePageChange,
    } = props;

    const [tableHeight, setTableHeight] = useState(window.innerHeight - 215);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const renderCycle = (rule) => {
        if (!rule.cyclical)
            return "无";

        if (rule.formula && rule.formula !== "")
            return "检验报告规定日期";

        return rule.cycle + "天";
    }

    const renderCat = (rule) => {
        if (optCategories && optCategories.length > 0) {
            let cat = _.find(optCategories, { 'code': rule.cat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderApproval = (rule) => {
        if (rule.status === 'new') {
            const firstApproval = _.find(rule.pendingApprovals, { "preId": null });
            if (firstApproval) {
                return firstApproval.lastName + firstApproval.firstName
            }
        } else if (rule.status === 'submitted') {
            let approval = ''
            for (const pApproval of rule.pendingApprovals) {
                if (pApproval.preId === rule.pendingApprovals[0].preId) {
                    if (pApproval.userId) {
                        approval += pApproval.lastName + pApproval.firstName + ' '
                    } else {
                        approval += pApproval.roleName
                    }
                }
            }
            return approval
        }
    }

    const renderTime = (rule) => {
        return rule.updateTime ? formatDay(rule.updateTime) : formatDay(rule.createTime)
    }

    const renderStatus = (rule) => {
        let mapping = { 'new': '起草中', 'active': '有效', 'inactive': '无效', 'submitted': '审核中' };
        return mapping[rule.status]
    }

    const assemblyData = () => {
        return rules.list
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '规则名称',
                dataIndex: 'ruleName',
                key: 'ruleName',
                render: (text, row, index) => row ? <Typography>{row.ruleName}</Typography> : '',
            },
            {
                title: '规则编码',
                dataIndex: 'ruleCode',
                key: 'ruleCode',
                render: (text, row, index) => row ? <Typography>{row.ruleCode}</Typography> : '',
            },
            {
                title: '运维类别',
                dataIndex: 'cat',
                key: 'cat',
                render: (text, row, index) => row ? <Typography>{renderCat(row)}</Typography> : '',
            },
            {
                title: '执行周期',
                dataIndex: 'cycle',
                key: 'cycle',
                render: (text, row, index) => row ? <Typography>{renderCycle(row)}</Typography> : '',
            },
            {
                title: '规则描述',
                dataIndex: 'opentext',
                key: 'opentext',
                width: '30%',
                render: (text, row, index) => row ? <Typography>{row.opentext}</Typography> : '',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text, row, index) => row ? <Typography>{renderTime(row)}</Typography> : '',
            },
            {
                title: '审核人',
                dataIndex: 'approval',
                key: 'approval',
                render: (text, row, index) => row ? <Typography>{renderApproval(row)}</Typography> : '',
            }, {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, row, index) => row ? <Typography>{renderStatus(row)}</Typography> : '',
            }
            , {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                className: classes.actionColumn,
                render: (text, row, index) => row ? renderAction(row) : '',
            }
        ]

        return columns
    }

    // const checkReastartPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_rule_restart' })
    //     return permission && permission.action === 'W'
    // }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_rule' })
        return permission && permission.action === 'W'
    }

    const checkDeletePermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_rule_delete' })
        return permission && permission.action === 'W'
    }


    const renderAction = (row) => {
        let actions = []
        actions.push(<Grid item key='view'><Tooltip title='查看'><IconButton variant='text' onClick={(event) => onView(event, row.ruleId)}> <DescriptionIcon fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>)

        if (checkPermission() && row.status === 'new') {
            actions.push(<Grid item key='edit'><Tooltip title='编辑'><IconButton variant='text' onClick={(event) => onEdit(event, row.ruleId)}> <EditIcon fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>)
        }

        if (checkDeletePermission() && row.status === 'active') {
            actions.push(<Grid item key='delete'><Tooltip title='删除'><IconButton variant='text' onClick={(event) => onDelete(event, row.ruleId, row.ruleName)}> <DeleteIcon fontSize='small' color={'error'} /></IconButton></Tooltip></Grid>)
        }

        return <Grid container alignItems='center' className={classes.actionCell}>
            {actions}
        </Grid>
    }

    return (
        <Card alignitems="center" className={classes.root}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'运维规则列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            运维类别
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            value={search.optCategory}
                            onChange={(event) => onhandleSelectChange(event, 'optCategory')}
                            variant="outlined"
                        >
                            <MenuItem value={'ALL'}>{'全部'}</MenuItem>
                            {_.sortBy(optCategories, 'code').map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}></Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {checkPermission() && <Button variant='contained'
                            color='primary'
                            onClick={onhandleCreate}>
                            <AddIcon />新增规则
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.detailtable}>
                <Table
                    className={classes.stripedTable}
                    bodyStyle={{ height: tableHeight }}
                    rowKey={'ruleId'}
                    columns={assemblycolumns()}
                    dataSource={assemblyData()}
                    size={'small'}
                    scroll={{ x: '100%' }}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: () => `共${rules.total}条`,
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: search.size,
                        current: search.page,
                        total: rules.total,
                        onChange: (current, pageSize) => onhandlePageChange(current, pageSize)
                    }}
                >
                </Table>
                {/* <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={rules.list}
                    options={{
                        ...options,
                        pageSize: rowsPerPage,
                        actionsColumnIndex: -1,
                        search: false,
                        rowStyle: rowData => ({
                            backgroundColor: selectedRow === rowData.ruleId ? '#EEEEEE' : 'FFFFFF'
                        })
                    }}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    localization={localization}
                    style={style}
                    components={components}
                    actions={
                        [rowData => ({
                            icon: 'edit',
                            tooltip: '编辑',
                            onClick: (event, rowData) => {
                                onEdit(event, rowData.ruleId)
                            }
                        }),
                        {
                            icon: 'add',
                            tooltip: '新建',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleCreate(event)
                            },
                        },
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onDelete(event, rowData.ruleId, rowData.ruleName)
                            }
                        }), rowData => ({
                            icon: 'description',
                            tooltip: '查看',
                            onClick: (event, rowData) => {
                                onView(event, rowData.ruleId)
                            }
                        })
                        ]}
                >
                </MaterialTable> */}
            </Grid>
        </Card >
    );
};
RuleListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    rules: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    getRuleList: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    optCategories: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
};

export default withStyles(RuleListComponent);
