import React from 'react';
import {
    Grid,
    Typography,
    Tabs,
    Tab,
    Tooltip,
    Chip,
    TextField,
    MenuItem
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import PeopleIcon from '@material-ui/icons/People';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { localization, options, style } from "@/utils/mtable";
import { formatDay, formatDate_hms } from "@/utils/datetime";
import OperationPlan from '@/containers/DeviceManagement/OperationPlan/OperationPlan';
import { getCodeName, PROJECT_STATUS } from "@/utils/manufactureUtils";
import * as _ from 'lodash'

const ProjectPlanComponent = props => {
    const { classes,
        projects,
        search,
        projectServiceList,
        companyList,
        manufactureDicts,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
        onhandleGenerateOrder,
        onhandleUpdateLaborer,
        onhandleOpenAddOrder,
        onhandleSearchChange,
        onhandleSearch,
        onhandleClearSearch
    } = props;

    const [value, setValue] = React.useState(0);

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        tableLayout: 'fixed',
    }
    const detailOptions = {
        ...options,
        paging: false,
    }

    const tableStyle = {
        ...style
    }

    const getServiceName = (serviceId) => {
        let serviceDto = projectServiceList.find(ele => ele.serviceId === serviceId);
        return (serviceDto && serviceDto.serviceName) ? serviceDto.serviceName : ''
    }

    const renderRequireSide = (project) => {
        if (!project) return ''
        if (project.requireCompany) {
            const com = _.find(companyList, c => { return c.companyId === project.requireCompany })
            return com.companyName
        }
        return project.requireSide ? project.requireSide : ''
    }

    const renderProjectStatus = (project) => {
        let projectStatus = getCodeName(manufactureDicts, PROJECT_STATUS, project.status)
        let ariaLabel = projectStatus
        let statusStyle = classes[project.status]
        if (project.status === 'doing') {
            if (project.completionRate) {
                projectStatus = Number(project.completionRate * 100).toFixed(0) + '%'
            } else {
                projectStatus = '0%'
            }
        }
        return <Tooltip aria-label="{ariaLabel}" title={ariaLabel} >
            <Chip size="small" className={statusStyle} label={projectStatus} />
        </Tooltip>
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '',
                width: 10,
                render: row => <Typography></Typography>
            },
            // {
            //     title: '工程编码',
            //     field: 'projectCode',
            //     width: 150,
            //     render: row => <Typography>{row.projectCode}</Typography>,
            //     editable: 'never',
            // },
            {
                title: '工程名称',
                field: 'projectName',
                width: 120,
                render: row => <Typography>{row.projectName}</Typography>
            },
            {
                title: '编号',
                field: 'ticketNo',
                width: 150,
                render: row => <Typography>{row.ticketNo}</Typography>,
                editable: 'never',
            },
            {
                title: '工程服务',
                field: 'serviceId',
                width: 150,
                render: row => <Typography>{getServiceName(row.serviceId)}</Typography>,
            },
            {
                title: '需方',
                field: 'requireSide',
                width: 120,
                render: row => <Typography>{renderRequireSide(row)}</Typography>,
            },
            {
                title: '开始时间',
                field: 'startTime',
                width: 130,
                render: row => <Typography>{formatDay(row.startTime) || ''}</Typography>,
            },
            // {
            //     title: '结束时间',
            //     field: 'endTime',
            //     width: 130,
            //     render: row => <Typography>{formatDay(row.endTime)}</Typography>,
            // },
            {
                title: '工期',
                field: 'period',
                width: 90,
                render: row => <Typography>{row.period || 0}{'天'}</Typography>,
            },
            {
                title: '预算',
                field: 'budget',
                type: 'numeric',
                width: 100,
                render: row => <Typography>{(row.valuationType === 'PRICE') ? '-' : (row.budget || 0)}{'元'}</Typography>,
            },
            // {
            //     title: '材料总价',
            //     field: 'materialFee',
            //     type: 'numeric',
            //     width: 100,
            //     render: row => <Typography>{(row.materialFee || 0)}{'元'}</Typography>,
            // },
            // {
            //     title: '完成进度',
            //     field: 'rate',
            //     width: 100,
            //     render: row => <Typography>{(row.rate * 100)}{'%'}</Typography>,
            //     editable: 'never',
            // },
            {
                title: '决算',
                field: 'finalCost',
                width: 100,
                render: row => <Typography>{(row.valuationType === 'PRICE') ? '-' : (row.finalCost || 0)}{'元'}</Typography>,
                initialEditValue: 0,
                editable: 'never',
            },
            {
                title: '状态',
                field: 'status',
                width: 100,
                render: row => renderProjectStatus(row),
                editable: 'never',
            },
        ]
        return columns
    }

    const rendorDetailcolumns = () => {
        return [
            {
                title: '关联工单号',
                field: 'orderNo',
                render: row => <Typography>{row.orderNo}</Typography>
            },
            {
                title: '执行时间',
                field: 'triggerTime',
                render: row => <Typography>{formatDate_hms(row.triggerTime)}</Typography>
            },
            {
                title: '记录人',
                field: 'creatorName',
                render: row => <Typography>{row.creatorName}</Typography>
            },
            {
                title: '工作时长(小时)',
                field: 'workTime',
                render: row => <Typography>{row.workTime}</Typography>
            },
            {
                title: '记录完成进度',
                field: 'rate',
                render: row => <Typography>{(row.rate * 100) + '%'}</Typography>
            },
        ]
    }

    const assemblyData = () => {
        return projects;
    }

    const assemblePointDetailPanel = () => {
        return [
            row => {
                return {
                    disabled: !(row.projectRecordList && row.projectRecordList.length > 0),
                    render: rowData => renderItems(rowData)
                }
            }
        ];
    }

    const renderItems = (rowData) => (
        <React.Fragment>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    title={<Typography variant="h4">{'工程执行记录'}</Typography>}
                    options={detailOptions}
                    columns={rendorDetailcolumns()}
                    data={rowData.projectRecordList}
                    style={tableStyle}
                />
            </Grid>
        </React.Fragment>
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label={"工程计划列表"} />
                    <Tab label={"运维计划列表"} />
                </Tabs>
                <Grid>
                    <Grid>
                        {value === 0 &&
                            <Grid>
                                <Grid className={classes.planitemroot}>
                                    <MaterialTable
                                        columns={assemblycolumns()}
                                        data={assemblyData()}
                                        options={tableOptions}
                                        localization={tableLocalization}
                                        style={tableStyle}
                                        title={''}
                                        editable={
                                            {
                                                onRowDelete: (oldData) => onhandleDelete(oldData),
                                            }
                                        }
                                        components={{
                                            Toolbar: props => (
                                                <div>
                                                    <Typography variant="h3">
                                                        工程计划列表
                                                    </Typography>
                                                    <Grid container direction="row" spacing={2} alignItems="center" className={classes.gridContainer}>
                                                        {/* <Grid item>
                                                            <Typography >
                                                                工程名称:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className={classes.gridSelect}>
                                                            <TextField
                                                                fullWidth
                                                                variant="standard"
                                                                onChange={event => onhandleSearchChange(event, 'projectName')}
                                                                value={search.projectName || ''}
                                                            >
                                                            </TextField>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <Typography>
                                                                工程日期:
                                                         </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                                locale={zhCN}>
                                                                <KeyboardDatePicker
                                                                    className={classes.gridItem}
                                                                    variant="inline"
                                                                    autoOk
                                                                    disableToolbar
                                                                    format="yyyy/MM/dd"
                                                                    margin="normal"
                                                                    name='startDate'
                                                                    InputProps={{ readOnly: true }}
                                                                    value={search.startDate}
                                                                    onChange={(event) => onhandleSearchChange(event, 'startDate')}

                                                                />
                                                            </MuiPickersUtilsProvider>

                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>
                                                                ~
                                                         </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                                locale={zhCN}>
                                                                <KeyboardDatePicker
                                                                    className={classes.gridItem}
                                                                    variant="inline"
                                                                    autoOk
                                                                    disableToolbar
                                                                    format="yyyy/MM/dd"
                                                                    margin="normal"
                                                                    name='endDate'
                                                                    InputProps={{ readOnly: true }}
                                                                    value={search.endDate}
                                                                    onChange={(event) => onhandleSearchChange(event, 'endDate')}

                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography >
                                                                工程服务:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className={classes.gridSelect}>
                                                            <TextField
                                                                fullWidth
                                                                select
                                                                name='serviceId '
                                                                onChange={event => onhandleSearchChange(event, 'serviceId')}
                                                                value={search.serviceId || 0}
                                                            >
                                                            <MenuItem key={0} value={0}>
                                                                        {'全部'}
                                                                    </MenuItem>
                                                                {projectServiceList && projectServiceList.map(ele => (
                                                                    <MenuItem key={ele.serviceId} value={ele.serviceId}>
                                                                        {ele.serviceName}
                                                                    </MenuItem>)
                                                                )}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography >
                                                                状态:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className={classes.gridSelect}>
                                                            <TextField
                                                                fullWidth
                                                                select
                                                                name='status '
                                                                onChange={event => onhandleSearchChange(event, 'status')}
                                                                value={search.status || 'all'}
                                                            >
                                                             <MenuItem key={'s99'} value={'all'}>
                                                                        {'全部'}
                                                                    </MenuItem>
                                                                <MenuItem key={'s0'} value={'new'}>
                                                                        {'起草中'}
                                                                    </MenuItem>
                                                                    <MenuItem key={'s1'} value={'doing'}>
                                                                        {'进行中'}
                                                                    </MenuItem>
                                                                    <MenuItem key={'s2'} value={'done'}>
                                                                        {'已完成'}
                                                                    </MenuItem>
                                                                    <MenuItem key={'s3'} value={'final'}>
                                                                        {'已决算'}
                                                                    </MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                    <MTableToolbar {...props} />
                                                </div>
                                            )
                                        }}
                                        actions={[
                                            {
                                                icon: 'search',
                                                tooltip: '搜索记录',
                                                isFreeAction: true,
                                                onClick: (event, rowData) => {
                                                    onhandleSearch()
                                                }
                                            },
                                            {
                                                icon: 'clear_all',
                                                tooltip: '清空搜索条件',
                                                isFreeAction: true,
                                                onClick: (event, rowData) => {
                                                    onhandleClearSearch()
                                                }
                                            },
                                            {
                                                icon: 'add',
                                                isFreeAction: true,
                                                tooltip: '新增工程',
                                                onClick: (event) => onhandleAdd()
                                            },
                                            rowData => ({
                                                icon: 'library_add',
                                                tooltip: '生成工单',
                                                disabled: rowData.status !== 'new',
                                                onClick: (event, rowData) => {
                                                    onhandleGenerateOrder(rowData)
                                                }
                                            }),
                                            rowData => ({
                                                icon: 'add_box',
                                                tooltip: '新增工单',
                                                disabled: rowData.status !== 'doing',
                                                onClick: (event, rowData) => {
                                                    onhandleOpenAddOrder(rowData)
                                                }
                                            }),
                                            {
                                                icon: 'edit',
                                                tooltip: '查看和修改',
                                                onClick: (event, rowData) => {
                                                    onhandleUpdate(rowData)
                                                }
                                            },
                                            {
                                                icon: () => <PeopleIcon />,
                                                tooltip: '劳务人员',
                                                onClick: (event, rowData) => {
                                                    onhandleUpdateLaborer(rowData)
                                                }
                                            },
                                        ]}
                                        detailPanel={assemblePointDetailPanel()}
                                    >
                                    </MaterialTable>
                                </Grid>
                            </Grid>}
                        {value === 1 &&
                            <Grid>
                                <Grid className={classes.planItem}>
                                    <OperationPlan routeType='projectmanage' />
                                </Grid>
                            </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

ProjectPlanComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    projects: PropTypes.array,
    companyList: PropTypes.array,
    departmentList: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleAdd: PropTypes.func,
};

ProjectPlanComponent.defaultProps = {
    projects: [],
    projectServiceList: [],
    serviceDptList: [],
};

export default withStyles(ProjectPlanComponent);