import React from 'react';
import {
    Grid,
    Tabs,
    Tab
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import DailyAmoebaReportContainer from '@/containers/AmoebaManagement/Report/DailyAmoebaReportContainer';
import MonthlyAmoebaReportContainer from '@/containers/AmoebaManagement/Report/MonthlyAmoebaReportContainer';
import AnnualAmoebaReportContainer from '@/containers/AmoebaManagement/Report/AnnualAmoebaReportContainer';

const AmoebaReportComponent = props => {
    const { classes,
        tabValue,
        onhandleChangeTab,
        departments
    } = props;
    return (
        <div alignitems="center" className={classes.root}>
            {
                departments && departments.length > 0 ?
                    <React.Fragment>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={onhandleChangeTab}
                        >
                            <Tab label={"日核算"} value={0} className={classes.tab} />
                            <Tab label={"月核算"} value={1} className={classes.tab} />
                            <Tab label={"年核算"} value={2} className={classes.tab} />
                        </Tabs>
                        <Grid>
                            {tabValue === 0 && <DailyAmoebaReportContainer />}
                            {tabValue === 1 && <MonthlyAmoebaReportContainer />}
                            {tabValue === 2 && <AnnualAmoebaReportContainer />}

                        </Grid>
                    </React.Fragment>
                    : null
            }
        </div>
    )
}

AmoebaReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

AmoebaReportComponent.defaultProps = {
};

export default withStyles(AmoebaReportComponent);