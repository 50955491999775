import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMoment } from '@/utils/datetime';
import OilPriceComponent from '@/components/AmoebaManagement/OilPrice/OilPriceComponent';
import { initOilPrice, getOilPrices, updateOilPrice, addOilPrice, deleteOilPrice } from '@/redux/modules/amoeba/oilPrice';
import { formatDate } from "@/utils/datetime";
// import * as _ from 'lodash';

class OilPriceContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        this.props.onhandleInitOilPrice(getMoment()().startOf('month').format('YYYY-MM-DD'),
            getMoment()().endOf('month').format('YYYY-MM-DD'))
    }

    handleDateChange = (date) => {
        let selectDate = getMoment()(date)
        let startDate = getMoment()(date).startOf('month').format('YYYY-MM-DD')
        let endDate = getMoment()(date).endOf('month').format('YYYY-MM-DD')
        this.props.onhandleGetOilPrices(startDate, endDate);
        this.setState({
            selectedDate: selectDate
        })
    }



    handleUpdate = async (rowData) => {

        let newRecord = {
            'priceId': parseInt(rowData.priceId),
            'station': rowData.station,
            'oilType': rowData.oilType,
            'unitPrice': parseFloat(rowData.unitPrice),
            'startDate': formatDate(rowData.startDate),
            'endDate': formatDate(rowData.endDate),
        }
        await this.props.onhandleUpdateOilPrice(newRecord);

        this.props.onhandleGetOilPrices(
            getMoment()(this.state.selectedDate).startOf('month').format('YYYY-MM-DD'),
            getMoment()(this.state.selectedDate).endOf('month').format('YYYY-MM-DD')
        );
    }


    handleAdd = async (rowData) => {

        let newRecord = {
            'station': rowData.station,
            'oilType': rowData.oilType,
            'unitPrice': parseFloat(rowData.unitPrice),
            'startDate': formatDate(rowData.startDate),
            'endDate': formatDate(rowData.endDate),
        }
        await this.props.onhandleAddOilPrice(newRecord);

        this.props.onhandleGetOilPrices(
            getMoment()(this.state.selectedDate).startOf('month').format('YYYY-MM-DD'),
            getMoment()(this.state.selectedDate).endOf('month').format('YYYY-MM-DD')
        );
    }

    handleDelete = async (rowData) => {
        await this.props.onhandleDeleteOilPrice(rowData.priceId);

        this.props.onhandleGetOilPrices(
            getMoment()(this.state.selectedDate).startOf('month').format('YYYY-MM-DD'),
            getMoment()(this.state.selectedDate).endOf('month').format('YYYY-MM-DD')
        );
    }

    render() {
        return (
            <div>
                <OilPriceComponent
                    selectedDate={this.state.selectedDate}
                    oilPrices={this.props.oilPrices}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    dicts={this.props.dicts}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oilPrices: state.oilPrice.oilPrices,
        dicts: state.constant.manufactureDicts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitOilPrice: (startDate, endDate) => {
            return dispatch(initOilPrice(startDate, endDate));
        },
        onhandleUpdateOilPrice: (oilPrice) => {
            return dispatch(updateOilPrice(oilPrice.priceId, oilPrice));
        },
        onhandleAddOilPrice: (oilPrice) => {
            return dispatch(addOilPrice(oilPrice))
        },
        onhandleDeleteOilPrice: (priceId) => {
            return dispatch(deleteOilPrice(priceId))
        },
        onhandleGetOilPrices: (startDate, endDate) => {
            dispatch(getOilPrices(null, null, startDate, endDate, null));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OilPriceContainer);
