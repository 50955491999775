import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    TablePagination,
    Card,
    MenuItem,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../utils/mtable";
import { getDateByTimestamp, getDateByString } from '../../utils/datetime';
import ProcessLineWithUser from '../ProcessLineApproval/ProcessLineApprovalComponent';
import { arrayToTree } from "../../utils/constant";
import _ from 'lodash';

const PredefinedProcessComponent = ({
    classes,
    companyId,
    userId,
    processTypes,
    predefinedProcess,
    search,
    onhandleChange,
    onhandleSearch,
    processPageSize,
    onhandleSetProcessPageSize,
    onhandleCopyProcessFlow,
    onhandleSave,
    onhandleUpdatePredefinedProcess,
    onhandlePageChange,
    pageNum,
    onhandleProcessFlow,
    page

}) => {

    const PROCESS_SCOPE = [{
        code: 'USER',
        name: '个人'
    }, {
        code: 'COMPANY',
        name: '分厂'
    }, {
        code: 'TENANT',
        name: '全公司'
    }]

    const processTypesSelect = () => {
        var types = _.cloneDeep(processTypes)
        types.unshift({
            code: 'ALL',
            name: '所有分类'
        })
        return types
    }

    const renderProcessType = (row) => {
        if (row && row.ptype) {
            var type = _.find(processTypes, { 'code': row.ptype })
            if (type) {
                return type.name
            }
        }
        return '';
    }

    const renderProcessScope = (row) => {
        if (row && row.pscope) {
            var scope = _.find(PROCESS_SCOPE, { 'code': row.pscope })
            if (scope) {
                return scope.name
            }
        }
        return '';
    }

    const processScopesSelect = () => {

        var scopes = _.cloneDeep(PROCESS_SCOPE)
        scopes.unshift({
            code: 'ALL',
            name: '所有'
        })
        return scopes
    }

    const renderDate = (detail) => {
        return detail.createTime ? getDateByString(detail.createTime) : getDateByTimestamp(detail.createTime, true)
    }


    const renderStatus = (WorkOrder) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效', 'stoped': '停止' };
        return mapping[WorkOrder.status]
    }

    const renderColumns = () => {
        let columns = [];
        columns.push(
            {
                title: '流程名称',
                field: 'pname',
                render: row => row ? <Typography>{row.pname}</Typography> : '',
                // editable: 'never'
                validate: rowData => (!rowData.pname || rowData.pname === '') ? {
                    isValid: false,
                    helperText: '流程名称不能为空'
                } : true,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.pname ? props.rowData.pname : ''}
                        name='pname'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '流程类别',
                field: 'ptype',
                render: row => row ? <Typography>{renderProcessType(row)}</Typography> : '',
                // editable: 'never'
                validate: rowData => (!rowData.ptype || rowData.ptype === '') ? {
                    isValid: false,
                    helperText: '流程类别不能为空'
                } : true,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.ptype ? props.rowData.ptype : ''}
                        name='ptype'
                        onChange={e => props.onChange(e.target.value)}>
                        {processTypes.map(ele => (
                            <MenuItem key={ele.code} value={ele.code}>{ele.name}</MenuItem>
                        ))}
                    </TextField>
                )
            },
            {
                title: '使用范围',
                field: 'pscope',
                render: row => row ? <Typography>{renderProcessScope(row)}</Typography> : '',
                // editable: 'never'
                validate: rowData => (!rowData.pscope || rowData.pscope === '') ? {
                    isValid: false,
                    helperText: '使用范围不能为空'
                } : true,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.pscope ? props.rowData.pscope : ''}
                        name='pscope'
                        onChange={e => props.onChange(e.target.value)}>
                        {PROCESS_SCOPE.map(ele => (
                            <MenuItem key={ele.code} value={ele.code}>{ele.name}</MenuItem>
                        ))}
                    </TextField>
                )
            },
            {
                title: '创建人',
                field: 'creator',
                render: row => row ?
                    row.creatorLastName && row.creatorFirstName ?
                        <Typography>{row.creatorLastName + row.creatorFirstName}</Typography>
                        : row.creatorLastName ?
                            <Typography>{row.creatorLastName}</Typography> :
                            row.creatorFirstName ?
                                <Typography>{row.creatorFirstName}</Typography> :
                                ''
                    : '',
                editable: 'never'
            },
            {
                title: '创建时间',
                field: 'createTime',
                render: row => row ? <Typography>{renderDate(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? renderStatus(row) : '',
                editable: 'never'
            }
        )

        return columns

    }

    const tableOptions = {
        ...options,
        pageSize: processPageSize ? processPageSize : 5,
        showTitle: false,
        // toolbar: false,
        defaultExpanded: true,
        detailPanelType: 'multiple'
        // selection: true
    }
    const tableStyle = {
        ...style
    }

    const renderActions = () => {
        let actions = [];

        actions = [
            rowData => ({
                icon: 'post_add',
                tooltip: '拷贝新建流程及步骤',
                hidden: Boolean(rowData.processSteps.length === 0 || rowData.creator === userId),
                onClick: (event, rowData) => {
                    onhandleCopyProcessFlow(rowData.inventoryId);
                }
            }),
            rowData => ({
                icon: 'playlist_add',
                tooltip: '添加流程步骤',
                hidden: Boolean(rowData.processSteps.length !== 0 || rowData.creator !== userId),
                onClick: (event, rowData) => {
                    onhandleProcessFlow(rowData.inventoryId);
                }
            }),
            rowData => ({
                icon: 'playlist_add_check',
                tooltip: '更新流程步骤',
                hidden: Boolean(rowData.processSteps.length === 0 || rowData.status === 'active' || rowData.creator !== userId),
                onClick: (event, rowData) => {
                    onhandleProcessFlow(rowData.inventoryId);
                }
            }),
            rowData => ({
                icon: 'play_circle_filled',
                tooltip: '启动',
                hidden: Boolean(rowData.processSteps.length === 0 || rowData.creator !== userId),
                disabled: Boolean(rowData.status === 'active'),
                onClick: (event, rowData) => {
                    onhandleUpdatePredefinedProcess(rowData, 'start')
                }
            }),
            rowData => ({
                icon: 'pause_circle_filled',
                tooltip: '停止',
                hidden: Boolean(rowData.processSteps.length === 0 || rowData.creator !== userId),
                disabled: Boolean(rowData.status === 'new' || rowData.status === 'stoped'),
                onClick: (event, rowData) => {
                    onhandleUpdatePredefinedProcess(rowData, 'stop')
                }
            })
        ]
        return actions;
    }

    const components = {
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={processPageSize ? processPageSize : 5}
                count={predefinedProcess.total}
                page={page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum, processPageSize);
                    onhandleSearch(null, null,
                        pageNum * (processPageSize ? processPageSize : 5)
                    )
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSetProcessPageSize(event.target.value);
                    onhandlePageChange(0, processPageSize);
                    onhandleSearch(
                        null, null, 0
                    )
                }}

            />
        )
    }

    const assembleRelationStepPanel = () => {
        return [{
            tooltip: '展开流程步骤',
            render: rowData => {
                return renderItems(rowData);
            }
        }];
    }

    const renderItems = (row) => {
        if (row.processSteps.length === 0) {
            return <Grid className={classes.detailtable}>
                <Typography color='textPrimary' className={classes.label}>
                    当前未设定流程
                </Typography>
            </Grid>
        } else {
            return < React.Fragment>
                <Grid className={classes.detailtable}>
                    <ProcessLineWithUser
                        idKey={'stepId'}
                        treeData={arrayToTree(_.cloneDeep(row.processSteps), 'stepId', 'preId')}
                        singleShow={true}
                        processNotices={_.cloneDeep(row.processNoticeTargets)} />
                </Grid>
            </React.Fragment>
        }
    }

    return (

        <div className={classes.root}>
            <Card className={classes.content}>
                <div>
                    <div>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            预设流程
                        </Typography>
                    </div>
                    <div>
                        <Grid container spacing={4} className={classes.gridContainer}>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography color='textPrimary' className={classes.label}>
                                            名称
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            value={search.text || ''}
                                            variant="standard"
                                            placeholder="流程名称"
                                            onChange={event => onhandleChange(event, 'text')}
                                            // onClick={event => onhandleSearch(event)}
                                            onBlur={event => onhandleSearch(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography color='textPrimary' className={classes.label}>
                                            流程类别
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            select
                                            name='ptype'
                                            onChange={event => onhandleChange(event, 'ptype')}
                                            onClick={event => onhandleSearch(event)}
                                            // onBlur={event => onhandleSearch(event)}
                                            value={search.ptype || ''}
                                        >
                                            {processTypesSelect().map(ele => (
                                                <MenuItem key={ele.code} value={ele.code}>{ele.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography color='textPrimary' className={classes.label}>
                                            使用范围
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            select
                                            name='pscope'
                                            onChange={event => onhandleChange(event, 'pscope')}
                                            onClick={event => onhandleSearch(event)}
                                            // onBlur={event => onhandleSearch(event)}
                                            value={search.pscope || ''}
                                        >
                                            {processScopesSelect().map(ele => (
                                                <MenuItem key={ele.code} value={ele.code}>{ele.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={renderColumns()}
                        data={predefinedProcess.list}
                        options={tableOptions}
                        components={components}
                        editable={
                            {
                                isEditHidden: rowData => rowData.status === 'active' || rowData.creator !== userId,
                                isDeleteHidden: rowData => rowData.status === 'active' || rowData.creator !== userId,
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        resolve()

                                        onhandleSave(newData)
                                    }),
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        resolve()
                                        // onhandlePageChange(pageNum,processPageSize);

                                        onhandleUpdatePredefinedProcess(newData, '')
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve, reject) => {
                                        // onhandlePageChange(pageNum,processPageSize);
                                        onhandleUpdatePredefinedProcess(oldData, 'delete')
                                        resolve()
                                    }),
                            }}
                        localization={localization}
                        style={tableStyle}
                        actions={renderActions()}
                        detailPanel={assembleRelationStepPanel()}
                    >
                    </MaterialTable>
                </div>
            </Card>
        </div>
    );

};
PredefinedProcessComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    processTypes: PropTypes.array.isRequired,
    predefinedProcess: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    onhandlePageChange: PropTypes.func.isRequired
};

PredefinedProcessComponent.defaultProps = {
    processTypes: [],
    predefinedProcess: {
        total: 0,
        list: []
    },
    search: {}

};


export default (withStyles(PredefinedProcessComponent));