import React from 'react';
import './rule.css'
import {
    Typography,
    Button,
    Grid,
    TextField,
    InputLabel,
    NativeSelect,
    FormControl,
    Link
} from '@material-ui/core';
import withStyles from './styles';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';

const RuleSetting = ({
    classes,
    showDetail,
    editRule,
    ruleData,
    onHandleEditRule,
    onHandleCancelEditRule,
    onHandleSaveRule,
    onHandleDeleteRule,
    onhandleOpenConfirmDialog
}) => {
    const tableOptions = {
        pageSize: 5,
        tableLayout: 'auto',
        pageSizeOptions: [5]

    }
    const activityTypeList = {
        0: '普通活动',
        1: '值班活动',
        2: '评审活动'
    };
    const units = {
        'day': '日',
        'week': '周',
        'month': '月',
        'season': '季度',
        'year': '年'
    };
    const ruleColumns = [{
        title: '规则名',
        field: 'ruleName',
        width: '8%',
    },
    {
        title: '规则类型',
        field: 'ruleType',
        width: '10%',
    },
    {
        title: '活动类型',
        field: 'activityType',
        width: '10%',
        lookup: activityTypeList,
    },
    {
        title: '规则内容',
        field: 'rule_display',
        width: '10%',
    },
    {
        title: '创建者',
        field: 'creator',
        width: '10%',
    },
    {
        title: '创建时间',
        field: 'createTime',
        width: '18%',
    },
    {
        title: '修订者',
        field: 'revisor',
        width: '10%',
    },
    {
        title: '修订时间',
        field: 'updateTime',
        width: '18%',
    }
    ];

    const addRule = () => {
        onHandleEditRule({
            ruleId: 0,
            ruleName: '',
            ruleType: '',
            activityType: '0',
            rule: '1/month',
        })
    }

    const setEditDetail = (rowData) => {
        let rule = JSON.parse(JSON.stringify(rowData))
        onHandleEditRule(rule)
    }

    const getRuleAttr = (rule, isUnit) => {
        const index = rule.indexOf('/');
        return isUnit ? rule.substr(index + 1) : rule.substr(0, index);
    }

    const handleRuleNameChange = event => {
        const ruleName = event.target.value;
        let ruleTmp = editRule;
        ruleTmp.ruleName = ruleName;
        onHandleEditRule(ruleTmp)

    }

    const handleRuleTypeChange = event => {
        const ruleType = event.target.value;
        let ruleTmp = editRule;
        ruleTmp.ruleType = ruleType;
        onHandleEditRule(ruleTmp)
    }

    const handleActivityTypeChange = event => {
        // 0: 普通活动
        // 1: 值班活动
        // 2: 评审活动
        const activityType = event.target.value;
        let ruleTmp = editRule;
        ruleTmp.activityType = activityType;
        onHandleEditRule(ruleTmp)
    }

    const handleRuleContentChange = (event, isUnit) => {
        const unit = isUnit ? event.target.value : getRuleAttr(editRule.rule, true);
        const count = !isUnit ? event.target.value : getRuleAttr(editRule.rule, false);

        let ruleTmp = editRule;
        ruleTmp.rule = count + '/' + unit;
        onHandleEditRule(ruleTmp)
    }

    return (<div alignitems="center" className={classes.planitemroot}>
        <Grid container className={classes.title}>
            <Grid item>
                <Typography variant='h3' className={classes.plantype} >个人安全行动计划 - 规则设定</Typography>
            </Grid>
        </Grid>
        {!showDetail ? '' : (
            <div className="ruleDetail">
                <div className="inputArea">
                    <TextField
                        label="规则名"
                        fullWidth
                        value={editRule.ruleName}
                        // disabled={!isEdit}
                        onChange={handleRuleNameChange}
                    />
                </div>
                <div className="inputArea">
                    <TextField
                        label="规则类型"
                        fullWidth
                        value={editRule.ruleType}
                        onChange={handleRuleTypeChange}
                    />
                </div>
                <div className="inputArea">
                    <FormControl fullWidth>
                        <InputLabel id="activity-type-label">活动类型</InputLabel>
                        <NativeSelect
                            labelid="activity-type-label"
                            id="activity-type-label-select"
                            value={editRule.activityType}
                            onChange={handleActivityTypeChange}
                        >
                            {Object.keys(activityTypeList).map((item, index) => {
                                return <option key={'activity-type-' + index} value={item}>{activityTypeList[item]}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                </div>
                <div className="inputArea">
                    <TextField
                        label="次数"
                        fullWidth
                        value={getRuleAttr(editRule.rule, false)}
                        type="number"
                        onChange={(event) => handleRuleContentChange(event, false)}
                    />
                    <div style={{ margin: "25px 15px" }}>/</div>
                    <FormControl fullWidth>
                        <InputLabel id="unit-label">单位</InputLabel>
                        <NativeSelect
                            labelid="unit-label"
                            id="unit-label-select"
                            value={getRuleAttr(editRule.rule, true)}
                            onChange={(event) => handleRuleContentChange(event, true)}
                        >
                            {Object.keys(units).map((item, index) => {
                                return <option key={'unit-time-' + index} value={item}>{units[item]}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                </div>
                <div className="inputArea">
                    <div className="content-button">
                        <Button variant="contained" color="primary" onClick={() => { onHandleSaveRule(editRule) }}>
                            保存
                            </Button>
                    </div>
                    <div className="content-button">
                        <Button variant="contained" onClick={() => { onHandleCancelEditRule() }}>取消</Button>
                    </div>
                </div>
            </div>

        )}
        <MaterialTable
            title=''
            columns={ruleColumns}
            data={ruleData}
            options={tableOptions}
            actions={[
                {
                    icon: 'edit',
                    tooltip: '编辑规则',
                    onClick: (event, rowData) => setEditDetail(rowData)
                },
                {
                    icon: 'delete_outlined',
                    tooltip: '删除规则',
                    onClick: (event, rowData) => {
                        onhandleOpenConfirmDialog('删除确认', '确定要删除本活动吗？', () => onHandleDeleteRule(JSON.parse(JSON.stringify(rowData))))
                    }
                },
                {
                    icon: () => { return (<Link href="#"><AddBox /></Link>) },
                    tooltip: '新增规则',
                    isFreeAction: true,
                    onClick: () => addRule()
                },
            ]}
            localization={{
                header: {
                    actions: ''
                },
            }}
        >
        </MaterialTable>
    </div>
    )
}
const mapStateToProps = (state) => {
    return { CurrentUser: state.auth.currentUserInfo };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(RuleSetting));