import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
} from '@material-ui/core';
import { localization, options, style } from "@/utils/mtable";
import { formatDate_hms } from '@/utils/datetime'
import MaterialTable from 'material-table';
import * as _ from 'lodash';



const VariablePlanComponent = ({
    classes,
    variableBudgetConfigs,
    budgetVariableSubs,
    selectCompanies,
    companyId,
    roleFlag,
    onhandleUpdate,
    onhandleCompanyChange,
}) => {

    const budgetTypeDict = { 'NONE': '单独预算', 'PRODUCE': '基于产量预算' }
    const tableOptions = {
        ...options,
        paging: false,
        fixedColumns: {
            left: 1,
        },
    }
    const tableLocalization = {
        ...localization,
    }

    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '指标名称',
                field: 'subName',
                width: 150,
                render: row => <Typography>{row.subName}</Typography>,
                editable: 'never',
            },
            {
                title: '预算类型',
                field: 'budgetType',
                width: 150,
                render: row => <Typography>{budgetTypeDict[row.budgetType]}</Typography>,
                lookup: budgetTypeDict
            },
            {
                title: '更新时间',
                field: 'updateTime',
                width: 150,
                render: row => <Typography>{row.updateTime ? formatDate_hms(row.updateTime) : ''}</Typography>,
                editable: 'never',
            },
        ]
        return columns
    }

    const assemblyData = () => {
        const configs = _.filter(variableBudgetConfigs, { companyId: companyId })
        let data = _.map(budgetVariableSubs, s => {
            const config = _.find(configs, { subId: s.subId })
            s.budgetType = config ? config.budgetType : 'PRODUCE'
            s.configId = config ? config.configId : 0
            s.updateTime = config ? config.updateTime : ''
            return s
        })
        return data;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container direction="row"
                    justify="space-between" className={classes.gridContainer}>
                    <Grid item>
                    </Grid>
                    <Grid >
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId || ''}
                        >
                            {selectCompanies.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        title={<Typography variant='h3'>变动费预算指标配置</Typography>}
                        style={tableStyle}
                        editable={{
                            isEditHidden: () => !roleFlag,
                            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                onhandleUpdate(newData, oldData)
                                resolve()
                            })
                        }}
                        localization={tableLocalization}
                    >
                    </MaterialTable>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        </div >

    );
};
VariablePlanComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    goalPlan: PropTypes.object,
    year: PropTypes.number,
    businessUnits: PropTypes.array,
    currentProcessStatus: PropTypes.object,
    goalDetails: PropTypes.array,
    onhandleEdit: PropTypes.func,
    onhandleUpdate: PropTypes.func,
    onhandleEditFixedPlan: PropTypes.func,
};

VariablePlanComponent.defaultProps = {
    variableBudgetConfigs: [],
    budgetVariableSubs: [],
    selectCompanies: [],
    companyId: 0,
    roleFlag: false,
}

export default withStyles(VariablePlanComponent);