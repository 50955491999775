import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Button,
    Card
} from '@material-ui/core';
import {
    Add,
} from '@material-ui/icons'
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';
import { getDateByTimestamp } from '../../../../utils/datetime';
import clsx from 'clsx';


const DeviceAccessoryList = props => {

    const {
        classes,
        tabValue,
        index,
        deviceAccessoryList,
        userProfile,
        // getAccessoryList,
        // onEdit,
        onDelete,
        catList,
        // onSizeChange,
        // onView,
        onhandleCreate,
        onhandleBack,
        onReplace,
        // 当前设备
        currentDevice,
        isView,
        isDeviceEdit,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_accessory_list' })
        return permission && permission.action === 'W'
    }
    const devEditable = checkPermission() && !isView && !isDeviceEdit

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // const renderIsCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    // const renderStatus = (row) => {
    //     if (row.status === 'active') {
    //         return '在用'
    //     } else if (row.status === 'new') {
    //         return '备用'
    //     } else if (row.status === 'inactive') {
    //         return '弃用'
    //     } else {
    //         return ''
    //     }
    // }

    const renderTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    }

    const assemblycolumns = () => {
        let columns = [
            {
                title: '部件编号',
                field: 'accNo',
                render: row => row ? <Typography>{row.accNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件名称',
                field: 'accName',
                render: row => row ? <Typography>{row.accName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件类别',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
            },
            // {
            //     title: '通用部件',
            //     field: 'isCommon',
            //     render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
            //     editable: 'never'
            // },
            // 缺陷 部件页面 使用状态删除 SBGL-274
            // {
            //     title: '使用状态',
            //     field: 'status',
            //     render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '更新时间',
                field: 'update_time',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <div>
                <Grid container alignItems="center">
                    <Grid container className={classes.title}>
                        <Typography variant="h3" className={classes.plantname}>
                            {'设备部件列表'}
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                设备编号
                            </Typography>
                            <Typography gutterBottom>
                                {currentDevice.deviceNo ? currentDevice.deviceNo : '-'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                设备名称
                            </Typography>
                            <Typography gutterBottom>
                                {currentDevice.deviceName ? currentDevice.deviceName : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.flexgrow}></Grid>
                </Grid>
                <MTableToolbar {...props} />
            </div>
        )
    }

    return (
        <Card alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={
                clsx(classes.detailtable, {
                    [classes.tableToolbarEditable]: devEditable,
                })
            }>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={deviceAccessoryList}
                    options={{
                        ...options,
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: devEditable,
                    }}
                    components={tabValue ? null : components}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    actions={devEditable ?
                        [rowData => ({
                            icon: 'compare_arrows_icon',
                            tooltip: '更换部件',
                            onClick: (event, rowData) => {
                                onReplace(event, rowData)
                            }
                        }),
                        {
                            icon: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}>
                                新建
                            </Button>,
                            tooltip: '新建',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleCreate()
                            }
                        },
                        rowData => ({
                            icon: 'delete_outline',
                            tooltip: '删除部件',
                            onClick: (event, rowData) => {
                                onDelete(event, rowData)
                            }
                        })
                        ] : [
                        ]}
                >
                </MaterialTable>
            </Grid>
            <div className={classes.card}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="primary"
                            onClick={event => onhandleBack(event)}
                        >
                            返回
                        </Button>
                    </Grid>

                </Grid>
            </div>
        </Card>
    );
};

DeviceAccessoryList.propTypes = {
    classes: PropTypes.object.isRequired,
    deviceAccessoryList: PropTypes.array.isRequired,
    getAccessoryList: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    catList: PropTypes.array.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onReplace: PropTypes.func.isRequired,
    // 当前设备
    currentDevice: PropTypes.object.isRequired,
};

export default (withStyles(DeviceAccessoryList));