import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import { getUsers, getDepartment, getRouteList } from './onsiteInspectionPlan';
import specsColor from './data/specsColor.json';
import { getInstallations } from './installation';
import { getCompanyTree } from './trees';
// import { getSpecCode } from './operation';
import { csv } from '../../utils/exportfile';
import _ from 'lodash';
import { getMoment } from '../../utils/datetime'
const porxyApi = '/api'

const initialState = {
    planReport: [],
    search: {
        companyId: "",
        inspectionType: "",
        routeId: "",
        dptId: "",
        installId: "",
        genItem: "",
        status: "",
        intervalDate: {}
    },
    routeList: [],
    departmentList: [],
    users: {
        total: 0,
        list: []
    },
    load: false,
    specsColor: specsColor,
}

const _renderStatus = (status) => {
    let text = ''
    switch (status) {
        case 'new':
            text = '未开始'
            break;
        case 'active':
            text = '进行中'
            break;
        case 'done':
            text = '已完成'
            break;
        default:
            break;
    }
    return text
}

export const exportCsv = createAction(
    '@@xcloud/onsiteInspectionPlanReport/exportCsv',
    () => (dispatch, getState, httpClient) => {
        let search = getState().onsiteInspectionPlanReport.search;
        let planReport = getState().onsiteInspectionPlanReport.planReport;
        let fileName = '巡检报告';

        if (search.companyId) {
            let companyList = getState().constant.companies;
            let companyInfo = _.find(companyList, { companyId: search.companyId });
            fileName = fileName + '_' + companyInfo.companyAlias;
        }

        if (search.inspectionType) {
            let inspDict = getState().constant.inspectionDictionaries;
            let inspectionType = _.find(inspDict, { code: search.inspectionType });
            fileName = fileName + '_' + inspectionType.name;
        }

        if (search.routeId && search.routeId !== '') {
            let routeList = getState().onsiteInspectionPlan.routeList;
            let routeInfo = _.find(routeList, { routeId: search.routeId });
            fileName = fileName + '_' + routeInfo.routeName;
        }

        if (search.dptId && search.dptId !== '') {
            let departmentList = getState().onsiteInspectionPlan.departmentList;
            let dpt = _.find(departmentList, { dptId: search.dptId });
            fileName = fileName + '_' + dpt.dptName;
        }

        if (search.status && search.status !== '') {
            let status = _renderStatus(search.status);
            fileName = fileName + '_' + status;
        }

        switch (search.intervalDate.view) {
            case 'day':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD")
                break;
            case 'week':
                fileName = fileName + '_[' + getMoment()(search.intervalDate.fromDate).format("YYYYMMDD") + '-' + getMoment()(search.intervalDate.toDate).format("YYYYMMDD") + ']';
                break;
            case 'month':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYYMM");
                break;
            case 'year':
                fileName = fileName + '_' + getMoment()(search.intervalDate.fromDate).format("YYYY");
                break;
            default:
        }

        fileName = fileName + '_' + new Date().getTime()

        let columns = ['单位', '巡检类型', '路径', '执行班组', '日期', '完成情况', '数量']
        let data = []
        planReport.forEach(report => {
            let cell = [
                report.companyName,
                report.inspectionName,
                report.routeName ? report.routeName : '-',
                report.departments,
                report.countDate,
                _renderStatus(report.status),
                report.total,
            ];
            data.push(cell)
        })
        dispatch(csv(fileName, columns, data));
    }
);

export const initPlanReport = createAction('@@xcloud/onsiteInspectionPlanReport/initPlanReport',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getCompanyTree()),
        ]).then((data) => {
            Promise.all([
                //初始化页面头部
                dispatch(getDepartment()),
                dispatch(getRouteList()),
                dispatch(getUsers()),
                // dispatch(getCompanyTree()),
                dispatch(resetSearch()),
                // dispatch(getSpecCode()),
            ]).then((data) => {
                dispatch(getPlanReport(getState().onsiteInspectionPlanReport.search))
            }).catch(err => {
                console.log(err)
                let msg = '初始化巡检报表统计搜索失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        }).catch(err => {
            let msg = '初始化巡检报表统计搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const getPlanReport = createAction(
    '@@xcloud/onsiteInspectionPlanReport/getPlanReport',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/onsiteinspections/planreport"

        if (query.sort !== undefined) {
            url += "?sort=" + query.sort;
        } else {
            url += "?sort=-create_time";
        }

        if (query.companyId && query.companyId !== "") url += "&companyId=" + query.companyId;
        if (query.inspectionType && query.inspectionType !== "") url += "&inspectionType=" + query.inspectionType;
        if (query.routeId && query.routeId !== "") url += "&routeId=" + query.routeId;
        if (query.dptId && query.dptId !== "") url += "&dptId=" + query.dptId;
        if (query.installId && query.installId !== "") url += "&installId=" + query.installId;
        if (query.genItem && query.genItem !== "") url += "&genItem=" + query.genItem;
        if (query.status && query.status !== "") url += "&status=" + query.status;
        if (query.userId && query.userId !== "") url += "&userId=" + query.userId;
        if (query.fromDate && query.fromDate !== "") url += "&fromDate=" + query.fromDate;
        if (query.toDate && query.toDate !== "") url += "&toDate=" + query.toDate;

        if (query.intervalDate && query.intervalDate.view) {
            if (query.intervalDate.view === 'year') url += "&type=year";
            url += "&fromDate=" + query.intervalDate.fromDate + "&toDate=" + query.intervalDate.toDate;
        }
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setplanReport(res.data));
                    dispatch(setLoad(true));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `获取巡检统计信息失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const setplanReport = createAction('@@xcloud/onsiteInspectionPlanReport/setplanReport');

export const resetSearch = createAction('@@xcloud/onsiteInspectionPlanReport/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let newCompantId = null
        let companyList = getState().constant.companies;
        let companyInfo = _.find(companyList, { companyId: company })
        if (!companyInfo.pid) {
            newCompantId = undefined
            dispatch(resetInstallation())
        } else {
            newCompantId = company
            dispatch(resetInstallation(company))
        }

        let search = getState().onsiteInspectionPlanReport.search;
        let newSearch = {
            ...search,
            companyId: newCompantId ? newCompantId : undefined,
            inspectionType: "",
            routeId: "",
            dptId: "",
            installId: "",
            genItem: "",
            status: "",
            intervalDate: {
                view: 'day',
                fromDate: getMoment()().startOf('day').format("YYYY/MM/DD"),
                toDate: getMoment()().startOf('day').format("YYYY/MM/DD")
            }
        }
        dispatch(setSearch(newSearch));
    }
);

export const resetInstallation = createAction('@@xcloud/onsiteInspectionPlanReport/resetInstallation',
    (companyId) => (dispatch, getState, httpClient) => {
        dispatch(getInstallations({ companyId: companyId, size: 0 }))
    }
);

export const updSearchData = createAction('@@xcloud/onsiteInspectionPlanReport/updSearchData',
    (value) => (dispatch, getState, httpClient) => {
        Promise.all([
            dispatch(setSearch(value))
        ]).then(() => {
            dispatch(getPlanReport(value))
        }).catch(err => {
        });
    }
);

export const setSearch = createAction('@@xcloud/onsiteInspectionPlanReport/setSearch');

export const setLoad = createAction('@@xcloud/onsiteInspectionPlanReport/setLoad');

export const onsiteInspectionPlanReport = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setplanReport]: (state, { payload: value }) => {
            return {
                ...state,
                planReport: value,
            };
        },
        [setLoad]: (state, { payload: value }) => {
            return {
                ...state,
                load: value
            };
        },
    },
    initialState
);

export default onsiteInspectionPlanReport;