import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Typography,
    Grid,
    Chip,
    Avatar,
    TextField,
    Button
} from "@material-ui/core";

import MaterialTable, { MTableHeader } from 'material-table';
import { localization, options, style } from "../../../../../utils/mtable";
import UserCard from '../../../../../containers/UserCard';
import ShowCard from '../../../../DeviceManagement/OperationAnnuaPlan/ShowCard';

import _ from 'lodash';

const WorkOrderWithOperationPlanList = props => {

    const {
        classes,
        orderTypeList,
        departmentList,
        optCategories,
        companyList,
        planDetails,
        catList,
        workOrderList,
        accCatList,
        onhandleClose
    } = props;
    const tableRef = React.createRef();

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderCat = (deviceCat) => {
        if (catList && catList.length > 0 && deviceCat) {
            let cat = _.find(catList, { 'code': deviceCat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderAccCat = (accCat) => {
        let label = '';
        if (accCatList && accCatList.length > 0) {
            let value = _.find(accCatList, function (o) { return o.code === accCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderOrderType = (WorkOrder) => {
        let label = '';
        if (orderTypeList && orderTypeList.length > 0) {
            let value = _.find(orderTypeList, function (o) { return o.code === WorkOrder.orderType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // 渲染 - 运维类别
    const renderOptCat = (row) => {
        if ((row.ruleId || row.optType) && optCategories && optCategories.length > 0) {
            let cat = _.find(optCategories, { 'code': (row.ruleId ? row.rule.cat : row.optType) });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderDepartment = (WorkOrder) => {
        let label = '';
        if (departmentList && departmentList.length > 0) {
            let value = _.find(departmentList, function (o) { return o.dptId === WorkOrder.dptId })
            if (value) {
                label = value.dptName
            }
        }
        return label;
    }

    const renderStatus = (WorkOrder) => {
        let mapping = { 'new': '新建', 'active': '有效', 'inactive': '无效', 'reporting': '提交报告', 'approved': '核准', 'published': '可作业', 'submitted': '审核中', 'finished': '待决算', 'done': '完成', 'invalid': '已作废' };
        return mapping[WorkOrder.status]
    }

    const renderContractor = (workOrder) => {
        if (workOrder.executors && workOrder.executors.length > 0) {
            return workOrder.executors.map(ele => {
                return ele.internalCompanyName && ele.internalDptName ?
                    <Chip
                        key={ele.woeId}
                        size="small"
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic1}>
                            内
                        </Avatar>}
                        label={`${ele.internalCompanyName} > ${ele.internalDptName}`}
                    />
                    :
                    <Chip
                        key={ele.woeId}
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic2}>
                            外
                        </Avatar>}
                        size="small"
                        label={`${ele.supplierName}`}
                    />
            }
            )
        } else {
            return null;
        }
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '工单',
                field: 'sortOrderNo',
                defaultGroupOrder: 1,
                defaultGroupSort: 'asc'
            },
            {
                field: 'type',
                render: row => row ?
                    row.masterOrder ?
                        <Avatar className={classes.pic2}>从</Avatar>
                        : <Avatar className={classes.pic1}>主</Avatar>
                    : null,
                width: '2em',
                editable: 'never'
            },
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.orderNo}</Typography> : '',
                width: 150,
                editable: 'never'
            },
            {
                title: '主工单编号',
                field: 'orderNo',
                width: 150,
                render: row => row ? <Typography>{row.masterOrderNo}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '来源单位',
            //     field: 'companyId',
            //     render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '来源部门',
                field: 'dptId',
                render: row => row ? <Typography>{renderDepartment(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '工单类型',
                field: 'orderType',
                editable: 'never',
                render: row => row ? <Typography>{renderOrderType(row)}</Typography> : '',
            },
            {
                title: '工单内容',
                field: 'orderContent',
                editable: 'onUpdate',
                validate: rowData => (!rowData.orderContent || rowData.orderContent === '') ? { isValid: false, helperText: '工单内容不能为空' } : true,
                render: row => row ? <Typography>{row.orderContent}</Typography> : '',
            },
            {
                title: '预算(元)',
                field: 'orderBudget',
                editable: 'onUpdate',
                render: row => row ? <Typography>{row.orderBudget}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        value={props.rowData.orderBudget}
                        type="number"
                        onChange={e => props.onChange(_.toNumber(e.target.value))}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}>
                    </TextField>
                )
            },
            {
                title: '承包商',
                field: 'contractor',
                render: row => row ? renderContractor(row) : '',
                editable: 'never'
            },
            {
                title: '负责人',
                field: 'orderHead',
                render: row => row && row.orderHeadLastName && row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadLastName + row.orderHeadFirstName}</Typography>} /> : row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadFirstName}</Typography>} /> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Chip size="small" className={classes[row.status]} label={renderStatus(row)} /> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    // const renderDate = (date) => {
    //     return date ? getDateByString(date) : ''
    // }

    const renderPlan = () => {
        let columns = [];
        columns.push(
            {
                title: '对象',
                field: 'group',
                defaultGroupOrder: 0,
                defaultGroupSort: 'desc'
            },
            {
                field: 'type',
                render: row => row ? row.device && row.device.deviceId ?
                    <ShowCard currentInfo={row.device} target={row.target}
                        child={
                            < Avatar className={classes.pic1} > 设备</Avatar >}
                    />
                    :
                    < ShowCard currentInfo={row.accessory} target={row.target}
                        child={
                            < Avatar className={classes.pic2} > 部件</Avatar >}
                    />
                    //     < Avatar className = { classes.pic1 } > 设备</Avatar > :
                    // <Avatar className={classes.pic2}>部件</Avatar> :
                    : '',
                // width: '2em',
                editable: 'never'
            },
            {
                title: '单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '计划编号',
                field: 'optNo',
                render: row => row ? <Typography>{row.optNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维计划',
                field: 'ruleName',
                render: row => row ? row.rule ? <Typography>{row.rule.ruleName}</Typography> : <Typography>{'临时计划'}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维类别',
                field: 'optType',
                render: row => row ? <Typography>{renderOptCat(row)}</Typography> : '',
                validate: rowData => (!rowData.optType || rowData.optType === "") ? { isValid: false, helperText: '运维类别不能为空' } : true,
                editable: 'never'
            },
            {
                title: '预算(元)',
                field: 'budget',
                render: row => row ? <Typography>{row.budget}</Typography> : '',
                editable: 'never'
            },
            {
                title: '决算(元)',
                field: 'finalCost',
                render: row => row ? <Typography>{row.finalCost}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
            }
        )
        return columns
    }

    const renderItems = () => {
        return < React.Fragment >
            <Grid className={classes.detailtable}>
                <MaterialTable
                    options={detailOptions}
                    columns={renderPlan()}
                    data={planDetails}
                    style={tableStyle}
                />
            </Grid>
        </React.Fragment >
    }

    const detailOptions = {
        ...options,
        toolbar: false,
        paging: false,
        showTitle: false,
        defaultExpanded: true,
    }

    // const components = {
    //     Toolbar: props => (

    //     )
    // }

    const tableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
        defaultExpanded: true,
        toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    return (
        <div alignitems="center" className={classes.root}><div>
            <Grid container alignItems="center">
                <Grid container className={classes.title}>
                    <Typography variant="h3">
                        {'计划和工单信息'}
                    </Typography>
                </Grid>
                <Grid item className={classes.flexgrow}></Grid>
            </Grid>
            {Boolean(planDetails && planDetails.length > 0) && <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'计划信息'}
                    </Typography>
                </Grid>
                <Grid container className={classes.basicInfoContainer}>
                    {planDetails.length === 1 ? <React.Fragment>
                        <Grid container className={classes.basicInfoContainer}>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    单&emsp;&nbsp;位:
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].companyId ? renderCompany(planDetails[0].companyId) : '-'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    计划编号:
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].optNo ? planDetails[0].optNo : '-'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    运维计划:
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].rule && planDetails[0].rule.ruleName ? planDetails[0].rule.ruleName : '临时计划'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    运维类别:
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0] ? renderOptCat(planDetails[0]) : '-'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    预算(元):
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].budget ? planDetails[0].budget : '-'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    决算(元):
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].fianlCost ? planDetails[0].fianlCost : '-'}
                                </Typography>
                            </Grid>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    计划描述:
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].opentext ? planDetails[0].opentext : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        {planDetails[0].device && planDetails[0].device.deviceId ?
                            <Grid container className={classes.basicInfoContainer}>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备编号:
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.deviceNo ? planDetails[0].device.deviceNo : '-'}
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备名称:
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.deviceName ? planDetails[0].device.deviceName : '-'}
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备位号:
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.tagNo ? planDetails[0].device.tagNo : '-'}
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备类别:
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.cat ? renderCat(planDetails[0].device.cat) : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            : null
                        }
                        {planDetails[0].accessory && planDetails[0].accessory.accId ?
                            <React.Fragment>
                                <Grid container className={classes.basicInfoContainer}>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            部件编号:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.accNo ? planDetails[0].accessory.accNo : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            部件名称:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.accName ? planDetails[0].accessory.accName : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            型&emsp;&emsp;号:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.model ? planDetails[0].accessory.model : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            部件类别:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.cat ? renderAccCat(planDetails[0].accessory.cat) : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            关联设备编号:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.deviceNo ? planDetails[0].accessory.deviceNo : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                        <Typography className={classes.label}>
                                            关联设备名称:
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {planDetails[0].accessory.deviceName ? planDetails[0].accessory.deviceName : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            : null
                        }
                    </React.Fragment> : renderItems()}
                </Grid>
            </React.Fragment>}
        </div>
            <Grid >
                <Typography variant="h4" className={classes.subTitle}>
                    {'工单信息'}
                </Typography>
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={_.sortBy(workOrderList.list, 'orderId')}
                    options={tableOptions}
                    components={{
                        // 修复表头样式错乱问题
                        Header: props => <MTableHeader {...props} classes={{ header: classes.mtHeader }} />
                    }}
                    localization={localization}
                    style={tableStyle}
                >
                </MaterialTable>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item className={classes.flexgrow}></Grid>
                <Button variant="contained" color="primary"
                    onClick={event => onhandleClose(event)}
                >
                    关闭
                </Button>
            </Grid>
        </div >
    );
};

WorkOrderWithOperationPlanList.propTypes = {
    orderTypeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    planDetails: PropTypes.array.isRequired,
    optCategories: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    accCatList: PropTypes.array.isRequired,
};

export default withStyles(WorkOrderWithOperationPlanList);
