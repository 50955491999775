import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ServicePricingComponent from '@/components/AmoebaManagement/ServicePricing';
import ValuationFormulaPop from '@/containers/Common/ValuationFormulaPop/ValuationFormulaPopContainer'
import ServiceHistory from '@/components/AmoebaManagement/ServicePricing/ServiceHistory';
import {
    getServicesPrices, updateServicePrice, getServiceHistories
} from '@/redux/modules/manufacture/manufactureService';
import { judgeMainCompany, SERVICE_TYPE_ALL } from '@/utils/manufactureUtils'
import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { getUser } from '@/redux/modules/usercard';
import { getRole } from "@/utils/userUtils";


/**
 * 服务定价
 */
class ServicePricingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            service: {},
            anchorEl: null,
            formulaType: '',
            valuationFormula: '',
            valuationFormulaStr: '',
            valuationVars: [],
            outputFormula: '',
            oldPopChangeValue: '',
            attrList: [{ id: 1, name: 'new' }],
            isMainCompany: false,
            openFlag: false,
        }
    }

    componentDidMount() {
        const { companyId, companyList, currentUserInfo, getUser, getServices, getBusinessGroupList } = this.props;
        let flag = judgeMainCompany(companyList, companyId);
        if (flag) {
            getServices();
        } else {
            let reqMainCompanyId = companyList.find(ele => ele.companyId === companyId).pid;
            getServices(companyId, reqMainCompanyId);
        }

        getUser(currentUserInfo.userId);
        // 取得指标列表
        getBusinessGroupList();

        this.setState({
            isMainCompany: flag
        })
    }

    handleUpdate = (newData, oldData) => {
        const { companyId, updateServicePrice, companyList, getServices } = this.props;
        const isMainCompany = this.state.isMainCompany;
        if (newData.valuationType === 'CUSTOMER') {
            newData.unitPrice = 1
        }
        const submit = { ...newData };
        if (submit.taxRate) {
            submit.taxRate = submit.taxRate / 100;
        }
        return updateServicePrice(submit,
            () => {
                if (isMainCompany) {
                    getServices();
                } else {
                    let reqMainCompanyId = companyList.find(ele => ele.companyId === companyId).pid;
                    getServices(companyId, reqMainCompanyId);
                }
            }
        );
    }

    getServiceAttr = (service) => {
        let attrList = [];
        if (service.valuationType === 'FIXED') {
            attrList.push({
                id: 'unitPrice',
                name: '月固定价'
            })
        } else if (service.valuationType === 'BUDGET') {
            attrList.push({
                id: 'unitPrice',
                name: '预算价'
            })
        } else if (service.valuationType === 'CUSTOMER') {
            attrList.push({
                id: 'unitPrice',
                name: '客户价'
            })
        } else {
            attrList.push({
                id: 'unitPrice',
                name: '单价'
            })
        }
        service.activities.forEach(element => {
            element.manufactureAttrs.forEach(ele => {
                let node = {
                    id: '_' + ele.serviceConfigId,
                    name: element.activityName + "." + (ele.attrAlias ? ele.attrAlias : ele.attrName)
                }
                if (ele.attrType === "INTEGER" || ele.attrType === "DOUBLE" || ele.attrType === "STRING_LIST") {
                    attrList.push(node);
                }
            })
        })
        return attrList;
    }

    // 计价公式点击事件
    handleClick = (event, rowData, formulaType) => {
        let service = rowData;
        const attrList = this.getServiceAttr(service);
        const valuationVars = JSON.parse(service.valuationVars);
        this.setState({
            service: service,
            attrList: attrList || [],
            anchorEl: event.currentTarget,
            formulaType: formulaType,
            valuationFormulaStr: service.valuationFormulaStr || '',
            valuationFormula: service.valuationFormula || '',
            outputFormulaStr: service.outputFormulaStr || '',
            outputFormula: service.outputFormula || '',
            valuationVars: valuationVars === null ? [] : valuationVars
        })
    }

    handlePopClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleUpdFormula = (valuationFormula, valuationFormulaStr, outputFormula, outputFormulaStr, valuationVars) => {
        let service = this.state.service;
        service.valuationFormula = valuationFormula;
        service.valuationFormulaStr = valuationFormulaStr;
        service.outputFormula = outputFormula;
        service.outputFormulaStr = outputFormulaStr;
        service.valuationVars = valuationVars;

        this.props.updateServicePrice(service).then(() => {
            this.setState({
                service: service,
                anchorEl: null
            })
        });
    }

    handleShowHistory = (event, rowData) => {
        this.props.getServiceHistories(rowData.serviceId);
        this.setState({
            serviceName: rowData.serviceName,
            serviceUnit: rowData.unit,
            openFlag: true,
        })
    }

    handleClose = () => {
        this.setState({
            openFlag: false,
        })
    }

    render() {
        const roleAction = this.props.roleRoutes.find(ele => ele.route === 'amoeba_service_pricing');
        const roleEditFlag = roleAction ? roleAction.action === 'W' : false;
        return (
            <div>
                <ServicePricingComponent
                    services={this.props.servicePrices}
                    manufactureDicts={this.props.manufactureDicts}
                    businessGroupList={this.props.businessGroupList}
                    roleEditFlag={roleEditFlag}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleClick={this.handleClick.bind(this)}
                    onhandleShowHistory={this.handleShowHistory.bind(this)}
                />
                <ValuationFormulaPop
                    anchorEl={this.state.anchorEl}
                    attrList={this.state.attrList}
                    formulaType={this.state.formulaType}
                    valuationFormula={this.state.valuationFormula}
                    outputFormula={this.state.outputFormula}
                    outputFormulaStr={this.state.outputFormulaStr}
                    valuationVars={this.state.valuationVars}
                    serviceName={this.state.service.serviceName}
                    valuationFormulaStr={this.state.valuationFormulaStr}
                    handlePopClose={this.handlePopClose.bind(this)}
                    handleUpdFormula={this.handleUpdFormula.bind(this)}
                />
                {this.state.openFlag &&
                    <ServiceHistory
                        open={this.state.openFlag}
                        serviceName={this.state.serviceName}
                        serviceUnit={this.state.serviceUnit}
                        manufactureDicts={this.props.manufactureDicts}
                        users={this.props.users}
                        serviceHistories={this.props.serviceHistories}
                        onhandleClose={this.handleClose.bind(this)}
                    />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyId: state.auth.currentUserInfo.companyId,
        currentUserInfo: state.auth.currentUserInfo,
        servicePrices: state.manufactureService.servicePrices,
        companyList: state.company.companyList.list,
        manufactureDicts: state.constant.manufactureDicts,
        businessGroupList: state.businessGroup.businessGroupList,
        serviceHistories: state.manufactureService.serviceHistories,
        users: state.usercard.users,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (companyId, reqMainCompanyId) => {
            dispatch(getServicesPrices(companyId, SERVICE_TYPE_ALL, null, null, reqMainCompanyId, null));
        },
        getUser: (userId) => {
            dispatch(getUser(userId));
        },
        updateServicePrice: (service, callback) => {
            return dispatch(updateServicePrice(service, callback));
        },
        getServiceHistories: (serviceId) => {
            return dispatch(getServiceHistories(serviceId));
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServicePricingContainer);
