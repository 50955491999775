import React from 'react';
import {
    Card,
    Grid,
    Paper,
    Container,
    CardMedia,
    Typography,

} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';


const NewsItemPage = props => {
    const { classes
    } = props;
    
    NewsItemPage.propTypes = {
        title: PropTypes.string,
    };

    NewsItemPage.defaultProps = {
        title: '新闻公告',
    };

    return (
        <div alignitems="center" className={classes.root}>
            <Container className={classes.grid} >
                <Paper className={classes.paper}>
                    <Grid>
                        <Typography variant="h1" className={classes.title} >
                            文章标题
                        </Typography>
                    </Grid>

                    <Grid>
                        <Typography variant="subtitle2">时间：19/10/02 作者：王杰</Typography>
                    </Grid>
                    <Grid >
                        <Card className={classes.imagecard}>
                            <CardMedia
                                component="img"
                                alt=""
                                image='/images/login.png'
                                title=''
                                className=''
                            ></CardMedia>
                        </Card>
                    </Grid>
                    <Grid className={classes.contentgrid}>
                        <Typography variant="body" className={classes.content}>

                            中华人民共和国国家安全法，是为了维护国家安全，保卫人民民主专政的政权和中国特色社会主义制度，保护人民的根本利益，保障改革开放和社会主义现代化建设的顺利进行，实现中华民族伟大复兴，根据《中华人民共和国宪法》，制定的法规。
    2015年7月1日，第十二届全国人民代表大会常务委员会第十五次会议通过新的国家安全法。国家主席习近平签署第29号主席令予以公布。法律对政治安全、国土安全、军事安全、文化安全、科技安全等11个领域的国家安全任务进行了明确，共7章84条，自2015年7月1日起施行。
                            1993年2月22日，第七届全国人民代表大会常务委员会第三十次会议通过 ，中华人民共和国主席令第68号公布 施行过一部国家安全法，主要是规定国家安全机关履行的职责特别是反间谍工作方面的职责。但随着国家安全形势的发展变化，这部法律已难以适应全面维护各领域国家安全的需要。
    2014年11月1日，十二届全国人大常委会第十一次会议审议通过了《中华人民共和国反间谍法》，相应废止了1993年2月22日通过的国家安全法。
    2015年7月1日，第十二届全国人民代表大会常务委员会第十五次会议通过，中华人民共和国主席令第29号公布《中华人民共和国国家安全法》，自公布之日起施行。
                        </Typography>

                    </Grid>
                </Paper>
            </Container>
        </div>
    )


}

export default withStyles(NewsItemPage);