import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, DialogContent, Dialog, DialogTitle, DialogActions, TextField} from '@material-ui/core';
import withStyles from './styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as _ from 'lodash';


const ActivityPannelComponent = ({
    classes,
    activityTask,
    open,
    constant,
    onhandleClose,
    onhandleSave,
    onhandleActivityTaskChange,
    users
}) => {
    const activityTaskType = [
        { "seq": 0, "name": "NORMAL" },
        { "seq": 1, "name": "DAILY" },
        { "seq": 2, "name": "WEEKLY" },
        { "seq": 3, "name": "MONTHLY" },
        { "seq": 4, "name": "YEARLY" }
    ]

    return (
        <div alignitems="center" >     
                <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>活动任务</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="任务名称"
                                    name='activityTask'
                                    value={activityTask.taskName}
                                    onChange={event => onhandleActivityTaskChange(event, 'taskName')}
                                />
                            </Grid>
                            <Grid item className={classes.item}>
                            <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="状态"
                                    name='status'
                                    value={activityTask.taskType}
                                    onChange={event => onhandleActivityTaskChange(event, 'taskType')}
                                >
                                    {activityTaskType.map(item => {
                                        return <option key={item.seq} value={item.seq}>
                                            {item.name}
                                        </option>
                                    })}
                                </TextField>
                            </Grid>
                           
                            <Grid item className={classes.item}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.datePicker}
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        label='时间'
                                        inputVariant="outlined"
                                        format="yyyy/MM/dd"
                                        id="date-selector"
                                        margin="normal"
                                        value={activityTask.taskTime}
                                        onChange={event => onhandleActivityTaskChange(event, 'taskTime')}
                                        inputProps={{
                                            readOnly: true
                                        }} />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    variant="outlined"
                                    placeholder="请输入"
                                    label={"任务地点"}
                                    fullWidth
                                    multiline
                                    value={activityTask.taskLocation}
                                    name='recordContent'
                                    onChange={event => onhandleActivityTaskChange(event, 'taskLocation')}
                                />
                            </Grid>

                            <Grid item className={classes.item}>
                                
                                <TextField
                                    variant="outlined"
                                    placeholder="请输入"
                                    label={"任务预算"}
                                    fullWidth
                                    value={activityTask.budget|| '0'}
                                    type="number"
                                    name='recordContent'
                                    onChange={event => onhandleActivityTaskChange(event, 'budget')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item className={classes.item}>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    label={"任务负责人"}
                                    options={users}
                                    getOptionSelected={(option, value) => {
                                        return option.userId === value.userId
                                    }}
                                    onChange={(event, newValue) => onhandleActivityTaskChange(newValue, 'taskHead')}
                                    getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                    style={{
                                        minWidth: 120,
                                    }}
                                    value={_.find(users, { 'userId': activityTask.taskHead }) || ''}
                                    renderInput={(params) => <TextField {...params} placeholder="输入客户名查询" variant="outlined" />}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={onhandleSave}>
                            保存</Button>
                        <Button variant="text" onClick={onhandleClose}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            
        </div>

    );
}

ActivityPannelComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};
ActivityPannelComponent.defaultProps = {

}


export default withStyles(ActivityPannelComponent);