import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    Grid,
    TextField,
    Button,
    Typography,
    Tooltip,
    Card,
    // IconButton,
    MenuItem,
} from '@material-ui/core';
import {
    ExpandMore,
    ExpandLess,
} from '@material-ui/icons'
import { Table } from 'antd';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
    SaveAlt,
} from '@material-ui/icons';
import { getMoment } from '@/utils/datetime';
import '@/assets/rc-tree.css';
import '@/assets/rc-tree-select-tree.css';
import _ from 'lodash';

const RecordReportComponent = props => {
    const {
        classes,
        search,
        companyTree,
        companyList,
        inspectionDictionaries,
        inspectionItemList,
        recordHistoryList,
        onhandleSelect,
        onhandleClearSearch,
        onhandleSearch,
        onhandleDateChange,
        routeList,
        onhandleExport,
        unit
    } = props;

    const [expand, setExpand] = useState(false);

    console.log('inspectionItemList recordHistoryList')
    console.log(inspectionItemList)
    // console.log(recordHistoryList)
    // console.log(search)

    const showSummary = Boolean(inspectionItemList && inspectionItemList.length && inspectionItemList.length > 0);

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }
        return false
    }


    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE" && t.code !== 'OIT05') {
                result.push({ label: t.name, value: t.code })
            }
        })
        return result;
    };

    // const getDptData = (companyId) => {
    //     let company = _.isObject(companyId) ? companyId.value : companyId

    //     let item = _.filter(departmentList, function (o) { return _.toInteger(company) === o.companyId })
    //     let result = []
    //     if (item && item.length) {
    //         _.map(item, t => {
    //             result.push({ label: t.dptName, value: t.dptId })
    //         })
    //     }
    //     return result
    // }

    const getRouteData = (companyId, inspectionType) => {
        let company = _.isObject(companyId) ? companyId.value : companyId


        const condition = { companyId: _.toInteger(company) }
        if (inspectionType) condition.inspectionType = inspectionType
        // let item = _.filter(inspectionRouteList, condition)
        // const condition = {}
        if (company) condition.companyId = _.toInteger(company)
        if (inspectionType) condition.inspectionType = inspectionType

        let item = _.filter(routeList, condition)

        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.routeName, value: t.routeId, prefix: t.companyName })
            })
        }
        return result
    }

    const renderUnit = (row) => {
        let label = '-';
        if (unit && unit.length > 0) {
            let value = _.find(unit, function (o) { return o.code === row.itemUnit })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // 校验巡检数值
    const validateVal = (row, specifiedValue) => {
        let validResult = true
        let val = specifiedValue ? specifiedValue.value : '-';
        let histInsUseStatus = specifiedValue ? specifiedValue.histInsUseStatus : 'INUSE';
        if (specifiedValue) {
            histInsUseStatus = specifiedValue.histInsUseStatus || 'INUSE'
        } else {
            histInsUseStatus = row.histInsUseStatus || 'INUSE'
        }

        if (val === "不合格" || val === "未知") {
            return false
        }

        if (val === "合格") {
            return true
        }

        let standardTemplate = row.template ? row.template : '';
        let standartValue = row.standardValue ? row.standardValue : '';
        if (histInsUseStatus === 'OFFUSE' && row.offTemplate && row.standardOffValue) {
            standardTemplate = row.offTemplate
            standartValue = row.standardOffValue
        }

        let decimalVal = _.toNumber(val)

        let operatorArray = standardTemplate ? standardTemplate.split("|") : []
        let stdValArry = standartValue ? standartValue.split(",") : []

        operatorArray.forEach((operator, idx) => {
            let decimalStdVal = _.toNumber(stdValArry[idx])
            let subOperator = operator.substring(0, 2)

            switch (subOperator) {
                case '<{':
                    validResult = (validResult && decimalVal < decimalStdVal)
                    break;
                case '<=':
                    validResult = (validResult && decimalVal <= decimalStdVal)
                    break;
                case '={':
                    validResult = (validResult && decimalVal === decimalStdVal)
                    break;
                case '>{':
                    validResult = (validResult && decimalVal > decimalStdVal)
                    break;
                case '>=':
                    validResult = (validResult && decimalVal >= decimalStdVal)
                    break;
                default:
                    break;
            }
        })

        return validResult
    }

    const renderstandardValues = (template, standardValue) => {
        if (!template || !standardValue) return '-';

        let output = '';
        if (template.indexOf('|') > -1 && standardValue.indexOf(',') > -1) {

            let logicList = template.split('|');
            let logic1 = logicList[0].split('{')[0];
            let logic2 = logicList[1].split('{')[0];

            let valueList = standardValue.split(',');
            let value1 = valueList[0];
            let value2 = valueList[1];

            output = logic1 + ' ' + value1 + ' 且 ' + logic2 + ' ' + value2
        } else {
            let logic = template.split('{')[0]
            let value = standardValue
            output = logic + value
        }

        return output
    }

    const renderSummaryRow = () => {
        return (<Table.Summary fixed>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}><Typography>{'记录人：'}</Typography></Table.Summary.Cell>
                {
                    Boolean(recordHistoryList && Object.keys(recordHistoryList).length) ?
                        Object.keys(recordHistoryList)
                            .sort((a, b) => {
                                return getMoment()(a).valueOf() - getMoment()(b).valueOf()
                            })
                            .map(key => {
                                // 找巡检人
                                const dateHistory = Object.values(recordHistoryList[key]);
                                let inspector = '';
                                for (const item of dateHistory) {
                                    if (item.inspector) {
                                        inspector = item.inspector;
                                        break;
                                    }
                                }
                                if (inspector) {
                                    return <Table.Summary.Cell align={'right'} key={key} index={'footer'} className={classes.tableFooterCell}>{inspector || '-'}</Table.Summary.Cell>
                                } else {
                                    return <Table.Summary.Cell align={'right'} key={key} index={'footer'} className={classes.tableFooterCell}>{'-'}</Table.Summary.Cell>
                                }
                            }) : <Table.Summary.Cell align={'right'} key={0} index={'footer'} className={classes.tableFooterCell}>无记录人</Table.Summary.Cell>
                }
            </Table.Summary.Row>
        </Table.Summary>)
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '设备',
                dataIndex: 'deviceName',
                key: 'deviceName',
                render: (text, row, index) => row ? <Typography noWrap>{row.deviceName}</Typography> : '',
                width: 150,
                fixed: 'left',
                ellipsis: false
            },
            {
                title: '检查参数',
                dataIndex: 'itemName',
                key: 'itemName',
                align: 'left',
                render: (text, row, index) => <Typography noWrap>{row.itemNameAlias ? row.itemNameAlias : (row.itemName ? row.itemName : '')}</Typography>,
                width: 120,
                fixed: 'left',
                ellipsis: false
            },
            // {
            //     title: '参数描述',
            //     field: 'opentext',
            //     align: 'left',
            //     render: (text, row, index) => <Typography>{row.opentext ? row.opentext : ''}</Typography>,
            //     width: 120,
            //     fixed: 'left',
            // },
            {
                title: '单位',
                field: 'itemUnit',
                dataIndex: 'itemUnit',
                key: 'itemUnit',
                render: (text, row, index) => row ? <Typography>{renderUnit(row)}</Typography> : '',
                width: 90,
                fixed: 'left',
            },
            {
                title: '运行参考值',
                dataIndex: 'template',
                key: 'template',
                align: 'left',
                render: (text, row, index) => <Typography>{renderstandardValues(row.template, row.standardValue)}</Typography>,
                width: 130,
                fixed: 'left',
            },
            {
                title: '停机参考值',
                dataIndex: 'template',
                key: 'template',
                align: 'left',
                render: (text, row, index) => <Typography>{renderstandardValues(row.offTemplate, row.standardOffValue)}</Typography>,
                width: 130,
                fixed: 'left',
            },
            // {
            //     title: '编号',
            //     dataIndex: '调试用的列',
            //     key: '调试用的列',
            //     render: (text, row, index) => row ? <Typography>{row.itemId}</Typography> : '',
            //     editable: 'never',
            //     sorting: false,
            //     width: 90,
            //     fixed: 'left',
            // },
        ];

        // 历史记录列
        if (recordHistoryList && Object.keys(recordHistoryList).length) {
            Object.keys(recordHistoryList)
                .sort((a, b) => {
                    return getMoment()(a).valueOf() - getMoment()(b).valueOf()
                })
                .forEach(key => {
                    columns.push({
                        align: 'right',
                        title: key,
                        dataIndex: key,
                        key: key,
                        render: (text, row, index) => {
                            if (row && row[key]) {
                                return <Tooltip title={row[key].histInsUseStatus === 'OFFUSE' ? '停机巡检' : '正常巡检'} placement="top"><Typography className={!validateVal(row, row[key]) ? classes.exception : null}>
                                    {row[key].value}
                                </Typography>
                                </Tooltip>
                            } else {
                                return '-'
                            }
                        },
                        width: 90
                    })
                })
        } else {
            columns.push({
                align: 'left',
                title: '',
                render: row => <Typography>{'无历史记录'}</Typography>,
                editable: 'never',
                sorting: false,
                width: 90,
                headerStyle: { padding: 2, whiteSpacing: 'nowrap' },
                cellStyle: { padding: 2, whiteSpacing: 'nowrap' }
            })
        }
        return columns;
    }

    return (
        <Card className={classes.content}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'巡检统计'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            {'单位'}
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-company"
                            select
                            value={search.companyId || ''}
                            onChange={(event) => onhandleSelect(event, 'companyId')}
                            variant="outlined"
                            disabled={companyDisabled()}
                        >
                            <MenuItem key={1} value={''}>{'不限'}</MenuItem>
                            {_.sortBy(companyList, 'companyId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            {'巡检类型'}
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            value={search.inspectionType || ''}
                            onChange={(event) => onhandleSelect(event, 'inspectionType')}
                            variant="outlined"
                        >
                            <MenuItem key={1} value={""}>{'不限'}</MenuItem>
                            {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            {'巡检路线'}
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            select
                            value={search.routeId || 0}
                            onChange={(event) => onhandleSelect(event, 'routeId')}
                            variant="outlined"
                        >
                            <MenuItem key={1} value={0} disabled>{'请选定巡检路线'}</MenuItem>
                            {_.sortBy(getRouteData(search.companyId, search.inspectionType), 'prefix').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {!search.companyId ? (option.prefix + ' - ' + option.label) : option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {Boolean(expand) && <React.Fragment>
                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                {'开始时间'}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDateTimePicker
                                    autoOk
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant='outlined'
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm"
                                    name='fromDate'
                                    value={search.fromDate}
                                    onChange={value => onhandleDateChange(value, 'fromDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '开始时间',
                                    }}
                                    InputProps={{
                                        readOnly: true, style: { paddingRight: 0 },
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                {'结束时间'}
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDateTimePicker
                                    autoOk
                                    disableToolbar
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    variant="inline"
                                    inputVariant='outlined'
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm"
                                    name='toDate'
                                    value={search.toDate}
                                    onChange={value => onhandleDateChange(value, 'toDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '结束时间',
                                    }}
                                    InputProps={{
                                        readOnly: true, style: { paddingRight: 0 },
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </React.Fragment>}

                </Grid>

                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                            disabled={!search.routeId}
                        >{'统计'}</Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >{'重置'}</Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>

                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>

                        <Tooltip title={search.routeId ? '导出' : '未选择巡检路线'} placement="top">
                            <Button variant='contained' color='primary' startIcon={<SaveAlt />} style={{ marginLeft: '10px' }} disabled={!search.routeId}
                                onClick={onhandleExport}
                            >
                                导出
                            </Button>
                        </Tooltip>
                        {/* <Tooltip title={search.routeId ? '导出' : '未选择巡检路线'} placement="top">
                        <span>
                            <IconButton onClick={() => { onhandleExport() }} disabled={!search.routeId}>
                                <SaveAlt />
                            </IconButton>
                        </span> */}
                    </Grid>

                </Grid>

            </Grid>


            <Grid className={classes.detailtable}>
                <Table
                    className={classes.stripedTable}
                    rowKey={record => (record.itemId + '_' + Date.now())} // 保持刷新
                    columns={assemblycolumns()}
                    dataSource={inspectionItemList}
                    pagination={false}
                    scroll={{ x: '100%', y: 'calc(100vh - 400px)' }}
                    size={'small'}
                    tableLayout={'fixed'}
                    summary={showSummary ? renderSummaryRow : null}
                />
            </Grid>
        </Card >
    );
};

RecordReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionItemList: PropTypes.array.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleClearSearch: PropTypes.func.isRequired,
    onhandleSearch: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    routeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired
};

export default withRouter(withStyles(RecordReportComponent));