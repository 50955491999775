import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    grid: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    processButton: {
        marginLeft: theme.spacing(2),
    },
    processLine: {
        flexGrow: 1,
    },
    detailTable: {
        padding: theme.spacing(2),
    },
    companySelect: {
        paddingTop: theme.spacing(2),
    }
});


export default withStyles(styles);