import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { getDeviceDictionaries } from '@/redux/modules/constant'
import { createhttpMessage, createMessage } from '@/redux/modules/message';
import { createloading, cleanloading } from '@/redux/modules/loading';
// import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash';

const defaultState = {
  insurances: {
    total: 0,
    list: []
  },
  editable: false,
  currentInsurance: {},
  insuranceError: {},
  insuranceChange: false,
  search: {
    companyId: 0,
    startDate: '',
    endDate: '',
    status: '',
    search: '',
    page: 0,
    size: 10,
  },
  insuranceCount: {},
  insuranceRole: {},
  departmentList: [],
  insTypeList: [],
  statusList: [],
  roleList: []
};


export const getInsTypeList = createAction('@@xcloud/inspection/getInsTypeList',
  () => (dispatch, getState, httpClient) => {
    let { deviceDictionaries } = getState().constant;

    const insTypeList = _.filter(deviceDictionaries, { 'type': 'INSTYPE' });
    dispatch(setInsTypeList(insTypeList));
  });

export const backToInsuranceList = createAction('@@xcloud/insurance/backToInsuranceList',
  () => (dispatch, getState) => {
    dispatch(push('/devices/insurances', { isBack: true }));
  });

export const importInsuranceItems = createAction('@@xcloud/insurance/importInsuranceItems',
  (doc, callback) =>
    (dispatch, getState, httpClient) => {
      dispatch(createloading());
      return httpClient.post('/api/devices/insurances/import', doc).then(
        (res) => {
          dispatch(cleanloading());
          if (callback) {
            callback();
          }
          dispatch(createhttpMessage(res, "导入保险项成功"));
          return res.status;
        }).catch(err => {
          dispatch(cleanloading());
          let msg = `导入保险项失败`;
          dispatch(createhttpMessage(err.response ? err.response : '', msg));
          return []
        })
    });

export const resetSearch = createAction('@@xcloud/insurance/resetSearch',
  () => (dispatch, getState, httpClient) => {

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;
    dispatch(setSearch({
      companyId: hasPcomp ? companyId : 0,
      startDate: '',
      endDate: '',
      status: '',
      search: "",
      page: 0,
      size: 10,
    }));
  });

export const initInsuranceHeader = createAction('@@xcloud/insurance/initInsuranceHeader',
  (isBack) => (dispatch, getState) => {

    const promiseArr = [];
    if (!isBack) {
      promiseArr.push(dispatch(resetSearch()))
    }
    Promise.all(promiseArr).then(() => {

      dispatch(getInsurances(getState().deviceInsurance.search))
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化保险搜索失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });


export const getInsurances = createAction('@@xcloud/insurance/getInsurances',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    // dispatch(setInsuranceChange(false));
    let url = '/api/devices/insurances';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=10";
    }
    if (query.sort !== undefined) {
      url += "&sort=" + query.sort;
    } else {
      url += "&sort=-create_time";
    }
    if (query.companyId)
      url += "&companyId=" + query.companyId;
    if (query.startDate)
      url += "&startDate=" + query.startDate;
    if (query.endDate)
      url += "&endDate=" + query.endDate;
    if (query.search)
      url += "&search=" + query.search;
    if (query.status)
      url += "&status=" + query.status;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setInsurances(res.data));
            dispatch(cleanloading());
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '保险获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getInsuranceById = createAction('@@xcloud/insurance/getInsuranceById',
  (id) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/devices/insurances/' + id;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentInsurance(res.data));
          dispatch(cleanloading());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '保险获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createInsurance = createAction('@@xcloud/insurance/createInsurance',
  (newIns, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());

    let url = '/api/devices/insurances';

    return httpClient.post(url, newIns)
      .then((res) => {
        if (res.status === 201) {
          dispatch(cleanloading());
          dispatch(createMessage('success', '创建保险记录完成'));
          dispatch(backToInsuranceList())
          // dispatch(setCurrentInsurance(res.data));
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        debugger
        dispatch(cleanloading());
        let msg = '创建保险记录失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const changeInsuranceStatus = createAction('@@xcloud/insurance/changeInsuranceStatus',
  (id, status, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/devices/insurances/' + id;
    let to = {
      insId: id,
      status: status
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新保险状态完成'));
          // dispatch(getInsurances(getState().deviceInsurance.search));
          dispatch(backToInsuranceList())
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新保险状态失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateInsurance = createAction('@@xcloud/insurance/updateInsurance',
  (updatedInsurance, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    let url = '/api/devices/insurances/' + updatedInsurance.insId;
    let to = {
      ...updatedInsurance,
      insId: parseInt(updatedInsurance.insId),
      deviceId: parseInt(updatedInsurance.deviceId),
      device: undefined,
      createTime: undefined,
      creator: undefined,
      updateTime: undefined,
      revisor: undefined,
      status: undefined,
      // insCompany: updatedInsurance.insCompany ? updatedInsurance.insCompany : '',
      // insType: updatedInsurance.insType ? updatedInsurance.insType : '',
      // fee1: updateInsurance.fee1 ? _.toNumber(updateInsurance.fee1) : 0,
      // fee2: updateInsurance.fee2 ? _.toNumber(updateInsurance.fee2) : 0,
      // fee3: updateInsurance.fee3 ? _.toNumber(updateInsurance.fee3) : 0,
      // fee4: updateInsurance.fee4 ? _.toNumber(updateInsurance.fee4) : 0,
      // fee5: updateInsurance.fee5 ? _.toNumber(updateInsurance.fee5) : 0,
      // fee6: updateInsurance.fee6 ? _.toNumber(updateInsurance.fee6) : 0,
      // fee7: updateInsurance.fee7 ? _.toNumber(updateInsurance.fee7) : 0,
      // fee8: updateInsurance.fee8 ? _.toNumber(updateInsurance.fee8) : 0,
      // fee9: updateInsurance.fee9 ? _.toNumber(updateInsurance.fee9) : 0,
      // fee10: updateInsurance.fee10 ? _.toNumber(updateInsurance.fee10) : 0,
      // feeSum: updateInsurance.feeSum ? _.toNumber(updateInsurance.feeSum) : 0,
      // startDate: updatedInsurance.startDate ? moment()(updatedInsurance.startDate).startOf('day').valueOf() : undefined,
      // endDate: updatedInsurance.endDate ? moment()(updatedInsurance.endDate).startOf('day').valueOf() : undefined,
      // policyHolder: updatedInsurance.policyHolder ? updatedInsurance.policyHolder : '',
      // status: updatedInsurance.status
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(cleanloading());
          dispatch(getInsurances(getState().deviceInsurance.search))
          dispatch(createMessage('success', '更新保险信息完成'));
          if (callback) callback(res.data);
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '更新保险失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const setCklSearch = createAction('@@xcloud/insurance/setCklSearch');

export const setInsurances = createAction('@@xcloud/insurance/setInsurances');

export const setSearch = createAction('@@xcloud/insurance/setSearch');

export const setCurrentInsurance = createAction('@@xcloud/insurance/setCurrentInsurance');

export const setInsuranceError = createAction('@@xcloud/insurance/setInsuranceError');

export const setInsuranceChange = createAction('@@xcloud/insurance/setInsuranceChange');

export const setEditable = createAction('@@xcloud/insurance/setEditable');

export const setInsTypeList = createAction('@@xcloud/insurance/setInsTypeList');



export const routeToInsuranceInfo = createAction(
  '@@xcloud/insurance/routeToInsuranceInfo',
  (insId, editable) => (dispatch, getState) => {
    return Promise.all([
      dispatch(setEditable(editable))
    ]).then(() => {
      dispatch(push(`/devices/insurances/info/${insId}`));
    })
  }
);

export const initEdit = createAction(
  '@@xcloud/insurance/initEdit',
  (insId) => (dispatch, getState) => {
    const promiseArr = [];
    let isCreate = false;
    if (!insId) {
      isCreate = true;
    } else {
      isCreate = false;
      promiseArr.push(dispatch(getInsuranceById(insId)))
    }

    promiseArr.push(
      promiseArr.push(dispatch(getDeviceDictionaries({ type: 'INSTYPE' })))
    )
    Promise.all(promiseArr).then((res) => {
      dispatch(getInsTypeList())
      dispatch(initInsurance(isCreate))
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        console.error(err)
        dispatch(cleanloading());
        let msg = '初始化详情页失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  }
);

export const initInsurance = createAction(
  '@@xcloud/insurance/initInsurance',
  (isCreate) => (dispatch, getState) => {

    let insurance = isCreate ? {
      "insId": 0,
      "deviceId": 0,
      "insCompany": '',
      "insType": 'INS01',
      "startDate": '',
      "endDate": '',
      "fee1": 0,
      "fee2": 0,
      "fee3": 0,
      "fee4": 0,
      "fee5": 0,
      "fee6": 0,
      "fee7": 0,
      "fee8": 0,
      "fee9": 0,
      "fee10": 0,
      "feeSum": 0,
      "policyHolder": '',
      "status": "active"
    } : getState().deviceInsurance.currentInsurance;
    let terror = {
      "deviceId": '',
      // "insCompany": '',
      "insType": '',
      "startDate": '',
      "endDate": '',
      "feeSum": ''
    };
    dispatch(setCurrentInsurance(insurance));
    dispatch(setInsuranceError(terror));
    dispatch(setInsuranceChange(false));
  }
);

export const insuranceReducer = handleActions(
  {
    [setInsurances]: (state, { payload: value }) => {
      return {
        ...state,
        insurances: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setInsuranceError]: (state, { payload: value }) => {
      return {
        ...state,
        insuranceError: value
      };
    },
    [setInsuranceChange]: (state, { payload: value }) => {
      return {
        ...state,
        insuranceChange: value
      };
    },
    [setCurrentInsurance]: (state, { payload: value }) => {
      return {
        ...state,
        currentInsurance: value
      };
    },
    [setEditable]: (state, { payload: data }) => {
      return {
        ...state,
        editable: data
      }
    },
    [setInsTypeList]: (state, { payload: value }) => {
      return {
        ...state,
        insTypeList: value
      };
    },
  },
  defaultState
);

export default insuranceReducer;
