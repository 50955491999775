import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChecklistInfo from '../../../components/InspectionManagement/Checklist/ChecklistInfo'
import {
    createChecklist,
    updateChecklist,
    setCurrentChecklist,
    backToChecklistTable,
    backToInventoryList,
    setChecklistError,
    goToRegulation,
    setChecklistChange
} from "@/redux/modules/inspection/inspection";
import { getRegulations, getRegulationVersions } from "../../../redux/modules/regulation";
import _ from 'lodash';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { download } from '../../../redux/modules/minio';
import PropTypes from "prop-types";

class ChecklistEditContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        if (!this.props.currentInventory || !this.props.currentInventory.inventoryId) {
            this.props.onhandleBackToInventoryList();
        }
    }

    static propTypes = {
        currentChecklist: PropTypes.object,
        checklistError: PropTypes.object,
        checklistChange: PropTypes.bool,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
        listTypeList: PropTypes.array,
        categoryList: PropTypes.array,
        severityList: PropTypes.array,
        rootCauseList: PropTypes.array,
        unitList: PropTypes.array,
        buList: PropTypes.array,
        methodList: PropTypes.array,
        disciplineList: PropTypes.array,
        resultTypeList: PropTypes.array
    };

    getDptInspectTypes(listTypeList) {
        const {
            inspectionDptMapping
        } = this.props;

        // 过滤排查列表
        const filtedTypeList = _.filter(listTypeList, (o) => {
            if (_.findIndex(inspectionDptMapping, { 'inspectionCode': o.code }) > -1)
                return true;
        })
        return filtedTypeList;
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.checklistError), function (o) { return o !== ''; }) < 0) {
            const { currentChecklist } = this.props;
            this.props.onhandleChecklistChange(false);

            let pCompany = _.find(this.props.companies, o => !o.pid);
            if (!currentChecklist.companyId || currentChecklist.companyId === '')
                currentChecklist.companyId = pCompany.companyId;

            if (currentChecklist.attr1)
                currentChecklist.attr1 = _.trim(currentChecklist.attr1);
            if (currentChecklist.attr2)
                currentChecklist.attr2 = _.trim(currentChecklist.attr2);
            if (currentChecklist.problem)
                currentChecklist.problem = _.trim(currentChecklist.problem);
            if (currentChecklist.reference)
                currentChecklist.reference = _.trim(currentChecklist.reference);
            if (currentChecklist.opentext)
                currentChecklist.opentext = _.trim(currentChecklist.opentext);
            // att中如果只填写了attr2，则将attr1内容换为attr2的内容
            if (!currentChecklist.attr1 || currentChecklist.attr1 === '') {
                if (currentChecklist.attr2 && currentChecklist.attr2 !== '') {
                    currentChecklist.attr1 = currentChecklist.attr2;
                    currentChecklist.attr2 = '';
                }
            }
            if (currentChecklist.listId) {
                this.props.onhandleUpdateChecklist(currentChecklist);
            } else {
                this.props.onhandleCreateChecklist(currentChecklist);
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleChecklistChange(true);
        let newChecklist;
        switch (field) {
            case 'score':
                newChecklist = {
                    ...this.props.currentChecklist,
                    score: event.target.value
                };
                break;
            case 'attr1':
                newChecklist = {
                    ...this.props.currentChecklist,
                    attr1: event.target.value
                };
                break;
            case 'attr2':
                newChecklist = {
                    ...this.props.currentChecklist,
                    attr2: event.target.value
                };
                break;
            case 'problem':
                newChecklist = {
                    ...this.props.currentChecklist,
                    problem: event.target.value
                };
                break;
            case 'reference':
                newChecklist = {
                    ...this.props.currentChecklist,
                    reference: event.target.value
                };
                break;
            case 'opentext':
                newChecklist = {
                    ...this.props.currentChecklist,
                    opentext: event.target.value
                };
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditChecklist(newChecklist);
    };

    handleSelect = (value, field) => {
        this.props.onhandleChecklistChange(true);
        let newSelect;
        switch (field) {
            case 'listType':
                newSelect = {
                    ...this.props.currentChecklist,
                    listType: value.target.value
                };
                break;
            case 'category':
                newSelect = {
                    ...this.props.currentChecklist,
                    catCode: value.target.value
                };
                break;
            case 'company':
                newSelect = {
                    ...this.props.currentChecklist,
                    companyId: value.target.value
                };
                break;
            case 'department':
                let findDpt = _.find(this.props.departments, { 'dptId': _.toInteger(value.target.value) });
                newSelect = {
                    ...this.props.currentChecklist,
                    dptId: value.target.value,
                    companyId: findDpt ? findDpt.companyId : undefined,
                    roleId: undefined,
                };
                break;
            case 'role':
                newSelect = {
                    ...this.props.currentChecklist,
                    roleId: value.target.value
                };
                break;
            case 'unit':
                newSelect = {
                    ...this.props.currentChecklist,
                    unitId: value.target.value
                };
                break;
            case 'severity':
                newSelect = {
                    ...this.props.currentChecklist,
                    severity: value.target.value
                };
                break;
            case 'method':
                newSelect = {
                    ...this.props.currentChecklist,
                    method: value.target.value
                };
                break;
            case 'rootCause':
                newSelect = {
                    ...this.props.currentChecklist,
                    rootCause: value.target.value
                };
                break;
            case 'discipline':
                newSelect = {
                    ...this.props.currentChecklist,
                    discipline: value.target.value
                };
                break;
            case 'resultType':
                newSelect = {
                    ...this.props.currentChecklist,
                    resultType: value.target.value
                };
                break;
            case 'regulation':
                newSelect = {
                    ...this.props.currentChecklist,
                    regId: value ? value.regId : null
                };
                break;
            case 'descRequired':
                newSelect = {
                    ...this.props.currentChecklist,
                    descRequired: value.target.value === "true" ? true : false
                };
                break;
            case 'imageRequired':
                newSelect = {
                    ...this.props.currentChecklist,
                    imageRequired: value.target.value === "true" ? true : false
                };
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditChecklist(newSelect);
        // 等currentChecklist状态更新后再check select组件
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleBack = (event) => {
        const { currentInventory } = this.props;
        if (currentInventory && currentInventory.inventoryId) {
            this.props.onhandleBackToChecklistTable(currentInventory.inventoryId);
        } else {
            this.props.onhandleBackToInventoryList();
        }
    };

    handleGoToRegulation = (regId) => {
        this.props.onhandleGoToRegulation(regId);
    }

    handleCheck = (event, field) => {
        // 部门可为空，为空时表示该检查表所有部门都会参与
        let err = {};

        if (!field || field === 'problem') {
            if (_.trim(this.props.currentChecklist.problem) === '') {
                err['problem'] = '检查内容不能为空';
            } else {
                err['problem'] = '';
            }
        }

        if (!field || field === 'listType') {
            if (_.trim(this.props.currentChecklist.listType) === '') {
                err['listType'] = '排查分类不能为空';
            } else {
                err['listType'] = '';
            }
        }

        if (!field || field === 'category') {
            if (_.find(this.props.categoryList, { 'pcode': this.props.currentChecklist.listType }) && this.props.currentChecklist.catCode === '') {
                err['catCode'] = '子分类不能为空';
            } else {
                err['catCode'] = '';
            }
        }

        // 角色可以不选，不选意味着“不限”角色
        // if (!field || field === 'role') {
        //     const selectedDpt = _.find(this.props.departments, { 'dptId': _.toInteger(this.props.currentChecklist.dptId) });
        //     const showRoleSelect = selectedDpt && selectedDpt.companyPid && selectedDpt.companyPid !== '';
        //     if (showRoleSelect && _.trim(this.props.currentChecklist.roleId) === '') {
        //         err['role'] = '角色不能为空';
        //     } else {
        //         err['role'] = '';
        //     }
        // }

        // if (!field || field === 'company') {
        //     if (_.trim(this.props.currentChecklist.companyId) === '') {
        //         err['company'] = '单位不能为空';
        //     } else {
        //         err['company'] = '';
        //     }
        // }

        if (!field || field === 'resultType') {
            if (_.trim(this.props.currentChecklist.resultType) === '') {
                err['resultType'] = '结果类型不能为空';
            } else {
                err['resultType'] = '';
            }
        }

        if (!field || field === 'score') {
            if (this.props.currentChecklist.resultType === 'SCORE' && _.trim(this.props.currentChecklist.score) === '') {
                err['score'] = '分值不能为空';
            } else if (_.trim(this.props.currentChecklist.score) !== '' && (_.isNaN(_.toNumber(this.props.currentChecklist.score)) || _.toNumber(this.props.currentChecklist.score) <= 0)) {
                err['score'] = '请输入正确的数值';
            } else {
                err['score'] = '';
            }
        }

        let newError = {
            ...this.props.checklistError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            departments,
            bUnits,
            roleList,
            inspectionDict,
            regulations
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyAlias : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldId }) ? _.find(departments, { 'dptId': fieldId }).companyDptName : '不限';
                break;
            case 'role':
                value = _.find(roleList, { 'code': fieldId }) ? _.find(roleList, { 'code': fieldId }).name : '-';
                break;
            case 'unit':
                value = _.find(bUnits, { 'unitId': fieldId }) ? _.find(bUnits, { 'unitId': fieldId }).unitName : '-';
                break;
            case 'inspectionDict':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '-';
                break;
            case 'regulation':
                value = _.find(regulations, { 'regId': fieldId }) ? _.find(regulations, { 'regId': fieldId }).regName : '-';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleDownload(regId) {
        const { onhandleGetRegulationVersion, onhandleDownloadRegulation } = this.props
        onhandleGetRegulationVersion(regId, (versions) => {
            if (versions) {
                let versionArr = versions.filter((version) => {
                    return version.status === 'new'
                })
                if (versionArr && versionArr.length !== 0) {
                    let fileName = versionArr[0].docLink;
                    let mybucket = 'test';
                    onhandleDownloadRegulation(fileName, mybucket);
                }
            }
        })
    }

    // 机关内"不限"部门选项是否显示
    handleShowNoSpecifyOption() {
        const company = _.find(this.props.companyList, { 'companyId': this.props.currentUserInfo.companyId });
        return company ? !company['pid'] : false
    }

    render() {
        return (
            <ChecklistInfo
                editable={this.props.editable}
                currentUserInfo={this.props.currentUserInfo}
                onhandleSave={this.handleSave.bind(this)}
                currentInventory={this.props.currentInventory}
                currentChecklist={this.props.currentChecklist}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onGoToRegulation={this.handleGoToRegulation.bind(this)}
                showNoSpecify={this.handleShowNoSpecifyOption()}
                checklistError={this.props.checklistError}
                checklistChange={this.props.checklistChange}
                companyList={this.props.companyList}
                departmentList={this.props.departments}
                listTypeList={this.getDptInspectTypes(this.props.listTypeList)}
                categoryList={this.props.categoryList}
                severityList={this.props.severityList}
                rootCauseList={this.props.rootCauseList}
                buList={this.props.buList}
                methodList={this.props.methodList}
                disciplineList={this.props.disciplineList}
                resultTypeList={this.props.resultTypeList}
                roleList={this.props.roleList}
                regulationList={this.props.regulations}
                onhandleListMapping={this.handleListMapping.bind(this)}
                inspectionDptMapping={this.props.inspectionDptMapping}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        bUnits: state.constant.businessunits,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        editable: state.inspection.editable,
        currentInventory: state.inspection.currentInventory,
        currentChecklist: state.inspection.currentChecklist,
        checklistError: state.inspection.checklistError,
        checklistChange: state.inspection.checklistChange,
        companyList: state.constant.companies,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        severityList: state.inspection.severityList,
        rootCauseList: state.inspection.rootCauseList,
        buList: state.inspection.buList,
        methodList: state.inspection.methodList,
        disciplineList: state.inspection.disciplineList,
        resultTypeList: state.inspection.resultTypeList,
        roleList: state.inspection.roleList,
        regulations: state.regulation.regulations
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateChecklist: (entity) => {
            dispatch(createChecklist(entity))
        },
        onhandleUpdateChecklist: (entity) => {
            dispatch(updateChecklist(entity))
        },
        onhandleUpdateEditChecklist: (entity) => {
            dispatch(setCurrentChecklist(entity))
        },
        onhandleGetRegulations: () => {
            dispatch(getRegulations())
        },
        onhandleBackToChecklistTable: (id) => {
            dispatch(backToChecklistTable(id))
        },
        onhandleBackToInventoryList: () => {
            dispatch(backToInventoryList())
        },
        onhandleGoToRegulation: (regId) => {
            dispatch(goToRegulation(regId))
        },
        onhandleSetError: (err) => {
            dispatch(setChecklistError(err))
        },
        onhandleChecklistChange: (isChanged) => {
            dispatch(setChecklistChange(isChanged))
        },
        onhandleGetRegulationVersion: (regId, callback) => {
            dispatch(getRegulationVersions(regId, callback))
        },
        onhandleDownloadRegulation: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistEditContainer)
