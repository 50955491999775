import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Link,
    TextField,
    FormHelperText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { CloudUpload } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

const WorkPermitDocPanel = ({
    classes,
    open,
    type,
    docError,
    doc,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleUpload,
}) => {

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">
                        {type === 'JSA' ? "JSA文档上传" : '大许可文档上传'}
                    </Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"名称"}
                                variant='outlined'
                                className={classes.textField}
                                error={docError.docName !== ''}
                                helperText={docError.docName}
                                value={doc.docName || ''}
                                name='docName'
                                onChange={event => onhandleChange(event, 'docName')}
                                onBlur={event => onhandleCheck(event, 'docName')}
                            />
                        </Grid>
                        <Grid item container className={classes.itemGrid} justify={'space-between'}>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={Boolean(docError.file) ? classes.uploadErr : null}
                                    onClick={onhandleUpload}
                                    startIcon={<CloudUpload />}
                                >
                                    {'上传文档'}
                                </Button>
                                <FormHelperText className={classes.uploadErrText}>{docError.file}</FormHelperText>
                            </Grid>
                        </Grid>
                        {
                            doc.file && doc.file.length && doc.file.length > 0 &&
                            <Grid item className={classes.itemGrid} alignItems={'flex-end'} container>
                                <Typography align={'right'}>已选择文件（保存时上传）：</Typography>
                                <Link underline='always' component="button">{doc.file[0].name}</Link>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {'保存'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
WorkPermitDocPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
};

WorkPermitDocPanel.defaultProps = {

}

export default withStyles(WorkPermitDocPanel);