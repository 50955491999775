import { createAction, handleActions } from 'redux-actions';
// import { handleActions } from 'redux-actions';
// import * as _ from 'lodash'
import { changeExistTreeData } from './deviceTree'

const initialState = {
  canvasWidth: window.innerWidth,
  canvasHeight: window.innerHeight,
  konvaWidth: 6000,
  konvaHeight: 3000,
  zoom: 100,
  beginX: 100,
  beginY: 100,
  zIndex: 1,
  dialogshow: false,
  isFullScreen: false,
  scaleBy: 1.04,
  scale: 1,
  unitView: true
}

export const setSetting = createAction('@@xcloud/viewSetting/setSetting');
export const fullScreenSetting = createAction('@@xcloud/viewSetting/fullScreenSetting');
export const exitFullScreenSetting = createAction('@@xcloud/viewSetting/exitFullScreenSetting');
export const setScale = createAction('@@xcloud/viewSetting/setScale');
export const updateUnitView = createAction('@@xcloud/viewSetting/updateUnitView',
  () => (dispatch, getState) => {
    dispatch(changeExistTreeData(!getState().setting.unitView));
  });

export const viewSettingReducer = handleActions(
  {
    [setSetting]: (state) => {
      return {
        ...state
      }
    },
    [fullScreenSetting]: (state) => {
      return {
        ...state,
        isFullScreen: true,
        canvasWidth: window.innerWidth,
        canvasHeight: window.innerHeight,
      }
    },
    [exitFullScreenSetting]: (state) => {
      return {
        ...state,
        isFullScreen: false
      }
    },
    [setScale]: (state, { payload: scale }) => {
      return {
        ...state,
        scale: scale
      }
    },
    [updateUnitView]: (state) => {
      return {
        ...state,
        unitView: !state.unitView
      }
    },
  },
  initialState
)

export default viewSettingReducer