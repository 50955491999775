import React from 'react';
import {
    Card, Grid, Typography, TextField, MenuItem, Button, IconButton, InputAdornment
} from '@material-ui/core';
import withStyles from '@/components/ManufactureManagement/AssayRecordEdit/styles';
import PropTypes from 'prop-types';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash'

const AssayRecordEditComponent = props => {
    const { classes,
        record,
        companyList,
        installList,
        temps,
        departments,
        assayers,
        inspectors,
        recordError,
        onhandleSave,
        onhandleChange,
        onhandleCancel,
        onhandleDateChange,
        onhandleCheck,
        onhandleItemChange
    } = props;

    const renderRecordDetail = () => {
        var temp = _.find(temps, function (o) { return o.tempId === record.tempId })
        if (temp) {
            var basicItems = _.filter(temp.items, function (o) { return o.itemType === 1 || o.itemType === 3 });
            var valItems = _.filter(temp.items, function (o) { return o.itemType === 2 });
            return <div className={classes.gridContainer}>
                <Typography variant='h3'>详细结果</Typography>
                <Grid container direction="row" spacing={0} className={classes.gridContainer}>
                    {renderDetailHeader(basicItems)}
                </Grid>
                <Grid container direction="row" spacing={0}>
                    {renderDetailValue(basicItems, record.details)}
                </Grid>
                {renderAssayDetails(valItems)}
            </div>
        } else {
            return <div alignitems="center" className={classes.detailroot}>
                <Typography variant="h6">化验记录模板缺失，请联系系统管理员</Typography></div>
        }
    }

    const renderAssayDetails = (items) => {
        if (items.length > 12) {
            let maxCount = parseInt(items.length / 12)
            let itemArr = [];
            for (var i = 0; i < maxCount; i++) {
                itemArr.push(items.slice(i, i+12))
            }
            itemArr.push(items.slice(items.length - items.length % 12))
            return itemArr.map(ele => (<div>
                <Grid container direction="row" spacing={0} className={classes.gridContainer}>
                    {renderDetailHeader(ele)}
                </Grid>
                <Grid container direction="row" spacing={0}>
                    {renderDetailValue(ele, record.details)}
                </Grid>
            </div>))
        } else {
            return (<div>
                <Grid container direction="row" spacing={0} className={classes.gridContainer}>
                    {renderDetailHeader(items)}
                </Grid>
                <Grid container direction="row" spacing={0}>
                    {renderDetailValue(items, record.details)}
                </Grid>
            </div>)
        }
    }

    const renderDetailHeader = (items) => {
        return items.map(item => {
            return (<Grid item xs={1}>
                <Typography variant='caption'>
                    {item.itemName}
                </Typography>
            </Grid>)
        })
    }

    const renderDetailValue = (items, data) => {
        return items.map(item => {
            var val = _.find(data, function (o) { return o.itemId === item.itemId });
            if (val) {
                return (<Grid item xs={1}>
                    <TextField
                        className={classes.textField}
                        value={val.itemVal || ''}
                        variant="standard"
                        onChange={event => onhandleItemChange(event, item.itemId)}
                    />
                </Grid>)
            } else {
                return (<Grid item xs={1}>
                    <TextField
                        className={classes.textField}
                        value={''}
                        variant="standard"
                        onChange={event => onhandleItemChange(event, item.itemId)}
                    />
                </Grid>)
            }

        })
    }

    return (
        <div>
            <Card className={classes.card}>

                {
                    record.recordId ? (<Typography variant="h3" gutterBottom>
                        编辑化验记录
                    </Typography>) : (<Typography variant="h3" gutterBottom>
                            新增化验记录
                    </Typography>)
                }
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography >
                            单位:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='companyId'
                            onChange={event => onhandleChange(event, 'companyId')}
                            value={record.companyId}
                        >
                            {companyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            部门:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='dptId'
                            onChange={event => onhandleChange(event, 'dptId')}
                            value={record.dptId}
                        >
                            {[{ dptId: 0, dptName: '无' }, ...departments].map(ele => {
                                if (ele.dptId === 0 || ele.companyId === record.companyId) {
                                    return <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                                } else {
                                    return null
                                }
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            装置:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='installId'
                            onChange={event => onhandleChange(event, 'installId')}
                            value={record.installId}
                        >
                            {[{ installId: 0, installName: '无' }, ...installList].map(ele => {
                                if (ele.installId === 0 || ele.companyId === record.companyId) {
                                    return <MenuItem key={ele.installId} value={ele.installId}>{ele.installName}</MenuItem>
                                } else {
                                    return null
                                }
                            })}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography >
                            化验时间:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDateTimePicker
                                autoOk
                                className={classes.textField}
                                disableToolbar
                                variant="inline"
                                ampm={false}
                                format="yyyy/MM/dd HH:mm"
                                name='testTime'
                                value={record.testTime}
                                onChange={value => onhandleDateChange(value, 'testTime')}
                                KeyboardButtonProps={{
                                    'aria-label': '化验时间',
                                }}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment:
                                        (
                                            <IconButton
                                                style={{ order: 1, display: '' }}
                                                onClick={() => onhandleDateChange(null, 'testTime')}
                                            >

                                            </IconButton>
                                        )
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2, marginLeft: 0 }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            物料名称:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            error={recordError.testTarget !== undefined && recordError.testTarget !== ''}
                            helperText={recordError.testTarget || ''}
                            className={classes.textField}
                            value={record.testTarget || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'testTarget')}
                            onBlur={event => onhandleCheck(event, 'testTarget')}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            化验内容:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='tempId'
                            onChange={event => onhandleChange(event, 'tempId')}
                            value={record.tempId}
                        >
                            {temps.map(ele => (
                                <MenuItem key={ele.tempId} value={ele.tempId}>{ele.tempName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography >
                            化验员:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='assayer'
                            onChange={event => onhandleChange(event, 'assayer')}
                            value={record.assayer}
                        >
                            {assayers.map(ele => (
                                <MenuItem key={ele.userId} value={ele.userId}>{(ele.lastName || '') + (ele.firstName || '')}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            质检员:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            select
                            name='inspector'
                            onChange={event => onhandleChange(event, 'inspector')}
                            value={record.inspector}
                        >
                            {inspectors.map(ele => (
                                <MenuItem key={ele.userId} value={ele.userId}>{(ele.lastName || '') + (ele.firstName || '')}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography >
                            取样/送样费用:
                                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className={classes.textField}
                            value={record.extFee != null ? record.extFee : '0'}
                            variant="standard"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                            onChange={event => onhandleChange(event, 'extFee')}
                            onBlur={event => onhandleCheck(event, 'extFee')}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography >
                            备注:
                                    </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            className={classes.textField}
                            value={record.opentext || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>
                {renderRecordDetail()}
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleSave(event)}
                        >
                            保存
                </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleCancel(event)}
                        >
                            取消
                </Button>
                    </Grid>
                </Grid>
            </Card>
        </div >
    )
}

AssayRecordEditComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

AssayRecordEditComponent.defaultProps = {
};

export default withStyles(AssayRecordEditComponent);