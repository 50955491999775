import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
    Button,
    Divider,
    Typography,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import Add from '@material-ui/icons/Add';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';


const CreateOnDutyPlan = ({
    classes,
    open,
    planName,
    selectedDate,
    rules,
    ruleChanged,
    onhandleChangeRule,
    onhandleSaveRule,
    onhandleAddOnDutyPlan,
    onhandleChangePlan,
    onhandleChangeDate,
    onhandleClose,
}) => {

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog
                open={open}>
                <DialogTitle  >
                    <Typography component={'span'} variant='h3'>创建带班值班计划</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item >
                            <Grid container className={classes.contentGrid} >
                                <Grid item className={classes.action}>
                                    <TextField className={classes.textfield}
                                        variant="outlined"
                                        placeholder="请输入"
                                        label="目标名称"
                                        value={planName}
                                        name='planName'
                                        onChange={onhandleChangePlan}
                                    />
                                </Grid>
                                <Grid item className={classes.action}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            views={['year', 'month']}
                                            format="yyyy/MM"
                                            id="date-selector"
                                            margin="normal"
                                            value={selectedDate}
                                            onChange={onhandleChangeDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item><Divider orientation="vertical" /></Grid>
                        <Grid item>
                            <Grid className={classes.action}>
                                <Typography variant='h5'>带班值班规则</Typography>
                            </Grid>
                            <Grid container className={classes.contentGrid}>
                                {rules.map(rule => (
                                    <Grid item key={rule.ruleId} className={classes.action}>
                                        <TextField className={classes.textfield}
                                            variant="outlined"
                                            label={rule.ruleName}
                                            value={rule.ruleValue}
                                            name='rule'
                                            onChange={onhandleChangeRule.bind(this, rule)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{rule.ruleUnit}</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined'
                        color='primary'
                        disabled={!ruleChanged}
                        onClick={onhandleSaveRule}>
                        <Save />保存规则
                    </Button>
                    <Button variant='contained' color='primary' onClick={onhandleAddOnDutyPlan}>
                        <Add />创建计划
                        </Button>
                    <Button variant='outlined' onClick={onhandleClose}><Close /> 取消</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
CreateOnDutyPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleAddOnDutyPlan: PropTypes.func,
};

CreateOnDutyPlan.defaultProps = {
    planName: '',
    month: 0,
    activityRules: [],
}

export default (withStyles(CreateOnDutyPlan));