import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 0
  },
  card: {
    width: '100%',
    padding: 20,
    margin: 5
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  gridContainer: {
    marginTop: 10
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  label: {
    marginTop: 5
  },
  gridItem: {
    height: 24
  },
  select: {
    width: '90%',
    marginTop: 7
  },
  pending: {
    width: 60,
  },
  active: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main
  },
  done: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  invalid: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  passed: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  denied: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
});

export default withStyles(styles);

