import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    customers: [], //客户列表
    customerGroups: [], //客户分组列表
};

export const setCustomers = createAction('@@xcloud/manufacture/setCustomers');
export const setCustomerGroups = createAction('@@xcloud/manufacture/setCustomerGroups');

export const getCustomers = createAction('@@xcloud/manufacture/getCustomers',
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/customers'
        if (query.cstName || query.extId || query.groupId || query.status) {
            let strs = []
            if (query.cstName) strs.push('cstName=' + query.cstName)
            if (query.extId) strs.push('extId=' + query.extId)
            if (query.groupId) strs.push('groupId=' + query.groupId)
            if (query.status) strs.push('status=' + query.status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setCustomers(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取客户列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });
export const getCustomerGroups = createAction('@@xcloud/manufacture/getCustomerGroups',
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/customergroups'
        if (query.parentId || query.extId || query.extParentId || query.groupName || query.status) {
            let strs = []
            if (query.parentId) strs.push('parentId=' + query.parentId)
            if (query.extId) strs.push('extId=' + query.extId)
            if (query.extParentId) strs.push('extParentId=' + query.extParentId)
            if (query.groupName) strs.push('groupName=' + query.groupName)
            if (query.status) strs.push('status=' + query.status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setCustomerGroups(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取客户列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });
export const CustomerReducer = handleActions(
    {
        [setCustomers]: (state, { payload: data }) => {
            return {
                ...state,
                customers: data
            }
        },
        [setCustomerGroups]: (state, { payload: data }) => {
            return {
                ...state,
                customerGroups: data
            }
        },
    },
    initialState
)

export default CustomerReducer
