import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingPage from '../components/LoadingPage';

class LoadingPageContainer extends Component {
  static propTypes = {
    open: PropTypes.bool,
  }

  render() {
    return (
      <LoadingPage
        open={this.props.open}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.loading.globalLoading,
  };
};


export default connect(
  mapStateToProps
)(LoadingPageContainer);
