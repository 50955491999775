import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCompanyTopology, getAllDepartments } from "../../../redux/modules/company";
import { getTeamActivities, getDutyRecords, setTeamActivityCreateOpen, executeProcessTeamActivity, updateTeamActivity, getTeamActivityPlan } from '../../../redux/modules/safetyActivity';
import { getCurrentProcessStatus, getProcessByRoleId } from '../../../redux/modules/process'
import { getLeaders } from '../../../redux/modules/user'
import { getTimeStampByString } from "../../../utils/datetime";
import { getDepartmentsByRole } from "../../../utils/userUtils";
import * as _ from 'lodash'
import { getMoment } from "../../../utils/datetime";
import TeamActivityCalendar from '../../../components/LeadershipManagement/TeamActivity/TeamActivityCalendar';
import TeamActivityDetail from '../../../components/LeadershipManagement/TeamActivity/TeamActivityCalendar/TeamActivityDetail'
import CreateTeamActivityPlan from './CreateTeamActivityPlan'
import ApprovePannel from '../ApprovePannel'
import { openApprovePannel } from "../../../redux/modules/approvePannel"
import { getRole } from "../../../utils/userUtils"
import TeamActivityTableComponent from '../../../components/LeadershipManagement/TeamActivity/TeamActivityCalendar/TeamActivityTable';

class TeamActivityCalendarContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            activityList: [],
            openActivity: {},
            activityTableOpen: false,
            loading: true,
            open: false,
            selectedRow: {},
            taskOpen: false,
            selectedCompanyId: this.props.currentUserInfo.companyId,
            selectedDate: getMoment()(),
            tempLeaders: []
        }
        this.detailTitle = ''
        this.selectDate = null
        this.changeLeader = false
        this.changeActivityTime = false
        this.tempLeaders = [];
        this.tempActivityTime = null
    }

    componentDidMount() {

        const { currentUserInfo,
            onhandleGetCompanyTopology,
            onhandleGetCurrentProcessStatus,
            onhandleGetLeaders,
            onhandleGetNewPlans,
            onhandleGetTeamActivityPlan } = this.props


        onhandleGetCompanyTopology((companyTopology) => {

            const com = _.find(companyTopology, (c) => {
                return (c.companyId === currentUserInfo.companyId && c.pid)
                    || c.pid === currentUserInfo.companyId
            })
            this.setState({ selectedCompanyId: com.companyId })
        })
        this.handleGetTeamActivities()
        onhandleGetCurrentProcessStatus()
        onhandleGetLeaders(currentUserInfo.companyId)
        onhandleGetNewPlans(currentUserInfo.roleId)
        onhandleGetTeamActivityPlan(this.state.selectedDate)

    }

    handleGetTeamActivities(date) {
        // console.log(date)
        const { onhandleGetTeamActivities } = this.props
        let mDate = getMoment()()
        if (date)
            mDate = getMoment()(date)

        // console.log(mDate)
        const query = {
            fromDate: mDate.startOf('month').format('YYYY-MM-DD'),
            toDate: mDate.add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            // fromDate: mDate.startOf('month').valueOf(),
            // toDate: mDate.add(1, 'months').startOf('month').valueOf(),
        }
        onhandleGetTeamActivities(query)
    }


    getMyLeaders() {
        const { currentUserInfo, leaders } = this.props
        if (!leaders || leaders.length === 0)
            return []
        return _.filter(leaders, l => { return l.companyId === currentUserInfo.companyId })
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected' || action.review) {
            action.companyId = this.state.selectedCompanyId
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleExecuteProcess(action) {

        const { onhandleProcess,
            onhandleGetCurrentProcessStatus,
            userProfile,
            currentUserInfo,
            activityList,
            teamActivityPlan } = this.props

        const dpts = getDepartmentsByRole(currentUserInfo.roleId, userProfile)

        let actionParams = []
        if (action.statusCode === 'Submitted' || action.statusCode === 'Reported') {
            let param = {
                handle: 'updateTeamActivity',
                status: action.statusCode,
                activities: _.filter(activityList, a => { return _.intersectionBy(a.dpts, dpts, 'dptId').length > 0 })
            }
            actionParams.push(param)
        } if (action.statusCode === 'Published') {
            let param = {
                handle: 'updateTeamActiviyPlan',
                status: action.statusCode,
                plan: teamActivityPlan
            }
            actionParams.push(param)
        }

        let processParams = {
            action: action,
            actionParams: actionParams
        }
        onhandleProcess(processParams, () => {
            onhandleGetCurrentProcessStatus()
        })
    }


    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    /**
   * 日期改变
   */
    handleChangeDate(date) {
        this.setState({ selectedDate: getMoment()(date) })
        this.handleGetTeamActivities(date)
        this.props.onhandleGetTeamActivityPlan(getMoment()(date))
        this.props.onhandleGetCurrentProcessStatus()

    }

    /**
    * 公司下拉菜单
    */
    _getMyCompanyChildCompanies() {
        const { companyTopology, currentUserInfo } = this.props
        const companies = _.filter(companyTopology, c => {
            return (c.companyId === currentUserInfo.companyId && c.pid)
                || c.pid === currentUserInfo.companyId
        })
        return companies
    }

    handleChangeCompany(event) {
        this.setState({
            selectedCompanyId: event.target.value
        });
    }

    /**
     * 显示和修改带班值班
     */

    handleOpenActivity(event) {
        const { activityList, onhandleGetDutyRecords } = this.props
        if (event.actId !== this.state.openActivity.actId) {
            onhandleGetDutyRecords(event.actId, (data) => {
                this.setState({
                    openActivity: _.find(activityList, (a) => { return a.actId === event.actId }),
                    dutyRecords: data
                })
            })
        }

    }

    handleUpdate() {

        let activity = this.state.openActivity
        // console.log(activity)
        const { onhandleUpdateTeamActivity, leaders } = this.props

        let userIds = _.map(activity.users, 'userId')
        if (this.changeLeader) {
            userIds = _.difference(userIds, _.map(leaders, 'userId'))
            userIds = _.concat(userIds, _.map(this.tempLeaders, 'userId'))
        }
        let newActivity = {
            actId: activity.actId,
            users: userIds,
            dpts: _.map(activity.dpts, 'dptId'),
            lecturer: activity.lecturer,
            opentext: activity.opentext,
            location: activity.location,
        }
        if (this.changeActivityTime) {
            newActivity.activityTime = getTimeStampByString(this.tempActivityTime)
        }

        // console.log(activity)

        onhandleUpdateTeamActivity(newActivity, () => {
            this.handleGetTeamActivities(this.state.selectedDate)
            this.setState({ openActivity: {}, dutyRecords: {} })
        })

    }

    handleChangeLeader(event, users) {
        this.changeLeader = true
        this.tempLeaders = users
        // console.log('users', users)
    }

    handleChangeActivityTime(date) {
        this.changeActivityTime = true
        this.tempActivityTime = getMoment()(date).valueOf()
        let newAct = { ...this.state.openActivity }
        newAct.activityTime = date
        this.setState({
            openActivity: newAct
        })
        // console.log('tempActivityTime', this.tempActivityTime)
    }

    handleChangeActivityAttr(event) {
        let newAct = { ...this.state.openActivity }
        const attr = event.target.name
        newAct[attr] = event.target.value
        this.setState({
            openActivity: newAct
        })
    }


    handleCloseActivity() {
        this.tempLeaders = []
        this.changeLeader = false
        this.changeActivityTime = false
        this.activityTime = null
        this.setState({ openActivity: {}, dutyRecords: [] })
    }

    /**
     * 创建计划对话框
     */
    handleOpenCreate() {
        const { onhandleOpenCreate } = this.props
        onhandleOpenCreate()
    }

    handleOpenActivityTable(events, date) {
        if (events.length > 0) {
            this.setState({
                activityTableOpen: true,
                selectedDate: getMoment()(date)
            })
        }

    }


    handleCloseActivityTable() {
        this.setState({ activityTableOpen: false })
    }

    render() {

        const { currentProcessStatus, startProcesses, teamActivityPlan } = this.props

        let createPermission = false;
        if (_.find(startProcesses, plan => { return plan.processCode === 'MonthlyTeamActivity' })) {
            createPermission = true
        }

        let editDisabled = true
        let hasProcess = false
        if ((this.state.selectedDate.year() > getMoment()().year() ||
            (this.state.selectedDate.year() === getMoment()().year()
                && this.state.selectedDate.month() > getMoment()().month()))
            && currentProcessStatus && currentProcessStatus.statusCode
            && teamActivityPlan && teamActivityPlan.status && teamActivityPlan.status !== 'Published'
        ) {
            hasProcess = true
            if ((currentProcessStatus.statusCode === 'Drafting' //起草
                || currentProcessStatus.statusCode === 'Rejected' //驳回
                || currentProcessStatus.statusCode === 'Submitted') //提交之后安全员还可编辑领导安排
                && currentProcessStatus.actions.length > 0) {
                editDisabled = false
            }
        }

        const selectedMoment = getMoment()(this.state.selectedDate).startOf('day')
        let currentActivityList = _.filter(this.props.activityList, a => {
            return this.state.selectedCompanyId === a.companyId && selectedMoment.diff(getMoment()(a.activityTime).startOf('day'), 'days') === 0
        })
        currentActivityList = _.sortBy(currentActivityList, ['activityTime'])

        return (
            <div>
                <TeamActivityCalendar
                    createPermission={createPermission}
                    hasProcess={hasProcess}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    activityList={this.props.activityList}
                    leaders={this.props.leaders}
                    myLeaders={this.getMyLeaders()}
                    companies={this._getMyCompanyChildCompanies()}
                    selectedCompanyId={this.state.selectedCompanyId}
                    companyTopology={this.props.companyTopology}
                    currentProcessStatus={this.props.currentProcessStatus}
                    allDepartments={this.props.allDepartments}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                    onhandleOpenCreate={this.handleOpenCreate.bind(this)}
                    onhandleChangeCompany={this.handleChangeCompany.bind(this)}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onhandleChangeLeader={this.handleChangeLeader.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleOpenActivityTable={this.handleOpenActivityTable.bind(this)}
                />
                <TeamActivityTableComponent
                    constant={this.props.constant}
                    currentActivityList={currentActivityList}
                    leaders={this.props.leaders}
                    selectedMoment={selectedMoment}
                    open={this.state.activityTableOpen}
                    onhandleClose={this.handleCloseActivityTable.bind(this)}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                />
                <TeamActivityDetail
                    activity={this.state.openActivity}
                    leaders={this.props.leaders}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    companyTopology={this.props.companyTopology}
                    editDisabled={editDisabled}
                    dutyRecords={this.props.dutyRecords}
                    onhandleUpdateActivity={this.handleUpdate.bind(this)}
                    onhandleChangeLeader={this.handleChangeLeader.bind(this)}
                    onhandleChangeActivityTime={this.handleChangeActivityTime.bind(this)}
                    onhandleChangeActivityAttr={this.handleChangeActivityAttr.bind(this)}
                    onhandleClosePannel={this.handleCloseActivity.bind(this)}
                />
                {createPermission && <CreateTeamActivityPlan
                />}
                <ApprovePannel />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        teamActivityPlan: state.safetyActivity.teamActivityPlan,
        constant: state.constant,
        leaders: state.user.leaders,
        activityList: state.safetyActivity.teamActivities,
        dutyRecords: state.safetyActivity.dutyRecords,
        companyTopology: state.company.companyTopology,
        allDepartments: state.company.allDepartments,
        startProcesses: state.process.startProcesses,
        currentProcessStatus: state.process.currentProcessStatus && state.process.currentProcessStatus.MonthlyTeamActivity ? state.process.currentProcessStatus.MonthlyTeamActivity : {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCompanyTopology: (callback) => {
            dispatch(getCompanyTopology(callback))
        },
        onhandleGetAllDepartments: (executable, callback) => {
            dispatch(getAllDepartments(executable, callback))
        },
        onhandleGetTeamActivities: (query, callback) => {
            query.type = 2 //班组安全活动
            dispatch(getTeamActivities(query, callback))
        },
        onhandleGetNewPlans: (roleId) => {
            dispatch(getProcessByRoleId(roleId, 1))
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeProcessTeamActivity(actionParams, callback))
        },
        onhandleGetLeaders: (companyId, callback) => {
            dispatch(getLeaders(companyId, callback))
        },
        onhandleUpdateTeamActivity: (activity, callback) => {
            dispatch(updateTeamActivity(activity, callback))
        },
        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('MonthlyTeamActivity', null, callback))
        },
        onhandleGetDutyRecords: (activityId, callback) => {
            dispatch(getDutyRecords(activityId, callback))
        },
        onhandleOpenCreate: () => {
            dispatch(setTeamActivityCreateOpen(true))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },

        onhandleGetTeamActivityPlan: (moment) => {
            const year = moment.year()
            const month = moment.month() + 1
            dispatch(getTeamActivityPlan(year, month))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamActivityCalendarContainer);