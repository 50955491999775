import { createAction, handleActions } from 'redux-actions';
import { getDeviceCategoryTree } from './trees';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { createhttpMessage } from '@/redux/modules/message';
import { getMoment } from '@/utils/datetime';
import _ from 'lodash';

const defaultState = {
  deviceOperationReport: [],
  deviceServiceAbilityReport: [],
  deviceNewnessCoefficientReport: [],
  deviceUtilizationReport: [],
  planReport: [],
  tableValue: 0,
  search: {
    companyId: "",
    status: '',
    intervalDate: {
      view: 'week',
      startDate: '',
      endDate: ''
    }
  },
}

export const resetSearch = createAction('@@xcloud/deviceReport/resetSearch',
  () => (dispatch, getState, httpClient) => {

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;

    let newSearch = {
      companyId: hasPcomp ? getState().auth.currentUserInfo.companyId : '',
      status: '',
      tableValue: 0,
      intervalDate: {
        view: 'week',
        startDate: getMoment()().startOf('month').startOf('week').format("YYYY-MM-DD"),
        endDate: getMoment()().endOf('week').format("YYYY-MM-DD")
      }
    }
    dispatch(setSearch(newSearch));
  });

export const initDeviceReport = createAction('@@xcloud/deviceReport/initDeviceReport',
  () => (dispatch, getState) => {

    dispatch(createloading());

    const dispatchArray = [];
    dispatchArray.push(
      dispatch(resetSearch())
    )
    Promise.all(dispatchArray)
      .then(() => {
        const search = getState().deviceReport.search;
        dispatch(getDeviceOperationReport(search));
        dispatch(getDeviceServiceAbilityReport(search));
        dispatch(getDeviceNewnessCoefficientReport(search));
        dispatch(getDeviceUtilizationReport(search));

        dispatch(getDeviceCategoryTree());
        // dispatch(getPlanReport(search));
      })
      .then(() => {
        dispatch(cleanloading());
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化设备报表失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

// export const getDeviceReport = createAction('@@xcloud/deviceReport/getDeviceReport',
//   (query) => (dispatch, getState, httpClient) => {
//     dispatch(createloading());
//     let url = '/api/devicelist/report';
//     let param = [];

//     if (query.companyId || query.startDate || query.endDate || query.status)
//       param.push("&companyIds=" + query.companyId);
//     if (query.intervalDate.startDate)
//       param.push("&startDate=" + query.intervalDate.startDate);
//     if (query.intervalDate.endDate)
//       param.push("&endDate=" + query.intervalDate.endDate);
//     if (query.status)
//       param.push("&status=" + query.status);
//     url = url + '?' + param.join('&')
//     console.log('device url',url)
//     console.log('tabValue',query.tableValue)
//     return httpClient.get(url, { headers: { need_loading: 'true' } })
//       .then((res) => {
//         if (res.status === 200) {
//           if (res.data) {
//             dispatch(setDeviceOperationReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
//           }
//         }
//         dispatch(cleanloading());
//         return res;
//       })
//       // eslint-disable-next-line no-unused-vars
//       .catch(err => {
//         dispatch(cleanloading());
//         let msg = '设备报表数据获取失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//   });

export const getDeviceOperationReport = createAction('@@xcloud/deviceReport/getDeviceOperationReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/device/operation/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')
    // console.log('device url', url)

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setDeviceOperationReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
            // 获取已有设备类别信息
            const catDict = getState().trees.deviceCategoryList;
            const existsCats = res.data.map(item => ({ code: item.deviceCat }));
            existsCats.forEach(cat => {
              cat.name = _.find(catDict, { code: cat.code }) ? _.find(catDict, { code: cat.code }).name : '';
              cat.pcode = _.find(catDict, { pcode: cat.code }) ? _.find(catDict, { pcode: cat.code }).pcode : '';
              cat.pname = _.find(catDict, { pcode: cat.code }) ? _.find(catDict, { pcode: cat.code }).name : '';
            });
            dispatch(setDeviceCatList(existsCats));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '设备运维报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });


export const getDeviceServiceAbilityReport = createAction('@@xcloud/deviceReport/getDeviceServiceAbilityReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/device/serviceability/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')
    // console.log('device url', url)

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setDeviceServiceAbilityReport(_.sortBy(res.data, ['deviceCat'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '设备完好性报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getDeviceNewnessCoefficientReport = createAction('@@xcloud/deviceReport/getDeviceNewnessCoefficientReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/device/newnesscoefficient/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')
    // console.log('device url', url)

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            /// 方便调试，刻意过滤净值为0的数据
            // res.data = _.filter(res.data, sum => sum.faNetSum > 0);
            dispatch(setDeviceNewnessCoefficientReport(_.sortBy(res.data, ['deviceCat'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '设备新度系数数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });


export const getDeviceUtilizationReport = createAction('@@xcloud/deviceReport/getDeviceUtilizationReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/device/utilization/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')
    // console.log('device url', url)

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setDeviceUtilizationReport(_.sortBy(res.data, ['deviceCat'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '设备利用率报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });


export const getPlanReport = createAction('@@xcloud/deviceReport/getPlanReport',
  (query) => (dispatch, getState, httpClient) => {
    // dispatch(createloading());
    let url = '/api/inspectionplans/report';
    let param = [];
    if (query.companyId || query.startDate || query.endDate || query.status)
      param.push("&companyIds=" + query.companyId);
    if (query.intervalDate.startDate)
      param.push("&startDate=" + query.intervalDate.startDate);
    if (query.intervalDate.endDate)
      param.push("&endDate=" + query.intervalDate.endDate);
    if (query.status)
      param.push("&status=" + query.status);
    url = url + '?' + param.join('&')

    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setPlanReport(_.sortBy(res.data, ['year', 'month', 'week', 'day'])));
          }
        }
        dispatch(cleanloading());
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '计划报表数据获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

// export const updSearchData = createAction('@@xcloud/deviceReport/updSearchData',
//   (value) => (dispatch, getState, httpClient) => {
//       console.log('')
//       Promise.all([
//         dispatch(setSearch(value))
//       ]).then(() => {
//         dispatch(getDeviceReport(value))
//       }).catch(err => {
//         dispatch(cleanloading());
//         let msg = '设备报表更新失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//     }
// );

export const updSearchData = createAction('@@xcloud/deviceReport/updSearchData',
  (value, tabValue) => (dispatch, getState, httpClient) => {
    console.log('updSearchData   ......')
    Promise.all([
      dispatch(setSearch(value))
    ]).then(() => {
      // if (tabValue === 4) {
      // dispatch(getPlanReport(value))
      // } else {
      dispatch(getDeviceOperationReport(value))
      dispatch(getDeviceServiceAbilityReport(value))
      dispatch(getDeviceNewnessCoefficientReport(value))
      dispatch(getDeviceUtilizationReport(value))

      // }
    }).catch(err => {
      // dispatch(cleanloading());
      // let msg = '设备报表更新失败';
      // dispatch(createhttpMessage(err.response ? err.response : '', msg));
      // return [];
    });
  }
);

// export const getDeviceListForCsv = createAction('@@xcloud/deviceReport/getDeviceListForCsv',
//   (query, callback) => (dispatch, getState, httpClient) => {
//     let url = '/api/devicelist';
//     if (query.offset !== undefined) {
//       url += "?offset=" + query.offset;
//     } else {
//       url += "?offset=0";
//     }
//     if (query.size !== undefined) {
//       url += "&size=" + query.size;
//     } else {
//       url += "&size=10";
//     }
//     if (query.sort !== undefined)
//       url += "&sort=" + query.sort;
//     if (query.company)
//       url += "&companyId=" + query.company;
//     if (query.dptId)
//       url += "&dptId=" + query.dptId;
//     if (query.severity)
//       url += "&severity=" + query.severity;
//     if (query.impactLevel)
//       url += "&impactLevel=" + query.impactLevel;
//     if (query.factor)
//       url += "&factor=" + query.factor;
//     if (query.createTime)
//       url += "&createTime=" + query.createTime;
//     if (query.createTimeEnd)
//       url += "&createTimeEnd=" + query.createTimeEnd;
//     if (query.creator)
//       url += "&creator=" + query.creator;
//     if (query.status)
//       url += "&status=" + query.status;
//     return httpClient.get(url, { headers: { need_loading: 'true' } })
//       .then((res) => {
//         if (res.status === 200) {
//           if (res.data) {
//             callback(res.data)
//           }
//         }
//         return res;
//       })
//       // eslint-disable-next-line no-unused-vars
//       .catch(err => {
//         let msg = '设备台账获取失败';
//         dispatch(createhttpMessage(err.response ? err.response : '', msg));
//         return [];
//       });
//   });


// export const backToDeviceList = createAction('@@xcloud/deviceReport/backToDeviceList',
//   (status) => (dispatch, getState, httpClient) => {
//     let param = 'beforeevaluated';
//     switch (status) {
//       case 'active':
//         param = 'beforeevaluated';
//         break;
//       case 'evaluated':
//         param = 'afterevaluated';
//         break;
//       case 'treating':
//       case 'done':
//         param = 'treating';
//         break;
//       default:
//       // do nothing
//     }
//     dispatch(push('/inspection/devicelist/' + param, { isBack: true }));
//   });

export const setDeviceOperationReport = createAction('@@xcloud/deviceReport/setDeviceOperationReport');
export const setDeviceServiceAbilityReport = createAction('@@xcloud/deviceReport/setDeviceServiceAbilityReport');
export const setDeviceNewnessCoefficientReport = createAction('@@xcloud/deviceReport/setDeviceNewnessCoefficientReport');
export const setDeviceUtilizationReport = createAction('@@xcloud/deviceReport/setDeviceUtilizationReport');

export const setPlanReport = createAction('@@xcloud/deviceReport/setPlanReport');

export const setSearch = createAction('@@xcloud/deviceReport/setSearch');

export const setDeviceCatList = createAction('@@xcloud/deviceReport/setDeviceCatList');

export const setUserList = createAction('@@xcloud/deviceReport/setUserList');

export const deviceReducer = handleActions(
  {
    [setDeviceOperationReport]: (state, { payload: value }) => {
      return {
        ...state,
        deviceOperationReport: value
      };
    },
    [setDeviceServiceAbilityReport]: (state, { payload: value }) => {
      return {
        ...state,
        deviceServiceAbilityReport: value
      };
    },
    [setDeviceNewnessCoefficientReport]: (state, { payload: value }) => {
      return {
        ...state,
        deviceNewnessCoefficientReport: value
      };
    },
    [setDeviceUtilizationReport]: (state, { payload: value }) => {
      return {
        ...state,
        deviceUtilizationReport: value
      };
    },
    [setPlanReport]: (state, { payload: value }) => {
      return {
        ...state,
        planReport: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setDeviceCatList]: (state, { payload: value }) => {
      return {
        ...state,
        deviceCategoryList: value
      };
    },
  },
  defaultState
);

export default deviceReducer;