import React from 'react';
import {
    Grid,
    Tab,
    Tabs,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import OfficeSharingContainer from '@/containers/AmoebaManagement/SharingCost/OfficeSharingContainer';
import CompanySharingContainer from '@/containers/AmoebaManagement/SharingCost/CompanySharingContainer';
// import StuffSharingContainer from '@/containers/AmoebaManagement/SharingCost/StuffSharingContainer';
import * as _ from 'lodash';

const SharingCostComponent = props => {
    const { classes,
        tabValue,
        // roleFlag,
        onhandleChangeTab,
        currentUserInfo,
        companies,
        // onhandleOpenHistory,
    } = props;

    const checkPermissions = () => {
        let pcom = _.find(companies, (c) => { return c.pid === null })
        if (currentUserInfo.companyId === pcom.companyId) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"公司管理费"} value={0} className={classes.tab} />
                {!checkPermissions() && <Tab label={"车间管理费"} value={1} className={classes.tab} />}
                {/* <Tab label={"员工"} value={2} className={classes.tab} /> */}
            </Tabs>
            <Grid>
                {tabValue === 0 && <OfficeSharingContainer />}
                {tabValue === 1 && <CompanySharingContainer />}
                {/* {tabValue === 2 && <StuffSharingContainer />} */}

            </Grid>
        </div>
    )
}

SharingCostComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

SharingCostComponent.defaultProps = {
};

export default withStyles(SharingCostComponent);