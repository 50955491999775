import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Typography,
    InputAdornment,
    TextField,
    Grid,
} from "@material-ui/core";
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '../../../../../assets/rc-tree.css';
import '../../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';

const DeviceAsset = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        // onhandleBack,
        onhandleCheck,
        deviceAsset,
        deviceAssetError,
        deviceAssetChange,
        tabValue,
        index,
        onhandleSelectChange,
        isView,
        departmentList,
        isDeviceEdit,
        userProfile,
        erpsrc,
        erpunit,
        erpcat,
        erpstatus,
        erpdisposal,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }

    const getErpSrcData = () => {
        let result = []

        _.map(erpsrc, t => {
            result.push({ value: t.code, label: t.name })
        })

        return result
    }

    const getErpUnitData = () => {
        let result = []

        _.map(erpunit, t => {
            result.push({ value: t.code, label: t.name })
        })

        return result
    }

    const getErpCatData = () => {
        let result = []

        _.map(erpcat, t => {
            result.push({ value: t.code, label: t.name })
        })

        return result
    }

    const getErpStatusData = () => {
        let result = []

        _.map(erpstatus, t => {
            result.push({ value: t.code, label: t.name })
        })

        return result
    }

    const getErpDisposalData = () => {
        let result = []

        _.map(erpdisposal, t => {
            result.push({ value: t.code, label: t.name })
        })

        return result
    }

    return (
        <div>
            <div className={classes.card} hidden={tabValue !== index}>
                <Grid container item spacing={1} className={classes.gridContainer} sm={12} lg={12}>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'管理信息'}
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>固定资产编号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceAssetError.faCode !== ''}
                                helperText={deviceAssetError.faCode || ''}
                                className={classes.textField}
                                value={deviceAsset.faCode || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'faCode')}
                                onBlur={event => onhandleCheck(event, 'faCode')}
                            />
                            {/* {deviceAsset.assetId && deviceAsset.faCode !== '' ? (
                                    <Typography className={classes.label}>
                                        {deviceAsset.faCode}
                                    </Typography>
                                ) : (<TextField
                                    className={classes.textField}
                                    value={deviceAsset.faCode || ''}
                                    variant="outlined"
                                    disabled={isView || isDeviceEdit}
                                    onChange={event => onhandleChange(event, 'faCode')}
                                    onBlur={event => onhandleCheck(event, 'faCode')}
                                />) */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>资产来源</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            {/* <TextField
                                    error={deviceAssetError.source !== ''}
                                    helperText={deviceAssetError.source || ''}
                                    className={classes.textField}
                                    value={deviceAsset.source || ''}
                                    variant="outlined"
                                    disabled={isView || isDeviceEdit}
                                    onChange={event => onhandleChange(event, 'source')}
                                    onBlur={event => onhandleCheck(event, 'source')}
                                /> */}

                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.source}
                                labelInValue
                                treeData={getErpSrcData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'source')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>


                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>所属部门</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.dptId}
                                labelInValue
                                treeData={departmentList}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'dptId')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>资产类别</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.assetCat}
                                labelInValue
                                treeData={getErpCatData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'assetCat')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>计量单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.countUnit}
                                labelInValue
                                treeData={getErpUnitData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'countUnit')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>资产状态</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.assetStatus}
                                labelInValue
                                treeData={getErpStatusData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'assetStatus')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>处置方式</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TreeSelect
                                className={classes.textField}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                                allowClear
                                treeLine
                                value={deviceAsset.disposalMode}
                                labelInValue
                                treeData={getErpDisposalData()}
                                treeNodeFilterProp="label"
                                filterTreeNode={false}
                                disabled={isView || isDeviceEdit}
                                onChange={(value) => onhandleSelectChange(value, 'disposalMode')}
                            />
                            {/* {deviceAssetError.dptId !== '' ? <FormHelperText
                                    error={true}
                                >
                                    {deviceAssetError.dptId}
                                </FormHelperText> : null} */}
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>发票号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                value={deviceAsset.invoiceNo || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'invoiceNo')}
                                onBlur={event => onhandleCheck(event, 'invoiceNo')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'资金信息'}
                        </Typography>
                    </Grid>


                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>固定资产原值</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceAssetError.faVal !== ''}
                                helperText={deviceAssetError.faVal || ''}
                                className={classes.textField}
                                value={deviceAsset.faVal || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'faVal')}
                                onBlur={event => onhandleCheck(event, 'faVal')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            元
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>固定资产净值</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceAssetError.faNet !== ''}
                                helperText={deviceAssetError.faNet || ''}
                                className={classes.textField}
                                value={deviceAsset.faNet || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'faNet')}
                                onBlur={event => onhandleCheck(event, 'faNet')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            元
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>累计维修费</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={deviceAssetError.maintCost !== ''}
                                helperText={deviceAssetError.maintCost || ''}
                                className={classes.textField}
                                value={deviceAsset.maintCost || ''}
                                variant="outlined"
                                size='small'
                                disabled={isView || isDeviceEdit}
                                onChange={event => onhandleChange(event, 'maintCost')}
                                onBlur={event => onhandleCheck(event, 'maintCost')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            元
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div >

            <div className={classes.card} hidden={tabValue !== index}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        hidden={!checkPermission() || isView || isDeviceEdit}
                        disabled={!deviceAssetChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>

            </div>
            <Prompt message="数据未保存,确定要离开？" when={deviceAssetChange} />
        </div >
    );
};

DeviceAsset.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    deviceAsset: PropTypes.object.isRequired,
    deviceAssetError: PropTypes.object.isRequired,
    deviceAssetChange: PropTypes.bool.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    isView: PropTypes.bool.isRequired,
    departmentList: PropTypes.array.isRequired,
    isDeviceEdit: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    erpsrc: PropTypes.array.isRequired,
    erpunit: PropTypes.array.isRequired,
    erpcat: PropTypes.array.isRequired,
    erpstatus: PropTypes.array.isRequired,
    erpdisposal: PropTypes.array.isRequired,
};

export default withStyles(DeviceAsset);
