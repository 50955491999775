import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    MenuItem,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { getCodeName, TYPE_ATTR } from '@/utils/manufactureUtils'



const AcitivityActionComponent = props => {
    const { classes,
        openFlag,
        openType,
        attrData,
        attrList,
        manufactureDicts,
        onhandleClose,
        onhandleSave,
        onhandleChange,
    } = props;

    const textFieldReadOnly = {
        readOnly: true,
    }

    const textFieldDefault = {
        style: {
            width: '185px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={openFlag} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>
                        {'活动属性' + (openType === 1 ? '添加' : '修改')}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid container direction="column" >
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    select
                                    label='名称'
                                    name='attrId'
                                    variant="outlined"
                                    value={attrData.attrId || ''}
                                    InputProps={{ ...textFieldDefault }}
                                    onChange={onhandleChange}>
                                    {attrList.map((i) => (
                                        <MenuItem key={i.attrId} value={i.attrId}>
                                            {i.attrName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='类型'
                                    name='attrType'
                                    variant="outlined"
                                    InputProps={{ ...textFieldDefault, ...textFieldReadOnly }}
                                    value={getCodeName(manufactureDicts, TYPE_ATTR, attrData.attrType)}
                                />
                            </Grid>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    label='说明'
                                    name='opentext'
                                    variant="outlined"
                                    InputProps={{ ...textFieldDefault, ...textFieldReadOnly }}
                                    value={attrData.opentext || ''}
                                />
                            </Grid>
                            <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AcitivityActionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    attrData: PropTypes.object,
    attrList: PropTypes.array,
    manufactureDicts: PropTypes.array,
    openFlag: PropTypes.bool,
    openType: PropTypes.number,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleChange: PropTypes.func,
};

AcitivityActionComponent.defaultProps = {

};

export default withStyles(AcitivityActionComponent);