import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message'
import { getDeviceCategoryTree } from './trees';
import { getAccessoryCategory } from './accessory';
import { getDeviceExtCat } from './devices';
import * as _ from 'lodash';

const defaultState = {
  isloading: false,
  rules: {
    total: 0,
    list: []
  },
  currentRule: {},
  ruleError: {},
  ruleChange: false,
  readonly: false,
  devCatSelection: undefined,
  devExtCatSelection: undefined,
  ruleTargets: {
    total: 0,
    list: []
  },
  ruleTargetExcepts: {
    total: 0,
    list: []
  },
  optCategories: [],
  objectPageSize: 10,
  objectExceptPageSize: 10,
  showList: [],
  ruleTargetChanged: false,
  search: {
    page: 1,
    size: 10,
    optCategory: 'ALL',
  },
  ruleApprovals: [], //运维规则审批节点
};

export const setSearch = createAction('@@xcloud/operationRule/setSearch');

export const resetSearch = createAction('@@xcloud/operationRule/resetSearch',
  () => (dispatch, getState, httpClient) => {
    let search = getState().operationRule.search;

    let newSearch = {
      ...search,
      optCategory: 'ALL'
    }

    dispatch(setSearch(newSearch));
    dispatch(setDeviceCatSelection(undefined));
    dispatch(setDeviceExtCatSelection(undefined));
  }
);

export const backToRuleList = createAction('@@xcloud/operationRule/backToRuleList',
  () => (dispatch, getState) => {
    dispatch(push('/operationRule'));
  });

export const backToRuleEdit = createAction('@@xcloud/operationRule/backToRuleEdit',
  () => (dispatch, getState) => {
    dispatch(push("/operationRule/info"));
  });

export const getOperationCategory = createAction('@@xcloud/operationRule/getOperationCategory',
  () => (dispatch, getState, httpClient) => {

    let url = '/api/unprotected/utils/device/dictionaries?type=OPTCAT';
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setOptCategories(res.data));
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '设备运维规则类别获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const initRuleHeader = createAction('@@xcloud/operationRule/initRuleHeader',
  () => (dispatch, getState, httpClient) => {

    Promise.all([
      dispatch(resetSearch()),
      dispatch(getOperationCategory()),
      dispatch(getDeviceCategoryTree()),
      dispatch(getDeviceExtCat()),
    ]).then(dispatch(getRuleList({
      offset: 0,
      size: 10,
      sort: "-update_time"
    })))
      .catch(err => {
        dispatch(stopLoading());
        let msg = '初始化设备运维规则页面失败';
        dispatch(createMessage('error', msg));
      });
  });

export const getRuleList = createAction('@@xcloud/operationRule/getRuleList',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(setRuleChange(false));
    let url = `/api/operationrules`;
    if (query) {
      if (query.offset) {
        url += "?offset=" + query.offset;
      } else {
        url += "?offset=0";
      }
      if (query.size !== undefined)
        url += "&size=" + query.size;
      if (query.sort)
        url += "&sort=" + query.sort;
      if (query.status)
        url += "&status=" + query.status;
      if (query.optCategory && query.optCategory !== 'ALL') {
        url += "&cat=" + query.optCategory;
      }
    }
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setRules(res.data));
          }
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '设备运维规则列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getRuleById = createAction('@@xcloud/operationRule/getRuleList',
  (ruleId) => (dispatch, getState, httpClient) => {
    dispatch(setRuleChange(false));
    let url = `/api/operationrules/${ruleId}`;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setCurrentRule(res.data))
          }
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '设备运维规则列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });
export const createRule = createAction('@@xcloud/operationRule/createRule',
  () => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/operationrules';
    let rule = getState().operationRule.currentRule;
    return httpClient.post(url, rule)
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentRule(res.data));
          dispatch(stopLoading());
          dispatch(createMessage('success', '创建设备运维规则成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '创建设备运维计划失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateRule = createAction('@@xcloud/operationRule/updateRule',
  () => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let rule = getState().operationRule.currentRule;
    let url = '/api/operationrules/' + rule.ruleId;
    let dto = {
      "ruleId": rule.ruleId,
      "ruleName": rule.ruleName,
      "ruleCode": rule.ruleCode,
      "cat": rule.cat,
      "status": rule.status,
      "opentext": rule.opentext,
      "cycle": rule.cycle,
      "unit": rule.unit,
      "formula": rule.formula,
      "cyclical": rule.cyclical,
      "target": rule.target
    };
    return httpClient.patch(url, dto)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentRule(res.data));
          dispatch(stopLoading());
          dispatch(createMessage('success', '更新设备运维规则成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '更新设备运维规则失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateOperationsRuleTargets = createAction('@@xcloud/operationRule/updateOperationsRuleTargets',
  (type) => (dispatch, getState, httpClient) => {
    // console.log(vals);
    let rule = getState().operationRule.currentRule;
    let category = _.clone(getState().operationRule.showList);
    let selectedCategory = _.filter(category, { action: true });
    let dto = []

    selectedCategory.forEach(category => {

      if (!category.pcode) {
        dto.push({
          targetCat: category.code,
          target: type,
          ruleId: rule.ruleId
        })
      } else {
        if (!_.find(selectedCategory, { code: category.pcode })) {
          dto.push({
            targetCat: category.code,
            target: type,
            ruleId: rule.ruleId
          })
        }
      }
    })

    let url = '/api/operationrules/' + rule.ruleId + '/targets/' + rule.target
    httpClient.post(url, dto)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRuleTargetChanged(false));
          dispatch(createMessage('success', '添加运维规则对象成功'));
        }
        return res;
      })
      .catch(err => {
        let msg = '添加运维规则对象失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);

export const updateRuleTargets = createAction('@@xcloud/operationRule/deleteRule',
  (vals, idAdd) => (dispatch, getState, httpClient) => {
    // console.log(vals);
    let rule = getState().operationRule.currentRule;
    let dto = []
    if (rule.target === "DEV") {
      vals.forEach((v) => {
        dto.push({
          targetId: v.deviceId,
          target: "DEV",
          ruleId: rule.ruleId
        })
      })
    }
    let url = '/api/operationrules/' + rule.ruleId + '/targets/' + rule.target;
    if (idAdd) {
      httpClient.post(url, dto)
        .then((res) => {
          if (res.status === 200) {
            Promise.all([
              dispatch(getRuleTargetExcepts({ offset: 0 })),
              dispatch(getRuleTargets({ offset: 0 }))
            ]).then((rtndata) => {
              dispatch(createMessage('success', '添加设备运维规则对象成功'));
            }).catch(err => {
              let msg = '获取设备运维规则对象失败';
              dispatch(createhttpMessage(err.response ? err.response : '', msg));
              return [];
            })
          }
          return res;
        })
        .catch(err => {
          let msg = '添加设备运维规则对象失败';
          dispatch(createhttpMessage(err.response ? err.response : '', msg));
          return [];
        })
    } else {
      httpClient.patch(url, dto)
        .then((res) => {
          if (res.status === 200) {
            Promise.all([
              dispatch(getRuleTargetExcepts({ offset: 0 })),
              dispatch(getRuleTargets({ offset: 0 }))
            ]).then((rtndata) => {
              dispatch(createMessage('success', '删除设备运维规则对象成功'));
            }).catch(err => {
              let msg = '获取设备运维规则对象失败';
              dispatch(createhttpMessage(err.response ? err.response : '', msg));
              return [];
            })
          }
          return res;
        })
        .catch(err => {
          let msg = '删除设备运维规则对象失败';
          dispatch(createhttpMessage(err.response ? err.response : '', msg));
          return [];
        })
    }
  }
);

export const deleteRule = createAction('@@xcloud/operationRule/deleteRule',
  (id, name) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let dto = {
      ruleId: id,
      status: 'inactive'
    }
    let url = '/api/operationrules/' + id;
    httpClient.patch(url, dto)
      .then((res) => {
        if (res.status === 200) {
          dispatch(stopLoading());
          dispatch(createMessage('success', '设备运维规则[' + name + ']删除成功'));
          dispatch(initRuleHeader());
        }
        return res;
      })
      .catch(err => {
        dispatch(stopLoading());
        let msg = '设备运维规则[' + name + ']删除失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);

export const initCreate = createAction(
  '@@xcloud/operationRule/initCreate',
  () => (dispatch) => {
    dispatch(setReadonly(false));
    dispatch(initRule(true));
    dispatch(push("/operationRule/info/0"));
  }
);

export const initView = createAction(
  '@@xcloud/operationRule/initView',
  (id) => (dispatch, getState, httpClient) => {
    let rules = getState().operationRule.rules;
    let crule = _.find(rules.list, ['ruleId', id]);
    dispatch(setReadonly(true));
    dispatch(setCurrentRule(crule));
    dispatch(initRule(false));
    dispatch(getRuleApprovals(crule.ruleId))
    dispatch(initSetup(crule.target));
    dispatch(push("/operationRule/info/" + id));
  }
);

export const initUpdate = createAction(
  '@@xcloud/operationRule/initUpdate',
  (id) => (dispatch, getState, httpClient) => {
    let rules = getState().operationRule.rules;
    let crule = _.find(rules.list, ['ruleId', id]);
    dispatch(setReadonly(false));
    dispatch(setCurrentRule(crule));
    dispatch(initRule(false));
    dispatch(getRuleApprovals(crule.ruleId))
    dispatch(initSetup(crule.target));
    dispatch(push("/operationRule/info/" + id));
  }
);

export const initSetup = createAction(
  '@@xcloud/operationRule/initSetup',
  (target) => (dispatch, getState, httpClient) => {
    if (target === 'DEVTYPE') {
      dispatch(initRule(false));
      Promise.all([
        dispatch(getRuleTargets({ offset: 0, size: 0 })),
        dispatch(getDeviceCategoryTree())
      ]).then((res) =>
        Promise.all([
          dispatch(initCategory(target)),
        ]).then((res) => {
          dispatch(setRuleTargetChanged(false));
          // dispatch(push(`/operationRule/setup/${target}`));
        }))
    } else if (target === 'EXTTYPE') {
      dispatch(initRule(false));
      Promise.all([
        dispatch(getRuleTargets({ offset: 0, size: 0 })),
        dispatch(getDeviceExtCat())
      ]).then((res) =>
        Promise.all([
          dispatch(initCategory(target)),
        ]).then((res) => {
          dispatch(setRuleTargetChanged(false));
          // dispatch(push(`/operationRule/setup/${target}`));
        }))
    } else if (target === 'DEV') {
      dispatch(initRule(false));
      dispatch(setDeviceCatSelection(undefined))
      dispatch(setObjectPageSize(10))
      dispatch(setObjectExceptPageSize(10))
      Promise.all([
        dispatch(getRuleTargetExcepts({ offset: 0, size: 10 })),
        dispatch(getRuleTargets({ offset: 0, size: 10 }))
      ]).then((res) => {
        // dispatch(push("/operationRule/setup"));
      })
    } else {
      dispatch(initRule(false));
      Promise.all([
        dispatch(getRuleTargets({ offset: 0, size: 0 })),
        dispatch(getAccessoryCategory())
      ]).then((res) =>
        Promise.all([
          dispatch(initCategory(target)),
        ]).then((res) => {
          dispatch(setRuleTargetChanged(false));
          // dispatch(push(`/operationRule/setup/${target}`));
        }))
    }
  }
);

export const initCategory = createAction(
  '@@xcloud/operationRule/initCategory',
  (target) => (dispatch, getState) => {
    let targets = getState().operationRule.ruleTargets.list;
    let categories = [];

    switch (target) {
      case 'DEVTYPE':
        categories = _.clone(getState().trees.deviceCategoryList);
        break;
      case 'EXTTYPE':
        categories = _.clone(getState().devices.extcat);
        break;
      case 'ACCTYPE':
        categories = _.clone(getState().accessory.catList);
        break;
      default:
      // do nothing
    }

    categories.forEach(category => category.action = false);
    dispatch(setShowList(categories));
    if (targets && targets.length > 0) {
      targets.forEach(target => {
        Promise.all([
          dispatch(commonSelectCategory(target)),
        ]).then((res) => {
          dispatch(setShowList(res[0].payload));
        })
      })
    }
  }
);


export const selectCategory = createAction(
  '@@xcloud/operationRule/selectCategory',
  (row) => (dispatch, getState) => {
    Promise.all([
      dispatch(commonSelectCategory(row)),
    ]).then((res) => {
      console.log(res)
      dispatch(setShowList(res[0].payload));
      dispatch(setRuleTargetChanged(true));
    })
  }
);

export const commonSelectCategory = createAction(
  '@@xcloud/operationRule/commonSelectCategory',
  (row) => (dispatch, getState) => {

    function dploop(val, action) {
      let targetChildArray = _.filter(category, { pcode: val.code });
      val.action = action;
      if (targetChildArray.length > 0) {
        targetChildArray.forEach(child => {
          dploop(child, action);
        })
      }
    }

    function uploop(val) {
      let targetFatherRow = _.find(category, { code: val.pcode });
      if (targetFatherRow) {
        if (!val.action) {
          targetFatherRow.action = false;
          let targetFatherChildRow = _.filter(category, c => (c.pcode === val.pcode && (c.action === true || c.indeterminate === true)));
          if (targetFatherChildRow.length !== 0) {
            targetFatherRow.indeterminate = true;
          } else {
            targetFatherRow.indeterminate = false;
          }
        } else {
          let targetFatherChildRow = _.filter(category, { pcode: val.pcode, action: false });
          if (targetFatherChildRow.length === 0) {
            targetFatherRow.action = true;
          } else {
            targetFatherRow.indeterminate = true;
          }
        }
        uploop(targetFatherRow);
      }
    }

    let category = _.clone(getState().operationRule.showList);
    let targetRow = _.find(category, { code: row.code })
    dploop(targetRow, targetRow.action ? false : true);
    uploop(targetRow);

    return category;
  }
);


export const setShowList = createAction('@@xcloud/operationRule/setShowList');

export const setRuleTargetChanged = createAction('@@xcloud/operationRule/setRuleTargetChanged');

export const getRuleTargetExcepts = createAction(
  '@@xcloud/operationRule/getRuleTargetExcepts',
  (query) => (dispatch, getState, httpClient) => {
    let objectExceptPageSize = getState().operationRule.objectExceptPageSize
    let rules = getState().operationRule.currentRule;
    if (rules && rules.ruleId) {
      query.ruleId = rules.ruleId
    }
    if (rules && rules.target) {
      query.target = rules.target
    }
    let cat = getState().operationRule.devCatSelection;
    if (cat && cat.value) {
      query.cat = cat.value
      query.recursive = true
    }
    let extCat = getState().operationRule.devExtCatSelection;
    if (extCat && extCat.value) {
      query.extCat = extCat.value
      query.recursive = true
    }
    let url = '/api/operationrules/TargetExcepts';
    if (query && _.isInteger(query.offset)) {
      url += "?offset=" + query.offset
    } else {
      url += "?offset=0"
    }
    if (query && query.size) {
      url += "&size=" + query.size
    } else {
      url += "&size=" + (objectExceptPageSize ? objectExceptPageSize : 10)
    }
    if (query && query.sort) {
      url += "&sort=" + query.sort
    }
    if (query && query.cat) {
      url += "&cat=" + query.cat
    }
    if (query && query.extCat) {
      url += "&extCat=" + query.extCat
    }
    if (query && query.recursive) {
      url += "&recursive=" + query.recursive
    }
    if (query && query.companyId) {
      url += "&companyId=" + query.companyId
    }
    if (query && query.ruleId) {
      url += "&ruleId=" + query.ruleId
    }
    if (query && query.target) {
      url += "&target=" + query.target
    }
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRuleTargetExcepts(res.data));
        }
        return res;
      })
      .catch(err => {
        let msg = '设备运维规则对象页面初始化失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })

  }
);

export const getRuleTargets = createAction(
  '@@xcloud/operationRule/getRuleTargets',
  (query) => (dispatch, getState, httpClient) => {
    let objectPageSize = getState().operationRule.objectPageSize
    let crule = getState().operationRule.currentRule;
    let url = '/api/operationrules/' + crule.ruleId + "/targets?target=" + crule.target;
    if (query && _.isInteger(query.offset)) {
      url += "&offset=" + query.offset
    }
    if (query && _.isInteger(query.size)) {
      url += "&size=" + query.size
    } else {
      url += "&size=" + (objectPageSize ? objectPageSize : 10)
    }
    if (query && query.sort) {
      url += "&sort=" + query.sort
    }
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRuleTargets(res.data));
        }
        return res;
      })
      .catch(err => {
        let msg = '设备运维规则对象页面初始化失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);



export const initRule = createAction(
  '@@xcloud/operationRule/initRule',
  (isCreate) => (dispatch, getState) => {
    if (isCreate) {
      let newRule = {
        "ruleId": 0,
        "cat": "",
        "status": "new",
        "opentext": "",
        "cycle": 0,
        "unit": "",
        "formula": "",
        "cyclical": false,
        "target": 'DEV'
      };
      dispatch(setCurrentRule(newRule));
      dispatch(setRuleApprovals([]));
    }
    let errorInfo = {
      cat: '',
      cycle: ''
    };
    dispatch(setRuleError(errorInfo));
    dispatch(setRuleChange(false));
  }
);


export const getRuleApprovals = createAction(
  '@@xcloud/operationRule/getRuleApprovals',
  (ruleId) => (dispatch, getState, httpClient) => {
    let url = '/api/operationrules/' + ruleId + "/approvals";
    return httpClient.get(url, { headers: { need_loading: 'false' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setRuleApprovals(res.data));
        }
        return res;
      })
      .catch(err => {
        let msg = '获取设备运维规则审批流程失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      })
  }
);


/**
 * DTO:
 * actionType:
 * approvalDTO:
 * approvalDTOList:
 */
export const handleRuleApproval = createAction('@@xcloud/operationRule/handleRuleApproval',
  (ruleId, dto) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let url = '/api/operationrules/' + ruleId + '/approvals/actions/' + dto.actionType;
    return httpClient.patch(url, dto)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getRuleApprovals(ruleId))
          dispatch(getRuleById(ruleId))
          dispatch(stopLoading());
          dispatch(createMessage('success', '运维规则审批操作成功'));
        }
        return res;
      }).catch(err => {
        let msg = '操作设备运维规则流程失败';
        dispatch(stopLoading());
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const setOptCategories = createAction('@@xcloud/operationRule/setOptCategories');

export const setRules = createAction('@@xcloud/operationRule/setRules');

export const setCurrentRule = createAction('@@xcloud/operationRule/setCurrentRule');

export const setRuleTargets = createAction('@@xcloud/operationRule/setRuleTargets');

export const setRuleTargetExcepts = createAction('@@xcloud/operationRule/setRuleTargetExcepts');

export const setDeviceCatSelection = createAction('@@xcloud/operationRule/setDeviceCatSelection');

export const setDeviceExtCatSelection = createAction('@@xcloud/operationRule/setDeviceExtCatSelection');

export const setRuleError = createAction('@@xcloud/operationRule/setRuleError');

export const setReadonly = createAction('@@xcloud/operationRule/setReadonly');

export const setRuleChange = createAction('@@xcloud/operationRule/setRuleChange');

export const stopLoading = createAction('@@xcloud/operationRule/stopLoading');

export const startLoading = createAction('@@xcloud/operationRule/startLoading');

export const setObjectPageSize = createAction('@@xcloud/operationRule/setObjectPageSize');

export const setObjectExceptPageSize = createAction('@@xcloud/operationRule/setObjectExceptPageSize');

export const setRuleApprovals = createAction('@@xcloud/operationRule/setRuleApprovals');

export const ruleReducer = handleActions(
  {
    [setRules]: (state, { payload: value }) => {
      return {
        ...state,
        rules: value
      };
    },
    [setRuleTargets]: (state, { payload: value }) => {
      return {
        ...state,
        ruleTargets: value
      };
    },
    [setRuleTargetExcepts]: (state, { payload: value }) => {
      return {
        ...state,
        ruleTargetExcepts: value
      };
    },
    [setObjectPageSize]: (state, { payload: value }) => {
      return {
        ...state,
        objectPageSize: value
      };
    },
    [setObjectExceptPageSize]: (state, { payload: value }) => {
      return {
        ...state,
        objectExceptPageSize: value
      };
    },
    [setDeviceCatSelection]: (state, { payload: value }) => {
      return {
        ...state,
        devCatSelection: value
      };
    },
    [setDeviceExtCatSelection]: (state, { payload: value }) => {
      return {
        ...state,
        devExtCatSelection: value
      };
    },
    [setRuleError]: (state, { payload: value }) => {
      return {
        ...state,
        ruleError: value
      };
    },
    [setReadonly]: (state, { payload: value }) => {
      return {
        ...state,
        readonly: value
      };
    },
    [setRuleChange]: (state, { payload: value }) => {
      return {
        ...state,
        ruleChange: value
      };
    },
    [setCurrentRule]: (state, { payload: value }) => {
      return {
        ...state,
        currentRule: value
      };
    },
    [setOptCategories]: (state, { payload: value }) => {
      return {
        ...state,
        optCategories: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    },
    [setShowList]: (state, { payload: value }) => {
      return {
        ...state,
        showList: value
      };
    },
    [setRuleTargetChanged]: (state, { payload: value }) => {
      return {
        ...state,
        ruleTargetChanged: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setRuleApprovals]: (state, { payload: value }) => {
      return {
        ...state,
        ruleApprovals: value
      };
    },
  },
  defaultState
);

export default ruleReducer;