import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    MenuItem
} from '@material-ui/core';
import { AddBox, Edit, DeleteOutline } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getManufactureTypeName, filterManufactureTypesByType, MANUFACTURE_DICT_TYPES } from "@/utils/constant";
import * as _ from 'lodash';


const CreateInstallActionComponent = props => {
    const { classes,
        open,
        manipulate,
        onhandleSelectMaster,
        users,
        selectType,
        constant,
        installationList,
        constantError,
        componentTitle,
        onhandleClose,
        onhandleSave,
        onhandleTextFieldChange,
        onhandleChangeUser,
        onhandleAddActionRow,
        onhandleDelActionRow,
        onhandleUpdateActionRow,
        onhandleSelectChange
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        minBodyHeight: 120,
        maxBodyHeight: 250,
        pageSize: 3,
        pageSizeOptions: [3, 5, 10],
        pagination: false,
        header: false,
    }
    const tableStyle = {
        ...style,
        border: '1px solid rgb(183,183,183)',
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '操作类型',
                field: 'actionName',
                render: row => <Typography>{getManufactureTypeName(constant, MANUFACTURE_DICT_TYPES.INSTALL_ACTION, row.actionName)}</Typography>,
                lookup: _.chain(filterManufactureTypesByType(constant, MANUFACTURE_DICT_TYPES.INSTALL_ACTION)).keyBy('code').mapValues('name').value()
            }
        ]
        return columns
    }

    const assemblyData = () => {
        return manipulate.actions;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{componentTitle}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='操作项名称'
                                        value={manipulate.manipulateName}
                                        onChange={onhandleTextFieldChange}
                                        name='manipulateName'
                                    />
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        name='selectType'
                                        onChange={event => onhandleSelectChange(event, 'selectType')}
                                        value={selectType}
                                    >
                                        <MenuItem key={'install'} value={'install'}>{'装置'}</MenuItem>
                                        <MenuItem key={'device'} value={'device'}>{'设备'}</MenuItem>
                                    </TextField>
                                </Grid>
                                {selectType === 'install' && <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        select
                                        required
                                        variant="outlined"
                                        name='install'
                                        label='点击选择装置'
                                        onChange={(event) => onhandleSelectChange(event, 'install')}
                                        error={constantError.installErr && constantError.installErr !== ''}
                                        helperText={constantError.installErr || ''}
                                        value={manipulate.installId}
                                    >
                                        {installationList ?
                                            installationList.map(data => (
                                                <option value={data.installId} key={data.installId}>{data.installName}</option>
                                            )) : null
                                        }
                                    </TextField>
                                </Grid>}
                                {selectType === 'device' && <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label='点击选择设备'
                                        name='deviceId'
                                        error={constantError.deviceErr && constantError.deviceErr !== ''}
                                        helperText={constantError.deviceErr || ''}
                                        value={manipulate.deviceName || ''}
                                        onClick={event => onhandleSelectMaster(event)}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container>
                                <Grid item xs={12}> <Divider /></Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="column" >
                                        <Grid item className={classes.itemGrid}>

                                            <MaterialTable
                                                columns={assemblycolumns()}
                                                data={assemblyData()}
                                                options={tableOptions}
                                                localization={tableLocalization}
                                                style={tableStyle}
                                                title='操作类型'
                                                editable={{
                                                    onRowDelete: (oldData) => new Promise((resolve, reject) => {
                                                        onhandleDelActionRow(oldData);
                                                        resolve()
                                                    }),

                                                    onRowAdd: (newData) => new Promise((resolve, reject) => {
                                                        onhandleAddActionRow(newData);
                                                        resolve()
                                                    }),
                                                    onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                        onhandleUpdateActionRow(newData, oldData);
                                                        resolve()
                                                    }),
                                                }}
                                                icons={{
                                                    Add: () => <AddBox color={'primary'} />,
                                                    Edit: () => <Edit color={'primary'} />,
                                                    Delete: () => <DeleteOutline color={'error'} />
                                                }}
                                            >
                                            </MaterialTable>
                                        </Grid>

                                        <Grid item className={classes.itemGrid}>

                                            <Autocomplete
                                                variant="outlined"
                                                multiple
                                                filterSelectedOptions
                                                id={"users-standard"}
                                                options={users}
                                                getOptionLabel={option => option.lastName + '' + option.firstName}
                                                value={manipulate.owners}
                                                onChange={onhandleChangeUser}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        {...params}
                                                        id={"users-standard-text"}
                                                        label="负责人"
                                                        placeholder="选择人员"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={1}> <Divider orientation="vertical" /></Grid>
                                <Grid item xs={5} >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} variant="outlined" color="primary"> 取消
                    </Button>
                    <Button onClick={onhandleSave} variant="contained" color="primary"> 保存
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreateInstallActionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.array,
    users: PropTypes.array,
};

CreateInstallActionComponent.defaultProps = {
    manipulate: {},
    actions: [],
    users: [],
    componentTitle: "",
    constantError: {}
};

export default withStyles(CreateInstallActionComponent);