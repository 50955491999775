import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import MAttrListComponent from '@/components/ManufactureManagement/ManufactureAttr/MAttrList/MAttrListComponent';
import { getAttrs, addAttr, updateAttr, deleteAttr } from '@/redux/modules/manufacture/manufactureAttr';
import { getManufactureattrtypes } from '@/redux/modules/constant';

class MAttrContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        this.props.getManufactureattrtypes();
        this.props.getAttrs();
    }

    handleAdd = (newData) => {
        return this.props.addAttr(newData).then((res) => {
            if (res) {
                this.props.getAttrs();
            }
        });
    }

    handleUpdate = (newData, oldData) => {

        const newAttr = { attrId: oldData.attrId, attrCode: newData.attrCode ? newData.attrCode : '', attrName: newData.attrName ? newData.attrName : '', attrType: newData.attrType, opentext: newData.opentext ? newData.opentext : '', status: newData.status };
        return this.props.updateAttr(oldData.attrId, newAttr).then((res) => {
            if (res) {
                this.props.getAttrs();
            }
        });
    }

    handleDelete = (oldData) => {
        return this.props.deleteAttr(oldData.attrId).then((res) => {
            if (res) {
                this.props.getAttrs();
            }
        });
    }

    render() {
        return (
            <div>
                <MAttrListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    attrs={this.props.attrs}
                    attrTypes={this.props.attrTypes}
                />

            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        attrs: state.manufactureAttr.attrs,
        attrTypes: state.constant.manufactureAttrTypes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAttrs: (attrCode, attrName, attrUnit, status) => {
            dispatch(getAttrs(attrCode, attrName, attrUnit, status))
        },
        addAttr: (attr) => {
            return dispatch(addAttr(attr));
        },
        updateAttr: (attrId, attr) => {
            return dispatch(updateAttr(attrId, attr));
        },
        deleteAttr: (attrId) => {
            return dispatch(deleteAttr(attrId));
        },
        getManufactureattrtypes: () => {
            return dispatch(getManufactureattrtypes());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MAttrContainer);
