import React from 'react';
import {
    Grid,
    Typography,
    // Switch
} from '@material-ui/core';
import withStyles from './styles';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { getCodeName, getCodeListByType, TYPE_ATTR, TYPE_PERMISSION } from '@/utils/manufactureUtils';

const AttrPanelComponent = props => {
    const { classes,
        activity,
        manufactureDicts,
        onhandleAttrUpdate,
        roleEditFlag,
        // onhandleShareTypeChange,
    } = props;

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,
    }
    const detailTableStyle = {
        ...style,
        paddingLeft: 30
    }

    const assemblyDetailColumns = () => {

        const columns = [
            {
                title: '名称',
                field: 'attrName',
                render: row => <Typography>{row.attrName}</Typography>,
                editable: 'never'
            }, {
                title: '类型',
                field: 'attrType',
                render: row => <Typography>{getCodeName(manufactureDicts, TYPE_ATTR, row.attrType)}</Typography>,
                editable: 'never'
            }, {
                title: '初始值',
                field: 'attrDefault',
                render: row => <Typography>{row.attrDefault}</Typography>,
            }, {
                title: '单位',
                field: 'attrUnit',
                render: row => <Typography>{row.attrUnit}</Typography>,
            }, {
                title: '别名',
                field: 'attrAlias',
                render: row => <Typography>{row.attrAlias}</Typography>,
            }, {
                title: '能否编辑',
                field: 'attrPermission',
                render: row => <Typography>{getCodeName(manufactureDicts, TYPE_PERMISSION, row.attrPermission)}</Typography>,
                lookup: _.chain(getCodeListByType(manufactureDicts, TYPE_PERMISSION)).keyBy('code').mapValues('name').value()
            }, {
                title: '排序',
                field: 'seq',
                type:'numeric',
                render: row => <Typography>{row.seq}</Typography>,
            },
        ]
        return columns
    }

    return (
        <div alignitems="center" className={classes.root}>
            {/* <Grid container>
                <Grid item>
                    <Typography gutterBottom>
                        {'是否需要填需方'}
                    </Typography>
                    <Switch
                        checked={activity.isRequire === 1}
                        onChange={(event) => onhandleShareTypeChange(event, activity.activityId)}
                        color="primary"
                        name="isRequire"
                    />
                </Grid>
            </Grid> */}
            <Grid>
                <MaterialTable
                    columns={assemblyDetailColumns()}
                    data={activity.manufactureAttrs}
                    options={detailTableOptions}
                    localization={detailTableLocalization}
                    style={detailTableStyle}
                    title={<Typography variant='h3'>活动属性列表</Typography>}
                    editable={
                        {
                            isEditable: () => roleEditFlag,
                            onRowUpdate: (newData, oldData) => onhandleAttrUpdate(newData, oldData, activity.activityId)
                        }
                    }
                ></MaterialTable>
            </Grid>
        </div>
    )
}

AttrPanelComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    activity: PropTypes.object,
    manufactureDicts: PropTypes.array,
    roleEditFlag: PropTypes.bool,
    onhandleAttrUpdate: PropTypes.func,
    onhandleShareTypeChange: PropTypes.func,
};

AttrPanelComponent.defaultProps = {
    roleEditFlag: true,
};

export default withStyles(AttrPanelComponent);