import { withStyles } from '@material-ui/styles';
import { stripedTableStyle, tableStyle, default as antdCustomStyle } from '@/utils/antdCustomStyle';
// import { colors } from '@material-ui/core';

const styles = theme => ({
  stripedTable: stripedTableStyle,
  normalTable: tableStyle,
  oddRow: antdCustomStyle.oddRow,
  detailtable: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  materialtable: {
    boxShadow: 'none'
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  basicInfoContainer: {
    marginLeft: theme.spacing(2),
  },
  subTitle: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    borderLeft: '2px solid ' + theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  infoGrid: {
    marginBottom: theme.spacing(1),
  },

  // label: {
  //   fontSize: 14,
  //   fontWeight: 600,
  //   whiteSpace: 'nowrap',
  //   textAlign: 'right',
  //   width: 80,
  //   paddingRight: theme.spacing(2),
  // },

  flexgrow: {
    flexGrow: 1
  },

  label: {
    paddingTop: theme.spacing(0.8),
    whiteSpace: 'nowrap',
    width: 90,
    textAlign: 'right'
  },
  labelarea: {
    paddingTop: theme.spacing(2),
    whiteSpace: 'nowrap',
    width: 90,
    textAlign: 'right',
    paddingRight: theme.spacing(0.5),
  },
  content: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    width: 'calc(100% - 24px)'
  },
  gridItem: {
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2)
  },
  formContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 80px)'
  },

  textAreaContainer: {
    flexWrap: 'nowrap',
    // alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    width: 'calc(100% - 80px)'
  },
  textArea: {
    width: '100%',
    // border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles);
