import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Typography,
    Button,
    TextField,
    Grid,
    MenuItem,
    FormGroup,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Chip,
    InputAdornment,
} from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import * as _ from 'lodash';

const InfoEdit = props => {
    const {
        classes,
        onhandleSave,
        onBackToList,
        editInstallation,
        editInstallationError,
        installationChange,
        onhandleChange,
        onhandleCheckChange,
        onhandleSelectChange,
        onhandleCheck,
        companyList,
        departmentList,
        companyTree,
    } = props;

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    return (
        <div>
            <div className={classes.card}>

                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'基本信息'}
                    </Typography>
                </Grid>

                <Grid container item spacing={1} className={classes.gridContainer} sm={12} lg={12}>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>装置名</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                id="instName-name"
                                className={classes.textField}
                                variant="outlined"
                                size='small'
                                name="instName"
                                value={editInstallation.installName}
                                onChange={event => onhandleChange(event, 'installName')}
                                error={editInstallationError.installName !== ''}
                                onBlur={event => onhandleCheck(event, 'installName')}
                                helperText={editInstallationError.installName || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>装置编号</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                id="installCode-name"
                                className={classes.textField}
                                variant="outlined"
                                size='small'
                                name="installCode"
                                value={editInstallation.installCode}
                                onChange={event => onhandleChange(event, 'installCode')}
                                disabled={_.isInteger(editInstallation.installId)}
                                error={editInstallationError.installCode !== ''}
                                onBlur={event => onhandleCheck(event, 'installCode')}
                                helperText={editInstallationError.installCode || ''}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.justify}>英文名</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                id="enName-name"
                                className={classes.textField}
                                variant="outlined"
                                size='small'
                                name="enName"
                                value={editInstallation.enName ? editInstallation.enName : ''}
                                onChange={event => onhandleChange(event, 'enName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>所属单位</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                id="filled-select-company"
                                select
                                value={editInstallation.companyId}
                                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                                variant="outlined"
                                size='small'
                                error={editInstallationError.companyId !== ''}
                                onBlur={event => onhandleCheck(event, 'productivity')}
                                helperText={editInstallationError.companyId || ''}
                                disabled={editInstallation.installId || companyDisabled()}
                            >
                                {_.sortBy(companyList, 'companyId').map((option) => (
                                    <MenuItem key={option.companyId} value={option.companyId}>
                                        {option.companyName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>设计产能</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <TextField
                                id="outlined-full-width"
                                className={classes.textField}
                                value={editInstallation.productivity}
                                type="number"
                                onChange={event => onhandleChange(event, 'productivity')}
                                variant="outlined"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={editInstallationError.productivity !== ''}
                                onBlur={event => onhandleCheck(event, 'productivity')}
                                helperText={editInstallationError.productivity || ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            万吨/年
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>定员</span>
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                id="outlined-full-width"
                                className={classes.textField}
                                value={editInstallation.staffNo}
                                type="number"
                                onChange={event => onhandleChange(event, 'staffNo')}
                                variant="outlined"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={editInstallationError.staffNo !== ''}
                                onBlur={event => onhandleCheck(event, 'staffNo')}
                                helperText={editInstallationError.staffNo || ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            人
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={12}>
                        <Typography className={classes.checkboxLabel}>
                            <span className={classes.required}>*</span>
                            <span className={classes.justify}>负责班组</span>
                        </Typography>
                        <Grid item className={classes.formContainer}>
                            <FormGroup row className={classes.checkboxContainer}>
                                {
                                    departmentList ? departmentList.map((dpt) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={"dptCheck" + dpt.dptId}
                                                    color="primary"
                                                    size={'small'}
                                                    sx={classes.checkbox}
                                                    checked={_.findIndex(editInstallation.dpts, (d) => { return d.dptId === dpt.dptId }) > -1}
                                                    onChange={(event) => onhandleCheckChange(event, dpt.dptId)}
                                                />
                                            }
                                            label={dpt.dptName}
                                        />
                                    )) : null
                                }
                            </FormGroup>
                            <FormHelperText error={editInstallationError.dpts !== ''}>
                                {editInstallationError.dpts || ''}</FormHelperText>
                        </Grid>
                    </Grid>

                </Grid>
                {Boolean(editInstallation && editInstallation.units && editInstallation.units.length && editInstallation.units.length > 0) && <>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'单元'}
                        </Typography>
                    </Grid>

                    <Grid container item spacing={1} className={classes.chipContainer} sm={12} lg={8}>
                        {editInstallation.units.map(unit => (
                            <Chip
                                className={classes.chip}
                                color='primary'
                                label={unit.unitName}
                            />
                        ))}
                    </Grid></>}
                {Boolean(editInstallation && editInstallation.areas && editInstallation.areas && editInstallation.areas.length && editInstallation.areas.length > 0) && <>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'区域'}
                        </Typography>
                    </Grid>

                    <Grid container item spacing={1} className={classes.chipContainer} sm={12} lg={8}>
                        {editInstallation
                            && editInstallation.areas
                            && editInstallation.areas.map(unit => (
                                <Chip
                                    className={classes.chip}
                                    color='primary'
                                    label={unit.areaName}
                                />
                            ))}
                    </Grid></>}
            </div>

            <div className={classes.card} >
                <Button variant="contained" color="primary" className={classes.button}
                    onClick={event => onhandleSave(event)}
                    disabled={!installationChange || _.findIndex(Object.values(editInstallationError), function (o) { return o !== ''; }) > 0}
                >
                    保存
                </Button>

                <Button variant="outlined" color="primary" className={classes.button}
                    onClick={event => onBackToList(event)}
                >
                    返回
                </Button>
            </div>
            <Prompt message="数据未保存,确定要离开？" when={installationChange} />
        </div >
    );
};

InfoEdit.propTypes = {
    onhandleChange: PropTypes.func.isRequired,
    onhandleCheckChange: PropTypes.func.isRequired,
    editInstallation: PropTypes.object.isRequired,
    editInstallationError: PropTypes.object.isRequired,
    onBackToList: PropTypes.func.isRequired,
    companyTree: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    installationChange: PropTypes.bool.isRequired,
};

InfoEdit.defaultProps = {
    editInstallation: {
        units: [],
        areas: [],
    },
    installationChange: false,
    departmentList: [],
    companyTree: [],
}

export default withStyles(InfoEdit);
