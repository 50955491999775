import React, { Component } from 'react';
import { connect } from 'react-redux';
import AttachmentDocs from "../../components/AttachmentDocs";
import PropTypes from "prop-types";
import { cleanmodal } from "../../redux/modules/modal";
import { download } from '../../redux/modules/minio';
import axios from 'axios';

class AttachmentDocsContainer extends Component {

    static propTypes = {
        docs: PropTypes.object
    };
    constructor(props) {
        super(props)
        this.state = {
            docs: [],
            title: '',
        }
    }

    componentDidMount() {
        const {
            parameters
        } = this.props;
        this.setState({
            docs: parameters.docs,
            title: parameters.title
        })
    }

    handleGetMediaBlob = async (docType, url) => {
        let blob = null;
        if (url) {
            if (docType === 'DOC_VIDEO') {
                // console.log('blob video')
                await axios.get(url, { responseType: 'blob' }).then(res => {
                    blob = new Blob([res.data], { type: "video/mp4" });
                })
            } else {
                // console.log('blob image')
                await axios.get(url, { responseType: 'blob' }).then(res => {
                    blob = new Blob([res.data], { type: "image/jpg" });
                })
            }
        }
        return blob;
    }

    handleDownload = async (doc) => {
        if (doc) {
            const {
                docName,
                downloadName,
                docType,
                url
            } = doc;
            const blob = await this.handleGetMediaBlob(docType, url);
            let fileName = downloadName ? downloadName : docName;
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(link.href);
            }
        }
    }

    render() {
        return (
            <AttachmentDocs
                title={this.state.title}
                docs={this.state.docs}
                onhandleGetBlob={this.handleGetMediaBlob.bind(this)}
                onhandleCancel={this.props.onhandleCancel.bind(this)}
                onDownload={this.handleDownload.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.user.leaders,
        parameters: state.modal.parameters,
        userProfile: state.auth.userProfile
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCancel: () => {
            dispatch(cleanmodal())
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentDocsContainer)
