import React from 'react';
import {
    Grid, Typography, TextField, MenuItem
} from '@material-ui/core';
import withStyles from '@/components/ManufactureManagement/AssayRecordSummary/styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';

const AssayRecordSummaryComponent = props => {
    const { classes,
        records,
        companyList,
        installList,
        departments,
        onhandleChange,
        onhandleDateChange,
        tempId,
        temps,
        endDate,
        startDate,
        companyId,
        dptId,
        installId,
        onhandleSearch,
        onhandleClearSearch,
        exportCsv
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbarButtonAlignment: 'right',
        tableLayout: 'fixed'
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '化验记录',
                field: 'tempName',
                editable: 'never',
                render: row => <Typography>{row.tempName}</Typography>
            },
            {
                title: '物料名称',
                field: 'testTarget',
                editable: 'never',
                render: row => <Typography>{row.testTarget}</Typography>
            },
            {
                title: '化验项目',
                field: 'itemName',
                editable: 'never',
                render: row => <Typography>{row.itemName}</Typography>
            },
            {
                title: '化验次数',
                field: 'recordCount',
                type: 'numeric',
                width: 100,
                render: row => <Typography>{row.recordCount}</Typography>
            },
        ]

        return columns

    }

    return (
        <div alignitems="center" className={classes.planitemroot}>

            <MaterialTable
                className={classes.materialtable}
                columns={assemblycolumns()}
                data={records}
                options={tableOptions}
                localization={tableLocalization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                title={''}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h3">
                                化验次数统计
                                    </Typography>
                            <Grid container direction="row" spacing={4} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        单位:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='companyId'
                                        onChange={event => onhandleChange(event, 'companyId')}
                                        value={companyId}
                                    >
                                        {[{companyId:0,companyAlias:'全部'},...companyList].map(ele => (
                                            <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        部门:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='dptId'
                                        onChange={event => onhandleChange(event, 'dptId')}
                                        value={dptId}
                                    >
                                        {[{dptId:0,dptName:'全部'},...departments].map(ele => {
                                            if (ele.dptId === 0 || ele.companyId === companyId) {
                                                return <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                                            }else{
                                                return null
                                            }
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        装置:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='installId'
                                        onChange={event => onhandleChange(event, 'installId')}
                                        value={installId}
                                    >
                                        {[{installId:0,installName:'全部'},...installList].map(ele => {
                                            if (ele.installId === 0 || ele.companyId === companyId) {
                                                return <MenuItem key={ele.installId} value={ele.installId}>{ele.installName}</MenuItem>
                                            }else{
                                                return null
                                            }
                                        })}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={4} alignItems="center">
                                <Grid item>
                                    <Typography >
                                        化验记录:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridLongSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='tempId'
                                        onChange={event => onhandleChange(event, 'tempId')}
                                        value={tempId}
                                    >
                                        {[{tempId:0,tempName:'全部'},...temps].map(ele => (
                                            <MenuItem key={ele.tempId} value={ele.tempId}>{ele.tempName}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        化验日期:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            id="date-selector"
                                            margin="normal"
                                            value={startDate}
                                            onChange={date => onhandleDateChange(date, 'startDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        ~
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            id="date-selector"
                                            margin="normal"
                                            value={endDate}
                                            onChange={date => onhandleDateChange(date, 'endDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                actions={[
                        {
                            icon: 'search',
                            tooltip: '搜索记录',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                onhandleSearch()
                            }
                        },
                        {
                            icon: 'clear_all',
                            tooltip: '清空搜索条件',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                onhandleClearSearch()
                            }
                        },
                        {
                            icon: 'download',
                            tooltip: '导出',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                exportCsv()
                            }
                        }
                    ]}
            >
            </MaterialTable>

        </div >
    )
}

AssayRecordSummaryComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

AssayRecordSummaryComponent.defaultProps = {
};

export default withStyles(AssayRecordSummaryComponent);