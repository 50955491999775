import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppointmentDetail from '../../components/OperationManagement/Appointment/AppointmentDetail';
import OperationWorkPermitPanel from '../OperationManagement/OperationWorkPermitPanel';
import OperationPermitUserPanel from '../OperationManagement/OperationPermitUserPanel';
import OperationWorkAcceptancePanel from '../OperationManagement/OperationWorkAcceptancePanel';
import WorkRecordPanel from './WorkRecordPanel';
import { getDateByTimestamp } from '@/utils/datetime';
import {
    initAppointmentDetail,
    updateAppointment,
    updateAppointmentDetail, // 仅用于表格内更新
    updateAppointmentDetailStatus,
    // deleteAppointmentDetail,
    createWorkPermitUser,
    updateWorkPermitUser,
    removeWorkPermitUser,
    createWorkPermit,
    updateWorkPermit,
    changeWorkPermitStatus,
    removeWorkPermitDoc,
    getWorkRecordList,
    getWorkOrder,
    getTerminateType,
    routeToAppointmentCreate,
    createWorkRecord
} from '../../redux/modules/operation';
import {
    // addPlanDetail
} from '@/redux/modules/inspection/inspectionPlan';
import { openWorkPermitPanel } from '@/redux/modules/operationWorkPermit';
import { openPermitUserPanel } from '@/redux/modules/operationPermitUser';
import { openAcceptancePanel } from '@/redux/modules/operationWorkAcceptance';
import { download, downloadURL, upload, removeObject } from '@/redux/modules/minio';
import { getCompanyId } from "@/utils/userUtils";
import { getNowTimestamp } from '@/utils/datetime';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '../../redux/modules/message';
import { mappingSpecCodeToPermitCode } from '@/utils/operationUtils'
import { STATUS_VALUE } from '@/utils/constant';
import { arrayToTree, treeToArray } from '@/utils/constant';
import _ from 'lodash';
import { FilePreviewer } from '@/components/SimpleComponents'

class AppointmentDetailContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        companyList: PropTypes.array,
        appointmentDetails: PropTypes.array,
        specCodeList: PropTypes.array,
        userProfile: PropTypes.object,
        newAppointmentChange: PropTypes.bool,
        currentAppointment: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            orderId: undefined,
            appId: undefined,
            routeType: '',
            workType: '',

            workRecordPanelOpen: false,
            currentRecord: {},
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            doc: {}
        }
    }


    componentWillMount() {
        let orderId = this.props.match.params.oid;
        let appId = this.props.match.params.aid;
        let routeType = this.props.match.params.routeType;
        let workType = this.props.match.params.workType;
        this.setState({
            orderId: orderId,
            appId: appId,
            routeType: routeType,
            workType: workType
        })
        this.props.onhandleInitAppointmentDetail(_.toInteger(appId), _.toInteger(orderId));
    }

    handleDownload() {
        let tenantId = this.props.userProfile.roles[0].tenantId;
        let envCode = 'dev';
        let doc = this.state.doc;
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        this.props.onhandleDownload(doc.docLink, mybucket);
    }

    async handlePreview(doc) {
        let tenantId = this.props.userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        return this.props.onhandleDownloadURL(doc.docLink, mybucket)
            .then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: doc.docLink.split('.')[1]
                        },
                        doc: doc
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    handleAttachment(event, record) {
        const {
            currentAppointment,
            onhandleOpenWorkRecordDocs
        } = this.props;
        const {
            docs
        } = record;

        if (docs && docs.length) {
            const promiseArr = [];
            docs.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.operation.work-record-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docs.forEach((doc, index) => {
                    doc.url = res[index].payload;
                    let recordTimeStr = getDateByTimestamp(record.createTime);
                    let specType = this.renderSpecCode(currentAppointment);
                    doc.downloadName = [
                        '作业记录',
                        specType,
                        currentAppointment.serialNo,
                        record.content,
                        record.result,
                        record.creatorLastName + record.revisorFirstName
                    ].join('_') + '_' + recordTimeStr
                        + '.' + doc.docName.split('.').pop().toLowerCase(); // 后缀名
                })
                onhandleOpenWorkRecordDocs({
                    docs: docs,
                    title: '作业记录附件'
                });
            }).catch(err => {

            });
        }
    }

    renderSpecCode = (appointment) => {
        const { specCodeList } = this.props;
        let codeName = '普通作业'
        if (specCodeList && specCodeList.length > 0) {
            let value = _.find(specCodeList, function (o) { return o.code === appointment.specCode })
            if (value) {
                codeName = value.name
            }
        }
        return codeName
    }

    handleDeletePermit(permit) {
        let changeStatus = () => {
            this.props.onhandleChangeWorkPermitStatus(permit.ptwId, 'inactive', () => {
                // let { permitDocs } = permit;
                // if (!permitDocs || !permitDocs[0]) return;

                // let tenantId = this.props.userProfile.roles[0].tenantId;

                // let doc = permitDocs[0];
                // let envCode = 'dev';
                // if (window.location.host.indexOf('3050') > -1) {
                //     envCode = 'dev';
                // } else if (window.location.host.indexOf('3060') > -1) {
                //     envCode = 'uat';
                // } else if (process.env.NODE_ENV === 'production') {
                //     envCode = 'prod';
                // }
                // let mybucket = _.replace(envCode + '.{tenant_id}.inspection.pretreatment-doc', '{tenant_id}', tenantId);
                // 要显示inactive的许可，故不删除
                // this.props.onhandleRemoveDoc(doc, mybucket);
                this.props.onhandleInitAppointmentDetail(permit.appId);
            });
        }
        this.props.onhandleOpenConfirmDialog(`删除确认`, `是否删除许可办理?`, changeStatus, null, false);
    }

    handleOpenPermitPanel(permit, showtype) {
        console.log(showtype)
        const {
            onhandleOpenPermitPanel,
            currentAppointment
        } = this.props;
        if (currentAppointment.specCode && currentAppointment.specCode !== '') {
            onhandleOpenPermitPanel(this.handleSubmit.bind(this), null, permit, currentAppointment, showtype);
        }
    }

    handleSubmit(permit, showtype) {
        const {
            currentAppointment,
            userProfile,
            onhandleCreateWorkPermit,
            onhandleUpdateWorkPermit,
            onhandleMinioUpload,
            onhandleInitAppointmentDetail,
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;

        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();
        let newPermit = {
            appId: currentAppointment.appId,
            permitType: mappingSpecCodeToPermitCode(currentAppointment.specCode),
            ptwName: permit.ptwName,
            // expiredTime: _.toInteger(permit.expiredTime),
            opentext: permit.opentext || '',
            // intGuarder: _.toInteger(permit.intGuarder),
            // extGuarder: permit.extGuarder || '',
            tenantId: tenantId
        }
        console.log(showtype)
        if (showtype === 'close') {
            newPermit.status = 'done';
        }
        if (!permit.ptwId) {
            // 许可文档必上传, 后台进行createPermit和permit-doc更新事务
            onhandleMinioUpload(permit.file, mybucket, offset, () => {
                let doc = {
                    docId: 0,
                    docName: permit.file[0].name,
                    docLink: offset + permit.file[0].name,
                    docType: 'DOC_FILE',
                    mybucket: mybucket,
                    tenantId: tenantId
                };
                newPermit.permitDoc = doc;
                onhandleCreateWorkPermit(newPermit, (resPermit) => {
                    // reload permits
                    onhandleInitAppointmentDetail(currentAppointment.appId);
                });
            })
        } else {
            newPermit.ptwId = permit.ptwId;
            newPermit.status = STATUS_VALUE.STATUS_NEW;
            // 判断是否有重传文档操作
            const hasRemovedDocs = permit.permitDocsBack && permit.permitDocs.length === 0;
            const hasNewFile = permit.file && permit.file.length && permit.file.length > 0;
            if (hasRemovedDocs && hasNewFile) {
                onhandleMinioUpload(permit.file, mybucket, offset, () => {
                    let doc = {
                        docId: permit.permitDocsBack[0].docId,
                        docName: permit.file[0].name,
                        docLink: offset + permit.file[0].name,
                        docType: 'DOC_FILE',
                        mybucket: mybucket,
                        tenantId: tenantId
                    };
                    newPermit.permitDoc = doc;
                    onhandleUpdateWorkPermit(newPermit, (resPermit) => {
                        // reload permits
                        onhandleInitAppointmentDetail(currentAppointment.appId);
                        return;
                    })
                })
            } else {
                onhandleUpdateWorkPermit(newPermit, (resPermit) => {
                    // reload permits
                    onhandleInitAppointmentDetail(currentAppointment.appId);
                    return;
                })
            }
        }
    }

    handleSelectApprover() {
        this.handleCreateProcessLine();
    }

    handleSubmitPermit() {
        let userId = this.props.userProfile.userId;

        if (this.props.currentPermit.permitUsers && this.props.currentPermit.permitUsers.length > 0) {
            let userData = _.find(this.props.currentPermit.permitUsers, { userId: userId });
            userData.confirm = true;
            userData.opentext = '提交特殊许可办理申请'
            let changeStatus = () => {
                this.props.updateWorkPermitUser(userData);
            }
            this.props.onhandleOpenConfirmDialog(`提交确认`, `是否提特殊交许可办理?`, changeStatus, null, false);
        }
    }

    handleAuditPermit(result) {
        if (result) {
            // 通过
            const {
                userProfile,
                currentAppointment,
                currentPermit,
                onhandleOpenConfirmDialog
            } = this.props;
            if (currentAppointment && currentPermit && currentPermit.permitUsers) {
                const permitUsers = currentPermit.permitUsers;
                const permitUser = _.find(permitUsers, { 'userId': userProfile.userId });
                permitUser.confirm = true;
                let changeStatus = () => {
                    this.props.updateWorkPermitUser(permitUser);
                }
                onhandleOpenConfirmDialog(`提交确认`, `是否通过许可办理?`, changeStatus, null, false);
            }
        } else {
            // 驳回
            this.handleOpenPermitUserPanel();
        }
    }

    handleOpenPermitUserPanel() {
        const {
            onhandleOpenPermitUserPanel,
            userProfile,
            currentPermit
        } = this.props;
        if (currentPermit && currentPermit.permitUsers) {
            const permitUsers = currentPermit.permitUsers;
            const permitUser = _.find(permitUsers, { 'userId': userProfile.userId });
            console.log(permitUser);
            onhandleOpenPermitUserPanel(this.handleRejectPermit.bind(this), null, permitUser);
        }
    }

    handleRejectPermit(permitUser) {
        // const {
        //     currentAppointment,
        //     onhandleCreateWorkPermitUser
        // } = this.props;
        console.log(permitUser);
        permitUser.confirm = false;
        // permitUser.opentext = '提交特殊许可办理申请'
        // const newUsers = [
        //     {
        //         userId: permitUser.userId,
        //         ptwId: permitUser.ptwId,
        //         confirm: permitUser.confirm,
        //         opentext: permitUser.opentext || '',
        //         appId: currentAppointment.appId,
        //     }
        // ];
        // 添加app审批记录

        this.props.updateWorkPermitUser(permitUser);
    }

    handleCreateProcessLine() {
        let companys = []
        let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
        if (mainCompany) {
            companys.push(mainCompany.companyId)
        }
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        companys.push(companyId)
        companys = _.uniq(companys);

        let permitUsers = []
        if (this.props.currentPermit.permitUsers && this.props.currentPermit.permitUsers.length > 0) {
            _.cloneDeep(this.props.currentPermit.permitUsers).forEach(permitUser => {
                permitUser.id = permitUser.puId;
                permitUsers.push(permitUser);
            })
            permitUsers = arrayToTree(permitUsers, 'id', 'preId');
        }

        let param = {
            companyId: companys.length > 0 ? companys : null,
            processTree: permitUsers,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.processesline && this.props.processesline.length > 0) {
                        let processesline = treeToArray(_.cloneDeep(this.props.processesline));
                        processesline.forEach(pl => {
                            pl.ptwId = this.props.currentPermit.ptwId
                        })
                        this.props.onhandleCreateWorkPermitUser(processesline)
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleCreateProcess(param);
    }

    handleDeletePermitUser(permitUser) {
        this.props.removeWorkPermitUser(permitUser);
    }

    handleAcceptance(status) {
        const {
            currentAppointment,
            onhandleOpenConfirmDialog,
            onhandleOpenWorkAcceptancePanel,
            onhandleInitAppointmentDetail,
            onhandleUpdateAppointmentDetail,
            updateAppointmentDetailStatus,
            onhandleMessage
        } = this.props;
        if (status === STATUS_VALUE.STATUS_FINALIZED) {
            // 修改app审批记录, 如果是特殊作业还需创建特殊计划
            let changeStatus = () => {
                if (Boolean(currentAppointment.specCode)) {
                    // this.handleCreateInspectionTempPlan((res) => {
                    const to = {
                        appId: currentAppointment.appId,
                        // inspectionPlan: res.dtlId,
                        status: STATUS_VALUE.STATUS_FINALIZED
                    }
                    // 更新app状态
                    onhandleUpdateAppointmentDetail(to, () => {
                        onhandleInitAppointmentDetail(currentAppointment.appId);
                    });
                    // });
                } else {
                    updateAppointmentDetailStatus(currentAppointment.appId, STATUS_VALUE.STATUS_FINALIZED, () => {
                        onhandleInitAppointmentDetail(currentAppointment.appId);
                    });
                }
            }
            onhandleOpenConfirmDialog(`作业确认`, `是否结束本作业?`, changeStatus, null, false);
        } else if (status === STATUS_VALUE.STATUS_ACCEPTANCED) {
            let changeStatus = () => {
                updateAppointmentDetailStatus(currentAppointment.appId, STATUS_VALUE.STATUS_ACCEPTANCED, () => {
                    onhandleInitAppointmentDetail(currentAppointment.appId);
                });
            }
            onhandleOpenConfirmDialog(`作业确认`, `是否验收本作业?`, changeStatus, null, false);
        } else if (status === STATUS_VALUE.STATUS_DONE) {
            if (Boolean(currentAppointment.specCode)) {
                if (_.filter(currentAppointment.permits, { status: 'done' }).length > 0) {
                    let changeStatus = () => {
                        updateAppointmentDetailStatus(currentAppointment.appId, STATUS_VALUE.STATUS_DONE, () => {
                            onhandleInitAppointmentDetail(currentAppointment.appId);
                        });
                    }
                    onhandleOpenConfirmDialog(`作业确认`, `是否关闭特殊许可申请?`, changeStatus, null, false);
                } else {
                    onhandleMessage('请先创建特殊许可关闭记录');
                }
            } else {
                let changeStatus = () => {
                    updateAppointmentDetailStatus(currentAppointment.appId, STATUS_VALUE.STATUS_DONE, () => {
                        onhandleInitAppointmentDetail(currentAppointment.appId);
                    });
                }
                onhandleOpenConfirmDialog(`作业确认`, `是否验收本作业?`, changeStatus, null, false);
            }
        } else {
            onhandleOpenWorkAcceptancePanel(this.handleWorkAcceptance.bind(this), null, status);
        }
    }

    handleWorkAcceptance(acceptance) {
        const {
            currentAppointment,
            onhandleUpdateAppointmentDetail,
            onhandleInitAppointmentDetail
        } = this.props;
        const newAppointment = {
            appId: currentAppointment.appId,
            opentext: acceptance.opentext || '',
            status: acceptance.status
        };
        if (acceptance.status === STATUS_VALUE.STATUS_TERMINATED) {
            newAppointment.terminateType = acceptance.terminateType
        }
        // 更新app状态
        onhandleUpdateAppointmentDetail(newAppointment, () => {
            onhandleInitAppointmentDetail(currentAppointment.appId);
        });
    }

    // handleCreateInspectionTempPlan(callback) {
    //     const {
    //         onhandleCreateTempPlan,
    //         currentAppointment,
    //         currentWorkOrder,
    //         currentUserInfo,
    //     } = this.props;

    //     let addDetail = {
    //         creator: currentUserInfo.userId,
    //         tenantId: currentUserInfo.tenantId,
    //         companyId: currentAppointment.locationCompany,
    //         inspectHead: currentAppointment.appHead,
    //         startTime: moment()().valueOf(),
    //         inspectTime: currentAppointment.endTime,
    //         inspectType: currentAppointment.specCode.replace('SPECCODE', 'INS07'),
    //         inspectionDptStrList: [currentWorkOrder.dptId]
    //     }
    //     onhandleCreateTempPlan(addDetail, (res) => {
    //         callback(res);
    //     })
    // }


    // 获取当前许可
    // getCurrentPermit = () => {
    //     if (!this.props.currentAppointment.permits) return {};
    //     let permit = null;
    //     const result = _.find(this.props.currentAppointment.permits, o => {
    //         return [STATUS_VALUE.STATUS_NEW, STATUS_VALUE.STATUS_SUBMITTED, STATUS_VALUE.STATUS_APPROVED]
    //             .includes(o.status);
    //     })
    //     if (result) permit = result
    //     return permit;
    // }

    //-----------------------------------------------------------------
    handleAddDetail = () => {
        this.setState({
            workRecordPanelOpen: true,
            currentRecord: { deviceId: this.props.currentWorkOrder.deviceId, deviceName: this.props.currentWorkOrder.deviceName, companyId: this.props.currentWorkOrder.companyId }
        })
    }

    handleWorkRecordPanelClose = () => {
        this.setState({
            workRecordPanelOpen: false,
            currentRecord: {},
        })
    }

    handleWorkRecordPanelConfirm = (record) => {
        console.log(record)
        const {
            userProfile,
            currentAppointment,
            onhandleCreateWorkOrder,
            onhandleMinioUpload,
            onhandleInitAppointmentDetail,
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;

        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.work-record-doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();
        let newWorkRecord = {
            appId: currentAppointment.appId,
            content: record.content,
            result: record.result,
            workTime: _.toInteger(record.workTime),
            tenantId: tenantId
        }

        if (_.isInteger(record.deviceId) && record.deviceId !== 0) {
            newWorkRecord.deviceId = record.deviceId
        }
        if (record.file) {
            onhandleMinioUpload(record.file, mybucket, offset, () => {
                let doc = {
                    docId: 0,
                    docName: offset + record.file[0].name,
                    docLink: mybucket,
                    docType: 'DOC_FILE',
                    mybucket: mybucket,
                    tenantId: tenantId
                };
                newWorkRecord.docs = [doc];
                onhandleCreateWorkOrder(newWorkRecord,
                    () => {
                        // reload permits
                        this.setState({
                            workRecordPanelOpen: false,
                            currentRecord: {},
                        })
                        onhandleInitAppointmentDetail(currentAppointment.appId);
                        return;
                    }
                )
            })
        } else {
            onhandleCreateWorkOrder(newWorkRecord,
                () => {
                    // reload permits
                    onhandleInitAppointmentDetail(currentAppointment.appId);
                    return;
                }
            )
        }
    }

    handleDonePermit = () => {
        const {
            currentAppointment,
            currentPermit,
            onhandleMessage,
            onhandleChangeWorkPermitStatus,
            onhandleInitAppointmentDetail,
            onhandleOpenConfirmDialog
        } = this.props;
        if (_.filter(currentAppointment.permits, { status: 'new' }).length > 0) {
            let changeStatus = () => {
                onhandleChangeWorkPermitStatus(currentPermit.ptwId, 'approved', () => {
                    onhandleInitAppointmentDetail(currentAppointment.appId);
                });
            }
            onhandleOpenConfirmDialog(`特殊许可办理完成确认`, `特殊许可是否办理完成?`, changeStatus, null, false);
        } else {
            onhandleMessage('请先创建特殊许可关闭记录');
        }
    }

    render() {
        const { previewFile } = this.state;
        return (
            <>
                <AppointmentDetail
                    companyList={this.props.companyList}
                    currentWorkOrder={this.props.currentWorkOrder}
                    currentAppointment={this.props.currentAppointment}
                    currentPermit={this.props.currentPermit}
                    workRecordList={this.props.workRecordList}
                    newAppointmentChange={this.props.newAppointmentChange}
                    userProfile={this.props.userProfile}
                    specCodeList={this.props.specCodeList}
                    userList={this.props.userList}
                    onhandleUpdate={this.props.updateAppointmentDetail.bind(this)}
                    onhandleBack={this.props.routeToAppointmentList.bind(this, this.state.orderId, this.state.routeType)}
                    onhandleDownload={this.handlePreview.bind(this)}
                    onhandleAttachment={this.handleAttachment.bind(this)}
                    onhandlePermit={this.handleOpenPermitPanel.bind(this)}
                    onhandleDeletePermit={this.handleDeletePermit.bind(this)}
                    onhandleSelectApprover={this.handleSelectApprover.bind(this)}
                    onhandleSubmitPermit={this.handleSubmitPermit.bind(this)}
                    // 不直接修改permit status
                    onhandleRejectPermit={this.handleAuditPermit.bind(this, false)}
                    onhandleApprovePermit={this.handleAuditPermit.bind(this, true)}
                    onhandleDeletePermitUser={this.handleDeletePermitUser.bind(this)}
                    workType={this.state.workType}
                    onhandleAcceptance={this.handleAcceptance.bind(this)}
                    onhandleAddDetail={this.handleAddDetail.bind(this)}
                    onhandleDonePermit={this.handleDonePermit.bind(this, true)}
                />
                <WorkRecordPanel
                    open={this.state.workRecordPanelOpen}
                    record={this.state.currentRecord}
                    closeCallBack={this.handleWorkRecordPanelClose.bind(this)}
                    confirmCallback={this.handleWorkRecordPanelConfirm.bind(this)}
                />
                <OperationWorkPermitPanel />
                <OperationPermitUserPanel />
                <OperationWorkAcceptancePanel />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onDownload={this.handleDownload.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyList: state.constant.companies,
        appointmentDetails: state.operation.appointmentDetails,
        newAppointmentChange: state.operation.newAppointmentChange,
        currentAppointment: state.operation.currentAppointment,
        currentWorkOrder: state.operation.currentWorkOrder,
        specCodeList: state.operation.specCodeList,
        currentPermit: state.operation.currentPermit,
        workRecordList: state.operation.workRecordList,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        selectedUsers: state.userSelect.selectedUsers,
        userList: state.user.users.list,
        companies: state.constant.companies,
        processesline: state.processlinewithuser.processesline,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitAppointmentDetail: (appId, orderId) => {
            dispatch(initAppointmentDetail(appId))
            dispatch(getWorkRecordList({ appId: appId }))
            // 可选刷新order
            if (Boolean(orderId) && orderId !== '') { dispatch(getWorkOrder(orderId)) }
            dispatch(getTerminateType())
        },
        // 仅用于表格更新
        updateAppointmentDetail: (value, oldData) => {
            dispatch(updateAppointmentDetail(value, oldData))
        },
        onhandleUpdateAppointmentDetail: (value, callback) => {
            dispatch(updateAppointment(value, callback))
        },
        updateAppointmentDetailStatus: (appId, status, callback) => {
            dispatch(updateAppointmentDetailStatus(appId, status, callback))
        },
        onhandleCreateWorkPermitUser: (users, callback) => {
            dispatch(createWorkPermitUser(users, callback, 'spec'))
        },
        updateWorkPermitUser: (permitUser, callback) => {
            dispatch(updateWorkPermitUser(permitUser, callback, 'spec'))
        },
        removeWorkPermitUser: (users, callback) => {
            dispatch(removeWorkPermitUser(users, callback))
        },
        onhandeModalClose: () => {
            dispatch(cleanmodal());
        },
        routeToAppointmentList: (orderId, routeType) => {
            dispatch(routeToAppointmentCreate(orderId, routeType))
        },
        onhandleOpenPermitPanel: (confirmCallback, closeCallback, permit, appointment, showtype) => {
            dispatch(openWorkPermitPanel(confirmCallback, closeCallback, permit, appointment, showtype))
        },
        onhandleOpenPermitUserPanel: (confirmCallback, closeCallback, userId, ptwId) => {
            dispatch(openPermitUserPanel(confirmCallback, closeCallback, userId, ptwId))
        },
        onhandleOpenWorkAcceptancePanel: (confirmCallback, closeCallback, status) => {
            dispatch(openAcceptancePanel(confirmCallback, closeCallback, status))
        },
        onhandleMinioUpload: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateWorkPermit: (value, callback) => {
            dispatch(createWorkPermit(value, callback));
        },
        onhandleUpdateWorkPermit: (value, callback) => {
            dispatch(updateWorkPermit(value, callback));
        },
        onhandleChangeWorkPermitStatus: (ptwId, status, callback) => {
            dispatch(changeWorkPermitStatus(ptwId, status, callback))
        },
        onhandleOpenWorkRecordDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleDownload: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onhandleRemoveMinioObject: (docName, mybucket) => {
            dispatch(removeObject(mybucket, docName));
        },
        onhandleRemoveDoc: (doc, mybucket) => {
            dispatch(removeWorkPermitDoc(doc, mybucket));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        // onhandleCreateTempPlan: (planDetail, callback) => {
        //     // true => 新建临时计划 & 临时计划详情
        //     dispatch(addPlanDetail(planDetail, callback, true))
        // },
        onhandleSelectUserWarning: () => {
            dispatch(createMessage('error', `请选择批准人`));
        },
        onhandlePushUserWarning: () => {
            dispatch(createMessage('error', `无法选择已添加的批准人`));
        },
        onhandlePushAppHeadWarning: () => {
            dispatch(createMessage('error', `无法选择作业负责人作为批准人`));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleCreateProcess: (parm) => {
            dispatch(createmodal('createProcessLineApproval', parm, 'medium'));
        },
        onhandleCreateWorkOrder: (value, callback) => {
            dispatch(createWorkRecord(value, callback));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentDetailContainer);