import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";
import {
    Grid,
    Typography,
    ButtonGroup,
    Divider,
    Button,
    Card,
    Select,
    IconButton,
    Tooltip,
    Box,
    FormControl,
    FormHelperText
} from "@material-ui/core";
import {
    AttachFile,
    ExpandMore,
    Link,
    SaveAlt
    // AssignmentTurnedIn
} from '@material-ui/icons';
import { getDateByTimestamp } from '@/utils/datetime';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

const HazardInfo = props => {
    const {
        classes,
        editable,
        isCompanySupervisor,
        userProfile,
        companies,
        currentHazard,
        currentHazardBackup,
        hazardError,
        treatmentSchemeList,
        companyLeaders,
        // search,
        // onhandleAdd,
        rootCauseList,
        severityList,
        impactLevelList,
        factorList,
        hazardChange,
        onHazardAttach,
        onAcceptAttach,
        // onhandleGetTreatmentScheme,
        onhandleSelectChange,
        // onhandlePageChange,
        onhandleListMapping,
        onhandleSchemePlanMapping,
        onhandleSave,
        // onhandleStartTreatment,
        onhandleCloseHazard,
        onhandleUpdateSchemeStatus,
        onhandleBack,
        onEditTreatment,
        onSetTreatmentPlan,
        onAcceptTreatment,
        onDeleteTreatment,
        onSchemePlanLink,
        onhandleExportFile
    } = props;

    const getComponentTitle = () => {
        let title = '隐患记录';
        switch (currentHazardBackup.status) {
            case 'active':
                title = '隐患审定';
                break;
            case 'evaluated':
                title = '隐患方案制定';
                break;
            case 'treating':
                title = '隐患治理';
                break;
            case 'done':
                title = '隐患档案';
                break;
            case 'invalid':
                title = '无效隐患记录';
                break;
            default:
            // do nothing
        }
        return title
    }

    const { userId: USER_ID } = userProfile;

    // 基于角色的隐患审定/开始治理/关闭权限
    const hazardPermission = () => {
        // 监管方隐患审定编辑权限(route配置)
        let supervisor = false;
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_hazardlist_info' });
        if (permission && permission.action === 'W' && isCompanySupervisor) supervisor = true;

        // 责任方治理方案编辑权限
        let companyLeader = false;
        companyLeader = _.findIndex(companyLeaders, { userId: USER_ID }) > -1;

        return supervisor || companyLeader;
    }

    // 治理方案指派权限
    const schemeAssignPermission = () => {

        // 监管方治理方案指派权限(route配置)部门领导、厂领导
        let supervisor = false;
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_treatment_info' });
        if (permission && permission.action === 'R' && isCompanySupervisor) supervisor = true;

        return supervisor;
    }

    // 治理方案编辑权限
    const schemePermission = () => {

        // 监管方治理方案编辑权限(route配置)
        let supervisor = false;
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_treatment_info' });
        if (permission && (permission.action === 'W' || permission.action === 'R') && isCompanySupervisor) supervisor = true;

        // 责任方治理方案编辑权限
        // let companyLeader = false;
        // companyLeader = _.findIndex(companyLeaders, { userId: USER_ID }) > -1;

        return supervisor;
    }

    // 责任人 治理方案 完善补充权限
    const schemeDetailPermission = () => {
        if (!treatmentSchemeList || !treatmentSchemeList.list || !treatmentSchemeList.list.length) {
            return false;
        }
        for (const scheme of treatmentSchemeList.list) {
            if (scheme.head === USER_ID) {
                return true;
            }
        }
        return false;
    }

    // 判断是否完成隐患审定
    const evaluated =
        currentHazardBackup.rootCause &&
        currentHazardBackup.severity &&
        currentHazardBackup.impactLevel &&
        currentHazardBackup.factor &&
        ['evaluated', 'treating', 'done'].includes(currentHazard.status); // 'accepting',

    // 判断Hazard是否进入Scheme编辑&审批阶段
    const needApprovedStatus = ['evaluated', 'treating'].includes(currentHazard.status);

    // 判断能否编辑Scheme
    const schemeEditable = (treatmentScheme) => {
        const {
            status
        } = treatmentScheme;

        let editable = false;
        if (['new', 'rejected'].includes(status)) editable = true;
        return editable;
    }

    // 判断能否开始治理（全部Scheme完成审批 & Hazard状态=待治理）
    // const allSchemeApproved =
    //     treatmentSchemeList.total > 0
    //     && _.findIndex(treatmentSchemeList.list, (obj) => (obj.status !== 'finalized')) < 0
    //     && currentHazard.status === 'evaluated';

    // 为单条Scheme渲染审批操作
    const actionMapping = (treatmentScheme) => {
        const {
            head,
            // accepter,
            status
        } = treatmentScheme;
        // 2步死流程  2022.2.19 工程公司不再需要流程
        const actions = {
            // 'rejected': [
            //     { current: 'rejected', name: '提交', next: 'submitted', permission: USER_ID === head }
            // ],
            // 'new': [
            //     { current: 'new', name: '提交', next: 'submitted', permission: USER_ID === head }
            // ],
            'new': [
                { current: 'new', name: '提交', next: 'inprocess', permission: USER_ID === head }
            ],
            // 'submitted': [
            //     { current: 'submitted', name: '驳回', next: 'rejected', permission: USER_ID === accepter },
            //     { current: 'submitted', name: '通过', next: 'inprocess', permission: USER_ID === accepter },
            // ]
        }
        const currentAction = actions[status];

        return currentAction ? currentAction.map(action => {
            return (
                <Button
                    className={classes.inlineButton}
                    key={USER_ID + action.next}
                    variant={action.next === 'rejected' ? "outlined" : "contained"}
                    color="primary"
                    size="small"
                    onClick={event => onhandleUpdateSchemeStatus(event, treatmentScheme, action)}
                    disabled={!action.permission}
                >{action.name}</Button>
            )
        }) : null
    }

    const [expand, setExpand] = useState(false);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [page, setPage] = useState(0);

    const companyOptions = () => (
        <React.Fragment>
            {companies ?
                companies.map(data => (
                    <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const rootCauseOptions = () => (
        <React.Fragment>
            {rootCauseList ?
                rootCauseList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const severityOptions = () => (
        <React.Fragment>
            {severityList ?
                severityList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const impactLevelOptions = () => (
        <React.Fragment>
            {impactLevelList ?
                impactLevelList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const factorOptions = () => (
        <React.Fragment>
            {factorList ?
                factorList.map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )

    const assemblycolumns = () => {

        let columns = [
            // {
            //     title: '序号',
            //     field: 'schemeId',
            //     render: row => row ? <Typography>{row.schemeId}</Typography> : '',
            //     width: '10%',
            //     editable: 'never'
            // },
            {
                title: '整改方式',
                field: 'schemeCat',
                render: row => row ? <Typography>{onhandleListMapping(row.schemeCat, 'inspectionDict')}</Typography> : '',
                width: '15%',
                editable: 'never'
            },
            {
                title: '整改单位',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '整改部门',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.dptId, 'department')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '整改负责人',
                field: 'head',
                render: row => row ? <Typography>{row.headName}</Typography> : '',
                width: '10%',
                editable: 'never'
            },
            {
                title: '整改期限',
                field: 'targetDate',
                render: row => row ? <Typography>{getDateByTimestamp(row.targetDate)}</Typography> : '',
                width: '10%',
                editable: 'never'
            },
            {
                title: '验收负责人',
                field: 'accepter',
                render: row => row ? <Typography>{row.accepterName}</Typography> : '',
                width: '10%',
                editable: 'never'
            },
            {
                title: '验收评价',
                field: 'acceptResult',
                width: '10%',
                render: row => row ? <Typography title={row.acceptResult}>{truncate(row.acceptResult, 10)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '验收凭据',
                field: 'acceptDocs',
                width: '10%',
                render: row => row ? <Typography>{
                    (row.acceptDocs && row.acceptDocs.length > 0) ?
                        <Typography gutterBottom>
                            <Button size="small"
                                className={classes.attachmentBtn}
                                onClick={() => { onAcceptAttach(row) }}
                                color="primary"
                                endIcon={<AttachFile fontSize="small" />}
                            >{'查看'}
                            </Button>
                        </Typography>
                        : '无'

                }</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '备注',
            //     field: 'opentext',
            //     render: row => (row && row.acceptResult && row.acceptResult !== '') ? (
            //         <Chip size="small" className={row.acceptResult === 'true' ? classes.passed : classes.denied} label={onhandleListMapping(row.acceptResult, 'result')} />
            //     ) : <Typography>{''}</Typography>,
            //     editable: 'never'
            // },
            {
                title: '方案提交',
                field: 'process',
                width: '10%',
                hidden: !needApprovedStatus,
                render: row => {
                    if (row) {
                        return row.status === 'inprocess' ?
                            <Typography>已提交</Typography>
                            :
                            <ButtonGroup disableElevation>{
                                actionMapping(row)
                            }</ButtonGroup>
                    }
                    return ''
                },
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.status, 'schemeStatus')}</Typography> : '',
                editable: 'never'
            }
        ]
        return columns
    }

    const assembleDetailPanel = [
        rowData => ({
            disabled: rowData.schemeCat === 'TRE03',
            tooltip: '查看治理关联计划',
            render: rowData => {
                return rowData.schemeCat !== 'TRE03' ?
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            localization={localization}
                            columns={assemblePlanColumns(rowData)}
                            data={rowData.schemePlan}
                            options={{
                                showTitle: false,
                                padding: 'dense',
                                search: false,
                                paging: false,
                                toolbar: false
                            }}
                            style={{
                                border: '0px solid black',
                                boxShadow: 'none'
                            }}
                        >
                        </MaterialTable>
                    </Grid> : <Typography>{rowData.opentext && rowData.opentext !== '' ? rowData.opentext : '-'}</Typography>
            }
        })
    ];

    const assemblePlanColumns = (treatmentScheme) => ([
        {
            title: '计划类型',
            field: 'planType',
            width: '10%',
            render: row => row ? <Typography>{onhandleSchemePlanMapping(treatmentScheme, row.planType, 'planType')}</Typography> : '',
            editable: 'never'
        },
        {
            title: '概述',
            field: 'opentext',
            width: '10%',
            render: row => row ? <Typography>{row.opentext}</Typography> : '',
            editable: 'never'
        },
        {
            title: '状态',
            field: 'status',
            width: '10%',
            render: row => row ? <Typography>{onhandleSchemePlanMapping(treatmentScheme, row.status, 'status')}</Typography> : '',
            editable: 'never'
        },
        {
            title: '',
            field: '',
            width: '10%',
            render: row => row ? <Typography gutterBottom>
                <Button size="small"
                    className={classes.attachmentBtn}
                    onClick={() => { onSchemePlanLink(row.planType) }}
                    color="primary"
                    endIcon={<Link fontSize="small" />}
                >
                    前往计划</Button>
            </Typography> : '',
            editable: 'never'
        },
    ])

    const components = {
        Toolbar: props => (
            <>
                <MTableToolbar {...props} />
            </>
        )
    }

    return (
        <Card className={classes.card}>
            <div>
                <Grid container spacing={1} justify={'space-between'} >
                    <Grid item xs={2} margin={0}>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            {getComponentTitle()}</Typography>
                    </Grid>
                    <Grid item margin={0}>
                        <Typography variant="h5" style={{ display: 'inline-block', margin: 10 }}>
                            {'隐患编号: ' + currentHazard.serialNo}</Typography>
                    </Grid>
                </Grid>
            </div>
            <div>
                {currentHazard.status === 'done' &&
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Button startIcon={<SaveAlt />}
                            size="small"
                            color="primary"
                            variant="contained" onClick={() => { onhandleExportFile(currentHazard.hid) }}>
                            {'导出'}
                        </Button></Grid>
                }
                {
                    !currentHazard.recordId && // 随机排查
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                检查类型</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {'随机排查'}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={1} className={classes.gridContainer}>
                    {
                        currentHazard.recordId && // 计划排查
                        <>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    检查类型</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom>{
                                    onhandleListMapping(currentHazard.checklist.listType, 'inspectionDict')
                                    + (currentHazard.checklist.catCode ? ` - ${onhandleListMapping(currentHazard.checklist.catCode, 'inspectionDict')}` : '')
                                }</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    监管部门</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom>
                                    {currentHazard.checklist.dptId ? onhandleListMapping(currentHazard.checklist.dptId, 'department') : '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    预期根因</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom>
                                    {currentHazard.checklist.rootCause ? onhandleListMapping(currentHazard.checklist.rootCause, 'inspectionDict') : '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    预期隐患级别</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom>
                                    {currentHazard.checklist.severity ? onhandleListMapping(currentHazard.checklist.severity, 'inspectionDict') : '-'}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        expand && currentHazard.recordId && // 计划排查
                        <>
                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    检查内容</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography gutterBottom className={classes.infoBlock}>
                                    {/* .replace(/([.\n\r]+)/g, '<br />') */}
                                    {currentHazard.checklist.problem ? currentHazard.checklist.problem : '-'}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography gutterBottom>
                                    检查依据</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography gutterBottom className={classes.infoBlock}>
                                    {/* .replace(/([.\n\r]+)/g, '<br />') */}
                                    {currentHazard.checklist.reference ? currentHazard.checklist.reference : '-'}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        currentHazard.recordId && // 计划排查折叠/展开
                        <Grid container spacing={1} className={classes.gridContainerSub}>
                            <Grid container alignItems="center" justify="center">
                                <Box mr={1}>
                                    <Tooltip title="检查详情">
                                        <span><IconButton
                                            size={'small'}
                                            color="inherit"
                                            onClick={() => setExpand(!expand)}
                                        >
                                            <ExpandMore className={expand ? classes.iconRotate : null} />
                                        </IconButton></span>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </div>
            <Divider variant="middle" />
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Typography gutterBottom >
                        产生时间</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom>
                        {currentHazard.createTime ? getDateByTimestamp(currentHazard.createTime, true) : '-'}
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <Typography gutterBottom>
                        属地单位</Typography>
                </Grid>
                <Grid item xs={2}>
                    {!editable ?
                        <Typography gutterBottom>
                            {onhandleListMapping(currentHazard.companyId, 'company')}
                        </Typography>
                        :
                        <FormControl className={classes.formControl} disabled={!editable}
                            error={hazardError.companyId !== ''}
                        >
                            <Select
                                style={{ width: '90%' }}
                                native
                                name='companyId'
                                value={currentHazard.companyId || ''}
                                onChange={(event) => onhandleSelectChange(event)}
                            >
                                {/* <option aria-label="None" value=""></option> */}
                                {companyOptions()}
                            </Select>
                            <FormHelperText>{hazardError.companyId}</FormHelperText>
                        </FormControl>
                    }
                </Grid>

                <Grid item xs={1}>
                    <Typography gutterBottom>
                        记录人</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom>
                        {/* {onhandleListMapping(currentHazard.creator, 'user')} */}
                        {currentHazard.recorder}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>隐患图像</Typography>
                </Grid>
                <Grid item xs={2}>
                    {
                        (currentHazard.docs && currentHazard.docs.length > 0) ?
                            <Typography gutterBottom>
                                <Button size="small"
                                    className={classes.attachmentBtn}
                                    onClick={onHazardAttach}
                                    color="primary"
                                    endIcon={<AttachFile fontSize="small" />}
                                >
                                    点击查看</Button>
                            </Typography>
                            :
                            <Typography gutterBottom>
                                无</Typography>
                    }
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        问题描述</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography gutterBottom>
                        {/* .replace(/([.\n\r]+)/g, '<br />') */}
                        {currentHazard.opentext ? currentHazard.opentext : '-'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        根因</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.formControl} disabled={!editable}
                        error={hazardError.rootCause !== ''}
                    >
                        <Select
                            style={{ width: '90%' }}
                            native
                            name='rootCause'
                            value={currentHazard.rootCause || ''}
                            onChange={(event) => onhandleSelectChange(event)}
                        >
                            <option aria-label="None" value=""></option>
                            {rootCauseOptions()}
                        </Select>
                        <FormHelperText>{hazardError.rootCause}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        隐患级别</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.formControl} disabled={!editable}
                        error={hazardError.severity !== ''}
                    >
                        <Select
                            style={{ width: '90%' }}
                            native
                            name='severity'
                            value={currentHazard.severity || ''}
                            onChange={(event) => onhandleSelectChange(event)}
                        >
                            <option aria-label="None" value=""></option>
                            {severityOptions()}
                        </Select>
                        <FormHelperText>{hazardError.severity}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        危害程度</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.formControl} disabled={!editable}
                        error={hazardError.impactLevel !== ''}
                    >
                        <Select
                            style={{ width: '90%' }}
                            native
                            name='impactLevel'
                            value={currentHazard.impactLevel || ''}
                            onChange={(event) => onhandleSelectChange(event)}
                        >
                            <option aria-label="None" value=""></option>
                            {impactLevelOptions()}
                        </Select>
                        <FormHelperText>{hazardError.impactLevel}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom>
                        安全管理要素</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.formControl} disabled={!editable}
                        error={hazardError.factor !== ''}
                    >
                        <Select
                            style={{ width: '90%' }}
                            native
                            name='factor'
                            value={currentHazard.factor || ''}
                            onChange={(event) => onhandleSelectChange(event)}
                        >
                            <option aria-label="None" value=""></option>
                            {factorOptions()}
                        </Select>
                        <FormHelperText>{hazardError.factor}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider variant="middle" />
            {
                evaluated &&
                <div alignitems="center" className={classes.planitemroot}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={treatmentSchemeList.list}

                        options={{
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: true,
                            padding: 'dense'
                        }}
                        localization={{
                            ...localization,
                            body: {
                                ...localization.body,
                                emptyDataSourceMessage: '无治理方案'
                            }
                        }}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        components={components}
                        detailPanel={assembleDetailPanel}
                        actions={
                            [
                                {
                                    icon: 'add',
                                    tooltip: schemeDetailPermission() ? '添加治理方案' : '治理方案指派',
                                    isFreeAction: true,
                                    onClick: () => {
                                        onEditTreatment(null, schemeDetailPermission(), true);

                                    },
                                    // 治理方案指派  只能增加一条记录
                                    hidden: !evaluated || !['evaluated', 'treating'].includes(currentHazardBackup.status) ||
                                        (!schemeAssignPermission() && !schemeDetailPermission()) ||
                                        (treatmentSchemeList !== undefined && treatmentSchemeList.list !== undefined && treatmentSchemeList.list.length > 0)
                                },

                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onEditTreatment(rowData, schemeDetailPermission(), false);
                                    }
                                }),
                                rowData => ({
                                    icon: 'how_to_vote',
                                    tooltip: '下计划',
                                    onClick: (event, rowData) => {
                                        onSetTreatmentPlan(rowData)
                                    },
                                    hidden: !['inprocess', 'invalid'].includes(rowData.status) || currentHazard.status !== 'treating' || rowData.schemeCat === 'TRE03',
                                    disabled: rowData.head !== USER_ID
                                }),
                                rowData => ({
                                    icon: 'assignment_turned_in',
                                    tooltip: '验收',
                                    onClick: (event, rowData) => {
                                        onAcceptTreatment(rowData)
                                    },
                                    hidden: !['inprocess', 'finalized', 'invalid'].includes(rowData.status) || currentHazard.status !== 'treating',
                                    disabled: rowData.accepter !== USER_ID || !(rowData.schemeCat === 'TRE03' || rowData.schemePlan.length > 0)
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: '编辑',
                                    onClick: (event, rowData) => {
                                        onEditTreatment(rowData, schemeDetailPermission(), true);
                                    },
                                    hidden: !schemeEditable(rowData),
                                    disabled: rowData.head !== USER_ID && rowData.creator !== USER_ID && !schemePermission()
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除',
                                    onClick: (event, rowData) => {
                                        onDeleteTreatment(rowData)
                                    },
                                    hidden: !schemeEditable(rowData),
                                    disabled: rowData.head !== USER_ID && rowData.creator !== USER_ID && !schemePermission()
                                }),
                            ]
                        }
                    >
                    </MaterialTable>
                </div>
            }

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={4}>
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}
                    >返回</Button>
                    {
                        hazardPermission() && currentHazard.status === 'active' &&
                        <>
                            <Button variant="contained" color="primary" className={classes.button}
                                onClick={event => onhandleSave(event, null)}
                                disabled={!hazardChange}
                            >保存</Button>
                            <Button variant="contained" color="primary" className={classes.button}
                                onClick={event => onhandleSave(event, 'evaluated')}
                            // disabled={!hazardChange}
                            >审定</Button>
                        </>
                    }
                    {/* {所有治理方案编辑&审批无误后，手动开始治理  => 指派治理方案后直接开始治理
                        hazardPermission() && currentHazard.status === 'evaluated' &&
                        <Tooltip title={allSchemeApproved ? "点击开始治理" : "需要完成所有治理方案的审批"}>
                            <span>
                                <Button variant="contained" color="primary" className={classes.button}
                                    onClick={onhandleStartTreatment}
                                    disabled={!allSchemeApproved}
                                >开始治理</Button>
                            </span>
                        </Tooltip>
                    } */}
                    {
                        ['active'].includes(currentHazard.status) &&
                        <Button variant="contained" className={classes.terminateBtn}
                            onClick={onhandleCloseHazard}
                        >销项</Button>
                    }
                </Grid>

            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={hazardChange} />
        </Card >
    );
};

HazardInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    treatmentSchemeList: PropTypes.object.isRequired,
    onHazardAttach: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleUpdateSchemeStatus: PropTypes.func.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    onhandleStartTreatment: PropTypes.func.isRequired,
    onhandleCloseHazard: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandleSchemePlanMapping: PropTypes.func.isRequired,
    onhandleGetTreatmentScheme: PropTypes.func.isRequired,
    hazardChange: PropTypes.bool.isRequired,
    onEditTreatment: PropTypes.func.isRequired,
    onSetTreatmentPlan: PropTypes.func.isRequired,
    onDeleteTreatment: PropTypes.func.isRequired,
};

export default withStyles(HazardInfo);
