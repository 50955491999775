import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
    Button,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "@/utils/mtable";
import PeopleIcon from '@material-ui/icons/People';

import * as _ from 'lodash';

const InstallSharingComponent = props => {
    const { classes,
        currentUserInfo,
        selectedInstallation,
        departments,
        companies,
        sharingTakelist,
        valuations,
        installationList,
        onhandleMessage,
        onhandleSelectChange,
        onhandleAdd,
        onhandleDelete,
        onhandleUpdate,
        isRootCompany,
        selectedType,
        onhandleTypeChange,
        otherAssets,
        selectedAsset,
        onhandleAssetChange,
        onhandleOpenHistory,
        onhandleOpenInstallationUser,
        userProfile
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options
    }

    // const tableStyle = {
    //     ...style
    // }

    const checkPermissions = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'amoeba_installation_sharing' })
        return permission && permission.action === 'W'
    }

    const renderValuations = () => {
        return <React.Fragment>{valuations.map(data => (<option value={data.code} key={data.code}>{data.name}</option>))}</React.Fragment>
    }
    const renderValuationType = (row) => {
        const take = _.find(valuations, { 'code': row.valuationType });
        return take ? take.name : ''
    }

    const getFixedValue = (row) => {
        if (!row.valuationType || row.valuationType === 'FIXED') {
            return row.fixed
        }
        return "-"
    }

    const getPercentageValue = (row) => {
        if (!row.valuationType || row.valuationType === 'PERCENTAGE') {
            return row.percentage + "%";
        }
        return "-"
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '分厂',
                field: 'takerCompany',
                validate: rowData => (!rowData.takerCompany || rowData.takerCompany === '') ? { isValid: false, helperText: '单位不能为空' } : true,
                render: row => <Typography>{renderCompany(row.takerCompany)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.takerCompany ? props.rowData.takerCompany : ''}
                        name='takerCompany'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {companies ?
                            companies.map(data => (
                                <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
                            )) : null
                        }
                    </TextField>
                )
            }, {
                title: '班组',
                field: 'takerDpt',
                validate: rowData => {
                    if (!rowData.takerDpt || rowData.takerDpt === '') {
                        return { isValid: false, helperText: '班组不能为空' }
                    } else {
                        let dpt = _.find(departments, (d) => { return d.dptId === parseInt(rowData.takerDpt) })
                        if (dpt !== undefined) {
                            if (dpt.companyId !== parseInt(rowData.takerCompany)) {
                                return { isValid: false, helperText: '班组跟单位不匹配' }
                            } else {
                                return true
                            }
                        } else {
                            return { isValid: false, helperText: '班组不能为空' }
                        }
                    }
                },
                render: row => <Typography>{renderDpt(row.takerDpt)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.takerDpt ? props.rowData.takerDpt : ''}
                        name='takerDpt'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {departments && props.rowData.takerCompany !== '' ?
                            _.filter(departments, (d) => {
                                return d.companyId === parseInt(props.rowData.takerCompany) //&& d.amoebaType === 'PROFIT'
                            }).map(data => (
                                <option value={data.dptId} key={data.dptId}>{data.dptName}</option>
                            )) : departments.map(data => (
                                <option value={data.dptId} key={data.dptId}>{data.dptName}</option>
                            ))
                        }
                    </TextField>
                )
            }, {
                title: '分摊方式',
                field: 'valuationType',
                validate: rowData => {
                    if (!rowData.valuationType || rowData.valuationType === '') {
                        return { isValid: false, helperText: '分摊方式不能为空' }
                    } else {
                        return true
                    }
                },
                render: row => <Typography>{renderValuationType(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.valuationType ? props.rowData.valuationType : ''}
                        name='valuationType'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {renderValuations()}
                    </TextField>
                )
            }, {
                title: '百分比',
                field: 'percentage',
                validate: rowData => {
                    if (rowData.valuationType === 'PERCENTAGE' && (!rowData.percentage || rowData.percentage === '')) {
                        return { isValid: false, helperText: '百分比不能为空' }
                    } else {
                        return true
                    }
                },
                render: row => <Typography>{getPercentageValue(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={props.rowData.percentage ? props.rowData.percentage : ''}
                        name='percentage'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            }, {
                title: '固定费',
                field: 'fixed',
                validate: rowData => {
                    if (rowData.valuationType === 'FIXED' && (!rowData.fixed || rowData.fixed === '')) {
                        return { isValid: false, helperText: '固定费不能为空' }
                    } else {
                        return true
                    }
                },
                render: row => <Typography>{getFixedValue(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        value={props.rowData.fixed ? props.rowData.fixed : ''}
                        name='fixed'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
        ]

        return columns

    }

    const assemblyData = () => {
        return sharingTakelist
    }

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companies && companies.length > 0) {
            let value = _.find(companies, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDpt = (dptId) => {
        let label = '';
        let value = _.find(departments, function (o) { return o.dptId === dptId })
        if (value) {
            label = value.dptName
        }
        return label;
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    title={''}
                    actions={[
                        {
                            icon: 'history_icon',
                            tooltip: '修改历史',
                            onClick: (event, rowData) => {
                                onhandleOpenHistory(rowData)
                            }
                        },
                    ]}
                    components={{
                        Toolbar: props => (
                            <div>
                                <Typography variant="h3">
                                    资产分摊
                                </Typography>
                                <Grid container direction="row" spacing={2} alignItems="center" className={classes.gridContainer}>
                                    <Grid item>
                                        <Typography >
                                            资产类型:
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.gridSelect}>
                                        <TextField
                                            fullWidth
                                            select
                                            value={selectedType}
                                            onChange={onhandleTypeChange}
                                        >
                                            <MenuItem key={'type_install'} value={'install'}>{'装置'}</MenuItem>
                                            <MenuItem key={'type_other'} value={'other'}>{'其他'}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {selectedType === 'install' ? <Grid item>
                                        <Typography >
                                            装置名称:
                                        </Typography>
                                    </Grid> : <Grid item>
                                        <Typography >
                                            资产名称:
                                        </Typography>
                                    </Grid>}
                                    {selectedType === 'install' ? <Grid item className={classes.gridSelect}>
                                        <TextField
                                            fullWidth
                                            select
                                            value={selectedInstallation}
                                            onChange={onhandleSelectChange}
                                        >
                                            <MenuItem key={0} value={0}>{''}</MenuItem>
                                            {installationList ?
                                                installationList.map(data => {
                                                    if (!isRootCompany) {
                                                        if (currentUserInfo.companyId === data.companyId) {
                                                            return <MenuItem key={data.installId} value={data.installId}>{data.installName}</MenuItem>
                                                        } else {
                                                            return null
                                                        }
                                                    } else {
                                                        return <MenuItem key={data.installId} value={data.installId}>{data.installName}</MenuItem>
                                                    }
                                                }) : null
                                            }
                                        </TextField>
                                    </Grid> : <Grid item className={classes.gridSelect}> <TextField
                                        fullWidth
                                        select
                                        value={selectedAsset}
                                        onChange={onhandleAssetChange}
                                    >
                                        {otherAssets ?
                                            otherAssets.map(data => (
                                                <MenuItem key={data.ruleId} value={data.ruleId}>{data.targetName}</MenuItem>
                                            )) : null
                                        }</TextField></Grid>}

                                    <Grid className={classes.flexgrow}></Grid>
                                    {selectedType === 'install' && selectedInstallation !== 0 && <Grid>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onhandleOpenInstallationUser()}
                                            startIcon={<PeopleIcon />}
                                        >查看装置人员</Button>
                                    </Grid>}
                                </Grid>
                                <MTableToolbar {...props} />
                            </div>
                        )
                    }}
                    editable={checkPermissions() ? {
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                let errorMsg = ''
                                let valuationType = newData['valuationType'];
                                //按百分比
                                if (valuationType === 'PERCENTAGE') {
                                    let sum = parseFloat(newData['percentage']);
                                    sharingTakelist.forEach((o) => {
                                        if (o.valuationType === 'PERCENTAGE') {
                                            sum += o.percentage
                                        }
                                    })
                                    if (sum > 100) {
                                        // 检查是否超过了100%
                                        errorMsg = '分摊比例总和不能超过100%';
                                    }
                                }
                                if (_.findIndex(sharingTakelist, (o) => { return o.takerDpt === parseInt(newData['takerDpt']) }) > -1) {
                                    // 不能重复班组
                                    errorMsg = '不能添加重复的班组'
                                }

                                if (errorMsg !== '') {
                                    reject()
                                    onhandleMessage(errorMsg);
                                } else {
                                    resolve()
                                    onhandleAdd(newData)
                                }
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                let errorMsg = ''
                                if (_.findIndex(sharingTakelist, (o) => { return o.takerDpt === parseInt(newData['takerDpt']) && o.takeId !== parseInt(newData['takeId']) }) > -1) {
                                    // 不能重复班组
                                    errorMsg = '不能添加重复的班组'
                                } else {
                                    let valuationType = newData['valuationType'];
                                    //按百分比
                                    if (valuationType === 'PERCENTAGE') {
                                        let sum = parseFloat(newData['percentage']);
                                        sharingTakelist.forEach((o) => {
                                            if (o.valuationType === 'PERCENTAGE' && o.takeId !== parseInt(newData['takeId'])) {
                                                sum += o.percentage
                                            }
                                        })
                                        if (sum > 100) {
                                            // 检查是否超过了100%
                                            errorMsg = '分摊比例总和不能超过100%';
                                        }
                                    }
                                }
                                if (errorMsg !== '') {
                                    reject()
                                    onhandleMessage(errorMsg);
                                } else {
                                    resolve()
                                    onhandleUpdate(newData)
                                }
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleDelete(oldData)
                            }),
                    } : {}}
                >
                </MaterialTable>
            </Grid>
        </div>
    )
}

InstallSharingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

InstallSharingComponent.defaultProps = {
    sharingTakelist: []
};

export default withStyles(InstallSharingComponent);