import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    serviceReportList: [] //服务报表
};

export const setServiceReportList = createAction('@@xcloud/manufacture/setServiceReportList');


export const getServiceReportList = createAction('@@xcloud/manufacture/getServiceReportList',
    (day, month, year, companyId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/servicereports'
        let strs = []
        if (day || month || year || companyId || status) {
            if (day) strs.push('day=' + day)
            if (month) strs.push('month=' + month)
            if (year) strs.push('year=' + year)
            if (companyId) strs.push('companyId=' + companyId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setServiceReportList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产报表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const manufactureServiceReportReducer = handleActions(
    {
        [setServiceReportList]: (state, { payload: data }) => {
            return {
                ...state,
                serviceReportList: data
            }
        },
    },
    initialState
)

export default manufactureServiceReportReducer
