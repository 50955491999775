import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import RouteInfoReadComponent from './RouteInfoRead/RouteInfoReadComponent';
import RouteInfoWriteComponent from './RouteInfoWrite/RouteInfoWriteComponent';
import * as _ from 'lodash';

import {
    Grid,
    Typography,
    // CircularProgress,
    Button,
    Divider,
} from '@material-ui/core';

const RouteInfoComponent = props => {
    const {
        classes,
        currentUserInfo,
        userProfile,
        companyTree,
        companyList,
        confirmRoleList,
        inspectionDictionaries,
        currentRoute,
        isRouteEditMode,
        isRouteChange,
        onhandleSelect,
        onhandleChange,
        onhandleChangeRecordConfirm,
        onhandleSave,
        routeError,
        users
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
        return permission && permission.action === 'W'
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h3" color='textPrimary'>基本信息</Typography>
                <Grid>
                    <Button variant="contained" color="primary" size="small" style={{ marginRight: 10, marginBottom: 10 }}
                        hidden={!checkPermission() || !isRouteEditMode}
                        disabled={!isRouteChange}
                        onClick={(event) => onhandleSave()}>保存</Button>
                </Grid>
            </Grid>
            <Divider />
            <RouteInfoWriteComponent
                currentUserInfo={currentUserInfo}
                currentRoute={currentRoute}
                userProfile={userProfile}
                companyTree={companyTree}
                companyList={companyList}
                confirmRoleList={confirmRoleList}
                inspectionDictionaries={inspectionDictionaries}
                isRouteEditMode={isRouteEditMode}
                onhandleSelect={onhandleSelect}
                onhandleChange={onhandleChange}
                onhandleChangeRecordConfirm={onhandleChangeRecordConfirm}
                routeError={routeError}
                users={users}
            />
        </div>
    );
};
RouteInfoComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    currentRoute: PropTypes.object.isRequired,
    isRouteEditMode: PropTypes.bool.isRequired,
    isRouteChange: PropTypes.bool.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    routeError: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
};

export default withStyles(RouteInfoComponent);