import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  MenuItem,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import _ from 'lodash';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';

const OperationPlanDocsHeader = props => {
  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    search,
    onhandleSelectChange,
    onhandleCheck,
    companyTree,
    companyList,
    departmentList,
    currentCompany,
  } = props;

  const [expand, setExpand] = useState(false);

  const dptDisabled = () => {
    if (currentCompany.companyType === 'SUPPLIER') {
      return true
    }

    return false
  }

  const companyDisabled = () => {
    if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
      return true
    } else {
      if (dptDisabled) {
        return true
      }
    }


    return false
  }

  return (
    <Grid container alignItems="center" className={classes.gridContainer}>
      {/* 搜索项 */}
      <Grid container item alignItems="center" sm={12} md={12} lg={8}>
        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
          <Typography className={classes.label}>
            计划编号
          </Typography>
          <TextField
            className={classes.textFieldInput}
            value={search.optNo}
            variant="outlined"
            size={'small'}
            placeholder="计划编号"
            onChange={event => onhandleSelectChange(event, 'optNo')}
          />
        </Grid>
        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
          <Typography className={classes.label}>
            工单编号
          </Typography>
          <TextField
            className={classes.textFieldInput}
            value={search.orderNo}
            variant="outlined"
            size={'small'}
            placeholder="工单编号"
            onChange={event => onhandleSelectChange(event, 'orderNo')}
          />
        </Grid>
        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
          <Typography className={classes.label}>
            设备编号
          </Typography>
          <TextField
            className={classes.textFieldInput}
            value={search.deviceNo}
            variant="outlined"
            size={'small'}
            placeholder="设备编号"
            onChange={event => onhandleSelectChange(event, 'deviceNo')}
          />
        </Grid>
        {Boolean(expand) && <React.Fragment>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              部件编号
            </Typography>
            <TextField
              className={classes.textFieldInput}
              value={search.accNo}
              variant="outlined"
              size={'small'}
              placeholder="部件编号"
              onChange={event => onhandleSelectChange(event, 'accNo')}
            />
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              单位
            </Typography>
            <TextField
              className={classes.textFieldInput}
              id="filled-select-company"
              select
              value={search.companyId}
              onChange={(event) => onhandleSelectChange(event, 'companyId')}
              variant="outlined"
              size={'small'}
              disabled={companyDisabled()}
            >
              <MenuItem aria-label="None" value={''}></MenuItem>
              {_.sortBy(companyList, 'companyId').map((option) => (
                <MenuItem key={option.companyId} value={option.companyId}>
                  {option.companyAlias}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              部门
            </Typography>
            <TextField
              className={classes.textFieldInput}
              id="filled-select-company"
              select
              value={search.dptId}
              onChange={(event) => onhandleSelectChange(event, 'dptId')}
              variant="outlined"
              size={'small'}
              disabled={!search.companyId || search.companyId === '' || dptDisabled()}
            >
              <MenuItem aria-label="None" value={''}></MenuItem>
              {departmentList ?
                _.sortBy(_.filter(departmentList, { companyId: search.companyId }), 'dptId').map(data => (
                  <MenuItem value={data.dptId} key={data.dptId}>{data.dptName}</MenuItem>
                )) : null
              }
            </TextField>
          </Grid>
        </React.Fragment>}
      </Grid>
      <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={4}>
        {/* 搜索区 */}
        <Grid container item className={classes.gridItemSearchButtons} sm={12}>
          <Button variant="contained" color="primary"
            onClick={() => onhandleSearch()}
          >
            搜索
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
            onClick={onhandleClearSearch}

          >
            重置
          </Button>
          <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
            onClick={() => setExpand(!expand)}
            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
          >
            {expand ? '收起' : '展开'}
          </Button>
          <FormControlLabel
            className={classes.switch}
            label={<Typography style={search.onlyMe ? { fontWeight: 500 } : { color: 'gray' }}>仅看我的工单</Typography>} control={
              <Checkbox size={'small'}
                checked={search.onlyMe}
                onChange={event => onhandleCheck(event, 'onlyMe')} />
            } />
        </Grid>
      </Grid>
    </Grid>
  );
};

OperationPlanDocsHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  departmentList: PropTypes.array.isRequired,
};

export default withStyles(OperationPlanDocsHeader);
