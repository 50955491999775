import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    Tooltip,
    FormHelperText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TreeSelect from 'rc-tree-select';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from './styles';
import PropTypes from 'prop-types';
import AttrPanelComponent from '../MAttrPanel/AttrPanelComponent';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import {
    SERVICE_TYPE_COMMISSION, SERVICE_TYPE, SERVICE_COST_SHARE_TYPE, getCodeListByType, COST_SHARE_TYPE_SHARE_NONE, SERVICE_TYPE_OUTER,
} from '@/utils/manufactureUtils';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash';

const MServiceConfigComponent = props => {
    const { classes,
        service,
        tabValue,
        businessGroupList,
        manufactureDicts,
        materialGroupTree,
        rawMaterialGroupTree,
        amoebaIndexTree,
        serviceList,
        serviceType,
        provideCompaniesSelect,
        provideDptsSelect,
        requireCompaniesSelect,
        requireDptsSelect,
        roleEditFlag,
        onhandleChangeTab,
        onhandleProvideDptsChange,
        onhandleRequireDptsChange,
        onhandleNameClose,
        onhandleChange,
        onhandleSave,
        onhandleClose,
        onhandleDelete,
        onhandleAdd,
        onhandleComActAdd,
        onhandleAttrUpdate,
        onhandleShareTypeChange,
        anchorEl,
        onhandleClickAdd,
        onhandleCloseMenu,
        viewFlg,
        installs,
        onhandleAddBomRelation,
        onhandleUpdateBomRelation,
        // onhandleUpdateBomRelationCancelled,
        onhandleAddSubBomRelation,
        onhandleDeleteBomRelation,
    } = props;
    const tableRef = React.createRef();
    const textFieldDefault = {
        style: {
            width: 120,
        },
    }

    let businessGroup = { groupType: 'OTHER' }
    if (businessGroupList) {
        const bg = _.find(businessGroupList, bg => { return bg.subId === service.amoebaIndex })
        if (bg) businessGroup = bg
    }

    const getServiceTypes = () => {
        let serviceTypes = [];
        if (manufactureDicts) {
            serviceTypes = getCodeListByType(manufactureDicts, SERVICE_TYPE)
        }
        return serviceTypes.filter(ele => ele.code !== SERVICE_TYPE_COMMISSION).map(e => (
            <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
        ))
    }

    const getCostSharingList = () => {
        let list = getCodeListByType(manufactureDicts, SERVICE_COST_SHARE_TYPE);
        list = list.filter(ele => ele.code !== COST_SHARE_TYPE_SHARE_NONE);
        return list.map(e => (
            <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
        ))
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '产品/材料名称',
                field: 'materialName',
                render: row => row ?
                    row.materialGroupName ? <Typography>{row.materialName ? row.materialGroupName + '/ ' + row.materialName + (row.materialSpecification && _.trim(row.materialSpecification) !== '' ? ('/ ' + row.materialSpecification) : '') : row.materialGroupName}</Typography>
                        : <Typography>{row.materialName ? row.materialName + (row.materialSpecification && _.trim(row.materialSpecification) !== '' ? ('/ ' + row.materialSpecification) : '') : ''}</Typography>
                    : '',
                editable: 'onUpdate',
                validate: rowData => (!rowData.materialId || rowData.materialId === '') ? { isValid: false, helperText: '产品/材料不能为空' } : true,
                editComponent: (props) => (
                    <div>
                        <TreeSelect
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                            treeLine
                            value={props.rowData.materialId || ''}
                            style={{
                                marginTop: 5,
                                width: 180,
                            }}
                            labelInValue
                            treeData={rawMaterialGroupTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => {
                                props.rowData.materialId = value.value;
                                props.rowData.materialName = value.label;
                                tableRef.current.forceUpdate();
                            }
                            }
                        />
                        {props.helperText !== '' ? <FormHelperText
                            error={true}
                        >
                            {props.helperText}
                        </FormHelperText> : null}
                    </div>
                )
            },
            {
                title: '配比',
                field: 'ratio',
                render: row => row ? <Typography>{row.ratio ? row.ratio : ''}</Typography> : '',
                editable: 'onUpdate',
                validate: rowData => (!(rowData && _.isNumber(rowData.ratio))) ? { isValid: false, helperText: '配比格式不正确' } : true,
                editComponent: (props) => (
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        value={props.rowData.ratio}
                        type="number"
                        onChange={e => props.onChange(_.toNumber(e.target.value))}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}>
                    </TextField>
                )
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'onUpdate',
            },
        ]

        return columns
    }


    const editTable = !roleEditFlag;

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={true} onClose={onhandleClose} className={classes.dialog}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>服务配置</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid >
                            <Grid container>
                                <Grid className={classes.itemGrid}>
                                    <Typography gutterBottom>
                                        {'服务分类'}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        select
                                        name='amoebaIndex'
                                        InputProps={textFieldDefault}
                                        disabled={editTable}
                                        onChange={(event) => onhandleChange(event, 'amoebaIndex')}
                                        value={service.amoebaIndex || ''}
                                    >
                                        {businessGroupList && businessGroupList.map(e => (
                                            <MenuItem key={e.subId} value={e.subId}>{e.groupName}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {(businessGroup.groupType === 'PRODUCE' || businessGroup.groupType === 'SALES') &&
                                    <Grid className={classes.itemGrid}>
                                        <Typography gutterBottom>
                                            {'主要材料'}
                                        </Typography>
                                        <TreeSelect
                                            disabled={editTable}
                                            transitionName="rc-tree-select-dropdown-slide-up"
                                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                            treeLine
                                            value={service.materialId || service.materialGroup || ''}
                                            style={{
                                                marginTop: 5,
                                                width: 180,
                                            }}
                                            labelInValue
                                            treeData={materialGroupTree}
                                            treeNodeFilterProp="label"
                                            filterTreeNode={false}
                                            onChange={(value) => onhandleChange(value, 'materialGroup')}
                                        />
                                    </Grid>
                                }
                                {(businessGroup.groupType === 'PRODUCE' || businessGroup.groupType === "TRADE" || businessGroup.groupType === 'SALES') &&
                                    <Grid className={classes.itemGrid}>
                                        <Typography gutterBottom>
                                            {'产品'}
                                        </Typography>
                                        <TreeSelect
                                            disabled={editTable}
                                            transitionName="rc-tree-select-dropdown-slide-up"
                                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                            treeLine
                                            value={service.productId || service.productGroup || ''}
                                            style={{
                                                marginTop: 5,
                                                width: 180,
                                            }}
                                            labelInValue
                                            treeData={materialGroupTree}
                                            treeNodeFilterProp="label"
                                            filterTreeNode={false}
                                            onChange={(value) => onhandleChange(value, 'productGroup')}
                                        />
                                    </Grid>
                                }
                                {(service.serviceType === 'COMMISSION' || viewFlg === 1) &&
                                    <Grid className={classes.itemGrid}>
                                        <Typography gutterBottom>
                                            {'关联指标'}
                                        </Typography>
                                        <TreeSelect
                                            disabled={editTable}
                                            transitionName="rc-tree-select-dropdown-slide-up"
                                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                            dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                                            treeLine
                                            value={service.costSub || ''}
                                            style={{
                                                marginTop: 5,
                                                width: 180,
                                            }}
                                            labelInValue
                                            treeData={amoebaIndexTree}
                                            treeNodeFilterProp="label"
                                            filterTreeNode={false}
                                            onChange={(value) => onhandleChange(value, 'costSub')}
                                        />
                                    </Grid>
                                }
                                <Grid className={classes.itemGrid} key='serviceName'>
                                    <Typography gutterBottom>
                                        {'服务名称'}
                                    </Typography>
                                    <Autocomplete
                                        freeSolo
                                        disableClearable
                                        handleHomeEndKeys
                                        options={serviceList.map((e) =>
                                            e.materialName + (e.specification ? '/ ' + e.specification : '')
                                        )}
                                        style={{
                                            marginTop: -15,
                                            width: 160,
                                        }}
                                        onClose={(event, reason) => onhandleNameClose(event, reason, 'serviceName')}
                                        onChange={(event, value) => onhandleChange(event, 'serviceName', value)}
                                        value={service.serviceName || ''}
                                        disabled={editTable}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                margin="normal"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {serviceType !== SERVICE_TYPE_COMMISSION &&
                                    <Grid className={classes.itemGrid} key='serviceType'>
                                        <Typography gutterBottom>
                                            {'服务方式'}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            select
                                            name='serviceType'
                                            InputProps={textFieldDefault}
                                            disabled={editTable}
                                            onChange={(event) => onhandleChange(event, 'serviceType')}
                                            value={service.serviceType || ''}
                                        >
                                            {getServiceTypes()}
                                        </TextField>
                                    </Grid>
                                }
                                {service.serviceType !== SERVICE_TYPE_OUTER &&
                                    <Grid className={classes.itemGrid} key='costSharing'>
                                        <Typography gutterBottom>
                                            {'成本分配'}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            select
                                            name='costSharing'
                                            onChange={(event) => onhandleChange(event, 'costSharing')}
                                            InputProps={textFieldDefault}
                                            disabled={editTable}
                                            value={service.costSharing || ''}
                                        >
                                            {getCostSharingList()}
                                        </TextField>
                                    </Grid>
                                }

                                <Grid className={classes.itemGrid} key='isTemp'>
                                    <Typography gutterBottom>
                                        {'是否临时'}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        select
                                        name='isTemp'
                                        onChange={(event) => onhandleChange(event, 'isTemp')}
                                        InputProps={textFieldDefault}
                                        disabled={editTable}
                                        value={service.isTemp}
                                    >
                                        <MenuItem value={'1'}>{'是'}</MenuItem>
                                        <MenuItem value={'0'}>{'否'}</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid className={classes.itemGrid} key='installId'>
                                    <Typography gutterBottom>
                                        {'关联装置'}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        select
                                        name='installId'
                                        onChange={(event) => onhandleChange(event, 'installId')}
                                        InputProps={textFieldDefault}
                                        disabled={editTable}
                                        value={service.installId}
                                    >
                                        {installs && installs.map(e => (
                                            <MenuItem key={e.installId} value={e.installId}>{e.installName}</MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classes.itemGrid}>
                                <Typography gutterBottom>
                                    {'供方'}
                                </Typography>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    options={provideCompaniesSelect.map((e) => e.companyName)}
                                    style={{
                                        marginTop: -15,
                                        width: 160,
                                    }}
                                    onClose={(event, reason) => onhandleNameClose(event, reason, 'serviceSide')}
                                    onChange={(event, value) => onhandleChange(event, 'serviceSide', value)}
                                    value={service.serviceSide || ''}
                                    disabled={editTable}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            value={service.serviceSide || ''}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',

                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {service.serviceCompany &&
                                <Grid key={'serviceDpts'} className={classes.itemGrid}>
                                    <Typography gutterBottom>
                                        {'班组选择'}
                                    </Typography>
                                    <Autocomplete
                                        variant="outlined"
                                        multiple
                                        ChipProps={
                                            { style: { maxHeight: 22 } }
                                        }
                                        filterSelectedOptions
                                        id='serviceDpts'
                                        options={provideDptsSelect}
                                        getOptionLabel={option => option.dptName}
                                        value={service.provideDpts || []}
                                        style={{
                                            minWidth: 150
                                        }}
                                        onChange={onhandleProvideDptsChange}
                                        disabled={editTable}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    id={"serviceDpt-text"}
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid className={classes.itemGrid}>
                                <Typography gutterBottom>
                                    {'需方'}
                                </Typography>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    handleHomeEndKeys
                                    options={requireCompaniesSelect.map((e) => e.companyName)}
                                    style={{
                                        marginTop: -15,
                                        width: 160,
                                    }}
                                    onClose={(event, reason) => onhandleNameClose(event, reason, 'requireSide')}
                                    onChange={(event, value) => onhandleChange(event, 'requireSide', value)}
                                    value={service.requireSide || ''}
                                    disabled={editTable}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            value={service.requireSide || ''}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {service.requireCompany &&
                                <Grid key='requireDpts' className={classes.itemGrid}>
                                    <Typography gutterBottom>
                                        {'班组选择'}
                                    </Typography>
                                    <Autocomplete
                                        variant="outlined"
                                        multiple
                                        ChipProps={
                                            {
                                                style: { maxHeight: 22 }
                                            }
                                        }
                                        filterSelectedOptions
                                        id='requireDpts'
                                        options={requireDptsSelect}
                                        getOptionLabel={option => option.dptName}
                                        value={service.requireDpts || []}
                                        onChange={onhandleRequireDptsChange}
                                        disabled={editTable}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    id={"serviceDpt-text"}
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>

                        <Grid container>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'有效开始日期'}</Typography>
                                <MuiPickersUtilsProvider key={'startDate'} utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        invalidDateMessage={'时间格式错误'}
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='startDate'
                                        InputProps={{
                                            // readOnly: projectData.rate > 0,
                                        }}
                                        value={service.startDate || ''}
                                        onChange={(date, value) => onhandleChange(value, 'startDate')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className={classes.itemGrid}>
                                <Typography>{'有效结束日期'}</Typography>
                                <MuiPickersUtilsProvider key={'endDate'} utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        invalidDateMessage={''}
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='endDate'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={service.endDate || ''}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        <Grid>
                            {(businessGroup.groupType === 'PRODUCE' || businessGroup.groupType === "TRADE" || businessGroup.groupType === 'SALES') &&
                                <MaterialTable
                                    className={classes.materialtable}
                                    tableRef={tableRef}
                                    columns={assemblycolumns()}
                                    data={service.bomRelations}
                                    options={{
                                        showTitle: false,
                                        padding: 'dense',
                                        actionsColumnIndex: -1,
                                        search: false,
                                        paging: false,
                                        maxBodyHeight: `500px`,
                                        rowStyle: rowData => ({
                                            backgroundColor: rowData.parentBom && rowData.parentBom !== '' ? 'FFFFFF' : '#E0F8F7'
                                        }),
                                        defaultExpanded: true,
                                    }}
                                    localization={localization}
                                    style={{
                                        border: '0px solid black',
                                        boxShadow: 'none'
                                    }}
                                    parentChildData={(row, rows) => {
                                        if (row.parentBom && rows.length > 0) {
                                            return rows.find(a => {
                                                return a.bomId === row.parentBom
                                            })
                                        }
                                    }
                                    }
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <Typography variant="h3">
                                                    产品配置
                                                </Typography>
                                                <MTableToolbar {...props} />
                                            </div>
                                        )
                                    }}
                                    editable={{
                                        // onRowUpdateCancelled: rowData => onhandleUpdateBomRelationCancelled(),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                onhandleUpdateBomRelation(newData)
                                                resolve()
                                            }),
                                    }}
                                    actions={roleEditFlag ?
                                        [rowData => ({
                                            icon: 'add_circle',
                                            tooltip: '添加子产品/材料',
                                            onClick: (event, rowData) => {
                                                onhandleAddSubBomRelation(event, rowData)
                                            },
                                            hidden: !rowData.materialId || rowData.materialId === ''
                                        }),
                                        rowData => ({
                                            icon: 'delete_outlined',
                                            tooltip: '删除',
                                            onClick: (event, rowData) => {
                                                onhandleDeleteBomRelation(event, rowData)
                                            },
                                            hidden: _.filter(service.bomRelations, { parentBom: rowData.bomId }).length > 0
                                        }),
                                        {
                                            icon: 'add',
                                            tooltip: '添加产品/材料',
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                onhandleAddBomRelation(event)
                                            },
                                        }
                                        ] : [
                                        ]}
                                >
                                </MaterialTable>
                            }
                        </Grid>
                        <Grid>
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                onChange={onhandleChangeTab}
                            >
                                {service.activities.map(activity => (
                                    <Tab
                                        key={activity.activityId}
                                        value={activity.activityId}
                                        label={
                                            <Typography>
                                                {activity.activityName}
                                                {(activity.commissionService) && <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>}
                                            </Typography>}
                                        className={classes.tab} />
                                ))}
                                {roleEditFlag && <Tab className={classes.tab} value={-1} icon={<Tooltip title="添加服务"><AddIcon /></Tooltip>} style={{ 'minWidth': '30px' }} onClick={onhandleClickAdd} />}
                                {roleEditFlag && <Tab className={classes.tab} icon={<Tooltip title="删除服务"><DeleteIcon /></Tooltip>} style={{ 'minWidth': '30px' }} onClick={onhandleDelete} />}
                            </Tabs>
                            <Grid>
                                {service.activities.map((activity) => (
                                    tabValue === activity.activityId ?
                                        <AttrPanelComponent
                                            key={activity.activityId}
                                            value={tabValue}
                                            index={activity.activityId}
                                            manufactureDicts={manufactureDicts}
                                            activity={activity}
                                            roleEditFlag={roleEditFlag}
                                            onhandleAttrUpdate={onhandleAttrUpdate}
                                            onhandleShareTypeChange={onhandleShareTypeChange}
                                        /> : ''
                                ))}
                            </Grid>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={onhandleCloseMenu}
                            >
                                <MenuItem onClick={onhandleAdd}>{'添加生产活动'}</MenuItem>
                                {roleEditFlag && serviceType !== SERVICE_TYPE_COMMISSION && <MenuItem onClick={onhandleComActAdd}>{'添加外委活动'}</MenuItem>}
                            </Menu>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {roleEditFlag &&
                        <Button onClick={onhandleSave} color="primary"> {'保存'}
                        </Button>}
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MServiceConfigComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    service: PropTypes.object,
    tabValue: PropTypes.number,
    materialList: PropTypes.array,
    manufactureDicts: PropTypes.array,
    subCompanyList: PropTypes.array,
    comDepartmentList: PropTypes.array,
    onhandleChangeTab: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,
    onhandleAdd: PropTypes.func,
    onhandleChange: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleClose: PropTypes.func,
    onhandleSave: PropTypes.func,
    onhandleAttrUpdate: PropTypes.func,
};

MServiceConfigComponent.defaultProps = {
    service: {
        activities: []
    },
    tabValue: 0,
    materialList: [],
    businessGroupList: [],
    manufactureDicts: [],
    materialGroupTree: [],
    rawMaterialGroupTree: [],
    serviceList: [],
    provideCompaniesSelect: [],
    provideDptsSelect: [],
    requireCompaniesSelect: [],
    requireDptsSelect: [],
    roleEditFlag: true,
};

export default withStyles(MServiceConfigComponent);