import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserSelectPage from '@/components/ModalPage/UserSelectPage';
import {
  initUserSelectList,
  setSearch,
  resetSearch,
  getUserList,
  setSelectedUsers,
  exitSelected,
  resetSearchField
} from '@/redux/modules/userSelect';
// import { getTreePartById } from '@/utils/constant';
// import { getCompanyId } from "@/utils/userUtils";
import { cleanmodal } from '@/redux/modules/modal';
import _ from 'lodash';

class UserSelectPageContainer extends Component {
  static propTypes = {
    userList: PropTypes.object,
    search: PropTypes.object,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    userProfile: PropTypes.object,
    companyTree: PropTypes.array,
    companyList: PropTypes.array,
    currentUserInfo: PropTypes.object,
  }

  componentDidMount() {
    this.props.onhandleInit();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'company':
        if (value && this.props.search.companyId !== value) {
          newSearch = {
            ...this.props.search,
            companyId: value.target.value,
            dptId: ''
          };
          this.props.onhandleUpdateEditSearch(newSearch);
          this.props.onhandleResetSearchField(value.target.value, 'company');
        } else {
          newSearch = {
            ...this.props.search,
            companyId: null,
            dptId: ''
          };
          this.props.onhandleUpdateEditSearch(newSearch);
          this.props.onhandleResetSearchField(null, 'company');
        }
        break;
      case 'department':
        newSearch = {
          ...this.props.search,
          dptId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'roleGroup':
        newSearch = {
          ...this.props.search,
          groupId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        this.props.onhandleResetSearchField(value.target.value, 'roleGroup');
        break;
      case 'role':
        newSearch = {
          ...this.props.search,
          roleId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'userName':
        newSearch = {
          ...this.props.search,
          userName: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'name':
        newSearch = {
          ...this.props.search,
          name: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleInnerConfirm = (row) => {
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    if (parameters.callback) {
      parameters.callback(row)
    }
  };

  handleConfirm = () => {
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    if (parameters.callback) {
      parameters.callback()
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          // console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };

  // trimCompanyTree() {
  //   let companyId = null
  //   if (this.props.parameters.companyId) {
  //     companyId = this.props.parameters.companyId
  //   } else {
  //     companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
  //   }
  //   let tree = getTreePartById(companyId, this.props.companyTree);
  //   return tree
  // }
  filterCompany(companyList) {
    const { search } = this.props;
    let parameters = this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters
    const filtedList = companyList
      .filter(company => {
        if (Array.isArray(parameters.companyId) && parameters.companyId.length > 0) {
          return parameters.companyId.includes(company.companyId);
        } else if (!isNaN(parameters.companyId)) {
          return company.companyId === parameters.companyId
        }
        return true;
      })
    // 检查搜索项是否在选项内
    if (filtedList.length > 0 && search.companyId &&
      _.findIndex(filtedList, { 'companyId': search.companyId }) < 0) {
      const newSearch = {
        ...this.props.search,
        companyId: filtedList[0].companyId,
        dptId: ''
      };
      this.props.onhandleUpdateEditSearch(newSearch);
    }
    return filtedList;
  }

  render() {
    return (
      <UserSelectPage
        search={this.props.search}
        parameters={this.props.innerParam && Object.keys(this.props.innerParam).length > 0 ? this.props.innerParam : this.props.parameters}
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleChange={this.handleChange.bind(this)}
        onhandleClearSearch={this.props.onhandleClearSearch.bind(this)}
        userList={this.props.userList}
        // companyTree={this.trimCompanyTree()}
        companyList={this.filterCompany(this.props.companyList)}
        departmentList={this.props.departmentList}
        groupList={this.props.groupList}
        roleList={this.props.roleList}
        fullDepartmentList={this.props.fullDepartmentList}
        // fullRoleList={this.props.fullRoleList}
        onhandleSearch={this.props.onhandleSearch.bind(this)}
        onhandleSetSelected={this.props.onhandleSetSelected.bind(this)}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        onhandleConfirm={this.handleConfirm.bind(this)}
        onhandleInnerConfirm={this.handleInnerConfirm.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedUsers: state.userSelect.selectedUsers,
    userList: state.userSelect.userList,
    search: state.userSelect.search,
    parameters: state.modal.parameters,
    // companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    fullDepartmentList: state.constant.departments,
    // fullRoleList: state.userSelect.fullRoleList,
    groupList: state.userSelect.groupList,
    roleList: state.userSelect.roleList,
    departmentList: state.userSelect.departmentList,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInit: () => {
      dispatch(initUserSelectList());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: () => {
      dispatch(getUserList());
    },
    onhandleResetSearchField: (value, field) => {
      dispatch(resetSearchField(value, field));
    },
    onhandleSetSelected: (value) => {
      dispatch(setSelectedUsers(value));
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSelectPageContainer);
