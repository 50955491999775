import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    flexgrow: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    positive: {
        color: theme.palette.success.dark
    },
    negative: {
        color: theme.palette.error.dark
    }
});


export default withStyles(styles);