import {createAction, handleActions} from 'redux-actions';
import {push} from 'connected-react-router';
import * as _ from 'lodash';
import {createhttpMessage, createMessage} from './message';

const defaultState = {
    goingForm: {}, //用户正在的设备变更单
    goingFormDtls: [], //用户正在的设备变更单明细
    //搜索表单
    searchForms: {
        total: 0,
        list: []
    },
    searchCondition: {},
    page: 0,
    pageSize: 15,
    form: {},//单个表单

}

/**
 *  attrType
 */
export const onhandleSetpage = createAction('@@xcloud/devicefrom/onhandleSetpage',)
export const setPage = createAction('@@xcloud/devicefrom/setPage',)
export const appendFormDtls = createAction('@@xcloud/deviceform/appendFormDtls',
    (formDtl) => (dispatch, getState, httpClient) => {
        let dtls = _.clone(getState().deviceForm.goingFormDtls)
        let dtlKey = formDtl.attrType + formDtl.attrSubType + formDtl.attrName + formDtl.attrDtlTab + formDtl.attrDtlId
        let dtl = _.find(dtls, {'dtlKey': dtlKey});
        if (dtl) {
            _.remove(dtls, {'dtlKey': dtlKey})
            dtl['attrNewName'] = formDtl.attrNewName
            dtl['attrNewValue'] = formDtl.attrNewValue
        } else {
            dtl = {
                ...formDtl,
                'dtlKey': dtlKey,
                'dtlId': dtlKey
            }
        }
        dispatch(setGoingFormDtls(_.concat(dtls, dtl)))
    });
export const setGoingForm = createAction('@@xcloud/deviceform/setGoingForm');
export const setGoingFormDtls = createAction('@@xcloud/deviceform/setGoingFormDtls');
export const resetGoingDeviceForm = createAction('@@xcloud/deviceform/resetDeviceForm',
    () => (dispatch, getState, httpClient) => {
        if (getState().devices.currentDevice &&
            getState().deviceForm.goingForm &&
            getState().devices.currentDevice.deviceId !== getState().deviceForm.goingForm.deviceId) {
            dispatch(setGoingForm({}))
            dispatch(setGoingFormDtls([]))
        }
    });
export const setPageSize = createAction('@@xcloud/oppo/setPageSize');
export const setSearchCondition = createAction('@@xcloud/deviceform/setSearchCondition');
export const setSearchForms = createAction('@@xcloud/deviceform/setSearchForms');
export const getSearchForms = createAction('@@xcloud/deviceform/getSearchForms',
    (query) => (dispatch, getState, httpClient) => {
        let pageSize = getState().deviceForm.pageSize
        let url = `/api/deviceforms`;
        if (query && query.offset) {
            url += "?offset=" + query.offset;
        } else {
            url += "?offset=0";
        }
        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 15)
        }
        if (query && query.sort) {
            url += "&sort=" + query.sort;
        }
        if (query && _.isInteger(query.deviceId)) {
            url += "&deviceId=" + query.deviceId;
        }
        if (query && _.isInteger(query.accId)) {
            url += "&accId=" + query.accId;
        }
        if (query && query.formType) {
            url += "&formType=" + query.formType;
        }
        if (query && query.formNo) {
            url += "&formNo=" + query.formNo;
        }
        if (query && _.isInteger(query.companyId)) {
            url += "&companyId=" + query.companyId;
        }
        if (query && query.formName) {
            url += "&formName=" + encodeURIComponent(query.formName);
        }
        if (query && query.status) {
            url += "&status=" + query.status;
        }

        return httpClient.get(url, {headers: {need_loading: true}})
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setSearchForms(res.data));
                }
                return res.data
            }).catch(err => {
                let msg = `获取设备表单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const createDeviceForm = createAction('@@xcloud/devicefrom/createDeviceForm',
    (form) => (dispatch, getState, httpClient) => {
        let url = `/api/deviceforms`;
        let validateForm = _.omit(_.pickBy(form), ['createTime', 'updateTime'])
        validateForm['formDtlList'] = _.map(form.formDtlList, (d) => {
            return _.omit(_.pickBy(d), ['createTime', 'updateTime', 'updateVals', 'dtlId'])
        })
        return httpClient.post(url, validateForm)
            .then((res) => {
                dispatch(createMessage('success', '表单创建[' + form.formName + ']成功'));
                return res.data;
            })
            .catch(err => {
                let msg = '表创建失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    });


export const updateDeviceForm = createAction('@@xcloud/devicefrom/updateDeviceForm',
    (formId, form) => (dispatch, getState, httpClient) => {
        let url = `/api/deviceforms/${formId}`
        let validateForm = _.omit(_.pickBy(form), ['createTime', 'updateTime', 'updateVals'])
        validateForm['formDtlList'] = _.map(form.formDtlList, (d) => {
            return _.omit(_.pickBy(d), ['createTime', 'updateTime', 'updateVals'])
        })
        return httpClient.patch(url, validateForm)
            .then((res) => {
                dispatch(createMessage('success', '表单更新[' + form.formName + ']成功'));
                return res.data;
            })
            .catch(err => {
                let msg = '表更新失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    });

export const deleteDeviceForm = createAction('@@xcloud/devicefrom/deleteDeviceForm',
    (formId) => (dispatch, getState, httpClient) => {
        let url = `/api/deviceforms/${formId}`
        return httpClient.delete(url)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createMessage('success', '表单删除成功'));
                }
                return res.data;
            })
            .catch(err => {
                let msg = '表单删除失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const setForm = createAction('@@xcloud/deviceform/setForm');
export const getForm = createAction('@@xcloud/deviceform/getForm',
    (formId) => (dispatch, getState, httpClient) => {
        let url = `/api/deviceforms/${formId}`
        return httpClient.get(url, {headers: {need_loading: true}})
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setForm(res.data));
                }
            }).catch(err => {
                let msg = `获取设备表单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const routeToFormDetail = createAction(
    '@@xcloud/operation/routeToFormDetail',
    (formId) => (dispatch) => {
        dispatch(push(`/deviceForm/${formId}`))
    }
);

export const routeToFormList = createAction(
    '@@xcloud/operation/routeToFormList',
    (formId) => (dispatch) => {
        dispatch(push(`/deviceFormList`))
    }
);

export const routeToFormInfo = createAction(
    '@@xcloud/operation/routeToFormInfo',
    (formId) => (dispatch) => {
        dispatch(push(`/deviceForm/${formId}`))
    }
);


export const handleDeviceFormProcess = createAction('@@xcloud/devicefrom/handleDeviceFormProcess',
    (deviceId, formType, formId, dto) => (dispatch, getState, httpClient) => {
        let url = `/api/deviceforms/devices/${deviceId}/approvals/actions/${formType}/${formId}`;
        dto['approvalDTO'] = _.omit(_.pickBy(dto.approvalDTO), ['createTime', 'updateTime'])
        return httpClient.post(url, dto)
            .then((res) => {
                dispatch(createMessage('success', '表单流程操作成功'));
                return res.data;
            })
            .catch(err => {
                let msg = '表单流程操作失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    });

export const deviceFormReducer = handleActions(
    {
        [setGoingForm]: (state, {payload: value}) => {
            return {
                ...state,
                goingForm: value
            };
        },
        [setGoingFormDtls]: (state, {payload: value}) => {
            return {
                ...state,
                goingFormDtls: value
            };
        },
        [setSearchCondition]: (state, {payload: value}) => {
            return {
                ...state,
                searchCondition: value
            };
        },
        [setPageSize]: (state, {payload: value}) => {
            return {
                ...state,
                pageSize: value
            };
        },
        //setPage

        [setPage]: (state, {payload: value}) => {
            return {
                ...state,
                page: value
            };
        },

        [setSearchForms]: (state, {payload: value}) => {
            return {
                ...state,
                searchForms: value
            };
        },
        [setForm]: (state, {payload: value}) => {
            return {
                ...state,
                form: value
            };
        },

    },
    defaultState
)

export default deviceFormReducer;