import React, { useState, useEffect } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { localization, options } from "../../../utils/mtable";
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import { getDateByTimestamp, getDateTimeAfterSomeMins } from '../../../utils/datetime';
import * as _ from 'lodash';
import {
    Grid,
    TextField,
    Button,
    Typography,
    TablePagination,
    Card,
    IconButton,
    Avatar,
    MenuItem,
    Chip
} from '@material-ui/core';
import {
    Add,
    SaveAlt,
    ExpandMore,
    ExpandLess
} from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getMoment } from '../../../utils/datetime'
// import { stubFalse } from 'lodash';

const PlanListComponent = props => {
    const {
        classes,
        search,
        userProfile,
        currentUserInfo,
        companyTree,
        companyList,
        inspectionDictionaries,
        planList,
        onhandleSelect,
        onhandleClearSearch,
        onhandleSearch,
        onhandleSearchCurrent,
        getPlanList,
        onSizeChange,
        onhandleView,
        onhandleRecord,
        onhandleDateChange,
        routeList,
        departmentList,
        users,
        onhandleCreate,
        onhandleUpdate,
        onhandleDelete,
        onhandleExport
    } = props;

    const [page, setPage] = useState(0);
    const [expand, setExpand] = useState(false);
    const [tableHeight, setTableHeight] = useState(null);

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }
        return false
    }

    // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
    const noOptionDisabled = (optionList) => {
        if (optionList && optionList.length > 0) {
            return false
        }

        return true
    }

    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE" && t.code !== 'OIT05') {
                result.push({ label: t.name, value: t.code })
            }
        })

        return result;
    };

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_plan' })
        return permission && permission.action === 'W'
    };

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);


    const getStatusData = () => {
        let result = []
        result.push({ label: '未开始', value: 'new' })
        result.push({ label: '进行中', value: 'active' })
        result.push({ label: '已完成', value: 'done' })
        result.push({ label: '已补录', value: 'expired' })
        result.push({ label: '已提交', value: 'pending' })

        return result
    }

    const getDptData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        let item = _.filter(departmentList, function (o) { return _.toInteger(company) === o.companyId })
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.dptName, value: t.dptId })
            })
        }
        return result
    }

    const getRouteData = (companyId, inspectionType) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        const condition = { companyId: _.toInteger(company) }
        if (inspectionType) condition.inspectionType = inspectionType

        let item = _.filter(routeList, condition)
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.routeName, value: t.routeId })
            })
        }
        return result
    }

    const getUsersData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId
        let item = _.filter(users.list, function (o) { return company === o.companyId })
        let result = []
        if (item && item.length) {
            return item
        }
        return result
    }

    const renderCompany = (row) => {
        let item = _.filter(companyList, function (o) { return row.companyId === o.value })
        if (item && item.length) {
            return item[0].label
        } else {
            return ''
        }
    };

    const renderDpt = (row) => {
        let dptNames = ''
        let dpts = row.onsitePlanDpt;
        for (let i in dpts) {
            let item = _.filter(departmentList, function (o) { return dpts[i].dptId === o.dptId })

            if (item && item.length) {
                dptNames += "," + item[0].dptName;
            }
        }
        return dptNames.substring(1);
        // let item = _.filter(companyList, function (o) { return row.companyId === o.value })
        // if (item && item.length) {
        //     return item[0].label
        // } else {
        //     return ''
        // }
        // return ''
    };

    const renderStatus = (row) => {
        let text = ''
        const deadlineMoment = getMoment()(row.startTime).add(row.expiredAt, 'minutes');
        switch (row.status) {
            case 'new':
                text = '未开始'
                if (getMoment()().isAfter(deadlineMoment)) {
                    text = '已过期'
                }
                break;
            case 'active':
                text = '进行中'
                if (getMoment()().isAfter(deadlineMoment)) {
                    text = '已过期'
                }
                break;
            case 'pending':
                text = '已提交'
                break;
            case 'done':
                text = '已完成'
                break;
            case 'invalid':
                text = '已过期'
                break;
            case 'expired':
                text = '已补录'
                break;
            default:
                break;
        }

        return text
    }

    // 状态渲染
    const renderRowStatusDisplay = (row) => {
        let status = renderStatus(row)
        let cssClassName = classes.new

        switch (status) {
            case '未开始':
                cssClassName = classes.new
                break;
            case '进行中':
                cssClassName = classes.active
                break;
            case '已过期':
                cssClassName = classes.expired
                break;
            case '已完成':
                cssClassName = classes.done
                break;
            case '已补录':
                cssClassName = classes.expiredDone
                break;
            case '已提交':
                cssClassName = classes.pending
                break;
            default:
                cssClassName = classes.new
        }
        return cssClassName
    };

    const assemblycolumns = () => {
        return [
            {
                title: '',
                field: 'planId',
                render: row => row ? (
                    row.exceptionList && row.exceptionList.length > 0 && row.status === 'done' ? (<Avatar className={classes.pic1} >异</Avatar>) : ''
                ) : '',
                editable: 'never',
                width: 26,
                align: 'center',
                cellStyle: {
                    width: 26,
                    maxWidth: 26,
                    // color: '#FFF'
                    padding: '6px 2px',
                },
                headerStyle: {
                    width: 26,
                    maxWidth: 26,
                    padding: '6px 2px',
                },
            },
            {
                title: '巡检类型',
                field: 'inspectionType',
                render: row => row ? <Typography>{renderInspectionType(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '所属公司',
                field: 'companyName',
                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检路线',
                field: 'routeName',
                render: row => row ? <Typography>{row.routeName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '执行班组',
                field: 'dptName',
                render: row => row ? <Typography>{renderDpt(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检人员',
                field: 'inspectorName',
                render: row => row ? <Typography>{row.inspectorName || '未指派'}</Typography> : '',
                editable: 'never'
            },
            {
                title: '预计开始时间',
                field: 'startTime',
                render: row => row ? <Typography>{renderStartTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '预计结束时间',
                field: 'startTime',
                render: row => row ? <Typography>{renderExpectEndTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '实际结束时间',
                field: 'endTime',
                render: row => row ? <Typography>{renderEndTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                // render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                render: row => row ? <Chip size="small" className={renderRowStatusDisplay(row)} label={renderStatus(row)} /> : '',
                editable: 'never',
                align: 'center',
                width: 100,
            }
        ];
    };

    const renderInspectionType = (row) => {
        let item = _.filter(inspectionDictionaries, function (o) { return row.inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    };

    const renderStartTime = (row) => {
        return row.startTime ? getDateByTimestamp(row.startTime, true) : ''
    }

    const renderEndTime = (row) => {
        return row.endTime ? getDateByTimestamp(row.endTime, true) : ''
    }

    const renderExpectEndTime = (row) => {
        return row.startTime && row.expiredAt ? getDateTimeAfterSomeMins(row.startTime, row.expiredAt, true) : ''
    }

    const validateAction = (rowData) => {
        // 对无法巡检的计划开放编辑权限
        // true: 隐藏
        if (rowData.inspectionType === 'OIT04') {
            return true
        }
        let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
        let tomorrowTime = new Date(tomorrow).getTime();
        let startTime = new Date(rowData.startTime).getTime()

        if (rowData.ruleId === null && tomorrowTime < startTime && rowData.status === 'new') {
            return false
        }

        return true
    }

    const renderIndoorInspectionIcon = (rowData) => {
        const { status } = rowData;
        if (['new'].includes(status)) {
            return 'create'
        }
        if (['active'].includes(status)) {
            return rowData.inspector === userProfile.userId ? 'create' : 'list_alt'
        }
        if (['pending'].includes(status)) {
            return rowData.confirmRole === currentUserInfo.roleId ? 'assignment_turned_in' : 'list_alt'
        }
        if (['done', 'expired'].includes(status)) {
            return 'list_alt'
        }
    }

    const renderIndoorInspectionTooltip = (rowData) => {
        const { status } = rowData;
        if (['new'].includes(status)) {
            return '认领并开始内操记录'
        }
        if (['active'].includes(status)) {
            return rowData.inspector === userProfile.userId ? '继续内操记录' : '查看内操记录'
        }
        if (['pending'].includes(status)) {
            return rowData.confirmRole === currentUserInfo.roleId ? '巡检记录复核' : '查看内操记录'
        }
        if (['done', 'expired'].includes(status)) {
            return '查看内操记录'
        }
    }

    const showIndoorInspectionEdit = (plan) => {
        const { onsitePlanDpt, inspector, startTime, status } = plan;
        const planStarted = getMoment()().isSameOrAfter(getMoment()(startTime));
        let permission = false;
        // 未认领
        if (['new'].includes(status)) {
            if (inspector) {
                permission = userProfile.userId === inspector
            } else {
                if (onsitePlanDpt && onsitePlanDpt.length) {
                    const userDptId = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0].dptId : null;
                    permission = _.findIndex(onsitePlanDpt, { 'dptId': userDptId }) >= 0;
                }
            }
            return permission && planStarted;
        }
        // 进行中或已完成
        if (['active', 'done', 'expired', 'pending'].includes(status)) {
            return true
        }
    }

    return (
        <Card className={classes.content}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'巡检计划'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            所属单位
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-company"
                            select
                            value={search.companyId || 0}
                            onChange={(event) => onhandleSelect(event, 'companyId')}
                            variant="outlined"
                            disabled={companyDisabled()}
                        >
                            <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                            {_.sortBy(companyList, 'companyId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            巡检类型
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-inspectiontype"
                            select
                            value={search.inspectionType}
                            onChange={(event) => onhandleSelect(event, 'inspectionType')}
                            variant="outlined"
                        >
                            <MenuItem key={1} value={""}>{'不限'}</MenuItem>
                            {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            巡检路线
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-routeid"
                            select
                            value={search.routeId}
                            onChange={(event) => onhandleSelect(event, 'routeId')}
                            variant="outlined"
                            disabled={noOptionDisabled(getRouteData(search.companyId, search.inspectionType))}
                        >
                            <MenuItem key={1} value={""}>{'不限'}</MenuItem>
                            {_.sortBy(getRouteData(search.companyId, search.inspectionType), 'routeId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {Boolean(expand) && <React.Fragment>
                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                执行班组
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-dptid"
                                select
                                value={search.dptId}
                                onChange={(event) => onhandleSelect(event, 'dptId')}
                                variant="outlined"
                                disabled={noOptionDisabled(getDptData(search.companyId))}
                            >
                                <MenuItem key={1} value={""}>{'不限'}</MenuItem>
                                {_.sortBy(getDptData(search.companyId), 'dptId').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                计划状态
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                select
                                value={search.status}
                                onChange={(event) => onhandleSelect(event, 'status')}
                                variant="outlined"
                            >
                                <MenuItem key={1} value={""}>{'不限'}</MenuItem>
                                {_.sortBy(getStatusData(), 'status').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                巡检人
                            </Typography>
                            <Autocomplete
                                className={classes.textFieldInput}
                                size={'small'}
                                options={getUsersData(search.companyId)}
                                onChange={(e, newValue) => onhandleSelect(newValue, 'userId')}
                                //onClose={event => onhandleSelect(null, 'userId')}
                                getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                getOptionSelected={(option, value) => {
                                    return option.userId === value.userId
                                }}
                                noOptionsText='无可选人员'
                                value={search.userId}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    placeholder="搜索并选择巡检人"
                                />}
                            />
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                开始时间
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDateTimePicker
                                    autoOk
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm"
                                    name='startTimeselect'
                                    value={search.fromDate}
                                    onChange={value => onhandleDateChange(value, 'fromDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '开始时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: '' }}
                                                    onClick={() => onhandleDateChange(null, 'fromDate')}
                                                    disabled={Boolean(!search.fromDate)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                结束时间
                            </Typography>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN} >
                                <KeyboardDateTimePicker
                                    autoOk
                                    disableToolbar
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    variant="inline"
                                    inputVariant="outlined"
                                    ampm={false}
                                    format="yyyy/MM/dd HH:mm"
                                    name='endTimeselect'
                                    value={search.toDate}
                                    onChange={value => onhandleDateChange(value, 'toDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': '结束时间',
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { paddingRight: 0 },
                                        startAdornment:
                                            (
                                                <IconButton
                                                    style={{ order: 1, display: '' }}
                                                    onClick={() => onhandleDateChange(null, 'toDate')}
                                                    disabled={Boolean(!search.toDate)}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }}
                                    InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2, marginLeft: 0 }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </React.Fragment>}

                </Grid>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>

                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                        >
                            搜索
                        </Button>
                        <Button variant="contained" color="secondary" style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
                            onClick={onhandleSearchCurrent}
                        >
                            近期内操巡检
                        </Button>
                        <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >
                            重置
                        </Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>

                        {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={onhandleCreate}
                        >
                            新建
                        </Button>}
                        <Button variant='contained' color='primary' startIcon={<SaveAlt />} style={{ marginLeft: '10px' }}
                            onClick={onhandleExport}
                        >
                            导出
                        </Button>
                    </Grid>
                </Grid >

            </Grid>

            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={planList.list}
                    options={{
                        ...options,
                        pageSize: _.toInteger(search.size),
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        maxBodyHeight: `${tableHeight}px`,
                        minBodyHeight: `${tableHeight}px`,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[15, 30, 50]}
                                rowsPerPage={_.toInteger(search.size)}
                                count={planList.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getPlanList({ offset: pageNum * search.size, size: search.size, sort: "-start_time" })

                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    getPlanList({ offset: 0, size: event.target.value, sort: "-start_time" })
                                }}
                            />
                        )
                    }}
                    actions={checkPermission() ? [rowData => ({
                        icon: 'edit',
                        tooltip: '编辑',
                        hidden: validateAction(rowData),
                        onClick: (event, rowData) => {
                            onhandleUpdate(event, rowData)
                        }
                    }),
                    rowData => ({
                        icon: 'delete_outlined',
                        tooltip: '删除',
                        hidden: validateAction(rowData),
                        onClick: (event, rowData) => {
                            onhandleDelete(event, rowData)
                        }
                    }),
                    rowData => ({
                        icon: 'description',
                        tooltip: '查看巡检记录',
                        hidden: rowData.status === 'new',
                        onClick: (event, rowData) => {
                            onhandleView(event, rowData)
                        }
                    }),
                    rowData => ({
                        // 内操
                        icon: renderIndoorInspectionIcon(rowData),
                        tooltip: renderIndoorInspectionTooltip(rowData),
                        hidden: rowData.inspectionType !== 'OIT02',
                        disabled: !showIndoorInspectionEdit(rowData),
                        onClick: (event, rowData) => {
                            onhandleRecord(event, rowData)
                        }
                    }),
                        // {
                        //     icon: 'save_alt',
                        //     tooltip: '导出',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         onhandleExport() 
                        //     }
                        // },
                        // {
                        //     icon: 'add',
                        //     tooltip: '新建',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         onhandleCreate()
                        //     }
                        // }
                    ] : [rowData => ({
                        icon: 'description',
                        tooltip: '查看巡检记录',
                        hidden: rowData.status === 'new',
                        onClick: (event, rowData) => {
                            onhandleView(event, rowData)
                        }
                    }),
                    rowData => ({
                        // 内操
                        icon: renderIndoorInspectionIcon(rowData),
                        tooltip: renderIndoorInspectionTooltip(rowData),
                        hidden: rowData.inspectionType !== 'OIT02',
                        disabled: !showIndoorInspectionEdit(rowData),
                        onClick: (event, rowData) => {
                            onhandleRecord(event, rowData)
                        }
                    }),
                        // {
                        //     icon: 'save_alt',
                        //     tooltip: '导出',
                        //     isFreeAction: true,
                        //     onClick: (event) => {
                        //         onhandleExport()
                        //     }
                        // }
                    ]
                    }
                >
                </MaterialTable>
            </Grid>

        </Card>
    );
};

PlanListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    planList: PropTypes.object.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleClearSearch: PropTypes.func.isRequired,
    onhandleSearch: PropTypes.func.isRequired,
    getPlanList: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onhandleView: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    routeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    users: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleExport: PropTypes.func.isRequired
};

export default withRouter(withStyles(PlanListComponent));