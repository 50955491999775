import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
// import { push } from 'connected-react-router';
import { getCompanyId } from "../../utils/userUtils";
import _ from 'lodash';
import { getTreePartById } from '../../utils/constant';
import { csv } from '../../utils/exportfile';
import { getDateByTimestamp, getMoment as moment } from '../../utils/datetime';
import { getPointList, setIndoorInspectionItemList } from '../../redux/modules/onsiteInspection';
import { getHistoryRecordList, setRecordHistoryList, getDeviceUnit } from '../../redux/modules/onsiteInspectionPlan';
import { createloading, cleanloading } from '@/redux/modules/loading';

const defaultState = {
    search: {
        companyId: "",
        inspectionType: "",
        routeId: "",
        dptId: "",
        status: "",
        userId: null,
        fromDate: null,
        toDate: null,
        isCurrent: false,
        offset: 0,
        size: 5
    },
    routeList: [],
    departmentList: [],
    users: {
        total: 0,
        list: []
    },
    currentPlan: {
        onsitePlanDpt: [{ dptId: '' }],
    },
    recordReportList: [],
    planPanelOpen: false,
    leaders: [],
    planError: {},
    searchUrl: '',
    unit: [],
    unitTree: [],
};

export const getUsers = createAction('@@xcloud/onsiteInspectionRecordReport/getUsers',
    () => (dispatch, getState, httpClient) => {
        let url = '/api/users?offset=0&size=0&status=active';
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
                return res;
            }).catch(err => {
                let msg = '巡检人列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            });
    });

export const getDepartment = createAction('@@xcloud/onsiteInspectionRecordReport/getDepartment',
    () => (dispatch, getState, httpClient) => {
        let url = `/api/departments?offset=0&size=0&status=active&executable=true`;
        return httpClient
            .get(url)
            .then((res) => {
                return res.data.list
            })
            .catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '获取班组信息失败'));
                return []
            });
    });


export const getRouteList = createAction('@@xcloud/onsiteInspectionRecordReport/getRouteList',
    () => (dispatch, getState, httpClient) => {
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    return res.data.list;
                } else {
                    return []
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);

export const exportRecordList = createAction('@@xcloud/onsiteInspectionRecordReport/exportRecordList',
    (planId) => (dispatch, getState, httpClient) => {

        let url = '/api/onsiteinspections/records?offset=0&size=0&sort=update_time&planId=' + planId;
        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {

                let fileName = '巡检记录_' + new Date().getTime()
                let columns = ['装置', '设备编号', '设备名称', '单元', '参数', '值', '单位', '时间']
                let data = []
                if (res.status === 200 && res.data && res.data.list.length > 0) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        let updateTime = res.data.list[i].updateTime ? getDateByTimestamp(res.data.list[i].updateTime, true) : ''
                        let cell = [
                            res.data.list[i].apparatusName,
                            res.data.list[i].deviceNo,
                            res.data.list[i].deviceName,
                            res.data.list[i].unitName,
                            res.data.list[i].itemName,
                            res.data.list[i].val,
                            res.data.list[i].itemUnit,
                            updateTime
                        ];
                        data.push(cell)
                    }
                }
                dispatch(csv(fileName, columns, data));

            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检记录获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

/**
 * 初始化巡检统计页面
 */
export const initRecordReportPage = createAction('@@xcloud/onsiteInspectionRecordReport/initRecordReportPage',
    (query, reload) => (dispatch, getState) => {
        dispatch(createloading())

        const promiseArr = [
            //初始化页面头部
            dispatch(getDepartment()),
            dispatch(getRouteList()),
            // dispatch(getUsers()),
            dispatch(getDeviceUnit()),
            dispatch(setPlanPanelOpen(false))
        ]
        if (!reload) {
            promiseArr.push(
                dispatch(resetSearch())
            )
        }

        Promise.all(promiseArr).then(() => {
            dispatch(cleanloading())
        }).catch(err => {
            debugger
            dispatch(cleanloading())
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化巡检统计失败'));
            return [];
        });
    });

export const resetSearch = createAction('@@xcloud/onsiteInspectionRecordReport/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().deviceUseStatus.search;

        // let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        const companyId = getState().auth.currentUserInfo.companyId;
        const company = _.find(getState().constant.companies, { 'companyId': companyId });
        const hasPcomp = company && company.pid;

        let newSearch = {
            ...search,
            companyId: hasPcomp ? companyId : 0,
            inspectionType: "",
            routeId: "",
            dptId: "",
            status: "",
            // userId: null,
            fromDate: moment()().subtract(1, 'days').set({ 'hour': 8, 'minute': 0, 'second': 0, 'millisecond': 0 }).valueOf(),
            toDate: moment()().set({ 'hour': 8, 'minute': 0, 'second': 0, 'millisecond': 0 }).valueOf(),
            // isCurrent: false,
            // offset: 0,
            // size: 0
        }

        dispatch(setRecordHistoryList([]));
        dispatch(setIndoorInspectionItemList([]));
        dispatch(setSearch(newSearch));
    }
);

export const setSearch = createAction('@@xcloud/onsiteInspectionRecordReport/setSearch');

export const setRecordReportList = createAction('@@xcloud/onsiteInspectionRecordReport/setRecordReportList');

export const setPlanPanelOpen = createAction('@@xcloud/onsiteInspectionRecordReport/setPlanPanelOpen');

// export const initView = createAction('@@xcloud/onsiteInspectionRecordReport/initView',
//     (data) => (dispatch, getState) => {
//         let plans = getState().onsiteInspectionRecordReport.planList;
//         let plan = _.find(plans.list, ['planId', data.planId])
//         dispatch(setCurrentPlan(plan));
//         dispatch(push('/qc/record/' + plan.planId));
//     }
// );

export const getRecordReport = createAction('@@xcloud/onsiteInspectionRecordReport/getRecordReport',
    (query) => (dispatch, getState) => {

        dispatch(createloading());
        dispatch(setRecordHistoryList([]));
        dispatch(setIndoorInspectionItemList([]));
        Promise.all([
            dispatch(getHistoryRecordList(0, query.routeId, null, query.fromDate, query.toDate)),
            dispatch(getPointList(query.routeId, false)),
        ]).then(() => {
            // 从隔壁取
            const inspectionItemList = getState().onsiteInspection.inspectionItemList;
            const recordHistoryList = getState().onsiteInspectionPlan.recordHistoryList;
            const newList = [];
            if (inspectionItemList && inspectionItemList.length) {
                // 组装item <-> history
                inspectionItemList.forEach(item => {
                    const newItem = _.cloneDeep(item);

                    newItem['err'] = '';

                    Object.keys(recordHistoryList).forEach((key, index) => {
                        const history = recordHistoryList[key];
                        newItem[key] = history[item.itemKey];
                    })
                    newList.push(newItem);
                })
            }

            dispatch(cleanloading());
            dispatch(setIndoorInspectionItemList(newList));
            // dispatch(setRecordReportList(newList));
        }).catch(err => {
            debugger
            dispatch(cleanloading());
            dispatch(createhttpMessage(err.response ? err.response : '', '加载巡检统计失败'));
            return [];
        });
    });

export const exportRecordListCollect = createAction('@@xcloud/onsiteInspectionRecordReport/exportRecordListCollect',
    () => (dispatch, getState, httpClient) => {

        dispatch(createloading());
        // dispatch(setRecordHistoryList([]));
        // dispatch(setIndoorInspectionItemList([]));
        Promise.all([
            // 改直接前台用已生成报表
            // dispatch(getRecordReport(getState().onsiteInspectionRecordReport)),
        ]).then(() => {
            // 从隔壁取
            const inspectionItemList = getState().onsiteInspection.inspectionItemList;
            const recordHistoryList = getState().onsiteInspectionPlan.recordHistoryList;
            // 
            const search = getState().onsiteInspectionRecordReport.search;
            const unitList = getState().onsiteInspectionPlan.unit;
            const routeList = getState().onsiteInspectionRecordReport.routeList;
            const currentRoute = _.find(routeList, { routeId: getState().onsiteInspectionRecordReport.search.routeId })

            const fromMoment = search.fromDate ? moment()(search.fromDate) : moment()();
            const toMoment = search.fromDate ? moment()(search.toDate) : moment()();

            const fromMomentStr = fromMoment.format('YYYYMMDD')
            const toMomentStr = toMoment.format('YYYYMMDD')

            const dateStr = fromMoment === toMoment ? fromMomentStr : fromMomentStr + '-' + toMomentStr;

            let fileName = currentRoute.routeName + dateStr + '_巡检记录';

            let columns = ['设备', '检查参数', '单位', '运行参考值', '停机参考值'];
            // 时间列
            if (recordHistoryList && Object.keys(recordHistoryList).length) {
                Object.keys(recordHistoryList)
                    .sort((a, b) => {
                        return moment()(a).valueOf() - moment()(b).valueOf()
                    })
                    .forEach(key => {
                        columns.push(key)
                    })
            } else {
                columns.push('无历史记录')
            }
            // 数据
            let data = []
            if (inspectionItemList && inspectionItemList.length > 0) {
                inspectionItemList.forEach((itemList) => {

                    let itemName = itemList.itemNameAlias ? itemList.itemNameAlias : (itemList.itemName ? itemList.itemName : '')
                    // 单位
                    let itemUnit = '';
                    if (unitList && unitList.length > 0) {
                        let value = _.find(unitList, (o) => (o.code === itemList.itemUnit))
                        if (value) {
                            itemUnit = value.name
                        }
                    }
                    // 标准值
                    let standardTips = '';
                    if (itemList.template && itemList.standardValue) {
                        if (itemList.template.indexOf('|') > -1 && itemList.standardValue.indexOf(',') > -1) {

                            let logicList = itemList.template.split('|');
                            let logic1 = logicList[0].split('{')[0];
                            let logic2 = logicList[1].split('{')[0];

                            let valueList = itemList.standardValue.split(',');
                            let value1 = valueList[0];
                            let value2 = valueList[1];

                            standardTips = logic1 + ' ' + value1 + ' 且 ' + logic2 + ' ' + value2
                        } else {
                            let logic = itemList.template.split('{')[0]
                            let value = itemList.standardValue
                            standardTips = logic + value
                        }
                    }
                    //停机标准值
                    let standardOffTips = '';
                    if (itemList.offTemplate && itemList.standardOffValue) {
                        if (itemList.offTemplate.indexOf('|') > -1 && itemList.standardOffValue.indexOf(',') > -1) {

                            let logicList = itemList.offTemplate.split('|');
                            let logic1 = logicList[0].split('{')[0];
                            let logic2 = logicList[1].split('{')[0];

                            let valueList = itemList.standardOffValue.split(',');
                            let value1 = valueList[0];
                            let value2 = valueList[1];

                            standardOffTips = logic1 + ' ' + value1 + ' 且 ' + logic2 + ' ' + value2
                        } else {
                            let logic = itemList.offTemplate.split('{')[0]
                            let value = itemList.standardOffValue
                            standardOffTips = logic + value
                        }
                    }
                    let cell = [
                        itemList.deviceName || '',
                        itemName,
                        // itemList.opentext || '',
                        itemUnit,
                        standardTips,
                        standardOffTips
                    ]
                    // 历史记录
                    if (recordHistoryList && Object.keys(recordHistoryList).length) {
                        Object.keys(recordHistoryList).sort((a, b) => {
                            return moment()(a).valueOf() - moment()(b).valueOf()
                        }).forEach(key => {
                            // 找值
                            // const dateHistory = Object.values(recordHistoryList[key]);
                            // for (const item of dateHistory) {
                            //     if (item.value) {
                            //         cell.push(item.value.toString() || '');
                            //     }
                            // }
                            if (itemList.status === 'invalid') {
                                cell.push('不涉及');
                            } else {
                                cell.push(itemList[key] && itemList[key].value ? itemList[key].value.toString() : '-')
                            }
                        })
                    }
                    data.push(cell)
                })
            }
            // 末行   记录人
            let summaryCells = [
                '',
                '',
                '',
                '',
                '记录人:'
            ]
            if (recordHistoryList && Object.keys(recordHistoryList).length) {
                Object.keys(recordHistoryList)
                    .sort((a, b) => {
                        return moment()(a).valueOf() - moment()(b).valueOf()
                    })
                    .forEach(key => {
                        // 找巡检人
                        const dateHistory = Object.values(recordHistoryList[key]);
                        let inspector = '';
                        for (const item of dateHistory) {
                            if (item.inspector) {
                                inspector = item.inspector;
                                break;
                            }
                        }
                        if (inspector) {
                            summaryCells.push(inspector || '-')
                        } else {
                            summaryCells.push('-')
                        }
                    })
            } else {
                summaryCells.push('无')
            }
            data.push(summaryCells);
            dispatch(csv(fileName, columns, data));

            dispatch(cleanloading());
        }).catch(err => {
            debugger
            dispatch(cleanloading());
            dispatch(createhttpMessage(err.response ? err.response : '', '导出巡检统计失败'));
            return [];
        });
    }
);

/********************* onsiteInspectionRecordReport Reducer *********************/

export const planListReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setRecordReportList]: (state, { payload: value }) => {
            return {
                ...state,
                recordReportList: value
            };
        },
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [getDepartment]: (state, { payload: value }) => {
            return {
                ...state,
                departmentList: value
            };
        },
        [getUsers]: (state, { payload: value }) => {
            return {
                ...state,
                users: value
            };
        },
        [setPlanPanelOpen]: (state, { payload: value }) => {
            return {
                ...state,
                planPanelOpen: value
            };
        },
    },
    defaultState
);

export default planListReducer;