import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { getCompanyId } from "../../utils/userUtils";
import * as _ from 'lodash';
import { getTreePartById } from '../../utils/constant';
import { createloading, cleanloading } from '../modules/loading';

const defaultState = {
    ruleList: {
        total: 0,
        list: []
    },
    routeList: [],
    departmentList: [],
    search: {
        companyId: "",
        inspectionType: "",
        routeId: "",
        dptId: "",
        offset: 0,
        size: 15
    },
    currentRule: {},
    frequency: {
        ruleData: {},
        ruleId: 0,
        freqType: '',
        freqP: '',
        freqC: '',
        timeP: '',
        timeC: '',
        customTime: '',
        customTimes: [],
        warnTime1: '',
        warnTime2: ''
    },
    frequencyOpen: false,
    ruleListByRouteId: {
        total: 0,
        list: []
    },
};

/********************* onsiteInspectionRule List Page *********************/

export const getRouteList = createAction('@@xcloud/onsiteInspectionRule/getRouteList',
    () => (dispatch, getState, httpClient) => {
        let companyId = ''
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let companyTree = getState().constant.companyTree;
        let tree = getTreePartById(company, companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            companyId = company
        }

        let url = '/api/onsiteinspections/routes?offset=0&size=0&sort=-update_time&status=active';

        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }

        return httpClient
            .get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data.list;
                } else {
                    return []
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '巡检路线获取失败'));
                return [];
            });
    }
);

export const getRuleList = createAction('@@xcloud/onsiteInspectionRule/getRuleList',
    (offset, size, sort, companyId) => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let search = getState().onsiteInspectionRule.search
        companyId = search.companyId ? search.companyId : ""
        let inspectionType = search.inspectionType ? search.inspectionType : ""
        let routeId = search.routeId ? search.routeId : ""
        let dptId = search.dptId ? search.dptId : ""
        size = search.size ? search.size : size

        let url = '/api/onsiteinspections/rules';

        if (offset && offset !== "") {
            url += "?offset=" + offset
        } else {
            url += "?offset=0"
        }
        if (_.isInteger(size)) {
            url += "&size=" + size
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=-update_time"
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (inspectionType && inspectionType !== "") {
            url += "&inspectionType=" + inspectionType
        }
        if (routeId && routeId !== "") {
            url += "&routeId=" + routeId
        }
        if (dptId && dptId !== "") {
            url += "&dptId=" + dptId
        }

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                dispatch(cleanloading());
                if (res.status === 200 && res.data) {
                    let list = res.data.list;
                    for (let i in list) {
                        let ids = list[i].dptIds.split(",")
                        let fids = [];
                        for (let j in ids) {
                            fids.push(parseInt(ids[j]))
                        }
                        list[i].dptIds = fids;
                    }
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(cleanloading());
                dispatch(createhttpMessage(err.response ? err.response : '', '计划配置获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

export const getRuleListByRouteId = createAction('@@xcloud/onsiteInspectionRule/getRuleListByRouteId',
    (routeId) => (dispatch, getState, httpClient) => {

        let url = '/api/onsiteinspections/rules?offset=0&size=0&sort=-update_time&routeId=' + routeId;

        return httpClient
            .get(url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                } else {
                    return { total: 0, list: [] }
                }
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '计划配置获取失败'));
                return {
                    total: 0,
                    list: []
                };
            });
    }
);

// export const getDepartment = createAction('@@xcloud/onsiteInspectionRule/getDepartment',
//     () => (dispatch, getState, httpClient) => {
//         let url = `/api/departments?offset=0&size=0&status=active&executable=true`;
//         return httpClient
//             .get(url)
//             .then((res) => {
//                 return res.data.list
//             })
//             .catch(err => {
//                 dispatch(createhttpMessage(err.response ? err.response : '', '获取班组信息失败'));
//                 return []
//             });
//     });

/**
 * 初始化路线列表页面
 */
export const initRuleListPage = createAction('@@xcloud/onsiteInspectionRule/initRuleListPage',
    () => (dispatch, getState) => {
        Promise.all([
            //初始化页面头部
            dispatch(resetSearch()),
            // dispatch(getDepartment()),
            dispatch(getRouteList())
        ]).then(() => {
            let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
            dispatch(getRuleList(0, 15, "-update_time", companyId))

        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '初始化计划配置搜索失败'));
            return [];
        });
    });

export const resetFrequency = createAction('@@xcloud/onsiteInspectionRule/resetFrequency',
    () => (dispatch, getState) => {

        let frequency = getState().onsiteInspectionRule.frequency;
        let newFrequency = {
            ...frequency,
            ruleData: {},
            ruleId: 0,
            freqType: '',
            freqP: '',
            freqC: '',
            timeP: '',
            timeC: '',
            customTime: '',
            customTimes: [],
            warnTime1: '',
            warnTime2: ''
        }

        dispatch(setFrequency(newFrequency));
    }
);

export const resetSearch = createAction('@@xcloud/onsiteInspectionRule/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().deviceUseStatus.search;
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)

        let newSearch = {
            ...search,
            companyId: companyId,
            inspectionType: "",
            routeId: "",
            dptId: "",
            offset: 0,
            size: 15
        }

        dispatch(setSearch(newSearch));
    }
);

export const deleteRule = createAction('@@xcloud/onsiteInspectionRule/deleteRule',
    (data) => (dispatch, getState, httpClient) => {
        let dto = {
            ruleId: data.ruleId,
            status: "inactive",
            immeEffect: true
        }

        let url = `/api/onsiteinspections/rules/${data.ruleId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(getRuleList(0, 15, "-update_time", ""))
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '删除计划配置失败'));
                return [];
            });
    }
);

export const changeRuleValid = createAction('@@xcloud/onsiteInspectionRule/changeRuleValid',
    (data, status, immeEffect) => (dispatch, getState, httpClient) => {
        let dto = {
            ruleId: data.ruleId,
            status: status,
            immeEffect: immeEffect === 'true' ? true : false
        }

        let url = `/api/onsiteinspections/rules/${data.ruleId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(getRuleList(0, 15, "-update_time", ""))
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '编辑计划配置失败'));
                return [];
            });
    }
);

export const setSearch = createAction('@@xcloud/onsiteInspectionRule/setSearch');

export const insertRule = createAction('@@xcloud/onsiteInspectionRule/insertRule',
    (data, rule) => (dispatch, getState, httpClient) => {
        let url = '/api/onsiteinspections/rules';
        let dto = {}
        dto.routeId = rule.routeId
        dto.isShift = rule.isShift
        dto.immeEffect = rule.immeEffect === 'true' ? true : false
        dto.dptIds = rule.dptIds.join(",")
        dto.expiredAt = rule.expiredAt ? _.toInteger(rule.expiredAt) : undefined
        dto.freqType = data.freqType
        dto.warnTime1 = data.warnTime1 ? _.toInteger(data.warnTime1) : undefined
        dto.warnTime2 = data.warnTime2 ? _.toInteger(data.warnTime2) : undefined

        if (data.freqType === 'P') {
            dto.freq = data.freqP * 60
            let arr = data.timeP.split(':')
            dto.startTime = _.toInteger(arr[0]) * 60 + _.toInteger(arr[1])

        }

        if (data.freqType === 'C') {
            dto.freq = _.toInteger(data.freqC)
            let arr = data.timeC.split(':')
            dto.startTime = _.toInteger(arr[0]) * 60 + _.toInteger(arr[1])
        }

        if (data.freqType === 'S') {
            let result = []

            data.customTimes.unshift(data.customTime)
            for (let i = 0; i < data.customTimes.length; i++) {
                if (data.customTimes[i]) {
                    let arrTime = data.customTimes[i].split(':')
                    let time = _.toInteger(arrTime[0]) * 60 + _.toInteger(arrTime[1])
                    result.push(time)
                }
            }

            if (result.length > 0) {
                dto.times = result.join(',')
            }
        }

        return httpClient.post(url, dto, { headers: { need_loading: 'true' } }).then((res) => {
            if (res.status === 201) {
                dispatch(resetFrequency())
                dispatch(setFrequencyOpen(false))
                dispatch(getRuleList(0, 15, "-update_time", ""))
                dispatch(createMessage('success', '创建巡检计划配置成功'));
            }
            return res;
        }).catch(err => {
            dispatch(createhttpMessage(err.response ? err.response : '', '创建巡检计划配置失败'));
            return null;
        });
    }
);


export const modifyFrequency = createAction('@@xcloud/onsiteInspectionRule/modifyFrequency',
    (data, rule) => (dispatch, getState, httpClient) => {
        let dto = {
            ruleId: rule.ruleId,
            tenantId: rule.tenantId,
            isShift: rule.isShift,
            expiredAt: (rule.expiredAt ? _.toInteger(rule.expiredAt) : undefined)
        }
        dto.routeId = rule.routeId
        dto.immeEffect = rule.immeEffect === 'true' ? true : false
        dto.dptIds = rule.dptIds.join(",")
        dto.freqType = data.freqType
        dto.warnTime1 = data.warnTime1 ? _.toInteger(data.warnTime1) : undefined
        dto.warnTime2 = data.warnTime2 ? _.toInteger(data.warnTime2) : undefined
        dto.status = rule.status ? rule.status : undefined

        if (data.freqType === 'P') {
            dto.freq = data.freqP * 60
            let arr = data.timeP.split(':')
            dto.startTime = _.toInteger(arr[0]) * 60 + _.toInteger(arr[1])
            dto.times = ''
        }

        if (data.freqType === 'C') {
            dto.freq = _.toInteger(data.freqC)
            let arr = data.timeC.split(':')
            dto.startTime = _.toInteger(arr[0]) * 60 + _.toInteger(arr[1])
            dto.times = ''
        }

        if (data.freqType === 'S') {
            let result = []
            // data.customTimes.unshift(data.customTime)
            for (let i = 0; i < data.customTimes.length; i++) {
                if (data.customTimes[i]) {
                    let arrTime = data.customTimes[i].split(':')
                    let time = _.toInteger(arrTime[0]) * 60 + _.toInteger(arrTime[1])
                    result.push(time)
                }
            }

            if (result.length > 0) {
                dto.times = result.join(',')
            }
        }

        let url = `/api/onsiteinspections/rules/${rule.ruleId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(resetFrequency())
                    dispatch(setFrequencyOpen(false))
                    dispatch(getRuleList(0, 15, "-update_time", ""))
                    dispatch(createMessage('success', '更新计划配置信息完成'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新计划配置失败'));
                return [];
            });

    }
);

export const modifyRule = createAction('@@xcloud/onsiteInspectionRule/modifyRule',
    (rule) => (dispatch, getState, httpClient) => {
        let dto = {
            ruleId: rule.ruleId,
            routeId: rule.routeId,
            dptIds: rule.dptIds.join(","),
            immeEffect: rule.immeEffect === 'true' ? true : false,
            tenantId: rule.tenantId
        }

        let url = `/api/onsiteinspections/rules/${rule.ruleId}`
        return httpClient
            .patch(url, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(getRuleList(0, 15, "-update_time", ""))
                    dispatch(createMessage('success', '更新计划配置信息完成'));
                }
                return res.data;
            }).catch(err => {
                dispatch(createhttpMessage(err.response ? err.response : '', '更新计划配置失败'));
                return [];
            });
    }
);

export const setCurrentRule = createAction('@@xcloud/onsiteInspectionRule/setCurrentRule');

export const setFrequencyOpen = createAction('@@xcloud/onsiteInspectionRule/setFrequencyOpen');

export const setFrequency = createAction('@@xcloud/onsiteInspectionRule/setFrequency');

/********************* onsiteInspectionRule Reducer *********************/

export const ruleListReducer = handleActions(
    {
        [setFrequencyOpen]: (state, { payload: value }) => {
            return {
                ...state,
                frequencyOpen: value
            };
        },
        [setFrequency]: (state, { payload: value }) => {
            return {
                ...state,
                frequency: value
            };
        },
        [getRuleList]: (state, { payload: value }) => {
            return {
                ...state,
                ruleList: value
            };
        },
        [getRuleListByRouteId]: (state, { payload: value }) => {
            return {
                ...state,
                ruleListByRouteId: value
            };
        },
        [getRouteList]: (state, { payload: value }) => {
            return {
                ...state,
                routeList: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        // [getDepartment]: (state, { payload: value }) => {
        //     return {
        //         ...state,
        //         departmentList: value
        //     };
        // },
        [setCurrentRule]: (state, { payload: value }) => {
            return {
                ...state,
                currentRule: value
            };
        },
    },
    defaultState
);

export default ruleListReducer;