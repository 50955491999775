import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addProject, updateProject, delProject, getProjects } from '@/redux/modules/manufacture/projectPlan';
import { createActionMessage } from '@/redux/modules/message';
import ProjectPlanComponent from '@/components/ManufactureManagement/ProjectPlan/ProjectPlanComponent';
import UpdateProjectComponent from '@/components/ManufactureManagement/ProjectPlan/UpdateProject/UpdateProjectComponent';
import LaborerManageContainer from '@/containers/ManufactureManagement/LaborerManage/LaborerManageContainer';
import { judgeMainCompany, VALUATION_TYPE_BUDGET } from '@/utils/manufactureUtils'
import { getMoment, formatDate, formatDay, convertToMoment } from '@/utils/datetime';
import { getDepartmentsByRole } from '@/utils/userUtils';
import { addOrder } from '@/redux/modules/manufacture/manufactureOrder';
import { getProjectServices } from '@/redux/modules/manufacture/manufactureService';
import { getInstallationsInManipulate } from '@/redux/modules/manipulate';
import * as _ from 'lodash'
import CreateProjectOrderComponent from '@/components/ManufactureManagement/ProjectPlan/CreateProjectOrder';

class ProjectPlanContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectDptList: [],
            openLaborerFlag: false,
            openProjectFlag: false,
            openOrderFlag: false,
            openType: 1,
            isMainCompany: false,
            projectData: {},
            projectServiceList: [],
            dptsSelect: [],
            selectProjectId: 0,
            selectProjectName: '',
            orderData: {},
            search: {
                projectName: undefined,
                startDate: getMoment()(),
                endDate: getMoment()(),
                serviceId: undefined,
                status: undefined
            }
        }
    }

    componentDidMount() {
        const { userCompanyId, companyList, getProjects, getProjectServices, getInstallationsInManipulate } = this.props;
        let flag = judgeMainCompany(companyList, userCompanyId);
        if (flag) {
            getProjects(this.state.search);
            //获取工程服务
            getProjectServices(null, null, (data) => {
                this.setState({
                    projectServiceList: data
                })
            });
        } else {
            getProjects(this.state.search, userCompanyId);
            const mainCompanyId = companyList.find(ele => ele.companyId === userCompanyId).pid;
            //获取工程服务
            getProjectServices(userCompanyId, mainCompanyId, (data) => {
                this.setState({
                    projectServiceList: data
                })
            });
            // 获取装置数据
            getInstallationsInManipulate(userCompanyId);
        }

        this.setState({
            isMainCompany: flag
        })
    }

    getCompanyId = () => {
        const company = this.props.companyList.find(item => {
            return item.companyId === this.props.currentUserInfo.companyId
        });
        return company.pid === null ? null : company.companyId
    }

    getProjectList() {
        const { userCompanyId, getProjects } = this.props;
        const { isMainCompany } = this.state;
        if (isMainCompany) {
            getProjects(this.state.search);
        } else {
            getProjects(this.state.search, userCompanyId);
        }
    }

    handleAdd = () => {
        let projectData = {};

        // 数据初始化
        const defualtDate = new Date(formatDay(getMoment()()));
        projectData.startTime = defualtDate;
        projectData.endTime = defualtDate;
        projectData.period = 1;
        projectData.budget = 0;
        projectData.materialFee = 0;
        projectData.finalCost = 0;
        this.setState({
            openProjectFlag: true,
            openType: 1,
            projectData: projectData,
        })
    }

    handleDelete = (rowData) => {
        return this.props.delProject(rowData.projectId, () => {
            this.getProjectList()
        })
    }

    handleUpdate = (rowData) => {
        const { departmentList } = this.props;
        let dptsSelect = [];
        if (rowData.requireCompany) {
            dptsSelect = departmentList.filter(dpt => dpt.companyId === rowData.requireCompany);
            if (rowData.dptList) {
                //供方班组
                rowData.provideDpts = _.filter(departmentList, d => {
                    return _.find(rowData.dptList, dd => { return dd.dptId === d.dptId && dd.dependType === 'SERVICE' })
                });
                //需方班组
                rowData.requireDpts = _.filter(departmentList, d => {
                    return _.find(rowData.dptList, dd => { return dd.dptId === d.dptId && dd.dependType === 'REQUIRE' })
                });
            }
            if (rowData.installId) {
                this.props.getInstallationsInManipulate(rowData.requireCompany);
            }
        }

        this.setState({
            openProjectFlag: true,
            openType: 2,
            projectData: rowData,
            dptsSelect: dptsSelect,
        })
    }

    handleUpdateLaborer = (rowData) => {
        this.setState({
            openLaborerFlag: true,
            selectProjectId: rowData.projectId,
            selectProjectName: rowData.projectName,
        })
    }

    handleDateChange = (value, type) => {
        let projectData = this.state.projectData;
        const date = new Date(value);
        if (type === 'endTime') {
            let period = 0;
            if (projectData.startTime) {
                let startTime = new Date(projectData.startTime);
                let startTimeInt = startTime.getTime();
                let endTimeInt = date.getTime();
                period = parseInt((endTimeInt - startTimeInt) / 1000 / 3600 / 24) + 1;
                if (period <= 0) {
                    return this.props.createActionMessage('warning', '结束时间不得早于于开始时间')
                }
            }
            projectData.endTime = date;
            projectData.period = period;
        } else {
            let period = 0;
            if (projectData.endTime) {
                let endTime = new Date(projectData.endTime);
                let startTimeInt = date.getTime();
                let endTimeInt = endTime.getTime();
                period = parseInt((endTimeInt - startTimeInt) / 1000 / 3600 / 24) + 1;
                if (period <= 0) {
                    return this.props.createActionMessage('warning', '开始时间不得晚于结束时间')
                }
            }
            projectData.startTime = date;
            projectData.period = period;
        }
        this.setState({
            projectData: projectData,
        });
    }

    handleNameClose = (event, reason) => {
        let projectData = this.state.projectData;
        if (reason === 'blur' && event.target.value) {
            projectData.customer = event.target.value
        }
        this.setState({
            projectData: projectData,
        });
    }

    handleCustomerChange = (event, value) => {
        const { departmentList, getInstallationsInManipulate } = this.props;
        let projectData = this.state.projectData;
        const selectedCompanyId = event.target.value
        if (selectedCompanyId) {
            projectData.requireDpts = [];
            projectData.requireDptSelect = departmentList.filter(dpt => dpt.companyId === selectedCompanyId);
            projectData.requireCompany = selectedCompanyId;
            getInstallationsInManipulate(selectedCompanyId);
        }
        this.setState({
            projectData: projectData,
        });
    }

    handleChange = (event) => {
        let projectData = this.state.projectData;
        projectData[event.target.name] = event.target.value;
        this.setState({
            projectData: projectData,
        });
    }

    handleDptsChange = (event, dpts) => {
        dpts.forEach(ele => ele.dependType = 'REQUIRE');
        let projectData = {
            ...this.state.projectData,
            requireDpts: dpts,
        }
        this.setState({ projectData: projectData })
    }

    handleServiceDptsChange = (event, dpts) => {
        dpts.forEach(ele => ele.dependType = 'SERVICE');
        let projectData = {
            ...this.state.projectData,
            provideDpts: dpts,
        }
        this.setState({ projectData: projectData })
    }

    // 获取公司名
    getCompanyName = (id) => {
        let dto = this.props.companyList.find(item => {
            return item.companyId === id ? item.companyAlias : ''
        });
        if (dto !== undefined) {
            return dto.companyAlias;
        }
        return '';
    }

    handleServiceChange = (event) => {
        const { departmentList, companyList, userCompanyId, currentUserInfo, userProfile, getInstallationsInManipulate } = this.props;
        const { projectServiceList } = this.state;
        let projectData = this.state.projectData;
        const serviceId = event.target.value;
        projectData.serviceId = serviceId;
        let service = projectServiceList.find(ele => ele.serviceId === serviceId);
        if (service) {
            if (service.serviceType === 'COMMISSION') {
                projectData.requireCompany = userCompanyId
                const company = companyList.find(ele => ele.companyId === userCompanyId)
                projectData.requireCompanySelect = [company]
                projectData.requireSide = company ? company.companyName : ''
                projectData.provideDpts = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
                projectData.requireDptSelect = getDepartmentsByRole(currentUserInfo.roleId, userProfile)

                projectData.serviceCompany = undefined
                projectData.serviceSide = ''
                projectData.serviceDpts = []
                projectData.serviceDptSelect = []
            } else if (service.serviceType === 'OUTER') { //对外服务

                projectData.requireCompany = undefined
                projectData.requireCompanySelect = []
                projectData.requireDpts = []
                projectData.requireDptSelect = []
                projectData.requireSide = ''

                projectData.serviceCompany = userCompanyId
                const company = companyList.find(ele => ele.companyId === userCompanyId)
                projectData.serviceSide = company ? company.companyName : ''
                let tempDpts = _.filter(departmentList, d => { return _.find(service.serviceDpts, sd => { return sd.dptId === d.dptId && sd.dependType === 'SERVICE' }) })
                if (!tempDpts || tempDpts.length === 0) { //没有配置供方班组
                    tempDpts = _.filter(departmentList, d => { return d.companyId === userCompanyId })
                }
                projectData.provideDpts = tempDpts
                projectData.serviceDptSelect = tempDpts
                // projectData.provideDpts = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
                // projectData.serviceDptSelect = getDepartmentsByRole(currentUserInfo.roleId, userProfile)

            } else { //对内服务
                const rc = _.find(companyList, c => { return c.companyId === service.requireCompany })
                if (!rc || !rc.pid) { //无公司，或者总公司
                    projectData.requireCompany = userCompanyId
                    projectData.requireCompanySelect = companyList
                    projectData.requireDpts = []
                    projectData.requireDptSelect = departmentList.filter(dep => dep.companyId === userCompanyId);
                } else {
                    projectData.requireCompany = rc.companyId
                    projectData.requireCompanySelect = [rc]
                    projectData.requireDpts = _.filter(departmentList, d => {
                        return _.find(service.serviceDpts, dd => { return dd.dptId === d.dptId && dd.dependType === 'REQUIRE' })
                    });
                    projectData.requireDptSelect = departmentList.filter(dep => dep.companyId === rc.companyId);
                }

                projectData.serviceCompany = userCompanyId
                const company = companyList.find(ele => ele.companyId === userCompanyId)
                projectData.serviceSide = company ? company.companyName : ''
                let tempDpts = _.filter(departmentList, d => { return _.find(service.serviceDpts, sd => { return sd.dptId === d.dptId && sd.dependType === 'SERVICE' }) })
                if (!tempDpts || tempDpts.length === 0) { //没有配置供方班组
                    tempDpts = _.filter(departmentList, d => { return d.companyId === userCompanyId })
                }
                projectData.provideDpts = tempDpts
                projectData.serviceDptSelect = tempDpts
            }

            projectData.installId = service.installId
            if (projectData.requireCompany) {
                getInstallationsInManipulate(projectData.requireCompany);
            }
            if (service.valuationType) {
                projectData.valuationType = service.valuationType;
            } else {
                projectData.valuationType = VALUATION_TYPE_BUDGET;
            }
            if (service.costSharing) {
                projectData.costSharing = service.costSharing
            }
            projectData.serviceType = service.serviceType;
        }
        this.setState({
            projectData: projectData,
            dptsSelect: [],
        });
    }

    handleSave = () => {
        const openType = this.state.openType;
        let projectData = this.state.projectData;
        projectData.dptList = [];
        if (projectData.requireDpts) {
            projectData.requireDpts.forEach(ele => {
                let data = {};
                data.dptId = ele.dptId;
                data.dependType = 'REQUIRE';
                projectData.dptList.push(data);
            })
        }
        if (projectData.provideDpts) {
            projectData.provideDpts.forEach(ele => {
                let data = {};
                data.dptId = ele.dptId;
                data.dependType = 'SERVICE';
                projectData.dptList.push(data);
            })
        }
        if (openType === 1) {
            this.props.addProject(projectData, () => {
                this.getProjectList()
            })
        } else {
            this.props.updateProject(projectData, () => {
                this.getProjectList()
            })
        }

        this.setState({
            openProjectFlag: false,
            projectData: {},
        });
    }

    handleClose = () => {
        this.setState({
            openProjectFlag: false,
            projectData: {},
        })
    }

    handleGenerateOrder = (rowData) => {
        let newData = {};
        newData['startTime'] = formatDate(getMoment()())
        newData['companyId'] = this.getCompanyId();
        newData['flg'] = 2;
        newData.projectId = rowData.projectId;
        return this.props.handleAdd(newData).then(res => {
            this.getProjectList()
        })
    }

    handleLaborerClose = () => {
        this.setState({
            openLaborerFlag: false,
            selectProjectId: 0,
            selectProjectName: '',
        })
    }

    handleOpenAddOrder = (rowData) => {
        let dptId = 0
        if (rowData.dptList) {
            const dpt = _.find(rowData.dptList, { dependType: 'SERVICE' })
            if (dpt) {
                dptId = dpt.dptId
            }
        }
        this.setState({
            openOrderFlag: true,
            orderData: {
                ...rowData,
                dptId: dptId,
                orderTime: getMoment()()
            }
        })
    }

    handleCloseAddOrder = () => {
        this.setState({
            openOrderFlag: false,
            orderData: {}
        })
    }

    handleAddOrder = () => {
        let orderDto = {}
        orderDto.projectId = this.state.orderData.projectId
        orderDto.serviceId = this.state.orderData.serviceId
        orderDto.dptId = this.state.orderData.dptId
        orderDto.companyId = this.state.orderData.serviceCompany
        orderDto.startTime = this.state.orderData.orderTime
        orderDto.endTime = getMoment()(orderDto.startTime).add(1, 'days')
        orderDto.orderContent = '新增工程工单'
        orderDto.allocation = Number(this.state.orderData.allocation) / 100
        this.props.handleAdd(orderDto).then(res => {
            this.handleCloseAddOrder()
        })
    }

    handleOrderDateChange = (value) => {
        this.setState({
            orderData: {
                ...this.state.orderData,
                orderTime: getMoment()(value)
            }
        })
    }

    handleOrderChange = (event) => {
        let newOrder = {
            ...this.state.orderData,
        }
        newOrder[event.target.name] = event.target.value
        this.setState({ orderData: newOrder })
    }

    handleClearSearch = () => {
        this.setState({
            search: {
                projectName: undefined,
                startDate: getMoment()(),
                endDate: getMoment()(),
                serviceId: undefined,
                status: undefined
            }
        })
    }

    handleSearchChange = (event, field) => {
        if (field === 'projectName') {
            let newSearch = {
                ...this.state.search,
                projectName: _.trim(event.target.value)
            }
            this.setState({
                search: newSearch
            })
        } else if (field === 'startDate') {
            let newDate = convertToMoment(event)
            let newSearch = {
                ...this.state.search,
                startDate: newDate
            }
            this.setState({
                search: newSearch
            })
        } else if (field === 'endDate') {
            let newDate = convertToMoment(event)
            let newSearch = {
                ...this.state.search,
                endDate: newDate
            }
            this.setState({
                search: newSearch
            })
        } else if (field === 'serviceId') {
            let newSearch = {
                ...this.state.search,
                serviceId: parseInt(event.target.value) === 0 ? undefined : parseInt(event.target.value)
            }
            this.setState({
                search: newSearch
            })
        } else if (field === 'status') {
            let newSearch = {
                ...this.state.search,
                status: event.target.value === 'all' ? undefined : event.target.value
            }
            this.setState({
                search: newSearch
            })
        }
    }

    render() {
        return (
            <div>
                <ProjectPlanComponent
                    projects={this.props.projects}
                    projectServiceList={this.state.projectServiceList}
                    search={this.state.search}
                    companyList={this.props.companyList}
                    manufactureDicts={this.props.manufactureDicts}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleGenerateOrder={this.handleGenerateOrder.bind(this)}
                    onhandleUpdateLaborer={this.handleUpdateLaborer.bind(this)}
                    onhandleOpenAddOrder={this.handleOpenAddOrder.bind(this)}
                    onhandleSearchChange={this.handleSearchChange.bind(this)}
                    onhandleSearch={this.getProjectList.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                />
                {this.state.openProjectFlag &&
                    <UpdateProjectComponent
                        openProjectFlag={this.state.openProjectFlag}
                        openType={this.state.openType}
                        projectData={this.state.projectData}
                        companyList={this.props.companyList}
                        departmentList={this.props.departmentList}
                        installs={this.props.installs}
                        manufactureDicts={this.props.manufactureDicts}
                        projectServiceList={this.state.projectServiceList}
                        dptsSelect={this.state.dptsSelect}
                        isMainCompany={this.state.isMainCompany}
                        onhandleClose={this.handleClose.bind(this)}
                        onhandleSave={this.handleSave.bind(this)}
                        onhandleDateChange={this.handleDateChange.bind(this)}
                        onhandleNameClose={this.handleNameClose.bind(this)}
                        onhandleCustomerChange={this.handleCustomerChange.bind(this)}
                        onhandleServiceChange={this.handleServiceChange.bind(this)}
                        onhandleChange={this.handleChange.bind(this)}
                        onhandleDptsChange={this.handleDptsChange.bind(this)}
                        onhandleServiceDptsChange={this.handleServiceDptsChange.bind(this)}
                    />
                }
                {this.state.openLaborerFlag &&
                    <LaborerManageContainer
                        openLaborerFlag={this.state.openLaborerFlag}
                        projectId={this.state.selectProjectId}
                        projectName={this.state.selectProjectName}
                        onhandleLaborerClose={this.handleLaborerClose.bind(this)}
                    />}
                {
                    this.state.openOrderFlag && <CreateProjectOrderComponent
                        openFlag={this.state.openOrderFlag}
                        orderData={this.state.orderData}
                        companyList={this.props.companyList}
                        departmentList={this.props.departmentList}
                        onhandleAddOrder={this.handleAddOrder.bind(this)}
                        onhandleClose={this.handleCloseAddOrder.bind(this)}
                        onhandleDateChange={this.handleOrderDateChange.bind(this)}
                        onhandleChange={this.handleOrderChange.bind(this)}


                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyList: state.company.companyList.list,
        departmentList: state.department.departments.list,
        installs: state.installation.installationList.list,
        userCompanyId: state.auth.currentUserInfo.companyId,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        projects: state.projectPlan.projects,
        manufactureDicts: state.constant.manufactureDicts,
        projectServices: state.manufactureService.projectServices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProject: (data, callback) => {
            return dispatch(addProject(data, callback));
        },
        handleAdd: (newData) => {
            return dispatch(addOrder(newData));
        },
        updateProject: (updateDto, callback) => {
            dispatch(updateProject(updateDto, callback));
        },
        delProject: (projectId, callback) => {
            return dispatch(delProject(projectId, callback));
        },
        getProjects: (searchData, ownCompany) => {
            return dispatch(getProjects(null, formatDate(searchData.startDate), formatDate(searchData.endDate), ownCompany, searchData.serviceId, searchData.status));
        },
        getProjectServices: (companyId, mainCompanyId, callback) => {
            dispatch(getProjectServices(companyId, mainCompanyId, callback));
        },
        getInstallationsInManipulate: (companyId) => {
            return dispatch(getInstallationsInManipulate(companyId));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectPlanContainer);
