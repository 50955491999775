import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'


const porxyApi = '/api'

const initialState = {
    completeRates: [],
    products: [],
    subReports: []
};

export const setCompleteRates = createAction('@@xcloud/amoeba/setCompleteRates');

export const setProducts = createAction('@@xcloud/amoeba/setProducts');

export const setSubReports = createAction('@@xcloud/amoeba/setSubReports');

export const getCompleteRates = createAction('@@xcloud/amoeba/getCompleteRates',
    (timeDim, year, month, day, orgDim, companyId, dptId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/analysis/completeRates'
            let strs = []
            if (timeDim || year || month || day || orgDim || companyId || dptId) {
                if (timeDim) strs.push('timeDim=' + timeDim)
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                if (day) strs.push('day=' + day)
                if (orgDim) strs.push('orgDim=' + orgDim)
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setCompleteRates(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴分析完成率失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const getProducts = createAction('@@xcloud/amoeba/getProducts',
    (timeDim, year, month, day, orgDim, companyId, dptId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/analysis/products'
            let strs = []
            if (timeDim || year || month || day || orgDim || companyId || dptId) {
                if (timeDim) strs.push('timeDim=' + timeDim)
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                if (day) strs.push('day=' + day)
                if (orgDim) strs.push('orgDim=' + orgDim)
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setProducts(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴分析完成率失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getSubReports = createAction('@@xcloud/amoeba/getSubReports',
    (timeDim, year, month, day, orgDim, companyId, dptId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/analysis/subreports'
            let strs = []
            if (timeDim || year || month || day || orgDim || companyId || dptId) {
                if (timeDim) strs.push('timeDim=' + timeDim)
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                if (day) strs.push('day=' + day)
                if (orgDim) strs.push('orgDim=' + orgDim)
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setSubReports(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴分析完成率失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const amoebaReportReducer = handleActions(
    {
        [setCompleteRates]: (state, { payload: data }) => {
            return {
                ...state,
                completeRates: data
            }
        },
        [setProducts]: (state, { payload: data }) => {
            return {
                ...state,
                products: data
            }
        },
        [setSubReports]: (state, { payload: data }) => {
            return {
                ...state,
                subReports: data
            }
        },
    },
    initialState
)

export default amoebaReportReducer
