import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  detailtable: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  title: {
    padding: theme.spacing(2)
  },
  materialtable: {
    boxShadow: 'none'
  },
  tableFreeButton: {
    // 表格工具栏样式覆写无效，故设置如下按钮假样式
    position: 'relative',
    top: 8,
    margin: theme.spacing(-1.5),
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
});

export default withStyles(styles);
