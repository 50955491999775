import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    plans: [], //生产计划列表
    plan: {}, //生产计划
    planDtls: [],//生产计划明细
    planDpts: [],//生产计划班组
    rangeOrders: []
};

export const setPlans = createAction('@@xcloud/manufacture/setPlans');
export const setPlanDtls = createAction('@@xcloud/manufacture/setPlanDtls');
export const setPlanDpts = createAction('@@xcloud/manufacture/setPlanDpts');
export const setPlan = createAction('@@xcloud/manufacture/setPlan');
export const openEditPlan = createAction('@@xcloud/manufacture/openEditPlan');
export const closeEditPlan = createAction('@@xcloud/manufacture/closeEditPlan');

export const setRangeOrders = createAction('@@xcloud/manufacture/setRangeOrders');

export const getPlans = createAction('@@xcloud/manufacture/getPlans',
    (companyId, planType, year, month) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/plans'
        let strs = []
        if (companyId || planType || year || month) {
            if (companyId) strs.push('companyId=' + companyId)
            if (planType) strs.push('planType=' + planType)
            if (year) strs.push('year=' + year)
            if (month) strs.push('month=' + month)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setPlans(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getRangerOrders = createAction('@@xcloud/manufacture/getRangerOrders',
    (companyId, startTime, endTime) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/rangeorders'
        url += '?companyId=' + companyId;
        url += '&startTime=' + startTime;
        url += '&endTime=' + endTime;
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setRangeOrders(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产月度计划明细列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getPlanDtls = createAction('@@xcloud/manufacture/getPlanDtls',
    (planId, serviceId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/plans/details'
        let strs = []
        if (planId || serviceId) {
            if (planId) strs.push('planId=' + planId)
            if (serviceId) strs.push('serviceId=' + serviceId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setPlanDtls(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产计划明细列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getPlanDpts = createAction('@@xcloud/manufacture/getPlanDpts',
    (dtlId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/manufacture/plans/${dtlId}/dpts`
        let strs = []
        if (dtlId) {
            if (dtlId) strs.push('dtlId=' + dtlId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setPlanDpts(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产计划班组列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });


export const updatePlanDpt = createAction('@@xcloud/manufacture/plans/updatePlanDpt',
    (dtlDptId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/manufacture/plans/dpts/${dtlDptId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产计划班组成功"));
                }
            }).catch(err => {
                let msg = `更新生产计划班组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addPlan = createAction('@@xcloud/manufacture/plans/addPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/plans`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加生产计划成功"));
                }
            }).catch(err => {
                let msg = `增加生产计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addTempPlan = createAction('@@xcloud/manufacture/plans/addTempPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/tempplans`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加临时生产计划成功"));
                    return res;
                }
            }).catch(err => {
                let msg = `增加临时生产计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addDayPlans = createAction('@@xcloud/manufacture/plans/addDayPlans',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/dayplans`, newData)
            .then((res) => {
                if (res) {
                    const plan = res.data;
                    dispatch(createhttpMessage(res, "批量增加" + plan.year + "年" + plan.month + "月的日计划成功"));
                }
            }).catch(err => {
                let msg = `批量增加日计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addPlanDtl = createAction('@@xcloud/manufacture/plans/addPlanDtl',
    (planId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/plans/${planId}/details`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加生产计划明细成功"));
                }
            }).catch(err => {
                let msg = `增加生产计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updatePlan = createAction('@@xcloud/manufacture/plans/updatePlan',
    (planId, newData) => (dispatch, getState, httpClient) => {
        console.log(planId);
        console.log(newData);
        return httpClient.patch(porxyApi + `/manufacture/plans/${planId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产计划成功"));
                }
            }).catch(err => {
                let msg = `更新生产计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateTempPlan = createAction('@@xcloud/manufacture/plans/updateTempPlan',
    (planId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/manufacture/tempplans/${planId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新临时生产计划成功"));
                }
            }).catch(err => {
                let msg = `更新临时生产计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const updatePlanDtl = createAction('@@xcloud/manufacture/plans/updatePlanDtl',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/manufacture/plans/details/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新生产计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updatePlanAnnualDtl = createAction('@@xcloud/manufacture/plans/updatePlanAnnualDtl',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/manufacture/plans/details/annual/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新生产计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const deletePlan = createAction('@@xcloud/manufacture/deletePlan',
    (planId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/manufacture/plans/${planId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除生产计划成功"));
                }
            }).catch(err => {
                let msg = `删除生产计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deletePlanDtl = createAction('@@xcloud/manufacture/deletePlanDtl',
    (dtlId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/manufacture/plans/details/${dtlId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除生产计划明细成功"));
                }
            }).catch(err => {
                let msg = `删除生产计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startMonthPlan = createAction('@@xcloud/manufacture/startMonthPlan',
    (processCode, callback) => (dispatch, getState, httpClient) => {
        return httpClient.get(porxyApi + '/manufacture/plans/process/month/' + processCode)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动月度生产计划成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = '启动月度生产计划成功失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeMonthProcess = createAction('@@xcloud/manufacture/executeMonthProcess',
    (processCode, actionParams, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/plans/process/month/' + processCode, actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行月度生产计划流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行月度生产计划流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const startYearPlan = createAction('@@xcloud/manufacture/startYearPlan',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/plans/process/year/start', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动年度生产计划成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = '启动年度生产计划成功失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeYearProcess = createAction('@@xcloud/manufacture/executeYearProcess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/plans/process/year/execute', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行年度生产计划流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行年度生产计划流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const appendService2AnnualPlan = createAction('@@xcloud/manufacture/plans/services/append',
    (appendParam) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/plans/services`, appendParam)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "新增服务到年度计划成功"));
                }
            }).catch(err => {
                let msg = `新增服务到年度计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const manufacturePlanReducer = handleActions(
    {
        [setPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setPlan]: (state, { payload: data }) => {
            return {
                ...state,
                plan: data,
            }
        },
        [setRangeOrders]: (state, { payload: data }) => {
            return {
                ...state,
                rangeOrders: data,
            }
        },
        [setPlanDtls]: (state, { payload: data }) => {
            return {
                ...state,
                planDtls: data,
            }
        },
        [setPlanDpts]: (state, { payload: data }) => {
            return {
                ...state,
                planDpts: data,
            }
        },
        [openEditPlan]: (state, { payload: data }) => {
            return {
                ...state,
                editPlan: data,
                tempPlanOpen: true
            }
        },
        [closeEditPlan]: (state, { payload: data }) => {
            return {
                ...state,
                editPlan: {},
                tempPlanOpen: false
            }
        },
    },
    initialState
)

export default manufacturePlanReducer
