import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Avatar,
    Button,
    Card,
    Chip,
    Drawer,
    Typography,
    Grid,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
} from "@material-ui/core";
import {
    Alert, AlertTitle
} from '@material-ui/lab';
import {
    Block,
    Done,
    PostAdd,
    Visibility,
    Edit,
    DeleteOutlined,
    GroupAdd,
    Person,
    PersonAdd,
} from '@material-ui/icons';
import MaterialTable, { MTableHeader } from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import UserCard from '@/containers/UserCard';
import ProcessLineWithUser from '@/components/ProcessLineApproval/ProcessLineApprovalComponent';
import { getDateByString } from '@/utils/datetime';
import { arrayToTree, OPT_CAT } from "@/utils/constant";
import ShowCard from '@/components/DeviceManagement/OperationAnnuaPlan/ShowCard';
import { WORKORDER_STATUS_ALL } from '@/utils/operationUtils';

import _ from 'lodash';

const WorkOrderProcess = props => {

    const {
        classes,
        orderTypeList,
        departmentList,
        optCategories,
        companyList,
        planDetails,
        onhandleBack,
        onhandleUpdate,
        onhandleDelete,
        onCreateSub,
        catList,
        workOrderList,
        accCatList,
        onhandleAssignHead,
        onhandleAssign,
        actions,
        onhandleProcess,
        // onhandleProcessFlowEdit,
        workOrderApprovers,
        workOrderApproversLast,
        globalStatus,
        permission,
        specChecker,
        currentProcessActiveUser,
        workOrderNotices,
        mainStartUser,
        onDownLoadDoc,
        routeType,
        userProfile,
        //部件更新相关
        accessoryList, //工单相关部件表
        onhandleReplaceAccessory,
        onhandleDeleteAccessory,
        onhandleUpdateAccessory,
    } = props;


    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_workorder_list' })
        return permission && permission.action === 'W'
    }
    // 审批信息改为一个抽屉
    const [processDrawerOpen, setProcessDrawerOpen] = useState(false);
    // 判断创建/修改流程用按钮
    const renderProcessFlowEditButton = () => {
        let buttonText = ''

        if (!workOrderList || !workOrderList.list || workOrderList.list.length === 0) {
            return <Grid></Grid>
        }

        if ((workOrderList.list[0].status === 'new' || workOrderList.list[0].status === 'rejected')
            && checkPermission()) {
            buttonText = '审批流程配置'
            // return <Button variant="contained" color="primary" onClick={onhandleProcessFlowEdit}>{buttonText}</Button>

        } else {
            buttonText = '审批流程'
        }
        return <Button variant="contained" color="primary" onClick={() => setProcessDrawerOpen(true)}>{buttonText}</Button>
    }

    // 用于表格内编辑
    const tableRef = React.createRef();

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_workorder_list' })
    //     return permission && permission.action === 'W'
    // }

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderCat = (deviceCat) => {
        if (catList && catList.length > 0 && deviceCat) {
            let cat = _.find(catList, { 'code': deviceCat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderAccCat = (accCat) => {
        let label = '';
        if (accCatList && accCatList.length > 0) {
            let value = _.find(accCatList, function (o) { return o.code === accCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderOrderType = (WorkOrder) => {
        let label = '';
        if (orderTypeList && orderTypeList.length > 0) {
            let value = _.find(orderTypeList, function (o) { return o.code === WorkOrder.orderType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    // 渲染 - 运维类别
    const renderOptCat = (row) => {
        if ((row.ruleId || row.optType) && optCategories && optCategories.length > 0) {
            let cat = _.find(optCategories, { 'code': (row.ruleId ? row.rule.cat : row.optType) });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderDepartment = (WorkOrder) => {
        let label = '';
        if (departmentList && departmentList.length > 0) {
            let value = _.find(departmentList, function (o) { return o.dptId === WorkOrder.dptId })
            if (value) {
                label = value.dptName
            }
        }
        return label;
    }

    const renderStatus = (WorkOrder) => {
        return WORKORDER_STATUS_ALL[WorkOrder.status]
    }

    const assignable = () => {

        if (checkEditPermission()) {
            if (specChecker) {
                if (currentProcessActiveUser || mainStartUser) {
                    return true;
                }
            } else {
                if (globalStatus === 'new' || globalStatus === 'rejected') {
                    return true;
                }
            }
        }
        return false;
    }

    const renderContractor = (workOrder) => {
        if (workOrder.executors && workOrder.executors.length > 0) {
            return workOrder.executors.map(ele => {
                return ele.internalCompanyName && ele.internalDptName ?
                    <Chip
                        key={ele.woeId}
                        size="small"
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic1}>
                            内
                        </Avatar>}
                        label={`${ele.internalCompanyName} > ${ele.internalDptName}`}
                        onClick={assignable() ? () => { onhandleAssign(null, workOrder) } : null}
                    />
                    :
                    <Chip
                        key={ele.woeId}
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic2}>
                            外
                        </Avatar>}
                        size="small"
                        label={`${ele.supplierName}`}
                        onClick={assignable() ? () => { onhandleAssign(null, workOrder) } : null}
                    />
            })
        } else {
            if (assignable()) {
                return <Button
                    key={`rule_button_${workOrder.orderId}`}
                    variant={'contained'}
                    color="primary"
                    size="small"
                    startIcon={<GroupAdd />}
                    className={classes.inlineButton}
                    onClick={() => { onhandleAssign(null, workOrder) }}
                >
                    {'指派'}
                </Button>;
            }
            return null;
        }
    }

    const renderCreator = (workOrder) => {

        let creatorName = ''
        if (workOrder.creatorLastName) {
            creatorName = creatorName + workOrder.creatorLastName
        }
        if (workOrder.creatorFirstName) {
            creatorName = creatorName + workOrder.creatorFirstName
        }
        return creatorName
    }

    const renderOrderHead = (workOrder) => {
        if (workOrder.orderHead) {
            if (workOrder.orderHeadLastName && workOrder.orderHeadFirstName) {
                return <UserCard userId={workOrder.orderHead} child={
                    // <Typography>{workOrder.orderHeadLastName + workOrder.orderHeadFirstName}</Typography>
                    <Chip
                        key={workOrder.woeId}
                        size="small"
                        avatar={<Person />}
                        label={workOrder.orderHeadLastName + workOrder.orderHeadFirstName}
                        onClick={assignable() ? () => { onhandleAssignHead(null, workOrder) } : null}
                    />
                } />
            } else if (workOrder.orderHeadFirstName) {
                return <UserCard userId={workOrder.orderHead} child={
                    // <Typography>{workOrder.orderHeadFirstName}</Typography>
                    <Chip
                        key={workOrder.woeId}
                        size="small"
                        avatar={<Person />}
                        label={workOrder.orderHeadFirstName}
                        onClick={assignable() ? () => { onhandleAssignHead(null, workOrder) } : null}
                    />
                } />
            }
        } else {
            if (assignable()) {
                return <Button
                    key={`rule_button_${workOrder.orderId}`}
                    variant={'contained'}
                    color="primary"
                    size="small"
                    startIcon={<PersonAdd />}
                    className={classes.inlineButton}
                    onClick={() => { onhandleAssignHead(null, workOrder) }}
                >
                    {'指派'}
                </Button>;
            }
            return null;
        }
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '工单',
                field: 'sortOrderNo',
                defaultGroupOrder: 1,
                defaultGroupSort: 'asc',
            },
            {
                field: 'type',
                render: row => row ?
                    row.masterOrder ?
                        <Avatar className={classes.pic2}>从</Avatar>
                        : <Avatar className={classes.pic1}>主</Avatar>
                    : null,
                width: '1em',
                editable: 'never',
                cellStyle: {
                    padding: '0px 0px',
                },
                headerStyle: {
                    padding: '6px 0px',
                },
            },
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never',
                width: 110,
            },
            // {
            //     title: '主工单编号',
            //     field: 'orderNo',
            //     render: row => row ? <Typography>{row.masterOrderNo}</Typography> : '',
            //     editable: 'never',
            //     width: 100,
            // },
            // {
            //     title: '来源单位',
            //     field: 'companyId',
            //     render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '来源部门',
                field: 'dptId',
                render: row => row ? <Typography>{renderDepartment(row)}</Typography> : '',
                editable: 'never',
                width: 120,
            },
            {
                title: '工单类型',
                field: 'orderType',
                editable: 'never',
                render: row => row ? <Typography>{renderOrderType(row)}</Typography> : '',
                width: 130,
            },
            {
                title: '预算(元)',
                field: 'orderBudget',
                editable: 'onUpdate',
                render: row => row ? <Typography>{row.orderBudget}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        value={props.rowData.orderBudget}
                        type="number"
                        onChange={e => props.onChange(_.toNumber(e.target.value))}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}>
                    </TextField>
                ),
                width: 100,
            },]

        if (routeType === 'all') {
            columns = _.concat(columns, [
                {
                    title: '决算(元)',
                    field: 'orderCost',
                    editable: 'never',
                    render: row => row ? <Typography>{row.orderCost}</Typography> : '',
                    width: 100,
                },])
        }
        columns = _.concat(columns, [{
            title: '工单内容',
            field: 'orderContent',
            editable: 'onUpdate',
            validate: rowData => (!rowData.orderContent || rowData.orderContent === '') ? { isValid: false, helperText: '工单内容不能为空' } : true,
            render: row => row ? <Typography className={classes.longContent} title={row.orderContent}>{row.orderContent}</Typography> : '',
            width: 300,
        }])

        if (routeType === 'all') {
            columns = _.concat(columns, [{
                title: '运维内容',
                field: 'opentext',
                editable: 'never',
                render: row => row ? <Typography className={classes.longContent} title={row.opentext}>{row.opentext}</Typography> : '',
                width: 300,
            }])
        }

        columns = _.concat(columns, [
            {
                title: '承包商',
                field: 'contractor',
                render: row => row ? renderContractor(row) : '',
                editable: 'never',
                width: 220,
            },
            {
                title: '负责人',
                align: 'center',
                field: 'orderHead',
                render: row => row ? renderOrderHead(row) : '',
                editable: 'never',
                width: 100,
            },
            {
                title: '创建人',
                align: 'center',
                field: 'creator',
                render: row => row ? renderCreator(row) : '',
                editable: 'never',
                width: 100,
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Chip size="small" className={classes[row.status]} label={renderStatus(row)} /> : '',
                editable: 'never',
                width: 100,
            }
        ])

        return columns
    }

    const renderPermitPanel = () => {
        let bg = 'info';
        if (workOrderApprovers && workOrderApprovers.length > 0) {
            if (workOrderApprovers[0].preId) {
                if (workOrderApprovers[0].confirm && workOrderApprovers[0].confirmTime) bg = 'success';
                if (!workOrderApprovers[0].confirm && workOrderApprovers[0].confirmTime) bg = 'error';
            } else {
                if (workOrderApprovers[0].confirm && workOrderApprovers[0].confirmTime) bg = 'warning';
            }
        }
        return bg;
    }

    const renderActions = () => {
        if (!actions || actions.length === 0) {
            return
        } else {
            let buttons = actions.map((action, idx) => (
                <Button
                    key={`rule_button_${idx}`}
                    variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                    color="primary"
                    className={classes.button}
                    onClick={onhandleProcess.bind(this, action)}
                >
                    {action.actionName}
                </Button>
            ))
            return buttons
        }
    }

    const renderConfirm = (row) => {
        let style = null;
        let text = '开始审批';
        if (row.confirm && row.confirmTime) {
            style = classes.confirmTrue;
            if (row.preId) {
                text = '通过';
            } else {
                text = '提交审批';
            }
        }
        if (!row.confirm && row.confirmTime) {
            style = classes.confirmFalse;
            text = '驳回';
        }
        return <Typography display={'inline'} className={style} style={{ display: 'inline-block', minWidth: 48, marginLeft: 16 }}>{text}</Typography>
    }

    const renderDate = (date) => {
        return date ? getDateByString(date) : ''
    }

    const renderPlan = () => {
        let columns = [];
        columns.push(
            {
                title: '对象',
                field: 'group',
                defaultGroupOrder: 0,
                defaultGroupSort: 'desc',
            },
            {
                field: 'type',
                render: row => row ? row.device && row.device.deviceId ?
                    <ShowCard currentInfo={row.device} target={row.target}
                        child={
                            < Avatar className={classes.pic1} > 设</Avatar >}
                    />
                    :
                    < ShowCard currentInfo={row.accessory} target={row.target}
                        child={
                            < Avatar className={classes.pic2} > 配</Avatar >}
                    />
                    //     < Avatar className = { classes.pic1 } > 设备</Avatar > :
                    // <Avatar className={classes.pic2}>部件</Avatar> :
                    : '',
                width: '1em',
                editable: 'never'
            },
            {
                title: '单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '计划编号',
                field: 'optNo',
                render: row => row ? <Typography>{row.optNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维计划',
                field: 'ruleName',
                render: row => row ? row.rule ? <Typography>{row.rule.ruleName}</Typography> : <Typography>{'临时计划'}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维类别',
                field: 'optType',
                render: row => row ? <Typography>{renderOptCat(row)}</Typography> : '',
                validate: rowData => (!rowData.optType || rowData.optType === "") ? { isValid: false, helperText: '运维类别不能为空' } : true,
                editable: 'never'
            },
            {
                title: '预算(元)',
                field: 'budget',
                render: row => row ? <Typography>{row.budget}</Typography> : '',
                editable: 'never'
            },
            {
                title: '决算(元)',
                field: 'finalCost',
                render: row => row ? <Typography>{row.finalCost}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
            }
        )
        return columns
    }

    const renderItems = () => {
        return <Grid>
            <Typography variant="h4" className={classes.subTitle}>
                {'关联计划'}
            </Typography>
            <MaterialTable
                components={{
                    // 修复表头样式错乱问题
                    Header: props => <MTableHeader {...props} classes={{ header: classes.mtHeader }} />
                }}
                options={detailOptions}
                columns={renderPlan()}
                data={planDetails}
                style={tableStyle}
            />
        </Grid>
    }

    const detailOptions = {
        ...options,
        toolbar: false,
        paging: false,
        showTitle: false,
        defaultExpanded: true,
    }


    const renderHistoy = () => {
        if (workOrderApprovers && workOrderApprovers.length > 0) {
            let histoy = workOrderApprovers.map((workOrderApprover, idx) => {
                const noConfirm = Boolean(!workOrderApprover.confirm && !workOrderApprover.confirmTime);
                const trueConfirm = Boolean(workOrderApprover.confirm && workOrderApprover.confirmTime);
                const falseConfirm = Boolean(!workOrderApprover.confirm && workOrderApprover.confirmTime);
                return (
                    <ListItem key={`${workOrderApprover.aprvId}`} className={classes.permitHistoryItem} dense >
                        <ListItemIcon style={{ minWidth: '2em' }}>
                            {noConfirm ? <PersonAdd fontSize={'small'} /> : null}
                            {trueConfirm ? <Done fontSize={'small'} className={classes.confirmTrue} /> : null}
                            {falseConfirm ? <Block fontSize={'small'} className={classes.confirmFalse} /> : null}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Grid container>
                                    <Grid item>
                                        <Typography display={'inline'} color={'primary'}>{renderDate(workOrderApprover.confirmTime ? workOrderApprover.confirmTime : workOrderApprover.createTime)}</Typography>
                                        <Typography display={'inline'} variant={'h5'} style={{ display: 'inline-block', minWidth: 64, marginLeft: 16 }}><strong>{' ' + workOrderApprover.lastName + workOrderApprover.firstName + ' '}</strong></Typography>
                                        {renderConfirm(workOrderApprover)}
                                        <Typography display={'inline'} style={{ display: 'inline-block', minWidth: 48, marginLeft: 16 }}>{Boolean(workOrderApprover.opentext) ? workOrderApprover.opentext : null}</Typography>
                                        {workOrderApprover.orderApprovalDoc ?
                                            <React.Fragment>
                                                <Typography display={'inline'} align={'right'}>附件：</Typography>
                                                <Link display={'inline'} underline='always' onClick={() => onDownLoadDoc(workOrderApprover.orderApprovalDoc, 'process-doc')} component="button">{workOrderApprover.orderApprovalDoc.docName}</Link>
                                            </React.Fragment>
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem >
                )
            })
            return histoy
        } else {
            return
        }
    }

    // const components = {
    //     Toolbar: props => (

    //     )
    // }

    const tableOptions = {
        ...options,
        toolbar: false,
        showTitle: false,
        search: false,
        paging: false,
        defaultExpanded: true,
        // tableLayout: 'fixed',
    }
    const tableStyle = {
        ...style
    }

    const renderTableActions = () => {
        let actions = [];
        if (checkEditPermission()) {
            if (specChecker) {
                if (currentProcessActiveUser || mainStartUser) {
                    actions = [
                        rowData => ({
                            icon: () => <PostAdd color={'primary'} />,
                            tooltip: '创建子工单',
                            hidden: Boolean(rowData.masterOrderNo),
                            onClick: (event, rowData) => {
                                onCreateSub(event, rowData)
                            }
                        })]
                }
            } else {
                if (globalStatus === 'new' || globalStatus === 'rejected') {
                    actions = [
                        rowData => ({
                            icon: () => <PostAdd color={'primary'} />,
                            tooltip: '创建子工单',
                            hidden: Boolean(rowData.masterOrderNo),
                            onClick: (event, rowData) => {
                                onCreateSub(event, rowData)
                            }
                        })]
                }
            }
        }

        actions.push(rowData => ({
            icon: () => <Visibility color={'primary'} />,
            tooltip: '预览文件',
            hidden: !Boolean(rowData.docs && _.size(rowData.docs) > 0),
            onClick: (event, rowData) => {
                onDownLoadDoc(rowData.docs, 'operationdoc')
            }
        }))


        return actions
    }

    const checkEditPermission = () => {
        let rtn = false;
        // if (specChecker && currentProcessActiveUser) {
        //     rtn = true
        // } else if (permission && globalStatus === 'new' && !specChecker) {
        //     rtn = true
        // }
        if (specChecker) {
            if (mainStartUser) {
                rtn = true
            } else if (currentProcessActiveUser) {
                rtn = true
            }
        } else {
            if (permission && (globalStatus === 'new' || globalStatus === 'rejected')) {
                rtn = true
            }
        }
        return rtn
    }

    const handleDrawerClose = () => {
        setProcessDrawerOpen(false)
    }

    const assemblyAccessorycolumns = () => {
        let columns = [
            {
                title: '部件编号',
                field: 'accNo',
                render: row => row ? <Typography>{row.accNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件名称',
                field: 'accName',
                render: row => row ? <Typography>{row.accName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件类别',
                field: 'cat',
                render: row => row ? <Typography>{renderAccCat(row.cat)}</Typography> : '',
                editable: 'never',
            },
            {
                title: '数量',
                field: 'amount',
                render: row => row ? <Typography>{row.amount}</Typography> : '',
            },

            // 缺陷 部件页面 使用状态删除 SBGL-274
            // {
            //     title: '使用状态',
            //     field: 'status',
            //     render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '更新时间',
            //     field: 'update_time',
            //     type: 'date',
            //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            //     editable: 'never'
            // }
        ]

        return columns
    }

    const processDialog = () => {
        return (
            <Drawer anchor={'right'} open={processDrawerOpen} PaperProps={{ style: { minWidth: '60%' } }}
                onClose={handleDrawerClose}>
                <Alert
                    className={classes.alert}
                    classes={{
                        message: classes.permitBar,
                    }}
                    severity={renderPermitPanel()}>
                    <AlertTitle>
                        <Grid container justify={'space-between'}>
                            <Typography variant={'h4'}>{'审批状态'}</Typography>
                        </Grid>
                    </AlertTitle>
                    <Grid container>
                        <Grid item className={classes.processline}>
                            {workOrderApproversLast ?
                                <ProcessLineWithUser idKey={'aprvId'} treeData={arrayToTree(_.cloneDeep(workOrderApproversLast), 'aprvId', 'preId')} processNotices={workOrderNotices} />
                                : null
                            }
                        </Grid>
                    </Grid>
                    {workOrderApprovers && workOrderApprovers.length > 0 ?
                        <Grid container style={{ marginTop: '16px' }}>
                            <Grid item className={classes.processUser}>
                                <Typography variant={'h4'}>审批记录</Typography>
                                <List className={classes.historyList}>
                                    {renderHistoy()}
                                </List>
                            </Grid>
                        </Grid>
                        : null
                    }
                    <Grid item style={{ height: '100%' }} ></Grid>
                    <Grid item container justify={'flex-end'} className={classes.processActionGrid}>
                        <List className={classes.processAction}>
                            {renderActions()}
                        </List>
                    </Grid>
                </Alert>
            </Drawer>)
    }

    return (
        <Card alignitems="center" className={classes.card}>
            {processDialog()}
            <div>
                <Grid container alignItems="center" justify="space-between" className={classes.title}>
                    <Grid item container xs={10}>
                        <Typography variant="h3">
                            {'工单编辑'}
                        </Typography>
                        {/* <Chip className={classes.tag} size='small' label={'本想显示工单状态，但下面的工单已经显示了状态；'} /> */}
                    </Grid>
                    {renderProcessFlowEditButton()}
                </Grid>
                {Boolean(planDetails && planDetails.length > 0) && <React.Fragment>
                    {planDetails.length === 1 ? <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.subTitle}>
                                {'基本信息'}
                            </Typography>
                        </Grid>
                        <Grid container className={classes.basicInfoContainer}>
                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    单&emsp;&emsp;位
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].companyId ? renderCompany(planDetails[0].companyId) : '-'}
                                </Typography>
                            </Grid>

                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    计划编号
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].optNo ? planDetails[0].optNo : '-'}
                                </Typography>
                            </Grid>

                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    运维计划
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].rule && planDetails[0].rule.ruleName ? planDetails[0].rule.ruleName : '临时计划'}
                                </Typography>
                            </Grid>

                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    运维类别
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0] ? renderOptCat(planDetails[0]) : '-'}
                                </Typography>
                            </Grid>

                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    预算 (元)
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].budget ? planDetails[0].budget : '-'}
                                </Typography>
                            </Grid>

                            <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                <Typography className={classes.label}>
                                    决算 (元)
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].fianlCost ? planDetails[0].fianlCost : '-'}
                                </Typography>
                            </Grid>

                            <Grid container item xs={6}>
                                <Typography className={classes.label}>
                                    计划描述
                                </Typography>
                                <Typography className={classes.content}>
                                    {planDetails[0].opentext ? planDetails[0].opentext : '-'}
                                </Typography>
                            </Grid>

                        </Grid>

                        {Boolean(planDetails[0].device && planDetails[0].device.deviceId) &&

                            <Grid container className={classes.basicInfoContainer}>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备编号
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.deviceNo ? planDetails[0].device.deviceNo : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备名称
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.deviceName ? planDetails[0].device.deviceName : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备位号
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.tagNo ? planDetails[0].device.tagNo : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        设备类别
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].device.cat ? renderCat(planDetails[0].device.cat) : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>}

                        {Boolean(planDetails[0].accessory && planDetails[0].accessory.accId) &&
                            <Grid container className={classes.basicInfoContainer}>
                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        部件编号
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.accNo ? planDetails[0].accessory.accNo : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        部件名称
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.accName ? planDetails[0].accessory.accName : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        型&emsp;&emsp;号
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.model ? planDetails[0].accessory.model : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        部件类别
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.cat ? renderAccCat(planDetails[0].accessory.cat) : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        关联设备编号
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.deviceNo ? planDetails[0].accessory.deviceNo : '-'}
                                    </Typography>
                                </Grid>

                                <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                                    <Typography className={classes.label}>
                                        关联设备名称
                                    </Typography>
                                    <Typography className={classes.content}>
                                        {planDetails[0].accessory.deviceName ? planDetails[0].accessory.deviceName : '-'}
                                    </Typography>
                                </Grid>

                            </Grid>}

                    </React.Fragment> : renderItems()}

                </React.Fragment>}
            </div>
            <Grid>
                <Typography variant="h4" className={classes.subTitle}>
                    {'工单一览'}
                </Typography>
                <Grid> </Grid>
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialTable}
                    components={{
                        // 修复表头样式错乱问题
                        Header: props => <MTableHeader {...props} classes={{ header: classes.mtHeader }} />
                    }}
                    columns={assemblycolumns()}
                    data={_.sortBy(workOrderList.list, 'orderId')}
                    options={tableOptions}
                    // components={components}
                    localization={localization}
                    style={tableStyle}
                    editable={{
                        isDeleteHidden: rowData =>
                            Boolean(!rowData.masterOrderNo)
                            || !checkEditPermission()
                        ,
                        isEditHidden: rowData =>
                            !checkEditPermission()
                        ,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleUpdate(newData)
                                resolve()
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDelete(oldData)
                                resolve()
                            })
                    }}
                    actions={renderTableActions()}
                    icons={{
                        Edit: () => <Edit color={'primary'} />,
                        Delete: () => <DeleteOutlined color={'error'} />
                    }}
                >
                </MaterialTable>
            </Grid>
            {planDetails.length === 1 && planDetails[0].optType === OPT_CAT.OPT_ACC_UPD ? <Grid>

                <Grid container alignItems='center'>
                    <Grid item>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'新部件列表'}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.processline}></Grid>

                    {checkEditPermission() ?
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => onhandleReplaceAccessory()}>{'选择新部件'}</Button>
                        </Grid> : <Grid></Grid>}
                    {/* {
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => onhandleReplaceAccessory()}>{'选择新部件'}</Button>
                        </Grid>} */}
                </Grid>

                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblyAccessorycolumns()}
                    data={accessoryList}
                    options={{
                        ...options,
                        tableLayout: 'auto',
                        search: false,
                        toolbar: false,
                        paging: false,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    editable={{
                        isDeleteHidden: rowData =>
                            Boolean(!rowData.accId)
                            || !checkEditPermission()
                        ,
                        isEditHidden: rowData =>
                            !checkEditPermission()
                        ,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleUpdateAccessory(newData)
                                resolve()
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDeleteAccessory(oldData)
                                resolve()
                            })
                    }}
                    actions={checkEditPermission() ?
                        [
                            //     rowData => ({
                            //     icon: () => <Edit color={'primary'} />,
                            //     tooltip: '编辑',
                            //     hidden: rowData.status === 'inactive',
                            //     onClick: (event, rowData) => {
                            //         onEdit(event, rowData.accId)
                            //     }
                            // }),
                            // rowData => ({
                            //     icon: 'adjust',
                            //     tooltip: '激活',
                            //     onClick: (event, rowData) => {
                            //         onActivate(event, rowData)
                            //     },
                            //     hidden: rowData.status !== 'new'
                            // }),
                            // rowData => ({
                            //     icon: 'post_add',
                            //     tooltip: '拷贝新建',
                            //     onClick: (event, rowData) => {
                            //         onCopy(event, rowData.accId)
                            //     },
                            //     hidden: rowData.status === 'inactive'
                            // }),
                            // {
                            //     icon: () => <Add color={'primary'} />,
                            //     tooltip: '新建',
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onhandleReplaceAccessory()
                            //     }
                            // },
                            // rowData => ({
                            //     icon: () => <DeleteOutlined color={'error'} />,
                            //     tooltip: '删除',
                            //     hidden: rowData.status === 'inactive',
                            //     onClick: (event, rowData) => {
                            //         onhandleDeleteAccessory(event, rowData)
                            //     },
                            // })
                        ] : [
                            // rowData => ({
                            //     icon: () => <Description color={'primary'} />,
                            //     tooltip: '查看',
                            //     onClick: (event, rowData) => {
                            //         onView(event, rowData.accId)
                            //     }
                            // })
                        ]}
                >
                </MaterialTable>
            </Grid> : <Grid></Grid>
            }
            <Grid container spacing={0} className={classes.gridContainer}>
                <Button variant="outlined" color="primary"
                    onClick={event => onhandleBack(event)}
                >
                    返回
                </Button>
            </Grid>
        </Card >
    );
};

WorkOrderProcess.propTypes = {
    orderTypeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    planDetails: PropTypes.array.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    optCategories: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    accCatList: PropTypes.array.isRequired,
    onCreateSub: PropTypes.func.isRequired,
    onhandleAssignHead: PropTypes.func.isRequired,
    onhandleAssign: PropTypes.func.isRequired,
    onhandleProcess: PropTypes.func.isRequired,
};

export default withStyles(WorkOrderProcess);
