import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate_hms } from '@/utils/datetime';

const SalOrderComponent = props => {
    const { classes,
        selectedDate,
        onhandleDateChange,
        salorderList,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: true,
    }
    const tableStyle = {
        ...style
    }

    const detailTableStyle = {
        ...style,
        paddingLeft: 45
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单价编号',
                field: 'billNo',
                render: row => <Typography>{row.billNo}</Typography>
            },
            {
                title: '客户',
                field: 'customerName',
                render: row => <Typography>{row.customerName}</Typography>
            },
            {
                title: '销售人员',
                field: 'salmanName',
                render: row => <Typography>{row.salmanName}</Typography>
            },
            {
                title: '销售时间',
                field: 'orderDate',
                render: row => <Typography>{formatDate_hms(row.orderDate)}</Typography>
            }
        ]

        return columns

    }

    const assemblycolumnsOrderInfo = () => {

        const columns = [
            {
                title: '物料',
                field: 'materialName',
                render: row => <Typography>{row.material ? row.material.materialName : ""}</Typography>

            }, {
                title: '规格',
                field: 'specification',
                render: row => <Typography>{row.material ? row.material.specification : ""}</Typography>
            },
            {
                title: '价格',
                field: 'unitPrice',
                align: 'right',
                render: row => <Typography>{row.unitPrice}</Typography>
            },
            {
                title: '含税价格',
                field: 'taxPrice',
                align: 'right',
                render: row => <Typography>{row.taxPrice}</Typography>
            },
            {
                title: '数量',
                field: 'qty',
                align: 'right',
                render: row => <Typography>{row.qty}</Typography>
            },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit ? row.unit.unitName : ""}</Typography>
            }
        ]

        return columns

    }

    const assemblyData = () => {
        return salorderList
    }

    const assemblyDataOrderInfo = (salorder) => {
        return salorder.salOrderDtls ? salorder.salOrderDtls : []
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>销售记录列表</Typography>}
                        detailPanel={[{
                            render: salorder => {
                                return (
                                    <Grid className={classes.detailTable}
                                    >
                                        <MaterialTable
                                            columns={assemblycolumnsOrderInfo()}
                                            data={assemblyDataOrderInfo(salorder)}
                                            options={{ ...options, paging: false }}
                                            localization={tableLocalization}
                                            style={detailTableStyle}
                                            title={<Typography variant='h4'>销售明细列表</Typography>}
                                        >
                                        </MaterialTable>
                                    </Grid>
                                )
                            }
                        }]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

SalOrderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

SalOrderComponent.defaultProps = {
    salorderList: []
};

export default withStyles(SalOrderComponent);