import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTableTechParam, setDeviceTechParamChange, backToList, modifyDeviceTechParam, initDeviceTechParam } from '../../../redux/modules/devices';
import DeviceTechParam from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceTechParam';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

class DeviceTechParamContainer extends Component {
    static propTypes = {
        unitList: PropTypes.array,
        logicList: PropTypes.array,
        techParam: PropTypes.array,
        currentDevice: PropTypes.object,
        valtypeList: PropTypes.array,
        tableTechParam: PropTypes.array,
        userProfile: PropTypes.object,
        deviceTechParamChange: PropTypes.bool,
        techParamTemp: PropTypes.array,
        isview: PropTypes.bool,
        techParamWarning: PropTypes.bool,

    }

    componentWillMount() {
        //获取设备的技术参数
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceTechParam()
        }
    }

    _generateTemplate = (template) => {
        return template.logic + '{$target=' + template.vals + '}'
    }

    handleAdd(newData) {
        newData._id = uuidv4()
        newData.template = this._generateTemplate(newData)
        let value = _.cloneDeep(this.props.tableTechParam)
        value.push(newData)
        this.props.onhandleAdd(value)
        this.props.onhandleDeviceTechParamChange(true)
    }

    handleUpdate = async (newData) => {
        newData.template = this._generateTemplate(newData)
        let index = newData._id
        let value = _.cloneDeep(this.props.tableTechParam)
        for (let i = 0; i < value.length; i++) {
            if (value[i]._id === index) {
                value[i] = newData
            }
        }
        this.props.onhandleUpdate(value)
        this.props.onhandleDeviceTechParamChange(true)
    }

    handleDelete = async (newData) => {
        newData.template = this._generateTemplate(newData)
        let index = newData._id
        let value = _.cloneDeep(this.props.tableTechParam)
        let list = _.remove(value, function (n) {
            return n._id !== index;
        });
        this.props.onhandleDelete(list)
        this.props.onhandleDeviceTechParamChange(true)
    }

    handleSave = async (event) => {
        this.props.onhandleDeviceTechParamChange(false);
        this.props.onhandleUpdateDeviceTechParam()
    }

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    render() {
        return (
            <div>
                <DeviceTechParam
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    tabValue={this.props.tabValue}
                    index={this.props.index}
                    unitList={this.props.unitList}
                    logicList={this.props.logicList}
                    techParam={this.props.techParam}
                    techParamTemp={this.props.techParamTemp}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    userProfile={this.props.userProfile}
                    deviceTechParamChange={this.props.deviceTechParamChange}
                    tableTechParam={this.props.tableTechParam}
                    valtypeList={this.props.valtypeList}
                    isView={this.props.isview}
                    techParamWarning={this.props.techParamWarning}
                    isDeviceEdit={this.props.isDeviceEdit}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDeviceEdit: state.devices.deviceEdit,
        userProfile: state.auth.userProfile,
        unitList: state.devices.unit,
        logicList: state.devices.logic,
        valtypeList: state.devices.valtype,
        deviceTechParamChange: state.devices.deviceTechParamChange,
        currentDevice: state.devices.currentDevice,
        techParamTemp: state.devices.techParamTemp,
        techParam: state.devices.techParam,
        tableTechParam: state.devices.tableTechParam,
        isview: state.devices.view,
        techParamWarning: state.devices.techParamWarning,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdate: (entity) => {
            dispatch(setTableTechParam(entity))
        },
        onhandleDelete: (entity) => {
            dispatch(setTableTechParam(entity))
        },
        onhandleAdd: (entity) => {
            dispatch(setTableTechParam(entity))
        },
        onhandleDeviceTechParamChange: (isChanged) => {
            dispatch(setDeviceTechParamChange(isChanged))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleUpdateDeviceTechParam: (entity) => {
            dispatch(modifyDeviceTechParam(entity))
        },
        onhandleInitDeviceTechParam: () => {
            dispatch(initDeviceTechParam())
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceTechParamContainer);