import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getManufactureServices, delManufactureService
} from '@/redux/modules/manufacture/manufactureService';
import { judgeMainCompany, SERVICE_TYPE_COMMISSION } from '@/utils/manufactureUtils';
import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { getMaterialGroups } from '@/redux/modules/material';
import MCommissionListComponent from '@/components/ManufactureManagement/ManufactureService/MCommissionList';
import MServiceConfigContainer from './MServiceConfigContainer';
import { getRole } from "@/utils/userUtils";

class MCommissionListContainer extends Component {
    static propTypes = {
        services: PropTypes.array,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            createOpen: false,
            opreationType: 0,
            isMainCompany: false,
            subCompanyList: [],
            amoebaIndex: '',
            serviceType: '',
            serviceCompany: '',
            filterFlag: false,
            filterServices: [],
        }
    }

    componentDidMount() {

        const { userCompanyId, companyList, getMaterialGroups, getServices } = this.props;
        let flag = judgeMainCompany(companyList, userCompanyId);
        // 获取母公司Id
        let reqMainCompanyId = null;
        // 获取下属公司
        const subCompanyList = companyList.filter((element) => element.pid === userCompanyId);

        if (flag) {
            getServices();
        } else {
            reqMainCompanyId = companyList.find(ele => ele.companyId === userCompanyId).pid;
            getServices(userCompanyId, reqMainCompanyId);
        }
        getMaterialGroups();
        this.setState({
            isMainCompany: flag,
            reqMainCompanyId: reqMainCompanyId,
            subCompanyList: subCompanyList
        })
    }

    handleUpdate = (rowData) => {
        this.setState({
            createOpen: true,
            opreationType: 2,
            service: rowData
        });
    }

    handleDelete = (rowData) => {
        const { userCompanyId, delManufactureService, getServices } = this.props;
        const { isMainCompany, reqMainCompanyId } = this.state;
        return delManufactureService(rowData.serviceId).then(
            () => {
                if (isMainCompany) {
                    getServices();
                } else {
                    getServices(userCompanyId, reqMainCompanyId);
                }
            }
        );
    }

    handleAdd = () => {
        this.setState({
            createOpen: true,
            opreationType: 1,
            service: { serviceId: 0, activities: [] }
        })
    }

    handleClose = (closeType) => {
        const { userCompanyId, getServices } = this.props;
        const { isMainCompany, reqMainCompanyId } = this.state;
        if (closeType === 'save') {
            if (isMainCompany) {
                getServices();
            } else {
                getServices(userCompanyId, reqMainCompanyId);
            }
        }
        this.setState({
            createOpen: false,
        })
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'amoebaIndex') {
            this.setState({
                amoebaIndex: value,
            })
        }
        if (name === 'serviceType') {
            this.setState({
                serviceType: value,
            })
        }
        if (name === 'serviceCompany') {
            this.setState({
                serviceCompany: value,
            })
        }
    }

    handleFilterClick = () => {
        const { amoebaIndex, serviceCompany } = this.state;
        console.log(serviceCompany)
        console.log(this.props.services)
        const filterServices = this.props.services.filter((ele) => {
            return (amoebaIndex ? ele.amoebaIndex === amoebaIndex : true)
                && (serviceCompany ? ele.requireCompany === serviceCompany : true)
        });

        this.setState({
            filterServices: filterServices,
            filterFlag: true,
        })
    }

    handleClearClick = () => {
        this.setState({
            filterFlag: false,
            amoebaIndex: '',
            serviceType: '',
            serviceCompany: '',
        })
    }

    render() {
        let services = this.props.services;
        const { filterFlag, filterServices } = this.state;
        if (filterFlag) {
            services = filterServices
        }
        const roleAction = this.props.roleRoutes.find(ele => ele.route === 'amoeba_commission_config');
        const roleEditFlag = roleAction ? roleAction.action === 'W' : false;

        return (
            <div>
                <MCommissionListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    services={services}
                    companyList={this.props.companyList}
                    isMainCompany={this.state.isMainCompany}
                    subCompanyList={this.state.subCompanyList}
                    departmentList={this.props.departmentList}
                    manufactureDicts={this.props.manufactureDicts}
                    businessGroupList={this.props.businessGroupList}
                    onhandleFilterClick={this.handleFilterClick.bind(this)}
                    onhandleClearClick={this.handleClearClick.bind(this)}
                    amoebaIndex={this.state.amoebaIndex}
                    serviceType={this.state.serviceType}
                    serviceCompany={this.state.serviceCompany}
                    roleEditFlag={roleEditFlag}
                    onhandleChange={this.handleChange.bind(this)}
                />
                {  this.state.createOpen &&
                    <MServiceConfigContainer
                        service={this.state.service}
                        opreationType={this.state.opreationType}
                        isMainCompany={this.state.isMainCompany}
                        reqMainCompanyId={this.state.reqMainCompanyId}
                        serviceType={SERVICE_TYPE_COMMISSION}
                        onhandleClose={this.handleClose.bind(this)}
                        roleEditFlag={roleEditFlag}
                        viewFlg={1}
                    />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        services: state.manufactureService.commissionServices,
        companyList: state.company.companyList.list,
        departmentList: state.department.departments.list,
        userCompanyId: state.auth.currentUserInfo.companyId,
        materialGroups: state.material.materialGroups,
        manufactureDicts: state.constant.manufactureDicts,
        businessGroupList: state.businessGroup.businessGroupList,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (requireCompany, reqMainCompany) => {
            dispatch(getManufactureServices(requireCompany, SERVICE_TYPE_COMMISSION, null, null, reqMainCompany));
        },
        delManufactureService: (data) => {
            return dispatch(delManufactureService(data));
        },
        getMaterialGroups: () => {
            return dispatch(getMaterialGroups());
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MCommissionListContainer);
