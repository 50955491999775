import React from 'react';
// import withStyles from '../AppointmentWorkPermit/styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Chip,
    Link,
    // List,
    // ListItem,
    // ListItemIcon,
    // ListItemText
} from '@material-ui/core';
// import {
//     Alert, AlertTitle
// } from '@material-ui/lab';
// import {
//     PersonAdd,
//     Block,
//     Done,
// } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDateByTimestamp } from '@/utils/datetime';
import { truncate } from '@/utils/string';
import { STATUS_VALUE } from '@/utils/constant';
// import ProcessLineWithUser from '../../../ProcessLineWithUser/ProcessLineWithUser';
// import { arrayToTree, findSameLevel } from '@/utils/constant';
import _ from 'lodash';


const AppointmentWorkPermit = ({
    classes,
    isSpecWork,
    isAppHead,
    isOrderHead,
    appReadOnly,
    currentAppointment,
    currentPermit,
    userList,
    userProfile,
    onhandlePermit,
    onhandleDeletePermit,
    onhandleSelectApprover,
    onhandleSubmitPermit,
    onhandleRejectPermit,
    onhandleApprovePermit,
    onhandleDownload,
    onhandleDonePermit,
    viewMode,
    showtype
}) => {

    // let currentUserId = userProfile.userId;

    /** 有许可记录 */
    const hasPermit = Boolean(isSpecWork &&
        currentAppointment.permits &&
        currentAppointment.permits.length &&
        currentAppointment.permits.length > 0
    );

    // // 获取当前许可(改为redux保存)
    // const getCurrentPermit = () => {
    //     if (!hasPermit) return {};
    //     let permit = null;
    //     const result = _.find(currentAppointment.permits, o => {
    //         return [STATUS_VALUE.STATUS_NEW, STATUS_VALUE.STATUS_SUBMITTED, STATUS_VALUE.STATUS_APPROVED]
    //             .includes(o.status);
    //     })
    //     if (result) permit = result
    //     return permit;
    // }
    // /** 当前可用许可 */
    // const currentPermit = getCurrentPermit();

    // const checkLastStep = () => {
    //     let rtn = false;
    //     let userId = userProfile.userId;
    //     if (currentPermit.permitUsers && currentPermit.permitUsers.length > 0) {
    //         let userApprover = _.find(currentPermit.permitUsers, { userId: userId });
    //         if (userApprover) {
    //             if (!userApprover.confirm) {
    //                 console.log(currentPermit.permitUsers)
    //                 let fatherSameLevelList = findSameLevel(_.cloneDeep(currentPermit.permitUsers), userApprover.preId, 'puId', 'preId');
    //                 console.log(fatherSameLevelList)
    //                 if (fatherSameLevelList && fatherSameLevelList.length > 0) {
    //                     if (_.filter(fatherSameLevelList, function (o) { return !o.confirmTime }).length > 0) {
    //                         rtn = false;
    //                     } else {
    //                         rtn = true;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     console.log(rtn)
    //     return rtn;
    // }

    /** 许可为有效状态 */
    const hasValidPermit = Boolean(currentPermit && Object.keys(currentPermit).length > 0);

    const hasApprovedPermit = Boolean(currentPermit && (_.filter(currentAppointment.permits, { status: 'approved' }).length > 0));

    const hasClosePermit = Boolean(showtype === 'close') && Boolean(currentPermit && !(_.filter(currentAppointment.permits, { status: 'done' }).length > 0));

    /** 许可状态是New */
    const hasDraftPermit = Boolean(currentPermit && currentPermit.status === STATUS_VALUE.STATUS_NEW);

    /** 当前作业验收 */
    const appAcceptanced = Boolean([STATUS_VALUE.STATUS_ACCEPTANCED].includes(currentAppointment.status));

    // /** 许可状态是Submitted */
    // const hasSubmittedPermit = Boolean(currentPermit && currentPermit.status === STATUS_VALUE.STATUS_SUBMITTED);

    // /** 已创建许可审批人(仅关联当前许可) */
    // const hasPermitUsers = Boolean(currentPermit && currentPermit.permitUsers && currentPermit.permitUsers.length && currentPermit.permitUsers.length > 0);

    // /** 许可审批人已进行过记录 */
    // // TODO:const hasConfirmedUsers = Boolean(hasPermitUsers && _.findIndex(currentPermit.permitUsers, o => (o.confirmTime)) > -1);
    // const hasConfirmedUsers = Boolean(hasPermitUsers && currentPermit.permitUsersHistory && currentPermit.permitUsersHistory.length && currentPermit.permitUsersHistory.length > 0);

    // /** 许可开始审批流程 */
    // const hasApprovingPermit = Boolean(
    //     hasValidPermit && (
    //         // 提交状态
    //         [STATUS_VALUE.STATUS_SUBMITTED].includes(currentPermit.status)
    //         // New状态但有审批履历
    //         || ([STATUS_VALUE.STATUS_NEW].includes(currentPermit.status) && hasConfirmedUsers)
    //     )
    // )

    // /** 当前用户是审批人 */
    // const isApprover = Boolean(hasPermitUsers && _.findIndex(currentPermit.permitUsers, { 'userId': currentUserId }) > -1);

    // /** 当前用户的最新审批记录 */
    // const userPermitRecord = isApprover && _.find(currentPermit.permitUsers, { 'userId': currentUserId });

    // /** 当前用户可进行审批 */
    // const userApprovable = Boolean(isApprover && !userPermitRecord.confirm);

    // const renderUser = (userId) => {
    //     let userName = '-';
    //     if (userList && userList.length > 0) {
    //         let user = _.find(userList, function (o) { return o.userId === userId })
    //         if (user) {
    //             userName = user.lastName + user.firstName;
    //         }
    //     }
    //     return userName;
    // }

    // const renderConfirm = (row) => {
    //     let style = null;
    //     let text = '开始审批';
    //     if (!row.preId) {
    //         style = classes.confirmTrue;
    //         text = '提交申请';
    //     } else {
    //         if (row.confirm && row.confirmTime) {
    //             style = classes.confirmTrue;
    //             text = '通过申请';
    //         }
    //         if (!row.confirm && row.confirmTime) {
    //             style = classes.confirmFalse;
    //             text = '驳回申请';
    //         }
    //     }

    //     return <Typography display={'inline'} className={style}>{text}</Typography>
    // }

    const renderDate = (date) => {
        return date ? getDateByTimestamp(date, true) : ''
    }

    // const renderPermitPanel = () => {
    //     // 许可框背景色，仅关联当前可用计划
    //     let bg = 'info';
    //     if (!hasValidPermit) return bg;
    //     if (!isSpecWork) return bg;
    //     if (currentPermit.status === 'submitted') bg = 'info';
    //     if (currentPermit.status === 'approved') bg = 'success';
    //     // if (permitRejected) bg = 'error';
    //     return bg;
    // }

    // const renderPermitChipTitle = (permitUser) => {
    //     let title = undefined;
    //     if (hasApprovingPermit && !permitUser.confirm && !permitUser.confirmTime) title = '审批中';
    //     if (permitUser.confirm && permitUser.confirmTime) title = '审批通过';
    //     if (!permitUser.confirm && permitUser.confirmTime) title = '审批驳回';
    //     return title;
    // }

    const renderPermitStatus = (status) => {
        let value = ''
        const permitStatus = { 'new': '有效', 'rejected': '驳回', 'submitted': '审核中', 'approved': '审核通过', 'inactive': '废止', 'done': '关闭' };
        value = permitStatus[status]
        return value;
    }

    const renderPermitStatusStyle = (status) => {
        let resultStyle = classes.pendingPermit;
        if (status === 'inactive') resultStyle = classes.inactivePermit;
        if (status === 'approved') resultStyle = classes.submittedPermit;
        if (status === 'done') resultStyle = classes.donePermit;
        if (status === 'new') resultStyle = classes.finalizedPermit;
        // if (permitRejected) resultStyle = classes.rejectedPermit;
        return resultStyle;
    }

    const permitColumns = [
        {
            title: '编号',
            field: 'serialNo',
            render: row => row ? <Typography>{row.serialNo}</Typography> : '',
            width: '5%',
            editable: 'never'
        },
        {
            title: '许可证名',
            field: 'ptwName',
            render: row => row ? <Typography>{row.ptwName}</Typography> : '',
            // width: '10%'
        },
        // {
        //     title: '有效期限',
        //     field: 'expiredTime',
        //     render: row => row ? <Typography>{row.expiredTime + '小时'}</Typography> : '',
        //     // width: '5%'
        // },
        // {
        //     title: '属地监护人',
        //     field: 'intGuarder',
        //     render: row => row ? <Typography>{renderUser(row.intGuarder)}</Typography> : '',
        //     // width: '5%'
        // },
        // {
        //     title: '作业监护人',
        //     field: 'intGuarder',
        //     render: row => row ? <Typography>{row.extGuarder}</Typography> : '',
        //     // width: '5%'
        // },
        {
            title: '申请时间',
            field: 'createTime',
            render: row => row ? <Typography>{renderDate(row.createTime)}</Typography> : '',
            // width: '5%'
        },
        {
            title: '备注',
            field: 'opentext',
            render: row => row ? <Typography title={row.opentext}>{truncate(row.opentext, 15)}</Typography> : '',
            // width: '10%'
        },
        {
            title: '许可证',
            field: 'doc',
            render: row => row && row.permitDocs && row.permitDocs[0] ?
                <Link component="button" onClick={() => { onhandleDownload(row.permitDocs[0]) }} className={classes.downloadPermit}>{row.permitDocs[0].docName}</Link> : '',
            // width: '10%'
        },
        {
            title: '状态',
            field: 'status',
            render: row => {
                return <Chip size="small" className={renderPermitStatusStyle(row.status)} label={renderPermitStatus(row.status, 'permitStatus')} />
            },
            width: '10%'
        },
    ]

    // const permitDetailPanel = [{
    //     tooltip: '展开审批履历',
    //     render: (row) => {
    //         if (row.permitUsersHistory && row.permitUsersHistory.length > 0) {
    //             return (
    //                 <Grid className={classes.permitHistory}>
    //                     <Typography><strong>{'审批履历'}</strong></Typography>
    //                     <List className={classes.historyList}>
    //                         {renderHistoy(row.permitUsersHistory)}
    //                     </List>
    //                 </Grid>
    //             )
    //         } else {
    //             return <Typography style={{ marginLeft: '4em' }}>{'无审批记录'}</Typography>
    //         }
    //     }
    // }]

    const permitTableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
        toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    // const renderHistoy = (permitUsersHistory) => {
    //     if (permitUsersHistory && permitUsersHistory.length > 0) {
    //         let histoy = permitUsersHistory.map((permitUserHistory, idx) => {
    //             const noConfirm = Boolean(!permitUserHistory.confirm && !permitUserHistory.confirmTime);
    //             const trueConfirm = Boolean(permitUserHistory.confirm && permitUserHistory.confirmTime);
    //             const falseConfirm = Boolean(!permitUserHistory.confirm && permitUserHistory.confirmTime);
    //             return (
    //                 <ListItem key={`${permitUserHistory.puId}`} className={classes.permitHistoryItem} dense >
    //                     <ListItemIcon style={{ minWidth: '2em' }}>
    //                         {noConfirm ? <PersonAdd fontSize={'small'} /> : null}
    //                         {trueConfirm ? <Done fontSize={'small'} className={classes.confirmTrue} /> : null}
    //                         {falseConfirm ? <Block fontSize={'small'} className={classes.confirmFalse} /> : null}
    //                     </ListItemIcon>
    //                     <ListItemText
    //                         primary={
    //                             <Grid container>
    //                                 <Grid item>
    //                                     <Typography display={'inline'} color={'primary'}>{renderDate(permitUserHistory.confirmTime ? permitUserHistory.confirmTime : permitUserHistory.createTime)}</Typography>
    //                                     <Typography display={'inline'} variant={'h5'}><strong>{' ' + permitUserHistory.lastName + permitUserHistory.firstName + ' '}</strong></Typography>
    //                                     {renderConfirm(permitUserHistory)}
    //                                     <Typography display={'inline'} style={{ marginLeft: 20 }}>{Boolean(permitUserHistory.opentext) ? permitUserHistory.opentext : null}</Typography>
    //                                 </Grid>
    //                             </Grid>
    //                         }
    //                     />
    //                 </ListItem >
    //             )
    //         })
    //         return histoy
    //     } else {
    //         return
    //     }
    // }

    return (
        <div>
            {/* classes={{
                message: classes.permitBar,
            }}
            severity={renderPermitPanel()}>
            <AlertTitle>
                <Grid container justify={'space-between'}>
                    <Typography><strong>{'作业许可'}</strong></Typography>
                    <Grid item>
                        {
                            !viewMode && isSpecWork && !appReadOnly && isAppHead && !hasConfirmedUsers && hasDraftPermit && !hasPermitUsers ?
                                <Button color="primary" variant="contained" size="small" mr={5} style={{ marginRight: 5 }}
                                    onClick={event => onhandleSelectApprover()}
                                >{'创建许可签发流程'}</Button>
                                : null
                        }
                        {
                            !viewMode && isSpecWork && !appReadOnly && isAppHead && !hasConfirmedUsers && hasDraftPermit && hasPermitUsers ?
                                <Button color="primary" variant="contained" size="small" mr={5} style={{ marginRight: 5 }}
                                    onClick={event => onhandleSelectApprover()}
                                >{'修改许可签发流程'}</Button>
                                : null
                        }
                        {
                            !viewMode && isSpecWork && !appReadOnly && isAppHead && hasDraftPermit && hasPermitUsers ?
                                <Button color="primary" variant="contained" size="small"
                                    onClick={event => onhandleSubmitPermit()}
                                >{'提交办理'}</Button>
                                : null
                        }
                        {
                            !viewMode && isSpecWork && !appReadOnly && userApprovable && !hasDraftPermit && !checkLastStep() ?
                                <>
                                    <Button color="primary" variant="outlined" size="small" style={{ marginRight: 5 }}
                                        onClick={event => onhandleRejectPermit()} currentUserId
                                    >{'驳回'}</Button>
                                    <Button color="primary" variant="contained" size="small"
                                        onClick={event => onhandleApprovePermit()}
                                    >{'允许办理'}</Button>
                                </>
                                : null
                        }
                        {
                            !viewMode && isSpecWork && !appReadOnly && userApprovable && !hasDraftPermit && checkLastStep() ?
                                <>
                                    <Button color="primary" variant="outlined" size="small" style={{ marginRight: 5 }}
                                        onClick={event => onhandleRejectPermit()} currentUserId
                                    >{'驳回'}</Button>
                                    <Button color="primary" variant="contained" size="small"
                                        onClick={event => onhandleApprovePermit()}
                                    >{'许可办理'}</Button>
                                </>
                                : null
                        }
                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item className={classes.processline}>
                        {isSpecWork && hasPermitUsers ?
                            <ProcessLineWithUser idKey={'puId'} treeData={arrayToTree(_.cloneDeep(currentPermit.permitUsers), 'puId', 'preId')} />
                            : null
                        }
                    </Grid>
                </Grid>
            </AlertTitle> */}
            {!isSpecWork &&
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Typography className={classes.title}>{'当前作业无需特殊作业许可'}</Typography>
                </Grid>
            }
            {isSpecWork &&
                <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
                    {
                        !appReadOnly && (isAppHead || isOrderHead) && !hasValidPermit &&
                        <Button variant={'contained'} color={'primary'} disableElevation style={{ marginBottom: 10 }}
                            onClick={() => { onhandlePermit(null, showtype) }}
                        >
                            {'上传特殊许可'}
                        </Button>
                    }
                    {
                        !appReadOnly && (isAppHead || isOrderHead) && hasValidPermit && !hasApprovedPermit &&
                        <Button variant={'contained'} color={'primary'} disableElevation style={{ marginBottom: 10 }}
                            onClick={() => { onhandleDonePermit(null, showtype) }}
                        >
                            {'特殊许可办理完成'}
                        </Button>
                    }
                    {
                        (isAppHead || isOrderHead) && hasClosePermit && appAcceptanced &&
                        <Button variant={'contained'} color={'primary'} disableElevation style={{ marginBottom: 10 }}
                            onClick={() => { onhandlePermit(null, showtype) }}
                        >
                            {'上传特殊许可关闭记录'}
                        </Button>
                    }
                </Grid>
            }
            {
                isSpecWork && hasPermit &&
                <MaterialTable
                    className={classes.materialtable}
                    columns={permitColumns}
                    data={showtype === 'close' ? _.filter(currentAppointment.permits, { status: 'done' }) : _.filter(currentAppointment.permits, function (o) { return o.status !== 'done' })}
                    options={permitTableOptions}
                    localization={localization}
                    // detailPanel={permitDetailPanel}
                    style={tableStyle}
                    editable={{
                    }}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: '编辑许可',
                            onClick: (event, rowData) => {
                                onhandlePermit(rowData, showtype)
                            },
                            hidden: Boolean(showtype === 'close') ? Boolean(!isOrderHead || !appAcceptanced) : Boolean(!isAppHead || !hasDraftPermit || rowData.ptwId !== currentPermit.ptwId),
                        }),
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '废止许可',
                            onClick: (event, rowData) => {
                                onhandleDeletePermit(rowData)
                            },
                            hidden: Boolean(showtype === 'close') ? true : Boolean(!isAppHead || !hasDraftPermit || rowData.ptwId !== currentPermit.ptwId),
                        }),
                    ]
                    }
                />
            }
        </div>
    );

};
AppointmentWorkPermit.propTypes = {
    classes: PropTypes.object.isRequired,
    currentAppointment: PropTypes.object.isRequired,
    onhandlePermit: PropTypes.func.isRequired,
    onhandleDownload: PropTypes.func.isRequired,
};

export default AppointmentWorkPermit;