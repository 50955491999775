import React, { useState } from "react";
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  MenuItem,
  TextField,
  Grid,
} from '@material-ui/core';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import _ from 'lodash';
// import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';
// import { getMoment } from '../../../../utils/datetime'
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import zhCN from 'date-fns/locale/zh-CN';
// import DateFnsUtils from '@date-io/date-fns';

const DeviceFromListHeader = props => {
  const {
    classes,
    searchCondition,
    companies,
    formTypeList,
    // targetTypeList,
    // deviceAttrFields,
    formStatusList,
    isLoading,
    onhandleChange,
    onhandleSelectChange,
    onhandleSearch,
    onhandleClearSearch,
  } = props;

  const [expand, setExpand] = useState(false);

  const companyDisabled = () => {
    return false
  }

  return (
    <Grid className={classes.card}>
      <Grid container className={classes.title}>
        <Typography variant="h3">
          {`设备表单`}
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container alignItems="center" className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={expand ? 9 : 6}>
          {<React.Fragment>
            <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
              <Typography className={classes.label}>
                单&emsp;&emsp;位
              </Typography>
              <TextField
                className={classes.textFieldInput}
                size={'small'}
                id="filled-select-company"
                select
                value={searchCondition.companyId}
                onChange={(event) => onhandleSelectChange(event, 'companyId')}
                variant="outlined"
                disabled={companyDisabled()}
              >
                <MenuItem aria-label="None" value={''}></MenuItem>
                {_.sortBy(companies, 'companyId').map((option) => (
                  <MenuItem key={option.companyId} value={option.companyId}>
                    {option.companyAlias}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
              <Typography className={classes.label}>
                {'表单编号'}
              </Typography>
              <TextField
                className={classes.textFieldInput}
                size={'small'}
                value={searchCondition.formNo || ''}
                variant="outlined"
                placeholder="表单编号"
                onChange={event => onhandleChange(event, 'formNo')}
              />
            </Grid>
            <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
              <Typography className={classes.label}>
                {'表单名称'}
              </Typography>
              <TextField
                className={classes.textFieldInput}
                size={'small'}
                value={searchCondition.formName || ''}
                variant="outlined"
                placeholder="表单名称"
                onChange={event => onhandleChange(event, 'formName')}
              />
            </Grid>
            {expand && <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  {'表单类别'}
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  id="filled-select-company"
                  select
                  value={searchCondition.formType}
                  onChange={(event) => onhandleSelectChange(event, 'formType')}
                  variant="outlined"
                >
                  <MenuItem aria-label="None" value={''}></MenuItem>
                  {formTypeList.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  对象类别
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  id="filled-select-company"
                  select
                  value={searchCondition.targetType}
                  onChange={(event) => onhandleSelectChange(event, 'targetType')}
                  variant="outlined"
                >
                  <MenuItem aria-label="None" value={''}></MenuItem>
                  {targetTypeList.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              <Grid container item className={classes.gridItem} sm={6} md={3} lg={expand ? 3 : 4}>
                <Typography className={classes.label}>
                  表单状态
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  id="filled-select-company"
                  select
                  value={searchCondition.status}
                  onChange={(event) => onhandleSelectChange(event, 'status')}
                  variant="outlined"
                >
                  <MenuItem aria-label="None" value={''}></MenuItem>
                  {formStatusList.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </React.Fragment>}
          </React.Fragment>}

        </Grid>
        <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={expand ? 3 : 6}>
          {/* 按钮区 */}
          <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
              disabled={isLoading}
            >{'搜索'}
            </Button>
            <Button variant="outlined" style={{ marginLeft: '10px' }}
              onClick={onhandleClearSearch}
              disabled={isLoading}
            >
              重置
            </Button>
            <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => setExpand(!expand)}
              endIcon={expand ? <ExpandLess /> : <ExpandMore />}
            >
              {expand ? '收起' : '展开'}
            </Button>
          </Grid>
          {/* 表格工具条区 */}
        </Grid>

      </Grid>
    </Grid>
  );
};

DeviceFromListHeader.propTypes = {
  searchCondition: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DeviceFromListHeader.defaultProps = {
  searchCondition: {
  },
  companies: [],
};

export default withStyles(DeviceFromListHeader);
