import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Apple, Android } from '@material-ui/icons';
import QRCode from 'qrcode.react';

function AppQRCode(props) {
  const { classes,
    url,
    iosUrl,
    onhandleExitPage
  } = props;

  return (
    <div className={classes.card}>
      <Typography variant="h3" gutterBottom>
        {"请使用手机扫描下方二维码下载手机端APP"}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={event => onhandleExitPage()}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={0} className={classes.gridContainer} justify="center">
        <Grid item xs={6}>
          <Grid item xs={12} justify="center">
            <Android className={classes.androidIcon} style={{ fontSize: 48 }} />
          </Grid>
          <Grid item xs={12}>
            {url ? <QRCode
              value={url}  //value参数为生成二维码的链接
              size={200} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
              className={classes.qrCode}
            />
              : null}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} justify="center">
            <Apple className={classes.appleIcon} style={{ fontSize: 48 }} />
          </Grid>
          <Grid item xs={12}>
            {iosUrl ? <QRCode
              value={iosUrl}  //value参数为生成二维码的链接
              size={200} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
              className={classes.qrCode}
            />
              : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

AppQRCode.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  iosUrl: PropTypes.string.isRequired,
  onhandleExitPage: PropTypes.func.isRequired,
};

export default withStyles(AppQRCode);
