/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from './styles';

function MessagePage(props) {
  const { classes, messages, cleanMessages, cleanMessage, messgaeDuration } = props;

  // eslint-disable-next-line consistent-return
  const editarea = (message) => {
    if (message.type === 'success') {
      return (
        <React.Fragment>
          <CheckCircleIcon className={classes.iconVariant} />
        </React.Fragment>
      );
    } else if (message.type === 'warning') {
      return (
        <React.Fragment>
          <WarningIcon className={classes.iconVariant} />
        </React.Fragment>
      );
    } else if (message.type === 'error') {
      return (
        <React.Fragment>
          <ErrorIcon className={classes.iconVariant} />
        </React.Fragment>
      );
    } else if (message.type === 'info') {
      return (
        <React.Fragment>
          <InfoIcon className={classes.iconVariant} />
        </React.Fragment>
      );
    }
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={messages.length > 0}
      onClose={event => cleanMessages(event)}
      autoHideDuration={messgaeDuration}
      className={classes.root}
    >
      <div>
        {messages.map(message => (
          <SnackbarContent
            className={classes[message.type]}
            key={message.id}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <div>{editarea(message)}</div>
                {message.content}
              </span>
            }
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={event => cleanMessage(event, message.id)}>
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        ))}
      </div>
    </Snackbar>
  );
}

MessagePage.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.any,
  cleanMessages: PropTypes.func,
  cleanMessage: PropTypes.func,
  messgaeDuration: PropTypes.any
};

export default withStyles(MessagePage);
