/**
 * Licence: MIT
 * Included in our project because the npm version has a bug
 * See: https://github.com/redux-utilities/redux-promise/commit/ae01607ce357e383edaf18defdc914a3e61f6bba
 */

import Promise from 'bluebird';
import { isFSA } from 'flux-standard-action';

function isPromise(val) {
  return val && typeof val.then === 'function';
}

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    if (!isFSA(action)) {
      return isPromise(action) ? action.then(dispatch) : next(action);
    }
    /* eslint-disable indent */
    return isPromise(action.payload)
      ? action.payload.then(
          result => dispatch({ ...action, payload: result }),
          error => {
            dispatch({ ...action, payload: error, error: true });
            return Promise.reject(error);
          }
        )
      : next(action);
  };
  /* eslint-enable */
}
