import React, { } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid, Button,
} from '@material-ui/core';
import withStyles from './styles';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import Search from '@material-ui/icons/Search';
import Save from '@material-ui/icons/Save';
import Check from '@material-ui/icons/Check';
import GetApp from '@material-ui/icons/GetApp';
import Publish from '@material-ui/icons/Publish';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Info from '@material-ui/icons/Info';
import Help from '@material-ui/icons/Help';
import Close from '@material-ui/icons/Close';
import Block from '@material-ui/icons/Block';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Link from '@material-ui/icons/Link';
import Message from '@material-ui/icons/Message';
import FilterList from '@material-ui/icons/FilterList';
import GroupWork from '@material-ui/icons/GroupWork';
import Call from '@material-ui/icons/Call';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import WbSunny from '@material-ui/icons/WbSunny';
import NightsStay from '@material-ui/icons/NightsStay';
import Class from '@material-ui/icons/Class';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
const Icon = props => {
  const { classes,

  } = props;

  const iconList = [
    {
      icon: Add,
      name: '新增'
    }, {
      icon: Delete,
      name: '删除'
    }, {
      icon: Create,
      name: '修改'
    }, {
      icon: Search,
      name: '查找'
    }, {
      icon: Save,
      name: '保存'
    }, {
      icon: Check,
      name: '提交'
    }, {
      icon: GetApp,
      name: '下载'
    }, {
      icon: Publish,
      name: '上传'
    }, {
      icon: Settings,
      name: '设置'
    }, {
      icon: ExitToApp,
      name: '退出'
    }, {
      icon: Info,
      name: '信息'
    }, {
      icon: Help,
      name: '帮助'
    }, {
      icon: Close,
      name: '关闭'
    }, {
      icon: Close,
      name: '取消'
    }, {
      icon: Block,
      name: '驳回'
    }, {
      icon: ArrowBack,
      name: '返回'
    }, {
      icon: ArrowForward,
      name: '往前'
    }, {
      icon: Link,
      name: '链接'
    }, {
      icon: Delete,
      name: '删除'
    }, {
      icon: Message,
      name: '消息'
    }, {
      icon: FilterList,
      name: '过滤'
    }, {
      icon: GroupWork,
      name: '班组活动'
    }, {
      icon: Call,
      name: '带班值班'
    }, {
      icon: WbSunny,
      name: '白班'
    }, {
      icon: NightsStay,
      name: '夜班'
    }, {
      icon: PlaylistAddCheck,
      name: '审批流活动'
    }, {
      icon: Class,
      name: '普通活动'
    }, {
      icon: ArrowBackIos,
      name: '上一个'
    }, {
      icon: ArrowForwardIos,
      name: '下一个'
    }
  ]

  const IconList = function (props) {
    return (
      <div>
        {iconList.map(item => {
          return (
            <Grid container spacing={2} xs={12} key={item.name} >
              <Grid item xs={2}>{item.name}</Grid>
              <Grid item xs={2}>
                <item.icon color="primary" />
                <item.icon />
                <item.icon className={classes.iconDisabled} />
              </Grid>
              <Grid item xs={4}>
                <Button aria-label={item.name} color="primary" variant="contained" className={classes.button}><item.icon /></Button>
                <Button aria-label={item.name} color="primary" variant="contained" className={classes.button}><item.icon />{item.name}</Button>
                <Button aria-label={item.name} color="primary" variant="outlined" className={classes.button}><item.icon /> {item.name}</Button>
              </Grid>
              <Grid item xs={4}>
                <Button aria-label={item.name} color="primary" variant="contained" className={classes.button} disabled><item.icon /></Button>
                <Button aria-label={item.name} color="primary" variant="contained" className={classes.button} disabled><item.icon />{item.name}</Button>
                <Button aria-label={item.name} color="primary" variant="outlined" className={classes.button} disabled><item.icon /> {item.name}</Button>
              </Grid>
            </Grid>)
        })}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid>
        <IconList />
      </Grid>
    </div>
  );
};

Icon.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(Icon));
