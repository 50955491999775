/**
 * 
 * @param {*} bytes 
 * @param {*} suffix 
 * @param {*} callback 加载完毕后回调
 * @returns 
 */
export const bytesToImageSrc = (bytes, suffix, callback) => {
    if (!bytes) return ''
    if (!suffix) suffix = 'png'

    var base64data = `data:image/${suffix};base64,${bytes}`;
    // 根据长宽判断是否旋转后再生成base64
    // let image = new Image();
    var image = document.createElement('img');
    image.src = base64data;
    image.onload = function () {
        if (image.width >= image.height) {
            callback(base64data)
        } else {
            const canvas = document.createElement('canvas');
            let ctx = canvas.getContext("2d");

            canvas.height = image.width;
            canvas.width = image.height;

            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(-90 * Math.PI / 180);
            ctx.drawImage(image, image.width / -2, image.height / -2);

            callback(canvas.toDataURL())
        }
    }
}
