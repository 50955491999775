import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initCreate, initUserHeader, setSearch, getUsers, resetSearch, resetSearchField } from '../../../redux/modules/user';
import UserHeader from "../../../components/SystemManagement/UserManagement/UserHeader";
import _ from 'lodash';

class UserHeaderContainer extends Component {

  static propTypes = {
    groupList: PropTypes.array,
    roleList: PropTypes.array,
    departmentList: PropTypes.array,
    buList: PropTypes.array,
    search: PropTypes.object,
    userProfile: PropTypes.object,
  };
  // constructor(props) {
  //   super(props)
  // }

  componentWillMount() {
    let newSearch = {
      ...this.props.search,
      companyId: this.props.userInfo.companyId
    };
    this.props.onhandleUpdateEditSearch(newSearch);
  }

  componentDidMount() {

    this.props.onhandleInitUserHeader(this.props.userInfo.companyId);

  }

  handleCreate(event) {
    this.props.onhandleCreate()
  }

  handleSearch(event) {
    let pageQuery = {
      page: 0,
      size: 10
    }
    let newSearch = {
      ...this.props.search,
      ...pageQuery
    };
    this.props.onhandleUpdateEditSearch(newSearch);
    this.props.onhandleSearch({ offset: 0 });
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'company':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        this.props.onhandleResetSearchField(value.target.value, 'company');
        break;
      case 'roleGroup':
        newSearch = {
          ...this.props.search,
          groupId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        this.props.onhandleResetSearchField(value.target.value, 'roleGroup');
        break;
      case 'role':
        newSearch = {
          ...this.props.search,
          roleId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'department':
        newSearch = {
          ...this.props.search,
          dptId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'bu':
        newSearch = {
          ...this.props.search,
          unitId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleClearSearch() {
    this.props.onhandleClearSearch(this.props.userInfo.companyId);
  }

  handleCheckPermission(route) {
    this.props.onhandleCheckPermission(route)
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'userName':
        newSearch = {
          ...this.props.search,
          userName: _.trim(event.target.value)
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'email':
        newSearch = {
          ...this.props.search,
          email: _.trim(event.target.value)
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'name':
        newSearch = {
          ...this.props.search,
          name: _.trim(event.target.value)
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  render() {
    return (
      <div>
        <UserHeader
          onhandleCreate={this.handleCreate.bind(this)}
          companyList={this.props.companyList}
          groupList={this.props.groupList}
          roleList={this.props.roleList}
          departmentList={this.props.departmentList}
          userProfile={this.props.userProfile}
          buList={this.props.buList}
          search={this.props.search}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleChange={this.handleChange.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return {
    companyList: state.constant.allCompanies,
    groupList: state.user.groupList,
    roleList: state.user.roleList,
    userInfo: state.auth.currentUserInfo,
    departmentList: state.user.departmentList,
    userProfile: state.auth.userProfile,
    buList: state.user.buList,
    search: state.user.search
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleCreate: () => {
      dispatch(initCreate());
    },
    onhandleInitUserHeader: (companyId) => {
      dispatch(initUserHeader(companyId));
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleSearch: (query) => {
      dispatch(getUsers(query));
    },
    onhandleClearSearch: (companyId) => {
      dispatch(resetSearch(companyId));
    },
    onhandleResetSearchField: (value, field) => {
      dispatch(resetSearchField(value, field));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserHeaderContainer)
