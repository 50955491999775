import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    detailTable: {
        paddingLeft: theme.spacing(4),
        width: '100%'
    }
});


export default withStyles(styles);