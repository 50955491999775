import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { backToList, initDeviceDoc, createDeviceDoc, changeDeviceDocStatus, setDeviceDocPageError } from '../../../redux/modules/devices';
import DeviceDoc from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceDoc';
import { cleanmodal, createmodal } from '../../../redux/modules/modal';
import { getNowTimestamp } from "../../../utils/datetime";
import { getEnvCode } from "../../../utils/exportfile";

import { upload, download, downloadURL } from '../../../redux/modules/minio';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import * as _ from 'lodash';
import { createMessage } from '../../../redux/modules/message';
import { FilePreviewer } from '@/components/SimpleComponents';

class DeviceDocContainer extends Component {
    static propTypes = {
        currentDevice: PropTypes.object,
        userProfile: PropTypes.object,
        isview: PropTypes.bool,
        deviceDoc: PropTypes.array,
        deviceDocPage: PropTypes.object,
        doctype: PropTypes.array,

    }
    constructor(props) {
        super(props);
        this.state = {
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        };
    };

    componentWillMount() {
        //获取设备的技术参数
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceDoc()
        }
    }

    handleDelete = async (rowData) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(rowData, rowData.docLink);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除设备文档[' + rowData.docDesc + ']吗?', changeStatus, null, false);
    }

    handleBack = (event) => {
        this.props.onhandleBackToList();
    };


    async handlePreview(rowData) {
        return this.props.onhandleDownloadUrl(rowData.docName, rowData.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        open: true,
                        fileURL: res.payload,
                        fileType: rowData.docName.split('.')[1]
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }

    trimUnitList() {
        let data = _.filter(this.props.unitList, function (unit) {
            return unit.pcode !== null
        });
        return data
    }

    // eslint-disable-next-line class-methods-use-this
    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        let fileTypes = '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg'.split(',')
        let index = _.findIndex(fileTypes, function (o) { return o === fileType; });
        if (index !== -1) {
            return files;
        } else {
            this.props.onhandleUploadPre()
        }
    }

    handleCheck = () => {

        let err = {};

        if (_.trim(this.props.deviceDocPage.docDesc) === '') {
            err['docDesc'] = '文档描述不能为空';
        } else {
            err['docDesc'] = '';
        }

        if (_.trim(this.props.deviceDocPage.docType) === '') {
            err['docType'] = '文档类型不能为空';
        } else {
            err['docType'] = '';
        }

        let newError = {
            ...this.props.deviceDocPageError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    async onConfirm(files, isUploadSetter, values) {
        await this.handleCheck();
        // console.log(Object.values(this.props.deviceDocPageError))
        if (_.findIndex(Object.values(this.props.deviceDocPageError), function (o) { return o !== ''; }) < 0) {
            let tenantId = this.props.userProfile.roles[0].tenantId
            isUploadSetter(true);
            let envCode = getEnvCode()
            let mybucket = _.replace(envCode + '.{tenant_id}.device.devicedoc', '{tenant_id}', tenantId);
            let offset = getNowTimestamp();

            this.props.onhandleUploadDoc(files, mybucket, offset, () => {
                let doc = {
                    docId: 0,
                    deviceId: this.props.currentDevice.deviceId,
                    docName: offset + files[0].name,
                    docDesc: this.props.deviceDocPage.docDesc,
                    docLink: mybucket,
                    docType: this.props.deviceDocPage.docType,
                };
                this.props.onhandleCreateDoc(doc);
                this.props.onhandleModalClose();
            });
        }
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
            children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    render() {
        const { previewFile } = this.state;
        return (
            <div>
                <DeviceDoc
                    onhandleDelete={this.handleDelete.bind(this)}
                    tabValue={this.props.tabValue}
                    index={this.props.index}
                    onhandleBack={this.handleBack.bind(this)}
                    userProfile={this.props.userProfile}
                    deviceDoc={this.props.deviceDoc}
                    isView={this.props.isview}
                    onOpenUploadModal={this.openUploadModal.bind(this)}
                    doctype={this.props.doctype}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    isDeviceEdit={this.props.isDeviceEdit}
                />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDeviceEdit: state.devices.deviceEdit,
        userProfile: state.auth.userProfile,
        currentDevice: state.devices.currentDevice,
        deviceDoc: state.devices.deviceDoc,
        isview: state.devices.view,
        deviceDocPage: state.devices.deviceDocPage,
        doctype: state.devices.doctype,
        deviceDocPageError: state.devices.deviceDocPageError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleInitDeviceDoc: () => {
            dispatch(initDeviceDoc())
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateDoc: (doc) => {
            dispatch(createDeviceDoc(doc));
        },
        onhandleChangeStatus: (entity, mybucket) => {
            dispatch(changeDeviceDocStatus(entity, mybucket));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleUploadPre: () => {
            dispatch(createMessage('error', '上传的文件格式不支持'));
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceDocPageError(err))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceDocContainer);