import React, { Component } from 'react';
import "./index.css";
import "../../../resource/rc-select.css";
import Konva from 'konva';
import { ReactComponent as ExportIcon } from '../../../resource/export.svg';
import { ReactComponent as FullScreenIcon } from '../../../resource/fullscreen.svg';
import { ReactComponent as FullScreenExitIcon } from '../../../resource/fullscreen-exit.svg';
import { ReactComponent as ZoomInIcon } from '../../../resource/zoom in.svg';
import { ReactComponent as ZoomOutIcon } from '../../../resource/zoom out.svg';
import { ReactComponent as FitScreenIcon } from '../../../resource/fitscreen.svg';
import { ReactComponent as ClearIcon } from '../../../resource/layers_clear.svg'
import { ReactComponent as ShowIcon } from '../../../resource/show.svg';
import { ReactComponent as HideIcon } from '../../../resource/hide.svg';
import { ReactComponent as StartIcon } from '../../../resource/start.svg';
import { ReactComponent as StopIcon } from '../../../resource/stop.svg';
// import Select, { Option } from 'rc-select';
import Switch from 'rc-switch';
import * as _ from 'lodash';
import KonvaUtil from "../devices/util";
import MapViewActionMapping from "../devices/mapViewActionMapping";
const konvaUtil = new KonvaUtil();
const mapViewActionMapping = new MapViewActionMapping();

export default class ViewTopBar extends Component {
    constructor(props) {
        super(props)
        this._exportImg = this._exportImg.bind(this);
        this._fullscreen = this._fullscreen.bind(this);
        this._requestFullScreen = this._requestFullScreen.bind(this);//进入全屏
        this._exitFullscreen = this._exitFullscreen.bind(this);//退出全屏
        this._watchFullScreen = this._watchFullScreen.bind(this);//监听fullScreen事件
        this._zoomAction = this._zoomAction.bind(this);
        this._changeUnitView = this._changeUnitView.bind(this);
        this._clearAction = this._clearAction.bind(this);
        this._deviceGroupShow = this._deviceGroupShow.bind(this);
        this.state = {
            isFullScreen: false,
            isShow: false,
            messageShow: false,
            datas: [
                {
                    deviceId: 860,
                    cat: 'D0501',
                    deviceName: '蒸发式冷凝器E-308',
                    paramName: '电流',
                    time: '2021/01/23 11:09',
                    status: '待处理',
                    type: 'warning',
                },
                {
                    deviceId: 872,
                    cat: 'D1001',
                    deviceName: '装车泵P-301A',
                    paramName: '开/停状态',
                    time: '2021/01/23 10:20',
                    status: '处理中',
                    type: 'error',
                },
                {
                    deviceId: 879,
                    cat: 'D0201',
                    deviceName: '二氧化碳精馏塔C-301',
                    paramName: '压力',
                    time: '2021/01/23 13:15',
                    status: '处理中',
                    type: 'error',
                },
                {
                    deviceId: 873,
                    cat: 'D1001',
                    deviceName: '装车泵P-301B',
                    paramName: '泄露',
                    time: '2021/01/23 14:21',
                    status: '待处理',
                    type: 'warning',
                }
            ]
        }
    }

    componentWillMount = () => {
        this._watchFullScreen();
        let selectedStorey = _.minBy(this.props.installation.storeys, 'altitudeDatum').storeyId;
        this.props.addStoreySelect(selectedStorey);
    }

    _watchFullScreen = () => {
        const _self = this;
        document.addEventListener(
            "webkitfullscreenchange",
            function () {
                console.log("webkitfullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                } else {
                    fullScreenSetting();
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                });
            },
            false
        );

        document.addEventListener(
            "fullscreenchange",
            function () {
                console.log("fullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                } else {
                    fullScreenSetting();
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                });
            },
            false
        );

        document.addEventListener(
            "mozfullscreenchange",
            function () {
                console.log("mozfullscreenchange");
                const { fullScreenSetting, exitFullScreenSetting } = _self.props;
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    exitFullScreenSetting();
                } else {
                    fullScreenSetting();
                }
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen,
                });
            },
            false
        );
    };

    _zoomAction(action) {
        konvaUtil.zoomAction(action, this.props)
    }

    _clearAction() {
        let stage = this.props.getStage();
        let layer = stage.find('Layer')[0];
        layer.find('Group').forEach(group => {
            group.fire('mouseout')
        })
    }

    _fullscreen() {
        let isFullScreen = this.state.isFullScreen;
        if (!this.state.isFullScreen) {
            this._requestFullScreen();
        } else {
            this._exitFullscreen();
        }
        this.setState({
            isFullScreen: !isFullScreen,
        })
    }

    _requestFullScreen() {
        var dom = document.getElementById('fullTag');//绑定想要全屏的组件
        if (dom.requestFullscreen) {
            dom.requestFullscreen();
        } else if (dom.mozRequestFullScreen) {
            dom.mozRequestFullScreen();
        } else if (dom.webkitRequestFullScreen) {
            dom.webkitRequestFullScreen();
        }
    };

    _exitFullscreen() {
        var dom = document;
        if (dom.exitFullscreen) {
            dom.exitFullscreen();
        } else if (dom.mozCancelFullScreen) {
            dom.mozCancelFullScreen();
        } else if (dom.webkitCancelFullScreen) {
            dom.webkitCancelFullScreen();
        }
    };

    _renderFullScreen() {
        if (this.state.isFullScreen) {
            return (
                <React.Fragment>
                    <FullScreenExitIcon className="top-control-btn"
                        onClick={this._fullscreen.bind(this)} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <FullScreenIcon className="top-control-btn"
                        onClick={this._fullscreen.bind(this)} />
                </React.Fragment>
            );
        }
    }

    _changeUnitView() {
        this.props.updateUnitView();
        let stage = this.props.getStage();
        let layer = stage.find('Layer');
        konvaUtil.updateView(this.props, layer[0], this.props.getPreviewStage(), 'view');
    }

    _exportImg() {
        let stage = this.props.getStage();
        let layer = stage.find('Layer');
        let dataURL = layer[0].toDataURL({ pixelRatio: 3 });
        let link = document.createElement('a');
        let selectValue = this.props.selectedId
        let storey = _.find(this.props.installation.storeys, function (o) { return o.storeyId === selectValue; });
        link.download = `${this.props.installation.installName}_${storey.storeyName}.png`;
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    }

    onSelect = (value, option) => {
        this.props.addStoreySelect(value)
        this.props.cleanDialog();
        let stage = this.props.getStage();
        stage.destroyChildren();
        stage.draw();
        let storey = _.find(this.props.installation.storeys, function (o) { return o.storeyId === value; });
        var layer = Konva.Layer.create(JSON.parse(storey.data2d));
        stage.add(layer);
        stage.draw();
        konvaUtil.updateView(this.props, layer, this.props.getPreviewStage(), 'view');
        mapViewActionMapping.layerMapping(this.props, layer, stage, this.props.getPreviewStage());
        konvaUtil.updatePreview(this.props, layer, this.props.getPreviewStage());
        this.props.initDeviceTreeData();
    };

    _getAltitudeDatum = () => {
        let id = this.props.selectedId
        let storey = _.find(this.props.installation.storeys, function (o) { return o.storeyId === id; });
        if (storey) {
            return storey.altitudeDatum;
        } else {
            return '';
        }
    }

    _deviceGroupShow = () => {
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        let deviceGroups = layer.find('.DeviceGroup');
        deviceGroups.forEach(deviceGroup => {
            let rectTemp = deviceGroup.findOne('.backGround');
            let circle = deviceGroup.findOne('.main');
            if (this.state.isShow) {
                if (!rectTemp.fill()) {
                    rectTemp.fill('#81c784');
                    rectTemp.opacity(1);
                    deviceGroup.moveToTop();
                    rectTemp.moveToTop();
                    circle.fill('#616161');
                    circle.moveToTop();
                    layer.draw();
                }
            } else {
                if (rectTemp.fill()) {
                    rectTemp.fill(null);
                    deviceGroup.moveToBottom();
                    rectTemp.moveToBottom();
                    circle.fill('green');
                    circle.moveToTop();
                    circle.moveToTop();
                    layer.draw();
                    let units = layer.find('.Unit');
                    if (units) {
                        units.forEach(unit => {
                            unit.moveToBottom();
                        })
                    }
                    let areas = layer.find('.Area');
                    if (areas) {
                        areas.forEach(area => {
                            area.moveToBottom();
                        })
                    }
                    layer.draw();
                }
            }
        })
        konvaUtil.updateView(this.props, layer, this.props.getPreviewStage());
        this.setState({
            isShow: !this.state.isShow,
        })

    }

    _stopAction = () => {
        this.setState({
            messageShow: false
        })
        const { cleanDialog } = this.props;
        cleanDialog();
        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        this.state.datas.forEach(data => {
            let cat = data.cat;
            let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
            let targetGroup = layer.findOne('#' + type + '_' + data.deviceId);
            if (targetGroup) {
                targetGroup.fire('mouseout');
            }
        })
    }

    _startAction = () => {
        this.setState({
            messageShow: true
        })

        const { createDialog } = this.props;
        createDialog('messageDetail', null, true);

        let stage = this.props.getStage();
        let layer = stage.findOne('.workLayer');
        this.state.datas.forEach(data => {
            let cat = data.cat;
            let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
            let targetGroup = layer.findOne('#' + type + '_' + data.deviceId);
            if (targetGroup) {
                targetGroup.fire('mouseover');
            }
        })
    }

    render() {
        const { canvasViewWidth, isFullScreen } = this.props;
        return (
            <div style={{ width: canvasViewWidth, left: isFullScreen ? 200 : 224 }} className="topbar">
                <div className="controls">
                    <div className="control">
                        <ExportIcon className="top-control-btn"
                            onClick={this._exportImg.bind(this)} />
                    </div>
                    <div className="divider"> </div>
                    {/* <div className="slideText">层:</div>
                <Select style={{ width: 200 }}
                    value={this.props.selectedId}
                    dropdownRender={menu => (
                        <React.Fragment>
                            {menu}
                        </React.Fragment>
                    )}
                    onSelect={this.onSelect}
                >
                    {_.sortBy(this.props.installation.storeys, 'storeyId').map(
                        storeyItem => (
                            <Option value={storeyItem.storeyId} key={storeyItem.storeyId}>
                                {storeyItem.storeyName}
                            </Option>
                        )
                    )}
                </Select>
                <div className="slideText">高度基准:{this._getAltitudeDatum()}</div>
                <div className="divider"> </div> */}
                    <div className="slideText">视图:</div>
                    <Switch
                        onChange={this._changeUnitView.bind(this)}
                        className="customeSwitch"
                        defaultChecked={this.props.unitView}
                        checkedChildren="单元"
                        unCheckedChildren="区域"
                    />
                    <div className="divider"> </div>
                    <div className="control">
                        {this.state.isShow
                            ?
                            <HideIcon className="top-control-btn"
                                onClick={this._deviceGroupShow.bind(this)} />
                            :
                            <ShowIcon className="top-control-btn"
                                onClick={this._deviceGroupShow.bind(this)} />
                        }
                    </div>
                </div>

                <div className="adjectControl">
                    {this.state.messageShow ?
                        <StopIcon className="top-control-btn" onClick={() => { this._stopAction() }} />
                        :
                        <StartIcon className="top-control-btn" onClick={() => { this._startAction() }} />
                    }
                    <div className="divider"> </div>
                    <ClearIcon className="top-control-btn" onClick={() => { this._clearAction("Out") }} />
                    <div className="divider"> </div>
                    <ZoomOutIcon className="top-control-btn" onClick={() => { this._zoomAction("Out") }} />
                    <ZoomInIcon className="top-control-btn" onClick={() => { this._zoomAction("In") }} />
                    <FitScreenIcon className="top-control-btn" onClick={() => { this._zoomAction("Reset") }} />

                    <div className="divider"> </div>
                    {this._renderFullScreen()}
                </div>
            </div>
        );
    }
}