import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'flex-start'
    },
    copyrights: {
        lineHeight: theme.spacing(3) + 'px',
        fontSize: 14
    },
    secureRecords: {
        paddingTop: 15,
        lineHeight: '12px',
        fontSize: 12,
        color: theme.palette.text.secondary
    },
    beianIcon: {
        marginRight: 5
    },
    secureRecordsText: {
        display: 'flex',
        alignItems: 'flex-end'
    }
});

export default withStyles(styles);