import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlanListComponent from '../../../../components/OnsiteInspectionManagement/PlanList/PlanListComponent';
import PlanPanel from '../../../../components/OnsiteInspectionManagement/PlanPanel';
import {
    getPlanList,
    initPlanListPage,
    setSearch,
    resetSearch,
    initView,
    initRecord,
    setCurrentPlan,
    setPlanPanelOpen,
    setPlanError,
    insertPlan,
    modifyPlan,
    deletePlan,
    exportRecordListCollect
} from '../../../../redux/modules/onsiteInspectionPlan';
import { getCompanyId } from "../../../../utils/userUtils";
import { getTreePartById } from '../../../../utils/constant';
import * as _ from 'lodash';
import { getMoment } from '../../../../utils/datetime'
import { openConfirmDialog } from '../../../../redux/modules/confirmDialog';

class OnsiteInspectionPlanListContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    componentWillMount() {
        const { location, search } = this.props;
        const isBack = location ? location.state : false;
        this.props.onhandleInitPage(null, isBack);
        // this.props.onhandleInitPage(this.props.query, isBack);

        if (isBack) {
            this.setState({ size: search.size });
        }
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value,
                    routeId: undefined,
                    dptId: undefined,
                    userId: null
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'inspectionType':
                newSearch = {
                    ...this.props.search,
                    inspectionType: value.target.value,
                    routeId: '',
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;

            case 'routeId':
                newSearch = {
                    ...this.props.search,
                    routeId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'status':
                newSearch = {
                    ...this.props.search,
                    status: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'userId':
                newSearch = {
                    ...this.props.search,
                    userId: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSearch(event) {
        this.props.onhandleSearch({ offset: 0, size: 15, sort: "-start_time" });
    }

    handleSearchCurrent(event) {
        let newSearch;
        newSearch = {
            ...this.props.search,
            inspectionType: 'OIT02',
            fromDate: getMoment()().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(2, 'h').toDate(),
            toDate: getMoment()().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(2, 'h').toDate(),
            isCurrent: true,
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        this.props.onhandleSearch({ offset: 0, size: 15, sort: "-start_time" });
    }

    handleDateChange = (value, field) => {
        let newSearch;
        switch (field) {
            case 'fromDate':
                newSearch = {
                    ...this.props.search,
                    fromDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'toDate':
                newSearch = {
                    ...this.props.search,
                    toDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    handleChange(value, field) {
        let newPlan;
        switch (field) {
            case 'dptId':
                let newDptId = [{ dptId: value.target.value }];
                newPlan = {
                    ...this.props.currentPlan,
                    onsitePlanDpt: newDptId
                };
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'routeId':
                newPlan = {
                    ...this.props.currentPlan,
                    routeId: value.target.value
                };
                let obj = _.find(this.props.routeList, function (o) { return o.routeId === value.target.value })
                if (obj) {
                    newPlan.inspectionType = obj.inspectionType
                }
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'startTime':
                newPlan = {
                    ...this.props.currentPlan,
                    startTime: value.target.value,
                };
                this.props.onhandleSelectedPlan(newPlan)
                break;
            case 'expiredAt':
                newPlan = {
                    ...this.props.currentPlan,
                    expiredAt: value.target.value
                };
                this.props.onhandleSelectedPlan(newPlan)
                break
            default:
            // do nothing
        }
    }

    handleClosePlanPanel() {
        this.props.onhandlePlanPanelOpen(false)
    }

    handlePanelSave = async (data) => {
        await this.handleCheck(data, null);
        if (_.findIndex(Object.values(this.props.planError), function (o) { return o !== ''; }) < 0) {
            if (this.props.currentPlan.planId) {
                this.props.onhandleEdit(data)
            } else {
                this.props.onhandleCreate(data)
            }
        }
    }

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'dptId') {
            if (_.trim(this.props.currentPlan.onsitePlanDpt[0].dptId) === '') {
                err['dptId'] = '执行班组不能为空';
            } else {
                err['dptId'] = '';
            }
        }

        if (!field || field === 'routeId') {
            if (_.trim(this.props.currentPlan.routeId) === '') {
                err['routeId'] = '巡检线路不能为空';
            } else {
                err['routeId'] = '';
            }
        }

        if (!field || field === 'startTime') {
            if (_.trim(this.props.currentPlan.startTime) === '') {
                err['startTime'] = '开始时间不能为空';
            } else {
                let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
                let tomorrowTime = new Date(tomorrow).getTime();
                let startTime = new Date(this.props.currentPlan.startTime).getTime()
                if (tomorrowTime > startTime) {
                    err['startTime'] = '开始时间不能早于明天';
                } else {
                    err['startTime'] = '';
                }
            }
        }

        if (!field || field === 'expiredAt') {
            if (_.trim(this.props.currentPlan.expiredAt) === '') {
                err['expiredAt'] = '巡检限时不能为空';
            } else {
                if (_.toInteger(this.props.currentPlan.expiredAt) <= 0) {
                    err['expiredAt'] = '请填写正整数';
                } else {
                    err['expiredAt'] = '';
                }
            }
        }
        let newError = {
            ...this.props.planError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleCreate() {
        this.props.onhandlePlanPanelOpen(true)
        this.props.onhandleSelectedPlan({ onsitePlanDpt: [{ dptId: '' }] })
        let planError = {
            dptId: '',
            routeId: '',
            startTime: '',
            expiredAt: '',
        }
        this.props.onhandleSetError(planError)
    }

    handleUpdate = (event, data) => {
        this.props.onhandlePlanPanelOpen(true)
        this.props.onhandleSelectedPlan(data)
        let planError = {
            dptId: '',
            routeId: '',
            startTime: '',
            expiredAt: ''
        }
        this.props.onhandleSetError(planError)
    }
    handleDelete = (event, data) => {
        let changeStatus = () => {
            this.props.onhandleDelete(data)
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除路线为[' + data.routeName + ']的临时巡检计划吗?', changeStatus, null, false);
    }

    handleExport = () => {
        this.props.onhandleExport();
    }

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    handleView(event, plan) {
        this.props.onhandleView(plan);
    }

    handleRecord(event, plan) {
        this.props.onhandleRecord(plan);
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    trimLeadersList() {
        let companyTree = this.trimCompanyTree()
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
            let result = _.filter(this.props.leaders, function (o) { return companyId === o.companyId; })
            return result
        } else {
            return this.props.leaders
        }
    }

    trimDepartmentList() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let result = _.filter(this.props.departmentList, (o) => {
            const mngFlag = o.executable === false && !o.pid && o.amoebaType === 'COST';
            return companyId === o.companyId && (o.executable === true || mngFlag)
        })
        return result
    }

    trimRouteList() {
        let result = _.filter(this.props.routeList, function (o) { return 'OIT05' !== o.inspectionType; })
        return result
    }

    render() {
        return (
            <div>
                <PlanListComponent
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    planList={this.props.planList}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    search={this.props.search}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleSearchCurrent={this.handleSearchCurrent.bind(this)}
                    getPlanList={this.props.onhandleSearch}
                    onhandleView={this.handleView.bind(this)}
                    onhandleRecord={this.handleRecord.bind(this)}
                    onSizeChange={this.handleListSize.bind(this)}
                    routeList={this.props.routeList}
                    departmentList={this.props.departmentList}
                    users={this.props.users}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                />
                {this.props.planPanelOpen && <PlanPanel
                    userProfile={this.props.userProfile}
                    open={this.props.planPanelOpen}
                    leaders={this.trimLeadersList()}
                    departmentList={this.trimDepartmentList()}
                    routeList={this.trimRouteList()}
                    currentPlan={this.props.currentPlan}
                    onhandleClose={this.handleClosePlanPanel.bind(this)}
                    onhandlePanelSave={this.handlePanelSave.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    planError={this.props.planError}
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        planList: state.onsiteInspectionPlan.planList,
        search: state.onsiteInspectionPlan.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        routeList: state.onsiteInspectionPlan.routeList,
        departmentList: state.constant.departments,
        users: state.onsiteInspectionPlan.users,
        planPanelOpen: state.onsiteInspectionPlan.planPanelOpen,
        currentPlan: state.onsiteInspectionPlan.currentPlan,
        leaders: state.onsiteInspectionPlan.leaders,
        planError: state.onsiteInspectionPlan.planError,
        query: state.router.location.query,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitPage: (query, reload) => {
            dispatch(initPlanListPage(query, reload));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSearch: () => {
            dispatch(resetSearch());
        },
        onhandleSearch: (query) => {
            dispatch(getPlanList(query));
        },
        onhandleView: (plan) => {
            dispatch(initView(plan));
        },
        onhandleRecord: (plan) => {
            dispatch(initRecord(plan));
        },
        onhandleSelectedPlan: (data) => {
            dispatch(setCurrentPlan(data))
        },
        onhandlePlanPanelOpen: (data) => {
            dispatch(setPlanPanelOpen(data));
        },
        onhandleSetError: (err) => {
            dispatch(setPlanError(err))
        },
        onhandleCreate: (data) => {
            dispatch(insertPlan(data));
        },
        onhandleEdit: (data) => {
            dispatch(modifyPlan(data));
        },
        onhandleDelete: (data) => {
            dispatch(deletePlan(data));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleExport: () => {
            dispatch(exportRecordListCollect());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionPlanListContainer);



