import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getServiceCustomers, addServiceCustomer,
    updateServiceCustomer, deleteServiceCustomer, getManufactureServices
} from '@/redux/modules/manufacture/manufactureService';
import { getCustomers, getCustomerGroups } from '@/redux/modules/manufacture/customer';
import { getMaterialGroups, getMaterialListByGroup } from '@/redux/modules/material';
import { judgeMainCompany } from '@/utils/manufactureUtils'
import { getRole } from '@/utils/userUtils';
import { USED_MATERIAL_GROUPS, formSelectTree } from '@/utils/constant';
import * as _ from 'lodash';
import ServiceCustomerComponent from '@/components/ManufactureManagement/ServiceCustomer';

class ServiceCustomerContainer extends Component {
    static propTypes = {
        companyList: PropTypes.any,
        companyId: PropTypes.number,
    }

    constructor(props) {
        super(props)
        this.state = {
            companyId: this.props.currentUserInfo.companyId,
            subCompanyList: [],
            isMainCompany: judgeMainCompany(this.props.companyList, this.props.currentUserInfo.companyId),
            materialGroupTree: [],
        }
    }


    getpid = (companyId) => {
        const company = _.find(this.props.companyList, { 'companyId': companyId });
        return company.pid === null ? null : company.companyId
    }

    componentDidMount() {
        const { companyList, companyId,
            onhandleGetServiceCustomers, onhandleGetCustomerGroups, onhandleGetCustomers,
            onhandleGetServices, onhandleGetMaterialListByGroup, onhandleGetMaterialGroups } = this.props;
        let subCompanyList = companyList.filter((ele) => ele.companyId === companyId);
        let selectCompanyId = companyId;
        if (this.state.isMainCompany) {
            subCompanyList = companyList.filter((ele) => ele.pid === companyId);
            if (subCompanyList && subCompanyList[0]) {
                selectCompanyId = subCompanyList[0].companyId;
            }
        }
        this.setState({
            companyId: selectCompanyId,
            subCompanyList: subCompanyList
        });

        onhandleGetServiceCustomers(selectCompanyId);
        onhandleGetCustomerGroups();
        onhandleGetCustomers();
        onhandleGetServices(selectCompanyId);

        onhandleGetMaterialGroups().then(mg => {
            let materialGroupList = _.filter(mg.payload, mg => {
                return USED_MATERIAL_GROUPS.indexOf(mg.extId) > -1
            })
            let treeList = [];
            materialGroupList.forEach(element => {
                treeList.push({
                    name: element['groupName'],
                    code: element['extId'],
                    pcode: 0
                });
            });
            onhandleGetMaterialListByGroup(USED_MATERIAL_GROUPS, (data) => {

                const selectCompany = _.find(companyList, c => { return c.companyId === selectCompanyId })
                if (selectCompany.companyName === '实华福利厂') {
                    data = _.filter(data, d => { return d.orgId === 101740 }) //大连实华福利厂
                } else {
                    data = _.filter(data, d => { return d.orgId === 1 }) //大连石油化工工程公司
                }
                data.forEach(element => {
                    treeList.push({
                        name: element.materialName + (element.specification ? '/' + element.specification : ''),
                        code: '_' + element.materialId,// 避免出现group和具体Id值重复问题
                        pcode: element.materialGroup
                    });
                });
                const materialGroupTree = formSelectTree(treeList, false)
                this.setState({ materialGroupTree: materialGroupTree });
            });
        });
    }

    handleCompanyChange = (event) => {
        const { getServiceCustomers } = this.props
        const companyId = event.target.value;
        getServiceCustomers(companyId)
        this.setState({
            companyId: companyId
        })
    }

    handleCustomerGroupChange = (customerGroupId) => {
        if (customerGroupId) {
            this.props.onhandleGetCustomers(customerGroupId)
        }
    }

    handleAdd = async (value) => {

        const { onhandleAddServicCustomer, onhandleGetServiceCustomers } = this.props;
        const { selectCompanyId } = this.state
        if (!value.serviceId) return
        let newData = {}
        newData.serviceId = parseInt(value.serviceId)
        if (value.cid) newData.cid = parseInt(value.cid)
        if (value.customerGroup) newData.customerGroup = parseInt(value.customerGroup)
        if (value.material) newData.material = parseInt(value.material)
        if (value.materialGroup) newData.materialGroup = parseInt(value.materialGroup)
        if (value.product) newData.product = parseInt(value.product)
        if (value.productGroup) newData.productGroup = parseInt(value.productGroup)
        if (value.unitPrice) newData.unitPrice = parseFloat(value.unitPrice)
        await onhandleAddServicCustomer(newData)
        onhandleGetServiceCustomers(selectCompanyId);

    }

    handleUpdate = async (value) => {

        const { onhandleUpdateServicCustomer, onhandleGetServiceCustomers } = this.props;
        const { selectCompanyId } = this.state

        let newData = {}
        if (!value.scid || !value.serviceId) {
            return;
        } else {
            newData.scid = parseInt(value.scid)
            newData.serviceId = parseInt(value.serviceId)
        }
        if (value.cid) newData.cid = parseInt(value.cid)
        if (value.customerGroup) newData.customerGroup = parseInt(value.customerGroup)
        if (value.material) newData.material = parseInt(value.material)
        if (value.materialGroup) newData.materialGroup = parseInt(value.materialGroup)
        if (value.product) newData.product = parseInt(value.product)
        if (value.productGroup) newData.productGroup = parseInt(value.productGroup)
        if (value.unitPrice) newData.unitPrice = parseFloat(value.unitPrice)
        await onhandleUpdateServicCustomer(newData)
        onhandleGetServiceCustomers(selectCompanyId);

    }

    handleDelete = async (value) => {

        const { onhandleDeleteServicCustomer, onhandleGetServiceCustomers } = this.props;
        const { selectCompanyId } = this.state
        if (!value.scid) return
        await onhandleDeleteServicCustomer({ scid: value.scid })
        onhandleGetServiceCustomers(selectCompanyId)
    }

    render() {
        //const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_service_customer').action === 'W';
        const roleFlag = true;
        return (
            <div>
                <ServiceCustomerComponent
                    subCompanyList={this.state.subCompanyList}
                    companyId={this.state.companyId}
                    isMainCompany={this.state.isMainCompany}
                    currentUserInfo={this.props.currentUserInfo}
                    roleFlag={roleFlag}
                    serviceCustomers={this.props.serviceCustomers}
                    customerGroups={this.props.customerGroups}
                    customers={this.props.customers}
                    services={this.props.services}
                    materialGroupTree={this.state.materialGroupTree}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleCustomerGroupChange={this.handleCustomerGroupChange.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyList: state.company.companyList.list,
        currentUserInfo: state.auth.currentUserInfo,
        companyId: state.auth.currentUserInfo.companyId,
        userProfile: state.auth.userProfile,
        serviceCustomers: state.manufactureService.serviceCustomers,
        manufactureDicts: state.constant.manufactureDicts,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        customerGroups: state.manufactureCustomer.customerGroups,
        customers: state.manufactureCustomer.customers,
        services: state.manufactureService.services,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetServiceCustomers: (companyId) => {
            return dispatch(getServiceCustomers({ companyId: companyId }, null));
        },
        onhandleGetCustomerGroups: () => {
            return dispatch(getCustomerGroups({}));
        },
        onhandleGetCustomers: (groupId) => {
            return dispatch(getCustomers({ groupId: groupId }));
        },
        onhandleAddServicCustomer: (dto) => {
            return dispatch(addServiceCustomer(dto));
        },
        onhandleUpdateServicCustomer: (dto) => {
            return dispatch(updateServiceCustomer(dto));
        },
        onhandleDeleteServicCustomer: (dto) => {
            return dispatch(deleteServiceCustomer(dto));
        },
        onhandleGetServices: (companyId, callback) => {
            dispatch(getManufactureServices(null, null, companyId, null, null, callback));
        },
        onhandleGetMaterialListByGroup: (materialGroups, callback) => {
            dispatch(getMaterialListByGroup(materialGroups, callback));
        },
        onhandleGetMaterialGroups: () => {
            return dispatch(getMaterialGroups());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceCustomerContainer);
