import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { getInstallationList } from '@/redux/modules/installation';

const porxyApi = '/api';
const initialState = {
    rules: [],
};

export const setDepreciationRules = createAction('@@xcloud/amoeba/setDepreciationRules');

export const initDepreciationRule = createAction('@@xcloud/amoeba/initDepreciationRule',
    (companyId, status) =>
        (dispatch, getState, httpClient) => {
            Promise.all([
                dispatch(getInstallationList({ offset: 0, size: 0 })),
                dispatch(getDepreciationRules(companyId, status))
            ]).then((data) => {
                return data;
            }).catch(err => {
                let msg = '获取资产折旧规则列表失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        });

export const getDepreciationRules = createAction('@@xcloud/amoeba/getDepreciationRules',
    (companyId, status) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/depreciationrules?status=' + status
            if (companyId !== 0) {
                url += '&companyId=' + companyId
            }
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setDepreciationRules(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取资产折旧规则列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const createDepreciationRule = createAction('@@xcloud/amoeba/createDepreciationRule',
    (rule, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(porxyApi + '/amoeba/depreciationrules', rule).then(
                (res) => {
                    if (callback) {
                        callback(res.data)
                    }
                    dispatch(createhttpMessage(res, "创建资产折旧规则成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `创建资产折旧规则失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const updateDepreciationRule = createAction('@@xcloud/amoeba/updateDepreciationRule',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.patch(porxyApi + '/amoeba/depreciationrules/' + record.ruleId, record).then(
                (res) => {
                    if (callback) {
                        callback(res.data)
                    }
                    dispatch(createhttpMessage(res, "更新资产折旧规则成功"))
                    return res.status;
                }).catch(err => {
                    let msg = `更新资产折旧规则失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const depreciationReducer = handleActions(
    {
        [setDepreciationRules]: (state, { payload: data }) => {
            return {
                ...state,
                rules: data
            }
        },
    },
    initialState
)

export default depreciationReducer
