import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(0)
  }
});

export default withStyles(styles);
