import React, { useState, useEffect } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import { getDateByTimestamp } from '../../../utils/datetime';
import { localization, options } from "@/utils/mtable";
import _ from 'lodash';
import {
    Grid,
    Button,
    Typography,
    TablePagination,
    Card,
    Avatar,
    TextField,
    MenuItem,
    IconButton,
    Checkbox,
    Tabs,
    Tab,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
    Chip,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
    SaveAlt,
    ExpandMore,
    ExpandLess,
    Toc,
    PlaylistAddCheck,
} from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import { AttachFile } from '@material-ui/icons';
import { getMoment } from '@/utils/datetime';

const ExceptionListComponent = props => {
    const {
        classes,
        search,
        userProfile,
        currentUserInfo,
        companyTree,
        companyList,
        exceptionList,
        onhandleSelect,
        onhandleClearSearch,
        onhandleSearch,
        getExceptionList,
        onSizeChange,
        // XJGL-127 异常分析页面变更
        inspectionDictionaries,
        routeList,
        onhandleDateChange,
        installationList,
        onhandleDownload,
        onhandleExceptionBatch,
        onhandleExport,
        onhandleLoad,
        isLoad,
        tabValue,
        onhandleChangeTab,
        onSetTreatmentPlan,
        // 任务 巡检的异常处理 立即整改功能 XJGL-193
        onAcceptTreatment,
        // 异常批量处置
        onhandleChangeCheckedItem
    } = props;

    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const [expand, setExpand] = useState(false);
    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(null);

    const checkedRows = _.filter(exceptionList.list, { 'checked': true });


    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 260  // window.innerHeight * 100; //px
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route' })
    //     return permission && permission.action === 'W'
    // };

    const getStatusData = () => {
        let result = []

        result.push({ label: '未处置', value: 'new' })
        result.push({ label: '待确认', value: 'pending' })
        result.push({ label: '已处置', value: 'active' })
        result.push({ label: '已下达', value: 'assigned' })

        return result
    }

    const hasHandlePermission = (row) => {
        if (!row) {
            if (checkedRows && checkedRows.length) {
                row = checkedRows[0]
            } else {
                return false
            }
        }
        const {
            inspectorId,
            dpts,
            status
        } = row;

        if (status && !['new'].includes(status)) {
            return false;
        }
        // 填写人
        if (inspectorId && currentUserInfo.userId === inspectorId) {
            return true;
        }
        // 巡检计划执行班组长
        const userDpt = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0] : null;
        const userRoleLevel = userProfile.roles[0].roleLevel;
        if (dpts && dpts.length && userDpt) {
            for (const dptId of dpts.split(',')) {
                if (userDpt.dptId === parseInt(dptId) && userDpt.executable === true && userRoleLevel === 2) {
                    return true;
                }
            }
        }
        return false;
    }

    const hasConfirmPermission = (row) => {
        if (!row) {
            if (checkedRows && checkedRows.length) {
                row = checkedRows[0]
            } else {
                return false
            }
        }
        const {
            status
        } = row;

        if (status && !['pending'].includes(status)) {
            return false;
        }
        // 非班长的管理人员
        const userDpt = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0] : null;
        const userRoleLevel = userProfile.roles[0].roleLevel;
        if (userDpt) {
            if (userDpt.executable === false && userRoleLevel === 2) {
                return true;
            }
        }
        return false;
    }

    const hasSelectedRows = () => {
        return checkedRows.length > 0
    }

    // const hasRightRows = (status) => {
    //     const rightRows = _.filter(exceptionList.list, { 'status': status });

    //     return rightRows > 0 && rightRows.length === checkedRows.length
    // }

    // const renderCompany = (row) => {
    //     let item = _.filter(companyList, function (o) { return row.companyId === o.value })
    //     if (item && item.length) {
    //         return item[0].label
    //     } else {
    //         return ''
    //     }
    // };

    const renderStatus = (row) => {
        let text = ''
        switch (row.status) {
            case 'active':
                text = '已处置'
                break;
            case 'new':
                text = '未处置'
                break;
            case 'pending':
                text = '待确认'
                break;
            case 'assigned':
                text = '已下达'
                break;
            default:
                break;
        }
        return text
    }

    const renderRowStatusDisplay = (row) => {
        let cssClassName = classes.pic1
        switch (row.status) {
            case 'assigned':
                cssClassName = classes.pic2
                break;
            case 'active':
                cssClassName = classes.pic2
                break;
            case 'new':
                cssClassName = classes.pic1
                break;
            case 'pending':
                cssClassName = classes.pic3
                break;
            default:
                cssClassName = classes.pic1
                break;
        }
        return cssClassName
    }

    const renderStatusDisplay = (row) => {
        let status = renderStatus(row)
        let cssClassName = classes.default

        switch (status) {
            case '已处置':
                cssClassName = classes.active
                break;
            case '未处置':
                cssClassName = classes.new
                break;
            case '待确认':
                cssClassName = classes.pending
                break;
            case '已下达':
                cssClassName = classes.assigned
                break;
            default:
                cssClassName = classes.default
        }

        return cssClassName
    };

    const renderInspectionType = (row) => {
        let item = _.filter(inspectionDictionaries, function (o) { return row.inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    }

    const renderValues = (row) => {
        const { template, offTemplate, standardValue, standardOffValue, histInsUseStatus } = row;
        let output = '';
        // 默认需有一个运行标准
        output = renderstandardValues(template, standardValue)
        if (histInsUseStatus === 'OFFUSE') {
            if (!offTemplate || !standardOffValue) return output
            output = renderstandardValues(offTemplate, standardOffValue) + '(停机)'
        }
        return output
    };

    const renderstandardValues = (template, standardValue) => {
        if (!template || !standardValue) return '-';

        let output = '';
        if (template.indexOf('|') > -1 && standardValue.indexOf(',') > -1) {
            let valueList = standardValue.split(',');
            let value1 = valueList[0];
            let value2 = valueList[1];

            output = value1 + ' ~ ' + value2
        } else {
            let logic = template.split('{')[0]
            let value = standardValue
            output = logic + value
        }

        return output
    }

    const renderCheckbox = (row) => {
        if (['assigned', 'active'].includes(row.status)) {
            return ''
        } else {
            return <Checkbox
                size={'small'}
                checked={row.checked || false}
                hidden={!(hasHandlePermission(row) || hasConfirmPermission(row))}
                name='checked'
                onChange={e => onhandleChangeCheckedItem(e.target.checked, row.listId)}
            />
        }
    }

    const assemblycolumns = () => {
        return [
            {
                title: '',
                field: 'checkedRow',
                render: renderCheckbox,
                editable: 'never',
                align: 'center',
                cellStyle: {
                    width: 26,
                    maxWidth: 26,
                    // color: '#FFF'
                    padding: '6px 0px',
                },
                headerStyle: {
                    width: 26,
                    maxWidth: 26,
                    padding: '6px 0px',
                },
            },
            {
                title: '',
                field: 'listId',
                render: row => row ? (<Avatar className={renderRowStatusDisplay(row)} >{renderStatus(row)[0]}</Avatar>) : '',
                editable: 'never',
                align: 'center',
                cellStyle: {
                    width: 26,
                    maxWidth: 26,
                    // color: '#FFF'
                    padding: '6px 2px',
                },
                headerStyle: {
                    width: 26,
                    maxWidth: 26,
                    padding: '6px 2px',
                },
            },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '所属单位',
            //     field: 'companyName',
            //     render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '参数名称',
                field: 'itemName',
                render: row => row ? <Typography>{row.itemName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '异常内容',
                field: 'val',
                render: row => row ? <Typography>{row.val}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运行范围',
                field: 'standardValue',
                render: row => row ? <Typography>{renderValues(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '所属装置',
                field: 'installTitle',
                render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检类型',
                field: 'inspectionType',
                render: row => row ? <Typography>{renderInspectionType(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检路线',
                field: 'routeName',
                render: row => row ? <Typography>{row.routeName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发现人',
                field: 'inspectorName',
                render: row => row ? <Typography>{row.inspectorName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发生时间',
                field: 'time',
                render: row => row ? <Typography>{getDateByTimestamp(row.createTime, true)}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '更新时间',
            //     field: 'time',
            //     render: row => row ? <Typography>{renderUpdateTime(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '状态',
                field: 'status',
                // render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                render:
                    row =>
                        row
                            ?
                            <Tooltip
                                title={
                                    row.handleResult ?
                                        row.handleResult :
                                        (row.status === 'assigned' ? '临时计划已下达' : '未处置')
                                } >
                                <Chip size="small" className={renderStatusDisplay(row)} label={renderStatus(row)} />
                            </Tooltip>
                            :
                            '',
                editable: 'never',
                align: 'center',
                width: 130,
            },
        ];
    };

    // const renderUpdateTime = (row) => {
    //     return row.updateTime ? getDateByTimestamp(row.updateTime, true) : ''
    // }

    // 【巡检类型】下拉列表选项取得
    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE") {
                result.push({ label: t.name, value: t.code })
            }
        })

        return result;
    };

    // 【巡检路线】下拉列表选项取得
    const getRouteData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        let item = _.filter(routeList, function (o) { return _.toInteger(company) === o.companyId })
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.routeName, value: t.routeId })
            })
        }
        return result
    }

    // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
    const noOptionDisabled = (optionList) => {
        if (optionList && optionList.length > 0) {
            return false
        }
        return true
    }

    // 【所属装置】下拉列表选项渲染
    // const installOptions = () => (
    //     <React.Fragment>
    //         {installationList ?
    //             installationList.map(data => (
    //                 <option value={data.installId} key={data.installId}>{data.installName}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )
    const getintervalDate = () => {
        if (search.view === 'day') {
            return getMoment()(search.fromDate).format("YYYY/MM/DD")
        } else if (search.view === 'week') {
            let start = getMoment()(search.fromDate).format("MM/DD")
            let end = getMoment()(search.toDate).format("MM/DD")
            return start + '~' + end
        } else if (search.view === 'month') {
            return getMoment()(search.fromDate).format("YYYY/MM")
        } else if (search.view === 'year') {
            return getMoment()(search.fromDate).format("YYYY")
        }
    };

    const goToFrontDate = () => {
        if (search.view === 'day') {
            let day = getMoment()(search.fromDate).add(-1, 'day')
            onhandleSelect(day, 'day')
        } else if (search.view === 'week') {
            let day = getMoment()(search.fromDate).add(-7, 'days')
            onhandleSelect(day, 'week')
        } else if (search.view === 'month') {
            let day = getMoment()(search.fromDate).add(-1, 'month')
            onhandleSelect(day, 'month')
        } else if (search.view === 'year') {
            let day = getMoment()(search.fromDate).add(-1, 'year')
            onhandleSelect(day, 'year')
        }
    }

    const goToNextDate = () => {
        if (search.view === 'day') {
            let day = getMoment()(search.fromDate).add(1, 'day')
            onhandleSelect(day, 'day')
        } else if (search.view === 'week') {
            let day = getMoment()(search.fromDate).add(7, 'days')
            onhandleSelect(day, 'week')
        } else if (search.view === 'month') {
            let day = getMoment()(search.fromDate).add(1, 'month')
            onhandleSelect(day, 'month')
        } else if (search.view === 'year') {
            let day = getMoment()(search.fromDate).add(1, 'year')
            onhandleSelect(day, 'year')
        }
    }

    // disabled={isView || !checkPermission()}

    const nextDisabled = () => {

        let end = _.cloneDeep(search.toDate)
        let now = getMoment()().format("YYYY/MM/DD")
        if (end >= now) {
            return true
        } else {
            return false
        }
    }

    return (
        <Card className={classes.content}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'巡检异常记录'}
                </Typography>
            </Grid>
            <Tabs className={classes.tabs}
                // classes={{ fixed: classes.tabs }}
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"异常一览"} className={classes.tab} />
                <Tab label={"异常报表"} className={classes.tab} />
            </Tabs>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            所属单位
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-company"
                            select
                            value={search.companyId || 0}
                            onChange={(event) => onhandleSelect(event, 'companyId')}
                            variant="outlined"
                            disabled={companyDisabled()}
                        >
                            <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                            {_.sortBy(companyList, 'companyId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            巡检类型
                        </Typography>
                        <TextField
                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-inspectiontype"
                            select
                            value={search.inspectionType || 0}
                            onChange={(event) => onhandleSelect(event, 'inspectionType')}
                            variant="outlined"
                        >
                            <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                            {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                        <Typography className={classes.label}>
                            巡检路线
                        </Typography>
                        <TextField

                            className={classes.textFieldInput}
                            size={'small'}
                            id="filled-select-routeid"
                            select
                            value={search.routeId || 0}
                            onChange={(event) => onhandleSelect(event, 'routeId')}
                            variant="outlined"
                            disabled={noOptionDisabled(getRouteData(search.companyId))}
                        >
                            <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                            {_.sortBy(getRouteData(search.companyId), 'routeId').map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {Boolean(expand) && <React.Fragment>
                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                状态
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-status"
                                select
                                value={search.status || 0}
                                onChange={(event) => onhandleSelect(event, 'status')}
                                variant="outlined"
                            >
                                <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                                {_.sortBy(getStatusData(), 'status').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>


                        {tabValue === 0 &&
                            <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                <Typography className={classes.label}>
                                    开始时间
                                </Typography>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        autoOk
                                        className={classes.textFieldInput}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        name='startTimeselect'
                                        value={search.fromDate || null}
                                        onChange={value => onhandleDateChange(value, 'fromDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '开始日期',
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            style: { paddingRight: 0 },
                                            startAdornment:
                                                (
                                                    <IconButton
                                                        style={{ order: 1, display: '' }}
                                                        onClick={() => onhandleDateChange(null, 'fromDate')}
                                                        disabled={Boolean(!search.fromDate)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                        }}
                                        InputAdornmentProps={{
                                            position: "end",
                                            style: { order: 2, marginLeft: 0 }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>}

                        {tabValue === 0 &&
                            <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                <Typography className={classes.label}>
                                    结束时间
                                </Typography>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN} >
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        className={classes.textFieldInput}
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        name='endTimeselect'
                                        value={search.toDate || null}
                                        onChange={value => onhandleDateChange(value, 'toDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '结束日期',
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            style: { paddingRight: 0 },
                                            startAdornment:
                                                (
                                                    <IconButton
                                                        style={{ order: 1, display: '' }}
                                                        onClick={() => onhandleDateChange(null, 'toDate')}
                                                        disabled={Boolean(!search.toDate)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                        }}
                                        InputAdornmentProps={{
                                            position: "end",
                                            style: { order: 2, marginLeft: 0 }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>}

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                所属装置
                            </Typography>
                            <TextField

                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-routeid"
                                select
                                value={search.installId || 0}
                                onChange={(event) => onhandleSelect(event, 'installId')}
                                variant="outlined"
                            >
                                <MenuItem key={1} value={0}>{'不限'}</MenuItem>
                                {installationList ?
                                    installationList.map(data => (
                                        <MenuItem key={data.installId} value={data.installId}>
                                            {data.installName}
                                        </MenuItem>
                                    )) : null
                                }
                            </TextField>
                        </Grid>
                    </React.Fragment>}

                </Grid>

                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>
                        {tabValue === 0 && <>
                            <Button variant="contained" color="primary"
                                onClick={onhandleSearch}
                            >
                                搜索
                            </Button>
                            <Button variant="outlined" style={{ marginLeft: '10px' }}
                                onClick={onhandleClearSearch}
                            >
                                重置
                            </Button>
                        </>}
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>

                    {/* {
                        icon: 'rule',
                        tooltip: '批量处置',
                        hidden: !(hasSelectedRows() && hasHandlePermission()),
                        disabled: !hasHandlePermission(),
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleExceptionBatch('handle')
                        }
                    },
                    {
                        icon: 'playlist_add_check',
                        tooltip: '批量确认',
                        hidden: !(hasSelectedRows() && hasConfirmPermission()),
                        disabled: !hasConfirmPermission(),
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleExceptionBatch('confirm')
                        }
                    },
                    {
                        icon: 'save_alt',
                        tooltip: '导出',
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleExport()
                        }
                    } */}
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {tabValue === 0 && hasSelectedRows() && hasHandlePermission() && <Button variant='contained' color='primary' startIcon={<Toc />} disabled={!hasHandlePermission()}
                            onClick={(event) => { onhandleExceptionBatch('handle') }}
                        >
                            批量处置
                        </Button>}
                        {tabValue === 0 && hasSelectedRows() && hasConfirmPermission() && <Button variant='contained' color='primary' startIcon={<PlaylistAddCheck />} disabled={!hasConfirmPermission()} style={{ marginLeft: '10px' }}
                            onClick={(event) => { onhandleExceptionBatch('confirm') }}
                        >
                            批量确认
                        </Button>}
                        {<Button variant='contained' color='primary' startIcon={<SaveAlt />} style={{ marginLeft: '10px' }}
                            onClick={(event) => { onhandleExport() }}
                        >
                            导出
                        </Button>}
                    </Grid>
                </Grid>

                {/* {tabValue === 0 && <div>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={onhandleSearch}
                            >
                                搜索
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={onhandleClearSearch}
                            >
                                重置
                            </Button>
                        </Grid>
                    </Grid>
                </div>} */}

            </Grid>

            <div alignitems="center" className={classes.planitemroot}>
                {tabValue === 0 && <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={exceptionList.list}
                        options={{
                            ...options,
                            pageSize: _.toInteger(search.size),
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: false,
                            maxBodyHeight: `${tableHeight}px`,
                            minBodyHeight: `${tableHeight}px`,
                        }}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[15, 30, 50]}
                                    rowsPerPage={_.toInteger(search.size)}
                                    count={exceptionList.total}
                                    page={page}
                                    onChangePage={(event, pageNum) => {
                                        setPage(pageNum);
                                        getExceptionList(pageNum * search.size, search.size, "-status,-create_time", "", "")

                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        // setRowsPerPage(event.target.value);
                                        setPage(0);
                                        onSizeChange(event.target.value)
                                        getExceptionList(0, event.target.value, "-status,-create_time", "", "")
                                    }}
                                />
                            )
                        }}
                        // actions={checkPermission() ? [rowData => ({
                        //     icon: 'description',
                        //     tooltip: '处置计划',
                        //     onClick: (event, rowData) => {
                        //         //onhandleView(event, rowData)
                        //     }
                        // })
                        // ] : []}
                        actions={[
                            rowData => ({
                                icon: 'build',
                                tooltip: '处置',
                                onClick: (event, rowData) => {
                                    onAcceptTreatment(rowData)
                                },
                                disabled: !hasHandlePermission(rowData),
                                hidden: !(rowData.status === 'new')
                            }),
                            rowData => ({
                                icon: 'assignment_turned_in',
                                tooltip: '确认',
                                onClick: (event, rowData) => {
                                    onAcceptTreatment(rowData)
                                },
                                disabled: !hasConfirmPermission(rowData),
                                hidden: !(rowData.status === 'pending')
                            }),
                            rowData => ({
                                icon: 'how_to_vote',
                                tooltip: '下计划',
                                onClick: (event, rowData) => {
                                    onSetTreatmentPlan(rowData)
                                },
                                hidden: !(rowData.status === 'new')
                            }),
                            rowData => ({
                                icon: AttachFile,
                                tooltip: '查看附件',
                                disabled: !rowData.docs.length && rowData.docs.length === 0,
                                onClick: (event, rowData) => {
                                    onhandleDownload(event, rowData.docs, false)
                                }
                            }),
                            // {
                            //     icon: 'rule',
                            //     tooltip: '批量处置',
                            //     hidden: !(hasSelectedRows() && hasHandlePermission()),
                            //     disabled: !hasHandlePermission(),
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onhandleExceptionBatch('handle')
                            //     }
                            // },
                            // {
                            //     icon: 'playlist_add_check',
                            //     tooltip: '批量确认',
                            //     hidden: !(hasSelectedRows() && hasConfirmPermission()),
                            //     disabled: !hasConfirmPermission(),
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onhandleExceptionBatch('confirm')
                            //     }
                            // },
                            // {
                            //     icon: 'save_alt',
                            //     tooltip: '导出',
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         onhandleExport()
                            //     }
                            // }
                        ]}
                    >
                    </MaterialTable>
                </Grid>}
                {tabValue === 1 && <Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={4}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="视图" name="view"
                                    defaultValue="year"
                                    value={search.view}
                                    onChange={(event) => onhandleSelect(event, 'view')}
                                    style={{ width: '100%' }}
                                >
                                    <FormControlLabel value="year" control={<Radio size={'small'} />} label="年视图" />
                                    <FormControlLabel value="month" control={<Radio size={'small'} />} label="月视图" />
                                    <FormControlLabel value="week" control={<Radio size={'small'} />} label="周视图" />
                                    {/* <FormControlLabel value="day" control={<Radio size={'small'} />} label="日视图" /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item className={classes.toolbarButton}>
                            <Button onClick={event => goToFrontDate(event)} size="small" >
                                <ArrowBackIos size="small" />
                            </Button>
                        </Grid>
                        <Grid item className={classes.toolbarLabel}>
                            <Typography className={classes.label}>{getintervalDate()}</Typography>
                        </Grid>
                        <Grid item className={classes.toolbarButton} >
                            <Button disabled={nextDisabled()} onClick={event => goToNextDate(event)}><ArrowForwardIos /></Button>
                        </Grid>
                        <Grid item className={classes.flexgrow}></Grid>
                        <Grid item className={classes.toolbarButton}>
                            {/* <Tooltip title="导出报告">
                                <IconButton aria-label="delete" onClick={event => onhandleExport()} size="small" disabled={isLoad}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {isLoad ? onhandleLoad() : ''}
                        <div id="main" style={{ width: '100%', height: 500 }}></div>
                    </Grid>

                </Grid>}
            </div>
        </Card>
    );
};

ExceptionListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    // userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    exceptionList: PropTypes.object.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleClearSearch: PropTypes.func.isRequired,
    onhandleSearch: PropTypes.func.isRequired,
    getExceptionList: PropTypes.func.isRequired,
    onSetTreatmentPlan: PropTypes.func.isRequired,
    onAcceptTreatment: PropTypes.func.isRequired,
};

export default withRouter(withStyles(ExceptionListComponent));