import React, { Component } from 'react';
import { connect } from 'react-redux';
import MTempPlanListComponent from '@/components/ManufactureManagement/ManufacturePlan/MTempPlanComponent';
import PropTypes from 'prop-types';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment, getYearMonth, formatMonth, formatDate } from '@/utils/datetime';
import {
    getPlans
    , deletePlan
    , openEditPlan
} from '@/redux/modules/manufacture/manufacturePlan';
import { addOrder } from '@/redux/modules/manufacture/manufactureOrder';
import { getManufactureServices } from '@/redux/modules/manufacture/manufactureService';
import { createActionMessage } from '@/redux/modules/message'
import { SERVICE_TYPE_ALL } from '@/utils/manufactureUtils'
import * as _ from 'lodash';
import MTempPlanDetailContainer from './MTempPlanDetailContainer';
import { filterAmoebaCompany } from '@/utils/constant';
import ServiceTempPlanConfigContainer from './ServiceTempPlanConfigContainer';
import { setOpenTempPlanConfig } from '@/redux/modules/manufacture/servicePlanConfig';

const filterService = (services, myCompanyId) => {

    return _.reject(services, s => {
        return s.valuationType === 'BUDGET' || (s.valuationType === 'FIXED' && s.serviceCompany === myCompanyId)

    })

}
class MTempPlanContainer extends Component {
    static propTypes = {
        plans: PropTypes.any,
        companies: PropTypes.any,
        companyId: PropTypes.number,
        plan: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            openPlan: {},
            year: getYearMonth(),
            tabValue: 'TEMP',
            openFlag: false,
            plan: {},
            openAddFlag: false,
            planDtl: {},
            startTime: null,
            endTime: null,
            dtpFlg: false,
            openDetailFlag: false,
        }
    }

    componentDidMount() {
        this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, this.state.year, this.getMainCompany(this.props.companyId))
    }

    handleDateChange = (date) => {
        const newYear = formatMonth(date)
        this.setState({
            selectedDate: date,
            year: newYear
        })
        this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, newYear, this.getMainCompany(this.props.companyId))
    }


    getpid = (companyId) => {
        const company = this.props.companies.find(item => {
            return item.companyId === companyId
        });
        return company.pid === null ? null : company.companyId
    }

    getMainCompany = (companyId) => {
        const company = this.props.companies.find(item => {
            return item.companyId === companyId
        });
        return company.pid
    }



    handleDeletePlan = (plan) => {
        return this.props.onhandleDeletePlan(plan.planId).then(
            (res) => this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, this.state.year, this.getMainCompany(this.props.companyId))
        );
    }

    getCompanyId = () => {
        const company = this.props.companies.find(item => {
            return item.companyId === this.props.companyId
        });
        return company.pid === null ? null : company.companyId
    }

    handleAddOrder = (rowData) => {
        this.props.createloading();
        if (rowData.manufacturePlanDtls && rowData.manufacturePlanDtls[0].dtlId) {
            const company = this.props.companies.find(item => {
                return item.companyId === this.props.companyId
            })
            const dpts = this.props.departments.filter(item => {
                return item.companyId === this.props.companyId && item.amoebaType === 'PROFIT'
            }).map(item => { return item.dptId })
            let newData = {};
            newData['startTime'] = formatDate(getMoment()())
            newData['companyId'] = this.getCompanyId();
            newData['requireCompany'] = company.pid ? company.pid : company.companyId
            newData['department'] = dpts.join(',')
            newData.planId = rowData.planId
            newData['flg'] = 1;
            return this.props.onhandleGenerateOrder(newData).then(
                () => this.props.cleanloading()
            )
        } else {
            this.props.createActionMessage('warning', '该计划没有明细计划')
            return Promise.resolve(true).then(
                () => this.props.cleanloading()
            )
        }
    }

    handleOpenDetail = (plan, actionType) => {

        const { currentUserInfo } = this.props
        let newPlan = {}
        if (actionType === 'NEW') {
            //新建计划
            newPlan = {
                companyId: currentUserInfo.companyId,
                planName: '',
                planType: 'TEMP',
                startTime: getMoment()(this.state.selectedDate).startOf('month'),
                endTime: getMoment()(this.state.selectedDate).endOf('month'),
                status: 'active',
                manufacturePlanDtls: []
            }
        } else {
            newPlan = { ...plan }
        }
        this.props.onhandleOpenTempPlan(newPlan)
    }

    handlePlanChange = (event) => {

        let newPlan = {
            ...this.state.plan,
        }
        if (event.target.name === 'companyId') {
            newPlan[event.target.name] = Number(event.target.value)
        } else {
            newPlan[event.target.name] = event.target.value
        }
        this.setState({ plan: newPlan })
    }

    handlePlanDtlChange = (planDtl) => {
        let newDtls = []
        for (let dtl of this.state.plan.manufacturePlanDtls) {
            if (dtl.dtlId === planDtl.dtlId || (dtl.tempId === planDtl.tempId)) {
                dtl = { ...planDtl }
            }
            newDtls.push(dtl)
        }
        this.setState({
            plan: {
                ...this.state.plan,
                manufacturePlanDtls: newDtls
            }
        })
    }

    handleOpenTempPlanConfig = () => {
        this.props.onhandleOpenTempPlanConfig()
    }

    render() {
        return (
            <div>
                <MTempPlanListComponent
                    selectedDate={this.state.selectedDate}
                    plans={this.props.plans}
                    companies={this.props.companies}
                    companyId={this.props.companyId}
                    departments={this.props.departments}
                    manufactureDicts={this.props.manufactureDicts}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleDeletePlan={this.handleDeletePlan.bind(this)}
                    ohhandleOpenDetail={this.handleOpenDetail.bind(this)}
                    ohhandleOpenTempPlanConfig={this.handleOpenTempPlanConfig.bind(this)}
                    onhandleAddOrder={this.handleAddOrder.bind(this)}
                />
                {this.props.tempPlanOpen && <MTempPlanDetailContainer
                    listQueryParams={{
                        companyId: this.props.companyId,
                        planType: this.state.tabValue,
                        year: Number(this.state.year.substring(0, 4)),
                        month: Number(this.state.year.substring(5, 7)),
                    }}
                />}
                {this.props.openTempPlanConfig && <ServiceTempPlanConfigContainer />}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tempPlanOpen: state.manufacturePlan.tempPlanOpen,
        plans: state.manufacturePlan.plans,
        companies: filterAmoebaCompany(state.company.companyList.list),
        companyId: state.auth.currentUserInfo.companyId,
        planDtls: state.manufacturePlan.planDtls,
        services: filterService(state.manufactureService.services, state.auth.currentUserInfo.companyId),
        departments: state.department.departments.list,
        currentUserInfo: state.auth.currentUserInfo,
        manufactureDicts: state.constant.manufactureDicts,
        openTempPlanConfig: state.servicePlanConfig.openTempPlanConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSelect: (companyId, planType, year, pid) => {
            const yearr = year.substring(0, 4);
            const month = year.substring(5, 7);
            dispatch(getPlans(companyId, planType, yearr, month));
            dispatch(getManufactureServices(companyId, SERVICE_TYPE_ALL, null, null, pid, null));
        },
        onhandleGenerateOrder: (newData) => {
            return dispatch(addOrder(newData));
        },
        onhandleDeletePlan: (planId) => {
            return dispatch(deletePlan(planId));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
        onhandleOpenTempPlan: (plan) => {
            return dispatch(openEditPlan(plan));
        },
        onhandleOpenTempPlanConfig: () => {
            return dispatch(setOpenTempPlanConfig(true));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MTempPlanContainer);
