import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message';

const defaultState = {
  isloading: false,
  tenants: {
    total: 0,
    list: []
  },
  currentTenant: {},
  tenantError: {},
  firstTenantAdmin: {},
  tenantAdminError: {},
  tenantChange: false,
  adminChange: false
};

export const backToTenantList = createAction('@@xcloud/tenant/backToTenantList',
  () => (dispatch, getState) => {
    dispatch(push('/tenantManagement'));
  });

export const getTenants = createAction('@@xcloud/tenant/getTenants',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    dispatch(setTenantChange(false));
    dispatch(setAdminChange(false));
    let url = '/api/tenants';
    if (query) {
      if (query.offset || query.size || query.sort) {
        url = url + '?';
        let strs = [];
        if (query.offset)
          strs.push('offset=' + query.offset)
        if (query.size !== undefined)
          strs.push('size=' + query.size)
        if (query.sort)
          strs.push('sort=' + query.sort)
        url = url + strs.join('&');
      }
    }
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setTenants(res.data));
            dispatch(stopLoading());
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '租户列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getAdminsByTenantId = createAction('@@xcloud/tenant/getAdminsByTenantId',
  (tid) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    let url = '/api/tenants/' + tid + '/admin';
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          if (res.data.length > 0) {
            dispatch(setTenantAdmin(res.data[0]));
          } else {
            dispatch(setTenantAdmin({}));
          }
          // dispatch(stopLoading());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(stopLoading());
        let msg = '租户信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getTenantById = createAction('@@xcloud/tenant/getTenantById',
  (tid) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    let url = '/api/tenants/' + tid;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentTenant(res.data));
          // dispatch(stopLoading());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(stopLoading());
        let msg = '租户信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createTenant = createAction('@@xcloud/tenant/createTenant',
  (newTenant) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    let url = '/api/tenants';
    return httpClient.post(url, newTenant)
      .then((res) => {
        if (res.status === 201) {
          // console.log(res.data);
          dispatch(setCurrentTenant(res.data));
          // dispatch(stopLoading());
          dispatch(initCreateAdmin());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(stopLoading());
        let msg = '创建新租户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const createTenantAdmin = createAction('@@xcloud/tenant/createTenantAdmin',
  (admin) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    
    let tid = getState().tenant.currentTenant.tenantId;
    let url = '/api/tenants/' + tid + "/admin";
    return httpClient.post(url, admin)
      .then((res) => {
        if (res.status === 201) {
          // dispatch(stopLoading());
          dispatch(push('/tenantManagement'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(stopLoading());
        let msg = '创建新租户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateTenant = createAction('@@xcloud/tenant/updateTenant',
  (updatedTenant) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    let tenant = getState().tenant.currentTenant;
    let url = '/api/tenants/' + tenant.tenantId;
    let to = {
      tenantId: updatedTenant.tenantId,
      tenantName: updatedTenant.tenantName,
      tenantAlias: updatedTenant.tenantAlias,
      email: updatedTenant.email,
      phone: updatedTenant.phone,
      opentext: updatedTenant.opentext
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
          // dispatch(stopLoading());
          dispatch(createMessage('success', '更新租户信息完成'));
          dispatch(initUpdate(tenant.tenantId));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '更新租户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const setTenants = createAction('@@xcloud/tenant/setTenants');

export const setCurrentTenant = createAction('@@xcloud/tenant/setCurrentTenant');

export const setTenantAdmin = createAction('@@xcloud/tenant/setTenantAdmin');

export const setTenantError = createAction('@@xcloud/tenant/setTenantError');

export const setTenantAdminError = createAction('@@xcloud/tenant/setTenantAdminError');

export const setTenantChange = createAction('@@xcloud/tenant/setTenantChange');

export const setAdminChange = createAction('@@xcloud/tenant/setAdminChange');

export const stopLoading = createAction('@@xcloud/tenant/stopLoading');

export const startLoading = createAction('@@xcloud/tenant/startLoading');

export const initCreate = createAction(
  '@@xcloud/tenant/initCreate',
  () => (dispatch) => {
    dispatch(initTenant(true));
    dispatch(push('/tenantManagement/info'));
  }
);

export const initCreateAdmin = createAction(
  '@@xcloud/tenant/initCreateAdmin',
  () => (dispatch) => {
    dispatch(initTenantAdmin());
    dispatch(push('/tenantManagement/admin'));
  }
);

export const initUpdate = createAction(
  '@@xcloud/tenant/initUpdate',
  (tid) => (dispatch) => {
    return Promise.all([
      dispatch(getTenantById(tid)),
      dispatch(getAdminsByTenantId(tid)),
      dispatch(initTenant(false)),
    ]).then(
      () => {
        dispatch(push('/tenantManagement/info'))
      }
    )
  }
);

export const initTenant = createAction(
  '@@xcloud/tenant/initTenant',
  (isCreate) => (dispatch, getState) => {
    let tenant = isCreate ? {
      "tenantId": 0,
      "tenantName": '',
      "phone": '',
      "email": '',
      "creator": 0,
      "revisor": 0,
      "status": "active",
      "opentext": ''
    } : getState().tenant.currentTenant;
    let terror = {
      tenantName: '',
      tenantAlias: '',
      email: '',
      phone: ''
    };
    dispatch(setCurrentTenant(tenant));
    dispatch(setTenantError(terror));
    dispatch(setTenantChange(false));
    dispatch(setAdminChange(false));
  }
);

export const initTenantAdmin = createAction(
  '@@xcloud/tenant/initTenantAdmin',
  () => (dispatch, getState) => {
    let tenant = getState().tenant.currentTenant;
    let admin = {
      "tenantId": tenant.tenantId,
      "userId": 0,
      "userName": '',
      "phone": '',
      "email": '',
      "firstName": '',
      "lastName": '',
      "password": '',
      "status": "active"
    };
    let adminError = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: ''
    };
    dispatch(setTenantAdmin(admin));
    dispatch(setTenantAdminError(adminError));
  }
);

export const tenantReducer = handleActions(
  {
    [setTenants]: (state, { payload: value }) => {
      return {
        ...state,
        tenants: value
      };
    },
    [setTenantError]: (state, { payload: value }) => {
      return {
        ...state,
        tenantError: value
      };
    },
    [setTenantChange]: (state, { payload: value }) => {
      return {
        ...state,
        tenantChange: value
      };
    },
    [setAdminChange]: (state, { payload: value }) => {
      return {
        ...state,
        adminChange: value
      };
    },
    [setTenantAdminError]: (state, { payload: value }) => {
      return {
        ...state,
        tenantAdminError: value
      };
    },
    [setCurrentTenant]: (state, { payload: value }) => {
      return {
        ...state,
        currentTenant: value
      };
    },
    [setTenantAdmin]: (state, { payload: value }) => {
      return {
        ...state,
        firstTenantAdmin: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    }
  },
  defaultState
);

export default tenantReducer;