import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    grid: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    datePicker: {
        flexGrow: 1,
    },
    gridContainer: {
        padding: theme.spacing(4)
    },
    detailTable: {
        overflowX: 'auto'
    },
    flexGrow: {
        flexGrow: 1,
    },
    textfield: {
        width: 50
    }
});

export default withStyles(styles);