import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExceptionListComponent from '../../../components/OnsiteInspectionManagement/ExceptionList/ExceptionListComponent';
import {
    getExceptionList,
    setExceptionList,
    initExceptionListPage,
    setSearch,
    resetSearch,
    // ▽　XJGL-127 异常分析页面变更　▽
    exportExceptionList,
    // △　XJGL-127 异常分析页面变更　△
    setLoad,
    getExceptionReport,
    // 巡检异常现象处置 XJGL-172  
    routeToDevicePlan,
    // 任务 巡检的异常处理 立即整改功能 XJGL-193
    handleException,
    // 批量异常处理
    handleExceptionBatch,
} from '../../../redux/modules/onsiteInspectionException';
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';
import _ from 'lodash';
import { createmodal } from "../../../redux/modules/modal";
import { downloadURL } from '../../../redux/modules/minio';
import { getMoment } from '@/utils/datetime';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/heatmap';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
// 巡检异常现象处置 XJGL-172  
import { createMessage } from '@/redux/modules/message';
// 任务 巡检的异常处理 立即整改功能 XJGL-193
import ExceptionAcceptPannel from './ExceptionAcceptPannel';
import { openExceptionAcceptPannel } from '@/redux/modules/onsiteInspectionExceptionAccept';
// 异常批量处理
import ExceptionBatchAcceptPanel from './ExceptionBatchAcceptPanel';
import { openExceptionBatchAcceptPanel } from '@/redux/modules/onsiteInspectionExceptionBatchAccept';


let myChart = null;

class OnsiteInspectionExceptionListContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    UNSAFE_componentWillMount() {
        this.props.onhandleInitPage();
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps() {

    }

    componentDidUpdate() {
        if (this.state.tabValue === 1) {
            myChart = echarts.init(document.getElementById('main'));
        }
    }


    handleLoad = () => {
        if (myChart) {
            if (this.props.search.companyId) {
                if (this.props.search.view === 'day') {
                    myChart.setOption(this.getBarOption(), true);
                } else {
                    myChart.setOption(this.getBarOptionDateBase(), true);
                }
            } else {
                if (this.props.search.view === 'day') {
                    myChart.setOption(this.getBarOption(), true);
                } else {
                    myChart.setOption(this.getBarOptionDateBase(), true);
                }
            }
            this.props.onhandleStopLoad()
        }
    }

    getBarOptionDateBase = () => {
        let result = [['date']];
        let date = [];
        switch (this.props.search.view) {
            case 'week':
                let weekStartDate = this.props.search.fromDate;
                do {
                    date.push({
                        date: weekStartDate,
                        name: getMoment()(weekStartDate).format("ddd")
                    })
                    weekStartDate = getMoment()(weekStartDate).add(1, 'day').format("YYYY/MM/DD")
                } while (weekStartDate <= this.props.search.toDate)
                break;
            case 'month':
                let monthStartDate = this.props.search.fromDate;
                do {
                    date.push({
                        date: monthStartDate,
                        name: getMoment()(monthStartDate).format("Do")
                    })
                    monthStartDate = getMoment()(monthStartDate).add(1, 'day').format("YYYY/MM/DD")
                } while (monthStartDate <= this.props.search.toDate)
                break;
            case 'year':
                let yearStartDate = this.props.search.fromDate;
                do {
                    date.push({
                        date: getMoment()(yearStartDate).format("YYYY/MM"),
                        name: getMoment()(yearStartDate).format("MMM")
                    })
                    yearStartDate = getMoment()(yearStartDate).add(1, 'month').format("YYYY/MM/DD")
                } while (yearStartDate <= this.props.search.toDate)
                break;
            default:
        }

        // let companyList = [];
        // if (this.props.search.companyId) {
        //     companyList.push(this.props.search.companyId)
        // } else {
        //     companyList = _.map(this.props.exceptionReport, 'companyId');
        // }
        // companyList = _.uniq(companyList);

        let deviceList = [];
        let exceptionReportList = [];
        this.props.exceptionReport.forEach((item) => {
            // if(item.deviceId) {

            // }
            deviceList.push({
                deviceId: item.deviceId,
                deviceName: item.deviceName || '未关联设备'
            });
            exceptionReportList.push({
                total: item.total,
                countDate: item.countDate,
                // installId: item.installId,
                // installName: item.installName,
                deviceId: item.deviceId,
                deviceName: item.deviceName,
                companyId: item.companyId,
                companyName: item.companyName,
                status: item.status,
                statusName: this.renderStatus(item.status)
            })
        });
        exceptionReportList = _.uniqWith(exceptionReportList, _.isEqual);
        deviceList = _.uniqWith(deviceList, _.isEqual);

        let heatMapData = [];
        let totalMax = 0;
        exceptionReportList.forEach(exceptionRepo => {
            let xIndex = _.findIndex(date, { date: exceptionRepo.countDate });
            let yIndex = _.findIndex(deviceList, { deviceId: exceptionRepo.deviceId });
            let heatMapPoint = [xIndex, yIndex, exceptionRepo.total || 0];
            if (xIndex > -1 && yIndex > -1) {
                heatMapData.push(heatMapPoint);
            }

            if (totalMax < exceptionRepo.total) {
                totalMax = exceptionRepo.total;
            }

        });

        let seriesData = [{
            name: '异常数',
            type: 'heatmap',
            data: heatMapData,
            label: {
                show: true
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }];

        let option = {
            tooltip: {
                formatter: (params, ticket, callback) => {
                    const { marker, seriesName, name, data } = params;
                    return `${marker}${seriesName}<br /> 日期: ${name}<br />次数: ${data[2]}`
                }
                // formatter: '{a}<br /> 日期: {b}<br />次数: {d}'
                // trigger: 'axis',
                // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                //     type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                // }
            },
            dataset: {
                source: result
            },
            xAxis: {
                name: '日期',
                type: 'category',
                data: _.map(date, 'name'),
                splitArea: { show: true }
            },
            yAxis: {
                name: '设备',
                type: 'category',
                data: _.map(deviceList, 'deviceName'),
                splitArea: { show: true }
            },
            series: seriesData,
            visualMap: {
                min: 0,
                max: totalMax,
                calculable: true,
                realtime: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '0%'
            },
        }
        return option
    }

    renderStatus = (status) => {
        let text = ''
        switch (status) {
            case 'new':
                text = '未解决'
                break;
            case 'active':
                text = '解决中'
                break;
            case 'done':
                text = '已解决'
                break;
            default:
                break;
        }
        return text
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'status':
                newSearch = {
                    ...this.props.search,
                    status: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            // ▽　XJGL-127 异常分析页面变更　▽
            case 'inspectionType':
                newSearch = {
                    ...this.props.search,
                    inspectionType: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'routeId':
                newSearch = {
                    ...this.props.search,
                    routeId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'installId':
                newSearch = {
                    ...this.props.search,
                    installId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            // △　XJGL-127 异常分析页面变更　△
            case 'view':
                let fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
                let toDate = getMoment()().endOf('day').format("YYYY/MM/DD");
                switch (value.target.value) {
                    case 'year':
                        fromDate = getMoment()().startOf('year').format("YYYY/MM/DD");
                        toDate = getMoment()().endOf('year').format("YYYY/MM/DD");
                        break;
                    case 'month':
                        fromDate = getMoment()().startOf('month').format("YYYY/MM/DD");
                        toDate = getMoment()().endOf('month').format("YYYY/MM/DD");
                        break;
                    case 'week':
                        fromDate = getMoment()().startOf('week').format("YYYY/MM/DD");
                        toDate = getMoment()().endOf('week').format("YYYY/MM/DD");
                        break;
                    case 'day':
                        fromDate = getMoment()().startOf('day').format("YYYY/MM/DD");
                        toDate = getMoment()().startOf('day').format("YYYY/MM/DD");
                        break;
                    default:
                }
                newSearch = {
                    ...this.props.search,
                    view: value.target.value,
                    fromDate: fromDate,
                    toDate: toDate
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'day':
                newSearch = {
                    ...this.props.search,
                    view: this.props.search.view,
                    fromDate: getMoment()(value).startOf('day').format("YYYY/MM/DD"),
                    toDate: getMoment()(value).endOf('day').format("YYYY/MM/DD")
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'week':
                newSearch = {
                    ...this.props.search,
                    view: this.props.search.view,
                    fromDate: getMoment()(value).startOf('week').format("YYYY/MM/DD"),
                    toDate: getMoment()(value).endOf('week').format("YYYY/MM/DD"),
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'month':
                newSearch = {
                    ...this.props.search,
                    view: this.props.search.view,
                    fromDate: getMoment()(value).startOf('month').format("YYYY/MM/DD"),
                    toDate: getMoment()(value).endOf('month').format("YYYY/MM/DD"),
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'year':
                newSearch = {
                    ...this.props.search,
                    view: this.props.search.view,
                    fromDate: getMoment()(value).startOf('year').format("YYYY/MM/DD"),
                    toDate: getMoment()(value).endOf('year').format("YYYY/MM/DD"),
                }
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSearch(event) {
        this.props.onhandleSearch(0, 15, "-status,-create_time", "", "", "", "", "", "", "", "");
    }

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    handleDateChange = (value, field) => {
        let newSearch;
        switch (field) {
            case 'fromDate':
                newSearch = {
                    ...this.props.search,
                    fromDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'toDate':
                newSearch = {
                    ...this.props.search,
                    toDate: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    // ▽　XJGL-127 异常分析页面变更　▽
    handleExport() {
        this.props.onhandleExport();
    }

    handleDownload = (event, docList) => {
        if (docList && docList.length) {
            const promiseArr = [];
            docList.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.onsite-inspection.onsite-inspection-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docList.forEach((doc, index) => {
                    doc.url = res[index].payload;
                })
                this.props.onhandleOpenAttachmentDocs({
                    docs: docList,
                    title: '巡检记录图像'
                });
            }).catch(err => {

            });
        }
    }
    // △　XJGL-127 异常分析页面变更　△

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
        // 一览页
        if (newValue === 0) {
            let newSearch = {
                ...this.props.search,
                view: null,
                fromDate: null,
                toDate: null,
            };
            this.props.onhandleUpdateEditSearch(newSearch);
            this.props.onhandleResetLoad();
        }
        // 报表页
        if (newValue === 1) {
            let newSearch = {
                ...this.props.search,
                view: 'week',
                fromDate: getMoment()().startOf('week').format("YYYY/MM/DD"),
                toDate: getMoment()().endOf('week').format("YYYY/MM/DD")
            };
            this.props.onhandleUpdateEditSearch(newSearch);
            // this.props.onhandleGetExceptionReport("", "", "", "", "", "", "", "", "")
        }
    }

    // 巡检异常现象处置 XJGL-172  
    handleSetTreatmentPlan(treatmentScheme) {
        const { onhandleRouteToDevicePlan } = this.props;
        const { listId, deviceId } = treatmentScheme;
        onhandleRouteToDevicePlan(listId, 'temp', deviceId)
    }

    // 任务 巡检的异常处理 立即整改功能 XJGL-193
    handleAccept = (exception) => {
        this.props.onhandleAccept(exception);
    }

    handleOpenAcceptPannel = async (exception) => {
        this.props.onhandleOpenAcceptPannel(this.handleAccept.bind(this), null, exception);
    }

    handleChangeCheckedItem(checked, listId) {
        const {
            exceptionList,
            onhandleSetExceptionList,
        } = this.props;
        let newList = _.cloneDeep(exceptionList);
        newList.list.forEach(exception => {
            if (exception.listId === listId) {
                exception.checked = checked;
            }
        })
        onhandleSetExceptionList(newList);
    }

    // 任务 异常批量处理功能
    handleAcceptBatch = (exceptionList) => {
        this.props.onhandleAcceptBatch(exceptionList);
    }

    handleExceptionBatch(type) {
        const {
            exceptionList,
            onhandleCreateMessage
        } = this.props;
        const checkedList = _.filter(exceptionList.list, { 'checked': true });
        if (checkedList.length < 1) {
            onhandleCreateMessage('error', '无选中项');
            return;
        }
        // 判断是否是单一类型
        const expectStatus = type === 'handle' ? 'new' : 'pending'
        const wrongItemIndex = _.findIndex(checkedList, item => item.status !== expectStatus);
        if (wrongItemIndex >= 0) {
            onhandleCreateMessage('error', '所选异常项目包含其他状态，无法批量' + (type === 'handle' ? '处置' : '确认'));
            return;
        }
        const editList = [];
        checkedList.forEach(item => {
            const newItem = _.cloneDeep(item);
            newItem.handleTypeError = '';
            newItem.handleResultError = '';
            editList.push(newItem);
        })
        this.props.onhandleOpenAcceptBatchPannel(this.handleAcceptBatch.bind(this), null, editList, type);
    }

    render() {
        return (
            <div>
                <ExceptionListComponent
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    exceptionList={this.props.exceptionList}
                    search={this.props.search}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                    getExceptionList={this.props.onhandleSearch}
                    onSizeChange={this.handleListSize.bind(this)}
                    // ▽　XJGL-127 异常分析页面变更　▽
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    routeList={this.props.routeList}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    installationList={this.props.installationList}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleDownload={this.handleDownload.bind(this)}
                    // △　XJGL-127 异常分析页面变更　△
                    tabValue={this.state.tabValue}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                    isLoad={this.props.isLoad}
                    onhandleLoad={this.handleLoad.bind(this)}
                    // 巡检异常现象处置 XJGL-172                    
                    onSetTreatmentPlan={this.handleSetTreatmentPlan.bind(this)}
                    // 任务 巡检的异常处理 立即整改功能 XJGL-193
                    onAcceptTreatment={this.handleOpenAcceptPannel.bind(this)}
                    // 异常批量处置
                    onhandleChangeCheckedItem={this.handleChangeCheckedItem.bind(this)}
                    onhandleExceptionBatch={this.handleExceptionBatch.bind(this)}
                />

                <ExceptionAcceptPannel />
                <ExceptionBatchAcceptPanel />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        exceptionList: state.onsiteInspectionException.exceptionList,
        search: state.onsiteInspectionException.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        // ▽　XJGL-127 异常分析页面变更　▽
        inspectionDictionaries: state.constant.inspectionDictionaries,
        routeList: state.onsiteInspectionException.routeList,
        installationList: state.onsiteInspectionException.installationList ? state.onsiteInspectionException.installationList.list : [],
        // △　XJGL-127 异常分析页面变更　△
        isLoad: state.onsiteInspectionException.load,
        exceptionReport: state.onsiteInspectionException.exceptionReport
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitPage: () => {
            dispatch(initExceptionListPage());
        },
        onhandleUpdateEditSearch: (search) => {
            Promise.all([
                dispatch(setSearch(search))
            ]).then(() => {
                if (search.view && search.view !== '')
                    dispatch(getExceptionReport("", "", "", "", "", "", "", "", ""))
            }).catch(err => {
            });
        },
        onhandleClearSearch: () => {

            dispatch(resetSearch());
        },
        onhandleSearch: (offset, size, sort, companyId, status, inspectionType, routeId, installId, fromDate, toDate) => {
            dispatch(getExceptionList(offset, size, sort, companyId, status, inspectionType, routeId, installId, fromDate, toDate));
        },
        onhandleSetExceptionList: (list) => {
            dispatch(setExceptionList(list));
        },

        // ▽　XJGL-127 异常分析页面变更　▽
        onhandleExport: () => {
            dispatch(exportExceptionList());
        },
        onhandleOpenAttachmentDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        // △　XJGL-127 异常分析页面变更　△
        onhandleGetExceptionReport: (companyId, status, inspectionType, routeId, installId, fromDate, toDate) => {
            dispatch(getExceptionReport(companyId, status, inspectionType, routeId, installId, fromDate, toDate));
        },
        onhandleStopLoad: () => {
            dispatch(setLoad(false));
        },
        onhandleResetLoad: () => {
            dispatch(setLoad(true));
        },
        // 巡检异常现象处置 XJGL-172
        onhandleRouteToDevicePlan: (listId, planType, deviceId) => {
            dispatch(routeToDevicePlan(listId, planType, deviceId));
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        },
        // 任务 巡检的异常处理 立即整改功能 XJGL-193
        onhandleOpenAcceptPannel: (confirmCallback, closeCallback, exception) => {
            dispatch(openExceptionAcceptPannel(confirmCallback, closeCallback, exception))
        },
        onhandleAccept: (exception) => {
            dispatch(handleException(exception))
        },
        // 任务 巡检异常批量处理
        onhandleOpenAcceptBatchPannel: (confirmCallback, closeCallback, exceptionList, type) => {
            dispatch(openExceptionBatchAcceptPanel(confirmCallback, closeCallback, exceptionList, type))
        },
        onhandleAcceptBatch: (exceptionList) => {
            dispatch(handleExceptionBatch(exceptionList))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionExceptionListContainer);