import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { setOpenInstallationUser } from '@/redux/modules/amoeba/amoebaCostSharing';
import { getInstallationUserList, addAndUpdateInstallationUserList } from '@/redux/modules/installation';
import InstallationUserComponent from '@/components/AmoebaManagement/InstallationUser/InstallationUserComponent';
import { createActionMessage } from '@/redux/modules/message'
import * as _ from 'lodash';



class InstallationUserContainer extends Component {
    static propTypes = {
        rows: PropTypes.any,
        installId: PropTypes.number,
    }

    constructor(props) {
        super(props)
        this.state = {
            installId: 0,
            rows: [],
        }
    }


    componentDidMount() {

        this.refresh()

    }

    refresh = async () => {

        const { installId, onhandleGetInstallationUserList } = this.props

        await onhandleGetInstallationUserList(installId);

        const { installationUserList } = this.props

        let id = 0
        let rows = _.map(installationUserList, d => {
            ++id
            return { ...d, 'id': id }
        })
        this.setState({
            rows: rows,
        })
    }

    handleClose = () => {
        this.props.onhandleClose()
    }

    handleChange = (row, type) => {
        let newRows = []
        if (type === 'add') {
            newRows = _.cloneDeep(this.state.rows)
            newRows.unshift(row)
        } else if (type === 'delete') {
            newRows = _.cloneDeep(this.state.rows)
            _.remove(newRows, d => { return d.id === row.id })
        }
        else {
            for (let d of this.state.rows) {
                if (d.id === row.id) {
                    d = { ...row }
                }
                newRows.push(d)
            }
        }
        this.setState({
            rows: newRows
        })
    }

    handleSave = () => {
        const { rows } = this.state
        const { installId } = this.props
        const { sharingTakelist, onhandleAddAndUpdateInstallationUserList, createActionMessage } = this.props

        let userDptNotInSharingTake = _.find(rows, r => {
            return !_.find(sharingTakelist, { 'takerDpt': r.dptId })
        })
        if (userDptNotInSharingTake) {
            return createActionMessage('warning', '选择人员所在班组不在分摊班组范围内。')
        }

        onhandleAddAndUpdateInstallationUserList(installId, rows);

    }



    render() {
        return (
            <div>
                <InstallationUserComponent
                    openFlag={this.props.openFlag}
                    departments={this.props.departments}
                    installationList={this.props.installationList}
                    rows={this.state.rows}
                    installId={this.props.installId}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                ></InstallationUserComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        openFlag: state.amoebaCostSharing.openInstallationUser,
        installationUserList: state.installation.installationUserList,
        departments: state.department.departments.list,
        installationList: state.installation.installationList.list,
        installId: state.amoebaCostSharing.selectedInstallation,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
        onhandleClose: () => {
            return dispatch(setOpenInstallationUser(false));
        },
        onhandleGetInstallationUserList: (installId) => {
            return dispatch(getInstallationUserList(installId));
        },
        onhandleAddAndUpdateInstallationUserList: (installId, dtos) => {
            return dispatch(addAndUpdateInstallationUserList(installId, dtos));
        },


    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationUserContainer);
