import React from 'react';
import {
    Grid,
    Tab,
    Tabs,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import PrimaryPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/PrimaryPricingContainer';
import OtherPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/OtherPricingContainer';
import OtherFreeContainer from '@/containers/AmoebaManagement/BudgetPricing/OtherFreeContainer';
import OtherFreeTwoContainer from '@/containers/AmoebaManagement/BudgetPricing/OtherFreeTwoContainer';

const PricingComponent = props => {
    const { classes,
        tabValue,
        onhandleChangeTab,
    } = props;
    return (
        <div alignitems="center" className={classes.root}>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"材料"} className={classes.tab} />
                <Tab label={"业务分包"} className={classes.tab} />
                <Tab label={"其他费用"} className={classes.tab} />
                <Tab label={"其他费用"} className={classes.tab} />
            </Tabs>
            <Grid>
                {tabValue === 0 && <PrimaryPricingContainer tabValue={tabValue} />}
                {tabValue === 1 && <OtherPricingContainer tabValue={tabValue} />}
                {tabValue === 2 && <OtherFreeContainer tabValue={tabValue} />}
                {tabValue === 3 && <OtherFreeTwoContainer tabValue={tabValue} />}
            </Grid>
        </div>
    )
}

PricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

PricingComponent.defaultProps = {
};

export default withStyles(PricingComponent);