import * as _ from 'lodash';

export const PLAN_ANNU = "ANNU";
export const PLAN_MONTH = "MONTH";
export const PLAN_DAYMONTH = "DAYMONTH";
export const PLAN_DAY = "DAY";
export const ANNUAL_PLAN_VIEW_ORGVIEW = 'ORGVIEW';
export const ANNUAL_PLAN_VIEW_MONTHVIEW = 'MONTHVIEW';
export const MONTH_PLAN_VIEW_ORGVIEW = 'ORGVIEW';
export const MONTH_PLAN_VIEW_DAYVIEW = 'DAYVIEW';



const AMOUNT_CONVERSION = 10000;
const AMOUNT_UNIT = '万元';

/**
 * 获取下属公司列表或部门列表
 * @param {*} subDataList 
 * @param {*} companyId 
 */
export function getComOrDptIds(subDataList, companyId) {
    let companyIds = '' + companyId;
    let dptIds = '';
    if (subDataList.length > 0 && subDataList[0].isMainCompany) {
        subDataList.forEach((element, index) => {
            if (typeof element.id === 'string' && element.id.indexOf('office_') >= 0) {
                return;
            }
            if (!(index === 0) && !element.isDpt) {
                companyIds += ',' + element.id
            } else if (element.isDpt) {
                // 班组的场合
                let dptId = element.id;
                dptId = dptId.substring(dptId.indexOf('_') + 1);
                if (dptIds === '') {
                    dptIds += dptId;
                } else {
                    dptIds += ',' + dptId;
                }
            }
        });
        return [companyIds, dptIds];
    } else if (subDataList.length > 0 && !subDataList[0].isMainCompany) {
        if (subDataList[0].isDpt) {
            dptIds += subDataList[0].id
            return [[], dptIds];
        } else {
            subDataList.forEach((element) => {
                if (element.isDpt) {
                    let dptId = element.id;
                    dptId = dptId.substring(dptId.indexOf('_') + 1);
                    if (dptIds === '') {
                        dptIds += dptId;
                    } else {
                        dptIds += ',' + dptId;
                    }
                }
            });
            return [companyIds, dptIds];
        }
    }
    return [[], []]
}

/**
 * 获取公司纵列动态显示数据
 * @param {*} companyList 
 * @param {*} userDepartment 
 * @param {*} departmentList 
 * @param {*} companyId 
 * @param {*} isMainCompany 
 */
export function getSubList(companyList, userDepartment, departmentList, companyId, isMainCompany) {
    let subDataList = [];
    if (companyList && companyList.length > 0) {
        if (isMainCompany) {
            let mainSubData = {};
            mainSubData.id = companyId;
            mainSubData.name = companyList.find(ele => ele.companyId === companyId).companyAlias;
            mainSubData.isMainCompany = true;
            mainSubData.isDpt = false;
            subDataList.push(mainSubData);
            companyList.forEach(element => {
                if (element.pid) {
                    let subData = {};
                    subData.id = element.companyId;
                    subData.name = element.companyAlias;
                    subData.isMainCompany = false;//是否总公司
                    subData.isDpt = false;//是否班组
                    subDataList.push(subData);
                }
            });
        } else {
            if (userDepartment.executable) {
                //班组
                let mainSubData = {};
                mainSubData.id = userDepartment.dptId;
                mainSubData.name = userDepartment.dptName;
                mainSubData.isMainCompany = false;
                mainSubData.isDpt = true;
                subDataList.push(mainSubData);
                for (let i = 1; i <= 12; i++) {
                    let subData = {};
                    subData.id = 'month_' + i;
                    subData.name = i + '月';
                    subDataList.push(subData);
                }
                this.setState({
                    isDpt: true,
                })
            } else {
                //分公司
                let mainSubData = {};
                mainSubData.id = companyId;
                mainSubData.name = companyList.find(ele => ele.companyId === companyId).companyAlias;
                mainSubData.isMainCompany = false;
                mainSubData.isDpt = false;
                mainSubData.companyId = companyId;
                subDataList.push(mainSubData);
                const executeDpts = departmentList.filter(ele => ele.executable === true)
                executeDpts.forEach(ele => {
                    if (ele.companyId === companyId) {
                        let subData = {};
                        subData.id = 'dpt_' + ele.dptId;
                        subData.name = ele.dptName;
                        subData.isMainCompany = false;
                        subData.isDpt = true;
                        subDataList.push(subData);
                    }
                })
            }
        }
    }
    return subDataList;
}

/**
 * 获取折旧计划显示数据
 * @param {*} plan 
 */
export function sortDepreciationPlan(plans) {
    const [MONTH, UNIT] = ['月', '元'];
    // 横向月度数据
    let monthData = [];
    for (let i = 1; i < 13; i++) {
        let data = {
            amoebaSub: {
                subName: i + MONTH,
                unit: UNIT
            },
            month: i,
            dtlIds: [],
            trueAmounts: []
        }
        monthData.push(data);
    }
    //编集显示数据
    let planData = [];
    let row = { ...plans[0] }
    row.amoebaSub.unit = UNIT;
    let rowData = {
        amoebaSub: row.amoebaSub,
        month: '',
        dtlIds: [],
        trueAmounts: []
    }
    if (row.subPlanDtls && row.subPlanDtls.length > 0) {
        for (let subPlanDtl of row.subPlanDtls) {
            const trueAmount = subPlanDtl.amount;//真实数值
            if (subPlanDtl.amoebaLevel === null && subPlanDtl.month !== null) {
                let monthRow = monthData[(subPlanDtl.month * 1) - 1];
                let rowName = 'dtl_' + subPlanDtl.companyId;
                if (subPlanDtl.dptId) {
                    rowName = 'dtl_dpt_' + subPlanDtl.dptId;
                }
                monthRow[rowName] = trueAmount;
                monthRow['dtlIds'].push(subPlanDtl.dtlId);
                const amountDto = {
                    'amount': trueAmount,
                    'id': rowName
                }
                monthRow['trueAmounts'].push(amountDto);
            } else {
                let rowName = 'dtl_' + subPlanDtl.companyId;
                if (subPlanDtl.dptId) {
                    rowName = 'dtl_dpt_' + subPlanDtl.dptId;
                }
                rowData[rowName] = trueAmount;
                rowData['dtlIds'].push(subPlanDtl.dtlId);
                const amountDto = {
                    'amount': trueAmount,
                    'id': rowName
                }
                rowData['trueAmounts'].push(amountDto);
            }
        }
    }
    planData.push(rowData);
    planData.push(...monthData);
    return planData;
}

/**
 * 获取基础的预算显示数据
 * @param {*} plan 
 */
export function getDefaultSortPlans(plan) {
    let subs = [];
    if (plan && plan.planDtls && plan.planDtls.length > 0) {
        _.forEach(plan.planDtls, (value) => {
            let sub = {};
            sub.subId = value.amoebaSub.subId;
            sub.subParentId = value.amoebaSub.subParentId;
            sub.subName = value.amoebaSub.subName;
            sub.subLevel = value.amoebaSub.subLevel;
            sub.unit = value.amoebaSub.unit;
            const amount = amountConversion(value.amount, value.amoebaSub.unit)
            sub[value.dptId ? 'dtl_' + value.dptId : 'dtl_' + value.companyId] = { 'dtlId': value.dtlId, 'amount': amount };
            subs.push(sub);
            _.forEach(value.subPlanDtls, (value) => {
                let sub = {};
                sub.subId = value.amoebaSub.subId;
                sub.subParentId = value.amoebaSub.subParentId;
                sub.subName = value.amoebaSub.subName;
                sub.subLevel = value.amoebaSub.subLevel;
                sub.unit = value.amoebaSub.unit;
                const amount = amountConversion(value.amount, value.amoebaSub.unit)
                sub[value.dptId ? 'dtl_' + value.dptId : 'dtl_' + value.companyId] = { 'dtlId': value.dtlId, 'amount': amount };
                subs.push(sub);
            })
        })
    }
    return subs;
}

function amountConversion(amount, unit) {
    if (unit === AMOUNT_UNIT) {
        amount = (amount / AMOUNT_CONVERSION).toFixed(2);
    }
    return amount;
}

/**
 * 设定预算显示数据
 * @param {*} sortPlan 
 * @param {*} plan 
 */
function setSortPlan(sortPlan, planDtls, plan) {
    const planDtl = planDtls.find(planDtl => planDtl.subId === sortPlan.subId);
    if (planDtl) {
        const amount = amountConversion(planDtl.amount, sortPlan.unit);
        if (planDtl.subId === sortPlan.subId) {
            if (planDtl.dptId === null && (plan.month == null)) {
                sortPlan['dtl_' + planDtl.companyId] = { 'dtlId': planDtl.dtlId, 'amount': amount };
            } else if (planDtl.dptId) {
                sortPlan['dtl_dpt_' + planDtl.dptId] = { 'dtlId': planDtl.dtlId, 'amount': amount };
            }
        }
    } else {
        // 数据异常
        sortPlan['dtl_' + planDtl.companyId] = { 'dtlId': planDtl.dtlId, 'amount': -9999999 };
    }
}

export function sortPlans(planList, companyId, isMainCompany) {
    //获取公司总和数据
    const mainPlan = planList.find(ele => ele.companyId === companyId && ele.planType === PLAN_ANNU);
    // 取得预算基础数据(竖列指标及总和数据)
    let sortPlans = getDefaultSortPlans(mainPlan);
    //获取非本公司数据
    let otherPlans = planList.filter(ele => ele.companyId !== companyId);
    if (mainPlan) {
        if (otherPlans) {
            // 年度视图的场合
            const otherAnnuPlans = planList.filter(ele => ele !== mainPlan);
            otherAnnuPlans.forEach(plan => {
                sortPlans.forEach(sortPlan => {
                    //设定数据
                    if (isMainCompany && plan.companyId === companyId && plan.dptId) {
                        const planDtl = plan.planDtls.find(planDtl => planDtl.subId === sortPlan.subId);
                        if (planDtl) {
                            let originAmount = sortPlan['dtl_office_' + planDtl.companyId] ?
                                sortPlan['dtl_office_' + planDtl.companyId].amount : 0;
                            let amount = planDtl.amount + originAmount
                            if (planDtl.subId === sortPlan.subId) {
                                sortPlan['dtl_office_' + planDtl.companyId] = { 'dtlId': planDtl.dtlId, 'amount': amount };
                            }
                        }
                    } else {
                        setSortPlan(sortPlan, plan.planDtls, plan)
                    }
                });
            });
        } else {
            // 月度视图的场合
            const monthPlans = planList.filter(ele => ele.companyId === companyId && ele.planType === PLAN_MONTH);
            if (monthPlans && monthPlans.length > 0) {
                monthPlans.forEach(plan => {
                    sortPlans.forEach(sortPlan => {
                        const planDtl = plan.planDtls.find(planDtl => planDtl.subId === sortPlan.subId);
                        const amount = amountConversion(planDtl.amount, sortPlan.unit);
                        if (planDtl) {
                            sortPlan['dtl_month_' + plan.month] = { 'dtlId': planDtl.dtlId, 'amount': amount };
                        }
                    });
                });
            } else {
                //分厂的场合
                const otherAnnuPlans = planList.filter(ele => ele !== mainPlan);
                otherAnnuPlans.forEach(plan => {
                    sortPlans.forEach(sortPlan => {
                        //设定数据
                        setSortPlan(sortPlan, plan.planDtls)
                    });
                });

            }
        }
        for (let sp of sortPlans) {
            for (let key of Object.keys(sp)) {
                if (key.indexOf('dtl_office_') !== -1) {
                    sp[key] = {
                        ...sp[key],
                        amount: amountConversion(sp[key].amount, sp.unit)
                    }
                }
            }
        }
        return sortPlans;
    }
    return [];
}


export function assemblyDailyView(plans, indexes, selectedDate, companyId, isMainCompany, department) {

    let dayCount = selectedDate.daysInMonth()
    if (isMainCompany) {
        //总公司
        return _.map(indexes, (sub, idx) => {
            let row = { ...sub }
            const monthPlan = _.find(plans, p => { return p.planType === PLAN_MONTH && p.companyId === companyId })
            if (monthPlan) {
                let monthDtl = _.find(monthPlan.planDtls, dtl => {
                    return !dtl.dptId && dtl.subId === sub.subId
                })
                if (monthDtl) {
                    row['dtl_' + companyId] = amountConversion(monthDtl.amount, sub.unit)
                }
            }

            for (let day = 1; day <= dayCount; day++) {
                row['dtl_day_' + day] = 0;
                const plan = _.find(plans, p => { return p.day === day && p.companyId === companyId })
                if (plan) {
                    let planDtl = _.find(plan.planDtls, dtl => {
                        return !dtl.dptId && dtl.subId === sub.subId
                    })
                    if (planDtl) {
                        row['dtl_day_' + day] = amountConversion(planDtl.amount, sub.unit)
                        if (planDtl.amount > 0) {
                            console.log('subName=' + sub.subName + ', day=' + day + ', amount=' + planDtl.amount)
                        }
                    }
                }
            }
            return row
        })
    } else { //分公司 或 班组
        return _.map(indexes, (sub, idx) => {
            let row = { ...sub }
            //月份
            const monthPlan = _.find(plans, p => { return p.planType === PLAN_MONTH && p.companyId === companyId })
            if (monthPlan) {
                let monthDtl = undefined
                if (department.executable) { //班组
                    monthDtl = _.find(monthPlan.planDtls, dtl => {
                        return dtl.dptId === department.dptId && dtl.subId === sub.subId
                    })
                    if (monthDtl) {
                        row['dtl_' + monthDtl.dptId] = amountConversion(monthDtl.amount, sub.unit)
                    }
                } else {
                    //分公司
                    monthDtl = _.find(monthPlan.planDtls, dtl => {
                        return !dtl.dptId && dtl.subId === sub.subId
                    })
                    if (monthDtl) {
                        row['dtl_' + companyId] = amountConversion(monthDtl.amount, sub.unit)
                    }
                }
            }

            //每日
            for (let day = 1; day <= dayCount; day++) {
                row['dtl_day_' + day] = 0;
                const plan = _.find(plans, p => { return p.day === day && p.companyId === companyId })
                if (plan) {
                    let planDtl = undefined
                    if (department.executable) {
                        //班组
                        planDtl = _.find(plan.planDtls, dtl => {
                            return dtl.dptId === department.dptId && dtl.subId === sub.subId
                        })
                    } else {
                        //分公司
                        planDtl = _.find(plan.planDtls, dtl => {
                            return !dtl.dptId && dtl.subId === sub.subId
                        })
                    }
                    if (planDtl) {
                        row['dtl_day_' + day] = amountConversion(planDtl.amount, sub.unit)
                        if (planDtl.amount > 0) {
                            console.log('subName=' + sub.subName + ', day=' + day + ', amount=' + planDtl.amount)
                        }
                    }
                }
            }
            return row
        })
    }

}


export function assemblyOrgView(plans, indexes, companyId, isMainCompany, dptIds, companyIds) {


    if (isMainCompany) {
        //总公司

        const plan = _.find(plans, p => { return p.companyId === companyId })

        return _.map(indexes, (sub, idx) => {
            let row = { ...sub }
            for (let cmpId of companyIds) {
                row['dtl_org_' + cmpId] = 0;
                const subPlan = _.find(plans, p => { return p.companyId === cmpId })
                if (subPlan) {
                    //分公司
                    let planDtl = _.find(subPlan.planDtls, dtl => {
                        return !dtl.dptId && dtl.subId === sub.subId
                    })
                    if (planDtl) {
                        row['dtl_org_' + cmpId] = amountConversion(planDtl.amount, sub.unit)
                    }
                }
            }
            if (plan) {
                let planCompanyDtl = _.find(plan.planDtls, dtl => {
                    return !dtl.dptId
                        && dtl.subId === sub.subId
                })
                if (planCompanyDtl) {
                    row['dtl_' + companyId] = amountConversion(planCompanyDtl.amount, sub.unit)
                }
            }
            return row
        })
    } else { //分公司
        const plan = _.find(plans, p => { return p.companyId === companyId })
        return _.map(indexes, (sub, idx) => {
            let row = { ...sub }
            for (let dptId of dptIds) {
                row['dtl_org_' + dptId] = 0;
                if (plan) {
                    //分公司
                    let planDtl = _.find(plan.planDtls, dtl => {
                        return dtl.dptId === dptId
                            && dtl.subId === sub.subId
                    })
                    if (planDtl) {
                        row['dtl_org_' + dptId] = amountConversion(planDtl.amount, sub.unit)
                        if (planDtl.amount > 0) {
                            console.log('subName=' + sub.subName + ', dptId=' + dptId + ', amount=' + planDtl.amount)
                        }
                    }
                }
            }
            if (plan) {
                let planCompanyDtl = _.find(plan.planDtls, dtl => {
                    return !dtl.dptId
                        && dtl.subId === sub.subId
                })
                if (planCompanyDtl) {
                    row['dtl_' + companyId] = amountConversion(planCompanyDtl.amount, sub.unit)
                }
            }
            return row
        })
    }

}