import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProcessInfo from '../../../components/SystemManagement/ProcessManagement/ProcessInfo'
import {
    createProcess,
    updateProcess,
    setCurrentProcess,
    backToProcessList,
    setProcessError,
    setProcessStatus,
    setProcessChange
} from "../../../redux/modules/process";
import * as _ from 'lodash';
import PropTypes from "prop-types";
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from './CustomProperties'
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import Modeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import { emptyBpmn } from '../../../empty.bpmn';
import customTranslate from './BpmnTranslate/customTranslate';
import { createMessage } from '../../../redux/modules/message';
import { PaletteProvider } from "./palette";
import { ContextPadProvider } from "./contextPad";
import './palette.css';

const customPaletteModule = {
    paletteProvider: ['type', PaletteProvider]
};

const customContextPadModule = {
    contextPadProvider: ['type', ContextPadProvider]
}

const customTranslateModule = {
    translate: ['value', customTranslate]
};

class ProcessEditContainer extends Component {

    static propTypes = {
        currentProcess: PropTypes.object,
        processError: PropTypes.object,
        modeler: PropTypes.any,
        constants: PropTypes.object,
        isloading: PropTypes.bool
    };

    modeler = null;

    componentDidMount() {
        this.modeler = new Modeler({
            container: '#bpmn_view',
            propertiesPanel: {
                parent: '#prop_view',
            },
            additionalModules: [
                propertiesPanelModule,
                propertiesProviderModule,
                customPaletteModule,
                customContextPadModule,
                customTranslateModule
            ],
            moddleExtensions: {
                camunda: camundaModdleDescriptor,
            },
        });
        let bpmnXml = this.props.currentProcess.bpmn && this.props.currentProcess.bpmn !== '' ? this.props.currentProcess.bpmn : emptyBpmn;
        this.importXML(bpmnXml);
    }

    importXML = (xml) => {
        this.modeler.importXML(xml, (error) => {
            if (error) {
                return createMessage('error', '导入流程图失败');
            }
        });
        // 删除 bpmn logo
        const bjsIoLogo = document.querySelector('.bjs-powered-by');
        while (bjsIoLogo.firstChild) {
            bjsIoLogo.removeChild(bjsIoLogo.firstChild);
        }
    }


    handleSave = async (event) => {
        await this.handleCheck(event, null);
        this.modeler.saveXML({ format: true }, (err, xml) => {
            if (_.findIndex(Object.values(this.props.processError), function (o) { return o !== ''; }) < 0) {
                this.props.onhandleProcessChange(false);
                if (this.props.currentProcess.processId) {
                    this.props.onhandleUpdateProcess(xml);
                } else {
                    this.props.onhandleCreateProcess(xml);
                }
            }
        });
    }

    handleSelect = (value, field) => {
        this.props.onhandleProcessChange(true);
        let newProcess;
        if (field === 'processType') {
            newProcess = {
                ...this.props.currentProcess,
                processType: value.target.value
            };
            this.props.onhandleUpdateEditProcess(newProcess);
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleProcessChange(true);
        let newProcess;
        switch (field) {
            case 'processName':
                newProcess = {
                    ...this.props.currentProcess,
                    processName: event.target.value
                };
                this.props.onhandleUpdateEditProcess(newProcess);
                break;
            case 'processCode':
                newProcess = {
                    ...this.props.currentProcess,
                    processCode: _.trim(event.target.value)
                };
                this.props.onhandleUpdateEditProcess(newProcess);
                break;
            case 'opentext':
                newProcess = {
                    ...this.props.currentProcess,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditProcess(newProcess);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToProcessList();
    };

    handleActivate = (event) => {
        this.props.onhandleUpdateStatus('active');
    };

    handleDeactivate = (event) => {
        this.props.onhandleUpdateStatus('draft');
    };

    handleCheck = (event, field) => {
        let err = {};
        if (!field || field === 'processName') {
            if (_.trim(this.props.currentProcess.processName) === '') {
                err['processName'] = '流程名称不能为空';
            } else {
                err['processName'] = '';
            }
        }

        if (!field || field === 'processCode') {
            if (_.trim(this.props.currentProcess.processCode) === '') {
                err['processCode'] = '流程编码不能为空';
            } else {
                err['processCode'] = '';
            }
        }

        if (!field || field === 'processType') {
            if (_.trim(this.props.currentProcess.processType) === '') {
                err['processType'] = '流程类型不能为空';
            } else {
                err['processType'] = '';
            }
        }

        let newEror = {
            ...this.props.processError,
            ...err
        }

        this.props.onhandleSetError(newEror);

    };

    render() {
        return (
            <ProcessInfo
                onhandleSave={this.handleSave.bind(this)}
                onhandleActivate={this.handleActivate.bind(this)}
                onhandleDeactivate={this.handleDeactivate.bind(this)}
                currentProcess={this.props.currentProcess}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleSelectionChange={this.handleSelect.bind(this)}
                processError={this.props.processError}
                constants={this.props.constants}
                diagramChange={this.props.diagramChange}
                processChange={this.props.processChange}
                isloading={this.props.isloading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentProcess: state.process.currentProcess,
        processError: state.process.processError,
        constants: state.constant.constants,
        diagramChange: state.process.diagramChange,
        processChange: state.process.processChange,
        isloading: state.process.isloading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateProcess: (xml) => {
            dispatch(createProcess(xml))
        },
        onhandleUpdateProcess: (xml) => {
            dispatch(updateProcess(xml))
        },
        onhandleUpdateEditProcess: (entity) => {
            dispatch(setCurrentProcess(entity))
        },
        onhandleBackToProcessList: () => {
            dispatch(backToProcessList())
        },
        onhandleSetError: (err) => {
            dispatch(setProcessError(err))
        },
        onhandleUpdateStatus: (status) => {
            dispatch(setProcessStatus(status))
        },
        onhandleProcessChange: (isChanged) => {
            dispatch(setProcessChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProcessEditContainer)
