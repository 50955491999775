import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  item: {
    padding: 5
  },
  card: {
    width: '100%',
    padding: 20,
    margin: 5
  },
  selectField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  textAreaField: {
    width: '100%'
  },
  gridContainer: {

  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  label: {
  },
  gridItem: {
    height: 24
  },
  select: {
    width: '90%',
    marginTop: 7
  },

  tabs: {
    margin: 5,
    background: 'none',
    color: '#2979ff',
    borderBottom: '1px solid #e7e7e7',
    '&:hover': {
      color: '#2979ff',
      opacity: 1
    },
    '&:focus': {
      color: '#2979ff'
    }
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  toolbar: {
    backgroundColor: 'white',
    alignItems: 'center',
    paddingBottom: 8
  },

  toolbarLabel: {
    padding: '8px'
  },

  toolbarLabelText: {
  },

  toolbarButton: {
  },

  flexgrow: {
    flexGrow: 1
  },
  timeInterval: {
    textAlign: 'center',
    margin: 5
  },

  currentCard: {
    width: '100%',
    padding: 7,
    margin: 5,
    height: 475,
    overflowY: 'auto',
  },

  intervalGrid: {
    height: 385,
    overflowY: 'auto',
  },
  warn: {
    color: '#c23531'
  }
});

export default withStyles(styles);

