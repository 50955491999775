import React from 'react';
import {
    Grid,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { findCompanyNameById, findDptNameById } from '@/utils/constant';
import { formatDate } from '@/utils/datetime';
import * as _ from 'lodash';


const ShiftRuleListComponent = props => {
    const { classes,
        shiftRuleList,
        selectedCompanyId,
        selectedCompanyName,
        shiftTypeDicts,
        companies,
        constant,
        // onhandleCreateShift,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
        isMainCompany,
        roleFlag,
        onhandleChangeCompany,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        //toolbar: false,
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '分厂',
                field: 'companyName',
                render: row => <Typography>{findCompanyNameById(constant, row.companyId)}</Typography>
            },
            {
                title: '班组',
                field: 'dptName',
                render: row => <Typography>{findDptNameById(constant, row.dptId)}</Typography>

            },
            {
                title: '排班类型',
                field: 'shiftType',
                render: row => <Typography>{getShiftType(row)}</Typography>
            },
            {
                title: '上班天数',
                field: 'workDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.workDays : "-"}</Typography>
            },
            {
                title: '休息天数',
                field: 'restDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.restDays : "-"}</Typography>
            },
            {
                title: '上班天数',
                field: 'secondWorkDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.secondWorkDays : "-"}</Typography>
            },
            {
                title: '休息天数',
                field: 'secondRestDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.secondRestDays : "-"}</Typography>
            },
            {
                title: '上班天数',
                field: 'thirdWorkDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.thirdWorkDays : "-"}</Typography>
            },
            {
                title: '休息天数',
                field: 'thirdRestDays',
                align: 'right',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? row.thirdRestDays : "-"}</Typography>
            },
            {
                title: '开始日期',
                field: 'startTime',
                render: row => <Typography>{row.shiftType && row.shiftType === 'SHIFT_WORKDAY' ? (row.startTime ? formatDate(row.startTime) : "") : "-"}</Typography>
            },
        ]
        return columns
    }

    const renderSelectCompany = () => {
        if (companies && companies.length >= 1) {
            return <Select
                labelId="company-select"
                id="company-select"
                value={selectedCompanyId}
                inputProps={{ readOnly: !isMainCompany }}
                style={{ width: '90%' }}
                onChange={onhandleChangeCompany}
            >
                {companies.map((c) => (
                    <MenuItem key={c.companyId} value={c.companyId}> {c.companyName}</MenuItem>
                ))}
            </Select>
        }
    }

    const assemblyData = () => {
        // console.log(roleFlag)
        return shiftRuleList
    }

    const getEditHiddenValue = (rowdata) => {
        const editDic = {
            icon: 'edit',
            tooltip: '修改',
            onClick: (event, rowData) => onhandleUpdate(rowData)
        };
        // if(currentUserInfo.companyId === rowdata.companyId&&currentUserInfo.userId === rowdata.creator){

        return editDic;
        // }

    }

    const getShiftType = (row) => {
        if (!row.shiftType) {
            return "未设置"
        }
        const type = _.find(shiftTypeDicts, item => {
            return item.code === row.shiftType
        });
        if (type) {
            return type.name
        } else {
            return "未定义"
        }
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item className={classes.action} xs={3} >
                        {/* <Grid item xs={12}>
                               <Button color="primary" variant="contained" className={classes.button}
                                   onClick={onhandleCreateShift}>生成排班</Button>
                        </Grid> */}
                    </Grid>
                    <Grid item className={classes.action} xs={7} >
                    </Grid>
                    <Grid item className={classes.action} xs={2} >
                        <Grid item xs={12}>
                            {renderSelectCompany()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>{selectedCompanyName}班组排班设置列表</Typography>}
                        editable={
                            {
                                // isDeleteHidden:rowData=>isMainCompany||rowData.creator!==currentUserInfo.userId,
                                onRowDelete: (oldData) => onhandleDelete(oldData)
                            }
                        }
                        actions={[
                            // {
                            //     icon: 'class',
                            //     tooltip: '生成排班',
                            //     isFreeAction: true,
                            //     // hidden:isMainCompany,
                            //     onClick: (event) => new Promise((resolve, reject) => {
                            //         onhandleCreateShift();
                            //         resolve();
                            //     })
                            // },
                            {
                                icon: 'add',
                                tooltip: '新增',
                                isFreeAction: true,
                                // hidden:isMainCompany,
                                onClick: (event, rowData) => new Promise((resolve, reject) => {
                                    onhandleAdd();
                                    resolve();
                                })
                            },
                            rowData => roleFlag && getEditHiddenValue(rowData),
                        ]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

ShiftRuleListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object,
    isMainCompany: PropTypes.bool,
    companies: PropTypes.array,
    shiftRuleList: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleAdd: PropTypes.func,
    onhandleOpen: PropTypes.func,
    shiftTypeDicts: PropTypes.array,
};

ShiftRuleListComponent.defaultProps = {
    shiftRuleList: [],
    shiftTypeDicts: [],
    currentUserInfo: {},
    companies: [],
    isMainCompany: false,
};

export default withStyles(ShiftRuleListComponent);