import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment as moment, getDateByString } from '@/utils/datetime';
import _ from 'lodash';


const InventoryEditPanel = ({
    classes,
    open,
    showNoSpecify,
    inspectionDptMapping,
    userProfile,
    listTypeList,
    categoryList,
    inventoryError,
    dateError,
    inventory,
    companyList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleDateChange
}) => {

    // console.log('==== ==== ==== == inventory == ==== ==== ==== ====')
    // console.log(inventory)
    // console.log(inventoryError)

    const isCreate = !inventory || !inventory.inventoryId;

    const hasCatCode = Boolean(_.find(categoryList, { 'pcode': inventory.listType }));

    // 分厂人员
    const isBranchComp = Boolean(_.find(companyList, o => o.companyId === userProfile.roles[0].companyId).pid);

    const minStartDate = moment()().startOf('year');

    const minEndDate = inventory.validStartTime ? moment()(inventory.validStartTime).endOf('day') : minStartDate.endOf('day');
    const categoryOptions = () => {
        // 过滤出当前用户可访问的子分类完整信息列表
        let filteredCategoryList = [];
        categoryList.filter(item => (item.pcode === inventory.listType)).forEach((cat) => {
            inspectionDptMapping.forEach((accessbleCat) => {
                if (accessbleCat.inspectionCode === cat.code) {
                    filteredCategoryList.push(cat);
                }
            })
        });

        return filteredCategoryList ? filteredCategoryList.map(cat => (
            <MenuItem dense key={cat.code} value={cat.code}>
                {cat.name}
            </MenuItem>
        )) : null
    }

    const getValidDateTips = () => {
        if (!inventory.validDate) {
            return '检查表将长期有效'
        }
        if (inventory.validStartTime && inventory.validEndTime) {
            if (moment()(inventory.validStartTime).isSame(inventory.validEndTime, 'day')) {
                return '检查表有效期为 ' + getDateByString(inventory.validStartTime) + ' 当日'
            }
            return '检查表有效期为 ' + getDateByString(inventory.validStartTime) + ' 至 ' + getDateByString(inventory.validEndTime) + ' 有效';
        }
        if (inventory.validStartTime && !inventory.validEndTime) {
            return '检查表将于 ' + getDateByString(inventory.validStartTime) + ' 生效';
        }
        if (!inventory.validStartTime && inventory.validEndTime) {
            return '检查表将于 ' + getDateByString(inventory.validEndTime) + ' 后失效';
        }
        return ''
    }
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{isCreate ? "新建检查表" : "检查表基础信息"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"检查表名称"}
                                error={inventoryError.inventoryName !== ''}
                                helperText={inventoryError.inventoryName}
                                variant='outlined'
                                className={classes.textField}
                                value={inventory.inventoryName || ''}
                                rows={6}
                                name='inventoryName'
                                onChange={event => onhandleChange(event, 'inventoryName')}
                            />
                        </Grid>
                        <Grid container className={classes.contentGrid}>
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    label='排查类型'
                                    error={inventoryError.listType !== ''}
                                    helperText={inventoryError.listType}
                                    select
                                    value={inventory.listType || ''}
                                    name='listType'
                                    onChange={onhandleChange}>
                                    {listTypeList.map((cat) => (
                                        <MenuItem dense key={cat.code} value={cat.code}>
                                            {cat.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {hasCatCode &&
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='子分类'
                                        error={inventoryError.catCode !== ''}
                                        helperText={inventoryError.catCode}
                                        select
                                        value={inventory.catCode || ''}
                                        name='catCode'
                                        onChange={onhandleChange}>
                                        {categoryOptions()}
                                    </TextField>
                                </Grid>
                            }
                            <Grid item className={classes.itemGrid}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    label='监督单位'
                                    // error={inventoryError.companyId !== ''}
                                    helperText={(!inventory.companyId || inventory.companyId === '') && '注意：本检查表允许分发给所有部门/分厂'}
                                    select
                                    value={inventory.companyId || 0}
                                    name='companyId'
                                    onChange={onhandleChange}>
                                    {showNoSpecify &&
                                        <MenuItem dense key={0} value={0} >
                                            {'不限'}
                                        </MenuItem>}
                                    {companyList
                                        .filter(item => (isBranchComp ? item.companyId === userProfile.roles[0].companyId : true))
                                        // .splice(0, 0, {companyId: '', companyAlias})
                                        .map((item) => (
                                            <MenuItem dense key={item.companyId} value={item.companyId} >
                                                {item.companyAlias}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.itemLabelGrid}>
                                <FormControlLabel
                                    label={<Typography variant="h5" style={inventory.validDate ? { fontWeight: 600 } : { color: 'gray' }}>设置有效期</Typography>}
                                    control={
                                        // <Checkbox size={'small'} name="validDate"
                                        //     checked={inventory.validDate}
                                        //     onChange={onhandleChange} />
                                        <Switch size="small" checked={inventory.validDate} onChange={onhandleChange} name="validDate" />
                                    } />
                            </Grid>
                            {inventory.validDate &&
                                <Grid item container className={classes.itemDateGrid} wrap={'nowrap'}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            // className={null}
                                            error={dateError.validStartTime !== ''}
                                            helperText={dateError.validStartTime}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment:
                                                    (
                                                        <IconButton
                                                            style={{ order: 1, display: '' }}
                                                            onClick={() => onhandleDateChange(null, 'validStartTime')}
                                                            disabled={Boolean(!inventory.validStartTime)}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    )
                                            }}
                                            InputAdornmentProps={{
                                                position: "end",
                                                style: { order: 2, marginLeft: 0 }
                                            }}
                                            disablePast
                                            autoOk
                                            variant='inline'
                                            inputVariant='outlined'
                                            minDate={minStartDate}
                                            minDateMessage={<Typography>不能早于当前日期</Typography>}
                                            format={'yyyy/MM/dd'}
                                            name='validStartTime'
                                            label={"生效日期"}
                                            value={inventory.validStartTime || null}
                                            onChange={date => onhandleDateChange(date, 'validStartTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={classes.textFieldInline}
                                            error={dateError.validEndTime !== ''}
                                            helperText={dateError.validEndTime}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment:
                                                    (
                                                        <IconButton
                                                            style={{ order: 1, display: '' }}
                                                            onClick={() => onhandleDateChange(null, 'validEndTime')}
                                                            disabled={Boolean(!inventory.validEndTime)}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    )
                                            }}
                                            InputAdornmentProps={{
                                                position: "end",
                                                style: { order: 2, marginLeft: 0 }
                                            }}
                                            disablePast
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            minDate={minEndDate}
                                            minDateMessage={<Typography>不能早于开始日期</Typography>}
                                            format={'yyyy/MM/dd'}
                                            name='validEndTime'
                                            label={"失效日期"}
                                            value={inventory.validEndTime || null}
                                            onChange={date => onhandleDateChange(date, 'validEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>}

                            <Grid item className={classes.itemGrid}>
                                <Typography display={'block'} style={{ marginLeft: '0.5rem' }}>
                                    {getValidDateTips()}
                                </Typography>
                            </Grid>
                            {/* <Grid item className={classes.itemGrid}>
                                    <TextField
                                        multiline
                                        label={"排查内容"}
                                        variant='outlined'
                                        className={classes.textField}
                                        value={inventory.opentext || ''}
                                        rows={6}
                                        name='opentext'
                                        onChange={event => onhandleChange(event, 'opentext')}
                                    />
                                </Grid> */}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {isCreate ? "创建" : "保存"}</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
InventoryEditPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    inventory: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    leaders: PropTypes.array.isRequired,
    listTypeList: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

InventoryEditPanel.defaultProps = {

}

export default withStyles(InventoryEditPanel);