import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getHazardReport,
    // getHazardListForCsv,
    initHazardReport,
    updSearchData,
    resetSearch
} from '@/redux/modules/inspection/hazardReport';
import HazardReport from "../../../components/InspectionManagement/HazardList/HazardReport";
import { getOptionList, INSPECTION_DICT_TYPES } from '@/utils/constant';
import { csv } from '@/utils/exportfile';
import {
    getMoment as moment,
} from '@/utils/datetime';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { defaultColors } from '@/utils/echartUtils';

import _ from 'lodash';

class HazardReportContainer extends Component {


    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            creatorName: false,
            routeType: '',
            statusList: {
                'active': '待审定', 'evaluated': '待治理', 'treating': '治理中',
                'done': '已治理', 'invalid': '已销项'
            }
        }

    }

    UNSAFE_componentWillMount() {
        const { isBack, routeType } = this.props;
        this.setState({
            routeType: routeType
        })
        this.props.onhandleInit(isBack, routeType);
    }
    // componentDidMount() {
    // }

    handleClearSearch() {
        this.props.onhandleClearSarch();
    }

    // onhandleExportCsv = () => {
    //     this.props.onhandleGetHazardReportForCsv({
    //         ...this.props.search,
    //         offset: 0,
    //         size: 0,
    //         sort: "-update_time"
    //     }, res => {
    //         this.generateCsvData(res);
    //     });
    // }

    findName = (code, type) => {
        let obj = _.find(this.props.inspectionDict, function (o) { return o.code === code && o.type === type })
        return obj ? obj.name : null;
    }

    renderCompany = (cid) => {
        let com = _.find(this.props.companies, function (o) { return o.companyId === cid });
        return com ? com.companyAlias : null;
    }

    renderDepartment = (c) => {
        let dpt = _.find(this.props.departments, function (o) { return o.dptId === c.dptId })
        return dpt ? dpt.companyDptName : null;
    }

    renderStatus = (s) => {
        return this.state.statusList[s] ? this.state.statusList[s] : '待审定'
    }

    // generateCsvData = (hazardReport) => {
    //     const {
    //         inspectionDict,
    //         exportCsv,
    //         onhandleCreateMessage
    //     } = this.props;

    //     if (hazardReport && Object.keys(hazardReport).length > 0 && hazardReport.list.length > 0) {

    //         let columns = ['编号', '产生时间', '检查类型', '来源事件', '检查子分类', '属地单位', '监管部门', '根因', '隐患级别', '危害程度', '安全生产要素', '问题描述', '记录人', '状态'];
    //         let dataList = [];
    //         hazardReport.list.forEach(hazard => {
    //             let inspectTypeStr = '随机检查';
    //             let categoryStr = '-';
    //             let eventName = '-';
    //             let dptName = '-';
    //             let rootCause = this.findName(hazard.rootCause, 'ROOTCAUSE');
    //             let severity = this.findName(hazard.severity, "SEVERITY");
    //             let impactLevel = this.findName(hazard.impactLevel, "IMPACTLEVEL");
    //             let factor = this.findName(hazard.factor, "FACTORTYPE");
    //             if (hazard.recordId) {
    //                 inspectTypeStr = this.findName(hazard.inspectType.substr(0, 5), 'INSTYPE');
    //                 if (_.find(inspectionDict, { 'pcode': hazard.inspectType.substr(0, 5) })) {
    //                     categoryStr = this.findName(hazard.inspectType, 'INSTYPE');
    //                 }
    //                 eventName = this.findName(hazard.inspectType, "INSTYPE") + "-" + hazard.planName;
    //                 dptName = this.renderDepartment(hazard.checklist);
    //                 rootCause = this.findName(hazard.checklist.rootCause, 'ROOTCAUSE');
    //                 severity = this.findName(hazard.checklist.severity, "SEVERITY");
    //             }
    //             let recordTimeStr = getDateByTimestamp(hazard.startDate, true);
    //             let data = [
    //                 hazard.serialNo,
    //                 recordTimeStr,
    //                 inspectTypeStr,
    //                 eventName,
    //                 categoryStr,
    //                 this.renderCompany(hazard.companyId),
    //                 dptName,
    //                 rootCause || '-',
    //                 severity || '-',
    //                 impactLevel || '-',
    //                 factor || '-',
    //                 hazard.opentext,
    //                 hazard.recorder || '',
    //                 this.renderStatus(hazard.status)
    //             ];
    //             dataList.push(data);
    //         });
    //         let fileName = ['隐患台账'].join('_') + '_' + moment()((new Date())).format('YYYYMMDD');
    //         console.log(fileName, dataList)
    //         exportCsv(fileName, columns, dataList);
    //         onhandleCreateMessage('success', '导出隐患台账成功');
    //     }
    // }

    handleSelect = (value, field) => {
        let newSearch = { ...this.props.search };
        switch (field) {
            case 'companyId':
                newSearch.companyId = value.target.value
                break;
            case 'rootCause':
                newSearch.rootCause = value.target.value
                break;
            case 'status':
                newSearch.status = value.target.value
                break;
            case 'view':
                let startDate = moment()().startOf('day').format("YYYY-MM-DD");
                let endDate = moment()().endOf('day').format("YYYY-MM-DD");

                switch (value.target.value) {
                    case 'year':
                        startDate = moment()().startOf('year').format("YYYY-MM-DD");
                        endDate = moment()().endOf('year').format("YYYY-MM-DD");
                        break;
                    case 'month':
                        startDate = moment()().startOf('year').format("YYYY-MM-DD");
                        endDate = moment()().endOf('month').format("YYYY-MM-DD");
                        break;
                    case 'week':
                        startDate = moment()().startOf('month').startOf('week').format("YYYY-MM-DD");
                        endDate = moment()().endOf('week').format("YYYY-MM-DD");
                        break;
                    case 'day':
                        startDate = moment()().startOf('day').format("YYYY-MM-DD");
                        endDate = moment()().startOf('day').format("YYYY-MM-DD");
                        break;
                    default:
                }
                newSearch = {
                    ...this.props.search,
                    intervalDate: {
                        view: value.target.value,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
    }

    handleDateChange(value, field) {
        const { search } = this.props;
        let newSearch = { ...search };

        const date = moment()(value);

        switch (field) {
            case 'startDate':
                newSearch.intervalDate.startDate = date.startOf(search.intervalDate.view).format('YYYY-MM-DD');
                break;
            case 'endDate':
                if (
                    date.isBefore(moment()(newSearch.intervalDate.startDate))
                ) {
                    newSearch.intervalDate.endDate = moment()(newSearch.intervalDate.startDate).endOf(search.intervalDate.view).format('YYYY-MM-DD');
                } else {
                    newSearch.intervalDate.endDate = date.endOf(search.intervalDate.view).format('YYYY-MM-DD');
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    // 隐患级别
    handleSeverityOption = () => {

        // 图配置逻辑
        const {
            hazardReport,
            inspectionDict
        } = this.props;

        const severities = _.filter(inspectionDict, { type: INSPECTION_DICT_TYPES.SEVERITY });

        // 构造series, legend
        const series = [];
        const legendData = [];

        severities.forEach(severity => {
            legendData.push(severity.name)
        })
        legendData.push('待审定');

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            hazardReport.forEach(report => {
                let severity = report.severity;
                if (['active'].includes(report.status)) { severity = null }
                let index = _.findIndex(reportFormatted, {
                    severity: severity,
                    status: report.status,
                    hazardCompanyId: report.hazardCompanyId
                })
                if (index > -1) {
                    reportFormatted[index].total += report.total;
                } else {
                    reportFormatted.push({
                        total: report.total,
                        severity: severity,
                        status: report.status,
                        hazardCompanyId: report.hazardCompanyId
                    });
                }
            })
        }

        series.push({
            label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} 项}\n{d} %',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#999'
                    }
                }
            },
            labelLine: {
                length: 15,
                length2: 10,
                maxSurfaceAngle: 50
            },
            type: 'pie',
            radius: ['0%', '50%'],
            data: legendData.map(str => ({ value: null, name: str }))
        })

        series.forEach(serie => {
            reportFormatted.forEach(item => {
                const itemSeverity = _.find(severities, { code: item.severity });
                const severityName = itemSeverity ? itemSeverity.name : '待审定';
                const dataIndex = _.findIndex(serie.data, o => severityName === o.name);
                if (dataIndex > -1 && item.status !== 'invalid') {
                    serie.data[dataIndex].value += item.total;
                }
            })
        })

        // console.log('============= SEVERITY hazardReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(hazardReport)
        // console.log(reportFormatted)

        let chartTitle = '隐患级别';
        return {
            title: { text: chartTitle, left: 20 },
            series: series,
            grid: {
                containLabel: true,
            },
            color: defaultColors
        }
    }
    // 根因排查比
    handleRootCauseOption = () => {

        // 图配置逻辑
        const {
            hazardReport,
            inspectionDict
        } = this.props;

        // 根因列表
        const rootCauses = _.filter(inspectionDict, { type: INSPECTION_DICT_TYPES.ROOTCAUSE });

        // 构造series, legend
        const series = [];
        const legendData = [];

        rootCauses.forEach(rootCause => {
            legendData.push(rootCause.name)
        })
        legendData.push('待审定');

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            hazardReport.forEach(report => {
                let rootCause = report.rootCause;
                if (['active'].includes(report.status)) { rootCause = null }

                let index = _.findIndex(reportFormatted, {
                    rootCause: rootCause,
                    status: report.status,
                    hazardCompanyId: report.hazardCompanyId
                })
                if (index > -1) {
                    reportFormatted[index].total += report.total;
                } else {
                    reportFormatted.push({
                        total: report.total,
                        rootCause: rootCause,
                        hazardCompanyId: report.hazardCompanyId
                    });
                }
            })
        }

        series.push({
            label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} 项}\n{d} %',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                    time: {
                        fontSize: 10,
                        color: '#999'
                    }
                }
            },
            labelLine: {
                length: 15,
                length2: 10,
                maxSurfaceAngle: 50
            },
            type: 'pie',
            radius: ['0%', '50%'],
            data: legendData.map(str => ({ value: null, name: str }))
        })

        series.forEach(serie => {
            reportFormatted.forEach(item => {
                const itemRootCause = _.find(rootCauses, { code: item.rootCause });
                const rootCauseName = itemRootCause ? itemRootCause.name : '待审定';
                const dataIndex = _.findIndex(serie.data, o => rootCauseName === o.name);
                if (dataIndex > -1 && item.status !== 'invalid') {
                    serie.data[dataIndex].value += item.total;
                }
            })
        })

        let chartTitle = '根因分析';
        return {
            title: { text: chartTitle, left: 20 },
            series: series,
            grid: {
                containLabel: true,
            },
            color: defaultColors
        }
    }

    // 隐患状态
    handleStatusOption = () => {

        // 图配置逻辑
        const {
            hazardReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        const {
            statusList
        } = this.state;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];

        // 子组织(组织标识符seriesId)
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                series.push({
                    seriesId: searchCompany.companyId,
                    name: searchCompany.companyAlias,
                    stack: '叠',
                    type: 'bar',
                    data: [],
                    emphasis: emphasis,
                    barMaxWidth: '80'
                })
                legendData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    series.push({
                        seriesId: comp.companyId,
                        name: comp.companyAlias,
                        stack: '叠',
                        type: 'bar',
                        data: [],
                        emphasis: emphasis,
                        barMaxWidth: '80'
                    })
                    legendData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位检出
            series.push({
                seriesId: userCompany.companyId,
                name: userCompany.companyAlias,
                stack: '叠',
                type: 'bar',
                data: [],
                emphasis: emphasis,
                barMaxWidth: '80'
            })
            legendData.push(userCompany.companyAlias)
        }

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            hazardReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    status: report.status,
                    hazardCompanyId: report.hazardCompanyId
                })
                if (index > -1) {
                    reportFormatted[index].total += report.total;
                } else {
                    reportFormatted.push({
                        total: report.total,
                        status: report.status,
                        hazardCompanyId: report.hazardCompanyId
                    });
                }
            })
        }

        let xAxisData = [...Object.values(statusList)];

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = Object.keys(statusList).findIndex(o => o === item.status);
            totalArray[axisIndex] += item.total;
        })
        // 各分厂数据
        series.forEach(serie => {
            serie.data = [...Array(xAxisData.length)].map(_ => 0);
            reportFormatted.forEach(item => {
                const axisIndex = Object.keys(statusList).findIndex(o => o === item.status);
                if (item.hazardCompanyId === serie.seriesId || serie.seriesId === 0) {
                    if (axisIndex > -1) {
                        serie.data[axisIndex] += item.total;
                    }
                }
            })
        })
        // 总数据作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        return totalArray[param.dataIndex];
                        // console.log(param);
                    },
                }
            }
        })

        // console.log('============= hazardReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(hazardReport)
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)
        // console.log(xAxisData)

        return {
            title: { text: '隐患状态', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '次数',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 历史隐患总量
    handleCountOption = () => {
        // 图配置逻辑
        const {
            search,
            hazardReport,
            companies,
            currentUserInfo,
            // departments,
        } = this.props;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];

        // 子组织(组织标识符seriesId)
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                series.push({
                    seriesId: searchCompany.companyId,
                    name: searchCompany.companyAlias,
                    stack: '叠',
                    type: 'bar',
                    data: [],
                    emphasis: emphasis,
                    barMaxWidth: '80'
                })
                legendData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    series.push({
                        seriesId: comp.companyId,
                        name: comp.companyAlias,
                        stack: '叠',
                        type: 'bar',
                        data: [],
                        emphasis: emphasis,
                        barMaxWidth: '80'
                    })
                    legendData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位检出
            series.push({
                seriesId: userCompany.companyId,
                name: userCompany.companyAlias,
                stack: '叠',
                type: 'bar',
                data: [],
                emphasis: emphasis,
                barMaxWidth: '80'
            })
            legendData.push(userCompany.companyAlias)
        }

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            if (search.intervalDate.view === 'week') {
                hazardReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        hazardCompanyId: report.hazardCompanyId,
                        hazardCompanyName: report.hazardCompanyName,
                        year: report.year,
                        week: report.week,
                        date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].total += report.total;
                    } else {
                        reportFormatted.push({
                            total: report.total,
                            hazardCompanyId: report.hazardCompanyId,
                            hazardCompanyName: report.hazardCompanyName,
                            year: report.year,
                            week: report.week,
                            date: moment()().year(report.year).week(report.week).startOf('week').format('YYYY-MM-DD')
                        });
                    }
                })

            }
            if (search.intervalDate.view === 'month') {
                hazardReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        hazardCompanyId: report.hazardCompanyId,
                        hazardCompanyName: report.hazardCompanyName,
                        year: report.year,
                        month: report.month
                    })
                    if (index > -1) {
                        reportFormatted[index].total += report.total;
                    } else {
                        reportFormatted.push({
                            total: report.total,
                            hazardCompanyId: report.hazardCompanyId,
                            hazardCompanyName: report.hazardCompanyName,
                            year: report.year,
                            month: report.month
                        });
                    }
                })
            }
            if (search.intervalDate.view === 'year') {
                hazardReport.forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        hazardCompanyId: report.hazardCompanyId,
                        hazardCompanyName: report.hazardCompanyName,
                        year: report.year,
                        // rootCause: report.rootCause
                    })
                    if (index > -1) {
                        reportFormatted[index].total += report.total;
                    } else {
                        reportFormatted.push({
                            total: report.total,
                            hazardCompanyId: report.hazardCompanyId,
                            hazardCompanyName: report.hazardCompanyName,
                            year: report.year
                        });
                    }
                })
            }
        }

        let xAxisData = [];
        // reportFormatted.forEach(item => {
        //     let xAxisCoord = '';
        //     if (search.intervalDate.view === 'day') { xAxisCoord = [item.year, item.month, item.day].join('-') }
        //     if (search.intervalDate.view === 'week') { xAxisCoord = item.date }
        //     // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
        //     if (search.intervalDate.view === 'month') { xAxisCoord = [item.year, item.month].join('-') }
        //     if (search.intervalDate.view === 'year') { xAxisCoord = item.year }
        //     xAxisData.push(xAxisCoord);
        // })
        const indexMoment = moment()(search.intervalDate.startDate);
        const endMoment = moment()(search.intervalDate.endDate);
        while (indexMoment.isSameOrBefore(endMoment)) {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'days');
            }
            if (search.intervalDate.view === 'week') {
                xAxisCoord = indexMoment.format('YYYY-MM-DD');
                indexMoment.add(1, 'weeks');
            }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') {
                xAxisCoord = indexMoment.format('YYYY-MM');
                indexMoment.add(1, 'months');
            }
            if (search.intervalDate.view === 'year') {
                xAxisCoord = indexMoment.format('YYYY');
                indexMoment.add(1, 'years');
            }
            xAxisData.push(xAxisCoord);
        }
        xAxisData = _.uniq(xAxisData);

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            let xAxisCoord = '';
            if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
            if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
            // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
            if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
            if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }
            const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
            totalArray[axisIndex] += item.total;
        })
        // 各分厂数据
        series.forEach(serie => {
            serie.data = [...Array(xAxisData.length)].map(_ => 0);
            reportFormatted.forEach(item => {
                let xAxisCoord = '';
                if (search.intervalDate.view === 'day') { xAxisCoord = moment()([item.year, item.month, item.day].join('-')).format('YYYY-MM-DD') }
                if (search.intervalDate.view === 'week') { xAxisCoord = moment()(item.date).format('YYYY-MM-DD') }
                // if (search.intervalDate.view === 'week') { xAxisCoord = item.year + '第' + item.week + '周' }
                if (search.intervalDate.view === 'month') { xAxisCoord = moment()([item.year, item.month].join('-')).format('YYYY-MM') }
                if (search.intervalDate.view === 'year') { xAxisCoord = moment()(item.year).format('YYYY') }

                const axisIndex = xAxisData.findIndex(o => o === xAxisCoord);
                if (item.hazardCompanyId === serie.seriesId || serie.seriesId === 0) {
                    if (axisIndex > -1) {
                        serie.data[axisIndex] += item.total;
                    }
                }
            })
        })
        // 总数据作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        return totalArray[param.dataIndex];
                        // console.log(param);
                    },
                }
            }
        })

        // console.log('============= hazardReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(hazardReport)
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)
        // console.log(xAxisData)

        return {
            title: { text: '隐患历史', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '次数',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    // 机关/分厂排查比
    handleRatioOption = (currentComp) => {
        // 图配置逻辑
        const {
            hazardReport,
            companies,
        } = this.props;
        const pCompany = _.find(companies, o => !o.pid);

        const isPCompany = currentComp.companyId === pCompany.companyId;

        // 构造series, legend
        const series = [];
        const legendData = ['机关', currentComp.companyAlias];
        if (isPCompany) { legendData.shift() }

        series.push({
            seriesId: currentComp.companyId,
            label: {
                show: true,
                position: 'center'
            },
            type: 'pie',
            radius: ['35%', '50%'],
            data: legendData.map(str => ({ value: 0, name: str })),
            // emphasis: emphasis,
        })

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            hazardReport.filter(o => o.hazardCompanyId === currentComp.companyId)
                .forEach(report => {
                    let index = _.findIndex(reportFormatted, {
                        recorderCompanyId: report.recorderCompanyId,
                        recorderCompanyName: report.recorderCompanyName,
                        hazardCompanyId: report.hazardCompanyId
                    })
                    if (index > -1) {
                        reportFormatted[index].total += report.total;
                    } else {
                        reportFormatted.push({
                            total: report.total,
                            recorderCompanyId: report.recorderCompanyId,
                            recorderCompanyName: report.recorderCompanyName,
                            hazardCompanyId: report.hazardCompanyId
                        });
                    }
                })
        }

        let ratioValue = '';
        series.forEach(serie => {
            reportFormatted.forEach(item => {
                if (!isPCompany) {
                    if (item.recorderCompanyId === pCompany.companyId) {
                        serie.data[0].value += item.total;
                    }
                    if (item.recorderCompanyId === currentComp.companyId) {
                        serie.data[1].value += item.total;
                    }
                } else {
                    serie.data[0].value += item.total;
                }
            })
            // 计算比率
            if (!isPCompany) {
                if (serie.data[0].value) {
                    ratioValue = (parseFloat(serie.data[1].value) / parseFloat(serie.data[0].value)).toFixed(1) + ' : 1'
                } else if (!serie.data[0].value && serie.data[1].value) {
                    ratioValue = '机关未参与'
                } else {
                    ratioValue = '无隐患记录'
                }
            } else {
                if (serie.data[0].value) {
                    ratioValue = (parseFloat(serie.data[0].value)) + '项'
                } else {
                    ratioValue = '无隐患记录'
                }
            }
        })

        let chartTitle = currentComp.companyAlias + '/机关排查比';
        if (isPCompany) {
            chartTitle = '机关自查';
        }
        return {
            title: { text: chartTitle, left: 20 },
            series: series,
            legend: {
                bottom: '5%',
                data: legendData
            },
            label: {
                formatter: ratioValue,
                fontSize: 16,
                color: "#000"
            },
            tooltip: {
                trigger: 'item'
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            color: defaultColors
        }
    }

    // 隐患治理完成率
    handleTreatmentOption = () => {

        // 图配置逻辑
        const {
            hazardReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;

        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: { show: true }
        }

        // 构造series, legend
        const series = [];
        const legendData = [];
        // 当前单位检出
        series.push({
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '80'
        })

        const reportFormatted = [];
        if (hazardReport && hazardReport.length) {
            hazardReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    status: report.status,
                    hazardCompanyId: report.hazardCompanyId,
                    hazardCompanyName: report.hazardCompanyName
                })
                if (index > -1) {
                    reportFormatted[index].total += report.total;
                } else {
                    reportFormatted.push({
                        total: report.total,
                        status: report.status,
                        hazardCompanyId: report.hazardCompanyId,
                        hazardCompanyName: report.hazardCompanyName
                    });
                }
            })
        }

        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }

        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.hazardCompanyName);
            if (axisIndex > -1 && item.status !== 'invalid') {
                totalArray[axisIndex] += item.total;
            }
        })
        // 已治理数
        series[0].data = [...Array(xAxisData.length)].map(_ => 0);
        const doneList = [...Array(xAxisData.length)].map(_ => 0);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.hazardCompanyName);
            if (axisIndex > -1 && ['done'].includes(item.status)) {
                doneList[axisIndex] += item.total;
            }
        })
        doneList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = (item / total).toFixed(2)
            }
        })
        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalArray[param.dataIndex];
                        if (total <= 0) {
                            return '无隐患'
                        } else {
                            return (param.data * 100).toFixed(1) + '%';
                        }
                    },
                }
            }
        })

        return {
            title: { text: '隐患治理完成率', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '百分比',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    //  计划完成率
    handlePlanCompOption = () => {
        // 图配置逻辑
        const {
            planReport,
            companies,
            currentUserInfo,
            search,
            // inspectionDict
        } = this.props;
        const emphasis = {
            scale: true,
            focus: 'series',
            blurScope: 'series',
            lineStyle: { width: 'bolder' },
            label: {
                show: true,
                // // 条块上label格式化
                formatter: function (param) {
                    const data = param.data;
                    if (data <= 0) {
                        return ''
                    } else {
                        return (data * 100).toFixed(1) + '%';
                    }
                },
            }
        }
        // 构造series, legend
        const series = [];
        const legendData = ['按时完成', '补录'];
        // 当前单位检出
        series.push({
            name: '按时完成',
            stack: 'status',
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '80'
        })
        series.push({
            name: '补录',
            stack: 'status',
            type: 'bar',
            data: [],
            emphasis: emphasis,
            showBackground: true,
            barMaxWidth: '80'
        })
        const reportFormatted = [];
        if (planReport && planReport.length) {
            planReport.forEach(report => {
                let index = _.findIndex(reportFormatted, {
                    status: report.status,
                    creatorCompanyId: report.creatorCompanyId,
                    creatorCompanyName: report.creatorCompanyName
                })
                if (index > -1) {
                    reportFormatted[index].total += report.total;
                } else {
                    reportFormatted.push({
                        total: report.total,
                        status: report.status,
                        creatorCompanyId: report.creatorCompanyId,
                        creatorCompanyName: report.creatorCompanyName
                    });
                }
            })
        }
        let xAxisData = [];
        const pCompany = _.find(companies, o => !o.pid);
        const userCompany = _.find(companies, { companyId: currentUserInfo.companyId });
        if (pCompany.companyId === currentUserInfo.companyId) {
            if (search.companyId) {
                const searchCompany = _.find(companies, { companyId: Number.parseInt(search.companyId) });
                xAxisData.push(searchCompany.companyAlias)
            } else {
                companies.forEach(comp => {
                    xAxisData.push(comp.companyAlias)
                })
            }
        } else {
            // 当前单位
            xAxisData.push(userCompany.companyAlias)
        }
        // 总数
        const totalArray = [...Array(xAxisData.length)].map(_ => null);
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.creatorCompanyName);
            if (axisIndex > -1) {
                totalArray[axisIndex] += item.total;
            }
        })
        // 计划已完成数
        series[0].data = [...Array(xAxisData.length)].map(_ => null); // done
        series[1].data = [...Array(xAxisData.length)].map(_ => null); // expired done
        const doneList = [...Array(xAxisData.length)].map(_ => null);
        const expiredDoneList = [...Array(xAxisData.length)].map(_ => null);
        const totalDoneList = [...Array(xAxisData.length)].map(_ => 0); // 总完成数
        reportFormatted.forEach(item => {
            const axisIndex = xAxisData.findIndex(o => o === item.creatorCompanyName);
            if (axisIndex > -1 && ['done'].includes(item.status)) {
                doneList[axisIndex] += item.total;
            }
            if (axisIndex > -1 && ['expired'].includes(item.status)) {
                expiredDoneList[axisIndex] += item.total;
            }
            if (axisIndex > -1 && ['expired', 'done'].includes(item.status)) {
                totalDoneList[axisIndex] += item.total;
            }
        })
        doneList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[0].data[index] = 0;
            } else {
                series[0].data[index] = (item / total).toFixed(2)
            }
        })
        expiredDoneList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                series[1].data[index] = 0;
            } else {
                series[1].data[index] = (item / total).toFixed(2)
            }
        })

        // 总完成率
        const totalRateArray = [...Array(xAxisData.length)].map(_ => 0);
        totalDoneList.forEach((item, index) => {
            const total = totalArray[index];
            if (total <= 0) {
                totalRateArray[index] = 0;
            } else {
                totalRateArray[index] = (item / total).toFixed(2)
            }
        })

        // 百分比作为label，显示在最顶部的serie上
        series.forEach((serie, index) => {
            if (index === series.length - 1) {
                serie.label = {
                    position: 'top',
                    color: '#333',
                    show: true,
                    formatter: function (param) {
                        const total = totalRateArray[param.dataIndex];
                        if (total <= 0) {
                            return '无排查计划'
                        } else {
                            return (total * 100).toFixed(1) + '%';
                        }
                    },
                }
            }
        })
        // console.log('handlePlanCompOption============= planReport, reportFormatted, series, legend, xAxisData ==============')
        // console.log(planReport)
        // console.log(reportFormatted)
        // console.log(series)
        // console.log(legendData)
        // console.log(xAxisData)
        return {
            title: { text: '计划完成率', left: 20 },
            series: series,
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '5%',
                data: legendData,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                left: '5%',
                bottom: '5%'
            },
            xAxis: {
                type: 'category',
                name: '日期',
                data: xAxisData
            },
            yAxis: {
                type: 'value',
                name: '百分比',
                minInterval: 1
            },
            color: defaultColors
        }
    }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch, this.state.tabValue);
                break;
            default:
            // do nothing
        }
    };

    render() {
        return (
            <HazardReport
                routeType={this.state.routeType}
                hazardReport={this.props.hazardReport}
                userProfile={this.props.userProfile}
                currentUserInfo={this.props.currentUserInfo}
                companies={this.props.companies}
                departments={this.props.departments}
                departmentList={this.props.departmentList}
                dictionary={this.props.inspectionDict}

                // rootCauseList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.ROOTCAUSE)}
                severityList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.SEVERITY)}
                // impactLevelList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.IMPACTLEVEL)}
                // factorList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.FACTORTYPE)}

                search={this.props.search}
                // exportCsv={this.onhandleExportCsv.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                // onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandleStatusOption={this.handleStatusOption}
                onhandleCountOption={this.handleCountOption}
                onhandleRootCauseOption={this.handleRootCauseOption}
                onhandleSeverityOption={this.handleSeverityOption}
                onhandleRatioOption={this.handleRatioOption.bind(this)}
                onhandleTreatmentOption={this.handleTreatmentOption.bind(this)}
                onhandlePlanCompOption={this.handlePlanCompOption.bind(this)}

                creatorName={this.state.creatorName}

                tabValue={this.state.tabValue}
                onhandleChangeTab={this.handleChangeTab.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companies: getActualCompany(state.constant.companies),
        departments: state.constant.departments,
        inspectionDict: state.constant.inspectionDictionaries,
        departmentList: getManageDpt(state.constant.departments),
        hazardReport: state.hazardReport.hazardReport,
        planReport: state.hazardReport.planReport,
        search: state.hazardReport.search,
        selectedUsers: state.userSelect.selectedUsers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetHazardReport: (query) => {
            dispatch(getHazardReport(query))
        },
        onhandleInit: () => {
            dispatch(initHazardReport())
        },
        // onhandleGetHazardReportForCsv: (query, callback) => {
        //     dispatch(getHazardListForCsv(query, callback))
        // },
        exportCsv: (fileName, columns, data) => {
            dispatch(csv(fileName, columns, data))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        },
        onhandleClearSarch: () => {
            dispatch(resetSearch());
        },
        onhandleUpdateEditSearch: (search, tabValue) => {
            dispatch(updSearchData(search, tabValue));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhandeModalclose: () => {
            dispatch(cleanmodal());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HazardReportContainer)