import React, { Component } from 'react';
import { connect } from 'react-redux';
import BuList from "../../../components/SystemManagement/BuManagement/BuList";
import PropTypes from "prop-types";
import { initCreate, getBus, initUpdate, deleteBu } from '../../../redux/modules/bu';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';

class BuListContainer extends Component {
    static propTypes = {
        bus: PropTypes.object,
        constants: PropTypes.object,
        userProfile: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleGetBus({
            offset: 0,
            size: 15
        });
    }

    deleteInfo(event, id, name) {
        let doDelete = () => {
            this.props.onhandleDelete(id, name);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除业务分类[' + name + ']吗?', doDelete, null, false);
    }

    handleCreate(event) {
        this.props.onhandleCreate()
    }

    detailedInfo(event, id) {
        this.props.onhandleOpen(id);
    }

    render() {
        return (
            <BuList
                bus={this.props.bus}
                onDeleteInfo={this.deleteInfo.bind(this)}
                onDetailedInfo={this.detailedInfo.bind(this)}
                getBus={this.props.onhandleGetBus.bind(this)}
                onhandleCreate={this.handleCreate.bind(this)}
                constants={this.props.constants}
                userProfile={this.props.userProfile}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bus: state.bu.bus,
        constants: state.constant.constants,
        userProfile: state.auth.userProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetBus: (query) => {
            dispatch(getBus(query))
        },
        onhandleDelete: (uid, uName) => {
            dispatch(deleteBu(uid, uName));
        },
        onhandleOpen: (uid) => {
            dispatch(initUpdate(uid));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleCreate: () => {
            dispatch(initCreate());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuListContainer)