import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Divider,
    Typography,
    Button,
    TextField,
    // InputAdornment,
    Grid
} from "@material-ui/core";
// import {Commute} from '@material-ui/icons';
// import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
// import zhCN from 'date-fns/locale/zh-CN';
// import DateFnsUtils from '@date-io/date-fns';
import { getMoment as moment } from '@/utils/datetime';
import { Prompt } from 'react-router-dom';

const SupplierEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        // onhandleSelectChange,
        // onhandleDateChange,
        onhandleBack,
        // onhandleCheck,
        // currentUserInfo,
        // onhandleAddVehicle,
        currentSupplier,
        // companyList,
        // insTypeList,
        editable,
        // supplierError,
        supplierChange,
        onhandleListMapping,
        // inspectionDptMapping,
    } = props;

    // console.log('currentSupplier  supplierError')
    // console.log("当前supplier")
    // console.log(currentSupplier)
    // console.log(supplierError)

    // const insTypeOptions = () => (
    //     <React.Fragment>
    //         {insTypeList ?
    //             insTypeList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    return (
        <div>
            <Card className={classes.card}>

                <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                    <Grid item xs={6}>
                        <Typography variant="h3">
                            {currentSupplier.supplierId ? "承包商详情" : "新建承包商"}
                        </Typography>
                    </Grid>
                    {/* {currentSupplier.supplierId ? (
                        <Grid item margin={0}>
                            <Typography variant="h5" style={{ display: 'inline-block' }}>
                                {'编号: ' + currentSupplier.serialNo}</Typography>
                        </Grid>) : null
                    } */}
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                    <Grid container item xs={12} alignItems={'center'}>
                        <Typography variant="h4" className={classes.subTitle}>
                            {'承包商信息'}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item className={classes.gridItem} sm={3}>
                    <Typography className={classes.label}>
                        <span className={classes.required}> *</span>
                        {'承包商名称'}
                    </Typography>
                    <Grid item className={classes.formContainer}>
                        <TextField
                            className={classes.textField}
                            disabled={!editable}
                            // error={supplierError.insCompany !== ''}
                            // helperText={supplierError.insCompany}
                            variant="outlined"
                            size='small'
                            value={currentSupplier.supplierName || ''}
                            name='supplierName'
                            onChange={event => onhandleChange(event)}
                        />
                    </Grid>
                </Grid>
                <Grid container item className={classes.gridItem} sm={3}>
                    <Typography className={classes.label}>
                        {'承包商编号'}
                    </Typography>
                    <Grid item className={classes.formContainer}>
                        <TextField
                            className={classes.textField}
                            disabled={!editable}
                            // error={supplierError.insCompany !== ''}
                            // helperText={supplierError.insCompany}
                            variant="outlined"
                            size='small'
                            value={currentSupplier.supplierNo || ''}
                            name='supplierNo'
                            onChange={event => onhandleChange(event)}
                        />
                    </Grid>
                </Grid>


                <Divider className={classes.divider} />


                {/*<Grid container item className={classes.gridHolder} sm={6}></Grid>*/}

                {/*<Divider className={classes.divider} />*/}

                {Boolean(currentSupplier.supplierId) && <>
                    <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Typography variant="h4" className={classes.subTitle}>
                                {'外部信息'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                创建组号
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {onhandleListMapping(currentSupplier.createOrgid, 'createOrgid') || '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                外部id
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.extId ? currentSupplier.extId : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                版本号
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.revisor ? currentSupplier.revisor : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                状态
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.status && currentSupplier.status === 'active' ? '有效' : currentSupplier.status && currentSupplier.status === 'inactive' ? '无效' : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                组号
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.supplierGroupNo ? currentSupplier.supplierGroupNo : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>

                            <Typography className={classes.infoLabel}>
                                修改时间
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.updateTime ? moment()(currentSupplier.updateTime).format('yyyy/MM/DD HH:mm:ss') : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                用户组编号
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.useOrgid ? currentSupplier.useOrgid : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.infoLabel}>
                                用户编号
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentSupplier.useOrgno ? currentSupplier.useOrgno : '-'}
                            </Typography>
                        </Grid>

                    </Grid>
                    <Divider className={classes.divider} />
                    {currentSupplier.supplierId ? (
                        <>
                            <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                                <Grid container item className={classes.gridItem} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        创建人
                                    </Typography>
                                    <Typography className={classes.infoContent}>

                                        {currentSupplier.creatorLastName && currentSupplier.creatorFirstName ?
                                            currentSupplier.creatorLastName + currentSupplier.creatorFirstName
                                            : currentSupplier.creatorLastName ?
                                                currentSupplier.creatorLastName :
                                                currentSupplier.creatorFirstName ?
                                                    currentSupplier.creatorFirstName :
                                                    '管理员'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.gridItem} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        创建时间
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {moment()(currentSupplier.createTime).format('yyyy/MM/DD HH:mm:ss')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>) : null
                    }
                    {/*<Divider className={classes.divider}/>*/}
                    {currentSupplier.supplierId && currentSupplier.updateTime ? (
                        <>
                            <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                                <Grid container item className={classes.gridItem} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        更新人
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {currentSupplier.creatorLastName && currentSupplier.creatorFirstName ?
                                            currentSupplier.creatorLastName + currentSupplier.creatorFirstName
                                            : currentSupplier.creatorLastName ?
                                                currentSupplier.creatorLastName :
                                                currentSupplier.creatorFirstName ?
                                                    currentSupplier.creatorFirstName :
                                                    '管理员'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.gridItem} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        更新时间
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {moment()(currentSupplier.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>) : null
                    }
                    <Divider className={classes.divider} />
                </>}

                <Grid container spacing={0} className={classes.bottomButtonContainer}>
                    {editable ? (
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleSave(event)}
                            disabled={!supplierChange}>
                            {'保存'}</Button>
                    ) : null
                    }
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}>
                        {'返回'}
                    </Button>
                </Grid>

            </Card>
            <Prompt message="数据未保存,确定要离开？" when={supplierChange} />
        </div>
    );
};

SupplierEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    currentSupplier: PropTypes.object.isRequired,
    supplierError: PropTypes.object.isRequired,
    supplierChange: PropTypes.bool.isRequired,
    companyList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired
};

export default withStyles(SupplierEdit);
