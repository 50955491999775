import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialog: {
        width: '100%',
        padding: 20
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    },
    flexgrow: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    positive: {
        color: theme.palette.success.dark
    },
    negative: {
        color: theme.palette.error.dark
    },
});


export default withStyles(styles);