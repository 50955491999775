import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';

const initialState = {
    docData: [],
    isloading: false,

}

// Actions
export const selectConnectTenants = createAction(
    '@@xcloud/installationDoc/select-connecttenants'
);
export const initSelectdConnectTenants = createAction(
    '@@xcloud/installationDoc/select-connecttenants'
);


//---------Start-------------------
export const startLoading = createAction('@@xcloud/installationDoc/startLoading');

export const stopLoading = createAction('@@xcloud/installationDoc/stopLoading');

export const startProcessing = createAction('@@xcloud/installationDoc/startProcessing');

export const stopProcessing = createAction('@@xcloud/installationDoc/stopProcessing');

export const initInstallationDocs = createAction(
    '@@xcloud/installationdocs/init-docs',
    () => (dispatch, getState, httpClient) => {
        let instId = getState().installation.editInstallation.instId.value;
        dispatch(startLoading());
        return httpClient
            .get(`/tenants/1/installations/${instId}/docs?offset=0&size=0&sort=-update_time`)
            .then((res) => {
                if (res) {
                    return res.data;
                }
                return []
            })
            .catch(err => {
                let msg = `装置文档信息获取失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const createDoc = createAction(
    '@@xcloud/installationdocs/create-doc',
    (doc) => (dispatch, getState, httpClient) => {
        doc.instId = getState().installation.editInstallation.instId.value;
        doc.uploader = 1;
        doc.tenantId = 1;
        dispatch(startLoading());
        return httpClient
            .post(`/tenants/1/installations/docs`, doc)
            .then((res) => {
                dispatch(initInstallationDocs());
                dispatch(stopProcessing());
            })
            .catch(err => {
                let msg = `装置文档信息创建失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const deleteDoc = createAction(
    '@@xcloud/installationdocs/delete-doc',
    (tenantId, docId) => (dispatch, getState, httpClient) => {
        dispatch(startLoading());
        return httpClient
            .delete(`/tenants/${tenantId}/installations/docs/${docId}`)
            .then((res) => {
                dispatch(initInstallationDocs());
                dispatch(stopProcessing());
            })
            .catch(err => {
                let msg = `装置文档删除失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);


export const installaiondocReducer = handleActions(
    {
        [initInstallationDocs]: (state, { payload: docData }) => {
            return {
                ...state,
                docData: docData,
                isloading: false
            }
        },
        [startLoading]: (state) => {
            return {
                ...state,
                isloading: true
            };
        },
    },
    initialState
)

export default installaiondocReducer
