import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    dialog: {
        marginLeft: theme.spacing(30),
        marginRight: theme.spacing(30),
    },

    dialogTitle: {

    },
    dialogContent: {

    },
    itemText: {
        marginTop: theme.spacing(2),
        marginBotton: theme.spacing(1),
    },
    itemGrid: {
        padding: theme.spacing(2),
    },
    flexgrow: {
        flexGrow: 1
    },
});


export default withStyles(styles);