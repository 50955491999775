import React, { Component } from 'react';
import { connect } from 'react-redux';
import SupplierEdit from '@/components/DeviceManagement/Suppliers/SupplierEdit';
import {
    initEdit,
    createSupplier,
    updateSupplier,
    setCurrentSupplier,
    backToSupplierList,
    setSupplierError,
    setSupplierChange
} from "@/redux/modules/supplier";
import _ from 'lodash';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { getMoment as moment } from '@/utils/datetime';
import { createmodal } from '@/redux/modules/modal';
import { createMessage } from '@/redux/modules/message';
import PropTypes from "prop-types";

class SupplierEditContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    UNSAFE_componentWillMount() {
        let supplierId = this.props.match.params.supplierId;
        // let mode = this.props.match.params.mode;
        console.log(supplierId)
        this.props.onhandleInit(Number(supplierId));
    }

    static propTypes = {
        currentSupplier: PropTypes.object,
        supplierError: PropTypes.object,
        supplierChange: PropTypes.bool,
        // insTypeList: PropTypes.array,
    };

    handleSave = async (event) => {
        // await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.supplierError), function (o) {
            return o !== '';
        }) < 0) {
            // console.log(currentSupplier.tenantId)
            // let tenantId = getState().auth.currentUserInfo.tenantId;

            // console.log(currentSupplier.tenantId)

            const { currentSupplier } = this.props;
            const newtSupplier = {
                ...currentSupplier,


                supplierName: currentSupplier.supplierName ? currentSupplier.supplierName : undefined,
                supplierNo: currentSupplier.supplierNo ? currentSupplier.supplierNo : undefined,
                //日后更改
                useOrgid: 1
                // tenantId: 1


            };
            this.props.onhandleSupplierChange(false);
            if (newtSupplier.supplierId) {
                this.props.onhandleUpdateSupplier(newtSupplier);
            } else {
                this.props.onhandleCreateSupplier(newtSupplier);
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleSupplierChange(true);
        let newSupplier = {
            ...this.props.currentSupplier
        };

        const fieldName = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);

        newSupplier[fieldName] = fieldValue;

        this.props.onhandleUpdateEditSupplier(newSupplier);
    };

    handleSelect = (value, field) => {
        this.props.onhandleSupplierChange(true);
        let newSelect = { ...this.props.currentSupplier };
        switch (field) {
            case 'insType':
                newSelect.insType = value.target.value
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSupplier(newSelect);
        // 等currentSupplier状态更新后再check select组件
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleDateChange(value, field) {
        this.props.onhandleSupplierChange(true);
        let newSelect = { ...this.props.currentSupplier };

        const date = moment()(value);
        switch (field) {
            case 'startDate':
                newSelect.startDate = date.startOf('day').format("YYYY-MM-DD")
                newSelect.endDate = date.endOf('day').add('12', 'months').format("YYYY-MM-DD")
                break;
            case 'endDate':
                if (
                    date.isBefore(moment()(newSelect.startDate))
                ) {
                    newSelect.endDate = moment()(newSelect.startDate).endOf('day').add('12', 'months').format("YYYY-MM-DD")
                } else {
                    newSelect.endDate = date.endOf('day').format("YYYY-MM-DD")
                }
                break;
            default:
            // do nothing
        }
        this.props.onhandleUpdateEditSupplier(newSelect);
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field) {
            if (_.trim(this.props.currentSupplier.insType) === '') {
                err['insType'] = '费用类型不能为空';
            } else {
                err['insType'] = '';
            }
        }

        // if (!field || field === 'insCompany') {
        //     if (_.trim(this.props.currentSupplier.insCompany) === '') {
        //         err['insCompany'] = '保险公司不能为空';
        //     } else {
        //         err['insCompany'] = '';
        //     }
        // }

        if (!field || field === 'startDate') {
            if (_.trim(this.props.currentSupplier.startDate) === '') {
                err['startDate'] = '开始日期不能为空';
            } else {
                err['startDate'] = '';
            }
        }

        if (!field || field === 'endDate') {
            if (_.trim(this.props.currentSupplier.endDate) === '') {
                err['endDate'] = '结束日期不能为空';
            } else {
                err['endDate'] = '';
            }
        }

        if (!field || field === 'feeSum') {
            if (_.trim(this.props.currentSupplier.feeSum) === '') {
                err['feeSum'] = '总费用不能为空';
            } else if (_.trim(this.props.currentSupplier.feeSum) !== '' && (_.isNaN(_.toNumber(this.props.currentSupplier.feeSum)) || _.toNumber(this.props.currentSupplier.feeSum) <= 0)) {
                err['feeSum'] = '请输入正确的数值';
            } else {
                err['feeSum'] = '';
            }
        }

        let newError = {
            ...this.props.supplierError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            currentSupplier,
            // companies,
            // roleList,
            // deviceDict,
        } = this.props;
        switch (field) {
            case 'createOrgid':
                value = _.find(currentSupplier, { 'createOrgid': fieldId }) ? _.find(currentSupplier, { 'createOrgid': fieldId }).createOrgid : '';
                break;
            case 'role':
                value = _.find(currentSupplier, { 'code': fieldId }) ? _.find(currentSupplier, { 'code': fieldId }).name : '-';
                break;
            case 'deviceDict':
                value = _.find(currentSupplier, { 'code': fieldId }) ? _.find(currentSupplier, { 'code': fieldId }).name : '-';
                break;
            case 'companyId':
                value = _.find(currentSupplier, { 'companyId': fieldId }) ? _.find(currentSupplier, { 'companyId': fieldId }).companyName : '';
                break;
            case 'createTime':
                value = _.find(currentSupplier, { 'createTime': fieldId }) ? _.find(currentSupplier, { 'createTime': fieldId }).createOrgid : '';
                break;
            case 'creator':
                value = _.find(currentSupplier, { 'creator': fieldId }) ? _.find(currentSupplier, { 'creator': fieldId }).creator : '';
                break;
            case 'extId':
                value = _.find(currentSupplier, { 'extId': fieldId }) ? _.find(currentSupplier, { 'extId': fieldId }).extId : '';
                break;
            case 'revisor':
                value = _.find(currentSupplier, { 'revisor': fieldId }) ? _.find(currentSupplier, { 'revisor': fieldId }).revisor : '';
                break;
            case 'status':
                value = _.find(currentSupplier, { 'status': fieldId }) ? _.find(currentSupplier, { 'status': fieldId }).status : '';
                break;
            case 'supplierGroup':
                value = _.find(currentSupplier, { 'supplierGroup': fieldId }) ? _.find(currentSupplier, { 'supplierGroup': fieldId }).supplierGroup : '';
                break;
            case 'useOrgid':
                value = _.find(currentSupplier, { 'useOrgid': fieldId }) ? _.find(currentSupplier, { 'useOrgid': fieldId }).useOrgid : '';
                break;
            case 'useOrgno':
                value = _.find(currentSupplier, { 'useOrgno': fieldId }) ? _.find(currentSupplier, { 'useOrgno': fieldId }).createOrgid : '';
                break;
            case 'updateTime':
                value = _.find(currentSupplier, { 'updateTime': fieldId }) ? _.find(currentSupplier, { 'updateTime': fieldId }).updateTime : '';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleAddVehicle = () => {
        const { companies, currentUserInfo } = this.props;
        const pcompany = _.find(companies, o => !o.pid);
        let companyId = null;
        if (currentUserInfo.companyId !== pcompany.companyId) {
            companyId = currentUserInfo.companyId
        }
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            companyId: companyId,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleCreateErrorMessage(`只能选择一个车辆`)
                        return reject('callbackFunc Error')
                    } else if (this.props.selectedDevices[0].cat && !(this.props.selectedDevices[0].cat.includes('D1706') || this.props.selectedDevices[0].cat === 'D170507')) {
                        this.props.onhandleCreateErrorMessage(`只能选择车辆设备`)
                        return reject('callbackFunc Error')
                    } else {
                        //添加车辆
                        this.setVehicleInfo(this.props.selectedDevices[0])
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleDialog(param);
    }

    setVehicleInfo = (device) => {
        if (!device) return;

        const {
            currentSupplier,
            onhandleUpdateEditSupplier
        } = this.props;

        onhandleUpdateEditSupplier({
            ...currentSupplier,
            deviceId: device.deviceId,
            device: {
                deviceName: device.deviceName,
                tagNo: device.tagNo,
                companyId: device.companyId
            }
        })
    }

    handleBack = (event) => {
        this.props.onhandleBackToSupplierList();
    };

    render() {
        return (
            <SupplierEdit
                editable={this.props.editable}
                currentUserInfo={this.props.currentUserInfo}
                onhandleSave={this.handleSave.bind(this)}
                currentSupplier={this.props.currentSupplier}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleAddVehicle={this.handleAddVehicle.bind(this)}
                supplierError={this.props.supplierError}
                supplierChange={this.props.supplierChange}
                companyList={this.props.companyList}
                departmentList={this.props.departments}
                // insTypeList={this.props.insTypeList}
                onhandleListMapping={this.handleListMapping.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        bUnits: state.constant.businessunits,
        deviceDict: state.constant.deviceDictionaries,
        editable: state.supplier.editable,
        currentSupplier: state.supplier.currentSupplier,
        supplierError: state.supplier.supplierError,
        supplierChange: state.supplier.supplierChange,
        companyList: state.constant.companies,
        // insTypeList: state.deviceSupplier.insTypeList,

        selectedDevices: state.deviceSelect.selectedDevices,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInit: (supplierId) => {
            dispatch(initEdit(supplierId))
        },
        onhandleCreateSupplier: (entity) => {
            dispatch(createSupplier(entity))
        },
        onhandleUpdateSupplier: (entity) => {
            dispatch(updateSupplier(entity))
        },
        onhandleUpdateEditSupplier: (entity) => {
            dispatch(setCurrentSupplier(entity))
        },
        onhandleBackToSupplierList: () => {
            dispatch(backToSupplierList())
        },
        onhandleSetError: (err) => {
            dispatch(setSupplierError(err))
        },
        onhandleSupplierChange: (isChanged) => {
            dispatch(setSupplierChange(isChanged))
        },
        onhandleCreateErrMessage: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('deviceSelect', param, 'medium'))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierEditContainer)
