import { createAction, handleActions } from 'redux-actions';
import { sortPlans, PLAN_MONTH } from '@/utils/amoebaUtils';
import { createhttpMessage, createMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    plans: [], //阿米巴预算列表
    dailyPlans: [],
    monthPlans: [],
    budgets: [],
};

export const setPlans = createAction('@@xcloud/amoeba/setPlans');
export const setBudgets = createAction('@@xcloud/amoeba/setBudgets');
export const setMonthPlans = createAction('@@xcloud/amoeba/setMonthPlans');
export const setDailyPlans = createAction('@@xcloud/amoeba/setDailyPlans');

export const getPlans = createAction('@@xcloud/amoeba/getPlans',
    (companys, dpts, year, planType, notSaveFlag, isMainCompany) => (dispatch, getState, httpClient) => {
        const companyId = getState().auth.currentUserInfo.companyId;
        let url = porxyApi + '/amoeba/plans'
        let strs = []
        if (companys || dpts || year || planType) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            if (planType) strs.push('planType=' + planType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.data) {
                //整合画面显示数据
                let plans = sortPlans(res.data, notSaveFlag ? companys[0] : companyId, isMainCompany);
                if (!notSaveFlag) {
                    dispatch(setPlans(plans));
                }
                return plans;
            }
        }).catch(err => {
            let msg = `获取阿米巴预算列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addPlan = createAction('@@xcloud/amoeba/addPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/plans`, newData, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动阿米巴预算成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `启动阿米巴预算失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updatePlanDtlAmountBatch = createAction('@@xcloud/amoeba/updPlanDtlAmountBatch',
    (year, companys) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/plans/dtls/batch/' + year, companys, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "同步阿米巴预算金额成功"));
                    return res.data;
                }
            }).catch(err => {
                if (!err || !err.response || err.response.status === 502) {
                    dispatch(createMessage('success', '同步阿米巴预算系统处理中,请5分钟后刷新页面。'));
                    return {}
                }
                let msg = `同步阿米巴预算金额失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const createDailyPlans = createAction('@@xcloud/amoeba/createDailyPlans',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/plans/daily`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动阿米巴预算成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `启动阿米巴预算失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getDailyPlans = createAction('@@xcloud/amoeba/getDailyPlans',
    (companys, year, month, planType, notSaveFlag, isMainCompany, day) => (dispatch, getState, httpClient) => {
        // const companyId = getState().auth.currentUserInfo.companyId;
        let url = porxyApi + '/amoeba/plans'
        let strs = []
        if (companys || year || month || planType) {
            if (companys) strs.push('companys=' + companys)
            // if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            if (month) strs.push('month=' + month)
            if (day) strs.push('day=' + day)
            if (planType) strs.push('planType=' + planType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.data) {
                if (planType === PLAN_MONTH) {
                    dispatch(setMonthPlans(res.data));
                } else {
                    dispatch(setDailyPlans(res.data));
                }
                //整合画面显示数据
                //let plans = sortPlans(res.data, notSaveFlag ? companys[0] : companyId, isMainCompany);
                // if (!notSaveFlag) {
                //     dispatch(setDailyPlans(plans));
                //     dispatch(setMonthPlans(plans));
                // }
                return res.data;
            }
        }).catch(err => {
            let msg = `获取阿米巴预算列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateDailyPlanBatch = createAction('@@xcloud/amoeba/updateDailyPlanBatch',
    (year, month, planParam) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/plans/dtls/batch/'
        let strs = [];
        if (year) strs.push(year)
        if (month) strs.push(month)
        url = url + strs.join('/')
        console.log(url);
        return httpClient.patch(url, planParam, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "同步阿米巴预算金额成功"));
                    return res.data;
                }
            }).catch(err => {
                if (!err || !err.response || err.response.status === 502) {
                    dispatch(createMessage('success', '同步阿米巴预算系统处理中,请5分钟后刷新页面。'));
                    return {}
                }
                let msg = `同步阿米巴预算金额失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getBudgets = createAction('@@xcloud/amoeba/getBudgets',
    (companyId, dptId, year, month, day, planType) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/budgets'
        let strs = []
        if (companyId || dptId || year || month || day || planType) {
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            if (year) strs.push('year=' + year)
            if (month) strs.push('month=' + month)
            if (day) strs.push('day=' + day)
            if (planType) strs.push('planType=' + planType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.data) {
                dispatch(setBudgets(res.data))
                return res.data;
            }
        }).catch(err => {
            let msg = `获取阿米巴预算列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const amoebaPlanReducer = handleActions(
    {
        [setPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setDailyPlans]: (state, { payload: data }) => {
            return {
                ...state,
                dailyPlans: data
            }
        },
        [setMonthPlans]: (state, { payload: data }) => {
            return {
                ...state,
                monthPlans: data
            }
        },
        [setBudgets]: (state, { payload: data }) => {
            return {
                ...state,
                budgets: data
            }
        },
    },
    initialState
)

export default amoebaPlanReducer
