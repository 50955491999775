import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    exception: {
        listId: undefined,
        handleType: '',
        handleResult: '',
        acceptResult: '',
        status: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openExceptionAcceptPannel = createAction('@@xcloud/onsiteInspectionExceptionAccept/open',
    (
        confirmCallback, closeCallback, exception
    ) => ({
        confirmCallback, closeCallback, exception
    })
);

export const closeExceptionAcceptPannel = createAction('@@xcloud/onsiteInspectionExceptionAccept/close');

export const changeException = createAction('@@xcloud/inspectionSchemeAccept/changeException');

// Actions

export const onsiteInspectionExceptionAcceptReducer = handleActions(
    {
        [openExceptionAcceptPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                exception: data.exception ?
                    {
                        listId: data.exception.listId,
                        handleType: data.exception.handleType,
                        handleResult: data.exception.handleResult,
                        acceptResult: data.exception.acceptResult,
                        status: data.exception.status
                    }
                    : {
                        handleType: '',
                        handleResult: '',
                        acceptResult: '',
                        status: ''
                    },
            };
        },
        [closeExceptionAcceptPannel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeException]: (state, { payload: data }) => {
            return {
                ...state,
                exception: data,
            };
        }
    },
    initialState
);

export default onsiteInspectionExceptionAcceptReducer;
