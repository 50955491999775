import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
    // padding: theme.spacing(4),
    overflowX: 'auto'
  },
  materialtable: {
    boxShadow: 'none'
  },
  detailtable: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  action: {
    padding: 16,
  },
  title: {
    padding: theme.spacing(2),
  },
  switch: {
    marginBottom: 0,
    marginLeft: 0
  },
  button: {
    padding: 8,
  },
  flexgrow: {
    flexGrow: 1
  },
  root: {
    padding: theme.spacing(0)
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  gridSubItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
});


export default withStyles(styles);