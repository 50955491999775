import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    pos: {
        marginTop: '-15px'
    },
    required: {
        display: 'inline-block',
        color: theme.palette.error.main,
        marginRight: 2,
    },
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    title: {
        padding: theme.spacing(0)
    },
    timeline: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    infocontainer: {
        padding: theme.spacing(2),
        flexDirection: 'column'
    },
    item: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        minWidth: 300
    },
    datePicker: {
        marginTop: 0,
        marginBottom: 0
    },
    radioGroup: {
        justifyContent: 'center'
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        color: theme.palette.divider,
        margin: 1,
    },

});


export default withStyles(styles);