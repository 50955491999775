
// import React from 'react';
// import AppsIcon from '@material-ui/icons/Apps';
// import GraphicEq from '@material-ui/icons/GraphicEq';
// import HomeIcon from '@material-ui/icons/Home';
/* eslint-disable import/no-cycle */
import {
  Home,
  OrgManagement,
  ProcessManagement,
  BuManagement,
  JobManagement,
  UserManagement,
  DeviceList,
  AccessoryList
} from './components';
import RoleManagement from './containers/SystemManagement/RoleManagement/RoleList';
import NoticeManagement from './containers/SystemManagement/NoticeManagement/NoticeConfigList';
import NoticeConfigTarget from './containers/SystemManagement/NoticeManagement/NoticeConfigTarget';
// import LeadershipManagement from './containers/LeadershipManagement/LeadershipManagement';
import OnsiteInspectionRouteListContainer from './containers/OnsiteInspectionManagement/Route/RouteList/OnsiteInspectionRouteListContainer';
import OnsiteInspectionRouteDetailContainer from './containers/OnsiteInspectionManagement/Route/RouteDetail/OnsiteInspectionRouteDetailContainer';
import OnsiteInspectionRuleListContainer from './containers/OnsiteInspectionManagement/Rule/RuleList/OnsiteInspectionRuleListContainer';
import OnsiteInspectionPlanListContainer from './containers/OnsiteInspectionManagement/Plan/PlanList/OnsiteInspectionPlanListContainer';
import OnsiteInspectionRecordReportContainer from './containers/OnsiteInspectionManagement/Report/OnsiteInspectionRecordReportContainer';
import OnsiteInspectionRecordListContainer from './containers/OnsiteInspectionManagement/Plan/RecordList/OnsiteInspectionRecordListContainer';
import OnsiteInspectionIndoorRecordContainer from './containers/OnsiteInspectionManagement/Plan/OnsiteInspectionIndoorRecordContainer';
import OnsiteInspectionExceptionListContainer from './containers/OnsiteInspectionManagement/Exception/OnsiteInspectionExceptionListContainer';
import OnsiteInspectionPlanReportContainer from './containers/OnsiteInspectionManagement/Report/OnsiteInspectionPlanReportContainer';
import PaymentRecord from './containers/SystemManagement/PaymentRecord/PaymentRecordContainer';
import LabourPayment from './containers/SystemManagement/LabourPayment/LabourPaymentContainer';
import Regulation from './containers/LeadershipManagement/CompanyRegulation/Regulation';
// import GoalAndPlan from './containers/LeadershipManagement/GoalAndPlan';
import AnnualGoal from './containers/LeadershipManagement/AnnualGoal/AnnualGoal';
import AnnualPlan from './containers/LeadershipManagement/AnnualPlan/AnnualPlan';
//import CompanyRegulation from './containers/LeadershipManagement/CompanyRegulation/CompanyRegulation';
// import TemporaryRegulationPlan from './components/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan';
//import TemporaryRegulationPlanDetail from './containers/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanDetail';
import RegulationPlan from './containers/LeadershipManagement/AnnualPlan/RegulationPlan'
import RegulationPlanAll from './containers/LeadershipManagement/AnnualPlan/RegulationPlanAll'
import RegulationPlanList from './containers/LeadershipManagement/AnnualPlan/RegulationPlanList'
import TemporaryRegulationPlanInfo from './containers/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanInfo';
import TemporaryRegulationPlanExecution from './containers/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanExecution';

import RuleSettingContainer from './containers/LeadershipManagement/SafetyActivity/RuleSetting'
import PricingContainer from './containers/AmoebaManagement/BudgetPricing/PricingContainer'
import EnergyContainer from './containers/AmoebaManagement/BudgetPricing/EnergyContainer'

// import SafetyActivity from './containers/LeadershipManagement/SafetyActivity/safetyActivity';
import OnDutyCalendar from './containers/LeadershipManagement/OnDuty/OnDutyCalendar';
import TeamActivityCalendar from './containers/LeadershipManagement/TeamActivity/TeamActivityCalendar';
// import MaterialContainer from './containers/MaterialPage';
import InspectionAnnualPlan from './containers/InspectionManagement/AnuualPlan/AnuualPlan';
import FiveSPlan from './components/InspectionManagement/FiveSPlan/FiveSPlan';
import InspectionMonthlyPlan from './components/InspectionManagement/MonthlyPlan/MonthlyPlan';
import MonthlyPlanEdit from "./containers/InspectionManagement/MonthlyPlan/MonthlyPlanEdit";
import MonthlyPlanRecords from "./containers/InspectionManagement/MonthlyPlan/MonthlyPlanRecords";

import InspectionRiskInvestigation from './components/InspectionManagement/RiskInvestigation/RiskInvestigation';
import RiskInvestigationEdit from "./containers/InspectionManagement/RiskInvestigation/RiskInvestigationEdit";
import RiskInvestigationRecords from "./containers/InspectionManagement/RiskInvestigation/RiskInvestigationRecords";

import FiveSPlanEdit from "./containers/InspectionManagement/FiveSPlan/FiveSPlanEdit";
import FiveSPlanRecords from "./containers/InspectionManagement/FiveSPlan/FiveSPlanRecords";

import HazardList from "./components/InspectionManagement/HazardList/HazardListManagement";
import HazardInfo from "./containers/InspectionManagement/HazardList/HazardInfo";
import HazardReport from "./containers/InspectionManagement/HazardList/HazardReport";

// import ChecklistManagement from './components/InspectionManagement/Checklist/ChecklistManagement';
import ChecklistInventory from './containers/InspectionManagement/Checklist/ChecklistInventory';
import ChecklistItems from './containers/InspectionManagement/Checklist/ChecklistItems';
import ChecklistEdit from "./containers/InspectionManagement/Checklist/ChecklistEdit";

import DanagerChecklistInventory from './containers/InspectionManagement/DangerChecklist/ChecklistInventory';
import DangerChecklistItems from './containers/InspectionManagement/DangerChecklist/ChecklistItems';
import DangerChecklistEdit from "./containers/InspectionManagement/DangerChecklist/ChecklistInfo";
// import ComponentsPannel from "./containers/ComponentsPannel";
import App from './App';

import InstallationDetail from "./containers/InstallationManagement/InstallationDetail";
import InstallationMapEdit from "./containers/InstallationManagement/InstallationMapEdit";
import InstallationMapView from "./containers/InstallationManagement/InstallationMapView";
import InstallationManagement from "./components/InstallationManagement";
import RuleListContainer from './containers/DeviceManagement/OperationRule/RuleListContainer';
import RuleDetailContainer from "./containers/DeviceManagement/OperationRule/RuleDetailContainer";
// import OperationRuleSetup from "./containers/DeviceManagement/OperationRule/RuleSetup";
import RuleCatSetup from "./containers/DeviceManagement/OperationRule/RuleCatSetup";
import OperationAnnuaPlan from './containers/DeviceManagement/AnnualPlan/OperationAnnuaPlan';
import OperationGoalPlanDetail from './containers/DeviceManagement/AnnualPlan/OperationGoalPlanDetail';
import OperationGoalPlanDetailEdit from './containers/DeviceManagement/AnnualPlan/OperationGoalPlanDetailEdit';
import OperationPlan from './containers/DeviceManagement/OperationPlan/OperationPlan';
import OperationPlanDetail from './containers/DeviceManagement/OperationPlan/OperationPlanDetail';
import OperationPlanEdit from './containers/DeviceManagement/OperationPlan/OperationPlanEdit';
import OperationPlanDocs from './containers/DeviceManagement/OperationPlanDocs/OperationPlanDocs';

import DeviceFormList from './containers/DeviceManagement/DeviceInfo/DeviceFormList';
import DeviceFormDetail from './containers/DeviceManagement/DeviceInfo/DeviceFormDetail';

import OperationStatus from './containers/OperationManagement/OperationStatus';
import DeviceEdit from "./containers/DeviceManagement/DeviceInfo/DeviceEdit";
import AccessoryEdit from "./containers/AccessoryManagement/AccessoryInfo/AccessoryEdit";
import DeviceAccessoryList from "./containers/AccessoryManagement/AccessoryInfo/DeviceAccessoryList";
//import MaintainDocList from "./containers/DeviceManagement/MaintainDoc/MaintainDocList";
// import DeviceStatusStatistics from "./containers/DataStatistics/DeviceUseStatus";
import DeviceWorkOrder from './containers/OperationManagement/DeviceWorkOrder';
import DeviceOpsRecord from './containers/DeviceManagement/DeviceOpsRecord/DeviceOpsRecord';
import DeviceReport from './containers/DeviceManagement/DeviceReport/DeviceReport';
import InsuranceList from './containers/DeviceManagement/Insurances/InsuranceList';
import InsuranceEdit from './containers/DeviceManagement/Insurances/InsuranceEdit';
// 承包商containers引入
import SupplierList from './containers/DeviceManagement/Supplier/SupplierList';
import SupplierEdit from './containers/DeviceManagement/Supplier/SupplierEdit';



import MajorHazardInfo from './components/MajorHazardSources/MajorHazardInfo';
import MajorHazardDetection from './components/MajorHazardSources/MajorHazardDetection';
import MajorHazardDoc from './containers/MajorHazardSources/MajorHazardDoc';

// import Customer from './components/Crm/Customer';
// import CustomerInfoContainer from './containers/Crm/Customers/CustomerInfo/CustomerInfo';
import Oppo from './components/Crm/Oppo';
import ActivitiesContainer from './containers/Crm/Activities/Activities';

import OppoInfo from './containers/Crm/Oppo/OppoInfo';
import MyActivitiesContainer from './containers/Crm/Activities/MyActivitiesContainer';

import WorkOrderList from './containers/OperationManagement/WorkOrderList';
import WorkOrderProcess from './containers/OperationManagement/WorkOrderProcess';
import CreateAppointment from './containers/OperationManagement/CreateAppointment';
import AppointmentDetail from './containers/OperationManagement/AppointmentDetail';

import ProcessEdit from "./containers/SystemManagement/ProcessManagement/ProcessEdit";
import BuEdit from "./containers/SystemManagement/BuManagement/BuEdit";
import JobEdit from "./containers/SystemManagement/JobManagement/JobEdit";
import UserEdit from "./containers/SystemManagement/UserManagement/UserEdit";
import RoleGroupEdit from "./containers/SystemManagement/RoleManagement/RoleGroupEdit";
import HolidayManagement from "./containers/SystemManagement/HolidayManagement/HolidayList";
import RoleEdit from "./containers/SystemManagement/RoleManagement/RoleEdit";
import Icon from "./lab/Icon"
import Dialog from "./lab/Dialog"
import ThreeD from "./lab/ThreeD"


// 生产管理
// import HandoverContainer from "./containers/ManufactureManagement/Handover/HandoverContainer";
// import InstallActionContainer from "./containers/ManufactureManagement/InstallAction/InstallActionContainer";
// import InnerLaborContainer from "./containers/ManufactureManagement/Labor/InnerLaborContainer";
// import OuterLaborContainer from "./containers/ManufactureManagement/Labor/OuterLaborContainer";
import PickingContainer from "./containers/ManufactureManagement/Picking/PickingContainer";
import ProdReportContainer from "./containers/ManufactureManagement/ProdReport/ProdReportContainer";
// import ServiceReportContainer from "./containers/ManufactureManagement/ServiceReport/ServiceReportContainer";
// import TaskContainer from "./containers/ManufactureManagement/Task/TaskContainer";
import WarehousingContainer from "./containers/ManufactureManagement/Warehousing/WarehousingContainer";
import ManufactureManagementContainer from "./containers/ManufactureManagement/ManufactureManagementContainer";
import InstallActionRecordContainer from './containers/ManufactureManagement/InstallAction/InstallActionRecordContainer';
import InstallActionListContainer from './containers/ManufactureManagement/InstallAction/InstallActionListContainer';
import MActivityRecordContainer from './containers/ManufactureManagement/ManufactureActivity/MActivityRecordContainer';
// import GasContainer from './containers/AmoebaManagement/Gas/GasContainer';
// import ElecContainer from './containers/AmoebaManagement/Energy/Elec/ElecContainer';
// import SteamContainer from './containers/AmoebaManagement/Energy/Steam/SteamContainer';
// import MarketingContainer from './containers/AmoebaManagement/Marketing/MarketingConer';
import DepreciationRuleContainer from './containers/AmoebaManagement/DepreciationRule/DepreciationRule';
import FixedCostContainer from './containers/AmoebaManagement/FixedCost/FixedCostContainer';
import SharingCostContainer from './containers/AmoebaManagement/SharingCost/SharingCostContainer';
// import OtherSharingContainer from './containers/AmoebaManagement/SharingCost/OtherSharingContainer';
import InstallSharingContainer from './containers/AmoebaManagement/SharingCost/InstallSharingContainer';
import ServiceSharingContainer from './containers/AmoebaManagement/SharingCost/ServiceSharingContainer';
// import MealContainer from './containers/AmoebaManagement/Meal/MealContainer';
// import WaterContainer from './containers/AmoebaManagement/Energy/Water/WaterContainer';
import SalOrderContainer from './containers/AmoebaManagement/SalOrder/SalOrderContainer';
import SalOutStockContainer from './containers/AmoebaManagement/SalOutStock/SalOutStockContainer';
import AActivityRecordContainer from './containers/AmoebaManagement/AmoebaActivity/AActivityRecordContainer';
import MOrderContainer from './containers/ManufactureManagement/ManufactureOrder/MOrderContainer';
// import OuterLaborRecordContainer from './containers/ManufactureManagement/Labor/OuterLaborRecordContainer';
// import InnerLaborRecordContainer from './containers/ManufactureManagement/Labor/InnerLaborRecordContainer';
// import InnerLaborListContainer from './containers/ManufactureManagement/Labor/InnerLaborListContainer';
// import OuterLaborListContainer from './containers/ManufactureManagement/Labor/OuterLaborListContainer';
// import MPlanDetailContainer from './containers/ManufactureManagement/ManufacturePlanDetail/MPlanDetailContainer';
import MMonthlyPlanContainer from './containers/ManufactureManagement/ManufacturePlan/MMonthlyPlanContainer';
// import MTempPlanContainer from './containers/ManufactureManagement/ManufacturePlan/MTempPlanListContainer';
// import GasRecordContainer from './containers/AmoebaManagement/Gas/GasRecordContainer';
import MAttrListContainer from './containers/ManufactureManagement/ManufactureAttr/MAttrListContainer';
import MActivityListContainer from './containers/ManufactureManagement/ManufactureActivity/MActivityListContainer';
import MServiceListContainer from './containers/ManufactureManagement/ManufactureService/MServiceListContainer';
import MCommissionListContainer from './containers/ManufactureManagement/ManufactureService/MCommissionListContainer';
import ShiftRuleListContainer from './containers/ManufactureManagement/ManufactureShiftRule/ShiftRuleListContainer';
import ServicePricingContainer from './containers/AmoebaManagement/ServicePricing/ServicePricingContainer';
// import PrimaryPricingContainer from './containers/AmoebaManagement/BudgetPricing/PrimaryPricingContainer';
// import OtherPricingContainer from './containers/AmoebaManagement/BudgetPricing/OtherPricingContainer';
// import EnergyPricingContainer from './containers/AmoebaManagement/BudgetPricing/EnergyPricingContainer';
import MyCalendarContainer from './containers/LeadershipManagement/MyCalendar/MyCalendarContainer';
import TeamActivityConfig from './containers/LeadershipManagement/TeamActivity/TeamActivityConfig';
import ScheduleContainer from './containers/OnsiteInspectionManagement/Schedule/ScheduleContainer';
import IndexContainer from './containers/AmoebaManagement/Index/IndexContainer';
import AnnualBudgetContainer from './containers/AmoebaManagement/Plan/AnnualBudgetContainer';
import MonthBudgetContainer from './containers/AmoebaManagement/Plan/MonthBudgetContainer';
// import AmoebaPlanContainer from './containers/AmoebaManagement/Plan/AmoebaPlanContainer';
// import AmoebaMonthlyPlanContainer from './containers/AmoebaManagement/Plan/AmoebaMonthlyPlanContainer';
// import HrPlanContainer from './containers/AmoebaManagement/HrPlan/HrPlanContainer';
// import FixedPlanContainer from './containers/AmoebaManagement/FixedPlan/FixedPlanContainer';
// import DepreciationPlanContainer from './containers/AmoebaManagement/DepreciationPlan/DepreciationPlanContainer';
import ServiceBudgetContainer from './containers/AmoebaManagement/ServiceBudget/ServiceBudgetContainer';
import AmoebaReportContainer from './containers/AmoebaManagement/Report/AmoebaReportContainer';
import AmoebaAnalysisReportContainer from "./containers/AmoebaManagement/Report/AmoebaAnalysisReportContainer";
import AmoebaReportFixedContainer from './containers/AmoebaManagement/Report/AmoebaReportFixedContainer';
import ProjectPlanContainer from './containers/ManufactureManagement/ProjectPlan/ProjectPlanContainer';
import MisdeliveryContainer from "./containers/ManufactureManagement/Misdelivery/MisdeliveryContainer";
import RefuelRecordContainer from "./containers/AmoebaManagement/RefuelRecord/RefuelRecordContainer";
import PhoneBillContainer from "./containers/AmoebaManagement/PhoneBill/PhoneBillContainer";
import HWInstockContainer from "./containers/ManufactureManagement/HWInstock/HWInstockContainer";
import StaffSharingContainer from './containers/AmoebaManagement/SharingCost/StaffSharingContainer';
import ServiceCustomerContainer from './containers/ManufactureManagement/ServiceCustomer/ServiceCustomerContainer';
import LabourPaymentConfigContainer from './containers/AmoebaManagement/LabourPaymentConfig/LabourPaymentConfigContainer';
import PredefinedProcess from './containers/PredefinedProcess';
import VariablePlanContainer from './containers/AmoebaManagement/VariablePlan/VariablePlanContainer';
import VariableConfigContainer from './containers/AmoebaManagement/VariableConfig/VariableConfigContainer';
import AssayRecordContainer from './containers/ManufactureManagement/AssayRecord/AssayRecordContainer';
import AssayRecordSummaryContainer from './containers/ManufactureManagement/AssayRecord/AssayRecordSummaryContainer';
import OilPriceContainer from './containers/AmoebaManagement/OilPrice/OilPriceContainer';
import GoalFixedPlanContainer from './containers/AmoebaManagement/FixedPlan/GoalFixedPlanContainer';
import MTempPlanListContainer from './containers/ManufactureManagement/ManufacturePlan/MTempPlanListContainer';
import MAnnualPlanContainer from './containers/ManufactureManagement/ManufacturePlan/MAnnualPlanContainer';
// import AmoebaInstallReportContainer from './containers/AmoebaManagement/Report/AmoebaInstallReportContainer';

import { APP_MENU_MAIN } from 'src/utils/constant'
import {
  HomeOutlined,
  FlagOutlined,
  CalendarOutlined,
  ClusterOutlined,
  DeploymentUnitOutlined,
  SnippetsOutlined,
  AlertOutlined,
  IssuesCloseOutlined,
  BranchesOutlined,
  ControlOutlined,
  FundProjectionScreenOutlined,
  TeamOutlined,
  ToolOutlined,
} from '@ant-design/icons';
export default [
  {
    id: "root",
    title: 'root',
    href: '/',
    component: Home,
    auth: true,
    slide: false,
    rootcomponent: App,
    level: 1,
    checkroute: false
  },
  {
    id: "home",
    title: APP_MENU_MAIN.MY_HOMEPAGE,
    Icon: HomeOutlined,
    href: '/home',
    component: Home,
    auth: false,
    slide: true,
    rootcomponent: App,
    level: 1,
    checkroute: false
  },
  {
    id: "schedule",
    title: APP_MENU_MAIN.MY_CALENDAR,
    Icon: CalendarOutlined,
    href: '/schedule',
    component: MyCalendarContainer,
    auth: true,
    slide: false,
    rootcomponent: App,
    level: 1,
    checkroute: false
  },
  {
    id: "leadership",
    title: APP_MENU_MAIN.LEADER_SHIP,
    Icon: FlagOutlined,
    href: '/leadership',
    component: AnnualGoal,
    rootcomponent: App,
    auth: false,
    slide: true,
    level: 1,
    checkroute: true,
    children:
      [
        // {
        //   id: "goal",
        //   title: '年度目标',
        //   href: '/leadership/goal',
        //   component: AnnualGoal,
        //   rootcomponent: App,
        //   auth: true,
        //   slide: true,
        //   level: 2,
        //   checkroute: false
        // },
        {
          id: "regulation",
          title: '公司规章制度',
          href: '/leadership/regulation',
          component: Regulation,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "leadership_plan",
          title: '企业年度计划',
          href: '/leadership/plan',
          component: AnnualPlan,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
          children:
            [
              {
                id: "leadership_regulation",
                title: '规章制度',
                href: '/leadership/plan/regulation',
                component: AnnualPlan,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 3,
                checkroute: true
              },
              {
                id: "leadership_operation",
                title: '设备运维',
                href: '/leadership/plan/operation',
                component: OperationAnnuaPlan,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 3,
                checkroute: false
              },
              {
                id: "leadership_operation_detail",
                title: '设备运维详细',
                href: '/leadership/plan/operation/:id',
                component: OperationGoalPlanDetail,
                rootcomponent: App,
                auth: true,
                slide: false,
                level: 3,
                checkroute: false
              },
              {
                id: "leadership_operation_detail_edit",
                title: '新建设备运维详细',
                href: '/leadership/plan/operation/create/:planId/:target',
                component: OperationGoalPlanDetailEdit,
                rootcomponent: App,
                auth: true,
                slide: false,
                level: 4,
                checkroute: false
              },
              {
                id: "leadership_inspection",
                title: '隐患排查',
                href: '/leadership/plan/inspection',
                component: InspectionAnnualPlan,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 3,
                checkroute: true,
              },
            ]
        },
        {
          id: "leadership_personal_plan",
          title: '个人安全行动计划',
          href: '/leadership/personal/plan',
          component: null,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: false,
          children: [
            {
              id: "leadership_onduty",
              title: '带班值班',
              href: '/leadership/onduty',
              component: OnDutyCalendar,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            },
            {
              id: "leadership_teamactivity",
              title: '班组活动',
              href: '/leadership/teamactivity',
              component: TeamActivityCalendar,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
              children: [
                {
                  id: "leadership_teamactivity",
                  title: '班组安全活动',
                  href: '/leadership/teamactivity',
                  component: TeamActivityCalendar,
                  rootcomponent: App,
                  auth: true,
                  slide: true,
                  level: 4,
                  checkroute: true,
                }, {
                  id: "leadership_teamactivity_config",
                  title: '班组安全活动配置',
                  href: '/leadership/teamactivity/config',
                  component: TeamActivityConfig,
                  rootcomponent: App,
                  auth: true,
                  slide: true,
                  level: 4,
                  checkroute: false,
                }
              ]
            }
          ]
        },

      ]
  },
  {
    id: "installation",
    title: APP_MENU_MAIN.INSTALLATION_MANAGEMENT,
    Icon: ClusterOutlined,
    href: '/installtion',
    component: Home,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [{
      id: "installation_management",
      title: '装置档案',
      href: '/installationManagement',
      component: InstallationManagement,
      rootcomponent: App,
      auth: true,
      slide: true,
      level: 2,
      checkroute: true
    },
    {
      id: "installation_info",
      title: '装置详细信息',
      href: '/installationManagement/info/:id',
      component: InstallationDetail,
      rootcomponent: App,
      auth: true,
      slide: false,
      checkroute: true
    },
    {
      id: "installation_mapview",
      title: '装置缩略图',
      href: '/installationManagement/mapview/:id',
      component: InstallationMapView,
      rootcomponent: App,
      auth: true,
      slide: false,
      checkroute: true
    },
    {
      id: "installation_map",
      title: '装置图',
      href: '/installationManagement/map/:id',
      component: InstallationMapEdit,
      rootcomponent: App,
      auth: true,
      slide: false,
      checkroute: true
    },
    {
      id: "manufacture_manipulate",
      title: '装置操作',
      href: '/manufacture/manipulate',
      component: ProdReportContainer,
      rootcomponent: App,
      auth: true,
      slide: true,
      level: 2,
      checkroute: true,
      children: [
        {
          id: "manufacture_installation",
          title: '操作记录',
          href: '/manufacture/installation',
          component: InstallActionRecordContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 3,
        },
        {
          id: "amoeba_install_config",
          title: '操作配置',
          href: '/amoeba/installconfig',
          component: InstallActionListContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 3,
          checkroute: true,
        },
      ]
    },
    ]
  },
  {
    id: "device_management",
    title: APP_MENU_MAIN.DEVICE_MANAGEMENT,
    Icon: DeploymentUnitOutlined,
    href: '/deviceManagement',
    component: Home,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [
      {
        id: "device_list",
        title: '设备档案',
        href: '/deviceList',
        component: DeviceList,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "device_form_list",
        title: '设备表单',
        href: '/deviceFormList',
        component: DeviceFormList,
        rootcomponent: App,
        level: 2,
        auth: false,
        slide: true,
        checkroute: false
      },
      {
        id: "device_form_info",
        title: '设备表单',
        href: '/deviceForm/:formId',
        component: DeviceFormDetail,
        rootcomponent: App,
        level: 2,
        auth: false,
        slide: false,
        checkroute: false
      },
      {
        id: "device_info",
        title: '设备详细信息',
        href: '/deviceManagement/info',
        component: DeviceEdit,
        rootcomponent: App,
        auth: true,
        slide: false,
        checkroute: true
      },
      {
        id: "accessory_list",
        title: '部件管理',
        href: '/accessoryList',
        component: AccessoryList,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "accessory_info",
        title: '部件详细信息',
        href: '/accessoryManagement/info',
        component: AccessoryEdit,
        rootcomponent: App,
        auth: true,
        slide: false,
        checkroute: true
      },
      {
        id: "device_accessory_list",
        title: '设备部件列表信息',
        href: '/deviceAccessoryList/info',
        component: DeviceAccessoryList,
        rootcomponent: App,
        auth: true,
        slide: false,
        checkroute: true
      },
      {
        id: "device_operation",
        title: '设备运维',
        href: '/deviceOperation',
        component: RuleListContainer,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true,
        children: [
          {
            id: "operation_rule",
            title: '运维规则',
            href: '/operationRule',
            component: RuleListContainer,
            rootcomponent: App,
            level: 3,
            auth: true,
            slide: true,
            checkroute: true
          },
          {
            id: "operation_plan",
            title: '运维计划',
            href: '/deviceManagement/operationplan',
            component: OperationPlan,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          },
          {
            id: "operation_plan_info",
            title: '运维计划详细信息',
            href: '/deviceManagement/operationplan/:id',
            component: OperationPlanDetail,
            rootcomponent: App,
            auth: true,
            slide: false,
            level: 3,
            checkroute: false
          },
          {
            id: "operation_plan_edit",
            title: '新建运维计划',
            href: '/deviceManagement/operationplan/create/:target',
            component: OperationPlanEdit,
            rootcomponent: App,
            auth: true,
            slide: false,
            level: 3,
            checkroute: false
          },
          {
            id: "operation_plan_workorder",
            title: '新建运维计划工单',
            href: '/deviceManagement/operationplan/workorder/:id',
            component: DeviceWorkOrder,
            rootcomponent: App,
            auth: true,
            slide: false,
            level: 3,
            checkroute: false
          },
          {
            id: "operation_plan_workorder_2",
            title: '新建运维计划工单',
            href: '/deviceManagement/operationplan/workorder/:source/:id',
            component: DeviceWorkOrder,
            rootcomponent: App,
            auth: true,
            slide: false,
            level: 3,
            checkroute: false
          },
          {
            id: "operation_workorder_list",
            title: '所有工单',
            href: '/operationManagement/workorderlist/all',
            component: WorkOrderList,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          },
          {
            id: "operation_workorder_list",
            title: '工单审批',
            href: '/operationManagement/workorderlist/needProcess',
            component: WorkOrderList,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          },
          {
            id: "operation_plan_doc",
            title: '报告待上传',
            href: '/deviceManagement/operationplan/report/docs',
            component: OperationPlanDocs,
            rootcomponent: App,
            auth: false,
            slide: true,
            level: 3,
            checkroute: false
          },
          {
            id: "operation_workorder_list",
            title: '待决算工单',
            href: '/operationManagement/workorderlist/needCount',
            component: WorkOrderList,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          },
          {
            id: "operation_workorder_list",
            title: '工单处理',
            href: '/operationManagement/workorderlist/:routeType/edit/:id',
            component: WorkOrderProcess,
            rootcomponent: App,
            auth: true,
            slide: false,
            level: 3,
            checkroute: false
          },
          {
            id: "device_status_statistics",
            title: '运维记录',
            href: '/deviceManagement/deviceOpsRecord',
            component: DeviceOpsRecord,
            rootcomponent: App,
            level: 3,
            auth: true,
            slide: true,
            checkroute: true
          },
        ]
      },
      {
        id: "device_monitor",
        title: '状态监测',
        href: '/deviceMonitor',
        component: null,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true,
        newWindow: "/devicemonitor.html"
      },
      // {
      //   id: "device_status_statistics",
      //   title: '设备报表OLD',
      //   href: '/deviceStatusStatistics',
      //   component: DeviceStatusStatistics,
      //   rootcomponent: App,
      //   level: 2,
      //   auth: true,
      //   slide: true,
      //   checkroute: true
      // },
      {
        id: "device_status_statistics",
        title: '设备报表',
        href: '/deviceReport',
        component: DeviceReport,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: 'device_insurance_list',
        title: '车辆保险',
        href: '/devices/insurances',
        component: InsuranceList,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
      {
        id: 'supplier_list',
        title: '承包商管理',
        href: '/devices/suppliers',
        component: SupplierList,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
      // {
      //   id: "device_ops_record",
      //   title: '设备运维记录',
      //   href: '/deviceManagement/deviceOpsRecord',
      //   component: DeviceOpsRecord,
      //   rootcomponent: App,
      //   level: 2,
      //   auth: true,
      //   slide: true,
      //   checkroute: true
      // }
    ]
  },
  {
    id: "operation_management",
    title: APP_MENU_MAIN.OPERATION_MANAGEMENT,
    Icon: SnippetsOutlined,
    href: '/operationManagement',
    component: Home,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [
      {
        id: "operation_workorder_list",
        title: '作业预约',
        href: '/operationManagement/workorderlist/needAppointment',
        component: WorkOrderList,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false
      },
      {
        id: "operation_workorder_list",
        title: '作业记录',
        href: '/operationManagement/workorderlist/needWork',
        component: WorkOrderList,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false
      },
      {
        id: "operation_workorder_appointment",
        title: '作业预约',
        href: '/operationManagement/workorder/:routeType/appointment/:id',
        component: CreateAppointment,
        rootcomponent: App,
        auth: true,
        slide: false,
        level: 2,
        checkroute: false
      },
      {
        id: "operation_workorder_work_record",
        title: '作业记录',
        href: '/operationManagement/workorder/:routeType/:oid/appointment/:aid/:workType',
        component: AppointmentDetail,
        rootcomponent: App,
        auth: false,
        slide: false,
        level: 2,
        checkroute: false
      },
      {
        id: "operation_report",
        title: '作业报表',
        href: '/operationManagement/operationReport',
        component: OperationStatus,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false
      },
    ]
  },
  {
    id: "majorhazard_management",
    title: APP_MENU_MAIN.MAJORHAZARD_MANAGEMENT,
    Icon: AlertOutlined,
    href: '/majorhazardsources',
    component: Home,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [
      {
        id: "majorHazard_list",
        title: '重大危险源列表',
        href: '/majorhazardsources/list',
        component: MajorHazardInfo,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
      {
        id: "majorhazard_doc_list",
        title: '重大危险源评估报告',
        href: '/majorhazardsources/list/:id',
        component: MajorHazardDoc,
        rootcomponent: App,
        auth: true,
        slide: false,
        level: 3,
        checkroute: false
      },
      {
        id: "majorhazard_detection",
        title: '重大危险源监测',
        href: '/majorhazardsources/detection',
        component: MajorHazardDetection,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false
      },
      {
        id: "majorhazard_doc_detection",
        title: '重大危险源评估报告',
        href: '/majorhazardsources/detection/:id',
        component: MajorHazardDoc,
        rootcomponent: App,
        auth: true,
        slide: false,
        level: 3,
        checkroute: false
      },
    ]
  },
  {
    id: "inspection",
    title: APP_MENU_MAIN.INSPECTION_MANAGEMENT,
    Icon: IssuesCloseOutlined,
    href: '/inspectionManagement',
    component: ChecklistInventory,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children:
      [
        {
          id: "inspection_fives",
          title: '5S检查计划',
          href: '/inspection/5splan',
          component: FiveSPlan,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_mounthly",
          title: '隐患排查计划',
          href: '/inspection/monthlyplan',
          component: InspectionMonthlyPlan,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_hazardlist",
          title: '隐患审定',
          href: '/inspection/hazardlist/beforeevaluated',
          component: HazardList,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_hazardlist",
          title: '隐患治理通知单',
          href: '/inspection/hazardlist/afterevaluated',
          component: HazardList,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_hazardlist",
          title: '隐患台账',
          href: '/inspection/hazardlist/treating',
          component: HazardList,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_hazardlist",
          title: '历史上报隐患',
          href: '/inspection/hazardlist/hisevaluated',
          component: HazardList,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        }, {
          id: "inspection_checklist",
          title: '检查表管理',
          href: '/checklistManagement',
          component: ChecklistInventory,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_hazard_report",
          title: '隐患报表',
          href: '/hazardReport',
          component: HazardReport,
          rootcomponent: App,
          // auth: true,
          slide: true,
          level: 2,
          // checkroute: true
        }, {
          id: "inspection_risk_investigation",
          title: '风险研判计划',
          href: '/inspection/riskinvestigation',
          component: InspectionRiskInvestigation,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        },
        {
          id: "inspection_danager_checklist",
          title: '风险研判表',
          href: '/danagerchecklistManagement',
          component: DanagerChecklistInventory,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true
        }
      ]
  },
  {
    id: "qc",
    title: APP_MENU_MAIN.ONSITE_INSPECTION_MANAGEMENT,
    Icon: BranchesOutlined,
    href: '/qc',
    component: OnsiteInspectionRouteListContainer,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [
      {
        id: "qc_route_detail",
        title: '巡检路线详情',
        href: '/qc/route/details/:id',
        component: OnsiteInspectionRouteDetailContainer,
        rootcomponent: App,
        auth: true,
        slide: false,
        level: 2,
      },
      {
        id: "qc_plan",
        title: '巡检计划管理',
        href: '/qc/plan',
        component: OnsiteInspectionPlanListContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
      },
      {
        id: "qc_plan",
        title: '巡检统计',
        href: '/qc/record/report',
        component: OnsiteInspectionRecordReportContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
      {
        id: "qc_record",
        title: '巡检记录',
        href: '/qc/record/:id',
        component: OnsiteInspectionRecordListContainer,
        rootcomponent: App,
        auth: false,
        slide: false,
        level: 2,
      },
      {
        id: "qc_record",
        title: '巡检记录',
        href: '/qc/indoor/:id',
        component: OnsiteInspectionIndoorRecordContainer,
        rootcomponent: App,
        auth: true,
        slide: false,
        level: 2,
      },
      {
        id: "qc_exception",
        title: '巡检异常记录',
        href: '/qc/exception',
        component: OnsiteInspectionExceptionListContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
      },
      {
        id: "qc_operation_report",
        title: '巡检报表',
        href: '/qc/report/qcplan',
        component: OnsiteInspectionPlanReportContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false
      },
      {
        id: "qc_config",
        title: '巡检设置',
        href: '/qc/config',
        component: null,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: false,
        children: [
          {
            id: "qc_route",
            title: '巡检路线管理',
            href: '/qc/route',
            component: OnsiteInspectionRouteListContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
          },
          {
            id: "qc_rule",
            title: '巡检规则配置',
            href: '/qc/rule',
            component: OnsiteInspectionRuleListContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
          },
          {
            id: "qc_schedule",
            title: '管理巡检配置',
            href: '/qc/schedule',
            component: ScheduleContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
          },
        ]
      },
    ]
  },
  {
    id: "manufacture",
    title: APP_MENU_MAIN.MANUFACTURE_MANAGEMENT,
    Icon: ControlOutlined,
    href: '/manufacture',
    component: ManufactureManagementContainer,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children:
      [
        {
          id: "manufacture_plan",
          title: '生产计划',
          href: '/manufacture/plan',
          component: null,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
          children: [
            {
              id: "manufacture_plan_annu",
              title: '年度计划',
              href: '/manufacture/plans',
              component: MAnnualPlanContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "manufacture_plan_monthly",
              title: '月度计划',
              href: '/manufacture/monthlyplans',
              component: MMonthlyPlanContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "manufacture_plan_temp",
              title: '临时计划',
              href: '/manufacture/plans/temp',
              component: MTempPlanListContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "manufacture_workorder",
              title: '生产班组日工单',
              href: '/manufacture/workorder',
              component: MOrderContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "manufacture_shiftrule_config",
              title: '排班设置',
              href: '/manufacture/shiftrule/config',
              component: ShiftRuleListContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
          ],
        },
        {
          id: "manufacture_execution",
          title: '生产记录',
          href: '/manufacture/execution',
          component: null,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
          children: [
            {
              id: "manufacture_picking",
              title: '生产领料',
              href: '/manufacture/picking',
              component: PickingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            }, {
              id: 'manufacture_activity_record',
              title: '生产活动',
              href: '/manufacture/activityrecord',
              component: MActivityRecordContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: "manufacture_instock",
              title: '生产入库',
              href: '/manufacture/instock',
              component: WarehousingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            }, {
              id: "manufacture_misdelivery",
              title: '其他领料',
              href: '/manufacture/misdelivery',
              component: MisdeliveryContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            }, {
              id: 'manufacture_refuel_record',
              title: '加油记录',
              href: '/amoeba/refuelrecord',
              component: RefuelRecordContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            },
            {
              id: 'manufacture_phone_bill',
              title: '电话费',
              href: '/amoeba/phone_bill',
              component: PhoneBillContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true
            },
            // {
            //   id: 'manufacture_handover',
            //   title: '交接班',
            //   href: '/manufacture/handover',
            //   component: HandoverContainer,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            // },
          ],
        },
        {
          id: "amoeba_marketing",
          title: '营销记录',
          href: '/amoeba/marketing',
          component: null,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
          children: [{
            id: "amoeba_salorder",
            title: '销售订单',
            href: '/amoeba/salorder',
            component: SalOrderContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          }, {
            id: "amoeba_saloutstock",
            title: '销售出库',
            href: '/amoeba/saloutstock',
            component: SalOutStockContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          }, {
            id: "manufacture_hwinstock",
            title: '危废进厂',
            href: '/manufacture/hwinstock',
            component: HWInstockContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          }, {
            id: "amoeba_marketing_activity",
            title: '销售活动',
            href: '/amoeba/maketing_activity',
            component: AActivityRecordContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          }]
        },
        {
          id: "manufacture_assay",
          title: '化验管理',
          href: '/manufacture/assay',
          component: null,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
          children: [{
            id: "manufacture_assay_record",
            title: '化验记录',
            href: '/manufacture/assay/record',
            component: AssayRecordContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          },
          {
            id: "manufacture_assay_summary",
            title: '化验统计',
            href: '/manufacture/assay/summary',
            component: AssayRecordSummaryContainer,
            rootcomponent: App,
            auth: true,
            slide: true,
            level: 3,
            checkroute: true
          }]
        },

        {
          id: 'manufacture_project_manage',
          title: '工程管理',
          href: '/amoeba/projectmanage',
          component: ProjectPlanContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
        },
        {
          id: "manufacture_report",
          title: '生产报表',
          href: '/manufacture/report',
          component: ProdReportContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          checkroute: true,
        },
      ]
  }, {
    id: "amoeba",
    title: APP_MENU_MAIN.AMOEBA_MANAGEMENT,
    Icon: FundProjectionScreenOutlined,
    href: '/amoeba',
    component: ManufactureManagementContainer,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children:
      [
        {
          id: 'amoeba_market',
          title: '市场定义',
          href: '/amoeba/market',
          component: FixedCostContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          children: [
            {
              id: "amoeba_service_config",
              title: '服务配置',
              href: '/amoeba/service/config',
              component: MServiceListContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "amoeba_commission_config",
              title: '外委服务',
              href: '/amoeba/commission/config',
              component: MCommissionListContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: "amoeba_service_pricing",
              title: '服务定价',
              href: '/amoeba/service/pricing',
              component: ServicePricingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            }, {
              id: "amoeba_service_customer",
              title: '客户定价',
              href: '/amoeba/service/customer',
              component: ServiceCustomerContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            }, {
              id: "amoeba_oil_price",
              title: '油品定价',
              href: '/amoeba/oilprice',
              component: OilPriceContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            }, {
              id: "amoeba_activity",
              title: '生产活动配置',
              href: '/amoeba/activity',
              component: MActivityListContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
              children: [{
                id: "amoeba_attr_config",
                title: '活动属性配置',
                href: '/amoeba/attr/config',
                component: MAttrListContainer,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 4,
                checkroute: true,
              },
              {
                id: "amoeba_activity_config",
                title: '活动配置',
                href: '/amoeba/activity/config',
                component: MActivityListContainer,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 4,
                checkroute: true,
              },]
            },

            // {
            //   id: 'amoeba_energy',
            //   title: '能耗分摊',
            //   href: '/amoeba/energy',
            //   component: null,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            //   children: [
            //     {
            //       id: 'amoeba_watercost',
            //       title: '水费配置',
            //       href: '/amoeba/watercost',
            //       component: WaterContainer,
            //       rootcomponent: App,
            //       auth: true,
            //       slide: true,
            //       level: 4,
            //     }, {
            //       id: "amoeba_elec",
            //       title: '电费配置',
            //       href: '/amoeba/energy/elec',
            //       component: ElecContainer,
            //       rootcomponent: App,
            //       auth: true,
            //       slide: true,
            //       level: 4,
            //       checkroute: true,
            //     }, {
            //       id: "amoeba_steam",
            //       title: '蒸汽配置',
            //       href: '/amoeba/energy/steam',
            //       component: SteamContainer,
            //       rootcomponent: App,
            //       auth: true,
            //       slide: true,
            //       level: 4,
            //       checkroute: true,
            //     },
            //   ]
            // },
            {
              id: 'amoeba_manager_sharing',
              title: '管理分摊',
              href: '/amoeba/managersharing',
              component: SharingCostContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: 'amoeba_installation_sharing',
              title: '资产分摊',
              href: '/amoeba/installationsharing',
              component: InstallSharingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: "amoeba_depreciation_rule",
              title: '资产折旧规则',
              href: '/amoeba/depreciationrule',
              component: DepreciationRuleContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: 'amoeba_service_sharing',
              title: '服务分摊',
              href: '/amoeba/servicesharing',
              component: ServiceSharingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: 'amoeba_staff_sharing',
              title: '人员分摊',
              href: '/amoeba/staffsharing',
              component: StaffSharingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            // {
            //   id: 'amoeba_other_sharing',
            //   title: '其他内部结算',
            //   href: '/amoeba/othersharing',
            //   component: OtherSharingContainer,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            // },
          ]
        }, {
          id: 'amoeba_buget',
          title: '阿米巴预算',
          href: '/amoeba',
          component: AnnualBudgetContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          children: [
            {
              id: 'amoeba_plan',
              title: '年度预算',
              href: '/amoeba/plan',
              component: AnnualBudgetContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: 'amoeba_monthly_plan',
              title: '月度预算',
              href: '/amoeba/monthlyplan',
              component: MonthBudgetContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
            },
            {
              id: 'amoeba_service_budget',
              title: '服务预算价格',
              href: '/amoeba/servicebudget',
              component: ServiceBudgetContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: "amoeba_plan_variable",
              title: '变动费预算',
              href: '/amoeba/variable',
              component: PricingContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
              checkroute: true,
              children: [{
                id: "amoeba_plan_variable_config",
                title: '预算指标配置',
                href: '/amoeba/plan/variable/config',
                component: VariableConfigContainer,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 4,
                checkroute: true,
              },
              {
                id: "amoeba_plan_variable_rule",
                title: '基于产量预算',
                href: '/amoeba/plan/variable/rule',
                component: PricingContainer,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 4,
                checkroute: true,
              },
              {
                id: "amoeba_plan_variable_unify",
                title: '能耗油耗预算',
                href: '/amoeba/plan/variable/unify',
                component: EnergyContainer,
                rootcomponent: App,
                auth: true,
                slide: true,
                level: 4,
              },
              {
                id: 'amoeba_plan_variable_plan',
                title: '独立预算',
                href: '/amoeba/plan/variable/plan',
                component: VariablePlanContainer,
                rootcomponent: App,
                auth: false,
                slide: true,
                level: 4,
              }]
            },
            // {
            //   id: 'amoeba_hr_plan',
            //   title: '人力资源计划',
            //   href: '/amoeba/hrplan',
            //   component: HrPlanContainer,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            // },
            {
              id: 'amoeba_hr_plan',
              title: '固定费',
              href: '/amoeba/nhrplan',
              component: GoalFixedPlanContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            // {
            //   id: 'amoeba_depreciation_plan',
            //   title: '资产折旧计划',
            //   href: '/amoeba/depreciation',
            //   component: DepreciationPlanContainer,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            // },
          ]
        }, {
          id: 'amoeba_report',
          title: '阿米巴报表',
          href: '/amoeba/report',
          component: AmoebaReportContainer,
          rootcomponent: App,
          auth: true,
          slide: true,
          level: 2,
          children: [
            {
              id: 'amoeba_report_daily',
              title: '日常报表',
              href: '/amoeba/reportdaily',
              component: AmoebaReportContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            {
              id: 'amoeba_report_fixed',
              title: '报表修正',
              href: '/amoeba/reportfixed',
              component: AmoebaReportFixedContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            },
            // {
            //   id: 'amoeba_install_report',
            //   title: '阿米巴装置报表',
            //   href: '/amoeba/installreport',
            //   component: AmoebaInstallReportContainer,
            //   rootcomponent: App,
            //   auth: true,
            //   slide: true,
            //   level: 3,
            // }, 
            {
              id: 'amoeba_report_analysis',
              title: '报表分析',
              href: '/amoeba/reportanalysis',
              component: AmoebaAnalysisReportContainer,
              rootcomponent: App,
              auth: true,
              slide: true,
              level: 3,
            }
          ]
        }
      ]
  },

  {
    title: '规则设定组件测试',
    href: '/RuleSetting',
    component: RuleSettingContainer,
    rootcomponent: App,
    auth: true,
    slide: false,
    level: 1,
  },
  {
    id: "crm_management",
    title: APP_MENU_MAIN.CRM_MANAGEMENT,
    Icon: TeamOutlined,
    href: '/crm',
    component: Home,
    rootcomponent: App,
    auth: true,
    slide: true,
    level: 1,
    checkroute: true,
    children: [
      // {
      //   id: "crm_customer_list",
      //   title: '客户档案',
      //   href: '/crm/customer',
      //   component: Customer,
      //   rootcomponent: App,
      //   auth: false,
      //   slide: true,
      //   level: 2,
      //   checkroute: false
      // },
      {
        id: "crm_oppo_list",
        title: '机会管理',
        href: '/crm/oppoList',
        component: Oppo,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
      {
        id: "crm_my_activities",
        title: '我的活动',
        href: '/crm/myactivities',
        component: MyActivitiesContainer,
        auth: true,
        slide: true,
        rootcomponent: App,
        level: 2,
        checkroute: true
      },
      {
        id: "crm_activities",
        title: '活动列表',
        href: '/crm/activities',
        component: ActivitiesContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true
      },
    ]
  },
  {
    id: "general_management",
    title: APP_MENU_MAIN.CONFIGS,
    Icon: ToolOutlined,
    href: '/generalManagement',
    component: Home,
    rootcomponent: App,
    level: 1,
    auth: true,
    slide: true,
    checkroute: true,
    children: [
      {
        id: "org_management",
        title: '组织架构管理',
        href: '/orgManagement',
        component: OrgManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "process_management",
        title: '流程管理',
        href: '/processManagement',
        component: ProcessManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "predefinded_process",
        title: '预设流程',
        href: '/predefined/process',
        component: PredefinedProcess,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "bu_management",
        title: '业务分类管理',
        href: '/buManagement',
        component: BuManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "job_management",
        title: '职务管理',
        href: '/jobManagement',
        component: JobManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "user_management",
        title: '用户管理',
        href: '/userManagement',
        component: UserManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "role_management",
        title: '角色管理',
        href: '/roleManagement',
        component: RoleManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "notice_management",
        title: '通知管理',
        href: '/noticeManagement',
        component: NoticeManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "holiday_management",
        title: '节假日管理',
        href: '/holidayManagement',
        component: HolidayManagement,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "payment_record",
        title: '返聘工资管理',
        href: '/paymentRecord',
        component: PaymentRecord,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "labour_payment_config",
        title: '劳务工资管理',
        href: '/labourPaymentConfig',
        component: LabourPaymentConfigContainer,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      {
        id: "labour_payment",
        title: '劳务工资记录',
        href: '/labourPayment',
        component: LabourPayment,
        rootcomponent: App,
        level: 2,
        auth: true,
        slide: true,
        checkroute: true
      },
      // {
      //   id: "manufacture_shiftrule_config",
      //   title: '排班设置',
      //   href: '/manufacture/shiftrule/config',
      //   component: ShiftRuleListContainer,
      //   rootcomponent: App,
      //   auth: true,
      //   slide: true,
      //   level: 2,
      //   checkroute: true,
      // },
      {
        id: 'amoeba_index',
        title: '阿米巴指标管理',
        href: '/amoeba/index',
        component: IndexContainer,
        rootcomponent: App,
        auth: true,
        slide: true,
        level: 2,
        checkroute: true,
      },
    ]
  },

  {
    id: "process_info",
    title: '流程详细信息',
    href: '/processManagement/info',
    component: ProcessEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "bu_info",
    title: '业务分类详细信息',
    href: '/buManagement/info',
    component: BuEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "job_info",
    title: '职务详细信息',
    href: '/jobManagement/info',
    component: JobEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "operation_rule_info",
    title: '设备运维规则详细信息',
    href: '/operationRule/info/:ruleId',
    component: RuleDetailContainer,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "operation_rule_setup",
    title: '设备运维规则对象设置',
    href: '/operationRule/setup/:type',
    component: RuleCatSetup,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  // {
  //   id: "operation_acc_rule_setup",
  //   title: '设备运维规则部件对象设置',
  //   href: '/operationRule/accsetup',
  //   component: OperationAccRuleSetup,
  //   rootcomponent: App,
  //   auth: true,
  //   slide: false,
  //   checkroute: true
  // },
  {
    id: 'device_insurance_info',
    title: '车辆保险明细',
    href: '/devices/insurances/info/:insId',
    component: InsuranceEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "user_info",
    title: '用户详细信息',
    href: '/userManagement/info',
    component: UserEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "notice_config_target",
    title: '通知配置对象',
    href: '/noticeManagement/target',
    component: NoticeConfigTarget,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "danger_checklist_info",
    title: '风险研判检查表详细信息',
    href: '/danagerchecklistManagement/danger/:inventoryId',
    component: DangerChecklistItems,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "danger_checklist_info",
    title: '风险研判检查表项详细信息',
    href: '/danagerchecklistManagement/danager/info',
    component: DangerChecklistEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "checklist_info",
    title: '检查表详细信息',
    href: '/checklistManagement/inventory/:inventoryId',
    component: ChecklistItems,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "checklist_info",
    title: '检查表项详细信息',
    href: '/checklistManagement/info',
    component: ChecklistEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "monthly_plan_info",
    title: '隐患排查计划详细信息',
    href: '/inspection/monthlyplan/info/:dtlId',
    component: MonthlyPlanEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "monthly_plan_record",
    title: '隐患排查计划排查记录',
    href: '/inspection/monthlyplan/record/:dtlId',
    component: MonthlyPlanRecords,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "risk_investigation_info",
    title: '风险研判计划详细信息',
    href: '/inspection/riskinvestigation/info/:dtlId',
    component: RiskInvestigationEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "risk_investigation_record",
    title: '风险研判计划排查记录',
    href: '/inspection/riskinvestigation/record/:dtlId',
    component: RiskInvestigationRecords,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "inspection_hazardlist_info",
    title: '隐患台账详情治理',
    href: '/inspection/hazardlist/info/:hid/:mode',
    component: HazardInfo,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "fives_plan_info",
    title: '5S检查计划详细信息',
    href: '/inspection/5splan/info/:dtlId',
    component: FiveSPlanEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "fives_plan_record",
    title: '5S检查计划排查记录',
    href: '/inspection/5splan/record/:dtlId',
    component: FiveSPlanRecords,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "role_group_info",
    title: '用户组详细信息',
    href: '/roleManagement/groupInfo',
    component: RoleGroupEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "role_info",
    title: '角色详细信息',
    href: '/roleManagement/roleInfo',
    component: RoleEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "icon",
    title: 'Icon',
    href: '/lab/icon',
    component: Icon,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: false
  },
  {
    id: "dialog",
    title: 'Dialog',
    href: '/lab/dialog',
    component: Dialog,
    rootcomponent: App,
    level: 1,
    auth: true,
    slide: false,
    checkroute: false
  },
  {
    id: "threed",
    title: '3D Demo',
    href: '/lab/threed',
    component: ThreeD,
    rootcomponent: App,
    level: 1,
    auth: true,
    slide: false,
    checkroute: false
  },
  /*规章制度计划第三版*/
  {
    id: "leadership_regulationplandetail",
    title: '计划详情',
    href: '/leadership/regulationplan/regulationplandetail',
    component: RegulationPlan,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "leadership_regulationplandetailall",
    title: '计划详情',
    href: '/leadership/regulationplan/regulationplandetailall',
    component: RegulationPlanAll,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "leadership_regulationplan_list",
    title: '计划详情',
    href: '/leadership/regulationplan/list',
    component: RegulationPlanList,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "leadership_tempregulationplancreate",
    title: '临时计划创建',
    href: '/leadership/temporaryplan/temporaryregulationplaninfo',
    component: TemporaryRegulationPlanInfo,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "leadership_tempregulationplanexecution",
    title: '临时计划审核',
    href: '/leadership/temporaryplan/temporaryregulationplanexecution',
    component: TemporaryRegulationPlanExecution,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: true
  },
  {
    id: "crm_oppo_info",
    title: '客户机会详细信息',
    href: '/crm/oppoInfo',
    component: OppoInfo,
    rootcomponent: App,
    auth: true,
    slide: false,
    checkroute: false
  },
  {
    id: 'supplier_info',
    title: '承包商列表明细',
    href: '/devices/suppliers/info/:supplierId',
    component: SupplierEdit,
    rootcomponent: App,
    auth: true,
    slide: false,
    level: 2,
    checkroute: false
  },

  // {
  //   id: "crm_customer_info",
  //   title: '客户明细',
  //   href: '/crm/customer/detail/:cid',
  //   component: CustomerInfoContainer,
  //   rootcomponent: App,
  //   auth: false,
  //   slide: false,
  //   checkroute: false
  // },
];
