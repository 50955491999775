import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'
import { csv } from '../../../utils/exportfile';
import _ from 'lodash'

const porxyApi = '/api';
const basePath = porxyApi + '/amoeba';
const initialState = {
    records: [],
};

export const setConfigs = createAction('@@xcloud/amoeba/setConfigs');

export const getConfigs = createAction('@@xcloud/amoeba/getConfigs',
    (companyId, dptId, userId) =>
        (dispatch, getState, httpClient) => {
            let url = basePath + "/laborconfigs";
            if (companyId || dptId || userId) {
                let strs = [];
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                if (userId) strs.push('userId=' + userId)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setConfigs(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取劳务工资配置列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const importConfigs = createAction('@@xcloud/amoeba/importConfigs',
    (dto, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(basePath + "/laborconfigs/import", dto).then(
                (res) => {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "导入劳务工资配置成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `导入劳务工资配置失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const updateConfig = createAction('@@xcloud/amoeba/updateConfig',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.patch(basePath + "/laborconfigs/" + record.configId, record).then(
                (res) => {
                    if (res && callback)
                    callback(res.data)
                    dispatch(createhttpMessage(res, "更新劳务工资配置成功"))
                    return res.status;
                }).catch(err => {
                    let msg = `更新劳务工资配置失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const exportConfigTemplate = createAction('@@xcloud/payment/exportConfigTemplate',
        (companyId) =>
            (dispatch, getState, httpClient) => {
                let url = porxyApi + '/users?userType=3&offset=0&size=0&companyId=' + companyId
                return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                    if (res.status === 200) {
                        let fileName = '劳务人员工资配置'
                        let users = _.sortBy(res.data.list, function(o) { return o.dptId; });
                        let data = [];
                        let columns = ['公司ID', '部门ID', '姓名', '手机号', '日工资', '保险费用', '餐补', '管理费用']
                        for (let i = 0; i < users.length; i++) {
                            let cell = [
                                companyId,
                                users[i].dptId,
                                users[i].lastName + users[i].firstName,
                                users[i].phone,
                                0, 0, 0,0
                            ];
                            data.push(cell)
                        }
                        dispatch(csv(fileName, columns, data));
                    }
                    return res.status;
                }).catch(err => {
                    let msg = `获取劳务人员工资配置失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
            });
    
    

export const labourReducer = handleActions(
    {
        [setConfigs]: (state, { payload: data }) => {
            return {
                ...state,
                records: data
            }
        },
    },
    initialState
)

export default labourReducer
