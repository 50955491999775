import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api';
const basePath = porxyApi + '/amoeba/servicebudgets';
const initialState = {
    serviceBudgets: [],//服务预算
    budgetHistories: [],//服务预算
};

export const setServiceBudgets = createAction('@@xcloud/amoeba/setServiceBudgets');
export const setBudgetHistories = createAction('@@xcloud/amoeba/setBudgetHistories');

export const updateServiceBudget = createAction('@@xcloud/manufacture/updateServiceBudget',
    (updateDto, callBack) => (dispatch, getState, httpClient) => {
        return httpClient.patch(basePath + '/' + updateDto.budgetId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新服务预算成功"));
                    if (callBack)
                        callBack();
                }
                return res.status;
            }).catch(err => {
                let msg = `更新服务预算失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getServiceBudgets = createAction('@@xcloud/amoeba/getServiceBudgets',
    (serviceId, companyId, valuationType, subFlg) =>
        (dispatch, getState, httpClient) => {
            let url = basePath;
            let strs = []
            if (serviceId || companyId || valuationType || subFlg) {
                if (serviceId) strs.push('serviceId=' + serviceId)
                if (companyId) strs.push('companyId=' + companyId)
                if (valuationType) strs.push('valuationType=' + valuationType)
                if (subFlg) strs.push('subFlg=' + subFlg)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    dispatch(setServiceBudgets(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取服务预算列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getServiceBudgetHistories = createAction('@@xcloud/amoeba/getServiceBudgetHistories',
    (serviceId, variableSub, cate) =>
        (dispatch, getState, httpClient) => {
            dispatch(setBudgetHistories([]))
            let url = basePath + '/history';
            let strs = []
            if (serviceId || variableSub || cate) {
                if (serviceId) strs.push('serviceId=' + serviceId)
                if (variableSub) strs.push('variableSub=' + variableSub)
                if (cate) strs.push('cate=' + cate)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setBudgetHistories(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取服务预算列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const serviceBudgetReducer = handleActions(
    {
        [setServiceBudgets]: (state, { payload: data }) => {
            return {
                ...state,
                serviceBudgets: data
            }
        },
        [setBudgetHistories]: (state, { payload: data }) => {
            return {
                ...state,
                budgetHistories: data
            }
        },
    },
    initialState
)

export default serviceBudgetReducer
