import { createAction, handleActions } from 'redux-actions';
import { createMessage, createhttpMessage } from './message';
import { setAccessoryDeviceId } from './devices';
import { formSelectTree } from '../../utils/constant';
import { getCompanyId } from "../../utils/userUtils";
import { push } from 'connected-react-router';
import * as _ from 'lodash';
const porxyApi = '/api'

const initialState = {
    searchAccessorylist: {
        total: 0,
        list: []
    },
    deviceAccessoryList: [],
    search: {
        cat: undefined,
        // 设备类别
        devCat: undefined,
        companyId: undefined,
        isCommon: undefined,
        status: undefined,
        size: '',
    },
    currentAccessory: {},
    accessoryError: {},
    view: false,
    accessoryChange: false,
    posTypeList: [],
    posTypeTree: [],
    posKeyTypeList: [],
    posKeyTypeTree: [],
    catList: [],
    catTree: [],
    deviceParts: [],
    currentDevicePart: {},
    devicePartError: {},
    devicePartChange: false,
    devicePartEditOpen: false,
    workOrderAccessoryList: []
}

export const initCreate = createAction(
    '@@xcloud/accessory/initCreate',
    () => (dispatch, getState) => {
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let accessory = {
            accId: 0,
            accName: '',
            deviceId: null,
            deviceName: '',
            cat: undefined,
            accNo: '',
            specs: '',
            model: '',
            material: '',
            manufacturer: '',
            installPos: '',
            posType: [],
            isCommon: undefined,
            vulnerable: undefined,
            serviceLife: '',
            drawing: '',
            weight: '',
            enableTime: null,
            amount: '',
            companyId: companyId,
        }
        let accessoryError = {
            accName: '',
            cat: '',
            companyId: '',
            isCommon: '',
            serviceLife: '',
            weight: '',
            amount: '',
            accNo: '',
            model: '',
            material: '',
            manufacturer: '',
            vulnerable: '',
        };

        dispatch(setCurrentAccessory(accessory))
        dispatch(setAccessoryError(accessoryError));
        dispatch(setAccessoryChange(false));
        dispatch(setAccessoryView(false));
        dispatch(push('/accessoryManagement/info'));
    }
);

export const copyInitEdit = createAction(
    '@@xcloud/accessory/copyInitEdit',
    (id) => (dispatch, getState) => {
        let accessorys = getState().accessory.searchAccessorylist;
        let accessory = _.find(accessorys.list, ['accId', id])
        accessory.accId = 0
        accessory.accNo = ''

        let accessoryError = {
            accName: '',
            cat: '',
            companyId: '',
            isCommon: '',
            serviceLife: '',
            weight: '',
            amount: '',
            accNo: '',
            model: '',
            material: '',
            manufacturer: '',
            vulnerable: '',
        };

        if (accessory.isCommon !== false && accessory.isCommon !== true) {
            accessory.isCommon = undefined
        }
        if (accessory.vulnerable !== false && accessory.vulnerable !== true) {
            accessory.vulnerable = undefined
        }
        if (!accessory.serviceLife) {
            accessory.serviceLife = ''
        }
        if (!accessory.amount) {
            accessory.amount = ''
        }
        if (!accessory.weight) {
            accessory.weight = ''
        }
        if (!accessory.deviceId) {
            accessory.deviceName = ''
        }
        dispatch(setCurrentAccessory(accessory))
        dispatch(setAccessoryError(accessoryError));
        dispatch(setAccessoryChange(false));
        dispatch(setAccessoryView(false));
        dispatch(push('/accessoryManagement/info'));
    }
);


export const initUpdate = createAction(
    '@@xcloud/accessory/initUpdate',
    (id) => (dispatch, getState) => {
        let accessorys = getState().accessory.searchAccessorylist;
        let accessory = _.find(accessorys.list, ['accId', id])
        let accessoryError = {
            accName: '',
            cat: '',
            companyId: '',
            isCommon: '',
            serviceLife: '',
            weight: '',
            amount: '',
            accNo: '',
            model: '',
            material: '',
            manufacturer: '',
            vulnerable: '',
        };

        if (accessory.isCommon !== false && accessory.isCommon !== true) {
            accessory.isCommon = undefined
        }
        if (accessory.vulnerable !== false && accessory.vulnerable !== true) {
            accessory.vulnerable = undefined
        }
        if (!accessory.serviceLife) {
            accessory.serviceLife = ''
        }
        if (!accessory.amount) {
            accessory.amount = ''
        }
        if (!accessory.weight) {
            accessory.weight = ''
        }
        if (!accessory.deviceId) {
            accessory.deviceName = ''
        }
        dispatch(setCurrentAccessory(accessory))
        dispatch(setAccessoryError(accessoryError));
        dispatch(setAccessoryChange(false));
        dispatch(setAccessoryView(false));
        dispatch(push('/accessoryManagement/info'));
    }
);



export const initView = createAction(
    '@@xcloud/accessory/initView',
    (id) => (dispatch, getState) => {
        let accessorys = getState().accessory.searchAccessorylist;
        let accessory = _.find(accessorys.list, ['accId', id])
        let accessoryError = {
            accName: '',
            cat: '',
            companyId: '',
            isCommon: '',
            serviceLife: '',
            weight: '',
            amount: '',
            accNo: '',
            model: '',
            material: '',
            manufacturer: '',
            vulnerable: '',
        };

        if (accessory.isCommon !== false && accessory.isCommon !== true) {
            accessory.isCommon = undefined
        }
        if (accessory.vulnerable !== false && accessory.vulnerable !== true) {
            accessory.vulnerable = undefined
        }
        if (!accessory.serviceLife) {
            accessory.serviceLife = ''
        }
        if (!accessory.amount) {
            accessory.amount = ''
        }
        if (!accessory.weight) {
            accessory.weight = ''
        }
        if (!accessory.deviceId) {
            accessory.deviceName = ''
        }
        dispatch(setCurrentAccessory(accessory))
        dispatch(setAccessoryError(accessoryError));
        dispatch(setAccessoryChange(false));
        dispatch(setAccessoryView(true));
        dispatch(push('/accessoryManagement/info'));
    }
);


export const initAccessoryHeader = createAction('@@xcloud/accessory/initAccessoryHeader',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(resetSearch()),
            dispatch(getAccessoryCategory()),
            dispatch(getAccessoryList(0, 10, "-update_time", "", "", "", "", ""))
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化部件搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });

export const initAccessoryList = createAction('@@xcloud/accessory/initAccessoryList',
    () => (dispatch, getState) => {
        // 从设备列表检索当前设备

        let device = getState().devices.currentDevice;
        Promise.all([
            dispatch(getAccessoryCategory()),
            dispatch(setAccessoryDeviceId(device.deviceId)),
            dispatch(getDeviceAccessorylist(device.deviceId)),
        ]).then(() => {
        }).catch(err => {
            let msg = '部件加载失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    });




export const resetSearch = createAction('@@xcloud/accessory/resetSearch',
    () => (dispatch, getState, httpClient) => {
        let search = getState().accessory.search;
        let newSearch = {
            ...search,
            cat: undefined,
            // 设备类别
            devCat: undefined,
            companyId: undefined,
            isCommon: undefined,
            status: undefined,
            search: "",
        }
        dispatch(setSearch(newSearch));
    });


export const getAccessoryList = createAction(
    '@@xcloud/accessory/getAccessoryList',
    (offset, size, sort, cat, search, companyId, isCommon, status, devCat) => (dispatch, getState, httpClient) => {
        let searchCond = getState().accessory.search;
        //条件检索
        cat = searchCond.cat ? searchCond.cat.value : cat
        // 设备类别
        devCat = searchCond.devCat ? searchCond.devCat.value : devCat
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        search = searchCond.search ? searchCond.search : search
        companyId = searchCond.companyId ? searchCond.companyId : company
        isCommon = searchCond.isCommon ? searchCond.isCommon.value : isCommon
        status = searchCond.status ? searchCond.status.value : status
        size = searchCond.size ? searchCond.size : size
        let url = "/accessorys"
        if (offset && offset !== "") {
            url += "?offset=" + offset
        } else {
            url += "?offset=0"
        }
        if (cat && cat !== "") {
            url += "&cat=" + cat
        }
        // 设备类别
        if (devCat && devCat !== "") {
            url += "&devCat=" + devCat
        }
        if (search && search !== "") {
            url += "&search=" + encodeURIComponent(search)
        }
        if (companyId && companyId !== "") {
            url += "&companyId=" + companyId
        }
        if (_.isInteger(size)) {
            url += "&size=" + size
        }
        if (sort && sort !== "") {
            url += "&sort=" + sort
        } else {
            url += "&sort=-update_time"
        }
        if (isCommon === true || isCommon === false) {
            url += "&isCommon=" + isCommon
        }
        if (status && status !== "") {
            url += "&status=" + status
        }

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
            }).catch(err => {
                let msg = `按照条件查找部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const getDeviceAccessorylist = createAction(
    '@@xcloud/accessory/getDeviceAccessorylist',
    (deviceId, params) => (dispatch, getState, httpClient) => {

        let path = porxyApi + `/accessorys/${deviceId}`

        // 如果传入参数不为空
        if (params && params.status) {
            path = path + "?status=" + params.status
        }

        return httpClient
            .get(path, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
            }).catch(err => {
                let msg = `按照条件查找部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const accessoryReplace = createAction(
    '@@xcloud/accessory/accessoryReplace',
    (param) => (dispatch, getState, httpClient) => {
        return httpClient
            .patch(porxyApi + `/accessoryreplace`, param, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更换部件成功'))
                    //重新检索列表
                    // dispatch(getDeviceAccessorylist(param.deviceId))
                    return res.data;
                }
            }).catch(err => {
                let msg = `更换部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const changeAccessoryStatus = createAction(
    '@@xcloud/accessory/changeAccessoryStatus',
    (accessoryDto, status) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.accId = accessoryDto.accId
        dto.tenantId = accessoryDto.tenantId
        dto.status = status
        return httpClient
            .patch(porxyApi + `/accessorys`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除部件成功'))
                    // 重新检索部件列表
                    dispatch(getAccessoryList(0, 10, "-update_time", "", "", "", "", ""))
                    // 重新检索设备部件列表
                    dispatch(getDeviceAccessorylist(getState().devices.currentDevice.deviceId));
                    return res.data;
                }
            }).catch(err => {
                let msg = `删除部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const accessoryRelevance = createAction(
    '@@xcloud/accessory/accessoryRelevance',
    (accessoryDto, status) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.accId = accessoryDto.accId ? accessoryDto.accId : undefined
        dto.accName = accessoryDto.accName ? accessoryDto.accName : undefined
        dto.accNo = accessoryDto.accNo ? accessoryDto.accNo : undefined
        dto.cat = accessoryDto.cat ? accessoryDto.cat : undefined
        dto.specs = accessoryDto.specs ? accessoryDto.specs : undefined
        dto.deviceId = accessoryDto.deviceId ? accessoryDto.deviceId : undefined
        dto.material = accessoryDto.material ? accessoryDto.material : undefined
        dto.manufacturer = accessoryDto.manufacturer ? accessoryDto.manufacturer : undefined
        dto.installPos = accessoryDto.installPos ? accessoryDto.installPos : undefined
        dto.drawing = accessoryDto.drawing ? accessoryDto.drawing : undefined
        dto.isCommon = accessoryDto.isCommon ? accessoryDto.isCommon : undefined
        dto.vulnerable = accessoryDto.vulnerable ? accessoryDto.vulnerable : undefined
        dto.model = accessoryDto.model ? accessoryDto.model : undefined
        dto.enableTime = accessoryDto.enableTime ? new Date(accessoryDto.enableTime) : new Date('1000-01-01')
        let companyId = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        dto.companyId = companyId
        dto.amount = accessoryDto.amount ? parseInt(accessoryDto.amount) : undefined
        dto.serviceLife = accessoryDto.serviceLife ? parseInt(accessoryDto.serviceLife) : undefined
        dto.weight = accessoryDto.weight ? parseFloat(accessoryDto.weight) : undefined
        dto.posType = accessoryDto.posType ? accessoryDto.posType : undefined
        dto.status = 'active'
        return httpClient
            .patch(porxyApi + `/relevanceaccessory`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '关联部件成功'))
                    //重新检索列表
                    dispatch(getDeviceAccessorylist(accessoryDto.deviceId))
                    return res.data;
                }
            }).catch(err => {
                let msg = `关联部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const initAccessoryEdit = createAction('@@xcloud/accessory/initAccessoryEdit',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getAccessoryCategory()),
            dispatch(getAccessoryPosType()),
            dispatch(getAccessoryPosKeyType()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化部件详情失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    });

export const getAccessoryCategory = createAction('@@xcloud/accessory/getAccessoryCategory',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ACCDEVCAT";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '部件类别层级结构获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });


export const backToList = createAction('@@xcloud/accessory/backToList',
    () => (dispatch, getState) => {
        dispatch(push('/accessoryList'));
    });
export const backToDeviceList = createAction('@@xcloud/accessory/backToDeviceList',
    () => (dispatch, getState) => {
        dispatch(push('/deviceList'));
    });


export const insertAccessory = createAction(
    '@@xcloud/accessory/insertAccessory',
    (accessoryDto) => (dispatch, getState, httpClient) => {
        console.log('accessoryDto', accessoryDto)
        let company = getCompanyId(getState().auth.currentUserInfo.roleId, getState().auth.userProfile)
        let dto = {}
        dto.accName = accessoryDto.accName ? accessoryDto.accName : undefined
        dto.accNo = accessoryDto.accNo ? accessoryDto.accNo : undefined
        dto.cat = _.isObject(accessoryDto.cat) ? accessoryDto.cat.value : accessoryDto.cat
        dto.specs = accessoryDto.specs ? accessoryDto.specs : undefined
        dto.deviceId = accessoryDto.deviceId ? accessoryDto.deviceId : undefined
        dto.material = accessoryDto.material ? accessoryDto.material : undefined
        dto.manufacturer = accessoryDto.manufacturer ? accessoryDto.manufacturer : undefined
        dto.installPos = _.isObject(accessoryDto.installPos) ? accessoryDto.installPos.value : accessoryDto.installPos
        dto.drawing = accessoryDto.drawing ? accessoryDto.drawing : undefined
        dto.isCommon = _.isObject(accessoryDto.isCommon) ? accessoryDto.isCommon.value : accessoryDto.isCommon
        dto.vulnerable = _.isObject(accessoryDto.vulnerable) ? accessoryDto.vulnerable.value : accessoryDto.vulnerable
        dto.model = accessoryDto.model ? accessoryDto.model : undefined
        dto.enableTime = accessoryDto.enableTime ? new Date(accessoryDto.enableTime) : new Date('1000-01-01')
        dto.companyId = accessoryDto.companyId ? accessoryDto.companyId : company
        dto.amount = accessoryDto.amount ? parseInt(accessoryDto.amount) : undefined
        dto.serviceLife = accessoryDto.serviceLife ? parseInt(accessoryDto.serviceLife) : undefined
        dto.weight = accessoryDto.weight ? parseFloat(accessoryDto.weight) : undefined
        dto.posType = _.isObject(accessoryDto.posType) ? accessoryDto.posType.value : accessoryDto.posType


        return httpClient
            .post(porxyApi + `/accessorys`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    let newAccessory = {
                        ...getState().accessory.currentAccessory,
                        accId: res.data.accId,
                        tenantId: res.data.tenantId,
                    };
                    dispatch(setCurrentAccessory(newAccessory))
                    dispatch(createhttpMessage({ status: 200 }, '创建部件成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `创建部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const modifyAccessory = createAction(
    '@@xcloud/accessory/modifyAccessory',
    (accessoryDto) => (dispatch, getState, httpClient) => {

        let dto = {}
        dto.accName = accessoryDto.accName ? accessoryDto.accName : undefined
        dto.accNo = accessoryDto.accNo ? accessoryDto.accNo : undefined
        dto.cat = _.isObject(accessoryDto.cat) ? accessoryDto.cat.value : accessoryDto.cat
        dto.specs = accessoryDto.specs ? accessoryDto.specs : undefined
        dto.deviceId = accessoryDto.deviceId ? accessoryDto.deviceId : undefined
        dto.material = accessoryDto.material ? accessoryDto.material : undefined
        dto.manufacturer = accessoryDto.manufacturer ? accessoryDto.manufacturer : undefined
        dto.installPos = _.isObject(accessoryDto.installPos) ? accessoryDto.installPos.value : accessoryDto.installPos
        dto.drawing = accessoryDto.drawing ? accessoryDto.drawing : undefined
        dto.isCommon = _.isObject(accessoryDto.isCommon) ? accessoryDto.isCommon.value : accessoryDto.isCommon
        dto.isVulnerable = _.isObject(accessoryDto.isVulnerable) ? accessoryDto.isVulnerable.value : accessoryDto.isVulnerable
        dto.model = accessoryDto.model ? accessoryDto.model : undefined
        if (accessoryDto.enableTime)
            dto.enableTime = accessoryDto.enableTime ? new Date(accessoryDto.enableTime) : new Date('1000-01-01')
        if (accessoryDto.disableTime)
            dto.disableTime = accessoryDto.disableTime ? new Date(accessoryDto.disableTime) : new Date('1000-01-01')
        dto.amount = accessoryDto.amount ? parseInt(accessoryDto.amount) : undefined
        dto.serviceLife = accessoryDto.serviceLife ? parseInt(accessoryDto.serviceLife) : undefined
        dto.weight = accessoryDto.weight ? parseFloat(accessoryDto.weight) : undefined
        dto.status = accessoryDto.status ? accessoryDto.status : undefined
        dto.tenantId = accessoryDto.tenantId
        dto.accId = accessoryDto.accId
        dto.posType = _.isObject(accessoryDto.posType) ?
            (accessoryDto.posType.value ? accessoryDto.posType.value : undefined)
            : (accessoryDto.posType ? accessoryDto.postType : undefined)
        if (accessoryDto.orderId)
            dto.orderId = accessoryDto.orderId
        if (accessoryDto.dtlId)
            dto.dtlId = accessoryDto.dtlId
        return httpClient
            .patch(porxyApi + `/accessorys`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新部件成功'))
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `更新部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const getAccessoryPosType = createAction('@@xcloud/accessory/getAccessoryPosType',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ACCPOSTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '部件安装部位类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getAccessoryPosKeyType = createAction('@@xcloud/accessory/getAccessoryPosKeyType',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/device/dictionaries?type=ACCPOSKEYTYPE";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { list: res.data, tree: formSelectTree(res.data, false) };
                }
                return res;
            }).catch(err => {
                let msg = '部件安装部位关键类别获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });
export const getAccessoryByWorkOrder = createAction(
    '@@xcloud/devicePart/getAccessoryByWorkOrder',
    (orderId) => (dispatch, getState, httpClient) => {
        let url = `/device/workorders/${orderId}/accessories`
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setWorkorderAccessoryList(res.data))
                    return res.data;
                }
            }).catch(err => {
                let msg = `按部件ID查找失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);

export const initDevicePartList = createAction('@@xcloud/accessory/initDevicePartList',
    () => (dispatch, getState) => {
        // 从设备列表检索当前设备
        let device = getState().devices.currentDevice;
        if (!device.deviceId) {
            return;
        }
        Promise.all([
            dispatch(getAccessoryCategory()),
            dispatch(getDevicePartList({ 'deviceId': device.deviceId })),
        ]).then(() => {
        }).catch(err => {
            let msg = '部件加载失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    });

export const getDevicePart = createAction(
    '@@xcloud/devicePart/getDevicePart',
    (partId) => (dispatch, getState, httpClient) => {
        let url = "/deviceparts/parts/" + partId
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setDevicePart(res.data))
                    return res.data;
                }
            }).catch(err => {
                let msg = `按部件ID查找失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);
export const getDevicePartList = createAction(
    '@@xcloud/devicePart/getDevicePartList',
    (query) => (dispatch, getState, httpClient) => {

        let url = "/deviceparts/parts?"
        let strs = []
        if (query.companyId && query.companyId !== "") {
            strs.push("companyId=" + query.companyId)
        }
        if (query.deviceId && query.deviceId !== "") {
            strs.push("deviceId=" + query.deviceId)
        }
        if (query.connectId && query.connectId !== "") {
            strs.push("connectId=" + query.connectId)
        }
        if (query.searchName && query.searchName !== "") {
            strs.push("searchName=" + encodeURIComponent(query.searchName))
        }
        if (query.cat && query.cat !== "") {
            strs.push("cat=" + query.cat)
        }
        if (query.isCommon === true || query.isCommon === false) {
            strs.push("isCommon=" + query.isCommon)
        }
        if (query.isVulnerable === true || query.isVulnerable === false) {
            strs.push("isVulnerable=" + query.isVulnerable)
        }
        if (query.isSpare === true || query.isSpare === false) {
            strs.push("isSpare=" + query.isSpare)
        }
        if (query.status && query.status !== "") {
            strs.push("status=" + query.status)
        }
        url = url + strs.join('&')
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setDeviceParts(res.data))
                    return res.data;
                }
            }).catch(err => {
                let msg = `按照条件查找失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })
    }
);


export const createDevicePart = createAction('@@xcloud/devicefrom/createDevicePart',
    (partDto) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/deviceparts/parts`;
        partDto = _.omit(_.pickBy(partDto, (v) => { return !_.isNull(v) }), ['createTime', 'updateTime'])
        // validateForm['formDtlList'] = _.map(form.formDtlList, (d) => { return _.omit(_.pickBy(d), ['createTime', 'updateTime', 'updateVals', 'dtlId']) })
        return httpClient.post(url, partDto)
            .then((res) => {
                dispatch(createMessage('success', '部件创建[' + partDto.partName + ']成功'));
                return res.data;
            })
            .catch(err => {
                let msg = '部门创建失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    });

export const createDeviceParts = createAction('@@xcloud/devicefrom/createDeviceParts',
    (deviceId, partDtos) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/deviceparts/${deviceId}/parts`;
        // let validateForm = _.omit(_.pickBy(form), ['createTime', 'updateTime'])
        // validateForm['formDtlList'] = _.map(form.formDtlList, (d) => { return _.omit(_.pickBy(d), ['createTime', 'updateTime', 'updateVals', 'dtlId']) })
        return httpClient.post(url, partDtos)
            .then((res) => {
                dispatch(createMessage('success', '部件列表创建成功'));
                return res.data;
            })
            .catch(err => {
                let msg = '部门创建失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    });

export const updateDevicePart = createAction(
    '@@xcloud/accessory/updateDevicePart',
    (partDto) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/deviceparts/parts/${partDto.partId}`
        partDto = _.omit(_.pickBy(partDto, (v) => { return !_.isNull(v) }), ['createTime', 'updateTime'])
        return httpClient
            .patch(url, partDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新部件成功'))
                    return res.data;
                }
            }).catch(err => {
                let msg = `更新部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const updateDeviceParts = createAction(
    '@@xcloud/accessory/updateDeviceParts',
    (deviceId, partDtos) => (dispatch, getState, httpClient) => {
        let url = porxyApi + `/deviceparts/${deviceId}/parts`
        return httpClient
            .patch(url, partDtos, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '更新部件列表成功'))
                    return res.data;
                }
            }).catch(err => {
                let msg = `更新部件列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const partRelevanceAccessory = createAction(
    '@@xcloud/accessory/partRelevanceAccessory',
    (deviceId, partId, accId, accDto) => (dispatch, getState, httpClient) => {
        accDto = _.omit(_.pickBy(accDto), ['createTime', 'updateTime'])
        let url = porxyApi + `/deviceparts/${deviceId}/parts/${partId}/relevance/${accId}`
        return httpClient
            .patch(url, accDto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '关联部件成功'))
                    return res.data;
                }
            }).catch(err => {
                let msg = `关联部件失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const setSearch = createAction('@@xcloud/accessory/setSearch');

export const setCurrentAccessory = createAction('@@xcloud/accessory/setCurrentAccessory');

export const setAccessoryView = createAction('@@xcloud/accessory/setAccessoryView');

export const setAccessoryError = createAction('@@xcloud/accessory/setAccessoryError');

export const setAccessoryChange = createAction('@@xcloud/accessory/setAccessoryChange');

export const setWorkorderAccessoryList = createAction('@@xcloud/accessory/setWorkorderAccessoryList');

export const setDeviceParts = createAction('@@xcloud/accessory/setDeviceParts');
export const setDevicePart = createAction('@@xcloud/accessory/setDevicePart');
export const setCurrentDevicePart = createAction('@@xcloud/accessory/setCurrentDevicePart');
export const setDevicePartChange = createAction('@@xcloud/accessory/setDevicePartChange');
export const setDevicePartError = createAction('@@xcloud/accessory/setDevicePartError');
export const setDevicePartEditOpen = createAction('@@xcloud/accessory/setDevicePartEditOpen');

export const accessoryReducer = handleActions(
    {
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [getAccessoryList]: (state, { payload: value }) => {
            return {
                ...state,
                searchAccessorylist: value
            };
        },
        [getDeviceAccessorylist]: (state, { payload: value }) => {
            return {
                ...state,
                deviceAccessoryList: value
            };
        },
        [setCurrentAccessory]: (state, { payload: value }) => {
            return {
                ...state,
                currentAccessory: value
            };
        },
        [setAccessoryView]: (state, { payload: value }) => {
            return {
                ...state,
                view: value
            };
        },
        [setAccessoryError]: (state, { payload: value }) => {
            return {
                ...state,
                accessoryError: value
            };
        },
        [setAccessoryChange]: (state, { payload: value }) => {
            return {
                ...state,
                accessoryChange: value
            };
        },
        [getAccessoryPosType]: (state, { payload: value }) => {
            return {
                ...state,
                posTypeList: value.list,
                posTypeTree: value.tree
            };
        },
        [getAccessoryPosKeyType]: (state, { payload: value }) => {
            return {
                ...state,
                posKeyTypeList: value.list,
                posKeyTypeTree: value.tree
            };
        },
        [getAccessoryCategory]: (state, { payload: value }) => {
            return {
                ...state,
                catList: value.list,
                catTree: value.tree
            };
        },
        [setDeviceParts]: (state, { payload: value }) => {
            return {
                ...state,
                deviceParts: value
            };
        },
        [setCurrentDevicePart]: (state, { payload: value }) => {
            return {
                ...state,
                currentDevicePart: value
            };
        },
        [setDevicePartChange]: (state, { payload: value }) => {
            return {
                ...state,
                devicePartChange: value
            };
        },

        [setDevicePartError]: (state, { payload: value }) => {
            return {
                ...state,
                devicePartError: value
            };
        },
        [setDevicePartError]: (state, { payload: value }) => {
            return {
                ...state,
                devicePartError: value
            };
        },
        [setDevicePartEditOpen]: (state, { payload: value }) => {
            return {
                ...state,
                devicePartEditOpen: value
            };
        },

        [setWorkorderAccessoryList]: (state, { payload: value }) => {
            return {
                ...state,
                workorderAccessoryList: value
            };
        },


    },
    initialState
);

export default accessoryReducer;