import React, { Component } from 'react';
import Signin from '../components/Signin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingPage from '@/containers/LoadingPage';
import { updateloginInfo, signin } from '../redux/modules/auth';
import { cleanloading } from '@/redux/modules/loading';
import _ from 'lodash';

class SigninContainer extends Component {

    static propTypes = {
        loginInfo: PropTypes.any,
        onhandleChange: PropTypes.func,
        onhandleClearTextField: PropTypes.func,
        onhandleUpdateLoginInfo: PropTypes.func,
        onhandleSignin: PropTypes.func,
    }

    componentDidMount = () => {
        // 网络中断、中途刷新等导致loading动画无法关闭的情况
        this.props.onhandleClearLoading();

    }
    handleCheck = (event, field) => {
        // if ((!field || field === 'tenantcode') && this.props.loginInfo.tenantAlias === '') {
        //     const newloginInfo = {
        //         ...this.props.loginInfo,
        //         errorMessage: '请输入租户代码'
        //     };
        //     this.props.onhandleUpdateLoginInfo(newloginInfo);
        // }else 
        if ((!field || field === 'username') && _.trim(this.props.loginInfo.usernameOrEmail) === '') {
            const newloginInfo = {
                ...this.props.loginInfo,
                errorMessage: '请输入用户名或邮箱'
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        } else if ((!field || field === 'password') && this.props.loginInfo.password === '') {
            const newloginInfo = {
                ...this.props.loginInfo,
                errorMessage: '请输入密码'
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        } else {
            const newloginInfo = {
                ...this.props.loginInfo,
                errorMessage: ''
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        }
    };

    handleClearTextField = (event, field) => {
        if (field === 'username') {
            const newloginInfo = {
                ...this.props.loginInfo,
                usernameOrEmail: ''
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        }
        // else if (field === 'tenantcode') {
        //     const newloginInfo = {
        //         ...this.props.loginInfo,
        //         tenantAlias: ''
        //     };
        //     this.props.onhandleUpdateLoginInfo(newloginInfo);
        // }
    };

    handleSignIn = async (event) => {
        await this.handleCheck(event, null);
        if (this.props.loginInfo.errorMessage === '') {
            let directTo = '';
            if (this.props.location.state) {
                directTo = this.props.location.state.from.pathname;
            } else {
                directTo = '/';
            }
            this.props.onhandleSignin(directTo);
        }
    };

    handleChange = (event, field) => {
        // if (field === 'tenantcode') {
        //     const newloginInfo = {
        //         ...this.props.loginInfo,
        //         tenantAlias: event.target.value
        //     };
        //     this.props.onhandleUpdateLoginInfo(newloginInfo);
        // }else 
        if (field === 'username') {
            const newloginInfo = {
                ...this.props.loginInfo,
                usernameOrEmail: event.target.value
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        } else if (field === 'password') {
            const newloginInfo = {
                ...this.props.loginInfo,
                password: event.target.value
            };
            this.props.onhandleUpdateLoginInfo(newloginInfo);
        }
    };

    handleKeyDown = event => {
        switch (event.key) {
            case 'Enter':
                this.handleSignIn(null);
                break
            default: break
        }
    }


    render() {
        return (<>
            <Signin onhandleSignIn={this.handleSignIn.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleClearTextField={this.handleClearTextField.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleKeyDown={this.handleKeyDown.bind(this)}
                loginInfo={this.props.loginInfo} />
            <LoadingPage />
        </>);
    }

}

const mapStateToProps = (state) => {
    return {
        loginInfo: state.auth.loginInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleClearLoading: () => {
            dispatch(cleanloading());
        },
        onhandleUpdateLoginInfo: (loginInfo) => {
            dispatch(updateloginInfo(loginInfo));
        },
        onhandleSignin: (directTo) => {
            dispatch(signin(directTo));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SigninContainer);