/* eslint-disable dot-notation */
import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const trans = '#00000000';

export default {
  black,
  white,
  trans,
  primary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[500],
    light: colors.blue[300]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  warning: {
    contrastText: white,
    dark: colors.amber[900],
    main: colors.amber[600],
    light: colors.amber[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    emphasize: colors.blue[500],
  },
  menuText: {
    normal: colors.blueGrey[300],
    hover: colors.blueGrey[100],
    active: colors.blueGrey[50],
  },
  background: {
    default: colors.grey[100],
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  title: colors.grey[100],
  hover1: colors.grey[300],
  hover2: colors.grey[100],
  selected: 'rgba(33, 150, 243, 0.08)',
  table: {
    header: {
      backgroundColor: colors.grey[100]
    },
    oddRow: {
      backgroundColor: colors.grey[100]
    },
    evenRow: {
      backgroundColor: white
    },
    childTable: {
      // 建议与主题色相似
      backgroundColor: colors.blue[300]
    }
  }
};
