import React from 'react';
import {
    Grid, Typography, TextField, InputAdornment, MenuItem,
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/PhoneBill/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import _ from 'lodash'

const PhoneBillComponent = props => {
    const { classes,
        selectedDate,
        phoneBillList,
        onhandleDateChange,
        onhandleUpdate,
        onhandleUpload,
        onhandleDptChange,
        userProfile,
        onhandleCompanyChange,
        companyId,
        companies,
        departments,
        dptId,
        isMainCompany,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options
    }
    const tableStyle = {
        ...style
    }


    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'manufacture_phone_bill' })
        return permission && permission.action === 'W'
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '部门',
                field: 'dptName',
                editable: 'never',
                render: row => <Typography>{row.dptName ? row.dptName : ''}</Typography>
            },
            {
                title: '用户',
                field: 'userName',
                editable: 'never',
                render: row => <Typography>{row.userName ? row.userName : ''}</Typography>
            },
            {
                title: '电话号',
                field: 'phoneNo',
                editable: 'never',
                render: row => <Typography>{row.phoneNo}</Typography>
            },
            {
                title: '应付金额',
                field: 'actPaid',
                type: 'numeric',
                editable: 'never',
                render: row => <Typography>{row.actPaid}</Typography>
            },
            {
                title: '月租费',
                field: 'item1',
                type: 'numeric',
                render: row => <Typography>{row.item1.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item1 ? props.rowData.item1 : ''}
                        name='item1'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '纯话费合计',
                field: 'item2',
                type: 'numeric',
                render: row => <Typography>{row.item2.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item2 ? props.rowData.item2 : ''}
                        name='item2'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '市话费',
                field: 'item3',
                type: 'numeric',
                render: row => <Typography>{row.item3.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item3 ? props.rowData.item3 : ''}
                        name='item3'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '区间费',
                field: 'item4',
                type: 'numeric',
                render: row => <Typography>{row.item4.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item4 ? props.rowData.item4 : ''}
                        name='item4'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '国内长途',
                field: 'item5',
                type: 'numeric',
                render: row => <Typography>{row.item5.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item5 ? props.rowData.item5 : ''}
                        name='item5'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: 'IP长途',
                field: 'item6',
                type: 'numeric',
                render: row => <Typography>{row.item6.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item6 ? props.rowData.item6 : ''}
                        name='item6'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '国际长途',
                field: 'item7',
                type: 'numeric',
                render: row => <Typography>{row.item7.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        value={props.rowData.item7 ? props.rowData.item7 : ''}
                        name='item7'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },

        ]

        return columns

    }

    const assemblyData = () => {
        return phoneBillList;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container direction="row" className={classes.gridContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                views={["year", "month"]}
                                openTo={"month"}
                                format="yyyy/MM"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={(event) => { }}
                                onMonthChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item className={classes.companySelect}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId || ''}
                        >
                            {companies.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item className={classes.companySelect}>
                        <TextField
                            fullWidth
                            select
                            name='dptId'
                            InputProps={textFieldDefault}
                            onChange={onhandleDptChange}
                            value={dptId || ''}
                        >
                            {departments.map(ele => (
                                <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>电话费</Typography>}
                        actions={checkPermission() ? [
                            {
                                icon: 'backup',
                                tooltip: '导入电话费',
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                    onhandleUpload()
                                }
                            },
                        ] : []}
                        editable={checkPermission() ?
                            {
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        resolve()
                                        onhandleUpdate(newData)
                                    })
                            } : {}
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

PhoneBillComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

PhoneBillComponent.defaultProps = {
    phoneBillList: []
};

export default withStyles(PhoneBillComponent);