import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Typography,
    Button,
    TextField,
    Grid,
    Chip,
    Select
} from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';
import { formatRoleGroupTreeForOperationRole } from '../../../../utils/orgchart';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { getDateByTimestamp, getMoment, getLastDayInYear, getYear } from '../../../../utils/datetime';


const OperationPlanDetail = props => {

    const {
        classes,
        optCategories,
        companyList,
        currentPlanDetail,
        onhandleBack,
        catList,
        accCatList,

        // 基本信息变更处理
        onhandleChange,
        // 角色树形列表
        roleTree,
        // 当前运维计划是否被更改？
        currentPlanDetailChangeFlg,
        // // “下达工单” 按钮处理
        // onhandleOrderBtn,
        // “保存计划” 按钮处理
        onhandleSaveBtn,
        // “保存计划” 按钮处理
        onhandleDeleteBtn,
        // “下达工单” 按钮处理
        onhandleCreateWorkOrder,
        // 用户档案信息
        userProfile,
    } = props;

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    // const renderOptCat = (optCat) => {
    //     if (optCategories && optCategories.length > 0) {
    //         let cat = _.find(optCategories, { 'code': optCat });
    //         return cat.name ? cat.name : "";
    //     } else {
    //         return ""
    //     }
    // }

    const renderCat = (deviceCat) => {
        if (catList && catList.length > 0 && deviceCat) {
            let cat = _.find(catList, { 'code': deviceCat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderAccCat = (accCat) => {
        let label = '';
        if (accCatList && accCatList.length > 0) {
            let value = _.find(accCatList, function (o) { return o.code === accCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderRoleTree = (row) => {
        // console.log(roleTree)
        let newRoleTree = _.find(roleTree, ['companyId', row.companyId])
        let newTree = newRoleTree && newRoleTree.roleGroups ? newRoleTree.roleGroups : null
        return formatRoleGroupTreeForOperationRole(newTree)
    }

    // 渲染计划负责角色
    const renderRoleList = (roles) => {
        let list = []
        if (roles) {
            roles.forEach(role => {
                role.value = role.value ? role.value : role.roleId;
                role.label = role.label ? role.label : role.roleName;
                role.roleId = role.roleId ? role.roleId : role.value;
                role.roleName = role.roleName ? role.roleName : role.label;
                list.push(role)
            })
        }


        return list
    }

    // 渲染计划执行时间
    const renderExecuteTime = (detail) => {
        return detail.executeTime ? getDateByTimestamp(detail.executeTime, true) : '-'
    }

    // 渲染工单（状态）
    const renderWorkOrder = (plan) => {
        if (plan.executeTime) {
            return <Chip
                size="small"
                color="primary"
                label="已下达"
            />
        } else {
            return <Chip
                size="small"
                label="未下达"
            />
        }
    }

    // 渲染状态（计划）
    const renderStatus = (WorkOrder) => {
        let mapping = {
            'new': '新建',
            'active': '有效',
            'inactive': '无效',
            'approved': '批准',
            'finalized': '待验收',
            'done': '完成'
        };

        return mapping[WorkOrder.status]
    }

    // 权限检查
    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_plan' })
        return permission && permission.action === 'W'
    }
    const checkEditPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_plan_edit' })
        return permission && permission.action === 'W'
    }

    // 运维类别 下拉框选项列表
    const optCatOptions = () => (
        <React.Fragment>
            {optCategories ?
                _.sortBy(optCategories, 'seq').map(data => (
                    <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
            }
        </React.Fragment>
    )


    return (
        <div alignitems="center" className={classes.root}>
            <Card className={classes.card}>
                <div style={{ margin: 20 }}>
                    <Typography variant="h3" gutterBottom>
                        运维计划详细
                    </Typography>
                </div>
                <Card className={classes.card}>
                    {
                        // 设备信息
                        currentPlanDetail.device && currentPlanDetail.device.deviceId

                            ?

                            <div style={{ margin: 20 }}>
                                <Typography variant="h4" gutterBottom>
                                    对象信息（设备）
                                </Typography>
                                <div className={classes.root}>
                                    <Grid item xs={12} className={classes.content}>
                                        <Grid container spacing={2} style={{ margin: 20 }}>
                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    设备编号
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.device.deviceNo ? currentPlanDetail.device.deviceNo : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="deviceNo-dev"
                                                className={classes.textField}
                                                variant="standard"
                                                name="deviceNo"
                                                value={currentPlanDetail.device.deviceNo ? currentPlanDetail.device.deviceNo : '-'}
                                                onChange={event => onhandleChange(event, 'deviceNo')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    设备名称
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.device.deviceName ? currentPlanDetail.device.deviceName : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="enName-name"
                                                className={classes.textField}
                                                variant="standard"
                                                name="deviceName"
                                                value={currentPlanDetail.device.deviceName ? currentPlanDetail.device.deviceName : '-'}
                                                onChange={event => onhandleChange(event, 'deviceName')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    设备位号
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.device.tagNo ? currentPlanDetail.device.tagNo : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="tagNo-dev"
                                                className={classes.textField}
                                                variant="standard"
                                                name="tagNo"
                                                value={currentPlanDetail.device.tagNo ? currentPlanDetail.device.tagNo : '-'}
                                                onChange={event => onhandleChange(event, 'tagNo')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    设备类别
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.device.cat ? renderCat(currentPlanDetail.device.cat) : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="cat-dev"
                                                className={classes.textField}
                                                variant="standard"
                                                name="cat"
                                                value={currentPlanDetail.device.cat ? renderCat(currentPlanDetail.device.cat) : '-'}
                                                onChange={event => onhandleChange(event, 'cat')}
                                                disabled={true}
                                            /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            :

                            null
                    }

                    {
                        // 部件信息
                        currentPlanDetail.accessory && currentPlanDetail.accessory.accId

                            ?

                            <div style={{ margin: 20 }}>
                                <Typography variant="h4" gutterBottom>
                                    对象信息（部件）
                                </Typography>
                                <div className={classes.root}>
                                    <Grid item xs={12} className={classes.content}>
                                        <Grid container spacing={2} style={{ margin: 20 }}>
                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    部件编号
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.accNo ? currentPlanDetail.accessory.accNo : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="accNo-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="accNo"
                                                value={currentPlanDetail.accessory.accNo ? currentPlanDetail.accessory.accNo : '-'}
                                                onChange={event => onhandleChange(event, 'accNo')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    部件名称
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.accName ? currentPlanDetail.accessory.accName : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="accName-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="accName"
                                                value={currentPlanDetail.accessory.accName ? currentPlanDetail.accessory.accName : '-'}
                                                onChange={event => onhandleChange(event, 'accName')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    型号
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.model ? currentPlanDetail.accessory.model : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="model-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="model"
                                                value={currentPlanDetail.accessory.model ? currentPlanDetail.accessory.model : '-'}
                                                onChange={event => onhandleChange(event, 'model')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    部件类别
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.cat ? renderAccCat(currentPlanDetail.accessory.cat) : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="cat-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="cat"
                                                value={currentPlanDetail.accessory.cat ? renderAccCat(currentPlanDetail.accessory.cat) : '-'}
                                                onChange={event => onhandleChange(event, 'cat')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    关联设备编号
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.deviceNo ? currentPlanDetail.accessory.deviceNo : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="deviceNo-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="cat"
                                                value={currentPlanDetail.accessory.deviceNo ? currentPlanDetail.accessory.deviceNo : '-'}
                                                onChange={event => onhandleChange(event, 'deviceNo')}
                                                disabled={true}
                                            /> */}
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography gutterBottom>
                                                    关联设备名称
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {currentPlanDetail.accessory.deviceName ? currentPlanDetail.accessory.deviceName : '-'}
                                                </Typography>
                                                {/* <TextField
                                                id="deviceName-acc"
                                                className={classes.textField}
                                                variant="standard"
                                                name="deviceName"
                                                value={currentPlanDetail.accessory.deviceName ? currentPlanDetail.accessory.deviceName : '-'}
                                                onChange={event => onhandleChange(event, 'deviceName')}
                                                disabled={true}
                                            /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            :

                            null
                    }

                </Card>

                <Card className={classes.card}>
                    <div style={{ margin: 20 }}>
                        <Typography variant="h3" gutterBottom>
                            计划内容
                        </Typography>
                        <div className={classes.root}>
                            <Grid item xs={12} className={classes.content}>
                                <Grid container spacing={2} style={{ margin: 20 }}>
                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            计划编号
                                        </Typography>
                                        <Typography gutterBottom>
                                            {currentPlanDetail.optNo ? currentPlanDetail.optNo : '-'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.content}>

                                <Grid container spacing={2} style={{ margin: 20 }}>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            单位
                                        </Typography>
                                        <Typography gutterBottom>
                                            {currentPlanDetail.companyId ? renderCompany(currentPlanDetail.companyId) : '-'}
                                        </Typography>
                                        {/* <TextField
                                        id="companyId-plan"
                                        className={classes.textField}
                                        variant="standard"
                                        name="companyId"
                                        value={currentPlanDetail.companyId ? renderCompany(currentPlanDetail.companyId) : '-'}
                                        onChange={event => onhandleChange(event, 'companyId')}
                                        disabled={true}
                                    /> */}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            运维计划
                                        </Typography>
                                        <Typography gutterBottom>
                                            {currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? currentPlanDetail.rule.ruleName : '临时计划'}
                                        </Typography>
                                        {/* <TextField
                                        id="rule-plan"
                                        className={classes.textField}
                                        variant="standard"
                                        name="rule"
                                        value={currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? currentPlanDetail.rule.ruleName : '临时计划'}
                                        onChange={event => onhandleChange(event, 'rule')}
                                        disabled={true}
                                    /> */}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            运维类别
                                        </Typography>
                                        {/* <Typography gutterBottom>
                                            {currentPlanDetail.rule && currentPlanDetail.rule.cat ? renderOptCat(currentPlanDetail.rule.cat) : '-'}
                                        </Typography> */}
                                        {/* <TextField
                                        id="cat-plan"
                                        className={classes.textField}
                                        variant="standard"
                                        name="cat"
                                        value={currentPlanDetail.rule && currentPlanDetail.rule.cat ? renderOptCat(currentPlanDetail.rule.cat) : '-'}
                                        onChange={event => onhandleChange(event, 'cat')}
                                        disabled={true}
                                        /> */}
                                        <Select
                                            style={{ width: '90%' }}
                                            native
                                            value={currentPlanDetail.optType}
                                            onChange={event => onhandleChange(event, 'optType')}
                                        >
                                            <option aria-label="None" value="" />
                                            {optCatOptions()}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            负责角色
                                        </Typography>
                                        {/* <TextField
                                        id="enName-name"
                                        className={classes.textField}
                                        variant="standard"
                                        name="cat"
                                        value={currentPlanDetail.rule && currentPlanDetail.rule.cat ? renderOptCat(currentPlanDetail.rule.cat) : '-'}
                                        onChange={event => onhandleChange(event, 'cat')}
                                        disabled={disabledTest()}
                                    /> */}
                                        <TreeSelect
                                            showArrow
                                            className={classes.select}
                                            choiceTransitionName="rc-select-selection__choice-zoom"
                                            dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                                            allowClear
                                            treeLine
                                            value={renderRoleList(currentPlanDetail.roles)}
                                            labelInValue
                                            treeData={renderRoleTree(currentPlanDetail)}
                                            treeNodeFilterProp="label"
                                            filterTreeNode={false}
                                            multiple={true}
                                            onChange={event => onhandleChange(event, 'roles')}
                                            disabled={
                                                !checkEditPermission() // 无编辑权限
                                                ||
                                                currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                                                ||
                                                currentPlanDetail.executeTime // 工单已下达
                                            }
                                        />


                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            执行时间
                                        </Typography>
                                        {/* <TextField
                                        id="enName-name"
                                        className={classes.textField}
                                        variant="standard"
                                        name="cat"
                                        value={currentPlanDetail.rule && currentPlanDetail.rule.cat ? renderOptCat(currentPlanDetail.rule.cat) : '-'}
                                        onChange={event => onhandleChange(event, 'cat')}
                                        disabled={disabledTest()}
                                    /> */}
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            locale={zhCN} >
                                            <KeyboardDatePicker
                                                error={Boolean(props.helperText)}
                                                helperText={props.helperText || ''}
                                                autoOk
                                                className={classes.textField}
                                                InputProps={{ readOnly: true, }}
                                                disableToolbar
                                                variant="inline"
                                                format="yyyy/MM/dd"
                                                margin="normal"
                                                name='targetDate'
                                                value={currentPlanDetail.targetDate}
                                                onChange={event => onhandleChange(event, 'targetDate')}
                                                KeyboardButtonProps={{
                                                    'aria-label': '修改时间',
                                                }}
                                                minDate={new Date(getMoment()().format("YYYY-MM-DD"))}
                                                maxDate={new Date(getLastDayInYear(getYear()))}
                                                disabled={
                                                    !checkEditPermission() // 无编辑权限
                                                    ||
                                                    currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                                                    ||
                                                    currentPlanDetail.executeTime // 工单已下达
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            预算（元）
                                        </Typography>
                                        <TextField
                                            id="budget-plan"
                                            className={classes.textField}
                                            variant="standard"
                                            name="budget"
                                            value={currentPlanDetail.budget ? currentPlanDetail.budget : '-'}
                                            type="number"
                                            onChange={event => onhandleChange(event, 'budget')}
                                            // error={Boolean(props.helperText)}
                                            // helperText={props.helperText || ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={
                                                !checkEditPermission() // 无编辑权限
                                                ||
                                                currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                                                ||
                                                currentPlanDetail.executeTime // 工单已下达
                                            }
                                        />

                                        {/* <TextField
                                        error={Boolean(props.helperText)}
                                        helperText={props.helperText || ''}
                                        value={props.rowData.budget}
                                        type="number"
                                        onChange={e => props.onChange(_.toNumber(e.target.value))}
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}>
                                    </TextField> */}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            描述
                                        </Typography>
                                        <TextField
                                            id="opentext-plan"
                                            className={classes.textField}
                                            variant="standard"
                                            name="opentext"
                                            value={currentPlanDetail.opentext ? currentPlanDetail.opentext : ''}
                                            onChange={event => onhandleChange(event, 'opentext')}
                                            disabled={
                                                !checkEditPermission() // 无编辑权限
                                                ||
                                                currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                                                ||
                                                currentPlanDetail.executeTime // 工单已下达
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            下达时间
                                        </Typography>
                                        <Typography gutterBottom>
                                            {currentPlanDetail ? renderExecuteTime(currentPlanDetail) : '-'}
                                        </Typography>
                                        {/* <TextField
                                        id="executeTime-plan"
                                        className={classes.textField}
                                        variant="standard"
                                        name="executeTime"
                                        value={currentPlanDetail ? renderExecuteTime(currentPlanDetail) : ''}
                                        onChange={event => onhandleChange(event, 'executeTime')}
                                        disabled={true}
                                    /> */}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            工单
                                        </Typography>
                                        {/* <TextField
                                        id="enName-name"
                                        className={classes.textField}
                                        variant="standard"
                                        name="opentext"
                                        value={currentPlanDetail ? renderWorkOrder(currentPlanDetail) : ''}
                                        onChange={event => onhandleChange(event, 'opentext')}
                                        disabled={true}
                                    /> */}
                                        {currentPlanDetail ? renderWorkOrder(currentPlanDetail) : ''}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography gutterBottom>
                                            状态
                                        </Typography>
                                        {currentPlanDetail ? <Chip size="small" className={classes[currentPlanDetail.status]} label={renderStatus(currentPlanDetail)} /> : ''}
                                    </Grid>

                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Card>
            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={
                            !checkPermission()
                            ||
                            currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                            ||
                            currentPlanDetail.executeTime // 工单已下达
                            ||
                            Boolean(currentPlanDetailChangeFlg) // 运维计划未编辑
                        }
                        // onClick={event => onhandleOrderBtn(event)}
                        onClick={event => onhandleCreateWorkOrder(currentPlanDetail)}

                    >
                        下达工单
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={
                            !checkEditPermission() // 无编辑权限
                            ||
                            currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                            ||
                            currentPlanDetail.executeTime // 工单已下达
                            ||
                            Boolean(!currentPlanDetailChangeFlg) // 运维计划未编辑
                        }
                        onClick={event => onhandleSaveBtn(event)}
                    >
                        保存计划
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={
                            !checkEditPermission() // 无编辑权限
                            ||
                            currentPlanDetail.status === 'inactive' // 无效计划（已逻辑删除）
                            ||
                            currentPlanDetail.executeTime // 工单已下达
                        }
                        onClick={event => onhandleDeleteBtn(event)}
                    >
                        删除计划
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                    </Button>
                </Grid>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={currentPlanDetailChangeFlg} />
        </div >
    );
};

OperationPlanDetail.propTypes = {
    companyList: PropTypes.array.isRequired,
    currentPlanDetail: PropTypes.object.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    optCategories: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    accCatList: PropTypes.array.isRequired,

    // 基本信息变更处理
    onhandleChange: PropTypes.func.isRequired,
    // 角色树形列表
    roleTree: PropTypes.array.isRequired,
    // 当前运维计划是否被更改？
    currentPlanDetailChangeFlg: PropTypes.bool.isRequired,
    // “保存计划” 按钮处理
    onhandleSaveBtn: PropTypes.func.isRequired,
    // “保存计划” 按钮处理
    onhandleDeleteBtn: PropTypes.func.isRequired,
    // “下达工单” 按钮处理
    onhandleCreateWorkOrder: PropTypes.func.isRequired,
};

export default withStyles(OperationPlanDetail);
