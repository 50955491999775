import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    orders: [], //生产工单列表
};

export const setOrders = createAction('@@xcloud/manufacture/setOrders');

export const getOrders = createAction('@@xcloud/manufacture/getOrders',
    (startTime, companyId, endTime, dptId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/orders'
        let strs = []
        if (startTime || endTime || companyId || dptId) {
            if (startTime) strs.push('startTime=' + startTime)
            if (endTime) strs.push('endTime=' + endTime)
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setOrders(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产工单列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addOrder = createAction('@@xcloud/manufacture/addOrder',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/orders`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "生产工单生成成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `生产工单生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const generateFixAmountOrders = createAction('@@xcloud/manufacture/generateFixAmountOrders',
    (fixAmountData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/manufacture/orders/amountfix`, fixAmountData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "生产修正产量工单成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `生产修正产量工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateOrder = createAction('@@xcloud/manufacture/updateOrder',
    (orderId, newData) => (dispatch, getState, httpClient) => {
        console.log(orderId);
        console.log(newData);
        return httpClient.patch(porxyApi + `/manufacture/orders/${orderId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产工单成功"));
                }
            }).catch(err => {
                let msg = `更新生产工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteOrder = createAction('@@xcloud/manufacture/deleteOrder',
    (orderId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/manufacture/orders/${orderId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除生产工单成功"));
                }
            }).catch(err => {
                let msg = `删除生产工单失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const manufactureOrderReducer = handleActions(
    {
        [setOrders]: (state, { payload: data }) => {
            return {
                ...state,
                orders: data
            }
        },
    },
    initialState
)

export default manufactureOrderReducer
