import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import withStyles from './styles';

const ComponentsPannel = props => {
  const {
    classes,
    onHandleClick
  } = props;
  return (
    <div className={classes.root}>
      <Grid
        className={classes.content}
        container
      >
        <Typography>组件测试用页面</Typography>
      </Grid>
      <Button variant="contained" onClick={onHandleClick.bind(this)}>
        <Typography>上传组件</Typography>
      </Button>
    </div>
  );
};

ComponentsPannel.propTypes = {
  classes: PropTypes.object.isRequired,
  onHandleClick: PropTypes.func.isRequired
};

export default withRouter(withStyles(ComponentsPannel));
