import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    MenuItem,
    Card,
    Grid,
    TextField,
    Typography,
    Tabs,
    Tab,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import {
    AttachMoney,
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
// import { getMoment } from '@/utils/datetime'
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import withStyles from './styles';

const AmoebaAnalysisReport = props => {
    const {
        classes,
        sortSubs,
        budgets,
        selectedCompanyId,
        selectedDptId,
        companySelects,
        dptSelects,
        selectedDate,
        dateView,
        tabValue,
        viewType,
        onhandleChangeTab,
        onhandleSelectChange,
        onhandleDateChange,
        onhandleManufactureOption,
        onhandleIncomeOption,
        onhandlePlanFinishRateOption,
        onhandleCostOption,
        onhandleProfitOption
    } = props;

    const getPlan = (subId) => {
        let dtl = null;
        if (budgets) {
            if (selectedCompanyId) {
                if (selectedCompanyId === 1) {
                } else {
                    dtl = _.find(budgets, { 'subId': subId, 'companyId': selectedCompanyId });
                }
            } else {
                dtl = _.find(budgets, { 'subId': subId, 'companyId': null });
            }
        }
        return dtl ? dtl.amount : 0
    }

    const renderDiff = (subId, sum, unit) => {
        let amount = Number(sum) - getPlan(subId)
        if (unit === '万元') {
            amount = amount / 10000
        }
        amount = amount.toFixed(2)

        if (Math.abs(amount) < 0.01) {
            return ''
        }
        return amount >= 0 ? '+' + amount : amount
    }

    const getSubColorClass = (amount) => {
        let colorClass = null
        if (amount > 0) colorClass = classes.exceedColor
        if (amount === 0) colorClass = classes.reachedColor
        if (amount <= 0) colorClass = classes.unreachedColor
        return colorClass
    }

    const getSumTotal = (num, unit) => {
        let result = Number(num) || 0.0;
        if (unit === '万元') {
            result = num / 10000
        }
        result = result.toFixed(2)
        return result
    }

    const getReportKey = () => {

        if (viewType === 'DEPARTMENT') {
            return "sum" + selectedDptId
        } else { return 'sum0' }

    }

    const showToolbar = () => {
        if (dateView === 'MONTH') { return true }
        if (dateView === 'YEAR') { return false }
    }

    const getPickerView = () => {
        if (dateView === 'MONTH') { return ['year', 'month'] }
        if (dateView === 'YEAR') { return ['year'] }
        return ['date']
    }

    const getPickerFormat = () => {
        if (dateView === 'MONTH') { return 'yyyy/MM' }
        if (dateView === 'YEAR') { return 'yyyy' }
        return 'yyyy/MM/dd'
    }

    const getPickerOpenTo = () => {
        if (dateView === 'MONTH') { return 'month' }
        return undefined
    }

    return (
        <>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {"阿米巴分析报表"}</Typography>
            </div>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                        <Grid item xs={6} container wrap={'nowrap'} alignItems={'center'}>
                            <Typography gutterBottom className={classes.label} style={{ margin: '0 10px' }}>
                                {'视图'}
                            </Typography>
                            <ButtonGroup size="small" style={{ height: 35, marginRight: 15 }}>
                                <Button variant={dateView === 'DAY' ? 'contained' : null} color={dateView === 'DAY' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'DAY' }
                                        }, 'view')
                                    }}>{'日'}</Button>
                                <Button variant={dateView === 'MONTH' ? 'contained' : null} color={dateView === 'MONTH' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'MONTH' }
                                        }, 'view')
                                    }}>{'月'}</Button>
                                <Button variant={dateView === 'YEAR' ? 'contained' : null} color={dateView === 'YEAR' ? 'primary' : null} disableElevation={true}
                                    onClick={() => {
                                        onhandleSelectChange({
                                            target: { value: 'YEAR' }
                                        }, 'view')
                                    }}>{'年'}</Button>
                            </ButtonGroup>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={zhCN}>
                                <KeyboardDatePicker
                                    className={classes.dateField}
                                    InputProps={{ readOnly: true }}
                                    disableToolbar={!showToolbar()}
                                    autoOk
                                    variant='inline'
                                    inputVariant='outlined'
                                    size={'small'}
                                    views={getPickerView()}
                                    openTo={getPickerOpenTo()}
                                    format={getPickerFormat()}
                                    margin="none"
                                    value={selectedDate || null}
                                    onChange={date => onhandleDateChange(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': '修改时间',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid className={classes.flexgrow}></Grid>
                        <Grid item className={classes.selectField}>
                            <TextField
                                select
                                variant={'outlined'}
                                style={{ width: 150, height: 35 }}
                                size={'small'}
                                value={selectedCompanyId || 0}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: { vertical: "top", horizontal: "center" },
                                        transformOrigin: { vertical: "top", horizontal: "center" },
                                        getContentAnchorEl: null
                                    }
                                }}
                                onChange={(event) => onhandleSelectChange(event, 'selectedCompanyId')}
                            >
                                {companySelects.map(data => (
                                    <MenuItem dense value={data.companyId} key={data.companyId}>{data.companyAlias}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {selectedCompanyId ? <Grid item className={classes.selectField}>
                            <TextField
                                select
                                variant={'outlined'}
                                style={{ width: 150, height: 35 }}
                                size={'small'}
                                value={selectedDptId || 0}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: { vertical: "top", horizontal: "center" },
                                        transformOrigin: { vertical: "top", horizontal: "center" },
                                        getContentAnchorEl: null
                                    }
                                }}
                                onChange={(event) => onhandleSelectChange(event, 'selectedDptId')}
                            >
                                {dptSelects.map(data => (
                                    <MenuItem dense value={data.dptId} key={data.dptId}>{data.dptName}</MenuItem>
                                ))}
                            </TextField>
                        </Grid> : <Grid></Grid>}
                    </Grid>
                    <Grid container spacing={0} className={classes.titleContainer}>
                        <Grid item xs={12}>
                            <Grid container justify={'space-between'}>
                                {
                                    sortSubs.filter(({ subId }) => ([2, 7, 3, 6].includes(subId))).map((s) =>
                                        <Grid item xs={3} container direction={'column'} key={s.subId} className={classes.summaryGrid}>
                                            <Grid item container justify={'center'} alignItems={'center'}>
                                                <Grid className={classes.icongrid}>
                                                    {<AttachMoney />}
                                                    <Typography className={classes.notifiText} noWrap={true}>
                                                        {`${s.subName}` || '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container justify={'center'}>
                                                <Grid container justify={'center'}>
                                                    <Typography className={classes.notifiSummary} variant={'h1'}>{getSumTotal(s[getReportKey()], s.unit)}</Typography>
                                                    <Typography className={classes.notifiSummary} variant={'h2'}>{s.unit}</Typography>
                                                    {/* <Typography className={classes.notifiSummary} variant={'h2'}>{'/'}</Typography> */}
                                                    <Typography className={classes.notifiSummary + ' ' + getSubColorClass(renderDiff(s.subId, s[getReportKey()], s.unit))} variant={'h5'}>
                                                        {'(' + renderDiff(s.subId, s[getReportKey()], s.unit) + ')'}</Typography>
                                                </Grid>
                                                <Grid container justify={'center'}>
                                                    <Typography className={classes.budgets} variant={'h5'}>{'计划'}</Typography>
                                                    <Typography className={classes.budgets} variant={'h3'}>{getSumTotal(getPlan(s.subId, false), s.unit)}</Typography>
                                                    <Typography className={classes.budgets} variant={'h5'}>{s.unit}</Typography>
                                                    {/* <Typography className={classes.budgets + ' ' + getSubColorClass(subType)} variant={'h5'}>{'实际'}</Typography> */}
                                                    {/* <Typography className={classes.budgets} variant={'h5'}>{'/'}</Typography> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item container xs={12}>
                            {/* <Card className={classes.card}> */}
                            {
                                sortSubs.filter(({ subId }) => ([2, 7, 3, 6].includes(subId))).map(({ subId }) => {
                                    return <Grid item xs={3} key={'sub_' + subId}>
                                        <ReactEcharts option={onhandlePlanFinishRateOption(subId)} style={{ height: 240, width: '100%' }} />
                                    </Grid>
                                })
                            }
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className={classes.gridContainer} justify="center">
                        <Tabs
                            classes={{ fixed: classes.tabs, indicator: classes.indicator }}
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            // centered
                            onChange={onhandleChangeTab}
                        >
                            <Tab label={"生产情况"} className={classes.tab} />
                            <Tab label={"收入分析"} className={classes.tab} />
                            <Tab label={"成本分析"} className={classes.tab} />
                            <Tab label={"利润分析"} className={classes.tab} />
                        </Tabs>
                    </Grid>
                    {tabValue === 0 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleManufactureOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 1 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleIncomeOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 2 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleCostOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                    {tabValue === 3 && <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <ReactEcharts
                                    notMerge={true} // 不合并缓存内的图表元素
                                    option={onhandleProfitOption()} style={{ height: 300, width: '100%' }} />
                            </Card>
                        </Grid>
                    </Grid>}
                </Grid>
            </div>
        </>
    );
};

AmoebaAnalysisReport.propTypes = {
    classes: PropTypes.object.isRequired,
    sortSubs: PropTypes.array.isRequired,
    budgets: PropTypes.array.isRequired,
    companySelects: PropTypes.array.isRequired,
    dptSelects: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    onhandleChangeTab: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleDateChange: PropTypes.func.isRequired,
    onhandleManufactureOption: PropTypes.func.isRequired,
    onhandleIncomeOption: PropTypes.func.isRequired,
    onhandlePlanFinishRateOption: PropTypes.func.isRequired,
    onhandleCostOption: PropTypes.func.isRequired,
    onhandleProfitOption: PropTypes.func.isRequired,
};
AmoebaAnalysisReport.defaultProps = {
    sortSubs: [],
    budgets: [],
    selectedCompanyId: 0,
    selectedDptId: 0,
    companySelects: [],
    dptSelects: [],
    selectedDate: {},
    dateView: 'MONTH',
    tabValue: 0,
};

export default withStyles(AmoebaAnalysisReport);
