import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    Grid,
    Typography,
    TablePagination
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";
import {
    Card,
    Divider
} from "@material-ui/core";
import { getMoment as moment } from '@/utils/datetime';

import _ from 'lodash';

const ChecklistItems = props => {
    const {
        classes,
        currentInventory,
        currentChecklist,
        lists,
        search,
        userProfile,
        currentUserInfo,
        companyList,
        departmentList,
        // listTypeList,
        // categoryList,
        // roleList,
        onEdit,
        getChecklists,
        onhandleCreate,
        onhandleSelectChange,
        // onhandleSearch,
        onhandleClearSearch,
        // onhandleDownloadTemp,
        // onhandleUpload,
        onhandlePageChange,
        onhandleBack,
        onhandleListMapping,
        inspectionDptMapping,
        onDelete,
    } = props;

    // console.log('search')
    // console.log(search)
    const companyId = currentUserInfo.companyId;
    const company = _.find(companyList, { 'companyId': companyId });
    const hasPcomp = company && company.pid;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_checklist' })
        return permission && (permission.action === 'W' || permission.action === 'R')
    }

    const checklistEditable = () => {
        // check公司
        if (!currentInventory.companyId && hasPcomp) return false;
        if (hasPcomp && currentInventory.companyId !== currentUserInfo.companyId) return false;
        // 个人权限
        if (!checkPermission()) return false;
        // 同检查类型
        if (_.findIndex(inspectionDptMapping, { 'inspectionCode': currentInventory.listType }) < 0) return false;
        // 同检查子分类（如果需要）
        if (currentInventory.catCode && currentInventory.catCode !== '') {
            return _.findIndex(inspectionDptMapping, { 'inspectionCode': currentInventory.catCode }) >= 0
        }
        return true;
    }

    // const companyOptions = () => (
    //     <React.Fragment>
    //         {companyList ?
    //             companyList.map(data => (
    //                 <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
    //                 // <option value={data.companyId} key={data.companyId}>{data.companyName}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // const departmentOptions = () => (
    //     <React.Fragment>
    //         {departmentList ?
    //             departmentList
    //                 .filter(data => (currentInventory.companyId === data.companyId))
    //                 .map(data => (
    //                     <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
    //                 )) : null
    //         }
    //     </React.Fragment>
    // )

    // const selectedDpt = _.find(departmentList, { 'dptId': _.toInteger(search.dptId) });
    // const showRoleSelect = selectedDpt && selectedDpt.companyPid && selectedDpt.companyPid !== '';
    // const roleOptions = () => (
    //     <React.Fragment>
    //         {roleList ?
    //             roleList
    //                 .filter(item => (item.companyId === search.company))
    //                 .map(data => (
    //                     <option value={data.code} key={data.code}>{data.name}</option>
    //                 )) : null
    //         }
    //     </React.Fragment>
    // )

    // const listTypeOptions = () => (
    //     <React.Fragment>
    //         {listTypeList ?
    //             listTypeList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // const showCategory = _.find(categoryList, { 'pcode': currentInventory.listType });
    // const categoryOptions = () => (
    //     <React.Fragment>
    //         {categoryList ?
    //             categoryList.filter(item => (item.pcode === search.listType)).map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const renderDpt = (dptId) => {

        let dpt = _.find(departmentList, { 'dptId': dptId })
        if (dpt) {
            return dpt.dptName
        }
        return ''
    }

    const tableOptions = {
        ...options,
        pageSize: search.size,
        tableLayout: 'auto',
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        toolbar: true
    }

    const tableStyle = {
        ...style,
        border: '0px solid black',
        boxShadow: 'none'
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '序号',
                field: 'serialNo',
                render: row => row ? <Typography>{row.serialNo}</Typography> : '',
                editable: 'never',
                width: '5%'
            },
            {
                title: '风险等级',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never',
                width: '8%'
            },
            {
                title: '研判部门',
                field: 'opentext',
                render: row => row ? <Typography>{renderDpt(row.dptId)}</Typography> : '',
                editable: 'never',
                width: '8%'
            },
            {
                title: '研判区域',
                field: 'attr1',
                render: row => row ? <Typography>{row.attr1}</Typography> : '',
                editable: 'never',
                width: '10%'
            },
            {
                title: '研判内容',
                field: 'attr2',
                render: row => row ? <Typography>{row.attr2}</Typography> : '',
                editable: 'never',
                width: '10%'
            },

            {
                title: '危害因素描述',
                field: 'reference',
                render: row => row ? <Typography>{onhandleListMapping(row.reference, 30)}</Typography> : '',
                editable: 'never',
                width: '400px'
            },
            {
                title: '研判标准',
                field: 'problem',
                render: row => row ? <Typography title={row.problem}>{truncate(row.problem, 30)}</Typography> : '',
                editable: 'never',
                width: '400px'
            }
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <>
                <Grid container spacing={1} className={classes.gridContainer}>

                </Grid>

                <MTableToolbar {...props} />
            </>

        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={search.size}
                count={lists.total}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    onhandlePageChange(pageNum);
                    getChecklists({
                        ...search,
                        offset: pageNum * search.size,
                        size: search.size
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                    getChecklists({
                        ...search,
                        offset: 0,
                        size: event.target.value
                    })
                }}

            />
        )
    }

    return (
        <Card className={classes.card}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {currentInventory.inventoryName || '检查表明细'}</Typography>
            </div>

            <Divider variant="middle" />

            <Grid container spacing={1} className={classes.gridContainer}>
                <Typography variant="h3" color='textPrimary'>基本信息</Typography>
            </Grid>

            <Grid container spacing={1} className={classes.gridContainer}>
                {currentChecklist.listId ? (
                    <>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                {currentChecklist.creatorLastName + currentChecklist.creatorFirstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {moment().utc(currentChecklist.createTime).format('yyyy/MM/DD HH:mm:ss')}
                            </Typography>
                        </Grid>

                    </>) : null
                }

                {currentChecklist.listId && currentChecklist.updateTime ? (
                    <>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                更新人
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom>
                                {currentChecklist.revisorLastName + currentChecklist.revisorFirstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                更新时间
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography gutterBottom>
                                {moment().utc(currentChecklist.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                            </Typography>
                        </Grid>

                    </>) : null
                }
            </Grid>

            <Divider variant="middle" />


            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={tableOptions}
                        localization={localization}
                        style={tableStyle}
                        components={components}
                        actions={checklistEditable() ?
                            [
                                {
                                    icon: 'settings_backup_restore',
                                    tooltip: '重置筛选',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleClearSearch() }
                                },
                                // {
                                //     icon: 'get_app',
                                //     tooltip: '下载检查表模板',
                                //     isFreeAction: true,
                                //     onClick: (event) => { onhandleDownloadTemp() }
                                // },
                                // {
                                //     icon: 'backup',
                                //     tooltip: '导入检查项',
                                //     isFreeAction: true,
                                //     onClick: (event, rowData) => { onhandleUpload() }
                                // },
                                {
                                    icon: 'add',
                                    tooltip: '新建研判明细',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleCreate() }
                                },
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, false)
                                    }
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: '编辑',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, true)
                                    },
                                    disabled: !checklistEditable(rowData)
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除',
                                    onClick: (event, rowData) => {
                                        onDelete(event, rowData.listId)
                                    },
                                    disabled: !checklistEditable(rowData)
                                })
                            ] : [
                                {
                                    icon: 'settings_backup_restore',
                                    tooltip: '重置筛选',
                                    isFreeAction: true,
                                    onClick: (event) => { onhandleClearSearch() }
                                },
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.listId, false)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="primary"
                            onClick={event => onhandleBack(event)}>
                            {'返回'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

ChecklistItems.propTypes = {
    classes: PropTypes.object.isRequired,
    lists: PropTypes.object.isRequired,
    inspectionDptMapping: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    getChecklists: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    onhandlePageChange: PropTypes.func.isRequired
};

export default withStyles(ChecklistItems);
