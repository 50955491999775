import React from 'react';
import {
    TextField,
    Grid,
    Link,
    MenuItem,
    Typography,
    // ButtonGroup,
    // Button,
    Tooltip,
    IconButton,
    Tabs,
    Tab
} from '@material-ui/core';
import {
    CloudDownload
} from '@material-ui/icons';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment, formatDate_hms } from '@/utils/datetime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _ from 'lodash';

const ProdServiceReportComponent = props => {
    const { classes,
        dateHeadDataList,
        prodReportServiceList,
        outputCheckList,
        isMainCompany,
        selectedCompanyId,
        selectedCompanyName,
        companies,
        onhandleChangeCompany,
        onhandleSelectViewChange,
        timeDim,
        startDate,
        endDate,
        roleFlag,
        onhandleExport,
        onhandleDateChange,
        onhandleShowServiceOrgReport,
        onhandleCheckOutput,
        // onhandleShowServiceDetailReport,
    } = props;

    // console.log('new daily prod report =======================')
    // console.log(dateHeadDataList)
    // console.log(prodReportServiceList)

    const getTimeDimShow = () => {
        const startMoment = getMoment()(startDate);
        const endMoment = getMoment()(endDate);

        let format = 'yyyy/MM/DD';
        let suffix = ' 日';
        if (timeDim === 'day') {
            format = 'yyyy/MM/DD';
            suffix = ' 日';
        }
        if (timeDim === 'month') {
            format = 'yyyy/MM';
            suffix = ' 月'
        }
        if (timeDim === 'year') {
            format = 'yyyy';
            suffix = ' 年'
        }
        if (startMoment.startOf(timeDim).isSame(endMoment.startOf(timeDim))) {
            const during = startMoment.format(format)
            return during + suffix
        } else {
            const during = startMoment.format(format) + ' - ' + endMoment.format(format)
            return during + suffix
        }
    }

    const showToolbar = () => {
        if (timeDim === 'day') { return false }
        if (timeDim === 'month') { return true }
        if (timeDim === 'year') { return false }
    }

    const getPickerView = () => {
        if (timeDim === 'day') { return ['date'] }
        if (timeDim === 'month') { return ['year', 'month'] }
        if (timeDim === 'year') { return ['year'] }
        return ['date']
    }

    const getPickerFormat = () => {
        if (timeDim === 'day') { return 'yyyy/MM/dd' }
        if (timeDim === 'month') { return 'yyyy/MM' }
        if (timeDim === 'year') { return 'yyyy' }
        return 'yyyy/MM/dd'
    }

    const getCheckStatus = (headItem) => {
        if (timeDim === 'day' && selectedCompanyId) {
            const check = _.find(outputCheckList, c => {
                return getMoment()(c.checkDate).format('yyyy/MM/DD') === headItem.key
            })
            if (check) {
                return <Tooltip title={'确认时间:' + formatDate_hms(check.createTime)}><CheckCircleIcon className={classes.checked} onClick={() => onhandleCheckOutput(headItem.key, true)}></CheckCircleIcon></Tooltip>
            } else {
                return <Tooltip title={'确认日产量'}><CheckCircleIcon className={classes.unchecked} onClick={() => onhandleCheckOutput(headItem.key, false)}></CheckCircleIcon></Tooltip>
            }
        }
        return <Typography></Typography>
    }

    const getMaxFromDate = () => {
        let maxFromDate = getMoment()('2021-01-01');
        return maxFromDate.startOf(timeDim).toDate()
    }

    const getMaxToDate = () => {
        let maxToDate = getMoment()();
        return maxToDate.endOf(timeDim).toDate()
    }

    const getPickerOpenTo = () => {
        if (timeDim === 'month') { return 'month' }
        return undefined
    }

    const renderSelectCompany = () => {
        if (companies && companies.length > 1) {
            return <TextField
                select
                variant={'standard'}
                style={{ width: 150 }}
                size={'small'}
                value={selectedCompanyId}
                onChange={onhandleChangeCompany}
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: { vertical: "top", horizontal: "center" },
                        transformOrigin: { vertical: "top", horizontal: "center" },
                        getContentAnchorEl: null
                    }
                }}
            >
                {companies.map((c) => (
                    <MenuItem key={c.companyId} value={c.companyId}> {c.companyAlias}</MenuItem>
                ))}
            </TextField>
        }
    }

    const renderOutput = (headItem, row) => {
        const data = row.data || {}
        const outputValue = data[headItem.key] ? (data[headItem.key]).toFixed(2) : 0
        if (timeDim === 'day') {
            const check = _.find(outputCheckList, c => {
                return getMoment()(c.checkDate).format('yyyy/MM/DD') === headItem.key
            })
            if (check) {
                const sData = row.snapshotData || {}
                const sOutputValue = sData[headItem.key] ? (sData[headItem.key]).toFixed(2) : 0
                const isChange = Math.abs(sOutputValue - outputValue) >= 0.01
                if (isChange) {
                    return <Tooltip title={"已确认值：" + sOutputValue}><Typography className={isChange ? classes.highlight : {}}>{outputValue}</Typography></Tooltip>
                }
            }
        }
        return <Typography>{outputValue}</Typography>
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '服务',
                dataIndex: 'serviceName',
                key: 'serviceName',
                width: 160,
                fixed: 'left',
                render: (text, row, index) => <Typography>{row.serviceName}</Typography>
            },
            {
                title: '单位',
                dataIndex: 'unit',
                key: 'unit',
                width: 60,
                fixed: 'left',
                render: (text, row, index) => <Typography>{row.unit}</Typography>
            }
        ]

        if (dateHeadDataList && dateHeadDataList.length > 0) {

            _.forEach(dateHeadDataList, item => {
                const column = {
                    title: <Grid container>
                        <Grid item className={classes.flexgrow}></Grid>
                        <Grid item ><Link
                            key={item.key}
                            color="primary"
                            underline="always"
                            target="_blank"
                            component="button"
                            variant="body2"
                            display="inline"
                            onClick={(event) => onhandleShowServiceOrgReport(item.key)}><Typography>{item.name}</Typography>
                        </Link></Grid>
                        <Grid item >{getCheckStatus(item)}</Grid>
                    </Grid>,
                    width: 120,
                    dataIndex: item.key,
                    key: item.key,
                    align: 'right',
                    render: (text, row, index) => renderOutput(item, row)
                }
                columns.push(column);
            })
        }
        // 自适应列防止宽度失效
        // columns.push({
        //     // title: '',
        //     dataIndex: 'justify',
        //     key: 'justify',
        // });

        return columns

    }

    const assemblyData = () => {
        return prodReportServiceList
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                        <Grid item xs={6} container wrap={'nowrap'} alignItems={'center'}>
                            {/* <Typography gutterBottom className={classes.label} style={{ margin: '0 10px' }}>
                                {'视图'}
                            </Typography> */}
                            {/* <ButtonGroup size="small" style={{ height: 35, marginRight: 15 }}>
                                <Button variant={timeDim === 'day' ? 'contained' : null} color={timeDim === 'day' ? 'primary' : null} disableElevation={true} style={{ whiteSpace: 'nowrap' }}
                                    onClick={() => {
                                        onhandleSelectViewChange('day')
                                    }}>{'日报表'}</Button>
                                <Button variant={timeDim === 'month' ? 'contained' : null} color={timeDim === 'month' ? 'primary' : null} disableElevation={true} style={{ whiteSpace: 'nowrap' }}
                                    onClick={() => {
                                        onhandleSelectViewChange('month')
                                    }}>{'月报表'}</Button>
                                <Button variant={timeDim === 'year' ? 'contained' : null} color={timeDim === 'year' ? 'primary' : null} disableElevation={true} style={{ whiteSpace: 'nowrap' }}
                                    onClick={() => {
                                        onhandleSelectViewChange('year')
                                    }}>{'年报表'}</Button>
                            </ButtonGroup> */}
                            <Tabs
                                value={timeDim}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label={"日报表"} value={'day'} className={classes.tab} onClick={() => { onhandleSelectViewChange('day') }} />
                                <Tab label={"月报表"} value={'month'} className={classes.tab} onClick={() => { onhandleSelectViewChange('month') }} />
                                <Tab label={"年报表"} value={'year'} className={classes.tab} onClick={() => { onhandleSelectViewChange('year') }} />
                            </Tabs>
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                            <Grid item xs={6} container wrap={'nowrap'} alignItems={'center'}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        InputProps={{ readOnly: true }}
                                        disableToolbar={!showToolbar()}
                                        autoOk
                                        variant='inline'
                                        inputVariant='standard'
                                        size={'small'}
                                        views={getPickerView()}
                                        openTo={getPickerOpenTo()}
                                        format={getPickerFormat()}
                                        minDate={getMaxFromDate()}
                                        maxDate={getMaxToDate()}
                                        margin="none"
                                        value={startDate || null}
                                        onChange={date => onhandleDateChange(date, 'startDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <Typography gutterBottom className={classes.label} style={{ margin: '0 10px' }}>
                                    {' 至 '}
                                </Typography>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        InputProps={{ readOnly: true }}
                                        disableToolbar={!showToolbar()}
                                        autoOk
                                        variant="inline"
                                        inputVariant='standard'
                                        size={'small'}
                                        views={getPickerView()}
                                        openTo={getPickerOpenTo()}
                                        format={getPickerFormat()}
                                        minDate={getMaxFromDate()}
                                        maxDate={getMaxToDate()}
                                        margin="none"
                                        value={endDate || null}
                                        onChange={date => onhandleDateChange(date, 'endDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                {roleFlag && timeDim !== 'day' && <>
                                    <Tooltip title={'导出生产报表'} placement="top">
                                        <IconButton onClick={() => { onhandleExport() }}>
                                            <CloudDownload />
                                        </IconButton>
                                    </Tooltip>
                                </>}
                                {
                                    isMainCompany && renderSelectCompany()
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Typography variant='h3'>{selectedCompanyName} {getTimeDimShow()}生产报表</Typography>
                </Grid>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Table
                        className={classes.stripedTable}
                        rowKey={record => (record.serviceId + '_' + Date.now())} // 保持刷新
                        columns={assemblycolumns()}
                        dataSource={assemblyData()}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: '100%', y: 'calc(100vh - 310px)' }}
                        size={'small'}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

ProdServiceReportComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    timeDim: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default withStyles(ProdServiceReportComponent);