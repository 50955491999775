import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Avatar,
    Chip,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import {
    Ballot,
    Edit,
    Description,
    AttachFile,
    Link,
    MonetizationOn,
    CancelOutlined,
} from '@material-ui/icons';
import { Table } from 'antd';
import { getDateByTimestamp } from '@/utils/datetime';
import { truncate } from "@/utils/string";
import UserCard from '@/containers/UserCard';
import { getOperationWorkRecordFlg, WORKORDER_STATUS_ALL } from '@/utils/operationUtils'


import _ from 'lodash';

const WorkOrderList = props => {
    const {
        classes,
        initListData,
        isLoading,
        orderTypeList,
        // departmentList,
        companyList,
        userProfile,
        currentUserInfo,
        onEditWorkOrders,
        routeType,
        onhandleRouteToAppointmentCreate,
        // 根据不同状态，路由到不同路径
        onhandleRouteBaseOnStatus,
        onhandleFinalCost,
        onhandlePageChange,
        onhandleInvalid,
        search,
        onDownLoadDoc,
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_workorder_list' })
        return permission && permission.action === 'W'
    }

    // const checkPermissionAppointment = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'operation_workorder_appointment' })
    //     return permission && permission.action === 'W'
    // }

    const checkInvildPermission = (workOrder) => {

        if (workOrder.creator === currentUserInfo.userId
            || workOrder.orderHead === currentUserInfo.userId) {

            if (_.indexOf(['new', 'rejected', 'submitted'], workOrder.status) >= 0) {

                return true;
            }
        }
        return false;
    }

    const renderTime = (time) => {
        return time ? getDateByTimestamp(time, false) : null;
    }

    const renderStatus = (row) => {
        let rtnValue
        rtnValue = <Chip size="small" className={classes[row.status]} label={renderStatusLabel(row)} />
        return rtnValue
    }

    const renderApprovers = (row) => {
        if (!row.approvers || row.approvers.length === 0) {
            return <Typography>{""}</Typography>
        } else {
            let preId = row.approvers[0].preId;
            let approvers = [];
            if (row.approvers[0].userId) { //审批用户
                approvers.push(row.approvers[0].lastName + row.approvers[0].firstName);
            } else {//审批角色
                approvers.push(row.approvers[0].roleName);
            }
            for (var i = 1; i < row.approvers.length; i++) {
                if (row.approvers[i].preId !== preId) {
                    break;
                } else {
                    if (row.approvers[i].userId) {//审批用户
                        approvers.push(row.approvers[i].lastName + row.approvers[i].firstName);
                    } else {//审批角色
                        approvers.push(row.approvers[i].roleName);
                    }
                }
            }
            return <Typography>{approvers.join(",")}</Typography>
        }
    }

    const renderStatusLabel = (WorkOrder) => {
        return WORKORDER_STATUS_ALL[WorkOrder.status]
    }

    const renderOrderType = (WorkOrder) => {
        let label = '';
        if (orderTypeList && orderTypeList.length > 0) {
            let value = _.find(orderTypeList, function (o) { return o.code === WorkOrder.orderType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderCompany = (WorkOrder) => {
        let label = '';
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === WorkOrder.companyId })
            if (value) {
                label = value.companyAlias
            }
        }
        return label;
    }

    // const renderDepartment = (WorkOrder) => {
    //     let label = '';
    //     if (departmentList && departmentList.length > 0) {
    //         let value = _.find(departmentList, function (o) { return o.dptId === WorkOrder.dptId })
    //         if (value) {
    //             label = value.dptName
    //         }
    //     }
    //     return label;
    // }

    const renderContractor = (workOrder) => {
        if (workOrder.executors && workOrder.executors.length > 0) {
            return workOrder.executors.map(ele => {
                return ele.internalCompanyName && ele.internalDptName ?
                    <Chip
                        key={ele.woeId}
                        size="small"
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic1}>
                            内
                        </Avatar>}
                        label={<Typography title={`${ele.internalCompanyName} > ${ele.internalDptName}`}>{ele.internalDptName}</Typography>}
                    />
                    :
                    <Chip
                        key={ele.woeId}
                        avatar={<Avatar style={{ color: '#FFFFFF' }} className={classes.pic2}>
                            外
                        </Avatar>}
                        size="small"
                        label={<Typography title={ele.supplierName}>{truncate(ele.supplierName, 10)}</Typography>}
                    />
            }
            )
        } else {
            return null;
        }
    }

    // const checkOrder = (WorkOrder) => {
    //     let rtnFlg = true;
    //     if (WorkOrder.orderHead === userProfile.userId) {
    //         rtnFlg = false;
    //     } else if (_.filter(WorkOrder.executors, function (o) { return o.internalCompany }).length > 0) {
    //         if (_.filter(WorkOrder.executors, function (o) { return o.internalCompany === companyId && o.internalDpt === userProfile.roles[0].departments[0].dptId }).length > 0) {
    //             rtnFlg = false;
    //         }
    //     }
    //     return rtnFlg
    // }

    // const checkViewOrder = (WorkOrder) => {
    //     let rtnFlg = true;
    //     if (WorkOrder.startTime) rtnFlg = false;
    //     return rtnFlg
    // }

    const assemblycolumns = () => {
        let columns = [
            {
                field: 'type',
                align: 'center',
                render: row => row && row.masterOrderNo != null ?
                    <Tooltip title={"主工单编号: " + row.masterOrderNo}>
                        <Avatar className={classes.pic2}>从</Avatar>
                    </Tooltip>
                    : <Avatar className={classes.pic1}>主</Avatar>,
                width: '2.8em',
                editable: 'never',
                onCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 26,
                        maxWidth: 26,
                        padding: '6px auto',
                    }
                }),
            },
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never',
                width: 150,
            },
            {
                title: '工单类型',
                field: 'orderType',
                render: row => row ? <Typography>{renderOrderType(row)}</Typography> : '',
                editable: 'never',
                width: 120,
            },
            {
                title: '工单内容',
                field: 'orderContent',
                width: 200,
                onCell: () => ({
                    style: {
                        width: 200,
                        maxWidth: 300,
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        width: 200,
                        maxWidth: 300,
                    }
                }),
                render: row => row ? <Typography className={classes.longContent} title={row.orderContent}>{row.orderContent}</Typography> : '',
                editable: 'never'
            },
            {
                title: '单位',
                field: 'companyId',
                width: 80,
                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '部门',
            //     field: 'dptId',
            //     width: 80,
            //     render: row => row ? <Typography>{renderDepartment(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '承包商',
                align: 'center',
                field: 'contractor',
                width: 140,
                render: row => row ? renderContractor(row) : '',
                editable: 'never',
                onCell: () => ({
                    style: {
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        padding: '6px auto',
                    }
                }),
            },
            {
                title: '负责人',
                field: 'orderHead',
                render: row => row && row.orderHeadLastName && row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadLastName + row.orderHeadFirstName}</Typography>} /> : row.orderHeadFirstName ? <UserCard userId={row.orderHead} child={<Typography>{row.orderHeadFirstName}</Typography>} /> : '',
                editable: 'never',
                width: 70,
            },
        ]

        if (routeType !== 'needProcess') {
            columns.push(
                {
                    title: '开始时间',
                    align: 'center',
                    field: 'startTime',
                    type: 'date',
                    width: 100,
                    render: row => row ? <Typography>{renderTime(row.startTime)}</Typography> : '',
                    editable: 'never'
                },
                {
                    title: '结束时间',
                    align: 'center',
                    field: 'endTime',
                    type: 'date',
                    width: 100,
                    render: row => row ? <Typography>{renderTime(row.endTime)}</Typography> : '',
                    editable: 'never'
                })
        }

        if (routeType === 'needCount') {
            columns.push({
                title: '预算(元)',
                width: 130,
                align: 'right',
                field: 'orderBudget',
                render: row => row ? <Typography>{row.orderBudget}</Typography> : '',
                editable: 'never'
            })
        } else if (routeType === 'all') {
            columns.push(
                {
                    title: '预算(元)',
                    width: 130,
                    align: 'right',
                    field: 'orderBudget',
                    render: row => row ? <Typography>{row.orderBudget}</Typography> : '',
                    editable: 'never'
                },
                {
                    title: '决算(元)',
                    width: 130,
                    align: 'right',
                    field: 'orderCost',
                    render: row => row ? <Typography>{row.orderCost}</Typography> : '',
                    editable: 'never'
                },
            )
        } else if (routeType === 'needProcess') {
            columns.push({
                title: '审核人',
                field: 'approvers',
                render: row => row ? renderApprovers(row) : '',
                editable: 'never',
                width: 70,
            })
        }
        columns.push(
            {
                title: '状态',
                align: 'center',
                field: 'status',
                render: row => row ? renderStatus(row) : '',
                editable: 'never',
                width: 80,
                onCell: () => ({
                    style: {
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        padding: '6px auto',
                    }
                }),
            },
            {
                title: '操作',
                field: 'actions',
                width: 90,
                render: row => renderActions(row),
                onCell: () => ({
                    style: {
                        padding: '0px 0px',
                    }
                }),
                onHeaderCell: () => ({
                    style: {
                        padding: '6px auto',
                    }
                }),
            }
        )

        return columns
    }

    const renderActions = (row) => {
        switch (routeType) {
            case 'all':
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='查看工单详细'><IconButton variant='text' onClick={() => onEditWorkOrders(row.masterOrder ? row.masterOrder : row.orderId)}> <Description fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                    {row.workRecordFlg === 'N' ? <Grid item><Tooltip title='查看作业信息'><IconButton variant='text' onClick={() => onhandleRouteToAppointmentCreate(row.orderId)}> <Ballot fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid> : null}
                    {checkInvildPermission(row) ? <Grid item><Tooltip title='废止工单'><IconButton variant='text' onClick={() => onhandleInvalid(row)}> <CancelOutlined fontSize='small' color={'error'} /></IconButton></Tooltip></Grid> : null}
                </Grid>
            case 'needProcess':
                if (checkPermission()) {
                    return <Grid container alignItems='center'>
                        <Grid item><Tooltip title='工单编辑审核'><IconButton variant='text' onClick={() => onEditWorkOrders(row.masterOrder ? row.masterOrder : row.orderId)}> <Edit fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                    </Grid>
                } else {
                    return <Grid container alignItems='center'>
                        <Grid item><Tooltip title='查看工单编辑审核信息'><IconButton variant='text' onClick={() => onEditWorkOrders(row.masterOrder ? row.masterOrder : row.orderId)}> <Description fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                    </Grid>
                }
            // case 'needAppointment':
            //     actions =
            //         [
            //             rowData => ({
            //                 icon: checkOrder(rowData) ? 'ballot' : 'assignment',
            //                 tooltip: checkOrder(rowData) ? '查看预约' : '作业预约',
            //                 disabled: checkOrder(rowData) ? checkViewOrder(rowData) : false,
            //                 onClick: (event, rowData) => {
            //                     onhandleRouteToAppointmentCreate(rowData.orderId);
            //                 }
            //             }),
            //             rowData => ({
            //                 icon: 'description',
            //                 tooltip: '查看工单编辑审核信息',
            //                 onClick: (event, rowData) => {
            //                     onEditWorkOrders(rowData.masterOrder ? rowData.masterOrder : rowData.orderId);
            //                 }
            //             })]

            //     break;
            // case 'needWork':
            //     actions = [
            //         rowData => ({
            //             icon: checkOrder(rowData) ? 'business_center' : 'work',
            //             tooltip: checkOrder(rowData) ? '查看作业' : '前往作业',
            //             disabled: checkOrder(rowData) ? checkViewOrder(rowData) : false,
            //             onClick: (event, rowData) => {
            //                 onhandleRouteToAppointmentCreate(rowData.orderId);
            //             }
            //         }),
            //         rowData => ({
            //             icon: 'description',
            //             tooltip: '查看工单编辑审核信息',
            //             onClick: (event, rowData) => {
            //                 onEditWorkOrders(rowData.masterOrder ? rowData.masterOrder : rowData.orderId);
            //             }
            //         })
            //     ]
            //     break;
            case 'needCount':
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='查看工单编辑审核信息'><IconButton variant='text' onClick={() => onEditWorkOrders(row.masterOrder ? row.masterOrder : row.orderId)}> <Description fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                    {getOperationWorkRecordFlg(row.workRecordFlg) === 'Y' ?
                        // <Grid item><Tooltip title='前往决算'><IconButton variant='text' onClick={() => onhandleRouteToAppointmentCreate(row.orderId)}> <MonetizationOn fontSize='small' /></IconButton></Tooltip></Grid>
                        null : <Grid item><Tooltip title='决算'><IconButton variant='text' onClick={() => onhandleFinalCost(row)}> <MonetizationOn fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>}
                    {
                        //是否有附件
                        row.docs && row.docs.length > 0 ? <Grid item><Tooltip title='附件'><IconButton variant='text' onClick={() => onDownLoadDoc(row.docs)}> <AttachFile fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid> : null
                    }
                </Grid>
            case 'popUp':
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='查看工单列表'><IconButton variant='text' onClick={() => onhandleRouteBaseOnStatus(row.status)}> <Link fontSize='small' color={'primary'} /></IconButton></Tooltip></Grid>
                </Grid>
            default:
                return <Grid container alignItems='center'></Grid>
            // do nothing
        }

    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            {isLoading ? null :
                <Grid className={routeType === 'popUp' ? classes.detailtablePopup : classes.detailtable}>
                    <Table
                        // tableRef={tableRef}
                        className={classes.stripedTable}
                        rowKey={'orderId'}
                        columns={assemblycolumns()}
                        dataSource={initListData.list}
                        size={'small'}

                        scroll={{ x: 'max-content', y: 'calc(100vh - 260px)' }}
                        pagination={routeType === 'popUp' ? false : {
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: () => `共${initListData.total}条`,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            pageSize: search.size,
                            current: search.page,
                            total: initListData.total,
                            onChange: (current, pageSize) => onhandlePageChange(current, pageSize)
                        }}
                    >
                    </Table>
                </Grid>
            }
        </div>
    );
};

WorkOrderList.propTypes = {
    classes: PropTypes.object.isRequired,
    initListData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    orderTypeList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    onhandleRouteToAppointmentCreate: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onEditWorkOrders: PropTypes.func.isRequired,
    onhandleRouteBaseOnStatus: PropTypes.func.isRequired,
};

export default withStyles(WorkOrderList);
