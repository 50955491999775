import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    shiftList: [] //查询当班List
};

export const setManufactureShifts = createAction('@@xcloud/manufacture/setManufactureShifts');

export const getManufactureShifts = createAction('@@xcloud/manufacture/getManufactureShifts',
    (shiftName, shiftCat, shiftNumber, dptId, companyId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/shiftnoend'
        let strs = []
        if (shiftName || shiftCat || shiftNumber || dptId || companyId || status) {
            if (shiftName) strs.push('shiftName=' + shiftName)
            if (shiftCat) strs.push('shiftCat=' + shiftCat)
            if (shiftNumber) strs.push('shiftNumber=' + shiftNumber)
            if (dptId) strs.push('dptId=' + dptId)
            if (companyId) strs.push('companyId=' + companyId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setManufactureShifts(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取当班失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const ManufactureShiftReducer = handleActions(
    {
        [setManufactureShifts]: (state, { payload: data }) => {
            return {
                ...state,
                shiftList: data
            }
        },
    },
    initialState
)

export default ManufactureShiftReducer
