import React from 'react';
import {
    Grid,
    Tab,
    Tabs,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import EnergyPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/EnergyPricingContainer';
import NewEnergyPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/NewEnergyPricingContainer';
import UnifyPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/UnifyPricingContainer';
import OilPricingContainer from '@/containers/AmoebaManagement/BudgetPricing/OilPricingContainer';

const EnergyComponent = props => {
    const { classes,
        tabValue,
        onhandleChangeTab,
    } = props;
    return (
        <div alignitems="center" className={classes.root}>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"能耗"} className={classes.tab} />
                <Tab label={"能耗"} className={classes.tab} />
                <Tab label={"油耗"} className={classes.tab} />
                <Tab label={"价格"} className={classes.tab} />
            </Tabs>
            <Grid>
                {tabValue === 0 && <EnergyPricingContainer tabValue={tabValue} />}
                {tabValue === 1 && <NewEnergyPricingContainer tabValue={tabValue} />}
                {tabValue === 2 && <OilPricingContainer tabValue={tabValue} />}
                {tabValue === 3 && <UnifyPricingContainer tabValue={tabValue} />}
            </Grid>
        </div>
    )
}

EnergyComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

EnergyComponent.defaultProps = {
};

export default withStyles(EnergyComponent);