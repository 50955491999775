import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    planDetail: {
        inventoryId: '',
        inspectType: '',
        inspectionDptUserList: [],
        inspectionCompanyIdList: [],
        inspectHead: '',
        startTime: '',
        inspectTime: '',
        intervalTime: 'false',
        frequencyDate: false,
        frequencyType: 'day',
        planDates: []
    },
    confirmCallback: null,
    closeCallback: null
};

export const openTempPlanDetailAddPannel = createAction('@@xcloud/inspectionTempPlanDetailAdd/open',
    (
        confirmCallback, closeCallback, tabValue
    ) => ({
        confirmCallback, closeCallback, tabValue
    })
);

export const closeTempPlanDetailAddPannel = createAction('@@xcloud/inspectionTempPlanDetailAdd/close');

export const changeTempPlanDetail = createAction('@@xcloud/inspectionTempPlanDetailAdd/changeTempPlanDetail');

// Actions

export const InspectionTempPlanDetailAddReducer = handleActions(
    {
        [openTempPlanDetailAddPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                planDetail: {
                    inventoryId: '',
                    inspectType: data.tabValue,
                    inspectionDptUserList: [],
                    inspectionCompanyIdList: [],
                    startTime: '',
                    inspectTime: '',
                    inspectHead: '',
                    intervalTime: 'false',
                    frequencyDate: false,
                    frequencyType: 'day',
                    planDates: []

                },
            };
        },
        [closeTempPlanDetailAddPannel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeTempPlanDetail]: (state, { payload: data }) => {
            return {
                ...state,
                planDetail: data,
            };
        }
    },
    initialState
);

export default InspectionTempPlanDetailAddReducer;
