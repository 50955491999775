import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    businessGroupList: [],
};

export const setBusinessGroupList = createAction('@@xcloud/manufacture/setBusinessGroupList');

export const addBusinessGroup = createAction('@@xcloud/manufacture/addBusinessGroup',
    (businessGroup) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/businessgroup', businessGroup)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加业务分组成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加业务分组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateBusinessGroup = createAction('@@xcloud/manufacture/updateBusinessGroup',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/businessgroup/' + updateDto.groupId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新业务分组成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新业务分组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delBusinessGroup = createAction('@@xcloud/manufacture/deleteBusinessGroup',
    (groupId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/businessgroup/' + groupId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除业务分组成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除业务分组失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getBusinessGroupList = createAction('@@xcloud/manufacture/getBusinessGroupList',
    (groupName, parentId, opentext, subId, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/businessgroup'
        let strs = []
        if (groupName || parentId || opentext || subId || status) {
            if (groupName) strs.push('groupName=' + groupName)
            if (parentId) strs.push('parentId=' + parentId)
            if (opentext) strs.push('opentext=' + opentext)
            if (subId) strs.push('subId=' + subId)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setBusinessGroupList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取业务分组列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const businessGroupReducer = handleActions(
    {
        [setBusinessGroupList]: (state, { payload: data }) => {
            return {
                ...state,
                businessGroupList: data
            }
        },
    },
    initialState
)

export default businessGroupReducer
