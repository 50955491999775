import React from 'react';
import {
    Grid, Typography, TextField, InputAdornment, MenuItem,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate } from "@/utils/datetime";
import _ from 'lodash'

const RefuelRecordComponent = props => {
    const { classes,
        startDate,
        endDate,
        refuelRecordList,
        onhandleDateChange,
        onhandleUpdate,
        onhandleUpload,
        onhandleDownload,
        dicts,
        userProfile,
        onhandleCompanyChange,
        companyId,
        companies,
        isMainCompany,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: true

    }
    const tableStyle = {
        ...style
    }


    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'manufacture_refuel_record' })
        return permission && permission.action === 'W'
    }

    const renderField = (type, code) => {
        let element = _.find(dicts, (o) => {
            return o['type'] === type && o['code'] === code
        })
        return element !== undefined ? element['name'] : '';
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '加油日期',
                field: 'refuelDate',
                editable: 'never',
                width: 120,
                render: row => <Typography>{formatDate(row.refuelDate)}</Typography>
            },
            {
                title: '单位',
                field: 'companyName',
                editable: 'never',
                render: row => <Typography>{row.companyName}</Typography>
            }, {
                title: '班组',
                field: 'dptName',
                editable: 'never',
                render: row => <Typography>{row.dptName}</Typography>
            },
            {
                title: '司机',
                field: 'driverName',
                editable: 'never',
                render: row => <Typography>{row.driverName}</Typography>
            },
            {
                title: '车牌号',
                field: 'carNo',
                width: 120,
                validate: rowData => (!rowData.carNo || rowData.carNo === '') ? { isValid: false, helperText: '车牌号不能为空' } : true,
                render: row => <Typography>{row.carNo}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.carNo ? props.rowData.carNo : ''}
                        name='carNo'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '油站',
                field: 'station',
                editable: 'never',
                render: row => <Typography>{renderField('OIL_STATION', row.station)}</Typography>,
            },
            {
                title: '油品',
                field: 'oilType',
                editable: 'never',
                render: row => <Typography>{renderField('OIL_TYPE', row.oilType)}</Typography>
            },
            {
                title: '单价(元/升)',
                field: 'unitPrice',
                type: 'numeric',
                validate: rowData => (!rowData.unitPrice || rowData.unitPrice === '') ? { isValid: false, helperText: '单价不能为空' } : true,
                render: row => <Typography>{row.unitPrice.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.unitPrice ? props.rowData.unitPrice : ''}
                        name='unitPrice'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '数量(升)',
                field: 'quantity',
                type: 'numeric',
                validate: rowData => (!rowData.quantity || rowData.quantity === '') ? { isValid: false, helperText: '数量不能为空' } : true,
                render: row => <Typography>{row.quantity.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.quantity ? props.rowData.quantity : ''}
                        name='quantity'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '金额(元)',
                field: 'amount',
                type: 'numeric',
                validate: rowData => (!rowData.amount || rowData.amount === '') ? { isValid: false, helperText: '金额不能为空' } : true,
                render: row => <Typography>{row.amount.toFixed(2)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.amount ? props.rowData.amount : ''}
                        name='amount'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            // {
            //     title: '卡号',
            //     field: 'opentext',
            //     editable: 'never',
            //     render: row => <Typography>{row.opentext}</Typography>
            // },
            // {
            //     title: '持卡人',
            //     field: 'cardHolder',
            //     editable: 'never',
            //     render: row => <Typography>{row.cardHolder}</Typography>
            // },
            // {
            //     title: '卡余额(元)',
            //     field: 'cardBalance',
            //     editable: 'never',
            //     type: 'numeric',
            //     render: row => <Typography>{row.cardBalance ? row.cardBalance.toFixed(2) : ''}</Typography>
            // },
            // {
            //     title: '交易类型',
            //     field: 'tradeType',
            //     editable: 'never',
            //     render: row => <Typography>{row.tradeType}</Typography>
            // },

        ]

        return columns

    }

    const assemblyData = () => {
        return refuelRecordList;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container direction="row" className={classes.gridContainer}>
                    <Typography variant="h3">
                        {"加油记录"}
                    </Typography>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2} className={classes.gridContainer}>
                    <Grid item>
                        <Typography>
                            单位:
                        </Typography>
                    </Grid>
                    <Grid item className={classes.companySelect}>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId || ''}
                        >
                            {companies.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Typography>
                            加油日期:
                        </Typography>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                style={{ marginTop: '8px' }}
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={startDate}
                                onChange={(event) => onhandleDateChange(event, 'startDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <Typography>
                            至
                        </Typography>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                style={{ marginTop: '8px' }}
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={endDate}
                                onChange={(event) => onhandleDateChange(event, 'endDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {/* <Grid item>
                        <Button variant='contained' color='primary' onClick={onhandleUpload} >
                            <CloudUploadIcon />上传
                        </Button>
                    </Grid> */}
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'> </Typography>}
                        actions={checkPermission() ? [
                            {
                                icon: 'get_app',
                                tooltip: '下载加油记录模板',
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                    onhandleDownload()
                                }
                            },
                            {
                                icon: 'backup',
                                tooltip: '导入加油记录',
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                    onhandleUpload()
                                }
                            },
                        ] : []}
                        editable={checkPermission() ?
                            {
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        resolve()
                                        onhandleUpdate(newData)
                                    })
                            } : {}
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

RefuelRecordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

RefuelRecordComponent.defaultProps = {
    refuelRecordList: []
};

export default withStyles(RefuelRecordComponent);