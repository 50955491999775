import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Chip,
    Grid,
    Link,
    TextField,
    InputAdornment,
    Tooltip,
    FormHelperText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { AttachFile, CloudUpload } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import BuildIcon from '@material-ui/icons/Build';
import { truncate } from '@/utils/string';

const WorkRecordPanel = ({
    classes,
    open,
    recordError,
    record,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleUpload,
    onhandleDeleteFile,
    onhandleDownloadFile,
    onhandleClearDevice,
    onhandleSelectDevice,
}) => {

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{
                        "作业记录"
                    }</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"作业设备"}
                                variant='outlined'
                                className={classes.textField}
                                value={record.deviceName || ''}
                                name='deviceName'
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position={'end'}>
                                        <Tooltip title="清除">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => onhandleClearDevice()}
                                            >
                                                <CancelOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="选择设备">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={event => onhandleSelectDevice()}
                                            >
                                                <BuildIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"记录描述"}
                                variant='outlined'
                                className={classes.textField}
                                error={recordError.content !== ''}
                                helperText={recordError.content}
                                value={record.content || ''}
                                name='content'
                                rows={6}
                                multiline
                                onChange={event => onhandleChange(event, 'content')}
                                onBlur={event => onhandleCheck(event, 'content')}
                            />
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"记录结果"}
                                variant='outlined'
                                className={classes.textField}
                                error={recordError.result !== ''}
                                helperText={recordError.result}
                                value={record.result || ''}
                                name='result'
                                rows={6}
                                multiline
                                onChange={event => onhandleChange(event, 'result')}
                                onBlur={event => onhandleCheck(event, 'result')}
                            />
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"工作用时"}
                                variant='outlined'
                                type="number"
                                className={classes.textField}
                                error={recordError.workTime !== ''}
                                helperText={recordError.workTime}
                                value={record.workTime || ''}
                                name='workTime'
                                InputProps={{
                                    endAdornment: <InputAdornment position={'end'}>{'分钟'}</InputAdornment>,
                                }}
                                onChange={event => onhandleChange(event, 'workTime')}
                                onBlur={event => onhandleCheck(event, 'workTime')}
                            />
                        </Grid>
                        {
                            record.docs && record.docs.length > 0 &&
                            <div>
                                {
                                    record.docs.map(doc => (
                                        <Grid item className={classes.itemGrid}>
                                            <Chip
                                                icon={<AttachFile />}
                                                onClick={onhandleDownloadFile}
                                                onDelete={onhandleDeleteFile}
                                                variant="outlined"
                                                title={doc.docName}
                                                label={truncate(doc.docName, 20)}
                                            />
                                        </Grid>
                                    ))
                                }
                            </div>
                        }
                        {
                            (!record.docs || !record.docs.length > 0) &&
                            <Grid item container className={classes.itemGrid} justify={'space-between'}>
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={Boolean(recordError.file) ? classes.uploadErr : null}
                                        onClick={onhandleUpload}
                                        startIcon={<CloudUpload />}
                                    >
                                        {(record.docs) ? '变更文档' : '上传文档'}
                                    </Button>
                                    <FormHelperText className={classes.uploadErrText}>{recordError.file}</FormHelperText>
                                </Grid>
                            </Grid>
                        }
                        {
                            record.file && record.file.length && record.file.length > 0 &&
                            <Grid item className={classes.itemGrid} alignItems={'flex-end'} container>
                                <Typography align={'right'}>已选择文件（保存时上传）：</Typography>
                                <Link underline='always' component="button">{record.file[0].name}</Link>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {
                            record.recordId ? '保存' : '新增'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
WorkRecordPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
};

WorkRecordPanel.defaultProps = {

}

export default withStyles(WorkRecordPanel);