import { withStyles } from '@material-ui/styles';
import { tableStyle, default as antdCustomStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    normalTable: tableStyle,
    oddRow: antdCustomStyle.oddRow,
    flexgrow: {
        flexGrow: 1
    },
});


export default withStyles(styles);