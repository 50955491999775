import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    rules: [], //能耗分配规则列表
};

export const setRules = createAction('@@xcloud/amoeba/setRules');

export const getRules = createAction('@@xcloud/amoeba/getRules',
    (energyType) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/rules'
        let strs = []
        if (energyType) {
            if (energyType) strs.push('energyType=' + energyType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setRules(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取能耗分配规则列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addRule = createAction('@@xcloud/amoeba/addRule',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/rules`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "能耗分配规则生成成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `能耗分配规则生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateRule = createAction('@@xcloud/amoeba/updateRule',
    (ruleId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/rules/${ruleId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新能耗分配规则成功"));
                }
            }).catch(err => {
                let msg = `更新能耗分配规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteRule = createAction('@@xcloud/amoeba/deleteRule',
    (ruleId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/amoeba/rules/${ruleId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除能耗分配规则成功"));
                }
            }).catch(err => {
                let msg = `删除能耗分配规则失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const amoebaRuleReducer = handleActions(
    {
        [setRules]: (state, { payload: data }) => {
            return {
                ...state,
                rules: data
            }
        },
    },
    initialState
)

export default amoebaRuleReducer
