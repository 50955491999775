import React from 'react';
import {
    Button,
    Popover,
    Chip,
    Grid,
    TextField,
    InputAdornment,
    Typography
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';

const ValuationFormulaPopComponent = props => {
    const { classes,
        anchorEl,
        attrList,
        serviceName,
        valuationFormulaStr,
        outputFormulaStr,
        formulaType,
        onhandlePopChange,
        onhandlePopClose,
        onhandleResetClick,
        onhandleSetClick,
        onhandleAttrClick,
    } = props;

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onhandlePopClose}>
            <div className={classes.popContent}>
                {serviceName &&
                    <Grid item>
                        <Typography variant="h3">{serviceName}</Typography>
                    </Grid>}
                <Grid container>
                    <Typography variant="h5">计算公式:</Typography>
                    {formulaType === 'OUTPUT' && <TextField
                        label=''
                        name='outputFormulaStr'
                        InputProps={
                            {
                                style: {
                                    width: '300px',
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <p></p>
                                    </InputAdornment>),
                            }
                        }
                        multiline
                        onChange={onhandlePopChange}
                        value={outputFormulaStr || ''}
                    ></TextField>}
                    {formulaType === 'VALUATION' && <TextField
                        label=''
                        name='valuationFormulaStr'
                        InputProps={
                            {
                                style: {
                                    width: '300px',
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <p></p>
                                    </InputAdornment>),
                            }
                        }
                        multiline
                        onChange={onhandlePopChange}
                        value={valuationFormulaStr || ''}
                    ></TextField>}

                    <Grid item>
                        <Button size='small' variant="contained" color="primary"
                            onClick={onhandleResetClick} className={classes.button}>重设</Button>
                        <Button size='small' variant="contained" color="primary"
                            onClick={onhandleSetClick} className={classes.button}>设定</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    {attrList.map((data) => {
                        return (
                            <Grid className={classes.chip} key={data.id}>
                                <Chip
                                    label={data.name}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => onhandleAttrClick(data)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </Popover>
    )
}

ValuationFormulaPopComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    anchorEl: PropTypes.object,
    attrList: PropTypes.array,
    serviceName: PropTypes.string,
    valuationFormulaStr: PropTypes.string,
    onhandlePopChange: PropTypes.func,
    onhandlePopClose: PropTypes.func,
    onhandleResetClick: PropTypes.func,
    onhandleSetClick: PropTypes.func,
    onhandleAttrClick: PropTypes.func,
};

ValuationFormulaPopComponent.defaultProps = {

};

export default withStyles(ValuationFormulaPopComponent);