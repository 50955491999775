import React, { Component } from 'react';
import { connect } from 'react-redux';
import DailyAmoebaReportComponent from '@/components/AmoebaManagement/Report/DailyAmoebaReport';
import PropTypes from 'prop-types';
import { getMoment, formatDay } from '@/utils/datetime';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getBudgets } from '@/redux/modules/amoeba/amoebaPlan';
import { getAmoebaReports, generateAmoebaReport, getReportFixeds } from '@/redux/modules/amoeba/amoebaReport';
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { openTrack, getIncomes, getMaterialCosts } from '@/redux/modules/amoeba/reportTrack';
import { openTrends, getTrends } from '@/redux/modules/amoeba/reportTrend';
import { filterAmoebaCompany } from "@/utils/constant";
import { getCompanyName, getDepartmentsByRole } from '@/utils/userUtils';
import * as _ from 'lodash'
import ReportTrackContainer from './ReportTrackContainer';
import ReportTrendsContainer from './ReportTrendsContainer';

class DailyAmoebaReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            innerWidth: window.innerWidth,
            selectedCompanyId: 0,
            companies: [],
            sortSubs: [],
            dpts: [],
            pid: null,
            dptIds: '',
            selectedDate: getMoment()(),
            roleFlag: this.props.roleRoutes.find(ele => ele.route === 'amoeba_report').action === 'W',
            planDisplay: false,
        }
    }

    getReportViewType(companyId) {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_report').action === 'W'
        if (roleFlag) {
            if (companyId) {
                return 'COMPANY'
            } else {
                return 'ALLCOMPANY'
            }
        } else {
            if (this.getPid()
                && !this.props.userDepartment.executable) {
                return 'COMPANY'
            } else {
                return 'DEPARTMENT'
            }
        }
    }


    getDpts(date, companyId) {
        let dpts = [];
        if (!date) {
            date = getMoment()();
        }
        if (!companyId) {
            companyId = this.props.currentUserInfo.companyId;
        }
        const day = formatDay(date);
        dpts = _.filter(this.props.departments, d => {
            return d.companyId === companyId
                && d.amoebaType !== 'OTHER'
        })

        //添加部门inactive的判断
        dpts = _.filter(dpts, d => {
            return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= day)) ||
                (!d.endDate || (formatDay(d.startDate) <= day && formatDay(d.endDate) >= day))
        })
        let stateDpts = _.cloneDeep(dpts);
        stateDpts.unshift({
            dptId: 0,
            dptName: _.find(this.props.companies, { 'companyId': companyId }).companyName,
            companyId: this.getPid() ? companyId : 0
        })

        this.setState({
            dpts: stateDpts
        })


        return dpts;

    }

    // 页面初期化获取数据
    componentDidMount() {
        const { currentUserInfo, userDepartment, companies,
            getReportFixeds, getAmoebaReports, onhandleGetBudgets } = this.props
        const date = getMoment()();
        const day = formatDay(date);
        const pid = this.getPid();
        const viewType = this.getReportViewType(0)
        let dpts = this.getDpts(null, null)
        const dptIds = _.map(dpts, 'dptId').join(',')
        if (viewType === 'COMPANY' || viewType === 'DEPARTMENT') { //分厂或者总公司的部门
            this.setState({ companies: [], selectedCompanyId: currentUserInfo.companyId });
            getReportFixeds(day, currentUserInfo.companyId, dptIds)
            onhandleGetBudgets(currentUserInfo.companyId, viewType === 'COMPANY' ? null : userDepartment.dptId, date.year(), date.month() + 1, date.date())
            getAmoebaReports(day, currentUserInfo.companyId, dptIds).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        } else {
            const findCompanies = _.cloneDeep(companies)
            const cpy = { companyId: 0, companyName: "工程公司", companyAlias: "工程公司" };
            findCompanies.unshift(cpy);
            this.setState({ companies: findCompanies, selectedCompanyId: 0 });
            getReportFixeds(day, null, null)
            onhandleGetBudgets(0, null, date.year(), date.month() + 1, date.date())
            getAmoebaReports(day, null, null).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        }
        this.setState({
            // dpts: dpts,
            pid: pid,
            dptIds: dptIds,
        })
    }

    getSortAmoebaIndexes(length) {
        if (length === 0) {
            this.setState({
                sortSubs: [],
            })
        } else {
            this.props.getAmoebaIndexes().then(res => {
                let sortSubs = [];
                const parentSubs = _.filter(res.payload, { 'subParentId': 0 });
                _.forEach(parentSubs, value => {
                    sortSubs.push(value)
                    const subSubs = _.filter(res.payload, { 'subParentId': value.subId });
                    subSubs.forEach(sub => {
                        sortSubs.push(sub)
                        const subs = _.filter(res.payload, { 'subParentId': sub.subId });
                        if (subs.length !== 0) sortSubs = sortSubs.concat(subs)
                    })
                })
                this.setState({
                    sortSubs: sortSubs,
                })
            })
        }
    }


    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }

    handlePlanDisplay(event) {
        this.setState({ planDisplay: event.target.checked })
    }



    handleGenerateAmoebaReport() {
        this.props.createloading();
        const { companies, departments, currentUserInfo, indexes, generateAmoebaReport, getAmoebaReports } = this.props;
        const companyId = currentUserInfo.companyId;
        const { selectedDate, dptIds } = this.state;
        let dptList = [];
        let companyList = [];
        companyList = _.map(companies, c => { return c.companyId })
        dptList = _.filter(departments, dd => { return { companyId: dd.companyId, dptId: dd.dptId } })
        const subList = indexes.map(ele => {
            return {
                subId: ele.subId,
                version: ele.version,
            }
        })
        const date = formatDay(selectedDate);
        const [year, month, day] = date.split('/');

        let param = {
            companyId: companyId,
            reportDate: date,
            year: year,
            month: month,
            day: day,
            companyList: companyList,
            dptList: dptList,
            subList: subList,
        }
        // console.log(dptList)
        generateAmoebaReport(param, () => {
            if (this.state.pid || !this.state.roleFlag) {
                this.props.getReportFixeds(date, companyId, dptIds)
                getAmoebaReports(date, companyId, dptIds).then(res => this.getSortAmoebaIndexes(res.payload.length))
            } else {
                this.props.getReportFixeds(formatDay(date), null, null)
                getAmoebaReports(formatDay(date), null, null).then(res => this.getSortAmoebaIndexes(res.payload.length))
            }
        }).then(
            () => this.props.cleanloading()
        );
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = _.find(this.props.companies, { 'companyId': companyId })
        this.refreshReport({
            selectedCompanyId: companyId,
            pid: company ? company.pid : 0,
        })
    }

    handleDateChange = (date) => {
        this.refreshReport({
            selectedDate: getMoment()(date)
        })
    }

    refreshReport = (changeState) => {

        const { userDepartment, getReportFixeds, onhandleGetBudgets, getAmoebaReports } = this.props

        let newState = {
            ...this.state
            , ...changeState
        }

        const viewType = this.getReportViewType(newState.selectedCompanyId)

        if (viewType === 'COMPANY' || viewType === 'DEPAERMENT') {
            let dpts = this.getDpts(newState.selectedDate, newState.selectedCompanyId);
            delete newState.dpts;
            let dptIds = _.map(dpts, 'dptId').join(',')
            getReportFixeds(formatDay(newState.selectedDate), newState.selectedCompanyId, dptIds)
            onhandleGetBudgets(newState.selectedCompanyId, viewType === 'COMPANY' ? null : userDepartment.dptId, newState.selectedDate.year(), newState.selectedDate.month() + 1, newState.selectedDate.date())
            getAmoebaReports(formatDay(newState.selectedDate), newState.selectedCompanyId, dptIds).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
                this.setState({
                    ...newState,
                    dpts: this.state.dpts,
                })
            })
        } else {
            getReportFixeds(formatDay(newState.selectedDate), null, null)
            onhandleGetBudgets(0, null, newState.selectedDate.year(), newState.selectedDate.month() + 1, newState.selectedDate.date())
            getAmoebaReports(formatDay(newState.selectedDate), null, null).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
                this.setState({
                    ...newState,
                })
            })
        }
    }

    handleOpenTrack = (row, value, field) => {

        let query = {
            startDate: this.state.selectedDate.format('YYYY-MM-DD'),
            offset: 0,
            size: 0,
        }
        if (field === 'dptId') {
            query.dptIds = value[field]
            if (!value[field]) query.companyId = value['companyId']
        } else {
            query.companyId = value[field]
        }

        query.subIds = row.subId

        if (_.indexOf(['LABORINCOME'
            , 'TRANSPORTINCOME'
            , 'ENGINEERINCOME'
            , 'MARKETINCOME'
            , 'TRADEINCOME'
            , 'PRODUCEINCOME'
            , 'PROCESSINCOME'
            , 'SELFINCOME'
            , 'INNERINCOME'], row.subCode) > -1) {

            this.props.onhandleGetIncomes(query.companyId,
                query.dptIds,
                query.subIds,
                query.startDate,
                query.endDate,
                query.offset,
                query.size,
                query.sort)
            this.props.onhandleOpenTrack('INCOME')
        }else if (_.indexOf(['MAJORMATERIAL','OTHERMATERIAL','LABORPROTECT','OFFICE'], row.subCode)>-1){
            this.props.onhandleGetMaterialCosts(query.companyId,
                query.dptIds,
                query.subIds,
                query.startDate,
                query.endDate,
                query.offset,
                query.size,
                query.sort)
            this.props.onhandleOpenTrack('MATERIALCOST')
        }
    }

    handleOpenTrends = (row) => {
        let query = {
            timeDim: 'DAY',
            startDate: getMoment()(this.state.selectedDate).startOf('month').format('YYYY-MM-DD'),
            endDate: getMoment()(this.state.selectedDate).format('YYYY-MM-DD'),
            subIds: row.subId
        }

        query.orgDim = this.getReportViewType(this.state.selectedCompanyId)

        if (query.orgDim === "COMPANY") {
            query.companyIds = this.state.selectedCompanyId || this.props.userProfile.companyId
        }
        if (query.orgDim === "DEPARTMENT") {
            query.dptIds = this.props.userDepartment.dptId || 0
        }
        const showDpts = _.map(this.state.dpts, 'dptId');
        this.props.onhandleGetTrends(query.orgDim, query.companyIds, query.dptIds, query.subIds, query.timeDim, query.startDate, query.endDate, showDpts);

        const trendInfo = {
            ...query,
            subInfo: row
        }
        this.props.onhandleOpenTrends(trendInfo)
    }

    render() {
        return (
            <div>
                <DailyAmoebaReportComponent
                    selectedDate={this.state.selectedDate}
                    companies={this.state.companies}
                    sortSubs={this.state.sortSubs}
                    selectedCompanyId={this.state.selectedCompanyId}
                    reports={this.props.reports}
                    reportFixeds={this.props.reportFixeds}
                    dpts={this.state.dpts}
                    viewType={this.getReportViewType(this.state.selectedCompanyId)}
                    pid={this.state.pid}
                    roleFlag={this.state.roleFlag}
                    constant={this.props.constant}
                    budgets={this.props.budgets}
                    userDepartment={this.props.userDepartment}
                    planDisplay={this.state.planDisplay}
                    innerWidth={this.state.innerWidth}
                    onhandlePlanDisplay={this.handlePlanDisplay.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onGenerateAmoebaReport={this.handleGenerateAmoebaReport.bind(this)}
                    onhandleOpenTrack={this.handleOpenTrack.bind(this)}
                    onhandleOpenTrends={this.handleOpenTrends.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                />
                <ReportTrackContainer />
                <ReportTrendsContainer />
            </div>

        );
    }
}

const getUserDpt = (roleId, userProfile, departments) => {
    const userDpts = getDepartmentsByRole(roleId, userProfile)
    if (userDpts.length > 0 && departments && departments.length > 0) {
        return _.find(departments, { dptId: userDpts[0].dptId })
    } else {
        return {}
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        userDepartment: getUserDpt(state.auth.currentUserInfo.roleId, state.auth.userProfile, state.department.departments.list),
        companyName: getCompanyName(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        reports: state.amoebaReport.reports,
        reportFixeds: state.amoebaReport.reportFixeds,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        companies: filterAmoebaCompany(state.company.companyList.list),
        departments: state.department.allDepartments.list,
        indexes: state.amoebaIndex.indexes,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        budgets: state.amoebaPlan.budgets,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, null, null));
        },
        getAmoebaReports: (day, companyIds, dptIds) => {
            const year = day.split("/")[0];
            const month = day.split("/")[1];
            const date = day.split("/")[2];
            return dispatch(getAmoebaReports(null, year, month, date, null, companyIds, dptIds, null));
        },
        // onhandlegetDailyPlans: (companyIds, year, month, planType, notSaveFlag, isMainCompany, day) => {
        //     return dispatch(getDailyPlans(companyIds, year, month, planType, notSaveFlag, isMainCompany, day))
        // },

        onhandleGetBudgets: (companyId, dptId, year, month, day) => {
            return dispatch(getBudgets(companyId, dptId, year, month, day, 'DAY_REPORT'))
        },

        getReportFixeds: (day, companyIds, dptIds) => {
            const year = day.split("/")[0];
            const month = day.split("/")[1];
            const date = day.split("/")[2];
            return dispatch(getReportFixeds(year, month, date, null, companyIds, dptIds, null));
        },
        generateAmoebaReport: (param, callback) => {
            return dispatch(generateAmoebaReport(param, callback));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
        onhandleOpenTrack: (trackType) => {
            return dispatch(openTrack(trackType));
        },
        onhandleGetIncomes: (companyId, dptIds, subIds, startDate, endDate, offset, size, sort) => {
            return dispatch(getIncomes(companyId, dptIds, subIds, startDate, endDate, offset, size, sort));
        },
        onhandleGetMaterialCosts: (companyId, dptIds, subIds, startDate, endDate, offset, size, sort) => {
            return dispatch(getMaterialCosts(companyId, dptIds, subIds, startDate, endDate, offset, size, sort));
        },
        onhandleOpenTrends: (trendInfo) => {
            return dispatch(openTrends(trendInfo));
        },
        onhandleGetTrends: (orgDim, companyId, dptIds, subIds, timeDim, startDate, endDate, showDpts) => {
            return dispatch(getTrends(orgDim, companyId, dptIds, subIds, timeDim, startDate, endDate, showDpts));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DailyAmoebaReportContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}