import React, { useState, useEffect } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { options, localization } from "../../../utils/mtable";
import '../../../assets/rc-tree.css';
import '../../../assets/rc-tree-select-tree.css';
import { getCompanyId } from "../../../utils/userUtils";
import _ from 'lodash';
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {
    Grid,
    TextField,
    Button,
    Typography,
    TablePagination,
    Card,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    RadioGroup,
    Radio,
    Select,
    Input,
    InputLabel,
    FormControl,
    FormControlLabel,
    ListItemText,
    Checkbox,
    Chip
    // Switch,
} from '@material-ui/core';
import {
    Add,
    // SaveAlt,
    ExpandMore,
    ExpandLess,
    Edit,
    PauseCircleFilled,
    DeleteOutlined,
    PlayCircleOutline,
} from '@material-ui/icons';


const RuleListComponent = props => {
    const {
        classes,
        search,
        onhandleDeleteRule,
        userProfile,
        companyTree,
        companyList,
        inspectionDictionaries,
        ruleList,
        onhandleSelect,
        onhandleClearSearch,
        onhandleSearch,
        getRuleList,
        onSizeChange,
        onhandleCreate,
        //onhandleEdit,
        departmentList,
        currentUserInfo,
        frequencyOpen,
        onhandleFrequencyEdit,
        onhandleFrequencyClose,
        frequency,
        onhandleChange,
        routeList,
        onhandleAddTime,
        onhandleCancelTime,
        onhandleCustomTimes,
        onhandleFrequencySave,
        onhandlePauseRule,
        onhandleRestartRule,
        currentRule
    } = props;

    // console.log('===== currentRule =====')
    // console.log(currentRule)

    const [expand, setExpand] = useState(false);
    const [tableHeight, setTableHeight] = useState(null);
    const [page, setPage] = useState(0);

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }
        return false
    }

    // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
    const noOptionDisabled = (optionList) => {
        if (optionList && optionList.length > 0) {
            return false
        }

        return true
    }

    const getInspectionTypeList = () => {
        let result = [];
        _.map(inspectionDictionaries, t => {
            if (t.type === "OITYPE" && t.code !== 'OIT05') {
                result.push({ label: t.name, value: t.code })
            }
        })

        return result;
    };

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_rule' })
        return permission && permission.action === 'W'
    }

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215  // window.innerHeight * 100; //px
        // const tableBodyHeight = (window.innerHeight - 215 * (window.outerWidth - 8) / window.innerWidth) / window.innerHeight * 100; //vh
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const showCustomTime = (index) => (
        <React.Fragment key={"fqtime" + index}>
            <TextField
                type="time"
                value={frequency.customTimes[index] || ''}
                onChange={event => onhandleCustomTimes(event, index)}
                className={classes.customTime}
            />
            <IconButton onClick={event => onhandleCancelTime(index)} aria-label="add">
                <CancelIcon />
            </IconButton>
        </React.Fragment>
    );

    const showEditDialog = () => (
        <React.Fragment>
            {/* 缺陷 巡检计划配置 Dialog 禁止[点击空白处关闭] XJGL-170 */}
            {/* <Dialog maxWidth="xs" fullWidth={true} open={frequencyOpen} onClose={event => onhandleFrequencyClose(event)} aria-labelledby="form-dialog-title"> */}
            <Dialog maxWidth="md" fullWidth={true} open={frequencyOpen} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                        设置配置信息
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid className={classes.warnContainer}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            placeholder="请选择巡检路线"
                            label="巡检路线"
                            name='routeId'
                            value={currentRule.routeId || ''}
                            onChange={event => onhandleChange(event, 'routeId')}
                        >
                            {getRouteData(getCompany()).map((item) => {
                                return <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid className={classes.warnContainer}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel id="select-outlined-label">执行班组</InputLabel>
                            <Select
                                labelId="select-outlined-label"
                                label="执行班组"
                                fullWidth
                                multiple
                                value={currentRule.dptIds ? currentRule.dptIds : []}
                                onChange={event => onhandleChange(event, 'dptIds')}
                                renderValue={() => (
                                    renderDpt(currentRule)
                                )}
                            >
                                {getDptData(getCompany()).map((item) => {
                                    return <MenuItem dense key={item.value} value={item.value}>
                                        <Checkbox className={classes.checkbox} size={'small'} checked={currentRule.dptIds && currentRule.dptIds.indexOf(item.value) > -1} />
                                        <ListItemText primary={item.label} />
                                        {/* {item.label} */}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        currentRule.dptIds && currentRule.dptIds.length === 1 &&
                        <Grid className={classes.warnContainer}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <FormControlLabel label={<Typography style={currentRule.isShift ? { fontWeight: 600 } : { color: 'gray' }}>使用班组排班制</Typography>} control={
                                    // <Switch size="small" checked={currentRule.isShift} onChange={event => onhandleChange(event, 'isShift')} name="isShift" />
                                    <Checkbox size={'small'}
                                        checked={currentRule.isShift}
                                        onChange={event => onhandleChange(event, 'isShift')} />
                                } />
                            </FormControl>
                            <Typography></Typography>
                        </Grid>
                    }
                    <Typography variant="h3" className={classes.warnTitle}>设置巡检限时</Typography>
                    <Grid>
                        <Grid container className={classes.warnContainer}>
                            <Typography>在</Typography>
                            <TextField
                                value={currentRule.expiredAt || ''}
                                type="number"
                                size="small"
                                className={classes.intervalInput}
                                onChange={(event) => onhandleChange(event, 'expiredAt')}
                            />
                            <Typography>分钟内完成巡检</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h3" className={classes.warnTitle}>设置巡检频次</Typography>
                    <Grid>
                        <Grid container className={classes.timeContainer}>
                            <Radio
                                checked={frequency.freqType === 'P'}
                                onChange={(event) => onhandleChange(event, 'freqType')}
                                value="P"
                                name="radio-type-a"
                                // inputProps={{ 'aria-label': 'A' }}
                                size="small"
                            />
                            <span><Typography>每</Typography></span>
                            <TextField
                                type="number"
                                size="small"
                                value={frequency.freqP || ''}
                                onChange={event => onhandleChange(event, 'freqP')}
                                className={classes.intervalInput}
                            />
                            <span><Typography>小时一次，开始时间为每天</Typography></span>
                            <TextField
                                type="time"
                                value={frequency.timeP || ''}
                                onChange={event => onhandleChange(event, 'timeP')}
                                className={classes.timeInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container className={classes.timeContainer}>
                            <Radio
                                checked={frequency.freqType === 'C'}
                                onChange={(event) => onhandleChange(event, 'freqType')}
                                value="C"
                                name="radio-type-b"
                                // inputProps={{ 'aria-label': 'B' }}
                                size="small"
                            />
                            <span><Typography>每天</Typography></span>
                            <TextField
                                type="number"
                                size="small"
                                value={frequency.freqC || ''}
                                onChange={event => onhandleChange(event, 'freqC')}
                                className={classes.intervalInput}
                            />
                            <span><Typography>次，开始时间为每天</Typography></span>
                            <TextField
                                type="time"
                                value={frequency.timeC || ''}
                                onChange={event => onhandleChange(event, 'timeC')}
                                className={classes.timeInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container className={classes.timeContainer}>
                            <Radio
                                checked={frequency.freqType === 'S'}
                                onChange={(event) => onhandleChange(event, 'freqType')}
                                value="S"
                                name="radio-type-c"
                                size="small"
                            />
                            <span><Typography>自定义时间</Typography></span>
                            <TextField
                                type="time"
                                value={frequency.customTime || ''}
                                onChange={event => onhandleChange(event, 'customTime')}
                                className={classes.timeInput}
                            />
                            <IconButton onClick={event => onhandleAddTime()} aria-label="add">
                                <AddCircleIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid container className={classes.customGrid}>
                            {frequency.customTimes.map((item, index) => {
                                return showCustomTime(index)
                            })}
                        </Grid>
                    </Grid>

                    <Typography variant="h3" className={classes.warnTitle}>设置提醒时间</Typography>
                    <Grid>
                        <Grid container className={classes.warnContainer}>
                            <Typography>第一次提醒时间：执行开始后</Typography>
                            <TextField
                                value={frequency.warnTime1 || ''}
                                type="number"
                                size="small"
                                className={classes.intervalInput}
                                onChange={(event) => onhandleChange(event, 'warnTime1')}
                            />
                            <Typography>分钟</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container className={classes.warnContainer}>
                            <Typography>第二次提醒时间：执行开始后</Typography>
                            <TextField
                                value={frequency.warnTime2 || ''}
                                type="number"
                                size="small"
                                className={classes.intervalInput}
                                onChange={(event) => onhandleChange(event, 'warnTime2')}
                            />
                            <Typography>分钟</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h3" className={classes.warnTitle}>设置生效时间</Typography>
                    <Grid>
                        <Grid container className={classes.warnContainer}>
                            {currentRule.status !== 'paused' ?
                                <RadioGroup row aria-label="生效时间" name="immeEffect"
                                    value={currentRule.immeEffect || ''}
                                    onChange={(event) => onhandleChange(event, 'immeEffect')}>
                                    <FormControlLabel value={'false'} control={<Radio size={'small'} />} label="次日生效" />
                                    <FormControlLabel value={'true'} control={<Radio size={'small'} />} label="立即生效" />
                                </RadioGroup>
                                :
                                <Typography>{'巡检暂停中，恢复后生效'}</Typography>
                            }
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={event => onhandleFrequencyClose(event)} >取消</Button>
                    <Button variant="contained" color="primary" onClick={event => onhandleFrequencySave(event)} >保存</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    const renderInspectionType = (row) => {
        let item = _.filter(inspectionDictionaries, function (o) { return row.inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    };

    const renderCompany = (row) => {
        let item = _.filter(companyList, function (o) { return row.companyId === o.value })
        if (item && item.length) {
            return item[0].label
        } else {
            return ''
        }
    };

    const renderDpt = (row) => {

        let dptNames = '';
        let dpts = row.dptIds;
        for (let i in dpts) {
            let item = _.filter(departmentList, function (o) { return parseInt(dpts[i]) === o.dptId })

            if (item && item.length) {
                dptNames += ", " + item[0].dptName;
            }
        }
        return dptNames.substring(1);
    };

    const renderStatus = (s) => {
        let status = { 'active': '执行中', 'paused': '暂停中' };
        return status[s]
    }

    // const getInspectionTypeData = () => {
    //     let data = _.filter(inspectionDictionaries, function (o) { return o.type === 'OITYPE' })
    //     if (data && data.length > 0) {
    //         return data
    //     } else {
    //         return []
    //     }
    // }

    // const getCompanyData = () => {
    //     if (companyDisabled()) {
    //         let companyId = getCompanyId(currentUserInfo.roleId, userProfile)
    //         let selectedCompany = _.find(companyList, ['value', companyId])
    //         return [selectedCompany]
    //     } else {
    //         return companyList
    //     }
    // }

    const getCompany = () => {
        let companyId = getCompanyId(currentUserInfo.roleId, userProfile)

        return companyId
    }

    /* SelectTree组件情况下执行班组取得函数 */
    // const getDptData = (companyId) => {
    //     let item = _.filter(departmentList, function (o) { return companyId === o.companyId })
    //     if (item && item.length) {
    //         return item
    //     } else {
    //         return []
    //     }
    // }
    const getDptData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        let item = _.filter(departmentList, (o) => {
            const mngFlag = o.executable === false && !o.pid && o.amoebaType === 'COST';
            return _.toInteger(company) === o.companyId && (o.executable === true || mngFlag)
        })
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.dptName, value: t.dptId })
            })
        }
        return result
    }


    const renderRoute = (row) => {
        let item = _.filter(routeList, function (o) { return row.routeId === o.routeId })
        if (item && item.length) {
            return item[0].routeName
        } else {
            return ''
        }
    };

    /* SelectTree组件情况下路线取得函数 */
    // const getRouteData = (companyId) => {
    //     let item = _.filter(routeList, function (o) { return companyId === o.companyId })
    //     if (item && item.length) {
    //         return item
    //     } else {
    //         return []
    //     }
    // }
    const getRouteData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        let item = _.filter(routeList, function (o) { return _.toInteger(company) === o.companyId })
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.routeName, value: t.routeId })
            })
        }
        return result
    }

    const validateDptId = (rowData) => {
        if (rowData.dptIds && rowData.dptIds.length === 0) {
            return { isValid: false, helperText: '执行班组不能为空' }
        }
        // let item = _.filter(departmentList, function (o) { return rowData.dptId === o.dptId })
        // if (item && item.length) {
        //     if (item[0].companyId !== rowData.companyId) {
        //         //return { isValid: false, helperText: '选择的单位中不存在该班组' }
        //         return { isValid: false, helperText: '执行班组不能为空' }
        //     }
        // } else {
        //     //return { isValid: false, helperText: '选择的单位中不存在该班组' }
        //     return { isValid: false, helperText: '执行班组不能为空' }
        // }

        return true
    }

    const validateRouteId = (rowData) => {
        if (_.trim(rowData.routeId) === '') {
            return { isValid: false, helperText: '巡检路线不能为空' }
        }
        // let item = _.filter(routeList, function (o) { return rowData.routeId === o.routeId })
        // if (item && item.length) {
        //     if (item[0].companyId !== rowData.companyId) {
        //         //return { isValid: false, helperText: '选择的单位中不存在该路线' }
        //         return { isValid: false, helperText: '巡检路线不能为空' }
        //     }
        // } else {
        //     //return { isValid: false, helperText: '选择的单位中不存在该路线' }
        //     return { isValid: false, helperText: '巡检路线不能为空' }
        // }

        return true
    }

    const renderRuleTime = (row) => {
        let frequencyTitle = ''
        if (row.freqType === 'P') {
            let interval = Math.floor(row.freq / 60);
            let hour = Math.floor(row.startTime / 60);
            let minute = row.startTime % 60
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            frequencyTitle = interval + '小时/次,' + hour + ':' + minute + '开始'
        }

        if (row.freqType === 'C') {
            let hour = Math.floor(row.startTime / 60);
            let minute = row.startTime % 60
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            frequencyTitle = row.freq + '次/天,' + hour + ':' + minute + '开始'
        }

        if (row.freqType === 'S') {
            let result = []
            _.map(row.times.split(','), t => {
                let hour = Math.floor(t / 60);
                let minute = t % 60
                if (hour < 10) {
                    hour = '0' + hour
                }
                if (minute < 10) {
                    minute = '0' + minute
                }
                result.push(hour + ':' + minute)
            })
            frequencyTitle = result.join(',')
        }

        if (checkPermission()) {
            if (row.tableData && row.tableData.editing === 'update') {
                return frequencyTitle ? <Typography>{frequencyTitle}</Typography> : ''
            } else {
                //return row ? <Button className={classes.cellButton} onClick={event => onhandleFrequencyEdit(event, row)} color="primary">{frequencyTitle}</Button> : ''
                return frequencyTitle ? <Typography>{frequencyTitle}</Typography> : ''
            }
        } else {
            return frequencyTitle ? <Typography>{frequencyTitle}</Typography> : ''
        }
    };

    const assemblycolumns = () => {
        return [
            {
                title: '巡检类型',
                field: 'inspectionType',
                render: row => row ? <Typography>{renderInspectionType(row)}</Typography> : '',
                editable: 'never',
                width: 100,
                // validate: row => (!row.inspectionType || row.inspectionType === '') ? { isValid: false, helperText: '巡检类型不能为空' } : true,
                // editComponent: (props) => (
                //     <TextField
                //         error={props.helperText}
                //         helperText={props.helperText || ''}
                //         select
                //         value={props.rowData.inspectionType ? props.rowData.inspectionType : ''}
                //         name='inspectionType'
                //         onChange={e => props.onChange(e.target.value)}>
                //         {getInspectionTypeData().map((item) => {
                //             return <MenuItem key={item.code} value={item.code}>
                //                 {item.name}
                //             </MenuItem>
                //         })}
                //     </TextField>
                // )
            },
            {
                title: '所属单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                editable: 'never',
                // validate: row => (!row.companyId || row.companyId === '') ? { isValid: false, helperText: '所属单位不能为空' } : true,
                // editComponent: (props) => (
                //     <TextField
                //         error={props.helperText}
                //         helperText={props.helperText || ''}
                //         select
                //         value={props.rowData.companyId ? props.rowData.companyId : ''}
                //         name='companyId'
                //         onChange={e => props.onChange(e.target.value)}>
                //         {getCompanyData().map((item) => {
                //             return <MenuItem key={item.value} value={item.value}>
                //                 {item.label}
                //             </MenuItem>
                //         })}
                //     </TextField>
                // )
            },
            {
                title: '巡检路线',
                field: 'routeId',
                render: row => row ? <Typography>{renderRoute(row)}</Typography> : '',
                //validate: row => (!row.routeId || row.routeId === '') ? { isValid: false, helperText: '巡检路线不能为空' } : true,
                validate: validateRouteId,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.routeId ? props.rowData.routeId : ''}
                        name='routeId'
                        onChange={e => props.onChange(e.target.value)}>
                        {getRouteData(getCompany()).map((item) => {
                            return <MenuItem key={item.routeId} value={item.routeId}>
                                {item.routeName}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '执行班组',
                field: 'dptIds',
                render: row => row ? <Typography>{renderDpt(row)}</Typography> : '',
                validate: validateDptId,
                editComponent: (props) => (
                    <Select
                        error={props.helperText}
                        multiple
                        value={props.rowData.dptIds ? props.rowData.dptIds : []}
                        onChange={e => {
                            props.onChange(e.target.value)
                        }}
                        input={<Input />}
                    >
                        {getDptData(getCompany()).map((item) => {
                            return <MenuItem key={item.dptId} value={item.dptId}>
                                {item.dptName}
                            </MenuItem>
                        })}
                    </Select>
                    // <TextField
                    //     error={props.helperText}
                    //     helperText={props.helperText || ''}
                    //     select
                    //     multiple
                    //     value={props.rowData.dptId ? props.rowData.dptId : ''}
                    //     name='dptId'
                    //     onChange={e => props.onChange(e.target.value)}>
                    //     {getDptData(getCompany()).map((item) => {
                    //         return <MenuItem key={item.dptId} value={item.dptId}>
                    //             {item.dptName}
                    //         </MenuItem>
                    //     })}
                    // </TextField>
                )
            },
            {
                title: '巡检时间',
                field: 'ruleTime',
                render: renderRuleTime,
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => {
                    let statusStyle = classes.active;
                    if (row.status === 'paused') statusStyle = classes.paused;
                    return <Chip size="small" className={statusStyle} label={renderStatus(row.status)} />
                },
                editable: 'never',
                width: 100,
                align: 'center'
            }
        ];
    };

    return (<>
        <Card className={classes.content}>
            <Grid container alignItems="center" className={classes.title}>
                <Typography variant="h3">
                    {'计划配置'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={6}>
                    <Grid container spacing={0} className={classes.gridContainer}>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                所属单位
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-companyid"
                                select
                                value={search.companyId}
                                onChange={(event) => onhandleSelect(event, 'companyId')}
                                variant="outlined"
                                disabled={companyDisabled()}
                            >
                                {_.sortBy(companyList, 'companyId').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                巡检类型
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-inspectiontype"
                                select
                                value={search.inspectionType}
                                onChange={(event) => onhandleSelect(event, 'inspectionType')}
                                variant="outlined"
                            >
                                {_.sortBy(getInspectionTypeList(), 'inspectionType').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                            <Typography className={classes.label}>
                                巡检路线
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                id="filled-select-routeid"
                                select
                                value={search.routeId}
                                onChange={(event) => onhandleSelect(event, 'routeId')}
                                variant="outlined"
                                disabled={noOptionDisabled(getRouteData(search.companyId))}
                            >
                                {_.sortBy(getRouteData(search.companyId), 'routeId').map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        {Boolean(expand) && <React.Fragment>
                            <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                                <Typography className={classes.label}>
                                    执行班组
                                </Typography>
                                <TextField
                                    className={classes.textFieldInput}
                                    size={'small'}
                                    id="filled-select-dptid"
                                    select
                                    value={search.dptId}
                                    onChange={(event) => onhandleSelect(event, 'dptId')}
                                    variant="outlined"
                                    disabled={noOptionDisabled(getDptData(search.companyId))}
                                >
                                    {_.sortBy(getDptData(search.companyId), 'dptId').map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </React.Fragment>}

                    </Grid>
                </Grid>

                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                        >
                            搜索
                        </Button>
                        <Button variant="outlined" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >
                            重置
                        </Button>
                        <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
                            onClick={() => setExpand(!expand)}
                            endIcon={expand ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expand ? '收起' : '展开'}
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={(event) => {
                                let newData = {};
                                newData.companyId = getCompany()
                                newData.dptIds = []
                                newData.immeEffect = 'false'
                                onhandleCreate(newData)
                            }}
                        >
                            新建
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={assemblycolumns()}
                    data={ruleList.list}
                    options={{
                        ...options,
                        pageSize: _.toInteger(search.size),
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        maxBodyHeight: `${tableHeight}px`,
                        minBodyHeight: `${tableHeight}px`,
                    }}
                    localization={localization}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[15, 30, 50]}
                                rowsPerPage={_.toInteger(search.size)}
                                count={ruleList.total}
                                page={page}
                                onChangePage={(event, pageNum) => {
                                    setPage(pageNum);
                                    getRuleList(pageNum * _.toInteger(search.size), _.toInteger(search.size), "update_time", "", "", "")
                                }}
                                onChangeRowsPerPage={(event) => {
                                    props.onChangeRowsPerPage(event);
                                    setPage(0);
                                    onSizeChange(event.target.value)
                                    getRuleList(0, event.target.value, "update_time", "", "", "")
                                }}
                            />
                        )
                    }}
                    // editable={checkPermission() ? {
                    //     onRowAdd: (newData) =>
                    //         new Promise((resolve, reject) => {
                    //             resolve()
                    //             newData.companyId = getCompany()
                    //             onhandleCreate(newData)
                    //         }),
                    //     onRowUpdate: (newData) =>
                    //         new Promise((resolve, reject) => {
                    //             resolve()
                    //             onhandleEdit(newData)
                    //         }),
                    //     onRowDelete: (oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             onhandleDeleteRule(oldData)
                    //             resolve()
                    //         }),
                    // } : {}}

                    actions={checkPermission() ?
                        [rowData => ({
                            icon: () => <Edit color={'primary'} />,
                            tooltip: '编辑',
                            onClick: (event, rowData) => {
                                // onhandleEdit(rowData)
                                onhandleFrequencyEdit(event, rowData)
                            },
                        }),
                        rowData => ({
                            icon: () => <PauseCircleFilled color={'error'} />,
                            tooltip: '暂停巡检',
                            hidden: rowData.status === 'paused',
                            onClick: (event, rowData) => {
                                onhandlePauseRule(rowData)
                            }
                        }),
                        rowData => ({
                            icon: () => <PlayCircleOutline color={'primary'} />,
                            tooltip: '恢复巡检',
                            hidden: rowData.status === 'active',
                            onClick: (event, rowData) => {
                                onhandleRestartRule(rowData)
                            }
                        }),
                        rowData => ({
                            icon: () => <DeleteOutlined color={'error'} />,
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onhandleDeleteRule(rowData)
                            }
                        }),
                            // {
                            //     icon: 'add',
                            //     tooltip: '新建',
                            //     isFreeAction: true,
                            //     onClick: (event) => {
                            //         let newData = {};
                            //         newData.companyId = getCompany()
                            //         newData.dptIds = []
                            //         newData.immeEffect = 'false'
                            //         onhandleCreate(newData)
                            //     }
                            // }
                        ] : []
                    }
                >
                </MaterialTable>
            </Grid>
        </Card>
        {showEditDialog()}


    </>);
};
RuleListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    onhandleDeleteRule: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    ruleList: PropTypes.object.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleClearSearch: PropTypes.func.isRequired,
    onhandleSearch: PropTypes.func.isRequired,
    getRuleList: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onhandleCreate: PropTypes.func.isRequired,
    //onhandleEdit: PropTypes.func.isRequired,
    departmentList: PropTypes.array.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    frequencyOpen: PropTypes.bool.isRequired,
    onhandleFrequencyEdit: PropTypes.func.isRequired,
    onhandleFrequencyClose: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    routeList: PropTypes.array.isRequired,
    onhandleAddTime: PropTypes.func.isRequired,
    onhandleCancelTime: PropTypes.func.isRequired,
    onhandleCustomTimes: PropTypes.func.isRequired,
    onhandleFrequencySave: PropTypes.func.isRequired,
    currentRule: PropTypes.object.isRequired,
};

export default withRouter(withStyles(RuleListComponent));