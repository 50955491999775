import { withStyles } from '@material-ui/styles';
import { stripedTableStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    stripedTable: stripedTableStyle,
    root: {
        padding: theme.spacing(0)
    },
    title: {
        padding: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    content: {
        marginTop: theme.spacing(0),
        padding: 20
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridContainer: {
        paddingBottom: theme.spacing(2),
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    textField: {
        width: '100%',
    },
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    warnContainer: {
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
        marginBottom: 10,
        marginLeft: 10
    },
    materialtable: {
        boxShadow: 'none'
    }
});

export default withStyles(styles);