import React, { Component } from 'react';
import { connect } from 'react-redux';
import HazardInfo from "../../../components/InspectionManagement/HazardList/HazardInfo";
import PropTypes from "prop-types";
import {
    backToHazardList,
    getHazardListById,
    updateHazard,
    setHazardChange,
    setCurrentHazard,
    setCurrentHazardBackup,
    setTreatmentScheme,
    getTreatmentScheme,
    getTreatmentSchemeById,
    addTreatmentScheme,
    updateTreatmentScheme,
    changeTreatmentSchemeStatus,
    changeHazardStatus,
    routeToDevicePlan,
    routeToRegulationPlan,
    routeToDeviceList,
    routeToRegulationList,
    createPretreatmentDoc,
    removePretreatmentDoc,
    exportHazardFileById
} from '@/redux/modules/inspection/hazard';
import InspectionTreatmentSchemePannel from './InspectionTreatmentSchemePannel';
import SchemeAcceptPannel from './SchemeAcceptPannel';
import HazardClosePanel from './HazardClosePanel';
import { openTreatmentSchemePannel } from '@/redux/modules/inspection/inspectionTreatmentScheme';
import { openTreatmentSchemeAcceptPannel } from '@/redux/modules/inspection/inspectionSchemeAccept';
import { openHazardClosePanel } from '@/redux/modules/inspection/inspectionHazardClose';

import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import { createmodal, cleanmodal } from "@/redux/modules/modal";
import { downloadURL, upload } from '@/redux/modules/minio'
import {
    getDateByTimestamp,
    getMoment as moment,
    getNowTimestamp
} from '@/utils/datetime';
import { getCompanyId } from "@/utils/userUtils";
import { INSPECTION_DICT_TYPES, getActualCompany, getOptionList } from '@/utils/constant';
import { csv } from '@/utils/exportfile';
import { createMessage } from '@/redux/modules/message';

import _ from 'lodash';

// 找时间做拆分
class HazardInfoContainer extends Component {

    static propTypes = {
        checklists: PropTypes.object
    };
    constructor(props) {
        super(props)
        this.state = {
            search: {
                dtlId: undefined,
                userId: undefined,
                dptId: undefined,
                result: '', // 分值情况下, 6分以上为合格
                offset: 0,
                size: 5,
                sort: "-record_time"
            },
            hazardError: {
                "companyId": '',
                "rootCause": '',
                "severity": '',
                "impactLevel": '',
                "factor": ''
            }
        }
    }

    componentWillMount() {
        let hid = this.props.match.params.hid;
        // let mode = this.props.match.params.mode;
        this.props.onhandleInit(hid);
    }

    handleSelect = (event) => {
        this.props.onhandleHazardChange(true);
        let newHazard = {
            ...this.props.currentHazard
        };
        let fieldName = event.target.name;
        newHazard[fieldName] = event.target.value;

        this.props.onhandleUpdateEditHazard(newHazard);
        // 等currentChecklist状态更新后再check select组件
        setTimeout(() => {
            this.handleCheck(null, fieldName);
        }, 100)
    }

    handleSave = async (event, status) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.state.hazardError), function (o) { return o !== ''; }) < 0) {
            const { currentHazard, onhandleHazardChange } = this.props;
            onhandleHazardChange(false);
            if (currentHazard.hid) {
                const to = {
                    hid: currentHazard.hid,
                    companyId: currentHazard.companyId ? parseInt(currentHazard.companyId) : undefined,
                    rootCause: currentHazard.rootCause,
                    severity: currentHazard.severity,
                    impactLevel: currentHazard.impactLevel,
                    factor: currentHazard.factor,
                    status: status ? status : undefined
                }
                this.props.onhandleUpdateHazardList(to);
            }
        }
    }

    handleStartTreatment = () => {
        const {
            currentHazard,
            treatmentSchemeList,
            onhandleChangeHazardStatus,
            onhandleCreateMessage,
            onhandleInit
        } = this.props;
        if (treatmentSchemeList.total > 0) {
            let changeStatus = () => {
                onhandleChangeHazardStatus(currentHazard.hid, 'treating', () => {
                    onhandleInit(currentHazard.hid);
                });
            }
            this.props.onhandleOpenConfirmDialog(`确认`, `是否开始本隐患的治理?`, changeStatus, null, false);
        } else {
            onhandleCreateMessage('warning', '需要添加治理方案，并完成所有方案的审批');
        }
    }

    handleOpenCloseHazardModal = () => {
        const {
            currentHazard,
            onhandleOpenClosePanel
        } = this.props;
        onhandleOpenClosePanel(this.handleCloseHazard.bind(this), null, currentHazard.opentext, currentHazard.hid);
    }

    handleCloseHazard(hazard) {
        const {
            currentHazard,
            onhandleUpdateHazardList
        } = this.props;

        const newHazard = {
            hid: currentHazard.hid,
            reason: hazard.reason,
            status: 'invalid'
        };
        onhandleUpdateHazardList(newHazard);
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field || field === 'companyId') {
            if (_.trim(this.props.currentHazard.companyId) === '') {
                err['companyId'] = '属地单位不能为空';
            } else {
                err['companyId'] = '';
            }
        }

        if (!field || field === 'rootCause') {
            if (_.trim(this.props.currentHazard.rootCause) === '') {
                err['rootCause'] = '根因不能为空';
            } else {
                err['rootCause'] = '';
            }
        }

        if (!field || field === 'severity') {
            if (_.trim(this.props.currentHazard.severity) === '') {
                err['severity'] = '隐患级别不能为空';
            } else {
                err['severity'] = '';
            }
        }

        if (!field || field === 'impactLevel') {
            if (_.trim(this.props.currentHazard.impactLevel) === '') {
                err['impactLevel'] = '危害程度不能为空';
            } else {
                err['impactLevel'] = '';
            }
        }

        if (!field || field === 'factor') {
            if (_.trim(this.props.currentHazard.factor) === '') {
                err['factor'] = '安全管理要素不能为空';
            } else {
                err['factor'] = '';
            }
        }

        let newError = {
            ...this.state.hazardError,
            ...err
        }
        this.setState({ hazardError: newError });
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            departments,
            inspectionDict,
            userList
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyName : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldId }) ? _.find(departments, { 'dptId': fieldId }).companyDptName : '';
                break;
            case 'inspectionDict':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '';
                break;
            case 'user':
                const user = _.find(userList, { 'userId': fieldId });
                value = user ? user.lastName + user.firstName : '';
                break;
            case 'result':
                if (fieldId && fieldId !== '') {
                    if (fieldId === 'true') {
                        value = '合格';
                    } else if (fieldId === 'false') {
                        value = '不合格';
                    } else {
                        value = fieldId + '分'
                    }
                }
                break;
            case 'status':
                let status = { 'active': '待审定', 'evaluated': '待治理', 'treating': '治理中', 'done': '已治理', 'invalid': '已销项' }; // 'accepting': '待验收',
                value = status[fieldId]
                break;
            case 'schemeStatus':
                let schemeStatus = {
                    'new': '待提交',
                    'rejected': '审批未通过',
                    'submitted': '待审批',
                    'inprocess': '治理中',
                    'finalized': '待验收',
                    'pending': '待审核',
                    'toreview': '待评审',
                    'done': '已整改',
                    'invalid': '验收未通过'
                };
                value = schemeStatus[fieldId]
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleSchemePlanMapping = (treatmentScheme, fieldValue, field) => {

        const { schemeId, schemeCat, hazard } = treatmentScheme;
        const { rootCause } = hazard;

        if (!schemeId || !schemeCat || !rootCause) {
            this.props.onhandleCreateMessage('error', '隐患信息异常，请重试');
            return false;
        }
        let value = '';
        switch (field) {
            case 'planType':
                // 物的不安全状态，设备运维临时/年度计划
                if (rootCause === 'RC02' && schemeCat === 'TRE01' && fieldValue === 'OPERATION') { value = '临时设备运维计划' }
                if (rootCause === 'RC02' && schemeCat === 'TRE02' && fieldValue === 'OPERATION') { value = '年度设备运维计划' }

                // 管理缺陷，规章制度完善临时/年度计划
                if (rootCause === 'RC03' && schemeCat === 'TRE01' && fieldValue === 'REGULATION') { value = '临时规章制度完善计划' }
                if (rootCause === 'RC03' && schemeCat === 'TRE02' && fieldValue === 'REGULATION') { value = '年度规章制度完善计划' }
                break;
            case 'status':
                value = '未完成';
                // 物的不安全状态，设备运维临时/年度计划
                if (rootCause === 'RC02' && fieldValue === 'done') { value = '已完成' }
                // 管理缺陷，规章制度完善临时/年度计划
                if (rootCause === 'RC03' && fieldValue === 'finalized') { value = '已完成' }
                break;
            default:
        }
        return value;
    }

    handleSchemePlanLink = (planType) => {
        if (planType === 'OPERATION') this.props.onhandleRouteToDeviceList();
        if (planType === 'REGULATION') this.props.onhandleRouteToRegulationList();
    }

    handleBack = (event) => {
        this.props.onhandleBackToHazardList(this.props.currentHazard.status);
    };

    handleHazardAttach = () => {
        const {
            currentHazard,
            onhandleOpenHazardDocs,
            inspectionDict
        } = this.props;
        const {
            docs
        } = currentHazard;
        if (docs && docs.length) {
            const promiseArr = [];
            docs.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'uat';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.inspection.hazard-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docs.forEach((doc, index) => {
                    doc.url = res[index].payload;

                    let inspectTypeStr = '随机检查';
                    let inspectFullStr = inspectTypeStr;
                    let categoryStr = '';
                    if (currentHazard.recordId) {
                        inspectTypeStr = this.handleListMapping(currentHazard.inspectType.substr(0, 5), 'inspectionDict');
                        if (_.find(inspectionDict, { 'pcode': currentHazard.inspectType.substr(0, 5) })) {
                            categoryStr = this.handleListMapping(currentHazard.inspectType, 'inspectionDict');
                            inspectFullStr = inspectTypeStr + '(' + categoryStr + ')';
                        }
                    }
                    let recordTimeStr = currentHazard.createTime ? moment()((currentHazard.createTime)).format('YYYYMMDD') : '';

                    doc.downloadName = [
                        currentHazard.serialNo,
                        inspectFullStr,
                        this.handleListMapping(currentHazard.companyId, 'company'),
                        this.handleListMapping(currentHazard.creator, 'user')
                    ].join('_') + '_' + recordTimeStr
                        + '.' + doc.docName.split('.').pop().toLowerCase(); // 后缀名
                })
                onhandleOpenHazardDocs({
                    docs: docs,
                    title: '隐患记录附件'
                });
            }).catch(err => {

            });
        }
    }

    handleAcceptAttach = (treatmentScheme) => {
        const {
            currentHazard,
            onhandleOpenHazardDocs,
            inspectionDict
        } = this.props;
        const {
            acceptDocs
        } = treatmentScheme;
        if (acceptDocs && acceptDocs.length) {
            const promiseArr = [];
            acceptDocs.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'uat';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.inspection.pretreatment-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                acceptDocs.forEach((doc, index) => {
                    doc.url = res[index].payload;

                    let inspectTypeStr = '随机检查';
                    let inspectFullStr = inspectTypeStr;
                    let categoryStr = '';
                    if (currentHazard.recordId) {
                        inspectTypeStr = this.handleListMapping(currentHazard.inspectType.substr(0, 5), 'inspectionDict');
                        if (_.find(inspectionDict, { 'pcode': currentHazard.inspectType.substr(0, 5) })) {
                            categoryStr = this.handleListMapping(currentHazard.inspectType, 'inspectionDict');
                            inspectFullStr = inspectTypeStr + '(' + categoryStr + ')';
                        }
                    }
                    let recordTimeStr = currentHazard.createTime ? moment()((currentHazard.createTime)).format('YYYYMMDD') : '';

                    doc.downloadName = [
                        '验收依据',
                        currentHazard.serialNo,
                        inspectFullStr,
                        // this.handleListMapping(currentHazard.companyId, 'company'),
                        this.handleListMapping(treatmentScheme.accepter, 'user')
                    ].join('_') + '_' + recordTimeStr
                        + '.' + doc.docName.split('.').pop().toLowerCase(); // 后缀名
                })
                onhandleOpenHazardDocs({
                    docs: acceptDocs,
                    title: '验收凭据'
                });
            }).catch(err => {

            });
        }
    }

    handleOpenAddPannel(rowData, isTreatmentHead, editable) {
        const { onhandleOpenAddPannel, currentHazardBackup, onhandleGetTreatmentSchemeById } = this.props;
        if (rowData) {
            onhandleGetTreatmentSchemeById(rowData.schemeId, (data) => {
                onhandleOpenAddPannel(this.handleSubmit.bind(this), null, currentHazardBackup, data, isTreatmentHead, editable);
            });
        } else {
            onhandleOpenAddPannel(this.handleSubmit.bind(this), null, currentHazardBackup, null, isTreatmentHead, editable);
        }
    }

    handleSubmit(treatmentScheme) {
        const {
            onhandleCreateTreatmentScheme,
            onhandleUpdateTreatmentScheme,
            onhandleInit,
            currentHazardBackup,
            userProfile
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;

        let newTS = {
            schemeId: treatmentScheme.schemeId ? treatmentScheme.schemeId : undefined,
            hid: currentHazardBackup.hid,
            companyId: currentHazardBackup.companyId,
            dptId: treatmentScheme.dptId ? _.toNumber(treatmentScheme.dptId) : undefined,
            schemeCat: treatmentScheme.schemeCat,
            measure: treatmentScheme.measure || '',
            head: treatmentScheme.head,
            cost: treatmentScheme.cost ? _.toNumber(treatmentScheme.cost) : undefined,
            targetDate: treatmentScheme.targetDate ? moment()(treatmentScheme.targetDate).endOf('day').valueOf() : undefined,
            pretreatment: treatmentScheme.pretreatment || '',
            sanction: treatmentScheme.sanction || '',
            accepter: treatmentScheme.accepter ? _.toNumber(treatmentScheme.accepter) : undefined,
            opentext: treatmentScheme.opentext || ''
        }
        let envCode = 'uat';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.inspection.pretreatment-doc', '{tenant_id}', tenantId);

        if (!newTS.schemeId) {
            onhandleCreateTreatmentScheme(newTS, (resScheme) => {
                if (!treatmentScheme.file || !treatmentScheme.file.length || treatmentScheme.file.length === 0) {
                    onhandleInit(currentHazardBackup.hid);
                    return;
                };
                let offset = getNowTimestamp();
                // 上传文件
                this.props.onhandleMinioUpload(treatmentScheme.file, mybucket, offset, () => {
                    let doc = {
                        docId: 0,
                        schemeId: resScheme.schemeId,
                        docName: treatmentScheme.file[0].name,
                        docLink: offset + treatmentScheme.file[0].name,
                        docType: 'DOC_FILE',
                    };
                    this.props.onhandleCreateDoc(doc);
                    this.props.onhandleModalClose();
                    onhandleInit(currentHazardBackup.hid);
                });
            })
        } else {
            onhandleUpdateTreatmentScheme(newTS, (resScheme) => {
                // 删除旧文档
                if (treatmentScheme.pretreatmentDocsBack && treatmentScheme.pretreatmentDocs.length === 0) {
                    this.props.onhandleRemoveDoc(treatmentScheme.pretreatmentDocsBack[0], mybucket);
                }
                // 判断是否有重传文档操作
                if (!treatmentScheme.file || !treatmentScheme.file.length || treatmentScheme.file.length === 0) {
                    onhandleInit(currentHazardBackup.hid);
                    return;
                };
                let offset = getNowTimestamp();
                // 上传文件
                this.props.onhandleMinioUpload(treatmentScheme.file, mybucket, offset, () => {
                    let doc = {
                        docId: 0,
                        schemeId: resScheme.schemeId,
                        docName: treatmentScheme.file[0].name,
                        docLink: offset + treatmentScheme.file[0].name,
                        docType: 'DOC_FILE',
                    };
                    this.props.onhandleCreateDoc(doc);
                    this.props.onhandleModalClose();
                    onhandleInit(currentHazardBackup.hid);
                });
            })
        }
    }

    handleSetTreatmentPlan(treatmentScheme) {
        const { onhandleCreateMessage, onhandleRouteToCreateDevicePlan, onhandleRouteToCreateRegulationPlan } = this.props;
        const { schemeId, schemeCat, hazard } = treatmentScheme;
        const { rootCause, hid } = hazard;

        if (!schemeId || !schemeCat || !rootCause) {
            onhandleCreateMessage('error', '发生异常，请重试');
            return;
        }
        // 人的不安全行为
        if (rootCause === 'RC01') { onhandleCreateMessage('warning', '人的不安全行为，请组织教育培训后进行验收'); }

        // 物的不安全状态，设备运维临时/年度计划
        if (rootCause === 'RC02' && schemeCat === 'TRE01') { onhandleRouteToCreateDevicePlan(schemeId, 'temp', hid) }
        if (rootCause === 'RC02' && schemeCat === 'TRE02') { onhandleRouteToCreateDevicePlan(schemeId, 'annual', hid) }

        // 管理缺陷，规章制度完善临时/年度计划
        if (rootCause === 'RC03' && schemeCat === 'TRE01') { onhandleRouteToCreateRegulationPlan(schemeId, 'temp', hid) }
        if (rootCause === 'RC03' && schemeCat === 'TRE02') { onhandleRouteToCreateRegulationPlan(schemeId, 'annual', hid) }
    }

    handleUpdateSchemeStatus = (event, treatmentScheme, action) => {
        const { name, next } = action;
        if (next && next === 'submitted') {
            const {
                dptId,
                schemeCat,
                measure,
                head,
                targetDate,
                pretreatment,
                accepter,
                headSign
            } = treatmentScheme;
            if (!dptId || !schemeCat || !measure || !head || !targetDate || (treatmentScheme.schemeCat !== 'TRE03' && !pretreatment) || !accepter) {
                this.props.onhandleCreateMessage('warning', '请补充治理方案后再提交');
                return;
            }
            if (!headSign) {
                this.props.onhandleCreateMessage('warning', '治理人未在手机端完成签名');
                return;
            }
        }
        let changeStatus = () => {
            this.props.onhandleChangeSchemeStatus(treatmentScheme.schemeId, next, () => {
                this.props.onhandleGetTreatmentScheme(treatmentScheme.hid);
            });
        }
        this.props.onhandleOpenConfirmDialog(`${name}确认`, `是否${name}本条方案?`, changeStatus, null, false);
    }

    handleDeleteTreatment = (treatmentScheme) => {
        let changeStatus = () => {
            this.props.onhandleChangeSchemeStatus(treatmentScheme.schemeId, 'inactive', () => {
                this.props.onhandleGetTreatmentScheme(treatmentScheme.hid);
            });
        }
        this.props.onhandleOpenConfirmDialog(`删除确认`, `是否删除本条方案?`, changeStatus, null, false);
    }

    handleAccept = (treatmentScheme) => {
        const {
            userProfile,
            onhandleUpdateTreatmentScheme,
            // onhandleGetTreatmentScheme,
            onhandleInit,
            currentHazard,
            // onhandleChangeHazardStatus
        } = this.props;

        let tenantId = userProfile.roles[0].tenantId;
        let newTS = {
            schemeId: treatmentScheme.schemeId ? treatmentScheme.schemeId : undefined,
            tenantId: tenantId,
            acceptResult: treatmentScheme.acceptResult,
            acceptDate: moment()().valueOf(),
            status: treatmentScheme.status,
        }

        if (newTS.schemeId) {
            onhandleUpdateTreatmentScheme(newTS, (resScheme) => {
                onhandleInit(currentHazard.hid);
            });
        }
    }

    handleOpenAcceptPannel = async (treatmentScheme) => {
        if (await this.checkAcceptable(treatmentScheme)) {
            const { onhandleOpenAcceptPannel, onhandleGetTreatmentSchemeById } = this.props;
            onhandleGetTreatmentSchemeById(treatmentScheme.schemeId, (data) => {
                onhandleOpenAcceptPannel(this.handleAccept.bind(this), null, data);
            });
        }
    }

    checkAcceptable = async (treatmentScheme) => {
        const {
            onhandleCreateMessage
        } = this.props;

        // 21.11.13 手机端加入了签名验收,web端不再开放直接验收功能
        onhandleCreateMessage('error', '请在手机端进行签名，并完成验收');
        return false;
        // // 首先判断该治理方案是否完成计划/预案
        // const { schemeId, schemeCat, hazard, schemePlan, acceptDocs, } = treatmentScheme;
        // const { rootCause } = hazard;

        // if (!schemeId || !schemeCat || !rootCause) {
        //     onhandleCreateMessage('error', '隐患信息异常，请重试');
        //     return false;
        // }
        // if (rootCause !== 'RC01' && schemeCat !== 'TRE03' && (!schemePlan || !schemePlan.length)) {
        //     onhandleCreateMessage('error', '治理方案异常，请重试');
        //     return false;
        // }
        // const noApproveDocs = !acceptDocs || !acceptDocs.length || acceptDocs.length <= 0;
        // const noSignature = !acceptDocs || !acceptDocs.length || acceptDocs.length <= 0;
        // // 人的不安全行为 || 应急预案
        // if (rootCause === 'RC01' || schemeCat === 'TRE03') {
        //     if (noApproveDocs) {
        //         onhandleCreateMessage('error', '无验收凭据，请于手机端拍摄后进行验收');
        //         return false
        //     }
        //     return true
        // } else {
        //     for (const elem of schemePlan) {
        //         const { status } = elem;
        //         // 物的不安全状态，设备运维临时计划
        //         if (rootCause === 'RC02' && schemeCat === 'TRE01' && status.toLowerCase() !== 'done') {
        //             onhandleCreateMessage('error', '运维计划未完成，请完成后验收');
        //             return false;
        //         }
        //         // 物的不安全状态，设备运维年度计划
        //         if (rootCause === 'RC02' && schemeCat === 'TRE02' && status.toLowerCase() !== 'done') {
        //             onhandleCreateMessage('error', '运维计划未完成，请完成后验收');
        //             return false;
        //         }
        //         // 管理缺陷，规章制度完善临时计划
        //         if (rootCause === 'RC03' && schemeCat === 'TRE01' && status.toLowerCase() !== 'finalized') {
        //             onhandleCreateMessage('error', '规章制度完善计划未完成，请完成后验收');
        //             return false;
        //         }
        //         // 管理缺陷，规章制度完善年度计划
        //         if (rootCause === 'RC03' && schemeCat === 'TRE02' && status.toLowerCase() !== 'finalized') {
        //             onhandleCreateMessage('error', '规章制度完善计划未完成，请完成后验收');
        //             return false;
        //         }
        //     };
        //     if (noApproveDocs) {
        //         onhandleCreateMessage('error', '无验收凭据，请于手机端拍摄后进行验收');
        //         return false
        //     }
        //     return true;
        // }

    }

    onhandleExportCsv = () => {
        this.props.onhandleGetPlanRecordsForCsv({
            ...this.state.search,
            offset: 0,
            size: 0,
            sort: "-record_time"
        }, res => {
            this.generateCsvData(res);
        });
    }

    // 未来或实现治理方案的导出
    generateCsvData = (planRecords) => {
        const {
            currentPlanDetail,
            inspectionDict,
            exportCsv,
            onhandleCreateMessage
        } = this.props;

        if (currentPlanDetail && Object.keys(currentPlanDetail).length > 0
            && planRecords && Object.keys(planRecords).length > 0 && planRecords.list.length > 0) {

            let planName = currentPlanDetail.inspectionPlan.planName;
            if (currentPlanDetail.inspectionPlan.planType === 'TEMP') {
                planName = currentPlanDetail.inspectionPlan.year + planName;
            }
            let inspectTypeStr = this.handleListMapping(currentPlanDetail.listType, 'listType');
            let categoryStr = null;
            let inspectFullStr = inspectTypeStr;
            if (_.find(inspectionDict, { 'pcode': this.props.currentPlanDetail.listType })) {
                categoryStr = this.handleListMapping(currentPlanDetail.inspectType, 'listType');
                inspectFullStr = inspectTypeStr + '(' + categoryStr + ')';
            }
            const startTimeStr = currentPlanDetail.startTime ? getDateByTimestamp(currentPlanDetail.startTime) : '';
            let fileName = ['排查实施记录', planName, inspectFullStr].join('_') + '_' + startTimeStr;
            let columns = ['序号', '计划名称', '记录时间', '检查类型', '检查子分类', '根因', '根因子分类', '受检单位', '责任部门', '记录人', '检查内容', '结果', '备注'];
            let dataList = [];
            planRecords.list.forEach(planRecord => {
                let recordTimeStr = planRecord.recordTime ? getDateByTimestamp(planRecord.recordTime, true) : '';
                let data = [
                    planRecord.checklist.serialNo,
                    planName,
                    recordTimeStr,
                    inspectTypeStr,
                    categoryStr || '-',
                    this.handleListMapping(planRecord.checklist.rootCause, 'rootCause') || '-',
                    this.handleListMapping(planRecord.checklist.discipline, 'discipline') || '-',
                    this.handleListMapping(currentPlanDetail.companyId, 'company'),
                    this.handleListMapping(planRecord.inspectionResult.dptId, 'department'),
                    this.handleListMapping(planRecord.inspectionResult.userId, 'user'),
                    planRecord.checklist.problem,
                    this.handleListMapping(planRecord.result, 'result'),
                    planRecord.opentext
                ];
                dataList.push(data);
            });
            exportCsv(fileName, columns, dataList);
            onhandleCreateMessage('success', '导出检查记录成功');
        }
    }

    checkCompany = () => {
        const {
            currentHazardBackup,
            currentUserInfo,
            userProfile,
            companies
        } = this.props;
        let companyId = getCompanyId(currentUserInfo.roleId, userProfile);
        const company = _.find(companies, { companyId: companyId });
        // 机关
        if (company && !company.pid) {
            return true;
        }
        if (company && company.pid && company.pid !== '') {
            return company.companyId === currentHazardBackup.companyId;
        }
    }

    render() {
        return (
            <>
                <HazardInfo
                    editable={this.props.currentHazard.status === 'active'} // active = 初始状态，待审定
                    isCompanySupervisor={this.checkCompany()}
                    userProfile={this.props.userProfile}
                    companies={this.props.companies}
                    currentHazard={this.props.currentHazard}
                    currentHazardBackup={this.props.currentHazardBackup}
                    hazardError={this.state.hazardError}
                    hazardChange={this.props.hazardChange}
                    treatmentSchemeList={this.props.treatmentSchemeList}
                    companyLeaders={this.props.companyLeaders}

                    onhandleSave={this.handleSave.bind(this)}
                    onhandleStartTreatment={this.handleStartTreatment.bind(this)}
                    onhandleCloseHazard={this.handleOpenCloseHazardModal.bind(this)}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onHazardAttach={this.handleHazardAttach.bind(this)}
                    onAcceptAttach={this.handleAcceptAttach.bind(this)}
                    onhandleGetTreatmentScheme={this.props.onhandleGetTreatmentScheme}
                    onhandleListMapping={this.handleListMapping.bind(this)}
                    onhandleSchemePlanMapping={this.handleSchemePlanMapping.bind(this)}
                    search={this.state.search}

                    onEditTreatment={this.handleOpenAddPannel.bind(this)}
                    onSetTreatmentPlan={this.handleSetTreatmentPlan.bind(this)}
                    onDeleteTreatment={this.handleDeleteTreatment.bind(this)}
                    onhandleUpdateSchemeStatus={this.handleUpdateSchemeStatus.bind(this)}
                    onAcceptTreatment={this.handleOpenAcceptPannel.bind(this)}
                    onSchemePlanLink={this.handleSchemePlanLink.bind(this)}
                    onhandleExportFile={this.props.onhandleExportHazardFileById.bind(this)}

                    rootCauseList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.ROOTCAUSE)}
                    severityList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.SEVERITY)}
                    impactLevelList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.IMPACTLEVEL)}
                    factorList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.FACTORTYPE)}
                />
                <InspectionTreatmentSchemePannel />
                <SchemeAcceptPannel />
                <HazardClosePanel />
            </>
        )
    }
}

const getCompanyLeader = (userList, currentHazardBackup) => {
    return userList.filter((user) => (user.companyId === currentHazardBackup.companyId))
}

const mapStateToProps = (state) => {
    return {
        hazardChange: state.hazard.hazardChange,
        users: state.user.leaders,
        companyLeaders: getCompanyLeader(state.user.leaders, state.hazard.currentHazardBackup),
        userList: state.hazard.userList,
        categoryList: state.inspection.categoryList,

        companies: getActualCompany(state.constant.companies),
        departments: state.constant.departments,
        inspectionDict: state.constant.inspectionDictionaries,

        currentHazard: state.hazard.currentHazard,
        treatmentSchemeList: state.hazard.treatmentScheme,
        currentHazardBackup: state.hazard.currentHazardBackup,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInit: (hid) => {
            dispatch(setCurrentHazard({}));
            dispatch(setCurrentHazardBackup({}));
            dispatch(setTreatmentScheme({}));
            dispatch(setHazardChange(false));
            dispatch(getHazardListById(hid));
            dispatch(getTreatmentScheme({ hid: hid }));
        },
        onhandleGetTreatmentScheme: (hid, callback) => {
            dispatch(getTreatmentScheme({ hid: hid }, callback))
        },
        onhandleGetTreatmentSchemeById: (schemeId, callBack) => {
            dispatch(getTreatmentSchemeById(schemeId, callBack))
        },
        onhandleBackToHazardList: () => {
            dispatch(backToHazardList());
        },
        onhandleUpdateHazardList: (entity) => {
            dispatch(updateHazard(entity));
        },
        onhandleHazardChange: (isChanged) => {
            dispatch(setHazardChange(isChanged))
        },
        onhandleUpdateEditHazard: (newHazard) => {
            dispatch(setCurrentHazard(newHazard))
        },
        onhandleOpenAddPannel: (confirmCallback, closeCallback, hazard, treatmentScheme, isTreatmentHead, editable) => {
            dispatch(openTreatmentSchemePannel(confirmCallback, closeCallback, hazard, treatmentScheme, isTreatmentHead, editable))
        },
        onhandleOpenClosePanel: (confirmCallback, closeCallback, opentext, hid) => {
            dispatch(openHazardClosePanel(confirmCallback, closeCallback, opentext, hid))
        },
        onhandleOpenAcceptPannel: (confirmCallback, closeCallback, treatmentScheme) => {
            dispatch(openTreatmentSchemeAcceptPannel(confirmCallback, closeCallback, treatmentScheme))
        },
        onhandleMinioUpload: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleUploadPre: () => {
            dispatch(createMessage('error', '上传的文件格式不支持'));
        },
        onhandleCreateDoc: (doc) => {
            dispatch(createPretreatmentDoc(doc));
        },
        onhandleRemoveDoc: (doc, mybucket) => {
            dispatch(removePretreatmentDoc(doc, mybucket));
        },
        onhandleCreateTreatmentScheme: (treatmentScheme, callback) => {
            dispatch(addTreatmentScheme(treatmentScheme, callback))
        },
        onhandleUpdateTreatmentScheme: (entity, callback) => {
            dispatch(updateTreatmentScheme(entity, callback));
        },
        onhandleChangeHazardStatus: (id, status, callback) => {
            dispatch(changeHazardStatus(id, status, callback));
        },
        onhandleChangeSchemeStatus: (id, status, callback) => {
            dispatch(changeTreatmentSchemeStatus(id, status, callback));
        },
        onhandleRouteToCreateDevicePlan: (schemeId, planType, hid) => {
            dispatch(routeToDevicePlan(schemeId, planType, hid));
        },
        onhandleRouteToCreateRegulationPlan: (schemeId, planType, hid) => {
            dispatch(routeToRegulationPlan(schemeId, planType, hid));
        },
        onhandleRouteToDeviceList: (id) => {
            dispatch(routeToDeviceList(id));
        },
        onhandleRouteToRegulationList: (id) => {
            dispatch(routeToRegulationList(id));
        },
        exportCsv: (fileName, columns, data) => {
            dispatch(csv(fileName, columns, data))
        },
        onhandleExportHazardFileById: (hid) => {
            dispatch(exportHazardFileById(hid));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleOpenHazardDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config, 'medium'));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HazardInfoContainer)
