import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '@/redux/modules/message'
import {
    getManufactureServices
} from '@/redux/modules/manufacture/manufactureService';
import { judgeMainCompany, SERVICE_TYPE_ALL } from '@/utils/manufactureUtils';
import ServiceSharingComponent from '@/components/AmoebaManagement/SharingCost/ServiceSharing';
import {
    getSharingTakeHistories,
    initServiceSharing,
    setSharingTakelist,
    addCostSharing,
    getSharingTakeById,
    addSharingTake,
    updateSharingTake,
    getCostSharingList
} from '@/redux/modules/amoeba/amoebaCostSharing';
import * as _ from 'lodash';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import SharingTakeHistory from '@/components/AmoebaManagement/SharingCost/SharingTakeHistory';

/**
 * 服务分摊
 */


class ServiceSharingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectIndex: this.props.manufactureDicts[0].code,
            selectedOfDpt: this.props.selectedDpt,
            selectedService: "",
            subCompanyList: [],
            selectedCompany: this.props.companies[0].companyId,
            open: false,
            addRoleFlag: true,
            sharingTakeHistoryOpen: false,
        }
    }

    componentWillMount() {
        this.props.onhandleGetCostSharingList();
        const { getServices, companies, currentUserInfo } = this.props;
        let flag = judgeMainCompany(companies, currentUserInfo.companyId);
        // 获取母公司Id
        if (flag) {
            getServices(currentUserInfo.companyId, currentUserInfo.companyId, (data) => {
                if (data && data.length > 0) {
                    //获取服务分摊
                    this.getSharingTakeList(data[0].serviceId);
                }
            });
        } else {
            let reqMainCompanyId = companies.find(ele => ele.companyId === currentUserInfo.companyId).pid;
            getServices(currentUserInfo.companyId, reqMainCompanyId, (data) => {
                if (data && data.length > 0) {
                    if (data[0].requireCompany !== this.props.currentUserInfo.companyId) {
                        this.setState({ addRoleFlag: false })
                    } else {
                        this.setState({ addRoleFlag: true })
                    }
                    //获取服务分摊
                    this.getSharingTakeList(data[0].serviceId);
                }

            });
        }

        this.setState({ isMainCompany: flag })
    }

    handleSelectChange = (event) => {
        const sd = _.find(this.props.services, item => {
            return item.serviceId === Number(event.target.value)
        })

        //总公司选中分厂不能添加、分厂选中需方总公司服务不能添加
        let reqMainCompanyId = this.props.companies.find(ele => ele.companyId === this.props.currentUserInfo.companyId).pid;
        if ((!this.state.isMainCompany && sd.requireCompany && sd.requireCompany === reqMainCompanyId) || (this.state.isMainCompany && this.props.currentUserInfo.companyId !== this.state.selectedCompany)) {
            this.setState({ addRoleFlag: false })
        } else {
            this.setState({ addRoleFlag: true })
        }
        this.getSharingTakeList(event.target.value);

    }

    getSharingTakeList = (serviceId) => {
        this.setState({ selectedOfService: serviceId })
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.shareType === this.state.selectIndex && c.shareService === parseInt(serviceId)
        })

        if (cs) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        } else {
            this.props.onhandlesetSharingTakelist()
        }
    }

    handleAdd = (value) => {
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.shareType === this.state.selectIndex && c.shareService === parseInt(this.state.selectedOfService)
        })

        if (cs !== undefined) {
            value['sharingId'] = cs.sharingId
            value['percentage'] = parseFloat(value['percentage'])
            value['takerCompany'] = parseInt(value['takerCompany'])
            value['takerDpt'] = parseInt(value['takerDpt'])
            this.props.onhandleAdd(value);
        } else {

            const cost = {
                shareService: parseInt(this.state.selectedOfService),
                sharerCompany: this.props.currentUserInfo.companyId,
            }

            this.props.onhandleAddCost(cost, this.state.selectIndex).then(
                (res) => {
                    value['sharingId'] = res.payload.sharingId
                    value['percentage'] = parseFloat(value['percentage'])
                    value['takerCompany'] = parseInt(value['takerCompany'])
                    value['takerDpt'] = parseInt(value['takerDpt'])
                    this.props.onhandleAdd(value);
                    this.props.onhandleGetCostSharingList();
                }
            )
        }
    }

    handleDelete = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'status': 'inactive'
        }
        this.props.onhandleUpdate(newData);
    }

    handleUpdate = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'takerCompany': parseInt(value['takerCompany']),
            'takerDpt': parseInt(value['takerDpt']),
            'percentage': parseFloat(value['percentage']),
            'fixed': parseFloat(value['fixed']),
            'valuationType': value['valuationType'],
        }
        this.props.onhandleUpdate(newData);
    }

    handleCreateServiceCost = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleSave = () => {
        this.setState({ open: false })
    }

    handleTextChange = (event) => {
        if (event.target.value) {
            this.setState({ selectedService: event.target.value });
        }
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const { getServices, currentUserInfo } = this.props;

        getServices(companyId, currentUserInfo.companyId, (data) => {
            if (data && data.length > 0) {
                this.getSharingTakeList(data[0].serviceId);
                this.setState({ selectedOfService: data[0].serviceId })
            }
        })

        if (this.props.currentUserInfo.companyId !== Number(companyId)) {
            this.setState({ addRoleFlag: false })
        } else {
            this.setState({ addRoleFlag: true })
        }

        this.setState({
            selectedCompany: Number(companyId)
        })
    }
    handleOpenHistory = (value) => {
        this.setState({ sharingTakeHistoryOpen: true })
        this.props.onhandleGetSharingTakeHistories(value['sharingId'], value['takeId'])
    }

    handleCloseHistory = () => {
        this.setState({ sharingTakeHistoryOpen: false })
    }
    render() {
        let roleFlag = false;
        if (this.props.roleRoutes.find(ele => ele.route === 'amoeba_service_sharing')) {
            roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_service_sharing').action === 'W';
        }

        return (
            <div>
                <ServiceSharingComponent
                    selectIndex={this.state.selectIndex}
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    companies={this.props.companies}
                    addRoleFlag={this.state.addRoleFlag}
                    commissionServices={this.props.services}
                    sharingTakelist={this.props.sharingTakelist}
                    costSharinglist={this.props.costSharinglist}
                    selectedDpt={parseInt(this.props.selectedDpt)}
                    selectedOfDpt={this.state.selectedOfService}
                    selectedCompany={this.state.selectedCompany}
                    isMainCompany={this.state.isMainCompany}
                    roleFlag={roleFlag}
                    manufactureDicts={this.props.manufactureDicts}
                    valuations={this.props.valuations}
                    onhandleMessage={this.props.onhandleMessage}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleCreateServiceCost={this.handleCreateServiceCost.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleOpenHistory={this.handleOpenHistory.bind(this)}

                />
                <SharingTakeHistory
                    open={this.state.sharingTakeHistoryOpen}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    histories={this.props.sharingTakeHistories}
                    users={this.props.users}
                    onhandleClose={this.handleCloseHistory.bind(this)}
                    valuations={this.props.valuations}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        companies: state.constant.companies,
        costSharinglist: state.amoebaCostSharing.costSharinglist,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
        selectedDpt: state.amoebaCostSharing.selectedDpt,
        services: state.manufactureService.services,
        manufactureDicts: _.filter(state.constant.manufactureDicts, d => {
            return d.type === 'SHARING_TYPE' && d.code === 'SHARING_SERVICE'
        }),
        valuations: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.OTHER_SHARE_TYPE
        }),
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        sharingTakeHistories: state.amoebaCostSharing.sharingTakeHistories,
        users: state.usercard.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (shareType) => {
            return dispatch(initServiceSharing(shareType));
        },
        onhandleGetSharingTakeList: (sid) => {
            dispatch(getSharingTakeById(sid));
        },
        onhandlesetSharingTakelist: () => {
            dispatch(setSharingTakelist([]));
        },
        onhandleAdd: (value) => {
            dispatch(addSharingTake(value, 'TYPE_DEPARTMENT'));
        },
        onhandleGetCostSharingList: () => {
            dispatch(getCostSharingList("SHARING_SERVICE"));
        },
        onhandleAddCost: (value, type) => {
            return dispatch(addCostSharing(value, type));
        },
        onhandleUpdate: (value) => {
            dispatch(updateSharingTake(value.takeId, value));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        },
        getServices: (requireCompany, reqMainCompanyId, callBack) => {
            dispatch(getManufactureServices(requireCompany, SERVICE_TYPE_ALL, null, null, reqMainCompanyId, callBack));
        },
        onhandleGetSharingTakeHistories: (sid, takeId, status) => {
            dispatch(getSharingTakeHistories(sid, takeId, status));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceSharingContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}