import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    List,
    Button,
    Typography
} from '@material-ui/core';
import { getTimeUnitByCode, getDateByTimestamp } from "../../../utils/datetime";
import { findBuNameById } from "../../../utils/constant";

import ProcessLine from '../../ProcessLine';
import MaterialTable, { } from 'material-table';
import { localization, options, style } from "../../../utils/mtable";
//import getCurrentProcessStatus from '../../../redux/modules/process;'
//getCurrentProcessStatus('RegulationPlanExecution', null, callback)

import * as _ from 'lodash';

const RegulationPlanExecution = ({
    classes,
    businessUnits,
    currentPlanDetails,
    constant,
    onhandleProcess,
    onOpenUploadModal,
    onDownLoadDoc
    //currentProcessStatus
}) => {

    // const [expanded, setExpanded] = useState(false)

    let checkEditable = (currentProcessStatus, planType) => {
        if(planType === 'Delete') return false
        if (currentProcessStatus.statusCode !== 'Drafting' && currentProcessStatus.statusCode !== 'Rejected')
            return false       
        return true
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: false
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                align: 'center',
                render: row => row && <Typography>{findBuNameById(constant, row.unitId)}</Typography>,
                editable: 'never',
                cellStyle: (value, rowData) => {
                    if (rowData && rowData.unitDuplicated) {
                        return { border: ' 0px solid black', }
                    }
                    return {}
                },
                disableClick: true,
            },
            {
                title: '公司规章制度',
                field: 'regName',
                align: 'left',
                disableClick: true,
                render: row => <Typography>{row.regName}</Typography>
            },
            {
                title: '修订周期',
                align: 'center',
                render: (rowData) => <Typography>{rowData ? rowData.revisionCycle + getTimeUnitByCode(rowData.revisionUnit) : ''}</Typography>,
                disableClick: true,
            },
            {
                title: '修改建议',
                align: 'left',
                field: 'content',
                disableClick: true,
                render: row => <Typography>{row.content}</Typography>

            },
            {
                title: '修订类型',
                align: 'center',
                field: 'planType',
                lookup: {
                    Add: '新增',
                    Update: '修改',
                    Delete: '废止'
                },
                disableClick: true,
                cellStyle: { fontSize: 12 }
            },
            {
                title: '计划完成时间',
                align: 'left',
                field: 'targetDate',
                render: (row) => <Typography>{getDateByTimestamp(row.targetDate)}</Typography>,
                type: 'date'

            },
            {
                title: '计划类型',
                align: 'left',
                field: 'planId',
                //render: (row) => <Typography>{getDateByTimestamp(row.targetDate)}</Typography>,
                //type: 'date'
            },
        ]

        return columns
    }

    const assemblyData = () => {

        if (!currentPlanDetails || currentPlanDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(currentPlanDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }
    let renderActions = (rowData) => {
        let currentProcessStatus = rowData.processStatus;
        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0)
            return
        const actions = _.filter(currentProcessStatus.actions, (action) => { return !action.distributed })
        return actions.map((action, idx) => (
            <Button
                key={action.actionId}
                disabled={action.disabled || false}
                variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                color="primary"
                className={classes.button}
                onClick={onhandleProcess.bind(this, action, rowData)}
            >
                {action.actionName}
            </Button>))
    }

    let fixedStatusCodes = (currentProcessStatus) => {
        let fsc = []
        if (currentProcessStatus && currentProcessStatus.statusCodes) {
            for (let sc of currentProcessStatus.statusCodes) {
                if (sc === 'ToBeInvited' || sc === 'InTrial' || sc === 'ToBeSigned') {
                    fsc.push('Trial')
                } else {
                    fsc.push(sc)
                }
            }
        }
        fsc = _.uniq(fsc)
        return fsc
    }
    let fixedStatusCode = (currentProcessStatus) => { 
        let fsc = currentProcessStatus.statusCode
        if (fsc === 'ToBeInvited' || fsc === 'InTrial' || fsc === 'ToBeSigned') {
            fsc = 'Trial'
        }
        return fsc
    }
    /*let fixedStatusCodes = []
    let fixedStatusCode = currentProcessStatus.statusCode
    if (currentProcessStatus && currentProcessStatus.statusCodes) {
        for (let sc of currentProcessStatus.statusCodes) {
            if (sc === 'ToBeInvited' || sc === 'InTrial' || sc === 'ToBeSigned') {
                fixedStatusCodes.push('Trial')
            } else {
                fixedStatusCodes.push(sc)
            }
        }
    }

    fixedStatusCodes = _.uniq(fixedStatusCodes)

    if (fixedStatusCode === 'ToBeInvited' || fixedStatusCode === 'InTrial' || fixedStatusCode === 'ToBeSigned') {
        fixedStatusCode = 'Trial'
    }*/

    return (
        <div alignitems="center" className={classes.root}>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            //title=''
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            localization={localization}
                            style={style}
                            actions={
                                [
                                    rowData => ({
                                        icon: 'backup',
                                        tooltip: '上传制度',
                                        onClick: (event, rowData) => {
                                            onOpenUploadModal(rowData.regId, rowData.regName, rowData.dtlId, rowData.planType)
                                        },
                                        hidden: !checkEditable(rowData.processStatus, rowData.planType)
                                    }),
                                    rowData => ({
                                        icon: 'archive',
                                        tooltip: '下载',
                                        onClick: (event, rowData) => {
                                            onDownLoadDoc(rowData.regId, rowData.status)
                                        },
                                        //disabled: _.find(businessUnits, function (o) { return o.unitId !== rowData.unitId })
                                        //hidden: rowData.status === 'Drafting'
                                    })
                                ]}
                                detailPanel={rowData => {
                                    
                                    return (
                                        <Grid>
                                        <Grid container >
                                            <Grid item className={classes.processLine}>
                                                <ProcessLine
                                                    statusCodes={fixedStatusCodes(rowData.processStatus)}
                                                    statusCode={fixedStatusCode(rowData.processStatus)}
                                                    constant={constant}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <List >
                                                    {renderActions(rowData)}
                                                </List>
                                            </Grid>
                                        </Grid>
                    
                                        {rowData.processStatus.rejectComment && (<Grid className={classes.attention} >
                                            {rowData.processStatus.rejectComment.split('\n').map((line, index) => (
                                                <Typography key={index} className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
                                            ))}
                                        </Grid>)}
                                        </Grid>
                                    )
                                }}
                        >
                        </MaterialTable>
                    </Grid>
                    <Grid>
                    </Grid>
                </Grid>
            </div>
        </div >

    );
};
RegulationPlanExecution.propTypes = {
    classes: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    currentPlanDetails: PropTypes.array.isRequired,
    onhandleProcess: PropTypes.func.isRequired,
    onOpenUploadModal: PropTypes.func.isRequired,
    onDownLoadDoc: PropTypes.func.isRequired,
};

RegulationPlanExecution.defaultProps = {
    constant: {},
    departments: [],
    businessUnits: [],
    currentProcessStatus: {},
}

export default withStyles(RegulationPlanExecution);