import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    planDetail: {},
    planDetailError: {},
    confirmCallback: null,
    closeCallback: null,
};

export const openPlanDetailAddPannel = createAction('@@xcloud/leadershipPlanDetailAdd/open',
    (
        confirmCallback, closeCallback
    ) => ({
        confirmCallback, closeCallback
    })
);

export const initPlanDetail = createAction(
    '@@xcloud/leadershipPlanDetailAdd/initPlanDetail',
    () => (dispatch, getState) => {
        let errorInfo = {
            regName: '',
            regId: '',
            content: '',
            revisionCycle: ''
        };
        dispatch(setRulePlanDetailError(errorInfo));
    }
);

export const closePlanDetailAddPannel = createAction('@@xcloud/leadershipPlanDetailAdd/close');

export const changePlanDetail = createAction('@@xcloud/leadershipPlanDetailAdd/changeplanDetail');

export const setRulePlanDetailError = createAction('@@xcloud/leadershipPlanDetailAdd/setRulePlanDetailError');
// Actions

export const LeadershipPlanDetailAddReducer = handleActions(
    {
        [openPlanDetailAddPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
            };
        },
        [closePlanDetailAddPannel]: (state,) => {
            return {
                ...state,
                open: false,
                planDetail: {},
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [setRulePlanDetailError]: (state, { payload: value }) => {
            return {
                ...state,
                planDetailError: value
            };
        },
        [changePlanDetail]: (state, { payload: data }) => {
            return {
                ...state,
                planDetail: data,
            };
        }
    },
    initialState
);

export default LeadershipPlanDetailAddReducer;
