import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import {
    Grid,
} from '@material-ui/core';

const LeadershipManagement = (
    { classes,
    }) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Grid container>
                <Grid item> 安全领导力 Dashboard</Grid>
            </Grid>
           
        </div>

    );
};
LeadershipManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

LeadershipManagement.defaultProps = {
    newPlans: [],
    currentPlans: [],
}


export default withRouter(withStyles(LeadershipManagement));