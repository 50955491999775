import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '@/redux/modules/message'
import CompanySharingComponent from '@/components/AmoebaManagement/SharingCost/CompanySharing/CompanySharingComponent';
import AddCompanySharing from '@/components/AmoebaManagement/SharingCost/CompanySharing/AddCompanySharing';

import {
    initCompanySharing,
    setSelectedDpt,
    addCostSharing,
    getSharingTakeById,
    addSharingTake,
    updateSharingTake,
    getSharingTakeHistories,
    setResetSharingParam,
} from '@/redux/modules/amoeba/amoebaCostSharing';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import * as _ from 'lodash';
import SharingTakeHistory from '@/components/AmoebaManagement/SharingCost/SharingTakeHistory';

/**
 * 服务半径-装置
 */
class CompanySharingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            addSharingTakeOpen: false,
            sharingTakeHistoryOpen: false,
            dptId: 0,
        }
    }

    componentWillMount() {
        this.props.onInitPage(this.props.currentUserInfo.companyId);
    }

    handleSelectChange = (event) => {
        this.props.onhandleSelectChange(parseInt(event.target.value))
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.sharerDpt === parseInt(event.target.value)
        })
        if (cs !== undefined) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        }
    }

    handleAdd = (value) => {
        let cs = _.find(this.props.costSharinglist, (c) => { return c.sharerDpt === parseInt(this.props.selectedDpt) })
        if (cs !== undefined) {
            value['sharingId'] = cs.sharingId
            value['percentage'] = parseFloat(value['percentage'])
            value['takerCompany'] = parseInt(value['takerCompany'])
            value['takerDpt'] = parseInt(value['takerDpt'])
            value['fixed'] = parseFloat(value['fixed'])
            this.props.onhandleAdd(value);
        }
    }

    handleDelete = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'status': 'inactive'
        }
        this.props.onhandleUpdate(newData);
    }

    handleUpdate = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'takerCompany': parseInt(value['takerCompany']),
            'takerDpt': parseInt(value['takerDpt']),
            'percentage': parseFloat(value['percentage']),
            'fixed': parseFloat(value['fixed']),
            'valuationType': value['valuationType'],
        }
        this.props.onhandleUpdate(newData);
    }

    handleOpenAdd = () => {
        this.setState({ addSharingTakeOpen: true })
    }

    handleOpenHistory = (value) => {
        this.setState({ sharingTakeHistoryOpen: true })
        this.props.onhandleGetSharingTakeHistories(value['sharingId'], value['takeId'])
    }

    handleCloseHistory = () => {
        this.setState({ sharingTakeHistoryOpen: false })
    }

    handleChangeDepartment = (event) => {
        this.setState({ dptId: event.target.value })
    }

    handleClose = () => {
        this.setState({ addSharingTakeOpen: false })
    }

    handleAddCostSharing = () => {
        let newData = {
            'sharerDpt': this.state.dptId,
            'sharerCompany': this.props.currentUserInfo.companyId,
        }
        this.props.onhandleAddCostSharing(newData, 'SHARING_COMPANY',)
        this.setState({ addSharingTakeOpen: false })
    }

    handleOpenReset = () => {
        let param = {
            selectedDptId: this.props.selectedDpt,
            open: true,
            sharingType: 'SHARING_COMPANY',
            originTakes: this.props.sharingTakelist
        }
        this.props.onhandleSetResetSharingParam(param)
    }


    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_manager_sharing').action === 'W';
        let departments = _.filter(this.props.departments, d => {
            return d.companyId === this.props.currentUserInfo.companyId
                && d.amoebaType === 'COST'
                && !_.find(this.props.costSharinglist, c => { return c.sharerDpt === d.dptId })
        })
        return (
            <React.Fragment>
                <CompanySharingComponent
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    valuations={this.props.valuations}
                    companies={this.props.companies}
                    sharingTakelist={this.props.sharingTakelist}
                    costSharinglist={this.props.costSharinglist}
                    selectedDpt={this.props.selectedDpt}
                    roleFlag={roleFlag}
                    onhandleMessage={this.props.onhandleMessage}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleOpenAdd={this.handleOpenAdd.bind(this)}
                    onhandleOpenHistory={this.handleOpenHistory.bind(this)}
                    onhandleOpenReset={this.handleOpenReset.bind(this)}

                />
                {roleFlag && <AddCompanySharing
                    open={this.state.addSharingTakeOpen}
                    dptId={this.state.dptId}
                    departments={departments}
                    onhandleChangeDepartment={this.handleChangeDepartment.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleAdd={this.handleAddCostSharing.bind(this)}
                />}
                <SharingTakeHistory
                    open={this.state.sharingTakeHistoryOpen}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    histories={this.props.sharingTakeHistories}
                    users={this.props.users}
                    onhandleClose={this.handleCloseHistory.bind(this)}
                    valuations={this.props.valuations}
                />

            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departments: state.constant.departments,
        companies: state.constant.companies,
        costSharinglist: state.amoebaCostSharing.costSharinglist,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
        selectedDpt: state.amoebaCostSharing.selectedDpt,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        sharingTakeHistories: state.amoebaCostSharing.sharingTakeHistories,
        valuations: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.MANAGE_SHARE_TYPE
        }),
        users: state.usercard.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (companyId) => {
            dispatch(initCompanySharing(companyId));
        },
        onhandleSelectChange: (dptId) => {
            dispatch(setSelectedDpt(dptId));
        },
        onhandleGetSharingTakeList: (sid) => {
            dispatch(getSharingTakeById(sid));
        },
        onhandleGetSharingTakeHistories: (sid, takeId, status) => {
            dispatch(getSharingTakeHistories(sid, takeId, status));
        },
        onhandleAdd: (value) => {
            dispatch(addSharingTake(value, 'TYPE_DEPARTMENT'));
        },
        onhandleAddCostSharing: (value, type) => {
            dispatch(addCostSharing(value, type));
        },
        onhandleUpdate: (value) => {
            dispatch(updateSharingTake(value.takeId, value));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        },
        onhandleSetResetSharingParam: (param) => {
            dispatch(setResetSharingParam(param))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySharingContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}