import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import ReactEcharts from 'echarts-for-react';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import _ from 'lodash';


const TrendsComponent = props => {
    const {
        classes,
        title,
        // trends,
        // departments,
        // companies,
        open,
        timeDim,
        startDate,
        endDate,
        onhandleDateChange,
        onhandleEchartOption,
        onhandleClose
    } = props;

    // console.log(
    //     startDate,
    //     endDate)

    const getPickerView = () => {
        if (timeDim === 'DAY') { return ['date'] }
        if (timeDim === 'MONTH') { return ['year', 'month'] }
        if (timeDim === 'YEAR') { return ['year'] }
        return ['date']
    }

    const getPickerFormat = () => {
        if (timeDim === 'DAY') { return 'yyyy/MM/dd' }
        if (timeDim === 'MONTH') { return 'yyyy/MM' }
        if (timeDim === 'YEAR') { return 'yyyy' }
        return 'yyyy/MM/dd'
    }

    const getPickerOpenTo = () => {
        if (timeDim === 'MONTH') { return 'month' }
        return undefined
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            onClose={onhandleClose}>
            <DialogTitle disableTypography>
                <Typography component={'span'} variant="h3">{title}</Typography>
                {onhandleClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={2} container wrap={'nowrap'}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN}>
                            <KeyboardDatePicker
                                className={classes.dateField}
                                InputProps={{ readOnly: true }}
                                autoOk
                                variant='inline'
                                views={getPickerView()}
                                openTo={getPickerOpenTo()}
                                format={getPickerFormat()}
                                margin="none"
                                value={startDate || null}
                                onChange={date => onhandleDateChange(date, 'startDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} container wrap={'nowrap'}>
                        <Typography gutterBottom className={classes.label} style={{ marginRight: '15px' }}>
                            {'至'}
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN}>
                            <KeyboardDatePicker
                                className={classes.dateField}
                                InputProps={{ readOnly: true }}
                                autoOk
                                variant="inline"
                                views={getPickerView()}
                                openTo={getPickerOpenTo()}
                                format={getPickerFormat()}
                                margin="none"
                                value={endDate || null}
                                onChange={date => onhandleDateChange(date, 'endDate')}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                <ReactEcharts option={onhandleEchartOption} style={{ height: 400, width: '100%' }} />
            </DialogContent>
        </Dialog>
    )
}

TrendsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companies: PropTypes.array,
};

TrendsComponent.defaultProps = {
    title: '',
    open: false,
    timeDim: '',
    startDate: {},
    endDate: {},
};

export default withStyles(TrendsComponent);