import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { getStore } from '../../../../../redux/store';
import {setDiagramChange} from '../../../../../redux/modules/process';

export default function(group, element, bpmnFactory, translate) {
    const store = getStore();
    group.entries.push(
        entryFactory.textField({
            id: 'name',
            label: translate('名称'),
            modelProperty: 'name',
            set(el, value) {
                let res = {}
                if (value['name'] !== '') {
                    res['name'] = value['name'];
                  } else {
                    res['name'] = undefined;
                  }
                store.dispatch(setDiagramChange(true));
                return cmdHelper.updateProperties(el, res);
            },
            validate: function(element, values) {
                let validationResult = {};

                if (!values.name) {
                    validationResult.name = '请输入节点名称';
                }

                if (values.name && values.name.length > 30) {
                    validationResult.name = '名称最多30个字';
                }

                return validationResult;
            },
        })
    );
}
