import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MajorHazardDetectionHeader from "../../../containers/MajorHazardSources/MajorHazardDetection/MajorHazardDetectionHeader";
import MajorHazardDetectionList from "../../../containers/MajorHazardSources/MajorHazardDetection/MajorHazardDetectionList";

const MajorHazardDetection = props => {

    const { classes } = props;

    return (
        <div className={classes.root}>
            <MajorHazardDetectionHeader />
            <div className={classes.content}>
                <MajorHazardDetectionList />
            </div>
        </div>
    );
};
MajorHazardDetection.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(MajorHazardDetection);
