import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssayRecordComponent from '@/components/ManufactureManagement/AssayRecord';
import { getMoment, formatDate } from '@/utils/datetime';
import { getAssayRecords, updateAssayRecord, initAssay, setCurrentRecord, exportAssayRecords } from '@/redux/modules/manufacture/assay';
import { createmodal } from '@/redux/modules/modal';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';

class AssayRecordContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: 0,
            dptId: 0,
            installId: 0,
            tempId: 0,
            testTarget: undefined,
            startDate: getMoment()(),
            endDate: getMoment()(),
            status: '',
            offset: 0,
            size: 0
        }
    }

    // 页面初期化获取数据
    componentWillMount() {
        this.props.initAssay();
    }

    handleChange = (event, field) => {
        if (field === 'companyId') {
            this.setState({
                companyId: parseInt(event.target.value),
                dptId: 0,
                installId: 0
            })
        } else if (field === 'dptId') {
            this.setState({
                dptId: parseInt(event.target.value)
            })
        } else if (field === 'installId') {
            this.setState({
                installId: parseInt(event.target.value)
            })
        } else if (field === 'tempId') {
            this.setState({
                tempId: parseInt(event.target.value)
            })
        } else if (field === 'status') {
            this.setState({
                status: event.target.value
            })
        }
    }

    handleDateChange = (date, field) => {
        if (field === 'startDate') {
            this.setState({
                startDate: date
            })
        } else if (field === 'endDate') {
            this.setState({
                endDate: date
            })
        }
    }

    handleCreate = () => {
        this.props.setCurrentRecord({
            recordId: 0,
            companyId: this.props.userInfo.companyId,
            dptId: this.state.dptId,
            installId: this.state.installId,
            tempId: this.props.temps[0].tempId,
            extFee: 0,
            testTime: getMoment()(),
            details: []
        })
        this.props.onhandleEdit({
            callback: () => {
                this.handleSearch()
            }
        });
    }

    handleUpdate = (data) => {
        this.props.setCurrentRecord(data)
        this.props.onhandleEdit({
            callback: () => {
                this.handleSearch()
            }
        });
    }

    handleDelete = (data) => {
        let deleteAction = () => {
            let nd = {
                recordId: data.recordId,
                details: [],
                status: 'inactive'
            }
            this.props.onhandleUpdateRecord(nd, () => {
                this.handleSearch()
            });
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除该记录吗?', deleteAction, null, false);
    }

    handleConfirm = (data) =>{
        let confirmAction = () => {
            let nd = {
                recordId: data.recordId,
                details: [],
                status: 'done',
                confirmUser: this.props.userInfo.userId,
                confirmTime: new Date()
            }
            this.props.onhandleUpdateRecord(nd, () => {
                this.handleSearch()
            });
        }
        this.props.onhandleOpenConfirmDialog('确认', '确认该记录吗?', confirmAction, null, false);
    }

    handleSearch = () => {
        this.props.getAssayRecords(this.state.companyId, this.state.dptId, this.state.installId,
            undefined, undefined, undefined, this.state.tempId, undefined, formatDate(this.state.startDate),
            formatDate(this.state.endDate), this.state.status, this.state.offset, this.state.size, undefined);
    }

    handleExport = () => {
        this.props.exportAssayRecords(this.state.companyId, this.state.dptId, this.state.installId,
            undefined, undefined, undefined, this.state.tempId, undefined, formatDate(this.state.startDate),
            formatDate(this.state.endDate), this.state.status, this.state.offset, this.state.size, undefined);
    }

    handleClearSearch = () => {
        this.setState({
            companyId: 0,
            dptId: 0,
            installId: 0,
            tempId: 0,
            startDate: getMoment()(),
            endDate: getMoment()()
        })
    }

    render() {
        return (
            <div>
                <AssayRecordComponent
                    companyList={this.props.companies}
                    installList={this.props.installations}
                    temps={this.props.temps}
                    records={this.props.records}
                    departments={this.props.departments}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleConfirm={this.handleConfirm.bind(this)}
                    testTarget={this.state.testTarget}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    companyId={this.state.companyId}
                    dptId={this.state.dptId}
                    installId={this.state.installId}
                    tempId={this.state.tempId}
                    status={this.state.status}
                    userProfile={this.props.userProfile}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.assay.assayRecords,
        temps: state.assay.assayTemps,
        userProfile: state.auth.userProfile,
        userInfo: state.auth.currentUserInfo,
        installations: state.installation.installationList ? state.installation.installationList.list : [],
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initAssay: () => {
            dispatch(initAssay());
        },
        setCurrentRecord: (data) => {
            dispatch(setCurrentRecord(data));
        },
        getAssayRecords: (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status, offset, size, sort) => {
            dispatch(getAssayRecords(companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status, offset, size, sort));
        },
        exportAssayRecords: (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status, offset, size, sort) => {
            dispatch(exportAssayRecords(companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status, offset, size, sort));
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updateAssayRecord(record, callback));
        },
        onhandleEdit: (param) => {
            dispatch(createmodal('assayRecordEdit', param, 'big'))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssayRecordContainer);
