import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message';
import { push } from 'connected-react-router';
import * as _ from 'lodash';


const porxyApi = '/api'

const initialState = {
    search: {
        companyId: undefined,
        dangerLevel: undefined,
        search: undefined,
        statusTree: [],

    },
    pageSize: 5,
    oppolist: {
        total: 0,
        list: []
    },
    statusList: [],
    customerList: []
}

export const initOppoHeader = createAction('@@xcloud/oppo/initOppoHeader',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(resetSearch(true)),
            dispatch(getOppoStatus()),
            dispatch(getOppo())
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化客户机会搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const initOppoList = createAction('@@xcloud/oppo/initOppoList',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getOppoStatus()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化客户机会搜索失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const initOppoInfo = createAction('@@xcloud/oppo/initOppoInfo',
    () => (dispatch, getState) => {
        Promise.all([
            dispatch(getCustomers()),
        ]).then(() => {
        }).catch(err => {
            let msg = '初始化客户列表失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });

    }
);

export const changeOppoStatus = createAction(
    '@@xcloud/oppo/changeOppoStatus',
    (oppoDto, status) => (dispatch, getState, httpClient) => {
        let dto = {}
        dto.oid = oppoDto.oid
        dto.tenantId = oppoDto.tenantId
        dto.status = status
        return httpClient
            .patch(porxyApi + `/opps/${oppoDto.oid}`, dto, { headers: { need_loading: true } })
            .then((res) => {
                if (res.data) {
                    dispatch(createhttpMessage({ status: 200 }, '删除机会成功'))
                    // 重新检索列表
                    dispatch(getOppo())
                    return res.data;
                }
            }).catch(err => {
                let msg = `删除客户机会失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);

export const onView = createAction(
    '@@xcloud/oppo/onView',
    (oid) => (dispatch, getState, httpClient) => {
        dispatch(startLoading());
        return Promise.all([
            dispatch(getCustomers()),
            dispatch(getOppoById(oid))
        ]).then(dispatch(push('/crm/oppoInfo')))
            .catch(err => {
                dispatch(stopLoading());
                let msg = '获取客户机会失败';
                dispatch(createMessage('error', msg));
            });
    }
);

export const getOppoById = createAction('@@xcloud/oppo/getOppoById',
    (oid) => (dispatch, getState, httpClient) => {
        dispatch(startLoading());
        let oppo = getState().customerOppo;
        console.log('oppo', oppo);
        let url = '/opps/' + oid;
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCurrentOppo(res.data));
                    dispatch(stopLoading());
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                // dispatch(stopLoading());
                let msg = '客户机会获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getCustomers = createAction('@@xcloud/customer/getCustomers',
    () => (dispatch, getState, httpClient) => {
        let url = '/allcustomers';

        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                }
                return res;
            }).catch(err => {
                let msg = '客户列表获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const createOppo = createAction('@@xcloud/oppo/createOppo',
    () => (dispatch, getState, httpClient) => {

        let url = '/oppos';
        let coppo = getState().customerOppo.currentOppo;
        //console.log(coppo);
        return httpClient.post(url, coppo)
            .then((res) => {
                if (res.status === 201) {
                    // console.log(res.data);
                    dispatch(setCurrentOppo(res.data));
                    dispatch(createMessage('success', '创建客户机会[' + coppo.oppoName + ']成功'));
                    dispatch(getOppo());
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '创建客户机会失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const updateOppo = createAction('@@xcloud/oppo/updateOppo',
    () => (dispatch, getState, httpClient) => {
        let updateOppo = getState().customerOppo.currentOppo;
        let url = '/opps/' + updateOppo.oid;
        let to = {
            oppoName: updateOppo.oppoName,
            tenantId: updateOppo.tenantId,
            cid: updateOppo.cid,
            source: updateOppo.source,
            possibility: updateOppo.possibility,
            expectedAmount: updateOppo.expectedAmount,
            product: updateOppo.product,
            oppoTime: updateOppo.oppoTime,
            expiredDate: updateOppo.expiredDate,
            developer: updateOppo.developer,
            opentext: updateOppo.opentext
        }
        return httpClient.patch(url, to)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setCurrentOppo(res.data));
                    dispatch(createMessage('success', '更新客户机会[' + updateOppo.oppoName + ']成功'));
                    dispatch(getOppo());
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                let msg = '更新客户机会[' + updateOppo.oppoName + ']失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const getOppo = createAction(
    '@@xcloud/oppo/getOppo',
    (query) => (dispatch, getState, httpClient) => {
        let url = "/opps"
        let searchCond = getState().customerOppo.search;
        console.log('searchCond', searchCond);
        let pageSize = getState().customerOppo.pageSize
        if (query && query.offset && query.offset !== "") {
            url += "?offset=" + query.offset
        } else {
            url += "?offset=0"
        }

        if (query && query.search && query.search !== "") {
            url += "&search=" + query.search
        } else if (searchCond.search && searchCond.search !== "") {
            url += "&search=" + searchCond.search
        }

        if (query && query.status && query.status !== "") {
            url += "&status=" + query.status.value
        } else if (searchCond.status && searchCond.status !== "") {
            url += "&status=" + searchCond.status
        }

        if (query && query.source && query.source !== "") {
            url += "&source=" + query.source
        } else if (searchCond.source && searchCond.source !== "") {
            url += "&source=" + searchCond.source
        }

        if (query && query.fromAmount && query.fromAmount !== "") {
            url += "&fromAmount=" + query.fromAmount
        } else if (searchCond.fromAmount && searchCond.fromAmount !== "") {
            url += "&fromAmount=" + searchCond.fromAmount
        }

        if (query && query.toAmount && query.toAmount !== "") {
            url += "&toAmount=" + query.toAmount
        } else if (searchCond.toAmount && searchCond.toAmount !== "") {
            url += "&toAmount=" + searchCond.toAmount
        }

        if (query && query.fromPossibility && query.fromPossibility !== "") {
            url += "&fromPossibility=" + query.fromPossibility
        } else if (searchCond.fromPossibility && searchCond.fromPossibility !== "") {
            url += "&fromPossibility=" + searchCond.fromPossibility
        }

        if (query && query.toPossibility && query.toPossibility !== "") {
            url += "&toPossibility=" + query.toPossibility
        } else if (searchCond.toPossibility && searchCond.toPossibility !== "") {
            url += "&toPossibility=" + searchCond.toPossibility
        }

        if (query && query.fromDate && query.fromDate !== "") {
            url += "&fromDate=" + query.fromDate
        } else if (searchCond.fromDate && searchCond.fromDate !== "") {
            url += "&fromDate=" + searchCond.fromDate
        }

        if (query && query.toDate && query.toDate !== "") {
            url += "&toDate=" + query.toDate
        } else if (searchCond.toDate && searchCond.toDate !== "") {
            url += "&toDate=" + searchCond.toDate
        }


        if (query && query.expiredFromDate && query.expiredFromDate !== "") {
            url += "&expiredFromDate=" + query.expiredFromDate
        } else if (searchCond.expiredFromDate && searchCond.expiredFromDate !== "") {
            url += "&expiredFromDate=" + searchCond.expiredFromDate
        }

        if (query && query.expiredToDate && query.expiredToDate !== "") {
            url += "&expiredToDate=" + query.expiredToDate
        } else if (searchCond.expiredToDate && searchCond.expiredToDate !== "") {
            url += "&expiredToDate=" + searchCond.expiredToDate
        }

        if (query && _.isInteger(query.size)) {
            url += "&size=" + query.size
        } else {
            url += "&size=" + (pageSize ? pageSize : 5)
        }

        if (query && query.sort && query.sort !== "") {
            url += "&sort=" + query.sort
        } else {
            url += "&sort=-update_time"
        }

        //console.log('search url:', url);
        return httpClient
            .get(porxyApi + url, { headers: { need_loading: true } })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setOppolist(res.data));
                    return res.data;
                }
            })
            .catch(err => {
                let msg = `取得客户机会列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            })
    }
);


export const resetSearch = createAction('@@xcloud/oppo/resetSearch',
    (firstLoadFlag) => (dispatch, getState, httpClient) => {
        let showFilters = getState().customerOppo.search.showFilters;
        let search = getState().customerOppo.search.search;
        console.log('showFilters', showFilters);

        // let search = getState().customerOppo.search;
        let newSearch = {
            // ...search,
            //companyId: newCompanyId ? newCompanyId : undefined,
            search: !showFilters && !firstLoadFlag ? search : undefined,
            showFilters: firstLoadFlag ? false : !showFilters
        }
        dispatch(setSearch(newSearch));
    }
);

export const getOppoStatus = createAction('@@xcloud/oppo/getOppoStatus',
    () => (dispatch, getState, httpClient) => {
        let url = "/api/unprotected/utils/crm/dictionaries?type=OPPO_STATUS";
        return httpClient.get(url)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                }
                return res;
            }).catch(err => {
                let msg = '客户机会状态获取失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
    });

export const getCustomerDocByDocId = createAction(
    '@@xcloud/customerdoc/getCustomerDocByDocId',
    (regId, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulations/' + regId)
            .then((res) => {
                if (res) {
                    // console.log(res)
                    //dispatch(createhttpMessage(res, "获取version成功"));
                    if (callback)
                        callback(res.data.versions)
                }
            })
            .catch(err => {
                let msg = '获取规章制度失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    }
);

export const setSearch = createAction('@@xcloud/oppo/setSearch');

export const setPageSize = createAction('@@xcloud/oppo/setPageSize');

export const setOppolist = createAction('@@xcloud/oppo/setOppolist');

export const setCurrentOppo = createAction('@@xcloud/oppo/setCurrentOppo');

export const setOppoChange = createAction('@@xcloud/oppo/setOppoChange');

export const startLoading = createAction('@@xcloud/oppo/startLoading');

export const stopLoading = createAction('@@xcloud/oppo/stopLoading');

export const oppoReducer = handleActions(
    {
        [getOppoStatus]: (state, { payload: value }) => {
            return {
                ...state,
                statusList: value,
            };
        },
        [getCustomers]: (state, { payload: value }) => {
            return {
                ...state,
                customerList: value,
            };
        },
        [setCurrentOppo]: (state, { payload: value }) => {
            return {
                ...state,
                currentOppo: value
            };
        },
        [setSearch]: (state, { payload: value }) => {
            return {
                ...state,
                search: value
            };
        },
        [setOppolist]: (state, { payload: value }) => {
            return {
                ...state,
                oppolist: value
            };
        },
        [setPageSize]: (state, { payload: value }) => {
            return {
                ...state,
                pageSize: value
            };
        },
        [setOppoChange]: (state, { payload: value }) => {
            return {
                ...state,
                oppoChange: value
            };
        },
        [startLoading]: (state) => {
            return {
                ...state,
                isloading: true
            };
        },
        [stopLoading]: (state) => {
            return {
                ...state,
                isloading: false
            };
        }
    },
    initialState
);

export default oppoReducer;