import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api'

const initialState = {
    oilPrices: [], //油价
};

export const initOilPrice = createAction('@@xcloud/manufacture/initOilPrice',
    (startDate, endDate) =>
        (dispatch, getState, httpClient) => {
            Promise.all([
                dispatch(getOilPrices(null, null, startDate, endDate, null)),
            ]).then((data) => {
                return data;
            }).catch(err => {
                let msg = '初始化油价页面失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        });


export const setOilPrices = createAction('@@xcloud/amoeba/setOilPrices');

export const getOilPrices = createAction('@@xcloud/amoeba/getOilPrices',
    (station, oilType, startDate, endDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/oilprices'
        let strs = []
        if (station || oilType || startDate || endDate || status) {
            if (station) strs.push('station=' + station)
            if (oilType) strs.push('oilType=' + oilType)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setOilPrices(res.data))
                return res.status;
            }
        }).catch(err => {
            let msg = `获取油价列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateOilPrice = createAction('@@xcloud/amoeba/updateOilPrice',
    (priceId, dto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/oilprices/${priceId}`, dto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新油价成功"));
                }
            }).catch(err => {
                let msg = `更新油价失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const addOilPrice = createAction('@@xcloud/amoeba/addOilPrice',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/oilprices`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "创建油价成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `创建油价失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteOilPrice = createAction('@@xcloud/amoeba/deleteOilPrice',
    (priceId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/oilprices/' + priceId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除油价成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除油价失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const oilPriceReducer = handleActions(
    {
        [setOilPrices]: (state, { payload: data }) => {
            return {
                ...state,
                oilPrices: data
            }
        },
    },
    initialState
)

export default oilPriceReducer
