import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    title: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formGridContainer: {

    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    materialtable: {
        boxShadow: 'none'
    },
    menuItemNormal: {
        color: theme.palette.primary.main
    },
    menuItemCritical: {
        color: theme.palette.error.main
    },
    menuIcon: {
        marginRight: 5
    },
    /* 巡检路线的状态强调显示 */
    rejected: {
        width: 60,
        backgroundColor: colors.red[400],
        color: theme.palette.white,
    },
    drafting: {
        width: 60,
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    waitingforapprove: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    published: {
        width: 60,
        backgroundColor: colors.blue[400],
        color: theme.palette.white,
    },
    invalid: {
        width: 60,
        backgroundColor: colors.orange[400],
        color: theme.palette.white,
    },
    submitted: {
        width: 60,
        backgroundColor: colors.brown[400],
        color: theme.palette.white,
    },
    default: {
        width: 60,
        backgroundColor: colors.grey[300],
        color: theme.palette.white,
    },
});

export default withStyles(styles);