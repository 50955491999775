import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
    },
    routeInfoContainer: {
        paddingTop: 15
    },
    select: {
        minWidth: 300,
        maxWidth: 300
    },
    textField: {
        minWidth: 300,
        maxWidth: 300
    },
    gridContainer: {
        marginTop: 10
    },
    label: {
        marginTop: 4
    },

    item: {
        padding: 0
    },
    card: {
        width: '100%',
        padding: 20
    },

    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        display: 'block',
        textAlign: 'center'
    },
    processline: {
        flexGrow: 1
    },
    attention: {
        // marginLeft: theme.spacing(4),
        //marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
    detailtable: {
        padding: theme.spacing(4)
    },
    button: {
        margin: theme.spacing(1)
    },
    tabs: {
        background: 'none',
        color: '#2979ff',
        '&:hover': {
            color: '#2979ff',
            opacity: 1
        },
        '&:focus': {
            color: '#2979ff'
        }
    },
    tooltip: {
        maxWidth: 200,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main
    }
});

export default withStyles(styles);