import palette from '../palette';

export default {
  '@global': {
    html: {
      //
    },
    body: {
      backgroundColor: palette.background.default
    }
  },
};
