import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dialog: {
    },
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    title: {
        padding: theme.spacing(1)
    },
    timeline: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    infocontainer: {
        padding: theme.spacing(2),
        flexDirection: 'column'
    },
    item: {
        paddingBottom: theme.spacing(2),
        minWidth: 300
    },
    record: {
        minWidth: 300
    },
    itemText: {
        // minWidth: 400
    },
    contentGrid: {
        flexDirection: 'column'
    },
    col: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }

});


export default withStyles(styles);