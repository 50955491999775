import React, { Component } from 'react';
import "./index.css";
import * as _ from 'lodash';
export class DeviceDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceNo: '',
            deviceName: '',
            catName: '',
            specs: '',
            useStatusName: '',
            mediumName: '',
            tagNo: '',
            x: '',
            y: '',
        }
    }
    componentDidMount() {
        this.getDetail();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.parameters.data.id !== this.props.parameters.data.id) {
            this.getDetail();
        }
    }

    getDetail() {
        this.setState({
            deviceNo: '',
            deviceName: '',
            catName: '',
            specs: '',
            useStatusName: '',
            mediumName: '',
            tagNo: '',
            x: '',
            y: '',
        })
        let id = _.toInteger(_.split(this.props.parameters.data.id, '_')[1]);
        let data = _.find(this.props.searchDevicelist.list, function (o) { return o.deviceId === id; })
        if (data) {
            data.catName = _.find(this.props.deviceCategoryList, function (o) { return o.code === data.cat; }).name;
            data.useStatusName = _.find(this.props.useStatus, function (o) { return o.code === data.useStatus; }).name;
            if (data.medium) {
                let mediumArray = _.split(data.medium, ',');
                if (mediumArray && mediumArray.length > 0) {
                    let mediumNames = [];
                    mediumArray.forEach(medium => {
                        let name = _.find(this.props.medium, function (o) { return o.code === medium; }).name;
                        if (name) {
                            mediumNames.push(name);
                        }
                    })
                    if (mediumNames.length > 0) {
                        data.mediumName = mediumNames.join(',');
                    }

                }
            }


            data.x = this.props.parameters.data.x;
            data.y = this.props.parameters.data.y;
            this.setState({
                deviceNo: data.deviceNo,
                deviceName: data.deviceName,
                catName: data.catName,
                specs: data.specs,
                useStatusName: data.useStatusName,
                mediumName: data.mediumName ? data.mediumName : '',
                tagNo: data.tagNo ? data.tagNo : '',
                x: data.x,
                y: data.y,
            })
        }
    }

    render() {
        return (
            <div className='fillarea'>
                <React.Fragment>
                    <div className='title'>编号:</div>
                    <div className='showArea'>
                        {this.state.deviceNo ? this.state.deviceNo : '-'}
                    </div>
                    <div className='title'>名称:</div>
                    <div className='showArea'>
                        {this.state.deviceName}
                    </div>
                    <div className='title'>类型:</div>
                    <div className='showArea'>
                        {this.state.catName}
                    </div>
                    <div className='title'>位号:</div>
                    <div className='showArea'>
                        {this.state.tagNo}
                    </div>
                    <div className='title'>规格:</div>
                    <div className='showArea'>
                        {this.state.specs}
                    </div>
                    <div className='title'>使用状态:</div>
                    <div className='showArea'>
                        {this.state.useStatusName}
                    </div>
                    <div className='title'>介质:</div>
                    <div className='showArea'>
                        {this.state.mediumName}
                    </div>
                </React.Fragment>
            </div>
        )
    }
}
