import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Tabs,
    Tab,
    Grid,
    Typography
} from '@material-ui/core';

import { localization, options, style } from "../../../../utils/mtable";
import MaterialTable from 'material-table';
import { getMoment as moment, getDateByTimestamp } from "../../../../utils/datetime";

import * as _ from 'lodash';

const InspectionPlanCurrentYear = ({
    classes,
    currentPlanDetails,
    listTypeList,
    tabValue,
    categoryList,
    onhandleChangeTab,
    onhandleListMapping,
}) => {

    const needCategory = _.find(categoryList, { 'pcode': tabValue });

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        showTitle: false,
        toolbar: false,
        sorting: false
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '检查日期',
                field: 'startTime',
                render: (row) => row && <Typography>{
                    moment()(row.startTime).isSame(row.inspectTime, 'day') ?
                        getDateByTimestamp(row.startTime)
                        :
                        (getDateByTimestamp(row.startTime) + ' 至 ' + getDateByTimestamp(row.inspectTime))
                }</Typography>,
                type: 'date',
                align: 'left'
            },
            {
                title: '受检单位',
                field: 'companyId',
                render: row => row && <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography>,
                align: 'left'
            },
            {
                title: '子分类',
                field: 'inspectType',
                hidden: !needCategory,
                //width: '10%',
                render: row => row && <Typography>{onhandleListMapping(row.inspectType, 'inspectType')}</Typography>,
                align: 'left'
            },
            {
                title: '检查表',
                field: 'inventoryId',
                //width: '10%',
                render: row => row && <Typography>{row.inventoryName || '-'}</Typography>,
                align: 'left'
            },
            {
                title: '检查部门',
                field: 'dptId',
                width: '30%',
                render: row => row && <Typography>{onhandleListMapping(row.inspectionDptList, 'departmentShow')}</Typography>,
                align: 'left'
            },
            {
                title: '负责人',
                field: 'inspectHead',
                align: 'left',
                render: row => row && <Typography>{onhandleListMapping(row.inspectHead, 'user')}</Typography>
            },
            {
                width: '10%',
                title: '状态',
                field: 'status',
                render: row => row && <Typography>{onhandleListMapping(row.status, 'status')}</Typography>,
                editable: 'never',
                align: 'left'
            }
        ]

        return columns
    }

    const assemblyData = (tabValue) => {

        if (!currentPlanDetails || currentPlanDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(currentPlanDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return _.filter(
            assemblyData,
            (item) => { return item.inspectType.includes(tabValue); }
        ).reverse();
        // return assemblyData.reverse()
    }

    return (
        <div alignitems="center" className={classes.root}>

            <div alignitems="center" className={classes.planitemroot}>
                {/*<Grid container className={classes.title}>
                     <Grid item>
                        <Typography variant='h3' className={classes.plantype} >隐患排查年度计划</Typography>
                    </Grid>
                    <Grid item className={classes.flexgrow} ></Grid>
                    <Grid item> <Divider orientation="vertical" className={classes.divider} /></Grid>

                </Grid> */}

                <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={onhandleChangeTab}
                >
                    {listTypeList ?
                        listTypeList.filter(
                            (data) => (data.code !== 'INS07')
                        ).map(data => (
                            <Tab className={classes.tab} value={data.code} key={data.code} label={data.name} />
                        )) : null
                    }

                </Tabs>
                <Grid>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            columns={assemblycolumns()}
                            data={assemblyData(tabValue)}
                            options={tableOptions}
                            editable={{}}
                            localization={localization}
                            style={style}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            //options={options}

                            components={{}}
                        />
                    </Grid>
                    <Grid>
                    </Grid>
                </Grid>
            </div>
        </div >

    );
};
InspectionPlanCurrentYear.propTypes = {
    classes: PropTypes.object.isRequired,
    categoryList: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    currentPlanDetails: PropTypes.array.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

InspectionPlanCurrentYear.defaultProps = {
    plan: {},
    currentPlanDetails: [],
    currentUserInfo: {},
    userProfile: {},
    constant: {},
    departments: [],
    businessUnits: [],
}

export default withStyles(InspectionPlanCurrentYear);