import React from 'react';
import PropTypes from 'prop-types';
// import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
// import Draggable from 'react-draggable';
import withStyles from './styles';
import FileUploadPage from '../../containers/FileUploadPage';
import CommonFileUploadPage from '../../containers/CommonFileUploadPage';
import CompanyInfo from '../../containers/SystemManagement/OrgManagement/CompanyInfo';
import DepartmentInfo from '../../containers/SystemManagement/OrgManagement/DepartmentInfo';
import DeviceSelectPage from '../../containers/DeviceSelectPage'
import AssayRecordEdit from '../../containers/ManufactureManagement/AssayRecord/AssayRecordEditContainer';
import UserSelectPage from '../../containers/Utils/UserSelectPage';
import AccessoryReplaceRecord from '../../containers/AccessoryReplaceRecord'
import AccessorySelectPage from '../../containers/AccessorySelectPage'
import AttachmentDocs from '../../containers/AttachmentDocs/AttachmentDocs';
import AppQRCode from '../../containers/AppQRCode';
import AcctypeSelectPage from '../../containers/AcctypeSelectPage';
import OnsiteInspectionStatistics from '../../containers/OnsiteInspectionStatistics';
import MajorHazardInspectionPage from '../../containers/MajorHazardSources/MajorHazardDetection/MajorHazardInspectionPage';
import DeviceOpsRecord from '../../containers/DeviceManagement/DeviceOpsRecord/DeviceOpsRecord';
import CreateProcessLineApprovalContainer from '../../containers/ProcessLineApproval/CreateProcessLineApprovalContainer';
import WorkOrderListForPopup from '../../containers/OperationManagement/WorkOrderListForPopup';
import WorkOrderWithOperationPlanList from '../../containers/OperationManagement/WorkOrderWithOperationPlanList';
import SupplierSelectPage from '../../containers/SupplierSelectPage';

function ModalPage(props) {
  const { classes, open, children, size } = props;

  // 加入本容器组件的组件，必须没有互相嵌套调用的需求
  const childcompant = (children) => {
    if (children === 'fileUpload') {
      return (
        <React.Fragment>
          <FileUploadPage />
        </React.Fragment>
      );
    } else if (children === 'commonFileUpload') {
      return (
        <React.Fragment>
          <CommonFileUploadPage />
        </React.Fragment>
      );
    } else if (children === 'companyInfo') {
      return (
        <React.Fragment>
          <CompanyInfo />
        </React.Fragment>
      );
    } else if (children === 'departmentInfo') {
      return (
        <React.Fragment>
          <DepartmentInfo />
        </React.Fragment>
      );
    } else if (children === 'userSelect') {
      return (
        <React.Fragment>
          <UserSelectPage />
        </React.Fragment>
      );
    } else if (children === 'deviceSelect') {
      return (
        <React.Fragment>
          <DeviceSelectPage />
        </React.Fragment>
      );
    } else if (children === 'accessoryReplaceRecord') {
      return (
        <React.Fragment>
          <AccessoryReplaceRecord />
        </React.Fragment>
      );
    } else if (children === 'accessorySelect') {
      return (
        <React.Fragment>
          <AccessorySelectPage />
        </React.Fragment>
      );
    } else if (children === 'qrCode') {
      return (
        <React.Fragment>
          <AppQRCode />
        </React.Fragment>
      );
    } else if (children === 'attachmentDocs') {
      return (
        <React.Fragment>
          <AttachmentDocs />
        </React.Fragment>
      );
    } else if (children === 'acctypeSelect') {
      return (
        <React.Fragment>
          <AcctypeSelectPage />
        </React.Fragment>
      );
    } else if (children === 'onsiteInspectionStatistics') {
      return (
        <React.Fragment>
          <OnsiteInspectionStatistics />
        </React.Fragment>
      );
    } else if (children === 'majorHazardInspectionPage') {
      return (
        <React.Fragment>
          <MajorHazardInspectionPage />
        </React.Fragment>
      );
    } else if (children === 'deviceOpsRecord') {
      return (
        <React.Fragment>
          <DeviceOpsRecord />
        </React.Fragment>
      );
    } else if (children === 'createProcessLineApproval') {
      return (
        <React.Fragment>
          <CreateProcessLineApprovalContainer />
        </React.Fragment>
      )
    } else if (children === 'workOrderListForPopup') {
      // 工单列表
      return (
        <React.Fragment>
          <WorkOrderListForPopup />
        </React.Fragment>
      )
    } else if (children === 'assayRecordEdit') {
      return (
        <React.Fragment>
          <AssayRecordEdit />
        </React.Fragment>
      );
    } else if (children === 'workOrderWithOperationPlan') {
      // 工单列表
      return (
        <React.Fragment>
          <WorkOrderWithOperationPlanList />
        </React.Fragment>
      )
    } else if (children === 'supplierSelect') {
      return (
        <React.Fragment>
          <SupplierSelectPage />
        </React.Fragment>
      );
    }

    return children;
  };

  let maxWidth = '';
  if (size === 'small') { maxWidth = 'sm' }
  if (size === 'medium') { maxWidth = 'md' }
  if (size === 'large') { maxWidth = 'lg' }

  let fullScreen = false;
  if (size === 'full') { fullScreen = true }

  return (
    // 高度适配有问题
    // <Modal
    //   aria-labelledby="adduser-modal-title"
    //   aria-describedby="adduser-modal-description"
    //   open={open}
    //   className={classes.modal}
    // >
    //   {/* <Draggable cancel='input'> */}
    //   <div className={classes.paper + ' ' + classes[size + 'Modal'] + ' dragHandle'}>
    //     {childcompant(children)}
    //   </div>
    //   {/* </Draggable> */}
    // </Modal>

    <Dialog
      open={open}
      className={classes.modal}
      scroll={'body'}
      fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
    >
      <div className={classes.paper + ' ' + classes[size + 'Modal'] + ' dragHandle'}>
        {/* <DialogContent dividers={false}> */}
        {childcompant(children)}
        {/* </DialogContent> */}
      </div>
    </Dialog>
  );
}

ModalPage.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  children: PropTypes.any
};

export default withStyles(ModalPage);
