import React from 'react';
import {
    // Grid,
    // Tab,
    // Tabs,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MOrderListContainer from '@/containers/ManufactureManagement/ManufactureOrder/MOrderListContainer';


const MOrderComponent = props => {
    const { classes,
        // tabValue,
        // onhandleChangeTab,
    } = props;
    return (
        <div alignitems="center" className={classes.root}>
            <MOrderListContainer />
            {/* <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label={"工单列表"} className={classes.tab} />
                <Tab label={"创建工单"} className={classes.tab} />
            </Tabs>
            <Grid>
                {tabValue === 0 && <MOrderListContainer />}
                {tabValue === 1 && <MOrderListContainer />}
            </Grid> */}
        </div>
    )
}

MOrderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

MOrderComponent.defaultProps = {
};

export default withStyles(MOrderComponent);