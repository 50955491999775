import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core';
import CardActions from "@material-ui/core/CardActions";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from '@material-ui/core/Tooltip';
import { truncate } from '../../../utils/string';
import { formatUTC } from '../../../utils/datetime';
import { localizationPagination } from "../../../utils/mtable";

const TenantList = props => {
    const {
        classes,
        tenants,
        onDetailedInfo,
        getTenantList,
        isloading
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [sortBy] = useState('-update_time');

    const handlePageChange = (event, page) => {
        setPage(page);
        getTenantList({
            offset: page * rowsPerPage,
            size: rowsPerPage,
            sort: sortBy
        })
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
        setPage(0);
        getTenantList({
            offset: 0,
            size: event.target.value,
            sort: sortBy
        })
    };

    // const handleSortByChange = () => {
    //     setSortBy('-update_time');//TODO
    // }

    const loadingItem = () => (
        <React.Fragment>
            <div>
                <Grid className={classes.loading} container spacing={0} alignItems="center" wrap="nowrap">
                    <CircularProgress className={classes.progress} />
                </Grid>
            </div>
        </React.Fragment>
    )

    const actionControl = () => (
        <React.Fragment>
            <CardActions className={classes.actions}>
                <TablePagination
                    component="div"
                    count={tenants.total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    {...localizationPagination}
                />
            </CardActions>
        </React.Fragment>
    );

    const listItem = () => (
        <React.Fragment>
            <div>
                {tenants.list.map(data => (
                    <div key={data.tenantId}>
                        <Grid className={classes.detail} container spacing={0} alignItems="center" wrap="nowrap">
                            <Grid item md={2} xs={2}>
                                <Tooltip title={data.tenantAlias} aria-label={data.tenantAlias}>
                                    <Typography noWrap>
                                        {data.tenantAlias}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Tooltip title={data.tenantName} aria-label={data.tenantName}>
                                    <Typography noWrap>
                                        {truncate(data.tenantName, 10)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Tooltip title={data.email} aria-label={data.email}>
                                    <Typography noWrap>
                                        {data.email}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Tooltip title={data.phone} aria-label={data.phone}>
                                    <Typography noWrap>
                                        {data.phone}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Tooltip title={formatUTC(data.createTime, 'YYYY-MM-DD')}
                                    aria-label={formatUTC(data.createTime, 'YYYY-MM-DD')}>
                                    <Typography noWrap>
                                        {formatUTC(data.createTime, 'YYYY-MM-DD')}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Tooltip title={formatUTC(data.updateTime, 'YYYY-MM-DD')}
                                    aria-label={formatUTC(data.updateTime, 'YYYY-MM-DD')}>
                                    <Typography noWrap>
                                        {formatUTC(data.updateTime, 'YYYY-MM-DD')}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignSelf: 'center',
                                    margin: 10
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button variant="contained" color="primary"
                                            disabled
                                            onClick={function () {
                                                alert("暂时没有删除功能")
                                            }}
                                        >
                                            删除
                                        </Button>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <Button variant="contained" color="primary"
                                            onClick={event => onDetailedInfo(event, data.tenantId)}
                                        >
                                            编辑
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )

    return (
        <Card
            className={classes.root}
        >
            <CardContent className={classes.content}>
                <div className={classes.inner}>
                    <div>
                        <Grid className={classes.title} container spacing={0} alignItems="center" wrap="nowrap">
                            <Grid item md={2} xs={2}>
                                <Typography noWrap>
                                    租户编号
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Typography noWrap>
                                    租户名称
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Typography noWrap>
                                    邮箱
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Typography noWrap>
                                    联系电话
                                </Typography>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Typography noWrap>
                                    创建时间
                                </Typography>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Typography noWrap>
                                    更新时间
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={2}>
                                <Typography noWrap align="center">
                                    操作
                                </Typography>
                            </Grid>
                        </Grid>
                        {isloading ? loadingItem() : listItem()}
                    </div>
                </div>
            </CardContent>
            {isloading ? null : actionControl()}
        </Card>
    );
};

TenantList.propTypes = {
    classes: PropTypes.object.isRequired,
    tenants: PropTypes.object.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    getTenantList: PropTypes.func.isRequired,
    isloading: PropTypes.bool.isRequired
};

export default withStyles(TenantList);
