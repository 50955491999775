import * as Util from 'bpmn-js/lib/util/ModelUtil';
export function ContextPadProvider(eventBus, contextPad, modeling, elementFactory,
                            connect, create, 
                            // popupMenu,canvas, rules, 
                            translate) {

  contextPad.registerProvider(this);

  this._contextPad = contextPad;

  this._modeling = modeling;

  this._elementFactory = elementFactory;
  this._connect = connect;
  this._create = create;
  // this._popupMenu = popupMenu;
  // this._canvas  = canvas;
  // this._rules = rules;
  this._translate = translate;

}

ContextPadProvider["$inject"] = [
  'eventBus',
  'contextPad',
  'modeling',
  'elementFactory',
  'connect',
  'create',
  // 'popupMenu',
  // 'canvas',
  // 'rules',
  'translate'
];

ContextPadProvider.prototype.getContextPadEntries = function (element) {

  // let self = this;
  let 
    // contextPad = this._contextPad,
    modeling = this._modeling,
    elementFactory = this._elementFactory,
    connect = this._connect,
    create = this._create,
    // popupMenu = this._popupMenu,
    // canvas = this._canvas,
    // rules = this._rules,
    translate = this._translate;
  // let actions = {};

  function startConnect(event, element, autoActivate) {
    connect.start(event, element, autoActivate);
  }

  function removeElement(e) {
    modeling.removeElements([element]);
  }

  // function showModal() {
  //   modeling.showModal(element.id)
  // }

  function appendTask(event, element) {
    let shape = elementFactory.createShape(Object.assign({
      type: 'bpmn:Task'
    }));
    create.start(event, shape, element);
  }

  function appendEndTask(event, element) {
    let shape = elementFactory.createShape(Object.assign({
      type: 'bpmn:EndEvent'
    }));
    create.start(event, shape, element);
  }

  function appendGateway(event, element) {
    let shape = elementFactory.createShape(Object.assign({
      type: 'bpmn:ExclusiveGateway'
    }));
    create.start(event, shape, element);
  }

  if ((Util.is(element, 'bpmn:Task') || Util.is(element, 'bpmn:ExclusiveGateway')) && !!element.id) {
    return {
      'connect': {
        group: 'edit',
        className: 'bpmn-icon-connection-multi',
        title: translate('Connect using Sequence/MessageFlow or Association'),
        action: {
          click: startConnect,
          dragstart: startConnect
        }
      },
      'appendtask': {
        group: "edit",
        className: "bpmn-icon-task",
        title: translate('Append Task'),
        action: {
          dragstart: appendTask,
          click: appendTask
        }
      },
      'gateway': {
        group: 'edit',
        className: 'bpmn-icon-gateway-none',
        title: translate('Append Gateway'),
        action: {
          click: appendGateway
        }
      },
      'endEvent': {
        group: 'edit',
        className: 'bpmn-icon-end-event-none',
        title: translate('Append EndEvent'),
        action: {
          click: appendEndTask
        }
      },
      'delete': {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: translate('Remove'),
        action: {
          click: removeElement
        }
      }
    };
  } else if (Util.is(element, 'bpmn:StartEvent') && !!element.id) {
    return {
      'connect': {
        group: 'edit',
        className: 'bpmn-icon-connection-multi',
        title: translate('Connect using Sequence/MessageFlow or Association'),
        action: {
          click: startConnect,
          dragstart: startConnect
        }
      },
      'appendtask': {
        group: "edit",
        className: "bpmn-icon-task",
        title: translate('Append Task'),
        action: {
          dragstart: appendTask,
          click: appendTask
        }
      },
      'gateway': {
        group: 'edit',
        className: 'bpmn-icon-gateway-none',
        title: translate('Append Gateway'),
        action: {
          click: appendGateway
        }
      },
      'endEvent': {
        group: 'edit',
        className: 'bpmn-icon-end-event-none',
        title: translate('Append EndEvent'),
        action: {
          click: appendEndTask
        }
      },
      'delete': {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: translate('Remove'),
        action: {
          click: removeElement
        }
      },
    };
  } else if (Util.is(element, 'bpmn:SequenceFlow')) {
    return {
      'delete': {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: translate('WORKFLOWVIEW.REMOVE'),
        action: {
          click: removeElement
        }
      }
    }
  } else if (Util.is(element, 'bpmn:EndEvent')) {
    return {
      'connect': {
        group: 'edit',
        className: 'bpmn-icon-connection-multi',
        title: translate('Connect using Sequence/MessageFlow or Association'),
        action: {
          click: startConnect,
          dragstart: startConnect
        }
      },
      'delete': {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: translate('WORKFLOWVIEW.REMOVE'),
        action: {
          click: removeElement
        }
      }
    }
  } else if (Util.is(element, 'bpmn:Task') && !!element.id) {
    return {}
  }
};
