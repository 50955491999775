import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegulationPlanExecution from "../../../components/LeadershipManagement/RegulationPlanExecution";
import {
    getPlanDetails,
    updatePlanDetails,
    addPlanDetail,
    deletePlanDetail,
    getExecutionPlan,
    executeExecutionProcessByRecordId,
} from "../../../redux/modules/leadershipRegulationPlan";
import { getCurrentProcessStatus } from "../../../redux/modules/process";
import { getBusinessUnitsByRole, getDepartmentsByRole, getRole } from "../../../utils/userUtils";
import { getTimeStampByString, getNowTimestamp } from "../../../utils/datetime";
import { getRegulationVersions, createRegulationVersion, updateRegulationVersion } from "../../../redux/modules/regulation"

import ApprovePannel from "../ApprovePannel";
import RegulationUploadPannel from "./RegulationUploadPannel"

import { openApprovePannel } from "../../../redux/modules/approvePannel";
import { openRegulationUploadPannel } from "../../../redux/modules/regulationUpload";

import { cleanmodal, createmodal } from '../../../redux/modules/modal';
import { upload, download, downloadURL } from '../../../redux/modules/minio'
import * as _ from 'lodash';
import { FilePreviewer } from '@/components/SimpleComponents';

class RegulationPlanExecutionContainer extends Component {
    static propTypes = {
        plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        onhandleOpenPlanDetails: PropTypes.func,
        onhandleProcess: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            regId: '',
            status: ''
        };

    };


    componentDidMount() {
        const {
            onhandleGetPlanDetails,
            //onhandleGetCurrentPlan,
            //onhandleGetCurrentProcessStatus,
        } = this.props

        /*onhandleGetCurrentPlan((plan) => {
            if (!plan || Object.keys(plan).length === 0)
                return
            //onhandleGetPlanDetails(plan.planId)
        })*/
        onhandleGetPlanDetails('Drafting', '2', (data) => {
            console.log(data)
        })
        //onhandleGetCurrentProcessStatus((data) => {
        //    console.log(data)
        //})
    }

    getBucketName() {
        const { currentUserInfo } = this.props
        const bucketName = _.replace('dev.{tenant_id}.leadership.regulation-doc', '{tenant_id}', currentUserInfo.tenantId);
        return bucketName
    }

    handleProcessConfirm(action, planDetails, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment }, planDetails)
    }

    handleProcess(action, planDetails) {
        if (action.statusCode === 'Rejected' || action.review) {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action, planDetails))
        } else {
            this.handleExecuteProcess(action, planDetails)
        }
    }

    handleExecuteProcess(action, planDetails) {
        const {
            //currentUserInfo,
            //planDetails,
            //businessUnits,
            //onhandleUpdateVersion,
            //onhandleGetRegulationVersion,
            onhandleProcess,
            onhandleGetPlanDetails//,
            //onhandleGetCurrentProcessStatus
        } = this.props
        let actionParams = []
        //let currentProcessStatus = planDetails.currentProcessStatus
        let processCode = 'RegulationPlanExecution_' + planDetails.unitId;
        actionParams.push({
            recordId: planDetails.recordId,
            regId: planDetails.regId,
            planType: planDetails.planType,
            processCode: processCode
        })
        if (!action.distributed) {
            actionParams.push({
                handle: "updatePlanDetails",
                planDetails: planDetails,
                status: action.statusCode === 'Rejected' ? 'Rejected' : 'Submitted'
            })
            /*let planDetailFilter = null
            if (action.statusCode === 'Rejected') {//驳回本级，全业务线
                planDetailFilter = _.filter(planDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId
                })
            } else if (currentProcessStatus.statusCode === 'Rejected' || currentProcessStatus.statusCode === 'Drafting') { // 不是驳回，是本级提交，分业务线。
                planDetailFilter = _.filter(planDetails, (detail) => {
                    return detail.companyId === currentUserInfo.companyId && _.find(businessUnits, function (o) {
                        return o.unitId === detail.unitId
                    })
                })
            }
            if (planDetailFilter) {
                actionParams.push({
                    handle: "updateGoalDetails",
                    planDetails: planDetailFilter,
                    status: action.statusCode === 'Rejected' ? 'Rejected' : 'Submitted'
                })
            }*/
        }

        if (action.review) { //增加会审记录
            actionParams.push({
                handle: "addPlanReview",
                planId: planDetails.planId,
                comment: action.comment,
            })

        }

        let processParams = {
            action: action,
            actionParams: actionParams
        }

        onhandleProcess(processParams, () => {
            onhandleGetPlanDetails('Drafting', '2', (data) => {
                console.log(data)
            })
            //onhandleGetCurrentProcessStatus()
        })
    }

    handleAdd(planDetail) {
        const { plan, onhandleAdd, onhandleGetPlanDetails, regulations, businessUnits } = this.props
        if (planDetail.planType && planDetail.planType !== 'Add') {
            planDetail.regId = Number(planDetail.regId)
            let reg = _.find(regulations, (r) => { return r.regId === planDetail.regId })
            planDetail['unitId'] = reg.unitId
        } else {
            planDetail['planType'] = 'Add'
            planDetail['unitId'] = businessUnits[0].unitId
            if (!planDetail.revisionUnit) {
                planDetail['revisionUnit'] = 'YEAR'
            }
            planDetail.revisionCycle = Number(planDetail.revisionCycle)
        }
        planDetail['planId'] = plan.planId
        if (planDetail.targetDate) {
            planDetail['targetDate'] = getTimeStampByString(planDetail.targetDate)
        } else {
            planDetail['targetDate'] = getNowTimestamp()
        }
        onhandleAdd(planDetail, () => {
            onhandleGetPlanDetails('Drafting', '2', (data) => {
                console.log(data)
            })
        })
    }

    async handleUpdate(planDetail) {
        const { onhandleUpdate, onhandleGetPlanDetails } = this.props
        let updateDetail = {
            dtlId: planDetail.dtlId,
            content: planDetail.content,
            targetDate: getTimeStampByString(planDetail.targetDate)
        }
        await onhandleUpdate([updateDetail])
        onhandleGetPlanDetails('Drafting', '2', (data) => {
            console.log(data)
        })
    }

    handleDelete(planDetail) {
        const { onhandleDelete, onhandleGetPlanDetails } = this.props
        onhandleDelete(planDetail.dtlId, () => {
            onhandleGetPlanDetails('Drafting', '2', (data) => {
                console.log(data)
            })
        })
    }

    handleOpenRegulationUploadPannel(rowData) {
        const { onhandleOpenRegulationUploadPannel } = this.props
        onhandleOpenRegulationUploadPannel(this.handleAdd.bind(this))
    }

    // eslint-disable-next-line class-methods-use-this
    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        return files;
    }


    handleDownload() {
        const { onhandleGetRegulationVersion, onhandleDownloadRegulation } = this.props
        let regId = this.state.regId
        let status = this.state.status
        onhandleGetRegulationVersion(regId, (versions) => {
            if (versions) {
                let versionArr;
                if (status === 'Published') {
                    versionArr = versions.filter((version) => {
                        return version.status === 'active'
                    })
                }
                else {
                    versionArr = versions.filter((version) => {
                        return version.status === 'new'
                    })
                }
                if (versionArr && versionArr.length !== 0) {
                    let fileName = versionArr[0].docLink;
                    onhandleDownloadRegulation(fileName, this.getBucketName());
                }
            }
        })
    }

    async handlePreview(regId, status) {
        const { onhandleGetRegulationVersion } = this.props
        onhandleGetRegulationVersion(regId, (versions) => {
            if (versions) {
                let versionArr;
                if (status === 'Published') {
                    versionArr = versions.filter((version) => {
                        return version.status === 'active'
                    })
                }
                else {
                    versionArr = versions.filter((version) => {
                        return version.status === 'new'
                    })
                }
                if (versionArr && versionArr.length !== 0) {
                    let fileName = versionArr[0].docLink;
                    return this.props.onhandleDownloadUrl(fileName, this.getBucketName()).then(res => {
                        if (res && res.payload) {
                            console.log(' res.payload', res.payload);
                            this.setState({
                                previewFile: {
                                    open: true,
                                    fileURL: res.payload,
                                    fileType: fileName.split('.')[1]
                                },
                                regId: regId,
                                status: status
                            })
                            return res.payload;
                        } else {
                            // 不做什么
                        }
                    });
                }
            }

        })
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    onConfirm(files, isUploadSetter, values) {
        isUploadSetter(true);
        // 开始自定义上传操作

        const that = this;
        const {
            //onhandleUpdate,
            //onhandleGetPlanDetails,
            onhandleGetRegulationVersion,
            onhandleUpdateVersion,
            onhandleUploadRegulation
        } = that.props
        let offset = getNowTimestamp();
        onhandleUploadRegulation(files, this.getBucketName(), offset, () => {
            onhandleGetRegulationVersion(values.paraB, (versions) => {
                if (versions) {
                    let versionArr = versions.filter((version) => {
                        return version.status === 'new'
                    })
                    if (versionArr && versionArr.length !== 0) {
                        let updateVersion = {
                            "versionId": versionArr[0].versionId,
                            "status": "inactive",
                            "tenantId": versionArr[0].tenantId
                        }
                        onhandleUpdateVersion(versionArr[0].versionId, updateVersion)
                    }
                }
                //let arr = files[0].name.split('.');
                let doc = {
                    "versionId": 0,
                    "regId": values.paraB,
                    "versionOrder": 1,
                    "docLink": offset + files[0].name
                };
                that.props.onhandleCreateDoc(doc);
                /*let updatePlanDtl = [{
                    "dtlId": values.paraA,
                    "status": "Submitted",
                }];

                onhandleUpdate(updatePlanDtl, () => {
                    onhandleGetPlanDetails('Drafting', '2', (data) => {
                        console.log(data)
                    })
                })*/
                that.props.onhandleModalClose();
            })
        });
    }

    openUploadModal(regId, regName, dtlId, planType) {
        const {
            onhandleGetRegulationVersion,
            onhandleOpenFileUpload
        } = this.props
        onhandleGetRegulationVersion(regId, (reg) => {
            let versionOrder = 1;
            if (planType !== 'Add') {
                let versionActive = _.filter(reg.versions, (r) => { return r.status === "active" })
                versionOrder = versionActive.versionOrder + 1
            }
            onhandleOpenFileUpload({
                // 上传组件初始化配置
                title: '上传文件',
                note: '请拖拽文件至此处',
                // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
                fileType: '.xls,.xlsx,.doc,.docs',
                // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
                maxSize: '1024MB',
                // 是否支持多文件上传
                multiple: true,
                // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
                onFileSelected: this.onFileSelected.bind(this),
                // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
                onConfirm: this.onConfirm.bind(this),
                docName: regName,
                versionOrder: versionOrder.toString(),
                paraA: dtlId,
                paraB: regId
            });
        })
    }

    render() {
        const { previewFile } = this.state;
        return (
            <div>
                <RegulationPlanExecution
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentPlanDetails={this.props.planDetails}
                    onhandleOpenRegulationUploadPannel={this.handleOpenRegulationUploadPannel.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onOpenUploadModal={this.openUploadModal.bind(this)}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                    currentProcessStatus={this.props.currentProcessStatus}
                />
                <ApprovePannel />
                <RegulationUploadPannel />
                <FilePreviewer
                    open={previewFile.open}
                    fileType={previewFile.fileType}
                    filePath={previewFile.fileURL}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onDownload={this.handleDownload.bind(this)}
                />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.executionPlan,
        planDetails: state.leadershipRegulationPlan.planDetailsW,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        regulations: state.regulation.regulations,
        currentProcessStatus: state.process.currentProcessStatus.RegulationPlanExecution || {},
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('RegulationPlanExecution', null, callback))
        },
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getExecutionPlan(callback))
        },
        onhandleGetPlanDetails: (status, type, callback) => {
            dispatch(getPlanDetails(status, type, callback))
        },
        onhandleUpdate: (planDetails, callback) => {
            dispatch(updatePlanDetails(planDetails, callback))
        },
        onhandleAdd: (planDetail, callback) => {
            dispatch(addPlanDetail(planDetail, callback))
        },
        onhandleDelete: (dtlId, callback) => {
            dispatch(deletePlanDetail(dtlId, callback))
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeExecutionProcessByRecordId(actionParams, callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleOpenRegulationUploadPannel: (confirmCallback, closeCallback) => {
            dispatch(openRegulationUploadPannel(confirmCallback, closeCallback))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('fileUpload', config));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateDoc: (doc) => {
            dispatch(createRegulationVersion(doc));
        },
        onhandleGetRegulationVersion: (regId, callback) => {
            dispatch(getRegulationVersions(regId, callback))
        },
        onhandleUpdateVersion: (versionId, regulationVersion) => {
            dispatch(updateRegulationVersion(versionId, regulationVersion));
        },
        onhandleUploadRegulation: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleDownloadRegulation: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanExecutionContainer)