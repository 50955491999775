import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message'
import { push } from 'connected-react-router';
import moment from 'moment';
import * as _ from 'lodash';


const porxyApi = '/api'

const currentYear = moment().format("YYYY");

const initialState = {
    plan: {},
    plans: [],
    planDetails: [],
    planDetailsW: [],
    planReviews: [],

    plansConfig: {},
    planCofig: {},
    planDetailConfig: {},
    planDetailTableConfig: {},

    isEditablePlan: false,
    isEditablePlanDetail: false,
    regulationPlanCreateOpen: false,

    currentPlan: {},
    executionPlan: {},

    currentTrp: {},
    TrpError: {},
    TrpChange: false,

    TrpProcessRecord: {},

    currentPlanS: {},//第三版修改，记录目前操作的plan，包括所以业务的临时计划和年度计划
    annuPlan: {}
};


export const setPlan = createAction('@@xcloud/regulation/setplan')
export const setPlanConfig = createAction('@@xcloud/regulation/setplanconfig')

export const setPlans = createAction('@@xcloud/regulation/setplans')
export const setPlansConfig = createAction('@@xcloud/regulation/setplansconfig')

export const setIsEditablePlan = createAction('@@xcloud/regulation/setiseditableplan')

export const setCurrentPlan = createAction('@@xcloud/regulation/setcurrentplan')
export const setExecutionPlan = createAction('@@xcloud/regulation/setexecutionplan')

export const setPlanDetails = createAction('@@xcloud/regulation/setplandetails')
export const setPlanDetailsW = createAction('@@xcloud/regulation/setplandetailsw')
export const setPlanDetailConfig = createAction('@@xcloud/regulation/setplandetailsconfig')

export const setPlanDetailTableConfig = createAction('@@xcloud/regulation/setplandetailsTableconfig')

export const setPlanReviews = createAction('@@xcloud/regulation/setplanreviews')
export const setIsEditablePlanReview = createAction('@@xcloud/regulation/setiseditableplanreview')

export const setRegulationPlanCreateOpen = createAction('@@anfenguserportal/safteyactivity/setteamactivitycreateopen')

export const stopLoading = createAction('@@xcloud/regulation/stopLoading');

export const startLoading = createAction('@@xcloud/regulation/startLoading');

export const setCurrentTrp = createAction('@@xcloud/regulation/setCurrentTrp');
export const setTrpError = createAction('@@xcloud/regulation/setTrpError');
export const setTrpChange = createAction('@@xcloud/regulation/setTrpChange');

export const setTrpProcessRecord = createAction('@@xcloud/regulation/setTrpProcessRecord');


export const setCurrentPlanS = createAction('@@xcloud/regulation/setcurrentplans')
export const setAnnuPlan = createAction('@@xcloud/regulation/setannuplan')

/**
 * Regulation Plan
 */
export const addPlans = createAction('@@xcloud/regulation/addplans',
    (plan, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans', plan)
            .then((res) => {
                if (res) {
                    dispatch(setCurrentPlanS(res.data));
                    dispatch(stopLoading());
                    dispatch(createMessage('success', '启动年度计划成功'));
                    dispatch(goToPlanDetail());
                    //dispatch(createhttpMessage(res, "增加规章制度完善计划成功"));
                    if (callback)
                        callback(res.data)
                }

            }).catch(err => {
                let msg = `增加规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deletePlan = createAction('@@xcloud/regulation/deleteplan',
    (planId, callback) => (dispatch, getState, httpClient) => {
        httpClient.delete(porxyApi + '/regulationplans/' + planId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除规章制度完善计划成功"));
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `删除规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    })

export const updatePlans = createAction('@@xcloud/regulation/updateplans',
    (plan, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/regulationplans', plan)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新规章制度完善计划成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `更新规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getPlan = createAction('@@xcloud/regulation/getplan',
    (planId,callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/' + planId)
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划成功"));
                    dispatch(setPlan(res.data))
                    if (callback)
                        callback(res.data)
                }

            })
            .catch(err => {
                let msg = `获取规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const getPlans = createAction('@@xcloud/regulation/getplans',
    (year, status, type, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/regulationplans?type=' + type;
        if (year)
            url = url + '&year=' + year
        if (status)
            url = url + '&status=' + status
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setPlans(res.data))
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `获取规章制度完善计划列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

    export const getAnnuPlan = createAction('@@xcloud/regulation/annuplan',
    (planCode, callback) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/regulationplans?type=1&status=new'
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    let realPlan = _.filter(res.data,(plan)=>{
                        return plan.planCode === planCode
                    })
                    if( realPlan||realPlan.length!==0 ){
                        realPlan= _.filter(realPlan,(plan)=>{
                            return plan.planType === "ANNU"
                        })
                    }
                    dispatch(setAnnuPlan(realPlan))
                    if (callback)
                        callback(realPlan)
                }
            })
            .catch(err => {
                let msg = `获取规章制度完善计划列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });

export const getCurrentPlan = createAction('@@xcloud/regulation/getcurrrentplan',
    (callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/current')
            .then((res) => {
                // console.log(res.data);
                if (res) {
                    dispatch(setCurrentPlan(res.data))
                    //dispatch(createhttpMessage(res, "获取当前规章制度完善计划成功"));
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                //let msg = `获取当前规章制度完善计划失败`;
                //dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getExecutionPlan = createAction('@@xcloud/regulation/getexecutionplan',
    (callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans?year=' + currentYear + '&status=finalized')
            .then((res) => {
                if (res) {
                    dispatch(setExecutionPlan(res.data[0]))
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划列表成功"));
                    if (callback)
                        callback(res.data[0])
                }
            })
            .catch(err => {
                let msg = `获取本年度执行规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        return {}
    });


/**
* Regulation Plan Detail
*/
export const addPlanDetail = createAction('@@xcloud/regulation/addplandetail',
    (planDetail, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/details', planDetail)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加规章制度完善计划详情成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `增加规章制度完善计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const addPlanDetailTemp = createAction('@@xcloud/regulation/addplandetail',
    (planDetail, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/details/temp', planDetail)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加临时规章制度完善计划详情成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `增加临时规章制度完善计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const deletePlanDetail = createAction('@@xcloud/regulation/deleteplandetail',
    (dtlId, callback) => (dispatch, getState, httpClient) => {
        httpClient.delete(porxyApi + '/regulationplans/details/' + dtlId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除规章制度完善计划详情成功"));
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `删除规章制度完善计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    })

export const updatePlanDetails = createAction('@@xcloud/regulation/updateplandetails',
    (planDetails, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/regulationplans/details', planDetails)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新规章制度完善计划详情成功"));
                    if (callback)
                        callback(res.data)
                }
            }
            ).catch(err => {
                let msg = `更新规章制度完善计划详情失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
//依据planId显示dtl
export const getPlanDetailsP = createAction('@@xcloud/regulation/getplandetails',
    (status, type, planId, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/details?status=' + status + '&type=' + type)
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划详情列表成功"));
                    if (planId) {
                        let planDtl = res.data;
                        planDtl = _.filter(planDtl, function (o) { return o.planId === planId; });
                        if (type === '2') dispatch(setPlanDetailsW(planDtl))
                        else dispatch(setPlanDetails(planDtl))
                    }
                    else {
                        if (type === '2') dispatch(setPlanDetailsW(res.data))
                        else dispatch(setPlanDetails(res.data))
                    }
                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `获取规章制度完善计划详情列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
//未附带附加条件显示dtl
export const getPlanDetails = createAction('@@xcloud/regulation/getplandetails',
    (status, type, callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/details?status=' + status + '&type=' + type)
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划详情列表成功"));
                    if (type === '2') dispatch(setPlanDetailsW(res.data))
                    else dispatch(setPlanDetails(res.data))

                    if (callback)
                        callback(res.data)
                }
            })
            .catch(err => {
                let msg = `获取规章制度完善计划详情列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

/*export const getPlanDetails = createAction('@@xcloud/regulation/getplandetails',
(planId) => (dispatch, getState, httpClient) => {
    httpClient.get(porxyApi + '/regulationplans/' + planId + '/details')
        .then((res) => {
            if (res) {
                //dispatch(createhttpMessage(res, "获取规章制度完善计划详情列表成功"));
                dispatch(setPlanDetails(res.data))
            }
        })
        .catch(err => {
            let msg = `获取规章制度完善计划详情列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
});*/


/**
* Regulation Plan Review
*/
export const addPlanReviews = createAction('@@xcloud/regulation/addplanreviews',
    (planReviews, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/reviews', planReviews)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加规章制度完善计划评审成功"));
                    if (callback)
                        callback(res.data)
                }

            }).catch(err => {
                let msg = `增加规章制度完善计划评审失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updatePlanReviews = createAction('@@xcloud/regulation/updateplanreviews',
    (planReviews, callback) => (dispatch, getState, httpClient) => {
        httpClient.patch(porxyApi + '/regulationplans/reviews', planReviews)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新规章制度完善计划评审成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `更新规章制度完善计划评审失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getPlanReviews = createAction('@@xcloud/regulation/getplanreviews',
    (planId) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/' + planId + '/reviews')
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划评审成功"));
                    dispatch(setPlanReviews(res.data))
                }
            })
            .catch(err => {
                let msg = `获取规章制度完善计划评审失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeProcess = createAction('@@xcloud/regulation/excuteprocess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplan/process', actionParams, { headers: { need_loading: true } })
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行规章制度完善计划流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行规章制度完善计划流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
    export const executeProcessByRecordId = createAction('@@xcloud/regulation/excuteprocessbyrecordid',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/process/recordid', actionParams)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行规章制度完善进展流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行规章制度完善进展流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const startPlanExecution = createAction('@@xcloud/regulation/startplanexecution',
    (plan, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/execution/start', plan)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动规章制度完善执行流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `启动规章制度完善执行流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const executeExecutionProcess = createAction('@@xcloud/regulation/excuteexecutionprocess',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/execution/process', actionParams)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行规章制度完善进展流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行规章制度完善进展流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const executeExecutionProcessByRecordId = createAction('@@xcloud/regulation/excuteexecutionprocessbyrecordid',
    (actionParams, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/execution/process/recordid', actionParams)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "执行规章制度完善进展流程成功"));
                    if (callback)
                        callback(res.data)
                }
            }).catch(err => {
                let msg = `执行规章制度完善进展流程失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
/**
* Temp Regulation Plan
*/
export const initTempPlan = createAction('@@xcloud/regulation/inittempplan',
    (planId) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/details?type=3')
            .then((res) => {
                if (res) {
                    let planDtl = res.data;
                    planDtl = _.filter(planDtl, function (o) { return o.planId === planId; });
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划成功"));
                    dispatch(setPlanDetails(planDtl))
                    //dispatch(setPlan(res.data))
                    dispatch(push('/leadership/temporaryplan/temporaryregulationplanexecution'));
                }

            })
            .catch(err => {
                let msg = `获取规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const initCreate = createAction('@@xcloud/regulation/initcreate',
    () => (dispatch, getState, httpClient) => {
        dispatch(startLoading());
        // 隐患治理入口不在此，初始化表单数据可能异常
        // dispatch(initTrp(true));
        dispatch(push('/leadership/temporaryplan/temporaryregulationplaninfo'));
    });

export const initTrp = createAction('@@xcloud/regulation/initTrp',
    (isCreate) => (dispatch, getState) => {
        //let currentUserInfo = getState().auth.currentUserInfo;
        if (isCreate) {
            let newTrp = {
                "planName": '',
                "opentext": ''
            };
            dispatch(setCurrentTrp(newTrp));
        }
        let errorInfo = {
            planName: ''
        };
        dispatch(setTrpError(errorInfo));
        dispatch(setTrpChange(false));
    });

export const backToTrpList = createAction('@@xcloud/regulation/backToTrpList',
    () => (dispatch, getState) => {
        dispatch(push('/leadership/plan/regulation'));
    });//backToPlanList

export const backToPlanList = createAction('@@xcloud/regulation/backToPlanList',
    () => (dispatch, getState) => {
        dispatch(push('/leadership/plan/regulation'));
    });//backToPlanList

export const goToPlanDetail = createAction('@@xcloud/regulation/goToPlanDetail',
    () => (dispatch, getState) => {
        dispatch(push('/leadership/regulationplan/regulationplandetail'));
    });

export const goToPlanDetailAll = createAction('@@xcloud/regulation/goToPlanDetailAll',
    () => (dispatch, getState) => {
        dispatch(push('/leadership/regulationplan/regulationplandetailall'));
    });

export const goToTrpExecution = createAction('@@xcloud/regulation/goToTrpExecution',
    () => (dispatch, getState) => {
        dispatch(push('/leadership/temporaryplan/temporaryregulationplanexecution'));
    });//leadership_tempregulationplanexecution

export const createTrp = createAction('@@xcloud/regulation/createTrp',
    (tempRegulationPlan, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/temp', tempRegulationPlan)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res.data);
                    dispatch(setCurrentPlanS(res.data));
                    dispatch(stopLoading());
                    dispatch(createMessage('success', '创建计划成功'));
                    dispatch(goToPlanDetail());
                    //dispatch(backToTrpList());
                    if (callback)
                        callback(res.data)
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '创建计划失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });

export const createTrpProcessRecord = createAction('@@xcloud/regulation/createTrpProcessRecord',
    (TrpProcessRecord, callback) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/processes/records', TrpProcessRecord)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res.data);
                    dispatch(setTrpProcessRecord(res.data));
                    dispatch(stopLoading());
                    dispatch(createMessage('success', '创建流程记录成功'));
                    //dispatch(backToTrpList());
                    if (callback)
                        callback(res.data)
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '创建流程记录失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });
//创建规章制度计划关联
/*export const createTrpProcess = createAction('@@xcloud/regulation/createTrpProcessRecord',
    (TrpProcess) => (dispatch, getState, httpClient) => {
        httpClient.post(porxyApi + '/regulationplans/processes', TrpProcess)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res.data);
                    //dispatch(setTrpProcessRecord(res.data));
                    dispatch(stopLoading());
                    dispatch(createMessage('success', '创建规章制度计划关联成功'));
                    dispatch(goToPlanDetail());
                }
                return res;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(err => {
                dispatch(stopLoading());
                let msg = '创建规章制度计划关联失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
    });*/
//新版规章制度
export const getPlanDtl = createAction('@@xcloud/regulation/getplandtl',
    (planId,callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/' + planId)
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划成功"));
                    dispatch(setCurrentPlanS(res.data))
                    dispatch(stopLoading());
                    dispatch(goToPlanDetail());
                    if (callback)
                        callback(res.data)
                }

            })
            .catch(err => {
                let msg = `获取规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getPlanDtlAll = createAction('@@xcloud/regulation/getplandtlall',
    (planId,callback) => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/regulationplans/' + planId)
            .then((res) => {
                if (res) {
                    //dispatch(createhttpMessage(res, "获取规章制度完善计划成功"));
                    dispatch(setCurrentPlanS(res.data))
                    dispatch(stopLoading());
                    dispatch(goToPlanDetailAll());
                    if (callback)
                        callback(res.data)
                }

            })
            .catch(err => {
                let msg = `获取规章制度完善计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });
export const LeadershipRegulationPlanReducer = handleActions(
    {
        [setPlan]: (state, { payload: data }) => {
            return {
                ...state,
                plan: data
            }
        },
        [setPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setAnnuPlan]: (state, { payload: data }) => {
            return {
                ...state,
                annuPlan: data
            }
        },
        [setTrpError]: (state, { payload: value }) => {
            return {
                ...state,
                TrpError: value
            };
        },
        [setTrpChange]: (state, { payload: value }) => {
            return {
                ...state,
                TrpChange: value
            };
        },
        [setCurrentTrp]: (state, { payload: value }) => {
            return {
                ...state,
                currentTrp: value
            };
        },
        [setTrpProcessRecord]: (state, { payload: value }) => {
            return {
                ...state,
                TrpProcessRecord: value
            };
        },

        [setPlanConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planConfig: data
            }
        },

        [setPlansConfig]: (state, { payload: data }) => {
            return {
                ...state,
                plansConfig: data
            }
        },


        [setIsEditablePlan]: (state, { payload: data }) => {
            return {
                ...state,
                isEditablePlan: data
            }
        },

        [setCurrentPlan]: (state, { payload: data }) => {
            return {
                ...state,
                currentPlan: data
            }
        },

        [setCurrentPlanS]: (state, { payload: data }) => {
            return {
                ...state,
                currentPlanS: data
            }
        },

        [setExecutionPlan]: (state, { payload: data }) => {
            return {
                ...state,
                executionPlan: data
            }
        },

        [setPlanDetails]: (state, { payload: data }) => {
            return {
                ...state,
                planDetails: data
            }
        },

        [setPlanDetailsW]: (state, { payload: data }) => {
            return {
                ...state,
                planDetailsW: data
            }
        },

        [setPlanDetailConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planDetailConfig: data
            }
        },

        [setPlanDetailTableConfig]: (state, { payload: data }) => {
            return {
                ...state,
                planDetailTableConfig: data
            }
        },

        [setPlanReviews]: (state, { payload: data }) => {
            return {
                ...state,
                planReviews: data
            }
        },

        [setIsEditablePlanReview]: (state, { payload: data }) => {
            return {
                ...state,
                isEditablePlanReview: data
            }
        },
        [setRegulationPlanCreateOpen]: (state, { payload: data }) => {
            return {
                ...state,
                regulationPlanCreateOpen: data
            }
        },

    },
    initialState
)

export default LeadershipRegulationPlanReducer

