import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    width: '100%',
    padding: 20
  },
  cardRoot: {
    maxWidth: 345
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    marginTop: 10
  },
  media: {
    height: 240
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.white,
  },
  // title: {
  // backgroundColor: theme.palette.title,
  // borderBottom: `2px solid ${theme.palette.divider}`,
  // padding: '0 24px 0 24px',
  // height: '50px',
  // textAlign: 'center'
  // },
  icon: {
    color: theme.palette.white,
  },
  detail: {
    padding: '0 24px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  },
  gridListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    // width: '100%',
    // height: '30rem',
    // height: 450,
  },
  gridListTile: {
    width: '20em !important',
    transition: 'opacity 0.2s',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5
    },
  },
  titleBar: {
    color: theme.palette.primary.light,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  beforeButton: {
    position: 'absolute',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.3)',
    left: 5,
    top: '50%',
  },
  nextButton: {
    position: 'absolute',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.3)',
    right: 5,
    top: '50%',
  },
  detailDownloadButton: {
    color: theme.palette.white
  },
});

export default withStyles(styles);
