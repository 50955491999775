import React from 'react';
import {
    Button,
    Card,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Add,
    Edit,
    DeleteOutline,
} from '@material-ui/icons';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getManufactureTypeName, MANUFACTURE_DICT_TYPES } from "@/utils/constant";

const InstallActionListComponent = props => {
    const { classes,
        manipulates,
        constant,
        onhandleUpdate,
        onhandleDelete,
        onhandleAdd,
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        title: false,
        toolbar: false
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '操作名称',
                field: 'manipulateName',
                render: row => <Typography>{row.manipulateName}</Typography>
            },
            {
                title: '装置',
                field: 'installName',
                render: row => <Typography>{row.installName}</Typography>
            },
            {
                title: '单元',
                field: 'unitName',
                render: row => <Typography>{row.unitName}</Typography>
            },
            {
                title: '设备',
                field: 'deviceName',
                render: row => <Typography>{row.deviceName}</Typography>
            },
            {
                title: '操作类型',
                field: 'optType',
                render: row => <Typography>{getOptType(row.actions)}</Typography>
            },
            {
                title: '负责人',
                field: 'optUsers',
                render: row => <Typography>{getOptOwner(row.owners)}</Typography>
            },
        ]
        return columns
    }

    const assemblyData = () => {
        return manipulates
    }

    const getOptType = (actions) => {
        let otype = "";
        if (actions) {
            actions.forEach(element => {
                if (element.actionName) {
                    otype = otype + getManufactureTypeName(constant, MANUFACTURE_DICT_TYPES.INSTALL_ACTION, element.actionName) + ",";
                }
            });
        }
        return otype.length > 0 ? otype.substring(0, otype.length - 1) : "";
    }

    const getOptOwner = (owners) => {
        let owner = "";
        if (owners) {
            owners.forEach(element => {
                owner = owner + element.lastName + element.firstName + ","
            });
        }
        return owner.length > 0 ? owner.substring(0, owner.length - 1) : "";
    }

    return (
        <Card alignitems="center" className={classes.root}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'装置操作配置列表'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={12}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        <Button variant='contained'
                            color='primary'
                            onClick={onhandleAdd}
                            startIcon={<Add />}
                        >
                            新增
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={tableStyle}
                    editable={
                        {
                            onRowDelete: (oldData) => onhandleDelete(oldData)
                        }
                    } icons={{
                        Delete: () => <DeleteOutline color={'error'} />
                    }}
                    actions={[
                        // {
                        //     icon: 'add',
                        //     tooltip: '新增',
                        //     isFreeAction: true,
                        //     onClick: (event, rowData) => {
                        //         onhandleAdd(event, rowData)
                        //     }
                        // },
                        rowData => ({
                            icon: () => <Edit color="primary" />,
                            tooltip: '修改',
                            onClick: (event, rowData) => onhandleUpdate(event, rowData)
                        }),
                    ]}
                >
                </MaterialTable>
            </Grid>
        </Card>
    )
}

InstallActionListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

InstallActionListComponent.defaultProps = {
    manipulates: []
};

export default withStyles(InstallActionListComponent);