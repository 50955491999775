import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrgChart from "../../../components/SystemManagement/OrgManagement/OrgChart";
import PropTypes from "prop-types";
import { getCompanyTree, setCurrentNode } from '../../../redux/modules/company';

class OrgChartContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.object,
        currentNode: PropTypes.object
    };

    componentDidMount() {
        this.props.onhandleInitOrg();
    }

    handleSetCurrentNode = (node) => {
        this.props.onhandleSetCurrentNode(node);
    };

    render() {
        return (
            <OrgChart
                companyTree={this.props.companyTree}
                onSetCurrentNode={this.handleSetCurrentNode.bind(this)}
                currentNode={this.props.currentNode}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyTree: state.company.companyTree,
        currentNode: state.company.currentNode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitOrg: () => {
            dispatch(getCompanyTree())
        },
        onhandleSetCurrentNode: (node) =>{
            dispatch(setCurrentNode(node))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgChartContainer)
