import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { localization } from "../../../utils/mtable";
import { getMoment, getDateByTimestamp } from '../../../utils/datetime';
import _ from 'lodash';
import {
    Chip,
    Grid,
    Button,
    Typography,
    TextField,
    InputAdornment,
    Card,
    Divider,
    FormControlLabel,
    Switch
} from '@material-ui/core';

const IndoorRecord = props => {
    const {
        classes,
        userProfile,
        currentUserInfo,
        companyList,
        inspectionItemList,
        recordHistoryList,
        currentPlan,
        onhandleChange,
        onhandleOpentextChange,
        onhandleInvalid,
        onhandleSave,
        onhandleBack,
        users,
        departmentList,
        unit
    } = props;

    // console.log('======================= currentPlan recordHistoryList')
    // console.log(currentPlan)
    // console.log(recordHistoryList)

    const isExpired = getMoment()().isAfter(getMoment()(currentPlan.startTime).add(currentPlan.expiredAt, 'minutes'))

    const isPlanEditable = !['done', 'expired', 'pending'].includes(currentPlan.status);

    const isConfirming = () => {
        // 是确认角色且计划处于确认状态
        if (currentPlan.recordConfirm && currentPlan.confirmRole === currentUserInfo.roleId && currentPlan.status === 'pending') {
            return true;
        }
        return false;
    }

    const getEditable = () => {

        // 可编辑的计划状态
        let editableStatus = true;
        if (!isPlanEditable) {
            editableStatus = false
        }
        // 可编辑的时间
        const planStarted = getMoment()().isSameOrAfter(getMoment()(currentPlan.startTime));
        // 可编辑的人员
        let permission = false;
        if (currentPlan.inspector) {
            // 巡检人本人
            permission = userProfile.userId === currentPlan.inspector
        } else {
            // 巡检计划执行班组成员
            if (currentPlan.onsitePlanDpt && currentPlan.onsitePlanDpt.length) {
                const userDptId = userProfile.roles[0].departments && userProfile.roles[0].departments[0] ? userProfile.roles[0].departments[0].dptId : null;
                permission = _.findIndex(currentPlan.onsitePlanDpt, { 'dptId': userDptId }) >= 0;
            }
        }
        // 可编辑 or 可确认
        return (editableStatus && permission && planStarted) || isConfirming();
    }

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route' })
    //     return permission && permission.action === 'W'
    // };

    const renderCompany = (companyId) => {
        let item = _.filter(companyList, function (o) { return companyId === o.companyId })
        if (item && item.length) {
            return item[0].companyName
        } else {
            return ''
        }
    };

    const renderDepName = (inspector) => {
        let item = _.filter(users.list, function (o) { return inspector === o.userId })
        if (item && item.length) {
            let cell = _.filter(departmentList, function (o) { return item[0].dptId === o.dptId })
            if (cell && cell.length) {
                return cell[0].dptName
            } else {
                return ''
            }
        } else {
            return ''
        }
    };

    // 校验巡检数值
    const validateVal = (row, specifiedValue) => {
        let validResult = true
        // specify指历史值，不指定则指row值（填写值）
        let val = specifiedValue ? specifiedValue.value : row.val;
        let histInsUseStatus = 'INUSE'
        if (specifiedValue) {
            histInsUseStatus = specifiedValue.histInsUseStatus || 'INUSE'
        } else {
            histInsUseStatus = row.histInsUseStatus || 'INUSE'
        }

        if (val === "不合格" || val === "未知") {
            return false
        }

        if (val === "合格") {
            return true
        }

        let standardTemplate = row.template ? row.template : '';
        let standartValue = row.standardValue ? row.standardValue : '';
        if (histInsUseStatus === 'OFFUSE' && row.offTemplate && row.standardOffValue) {
            standardTemplate = row.offTemplate
            standartValue = row.standardOffValue
        }

        let operatorArray = standardTemplate ? standardTemplate.split("|") : []
        let stdValArry = standartValue ? standartValue.split(",") : []

        let decimalVal = _.toNumber(val)
        operatorArray.forEach((operator, idx) => {
            let decimalStdVal = _.toNumber(stdValArry[idx])
            let subOperator = operator.substring(0, 2)

            switch (subOperator) {
                case '<{':
                    validResult = (validResult && decimalVal < decimalStdVal)
                    break;
                case '<=':
                    validResult = (validResult && decimalVal <= decimalStdVal)
                    break;
                case '={':
                    validResult = (validResult && decimalVal === decimalStdVal)
                    break;
                case '>{':
                    validResult = (validResult && decimalVal > decimalStdVal)
                    break;
                case '>=':
                    validResult = (validResult && decimalVal >= decimalStdVal)
                    break;
                default:
                    break;
            }
        })

        return validResult
    }

    const tableOptions = {
        padding: 'dense',
        paging: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        search: false,
        toolbar: false
    }

    const assemblycolumns = () => {
        const basicColumns = [
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                width: '150px',
                editable: 'never',
            },
            {
                title: '检查参数',
                field: 'itemName',
                align: 'left',
                render: (row) => <Typography>{row.itemNameAlias ? row.itemNameAlias : (row.itemName ? row.itemName : '')}</Typography>,
                editable: 'never'
            },
            // {
            //     title: '参数',
            //     field: 'itemName',
            //     render: row => row ? <Typography>{row.itemName}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '设备编号',
            //     field: 'deviceNo',
            //     render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '参数描述',
                field: 'opentext',
                align: 'left',
                render: (row) => <Typography>{row.opentext ? row.opentext : ''}</Typography>,
            },
            // {
            //     title: '标准值',
            //     field: 'standardValue',
            //     render: row => row ? <Typography>{row.standardValue}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: 'template',
            //     field: 'template',
            //     render: row => row ? <Typography>{row.template}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '单位',
            //     field: 'itemUnit',
            //     render: row => row ? <Typography>{renderUnit(row)}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '时间',
            //     field: 'updateTime',
            //     render: row => row ? <Typography>{renderRowTime(row)}</Typography> : '',
            //     editable: 'never'
            // },
        ];

        // 历史记录5列
        if (recordHistoryList && Object.keys(recordHistoryList).length) {
            Object.keys(recordHistoryList).forEach(key => {
                basicColumns.push({
                    align: 'left',
                    title: key,
                    field: key,
                    render: row => {
                        if (row && row[key]) {
                            return <Typography className={!validateVal(row, row[key]) ? classes.exception : null}>{row[key].value + ' ' + renderUnit(row)}</Typography>
                        } else {
                            return '-'
                        }
                    },
                    editable: 'never',
                    width: '90px',
                    headerStyle: { padding: 0 },
                    cellStyle: { padding: 0 }
                })
            })
        } else {
            basicColumns.push({
                align: 'left',
                title: '',
                render: row => <Typography>{'无历史记录'}</Typography>,
                editable: 'never',
                width: '90px',
                headerStyle: { padding: 0 },
                cellStyle: { padding: 0 }
            })
        }

        // 记录列
        basicColumns.push({
            title: '记录值',
            field: 'val',
            width: '250px',
            render: row => {
                if (!getEditable()) {
                    if (row.status === 'invalid') {
                        return <Typography className={classes.invalidRecord}>{'不涉及'}</Typography>
                    } else if (!row.valBackup) {
                        return <Typography className={classes.invalidRecord}>{'-'}</Typography>
                    } else {
                        return <Typography className={!validateVal(row) ? classes.exception : null}>{row.valBackup + ' ' + renderUnit(row)} </Typography>
                    }
                } else {
                    return <Grid container direction={'row-reverse'} wrap={'nowrap'} justify={'space-between'}>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            control={
                                <Switch size="small" color="primary" checked={row.status === 'invalid'} onChange={event => onhandleInvalid(event, row)} tabIndex={-1} />
                            }
                            label="不涉及"
                            labelPlacement="end"
                        />
                        {row.status !== 'invalid' &&
                            <TextField
                                variant="standard" size="small"
                                error={row && row.err !== ''}
                                helperText={row.err}
                                style={{ width: '120px' }}
                                value={row.val || ''}
                                placeholder={'待输入'}
                                type="number"
                                name='val'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{renderUnit(row)}</InputAdornment>,
                                }}
                                onChange={event => onhandleChange(event, row)}
                            />
                        }
                    </Grid>
                }
            }
        })

        return basicColumns;
    };

    const renderTime = (time) => {
        return time ? getDateByTimestamp(time, true) : ''
    }

    const renderUnit = (row) => {
        let label = '';
        if (unit && unit.length > 0) {
            let value = _.find(unit, function (o) { return o.code === row.itemUnit })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderStatus = (row) => {
        let text = ''
        const deadlineMoment = getMoment()(row.startTime).add(row.expiredAt, 'minutes');
        switch (row.status) {
            case 'new':
                text = '未开始'
                if (getMoment()().isAfter(deadlineMoment)) {
                    text = '已过期'
                }
                break;
            case 'active':
                text = '进行中'
                if (getMoment()().isAfter(deadlineMoment)) {
                    text = '已过期'
                }
                break;
            case 'pending':
                text = '已提交'
                break;
            case 'done':
                text = '已完成'
                break;
            case 'invalid':
                text = '已过期'
                break;
            case 'expired':
                text = '已补录'
                break;
            default:
                break;
        }

        return text
    }

    // 状态渲染
    const renderRowStatusDisplay = (row) => {
        let status = renderStatus(row)
        let cssClassName = classes.new

        switch (status) {
            case '未开始':
                cssClassName = classes.new
                break;
            case '进行中':
                cssClassName = classes.active
                break;
            case '已过期':
                cssClassName = classes.expired
                break;
            case '已完成':
                cssClassName = classes.done
                break;
            case '已补录':
                cssClassName = classes.expiredDone
                break;
            case '已提交':
                cssClassName = classes.pending
                break;
            default:
                cssClassName = classes.new
        }
        return cssClassName
    };

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                <div>
                    <Grid container justify={'space-between'}>
                        <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                            {'内操记录'}
                        </Typography>
                        < Chip className={renderRowStatusDisplay(currentPlan)} size='small' label={renderStatus(currentPlan)} />
                    </Grid>
                    <div>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    所属单位
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderCompany(currentPlan.companyId)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    巡检路线
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {currentPlan.routeName}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    执行班组
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderDepName(currentPlan.inspector)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={1}>
                                <Typography gutterBottom className={classes.label}>
                                    巡检人
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography gutterBottom className={classes.label}>
                                    {currentPlan.inspectorName}
                                </Typography>
                            </Grid>

                            <Grid item xs={1}>
                                <Typography color='textPrimary' className={classes.label}>
                                    开始时间
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color='textPrimary' className={classes.label}>
                                    {renderTime(currentPlan.startTime)}
                                </Typography>
                            </Grid>
                            {
                                Boolean(currentPlan.opentext) && !getEditable() &&
                                <>
                                    <Grid item xs={1}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            补录理由
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography color='textPrimary' className={classes.label}>
                                            {currentPlan.opentext || ''}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </div>
                    <Divider variant="middle" />
                </div>

                <div alignitems="center" className={classes.planitemroot}>
                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title=''
                            columns={assemblycolumns()}
                            data={inspectionItemList}
                            options={tableOptions}
                            localization={localization}
                            style={{
                                border: '0px solid black',
                                boxShadow: 'none'
                            }}
                        >
                        </MaterialTable>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                        <Grid item xs={1}>
                            <Button variant="outlined" color="primary"
                                onClick={onhandleBack}
                            >{'返回'}</Button>
                        </Grid>
                        {getEditable() &&
                            <Grid item>
                                {!isConfirming() && isExpired && isPlanEditable &&
                                    <TextField
                                        error={isExpired && isPlanEditable && !currentPlan.opentext}
                                        helperText={isExpired && isPlanEditable && !currentPlan.opentext ? '请输入补录理由' : ''}
                                        // className={classes.textField}
                                        style={{ marginRight: 10 }}
                                        label={'补录理由'}
                                        size={'small'}
                                        value={currentPlan.opentext || ''}
                                        variant="outlined"
                                        disabled={!(!isConfirming() && isExpired && isPlanEditable)}
                                        onChange={event => onhandleOpentextChange(event)}
                                    />
                                }
                                {
                                    Boolean(currentPlan.opentext) && !isPlanEditable &&
                                    <Grid item container>
                                        <Typography gutterBottom className={classes.label} style={{ fontWeight: 'bold' }}>
                                            补录理由:
                                        </Typography>
                                        <Typography gutterBottom className={classes.label}>
                                            {currentPlan.opentext || ''}
                                        </Typography>
                                    </Grid>
                                }
                                {!isConfirming() &&
                                    <Button variant="outlined" color="primary" style={{ marginRight: 10 }}
                                        onClick={event => onhandleSave(false)}
                                    >{'仅保存'}</Button>
                                }
                                <Button variant="contained" color="primary" style={{ marginRight: 50 }}
                                    onClick={event => onhandleSave(true)}
                                >{
                                        (isConfirming() ? '保存并完成复核' : '保存并结束巡检')
                                    }</Button>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Card >
        </div >
    );
};

IndoorRecord.propTypes = {
    classes: PropTypes.object.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    recordList: PropTypes.object.isRequired,
    currentPlan: PropTypes.object.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleExport: PropTypes.func.isRequired,
    onhandleDownload: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
    departmentList: PropTypes.array.isRequired,
    unit: PropTypes.array.isRequired,
};

export default withRouter(withStyles(IndoorRecord));