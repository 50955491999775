import { withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(0),
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    title: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formGridContainer: {

    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
    },
    materialtable: {
        boxShadow: 'none'
    },
    pic1: {
        width: 20,
        height: 20,
        backgroundColor: red[300],
        fontSize: '12px',
        margin: 0,
    },
    pic2: {
        width: 20,
        height: 20,
        backgroundColor: theme.palette.primary.main,
        fontSize: '12px',
        margin: 0,
    },
    interval: {
        textAlign: 'center'
    },
    /* 巡检计划的状态强调显示 */
    new: {
        width: 60,
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    active: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    expired: {
        width: 60,
        backgroundColor: colors.orange[400],
        color: theme.palette.white,
    },
    done: {
        width: 60,
        backgroundColor: colors.green[500],
        color: theme.palette.white,
    },
    expiredDone: {
        width: 60,
        backgroundColor: colors.green[300],
        color: theme.palette.white,
    },
    pending: {
        width: 60,
        backgroundColor: colors.lime[500],
        color: theme.palette.white,
    },
});

export default withStyles(styles);