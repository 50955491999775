import React, { useState } from 'react';
import withStyles from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { Add, Edit, DeleteOutline } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { options, localization } from "../../../../../utils/mtable";
import Button from "@material-ui/core/Button";
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

const DeviceRunParam = props => {

    const {
        classes,
        onhandleAdd,
        onhandleUpdate,
        onhandleDelete,
        userProfile,
        tabValue,
        index,
        unitList,
        //logicList,
        onhandleSave,
        // onhandleBack,
        deviceRunParamChange,
        valtypeList,
        tableRunParam,
        // isView,
        isDeviceEdit,
        runParamWarning,
        onhandleDialog
    } = props;
    const tableRef = React.createRef();
    // https://github.com/mbrn/material-table/issues/2133 外部按钮触发表格AddRow
    // const tableActionRef = React.createRef();

    const [dataError] = useState({
        vals: '', paramType: '', param: '', logic: ''
    })

    const getUnit = () => {
        let data = {}
        if (unitList) {
            for (let i = 0; i < unitList.length; i++) {
                let key = unitList[i].code
                data[key] = unitList[i].name
            }
        }
        return data
    }

    const renderUnit = (row) => {
        let label = '';
        if (unitList && unitList.length > 0) {
            let value = _.find(unitList, function (o) { return o.code === row.unit })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const getLogic = (rowData) => {
        let data = []
        console.log(rowData);
        if (rowData.paramType && (rowData.paramType === 'VAL_BOOL' || rowData.paramType === 'VAL_STRING')) {
            data = [
                { code: '=', name: '=' },
            ]
        } else {
            data = [
                { code: '<=', name: '<=' },
                { code: '<', name: '<' },
                { code: '=', name: '=' },
                { code: '>=', name: '>=' },
                { code: '>', name: '>' }
            ]
        }

        return data
    }

    const getLogic2 = () => {
        let data = []
        data = [
            { code: 0, name: '无' },
            { code: '<=', name: '<=' },
            { code: '<', name: '<' }
        ]

        return data
    }

    const renderValue = (props) => {
        if (props.rowData.paramType === 'VAL_BOOL') {
            return renderBool(props)
        } else {
            return renderVals(props)
        }
    }

    const renderOffValue = (props) => {
        if (props.rowData.paramType === 'VAL_BOOL') {
            return renderOffBool(props)
        } else {
            return renderOffVals(props)
        }
    }

    const renderValue2 = (props) => {
        return renderVals2(props)
    }

    const renderVals2 = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(props.helperText)}
            helperText={props.helperText || ''}
            required
            disabled={Boolean(!props.rowData.logic2)}
            value={props.rowData.vals2 ? props.rowData.vals2 : ''}
            name='vals2'
            onChange={e => props.onChange(e.target.value)}>
        </TextField >
    }

    const renderOffValue2 = (props) => {
        return renderOffVals2(props)
    }

    const renderOffVals2 = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(props.helperText)}
            helperText={props.helperText || ''}
            required
            disabled={Boolean(!props.rowData.offLogic2)}
            value={props.rowData.offVals2 ? props.rowData.offVals2 : ''}
            name='offVals2'
            onChange={e => props.onChange(e.target.value)}>
        </TextField >
    }

    const renderBool = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(dataError.vals1 && dataError.vals1 !== '')}
            helperText={dataError.vals1 || ''}
            select
            value={props.rowData.vals1 ? props.rowData.vals1 : ''}
            name='vals'
            onChange={e => props.onChange(e.target.value)}>
            <MenuItem key={'是'} value={'是'}>
                {'是'}
            </MenuItem>
            <MenuItem key={'否'} value={'否'}>
                {'否'}
            </MenuItem>
        </TextField>
    }

    const renderOffBool = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(dataError.offVals1 && dataError.offVals1 !== '')}
            helperText={dataError.offVals1 || ''}
            select
            value={props.rowData.offVals1 ? props.rowData.offVals1 : ''}
            name='offVals'
            onChange={e => props.onChange(e.target.value)}>
            <MenuItem key={'是'} value={'是'}>
                {'是'}
            </MenuItem>
            <MenuItem key={'否'} value={'否'}>
                {'否'}
            </MenuItem>
        </TextField>
    }

    const renderVals = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(props.helperText)}
            helperText={props.helperText || ''}
            required
            value={props.rowData.vals1 ? props.rowData.vals1 : ''}
            name='vals1'
            onChange={e => props.onChange(e.target.value)}>
        </TextField >
    }

    const renderOffVals = (props) => {
        return <TextField
            className={classes.textField}
            error={Boolean(props.helperText)}
            helperText={props.helperText || ''}
            required
            value={props.rowData.offVals1 ? props.rowData.offVals1 : ''}
            name='offVals1'
            onChange={e => props.onChange(e.target.value)}>
        </TextField >
    }

    const renderSource = (row) => {

        if (devEditable) {
            if (row.tableData && row.tableData.editing === 'update') {
                return row.sourceName ? <Typography>{row.sourceName}</Typography> : ''
            } else {
                return row ? <Button className={classes.cellButton} onClick={event => onhandleDialog(event, row)} color="primary" size={'small'}>{row.sourceName ? row.sourceName : '请选择设备'}</Button> : ''
            }
        } else {
            return row.sourceName ? <Typography>{row.sourceName}</Typography> : ''
        }
    }

    const validateValue = (rowData) => {
        if (rowData && rowData.tableData && rowData.tableData.editing && rowData.tableData.editing === "delete") {
            return true
        }

        if (_.trim(rowData.vals1) === '') {
            return { isValid: false, helperText: '不能为空' }
        }

        if (rowData.paramType === 'VAL_NUMBER') {
            if (_.isNaN(_.toNumber(rowData.vals1))) {
                return { isValid: false, helperText: '请输入数字' }
            }
        }

        if (rowData.paramType === 'VAL_INTEGER') {
            if (_.isNaN(_.toInteger(rowData.vals1))) {
                return { isValid: false, helperText: '请输入整数' }
            }
        }
        return true
    }

    const validateValue2 = (rowData) => {
        if (rowData && rowData.tableData && rowData.tableData.editing && rowData.tableData.editing === "delete") {
            return true
        } else {
            if (rowData.logic2) {
                if (_.trim(rowData.vals2) === '') {
                    return { isValid: false, helperText: '不能为空' }
                }

                if (rowData.paramType === 'VAL_NUMBER') {
                    if (_.isNaN(_.toNumber(rowData.vals2))) {
                        return { isValid: false, helperText: '请输入数字' }
                    }
                }

                if (rowData.paramType === 'VAL_INTEGER') {
                    if (_.isNaN(_.toInteger(rowData.vals2))) {
                        return { isValid: false, helperText: '请输入整数' }
                    }
                }

                if (rowData.paramType === 'VAL_NUMBER') {
                    if (!_.isNaN(_.toNumber(rowData.vals1))) {
                        if (_.toNumber(rowData.vals1) >= _.toNumber(rowData.vals2)) {
                            return { isValid: false, helperText: '参数值2不能小于等于参数值1' }
                        }
                    }
                }
                return true
            } else {
                return true
            }
        }
    }

    const validateOffValue = (rowData) => {
        if (rowData && rowData.tableData && rowData.tableData.editing && rowData.tableData.editing === "delete") {
            return true
        }

        if (rowData.offLogic1) {
            if (_.trim(rowData.offVals1) === '') {
                return { isValid: false, helperText: '不能为空' }
            }

            if (rowData.paramType === 'VAL_NUMBER') {
                if (_.isNaN(_.toNumber(rowData.offVals1))) {
                    return { isValid: false, helperText: '请输入数字' }
                }
            }

            if (rowData.paramType === 'VAL_INTEGER') {
                if (_.isNaN(_.toInteger(rowData.offVals1))) {
                    return { isValid: false, helperText: '请输入整数' }
                }
            }
        }
        return true
    }

    const validateOffValue2 = (rowData) => {
        if (rowData && rowData.tableData && rowData.tableData.editing && rowData.tableData.editing === "delete") {
            return true
        } else {
            if (rowData.offLogic2) {
                if (_.trim(rowData.offVals2) === '') {
                    return { isValid: false, helperText: '不能为空' }
                }

                if (rowData.paramType === 'VAL_NUMBER') {
                    if (_.isNaN(_.toNumber(rowData.offVals2))) {
                        return { isValid: false, helperText: '请输入数字' }
                    }
                }

                if (rowData.paramType === 'VAL_INTEGER') {
                    if (_.isNaN(_.toInteger(rowData.offVals2))) {
                        return { isValid: false, helperText: '请输入整数' }
                    }
                }

                if (rowData.paramType === 'VAL_NUMBER') {
                    if (!_.isNaN(_.toNumber(rowData.offVals1))) {
                        if (_.toNumber(rowData.offVals1) >= _.toNumber(rowData.offVals2)) {
                            return { isValid: false, helperText: '参数值2不能小于等于参数值1' }
                        }
                    }
                }
                return true
            } else {
                return true
            }
        }
    }

    const renderLogic = (logic) => {
        let label = '';
        let data = [
            { code: '<=', name: '<=' },
            { code: '<', name: '<' },
            { code: '=', name: '=' },
            { code: '>=', name: '>=' },
            { code: '>', name: '>' }
        ]
        let value = _.find(data, function (o) { return o.code === logic })
        if (value) {
            label = value.name
        }
        return label;
    }

    const renderParamType = (row) => {
        let label = '';
        if (valtypeList && valtypeList.length > 0) {
            let value = _.find(valtypeList, function (o) { return o.code === row.paramType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '参数名称',
                field: 'param',
                align: 'left',
                editable: 'onAdd',
                width: 120,
                validate: rowData => (!rowData.param || rowData.param === '') ? { isValid: false, helperText: '不能为空' } : true,
                render: row => row ? <Typography>{row.param}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.param ? props.rowData.param : ''}
                        name='param'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },

            {
                title: '参数类型',
                field: 'paramType',
                width: 100,
                align: 'left',
                editable: 'onAdd',
                validate: rowData => (!rowData.paramType || rowData.paramType === '') ? { isValid: false, helperText: '不能为空' } : true,
                render: row => row ? <Typography>{renderParamType(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.paramType ? props.rowData.paramType : ''}
                        name='paramType'
                        onChange={(e) => {
                            props.rowData.paramType = e.target.value;
                            props.rowData.logic1 = null;
                            props.rowData.vals1 = null;
                            props.rowData.logic2 = null;
                            props.rowData.vals2 = null;
                            props.rowData.offLogic1 = null;
                            props.rowData.offVals1 = null;
                            props.rowData.offLogic2 = null;
                            props.rowData.offVals2 = null;
                            tableRef.current.forceUpdate();
                        }
                        }>
                        {valtypeList.map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '正常运行条件1',
                field: 'logic1',
                width: 120,
                align: 'right',
                // editable: 'onAdd',
                validate: rowData => (!rowData.logic1 || rowData.logic1 === '') ? { isValid: false, helperText: '不能为空' } : true,
                render: row => row ? <Typography>{renderLogic(row.logic1)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.logic1 ? props.rowData.logic1 : ''}
                        name='logic1'
                        onChange={(e) => {
                            props.rowData.logic1 = e.target.value;
                            if (e.target.value !== '>' && e.target.value !== '>=') {
                                props.rowData.logic2 = null;
                                props.rowData.vals2 = null;
                            }
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }
                        }>
                        {getLogic(props.rowData).map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '标准值1',
                field: 'vals1',
                align: 'right',
                width: 100,
                render: row => row ? <Typography>{row.vals1}</Typography> : '',
                validate: validateValue,
                editComponent: renderValue
            },
            {
                title: '正常运行条件2',
                field: 'logic2',
                width: 120,
                align: 'right',
                // editable: 'onAdd',
                // validate: rowData => (!rowData.logic || rowData.logic === '') ? { isValid: false, helperText: '不能为空' } : true,
                render: row => row ? <Typography>{renderLogic(row.logic2)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        disabled={Boolean(!props.rowData.logic1 || !(props.rowData.logic1 === '>' || props.rowData.logic1 === '>='))}
                        select
                        value={props.rowData.logic2 ? props.rowData.logic2 : 0}
                        name='logic'
                        onChange={(e) => {
                            props.rowData.logic2 = e.target.value;
                            if (e.target.value === 0) {
                                props.rowData.logic2 = null;
                                props.rowData.vals2 = null;
                            }
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }}>
                        {getLogic2().map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '标准值2',
                field: 'vals2',
                align: 'right',
                width: 100,
                render: row => row ? <Typography>{row.vals2}</Typography> : '',
                validate: validateValue2,
                editComponent: renderValue2
            },
            {
                title: '正常停机条件1',
                field: 'offLogic1',
                width: 120,
                align: 'right',
                // editable: 'onAdd',
                validate: row => true,
                render: row => row ? <Typography>{renderLogic(row.offLogic1)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.offLogic1 ? props.rowData.offLogic1 : 0}
                        name='offLogic1'
                        onChange={(e) => {
                            props.rowData.offLogic1 = e.target.value;
                            if (e.target.value !== '>' && e.target.value !== '>=') {
                                props.rowData.offLogic2 = null;
                                props.rowData.offVals2 = null;
                            }
                            if (e.target.value === 0) {
                                props.rowData.offVals1 = null;
                            }
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }
                        }>
                        <MenuItem key={0} value={0}>{'无'}</MenuItem>
                        {getLogic(props.rowData).map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '停机标准值1',
                field: 'offVals1',
                align: 'right',
                width: 100,
                render: row => row ? <Typography>{row.offVals1}</Typography> : '',
                validate: validateOffValue,
                editComponent: renderOffValue
            },
            {
                title: '正常停机条件2',
                field: 'offLogic2',
                width: 120,
                align: 'right',
                // editable: 'onAdd',
                // validate: rowData => (!rowData.logic || rowData.logic === '') ? { isValid: false, helperText: '不能为空' } : true,
                render: row => row ? <Typography>{renderLogic(row.offLogic2)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        disabled={Boolean(!props.rowData.offLogic1 || !(props.rowData.offLogic1 === '>' || props.rowData.offLogic1 === '>='))}
                        select
                        value={props.rowData.offLogic2 ? props.rowData.offLogic2 : 0}
                        name='offLogic'
                        onChange={(e) => {
                            props.rowData.offLogic2 = e.target.value;
                            if (e.target.value === 0) {
                                props.rowData.offLogic2 = null;
                                props.rowData.offVals2 = null;
                            }
                            tableRef.current.forceUpdate();
                            // this.forceUpdate();
                        }}>
                        {getLogic2().map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '停机标准值2',
                field: 'offVals2',
                align: 'right',
                width: 100,
                render: row => row ? <Typography>{row.offVals2}</Typography> : '',
                validate: validateOffValue2,
                editComponent: renderOffValue2
            },
            {
                title: '单位',
                field: 'unit',
                width: 100,
                align: 'left',
                render: row => row ? <Typography>{renderUnit(row)}</Typography> : '',
                lookup: getUnit()
            },
            {
                title: '别名',
                field: 'alias',
                align: 'left',
                width: 120,
                render: row => row ? <Typography>{row.alias}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        className={classes.textField}
                        error={Boolean(props.helperText)}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.alias ? props.rowData.alias : ''}
                        name='alias'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '运行时读数来源',
                field: 'sourceName',
                width: 100,
                align: 'left',
                editable: 'never',
                render: renderSource,
                cellStyle: {
                    paddingTop: '2px',
                    paddingBottom: '2px',
                },
            },
        ]

        return columns
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }
    // console.log("============================================")
    // console.log("checkPermission(): " + checkPermission())
    // console.log("isDeviceEdit:  " + isDeviceEdit)
    // console.log("runParamWarning:  " + runParamWarning)
    // 不论查看还是编辑，都可以更改 
    // const devEditable = checkPermission() && !isView && !isDeviceEdit && !runParamWarning;
    const devEditable = checkPermission() && isDeviceEdit && !runParamWarning;

    return (
        <div alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={
                clsx(classes.detailtable, {
                    [classes.tableToolbarEditable]: devEditable,
                })
            }>
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblycolumns()}
                    data={tableRunParam}
                    options={{
                        ...options,
                        showTitle: false,
                        padding: 'dense',
                        addRowPosition: 'first',
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        toolbar: devEditable,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    icons={{
                        Add: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}
                        // onClick={onhandleAdd}
                        >
                            新建
                        </Button>,
                        Edit: () => <Edit color={'primary'} />,
                        Delete: () => <DeleteOutline color={'error'} />
                    }}
                    editable={devEditable ? {
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleAdd(newData)
                            }),
                        onRowUpdate: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleUpdate(newData)
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDelete(oldData)
                                resolve()
                            }),
                    } : {}}
                >
                </MaterialTable>
            </Grid>

            <div className={classes.card}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        hidden={!devEditable}
                        disabled={!deviceRunParamChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
            </div>
            <Prompt message="数据未保存,确定要离开？" when={deviceRunParamChange} />
        </div >

    );
};
DeviceRunParam.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleAdd: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    unitList: PropTypes.array.isRequired,
    //logicList: PropTypes.array.isRequired,
    deviceRunParamChange: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    valtypeList: PropTypes.array.isRequired,
    tableRunParam: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    runParamWarning: PropTypes.bool.isRequired,
    onhandleDialog: PropTypes.func.isRequired,
};

export default (withStyles(DeviceRunParam));