import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeviceSelectPage from '../components/DeviceSelectPage';
import {
  initDeviceSelectList,
  setSearch,
  resetSearch,
  getDeviceList,
  resetInstallation,
  exitSelected,
  setSelectedDevices,
} from '../redux/modules/deviceSelect';
import { getTreePartById } from '../utils/constant';
import { getCompanyId } from "../utils/userUtils";
import { cleanmodal } from '../redux/modules/modal';

class DeviceSelectPageContainer extends Component {
  static propTypes = {
    deviceList: PropTypes.object,
    search: PropTypes.object,
    catTree: PropTypes.array,
    catList: PropTypes.array,
    useStatusTree: PropTypes.array,
    procatTree: PropTypes.array,
    procatList: PropTypes.array,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    userProfile: PropTypes.object,
    companyTree: PropTypes.array,
    companyList: PropTypes.array,
    currentUserInfo: PropTypes.object,
    installationList: PropTypes.array,
  }

  componentWillMount() {
    this.props.onhandleInitDeviceSelectList();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        if (value && this.props.search.companyId !== value.target.value) {
          newSearch = {
            ...this.props.search,
            companyId: value.target.value,
            installId: ''
          };
          this.props.onhandleUpdateEditSearch(newSearch);
          this.props.onhandleResetInstallation(value.value);
        } else {
          newSearch = {
            ...this.props.search,
            companyId: null,
            installId: ''
          };
          this.props.onhandleUpdateEditSearch(newSearch);
          this.props.onhandleResetInstallation(null);
        }
        break;
      case 'cat':
        newSearch = {
          ...this.props.search,
          cat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'useStatus':
        newSearch = {
          ...this.props.search,
          useStatus: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'procat':
        newSearch = {
          ...this.props.search,
          procat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;

      case 'installId':
        newSearch = {
          ...this.props.search,
          installId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'search':
        newSearch = {
          ...this.props.search,
          search: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleConfirm = () => {
    if (this.props.parameters.callback) {
      this.props.parameters.callback(this.props.selectedDevices)
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          // console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };

  trimCompanyTree() {
    let companyId = null
    if (this.props.parameters.companyId) {
      companyId = this.props.parameters.companyId
    } else {
      companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    }
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <DeviceSelectPage
        search={this.props.search}
        catTree={this.props.catTree}
        catList={this.props.catList}
        useStatusTree={this.props.useStatusTree}
        procatTree={this.props.procatTree}
        procatList={this.props.procatList}
        parameters={this.props.parameters}
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleChange={this.handleChange.bind(this)}
        onhandleClearSearch={this.props.onhandleClearSearch.bind(this)}
        deviceList={this.props.deviceList}
        companyTree={this.trimCompanyTree()}
        companyList={this.props.companyList}
        onhandleSearch={this.props.onhandleSearch.bind(this)}
        onhandleSetSelectedDevices={this.props.onhandleSetSelectedDevices.bind(this)}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        installationList={this.props.installationList}
        onhandleConfirm={this.handleConfirm.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.deviceSelect.selectedDevices,
    deviceList: state.deviceSelect.deviceList,
    search: state.deviceSelect.search,
    catTree: state.trees.deviceCategoryTree,
    catList: state.trees.deviceCategoryList,
    useStatusTree: state.devices.useStatusTree,
    procatTree: state.devices.procatTree,
    procatList: state.devices.procat,
    parameters: state.modal.parameters,
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    installationList: state.installation.installationList ? state.installation.installationList.list : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitDeviceSelectList: () => {
      dispatch(initDeviceSelectList());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: () => {
      dispatch(getDeviceList());
    },
    onhandleResetInstallation: (value) => {
      dispatch(resetInstallation(value));
    },
    onhandleSetSelectedDevices: (value) => {
      dispatch(setSelectedDevices(value));
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSelectPageContainer);
