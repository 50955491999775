import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'

const porxyApi = '/api'

const initialState = {
    tasks: [],//生产任务列表
    taskReports: [],//任务状态
    openReport: false
};

export const setTasks = createAction('@@xcloud/manufacture/setTasks');
export const setTaskReports = createAction('@@xcloud/manufacture/setTaskReports');
export const openReport = createAction('@@xcloud/amoeba/task/report/open');
export const closeReport = createAction('@@xcloud/amoeba/task/report/close');


export const addManufactureTask = createAction('@@xcloud/manufacture/addTask',
    (task) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/tasks', task)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加生产任务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加生产任务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const addManufactureTasks = createAction('@@xcloud/manufacture/addTasks',
    (taskList, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/tasks', taskList)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, '批量生成任务成功'));
                }
                return res.status;
            }).catch(err => {
                let msg = '批量生成任务失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManufactureTask = createAction('@@xcloud/manufacture/updateTask',
    (updateDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/tasks/' + updateDto.taskId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    // dispatch(createhttpMessage(res, "更新生产任务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产任务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delManufactureTask = createAction('@@xcloud/manufacture/deleteTask',
    (taskId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/tasks/' + taskId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除生产任务成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除生产任务失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getManufactureTasks = createAction('@@xcloud/manufacture/getTasks',
    (companyId, startTime, endTime, dptId, orderId, serviceId, activityId, status, executor) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/tasks'
            let strs = []
            if (companyId || activityId || dptId || endTime || orderId || serviceId || startTime || status || executor) {
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                if (activityId) strs.push('activityId=' + activityId)
                if (endTime) strs.push('endTime=' + endTime)
                if (orderId) strs.push('orderId=' + orderId)
                if (serviceId) strs.push('serviceId=' + serviceId)
                if (startTime) strs.push('startTime=' + startTime)
                if (status) strs.push('status=' + status)
                if (executor) strs.push('executor=' + executor)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setTasks(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产任务列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getTasksReports = createAction('@@xcloud/manufacture/getTasksReports',
    (companyId, dptId, startTime, endTime) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/tasks/reports'
            let strs = []
            if (companyId || dptId || endTime || startTime) {
                if (companyId) strs.push('companyId=' + companyId)
                if (dptId) strs.push('dptId=' + dptId)
                if (startTime) strs.push('startTime=' + startTime)
                if (endTime) strs.push('endTime=' + endTime)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url, { headers: { need_loading: false } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setTaskReports(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产任务统计失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const manufactureTaskReducer = handleActions(
    {
        [setTasks]: (state, { payload: data }) => {
            return {
                ...state,
                tasks: data
            }
        },
        [setTaskReports]: (state, { payload: data }) => {
            return {
                ...state,
                taskReports: data
            }
        },
        [openReport]: (state, { payload: data }) => {
            return {
                ...state,
                openReport: true,
            }
        },

        [closeReport]: (state, { payload: data }) => {
            return {
                ...state,
                openReport: false,
            }
        },
    },
    initialState
)

export default manufactureTaskReducer
