import { withStyles } from '@material-ui/styles';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    card: {
        width: '100%',
    },
    stepper: {
        alignItems: 'center',
        padding: 16,
        background: 'none',
        lineHeight: '36px'
    },
    subTitle: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        borderLeft: '2px solid ' + theme.palette.primary.main,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    dialogTitle: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    gridContainer: {
        padding: theme.spacing(1),
        alignItems: 'center',
    },
    materialtable: {
        margin: theme.spacing(0, 2, 2, 2),
    },
    line: {
        display: 'inline-block',
        width: '5%',
        height: 0,
        borderTop: '2px solid',
        borderTopColor: 'rgba(0, 0, 0, 0.3)',
        marginLeft: 1,
        marginRight: 1,
    },
    step: {
        display: 'grid'
    },
    chip: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    pendingUser: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    rejectedUser: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    approvedUser: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        margin: theme.spacing(0.5),
    },
    infoIcon: {
        fontSize: theme.spacing(10),
    }

});

export default withStyles(styles);