import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MActivityListComponent from '@/components/ManufactureManagement/ManufactureActivity/MActivityList';
import MActivityActionComponent from '@/components/ManufactureManagement/ManufactureActivity/MActivityAction';
import {
    getActivities, addManufactureActivity, updateManufactureActivity,
    delManufactureActivity, setActivities
} from '@/redux/modules/manufacture/manufactureActivity';
import { getAttrs } from '@/redux/modules/manufacture/manufactureAttr';
import { createActionMessage } from '@/redux/modules/message'
// import { getManufactureDictionaries } from '@/redux/modules/constant';

class MActivityListContainer extends Component {
    static propTypes = {
        activities: PropTypes.array,
        attrList: PropTypes.array,
        manufactureDicts: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            activity: {},
            openFlag: false,
            openType: 0,
            attrData: {
                attrId: 0,
                attrName: '',
                attrCode: '',
                attrType: '',
                opentext: '',
            }
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        this.props.getActivities();
        this.props.getAttrs();
        // this.props.getManufactureDictionaries();
    }

    handleAdd = (rowData) => {
        rowData.activityType = 'CUSTOMIZE'
        return this.props.addManufactureActivity(rowData).then(
            () => { this.props.getActivities(); }
        );
    }

    handleUpdate = (rowData, activity) => {
        let checkFlag = true;
        // 更新Dto
        let updateDto = { activityId: 0, activityName: '', activityType: '', opentext: '', attrIds: [] };
        //活动属性增删改的场合
        if (activity !== undefined) {
            updateDto.activityId = activity.activityId;
            updateDto.activityName = activity.activityName ? activity.activityName : '';
            updateDto.activityType = activity.activityType ? activity.activityType : '';
            updateDto.opentext = activity.opentext ? activity.opentext : '';
            if (activity.manufactureAttrs.length > 0) {
                activity.manufactureAttrs.forEach((element) => {
                    //事件为删除时,List不放入对应的attrId
                    if (!this.state.openFlag && rowData.attrId === element.attrId) {

                    } else if (rowData.attrId === element.attrId && this.state.openFlag) {
                        //更新的属性在活动中存在
                        let message = (this.state.openType === 1 ? '增加' : '更新')
                            + '属性失败，属性名:[' + rowData.attrName + ']在活动属性列表中已存在';
                        this.props.createActionMessage('warning', message);
                        checkFlag = false;
                        return;
                    } else {
                        updateDto.attrIds.push(element.attrId);
                    }
                });
            }
            if (this.state.openFlag) {
                updateDto.attrIds.push(rowData.attrId);
            }
        } else {
            updateDto.activityId = rowData.activityId;
            updateDto.activityName = rowData.activityName ? rowData.activityName : '';
            updateDto.activityType = rowData.activityType ? rowData.activityType : '';
            updateDto.opentext = rowData.opentext ? rowData.opentext : '';

            if (rowData.manufactureAttrs.length > 0)
                rowData.manufactureAttrs.forEach(element => updateDto.attrIds.push(element.attrId));
        }

        if (checkFlag)
            return this.props.updateManufactureActivity(updateDto).then(
                () => { this.props.getActivities(); }
            );
    }

    handleDelete = (rowData) => {
        return this.props.delManufactureActivity(rowData.activityId).then(
            () => { this.props.getActivities(); }
        );
    }

    handleAttrAdd = (rowData, activity) => {
        this.setState({
            activity: activity,
            attrData: rowData,
            openFlag: true,
            openType: 1
        });
    }

    handleAttrUpdate = (rowData, activity) => {
        this.setState({
            activity: activity,
            attrData: rowData,
            openFlag: true,
            openType: 2
        });
    }

    handleCloseCreate = () => {
        this.setState({
            openFlag: false,
            attrData: {}
        });
    }

    handleSave = () => {
        if (this.handleUpdate(this.state.attrData, this.state.activity)) {
            this.setState({
                openFlag: false,
                attrData: {}
            })
        };
    }

    handleChange = (event) => {
        let attrData = { ...this.state.attrData };
        attrData[event.target.name] = event.target.value;
        let attrList = this.props.attrList;
        attrList.forEach(element => {
            if (element.attrId === event.target.value) {
                attrData.attrId = element.attrId;
                attrData.attrName = element.attrName;
                attrData.attrCode = element.attrCode;
                attrData.attrType = element.attrType;
                attrData.opentext = element.opentext;
                return;
            }
        });
        this.setState({ attrData: attrData });
    }

    render() {
        return (
            <div>
                <MActivityListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleAttrAdd={this.handleAttrAdd.bind(this)}
                    onhandleAttrUpdate={this.handleAttrUpdate.bind(this)}
                    activities={this.props.activities}
                    manufactureDicts={this.props.manufactureDicts}
                />
                <MActivityActionComponent
                    openFlag={this.state.openFlag}
                    openType={this.state.openType}
                    attrData={this.state.attrData}
                    attrList={this.props.attrList}
                    manufactureDicts={this.props.manufactureDicts}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleClose={this.handleCloseCreate.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activities: state.manufactureActivity.activities,
        attrList: state.manufactureAttr.attrs,
        manufactureDicts: state.constant.manufactureDicts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActivities: () => {
            dispatch(getActivities());
        },
        getAttrs: () => {
            dispatch(getAttrs());
        },
        setActivities: (data) => {
            dispatch(setActivities(data));
        },
        // getManufactureDictionaries: () => {
        //     dispatch(getManufactureDictionaries());
        // },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        addManufactureActivity: (data) => {
            return dispatch(addManufactureActivity(data));
        },
        delManufactureActivity: (data) => {
            return dispatch(delManufactureActivity(data));
        },
        updateManufactureActivity: (data) => {
            return dispatch(updateManufactureActivity(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MActivityListContainer);
