import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
  Typography, MenuItem
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import * as _ from 'lodash';
import { Add, ExpandLess, ExpandMore } from "@material-ui/icons";

const UserHeader = props => {
  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    search,
    companyList,
    groupList,
    roleList,
    departmentList,
    // buList,
    onhandleCreate,
    userProfile,
    onhandleSelectChange,
    onhandleChange
  } = props;

  // const buOptions = () => (
  //   <React.Fragment>
  //     {buList ?
  //       buList.map(data => (
  //         <option value={data.code} key={data.code}>{data.name}</option>
  //       )) : null
  //     }
  //   </React.Fragment>
  // )
  const [expand, setExpand] = useState(false);

  const checkPermission = () => {
    let permission = _.find(userProfile.roles[0].routes, { 'route': 'user_management' })
    return permission && permission.action === 'W'
  }

  return (
    <div>
      <Grid container alignItems="center" className={classes.title}>
        <Typography variant="h3">
          {'用户列表'}
        </Typography>
      </Grid>
      {/* 整个搜索功能区域 */}
      <Grid container spacing={0} className={classes.gridContainer}>
        {/* 搜索项 */}
        <Grid container item alignItems="center" sm={12} md={12} lg={6}>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              单&emsp;&emsp;位
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              select
              name='companyId'
              onChange={event => onhandleSelectChange(event, 'company')}
              value={search.companyId}
              variant="outlined"
            >
              {[{ companyId: 0, companyAlias: '全部' }, ...companyList].map(ele => (
                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              用户组
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              select
              name='groupId'
              variant="outlined"
              onChange={(event) => onhandleSelectChange(event, 'roleGroup')}
              value={search.groupId || ''}
            >
              {groupList ?
                groupList.map(data => (
                  <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
              }
            </TextField>
          </Grid>
          <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
            <Typography className={classes.label}>
              角色
            </Typography>
            <TextField
              className={classes.textFieldInput}
              size={'small'}
              select
              variant="outlined"
              name='roleId'
              onChange={(event) => onhandleSelectChange(event, 'role')}
              value={search.roleId || ''}
            >
              {roleList ?
                roleList.map(data => (
                  <option value={data.code} key={data.code}>{data.name}</option>
                )) : null
              }
            </TextField>

          </Grid>
          {Boolean(expand) &&
            <React.Fragment>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  部门
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  variant="outlined"
                  select
                  name='roleId'
                  onChange={(event) => onhandleSelectChange(event, 'department')}
                  value={search.dptId || ''}
                >
                  {departmentList ?
                    departmentList.map(data => (
                      <option value={data.code} key={data.code}>{data.name}</option>
                    )) : null
                  }
                </TextField>

              </Grid>

              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  用户名
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  value={search.userName || ''}
                  variant="outlined"
                  onChange={event => onhandleChange(event, 'userName')}
                />
              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  姓名
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  value={search.name || ''}
                  variant="outlined"
                  onChange={event => onhandleChange(event, 'name')}
                />
              </Grid>
              {/* <Grid item xs={1}>
            <Typography className={classes.label}>
              业务分类
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.unitId}
              onChange={(event) => onhandleSelectChange(event, 'bu')}
            >
              <option aria-label="None" value="" />
              {buOptions()}
            </Select>
          </Grid> 
          <Grid item xs={1}>
            <Typography className={classes.label}>
              状态
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: '90%' }}
              native
              value={search.status}
              onChange={(event) => onhandleSelectChange(event, 'status')}
            >
              <option aria-label="None" value="" />
              <option value="new" key="new" >未激活</option>
              <option value="active" key="active" >正常</option>
              <option value="inactive" key="inactive" >无效</option>
            </Select>
          </Grid>*/}


              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  状态
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  select
                  variant="outlined"
                  name='roleId'
                  onChange={(event) => onhandleSelectChange(event, 'status')}
                  value={search.status}
                >
                  <option value="all" key="all">全部</option>
                  <option value="active" key="active" >正常</option>
                  <option value="new" key="new" >未激活</option>
                  <option value="inactive" key="inactive" >无效</option>
                </TextField>

              </Grid>
              <Grid container item className={classes.gridItem} sm={6} md={4} lg={4}>
                <Typography className={classes.label}>
                  邮箱
                </Typography>
                <TextField
                  className={classes.textFieldInput}
                  size={'small'}
                  variant="outlined"
                  value={search.email || ''}
                  onChange={event => onhandleChange(event, 'email')}
                />
              </Grid>

            </React.Fragment>}

        </Grid>

        <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={6}>

          {/* 搜索区 */}
          <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            <Button variant="contained" color="primary"
              onClick={onhandleSearch}
            >
              搜索
            </Button>
            <Button variant="outlined" style={{ marginLeft: '10px' }}
              onClick={onhandleClearSearch}

            >
              重置
            </Button>

            <Button variant="text" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => setExpand(!expand)}
              endIcon={expand ? <ExpandLess /> : <ExpandMore />}
            >
              {expand ? '收起' : '展开'}
            </Button>
          </Grid>
          {/* 表格工具条区 */}
          <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={expand ? 12 : 6}>
            {checkPermission() && <Button variant='contained' color='primary' startIcon={<Add />}
              onClick={onhandleCreate}
            >
              新建
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

UserHeader.propTypes = {
  onhandleSearch: PropTypes.func.isRequired,
  onhandleCreate: PropTypes.func.isRequired,
  onhandleClearSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  groupList: PropTypes.array.isRequired,
  roleList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
  buList: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired
};

export default withStyles(UserHeader);
