import React, { useState } from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    Divider,
    Chip,
    Link
} from '@material-ui/core';
import {
    AttachFile,
    CloudUpload
} from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { truncate } from '@/utils/string';
import { bytesToImageSrc } from '@/utils/imageUtils';
import _ from 'lodash';
import {
    getMoment as moment,
    // getDateByTimestamp
} from '@/utils/datetime';


const TreatmentSchemePannel = ({
    classes,
    open,
    editable,
    users,
    companyLeaders,
    schemeCatList,
    currentHazardBackup,
    treatmentSchemeError,
    treatmentScheme,
    isTreatmentHead,
    // needPretreatment,
    companyList,
    departmentList,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    // onhandleAutoCompleteChange,
    onhandleUserPickerClick,
    onhandleDateChange,
    onhandleUpload,
    onhandleDeleteFile,
    onhandlePreview
    // onhandleListMapping,
}) => {

    const [headSign, setHeadSign] = useState(null);
    const [leaderSign, setLeaderSign] = useState(null);
    const [accepterSign, setAccepterSign] = useState(null);

    bytesToImageSrc(treatmentScheme.headSign, null, setHeadSign);
    bytesToImageSrc(treatmentScheme.leaderSign, null, setLeaderSign);
    bytesToImageSrc(treatmentScheme.accepterSign, null, setAccepterSign);
    // console.log('isTreatmentHead, editable')
    // console.log(isTreatmentHead, editable)
    // console.log('treatmentScheme')
    // console.log(treatmentScheme)
    // console.log('treatmentSchemeError')
    // console.log(treatmentSchemeError)


    const renderModalTitle = () => {
        if (editable) {
            if (isTreatmentHead) {
                return treatmentScheme.schemeId ? '编辑治理方案' : '新增治理方案';
            } else {
                return '治理方案指派';
            }
        }
        return '治理方案';
    }

    //根据用户id 获得用户名
    const handleGetUserName = (userId) => {
        let user = {};
        let username = '';
        user = _.find(users, { 'userId': userId });
        if (userId) {
            username = user.lastName + user.firstName;
        }
        else {
            username = '';

        }
        return username;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick maxWidth={'lg'}>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">
                        {renderModalTitle()}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container >
                        <Grid item className={classes.col}>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='整改单位'
                                        select
                                        value={currentHazardBackup.companyId || ''}
                                        InputProps={{ readOnly: true }}
                                        name='companyId'
                                    >
                                        {companyList.map((comp) => (
                                            <MenuItem dense key={comp.companyId} value={comp.companyId}>
                                                {comp.companyAlias}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {(isTreatmentHead || !editable) &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField className={classes.textField}
                                            select
                                            variant="outlined"
                                            label="整改部门"
                                            error={treatmentSchemeError.dptId !== ''}
                                            helperText={treatmentSchemeError.dptId}
                                            value={treatmentScheme.dptId || ''}
                                            // disabled={!editable}
                                            InputProps={{ readOnly: !editable }}
                                            name='dptId'
                                            onChange={onhandleChange}
                                        >
                                            {departmentList
                                                .filter(dpt => (dpt.companyId === currentHazardBackup.companyId))
                                                .map((dpt) => (
                                                    <MenuItem dense key={dpt.dptId} value={dpt.dptId}>
                                                        {dpt.dptName}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label='整改方式'
                                        error={treatmentSchemeError.schemeCat !== ''}
                                        helperText={treatmentSchemeError.schemeCat}
                                        select
                                        value={treatmentScheme.schemeCat}
                                        // disabled={!editable}
                                        InputProps={{ readOnly: !editable }}
                                        name='schemeCat'
                                        onChange={onhandleChange}>
                                        {schemeCatList.map((cat) => (
                                            <MenuItem dense key={cat.code} value={cat.code} disabled={cat.code === 'TRE01'}>
                                                {cat.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {(isTreatmentHead || !editable) &&
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            label={"整改预算（元）"}
                                            variant='outlined'
                                            className={classes.textField}
                                            error={treatmentSchemeError.cost !== ''}
                                            helperText={treatmentSchemeError.cost}
                                            value={treatmentScheme.cost || 0}
                                            // disabled={!editable}
                                            InputProps={{ readOnly: !editable }}
                                            name='cost'
                                            onChange={event => onhandleChange(event, 'cost')}
                                            onBlur={event => onhandleCheck(event, 'cost')}
                                        />
                                    </Grid>
                                }
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        className={classes.textField}
                                        variant={'outlined'}
                                        value={treatmentScheme.headName || ''}
                                        error={treatmentSchemeError.head !== ''}
                                        helperText={treatmentSchemeError.head}
                                        disabled={!editable}
                                        name='head'
                                        label='整改负责人'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onClick={event => editable ? onhandleUserPickerClick('head') : undefined}
                                    />
                                    {/* <Autocomplete
                                        className={classes.textField}
                                        options={companyLeaders}
                                        onChange={(e, newValue) => onhandleAutoCompleteChange(e, newValue, 'head')}
                                        onClose={event => onhandleCheck(event, 'head')}
                                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                        getOptionSelected={(option, value) => {
                                            return option.userId === value.userId
                                        }}
                                        noOptionsText='无可选人员'
                                        value={_.find(users, { 'userId': treatmentScheme.head }) || null}
                                        disabled={!editable}
                                        renderInput={(params) => <TextField {...params}
                                            label='整改负责人'
                                            placeholder="搜索并选择整改负责人"
                                            variant="outlined"
                                            error={treatmentSchemeError.head !== ''}
                                            helperText={treatmentSchemeError.head}
                                        />}
                                        renderOption={option => (
                                            <Grid container justify={'space-between'}>
                                                <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                                <Typography variant={'subtitle2'}>{option.roleName + ' - ' + option.companyName || ''}</Typography>
                                            </Grid>
                                        )}
                                    /> */}
                                </Grid>
                                {/* {(isTreatmentHead || !editable) && */}
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        variant={'outlined'}
                                        className={classes.textField}
                                        value={treatmentScheme.accepterName || ''}
                                        error={treatmentSchemeError.accepter !== ''}
                                        helperText={treatmentSchemeError.accepter}
                                        disabled={!editable}
                                        name='accepter'
                                        label='验收负责人'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onClick={event => editable ? onhandleUserPickerClick('accepter') : undefined}
                                    />
                                    {/* <Autocomplete
                                        className={classes.textField}
                                        options={users}
                                        onChange={(e, newValue) => onhandleAutoCompleteChange(e, newValue, 'accepter')}
                                        onClose={event => onhandleCheck(event, 'accepter')}
                                        getOptionLabel={(option) => { return option.lastName + option.firstName || '' }}
                                        getOptionSelected={(option, value) => {
                                            return option.userId === value.userId
                                        }}
                                        noOptionsText='无可选人员'
                                        value={_.find(users, { 'userId': treatmentScheme.accepter }) || null}
                                        disabled={!editable}
                                        renderInput={(params) => <TextField {...params}
                                            label='验收负责人'
                                            placeholder="搜索并选择验收负责人"
                                            variant="outlined"
                                            error={treatmentSchemeError.accepter !== ''}
                                            helperText={treatmentSchemeError.accepter}
                                        />}
                                        renderOption={option => (
                                            <Grid container justify={'space-between'}>
                                                <Typography>{option.lastName + option.firstName || `ID用户${option.userId}`}</Typography>
                                                <Typography variant={'subtitle2'}>{option.roleName + ' - ' + option.companyName || ''}</Typography>
                                            </Grid>
                                        )}
                                    /> */}
                                </Grid>
                                {/* } */}
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={zhCN}>
                                        <KeyboardDatePicker
                                            className={classes.textField}
                                            error={treatmentSchemeError.targetDate !== ''}
                                            helperText={treatmentSchemeError.targetDate}
                                            InputProps={{ readOnly: true }}
                                            disableToolbar
                                            disablePast
                                            disabled={!editable} // || treatmentScheme.schemeCat === 'TRE03'
                                            autoOk
                                            variant='inline'
                                            inputVariant='outlined'
                                            minDateMessage={<Typography>不能早于当前日期</Typography>}
                                            format='yyyy/MM/dd'
                                            name='targetDate'
                                            label={"整改期限"}
                                            value={treatmentScheme.targetDate || null}
                                            onChange={date => onhandleDateChange(date, 'targetDate')}
                                            KeyboardButtonProps={{
                                                'aria-label': '修改时间',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {!isTreatmentHead && editable &&
                                    <Grid item >
                                        <Typography>
                                            <span className={classes.required}>* </span>
                                            {"请指定一名负责人，进行应急管控措施制定等后续治理流程。"}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {(isTreatmentHead || !editable) && <>
                            <Grid item><Divider orientation="vertical" /></Grid>
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            multiline
                                            label={"整改措施"}
                                            variant='outlined'
                                            error={treatmentSchemeError.measure !== ''}
                                            helperText={treatmentSchemeError.measure}
                                            className={classes.textField}
                                            value={treatmentScheme.measure || ''}
                                            // disabled={!editable}
                                            InputProps={{ readOnly: !editable }}
                                            rows={7}
                                            name='measure'
                                            onChange={event => onhandleChange(event, 'measure')}
                                            onBlur={event => onhandleCheck(event, 'measure')}
                                        />
                                    </Grid>
                                    {/* <Grid item className={classes.itemGrid}>
                                    <TextField
                                        multiline
                                        label={"处罚"}
                                        variant='outlined'
                                        className={classes.textField}
                                        value={treatmentScheme.sanction || ''}
                                        rows={7}
                                        name='sanction'
                                        onChange={event => onhandleChange(event, 'sanction')}
                                    />
                                </Grid> */}
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            multiline
                                            label={"应急管控措施"}
                                            variant='outlined'
                                            error={treatmentSchemeError.pretreatment !== ''}
                                            helperText={treatmentSchemeError.pretreatment}
                                            className={classes.textField}
                                            value={treatmentScheme.pretreatment || ''}
                                            // disabled={!editable}
                                            InputProps={{ readOnly: !editable }}
                                            rows={7}
                                            name='pretreatment'
                                            onChange={event => onhandleChange(event, 'pretreatment')}
                                        // onBlur={event => onhandleCheck(event, 'pretreatment')}
                                        />
                                    </Grid>
                                    {
                                        treatmentScheme.pretreatmentDocs && treatmentScheme.pretreatmentDocs[0] &&
                                        <Grid item className={classes.itemGrid}>
                                            <Chip
                                                icon={<AttachFile />}
                                                onClick={onhandlePreview}
                                                onDelete={editable ? onhandleDeleteFile : null}
                                                variant="outlined"
                                                title={'点击预览：' + treatmentScheme.pretreatmentDocs[0].docName}
                                                label={truncate(treatmentScheme.pretreatmentDocs[0].docName, 20)}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (!treatmentScheme.pretreatmentDocs || !treatmentScheme.pretreatmentDocs[0]) && editable &&
                                        <Grid item className={classes.itemGrid}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.button}
                                                onClick={onhandleUpload}
                                                startIcon={<CloudUpload />}
                                            >
                                                {(treatmentScheme.pretreatmentDocs && treatmentScheme.pretreatmentDocs[0]) ? '变更应急预案文档' : '上传应急预案文档'}
                                            </Button>
                                        </Grid>
                                    }
                                    {
                                        treatmentScheme.file && treatmentScheme.file.length && treatmentScheme.file.length > 0 &&
                                        <Grid item className={classes.itemGrid}>
                                            <Typography>已选择文件（保存时上传）：</Typography>
                                            <Link underline='always' component="button">{treatmentScheme.file[0].name}</Link>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </>}
                        {(isTreatmentHead || !editable) && <>
                            <Grid item><Divider orientation="vertical" /></Grid>
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item className={classes.itemGrid}>
                                        <TextField
                                            multiline
                                            label={"备注"}
                                            variant='outlined'
                                            className={classes.textField}
                                            value={treatmentScheme.opentext || ''}
                                            // disabled={!editable}
                                            InputProps={{ readOnly: !editable }}
                                            rows={10}
                                            name='opentext'
                                            onChange={event => onhandleChange(event, 'opentext')}
                                        // onBlur={event => onhandleCheck(event, 'opentext')}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography gutterBottom >
                                            创建人:  {handleGetUserName(treatmentScheme.creator)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom >
                                            创建时间:  {moment()(treatmentScheme.createTime).format('YYYY-MM-DD')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography gutterBottom >
                                            更新人:  {handleGetUserName(treatmentScheme.revisor)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom >
                                            更新时间:  {moment()(treatmentScheme.updateTime).format('YYYY-MM-DD')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>}
                        {(isTreatmentHead || !editable) && !['new', 'rejected'].includes(treatmentScheme.status) && <>
                            <Grid item><Divider orientation="vertical" /></Grid>
                            <Grid item className={classes.col}>
                                <Grid container className={classes.contentGrid}>
                                    <Grid item className={classes.itemGrid}>
                                        <Typography gutterBottom >
                                            负责人签名:
                                        </Typography>
                                        {Boolean(treatmentScheme.headSign) ?
                                            <img
                                                className={classes.signImg}
                                                src={headSign}
                                                alt={treatmentScheme.headName}
                                                loading="lazy"
                                            /> : '无'
                                        }
                                    </Grid>
                                    <Grid item className={classes.itemGrid}>
                                        <Typography gutterBottom >
                                            领导签名:
                                        </Typography>
                                        {Boolean(treatmentScheme.headSign) ?
                                            <img
                                                className={classes.signImg}
                                                src={leaderSign}
                                                alt={'领导签名'}
                                                loading="lazy"
                                            /> : '无'
                                        }
                                    </Grid>
                                    <Grid item className={classes.itemGrid}>
                                        <Typography gutterBottom >
                                            验收人签名:
                                        </Typography>
                                        {Boolean(treatmentScheme.headSign) ?
                                            <img
                                                className={classes.signImg}
                                                src={accepterSign}
                                                alt={treatmentScheme.accepterName}
                                                loading="lazy"
                                            /> : '无'
                                        }
                                    </Grid>
                                    {/* <Grid item className={classes.itemGrid}>
                                        <Typography gutterBottom >
                                            专业评审签名:
                                        </Typography>
                                        {Boolean(treatmentScheme.supervisorSign) ?
                                            <img
                                                className={classes.signImg}
                                                src={bytesToImageSrc(treatmentScheme.supervisorSign)}
                                                alt={'领导签名'}
                                                loading="lazy"
                                            /> : '无'
                                        }
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {editable &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onhandleConfirm}
                        >
                            {treatmentScheme.schemeId ? '保存' : '新增'}</Button>
                    }
                </DialogActions>
            </Dialog>
        </div >

    );
};
TreatmentSchemePannel.propTypes = {
    classes: PropTypes.object.isRequired,
    treatmentScheme: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    schemeCatList: PropTypes.array.isRequired,
    onhandleUpload: PropTypes.func.isRequired,
    onhandleDeleteFile: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

TreatmentSchemePannel.defaultProps = {

}

export default withStyles(TreatmentSchemePannel);