import { withStyles } from '@material-ui/styles';
import { stripedTableStyle, tableStyle, default as antdCustomStyle } from '@/utils/antdCustomStyle';
import { colors } from '@material-ui/core';

const styles = theme => ({
  stripedTable: stripedTableStyle,
  normalTable: tableStyle,
  oddRow: antdCustomStyle.oddRow,
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  detailtable: {
    padding: theme.spacing(4)
  },
  materialtable: {
    boxShadow: 'none'
  },
  pic1: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.blue[500],
    fontSize: '12px',
  },
  pic2: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.teal[400],
    fontSize: '12px',
  },
  pic3: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.lightBlue[800],
    fontSize: '4px',
  },
  pic4: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.cyan[800],
    fontSize: '4px',
  },
  pic5: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: colors.teal[800],
    fontSize: '4px',
  },
  new: {
    width: 60,
    backgroundColor: colors.grey[400],
    color: theme.palette.white,
  },
  finalized: {
    width: 60,
    backgroundColor: colors.yellow[400],
    color: theme.palette.black,
  },
  done: {
    width: 60,
    backgroundColor: colors.blue[400],
    color: theme.palette.white,
  },
  default: {
    width: 60,
    backgroundColor: colors.grey[300],
    color: theme.palette.white,
  },
  card: {
    width: '100%',
    padding: 5,
    margin: 5
  },
  actions: {
    padding: theme.spacing(1, 3)
  }
});

export default withStyles(styles);
