import React from 'react';
import PropTypes from 'prop-types';
import {
    Divider,
    Grid,
    Button,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    Card,
    CardContent,
    MenuItem,
    InputAdornment,
    IconButton,
    Tooltip,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import withStyles from './styles';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import StoreIcon from '@material-ui/icons/Store';

const AssignmentPannelComponent = ({
    classes,
    open,
    onhandleClose,
    onhandleUpdate,
    companyList,
    departmentList,
    currentWOE,
    onhandleChangeItem,
    onhandleSelectItem
}) => {

    const getSupplierType = () => {
        if (currentWOE.supplierType) {
            return currentWOE.supplierType
        } else {
            return currentWOE && currentWOE.supplierId ? 'external' : 'internal'
        }
    }

    const checkSave = () => {
        let rtn = true
        if (currentWOE.supplierId) {
            rtn = false;
        } else if (currentWOE.internalCompany && currentWOE.internalCompany !== 0
            && currentWOE.internalDpt && currentWOE.internalDpt !== 0) {
            rtn = false;
        }
        return rtn
    }

    return (
        <div alignitems="center" >
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="title">
                    <Typography className={classes.title} component={'span'} variant='h3'>承包商指派</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="row" className={classes.radioGroup} spacing={1}>
                        <RadioGroup row aria-label="supplier type" name="supplierType" value={getSupplierType()} onChange={(event) => onhandleChangeItem(event, 'supplierType')}>
                            <FormControlLabel value="internal" control={<Radio />} label="内部承包商" />
                            <FormControlLabel value="external" control={<Radio />} label="外部承包商" />
                        </RadioGroup>
                    </Grid>
                    {getSupplierType() === 'internal' && <Card className={classes.root} spacing={1}>
                        <CardContent>
                            <Typography className={classes.pos} color="textSecondary">
                                {''}
                            </Typography>
                            <Grid container direction="column" alignItems="center">
                                <Grid item className={classes.item}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请选择"
                                        label="单位"
                                        name='company'
                                        value={currentWOE.internalCompany ? currentWOE.internalCompany : 0}
                                        disabled={Boolean(currentWOE.supplierId)}
                                        onChange={(event) => onhandleChangeItem(event, 'companyId')}
                                        select
                                    >
                                        <MenuItem aria-label="None" value={0}></MenuItem>
                                        {companyList ?
                                            _.sortBy(companyList, 'companyId').map(data => (
                                                <MenuItem value={data.companyId} key={data.companyId}>{data.companyAlias}</MenuItem>
                                            )) : null
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.item}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请选择"
                                        label="部门"
                                        name='dpt'
                                        disabled={Boolean(currentWOE.internalCompany === 0 || currentWOE.supplierId !== 0)}
                                        value={currentWOE.internalDpt ? currentWOE.internalDpt : 0}
                                        onChange={(event) => onhandleChangeItem(event, 'dpt')}
                                        select
                                    >
                                        <MenuItem aria-label="None" value={0}></MenuItem>
                                        {departmentList ?
                                            _.sortBy(_.filter(departmentList, { companyId: currentWOE.internalCompany }), 'dptId').map(data => (
                                                <MenuItem value={data.dptId} key={data.dptId}>{data.dptName}</MenuItem>
                                            )) : null
                                        }
                                    </TextField>
                                </Grid>
                                {/* <Grid item className={classes.item}>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <span className={classes.required}>*</span>
                                        {` 指派已注册外部承包商，请选择【${pcompany.companyAlias}-外部承包商】`}
                                    </Typography>
                                </Grid> */}
                            </Grid>
                        </CardContent>
                    </Card>}
                    {getSupplierType() === 'external' && <Card className={classes.root} spacing={1}>
                        <CardContent>
                            <Typography className={classes.pos} color="textSecondary">
                                {''}
                            </Typography>
                            <Grid container direction="column" alignItems="center">

                                <Grid item className={classes.item}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="点击右侧按钮选择"
                                        label="外部承包商"
                                        name='supplier'
                                        value={currentWOE.supplierName ? currentWOE.supplierName : ''}
                                        // disabled={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {Boolean(currentWOE.supplierName) &&
                                                        <Tooltip title="清空选择">
                                                            <IconButton className={classes.iconButton}
                                                                onClick={(event) => onhandleChangeItem(event, 'supplierId')}
                                                                disabled={Boolean(currentWOE.internalCompany && currentWOE.internalCompany !== 0)}
                                                                edge="start"
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    <Divider className={classes.divider} orientation="vertical" />
                                                    <Tooltip title="选择承包商">
                                                        <IconButton className={classes.iconButton}
                                                            onClick={(event) => onhandleSelectItem(event, 'supplierId')}
                                                            disabled={Boolean(currentWOE.internalCompany && currentWOE.internalCompany !== 0)}
                                                            edge="end"
                                                        >
                                                            <StoreIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    // disabled={Boolean(currentWOE.internalCompany && currentWOE.internalCompany !== 0)}
                                    // onChange={(event) => onhandleChangeItem(event, 'supplierId')}
                                    // select
                                    >
                                        {/* <MenuItem aria-label="None" value={0}></MenuItem>
                                        {supplierList ?
                                            _.sortBy(supplierList, 'supplierId').map(data => (
                                                <MenuItem value={data.supplierId} key={data.supplierId}>{data.supplierName}</MenuItem>
                                            )) : null
                                        } */}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" className={classes.button} onClick={onhandleClose}>
                        关闭</Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={onhandleUpdate} disabled={checkSave()}>
                        保存</Button>
                </DialogActions>
            </Dialog >
        </div >

    );
}

AssignmentPannelComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    companyList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    currentWOE: PropTypes.object.isRequired,
    onhandleChangeItem: PropTypes.func.isRequired,
};
AssignmentPannelComponent.defaultProps = {

}


export default withStyles(AssignmentPannelComponent);