import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    attrs: [], //生产活动属性列表
    attr: {}, //生产活动属性
};

export const setAttrs = createAction('@@xcloud/manufacture/setAttrs');
export const setAttr = createAction('@@xcloud/manufacture/setAttr');

export const getAttrs = createAction('@@xcloud/manufacture/getAttrs',
    (attrCode, attrName, attrUnit, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/activity/attrs'
        let strs = []
        if (attrCode || attrName || attrUnit || status) {
            if (attrCode) strs.push('attrCode=' + attrCode)
            if (attrName) strs.push('attrName=' + attrName)
            if (attrUnit) strs.push('attrUnit=' + attrUnit)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setAttrs(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产活动属性列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getAttr = createAction('@@xcloud/manufacture/getAttr',
    (attrId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/activity/attrs/' + attrId
        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setAttr(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取生产活动属性失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addAttr = createAction('@@xcloud/manufacture/addAttr',
    (attr) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/activity/attrs', attr)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加生产活动属性成功"));
                }
            }).catch(err => {
                let msg = `增加生产活动属性失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateAttr = createAction('@@xcloud/manufacture/updateAttr',
    (attrId, attr) => (dispatch, getState, httpClient) => {
        const userInfo = getState().auth.currentUserInfo;
        attr.tenantId = userInfo.tenantId;
        return httpClient.patch(porxyApi + '/manufacture/activity/attrs/' + attrId, attr)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新生产活动属性成功"));
                }
            }).catch(err => {
                let msg = `更新生产活动属性失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteAttr = createAction('@@xcloud/manufacture/deleteAttr',
    (attrId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/activity/attrs/' + attrId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除生产活动属性成功"));
                }
            }).catch(err => {
                let msg = `删除生产活动属性失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const manufactureActivityAttrReducer = handleActions(
    {
        [setAttrs]: (state, { payload: data }) => {
            return {
                ...state,
                attrs: data
            }
        },

        [setAttr]: (state, { payload: data }) => {
            return {
                ...state,
                attr: data,
            }
        },
    },
    initialState
)

export default manufactureActivityAttrReducer
