import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IndoorRecordComponent from '@/components/OnsiteInspectionManagement/IndoorRecord/IndoorRecord';
import {
    setIndoorInspectionItemList
} from '@/redux/modules/onsiteInspection';
import {
    initIndoorRecordPage,
    backToList,
    submitInspectionRecords,
    setCurrentPlan,
    exportRecordList
} from '@/redux/modules/onsiteInspectionPlan';
// import { getCompanyId } from "@/utils/userUtils";
import { createmodal } from "@/redux/modules/modal";
import { downloadURL } from '@/redux/modules/minio';
import { createMessage } from '@/redux/modules/message';
import _ from 'lodash';

class OnsiteInspectionIndoorRecordContainer extends Component {
    static propTypes = {
        companyTree: PropTypes.array,
        inspectionTypeList: PropTypes.array,
        keyword: PropTypes.string,
        search: PropTypes.object,
        currentCompany: PropTypes.object
    };

    componentWillMount() {
        let planId = this.props.match.params.planId;
        this.props.onhandleInitPage(planId);
    }

    // handleListSize = (size) => {
    //     this.props.onhandleUpdateEditSize(size)
    // }

    handleBack() {
        this.props.onhandleBack();
    }

    handleExport(planId) {
        this.props.onhandleExport(planId);
    }

    // trimCompanyList() {
    //     const that = this
    //     let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    //     let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
    //     let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
    //     let list = _.concat(companyList, subCompanyList)

    //     function loop(array) {
    //         for (let i = 0; i < array.length; i++) {
    //             const item = array[i];
    //             let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
    //             list = _.concat(list, sub)
    //             loop(sub);
    //         }
    //     }

    //     loop(subCompanyList);

    //     let result = _.map(list, t => {
    //         return { label: t.companyName, value: t.companyId }
    //     })

    //     return result
    // }

    handleDownload = (event, docList) => {
        if (docList && docList.length) {
            const promiseArr = [];
            docList.forEach(doc => {
                const {
                    docName
                } = doc;
                let tenantId = this.props.userProfile.roles[0].tenantId
                let envCode = 'dev';
                if (window.location.host.indexOf('3050') > -1) {
                    envCode = 'dev';
                } else if (window.location.host.indexOf('3060') > -1) {
                    envCode = 'uat';
                } else if (process.env.NODE_ENV === 'production') {
                    envCode = 'prod';
                }
                let mybucket = _.replace(envCode + '.{tenant_id}.onsite-inspection.onsite-inspection-doc', '{tenant_id}', tenantId);
                promiseArr.push(this.props.onhandleDownloadURL(docName, mybucket));
            });
            Promise.all(
                promiseArr
            ).then(res => {
                docList.forEach((doc, index) => {
                    doc.url = res[index].payload;
                })
                this.props.onhandleOpenAttachmentDocs({
                    docs: docList,
                    title: '巡检记录图像'
                });
            }).catch(err => {

            });
        }
    }

    handleChange = (event, row) => {
        const {
            currentUserInfo,
            inspectionItemList,
            onhandleSetIndoorInspectionItemList
        } = this.props;
        // const fieldName = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);
        inspectionItemList.forEach(item => {
            if (row.itemId === item.itemId) {
                item.val = fieldValue;
                item.revisor = currentUserInfo.userId;
            }
        })
        onhandleSetIndoorInspectionItemList([...inspectionItemList]);
    }

    handleInvalid = (event, row) => {
        const {
            currentUserInfo,
            inspectionItemList,
            onhandleSetIndoorInspectionItemList
        } = this.props;
        // 获取checkbox值只能取checked
        let status = 'active';
        if (event.target.checked) {
            status = 'invalid';
        }
        inspectionItemList.forEach(item => {
            if (row.itemId === item.itemId) {
                item.status = status
                item.revisor = currentUserInfo.userId;
            }
        })
        onhandleSetIndoorInspectionItemList([...inspectionItemList]);
    }

    handleOpentextChange = (event) => {
        const {
            currentPlan,
            onhandleSetCurrentPlan
        } = this.props;
        const fieldValue = _.cloneDeep(event.target.value);
        const newPlan = _.cloneDeep(currentPlan);
        newPlan.opentext = fieldValue;
        onhandleSetCurrentPlan(newPlan);
    }

    handleSave = (finishInspection) => {
        const {
            currentPlan,
            currentUserInfo,
            inspectionItemList,
            onhandleSubmitInspectionRecords,
            onhandleCreateMessage
        } = this.props;

        if (!this.handleCheck(finishInspection)) {
            return
        }
        let records = [];
        // 保存并结束
        inspectionItemList.forEach(item => {
            const { itemId, val, paramId, deviceId, status, revisor, installUseStatus, deviceUseStatus } = item;
            if (
                status === 'invalid' || (val !== null && val !== '' && !_.isNaN(_.toNumber(val)))
            ) {
                const record = {
                    recordId: item.recordId || 0,
                    planId: currentPlan.planId,
                    itemId: itemId,
                    val: status === 'invalid' ? "" : Number(val),
                    status: status || 'active',
                    tenantId: currentUserInfo.tenantId,
                    paramId: paramId,
                    deviceId: deviceId,
                    insUseStatus: installUseStatus || 'INUSE',
                    deviceUseStatus: deviceUseStatus || 'INUSE',
                    revisor: revisor ? revisor : undefined
                }
                records.push(record);
            }
        })
        // 结束巡检 根据当前结束阶段传入plan状态 pending/done
        let submitStatus = currentPlan.status;
        if (finishInspection) {
            if (records.length !== inspectionItemList.length) {
                onhandleCreateMessage('error', '巡检项未完成填写，无法结束巡检');
                return;
            }
            if (currentPlan.recordConfirm && currentPlan.status !== 'pending') {
                submitStatus = 'pending'
            } else {
                submitStatus = 'done';
            }
        }
        onhandleSubmitInspectionRecords(currentPlan.planId, records, submitStatus);
    }


    isConfirmStatus = () => {
        const {
            currentPlan,
            currentUserInfo
        } = this.props;
        // 是确认角色且计划处于确认状态
        if (currentPlan.recordConfirm && currentPlan.confirmRole === currentUserInfo.roleId && currentPlan.status === 'pending') {
            return true;
        }
        return false;
    }

    handleCheck = (finishInspection) => {
        const {
            inspectionItemList,
            onhandleSetIndoorInspectionItemList
        } = this.props;

        let hasErr = false;
        inspectionItemList.forEach((item) => {
            const {
                val,
                status
            } = item;
            if (status !== 'invalid') {
                if (finishInspection && (val === null || val === '')) {
                    item.err = '未填写';
                    hasErr = true;
                } else if (val !== null && val !== '' && _.isNaN(Number(val))) {
                    item.err = '无效数值';
                    hasErr = true;
                } else {
                    item.err = '';
                }
            } else {
                item.err = '';
            }
        })
        onhandleSetIndoorInspectionItemList([...inspectionItemList]);
        return !hasErr;
    }


    render() {
        return (
            <div>
                <IndoorRecordComponent
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    currentPlan={this.props.currentPlan}
                    onhandleBack={this.handleBack.bind(this)}
                    inspectionItemList={this.props.inspectionItemList}
                    recordList={this.props.recordList}
                    recordHistoryList={this.props.recordHistoryList}
                    companyList={this.props.companyList}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleOpentextChange={this.handleOpentextChange.bind(this)}
                    onhandleInvalid={this.handleInvalid.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleDownload={this.handleDownload.bind(this)}
                    users={this.props.users}
                    departmentList={this.props.departmentList}
                    unit={this.props.unit}
                />
            </div>
        );
    }

    componentWillUnmount() {
        // 清理store内编辑中对象
        if (this.props.onhandleSetIndoorInspectionItemList) {
            this.props.onhandleSetIndoorInspectionItemList([]);
        }
        if (this.props.setCurrentPlan) {
            this.props.setCurrentPlan({});
        }
    }
}

const mapStateToProps = (state) => {
    return {
        recordList: state.onsiteInspectionPlan.recordList,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companyList: state.constant.companies,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        currentPlan: state.onsiteInspectionPlan.currentPlan,
        users: state.onsiteInspectionPlan.users,
        departmentList: state.onsiteInspectionPlan.departmentList,
        inspectionItemList: state.onsiteInspection.inspectionItemList,
        recordHistoryList: state.onsiteInspectionPlan.recordHistoryList,
        unit: state.onsiteInspectionPlan.unit,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitPage: (planId) => {
            dispatch(initIndoorRecordPage(planId));
        },
        onhandleSetIndoorInspectionItemList: (list) => {
            dispatch(setIndoorInspectionItemList(list));
        },
        onhandleBack: () => {
            dispatch(backToList());
        },
        onhandleSetCurrentPlan: (currentPlan) => {
            dispatch(setCurrentPlan(currentPlan));
        },
        onhandleSubmitInspectionRecords: (planId, records, submitStatus) => {
            dispatch(submitInspectionRecords(planId, records, submitStatus));
        },
        onhandleExport: (planId) => {
            dispatch(exportRecordList(planId));
        },
        onhandleOpenAttachmentDocs: (config) => {
            dispatch(createmodal('attachmentDocs', config));
        },
        onhandleDownloadURL: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        onhandleCreateMessage: (type, message) => {
            dispatch(createMessage(type, message))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionIndoorRecordContainer);