import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import { Prompt } from 'react-router-dom';

const TemporaryRegulationPlanInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        currentTrp,
        TrpError,
        TrpChange,
        schemeMap
    } = props;

    return (
        <div>
            <Card className={classes.card}>

                <Typography variant="h3" gutterBottom>
                    {
                        schemeMap && schemeMap.schemeType && schemeMap.schemeType === 'annual' ?
                            '新建计划' : '新建临时计划'
                    }
                    {schemeMap && schemeMap.hid && <Chip className={classes.tag} size='small' label={'隐患治理'} />}
                </Typography>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>计划名称</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            error={TrpError.planName && TrpError.planName !== ''}
                            helperText={TrpError.planName || ''}
                            className={classes.textField}
                            value={currentTrp.planName || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'planName')}
                            onBlur={event => onhandleCheck(event, 'planName')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>计划说明</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            className={classes.textField}
                            value={currentTrp.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={!TrpChange}
                        onClick={event => onhandleSave(event)}
                    >
                        下一步
                </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleBack(event)}
                    >
                        返回
                </Button>
                </Grid>

            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={TrpChange} />
        </div>
    );
};

TemporaryRegulationPlanInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentTrp: PropTypes.object.isRequired,
    TrpError: PropTypes.object.isRequired,
    TrpChange: PropTypes.bool.isRequired,
    schemeMap: PropTypes.object.isRequired
    //constant: PropTypes.object.isRequired
};

export default withStyles(TemporaryRegulationPlanInfo);
