import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import VariablePlanComponent from "@/components/AmoebaManagement/VariablePlan/VariablePlanComponent";
import {
    getVariablePlanDtls,
    initVariablePlan,
    addAndUpdateVariablePlanDtls,
    exportVariablePlanTemplate,
    importVariablePlanDtls
} from '@/redux/modules/amoeba/amoebaVariablePlan';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { formatYear, getMoment, getNowTimestamp } from '@/utils/datetime';
import { upload } from '@/redux/modules/minio';
import { getRole } from "@/utils/userUtils";
import _ from 'lodash';
class VariablePlanContainer extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            sortSubs: [],
            dpts: [],
        }
    }


    async componentDidMount() {
        let dpts = _.filter(this.props.departments,
            dpt => dpt.companyId === this.props.currentUserInfo.companyId && (dpt.amoebaType === 'COST' || dpt.amoebaType === 'PROFIT'))

        this.props.onInitPage(this.props.companyId, this.getPid() ? this.props.companyId : null, null, formatYear(this.state.selectedDate), (data) => {
            const subSubs = _.filter(this.props.subs, s => {
                return _.find(this.props.variableBudgetConfigs, c => { return c.subId === s.subId })
            })
            this.setState({
                dpts: dpts,
                dptId: dpts[0].dptId,
                sortSubs: subSubs,
            }, () => {
                this.buildData()
            })
        });
    }

    handleUpdate(newData, oldData) {
        console.log(newData)
        const { currentUserInfo, addAndUpdateAmoebaVariablePlanDtls } = this.props
        const { dptId, selectedDate } = this.state
        let datas = []
        for (let index = 1; index <= 12; index++) {
            if (newData['amount_' + index] !== oldData['amount_' + index]) {
                let data = {
                    dtlId: newData['dptId_' + index],
                    companyId: currentUserInfo.companyId,
                    dptId: dptId,
                    subId: newData.subId,
                    amount: newData['amount_' + index] * 10000,
                    year: formatYear(selectedDate),
                    month: index,
                }
                datas.push(data)
            }
        }
        console.log(datas)
        return addAndUpdateAmoebaVariablePlanDtls(datas).then((data) => {
            this.refresh()
        });
    }

    handleEditVariablePlan(event, ele) {
        //请求数据
        this.setState({
            isOpen: true,
            showCompanyId: ele.id,
            showCompanyName: ele.name
        })
    }

    refresh() {
        let companyId = null
        if (this.getPid()) {
            companyId = this.props.currentUserInfo.companyId
        }
        let date = this.state.selectedDate

        this.props.getAmoebaVariablePlanDtls(companyId, null, formatYear(date)).then(data => {
            this.buildData()
        })
    }

    buildData() {
        const { planDtls } = this.props
        if (!planDtls) {
            return []
        }
        let rows = []
        _.forEach(this.state.sortSubs, sub => {
            let row = {
                subId: sub.subId,
                subName: sub.subName,
                subLevel: sub.subLevel,
                subParentId: sub.subParentId,
            }
            var month = 1;
            for (month; month <= 12; month++) {
                // eslint-disable-next-line no-loop-func
                const dtl = _.filter(planDtls, dtl => dtl.subId === sub.subId && dtl.month === month && (this.state.dptId ? dtl.dptId === this.state.dptId : true))
                if (dtl.length > 0) {
                    console.log(dtl)
                    row['amount_' + month] = dtl[0].amount / 10000
                    row['dptId_' + month] = dtl[0].dtlId
                } else {
                    row['amount_' + month] = 0
                    row['dptId_' + month] = 0
                }
            }
            rows.push(row)
        })
        console.log(rows)
        this.setState({ rows: rows })
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }


    getUserRole = () => {
        const roleRoute = this.props.roleRoutes.find(ele => ele.route === 'amoeba_plan_variable_plan')
        if (roleRoute) {
            return roleRoute.action === 'W'
        }
        return false;
    }

    handleDateChange = (date) => {
        this.setState({ selectedDate: date, year: formatYear(date) }, function () {
            this.refresh()
        });
    }

    handleDptChange = (event) => {
        this.setState({ dptId: event.target.value }, () => { this.buildData() })
    }

    handleExport = () => {
        const { onhandleDownloadFile, currentUserInfo } = this.props;

        const year = parseInt(formatYear(this.state.selectedDate));
        const companyId = currentUserInfo.companyId;

        onhandleDownloadFile(year, companyId);
    }

    handleImport = () => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'uat';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.variable.plan.dtl.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                companyId: this.props.currentUserInfo.companyId,
                year: parseInt(formatYear(this.state.selectedDate)),
                docName: offset + files[0].name
            };
            this.props.onhandleCreateDoc(doc, () => {
                this.refresh();
            });
            this.props.onhandleModalClose();
        });
    }

    render() {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_plan_variable_plan').action === 'W';
        return (
            <div>
                <VariablePlanComponent
                    planDetails={this.state.rows}
                    selectedDate={this.state.selectedDate}
                    dpts={this.state.dpts}
                    dptId={this.state.dptId}
                    roleFlag={roleFlag}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleDptChange={this.handleDptChange.bind(this)}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleImport={this.handleImport.bind(this)}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.constant.companies,
        companyId: state.auth.currentUserInfo.companyId,
        planDtls: state.amoebaVariablePlan.planDtls,
        subs: state.amoebaIndex.subs,
        variableBudgetConfigs: state.amoebaIndex.variableBudgetConfigs,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        departments: state.constant.departments,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (companyId, companyIds, dptIds, year, callback) => {
            return dispatch(initVariablePlan(companyId, companyIds, dptIds, year, callback))
        },
        getAmoebaVariablePlanDtls: (companyIds, dptIds, year) => {
            return dispatch(getVariablePlanDtls(companyIds, dptIds, year))
        },
        addAndUpdateAmoebaVariablePlanDtls: (dtls) => {
            return dispatch(addAndUpdateVariablePlanDtls(dtls));
        },
        onhandleDownloadFile: (year, companyId) => {
            return dispatch(exportVariablePlanTemplate(year, companyId))
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleCreateDoc: (doc, callback) => {
            dispatch(importVariablePlanDtls(doc, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VariablePlanContainer))