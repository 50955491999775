import { withStyles } from '@material-ui/styles';
import { indigo, red } from '@material-ui/core/colors';
import { stripedTableStyle } from '@/utils/antdCustomStyle';
// import { colors } from '@material-ui/core';

const styles = theme => ({
    stripedTable: stripedTableStyle,
    root: {
        padding: theme.spacing(0)
    },
    exception: {
        color: '#f4511e',
        fontWeight: 'bold'
    },
    content: {
        marginTop: theme.spacing(0),
        // padding: 20
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    title: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formGridContainer: {

    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    detailtable: {
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
        height: '100%',
    },
    // detailtable: {
    //     height: '100%'
    //     // padding: theme.spacing(4)
    // },
    materialtable: {
        boxShadow: 'none',
        height: '100%',
    },
    tableFooter: {
        position: 'sticky',
        bottom: -1,
        background: 'white',
        width: '100%'
    },
    tableFooterCell: {
        padding: 4
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: red[300],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: indigo[300],
        fontSize: '12px',
    },
    interval: {
        textAlign: 'center'
    }
});

export default withStyles(styles);