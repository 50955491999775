import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/OtherPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDepartmentsByRole } from "@/utils/userUtils";
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import * as _ from 'lodash';

const OtherPricingComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        onhandleRuleUpdate,
        onhandleCompanyChange,
        serviceBudgets,
        currentUserInfo,
        department,
        userProfile,
        onhandleShowHistory,
        onhandleShowHistoryE,
        onhandleShowHistoryI,
        onhandleShowHistoryO,
        roleFlag,
    } = props;


    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,

    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }


    const otherassemblyDetailColumns = () => {
        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '业务分包单耗',
                field: 'ratio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.ratio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistory(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            }
            ,
            {
                title: '设备检维修单耗',
                field: 'eRatio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.eRatio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryE(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '内部支出单耗',
                field: 'iRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.iRatio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryI(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '自营支出单耗',
                field: 'oRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.oRatio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            }
        ]
        return columns
    }

    const otherassemblyData = () => {
        const bBudgets = _.filter(serviceBudgets, { 'subAlias': '业务分包' })
        const eBudgets = _.filter(serviceBudgets, { 'subAlias': '检维修' })
        const iBudgets = _.filter(serviceBudgets, { 'subAlias': '内部劳务支出' })
        const oBudgets = _.filter(serviceBudgets, { 'subAlias': '自营支出' })
        _.forEach(bBudgets, value => {
            _.forEach(eBudgets, e => {
                if (value.serviceId === e.serviceId) {
                    value.eBudgetId = e.budgetId
                    value.eRatio = e.ratio
                    value.eVariableSub = e.variableSub
                }
            })
            _.forEach(iBudgets, i => {
                if (value.serviceId === i.serviceId) {
                    value.iBudgetId = i.budgetId
                    value.iRatio = i.ratio
                    value.iVariableSub = i.variableSub
                }
            })
            _.forEach(oBudgets, o => {
                if (value.serviceId === o.serviceId) {
                    value.oBudgetId = o.budgetId
                    value.oRatio = o.ratio
                    value.oVariableSub = o.variableSub
                }
            })
        })
        console.log(bBudgets)
        return bBudgets
    }

    const checkDpt = () => {
        const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
        return dptId === department.dptId || roleFlag ? true : false;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item className={classes.processLine}>
                    </Grid>
                    <Grid>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            columns={otherassemblyDetailColumns()}
                            data={otherassemblyData()}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={detailTableStyle}
                            editable={checkDpt() ? {
                                onRowUpdate: (newData, oldData) => onhandleRuleUpdate(newData, oldData)
                            } : {}}
                            actions={
                                [
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '业务分包修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistory(event, rowData)
                                    //     }
                                    // },
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '检维修修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistoryE(event, rowData)
                                    //     }
                                    // },
                                    {
                                        icon: () => { return <HistoryIcon /> },
                                        tooltip: '自营支出修改历史',
                                        onClick: (event, rowData) => {
                                            onhandleShowHistoryO(event, rowData)
                                        }
                                    },
                                ]
                            }
                            title={<Typography variant='h3'>业务分包预算规则</Typography>}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

OtherPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companyId: PropTypes.number,
    subCompanyList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    varFeeRuleList: PropTypes.array,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,
};

OtherPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(OtherPricingComponent);