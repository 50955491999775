import { connect } from 'react-redux';
import { Dialog } from '../components/DialogCommon';
import { cleanDialog } from '../redux/modules/dialog';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.setting
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanDialog: () => {
            dispath(cleanDialog());
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(Dialog);

