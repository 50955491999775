import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateOnDutyPlan from '../../../components/LeadershipManagement/OnDuty/CreateOnDutyPlan';
import { startProcessMonthlyOnDuty, getRules, updateRule, setOnDutyCreateOpen } from '../../../redux/modules/safetyActivity';
import { getMoment } from '../../../utils/datetime';
import * as _ from 'lodash';
// import { isThisSecond } from 'date-fns';

class CreateOnDutyPlanContainer extends Component {

    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()().add(1, 'months'),
            year: getMoment()().add(1, 'months').year(),
            month: getMoment()().add(1, 'months').month() + 1,
            planName: '带班值班计划',
            ruleChanged: false,
            rules: []
        }
    }

    componentDidMount() {
        this.handleGetRules()
    }

    handleGetRules() {

        const { onhandleGetRules } = this.props
        onhandleGetRules((activityRules) => {
            const onDutyRules = _.filter(activityRules, r => { return r.activityType === '1' })
            let rules = _.map(onDutyRules, r => {
                let [value, unit] = r.rule.split('/')
                return {
                    ruleId: r.ruleId,
                    ruleName: r.ruleName,
                    ruleValue: value,
                    ruleUnit: '次/天',
                    unit: unit
                }
            })
            this.setState({ rules: rules })
        })

    }

    handleChangePlan(event) {
        if (event.target.name === 'planName')
            this.setState({ planName: event.target.value })
    }

    handleChangeDate = (date) => {
        this.setState({ selectedDate: getMoment()(date) })
    }

    handleAddOnDutyPlan = async () => {
        const { onhanleAddOnDutyPlan, currentUserInfo, onhandleClose } = this.props;
        let plan = {
            companyId: currentUserInfo.companyId,
            year: this.state.selectedDate.year(),
            month: this.state.selectedDate.month() + 1,
            planName: this.state.planName,
            activityType: 1 //带班值班活动
        }
        await onhanleAddOnDutyPlan(plan)
        onhandleClose()
    }

    handleChangeRule(rule, event) {

        let newRules = _.map(this.state.rules, r => {
            if (r.ruleId === rule.ruleId)
                return { ...r, ruleValue: event.target.value, changed: true }
            else return { ...r }
        })

        this.setState({
            rules: newRules,
            ruleChanged: true
        })
    }
    handleSaveRule() {
        const { onhandleSaveRule } = this.props
        _.map(this.state.rules, r => {
            if (r.changed) {
                onhandleSaveRule({
                    ruleId: r.ruleId,
                    rule: r.ruleValue + '/' + r.unit
                })
            }
        })
        this.setState({ ruleChanged: false })
    }
    handleClose() {
        const { onhandleClose } = this.props
        onhandleClose()
    }
    render() {
        return (
            <div>
                <CreateOnDutyPlan
                    open={this.props.open}
                    selectedDate={this.state.selectedDate}
                    planName={this.state.planName}
                    ruleChanged={this.state.ruleChanged}
                    rules={this.state.rules}
                    onhandleChangePlan={this.handleChangePlan.bind(this)}
                    onhandleChangeRule={this.handleChangeRule.bind(this)}
                    onhandleSaveRule={this.handleSaveRule.bind(this)}
                    onhandleAddOnDutyPlan={this.handleAddOnDutyPlan.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        open: state.safetyActivity.onDutyCreateOpen,
        onDutyRules: _.filter(state.safetyActivity.activityRules, r => { return r.activityType === '1' })
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhanleAddOnDutyPlan: (plan) => {
            return dispatch(startProcessMonthlyOnDuty(plan))
        },
        onhandleGetRules: (callback) => {
            dispatch(getRules(callback))
        },
        onhandleSaveRule: (rule, callback) => {
            dispatch(updateRule(rule, callback))
        },
        onhandleClose: () => {
            dispatch(setOnDutyCreateOpen(false))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOnDutyPlanContainer);