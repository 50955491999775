import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationWorkPermitPanel from '@/components/OperationManagement/Appointment/OperationWorkPermitPanel';
import { closeWorkPermitPannel, changePermit } from '@/redux/modules/operationWorkPermit';
import { getWorkPermitTemplateName, mappingSpecCodeToPermitCode } from '@/utils/operationUtils';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createMessage } from '../../redux/modules/message';
import { download } from '@/redux/modules/minio';
// import { getMoment as moment } from "@/utils/datetime";
import _ from 'lodash';

class OperationWorkPermitPanelContainer extends Component {
    static propTypes = {
        permit: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            permitError: {
                // permitType: '',
                ptwName: '',
                // expiredTime: '',
                file: '',
                // intGuarder: '',
                // extGuarder: ''
            }
        };
    }

    componentDidMount() {

    }

    handleConfirm() {
        const { confirmCallback, permit, onhandleClose, showtype } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(permit, showtype)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleDownloadTemp() {
        const { appointment, onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-template', '{tenant_id}', tenantId);
        const fileName = getWorkPermitTemplateName(mappingSpecCodeToPermitCode(appointment.specCode));
        onhandleDownloadFile(fileName, mybucket);
    }

    handleDeleteFile(e) {
        this.props.permit.permitDocs = [];
        const { onhandleChange, permit } = this.props;
        let newPermit = {
            ...permit
        }
        newPermit.permitDocs = [];
        onhandleChange(newPermit);
    }

    handleDownloadFile() {
        const { permit, onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        const fileName = permit.permitDocs[0].docLink;
        onhandleDownloadFile(fileName, mybucket);
    }

    handleApproverSelect(event) {

    }

    handleChange(event) {
        const { onhandleChange, permit } = this.props
        let newPermit = {
            ...permit
        }
        const field = event.target.name;
        newPermit[field] = event.target.value;

        onhandleChange(newPermit);
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    // handleListMapping = (colValue, field) => {
    //     let value = colValue;
    //     const {
    //         constant
    //     } = this.props;
    //     switch (field) {
    //         case 'permitType':
    //             value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
    //             break;
    //         default:
    //         // do nothing
    //     }
    //     return value;
    // }

    handleUploadConfirm(files, setUploadStatus) {
        const { onhandleChange, permit } = this.props
        let newPermit = {
            ...permit
        }
        newPermit.file = files;

        onhandleChange(newPermit);
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
        setTimeout(() => {
            this.handleCheck(null, 'file');
        }, 100)
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleOpenUserSelectModal(event) {
        let param = {
            title: '选择监护人',
            company: true,
            group: true,
            role: true,
            department: true,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhandleSelectUserCheck()
                        return reject('callbackFunc Error')
                    } else {
                        const { onhandleChange, permit } = this.props;
                        let userId = this.props.selectedUsers[0].userId;
                        let newPermit = {
                            ...permit
                        };
                        newPermit.intGuarder = userId;
                        onhandleChange(newPermit);
                        setTimeout(() => {
                            this.handleCheck(null, 'intGuarder');
                        }, 100)
                        return resolve('force close modal')
                    }
                })
            }
        }
        this.props.onhandleOpenUserSelectModal(param);
    }

    handleCheck = (event, field) => {

        const {
            permit
        } = this.props;

        let err = {};

        if (!field || field === 'ptwName') {
            if (_.trim(permit.ptwName) === '') {
                err['ptwName'] = '许可名称不能为空';
            } else {
                err['ptwName'] = '';
            }
        }

        // if (!field || field === 'intGuarder') {
        //     if (_.trim(permit.intGuarder) === '') {
        //         err['intGuarder'] = '属地监护人不能为空';
        //     } else {
        //         err['intGuarder'] = '';
        //     }
        // }

        // if (!field || field === 'extGuarder') {
        //     if (_.trim(permit.extGuarder) === '') {
        //         err['extGuarder'] = '作业监护人不能为空';
        //     } else {
        //         err['extGuarder'] = '';
        //     }
        // }

        // if (!field || field === 'expiredTime') {
        //     if (_.trim(permit.expiredTime) === '') {
        //         err['expiredTime'] = '有效期不能为空';
        //     } else if (_.trim(permit.expiredTime) !== '' && (_.isNaN(_.toNumber(permit.expiredTime)) || _.toNumber(permit.expiredTime) <= 0)) {
        //         err['expiredTime'] = '请输入正确的小时数';
        //     } else {
        //         err['expiredTime'] = '';
        //     }
        // }

        if (!field || field === 'file') {
            // 检测文件是否上传
            if (permit.ptwId) {
                if (permit.permitDocs && permit.permitDocs.length === 0 &&
                    (!permit.file || !permit.file.length || permit.file.length === 0)
                ) {
                    err['file'] = '请上传许可证';
                } else {
                    err['file'] = '';
                }
            } else {
                if (!permit.file || !permit.file.length || permit.file.length === 0
                ) {
                    err['file'] = '请上传许可证';
                } else {
                    err['file'] = '';
                }
            }
        }

        let newError = {
            ...this.state.permitError,
            ...err
        }
        this.setState({
            permitError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <OperationWorkPermitPanel
                open={this.props.open}
                currentUserInfo={this.props.currentUserInfo}
                permit={this.props.permit}
                appointment={this.props.appointment}
                users={this.props.users}
                specCodeList={this.props.specCodeList}
                userList={this.props.userList}
                permitError={this.state.permitError}
                onhandleDownloadTemp={this.handleDownloadTemp.bind(this)}
                onhandleApproverSelect={this.handleOpenUserSelectModal.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleDeleteFile={this.handleDeleteFile.bind(this)}
                onhandleDownloadFile={this.handleDownloadFile.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        open: state.operationWorkPermit.open,
        constant: state.constant,
        users: state.user.leaders,
        userProfile: state.auth.userProfile,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        specCodeList: state.operation.specCodeList,
        permit: state.operationWorkPermit.permit,
        appointment: state.operationWorkPermit.appointment,
        showtype: state.operationWorkPermit.showtype,
        selectedUsers: state.userSelect.selectedUsers,
        userList: state.user.users.list,

        confirmCallback: state.operationWorkPermit.confirmCallback,
        closeCallback: state.operationWorkPermit.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (permit) => {
            dispatch(changePermit(permit));
        },
        onhandleClose: () => {
            dispatch(closeWorkPermitPannel());
        },
        onhandleDownloadFile: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleOpenUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhandleSelectUserCheck: () => {
            dispatch(createMessage('error', `只能选择一名监护人`));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationWorkPermitPanelContainer);