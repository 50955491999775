import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import RegulationPlanList from '../../../containers/LeadershipManagement/AnnualPlan/RegulationPlanList';
import RegulationPlanListAll from '../../../containers/LeadershipManagement/AnnualPlan/RegulationPlanListAll';
import RegulationPlanCurrentYear from '../../../containers/LeadershipManagement/AnnualPlan/RegulationPlanCurrentYear';
import RegulationPlanExecution from '../../../containers/LeadershipManagement/CompanyRegulation/RegulationPlanExecution';
//import TemporaryRegulationPlanList from '../../../containers/LeadershipManagement/TemporaryPlan/TemporaryRegulationPlan/TemporaryRegulationPlanList';

import { Route, withRouter } from 'react-router';
import {
    Tabs,
    Tab,
    Grid,
    Card
} from '@material-ui/core';

const AnnualPlan = ({
    classes,
    onhandleChangeTab,
    tabValue,
    plan
}) => {
    const tab3 = () =>{
        //let year = plan.year;
        //let detail = '拟定' + year + '年度计划';
        let detail = '我的计划';
        return detail
    } 
    return (
        <Card alignitems="center" className={classes.root}>

            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={onhandleChangeTab}
            >
                <Tab label="我的修订" />

                <Tab label="修订列表" />

                <Tab label = {tab3()} />

                <Tab label="计划列表" />
            </Tabs>
            <Grid>
                {tabValue === 0 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/regulation' exact component={RegulationPlanExecution}></Route>
                        </Grid>
                    </Grid>}
                {tabValue === 1 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/regulation' exact component={RegulationPlanCurrentYear}></Route>
                        </Grid>
                    </Grid>}
                {tabValue === 2 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/regulation' exact component={RegulationPlanList}></Route>
                        </Grid>
                    </Grid>}
                {tabValue === 3 &&
                    <Grid>
                        <Grid className={classes.planItem}>
                            <Route path='/leadership/plan/regulation' exact component={RegulationPlanListAll}></Route>
                        </Grid>
                    </Grid>}
            </Grid>
        </Card>

    );
};
AnnualPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

AnnualPlan.defaultProps = {
}


export default withRouter(withStyles(AnnualPlan));