import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TreatmentSchemePannel from '../../../components/InspectionManagement/HazardList/TreatmentSchemePannel';
import { FilePreviewer } from '@/components/SimpleComponents';
import { closeTreatmentSchemePannel, changeTreatmentScheme } from '@/redux/modules/inspection/inspectionTreatmentScheme';
import { getMoment as moment } from "@/utils/datetime";
import { getUsers } from '@/redux/modules/user';
import {
    getListTypeList,
    getCategoryList,
} from "@/redux/modules/inspection/inspection";
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { getActualCompany, getManageDpt, INSPECTION_DICT_TYPES, getOptionList } from '@/utils/constant';
import { download, downloadURL } from '@/redux/modules/minio';
import _ from 'lodash';

class InspectionTreatmentSchemePannelContainer extends Component {
    static propTypes = {
        treatmentScheme: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            treatmentSchemeError: {
                "schemeCat": '',
                "measure": '',
                "dptId": '',
                "companyId": '',
                "head": '',
                "cost": '',
                "targetDate": '',
                "pretreatment": '', // 暂不整改时，必填应急预案
                "creator": '',
                "createTime": '',
                "revisor": '',
                "updateTime": '',
                // "sanction": '',
                "accepter": ''
                // "acceptResult": ''
            },
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        };
    }

    componentDidMount() {
        const {
            onhandleGetLeaderList,
            onhandleGetListTypeList,
            onhandleGetCategoryList
        } = this.props;
        onhandleGetLeaderList({ sort: 'companyId' });
        onhandleGetListTypeList();
        onhandleGetCategoryList();
    }

    getDptInspectTypes(listTypeList) {
        const {
            inspectionDptMapping
        } = this.props;

        // 过滤排查列表
        const filtedTypeList = _.filter(listTypeList, (o) => {
            if (_.findIndex(inspectionDptMapping, { 'inspectionCode': o.code }) > -1)
                return true;
        })
        return filtedTypeList;
    }

    handleConfirm() {
        const { confirmCallback, treatmentScheme, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(treatmentScheme)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, treatmentScheme } = this.props
        let newTreatmentScheme = {
            ...treatmentScheme
        }
        let field = event.target.name;
        let value = event.target.value;

        newTreatmentScheme[field] = value;

        if (field === 'schemeCat') {
            newTreatmentScheme.pretreatment = '';
            newTreatmentScheme.targetDate = '';
            if (value === 'TRE03') {
                const selectedDate = moment()().add(1, 'days');
                newTreatmentScheme.targetDate = selectedDate.endOf('day').valueOf();
                onhandleChange(newTreatmentScheme);
            }
        }
        onhandleChange(newTreatmentScheme);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleAutoCompleteChange(event, newValue, field) {
        const { onhandleChange, treatmentScheme } = this.props;
        if (field === 'head') treatmentScheme.head = newValue ? newValue.userId : null;
        if (field === 'accepter') treatmentScheme.accepter = newValue ? newValue.userId : null;

        onhandleChange({
            ...treatmentScheme,
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleUserPickerClick(field) {
        // 总公司则不限制公司选择
        let companyId = this.props.currentHazardBackup.companyId;
        let mainCompany = _.find(this.props.companyList, function (o) { return !o.pid })
        if (companyId === mainCompany.companyId || field !== 'head') {
            companyId = undefined;
        }
        let param = {
            title: field === 'head' ? '选择整改负责人' : '选择验收负责人',
            company: true,
            companyId: companyId,
            group: true,
            role: true,
            department: true,
            userName: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('只能选择一名人员');
                        return reject('callbackFunc Error')
                    } else {
                        const user = this.props.selectedUsers[0];
                        const { onhandleChange, treatmentScheme } = this.props
                        let newTreatmentScheme = {
                            ...treatmentScheme
                        }
                        let nameField = field + 'Name';
                        newTreatmentScheme[field] = user.userId;
                        newTreatmentScheme[nameField] = user.lastName + user.firstName;

                        onhandleChange(newTreatmentScheme);

                        setTimeout(() => {
                            this.handleCheck(null, field);
                        }, 100)
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    handleDateChange(date, fieldName) {
        const { onhandleChange, treatmentScheme } = this.props
        const selectedDate = moment()(date);
        let newTreatmentScheme = {
            ...treatmentScheme,
        }
        if (fieldName === 'targetDate') newTreatmentScheme.targetDate = selectedDate.endOf('day').valueOf();
        onhandleChange(newTreatmentScheme)
        setTimeout(() => {
            this.handleCheck(null, fieldName);
        }, 100);
    }

    handleDownload() {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = 'uat';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.inspection.pretreatment-doc', '{tenant_id}', tenantId);
        this.props.onhandleDownload(this.props.treatmentScheme.pretreatmentDocs[0].docLink, mybucket);
    }

    async handlePreview(e) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = 'uat';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.inspection.pretreatment-doc', '{tenant_id}', tenantId);
        return this.props.onhandleDownloadUrl(this.props.treatmentScheme.pretreatmentDocs[0].docLink, mybucket)
            .then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: this.props.treatmentScheme.pretreatmentDocs[0].docLink.split('.')[1]
                        }
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyName : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            case 'inspectType':
                value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
                break;
            case 'user':
                const user = _.find(users, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleDeleteFile(e) {
        // e.preventDefault();
        // e.stopPropagation();
        this.props.treatmentScheme.pretreatmentDocs = [];
        const { onhandleChange, treatmentScheme } = this.props;
        let newTreatmentScheme = {
            ...treatmentScheme
        }
        newTreatmentScheme.pretreatmentDocs = [];
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: '',
            }
        })
        onhandleChange(newTreatmentScheme);
    }

    handleUploadConfirm(files, setUploadStatus) {
        const { onhandleChange, treatmentScheme } = this.props
        let newTreatmentScheme = {
            ...treatmentScheme
        }
        newTreatmentScheme.file = files;
        onhandleChange(newTreatmentScheme);
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleCheck = (event, field) => {

        const {
            treatmentScheme,
            isTreatmentHead
        } = this.props;

        let err = {};

        // if (!field || field === 'companyId') {
        //     if (treatmentScheme.companyId === '') {
        //         err['companyId'] = '单位不能为空';
        //     } else {
        //         err['companyId'] = '';
        //     }
        // }

        if (!field || field === 'dptId') {
            if ((!treatmentScheme.dptId || treatmentScheme.dptId === '') && isTreatmentHead) {
                err['dptId'] = '整改部门不能为空';
            } else {
                err['dptId'] = '';
            }
        }

        if (!field || field === 'schemeCat') {
            if (treatmentScheme.schemeCat === '') {
                err['schemeCat'] = '整改方式不能为空';
            } else {
                err['schemeCat'] = '';
            }
        }

        if (!field || field === 'pretreatment') {
            if (treatmentScheme.schemeCat !== 'TRE03' && treatmentScheme.pretreatment === '' && isTreatmentHead) {
                err['pretreatment'] = '应急管控措施不能为空';
            } else {
                err['pretreatment'] = '';
            }
        }

        if (!field || field === 'head') {
            if (_.trim(treatmentScheme.head) === '') {
                err['head'] = '整改负责人不能为空';
            } else {
                err['head'] = '';
            }
        }

        if (!field || field === 'accepter') {
            if (_.trim(treatmentScheme.accepter) === '') {
                err['accepter'] = '验收负责人不能为空';
            } else {
                err['accepter'] = '';
            }
        }

        if (!field || field === 'targetDate') {
            if (!treatmentScheme.targetDate && isTreatmentHead) {
                err['targetDate'] = '整改期限不能为空';
            } else {
                err['targetDate'] = '';
            }
        }

        if (!field || field === 'measure') {
            if (!_.trim(treatmentScheme.measure) && isTreatmentHead) {
                err['measure'] = '整改措施不能为空';
            } else {
                err['measure'] = '';
            }
        }

        if (!field || field === 'cost') {
            // if (treatmentScheme.cost !== '' && _.isNaN(_.toNumber(treatmentScheme.cost))) {
            //     err['cost'] = '请输入数值';
            // } else {
            //     err['cost'] = '';
            // }
            if (_.trim(treatmentScheme.cost) === '' && isTreatmentHead) {
                err['cost'] = '整改预算不能为空';
            } else if (_.trim(treatmentScheme.cost) !== '' && (_.isNaN(_.toNumber(treatmentScheme.cost)) || _.toNumber(treatmentScheme.cost) < 0) && isTreatmentHead) {
                err['cost'] = '请输入正确的数值';
            } else {
                err['cost'] = '';
            }
        }

        let newError = {
            ...this.state.treatmentSchemeError,
            ...err
        }
        this.setState({
            treatmentSchemeError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    render() {
        const { previewFile } = this.state;
        // const needPretreatment = this.props.treatmentScheme.schemeCat === 'TRE03';
        return (<>
            <TreatmentSchemePannel
                open={this.props.open}
                editable={this.props.editable}
                isTreatmentHead={this.props.isTreatmentHead}
                // needPretreatment={needPretreatment}
                companyList={this.props.companyList}
                departmentList={this.props.departmentList}
                treatmentScheme={this.props.treatmentScheme}
                currentHazardBackup={this.props.currentHazardBackup}
                users={this.props.users}
                companyLeaders={this.props.companyLeaders}
                treatmentSchemeError={this.state.treatmentSchemeError}
                onhandleListMapping={this.handleListMapping.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                // onhandleAutoCompleteChange={this.handleAutoCompleteChange.bind(this)}
                onhandleUserPickerClick={this.handleUserPickerClick.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}

                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleUploadConfirm={this.handleUploadConfirm.bind(this)}
                onhandleDeleteFile={this.handleDeleteFile.bind(this)}
                onhandlePreview={this.handlePreview.bind(this)}

                schemeCatList={getOptionList(this.props.inspectionDict, INSPECTION_DICT_TYPES.SCHEMECAT)}
            />
            <FilePreviewer
                open={previewFile.open}
                fileType={previewFile.fileType}
                filePath={previewFile.fileURL}
                errorComponent={null}
                onError={null}
                onClose={this.handleCloseReviewer}
                onDownload={this.handleDownload.bind(this)}
            />
        </>);
    }
}

const getCompanyLeader = (companyList, userList, currentHazardBackup) => {
    const mainCompany = _.find(companyList, function (o) { return !o.pid })
    return userList.filter((user) => (user.companyId === currentHazardBackup.companyId || currentHazardBackup.companyId === mainCompany.companyId))
}

const mapStateToProps = (state) => {
    return {
        open: state.inspectionTreatmentScheme.open,
        // editable: true,
        // isTreatmentHead: false,
        editable: state.inspectionTreatmentScheme.editable,
        isTreatmentHead: state.inspectionTreatmentScheme.isTreatmentHead,
        constant: state.constant,
        companyList: getActualCompany(state.constant.companies),
        departmentList: getManageDpt(state.constant.departments),
        currentHazardBackup: state.hazard.currentHazardBackup,
        companyLeaders: getCompanyLeader(getActualCompany(state.constant.companies), state.user.leaders, state.hazard.currentHazardBackup),
        users: state.user.leaders,
        userProfile: state.auth.userProfile,
        inspectionDict: state.constant.inspectionDictionaries,
        treatmentScheme: state.inspectionTreatmentScheme.treatmentScheme,
        confirmCallback: state.inspectionTreatmentScheme.confirmCallback,
        closeCallback: state.inspectionTreatmentScheme.closeCallback,
        selectedUsers: state.userSelect.selectedUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (treatmentScheme) => {
            dispatch(changeTreatmentScheme(treatmentScheme));
        },
        onhandleClose: () => {
            dispatch(closeTreatmentSchemePannel());
        },
        onhandleGetLeaderList: (query) => {
            // 无必要限制管理组成员
            // dispatch(getAllLeaders(query))
            dispatch(getUsers(query))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
        onhandleGetListTypeList: () => {
            dispatch(getListTypeList())
        },
        onhandleGetCategoryList: () => {
            dispatch(getCategoryList())
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionTreatmentSchemePannelContainer);