import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccessoryReplaceRecord from '../components/AccessoryReplaceRecord';
import {
  initAccessoryReplaceRecordList
} from '../redux/modules/accessoryReplaceRecord';
import { cleanmodal } from '../redux/modules/modal';

class AccessoryReplaceRecordContainer extends Component {
  static propTypes = {
    recordList: PropTypes.array,
  }

  componentWillMount() {
    this.props.onhandleInitAccessoryReplaceRecordList();
  }

  render() {
    return (
      <AccessoryReplaceRecord
        recordList={this.props.recordList}
        onhandleCleanmodal={this.props.onhandleCleanmodal.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recordList: state.accessoryReplaceRecord.recordList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitAccessoryReplaceRecordList: () => {
      dispatch(initAccessoryReplaceRecordList());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessoryReplaceRecordContainer);
