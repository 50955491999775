import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceVehicleInfo from '../../../components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceVehicleInfo';
import {
    backToList,
    setDeviceVehicleInfoChange,
    setDeviceVehicleInfo,
    setDeviceVehicleInfoError,
    initDeviceVehicleInfo,
    insertDeviceVehicleInfo,
    modifyDeviceVehicleInfo
} from "../../../redux/modules/devices";
import { download, downloadURL } from '../../../redux/modules/minio';
import * as _ from 'lodash';
import PropTypes from "prop-types";
import { FilePreviewer } from '@/components/SimpleComponents';

class DeviceVehicleInfoContainer extends Component {

    static propTypes = {
        deviceVehicleInfoError: PropTypes.object,
        deviceVehicleInfoChange: PropTypes.bool,
        deviceVehicleInfo: PropTypes.object,
        isView: PropTypes.bool,
        currentDevice: PropTypes.object

    };

    constructor(props) {
        super(props);
        this.state = {
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            },
            docId: ''
        };

    };



    componentDidMount() {
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceVehicleInfo();
        }
    }

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.deviceVehicleInfoError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleDeviceVehicleInfoChange(false);
            if (this.props.deviceVehicleInfo.infoId) {
                this.props.onhandleUpdateDeviceVehicleInfo(this.props.deviceVehicleInfo);
            } else {
                this.props.onhandleCreateDeviceVehicleInfo(this.props.deviceVehicleInfo);
            }
        }
    }

    handleChange = (event, field) => {
        let newDevice;
        switch (field) {
            case 'vtype':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vtype: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'engineCode':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    engineCode: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'frameCode':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    frameCode: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'vweight':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vweight: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'vheight':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vheight: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'vlength':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vlength: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'vwidth':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vwidth: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'vcolor':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vcolor: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'loadNo':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    loadNo: _.toInteger(event.target.value)
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'licenseNo':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    licenseNo: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'emissionLevel':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    emissionLevel: event.target.value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'insCycle':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    insCycle: _.toInteger(event.target.value)
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleDateChange = (value, field) => {
        let newDevice;
        switch (field) {
            case 'buyTime':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    buyTime: value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            case 'registTime':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    registTime: value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            default:
            // do nothing
        }
    };

    handleSelectChange = (value, field) => {
        let newDevice;
        switch (field) {
            case 'vphoto':
                newDevice = {
                    ...this.props.deviceVehicleInfo,
                    vphoto: value
                };
                this.props.onhandleDeviceVehicleInfoChange(true);
                this.props.onhandleUpdateEditDeviceVehicleInfo(newDevice);
                break;
            default:
            // do nothing
        }
    };


    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handleCheck = (event, field) => {

        let err = {};

        if (!field || field === 'licenseNo') {
            if (_.trim(this.props.deviceVehicleInfo.licenseNo) === '') {
                err['licenseNo'] = '行驶证号码不能为空';
            } else {
                err['licenseNo'] = '';
            }
        }

        if (!field || field === 'engineCode') {
            if (_.trim(this.props.deviceVehicleInfo.engineCode) === '') {
                err['engineCode'] = '发动机号不能为空';
            } else {
                err['engineCode'] = '';
            }
        }

        if (!field || field === 'frameCode') {
            if (_.trim(this.props.deviceVehicleInfo.frameCode) === '') {
                err['frameCode'] = '车架号不能为空';
            } else {
                err['frameCode'] = '';
            }
        }

        let newError = {
            ...this.props.deviceVehicleInfoError,
            ...err
        }
        this.props.onhandleSetError(newError);
    };

    transformDepartmentList() {
        let list = _.map(this.props.departmentList, d => {
            let label = d.dptName
            let value = d.dptId
            return {
                label: label,
                value: value,
            }
        })
        return list
    }

    handleDownload = () => {
        if (this.state.docId) {
            let tenantId = this.props.userProfile.roles[0].tenantId
            let envCode = 'uat';
            if (window.location.host.indexOf('3050') > -1) {
                envCode = 'dev';
            } else if (window.location.host.indexOf('3060') > -1) {
                envCode = 'uat';
            } else if (process.env.NODE_ENV === 'production') {
                envCode = 'prod';
            }
            let mybucket = _.replace(envCode + '.{tenant_id}.device.devicedoc', '{tenant_id}', tenantId);
            let doc = _.find(this.props.deviceDoc, ['docId', this.state.docId.value])
            this.props.onhandleDownload(doc.docLink, mybucket);
        }
    }

    async handlePreview(docId) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        let envCode = 'uat';
        if (docId) {
            if (window.location.host.indexOf('3050') > -1) {
                envCode = 'dev';
            } else if (window.location.host.indexOf('3060') > -1) {
                envCode = 'uat';
            } else if (process.env.NODE_ENV === 'production') {
                envCode = 'prod';
            }
            let mybucket = _.replace(envCode + '.{tenant_id}.device.devicedoc', '{tenant_id}', tenantId);
            let doc = _.find(this.props.deviceDoc, ['docId', docId.value])
            return this.props.onhandleDownloadUrl(doc.docLink, mybucket).then(res => {
                if (res && res.payload) {
                    console.log(' res.payload', res.payload);
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: doc.docLink.split('.')[1]
                        },
                        docId: doc.docId
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
        }
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    render() {
        const { previewFile } = this.state;
        return (<>
            <DeviceVehicleInfo
                onhandleSave={this.handleSave.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                deviceVehicleInfo={this.props.deviceVehicleInfo}
                deviceVehicleInfoError={this.props.deviceVehicleInfoError}
                onhandleSelectChange={this.handleSelectChange.bind(this)}
                deviceVehicleInfoChange={this.props.deviceVehicleInfoChange}
                isView={this.props.isview}
                deviceDoc={this.props.deviceDoc}
                isDeviceEdit={this.props.isDeviceEdit}
                userProfile={this.props.userProfile}
                tabValue={this.props.tabValue}
                index={this.props.index}
                onhandleDownload={this.handlePreview.bind(this)}
            />
            <FilePreviewer
                open={previewFile.open}
                fileType={previewFile.fileType}
                filePath={previewFile.fileURL}
                errorComponent={null}
                onError={null}
                onClose={this.handleCloseReviewer}
                onDownload={this.handleDownload.bind(this)}
            />
        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        deviceVehicleInfoChange: state.devices.deviceVehicleInfoChange,
        deviceVehicleInfo: state.devices.deviceVehicleInfo,
        deviceVehicleInfoError: state.devices.deviceVehicleInfoError,
        isview: state.devices.view,
        currentDevice: state.devices.currentDevice,
        userProfile: state.auth.userProfile,
        isDeviceEdit: state.devices.deviceEdit,
        deviceDoc: state.devices.deviceDoc,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateDeviceVehicleInfo: (entity) => {
            dispatch(insertDeviceVehicleInfo(entity))
        },
        onhandleUpdateDeviceVehicleInfo: (entity) => {
            dispatch(modifyDeviceVehicleInfo(entity))
        },
        onhandleUpdateEditDeviceVehicleInfo: (entity) => {
            dispatch(setDeviceVehicleInfo(entity))
        },
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleSetError: (err) => {
            dispatch(setDeviceVehicleInfoError(err))
        },
        onhandleDeviceVehicleInfoChange: (isChanged) => {
            dispatch(setDeviceVehicleInfoChange(isChanged))
        },
        onhandleInitDeviceVehicleInfo: () => {
            dispatch(initDeviceVehicleInfo());
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceVehicleInfoContainer)
