import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Button,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { Table } from 'antd';
import { Visibility } from '@material-ui/icons';

import { getDateByTimestamp, getDateByString } from '../../../utils/datetime';

const DeviceOpsRecord = props => {
    const {
        classes,
        oriListData,
        initListData,
        // pageSize,
        // setPageSize,
        // getDeviceOpsRecordList,
        isLoading,
        parameters,
        onhandleCleanmodal,
        onhandlPreviewDoc
    } = props;

    // const [selectedRow, setSelectedRow] = useState(null);
    // const [page, setPage] = useState(0);

    const renderTime = (time) => {
        return time ? getDateByTimestamp(time, false) : null;
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '单位',
                dataIndex: 'companyName',
                render: (text, row, index) => row ? <Typography>{row.companyName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '装置编号',
                dataIndex: 'gbInstallId',
                render: (text, row, index) => row && row.installCode ? <Typography>{row.installCode}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '装置名称',
                dataIndex: 'installName',
                render: (text, row, index) => row && row.installName ? < Typography > {row.installName}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '设备编号',
                dataIndex: 'deviceNo',
                render: (text, row, index) => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备位号',
                dataIndex: 'deviceTagNo',
                render: (text, row, index) => row ? <Typography>{row.deviceTagNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                render: (text, row, index) => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '预算合计(元)',
                dataIndex: 'totalOrderBudget',
                render: (text, row, index) => row ? <Typography>{row.totalOrderBudget}</Typography> : '',
                editable: 'never'
            },
            {
                title: '决算合计(元)',
                dataIndex: 'totalOrderCost',
                render: (text, row, index) => row ? <Typography>{row.totalOrderCost}</Typography> : '',
                editable: 'never'
            },
        ]

        return columns
    }

    const renderActions = (row) => {
        if (row && row.planDocs && row.planDocs.length > 0) {
            if (row.planDocs[0].docName && row.planDocs[0].docLink) {
                return <Grid container>
                    <Grid item>
                        <Tooltip title='预览'><IconButton variant='text' onClick={() => onhandlPreviewDoc(row.planDocs)}> <Visibility /></IconButton></Tooltip></Grid>
                </Grid>
            }
        }
        return <Grid></Grid>

    }

    const assemblyRelationOpsRecordColumns = () => {

        let columns = [
            {
                title: '工单号',
                dataIndex: 'orderNo',
                render: (text, row, index) => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never'
            },

            {
                title: '运维预算(元)',
                field: 'orderBudget',
                render: (text, row, index) => row && row.orderBudget ? <Typography>{row.orderBudget}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '运维决算(元)',
                field: 'orderCost',
                render: (text, row, index) => row && row.orderCost ? <Typography>{row.orderCost}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '开始时间',
                field: 'startTime',
                render: (text, row, index) => row ? <Typography>{renderTime(row.startTime)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '结束时间',
                field: 'endTime',
                render: (text, row, index) => row ? <Typography>{renderTime(row.endTime)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '工单内容',
                field: 'content',
                render: (text, row, index) => row ? <Typography>{row.content}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维内容',
                field: 'opentext',
                render: (text, row, index) => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '故障原因',
                field: 'opentext1',
                render: (text, row, index) => row ? <Typography>{row.opentext1}</Typography> : '',
                editable: 'never'
            },
            {
                title: '避免措施',
                field: 'opentext2',
                render: (text, row, index) => row ? <Typography>{row.opentext2}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检测结果',
                field: 'executor',
                render: (text, row, index) => row && row.maintainResult ?
                    <Tooltip title={getMaintainResDate(row)}>
                        <Typography>{getMaintainResult(row.maintainResult)}</Typography>
                    </Tooltip >
                    :
                    '-',
                editable: 'never'
            },
            {
                title: '承包商',
                field: 'executor',
                render: (text, row, index) => row && row.executor ? <Typography>{row.executor}</Typography> : '-',
                editable: 'never'
            }, {
                title: '',
                dataIndex: 'actions',
                width: 50,
                render: (text, row, index) => renderActions(row),
            }

        ]

        return columns
    }

    const getMaintainResult = (maintainResult) => {
        if (maintainResult === "qualified") {
            return "合格"
        }
        if (maintainResult === "unqualified") {
            return "不合格"
        }
    }

    const getMaintainResDate = ({ maintainDate, nextMaintainDate }) => {
        if (!maintainDate && !nextMaintainDate) {
            return '长期有效';
        }
        if (maintainDate && nextMaintainDate) {
            return getDateByString(maintainDate) + ' 至 ' + getDateByString(nextMaintainDate) + ' 有效';
        }
        if (!maintainDate && nextMaintainDate) {
            return '结果于' + getDateByString(maintainDate) + '检测';
        }
        if (!maintainDate && nextMaintainDate) {
            return '结果于' + getDateByString(maintainDate) + '失效';
        }

    }

    const renderItems = (inData) => (
        <Table
            rowKey={'orderNo'}
            columns={assemblyRelationOpsRecordColumns()}
            dataSource={inData.opsRcords ? inData.opsRcords : []}
            pagination={false}
            size={'small'}
        />
    );

    // const onhandlePageSizeChange = (pageSize, current) => {
    //     setPageSize(pageSize);
    //     setPage(0);
    //     // 这里会触发pageChange事件
    // }

    // const onhandlePageChange = (current) => {
    //     const page = current - 1;
    //     setPage(page);
    //     getDeviceOpsRecordList({
    //         offset: page * (pageSize ? pageSize : 5)
    //     })
    // }

    const renderItemsForPopup = (inData) => (

        < React.Fragment >
            <Grid>
                <Table
                    className={classes.stripedTable}
                    rowKey={'gbDeviceId'}
                    columns={assemblyRelationOpsRecordColumns()}
                    dataSource={inData.list ? inData.list : []}
                    size={'small'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        // showTotal: () => `共${inData.total}条`,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        // pageSize: pageSize,
                        // current: page + 1,
                        // total: inData.total,
                        // onShowSizeChange: (current, pageSize) => onhandlePageSizeChange(pageSize, current),
                        // onChange: current => onhandlePageChange(current)
                    }}
                />
            </Grid>
        </React.Fragment >
    );

    const expandedRowRender = (record, index) => {
        return renderItems(record);
    }

    const getRowClass = (record, index) => {
        return (index % 2 !== 0 ? classes.oddRow : null);
    }

    return (
        <div alignitems="center">
            {isLoading ? null :
                /* 
                    如果不是Popup画面调用：把设备分组显示数据；
                    如果是Popup画面调用：直接显示明细数据 
                */
                !(parameters && parameters.deviceId) ?
                    <Grid className={classes.detailtable}>
                        <Table
                            className={classes.normalTable}
                            rowClassName={getRowClass}
                            rowKey={'gbDeviceId'}
                            columns={assemblycolumns()}
                            dataSource={initListData.list}
                            expandable={{ expandedRowRender }}
                            size={'small'}
                            scroll={{ y: 'calc(100vh - 260px)' }}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                // showTotal: () => `共${initListData.total}条`,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                // pageSize: pageSize,
                                // current: page + 1,
                                // total: initListData.total,
                                // onShowSizeChange: (current, pageSize) => onhandlePageSizeChange(pageSize, current),
                                // onChange: current => onhandlePageChange(current)
                            }}
                        />
                    </Grid>
                    :
                    <div>
                        <Grid className={classes.detailtable}>
                            {renderItemsForPopup(oriListData)}
                        </Grid>
                        <Grid container spacing={0} className={classes.gridContainerForPopup} justify="flex-end">
                            {/* <Button variant="contained" color="primary"
                                onClick={event => onhandleConfirm()}
                            >
                                确定
                                </Button> */}
                            <Button variant="outlined" color="primary"
                                onClick={event => onhandleCleanmodal()}
                            >
                                关闭
                            </Button>
                        </Grid>
                    </div>
            }
        </div >
    );
};

DeviceOpsRecord.propTypes = {
    classes: PropTypes.object.isRequired,
    oriListData: PropTypes.object.isRequired,
    initListData: PropTypes.object.isRequired,
    // pageSize: PropTypes.number.isRequired,
    // setPageSize: PropTypes.func.isRequired,
    // getDeviceOpsRecordList: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    parameters: PropTypes.object.isRequired,
    onhandleCleanmodal: PropTypes.func.isRequired
};

export default withStyles(DeviceOpsRecord);
