import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { formatDate_hms } from "../../../../utils/datetime";

import _ from "lodash"

const CreateTeamActivityPlan = ({
    classes,
    maps,
    companies,
}) => {

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    const getCompanyName = (companyId) => {
        const c = _.find(companies, function (o) { return o.companyId === companyId })
        if (c) return c.companyName
        return companyId
    }

    const assemblycolumns = () => {

        return [
            {
                title: '机关领导',
                field: 'firstName',
                render: row => <Typography>{row.lastName + row.firstName}</Typography>
            },
            {
                title: '分厂',
                field: 'companyId',
                render: row => <Typography>{getCompanyName(row.companyId)}</Typography>
            }, {
                title: '修改时间',
                field: 'updateTime',
                render: row => <Typography>{formatDate_hms(row.updateTime)}</Typography>
            }
        ]
    }

    const assemblyData = () => {
        return maps
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid container >
                <Grid item>
                    <Grid className={classes.action}>
                        <Typography variant='h3'>班组活动规则</Typography>
                    </Grid>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.action}>
                            <Typography variant='h5'>机关领导参加班组安全活动频率： 1次/季度</Typography>
                        </Grid>
                        <Grid item className={classes.action}>
                            <Typography variant='h5'>班组安全活动频率：2次/月</Typography>
                        </Grid>
                        <Grid item className={classes.action}>
                            <Typography variant='h5'>活动开展时间：第一周、第三周的周二</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <MaterialTable
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={tableStyle}
                    title={<Typography variant='h3'>机关领导对口分厂</Typography>}
                >
                </MaterialTable>
            </Grid>
        </div>
    );
};
CreateTeamActivityPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

CreateTeamActivityPlan.defaultProps = {
    maps: []
}

export default (withStyles(CreateTeamActivityPlan))