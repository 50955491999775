import React, { Component } from 'react';
import { connect } from 'react-redux';
import OperationPlanDocPanel from '@/components/DeviceManagement/OperationPlanDocs/OperationPlanDocPanel';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getAppendixParseResult } from '@/redux/modules/operationPlanDocs';
// import { getOperationTypeDocClass } from '@/utils/operationUtils'
import _ from 'lodash';

class OperationPlanDocPanelContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docError: {
                docDesc: '',
                maintainResult: '',
                maintainDate: null,
                nextMaintainDate: null,
                file: '',
            },
            open: false,
            doc: {},
            confirmCallback: null,
            closeCallback: null,
        };
    }
    componentDidUpdate = (oldProps) => {
        if (oldProps.open !== this.props.open) {
            const { open, docRecord, confirmCallback, closeCallback } = this.props;

            var docCate = 'OTHER_DOC';
            if (docRecord.orderTypeConfig && docRecord.orderTypeConfig.needTest === 1) {
                var testDoc = _.find(docRecord.operationPlanDocs, { 'docCate': 'TEST_DOC' })
                if (!testDoc) {
                    docCate = 'TEST_DOC'
                }
            } else {
                var maintainDoc = _.find(docRecord.operationPlanDocs, { 'docCate': 'MAINTAIN_DOC' })
                if (!maintainDoc) {
                    docCate = 'MAINTAIN_DOC'
                }
            }

            this.setState({
                docError: {
                    docDesc: '',
                    maintainResult: '',
                    maintainDate: null,
                    nextMaintainDate: null,
                    file: '',
                },
                open: open,
                orderTypeConfig: docRecord.orderTypeConfig,
                operationPlanDocs: docRecord.operationPlanDocs,
                doc: { docCate: docCate },
                confirmCallback: confirmCallback,
                closeCallback: closeCallback,
            })
        }
    }

    handleConfirm() {
        const { confirmCallback, doc } = this.state;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(doc)
            // if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { closeCallBack } = this.props;
        // close action
        if (closeCallBack) {
            closeCallBack()
        } else {
            this.setState({
                open: false
            })
        }

    }

    handleChange(event, field) {
        let newDoc = {
            ...this.state.doc
        }
        // const field = event.target.name;
        newDoc[field] = event.target.value

        if (field === 'skipDoc') {
            newDoc[field] = event.target.checked
        }

        this.setState({
            doc: newDoc
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleDateChange = (value, field) => {
        let newDoc = {
            ...this.state.doc
        }
        newDoc[field] = value;

        this.setState({
            doc: newDoc
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    };

    handleUploadConfirm(files, setUploadStatus) {
        
        this.props.createloading();
        let newDoc = {
            ...this.state.doc
        }
        newDoc.file = files; 
        let query = {
            // 'keys': ['介质', '下次检测时间'],
            'keys': ['maintainDate', 'nextMaintainDate'],
            'fileName': files.length > 0 ? files[0].name : ''
        };
        console.log(query);
        this.props.onGetAppendixParseResult(query).then((res) =>{ 
            if(res && res.payload && res.payload.data){
                let maintainDate = "";
                let nextMaintainDate = "";
                let obj = res.payload.data;
                for(var i =0; i< obj.length; i++)
                {                
                    if(obj[i].parseKey === "maintainDate")   maintainDate = obj[i].parseResult;
                    if(obj[i].parseKey === "nextMaintainDate")   nextMaintainDate = obj[i].parseResult;                    
                }
                newDoc.maintainDate = maintainDate;
                newDoc.nextMaintainDate = nextMaintainDate;
                this.setState({
                    doc: newDoc
                })     
            }

            this.props.cleanloading();

           
        });

        this.setState({
            doc: newDoc
        })
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
        setTimeout(() => {
            this.handleCheck(null, 'file');
        }, 100)
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleCheck = (event, field) => {

        const { doc } = this.state

        let err = {};

        if (!doc.docCate) {
            err['docCate'] = '请选择报告类型';
        }

        if (doc.docCate === 'TEST_DOC') {
            if (!field || field === 'maintainResult') {
                if (_.trim(doc.maintainResult) === '') {
                    err['maintainResult'] = '检测结果不能为空';
                } else {
                    err['maintainResult'] = '';
                }
            }
            if (!field || field === 'testDate') {
                if (!doc.testDate) {
                    err['testDate'] = '本次检测时间不能为空';
                } else {
                    err['testDate'] = '';
                }
            }

            if (!field || field === 'nextTestDate') {
                if (!doc.nextTestDate) {
                    err['nextTestDate'] = '下次检测时间不能为空';
                } else {
                    err['nextTestDate'] = '';
                }
            }

            if (!field) {
                if (doc.testDate >= doc.nextTestDate) {
                    err['nextTestDate'] = '下次检测时间不能早于检测时间';
                } else {
                    err['nextTestDate'] = '';
                }
            }
        } else if (doc.docCate === 'MAINTAIN_DOC') {

        }

        if (!field || field === 'docDesc') {
            if (_.trim(this.state.doc.docDesc) === '') {
                err['docDesc'] = '描述不能为空';
            } else {
                err['docDesc'] = '';
            }
        }

        if (!this.state.doc.skipDoc) {
            if (!field || field === 'file') {
                // 检测文件是否上传
                if (!this.state.doc.file || !this.state.doc.file.length || this.state.doc.file.length === 0
                ) {
                    err['file'] = '请上传文档';
                } else {
                    err['file'] = '';
                }
            }
        }

        let newError = {
            ...this.state.docError,
            ...err
        }
        this.setState({
            docError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o && o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <OperationPlanDocPanel
                open={this.props.open}
                doc={this.state.doc}
                orderTypeConfig={this.state.orderTypeConfig}
                operationPlanDocs={this.state.operationPlanDocs}
                docRecord={this.props.docRecord}
                docError={this.state.docError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
        onGetAppendixParseResult: (query) => {
            return dispatch(getAppendixParseResult(query))
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanDocPanelContainer);