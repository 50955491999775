import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    Select,
    Card,
    CardContent,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import withStyles from './styles';


const TargetSelect = ({
    classes,
    open,
    onhandleClose,
    onhandleUpdate,
    onhandleSelectChange,
    installationList,
    installationAreaList,
    installationUnitList,
    installId,
    areaId,
    unitId,
    target,
}) => {
    const installOptions = () => (
        <React.Fragment>
            {installationList ?
                installationList.map(data => (
                    <option value={data.installId} key={data.installId}>{data.installName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const areaOptions = () => (
        <React.Fragment>
            {installationAreaList ?
                installationAreaList.map(data => (
                    <option value={data.areaId} key={data.areaId}>{data.areaName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const unitOptions = () => (
        <React.Fragment>
            {installationUnitList ?
                installationUnitList.map(data => (
                    <option value={data.unitId} key={data.unitId}>{data.unitName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const checkDisabled = () => {
        let rtn = true;
        switch (target) {
            case 'INST':
                if (installId && installId !== null) {
                    rtn = false
                }
                break;
            case 'UNIT':
                if (unitId && unitId !== null) {
                    rtn = false
                }
                break;
            case 'AREA':
                if (areaId && areaId !== null) {
                    rtn = false
                }
                break;
            default:
        }

        return rtn
    }


    return (
        <div alignitems="center" >
            <Dialog
                open={open}
                onClose={onhandleClose}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
                fullWidth={true}
                maxWidth={'sm'}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="title">
                    <Typography className={classes.title} component={'span'} variant='h3'>重大危险源单位选择</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Card className={classes.root} spacing={1}>
                        <CardContent>
                            <Grid container alignItems="center" className={classes.gridContainer} >
                                <Grid item xs={3}>
                                    <Typography gutterBottom className={classes.label}>
                                        单元类别
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <RadioGroup direction="column" aria-label="单元类别" name="targetRadio"
                                        value={target}
                                        onChange={event => onhandleSelectChange(event, 'target')} style={{ width: '100%' }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="INST" control={<Radio />} label="装置" />
                                                <FormControlLabel value="UNIT" control={<Radio />} label="单元" />
                                                <FormControlLabel value="AREA" control={<Radio />} label="区域" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography gutterBottom className={classes.label}>
                                        装置
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        style={{ width: '90%' }}
                                        native
                                        value={installId}
                                        onChange={(event) => onhandleSelectChange(event, 'installId')}
                                    >
                                        <option aria-label="None" value="" />
                                        {installOptions()}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography gutterBottom className={classes.label}>
                                        单元
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        disabled={!installId || target !== 'UNIT'}
                                        style={{ width: '90%' }}
                                        native
                                        value={unitId}
                                        onChange={(event) => onhandleSelectChange(event, 'unitId')}
                                    >
                                        <option aria-label="None" value="" />
                                        {unitOptions()}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography gutterBottom className={classes.label}>
                                        区域
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        disabled={!installId || target !== 'AREA'}
                                        style={{ width: '90%' }}
                                        native
                                        value={areaId}
                                        onChange={(event) => onhandleSelectChange(event, 'areaId')}
                                    >
                                        <option aria-label="None" value="" />
                                        {areaOptions()}
                                    </Select>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={onhandleUpdate}
                        disabled={checkDisabled()}>
                        保存
                    </Button>
                    <Button variant="text" onClick={onhandleClose}>
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div >

    );
}

TargetSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};
TargetSelect.defaultProps = {

}


export default withStyles(TargetSelect);