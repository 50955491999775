import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OnsiteInspectionStatistics from '../components/OnsiteInspectionStatistics';
import {
  initCurrentData,
  setIntervalDate,
  getDeviceIntervalRecords
} from '../redux/modules/onsiteInspectionStatistics';
import { getTreePartById } from '../utils/constant';
import { getCompanyId } from "../utils/userUtils";
import { cleanmodal } from '../redux/modules/modal';
import * as _ from 'lodash';
import { getMoment } from '../utils/datetime'


class OnsiteInspectionStatisticsContainer extends Component {
  static propTypes = {
    deviceList: PropTypes.object,
    search: PropTypes.object,
    catTree: PropTypes.array,
    catList: PropTypes.array,
    useStatusTree: PropTypes.array,
    procatTree: PropTypes.array,
    procatList: PropTypes.array,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    userProfile: PropTypes.object,
    companyTree: PropTypes.array,
    companyList: PropTypes.array,
    currentUserInfo: PropTypes.object,
    installationList: PropTypes.array,
  }

  componentWillMount() {
    this.props.onhandleInitCurrentData(this.props.parameters.deviceId);
  }

  getLineOption = (data) => {
    let max = 0;
    let series = [];
    if (data.stdVal.indexOf(',') > -1) {
      max = data.stdVal.split(',')[1];
      max = Math.ceil(max * 3 / 2)
      if (max < data.y.reverse()) {
        max = Math.ceil(data.y.reverse() * 3 / 2)
      }
      series = [
        {
          name: data.param,
          type: 'line',
          data: data.y.reverse(),
          markLine: {
            data: [
              {
                label: {
                  formatter: "标准值(" + data.stdVal.split(',')[0] + ")",
                },
                yAxis: data.stdVal.split(',')[0]
              },
              {
                label: {
                  formatter: "标准值(" + data.stdVal.split(',')[1] + ")",
                },
                yAxis: data.stdVal.split(',')[1]
              }
            ]
          }
        }
      ]
    } else {
      max = Math.ceil(data.stdVal * 3 / 2);
      if (max < data.y.reverse()) {
        max = Math.ceil(data.y.reverse() * 3 / 2)
      } else {
        max = Math.ceil(max * 3 / 2)
      }
      series = [
        {
          name: data.param,
          type: 'line',
          data: data.y.reverse(),
          markLine: {
            data: [
              {
                label: {
                  formatter: "标准值(" + data.stdVal + ")"
                },
                yAxis: data.stdVal
              }
            ]
          }
        }
      ]
    }

    let option = {
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: data.x.reverse()
      },
      yAxis: {
        type: 'value',
        max: max,
        axisLabel: {
          formatter: '{value}' + this.getUnit(data.unit)
        }
      },
      series: series
    };

    return option
  }

  getGaugeOption = (data) => {
    let max = 0;
    let detail = { formatter: '{value}', color: '#111', textStyle: { fontSize: 15 } }
    if (data.stdVal.indexOf(',') > -1) {
      max = data.stdVal.split(',')[1];
      max = Math.ceil(max * 3 / 2)
      if (max < data.val) {
        max = Math.ceil(data.val * 3 / 2)
      }
      if (data.val < data.stdVal.split(',')[0] || data.val > data.stdVal.split(',')[1]) {
        detail = { formatter: '{value}', color: '#c23531', textStyle: { fontSize: 15 } }
      }
    } else {
      max = Math.ceil(data.stdVal * 3 / 2);
      if (max < data.val) {
        max = Math.ceil(data.val * 3 / 2)
      } else {
        max = Math.ceil(max * 3 / 2)
      }
      if (data.stdVal !== data.val) {
        detail = { formatter: '{value}', color: '#c23531', textStyle: { fontSize: 15 } }
      }
    }

    let unitName = this.getUnit(data.unit)
    let option = {
      title: {
        text: data.paramName + '(' + getMoment()(data.updateTime).format('DD日HH时mm分') + ')',
        textStyle: { fontSize: 15, fontWeight: 'normal' }
      },
      tooltip: {
        formatter: '{a} : {c} {b}'
      },
      series: [
        {
          name: data.paramName,
          type: 'gauge',
          min: 0,
          max: max,

          detail: detail,
          data: [{ value: data.val, name: unitName }]
        }
      ]
    };
    return option
  }

  getUnit = (code) => {
    let label = '';
    let value = _.find(this.props.unit, function (o) { return o.code === code })
    if (value) {
      label = value.name
    }

    return label;
  }

  handleChange = (event, field) => {
    let newData;
    switch (field) {
      case 'view':
        newData = {
          ...this.props.intervalDate,
          view: event.target.value
        };
        this.props.onhandleUpdateIntervalDate(newData);
        this.props.onhandleGetIntervalData()
        break;
      case 'month':
        newData = {
          ...this.props.intervalDate,
          month: event
        };
        this.props.onhandleUpdateIntervalDate(newData);
        break;
      case 'week':
        newData = {
          ...this.props.intervalDate,
          weekStart: event.start,
          weekEnd: event.end
        };
        this.props.onhandleUpdateIntervalDate(newData);
        break;
      case 'startDay':
        newData = {
          ...this.props.intervalDate,
          startDay: getMoment()(event.target.value)
        };
        this.props.onhandleUpdateIntervalDate(newData);
        this.props.onhandleGetIntervalData()
        break;
      case 'endDay':
        newData = {
          ...this.props.intervalDate,
          endDay: getMoment()(event.target.value)
        };
        this.props.onhandleUpdateIntervalDate(newData);
        this.props.onhandleGetIntervalData()
        break;
      case 'day':
        newData = {
          ...this.props.intervalDate,
          day: event
        };
        this.props.onhandleUpdateIntervalDate(newData);
        this.props.onhandleGetIntervalData()
        break;
      default:
      // do nothing
    }
  };

  trimCompanyTree() {
    let companyId = null
    if (this.props.parameters.companyId) {
      companyId = this.props.parameters.companyId
    } else {
      companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    }
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <OnsiteInspectionStatistics
        parameters={this.props.parameters}

        unit={this.props.unit}
        currentData={this.props.currentData}
        currentDescription={this.props.currentDescription}
        currentDevice={this.props.currentDevice}
        catList={this.props.catList}
        useStatusTree={this.props.useStatusTree}
        intervalData={this.props.intervalData}
        intervalDescription={this.props.intervalDescription}
        intervalDate={this.props.intervalDate}
        onhandleChange={this.handleChange.bind(this)}
        onhandleCleanmodal={this.props.onhandleCleanmodal.bind(this)}
        onhandleGetIntervalData={this.props.onhandleGetIntervalData.bind(this)}

        getGaugeOption={this.getGaugeOption.bind(this)}
        getLineOption={this.getLineOption.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parameters: state.modal.parameters,
    companyTree: state.constant.companyTree,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    currentData: state.onsiteInspectionStatistics.currentData,
    currentDescription: state.onsiteInspectionStatistics.currentDescription,
    // 当前设备基本信息
    currentDevice: state.onsiteInspectionStatistics.currentDevice,
    catList: state.trees.deviceCategoryList,
    useStatusTree: state.devices.useStatusTree,

    intervalData: state.onsiteInspectionStatistics.intervalData,
    intervalDescription: state.onsiteInspectionStatistics.intervalDescription,
    intervalDate: state.onsiteInspectionStatistics.intervalDate,
    unit: state.onsiteInspectionStatistics.unit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitCurrentData: (deviceId) => {
      dispatch(initCurrentData(deviceId));
    },
    onhandleUpdateIntervalDate: (search) => {
      dispatch(setIntervalDate(search));
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
    onhandleGetIntervalData: (deviceId) => {
      dispatch(getDeviceIntervalRecords(deviceId));
    },

  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnsiteInspectionStatisticsContainer);
