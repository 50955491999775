import React, { Component } from 'react';
import "./index.css";
import * as _ from 'lodash';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Label from '@material-ui/icons/Label';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class LeftSlideBarView extends Component {
  constructor(props) {
    super(props)
    this._createDialog = this._createDialog.bind(this);
  }

  renderDeviceTree = (nodes) => {

    return (
      <TreeItem key={nodes.value} nodeId={nodes.value} onIconClick={() => this.onLoadData(nodes)}
        label={(_.startsWith(nodes.value, 'D') || nodes.value === 'existDevice') ?
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.onLoadData(nodes)} title={nodes.label}>
            {nodes.loading ? <CircularProgress color="inherit" style={{ width: '24px', height: '24px' }} /> : <Label color="inherit" />}
            <Typography variant="body2" color="inherit">
              {nodes.label}
            </Typography>
            {nodes.total ?
              <Typography variant="caption" color="inherit">
                ({nodes.total})
                        </Typography>
              : null}
          </div>
          :
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.onSelectExistDevice(nodes)} title={nodes.label}>
            <SettingsApplicationsIcon color="inherit" />
            <Typography variant="body2" color="inherit">
              {nodes.label}
            </Typography>
          </div>
        }
      >
        {nodes.loading ? <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="inherit">
            {'loading...'}
          </Typography>
        </div>
          : Array.isArray(nodes.children) && nodes.children.length > 0 ? nodes.children.map((node) => this.renderDeviceTree(node)) : null}
      </TreeItem>
    )
  };

  showMain() {
    return <React.Fragment>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultExpanded={['existDevice']}
        style={{ width: '250px' }}
      >
        {this.renderDeviceTree(this.props.existTreeData)}
      </TreeView>
    </React.Fragment>
  }

  onLoadData = node => {
    if (node.value !== "existDevice" && !(node.total && node.total > 0)) {
      this.props.getNewTreeDeviceData(node.value, 'view');
    }
  }

  _createDialog(device) {
    const { createDialog } = this.props;
    createDialog('deviceInfo', device, true);
  }

  onSelectExistDevice = (device) => {
    let stage = this.props.getStage();
    let layer = stage.find('Layer')[0];
    layer.find('Group').forEach(group => {
      group.fire('mouseout')
    })
    let cat = device.cat;
    let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
    let targetGroup = layer.findOne('#' + type + '_' + device.deviceId);
    if (targetGroup) {
      targetGroup.fire('mouseover');
    }
  };

  render() {
    return (
      <div className="leftbar" style={{ height: this.props.height }}>
        {this.showMain()}
      </div>
    )
  }
}