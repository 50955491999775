import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    title: {
        padding: theme.spacing(1)
    },
    timeline: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    infocontainer: {
        padding: theme.spacing(2),
        flexDirection: 'column'
    },
    item: {
        paddingBottom: theme.spacing(2),
        minWidth: 300
    },
    datePicker: {
        marginTop: 0,
        marginBottom: 0
    },
    warnContainer: {
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
        marginBottom: 10,
        marginLeft: 10
    }

});


export default withStyles(styles);