import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  title: {
    padding: theme.spacing(2),
  },
  label: {
    whiteSpace: 'nowrap',
    width: 60,
    textAlign: 'right'
  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    width: 'calc(100% - 60px)'
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonGridContainer: {
    alignSelf: 'stretch',
  },
  gridItemSearchButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridItemTableButtons: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  gridItem: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  textField: {
    width: '25%'
  },
  detailtable: {
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  materialtable: {
    boxShadow: 'none'
  },
  menuIcon: {
    color: theme.palette.error.main
  },
});

export default withStyles(styles);