import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HazardClosePanel from '../../../components/InspectionManagement/HazardList/HazardClosePanel';
import { closeHazardClosePanel, changeHazard } from '@/redux/modules/inspection/inspectionHazardClose';

import _ from 'lodash';

class HazardClosePanelContainer extends Component {
    static propTypes = {
        hazard: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            hazardError: {
                'reason': ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose, hazard } = this.props;
        if (this.handleCheck(null, null)) {
            const to = {
                ...hazard
            }
            if (confirmCallback) confirmCallback(to)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, hazard } = this.props
        let newEntity = {
            ...hazard
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {

        const {
            hazard
        } = this.props;

        let err = {};

        if (!field || field === 'reason') {
            if (_.trim(hazard.reason) === '') {
                err['reason'] = '请填写销项理由';
            } else {
                err['reason'] = '';
            }
        }

        let newError = {
            ...this.state.hazardError,
            ...err
        }
        this.setState({
            hazardError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <HazardClosePanel
                open={this.props.open}
                hazard={this.props.hazard}
                hazardError={this.state.hazardError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.inspectionHazardClose.open,
        hazard: state.inspectionHazardClose.hazard,
        confirmCallback: state.inspectionHazardClose.confirmCallback,
        closeCallback: state.inspectionHazardClose.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (hazard) => {
            dispatch(changeHazard(hazard));
        },
        onhandleClose: () => {
            dispatch(closeHazardClosePanel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HazardClosePanelContainer);