import React from 'react';
import {
    Grid, Typography, TextField, MenuItem
} from '@material-ui/core';
import withStyles from '@/components/ManufactureManagement/AssayRecord/styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate_hms } from '@/utils/datetime';
import _ from 'lodash'

const AssayRecordComponent = props => {
    const { classes,
        records,
        companyList,
        installList,
        departments,
        onhandleChange,
        onhandleDateChange,
        tempId,
        temps,
        endDate,
        status,
        startDate,
        onhandleCreate,
        onhandleUpdate,
        onhandleDelete,
        userProfile,
        companyId,
        dptId,
        installId,
        onhandleSearch,
        onhandleClearSearch,
        onhandleExport,
        onhandleConfirm
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbarButtonAlignment: 'right',
        // tableLayout: 'fixed'
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'manufacture_assay_record' })
        return permission && permission.action === 'W'
    }

    const checkConfirmPermission = (f) => {
        if (f.status === 'active') {
            return userProfile.roles[0].departments[0].executable && userProfile.roles[0].roleLevel === 2
        } else {
            return false;
        }
    }

    const renderCompany = (row) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === row.companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDpt = (row) => {
        let dptName = ''
        if (row.dptId && departments && departments.length > 0) {
            let value = _.find(departments, function (o) { return o.dptId === row.dptId })
            if (value) {
                dptName = value.dptName
            }
        }
        return dptName
    }

    const renderInstall = (row) => {
        let installName = ''
        if (row.installId && installList && installList.length > 0) {
            let value = _.find(installList, function (o) { return o.installId === row.installId })
            if (value) {
                installName = value.installName
            }
        }
        return installName
    }

    const renderRecordDetail = (temp, row) => {
        var basicItems = _.filter(temp.items, function (o) { return o.itemType === 1 || o.itemType === 3 });
        var valItems = _.filter(temp.items, function (o) { return o.itemType === 2 });
        return <div className={classes.detailroot}>
            <Typography variant='subtitle1'>{temp.tempName}</Typography>
            <Grid container direction="row" spacing={0} className={classes.detailGrid}>
                {renderDetailHeader(basicItems)}
            </Grid>
            <Grid container direction="row" spacing={0}>
                {renderDetailValue(basicItems, row.details)}
            </Grid>
            {renderAssayDetails(valItems, row)}
        </div>
    }


    const renderAssayDetails = (items, record) => {
        if (items.length > 12) {
            let maxCount = parseInt(items.length / 12)
            let itemArr = [];
            for (var i = 0; i < maxCount; i++) {
                itemArr.push(items.slice(i, i + 12))
            }
            itemArr.push(items.slice(items.length - items.length % 12))
            return itemArr.map(ele => (<div>
                <Grid container direction="row" spacing={0} className={classes.gridContainer}>
                    {renderDetailHeader(ele)}
                </Grid>
                <Grid container direction="row" spacing={0}>
                    {renderDetailValue(ele, record.details)}
                </Grid>
            </div>))
        } else {
            return (<div>
                <Grid container direction="row" spacing={0} className={classes.gridContainer}>
                    {renderDetailHeader(items)}
                </Grid>
                <Grid container direction="row" spacing={0}>
                    {renderDetailValue(items, record.details)}
                </Grid>
            </div>)
        }
    }

    const renderDetailHeader = (items) => {
        return items.map(item => {
            return (<Grid item xs={1}>
                <Typography variant='caption'>
                    {item.itemName}
                </Typography>
            </Grid>)
        })
    }

    const renderDetailValue = (items, data) => {
        return items.map(item => {
            var val = _.find(data, function (o) { return o.itemId === item.itemId });
            if (val) {
                return (<Grid item xs={1}>
                    <Typography>
                        {val.itemVal}
                    </Typography>
                </Grid>)
            } else {
                return (<Grid item xs={1}>
                    <Typography>
                        -
                    </Typography>
                </Grid>)
            }

        })
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单位',
                field: 'companyId',
                editable: 'never',
                width: 100,
                render: row => <Typography>{renderCompany(row)}</Typography>
            }, {
                title: '部门',
                field: 'dptId',
                editable: 'never',
                width: 100,
                render: row => <Typography>{renderDpt(row)}</Typography>
            },
            {
                title: '装置',
                field: 'installId',
                editable: 'never',
                width: 150,
                render: row => <Typography>{renderInstall(row)}</Typography>
            },
            {
                title: '化验时间',
                field: 'testTime',
                editable: 'never',
                width: 180,
                render: row => <Typography>{formatDate_hms(row.testTime)}</Typography>
            },
            {
                title: '物料名称',
                field: 'testTarget',
                editable: 'never',
                width: 150,
                render: row => <Typography>{row.testTarget}</Typography>
            },
            {
                title: '化验员',
                field: 'assayerName',
                editable: 'never',
                // width: 100,
                render: row => <Typography>{row.assayerName}</Typography>
            },
            {
                title: '质检员',
                field: 'inspectorName',
                editable: 'never',
                // width: 100,
                render: row => <Typography>{row.inspectorName}</Typography>
            },
            {
                title: '取样/送样费用(元)',
                field: 'extFee',
                type: 'numeric',
                editable: 'never',
                width: 120,
                render: row => <Typography>{row.extFee !== null ? row.extFee.toFixed(2) : '0.00'}</Typography>
            },
            {
                title: '总费用(元)',
                field: 'totalCost',
                type: 'numeric',
                editable: 'never',
                width: 120,
                render: row => <Typography>{row.totalCost !== null ? row.totalCost.toFixed(2) : '0.00'}</Typography>
            },
            {
                title: '确认人',
                field: 'confirmUserName',
                editable: 'never',
                render: row => <Typography>{row.confirmUserName ?? ''}</Typography>
            },
            {
                title: '确认时间',
                field: 'comnfirmTime',
                editable: 'never',
                width: 180,
                render: row => <Typography>{row.confirmTime ? formatDate_hms(row.confirmTime) : ''}</Typography>
            },
            {
                title: '备注',
                field: 'opentext',
                editable: 'never',
                width: 500,
                render: row => <Typography>{row.opentext}</Typography>
            }
        ]

        return columns

    }

    return (
        <div alignitems="center" className={classes.planitemroot}>

            <MaterialTable
                className={classes.materialtable}
                columns={assemblycolumns()}
                data={records['list']}
                options={tableOptions}
                localization={tableLocalization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                title={''}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                detailPanel={rowData => {
                    var temp = _.find(temps, function (o) { return o.tempId === rowData.tempId })
                    if (temp) {
                        return renderRecordDetail(temp, rowData)
                    } else {
                        return <div alignitems="center" className={classes.detailroot}>
                            <Typography variant="h6">化验记录模板缺失，请联系系统管理员</Typography></div>
                    }


                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h3">
                                化验记录
                            </Typography>
                            <Grid container direction="row" spacing={4} alignItems="center" className={classes.gridContainer}>
                                <Grid item>
                                    <Typography >
                                        单位:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='companyId'
                                        onChange={event => onhandleChange(event, 'companyId')}
                                        value={companyId}
                                    >
                                        {[{ companyId: 0, companyAlias: '全部' }, ...companyList].map(ele => (
                                            <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyAlias}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        部门:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='dptId'
                                        onChange={event => onhandleChange(event, 'dptId')}
                                        value={dptId}
                                    >
                                        {[{ dptId: 0, dptName: '全部' }, ...departments].map(ele => {
                                            if (ele.dptId === 0 || ele.companyId === companyId) {
                                                return <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        装置:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='installId'
                                        onChange={event => onhandleChange(event, 'installId')}
                                        value={installId}
                                    >
                                        {[{ installId: 0, installName: '全部' }, ...installList].map(ele => {
                                            if (ele.installId === 0 || ele.companyId === companyId) {
                                                return <MenuItem key={ele.installId} value={ele.installId}>{ele.installName}</MenuItem>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        状态:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='status'
                                        onChange={event => onhandleChange(event, 'status')}
                                        value={status}
                                    >
                                       <MenuItem key={'s0'} value={''}>{'全部'}</MenuItem>
                                       <MenuItem key={'s1'} value={'active'}>{'待确认'}</MenuItem>
                                       <MenuItem key={'s2'} value={'done'}>{'已确认'}</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={4} alignItems="center">
                                <Grid item>
                                    <Typography >
                                        化验记录:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridLongSelect}>
                                    <TextField
                                        fullWidth
                                        select
                                        name='tempId'
                                        onChange={event => onhandleChange(event, 'tempId')}
                                        value={tempId}
                                    >
                                        {[{ tempId: 0, tempName: '全部' }, ...temps].map(ele => (
                                            <MenuItem key={ele.tempId} value={ele.tempId}>{ele.tempName}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        化验日期:
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            id="date-selector"
                                            margin="normal"
                                            value={startDate}
                                            onChange={date => onhandleDateChange(date, 'startDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        ~
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.gridSelect}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            id="date-selector"
                                            margin="normal"
                                            value={endDate}
                                            onChange={date => onhandleDateChange(date, 'endDate')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                actions={checkPermission() ? [
                    {
                        icon: 'search',
                        tooltip: '搜索记录',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleSearch()
                        }
                    },
                    {
                        icon: 'clear_all',
                        tooltip: '清空搜索条件',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleClearSearch()
                        }
                    },
                    {
                        icon: 'cloud_download',
                        tooltip: '导出',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleExport()
                        }
                    },
                    {
                        icon: 'add',
                        tooltip: '新建',
                        isFreeAction: true,
                        onClick: (event) => {
                            onhandleCreate()
                        }
                    },
                    rowData => ({
                        icon: 'edit',
                        tooltip: '编辑',
                        onClick: (event, rowData) => {
                            onhandleUpdate(rowData)
                        }
                    }),
                    rowData => ({
                        icon: 'delete_outlined',
                        tooltip: '删除',
                        onClick: (event, rowData) => {
                            onhandleDelete(rowData)
                        }
                    }),
                ] : [
                    {
                        icon: 'search',
                        tooltip: '搜索记录',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleSearch()
                        }
                    },
                    {
                        icon: 'clear_all',
                        tooltip: '清空搜索条件',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleClearSearch()
                        }
                    },
                    {
                        icon: 'cloud_download',
                        tooltip: '导出',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onhandleExport()
                        }
                    },
                    rowData => {
                        if (checkConfirmPermission(rowData)) {
                            return ({
                                icon: 'beenhere',
                                tooltip: '确认',
                                onClick: (event, rowData) => {
                                    onhandleConfirm(rowData)
                                }
                            })
                        }
                    },
                ]}
            >
            </MaterialTable>

        </div >
    )
}

AssayRecordComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

AssayRecordComponent.defaultProps = {
};

export default withStyles(AssayRecordComponent);