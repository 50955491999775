import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    hwList: [] //危废进场列表
};

export const setHwList = createAction('@@xcloud/manufacture/setHwList');

export const getHwList = createAction('@@xcloud/manufacture/getHwList',
    (inDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/hws'
        let strs = []
        if (inDate || status) {
            if (inDate) strs.push('inDate=' + inDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setHwList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取危废进场列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const ManufactureHWReducer = handleActions(
    {
        [setHwList]: (state, { payload: data }) => {
            return {
                ...state,
                hwList: data
            }
        },
    },
    initialState
)

export default ManufactureHWReducer
