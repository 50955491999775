import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationPlanEdit from '../../../components/DeviceManagement/OperationPlan/OperationPlanEdit';
import {
    initCreatePlan, addNewPlanDetail, updateCreatePlanData, deleteCreatePlanData, routeToOperationPlanList,
    addOperationPlanTemporaries, addOperationPlanDetailAnnu
} from "../../../redux/modules/operationPlan";
import { addSchemePlan, clearSchemeMap, routeToHazardInfo } from "../../../redux/modules/inspection/hazard";
import { routeToExceptionList, clearExceptionMap, updateDevOpsPlanRelatedException } from "../../../redux/modules/onsiteInspectionException";
import { getDeviceListById } from "../../../redux/modules/deviceSelect"
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';
import { getMoment } from '../../../utils/datetime';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
class OperationPlanEditContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        companyList: PropTypes.array,
        operationPlanDetails: PropTypes.array,
        procat: PropTypes.array,
        userProfile: PropTypes.object,
        newPlanChange: PropTypes.bool,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
        roleTree: PropTypes.array,
        roleList: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            target: 'DEV'
        }
    }

    componentDidMount() {
        let target = this.props.match.params.target;

        if (target) { this.setState({ target: target }) };

        this.props.onhandleInitCreatePlan(target);

        // 新需求: 巡检异常处置（下计划）时，传入deviceId，默认初始化一条记录
        this.handleInitNewPlanDetail();
    }

    // 新需求: 巡检异常处置（下计划）时，传入deviceId，默认初始化一条记录
    handleInitNewPlanDetail = async () => {
        const { exceptionMap } = this.props;
        if (exceptionMap && exceptionMap.deviceId) {
            await this.props.onhandleSelectDeiveListById(exceptionMap.deviceId);

            let planDetail = {};
            planDetail.target = this.state.target;
            planDetail.device = _.cloneDeep(this.props.selectedDevices[0])
            planDetail.companyId = planDetail.device.companyId
            planDetail._id = uuidv4();
            planDetail.targetDate = getMoment()().format("YYYY-MM-DD");
            planDetail.budget = 0;
            planDetail.roles = [];
            planDetail.opentext = '';
            planDetail.tableData = {
                // id: this.props.operationPlanDetails.length,
                editing: 'update'
            }
            this.props.onhandleAddNewPlanDetail(planDetail);

        }
    }

    onhandleSeletTarget() {
        if (this.state.target === 'DEV') {
            this.handleSeletDevice();
        } else {
            this.handleSeletAcc();
        }
    }

    handleSeletAcc() {
        let param = {
            search: true,
            cat: true,
            isCommon: true,
            useStatus: true,
            company: true,
            deviceSearch: true,
            connectedFlg: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedAccessory.length !== 1) {
                        this.props.onhandleSelectAccCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let planDetail = {};
                        planDetail.target = this.state.target;
                        planDetail.acc = _.cloneDeep(this.props.selectedAccessory[0])
                        planDetail.companyId = planDetail.acc.companyId
                        planDetail._id = uuidv4();
                        planDetail.targetDate = getMoment()().format("YYYY-MM-DD");
                        planDetail.budget = 0;
                        planDetail.roles = [];
                        planDetail.opentext = '';
                        planDetail.tableData = {
                            // id: this.props.operationPlanDetails.length,
                            editing: 'update'
                        }
                        this.props.onhandleAddNewPlanDetail(planDetail);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectAcc(param);
    }

    handleSeletDevice() {
        let param = {
            search: true,
            cat: true,
            procat: true,
            useStatus: true,
            company: true,
            install: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedDevices.length !== 1) {
                        this.props.onhandleSelectDeviceCheck()
                        return reject('callbackFunc Error')
                    } else {
                        let planDetail = {};
                        planDetail.target = this.state.target;
                        planDetail.device = _.cloneDeep(this.props.selectedDevices[0])
                        planDetail.companyId = planDetail.device.companyId
                        planDetail._id = uuidv4();
                        planDetail.targetDate = getMoment()().format("YYYY-MM-DD");
                        planDetail.budget = 0;
                        planDetail.roles = [];
                        planDetail.opentext = '';
                        planDetail.tableData = {
                            // id: this.props.operationPlanDetails.length,
                            editing: 'update'
                        }
                        this.props.onhandleAddNewPlanDetail(planDetail);
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.onhandleSelectDevice(param);
    }

    handleSave() {
        // 治理方案关联参数
        const { schemeMap, onhandleAddSchemePlan, exceptionMap, onhandleUpdateExceptionStatus } = this.props;
        // 如果type为annual，则创建无planId的年度计划明细，并关联治理方案
        if (schemeMap && schemeMap.schemeType && schemeMap.schemeType === 'annual') {
            this.props.onhandleSaveSchemeAnnual(
                this.state.target,
                (resData) => {
                    // 如果type为annual，则关联治理方案(用计划明细关联)
                    resData.details && resData.details.forEach(detail => {
                        const { dtlId } = detail;
                        onhandleAddSchemePlan({
                            schemeId: schemeMap.schemeId,
                            planType: 'OPERATION',
                            planId: dtlId
                        });
                    });
                    this.props.onhandleBack(schemeMap, exceptionMap)
                }
            );
            return;
        }

        // 创建常规临时计划
        this.props.onhandleSave(
            this.state.target,
            (resData) => {
                // 如果type为temp，则关联治理方案
                if (schemeMap && schemeMap.schemeType && schemeMap.schemeType === 'temp') {
                    const { details } = resData;
                    if (details && details.length) {
                        details.forEach(dtl => {
                            if (dtl.dtlId) {
                                onhandleAddSchemePlan({
                                    schemeId: schemeMap.schemeId,
                                    planType: 'OPERATION',
                                    planId: dtl.dtlId
                                });
                            }
                        })
                    }
                }

                // 如果type为temp，则关联异常分析
                if (exceptionMap && exceptionMap.planType && exceptionMap.planType === 'temp') {
                    const { planId } = resData;
                    onhandleUpdateExceptionStatus({
                        listId: exceptionMap.listId,
                        planType: 'OPERATION',
                        planId: planId
                    });
                }
                this.props.onhandleBack(schemeMap, exceptionMap)
            }
        );
    }

    render() {
        return (
            <div>
                <OperationPlanEdit
                    companyList={this.props.companyList}
                    operationPlanDetails={this.props.operationPlanDetails}
                    procat={this.props.procat}
                    catList={this.props.catList}
                    accCatList={this.props.accCatList}
                    userProfile={this.props.userProfile}
                    newPlanChange={this.props.newPlanChange}
                    roleTree={this.props.roleTree}
                    roleList={this.props.roleList}
                    target={this.state.target}
                    schemeMap={this.props.schemeMap}
                    // 运维类别
                    optCategories={this.props.optCategories}
                    onhandleSeletTarget={this.onhandleSeletTarget.bind(this)}
                    onhandleUpdate={this.props.onhandleUpdateCreatePlanData.bind(this)}
                    onhandleDelete={this.props.onhandleDeleteCreatePlanData.bind(this)}
                    onhandleBack={this.props.onhandleBack.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    exceptionMap={this.props.exceptionMap}
                />
            </div>
        );
    }

    componentWillUnmount() {
        const { schemeMap, exceptionMap } = this.props;
        if (schemeMap && schemeMap.schemeId) {
            // 清除隐患排查-治理方案映射信息
            this.props.onhandleClearSchemeMap();
        }

        if (exceptionMap && exceptionMap.listId) {
            // 清除异常分析-设备运维临时计划映射信息
            this.props.onhandleClearExceptionMap();
        }

    }
}

const mapStateToProps = (state) => {
    return {
        companyList: state.constant.companies,
        operationPlanDetails: state.operationPlan.newPlanDetailList,
        procat: state.devices.procat,
        userProfile: state.auth.userProfile,
        selectedDevices: state.deviceSelect.selectedDevices,
        selectedAccessory: state.accessorySelect.selectedAccessory,
        newPlanChange: state.operationPlan.newPlanChange,
        catList: state.trees.deviceCategoryList,
        accCatList: state.accessory.catList,
        roleTree: state.role.roleTree,
        roleList: state.role.roleList.list,
        schemeMap: state.hazard.schemeMap,
        // 运维类别
        optCategories: state.operationRule.optCategories,
        // 异常相关信息
        exceptionMap: state.onsiteInspectionException.exceptionMap,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitCreatePlan: (target) => {
            dispatch(initCreatePlan(target));
        },
        onhandleSelectDevice: (parm) => {
            dispatch(createmodal('deviceSelect', parm, 'medium'));
        },
        onhandleSelectDeviceCheck: () => {
            dispatch(createMessage('error', `请选择一个设备`));
        },
        onhandleSelectAcc: (parm) => {
            dispatch(createmodal('accessorySelect', parm, 'medium'));
        },
        onhandleSelectAccCheck: () => {
            dispatch(createMessage('error', `请选择一个部件`));
        },
        onhandleAddNewPlanDetail: (value) => {
            dispatch(addNewPlanDetail(value));
        },
        onhandleUpdateCreatePlanData: (value) => {
            dispatch(updateCreatePlanData(value));
        },
        onhandleDeleteCreatePlanData: (value) => {
            dispatch(deleteCreatePlanData(value));
        },
        onhandleBack: (schemeMap, exceptionMap) => {
            if (schemeMap && schemeMap.hid) {
                dispatch(routeToHazardInfo(schemeMap.hid, 'edit'));
            } else if (exceptionMap && exceptionMap.listId) {
                dispatch(routeToExceptionList(exceptionMap));
            } else {
                dispatch(routeToOperationPlanList());
            }
        },
        onhandleSave: (target, callback) => {
            dispatch(addOperationPlanTemporaries(target, callback));
        },
        onhandleSaveSchemeAnnual: (target, callback) => {
            dispatch(addOperationPlanDetailAnnu(target, callback));
        },
        onhandleAddSchemePlan: (value) => {
            dispatch(addSchemePlan(value));
        },
        onhandleClearSchemeMap: () => {
            dispatch(clearSchemeMap());
        },
        onhandleUpdateExceptionStatus: (value) => {
            dispatch(updateDevOpsPlanRelatedException(value));
        },
        onhandleClearExceptionMap: () => {
            dispatch(clearExceptionMap());
        },
        // 新需求: 巡检异常处置（下计划）时，传入deviceId，默认初始化一条记录
        onhandleSelectDeiveListById: (deviceId) => {
            dispatch(getDeviceListById(deviceId));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanEditContainer);