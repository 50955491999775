import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    width: '100%',
    padding: 20
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    margin: 10
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '0 24px 0 24px',
    height: '50px',
    // textAlign: 'center'
  },
  icon: {
    color: theme.palette.primary.main
  },
  detail: {
    padding: '0 24px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
