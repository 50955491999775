import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Card,
  Typography,
  Button,
  Grid,
  Chip,
  TablePagination
} from "@material-ui/core";
// import * as _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { getMoment as moment, getDateByTimestamp } from "../../../../utils/datetime";
import { localization } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";

function MajorHazardInspectionPage(props) {
  const { classes,
    yearMonth,
    lists,
    onhandleSelectChange,
    onhandleExit,
    onhandleBack,
    onhandleListMapping,
    detailShow,
    onView,
    currentPlanDetail,
    rowPerPage,
    setRowPerPage,
    onhandleGetPlanRecords,
    Recordlists
  } = props;

  const [pageSize, setpageSize] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPlanRow, setSelectedPlanRow] = useState(null);
  const tableHeight = (window.innerHeight - 600) / window.innerHeight * 100;


  return (
    <div>
      <Card className={classes.card}>
        <Typography variant="h3" gutterBottom>
          {`重大危险源隐患排查计划 ${detailShow ? ' - 实施记录' : ''}`}
        </Typography>
      </Card>
      <Card className={classes.card} style={{ display: detailShow ? 'none' : null }}>
        <Grid item xs={1}>
          <Typography color='textPrimary' className={classes.label}>
            检查月份
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={zhCN}>
            <KeyboardDatePicker
              className={classes.dateSelect}
              views={['year', 'month']}
              variant="inline"
              maxDate={moment()().endOf('year')}
              autoOk
              openTo={'month'}
              maxDateMessage={<Typography>日期不能超过计划年度</Typography>}
              format="yyyy/MM"
              margin="none"
              name='yearMonth'
              InputProps={{ readOnly: true }}
              value={yearMonth || moment()()}
              onChange={(event) => onhandleSelectChange(event, 'yearMonth')}
              KeyboardButtonProps={{
                'aria-label': '修改时间',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Card>

      <Card className={classes.card} style={{ display: detailShow ? 'none' : null }}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: '排查类型',
                  field: 'inspectType',
                  // planDetail没区分主类和子分类，所以用sub截断
                  render: row => row ? <Typography>{onhandleListMapping(row.inspectType.substr(0, 5), 'inspectType')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '受检单位',
                  field: 'companyId',
                  render: row => row ? <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '检查部门',
                  field: 'inspectionDptList',
                  render: row => row ? <Typography>{onhandleListMapping(row.inspectionDptList, 'departmentShow')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '负责人',
                  field: 'inspectHead',
                  render: row => row ? <Typography>{onhandleListMapping(row.inspectHead, 'user')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '检查日期',
                  field: 'startTime',
                  render: (row) => row && <Typography>{
                    moment()(row.startTime).isSame(row.inspectTime, 'day') ?
                      getDateByTimestamp(row.startTime)
                      :
                      (getDateByTimestamp(row.startTime) + ' 至 ' + getDateByTimestamp(row.inspectTime))
                  }</Typography>,
                  editable: 'never'
                },
                {
                  title: '完成时间',
                  field: 'endTime',
                  render: row => row ? <Typography>{row.endTime ? getDateByTimestamp(row.endTime) : ''}</Typography> : '',
                  editable: 'never'
                },
                {
                  width: '10%',
                  title: '状态',
                  field: 'status',
                  render: row => {
                    let statusStyle = classes.pending;
                    if (row.status === 'done' || row.endTime) statusStyle = classes.done;
                    if (row.status === 'active') statusStyle = classes.active;
                    if (row.status === 'invalid') statusStyle = classes.invalid;
                    return <Chip size="small" className={statusStyle} label={onhandleListMapping(row.status, 'status')} />
                  },
                  editable: 'never',
                  align: 'center'
                }]}
              data={lists}
              options={{
                pageSize: 5,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
                tableLayout: 'auto',
                actionsColumnIndex: -1,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                selection: false,
                rowStyle: rowData => ({
                  backgroundColor: selectedPlanRow === rowData.listId ? '#EEEEEE' : 'FFFFFF'
                }),
              }}
              onRowClick={((evt, selectedPlanRow) => setSelectedPlanRow(selectedPlanRow.tableData.id))}
              style={{
                border: '0px solid black',
                boxShadow: 'none'
              }}
              localization={localization}
              actions={[
                rowData => ({
                  icon: 'subject',
                  tooltip: '查看排查记录',
                  onClick: (event, rowData) => {
                    onView(event, rowData)
                  }
                }),
              ]}
            >
            </MaterialTable>
          </Grid>
        </Grid>
      </Card>

      <Card className={classes.card} style={{ display: !detailShow ? 'none' : null }}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom>
              检查类型</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography gutterBottom>
              {currentPlanDetail.inspectType ? onhandleListMapping(currentPlanDetail.inspectType.substr(0, 5), 'inspectType') : '-'}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography gutterBottom>
              完成时间</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography gutterBottom>
              {currentPlanDetail.endTime ? moment().utc(currentPlanDetail.endTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography gutterBottom>
              受检单位</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography gutterBottom>
              {currentPlanDetail.companyId ? onhandleListMapping(currentPlanDetail.companyId, 'company') : '-'}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography gutterBottom>
              负责人</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography gutterBottom>
              {currentPlanDetail.inspectHead ? onhandleListMapping(currentPlanDetail.inspectHead, 'user') : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Card className={classes.card} style={{ display: !detailShow ? 'none' : null }}>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: '序号',
                  field: 'serialNo',
                  render: row => row ? <Typography>{row.checklist.serialNo}</Typography> : '',
                  width: '10%',
                  editable: 'never'
                },
                {
                  title: '责任部门',
                  field: 'dptId',
                  width: '10%',
                  render: row => row ? <Typography>{onhandleListMapping(row.inspectionResult.dptId, 'departmentEdit')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '检查人',
                  field: 'dptId',
                  width: '10%',
                  render: row => row ? <Typography>{onhandleListMapping(row.inspectionResult.userId, 'user')}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '检查内容',
                  field: 'problem',
                  render: row => row ? <Typography>{row.checklist.problem}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '结果',
                  field: 'result',
                  width: '10%',
                  render: row => {
                    let resultStyle = classes.pending;
                    if (row.result === 'true' || Number.parseInt(row.result) > 5) resultStyle = classes.passed;
                    if (row.result === 'false' || Number.parseInt(row.result) <= 5) resultStyle = classes.denied;
                    return <Chip size="small" className={resultStyle} label={onhandleListMapping(row.result, 'result')} />
                  },
                  editable: 'never'
                },
                {
                  title: '备注',
                  field: 'opentext',
                  render: row => row ? <Typography>{truncate(row.opentext, 30)}</Typography> : '',
                  editable: 'never'
                }
              ]}
              data={Recordlists.list}
              options={{
                pageSize: rowPerPage,
                maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
                tableLayout: 'auto',
                actionsColumnIndex: -1,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                selection: false,
                rowStyle: rowData => ({
                  backgroundColor: selectedRow === rowData.listId ? '#EEEEEE' : 'FFFFFF'
                }),
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
              style={{
                border: '0px solid black',
                boxShadow: 'none'
              }}
              localization={localization}
              components={{
                Pagination: props => (
                  <TablePagination
                    {...props}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowsPerPage={rowPerPage}
                    count={Recordlists.total}
                    page={pageSize}
                    onChangePage={(event, pageNum) => {
                      setpageSize(pageNum);
                      onhandleGetPlanRecords({
                        dtlId: currentPlanDetail.dtlId,
                        offset: pageNum * rowPerPage,
                        size: rowPerPage,
                        sort: "-record_time",
                      })
                    }}
                    onChangeRowsPerPage={(event) => {
                      props.onChangeRowsPerPage(event);
                      setRowPerPage(event.target.value);
                      setpageSize(0);
                      onhandleGetPlanRecords({
                        dtlId: currentPlanDetail.dtlId,
                        offset: 0,
                        size: event.target.value,
                        sort: "-record_time"
                      })
                    }}

                  />
                )
              }
              }
            >
            </MaterialTable>
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Grid item xs={1}>
          <Button variant="outlined" color="primary"
            onClick={event => onhandleExit()}
          >
            关闭
          </Button>
        </Grid>
        <Grid item xs={1} style={{ display: !detailShow ? 'none' : null }}>
          <Button variant="contained" color="primary"
            onClick={event => onhandleBack()}
          >
            返回
          </Button>
        </Grid>
      </Grid>
    </div >
  );
}

MajorHazardInspectionPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onhandleListMapping: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default withStyles(MajorHazardInspectionPage);
