import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    List,
    Button,
    Typography
} from '@material-ui/core';
import { findBuNameById } from "../../../utils/constant";
import { getTimeUnitByCode, getDateByTimestamp } from "../../../utils/datetime";
import ProcessLine from '../../ProcessLine';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../utils/mtable"

import * as _ from 'lodash';

const RegulationPlan = ({
    classes,
    businessUnits,
    plan,
    userProfile,
    currentPlanDetails,
    constant,
    onhandleUpdate,
    onhandleDelete,
    onhandleProcess,
    onhandleNewPlan,
    onhandleBack,
    onhandleOpenPlanDetailAddPannel,
    currentProcessStatus,
    //createPermission
}) => {

    const checkEditable = () => {
        if (currentProcessStatus.statusCode !== 'Drafting' && currentProcessStatus.statusCode !== 'Rejected')
            return false
        return true
    }

    const displayAddpanel = () => {
        if (currentProcessStatus.statusCode !== 'Drafting' && currentProcessStatus.statusCode !== 'Rejected')
            return false
        //if(createPermission)
        //    return true
        return true
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        addRowPosition: 'first',
        showTitle: false,
    }

    const planTypeTrans = (name) => {
        if (name === 'Add') return '新增'
        if (name === 'Update') return '修改'
        if (name === 'Delete') return '废止'
    }
    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                render: row => row && <Typography>{findBuNameById(constant, row.unitId)}</Typography>,
                editable: 'never',
                cellStyle: (value, rowData) => {
                    if (rowData && rowData.unitDuplicated) {
                        return {
                            border: ' 0px solid black',
                        }
                    }
                    return {}
                }
            },
            {
                title: '公司规章制度',
                field: 'regName',
                editable: 'onAdd',
                render: row => <Typography>{row.regName}</Typography>
            },
            {
                title: '修订周期',
                render: (rowData) => <Typography>{rowData ? rowData.revisionCycle + getTimeUnitByCode(rowData.revisionUnit) : ''}</Typography>,
            },
            {
                title: '上次修订时间',
                field: 'updateTime',
                render: (row) => <Typography>{getDateByTimestamp(row.updateTime)}</Typography>,
                type: 'date',
                editable: 'never',
            },
            {
                title: '修改建议',
                field: 'content',
                render: row => <Typography>{row.content}</Typography>

            },
            {
                width: '10%',
                title: '修订类型',
                field: 'planType',
                lookup: {
                    Add: '新增',
                    Update: '修改',
                    Delete: '废止'
                },
                render: row => row ? <Typography>{planTypeTrans(row.planType)}</Typography> : '',
                editable: 'onAdd',
                cellStyle: { fontSize: 12 }
            },
            {
                title: '计划完成时间',
                field: 'targetDate',
                render: (row) => <Typography>{getDateByTimestamp(row.targetDate)}</Typography>,
                type: 'date'
            },
        ]

        return columns
    }

    const assemblyData = () => {

        if (!currentPlanDetails || currentPlanDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let planDetailsSort = _.sortBy(currentPlanDetails, ['unitId', 'content'])

        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in planDetailsSort) {
            let row = {
                num: planDetailsSort.length - Number(idx),
                ...planDetailsSort[idx]
            }

            if (preUnitId === planDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = planDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }

    const renderActions = () => {
        const actions = _.filter(currentProcessStatus.actions, (action) => { return !action.distributed })
        return actions.map((action, idx) => (
            <Button
                key={action.actionId}
                disabled={action.disabled || false}
                variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                color="primary"
                className={classes.button}
                onClick={onhandleProcess.bind(this, action)}
            >
                {action.actionName}
            </Button>))
    }

    let fixedStatusCodes = []
    let fixedStatusCode = currentProcessStatus.statusCode
    if (currentProcessStatus && currentProcessStatus.statusCodes) {
        for (let sc of currentProcessStatus.statusCodes) {
            if (sc === 'ToBeInvited' || sc === 'InTrial' || sc === 'ToBeSigned') {
                fixedStatusCodes.push('Trial')
            } else {
                fixedStatusCodes.push(sc)
            }
        }
    }

    fixedStatusCodes = _.uniq(fixedStatusCodes)

    if (fixedStatusCode === 'ToBeInvited' || fixedStatusCode === 'InTrial' || fixedStatusCode === 'ToBeSigned') {
        fixedStatusCode = 'Trial'
    }

    return (
        <div alignitems="center" className={classes.root}>

            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.infocontainer}>
                    <Grid container >
                        <Grid item className={classes.processline}>
                            <ProcessLine
                                statusCodes={fixedStatusCodes}
                                statusCode={fixedStatusCode}
                                constant={constant}
                            />
                        </Grid>
                        <Grid item>
                            <List >
                                {renderActions()}
                            </List>
                        </Grid>
                    </Grid>

                    {currentProcessStatus.rejectComment && (<Grid className={classes.attention} >
                        {currentProcessStatus.rejectComment.split('\n').map((line, index) => (
                            <Typography key={index} className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
                        ))}
                    </Grid>)}

                    <Grid className={classes.detailtable}>
                        <MaterialTable
                            className={classes.materialtable}
                            title={<Typography variant='h3' className={classes.tableTitle}>{plan.planName}</Typography>}
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            editable={!checkEditable() ? {} : {
                                isEditable: rowData => rowData.status !== 'Submitted'
                                    && _.find(businessUnits, function (o) { return o.unitId === rowData.unitId }),
                                isDeletable: rowData => rowData.status !== 'Submitted'
                                    && _.find(businessUnits, function (o) { return o.unitId === rowData.unitId }),

                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleUpdate(newData)
                                        resolve()
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve, reject) => {
                                        onhandleDelete(oldData)
                                        resolve()
                                    }),
                            }}
                            localization={localization}
                            style={style}
                            actions={
                                !displayAddpanel() ? [] :
                                    [
                                        {
                                            icon: 'add',
                                            tooltip: '新增计划',
                                            isFreeAction: true,
                                            onClick: (event) => { onhandleOpenPlanDetailAddPannel() }
                                        }
                                    ]}
                        >
                        </MaterialTable>
                    </Grid>
                    <Grid>
                    </Grid>
                </Grid>
            </div>
        </div >

    );
};
RegulationPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    regulations: PropTypes.array.isRequired,
    currentPlanDetails: PropTypes.array.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleProcess: PropTypes.func.isRequired,
    onhandleNewPlan: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
};

RegulationPlan.defaultProps = {
    plan: {},
    currentPlanDetails: [],
    regulations: [],
    userProfile: {},
    constant: {},
    departments: [],
    businessUnits: [],
    currentProcessStatus: {},
}

export default withStyles(RegulationPlan);