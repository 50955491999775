import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActivityList, getDutyRecords, addActivity, updateActivity, addDutyRecord, updateDutyRecord } from '@/redux/modules/safetyActivity';
import { getMoment } from '@/utils/datetime'
import MyCalendarComponent from '@/components/LeadershipManagement/MyCalendar/MyCalendarComponent';
import ActivityTableComponent from '@/components/LeadershipManagement/MyCalendar/ActivityTable/ActivityTableComponent';
import _ from 'lodash'
import ActivityPanelComponent from '@/components/LeadershipManagement/MyCalendar/ActivityPanel';
import TeamActivityPannelComponent from '@/components/LeadershipManagement/MyCalendar/TeamActivityPannel';
import { getLeaders } from '@/redux/modules/user'

class MyCalendarContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            activityTableOpen: false,
            activityOpen: false,
            selectedActivity: {},
            selectedDate: getMoment()(),
        }
    }

    componentDidMount() {
        this.handleGetActivityList()
        this.props.onhandleGetLeaders(this.props.currentUserInfo.companyId)
    }

    handleGetActivityList(date) {
        const { onhandleGetActivityList, currentUserInfo } = this.props
        let mDate = getMoment()()
        if (date)
            mDate = getMoment()(date)
        // console.log(mDate)
        const query = {
            fromDate: mDate.startOf('month').format('YYYY-MM-DD'),
            toDate: mDate.add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            // fromDate: mDate.startOf('month').valueOf(),
            // toDate: mDate.add(1, 'months').startOf('month').valueOf(),

            userId: currentUserInfo.userId,
        }
        onhandleGetActivityList(query)
    }

    handleChangeDate(date) {
        this.setState({ selectedDate: getMoment()(date) })
        this.handleGetActivityList(date)
    }

    handleOpenActivity(event) {
        // console.log(event)

        this.props.onhandleGetDutyRecords(event.actId, (records) => {
            let newAct = { ...event }
            if (records && records.length > 0) {
                newAct.recordId = records[0].recordId
                newAct.recordContent = records[0].content
            } else {
                newAct.recordId = 0
                newAct.recordContent = ''
            }
            this.setState({
                activityOpen: true,
                selectedActivity: newAct
            })
        })
    }
    handleCloseActivity() {
        this.setState({ activityOpen: false })
    }

    handleChangeActivityAttr(event) {
        // console.log(event)
        let newAct = { ...this.state.selectedActivity }
        const attr = event.target.name
        newAct[attr] = event.target.value
        // console.log(newAct)
        this.setState({
            selectedActivity: newAct
        })
    }

    handleChangeActivityTime(date) {
        let newAct = { ...this.state.selectedActivity }
        newAct.activityTime = getMoment()(date).toDate()
        this.setState({
            selectedActivity: newAct
        })
        // console.log(this.state.selectedActivity)
    }

    handleAddActivity(act) {

        const { currentUserInfo, onhandleAddActivity } = this.props
        // console.log(act)

        let newAct = {
            activity: act.activity,
            activityTime: getMoment()(this.state.selectedDate).startOf('day').toDate(),
            location: act.location,
            priority: parseInt(act.priority),
            activityType: 0, //普通活动
            creator: currentUserInfo.userId,
            status: act.status,
            users: [currentUserInfo.userId]
        }
        onhandleAddActivity(newAct, () => {
            this.handleGetActivityList(this.state.selectedDate)
        })

    }

    handleUpdateActivity(act) {

        const { onhandleUpdateActivity } = this.props
        // console.log(act)
        let newAct = {
            actId: act.actId,
            activity: act.activity,
            location: act.location,
            priority: parseInt(act.priority),
            status: act.status,
            users: _.map(act.users, value => { return value.userId })
        }
        onhandleUpdateActivity(newAct, () => {
            this.handleGetActivityList(this.state.selectedDate)
        })

    }

    handleSaveActivity() {

        const { onhandleUpdateActivity, onhandleAddDutyRecord, onhandleUpdateDutyRecord, currentUserInfo } = this.props
        const act = this.state.selectedActivity

        let newAct = {
            actId: act.actId,
            activity: act.activity,
            location: act.location,
            priority: parseInt(act.priority),
            activityTime: getMoment()(act.activityTime).toDate(),
            status: act.status,
            users: _.map(act.users, value => { return value.userId })
        }
        // console.log('newAct', newAct)
        onhandleUpdateActivity(newAct, () => {
            this.setState({ activityOpen: false })
            this.handleGetActivityList(this.state.selectedDate)
        })

        if (act.recordId) {
            //update
            let record = {
                "recordId": act.recordId,
                "content": act.recordContent,
            }
            onhandleUpdateDutyRecord(record)

        } else {
            //add
            let record = {
                creator: currentUserInfo.userId,
                actId: act.actId,
                content: act.recordContent,
            }
            onhandleAddDutyRecord(record)
        }

    }

    handleCloseActivityTable() {
        this.setState({ activityTableOpen: false })
    }

    handleOpenActivityTable(events) {
        // console.log(event)
        if (events.length > 0) {
            this.setState({
                activityTableOpen: true,
                selectedDate: getMoment()(events[0].start)
            })
        }
    }

    filterCurrentActivityList() {
        const selectedMoment = getMoment()(this.state.selectedDate)
        return _.filter(this.props.activityList, a => {
            return selectedMoment.startOf('day').diff(getMoment()(a.activityTime).startOf('day'), 'days') === 0
        })
    }

    handleUploadFileSelected() {

    }
    handleUploadConfirm() { }

    render() {

        // console.log(this.state.selectedActivity)
        let ActivityPannel = ActivityPanelComponent
        if (this.state.selectedActivity.activityType === 1) {
        } else if (this.state.selectedActivity.activityType === 2) {
            ActivityPannel = TeamActivityPannelComponent
        }
        return (
            <div>
                <MyCalendarComponent
                    constant={this.props.constant}
                    activityList={this.props.activityList}
                    currentUserInfo={this.props.currentUserInfo}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                    onhandleCloseActivity={this.handleCloseActivity.bind(this)}
                    onhandleOpenActivityList={this.handleOpenActivityTable.bind(this)}
                    onhandleCloseActivityList={this.handleCloseActivityTable.bind(this)}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                />

                { this.state.activityTableOpen &&
                    <ActivityTableComponent
                        constant={this.props.constant}
                        currentActivityList={this.filterCurrentActivityList()}
                        open={this.state.activityTableOpen}
                        onhandleClose={this.handleCloseActivityTable.bind(this)}
                        onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                        onhandleAdd={this.handleAddActivity.bind(this)}
                        onhandleUpdate={this.handleUpdateActivity.bind(this)}


                    />}
                {this.state.activityOpen && <ActivityPannel
                    constant={this.props.constant}
                    open={this.state.activityOpen}
                    activity={this.state.selectedActivity}
                    dutyRecords={this.props.dutyRecords}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    leaders={this.props.leaders}
                    companyTopology={this.props.companyTopology}
                    onhandleClose={this.handleCloseActivity.bind(this)}
                    onhandleSave={this.handleSaveActivity.bind(this)}
                    onhandleChangeActivityTime={this.handleChangeActivityTime.bind(this)}
                    onhandleChangeActivityAttr={this.handleChangeActivityAttr.bind(this)}
                    onhandleUploadFileSelected={this.handleUploadFileSelected.bind(this)}
                    onhandleUploadConfirm={this.handleUploadConfirm.bind(this)}

                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        activityList: state.safetyActivity.activityList,
        dutyRecords: state.safetyActivity.dutyRecords,
        companyTopology: state.company.companyTopology,
        userProfile: state.auth.userProfile,
        leaders: state.user.leaders,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetActivityList: (query, callback) => {
            dispatch(getActivityList(query, callback))
        },
        onhandleAddActivity: (activity, callback) => {
            dispatch(addActivity(activity, callback))
        },
        onhandleUpdateActivity: (activity, callback) => {
            dispatch(updateActivity(activity, callback))
        },
        onhandleGetDutyRecords: (activityId, callback) => {
            dispatch(getDutyRecords(activityId, callback))
        },
        onhandleAddDutyRecord: (record, callback) => {
            dispatch(addDutyRecord(record, callback))
        },
        onhandleUpdateDutyRecord: (record, callback) => {
            dispatch(updateDutyRecord(record, callback))
        },
        onhandleGetLeaders: (companyId, callback) => {
            dispatch(getLeaders(companyId, callback))
        },
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyCalendarContainer);