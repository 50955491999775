import React from 'react';
import withStyles from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { Add, Edit, DeleteOutline } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { localization, options } from "../../../../../utils/mtable";
import Button from "@material-ui/core/Button";
import { Prompt } from 'react-router-dom';
import * as _ from 'lodash';



const DeviceTechParam = props => {

    const {
        classes,
        onhandleAdd,
        onhandleUpdate,
        onhandleDelete,
        userProfile,
        tabValue,
        index,
        unitList,
        //logicList,
        onhandleSave,
        // onhandleBack,
        deviceTechParamChange,
        valtypeList,
        tableTechParam,
        // isView,
        isDeviceEdit,
        techParamWarning
    } = props;

    const getUnit = () => {
        let data = {}
        if (unitList) {
            for (let i = 0; i < unitList.length; i++) {
                let key = unitList[i].code
                data[key] = unitList[i].name
            }
        }

        return data
    }

    const renderUnit = (row) => {
        let label = '';
        if (unitList && unitList.length > 0) {
            let value = _.find(unitList, function (o) { return o.code === row.unit })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const getLogic = () => {
        let data = []
        // for (let i = 0; i < logicList.length; i++) {
        //     let key = logicList[i].code
        //     data[key] = logicList[i].name
        //     data.push()
        // }

        data = [
            { code: '<=', name: '<=' },
            { code: '<', name: '<' },
            { code: '=', name: '=' },
            { code: '>=', name: '>=' },
            { code: '>', name: '>' }
        ]

        return data
    }

    const renderValue = (props) => {
        if (props.rowData.paramType === 'VAL_BOOL') {
            return renderBool(props)
        } else {
            return renderVals(props)
        }
    }

    const renderBool = (props) => {
        return <TextField
            select
            value={props.rowData.vals ? props.rowData.vals : ''}
            name='vals'
            onChange={e => props.onChange(e.target.value)}>
            <MenuItem key={'是'} value={'是'}>
                {'是'}
            </MenuItem>
            <MenuItem key={'否'} value={'否'}>
                {'否'}
            </MenuItem>
        </TextField>
    }

    const renderVals = (props) => {
        return <TextField
            error={props.helperText}
            helperText={props.helperText || ''}
            required
            value={props.rowData.vals ? props.rowData.vals : ''}
            name='vals'
            onChange={e => props.onChange(e.target.value)}>
        </TextField>
    }


    const validateValue = (rowData) => {
        if (rowData && rowData.tableData && rowData.tableData.editing && rowData.tableData.editing === "delete") {
            return true
        }

        if (_.trim(rowData.vals) === '') {
            return { isValid: false, helperText: '参数值不能为空' }
        }

        if (rowData.paramType === 'VAL_NUMBER') {
            if (_.isNaN(_.toNumber(rowData.vals))) {
                return { isValid: false, helperText: '请输入数字' }
            }
        }

        if (rowData.paramType === 'VAL_INTEGER') {
            if (_.isNaN(_.toInteger(rowData.vals))) {
                return { isValid: false, helperText: '请输入整数' }
            }
        }
        return true
    }

    const renderLogic = (row) => {
        let label = '';
        if (getLogic() && getLogic().length > 0) {
            let value = _.find(getLogic(), function (o) { return o.code === row.logic })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderParamType = (row) => {
        let label = '';
        if (valtypeList && valtypeList.length > 0) {
            let value = _.find(valtypeList, function (o) { return o.code === row.paramType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '参数名称',
                field: 'param',
                align: 'left',
                editable: 'onAdd',
                validate: rowData => (!rowData.param || rowData.param === '') ? { isValid: false, helperText: '参数名称不能为空' } : true,
                render: row => row ? <Typography>{row.param}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.param ? props.rowData.param : ''}
                        name='param'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
            {
                title: '参数类型',
                field: 'paramType',
                width: '180px',
                align: 'left',
                editable: 'onAdd',
                validate: rowData => (!rowData.paramType || rowData.paramType === '') ? { isValid: false, helperText: '参数类型不能为空' } : true,
                render: row => row ? <Typography>{renderParamType(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.paramType ? props.rowData.paramType : ''}
                        name='paramType'
                        onChange={e => props.onChange(e.target.value)}>
                        {valtypeList.map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '正常运行条件',
                field: 'logic',
                width: '180px',
                align: 'right',
                editable: 'onAdd',
                validate: rowData => (!rowData.logic || rowData.logic === '') ? { isValid: false, helperText: '运行条件不能为空' } : true,
                render: row => row ? <Typography>{renderLogic(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.logic ? props.rowData.logic : ''}
                        name='logic'
                        onChange={e => props.onChange(e.target.value)}>
                        {getLogic().map((item) => {
                            return <MenuItem key={item.code} value={item.code}>
                                {item.name}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '标准值',
                field: 'vals',
                align: 'right',
                render: row => row ? <Typography>{row.vals}</Typography> : '',
                validate: validateValue,
                editComponent: renderValue
            },
            {
                title: '单位',
                field: 'unit',
                width: '180px',
                align: 'left',
                render: row => row ? <Typography>{renderUnit(row)}</Typography> : '',
                lookup: getUnit()

            },
            {
                title: '别名',
                field: 'alias',
                align: 'left',
                render: row => row ? <Typography>{row.alias}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        value={props.rowData.alias ? props.rowData.alias : ''}
                        name='alias'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
        ]

        return columns
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_info' })
        return permission && permission.action === 'W'
    }

    //  不受变更单影响：结构参数
    // const devEditable = checkPermission() && !isView && !isDeviceEdit && !techParamWarning;
    const devEditable = checkPermission() && isDeviceEdit && !techParamWarning;

    return (
        <div alignitems="center" className={classes.root} hidden={tabValue !== index}>
            <Grid className={
                clsx(classes.detailtable, {
                    [classes.tableToolbarEditable]: devEditable,
                })
            }>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    localization={localization}
                    columns={assemblycolumns()}
                    data={tableTechParam}
                    options={{
                        ...options,
                        showTitle: false,
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        addRowPosition: 'first',
                        search: false,
                        paging: false,
                        toolbar: devEditable,
                    }}
                    style={{
                        border: '0px solid black',
                        boxShadow: 'none'
                    }}
                    icons={{
                        Add: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}
                        // onClick={onhandleAdd}
                        >
                            新建
                        </Button>,
                        Edit: () => <Edit color={'primary'} />,
                        Delete: () => <DeleteOutline color={'error'} />
                    }}
                    editable={devEditable ? {
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleAdd(newData)
                            }),
                        onRowUpdate: (newData) =>
                            new Promise((resolve, reject) => {
                                resolve()
                                onhandleUpdate(newData)
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDelete(oldData)
                                resolve()
                            }),
                    } : {}}
                >
                </MaterialTable>
            </Grid>

            <div className={classes.card}>
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Button variant="contained" color="primary" className={classes.button}
                        hidden={!devEditable}
                        disabled={!deviceTechParamChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
            </div>

            <Prompt message="数据未保存,确定要离开？" when={deviceTechParamChange} />
        </div >
    );
};
DeviceTechParam.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleAdd: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    tabValue: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    unitList: PropTypes.array.isRequired,
    //logicList: PropTypes.array.isRequired,
    deviceTechParamChange: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    valtypeList: PropTypes.array.isRequired,
    tableTechParam: PropTypes.array.isRequired,
    isView: PropTypes.bool.isRequired,
    isDeviceEdit: PropTypes.bool.isRequired,
    techParamWarning: PropTypes.bool.isRequired,
};

export default (withStyles(DeviceTechParam));