import React from 'react';
import PropTypes from 'prop-types';
import './reviewerOverride.css';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    IconButton,
    DialogActions,
    Tooltip,
    Grid,
} from '@material-ui/core';

import {
    Close,
    GetApp,
    SkipNext,
    SkipPrevious
} from '@material-ui/icons';
import FileViewer from 'react-file-viewer';
import { getDocType, imageType } from '@/utils/fileUtils'


import withStyles from './styles';


const FilePreviewer = ({
    classes,
    open,
    title,
    fileType,
    filePath,
    fileDesc,
    fileIndex,
    fileCount,
    onDownload,
    // errorComponent,
    onError,
    onClose,
    onPre,
    onNext,
}) => {

    // console.log('errorComponent')
    // console.log(errorComponent)

    const handleError = (e) => {
        if (onError) {
            onError(e);
        } else {
            console.error(e, 'error in file-viewer');
        }
    }

    const handleDownload = (e) => {
        if (onDownload) {
            onDownload(filePath)
        } else {
            window.location = filePath
        }
    }

    const handlePre = (e) => {
        if (onPre) {
            onPre(fileIndex)
        }
    }

    const handleNext = (e) => {
        if (onNext) {
            onNext(fileIndex)
        }
    }
    const renderModalTitle = () => {
        if (title) {
            return title;
        }
        return '预览';
    }

    return (<Grid>
        <Dialog
            className={classes.drawer}
            open={open}
            scroll={'paper'}
            onClose={onClose}
            // fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle disableTypography>
                <Typography component={'span'} variant="h3">
                    {renderModalTitle()}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Tooltip title={fileDesc ? fileDesc : ''}>
                    {getDocType('.' + fileType) === imageType ? <Grid container
                        className={classes.imageGrid}>
                        <Grid item >
                            <img src={filePath} alt={title} width="100%" />
                        </Grid>
                    </Grid> :
                        <Grid container justifycontent={"center"}>
                            <Grid item >
                                <FileViewer
                                    fileType={fileType}
                                    filePath={filePath}
                                    // errorComponent={() => <Typography>{'预览发生错误'}</Typography>}
                                    unsupportedComponent={() => <Typography>{'该文件不支持预览'}</Typography>}
                                    onError={handleError} />
                            </Grid>
                        </Grid>
                    }
                </Tooltip>
            </DialogContent>
            <DialogActions>
                {fileCount > 0 && onPre && onNext ?
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <Tooltip title='上一个'>
                                <Grid>
                                    <IconButton variant='text' disabled={fileIndex <= 0} onClick={() => handlePre()}> <SkipPrevious /></IconButton>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Typography>{(fileIndex + 1) + '/' + (fileCount)}</Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title='下一个'>
                                <Grid>
                                    <IconButton variant='text' disabled={fileIndex + 1 >= fileCount} onClick={() => handleNext()}> <SkipNext /></IconButton>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid item className={classes.flexgrow}></Grid>
                        <Grid item >
                            <Button color={'primary'} variant="outlined" onClick={handleDownload}>
                                <GetApp />{'下载'}
                            </Button>
                        </Grid>
                    </Grid>
                    : <Grid item >
                        <Button color={'primary'} variant="outlined" onClick={handleDownload}>
                            <GetApp />{'下载'}
                        </Button>
                    </Grid>}
            </DialogActions>
        </Dialog >
    </Grid >);
}

FilePreviewer.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    fileType: PropTypes.string.isRequired,
    filePath: PropTypes.string.isRequired,
    errorComponent: PropTypes.object,
    onError: PropTypes.func,
    onDownload: PropTypes.func,
    fileIndex: PropTypes.number,
    fileCount: PropTypes.number,
    onPre: PropTypes.func,
    onNext: PropTypes.func,
};

export default withStyles(FilePreviewer);
