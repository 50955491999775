import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    paramrecords: [], //能耗参数记录列表
};

export const setParamrecords = createAction('@@xcloud/amoeba/setParamrecords');

export const getParamrecords = createAction('@@xcloud/amoeba/getParamrecords',
    (year, month, energyType) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/paramrecords'
        let strs = []
        if (year || month || energyType) {
            if (year) strs.push('year=' + year)
            if (month) strs.push('month=' + month)
            if (energyType) strs.push('energyType=' + energyType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setParamrecords(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取能耗参数记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addParamrecord = createAction('@@xcloud/amoeba/addParamrecord',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/paramrecords`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "批量生成记录成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `批量生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateParamrecord = createAction('@@xcloud/amoeba/updateParamrecord',
    (recordId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/paramrecords/${recordId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新水表计量和对应费用的记录成功"));
                }
            }).catch(err => {
                let msg = `更新能耗参数记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteParamrecord = createAction('@@xcloud/amoeba/deleteParamrecord',
    (recordId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/amoeba/paramrecords/${recordId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除能耗参数记录成功"));
                }
            }).catch(err => {
                let msg = `删除能耗参数记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const amoebaParamrecordReducer = handleActions(
    {
        [setParamrecords]: (state, { payload: data }) => {
            return {
                ...state,
                paramrecords: data
            }
        },
    },
    initialState
)

export default amoebaParamrecordReducer
