/* eslint-disable import/no-cycle */
import React, { useState, forwardRef, Component } from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { ConfigProvider as AntDConfigProvider } from 'antd'
import { Divider, Drawer, List, ListItem, Button, Tooltip, AppBar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { colors } from '@material-ui/core';
import { connect } from 'react-redux';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
// import LensIcon from '@material-ui/icons/Lens';
import * as _ from 'lodash';
import Home from './components/Home';
import Topbar from './containers/Topbar';
import MessagePage from './containers/MessagePage';
import ModalPage from './containers/ModalPage';
import LoadingPage from './containers/LoadingPage';
import ConfirmPage from './containers/ConfirmPage';
import Routes from './routes';
import { setExpandedMenu, updateMenuOpen, setFocusNode } from './redux/modules/menu'
import zhCN from 'antd/lib/locale/zh_CN'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import '@/theme/antdOverrides.css';
import { treeToArray } from './utils/constant';
// import { initConstantPath } from './redux/modules/constant';
// import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
// import { CallReceived } from '@material-ui/icons';

const drawerWidth = 200;
const AppBarHeight = 0;

const styles = theme => ({
  flex: {
    flex: 1
  },
  logo: {
    color: colors.blue[500],
    // color: colors.grey[100], // 原色
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '-0.24px',
    lineHeight: '30px'
  },
  logoimage: {
    // color: colors.blue[500],
    color: colors.grey[100], // 原色
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
    height: 18,
  },
  toolbar: {
    display: 'flex',
    // paddingLeft: theme.spacing(3),
    height: theme.spacing(6),
    zIndex: 1500,
    '&:focus-visible': 'none',
    // ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarNoShift: {
    marginLeft: theme.spacing(5) + 1,
    width: `calc(100% - ${theme.spacing(5) + 1}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    top: AppBarHeight,
    width: drawerWidth,
    height: '100%',
    // backgroundColor: '#001529',
    color: theme.palette.menuText.normal,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: 8,
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
      '-webkit-box-shadow': 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 0,
      boxShadow: 0,
      '-webkit-box-shadow': 0,
      background: 'rgba(255, 255, 255, 0.0)'
    }
  },
  drawerOpen: {
    top: AppBarHeight,
    borderRight: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: '#001529',
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    top: AppBarHeight,
    borderRight: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: '#001529',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
  },
  paperDrawerOpen: {
    height: '100%'
  },
  paperDrawerClose: {
    height: '0px',
    overflow: 'hidden'
  },
  divider: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.text.primary
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  shiftitem: {
    paddingLeft: theme.spacing(2),
  },
  logoButton: {
    // color: 'rgba(43,64,87,1)',
    color: theme.palette.menuText.normal,
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: 0,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    width: '100%',
    height: theme.spacing(6),
    '&:focus': {
      outline: 'none'
    },
  },
  button: {
    // color: 'rgba(43,64,87,1)',
    color: theme.palette.menuText.normal,
    padding: '10px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    height: theme.spacing(5),
    flexGrow: 1,
    borderRadius: 0,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      color: theme.palette.menuText.hover,
    },

  },
  subButton: {
    backgroundColor: '#000C18',
    color: theme.palette.menuText.normal,
    '&:hover': {
      color: theme.palette.menuText.hover,
      backgroundColor: '#000C18',
    },
  },
  restSubButton: {
    backgroundColor: '#000306',
    color: theme.palette.menuText.normal,
    '&:hover': {
      color: theme.palette.menuText.hover,
      backgroundColor: '#000306',
    },
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  menuicon: {
    color: '#f2f2f2',
    width: 6,
    height: 6,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  shiftbuttonOpen: {
    color: theme.palette.divider,
    padding: '10px 8px',
    textAlign: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
  shiftbuttonClose: {
    color: theme.palette.secondary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '40px',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
  active: {
    // color: '#3458C7FF',
    // color: theme.palette.text.primary,
    color: theme.palette.menuText.active,
    backgroundColor: '#1890ff',
    '&:hover': {
      color: theme.palette.menuText.active,
      backgroundColor: '#1890ff',
    },
    // borderLeft: '3px solid #3458C7FF',
    '& $menuicon': {
      color: '#FF4A4AFF'
    },
    paddingLeft: '13px',
    fontWeight: 700
  },
  content: {
    height: '100%',
    // flex: '1 1 auto',
    padding: 8,
    backgroundColor: colors.grey[100],
  },
  root: {
    paddingTop: 42,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 50
    }
  },
  noshifContent: {
    // paddingLeft: theme.spacing(5) + 1,
  },
  shiftContent: {
    paddingLeft: drawerWidth + 12,
    height: 'calc(100vh - 50px)',
  },
  tenantInfoOpen: {
    position: 'fixed',
    bottom: '0',
    maxWidth: drawerWidth - 1,
    // backgroundColor: '#001529',
    borderRight: '1px solid rgba(0,0,0,0.1)',
  },
  tenantInfoClose: {
    position: 'fixed',
    bottom: '0',
    maxWidth: theme.spacing(2) + 1,
    // backgroundColor: '#001529',
    borderRight: '1px solid rgba(0,0,0,0.1)',
  },
  menuButtonContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  menuMainIcon: {
    marginRight: theme.spacing(1),
    display: 'inline-flex'
  },
  menuTitle: {
    fontSize: '14px',
  },
  flexGrow: {
    flexGrow: 1
  },

  // menuitem: {
  //   display: 'flex',
  //   flexWrap: 'nowrap',
  //   alignItems: 'center',
  // },
  // menuitemtitle: {
  //   flexGrow: 1,
  //   color: colors.blueGrey[800],
  //   fontWeight: theme.typography.fontWeightMedium,

  // },
  list: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'calc(100% - 100px)',
  },

  listClose: {
    display: 'none'
  },

  listlist: {
    padding: '0px 0px',
  }
});

const App = withStyles(styles)((props) => {
  const { classes,
    token,
    currentUserInfo,
    loginUserTenants,
    loginSelectedTenant,
    userProfile,
    expandedMenus,
    setFocusNode,
    onhandleExpand,
    menuOpen,
    openNotification
  } = props;

  const [openSidebar, setOpenSidebar] = useState(true);
  // const [expandChange, setExpandChange] = useState(false); 

  const handleDrawer = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleExpand = (route) => {
    if (route.children) {
      onhandleExpand(route)
    }
  };

  const CustomRouterLink = forwardRef((props, ref) => {
    var lid = "link_" + props.id;
    var np = {
      ...props,
      onClick: () => {
        setFocusNode(lid)
      }
    }
    return <div
      id={lid}
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <NavLink className={classes.navLink}{...np} />
    </div>
  });

  const tenantShiftArea = () => (
    <Grid item container direction="column" className={openSidebar ? classes.tenantInfoOpen : classes.tenantInfoClose}>
      <Divider className={classes.divider} />
      <Tooltip title="切换租户">
        <Grid item md={12} xs={12}>
          <ListItem
            className={classes.item}
            disableGutters
          >
            <Button
              className={openSidebar ? classes.shiftbuttonOpen : classes.shiftbuttonClose}
              variant="contained"
              component={CustomRouterLink}
              to="/tenantselect"
            >
              <div className={classes.icon}><CompareArrowsIcon /></div>
              {openSidebar ? (`当前租户: ${loginSelectedTenant.tenantName}`) : ''}
            </Button>
          </ListItem>
        </Grid>
      </Tooltip>
    </Grid>
  );

  const tenantArea = () => (
    <Grid item container direction="column" className={openSidebar ? classes.tenantInfoOpen : classes.tenantInfoClose}>
      <Divider className={classes.divider} />
      <Grid item md={12} xs={12}>
        <ListItem
          className={classes.item}
          disableGutters
        >
          <Grid
            className={openSidebar ? classes.shiftbuttonOpen : classes.shiftbuttonClose}
          >
            {openSidebar ? loginSelectedTenant.tenantName : ''}
          </Grid>
        </ListItem>
      </Grid>
    </Grid>
  );

  const slidebarFilter = (theRoutes) => {

    if (!theRoutes) {
      theRoutes = Routes;
    }

    let myRole = _.find(userProfile.roles, ['roleId', currentUserInfo.roleId])
    let myroutes = myRole ? myRole.routes : [];

    let filteredRoutes = _.filter(theRoutes, function (o) {

      // 如果子孙路由有权限，父级也渲染
      let hasChildRoutes = false
      if (o.children && o.children.length) {
        const allRoutes = treeToArray(o.children);
        hasChildRoutes = _.findIndex(myroutes, m => {
          return _.findIndex(allRoutes, a => a.id === m.route) > -1;
        }) > -1;
      }

      return _.findIndex(myroutes, m => { return !o.checkroute || o.id === m.route || hasChildRoutes; }) > -1;
    });
    return filteredRoutes;
  };

  const loopRoutes = (routes) => {
    let allRoutes = []
    for (let r of routes) {
      allRoutes.push(r)
      if (r.children) {
        allRoutes = allRoutes.concat(loopRoutes(r.children))
      }
    }
    return allRoutes
  }

  const extendRoutes = () => {
    let extendRoutes = loopRoutes(Routes)
    extendRoutes = _.filter(extendRoutes, function (o) {
      let myRole = _.find(userProfile.roles, ['roleId', currentUserInfo.roleId])
      let myroutes = myRole ? myRole.routes : [];
      return _.findIndex(myroutes, function (j) { return !o.checkroute || o.id === j.route; }) > -1;
    });
    return extendRoutes
  }

  const onOpenNewWindow = (event, target) => {
    window.open(target)
  }

  const generateMenu = (theRoutes) => {
    return _.reject(slidebarFilter(theRoutes), function (o) { return o.slide === false; }).map(Route => (
      <div key={Route.title} >
        <ListItem button
          className={classes.item}
          disableGutters
          key={Route.title}
          onClick={() => handleExpand(Route)}
        >
          {Route.children && Route.children.length && Route.children.length > 0 ?
            <Button
              className={clsx(classes.button, {
                [classes.subButton]: Route.level === 2,
                [classes.restSubButton]: Route.level > 2,
              })}
            ><Grid container className={
              clsx(classes.menuButtonContent, {
                // [classes.shiftitem]: Route.level !== 1,
              })} style={{ paddingLeft: 16 * (Route.level - 1) }}>
                {Route.Icon ? <Grid item className={classes.menuMainIcon}><Route.Icon /></Grid> : null}
                <Grid item><Typography className={classes.menuTitle}>{Route.title}</Typography></Grid>
                <Grid item className={classes.flexGrow} ></Grid>
                {!expandedMenus[Route.title] ? (<ChevronRight />) : (<ExpandMore />)}
              </Grid> </Button>
            : (
              Route.newWindow ? <Button
                className={
                  clsx(classes.button, {
                    [classes.subButton]: Route.level === 2,
                    [classes.restSubButton]: Route.level > 2,
                  })}
                onClick={event => onOpenNewWindow(event, Route.newWindow)}
              // activeClassName={classes.active}
              >
                <Grid container className={
                  clsx(classes.menuButtonContent, {
                  })}
                  style={{ paddingLeft: 16 * (Route.level - 1) }}>
                  {Route.Icon ? <Grid item className={classes.menuMainIcon}><Route.Icon /></Grid> : null}
                  <Grid item><Typography className={classes.menuTitle}>{Route.title}</Typography></Grid>
                  {/* <Grid item><LensIcon className={classes.menuicon} /></Grid> */}
                </Grid>
              </Button> : <Button
                className={
                  clsx(classes.button, {
                    [classes.subButton]: Route.level === 2,
                    [classes.restSubButton]: Route.level > 2,
                  })}
                component={CustomRouterLink}
                to={Route.href}
                id={Route.id}
                exact
                activeClassName={classes.active}
              >
                <Grid container className={
                  clsx(classes.menuButtonContent, {
                  })}
                  style={{ paddingLeft: 16 * (Route.level - 1) }}>
                  {Route.Icon ? <Grid item className={classes.menuMainIcon}><Route.Icon /></Grid> : null}
                  <Grid item><Typography className={classes.menuTitle}>{Route.title}</Typography></Grid>
                  {/* <Grid item><LensIcon className={classes.menuicon} /></Grid> */}
                </Grid>
              </Button>
            )}
        </ListItem>
        {Route.children && expandedMenus[Route.title] ? <div>{generateMenu(Route.children)}</div> : ''}
      </div >
    ))

  }

  return (
    <div
      // className={clsx({
      //   [classes.root]: true,
      //   [classes.shiftContent]: isDesktop
      // })}
      className={classes.root}
    >
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          // [classes.appBarShift]: openSidebar,
          // [classes.appBarNoShift]: !openSidebar,
        })}
        style={{ zIndex: Boolean(openNotification) ? 1500 : 1000 }}
      >
        <Topbar openSidebar={openSidebar} handleDrawer={handleDrawer} />
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openSidebar,
          [classes.drawerClose]: !openSidebar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openSidebar,
            [classes.drawerClose]: !openSidebar,
            [classes.paperDrawerOpen]: menuOpen,
            [classes.paperDrawerClose]: !menuOpen,
          }),
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <Button disabled
            className={classes.logoButton}
            onClick={handleDrawer}>
            <img className={classes.logoimage} src='/images/logos/u104.svg' alt='祥云' />
            <Typography variant="h1" className={classes.logo} noWrap>
              祥云
            </Typography>
          </Button>
        </div>
        <Divider className={classes.divider} />
        <div className={clsx(classes.drawer, {
          [classes.list]: menuOpen,
          [classes.listClose]: !menuOpen,
        })}>
          <List className={classes.listlist}>
            {generateMenu(Routes)}
          </List>
        </div>
        {/* {currentUserInfo.roleType !== 'admin' ? '' : (loginUserTenants.length === 1 ? tenantArea() : tenantShiftArea())} */}
        {menuOpen ? loginUserTenants.length > 0 ? (loginUserTenants.length === 1 ? tenantArea() : tenantShiftArea()) : '' : null}
      </Drawer>
      <Grid item className={clsx(classes.content, {
        [classes.shiftContent]: menuOpen,
        [classes.noshifContent]: !menuOpen
      })}>
        <Switch>
          {extendRoutes().map((item, index) => {
            return <Route key={index} exact path={item.href} render={props => (
              !item.auth
                ? (<item.component {...props} />)
                : (token !== ''
                  ? <item.component {...props} />
                  : <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                  }} />
                )
            )
            } />;
          })}
          <Route path="/404" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Grid>
      <MessagePage />
      <ModalPage />
      <ConfirmPage />
      <LoadingPage />
    </div >
  );
});

class APPContainer extends Component {



  componentDidMount() {

    if (this.props.focusNode) {
      var node = document.getElementById(this.props.focusNode)
      if (node) {
        node.scrollIntoView();
      }
    }
    if (this.props.location.pathname.indexOf("/installationManagement/map/") !== -1 ||
      this.props.location.pathname.indexOf("/installationManagement/mapview/") !== -1) {
      this.props.updateMenuOpen(false);
    } else {
      this.props.updateMenuOpen(true);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname.indexOf("/installationManagement/map/") !== -1 ||
        this.props.location.pathname.indexOf("/installationManagement/mapview/") !== -1) {
        this.props.updateMenuOpen(false);
      } else {
        this.props.updateMenuOpen(true);
      }
    }
  }

  render() {
    return (<AntDConfigProvider locale={zhCN}><App {...this.props} /></AntDConfigProvider>);
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.jwt,
    currentUserInfo: state.auth.currentUserInfo,
    loginUserTenants: state.auth.loginUserTenants,
    loginSelectedTenant: state.auth.loginSelectedTenant,
    userProfile: state.auth.userProfile,
    expandedMenus: state.menu.expandedMenus,
    menuOpen: state.menu.menuOpen,
    openNotification: state.websocket.openNotification,
    focusNode: state.menu.focusNode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleExpand: (route) => {
      dispatch(setExpandedMenu(route));
    },
    updateMenuOpen: (value) => {
      dispatch(updateMenuOpen(value));
    },
    setFocusNode: (value) => {
      dispatch(setFocusNode(value));
    },
  };
};

App.defaultProps = {
  expandedMenus: {},
}
export default connect(mapStateToProps, mapDispatchToProps)(APPContainer);
