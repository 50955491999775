import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RegulationUploadPannel from '../../../components/LeadershipManagement/RegulationPlanExecution/RegulationUploadPannel';
import { closeRegulationUploadPannel, changePlanDetail } from '../../../redux/modules/regulationUpload';
import { getBusinessUnitsByRole, } from "../../../utils/userUtils";

class RegulationUploadPannelContainer extends Component {
    static propTypes = {
        planDetail: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    handleConfirm() {
        const { confirmCallback, planDetail, onhandleClose } = this.props;
        if (confirmCallback) confirmCallback(planDetail)
        if (onhandleClose) onhandleClose();

    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, planDetail } = this.props
        // console.log(event)

        let newDetail = {
            ...planDetail,
        }
        newDetail[event.target.name] = event.target.value
        onhandleChange(newDetail)
    }

    render() {
        return (
            <RegulationUploadPannel
                open={this.props.open}
                constant={this.props.constant}
                planDetail={this.props.planDetail}
                businessUnits={this.props.businessUnits}
                regulations={this.props.regulations}
                onhandleChange={this.handleChange.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.regulationUpload.open,
        constant: state.constant,
        regulations: state.regulation.regulations,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        planDetail: state.regulationUpload.planDetail,
        confirmCallback: state.regulationUpload.confirmCallback,
        closeCallback: state.regulationUpload.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (planDetail) => {
            dispatch(changePlanDetail(planDetail));
        },
        onhandleClose: () => {
            dispatch(closeRegulationUploadPannel());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationUploadPannelContainer);