import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssayRecordEditComponent from '@/components/ManufactureManagement/AssayRecordEdit';
import { convertToMoment } from '@/utils/datetime';
import {  createAssayRecord, updateAssayRecord, setCurrentRecord} from '@/redux/modules/manufacture/assay';
import { cleanmodal } from '@/redux/modules/modal';
import * as _ from 'lodash';

class AssayRecordEditContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recordError: {
                testTarget: ''
            }
        }
    }

    handleCheck = (event, field) => {
        if (!field || field === 'testTarget') {
            if (_.trim(this.props.record.testTarget) === '') {
                this.setState({
                    recordError: {
                        ...this.state.recordError,
                        testTarget: "物料名称不能为空"
                    }
                })
            } else {
                this.setState({
                    recordError: {
                        ...this.state.recordError,
                        testTarget: ""
                    }
                })
            }
        }
    }

    handleItemChange = (event, field) => {
        const item = _.find(this.props.record.details, function (o) { return o.itemId === field });
        if (_.trim(event.target.value) !== '') {
            if (item) {
                item.itemVal = _.trim(event.target.value)
                const newRecord = {
                    ...this.props.record
                }
                this.props.setCurrentRecord(newRecord)
            } else {
                const newRecord = {
                    ...this.props.record,
                    details: [...this.props.record.details, { itemId: field, itemVal: _.trim(event.target.value) }]
                }
                this.props.setCurrentRecord(newRecord)
            }
        } else {
            if (item) {
                _.remove(this.props.record.details, function (o) {
                    return o.itemId === item.itemId
                })
                const newRecord = {
                    ...this.props.record,
                    details: [...this.props.record.details, { itemId: field, itemVal: _.trim(event.target.value) }]
                }
                this.props.setCurrentRecord(newRecord)
            }
        }
    }

    handleChange = (event, field) => {
        if (field === 'companyId') {
            const newRecord = {
                ...this.props.record,
                companyId: parseInt(event.target.value),
                dptId: 0,
                installId: 0
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'dptId') {
            const newRecord = {
                ...this.props.record,
                dptId: parseInt(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'installId') {
            const newRecord = {
                ...this.props.record,
                installId: parseInt(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'tempId') {
            const newRecord = {
                ...this.props.record,
                tempId: parseInt(event.target.value),
                details: []
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'testTarget') {
            const newRecord = {
                ...this.props.record,
                testTarget: _.trim(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'assayer') {
            const newRecord = {
                ...this.props.record,
                assayer: parseInt(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'inspector') {
            const newRecord = {
                ...this.props.record,
                inspector: parseInt(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'extFee') {
            let extFee = 0;
            if (event.target.value !== ''){
                extFee = parseFloat(event.target.value);
            }
            const newRecord = {
                ...this.props.record,
                extFee: extFee
            }
            this.props.setCurrentRecord(newRecord)
        } else if (field === 'opentext') {
            const newRecord = {
                ...this.props.record,
                opentext: _.trim(event.target.value)
            }
            this.props.setCurrentRecord(newRecord)
        }
    }

    handleDateChange = (date, field) => {
        const newRecord = {
            ...this.props.record,
            testTime: date
        }
        this.props.setCurrentRecord(newRecord)
    }

    handleSave = () => {
        let tempId = this.props.record.tempId;
        var temp = _.find(this.props.temps, function (o) { return o.tempId === tempId })
        let details = this.props.record.details;
        let newDetails = [];
        for (let idx in details) {
            var item = _.find(temp.items, function (d) { return d.itemId === details[idx].itemId })
            let newItem = {}
            newItem.itemVal = details[idx].itemVal;
            newItem.itemId = details[idx].itemId;
            newItem.overTime = false
            if (item.computable) {
                newItem.computable = item.computable;
                newItem.unitPrice = item.unitPrice;
                if (item.extPrice) {
                    newItem.extPrice = item.extPrice
                }
            } else {
                newItem.computable = false
            }
            newDetails.push(newItem)
        }
        if (!this.props.record.dptId) {
            delete this.props.record.dptId
        }
        if (!this.props.record.installId) {
            delete this.props.record.installId
        }
        if (!this.props.record.assayer) {
            delete this.props.record.assayer
        }
        if (!this.props.record.inspector) {
            delete this.props.record.inspector
        }

        if (this.props.record.recordId === 0) {
            const newRecord = {
                ...this.props.record,
                details: [...newDetails]
            }
            this.props.onhandleCreateRecord(newRecord, this.props.parameters.callback)
        } else {
            delete this.props.record.deviceId;
            this.props.record.testTime = convertToMoment(this.props.record.testTime)
            const newRecord = {
                ...this.props.record,
                details: [...newDetails]
            }
            this.props.onhandleUpdateRecord(newRecord, this.props.parameters.callback)
        }
        this.props.onhandleCancel();
    }

    handleCancel = (data) => {
        this.props.onhandleCancel();
    }

    render() {
        return (
            <div>
                <AssayRecordEditComponent
                    record={this.props.record}
                    companyList={this.props.companies}
                    installList={this.props.installations}
                    assayers={this.props.assayers}
                    inspectors={this.props.inspectors}
                    temps={this.props.temps}
                    recordError={this.state.recordError}
                    departments={this.props.departments}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleCancel={this.handleCancel.bind(this)}
                    onhandleItemChange={this.handleItemChange.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        record: state.assay.currentRecord,
        temps: state.assay.assayTemps,
        assayers: state.assay.assayers,
        inspectors: state.assay.inspectors,
        parameters: state.modal.parameters,
        userInfo: state.auth.currentUserInfo,
        installations: state.installation.installationList ? state.installation.installationList.list : [],
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCancel: () => {
            dispatch(cleanmodal())
        },
        setCurrentRecord: (data) => {
            dispatch(setCurrentRecord(data));
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updateAssayRecord(record, callback));
        },
        onhandleCreateRecord: (record, callback) => {
            dispatch(createAssayRecord(record, callback));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssayRecordEditContainer);
