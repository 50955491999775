import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from './styles';

function ConfirmPage(props) {
  const { classes, open, title, text, onhandleConfirm, onhandleClose } = props;

  return (
    <Dialog
      open={open?open:false}
      onClose={onhandleClose}
      maxWidth={'md'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={onhandleClose}
        >
          取消
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.confirmButton}
          onClick={onhandleConfirm}
          autoFocus
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPage.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onhandleConfirm: PropTypes.func,
  onhandleClose: PropTypes.func
};

export default withStyles(ConfirmPage);
