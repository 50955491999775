import { withStyles } from '@material-ui/styles';
import { stripedTableStyle } from '@/utils/antdCustomStyle';

const styles = theme => ({
    flexTextField: {
        margin: theme.spacing(2),
    },
    title: {
        padding: theme.spacing(4)
    },
    button: {
        margin: theme.spacing(1)
    },
    materialtable: {
        boxShadow: 'none'
    },
    flexgrow: {
        flexGrow: 1
    },
    topflex: {
        marginLeft: '10px',
        marginTop: '15px',
    },
    selectbox: {
        paddingLeft: '8px',
        paddingTop: '8px',
    },
    stripedTable: stripedTableStyle
});

export default withStyles(styles);