import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RuleSetting from '../../../components/LeadershipManagement/SafetyActivity/RuleSetting/RuleSetting';

import { addRule, getRules, updateRule } from "../../../redux/modules/safetyActivity";
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { getNowUTC, getUTCByString } from "../../../utils/datetime";
import moment from 'moment'

class RuleSettingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        onHandleAddRule: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            editRule: {},
            showDetail: false,
            currentUser: this.props.auth.currentUserInfo
        }
        this.detailTitle = '';
        this.selectDate = null;
    }
    units = {
        'day':'日',
        'week':'周',
        'month':'月',
        'season':'季度',
        'year':'年'
    };

    componentDidMount() {
        const { 
            onhandleGetRules } = this.props

        onhandleGetRules()
        moment.locale('zh-cn')
    }

    getRuleAttr(rule, isUnit){
        const index = rule.indexOf('/');
        return isUnit ? rule.substr(index+1) : rule.substr(0, index);
    }

    // 编辑列表中规则的显示内容
    getDisplayRuleContent(rule){
        const times = this.getRuleAttr(rule, false);
        const unitEn = this.getRuleAttr(rule, true);
        const unitStr = this.units[unitEn];
        return '每'+ unitStr + times + '次';
    }

    // ruleData = [
    //     {
    //     rule_id: 1,
    //     rule_name:'测试规则名001',
    //     rule_type:'带班值班规则',
    //     activity_type: '0',
    //     rule:'1/day',            
    //     creator:'创建者001',
    //     create_time: '2020-04-18',
    //     revisor:'修订者001',
    //     update_time: '2020-04-18'},
    //     {
    //     rule_id:2,
    //     rule_name:'测试规则名002',
    //     rule_type:'带班值班规则',
    //     activity_type: '2',
    //     rule:'2/season',
    //     creator:'创建者002',
    //     create_time: '2020-04-18',
    //     revisor:'修订者001',
    //     update_time: '2020-04-18'}
    // ];

    handleEditRule(rule) {
        this.setState({editRule: rule, showDetail: true})
    }
    handleCancelEditRule(){
        this.setState({editRule: {}, showDetail: false})
    }

    handleSaveRule(rule) {
        if(rule.ruleId === 0){
            rule.creator = this.state.currentUser.userId
            rule.revisor = this.state.currentUser.userId
            rule.updateTime = getNowUTC()
            rule.createTime = getNowUTC()
            this.props.onHandleAddRule(rule, (data)=>{
                // console.log(data)
                this.handleCancelEditRule()
                this.props.onhandleGetRules()
            })

        } else {
            rule.revisor = this.state.currentUser.userId            
            rule.updateTime = getNowUTC()
            rule.createTime = getUTCByString(rule.createTime)
            this.props.onhandleUpdateRule(rule, (data)=>{
                // console.log(data)
                this.handleCancelEditRule()
                this.props.onhandleGetRules()
            })
        }
    }

    handleDeleteRule(rule) {
        rule.revisor = this.state.currentUser.userId            
        rule.updateTime = getNowUTC()
        rule.createTime = moment().toDate(rule.createTime)
        rule.status = 'inactive'
        this.props.onhandleUpdateRule(rule, (data)=>{
            // console.log(data)
            this.handleCancelEditRule()
            this.props.onhandleGetRules()
        })
    }

    render() {
        // if (this.state.loading) return ''
        const { ruleData } = this.props
        if(!ruleData) return ''
        ruleData.forEach(rule=>{
            rule.rule_display = this.getDisplayRuleContent(rule.rule);
            // rule.createTime = getUTCByString(rule.createTime)
            // rule.updateTime = getUTCByString(rule.updateTime)
        });
        return (
            <div>
                <RuleSetting
                    showDetail={this.state.showDetail}
                    ruleData={ruleData}
                    editRule={this.state.editRule}
                    onHandleEditRule={this.handleEditRule.bind(this)}
                    onHandleCancelEditRule={this.handleCancelEditRule.bind(this)}
                    onHandleSaveRule={this.handleSaveRule.bind(this)}
                    onHandleDeleteRule={this.handleDeleteRule.bind(this)}
                    onhandleOpenConfirmDialog={this.props.onhandleOpenConfirmDialog.bind(this)}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ruleData: state.personalActivity.activityRules,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetRules: (callback) => {
            dispatch(getRules(callback))
        },
        onHandleAddRule: (rule, callback) => {
            dispatch(addRule(rule, callback))
        },
        onhandleUpdateRule: (rule, callback) => {
            dispatch(updateRule(rule, callback))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleSettingContainer);