import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceList from "../../../components/DeviceManagement/DeviceInfo/DeviceList";
import PropTypes from "prop-types";
import { getDeviceList, setSearch, initDeviceList, initUpdate, initView, changeDeviceStatus, copyInitEdit, initBrowse } from '../../../redux/modules/devices';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { createMessage } from '../../../redux/modules/message';
import { createmodal } from '../../../redux/modules/modal';

class DeviceListContainer extends Component {
    static propTypes = {
        users: PropTypes.object,
        search: PropTypes.object,
        userProfile: PropTypes.object,
        devices: PropTypes.object,
        catList: PropTypes.array,
        useStatusTree: PropTypes.array,
    };

    componentWillMount() {
        this.props.onhandleInitDeviceList();
    }

    handleEdit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleCopy(event, rowData) {
        this.props.onhandleCopy(rowData);
    }

    handleBrowse(event, id) {
        this.props.onhandleBrowse(id);
    }

    handleRecord(event, id) {
        let param = {
            deviceId: id,
        }
        this.props.onhandleDialog(param);
    }

    handleCharts(event, id) {
        let param = {
            deviceId: id
        }
        this.props.onhandleCharts(param);
    }

    handleOpsRecords(event, params) {
        this.props.onhandleOpsRecords(params);
    }


    handleView(event, id) {
        this.props.onhandleView(id);
    }

    handleDelete = (event, rowData) => {
        if (!rowData.groupId) {
            let changeStatus = () => {
                this.props.onhandleChangeStatus(rowData);
            }
            this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除设备[' + rowData.deviceNo + ']吗?', changeStatus, null, false);
        } else {
            this.props.onhandleDeleteDevicePre()
        }
    };

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    // handleExportCsv = () => {
    //     this.props.onhandleExportCsv()
    // }

    // handleCreate(event) {
    //     this.props.onhandleCreate()
    // }

    useStatusMap = () => {
        let result = {}
        if (this.props.useStatusTree && this.props.useStatusTree.length > 0) {
            for (let i = 0; i < this.props.useStatusTree.length; i++) {
                let label = this.props.useStatusTree[i].label;
                let value = this.props.useStatusTree[i].value;
                result[value] = label
            }
        }
        return result
    }

    catMap = () => {
        let result = {}
        if (this.props.catList && this.props.catList.length > 0) {
            for (let i = 0; i < this.props.catList.length; i++) {
                let label = this.props.catList[i].name;
                let value = this.props.catList[i].code;
                result[value] = label
            }
        }
        return result
    }

    render() {
        return (
            <DeviceList
                userProfile={this.props.userProfile}
                onEdit={this.handleEdit.bind(this)}
                onCopy={this.handleCopy.bind(this)}
                getDeviceList={this.props.onhandleGetDeviceList}
                search={this.props.search}
                onDelete={this.handleDelete.bind(this)}
                onBrowse={this.handleBrowse.bind(this)}
                onRecord={this.handleRecord.bind(this)}
                devices={this.props.devices}
                catList={this.props.catList}
                useStatusTree={this.props.useStatusTree}
                onSizeChange={this.handleListSize.bind(this)}
                onView={this.handleView.bind(this)}
                useStatusMap={this.useStatusMap()}
                catMap={this.catMap()}
                // exportCsv={this.handleExportCsv.bind(this)}
                // onhandleCreate={this.handleCreate.bind(this)}
                onCharts={this.handleCharts.bind(this)}
                onOpsRecords={this.handleOpsRecords.bind(this)}
                // 根据设备类别决定的“位号”显示名称
                tagNoTitle={this.props.tagNoTitle}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.devices.search,
        userProfile: state.auth.userProfile,
        devices: state.devices.devicelist,
        // catTree: state.trees.deviceCategoryTree,
        catList: state.trees.deviceCategoryList,
        useStatusTree: state.devices.useStatusTree,
        // 根据设备类别决定的“位号”显示名称
        tagNoTitle: state.devices.tagNoTitle,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitDeviceList: () => {
            dispatch(initDeviceList());
        },
        onhandleGetDeviceList: (query) => {
            dispatch(getDeviceList(query))
        },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleCopy: (rowData) => {
            dispatch(copyInitEdit(rowData));
        },
        onhandleView: (id) => {
            dispatch(initView(id));
        },
        onhandleChangeStatus: (rowData) => {
            dispatch(changeDeviceStatus(rowData));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleDeleteDevicePre: () => {
            dispatch(createMessage('error', '请从装置移除后，再删除设备'));
        },
        // onhandleExportCsv: () => {
        //     dispatch(exportCsv());
        // },
        // onhandleCreate: () => {
        //     dispatch(initCreate());
        // },
        onhandleBrowse: (id) => {
            dispatch(initBrowse(id));
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('accessoryReplaceRecord', param, 'large'))
        },
        onhandleCharts: (param) => {
            dispatch(createmodal('onsiteInspectionStatistics', param, 'large'))
        },
        onhandleOpsRecords: (param) => {
            dispatch(createmodal('deviceOpsRecord', param, 'large'))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceListContainer)
