import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'
import { csv } from '../../utils/exportfile';

const porxyApi = '/api';
const initialState = {
    records: [],
    paymentTargets: []
};

export const setPaymentRecords = createAction('@@xcloud/payment/setPaymentRecords');
export const setPaymentTargets = createAction('@@xcloud/payment/setPaymentTargets');

export const exportTemplate = createAction('@@xcloud/payment/exportTemplate',
    (companyId, year, month) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/users?userType=1&offset=0&size=0&companyId=' + companyId
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    let fileName = '返聘人员工资'
                    let users = res.data.list;
                    let data = [];
                    let columns = ['公司ID', '姓名', '手机号', '基础劳务费', '绩效劳务费', '夜班津贴', '加奖', '节日工资', '加班工资', '上岗津贴', '通勤补贴', '应得工资', '扣税款', '实发金额', '年', '月']
                    for (let i = 0; i < users.length; i++) {
                        let cell = [
                            companyId,
                            users[i].lastName + users[i].firstName,
                            users[i].phone,
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            year,
                            month
                        ];
                        data.push(cell)
                    }
                    dispatch(csv(fileName, columns, data));
                }
                return res.status;
            }).catch(err => {
                let msg = `获取返聘人员列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const exportLabourTemplate = createAction('@@xcloud/payment/exportLabourTemplate',
    (companyId, year, month) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/users?userType=3&offset=0&size=0&companyId=' + companyId
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    let fileName = '劳务人员工资'
                    let users = res.data.list;
                    let data = [];
                    let columns = ['公司ID', '姓名', '手机号', '日工资', '保险费用', '餐补', '管理费用', '年', '月', '日']
                    for (let i = 0; i < users.length; i++) {
                        let cell = [
                            companyId,
                            users[i].lastName + users[i].firstName,
                            users[i].phone,
                            0, 0, 0, 0,
                            year,
                            month,
                            1
                        ];
                        data.push(cell)
                    }
                    dispatch(csv(fileName, columns, data));
                }
                return res.status;
            }).catch(err => {
                let msg = `获取劳务人员列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const initPayment = createAction('@@xcloud/payment/initPayment',
    (userType, pid, companyId, userId, year, month, offset, size, sort) =>
        (dispatch, getState, httpClient) => {
            Promise.all([
                dispatch(getPaymentRecords(userType, companyId, userId, year, month, offset, size, sort)),
                dispatch(getPaymentTargets(userType, pid, companyId)),
            ]).catch(err => {
                let msg = '初始化返聘工资管理失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return null;
            });
        });

export const getPaymentTargets = createAction('@@xcloud/payment/getPaymentTargets',
    (userType, pid, companyId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/users?userType=' + userType + '&offset=0&size=0'
            if (pid) {
                url += '&companyId=' + companyId
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setPaymentTargets(res.data.list))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取返聘人员列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getPaymentRecords = createAction('@@xcloud/payment/getPaymentRecords',
    (userType, companyId, userId, year, month, offset, size, sort) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/payments/records?companyId=' + companyId
            if (userId) {
                url += '&userId=' + userId
            }
            if (userType) {
                url += '&userType=' + userType
            }
            if (year && month) {
                url += '&year=' + year + '&month=' + month
            }
            if (offset) {
                url += '&offset=' + offset
            } else {
                url += '&offset=0'
            }
            if (size) {
                url += '&size=' + size
            } else {
                url += '&size=0'
            }
            if (sort) {
                url += '&sort=' + sort
            }
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setPaymentRecords(res.data.list))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取工资记录列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const importPaymentRecords = createAction('@@xcloud/payment/importPaymentRecords',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(porxyApi + '/payments/importrecords', record).then(
                (res) => {
                    if (callback) {
                        callback(res.data)
                    }
                    dispatch(createhttpMessage(res, "导入工资记录成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `创建导入记录失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const createPaymentRecord = createAction('@@xcloud/payment/createPaymentRecord',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.post(porxyApi + '/payments/records', record).then(
                (res) => {
                    if (callback) {
                        callback(res.data)
                    }
                    dispatch(createhttpMessage(res, "创建工资记录成功"));
                    return res.status;
                }).catch(err => {
                    let msg = `创建工资记录失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });


export const updatePaymentRecord = createAction('@@xcloud/payment/updatePaymentRecord',
    (record, callback) =>
        (dispatch, getState, httpClient) => {
            return httpClient.patch(porxyApi + '/payments/records/' + record.payId, record).then(
                (res) => {
                    if (callback) {
                        callback(res.data)
                    }
                    dispatch(createhttpMessage(res, "更新工资记录成功"))
                    return res.status;
                }).catch(err => {
                    let msg = `更新工资记录失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const paymentReducer = handleActions(
    {
        [setPaymentRecords]: (state, { payload: data }) => {
            return {
                ...state,
                records: data
            }
        },
        [setPaymentTargets]: (state, { payload: data }) => {
            return {
                ...state,
                paymentTargets: data
            }
        },
    },
    initialState
)

export default paymentReducer
