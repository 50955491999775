import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    devices: [], //能源设备列表
};

export const setDevices = createAction('@@xcloud/amoeba/setDevices');

export const getEnergyDevices = createAction('@@xcloud/amoeba/getDevices',
    (energyType) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/devices'
        let strs = []
        if (energyType) {
            if (energyType) strs.push('energyType=' + energyType)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            console.log(res.data);
            if (res) {
                dispatch(setDevices(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取能源设备列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addDevice = createAction('@@xcloud/amoeba/addDevice',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/devices`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "能源设备生成成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `能源设备生成失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateDevice = createAction('@@xcloud/amoeba/updateDevice',
    (energyDeviceId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/devices/${energyDeviceId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新能源设备成功"));
                }
            }).catch(err => {
                let msg = `更新能源设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteDevice = createAction('@@xcloud/amoeba/deleteDevice',
    (energyDeviceId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/amoeba/devices/${energyDeviceId}`)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除能源设备成功"));
                }
            }).catch(err => {
                let msg = `删除能源设备失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const amoebaDeviceReducer = handleActions(
    {
        [setDevices]: (state, { payload: data }) => {
            return {
                ...state,
                devices: data
            }
        },
    },
    initialState
)

export default amoebaDeviceReducer
