import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddDetailPannel from '../../../components/LeadershipManagement/RegulationPlan/AddDetailPannel';
import { closePlanDetailAddPannel, changePlanDetail, initPlanDetail, setRulePlanDetailError } from '../../../redux/modules/leadershipPlanDetailAdd';
import { getBusinessUnitsByRole, } from "../../../utils/userUtils";
import { getUTCByString } from "../../../utils/datetime";
import _ from 'lodash';

class RegulationPlanDetailAddPannelContainer extends Component {
    static propTypes = {
        planDetail: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    componentDidMount() {
        this.props.onhandleInitPlanDetail();
    }

    handleConfirm() {
        if (this.handleCheck()) {
            const { confirmCallback, planDetail, onhandleClose } = this.props;
            if (confirmCallback) confirmCallback(planDetail)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, planDetail } = this.props

        let newDetail = {
            ...planDetail,
        }
        const field = event.target.name;
        newDetail[event.target.name] = event.target.value
        onhandleChange(newDetail)
        setTimeout(() => {
            this.handleCheck(field);
        }, 100)
    }

    handleCheck = (field) => {

        const {
            planDetail,
            onhandleSetRulePlanDetailError,
            planDetailError
        } = this.props;

        let err = {};
        if (!planDetail.planType || planDetail.planType === 'Add') {
            if (!field || field === 'regName') {
                if (_.trim(planDetail.regName) === '') {
                    err['regName'] = '请输入公司规章制度名称';
                } else {
                    err['regName'] = '';
                }
            }

            if (!field || field === 'revisionCycle') {
                if (_.trim(planDetail.revisionCycle) === '' || _.toNumber(planDetail.revisionCycle) === 0) {
                    err['revisionCycle'] = '请输入修订周期';
                } else {
                    err['revisionCycle'] = '';
                }
            }
        } else {
            if (!field || field === 'regId') {
                if (_.trim(planDetail.regId) === '' || planDetail.regId === 0) {
                    err['regId'] = '请选择公司规章制度';
                } else {
                    err['regId'] = '';
                }
            }
        }

        if (!field || field === 'content') {
            if (_.trim(planDetail.content) === '') {
                err['content'] = '请输入修改建议';
            } else {
                err['content'] = '';
            }
        }

        let newError = {
            ...planDetailError,
            ...err
        }
        onhandleSetRulePlanDetailError(newError);
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    handleDateChange(date) {
        const { onhandleChange, planDetail } = this.props
        onhandleChange({
            ...planDetail,
            targetDate: getUTCByString(date)
        })
    }

    render() {
        return (
            <AddDetailPannel
                open={this.props.open}
                constant={this.props.constant}
                planDetail={this.props.planDetail}
                planDetailError={this.props.planDetailError}
                businessUnits={this.props.businessUnits}
                regulations={this.props.regulations}
                onhandleChange={this.handleChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.leadershipPlanDetailAdd.open,
        constant: state.constant,
        regulations: state.regulation.regulations,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        planDetail: state.leadershipPlanDetailAdd.planDetail,
        planDetailError: state.leadershipPlanDetailAdd.planDetailError,
        confirmCallback: state.leadershipPlanDetailAdd.confirmCallback,
        closeCallback: state.leadershipPlanDetailAdd.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (planDetail) => {
            dispatch(changePlanDetail(planDetail));
        },
        onhandleClose: () => {
            dispatch(closePlanDetailAddPannel());
        },
        onhandleInitPlanDetail: () => {
            dispatch(initPlanDetail());
        },
        onhandleSetRulePlanDetailError: (value) => {
            dispatch(setRulePlanDetailError(value));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanDetailAddPannelContainer);