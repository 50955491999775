import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Button,
    ButtonGroup,
    Tooltip,
    Grid,
    Typography,
    TextField,
    MenuItem,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RestoreIcon from '@material-ui/icons/Restore';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate } from '@/utils/datetime'
import zhCN from 'date-fns/locale/zh-CN';
import { getCodeName, TASK_STATUS } from '@/utils/manufactureUtils';
import _ from 'lodash';

const MOrderListComponent = props => {
    const {
        classes,
        manufactureOrders,
        departments,
        companyList,
        companyTree,
        onhandleSelectChange,
        search,
        onhandleDateChange,
        onhandleRowUpdate,
        manufactureDicts,
        // onhandleRowDelete,
        indexes,
        tasks,
        // members,
        isMainCompany,
        onhandleUpdate,
        onhandleDelete,
        // onhandleAdd,
        onhandlePopAdd,
        onhandleOpenFixAmount,
        onhandleOpenReport,
    } = props;

    const [serviceId, setServiceId] = useState(0);

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,

    }
    const tableStyle = {
        ...style
    }

    const getStatus = (code) => {
        const status = manufactureDicts.find(item => {

            return item.type === 'ORDER_STATUS' && item.code === code
        });
        return status === undefined ? '' : status.name
    }

    // const getStatuss = () => {
    //     let statuss = {};
    //     manufactureDicts.forEach(
    //         (item) => {
    //             if (item.type === 'ORDER_STATUS') {
    //                 statuss[item.code] = item.name;
    //             }
    //         }
    //     )
    //     return statuss ? statuss : ''
    // }

    const getServiceData = (companyId) => {
        // let company = _.isObject(companyId) ? companyId.value : companyId

        // let item = _.filter(manufactureOrders, function (o) { return _.toInteger(company) === o.companyId })
        let item = manufactureOrders
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                const m = t.manufactureService;
                result.push({ label: m.serviceName, value: m.serviceId })
            })
        }
        return _.uniqBy(result, 'value')
    }

    const getDptData = (companyId) => {
        let company = _.isObject(companyId) ? companyId.value : companyId

        let item = _.filter(departments, function (o) { return _.toInteger(company) === o.companyId })
        let result = []
        if (item && item.length) {
            _.map(item, t => {
                result.push({ label: t.dptName, value: t.dptId })
            })
        }
        return result
    }

    const companyDisabled = () => {
        if (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0)) {
            return true
        }

        return false
    }

    // 如果下拉选框的选项列表为空时：禁用； 不为空时：可用
    const noOptionDisabled = (optionList) => {
        if (optionList && optionList.length > 0) {
            return false
        }

        return true
    }

    const getDptName = (dptId) => {
        const department = departments.find(item => {
            return item.dptId === dptId
        });
        return department === undefined ? '' : (isMainCompany ? department.companyDptName : department.dptName)
    }

    // const getExecutorName = (executor) => {
    //     let dto = members.find(ele => ele.userId === executor);
    //     return dto ? dto.lastName + dto.firstName : ''
    // }

    const renderServiceName = (row) => {

        let order = _.find(tasks, { 'orderId': row.orderId })
        if (order) return order.serviceName
        return ''

    }

    const renderAllocation = (row) => {
        if (indexes.length > 0 && row.manufactureService.amoebaIndex === indexes[0].subId && row.manufactureService.valuationType === 'BUDGET') {
            return (row.allocation * 100).toFixed(2)
        } else if (row.manufactureService.serviceType === 'COMMISSION') {
            if (row.allocation) {
                return row.allocation.toFixed(2)
            } else {
                return ''
            }
        } else {
            if (row.allocation) {
                return row.allocation.toFixed(2)
            } else {
                return ''
            }
        }
    }
    const renderCompleteOutput = (row) => {
        if (row.completeOutput) {
            return row.completeOutput.toFixed(2)
        }
        else {
            return ''
        }
    }

    const renderTitle = () => {
        const { fromDate, toDate } = search;
        if (!fromDate || !toDate) {
            return '生产班组日工单'
        }
        const fromDateStr = formatDate(fromDate)
        const toDateStr = formatDate(toDate)
        if (fromDateStr === toDateStr) {
            return fromDateStr + ' 生产班组日工单'
        }
        if (fromDateStr !== toDateStr) {
            return fromDateStr + '~' + toDateStr + ' 生产班组日工单'
        }
    }

    const renderAmount = (row) => {
        return row.amount ? row.amount.toFixed(2) : ''
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '工单编号',
                field: 'orderNo',
                render: row => row ? <Typography>{row.orderNo}</Typography> : '',
                editable: 'never',
            },
            {
                title: '班组',
                field: 'dptName',
                render: row => row.manufactureService ? <Typography>{getDptName(row.dptId)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        select
                        value={props.rowData.dptId ? props.rowData.dptId : ''}
                        name='dptId'
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.dptId = e.target.value;
                            props.onRowDataChange(data);
                        }}>
                        {props.rowData.manufactureService.serviceDpts.map((item) => {
                            return <MenuItem key={item.dptId} value={item.dptId}>
                                {getDptName(item.dptId)}
                            </MenuItem>
                        })}
                    </TextField>
                )
            },
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{renderServiceName(row)}</Typography>,
                editable: 'never'
            },
            // {
            //     title: '产品',
            //     field: 'product',
            //     render: row => row.manufacturePlanDtl ? <Typography>{row.manufacturePlanDtl.product}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '计划量',
                field: 'allocation',
                render: row => row ? <Typography>{renderAllocation(row)}</Typography> : '',
                type: 'numeric',
                align: "right",
                width: 60
            },
            {
                title: '完成量',
                field: 'completeOutput',
                render: row => row ? <Typography>{renderCompleteOutput(row)}</Typography> : '',
                type: 'numeric',
                editable: 'never',
                align: "right",
                width: 60
            },
            {
                title: '单位',
                field: 'unit',
                render: row => row ? <Typography>{indexes.length > 0 && row.manufactureService.amoebaIndex === indexes[0].subId && row.manufactureService.valuationType === 'BUDGET' ? '%' : row.manufactureService.unit}</Typography> : '',
                type: 'numeric',
                editable: 'never',
            },
            {
                title: '金额(元)',
                field: 'amount',
                render: row => row ? <Typography>{renderAmount(row)}</Typography> : '',
                editable: 'never',
                align: "right",
                width: 60
            },
            {
                title: '时间',
                field: 'startTime',
                render: row => row ? <Typography>{formatDate(row.startTime)}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '结束时间',
            //     field: 'endTime',
            //     render: row => row ? <Typography>{row.endTime}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{getStatus(row.status)}</Typography> : '',
                // lookup: { ...getStatuss() },
                editable: 'never',
            }
        ]

        return columns
    }

    const assemblyData = () => {
        return manufactureOrders.filter(order => {
            if (!serviceId) {
                return true
            }
            return order.manufactureService.serviceId === serviceId
        })
    }

    const assemblycolumnsTask = () => {

        const columns = [
            // {
            //     title: '分厂',
            //     field: 'companyName',
            //     render: row => <Typography>{row.companyName}</Typography>,
            // }, 
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{row.serviceName}</Typography>,
            },
            {
                title: '活动任务',
                field: 'activityName',
                render: row => <Typography>{row.activityName}</Typography>,
            },
            {
                title: '执行人',
                field: 'executor',
                render: row => <Typography>{row.userName}</Typography>,
            },
            {
                title: '开始时间',
                field: 'startTime',
                render: row => <Typography>{formatDate(row.startTime)}</Typography>,
            },
            {
                title: '状态',
                field: 'status',
                render: row => <Typography>{getCodeName(manufactureDicts, TASK_STATUS, row.status)}</Typography>,
            },
        ]
        return columns
    }

    const assemblyDataTask = (order) => {
        return _.filter(tasks, { 'orderId': order.orderId })
    }

    return (
        <div alignitems="center" className={classes.planitemroot}>
            {/* <Grid container className={classes.toolbarContainer} >
                <Typography variant="h3">生产班组日工单</Typography>
            </Grid> */}
            <Grid container className={classes.toolbarContainer} >
                <Grid item xs={1}>
                    <Typography className={classes.label}>
                        {'单位'}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        style={{ width: '90%' }}
                        select
                        value={search.companyId}
                        onChange={(event) => onhandleSelectChange(event, 'companyId')}
                        variant="standard"
                        disabled={companyDisabled()}
                    >
                        <MenuItem aria-label="None" value={0}>不限</MenuItem>
                        {_.sortBy(companyList, 'companyId').map((option) => (
                            <MenuItem key={option.companyId} value={option.companyId}>
                                {option.companyAlias}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                        {'班组'}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        style={{ width: '90%' }}
                        select
                        value={search.dptId}
                        onChange={(event) => onhandleSelectChange(event, 'dptId')}
                        variant="standard"
                        disabled={noOptionDisabled(getDptData(search.companyId))}
                    >
                        <MenuItem aria-label="None" value={0}>不限</MenuItem>
                        {_.sortBy(getDptData(search.companyId), 'value').map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                        {'服务'}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        style={{ width: '90%' }}
                        select
                        value={serviceId}
                        onChange={(event) => setServiceId(event.target.value)}
                        variant="standard"
                    >
                        <MenuItem aria-label="None" value={0}>不限</MenuItem>
                        {_.sortBy(getServiceData(search.companyId), 'value').map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={classes.toolbarContainer}>

                <Grid item xs={1}>
                    <Typography className={classes.label}>
                        {'选择日期'}
                    </Typography>
                </Grid>

                <Grid item container xs={11}>
                    <Grid item >
                        <ButtonGroup size="small" style={{ height: 28, marginRight: 15 }}>
                            <Tooltip title='前一日'>
                                <Button onClick={() => { onhandleDateChange(null, 'prev') }} size="small" disableElevation={true}>
                                    <ChevronLeftIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title='返回今日'>
                                <Button onClick={() => { onhandleDateChange(null, 'curr') }} size="small" disableElevation={true}>
                                    <RestoreIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title='下一日'>
                                <Button onClick={() => { onhandleDateChange(null, 'next') }} size="small" disableElevation={true}>
                                    <ChevronRightIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                style={{ width: '100%' }}
                                variant="inline"
                                ampm={false}
                                format="yyyy/MM/dd"
                                name='fromDate'
                                value={search.fromDate}
                                onChange={value => onhandleDateChange(value, 'fromDate')}
                                KeyboardButtonProps={{
                                    'aria-label': '开始时间',
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2, marginLeft: 0 }
                                }}
                            />
                        </MuiPickersUtilsProvider>

                    </Grid>

                    <Typography className={classes.label} style={{ margin: '4px 10px auto 10px' }}>
                        {'至'}
                    </Typography>

                    <Grid item xs={2}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                style={{ width: '100%' }}
                                variant="inline"
                                ampm={false}
                                format="yyyy/MM/dd"
                                name='toDate'
                                value={search.toDate}
                                onChange={value => onhandleDateChange(value, 'toDate')}
                                KeyboardButtonProps={{
                                    'aria-label': '结束时间',
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2, marginLeft: 0 }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={tableLocalization}
                    style={tableStyle}
                    title={<Typography variant="h3">{renderTitle()}</Typography>}
                    // actions={[
                    //     {
                    //         icon: 'class',
                    //         tooltip: '工单及任务生成',
                    //         isFreeAction: true,
                    //         // hidden: formatDate(selectedDate) !== formatDate(getMoment()()),
                    //         onClick: (event) => onhandleGenerate(event)
                    //     }
                    // ]}
                    detailPanel={[{
                        render: order => {
                            return (
                                <Grid className={classes.detailTable}
                                >
                                    <MaterialTable
                                        columns={assemblycolumnsTask()}
                                        data={assemblyDataTask(order)}
                                        options={{ ...options, paging: false }}
                                        localization={tableLocalization}
                                        style={tableStyle}
                                        title={<Typography variant='h4'>工作任务调度列表</Typography>}
                                        editable={
                                            {
                                                isDeleteHidden: (rowData) => isMainCompany,
                                                onRowDelete: (oldData) => onhandleDelete(oldData),
                                            }
                                        }
                                        actions={
                                            isMainCompany ? [
                                                // {
                                                //     icon: 'add',
                                                //     tooltip: '增加任务',
                                                //     isFreeAction: true,
                                                //     onClick: (event) => onhandleAdd(event)
                                                // }
                                            ] : [
                                                {
                                                    icon: 'edit',
                                                    tooltip: '修改',
                                                    onClick: (event, rowData) => {
                                                        onhandleUpdate(event, rowData)
                                                    }
                                                },
                                                // {
                                                //     icon: 'add',
                                                //     tooltip: '增加任务',
                                                //     isFreeAction: true,
                                                //     onClick: (event) => onhandleAdd(event)
                                                // }
                                            ]
                                        }
                                    >
                                    </MaterialTable>
                                </Grid>
                            )
                        }
                    }]}
                    editable={{
                        onRowUpdate: (newData, oldData) => onhandleRowUpdate(newData, oldData),
                        // onRowDelete: oldData => onhandleRowDelete(oldData),
                        // isDeletable: rowData => rowData.status === 'active',
                    }}
                    actions={isMainCompany ? [{
                        icon: 'report',
                        tooltip: '任务统计',
                        isFreeAction: true,
                        onClick: (event) => onhandleOpenReport(event)
                    }] : [{
                        icon: 'report',
                        tooltip: '任务统计',
                        isFreeAction: true,
                        onClick: (event) => onhandleOpenReport(event)
                    }, {
                        icon: 'add',
                        tooltip: '增加工单',
                        isFreeAction: true,
                        onClick: (event) => onhandlePopAdd(event)
                    }, {
                        icon: 'playlist_add',
                        tooltip: '增加修正工单',
                        isFreeAction: true,
                        onClick: (event) => onhandleOpenFixAmount(event)
                    },]}
                >
                </MaterialTable>
            </Grid>

        </div >
    );
};

MOrderListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    manufactureOrders: PropTypes.array,
    companyList: PropTypes.any,
    onhandleDateChange: PropTypes.func,
    onhandleRowUpdate: PropTypes.func,
    onhandleRowDelete: PropTypes.func,
};

export default withStyles(MOrderListComponent);
