import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '@/redux/modules/message'
import InstallSharingComponent from '@/components/AmoebaManagement/SharingCost/InstallSharing/InstallSharingComponent';
import { initInstallSharing, setSelectedInstallation, setOpenInstallationUser, getSharingTakeById, addSharingTake, updateSharingTake, getSharingTakeHistories } from '@/redux/modules/amoeba/amoebaCostSharing';
import * as _ from 'lodash';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import SharingTakeHistory from '@/components/AmoebaManagement/SharingCost/SharingTakeHistory';
import InstallationUserContainer from './InstallationUserContainer';

/**
 * 服务半径-装置
 */
class InstallSharingContainer extends Component {
    static propTypes = {
        departments: PropTypes.array
    }

    constructor(props) {
        super(props)
        this.state = {
            isRootCompany: false,
            selectedType: 'install',
            sharingTakeHistoryOpen: false,
            selectedAsset: 0
        }
    }

    componentWillMount() {
        // 根据当前用户的company获取装置
        let myCom = _.find(this.props.companies, (c) => { return c.companyId === this.props.currentUserInfo.companyId })
        let cid = 0;
        if (!myCom.pid) {
            this.setState({ isRootCompany: true })
        } else {
            cid = myCom.companyId
        }
        this.props.onInitPage(cid);
    }

    handleSelectChange = (event) => {
        this.props.onhandleSelectChange(event.target.value)
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.sharerInstall === parseInt(event.target.value)
        })
        if (cs !== undefined) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        }
    }

    handleAdd = (value) => {
        let cs;
        if (this.state.selectedType === 'install') {
            cs = _.find(this.props.costSharinglist, (c) => { return c.sharerInstall === parseInt(this.props.selectedInstallation) })
        } else {
            cs = _.find(this.props.costSharinglist, (c) => { return c.shareOther === parseInt(this.state.selectedAsset) })
        }
        if (cs !== undefined) {
            value['sharingId'] = cs.sharingId
            value['percentage'] = parseFloat(value['percentage'])
            value['takerCompany'] = parseInt(value['takerCompany'])
            value['takerDpt'] = parseInt(value['takerDpt'])
            value['fixed'] = parseFloat(value['fixed'])
            // value['valuationType'] = value['valuationType']
            this.props.onhandleAdd(value);
        }
    }

    handleDelete = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'status': 'inactive'
        }
        this.props.onhandleUpdate(newData);
    }

    handleUpdate = (value) => {
        let newData = {
            'takeId': value['takeId'],
            'sharingId': value['sharingId'],
            'takerCompany': parseInt(value['takerCompany']),
            'takerDpt': parseInt(value['takerDpt']),
            'percentage': parseFloat(value['percentage']),
            'fixed': parseFloat(value['fixed']),
            'valuationType': value['valuationType'],
        }
        this.props.onhandleUpdate(newData);
    }

    handleOpenHistory = (value) => {
        this.setState({ sharingTakeHistoryOpen: true })
        this.props.onhandleGetSharingTakeHistories(value['sharingId'], value['takeId'])
    }

    handleCloseHistory = () => {
        this.setState({ sharingTakeHistoryOpen: false })
    }

    handleOpenInstallationUser = () => {
        this.props.onhandleOpenInstallationUser()
    }

    handleTypeChange = (event) => {
        this.setState({
            selectedType: event.target.value
        });
    }

    handleAssetChange = (event) => {
        this.setState({
            selectedAsset: parseInt(event.target.value)
        });
        let cs = _.find(this.props.costSharinglist, (c) => {
            return c.shareOther === parseInt(event.target.value)
        })
        if (cs) {
            this.props.onhandleGetSharingTakeList(cs.sharingId)
        }
    }

    render() {
        return (
            <React.Fragment>
                <InstallSharingComponent
                    selectedAsset={this.state.selectedAsset}
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    isRootCompany={this.state.isRootCompany}
                    onhandleTypeChange={this.handleTypeChange.bind(this)}
                    selectedType={this.state.selectedType}
                    companies={this.props.companies}
                    valuations={this.props.valuations}
                    installationList={this.props.installationList}
                    sharingTakelist={this.props.sharingTakelist}
                    onhandleMessage={this.props.onhandleMessage}
                    dicts={this.props.dicts}
                    otherAssets={this.props.otherAssets}
                    selectedInstallation={this.props.selectedInstallation}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleAssetChange={this.handleAssetChange.bind(this)}
                    userProfile={this.props.userProfile}
                    onhandleOpenHistory={this.handleOpenHistory.bind(this)}
                    onhandleOpenInstallationUser={this.handleOpenInstallationUser.bind(this)}
                />

                <SharingTakeHistory
                    open={this.state.sharingTakeHistoryOpen}
                    companies={this.props.companies}
                    departments={this.props.departments}
                    histories={this.props.sharingTakeHistories}
                    users={this.props.users}
                    onhandleClose={this.handleCloseHistory.bind(this)}
                    valuations={this.props.valuations}
                />
                {this.props.openInstallationUser && <InstallationUserContainer />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        // departments: _.filter(state.constant.departments, (d) => { return d.amoebaType === 'PROFIT' }),
        departments: state.constant.departments,
        companies: state.constant.companies,
        otherAssets: state.amoebaCostSharing.otherAssets,
        installationList: state.installation.installationList.list,
        costSharinglist: state.amoebaCostSharing.costSharinglist,
        sharingTakelist: state.amoebaCostSharing.sharingTakelist,
        selectedInstallation: state.amoebaCostSharing.selectedInstallation,
        dicts: state.constant.manufactureDicts,
        valuations: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.DEPRECIATION_SHARE_TYPE
        }),
        sharingTakeHistories: state.amoebaCostSharing.sharingTakeHistories,
        users: state.usercard.users,
        openInstallationUser: state.amoebaCostSharing.openInstallationUser,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitPage: (cid) => {
            dispatch(initInstallSharing(cid));
        },
        onhandleSelectChange: (installId) => {
            dispatch(setSelectedInstallation(installId));
        },
        onhandleGetSharingTakeList: (sid) => {
            dispatch(getSharingTakeById(sid));
        },
        onhandleAdd: (value) => {
            dispatch(addSharingTake(value, 'TYPE_DEPARTMENT'));
        },
        onhandleUpdate: (value) => {
            dispatch(updateSharingTake(value.takeId, value));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        },
        onhandleGetSharingTakeHistories: (sid, takeId, status) => {
            dispatch(getSharingTakeHistories(sid, takeId, status));
        },
        onhandleOpenInstallationUser: () => {
            dispatch(setOpenInstallationUser(true));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallSharingContainer);
