import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { getStore } from '../../../../../redux/store';
import { convertToNameValue } from '../../../../../utils/constant';
import { setDiagramChange } from '../../../../../redux/modules/process';


function getSelect(element, node) {
    const bo = getBusinessObject(element);
    const selectedOption = bo.get(node);
    return selectedOption;
}

function setSelect(element, value, name) {
    const obj = {};
    obj[`${name}`] = value[name];
    return obj;
}

function setCheckBox(element, value, name) {
    const obj = {};
    obj[`${name}`] = value[name] ? 'true' : 'false';
    return obj;
}

export default function (group, element, bpmnFactory, translate) {
    if (!is(element, 'bpmn:Task') && !is(element, 'bpmn:StartEvent')) return;

    const store = getStore();

    const branchOptions = store.getState().constant.constants.PBRANCH;

    const selectBranch = entryFactory.selectBox({
        id: 'branch',
        label: translate('任务分支'),
        selectOptions: [{ name: '', value: '' }].concat(convertToNameValue(branchOptions, 'name', 'code')),
        modelProperty: 'branch',
        get(el) {
            return {
                branch: getSelect(el, 'branch')
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setSelect(el, value, 'branch');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        },
    });

    group.entries.push(selectBranch);

    const branchScopeOptions = store.getState().constant.constants.PSCOPE;

    const selectBranchScope = entryFactory.selectBox({
        id: 'branchScope',
        label: translate('任务分支范围'),
        selectOptions: [{ name: '', value: '' }].concat(convertToNameValue(branchScopeOptions, 'name', 'code')),
        modelProperty: 'branchScope',
        get(el) {
            return {
                branchScope: getSelect(el, 'branchScope'),
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setSelect(el, value, 'branchScope');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        },
    });

    group.entries.push(selectBranchScope);

    const stageOptions = convertToNameValue(store.getState().process.currentProcess.processStage, 'stageName', 'stageId');

    const selectMergeStage = entryFactory.selectBox({
        id: 'mergeStage',
        label: translate('任务分支合并'),
        // to be repaced with stage list
        selectOptions: [{ name: '', value: '' }].concat(stageOptions),
        modelProperty: 'mergeStage',
        get(el) {
            return {
                mergeStage: getSelect(el, 'mergeStage'),
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setSelect(el, value, 'mergeStage');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        }
    });

    group.entries.push(selectMergeStage);



    const executable = entryFactory.checkbox({
        id: 'executable',
        label: translate('可执行班组'),
        modelProperty: 'executable',
        hidden: (el) => {
            return getSelect(el, 'branch') !== 'PBRANCH_DPT' && getSelect(el, 'branch') !== 'PBRANCH_COMP_DPT';
        },
        get(el) {
            return {
                executable: getSelect(el, 'executable') === 'true',
            };
        },
        set(el, value) {
            const bo = getBusinessObject(el);
            const props = setCheckBox(el, value, 'executable');
            store.dispatch(setDiagramChange(true));
            return cmdHelper.updateBusinessObject(element, bo, props);
        }
    });

    group.entries.push(executable);

    group.entries.push(
        entryFactory.textBox({
            id: 'opentext',
            label: translate('描述'),
            modelProperty: 'opentext',
            set(el, value) {
                let res = {}
                if (value['opentext'] !== '') {
                    res['opentext'] = value['opentext'];
                } else {
                    res['opentext'] = undefined;
                }
                store.dispatch(setDiagramChange(true));
                return cmdHelper.updateProperties(el, res);
            },
            validate: function (element, values) {
                let validationResult = {};

                if (values.desc && values.desc.length > 50) {
                    validationResult.desc = '描述最多50个字';
                }

                return validationResult;
            }
        })
    );
}
