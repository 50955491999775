import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    DialogActions,
    Button,
    Dialog,
    DialogContent,
    Typography,
    TextField,
    MenuItem
} from '@material-ui/core';
import { localization } from "../../../../utils/mtable";
import MaterialTable from 'material-table'
import _ from 'lodash'
import { getMoment, getDateByTimestamp } from '../../../../utils/datetime'

const ScheduleList = ({
    classes,
    currentDayPlanList,
    open,
    onhandleClose,
    onhandleAdd,
    onhandleUpdate,
    onhandleOpenPlanPanel,
    leaders,
    departmentList,
    routeList,
    userProfile,
    onhandleDelete,
    onhandleErrorStartTime,
    onhandlePanelDelete
}) => {

    const tableHeight = (window.innerHeight - 600) / window.innerHeight * 100;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_schedule' })
        return permission && permission.action === 'W'
    }

    const renderRoute = (row) => {
        let label = '';
        let value = _.find(routeList, function (o) { return o.routeId === row.routeId })
        if (value) {
            label = value.routeName
        }

        return label;
    }

    const renderLeaders = (row) => {
        let label = '';
        let value = _.find(leaders, function (o) { return o.userId === row.inspector })
        if (value) {
            label = value.lastName + value.firstName
        }

        return label;
    }

    const renderDpt = (row) => {
        let label = '';
        let value = _.find(departmentList, function (o) { return o.dptId === row.onsitePlanDpt[0].dptId })
        if (value) {
            label = value.dptName
        }

        return label;
    }

    // const validateStartTime = (newData, oldData) => {
    //     let newTime = new Date(newData.startTime).getTime()
    //     let oldTime = new Date(oldData.startTime).getTime()
    //     if (newTime === oldTime) {
    //         return true
    //     } else {
    //         let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
    //         let tomorrowTime = new Date(tomorrow).getTime();
    //         let startTime = new Date(newData.startTime).getTime()
    //         if (tomorrowTime > startTime) {
    //             return false
    //         } else {
    //             return true
    //         }
    //     }
    // }

    const validateAction = (rowData) => {
        let tomorrow = getMoment()().add(1, 'days').format('YYYY/MM/DD');
        let tomorrowTime = new Date(tomorrow).getTime();
        let startTime = new Date(rowData.startTime).getTime()

        if (rowData.ruleId === null && tomorrowTime < startTime && rowData.status === 'new') {
            return false
        }

        return true
    }

    const renderStartTime = (row) => {
        return row.startTime ? getDateByTimestamp(row.startTime, true) : ''
    }

    const renderTime = (startTime) => {
        return startTime ? getMoment()(startTime).format("YYYY-MM-DDTHH:mm") : ''
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '管理人员',
                field: 'inspector',
                validate: rowData => (!rowData.inspector || rowData.inspector === '') ? { isValid: false, helperText: '管理人员不能为空' } : true,
                render: row => row ? <Typography>{renderLeaders(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.inspector ? props.rowData.inspector : ''}
                        name='inspector'
                        onChange={e => props.onChange(e.target.value)}>
                        {leaders.map((item) => {
                            return <MenuItem key={item.userId} value={item.userId}>
                                {item.lastName + item.firstName}
                            </MenuItem>
                        })}
                    </TextField>
                )
            }, {
                title: '所属部门',
                field: 'dptId',
                render: row => <Typography>{renderDpt(row)}</Typography>,
                editable: 'never',
            }, {
                title: '巡检路线',
                field: 'routeId',
                validate: rowData => (!rowData.routeId || rowData.routeId === '') ? { isValid: false, helperText: '巡检路线不能为空' } : true,
                render: row => row ? <Typography>{renderRoute(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        select
                        value={props.rowData.routeId ? props.rowData.routeId : ''}
                        name='routeId'
                        onChange={e => props.onChange(e.target.value)}>
                        {routeList.map((item) => {
                            return <MenuItem key={item.routeId} value={item.routeId}>
                                {item.routeName}
                            </MenuItem>
                        })}
                    </TextField>
                )
            }, {
                title: '开始时间',
                field: 'startTime',
                validate: rowData => (!rowData.startTime || rowData.startTime === '') ? { isValid: false, helperText: '开始时间不能为空' } : true,
                render: row => row ? <Typography>{renderStartTime(row)}</Typography> : '',
                editComponent: (props) => (
                    <TextField
                        error={props.helperText}
                        helperText={props.helperText || ''}
                        required
                        type="datetime-local"
                        value={props.rowData.startTime ? renderTime(props.rowData.startTime) : ''}
                        name='startTime'
                        onChange={e => props.onChange(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                )
            }
        ]
        return columns
    }

    return (

        <div alignitems="center" className={classes.root}>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={onhandleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogContent dividers className={classes.activityDialogContent}>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={currentDayPlanList}
                        localization={localization}
                        options={{
                            pageSize: 10,
                            maxBodyHeight: `${tableHeight}vh`,
                            minBodyHeight: `${tableHeight}vh`,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                        }}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        title={<Typography variant='h3'>管理巡检排班列表</Typography>}
                        // editable={checkPermission() ?
                        //     {
                        //         onRowAdd: (newData) =>
                        //             new Promise((resolve, reject) => {
                        //                 onhandleAdd(newData)
                        //                 resolve()
                        //             }),
                        //         onRowUpdate: (newData, oldData) =>
                        //             new Promise((resolve, reject) => {
                        //                 if (!validateStartTime(newData, oldData)) {
                        //                     onhandleErrorStartTime()
                        //                     reject()
                        //                 } else {
                        //                     onhandleUpdate(newData)
                        //                     resolve()
                        //                 }
                        //             }),
                        //         onRowDelete: (oldData) =>
                        //             new Promise((resolve, reject) => {
                        //                 onhandleDelete(oldData)
                        //                 resolve()
                        //             }),
                        //     } : {}
                        // }
                        actions={checkPermission() ? [
                            rowData => ({
                                icon: 'edit',
                                tooltip: '编辑',
                                hidden: validateAction(rowData),
                                onClick: (event, rowData) => {
                                    onhandleOpenPlanPanel(rowData)
                                }
                            }),
                            rowData => ({
                                icon: 'delete_outlined',
                                tooltip: '删除',
                                hidden: validateAction(rowData),
                                onClick: (event, rowData) => {
                                    onhandlePanelDelete(rowData)
                                }
                            }),
                            {
                                icon: 'add',
                                tooltip: '新建',
                                isFreeAction: true,
                                onClick: (event) => {
                                    onhandleOpenPlanPanel({})
                                }
                            }
                        ] : []
                        }
                    >
                    </MaterialTable>
                </DialogContent>

                <DialogActions>
                    <Button variant='outlined' onClick={onhandleClose}>关闭</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
ScheduleList.propTypes = {
    classes: PropTypes.object.isRequired,
    currentDayPlanList: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandleAdd: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleOpenPlanPanel: PropTypes.func.isRequired,
    leaders: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    routeList: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onhandleErrorStartTime: PropTypes.func.isRequired,
    onhandlePanelDelete: PropTypes.func.isRequired,
};


export default withRouter(withStyles(ScheduleList));