import { withStyles } from '@material-ui/styles';
import { indigo, red } from '@material-ui/core/colors';

const styles = theme => ({
  planitemroot: {
    backgroundColor: '#FFFFFFFF',
  },
  materialtable: {
    boxShadow: 'none'
  },
  pic1: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: red[300],
    fontSize: '12px',
  },
  pic2: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: indigo[300],
    fontSize: '12px',
  },
  detailtable: {
    padding: theme.spacing(2)
  },
});

export default withStyles(styles);
