import React, { Component } from 'react';
import { connect } from 'react-redux';
import SupplierList from "@/components/DeviceManagement/Suppliers/SupplierList";
// import SupplierEditPanel from "@/containers/DeviceManagement/Supplier/SupplierEditPanel";
import PropTypes from "prop-types";
import {
    //初始化页面
    initSupplierHeader,
    // 搜索承包商列表
    searchSupplierList,
    //重置搜索
    resetSearch,
    // 详情页跳转
    routeToSupplierInfo,
    //设置搜索条件
    setSearch,
    // createSupplier,
    // updateSupplier,
    // 是否保存
    changeSupplierStatus,
    //导入
    // importSupplierItems,
} from '@/redux/modules/supplier';
// import {
//     openSupplierEditPanel
// } from '@/redux/modules/inspection/checklistSupplierEdit';
import { INSURANCE_TEMPLATE } from '@/utils/device';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import { getNowTimestamp } from '@/utils/datetime';
// import {upload, download} from '@/redux/modules/minio';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import _ from 'lodash';

class SupplierListContainer extends Component {

    static propTypes = {
        suppliers: PropTypes.object
    };

    constructor(props) {
        super(props)
        this.state = {}
    }

    UNSAFE_componentWillMount() {
        const { location, search } = this.props;
        const isBack = location ? location.state : false;

        this.props.onhandleInitSupplierHeader(isBack);

        if (isBack) {
            this.setState({ size: search.size });
        }
    }

    handleSearch() {
        this.props.onhandleGetSupplierList({
            ...this.props.search,
            offset: 0
        });
        const newSearch = {
            ...this.props.search,
            page: 0
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleClearSearch() {
        this.props.onhandleClearSarch();
    }

    handleSelect = (value, field) => {
        const newValue = value.target.value;
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: newValue,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'status':
                newSearch = {
                    ...this.props.search,
                    status: newValue,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'size':
                let newPage = this.props.search.size * this.props.search.page / newValue;
                newPage = newPage * newValue > this.props.supplier.total - newValue ? newPage - 1 : newPage;
                newPage = newPage < 0 ? 0 : newPage;
                newSearch = {
                    ...this.props.search,
                    size: newValue,
                    page: newPage
                };
                this.setState({ size: newValue });
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    // handleDateChange(value, field) {
    //     let newSearch = { ...this.props.search };
    //
    //     const date = moment()(value);
    //     switch (field) {
    //         case 'startDate':
    //             newSearch.startDate = date.startOf('day').format("YYYY-MM-DD")
    //             break;
    //         case 'endDate':
    //             if (
    //                 date.isBefore(moment()(newSearch.startDate))
    //             ) {
    //                 newSearch.endDate = moment()(newSearch.startDate).endOf('day').format("YYYY-MM-DD")
    //             } else {
    //                 newSearch.endDate = date.endOf('day').format("YYYY-MM-DD")
    //             }
    //             break;
    //         default:
    //         // do nothing
    //     }
    //     this.props.onhandleUpdateEditSearch(newSearch);
    // }

    handleChange = (event, field) => {
        let newSearch;
        switch (field) {
            case 'search':
                newSearch = {
                    ...this.props.search,
                    search: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'supplierName':
                newSearch = {
                    ...this.props.search,
                    supplierName: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'supplierNo':
                newSearch = {
                    ...this.props.search,
                    supplierNo: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'supplierGroupCode':
                newSearch = {
                    ...this.props.search,
                    supplierGroupCode: event.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    };

    handleSupplierEdit(id, editable) {
        if (!id) {
            // create
            this.props.onhandleRouteToSupplierInfo(0, true);
        } else {
            this.props.onhandleRouteToSupplierInfo(id, editable);
        }
        // const company = _.find(companies, { 'companyId': currentUserInfo.companyId });
        // const hasPcomp = company && company.pid;
        // const companyId = hasPcomp ? currentUserInfo.companyId : '';
        // this.props.onhandleOpenSupplierEditPanel(this.handleSubmit.bind(this), null, rowData, companyId);
    }

    handleSubmit(supplier) {
        // const {
        //     onhandleCreateSupplier,
        //     onhandleUpdateSupplier,
        //     onhandleGetSupplier,
        //     search
        // } = this.props;

        // const newSupplier = {
        //     ...supplier,
        //     supplierId: supplier.supplierId ? _.toNumber(supplier.supplierId) : undefined,
        //     insType: supplier.insType ? supplier.insType : '',
        //     insCompany: supplier.insCompany ? supplier.insCompany : '',
        //     listType: supplier.listType ? supplier.listType : undefined,
        //     catCode: supplier.catCode ? supplier.catCode : undefined,
        //     startDate: supplier.startDate ? supplier.startDate : undefined,
        //     validEndTime: supplier.validEndTime ? supplier.validEndTime : undefined,
        // }

        // if (!newSupplier.supplierId) {
        //     onhandleCreateSupplier(newSupplier, (resSupplier) => {
        //         onhandleGetSupplierList({ ...search, offset: 0 });
        //     });
        // } else {
        //     onhandleUpdateSupplier(newSupplier, (resSupplier) => {
        //         onhandleGetSupplierList({ ...search });
        //     });
        // }

    }

    changeCallback = () => {
        this.props.onhandleGetSupplierList({
            ...this.props.search,
            // offset: 0
        });
    }

    handleDelete = (event, id) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(id, 'inactive', this.changeCallback);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除此承包商记录吗?', changeStatus, null, false);
    };

    handleSetExpire = (event, rowData) => {
        const { supplierId, status } = rowData;
        let newStatus = 'active'
        let text = '生效'
        if (status === 'active') {
            newStatus = 'expired'
            text = '失效'
        } else {
            newStatus = 'active'
            text = '生效'
        }
        let changeStatus = () => {
            this.props.onhandleChangeStatus(supplierId, newStatus, this.changeCallback);
        }
        this.props.onhandleOpenConfirmDialog(text + '确认', '确定要' + text + '此保承包商记录吗?', changeStatus, null, false);
    };

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            page: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleDownloadTemp() {
        const { onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'uat';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'uat';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.Supplier.template', '{tenant_id}', tenantId);
        const fileName = INSURANCE_TEMPLATE;
        onhandleDownloadFile(fileName, mybucket);
    }

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '导入车辆保险记录',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xlsx',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.xlsx') {
            return files;
        } else {
            this.props.onhandleUploadPre('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        // const {

        // } = this.props;
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'uat';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.Supplier.record.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                docName: offset + files[0].name,
            };
            this.props.onhandleCreateDoc(doc, () => {
                this.props.onhandleGetSupplierList({
                    ...this.props.search,
                    offset: 0
                });
            });
            this.props.onhandleModalClose();
        });
    }

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            suppliers,
            companies,
            // departments,
            inspectionDict
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyAlias : '';
                break;
            case 'supplierId':
                value = _.find(suppliers.list, { 'supplierId': fieldId }) ? _.find(suppliers.list, { 'supplierId': fieldId }).supplierId : '';
                break;
            case 'supplierName':
                value = _.find(suppliers.list, { 'supplierName': fieldId }) ? _.find(suppliers.list, { 'supplierName': fieldId }).supplierName : '';
                break;
            case 'supplierNo':
                value = _.find(suppliers.list, { 'supplierNo': fieldId }) ? _.find(suppliers.list, { 'supplierNo': fieldId }).supplierNo : '';
                break;
            case 'supplierGroupCode':
                value = _.find(suppliers.list, { 'supplierGroupCode': fieldId }) ? _.find(suppliers.list, { 'supplierGroupCode': fieldId }).supplierGroupCode : '';
                break;
            case 'listType':
            case 'category':
            case 'severity':
            case 'rootCause':
            case 'discipline':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '-';
                break;
            default:
            // do nothing
        }
        return value;
    }

    render() {
        return (<>
            <SupplierList
                lists={this.props.suppliers}
                userProfile={this.props.userProfile}
                currentUserInfo={this.props.currentUserInfo}
                companyList={this.props.companies}
                departmentList={this.props.departments}
                onhandleChange={this.handleChange.bind(this)}
                onhandleSelectChange={this.handleSelect.bind(this)}
                // onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleListMapping={this.handleListMapping.bind(this)}
                onDelete={this.handleDelete.bind(this)}
                onSetExpire={this.handleSetExpire.bind(this)}
                onhandleEdit={this.handleSupplierEdit.bind(this)}
                onhandleSearch={this.handleSearch.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
                onhandleDownloadTemp={this.handleDownloadTemp.bind(this)}
                onhandleUpload={this.handleUpload.bind(this)}
                getSuppliers={this.props.onhandleGetSupplierList}
                roleList={this.props.roleList}
                search={this.props.search}
                onhandlePageChange={this.handlePageChange.bind(this)}
            />
            {/* {this.props.panelOpen && <SupplierEditPanel />} */}
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.supplier.search,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        roleList: state.supplier.roleList,
        suppliers: state.supplier.supplierList,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitSupplierHeader: (isBack) => {
            dispatch(initSupplierHeader(isBack));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSarch: () => {
            dispatch(resetSearch());
        },
        // onhandleOpenSupplierEditPanel: (confirmCallback, closeCallback, supplier, companyId) => {
        //     dispatch(openSupplierEditPanel(confirmCallback, closeCallback, supplier, companyId))
        // },
        // onhandleCreateSupplier: (Supplier, callback) => {
        //     dispatch(createSupplier(Supplier, callback))
        // },
        // onhandleUpdateSupplier: (Supplier, callback) => {
        //     dispatch(updateSupplier(Supplier, callback));
        // },
        onhandleGetSupplierList: (query) => {
            dispatch(searchSupplierList(query))
        },
        onhandleRouteToSupplierInfo: (id, editable) => {
            dispatch(routeToSupplierInfo(id, editable));
        },
        onhandleChangeStatus: (id, status, callback) => {
            dispatch(changeSupplierStatus(id, status, callback));
        },
        // onhandleUploadDoc: (files, mybucket, offset, callback) => {
        //     dispatch(upload(files, mybucket, offset, callback))
        // },
        // onhandleCreateDoc: (doc, callback) => {
        //     dispatch(importSupplierItems(doc, callback));
        // },
        // onhandleDownloadFile: (docName, mybucket) => {
        //     return dispatch(download(docName, mybucket))
        // },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        // onhandleEdit: (id, editable) => {
        //     dispatch(initEdit(id, editable));
        // },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierListContainer)
