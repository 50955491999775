import { connect } from 'react-redux';
import MyCanvasView from '../components/MyCanvasView';
import { createDialog, cleanDialog } from '../redux/modules/dialog';
import { initDeviceTreeData } from '../redux/modules/deviceTree';

const mapStateToProps = (state) => {
    return {
        ...state.viewSetting,
        ...state.installation,
        ...state.installationRelation,
        ...state.deviceTree,
    }
}

const mapDispathToProps = (dispath) => {
    return {
        createDialog: (children, parameters, showIcon) => {
            dispath(createDialog(children, parameters, showIcon))
        },
        cleanDialog: () => {
            dispath(cleanDialog())
        },
        initDeviceTreeData: () => {
            dispath(initDeviceTreeData())
        }
    }
}

export default connect(mapStateToProps, mapDispathToProps)(MyCanvasView);