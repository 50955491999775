import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    instockList: [] //生产入库列表
};

export const setInstocks = createAction('@@xcloud/manufacture/setInstocks');


export const updateManufactureInstock = createAction('@@xcloud/manufacture/updateManufactureInstock',
    (instockId, instockDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/instocks/' + instockId, instockDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新生产入库成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产入库失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getInstocks = createAction('@@xcloud/manufacture/getInstocks',
    (instockDpt, stockDpt, instockCompany, stockCompany, orderId, status, instockDate, orderIds) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/instocks'
        let strs = []
        if (instockDpt || stockDpt || instockCompany || stockCompany || orderId || orderIds || status || instockDate) {
            if (instockDpt) strs.push('instockDpt=' + instockDpt)
            if (stockDpt) strs.push('stockDpt=' + stockDpt)
            if (instockCompany) strs.push('instockCompany=' + instockCompany)
            if (stockCompany) strs.push('stockCompany=' + stockCompany)
            if (orderId) strs.push('orderId=' + orderId)
            if (orderIds) strs.push('orderIds=' + orderIds)
            if (instockDate) strs.push('instockDate=' + instockDate)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setInstocks(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产入库列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getInstock = createAction('@@xcloud/manufacture/getInstock',
    (instockId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/instocks/' + instockId
        return httpClient.get(url).then((res) => {
            if (res) {
                return res.data;
            }
        }).catch(err => {
            let msg = `获取生产入库失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const ManufactureInstockReducer = handleActions(
    {
        [setInstocks]: (state, { payload: data }) => {
            return {
                ...state,
                instockList: data
            }
        },
    },
    initialState
)

export default ManufactureInstockReducer
