import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    detailtable: {
        padding: theme.spacing(4)
    },
    action: {
        padding: 16,
    },
    buttonGridContainer: {
        alignSelf: 'stretch',
    },
    title: {
        padding: theme.spacing(2),
    },
    gridItemSearchButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    button: {
        margin: theme.spacing(1)
    },
    flexgrow: {
        flexGrow: 1
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    processline: {
        flexGrow: 1
    },
    attention: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        background: 'rgba(255,80,0,0.05)',
        border: '1px solid rgba(255,80,0,1)'
    },
    attentiontitle: {
        fontWeight: 500,
        color: '#FF5000FF'
    },
    attentioncontent: {
        color: '#FF5000FF'
    },
    materialtable: {
        boxShadow: 'none'
    },
    buttonintable: {
        margin: 0,
        padding: 0
    },
    addlink: {
        paddingTop: theme.spacing(3),
        alignItems: 'center'
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    pic3: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.purple[400],
        fontSize: '12px',
    },
    connectLabel: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        marginLeft: theme.spacing(1),
    },
    gridSubItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: 'calc(100% - 60px)',
    },
    gridItemTableButtons: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    textFieldGroupItem: {
        marginLeft: theme.spacing(1),
        width: '100%',
    },
    root: {
        padding: theme.spacing(0)
    },
    menuroot: {
        '& .MuiMenu-paper': {
            border: '1px solid #d3d4d5',
        },
    },
    gridItem: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    textFieldInput: {
        marginLeft: theme.spacing(1),
        width: 'calc(100% - 60px)'
    },
    label: {
        whiteSpace: 'nowrap',
        width: 60,
        textAlign: 'right'
    },
    tabs: {
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.contrastText,
            },
            backgroundColor: theme.palette.primary.main
        }
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },
});

export default withStyles(styles);
