import { connect } from 'react-redux';
import { MessageDetail } from '../components/DialogCommon/MessageDetail';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.devices,
        ...state.trees,
    }
}

export default connect(mapStateToProps)(MessageDetail);

