import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProdServiceReportComponent from '@/components/ManufactureManagement/ProdReport/ProdServiceReport';
import PropTypes from 'prop-types';
import { getMoment } from '@/utils/datetime';
import {
    getProdReportList,
    getProdReportServiceList,
    getProdReportServiceOrgList,
    exportProdServiceReport,
    setDateHeadDataList,
    setProdReportServiceList,
    checkManufactureOutput,
    getManufactureOutputCheckList
} from '@/redux/modules/manufacture/manufactureProdReport';
import { createMessage } from '@/redux/modules/message';
import ProdServiceReportDetailPanelContainer from '@/containers/ManufactureManagement/ProdReport/ProdServiceReportDetailPanelContainer';
import { judgeMainCompany } from '@/utils/manufactureUtils';
import { getRole } from "@/utils/userUtils";
import { openConfirmDialog } from '@/redux/modules/confirmDialog';

import _ from 'lodash'

class ProdServiceReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            timeDim: 'day',
            startDate: getMoment()().startOf('month').format("YYYY-MM-DD"),
            endDate: getMoment()().startOf('day').format("YYYY-MM-DD"),
            isShowService: false,
            isMainCompany: judgeMainCompany(this.props.companies, this.props.currentUserInfo.companyId),
            selectedCompanyId: this.props.currentUserInfo.companyId,
            selectedCompanyName: "",
            detailDate: null
        }
    }
    // 页面初期化获取数据
    componentDidMount() {
        const {
            currentUserInfo,
            companies,
        } = this.props;


        let isMainCompany = judgeMainCompany(companies, currentUserInfo.companyId);
        const companyList = this.getCompanyList();

        let selectCompanyId = isMainCompany ? 0 : currentUserInfo.companyId;

        const company = _.find(companyList, { companyId: selectCompanyId });
        const initState = {
            selectedCompanyId: company ? company.companyId : 0,
            selectedCompanyName: company ? company.companyName : '',
            isMainCompany: isMainCompany
        };
        this.setState(initState);

        const {
            timeDim, startDate, endDate
        } = this.state;
        this.handleUpdateProdReportList(initState.selectedCompanyId, timeDim, startDate, endDate);
    }

    // 页面state响应
    componentDidUpdate(prevProps, prevState) {
        const {
            selectedCompanyId, timeDim, startDate, endDate
        } = this.state;
        if (
            prevState.selectedCompanyId !== selectedCompanyId ||
            prevState.timeDim !== timeDim ||
            prevState.startDate !== startDate ||
            prevState.endDate !== endDate
        ) {
            this.handleUpdateProdReportList(selectedCompanyId, timeDim, startDate, endDate);
        }
    }

    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }

    handleChangeCompany(event) {
        const company = _.find(this.props.companies, item => {
            return item.companyId === event.target.value
        });
        this.setState({ selectedCompanyId: event.target.value, selectedCompanyName: company ? company.companyName : "" })
        // this.handleGetProdReportList(event.target.value)
    }

    handleDateChange(value, field) {
        const { timeDim } = this.state;
        const date = getMoment()(value);
        debugger
        if (field === 'startDate') {
            let startDate = date.startOf(timeDim);
            let endDate = getMoment()(this.state.endDate);
            if (
                startDate.isAfter(endDate)
            ) {
                startDate = endDate.clone().startOf(timeDim);
                this.props.onhadleMassge('开始日期不能晚于结束日期', 'warning')
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            });
        }
        if (field === 'endDate') {
            let startDate = getMoment()(this.state.startDate);
            let endDate = date.endOf(timeDim);
            if (
                startDate.isAfter(endDate)
            ) {
                endDate = startDate.clone().endOf(timeDim);
                this.props.onhadleMassge('开始日期不能晚于结束日期', 'warning')
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            });
        }
    }

    handleSelectView = (value) => {
        let startDate = getMoment()().startOf('month').format("YYYY-MM-DD");
        let endDate = getMoment()().endOf('day').format("YYYY-MM-DD");
        // 顺带做时间范围的初始化
        switch (value) {
            case 'year':
                startDate = getMoment()().startOf('year').format("YYYY-MM-DD");
                endDate = getMoment()().endOf('year').format("YYYY-MM-DD");
                break;
            case 'month':
                startDate = getMoment()().startOf('year').format("YYYY-MM-DD");
                endDate = getMoment()().endOf('month').format("YYYY-MM-DD");
                break;
            case 'day':
                startDate = getMoment()().startOf('month').format("YYYY-MM-DD");
                endDate = getMoment()().startOf('day').format("YYYY-MM-DD");
                break;
            default:
        }

        this.setState({
            timeDim: value,
            startDate: startDate,
            endDate: endDate
        });
    }


    handleUpdateProdReportList = (selectedCompanyId, timeDim, startDate, endDate) => {
        const { getProdReportServiceList } = this.props;
        getProdReportServiceList(selectedCompanyId, timeDim, startDate, endDate);
    }

    // 单个service的统计（暂未实现UI）
    handleShowServiceDetailReport = (row, date) => {
        // const { getProdReportServiceDetailList } = this.props;
        // const {
        //     serviceId,
        // } = row;
        // const {
        //     timeDim
        // } = this.state;
        // return getProdReportServiceDetailList(timeDim, date, this.state.selectedCompanyId, serviceId).then((data) => {
        //     this.setState({
        //         isShowService: true,
        //         detailDate: date
        //     });
        // });
    }

    // 单个单位时间内services统计
    handleShowServiceOrgReport = (dateKey) => {
        const { getProdReportServiceOrgList } = this.props;
        const {
            timeDim
        } = this.state;

        const dateMoment = getMoment()(dateKey);

        const startDate = dateMoment.startOf(timeDim).format('YYYY-MM-DD');
        const endDate = dateMoment.endOf(timeDim).format('YYYY-MM-DD');

        return getProdReportServiceOrgList(this.state.selectedCompanyId, timeDim, startDate, endDate).then((data) => {
            this.setState({
                isShowService: true,
                detailDate: dateKey
            });
        });
    }

    handleClose = () => {
        this.setState({
            isShowService: false,
            detailDate: null
        });
    }

    getCompanyList = () => {
        const { companies } = this.props;
        // const newcompanies = _.filter(companies, item => {
        //     return item.pid
        // });
        const newcompanies = [...companies];
        const rootCompany = _.find(companies, { pid: null })
        newcompanies.unshift({ companyId: 0, companyAlias: rootCompany ? rootCompany.companyName : '全体单位' })
        return newcompanies
    }

    handleExport = () => {
        const { onhandleDownloadFile, companies, currentUserInfo } = this.props;
        const { startDate, endDate } = this.state;

        let isMainCompany = judgeMainCompany(companies, currentUserInfo.companyId);
        const companyId = isMainCompany ? 0 : currentUserInfo.companyId;

        onhandleDownloadFile(startDate, endDate, companyId);
    }

    handleCheckOutput = async (date, isUpdate) => {

        const { selectedCompanyId, timeDim, startDate, endDate } = this.state
        const { currentUserInfo, onhandleOpenConfirmDialog, onhandleCheckManufactureOutput } = this.props

        onhandleOpenConfirmDialog('产量确认', '是否' + (isUpdate ? '再次重新' : '') + '确认' + date + '日产量报表？', async () => {
            var checkDto = {
                companyId: selectedCompanyId,
                checkDate: getMoment()(date, "YYYY/MM/DD").format('YYYY-MM-DD'),
                checker: currentUserInfo.userId
            }
            await onhandleCheckManufactureOutput(checkDto);

            //更新标题和数据
            this.handleUpdateProdReportList(selectedCompanyId, timeDim, startDate, endDate)
        })

    }



    render() {
        const roleFlag = Boolean(this.props.roleRoutes.find(ele => ele.route === 'manufacture_report').action);
        return (
            <div>
                <ProdServiceReportComponent
                    timeDim={this.state.timeDim}
                    isMainCompany={this.state.isMainCompany}
                    prodReportServiceList={this.props.prodReportServiceList}
                    outputCheckList={this.props.outputCheckList}
                    dateHeadDataList={this.props.dateHeadDataList}
                    companies={this.getCompanyList()}
                    sortSubs={this.state.sortSubs}
                    selectedCompanyId={this.state.selectedCompanyId}
                    selectedCompanyName={this.state.selectedCompanyName}
                    constant={this.props.constant}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleChangeCompany={this.handleChangeCompany.bind(this)}
                    onhandleSelectViewChange={this.handleSelectView.bind(this)}
                    onhandleShowServiceOrgReport={this.handleShowServiceOrgReport.bind(this)}
                    onhandleShowServiceDetailReport={this.handleShowServiceDetailReport.bind(this)}
                    onhandleCheckOutput={this.handleCheckOutput.bind(this)}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    roleFlag={roleFlag}
                    onhandleExport={this.handleExport.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
                {
                    // this.state.isShowService &&
                    <ProdServiceReportDetailPanelContainer
                        date={this.state.detailDate}
                        isShowService={this.state.isShowService}
                        companyId={this.state.selectedCompanyId}
                        timeDim={this.state.timeDim}
                        prodReportServiceOrgList={this.props.prodReportServiceOrgList}
                        onhandleClose={this.handleClose.bind(this)}
                        outputCheckList={this.props.outputCheckList}
                    />
                }
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setDateHeadDataList()
        this.props.setProdReportServiceList()
    }
}

const mapStateToProps = (state) => {
    return {
        prodReportList: state.manufactureProdReport.prodReportList, // 旧
        dateHeadDataList: state.manufactureProdReport.dateHeadDataList,
        prodReportServiceList: state.manufactureProdReport.prodReportServiceList,
        prodReportServiceOrgList: state.manufactureProdReport.prodReportServiceOrgList,
        outputCheckList: state.manufactureProdReport.outputCheckList,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.constant.companies,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProdReportList: (companyId, timeDim, startDate, endDate) => {
            dispatch(getProdReportList(companyId, timeDim, startDate, endDate))
        },
        getProdReportServiceList: (companyId, timeDim, startDate, endDate) => {
            return dispatch(getProdReportServiceList(companyId, timeDim, startDate, endDate))
        },
        // getProdReportServiceDetailList: (timeDim, detailDate, companyId, serviceId) => {
        //     return dispatch(getProdReportServiceDetailList(timeDim, detailDate, companyId, serviceId))
        // },
        onhandleDownloadFile: (startDate, endDate, companyId) => {
            return dispatch(exportProdServiceReport(startDate, endDate, companyId))
        },
        getProdReportServiceOrgList: (companyId, timeDim, startDate, endDate) => {
            return dispatch(getProdReportServiceOrgList(companyId, timeDim, startDate, endDate))
        },
        setDateHeadDataList: () => {
            return dispatch(setDateHeadDataList([]));
        },
        setProdReportServiceList: () => {
            return dispatch(setProdReportServiceList([]));
        },
        onhadleMassge: (message, type) => {
            dispatch(createMessage(type, message));
        },

        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },

        onhandleCheckManufactureOutput: (checkDto) => {
            return dispatch(checkManufactureOutput(checkDto));
        },

        onhandleGetManufactureOutputCheckList: (companyId, startDate, endDate) => {
            return dispatch(getManufactureOutputCheckList(companyId, startDate, endDate, null));
        },



    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProdServiceReportContainer);
