import React, {Component} from 'react';
import {connect} from 'react-redux';
import { updateMenuOpen } from '../../redux/modules/menu';
import InstallationMapView from '../../components/InstallationManagement/InstallationMapView'
import {routeToInstallationList,initInstallationMapEdit} from '../../redux/modules/installation';
import PropTypes from "prop-types";


class InstallationMapViewContainer extends Component {
    static propTypes = {
        installationInfo: PropTypes.object,
        globalLoading: PropTypes.bool,
      };
    
      componentDidMount()  {
        let id = this.props.match.params.id
        this.props.initInstallationMapEdit(id);
        this.props.updateMenuOpen(false);
      }
    
      routerToList(event, instId) {
        this.props.routeToInstallationList();
        this.props.updateMenuOpen(true);
      }
    
      render() {
        return (
          <div>
            <InstallationMapView
              onRouterToList={this.routerToList.bind(this)}
              installationInfo={this.props.installationInfo}
              globalLoading={this.props.globalLoading}
            />
          </div>
        )
      }
    }
    
    const mapStateToProps = (state) => {
      return {
        installationInfo: state.installation.editInstallation,
        globalLoading: state.loading.globalLoading,
      }
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        updateMenuOpen: (value) => {
          dispatch(updateMenuOpen(value));
        },
        routeToInstallationList: () => {
          dispatch(routeToInstallationList());
        },
        initInstallationMapEdit: (value) => {
          dispatch(initInstallationMapEdit(value));
        },
      }
    };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationMapViewContainer)
