import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    title: '',
    comment: '',
    confirmCallback: null,
    closeCallback: null,
    openWithDoc: false,
    docInfo: {},
    // parameters: {}
    // add loading status at original component, not this confirm dialog
};

export const openApprovePannel = createAction('@@xcloud/approvepannel/open',
    (
        title, confirmCallback, closeCallback
    ) => ({
        title, confirmCallback, closeCallback
    })
);

export const closeApprovePannel = createAction('@@xcloud/approvepannel/close');

export const changeComment = createAction('@@xcloud/approvepannel/changecomment');

export const changeDocInfo = createAction('@@xcloud/approvepannel/changeDocInfo');

export const openApprovePannelWithDoc = createAction('@@xcloud/approvepannel/openApprovePannelWithDoc',
    (
        title, comment, withDoc, confirmCallback, closeCallback
    ) => ({
        title, comment, withDoc, confirmCallback, closeCallback
    })
);
// Actions

export const approvePannelReducer = handleActions(
    {
        [openApprovePannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                openWithDoc: false,
                title: data.title,
                comment: '',
                docInfo: {},
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
            };
        },
        [openApprovePannelWithDoc]: (state, { payload: data }) => {
            return {
                ...state,
                open: false,
                openWithDoc: true,
                title: data.title,
                withDoc: data.withDoc,
                comment: data.comment,
                docInfo: {},
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
            };
        },
        [closeApprovePannel]: (state,) => {
            return {
                ...state,
                open: false,
                openWithDoc: false,
                title: '',
                comment: '',
                docInfo: {},
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeComment]: (state, { payload: comment }) => {
            return {
                ...state,
                comment: comment,
            };
        },
        [changeDocInfo]: (state, { payload: doc }) => {
            return {
                ...state,
                docInfo: doc,
            };
        },
    },
    initialState
);

export default approvePannelReducer;
