import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AnnualGoal from '../../../components/LeadershipManagement/AnnualGoal/AnnualGoal';
import { getProcessByRoleId, getCurrentProcessStatus } from '../../../redux/modules/process';
import * as _ from 'lodash';


class AnnualGoalContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
        const { currentUserInfo, onhandleGetNewPlans, onhandleGetCurrentProcessStatus } = this.props
        onhandleGetNewPlans(currentUserInfo.roleId)
        onhandleGetCurrentProcessStatus()
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    render() {
        let createPermission = _.find(this.props.startProcesses, plan => { return plan.processCode === 'SafetyGoal' })
        createPermission = createPermission ? true : false

        let hasPlan = false
        const currentProcessStatus = this.props.currentProcessStatus
        if (currentProcessStatus
            && currentProcessStatus.statusCode)
            hasPlan = true

        return (
            <div>
                <AnnualGoal
                    createPermission={createPermission}
                    tabValue={this.state.tabValue}
                    hasPlan={hasPlan}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        startProcesses: state.process.startProcesses,
        currentProcessStatus: state.process.currentProcessStatus.SafetyGoal || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetNewPlans: (roleId) => {
            dispatch(getProcessByRoleId(roleId, 1))
        },
        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('SafetyGoal', null, callback))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnnualGoalContainer);