import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ShiftRuleInfoComponent from '@/components/ManufactureManagement/ManufactureShiftRule/ShiftRuleInfo/ShiftRuleInfoComponent';
import { addShiftRule, updateShiftRule } from '@/redux/modules/manufacture/manufactureShiftRule';
import { getMoment, formatDate } from '@/utils/datetime';
import { MANUFACTURE_DICT_TYPES } from '@/utils/constant'
import { createMessage } from '@/redux/modules/message'
import * as _ from 'lodash';

class MAttrContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const { shiftRule } = this.props;
        if (!shiftRule || !shiftRule.ruleId) {
            this.setState({ shiftRule: { startTime: getMoment()(), shiftType: "SHIFT_WORKDAY" } });
        } else {
            this.setState({ shiftRule: shiftRule });
        }
        this.getDtps();
    }

    handleSave = () => {
        const { addShiftRule, updateShiftRule, selectedCompanyId } = this.props;
        const { shiftRule } = this.state;
        const newShiftRule = {};
        if (shiftRule.dptId) {
            newShiftRule.dptId = shiftRule.dptId;
        }
        if (shiftRule.workDays) {
            newShiftRule.workDays = parseInt(shiftRule.workDays);
        } else if (shiftRule.shiftType === 'SHIFT_WORKDAY') {
            this.props.onhandleMessage("工作天数不能为零");
            return
        }
        newShiftRule.restDays = shiftRule.restDays ? parseInt(shiftRule.restDays) : 0
        newShiftRule.secondWorkDays = shiftRule.secondWorkDays ? parseInt(shiftRule.secondWorkDays) : 0
        newShiftRule.secondRestDays = shiftRule.secondRestDays ? parseInt(shiftRule.secondRestDays) : 0
        newShiftRule.thirdWorkDays = shiftRule.thirdWorkDays ? parseInt(shiftRule.thirdWorkDays) : 0
        newShiftRule.thirdRestDays = shiftRule.thirdRestDays ? parseInt(shiftRule.thirdRestDays) : 0

        if (shiftRule.startTime) {
            newShiftRule.startTimeStr = formatDate(shiftRule.startTime);
        }
        if (shiftRule.shiftType) {
            newShiftRule.shiftType = shiftRule.shiftType;
        }
        if (shiftRule.ruleId) {
            newShiftRule.ruleId = shiftRule.ruleId;
            updateShiftRule(shiftRule.ruleId, newShiftRule).then(data => {
                if (data) {
                    this.handleClose();
                }
            });
        } else {
            newShiftRule.companyId = selectedCompanyId
            addShiftRule(newShiftRule).then(data => {
                if (data) {
                    this.handleClose();
                }
            });
        }
    }

    getDtps = () => {
        const { selectedCompanyId, departmentList } = this.props;
        const dpts = _.filter(departmentList, item => {
            return item.companyId === selectedCompanyId
        })
        this.setState({
            dpts: dpts
        })
    }

    handleTextChange = (event) => {
        let shiftRule = this.state.shiftRule;
        shiftRule[event.target.name] = event.target.value;
        this.setState({
            shiftRule: shiftRule
        });
    }

    handleDateChange = (date) => {
        const { shiftRule } = this.state;
        this.setState({
            shiftRule: {
                ...shiftRule,
                startTime: date
            }
        });
    }

    handleClose = () => {
        const { onhandleClose } = this.props;
        onhandleClose();
    }


    render() {
        return (
            <div>
                <ShiftRuleInfoComponent
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleTextChange={this.handleTextChange.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleClose={this.handleClose.bind(this)}
                    shiftTypeDicts={this.props.shiftTypeDicts}
                    shiftRule={this.state.shiftRule}
                    dpts={this.state.dpts}
                    open={this.props.open}
                />

            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        departmentList: state.department.departments.list,
        shiftTypeDicts: _.filter(state.constant.manufactureDicts, d => {
            return d.type === MANUFACTURE_DICT_TYPES.SHIFT_TYPE
        }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addShiftRule: (shiftRuleDto) => {
            return dispatch(addShiftRule(shiftRuleDto));
        },
        updateShiftRule: (ruleId, shiftRuleDto) => {
            return dispatch(updateShiftRule(ruleId, shiftRuleDto));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MAttrContainer);
