import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from './message'
import { formSelectTree } from '../../utils/constant';
import { getYear } from '../../utils/datetime';
import { getDepartmentsByRole } from '../../utils/userUtils';
import { getCompanyList, getCompanyALLList } from './company'
import { getDepartments } from './department'
import { getBus } from './bu'
import { getJobList } from './job'
import _ from 'lodash'
import { getAmoebaSubs } from './amoeba/amoebaIndex';

const porxyApi = '/api'

const initialState = {
    departments: [],
    businessunits: [],
    constants: {},
    companies: [],
    allCompanies: [],
    companyTree: {},
    allCompanyTree: {},
    jobs: [],
    industry: [],
    areas: [],
    activityStatus: [],
    holidays: [],
    manufactureAttrTypes: [],
    mandatoryRegulations: [],
    companySelection: undefined,
    inspectionDptMapping: [],
    manufactureDicts: [],
    processTypes:[],
};

export const initArea = createAction("@@xcloud/constant/initArea",
    () => (dispatch, getState, httpClient) => {
        let areas = getState().constant.areas;
        if (!areas || areas.length === 0) {
            let url = '/api/unprotected/utils/user/dictionaries?type=AREA';
            httpClient.get(url)
                .then((res) => {
                    if (res.data.length > 0) {
                        let formedData = formSelectTree(res.data, false);
                        dispatch(setArea(formedData));
                    }
                    return res;
                })
                .catch(err => {
                    let msg = `获取字典常量[区域]失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                });
        }
    });

export const initIndustry = createAction("@@xcloud/constant/initIndustry",
    () => (dispatch, getState, httpClient) => {
        let industry = getState().constant.industry;
        if (!industry || industry.length === 0) {
            let url = '/api/unprotected/utils/user/dictionaries?type=INDUSTRY';
            httpClient.get(url)
                .then((res) => {
                    if (res.data.length > 0) {
                        let formedData = formSelectTree(res.data, false);
                        dispatch(setIndustry(formedData));
                    }
                    return res;
                })
                .catch(err => {
                    let msg = `获取字典常量[行业]失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                });
        }
    });

export const initConstantLogin = createAction("@@xcloud/constant/initconstantlogin",
    () => (dispatch, getState, httpClient) => {
        const currentUserInfo = getState().auth.currentUserInfo
        if (currentUserInfo) {
            Promise.all([
                dispatch(getUserDictionaries()),
                dispatch(_getAllCompanies()),
                dispatch(_getCompanies()), //Get all companies
                dispatch(_getBusinessunits()), //Get all business unit
                dispatch(_getJobs()), //Get all job
                dispatch(getLeadershipDictionaries()),
                dispatch(getInspectionDictionaries()),
                // dispatch(getCrmDictionaries()),
                dispatch(getInspectionDptMapping()),
                dispatch(getDeviceDictionaries()),
                dispatch(getProcessStatus()),
                dispatch(getProcessTypes()),
                dispatch(getActivityStatus()),
                dispatch(getHolidays(getYear())),
                dispatch(getHolidayDictionaries()),
                dispatch(getMandatoryRegulations()),
                dispatch(getManufactureDictionaries()),
                dispatch(getAmoebaSubs())
            ]).then(() => {
                dispatch(_getDepartments())//Get all department info with companyName
            })
        }
    })

export const getUserDictionaries = createAction("@@xcloud/constant/getUserDictionaries",
    () => (dispatch, getState, httpClient) => {
        // 获取所有类型常量的root数据
        let url = '/api/unprotected/utils/user/dictionaries?pcode=null';
        httpClient.get(url)
            .then((res) => {
                if (res.status === 200) {
                    let newConstants = {};
                    for (let element of res.data) {
                        if (!newConstants[element.type]) {
                            newConstants[element.type] = [];
                        }
                        newConstants[element.type].push({
                            code: element.code,
                            name: element.name
                        });
                    }
                    dispatch(setConstants(newConstants));
                }
                return res;
            })
            .catch(err => {
                let msg = `获取字典常量失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const cleanConstant = createAction("@@xcloud/constant/cleanconstant")

export const setConstants = createAction('@@xcloud/constant/setConstants');

export const setIndustry = createAction('@@xcloud/constant/setIndustry');
export const setArea = createAction('@@xcloud/constant/setArea');


const setJobs = createAction("@@xcloud/constant/setjobs")
const _getJobs = createAction("@@xcloud/constant/getjobs",
    () => (dispatch, getState, httpClient) => {
        dispatch(getJobList()).then((data) => {
            if (data.payload)
                dispatch(setJobs(data.payload.list))
            return data.payload
        })
    });

const setDepartments = createAction("@@xcloud/constant/setdepartments")
const _getDepartments = createAction("@@xcloud/constant/getdepartments",
    () => (dispatch, getState, httpClient) => {
        dispatch(getDepartments({ offset: 0, size: 0 })).then((data) => {
            if (data.payload) {
                const companies = getState().constant.allCompanies
                data.payload.list.forEach(dpt => {
                    const company = _.find(companies, { 'companyId': dpt.companyId })
                    // 包装 公司-部门名
                    dpt.companyName = company.companyName
                    dpt.companyAlias = company.companyAlias.substring(0, 2)
                    dpt.companyDptName = dpt.dptName.includes(dpt.companyAlias) ? dpt.dptName : dpt.companyAlias + dpt.dptName
                    // 包装 部门company-pid
                    dpt.companyPid = company.pid
                })
                dispatch(setDepartments(data.payload.list))
            }
            return data.payload
        })
    });


const setBusinessunits = createAction("@@xcloud/constant/setbusinessunits")
const _getBusinessunits = createAction("@@xcloud/constant/getbusinessunits",
    () => (dispatch, getState, httpClient) => {
        dispatch(getBus({ offset: 0, size: 0 })).then((data) => {
            if (data.payload)
                dispatch(setBusinessunits(data.payload.list))
            return data.payload
        })
    });

const setCompanies = createAction("@@xcloud/constant/setcompanies")
const setAllCompanies = createAction("@@xcloud/constant/setAllCompanies")
const setCompanyTree = createAction('@@xcloud/constant/setCompanyTree');
const setAllCompanyTree = createAction('@@xcloud/constant/setAllCompanyTree');
const _getCompanies = createAction("@@xcloud/constant/getcompanies",
    () => (dispatch, getState, httpClient) => {
        dispatch(getCompanyList()).then((data) => {
            if (data.payload)
                dispatch(setCompanies(_.sortBy(data.payload.list, 'companyId')))
            let companyTree = formSelectTree(data.payload.list, false, 'companyName', 'companyId', 'pid')
            dispatch(setCompanyTree(companyTree))
            return data.payload
        })
    });

const _getAllCompanies = createAction("@@xcloud/constant/_getAllCompanies",
    () => (dispatch, getState, httpClient) => {
        dispatch(getCompanyALLList()).then((data) => {
            if (data.payload)
                dispatch(setAllCompanies(_.sortBy(data.payload.list, 'companyId')))
            let companyTree = formSelectTree(data.payload.list, false, 'companyName', 'companyId', 'pid')
            dispatch(setAllCompanyTree(companyTree))
            return data.payload
        })
    }
);

export const getMandatoryRegulations = createAction('@@xcloud/constant/getmandatoryregulations',
    () => (dispatch, getState, httpClient) => {
        httpClient.get(porxyApi + '/mandatoryregulations')
            .then((res) => {
                if (res) {
                    dispatch(setMandatoryRegulations(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[强制性规章制度]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });


export const setDeviceDictionaries = createAction("@@xcloud/constant/setDeviceDictionaries")
export const getDeviceDictionaries = createAction("@@xcloud/constant/getDeviceDictionaries",
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/device/dictionaries';
        if (query && query !== '') {
            if (query.code || query.pcode || query.type) {
                url = url + '?'
                let strs = []
                if (query.code)
                    strs.push('code=' + query.code)
                if (query.pcode)
                    strs.push('pcode=' + query.pcode)
                if (query.type)
                    strs.push('type=' + query.type)
                url = url + strs.join('&')
            }
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setDeviceDictionaries(res.data));
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const setMandatoryRegulations = createAction("@@xcloud/constant/setmandatoryregulations")

export const getLeadershipDictionaries = createAction("@@xcloud/constant/getleadershipdict",
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/leadership/dictionaries';
        if (query && query !== '') {
            if (query.code || query.pcode || query.type) {
                url = url + '?'
                let strs = []
                if (query.code)
                    strs.push('code=' + query.code)
                if (query.pcode)
                    strs.push('pcode=' + query.pcode)
                if (query.type)
                    strs.push('type=' + query.type)
                url = url + strs.join('&')
            }
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setLeadershipDictionaries(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setLeadershipDictionaries = createAction("@@xcloud/constant/setleadershipDictionaries")

export const getInspectionDictionaries = createAction("@@xcloud/constant/getinspectiondict",
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/inspection/dictionaries';
        if (query && query !== '') {
            if (query.code || query.pcode || query.type) {
                url = url + '?'
                let strs = []
                if (query.code)
                    strs.push('code=' + query.code)
                if (query.pcode)
                    strs.push('pcode=' + query.pcode)
                if (query.type)
                    strs.push('type=' + query.type)
                url = url + strs.join('&')
            }
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setInspectionDictionaries(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setInspectionDictionaries = createAction("@@xcloud/constant/setInspectionDictionaries")

export const getInspectionDptMapping = createAction("@@xcloud/constant/getInspectionDptMapping",
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/inspection/dictionaries/dptMapping';
        if (query && query !== '') {
            if (query.inspectionCode || query.dptId) {
                url = url + '?'
                let strs = []
                if (query.inspectionCode)
                    strs.push('inspectionCode=' + query.inspectionCode)
                if (query.dptId)
                    strs.push('dptId=' + query.dptId)
                url = url + strs.join('&')
            }
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    const currentUserInfo = getState().auth.currentUserInfo;
                    const userProfile = getState().auth.userProfile;
                    // 用户所属部门
                    const dpt = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0];
                    const userDptId = dpt ? dpt.dptId : null;
                    // 部门可选的排查类型
                    const dptInspectionTypes = _.filter(res.data, { 'dptId': userDptId });
                    dispatch(setInspectionDptMapping(dptInspectionTypes))
                }
            })
            .catch(err => {
                let msg = `获取常量[排查部门映射]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });
export const setInspectionDptMapping = createAction("@@xcloud/constant/setInspectionDptMapping");

export const getCrmDictionaries = createAction("@@xcloud/constant/getCrmDictionaries",
    (query) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/crm/dictionaries';
        if (query && query !== '') {
            if (query.code || query.pcode || query.type) {
                url = url + '?'
                let strs = []
                if (query.code)
                    strs.push('code=' + query.code)
                if (query.pcode)
                    strs.push('pcode=' + query.pcode)
                if (query.type)
                    strs.push('type=' + query.type)
                url = url + strs.join('&')
            }
        }
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setCrmDictionaries(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[客户管理字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setCrmDictionaries = createAction("@@xcloud/constant/setCrmDictionaries")

export const getHolidays = createAction("@@xcloud/constant/getholidays",
    (year, companyId, type) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/holidays';
        url += '?year=' + year;
        if (type !== undefined)
            url += '&type=' + type;
        if (companyId !== undefined)
            url += '&companyId=' + companyId;

        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setHolidays(res.data))
                }
            })
            .catch(err => {
                let msg = `获取节假日失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setManufactureattrtypes = createAction("@@xcloud/constant/setmanufactureattrtypes")

export const getManufactureattrtypes = createAction("@@xcloud/constant/getmanufactureattrtypes",
    () => (dispatch, getState, httpClient) => {
        let manufactureAttrTypes = getState().constant.manufactureAttrTypes;
        if (!manufactureAttrTypes || manufactureAttrTypes.length === 0) {
            let url = '/api/unprotected/utils/manufacture/dictionaries?type=ACTIVITY_ATTR';
            return httpClient.get(url)
                .then((res) => {
                    if (res.data.length > 0) {
                        dispatch(setManufactureattrtypes(res.data))
                    }
                    return res;
                })
                .catch(err => {
                    let msg = `获取字典[生产属性类型]失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                });
        }
    });


export const setManufactureDictionaries = createAction("@@xcloud/constant/setManufactureDictionaries")

export const getManufactureDictionaries = createAction("@@xcloud/constant/getManufactureDictionaries",
    (type) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/unprotected/utils/manufacture/dictionaries';
        if (type) url += '?type=' + type;
        return httpClient.get(url)
            .then((res) => {
                if (res.data.length > 0) {
                    dispatch(setManufactureDictionaries(res.data))
                }
                return res;
            })
            .catch(err => {
                let msg = `获取字典[生产模块]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            });
    });

export const setHolidayDictionaries = createAction("@@xcloud/constant/setDictionaries")

export const getHolidayDictionaries = createAction("@@xcloud/constant/getHolidayDictionaries",
    () => (dispatch, getState, httpClient) => {
        let url = gernateUserDictionariesUrl({ type: 'HOLIDAYTYPE' })
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setHolidayDictionaries(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const changeCompany = createAction("@@xcloud/constant/initCreateHoliday",
    (year, companySelection) => (dispatch, getState, httpClient) => {
        dispatch(setCompanySelection(companySelection));
        dispatch(getHolidays(year, companySelection ? companySelection.value : undefined))
    });

export const createHoliday = createAction("@@xcloud/constant/createHoliday",
    (entity) => (dispatch, getState, httpClient) => {
        let companySelection = getState().constant.companySelection;
        let url = porxyApi + '/utils/holidays';
        httpClient.post(url, entity)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '创建节假日成功'));
                    dispatch(getHolidays(entity.year, companySelection ? companySelection.value : undefined))
                }
            })
            .catch(err => {
                let msg = '创建节假日失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const updateHoliday = createAction("@@xcloud/constant/updateHoliday",
    (entity) => (dispatch, getState, httpClient) => {
        let companySelection = getState().constant.companySelection;
        entity['startTime'] = new Date(entity['startTime'])
        entity['endTime'] = new Date(entity['endTime'])
        let url = porxyApi + '/utils/holidays/' + entity.holidayId;
        httpClient.patch(url, entity)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '更新节假日成功'));
                    dispatch(getHolidays(entity.year, companySelection ? companySelection.value : undefined))
                }
            })
            .catch(err => {
                let msg = '更新节假日失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const deleteHoliday = createAction("@@xcloud/constant/updateHoliday",
    (entity) => (dispatch, getState, httpClient) => {
        let companySelection = getState().constant.companySelection;
        let url = porxyApi + '/utils/holidays/' + entity.holidayId;
        let dto = {
            holidayId: entity.holidayId,
            status: 'inactive'
        }
        httpClient.patch(url, dto)
            .then((res) => {
                if (res) {
                    dispatch(createMessage('success', '删除节假日成功'));
                    dispatch(getHolidays(entity.year, companySelection ? companySelection.value : undefined))
                }
            })
            .catch(err => {
                let msg = '删除节假日失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setHolidays = createAction("@@xcloud/constant/setholidays")

const gernateUserDictionariesUrl = (query) => {
    let url = porxyApi + '/unprotected/utils/user/dictionaries';
    if (query && query !== '') {
        if (query.code || query.pcode || query.type) {
            url = url + '?'
            let strs = []
            if (query.code)
                strs.push('code=' + query.code)
            if (query.pcode)
                strs.push('pcode=' + query.pcode)
            if (query.type)
                strs.push('type=' + query.type)
            url = url + strs.join('&')
        }
    }
    return url

}
export const getProcessStatus = createAction("@@xcloud/constant/getprocessstatus",
    () => (dispatch, getState, httpClient) => {
        let url = gernateUserDictionariesUrl({ type: 'PROCESS_STATUS' })
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setProcessStatus(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setProcessStatus = createAction("@@xcloud/constant/setlprocessstatus")

export const getProcessTypes = createAction("@@xcloud/constant/getProcessTypes",
    () => (dispatch, getState, httpClient) => {
        let url = gernateUserDictionariesUrl({ type: 'PROCESS_TYPE' })
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setProcessTypes(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setProcessTypes = createAction("@@xcloud/constant/setProcessTypes")

export const getActivityStatus = createAction("@@xcloud/constant/getactivitystatus",
    () => (dispatch, getState, httpClient) => {
        let url = gernateUserDictionariesUrl({ type: 'STATUS' })
        httpClient.get(url)
            .then((res) => {
                if (res) {
                    dispatch(setActivityStatus(res.data))
                }
            })
            .catch(err => {
                let msg = `获取常量[字典]失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
            })
    });

export const setActivityStatus = createAction("@@xcloud/constant/setactivitystatus")

export const setCompanySelection = createAction('@@xcloud/job/setCompanySelection');

export const ConstantReducer = handleActions(
    {
        [setCompanySelection]: (state, { payload: value }) => {
            return {
                ...state,
                companySelection: value
            };
        },
        [cleanConstant]: (state) => {
            return {
                ...state,
                departments: [],
                businessunits: [],
            }
        },
        [setCompanies]: (state, { payload: value }) => ({
            ...state,
            companies: value
        }),
        [setAllCompanies]: (state, { payload: value }) => ({
            ...state,
            allCompanies: value
        }),
        [setCompanyTree]: (state, { payload: value }) => {
            return {
                ...state,
                companyTree: value
            };
        },
        [setAllCompanyTree]: (state, { payload: value }) => {
            return {
                ...state,
                allCompanyTree: value
            };
        },
        [setConstants]: (state, { payload: value }) => ({
            ...state,
            constants: value
        }),

        [setBusinessunits]: (state, { payload: data }) => {
            return {
                ...state,
                businessunits: data
            }
        },

        [setMandatoryRegulations]: (state, { payload: data }) => {
            return {
                ...state,
                mandatoryRegulations: data
            }
        },

        [setDepartments]: (state, { payload: data }) => {
            return {
                ...state,
                departments: data
            }
        },

        [setJobs]: (state, { payload: data }) => {
            return {
                ...state,
                jobs: data
            }
        },

        [setLeadershipDictionaries]: (state, { payload: data }) => {
            return {
                ...state,
                leadershipDictionaries: data
            }
        },

        [setDeviceDictionaries]: (state, { payload: data }) => ({
            ...state,
            deviceDictionaries: data
        }),

        [setInspectionDictionaries]: (state, { payload: data }) => {
            return {
                ...state,
                inspectionDictionaries: data
            }
        },

        [setInspectionDptMapping]: (state, { payload: data }) => {
            return {
                ...state,
                inspectionDptMapping: data
            }
        },

        [setCrmDictionaries]: (state, { payload: data }) => {
            return {
                ...state,
                crmDictionaries: data
            }
        },
        [setHolidays]: (state, { payload: data }) => {
            return {
                ...state,
                holidays: data
            }
        },
        [setManufactureattrtypes]: (state, { payload: data }) => {
            return {
                ...state,
                manufactureAttrTypes: data
            }
        },
        [setHolidayDictionaries]: (state, { payload: data }) => {
            return {
                ...state,
                holidayDictionaries: data
            }
        },

        [setProcessStatus]: (state, { payload: data }) => {
            return {
                ...state,
                processStatus: data
            }
        },
        [setProcessTypes]: (state, { payload: data }) => {
            return {
                ...state,
                processTypes: data
            }
        },
        [setActivityStatus]: (state, { payload: data }) => {
            return {
                ...state,
                activityStatus: data
            }
        },
        [setIndustry]: (state, { payload: value }) => ({
            ...state,
            industry: value
        }),
        [setArea]: (state, { payload: value }) => ({
            ...state,
            areas: value
        }),
        [setManufactureDictionaries]: (state, { payload: value }) => ({
            ...state,
            manufactureDicts: value
        }),
    },
    initialState
)

export default ConstantReducer
