import React, { Component } from 'react';
import "./index.css";
import "../../../resource/rc-select.css"
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import * as _ from 'lodash';

export class FillDeviceGroupInfo extends Component {
    constructor(props) {
        super(props)
        this._cancelCreate = this._cancelCreate.bind(this);
        this._handelChangeName = this._handelChangeName.bind(this);
        this._handelChangeTagNo = this._handelChangeTagNo.bind(this);
        this._createDeviceGroup = this._createDeviceGroup.bind(this);
        this.state = {
            name: '',
            tagNo: '',
            mainDevice: null,
            deviceList: []
        }
    }

    componentDidMount = () => {
        if (this.props.parameters.deviceList && this.props.parameters.deviceList.length > 0) {
            this.props.parameters.deviceList.forEach(device => {
                let ids = _.split(device.id, '_');
                if (ids && ids.length > 1) {
                    device.value = _.toInteger(ids[1]);
                }
                device.label = device.name;
            })
            this.setState({
                deviceList: this.props.parameters.deviceList
            });
        }
    }

    _cancelCreate() {
        let stage = this.props.Stage;
        let worklayer = stage.findOne('.workLayer');
        let groupTemp = worklayer.findOne('.newGroupTemp');
        groupTemp.find('Group').forEach(group => {
            group.moveTo(worklayer);
        })
        groupTemp.destroy();
        worklayer.draw();
        this.props.cleanDialog();
    }

    _createDeviceGroup() {
        let deviceGroup = {}
        deviceGroup.name = this.state.name;
        deviceGroup.tagNo = this.state.tagNo;
        let masterId = this.state.mainDevice.value;
        let devices = _.filter(this.state.deviceList, function (o) { return o.value !== masterId; });
        devices.forEach(device => {
            device.masterId = masterId;
        })
        deviceGroup.deviceList = this.state.deviceList;
        console.log(deviceGroup);
        this.props.createDeviceGroupMap(deviceGroup);
        this.props.cleanDialog();
    }

    _handelChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    _handelChangeTagNo(e) {
        this.setState({
            tagNo: e.target.value
        });
    }

    onSelectMainDevice = (value) => {
        this.setState({
            mainDevice: value
        });
    }

    _disableCheck = () => {
        if (!(this.state.name === '' || this.state.tagNo === '' || !this.state.mainDevice)) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <div className='fillarea'>
                <div className='title'>请输入设备组名称:</div>
                <input className='inputarea' type="text" onChange={this._handelChangeName.bind(this)} placeholder='设备组' />
                <div className='title'>请输入设备组位号:</div>
                <input className='inputarea' type="text" onChange={this._handelChangeTagNo.bind(this)} placeholder='位号' />
                <div className='title'>请选择主设备:</div>
                <TreeSelect
                    style={{ width: '100%', margin: '5px 0px' }}
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                    allowClear
                    treeLine
                    value={this.state.mainDevice}
                    labelInValue
                    treeData={this.state.deviceList}
                    treeNodeFilterProp="label"
                    filterTreeNode={false}
                    onChange={(value) => this.onSelectMainDevice(value)}
                />
                <div className='confirm-btn'>
                    <button className='confirm-btn' onClick={this._createDeviceGroup.bind(this)} disabled={this._disableCheck()}>确定</button>
                    <button className='confirm-btn' onClick={this._cancelCreate.bind(this)}>取消</button>
                </div>
            </div>
        )
    }
}
