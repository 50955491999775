import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationGoalPlanDetail from '../../../components/DeviceManagement/OperationAnnuaPlan/OperationGoalPlanDetailNew';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import {
    initPlanDetail,
    routerToList,
    updateOperationPlanDetail,
    getPlanDetailList,
    setPlanDetailPageSize,
    routeToOperationGoalPlanCreate
} from "../../../redux/modules/operationPlan";
import {formatDate, getMoment} from "@/utils/datetime";
import {getTreePartById} from "@/utils/constant";
import {getCompanyId} from "@/utils/userUtils";
// import * as _ from 'lodash';


class OperationGoalPlanDetailContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        operationPlan: PropTypes.object,
        operationPlanDetails: PropTypes.object,
        isLoading: PropTypes.bool,
        optCategories: PropTypes.array,
        procat: PropTypes.array,
        planDetailPageSize: PropTypes.number,
        isViewMode: PropTypes.bool,
        catList: PropTypes.array,
        roleTree: PropTypes.array,
        roleList: PropTypes.array,
        companyList: PropTypes.array,
        rules: PropTypes.object,
        accCatList: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            planId: '',
            year: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            month: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            companyId: "",
            filterCompanyTree: [],
            routeType: '',
            targetDateStart:null,
            targetDateEnd: null,
            budgetStart:null,
            budgetEnd: null,
            optNo: '',
            onlyMe: false,
            userId: null,
            ruleId: null
        }
    }
    handleClearSearch = () => {

        this.setState({
            year: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            month: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            companyId: "",
            filterCompanyTree: [],
            targetDateStart:null,
            targetDateEnd: null,
            budgetStart:null,
            budgetEnd: null,
            routeType: '',
            optNo: '',
            onlyMe: false,
            ruleId: null
        })

    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({ planId: id });
        this.props.onhanleInitPlanDetail(id);

        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            this.setState({ companyId: companyId, filterCompanyTree: tree })
        } else {
            companyId = "";
            this.setState({ filterCompanyTree: tree })
        }

    }
    handleChangeItem = (value, field) => {
        if (field === 'companyId') {
            this.setState({ companyId: value.target.value })
        }  else if (field === 'targetDateStart') {
            // 执行时间 - 开始
            this.setState({ targetDateStart: formatDate(value) })
        } else if (field === 'targetDateEnd') {
            // 执行时间 - 结束
            this.setState({ targetDateEnd: formatDate(value) })

        } else if (field === 'ruleId') {
            // 规则
            this.setState({ ruleId: value.target.value })

        }  else if (field === 'optNo') {
            //
            this.setState({ optNo: value.target.value })

        }

    }

    handleUpdate(operationPlanDetail) {
        const { onhandleUpdate, onhanleGetPlanDetailList } = this.props;
        // final execute function from other component or redux-action
        onhandleUpdate(operationPlanDetail, operationPlanDetail.dtlId, () => {
            onhanleGetPlanDetailList({
                offset: 0,
                planId: operationPlanDetail.planId
            })
        })
    }
    hanleSearchPlanDetailList = () => {
        const { onhanleGetPlanDetailList } = this.props;

        // 检索运维计划详细列表
        onhanleGetPlanDetailList({
            offset: 0,
            companyId: this.state.companyId,
            fromDate: this.state.targetDateStart,
            toDate: this.state.targetDateEnd,
            amoeba: this.state.routeType === 'projectmanage' ? true : null,
            size: 10,
            planId: this.state.planId,
            // 执行时间 - 开始
            targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            // 执行时间 - 结束
            targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            ruleId: this.state.ruleId ? this.state.ruleId : null,
            // 工单下达状态
            orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            optNo: this.state.optNo ? this.state.optNo : null,
            // 用户名(目前仅与 “只看我的” 关联)
            userId: this.state.userId ? this.state.userId : null
        })

    }



    handleDelete(operationPlanDetail) {

        let changeStatus = () => {
            operationPlanDetail.status = 'inactive'
            const { onhandleUpdate, onhanleGetPlanDetailList } = this.props;
            // final execute function from other component or redux-action
            onhandleUpdate(operationPlanDetail, operationPlanDetail.dtlId, () => {
                onhanleGetPlanDetailList({
                    offset: 0,
                    planId: operationPlanDetail.planId
                })
            })
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除运维计划详细[' + operationPlanDetail.dtlId + ']吗?', changeStatus, null, false);

    }


    handleRouteToOperationGoalPlanCreate(target) {
        this.props.routeToOperationGoalPlanCreate(this.state.planId, target)
    }

    render() {
        return (
            <div>
                <OperationGoalPlanDetail
                    routeType={this.state.routeType}
                    operationPlan={this.props.operationPlan}
                    operationPlanDetails={this.props.operationPlanDetails}
                    isLoading={this.props.isLoading}
                    optCategories={this.props.optCategories}
                    procat={this.props.procat}
                    catList={this.props.catList}
                    accCatList={this.props.accCatList}
                    planDetailPageSize={this.props.planDetailPageSize}
                    isViewMode={this.props.isViewMode}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    onhandleChangeItem={this.handleChangeItem.bind(this)}
                    onhandleRouterToList={this.props.onhandleRouterToList.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    setPlanDetailPageSize={this.props.setPlanDetailPageSize.bind(this)}
                    onhanleGetPlanDetailList={this.props.onhanleGetPlanDetailList.bind(this)}
                    onhanleSearchPlanDetailList={this.hanleSearchPlanDetailList.bind(this)}
                    onhandleRouteToOperationGoalPlanCreate={this.handleRouteToOperationGoalPlanCreate.bind(this)}
                    roleTree={this.props.roleTree}
                    roleList={this.props.roleList}
                    companyList={this.props.companyList}
                    companyTree={this.state.filterCompanyTree}
                    companyId={this.state.companyId}
                    currentProcessStatus={this.props.currentProcessStatus}
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    // 运维类别 （检索条件）
                    optType={this.state.optType}
                    // 执行时间 - 开始 （检索条件）
                    targetDateStart={this.state.targetDateStart}
                    // 执行时间 - 结束 （检索条件）
                    targetDateEnd={this.state.targetDateEnd}
                    rules = {this.props.rules}
                    ruleId = {this.state.ruleId}
                    optNo={this.state.optNo}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        operationPlan: state.operationPlan.currentPlan,
        operationPlanDetails: state.operationPlan.planDetails,
        isLoading: state.loading.globalLoading,
        optCategories: state.operationRule.optCategories,
        procat: state.devices.procat,
        planDetailPageSize: state.operationPlan.planDetailPageSize,
        isViewMode: state.operationPlan.isViewMode,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        catList: state.trees.deviceCategoryList,
        roleTree: state.role.roleTree,
        roleList: state.role.roleList.list,
        accCatList: state.accessory.catList,
        companyList: state.constant.companies,
        companyTree: state.constant.companyTree,
        rules: state.operationRule.rules,
        currentProcessStatus: state.process.currentProcessStatus && state.process.currentProcessStatus['OPERATIONPLAN'] ? state.process.currentProcessStatus['OPERATIONPLAN'] : {}
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        routeToOperationGoalPlanCreate: (planId, target) => {
            dispatch(routeToOperationGoalPlanCreate(planId, target))
        },
        onhanleInitPlanDetail: (id) => {
            dispatch(initPlanDetail(id))
        },
        onhandleRouterToList: () => {
            dispatch(routerToList())
        },
        onhandleUpdate: (operationPlanDetail, dtlId, callback) => {
            dispatch(updateOperationPlanDetail(operationPlanDetail, dtlId, callback));
        },
        onhanleGetPlanDetailList: (query) => {
            dispatch(getPlanDetailList(query));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        setPlanDetailPageSize: (value) => {
            dispatch(setPlanDetailPageSize(value));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationGoalPlanDetailContainer);
