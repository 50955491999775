import React from "react";
import PropTypes from "prop-types";
import withStyles from "./styles";
import {
  Card,
  Typography,
  Button,
  TextField,
  Grid,
  MenuItem, FormControl, Select, FormHelperText
} from "@material-ui/core";
import { getMoment as moment } from "@/utils/datetime";
import { Prompt } from "react-router-dom";
import _ from "lodash";

const ChecklistEdit = (props) => {
  const {
    classes,
    onhandleSave,
    onhandleChange,
    onhandleBack,
    onhandleCheck,
    currentInventory,
    currentChecklist,
    editable,
    checklistError,
    checklistChange,
    onhandleListMapping,
    onhandleSelectChange,
    showNoSpecify,
    departmentList,
    // inspectionDptMapping,
  } = props;

  const getStatusData = () => {
    let result = []

    result.push({ label: '1级', value: '1级' })
    result.push({ label: '2级', value: '2级' })
    result.push({ label: '3级', value: '3级' })

    return result
  }

  const departmentOptions = () => (
    <React.Fragment>
      {departmentList ?
        departmentList
          .filter(data => (data.companyId === currentInventory.companyId || (showNoSpecify && !currentInventory.companyId)))
          .sort((a, b) => b.companyPid ? -1 : 1)
          .map(data => (
            <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
          )) : null
      }
    </React.Fragment>
  )

  return (
    <div>
      <Card className={classes.card}>
        <Grid
          container
          spacing={0}
          className={classes.gridContainer}
          justify={"space-between"}
        >
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom>
              {currentChecklist.listId
                ? currentInventory.inventoryName + "明细详情"
                : "新建" + currentInventory.inventoryName + "明细"}
              {/* {currentInventory.inventoryName ? " - " + currentInventory.inventoryName : null} */}
            </Typography>
          </Grid>
          {currentChecklist.listId ? (
            <Grid item margin={0}>
              <Typography variant="h5" style={{ display: "inline-block" }}>
                {"编号: " + currentChecklist.serialNo}
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          {!editable &&
            currentChecklist.attr1 &&
            currentChecklist.attr1 !== "" && (
              <>
                <Grid item xs={1}>
                  <Typography gutterBottom className={classes.label}>
                    研判内容
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom>
                    {currentChecklist.attr1 +
                      (currentChecklist.attr2
                        ? " - " + currentChecklist.attr2
                        : "")}
                  </Typography>
                </Grid>
              </>
            )}
          {editable && (
            <>
              <Grid item xs={1}>
                <Typography gutterBottom className={classes.label}>
                  研判区域
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  className={classes.textarea}
                  value={currentChecklist.attr1 || ""}
                  rows={5}
                  onChange={(event) => onhandleChange(event, "attr1")}
                />
              </Grid>

              <Grid item xs={1}>
                <Typography gutterBottom className={classes.label}>
                  研判内容
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  className={classes.textarea}
                  value={currentChecklist.attr2 || ""}
                  rows={5}
                  onChange={(event) => onhandleChange(event, "attr2")}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>

          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              危害因素描述
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              multiline
              variant="outlined"
              InputProps={{
                readOnly: !editable,
              }}
              className={classes.textarea}
              value={currentChecklist.reference || ""}
              rows={5}
              onChange={(event) => onhandleChange(event, "reference")}
              onBlur={(event) => onhandleCheck(event, "reference")}
            />
          </Grid>

          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              研判标准
              <span className={classes.required}> *</span>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              multiline
              variant="outlined"
              error={checklistError.problem !== ""}
              helperText={checklistError.problem || ""}
              className={classes.textarea}
              value={currentChecklist.problem || ""}
              InputProps={{
                readOnly: !editable,
              }}
              rows={5}
              onChange={(event) => onhandleChange(event, "problem")}
              onBlur={(event) => onhandleCheck(event, "problem")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              检查部门
              <span className={classes.required}> *</span>
            </Typography>
          </Grid>
          {!editable ? (
            <Grid item xs={3}>
              <Typography gutterBottom>
                {onhandleListMapping(currentChecklist.dptId, 'department')}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <FormControl className={classes.formControl} disabled={!editable}
                error={checklistError.department !== ''}
              >
                <Select
                  style={{ width: '90%' }}
                  native
                  value={currentChecklist.dptId || 0}
                  onChange={(event) => onhandleSelectChange(event, 'department')}
                >
                  {showNoSpecify && <option aria-label="None" label="不限" value={0} />}
                  {departmentOptions()}
                </Select>
                <FormHelperText id="component-error-text">{(!currentChecklist.dptId || currentChecklist.dptId === '') && '注意：本检查项允许分发给所有部门/分厂'}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={1}>
            <Typography gutterBottom className={classes.label}>
              风险等级
            </Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <TextField
              multiline
              variant="outlined"
              InputProps={{
                readOnly: !editable,
              }}
              className={classes.textarea}
              value={currentChecklist.opentext || ""}
              rows={5}
              onChange={(event) => onhandleChange(event, "opentext")}
              onBlur={(event) => onhandleCheck(event, "opentext")}
            />
          </Grid> */}
          <Grid item xs={3}>
            <TextField
              style={{ width: '80%' }}
              select
              value={currentChecklist.opentext || "1级"}
              onChange={(event) => onhandleChange(event, 'opentext')}
              variant="outlined"
            >
              {/* <MenuItem key={1} value={0}></MenuItem> */}
              {_.sortBy(getStatusData(), 'opentext').map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {currentChecklist.listId ? (
          <>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Typography gutterBottom>创建人</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom>
                  {currentChecklist.creatorLastName +
                    currentChecklist.creatorFirstName}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography gutterBottom>创建时间</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom>
                  {moment()
                    .utc(currentChecklist.createTime)
                    .format("yyyy/MM/DD HH:mm:ss")}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : null}

        {currentChecklist.listId && currentChecklist.updateTime ? (
          <>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Typography gutterBottom>更新人</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom>
                  {currentChecklist.revisorLastName +
                    currentChecklist.revisorFirstName}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography gutterBottom>更新时间</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom>
                  {moment()
                    .utc(currentChecklist.updateTime)
                    .format("yyyy/MM/DD HH:mm:ss")}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : null}

        {currentChecklist.listId && currentChecklist.revisor ? (
          <Grid container spacing={1} className={classes.gridContainer}></Grid>
        ) : null}
      </Card>

      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) => onhandleBack(event)}
          >
            {editable && currentChecklist.listId ? "取消" : "返回"}
          </Button>
        </Grid>
        {editable ? (
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => onhandleSave(event)}
              disabled={!checklistChange}
            >
              保存
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Prompt message="数据未保存,确定要离开？" when={checklistChange} />
    </div>
  );
};

ChecklistEdit.propTypes = {
  onhandleSave: PropTypes.func.isRequired,
  onhandleBack: PropTypes.func.isRequired,
  onhandleCheck: PropTypes.func.isRequired,
  currentChecklist: PropTypes.object.isRequired,
  checklistError: PropTypes.object.isRequired,
  checklistChange: PropTypes.bool.isRequired,
  companyList: PropTypes.array.isRequired,
  departmentList: PropTypes.array.isRequired,
  listTypeList: PropTypes.array.isRequired,
  categoryList: PropTypes.array.isRequired,
  rootCauseList: PropTypes.array.isRequired,
  methodList: PropTypes.array.isRequired,
  disciplineList: PropTypes.array.isRequired,
  resultTypeList: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default withStyles(ChecklistEdit);
