import React, { Component } from 'react';
import { connect } from 'react-redux';
import PickingComponent from '@/components/ManufactureManagement/Picking';
import UpdatePickComponent from '@/components/ManufactureManagement/Picking/UpdatePick/UpdatePickComponent';
import PropTypes from 'prop-types';
import { getMoment, formatDate } from '@/utils/datetime';
import { getPick, getPicks, updateManufacturePick } from '@/redux/modules/manufacture/manufacturePick';
import { getOrders } from '@/redux/modules/manufacture/manufactureOrder';
import * as _ from 'lodash'

class PickingContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            isShow: false,
            isediting: false,
            pickInfo: {},
            componentTitle: "",
            openFlg: 0,
            userInfo: this.props.userInfo,
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        const pickTime = formatDate(getMoment()());
        this.props.getPicks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, pickTime, undefined);
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        }, function () {
            const pickTime = formatDate(date);
            this.props.getPicks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, pickTime, undefined);
        })
    }

    handleEdit = (rowData) => {
        this.setState({ componentTitle: "编辑生产领料" });
        this.getInfo(rowData.pickId, false);
    }

    handleOpen = (rowData) => {
        this.setState({ componentTitle: "查看生产领料" });
        this.getInfo(rowData.pickId, true);
    }

    getInfo = (infoId, isShow) => {
        this.props.getPick(infoId).then((data) => {
            this.setState({
                pickInfo: data && data.payload ? data.payload : {},
                openFlg: 1,
                isShow: isShow
            }, function () {
                // this.props.getOrders(undefined,this.state.pickInfo.pickCompany);
            })
        });
    }

    getCompany = () => {
        const { companies } = this.props;
        const company = _.find(companies, item => {
            return item.pid && this.props.userInfo.companyId === item.companyId
        });
        return company ? company.companyId : undefined
    }

    handleSave = () => {
        const pickInfo = this.state.pickInfo;
        const newPickInfo = {
            pickId: pickInfo.pickId,
            orderId: pickInfo.orderId,
        }
        this.props.updateManufacturePick(pickInfo.pickId, newPickInfo).then((data) => {
            this.handleClose();
        });
    }

    handleClose = () => {
        this.setState({
            openFlg: 0
        })
        const pickTime = formatDate(this.state.selectedDate);
        this.props.getPicks(undefined, undefined, this.getCompany(), undefined, undefined, undefined, pickTime, undefined);
    }

    handleTextFieldChange = (event) => {
        const pickInfo = this.state.pickInfo;
        if (event.target.name === 'orderId') {
            this.setState({
                pickInfo: {
                    ...pickInfo,
                    orderId: event.target.value
                }
            });
        }
    }

    render() {
        return (
            <div>
                <PickingComponent
                    selectedDate={this.state.selectedDate}
                    pickList={this.props.pickList}
                    onhandleOpen={this.handleOpen.bind(this)}
                    onhandleEdit={this.handleEdit.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                />
                {
                    this.state.openFlg === 1 && <UpdatePickComponent
                        isediting={this.state.isediting}
                        isShow={this.state.isShow}
                        pickInfo={this.state.pickInfo}
                        orders={this.props.orders}
                        componentTitle={this.state.componentTitle}
                        onhandleSave={this.handleSave.bind(this)}
                        onhandleClose={this.handleClose.bind(this)}
                        onhandleTextFieldChange={this.handleTextFieldChange.bind(this)}

                    />
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        pickList: state.manufacturePick.pickList,
        userInfo: state.auth.currentUserInfo,
        orders: state.manufactureOrder.orders,
        companies: state.company.companyList.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPicks: (pickDpt, stockDpt, pickCompany, stockCompany, orderId, status, pickTime, orderIds) => {
            dispatch(getPicks(pickDpt, stockDpt, pickCompany, stockCompany, orderId, status, pickTime, orderIds));
        },
        getPick: (pickId) => {
            return dispatch(getPick(pickId));
        },
        updateManufacturePick: (pickId, pickDto) => {
            return dispatch(updateManufacturePick(pickId, pickDto));
        },
        getOrders: (startTime, companyId) => {
            dispatch(getOrders(startTime, companyId));
        }

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PickingContainer);
