import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { getMoment, formatDate_hms } from '@/utils/datetime';

import _ from 'lodash'


const ProdServiceReportDetailPanel = props => {
    const { classes,
        isShowService,
        date,
        orgName,
        prodReportServiceOrgList,
        outputCheckList,
        headDataList,
        onhandleClose,
    } = props;

    // console.log('new daily prod org report =======================')
    // console.log(headDataList)
    // console.log(prodReportServiceOrgList)

    const outputCheck = () => {
        return _.find(outputCheckList, c => {
            return getMoment()(c.checkDate).format('yyyy/MM/DD') === date
        })
    }

    const renderOutput = (headItem, row) => {
        const data = row.data || {}
        const outputValue = data[headItem.key] ? (data[headItem.key]).toFixed(2) : 0
        if (outputCheck()) {
            const sData = row.snapshotData || {}
            const sOutputValue = sData[headItem.key] ? (sData[headItem.key]).toFixed(2) : 0
            const isChange = Math.abs(sOutputValue - outputValue) >= 0.01
            if (isChange) {
                return <Tooltip title={"已确认值：" + sOutputValue}><Typography className={isChange ? classes.highlight : {}}>{outputValue}</Typography></Tooltip>
            }
        }
        return <Typography>{outputValue}</Typography>
    }

    const assemblycolumns = () => {
        const columns = [
            {
                title: '服务',
                dataIndex: 'serviceName',
                key: 'serviceName',
                width: 160,
                fixed: 'left',
                render: (text, row, index) => <Typography>{row.serviceName}</Typography>
            },
            {
                title: '单位',
                dataIndex: 'unit',
                key: 'unit',
                width: 60,
                fixed: 'left',
                render: (text, row, index) => <Typography>{row.unit}</Typography>
            }
        ]

        if (headDataList && headDataList.length > 0) {

            _.forEach(headDataList, item => {
                const column = {
                    title: <Typography>{item.name}</Typography>,
                    width: 120,
                    dataIndex: item.key,
                    key: item.key,
                    align: 'right',
                    render: (text, row, index) => renderOutput(item, row)
                }
                columns.push(column);
            })
        }

        return columns

    }

    const assemblyData = () => {
        return prodReportServiceOrgList
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={isShowService} onClose={onhandleClose}>
                <DialogTitle>
                    <Grid container>
                        <Grid item ><Typography component={'span'} variant='h3'>{orgName} {date} {'报表明细'}</Typography>
                        </Grid>
                        <Grid item >{outputCheck() ? <Tooltip title={'确认时间' + formatDate_hms(outputCheck().createTime)}><CheckCircleIcon className={classes.checked}></CheckCircleIcon></Tooltip> : <Typography></Typography>}</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid className={classes.container}>
                        <Table
                            className={classes.stripedTable}
                            rowKey={record => (record.serviceId + '_' + Date.now())} // 保持刷新
                            columns={assemblycolumns()}
                            dataSource={assemblyData()}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: '100%', y: '100%' }}
                            size={"small"}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

ProdServiceReportDetailPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    timeDim: PropTypes.string,
};

ProdServiceReportDetailPanel.defaultProps = {
};

export default withStyles(ProdServiceReportDetailPanel);