import React from 'react';
import {
    TextField
} from '@material-ui/core';
import withStyles from './styles';

const CustomerPage = (props) => {
    const [value, setValue] = React.useState('')
    const handleChangeDocName = event => {
        setValue(event.target.value)
        // console.log(event.target.value)
    };

    return (<TextField
        id="outlined-name"
        label="测试名称"
        onChange={handleChangeDocName.bind(this)}
        value={value}
        margin="normal"
        variant="outlined"
    />)
}

export default withStyles(CustomerPage);
