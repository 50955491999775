import React from 'react';
import {
    Grid,
    Typography,
    Switch,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { formatDate } from "@/utils/datetime"
import * as _ from 'lodash';


const TrackIncomeComponent = props => {
    const { classes,
        incomes,
        departments,
        companies,
        viewType,
        subs,
        onhandleChangeIncomViewType,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }

    const tableStyle = {
        ...style
    }

    const getCompanyName = (companyId) => {
        const company = _.find(companies, c => c.companyId === companyId)
        return company ? company.companyAlias : ''
    }

    const getDptName = (dptId) => {
        const dpt = _.find(departments, d => d.dptId === dptId)
        return dpt ? dpt.dptName : ''
    }

    const getSubName = (subId) => {
        const sub = _.find(subs, d => d.subId === subId)
        return sub ? sub.subName : ''
    }

    const formatNumber = (num) => {

        if (!num || isNaN(num)) return 0.0
        return Number(num).toFixed(2)

    }

    const assemblycolumns = () => {
        const commonColumns = [
            {
                title: '公司',
                field: 'companyId',
                render: row => <Typography>{getCompanyName(row.companyId)}</Typography>,
            },
            {
                title: '班组',
                field: 'dptId',
                render: row => <Typography>{getDptName(row.dptId)}</Typography>,
            },
            {
                title: '指标',
                field: 'subId',
                render: row => <Typography>{getSubName(row.subId)}</Typography>,
            },
        ]

        let viewColumn
        if (viewType === 'DETAIL') { //明细
            viewColumn = [{
                title: '日期',
                field: 'startTime',
                render: row => <Typography>{formatDate(row.startTime)}</Typography>,
            },
            {
                title: '工单号',
                field: 'orderNo',
                render: row => <Typography>{row.orderNo}</Typography>,
            },
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{row.serviceName ? row.serviceName : row.opentext}</Typography>,
            },
            {
                title: '单价',
                align: 'right',
                field: 'unitPrice',
                render: row => <Typography>{formatNumber(row.unitPrice) + (row.unit ? '元/' + row.unit : '')}</Typography>,
            },
            {
                title: '数量',
                align: 'right',
                field: 'attrValue',
                render: row => <Typography>{formatNumber(row.completeOutput) + (row.unit ? row.unit : '')}</Typography>,
            },
            {
                title: '金额(元)',
                field: 'amount',
                align: 'right',
                render: row => <Typography>{formatNumber(row.amount)}</Typography>,
            },]
        } else { //统计

            viewColumn = [
                {
                    title: '服务名称',
                    field: 'serviceName',
                    render: row => <Typography>{row.serviceName ? row.serviceName : row.opentext}</Typography>,
                },
                {
                    title: '单价',
                    align: 'right',
                    field: 'unitPrice',
                    render: row => <Typography>{formatNumber(row.unitPrice) + (row.unit ? '元/' + row.unit : '')}</Typography>,
                },
                {
                    title: '数量',
                    align: 'right',
                    field: 'attrValue',
                    render: row => <Typography>{formatNumber(row.allCompleteOutput) + (row.unit ? row.unit : '')}</Typography>,
                },
                {
                    title: '金额(元)',
                    field: 'amount',
                    align: 'right',
                    render: row => <Typography>{formatNumber(row.allAmount)}</Typography>,
                },]
        }

        return _.concat(commonColumns, viewColumn)

    }

    const assemblyData = () => {

        if (viewType === 'DETAIL') {
            return incomes
        } else {

            let serviceIncomes = [];

            _.map(incomes, i => {
                let servicIncomes = _.find(serviceIncomes, { serviceName: i.serviceName })
                if (servicIncomes) {
                    servicIncomes.allCompleteOutput = servicIncomes.allCompleteOutput + i.completeOutput
                    servicIncomes.allAmount = servicIncomes.allAmount + i.amount
                } else {
                    serviceIncomes.push({
                        ...i,
                        allCompleteOutput: i.completeOutput,
                        allAmount: i.amount
                    })
                }
            })
            return serviceIncomes
        }
    }

    const renderTitle = () => {
        if (viewType === 'DETAIL') {
            return '收入明细-工单'
        } else {
            return '按服务类别统计收入'
        }
    }


    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item className={classes.flexgrow}></Grid>
                    <Grid item className={classes.buttonlabel}><Typography>按服务</Typography></Grid>
                    <Grid item className={classes.button}>
                        <Switch color='primary'
                            checked={viewType === 'SERVICE'}
                            onClick={onhandleChangeIncomViewType} >
                        </Switch>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant="h3">{renderTitle()}</Typography>}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

TrackIncomeComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    incomes: PropTypes.array,
    departments: PropTypes.array,
    companies: PropTypes.array,
    viewType: PropTypes.string.isRequired,
};

TrackIncomeComponent.defaultProps = {
    incomes: [],
    departments: [],
    companies: [],
    viewType: 'DETAIL',
};

export default withStyles(TrackIncomeComponent);