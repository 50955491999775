import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {
        padding: '8px 8px',
        backgroundColor: 'white',
    },
    toolbarContainer: {
        paddingLeft: theme.spacing(2)
    }
});


export default withStyles(styles);