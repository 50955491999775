import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    detailtable: {
        padding: theme.spacing(4)
    },
    action: {
        padding: 16,
    },
    title: {
        padding: theme.spacing(0)
    },
    button: {
        padding: 16,
        paddingTop: 24,
    },
    flexgrow: {
        flexGrow: 1
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    label: {
        marginTop: 7
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    menuroot: {
        '& .MuiMenu-paper': {
            border: '1px solid #d3d4d5',
        },
    },
    tabs: {
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.contrastText,
            },
            backgroundColor: theme.palette.primary.main
        }
    },
    new: {
        width: 60,
        backgroundColor: colors.grey[400],
        color: theme.palette.white,
    },
    active: {
        width: 60,
        backgroundColor: colors.green[400],
        color: theme.palette.white,
    },
    finalized: {
        width: 60,
        backgroundColor: colors.yellow[400],
        color: theme.palette.black,
    },
    done: {
        width: 60,
        backgroundColor: colors.blue[400],
        color: theme.palette.white,
    },
    default: {
        width: 60,
        backgroundColor: colors.grey[300],
        color: theme.palette.white,
    },
    root: {
        padding: theme.spacing(0)
    },
    content: {
        marginTop: theme.spacing(0),
        padding: 20
    },
    gridContainer: {
        margin: 10
    },
    textField: {
        width: '90%',
    },
    interval: {
        textAlign: 'center'
    },
});

export default withStyles(styles);