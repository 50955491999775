// 年度计划、月度计划、5S计划共用本store
import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from '@/redux/modules/message';
// import { getDepartments } from '@/redux/modules/department';
import { getUsersWithCallback, getAllLeaders } from '@/redux/modules/user';
import {
  getPlanDetails
} from "@/redux/modules/inspection/riskInvestigation";
import {
  getChecklistCount,
  getListTypeList,
  getCategoryList
} from "@/redux/modules/inspection/riskInspection";
import { getRoleTree } from "@/redux/modules/role";
import { getMajorHazard, resetSearch as resetMajorHazardSearch } from "@/redux/modules/majorHazard";
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment as moment } from '@/utils/datetime';
import _ from 'lodash';

const porxyApi = '/api';

const defaultState = {
  monthlyPlans: {
    total: 0,
    list: []
  },
  currentMonthlyPlan: {},
  planResults: [],
  planRecords: [],
  monthlyPlanError: {},
  monthlyPlanChange: false,
  search: {
    companyId: 0,
    dptId: 0,
    listType: '',
    catCode: '',
    year: moment()().format('yyyy'),
    yearMonth: moment()(),
    inspectHead: undefined,
    creatorName: false,
    status: '',
    page: 0,
    size: 10
  },
  checkUserList: [],
  companyList: [],
  listTypeList: [],
  categoryList: [],
  statusList: [],
  roleList: []
};

export const getStatusList = createAction('@@xcloud/monthlyPlan/getStatusList',
  () => (dispatch, getState, httpClient) => {
    const mock = [
      {
        name: '未开始',
        code: 'finalized'
      }, {
        name: '进行中',
        code: 'active'
      }, {
        name: '已完成',
        code: 'done'
      }, {
        name: '已过期',
        code: 'invalid'
      }, {
        name: '已补录',
        code: 'expired'
      }
    ]
    dispatch(setStatusList(mock));
  });

export const resetSearch = createAction('@@xcloud/monthlyPlan/resetSearch',
  () => (dispatch, getState, httpClient) => {

    const companyId = getState().auth.currentUserInfo.companyId;
    const company = _.find(getState().constant.companies, { 'companyId': companyId });
    const hasPcomp = company && company.pid;
    dispatch(setSearch({
      companyId: hasPcomp ? companyId : 0,
      dptId: 0,
      listType: '',
      catCode: '',
      year: moment()().format('yyyy'),
      yearMonth: moment()(),
      inspectHead: undefined,
      creatorName: false,
      status: '',
      page: 0,
      size: 10
    }));
    let companies = getState().constant.companies;
    let companyList = [];
    companies.forEach(comp => {
      companyList.push({
        name: comp.companyAlias,
        code: comp.companyId
      })
    })
    dispatch(setCompanyList(companyList));
  });

export const initMonthlyPlanHeader = createAction('@@xcloud/monthlyPlan/initMonthlyPlanHeader',
  (isBack) => (dispatch, getState) => {

    dispatch(createloading());
    if (!isBack) {
      dispatch(resetSearch())
    }
    Promise.all([
      // dispatch(getDepartments({
      //   offset: 0,
      //   size: 0
      // })),
      dispatch(getRoleTree()),
      // 重置重大危险源搜索项
      dispatch(resetMajorHazardSearch())
    ]).then(
      () => {
        let roles = getState().role.roleTree;
        let roleList = [];
        roles.forEach(element => {
          if (element.roleGroups) {
            element.roleGroups.forEach(group => {
              if (group.roleList) {
                group.roleList.forEach(role => {
                  roleList.push({
                    companyId: element.companyId,
                    name: role.roleName,
                    code: role.roleId
                  });
                })
              }
            })
          }
        })
        dispatch(setRoleList(roleList));
        dispatch(getListTypeList())
        dispatch(getCategoryList())
        dispatch(getStatusList())
      }
    ).then(() => {
      dispatch(getUsersWithCallback({
        offset: 0,
        size: 0
      }))
      dispatch(getAllLeaders())
      dispatch(getMajorHazard({
        offset: 0,
        size: 0,
        sort: "-update_time"
      }))
      dispatch(getChecklistCount())
      dispatch(getPlanDetails(getState().inspectionPlanMonthly.search))
    }).then(() => {
      dispatch(cleanloading());
    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(cleanloading());
        let msg = '初始化月度排查计划搜索失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const backToMonthlyPlanList = createAction('@@xcloud/monthlyPlan/backToMonthlyPlanList',
  () => (dispatch, getState) => {
    dispatch(push('/inspection/riskinvestigation', { isBack: true }));
  });

export const backToFiveSPlanList = createAction('@@xcloud/monthlyPlan/backToFiveSPlanList',
  () => (dispatch, getState) => {
    dispatch(push('/inspection/5splan', { isBack: true }));
  });

export const getPlanResults = createAction('@@xcloud/inspectionplanMonthly/getPlanResults',
  (query, callback) => (dispatch, getState, httpClient) => {
    dispatch(setMonthlyPlanChange(false));
    let url = '/api/inspections/results';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined)
      url += "&size=" + query.size;
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.companyId)
      url += "&companyId=" + query.companyId;
    if (query.dtlId)
      url += "&dtlId=" + query.dtlId;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.userId)
      url += "&userId=" + query.userId;
    if (query.planType)
      url += "&planType=" + query.planType;
    if (query.status)
      url += "&status=" + query.status;
    if (query.fromDate)
      url += "&fromDate=" + query.fromDate;
    if (query.toDate)
      url += "&toDate=" + query.toDate;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setPlanResults(res.data.list));
            if (callback)
              callback(res.data)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '排查记录获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const addPlanResult = createAction('@@xcloud/inspectionplanMonthly/addPlanResult',
  (planResult, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    httpClient.post(porxyApi + '/inspections/results', planResult)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          // console.log('实施记录添加成功')
          if (callback)
            callback(res.data)
        }
      })
      .catch(err => {
        dispatch(cleanloading());
        let msg = `实施记录添加失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  })

export const deletePlanResult = createAction('@@xcloud/inspectionplanMonthly/deletePlanResult',
  (resultId, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    httpClient.delete(porxyApi + '/inspections/results/' + resultId)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          if (callback)
            callback(res.data)
        }
      })
      .catch(err => {
        dispatch(cleanloading());
        let msg = `实施记录删除失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  })

export const clearPlanResults = createAction('@@xcloud/inspectionplanMonthly/clearPlanResults',
  (dtlId, dptId, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    // dptId可选
    let url = '/inspections/results/detail/' + dtlId;
    if (dptId !== undefined) {
      url += "?dptId=" + dptId;
    }
    httpClient.delete(porxyApi + url)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          // dispatch(createhttpMessage(res, "实施记录清空"));
          if (callback)
            callback(res.data)
        }
      }
      ).catch(err => {
        dispatch(cleanloading());
        let msg = `实施记录清空失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });


export const getPlanRecords = createAction('@@xcloud/inspectionplanMonthly/getPlanRecords',
  (query, callback) => (dispatch, getState, httpClient) => {
    dispatch(setMonthlyPlanChange(false));
    let url = '/api/inspections/records';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined)
      url += "&size=" + query.size;
    if (query.sort !== undefined){
      url += "&sort=" + query.sort;
    }else{
      url += "&sort=-record_time";
    }
    if (query.resultId)
      url += "&resultId=" + query.resultId;
    if (query.planId)
      url += "&planId=" + query.planId;
    if (query.dtlId)
      url += "&dtlId=" + query.dtlId;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.fromDate)
      url += "&fromDate=" + query.fromDate;
    if (query.toDate)
      url += "&toDate=" + query.toDate;
    if (query.userId)
      url += "&userId=" + query.userId;
    if (query.result) // true, false, 0 ~ 10
      url += "&result=" + query.result;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setPlanRecords(res.data));
            if (callback)
              callback(res.data)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '检查记录获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });
export const getPlanRecordsForCsv = createAction('@@xcloud/inspectionplanMonthly/getPlanRecordsForCsv',
  (query, callback) => (dispatch, getState, httpClient) => {
    let url = '/api/inspections/records';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined)
      url += "&size=" + query.size;
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.resultId)
      url += "&resultId=" + query.resultId;
    if (query.planId)
      url += "&planId=" + query.planId;
    if (query.dtlId)
      url += "&dtlId=" + query.dtlId;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.fromDate)
      url += "&fromDate=" + query.fromDate;
    if (query.toDate)
      url += "&toDate=" + query.toDate;
    if (query.userId)
      url += "&userId=" + query.userId;
    if (query.result) // true, false, 0 ~ 10
      url += "&result=" + query.result;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            callback(res.data)
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '检查记录获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const setupPlanRecords = createAction('@@xcloud/inspectionplanMonthly/setupPlanRecords',
  (setupObj, callback) => (dispatch, getState, httpClient) => {
    dispatch(createloading());
    httpClient.post(porxyApi + '/inspections/records/setup', setupObj)
      .then((res) => {
        dispatch(cleanloading());
        if (res) {
          createMessage('success', '生成检查记录成功')
          if (callback)
            callback(res.data)
        }
      })
      .catch(err => {
        dispatch(cleanloading());
        let msg = `生成检查记录失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  })

export const setMonthlyPlans = createAction('@@xcloud/monthlyPlan/setMonthlyPlans');

export const setPlanResults = createAction('@@xcloud/monthlyPlan/setPlanResults');

export const setPlanRecords = createAction('@@xcloud/monthlyPlan/setPlanRecords');

export const setSearch = createAction('@@xcloud/monthlyPlan/setSearch');

export const setCheckUserList = createAction('@@xcloud/monthlyPlan/setCheckUserList');

export const setCompanyList = createAction('@@xcloud/monthlyPlan/setCompanyList');

export const setStatusList = createAction('@@xcloud/monthlyPlan/setStatusList');

export const setCurrentMonthlyPlan = createAction('@@xcloud/monthlyPlan/setCurrentMonthlyPlan');

export const setMonthlyPlanChange = createAction('@@xcloud/monthlyPlan/setMonthlyPlanChange');

export const stopLoading = createAction('@@xcloud/monthlyPlan/stopLoading');

export const startLoading = createAction('@@xcloud/monthlyPlan/startLoading');

export const setRoleList = createAction('@@xcloud/userSelect/setRoleList');


export const monthlyPlanReducer = handleActions(
  {
    [setMonthlyPlans]: (state, { payload: value }) => {
      return {
        ...state,
        monthlyPlans: value
      };
    },
    [setPlanResults]: (state, { payload: value }) => {
      return {
        ...state,
        planResults: value
      };
    },
    [setPlanRecords]: (state, { payload: value }) => {
      return {
        ...state,
        planRecords: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setCheckUserList]: (state, { payload: value }) => {
      return {
        ...state,
        checkUserList: value
      };
    },
    [setStatusList]: (state, { payload: value }) => {
      return {
        ...state,
        statusList: value
      };
    },
    [setCompanyList]: (state, { payload: value }) => {
      return {
        ...state,
        companyList: value
      };
    },
    [setMonthlyPlanChange]: (state, { payload: value }) => {
      return {
        ...state,
        monthlyPlanChange: value
      };
    },
    [setCurrentMonthlyPlan]: (state, { payload: value }) => {
      return {
        ...state,
        currentMonthlyPlan: value
      };
    },
    [startLoading]: (state) => {
      return {
        ...state,
        isloading: true
      };
    },
    [stopLoading]: (state) => {
      return {
        ...state,
        isloading: false
      };
    },
    [setRoleList]: (state, { payload: value }) => {
      return {
        ...state,
        roleList: value
      };
    },
  },
  defaultState
);

export default monthlyPlanReducer;
