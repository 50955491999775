import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';
import { getManufactureServiceById } from './manufactureService';

const porxyApi = '/api'

const initialState = {
    recordList: [], //生产活动记录属性列表
    userTasks: []
};

export const setActivityRecords = createAction('@@xcloud/manufacture/setActivityRecords');

export const addManufactureActivityRecord = createAction('@@xcloud/manufacture/addManufactureActivityRecord',
    (activityRecordDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/activities/records', activityRecordDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加生产活动记录成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加生产活动记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManufactureActivityRecord = createAction('@@xcloud/manufacture/updateManufactureActivityRecord',
    (recordId, activityRecordDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/manufacture/activities/records/' + recordId, activityRecordDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新生产活动记录成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新生产活动记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delManufactureActivityRecord = createAction('@@xcloud/manufacture/delManufactureActivityRecord',
    (recordId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/activities/records/' + recordId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除生产活动记录成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除生产活动记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getActivityRecords = createAction('@@xcloud/manufacture/getActivityRecords',
    (installId, payCompany, payDpt, receiveCompany, receiveDpt, participateCompany, participateDpt, orderIds, serviceId, activityId, companyId, triggerTime, status, taskId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/activities/records'
        let strs = []
        if (installId || payCompany || payDpt || receiveCompany || receiveDpt || participateCompany || participateDpt || orderIds || serviceId || activityId || triggerTime || status || companyId || taskId) {
            if (installId) strs.push('installId=' + installId)
            if (payCompany) strs.push('payCompany=' + payCompany)
            if (payDpt) strs.push('payDpt=' + payDpt)
            if (receiveCompany) strs.push('receiveCompany=' + receiveCompany)
            if (receiveDpt) strs.push('receiveDpt=' + receiveDpt)
            if (participateCompany) strs.push('participateCompany=' + participateCompany)
            if (participateDpt) strs.push('participateDpt=' + participateDpt)
            if (orderIds) strs.push('orderIds=' + orderIds)
            if (serviceId) strs.push('serviceId=' + serviceId)
            if (activityId) strs.push('activityId=' + activityId)
            if (companyId) strs.push('companyId=' + companyId)
            if (taskId) strs.push('taskId=' + taskId)
            if (triggerTime) strs.push('triggerTime=' + triggerTime)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setActivityRecords(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取生产活动记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getActivityRecord = createAction('@@xcloud/manufacture/getActivityRecord',
    (recordId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/activities/records/' + recordId
        return httpClient.get(url).then((res) => {
            if (res) {
                return res;
            }
        }).catch(err => {
            let msg = `获取生产活动记录失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

// export const getUserManufactureTasks = createAction('@@xcloud/manufacture/getUserManufactureTasks',
//     () => (dispatch, getState, httpClient) => {
//         dispatch(getManufactureTasks(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
// });

export const getServiceActivityAttrs = createAction('@@xcloud/manufacture/getServiceActivityAttrs',
    (serviceId) => (dispatch, getState, httpClient) => {
        return dispatch(getManufactureServiceById(serviceId));
    });

export const manufactureActivityRecordReducer = handleActions(
    {
        [setActivityRecords]: (state, { payload: data }) => {
            return {
                ...state,
                recordList: data
            }
        },
    },
    initialState
)

export default manufactureActivityRecordReducer
