import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserList from "../../../components/SystemManagement/UserManagement/UserList";
import PropTypes from "prop-types";
import { getUsers, initUpdate, setSearch, changeUserStatus, setPageSize, assginRole, assginDepartment, setOffset } from '../../../redux/modules/user';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import * as _ from 'lodash';

class UserListContainer extends Component {
    static propTypes = {
        users: PropTypes.object,
        search: PropTypes.object,
        userProfile: PropTypes.object,
        pageSize: PropTypes.number
    };

    constructor(props) {
        super(props)
        this.state = {
            isMainCompany: true
        }
    }

    componentDidMount() {
        let com = _.find(this.props.companyList, (c) => { return c.companyId === this.props.userInfo.companyId })
        if (com.pid) {
            this.setState({ isMainCompany: false })
        }
    }

    handleEdit(event, id) {
        this.props.onhandleEdit(id);
    }

    handleDelete = (event, id, name) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(id, 'inactive');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除用户[' + name + ']吗?', changeStatus, null, false);
    };

    handleActivate = (event, id, name) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(id, 'active');
        }
        this.props.onhandleOpenConfirmDialog('激活确认', '确定要激活用户[' + name + ']吗?', changeStatus, null, false);
    };

    assignRole = (val, uid, callback) => {
        if (val !== undefined) {
            let changeRole = () => {
                this.props.onhandleAssignRole(uid, val.value)
                callback(null)
            }
            this.props.onhandleOpenConfirmDialog('更新角色确认', '确定要改变用户角色吗?', changeRole, null, false);
        }
    }

    assginDepartment = (val, uid, callback) => {
        if (val !== undefined) {
            let changeDpt = () => {
                this.props.onhandleAssignDepartment(uid, val.value)
                callback(null)
            }
            this.props.onhandleOpenConfirmDialog('更新部门确认', '确定要改变用户部门吗?', changeDpt, null, false);
        }
    }

    render() {
        return (
            <UserList
                users={this.props.users}
                isMainCompany={this.state.isMainCompany}
                userProfile={this.props.userProfile}
                offset={this.props.offset}
                pageSize={this.props.pageSize}
                roleList={this.props.roleList}
                roleTree={this.props.roleTree}
                departmentTree={this.props.departmentTree}
                departmentList={this.props.departmentList}
                onAssginRole={this.assignRole.bind(this)}
                onAssginDepartment={this.assginDepartment.bind(this)}
                setPageSize={this.props.onSetPageSize.bind(this)}
                setOffset={this.props.onSetOffset.bind(this)}
                onEdit={this.handleEdit.bind(this)}
                getUserList={this.props.onhandleGetUserList}
                search={this.props.search}
                onDelete={this.handleDelete.bind(this)}
                onActivate={this.handleActivate.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        search: state.user.search,
        userInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        offset: state.user.offset,
        pageSize: state.user.pageSize,
        roleList: state.user.fullroleList,
        roleTree: state.user.roleTree,
        departmentTree: state.user.departmentTree,
        departmentList: state.user.fulldepartmentList,
        companyList: state.constant.companies
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetUserList: (query) => {
            dispatch(getUsers(query))
        },
        onhandleEdit: (id) => {
            dispatch(initUpdate(id));
        },
        onhandleChangeStatus: (id, status) => {
            dispatch(changeUserStatus(id, status));
        },
        onhandleAssignRole: (uid, rid) => {
            dispatch(assginRole(uid, rid));
        },
        onhandleAssignDepartment: (uid, did) => {
            dispatch(assginDepartment(uid, did));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onSetPageSize: (num) => {
            dispatch(setPageSize(num))
        },
        onSetOffset: (num) => {
            dispatch(setOffset(num))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserListContainer)
