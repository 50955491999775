import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message'

const porxyApi = '/api'

const initialState = {
    materialList: [],//生产产品列表
    materialGroups: [],//生产产品分组
    rawMaterialList: []
};


export const setServiceList = createAction('@@xcloud/manufacture/setServiceList');

export const getServiceList = createAction('@@xcloud/manufacture/getServiceList',
    () => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/materials?materialGroup=117069'
        return httpClient.get(url).then((res) => {
            if (res.status === 200) {
                dispatch(setServiceList(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取服务列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const setSelectMaterialList = createAction('@@xcloud/manufacture/setSelectMaterialList');
export const setRawMaterialList = createAction('@@xcloud/manufacture/setRawMaterialList');

export const getSelectMaterialList = createAction('@@xcloud/manufacture/getSelectMaterialList',
    (materialGroup, callback) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materials'
            let strs = []
            if (materialGroup) {
                if (materialGroup) strs.push('materialGroup=' + materialGroup)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback(res.data);
                    }
                    dispatch(setSelectMaterialList(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产产品列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });


export const getRawMaterialList = createAction('@@xcloud/manufacture/getRawMaterialList',
    (materialGroups, callback) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materials/groups'
            let strs = []
            if (materialGroups) {
                if (materialGroups) strs.push('materialGroups=' + materialGroups)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback(res.data);
                    }
                    // dispatch(setRawMaterialList(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取员材料列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const getMaterialListByGroup = createAction('@@xcloud/manufacture/getSelectMaterialList',
    (materialGroups, callback) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materials/groups'
            let strs = []
            if (materialGroups) {
                if (materialGroups) strs.push('materialGroups=' + materialGroups)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback(res.data);
                    }
                    dispatch(setSelectMaterialList(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产产品列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const setSelectProductList = createAction('@@xcloud/manufacture/setSelectProductList');

export const getSelectProductList = createAction('@@xcloud/manufacture/getSelectProductList',
    (materialGroup) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materials'
            let strs = []
            if (materialGroup) {
                if (materialGroup) strs.push('materialGroup=' + materialGroup)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setSelectProductList(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产产品列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const setMaterialList = createAction('@@xcloud/manufacture/setMaterialList');

export const getMaterialList = createAction('@@xcloud/manufacture/getMaterialList',
    (status, materialGroup) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materials'
            let strs = []
            if (status || materialGroup) {
                if (status) strs.push('status=' + status)
                if (materialGroup) strs.push('materialGroup=' + materialGroup)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setMaterialList(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取生产产品列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const setMaterialGroups = createAction('@@xcloud/manufacture/setMaterialGroups');

export const getMaterialGroups = createAction('@@xcloud/manufacture/getMaterialGroups',
    (status) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/manufacture/materialGroups'
            let strs = []
            if (status) {
                if (status) strs.push('status=' + status)
                url = url + '?' + strs.join('&')
            }
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setMaterialGroups(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取生产产品分类列表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const materialReducer = handleActions(
    {
        [setMaterialList]: (state, { payload: data }) => {
            return {
                ...state,
                materialList: data
            }
        },
        [setMaterialGroups]: (state, { payload: data }) => {
            return {
                ...state,
                materialGroups: data
            }
        },

        [setServiceList]: (state, { payload: data }) => {
            return {
                ...state,
                serviceList: data
            }
        },
        [setSelectMaterialList]: (state, { payload: data }) => {
            return {
                ...state,
                selectMaterialList: data
            }
        },


        [setRawMaterialList]: (state, { payload: data }) => {
            return {
                ...state,
                rawMaterialList: data
            }
        },

        [setSelectProductList]: (state, { payload: data }) => {
            return {
                ...state,
                selectProductList: data
            }
        },
    },
    initialState
)

export default materialReducer
