import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RuleValidPanel from '../../../components/OnsiteInspectionManagement/RuleValidPanel';
import { closeRuleValidPanel, changeRuleStatus } from '@/redux/modules/onsiteInspectionRuleValid';

import _ from 'lodash';

class RuleValidPanelContainer extends Component {
    static propTypes = {
        rule: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            error: {
                'immeEffect': '',
                'opentext': ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(this.props.rule.immeEffect, this.props.rule.opentext);
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, rule } = this.props
        let newEntity = {
            ...rule
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {

        const {
            rule
        } = this.props;

        let err = {};

        if (!field || field === 'immeEffect') {
            if (_.trim(rule.immeEffect) === '') {
                err['immeEffect'] = '请选择生效时间';
            } else {
                err['immeEffect'] = '';
            }
        }

        // if (!field || field === 'opentext') {
        //     if (_.trim(rule.opentext) === '') {
        //         err['opentext'] = '请填写理由';
        //     } else {
        //         err['opentext'] = '';
        //     }
        // }

        let newError = {
            ...this.state.error,
            ...err
        }
        this.setState({
            error: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <RuleValidPanel
                open={this.props.open}
                rule={this.props.rule}
                error={this.state.error}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.onsiteInspectionRuleValid.open,
        rule: state.onsiteInspectionRuleValid.rule,
        confirmCallback: state.onsiteInspectionRuleValid.confirmCallback,
        closeCallback: state.onsiteInspectionRuleValid.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (rule) => {
            dispatch(changeRuleStatus(rule));
        },
        onhandleClose: () => {
            dispatch(closeRuleValidPanel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleValidPanelContainer);