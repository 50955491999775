import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import * as _ from 'lodash';
import {
    Grid,
    Typography,
    CircularProgress,
    Paper,
    List,
    Button
} from '@material-ui/core';
import PointListComponent from './PointList/PointListComponent';
import RouteInfoComponent from './RouteInfo/RouteInfoComponent';
import ProcessLine from '../../ProcessLine';
import ItemOrderListComponent from './ItemOrderList/ItemOrderListComponent';
// import { getCompanyId } from "../../../utils/userUtils";

const RouteDetailComponent = props => {
    const {
        classes,
        constant,
        currentUserInfo,
        currentProcessStatus,
        userProfile,
        companyTree,
        companyList,
        confirmRoleList,
        inspectionDictionaries,
        currentRoute,
        currentRouteOrigin,
        // 当前Record相关历史信息
        currentRecordHistoryList,
        isRouteEditMode,
        isRouteChange,
        onhandleBack,
        onhandleSelect,
        onhandleChange,
        onhandleSave,
        routeError,
        onhandleAddPoint,
        onhandleSaveItemOrder,
        pointList,
        inspectionItemList,
        onhandleDeletePoint,
        onhandleUpdateItem,
        onhandleSelectItem,
        onhandleSavePoint,
        onhandleUpdatePoint,
        onhandleItemOrder,
        isLoading,
        // currentRecord,
        onhandleProcess,
        // onhandleRestart,
        unit,
        pointEditMode,
        onhandleChangeSeq,
        onhandleChangeIgnoreInstall,
        onhandleChangeRecordConfirm,
        users,
        onhandleRouteEditMode
    } = props;

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
        return permission && permission.action === 'W'
    }

    const isIndoorInspection = Boolean(currentRouteOrigin && currentRouteOrigin.inspectionType === 'OIT02');

    // const checkReastartPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_route_detail' })
    //     let company = getCompanyId(currentUserInfo.roleId, userProfile);
    //     let companyId = _.isObject(currentRoute.companyId) ? currentRoute.companyId.value : currentRoute.companyId
    //     return permission && permission.action === 'R' && companyId === company
    // }

    const renderActions = () => {
        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0) {
            // if (currentProcessStatus.statusCode === "Published" && checkReastartPermission()) {
            //     let buttons = (
            //         <Button className={classes.button} variant="contained" color="primary" onClick={onhandleRestart}>
            //             重新起草
            //         </Button>
            //     )
            //     return buttons
            // }
            return
        } else {
            const actions = _.filter(currentProcessStatus.actions, (action) => { return !action.distributed })
            if (actions.length === 2 && (actions[0].actionName === "审核通过" || actions[0].actionName === "审批通过")) {
                actions.reverse()
            }

            let buttons = actions.map((action, idx) => (
                <Button
                    key={`rule_button_${idx}`}
                    disabled={action.disabled || false}
                    variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                    color="primary"
                    className={classes.button}
                    onClick={onhandleProcess.bind(this, action)}
                >
                    {action.actionName}
                </Button>
            ))
            if (currentProcessStatus.statusCode && currentProcessStatus.actions
                && currentProcessStatus.actions.length > 0 && currentProcessStatus.actions[0].disabled) {
                onhandleRouteEditMode(false)
            }

            return buttons
        }
    }

    const showLoading = () => (
        <React.Fragment>
            <div>
                <Grid className={classes.loading} container spacing={0} alignItems="center" wrap="nowrap">
                    <CircularProgress className={classes.progress} />
                </Grid>
            </div>
        </React.Fragment>
    );
    const renderAttention = () => {
        // let record = currentRecord
        let rejectComments = [];

        // ▽　XJGL-133　巡检路线驳回后，再次提交，审批意见没有保留　▽
        // if (record.statusCode !== 'Rejected')
        //     return ''

        currentRecordHistoryList.forEach(tmpHistoryRecord => {
            if (tmpHistoryRecord.comment.includes("驳回：")) {
                rejectComments.push(tmpHistoryRecord.comment)
            }
        })

        if (rejectComments.length > 0) {
            return (<Grid className={classes.attention} >
                {rejectComments.join('\n').split('\n').map((line, index) => (
                    <Typography key={index} className={index ? classes.attentioncontent : classes.attentiontitle}>{line}</Typography>
                ))}
            </Grid>)
        } else {
            return ''
        }

    }

    return (
        <div alignitems="center" className={classes.root}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {!!currentRoute ? '巡检路线详情' : '创建巡检路线'}
                </Typography>
            </div>
            {currentRoute.routeId === 0 || isLoading ? '' : (<Grid container >
                <Grid item className={classes.processline}>
                    <ProcessLine
                        statusCodes={currentProcessStatus.statusCodes}
                        statusCode={currentProcessStatus.statusCode}
                        constant={constant}
                    />
                </Grid>
                <Grid item>
                    <List >
                        {renderActions()}
                    </List>
                </Grid>
            </Grid>)
            }
            {currentRoute.routeId === 0 || isLoading ? '' : renderAttention()}

            {isLoading ? showLoading() : ''}

            <Paper style={{ marginTop: 20, padding: 20, width: '100%' }}>
                <RouteInfoComponent
                    currentUserInfo={currentUserInfo}
                    userProfile={userProfile}
                    companyTree={companyTree}
                    companyList={companyList}
                    confirmRoleList={confirmRoleList}
                    inspectionDictionaries={inspectionDictionaries}
                    currentRoute={currentRoute}
                    isRouteEditMode={isRouteEditMode}
                    isRouteChange={isRouteChange}
                    onhandleSelect={onhandleSelect}
                    onhandleChange={onhandleChange}
                    onhandleChangeRecordConfirm={onhandleChangeRecordConfirm}
                    onhandleSave={onhandleSave}
                    routeError={routeError}
                    users={users}
                />
            </Paper>
            {/* {currentRoute && currentRoute.routeId ? */}
            <Paper style={{ marginTop: 20, padding: 20, width: '100%' }}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography variant="h3" color='textPrimary'>巡检项目</Typography>
                    <Button variant="contained" hidden={!checkPermission() || !isRouteEditMode} disabled={!currentRoute.routeId} style={{ marginRight: 10, marginBottom: 10 }} color="primary" size="small"
                        onClick={event => onhandleAddPoint()}
                    >添加</Button>
                </Grid>
                <PointListComponent
                    isIndoorInspection={isIndoorInspection}
                    currentRoute={currentRoute}
                    pointList={pointList}
                    inspectionDictionaries={inspectionDictionaries}
                    onhandleUpdateItem={onhandleUpdateItem}
                    onhandleSelectItem={onhandleSelectItem}
                    onhandleDeletePoint={onhandleDeletePoint}
                    onhandleSavePoint={onhandleSavePoint}
                    onhandleSaveItemOrder={onhandleSaveItemOrder}
                    onhandleUpdatePoint={onhandleUpdatePoint}
                    userProfile={userProfile}
                    isRouteEditMode={isRouteEditMode}
                    unit={unit}
                    pointEditMode={pointEditMode}
                    onhandleChangeSeq={onhandleChangeSeq}
                    onhandleChangeIgnoreInstall={onhandleChangeIgnoreInstall}
                    onhandleItemOrder={onhandleItemOrder.bind(this, 'point')}
                />
            </Paper>
            {
                isIndoorInspection &&
                <Paper style={{ marginTop: 20, padding: 20, width: '100%' }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h3" color='textPrimary'>内操排序</Typography>
                        <Button variant="contained" hidden={!checkPermission() || !isRouteEditMode} disabled={!currentRoute.routeId} style={{ marginRight: 10, marginBottom: 10 }} color="primary" size="small"
                            onClick={event => onhandleSaveItemOrder(inspectionItemList)}
                        >保存</Button>
                    </Grid>
                    <ItemOrderListComponent
                        currentRoute={currentRoute}
                        pointList={pointList}
                        inspectionItemList={inspectionItemList}
                        onhandleItemOrder={onhandleItemOrder.bind(this, 'item')}
                        inspectionDictionaries={inspectionDictionaries}
                        onhandleUpdateItem={onhandleUpdateItem}
                        onhandleSelectItem={onhandleSelectItem}
                        onhandleDeletePoint={onhandleDeletePoint}
                        onhandleSavePoint={onhandleSavePoint}
                        onhandleUpdatePoint={onhandleUpdatePoint}
                        userProfile={userProfile}
                        isRouteEditMode={isRouteEditMode}
                        unit={unit}
                        pointEditMode={pointEditMode}
                        onhandleChangeSeq={onhandleChangeSeq}
                    />
                </Paper>
            }
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleBack()}
                    >
                        返回
                    </Button>
                </Grid>

            </Grid>
        </div>

    );
};
RouteDetailComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    currentProcessStatus: PropTypes.object,
    userProfile: PropTypes.object.isRequired,
    companyTree: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    inspectionDictionaries: PropTypes.array.isRequired,
    currentRoute: PropTypes.object.isRequired,
    // 当前Record相关历史信息
    currentRecordHistoryList: PropTypes.array.isRequired,
    isRouteEditMode: PropTypes.bool.isRequired,
    isRouteChange: PropTypes.bool.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSelect: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    routeError: PropTypes.object.isRequired,
    onhandleAddPoint: PropTypes.func.isRequired,
    onhandleSaveItemOrder: PropTypes.func.isRequired,
    pointList: PropTypes.array.isRequired,
    onhandleDeletePoint: PropTypes.func.isRequired,
    onhandleUpdateItem: PropTypes.func.isRequired,
    onhandleSelectItem: PropTypes.func.isRequired,
    onhandleSavePoint: PropTypes.func.isRequired,
    onhandleUpdatePoint: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    // currentRecord: PropTypes.object,
    onhandleProcess: PropTypes.func.isRequired,
    // onhandleRestart: PropTypes.func.isRequired,
    unit: PropTypes.array.isRequired,
    pointEditMode: PropTypes.object.isRequired,
    onhandleChangeSeq: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
    onhandleRouteEditMode: PropTypes.func.isRequired,
};

RouteDetailComponent.defaultProps = {
}

export default withRouter(withStyles(RouteDetailComponent));