import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentRecordComponent from '@/components/SystemManagement/PaymentRecord';
import { getMoment, convertToMoment } from '@/utils/datetime';
import { getPaymentRecords, importPaymentRecords, createPaymentRecord, updatePaymentRecord, exportTemplate, initPayment } from '@/redux/modules/payment';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import * as _ from 'lodash';
import { createMessage } from '@/redux/modules/message';
import { upload } from '@/redux/modules/minio';
import { getNowTimestamp } from "@/utils/datetime";

class PaymentRecordContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            userInfo: this.props.userInfo,
            disableCompany: false,
            companyId: this.props.userInfo.companyId,
            userName: undefined,
            userId: undefined
        }
    }

    // 页面初期化获取数据
    componentDidMount() {
        // 查看是否机关
        let com = _.find(this.props.companies, (c) => { return c.companyId === this.state.userInfo.companyId })
        if (com.pid) {
            this.setState({ disableCompany: true })
        }
        this.props.initPayment(com.pid, this.state.userInfo.companyId, undefined, this.state.selectedDate.year(), this.state.selectedDate.month());
    }

    handleChange = (event, field) => {
        if (field === 'company') {
            this.setState({
                companyId: parseInt(event.target.value)
            })
            this.props.getPaymentRecords(parseInt(event.target.value), this.state.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
        } else if (field === 'yearMonth') {
            let newDate = convertToMoment(event)
            this.setState({
                selectedDate: newDate
            })
            this.props.getPaymentRecords(this.state.companyId, this.state.userId, newDate.year(), newDate.month());
        }
    }

    handleUpload = (event) => {
        let uploadParams = {
            children: 'commonFileUpload',
            size: 'medium',
        }
        uploadParams.config = {
            // 上传组件初始化配置
            title: '上传工资记录文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.csv',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '200MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        };

        this.props.onModalCreate(uploadParams);
    }

    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        if (fileType === '.csv') {
            return files;
        } else {
            this.props.onhadleMassgae('上传的文件格式不支持');
        }
    }

    onConfirm(files, isUploadSetter, values) {
        let tenantId = this.props.userProfile.roles[0].tenantId
        isUploadSetter(true);
        let envCode = 'dev';
        if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.payment.record.doc', '{tenant_id}', tenantId);
        let offset = getNowTimestamp();

        this.props.onhandleUploadDoc(files, mybucket, offset, () => {
            let doc = {
                importType: 0,
                docName: offset + files[0].name
            };
            this.props.onhandleImportRecords(doc, () => {
                this.setState({
                    userId: undefined,
                    userName: undefined
                })
                this.props.getPaymentRecords(this.state.companyId, undefined, this.state.selectedDate.year(), this.state.selectedDate.month());
            });
            this.props.onhandleModalClose();
        });
    }

    handleCreate = (data) => {

        let user = _.find(this.props.paymentTargets, (u) => { return u['userId'] === data.userId });
        let newRecord = {
            'userId': data.userId,
            'companyId': user.companyId,
            'dptId': user.dptId,
            'item1': data.item1 ? parseFloat(data.item1) : 0,
            'item2': data.item2 ? parseFloat(data.item2) : 0,
            'item3': data.item3 ? parseFloat(data.item3) : 0,
            'item4': data.item4 ? parseFloat(data.item4) : 0,
            'item5': data.item5 ? parseFloat(data.item5) : 0,
            'item6': data.item6 ? parseFloat(data.item6) : 0,
            'item7': data.item7 ? parseFloat(data.item7) : 0,
            'overtimePay': data.overtimePay ? parseFloat(data.overtimePay) : 0,
            'salaryTotal': data.salaryTotal ? parseFloat(data.salaryTotal) : 0,
            'salaryTax': data.salaryTax ? parseFloat(data.salaryTax) : 0,
            'actPaid': data.actPaid ? parseFloat(data.actPaid) : 0,
            'paidYear': this.state.selectedDate.year(),
            'paidMonth': this.state.selectedDate.month() + 1,
        }
        this.props.onhandleCreateRecord(newRecord, () => {
            this.props.getPaymentRecords(this.state.companyId, this.state.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
        });
    }

    handleUpdate = (data) => {

        let newRecord = {
            'payId': data.payId,
            'item1': data.item1 ? parseFloat(data.item1) : 0,
            'item2': data.item2 ? parseFloat(data.item2) : 0,
            'item3': data.item3 ? parseFloat(data.item3) : 0,
            'item4': data.item4 ? parseFloat(data.item4) : 0,
            'item5': data.item5 ? parseFloat(data.item5) : 0,
            'item6': data.item6 ? parseFloat(data.item6) : 0,
            'item7': data.item7 ? parseFloat(data.item7) : 0,
            'overtimePay': data.overtimePay ? parseFloat(data.overtimePay) : 0,
            'salaryTotal': data.salaryTotal ? parseFloat(data.salaryTotal) : 0,
            'salaryTax': data.salaryTax ? parseFloat(data.salaryTax) : 0,
            'actPaid': data.actPaid ? parseFloat(data.actPaid) : 0
        }
        this.props.onhandleUpdateRecord(newRecord, () => {
            this.props.getPaymentRecords(this.state.companyId, this.state.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
        });
    }

    handleDelete = (data) => {
        let nd = {
            payId: data.payId,
            status: 'inactive'
        }
        this.props.onhandleUpdateRecord(nd, () => {
            this.props.getPaymentRecords(this.state.companyId, this.state.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
        });
    }

    handleSearch = () => {
        this.props.getPaymentRecords(this.state.companyId, this.state.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
    }

    handleClearSearch = () => {
        if (this.state.disableCompany) {
            this.setState({
                selectedDate: getMoment()(),
                userName: undefined,
                userId: undefined
            })
        } else {
            this.setState({
                companyId: this.props.userInfo.companyId,
                selectedDate: getMoment()(),
                userName: undefined,
                userId: undefined
            })
        }

    }

    handleSelectUser = (event) => {
        let param = {
            companyId: this.state.companyId,
            company: !this.state.disableCompany,
            department: true,
            name: true,
            callback: () => {
                return new Promise((resolve, reject) => {
                    if (this.props.selectedUsers.length !== 1) {
                        this.props.onhadleMassgae('只能选择一个返聘人员');
                        return reject('callbackFunc Error')
                    } else {
                        const user = this.props.selectedUsers[0];
                        if (user.userType !== 1) {
                            this.props.onhadleMassgae('只能选择返聘人员');
                            return reject('callbackFunc Error')
                        }
                        this.setState({
                            userName: user.lastName + user.firstName || '',
                            userId: user.userId
                        })
                        this.props.getPaymentRecords(this.state.companyId, user.userId, this.state.selectedDate.year(), this.state.selectedDate.month());
                        return resolve('callbackFunc success')
                    }
                })
            }
        }
        this.props.openUserSelectModal(param);
    }

    handleExportCsv = () => {
        let now = getMoment()();
        this.props.onhandleExportTemplate(this.state.companyId, now.year(), now.month());
    }

    render() {
        return (
            <div>
                <PaymentRecordComponent
                    selectedDate={this.state.selectedDate}
                    companyList={this.props.companies}
                    disableCompany={this.state.disableCompany}
                    records={this.props.records}
                    paymentTargets={this.props.paymentTargets}
                    departments={this.props.departments}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleUpload={this.handleUpload.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    exportCsv={this.handleExportCsv.bind(this)}
                    userName={this.state.userName}
                    companyId={this.state.companyId}
                    userProfile={this.props.userProfile}
                    onhadleMassgae={this.props.onhadleMassgae}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSelectUser={this.handleSelectUser.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.payment.records,
        paymentTargets: state.payment.paymentTargets,
        userProfile: state.auth.userProfile,
        userInfo: state.auth.currentUserInfo,
        selectedUsers: state.userSelect.selectedUsers,
        companies: state.constant.companies,
        departments: state.constant.departments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initPayment: (pid, companyId, userId, year, month) => {
            dispatch(initPayment(1,pid, companyId, userId, year, month + 1));
        },
        getPaymentRecords: (companyId, userId, year, month) => {
            dispatch(getPaymentRecords(1,companyId, userId, year, month + 1));
        },
        openUserSelectModal: (config) => {
            dispatch(createmodal('userSelect', config, 'medium'));
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleImportRecords: (record, callback) => {
            dispatch(importPaymentRecords(record, callback));
        },
        onModalCreate: (uploadParams) => {
            dispatch(createmodal(uploadParams.children, uploadParams.config, uploadParams.size));
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateRecord: (record, callback) => {
            dispatch(createPaymentRecord(record, callback));
        },
        onhandleUpdateRecord: (record, callback) => {
            dispatch(updatePaymentRecord(record, callback));
        },
        onhandleExportTemplate: (companyId, year, month) => {
            dispatch(exportTemplate(companyId, year, month + 1));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentRecordContainer);
