import React, { Component } from 'react';
import { connect } from 'react-redux';
import MAnnualPlanComponent from '@/components/ManufactureManagement/ManufacturePlan/MAnnualPlanComponent';
import PropTypes from 'prop-types';
import { getMoment, getYear } from '@/utils/datetime';
import { getRole } from "@/utils/userUtils";
import { getManufactureServices } from '@/redux/modules/manufacture/manufactureService';
import {
    getPlans
    , addPlanDtl
    , updatePlanDtl
    , updatePlanAnnualDtl
    , deletePlanDtl
    , getPlanDpts
    , updatePlanDpt
    , addPlan
    , updatePlan
    , deletePlan
    , getPlanDtls
    , startYearPlan
    , executeYearProcess
    , appendService2AnnualPlan
} from '@/redux/modules/manufacture/manufacturePlan';
import MMonthlyDptPlanListComponent from '@/components/ManufactureManagement/ManufacturePlan/MMonthlyDptPlanListComponent';
import MAnnualQuantityDailog from '@/components/ManufactureManagement/ManufacturePlan/MAnnualQuantityDailog';
import { getAmoebaIndexesByType } from '@/redux/modules/amoeba/amoebaIndex';
import { createActionMessage } from '@/redux/modules/message';
import { getVariableFeeRules, addVarFeeRules, updateVarFeeRules } from '@/redux/modules/amoeba/variableFeeRule';
import { AMOEBA_SUB_TYPE_INCOME, AMOEBA_SUB_TYPE_VARIABLEFEE } from '@/utils/manufactureUtils';
import { filterAmoebaCompany } from '@/utils/constant';
import { judgeMainCompany } from '@/utils/manufactureUtils';

import * as _ from 'lodash';
import AppendService2AnnualPlan from '@/components/ManufactureManagement/ManufacturePlan/AppendService2AnnualPlan';

class MAnnualPlanContainer extends Component {
    static propTypes = {
        plans: PropTypes.any,
        companyList: PropTypes.any,
        companyId: PropTypes.number,
        plan: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
            year: getYear(),
            companyId: this.props.companyId,
            subCompanyList: [],
            tabValue: 'ANNU',
            isMainCompany: false,
            openFlag: false,
            openAddFlag: false,
            openAnnual: false,
            openAppendFlag: false,
            serviceRow: {},
            openDptFlag: false,
            plan: {},
            planDtl: {},
            monthRowData: {},//月度计划明细(班组生产数量修改)
            month: null,
            appendServiceId: 0,
        }
    }

    getpid = (companyId) => {
        const company = _.find(this.props.companyList, { 'companyId': companyId });
        return company.pid === null ? null : company.companyId
    }


    componentDidMount() {
        const {
            handleSelect,
            companyList, getPlanDtls,
            companyId } = this.props;
        const { tabValue, year } = this.state;

        let isMainCompany = judgeMainCompany(companyList, companyId)
        let subCompanyList = companyList.filter((ele) => ele.companyId === companyId);
        let selectCompanyId = companyId;
        if (isMainCompany) {
            subCompanyList = companyList.filter((ele) => ele.pid === companyId);
            if (subCompanyList && subCompanyList[0]) {
                selectCompanyId = subCompanyList[0].companyId;
            }
        }
        handleSelect(selectCompanyId, tabValue, year).then(res => {
            getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
        });
        this.setState({
            companyId: selectCompanyId,
            subCompanyList: subCompanyList,
            isMainCompany: isMainCompany
        });
        this.props.handleGetServices(selectCompanyId, this.getpid(selectCompanyId))
    }

    handleDateChange = (date) => {
        const selectedDate = getMoment()(date)
        this.setState({
            selectedDate: selectedDate,
            year: selectedDate.year()
        })
        return this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, selectedDate.year()).then(res => {
            this.props.getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
        })
    }

    onhandleRowUpdate = (newData, oldData) => {
        const { handleRowUpdate, handleSelect } = this.props;
        const { companyId, tabValue, year } = this.state;

        delete newData['month'];
        return handleRowUpdate(oldData.planId, newData).then(
            (res) => handleSelect(companyId, tabValue, year)
        );
    }

    onhandleRowDelete = (oldData) => {
        const { handleRowDelete, handleSelect } = this.props;
        const { companyId, tabValue, year } = this.state;
        return handleRowDelete(oldData.planId).then(
            (res) => handleSelect(companyId, tabValue, year)
        );
    }

    onhandleRowDtlAdd = (planId, newData) => {
        const { handleRowDtlAdd, handleSelect } = this.props;
        const { companyId, tabValue, year } = this.state;
        if (!newData.outputUnit)
            newData['outputUnit'] = ''
        return handleRowDtlAdd(planId, newData).then(
            (res) => handleSelect(companyId, tabValue, year)
        );
    }

    handleDptPopUp = (rowData, dtlId) => {
        return this.props.handleDptPopInit(rowData['dtlId_' + dtlId]).then(res => {
            this.setState({
                openDptFlag: true,
                monthRowData: rowData,
                month: dtlId,
            });
        })
    }

    handlePopUpUpdate = (newValue, oldValue, rowData, columnDef) => {
        const { tabValue, year, selectCompanyId } = this.state;
        const { handleRowDtlUpdate, handleSelect, getPlanDtls } = this.props;
        rowData.output = newValue;
        return handleRowDtlUpdate(rowData.dtlId, rowData).then(
            () => handleSelect(selectCompanyId, tabValue, year).then(res => {
                getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
            })
        )
    }

    handlePopUpDptUpdate = (newValue, oldValue, rowData, columnDef) => {
        let monthRowData = { ...this.state.monthRowData };
        const monthOutput = monthRowData['output_' + this.state.month];
        let newMonthOutput = monthOutput - oldValue + newValue;
        //班组重新设定的生产数量大于等于月度数量
        // if (newMonthOutput >= monthOutput) {
        rowData.output = newValue;
        this.props.handleRowDptUpdate(rowData.dtlDptId, rowData).then(
            (res) => this.props.handleDptPopInit(monthRowData['dtlId_' + this.state.month])
        );
        // 重新设定月度总值
        monthRowData['output_' + this.state.month] = newMonthOutput
        this.setState({
            monthRowData: monthRowData
        })
        // } else {
        //     this.props.createActionMessage('warning', '班组计划数量总和小于月度计划');
        // }
        return Promise.resolve(true);
    }

    handleCloseCreate = () => {
        const { handleSelect } = this.props;
        const { companyId, tabValue, year } = this.state;
        this.setState({
            openFlag: false,
        });
        handleSelect(companyId, tabValue, year).then(res => {
            this.props.getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
        })
    }

    handleCloseDptCreate = () => {
        const { companyId, tabValue, year, month } = this.state;
        const monthRowData = this.state.monthRowData;
        let data = {}
        data.dtlId = monthRowData['dtlId_' + month]
        data.output = monthRowData['output_' + month]
        this.props.handleRowDtlUpdate(data.dtlId, data).then(
            () => {
                this.props.handleSelect(companyId, tabValue, year).then(res => {
                    this.props.getPlanDtls(res.payload[0] ? res.payload[0].planId : null)
                    this.setState({
                        openDptFlag: false,
                        monthRowData: {},
                    });
                });
            }
        );
    }

    handleChange = (event) => {
        const { services } = this.props
        let planDtl = { ...this.state.planDtl };
        const service = _.find(services, { 'serviceId': event.target.value });
        planDtl['serviceId'] = service.serviceId;
        planDtl['materialId'] = service.materialId;
        planDtl['dtlName'] = service.serviceName;
        planDtl['product'] = service.materialName;
        planDtl['outputUnit'] = service.unit;
        this.setState({ planDtl: planDtl });
    }

    handleTextChange = (event) => {
        let planDtl = { ...this.state.planDtl };
        planDtl[event.target.name] = Number(event.target.value);
        this.setState({ planDtl: planDtl });
    }

    handleCloseAdd = () => {
        this.setState({
            openAddFlag: false,
            planDtl: {},
        })
    }


    handleAddVarFeeRule = () => {
        const { getVarFeeRules, addVarFeeRules,
            incomeIndexes, variableFeeIndexes
        } = this.props;
        const { companyId, year } = this.state;
        let varFeeRule = {
            year: year,
            companyId: companyId,
            feeSubs: [],
            incomeSubs: [],
        }
        incomeIndexes.forEach(element => {
            varFeeRule.incomeSubs.push(element.subId);
        });

        variableFeeIndexes.forEach(element => {
            varFeeRule.feeSubs.push(element.subId);
        });
        addVarFeeRules(varFeeRule).then(() => {
            getVarFeeRules(companyId, year)
        });
    }

    handleRuleUpdate = (newData, oldData) => {
        const {
            variableFeeIndexes, updateVarFeeRules
        } = this.props;
        const { companyId, year } = this.state;
        let varFeeRule = {
            year: year,
            companyId: companyId,
            incomeSub: newData.incomeSub,
            feeSubs: [],
            ratios: [],
        }
        variableFeeIndexes.forEach(element => {
            let ratio = newData['income_' + element.subId];
            oldData['income_' + element.subId] = ratio;
            varFeeRule.ratios.push(ratio)
            varFeeRule.feeSubs.push(element.subId);
        });
        return updateVarFeeRules(varFeeRule, () => {
            variableFeeIndexes.forEach(element => {
                let ratio = newData['income_' + element.subId];
                oldData['income_' + element.subId] = ratio;
            });
        });
    }

    handleCompanyChange = (event) => {
        const { handleSelect, handleGetServices } = this.props;
        const { tabValue, year } = this.state;
        const companyId = event.target.value;
        handleSelect(companyId, tabValue, year).then(res => {
            this.props.getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
        });
        this.setState({
            companyId: companyId
        })

        handleGetServices(companyId, this.getpid(companyId))

    }

    hangleEditAnnualClose = () => {

        const { handleSelect } = this.props;
        const { companyId, tabValue, year } = this.state;

        this.setState({
            annualOpen: false,
            serviceRow: {},
        })

        handleSelect(companyId, tabValue, year).then(res => {
            this.props.getPlanDtls(res.payload[0] ? res.payload[0].planId : null);
        })
    }
    handleEditAnnualPopUp = (serviceRow) => {
        this.setState({
            annualOpen: true,
            serviceRow: serviceRow,
        })
    }
    handleEditAnnualQuantity = (newValue, oldValue, rowData, columnDef) => {

        rowData.output = newValue;
        this.props.handleRowAnnualDtlUpdate(rowData.dtlId, rowData).then(

        );

        return Promise.resolve(true);
    }

    handleAppendServcieOpen = () => {
        this.setState({
            openAppendFlag: true,
        })
    }

    handleAppendServcieClose = () => {
        this.setState({
            openAppendFlag: false,
            appendServiceId: 0,
        })
    }

    handleChangeAppendService = (event) => {
        this.setState({ appendServiceId: Number(event.target.value) })
    }

    handleAppendServcieSave = async () => {

        const { year, companyId, tabValue, appendServiceId } = this.state
        const { services, handleAppendService2AnnualPlan, handleSelect } = this.props

        if (year && appendServiceId) {
            const service = _.find(services, { serviceId: appendServiceId })
            let appendParam = {}
            appendParam["year"] = year
            appendParam["serviceId"] = appendServiceId
            appendParam["companyId"] = service.serviceCompany
            let res = await handleAppendService2AnnualPlan(appendParam)
            if (!res) {
                this.setState({
                    openAppendFlag: false,
                    appendServiceId: 0,
                })
                handleSelect(companyId, tabValue, year)
            }

        }
    }

    filterNewService = () => {
        return _.filter(this.props.services, s => {
            let dtl = _.find(this.props.planDtls, { 'serviceId': s.serviceId })
            return !dtl
        })
    }

    render() {

        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'manufacture_plan_annu').action === 'W';

        return (
            <div>
                <MAnnualPlanComponent
                    year={this.state.year}
                    selectedDate={this.state.selectedDate}
                    plans={this.props.plans}
                    subCompanyList={this.state.subCompanyList}
                    companyId={this.state.companyId}
                    roleFlag={roleFlag}
                    isMainCompany={this.state.isMainCompany}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleRowDtlAdd={this.onhandleRowDtlAdd.bind(this)}
                    onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                    onhandleDptPopUp={this.handleDptPopUp.bind(this)}
                    planDtls={this.props.planDtls}
                    onhandlePopUpUpdate={this.handlePopUpUpdate.bind(this)}
                    onhandleEditAnnualPopUp={this.handleEditAnnualPopUp.bind(this)}
                    onhandleAppendServciePopUp={this.handleAppendServcieOpen.bind(this)}
                />
                <MMonthlyDptPlanListComponent
                    openDptFlag={this.state.openDptFlag}
                    outputUnit={this.state.monthRowData.outputUnit}
                    planDpts={this.props.planDpts}
                    departments={this.props.departments}
                    onhandleClose={this.handleCloseDptCreate.bind(this)}
                    onhandlePopUpDptUpdate={this.handlePopUpDptUpdate.bind(this)}
                    plan={this.state.plan}
                />

                <MAnnualQuantityDailog
                    open={this.state.annualOpen}
                    serviceRow={this.state.serviceRow}
                    onhandleClose={this.hangleEditAnnualClose.bind(this)}
                    onhandleEditAnnualQuantity={this.handleEditAnnualQuantity.bind(this)}
                    plan={this.state.plan}
                />

                {this.state.openAppendFlag && <AppendService2AnnualPlan
                    open={this.state.openAppendFlag}
                    serviceId={this.state.appendServiceId}
                    services={this.filterNewService()}
                    year={this.state.year}
                    onhandleClose={this.handleAppendServcieClose.bind(this)}
                    onhandleAppend={this.handleAppendServcieSave.bind(this)}
                    onhandleChangeAppendService={this.handleChangeAppendService.bind(this)} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plans: state.manufacturePlan.plans,
        services: state.manufactureService.services,
        companyList: filterAmoebaCompany(state.company.companyList.list),
        departments: state.department.departments.list,
        companyId: state.auth.currentUserInfo.companyId,
        planDtls: state.manufacturePlan.planDtls,
        planDpts: state.manufacturePlan.planDpts,
        constant: state.constant,
        manufactureDicts: state.constant.manufactureDicts,
        incomeIndexes: state.amoebaIndex.incomeIndexes,
        variableFeeIndexes: state.amoebaIndex.variableFeeIndexes,
        varFeeRuleList: state.variableFeeRule.varFeeRules,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSelect: (companyId, planType, year) => {
            return dispatch(getPlans(companyId, planType, year, null));
        },
        getAmoebaIndex: () => {
            dispatch(getAmoebaIndexesByType(AMOEBA_SUB_TYPE_INCOME));
            dispatch(getAmoebaIndexesByType(AMOEBA_SUB_TYPE_VARIABLEFEE));
        },
        getVarFeeRules: (companyId, year) => {
            return dispatch(getVariableFeeRules(null, companyId, null, year));
        },
        addVarFeeRules: (varFeeRule) => {
            return dispatch(addVarFeeRules(varFeeRule));
        },
        updateVarFeeRules: (updateDto, callBack) => {
            return dispatch(updateVarFeeRules(updateDto, callBack));
        },
        handleRowAdd: (newData) => {
            return dispatch(addPlan(newData));
        },
        handleRowUpdate: (planId, newData) => {
            return dispatch(updatePlan(planId, newData));
        },
        handleRowDelete: (planId) => {
            return dispatch(deletePlan(planId));
        },
        handleRowDtlAdd: (planId, newData) => {
            return dispatch(addPlanDtl(planId, newData));
        },
        handleRowDtlUpdate: (dtlId, newData) => {
            return dispatch(updatePlanDtl(dtlId, newData));
        },
        handleRowAnnualDtlUpdate: (dtlId, newData) => {
            return dispatch(updatePlanAnnualDtl(dtlId, newData));
        },
        handleRowDptUpdate: (dtl_dpt_id, newData) => {
            return dispatch(updatePlanDpt(dtl_dpt_id, newData));
        },
        handleRowDtlDelete: (dtlId) => {
            return dispatch(deletePlanDtl(dtlId));
        },
        handlePopInit: (planId, serviceId) => {
            return dispatch(getPlanDtls(planId, serviceId));
        },
        getPlanDtls: (planId) => {
            return dispatch(getPlanDtls(planId, null));
        },
        handleDptPopInit: (dtlId) => {
            return dispatch(getPlanDpts(dtlId));
        },

        startPlanProcess: (actionParams, callback) => {
            return dispatch(startYearPlan(actionParams, callback));
        },
        executeYearProcess: (actionParams, callback) => {
            dispatch(executeYearProcess(actionParams, callback))
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        handleAppendService2AnnualPlan: (appendParam) => {
            dispatch(appendService2AnnualPlan(appendParam));
        },
        handleGetServices: (companyId, pid) => {
            return dispatch(getManufactureServices(null, null, companyId, null, null, null));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MAnnualPlanContainer);
