import React, { Component } from 'react';
import { connect } from 'react-redux';
import TrackIncomeComponent from '@/components/AmoebaManagement/Report/TrackIncome';
import TrackMaterialCostComponent from '@/components/AmoebaManagement/Report/TrackMaterialCost';
import { Grid } from '@material-ui/core';
import { closeTrack } from '@/redux/modules/amoeba/reportTrack';
import Drawer from '@material-ui/core/Drawer';

import withStyles from './styles';
import PropTypes from 'prop-types';

class ReportTrackContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        classes: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            incomeViewType: 'DETAIL',  //DETAIL：明细  SERVICE：按业务
        }
    }

    handleChangeIncomViewType = () => {
        if (this.state.incomeViewType === 'DETAIL') {
            this.setState({ incomeViewType: 'SERVICE' })
        } else {
            this.setState({ incomeViewType: 'DETAIL' })
        }
    }

    handleCloseTrack = () => {
        this.props.onhandleCloseTrack()
    }

    getTrackComponent = () => {
        const { trackType } = this.props;

        switch (trackType) {
            case 'INCOME':
                return <TrackIncomeComponent
                    companies={this.props.companies}
                    departments={this.props.departments}
                    incomes={this.props.incomes}
                    viewType={this.state.incomeViewType}
                    subs={this.props.subs}
                    onhandleChangeIncomViewType={this.handleChangeIncomViewType.bind(this)}
                />
            case 'MATERIALCOST':
                return <TrackMaterialCostComponent
                    companies={this.props.companies}
                    departments={this.props.departments}
                    materialCosts={this.props.materialCosts}
                    subs={this.props.subs}
                />
            default:
                return <Grid></Grid>
        }
    }

    render() {
        return (
            <Drawer anchor={'bottom'} open={this.props.open} onClose={this.handleCloseTrack.bind(this)} classes={{
                // paperAnchorBottom: this.props.classes.drawer
            }}>
                <Grid>{this.getTrackComponent()}</Grid>

            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.reportTrack.open,
        trackType: state.reportTrack.trackType,
        incomes: state.reportTrack.incomes ? state.reportTrack.incomes.list : [],
        materialCosts: state.reportTrack.materialCosts ? state.reportTrack.materialCosts.list : [],
        companies: state.company.companyList.list,
        departments: state.department.departments.list,
        subs: state.amoebaIndex.subs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCloseTrack: () => {
            return dispatch(closeTrack());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(ReportTrackContainer));
