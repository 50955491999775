import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationWorkAcceptancePanel from '@/components/OperationManagement/Appointment/OperationWorkAcceptancePanel';
import { closeAcceptancePanel, changeAcceptance } from '@/redux/modules/operationWorkAcceptance';
import { STATUS_VALUE } from '@/utils/constant';

import _ from 'lodash';

class OperationWorkAcceptancePanelContainer extends Component {
    static propTypes = {
        acceptance: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            acceptanceError: {
                terminateType: '',
                opentext: ''
            }
        };
    }

    handleConfirm() {
        const { confirmCallback, onhandleClose } = this.props;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(this.props.acceptance)
            if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, acceptance } = this.props
        let newEntity = {
            ...acceptance
        }
        const field = event.target.name;
        newEntity[field] = event.target.value;
        onhandleChange(newEntity);

        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleCheck = (event, field) => {

        const {
            acceptance
        } = this.props;

        let err = {};

        if (!field || field === 'terminateType') {
            if (acceptance.status === STATUS_VALUE.STATUS_TERMINATED) {
                if (acceptance.terminateType === '') {
                    err['terminateType'] = '请选择终止类型';
                } else {
                    err['terminateType'] = '';
                }
            }
        }

        if (!field || field === 'opentext') {
            if (_.trim(acceptance.opentext) === '') {
                err['opentext'] = '请填写理由';
            } else {
                err['opentext'] = '';
            }
        }

        let newError = {
            ...this.state.acceptanceError,
            ...err
        }
        this.setState({
            acceptanceError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <OperationWorkAcceptancePanel
                open={this.props.open}
                acceptance={this.props.acceptance}
                acceptanceError={this.state.acceptanceError}
                terminateTypeList={this.props.terminateTypeList}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}

                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.operationWorkAcceptance.open,
        terminateTypeList: state.operation.terminateTypeList,
        acceptance: state.operationWorkAcceptance.acceptance,
        confirmCallback: state.operationWorkAcceptance.confirmCallback,
        closeCallback: state.operationWorkAcceptance.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (acceptance) => {
            dispatch(changeAcceptance(acceptance));
        },
        onhandleClose: () => {
            dispatch(closeAcceptancePanel());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationWorkAcceptancePanelContainer);