import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Typography,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    MenuItem
} from '@material-ui/core';
import withStyles from './styles';
import { getMoment } from '../../../utils/datetime'
import _ from 'lodash'


const PlanPanel = ({
    classes,
    currentPlan,
    open,
    //leaders,
    departmentList,
    routeList,
    onhandleChange,
    onhandleClose,
    onhandlePanelSave,
    userProfile,
    onhandleCheck,
    planError,
}) => {


    const renderTime = (startTime) => {
        return startTime ? getMoment()(startTime).format("YYYY-MM-DDTHH:mm") : ''
    }

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'qc_plan' })
        return permission && permission.action === 'W'
    }

    return (
        <div alignitems="center" >
            {
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open}
                    onClose={onhandleClose}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                >
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>临时巡检计划</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            {/* <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输选择管理人员"
                                    label="管理人员"
                                    select
                                    disabled={!checkPermission()}
                                    value={currentPlan.inspector ? currentPlan.inspector : ''}
                                    name='inspector'
                                    onChange={event => onhandleChange(event, 'inspector')}>
                                    {leaders.map((item) => {
                                        return <MenuItem key={item.userId} value={item.userId}>
                                            {item.firstName}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid> */}
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.dptId !== ''}
                                    helperText={planError.dptId || ''}
                                    onBlur={event => onhandleCheck(event, 'dptId')}
                                    fullWidth
                                    variant="outlined"
                                    label="执行班组"
                                    name='dptId'
                                    select
                                    disabled={!checkPermission()}
                                    value={currentPlan.onsitePlanDpt[0].dptId}
                                    onChange={event => onhandleChange(event, 'dptId')}>
                                    {departmentList.map((item) => {
                                        return <MenuItem key={item.dptId} value={item.dptId}>
                                            {item.dptName}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.routeId !== ''}
                                    helperText={planError.routeId || ''}
                                    onBlur={event => onhandleCheck(event, 'routeId')}
                                    fullWidth
                                    select
                                    disabled={!checkPermission()}
                                    variant="outlined"
                                    placeholder="请输选择巡检路线"
                                    label="巡检路线"
                                    name='routeId'
                                    value={currentPlan.routeId || ''}
                                    onChange={event => onhandleChange(event, 'routeId')}>
                                    {routeList.map((item) => {
                                        return <MenuItem key={item.routeId} value={item.routeId}>
                                            {item.routeName}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    error={planError.startTime !== ''}
                                    helperText={planError.startTime || ''}
                                    onBlur={event => onhandleCheck(event, 'startTime')}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输选择开始时间"
                                    label="开始时间"
                                    type="datetime-local"
                                    disabled={!checkPermission()}
                                    value={currentPlan.startTime ? renderTime(currentPlan.startTime) : ''}
                                    name='startTime'
                                    onChange={event => onhandleChange(event, 'startTime')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid container item className={classes.warnContainer}>
                                <Typography>在</Typography>
                                <TextField
                                    error={planError.expiredAt !== ''}
                                    helperText={planError.expiredAt || ''}
                                    value={currentPlan.expiredAt || ''}
                                    type="number"
                                    size="small"
                                    className={classes.intervalInput}
                                    onChange={(event) => onhandleChange(event, 'expiredAt')}
                                />
                                <Typography>分钟内完成巡检</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" hidden={!checkPermission()} color="primary" onClick={event => onhandlePanelSave(currentPlan)}>
                            保存</Button>
                        <Button variant="text" color="primary" onClick={event => onhandleClose()}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

PlanPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    currentPlan: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    //leaders: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    routeList: PropTypes.array.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleClose: PropTypes.func.isRequired,
    onhandlePanelSave: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    planError: PropTypes.object.isRequired,
};

export default withStyles(PlanPanel);