import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    button: {
        marginRight: 10
    },
    processline: {
        flexGrow: 1
    },
    pic11: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    pic21: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    pic41: {
        borderColor: colors.blue[500],
        fontSize: '12px',
    },
    pic51: {
        borderColor: colors.teal[400],
        fontSize: '12px',
    },
    pic31: {
        borderColor: colors.red[400],
        fontSize: '12px',
    },
    card: {
        width: '100%',
        padding: 20,
        margin: 5
    },
    inactiveBtn: {
        marginRight: 10,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.error.dark
        }
    },
    terminateBtn: {
        marginRight: 10,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.warning.dark
        }
    },
    pauseBtn: {
        marginRight: 10,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.warning.dark
        }
    },
    finishBtn: {
        marginRight: 10,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.success.main
        }
    },
    pendingPermit: {
    },
    rejectedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.error.main
    },
    submittedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.info.main
    },
    finalizedPermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
    donePermit: {
        color: theme.palette.white,
        backgroundColor: theme.palette.success.main
    },
    pendingUser: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    rejectedUser: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    approvedUser: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    detailTitle: {
        paddingTop: 24,
        paddingLeft: 16,
        alignItems: 'center'
    },
    action: {
        padding: 16,
    },
    title: {
        padding: theme.spacing(2)
    },
    flexgrow: {
        flexGrow: 1
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    gridContainer: {
        marginTop: 10
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '6px',
    },
    permitBar: {
        width: "100%"
    },
    permitHistory: {
        paddingLeft: '4em'
    },
    permitHistoryItem: {
        margin: 0,
        padding: 0,
        paddingLeft: '1em'
    },
    confirmTrue: {
        color: theme.palette.success.main
    },
    confirmFalse: {
        color: theme.palette.error.main
    },

});

export default withStyles(styles);