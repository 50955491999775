import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getAccessoryCategory } from './accessory';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { cleanmodal } from './modal';
import * as _ from 'lodash';

const initialState = {
    accShowList: [],
    selectedAcctype: [],
}

export const initAccessoryTypeSelectList = createAction('@@xcloud/acctypeSelect/initAccessoryTypeSelectList',
    () => (dispatch, getState) => {
        dispatch(createloading());
        Promise.all([
            dispatch(getAccessoryCategory()),
        ]).then(() => {
            let accCategory = _.clone(getState().accessory.catList);
            accCategory.forEach(acc => acc.action = false);
            dispatch(setAccShowList(accCategory));
            dispatch(cleanloading());
        }).catch(err => {
            dispatch(cleanloading());
            let msg = '初始化部件类别失败';
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return [];
        });
    }
);

export const updateSelectedAcctype = createAction('@@xcloud/acctypeSelect/updateSelectedAcctype',
    () => (dispatch, getState, httpClient) => {
        dispatch(createloading());
        let accCategory = _.clone(getState().acctypeSelect.accShowList);
        let selectedAccCategory = _.filter(accCategory, { action: true });
        let selectedAcctype = []
        selectedAccCategory.forEach(acc => {
            if (!acc.pcode) {
                selectedAcctype.push(acc)
            } else {
                if (!_.find(selectedAccCategory, { code: acc.pcode })) {
                    selectedAcctype.push(acc)
                }
            }
        })
        dispatch(setSelectedAcctype(selectedAcctype));
    }
);

export const selectAccCategory = createAction(
    '@@xcloud/acctypeSelect/selectAccCategory',
    (row) => (dispatch, getState) => {
        Promise.all([
            dispatch(commonSelectAccCategory(row)),
        ]).then((res) => {
            dispatch(setAccShowList(res[0].payload));
            dispatch(updateSelectedAcctype());
        })
    }
);

export const commonSelectAccCategory = createAction(
    '@@xcloud/acctypeSelect/commonSelectAccCategory',
    (row) => (dispatch, getState) => {

        function dploop(val, action) {
            let targetChildArray = _.filter(accCategory, { pcode: val.code });
            val.action = action;
            if (targetChildArray.length > 0) {
                targetChildArray.forEach(child => {
                    dploop(child, action);
                })
            }
        }

        function uploop(val) {
            let targetFatherRow = _.find(accCategory, { code: val.pcode });
            if (targetFatherRow) {
                if (!val.action) {
                    targetFatherRow.action = false;
                } else {
                    let targetFatherChildRow = _.filter(accCategory, { pcode: val.pcode, action: false });
                    if (targetFatherChildRow.length === 0) {
                        targetFatherRow.action = true;
                    }
                }
                uploop(targetFatherRow);
            }
        }

        let accCategory = _.clone(getState().acctypeSelect.accShowList);
        let targetRow = _.find(accCategory, { code: row.code })
        dploop(targetRow, targetRow.action ? false : true);
        uploop(targetRow);

        return accCategory;
    }
);

export const setAccShowList = createAction('@@xcloud/acctypeSelect/setAccShowList');

export const exitSelected = createAction('@@xcloud/acctypeSelect/exitSelected',
    () => (dispatch, getState, httpClient) => {
        dispatch(setSelectedAcctype([]))
        dispatch(cleanmodal())
    }
);

export const setSelectedAcctype = createAction('@@xcloud/acctypeSelect/setSelectedAcctype');

export const acctypeSelectReducer = handleActions(
    {
        [setSelectedAcctype]: (state, { payload: value }) => {
            return {
                ...state,
                selectedAcctype: value
            };
        },
        [setAccShowList]: (state, { payload: value }) => {
            return {
                ...state,
                accShowList: value
            };
        },
    },
    initialState
);

export default acctypeSelectReducer;