import { withStyles } from '@material-ui/styles';
const APP_BAR_HEIGHT = 170;
const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing() * 0,
    color: theme.palette.text.secondary,
    margin: theme.spacing() * 0.2,
    height: `calc(100vh - ${APP_BAR_HEIGHT + 8}px)`,
    position: 'relative',
    overflowY: 'auto',
  },
  rightpaper: {
    padding: theme.spacing() * 0,
    color: theme.palette.text.secondary,
    margin: theme.spacing() * 0.2,
    height: `calc(100vh - ${APP_BAR_HEIGHT + 8}px)`,
    position: 'relative',
    overflowY: 'auto',
    overflowX:'hidden'
  }
});

export default withStyles(styles);
