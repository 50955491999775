import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment as moment } from '@/utils/datetime';
import zhCN from 'date-fns/locale/zh-CN';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { formatDate_hms } from '@/utils/datetime';


import * as _ from 'lodash';

import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';

const ActivitiesList = props => {

  const {
    classes,
    onhandleSearch,
    onhandleClearSearch,
    onhandleChange,
    onhandleDateChange,
    onSelectAct,
    search,
    onhandleCreateActivity, activityList,onhandleOnCreateActivityTask } = props;

  //console.log('activityList', activityList)
  const minStartDate = moment()().startOf('year');
  const minToDate = search?.fromDate ? moment()(search.fromDate).endOf('day') : minStartDate.endOf('day');
  

  return (
    <div style={{ height: "calc(100% - 152px)" }}  >
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item container xs={12}>

          <Grid item xs={2}>
            <Typography variant="h3" className={classes.plantname}>
              {'客户活动历史'}
            </Typography>
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={3} align="right">
            <Button variant="contained" color="primary" size="small" onClick={onhandleClearSearch}>
              {search?.showFilters ? '清除筛选条件' : '活动筛选'}
            </Button>
             
             &nbsp;
                        <Button variant="contained" color="primary" size="small" onClick={onhandleCreateActivity}>
              新建活动
                        </Button>
          </Grid>



        </Grid>
      </Grid>

      {
        search.showFilters ?
          <Grid >

            {/* <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Typography className={classes.label} >
                  名称
                                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  style={{ width: '90%' }}
                  value={search.actName || ''}
                  variant="standard"
                  onChange={event => onhandleChange(event, 'actName')}
                />
              </Grid>

              <Grid item xs={1}>
                <Typography className={classes.label} >
                  负责人
                                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  style={{ width: '90%' }}
                  value={search.actHead || ''}
                  variant="standard"
                  onChange={event => onhandleChange(event, 'actHead')}
                />
              </Grid>
            </Grid> */}

            <Grid container spacing={0} className={classes.gridContainer}>

            <Grid item xs={3}>
                <TextField
                  style={{ width: '90%' }}
                  value={search.search || ''}
                  variant="standard"
                  placeholder="活动名称/负责人"
                  onChange={event => onhandleChange(event, 'search')}
                />
              </Grid>

              <Grid item xs={1}>
                <Typography gutterBottom className={classes.label}>
                  {'活动时间'}
                </Typography>
              </Grid>
              <Grid item xs={2} container wrap={'nowrap'}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={zhCN}>
                  <KeyboardDatePicker
                    className={classes.dateField}
                    InputProps={{ readOnly: true }}
                    disableToolbar
                    autoOk
                    variant='inline'
                    format='yyyy/MM/dd'
                    margin="none"
                    value={search.fromDate || null}
                    onChange={date => onhandleDateChange(date, 'fromDate')}
                    KeyboardButtonProps={{
                      'aria-label': '修改时间',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={1}>
                <Typography gutterBottom className={classes.label} align={'center'}>
                  {'~'}
                </Typography>
              </Grid>
              <Grid item xs={2} container wrap={'nowrap'}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={zhCN}>
                  <KeyboardDatePicker
                    className={classes.dateField}
                    InputProps={{ readOnly: true }}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="none"
                    value={search.toDate || null}
                    minDate={minToDate}
                    minDateMessage={<Typography>不能早于开始时间</Typography>}
                    onChange={date => onhandleDateChange(date, 'toDate')}
                    KeyboardButtonProps={{
                      'aria-label': '修改时间',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} align="right">
                <Button variant="contained" color="primary" size="small"
                  onClick={onhandleSearch}
                >
                  搜索
                 </Button>
              </Grid>
            </Grid>
          </Grid> : null
      }


      {activityList.map((value) => (
        <Card key={value.actId} onClick={(event) => onSelectAct(value.actId)} style={{backgroundColor:value.selected ? "#ebecec" : ""}}>
          <CardContent>
          <Grid >
            <Grid container spacing={0} className={classes.gridContainer}>
              
              <Grid item xs={2}>
                <Typography className={classes.label} >
                {value.actName}
                </Typography>
              </Grid>

              <Grid item xs={2} align="left">
                  <Button variant="contained" color="primary" size="small" onClick={onhandleOnCreateActivityTask}>
                      新建任务
                  </Button>
              </Grid>

              <Grid item xs={6} />
              <Grid item xs={1}>
                <Typography className={classes.label} >
                    状态:
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.label} >
                    {value.statusDesc}
                </Typography>
              </Grid>
            </Grid>
            </Grid>

            {_.sortBy(value.tasks, 'taskId').map((task) => (
            <Timeline key={task.taskId} align="left">
              <TimelineItem>
              <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineOppositeContent
                  className={classes.oppositeContent}
                  color="textSecondary"
                >
                  {formatDate_hms(task.taskTime)}
                </TimelineOppositeContent>
               
                <TimelineContent>{task.taskName}</TimelineContent>
              </TimelineItem>
            </Timeline>
             ))}
          </CardContent>
        </Card>
      ))}

    </div>
  );
};
ActivitiesList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(ActivitiesList);
