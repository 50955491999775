import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    Grid,
    Select,
    MenuItem,
    Typography,
    Button,
    List,
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as _ from 'lodash';
import { SAFETY_ACTIVITY_ICONS, getHolidayLabel } from '../../../../utils/constant';
import { formatWeekday, formatMonth } from '../../../../utils/datetime'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ProcessLine from '../../../ProcessLine'
import UserCard from '@/containers/UserCard';

let allViews = ['month'] //Object.keys(Views).map(k => Views[k]
moment.locale('zh-cn')
const localizer = momentLocalizer(moment)

const TeamActivityCalendar = ({
    classes,
    createPermission,
    activityList,
    constant,
    companies,
    selectedCompanyId,
    hasProcess,
    leaders,
    currentUserInfo,
    currentProcessStatus,
    onhandleChangeCompany,
    onhandleOpenActivity,
    onhandleOpenCreate,
    onhandleProcess,
    onhandleChangeDate,
    onhandleOpenActivityTable
}) => {

    let events = []
    activityList.forEach(activity => {
        if (activity.companyId && (
            (activity.companyId === selectedCompanyId)
            || (activity.companyId === currentUserInfo.companyId))
        ) {
            let event = {
                ...activity,
                id: activity.actId,
                title: activity.activity,
                start: activity.activityTime,
                end: activity.activityTime,
            };

            if (activity.dpts && activity.dpts.length > 0)
                event.dptName = activity.dpts[0].dptName
            events.push(event);
        }
    })

    const formats = {
        weekdayFormat: (date) => formatWeekday(date),
        monthHeaderFormat: (date) => formatMonth(date)
    }

    const handleSelect = event => {
        if (event) onhandleOpenActivity(event)
    }

    const CustomToolbar = (props) => {
        const goToLastMonth = () => {
            let m = moment(props.date)
            m.add(-1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('prev', props.date)
            onhandleChangeDate(props.date)
        }

        const goToNextMonth = () => {
            let m = moment(props.date)
            m.add(1, 'months')
            props.date.setYear(m.year());
            props.date.setMonth(m.month());
            props.onNavigate('next', props.date)
            onhandleChangeDate(props.date)
        }

        return (<div>
            <Grid container className={classes.toolbar}>
                <Grid item>
                    <Button className={classes.toolbarButton} onClick={goToLastMonth}>
                        <ArrowBackIos />
                    </Button>
                </Grid>
                <Grid item className={classes.toolbarLabel}>
                    <Typography className={classes.toolbarLabelText} variant='h5'>{props.label}</Typography>
                </Grid>
                <Grid item className={classes.toolbarButton} onClick={goToNextMonth}>
                    <Button><ArrowForwardIos /></Button>
                </Grid>

                <Grid item className={classes.flexgrow}>
                    {hasProcess && renderProcess()}
                </Grid>
                <Grid item >{renderSelectCompany()}</Grid>
                {createPermission && !hasProcess &&
                    <Grid item className={classes.toobarCreateButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onhandleOpenCreate}>拟定月度计划</Button>
                    </Grid>
                }
            </Grid>
        </div>
        )
    }

    const CustomMonthHeader = (props) => {
        return (
            <div style={{
                backgroundColor: '#FFF',
                margin: '-1px -10px',
                lineHeight: '45px',
                //color: '#FFF'
            }}
                onClick={() => console.log('clicked here')} > {props.label} </div>
        )
    };
    const Event = (event) => {
        const evt = event.event
        let icon = SAFETY_ACTIVITY_ICONS[evt.activityType]

        const showLeaders = _.intersectionBy(evt.users, leaders, 'userId')
        return (
            <div className={classes.calendarEvent}>
                <Grid container className={classes.calendarEventContainer}>
                    <Grid item className={classes.calendarEventContainerIcon}><Typography>{icon} {evt.dptName}</Typography></Grid>
                    {showLeaders && showLeaders.map(u => (
                        <Grid item key={u.userId}>
                            <UserCard userId={u.userId} child={<Typography >{' ' + u.lastName + u.firstName}</Typography>} />
                        </Grid>))}
                </Grid>
            </div>
        )
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: 'rgba(0,0,0,0)',
        };
        return {
            style: style
        };
    }

    const customDayPropGetter = value => {

    }

    const dateHeader = ({ date, label }) => {
        const todayHolidays = constant.holidays.filter((item) => {
            return moment(date).isBetween(moment(item.startTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'), moment(item.endTime.substr(0, 19), 'YYYY-MM-DDTHH:mm:ss'))
        })
        let headerLabel = ''
        if (todayHolidays.length > 0) {
            for (const h of todayHolidays) {
                headerLabel = headerLabel + ' ' + getHolidayLabel(h)
            }
        }
        return (
            <div>{headerLabel + ' ' + moment(date).format('D')}</div>
        );
    }

    const renderSelectCompany = () => {
        if (companies && companies.length > 1) {
            return <Select
                labelId="company-select"
                id="company-select"
                value={selectedCompanyId}
                onChange={onhandleChangeCompany}
            >
                {companies.map((c) => (
                    <MenuItem key={c.companyId} value={c.companyId}> {c.companyName}</MenuItem>
                ))}
            </Select>
        }
    }

    const renderActions = () => {

        if (!currentProcessStatus || !currentProcessStatus.actions || currentProcessStatus.actions.length === 0)
            return
        let actions = []
        if (selectedCompanyId === currentUserInfo.companyId) {
            actions = _.filter(currentProcessStatus.actions, (action) => { return !action.distributed })
        } else {
            actions = _.filter(currentProcessStatus.actions, (action) => { return action.distributed })
            if (!_.find(companies, c => { return c.companyId === currentUserInfo.companyId })) {
                actions = currentProcessStatus.actions
            }
        }
        return actions.map((action, idx) => (
            <Button
                key={action.actionId}
                disabled={action.disabled || false}
                variant={action.statusCode === 'Rejected' ? "outlined" : "contained"}
                color="primary"
                className={classes.button}
                onClick={onhandleProcess.bind(this, action)}
            >
                {action.actionName}
            </Button>
        ))
    }
    const renderProcess = () => {
        return (
            <Grid container >
                <Grid item className={classes.timeline}>
                    <ProcessLine
                        statusCodes={currentProcessStatus.statusCodes}
                        statusCode={currentProcessStatus.statusCode}
                        constant={constant} />
                </Grid>
                <Grid item>
                    <List className={classes.buttonList}>
                        {renderActions()}
                    </List>
                </Grid>
            </Grid>
        )
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Calendar
                selectable
                formats={formats}
                localizer={localizer}
                events={events}
                views={allViews}
                onSelectEvent={handleSelect}
                onSelectSlot={onhandleOpenActivityTable}
                onShowMore={onhandleOpenActivityTable}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={eventStyleGetter}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 650 }}
                //toolbar={false}
                components={{
                    toolbar: props => (<CustomToolbar  {...props} />),
                    month: {
                        header: CustomMonthHeader,
                        dateHeader: ({ date, label }) => dateHeader({ date, label })
                    },
                    event: Event
                }}
                messages={{
                    showMore: (count) => ('+ ' + count + ' 查看更多'),
                }}
            />
        </div>
    );
};
TeamActivityCalendar.propTypes = {
    classes: PropTypes.object.isRequired
};

TeamActivityCalendar.defaultProps = {
    activityList: [],
    companies: [],
}


export default withRouter(withStyles(TeamActivityCalendar));