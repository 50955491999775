import React, { Component } from 'react';
import { connect } from 'react-redux';
import Topbar from "../components/Topbar"
import { signout } from '../redux/modules/auth';
import { initClient, clearClient, confirmMessage, clearMessage, setOpenNotification } from '../redux/modules/websocket';
import { getCompanyTree } from '../redux/modules/trees';
import { createmodal } from '../redux/modules/modal'

class TopbarContainer extends Component {

    constructor(props) {
        super(props);
        this.openSidebar = props.openSidebar
        this.handleDrawer = props.handleDrawer
    }
    componentDidMount() {
        this.props.initClient();
        // this.props.getCompanyTree()
    }

    handleSignout = () => {
        this.props.setOpenNotification(null);
        this.props.clearClient();
        this.props.onhandeSignout()
    }

    handleOpenMessage = (id) => {
        this.props.confirmMessage(id)
    }

    handleClearMessage = (id) => {
        this.props.clearMessage(id)
    }


    handleOpenQrCode = () => {
        this.props.setOpenNotification(null);
        this.props.onhandleDialog();
    }
    render() {
        return (
            <Topbar
                openSidebar={this.props.openSidebar}
                handleDrawer={this.props.handleDrawer}
                currentUser={this.props.currentUser}
                userProfile={this.props.userProfile}
                onhandeSignout={this.handleSignout}
                messages={this.props.messages}
                openNotification={this.props.openNotification}
                onhandeOpenMessage={this.handleOpenMessage}
                onhandleClearMessage={this.handleClearMessage}
                onhandeOpenQrcode={this.handleOpenQrCode}
                onhandeSetOpenNotification={this.props.setOpenNotification.bind(this)}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        messages: state.websocket.messages,
        openNotification: state.websocket.openNotification,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandeSignout: () => {
            dispatch(signout());
        },
        getCompanyTree: () => {
            dispatch(getCompanyTree());
        },
        initClient: () => {
            dispatch(initClient());
        },
        clearClient: () => {
            dispatch(clearClient());
        },
        confirmMessage: (id) => {
            dispatch(confirmMessage(id));
        },
        clearMessage: (id) => {
            dispatch(clearMessage(id));
        },
        onhandleDialog: () => {
            dispatch(createmodal('qrCode', null, 'small'))
        },
        setOpenNotification: (value) => {
            dispatch(setOpenNotification(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)((TopbarContainer));