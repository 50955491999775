import React from 'react';
import {
    Grid, TextField, DialogActions, Button, Typography, Dialog, DialogTitle, DialogContent, Tabs,
    Tab,
    //InputAdornment,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import * as _ from 'lodash'

const UpdateInstockComponent = props => {
    const { classes,
        isShow,
        tabValue,
        instockInfo,
        companies,
        departments,
        componentTitle,
        onhandleSave,
        onhandleClose,
    } = props;


    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,

    }
    const tableStyle = {
        ...style
    }

    const renderDepartment = (dptId) => {

        if (departments) {
            const department = _.find(departments, { 'dptId': dptId })
            if (department) return department.dptName
        }
        return dptId
    }

    const renderCompany = (companyId) => {
        if (companies) {
            const company = _.find(companies, { 'companyId': companyId })
            if (company) return company.companyName
        }
        return companyId
    }


    const instockDtlsColumns = () => {

        const columns = [
            {
                title: '物料',
                field: 'materialName',
                render: row => <Typography>{row.material.materialName}</Typography>
            },
            {
                title: '入库分厂',
                field: 'instockCompany',
                render: row => <Typography>{renderCompany(row.instockCompany)}</Typography>
            },
            {
                title: '入库班组',
                field: 'instockDpt',
                render: row => <Typography>{renderDepartment(row.instockDpt)}</Typography>
            },
            {
                title: '物料',
                field: 'materialName',
                render: row => <Typography>{row.material.materialName}</Typography>

            },
            {
                title: '规格',
                field: 'specification',
                render: row => <Typography>{row.material.specification}</Typography>

            },
            {
                title: '应收数量',
                field: 'mustQty',
                align: 'right',
                render: row => <Typography>{row.mustQty}</Typography>
            },
            {
                title: '实收数量',
                field: 'realQty',
                align: 'right',
                render: row => <Typography>{row.realQty}</Typography>
            },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit ? row.unit.unitName : ""}</Typography>
            }
        ]
        return columns
    }


    // const textFieldDefault = {
    //     style: {
    //         width: '180px',
    //     },
    //     startAdornment: (
    //         <InputAdornment position="start">
    //             <p></p>
    //         </InputAdornment>)
    // }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog fullScreen open={true} onClose={onhandleClose} className={classes.dialog}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{componentTitle}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        placeholder=""
                                        label='单据号'
                                        name='billNo'
                                        value={instockInfo.billNo}>
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            ampm={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            inputVariant="outlined"
                                            format="yyyy/MM/dd HH:mm"
                                            id="date-selector"
                                            margin="normal"
                                            label='入库时间'
                                            value={instockInfo.instockDate}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            name='instockDate'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label={"入库明细"} className={classes.tab} />
                            </Tabs>
                        </Grid>
                        <MaterialTable
                            columns={instockDtlsColumns()}
                            data={instockInfo.instockDtls}
                            options={tableOptions}
                            localization={tableLocalization}
                            style={tableStyle}
                            title={<Typography variant='h4'>{"入库明细列表"}</Typography>}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !isShow && <Button onClick={onhandleSave} color="primary"> 保存
                        </Button>
                    }
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

UpdateInstockComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

UpdateInstockComponent.defaultProps = {
    tabValue: 0,
    componentTitle: "",
    instockInfo: {},
    isShow: false,
    orders: []
};

export default withStyles(UpdateInstockComponent);