import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccessorySelectPage from '../components/AccessorySelectPage';
import {
  initAccessorySelectList,
  setSearch,
  resetSearch,
  getAccessoryList,
  exitSelected,
  setSelectedAccessory,
} from '../redux/modules/accessorySelect';
import { getTreePartById } from '../utils/constant';
import { getCompanyId } from "../utils/userUtils";
import { cleanmodal } from '../redux/modules/modal';

class AccessorySelectPageContainer extends Component {
  static propTypes = {
    accessoryList: PropTypes.array,
    search: PropTypes.object,
    catTree: PropTypes.array,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    userProfile: PropTypes.object,
    companyTree: PropTypes.array,
    companyList: PropTypes.array,
    currentUserInfo: PropTypes.object,
  }

  componentWillMount() {
    this.props.onhandleInitAccessorySelectList();
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        if (value && this.props.search.companyId !== value.target.value) {
          newSearch = {
            ...this.props.search,
            companyId: value.target.value,
          };
          this.props.onhandleUpdateEditSearch(newSearch);
        } else {
          newSearch = {
            ...this.props.search,
            companyId: null,
          };
          this.props.onhandleUpdateEditSearch(newSearch);
        }
        break;
      case 'cat':
        newSearch = {
          ...this.props.search,
          cat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'isCommon':
        newSearch = {
          ...this.props.search,
          isCommon: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'search':
        newSearch = {
          ...this.props.search,
          search: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'deviceSearch':
        newSearch = {
          ...this.props.search,
          deviceSearch: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleConfirm = () => {
    if (this.props.parameters.callback) {
      this.props.parameters.callback()
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };

  trimCompanyTree() {
    let companyId = null
    if (this.props.parameters.companyId) {
      companyId = this.props.parameters.companyId
    } else {
      companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    }
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <AccessorySelectPage
        search={this.props.search}
        catTree={this.props.catTree}
        parameters={this.props.parameters}
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleChange={this.handleChange.bind(this)}
        onhandleClearSearch={this.props.onhandleClearSearch.bind(this)}
        accessoryList={this.props.accessoryList}
        companyTree={this.trimCompanyTree()}
        companyList={this.props.companyList}
        onhandleSearch={this.props.onhandleSearch.bind(this)}
        onhandleSetSelectedAccessory={this.props.onhandleSetSelectedAccessory.bind(this)}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        onhandleConfirm={this.handleConfirm.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accessoryList: state.accessorySelect.accessoryList,
    search: state.accessorySelect.search,
    catTree: state.accessory.catTree,
    parameters: state.modal.parameters,
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitAccessorySelectList: () => {
      dispatch(initAccessorySelectList());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: () => {
      dispatch(getAccessoryList());
    },
    onhandleSetSelectedAccessory: (value) => {
      dispatch(setSelectedAccessory(value));
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessorySelectPageContainer);
