import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { truncate } from "../../../../utils/string";
import {
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Select,
    TablePagination,
    Tooltip,
    Typography
} from "@material-ui/core";
import {
    AttachFile,
    ExpandMore
} from '@material-ui/icons';
import moment from 'moment';

const MonthlyPlanRecords = props => {
    const {
        classes,
        currentPlanDetail,
        lists,
        planResults,
        search,
        exportCsv,
        checkUserList,
        departmentList,
        resultList,
        onDownload,
        onhandleGetPlanRecords,
        onhandleSelectChange,
        onhandlePageChange,
        onhandleListMapping,
        onhandleBack
    } = props;


    const [expand, setExpand] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const totalRecordedCount = planResults.reduce((prev, curr) => (prev + curr.recordedCount), 0);
    const totalRecordCount = planResults.reduce((prev, curr) => (prev + curr.totalCount), 0);

    const userOptions = () => (
        <React.Fragment>
            {checkUserList ?
                checkUserList.map(data => (
                    <option value={data.userId} key={data.userId}>{data.lastName + data.firstName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const departmentOptions = () => (
        <React.Fragment>
            {departmentList ?
                departmentList.map(data => (
                    <option value={data.dptId} key={data.dptId}>{data.companyDptName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const resultOptions = () => (
        <React.Fragment>
            {resultList ?
                resultList.map(data => (
                    <option value={data.value} key={data.value}>{data.resultName}</option>
                )) : null
            }
        </React.Fragment>
    )

    const assemblycolumns = () => {

        let columns = [
            {
                title: '序号',
                field: 'serialNo',
                render: row => row ? <Typography>{row.checklist.serialNo}</Typography> : '',
                width: '10%',
                editable: 'never'
            },
            {
                title: '责任部门',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.inspectionResult.dptId, 'department')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查人',
                field: 'dptId',
                width: '10%',
                render: row => row ? <Typography>{onhandleListMapping(row.inspectionResult.userId, 'user')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查内容',
                field: 'problem',
                render: row => row ? <Typography>{row.checklist.problem}</Typography> : '',
                editable: 'never'
            },
            {
                title: '结果',
                field: 'result',
                width: '10%',
                render: row => {
                    let resultStyle = classes.pending;
                    if (row.result === 'true' || Number.parseInt(row.result) > 5) resultStyle = classes.passed;
                    if (row.result === 'false' || Number.parseInt(row.result) <= 5) resultStyle = classes.denied;
                    if (row.result === 'none') resultStyle = classes.none;
                    return <Chip size="small" className={resultStyle} label={onhandleListMapping(row.result, 'result')} />
                },
                editable: 'never'
            },
            {
                title: '备注',
                field: 'opentext',
                render: row => row ? <Typography>{truncate(row.opentext, 30)}</Typography> : '',
                editable: 'never'
            }
        ]

        return columns
    }

    const components = {
        Toolbar: props => (
            <>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography color='textPrimary' className={classes.label}>
                            检查人</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.userId}
                            onChange={(event) => onhandleSelectChange(event, 'userId')}
                        >
                            <option aria-label="None" value="">所有人</option>
                            {userOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            部门</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.dptId}
                            onChange={(event) => onhandleSelectChange(event, 'dptId')}
                        >
                            <option aria-label="None" value="">所有部门</option>
                            {departmentOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom className={classes.label}>
                            结果</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            style={{ width: '90%' }}
                            native
                            value={search.result}
                            onChange={(event) => onhandleSelectChange(event, 'result')}
                        >
                            <option aria-label="None" value="">不限</option>
                            {resultOptions()}
                        </Select>
                    </Grid>
                </Grid>
                <MTableToolbar {...props} />
            </>

        ),
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={rowsPerPage}
                count={lists.total}
                page={page}
                onChangePage={(event, pageNum) => {
                    setPage(pageNum);
                    onhandleGetPlanRecords({
                        ...search,
                        offset: pageNum * rowsPerPage,
                        size: rowsPerPage,
                        sort: "-record_time",
                    })
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    setRowsPerPage(event.target.value);
                    setPage(0);
                    onhandlePageChange({
                        size: event.target.value,
                    });
                    onhandleGetPlanRecords({
                        ...search,
                        offset: 0,
                        size: event.target.value,
                        sort: "-record_time"
                    })
                }}

            />
        )
    }

    return (
        <Card className={classes.card}>
            <div>
                <Typography variant="h3" style={{ display: 'inline-block', margin: 10 }}>
                    {'排查实施记录' + (currentPlanDetail.inventoryName ? ' - ' + currentPlanDetail.inventoryName : '')}</Typography>
            </div>
            <div>
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            检查类型</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {onhandleListMapping(currentPlanDetail.listType, 'listType')}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            完成时间</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {currentPlanDetail.endTime ? moment.utc(currentPlanDetail.endTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            受检单位</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {onhandleListMapping(currentPlanDetail.companyId, 'company')}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            负责人</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            {onhandleListMapping(currentPlanDetail.inspectHead, 'user')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainerSub}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            排查进度</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Box className={classes.expandable} display="flex" alignItems="center" onClick={() => setExpand(!expand)}>
                            <Box width="90%" mr={1}>
                                <LinearProgress variant="determinate" className={classes.progressMain} value={totalRecordedCount / totalRecordCount * 100 || 0} />
                            </Box>
                            <Box mr={2}>
                                <Typography>{`${totalRecordedCount}/${totalRecordCount}`}</Typography>
                            </Box>
                            <Box>
                                <Typography>{onhandleListMapping(currentPlanDetail.status, 'status')}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {
                    expand && planResults.map(
                        planResult =>
                            <Grid key={planResult.resultId} container spacing={1} className={classes.gridContainerSub}>
                                <Grid item xs={2} className={classes.subAlign} >
                                    <Typography>{onhandleListMapping(planResult.dptId, 'department') + ' - ' + onhandleListMapping(planResult.userId, 'user')}</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Box display="flex" alignItems="center">
                                        <Box width="35%" mr={1}>
                                            <LinearProgress variant="determinate" className={classes.progressSub} value={planResult.recordedCount / planResult.totalCount * 100 || 0} />
                                        </Box>
                                        <Box mr={2}>
                                            <Typography>{`${planResult.recordedCount || 0}/${planResult.totalCount || 0}`}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>{onhandleListMapping(planResult, 'recordStatus')}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                    )
                }
                <Grid container spacing={1} className={classes.gridContainerSub}>
                    <Grid container alignItems="center" justify="center">
                        <Box mr={1}>
                            <Tooltip title="进度详情">
                                <IconButton
                                    size={'small'}
                                    color="inherit"
                                    onClick={() => setExpand(!expand)}
                                >
                                    <ExpandMore className={expand ? classes.iconRotate : null} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <Divider variant="middle" />
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists.list}
                        options={{
                            ...options,
                            pageSize: rowsPerPage,
                            tableLayout: 'auto',
                            actionsColumnIndex: -1,
                            search: false,
                            toolbar: true,
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.listId ? '#EEEEEE' : 'FFFFFF'
                            })
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        localization={localization}
                        style={style}
                        components={components}
                        actions={
                            [
                                rowData => ({
                                    icon: AttachFile,
                                    tooltip: '查看附件',
                                    disabled: !rowData.docs.length && rowData.docs.length === 0,
                                    onClick: (event, rowData) => {
                                        onDownload(event, rowData, false)
                                    }
                                }),
                                {
                                    icon: 'save_alt',
                                    tooltip: '导出',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        exportCsv()
                                    }
                                }
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary" onClick={event => onhandleBack(event)}>
                        {'返回'}</Button>
                </Grid>

            </Grid>
        </Card >
    );
};

MonthlyPlanRecords.propTypes = {
    classes: PropTypes.object.isRequired,
    checkUserList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    resultList: PropTypes.array.isRequired,
    lists: PropTypes.object.isRequired,
    planResults: PropTypes.array.isRequired,
    onDownload: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    exportCsv: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

export default withStyles(MonthlyPlanRecords);
