import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    dialogContent:{

    },
    textfield:{
        minWidth: 500
    }

    

});

export default withStyles(styles);