import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorkOrderList from "../../components/OperationManagement/WorkOrder/WorkOrderList";
import WorkOrderHeader from "../../components/OperationManagement/WorkOrder/WorkOrderList/WorkOrderHeader";
import PropTypes from "prop-types";
import withStyles from './styles';
import { Card } from '@material-ui/core';
import {
    getWorkOrderList, initWorkList, updateWorkOrder,
    setSearch, resetSearch, routeToAppointmentCreate, routeBaseOnStatus,
    editWorkOrders, updateWorkOrderFinalCost, updateWorkOrderInvalid
} from '../../redux/modules/operation';
import {
    getOperationPlanDocs
} from '../../redux/modules/operationPlanDocs';
import { getPlanDetailList } from '../../redux/modules/operationPlan';
import { getCompanyId } from "../../utils/userUtils";
import { getTreePartById } from '../../utils/constant';
import ConfirmPanel from '../../components/OperationManagement/Appointment/CreateAppointment/ConfirmPanel';
import { openConfirmDialog } from '../../redux/modules/confirmDialog';
import { createMessage } from '../../redux/modules/message';
import { getOperationTypeDocClass, getWorkOrderStatusByRouteType } from '@/utils/operationUtils'
import { getFileExt } from '@/utils/fileUtils'
import { FilePreviewer } from '@/components/SimpleComponents';
import { downloadURL } from '@/redux/modules/minio';


import _ from 'lodash';

class WorkOrderListContainer extends Component {
    static propTypes = {
        initListData: PropTypes.object,
        userProfile: PropTypes.object,
        isLoading: PropTypes.bool,
        sourceList: PropTypes.array,
        orderTypeList: PropTypes.array,
        departmentList: PropTypes.array,
        companyList: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {
            routeType: '',
            confirmTableOpen: false,
            workOrdrCostList: [],
            planDetailCostList: [],
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        }
    }

    componentDidMount() {
        let routeType = '';
        if (this.props.location.pathname.indexOf("/workorderlist/all") !== -1) {
            routeType = 'all';
        } else if (this.props.location.pathname.indexOf("/workorderlist/needProcess") !== -1) {
            routeType = 'needProcess';
        } else if (this.props.location.pathname.indexOf("/workorderlist/needAppointment") !== -1) {
            routeType = 'needAppointment';
        } else if (this.props.location.pathname.indexOf("/workorderlist/needWork") !== -1) {
            routeType = 'needWork';
        } else if (this.props.location.pathname.indexOf("/workorderlist/needCount") !== -1) {
            routeType = 'needCount';
        }
        this.setState({
            routeType: routeType
        })
        //TODO
        this.props.onInitWorkList(routeType);
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value,
                    dptId: ''
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'orderType':
                newSearch = {
                    ...this.props.search,
                    orderType: value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'source':
                newSearch = {
                    ...this.props.search,
                    source: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'device':
                newSearch = {
                    ...this.props.search,
                    device: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'optNo':
                newSearch = {
                    ...this.props.search,
                    optNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'orderNo':
                newSearch = {
                    ...this.props.search,
                    orderNo: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'filterStatus':
                newSearch = {
                    ...this.props.search,
                    filterStatus: value.target.value,
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleCheck = (value, field) => {
        let newSearch;
        switch (field) {
            case 'onlyMe':
                newSearch = {
                    ...this.props.search,
                    onlyMe: value.target.checked
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    handleEditWorkOrders(id) {
        this.props.editWorkOrders(id, this.state.routeType);
    }

    handleRouteToAppointmentCreate(id) {
        this.props.onRouteToAppointmentCreate(id, this.state.routeType);
    }

    handleRouteBaseOnStatus(status) {
        this.props.onhandleRouteBaseOnStatus(status);
    }


    handleInvalid(rowData) {

        let orderId = rowData.orderId

        let d = {
            orderId: orderId,
            orderContent: `[工单作废]` + rowData.orderContent,
            status: 'invalid'
        }

        let invalidWorkOrder = () => {
            this.props.onhandleUpdateWorkOrderInvalid(d, () =>
                this.props.onInitWorkList(this.state.routeType)
            )
            this.setState({
                confirmTableOpen: false,
                workOrdrCostList: [],
                planDetailCostList: []
            })
        }

        this.props.onhandleOpenConfirmDialog(`工单作废`, `是否确认作废工单【` + rowData.orderNo + `】,同时作废本次审批流程`, invalidWorkOrder, null, false);


    }

    handleFinalCost(rowData) {
        if (getOperationTypeDocClass(rowData.workRecordFlg) !== 'N') {
            this.props.onhandleGetOperationPlanDocs({ size: 0, offset: 0, orderId: rowData.masterOrder ? rowData.masterOrder : rowData.orderId })
                .then(() => {
                    let operationPlanDocsList = _.clone(this.props.operationPlanDocsList.list);
                    if (_.filter(operationPlanDocsList, function (o) { return !o.operationPlanDoc }).length > 0 ||
                        _.filter(operationPlanDocsList, function (o) { return o.operationPlanDoc && o.operationPlanDoc.status !== 'active' }).length > 0) {
                        this.props.onhadleMassgae('请前往运维报告页面上传当前工单的报告');
                        return
                    } else {
                        this.handleFinalCostProcess(rowData)
                    }
                })
        } else {
            this.handleFinalCostProcess(rowData)
        }
    }

    handleFinalCostProcess(rowData) {
        let datas = _.clone(this.props.initListData.list);
        let outdata = [];
        let selectedData = _.find(datas, { orderId: rowData.orderId });
        outdata.push(selectedData);
        if (selectedData.masterOrder) {
            let masterData = _.find(datas, { orderId: selectedData.masterOrder });
            outdata.push(masterData);
        } else {
            let subData = _.find(datas, { masterOrder: rowData.orderId });
            if (subData && subData.orderId) {
                outdata.push(subData);
            }
        }

        this.props.onhandleGetPlanDetailList({ size: 0, offset: 0, orderId: rowData.orderId })
            .then(() => {
                let outdataDetail = _.clone(this.props.planDetails.list);

                this.setState({
                    confirmTableOpen: true,
                    workOrdrCostList: outdata,
                    planDetailCostList: outdataDetail,
                })
            })
    }

    handleClose() {
        this.setState({
            confirmTableOpen: false,
            workOrdrCostList: [],
            planDetailCostList: []
        })
    }

    handleChangeDetailItem(id, value) {
        let datas = _.clone(this.state.planDetailCostList);
        let data = _.find(datas, { dtlId: id });
        data.onefinalCost = value
        this.setState({
            planDetailCostList: datas,
        })
    }

    handleChangeItem(id, value, attr) {
        let datas = _.clone(this.state.workOrdrCostList);
        let data = _.find(datas, { orderId: id });
        data[attr] = value
        this.setState({
            workOrdrCostList: datas,
        })

        if (attr === 'orderCost' && _.filter(datas, function (o) { return !o.orderCost && o.orderCost !== 0 }).length === 0) {
            let total = 0;
            datas.forEach((woData) => {
                total = _.add(_.toNumber(woData.orderCost), _.toNumber(total))
            })
            let dtlCount = this.state.planDetailCostList.length;
            let dtlDatas = _.cloneDeep(this.state.planDetailCostList);
            if (dtlDatas.length === 1) {
                dtlDatas[0].onefinalCost = total
            } else {
                let subcount = 0
                dtlDatas.forEach((dtlData, index) => {
                    if (index === dtlDatas.length - 1) {
                        dtlData.onefinalCost = _.subtract(total, subcount);
                    } else {
                        dtlData.onefinalCost = _.floor(_.divide(total, dtlCount));
                        subcount = _.add(_.toNumber(dtlData.onefinalCost), _.toNumber(subcount))
                    }
                })
            }
            this.setState({
                planDetailCostList: dtlDatas,
            })
        }
    }

    handleUpdate() {
        let oldWorkOrders = _.cloneDeep(this.state.workOrdrCostList);
        let newWorkOrders = [];
        let workOrderTotal = 0;
        oldWorkOrders.forEach(workorder => {
            workOrderTotal = _.add(_.toNumber(workOrderTotal), _.toNumber(workorder.orderCost))
            newWorkOrders.push({
                orderId: workorder.orderId,
                orderCost: workorder.orderCost,
                opentext: workorder.opentext,
                opentext1: workorder.opentext1,
                opentext2: workorder.opentext2,
                status: 'done'
            })
        })

        let oldPlanDetails = _.cloneDeep(this.state.planDetailCostList);
        let newPlanDetails = [];
        let planDetailTotal = 0;
        oldPlanDetails.forEach(oldPlanDetail => {
            oldPlanDetail.finalCost = _.add(_.toNumber(oldPlanDetail.finalCost), _.toNumber(oldPlanDetail.onefinalCost));
            planDetailTotal = _.add(_.toNumber(planDetailTotal), _.toNumber(oldPlanDetail.onefinalCost))
            newPlanDetails.push({
                dtlId: oldPlanDetail.dtlId,
                finalCost: oldPlanDetail.finalCost,
                status: 'done'
            })
        })

        if (_.toNumber(workOrderTotal) !== _.toNumber(planDetailTotal)) {
            this.props.onhadleMassgae('工单决算总计和计划决算总计存在偏差');
            return
        }

        let data = {
            workOrders: newWorkOrders,
            operationPlanDetails: newPlanDetails
        }
        let saveData = () => {
            this.props.onhandleUpdateWorkOrderFinalCost(data, () =>
                this.props.onInitWorkList(this.state.routeType)
            )
            this.setState({
                confirmTableOpen: false,
                workOrdrCostList: [],
                planDetailCostList: []
            })
        }

        if (_.filter(oldPlanDetails, function (o) { return _.toNumber(o.finalCost) !== _.toNumber(o.budget) }).length > 0) {
            this.props.onhandleOpenConfirmDialog(`决算确认`, `存在一部分计划的决算金额和预算有偏差,是否依旧保存?`, saveData, null, false);
        } else {
            this.props.onhandleOpenConfirmDialog(`决算确认`, `是否保存?`, saveData, null, false);
        }

    }

    handlePageChange(current, pageSize) {
        let newSearch = {
            ...this.props.search,
            page: current,
            size: pageSize
        };
        this.props.onhandleUpdateEditSearch(newSearch)
        this.props.onGetWorkOrderList();
    }

    handleSearch() {
        let status = getWorkOrderStatusByRouteType(this.state.routeType);
        if (this.state.routeType === 'all') { //如果搜索栏有按状态搜索
            if (this.props.search && this.props.search.filterStatus) {
                status = this.props.search.filterStatus
            }
        }

        let newSearch = {
            ...this.props.search,
            page: 1,
            size: 10,
            status: status ? status : null,
        };
        this.props.onhandleUpdateEditSearch(newSearch)
        this.props.onGetWorkOrderList();
    }

    async handlePreview(data, docCate) { //文档种类类

        let docs = data
        if (!_.isArray(data)) {
            docs = [data]
        }
        if (docs && docs.length > 0) {
            const doc = docs[0]
            return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: getFileExt(doc.docName),
                            fileDesc: doc.docDesc,
                            docs: docs,
                            fileIndex: 0,
                            fileCount: docs.length,
                        }
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
        }
    }

    handleCloseReviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: ''
            }
        })
    }

    handlePreDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, false)
    }
    handleNextDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, true)
    }

    handleSwitchDoc = (fileIndex, isNext) => {
        const oldPreviewFile = this.state.previewFile
        var newIndex = fileIndex - 1
        if (isNext) {
            newIndex = fileIndex + 1
        }
        const doc = oldPreviewFile.docs[newIndex]
        return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        ...oldPreviewFile,
                        fileURL: res.payload,
                        fileType: getFileExt(doc.docName),
                        fileDesc: doc.docDesc,
                        fileIndex: newIndex,
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }


    render() {
        return (
            <Card>
                <WorkOrderHeader
                    orderTypeTree={this.props.orderTypeTree}
                    sourceList={this.props.sourceList}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.props.companyList}
                    departmentList={this.props.departmentList}
                    search={this.props.search}
                    routeType={this.state.routeType}
                    onhandleSelectChange={this.handleSelect.bind(this)}
                    onhandleCheck={this.handleCheck.bind(this)}
                    onhandleClearSearch={this.props.onResetSearch.bind(this)}
                    getWorkOrderList={this.handleSearch.bind(this)}
                />
                <WorkOrderList
                    initListData={this.props.initListData}
                    companyId={getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)}
                    userProfile={this.props.userProfile}
                    currentUserInfo={this.props.currentUserInfo}
                    isLoading={this.props.isLoading}
                    sourceList={this.props.sourceList}
                    orderTypeList={this.props.orderTypeList}
                    departmentList={this.props.departmentList}
                    companyList={this.props.companyList}
                    search={this.props.search}
                    onhandleRouteToAppointmentCreate={this.handleRouteToAppointmentCreate.bind(this)}
                    onEditWorkOrders={this.handleEditWorkOrders.bind(this)}
                    routeType={this.state.routeType}
                    onhandleRouteBaseOnStatus={this.handleRouteBaseOnStatus.bind(this)}
                    onhandleFinalCost={this.handleFinalCost.bind(this)}
                    onhandleInvalid={this.handleInvalid.bind(this)}
                    // onhandlePageSizeChange={this.handlePageSizeChange.bind(this)}
                    onhandlePageChange={this.handlePageChange.bind(this)}
                    onDownLoadDoc={this.handlePreview.bind(this)}
                />
                <FilePreviewer
                    open={this.state.previewFile.open}
                    fileType={this.state.previewFile.fileType}
                    filePath={this.state.previewFile.fileURL}
                    fileDesc={this.state.previewFile.fileDesc}
                    fileIndex={this.state.previewFile.fileIndex}
                    fileCount={this.state.previewFile.fileCount}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleCloseReviewer}
                    onPre={this.handlePreDoc}
                    onNext={this.handleNextDoc}
                />
                {this.state.confirmTableOpen && <ConfirmPanel
                    open={this.state.confirmTableOpen}
                    onhandleClose={this.handleClose.bind(this)}
                    workorderWithBigPermitList={this.state.workOrdrCostList}
                    planDetails={this.state.planDetailCostList}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleChangeItem={this.handleChangeItem.bind(this)}
                    onhandleChangeDetailItem={this.handleChangeDetailItem.bind(this)}
                />}
            </Card>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        initListData: state.operation.workOrderList,
        userProfile: state.auth.userProfile,
        currentUserInfo: state.auth.currentUserInfo,
        sourceList: state.operation.sourceList,
        orderTypeList: state.operation.orderTypeList,
        orderTypeTree: state.operation.orderTypeTree,
        isLoading: state.loading.globalLoading,
        departmentList: state.constant.departments,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        search: state.operation.search,
        planDetails: state.operationPlan.planDetails,
        operationPlanDocsList: state.operationPlanDocs.operationPlanDocsList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetWorkOrderList: () => {
            dispatch(getWorkOrderList());
        },
        onInitWorkList: (routeType) => {
            dispatch(initWorkList(routeType));
        },
        onUpdateWorkOrder: (data, callback) => {
            dispatch(updateWorkOrder(data, callback));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onResetSearch: () => {
            dispatch(resetSearch());
        },
        onRouteToAppointmentCreate: (id, routeType) => {
            dispatch(routeToAppointmentCreate(id, routeType));
        },
        editWorkOrders: (id, routeType) => {
            dispatch(editWorkOrders(id, routeType));
        },
        // 根据工单状态路由到不同地址
        onhandleRouteBaseOnStatus: (status) => {
            dispatch(routeBaseOnStatus(status));
        },
        onhandleUpdateWorkOrderFinalCost: (data, callback) => {
            dispatch(updateWorkOrderFinalCost(data, callback));
        },
        onhandleUpdateWorkOrderInvalid: (data, callback) => {
            dispatch(updateWorkOrderInvalid(data, callback));
        },
        onhandleGetPlanDetailList: (query) => {
            return dispatch(getPlanDetailList(query));
        },
        onhandleGetOperationPlanDocs: (query) => {
            return dispatch(getOperationPlanDocs(query));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhadleMassgae: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(WorkOrderListContainer))
