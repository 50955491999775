import { withStyles } from '@material-ui/styles';

const styles = theme => {
  return {
    toolButton: {
      cursor: 'pointer',
      marginBottom: '.5em',
      userSelect: 'none',
    },
    container: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
      position: 'relative',
    },
    title: {
      lineHeight: '60px',
      paddingLeft: '25px',
      color: theme.palette.black,
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between'
    },
    closeIcon: {
      position: 'absolute',
      right: '16px'
    },
    content: {
      padding: '0 16px'
    },
    radios: {
      marginTop: '20px'
    },
    dragDropHint: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      minHeight: '300px',
      fontSize: '24px',
      color: theme.palette.black,
      textAlign: 'center',
    },
    uploadIcon: {
      display: 'inline-block',
      height: '60px',
      width: '60px',
      margin: '10px',
      opacity: '0.5',
    },
    browseFile: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 'Bold',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        opacity: '0.6',
      }
    },
    fileFormat: {
      marginTop: '30px',
      fontSize: '16px',
    },
    filearea: {
      border: '2px dashed ' + theme.palette.black,
      borderRadius: '20px',
      backgroundColor: theme.palette.white,
      marginTop: theme.spacing(2),
      minHeight: '300px'
    },
    dragging: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    filename: {
      margin: '5px 0',
      minHeight: '340px',
    },
    fileItem: {
      // display: 'inline-block',
      // maxWidth: '95%',
      // borderRadius: '4px',
      // padding: '4px',
      margin: '2px 4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    uploadHint: {
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid ' + theme.palette.grey[500],
      padding: '5px 0px 5px 10px',
      maxHeight: '80px',
      lineHeight: '16px',
      color: theme.palette.black,
      fontSize: '16px',
    },
    uploadfile: {
      display: 'none',
    },
    note: {
      fontWeight: 'bold',
    },
    redStar: {
      color: 'red',
    },
    uploadname: {
      maxHeight: '50vh',
      overflow: 'auto',
      color: theme.palette.black,
      fontSize: '16px',
      fontWeight: '500',
      margin: '5px'
    },
    buttonarea: {
      textAlign: 'right',
      marginTop: theme.spacing(2)
    },
    buttons: {
      marginLeft: theme.spacing(1)
    },
    loading: {
      margin: '5px'
    }
  };
};

export default withStyles(styles);
