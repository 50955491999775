import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AnnualPlan from '../../../components/LeadershipManagement/AnnualPlan';
import {
    getCurrentPlan
} from "../../../redux/modules/leadershipRegulationPlan";
import { clearSchemeMap } from "@/redux/modules/inspection/hazard";


class AnnualPlanContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
        const {
            schemeMap,
            onhandleGetCurrentPlan
        } = this.props
        onhandleGetCurrentPlan()
        if (schemeMap && schemeMap.schemeId) {
            // 清除隐患排查-治理方案映射信息
            this.props.onhandleClearSchemeMap();
        }
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue
        })
    }

    render() {
        return (
            <div>
                <AnnualPlan
                    tabValue={this.state.tabValue}
                    plan={this.props.plan}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.currentPlan,
        schemeMap: state.hazard.schemeMap
    };
};
//getRegulations
const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getCurrentPlan(callback))
        },
        onhandleClearSchemeMap: () => {
            dispatch(clearSchemeMap());
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnnualPlanContainer);