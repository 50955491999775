import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
    Tooltip,
    TextField,
    MenuItem,
} from '@material-ui/core';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import * as _ from 'lodash';

const AppendService2AnnualPlanDailog = props => {
    const { classes,
        open,
        serviceId,
        services,
        year,
        onhandleClose,
        onhandleAppend,
        onhandleChangeAppendService,
    } = props;

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>新增服务到{year}年度计划</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='关联服务'
                                        select
                                        value={serviceId || ''}
                                        name='serviceId'
                                        onChange={onhandleChangeAppendService}>
                                        {services && services.map((i) => (
                                            <MenuItem key={i.serviceId} value={i.serviceId}>
                                                {i.serviceName} {renderServiceType(i.serviceType)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleAppend} color="primary"> 添加
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AppendService2AnnualPlanDailog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    servcies: PropTypes.array,
    year: PropTypes.number,
    onhandleClose: PropTypes.func,
    onhandleAppend: PropTypes.func,
};

AppendService2AnnualPlanDailog.defaultProps = {
    open: false,
    servcies: [],
    year: 0,
};

export default withStyles(AppendService2AnnualPlanDailog);