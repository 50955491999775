import Konva from 'konva';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from '../../../../../../redux/store';
import { cleanContentMenu, createContentMenu } from '../../../../redux/modules/contentmenu';
import KonvaUtil from "../../util";
import ActionUtil from '../../actionUtil';
const konvaUtil = new KonvaUtil();
const actionUtil = new ActionUtil();


export function init(props, bx, by, id, name, stage, previewStage, fillColor) {
    let layer = stage.findOne('.workLayer');
    const state = {
        width: 100,
        height: 0,
        strokeWidth: 4
    };

    const dragPoints = [
        {
            id: uuidv4() + '_' + 1,
            x: 0,
            y: 0,
            name: 'start'
        },
        {
            id: uuidv4() + '_' + 2,
            x: 100,
            y: 0,
            name: 'end'
        },
    ];
    // 生成管道对象
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: id,
        zIndex: 5,
        name: "Guandao",
    });

    var scaleLine = new Konva.Arrow({
        points: [0, 0,
            100, 0],
        stroke: fillColor,
        strokeWidth: state.strokeWidth,
        lineCap: 'round',
        lineJoin: 'round',
        name: 'basic',
        zindex: '0'
    });

    group.add(scaleLine)

    // // 管道名称
    // const text = new Konva.Text({
    //     text: name,
    //     x: 20,
    //     y: 10,
    //     fill: 'black',
    //     name: "GuandaoText",
    // })
    // group.add(text);

    for (const dragPoint of dragPoints) {
        CreatedragPoints(props, group, layer, scaleLine, previewStage, dragPoint.id, dragPoint.name, dragPoint.x, dragPoint.y, 2, fillColor)
    }

    lineCommon(props, scaleLine, group, layer, stage, previewStage);

    groupCommon(props, group, layer, stage, previewStage, fillColor, scaleLine);

    layer.add(group);
    layer.batchDraw();
    konvaUtil.updatePreview(props, layer, previewStage);
}

export function mapping(props, group, layer, stage, previewStage) {
    let lineColor = '#130C0E';
    let line = group.findOne('Arrow');
    // group.find('Arrow').forEach(line => {
    lineColor = line.stroke();
    lineCommon(props, line, group, layer, stage, previewStage)
    group.find('Circle').forEach(circle => {
        circleCommon(props, circle, layer, group, line, previewStage, lineColor);
    })
    group.find('Ring').forEach(ring => {
        ringCommon(props, ring, layer, group, line, previewStage);
    })

    // })
    groupCommon(props, group, layer, stage, previewStage, lineColor, line);
}

function CreatedragPoints(props, group, layer, scaleLine, previewStage, id, name, x, y, radius = 2, fill = "black") {
    const circle = new Konva.Circle({
        id: id,
        x: x,
        y: y,
        fill: fill,
        radius: radius,
        draggable: true,
        zindex: '1',
        name: name
    });
    group.add(circle);
    layer.batchDraw();
    circleCommon(props, circle, layer, group, scaleLine, previewStage, fill);
}

function ringCommon(props, ring, layer, group, line, previewStage, fill) {
    ring.on("dragmove", e => {
        console.log('ring move')
        group.find('Ring').forEach(r1 => {
            if (r1.id() !== ring.id()) {
                r1.remove();
            }

        })
        let ids = _.split(ring.id(), ',');
        if (ids.length > 0) {
            let circle1 = layer.findOne('#' + ids[0]);
            let circle2 = layer.findOne('#' + ids[1]);
            let cPos = {
                x: ring.getClientRect().x + ring.getClientRect().width / 2,
                y: ring.getClientRect().y + ring.getClientRect().width / 2
            };
            let x = cPos.x - ring.getParent().x();
            let y = cPos.y - ring.getParent().y();
            if (circle1.x() === circle2.x()) {
                y = circle1.y() > circle2.y() ? circle1.y() - (circle1.y() - circle2.y()) / 2 : circle2.y() - (circle2.y() - circle1.y()) / 2

                circle1.x(x);
                circle2.x(x);
                ring.x(x);
                ring.y(y);
            } else if (circle1.y() === circle2.y()) {
                x = circle1.x() > circle2.x() ? circle1.x() - (circle1.x() - circle2.x()) / 2 : circle2.x() - (circle2.x() - circle1.x()) / 2
                circle1.y(y);
                circle2.y(y);
                ring.x(x);
                ring.y(y);
            }

        }
        layer.batchDraw();
        updateObjects(props, group, line, layer, previewStage);
    });
    ring.on("dragend", e => {
        console.log('ring move')
        let ids = _.split(ring.id(), ',');
        if (ids.length > 0) {
            let circle1 = layer.findOne('#' + ids[0]);
            let circle2 = layer.findOne('#' + ids[1]);
            let cPos = {
                x: ring.getClientRect().x + ring.getClientRect().width / 2,
                y: ring.getClientRect().y + ring.getClientRect().width / 2
            };
            let x = cPos.x - ring.getParent().x();
            let y = cPos.y - ring.getParent().y();
            if (circle1.x() === circle2.x()) {
                y = circle1.y() > circle2.y() ? circle1.y() - (circle1.y() - circle2.y()) / 2 : circle2.y() - (circle2.y() - circle1.y()) / 2

                circle1.x(x);
                circle2.x(x);
                ring.x(x);
                ring.y(y);
            } else if (circle1.y() === circle2.y()) {
                x = circle1.x() > circle2.x() ? circle1.x() - (circle1.x() - circle2.x()) / 2 : circle2.x() - (circle2.x() - circle1.x()) / 2
                circle1.y(y);
                circle2.y(y);
                ring.x(x);
                ring.y(y);
            }

        }
        layer.batchDraw();
        updateObjects(props, group, line, layer, previewStage);
        UpdRing(props, line, group, layer, previewStage);
    });

}

function CreateMiddleDragPoints(props, group, layer, line, previewStage, id, x, y, fill = "yellow") {
    const ring = new Konva.Ring({
        id: id,
        x: x,
        y: y,
        innerRadius: 0,
        outerRadius: 6,
        fill: fill,
        stroke: 'black',
        strokeWidth: 1,
        draggable: true,
        zindex: '2',
        name: 'middleDrag'
    });
    group.add(ring);
    layer.batchDraw();
    ringCommon(props, ring, layer, group, line, previewStage, fill)
}

function circleCommon(props, circle, layer, orignalGroup, scaleLine, previewStage, lineColor) {
    circle.on("dragmove", e => {
        console.log('circle move')
        orignalGroup.find('Ring').forEach(ring => {
            ring.remove();
        })
        updateObjects(props, orignalGroup, scaleLine, layer, previewStage);

        layer.children.each(function (group) {
            // do not check intersection with itself
            if (group === circle.getParent()) {
                return;
            }
            if (konvaUtil.haveIntersection(group.getClientRect(), circle.getClientRect())) {
                group.fire('mouseover')
            } else {
                group.fire('mouseout')
            }
        });
    });

    circle.on("dragend", e => {
        layer.children.each(function (group) {
            // do not check intersection with itself
            if (group === circle.getParent()) {
                return;
            }
            if (konvaUtil.haveIntersection(group.getClientRect(), circle.getClientRect())) {
                group.find('Circle').each(function (connectPoint) {
                    // if (!connectPoint.name() || connectPoint.name() === '') {
                    if (konvaUtil.haveIntersection(connectPoint.getClientRect(), circle.getClientRect())) {
                        let cPos = {
                            x: connectPoint.getClientRect().x + connectPoint.getClientRect().width / 2,
                            y: connectPoint.getClientRect().y + connectPoint.getClientRect().width / 2
                        };
                        let x = cPos.x - circle.getParent().x();
                        let y = cPos.y - circle.getParent().y();
                        circle.x(x);
                        circle.y(y)
                        updateObjects(props, orignalGroup, scaleLine, layer, previewStage);
                        // }
                    }
                })
            }
            // if (circle.name() === 'start') {
            //     let text = orignalGroup.findOne('.GuandaoText');
            //     text.x(circle.x() + 20);
            //     text.y(circle.y() + 10);
            //     layer.batchDraw();
            // }
        });
        //updateObjects();
        formatterLine(props, orignalGroup, scaleLine, layer, previewStage)
        recoverStyle(e, orignalGroup, layer, lineColor);
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(orignalGroup, layer);
        actionUtil.caculateDeviceIntersectionWithAreaOrUnit(orignalGroup, layer);
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.markChange(orignalGroup.id());
        UpdRing(props, scaleLine, orignalGroup, layer, previewStage)
    });

    circle.on("dblclick", e => {
        if (circle.name() !== 'start' && circle.name() !== 'end') {
            console.log('circle dblclick')
            circle.destroy();
            UpdRing(props, scaleLine, orignalGroup, layer, previewStage)
            layer.batchDraw();
            updateObjects(props, orignalGroup, scaleLine, layer, previewStage);
            actionUtil.caculateDeviceIntersectionWithDeviceGroup(orignalGroup, layer);
            actionUtil.caculateDeviceIntersectionWithAreaOrUnit(orignalGroup, layer);
            konvaUtil.updatePreview(props, layer, previewStage);
            actionUtil.markChange(orignalGroup.id());
        }
    });

}


function formatterLine(props, orignalGroup, scaleLine, layer, previewStage) {
    let points = [];
    let circles = orignalGroup.find('Circle')
    let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); });
    newCircles.forEach((newCircle, index) => {
        if (newCircle.name() === 'start' || newCircle.name() === 'end') {
            points.push(newCircle.x());
            points.push(newCircle.y());
        } else {
            if (newCircle.x() === newCircles[index - 1].x() ||
                newCircle.x() === newCircles[index + 1].x() ||
                newCircle.y() === newCircles[index - 1].y() ||
                newCircle.y() === newCircles[index + 1].y()) {
                points.push(newCircle.x());
                points.push(newCircle.y());
            } else {
                // 计算新点在起点方向的哪一侧
                //(x2-x1)(y0-y1) – (y2-y1)(x0-x1)  >  0   左侧
                //(x2-x1)(y0-y1) – (y2-y1)(x0-x1)  <  0   右侧 
                //(x2-x1)(y0-y1) – (y2-y1)(x0-x1)  =  0   线段上
                let x2 = 0;
                let y2 = 0;
                let x1 = 0;
                let y1 = 0;
                if ((newCircles[index + 1].x() < newCircles[index - 1].x() && newCircles[index + 1].y() < newCircles[index - 1].y())
                    || (newCircles[index + 1].x() > newCircles[index - 1].x() && newCircles[index + 1].y() > newCircles[index - 1].y())
                ) {
                    x2 = newCircles[index - 1].x();
                    y2 = newCircles[index - 1].y();
                    x1 = newCircles[index + 1].x();
                    y1 = newCircles[index + 1].y();
                } else {
                    x2 = newCircles[index + 1].x();
                    y2 = newCircles[index + 1].y();
                    x1 = newCircles[index - 1].x();
                    y1 = newCircles[index - 1].y();
                }

                let temp = ((x2 - x1) * (newCircle.y() - y1)) - ((y2 - y1) * (newCircle.x() - x1))
                console.log(temp)
                let x = newCircle.x();
                let y = newCircle.y();
                if (temp <= 0) {
                    if (newCircles[index - 1].y() === newCircles[index + 1].y()
                        || newCircles[index + 1].x() === newCircles[index - 1].x()) {
                        x = newCircle.x();
                        y = newCircle.y();
                    } else {
                        x = newCircles[index - 1].x();
                        y = newCircles[index + 1].y();
                    }
                } else {
                    if (newCircles[index - 1].x() === newCircles[index + 1].x()
                        || newCircles[index + 1].y() === newCircles[index - 1].y()) {
                        x = newCircle.x();
                        y = newCircle.y();
                    } else {
                        x = newCircles[index + 1].x();
                        y = newCircles[index - 1].y();
                    }
                }
                newCircle.x(x)
                newCircle.y(y)
                points.push(newCircle.x());
                points.push(newCircle.y());
            }
        }
    })
    scaleLine.points(points);
    layer.batchDraw();
}

function updateObjects(props, orignalGroup, scaleLine, layer, previewStage) {
    let points = [];
    let circles = orignalGroup.find('Circle')
    let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); });
    for (const circle of newCircles) {
        points.push(circle.x());
        points.push(circle.y());
    }
    scaleLine.points(points);
    layer.batchDraw();
}

function UpdRing(props, line, group, layer, previewStage) {
    group.find('Ring').forEach(ring => {
        ring.remove();
    })
    layer.batchDraw();
    let circles = group.find('Circle')
    let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); })
    newCircles.forEach((newCircle, index) => {
        if (index !== newCircles.length - 1) {
            if (newCircle.x() === newCircles[index + 1].x() || newCircle.y() === newCircles[index + 1].y()) {
                let point = {
                    x: (newCircles[index + 1].x() >= newCircles[index].x()) ?
                        (newCircles[index].x() + Math.abs((newCircles[index + 1].x() - newCircles[index].x()) / 2)) :
                        (newCircles[index + 1].x() + Math.abs((newCircles[index + 1].x() - newCircles[index].x()) / 2)),
                    y: (newCircles[index + 1].y() >= newCircles[index].y()) ?
                        (newCircles[index].y() + Math.abs((newCircles[index + 1].y() - newCircles[index].y()) / 2)) :
                        (newCircles[index + 1].y() + Math.abs((newCircles[index + 1].y() - newCircles[index].y()) / 2)),
                }
                CreateMiddleDragPoints(props, group, layer, line, previewStage,
                    newCircle.id() + ',' + newCircles[index + 1].id(), point.x, point.y, "yellow");
            }
        }
    })
    layer.batchDraw();
}

function updateStye(props, line, group, layer, stage, previewStage) {
    group.find('Arrow').forEach(line => {
        line.stroke('red');
        line.dash([10, 5]);
    })
    group.find('Circle').forEach(circle => {
        circle.fill('blue');
        circle.radius(6);
    })
    if (group.find('Ring').length > 0) {
        group.find('Ring').forEach(ring => {
            ring.outerRadius(6);
            ring.fill('yellow');
        })
    } else {
        UpdRing(props, line, group, layer, previewStage)
    }

    layer.batchDraw();
}

function recoverStyle(e, orignalGroup, layer, lineColor) {
    orignalGroup.find('Arrow').forEach(line => {
        line.stroke(lineColor);
        line.dash([0, 0]);
    })
    orignalGroup.find('Circle').forEach(circle => {
        circle.fill(lineColor);
        circle.radius(2);
    })
    orignalGroup.find('Ring').forEach(ring => {
        ring.outerRadius(2);
        ring.fill(lineColor);
        ring.stroke(lineColor);
    })
    layer.batchDraw();
}

function creatNewPoint(e, props, line, group, layer, stage, previewStage) {
    let circles = group.find('Circle')
    if (circles.length === 2) {
        let point = {}
        let startPoint = _.find(circles, function (o) { return o.name() === 'start'; });
        let endPoint = _.find(circles, function (o) { return o.name() === 'end'; })
        if (startPoint.x() === endPoint.x()) {
            point = {
                x: startPoint.x(),
                y: startPoint.y() + Math.abs((endPoint.y() - startPoint.y()) / 2)
            }
        } else if (startPoint.y() === endPoint.y()) {
            point = {
                x: startPoint.x() + Math.abs((endPoint.x() - startPoint.x()) / 2),
                y: startPoint.y()
            }
        } else {
            point = {
                x: (endPoint.x() > startPoint.x()) ? (startPoint.x() + Math.abs((endPoint.x() - startPoint.x()) / 2)) : (endPoint.x() + Math.abs((endPoint.x() - startPoint.x()) / 2)),
                y: (endPoint.y() > startPoint.y()) ? (startPoint.y() + Math.abs((endPoint.y() - startPoint.y()) / 2)) : (endPoint.y() + Math.abs((endPoint.y() - startPoint.y()) / 2)),
            }
        }
        CreatedragPoints(props, group, layer, line, previewStage, uuidv4() + '_' + 2, 'dragpoint', point.x, point.y, 6, 'blue');
        endPoint.id(uuidv4() + '_' + 3);
    } else {
        let mousePos = stage.getPointerPosition();
        let x = Math.round(mousePos.x - group.x());
        let y = Math.round(mousePos.y - group.y());
        let fromIndex;
        let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); })
        newCircles.forEach((newCircle, index) => {
            if (index !== newCircles.length - 1) {
                if (newCircle.x() === newCircles[index + 1].x()) {
                    if (((newCircle.x() - 2 <= x && newCircle.x() + 2 >= x)
                        || (newCircle.x() - 2 >= x && newCircle.x() + 2 <= x))
                        && ((newCircle.y() <= y && newCircles[index + 1].y() >= y)
                            || (newCircle.y() >= y && newCircles[index + 1].y() <= y))
                    ) {
                        fromIndex = index
                    }
                } else if (newCircle.y() === newCircles[index + 1].y()) {
                    if (((newCircle.y() - 2 <= y && newCircle.y() + 2 >= y)
                        || (newCircle.y() - 2 >= y && newCircle.y() + 2 <= y))
                        && ((newCircle.x() <= x && newCircles[index + 1].x() >= x)
                            || (newCircle.x() >= x && newCircles[index + 1].x() <= x))
                    ) {
                        fromIndex = index
                    }
                } else {
                    if (((newCircle.x() <= x && newCircles[index + 1].x() >= x) && (newCircle.y() <= y && newCircles[index + 1].y() >= y)) ||
                        ((newCircle.x() <= x && newCircles[index + 1].x() >= x) && (newCircle.y() >= y && newCircles[index + 1].y() <= y)) ||
                        ((newCircle.x() >= x && newCircles[index + 1].x() <= x) && (newCircle.y() <= y && newCircles[index + 1].y() >= y)) ||
                        ((newCircle.x() >= x && newCircles[index + 1].x() <= x) && (newCircle.y() >= y && newCircles[index + 1].y() <= y))
                    ) {
                        fromIndex = index
                    }
                }
            }
        })

        let point = {
            x: (newCircles[fromIndex + 1].x() >= newCircles[fromIndex].x()) ?
                (newCircles[fromIndex].x() + Math.abs((newCircles[fromIndex + 1].x() - newCircles[fromIndex].x()) / 2)) :
                (newCircles[fromIndex + 1].x() + Math.abs((newCircles[fromIndex + 1].x() - newCircles[fromIndex].x()) / 2)),
            y: (newCircles[fromIndex + 1].y() >= newCircles[fromIndex].y()) ?
                (newCircles[fromIndex].y() + Math.abs((newCircles[fromIndex + 1].y() - newCircles[fromIndex].y()) / 2)) :
                (newCircles[fromIndex + 1].y() + Math.abs((newCircles[fromIndex + 1].y() - newCircles[fromIndex].y()) / 2)),
        }
        let id = uuidv4() + '_' + (fromIndex + 1)
        CreatedragPoints(props, group, layer, line, previewStage, id, 'dragpoint', point.x, point.y, 6, 'blue')
        newCircles.forEach((newCircle, index) => {
            if (index > fromIndex) {
                newCircle.id(uuidv4() + '_' + (index + 2));
            }
        })
    }
    UpdRing(props, line, group, layer, previewStage)
    layer.batchDraw();
}

function lineCommon(props, line, group, layer, stage, previewStage) {
    line.on('click', function (e) {
        updateStye(props, line, group, layer, stage, previewStage)
    });
    line.on('dblclick', function (e) {
        creatNewPoint(e, props, line, group, layer, stage, previewStage);
    });
}

function groupCommon(props, group, layer, stage, previewStage, lineColor, line) {
    const store = getStore();

    group.on('dragend', function () {
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer, 'drag');
        actionUtil.caculateDeviceIntersectionWithAreaOrUnit(group, layer);
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.markChange(group.id());
    });

    group.on('mouseover', function (e) {
        document.body.style.cursor = 'pointer';
        updateStye(props, line, group, layer, stage, previewStage);
    })

    group.on('mouseout', function (e) {
        document.body.style.cursor = 'default';
        recoverStyle(e, group, layer, lineColor);
        layer.batchDraw();
    })

    group.on('dragstart', function () {
        store.dispatch(cleanContentMenu());
    });

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let circles = group.find('Circle')
        let newCircles = _.sortBy(circles, function (o) { return _.toInteger(_.split(o.id(), '_')[1]); });
        let containerRect = stage.container().getBoundingClientRect();
        let top = containerRect.top + newCircles[1].getClientRect().y + newCircles[1].getClientRect().height / 2;
        let left = containerRect.left + newCircles[1].getClientRect().x + newCircles[1].getClientRect().width / 2;
        store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
    });
}



