import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { backToList, initDeviceOpsReport } from '@/redux/modules/devices';
import OpsReport from '@/components/DeviceManagement/DeviceInfo/DeviceDetail/OpsReport';
import { FilePreviewer } from '@/components/SimpleComponents';
import { getFileExt } from '@/utils/fileUtils';
import { downloadURL } from '@/redux/modules/minio';
import * as _ from 'lodash';

class OpsReportContainer extends Component {
    static propTypes = {
        opsRecords: PropTypes.object,
    };

    constructor(props) {
        super(props)
        this.state = {
            previewFile: {
                open: false,
                fileType: '',
                fileURL: ''
            }
        }
    }

    componentWillMount() {
        //获取设备的技术参数
        if (this.props.currentDevice.deviceId) {
            this.props.onhandleInitDeviceOpsReport()
        }
    }
    handleBack = (event) => {
        this.props.onhandleBackToList();
    };

    handlPreviewDoc(allDocs) {
        const docs = _.filter(allDocs, d => {
            return d.docName && d.docLink
        })
        if (docs && docs.length > 0) {
            const doc = docs[0]
            return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
                if (res && res.payload) {
                    this.setState({
                        previewFile: {
                            open: true,
                            fileURL: res.payload,
                            fileType: getFileExt(doc.docName),
                            fileDesc: doc.docDesc,
                            docs: docs,
                            fileIndex: 0,
                            fileCount: docs.length,
                        }
                    })
                    return res.payload;
                } else {
                    // 不做什么
                }
            });
        }

    }

    handleSwitchDoc = (fileIndex, isNext) => {
        const oldPreviewFile = this.state.previewFile
        var newIndex = fileIndex - 1
        if (isNext) {
            newIndex = fileIndex + 1
        }
        const doc = oldPreviewFile.docs[newIndex]
        return this.props.onhandleDownloadUrl(doc.docName, doc.docLink).then(res => {
            if (res && res.payload) {
                this.setState({
                    previewFile: {
                        ...oldPreviewFile,
                        fileURL: res.payload,
                        fileType: getFileExt(doc.docName),
                        fileDesc: doc.docDesc,
                        fileIndex: newIndex,
                    }
                })
                return res.payload;
            } else {
                // 不做什么
            }
        });
    }

    handlePreDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, false)
    }
    handleNextDoc = (fileIndex) => {
        this.handleSwitchDoc(fileIndex, true)
    }

    handleClosePreviewer = () => {
        this.setState({
            previewFile: {
                open: false,
                fileURL: '',
                fileType: '',
                fileDesc: '',
                docs: [],
                fileIndex: 0,
                fileCount: 0,
            }
        })
    }

    render() {
        return (
            <div>
                <OpsReport
                    opsRecords={this.props.opsRecords}
                    tabValue={this.props.tabValue}
                    index={this.props.index}
                    onhandleBack={this.handleBack.bind(this)}
                    onhandlPreviewDoc={this.handlPreviewDoc.bind(this)}
                />
                <FilePreviewer
                    open={this.state.previewFile.open}
                    fileType={this.state.previewFile.fileType}
                    filePath={this.state.previewFile.fileURL}
                    fileIndex={this.state.previewFile.fileIndex}
                    fileCount={this.state.previewFile.fileCount}
                    fileDesc={this.state.previewFile.fileDesc}
                    errorComponent={null}
                    onError={null}
                    onClose={this.handleClosePreviewer}
                    onPre={this.handlePreDoc}
                    onNext={this.handleNextDoc}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        currentDevice: state.devices.currentDevice,
        opsRecords: state.deviceOpsRecord.oriDeviceOpsRecordList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleBackToList: () => {
            dispatch(backToList())
        },
        onhandleInitDeviceOpsReport: () => {
            dispatch(initDeviceOpsReport())
        },

        onhandleDownloadUrl: (docName, mybucket) => {
            return dispatch(downloadURL(docName, mybucket))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpsReportContainer)