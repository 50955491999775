import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message'
import { getInstallationList } from '@/redux/modules/installation';
import { download } from '@/redux/modules/minio';
import _ from 'lodash';

const porxyApi = '/api/manufacture/assay'

const initialState = {
    assayTemps: [],
    assayRecords: {
        total: 0,
        list: []
    },
    currentRecord: {},
    assayRecordSummary: [],
    assayers: [],
    inspectors: []
};

export const initAssay = createAction('@@xcloud/manufacture/initAssay',
    () =>
        (dispatch, getState, httpClient) => {
            Promise.all([
                dispatch(getInstallationList({ offset: 0, size: 0 })),
                dispatch(getAssayTemps()),
                dispatch(getAssayers()),
                dispatch(getInspectors())
            ]).then((data) => {
                return data;
            }).catch(err => {
                let msg = '初始化化验记录页面失败';
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return [];
            });
        });

export const getAssayers = createAction('@@xcloud/manufacture/getAssayers',
    () =>
        (dispatch, getState, httpClient) => {
            let url = '/api/users?offset=0&size=0&status=active&dptId=8'
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setAssayers(res.data.list))
                } else {
                    dispatch(setAssayers([]))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取化验人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                dispatch(setAssayers([]))
                return []
            })
        });

export const getInspectors = createAction('@@xcloud/manufacture/getInspectors',
    () =>
        (dispatch, getState, httpClient) => {
            let url = '/api/users?offset=0&size=0&status=active&dptId=7'
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setInspectors(res.data.list))
                } else {
                    dispatch(setInspectors([]))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取质检人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                dispatch(setInspectors([]))
                return []
            })
        });

export const getAssayTemps = createAction('@@xcloud/manufacture/getAssayTemps',
    () =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/temps?status=active'
            return httpClient.get(url).then((res) => {
                if (res.status === 200) {
                    dispatch(setAssayTemps(res.data))
                }
                return res.status;
            }).catch(err => {
                let msg = `获取化验记录模板失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const setAssayers = createAction('@@xcloud/manufacture/setAssayers');

export const setInspectors = createAction('@@xcloud/manufacture/setInspectors');

export const setAssayRecords = createAction('@@xcloud/manufacture/setAssayRecords');

export const setAssayTemps = createAction('@@xcloud/manufacture/setAssayTemps');

export const setCurrentRecord = createAction('@@xcloud/manufacture/setCurrentRecord');

export const setAssayRecordSummary = createAction('@@xcloud/manufacture/setAssayRecordSummary');

export const createAssayRecord = createAction('@@xcloud/manufacture/createAssayRecord',
    (record, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/records', record)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加化验记录成功"));
                }
                callback();
                return res.status;
            }).catch(err => {
                let msg = `增加化验记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateAssayRecord = createAction('@@xcloud/manufacture/updateAssayRecord',
    (record, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/records/' + record.recordId, record)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新化验记录成功"));
                }
                callback();
                return res.status;
            }).catch(err => {
                let msg = `更新化验记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const exportAssayRecords = createAction('@@xcloud/manufacture/exportAssayRecords',
    (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/records/export'
        let strs = []
        if (companyId || dptId || installId || deviceId || assayer || inspector
            || tempId || testTarget || startDate || endDate || status) {
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            if (installId) strs.push('installId=' + installId)
            if (deviceId) strs.push('deviceId=' + deviceId)
            if (assayer) strs.push('assayer=' + assayer)
            if (inspector) strs.push('inspector=' + inspector)
            if (tempId) strs.push('tempId=' + tempId)
            if (testTarget) strs.push('testTarget=' + testTarget)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (status !== '') strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }

        return httpClient
            .get(url, { headers: { need_loading: 'true' } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let tenantId = getState().auth.userProfile.roles[0].tenantId
                        let mybucket = _.replace(res.data.bucketName, '{tenantId}', tenantId);
                        dispatch(download(
                            res.data.docName,
                            mybucket
                        ))
                    }
                }
                return res;
            })
            .catch(err => {
                let msg = `导出化验记录失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return {
                    total: 0,
                    list: []
                };
            })

        // return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
        //     if (res.status === 200) {
        //         dispatch(setAssayRecordSummary(res.data))
        //     }
        //     return res.status;
        // }).catch(err => {
        //     let msg = `获取化验次数列表失败`;
        //     dispatch(createhttpMessage(err.response ? err.response : '', msg));
        //     return []
        // })
    });

export const getAssayRecordSummary = createAction('@@xcloud/manufacture/getAssayRecordSummary',
    (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/recordsummary'
        let strs = []
        if (companyId || dptId || installId || deviceId || assayer || inspector
            || tempId || testTarget || startDate || endDate) {
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            if (installId) strs.push('installId=' + installId)
            if (deviceId) strs.push('deviceId=' + deviceId)
            if (assayer) strs.push('assayer=' + assayer)
            if (inspector) strs.push('inspector=' + inspector)
            if (tempId) strs.push('tempId=' + tempId)
            if (testTarget) strs.push('testTarget=' + testTarget)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            url = url + '?' + strs.join('&')
        }

        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setAssayRecordSummary(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取化验次数列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getAssayRecords = createAction('@@xcloud/manufacture/getAssayRecords',
    (companyId, dptId, installId, deviceId, assayer, inspector, tempId, testTarget, startDate, endDate, status, offset, size, sort) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/records'
        let strs = []
        if (companyId || dptId || installId || deviceId || assayer || inspector
            || tempId || testTarget || startDate || endDate || status || sort) {
            if (companyId) strs.push('companyId=' + companyId)
            if (dptId) strs.push('dptId=' + dptId)
            if (installId) strs.push('installId=' + installId)
            if (deviceId) strs.push('deviceId=' + deviceId)
            if (assayer) strs.push('assayer=' + assayer)
            if (inspector) strs.push('inspector=' + inspector)
            if (tempId) strs.push('tempId=' + tempId)
            if (testTarget) strs.push('testTarget=' + testTarget)
            if (startDate) strs.push('startDate=' + startDate)
            if (endDate) strs.push('endDate=' + endDate)
            if (sort) strs.push('sort=' + sort)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }
        if (offset !== undefined) {
            url += "&offset=" + offset;
        } else {
            url += "&offset=0";
        }
        if (size !== undefined) {
            url += "&size=" + size;
        } else {
            url += "&size=10";
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res.status === 200) {
                dispatch(setAssayRecords(res.data))
            }
            return res.status;
        }).catch(err => {
            let msg = `获取化验记录列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const AssayReducer = handleActions(
    {
        [setAssayRecords]: (state, { payload: data }) => {
            return {
                ...state,
                assayRecords: data
            }
        },
        [setAssayTemps]: (state, { payload: data }) => {
            return {
                ...state,
                assayTemps: data
            }
        },
        [setCurrentRecord]: (state, { payload: data }) => {
            return {
                ...state,
                currentRecord: data
            }
        },
        [setAssayRecordSummary]: (state, { payload: data }) => {
            return {
                ...state,
                assayRecordSummary: data
            }
        },
        [setAssayers]: (state, { payload: data }) => {
            return {
                ...state,
                assayers: data
            }
        },
        [setInspectors]: (state, { payload: data }) => {
            return {
                ...state,
                inspectors: data
            }
        }

    },
    initialState
)

export default AssayReducer
