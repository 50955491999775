import { connect } from 'react-redux';
import { FillDeviceGroupInfo } from '../components/DialogCommon/FillDeviceGroupInfo';
import { cleanDialog } from '../redux/modules/dialog';
import { createDeviceGroupMap } from '../redux/modules/installationRelation';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.installation,
        ...state.installationPlug,
        ...state.devices,
        ...state.trees
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanDialog: () => {
            dispath(cleanDialog());
        },
        createDeviceGroupMap: (value) => {
            dispath(createDeviceGroupMap(value));
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(FillDeviceGroupInfo);

