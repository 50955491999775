import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { formatDate_hms } from '@/utils/datetime';
import { localization, options } from "@/utils/mtable";
import * as _ from 'lodash';

const SharingTakeHistoryComponent = props => {
    const {
        classes,
        open,
        users,
        departments,
        valuations,
        companies,
        histories,
        onhandleClose,
    } = props;

    const getUserName = (userId) => {
        let user = users.find(ele => ele.userId === userId);
        if (user) {
            return user.lastName + user.firstName
        }
        return ''
    }

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companies && companies.length > 0) {
            let value = _.find(companies, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDpt = (dptId) => {
        let label = '';
        let value = _.find(departments, function (o) { return o.dptId === dptId })
        if (value) {
            label = value.dptName
        }
        return label;
    }


    const renderValuationType = (row) => {
        const take = _.find(valuations, { 'code': row.valuationType });
        return take ? take.name : ''
    }

    const getFixedValue = (row) => {
        if (!row.valuationType || row.valuationType === 'FIXED') {
            return row.fixed
        }
        return "-"
    }

    const getPercentageValue = (row) => {
        if (!row.valuationType || row.valuationType === 'PERCENTAGE') {
            return row.percentage + "%";
        }
        return "-"
    }

    const assemblycolumns = () => {
        let columns = [
            {
                field: 'updateTime',
                title: '更新时间',
                width: 150,
                render: row => <Typography>{formatDate_hms(row.updateTime) || ''}</Typography>,
            },
            {
                title: '分厂',
                field: 'takerCompany',
                render: row => <Typography>{renderCompany(row.takerCompany)}</Typography>,
            },
            {
                title: '班组',
                field: 'takerDpt',

                render: row => <Typography>{renderDpt(row.takerDpt)}</Typography>,

            }, {
                title: '分摊方式',
                field: 'valuationType',
                render: row => <Typography>{renderValuationType(row)}</Typography>,

            },
            {
                title: '百分比',
                field: 'percentage',
                render: row => <Typography>{getPercentageValue(row)}</Typography>,
            }, {
                title: '固定费',
                field: 'fixed',
                render: row => <Typography>{getFixedValue(row)}</Typography>,
            },
            {
                field: 'revisor',
                title: '修改人',
                render: row => <Typography>{getUserName(row.revisor)}</Typography>,
            },
        ]
        return columns
    }

    const tableOptions = {
        ...options,
        // toolBar: false,
        pageSize: 8,
        pageSizeOptions: [20, 30]
    }

    const tableLocalization = {
        ...localization,
    }

    const assemblyData = () => {
        return histories
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    {/* <Typography component={'span'} variant='h3'>
                        {'服务名：' + serviceName}</Typography> */}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <Grid className={classes.detailtable}>
                            <MaterialTable
                                title={<Typography variant='h3'>{'分摊历史记录'}</Typography>}
                                columns={assemblycolumns()}
                                data={assemblyData()}
                                options={tableOptions}
                                localization={tableLocalization}
                                style={{
                                    border: '0px solid black',
                                    boxShadow: 'none'
                                }}
                            >
                            </MaterialTable>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

SharingTakeHistoryComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    serviceHistories: PropTypes.array,
    onhandleClose: PropTypes.func,
};

SharingTakeHistoryComponent.defaultProps = {
    serviceHistories: [],
    serviceName: '',
    serviceUnit: '',
    users: []
}

export default withStyles(SharingTakeHistoryComponent);