import { connect } from 'react-redux';
import LeftSildeBar from '../components/LeftSlideBar';
import { createDialog } from '../redux/modules/dialog';
import { getNewTreeDeviceData } from '../redux/modules/deviceTree';
import { addExistDeviceToMap } from '../redux/modules/installationRelation';

const mapStateToProps = (state) => {
    return {
        ...state.device,
        ...state.setting,
        ...state.deviceTree,
        ...state.devices,
    }
}

const mapDispathToProps = (dispath) => {
    return {
        createDialog: (children, parameters, showIcon) => {
            dispath(createDialog(children, parameters, showIcon))
        },
        getNewTreeDeviceData: (index, action) => {
            dispath(getNewTreeDeviceData(index, action))
        },
        addExistDeviceToMap: (value) => {
            dispath(addExistDeviceToMap(value))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(LeftSildeBar);