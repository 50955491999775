import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '@/redux/modules/message';

const porxyApi = '/api'

const initialState = {
    laborers: [],
};

export const setLaborers = createAction('@@xcloud/amoeba/setLaborers');

export const addLaborer = createAction('@@xcloud/amoeba/addLaborer',
    (laborerDto, callback) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/laborers', laborerDto)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "增加劳务人员成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `增加劳务人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateLaborer = createAction('@@xcloud/amoeba/updateLaborer',
    (updateDto, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + '/amoeba/laborers/' + updateDto.laborerId, updateDto)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "更新劳务人员成功"));
                }
                return res.data;
            }).catch(err => {
                let msg = `更新劳务人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delLaborer = createAction('@@xcloud/amoeba/delLaborer',
    (laborerId, callback) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/amoeba/laborers/' + laborerId)
            .then((res) => {
                if (res.status === 200) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "删除劳务人员成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除劳务人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getLaborers = createAction('@@xcloud/amoeba/getLaborers',
    (laborer, laborCompany, projectId) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/laborers'
            let strs = []
            if (laborer || laborCompany || projectId) {
                if (laborer) strs.push('laborer=' + laborer)
                if (laborCompany) strs.push('laborCompany=' + laborCompany)
                if (projectId) strs.push('projectId=' + projectId)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setLaborers(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取劳务人员失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const laborerManageReducer = handleActions(
    {
        [setLaborers]: (state, { payload: data }) => {
            return {
                ...state,
                laborers: data
            }
        },
    },
    initialState
)

export default laborerManageReducer
