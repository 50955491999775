import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    treatmentScheme: {
        schemeId: '',
        acceptResult: '',
        status: ''
    },
    confirmCallback: null,
    closeCallback: null
};

export const openTreatmentSchemeAcceptPannel = createAction('@@xcloud/inspectionSchemeAccept/open',
    (
        confirmCallback, closeCallback, treatmentScheme
    ) => ({
        confirmCallback, closeCallback, treatmentScheme
    })
);

export const closeTreatmentSchemeAcceptPannel = createAction('@@xcloud/inspectionSchemeAccept/close');

export const changeTreatmentScheme = createAction('@@xcloud/inspectionSchemeAccept/changeTreatmentScheme');

// Actions

export const InspectionTreatmentSchemeAcceptReducer = handleActions(
    {
        [openTreatmentSchemeAcceptPannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                confirmCallback: data.confirmCallback,
                closeCallback: data.closeCallback,
                treatmentScheme: data.treatmentScheme ?
                    {
                        schemeId: data.treatmentScheme.schemeId,
                        acceptResult: data.treatmentScheme.acceptResult,
                        status: data.treatmentScheme.status
                    }
                    : {
                        schemeId: '',
                        acceptResult: '',
                        status: ''
                    },
            };
        },
        [closeTreatmentSchemeAcceptPannel]: (state) => {
            return {
                ...state,
                open: false,
                confirmCallback: null,
                closeCallback: null,
            };
        },
        [changeTreatmentScheme]: (state, { payload: data }) => {
            return {
                ...state,
                treatmentScheme: data,
            };
        }
    },
    initialState
);

export default InspectionTreatmentSchemeAcceptReducer;
