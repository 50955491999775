import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from '../message'
import { sortDepreciationPlan } from '@/utils/amoebaUtils';

const porxyApi = '/api'

const initialState = {
    plans: [], //资产折旧年度计划列表
    mainPlans: [], //资产折旧年度计划列表
};

export const setDepreciationPlans = createAction('@@xcloud/amoeba/setDepreciationPlans');
export const setMainDepreciationPlans = createAction('@@xcloud/amoeba/setMainDepreciationPlans');

export const getDepreciationPlans = createAction('@@xcloud/amoeba/getDepreciationPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/depreciationplans'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                let data = [];
                if (res.data && res.data.length > 0) {
                    data = sortDepreciationPlan(res.data);
                }
                dispatch(setDepreciationPlans(data))
                return res.status;
            }
        }).catch(err => {
            let msg = `获取资产折旧年度计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getMainDepreciationPlans = createAction('@@xcloud/amoeba/getMainDepreciationPlans',
    (companys, dpts, year) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/amoeba/depreciationplans'
        let strs = []
        if (companys || dpts || year) {
            if (companys) strs.push('companys=' + companys)
            if (dpts) strs.push('dpts=' + dpts)
            if (year) strs.push('year=' + year)
            url = url + '?' + strs.join('&')
        }
        return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
            if (res) {
                dispatch(setMainDepreciationPlans(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取资产折旧年度计划列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const updateDepreciationPlanDtl = createAction('@@xcloud/amoeba/updateDepreciationPlanDtl',
    (dtlId, newData) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/depreciationplans/dtls/${dtlId}`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新资产折旧年度计划明细成功"));
                }
            }).catch(err => {
                let msg = `更新资产折旧年度计划明细失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateDepreciationPlan = createAction('@@xcloud/amoeba/updateDepreciationPlan',
    (planId, planDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/depreciationplans/${planId}`, planDto)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新资产折旧年度计划成功"));
                }
            }).catch(err => {
                let msg = `更新资产折旧年度计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateDepPlanBatch = createAction('@@xcloud/amoeba/updateDepPlanBatch',
    (planDto, callback) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/depreciationplans/batch`, planDto)
            .then((res) => {
                if (res) {
                    if (callback) {
                        callback();
                    }
                    dispatch(createhttpMessage(res, "更新资产折旧数值成功"));
                }
            }).catch(err => {
                let msg = `更新资产折旧数值失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const addDepreciationPlan = createAction('@@xcloud/amoeba/addDepreciationPlan',
    (newData) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + `/amoeba/depreciationplans`, newData)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "启动资产折旧年度计划成功"));
                    return res.data;
                }
            }).catch(err => {
                let msg = `启动资产折旧年度计划失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });


export const amoebaDepreciationPlanReducer = handleActions(
    {
        [setDepreciationPlans]: (state, { payload: data }) => {
            return {
                ...state,
                plans: data
            }
        },
        [setMainDepreciationPlans]: (state, { payload: data }) => {
            return {
                ...state,
                mainPlans: data
            }
        },
    },
    initialState
)

export default amoebaDepreciationPlanReducer
