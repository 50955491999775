import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    planitemroot: {
        backgroundColor: '#FFFFFFFF',
    },
    title: {
        padding: theme.spacing(1)
    },
    timeline: {
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    infocontainer: {
        padding: theme.spacing(2),
        flexDirection: 'column'
    },
    item: {
        paddingBottom: theme.spacing(2),
        minWidth: 300
    },
    datePicker: {
        marginTop: 0,
        marginBottom: 0
    },
    gridContainer: {
        margin: 10
    },
    dialogCardContent: {
        minHeight: '500px',
        maxHeight: '500px',
        overflowY: 'auto',
    },
    mediumModal: {
        width: '60%',
        minHeight: '50%',
        height: 'auto'
    },
    pic1: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    pic11: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    pic21: {
        // width: theme.spacing(3),
        // height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '6px',
        color: '#FFFFFF'
    },
    action: {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }
});


export default withStyles(styles);