import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SupplierSelectPage from '../components/SupplierSelectPage';
import {
  initSupplierSelect,
  setSearch,
  resetSearch,
  searchSupplierList,
  exitSelected,
  setSelectedSupplier,
  setPageSize
} from '../redux/modules/supplier';
import { cleanmodal } from '../redux/modules/modal';

class SupplierSelectPageContainer extends Component {
  static propTypes = {
    supplierList: PropTypes.object,
    search: PropTypes.object,
    supplierGroupCodes: PropTypes.array,
    parameters: PropTypes.object,
    onhandleSelectChange: PropTypes.func,
    userProfile: PropTypes.object,
    currentUserInfo: PropTypes.object,
  }

  componentWillMount() {
    this.props.onhandleInit();
  }

  handleSelect = (value, field) => {
    let newSearch;
    console.log(value)
    switch (field) {
      case 'supplierGroupCode':
        newSearch = {
          ...this.props.search,
          supplierGroupCode: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'supplierName':
        newSearch = {
          ...this.props.search,
          supplierName: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  handleConfirm = () => {
    if (this.props.parameters.callback) {
      this.props.parameters.callback()
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          // console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };


  render() {
    return (
      <SupplierSelectPage
        search={this.props.search}
        supplierGroupCodes={this.props.supplierGroupCodes}
        onhandleSelectChange={this.handleSelect.bind(this)}
        onhandleChange={this.handleChange.bind(this)}
        onhandleClearSearch={this.props.onhandleClearSearch.bind(this)}
        supplierList={this.props.supplierList}
        onhandleSearch={this.props.onhandleSearch.bind(this)}
        onhandleSetSelectedSupplier={this.props.onhandleSetSelectedSupplier.bind(this)}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        onhandleConfirm={this.handleConfirm.bind(this)}
        pageSize={this.props.pageSize}
        setPageSize={this.props.onSetPageSize.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supplierList: state.supplier.supplierList,
    search: state.supplier.search,
    supplierGroupCodes: state.supplier.supplierGroupCodes,
    parameters: state.modal.parameters,
    userProfile: state.auth.userProfile,
    currentUserInfo: state.auth.currentUserInfo,
    pageSize: state.supplier.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInit: () => {
      dispatch(initSupplierSelect());
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleSearch: (query) => {
      dispatch(searchSupplierList(query));
    },
    onhandleSetSelectedSupplier: (value) => {
      dispatch(setSelectedSupplier(value));
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
    onSetPageSize: (num) => {
      dispatch(setPageSize(num))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierSelectPageContainer);
