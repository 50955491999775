import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage } from './message';
import { getInstallations } from './installation';
import { getDevices } from './devices';
import { getUsersWithCallback } from './user';

const porxyApi = '/api'

const initialState = {
    manipulates: [], //装置运行配置列表
    manipulate: {}, //装置运行配置
    users: [], //用户
};

export const setManipulates = createAction('@@xcloud/manufacture/setManipulates');
export const setManipulate = createAction('@@xcloud/manufacture/setManipulate');
export const setUsers = createAction('@@xcloud/manufacture/setUsers');

export const getManipulates = createAction('@@xcloud/manufacture/getManipulates',
    (companyId, installId, unitId, deviceId, manipulateName, status) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/manipulates'
        let strs = []
        if (companyId || installId || unitId || deviceId || manipulateName || status) {
            if (companyId) strs.push('companyId=' + companyId)
            if (installId) strs.push('installId=' + installId)
            if (unitId) strs.push('unitId=' + unitId)
            if (deviceId) strs.push('deviceId=' + deviceId)
            if (manipulateName) strs.push('manipulateName=' + manipulateName)
            if (status) strs.push('status=' + status)
            url = url + '?' + strs.join('&')
        }

        return httpClient.get(url).then((res) => {
            if (res) {
                dispatch(setManipulates(res.data))
                return res.data
            }
        }).catch(err => {
            let msg = `获取装置运行配置列表失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const getManipulate = createAction('@@xcloud/manufacture/getManipulate',
    (manipulateId) => (dispatch, getState, httpClient) => {
        let url = porxyApi + '/manufacture/manipulates/' + manipulateId
        return httpClient.get(url).then((res) => {
            if (res) {
                return res;
            }
        }).catch(err => {
            let msg = `获取装置运行配置失败`;
            dispatch(createhttpMessage(err.response ? err.response : '', msg));
            return []
        })
    });

export const addManipulate = createAction('@@xcloud/manufacture/addManipulate',
    (manipulate) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/manufacture/manipulates', manipulate)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "增加装置运行配置成功"));
                }
            }).catch(err => {
                let msg = `增加装置运行配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateManipulate = createAction('@@xcloud/manufacture/updateManipulate',
    (manipulateId, manipulate) => (dispatch, getState, httpClient) => {

        return httpClient.patch(porxyApi + '/manufacture/manipulates/' + manipulateId, manipulate)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "更新装置运行配置成功"));
                }
            }).catch(err => {
                let msg = `更新装置运行配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const deleteManipulate = createAction('@@xcloud/manufacture/deleteManipulate',
    (manipulateId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + '/manufacture/manipulates/' + manipulateId)
            .then((res) => {
                if (res) {
                    dispatch(createhttpMessage(res, "删除装置运行配置成功"));
                }
            }).catch(err => {
                let msg = `删除装置运行配置失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getInstallationsInManipulate = createAction('@@xcloud/manufacture/getInstallationsInManipulate',
    (companyId) => (dispatch, getState, httpClient) => {
        const search = { size: 100 };
        if (companyId) {
            search.companyId = companyId;
        }
        dispatch(getInstallations(search))
    });

export const getUnitDeviceList = createAction('@@xcloud/manufacture/getUnitDeviceList',
    (unitId) => (dispatch, getState, httpClient) => {
        let companyId = getState().auth.currentUserInfo.companyId;
        return dispatch(getDevices(null, 0, null, null, null, null, null, null,
            null, companyId, null, null, null, null, unitId, null));
    });

export const getUsersByCompanyId = createAction('@@xcloud/manufacture/getUsersByCompanyId',
    (query) => (dispatch, getState, httpClient) => {
        dispatch(getUsersWithCallback(query, (res) => {
            if (res) {
                dispatch(setUsers(res.list));
            }
        })).then((res) => {

        });
    });

export const manipulateReducer = handleActions(
    {
        [setManipulates]: (state, { payload: data }) => {
            return {
                ...state,
                manipulates: data
            }
        },
        [setManipulate]: (state, { payload: data }) => {
            return {
                ...state,
                manipulate: data,
            }
        },
        [setUsers]: (state, { payload: data }) => {
            return {
                ...state,
                users: data,
            }
        },
    },
    initialState
)

export default manipulateReducer
