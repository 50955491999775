import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';


const styles = theme => ({

    week: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(3)

    },
    weeklink: {
        color: theme.palette.text.secondary,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '&:hover': {
            opacity: '0.6',
        }
    },
    weekcurrent: {

        fontSize: '20px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    mood: {
        fontSize: '48px',
    },
    hello: {
        color: theme.palette.text.secondary,
    },
    summary: {
        color: theme.palette.text.secondary,
        fontSize: '20px'
    },
    detail: {
        paddingRight: theme.spacing(4)
    },
    taskcount: {
        color: theme.palette.text.primary,

    },
    nested: {
        backgroundColor: colors.grey[200],
        marginTop: theme.spacing(0.5)
    },
    listitemicon: {
        minWidth: '24px'
    },
    todobadge: {
        marginRight: theme.spacing(1),
        // color: theme.palette.primary.main,
    },
    donebadge: {
        marginRight: theme.spacing(1),
        // color: theme.palette.success.main,
    }
});


export default withStyles(styles);