import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import withStyles from './styles';
import PropTypes from 'prop-types';
// import * as _ from 'lodash';

const MAnnualQuantityDailog = props => {
    const { classes,
        open,
        serviceRow,
        plan,
        onhandleClose,
        onhandleEditAnnualQuantity,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableStyle = {
        ...style
    }

    const tableOptions = {
        ...options,
        paging: false,
        toolbar: false,
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '服务名称',
                field: 'dtlName',
                align: 'left',
                width: 50,
                editable: 'never',
                render: row => <Typography>{row.dtlName}</Typography>
            },
            {
                title: '数量',
                field: 'output',
                type: 'numeric',
                align: 'left',
                width: 50,
                render: row => <Typography>{row.output ? row.output.toFixed(2) : row.output}</Typography>
            },
            {
                title: '单位',
                field: 'outputUnit',
                width: 50,
                render: row => <Typography>{row.outputUnit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const assemblyData = () => {
        return [serviceRow]
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} className={classes.dialogPage}>
                <DialogTitle>
                    <Typography component={'span'} variant='h2'>{plan.year}年服务计划</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid>
                        <MaterialTable
                            columns={assemblycolumns()}
                            data={assemblyData()}
                            options={tableOptions}
                            localization={tableLocalization}
                            style={tableStyle}
                            cellEditable={{
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => onhandleEditAnnualQuantity(newValue, oldValue, rowData, columnDef)
                            }}
                            title={<Typography variant='h3'>{plan.product}</Typography>}
                        ></MaterialTable>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleClose} color="primary"> 关闭
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MAnnualQuantityDailog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onhandleClose: PropTypes.func,
    plaDtls: PropTypes.any,
    onhandlePopUpUpdate: PropTypes.any,
};

MAnnualQuantityDailog.defaultProps = {
    open: false,
    serviceRow: {},
};

export default withStyles(MAnnualQuantityDailog);