import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import HazardListHeader from "../../../containers/InspectionManagement/HazardList/HazardListHeader";
import HazardListTable from "../../../containers/InspectionManagement/HazardList/HazardListTable";
import { Card } from '@material-ui/core';

const HazardListManagement = props => {

    const { classes, location } = props;
    const isBack = location ? location.state : false;

    let routeType = 'beforeevaluated';
    if (location && location.pathname && location.pathname.indexOf("beforeevaluated") !== -1) {
        routeType = 'beforeevaluated';
    }
    if (location && location.pathname && location.pathname.indexOf("afterevaluated") !== -1) {
        routeType = 'afterevaluated';
    }
    if (location && location.pathname && location.pathname.indexOf("treating") !== -1) {
        routeType = 'treating';
    }
    if (location && location.pathname && location.pathname.indexOf("hisevaluated") !== -1) {
        routeType = 'hisevaluated';
    }

    return (
        <div className={classes.root}>
            <Card className={classes.content}>
                {/* <HazardListHeader /> */}
                <HazardListTable isBack={isBack} routeType={routeType} />
            </Card>
        </div>
    );
};
HazardListManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(HazardListManagement);
