import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem
} from "@material-ui/core";
import * as _ from 'lodash';
import TreeSelect from 'rc-tree-select';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../utils/mtable";
import '../../assets/rc-tree.css';
import '../../assets/rc-tree-select-tree.css';

function AccessorySelectPage(props) {
  const { classes,
    catTree,
    parameters,
    companyTree,
    companyList,
    search,
    accessoryList,
    onhandleSelectChange,
    onhandleChange,
    onhandleClearSearch,
    onhandleSearch,
    onhandleSetSelectedAccessory,
    onhandleExitSelected,
    onhandleConfirm
  } = props;

  const [pageSize] = useState(5);

  const tableHeight = (window.innerHeight - 600) / window.innerHeight * 100;

  const companyDisabled = () => {
    if (parameters.companyId || (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0))) {
      return true
    }

    return false
  }

  const getLocalization = () => {
    let newLocal = _.cloneDeep(localization);
    newLocal.body.emptyDataSourceMessage = '请输入检索条件检索数据';
    return newLocal;
  }

  const checkParameters = () => {
    if (parameters && _.keys(parameters).length > 0) {
      if (_.keys(parameters).length === 1 && (parameters.companyId || parameters.callback)) {
        return false
      } else if (_.keys(parameters).length === 2 && parameters.companyId && parameters.callback) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }

  }

  const statusList = () => {
    return [
      { label: "备用", value: 'new', pcode: null, children: null },
      { label: "在用", value: 'active', pcode: null, children: null },
      { label: "弃用", value: 'inactive', pcode: null, children: null }
    ]
  }

  const isCommonData = () => {
    return [
      { label: "是", value: true, pcode: null, children: null },
      { label: "否", value: false, pcode: null, children: null }
    ]
  }

  const renderStatus = (row) => {
    if (row.status === 'active') {
      return '在用'
    } else if (row.status === 'new') {
      return '备用'
    } else if (row.status === 'inactive') {
      return '弃用'
    } else {
      return ''
    }
  }

  const renderCompany = (row) => {
    let companyName = ''
    if (companyList && companyList.length > 0) {
      let value = _.find(companyList, function (o) { return o.companyId === row.companyId })
      if (value) {
        companyName = value.companyAlias
      }
    }
    return companyName
  }


  return (
    <div className={classes.card}>
      <Grid>
        <Typography variant="h3" gutterBottom>
          {parameters.libSelect ? "备件选择" : "部件选择"}
        </Typography>
      </Grid>
      {checkParameters()
        ?
        <Grid>
          <div className={classes.planitemroot}>
            <Grid container spacing={0} className={classes.gridContainer}>
              {parameters.search
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                      部件名称
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      value={search.search || ''}
                      variant="outlined"
                      size={'small'}
                      placeholder="部件编码，位号，型号，名称"
                      onChange={event => onhandleChange(event, 'search')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.deviceSearch
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                      关联设备
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      value={search.deviceSearch || ''}
                      variant="outlined"
                      size={'small'}
                      placeholder="设备编码，位号，型号，名称"
                      onChange={event => onhandleChange(event, 'deviceSearch')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.cat
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>
                      部件类型
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.select}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.cat}
                      labelInValue
                      treeData={catTree}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'cat')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.isCommon
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>
                      通用部件
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.select}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.isCommon}
                      labelInValue
                      treeData={isCommonData()}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'isCommon')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.company
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      单位
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      id="filled-select-company"
                      select
                      value={search.companyId}
                      onChange={(event) => onhandleSelectChange(event, 'companyId')}
                      variant="outlined"
                      size={'small'}
                      disabled={companyDisabled()}
                    >
                      <MenuItem key={1} value={''}>-</MenuItem>
                      {_.sortBy(companyList, 'companyId').map((option) => (
                        <MenuItem key={option.companyId} value={option.companyId}>
                          {option.companyAlias}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.useStatus
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      使用状态
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.select}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.status}
                      labelInValue
                      treeData={statusList()}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'status')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
            </Grid>
          </div>
          <div>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleSearch}
                >
                  搜索
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleClearSearch}
                >
                  重置
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        : null
      }

      <Grid>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: '单位',
                  field: 'companyId',
                  render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '部件编号',
                  field: 'accNo',
                  render: row => row ? <Typography>{row.accNo}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '部件名称',
                  field: 'accName',
                  render: row => row ? <Typography>{row.accName}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '部件型号',
                  field: 'model',
                  render: row => row ? <Typography>{row.model}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '使用状态',
                  field: 'status',
                  render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '关联设备编号',
                  field: 'deviceNo',
                  render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                  editable: 'never',
                  hidden: Boolean(parameters.libSelect)
                },
                {
                  title: '关联设备名称',
                  field: 'deviceName',
                  render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                  editable: 'never',
                  hidden: Boolean(parameters.libSelect)
                }]}
              data={accessoryList}
              options={{
                ...options,
                pageSize: pageSize,
                // maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
                // tableLayout: 'auto',
                actionsColumnIndex: -1,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                selection: true
              }}
              style={{
                ...style,
                border: '0px solid black',
                boxShadow: 'none'
              }}
              localization={getLocalization()}
              onSelectionChange={(rows) => onhandleSetSelectedAccessory(rows)}
            >
            </MaterialTable>
          </Grid>
        </Grid>
      </Grid>


      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Button variant="outlined" color="primary" style={{ marginRight: 16 }}
          onClick={event => onhandleExitSelected()}
        >
          取消
        </Button>
        <Button variant="contained" color="primary"
          onClick={event => onhandleConfirm()}
        >
          确定
        </Button>
      </Grid>
    </div >
  );
}

AccessorySelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  accessoryList: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  catTree: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  onhandleSetSelectedAccessory: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
};

export default withStyles(AccessorySelectPage);
