import React, { Component } from 'react';
import { connect } from 'react-redux';
import OppoInfo from '../../../components/Crm/Oppo/OppoInfo';
import { createMessage } from '../../../redux/modules/message';
import { getNowTimestamp } from "../../../utils/datetime";
import { cleanmodal, createmodal } from '../../../redux/modules/modal';
import { upload, download } from '../../../redux/modules/minio';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { getUsers } from '@/redux/modules/user';
import {
    createOppo,
    updateOppo,
    setCurrentOppo,
    setOppoChange,
    initOppoInfo,
    setIsEditable,
    backToList
} from "../../../redux/modules/customerOppo";
import {
    createCustomerDoc, changeCustomerDocStatus
} from "../../../redux/modules/customerDoc";
import * as _ from 'lodash';
import PropTypes from "prop-types";


class OppoEditContainer extends Component {

    static propTypes = {
        currentOppo: PropTypes.object,
        oppoError: PropTypes.object,
        constants: PropTypes.object,
        oppoChange: PropTypes.bool,
        customerList: PropTypes.array,
        userProfile: PropTypes.object,
        isEditable: PropTypes.bool,
        statusList: PropTypes.array,
    };

    componentWillMount() {
        this.props.getUsers({
            offset: 0,
            size: 0
        });
        this.props.onhandleInitOppoInfo();
    }

    getBucketName() {
        const bucketName = _.replace('dev.{tenant_id}.device.devicedoc', '{tenant_id}', this.props.userProfile.roles[0].tenantId);
        return bucketName;
    }

    checkFrequency() {
        let currentOppo = this.props.currentOppo;
        if (!currentOppo.oppoName) {
            //机会名称
            this.props.onhandleCheck('请输入机会名称')
            return true
        }
        if (!currentOppo.cid) {
            //机会名称
            this.props.onhandleCheck('请选择客户')
            return true
        }
        if (_.toInteger(currentOppo.expectedAmount) <= 0) {
            this.props.onhandleCheck('预期金额必须大于0')
            return true
        }
        if (!currentOppo.oppoTime) {
            this.props.onhandleCheck('请输入发现时间')
            return true
        }
        if (!currentOppo.expiredDate) {
            this.props.onhandleCheck('请输入过期时间')
            return true
        }
        if (!currentOppo.developer) {
            this.props.onhandleCheck('请选择发现人')
            return true
        }
        if (_.toInteger(currentOppo.possibility) < 0 || _.toInteger(currentOppo.possibility) > 100) {
            this.props.onhandleCheck('可能性范围必须0-100')
            return true
        }
        return false
    }

    handleSave(isEditable) {
        if (!isEditable) {
            this.props.onhandleEditableChange(true);
            return;
        }
        let currentOppo = this.props.currentOppo
        if (this.checkFrequency()) {
            return
        }
        if (this.props.currentOppo.oid) {
            this.props.onhandleUpdateOppo(currentOppo);
        } else {
            this.props.onhandleCreateOppo();
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleOppoChange(true);
        let newOppo;
        switch (field) {
            case 'oppoName':
                newOppo = {
                    ...this.props.currentOppo,
                    oppoName: event.target.value
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'oid':
                newOppo = {
                    ...this.props.currentOppo,
                    oid: event.target.value
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'cid':
                newOppo = {
                    ...this.props.currentOppo,
                    cid: event ? event.cid : null
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'developer':
                newOppo = {
                    ...this.props.currentOppo,
                    developer: event ? event.userId : null
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'source':
                newOppo = {
                    ...this.props.currentOppo,
                    source: event.target.value
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'possibility':
                newOppo = {
                    ...this.props.currentOppo,
                    possibility: _.toNumber(event.target.value)
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'expectedAmount':
                newOppo = {
                    ...this.props.currentOppo,
                    expectedAmount: _.toNumber(event.target.value)
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'product':
                newOppo = {
                    ...this.props.currentOppo,
                    product: event.target.value
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;

            case 'opentext':
                newOppo = {
                    ...this.props.currentOppo,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'oppoTime':
                newOppo = {
                    ...this.props.currentOppo,
                    oppoTime: event
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            case 'expiredDate':
                newOppo = {
                    ...this.props.currentOppo,
                    expiredDate: event
                };
                this.props.onhandleUpdateEditOppo(newOppo);
                break;
            default:
            // do nothing
        }
    };

    // eslint-disable-next-line class-methods-use-this
    onFileSelected(files) {
        // 返回文件对象（或文件对象列表）时，则组件进入上传就绪状态
        let arr = files[0].name.split('.')
        let fileType = '.' + arr[arr.length - 1]
        let fileTypes = '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg'.split(',')
        let index = _.findIndex(fileTypes, function (o) { return o === fileType; });
        if (index !== -1) {
            return files;
        } else {
            this.props.onhandleUploadPre()
        }
    }

    handleDownload(docLink) {
        this.props.onhandleDownload(docLink, this.getBucketName());
    }

    handleDeleteDoc = async (rowData) => {
        let changeStatus = () => {
            let mybucket = this.getBucketName()
            this.props.onhandleChangeStatus(rowData, mybucket);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除文档[' + rowData.docName + ']吗?', changeStatus, null, false);
    }

    onConfirm(files, isUploadSetter, values) {
        isUploadSetter(true);
        // 开始自定义上传操作

        let offset = getNowTimestamp();
        console.log('this.props.currentOppo', this.props.currentOppo);
        this.props.onhandleUploadDoc(files, this.getBucketName(), offset, () => {
            let doc = {
                cid: this.props.currentOppo.cid,
                oid: this.props.currentOppo.oid,
                docName: files[0].name,
                docLink: offset + files[0].name,
                status: '1',
                actId: 28
            };
            this.props.onhandleCreateDoc(doc);
            this.props.onhandleModalClose();
        });
    }

    openUploadModal() {
        // 调用上传组件示例
        this.props.onhandleOpenFileUpload({
            // 上传组件初始化配置
            title: '上传文件',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docs',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: true,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.onFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.onConfirm.bind(this),
        });
    }


    handleBack = (event) => {
        this.props.onhandleBackToOppoList();
    };

    onEdit = (event) => {
        this.props.onhandleEditableChange(true);
    };


    render() {
        return (
            <OppoInfo
                onhandleSave={this.handleSave.bind(this)}
                currentOppo={this.props.currentOppo}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                oppoError={this.props.oppoError}
                oppoChange={this.props.oppoChange}
                isEditable={this.props.isEditable}
                constants={this.props.constants}
                customerList={this.props.customerList}
                users={this.props.users}
                userProfile={this.props.userProfile}
                onDownLoadDoc={this.handleDownload.bind(this)}
                onhandleDeleteDoc={this.handleDeleteDoc.bind(this)}
                onOpenUploadModal={this.openUploadModal.bind(this)}
                onEdit={this.onEdit.bind(this)}
                statusList={this.props.statusList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentOppo: state.customerOppo.currentOppo,
        oppoError: state.customerOppo.oppoError,
        oppoChange: state.customerOppo.oppoChange,
        isEditable: state.customerOppo.isEditable,
        constants: state.constant.constants,
        customerList: state.customerOppo.customerList,
        users: state.user.users.list,
        userProfile: state.auth.userProfile,
        statusList: state.customerOppo.statusList,
        currentUserInfo: state.auth.currentUserInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitOppoInfo: (query, reload) => {
            dispatch(initOppoInfo());
        },
        onhandleCreateOppo: (entity) => {
            dispatch(createOppo(entity))
        },
        onhandleUpdateOppo: (entity) => {
            dispatch(updateOppo(entity))
        },
        onhandleUpdateEditOppo: (entity) => {
            dispatch(setCurrentOppo(entity))
        },
        onhandleBackToOppoList: () => {
            dispatch(backToList())
        },
        onhandleOppoChange: (isChanged) => {
            dispatch(setOppoChange(isChanged))
        },
        onhandleEditableChange: (isEditable) => {
            dispatch(setIsEditable(isEditable))
        },
        onhandleCheck: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleUploadDoc: (files, mybucket, offset, callback) => {
            dispatch(upload(files, mybucket, offset, callback))
        },
        onhandleModalClose: () => {
            dispatch(cleanmodal());
        },
        onhandleCreateDoc: (doc) => {
            dispatch(createCustomerDoc(doc));
        },
        onhandleDownload: (docName, mybucket) => {
            dispatch(download(docName, mybucket))
        },
        onhandleUploadPre: () => {
            dispatch(createMessage('error', '上传的文件格式不支持'));
        },
        onhandleChangeStatus: (entity, mybucket) => {
            dispatch(changeCustomerDocStatus(entity, mybucket));
        },
        getUsers: (query) => {
            dispatch(getUsers(query));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OppoEditContainer)
