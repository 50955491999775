import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem
} from "@material-ui/core";
import * as _ from 'lodash';
import TreeSelect from 'rc-tree-select';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../utils/mtable";
import '../../assets/rc-tree.css';
import '../../assets/rc-tree-select-tree.css';

function DeviceSelectPage(props) {
  const { classes,
    catTree,
    catList,
    useStatusTree,
    procatTree,
    procatList,
    parameters,
    companyTree,
    companyList,
    search,
    deviceList,
    installationList,
    onhandleSelectChange,
    onhandleChange,
    onhandleClearSearch,
    onhandleSearch,
    onhandleSetSelectedDevices,
    onhandleExitSelected,
    onhandleConfirm
  } = props;

  // const [pageSize, setpageSize] = useState(5);
  const tableHeight = (window.innerHeight - 600) / window.innerHeight * 100;

  const companyDisabled = () => {
    if (parameters.companyId || (companyTree.length === 1 && (!companyTree[0].children || companyTree[0].children.length === 0))) {
      return true
    }

    return false
  }

  // const installOptions = () => (
  //   <React.Fragment>
  //     {installationList ?
  //       installationList.map(data => (
  //         <option value={data.installId} key={data.installId}>{data.installName}</option>
  //       )) : null
  //     }
  //   </React.Fragment>
  // )

  const getLocalization = () => {
    let newLocal = _.cloneDeep(localization);
    newLocal.body.emptyDataSourceMessage = '请输入检索条件检索数据';
    return newLocal;
  }

  const checkParameters = () => {
    if (parameters && _.keys(parameters).length > 0) {
      if (_.keys(parameters).length === 1 && (parameters.companyId || parameters.callback)) {
        return false
      } else if (_.keys(parameters).length === 2 && parameters.companyId && parameters.callback) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }

  }
  const renderCompany = (device) => {
    let companyName = ''
    if (companyList && companyList.length > 0) {
      let value = _.find(companyList, function (o) { return o.companyId === device.companyId })
      if (value) {
        companyName = value.companyAlias
      }
    }
    return companyName
  }

  const renderProCat = (device) => {
    if (procatList && procatList.length > 0 && device.proCat) {
      let cat = _.find(procatList, { 'code': device.proCat });
      return cat.name ? cat.name : "";
    } else {
      return ""
    }
  }

  const renderCat = (device) => {
    if (catList && catList.length > 0 && device.cat) {
      let cat = _.find(catList, { 'code': device.cat });
      return cat.name ? cat.name : "";
    } else {
      return ""
    }
  }

  const renderIntallUnit = (device) => {

    if (device.installTitle) {
      if (device.unitName) {
        return device.installTitle + '->' + device.unitName
      }
      return device.installTitle
    }

    return ''
  }

  return (
    <div className={classes.card}>
      <Grid>
        <Typography variant="h3">
          {"设备选择"}
        </Typography>
      </Grid>
      {checkParameters()
        ?
        <Grid>
          <div className={classes.planitemroot}>
            <Grid container spacing={0} className={classes.gridContainer}>
              {parameters.search
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography color='textPrimary' className={classes.label}>
                      设备名称
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      value={search.search || ''}
                      variant="outlined"
                      size='small'
                      placeholder="设备编码，位号，型号，名称"
                      onChange={event => onhandleChange(event, 'search')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.cat
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      设备类型
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.textField}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.cat}
                      labelInValue
                      treeData={catTree}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'cat')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.procat
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      设备专业类别
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.textField}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.procat}
                      labelInValue
                      treeData={procatTree}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'procat')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.company
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      单&emsp;&emsp;位
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="filled-select-company"
                      select
                      value={search.companyId}
                      onChange={(event) => onhandleSelectChange(event, 'companyId')}
                      variant="outlined"
                      size='small'
                      disabled={companyDisabled()}
                    >
                      <MenuItem key={1} value={''}>-</MenuItem>
                      {_.sortBy(companyList, 'companyId').map((option) => (
                        <MenuItem key={option.companyId} value={option.companyId}>
                          {option.companyAlias}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.company && parameters.install
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      所属装置
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      select
                      variant="outlined"
                      size='small'
                      value={search.installId}
                      onChange={(event) => onhandleSelectChange(event, 'installId')}
                    >
                      <MenuItem key={1} value={''}>-</MenuItem>
                      {installationList ?
                        installationList.map(data => (
                          <MenuItem value={data.installId} key={data.installId}>{data.installName}</MenuItem>
                        )) : null
                      }
                    </TextField>
                  </Grid>
                </React.Fragment>
                : null
              }
              {parameters.useStatus
                ?
                <React.Fragment>
                  <Grid item xs={1}>
                    <Typography className={classes.label}>
                      设备使用状态
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TreeSelect
                      className={classes.textField}
                      transitionName="rc-tree-select-dropdown-slide-up"
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                      allowClear
                      treeLine
                      value={search.useStatus}
                      labelInValue
                      treeData={useStatusTree}
                      treeNodeFilterProp="label"
                      filterTreeNode={false}
                      onChange={(value) => onhandleSelectChange(value, 'useStatus')}
                    />
                  </Grid>
                </React.Fragment>
                : null
              }
            </Grid>
          </div>
          <div>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleSearch}
                >
                  搜索
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleClearSearch}
                >
                  重置
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        : null
      }

      <Grid>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: '单位',
                  field: 'companyId',
                  width: '90px',
                  render: row => row ? <Typography>{renderCompany(row)}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '设备编号',
                  field: 'deviceNo',
                  width: '120px',
                  render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '设备名称',
                  field: 'deviceName',
                  render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '设备位号',
                  field: 'tagNo',
                  render: row => row ? <Typography>{row.tagNo}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '类别',
                  field: 'cat',
                  render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '专业类别',
                  field: 'proCat',
                  render: row => row ? <Typography>{renderProCat(row)}</Typography> : '',
                  editable: 'never'
                }, {
                  title: '装置单元',
                  field: 'installTitle',
                  render: row => row ? <Typography>{renderIntallUnit(row)}</Typography> : '',
                  editable: 'never'
                },]}
              data={deviceList.list}
              options={{
                ...options,
                pageSize: 5,
                // maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
                // tableLayout: 'auto',
                actionsColumnIndex: -1,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                selection: true
              }}
              style={{
                ...style,
                border: '0px solid black',
                boxShadow: 'none'
              }}
              localization={getLocalization()}
              onSelectionChange={(rows) => onhandleSetSelectedDevices(rows)}
            >
            </MaterialTable>
          </Grid>
        </Grid>
      </Grid>


      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Button variant="outlined" color="primary" style={{ marginRight: 16 }}
          onClick={event => onhandleExitSelected()}
        >
          取消
        </Button>
        <Button variant="contained" color="primary"
          onClick={event => onhandleConfirm()}
        >
          确定
        </Button>
      </Grid>
    </div >
  );
}

DeviceSelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceList: PropTypes.object.isRequired,
  installationList: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  catTree: PropTypes.array.isRequired,
  useStatusTree: PropTypes.array.isRequired,
  procatTree: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  companyTree: PropTypes.array.isRequired,
  companyList: PropTypes.array.isRequired,
  onhandleSetSelectedDevices: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
};

export default withStyles(DeviceSelectPage);
