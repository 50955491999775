import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createloading, cleanloading } from '@/redux/modules/loading';
import { getMoment, formatMonth, formatDate } from '@/utils/datetime';
import {
    getServicePlanConfigs
    , createServicePlanConfig
    , updateServicePlanConfig
    , deleteServicePlanConfig
    , setOpenTempPlanConfig
    , initServicePlanConfig

} from '@/redux/modules/manufacture/servicePlanConfig';
import { createActionMessage } from '@/redux/modules/message'
import * as _ from 'lodash';
import { filterAmoebaCompany } from '@/utils/constant';
import ServiceTempPlanConfigComponent from '@/components/ManufactureManagement/ManufacturePlan/ServiceTempPlanConfigComponent';

const filterService = (services, myCompanyId) => {

    return _.reject(services, s => {
        return s.valuationType === 'BUDGET' || (s.valuationType === 'FIXED' && s.serviceCompany === myCompanyId)

    })

}

class ServiceTempPlanConfigContainer extends Component {
    static propTypes = {
        plans: PropTypes.any,
        companies: PropTypes.any,
        companyId: PropTypes.number,
        plan: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            editingConfig: {},
            editingConfigs: [],
        }
    }

    componentDidMount() {
        const { currentUserInfo, companies, onhandlInitServicePlanConfig } = this.props;
        let companyId = currentUserInfo.companyId
        let myCompany = _.find(companies, { 'companyId': currentUserInfo.companyId })
        if (!myCompany.pid) {
            companyId = null
        }
        onhandlInitServicePlanConfig(companyId).then(res => {

            let id = 0;
            let editingConfigs = _.map(this.props.servciePlanConfigs, c => {
                ++id
                return { ...c, 'id': id }
            })
            this.setState({
                editingConfigs: editingConfigs,
                companyId: companyId,
            })
        })


    }

    handleDateChange = (date) => {
        const newYear = formatMonth(date)
        this.setState({
            selectedDate: date,
            year: newYear
        })
        this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, newYear, this.getMainCompany(this.props.companyId))
    }


    getpid = (companyId) => {
        const company = this.props.companies.find(item => {
            return item.companyId === companyId
        });
        return company.pid === null ? null : company.companyId
    }

    getMainCompany = (companyId) => {
        const company = this.props.companies.find(item => {
            return item.companyId === companyId
        });
        return company.pid
    }



    handleDeletePlan = (plan) => {
        return this.props.onhandleDeletePlan(plan.planId).then(
            (res) => this.props.handleSelect(this.getpid(this.props.companyId), this.state.tabValue, this.state.year, this.getMainCompany(this.props.companyId))
        );
    }

    getCompanyId = () => {
        const company = this.props.companies.find(item => {
            return item.companyId === this.props.companyId
        });
        return company.pid === null ? null : company.companyId
    }

    handleAddOrder = (rowData) => {
        this.props.createloading();
        if (rowData.manufacturePlanDtls && rowData.manufacturePlanDtls[0].dtlId) {
            const company = this.props.companies.find(item => {
                return item.companyId === this.props.companyId
            })
            const dpts = this.props.departments.filter(item => {
                return item.companyId === this.props.companyId && item.amoebaType === 'PROFIT'
            }).map(item => { return item.dptId })
            let newData = {};
            newData['startTime'] = formatDate(getMoment()())
            newData['companyId'] = this.getCompanyId();
            newData['requireCompany'] = company.pid ? company.pid : company.companyId
            newData['department'] = dpts.join(',')
            newData.planId = rowData.planId
            newData['flg'] = 1;
            return this.props.onhandleGenerateOrder(newData).then(
                () => this.props.cleanloading()
            )
        } else {
            this.props.createActionMessage('warning', '该计划没有明细计划')
            return Promise.resolve(true).then(
                () => this.props.cleanloading()
            )
        }
    }

    handleOpenDetail = (plan, actionType) => {

        const { currentUserInfo } = this.props
        let newPlan = {}
        if (actionType === 'NEW') {
            //新建计划
            newPlan = {
                companyId: currentUserInfo.companyId,
                planName: '',
                planType: 'TEMP',
                startTime: getMoment()(this.state.selectedDate).startOf('month'),
                endTime: getMoment()(this.state.selectedDate).endOf('month'),
                status: 'active',
                manufacturePlanDtls: []
            }
        } else {
            newPlan = { ...plan }
        }
        this.props.onhandleOpenTempPlan(newPlan)
    }

    handlePlanChange = (event) => {

        let newPlan = {
            ...this.state.plan,
        }
        if (event.target.name === 'companyId') {
            newPlan[event.target.name] = Number(event.target.value)
        } else {
            newPlan[event.target.name] = event.target.value
        }
        this.setState({ plan: newPlan })
    }

    handlePlanDtlChange = (planDtl) => {
        let newDtls = []
        for (let dtl of this.state.plan.manufacturePlanDtls) {
            if (dtl.dtlId === planDtl.dtlId || (dtl.tempId === planDtl.tempId)) {
                dtl = { ...planDtl }
            }
            newDtls.push(dtl)
        }
        this.setState({
            plan: {
                ...this.state.plan,
                manufacturePlanDtls: newDtls
            }
        })
    }

    handleClose = () => {
        this.props.onhandleCloseTempPlanConfig()
    }

    refresh = async () => {

        await this.props.onhandleGetServicePlanConfigs(this.state.companyId)

        let id = 0;
        let editingConfigs = _.map(this.props.servciePlanConfigs, c => {
            ++id
            return { ...c, 'id': id }
        })
        this.setState({
            editingConfigs: editingConfigs,
        })
    }
    handleChange = async (row, type) => {

        const { onhandleUpdateServicePlanConfig, onhandleCreateServicePlanConfig, onhandleDeleteServicePlanConfig } = this.props

        if (type === 'add') {
            let configs = _.cloneDeep(this.state.editingConfigs)
            configs.push(row)
            this.setState({ editingConfigs: configs })
        } else if (type === 'update') {

        } else if (type === 'save') {
            if (row.configId) { //update
                await onhandleUpdateServicePlanConfig(row)
                this.refresh()

            } else { //add
                row['companyId'] = this.state.companyId
                await onhandleCreateServicePlanConfig(row)
                this.refresh()
            }
        } else if (type === 'cancel') {
            let configs = _.cloneDeep(this.state.editingConfigs)
            if (row.configId) {
                let config = _.find(configs, c => { return c.id === row.id })
                config.editing = false
            } else {
                configs = _.remove(configs, c => { return c.id === row.id })
            }

            this.setState({ editingConfigs: configs })
        } else if (type === 'delete') {
            await onhandleDeleteServicePlanConfig(row.configId)
            this.refresh()
        }

    }

    render() {
        return (
            <div>
                <ServiceTempPlanConfigComponent
                    editingConfigs={this.state.editingConfigs}
                    companies={this.props.companies}
                    currentUserInfo={this.props.currentUserInfo}
                    departments={this.props.departments}
                    openFlag={this.props.open}
                    services={this.props.services}
                    manufactureDicts={this.props.manufactureDicts}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.servicePlanConfig.openTempPlanConfig,
        servciePlanConfigs: state.servicePlanConfig.servicePlanConfigs,
        companies: filterAmoebaCompany(state.company.companyList.list),
        currentUserInfo: state.auth.currentUserInfo,
        services: filterService(state.manufactureService.services, state.auth.currentUserInfo.companyId),
        departments: state.department.departments.list,
        manufactureDicts: state.constant.manufactureDicts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandlInitServicePlanConfig: (companyId) => {
            return dispatch(initServicePlanConfig(companyId));
        },
        onhandleGetServicePlanConfigs: (companyId) => {
            return dispatch(getServicePlanConfigs(companyId, null, 'TEMP', null, null));
        },
        onhandleCreateServicePlanConfig: (config) => {
            return dispatch(createServicePlanConfig(config));
        },
        onhandleDeleteServicePlanConfig: (configId) => {
            return dispatch(deleteServicePlanConfig(configId));
        },
        onhandleUpdateServicePlanConfig: (config) => {
            return dispatch(updateServicePlanConfig(config));
        },
        onhandleCloseTempPlanConfig: () => {
            return dispatch(setOpenTempPlanConfig(false));
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceTempPlanConfigContainer);
