import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/EnergyPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDepartmentsByRole } from "@/utils/userUtils";
import * as _ from 'lodash';

const NewEnergyPricingComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        onhandleRuleUpdate,
        onhandleCompanyChange,
        serviceBudgets,
        currentUserInfo,
        department,
        userProfile,
        onhandleShowHistory,
        onhandleShowHistoryWD,
        onhandleShowHistoryN,
        roleFlag,
    } = props;

    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,
    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }

    const energyassemblyDetailColumns = () => {
        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '石油液化气单耗',
                field: 'ratio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.ratio}</Typography>,
            },
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>立方/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistory(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '风单耗',
                field: 'wdRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.wdRatio}</Typography>,
            },
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>立方/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryWD(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '氮气单耗',
                field: 'nRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.nRatio}</Typography>,
            },
            {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 50,
                render: row => <Typography>立方/{row.unit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const energyassemblyData = () => {
        const lBudgets = _.filter(serviceBudgets, { 'subAlias': '石油液化气' })
        const wdBudgets = _.filter(serviceBudgets, { 'subAlias': '风' })
        const nBudgets = _.filter(serviceBudgets, { 'subAlias': '氮气' })
        _.forEach(lBudgets, value => {
            _.forEach(wdBudgets, wd => {
                if (value.serviceId === wd.serviceId) {
                    value.wdBudgetId = wd.budgetId
                    value.wdVariableUnitPrice = wd.variableUnitPrice
                    value.wdRatio = wd.ratio
                    value.wdVariableSub = wd.variableSub
                }
            })
            _.forEach(nBudgets, n => {
                if (value.serviceId === n.serviceId) {
                    value.nBudgetId = n.budgetId
                    value.nVariableUnitPrice = n.variableUnitPrice
                    value.nRatio = n.ratio
                    value.nVariableSub = n.variableSub
                }
            })
        })
        return lBudgets
    }

    const checkDpt = () => {
        const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
        return dptId === department.dptId || roleFlag ? true : false;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item className={classes.processLine}>
                    </Grid>
                    <Grid>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            columns={energyassemblyDetailColumns()}
                            data={energyassemblyData()}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={detailTableStyle}
                            editable={checkDpt() ? {
                                onRowUpdate: (newData, oldData) => onhandleRuleUpdate(newData, oldData)
                            } : {}}
                            actions={
                                [
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '电费修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistory(event, rowData)
                                    //     }
                                    // },
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '水费修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistoryW(event, rowData)
                                    //     }
                                    // },
                                    {
                                        icon: () => { return <HistoryIcon /> },
                                        tooltip: '蒸汽费修改历史',
                                        onClick: (event, rowData) => {
                                            onhandleShowHistoryN(event, rowData)
                                        }
                                    },
                                ]
                            }
                            title={<Typography variant='h3'>能耗预算规则</Typography>}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

NewEnergyPricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    subCompanyList: PropTypes.array,
    varFeeRuleList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,

};

NewEnergyPricingComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(NewEnergyPricingComponent);