import { connect } from 'react-redux';
import ContentMenu from '../components/ContentMenuCommon';
import { cleanContentMenu } from '../redux/modules/contentmenu';
import { createDialog, cleanDialog } from '../redux/modules/dialog';
import { createMessage } from '../../../redux/modules/message';
import { deleteDeviceMap, updMapChanged, deleteUnitMap, deleteAreaMap, removeFromGroup } from '../redux/modules/installationRelation';

const mapStateToProps = (state) => {
    return {
        ...state.contentmenu,
        dialog: state.dialog,
        ...state.installationRelation,
        ...state.devices,
        ...state.installation,
        ...state.installationPlug
    }
}

const mapDispathToProps = (dispath) => {
    return {
        cleanContentMenu: () => {
            dispath(cleanContentMenu());
        },
        createDialog: (children, parameters, showIcon) => {
            dispath(createDialog(children, parameters, showIcon))
        },
        cleanDialog: () => {
            dispath(cleanDialog());
        },
        createMessage: (type, message) => {
            dispath(createMessage(type, message));
        },
        deleteDeviceMap: (value) => {
            dispath(deleteDeviceMap(value));
        },
        updMapChanged: (value) => {
            dispath(updMapChanged(value));
        },
        deleteUnitMap: (value) => {
            dispath(deleteUnitMap(value));
        },
        deleteAreaMap: (value) => {
            dispath(deleteAreaMap(value));
        },
        removeFromGroup: (value) => {
            dispath(removeFromGroup(value));
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ContentMenu);
