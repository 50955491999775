import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
  pending: {
    width: 60,
  },
  passed: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main
  },
  denied: {
    width: 60,
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main
  },
  none: {
    width: 60,
    color: theme.palette.black
  },
  card: {
    width: '100%',
    padding: 20
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    margin: 10
  },
  gridContainerSub: {
    margin: "2px 10px"
  },
  subAlign: {
    textAlign: 'end'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alignItem: {
    textAlign: 'center'
  },
  title: {
    backgroundColor: theme.palette.title,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '0 24px 0 24px',
    height: '50px',
    // textAlign: 'center'
  },
  expandable: {
    cursor: 'pointer'
  },
  progressMain: {
    height: 10,
    borderRadius: 5,
    // color: 'gray'
  },
  progressSub: {
    height: 6,
    borderRadius: 2
  },
  progressBarColor: {
    backgroundColor: theme.palette.success.main
  },
  progressBarBackgroundColor: {
    backgroundColor: colors.green[200]
  },
  progressBarFailColor: {
    backgroundColor: theme.palette.error.main
  },
  progressBarFailBackgroundColor: {
    backgroundColor: colors.red[200]
  },
  icon: {
    color: theme.palette.primary.main
  },
  iconRotate: {
    transform: 'rotate(180deg)'
  },
  detail: {
    padding: '0 24px 0 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.divider
    }
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    display: 'block',
    textAlign: 'center'
  }
});

export default withStyles(styles);
