import { createAction, handleActions } from 'redux-actions';
import { Client } from "@stomp/stompjs";
import * as _ from 'lodash';
import { push } from 'connected-react-router';
import { getClient, setClient, deleteClient } from '../../utils/websocket'

const defaultState = {
    messages: new Map(),
    openNotification: false
};

export const confirmMessage = createAction('@@xcloud/websocket/confirmMessage',
    (id) => (dispatch, getState) => {
        let messages = getState().websocket.messages;
        let message = messages.get(id)
        if (message) {
            let msg = JSON.parse(message.body)
            message.ack()
            messages.delete(id)
            if (msg.path && msg.path !== "") {
                if (msg.keyId && msg.keyId !== 0) {
                    if (msg.path.substring(msg.path.length - 1) !== '/') {
                        dispatch(push(msg.path + '/' + msg.keyId));
                    } else {
                        dispatch(push(msg.path + msg.keyId));
                    }
                } else {
                    dispatch(push(msg.path));
                }
            }
        }
    });

export const clearMessage = createAction('@@xcloud/websocket/clearMessage',
    (id) => (dispatch, getState) => {
        let messages = getState().websocket.messages;
        let message = messages.get(id)
        if (message) {
            message.ack()
            messages.delete(id)
        }
        let newMessages = _.clone(messages);
        dispatch(setMessages(newMessages));
    });

export const clearClient = createAction('@@xcloud/websocket/clearClient',
    () => (dispatch, getState) => {

        let client = getClient("notice_socket")
        if (client !== undefined) {
            console.log("Websocket deactivated")
            client.deactivate()
            deleteClient("notice_socket")
        }

    });
export const initClient = createAction('@@xcloud/websocket/initClient',
    () => (dispatch, getState) => {

        let client = getClient("notice_socket")
        if (client !== undefined) {
            return;
        }

        dispatch(setMessages(new Map()))
        let user = getState().auth.userProfile;
        let wsconfig = {}
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        if (envCode === 'prod') {
            wsconfig = {
                brokerURL: "wss://iixcloud.com/ws",
                connectHeaders: {
                    login: "webclient",
                    passcode: "dpP0ywBh3Pt5saSR"
                },
                reconnectDelay: 10000,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000
            }
        } else if (envCode === 'uat') {
            wsconfig = {
                brokerURL: "ws://39.100.159.87:15674/ws",
                connectHeaders: {
                    login: "webclient",
                    passcode: "xcloud_2020"
                },
                debug: function (str) {
                    // console.log(str);
                },
                reconnectDelay: 10000,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000
            }
        } else {
            wsconfig = {
                brokerURL: "ws://39.100.159.87:15674/ws",
                connectHeaders: {
                    login: "webclient",
                    passcode: "xcloud_2020"
                },
                debug: function (str) {
                    // console.log(str);
                },
                reconnectDelay: 10000,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000
            }
        }

        const socketClient = new Client(wsconfig);

        socketClient.onConnect = function (frame) {
            console.log("Websocket established")
            let headers = { ack: 'client-individual' };
            socketClient.subscribe("/queue/notice.queue." + user.userId, (message) => {
                if (message.body) {
                    let ctime = new Date().getTime();
                    let jsobData;
                    try {
                        // console.log(message.body)
                        jsobData = JSON.parse(message.body);
                        if (jsobData.id === undefined) {
                            console.warn("got an invalid message");
                            message.ack()
                        } else if (jsobData.expiredTime !== undefined && ctime > jsobData.expiredTime) {
                            console.log("exprired message auto confirmed")
                            message.ack();
                        } else {
                            let newMessages = _.clone(getState().websocket.messages);
                            newMessages.set(jsobData.id, message)
                            dispatch(setMessages(newMessages))
                        }
                    } catch (e) {
                        console.warn("got an invalid message");
                        message.ack()
                    }

                } else {
                    console.warn("got an empty message");
                }
            }, headers);

        };

        socketClient.onStompError = function (frame) {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        };

        socketClient.activate();
        setClient("notice_socket", socketClient)
    });

export const setMessages = createAction('@@xcloud/websocket/setMessages');

export const setOpenNotification = createAction('@@xcloud/websocket/setOpenNotification');

export const websocketReducer = handleActions(
    {
        [setMessages]: (state, { payload: value }) => {
            return {
                ...state,
                messages: value
            };
        },
        [setOpenNotification]: (state, { payload: value }) => {
            return {
                ...state,
                openNotification: value
            };
        }
    },
    defaultState
);

export default websocketReducer;