import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import withStyles from './styles';


const TenantSelect = props => {
  const { classes,
    loginUserTenants,
    tenantChangeErrorMessage,
    tenantSelectValue,
    onhandleChange,
    onhandeConfirm,
    isloading,
    isProcessing,
    loginSelectedTenant,
    // currentUserInfo
  } = props;

  const loadingpage = () => (
    <React.Fragment>
      <div>
        <Grid className={classes.loading} container spacing={0} alignItems="center" wrap="nowrap">
          <CircularProgress className={classes.progress} />
        </Grid>
      </div>
    </React.Fragment>
  );

  const showItemValue = (option) => {
    let rtnStr = '';
    if (loginSelectedTenant && loginSelectedTenant.tenantId && loginSelectedTenant.tenantId === option.tenantId) {
      rtnStr = `${option.tenantName} : ${'当前租户'} *`;
    // } else if (currentUserInfo && currentUserInfo.tenantId && currentUserInfo.tenantId === option.tenantId) {
    //   rtnStr = `${option.tenantName} : ${option.description} *`;
    // } else if (option.description) {
    //   rtnStr = `${option.tenantName} : ${option.description}`;
    // } else {
    //   rtnStr = option.tenantName;
    // }
    } else if (option.description) {
      rtnStr = `${option.tenantName} : ${option.description}`;
    } else {
      rtnStr = option.tenantName;
    }
    return rtnStr;
  };

  const editpage = () => (
    <React.Fragment>
      <FormHelperText id="my-helper-text"
      error= {tenantChangeErrorMessage !== ''}
      >
        {tenantChangeErrorMessage}</FormHelperText>
      <TextField
        fullWidth
        label="选择租户"
        margin="dense"
        name="tenant"
        className={classes.textField}
        onChange={event => onhandleChange(event)}
        required
        select
        // eslint-disable-next-line react/jsx-sort-props
        SelectProps={{ native: true }}
        value={tenantSelectValue}
        variant="outlined"
        disabled={isProcessing}
      >
        {loginUserTenants.map(option => (
          <option
            key={option.tenantId}
            value={option.tenantId}
          >
            {option.tenantId === '' ? '' : showItemValue(option)}
          </option>
        ))}
      </TextField>
      <Button
        className={classes.signInButton}
        color="primary"
        fullWidth
        size="large"
        // type="submit"
        variant="contained"
        onClick={onhandeConfirm}
      >
        确定
      </Button>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <div
                className={classes.form}
              >
                <Typography
                  className={classes.title}
                  variant="h1"
                >
                  选择租户
                </Typography>
                {isloading ? loadingpage() : (loginUserTenants ? editpage() : '')}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TenantSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUserTenants: PropTypes.any.isRequired,
  tenantChangeErrorMessage: PropTypes.string,
  tenantSelectValue: PropTypes.any.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  isloading: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onhandeConfirm: PropTypes.func.isRequired,
  loginSelectedTenant: PropTypes.any.isRequired,
  currentUserInfo: PropTypes.any.isRequired,
};

export default withRouter(withStyles(TenantSelect));
