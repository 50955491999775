import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: theme.palette.primary.main,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        border: '1px solid ' + theme.palette.primary.main,
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },

});

export default withStyles(styles);