import React, { Component } from 'react';
import { connect } from 'react-redux';
import JobInfo from '../../../components/SystemManagement/JobManagement/JobInfo'
import {
    createJob,
    updateJob,
    setCurrentJob,
    backToJobList,
    setJobError,
    setJobChange
} from "../../../redux/modules/job";
import * as _ from 'lodash';
import PropTypes from "prop-types";

class JobEditContainer extends Component {

    static propTypes = {
        currentJob: PropTypes.object,
        jobError: PropTypes.object,
        jobChange: PropTypes.bool
    };

    handleSave = async (event) => {
        await this.handleCheck(event, null);
        if (_.findIndex(Object.values(this.props.jobError), function (o) { return o !== ''; }) < 0) {
            this.props.onhandleJobChange(false);
            if (this.props.currentJob.jobId) {
                this.props.onhandleUpdateJob();
            } else {
                this.props.onhandleCreateJob();
            }
        }
    }

    handleChange = (event, field) => {
        this.props.onhandleJobChange(true);
        let newJob;
        switch (field) {
            case 'jobName':
                newJob = {
                    ...this.props.currentJob,
                    jobName: event.target.value
                };
                this.props.onhandleUpdateEditJob(newJob);
                break;
            case 'opentext':
                newJob = {
                    ...this.props.currentJob,
                    opentext: event.target.value
                };
                this.props.onhandleUpdateEditJob(newJob);
                break;
            default:
            // do nothing
        }
    };

    handleBack = (event) => {
        this.props.onhandleBackToJobList();
    };

    handleCheck = (event, field) => {
        if (!field || field === 'jobName') {
            if (_.trim(this.props.currentJob.jobName) === '') {
                let err = {
                    ...this.props.jobError,
                    jobName: '职务名称不能为空'
                };
                this.props.onhandleSetError(err);
            } else {
                let err = {
                    ...this.props.jobError,
                    jobName: ''
                };
                this.props.onhandleSetError(err);
            }
        }
    };

    render() {
        return (
            <JobInfo
                onhandleSave={this.handleSave.bind(this)}
                currentJob={this.props.currentJob}
                onhandleChange={this.handleChange.bind(this)}
                onhandleBack={this.handleBack.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                jobError={this.props.jobError}
                jobChange={this.props.jobChange}
                companySelection={this.props.companySelection}
                companyList={this.props.companyList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentJob: state.job.currentJob,
        jobError: state.job.jobError,
        jobChange: state.job.jobChange,
        companySelection: state.job.companySelection,
        companyList: state.constant.companies
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleCreateJob: () => {
            dispatch(createJob())
        },
        onhandleUpdateJob: () => {
            dispatch(updateJob())
        },
        onhandleUpdateEditJob: (entity) => {
            dispatch(setCurrentJob(entity))
        },
        onhandleBackToJobList: () => {
            dispatch(backToJobList())
        },
        onhandleSetError: (err) => {
            dispatch(setJobError(err))
        },
        onhandleJobChange: (isChanged) => {
            dispatch(setJobChange(isChanged))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobEditContainer)
