import React from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Typography,
    Tooltip,
    InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import PropTypes from 'prop-types';
// import * as _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';




const MGenerateFixComponent = props => {
    const { classes,
        open,
        onhandleClose,
        onhandleGenerate,
        onhandleChange,
        onhandleDateChange,
        onhandleServiceDptsChange,
        services,
        fixAmount,
        dptsSelect,
        departments,
    } = props;


    const dptLabel = (dptId) => {
        if (!departments || !dptId) return ''
        const dpt = departments.find(d => d.dptId === dptId)
        if (!dpt) return ''
        return dpt.dptName
    }

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>创建月度修正工单</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="column" >
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='关联服务'
                                        select
                                        value={fixAmount.serviceId || ''}
                                        name='serviceId'
                                        onChange={onhandleChange}>
                                        {services && services.map((i) => (
                                            <MenuItem key={i.serviceId} value={i.serviceId}>
                                                {i.serviceName} {renderServiceType(i.serviceType)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <Autocomplete
                                        variant="outlined"
                                        multiple
                                        filterSelectedOptions
                                        freeSolo
                                        id='serviceDpts'
                                        options={dptsSelect}
                                        getOptionLabel={option => dptLabel(option)}
                                        value={fixAmount.dpts || []}
                                        onChange={onhandleServiceDptsChange}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    variant="outlined"
                                                    {...params}
                                                    id={"serviceDpt-text"}
                                                    label={"班组选择"}
                                                    fullWidth
                                                />)
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                        type='number'
                                        label='数量'
                                        name='allocation'
                                        value={fixAmount.allocation || ''}
                                        onChange={onhandleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment >{fixAmount.unit}</InputAdornment>,
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            autoOk
                                            disableToolbar
                                            inputVariant="outlined"
                                            id="date-selector"
                                            views={['year', 'month']}
                                            format="yyyy/MM"
                                            margin="normal"
                                            label='修正时间'
                                            value={fixAmount.fixDate}
                                            onChange={onhandleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleGenerate} color="primary"> 生成工单
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MGenerateFixComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

MGenerateFixComponent.defaultProps = {

};

export default withStyles(MGenerateFixComponent);