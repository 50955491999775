import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';
import { formatDate } from '../../../../utils/datetime';
import clsx from 'clsx';


const DevicePartAccessoryListComponent = props => {

    const {
        classes,
        open,
        devicePart,
        catList,
        onhandleClose,
    } = props;

    // const checkPermission = () => {
    //     let permission = _.find(userProfile.roles[0].routes, { 'route': 'device_accessory_list' })
    //     return permission && permission.action === 'W'
    // }

    // const devEditable = checkPermission() && !isView && !isDeviceEdit

    const renderCat = (device) => {
        let label = '';
        if (catList && catList.length > 0) {
            let value = _.find(catList, function (o) { return o.code === device.cat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }


    // const renderIsCommon = (row) => {
    //     if (row.isCommon === true) {
    //         return '是'
    //     } else if (row.isCommon === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    // const renderIsSpare = (row) => {
    //     if (row.isSpare === true) {
    //         return '是'
    //     } else if (row.isSpare === true) {
    //         return '否'
    //     } else {
    //         return ''
    //     }
    // }

    const renderStatus = (row) => {
        if (row.status === 'active') {
            return '在用'
        } else if (row.status === 'new') {
            return '备用'
        } else if (row.status === 'inactive') {
            return '弃用'
        } else {
            return ''
        }
    }

    // const renderTime = (row) => {
    //     return row.updateTime ? getDateByTimestamp(row.updateTime, true) : getDateByTimestamp(row.createTime, true)
    // }

    const renderEnableTime = (row) => {
        if (row.enableTime) {
            return formatDate(row.enableTime)
        }
        return ''
    }

    const renderDisableTime = (row) => {
        if (row.disableTime) {
            return formatDate(row.disableTime)
        }
        return ''
    }

    const assemblycolumns = () => {
        let columns = [
            {
                title: '部件编号',
                field: 'accNo',
                render: row => row ? <Typography>{row.accNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件名称',
                field: 'accName',
                render: row => row ? <Typography>{row.accName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '型号',
                field: 'model',
                render: row => row ? <Typography>{row.model}</Typography> : '',
                editable: 'never'
            },
            {
                title: '规格',
                field: 'spec',
                render: row => row ? <Typography>{row.spec}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部件类别',
                field: 'cat',
                render: row => row ? <Typography>{renderCat(row)}</Typography> : '',
                editable: 'never',
            },
            // {
            //     title: '通用部件',
            //     field: 'isCommon',
            //     render: row => row ? <Typography>{renderIsCommon(row)}</Typography> : '',
            //     editable: 'never'
            // },
            // {
            //     title: '更新时间',
            //     field: 'update_time',
            //     type: 'date',
            //     render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '数量（个）',
                field: 'amount',
                type: 'number',
                align: 'right',
                render: row => row ? <Typography>{row.amount}</Typography> : '',
                editable: 'never'
            },
            {
                title: '投用时间',
                field: 'enable_time',
                type: 'date',
                render: row => row ? <Typography>{renderEnableTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '拆除时间',
                field: 'enable_time',
                type: 'date',
                render: row => row ? <Typography>{renderDisableTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '使用状态',
                field: 'status',
                render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                editable: 'never'
            },

        ]

        return columns
    }

    return (

        <Dialog open={open} onClose={onhandleClose} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h3">
                        {"关联部件列表" + (devicePart.partName ? '-' + devicePart.partName : '')}
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid className={
                    clsx(classes.detailtable)
                }>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={devicePart && devicePart.accessoryList ? devicePart.accessoryList : []}
                        options={{
                            ...options,
                            showTitle: false,
                            padding: 'dense',
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            toolbar: false,
                        }}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                    >
                    </MaterialTable>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant="outlined" color="primary" className={classes.button}
                    onClick={event => onhandleClose(event)}
                >关闭
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DevicePartAccessoryListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    accessoryList: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
};

export default (withStyles(DevicePartAccessoryListComponent));