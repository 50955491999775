import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const SchemeAcceptPannel = ({
    classes,
    open,
    treatmentSchemeError,
    treatmentScheme,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck
}) => {
    // console.log('treatmentScheme comp')
    // console.log(treatmentScheme)

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{"治理结果验收"}</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <FormControl error={treatmentSchemeError.status !== ''} className={classes.formControl}>
                                <RadioGroup row aria-label="验收结果" name="status"
                                    value={treatmentScheme.status || ''}
                                    onChange={onhandleChange} >
                                    <FormControlLabel value={'done'} control={<Radio size={'small'} />} label="通过" />
                                    <FormControlLabel value={'invalid'} control={<Radio size={'small'} />} label="不通过" />
                                </RadioGroup>
                                <FormHelperText>{treatmentSchemeError.status}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"验收评价"}
                                variant='outlined'
                                error={treatmentSchemeError.acceptResult !== ''}
                                helperText={treatmentSchemeError.acceptResult}
                                className={classes.textField}
                                value={treatmentScheme.acceptResult || ''}
                                rows={7}
                                name='acceptResult'
                                onChange={event => onhandleChange(event)}
                                onBlur={event => onhandleCheck(event)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        确定</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
SchemeAcceptPannel.propTypes = {
    classes: PropTypes.object.isRequired,
    treatmentSchemeError: PropTypes.object.isRequired,
    treatmentScheme: PropTypes.object.isRequired
};

SchemeAcceptPannel.defaultProps = {

}

export default withStyles(SchemeAcceptPannel);