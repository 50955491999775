import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  textField: {
    width: '90%',
  },
  label: {
    marginTop: 2
  },
  title: {
    padding: theme.spacing(4)
  },
  gridContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  gridItem: {
    height: 24,
    align:'left',
  },
  select: {
    width: '90%',
    marginTop: 7
  },
  planitemroot: {
    backgroundColor: '#FFFFFFFF'
  }
});

export default withStyles(styles);
