import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({

    detailTitle: {
        paddingTop: 24,
        paddingLeft: 16,
        alignItems: 'center'
    },
    detailTable: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        alignItems: 'center'
    },
    tableFreeActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    title: {
        padding: theme.spacing(2)
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    flexgrow: {
        flexGrow: 1
    },
    select: {
        width: '90%',
        marginTop: 7
    },
    gridContainer: {
        padding: theme.spacing(2),
    },
    pic1: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.blue[500],
        fontSize: '12px',
    },
    pic2: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: colors.teal[400],
        fontSize: '12px',
    },
    tag: {
        marginLeft: 10,
        fontWeight: 400,
        color: theme.palette.white,
        backgroundColor: theme.palette.text.emphasize
    },
    toolbar: {
        padding: 0,
        minHeight: 48,
        height: 48,
    },
    tableButton: {
        // margin: -10,
    }
});

export default withStyles(styles);