import { CsvBuilder } from 'filefy';


export function getEnvCode() {
    let envCode = 'dev';
    if (window.location.host.indexOf('3050') > -1) {
        envCode = 'dev';
    } else if (window.location.host.indexOf('3060') > -1) {
        envCode = 'uat';
    } else if (process.env.NODE_ENV === 'production') {
        envCode = 'prod';
    }
    return envCode
}

export function csv(fileName, columns, data) {
    const builder = new CsvBuilder(fileName + ".csv");
    builder
        .setColumns(columns)
        .addRows(data).setDelimeter(",")
        .exportFile();
};
