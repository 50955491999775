import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActivityList, getCustomerList, setSearch, openActivity } from '../../../redux/modules/customerActivities';
import { getMoment } from '../../../utils/datetime'
import MyActivitiesComponent from '../../../components/Crm/Activities/MyActivities/MyActivitiesComponent';

class MyActivitiesContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        customerList: PropTypes.array,
        search: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedActivity: {},
            selectedDate: getMoment()(),
        }
    }

    componentDidMount() {
        this.handleGetActivityList()
        this.handleGetCustomerList()
    }

    handleGetActivityList(date, cid) {
        const { onhandleGetActivityList } = this.props
        let mDate = getMoment()()
        if (date)
            mDate = getMoment()(date)
        // console.log(mDate)
        const query = {
            fromDate: mDate.startOf('month').format('YYYY-MM-DD'),
            toDate: mDate.add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            cid: cid,
        }
        onhandleGetActivityList(query)
    }

    handleGetCustomerList() {
        this.props.onhandleGetCustomerList({ offset: 0, size: 0, sort: "-update_time" });
    }

    handleChangeDate(date) {
        this.setState({ selectedDate: getMoment()(date) })
        this.handleGetActivityList(date)
    }

    handleChangeCustomer(event) {
        //console.log('event', event.target.value);
        let newSearch = { ...this.props.search };
        newSearch.cid = event ? event.target.value : null;

        this.props.onhandleUpdateEditSearch(newSearch);
        this.handleGetActivityList(this.state.selectedDate, newSearch.cid)
    }

    handleOpenActivity(data) {
        this.props.onhandleOpenActivity(data.actId);
    }

    render() {

        // console.log(this.state.selectedActivity)
        return (
            <div>
                <MyActivitiesComponent
                    constant={this.props.constant}
                    search={this.props.search}
                    activityList={this.props.activityList}
                    customerList={this.props.customerList}
                    currentUserInfo={this.props.currentUserInfo}
                    selectedCustomerId={this.props.selectedCustomerId}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                    onhandleChangeCustomer={this.handleChangeCustomer.bind(this)}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        activityList: state.customerActivities.activityList,
        customerList: state.customerOppo.customerList,
        companyTopology: state.company.companyTopology,
        userProfile: state.auth.userProfile,
        search: state.customerActivities.search,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetActivityList: (query, callback) => {
            dispatch(getActivityList(query, callback))
        },
        onhandleGetCustomerList: (query, callback) => {
            dispatch(getCustomerList(query, callback))
        },
        onhandleUpdateEditSearch: (value) => {
            dispatch(setSearch(value));
        },
        onhandleOpenActivity: (value) => {
            dispatch(openActivity(value));
        },
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyActivitiesContainer);