import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Tooltip, Typography, CircularProgress
} from '@material-ui/core';
import withStyles from './styles';


const DeviceCard = props => {
  const {
    // classes,
    isloading,
    currentDevice,
    child,
    onhandleOpen
  } = props;

  return (
    <div>
      <Tooltip
        title={
          isloading ? <CircularProgress color='inherit' size={20} />
            :
            <div>
              <Typography>名称:{currentDevice.deviceName ? currentDevice.deviceName : ''}</Typography>
              <Typography>编号:{currentDevice.deviceNo ? currentDevice.deviceNo : ''}</Typography>
              <Typography>位号:{currentDevice.tagNo ? currentDevice.tagNo : ''}</Typography>
              <Typography>所属装置:{currentDevice.installTitle ? currentDevice.installTitle : ''}</Typography>
            </div>
        }
        arrow
        placement="left"
        onOpen={(event) => { onhandleOpen() }}
      >
        {child}
      </Tooltip>
    </div >
  );
};

DeviceCard.propTypes = {
  // classes: PropTypes.object.isRequired,
  isloading: PropTypes.bool.isRequired,
  currentDevice: PropTypes.object.isRequired,
  child: PropTypes.object.isRequired,
  onhandleOpen: PropTypes.func.isRequired
};

export default withRouter(withStyles(DeviceCard));
