import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegulationPlanAll from "../../../components/LeadershipManagement/RegulationPlanAll";
import {
    getPlan,
    getPlanDetailsP,
    getCurrentPlan,
    backToPlanList
} from "../../../redux/modules/leadershipRegulationPlan";
import { getProcessByRoleId, getCurrentProcessStatusR } from "../../../redux/modules/process";
import { getBusinessUnitsByRole, getDepartmentsByRole, getRole } from "../../../utils/userUtils";
import { getRegulations } from "../../../redux/modules/regulation"

import ApprovePannel from "../ApprovePannel";
import RegulationPlanDetailAddPannel from "./RegulationPlanDetailAddPannel"

import { openApprovePannel } from "../../../redux/modules/approvePannel";
import { openPannel as openRegulationPannel } from "../../../redux/modules/regulation";
import { openPlanDetailAddPannel } from "../../../redux/modules/leadershipPlanDetailAdd";


import * as _ from 'lodash';
class RegulationPlanAllContainer extends Component {
    static propTypes = {
        // plan: PropTypes.object,
        config: PropTypes.object,
        currentUserInfo: PropTypes.object,
        userProfile: PropTypes.object,
        onhandleOpenPlanDetails: PropTypes.func,
        onhandleProcess: PropTypes.func,
    };

    componentDidMount() {
        const {
            currentUserInfo, 
            onhandleGetNewPlans,
            onhandleGetPlanDetails,
            onhandleGetPlan,
            onhandleGetCurrentProcessStatus,
            onhandleGetRegulations,
            currentPlanS
            //userProfile
        } = this.props


        onhandleGetNewPlans(currentUserInfo.roleId)
        onhandleGetPlan(currentPlanS.planId, (plan) => {
            if (!plan || Object.keys(plan).length === 0)
                return
                onhandleGetCurrentProcessStatus(plan.planCode, plan.recordId, (data) => {
                    if(data.statusCode === 'Published'){
                        onhandleGetPlanDetails('Drafting', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                    else{
                        onhandleGetPlanDetails('new', '1', plan.planId, (data) => {
                            console.log(data)
                        })
                    }
                })
        })
        onhandleGetRegulations()
    }

    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleProcess(action) {
        if (action.statusCode === 'Rejected' || action.review) {
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleOpenRegulation() {
        const { onhandleOpenRegulation } = this.props
        onhandleOpenRegulation()
    }

    handleBack = (event) => {
        this.props.onhandleBackToPlanList();
    };

    handleOpenPlanDetailAddPannel() {
        const { onhandleOpenPlanDetailAddPannel } = this.props
        onhandleOpenPlanDetailAddPannel(this.handleAdd.bind(this))
    }

    render() {
        let createPermission = _.find(this.props.startProcesses, plan => { return plan.processCode === 'RegulationPlan' })
        createPermission = createPermission ? true : false

        return (
            <div>
                <RegulationPlanAll
                    createPermission={createPermission}
                    plan={this.props.currentPlanS}
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    userProfile={this.props.userProfile}
                    departments={this.props.departments}
                    currentPlanDetails={this.props.planDetails}
                    regulations={this.props.regulations}
                    onhandleProcess={this.handleProcess.bind(this)}
                    currentProcessStatus={this.props.currentProcessStatus}
                    onhandleBack={this.handleBack.bind(this)}
                />
                <ApprovePannel />
                <RegulationPlanDetailAddPannel />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.currentPlan,
        planDetails: state.leadershipRegulationPlan.planDetails,
        currentPlanS: state.leadershipRegulationPlan.currentPlanS,
        constant: state.constant,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        departments: getDepartmentsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
        regulations: state.regulation.regulations,
        currentProcessStatus: state.process.currentProcessStatus.RegulationPlan || {},
        startProcesses: state.process.startProcesses,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetNewPlans: (roleId) => {
            dispatch(getProcessByRoleId(roleId, 1))
        },
        onhandleGetCurrentProcessStatus: (planCode, recordId,callback) => {
            dispatch(getCurrentProcessStatusR(planCode, 'RegulationPlan', recordId, callback))
        },
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getCurrentPlan(callback))
        },
        onhandleGetRegulations: () => {
            dispatch(getRegulations())
        },

        onhandleGetPlan: (planId, callback) => {
            dispatch(getPlan(planId, callback))
        },
        onhandleGetPlanDetails: (status,type,planId,callback) => {
            dispatch(getPlanDetailsP(status,type,planId,callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleOpenRegulation: (changeCallback, closeCallback) => {
            dispatch(openRegulationPannel(changeCallback, closeCallback))
        },
        onhandleOpenPlanDetailAddPannel: (confirmCallback, closeCallback) => {
            dispatch(openPlanDetailAddPannel(confirmCallback, closeCallback))
        },
        onhandleBackToPlanList: () => {
            dispatch(backToPlanList())
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanAllContainer)