import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initCreate } from '../../redux/modules/tenant';
import TenantHeader from "../../components/TenantManagement/TenantHeader";

class TenantHeaderContainer extends Component {

  static propTypes = {};

  handleOpen(event) {
    if (this.props.onhandleOpen) {
      this.props.onhandleOpen()
    }
  }

  render() {
    return (
      <div>
        <TenantHeader
            onhandleOpen={this.handleOpen.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleOpen: () => {
      dispatch(initCreate());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantHeaderContainer)
