import { createAction, handleActions } from 'redux-actions';
// import * as _ from 'lodash'

const initialState = {
  selectedId: ''
};

export const addStoreySelect = createAction('@@xcloud/installationPlug/addStoreySelect');

export const deleteStorey = createAction('@@xcloud/installationPlug/deleteStorey');

export const updateStorey = createAction('@@xcloud/installationPlug/updateStorey');
// Actions

export const installationPlugReducer = handleActions(
  {
    [addStoreySelect]: (state, { payload: id }) => {
      return {
        ...state,
        selectedId: id
      };
    },
  },
  initialState
);

export default installationPlugReducer