import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message';
import { getRoleTree } from './role';
// import { getBus } from './bu';
import { getDepartments } from './department';
import * as _ from 'lodash';

const defaultState = {
  isloading: false,
  users: {
    total: 0,
    list: []
  },
  currentUser: {},
  userError: {},
  userChange: false,
  offset: 0,
  pageSize: 10,
  search: {
    companyId: 0,
    groupId: '',
    roleId: '',
    roleLevel: undefined,
    dptId: '',
    unitId: '',
    userName: undefined,
    email: undefined,
    name: undefined,
    status: 'active'
  },
  fullgroupList: [],
  fullroleList: [],
  roleTree: [],
  departmentTree: [],
  fulldepartmentList: [],
  groupList: [],
  roleList: [],
  departmentList: [],
  buList: [],
  leaders: [],

};

export const resetSearch = createAction('@@xcloud/user/resetSearch',
  (companyId) => (dispatch, getState, httpClient) => {
    dispatch(setSearch({
      companyId: companyId,
      groupId: '',
      roleId: '',
      roleLevel: undefined,
      dptId: '',
      unitId: '',
      userName: undefined,
      email: undefined,
      name: undefined,
      status: ''
    }));
    let roles = getState().role.roleTree;
    let groupList = [];
    let roleList = [];
    roles.forEach(element => {
      if (element.roleGroups) {
        element.roleGroups.forEach(group => {
          if (group.companyId === companyId) {
            groupList.push({
              name: group.groupName,
              code: group.groupId
            });
            if (group.roleList) {
              group.roleList.forEach(role => {
                roleList.push({
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          }
        })
      }
    })
    dispatch(setGroupList(groupList));
    dispatch(setRoleList(roleList));
    let bus = getState().bu.bus;
    let buList = [];
    bus.list.forEach(bu => {
      buList.push({
        name: bu.unitName,
        code: bu.unitId
      })
    })
    dispatch(setBuList(buList));
    let departments = getState().department.departments.list;
    let departmentList = [];
    departments.forEach(dpt => {
      if (dpt.companyId === companyId) {
        departmentList.push({
          name: dpt.dptName,
          code: dpt.dptId
        })
      }
    })
    dispatch(setDepartmentList(departmentList));
  });

export const initUserHeader = createAction('@@xcloud/user/initUserHeader',
  (companyId) => (dispatch, getState) => {

    Promise.all([
      dispatch(getRoleTree()),
      // dispatch(getBus({
      //   offset: 0,
      //   size: 0
      // })),
      dispatch(getDepartments({
        offset: 0,
        size: 0
      }))
    ]).then(() => {
      dispatch(getUsers({ companyId: companyId, offset: getState().user.offset, size: getState().user.pageSize }))
      let roles = getState().role.roleTree;
      let groupList = [];
      let roleList = [];
      let fgList = [];
      let frList = [];
      roles.forEach(element => {
        if (element.roleGroups) {
          element.roleGroups.forEach(group => {
            if (group.companyId === companyId) {
              groupList.push({
                name: group.groupName,
                code: group.groupId
              });
              if (group.roleList) {
                group.roleList.forEach(role => {
                  roleList.push({
                    name: role.roleName,
                    code: role.roleId
                  });
                })
              }
            }
            fgList.push({
              name: group.groupName,
              code: group.groupId
            });
            if (group.roleList) {
              group.roleList.forEach(role => {
                frList.push({
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          })
        }
      })
      dispatch(setGroupList(groupList));
      dispatch(setRoleList(roleList));
      dispatch(setFullGroupList(fgList));
      dispatch(setFullRoleList(frList));
      // convert role tree
      let roleTree = []
      roles.forEach(element => {
        let rt = {
          label: element['companyName'],
          value: 'c' + element['companyId'],
          selectable: false,
          children: []
        }
        if (element['roleGroups']) {
          element['roleGroups'].forEach(rg => {
            let rgroup = {
              label: rg['groupName'],
              value: 'g' + rg['groupId'],
              selectable: false,
              children: []
            }
            if (rg['roleList']) {
              rg['roleList'].forEach(item => {
                let rr = {
                  label: item['roleName'],
                  value: item['roleId']
                }
                rgroup.children.push(rr)
              })
            }
            rt.children.push(rgroup)
          })
        }
        roleTree.push(rt)
      })
      dispatch(setRoleTree(roleTree));
      let departments = getState().department.departments.list;
      let departmentList = [];
      let fdptList = [];
      departments.forEach(dpt => {
        if (dpt.companyId === companyId) {
          departmentList.push({
            name: dpt.dptName,
            code: dpt.dptId
          })
        }
        fdptList.push({
          name: dpt.dptName,
          code: dpt.dptId
        })
      })
      dispatch(setDepartmentList(departmentList));
      dispatch(setFullDepartmentList(fdptList));
      let companies = getState().constant.companies
      let dptTree = [];
      companies.forEach(com => {
        let c = {
          label: com['companyName'],
          value: "c" + com['companyId'],
          selectable: false,
          children: []
        }
        departments.forEach(dpt => {
          if (dpt.companyId === com.companyId) {
            c.children.push({
              label: dpt['dptName'],
              value: dpt['dptId']
            })
          }
        })
        dptTree.push(c)
      })
      // convert department tree
      dispatch(setDepartmentTree(dptTree));

    })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '初始化用户搜索失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });

  });

export const resetSearchField = createAction('@@xcloud/user/resetSearchField',
  (value, field) => (dispatch, getState, httpClient) => {
    if (field === 'company') {

      let roles = getState().role.roleTree;
      let groupList = [];
      let roleList = [];
      roles.forEach(element => {
        if (element.roleGroups && (element.companyId === value || value === 0)) {
          element.roleGroups.forEach(group => {
            groupList.push({
              name: group.groupName,
              code: group.groupId
            });
            if (group.roleList) {
              group.roleList.forEach(role => {
                roleList.push({
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          })
        }
      })
      dispatch(setGroupList(groupList));
      dispatch(setRoleList(roleList));
      let departments = getState().department.departments.list;
      let departmentList = [];
      departments.forEach(dpt => {
        if (dpt.companyId === value || value === 0) {
          departmentList.push({
            name: dpt.dptName,
            code: dpt.dptId
          })
        }
      })
      dispatch(setDepartmentList(departmentList));
      let search = getState().user.search;
      let newSearch = {
        ...search,
        dptId: '',
        groupId: '',
        roleId: '',
        status: 'active'
      }
      dispatch(setSearch(newSearch));

    } else if (field === 'roleGroup') {
      let roles = getState().role.roleTree;
      let roleList = [];
      roles.forEach(element => {
        if (element.roleGroups) {
          element.roleGroups.forEach(group => {
            if (value === "" || (group.groupId === value && group.roleList)) {
              group.roleList.forEach(role => {
                roleList.push({
                  name: role.roleName,
                  code: role.roleId
                });
              })
            }
          })
        }
      })
      dispatch(setRoleList(roleList));
      let search = getState().user.search;
      let newSearch = {
        ...search,
        roleId: '',
        status: 'active'
      }
      dispatch(setSearch(newSearch));
    }
  });

export const backToUserList = createAction('@@xcloud/user/backToUserList',
  () => (dispatch, getState) => {
    dispatch(push('/userManagement'));
  });
export const getUsers = createAction('@@xcloud/user/getUsers',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(setUserChange(false));
    let search = getState().user.search;
    let pageSize = getState().user.pageSize
    let url = '/api/users';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=" + (pageSize ? pageSize : 10)
    }
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;

    if (search.companyId && search.companyId !== 0) {
      url += "&companyId=" + search.companyId;
    } else if (search.company) {
      url += "&companyId=" + search.company.value;
    }
    if (search.groupId)
      url += "&groupId=" + search.groupId;
    if (search.roleId)
      url += "&roleId=" + search.roleId;
    if (search.roleLevel)
      url += "&roleLevel=" + search.roleLevel;
    if (search.dptId)
      url += "&dptId=" + search.dptId;
    if (search.unitId)
      url += "&unitId=" + search.unitId;
    if (search.userName && search.userName !== '')
      url += "&userName=" + search.userName;
    if (search.email && search.email !== '')
      url += "&email=" + search.email;
    if (search.name && search.name !== '')
      url += "&name=" + search.name;
    if (search.status && search.status !== '' && search.status !== 'all')
      url += "&status=" + search.status;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setUsers(res.data));
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '用户列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getUsersWithCallback = createAction('@@xcloud/user/getUsersWithCallback',
  (query, callback) => (dispatch, getState, httpClient) => {
    dispatch(setUserChange(false));
    let pageSize = getState().user.pageSize
    let url = '/api/users';
    if (query.offset !== undefined) {
      url += "?offset=" + query.offset;
    } else {
      url += "?offset=0";
    }
    if (query.size !== undefined) {
      url += "&size=" + query.size;
    } else {
      url += "&size=" + (pageSize ? pageSize : 10)
    }
    if (query.sort !== undefined)
      url += "&sort=" + query.sort;
    if (query.company)
      url += "&companyId=" + query.company;
    if (query.groupId)
      url += "&groupId=" + query.groupId;
    if (query.roleId)
      url += "&roleId=" + query.roleId;
    if (query.roleLevel)
      url += "&roleLevel=" + query.roleLevel;
    if (query.dptId)
      url += "&dptId=" + query.dptId;
    if (query.unitId)
      url += "&unitId=" + query.unitId;
    if (query.userName && query.userName !== '')
      url += "&userName=" + query.userName;
    if (query.email && query.email !== '')
      url += "&email=" + query.email;
    if (query.name && query.name !== '')
      url += "&name=" + query.name;
    if (query.status && query.status !== '' && query.status !== 'all')
      url += "&status=" + query.status;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            if (callback) {
              callback(res.data)
            } else {
              dispatch(setUsers(res.data));
            }
          }
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '用户列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getUserById = createAction('@@xcloud/user/getUserById',
  (id) => (dispatch, getState, httpClient) => {
    let url = '/api/users/' + id;
    return httpClient.get(url, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentUser(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '租户信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createUser = createAction('@@xcloud/user/createUser',
  (newUser) => (dispatch, getState, httpClient) => {
    let cuser = getState().auth.currentUserInfo;
    let url = '/api/users';
    let to = {
      "firstName": newUser.firstName,
      "lastName": newUser.lastName,
      "password": newUser.password,
      "phone": newUser.phone,
      "tenantId": cuser.tenantId,
      "dptId": newUser.dptId,
      "userName": newUser.userName,
      "userType": newUser.userType !== undefined ? newUser.userType : 0
    }
    if (newUser.email) {
      to.email = newUser.email
    }
    if (newUser.opentext1) {
      to.opentext1 = newUser.opentext1
    }
    return httpClient.post(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 201) {
          dispatch(setCurrentUser(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '创建新用户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const changeUserStatus = createAction('@@xcloud/user/changeUserStatus',
  (id, status) => (dispatch, getState, httpClient) => {
    let url = '/api/users/' + id;
    let to = {
      userId: id,
      status: status
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新用户完成'));
          dispatch(getUsers({ offset: getState().user.offset, size: getState().user.pageSize }))
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新用户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const assginDepartment = createAction('@@xcloud/user/assginDepartment',
  (uid, did) => (dispatch, getState, httpClient) => {
    let url = '/api/users/' + uid + "/departments/" + did;
    return httpClient.post(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新用户部门完成'));
          dispatch(getUsers({ offset: getState().user.offset, size: getState().user.pageSize }))
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新用户部门失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const assginRole = createAction('@@xcloud/user/assginRole',
  (uid, rid) => (dispatch, getState, httpClient) => {
    let url = '/api/users/' + uid + "/roles/" + rid;
    return httpClient.post(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新用户角色完成'));
          dispatch(getUsers({ offset: getState().user.offset, size: getState().user.pageSize }))
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新用户角色失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });


export const updateUser = createAction('@@xcloud/user/updateUser',
  (updatedUser) => (dispatch, getState, httpClient) => {
    let user = getState().user.currentUser;
    let url = '/api/users/' + user.userId;
    let to = {
      userId: updatedUser.userId,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      phone: updatedUser.phone,
      userType: updatedUser.userType
    }
    if (updatedUser.email) {
      to.email = updatedUser.email
    }
    if (updatedUser.opentext1) {
      to.opentext1 = updatedUser.opentext1
    }
    return httpClient.patch(url, to, { headers: { need_loading: 'true' } })
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新用户信息完成'));
          dispatch(getUserById(updatedUser.userId))
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新用户失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const setUsers = createAction('@@xcloud/user/setUsers');

export const setPageSize = createAction('@@xcloud/user/setPageSize');

export const setOffset = createAction('@@xcloud/user/setOffset');

export const setSearch = createAction('@@xcloud/user/setSearch');

export const setGroupList = createAction('@@xcloud/user/setGroupList');

export const setRoleList = createAction('@@xcloud/user/setRoleList');

export const setDepartmentList = createAction('@@xcloud/user/setDepartmentList');

export const setFullGroupList = createAction('@@xcloud/user/setFullGroupList');

export const setFullRoleList = createAction('@@xcloud/user/setFullRoleList');

export const setRoleTree = createAction('@@xcloud/user/setRoleTree');

export const setDepartmentTree = createAction('@@xcloud/user/setDepartmentTree');

export const setFullDepartmentList = createAction('@@xcloud/user/setFullDepartmentList');

export const setBuList = createAction('@@xcloud/user/setBuList');

export const setCurrentUser = createAction('@@xcloud/user/setCurrentUser');

export const setUserError = createAction('@@xcloud/user/setUserError');

export const setUserChange = createAction('@@xcloud/user/setUserChange');

export const setLeaders = createAction('@@xcloud/user/setleaders');

export const setUserCountInDepartment = createAction('@@xcloud/user/setUserCountInDepartment');




export const initCreate = createAction(
  '@@xcloud/user/initCreate',
  () => (dispatch, getState) => {
    let dpts = getState().user.departmentList;
    let user = {
      "userId": 0,
      "userName": '',
      "phone": '',
      "email": '',
      "opentext1": '',
      "firstName": '',
      "lastName": '',
      "dptId": dpts[0].code,
      "password": '',
      "confirmPassword": '',
      "status": "active"
    }
    let userError = {
      userName: '',
      phone: '',
      email: '',
      opentext1: '',
      firstName: '',
      dptId: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    };
    dispatch(setCurrentUser(user));
    dispatch(setUserError(userError));
    dispatch(setUserChange(false));
    dispatch(push('/userManagement/info'));
  }
);

export const initUpdate = createAction(
  '@@xcloud/user/initUpdate',
  (id) => (dispatch, getState) => {
    let users = getState().user.users;
    let user = _.find(users.list, ['userId', id])
    let userError = {
      userName: '',
      phone: '',
      email: '',
      opentext1: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    };
    dispatch(setCurrentUser(user));
    dispatch(setUserError(userError));
    dispatch(setUserChange(false));
    dispatch(push('/userManagement/info'));
  }
);

export const getLeaders = createAction('@@xcloud/user/getleaders',
  (companyId, callback) => (dispatch, getState, httpClient) => {
    httpClient.get('/api/users/companies/' + companyId + '/leaders', callback)
      .then((res) => {
        if (res) {
          dispatch(setLeaders(res.data))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取该公司所有领导层失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const getAllLeaders = createAction('@@xcloud/user/getAllLeaders',
  (companyId, callback) => (dispatch, getState, httpClient) => {
    httpClient.get('/api/users/leaders', callback)
      .then((res) => {
        if (res) {
          dispatch(setLeaders(res.data))
          if (callback)
            callback(res.data)
        }
      }).catch(err => {
        let msg = `获取所有领导层失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const getUserCountInDepartment = createAction('@@xcloud/userCountInDeparment',
  (companyId, userTypes, status, callback) => (dispatch, getState, httpClient) => {

    let url = '/api/users/counts/department'

    if (companyId || userTypes || status) {
      let strs = []
      if (companyId) strs.push('companyId=' + companyId)
      if (userTypes) strs.push('userTypes=' + userTypes)
      if (status) strs.push('status=' + status)
      url = url + '?' + strs.join('&')
    }

    return httpClient.get(url, callback)
      .then((res) => {
        if (res) {
          dispatch(setUserCountInDepartment(res.data))
          if (callback)
            callback(res.data)
          return res.data
        }
      }).catch(err => {
        let msg = `获取部门人数失败`;
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return []
      })
  });

export const userReducer = handleActions(
  {
    [setPageSize]: (state, { payload: value }) => {
      return {
        ...state,
        pageSize: value
      };
    },
    [setOffset]: (state, { payload: value }) => {
      return {
        ...state,
        offset: value
      };
    },
    [setUsers]: (state, { payload: value }) => {
      return {
        ...state,
        users: value
      };
    },
    [setSearch]: (state, { payload: value }) => {
      return {
        ...state,
        search: value
      };
    },
    [setGroupList]: (state, { payload: value }) => {
      return {
        ...state,
        groupList: value
      };
    },
    [setRoleList]: (state, { payload: value }) => {
      return {
        ...state,
        roleList: value
      };
    },
    [setRoleTree]: (state, { payload: value }) => {
      return {
        ...state,
        roleTree: value
      };
    },
    [setDepartmentTree]: (state, { payload: value }) => {
      return {
        ...state,
        departmentTree: value
      };
    },
    [setDepartmentList]: (state, { payload: value }) => {
      return {
        ...state,
        departmentList: value
      };
    },
    [setFullGroupList]: (state, { payload: value }) => {
      return {
        ...state,
        fullgroupList: value
      };
    },
    [setFullRoleList]: (state, { payload: value }) => {
      return {
        ...state,
        fullroleList: value
      };
    },
    [setFullDepartmentList]: (state, { payload: value }) => {
      return {
        ...state,
        fulldepartmentList: value
      };
    },
    [setBuList]: (state, { payload: value }) => {
      return {
        ...state,
        buList: value
      };
    },
    [setUserError]: (state, { payload: value }) => {
      return {
        ...state,
        userError: value
      };
    },
    [setUserChange]: (state, { payload: value }) => {
      return {
        ...state,
        userChange: value
      };
    },
    [setCurrentUser]: (state, { payload: value }) => {
      return {
        ...state,
        currentUser: value
      };
    },
    [setLeaders]: (state, { payload: data }) => {
      return {
        ...state,
        leaders: data
      }
    },
    [setUserCountInDepartment]: (state, { payload: data }) => {
      return {
        ...state,
        userCounts: data
      }
    },

  },
  defaultState
);

export default userReducer;
