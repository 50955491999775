import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  // Card,
  Typography,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import _ from 'lodash';
// import TreeSelect from 'rc-tree-select';
import { Table } from 'antd';
import '@/assets/rc-tree.css';
import '@/assets/rc-tree-select-tree.css';

function RoleSelectCompoent(props) {
  const { classes,
    parameters,
    companyList,
    groupList,
    roleList,
    search,
    currentUserInfo,
    onhandleSelectChange,
    onhandleChange,
    onhandleClearSearch,
    onhandleSearch,
    onhandleExitSelected,
    onhandleConfirm,
    onhandleInnerConfirm
  } = props;

  const companyOptions = () => {
    var optionCompanies = companyList
    const currentUserCompany = _.find(companyList, { "companyId": currentUserInfo.companyId })
    if (currentUserCompany && currentUserCompany.pid) {//分厂
      optionCompanies = _.filter(companyList, c => {
        return !c.pid || c.companyId === currentUserInfo.companyId
      })
    }
    return _.sortBy(optionCompanies, 'companyId').map(data => (
      <MenuItem dense key={data.companyId} value={data.companyId}>{data.companyAlias}</MenuItem>
    ))
  }

  const roleGroupOptions = () => {
    const optionGroups = _.filter(groupList, { 'companyId': search.companyId })
    if (optionGroups) {
      return optionGroups.map(g => (
        <MenuItem dense key={g.groupId} value={g.groupId}>{g.groupName}</MenuItem>
      ))
    }
    return <Grid></Grid>
  }


  const companyDisabled = () => {
    if (parameters.companyId
      && !isNaN(parameters.companyId)
    ) {
      return true
    }
    return false
  }



  const checkParameters = () => {
    if (parameters && _.keys(parameters).length > 0) {
      if (_.keys(parameters).length === 1 && (parameters.companyId || parameters.callback)) {
        return false
      } else if (_.keys(parameters).length === 2 && parameters.companyId && parameters.callback) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }


  const renderCompany = (row) => {

    let companyName = ''
    const group = _.find(groupList, { groupId: row.groupId })
    if (group) {
      const compnay = _.find(companyList, function (o) { return o.companyId === group.companyId })
      if (compnay) {
        companyName = compnay.companyAlias
      }
    }
    return companyName

  }
  const renderGroupName = (row) => {
    let groupName = ''
    const group = _.find(groupList, { groupId: row.groupId })
    if (group) {
      groupName = group.groupName
    }
    return groupName
  }

  const assemblycolumns = () => {
    const columns = [
      {
        title: '角色名称',
        dataIndex: 'roleName',
        key: 'roleName',
        render: (text, row, index) => row ? <Typography>{row.roleName}</Typography> : '',
      },
      {
        title: '单位',
        dataIndex: 'companyId',
        key: 'companyId',
        render: (text, row, index) => row ? <Typography>{renderCompany(row)}</Typography> : '',
      },
      {
        title: '角色组',
        dataIndex: 'groupName',
        key: 'groupName',
        render: (text, row, index) => row ? <Typography>{renderGroupName(row)}</Typography> : '',
      },
    ]
    return columns
  }

  const assemblyData = () => {
    return roleList.list
  }


  const rowSelection = {
    type: parameters.isMutiple ? 'checkbox' : 'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      onhandleInnerConfirm(selectedRows[0])
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: false,
      name: record.name,
    }),
  };



  return (
    <div>
      <Grid className={classes.gridContainer}>
        <Typography variant="h3" gutterBottom>
          {parameters.title ? parameters.title : "角色选择"}
        </Typography>
        {checkParameters() && <>
          <div className={classes.planitemroot}>
            <Grid container spacing={0} className={classes.gridContainer}>
              {parameters.company &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'单位'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      style={{ width: '90%' }}
                      value={search.companyId || 0}
                      onChange={(event) => onhandleSelectChange(event, 'companyId')}
                      variant="standard"
                      disabled={companyDisabled()}
                    >
                      {!parameters.companyId ? <MenuItem key={0} value={''}>-</MenuItem> : null}
                      {companyOptions()}
                    </TextField>
                  </Grid>
                </>
              }
              {parameters.group &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'角色组'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      style={{ width: '90%' }}
                      value={search.groupId || ''}
                      onChange={(event) => onhandleSelectChange(event, 'groupId')}
                      variant="standard"
                    >
                      <MenuItem dense key={0} value={''}>-</MenuItem>
                      {roleGroupOptions()}
                    </Select>
                  </Grid>
                </>
              }
              {parameters.roleName &&
                <>
                  <Grid item xs={1}>
                    <Typography gutterBottom className={classes.label}>{'角色名称'}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ width: '90%' }}
                      value={search.roleName || ''}
                      variant="standard"
                      onChange={event => onhandleChange(event, 'roleName')}
                      placeholder=""
                    />
                  </Grid>
                </>
              }
            </Grid>
          </div>
          <div>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleSearch}
                >{'搜索'}</Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" color="primary"
                  onClick={onhandleClearSearch}
                >{'重置'}</Button>
              </Grid>
            </Grid>
          </div>
        </>}
      </Grid>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12}>
          <Table
            className={classes.stripedTable}
            rowKey={record => record.roleId}
            columns={assemblycolumns()}
            dataSource={assemblyData()}
            size={'small'}
            pagination={{ pageSize: 10 }}
            rowSelection={{
              ...rowSelection,
            }}

          />

          {/* <MaterialTable
            columns={columns}
            data={userList.list}
            options={{
              ...options,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20],
              // maxBodyHeight: `${tableHeight}vh`,
              // minBodyHeight: `${tableHeight}vh`,
              actionsColumnIndex: -1,
              search: false,
              showTextRowsSelected: false,
              toolbar: false,
              selection: parameters.innerSelect ? false : true,
              rowStyle: rowData => ({
                backgroundColor: selectedRow === rowData.userId ? '#EEEEEE' : 'FFFFFF'
              })
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            style={{
              ...style,
              border: '0px solid black',
              boxShadow: 'none'
            }}
            localization={getLocalization()}
            onSelectionChange={(rows) => onhandleSetSelected(rows)}
            actions={parameters.innerSelect ? [
              rowData => ({
                icon: 'assignment',
                tooltip: '选择用户',
                onClick: (event, rowData) => {
                  onhandleInnerConfirm(rowData);
                }
              }),
            ] : []
            }
          >
          </MaterialTable> */}
        </Grid>
      </Grid>
      {parameters.innerSelect ? null :
        <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
          <Button variant="contained" color="primary" style={{ marginRight: 15 }}
            onClick={event => onhandleConfirm()}
          >
            {'确定'}
          </Button>
          <Button variant="outlined" color="primary"
            onClick={event => onhandleExitSelected()}
          >
            {'取消'}
          </Button>
        </Grid>

      }

    </div >
  );
}

RoleSelectCompoent.propTypes = {
  classes: PropTypes.object.isRequired,
  roleList: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  companyList: PropTypes.array.isRequired,
  onhandleSetSelected: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
  onhandleInnerConfirm: PropTypes.func.isRequired,
};

RoleSelectCompoent.defaultProps = {
  roleList: { total: 0, list: [] },
  search: {},
  companyList: []

}

export default withStyles(RoleSelectCompoent);
