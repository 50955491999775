import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    TablePagination,
    // Select
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../../utils/datetime';
import { localization, options } from "../../../../utils/mtable";
import _ from 'lodash';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';

const UserList = props => {
    const {
        classes,
        users,
        pageSize,
        offset,
        userProfile,
        getUserList,
        roleList,
        roleTree,
        departmentTree,
        departmentList,
        onEdit,
        onDelete,
        onActivate,
        onAssginRole,
        onAssginDepartment,
        setPageSize,
        setOffset,
        isMainCompany
    } = props;

    const [selectedRow, setSelectedRow] = useState(null);
    console.debug(selectedRow)

    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(null);

    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedDpt, setSelectedDpt] = useState(null);

    const calculateTableBodyHeight = () => {
        const tableBodyHeight = window.innerHeight - 215
        setTableHeight(tableBodyHeight);
    };

    useEffect(() => {
        // 动态调整表格高度
        calculateTableBodyHeight();
        window.addEventListener("resize", calculateTableBodyHeight);
        return () => window.removeEventListener("resize", calculateTableBodyHeight);
    }, []);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'user_management' })
        return permission && permission.action === 'W'
    }

    const renderName = (user) => {
        return user.lastName + user.firstName;
    }

    const renderTime = (user) => {
        return user.updateTime ? getDateByTimestamp(user.updateTime, false) : getDateByTimestamp(user.createTime, false)
    }

    const renderStatus = (user) => {
        let mapping = { 'new': '未激活', 'active': '有效', 'inactive': '无效' };
        return mapping[user.status]
    }

    // const roleOptions = () => (
    //     <React.Fragment>
    //         {roleList ?
    //             roleList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    // const dptOptions = () => (
    //     <React.Fragment>
    //         {departmentList ?
    //             departmentList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    const renderRole = (user) => {
        if (user.roleId) {
            let role = _.find(roleList, { 'code': user.roleId })
            return role ? role.name : null
        } else {
            return null
        }
    }

    const renderRoleTree = (user) => {
        if (user.roleId) {
            let role = _.find(roleList, { 'code': user.roleId })
            return role ? { label: role.name, value: user.roleId } : undefined
        } else {
            return undefined
        }
    }

    const renderDepartment = (user) => {
        if (user.dptId) {
            let dpt = _.find(departmentList, { 'code': user.dptId })
            return dpt ? dpt.name : null
        } else {
            return null
        }
    }

    const renderUserType = (user) => {
        if (user.userType === 0) {
            return '正式员工'
        } else if (user.userType === 1) {
            return '返聘员工'
        } else if (user.userType === 2) {
            return '外包员工'
        } else if (user.userType === 3) {
            return '劳务人员'
        } else {
            return '未知'
        }
    }

    const renderDepartmentTree = (user) => {
        if (user.dptId) {
            let dpt = _.find(departmentList, { 'code': user.dptId })
            return dpt ? { label: dpt.name, value: dpt.dptId } : undefined
        } else {
            return undefined
        }
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '用户名',
                field: 'userName',
                render: row => row ? <Typography>{row.userName}</Typography> : '',
                editable: 'never'
            }
            ,
            {
                title: '姓名',
                field: 'userId',
                render: row => row ? <Typography>{renderName(row)}</Typography> : '',
                editable: 'never'
            },
            // {
            //     title: '邮箱',
            //     field: 'email',
            //     render: row => row ? <Typography>{row.email}</Typography> : '',
            //     editable: 'never'
            // },
            {
                title: '手机',
                field: 'phone',
                render: row => row ? <Typography>{row.phone}</Typography> : '',
                editable: 'never'
            },
            {
                title: '短号',
                field: 'opentext1',
                render: row => row ? <Typography>{row.opentext1}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'updateTime',
                type: 'date',
                render: row => row ? <Typography>{renderTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{renderStatus(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '类型',
                field: 'userType',
                render: row => row ? <Typography>{renderUserType(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '部门',
                field: 'dptId',
                render: row => row ? (selectedDpt !== row.userId ? <Typography>{renderDepartment(row)}</Typography>
                    : <TreeSelect
                        showArrow
                        className={classes.select}
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        allowClear
                        treeLine
                        value={renderDepartmentTree(row)}
                        labelInValue
                        treeData={departmentTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={(value) => {
                            onAssginDepartment(value, row.userId, setSelectedDpt)
                        }}
                    />
                    //  <Select
                    //     style={{ width: '100%' }}
                    //     native
                    //     value={row.dptId}
                    //     onChange={(event) => {
                    //         onAssginDepartment(event, row.userId, setSelectedDpt)
                    //     }}
                    // >
                    //     <option aria-label="None" value="" />
                    //     {dptOptions()}
                    // </Select>
                ) : '',
                editable: 'never'
            },
            {
                title: '角色',
                field: 'roleId',
                render: row => row ? (selectedRole !== row.userId ? <Typography>{renderRole(row)}</Typography>
                    : <TreeSelect
                        showArrow
                        className={classes.select}
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        allowClear
                        treeLine
                        value={renderRoleTree(row)}
                        labelInValue
                        treeData={roleTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={(value) => {
                            onAssginRole(value, row.userId, setSelectedRole)
                        }}
                    />
                ) : '',
                editable: 'never'
            }
        ]

        return columns
    }

    return (
        <Grid className={classes.detailtable}>
            <MaterialTable
                className={classes.materialtable}
                title=''
                columns={assemblycolumns()}
                data={users.list}
                options={{
                    ...options,
                    pageSize: pageSize ? pageSize : 10,
                    tableLayout: 'auto',
                    actionsColumnIndex: -1,
                    search: false,
                    toolbar: false,
                    maxBodyHeight: `${tableHeight}px`,
                    minBodyHeight: `${tableHeight}px`,
                }}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                localization={localization}
                style={{
                    border: '0px solid black',
                    boxShadow: 'none'
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            rowsPerPage={pageSize ? pageSize : 10}
                            count={users.total}
                            page={offset && pageSize ? Math.ceil(offset / pageSize) : page}
                            onChangePage={(event, pageNum) => {
                                setPage(pageNum);
                                setOffset(pageNum * (pageSize ? pageSize : 10))
                                getUserList({
                                    offset: pageNum * (pageSize ? pageSize : 10)
                                })
                            }}
                            onChangeRowsPerPage={(event) => {
                                props.onChangeRowsPerPage(event);
                                setPageSize(event.target.value);
                                setPage(0);
                                setOffset(0)
                                getUserList({
                                    offset: 0
                                })
                            }}

                        />
                    )
                }}
                actions={checkPermission() ?
                    [rowData => ({
                        icon: 'people',
                        tooltip: '选择部门',
                        onClick: (event, rowData) => {
                            if (selectedDpt === rowData.userId) {
                                setSelectedDpt(null)
                            } else {
                                setSelectedDpt(rowData.userId)
                            }
                        },
                        disabled: rowData.status === 'inactive' || (rowData.companyId && !isMainCompany && rowData.companyId !== userProfile.roles[0].companyId)
                    }),
                    rowData => ({
                        icon: 'person',
                        tooltip: '选择角色',
                        onClick: (event, rowData) => {
                            if (selectedRole === rowData.userId) {
                                setSelectedRole(null)
                            } else {
                                setSelectedRole(rowData.userId)
                            }
                        },
                        disabled: rowData.status === 'inactive' || (rowData.companyId && !isMainCompany && rowData.companyId !== userProfile.roles[0].companyId)
                    }),
                    rowData => ({
                        icon: 'edit',
                        tooltip: '编辑',
                        onClick: (event, rowData) => {
                            onEdit(event, rowData.userId)
                        },
                        disabled: rowData.status === 'inactive' || (rowData.companyId && !isMainCompany && rowData.companyId !== userProfile.roles[0].companyId)
                    }),
                    rowData => ({
                        icon: 'delete_outlined',
                        tooltip: '删除',
                        onClick: (event, rowData) => {
                            onDelete(event, rowData.userId, rowData.lastName + rowData.firstName)
                        },
                        disabled: rowData.status === 'inactive' || (rowData.companyId && !isMainCompany && rowData.companyId !== userProfile.roles[0].companyId)
                    }),
                    rowData => ({
                        icon: 'adjust',
                        tooltip: '激活',
                        onClick: (event, rowData) => {
                            onActivate(event, rowData.userId, rowData.lastName + rowData.firstName)
                        },
                        disabled: rowData.status === 'active' || (rowData.companyId && !isMainCompany && rowData.companyId !== userProfile.roles[0].companyId)
                    })
                    ] : [
                        // rowData => ({
                        //     icon: 'description',
                        //     tooltip: '查看',
                        //     onClick: (event, rowData) => {
                        //         // onView(event, rowData.userId)
                        //     }
                        // })
                    ]}
            >
            </MaterialTable>
        </Grid>
    );
};

UserList.propTypes = {
    classes: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    offset: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    getUserList: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    roleList: PropTypes.array.isRequired,
    roleTree: PropTypes.array.isRequired,
    departmentTree: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    // onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onActivate: PropTypes.func.isRequired,
    onAssginRole: PropTypes.func.isRequired,
    onAssginDepartment: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setOffset: PropTypes.func.isRequired
};

export default withStyles(UserList);
