import React, { Component } from 'react'
import { Stage } from 'react-konva';
import LeftSildeBar from '../../containers/LeftSildeBar';
import Dialog from '../../containers/Dialog';
import TopBar from '../../containers/TopBar';
import ContentMenu from '../../containers/ContentMenu';
import Konva from 'konva';
import * as _ from 'lodash';
import * as device from '../devices/core';
import "./mycanvas.css";
import KonvaUtil from "../devices/util";
import MapEditActionMapping from "../devices/mapEditActionMapping";
import { recaculateDeviceGroup } from '../devices/actionUtil'
const konvaUtil = new KonvaUtil();
const mapEditActionMapping = new MapEditActionMapping();
export default class MyCanvas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startPointX: null,
            startPointY: null,
            endPointX: null,
            endPointY: null,
            viewX: 0,
            viewY: 0,
        }
    }

    componentDidMount() {
        this.props.cleanDialog();
        this.stage = this.refs.stage;
        this.previewStage = this.refs.previewStage;

        //创建背景网格
        // var points = []
        // for (var ix = 0; ix * this.props.gridSize < this.props.konvaWidth * 2; ix++) {
        //     for (var iy = 0; iy * this.props.gridSize < this.props.konvaHeight * 2; iy++) {
        //         points.push({
        //             x: ix * this.props.gridSize,
        //             y: iy * this.props.gridSize
        //         })
        //     }
        // }
        // var layer1 = new Konva.Layer({
        //     id: 'basicLayer'
        // })
        // points.forEach((point) => {
        //     var circle = new Konva.Circle({
        //         x: point.x,
        //         y: point.y,
        //         radius: 1,
        //         fill: 'black',
        //         opacity: 0.2
        //     });
        //     layer1.add(circle);
        // })
        // this.stage.add(layer1);
        // layer1.draw();

        let delworklayer = this.stage.findOne('.workLayer');
        if (delworklayer) {
            delworklayer.destroy();
        }
        let selectedStorey = {}
        if (this.props.editInstallation.storeys) {
            selectedStorey = _.minBy(this.props.editInstallation.storeys, 'altitudeDatum');
        }
        if (selectedStorey.data2d) {

            let worklayer = Konva.Layer.create(JSON.parse(selectedStorey.data2d));
            worklayer.name('workLayer');
            this.stage.add(worklayer);
            mapEditActionMapping.layerMapping(this.props, worklayer, this.stage, this.previewStage);
        } else {
            let worklayer = new Konva.Layer({
                name: 'workLayer'
            })
            this.stage.add(worklayer);
        }

        //将主窗口同步到miniview窗口
        let worklayer = this.stage.findOne('.workLayer');
        let previewLayer = worklayer.clone({ listening: false });
        previewLayer.name('previewLayer');
        this.previewStage.add(previewLayer);
        device.initPreviewRect(this.refs.previewStage, this.refs.konvaDiv);
        konvaUtil.updateView(this.props, worklayer, this.previewStage);
    }

    // componentWillMount() {
    //     this.props.getNewTreeData();
    // }

    async componentDidUpdate() {
        if (this.props.newDevice && this.props.newDevice.type) {
            let result = await this._createDevice();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.newUnit && this.props.newUnit.type) {
            let result = await this._createUnit();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.newArea && this.props.newArea.type) {
            let result = await this._createArea();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.newDeviceGroup && this.props.newDeviceGroup.type) {
            let result = await this._createDeviceGroup();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.deleteDevices && this.props.deleteDevices.deviceId) {
            let result = await this._deleteDevice();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.deleteUnits && this.props.deleteUnits.unitId) {
            let result = await this._deleteUnit();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.deleteAreas && this.props.deleteAreas.areaId) {
            let result = this._deleteArea();
            if (result === 'success') {
                this.props.updAutoSave(true);
            }
        }
        if (this.props.recalDeviceGroup && this.props.recalDeviceGroup.id) {
            let worklayer = this.stage.findOne('.workLayer');
            recaculateDeviceGroup(this.props, this.props.recalDeviceGroup.id, worklayer, this.previewStage);
        }
    }

    _deleteArea() {
        let worklayer = this.stage.findOne('.workLayer');
        let device = worklayer.findOne('#' + this.props.deleteAreas.areaId);
        device.destroy();
        worklayer.draw();
        this.props.deleteMapArea();
        konvaUtil.updateView(this.props, worklayer, this.previewStage);
        return 'success';
    }

    _deleteUnit() {
        let worklayer = this.stage.findOne('.workLayer');
        let device = worklayer.findOne('#' + this.props.deleteUnits.unitId);
        device.destroy();
        worklayer.draw();
        this.props.deleteMapUnit();
        konvaUtil.updateView(this.props, worklayer, this.previewStage);
        return 'success';
    }

    _deleteDevice() {
        let worklayer = this.stage.findOne('.workLayer');
        let device = worklayer.findOne('#' + this.props.deleteDevices.deviceId);
        device.destroy();
        worklayer.draw();
        this.props.deleteMapDevice();
        konvaUtil.updateView(this.props, worklayer, this.previewStage);
        return 'success';
    }

    handleScroll(e) {
        konvaUtil.updatePreviewRect(this.refs.previewStage, e.target.scrollLeft, (e.target.scrollWidth - e.target.clientWidth), e.target.scrollTop, (e.target.scrollHeight - e.target.clientHeight))

        this.setState({
            viewX: this.stage.width() * (e.target.scrollLeft / e.target.scrollWidth),
            viewY: this.stage.height() * (e.target.scrollTop / e.target.scrollHeight)
        })
    }

    _getStage() {
        return this.refs.stage;
    }

    _getPreviewStage() {
        return this.refs.previewStage;
    }

    _contextMenu(e) {
        e.evt.preventDefault();
        if (e.target === e.target.getStage()) {
            const { cleanContentMenu } = this.props;
            cleanContentMenu();
        }
    }

    _clearTransformer(e) {
        e.evt.preventDefault();

        if (e.target === e.target.getStage()) {
            let layer = this.stage.findOne('.workLayer');
            layer.find('Transformer').destroy();
            layer.draw();
            const { cleanContentMenu } = this.props;
            cleanContentMenu();
        }
    }

    _createDeviceGroup() {
        device.initDeviceGroup(this.props, this.props.newDeviceGroup.type + '_' + this.props.newDeviceGroup.groupId, this.props.newDeviceGroup.groupName, this.stage, this.previewStage)
        this.props.createMapDeviceGroup();
        return 'success';
    }

    _createUnit() {
        device.initUnit(this.props, 0, 0, this.props.newUnit.type + '_' + this.props.newUnit.unitId, this.props.newUnit.unitName, this.stage, this.previewStage);
        this.props.createMapUnit();
        return 'success';
    }

    _createArea() {
        device.initArea(this.props, 0, 0, this.props.newArea.type + '_' + this.props.newArea.areaId, this.props.newArea.areaName, this.stage, this.previewStage);
        this.props.createMapArea();
        return 'success';
    }

    _createDevice() {
        let pos = {
            x: this.state.viewX + 10,
            y: this.state.viewY + 10
        }
        if (this.stage) {
            let color = '#130C0E';
            switch (this.props.newDevice.type) {
                case "Jiandingguan":
                    device.initJiandingguan(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Beng":
                    device.initBeng(this.props, pos.x, pos.y, this.props.newDevice.deviceId);
                    break;
                case "Xian":
                    if (this.props.newDevice.cat) {
                        if (this.props.newDevice.cat.indexOf('D150104') > -1) {
                            color = '#7fb80e'
                        } else if (this.props.newDevice.cat.indexOf('D1211') > -1 || this.props.newDevice.cat.indexOf('D1210') > -1) {
                            color = '#ef5b9c'
                        }
                    }
                    device.initXian(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage, color);
                    break;
                case "Guandao":
                    if (this.props.newDevice.medium) {
                        let mediumArray = _.split(this.props.newDevice.medium, ',');
                        if (mediumArray && mediumArray.length > 0) {
                            color = _.find(this.props.mediumColor, function (o) { return o.value === mediumArray[0].substring(0, 3); }).color;
                        }
                    }
                    device.initGuandao(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage, color);
                    break;
                case "Falanlianjie":
                    device.initFalanlianjie(this.props, pos.x, pos.y, this.props.newDevice.deviceId);
                    break;
                case "Lishiguan":
                    device.initLishiguan(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Woshiguan":
                    device.initWoshiguan(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Qiuxingguan":
                    device.initQiuxingguan(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Qiping":
                    device.initQiping(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Qicang":
                    device.initQicang(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Tuoshuicang":
                    device.initTuoshuicang(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Jiezhifa":
                    device.initJiezhifa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Zhafa":
                    device.initZhafa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Qiufa":
                    device.initQiufa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Diefa":
                    device.initDiefa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Zhihuifa":
                    device.initZhihuifa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Jianyafa":
                    device.initJianyafa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Zhusaifa":
                    device.initZhusaifa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Gemofa":
                    device.initGemofa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Xuansaifa":
                    device.initXuansaifa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Anquanfa":
                    device.initAnquanfa(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Lixinbeng":
                    device.initLixinbeng(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Jingliuta":
                    device.initJingliuta(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Lu":
                    device.initLu(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Fanyingqi":
                    device.initFanyingqi(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Huanreqi":
                    device.initHuanreqi(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Yibiao":
                    device.initYibiao(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                case "Other":
                    device.initOther(this.props, pos.x, pos.y, this.props.newDevice.type + '_' + this.props.newDevice.deviceId, this.props.newDevice.deviceName, this.stage, this.previewStage);
                    break;
                default:
                // do nothing
            }
            this.props.createMapDevice();
            return 'success';
        }
    }

    _handleMouseDown(e) {
        // console.log('_handleMouseDown');
        // console.log(this.state.startPointX,this.state.startPointY,this.state.endPointX,this.state.endPointY);
        let worklayer = this.stage.findOne('.workLayer');
        let mousePos = this.stage.getPointerPosition();
        let groups = worklayer.find('Group');
        let insideGroup = false;
        groups.forEach(group => {
            if (konvaUtil.pointInsideShape(mousePos.x, mousePos.y, group.getClientRect())) {
                insideGroup = true
            }
        })

        if (insideGroup) {
            this.setState({
                startPointX: null,
                startPointY: null,
                endPointX: null,
                endPointY: null,
            })
        } else {
            this.setState({
                startPointX: mousePos.x,
                startPointY: mousePos.y,
                endPointX: null,
                endPointY: null,
            })
        }
    }

    _handleMouseMove(e) {
        // console.log('_handleMouseMove',e);
        // console.log(this.state.startPointX,this.state.startPointY,this.state.endPointX,this.state.endPointY);
        let worklayer = this.stage.findOne('.workLayer');
        let mousePos = this.stage.getPointerPosition();
        if (_.isInteger(this.state.startPointX) && _.isInteger(this.state.startPointY)) {
            this.setState({
                startPointX: this.state.startPointX,
                startPointY: this.state.startPointY,
                endPointX: mousePos.x,
                endPointY: mousePos.y,
            })
            let oldGroup = worklayer.findOne('.groupTemp');
            if (oldGroup) {
                oldGroup.width(this.state.endPointX - this.state.startPointX);
                oldGroup.height(this.state.endPointY - this.state.startPointY);
                worklayer.draw();
            } else {
                let groupTemp = new Konva.Rect({
                    name: 'groupTemp',
                    draggable: false,
                    zIndex: 2,
                    x: this.state.startPointX,
                    y: this.state.startPointY,
                    width: this.state.endPointX - this.state.startPointX,
                    height: this.state.endPointY - this.state.startPointY,
                    stroke: '#388e3c',
                    opacity: 0.5,
                    fill: '#81c784',
                    strokeWidth: 4,
                    dash: [10, 5]
                });
                worklayer.add(groupTemp);
                worklayer.draw();
            }
        } else {
            this.setState({
                startPointX: null,
                startPointY: null,
                endPointX: null,
                endPointY: null,
            })
        }

    }

    _handleMouseUp(e) {
        // TODO: 添加集体移动功能
        let worklayer = this.stage.findOne('.workLayer');
        let groupTemp = worklayer.findOne('.groupTemp');
        if (groupTemp) {
            let intersectionGroups = []
            let groups = worklayer.find('Group');
            groups.forEach(group => {
                if (group.name() !== groupTemp.name() && group.name() !== 'Unit' && group.name() !== 'Area') {
                    if (konvaUtil.shape1ContainShape2(groupTemp.getClientRect(), group.getClientRect())) {
                        intersectionGroups.push(group);
                    }
                }
            })
            groupTemp.destroy();
            worklayer.draw();
            console.log('intersectionGroups:', intersectionGroups);
            let deviceList = [];
            if (intersectionGroups && intersectionGroups.length > 1) {
                let newGroupTemp = new Konva.Group({
                    name: 'newGroupTemp',
                    draggable: false,
                    stroke: '#388e3c',
                    strokeWidth: 4,
                    dash: [10, 5]
                });
                intersectionGroups.forEach(intersectionGroup => {
                    deviceList.push({
                        id: intersectionGroup.id(),
                        name: intersectionGroup.find('Text')[0].text(),
                        x1: intersectionGroup.getClientRect().x,
                        y1: intersectionGroup.getClientRect().y,
                        x2: intersectionGroup.getClientRect().x + intersectionGroup.getClientRect().width,
                        y2: intersectionGroup.getClientRect().y + intersectionGroup.getClientRect().height,
                    });
                })
                console.log(Math.min.apply(Math, deviceList.map(function (o) { return o.x1 })),
                    Math.min.apply(Math, deviceList.map(function (o) { return o.y1 })),
                    Math.max.apply(Math, deviceList.map(function (o) { return o.x2 })),
                    Math.max.apply(Math, deviceList.map(function (o) { return o.y2 }))
                )
                // Math.min.apply(Math, deviceList.map(function(o) {return o.y1})).y1,


                // _.minBy(deviceList, 'y1')[0].y1, _.maxBy(deviceList, 'x2')[0].x2, _.maxBy(deviceList, 'y2')[0].y2)
                let x = Math.min.apply(Math, deviceList.map(function (o) { return o.x1 }));
                let y = Math.min.apply(Math, deviceList.map(function (o) { return o.y1 }));
                let width = Math.max.apply(Math, deviceList.map(function (o) { return o.x2 })) - x;
                let height = Math.max.apply(Math, deviceList.map(function (o) { return o.y2 })) - y;
                console.log(x, y, width, height)
                let newGroupBackground = new Konva.Rect({
                    name: 'backGround',
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    stroke: '#388e3c',
                    strokeWidth: 4,
                    dash: [10, 5]
                });
                newGroupTemp.add(newGroupBackground);
                newGroupTemp.moveToBottom();
                worklayer.add(newGroupTemp);
                worklayer.draw();
                let units = worklayer.find('.Unit');
                if (units) {
                    units.forEach(unit => {
                        unit.moveToBottom();
                    })
                }
                let areas = worklayer.find('.Area');
                if (areas) {
                    areas.forEach(area => {
                        area.moveToBottom();
                    })
                }
                worklayer.draw();
                this.props.createDialog('deviceGroupInfo', { type: 'edit', deviceList: deviceList }, false);
            }
        }

        this.setState({
            startPointX: null,
            startPointY: null,
            endPointX: null,
            endPointY: null,
        })
    }

    render() {
        const { canvasWidth, canvasHeight, konvaWidth, konvaHeight, isFullScreen, editInstallation, existTreeData } = this.props;
        return (
            <div id='fullTag' style={{ width: canvasWidth, height: canvasHeight, backgroundColor: "white" }}>
                {(editInstallation.storeys && editInstallation.storeys.length > 0)
                    ?
                    <TopBar getStage={() => this._getStage()}
                        getPreviewStage={() => this._getPreviewStage()}
                    />
                    : null}

                {(existTreeData.children && existTreeData.children.length > 0)
                    ?
                    <LeftSildeBar getStage={() => this._getStage()} />
                    : null}

                <Dialog style={{ height: canvasHeight, left: isFullScreen ? canvasWidth - 200 : canvasWidth - 200 + 24 }}
                    getStage={() => this._getStage()}
                    getPreviewStage={() => this._getPreviewStage()}
                />
                <ContentMenu getStage={() => this._getStage()}
                    getPreviewStage={() => this._getPreviewStage()}
                />

                <div id='konvaDiv' style={{
                    width: canvasWidth - 400,
                    height: canvasHeight - 46,
                    left: isFullScreen ? 200 : 224,
                    top: isFullScreen ? 46 : 160,
                    backgroundSize: `${this.props.gridSize}px ${this.props.gridSize}px`,
                }}
                    className="konvaArea"
                    onScroll={(e) => this.handleScroll(e)}
                    ref="konvaDiv"
                >
                    <Stage
                        ref="stage"
                        width={konvaWidth}
                        height={konvaHeight}
                        scaleX={1}
                        scaleY={1}
                        onContextMenu={(e) => this._contextMenu(e)}
                        onClick={(e) => this._clearTransformer(e)}
                        onMouseDown={(e) => this._handleMouseDown(e)}
                        onMouseMove={(e) => this._handleMouseMove(e)}
                        onMouseUp={(e) => this._handleMouseUp(e)}>
                    </Stage>
                </div>
                <div className="preview"
                    style={{
                        left: isFullScreen ? canvasWidth - 200 : canvasWidth - 200 + 24,
                        top: isFullScreen ? canvasHeight - 100 : canvasHeight + 188 - 144
                    }}
                >
                    <Stage
                        ref="previewStage"
                        width={200}
                        height={100}
                        scaleX={1 / 30}
                        scaleY={1 / 30}
                    >
                    </Stage>
                </div>
            </div>
        );

    }
}
