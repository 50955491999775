import React, { Component } from 'react';
import { connect } from 'react-redux';
import OnDutyCalendar from '../../../components/LeadershipManagement/OnDuty/OnDutyCalendar'
import OnDutyDetail from '../../../components/LeadershipManagement/OnDuty/OnDutyCalendar/OnDutyDetail'
import CreateOnDutyPlan from './CreateOnDutyPlan'
import { getOnDutyActivities, getDutyRecords, setOnDutyCreateOpen, updateActivity, executeProcessMonthlyOnDuty, getOnDutyPlan, exportSafeActivityList } from '../../../redux/modules/safetyActivity';
import { getCompanyTopology } from "../../../redux/modules/company";
import { getProcessByRoleId, getCurrentProcessStatus } from '../../../redux/modules/process';
import ApprovePannel from '../ApprovePannel'
import { openApprovePannel } from "../../../redux/modules/approvePannel"
import { getMoment } from "../../../utils/datetime";
import { getRole } from "../../../utils/userUtils"
import { getLeaders } from '../../../redux/modules/user'
import { createActionMessage } from '../../../redux/modules/message'

import * as _ from 'lodash';
import OnDutyTableComponent from '../../../components/LeadershipManagement/OnDuty/OnDutyCalendar/OnDutyTable';

class OnDutyCalendarContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openActivity: {},
            activityTableOpen: false,
            selectedCompanyId: this.props.currentUserInfo.companyId,
            selectedDate: getMoment()()
        }
        this.changeLeader = false
        this.tempLeaders = [];
    }

    componentDidMount() {
        const { currentUserInfo,
            onhandleGetCompanyTopology,
            onhandleGetLeaders,
            onhandleGetCurrentProcessStatus,
            onhandleGetNewPlans,
            onhandleGetOnDutyPlan } = this.props
        this.handleGetMonthOnDutyActivities()
        onhandleGetCompanyTopology()
        onhandleGetLeaders(currentUserInfo.companyId)
        onhandleGetCurrentProcessStatus()
        onhandleGetNewPlans(currentUserInfo.roleId)
        onhandleGetOnDutyPlan(this.state.selectedDate)
    }

    handleGetMonthOnDutyActivities(date) {
        // console.log(date)
        const { onhandleGetOnDutyActivities } = this.props
        let mDate = getMoment()()
        if (date)
            mDate = getMoment()(date)

        const query = {
            fromDate: mDate.startOf('month').format('YYYY-MM-DD'),
            toDate: mDate.add(1, 'months').startOf('month').format('YYYY-MM-DD'),
        }
        onhandleGetOnDutyActivities(query)
    }

    /**
     * 流程相关
     */

    //提交时，校验规则
    _checkOnDutyWithRule(actionCode) {

        const { currentUserInfo, onhandleShowMessage } = this.props

        let filterCompanyId = currentUserInfo.companyId

        if (actionCode === 'Rejected') { //驳回不需要校验自己公司的，需要校验驳回公司的
            filterCompanyId = this.state.selectedCompanyId
        }
        const invalidate = _.find(this.props.activityList, a => {
            return a.users.length < 1 &&
                a.companyId === filterCompanyId
        })
        if (invalidate) {
            onhandleShowMessage('warning', '存在带班值班活动未安排情况:' + invalidate.activity)
            return false
        } else
            return true
    }

    handleProcess(action) {

        if (!this._checkOnDutyWithRule(action.statusCode)) return ''

        if (action.statusCode === 'Rejected' || action.review) {
            action.companyId = this.state.selectedCompanyId
            this.props.openApprovePannel(action.actionName, this.handleProcessConfirm.bind(this, action))
        } else {
            this.handleExecuteProcess(action)
        }
    }

    handleProcessConfirm(action, comment) {

        let fixedComment = this.props.currentUserInfo.userName
            + ' - ' + getRole(this.props.currentUserInfo.roleId, this.props.userProfile).roleName
            + ' ' + action.actionName + '： \n' + comment

        this.handleExecuteProcess({ ...action, comment: fixedComment })
    }

    handleExecuteProcess(action) {

        const { onhandleProcess,
            onhandleGetCurrentProcessStatus,
            onDutyPlan,
            activityList,
            currentUserInfo } = this.props

        let actionParams = []
        if (action.statusCode === 'Submitted' || action.statusCode === 'Reported') {
            let param = {
                handle: 'updateOnDuty',
                status: action.statusCode,
                activities: _.filter(activityList, a => { return a.companyId === currentUserInfo.companyId })
            }
            actionParams.push(param)
        }
        if (action.statusCode === 'Published') {
            let param = {
                handle: 'updateOnDutyPlan',
                status: action.statusCode,
                plan: onDutyPlan,
            }
            actionParams.push(param)
        }
        let processParams = {
            action: action,
            actionParams: actionParams
        }
        onhandleProcess(processParams, () => {
            onhandleGetCurrentProcessStatus()
        })
    }

    /**
     * 日期改变
     */
    handleChangeDate(date) {
        this.setState({ selectedDate: getMoment()(date) })
        this.handleGetMonthOnDutyActivities(date)
        this.props.onhandleGetOnDutyPlan(getMoment()(date))
        this.props.onhandleGetCurrentProcessStatus()

    }

    /**
     * 公司下拉菜单
     */
    _getMyCompanyChildCompanies() {
        const { companyTopology } = this.props
        const companies = _.filter(companyTopology, c => {
            return c.dutySupport //只有部分分厂需要带班值班
            // && (c.companyId === currentUserInfo.companyId || c.pid === currentUserInfo.companyId)
        })
        return companies
    }

    handleChangeCompany(event) {
        this.setState({
            selectedCompanyId: event.target.value
        });
    }


    /**
     * 显示和修改带班值班
     */
    handleOpenActivity(event) {

        const { activityList, onhandleGetDutyRecords } = this.props
        if (event.actId !== this.state.openActivity.actId) {
            onhandleGetDutyRecords(event.actId, (data) => {
                this.setState({
                    openActivity: _.find(activityList, (a) => { return a.actId === event.actId }),
                    dutyRecords: data
                })
            })
        }

    }

    handleChangeLeader(event, users) {
        this.changeLeader = true
        this.tempLeaders = users
        // console.log('users', users)
    }

    handleUpdateActivity() {

        if (!this.state.openActivity || !this.state.openActivity.actId)
            return

        const { onhandleUpdateActivity } = this.props

        let newActivity = {
            actId: this.state.openActivity.actId,
            users: _.map(this.tempLeaders, 'userId'),
        }

        onhandleUpdateActivity(newActivity, () => {
            this.handleGetMonthOnDutyActivities(this.state.selectedDate)
            this.handleCloseActivity()
        })

    }

    handleCloseActivity() {
        this.tempLeaders = []
        this.changeLeader = false
        this.setState({ openActivity: {}, dutyRecords: [] })

    }

    /**
     * 创建计划对话框
     */
    handleOpenCreate() {
        const { onhandleOpenCreate } = this.props
        onhandleOpenCreate()
    }

    handleOpenActivityTable(events, date) {
        if (events.length > 0) {
            this.setState({
                activityTableOpen: true,
                selectedDate: getMoment()(date)
            })
        }
    }

    handleCloseActivityTable() {
        this.setState({ activityTableOpen: false })
    }

    // CSV下载
    handleExport() {
        this.props.onhandleExport(this.state.selectedCompanyId);
    }

    render() {

        const { currentProcessStatus, startProcesses, onDutyPlan } = this.props

        let createPermission = false;
        if (_.find(startProcesses, plan => { return plan.processCode === 'MonthlyOnDuty' })) {
            createPermission = true
        }

        let editDisabled = true
        let hasProcess = false
        if (
            (this.state.selectedDate.year() > getMoment()().year() ||
                (this.state.selectedDate.year() === getMoment()().year()
                    && this.state.selectedDate.month() > getMoment()().month()))
            && currentProcessStatus && currentProcessStatus.statusCode
            && onDutyPlan && onDutyPlan.status && onDutyPlan.status !== 'Published') {
            hasProcess = true
            if ((currentProcessStatus.statusCode === 'Drafting' ||
                currentProcessStatus.statusCode === 'Rejected')
                && currentProcessStatus.actions.length > 0) {
                editDisabled = false
            }
        }

        let activityList = this.props.activityList
        activityList = _.sortBy(activityList, ['activity', 'activityTime'])
        activityList = _.reverse(activityList)

        const selectedMoment = getMoment()(this.state.selectedDate).startOf('day')
        let currentActivityList = _.filter(activityList, a => {
            return this.state.selectedCompanyId === a.companyId && selectedMoment.diff(getMoment()(a.activityTime).startOf('day'), 'days') === 0
        })

        return (
            <div>
                <OnDutyCalendar
                    createPermission={createPermission}
                    userList={this.props.leaders}
                    activityList={activityList}
                    openActivity={this.state.openActivity}
                    leaders={this.props.leaders}
                    editDisabled={editDisabled}
                    hasProcess={hasProcess}
                    currentProcessStatus={this.props.currentProcessStatus}
                    constant={this.props.constant}
                    currentUserInfo={this.props.currentUserInfo}
                    companies={this._getMyCompanyChildCompanies()}
                    selectedCompanyId={this.state.selectedCompanyId}
                    onhandleChangeCompany={this.handleChangeCompany.bind(this)}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                    onhandleOpenCreate={this.handleOpenCreate.bind(this)}
                    onhandleChangeDate={this.handleChangeDate.bind(this)}
                    onhandleProcess={this.handleProcess.bind(this)}
                    onhandleOpenActivityTable={this.handleOpenActivityTable.bind(this)}
                    onhandleExportCsv={this.handleExport.bind(this)}
                />
                <OnDutyTableComponent
                    constant={this.props.constant}
                    currentActivityList={currentActivityList}
                    leaders={this.props.leaders}
                    selectedMoment={selectedMoment}
                    open={this.state.activityTableOpen}
                    onhandleClose={this.handleCloseActivityTable.bind(this)}
                    onhandleOpenActivity={this.handleOpenActivity.bind(this)}
                />

                <OnDutyDetail
                    activity={this.state.openActivity}
                    leaders={this.props.leaders}
                    companyTopology={this.props.companyTopology}
                    editDisabled={editDisabled}
                    onhandleUpdateActivity={this.handleUpdateActivity.bind(this)}
                    onhandleChangeLeader={this.handleChangeLeader.bind(this)}
                    dutyRecords={this.props.dutyRecords}
                    onhandleClosePannel={this.handleCloseActivity.bind(this)}
                />
                {createPermission && <CreateOnDutyPlan />}
                <ApprovePannel />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        onDutyPlan: state.safetyActivity.onDutyPlan,
        companyTopology: state.company.companyTopology,
        activityList: state.safetyActivity.onDutyActivities,
        dutyRecords: state.safetyActivity.dutyRecords,
        constant: state.constant,
        leaders: state.user.leaders,
        startProcesses: state.process.startProcesses,
        currentProcessStatus: state.process.currentProcessStatus.MonthlyOnDuty || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCompanyTopology: () => {
            dispatch(getCompanyTopology())
        },
        onhandleGetNewPlans: (roleId) => {
            dispatch(getProcessByRoleId(roleId, 1))
        },
        onhandleGetOnDutyActivities: (query, callback) => {
            query.type = 1 //带班值班
            dispatch(getOnDutyActivities(query, callback))
        },
        onhandleGetDutyRecords: (activityId, callback) => {
            dispatch(getDutyRecords(activityId, callback))
        },
        onhandleOpenCreate: () => {
            dispatch(setOnDutyCreateOpen(true))
        },
        onhandleUpdateActivity: (activity, callback) => {
            dispatch(updateActivity(activity, callback))
        },
        onhandleGetLeaders: (companyId, callback) => {
            dispatch(getLeaders(companyId, callback))
        },
        onhandleProcess: (actionParams, callback) => {
            dispatch(executeProcessMonthlyOnDuty(actionParams, callback))
        },
        openApprovePannel: (title, confirmCallback, CloseCallback) => {
            dispatch(openApprovePannel(title, confirmCallback, CloseCallback))
        },
        onhandleGetCurrentProcessStatus: (callback) => {
            dispatch(getCurrentProcessStatus('MonthlyOnDuty', null, callback))
        },
        onhandleShowMessage: (type, message) => {
            dispatch(createActionMessage(type, message))
        },
        onhandleGetOnDutyPlan: (moment) => {
            const year = moment.year()
            const month = moment.month() + 1
            dispatch(getOnDutyPlan(year, month))
        },
        onhandleExport: (companyId) => {
            dispatch(exportSafeActivityList(companyId));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnDutyCalendarContainer);