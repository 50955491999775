import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import { getDateByTimestamp } from '../../../../utils/datetime';

const DepartmentInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleCancel,
        onhandleCheck,
        onhandleSwitchChange,
        currentDepartment,
        parentNode,
        departmentError,
        departmentChange,
        operationType
    } = props;

    const renderDptTitle = (operationType) => {
        let mapping = { 'add': '新建部门', 'update': '编辑部门', 'delete': '删除部门' };
        return mapping[operationType]
    }

    const renderDptTitle2 = (operationType) => {
        let mapping = { 'add': '新建班组', 'update': '编辑班组', 'delete': '删除班组' };
        return mapping[operationType]
    }

    return (
        <div>
            <Card className={classes.card}>

                {
                    !currentDepartment.executable ? (<Typography variant="h3" gutterBottom>
                        {renderDptTitle(operationType)}
                    </Typography>) : (<Typography variant="h3" gutterBottom>
                        {renderDptTitle2(operationType)}
                    </Typography>)
                }

                {!parentNode.dptId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>

                            {!parentNode.indivisible ? (
                                <Typography gutterBottom>
                                    所属公司
                                </Typography>) : (
                                <Typography gutterBottom>
                                    所属车间
                                </Typography>)}

                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {parentNode ? parentNode.companyName : null}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null
                }

                {parentNode.dptId ?
                    (<Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                上级部门
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {parentNode.dptName}
                            </Typography>
                        </Grid>
                    </Grid>) : null}

                {currentDepartment.dptId ?
                    (<Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                编号
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {currentDepartment.dptId}
                            </Typography>
                        </Grid>
                    </Grid>) : null}

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            名称
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            error={departmentError.dptName && departmentError.dptName !== ''}
                            helperText={departmentError.dptName || ''}
                            className={classes.textField}
                            value={currentDepartment.dptName || ''}
                            disabled={Boolean(operationType === 'delete')}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'dptName')}
                            onBlur={event => onhandleCheck(event, 'dptName')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            阿米巴类型
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            className={classes.textField}
                            native
                            value={currentDepartment.amoebaType}
                            disabled={Boolean(operationType === 'delete')}
                            onChange={(event) => onhandleChange(event, 'amoebaType')}
                        >
                            <option aria-label="None" value="" />
                            <option value={'COST'}>{'成本中心'}</option>
                            <option value={'PROFIT'}>{'利润中心'}</option>
                            <option value={'OTHER'}>{'其他'}</option>
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            阿米巴级别
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            className={classes.textField}
                            native
                            value={currentDepartment.amoebaLevel}
                            disabled={Boolean(operationType === 'delete')}
                            onChange={(event) => onhandleChange(event, 'amoebaLevel')}
                        >
                            <option aria-label="None" value="0" />
                            <option value={1}>{'一级巴'}</option>
                            <option value={2}>{'二级巴'}</option>
                            <option value={3}>{'三级巴'}</option>
                        </Select>
                    </Grid>

                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={2}>
                        <Typography gutterBottom>
                            是否执行班组
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>否</Grid>
                            <Grid item>
                                <Switch checked={currentDepartment.executable} disabled={Boolean(operationType === 'delete')}
                                    onChange={(event) => onhandleSwitchChange(event, 'executable')}
                                    name="ileCheck" />
                            </Grid>
                            <Grid item>是</Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            简介
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            disabled={Boolean(operationType === 'delete')}
                            className={classes.textAreaField}
                            value={currentDepartment.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            有效开始时间
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDatePicker
                                autoOk
                                className={classes.textField}
                                disableToolbar
                                openTo="year"
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="normal"
                                name='startDateselect'
                                value={currentDepartment.startDate}
                                disabled={Boolean(operationType === 'delete')}
                                onChange={value => onhandleChange(value, 'startDate')}
                                KeyboardButtonProps={{
                                    'aria-label': '修改时间',
                                }}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment:
                                        (
                                            <IconButton
                                                style={{ order: 1, display: operationType === 'delete' ? 'none' : '' }}
                                                onClick={() => onhandleChange(null, 'startDate')}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        )
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2, marginLeft: 0 }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        {departmentError.startDate !== '' ? <FormHelperText
                            error={true}
                        >
                            {departmentError.startDate}
                        </FormHelperText> : null}
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            有效结束时间
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={zhCN} >
                            <KeyboardDatePicker
                                autoOk
                                className={classes.textField}
                                disableToolbar
                                openTo="year"
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="normal"
                                name='endDateselect'
                                value={currentDepartment.endDate}
                                onChange={value => onhandleChange(value, 'endDate')}
                                disabled={operationType !== 'delete'}
                                KeyboardButtonProps={{
                                    'aria-label': '修改时间',
                                }}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment:
                                        (
                                            <IconButton
                                                style={{ order: 1, display: operationType !== 'delete' ? 'none' : '' }}
                                                onClick={() => onhandleChange(null, 'endDate')}
                                                disabled={Boolean(operationType !== 'delete')}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        )
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2, marginLeft: 0 }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        {departmentError.endDate !== '' ? <FormHelperText
                            error={true}
                        >
                            {departmentError.endDate}
                        </FormHelperText> : null}
                    </Grid>
                </Grid>

                {currentDepartment.dptId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentDepartment.createTime, true)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {currentDepartment.creatorLastName + currentDepartment.creatorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }


                {currentDepartment.dptId && currentDepartment.updateTime ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改时间
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentDepartment.updateTime, true)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改人
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {currentDepartment.revisorLastName + currentDepartment.revisorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={!departmentChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleCancel(event)}
                    >
                        取消
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

DepartmentInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSwitchChange: PropTypes.func.isRequired,
    onhandleCancel: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentDepartment: PropTypes.object.isRequired,
    parentNode: PropTypes.object,
    departmentError: PropTypes.object.isRequired,
    departmentChange: PropTypes.bool.isRequired
};

export default withStyles(DepartmentInfo);
