import React, {
    // useState
} from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { Add, Edit, DeleteOutline } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { options, localization } from "@/utils/mtable";
import TreeSelect from 'rc-tree-select';
import '@/assets/rc-tree.css';
import '@/assets/rc-tree-select-tree.css';
import { treeToArray } from '@/utils/constant';
import * as _ from 'lodash';



const DeviceMedium = props => {
    const {
        classes,
        open,
        device,
        media,
        mediumTree,
        mediumPhaseDict,
        onhandleClose,
        onhandleAdd,
        onhandleUpdate,
        onhandleDelete,
        onhandleSave,
    } = props;

    const tableRef = React.createRef();
    // https://github.com/mbrn/material-table/issues/2133 外部按钮触发表格AddRow
    // const tableActionRef = React.createRef();

    const renderMediumName = (mediumName) => {

        if (mediumTree) {
            let mediumList = treeToArray(_.cloneDeep(mediumTree))
            let medium = _.find(mediumList, { value: mediumName });
            if (medium) {
                return medium.label
            }
        }
        return ''

    }

    const renderMediumPhase = (mediumPhase) => {

        let phase = _.find(mediumPhaseDict, { code: mediumPhase })
        if (phase) {
            return phase.name;
        }
        return ''
    }

    const getPhaseDict = () => {
        let data = {}
        if (mediumPhaseDict) {
            for (let i = 0; i < mediumPhaseDict.length; i++) {
                let key = mediumPhaseDict[i].code
                data[key] = mediumPhaseDict[i].name
            }
        }
        return data
    }

    const renderMediumInTree = (row) => {

        if (!row.medium) {
            let mediumList = treeToArray(_.cloneDeep(mediumTree))
            let medium = _.find(mediumList, { value: row.mediumName });
            row['medium'] = {
                label: medium ? medium.label : '',
                value: row.mediumName,
            }
        }

        return row.medium

    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '介质名称',
                field: 'medium',
                width: 120,
                align: 'left',
                validate: row => true,
                render: row => row ? <Typography>{renderMediumName(row.mediumName)}</Typography> : '',
                editComponent: (props) => (
                    <TreeSelect
                        showArrow
                        className={classes.textField}
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto', width: '100%' }}
                        allowClear
                        treeLine
                        value={renderMediumInTree(props.rowData)}
                        labelInValue
                        treeData={mediumTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        multiple={false}
                        onChange={value => props.onChange(value)}
                    />
                )
            },
            {
                title: '相态',
                field: 'mediumPhase',
                width: 100,
                align: 'left',
                render: row => row ? <Typography>{renderMediumPhase(row.mediumPhase)}</Typography> : '',
                lookup: getPhaseDict()
            },

        ]

        return columns
    }


    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} fullWidth maxWidth={'sm'}>
                {/* <DialogTitle>
                    <Typography component={'span'} variant='h3'>{device.deviceName + '介质'}</Typography>
                </DialogTitle> */}
                <DialogContent className={classes.dialogContent}>
                    <MaterialTable
                        tableRef={tableRef}
                        className={classes.materialtable}
                        title={<Typography component={'span'} variant='h3'>{device.deviceName + '介质'}</Typography>}
                        localization={localization}
                        columns={assemblycolumns()}
                        data={media ? media : []}
                        options={{
                            ...options,
                            showTitle: true,
                            padding: 'dense',
                            addRowPosition: 'first',
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false,
                            toolbar: true,
                        }}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        icons={{
                            Add: () => <Button className={classes.tableFreeButton} variant='contained' color='primary' startIcon={<Add />}                            >
                                新建
                            </Button>,
                            Edit: () => <Edit color={'primary'} />,
                            Delete: () => <DeleteOutline color={'error'} />
                        }}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    resolve()
                                    onhandleAdd(newData)
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    resolve()
                                    onhandleUpdate(newData, oldData)
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    onhandleDelete(oldData)
                                    resolve()
                                }),
                        }}
                    >
                    </MaterialTable>

                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={onhandleClose} color="primary"> 取消</Button>
                    <Button onClick={onhandleSave} variant="contained" color="primary">确定</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};


DeviceMedium.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(DeviceMedium);
