import Konva from 'konva';
// import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from '../../../../../../redux/store';
import { cleanContentMenu, createContentMenu } from '../../../../redux/modules/contentmenu';
import KonvaUtil from "../../util";
import ActionUtil from '../../actionUtil';
const konvaUtil = new KonvaUtil();
const actionUtil = new ActionUtil();

export function init(props, bx, by, id, name, stage, previewStage) {
    let layer = stage.findOne('.workLayer');
    const state = {
        color: "black",
        width: 40,
        height: 100,
        strokeWidth: 4
    };

    const connectPoints = [
        {
            id: uuidv4(),
            x: 0,
            y: 10
        },
        {
            id: uuidv4(),
            x: 0,
            y: 90
        },
        {
            id: uuidv4(),
            x: 40,
            y: 10
        },
        {
            id: uuidv4(),
            x: 40,
            y: 90
        },
    ];

    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: id,
        zIndex: 5,
        name: "Lishiguan"
    });

    const background = new Konva.Rect({
        x: 0,
        y: 0,
        width: state.width,
        height: state.height
        // ,fill:'red'
    });
    group.add(background);
    // 顶部左线
    const topArc = new Konva.Line({
        points: [0, 10, 20, 0, 40, 10],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        tension: 0.9
    })
    group.add(topArc);

    // 左边竖线
    const leftLine = new Konva.Line({
        points: [0, 10, 0, 90],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(leftLine);

    // 右边竖线
    const rightLine = new Konva.Line({
        points: [40, 10, 40, 90],
        stroke: state.color,
        strokeWidth: state.strokeWidth
    })
    group.add(rightLine);

    // 底部弧线
    const bottomArc = new Konva.Line({
        points: [0, 90, 20, 100, 40, 90],
        stroke: state.color,
        strokeWidth: state.strokeWidth,
        tension: 0.9
    })
    group.add(bottomArc);

    const text = new Konva.Text({
        text: name,
        x: 0,
        y: 110,
        fill: state.color
    })
    group.add(text);

    for (const connectPoint of connectPoints) {
        CreatedragPoints(props, group, layer, connectPoint.x, connectPoint.y)
    }

    lineCommon(props, leftLine, group, layer, stage);
    lineCommon(props, rightLine, group, layer, stage);
    lineCommon(props, topArc, group, layer, stage);
    lineCommon(props, bottomArc, group, layer, stage);

    groupCommon(props, group, layer, stage, previewStage);

    layer.add(group);
    layer.batchDraw();
}


export function mapping(props, group, layer, stage, previewStage) {
    groupCommon(props, group, layer, stage, previewStage);
    group.find('Line').forEach(line => {
        lineCommon(props, line, group, layer, stage)
    })
    group.find('Circle').forEach(circle => {
        circleCommon(props, circle, layer)
    })
}

function CreateNewPoint(props, group, layer, stage) {
    let mousePos = stage.getPointerPosition();
    let x = Math.round(mousePos.x - group.x());
    let y = Math.round(mousePos.y - group.y());
    CreatedragPoints(props, group, layer, x, y);
}

function CreatedragPoints(props, group, layer, x, y, radius = 2, fill = "black") {
    const circle = new Konva.Circle({
        id: uuidv4(),
        x: x,
        y: y,
        fill: fill,
        radius: radius / 2,
        zindex: '6'
    });
    group.add(circle);
    layer.batchDraw();
    circleCommon(props, circle, layer);
}

function circleCommon(props, circle, layer) {
    circle.on("dblclick", e => {
        circle.destroy();
        layer.batchDraw();
    });
}

function lineCommon(props, line, group, layer, stage) {
    line.on('dblclick', function (e) {
        CreateNewPoint(props, group, layer, stage)
    });
}

function groupCommon(props, group, layer, stage, previewStage) {
    const store = getStore();
    group.on('transformend', function (e) {
        console.log('transform end');
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer);
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.markChange(group.id());
    });

    group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
        group.find('Line').forEach(element => {
            element.stroke('red');
        });
        group.find('Circle').forEach(element => {
            element.fill('blue');
            element.radius(6);
        });
        layer.batchDraw();
    })

    group.on('mouseout', function () {
        document.body.style.cursor = 'default';
        group.find('Line').forEach(element => {
            element.stroke('black');
        });
        group.find('Circle').forEach(element => {
            element.fill('black');
            element.radius(1);
        });
        layer.batchDraw();
    })

    group.on('dragend', function () {
        konvaUtil.updatePreview(props, layer, previewStage);
        actionUtil.caculateDeviceIntersectionWithAreaOrUnit(group, layer);
        actionUtil.caculateDeviceIntersectionWithDeviceGroup(group, layer, 'drag');
    })

    group.on('dragstart', function () {
        store.dispatch(cleanContentMenu());
    })

    group.on('contextmenu', function (e) {
        // prevent default behavior
        e.evt.preventDefault();
        let containerRect = stage.container().getBoundingClientRect();
        let top = containerRect.top + group.getClientRect().y + group.getClientRect().height / 2;
        let left = containerRect.left + group.getClientRect().x + group.getClientRect().width / 2;
        store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
    });
}
