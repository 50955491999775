import React, { Component } from 'react';
import { connect } from 'react-redux';
import MajorHazardDetectionList from "../../../components/MajorHazardSources/MajorHazardDetection/MajorHazardDetectionList";
import { initMajorHazardList, setPageSize, getMajorHazard, routeToMajorHazardDoc } from '../../../redux/modules/majorHazard';
import PropTypes from "prop-types";
import { createmodal } from '../../../redux/modules/modal';
// import * as _ from 'lodash';

class MajorHazardDetectionListContainer extends Component {
    static propTypes = {
        users: PropTypes.object,
        search: PropTypes.object,
        majorHazardlist: PropTypes.object,
        catList: PropTypes.array,
        useStatusList: PropTypes.array,
        dangerLevelList: PropTypes.array,
    };

    componentWillMount() {
        this.props.onhandleInitMajorHazardList('VIEW');
    }

    handleCharts(event, id) {
        let param = {
            deviceId: id
        }
        this.props.onhandleCharts(param);
    }

    handleInspection(event, id) {
        let param = {
            targetId: id
        }
        this.props.onhandleInspection(param);
    }

    handleOpsRecords(event, params) {
        this.props.onhandleOpsRecords(params);
    }

    handleViewDoc(data) {
        this.props.onhandleRouteToMajorHazardDoc(data.hazardId)
    }

    render() {
        return (
            <MajorHazardDetectionList
                majorHazardlist={this.props.majorHazardlist}
                catList={this.props.catList}
                mediumList={this.props.mediumList}
                useStatusList={this.props.useStatusList}
                extcatList={this.props.extcatList}
                dangerLevelList={this.props.dangerLevelList}
                onSizeChange={this.props.onhandleListSize.bind(this)}
                pageSize={this.props.pageSize}
                handleGetList={this.props.onhandleGetList.bind(this)}
                onCharts={this.handleCharts.bind(this)}
                onhandleInspection={this.handleInspection.bind(this)}
                onOpsRecords={this.handleOpsRecords.bind(this)}
                handleViewDoc={this.handleViewDoc.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.devices.search,
        majorHazardlist: state.majorHazard.majorHazardlist,
        catList: state.trees.deviceCategoryList,
        useStatusList: state.devices.useStatus,
        dangerLevelList: state.majorHazard.dangerLevelList,
        pageSize: state.majorHazard.pageSize,
        extcatList: state.devices.extcat,
        mediumList: state.devices.medium
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitMajorHazardList: (data) => {
            dispatch(initMajorHazardList(data));
        },
        onhandleListSize: (value) => {
            dispatch(setPageSize(value));
        },
        onhandleGetList: (query) => {
            dispatch(getMajorHazard(query));
        },
        onhandleCharts: (param) => {
            dispatch(createmodal('onsiteInspectionStatistics', param, 'medium'))
        },
        onhandleInspection: (param) => {
            dispatch(createmodal('majorHazardInspectionPage', param, 'medium'))
        },
        onhandleOpsRecords: (param) => {
            dispatch(createmodal('deviceOpsRecord', param, 'medium'))
        },
        onhandleRouteToMajorHazardDoc: (id) => {
            dispatch(routeToMajorHazardDoc('detection', id));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MajorHazardDetectionListContainer)
