import { createAction, handleActions } from 'redux-actions';

const initialState = {
    open: false,
    params: {},
    saveCallback: null,
    closeCallback: null,
    // parameters: {}
    // add loading status at original component, not this save dialog
};

export const openCreatePannel = createAction('@@xcloud/createpannel/open',
    (
        params, saveCallback, closeCallback
    ) => ({
        params, saveCallback, closeCallback
    })
);

export const closeCreatePannel = createAction('@@xcloud/createpannel/close');

export const changeParams = createAction('@@xcloud/createpannel/changeparams');

// Actions

export const createPannelReducer = handleActions(
    {
        [openCreatePannel]: (state, { payload: data }) => {
            return {
                ...state,
                open: true,
                params: data.params,
                saveCallback: data.saveCallback,
                closeCallback: data.closeCallback,
            };
        },
        [closeCreatePannel]: (state, ) => {
            return {
                ...state,
                open: false,
                params: {},
                saveCallback: null,
                closeCallback: null,
            };
        },
        [changeParams]: (state, { payload: data }) => {
            return {
                ...state,
                params: data,
            };
        }
    },
    initialState
);

export default createPannelReducer;
