import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AmoebaBudgetComponent from "@/components/AmoebaManagement/Plan/Budget";
import { createloading, cleanloading } from '@/redux/modules/loading';
import { addPlan, getBudgets, updatePlanDtlAmountBatch } from '@/redux/modules/amoeba/amoebaPlan';
import { getYear, getMoment } from '@/utils/datetime';
import { getRole, getDepartmentsByRole } from "@/utils/userUtils";
import { ANNUAL_PLAN_VIEW_ORGVIEW, ANNUAL_PLAN_VIEW_MONTHVIEW } from '@/utils/amoebaUtils';
import { arrayToTree, filterAmoebaCompany } from "@/utils/constant";

import * as _ from 'lodash';

class AnnualBudgetContainer extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props)
        this.state = {
            viewType: ANNUAL_PLAN_VIEW_ORGVIEW,
            selectedDate: getMoment()(),
            selectedCompanyId: 0,
            selectedDptId: 0,
            year: getYear(),
            tableHeaders: [],
            tableData: [],
            roleW: false,
            myDepartment: {},
        }
    }




    componentDidMount() {
        const { roleRoutes, currentUserInfo, userProfile } = this.props
        const { year } = this.state
        const route = _.find(roleRoutes, { 'route': 'amoeba_plan' })
        const roleW = route ? route.action === 'W' : false
        const viewType = roleW ? ANNUAL_PLAN_VIEW_ORGVIEW : ANNUAL_PLAN_VIEW_MONTHVIEW
        const myDepartments = getDepartmentsByRole(currentUserInfo.roleId, userProfile)
        const myDepartment = myDepartments && !_.isEmpty(myDepartments) ? myDepartments[0] : {}
        this.setState({
            selectedCompanyId: roleW && this.isRootCompany(currentUserInfo.companyId) ? 0 : currentUserInfo.companyId,
            selectedDptId: roleW ? 0 : myDepartment.dptId,
            roleW: roleW,
            viewType: viewType,
            myDepartment: myDepartments && !_.isEmpty(myDepartments) ? myDepartments[0] : {}
        }, () => {
            this.handleFreshView(year, roleW)
        })

    }

    isRootCompany(companyId) {
        const rootCompany = _.find(this.props.companyList, (c) => {
            return c.companyId === companyId && !c.pid
        })
        return rootCompany ? true : false
    }


    handleFreshView(year, roleW) {
        const { onhandleGetBudgets } = this.props
        const { selectedCompanyId, selectedDptId, viewType } = this.state

        const planType = viewType === ANNUAL_PLAN_VIEW_ORGVIEW ? 'ANNU' : 'MONTH'
        if (roleW && !selectedDptId) {
            onhandleGetBudgets(selectedCompanyId, null, year, planType).then((data) => {
                this.setState({
                    tableHeaders: this.getTableHeaders(),
                    tableData: this.getAssemblyData(),
                })
            })
        } else {
            onhandleGetBudgets(selectedCompanyId, selectedDptId, year, planType).then((data) => {
                this.setState({
                    tableHeaders: this.getTableHeaders(),
                    tableData: this.getAssemblyData(),
                })
            })
        }
    }

    getTableHeaders() {
        //每天header的key的格式为：companyId_dptId_year_month
        let headers = []
        const { roleRoutes, departmentList, companyList } = this.props
        const { viewType, year, selectedCompanyId, selectedDptId } = this.state
        const route = _.find(roleRoutes, { 'route': 'amoeba_plan' })
        if (route && route.action === 'W' && !selectedDptId) {
            let selectedCompany = _.find(companyList, { 'companyId': selectedCompanyId });
            let secondHeader = {//第二列汇总列
                key: 'null_null_' + year + '_null',
                title: '工程公司'
            }
            if (selectedCompany) {
                secondHeader.key = selectedCompany.companyId + '_null_' + year + '_null'
                secondHeader.title = selectedCompany.companyAlias
            }
            headers.push(
                secondHeader
            )
            if (viewType === ANNUAL_PLAN_VIEW_ORGVIEW) { //部门视图
                if (selectedCompanyId === 0) { //总公司
                    const clildCompanies = companyList //各分厂和机关
                    _.map(clildCompanies, c => {
                        headers.push({
                            key: c.companyId + '_null_' + year + '_null',
                            title: c.companyAlias,
                        })
                    })
                } else { //分公司
                    const clildDepartment = _.filter(departmentList, d => {
                        return d.companyId === selectedCompanyId && d.amoebaType !== 'OTHER'
                    })
                    _.map(clildDepartment, d => {
                        headers.push({
                            key: d.companyId + '_' + d.dptId + '_' + year + '_null',
                            title: d.dptName,
                        })
                    })
                }
            } else { //月视图
                for (let i = 1; i <= 12; i++) {
                    headers.push({
                        key: (selectedCompanyId ? selectedCompanyId : 'null') + '_null_' + year + '_' + i,
                        title: i + '月'
                    })
                }
            }
        } else { //单部门
            if (selectedDptId) { //第二列汇总列
                const selectDpt = _.find(departmentList, { 'dptId': selectedDptId })
                headers.push({
                    key: selectedCompanyId + '_' + selectedDptId + '_' + year + '_null',
                    title: selectDpt ? selectDpt.dptName : '',
                })
            }

            //只有月视图
            for (let i = 1; i <= 12; i++) {
                headers.push({
                    key: selectedCompanyId + '_' + selectedDptId + '_' + year + '_' + i,
                    title: i + '月'
                })
            }
        }

        return headers
    }

    getAssemblyData() {
        const { subs, budgets } = this.props;

        if (_.isEmpty(budgets) || _.isEmpty(subs)) {
            return []
        }

        let data = _.cloneDeep(_.filter(subs, { 'isPlan': true }));

        for (const budget of budgets) {
            let subItem = _.find(data, { 'subId': budget.subId })
            if (!subItem) {
                console.log(budget)
            }
            let key = budget.companyId + '_' + budget.dptId + '_' + budget.year + '_' + budget.month
            subItem[key] = budget.amount
        }

        return arrayToTree(data, 'subId', 'subParentId')
    }

    handleBudgetClick() {
        const { companyList, updatePlanDtlAmountBatch } = this.props;
        const { year, roleW } = this.state;
        let companys = companyList.map(ele => {
            return {
                'companyId': ele.companyId,
                'pid': (ele.pid || 0)
            }
        });
        updatePlanDtlAmountBatch(year, companys).then(() => {
            this.handleFreshView(year, roleW)
        })
    }

    handleAddPlan() {
        const { addAmoebaPlan, currentUserInfo, companyList, departmentList, subs } = this.props;
        let companyIdList = [], dptList = [];
        //指标列表
        let subIdList = _.map(subs, 'subId')

        companyIdList = _.map(companyList, c => { return c.companyId })
        departmentList.forEach((ele) => {
            let object = {};
            object.companyId = ele.companyId;
            object.dptId = ele.dptId;
            object.amoebaType = ele.amoebaType;
            object.executable = ele.executable;
            dptList.push(object)
        })
        let plan = {
            companyId: currentUserInfo.companyId,
            year: Number(this.state.year),
            subIdList: subIdList,
            companyIdList: companyIdList,
            dptList: dptList,
        }
        addAmoebaPlan(plan);
    }

    handleViewChange(event) {
        const { roleW, year } = this.state;
        const viewType = event.target.value;
        this.setState({ 'viewType': viewType }, () => {
            this.handleFreshView(year, roleW)
        })
    }

    handleDateChange = (date) => {
        const { roleW } = this.state;
        const selectDate = getMoment()(date)
        this.setState({
            selectedDate: selectDate,
            year: selectDate.year(),
        }, () => {
            this.handleFreshView(this.state.year, roleW)
        })
    }
    handleOrgChange = (event) => {
        const { roleW, year } = this.state;
        let changeState = {}
        if (event.target.name === 'companyId') {
            changeState.selectedCompanyId = Number(event.target.value)
            changeState.selectedDptId = 0
        } else if (event.target.name === 'dptId') {
            changeState.selectedDptId = Number(event.target.value)
        }
        this.setState(changeState, () => {
            this.handleFreshView(year, roleW)
        })
    }

    handleClose = () => {
        this.setState({
            openFlag: false
        })
    }

    render() {

        const params = {
            isAnnualPlan: true,
            dateViews: ['year'],
            dateFormat: 'yyyy',
            dateString: this.state.year + '年',
            viewTypeList: [
                {
                    code: ANNUAL_PLAN_VIEW_ORGVIEW,
                    name: '部门视图',
                },
                {
                    code: ANNUAL_PLAN_VIEW_MONTHVIEW,
                    name: '月视图'
                },
            ]
        }
        return (
            <AmoebaBudgetComponent
                userProfile={this.props.userProfile}
                year={this.state.year}
                roleW={this.state.roleW}
                selectedDate={this.state.selectedDate}
                selectedCompanyId={this.state.selectedCompanyId}
                selectedDptId={this.state.selectedDptId}
                viewType={this.state.viewType}
                params={params}
                tableHeaders={this.state.tableHeaders}
                companyList={this.props.companyList}
                departmentList={this.props.departmentList}
                tableData={this.state.tableData}
                currentUserInfo={this.props.currentUserInfo}
                onhandleClick={this.handleBudgetClick.bind(this)}
                onhandleAddPlan={this.handleAddPlan.bind(this)}
                onhandleViewChange={this.handleViewChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleOrgChange={this.handleOrgChange.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companyList: filterAmoebaCompany(state.company.companyList.list),
        departmentList: state.department.departments.list,
        subs: state.amoebaIndex.subs,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        userProfile: state.auth.userProfile,
        budgets: state.amoebaPlan.budgets,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAmoebaPlan: (plan) => {
            dispatch(addPlan(plan));
        },
        onhandleGetBudgets: (companyId, dptId, year, planType) => {
            return dispatch(getBudgets(companyId, dptId, year, null, null, planType))
        },
        updatePlanDtlAmountBatch: (year, companys) => {
            return dispatch(updatePlanDtlAmountBatch(year, companys));
        },
        createloading: () => {
            return dispatch(createloading());
        },
        cleanloading: () => {
            return dispatch(cleanloading());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnnualBudgetContainer))