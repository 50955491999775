import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initCreate, exportCsv, getDeviceList, initDeviceHeader, setSearch, resetSearchField, resetSearch } from '../../../redux/modules/devices';
import DeviceHeader from "../../../components/DeviceManagement/DeviceInfo/DeviceHeader";
import { getCompanyId } from "../../../utils/userUtils";
import { getTreePartById } from '../../../utils/constant';

class DeviceHeaderContainer extends Component {

  static propTypes = {
    companyTree: PropTypes.array,
    catTree: PropTypes.array,
    useStatusTree: PropTypes.array,
    procatTree: PropTypes.array,
    search: PropTypes.object,
    userProfile: PropTypes.object,
    installationList: PropTypes.array,
    installationUnitList: PropTypes.array,
    installationAreaList: PropTypes.array,
    companyList: PropTypes.array,
  };

  componentDidMount() {
    this.props.onhandleInitDeviceHeader(this.props.query, true);
  }

  handleCreate(event) {
    this.props.onhandleCreate()
  }

  handleExportCsv = () => {
    this.props.onhandleExportCsv()
  }

  handleSearch(event) {
    this.props.onhandleSearch({ offset: 0, size: 5, sort: "-update_time" });
  }

  handleSelect = (value, field) => {
    let newSearch;
    switch (field) {
      case 'companyId':
        newSearch = {
          ...this.props.search,
          companyId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //联动项目
        this.props.onhandleResetSearchField(value.target.value, 'companyId');
        break;
      case 'cat':
        newSearch = {
          ...this.props.search,
          cat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //this.props.onhandleResetSearchField(value, 'cat');
        break;
      case 'useStatus':
        newSearch = {
          ...this.props.search,
          useStatus: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //this.props.onhandleResetSearchField(value, 'status');
        break;
      case 'status':
        newSearch = {
          ...this.props.search,
          status: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //this.props.onhandleResetSearchField(value, 'status');
        break;
      case 'procat':
        newSearch = {
          ...this.props.search,
          procat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //this.props.onhandleResetSearchField(value, 'status');
        break;
      case 'extcat':
        newSearch = {
          ...this.props.search,
          extcat: value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //this.props.onhandleResetSearchField(value, 'status');
        break;
      case 'installId':
        newSearch = {
          ...this.props.search,
          installId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        //联动项目
        this.props.onhandleResetSearchField(value.target.value, 'installId');
        break;
      case 'unitId':
        newSearch = {
          ...this.props.search,
          unitId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      case 'areaId':
        newSearch = {
          ...this.props.search,
          areaId: value.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  }

  handleClearSearch() {
    this.props.onhandleClearSearch();
  }

  handleCheckPermission(route) {
    this.props.onhandleCheckPermission(route)
  }

  handleChange = (event, field) => {
    let newSearch;
    switch (field) {
      case 'search':
        newSearch = {
          ...this.props.search,
          search: event.target.value
        };
        this.props.onhandleUpdateEditSearch(newSearch);
        break;
      default:
      // do nothing
    }
  };

  trimCompanyTree() {
    let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
    let tree = getTreePartById(companyId, this.props.companyTree);
    return tree
  }

  render() {
    return (
      <div>
        <DeviceHeader
          exportCsv={this.handleExportCsv.bind(this)}
          onhandleCreate={this.handleCreate.bind(this)}
          companyTree={this.trimCompanyTree()}
          companyList={this.props.companyList}
          installationList={this.props.installationList}
          installationUnitList={this.props.installationUnitList}
          installationAreaList={this.props.installationAreaList}
          catTree={this.props.catTree}
          useStatusTree={this.props.useStatusTree}
          procatTree={this.props.procatTree}
          search={this.props.search}
          userProfile={this.props.userProfile}
          onhandleSearch={this.handleSearch.bind(this)}
          onhandleSelectChange={this.handleSelect.bind(this)}
          onhandleChange={this.handleChange.bind(this)}
          onhandleClearSearch={this.handleClearSearch.bind(this)}
          extcatTree={this.props.extcatTree}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyTree: state.constant.companyTree,
    companyList: state.constant.companies,
    search: state.devices.search,
    userProfile: state.auth.userProfile,
    catTree: state.trees.deviceCategoryTree,
    useStatusTree: state.devices.useStatusTree,
    procatTree: state.devices.procatTree,
    installationList: state.installation.installationList ? state.installation.installationList.list : [],
    installationUnitList: state.installation.installationUnitList,
    installationAreaList: state.installation.installationAreaList,
    currentUserInfo: state.auth.currentUserInfo,
    csvData: state.devices.csvData,
    query: state.router.location.query,
    extcatTree: state.devices.extcatTree,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleCreate: () => {
      dispatch(initCreate());
    },
    onhandleExportCsv: () => {
      dispatch(exportCsv());
    },
    onhandleInitDeviceHeader: (query, reload) => {
      dispatch(initDeviceHeader(query, reload));
    },
    onhandleUpdateEditSearch: (search) => {
      dispatch(setSearch(search));
    },
    onhandleSearch: (query) => {
      dispatch(getDeviceList(query));
    },
    onhandleClearSearch: () => {
      dispatch(resetSearch());
    },
    onhandleResetSearchField: (value, field) => {
      dispatch(resetSearchField(value, field));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceHeaderContainer)
