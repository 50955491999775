import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { getDateByTimestamp } from '../../../../../utils/datetime';
import { localization, options, style } from "../../../../../utils/mtable";
import * as _ from 'lodash';


const TemporaryRegulationPlanList = props => {
    const {
        classes,
        tempPlans,
        onDetailedInfo,
        // constants,
        //userProfile,
        onhandleCreate
    } = props;

    const tableOptions = {
        ...options,
        addRowPosition: 'first',
        showTitle: false,
    }
    const assemblycolumns = () => {

        let columns = [
            {
                title: '计划名称',
                field: 'planName',
                render: row => row ? <Typography>{row.planName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? <Typography>{row.opentext}</Typography> : '',
                editable: 'never'
            },
            {
                title: '创建时间',
                field: 'createTime',
                type: 'date',
                render: row => row ? <Typography>{getDateByTimestamp(row.createTime)}</Typography> : '',
                editable: 'never'
            }/*,
            {
                title: '状态',
                field: 'status',
                render: row => row ? <Typography>{getNameByTypeAndCode(constants, 'STATUS', row.status)}</Typography> : '',
                editable: 'never'
            }*/
        ]

        return columns
    }
    const assemblyData = () => {

        if (!tempPlans || tempPlans.length === 0)
            return []

        //排序按业务线和指标排序
        let tempPlansSort = _.sortBy(tempPlans, ['createTime'])

        let assemblyData = []
        let preCreateTime = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in tempPlansSort) {
            let row = {
                num: tempPlansSort.length - Number(idx),
                ...tempPlansSort[idx]
            }

            if (preCreateTime === tempPlansSort[idx].unitId) {
                row['createTimeDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['createTimeDuplicated'] = false
                preCreateTime = tempPlansSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }

        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }

    return (
        <div alignitems="center" >
            <Grid className={classes.detailtable}>
                <MaterialTable
                    title='临时计划列表'
                    columns={assemblycolumns()}
                    data={assemblyData()}
                    options={tableOptions}
                    localization={localization}
                    style={style}
                    actions={
                        [rowData => ({
                            icon: 'book',
                            tooltip: '详情',
                            onClick: (event, rowData) => {
                                onDetailedInfo(event, rowData.planId)
                            }
                        }),
                        {
                            icon: 'add',
                            tooltip: '     ',
                            isFreeAction: true,
                            onClick: (event) => onhandleCreate(event)
                        }]}
                >
                </MaterialTable>
            </Grid>
        </div>
    );
};

TemporaryRegulationPlanList.propTypes = {
    classes: PropTypes.object.isRequired,
    tempPlans: PropTypes.array.isRequired,
    //getBus: PropTypes.func.isRequired,
    onDetailedInfo: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    onhandleCreate: PropTypes.func.isRequired
};
TemporaryRegulationPlanList.defaultProps = {
    tempPlans: [],
    userProfile: {},
    constant: {},
}
export default withStyles(TemporaryRegulationPlanList);

