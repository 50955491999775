import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import {
    MenuItem,
    InputAdornment
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { getDateByTimestamp } from '../../../../utils/datetime';
import { Prompt } from 'react-router-dom';

const UserEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleBack,
        onhandleCheck,
        currentUser,
        departmentList,
        userError,
        userChange
    } = props;

    const renderStatus = (status) => {
        if (status === 'new') {
            return "未激活"
        } else if (status === 'active') {
            return "有效"
        } else {
            return "无效"
        }
    }

    const textFieldDefault = {
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    const dptFieldDefault = {
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: currentUser.userId ? true : false
    }

    return (
        <div>
            <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h3" >
                        {currentUser.userId ? "编辑用户" : "新建用户"}
                    </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.gridContainer} sm={12} lg={8}>
                    {currentUser.userId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                用户编号
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    value={currentUser.userId || ''}
                                    variant="outlined"
                                    size='small'
                                    disabled
                                />
                            </Grid>
                        </Grid>) : null
                    }

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            用户名
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                size="small"
                                error={userError.userName !== ''}
                                helperText={userError.userName || ''}
                                className={classes.textField}
                                value={currentUser.userName || ''}
                                variant="outlined"
                                onChange={event => onhandleChange(event, 'userName')}
                                onBlur={event => onhandleCheck(event, 'userName')}
                                disabled={currentUser.userId && currentUser.userName !== ''}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            姓
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={userError.lastName !== ''}
                                helperText={userError.lastName || ''}
                                value={currentUser.lastName || ''}
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                onChange={event => onhandleChange(event, 'lastName')}
                                onBlur={event => onhandleCheck(event, 'lastName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            名
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                error={userError.firstName !== ''}
                                helperText={userError.firstName || ''}
                                value={currentUser.firstName || ''}
                                variant="outlined"
                                size="small"
                                onChange={event => onhandleChange(event, 'firstName')}
                                onBlur={event => onhandleCheck(event, 'firstName')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            邮箱地址
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={userError.email !== ''}
                                helperText={userError.email || ''}
                                value={currentUser.email || ''}
                                type="email"
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                onChange={event => onhandleChange(event, 'email')}
                                onBlur={event => onhandleCheck(event, 'email')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            手机号码
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={userError.phone !== ''}
                                helperText={userError.phone || ''}
                                value={currentUser.phone || ''}
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                onChange={event => onhandleChange(event, 'phone')}
                                onBlur={event => onhandleCheck(event, 'phone')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            座机号码
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                error={userError.opentext1 !== ''}
                                helperText={userError.opentext1 || ''}
                                className={classes.textField}
                                placeholder={'多个座机号码请用逗号[,]间隔'}
                                value={currentUser.opentext1 || ''}
                                variant="outlined"
                                size="small"
                                onChange={event => onhandleChange(event, 'opentext1')}
                                onBlur={event => onhandleCheck(event, 'opentext1')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            用户类型
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                select
                                name='userType'
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                InputProps={textFieldDefault}
                                onChange={event => onhandleChange(event, 'userType')}
                                value={currentUser.userType !== undefined ? currentUser.userType : 0}
                            >
                                <MenuItem key={0} value={0}>正式员工</MenuItem>
                                <MenuItem key={1} value={1}>返聘员工</MenuItem>
                                <MenuItem key={2} value={2}>外包员工</MenuItem>
                                <MenuItem key={3} value={3}>劳务人员</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.gridItem} sm={6} md={4}>
                        <Typography className={classes.label}>
                            所属部门
                        </Typography>
                        <Grid item container className={classes.formContainer}>
                            <TextField
                                select
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                name='dptId'
                                InputProps={dptFieldDefault}
                                onChange={event => onhandleChange(event, 'dptId')}
                                value={currentUser.dptId !== undefined ? currentUser.dptId : 0}
                            >
                                {departmentList ?
                                    departmentList.map(data => (
                                        <option value={data.code} key={data.code}>{data.name}</option>
                                    )) : null
                                }
                            </TextField>
                        </Grid>
                    </Grid>

                    {!currentUser.userId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                初始密码
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={currentUser.password !== '' && userError.password !== ''}
                                    helperText={currentUser.password !== '' && userError.password !== '' ? userError.password : ''}
                                    className={classes.textField}
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    value={currentUser.password || ''}
                                    onChange={event => onhandleChange(event, 'password')}
                                    onBlur={event => onhandleCheck(event, 'password')}
                                />
                            </Grid>
                        </Grid>) : null}
                    {!currentUser.userId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                确认密码
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <TextField
                                    error={currentUser.confirmPassword !== '' && userError.confirmPassword !== ''}
                                    helperText={currentUser.confirmPassword !== '' && userError.confirmPassword !== '' ? userError.confirmPassword : ''}
                                    className={classes.textField}
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    value={currentUser.confirmPassword || ''}
                                    onChange={event => onhandleChange(event, 'confirmPassword')}
                                    onBlur={event => onhandleCheck(event, 'confirmPassword')}
                                />
                            </Grid>
                        </Grid>) : null}

                    {currentUser.userId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                当前状态
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {renderStatus(currentUser.status)}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }

                    {currentUser.userId ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                创建时间
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {getDateByTimestamp(currentUser.createTime, true)}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }

                    {currentUser.userId && currentUser.updateTime ? (
                        <Grid item container className={classes.gridItem} sm={6} md={4}>
                            <Typography className={classes.label}>
                                修改时间
                            </Typography>
                            <Grid item container className={classes.formContainer}>
                                <Typography className={classes.readOnly}>
                                    {getDateByTimestamp(currentUser.updateTime, true)}
                                </Typography>
                            </Grid>
                        </Grid>) : null
                    }




                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Button variant="contained" color="primary"
                            disabled={!userChange} className={classes.button}
                            onClick={event => onhandleSave(event)}
                        >
                            保存
                        </Button>
                        <Button variant="outlined" color="primary" className={classes.button}
                            onClick={event => onhandleBack(event)}
                        >
                            返回
                        </Button>
                    </Grid>


                </Grid>
                <Prompt message="数据未保存,确定要离开？" when={userChange} />
            </Card>
        </div>
    );
};

UserEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    userError: PropTypes.object.isRequired,
    userChange: PropTypes.bool.isRequired
};

export default withStyles(UserEdit);
