import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TodoComponent from '@/components/Home/Todo';
import {
    getTodoList,
    openTodo,
} from "@/redux/modules/todo";
import { getMoment } from '@/utils/datetime';


class TodoContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: getMoment()(),
        }
    }

    componentDidMount() {
        this.refreshTodos()
    }

    handleLastWeek() {
        this.setState({
            selectedDate: this.state.selectedDate.subtract(7, 'days')
        }, () => this.refreshTodos())
    }

    handleNextWeek() {
        this.setState({
            selectedDate: this.state.selectedDate.add(7, 'days')
        }, () => this.refreshTodos())
    }

    handleOpenTodo(path) {
        this.props.onhandleOpenTodo(path)
    }


    refreshTodos() {

        const { currentUserInfo } = this.props
        const { selectedDate } = this.state
        this.props.onhandleGetTodoList(currentUserInfo.userId, null, null,
            selectedDate.startOf('week').format('YYYY-MM-DD'), selectedDate.endOf('week').format('YYYY-MM-DD'));
    }

    render() {
        return (
            <div>
                <TodoComponent
                    currentUserInfo={this.props.currentUserInfo}
                    todos={this.props.todos}
                    selectedDate={this.state.selectedDate}
                    onhandleNextWeek={this.handleNextWeek.bind(this)}
                    onhandleLastWeek={this.handleLastWeek.bind(this)}
                    onhandleOpenTodo={this.handleOpenTodo.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        todos: state.todo.todos,
    };
};
//getRegulations 
const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetTodoList: (userId, todoPriority, todoType, startDate, endDate, status) => {
            dispatch(getTodoList(userId, todoPriority, todoType, startDate, endDate, status))
        },

        onhandleOpenTodo: (path) => {
            dispatch(openTodo(path))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TodoContainer);