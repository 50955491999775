import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, DialogContent, Dialog, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from './styles';
import { getDateByTimestamp } from '../../../../../utils/datetime'
import * as _ from 'lodash';

const OnDutyCurrentPannel = ({
    classes,
    activity,
    dutyRecords,
    leaders,
    editDisabled,
    companyTopology,
    onhandleClosePannel,
    onhandleChangeLeader,
    onhandleUpdateActivity,
}) => {

    const company = _.find(companyTopology, c => { return c.companyId === activity.companyId })
    if (company) {
        activity.companyName = company.companyName
        activity.companyAlias = company.companyAlias
    }

    const getLeaderPhones = () => {

        if (activity && activity.users && activity.users.length > 0) {
            const theLeaders = _.intersectionBy(activity.users, leaders, 'userId')
            return _.chain(theLeaders).map('phone').join(',').value()
        }
        return ''

    }

    return (
        <div alignitems="center" >
            {!activity || !activity.actId ? '' :
                <Dialog open={activity && activity.actId ? true : false} onClose={onhandleClosePannel} aria-labelledby="form-dialog-title" className={classes.dialog}>
                    {/*<Dialog open={false} onClose={onhandleClosePannel} aria-labelledby="form-dialog-title" className={classes.dialog}>*/}
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>带班值班详情</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="活动名称"
                                    defaultValue={activity.activity}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="单位"
                                    defaultValue={activity.companyAlias}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                            {/* {
                                activity.users && activity.users.length > 0 &&
                                <Grid item className={classes.item}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="请输入"
                                        label="值班领导"
                                        defaultValue={activity.users[0].lastName + activity.users[0].firstName}
                                        InputProps={{
                                            readOnly: true,
                                        }} />
                                </Grid>} */}
                            <Grid item className={classes.item}>
                                <Autocomplete
                                    variant="outlined"
                                    disabled={editDisabled}
                                    multiple
                                    filterSelectedOptions
                                    id={"users-standard"}
                                    options={leaders}
                                    getOptionLabel={option => option.lastName + '' + option.firstName}
                                    defaultValue={activity.users}
                                    onChange={onhandleChangeLeader}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            {...params}
                                            id={"users-standard-text"}
                                            label="值班人员"
                                            placeholder="选择人员"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="电话"
                                    defaultValue={getLeaderPhones()}
                                    InputProps={{
                                        //readOnly: true,
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="日期"
                                    defaultValue={getDateByTimestamp(activity.activityTime)}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                            {dutyRecords && dutyRecords.map((record, index) => (
                                <Grid item key={record.recordId} className={classes.item}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="请输入"
                                        label={"值班记录"}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        defaultValue={record.content}
                                        InputProps={{
                                            readOnly: true,
                                        }} />
                                </Grid>))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={onhandleUpdateActivity}>
                            保存</Button>
                        <Button variant="text" color="primary" onClick={onhandleClosePannel}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

OnDutyCurrentPannel.propTypes = {
    classes: PropTypes.object.isRequired,
};
OnDutyCurrentPannel.defaultProps = {

}


export default withStyles(OnDutyCurrentPannel);