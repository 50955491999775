import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {
        padding: '8px 8px',
        backgroundColor: 'white',
    },
    toolbarContainer: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    label: {
        marginTop: 4
    },
    icon: {
        minWidth: '40px',
        width: '56px',
        height: '36px',
        backgroundColor: '#3f51b5',
        lineHeight: '1.75',
        color: '#FFFFFF',
        borderRadius: '4px',
        padding: '6px 16px',
        '&:hover': {
            backgroundColor: '#1a237e',
            boxShadow:
                '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        }
    },
});


export default withStyles(styles);