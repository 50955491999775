import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MServiceConfigComponent from '@/components/ManufactureManagement/ManufactureService/MServiceConfig';
import MServiceActionComponent from '@/components/ManufactureManagement/ManufactureService/MServiceAction';
import { getActivities } from '@/redux/modules/manufacture/manufactureActivity';
import {
    addManufactureService, updateManufactureService, getManufactureServices,
} from '@/redux/modules/manufacture/manufactureService';
import { createActionMessage } from '@/redux/modules/message';
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { getMaterialGroups, getServiceList, getMaterialListByGroup, getRawMaterialList } from '@/redux/modules/material';
import { SERVICE_TYPE_COMMISSION, COST_SHARE_TYPE_SHARE_NONE, SERVICE_TYPE_OUTER } from '@/utils/manufactureUtils';
import { formSelectTree, findParent, USED_MATERIAL_GROUPS, RAW_MATERIAL_GROUPS, arrayToTree, treeToArray } from '@/utils/constant';
import { getInstallations } from '@/redux/modules/installation';

import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
// import { dateCompare } from '@/utils/datetime';

const copyService = (service, allDpts, companyList) => {
    let serviceCopy = JSON.parse(JSON.stringify(service))
    if (service.serviceCompany && companyList) serviceCopy.serviceSide = companyList.find(company => company.companyId === service.serviceCompany).companyName
    if (serviceCopy.serviceDpts) {
        serviceCopy.provideDpts = _.filter(allDpts, d => {
            return _.find(serviceCopy.serviceDpts, dd => { return dd.dptId === d.dptId && dd.dependType === 'SERVICE' })
        })
        serviceCopy.requireDpts = _.filter(allDpts, d => {
            return _.find(serviceCopy.serviceDpts, dd => { return dd.dptId === d.dptId && dd.dependType === 'REQUIRE' })
        })
    }

    if (service.isTemp) {
        serviceCopy.isTemp = 1
    } else {
        serviceCopy.isTemp = 0
    }

    if (serviceCopy.activities) {
        serviceCopy.activities.forEach(activity => {
            if (activity.manufactureAttrs) {
                activity.commissionService = activity.manufactureAttrs[0].commissionService;
                // activity.shareType = activity.manufactureAttrs[0].shareType;
                activity.isRequire = activity.manufactureAttrs[0].isRequire;
            }
        })
    }

    // 避免出现group和具体Id值重复问题
    if (serviceCopy.materialId) {
        serviceCopy.materialId = '_' + serviceCopy.materialId;
    }
    if (serviceCopy.productId) {
        serviceCopy.productId = '_' + serviceCopy.productId;
    }

    if (serviceCopy.bomRelations && serviceCopy.bomRelations.length > 0) {
        serviceCopy.bomRelations.forEach(bomRelation => {
            bomRelation.materialId = '_' + bomRelation.materialId;
        })
    }
    return serviceCopy
}

class MServiceConfigContainer extends Component {
    static propTypes = {
        service: PropTypes.object,
        isMainCompany: PropTypes.bool,
        opreationType: PropTypes.number,
        serviceType: PropTypes.string,
        onhandleClose: PropTypes.func,
        roleEditFlag: PropTypes.bool,
    }
    constructor(props) {
        super(props)
        this.state = {
            openFlag: false,
            openType: 1,
            provideCompaniesSelect: [],
            provideDptsSelect: [],
            requireCompaniesSelect: [],
            requireDptsSelect: [],
            tabValue: this.props.service && this.props.service.activities[0] ?
                this.props.service.activities[0].activityId : -1,
            activityData: {},
            activities: [],
            pageService: copyService(this.props.service, this.props.departmentList, this.props.companyList),//与传入的参数service区分开
            anchorEl: null,
        }
    }

    componentDidMount() {
        const { isMainCompany, companyList, departmentList, currentUserInfo, service, serviceType } = this.props;
        let myCompany = _.find(companyList, c => { return c.companyId === currentUserInfo.companyId })
        let provideCompaniesSelect = companyList.filter(ele => ele.pid && ele.pid !== null);
        if (!isMainCompany) {
            provideCompaniesSelect = [myCompany]
            if (serviceType !== SERVICE_TYPE_COMMISSION) {
                let reqMainCompanyId = companyList.find(ele => ele.companyId === currentUserInfo.companyId).pid;
                //获取外委服务列表
                this.props.getCommissionServices(currentUserInfo.companyId, reqMainCompanyId);
            }
        } else {
            if (serviceType !== SERVICE_TYPE_COMMISSION) {
                //获取外委服务列表
                this.props.getCommissionServices();
            }
        }

        let provideDptsSelect = []
        if (service.serviceCompany) {
            provideDptsSelect = _.filter(departmentList, d => { return d.companyId === service.serviceCompany })
        }
        let requireDptsSelect = []
        if (service.requireCompany) {
            requireDptsSelect = _.filter(departmentList, d => { return d.companyId === service.requireCompany })
        }

        let requireCompaniesSelect = companyList;
        if (serviceType !== SERVICE_TYPE_COMMISSION) {
            requireCompaniesSelect = companyList.filter(ele => ele.pid && ele.pid !== null);
        }

        this.setState({
            provideCompaniesSelect: provideCompaniesSelect,
            requireCompaniesSelect: requireCompaniesSelect,
            provideDptsSelect: provideDptsSelect,
            requireDptsSelect: requireDptsSelect,
        })

        // 取得活动列表
        this.props.getActivities();

        // 取得阿米巴指标
        this.props.getAmoebaIndexes().then(
            res => {
                let treeList = []
                const variable = _.find(res.payload, index => index.subCode === 'VARIABLE');
                treeList.push({
                    name: variable.subName,
                    code: variable.subId,
                    pcode: 0
                })
                const variableSubs = _.filter(res.payload, index => index.subParentId === variable.subId);
                _.forEach(variableSubs, sub => {
                    treeList.push({
                        name: sub.subName,
                        code: sub.subId,
                        pcode: sub.subParentId
                    })
                    const temps = _.filter(res.payload, index => index.subParentId === sub.subId);
                    if (temps) {
                        _.forEach(temps, temp => {
                            treeList.push({
                                name: temp.subName,
                                code: temp.subId,
                                pcode: temp.subParentId
                            })
                        })
                    }
                })
                const fixedfee = _.find(res.payload, index => index.subCode === 'FIXEDFEE');
                treeList.push({
                    name: fixedfee.subName,
                    code: fixedfee.subId,
                    pcode: 0
                })
                const fixedfeeSubs = _.filter(res.payload, index => index.subParentId === fixedfee.subId);
                _.forEach(fixedfeeSubs, sub => {
                    treeList.push({
                        name: sub.subName,
                        code: sub.subId,
                        pcode: sub.subParentId
                    })
                    const temps = _.filter(res.payload, index => index.subParentId === sub.subId);
                    if (temps) {
                        _.forEach(temps, temp => {
                            treeList.push({
                                name: temp.subName,
                                code: temp.subId,
                                pcode: temp.subParentId
                            })
                        })
                    }
                })
                const amoebaIndexTree = formSelectTree(treeList, false)
                this.setState({ amoebaIndexTree: amoebaIndexTree });
            }
        )

        // 取得产品分类
        this.props.getMaterialGroups().then(mg => {
            let materialGroupList = _.filter(mg.payload, mg => {
                return USED_MATERIAL_GROUPS.indexOf(mg.extId) > -1
            })
            let treeList = [];
            materialGroupList.forEach(element => {
                treeList.push({
                    name: element['groupName'],
                    code: element['extId'],
                    pcode: 0
                });
            });
            this.props.getMaterialListByGroup(USED_MATERIAL_GROUPS, (data) => {
                if (myCompany.companyName === '重载膜包装厂' || myCompany.companyName === '实华福利厂') {
                    data = _.filter(data, d => { return (d.orgId === 101740 || !d.orgId) }) //大连实华福利厂
                } else {
                    data = _.filter(data, d => { return (d.orgId === 1 || !d.orgId) }) //大连石油化工工程公司
                }
                data.forEach(element => {
                    treeList.push({
                        name: element.materialName + (element.specification ? '/' + element.specification : ''),
                        code: '_' + element.materialId,// 避免出现group和具体Id值重复问题
                        pcode: element.materialGroup
                    });
                });
                const materialGroupTree = formSelectTree(treeList, false)
                this.setState({ materialGroupTree: materialGroupTree });
            });

            this.props.getRawMaterialListByGroup(_.merge(USED_MATERIAL_GROUPS, RAW_MATERIAL_GROUPS), (data) => {
                if (myCompany.companyName === '重载膜包装厂' || myCompany.companyName === '实华福利厂') {
                    data = _.filter(data, d => { return d.orgId === 101740 }) //大连实华福利厂
                } else {
                    data = _.filter(data, d => { return d.orgId === 1 }) //大连石油化工工程公司
                }
                data.forEach(element => {
                    treeList.push({
                        name: element.materialName + (element.specification ? '/' + element.specification : ''),
                        code: '_' + element.materialId,// 避免出现group和具体Id值重复问题
                        pcode: element.materialGroup
                    });
                });
                const rawMaterialGroupTree = formSelectTree(treeList, true)
                this.setState({ rawMaterialGroupTree: rawMaterialGroupTree });
            });
        });

        // 取得指标列表
        this.props.getBusinessGroupList();

        // 取得产品服务列表 -- 从erp导出的服务名称
        this.props.getServiceList()


        let installsQuery = {}
        if (myCompany.pid) {
            installsQuery.companyId = myCompany.companyId
        }
        this.props.handleGetInstallations(installsQuery)
    }

    // 服务配置画面change事件
    handleServiceChange = (event, name, autoCompleteValue) => {
        const { departmentList, companyList } = this.props;
        let service = { ...this.state.pageService };
        if (name === 'serviceSide') {
            let company = companyList.find(ele => ele.companyName === autoCompleteValue);
            let provideDptsSelect = [];
            if (company) {
                let selectCompanyId = company.companyId;
                provideDptsSelect = _.filter(departmentList, d => { return d.companyId === selectCompanyId })
                service.serviceCompany = selectCompanyId;
            }

            service.serviceSide = autoCompleteValue;
            this.setState({
                provideDptsSelect: provideDptsSelect,
            })
        } else if (name === 'requireSide') {
            let company = companyList.find(ele => ele.companyName === autoCompleteValue);
            let requireDptsSelect = [];
            if (company) {
                let selectCompanyId = company.companyId;
                requireDptsSelect = _.filter(departmentList, d => { return d.companyId === selectCompanyId })
                service.requireCompany = selectCompanyId;
            }

            service.requireSide = autoCompleteValue;
            this.setState({
                requireDptsSelect: requireDptsSelect,
            })
        } else if (name === 'isTemp') {
            service.isTemp = event.target.value
        } else if (name === 'startDate') {
            const date = new Date(event)
            service.startDate = date
        } else if (name === 'installId') {
            service.installId = parseInt(event.target.value)
        } else {
            if (autoCompleteValue) {
                service[name] = autoCompleteValue;
            } else if (name === 'materialGroup' || name === 'productGroup') {
                const materialGroupTree = this.state.materialGroupTree;
                const checkGroup = materialGroupTree.find(ele => ele.value === event.value);
                if (checkGroup) {
                    service[name] = event.value;
                    if (name === 'materialGroup') {
                        service.materialId = undefined
                    }
                    if (name === 'productGroup') {
                        service.productId = undefined
                    }
                } else {
                    let mg = findParent(event.value, 'value', materialGroupTree)
                    if (name === 'materialGroup') {
                        service.materialGroup = mg.pcode
                        service.materialId = event.value
                    };
                    if (name === 'productGroup') {
                        service.productGroup = mg.pcode
                        service.productId = event.value
                    };
                }
            } else if (name === 'costSub') {
                const amoebaIndexTree = this.state.amoebaIndexTree;
                const checkGroup = amoebaIndexTree.find(ele => ele.value === event.value);
                if (checkGroup) {
                    service[name] = event.value;
                    if (name === 'costSub') {
                        service.materialId = undefined
                    }
                } else {
                    if (name === 'costSub') {
                        service.costSub = event.value
                    };
                }
            } else {
                service[name] = event.target.value;
                if (name === 'serviceType' && service[name] === SERVICE_TYPE_OUTER) {
                    service.costSharing = COST_SHARE_TYPE_SHARE_NONE;
                }
            }
        }
        this.setState({ pageService: service });
    }

    handleNameClose = (event, reason, name) => {
        const { companyList, departmentList } = this.props;
        if (reason === 'blur' && event.target.value != null) {
            let service = { ...this.state.pageService };
            service[name] = event.target.value;
            if (name === 'serviceSide') {
                let company = companyList.find(com => com.companyName === event.target.value);
                let provideDptsSelect = [];
                if (company) {
                    service.serviceCompany = company.companyId;
                    provideDptsSelect = _.filter(departmentList, d => { return d.companyId === service.serviceCompany })
                } else {
                    service.serviceCompany = null;
                }
                this.setState({
                    provideDptsSelect: provideDptsSelect,
                })
            } else if (name === 'requireSide') {
                let requireDptsSelect = [];
                let company = companyList.find(com => com.companyName === event.target.value);
                if (company) {
                    service.requireCompany = company.companyId;
                    requireDptsSelect = _.filter(departmentList, d => { return d.companyId === service.requireCompany })
                } else {
                    service.requireCompany = null;
                }
                this.setState({
                    requireDptsSelect: requireDptsSelect,
                })
            }
            this.setState({
                pageService: service
            })
        }
    }

    handleProvideDptsChange = (event, dpts) => {
        let service = {
            ...this.state.pageService,
            provideDpts: dpts,
        }
        this.setState({ pageService: service })
    }

    handleRequireDptsChange = (event, dpts) => {
        let service = {
            ...this.state.pageService,
            requireDpts: dpts,
        }
        this.setState({ pageService: service })
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue,
        })
    }

    handleAdd = () => {
        this.setState({
            activityData: {},
            openFlag: true,
            openType: 1,
            activities: this.props.activities,
        });
    }

    handleDelete = () => {
        let activityId = this.state.tabValue;
        let pageService = this.state.pageService;
        if (activityId) {
            let deleteData = pageService.activities.find(item => item.activityId === activityId);
            pageService.activities.splice(pageService.activities.indexOf(deleteData), 1)
        }

        this.setState({
            pageService: pageService,
            tabValue: pageService.activities.length > 0 ? pageService.activities[0].activityId : -1,
        });
    }

    handleAttrUpdate = (newData, oldData, activityId) => {
        let rowIndex = oldData.tableData.id;
        let pageService = this.state.pageService;
        pageService.activities.forEach((element) => {
            if (element.activityId === activityId)
                element.manufactureAttrs[rowIndex] = newData;
        });
        this.setState({
            pageService: pageService,
        });
        return Promise.resolve(true);
    }

    handleSave = () => {
        const { serviceType, opreationType, addManufactureService, updateManufactureService } = this.props;
        const { pageService } = this.state;

        //数据编集
        let service = {
            ...pageService,
            serviceType: serviceType,
            activities: [],
        };
        if (!pageService.isTemp || pageService.isTemp === '0') {
            service.isTemp = 0
        } else {
            service.isTemp = 1
        }
        if (serviceType !== SERVICE_TYPE_COMMISSION) {
            service.serviceType = pageService.serviceType;
        }
        // 避免出现group和具体Id值重复问题
        // 取得原本ID
        service.materialId = service.materialId ? service.materialId.substring(1) : null;
        service.productId = service.productId ? service.productId.substring(1) : null;

        let serviceDpts = [];
        if (pageService.provideDpts && pageService.provideDpts.length > 0) {
            _.map(pageService.provideDpts, d => {
                serviceDpts.push({
                    dptId: d.dptId,
                    serviceId: pageService.serviceId,
                    dependType: 'SERVICE'
                })
            })
        }
        if (pageService.requireDpts && pageService.requireDpts.length > 0) {
            _.map(pageService.requireDpts, d => {
                serviceDpts.push({
                    dptId: d.dptId,
                    serviceId: pageService.serviceId,
                    dependType: 'REQUIRE'
                })
            })
        }
        service.serviceDpts = serviceDpts;
        service.shiftCount = 0;
        service.version = 0;
        service.activities = [];
        pageService.activities.forEach(element => {
            let serviceActivity = {
                activityId: element.activityId,
                commissionService: element.commissionService,
                shareType: element.shareType,
                isRequire: element.isRequire,
                attrs: []
            };
            element.manufactureAttrs.forEach(attrElement => {
                let attr = {
                    attrId: 0,
                    attrAlias: "",
                    attrDefault: "",
                    attrUnit: "",
                    attrPermission: "",
                    seq: 0
                };
                attr.serviceConfigId = attrElement.serviceConfigId;
                attr.attrId = attrElement.attrId;
                attr.attrAlias = attrElement.attrAlias;
                attr.attrDefault = attrElement.attrDefault;
                attr.attrUnit = attrElement.attrUnit;
                attr.attrPermission = attrElement.attrPermission;
                attr.seq = attrElement.seq;
                serviceActivity.attrs.push(attr);
            });
            service.activities.push(serviceActivity);
        });
        if (service.activities.length === 0) {
            let message = '请配置具体活动后再保存！！！';
            this.props.createActionMessage('warning', message);
            return;
        }

        if (service.bomRelations && service.bomRelations.length > 0) {
            service.bomRelations = _.reject(service.bomRelations, function (o) { return !o.materialId || o.materialId === '' })
        }

        if (service.bomRelations && service.bomRelations.length > 0) {
            service.bomRelations.forEach(bomRelation => {
                bomRelation.stringBomId = _.toString(bomRelation.bomId);
                bomRelation.stringParentBom = _.toString(bomRelation.parentBom);
                bomRelation.stringRootBom = _.toString(bomRelation.rootBom);
                bomRelation.bomId = null;
                bomRelation.parentBom = null;
                bomRelation.rootBom = null;
                bomRelation.materialId = bomRelation.materialId ? bomRelation.materialId.substring(1) : null;
            })

            let bomRelationTree = arrayToTree(_.cloneDeep(service.bomRelations), 'stringBomId', 'stringParentBom');
            let bomRelationList = treeToArray(_.cloneDeep(bomRelationTree));
            service.bomRelations = bomRelationList;
        } else {
            service.bomRelations = [];
        }



        if (opreationType === 1) {
            // 操作种类:增加
            addManufactureService(service).then(
                () => { this.handleClose('save'); }
            );
        } else if (opreationType === 2) {
            // 操作种类:更新
            updateManufactureService(service).then(
                () => { this.handleClose('save'); }
            );
        }
    }

    // 页面close事件
    handleClose = (closeType) => {
        this.props.onhandleClose(closeType);
    }

    // 弹出框close事件
    handleActionClose = () => {
        this.setState({
            openFlag: false,
            anchorEl: null,
        })
    }

    // 弹出框保存事件
    handleActionSave = () => {
        let pageService = this.state.pageService;
        let activityData = this.state.activityData;
        let checkFlag = true;
        for (let index = 0; index < pageService.activities.length; index++) {
            let element = pageService.activities[index];
            if (element.activityId === activityData.activityId) {
                let message = '增加活动失败，活动名:[' + activityData.activityName + ']在活动属性列表中已存在';
                this.props.createActionMessage('warning', message);
                checkFlag = false;
                break;
            }
        }
        //设定默认分摊方式
        activityData.shareType = COST_SHARE_TYPE_SHARE_NONE;
        activityData.isRequire = 0;

        if (checkFlag) {
            // 设定字段初期値
            if (activityData.manufactureAttrs && activityData.manufactureAttrs.length > 0) {
                activityData.manufactureAttrs.forEach(element => {
                    element.serviceConfigId = null;
                    element.attrAlias = '';
                    element.attrDefault = ' ';
                    element.attrUnit = ' ';
                    element.attrPermission = 'W';
                    element.seq = 0
                });
            }
            pageService.activities.push(activityData);
            this.setState({
                openFlag: false,
                pageService: pageService,
                activityData: {},
                tabValue: activityData.activityId,
                anchorEl: null,
            })
        }
    }

    handleActionChange = (event) => {
        let activityData = { ...this.state.activityData };
        let commissionService = activityData.commissionService ? activityData.commissionService : '';
        let activities = this.props.activities;
        activityData = activities.find(element => element.activityId === event.target.value);
        activityData.commissionService = commissionService;
        this.setState({ activityData: activityData });
    }

    handleComServiceChange = (event) => {
        const serviceId = event.target.value;
        let activityData = { ...this.state.activityData };
        activityData.commissionService = serviceId;
        const service = this.props.commissionServices.find(ele => ele.serviceId === serviceId);
        this.setState({
            activityData: activityData,
            activities: service ? service.activities : [],
        })
    }

    handleComActAdd = () => {
        this.setState({
            activityData: {},
            openFlag: true,
            openType: 2,
            activities: [],
            anchorEl: null,
        });
    }

    handleShareTypeChange = (event, activityId) => {
        let pageService = this.state.pageService;
        let activity = pageService.activities.find(ele => ele.activityId === activityId);
        // activity.shareType = event.target.value;
        activity.isRequire = event.target.checked ? 1 : 0;
        this.setState({
            pageService: pageService,
        });
    }

    handleClickAdd = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null,
        })
    }

    handleAddbomRelation() {
        const { pageService } = this.state;
        let id = uuidv4();
        let newBomRelation = {
            bomId: id,
            serviceId: pageService.serviceId,
            parentBom: null,
            rootBom: id,
            stringBomId: id,
            stringParentBom: null,
        };
        newBomRelation.tableData = {
            editing: 'update'
        }
        let mewPageService = _.clone(pageService);
        if (mewPageService.bomRelations) {
            mewPageService.bomRelations.push(newBomRelation);
        } else {
            mewPageService.bomRelations = [];
            mewPageService.bomRelations.push(newBomRelation);
        }

        this.setState({ pageService: mewPageService });
    }

    handleAddSubBomRelation(event, data) {
        const { pageService } = this.state;
        let id = uuidv4();
        let newBomRelation = {
            bomId: id,
            serviceId: pageService.serviceId,
            parentBom: data.bomId,
            rootBom: data.rootBom,
            stringBomId: id,
            stringParentBom: data.bomId,
        };
        newBomRelation.tableData = {
            editing: 'update'
        }
        let mewPageService = _.clone(pageService);
        mewPageService.bomRelations.push(newBomRelation);
        this.setState({ pageService: mewPageService });
    }


    handleUpdateBomRelation(data) {
        const { pageService } = this.state;
        let mewPageService = _.clone(pageService);
        let olddata = _.find(mewPageService.bomRelations, { bomId: data.bomId });
        olddata.materialId = data.materialId;
        olddata.materialName = data.materialName;
        olddata.ratio = data.ratio;
        olddata.opentext = data.opentext;
        this.setState({ pageService: mewPageService });
    }

    handleDeleteBomRelation(event, data) {
        const { pageService } = this.state;
        let mewPageService = _.clone(pageService);
        mewPageService.bomRelations = _.reject(mewPageService.bomRelations, { bomId: data.bomId })
        this.setState({ pageService: mewPageService });
    }

    // handleUpdateBomRelationCancelled(data) {
    //     const { pageService } = this.state;
    //     let mewPageService = _.clone(pageService);
    //     let olddata = _.find(mewPageService.bomRelations, function (o) { return o.tableData });
    //     if (olddata.materialId === '' || olddata.materialId === null) {
    //         mewPageService.bomRelations = _.reject(mewPageService.bomRelations, { bomId: olddata.bomId });
    //     } else {
    //         delete olddata.tableData;
    //     }
    //     this.setState({ pageService: mewPageService });
    // }

    render() {
        return (
            <div>
                <MServiceConfigComponent
                    service={this.state.pageService}//服务数据
                    tabValue={this.state.tabValue}//活动tabValue
                    businessGroupList={this.props.businessGroupList}//服务分类
                    manufactureDicts={this.props.manufactureDicts}//字典表数据
                    serviceList={this.props.serviceList}//服务列表
                    serviceType={this.props.serviceType}
                    installs={this.props.installs} //装置
                    materialGroupTree={this.state.materialGroupTree}//产品树状数据
                    rawMaterialGroupTree={this.state.rawMaterialGroupTree}
                    amoebaIndexTree={this.state.amoebaIndexTree}//阿米巴指标数据
                    provideCompaniesSelect={this.state.provideCompaniesSelect}//选择的供方公司
                    provideDptsSelect={this.state.provideDptsSelect}//选择的供方班组
                    requireCompaniesSelect={this.state.requireCompaniesSelect}//选择的需方公司
                    requireDptsSelect={this.state.requireDptsSelect}//选择的需方班组
                    onhandleChangeTab={this.handleChangeTab.bind(this)}//活动Tab切换
                    onhandleProvideDptsChange={this.handleProvideDptsChange.bind(this)}//供方班组change事件
                    onhandleRequireDptsChange={this.handleRequireDptsChange.bind(this)}//需方班组change事件
                    onhandleChange={this.handleServiceChange.bind(this)}//服务Text框change事件
                    onhandleAdd={this.handleAdd.bind(this)}//增加活动事件
                    onhandleComActAdd={this.handleComActAdd.bind(this)}//增加活动事件
                    onhandleDelete={this.handleDelete.bind(this)}//删除活动事件
                    onhandleSave={this.handleSave.bind(this)}//保存服务
                    onhandleClose={this.handleClose.bind(this)}//关闭画面
                    onhandleAttrUpdate={this.handleAttrUpdate.bind(this)}//活动属性变更
                    onhandleNameClose={this.handleNameClose.bind(this)}//服务名焦点失去事件
                    roleEditFlag={this.props.roleEditFlag} //编辑权限
                    viewFlg={this.props.viewFlg} // 前画面判定0：服务配置;1:外委服务
                    onhandleShareTypeChange={this.handleShareTypeChange.bind(this)}
                    anchorEl={this.state.anchorEl}
                    onhandleClickAdd={this.handleClickAdd.bind(this)}
                    onhandleCloseMenu={this.handleCloseMenu.bind(this)}
                    onhandleAddBomRelation={this.handleAddbomRelation.bind(this)}
                    onhandleUpdateBomRelation={this.handleUpdateBomRelation.bind(this)}
                    onhandleAddSubBomRelation={this.handleAddSubBomRelation.bind(this)}
                    onhandleDeleteBomRelation={this.handleDeleteBomRelation.bind(this)}
                // onhandleUpdateBomRelationCancelled={this.handleUpdateBomRelationCancelled.bind(this)}
                />
                <MServiceActionComponent
                    activityData={this.state.activityData}//活动
                    activityList={this.state.activities}//活动列表
                    openFlag={this.state.openFlag}
                    manufactureDicts={this.props.manufactureDicts}//字典数据
                    openType={this.state.openType}//Action类型 1-普通增加 2-委外服务
                    commissionServices={this.props.commissionServices}//委外服务数据
                    onhandleClose={this.handleActionClose.bind(this)}
                    onhandleSave={this.handleActionSave.bind(this)}
                    onhandleChange={this.handleActionChange.bind(this)}
                    onhandleComServiceChange={this.handleComServiceChange.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activities: state.manufactureActivity.activities,
        manufactureDicts: state.constant.manufactureDicts,
        companyList: state.company.companyList.list,
        departmentList: state.department.departments.list,
        userCompanyId: state.auth.currentUserInfo.companyId,
        currentUserInfo: state.auth.currentUserInfo,
        materialList: state.material.materialList,
        serviceList: state.material.serviceList,
        selectProductList: state.material.selectProductList,
        selectMaterialList: state.material.selectMaterialList,
        rawMaterialList: state.material.rawMaterialList,
        materialGroups: state.material.materialGroups,
        businessGroupList: state.businessGroup.businessGroupList,
        commissionServices: state.manufactureService.commissionServices,
        installs: state.installation.installationList.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActivities: () => {
            dispatch(getActivities());
        },
        getMaterialListByGroup: (materialGroups, callback) => {
            dispatch(getMaterialListByGroup(materialGroups, callback));
        },
        getRawMaterialListByGroup: (materialGroups, callback) => {
            dispatch(getRawMaterialList(materialGroups, callback));
        },
        getServiceList: () => {
            dispatch(getServiceList());
        },
        getMaterialGroups: () => {
            return dispatch(getMaterialGroups());
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
        createActionMessage: (type, message) => {
            dispatch(createActionMessage(type, message));
        },
        addManufactureService: (data) => {
            return dispatch(addManufactureService(data));
        },
        updateManufactureService: (data) => {
            return dispatch(updateManufactureService(data));
        },
        getCommissionServices: (requireCompany, reqMainCompany) => {
            dispatch(getManufactureServices(requireCompany, SERVICE_TYPE_COMMISSION, null, null, reqMainCompany));
        },
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, null, null));
        },
        handleGetInstallations: (query) => {
            return dispatch(getInstallations(query));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MServiceConfigContainer);
