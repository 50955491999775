import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationAnnuaPlan from '../../../components/DeviceManagement/OperationAnnuaPlan';
import { setViewMode } from "../../../redux/modules/operationPlan";

import * as _ from 'lodash';

class OperationAnnuaPlanContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
        isViewMode: PropTypes.bool,
        userProfile: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            tabValue: 0,
            pageLoading: true,
        }
    }

    checkPermission() {
        let rtn = false
        let permission = _.find(this.props.userProfile.roles[0].routes, { 'route': 'leadership_operation_setup' })
        if (permission && permission.action) {
            rtn = true
        }
        return rtn
    }

    componentDidMount() {
        if (this.checkPermission()) {
            if (this.props.isViewMode) {
                this.setState({
                    tabValue: 0
                })
            } else {
                this.setState({
                    tabValue: 1
                })
            }
        } else {
            this.props.setViewMode(true);
            this.setState({
                tabValue: 0
            })
        }
        this.setState({ pageLoading: false })

    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabValue: newValue
        })
    }

    render() {
        return (
            <div>
                <OperationAnnuaPlan
                    tabValue={this.state.tabValue}
                    pageLoading={this.state.pageLoading}
                    userProfile={this.props.userProfile}
                    onhandleChangeTab={this.handleChangeTab.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isViewMode: state.operationPlan.isViewMode,
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setViewMode: (value) => {
            dispatch(setViewMode(value));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationAnnuaPlanContainer);
