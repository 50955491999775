import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Chip,
    Grid,
    Link,
    TextField,
    FormHelperText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { AttachFile, CloudUpload } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { truncate } from '@/utils/string';

const SolutionPanel = ({
    classes,
    open,
    solutionError,
    solution,
    onhandleClose,
    onhandleConfirm,
    onhandleChange,
    onhandleCheck,
    onhandleUpload,
    onhandleDeleteFile,
    onhandleDownloadFile,
}) => {

    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog} disableBackdropClick>
                <MuiDialogTitle disableTypography>
                    <Typography component={'span'} variant="h3">{
                        "作业方案"
                    }</Typography>
                    {onhandleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent>
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"方案名称"}
                                variant='outlined'
                                className={classes.textField}
                                error={solutionError.solutionName !== ''}
                                helperText={solutionError.solutionName}
                                value={solution.solutionName || ''}
                                name='solutionName'
                                onChange={event => onhandleChange(event, 'solutionName')}
                                onBlur={event => onhandleCheck(event, 'solutionName')}
                            />
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                label={"方案内容"}
                                variant='outlined'
                                className={classes.textField}
                                error={solutionError.solutionContent !== ''}
                                helperText={solutionError.solutionContent}
                                value={solution.solutionContent || ''}
                                name='solutionContent'
                                rows={6}
                                multiline
                                onChange={event => onhandleChange(event, 'solutionContent')}
                                onBlur={event => onhandleCheck(event, 'solutionContent')}
                            />
                        </Grid>
                        {
                            solution.solutionDoc && solution.solutionDoc.docName &&
                            <Grid item className={classes.itemGrid}>
                                <Chip
                                    icon={<AttachFile />}
                                    onClick={onhandleDownloadFile}
                                    onDelete={onhandleDeleteFile}
                                    variant="outlined"
                                    title={solution.solutionDoc.docName}
                                    label={truncate(solution.solutionDoc.docName, 20)}
                                />
                            </Grid>
                        }
                        {
                            (!solution.solutionDoc || !solution.solutionDoc.docName) &&
                            <Grid item container className={classes.itemGrid} justify={'space-between'}>
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={Boolean(solutionError.file) ? classes.uploadErr : null}
                                        onClick={onhandleUpload}
                                        startIcon={<CloudUpload />}
                                    >
                                        {(solution.solutionDoc) ? '变更文档' : '上传文档'}
                                    </Button>
                                    <FormHelperText className={classes.uploadErrText}>{solutionError.file}</FormHelperText>
                                </Grid>
                            </Grid>
                        }
                        {
                            solution.file && solution.file.length && solution.file.length > 0 &&
                            <Grid item className={classes.itemGrid} alignItems={'flex-end'} container>
                                <Typography align={'right'}>已选择文件（保存时上传）：</Typography>
                                <Link underline='always' component="button">{solution.file[0].name}</Link>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onhandleConfirm}
                    >
                        {
                            solution.solutionId ? '保存' : '新增'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
SolutionPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    solution: PropTypes.object.isRequired,
};

SolutionPanel.defaultProps = {

}

export default withStyles(SolutionPanel);