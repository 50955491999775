import { createAction, handleActions } from 'redux-actions';


const initialState = {
  open: false,
  children: '',
  parameters: {},
  size: 'small'
};

export const cleanmodal = createAction('@@xcloud/modal/cleanmodal');

export const createmodal = createAction('@@xcloud/modal/createmodal',
  (children, parameters,size) => ({ children, parameters,size }));


// Actions

export const modalsReducer = handleActions(
  {
    [createmodal]: (state, { payload: modals }) => {
      let newSize = modals.size? modals.size : 'small';
      return {
        ...state,
        open: true,
        children: modals.children,
        parameters: modals.parameters,
        size: newSize
      };
    },
    [cleanmodal]: (state) => {
      return {
        ...state,
        open: false,
        children: '',
        parameters: {},
        size: 'small'
      };
    }
  },
  initialState
);

export default modalsReducer;
