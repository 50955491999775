import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Typography,
    Button,
    List,
    Grid,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    Tooltip,
    Divider,
} from '@material-ui/core';
// import * as _ from 'lodash';
import { Add, Delete, Edit, KeyboardArrowRight } from '@material-ui/icons';
import { Table } from 'antd';

const UnitEditComponent = props => {
    const {
        classes,
        onBackToList,
        editUnits,
        editUnitDevices,
        selectedUnit,
        selectedDeviceKeys,
        search,
        onhandleOpenEditUnitDailog,
        onhandleDeleteUnit,
        onhandlePageChange,
        onhandleSelectUnit,
        onhandleDisconnectDevice2Unit,
        onhandleSelectDeviceKeys,
        onhandleOpenDeviceSearch,
    } = props;

    const assemblycolumns = () => {
        let columns = [
            {
                title: '设备编号',
                dataIndex: 'deviceNo',
                render: (text, row, index) => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                render: (text, row, index) => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备位号',
                dataIndex: 'tagNo',
                render: (text, row, index) => row ? <Typography>{row.tagNo}</Typography> : '',
                editable: 'never'
            }, {
                title: '设备分组',
                dataIndex: 'groupName',
                render: (text, row, index) => row ? <Typography>{row.groupName}</Typography> : '',
                editable: 'never'
            },

        ]

        return columns
    }

    const assemblyData = () => {
        return editUnitDevices.list
    }

    const rowSelection = {
        selectedDeviceKeys,
        onChange: onhandleSelectDeviceKeys,
    };

    return (
        <div>
            <Card className={classes.card}>
                <Grid container className={classes.content} >
                    <Grid container alignItems='center'>
                        {/* <Grid item ><Typography variant='h3'>{'单元列表'}</Typography></Grid> */}
                        <Grid item >
                            <Button variant='contained' color='primary' startIcon={<Add />} onClick={event => onhandleOpenEditUnitDailog(0)}>
                                新增单元
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} >
                        <List dense>
                            {editUnits.map(unit => (
                                <ListItem className={classes.listItem} key={unit.unitId} onClick={event => onhandleSelectUnit(unit.unitId)}
                                    selected={unit.unitId === selectedUnit.unitId} classes={{ selected: classes.selected }}>
                                    <ListItemText
                                        primary={<Typography variant='h4'> {unit.unitName} </Typography>}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="单元详情" >
                                            <IconButton edge="end" aria-label="单元详情" onClick={event => onhandleSelectUnit(unit.unitId)}>
                                                <KeyboardArrowRight color={unit.unitId === selectedUnit.unitId ? 'primary' : 'inherit'} />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

                    <Grid item xs={9} className={classes.childContent}>
                        {selectedUnit.unitId && <Grid container >
                            <Grid item xs={12}>
                                <Grid container alignItems='center' className={classes.unitDetail}>
                                    <Grid item >
                                        <Typography variant='h4'> {"【" + selectedUnit.unitName + "】单元设备列表"} </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="重命名单元" ><IconButton color={'primary'} aria-label="修改单元" onClick={event => onhandleOpenEditUnitDailog(selectedUnit.unitId)}>
                                            <Edit />
                                        </IconButton></Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="删除单元" ><IconButton aria-label="删除单元" onClick={event => onhandleDeleteUnit()}>
                                            <Delete className={classes.errorColor} />
                                        </IconButton ></Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems='center'>
                                    {/* <Grid item>
                                        <Typography>单元设备列表</Typography>
                                    </Grid> */}
                                    <Grid item className={classes.flexgrow}>

                                    </Grid>

                                    <Grid item>
                                        <Button variant='contained' color='primary' className={classes.button} startIcon={<Add />} onClick={event => onhandleOpenDeviceSearch()}>
                                            新增关联设备
                                        </Button>
                                        {/* <Tooltip title="关联设备" >
                                            <IconButton aria-label="关联设备"
                                                onClick={event => onhandleOpenDeviceSearch()}>
                                                <Add />
                                            </IconButton >
                                        </Tooltip> */}
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' color='primary' className={classes.button} startIcon={<Delete />}
                                            hidden={selectedDeviceKeys.length === 0 ? true : false}
                                            disabled={selectedDeviceKeys.length === 0 ? true : false}
                                            onClick={event => onhandleDisconnectDevice2Unit()}>
                                            取消关联
                                        </Button>
                                        {/* <Tooltip title="取消关联" >
                                            <Grid><IconButton aria-label="取消关联"
                                                disabled={selectedDeviceKeys.length === 0 ? true : false}
                                                onClick={event => onhandleDisconnectDevice2Unit()}>
                                                <Delete />
                                            </IconButton >
                                            </Grid>
                                        </Tooltip> */}
                                    </Grid>
                                    {selectedDeviceKeys.length === 0 ?
                                        <Grid item> </Grid> :
                                        <Grid item>
                                            <Typography>{'已选择' + selectedDeviceKeys.length + '项'}</Typography>
                                        </Grid>}
                                </Grid>

                                <Table
                                    className={classes.stripedTable}
                                    rowKey={record => record.deviceId}
                                    rowSelection={rowSelection}
                                    columns={assemblycolumns()}
                                    dataSource={assemblyData()}
                                    size={'small'}
                                    pagination={{
                                        showSizeChanger: true,
                                        showQuickJumper: true,
                                        showTotal: () => `共${editUnitDevices.total}条`,
                                        pageSizeOptions: ['10', '20'],
                                        pageSize: search.size,
                                        current: search.page,
                                        total: editUnitDevices.total,
                                        onChange: (current, pageSize) => onhandlePageChange(current, pageSize)
                                    }}
                                ></Table>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Card>
            <Button variant="outlined" color="primary" className={classes.button}
                onClick={event => onBackToList(event)}
                style={{ margin: 20 }}
            >
                返回
            </Button>
        </div>
    );
};

UnitEditComponent.propTypes = {
    editInstallation: PropTypes.object.isRequired,
    editUnits: PropTypes.array.isRequired,
    onBackToList: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
};


UnitEditComponent.defaultProps = {
    editInstallation: {},
    editUnits: [],
    editUnitDevices: {
        list: [],
        total: 0
    },
    search: { page: 1, size: 10 },
    departmentList: [],
    companyList: [],
    userProfile: {},
}

export default withStyles(UnitEditComponent);
