import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid,
    Typography,
    Avatar,
    Chip,
    TablePagination,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import { getMoment as moment, getDateByTimestamp } from "../../../../utils/datetime";
import _ from 'lodash';

const MonthlyPlanList = props => {
    const {
        classes,
        search,
        lists,
        userProfile,
        inspectionDptMapping,
        onOpenTempPlanAddPannel,
        onhandlePageChange,
        onhandleSelectChange,
        // onSetup,
        onStart,
        onView,
        onEdit,
        onDelete,
        onhandleListMapping,
    } = props;

    const tableRef = React.createRef();

    const [selectedRow, setSelectedRow] = useState(null);

    const checkPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_plan_detail' })
        return permission && (permission.action === 'W' || permission.action === 'R')
    }

    const checkReviewPermission = () => {
        let permission = _.find(userProfile.roles[0].routes, { 'route': 'inspection_plan_detail' })
        return permission && permission.action === 'R'
    }

    const checkDptPermission = (rowData) => {
        const typeNotAvailable = _.findIndex(inspectionDptMapping, { 'inspectionCode': rowData.inspectType.substr(0, 5) }) < 0;
        const notHeader = rowData.inspectHead !== userProfile.userId;
        const notDptLeader = userProfile.roles[0].departments ? _.findIndex(rowData.inspectionDptList, { dptId: userProfile.roles[0].departments[0].dptId }) < 0 : true
        return (
            typeNotAvailable && notHeader && notDptLeader
        )
    }

    const assemblycolumns = () => {

        let columns = [
            {
                title: '',
                field: 'planType',
                render: row => row ? (
                    row.inspectionPlan.planType === 'TEMP' ? (<Avatar className={classes.pic1} >临</Avatar>) : (<Avatar className={classes.pic2} >年</Avatar>)
                ) : '',
                editable: 'never',
                width: '5%',
            },
            {
                title: '风险研判类型',
                field: 'inspectType',
                // planDetail没区分主类和子分类，所以用sub截断
                render: row => row ? <Typography>{onhandleListMapping(row.inspectType, 'inspectType')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '风险研判检查表',
                field: 'inventory',
                render: row => row ? <Typography>{row.inventoryName || '-'}</Typography> : '-',
                editable: 'never'
            },
            {
                title: '风险研判单位',
                field: 'companyId',
                render: row => row ? <Typography>{onhandleListMapping(row.companyId, 'company')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查班组',
                field: 'inspectionDptList',
                render: row => row ? <Typography>{onhandleListMapping(row.inspectionDptList, 'departmentShow')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '班长',
                field: 'inspectHead',
                render: row => row ? <Typography>{onhandleListMapping(row.inspectHead, 'user')}</Typography> : '',
                editable: 'never'
            },
            {
                title: '检查日期',
                field: 'startTime',
                render: (row) => row && <Typography>{
                    moment()(row.startTime).isSame(row.inspectTime, 'day') ?
                        getDateByTimestamp(row.startTime)
                        :
                        (getDateByTimestamp(row.startTime) + ' 至 ' + getDateByTimestamp(row.inspectTime))
                }</Typography>,
                editable: 'never'
            },
            {
                title: '记录时间',
                field: 'updateTime',
                render: row => row ? <Typography>{row.updateTime ? getDateByTimestamp(row.updateTime) : ''}</Typography> : '',
                editable: 'never'
            },
            {
                title: '完成时间',
                field: 'endTime',
                render: row => row ? <Typography>{row.endTime ? getDateByTimestamp(row.endTime) : ''}</Typography> : '',
                editable: 'never'
            },
            {
                width: '10%',
                title: '状态',
                field: 'status',
                render: row => {
                    let statusStyle = classes.pending;
                    if (row.status === 'done' || row.endTime) statusStyle = classes.done;
                    if (row.status === 'expired') statusStyle = classes.expiredDone;
                    if (row.status === 'active') statusStyle = classes.active;
                    if (row.status === 'invalid') statusStyle = classes.invalid;
                    return <Chip size="small" className={statusStyle} label={onhandleListMapping(row.status, 'status')} />
                },
                editable: 'never',
                align: 'center'
            }
        ]

        return columns
    }

    const components = {
        Pagination: props => (
            <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={search.size}
                count={lists.length ? lists.length : 0}
                page={search.page}
                onChangePage={(event, pageNum) => {
                    props.onChangePage(event, pageNum);
                    onhandlePageChange(pageNum);
                }}
                onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    onhandleSelectChange(event, 'size');
                }}
            />
        )
    }

    return (
        <>
            <div alignitems="center" className={classes.planitemroot}>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        tableRef={tableRef}
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={lists}
                        options={{
                            ...options,
                            pageSize: search.size,
                            initialPage: search.page,
                            actionsColumnIndex: -1,
                            search: false,
                            rowStyle: rowData => ({
                                backgroundColor: selectedRow === rowData.listId ? '#EEEEEE' : '#FFFFFF'
                            })
                        }}
                        components={components}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        localization={localization}
                        style={{
                            ...style,
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                        actions={checkPermission() ?
                            [
                                {
                                    icon: 'add',
                                    tooltip: '新建风险研判计划',
                                    isFreeAction: true,
                                    onClick: (event) => { onOpenTempPlanAddPannel() }
                                },
                                rowData => ({
                                    icon: 'play_circle_outline',
                                    tooltip: '启动计划',
                                    onClick: (event, rowData) => {
                                        onStart(event, rowData)
                                    },
                                    hidden: ['active', 'done', 'expired', 'invalid'].includes(rowData.status),
                                    disabled: checkDptPermission(rowData)
                                }),
                                rowData => ({
                                    // icon: 'playlist_add_check',
                                    icon: 'subject',
                                    tooltip: '排查记录',
                                    hidden: !['active', 'done', 'expired', 'invalid'].includes(rowData.status),
                                    onClick: (event, rowData) => {
                                        onView(event, rowData)
                                    }
                                }),
                                rowData => ({
                                    icon: rowData.status === 'finalized' && !checkDptPermission(rowData) ? 'edit' : 'description',
                                    tooltip: rowData.status === 'finalized' && !checkDptPermission(rowData) ? '编辑计划' : '计划详情',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.dtlId)
                                    },
                                    // disabled: 
                                }),
                                rowData => ({
                                    icon: 'delete_outlined',
                                    tooltip: '删除风险研判',
                                    onClick: (event, rowData) => {
                                        onDelete(event, rowData)
                                    },
                                    hidden: rowData.inspectionPlan.planType !== 'TEMP' || (['active', 'done', 'expired', 'invalid'].includes(rowData.status) && !checkReviewPermission()),
                                    disabled: _.findIndex(inspectionDptMapping, { 'inspectionCode': rowData.inspectType.substr(0, 5) }) < 0 && !checkReviewPermission()
                                })
                            ] : [
                                rowData => ({
                                    // icon: 'playlist_add_check',
                                    icon: 'subject',
                                    tooltip: '排查记录',
                                    hidden: !['active', 'done', 'expired', 'invalid'].includes(rowData.status),
                                    onClick: (event, rowData) => {
                                        onView(event, rowData)
                                    }
                                }),
                                rowData => ({
                                    icon: 'description',
                                    tooltip: '计划详情',
                                    onClick: (event, rowData) => {
                                        onEdit(event, rowData.dtlId)
                                    }
                                })
                            ]}
                    >
                    </MaterialTable>
                </Grid>
            </div>
        </>
    );
};

MonthlyPlanList.propTypes = {
    classes: PropTypes.object.isRequired,
    lists: PropTypes.array.isRequired,
    inspectionDptMapping: PropTypes.array.isRequired,
    onOpenTempPlanAddPannel: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    onhandleListMapping: PropTypes.func.isRequired
};

export default withStyles(MonthlyPlanList);
