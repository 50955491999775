import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {
        padding: '8px 8px',
        backgroundColor: 'white',
    },
    toolbarContainer: {
        paddingLeft: theme.spacing(2)
    },
    dialogContent: {
        minWidth: 600
    },
    select: {
        paddingRight: '16px',
    },
    flexgrow: {
        flexGrow: 1
    },
    sumText: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    toolbarLabel: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    tableContainer: {
        paddingTop: theme.spacing(2),
    }
});


export default withStyles(styles);