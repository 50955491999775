import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // globalpaper: {
  //   position: 'absolute',
  // },
  // globalprogress: {
  //   width: '80px',
  //   height: '80px',
  // },
});

export default withStyles(styles);
