import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddDetailPannel from '../../../components/InspectionManagement/AnnualPlan/InspectionPlan/AddDetailPannel';
import { closePlanDetailAddPannel, changePlanDetail } from '@/redux/modules/inspection/inspectionPlanDetailAdd';
import { getMoment as moment, separateDates } from "../../../utils/datetime";
// import { getAllLeaders } from '../../../redux/modules/user';
import {
    getListTypeList,
    getCategoryList,
    getInventories
} from "@/redux/modules/inspection/inspection"
import { getActualCompany, getManageDpt } from '@/utils/constant';
import _ from 'lodash';

class InspectionPlanDetailAddPannelContainer extends Component {
    static propTypes = {
        planDetail: PropTypes.object,
        confirmCallback: PropTypes.func,
        closeCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            planDetailError: {
                'inspectType': '',
                'inspectTypeCat': '',
                'inventoryId': '',
                // 'companyId': '',
                'inspectionCompanyIdList': '',
                'inspectionDptStrList': '',
                'inspectHead': ''
            },
            planDateError: {
                'startTime': '',
                'inspectTime': '',
            }
        };
    }

    componentDidMount() {
        const {
            // onhandleGetLeaderList,
            onhandleGetListTypeList,
            onhandleGetCategoryList,
            onhandleGetInventoryList
        } = this.props;
        // onhandleGetLeaderList();
        onhandleGetListTypeList();
        onhandleGetCategoryList();
        onhandleGetInventoryList();
    }

    getDptInspectTypes(listTypeList) {
        const {
            inspectionDptMapping
        } = this.props;

        // 过滤排查列表
        const filtedTypeList = _.filter(listTypeList, (o) => {
            if (_.findIndex(inspectionDptMapping, { 'inspectionCode': o.code }) > -1)
                return true;
        })
        return filtedTypeList;
    }

    handleConfirm() {
        const { confirmCallback, planDetail, onhandleClose } = this.props;
        const fieldChecked = this.handleCheck(null, null);
        const datesChecked = this.handleDateCheck(null, null);
        if (fieldChecked && datesChecked) {
            if (confirmCallback) confirmCallback(planDetail)
            if (onhandleClose) onhandleClose();
        }

    }

    handleClose() {
        const { onhandleClose, closeCallBack } = this.props;
        // final execute function(from other component or redux-action) config by open action
        if (onhandleClose) onhandleClose();
        // close action
        if (closeCallBack) closeCallBack();
    }

    handleChange(event) {
        const { onhandleChange, planDetail, companies, inventoryList, majorHazardlist } = this.props;
        let newDetail = {
            ...planDetail
        }

        const fieldName = event.target.name;
        const fieldValue = _.cloneDeep(event.target.value);
        // 获取checkbox值只能取checked
        const checkboxValue = _.cloneDeep(event.target.checked);

        newDetail[fieldName] = fieldValue;

        // 调整与当前变动域相关的其他域
        if (fieldName === 'inspectType' || fieldName === 'inspectTypeCat') {

            let inspectType = null;
            let inspectTypeCat = null;
            if (fieldName === 'inspectType') {
                inspectType = fieldValue;
                newDetail.inspectTypeCat = '';
                newDetail.targetId = '';
                if (fieldValue === 'INS05') {
                    newDetail.inspectionCompanyIdList = [];
                }
            }
            if (fieldName === 'inspectTypeCat') {
                inspectType = fieldValue ? fieldValue.substr(0, 5) : null;
                inspectTypeCat = fieldValue
            }
            // 单选检查表时，匹配对应检查类型
            if (fieldName === 'inventoryId') {
                const inventory = _.find(inventoryList, { 'inventoryId': fieldValue });
                newDetail.inspectType = inventory.listType || null;
                newDetail.inspectTypeCat = inventory.catCode || null;
            }
            // 过滤后的检查表，如果只有一项，则默认选中
            const filtedInventoryList = this.filterInventoryList(inspectType, inspectTypeCat, inventoryList);
            newDetail.inventoryId = '';
            if (filtedInventoryList && filtedInventoryList.length) {
                if (filtedInventoryList.length === 1) {
                    newDetail.inventoryId = filtedInventoryList[0].inventoryId;
                } else {
                    // 不处理
                }
            } else {
                this.props.onhandleErrorMessage('无可用检查表，请创建后重试');
            }
            // 过滤无排查项的部门
            newDetail.inspectionDptUserList = _.filter(
                newDetail.inspectionDptUserList,
                dpt => (this.getDptChecklistCount(dpt, newDetail) > 0)
            );
        }
        if (fieldName === 'inventoryId') {
            // 单选检查表时，匹配对应检查类型
            const inventory = _.find(inventoryList, { 'inventoryId': fieldValue });
            newDetail.inspectType = inventory.listType || null;
            newDetail.inspectTypeCat = inventory.catCode || null;
        }
        if (fieldName === 'targetId') {
            const targetCompanyId = majorHazardlist ? majorHazardlist.find(mh => mh.hazardId === fieldValue).companyId : '';
            newDetail.inspectionCompanyIdList = companies.filter(comp => targetCompanyId === comp.companyId).map(comp => comp.companyId);
        }
        if (fieldName === 'inspectionCompanyIdList') {
            const selectedCompanyIds = fieldValue;
            const selectedCompany = _.filter(companies, company => selectedCompanyIds.includes(company.companyId));

            if (selectedCompany.length > 0 && _.findIndex(selectedCompany, o => !Boolean(o.pid)) > -1) {
                // 选择有机关，过滤所有基层部门
                newDetail.inspectionDptUserList = _.filter(
                    newDetail.inspectionDptUserList,
                    dpt => !Boolean(dpt.companyPid)
                );
            } else if (selectedCompany.length > 0 && _.findIndex(selectedCompany, o => !Boolean(o.pid)) < 0) {
                // 只选了基层，过滤不相关的基层部门
                newDetail.inspectionDptUserList = _.filter(
                    newDetail.inspectionDptUserList,
                    dpt => !Boolean(dpt.companyPid) || selectedCompanyIds.includes(dpt.companyId)
                );
            } else {
                // 过滤了个寂寞
            }
        }
        // 变更检查时间类型时，调整inspectTime
        if (event.target.name === 'intervalTime') {
            if (event.target.value === 'true') newDetail.inspectTime = '';
            if (event.target.value === 'false') newDetail.inspectTime = moment()(newDetail.startTime).endOf('day').valueOf();
        }

        // 变更是否进行频率检查，调整检查时间类型、结束时间inspectTime
        if (fieldName === 'frequencyDate') {
            newDetail.startTime = '';
            newDetail.inspectTime = '';
            newDetail.planDates = [];
            if (checkboxValue === true) {
                newDetail.frequencyType = 'day';
                newDetail.frequencyDate = true;
                newDetail.intervalTime = 'true';
            }
            if (checkboxValue === false) {
                newDetail.frequencyType = '';
                newDetail.frequencyDate = false;
                newDetail.intervalTime = 'false';
            }
        }

        // 变更频率类型时清空
        if (fieldName === 'frequencyType') {
            newDetail.startTime = '';
            newDetail.inspectTime = '';
            newDetail.planDates = [];
        }

        onhandleChange(newDetail);

        // 变更时间设置类型时，不用check
        if (fieldName === 'intervalTime' || fieldName === 'frequencyDate') return;
        setTimeout(() => {
            this.handleCheck(null, fieldName);
        }, 100)
    }

    handleInspectionHeadChange(e, newValue) {
        const { onhandleChange, planDetail } = this.props;
        planDetail.inspectHead = newValue ? newValue.userId : null;
        onhandleChange({
            ...planDetail,
        })
        setTimeout(() => {
            this.handleCheck(null, 'inspectHead');
        }, 100)
    }

    handleDateChange(date, fieldName) {
        const { onhandleChange, planDetail, holidays } = this.props;

        let newDetail = {
            ...planDetail,
        }

        const selectedDate = moment()(date);
        // 设开始时间
        if (fieldName === 'startTime') {
            newDetail.startTime = selectedDate.startOf('day').valueOf();

            // 单选月份时、额外处理天数
            if (newDetail.frequencyType === 'month') {
                if (moment()().startOf('day').isBefore(selectedDate.startOf('month'))) {
                    newDetail.startTime = selectedDate.startOf('month').valueOf();
                } else {
                    newDetail.startTime = moment()().startOf('day').valueOf();
                }
            }
            // 时间类型为非区间，自动设inspectTime
            if (planDetail.intervalTime === 'false') newDetail.inspectTime = selectedDate.endOf('day').valueOf();
        }
        // 设截止时间
        if (fieldName === 'inspectTime') {
            newDetail.inspectTime = selectedDate.endOf('day').valueOf();
        }

        onhandleChange(newDetail)

        setTimeout(() => {
            if (this.handleDateCheck(null, fieldName)) {
                // 两个时间都有、且为频次排查时，构造时间段数组
                if (planDetail.frequencyDate === true) {
                    newDetail.planDates = separateDates(newDetail.frequencyType, newDetail.startTime, newDetail.inspectTime, holidays);
                    onhandleChange(newDetail);
                }
            } else {
                if (planDetail.frequencyDate === true) {
                    newDetail.planDates = [];
                    onhandleChange(newDetail);
                }
            }
        }, 100);
    }

    handleListMapping = (colValue, field) => {
        let value = colValue;
        const {
            constant,
            users
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(constant.companies, { 'companyId': colValue }) ? _.find(constant.companies, { 'companyId': colValue }).companyAlias : '';
                break;
            case 'departmentShow':
                const deptNameList = [];
                colValue.forEach(dept => {
                    let deptStr = '';
                    deptStr = _.find(constant.departments, { 'dptId': dept.dptId }) ? _.find(constant.departments, { 'dptId': dept.dptId }).companyDptName : '';
                    deptNameList.push(deptStr);
                })
                value = deptNameList.join(', ');
                break;
            case 'departmentEdit':
                value = _.find(constant.departments, { 'dptId': colValue }) ? _.find(constant.departments, { 'dptId': colValue }).companyDptName : '';
                break;
            case 'inspectType':
                value = _.find(constant.inspectionDictionaries, { 'code': colValue }) ? _.find(constant.inspectionDictionaries, { 'code': colValue }).name : '';
                break;
            case 'user':
                const user = _.find(users, { 'userId': colValue });
                value = user ? user.lastName + user.firstName : '';
                break;
            default:
            // do nothing
        }
        return value;
    }

    handleCheck = (event, field) => {

        const {
            planDetail,
            categoryList
        } = this.props;

        let err = {};

        if (!field || field === 'inspectTypeCat' || field === 'inventoryId') {
            if (_.find(categoryList, { 'pcode': planDetail.inspectType }) && _.trim(planDetail.inspectTypeCat) === '') {
                err['inspectTypeCat'] = '子分类不能为空';
            } else {
                err['inspectTypeCat'] = '';
            }
            if (_.trim(planDetail.inventoryId) === '') {
                err['inventoryId'] = '检查表不能为空';
            } else {
                err['inventoryId'] = '';
            }
        }

        if (!field || field === 'targetId') {
            if (planDetail.inspectType === 'INS05' && planDetail.targetId === '') {
                err['targetId'] = '重大危险源不能为空';
            } else {
                err['targetId'] = '';
            }
        }

        // if (!field || field === 'companyId') {
        //     if (_.trim(planDetail.companyId) === '') {
        //         err['companyId'] = '单位不能为空';
        //     } else {
        //         err['companyId'] = '';
        //     }
        // }

        if (!field || field === 'inspectionCompanyIdList') {
            if (!planDetail.inspectionCompanyIdList || !planDetail.inspectionCompanyIdList.length || planDetail.inspectionCompanyIdList.length === 0) {
                err['inspectionCompanyIdList'] = '受检单位不能为空';
            } else {
                err['inspectionCompanyIdList'] = '';
            }
        }

        if (!field || field === 'inspectionDptStrList') {
            if (!planDetail.inspectionDptStrList || !planDetail.inspectionDptStrList.length || planDetail.inspectionDptStrList.length === 0) {
                err['inspectionDptStrList'] = '检查部门不能为空';
            } else {
                err['inspectionDptStrList'] = '';
            }
        }

        if (!field || field === 'inspectHead') {
            if (_.trim(planDetail.inspectHead) === '') {
                err['inspectHead'] = '负责人不能为空';
            } else {
                err['inspectHead'] = '';
            }
        }

        let newError = {
            ...this.state.planDetailError,
            ...err
        }
        this.setState({
            planDetailError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    handleDateCheck = (event, field) => {

        const {
            planDetail
        } = this.props;

        let err = {};

        if (!field || field === 'startTime') {
            if (!planDetail.startTime) {
                err['startTime'] = '日期不能为空';
            } else {
                err['startTime'] = '';
            }
        }

        if (!field || field === 'inspectTime') {
            if (!planDetail.inspectTime) {
                err['inspectTime'] = '日期不能为空';
            } else {
                err['inspectTime'] = '';
            }
        }

        if (planDetail.intervalTime === 'true') {
            if (planDetail.startTime && planDetail.inspectTime) {

                const startMoment = moment()(planDetail.startTime);
                const inspectMoment = moment()(planDetail.inspectTime);

                if (inspectMoment.isBefore(startMoment)) {
                    err['inspectTime'] = '不能早于开始日期';
                } else if (planDetail.frequencyDate === true) {
                    err['inspectTime'] = '';
                    if (planDetail.frequencyType === 'week' && inspectMoment.diff(startMoment, 'days') < 6) err['inspectTime'] = '日期范围不足7天';
                    if (planDetail.frequencyType === 'fortnight' && inspectMoment.diff(startMoment, 'days') < 13) err['inspectTime'] = '日期范围不足2周';
                    if (planDetail.frequencyType === 'month' && inspectMoment.diff(startMoment, 'months') < 1) err['inspectTime'] = '日期范围不足1个月';
                } else {
                    err['inspectTime'] = '';
                }
            }
        }

        let newError = {
            ...this.state.planDateError,
            ...err
        }
        this.setState({
            planDateError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    getDptChecklistCount = (dpt, paramDetail) => {
        const {
            planDetail,
            checklistCount
        } = this.props;

        const dptId = dpt.dptId;

        let detailRef = planDetail;
        if (paramDetail) detailRef = paramDetail;

        let inventoryId = '';
        let inspectType = '';
        if (detailRef.inspectTypeCat && detailRef.inspectTypeCat !== '') {
            inspectType = detailRef.inspectTypeCat;
        } else {
            inspectType = detailRef.inspectType;
        }
        if (detailRef.inventoryId) {
            inventoryId = detailRef.inventoryId;
        }

        let dptCount = 0;
        // 未选定排查类型时
        if ((!inspectType || inspectType === '') && checklistCount['all']) {
            // [0]代表不限部门
            dptCount = checklistCount['all'][0] ? checklistCount['all'][0] : 0;
            if (checklistCount['all'][dptId]) {
                dptCount += checklistCount['all'][dptId];
            }
        }
        // 仅 选定排查类型时
        if (!inventoryId && inspectType && inspectType !== '' && checklistCount[inspectType]) {
            dptCount = checklistCount[inspectType][0] ? checklistCount[inspectType][0] : 0;
            if (checklistCount[inspectType][dptId]) {
                dptCount += checklistCount[inspectType][dptId];
            }
        }
        // 选定检查表时
        if (inventoryId && inventoryId !== '' && checklistCount[inventoryId] && checklistCount[inventoryId]['dptCount']) {
            dptCount = checklistCount[inventoryId]['dptCount'][0] ? checklistCount[inventoryId]['dptCount'][0] : 0;
            if (checklistCount[inventoryId]['dptCount'][dptId]) {
                dptCount += checklistCount[inventoryId]['dptCount'][dptId];
            }
        }

        return dptCount;
    }

    checkCompanyRelation = (dpt, paramDetail) => {
        const {
            planDetail,
            companies
        } = this.props;

        let detailRef = planDetail;
        if (paramDetail) detailRef = paramDetail;
        const selectedCompanyIds = detailRef.inspectionCompanyIdList;
        const selectedCompany = _.filter(companies, company => selectedCompanyIds.includes(company.companyId));

        if (selectedCompany.length > 0 && _.findIndex(selectedCompany, o => !Boolean(o.pid)) > -1) {
            // 选择有机关，过滤所有基层部门
            return !Boolean(dpt.companyPid)
        } else if (selectedCompany.length > 0 && _.findIndex(selectedCompany, o => !Boolean(o.pid)) < 0) {
            // 只选了基层，过滤不相关的基层部门
            return (!Boolean(dpt.companyPid) || selectedCompanyIds.includes(dpt.companyId))
        }
        // 过滤了个寂寞
        return true
    }

    filterInventoryList = (inspectType, inspectTypeCat, inventoryList) => {
        const {
            userProfile
        } = this.props;
        return inventoryList
            .filter((ivt) => { return !ivt.companyId || ivt.companyId === userProfile.roles[0].companyId; })
            .filter((ivt) => {
                // 根据部门可排查类型筛选
                let codeMatch = false;
                const availableTypeList = this.getDptInspectTypes(this.props.listTypeList);
                if (_.findIndex(availableTypeList, { 'code': ivt.listType }) >= 0) {
                    codeMatch = true
                }
                // 根据已选检查类型筛选
                if (inspectType) {
                    codeMatch = ivt.listType === inspectType
                    if (inspectTypeCat) {
                        codeMatch = ivt.catCode === inspectTypeCat
                    }
                }
                return codeMatch
            })
    }

    render() {
        return (
            <AddDetailPannel
                open={this.props.open}
                intervalTime={this.props.planDetail.intervalTime === 'true' ? true : false}
                frequencyDate={this.props.planDetail.frequencyDate === true ? true : false}
                checklistCount={this.props.checklistCount}
                getDptChecklistCount={this.getDptChecklistCount.bind(this)}
                checkCompanyRelation={this.checkCompanyRelation.bind(this)}
                inspectType={this.props.inspectType}
                companyList={this.props.companies}
                departmentList={this.props.departmentList}
                plan={this.props.plan}
                planDetail={this.props.planDetail}
                currentUserInfo={this.props.currentUserInfo}
                userProfile={this.props.userProfile}
                leaders={this.props.leaders}
                roleList={this.props.roleList}
                listTypeList={this.getDptInspectTypes(this.props.listTypeList)}
                categoryList={this.props.categoryList}
                inventoryList={this.filterInventoryList(this.props.planDetail.inspectType, this.props.planDetail.inspectTypeCat, this.props.inventoryList)}
                majorHazardlist={this.props.majorHazardlist}
                planDetailError={this.state.planDetailError}
                planDateError={this.state.planDateError}

                onhandleListMapping={this.handleListMapping.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleInspectionHeadChange={this.handleInspectionHeadChange.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}

                onhandleCheck={this.handleCheck.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.inspectionPlanDetailAdd.open,
        constant: state.constant,
        companies: getActualCompany(state.constant.companies),
        departmentList: getManageDpt(state.constant.departments),
        leaders: state.user.leaders,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        majorHazardlist: state.majorHazard.majorHazardlist.list,
        inventoryList: state.inspection.inventories.list,
        roleList: state.inspection.roleList,
        checklistCount: state.inspection.checklistCount,
        plan: state.inspectionPlan.currentPlan,
        planDetail: state.inspectionPlanDetailAdd.planDetail,
        holidays: state.constant.holidays,
        confirmCallback: state.inspectionPlanDetailAdd.confirmCallback,
        closeCallback: state.inspectionPlanDetailAdd.closeCallback
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleChange: (planDetail) => {
            dispatch(changePlanDetail(planDetail));
        },
        onhandleClose: () => {
            dispatch(closePlanDetailAddPannel());
        },
        // onhandleGetLeaderList: (query) => {
        //     dispatch(getAllLeaders(query))
        // },
        onhandleGetListTypeList: () => {
            dispatch(getListTypeList())
        },
        onhandleGetCategoryList: () => {
            dispatch(getCategoryList())
        },
        onhandleGetInventoryList: () => {
            dispatch(getInventories({ offset: 0, size: 0 }))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionPlanDetailAddPannelContainer);