import React, {Component} from 'react';
import {connect} from 'react-redux';
import DeviceFormList from '@/components/DeviceManagement/DeviceFormList'
import DeviceFormListHeader from '@/components/DeviceManagement/DeviceFormList/DeviceFormListHeader';
import {
    getSearchForms,
    setPageSize,
    setPage,
    setSearchCondition,
    deleteDeviceForm,
    routeToFormDetail,
    //onhandleSetPageSize,
    //onhandleSetpage,

} from "@/redux/modules/deviceForm";
import {openConfirmDialog} from '@/redux/modules/confirmDialog';
import PropTypes from "prop-types";
import * as _ from 'lodash';
import {
    DEVICE_FROM_TYPES,
    DEVICE_FORM_TARGET_TYPES,
    DEVICE_FROM_STATUS,
    DEVICE_ATTR_FIELDS
} from '@/utils/device';
import {Card} from '@material-ui/core';


class DeviceFromListContainer extends Component {
    static propTypes = {
        currentUserInfo: PropTypes.object,
        forms: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {}
    }

    // 组件渲染后... ...
    componentDidMount() {

        let {
            currentUserInfo,
            companies,
            onhandleGetSearchForms,
            onhandleSetSearchCondition,

        } = this.props

        let companyId = currentUserInfo.companyId

        let query = {
            'companyId': companyId,

            'offset': this.props.searchCondition.offset,
        }


        let myCompany = _.find(companies, {companyId: companyId})
        if (!myCompany.pid) {

        }

        onhandleGetSearchForms(query)
        onhandleSetSearchCondition(query)
    }

    handleSetpage = (value) => {
        this.props.onhandleUpdateSetpage(value)
    }
    handleSearch = (value) => {
        let {
            searchCondition,
            onhandleGetSearchForms
        } = this.props
        let newSearchCondition = {
            ...this.props.searchCondition,
            offset: value.offset
        };
        setSearchCondition(newSearchCondition)
        onhandleGetSearchForms(newSearchCondition)
    }
    handleClearSearch = () => {
        let {currentUserInfo, onhandleSetSearchCondition} = this.props
        onhandleSetSearchCondition({
            companyId: currentUserInfo.companyId,
        })
    }

    handleChange = (event, name) => {
        let {
            searchCondition,
            onhandleSetSearchCondition
        } = this.props
        let newSearch = {
            ...searchCondition,
        }
        newSearch[name] = event.target.value
        onhandleSetSearchCondition(newSearch)
    }

    handleSelectChange = (event, name) => {

        let {
            searchCondition,
            onhandleSetSearchCondition
        } = this.props
        let newSearch = {
            ...searchCondition,
        }
        newSearch[name] = event.target.value
        onhandleSetSearchCondition(newSearch)
    }

    handleEdit = (formId) => {
        this.props.onhandleRouteToFormDetail(formId)
    }

    handleDelete = (formId) => {
        let {
            searchCondition,
            onhandleDeleteDeviceForm,
            onhandleGetSearchForms,
            onhandleOpenConfirmDialog
        } = this.props

        let deleteForm = async () => {
            await onhandleDeleteDeviceForm(formId)
            await onhandleGetSearchForms(searchCondition)
        }
        onhandleOpenConfirmDialog('删除确认', '确定要删除表单吗?', deleteForm, null, false);
    }
    handleSetPageSize = (value) => {
        //     // let newSearchForms = {
        //     ...this.props.searchForms,
        //     page: value
        // };
        //
        this.props.onhandleUpdatePageSize(value)
    }

    render() {
        return (
            <Card>
                <DeviceFormListHeader
                    formTypeList={DEVICE_FROM_TYPES}
                    targetTypeList={DEVICE_FORM_TARGET_TYPES}
                    formStatusList={DEVICE_FROM_STATUS}
                    deviceAttrFields={DEVICE_ATTR_FIELDS}
                    searchCondition={this.props.searchCondition}
                    isLoading={this.props.isLoading}
                    companies={this.props.companies}
                    onhandleSearch={this.handleSearch.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleSelectChange={this.handleSelectChange.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                />
                <div>
                    <DeviceFormList
                        formTypeList={DEVICE_FROM_TYPES}
                        formStatusList={DEVICE_FROM_STATUS}
                        forms={this.props.searchForms}
                        page={this.props.page}
                        pageSize={this.props.pageSize}
                        setPageSize={this.props.setPageSize}
                        companies={this.props.companies}
                        onhandleEdit={this.handleEdit.bind(this)}
                        onhandleDelete={this.handleDelete.bind(this)}
                        onhandleSetPage={this.handleSetpage.bind(this)}
                        onhandleSetPageSize={this.handleSetPageSize.bind(this)}
                        onhandleSearch={this.handleSearch.bind(this)}

                    />
                </div>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        searchForms: state.deviceForm.searchForms,
        searchCondition: state.deviceForm.searchCondition,
        currentUserInfo: state.auth.currentUserInfo,
        companies: state.constant.companies,
        isLoading: state.loading.globalLoading,
        page: state.deviceForm.page,
        pageSize: state.deviceForm.pageSize,
        setPageSize: state.deviceForm.setPageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateSetpage: (value) => {
            return dispatch(setPage(value))
        },

        onhandleGetSearchForms: (query) => {
            return dispatch(getSearchForms(query))
        },
        onhandleUpdatePageSize: (pageSize) => {
            return dispatch(setPageSize(pageSize))
        },
        onhandleSetSearchCondition: (sc) => {
            return dispatch(setSearchCondition(sc))
        },
        onhandleDeleteDeviceForm: (sc) => {
            return dispatch(deleteDeviceForm(sc))
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },

        onhandleRouteToFormDetail: (formId) => {
            return dispatch(routeToFormDetail(formId))
        },
        setSearchCondition: (value) => {
            return dispatch(setSearchCondition(value))
        }

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceFromListContainer)
