import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TreeSelect from 'rc-tree-select';
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { getDateByTimestamp } from '../../../../utils/datetime';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';

const CompanyInfo = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleCancel,
        onhandleCheck,
        onhandleSelectionChange,
        onhandleSwitchChange,
        currentCompany,
        parentNode,
        companyError,
        areaTree,
        industryTree,
        industrySelection,
        areaSelection,
        companySizes,
        companyChange
    } = props;

    const sizeOptions = () => (
        <React.Fragment>
            {
                companySizes.map(data => (
                    <option value={data.code}>{data.name}</option>
                ))
            }
        </React.Fragment>
    )

    return (
        <div>
            <Card className={classes.card}>

                {
                    !currentCompany.indivisible ? (<Typography variant="h3" gutterBottom>
                        {currentCompany.companyId ? "编辑公司" : "新建公司"}
                    </Typography>) : (<Typography variant="h3" gutterBottom>
                        {currentCompany.companyId ? "编辑车间" : "新建车间"}
                    </Typography>)
                }

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            上级公司
                            </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography gutterBottom>
                            {parentNode ? parentNode.companyName : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司编号
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间编号
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={5}>
                        {currentCompany.companyId ?
                            (<Typography gutterBottom>
                                {currentCompany.companyId}
                            </Typography>) : null}
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司名称
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间名称
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            error={companyError.companyName && companyError.companyName !== ''}
                            helperText={companyError.companyName || ''}
                            className={classes.textField}
                            value={currentCompany.companyName || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'companyName')}
                            onBlur={event => onhandleCheck(event, 'companyName')}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司别名
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间别名
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            className={classes.textField}
                            value={currentCompany.companyAlias || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'companyAlias')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            邮箱地址
                            </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            error={currentCompany.email && companyError.email && companyError.email !== ''}
                            helperText={currentCompany.email !== '' && companyError.email !== '' ? companyError.email : ''}
                            className={classes.textField}
                            value={currentCompany.email || ''}
                            type="email"
                            variant="standard"
                            onChange={event => onhandleChange(event, 'email')}
                            onBlur={event => onhandleCheck(event, 'email')}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            联系电话
                            </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            error={currentCompany.phone && currentCompany.phone !== '' && companyError.phone && companyError.phone !== ''}
                            helperText={currentCompany.phone !== '' && companyError.phone !== '' ? companyError.phone : ''}
                            className={classes.textField}
                            value={currentCompany.phone || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'phone')}
                            onBlur={event => onhandleCheck(event, 'phone')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            阿米巴类型
                            </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            className={classes.textField}
                            native
                            value={currentCompany.amoebaType}
                            onChange={(event) => onhandleSelectionChange(event, 'amoebaType')}
                        >
                            <option aria-label="None" value="" />
                            <option value={'COST'}>{'成本中心'}</option>
                            <option value={'PROFIT'}>{'利润中心'}</option>
                            <option value={'OTHER'}>{'其他'}</option>
                        </Select>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            阿米巴级别
                            </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            className={classes.textField}
                            native
                            value={currentCompany.amoebaLevel}
                            onChange={(event) => onhandleSelectionChange(event, 'amoebaLevel')}
                        >
                            <option aria-label="None" value="0" />
                            <option value={1}>{'一级巴'}</option>
                            <option value={2}>{'二级巴'}</option>
                            <option value={3}>{'三级巴'}</option>
                        </Select>
                    </Grid>

                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司规模
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间规模
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            className={classes.textField}
                            native
                            value={currentCompany.sizeCode}
                            onChange={(event) => onhandleSelectionChange(event, 'sizeCode')}
                        >
                            <option aria-label="None" value="" />
                            {sizeOptions()}
                        </Select>
                    </Grid>
                    {
                        !currentCompany.indivisible ? (
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <Typography gutterBottom>
                                        是否独立法人
                                </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>否</Grid>
                                        <Grid item>
                                            <Switch checked={currentCompany.independentLegalEntity}
                                                onChange={(event) => onhandleSwitchChange(event, 'independentLegalEntity')}
                                                name="ileCheck" />
                                        </Grid>
                                        <Grid item>是</Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>) : null
                    }

                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            所属地区
                            </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TreeSelect
                            style={{ width: '100%' }}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                            placeholder={<i>请下拉选择</i>}
                            allowClear
                            treeLine
                            value={areaSelection}
                            labelInValue
                            treeData={areaTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => onhandleSelectionChange(value, 'areaCode')}
                        />

                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司地址
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间地址
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            className={classes.textField}
                            value={currentCompany.address || ''}
                            variant="standard"
                            onChange={event => onhandleChange(event, 'address')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        <Typography gutterBottom>
                            所属行业
                            </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TreeSelect
                            className={classes.selectField}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                            placeholder={<i>请下拉选择</i>}
                            allowClear
                            treeLine
                            value={industrySelection}
                            labelInValue
                            treeData={industryTree}
                            treeNodeFilterProp="label"
                            filterTreeNode={false}
                            onChange={(value) => onhandleSelectionChange(value, 'industryCode')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={1}>
                        {
                            !currentCompany.indivisible ? (
                                <Typography gutterBottom>
                                    公司简介
                            </Typography>) : (
                                    <Typography gutterBottom>
                                        车间简介
                            </Typography>)
                        }
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            className={classes.textAreaField}
                            value={currentCompany.opentext || ''}
                            rowsMin={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                        />
                    </Grid>
                </Grid>

                {currentCompany.companyId ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建时间
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentCompany.createTime, true)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                创建人
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {currentCompany.creatorLastName + currentCompany.creatorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }


                {currentCompany.companyId && currentCompany.updateTime ? (
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改时间
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {getDateByTimestamp(currentCompany.updateTime, true)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom>
                                修改人
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography gutterBottom>
                                {currentCompany.revisorLastName + currentCompany.revisorFirstName}
                            </Typography>
                        </Grid>
                    </Grid>) : null
                }

            </Card>

            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        disabled={!companyChange}
                        onClick={event => onhandleSave(event)}
                    >
                        保存
                </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary"
                        onClick={event => onhandleCancel(event)}
                    >
                        取消
                </Button>
                </Grid>
            </Grid>
        </div>
    );
};

CompanyInfo.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleChange: PropTypes.func.isRequired,
    onhandleSelectionChange: PropTypes.func.isRequired,
    onhandleSwitchChange: PropTypes.func.isRequired,
    onhandleCancel: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    currentCompany: PropTypes.object.isRequired,
    parentNode: PropTypes.object,
    companyError: PropTypes.object.isRequired,
    areaTree: PropTypes.array,
    industryTree: PropTypes.array,
    industrySelection: PropTypes.object,
    areaSelection: PropTypes.object,
    companySizes: PropTypes.array.isRequired,
    companyChange: PropTypes.bool.isRequired
};

export default withStyles(CompanyInfo);
