import { createAction, handleActions } from 'redux-actions';
import * as _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';

const initialState = {
  messages: []
};

export const MESSAGE_DURATION = 5000;

export const cleanMessages = createAction('@@xcloud/message/cleanMessages');

export const cleanMessage = createAction('@@xcloud/message/cleanMessage');

export const createMessage = createAction(
  '@@xcloud/message/createMessage',
  (type, message) => {
    return ({
      id: uuidv1(),
      type: type,
      content: message,
      timestamp: Date.now()
    });
  }
);

export const createActionMessage = createAction(
  '@@xcloud/message/createActionMessage',
  (type, message) => (dispatch) => {
    if (type && message)
      dispatch(createMessage(type, message));
  }
)

export const createhttpMessage = createAction(
  '@@xcloud/message/createhttpMessage',
  (res, message) => (dispatch) => {
    let msg = message;
    if (res !== '') {
      if (res.status === 200 || res.status === 201) {
        dispatch(createMessage('success', msg));
      } else if (res.status !== 401) {
        msg = `${message} : ${res.data.message}`;
        dispatch(createMessage('error', msg));
      }
    } else {
      dispatch(createMessage('error', msg));
    }
  });
// Actions

export const messagesReducer = handleActions(
  {
    [createMessage]: (state, { payload: message }) => {
      return {
        ...state,
        messages: [...state.messages, message]
      };
    },
    [cleanMessages]: state => {
      const expiry = Date.now() - (MESSAGE_DURATION + 500);
      return {
        ...state,
        messages: _.reject(state.messages, function (o) { return o.timestamp < expiry; })
      };
    },
    [cleanMessage]: (state, { payload: id }) => {
      return {
        ...state,
        messages: _.reject(state.messages, function (o) { return o.id === id; })
      };
    }
  },
  initialState
);

export default messagesReducer;
