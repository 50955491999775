import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';
import { localization, options } from "@/utils/mtable";
import { getDateByTimestamp } from '@/utils/datetime';
import _ from 'lodash';


const ExceptionBatchAcceptPanel = ({
    classes,
    open,
    type,
    useBatchSetting,
    exceptionBatch,
    exceptionBatchError,
    exceptionList,
    inspectionDictionaries,
    onhandleChangeUseBatchSetting,
    onhandleRowChange,
    onhandleRowCheck,
    onhandleClose,
    onhandleConfirm,
    onhandleChangeExceptionBatch,
    onhandleBatchCheck
}) => {
    console.log('======= ======= ======= exceptionList panel ======= ======= ======= =======')
    console.log('=======type')
    console.log(type)
    console.log('=======useBatchSetting')
    console.log(useBatchSetting)
    console.log('=======exceptionBatch')
    console.log(exceptionBatch)
    console.log('=======exceptionBatchError')
    console.log(exceptionBatchError)

    const tableOptions = {
        ...options,
        padding: 'dense',
        paging: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        search: false,
        toolbar: false
    }

    const assemblycolumns = () => {
        return [
            // {
            //     title: '',
            //     field: 'checkedRow',
            //     render: renderCheckbox,
            //     editable: 'never',
            //     width: '5%',
            // },
            {
                title: '设备编号',
                field: 'deviceNo',
                render: row => row ? <Typography>{row.deviceNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '设备名称',
                field: 'deviceName',
                render: row => row ? <Typography>{row.deviceName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '参数名称',
                field: 'itemName',
                render: row => row ? <Typography>{row.itemName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '异常内容',
                field: 'val',
                render: row => row ? <Typography>{row.val}</Typography> : '',
                editable: 'never'
            },
            {
                title: '标准范围',
                field: 'standardValue',
                render: row => row ? <Typography>{renderValues(row.standardValue)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '所属装置',
                field: 'installTitle',
                render: row => row ? <Typography>{row.installTitle}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检类型',
                field: 'inspectionType',
                render: row => row ? <Typography>{renderInspectionType(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '巡检路线',
                field: 'routeName',
                render: row => row ? <Typography>{row.routeName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发现人',
                field: 'inspectorName',
                render: row => row ? <Typography>{row.inspectorName}</Typography> : '',
                editable: 'never'
            },
            {
                title: '发生时间',
                field: 'time',
                render: row => row ? <Typography>{getDateByTimestamp(row.createTime, true)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '更新时间',
                field: 'time',
                render: row => row ? <Typography>{renderUpdateTime(row)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '处置类型 *',
                field: 'handleType',
                hidden: !(useBatchSetting === 'false' || type === 'confirm'),
                render:
                    row => row ?
                        <FormControl
                            className={classes.resultTypeRadio}
                            error={row && row.handleTypeError !== ''}
                            disabled={!(useBatchSetting === 'false' || type === 'confirm')}
                        >
                            <RadioGroup name="handleType"
                                value={row.handleType}
                                onChange={event => onhandleRowChange(event, row)}
                                defaultValue={'HDT01'}>
                                <FormControlLabel value={'HDT01'} control={<Radio size={'small'} style={{ padding: 2 }} />} label="暂不处置，观察运行" style={{ margin: 0 }} />
                                <FormControlLabel value={'HDT02'} control={<Radio size={'small'} style={{ padding: 2 }} />} label="错报" style={{ margin: 0 }} />
                                <FormControlLabel value={'HDT03'} control={<Radio size={'small'} style={{ padding: 2 }} />} label="已处置" style={{ margin: 0 }} />
                            </RadioGroup>
                            <FormHelperText>{row.handleTypeError}</FormHelperText>
                        </FormControl>
                        :
                        '',
                editable: 'never',
                width: '190px',
                headerStyle: { padding: 0 },
                cellStyle: { padding: 2 }
            },
            {
                title: '处置说明 *',
                field: 'handleResult',
                hidden: !(useBatchSetting === 'false' || type === 'confirm'),
                render:
                    row => row ?
                        <TextField
                            multiline
                            variant='outlined'
                            disabled={!(useBatchSetting === 'false' || type === 'confirm')}
                            error={row && row.handleResultError !== ''}
                            helperText={row.handleResultError}
                            className={classes.textField}
                            value={row.handleResult || ''}
                            rows={4}
                            name='handleResult'
                            onChange={event => onhandleRowChange(event, row)}
                        // onBlur={event => onhandleRowCheck(event)}
                        />
                        :
                        '',
                editable: 'never',
                width: '120px',
                headerStyle: { padding: 0 },
                cellStyle: { padding: 2 }
            },
            {
                title: '确认说明',
                field: 'acceptResult',
                hidden: !(useBatchSetting === 'false' && type === 'confirm'),
                render:
                    row => row ?
                        <TextField
                            multiline
                            variant='outlined'
                            disabled={!(useBatchSetting === 'false' && type === 'confirm')}
                            className={classes.textField}
                            value={row.acceptResult || ''}
                            rows={4}
                            name='acceptResult'
                            onChange={event => onhandleRowChange(event, row)}
                        // onBlur={event => onhandleRowCheck(event)}
                        />
                        :
                        '',
                editable: 'never',
                width: '120px',
                headerStyle: { padding: 0 },
                cellStyle: { padding: 2 }
            },
        ];
    };

    const renderInspectionType = (row) => {
        let item = _.filter(inspectionDictionaries, function (o) { return row.inspectionType === o.code && o.type === "OITYPE" })
        if (item && item.length) {
            return item[0].name
        } else {
            return ''
        }
    }

    const renderValues = (standardValue) => {
        if (standardValue.indexOf(',') > -1) {
            let value = standardValue.split(',')[0] + ' - ' + standardValue.split(',')[1];
            return value
        } else {
            let value = standardValue
            return value
        }
    };

    const renderUpdateTime = (row) => {
        return row.updateTime ? getDateByTimestamp(row.updateTime, true) : ''
    }

    return (
        <Dialog open={open} onClose={onhandleClose} className={classes.dialog} fullWidth disableBackdropClick maxWidth={'xl'} scroll={'body'}>
            <MuiDialogTitle disableTypography>
                <Typography component={'span'} variant="h3">{type === 'confirm' ? "异常批量确认" : "异常批量处置"}</Typography>
                {onhandleClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onhandleClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <DialogContent>
                <Grid container className={classes.contentGrid}>
                    <Grid item className={classes.itemGrid}>
                        <FormControl className={classes.formControl}>
                            <RadioGroup row aria-label="批量处置" name="useBatchSetting"
                                value={useBatchSetting}
                                onChange={onhandleChangeUseBatchSetting}
                                defaultValue={'true'}>
                                <FormControlLabel value={'true'} control={<Radio size={'small'} />} label={"统一" + (type === 'handle' ? '处置' : '确认')} />
                                <FormControlLabel value={'false'} control={<Radio size={'small'} />} label={"逐项" + (type === 'handle' ? '处置' : '确认')} />
                            </RadioGroup>
                            {/* <FormHelperText>{exceptionBatchError.handleType}</FormHelperText> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.contentGrid}>
                    <MaterialTable
                        className={classes.materialtable}
                        columns={assemblycolumns()}
                        data={exceptionList}
                        options={tableOptions}
                        localization={localization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                    />
                </Grid>
                {useBatchSetting === 'true' && type === 'handle' &&
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <FormControl error={exceptionBatchError.handleType !== ''} className={classes.formControl}>
                                <RadioGroup row aria-label="处置类型" name="handleType"
                                    value={exceptionBatch.handleType}
                                    onChange={onhandleChangeExceptionBatch}
                                    defaultValue={'HDT01'}>
                                    <FormControlLabel value={'HDT01'} control={<Radio size={'small'} />} label="暂不处置，观察运行" />
                                    <FormControlLabel value={'HDT02'} control={<Radio size={'small'} />} label="错报" />
                                    <FormControlLabel value={'HDT03'} control={<Radio size={'small'} />} label="已处置" />
                                </RadioGroup>
                                <FormHelperText>{exceptionBatchError.handleType}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"处置说明"}
                                variant='outlined'
                                error={exceptionBatchError.handleResult !== ''}
                                helperText={exceptionBatchError.handleResult}
                                className={classes.textField}
                                value={exceptionBatch.handleResult || ''}
                                rows={4}
                                name='handleResult'
                                onChange={event => onhandleChangeExceptionBatch(event)}
                                onBlur={event => onhandleBatchCheck(event)}
                            />
                        </Grid>
                    </Grid>
                }
                {useBatchSetting === 'true' && type === 'confirm' &&
                    <Grid container className={classes.contentGrid}>
                        <Grid item className={classes.itemGrid}>
                            <TextField
                                multiline
                                label={"确认说明"}
                                variant='outlined'
                                // error={exceptionBatchError.acceptResult !== ''}
                                helperText={exceptionBatchError.acceptResult}
                                className={classes.textField}
                                value={exceptionBatch.acceptResult || ''}
                                rows={4}
                                name='acceptResult'
                                onChange={event => onhandleChangeExceptionBatch(event)}
                                onBlur={event => onhandleBatchCheck(event)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onhandleConfirm}
                >
                    确定</Button>
            </DialogActions>
        </Dialog>

    );
};
ExceptionBatchAcceptPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    exceptionBatchError: PropTypes.object.isRequired,
    exceptionBatch: PropTypes.object.isRequired
};

ExceptionBatchAcceptPanel.defaultProps = {

}

export default withStyles(ExceptionBatchAcceptPanel);