import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate_hms } from '@/utils/datetime';

const HWInstockComponent = props => {
    const { classes,
        selectedDate,
        // constant,
        onhandleDateChange,
        hwList,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: true,
    }
    const tableStyle = {
        ...style
    }

    const detailTableStyle = {
        ...style,
        paddingLeft: 45
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单据',
                field: 'billNo',
                render: row => <Typography>{row.billNo}</Typography>
            },
            {
                title: '所属分厂',
                field: 'companyName',
                render: row => <Typography>{row.ownerName}</Typography>
            },
            // {
            //     title: '班组',
            //     field: 'dptName',
            //     render: row => <Typography>{findDptNameById(constant, row.deptId)}</Typography>
            // },
            // {
            //     title: '进场人',
            //     field: 'instockName',
            //     render: row => <Typography>{row.instockName}</Typography>
            // },
            {
                title: '进场时间',
                field: 'inDate',
                render: row => <Typography>{formatDate_hms(row.inDate)}</Typography>
            }
        ]

        return columns

    }

    const assemblycolumnsHwInfo = () => {

        const columns = [
            {
                title: '物料',
                field: 'materialName',
                render: row => <Typography>{row.material ? row.material.materialName : ""}</Typography>

            }, {
                title: '规格',
                field: 'specification',
                render: row => <Typography>{row.material ? row.material.specification : ""}</Typography>
            },
            {
                title: '进场数量',
                field: 'qty',
                align: 'right',
                render: row => <Typography>{row.qty}</Typography>
            },
            {
                title: '单位',
                field: 'unit',
                render: row => <Typography>{row.unit ? row.unit.unitName : ""}</Typography>
            }
        ]

        return columns

    }

    const assemblyData = () => {
        return hwList
    }

    const assemblyDataHwInfo = (hwInfo) => {
        return hwInfo.miscellaneousDtls ? hwInfo.miscellaneousDtls : []
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>危废进场列表</Typography>}
                        detailPanel={[{
                            render: salorder => {
                                return (
                                    <Grid className={classes.detailTable}
                                    >
                                        <MaterialTable
                                            width="60%"
                                            columns={assemblycolumnsHwInfo()}
                                            data={assemblyDataHwInfo(salorder)}
                                            options={{ ...options, paging: false }}
                                            localization={tableLocalization}
                                            style={detailTableStyle}
                                            title={<Typography variant='h4'>危废明细</Typography>}
                                        >
                                        </MaterialTable>
                                    </Grid>
                                )
                            }
                        }]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

HWInstockComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

HWInstockComponent.defaultProps = {
    hwList: []
};

export default withStyles(HWInstockComponent);