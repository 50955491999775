import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MessagePage from '../components/MessagePage';
import { cleanMessage, cleanMessages } from '../redux/modules/message';

class MessagePageContainer extends Component {
    static propTypes = {
      messages: PropTypes.any,
      oncleanMessages: PropTypes.func,
      oncleanMessage: PropTypes.func
    }

    handleCleanMessages() {
      if (this.props.oncleanMessages) {
        this.props.oncleanMessages();
      }
    }

    handleCleanMessage(event, id) {
      if (this.props.oncleanMessage) {
        this.props.oncleanMessage(id);
      }
    }

    render() {
      return (
        <MessagePage
          messages={this.props.messages}
          cleanMessages={this.handleCleanMessages.bind(this)}
          cleanMessage={this.handleCleanMessage.bind(this)}
          messgaeDuration={5000}/>
      );
    }
}

const mapStateToProps = (state) => {
  return {
    messages: state.message.messages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    oncleanMessages: () => {
      dispatch(cleanMessages());
    },
    oncleanMessage: (id) => {
      dispatch(cleanMessage(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagePageContainer);
