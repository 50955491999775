import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Card,
    Divider,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Grid
} from "@material-ui/core";
import { Commute } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import zhCN from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { getMoment as moment } from '@/utils/datetime';
import { Prompt } from 'react-router-dom';

const InsuranceEdit = props => {

    const {
        classes,
        onhandleSave,
        onhandleChange,
        onhandleSelectChange,
        onhandleDateChange,
        onhandleBack,
        // onhandleCheck,
        // currentUserInfo,
        onhandleAddVehicle,
        currentInsurance,
        // companyList,
        insTypeList,
        editable,
        insuranceError,
        insuranceChange,
        onhandleListMapping,
        // inspectionDptMapping,
    } = props;

    console.log('currentInsurance  insuranceError')
    console.log(currentInsurance)
    console.log(insuranceError)

    // const insTypeOptions = () => (
    //     <React.Fragment>
    //         {insTypeList ?
    //             insTypeList.map(data => (
    //                 <option value={data.code} key={data.code}>{data.name}</option>
    //             )) : null
    //         }
    //     </React.Fragment>
    // )

    return (
        <div>
            <Card className={classes.card}>

                <Grid container spacing={0} className={classes.gridContainer} justify={'space-between'}>
                    <Grid item xs={6}>
                        <Typography variant="h3">
                            {currentInsurance.insId ? "保险记录详情" : "新建保险记录"}
                        </Typography>
                    </Grid>
                    {/* {currentInsurance.insId ? (
                        <Grid item margin={0}>
                            <Typography variant="h5" style={{ display: 'inline-block' }}>
                                {'编号: ' + currentInsurance.serialNo}</Typography>
                        </Grid>) : null
                    } */}
                </Grid>

                {editable &&
                    <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                        <Grid container item xs={12} justify={'space-between'} alignItems={'center'}>
                            <Typography variant="h4" className={classes.subTitle}>
                                {currentInsurance.deviceId ? '当前车辆信息' : '请选择保险车辆'}
                            </Typography>
                            <Button variant='contained'
                                className={classes.button}
                                color='primary'
                                onClick={onhandleAddVehicle}
                            >
                                <Commute />{currentInsurance.deviceId ? '变更保险车辆' : '点击选择'}
                            </Button>
                        </Grid>
                    </Grid>
                }

                {Boolean(currentInsurance.deviceId) && <>
                    <Grid container className={classes.basicInfoContainer} sm={8}>
                        <Grid container item className={classes.infoGrid} sm={4}>
                            <Typography className={classes.infoLabel}>
                                所属公司:
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {onhandleListMapping(currentInsurance.device.companyId, 'companyId') || '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={4}>
                            <Typography className={classes.infoLabel}>
                                车辆名称:
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentInsurance.device.deviceName || '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={4}>
                            <Typography className={classes.infoLabel}>
                                车牌号:
                            </Typography>
                            <Typography className={classes.infoContent}>
                                {currentInsurance.device.tagNo || '-'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                        <Grid container item xs={12} alignItems={'center'}>
                            <Typography variant="h4" className={classes.subTitle}>
                                {'保险明细'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                <span className={classes.required}> *</span>
                                {'费用类型'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    select
                                    variant="outlined"
                                    size='small'
                                    error={insuranceError.insType !== ''}
                                    disabled={!editable}
                                    value={currentInsurance.insType}
                                    helperText={insuranceError.insType || ''}
                                    onChange={(event) => onhandleSelectChange(event, 'insType')}
                                >
                                    <option aria-label="None" value="" />
                                    {insTypeList ?
                                        insTypeList.map(data => (
                                            <option value={data.code} key={data.code}>{data.name}</option>
                                        )) : null
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {/* <span className={classes.required}> *</span> */}
                                {'保险公司'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    disabled={!editable}
                                    // error={insuranceError.insCompany !== ''}
                                    // helperText={insuranceError.insCompany}
                                    variant="outlined"
                                    size='small'
                                    value={currentInsurance.insCompany || ''}
                                    name='insCompany'
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography gutterBottom className={classes.label}>
                                <span className={classes.required}> *</span>
                                {'参保生效时间'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled={!editable}
                                        InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                        disableToolbar
                                        autoOk
                                        variant='inline'
                                        inputVariant="outlined"
                                        size="small"
                                        format='yyyy/MM/dd'
                                        error={insuranceError.startDate !== ''}
                                        helperText={insuranceError.startDate}
                                        value={currentInsurance.startDate || null}
                                        onChange={date => onhandleDateChange(date, 'startDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography gutterBottom className={classes.label} >
                                <span className={classes.required}> *</span>
                                {'参保失效时间'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.textField}
                                        disabled={!editable}
                                        InputProps={{ readOnly: true, style: { paddingRight: 0 }, }}
                                        disableToolbar
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy/MM/dd"
                                        error={insuranceError.endDate !== ''}
                                        helperText={insuranceError.endDate}
                                        value={currentInsurance.endDate || null}
                                        onChange={date => onhandleDateChange(date, 'endDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': '修改时间',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider} />

                    <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'商业险费用'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee1 || ''}
                                    name='fee1'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridHolder} sm={6}></Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'交强险费用'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee2 || ''}
                                    name='fee2'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'车船税'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee3 || ''}
                                    name='fee3'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'增值税'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee4 || ''}
                                    name='fee4'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'其他费用1'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee5 || ''}
                                    name='fee5'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'其他费用2'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee6 || ''}
                                    name='fee6'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'其他费用3'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee7 || ''}
                                    name='fee7'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'其他费用4'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee8 || ''}
                                    name='fee8'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'其他费用5'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee9 || ''}
                                    name='fee9'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'小计（含税）'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.fee10 || ''}
                                    name='fee10'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                <span className={classes.required}> *</span>
                                {' 小计(不含税)'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    error={insuranceError.feeSum !== ''}
                                    helperText={insuranceError.feeSum}
                                    disabled={!editable}
                                    value={currentInsurance.feeSum || ''}
                                    name='feeSum'
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{'元'}</InputAdornment>,
                                    }}
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6}>
                            <Typography className={classes.label}>
                                {'投保人'}
                            </Typography>
                            <Grid item className={classes.formContainer}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    size='small'
                                    disabled={!editable}
                                    value={currentInsurance.policyHolder || ''}
                                    name='policyHolder'
                                    onChange={event => onhandleChange(event)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item className={classes.gridHolder} sm={6}></Grid>

                    <Divider className={classes.divider} />

                    {/* <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                    <Grid container item className={classes.gridItem} sm={6}>
                        <Typography gutterBottom className={classes.label}>
                            备注
                        </Typography>
                        <TextField
                            multiline
                            variant="outlined"
                            size='small'
                            InputProps={{
                                readOnly: !editable
                            }}
                            className={classes.textarea}
                            value={currentInsurance.opentext || ''}
                            rows={5}
                            onChange={event => onhandleChange(event, 'opentext')}
                            onBlur={event => onhandleCheck(event, 'opentext')}
                        />
                    </Grid>
                </Grid> */}

                    {currentInsurance.insId ? (
                        <>
                            <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                                <Grid container item className={classes.infoGrid} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        创建人:
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {currentInsurance.creatorLastName + currentInsurance.creatorFirstName}
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.infoGrid} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        创建时间:
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {moment()(currentInsurance.createTime).format('yyyy/MM/DD HH:mm:ss')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>) : null
                    }

                    {currentInsurance.insId && currentInsurance.updateTime ? (
                        <>
                            <Grid container spacing={1} className={classes.gridContainer} sm={8}>
                                <Grid container item className={classes.infoGrid} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        更新人:
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {currentInsurance.revisorLastName + currentInsurance.revisorFirstName}
                                    </Typography>
                                </Grid>
                                <Grid container item className={classes.infoGrid} sm={6}>
                                    <Typography className={classes.infoLabel}>
                                        更新时间:
                                    </Typography>
                                    <Typography className={classes.infoContent}>
                                        {moment()(currentInsurance.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>) : null
                    }
                </>}

                <Grid container spacing={0} className={classes.bottomButtonContainer}>
                    {editable ? (
                        <Button variant="contained" color="primary"
                            onClick={event => onhandleSave(event)}
                            disabled={!insuranceChange}>
                            {'保存'}</Button>
                    ) : null
                    }
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={event => onhandleBack(event)}>
                        {'返回'}
                    </Button>
                </Grid>

            </Card>
            <Prompt message="数据未保存,确定要离开？" when={insuranceChange} />
        </div>
    );
};

InsuranceEdit.propTypes = {
    onhandleSave: PropTypes.func.isRequired,
    onhandleSelectChange: PropTypes.func.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleCheck: PropTypes.func.isRequired,
    onhandleListMapping: PropTypes.func.isRequired,
    currentInsurance: PropTypes.object.isRequired,
    insuranceError: PropTypes.object.isRequired,
    insuranceChange: PropTypes.bool.isRequired,
    companyList: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    insTypeList: PropTypes.array.isRequired,
    categoryList: PropTypes.array.isRequired,
    rootCauseList: PropTypes.array.isRequired,
    methodList: PropTypes.array.isRequired,
    disciplineList: PropTypes.array.isRequired,
    resultTypeList: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired
};

export default withStyles(InsuranceEdit);
