import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SolutionPanel from '@/components/OperationManagement/Appointment/CreateAppointment/SolutionPanel';
import { createmodal, cleanmodal } from '@/redux/modules/modal';
import { download } from '@/redux/modules/minio';
// import { getMoment as moment } from "@/utils/datetime";
import _ from 'lodash';

class SolutionPanelContainer extends Component {
    static propTypes = {
        userProfile: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            solutionError: {
                solutionName: '',
                solutionContent: '',
                file: '',
            },
            open: false,
            solution: {},
            confirmCallback: null,
            closeCallback: null,
        };
    }
    componentDidUpdate = (oldProps) => {
        if (oldProps.solution !== this.props.solution) {
            console.log(this.props.solution)
            this.setState({
                solutionError: {
                    solutionName: '',
                    solutionContent: '',
                    file: '',
                },
                open: this.props.open,
                solution: this.props.solution,
                confirmCallback: this.props.confirmCallback,
                closeCallback: this.props.closeCallback,
            })
        }

    }


    // componentDidMount() {
    //     console.log(this.props.solution)
    //     this.setState({
    //         solutionError: {
    //             solutionName: '',
    //             solutionContent: '',
    //             file: '',
    //         },
    //         open: this.props.open,
    //         solution: this.props.solution,
    //         confirmCallback: this.props.confirmCallback,
    //         closeCallback: this.props.closeCallback,
    //     })
    // }

    handleConfirm() {
        const { confirmCallback, solution } = this.state;
        if (this.handleCheck(null, null)) {
            if (confirmCallback) confirmCallback(solution)
            // if (onhandleClose) onhandleClose();
        }
    }

    handleClose() {
        const { closeCallBack } = this.props;
        // close action
        if (closeCallBack) {
            closeCallBack()
        } else {
            this.setState({
                open: false
            })
        }

    }

    handleDeleteFile(e) {
        let newSolution = {
            ...this.state.solution
        }
        newSolution.solutionDocBack = this.state.solution.solutionDoc
        newSolution.solutionDoc = {};
        this.setState({
            solution: newSolution
        })
        setTimeout(() => {
            this.handleCheck(null, null);
        }, 100)
    }

    handleDownloadFile() {
        const { onhandleDownloadFile, userProfile } = this.props;
        let tenantId = userProfile.roles[0].tenantId;
        let envCode = 'dev';
        if (window.location.host.indexOf('3050') > -1) {
            envCode = 'dev';
        } else if (window.location.host.indexOf('3060') > -1) {
            envCode = 'uat';
        } else if (process.env.NODE_ENV === 'production') {
            envCode = 'prod';
        }
        let mybucket = _.replace(envCode + '.{tenant_id}.operation.permit-doc', '{tenant_id}', tenantId);
        const fileName = this.state.solution.solutionDoc.docLink;
        onhandleDownloadFile(fileName, mybucket);
    }

    handleChange(event) {
        let newSolution = {
            ...this.state.solution
        }
        const field = event.target.name;
        newSolution[field] = event.target.value;

        this.setState({
            solution: newSolution
        })
        setTimeout(() => {
            this.handleCheck(null, field);
        }, 100)
    }

    handleUploadConfirm(files, setUploadStatus) {
        let newSolution = {
            ...this.state.solution
        }
        newSolution.file = files;

        this.setState({
            solution: newSolution
        })
        setUploadStatus(false);
        this.props.onhandleCloseFileUpload();
        setTimeout(() => {
            this.handleCheck(null, 'file');
        }, 100)
    }

    handleUploadFileSelected(files) {
        return files;
    }

    openUploadModal() {
        let conifg = {
            // 上传组件初始化配置
            title: '选择文件',
            confirmText: '确定',
            note: '请拖拽文件至此处',
            // 支持的文件后缀名，用','分隔，仅用作初步验证，请在onFileSelected中进行进一步类型判断
            fileType: '.xls,.xlsx,.doc,.docx,.pdf,.zip,.rar,.png,.bmp,.jpg,.jpeg',
            // 支持的文件大小，仅作为提示，请在onFileSelected中进行大小判断
            maxSize: '1024MB',
            // 是否支持多文件上传
            multiple: false,
            // 拖拽或选择文件后的回调。获取参数为input<file>获取到的文件对象。可用于文件验证，最终需要返回处理后的文件对象数组
            onFileSelected: this.handleUploadFileSelected.bind(this),
            // 点击确认后的回调。获取参数为onFileSelected返回的【文件对象列表】以及【按钮状态（普通/上传中）】
            onConfirm: this.handleUploadConfirm.bind(this),
            // 拓展组件
            // children: 'DeviceDocPage'
        };
        this.props.onhandleOpenFileUpload(conifg);
    }

    handleCheck = (event, field) => {
        let err = {};

        if (!field || field === 'solutionName') {
            if (_.trim(this.state.solution.solutionName) === '') {
                err['solutionName'] = '作业名称不能为空';
            } else {
                err['solutionName'] = '';
            }
        }


        if (!field || field === 'solutionContent') {
            if (this.state.solution.solutionId) {
                if (this.state.solution.solutionDoc && this.state.solution.solutionDoc.length === 0 &&
                    (!this.state.solution.file || !this.state.solution.file.length || this.state.solution.file.length === 0)
                ) {
                    if (_.trim(this.state.solution.solutionContent) === '') {
                        err['solutionContent'] = '没有方案文档时作业内容不能为空';
                    } else {
                        err['solutionContent'] = '';
                        err['file'] = '';
                    }
                } else {
                    err['solutionContent'] = '';
                    err['file'] = '';
                }
            } else {
                if (!this.state.solution.file || !this.state.solution.file.length || this.state.solution.file.length === 0
                ) {
                    if (_.trim(this.state.solution.solutionContent) === '') {
                        err['solutionContent'] = '没有方案文档时作业内容不能为空';
                    } else {
                        err['solutionContent'] = '';
                        err['file'] = '';
                    }
                } else {
                    err['solutionContent'] = '';
                    err['file'] = '';
                }
            }
        }

        if (!field || field === 'file') {
            // 检测文件是否上传
            if (!this.state.solution.solutionContent || this.state.solution.solutionContent === '') {
                if (this.state.solution.solutionId) {
                    if (this.state.solution.solutionDoc && this.state.solution.solutionDoc.length === 0 &&
                        (!this.state.solution.file || !this.state.solution.file.length || this.state.solution.file.length === 0)
                    ) {
                        err['file'] = '没有作业内容时请上传方案文档';
                    } else {
                        err['file'] = '';
                        err['solutionContent'] = '';
                    }
                } else {
                    if (!this.state.solution.file || !this.state.solution.file.length || this.state.solution.file.length === 0
                    ) {
                        err['file'] = '没有作业内容时请上传方案文档';
                    } else {
                        err['file'] = '';
                        err['solutionContent'] = '';
                    }
                }
            }
        }

        let newError = {
            ...this.state.solutionError,
            ...err
        }
        this.setState({
            solutionError: newError
        });
        if (_.findIndex(Object.values(newError), function (o) { return o !== ''; }) < 0) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <SolutionPanel
                open={this.props.open}
                solution={this.state.solution}
                solutionError={this.state.solutionError}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleUpload={this.openUploadModal.bind(this)}
                onhandleDeleteFile={this.handleDeleteFile.bind(this)}
                onhandleDownloadFile={this.handleDownloadFile.bind(this)}
                onhandleConfirm={this.handleConfirm.bind(this)}
                onhandleClose={this.handleClose.bind(this)}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.auth.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleDownloadFile: (docName, mybucket) => {
            return dispatch(download(docName, mybucket))
        },
        onhandleOpenFileUpload: (config) => {
            dispatch(createmodal('commonFileUpload', config));
        },
        onhandleCloseFileUpload: (config) => {
            dispatch(cleanmodal());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SolutionPanelContainer);