import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
} from '@material-ui/core';

import { findBuNameById } from "../../../../utils/constant";

import MaterialTable from 'material-table';
import { localization, options } from "../../../../utils/mtable";


import * as _ from 'lodash';

const GoalPlan = ({
    classes,
    constant,
    goalDetails,
}) => {
    const getValFormat = (detail) => {
        if (!detail.vals)
            return ''
        const vals = JSON.parse(detail.vals)
        let str = detail.opentext

        for (let key in vals) {
            /* eslint-disable no-useless-escape */
            let re = new RegExp("\{" + key.replace('$', '\\$') + "\=[^\{]*\}", "gm")
            str = str.replace(re, vals[key])
        }
        return str
    }

    const getEditValFormat = (detail) => {
        if (!detail.vals)
            return ''
        const vals = JSON.parse(detail.vals)
        return vals.$target
    }


    const assemblycolumns = () => {

        let columns = [
            {
                title: '业务分类',
                field: 'unitId',
                width: 72,
                render: row => row.center && (<Typography>{findBuNameById(constant, row.unitId)}</Typography>),
                editable: 'never',
                cellStyle: (value, rowData) => {
                    if (rowData.unitDuplicated) {
                        return { border: ' 0px solid black', }
                    }
                    return {}
                },
            }, {
                title: '目标项',
                editable: 'never',
                field: 'tempName',
                render: row => <Typography>{row.tempName}</Typography>,
                width: 160,
            }, {
                title: '目标值',
                field: 'edit_vals',
                render: row => <Typography>{getValFormat(row)}</Typography>,
                width: 112,
            }]

        //下级单位
        if (goalDetails && goalDetails.length > 0 && goalDetails[0].extras && goalDetails[0].extras.length > 0) {
            //下级单位目标值
            for (let extra of goalDetails[0].extras) {
                let col = {
                    title: extra.companyAlias,
                    width: 96,
                    edit: 'never',
                    field: 'vals_' + extra.companyId,
                    render: row => <Typography>{row['vals_' + extra.companyId]}</Typography>
                }
                columns.push(col)
            }
        }

        return columns
    }

    const tableOptions = {
        ...options,
        tableLayout: 'auto',
        toolbar: false,
        // fixedColumns: {
        //     left: 4,
        //     right: 0
        // }
    }
    const tableLocalization = {
        ...localization,
    }


    const assemblyData = () => {

        if (!goalDetails || goalDetails.length === 0)
            return []

        //排序按业务线和指标排序
        let goalDetailsSort = _.sortBy(goalDetails, ['unitId', 'content'])


        let assemblyData = []
        let preUnitId = 0
        let sameCount = 0
        let sameSum = 0
        let sameChecked = false
        let centerIndex = null;


        for (let idx in goalDetailsSort) {
            let row = {
                ...goalDetailsSort[idx],
                num: goalDetailsSort.length - Number(idx),
                edit_vals: getEditValFormat(goalDetailsSort[idx])  //JSON中允许编辑的字段默认为$target
            }
            if (goalDetailsSort[idx].extras && goalDetailsSort[idx].extras.length > 0) {
                for (let gd of goalDetailsSort[idx].extras) {
                    row['vals_' + gd.companyId] = getValFormat(gd)
                }
            }

            if (preUnitId === goalDetailsSort[idx].unitId) {
                row['unitDuplicated'] = true
                sameSum = sameSum + Number(idx)
                sameCount++
            }
            else {
                if (Number(idx) !== 0) {
                    centerIndex = parseInt(sameSum / sameCount)
                    sameChecked = true
                }
                row['unitDuplicated'] = false
                preUnitId = goalDetailsSort[idx].unitId
                sameSum = Number(idx)
                sameCount = 1
            }
            assemblyData.push(row)
            if (sameChecked) {
                assemblyData[centerIndex]['center'] = true
                sameChecked = false
            }
        }
        assemblyData[parseInt(sameSum / sameCount)]['center'] = true
        return assemblyData.reverse()
    }


    return (
        <div alignitems="center" className={classes.planitemroot}>
            <Grid>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        title=''
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={{
                            border: '0px solid black',
                            boxShadow: 'none'
                        }}
                    >
                    </MaterialTable>
                </Grid>
                <Grid>
                </Grid>
            </Grid>

        </div>

    );
};
GoalPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    goalPlan: PropTypes.object,
    constant: PropTypes.object,
    businessUnits: PropTypes.array,
    currentProcessStatus: PropTypes.object,
    goalDetails: PropTypes.array,
    onhandleEdit: PropTypes.func,
    onhandleUpdate: PropTypes.func,
    onhandleProcess: PropTypes.func,
};

GoalPlan.defaultProps = {
    goalPlan: {},
    constant: {},
    businessUnits: [],
    currentProcessStatus: {},
    goalDetails: [],
}

export default withStyles(GoalPlan);