import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import { formatDate, formatDate_hms } from '@/utils/datetime';

const PickingComponent = props => {
    const { classes,
        selectedDate,
        onhandleOpen,
        onhandleDateChange,
        pickList,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: true,
    }
    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '单据号',
                field: 'billNo',
                render: row => <Typography>{row.billNo}</Typography>
            },
            {
                title: '领料分厂',
                field: 'pickCompanyName',
                render: row => <Typography>{row.pickCompanyName}</Typography>
            }, {
                title: '领料班组',
                field: 'pickDptName',
                render: row => <Typography>{row.pickDptName}</Typography>
            },
            // {
            //     title: '领料人',
            //     field: 'pickerName',
            //     render: row => <Typography>{row.pickerName}</Typography>
            // },
            // {
            //     title: '仓管员',
            //     field: 'stockerName',
            //     render: row => <Typography>{row.stockerName}</Typography>
            // },
            {
                title: '领料时间',
                field: 'pickDate',
                render: row => <Typography>{formatDate(row.pickDate)}</Typography>
            },
            {
                title: '创建时间',
                field: 'createTime',
                render: row => <Typography>{formatDate_hms(row.createTime)}</Typography>
            }
        ]

        return columns

    }

    const assemblyData = () => {
        return pickList
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>生产领料列表</Typography>}
                        actions={[
                            rowData => (
                                {
                                    icon: 'description',
                                    tooltip: '查看',
                                    onClick: (event, rowData) => {
                                        onhandleOpen(rowData)
                                    }
                                }
                            )

                        ]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

PickingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

PickingComponent.defaultProps = {
    pickList: []
};

export default withStyles(PickingComponent);