// import * as _ from "lodash";
import { getStore } from '../../../../redux/store';
export default class KonvaUtil {
    haveIntersection(r1, r2) {
        return !(
            r2.x > r1.x + r1.width ||
            r2.x + r2.width < r1.x ||
            r2.y > r1.y + r1.height ||
            r2.y + r2.height < r1.y
        );
    }

    shape1ContainShape2(r1, r2) {
        return (
            r2.x >= r1.x &&
            r2.x <= r1.x + r1.width &&
            r2.y >= r1.y &&
            r2.y <= r1.y + r1.height &&
            r2.x + r2.width >= r1.x &&
            r2.x + r2.width <= r1.x + r1.width &&
            r2.y + r2.height >= r1.y &&
            r2.y + r2.height <= r1.y + r1.height
        )
    }

    pointInsideShape(x, y, r1) {
        return (
            x >= r1.x &&
            x <= r1.x + r1.width &&
            y >= r1.y &&
            y <= r1.y + r1.height
        )
    }

    pinRest(action, props) {
        let { setPin } = props;
        if (!(action === 'In' || action === 'Out')) {
            let stage = props.getStage();
            stage.container().style.cursor = 'default';
            stage.position({ x: 0, y: 0 });
            stage.draggable(false);
            stage.batchDraw();
            setPin(false);
        }
    }

    zoomAction(action, props) {
        let { scaleBy, setScale } = props;
        let stage = props.getStage();
        var oldScale = stage.scaleX() ? stage.scaleX() : 1;

        var newScale = 1;
        if (action === 'In') {
            newScale = oldScale * scaleBy;
        } else if (action === 'Out') {
            newScale = oldScale / scaleBy;
        }
        stage.scale({ x: newScale, y: newScale });
        setScale(newScale);
        stage.batchDraw();
    }

    updatePreview(props, layer, previewStage) {
        let previewLayer = previewStage.findOne('.previewLayer');
        previewLayer.destroy();
        previewLayer = layer.clone({ listening: false });
        previewLayer.name('previewLayer');
        previewStage.add(previewLayer);
    }

    updateView(props, layer, previewStage, type = 'edit') {
        let store = getStore();
        let unitView = (type === 'view') ? store.getState().viewSetting.unitView : store.getState().setting.unitView;
        let unitGroup = layer.find('.Unit');
        let areaGroup = layer.find('.Area');
        if (unitView) {
            if (unitGroup && unitGroup.length > 0) {
                unitGroup.forEach(unit => {
                    unit.show();
                    unit.moveToBottom();
                });
            }
            if (areaGroup && areaGroup.length > 0) {
                areaGroup.forEach(area => {
                    area.hide();
                });
            }
        } else {
            if (unitGroup && unitGroup.length > 0) {
                unitGroup.forEach(unit => {
                    unit.hide();
                });
            }
            if (areaGroup && areaGroup.length > 0) {
                areaGroup.forEach(area => {
                    area.show();
                    area.moveToBottom();
                });
            }
        }
        layer.batchDraw();
        this.updatePreview(props, layer, previewStage);
    }

    updatePreviewRect(previewStage, left, width, top, height) {
        let controlLayer = previewStage.findOne('.controlLayer');
        let rect = controlLayer.findOne('.previewRect');
        let x = (previewStage.width() - rect.getClientRect().width) * (left / width) / previewStage.scaleX();
        let y = (previewStage.height() - rect.getClientRect().height) * (top / height) / previewStage.scaleY();
        rect.x(x);
        rect.y(y);
        controlLayer.batchDraw();
    }
}