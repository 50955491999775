import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
    Button,
    // Divider,
    Typography,
    // InputAdornment,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';

import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';

const CreateTeamActivityPlan = ({
    classes,
    open,
    planName,
    selectedDate,
    onhandleAddTeamActivityPlan,
    onhandleChangePlan,
    onhandleChangeDate,
    onhandleClose,
}) => {
    return (
        <div alignitems="center" className={classes.root}>
            <Dialog open={open}>
                <DialogTitle  >
                    <Typography component={'span'} variant='h3'>创建班组活动月计划</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid container className={classes.contentGrid} >
                            <Grid item className={classes.action}>
                                <TextField className={classes.textfield}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="活动名称"
                                    value={planName}
                                    name='planName'
                                    onChange={onhandleChangePlan}
                                />
                            </Grid>
                            <Grid item className={classes.action}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        fullWidth
                                        label={'月份'}
                                        inputVariant="outlined"
                                        views={['year', 'month']}
                                        format="yyyy/MM"
                                        id="date-selector"
                                        margin="normal"
                                        value={selectedDate}
                                        onChange={onhandleChangeDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        {/* <Grid item><Divider orientation="vertical" /></Grid> */}
                        {/* <Grid item>
                            <Grid className={classes.action}>
                                <Typography variant='h5'>班组活动规则</Typography>
                            </Grid>
                            <Grid container className={classes.contentGrid}>
                                {rules.map(rule => (
                                    <Grid item key={rule.ruleId} className={classes.action}>
                                        <TextField className={classes.textfield}
                                            variant="outlined"
                                            label={rule.ruleName}
                                            value={rule.ruleValue}
                                            name='rule'
                                            onChange={onhandleChangeRule.bind(this, rule)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{rule.ruleUnit}</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {/* <Button variant='outlined'
                        color='primary'
                        disabled={!ruleChanged}
                        onClick={onhandleSaveRule}>
                        <Save />保存规则
                    </Button> */}
                    <Button variant='contained' color='primary' onClick={onhandleAddTeamActivityPlan}>
                        <Add />创建计划
                        </Button>
                    <Button variant='outlined' onClick={onhandleClose}><Close /> 取消</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};
CreateTeamActivityPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    onhandleAddTeamActivityPlan: PropTypes.func,
};

CreateTeamActivityPlan.defaultProps = {
    planName: '',
    month: 0,
    activityRules: [],
}

export default (withStyles(CreateTeamActivityPlan));