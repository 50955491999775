import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTeamActivityMaps } from '../../../redux/modules/safetyActivity';
// import * as _ from 'lodash';
import TeamActivityConfig from '@/components/LeadershipManagement/TeamActivity/TeamActivityConfig';


class TeamActivityConfigContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.props.onhandleGetTeamActivityMaps()
    }


    render() {
        return (
            <div>
                <TeamActivityConfig
                    maps={this.props.maps}
                    companies={this.props.companies}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maps: state.safetyActivity.teamActivityMaps,
        companies: state.constant.companies
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetTeamActivityMaps: () => {
            dispatch(getTeamActivityMaps())
        },

    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamActivityConfigContainer);