/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Typography,
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import {
    Add,
    Edit,
    DeleteOutline,
    Backup,
    Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import {
    // getMandatoryRegulationNameById,
    findBuNameById,
    // getNameByTypeAndCode
} from "../../../utils/constant";
import { getDateByTimestamp } from "../../../utils/datetime";
import { localization, options, style } from "../../../utils/mtable";

const Regulation = ({
    classes,
    onhandleOpenRegulationAddPannel,
    regulations,
    constant,
    search,
    onhandleSearch,
    onhandleClearSearch,
    onhandleSelect,
    onhandleChange,
    onhandleUpdate,
    onhandleDelete,
    onOpenUploadModal,
    onDownLoadDoc,
    roleW,
}) => {

    const columns = [
        {
            title: '编号',
            field: 'regNo',
            textAlign: 'right',
            render: row => <Typography>{row.regNo}</Typography>
        },
        {
            title: '制度名称',
            field: 'regName',
            align: 'left',
            render: row => <Typography>{row.regName}</Typography>

        },
        {
            title: '业务分类',
            field: 'unitId',
            align: 'center',
            width: 80,
            render: (row) => <Typography>{findBuNameById(constant, row.unitId)}</Typography>,
            editComponent: (props) => (
                <TextField
                    className={classes.textField}
                    select
                    value={props.rowData.unitId ? props.rowData.unitId : ''}
                    name='unitId'
                    onChange={e => props.onChange(e.target.value)}>
                    {constant.businessunits.map((bu) => (
                        <MenuItem key={bu.unitId} value={bu.unitId}>
                            {bu.unitName}
                        </MenuItem>
                    ))}
                </TextField>
            )
        },
        {
            title: '修订周期',
            field: 'revisionCycle',
            type: 'numeric',
            align: 'right',
            width: 80,
            render: (row) => <Typography>{row.revisionCycle}</Typography>
        },
        {
            title: '单位',
            field: 'revisionUnit',
            align: 'left',
            width: 70,
            lookup: {
                'YEAR': '年',
                'MONTH': '月',
                'WEEK': '周',
                'DAY': '日'
            }
        },
        // {
        //     title: '强制性规章制度',
        //     field: 'mandaReg',
        //     align: 'left',
        //     render: (row) => <Typography>{getMandatoryRegulationNameById(constant, row.mandaReg)}</Typography>,
        //     editComponent: (props) => (
        //         <TextField
        //             className={classes.textField}
        //             select
        //             value={props.rowData.mandaReg ? props.rowData.mandaReg : ''}
        //             name='unitId'
        //             onChange={e => props.onChange(e.target.value)}>
        //             {constant.mandatoryRegulations.map((mr) => (
        //                 <MenuItem key={mr.regId} value={mr.regId}>
        //                     {mr.regName}
        //                 </MenuItem>
        //             ))}
        //         </TextField>
        //     )
        // },
        {
            title: '上次修订时间',
            field: 'updateTime',
            render: (row) => <Typography>{getDateByTimestamp(row.updateTime)}</Typography>,
            align: 'left',
            type: 'date',
            editable: 'never',
            width: 90,
        }
    ]

    // 暂时隐藏：规章制度 - 强制规章制度功能
    // const detailColumns = [
    //     {
    //         title: '法律法规',
    //         field: 'miscName',
    //         align: 'left',
    //         render: row => <Typography>{row.miscName}</Typography>
    //     },
    //     {
    //         title: '发布时间',
    //         field: 'publishTime',
    //         render: (row) => <Typography>{getDateByTimestamp(row.publishTime)}</Typography>,
    //         align: 'left',
    //         type: 'date',
    //     },
    //     {
    //         title: '实施时间',
    //         field: 'publishTime',
    //         render: (row) => <Typography>{getDateByTimestamp(row.publishTime)}|</Typography>,
    //         align: 'left',
    //         type: 'date'
    //     }, {
    //         title: '机构',
    //         field: 'management',
    //         align: 'left',
    //         render: row => <Typography>{row.management}</Typography>

    //     }, {
    //         title: '范围',
    //         field: 'applyScope',
    //         align: 'center',
    //         render: (row) => <Typography>{getNameByTypeAndCode(constant.constants, "FLFG", row.applyScope)}</Typography>,
    //     }, {
    //         title: '类型',
    //         field: 'applyType',
    //         align: 'center',
    //         render: (row) => <Typography>{getNameByTypeAndCode(constant.constants, "FLFG", row.applyType)}</Typography>,
    //     }
    // ]
    // 暂时隐藏：规章制度 - 强制规章制度功能
    // const detailOptions = {
    //     search: false,
    //     padding: 'dense',
    //     paging: false,
    //     toolbar: false,
    //     showTitle: false,
    //     headerStyle: {
    //         textAlign: 'left'
    //     },
    // }

    // 暂时隐藏：规章制度 - 强制规章制度功能
    // const rendorDetailPanel = (rowData) => {
    //     if (rowData.mandaRegs) {
    //         return <div>
    //             <MaterialTable
    //                 title=''
    //                 columns={detailColumns}
    //                 data={rowData.mandaRegs.miscs}
    //                 options={detailOptions}
    //                 localization={localization}
    //                 style={{
    //                     border: '0px solid black',
    //                     boxShadow: 'none'
    //                 }}
    //             >
    //             </MaterialTable>
    //             <Typography className={classes.detailtips}>法规依据：{rowData.mandaRegs.legalBasis}</Typography>
    //         </div>
    //     } else {
    //         return null
    //     }
    // }

    const tableOptions = {
        ...options,
        showTitle: false,
        //filtering: true,
        toolbar: false,
        search: false
    }
    const tableStyle = {
        ...style
    }

    return (
        <Card className={classes.root}>
            <Grid container className={classes.title}>
                <Typography variant="h3">
                    {'公司规章制度'}
                </Typography>
            </Grid>
            {/* 整个搜索功能区域 */}
            <Grid container alignItems="center" className={classes.gridContainer}>
                {/* 搜索项 */}
                <Grid container item alignItems="center" sm={12} md={12} lg={5}>
                    <Grid container spacing={0} className={classes.gridContainer}>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={6}>
                            <Typography className={classes.label}>
                                业务分类
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                select
                                value={search.unitId || 0}
                                onChange={(event) => onhandleSelect(event, 'unitId')}
                                variant="outlined"
                            >
                                <MenuItem aria-label="None" value={0}>-</MenuItem>
                                {constant.businessunits.map((bu) => (
                                    <MenuItem key={bu.unitId} value={bu.unitId}>
                                        {bu.unitName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid container item className={classes.gridItem} sm={6} md={4} lg={6}>
                            <Typography className={classes.label}>
                                规章制度
                            </Typography>
                            <TextField
                                className={classes.textFieldInput}
                                size={'small'}
                                value={search.search || ''}
                                variant="outlined"
                                placeholder="制度名称、编号"
                                onChange={event => onhandleChange(event, 'search')}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                {/* 按钮区 */}
                <Grid container item className={classes.buttonGridContainer} alignItems="center" sm={12} md={12} lg={7}>
                    {/* 搜索区 */}
                    <Grid container item className={classes.gridItemSearchButtons} sm={6} md={6} lg={6}>
                        <Button variant="contained" color="primary"
                            onClick={onhandleSearch}
                        >
                            搜索
                        </Button>
                        <Button variant="outlined" style={{ marginLeft: '10px' }}
                            onClick={onhandleClearSearch}
                        >
                            重置
                        </Button>
                    </Grid>
                    {/* 表格工具条区 */}
                    <Grid container item className={classes.gridItemTableButtons} sm={6} md={6} lg={6}>
                        {roleW && <Button variant='contained' color='primary' startIcon={<Add />}
                            onClick={(event) => { onhandleOpenRegulationAddPannel() }}
                        >
                            新建
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.detailtable}>
                <MaterialTable
                    className={classes.materialtable}
                    title=''
                    columns={columns}
                    data={regulations.filter((r) => { return r.status === "active" })}
                    options={tableOptions}
                    editable={roleW ? {
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleUpdate(newData)
                                resolve()
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleDelete(oldData)
                                resolve()
                            }),
                    } : {}}
                    // detailPanel={[{
                    //     tooltip: '展开',
                    //     icon: 'account_icon',
                    //     render: rendorDetailPanel
                    // }]}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    localization={localization}
                    style={tableStyle}
                    icons={{
                        Edit: () => <Edit color={'primary'} />,
                        Delete: () => <DeleteOutline color={'error'} />
                    }}
                    actions={roleW ? [
                        rowData => ({
                            // icon: 'backup',
                            icon: () => <Backup color={'primary'} />,
                            tooltip: '上传制度',
                            onClick: (event, rowData) => {
                                onOpenUploadModal(rowData.regId, rowData.regName)
                            }
                        }),
                        rowData => ({
                            // icon: 'visibility',
                            icon: () => <Visibility color={'primary'} />,
                            tooltip: '预览',
                            hidden: !rowData.versions || rowData.versions.length === 0,
                            onClick: (event, rowData) => {
                                onDownLoadDoc(rowData.regId)
                            }
                        }),
                        // {
                        //     icon: 'add',
                        //     tooltip: '新增规章制度',
                        //     isFreeAction: true,
                        //     onClick: (event) => { onhandleOpenRegulationAddPannel() }
                        // }
                    ] : [rowData => ({
                        icon: () => <Visibility color={'primary'} />,
                        tooltip: '预览',
                        hidden: !rowData.versions || rowData.versions.length === 0,
                        onClick: (event, rowData) => {
                            onDownLoadDoc(rowData.regId)
                        }
                    }),]}
                >
                </MaterialTable>

            </Grid>
        </Card>

    );
};
Regulation.propTypes = {
    classes: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    currentUserInfo: PropTypes.object.isRequired,
    businessUnits: PropTypes.array.isRequired,
    regulations: PropTypes.array.isRequired,
    onhandleOpenRegulationAddPannel: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    onOpenUploadModal: PropTypes.func.isRequired,
    onDownLoadDoc: PropTypes.func.isRequired,
};

Regulation.defaultProps = {
    regulations: [],
    currentUserInfo: {},
    userProfile: {},
    constant: {},
    departments: [],
    businessUnits: [],
}

export default withStyles(Regulation);