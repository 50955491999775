import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AcctypeSelectPage from '../components/AcctypeSelectPage';
import {
  initAccessoryTypeSelectList,
  exitSelected,
  selectAccCategory
} from '../redux/modules/acctypeSelect';
import { cleanmodal } from '../redux/modules/modal';

class AccessorySelectPageContainer extends Component {
  static propTypes = {
    accessoryList: PropTypes.array,
    parameters: PropTypes.object
  }

  componentWillMount() {
    this.props.onhandleInitAccessoryTypeSelectList();
  }

  handleConfirm = () => {
    if (this.props.parameters.callback) {
      this.props.parameters.callback()
        .then(() => {
          // console.log('Promise sucess')
          this.props.onhandleCleanmodal()
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      this.props.onhandleCleanmodal()
    }
  };

  handleSelectAccCategory = (event, value) => {
    this.props.onhandleSelectAccCategory(value);
  };


  render() {
    return (
      <AcctypeSelectPage
        accCatList={this.props.accCatList}
        onhandleExitSelected={this.props.onhandleExitSelected.bind(this)}
        onhandleSelectAccCategory={this.handleSelectAccCategory.bind(this)}
        onhandleConfirm={this.handleConfirm.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parameters: state.modal.parameters,
    accCatList: state.acctypeSelect.accShowList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onhandleInitAccessoryTypeSelectList: () => {
      dispatch(initAccessoryTypeSelectList());
    },
    onhandleSelectAccCategory: (value) => {
      dispatch(selectAccCategory(value))
    },
    onhandleExitSelected: () => {
      dispatch(exitSelected());
    },
    onhandleCleanmodal: () => {
      dispatch(cleanmodal());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessorySelectPageContainer);
