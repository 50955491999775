import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegulationPlanCurrentYear from "../../../components/LeadershipManagement/RegulationPlanCurrentYear";
import {
    getPlanDetails,
    getExecutionPlan,
} from "../../../redux/modules/leadershipRegulationPlan";
import { getBusinessUnitsByRole } from "../../../utils/userUtils";

class RegulationPlanCurrentYearContainer extends Component {
    static propTypes = {
        plan: PropTypes.object,
    };

    componentDidMount() {
        const { 
            //onhandleGetCurrentPlan,
            onhandleGetPlanDetails
        } = this.props
        onhandleGetPlanDetails('', '3', (data) => {
            console.log(data)
        })
    }

    render() {
        return (
            <div>
                <RegulationPlanCurrentYear
                    businessUnits={this.props.businessUnits}
                    constant={this.props.constant}
                    currentPlanDetails={this.props.planDetails}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        plan: state.leadershipRegulationPlan.executionPlan,
        planDetails: state.leadershipRegulationPlan.planDetails,
        constant: state.constant,
        businessUnits: getBusinessUnitsByRole(state.auth.currentUserInfo.roleId, state.auth.userProfile),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onhandleGetCurrentPlan: (callback) => {
            dispatch(getExecutionPlan(callback))
        },
        onhandleGetPlanDetails: (status,type,callback) => {
            dispatch(getPlanDetails(status,type,callback))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulationPlanCurrentYearContainer)