import { connect } from 'react-redux';
import TopBar from '../components/TopBar';
import { fullScreenSetting, exitFullScreenSetting, updGridSize, setScale, updateUnitView } from '../redux/modules/setting';
import { addStoreySelect } from '../redux/modules/installationPlug';
import { createInstallationStoreys } from '../../../redux/modules/installation';
import { createMessage } from '../../../redux/modules/message';
import { cleanContentMenu } from '../redux/modules/contentmenu';
import { saveMap, updAutoSave, updMapChanged } from '../redux/modules/installationRelation';
import { initDeviceTreeData } from '../redux/modules/deviceTree';
const mapStateToProps = (state) => {
    return {
        ...state.setting,
        ...state.installation,
        ...state.installationPlug,
        ...state.installationRelation
    }
}

const mapDispathToProps = (dispath) => {
    return {
        fullScreenSetting: () => {
            dispath(fullScreenSetting())
        },
        exitFullScreenSetting: () => {
            dispath(exitFullScreenSetting())
        },
        updGridSize: (value) => {
            dispath(updGridSize(value))
        },
        setScale: (value) => {
            dispath(setScale(value))
        },
        addStoreySelect: (value) => {
            dispath(addStoreySelect(value))
        },
        updateUnitView: () => {
            dispath(updateUnitView())
        },
        createInstallationStoreys: (id, name, datum) => {
            dispath(createInstallationStoreys(id, name, datum))
        },
        createMessage: (type, message) => {
            dispath(createMessage(type, message))
        },
        saveMap: (data) => {
            dispath(saveMap(data))
        },
        cleanContentMenu: () => {
            dispath(cleanContentMenu())
        },
        initDeviceTreeData: () => {
            dispath(initDeviceTreeData())
        },
        updAutoSave: (data) => {
            dispath(updAutoSave(data))
        },
        updMapChanged: (data) => {
            dispath(updMapChanged(data))
        },
    }
}

export default connect(mapStateToProps, mapDispathToProps)(TopBar);