import React from 'react';
import withStyles from './styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
    Tooltip,
    IconButton
} from '@material-ui/core';
import {
    Backup,
    SaveAlt,
} from '@material-ui/icons';
import { localization, options, style } from "@/utils/mtable";
import MaterialTable from 'material-table';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';


const VariablePlanComponent = ({
    classes,
    planDetails,
    selectedDate,
    onhandleUpdate,
    onhandleDateChange,
    dptId,
    onhandleDptChange,
    dpts,
    roleFlag,
    onhandleExport,
    onhandleImport,
}) => {

    const tableOptions = {
        ...options,
        toolbar: false,
        paging: false,
        fixedColumns: {
            left: 1,
        },
    }
    const tableLocalization = {
        ...localization,
    }

    const tableStyle = {
        ...style
    }

    const assemblycolumns = () => {
        const columns = []
        let column = {
            title: '指标名称',
            field: 'subName',
            width: 110,
            render: row => <Typography>{row.subName + '（万元）'}</Typography>,
            editable: 'never',
        }
        columns.push(column);
        const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        for (let index = 0; index < 12; index++) {
            let column = {
                // title: <Link color="primary" underline="always" target="_blank" component="button" variant="body2" display="inline" onClick={event => onhandleEditTittleFixedplan(event, index)}>{months[index]}</Link>,
                title: months[index],
                field: 'amount_' + (index + 1),
                width: 85,
                align: 'right',
                type: 'numeric',
                render: row => row['amount_' + Number(index + 1)] ? row['amount_' + Number(index + 1)].toFixed(2) : 0,
            }
            columns.push(column)
        }
        return columns
    }

    const assemblyData = () => {
        console.log(planDetails)

        return planDetails;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container direction="row"
                    justify="space-between" className={classes.gridContainer}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                views={["year"]}
                                variant="inline"
                                format="yyyy"
                                id="date-selector"
                                margin="normal"
                                value={selectedDate}
                                onChange={onhandleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid className={classes.flexgrow}></Grid>
                    <Grid item >
                        <TextField
                            fullWidth
                            select
                            name='dptId'
                            InputProps={textFieldDefault}
                            onChange={onhandleDptChange}
                            value={dptId || ''}
                        >
                            {dpts.map(ele => (
                                <MenuItem key={ele.dptId} value={ele.dptId}>{ele.dptName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item >
                        {roleFlag && <>
                            <Tooltip title={'导出模板'} placement="top">
                                <IconButton onClick={() => { onhandleExport() }}>
                                    <SaveAlt />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'导入独立费用计划'} placement="top">
                                <IconButton onClick={() => { onhandleImport() }}>
                                    <Backup />
                                </IconButton>
                            </Tooltip>
                        </>}

                    </Grid>
                </Grid>
                <Grid className={classes.detailtable}>
                    <MaterialTable
                        className={classes.materialtable}
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        title={<Typography variant='h3'>变动费计划列表</Typography>}
                        style={tableStyle}
                        editable={{
                            isEditHidden: () => !roleFlag,
                            onRowUpdate: (newData, oldData) => onhandleUpdate(newData, oldData)
                        }}
                        localization={tableLocalization}
                        parentChildData={(row, rows) => rows.find(ele => ele.subId === row.subParentId)}
                    >
                    </MaterialTable>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        </div >

    );
};
VariablePlanComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    goalPlan: PropTypes.object,
    year: PropTypes.number,
    businessUnits: PropTypes.array,
    currentProcessStatus: PropTypes.object,
    goalDetails: PropTypes.array,
    onhandleEdit: PropTypes.func,
    onhandleUpdate: PropTypes.func,
    onhandleExport: PropTypes.func,
    onhandleImport: PropTypes.func,
    onhandleEditFixedPlan: PropTypes.func,
};

VariablePlanComponent.defaultProps = {
    planDetails: [],
    selectedDate: {},
    dptId: 0,
    dpts: [],
    roleFlag: false,
}

export default withStyles(VariablePlanComponent);