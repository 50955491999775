import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnnualAmoebaReportComponent from '@/components/AmoebaManagement/Report/AnnualAmoebaReport';
import ReportTrendsContainer from './ReportTrendsContainer';
import PropTypes from 'prop-types';
import { getMoment, formatYear, formatDay } from '@/utils/datetime';
import { getAmoebaReports } from '@/redux/modules/amoeba/amoebaReport';
import { getAmoebaIndexes } from '@/redux/modules/amoeba/amoebaIndex';
import { getBudgets } from '@/redux/modules/amoeba/amoebaPlan';
import { getDepartmentsByRole } from '@/utils/userUtils';
import { openTrends, getTrends } from '@/redux/modules/amoeba/reportTrend';
import { filterAmoebaCompany } from "@/utils/constant";

import * as _ from 'lodash'

class AnnualAmoebaReportContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            innerWidth: window.innerWidth,
            selectedCompanyId: 0,
            companies: [],
            sortSubs: [],
            dpts: [],
            pid: null,
            dptIds: '',
            selectedDate: getMoment()(),
            roleFlag: this.props.roleRoutes.find(ele => ele.route === 'amoeba_report').action === 'W',
            planDisplay: false,
        }
    }

    getReportViewType(companyId) {
        const roleFlag = this.props.roleRoutes.find(ele => ele.route === 'amoeba_report').action === 'W'
        if (roleFlag) {
            if (companyId) {
                return 'COMPANY'
            } else {
                return 'ALLCOMPANY'
            }
        } else {
            if (this.getPid()
                && !this.props.userDepartment.executable) {
                return 'COMPANY'
            } else {
                return 'DEPARTMENT'
            }
        }
    }

    getDpts(date, companyId) {
        let dpts = [];
        if (!date) {
            date = getMoment()();
        }
        if (!companyId) {
            companyId = this.props.currentUserInfo.companyId;
        }
        const startDay = getMoment()(date).startOf('year').format("YYYY/MM/DD");
        const endDay = getMoment()(date).endOf('year').format("YYYY/MM/DD");
        dpts = _.filter(this.props.departments, d => {
            return d.companyId === companyId
                && d.amoebaType !== 'OTHER'
        })

        //添加部门inactive的判断
        dpts = _.filter(dpts, d => {
            return (d.status === 'active' && (!d.startDate || formatDay(d.startDate) <= endDay)) ||
                (!d.endDate || (formatDay(d.startDate) <= endDay && formatDay(d.endDate) >= startDay))
        })
        let stateDpts = _.cloneDeep(dpts);
        stateDpts.unshift({
            dptId: 0,
            dptName: _.find(this.props.companies, { 'companyId': companyId }).companyName,
            companyId: this.getPid() ? companyId : 0
        })

        this.setState({
            dpts: stateDpts
        })

        return dpts;

    }

    // 页面初期化获取数据
    componentDidMount() {
        const { currentUserInfo, userDepartment, companies,
            getAmoebaReports, onhandleGetBudgets } = this.props
        const date = getMoment()();
        const day = formatYear(date);
        const pid = this.getPid();
        const viewType = this.getReportViewType(0)
        let dpts = this.getDpts(null, null);
        const dptIds = _.map(dpts, 'dptId').join(',')
        if (viewType === 'COMPANY' || viewType === 'DEPARTMENT') {
            this.setState({ companies: [], selectedCompanyId: currentUserInfo.companyId });
            onhandleGetBudgets(currentUserInfo.companyId, viewType === 'COMPANY' ? null : userDepartment.dptId, date.year())
            getAmoebaReports(day, currentUserInfo.companyId, dptIds).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        } else {
            const findCompanies = _.cloneDeep(companies)
            const cpy = { companyId: 0, companyName: "工程公司", companyAlias: "工程公司" };
            findCompanies.unshift(cpy);
            this.setState({ companies: findCompanies, selectedCompanyId: 0 });
            onhandleGetBudgets(0, null, date.year())
            this.props.getAmoebaReports(day, null, null).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
            })
        }
        this.setState({
            pid: pid,
            dptIds: dptIds,
        })
    }

    getSortAmoebaIndexes(length) {
        if (length === 0) {
            this.setState({
                sortSubs: [],
            })
        } else {
            this.props.getAmoebaIndexes().then(res => {
                let sortSubs = [];
                const parentSubs = _.filter(res.payload, { 'subParentId': 0 });
                _.forEach(parentSubs, value => {
                    sortSubs.push(value)
                    const subSubs = _.filter(res.payload, { 'subParentId': value.subId });
                    subSubs.forEach(sub => {
                        sortSubs.push(sub)
                        const subs = _.filter(res.payload, { 'subParentId': sub.subId });
                        if (subs.length !== 0) sortSubs = sortSubs.concat(subs)
                    })
                })
                this.setState({
                    sortSubs: sortSubs,
                })
            })
        }
    }


    getPid() {
        const { companies, currentUserInfo } = this.props;
        const company = _.find(companies, item => {
            return item.companyId === currentUserInfo.companyId
        });
        return company.pid;
    }

    handlePlanDisplay(event) {
        this.setState({ planDisplay: event.target.checked })
    }

    handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = _.find(this.props.companies, { 'companyId': companyId })
        this.refreshReport({
            selectedCompanyId: companyId,
            pid: company ? company.pid : 0,
        })
    }


    handleDateChange = (date) => {
        this.refreshReport({
            selectedDate: getMoment()(date)
        })
    }

    refreshReport = (changeState) => {

        const { userDepartment, onhandleGetBudgets, getAmoebaReports } = this.props

        let newState = {
            ...this.state
            , ...changeState
        }
        const viewType = this.getReportViewType(newState.selectedCompanyId)

        if (viewType === 'COMPANY' || viewType === 'DEPAERMENT') {
            let dpts = this.getDpts(newState.selectedDate, newState.selectedCompanyId);
            delete newState.dpts;
            let dptIds = _.map(dpts, 'dptId').join(',')
            onhandleGetBudgets(newState.selectedCompanyId, viewType === 'COMPANY' ? null : userDepartment.dptId, newState.selectedDate.year())
            getAmoebaReports(formatYear(newState.selectedDate), newState.selectedCompanyId, dptIds).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
                this.setState({
                    ...newState,
                    dpts: this.state.dpts,
                })
            })
        }
        else {
            onhandleGetBudgets(0, null, newState.selectedDate.year())
            getAmoebaReports(formatYear(newState.selectedDate), null, null).then(res => {
                this.getSortAmoebaIndexes(res.payload.length)
                this.setState({
                    ...newState,
                })
            })
        }
    }

    handleOpenTrends = (row) => {
        let query = {
            timeDim: 'YEAR',
            startDate: getMoment()(this.state.selectedDate).startOf('year').format('YYYY-MM-DD'),
            endDate: getMoment()(this.state.selectedDate).format('YYYY-MM-DD'),
            subIds: row.subId
        }

        query.orgDim = this.getReportViewType(this.state.selectedCompanyId)

        if (query.orgDim === "COMPANY") {
            query.companyIds = this.state.selectedCompanyId || this.props.userProfile.companyId
        }
        if (query.orgDim === "DEPARTMENT") {
            query.dptIds = this.props.userDepartment.dptId || 0
        }
        const showDpts = _.map(this.state.dpts, 'dptId');
        this.props.onhandleGetTrends(query.orgDim, query.companyIds, query.dptIds, query.subIds, query.timeDim, query.startDate, query.endDate, showDpts);

        const trendInfo = {
            ...query,
            subInfo: row
        }
        this.props.onhandleOpenTrends(trendInfo)
    }

    handleOpenTrends = (row) => {
        let query = {
            timeDim: 'YEAR',
            startDate: getMoment()(2020, 'YYYY').startOf('year').format('YYYY-MM-DD'),
            endDate: this.state.selectedDate.format('YYYY-MM-DD'),
            subIds: row.subId
        }

        query.orgDim = this.getReportViewType(this.state.selectedCompanyId)

        if (query.orgDim === "COMPANY") {
            query.companyIds = this.state.selectedCompanyId || this.props.userProfile.companyId
        }
        if (query.orgDim === "DEPARTMENT") {
            query.dptIds = this.props.userDepartment.dptId || 0
        }
        const showDpts = _.map(this.state.dpts, 'dptId');
        this.props.onhandleGetTrends(query.orgDim, query.companyIds, query.dptIds, query.subIds, query.timeDim, query.startDate, query.endDate, showDpts);

        const trendInfo = {
            ...query,
            subInfo: row
        }
        this.props.onhandleOpenTrends(trendInfo)
    }

    render() {
        return (<>
            <AnnualAmoebaReportComponent
                selectedDate={this.state.selectedDate}
                companies={this.state.companies}
                sortSubs={this.state.sortSubs}
                selectedCompanyId={this.state.selectedCompanyId}
                reports={this.props.reports}
                dpts={this.state.dpts}
                viewType={this.getReportViewType(this.state.selectedCompanyId)}
                pid={this.state.pid}
                roleFlag={this.state.roleFlag}
                budgets={this.props.budgets}
                constant={this.props.constant}
                userDepartment={this.props.userDepartment}
                planDisplay={this.state.planDisplay}
                innerWidth={this.state.innerWidth}
                onhandlePlanDisplay={this.handlePlanDisplay.bind(this)}
                onhandleDateChange={this.handleDateChange.bind(this)}
                onhandleCompanyChange={this.handleCompanyChange.bind(this)}
                onhandleOpenTrends={this.handleOpenTrends.bind(this)}
            />
            <ReportTrendsContainer />
        </>);
    }
}

const getUserDpt = (roleId, userProfile, departments) => {
    const userDpts = getDepartmentsByRole(roleId, userProfile)
    if (userDpts.length > 0 && departments && departments.length > 0) {
        return _.find(departments, { dptId: userDpts[0].dptId })
    } else {
        return {}
    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.amoebaReport.reports,
        constant: state.constant,
        userDepartment: getUserDpt(state.auth.currentUserInfo.roleId, state.auth.userProfile, state.department.departments.list),
        currentUserInfo: state.auth.currentUserInfo,
        companies: filterAmoebaCompany(state.company.companyList.list),
        departments: state.department.allDepartments.list,
        roleRoutes: getRole(state.auth.currentUserInfo.roleId, state.auth.userProfile).routes,
        budgets: state.amoebaPlan.budgets,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmoebaIndexes: () => {
            return dispatch(getAmoebaIndexes(null, null, null, null));
        },
        getAmoebaReports: (day, companyIds, dptIds) => {
            return dispatch(getAmoebaReports(null, day, null, null, null, companyIds, dptIds, null));
        },
        onhandleGetBudgets: (companyId, dptId, year) => {
            return dispatch(getBudgets(companyId, dptId, year, null, null, 'ANNU_REPORT'))
        },
        onhandleOpenTrends: (trendInfo) => {
            return dispatch(openTrends(trendInfo));
        },
        onhandleGetTrends: (orgDim, companyId, dptIds, subIds, timeDim, startDate, endDate, showDpts) => {
            return dispatch(getTrends(orgDim, companyId, dptIds, subIds, timeDim, startDate, endDate, showDpts));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnnualAmoebaReportContainer);

export const getRole = (roleId, userProfile) => {

    if (!userProfile) {
        return {}
    }
    for (let role of userProfile.roles) {
        if (role.roleId === roleId)
            return role
    }
    return {}
}