import React, { Component } from 'react';
import { connect } from 'react-redux';
import InstallationList from "../../components/InstallationManagement/InstallationList/InstallationList";
import PropTypes from "prop-types";
import { getTreePartById } from '../../utils/constant';
import { getCompanyId } from "../../utils/userUtils";
import { routeToInstallationInfo, routeToInstallationMapView, setPageSize, getInstallationList } from '../../redux/modules/installation';
class InstallationListContainer extends Component {
    static propTypes = {
        initListData: PropTypes.object,
        userProfile: PropTypes.object,
        pageSize: PropTypes.number,
        companyTree: PropTypes.array,
        companyList: PropTypes.array,
        currentUserInfo: PropTypes.object,
    };

    constructor(props) {
        super(props)
        this.state = {
            companyId: null
        }
    }

    componentDidMount() {
        let query = {};
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile);
        let tree = getTreePartById(companyId, this.props.companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            query.companyId = companyId
            this.setState({ companyId: companyId })
        }
        this.props.onGetInstallationList(query);
    }

    detailedInfo(event, instId) {
        this.props.onhandleOpen(instId);
    }

    detailedMap(event, instId) {
        this.props.onhandleMapViewOpen(instId);
    }

    detailCreate(event) {
        if (this.props.onhandleOpen) {
            this.props.onhandleOpen('create')
        }
    }

    render() {
        return (
            <InstallationList
                initListData={this.props.initListData}
                onDetailedInfo={this.detailedInfo.bind(this)}
                onDetailedCreate={this.detailCreate.bind(this)}
                onDetailedMap={this.detailedMap.bind(this)}
                userProfile={this.props.userProfile}
                pageSize={this.props.pageSize}
                setPageSize={this.props.onSetPageSize.bind(this)}
                getInstallationList={this.props.onGetInstallationList.bind(this)}
                companyId={this.state.companyId}
                companyList={this.props.companyList}
            />
        )
    }
}

const mapStateToProps = (state) => {

    return {
        initListData: state.installation.initListData,
        userProfile: state.auth.userProfile,
        pageSize: state.installation.pageSize,
        currentUserInfo: state.auth.currentUserInfo,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleOpen: (instId) => {
            dispatch(routeToInstallationInfo(instId));
        },
        onhandleMapViewOpen: (instId) => {
            dispatch(routeToInstallationMapView(instId));
        },
        onGetInstallationList: (query) => {
            dispatch(getInstallationList(query));
        },
        onSetPageSize: (num) => {
            dispatch(setPageSize(num))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationListContainer)
