import Konva from 'konva';
import { getStore } from '../../../../../redux/store';
import KonvaUtil from "../util";
import ActionUtil from '../actionUtil';
import { cleanContentMenu, createContentMenu } from '../../../redux/modules/contentmenu';
import { createMessage } from '../../../../../redux/modules/message';
const konvaUtil = new KonvaUtil();
const actionUtil = new ActionUtil();


export function init(props, bx, by, id, name, stage, previewStage) {
    let layer = stage.findOne('.workLayer');
    const state = {
        color: "black",
        width: 100,
        height: 0,
        strokeWidth: 1
    };
    // 生成单元对象
    const group = new Konva.Group({
        x: bx,
        y: by,
        draggable: true,
        id: id,
        zIndex: 1,
        name: "Unit",
    });
    // 生成单元隐藏背景
    const unit = new Konva.Rect({
        x: 0,
        y: 0,
        width: 500,
        height: 200,
        stroke: state.color,
        strokeWidth: state.strokeWidth
    });
    group.add(unit);

    // 单元名称
    const text = new Konva.Text({
        text: name,
        x: 20,
        y: 10,
        fill: state.color
    })
    group.add(text);
    groupCommon(props, group, layer, stage, previewStage);

    layer.add(group);
    layer.batchDraw();
}

export function mapping(props, group, layer, stage, previewStage) {
    groupCommon(props, group, layer, stage, previewStage);
}

function groupCommon(props, group, layer, stage, previewStage) {
    const store = getStore();
    let prePos = {};
    let preTransformParam = { scaleX: 0, scaleY: 0, rotation: 0 };
    group.moveToBottom();
    layer.batchDraw();

    group.on('transformend', function (e) {
        console.log('transform end');
        let units = layer.find('.Unit');
        var intersectionFlg = false;
        if (units && units.length > 0) {
            units.forEach(unit => {
                if (unit.id() !== group.id()) {
                    if (konvaUtil.haveIntersection(unit.getClientRect(), group.getClientRect())) {
                        intersectionFlg = true;
                    }
                }
            })
        }
        if (intersectionFlg) {
            store.dispatch(createMessage('warning', '装置单元不能交叉！'));
            group.position(prePos);
            group.scaleX(preTransformParam.scaleX);
            group.scaleY(preTransformParam.scaleY);
            group.rotation(preTransformParam.rotation);
            layer.batchDraw();
        } else {
            konvaUtil.updatePreview(props, layer, previewStage);
            actionUtil.caculateUnitIntersectionWithDevice(group, layer);
            actionUtil.markChange(group.id());
        }
    });

    group.on('transformstart', function (e) {
        console.log('transform start');
        store.dispatch(cleanContentMenu());
        prePos = group.position()
        preTransformParam.scaleX = group.scaleX();
        preTransformParam.scaleY = group.scaleY();
        preTransformParam.rotation = group.rotation();
    });

    group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
        // 拖拽开始，组件变红色
        group.find('Rect').forEach(element => {
            element.stroke('red');
        });
        group.find('Text').forEach(element => {
            element.fill('red');
        });
        layer.draw();
    })

    group.on('mouseout', function () {
        document.body.style.cursor = 'default';
        // 拖拽结束，组件恢复黑色
        group.find('Rect').forEach(element => {
            element.stroke('black');
        });
        group.find('Text').forEach(element => {
            element.fill('black');
        });
        layer.draw();
    })

    group.on('dragstart', function () {
        store.dispatch(cleanContentMenu());
        prePos = group.position()
    })

    group.on('dragend', function () {
        let units = layer.find('.Unit');
        var intersectionFlg = false;
        if (units && units.length > 0) {
            units.forEach(unit => {
                if (unit.id() !== group.id()) {
                    if (konvaUtil.haveIntersection(unit.getClientRect(), group.getClientRect())) {
                        intersectionFlg = true;
                    }
                }
            })
        }
        if (intersectionFlg) {
            store.dispatch(createMessage('warning', '装置单元不能交叉！'));
            group.position(prePos);
            layer.batchDraw();
        } else {
            konvaUtil.updatePreview(props, layer, previewStage);
            actionUtil.caculateUnitIntersectionWithDevice(group, layer);
            actionUtil.markChange(group.id());
        }
    })

    group.on('contextmenu', function (e) {
        e.evt.preventDefault();
        let containerRect = stage.container().getBoundingClientRect();
        let top = containerRect.top + group.getClientRect().y;
        let left = containerRect.left + group.getClientRect().x + group.getClientRect().width;
        store.dispatch(createContentMenu(top, left, { id: group.id(), x: group.x(), y: group.y() }));
    });
}

