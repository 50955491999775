import React, { useState } from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    // Checkbox,
    // ListItemText,
    MenuItem,
    Typography,
    Tooltip,
    IconButton
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import { Table } from 'antd';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import * as _ from 'lodash';

const MTempPlanDetailComponent = props => {
    const {
        classes,
        currentUserInfo,
        // departments,
        companies,
        openFlag,
        actionType,
        plan,
        planDtls,
        services,
        onhandleClose,
        onhandleSave,
        onhandleChange,
        onhandlePlanDateChange,
        onhandlePlanDtlChange,
    } = props;



    const [editingRow, setEditingRow] = useState({})

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon fontSize='small' /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon fontSize='small' /></Tooltip>
        }
        return ''
    }

    // const renderDpts = (row) => {
    //     if (row.dpts && row.dpts.length > 0) {
    //         return _.chain(departments)
    //             .filter(d => { return _.find(row.dpts, { 'dptId': d.dptId }) })
    //             .map('dptName')
    //             .join(',')
    //             .value()
    //     } else {
    //         return ''
    //     }
    // }

    // const renderEditDpts = (dptIds) => {
    //     if (dptIds && dptIds.length > 0) {
    //         return _.chain(departments)
    //             .filter(d => { return _.indexOf(dptIds, d.dptId) > -1 })
    //             .map('dptName')
    //             .join(',')
    //             .value()
    //     } else {
    //         return ''
    //     }
    // }

    // const dptSelect = () => {
    //     if (editingRow.serviceId) {
    //         const service = _.find(services, { 'serviceId': editingRow.serviceId })
    //         if (service.serviceDpts && service.serviceDpts.length > 0) {
    //             return _.chain(departments)
    //                 .filter(d => { return _.find(service.serviceDpts, { 'dptId': d.dptId }) })
    //                 .value()
    //         }
    //     }
    //     return _.chain(departments).filter({ 'companyId': currentUserInfo.companyId })
    //         .value()
    // }

    const getCompanySelect = () => {
        const myCompany = _.find(companies, { 'companyId': currentUserInfo.companyId })
        if (!myCompany.pid) { //可选子公司
            return _.filter(companies, { 'pid': myCompany.companyId })
        }
        return [myCompany]
    }

    const editRow = (row) => {
        row.editing = true
        row.dptIds = _.map(row.dpts, 'dptId')
        onhandlePlanDtlChange(row, 'update')
        setEditingRow(row)
    }

    const addRow = () => {
        const maxIdDtl = _.maxBy(planDtls, 'id')
        let row = {
            id: maxIdDtl ? (maxIdDtl.id + 1) : 1,
            dpts: [],
            serviceId: 0,
            editing: true,
        }
        onhandlePlanDtlChange(row, 'add')
        setEditingRow(row)
    }


    const updateRow = (event) => {
        let dtl = { ...editingRow }
        if (event.target.name === 'output') {
            dtl['output'] = Number(event.target.value)
        } else if (event.target.name === 'serviceId') {
            const service = services.find(item => {
                return item.serviceId === Number(event.target.value)
            })
            dtl['serviceDpts'] = service.serviceDpts
            dtl['serviceId'] = service.serviceId;
            dtl['dtlName'] = service.serviceName;
            dtl['outputUnit'] = service.unit;
            dtl['dptIds'] = []
        } else if (event.target.name === 'dptIds') {

            dtl['dptIds'] = event.target.value

        } else {
            dtl[event.target.name] = event.target.value
        }
        setEditingRow(dtl)
    }

    const saveEditRow = () => {
        let newDtl = {
            ...editingRow,
            dpts: _.map(editingRow.dptIds, dptId => {
                return {
                    'dptId': dptId,
                }
            }),
            editing: false
        }
        onhandlePlanDtlChange(newDtl, 'update')
        setEditingRow({})
    }

    const cancelEditRow = (row) => {
        if (!row.dtl && !row.serviceId) {
            onhandlePlanDtlChange(row, 'delete')
        } else {
            let newDtl = { ...row, editing: false }
            onhandlePlanDtlChange(newDtl, 'update')
        }
        setEditingRow({})
    }

    const deleteRow = (row) => {
        onhandlePlanDtlChange(row, 'delete')
        setEditingRow({})
    }

    const assemblyColumns = () => {
        return [
            {
                title: '服务',
                dataIndex: 'dtlName',
                key: 'dtlName',
                render: (text, row, index) => row.editing ? <TextField
                    select
                    size='small'
                    name='serviceId'
                    variant="outlined"
                    error={editingRow.serviceId ? false : true}
                    value={editingRow.serviceId || ''}
                    onChange={(event) => updateRow(event)}>
                    {services && services.map((i) => (
                        <MenuItem key={i.serviceId} value={i.serviceId}>
                            {i.serviceName}{renderServiceType(i.serviceType)}
                        </MenuItem>
                    ))}
                </TextField> : <Typography>{row.dtlName}</Typography>,
            },
            // {
            //     title: '班组',
            //     dataIndex: 'dpts',
            //     key: 'dpts',
            //     type: 'string',
            //     render: (text, row, index) => row.editing ?
            //         <TextField
            //             select
            //             SelectProps={{
            //                 multiple: true,
            //                 renderValue: () => renderEditDpts(editingRow.dptIds)
            //             }}
            //             fullWidth
            //             size='small'
            //             name='dptIds'
            //             variant="outlined"
            //             value={editingRow.dptIds || []}
            //             onChange={(event) => updateRow(event)}
            //         >
            //             {dptSelect() && dptSelect().map((i) => (
            //                 <MenuItem key={i.dptId} value={i.dptId}>
            //                     <Checkbox className={classes.checkbox}
            //                         size={'small'}
            //                         checked={editingRow.dptIds && _.indexOf(editingRow.dptIds, i.dptId) > -1} />
            //                     <ListItemText primary={i.dptName} />
            //                 </MenuItem>
            //             ))}
            //         </TextField> : <Typography>{renderDpts(row)}</Typography>,
            // },
            {
                title: '数量',
                dataIndex: 'output',
                key: 'output',
                type: 'numeric',
                render: (text, row, index) => row.editing ? <Grid item className={classes.itemGrid}>
                    <TextField
                        size='small'
                        name='output'
                        variant="outlined"
                        onChange={(event) => updateRow(event)}
                        value={editingRow.output || ''}
                    />
                </Grid> : <Typography>{row.output}</Typography>,
            },
            {
                title: '单位',
                dataIndex: 'outputUnit',
                key: 'outputUnit',
                render: (text, row, index) => <Typography>{row.editing ? editingRow.outputUnit : row.outputUnit}</Typography>,
            },
            {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                width: '90px',
                render: (text, row, index) => renderActions(row),
            },]
    }

    const renderActions = (row) => {
        if (isEditing()) {
            if (editingRow.id === row.id) {
                return <Grid container alignItems='center'>
                    <Grid item><Tooltip title='保存'><IconButton variant='text' onClick={() => saveEditRow(row)}> <SaveIcon fontSize='small' /></IconButton></Tooltip></Grid>
                    <Grid item><Tooltip title='取消'><IconButton variant='text' onClick={() => cancelEditRow(row)}> <CancelIcon fontSize='small' /></IconButton></Tooltip></Grid>
                </Grid>
            } else {
                return <Grid container alignItems='center'></Grid>
            }
        } else {
            return <Grid container alignItems='center'>
                <Grid item><Tooltip title='修改'><IconButton variant='text' onClick={() => editRow(row)}> <EditIcon fontSize='small' /></IconButton></Tooltip></Grid>
                <Grid item><Tooltip title='删除'><IconButton variant='text' onClick={() => deleteRow(row)}> <DeleteIcon fontSize='small' /></IconButton></Tooltip></Grid>
            </Grid>
        }
    }

    const isEditing = () => {
        let editingRow = _.find(planDtls, { 'editing': true })
        return editingRow ? true : false
    }



    // const assemblyEditColumns = () => {
    //     const columns = assemblyColumns()
    //     return _.map(columns, col => {
    //         return {
    //             ...col,
    //             render: col.editable !== 'never' && col.editing ? col.editComponent : col.render
    //         }
    //     })
    // }


    return (
        <div alignitems="center" className={classes.root}>
            <Dialog
                // maxWidth={planDtlEditing ? 'md' : 'sm'}
                open={openFlag}
                onClose={onhandleClose}>
                <DialogTitle>
                    <Typography component={'span'} variant='h3'>{actionType === 'CREATE' ? '创建临时计划' : '临时计划详情'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2} >
                        <Grid container item xs={12} direction="column">
                            <Grid item container className={classes.planHeader} spacing={1}>
                                <Grid item className={classes.planHeaderItem} xs={3} >
                                    <TextField
                                        select
                                        fullWidth
                                        label='分厂'
                                        name='companyId'
                                        variant="outlined"
                                        value={plan.companyId}
                                        onChange={onhandleChange}
                                        inputProps={{
                                            readOnly: getCompanySelect().length > 1 ? false : true,
                                        }}>
                                        {getCompanySelect().map(c => (
                                            <MenuItem key={c.companyId} value={c.companyId}>{c.companyAlias}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.planHeaderItem} xs={6} >
                                    <TextField
                                        label='计划名称'
                                        fullWidth
                                        name='planName'
                                        error={plan.planName ? false : true}
                                        variant="outlined"
                                        value={plan.planName || ''}
                                        onChange={onhandleChange}>
                                    </TextField>
                                </Grid>
                                <Grid item className={classes.planHeaderItem} xs={3} >
                                    <TextField
                                        label='计划类型'
                                        fullWidth
                                        name='planType'
                                        variant="outlined"
                                        value={'临时'}
                                        inputProps={{
                                            readOnly: true,
                                        }}>
                                    </TextField></Grid>
                                <Grid item className={classes.planHeaderItem} xs={6} >
                                    <MuiPickersUtilsProvider key={'startTime'} utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            label='计划开始时间'
                                            name='startTime'
                                            style={{
                                            }}
                                            value={plan.startTime}
                                            onChange={(date) => onhandlePlanDateChange(date, 'startTime')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item className={classes.planHeaderItem} xs={6}>
                                    <MuiPickersUtilsProvider key={'endTime'} utils={DateFnsUtils} locale={zhCN}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            inputVariant="outlined"
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            label='计划结束时间'
                                            name='endTime'
                                            style={{
                                            }}
                                            value={plan.endTime}
                                            onChange={(date) => onhandlePlanDateChange(date, 'endTime')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.planDetails}>
                                <Grid container alignItems='flex-end'>
                                    <Grid item ><Typography variant='h4'>服务列表</Typography> </Grid>
                                    <Grid item className={classes.flexgrow}> </Grid>
                                    {isEditing() ? '' :
                                        <Grid item><Tooltip title='新增'><IconButton variant='text' onClick={() => addRow()}> <AddIcon fontSize='small' /></IconButton></Tooltip></Grid>}
                                </Grid>
                                <Table
                                    size='small'
                                    className={classes.stripedTable}
                                    rowKey={record => record.id}
                                    dataSource={planDtls}
                                    columns={assemblyColumns()}
                                ></Table>
                            </Grid>
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={onhandleSave} color="primary"> 保存
                    </Button>
                    <Button onClick={onhandleClose} color="primary"> 取消
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

MTempPlanDetailComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

MTempPlanDetailComponent.defaultProps = {
    plan: {},
    openFlag: false,

};

export default withStyles(MTempPlanDetailComponent);