import React, { useState } from "react";
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, TextField } from "@material-ui/core";
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
    Grid, Button, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { getStore } from '../../redux/store';
import { createmodal } from '../../redux/modules/modal';
import * as _ from 'lodash';
import UserCard from '../../containers/UserCard';
import DeviceCard from '../../containers/DeviceCard';
import UserSelectTest from './UserSelectTest';

const MyDialog = props => {
    const { classes, } = props

    const [open, setOpen] = useState(false)
    const [success, setSuccess] = useState(true)
    const [search, setSearch] = useState(false)
    const [cat, setCat] = useState(false)
    const [procat, setProcat] = useState(false)
    const [useStatus, setUseStatus] = useState(false)
    const [company, setCompany] = useState(false)
    const [install, setInstall] = useState(false)
    const [companyId, setCompanyId] = useState(null)
    const [commonUse, setCommonUse] = useState(false)
    const [deviceSearch, setDeviceSearch] = useState(false)

    const onhandleClose = () => {
        setOpen(false)
    }
    const onhandleOpen = () => {
        setOpen(true)
    }

    const onhandleChange = (event) => {
        setCompanyId(_.toInteger(event.target.value))
    }

    const onhandleOpenDeviceSelect = () => {
        let store = getStore();
        let parm = {}
        if (search) {
            parm.search = search
        }
        if (cat) {
            parm.cat = cat
        }
        if (procat) {
            parm.procat = procat
        }
        if (useStatus) {
            parm.useStatus = useStatus
        }
        if (company) {
            parm.company = company
        }
        if (install) {
            parm.install = install
        }
        if (companyId) {
            parm.companyId = companyId
        }
        if (success) {
            parm.callback = () => {
                return new Promise((resolve, reject) => {
                    console.log('callback success')
                    return resolve(true)
                })
            };
        } else {
            parm.callback = () => {
                return new Promise((resolve, reject) => {
                    console.log('callback fail')
                    return reject('callbackFunc Error')
                })
                // throw new Error('callbackFunc Error')
            };
        }

        store.dispatch(createmodal('deviceSelect', parm, 'medium'))
    }


    const handleChange = (type) => {
        switch (type) {
            case 'success':
                setSuccess(!success)
                break;
            case 'search':
                setSearch(!search)
                break;
            case 'cat':
                setCat(!cat)
                break;
            case 'procat':
                setProcat(!procat)
                break;
            case 'useStatus':
                setUseStatus(!useStatus)
                break;
            case 'company':
                setCompany(!company)
                break;
            case 'install':
                if (!install) {
                    setCompany(true)
                    setInstall(!install)
                } else {
                    setInstall(!install)
                }
                break;
            case 'commonUse':
                setCommonUse(!commonUse)
                break;
            case 'deviceSearch':
                setDeviceSearch(!deviceSearch)
                break;
            default:
            // do nothing
        }
    };

    const onhandleOpenAccTypeSelect = () => {
        let store = getStore();
        let param = {}
        store.dispatch(createmodal('acctypeSelect', param, 'medium'))
    }

    const onhandleOpenSupplierSelect = () => {
        let store = getStore();
        let param = {}
        store.dispatch(createmodal('supplierSelect', param, 'medium'))
    }

    const onhandleOpenAccessorySelect = () => {
        let store = getStore();
        let param = {}

        if (search) {
            param.search = search
        }
        if (cat) {
            param.cat = cat
        }
        if (commonUse) {
            param.commonUse = commonUse
        }
        if (useStatus) {
            param.useStatus = useStatus
        }
        if (company) {
            param.company = company
        }
        if (deviceSearch) {
            param.deviceSearch = deviceSearch
        }
        if (companyId) {
            param.companyId = companyId
        }
        if (success) {
            param.callback = () => {
                return new Promise((resolve, reject) => {
                    console.log('callback success')
                    return resolve(true)
                })
            };
        } else {
            param.callback = () => {
                return new Promise((resolve, reject) => {
                    console.log('callback fail')
                    return reject('callbackFunc Error')
                })
                // throw new Error('callbackFunc Error')
            };
        }

        store.dispatch(createmodal('accessorySelect', param, 'medium'))
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item>
                    <UserCard userId={141} child={<Typography>黄飞鸿</Typography>} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item>
                    <DeviceCard deviceId={1971} child={<Typography>设备</Typography>} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>

                <Grid item xs={3} >
                    <Dialog
                        open={open}
                        onClose={onhandleClose}
                    >
                        <DialogTitle  >
                            <Typography component={'span'} variant='h3'>对话框名称</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className={classes.contentGrid}>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="请输入1"
                                        label='名称' />
                                </Grid>
                                <Grid item className={classes.itemGrid}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='类型' />
                                </Grid>
                                <Grid item className={classes.itemGrid} >
                                    <TextField
                                        variant="outlined"
                                        placeholder="请输入"
                                        label='设置' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button > 确定</Button>
                            <Button onClick={onhandleClose}> 取消</Button>
                        </DialogActions>
                    </Dialog>
                    <Button onClick={onhandleOpen} variant='contained'>
                        Open Dialog
                    </Button>

                    <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">选择需要添加的条件</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={success} onChange={() => handleChange('success')} name="antoine" />}
                                    label="callback success"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={search} onChange={() => handleChange('search')} name="gilad" />}
                                    label="search"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cat} onChange={() => handleChange('cat')} name="jason" />}
                                    label="cat"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={procat} onChange={() => handleChange('procat')} name="antoine" />}
                                    label="procat"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={useStatus} onChange={() => handleChange('useStatus')} name="antoine" />}
                                    label="useStatus"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={company} onChange={() => handleChange('company')} name="antoine" />}
                                    label="company"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={install} onChange={() => handleChange('install')} name="antoine" />}
                                    label="install"
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            id="outlined-full-width"
                            label="Company Id"
                            value={companyId}
                            placeholder="121~126"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={event => onhandleChange(event)}
                        />
                    </div>
                    <div>
                        <Button onClick={onhandleOpenDeviceSelect} variant='contained'>
                            Open DeviceSelect
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={3} >
                    <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">选择需要添加的条件</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={success} onChange={() => handleChange('success')} name="antoine" />}
                                    label="callback success"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={search} onChange={() => handleChange('search')} name="gilad" />}
                                    label="search"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cat} onChange={() => handleChange('cat')} name="jason" />}
                                    label="cat"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={commonUse} onChange={() => handleChange('commonUse')} name="antoine" />}
                                    label="commonUse"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={deviceSearch} onChange={() => handleChange('deviceSearch')} name="antoine" />}
                                    label="deviceSearch"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={useStatus} onChange={() => handleChange('useStatus')} name="antoine" />}
                                    label="useStatus"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={company} onChange={() => handleChange('company')} name="antoine" />}
                                    label="company"
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            id="outlined-full-width"
                            label="Company Id"
                            value={companyId}
                            placeholder="121~126"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={event => onhandleChange(event)}
                        />
                    </div>
                    <div>
                        <Button onClick={onhandleOpenAccessorySelect} variant='contained'>
                            Open AccessorySelect
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <UserSelectTest />
                </Grid>
                <div>
                    <Button onClick={onhandleOpenSupplierSelect} variant='contained'>
                        Open SupplierSelect
                    </Button>
                </div>
            </Grid>
            <Grid container spacing={1}>
                <Button onClick={onhandleOpenAccTypeSelect} variant='contained'>
                    Open AccTypeSelect
                </Button>
            </Grid>
        </div >
    )
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(MyDialog);