import { connect } from 'react-redux';
import { DeviceGroupDetail } from '../components/DialogCommon/DeviceGroupDetail ';
import { getDeviceGroup } from '../../../redux/modules/deviceGroup';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.deviceGroup
    }
}

const mapDispathToProps = (dispath) => {
    return {
        getDeviceGroup: (id) => {
            dispath(getDeviceGroup(id));
        },
    }
}
export default connect(mapStateToProps, mapDispathToProps)(DeviceGroupDetail);

