import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import {
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  TablePagination
} from "@material-ui/core";
import * as _ from 'lodash';
import MaterialTable from 'material-table';
import { localization, options, style } from "../../utils/mtable";
import '../../assets/rc-tree.css';
import '../../assets/rc-tree-select-tree.css';

function SupplierSelectPage(props) {
  const { classes,
    search,
    supplierList,
    supplierGroupCodes,
    onhandleSelectChange,
    onhandleChange,
    onhandleClearSearch,
    onhandleSearch,
    onhandleSetSelectedSupplier,
    onhandleExitSelected,
    onhandleConfirm,
    pageSize,
    setPageSize
  } = props;

  const [page, setPage] = useState(0);
  const tableHeight = (window.innerHeight - 600) / window.innerHeight * 100;

  const getLocalization = () => {
    let newLocal = _.cloneDeep(localization);
    newLocal.body.emptyDataSourceMessage = '请输入检索条件检索数据';
    return newLocal;
  }

  const renderSupplierGroupCodes = (supplier) => {
    if (supplierGroupCodes && supplierGroupCodes.length > 0 && supplier.supplierGroupNo) {
      let supplierGroupCode = _.find(supplierGroupCodes, { 'code': supplier.supplierGroupNo });
      return supplierGroupCode.name ? supplierGroupCode.name : "";
    } else {
      return ""
    }
  }

  return (
    <div className={classes.card}>
      <Grid>
        <Typography variant="h3" gutterBottom>
          {"承包商选择"}
        </Typography>
      </Grid>
      <Grid>
        <div className={classes.planitemroot}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={2}>
              <Typography color='textPrimary' className={classes.label}>
                承包商名称
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ width: '90%' }}
                value={search.supplierName || ''}
                variant="standard"
                placeholder="承包商名称"
                onChange={event => onhandleChange(event, 'supplierName')}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.label}>
                承包商组别名称
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ width: '90%' }}
                id="filled-select-company"
                select
                value={search.supplierGroupCode}
                onChange={(event) => onhandleSelectChange(event, 'supplierGroupCode')}
                variant="standard"
              >
                <MenuItem key={1} value={''}>-</MenuItem>
                {_.sortBy(supplierGroupCodes, 'code').map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={1}>
              <Button variant="contained" color="primary"
                onClick={onhandleSearch}
              >
                搜索
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" color="primary"
                onClick={onhandleClearSearch}
              >
                重置
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: '承包商名称',
                  field: 'companyId',
                  render: row => row ? <Typography>{row.supplierName}</Typography> : '',
                  editable: 'never'
                },
                {
                  title: '承包商组别名称',
                  field: 'deviceNo',
                  render: row => row ? <Typography>{renderSupplierGroupCodes(row)}</Typography> : '',
                  editable: 'never'
                },
              ]}
              data={supplierList.list}
              options={{
                ...options,
                pageSize: 5,
                // maxBodyHeight: `${tableHeight}vh`,
                minBodyHeight: `${tableHeight}vh`,
                // tableLayout: 'auto',
                actionsColumnIndex: -1,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                selection: true
              }}
              style={{
                ...style,
                border: '0px solid black',
                boxShadow: 'none'
              }}
              components={{
                Pagination: props => (
                  <TablePagination
                    {...props}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowsPerPage={pageSize ? pageSize : 5}
                    count={supplierList.total}
                    page={page}
                    onChangePage={(event, pageNum) => {
                      setPage(pageNum);
                      onhandleSearch({
                        offset: pageNum * (pageSize ? pageSize : 5)
                      })
                    }}
                    onChangeRowsPerPage={(event) => {
                      props.onChangeRowsPerPage(event);
                      setPageSize(event.target.value);
                      setPage(0);
                      onhandleSearch({
                        offset: 0
                      })
                    }}

                  />
                )
              }}
              localization={getLocalization()}
              onSelectionChange={(rows) => onhandleSetSelectedSupplier(rows)}
            >
            </MaterialTable>
          </Grid>
        </Grid>
      </Grid>


      <Grid container spacing={0} className={classes.gridContainer} justify="flex-end">
        <Button variant="outlined" color="primary" style={{ marginRight: 16 }}
          onClick={event => onhandleExitSelected()}
        >
          取消
        </Button>
        <Button variant="contained" color="primary"
          onClick={event => onhandleConfirm()}
        >
          确定
        </Button>
      </Grid>
    </div>
  );
}

SupplierSelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  supplierList: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  supplierGroupCodes: PropTypes.array.isRequired,
  onhandleSelectChange: PropTypes.func.isRequired,
  onhandleChange: PropTypes.func.isRequired,
  onhandleSearch: PropTypes.func.isRequired,
  onhandleSetSelectedSupplier: PropTypes.func.isRequired,
  onhandleExitSelected: PropTypes.func.isRequired,
  onhandleConfirm: PropTypes.func.isRequired,
};

export default withStyles(SupplierSelectPage);
