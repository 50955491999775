import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { createhttpMessage, createMessage } from './message'

const defaultState = {
  isloading: false,
  bus: {
    total:0,
    list:[]
  },
  currentBu: {},
  buError: {},
  buChange: false
};

export const backToBuList = createAction('@@xcloud/businessUnit/backToBuList',
  () => (dispatch, getState) => {
    dispatch(push('/buManagement'));
  });

export const getBus = createAction('@@xcloud/businessUnit/getBus',
  (query) => (dispatch, getState, httpClient) => {
    dispatch(setBuChange(false));
    let url = '/api/businessunits?status=active';
    if (query.offset !== undefined){
      url += "&offset=" + query.offset
    }
    if (query.size !== undefined){
      url += "&size=" + query.size
    }
    if (query.sort !== undefined){
      url += "&sort=" + query.sort
    }
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            dispatch(setBus(res.data));
          }
        }
        return res.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '业务分类列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getBuById = createAction('@@xcloud/businessUnit/getBuById',
  (uid) => (dispatch, getState, httpClient) => {
    // dispatch(startLoading());
    let url = '/api/businessunits/' + uid;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentBu(res.data));
          // dispatch(stopLoading());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(stopLoading());
        let msg = '业务分类信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createBu = createAction('@@xcloud/businessUnit/createBu',
  () => (dispatch, getState, httpClient) => {
    //dispatch(startLoading());
    let url = '/api/businessunits';
    let cbu = getState().bu.currentBu;
    //console.log(cbu);
    return httpClient.post(url, cbu)
      .then((res) => {
        if (res.status === 201) {
          // console.log(res.data);
          dispatch(setCurrentBu(res.data));
          dispatch(stopLoading());
          dispatch(createMessage('success', '创建业务分类[' + cbu.unitName + ']成功'));
          dispatch(backToBuList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '创建新业务分类失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateBu = createAction('@@xcloud/businessUnit/updateBu',
  () => (dispatch, getState, httpClient) => {
    dispatch(startLoading());
    let updateBu = getState().bu.currentBu;
    let url = '/api/businessunits/' + updateBu.unitId;
    let to = {
      unitId: updateBu.unitId,
      tenantId: updateBu.tenantId,
      unitName: updateBu.unitName,
      status: updateBu.status,
      opentext: updateBu.opentext
    }
    return httpClient.patch(url, to)
      .then((res) => {
        if (res.status === 200) {
            dispatch(setCurrentBu(res.data));
            dispatch(stopLoading());
            dispatch(createMessage('success', '更新业务分类[' + updateBu.unitName + ']成功'));
            dispatch(backToBuList());
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        dispatch(stopLoading());
        let msg = '更新业务分类[' + updateBu.unitName + ']失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

  export const deleteBu = createAction('@@znsys/businessUnit/deleteBu',
    (uid,uName) => (dispatch, getState, httpClient) => {
      dispatch(startLoading());
      let to = {
        unitId: uid,
        status: 'inactive'
      }
      let url = '/api/businessunits/' + uid;
      httpClient.patch(url, to)
        .then((res) => {
          if (res.status === 200) {
            dispatch(stopLoading());
            dispatch(createMessage('success','业务分类[' + uName + ']删除成功'));
            dispatch(backToBuList());
          }
          return res;
        })
        .catch(err => {
          dispatch(stopLoading());
          let msg = '业务分类[' + uName + ']删除失败';
          dispatch(createhttpMessage(err.response?err.response:'',msg));
          return [];
        })
    }
  );

export const setBus = createAction('@@xcloud/businessUnit/setBus');

export const setCurrentBu = createAction('@@xcloud/businessUnit/setCurrentBu');

export const setBuError = createAction('@@xcloud/businessUnit/setBuError');

export const setBuChange = createAction('@@xcloud/tenant/setBuChange');

export const stopLoading = createAction('@@xcloud/businessUnit/stopLoading');

export const startLoading = createAction('@@xcloud/businessUnit/startLoading');

export const initCreate = createAction(
  '@@xcloud/businessUnit/initCreate',
  () => (dispatch) => {
    dispatch(initBu(true));
    dispatch(push('/buManagement/info'));
  }
);

export const initUpdate = createAction(
    '@@xcloud/businessUnit/initUpdate',
    (uid) => (dispatch, getState, httpClient) => {
      dispatch(startLoading());
      return Promise.all([
        dispatch(getBuById(uid)),
        dispatch(initBu(false))
      ]).then(dispatch(push('/buManagement/info')))
        .catch(err => {
          dispatch(stopLoading());
          let msg = '获取业务分类详细信息失败';
          dispatch(createMessage('error', msg));
        });
    }
  );

  export const initBu = createAction(
    '@@xcloud/businessUnit/initBu',
    (isCreate) => (dispatch, getState) => {
      let currentUserInfo = getState().auth.currentUserInfo;
      if (isCreate) {
        let newBu = {
          "unitId": 0,
          "unitName": '',
          "status": "active",
          "opentext":'',
          "tenantId":currentUserInfo.tenantId
        };
        dispatch(setCurrentBu(newBu));
      }
      let errorInfo = {
        unitName: ''
      };
      dispatch(setBuError(errorInfo));
      dispatch(setBuChange(false));
    }
  );

  export const buReducer = handleActions(
    {
      [setBus]: (state, { payload: value }) => {
        return {
          ...state,
          bus: value
        };
      },
      [setBuError]: (state, { payload: value }) => {
        return {
          ...state,
          buError: value
        };
      },
      [setBuChange]: (state, { payload: value }) => {
        return {
          ...state,
          buChange: value
        };
      },
      [setCurrentBu]: (state, { payload: value }) => {
        return {
          ...state,
          currentBu: value
        };
      },
      [startLoading]: (state) => {
        return {
          ...state,
          isloading: true
        };
      },
      [stopLoading]: (state) => {
        return {
          ...state,
          isloading: false
        };
      }
    },
    defaultState
  );

export default buReducer;