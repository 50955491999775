import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import { localization, options, style } from "@/utils/mtable";
import withStyles from './styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HistoryIcon from '@material-ui/icons/History';
import { getCodeName, VALUATION_TYPE, getCodeListByType } from '@/utils/manufactureUtils';
import { MANUFACTURE_SERVICE_TYPE } from '@/utils/constant';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import * as _ from 'lodash';


const ServicePricingComponent = props => {
    const { classes,
        services,
        manufactureDicts,
        businessGroupList,
        roleEditFlag,
        onhandleUpdate,
        onhandleClick,
        onhandleShowHistory,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        toolbar: false,
    }

    const tableStyle = {
        ...style
    }

    const renderAmoebaIndex = (ind) => {
        let bg = _.find(businessGroupList, bg => { return bg.subId === ind })
        return bg ? bg.groupName : ''
    }

    const renderServiceType = (serviceType) => {
        if (serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Tooltip title="外委服务"><CallMissedOutgoingIcon /></Tooltip>
        } else if (serviceType === MANUFACTURE_SERVICE_TYPE.INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    const renderTaxRate = (row) => {
        if (row.serviceType === MANUFACTURE_SERVICE_TYPE.COMMISSION) {
            return <Typography>{'-'}</Typography>
        } else if (!row.taxRate) {
            return <Typography>{''}</Typography>
        } else {
            return <Typography>{Number(row.taxRate).toFixed(3) + '%'}</Typography>
        }

    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '服务名称',
                field: 'serviceName',
                width: 200,
                render: row => <Typography>{row.serviceName}{renderServiceType(row.serviceType)}</Typography>,
                editable: 'never'
            },
            {
                title: '服务类型',
                field: 'amoebaIndex',
                width: 130,
                render: row => <Typography>{renderAmoebaIndex(row.amoebaIndex)}</Typography>,
                editable: 'never'
            },
            {
                title: '计价方式',
                field: 'valuationType',
                width: 100,
                render: row => <Typography>{getCodeName(manufactureDicts, VALUATION_TYPE, row.valuationType)}</Typography>,
                lookup: _.chain(getCodeListByType(manufactureDicts, VALUATION_TYPE)).keyBy('code').mapValues('name').value()
            },
            {
                field: 'unitPrice',
                title: '单价(元)',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.unitPrice > 0 ? Number(row.unitPrice).toFixed(2) : '-'}</Typography>,
            },
            {
                field: 'unit',
                title: '单位',
                align: 'left',
                width: 120,
                render: row => <Typography>{row.unit ? row.unit : ''}</Typography>,
            },
            {
                field: 'taxRate',
                title: '税率',
                align: 'right',
                width: 120,
                render: row => renderTaxRate(row),
                editComponent: props => <TextField
                    variant="standard" size="small"
                    // error={row && row.err !== ''}
                    // helperText={row.err}
                    // style={{ width: '120px' }}
                    error={props.value && (Number(props.value) > 100 || Number(props.value) < 0)}
                    helperText={props.value && (Number(props.value) > 100 || Number(props.value) < 0) ? '请输入有效税率' : ''}
                    value={props.value || ''}
                    type="number"
                    name='val'
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{"%"}</InputAdornment>,
                    }}
                    onChange={e => props.onChange(e.target.value)}
                />
            },
            {
                field: 'valuationFormulaStr',
                title: '计价公式',
                render: row => <Typography>{row.valuationFormulaStr || ''}</Typography>,
                editable: 'never'
            },
            {
                field: 'valuationFormulaStr',
                title: '产量公式',
                render: row => <Typography>{row.outputFormulaStr || ''}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }


    const assemblyData = () => {
        return services
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        editable={
                            {
                                isEditable: (rowData) => roleEditFlag ? true : rowData.serviceType !== 'COMMISSION',
                                onRowUpdate: (newData, oldData) => onhandleUpdate(newData, oldData),
                            }
                        }
                        actions={
                            [
                                rowData => ({
                                    disabled: roleEditFlag ? false : rowData.serviceType === 'COMMISSION',
                                    icon: () => { return <AttachMoneyIcon /> },
                                    tooltip: '修改计价公式',
                                    onClick: (event, rowData) => {
                                        onhandleClick(event, rowData, "VALUATION")
                                    }
                                }),
                                rowData => ({
                                    disabled: roleEditFlag ? false : rowData.serviceType === 'COMMISSION',
                                    icon: () => { return <LocalShippingIcon /> },
                                    tooltip: '修改产量公式',
                                    onClick: (event, rowData) => {
                                        onhandleClick(event, rowData, "OUTPUT")
                                    }
                                }), {
                                    icon: () => { return <HistoryIcon /> },
                                    tooltip: '服务修改历史',
                                    onClick: (event, rowData) => {
                                        onhandleShowHistory(event, rowData)
                                    }
                                },
                            ]
                        }
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div >
    )
}

ServicePricingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    services: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleClick: PropTypes.func,
};

ServicePricingComponent.defaultProps = {
    businessGroupList: []
};

export default withStyles(ServicePricingComponent);