import React, { Component } from 'react';
import ForgotPassword from '../components/ForgotPassword';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cellphoneRegx } from '../utils/regex';
import { updateResetPasswordInfo, resetPassword, sendSmsCode, setSmsClock } from '../redux/modules/auth';
import * as _ from 'lodash';

class ResetPasswordContainer extends Component {

    static propTypes = {
        resetPasswordInfo: PropTypes.object,
        smsClock: PropTypes.number,
        onhandleChange: PropTypes.func,
        onhandleUpdateResetPasswordInfo: PropTypes.func,
        onhandleResetPassword: PropTypes.func,
        onhandleSmsCode: PropTypes.func
    }

    componentDidMount() {
        this.props.onhandleUpdateResetPasswordInfo({
            errorMessage: '',
            phone: '',
            smsCode: '',
            newPwd: '',
            confirmPwd: ''
        });
        this.props.onhandleSetSmsClock();
    }

    handleCheck = (event, field) => {
        if ((!field || field === 'phone') && this.props.resetPasswordInfo.phone === '') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '请输入手机号码'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if ((!field || field === 'phone') &&
            (this.props.resetPasswordInfo.phone !== '' && !cellphoneRegx.test(_.trim(this.props.resetPasswordInfo.phone)))) {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '请输入有效的手机号码'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if ((!field || field === 'smsCode') && this.props.resetPasswordInfo.smsCode === '') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '请输入手机验证码'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if ((!field || field === 'newPwd') && this.props.resetPasswordInfo.newPwd === '') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '请输入新密码'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if ((!field || field === 'newPwd')
            && (this.props.resetPasswordInfo.newPwd !== '' && this.props.resetPasswordInfo.newPwd.length < 8)) {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '新密码不能少于8位字符'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if ((!field || field === 'confirmPwd')
            && (this.props.resetPasswordInfo.confirmPwd !== '' || this.props.resetPasswordInfo.newPwd !== '')
            && this.props.resetPasswordInfo.confirmPwd !== this.props.resetPasswordInfo.newPwd) {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: '确认密码不正确'
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                errorMessage: ''
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        }
    };

    handleResetPassword = async (event) => {
        await this.handleCheck(event, null);
        if (this.props.resetPasswordInfo.errorMessage === '') {
            this.props.onhandleResetPassword();
        }
    };

    handleSmsCode = () => {
        if (this.props.resetPasswordInfo.phone !== '') {
            this.props.onhandleSmsCode(this.props.resetPasswordInfo.phone);
        }
    };

    handleChange = (event, field) => {
        if (field === 'phone') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                phone: _.trim(event.target.value)
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if (field === 'smsCode') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                smsCode: _.trim(event.target.value)
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if (field === 'newPwd') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                newPwd: event.target.value
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        } else if (field === 'confirmPwd') {
            const newInfo = {
                ...this.props.resetPasswordInfo,
                confirmPwd: event.target.value
            };
            this.props.onhandleUpdateResetPasswordInfo(newInfo);
        }
    };

    render() {
        return (
            <ForgotPassword onhandleResetPassword={this.handleResetPassword.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleSmsCode={this.handleSmsCode.bind(this)}
                resetPasswordInfo={this.props.resetPasswordInfo}
                smsClock={this.props.smsClock}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        resetPasswordInfo: state.auth.resetPasswordInfo,
        smsClock: state.auth.smsClock
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateResetPasswordInfo: (info) => {
            dispatch(updateResetPasswordInfo(info));
        },
        onhandleResetPassword: () => {
            dispatch(resetPassword());
        },
        onhandleSmsCode: (phoneNum) => {
            dispatch(sendSmsCode(phoneNum, 'xcloud_changepwd'));
        },
        onhandleSetSmsClock: () => {
            dispatch(setSmsClock(180));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer);