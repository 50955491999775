import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, DialogContent, Dialog, DialogTitle, DialogActions, TextField } from '@material-ui/core';
import withStyles from './styles';
import { SAFETY_ACTIVITY_TYPE } from '../../../../utils/constant';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhCN from 'date-fns/locale/zh-CN';
import _ from 'lodash';

const ActivityPannelComponent = ({
    classes,
    activity,
    open,
    constant,
    onhandleClose,
    onhandleSave,
    onhandleChangeActivityAttr,
}) => {
    return (
        <div alignitems="center" >
            {!activity || !activity.actId ? '' :
                <Dialog open={open} onClose={onhandleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
                    <DialogTitle id="title">
                        <Typography className={classes.title} component={'span'} variant='h3'>活动详情</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid >
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="活动名称"
                                    name='activity'
                                    value={activity.activity}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="类型"
                                    name='activityType'
                                    value={activity.activityType}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {SAFETY_ACTIVITY_TYPE.map(item => {
                                        return <option key={item.value} value={item.value}>
                                            {item.name}
                                        </option>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="地点"
                                    name='location'
                                    value={activity.location}
                                    inputProps={{
                                        readOnly: true
                                    }} />
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    placeholder="请输入"
                                    label="状态"
                                    name='status'
                                    value={activity.status}
                                    onChange={onhandleChangeActivityAttr}
                                >
                                    {_.filter(constant.activityStatus, function (o) { return o.code === 'new' || o.code === 'active' || o.code === 'finalized' }).map(item => {
                                        return <option key={item.code} value={item.code}>
                                            {item.name}
                                        </option>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item className={classes.item}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhCN}>
                                    <KeyboardDatePicker
                                        className={classes.datePicker}
                                        fullWidth
                                        autoOk
                                        disableToolbar
                                        label='时间'
                                        inputVariant="outlined"
                                        format="yyyy/MM/dd"
                                        id="date-selector"
                                        margin="normal"
                                        value={activity.activityTime}
                                        inputProps={{
                                            readOnly: true
                                        }} />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item className={classes.item}>
                                <TextField
                                    variant="outlined"
                                    placeholder="请输入"
                                    label={"活动记录"}
                                    fullWidth
                                    multiline
                                    value={activity.recordContent}
                                    rows={3}
                                    name='recordContent'
                                    onChange={onhandleChangeActivityAttr}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={onhandleSave}>
                            保存</Button>
                        <Button variant="text" onClick={onhandleClose}>
                            关闭</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>

    );
}

ActivityPannelComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};
ActivityPannelComponent.defaultProps = {

}


export default withStyles(ActivityPannelComponent);