import { withStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        // backgroundColor: 'rgba(247,247,250,1)'
    },

    plancategory: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    plan: {
        width: 219,
        height: 118,
        backgroundColor: colors.blue['700'],
        borderRadius: 1,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    plantitle: {
        color: '#FFFFFF'
    },

    planaction: {
        fontSize: 12,
        padding: "8px 0px 0px 0px",
        color: '#FFFFFF'
    },
    planicon: {
        fontSize: 12,
    },
    tabs: {
        color: '#2979ff',
        minHeight: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    tab: {
        fontSize: 14,
        minWidth: 'auto',
        minHeight: theme.spacing(4),
        padding: '6px 18px',
    },
    actiongrid: {
        flexDirection: 'column'
    }
});

export default withStyles(styles);