import { connect } from 'react-redux';
import { DeviceDetail } from '../components/DialogCommon/DeviceDetail';

const mapStateToProps = (state) => {
    return {
        ...state.dialog,
        ...state.devices,
        ...state.trees
    }
}

export default connect(mapStateToProps)(DeviceDetail);

