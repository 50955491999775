import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChecklistInventory from "@/components/InspectionManagement/Checklist/ChecklistInventory";
import InventoryEditPanel from "@/containers/InspectionManagement/Checklist/InventoryEditPanel";
import PropTypes from "prop-types";
import {
    initInventoryHeader,
    getInventories,
    resetIvtSearch,
    routeToInventoryInfo,
    setIvtSearch,
    createInventory,
    updateInventory,
    changeInventoryStatus
} from '@/redux/modules/inspection/inspection';
import {
    openInventoryEditPanel
} from '@/redux/modules/inspection/checklistInventoryEdit';
import { openConfirmDialog } from '../../../redux/modules/confirmDialog';
import { getActualCompany, getManageDpt } from '@/utils/constant';
import _ from 'lodash';

class ChecklistInventoryContainer extends Component {

    static propTypes = {
        inventories: PropTypes.object
    };
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        const { location } = this.props;
        const isBack = location ? location.state : false;
        this.props.onhandleInitInventoryHeader(isBack);
    }

    handleSearch() {
        this.props.onhandleSearch({
            ...this.props.search,
            offset: 0,
            page: 0
        });
        const newSearch = {
            ...this.props.search,
            offset: 0,
            page: 0
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    }

    handleClearSearch() {
        this.props.onhandleClearSarch();
    }

    handleSelect = (value, field) => {
        const newValue = value.target.value;
        let newSearch;
        switch (field) {
            case 'company':
                newSearch = {
                    ...this.props.search,
                    company: newValue,
                    dptId: 0,
                    roleId: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'department':
                let findDpt = _.find(this.props.departments, { 'dptId': _.toInteger(newValue) });
                newSearch = {
                    ...this.props.search,
                    dptId: newValue,
                    company: findDpt ? findDpt.companyId : undefined,
                    roleId: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'role':
                newSearch = {
                    ...this.props.search,
                    roleId: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'listType':
                newSearch = {
                    ...this.props.search,
                    listType: newValue,
                    catCode: ''
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'category':
                newSearch = {
                    ...this.props.search,
                    catCode: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'severity':
                newSearch = {
                    ...this.props.search,
                    severity: newValue
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'size':
                newSearch = {
                    ...this.props.search,
                    size: newValue,
                    offset: 0,
                    page: 0
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleInventoryEdit(rowData) {
        const {
            currentUserInfo,
            companies
        } = this.props;
        const company = _.find(companies, { 'companyId': currentUserInfo.companyId });
        const hasPcomp = company && company.pid;
        const companyId = hasPcomp ? currentUserInfo.companyId : '';
        this.props.onhandleOpenInventoryEditPanel(this.handleSubmit.bind(this), null, rowData, companyId);
    }

    handleSubmit(inventory) {
        const {
            onhandleCreateInventory,
            onhandleUpdateInventory,
            onhandleGetChecklistInventory,
            search
        } = this.props;

        const newInventory = {
            inventoryId: inventory.inventoryId ? _.toNumber(inventory.inventoryId) : undefined,
            inventoryName: inventory.inventoryName ? inventory.inventoryName : '',
            companyId: inventory.companyId ? _.toNumber(inventory.companyId) : 0,
            listType: inventory.listType ? inventory.listType : undefined,
            catCode: inventory.catCode ? inventory.catCode : undefined,
            validStartTime: inventory.validStartTime ? inventory.validStartTime : undefined,
            validEndTime: inventory.validEndTime ? inventory.validEndTime : undefined,
        }

        if (!newInventory.inventoryId) {
            onhandleCreateInventory(newInventory, (resInventory) => {
                onhandleGetChecklistInventory({ ...search, offset: 0 });
            });
        } else {
            onhandleUpdateInventory(newInventory, (resInventory) => {
                onhandleGetChecklistInventory({ ...search });
            });
        }

    }

    handleInventoryDetail(id) {
        this.props.onhandleRouteToInventoryInfo(id);
    }

    handleDelete = (event, id) => {
        let changeStatus = () => {
            this.props.onhandleChangeStatus(id, 'inactive');
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除此检查表吗?', changeStatus, null, false);
    };

    handlePageChange = (value) => {
        let newSearch = {
            ...this.props.search,
            page: value,
            offset: value * this.props.search.size
        };
        this.props.onhandleUpdateEditSearch(newSearch);
    };

    handleListMapping = (fieldId, field) => {
        let value = fieldId;
        const {
            companies,
            departments,
            inspectionDict
        } = this.props;
        switch (field) {
            case 'company':
                value = _.find(companies, { 'companyId': fieldId }) ? _.find(companies, { 'companyId': fieldId }).companyAlias : '';
                break;
            case 'department':
                value = _.find(departments, { 'dptId': fieldId }) ? _.find(departments, { 'dptId': fieldId }).companyDptName : '不限';
                break;
            case 'listType':
            case 'category':
            case 'severity':
            case 'rootCause':
            case 'discipline':
                value = _.find(inspectionDict, { 'code': fieldId }) ? _.find(inspectionDict, { 'code': fieldId }).name : '-';
                break;
            default:
            // do nothing
        }
        return value;
    }

    render() {
        return (<>
            <ChecklistInventory
                lists={this.props.inventories}
                inspectionDptMapping={this.props.inspectionDptMapping}
                userProfile={this.props.userProfile}
                currentUserInfo={this.props.currentUserInfo}
                companyList={this.props.companies}
                departmentList={this.props.departments}
                listTypeList={this.props.listTypeList}
                categoryList={this.props.categoryList}
                onhandleSelectChange={this.handleSelect.bind(this)}
                onhandleListMapping={this.handleListMapping.bind(this)}
                onInventoryDetail={this.handleInventoryDetail.bind(this)}
                onDelete={this.handleDelete.bind(this)}
                onhandleEdit={this.handleInventoryEdit.bind(this)}
                onhandleSearch={this.handleSearch.bind(this)}
                onhandleClearSearch={this.handleClearSearch.bind(this)}
                getInventories={this.props.onhandleGetChecklistInventory}
                search={this.props.search}
                onhandlePageChange={this.handlePageChange.bind(this)}
            />
            {this.props.panelOpen && <InventoryEditPanel />}
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.inspection.ivtSearch,
        listTypeList: state.inspection.listTypeList,
        categoryList: state.inspection.categoryList,
        companies: getActualCompany(state.constant.companies),
        departments: getManageDpt(state.constant.departments),
        inspectionDict: state.constant.inspectionDictionaries,
        inspectionDptMapping: state.constant.inspectionDptMapping,
        inventories: state.inspection.inventories,
        currentUserInfo: state.auth.currentUserInfo,
        userProfile: state.auth.userProfile,
        panelOpen: state.checklistInventoryEdit.open
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        onhandleInitInventoryHeader: (isBack) => {
            dispatch(initInventoryHeader(isBack));
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setIvtSearch(search));
        },
        onhandleSearch: (query) => {
            dispatch(getInventories(query));
        },
        onhandleClearSarch: () => {
            dispatch(resetIvtSearch());
        },
        onhandleOpenInventoryEditPanel: (confirmCallback, closeCallback, inventory, companyId) => {
            dispatch(openInventoryEditPanel(confirmCallback, closeCallback, inventory, companyId))
        },
        onhandleCreateInventory: (inventory, callback) => {
            dispatch(createInventory(inventory, callback))
        },
        onhandleUpdateInventory: (inventory, callback) => {
            dispatch(updateInventory(inventory, callback));
        },
        onhandleGetChecklistInventory: (query) => {
            dispatch(getInventories(query))
        },
        onhandleRouteToInventoryInfo: (id) => {
            dispatch(routeToInventoryInfo(id));
        },
        onhandleChangeStatus: (id, status) => {
            dispatch(changeInventoryStatus(id, status));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistInventoryContainer)
