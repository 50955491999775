import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './styles';
import Typography from "@material-ui/core/Typography";
import {
    Button,
    TextField,
    Grid,
    Avatar,
    FormHelperText,
    Card
} from "@material-ui/core";
import {
    Add,
} from '@material-ui/icons'
import { Prompt } from 'react-router-dom';
import TreeSelect from 'rc-tree-select';
import '../../../../assets/rc-tree.css';
import '../../../../assets/rc-tree-select-tree.css';
import MaterialTable, { MTableHeader } from 'material-table';
import { localization, options, style } from "../../../../utils/mtable";
import ShowCard from '../../../DeviceManagement/OperationAnnuaPlan/ShowCard';
import _ from 'lodash';

const DeviceWorkOrder = props => {

    const {
        classes,
        sourceList,
        orderTypeList,
        orderTypeLeafTree,
        departmentList,
        optCategories,
        companyList,
        currentPlanDetail,
        newWorkOrderChange,
        onhandleAddDetail,
        onhandleBack,
        onhandleSave,
        onhandleUpdate,
        onhandleDelete,
        catList,
        newWorkOrders,
        accCatList,
        id,
        operationPlanDetails
    } = props;
    const tableRef = React.createRef();
    const renderCompany = (companyId) => {
        let companyName = ''
        if (companyList && companyList.length > 0) {
            let value = _.find(companyList, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    // 渲染 - 运维类别
    const renderOptCat = (row) => {
        if ((row.ruleId || row.optType) && optCategories && optCategories.length > 0) {
            let cat = _.find(optCategories, { 'code': (row.ruleId ? row.rule.cat : row.optType) });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderCat = (deviceCat) => {
        if (catList && catList.length > 0 && deviceCat) {
            let cat = _.find(catList, { 'code': deviceCat });
            return cat.name ? cat.name : "";
        } else {
            return ""
        }
    }

    const renderAccCat = (accCat) => {
        let label = '';
        if (accCatList && accCatList.length > 0) {
            let value = _.find(accCatList, function (o) { return o.code === accCat })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderDepartment = (WorkOrder) => {
        let label = '';
        if (departmentList && departmentList.length > 0) {
            let value = _.find(departmentList, function (o) { return o.dptId === WorkOrder.dptId })
            if (value) {
                label = value.dptName
            }
        }
        return label;
    }

    const renderSource = (WorkOrder) => {
        let label = '';
        if (sourceList && sourceList.length > 0) {
            let value = _.find(sourceList, function (o) { return o.code === WorkOrder.source })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderOrderType = (WorkOrder) => {
        let label = '';
        if (orderTypeList && orderTypeList.length > 0) {
            let value = _.find(orderTypeList, function (o) { return o.code === WorkOrder.orderType })
            if (value) {
                label = value.name
            }
        }
        return label;
    }

    const renderPlan = () => {
        let columns = [];
        columns.push(
            {
                title: '对象',
                field: 'group',
                defaultGroupOrder: 0,
                defaultGroupSort: 'desc'
            },
            {
                field: 'type',
                render: row => row ? row.device && row.device.deviceId ?
                    <ShowCard currentInfo={row.device} target={row.target}
                        child={
                            < Avatar className={classes.pic1} > 设备</Avatar >}
                    />
                    :
                    < ShowCard currentInfo={row.accessory} target={row.target}
                        child={
                            < Avatar className={classes.pic2} > 部件</Avatar >}
                    />
                    //     < Avatar className = { classes.pic1 } > 设备</Avatar > :
                    // <Avatar className={classes.pic2}>部件</Avatar> :
                    : '',
                // width: '2em',
                editable: 'never'
            },
            {
                title: '单位',
                field: 'companyId',
                render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
                editable: 'never'
            },
            {
                title: '计划编号',
                field: 'optNo',
                render: row => row ? <Typography>{row.optNo}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维计划',
                field: 'ruleName',
                render: row => row ? row.rule && row.rule.ruleName ? <Typography>{'年度计划'}</Typography> : <Typography>{'临时计划'}</Typography> : '',
                editable: 'never'
            },
            {
                title: '运维类别',
                field: 'optType',
                render: row => row ? <Typography>{renderOptCat(row)}</Typography> : '',
                validate: rowData => (!rowData.optType || rowData.optType === "") ? { isValid: false, helperText: '运维类别不能为空' } : true,
                editable: 'never'
            },
            {
                title: '预算(元)',
                align: 'right',
                field: 'budget',
                render: row => row ? <Typography>{row.budget}</Typography> : '',
                editable: 'never'
            },
            {
                title: '描述',
                field: 'opentext',
                render: row => row ? row.opentext ? <Typography>{row.opentext}</Typography> : row.rule && row.rule.ruleName ? <Typography>{row.rule.ruleName}</Typography> : '' : '',
            }
        )
        return columns
    }

    const renderItems = (row) => {
        return < Grid>
            <Typography variant="h4" className={classes.subTitle}>
                {'关联计划'}
            </Typography>
            <MaterialTable
                components={{
                    // 修复表头样式错乱问题
                    Header: props => <MTableHeader {...props} classes={{ header: classes.mtHeader }} />
                }}
                options={detailOptions}
                columns={renderPlan()}
                data={operationPlanDetails}
                style={tableStyle}
            />
        </Grid>
    }

    const detailOptions = {
        ...options,
        toolbar: false,
        paging: false,
        showTitle: false,
        defaultExpanded: true,
    }

    const columns = [
        {
            title: '来源单位',
            field: 'companyId',
            editable: 'never',
            render: row => row ? <Typography>{renderCompany(row.companyId)}</Typography> : '',
        },
        {
            title: '来源部门',
            field: 'dptId',
            editable: 'never',
            render: row => row ? <Typography>{renderDepartment(row)}</Typography> : '',
        },
        {
            title: '工单来源',
            field: 'source',
            editable: 'never',
            render: row => row ? <Typography>{renderSource(row)}</Typography> : '',
        },
        {
            title: '工单类型',
            field: 'orderType',
            editable: 'onUpdate',
            render: row => row ? <Typography>{renderOrderType(row)}</Typography> : '',
            validate: rowData => (!rowData.orderType || rowData.orderType === '') ? { isValid: false, helperText: '工单类型不能为空' } : true,
            editComponent: (props) => (
                <div>
                    <TreeSelect
                        className={classes.select}
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        dropdownStyle={{ zIndex: 9999, maxHeight: 200, overflow: 'auto' }}
                        allowClear
                        treeLine
                        value={props.rowData.orderType}
                        labelInValue
                        treeData={orderTypeLeafTree}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        onChange={(value) => {
                            props.rowData.orderType = value.value;
                            tableRef.current.forceUpdate();
                        }
                        }
                    />
                    {props.helperText !== '' ? <FormHelperText
                        error={true}
                    >
                        {props.helperText}
                    </FormHelperText> : null}
                </div>
            )
        },
        {
            title: '工单内容',
            field: 'orderContent',
            editable: 'onUpdate',
            validate: rowData => (!rowData.orderContent || rowData.orderContent === '') ? { isValid: false, helperText: '工单内容不能为空' } : true,
            render: row => row ? <Typography>{row.orderContent}</Typography> : '',
        },
        {
            title: '预算(元)',
            field: 'orderBudget',
            editable: 'onUpdate',
            render: row => row ? <Typography>{row.orderBudget}</Typography> : '',
            validate: rowData => (!(rowData && _.isNumber(rowData.orderBudget))) ? { isValid: false, helperText: '预算格式不正确' } : true,
            editComponent: (props) => (
                <TextField
                    error={Boolean(props.helperText)}
                    helperText={props.helperText || ''}
                    value={props.rowData.orderBudget}
                    type="number"
                    onChange={e => props.onChange(_.toNumber(e.target.value))}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}>
                </TextField>
            )
        },
    ]

    // const components = {
    //     Toolbar: props => (
    //         <div>
    //             <Grid container alignItems="center">
    //                 <Grid container className={classes.title}>
    //                     <Typography variant="h3" className={classes.plantname}>
    //                         {id === 'multiple' ? '多计划合并工单下达' : '工单下达'}
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item className={classes.flexgrow}></Grid>
    //             </Grid>
    //             {Boolean(id !== 'multiple' && currentPlanDetail) && <React.Fragment>
    //                 <Grid item xs={12}>
    //                     <Typography variant="h4" className={classes.subTitle}>
    //                         {'基本信息'}
    //                     </Typography>
    //                 </Grid>
    //                 <Grid container className={classes.basicInfoContainer}>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             单&emsp;&emsp;位
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.companyId ? renderCompany(currentPlanDetail.companyId) : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             计划编号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.optNo ? currentPlanDetail.optNo : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             运维计划
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? '年度计划' : '临时计划'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             运维类别
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail ? renderOptCat(currentPlanDetail) : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             预算 (元)
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.budget ? currentPlanDetail.budget : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             计划描述
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.opentext ? currentPlanDetail.opentext : currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? currentPlanDetail.rule.ruleName : ''}
    //                         </Typography>
    //                     </Grid>
    //                 </Grid>
    //                 {Boolean(currentPlanDetail.device && currentPlanDetail.device.deviceId) && <React.Fragment>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             设备编号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.device.deviceNo ? currentPlanDetail.device.deviceNo : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             设备名称
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.device.deviceName ? currentPlanDetail.device.deviceName : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             设备位号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.device.tagNo ? currentPlanDetail.device.tagNo : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             设备类别
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.device.cat ? renderCat(currentPlanDetail.device.cat) : '-'}
    //                         </Typography>
    //                     </Grid>
    //                 </React.Fragment>}
    //                 {Boolean(currentPlanDetail.accessory && currentPlanDetail.accessory.accId) && <React.Fragment>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             部件编号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.accNo ? currentPlanDetail.accessory.accNo : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             部件名称
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.accName ? currentPlanDetail.accessory.accName : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             型号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.model ? currentPlanDetail.accessory.model : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             部件类别
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.cat ? renderAccCat(currentPlanDetail.accessory.cat) : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             关联设备编号
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.deviceNo ? currentPlanDetail.accessory.deviceNo : '-'}
    //                         </Typography>
    //                     </Grid>
    //                     <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
    //                         <Typography className={classes.label}>
    //                             关联设备名称
    //                         </Typography>
    //                         <Typography className={classes.content}>
    //                             {currentPlanDetail.accessory.deviceName ? currentPlanDetail.accessory.deviceName : '-'}
    //                         </Typography>
    //                     </Grid>
    //                 </React.Fragment>}
    //             </React.Fragment>}
    //             {
    //                 id === 'multiple' && operationPlanDetails.length > 0
    //                     ?
    //                     renderItems()
    //                     : null
    //             }
    //             <MTableToolbar {...props} />
    //         </div >
    //     )
    // }

    const tableOptions = {
        ...options,
        showTitle: false,
        search: false,
        paging: false,
    }
    const tableStyle = {
        ...style
    }

    return (
        <Card alignitems="center" className={classes.card}>
            <Grid container alignItems="center">
                <Grid container className={classes.title}>
                    <Typography variant="h3" className={classes.plantname}>
                        {id === 'multiple' ? '多计划合并工单下达' : '工单下达'}
                    </Typography>
                </Grid>
                <Grid item className={classes.flexgrow}></Grid>
            </Grid>
            {Boolean(id !== 'multiple' && currentPlanDetail) && <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.subTitle}>
                        {'基本信息'}
                    </Typography>
                </Grid>
                <Grid container className={classes.basicInfoContainer}>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            单&emsp;&emsp;位
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail.companyId ? renderCompany(currentPlanDetail.companyId) : '-'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            计划编号
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail.optNo ? currentPlanDetail.optNo : '-'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            运维计划
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? '年度计划' : '临时计划'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            运维类别
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail ? renderOptCat(currentPlanDetail) : '-'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            预算 (元)
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail.budget ? currentPlanDetail.budget : '-'}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                        <Typography className={classes.label}>
                            计划描述
                        </Typography>
                        <Typography className={classes.content}>
                            {currentPlanDetail.opentext ? currentPlanDetail.opentext : currentPlanDetail.rule && currentPlanDetail.rule.ruleName ? currentPlanDetail.rule.ruleName : ''}
                        </Typography>
                    </Grid>
                    {Boolean(currentPlanDetail.device && currentPlanDetail.device.deviceId) && <React.Fragment>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                设备编号
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.device.deviceNo ? currentPlanDetail.device.deviceNo : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                设备名称
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.device.deviceName ? currentPlanDetail.device.deviceName : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                设备位号
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.device.tagNo ? currentPlanDetail.device.tagNo : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                设备类别
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.device.cat ? renderCat(currentPlanDetail.device.cat) : '-'}
                            </Typography>
                        </Grid>
                    </React.Fragment>}
                    {Boolean(currentPlanDetail.accessory && currentPlanDetail.accessory.accId) && <React.Fragment>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                部件编号
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.accNo ? currentPlanDetail.accessory.accNo : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                部件名称
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.accName ? currentPlanDetail.accessory.accName : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                型&emsp;&emsp;号
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.model ? currentPlanDetail.accessory.model : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                部件类别
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.cat ? renderAccCat(currentPlanDetail.accessory.cat) : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                关联设备编号
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.deviceNo ? currentPlanDetail.accessory.deviceNo : '-'}
                            </Typography>
                        </Grid>
                        <Grid container item className={classes.infoGrid} sm={6} md={4} lg={3}>
                            <Typography className={classes.label}>
                                关联设备名称
                            </Typography>
                            <Typography className={classes.content}>
                                {currentPlanDetail.accessory.deviceName ? currentPlanDetail.accessory.deviceName : '-'}
                            </Typography>
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </React.Fragment>}
            {
                id === 'multiple' && operationPlanDetails.length > 0
                    ?
                    renderItems()
                    : null
            }
            <Grid>
                {/* <Typography variant="h4" className={classes.subTitle}>
                    {'工单一览'}
                </Typography> */}
                <MaterialTable
                    tableRef={tableRef}
                    className={classes.materialtable}
                    title=''
                    columns={columns}
                    data={newWorkOrders}
                    options={tableOptions}
                    // components={components}
                    localization={localization}
                    style={tableStyle}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                onhandleUpdate(newData)
                                resolve()
                            })
                    }}
                    actions={[
                        rowData => ({
                            icon: 'delete_outlined',
                            tooltip: '删除',
                            onClick: (event, rowData) => {
                                onhandleDelete(rowData)
                            },
                        }),
                        {
                            icon: () => <Button variant='contained' color='primary' className={classes.tableFreeButton} startIcon={<Add />}>
                                新建工单
                            </Button>,
                            tooltip: '新建工单',
                            isFreeAction: true,
                            onClick: (event) => {
                                onhandleAddDetail(event)
                            },
                        }
                    ]
                    }
                >
                </MaterialTable>
            </Grid>
            <Grid container className={classes.gridContainer}>
                <Button variant="contained" color="primary" className={classes.button}
                    disabled={Boolean(!newWorkOrderChange)}
                    onClick={event => onhandleSave(event)}
                >
                    保存
                </Button>
                <Button variant="outlined" color="primary" className={classes.button}
                    onClick={event => onhandleBack(event)}
                >
                    返回
                </Button>
            </Grid>
            <Prompt message="数据未保存,确定要离开？" when={newWorkOrderChange} />
        </Card >
    );
};

DeviceWorkOrder.propTypes = {
    sourceList: PropTypes.array.isRequired,
    orderTypeList: PropTypes.array.isRequired,
    orderTypeLeafTree: PropTypes.array.isRequired,
    departmentList: PropTypes.array.isRequired,
    companyList: PropTypes.array.isRequired,
    currentPlanDetail: PropTypes.object.isRequired,
    onhandleBack: PropTypes.func.isRequired,
    onhandleSave: PropTypes.func.isRequired,
    optCategories: PropTypes.array.isRequired,
    catList: PropTypes.array.isRequired,
    newWorkOrders: PropTypes.array.isRequired,
    newWorkOrderChange: PropTypes.bool.isRequired,
    onhandleAddDetail: PropTypes.func.isRequired,
    onhandleUpdate: PropTypes.func.isRequired,
    onhandleDelete: PropTypes.func.isRequired,
    accCatList: PropTypes.array.isRequired,
};

export default withStyles(DeviceWorkOrder);
