import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceMedium from '@/components/DeviceManagement/DeviceInfo/DeviceDetail/DeviceMedium'

import {
    setMediumOpen,
    setCurrentDevice,
    setCurrentMedia,
    setDeviceBaseChange,
} from "@/redux/modules/devices";
// import * as _ from 'lodash';
import PropTypes from "prop-types";
import * as _ from 'lodash';


class DeviceTransferContainer extends Component {
    static propTypes = {
        currentDevice: PropTypes.object,
        currentUserInfo: PropTypes.object,
        companies: PropTypes.array,
    };

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    // 组件渲染后... ...
    componentDidMount() {

    }

    handleClose = (event) => {
        this.props.onhandleSetMediumOpen(false)
        this.props.onhandleSetCurrentMedia([])
    }

    handleSave = (event) => {
        this.props.onhandleSetMediumOpen(false)
        let newMedia = _.cloneDeep(this.props.currentMedia)
        let device = _.clone(this.props.currentDevice)
        device['media'] = newMedia;
        this.props.onhandleUpdateEditDevice(device)
        this.props.onhandleDeviceBaseChange(true)
        this.props.onhandleSetCurrentMedia([])
    }
    handleAdd = (newData) => {
        let value = _.cloneDeep(this.props.currentMedia)
        newData['mediumName'] = newData.medium.value
        newData['creator'] = this.props.currentUserInfo.userId
        value.push(newData)
        this.props.onhandleSetCurrentMedia(value)
    }
    handleUpdate = (newData, oldData) => {
        let value = _.cloneDeep(this.props.currentMedia)
        let od = _.find(value, { mediumName: oldData.mediumName, mediumPhase: oldData.mediumPhase })
        if (od) {
            od.mediumName = newData.medium.value
            od.mediumPhase = newData.mediumPhase
            od.creator = this.props.currentUserInfo.userId
        }
        this.props.onhandleSetCurrentMedia(value)
    }
    handleDelete = (oldData) => {
        let value = []
        let isFind = false
        for (let od of this.props.currentMedia) {
            if (!isFind && od.mediumName === oldData.mediumName && od.mediumPhase === oldData.mediumPhase) {
                isFind = true
            } else {
                value.push(od)
            }
        }
        this.props.onhandleSetCurrentMedia(value)
    }

    render() {
        return (
            <div>
                <DeviceMedium
                    open={this.props.mediumOpen}
                    device={this.props.currentDevice}
                    media={this.props.currentMedia}
                    mediumTree={this.props.mediumTree}
                    mediumPhaseDict={this.props.mediumPhaseDict}
                    onhandleClose={this.handleClose.bind(this)}
                    onhandleSave={this.handleSave.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        mediumOpen: state.devices.mediumOpen,
        currentDevice: state.devices.currentDevice,
        currentMedia: state.devices.currentMedia,
        mediumTree: state.devices.mediumTree,
        mediumPhaseDict: state.devices.mediumPhase,
        currentUserInfo: state.auth.currentUserInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleSetMediumOpen: (isOpen, media) => {
            return dispatch(setMediumOpen(isOpen))
        },
        onhandleSetCurrentMedia: (media) => {
            return dispatch(setCurrentMedia(media))
        },
        onhandleUpdateEditDevice: (entity) => {
            return dispatch(setCurrentDevice(entity))
        },
        onhandleDeviceBaseChange: (isChanged) => {
            dispatch(setDeviceBaseChange(isChanged))
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceTransferContainer)
