import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMenuOpen } from '../../redux/modules/menu';
import InstallationMapEdit from "../../components/InstallationManagement/InstallationMapEdit";
import { routeToInstallationInfo, initInstallationMapEdit } from '../../redux/modules/installation';
import PropTypes from "prop-types";

class InstallationMapEditContainer extends Component {
  static propTypes = {
    installationInfo: PropTypes.object,
    globalLoading: PropTypes.bool,
    mapChanged: PropTypes.bool,
  };

  componentDidMount() {
    let id = this.props.match.params.id
    this.props.initInstallationMapEdit(id);
    this.props.updateMenuOpen(false);
  }

  routerToInfo(event, instId) {
    let id = this.props.match.params.id
    this.props.routeToInstallationInfo(id);
    this.props.updateMenuOpen(true);
  }

  render() {
    return (
      <div>
        <InstallationMapEdit
          onRouterToInfo={this.routerToInfo.bind(this)}
          installationInfo={this.props.installationInfo}
          globalLoading={this.props.globalLoading}
          mapChanged={this.props.mapChanged}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    installationInfo: state.installation.editInstallation,
    globalLoading: state.loading.globalLoading,
    mapChanged: state.installationRelation.mapChanged
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMenuOpen: (value) => {
      dispatch(updateMenuOpen(value));
    },
    routeToInstallationInfo: (id) => {
      dispatch(routeToInstallationInfo(id));
    },
    initInstallationMapEdit: (value) => {
      dispatch(initInstallationMapEdit(value));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationMapEditContainer)
