import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    flexgrow: {
        flexGrow: 1
    },

    button: {
        margin: theme.spacing(1)
    },
    buttonlabel: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1.5)
    },
    input: {
        height: '40px'
    },
    numberlabel: {
        '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        }
    }
});


export default withStyles(styles);