import React, { useRef, useState } from 'react'
import * as THREE from 'three'
import PropTypes from 'prop-types';
import { useFrame, useThree, extend } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

extend({ OrbitControls })


function Controls() {
    const controls = useRef()
    const { camera, gl } = useThree()
    useFrame(() => controls.current.update())
    return (
        <orbitControls ref={controls} args={[camera, gl.domElement]} enableDamping dampingFactor={0.1} rotateSpeed={0.5} />
    )
}

const Pot = props => {

    // const { classes
    // } = props;

    const { gl } = useThree();

    gl.setSize(window.innerWidth - 268, window.innerHeight - 112);

    // const mesh = useRef()

    // const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    const group = useRef()
    // useFrame(() => (group.current.rotation.x = group.current.rotation.y += 0.01))

    const mesh = useRef()

    // useFrame(() => {console.log(mesh.current)})
    useFrame(() => {
        // let turn = false;
        if (mesh.current.scale.y <= 1.5 && !active) {
            mesh.current.position.y += 0.01 * 4;
            mesh.current.scale.y += 0.01;
        } else if (mesh.current.scale.y >= 0 && active) {
            mesh.current.position.y -= 0.01 * 4;
            mesh.current.scale.y -= 0.01;
        }
    })

    return <>
        <Controls />
        <group ref={group}
            onClick={(e) => setActive(!active)} >
            <mesh
                position={[0, 6, 0]}>
                <sphereGeometry attach="geometry" args={[7.8, 32, 32, 0, Math.PI * 2, 0, Math.PI / 2]} />
                <meshStandardMaterial attach="material" color='grey' opacity={0.8} transparent={true} />
            </mesh>
            <mesh
                position={[0, -2, 0]}>
                <cylinderGeometry attach="geometry" args={[8, 8, 16, 60]} />
                <meshStandardMaterial attach="material" color='grey' opacity={0.8} transparent={true} />
            </mesh>
            <mesh ref={mesh}
                position={[0, -6, 0]}>
                <cylinderGeometry attach="geometry" args={[7.8, 7.8, 8, 60]} />
                <meshStandardMaterial attach="material" color='blue' />
            </mesh>
        </group>
        <mesh
            position={[10, -8.3, 0]} rotation={[Math.PI / 2, 0, 0]}>
            <torusGeometry attach="geometry" args={[10, 1.2, 32, 3, 2.4]} />
            <meshStandardMaterial attach="material" color='grey' />
        </mesh>
        <mesh
            position={[0, -10.1, 0]} rotation={[Math.PI / 2, 0, 0]}>
            <planeGeometry attach="geometry" args={[50, 50, 32, 2]} />
            <lineBasicMaterial attach="material" color={'#B18904'} side={THREE.DoubleSide} />
        </mesh>
    </>
};

Pot.propTypes = {
    classes: PropTypes.object.isRequired
};

export default Pot;