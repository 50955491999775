import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RuleListComponent from '../../../../components/OnsiteInspectionManagement/RuleList/RuleListComponent';
import RuleValidPanelContainer from '../RuleValidPanelContainer';
import { openRuleValidPanel } from '@/redux/modules/onsiteInspectionRuleValid';
import { openConfirmDialog } from '@/redux/modules/confirmDialog';
import {
    getRuleList,
    deleteRule,
    changeRuleValid,
    initRuleListPage,
    setSearch,
    resetSearch,
    setFrequencyOpen,
    setFrequency,
    resetFrequency,
    insertRule,
    setCurrentRule,
    modifyRule,
    modifyFrequency
} from '../../../../redux/modules/onsiteInspectionRule';
import { getCompanyId } from "../../../../utils/userUtils";
import { getTreePartById } from '../../../../utils/constant';
import * as _ from 'lodash';
import { createMessage } from '../../../../redux/modules/message';

class OnsiteInspectionRuleListContainer extends Component {
    static propTypes = {
        params: PropTypes.object,
    }

    componentDidMount() {
        this.props.onhandleInitPage();
    }

    handleSelect = (value, field) => {
        let newSearch;
        switch (field) {
            case 'companyId':
                newSearch = {
                    ...this.props.search,
                    companyId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'inspectionType':
                newSearch = {
                    ...this.props.search,
                    inspectionType: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'routeId':
                newSearch = {
                    ...this.props.search,
                    routeId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            case 'dptId':
                newSearch = {
                    ...this.props.search,
                    dptId: value.target.value
                };
                this.props.onhandleUpdateEditSearch(newSearch);
                break;
            default:
            // do nothing
        }
    }

    handleChange = (event, field) => {
        let newFrequency;
        let newCurrentRule;
        switch (field) {
            case 'freqType':
                newFrequency = {
                    ...this.props.frequency,
                    freqType: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'freqP':
                newFrequency = {
                    ...this.props.frequency,
                    freqP: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'freqC':
                newFrequency = {
                    ...this.props.frequency,
                    freqC: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'timeP':
                newFrequency = {
                    ...this.props.frequency,
                    timeP: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'timeC':
                newFrequency = {
                    ...this.props.frequency,
                    timeC: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'warnTime1':
                newFrequency = {
                    ...this.props.frequency,
                    warnTime1: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'warnTime2':
                newFrequency = {
                    ...this.props.frequency,
                    warnTime2: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'customTime':
                newFrequency = {
                    ...this.props.frequency,
                    customTime: event.target.value
                };
                this.props.onhandleUpdateEditFrequency(newFrequency);
                break;
            case 'routeId':
                newCurrentRule = {
                    ...this.props.currentRule,
                    routeId: event.target.value
                };
                this.props.onhandleUpdateCurrentRule(newCurrentRule)
                break
            case 'dptIds':
                newCurrentRule = {
                    ...this.props.currentRule,
                    dptIds: event.target.value,
                    isShift: false
                };
                this.props.onhandleUpdateCurrentRule(newCurrentRule)
                break
            case 'isShift':
                newCurrentRule = {
                    ...this.props.currentRule,
                    isShift: event.target.checked
                };
                this.props.onhandleUpdateCurrentRule(newCurrentRule)
                break
            case 'expiredAt':
                newCurrentRule = {
                    ...this.props.currentRule,
                    expiredAt: event.target.value
                };
                this.props.onhandleUpdateCurrentRule(newCurrentRule)
                break
            case 'immeEffect':
                newCurrentRule = {
                    ...this.props.currentRule,
                    immeEffect: event.target.value
                };
                this.props.onhandleUpdateCurrentRule(newCurrentRule)
                break
            default:
            // do nothing
        }
    };

    handleClearSearch() {
        this.props.onhandleClearSearch();
    }

    handleSearch(event) {
        this.props.onhandleSearch(0, 15, "-update_time", "");
    }

    handleListSize = (size) => {
        let newSearch = {
            ...this.props.search,
            size: size
        };
        this.props.onhandleUpdateEditSearch(newSearch)
    }

    handleDeleteRule = (rule) => {
        //
        let changeStatus = () => {
            this.props.onhandleDeleteRule(rule);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除该条计划配置吗?', changeStatus, null, false);
    }

    handleRuleValid = (valid, rule) => {
        const text = valid ? '启动' : '暂停';
        const status = valid ? 'active' : 'paused';
        this.props.onhandleOpenConfirmDialog(`${text}确认`, `确认${text}该条计划配置吗?`, this.handleOpenRuleValidPannel.bind(this, rule, status), false);
    }

    handleOpenRuleValidPannel = (rule, status) => {
        console.log('========= radio panel =========')
        console.log(rule, status)
        const { onhandleOpenRuleValidPanel, onhandleRuleValid } = this.props;
        onhandleOpenRuleValidPanel(onhandleRuleValid.bind(this, rule, status), null, null);
    }

    handleCreate(rule) {
        this.props.onhandleFrequencyOpen(true);
        this.props.onhandleClearFrequency();
        this.props.onhandleUpdateCurrentRule(rule)
    }

    handleAddTime() {
        if (this.props.frequency.customTime === '') {
            return
        }
        let customTimes = this.props.frequency.customTimes
        let isExisted = false;
        for (let i in customTimes) {
            if (customTimes[i] === this.props.frequency.customTime) {
                isExisted = true;
                break;
            }
        }
        if (isExisted) {
            return
        }
        let newTimes = _.cloneDeep(customTimes)
        newTimes.push(this.props.frequency.customTime)
        newTimes.sort();
        let newFrequency = {
            ...this.props.frequency,
            customTime: '',
            customTimes: newTimes
        };
        this.props.onhandleUpdateEditFrequency(newFrequency);
    }

    handleCancelTime(index) {
        let customTimes = this.props.frequency.customTimes
        let newTimes = _.cloneDeep(customTimes)
        newTimes.splice(index, 1)
        let newFrequency = {
            ...this.props.frequency,
            customTimes: newTimes
        };
        this.props.onhandleUpdateEditFrequency(newFrequency);
    }

    handleCustomTimes(event, index) {
        let customTimes = this.props.frequency.customTimes
        let newTimes = _.cloneDeep(customTimes)
        newTimes[index] = event.target.value
        let newFrequency = {
            ...this.props.frequency,
            customTimes: newTimes
        };
        this.props.onhandleUpdateEditFrequency(newFrequency);
    }

    checkFrequency() {
        let frequency = this.props.frequency
        let rule = this.props.currentRule

        if (!rule.routeId) {
            this.props.onhandleCheck('请选择巡检路线')
            return true
        }

        if (!rule.dptIds || rule.dptIds.length === 0) {
            this.props.onhandleCheck('请选择执行班组')
            return true
        }

        if (!rule.expiredAt) {
            this.props.onhandleCheck('请设置巡检限时')
            return true
        }

        if (_.toInteger(rule.expiredAt) <= 0) {
            this.props.onhandleCheck('巡检限时请输入正整数')
            return true
        }

        if (!frequency.freqType) {
            //请选择巡检频次类型
            this.props.onhandleCheck('请选择巡检频次类型')
            return true
        }

        if (frequency.freqType === 'P') {
            if (!frequency.freqP) {
                //请设置小时
                this.props.onhandleCheck('请设置几小时一次')
                return true
            }
            if (_.toInteger(frequency.freqP) <= 0) {
                this.props.onhandleCheck('小时请输入正整数')
                return true
            }

            if (!frequency.timeP) {
                //请设置开始时间
                this.props.onhandleCheck('请设置开始时间')
                return true
            }
        }

        if (frequency.freqType === 'C') {
            if (!frequency.freqC) {
                //请设置次数
                this.props.onhandleCheck('请设置一天几次数')
                return true
            }

            if (_.toInteger(frequency.freqC) <= 0) {
                this.props.onhandleCheck('次数请输入正整数')
                return true
            }

            if (!frequency.timeC) {
                //请设置开始时间
                this.props.onhandleCheck('请设置开始时间')
                return true
            }
        }

        if (frequency.freqType === 'S') {
            if (!frequency.customTime) {
                let flag = false
                for (let i = 0; i < frequency.customTimes.length; i++) {
                    if (frequency.customTimes[i]) {
                        flag = true
                        break;
                    }
                }

                if (!flag) {
                    //请设置自定义时间
                    this.props.onhandleCheck('请设置自定义时间')
                }
            }
        }

        if (_.toInteger(frequency.warnTime1) <= 0) {
            this.props.onhandleCheck('第一次预警时间请输入正整数')
            return true
        }

        if (_.toInteger(frequency.warnTime2) <= 0) {
            this.props.onhandleCheck('第二次预警时间请输入正整数')
            return true
        }

        if (_.toInteger(frequency.warnTime1) >= _.toInteger(frequency.warnTime2)) {
            this.props.onhandleCheck('第二次预警时间要大于第一次预警时间')
            return true
        }

        return false
    }

    handleFrequencySave(event) {
        let frequency = this.props.frequency
        let rule = this.props.currentRule

        if (this.checkFrequency()) {
            return
        }
        if (rule.ruleId) {
            this.props.onhandleUpdateFrequency(frequency, rule)
        } else {
            this.props.onhandleCreate(frequency, rule)
        }

    }

    handleEdit(rule) {
        this.props.onhandleEdit(rule);
    }

    handleFrequencyEdit(event, rule) {
        rule.immeEffect = 'false'
        this.props.onhandleUpdateCurrentRule(rule)
        let newFrequency = {
            ...this.props.frequency
        };
        newFrequency.freqType = rule.freqType

        if (rule.freqType === 'P') {
            newFrequency.freqP = Math.floor(rule.freq / 60);
            let hour = Math.floor(rule.startTime / 60);
            let minute = rule.startTime % 60
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }

            newFrequency.timeP = hour + ':' + minute
        }

        if (rule.freqType === 'C') {
            newFrequency.freqC = rule.freq;
            let hour = Math.floor(rule.startTime / 60);
            let minute = rule.startTime % 60
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            newFrequency.timeC = hour + ':' + minute
        }

        if (rule.freqType === 'S') {
            let result = []
            _.map(rule.times.split(','), t => {
                let hour = Math.floor(t / 60);
                let minute = t % 60
                if (hour < 10) {
                    hour = '0' + hour
                }
                if (minute < 10) {
                    minute = '0' + minute
                }
                result.push(hour + ':' + minute)
            })
            // newFrequency.customTime = result.shift()
            newFrequency.customTimes = result
        }
        if (rule.warnTime1) {
            newFrequency.warnTime1 = rule.warnTime1
        }
        if (rule.warnTime2) {
            newFrequency.warnTime2 = rule.warnTime2
        }

        this.props.onhandleUpdateEditFrequency(newFrequency);
        this.props.onhandleFrequencyOpen(true);
    }

    handleFrequencyClose() {
        this.props.onhandleClearFrequency();
        this.props.onhandleFrequencyOpen(false);
    }

    trimCompanyTree() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        return tree
    }

    trimCompanyList() {
        const that = this
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let companyList = _.filter(this.props.companyList, function (o) { return companyId === o.companyId; })
        let subCompanyList = _.filter(this.props.companyList, function (o) { return companyId === o.pid; })
        let list = _.concat(companyList, subCompanyList)

        function loop(array) {
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                let sub = _.filter(that.props.companyList, function (o) { return item.companyId === o.pid; })
                list = _.concat(list, sub)
                loop(sub);
            }
        }

        loop(subCompanyList);

        let result = _.map(list, t => {
            return { label: t.companyName, value: t.companyId }
        })

        return result
    }

    trimRouteList() {
        let result = _.filter(this.props.routeList, function (o) { return 'OIT05' !== o.inspectionType; })
        return result
    }

    render() {
        return (
            <div>
                <RuleListComponent
                    userProfile={this.props.userProfile}
                    companyTree={this.trimCompanyTree()}
                    companyList={this.trimCompanyList()}
                    ruleList={this.props.ruleList}
                    inspectionDictionaries={this.props.inspectionDictionaries}
                    search={this.props.search}
                    onhandleDeleteRule={this.handleDeleteRule.bind(this)}
                    onhandleSelect={this.handleSelect.bind(this)}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleSearch={this.handleSearch.bind(this)}
                    getRuleList={this.props.onhandleSearch}
                    onhandleCreate={this.handleCreate.bind(this)}
                    onhandleEdit={this.handleEdit.bind(this)}
                    onSizeChange={this.handleListSize.bind(this)}
                    departmentList={this.props.departmentList}
                    currentUserInfo={this.props.currentUserInfo}
                    onhandleFrequencyEdit={this.handleFrequencyEdit.bind(this)}
                    frequencyOpen={this.props.frequencyOpen}
                    onhandleFrequencyClose={this.handleFrequencyClose.bind(this)}
                    frequency={this.props.frequency}
                    routeList={this.trimRouteList()}
                    onhandleAddTime={this.handleAddTime.bind(this)}
                    onhandleCancelTime={this.handleCancelTime.bind(this)}
                    onhandleCustomTimes={this.handleCustomTimes.bind(this)}
                    onhandleFrequencySave={this.handleFrequencySave.bind(this)}
                    onhandlePauseRule={this.handleRuleValid.bind(this, false)}
                    onhandleRestartRule={this.handleRuleValid.bind(this, true)}
                    currentRule={this.props.currentRule}
                />
                <RuleValidPanelContainer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ruleList: state.onsiteInspectionRule.ruleList,
        search: state.onsiteInspectionRule.search,
        userProfile: state.auth.userProfile,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        currentUserInfo: state.auth.currentUserInfo,
        inspectionDictionaries: state.constant.inspectionDictionaries,
        departmentList: state.constant.departments,
        frequencyOpen: state.onsiteInspectionRule.frequencyOpen,
        currentRule: state.onsiteInspectionRule.currentRule,
        frequency: state.onsiteInspectionRule.frequency,
        routeList: state.onsiteInspectionRule.routeList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleDeleteRule: (data) => {
            dispatch(deleteRule(data));
        },
        onhandleOpenRuleValidPanel: (confirmCallback, closeCallback, rule) => {
            dispatch(openRuleValidPanel(confirmCallback, closeCallback, rule));
        },
        onhandleRuleValid: (data, status, immeEffect) => {
            dispatch(changeRuleValid(data, status, immeEffect));
        },
        onhandleInitPage: () => {
            dispatch(initRuleListPage());
        },
        onhandleUpdateEditSearch: (search) => {
            dispatch(setSearch(search));
        },
        onhandleClearSearch: () => {
            dispatch(resetSearch());
        },
        onhandleSearch: (offset, size, sort, keyword, companyId, inspectionType) => {
            dispatch(getRuleList(offset, size, sort, keyword, companyId, inspectionType));
        },
        onhandleCreate: (data, rule) => {
            dispatch(insertRule(data, rule));
        },
        onhandleEdit: (rule) => {
            dispatch(modifyRule(rule));
        },
        onhandleUpdateFrequency: (data, rule) => {
            dispatch(modifyFrequency(data, rule));
        },
        onhandleFrequencyOpen: (data) => {
            dispatch(setFrequencyOpen(data));
        },
        onhandleUpdateEditFrequency: (frequency) => {
            dispatch(setFrequency(frequency));
        },
        onhandleClearFrequency: () => {
            dispatch(resetFrequency());
        },
        onhandleCheck: (msg) => {
            dispatch(createMessage('error', msg));
        },
        onhandleUpdateCurrentRule: (data) => {
            dispatch(setCurrentRule(data));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback, unsaved) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback, unsaved))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnsiteInspectionRuleListContainer);