import { createAction, handleActions } from 'redux-actions';
import { getRoleTree } from './role';
import { createhttpMessage, createMessage } from './message'

const defaultState = {
  isloading: false,
  noticeConfig: {},
  noticeConfigList: {
    total: 0,
    list: []
  },
  configState: {
    name: '',
    code: '',
    channel: 0,
    offset: 0,
    size: 0,
    sort: ''
  },
  editDialogOpen: false
};

export const initTarget = createAction('@@xcloud/notice/initTarget',
() => (dispatch, getState, httpClient) => {
  dispatch(getRoleTree())
});

export const getConfigList = createAction('@@xcloud/notice/getConfigList',
  (query) => (dispatch, getState, httpClient) => {
    let url = '/api/notifications/configs?status=active';
    if (query.name && query.name.trim() !== '') {
      url += "&name=" + encodeURIComponent(query.name)
    }
    if (query.code && query.code.trim() !== '') {
      url += "&code=" + encodeURIComponent(query.code)
    }
    if (query.channel !== undefined) {
      url += "&channel=" + query.channel
    }
    if (query.offset) {
      url += "&offset=" + query.offset
    }
    if (query.size) {
      url += "&size=" + query.size
    }
    if (query.sort) {
      url += "&sort=" + query.sort
    }
    // dispatch(setLoading(true));
    return httpClient.get(url)
      .then((res) => {
        // dispatch(setLoading(false));
        if (res.status === 200 && res.data) {
          dispatch(setConfigList(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        // dispatch(setLoading(false));
        let msg = '通知配置列表获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const getConfigById = createAction('@@xcloud/notice/getConfigById',
  (id) => (dispatch, getState, httpClient) => {
    let url = '/api/notifications/configs/' + id;
    return httpClient.get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setConfig(res.data));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '通知配置信息获取失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return [];
      });
  });

export const createConfig = createAction('@@xcloud/notice/createConfig',
  (data) => (dispatch, getState, httpClient) => {
    let url = '/api/notifications/configs';
    data.status = 'active'
    return httpClient.post(url, data)
      .then((res) => {
        if (res.status === 201) {
          dispatch(createMessage('success', '创建通知配置成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '创建新通知配置失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateConfigTargets = createAction('@@xcloud/notice/updateConfigTargets',
  (id, data) => (dispatch, getState, httpClient) => {
    let url = '/api/notifications/configs/' + id + '/targets';
    return httpClient.post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新通知配置对象成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新通知配置对象失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const updateConfig = createAction('@@xcloud/notice/updateConfig',
  (id, data) => (dispatch, getState, httpClient) => {
    let url = '/api/notifications/configs/' + id;
    return httpClient.patch(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(createMessage('success', '更新通知配置成功'));
        }
        return res;
      })
      // eslint-disable-next-line no-unused-vars
      .catch(err => {
        let msg = '更新通知配置失败';
        dispatch(createhttpMessage(err.response ? err.response : '', msg));
        return null;
      });
  });

export const setConfig = createAction('@@xcloud/notice/setConfig');

export const setConfigList = createAction('@@xcloud/notice/setConfigList');

export const setLoading = createAction('@@xcloud/notice/setLoading');

export const setConfigState = createAction('@@xcloud/notice/setConfigState');

export const setEditDialogOpen = createAction('@@xcloud/notice/setEditDialogOpen');

export const configReducer = handleActions(
  {
    [setConfig]: (state, { payload: value }) => {
      return {
        ...state,
        noticeConfig: value
      };
    },
    [setConfigList]: (state, { payload: value }) => {
      return {
        ...state,
        noticeConfigList: value
      };
    },
    [setLoading]: (state, { payload: value }) => {
      return {
        ...state,
        isloading: value
      };
    },
    [setEditDialogOpen]: (state, { payload: value }) => {
      return {
        ...state,
        editDialogOpen: value
      };
    },
    [setConfigState]: (state, { payload: value }) => {
      return {
        ...state,
        configState: value
      };
    }
  },
  defaultState
);

export default configReducer;