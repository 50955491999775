import React, { Component } from 'react';
import "./index.css";
import * as _ from 'lodash';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Label from '@material-ui/icons/Label';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class LeftSildeBar extends Component {
  constructor(props) {
    super(props)
    this._createDialog = this._createDialog.bind(this);
    const tabs = [
      { name: '既有设备', index: 0, isshow: true },
      { name: '新建设备', index: 1, isshow: true },
    ]
    this.state = {
      tab: 0,
      tabs,
    }
  }

  RenderTabs(tabs, tab, changeTabs) {
    return tabs.map((item, idx) => {
      if (!item.isshow) {
        return null;
      }
      return (
        <li
          key={item.index}
          className={tab === idx ? 'active' : ''}
          onClick={() => { changeTabs(idx) }}>
          {item.name}
        </li>
      )
    })
  }

  renderTree = (nodes) => (
    <TreeItem key={nodes.value} nodeId={nodes.value}
      label={Array.isArray(nodes.children) && nodes.children.length > 0 ?
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} title={nodes.label}>
          <Label color="inherit" />
          <Typography variant="body2">
            {nodes.label}
          </Typography>
        </div>
        :
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.onSelect(nodes.value, nodes.label)} title={nodes.label}>
          <SettingsApplicationsIcon color="inherit" />
          <Typography variant="body2">
            {nodes.label}
          </Typography>
        </div>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
    </TreeItem>
  );

  renderDeviceTree = (nodes) => {
    return (
      <TreeItem key={nodes.value} nodeId={nodes.value} onIconClick={() => this.onLoadData(nodes)}
        label={(_.startsWith(nodes.value, 'D') || nodes.value === 'existDevice') ?
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.onLoadData(nodes)} title={nodes.label}>
            {nodes.loading ? <CircularProgress color="inherit" style={{ width: '24px', height: '24px' }} /> : <Label color="inherit" />}
            <Typography variant="body2" color="inherit">
              {nodes.label}
            </Typography>
            {nodes.total ?
              <Typography variant="caption" color="inherit">
                ({nodes.total})
                        </Typography>
              : null}
          </div>
          :
          (
            // (nodes.mark && nodes.mark === 'exist')
            _.find(this.props.searchDevicelist.list, function (o) { return _.toString(o.deviceId) === nodes.value; })
              ?
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', background: '#3f51b5' }} onClick={() => this.onSelectAddedDevice(nodes)} title={nodes.label}>
                <SettingsApplicationsIcon style={{ color: '#ffffff' }} />
                <Typography variant="body2" style={{ color: '#ffffff' }}>
                  {nodes.label}
                </Typography>
              </div>
              :
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.onSelectExistDevice(nodes)} title={nodes.label}>
                <SettingsApplicationsIcon color="inherit" />
                <Typography variant="body2" color="inherit">
                  {nodes.label}
                </Typography>
              </div>
          )
        }
      >
        {nodes.loading ? <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="inherit">
            {'loading...'}
          </Typography>
        </div>
          : Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderDeviceTree(node)) : null}
      </TreeItem>
    )
  };

  showMain(idx) {
    if (idx === 1) {
      return <React.Fragment>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultExpanded={['newDevice']}
          style={{ width: '250px' }}
        >
          {this.renderTree(this.props.treedata)}
        </TreeView>
      </React.Fragment>
    } else {
      return <React.Fragment>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultExpanded={['existDevice']}
          style={{ width: '250px' }}
        >
          {this.renderDeviceTree(this.props.existTreeData)}
        </TreeView>
      </React.Fragment>
    }
  }

  onLoadData = node => {
    if (node.value !== "existDevice" && !(node.total && node.total > 0)) {
      this.props.getNewTreeDeviceData(node.value, 'edit');
    }
  }

  changeTabe = (idx) => {
    this.setState({
      tab: idx
    });
  }

  _createDialog(device) {
    const { createDialog } = this.props;
    createDialog('deviceInfo', device, true);
  }

  onSelect = (selectedKey, selectedtitle) => {
    this._createDialog({ type: selectedKey, typeName: selectedtitle })
  };

  onSelectExistDevice = (device) => {
    // this.props.addExistDeviceToMap(device);
    this._createDialog(device)
  };

  onSelectAddedDevice = (device) => {
    let stage = this.props.getStage();
    let layer = stage.findOne('.workLayer');
    layer.find('Group').forEach(group => {
      group.fire('mouseout')
    })
    let cat = device.cat;
    let type = _.find(this.props.deviceLegends, function (o) { return o.cat === cat; }).legend;
    let targetGroup = layer.findOne('#' + type + '_' + device.deviceId);
    if (targetGroup) {
      targetGroup.fire('mouseover');
    }

  };


  render() {
    const { tabs, tab } = this.state;
    return (
      <div className="leftbar" style={{ height: this.props.canvasHeight }}>
        <ul>
          {this.RenderTabs(tabs, tab, this.changeTabe)}
        </ul>
        {this.showMain(tab)}
      </div>
    )
  }
}