import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    // MenuItem,
    InputAdornment
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import * as _ from 'lodash';

const ServiceSharingComponent = props => {
    const { classes,
        currentUserInfo,
        departments,
        companies,
        commissionServices,
        valuations,
        sharingTakelist,
        selectedCompany,
        selectedOfService,
        addRoleFlag,
        roleFlag,
        isMainCompany,
        onhandleSelectChange,
        onhandleCompanyChange,
        onhandleMessage,
        onhandleAdd,
        onhandleDelete,
        onhandleUpdate,
        onhandleOpenHistory,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
        //toolbar: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30]

    }


    const tableStyle = {
        ...style
    }

    const renderServiceCostList = () => {

        return (
            <React.Fragment>
                {commissionServices.map(data => (<option value={data.serviceId} key={data.serviceId}>{data.serviceName}</option>
                ))}
            </React.Fragment>
        );
    }

    const getFixedValue = (row) => {
        if (!row.valuationType || row.valuationType === 'FIXED') {
            return row.fixed
        }
        return "-"
    }

    const getPercentageValue = (row) => {
        if (!row.valuationType || row.valuationType === 'PERCENTAGE') {
            return row.percentage + "%";
        }
        return "-"
    }

    const renderValuations = () => {
        return <React.Fragment>{valuations.map(data => (<option value={data.code} key={data.code}>{data.name}</option>))}</React.Fragment>
    }
    const renderValuationType = (row) => {
        const take = _.find(valuations, { 'code': row.valuationType });
        return take ? take.name : ''
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '分厂',
                field: 'takerCompany',
                validate: rowData => (!rowData.takerCompany || rowData.takerCompany === '') ? { isValid: false, helperText: '单位不能为空' } : true,
                render: row => <Typography>{renderCompany(row.takerCompany)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.takerCompany ? props.rowData.takerCompany : ''}
                        name='takerCompany'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {companies && !isMainCompany ?
                            _.filter(companies, (c) => {
                                return c.companyId === currentUserInfo.companyId
                            }).map(data => (
                                <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
                            )) : companies.map(data => (
                                <option value={data.companyId} key={data.companyId}>{data.companyAlias}</option>
                            ))
                        }
                    </TextField>
                )
            }, {
                title: '班组',
                field: 'takerDpt',
                validate: rowData => {
                    if (!rowData.takerDpt || rowData.takerDpt === '') {
                        return { isValid: false, helperText: '班组不能为空' }
                    } else {
                        let dpt = _.find(departments, (d) => { return d.dptId === Number(rowData.takerDpt) })
                        if (dpt) {
                            if (dpt.companyId !== Number(rowData.takerCompany)) {
                                return { isValid: false, helperText: '班组不能为空' }
                            } else {
                                return true
                            }
                        } else {
                            return { isValid: false, helperText: '班组不能为空' }
                        }
                    }
                },
                render: row => <Typography>{renderDpt(row.takerDpt)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.takerDpt ? props.rowData.takerDpt : ''}
                        name='takerDpt'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {departments && props.rowData.takerCompany !== '' ?
                            _.filter(departments, (d) => {
                                return d.companyId === Number(props.rowData.takerCompany)
                            }).map(data => (
                                <option value={data.dptId} key={data.dptId}>{data.dptName}</option>
                            )) : departments.map(data => (
                                <option value={data.dptId} key={data.dptId}>{data.dptName}</option>
                            ))
                        }
                    </TextField>
                )
            }, {
                title: '分摊方式',
                field: 'valuationType',
                render: row => <Typography>{renderValuationType(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        select
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        fullWidth
                        SelectProps={{
                            native: true,
                        }}
                        value={props.rowData.valuationType ? props.rowData.valuationType : ''}
                        name='valuationType'
                        onChange={e => props.onChange(e.target.value)}>
                        <option value={''} key={0}>{''}</option>
                        {renderValuations()}
                    </TextField>
                )
            }, {
                title: '百分比',
                field: 'percentage',
                render: row => <Typography>{getPercentageValue(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={props.rowData.percentage ? props.rowData.percentage : ''}
                        name='percentage'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            }, {
                title: '固定费',
                field: 'fixed',
                render: row => <Typography>{getFixedValue(row)}</Typography>,
                editComponent: (props) => (
                    <TextField
                        error={props.helperText !== ''}
                        helperText={props.helperText || ''}
                        required
                        type="number"
                        value={props.rowData.fixed ? props.rowData.fixed : ''}
                        name='fixed'
                        onChange={e => props.onChange(e.target.value)}>
                    </TextField>
                )
            },
        ]

        return columns

    }

    const assemblyData = () => {
        return sharingTakelist
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
    }

    const renderCompany = (companyId) => {
        let companyName = ''
        if (companies && companies.length > 0) {
            let value = _.find(companies, function (o) { return o.companyId === companyId })
            if (value) {
                companyName = value.companyAlias
            }
        }
        return companyName
    }

    const renderDpt = (dptId) => {
        let label = '';
        let value = _.find(departments, function (o) { return o.dptId === dptId })
        if (value) {
            label = value.dptName
        }
        return label;
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container className={classes.toolbarContainer}>
                    <Grid item xs={3} >
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            value={selectedOfService}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={onhandleSelectChange}
                        >
                            {renderServiceCostList()}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                        {
                            isMainCompany && <TextField
                                fullWidth
                                select
                                name='companyId'
                                InputProps={textFieldDefault}
                                onChange={onhandleCompanyChange}
                                value={selectedCompany}
                            >
                                {companies.map(data => (<option value={data.companyId} key={data.companyId}>{data.companyName}</option>
                                ))}
                            </TextField>
                        }
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title={<Typography variant='h3'>服务分摊列表</Typography>}
                        actions={[
                            {
                                icon: 'history_icon',
                                tooltip: '修改历史',
                                onClick: (event, rowData) => {
                                    onhandleOpenHistory(rowData)
                                }
                            },
                        ]}
                        editable={addRoleFlag && roleFlag ? {
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    let errorMsg = ''
                                    let valuationType = newData['valuationType'];
                                    //按百分比
                                    if (valuationType === 'PERCENTAGE') {
                                        let sum = parseFloat(newData['percentage']);
                                        sharingTakelist.forEach((o) => {
                                            if (o.valuationType === 'PERCENTAGE') {
                                                sum += o.percentage
                                            }
                                        })
                                        if (sum > 100) {
                                            // 检查是否超过了100%
                                            errorMsg = '分摊比例总和不能超过100%';
                                        }
                                    }
                                    if (_.findIndex(sharingTakelist, (o) => { return o.takerDpt === parseInt(newData['takerDpt']) && o.takerCompany === parseInt(newData['takerCompany']) }) > -1) {
                                        // 不能重复班组
                                        errorMsg = '不能添加重复的班组'
                                    }

                                    if (errorMsg !== '') {
                                        reject()
                                        onhandleMessage(errorMsg);
                                    } else {
                                        resolve()
                                        onhandleAdd(newData)
                                    }
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    let errorMsg = ''
                                    if (_.findIndex(sharingTakelist, (o) => { return o.takerCompany === parseInt(newData['takerCompany']) && o.takerDpt === parseInt(newData['takerDpt']) && o.takeId !== parseInt(newData['takeId']) }) > -1) {
                                        // 不能重复班组
                                        errorMsg = '不能添加重复的班组'
                                    } else {
                                        let valuationType = newData['valuationType'];
                                        //按百分比
                                        if (valuationType === 'PERCENTAGE') {
                                            let sum = parseFloat(newData['percentage']);
                                            sharingTakelist.forEach((o) => {
                                                if (o.valuationType === 'PERCENTAGE' && o.takeId !== parseInt(newData['takeId'])) {
                                                    sum += o.percentage
                                                }
                                            })
                                            if (sum > 100) {
                                                // 检查是否超过了100%
                                                errorMsg = '分摊比例总和不能超过100%';
                                            }
                                        }
                                    }
                                    if (errorMsg !== '') {
                                        reject()
                                        onhandleMessage(errorMsg);
                                    } else {
                                        resolve()
                                        onhandleUpdate(newData)
                                    }
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    resolve()
                                    onhandleDelete(oldData)
                                }),
                        } : {}}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

ServiceSharingComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    serviceCostList: PropTypes.array,
    commissionServices: PropTypes.array,
};

ServiceSharingComponent.defaultProps = {
    serviceCostList: [],
    commissionServices: []
};

export default withStyles(ServiceSharingComponent);