import React from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import withStyles from '@/components/AmoebaManagement/BudgetPricing/OtherPricing/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { localization, options, style } from "@/utils/mtable";
import { getDepartmentsByRole } from "@/utils/userUtils";
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import * as _ from 'lodash';

const OtherFreeComponent = props => {
    const { classes,
        companyId,
        subCompanyList,
        isMainCompany,
        onhandleRuleUpdate,
        onhandleCompanyChange,
        serviceBudgets,
        currentUserInfo,
        department,
        userProfile,
        onhandleShowHistory,
        onhandleShowHistoryF,
        onhandleShowHistoryM,
        roleFlag,
    } = props;


    const detailTableLocalization = {
        ...localization,
    }

    const detailTableOptions = {
        ...options,
        paging: false,

    }
    const detailTableStyle = {
        ...style,
        padding: '10px',
    }


    const otherassemblyDetailColumns = () => {
        const columns = [
            {
                title: '服务',
                field: 'serviceName',
                width: 100,
                render: row => <Typography>{row.serviceName}</Typography>,
                editable: 'never'
            },
            {
                title: '处置费单耗',
                field: 'ratio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.ratio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistory(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            }
            ,
            {
                title: '运费单耗',
                field: 'fRatio',
                type: 'numeric',
                align: 'right',
                width: 100,
                render: row => <Typography>{row.fRatio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
            {
                title: '',
                align: 'center',
                width: 25,
                render: row => <IconButton onClick={event => onhandleShowHistoryF(event, row)}><HistoryIcon /></IconButton>,
                editable: 'never'
            },
            {
                title: '营销费单耗',
                field: 'mRatio',
                type: 'numeric',
                align: 'right',
                width: 120,
                render: row => <Typography>{row.mRatio}</Typography>,
            }, {
                title: '单位',
                field: 'unit',
                type: 'numeric',
                align: 'center',
                width: 120,
                render: row => <Typography>元/{row.unit}</Typography>,
                editable: 'never'
            },
        ]
        return columns
    }

    const otherassemblyData = () => {
        const dBudgets = _.filter(serviceBudgets, { 'subAlias': '处置费' })
        const fBudgets = _.filter(serviceBudgets, { 'subAlias': '运费' })
        const mBudgets = _.filter(serviceBudgets, { 'subAlias': '营销费' })
        _.forEach(dBudgets, value => {
            _.forEach(fBudgets, f => {
                if (value.serviceId === f.serviceId) {
                    value.fBudgetId = f.budgetId
                    value.fRatio = f.ratio
                    value.fVariableSub = f.variableSub
                }
            })
            _.forEach(mBudgets, m => {
                if (value.serviceId === m.serviceId) {
                    value.mBudgetId = m.budgetId
                    value.mRatio = m.ratio
                    value.mVariableSub = m.variableSub
                }
            })
        })
        console.log(dBudgets)
        return dBudgets
    }

    const checkDpt = () => {
        const dptId = getDepartmentsByRole(currentUserInfo.roleId, userProfile)[0].dptId
        return dptId === department.dptId || roleFlag ? true : false;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>),
        readOnly: !isMainCompany
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item className={classes.processLine}>
                    </Grid>
                    <Grid>
                        <TextField
                            fullWidth
                            select
                            name='companyId'
                            InputProps={textFieldDefault}
                            onChange={onhandleCompanyChange}
                            value={companyId}
                        >
                            {subCompanyList.map(ele => (
                                <MenuItem key={ele.companyId} value={ele.companyId}>{ele.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className={classes.detailTable}>
                        <MaterialTable
                            columns={otherassemblyDetailColumns()}
                            data={otherassemblyData()}
                            options={detailTableOptions}
                            localization={detailTableLocalization}
                            style={detailTableStyle}
                            editable={checkDpt() ? {
                                onRowUpdate: (newData, oldData) => onhandleRuleUpdate(newData, oldData)
                            } : {}}
                            actions={
                                [
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '业务分包修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistory(event, rowData)
                                    //     }
                                    // },
                                    // {
                                    //     icon: () => { return <HistoryIcon /> },
                                    //     tooltip: '检维修修改历史',
                                    //     onClick: (event, rowData) => {
                                    //         onhandleShowHistoryE(event, rowData)
                                    //     }
                                    // },
                                    {
                                        icon: () => { return <HistoryIcon /> },
                                        tooltip: '自营支出修改历史',
                                        onClick: (event, rowData) => {
                                            onhandleShowHistoryM(event, rowData)
                                        }
                                    },
                                ]
                            }
                            title={<Typography variant='h3'>其他费用预算规则</Typography>}
                        ></MaterialTable>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

OtherFreeComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    companyId: PropTypes.number,
    subCompanyList: PropTypes.array,
    isMainCompany: PropTypes.bool,
    varFeeRuleList: PropTypes.array,
    selectedDate: PropTypes.object,
    onhandleDateChange: PropTypes.func,
    onhandleRuleUpdate: PropTypes.func,
    onhandleCompanyChange: PropTypes.func,
};

OtherFreeComponent.defaultProps = {
    subCompanyList: [],
    varFeeRuleList: [],
};

export default withStyles(OtherFreeComponent);