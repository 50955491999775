import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OperationPlan from '../../../components/DeviceManagement/OperationPlan';
import { getMoment, formatDate } from '../../../utils/datetime';
import { getTreePartById } from '../../../utils/constant';
import { getCompanyId } from "../../../utils/userUtils";
import { setOperationPlanState, initMonthlyPlan, getPlanDetailList, updateOperationPlanDetail, setMonthlyPlanPageSize, routeToOperationPlanCreate, routerToCreateWorkOrder } from "../../../redux/modules/operationPlan";
import { createmodal } from '../../../redux/modules/modal';
import { createMessage } from '../../../redux/modules/message';
import _ from 'lodash';
import { openConfirmDialog } from "@/redux/modules/confirmDialog";
class OperationPlanContainer extends Component {

    static propTypes = {
        params: PropTypes.object,
        userProfile: PropTypes.object,
        companyTree: PropTypes.array,
        companyList: PropTypes.array,
        currentUserInfo: PropTypes.object,
        operationPlanDetails: PropTypes.object,
        monthlyPlanPageSize: PropTypes.number,
        isLoading: PropTypes.bool,
        optCategories: PropTypes.array,
        procat: PropTypes.array,
        catList: PropTypes.array,
        accCatList: PropTypes.array,
        roleTree: PropTypes.array,
        roleList: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            year: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            month: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            companyId: "",
            filterCompanyTree: [],
            routeType: '',
            optNo: '',
            onlyMe: false,
            userId: null
        }
    }

    componentDidMount() {
        let companyId = getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile)
        let tree = getTreePartById(companyId, this.props.companyTree);
        if (tree.length === 1 && (!tree[0].children || tree[0].children.length === 0)) {
            this.setState({ companyId: companyId, filterCompanyTree: tree })
        } else {
            companyId = "";
            this.setState({ filterCompanyTree: tree })
        }

        let routeType = '';
        // if (this.props.routeType) {
        //     routeType = this.props.routeType;
        // }
        this.setState({
            routeType: routeType
        })

        // 运维类别
        this.setState({ optType: "" })
        // 执行时间 - 开始
        this.setState({ targetDateStart: null })
        // 执行时间 - 结束
        this.setState({ targetDateEnd: null })
        // 预算 - 开始
        this.setState({ budgetStart: null })
        // 预算 - 结束
        this.setState({ budgetEnd: null })
        // 工单下达状态
        this.setState({ orderAssigned: "" })

        this.setState({ optNo: null })

        this.props.onhandleInitMonthlyPlan(
            companyId ? companyId : "",
            getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            routeType
        )
    }

    handleCheck = (value, field) => {
        const checked = value.target.checked;
        const {
            // onhanleGetPlanDetailList,
            currentUserInfo,
        } = this.props;
        switch (field) {
            case 'onlyMe':
                let userId = null;
                if (checked) {
                    userId = currentUserInfo.userId
                } else {
                    userId = null
                }
                this.setState({ onlyMe: checked, userId: userId })
                // 检索运维计划详细列表
                // onhanleGetPlanDetailList({
                //     offset: 0,
                //     target: 'DEV,ACC',
                //     companyId: this.state.companyId,
                //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
                //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
                //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
                //     size: 0,
                //     // 运维类别
                //     optType: this.state.optType ? this.state.optType : null,
                //     // 执行时间 - 开始
                //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
                //     // 执行时间 - 结束
                //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
                //     // 预算 - 开始
                //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
                //     // 预算 - 结束
                //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
                //     // 工单下达状态
                //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
                //     optNo: this.state.optNo ? this.state.optNo : null,
                //     // 用户名(目前仅与 “只看我的” 关联)
                //     userId: userId ? userId : null
                // })
                break;
            default:
            // do nothing
        }
    }

    hanleGetPlanDetailList = () => {
        const { onhanleGetPlanDetailList } = this.props;

        // 检索运维计划详细列表
        onhanleGetPlanDetailList({
            offset: 0,
            target: 'DEV,ACC',
            companyId: this.state.companyId,
            fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            amoeba: this.state.routeType === 'projectmanage' ? true : null,
            size: 0,
            // 运维类别
            optType: this.state.optType ? this.state.optType : null,
            // 执行时间 - 开始
            targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            // 执行时间 - 结束
            targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            // 预算 - 开始
            budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            // 预算 - 结束
            budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            // 工单下达状态
            orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            optNo: this.state.optNo ? this.state.optNo : null,
            // 用户名(目前仅与 “只看我的” 关联)
            userId: this.state.userId ? this.state.userId : null
        })

    }

    handleClearSearch = () => {

        this.setState({
            year: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            month: (getMoment()().startOf('month').format("YYYY-MM-DD")),
            companyId: "",
            filterCompanyTree: [],
            routeType: '',
            optNo: '',
            onlyMe: false,
            userId: null
        })

    }

    handleChangeItem = (value, field) => {
        // const { onhanleGetPlanDetailList } = this.props;
        if (field === 'companyId') {
            this.setState({ companyId: value.target.value })
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: value.target.value,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     status: 'active',
            //     size: monthlyPlanPageSize,
            // })
            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: value.target.value,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'month') {
            this.setState({ month: formatDate(value) })
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId ? this.state.companyId : null,
            //     fromDate: getMoment()(formatDate(value)).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(formatDate(value)).endOf('month').format("YYYY-MM-DD"),
            //     status: 'active',
            //     size: monthlyPlanPageSize,
            // })
            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(formatDate(value)).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(formatDate(value)).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'optType') {
            // 运维类别
            this.setState({ optType: value.target.value })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: value.target.value ? value.target.value : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'targetDateStart') {
            // 执行时间 - 开始
            this.setState({ targetDateStart: formatDate(value) })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: formatDate(value) ? formatDate(value) : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'targetDateEnd') {
            // 执行时间 - 结束
            this.setState({ targetDateEnd: formatDate(value) })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: formatDate(value) ? formatDate(value) : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'budgetStart') {
            // 预算 - 开始
            this.setState({ budgetStart: value.target.value })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: value.target.value ? value.target.value : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'budgetEnd') {
            // 预算 - 结束
            this.setState({ budgetEnd: value.target.value })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: value.target.value ? value.target.value : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'orderAssigned') {
            // 工单下达状态
            this.setState({ orderAssigned: value.target.value })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: value.target.value ? value.target.value : null,
            //     optNo: this.state.optNo ? this.state.optNo : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        } else if (field === 'optNo') {
            //
            this.setState({ optNo: value.target.value })

            // 检索运维计划详细列表
            // onhanleGetPlanDetailList({
            //     offset: 0,
            //     target: 'DEV,ACC',
            //     companyId: this.state.companyId,
            //     fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
            //     toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
            //     amoeba: this.state.routeType === 'projectmanage' ? true : null,
            //     size: 0,
            //     // 运维类别
            //     optType: this.state.optType ? this.state.optType : null,
            //     // 执行时间 - 开始
            //     targetDateStart: this.state.targetDateStart ? this.state.targetDateStart : null,
            //     // 执行时间 - 结束
            //     targetDateEnd: this.state.targetDateEnd ? this.state.targetDateEnd : null,
            //     // 预算 - 开始
            //     budgetStart: this.state.budgetStart ? this.state.budgetStart : null,
            //     // 预算 - 结束
            //     budgetEnd: this.state.budgetEnd ? this.state.budgetEnd : null,
            //     // 工单下达状态
            //     orderAssigned: this.state.orderAssigned ? this.state.orderAssigned : null,
            //     optNo: value.target.value ? value.target.value : null,
            //     // 用户名(目前仅与 “只看我的” 关联)
            //     userId: this.state.userId ? this.state.userId : null
            // })
        }

    }

    handleUpdate(operationPlanDetail) {
        const { onhandleUpdate, onhanleGetPlanDetailList } = this.props;
        // final execute function from other component or redux-action
        onhandleUpdate(operationPlanDetail, operationPlanDetail.dtlId, () => {
            onhanleGetPlanDetailList({
                offset: 0,
                target: 'DEV,ACC',
                companyId: this.state.companyId ? this.state.companyId : null,
                fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
                toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
                amoeba: this.state.routeType === 'projectmanage' ? true : null,
                size: 0
            });
        })
    }

    // callback(operationPlanDetail) {
    //     const { onhandleUpdate, onhanleGetPlanDetailList, monthlyPlanPageSize } = this.props;
    //     operationPlanDetail.executeTime = new Date();
    //     onhandleUpdate(operationPlanDetail, operationPlanDetail.dtlId, () => {
    //         onhanleGetPlanDetailList({
    //             offset: 0,
    //             target: 'DEV,ACC',
    //             companyId: this.state.companyId ? this.state.companyId : null,
    //             fromDate: getMoment()(this.state.month).startOf('month').format("YYYY-MM-DD"),
    //             toDate: getMoment()(this.state.month).endOf('month').format("YYYY-MM-DD"),
    //             status: 'active',
    //             size: monthlyPlanPageSize
    //         })
    //     })
    // }

    handleCreateWorkOrder(operationPlanDetail) {

        this.props.onhandleRouterToCreateWorkOrder(operationPlanDetail.dtlId);
        // const { onhandleDialog } = this.props;
        // // final execute function from other component or redux-action

        // let param = {
        //     companyId: getCompanyId(this.props.currentUserInfo.roleId, this.props.userProfile),
        //     dptId: getDepartmentsByRole(this.props.currentUserInfo.roleId, this.props.userProfile)[0].dptId,
        //     source: 'ORD_DEV',
        //     sourceId: operationPlanDetail.dtlId,
        //     callback: () => this.callback(operationPlanDetail)
        // }
        // onhandleDialog(param);
    }

    handleCreateMergeWorkOrder() {
        if (_.filter(this.props.operationPlanDetails.list, { selected: true }).length > 0) {
            this.props.onhandleRouterToCreateWorkOrder('multiple');
        } else {
            this.props.onhandleMessage('请选择至少一个计划')
        }
    }

    handleDelete(plan) {

        plan.status = 'inactive'
        delete plan.executeTime

        const planObjectName = plan && plan.device && plan.device.deviceId ? plan.device.deviceName : plan.accessory.accName;

        let changeStatus = () => {
            this.handleUpdate(plan);
        }
        this.props.onhandleOpenConfirmDialog('删除确认', '确定要删除[' + planObjectName + ']的计划吗?', changeStatus, null, false);

    }

    // 查看已下工单列表
    handleViewOrderList(operationPlan) {
        let param = {
            operationPlan: operationPlan
        }

        this.props.onhandleViewOrderList(param);
    }

    async handlePageChange(current, pageSize) {

        this.setState(
            {
                page: current,
                size: pageSize
            },
            this.hanleGetPlanDetailList()
        );

    }

    render() {
        return (
            <div>
                <OperationPlan
                    routeType={this.state.routeType}
                    companyTree={this.state.filterCompanyTree}
                    companyList={this.props.companyList}
                    onlyMe={this.state.onlyMe}
                    operationPlanDetails={this.props.operationPlanDetails}
                    month={this.state.month}
                    companyId={this.state.companyId}
                    monthlyPlanPageSize={this.props.monthlyPlanPageSize}
                    isLoading={this.props.isLoading}
                    optCategories={this.props.optCategories}
                    procat={this.props.procat}
                    catList={this.props.catList}
                    accCatList={this.props.accCatList}
                    userProfile={this.props.userProfile}
                    roleTree={this.props.roleTree}
                    roleList={this.props.roleList}
                    onhandleCheck={this.handleCheck.bind(this)}
                    onhandleChangeItem={this.handleChangeItem.bind(this)}
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                    setMonthlyPlanPageSize={this.props.setMonthlyPlanPageSize.bind(this)}
                    onhanleGetPlanDetailList={this.hanleGetPlanDetailList.bind(this)}
                    onhandleClearSearch={this.handleClearSearch.bind(this)}
                    onhandleCreateWorkOrder={this.handleCreateWorkOrder.bind(this)}
                    onhandleRouteToOperationPlanCreate={this.props.onhandleRouteToOperationPlanCreate.bind(this)}
                    onhandleViewOrderList={this.handleViewOrderList.bind(this)}
                    onhandleCreateMergeWorkOrder={this.handleCreateMergeWorkOrder.bind(this)}
                    // 运维类别 （检索条件）
                    optType={this.state.optType}
                    // 执行时间 - 开始 （检索条件）
                    targetDateStart={this.state.targetDateStart}
                    // 执行时间 - 结束 （检索条件）
                    targetDateEnd={this.state.targetDateEnd}
                    // 预算（元） - 开始 （检索条件）
                    budgetStart={this.state.budgetStart}
                    // 预算（元） - 结束 （检索条件）
                    budgetEnd={this.state.budgetEnd}
                    // 下工单 （检索条件）
                    orderAssigned={this.state.orderAssigned}
                    optNo={this.state.optNo}

                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserInfo: state.auth.currentUserInfo,
        companyTree: state.constant.companyTree,
        companyList: state.constant.companies,
        userProfile: state.auth.userProfile,
        roleTree: state.role.roleTree,
        roleList: state.role.roleList.list,
        operationPlanDetails: state.operationPlan.planDetails,
        monthlyPlanPageSize: state.operationPlan.monthlyPlanPageSize,
        isLoading: state.loading.globalLoading,
        optCategories: state.operationRule.optCategories,
        procat: state.devices.procat,
        catList: state.trees.deviceCategoryList,
        accCatList: state.accessory.catList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOperationPlanState: (value) => {
            dispatch(setOperationPlanState(value));
        },
        onhandleInitMonthlyPlan: (companyId, fromDate, toDate, routeType) => {
            dispatch(initMonthlyPlan(companyId, fromDate, toDate, routeType));
        },
        setMonthlyPlanPageSize: (value) => {
            dispatch(setMonthlyPlanPageSize(value));
        },
        onhanleGetPlanDetailList: (query) => {
            dispatch(getPlanDetailList(query));
        },
        onhandleUpdate: (operationPlanDetal, dtlId, callback) => {
            dispatch(updateOperationPlanDetail(operationPlanDetal, dtlId, callback));
        },
        onhandleDialog: (param) => {
            dispatch(createmodal('createWorkOrder', param, 'small'));
        },
        onhandleRouteToOperationPlanCreate: (value) => {
            dispatch(routeToOperationPlanCreate(value));
        },
        onhandleRouterToCreateWorkOrder: (value) => {
            dispatch(routerToCreateWorkOrder("fromlistpage", value));
        },
        // 查看已下工单列表 (弹窗)
        onhandleViewOrderList: (parm) => {
            dispatch(createmodal('workOrderListForPopup', parm, 'large'));
        },
        onhandleMessage: (message) => {
            dispatch(createMessage('error', message));
        },
        onhandleOpenConfirmDialog: (title, text, confirmCallback, closeCallback) => {
            dispatch(openConfirmDialog(title, text, confirmCallback, closeCallback))
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperationPlanContainer);