import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    stepper: {
        alignItems: 'center',
        padding: 16,
        background: 'none'
    },
    step: {
    }


});

export default withStyles(styles);