import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MServiceListComponent from '@/components/ManufactureManagement/ManufactureService/MServiceList/MServiceListComponent';
import MServiceConfigContainer from './MServiceConfigContainer';
import {
    getManufactureServices, delManufactureService
} from '@/redux/modules/manufacture/manufactureService';
import { judgeMainCompany, SERVICE_TYPE_OUTER } from '@/utils/manufactureUtils'

import { formatDate } from '@/utils/datetime'

import { getBusinessGroupList } from '@/redux/modules/manufacture/businessGroup';
import { getMaterialGroups } from '@/redux/modules/material';
import MServiceDelete from '@/components/ManufactureManagement/ManufactureService/MServiceDelete';
class MServiceContainer extends Component {
    static propTypes = {
        services: PropTypes.array,
        companyList: PropTypes.array,
        departmentList: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            createOpen: false,
            opreationType: 0,
            isMainCompany: false,
            subCompanyList: [],
            amoebaIndex: '',
            serviceType: '',
            serviceCompany: '',
            filterFlag: false,
            filterServices: [],
            service: { serviceId: 0, activities: [] },
            deleteOpen: false,
        }
    }

    componentDidMount() {
        const { userCompanyId,
            companyList,
            getMaterialGroups,
            getServices,
            getBusinessGroupList } = this.props;

        getMaterialGroups();
        getBusinessGroupList();

        let flag = judgeMainCompany(companyList, userCompanyId);
        if (flag) {
            getServices('');
        } else {
            getServices(userCompanyId);
        }

        // 获取下属公司
        let subCompanyList = companyList.filter((element) => element.pid === userCompanyId);
        this.setState({
            isMainCompany: flag,
            subCompanyList: subCompanyList
        })
    }

    handleUpdate = (rowData) => {
        this.setState({
            createOpen: true,
            opreationType: 2,
            service: rowData
        });
    }



    handleAdd = () => {
        this.setState({
            createOpen: true,
            opreationType: 1,
            service: { serviceId: 0, activities: [] }
        })
    }

    handleClose = (closeType) => {
        const { userCompanyId, getServices } = this.props;
        const { isMainCompany } = this.state;
        if (closeType === 'save') {
            if (isMainCompany) {
                getServices('', () => {
                    this.handleFilterClick();
                });
            } else {
                getServices(userCompanyId, () => {
                    this.handleFilterClick();
                });
            }
        }
        this.setState({
            createOpen: false,
        })
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'amoebaIndex') {
            this.setState({
                amoebaIndex: value,
            })
        }
        if (name === 'serviceType') {
            this.setState({
                serviceType: value,
            })
        }
        if (name === 'serviceCompany') {
            this.setState({
                serviceCompany: value,
            })
        }
    }

    handleFilterClick = () => {
        const { amoebaIndex, serviceCompany } = this.state;
        const filterServices = this.props.services.filter((ele) => {
            return (amoebaIndex ? ele.amoebaIndex === amoebaIndex : true)
                && (serviceCompany ? ele.serviceCompany === serviceCompany : true)
        });

        this.setState({
            filterServices: filterServices,
            filterFlag: true,
        })
    }

    handleClearClick = () => {
        this.setState({
            filterFlag: false,
            amoebaIndex: '',
            serviceType: '',
            serviceCompany: '',
        })
    }

    handleOpenDelete = (rowData) => {
        this.setState({
            deleteOpen: true,
            service: rowData
        });
    }
    handleCloseDelete = () => {
        this.setState({
            deleteOpen: false,
            service: { serviceId: 0, activities: [] },
        });
    }

    handleDelete = (rowData) => {

        return this.props.delManufactureService(this.state.service.serviceId, formatDate(this.state.service.endDate)).then(
            () => {
                this.props.getServices(this.state.isMainCompany ? '' : this.props.userCompanyId);
                this.handleCloseDelete()
            });
    }

    handleDateChange = (d, name) => {
        let newService = { ...this.state.service }
        if (name === 'endDate') {
            const date = new Date(d)
            newService['endDate'] = date
        }
        this.setState({
            service: newService
        });

    }

    render() {
        let services = this.props.services;
        const { filterFlag, filterServices } = this.state;
        if (filterFlag) {
            services = filterServices
        }

        return (
            <div>
                <MServiceListComponent
                    onhandleUpdate={this.handleUpdate.bind(this)}
                    onhandleAdd={this.handleAdd.bind(this)}
                    services={services}
                    companyList={this.props.companyList}
                    isMainCompany={this.state.isMainCompany}
                    subCompanyList={this.state.subCompanyList}
                    departmentList={this.props.departmentList}
                    manufactureDicts={this.props.manufactureDicts}
                    businessGroupList={this.props.businessGroupList}
                    onhandleFilterClick={this.handleFilterClick.bind(this)}
                    onhandleClearClick={this.handleClearClick.bind(this)}
                    amoebaIndex={this.state.amoebaIndex}
                    serviceType={this.state.serviceType}
                    serviceCompany={this.state.serviceCompany}
                    onhandleChange={this.handleChange.bind(this)}
                    onhandleOpenDelete={this.handleOpenDelete.bind(this)}
                />
                {this.state.createOpen &&
                    <MServiceConfigContainer
                        service={this.state.service}
                        opreationType={this.state.opreationType}
                        isMainCompany={this.state.isMainCompany}
                        serviceType={SERVICE_TYPE_OUTER}
                        onhandleClose={this.handleClose.bind(this)}
                        viewFlg={0}
                    />}
                <MServiceDelete
                    open={this.state.deleteOpen}
                    service={this.state.service}
                    onhandleClose={this.handleCloseDelete.bind(this)}
                    onhandleDateChange={this.handleDateChange.bind(this)}
                    onhandleDelete={this.handleDelete.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        services: state.manufactureService.services,
        companyList: state.company.companyList.list,
        departmentList: state.department.departments.list,
        userCompanyId: state.auth.currentUserInfo.companyId,
        materialGroups: state.material.materialGroups,
        manufactureDicts: state.constant.manufactureDicts,
        businessGroupList: state.businessGroup.businessGroupList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (companyId, callback) => {
            dispatch(getManufactureServices(null, null, companyId, null, null, callback));
        },
        delManufactureService: (serviceId, endDate) => {
            return dispatch(delManufactureService(serviceId, endDate));
        },
        getMaterialGroups: () => {
            return dispatch(getMaterialGroups());
        },
        getBusinessGroupList: () => {
            dispatch(getBusinessGroupList());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MServiceContainer);
