import inherits from 'inherits';
import PropertiesActivator from 'bpmn-js-properties-panel/lib/PropertiesActivator';
import baseInfo from './parts/BaseInfoProps';
import stageProps from './parts/StageProps';
import actionProps from './parts/ActionProps';
import roleProps from './parts/RoleProps';
import {is} from 'bpmn-js/lib/util/ModelUtil';
import { getStore } from '../../../../redux/store';

function createBaseInfoTab(element, bpmnFactory, elementRegistry, translate) {
    const generalGroup = {
        id: 'baseInfo',
        label: '',
        entries: [],
    };

    baseInfo(generalGroup, element, bpmnFactory, translate);
    stageProps(generalGroup, element, bpmnFactory, translate);
    actionProps(generalGroup, element, bpmnFactory, translate);
    return [generalGroup];
}

function createRoleTab(element, bpmnFactory, elementRegistry, translate) {
    let roleGroups = [];
    const store = getStore();
    const roleTree = store.getState().role.roleTree;
    if (roleTree) {
        roleTree.forEach(company=>{
            let generalGroup = {
                id: 'company_' + company.companyId,
                label: company.companyName,
                entries: []
            };
            roleProps(generalGroup, element, bpmnFactory, translate,company.roleGroups);
            roleGroups.push(generalGroup);
        })
    }

    return roleGroups;
}

export default function CustomPropertiesProvider(eventBus, bpmnFactory, elementRegistry, translate) {
    PropertiesActivator.call(this, eventBus);
    this.getTabs = function (element) {

        let tabs = [];
        const baseInfoTab = {
            id: 'baseInfo',
            label: '属性',
            groups: createBaseInfoTab(element, bpmnFactory, elementRegistry, translate),
        };
        tabs.push(baseInfoTab);

        if (is(element, 'bpmn:Task') || is(element, 'bpmn:StartEvent') || is(element, 'bpmn:EndEvent')){
            const roleTab = {
                id: 'roleInfo',
                label: '角色',
                groups: createRoleTab(element, bpmnFactory, elementRegistry, translate)
            };
            tabs.push(roleTab);
        }

        return tabs;
    };
}

inherits(CustomPropertiesProvider, PropertiesActivator);

CustomPropertiesProvider.$inject = ['eventBus', 'bpmnFactory', 'elementRegistry', 'translate'];
