import React from 'react';
import withStyles from './styles';
import { Card } from '@material-ui/core';
import PropTypes from 'prop-types';
import UserHeader from "../../../containers/SystemManagement/UserManagement/UserHeader";
import UserList from "../../../containers/SystemManagement/UserManagement/UserList";

const UserManagement = props => {

    const { classes } = props;

    return (
        <Card className={classes.root}>
            <UserHeader />
            <div className={classes.content}>
                <UserList />
            </div>
        </Card>
    );
};
UserManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(UserManagement);
