import React from 'react';
import {
    Grid,
    Typography,
    Button,
    TextField,
    InputAdornment,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import withStyles from './styles';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { localization, options, style } from "@/utils/mtable";
import { SERVICE_TYPE_INNER } from '@/utils/manufactureUtils';
import * as _ from 'lodash';

const MServiceListComponent = props => {
    const { classes,
        services,
        companyList,
        departmentList,
        isMainCompany,
        subCompanyList,
        onhandleUpdate,
        onhandleAdd,
        // manufactureDicts,
        businessGroupList,
        onhandleFilterClick,
        onhandleClearClick,
        amoebaIndex,
        // serviceType,
        serviceCompany,
        onhandleChange,
        onhandleOpenDelete,
    } = props;

    const tableLocalization = {
        ...localization,
    }

    const tableOptions = {
        ...options,
    }
    const tableStyle = {
        ...style
    }

    // 获取公司名
    const getCompanyName = (data, id) => {
        let dto = data.find(item => {
            return item.companyId === id ? item.companyName : ''
        });
        if (dto !== undefined) {
            return dto.companyName;
        }
        return '';
    }

    // // 获取班组名
    // const getDptName = (data, serviceDpts) => {
    //     if (data && serviceDpts) {
    //         let dpts = '';
    //         serviceDpts.forEach((serviceDpt, index) => {
    //             let dto = data.find((dpt) => dpt.dptId === serviceDpt.dptId)
    //             if (dto !== undefined)
    //                 if (index === 0) {
    //                     dpts += dto.dptName;
    //                 } else {
    //                     dpts += ',' + dto.dptName;
    //                 }
    //         });
    //         return dpts
    //     }
    //     return '';
    // }

    const getDptNames = (serviceDpts, dependType) => {

        const dpts = _.filter(departmentList, d => {
            return _.find(serviceDpts, s => { return s.dptId === d.dptId && s.dependType === dependType })
        })
        const dptNames = _.map(dpts, d => { return d.dptName })
        return dptNames.join(',')
    }

    const getBusiness = (amoebaIndex) => {
        var bg = _.find(businessGroupList, bg => { return bg.subId === amoebaIndex })
        if (bg) return bg.groupName
        return ''
    }

    const renderServiceType = (serviceType) => {
        if (serviceType === SERVICE_TYPE_INNER) {
            return <Tooltip title="内部服务"><CallSplitIcon /></Tooltip>
        }
        return ''
    }

    const assemblycolumns = () => {

        const columns = [
            {
                title: '服务名称',
                field: 'serviceName',
                render: row => <Typography>{row.serviceName}{renderServiceType(row.serviceType)}</Typography>
            }, {
                title: '服务分类',
                field: 'amoebaIndex',
                render: row => <Typography>{getBusiness(row.amoebaIndex)}</Typography>
                // }, {
                //     title: '服务方式',
                //     field: 'serviceType',
                //     render: row => <Typography>{getCodeName(manufactureDicts, SERVICE_TYPE, row.serviceType)}</Typography>
            },
            {
                title: '主要材料',
                field: 'materialGroupName',
                render: row => <Typography>{row.materialGroupName ? (row.materialGroupName + (row.materialName ? '/' + row.materialName : '')) : ''}</Typography>
            },
            {
                title: '产成品',
                field: 'productGroupName',
                render: row => <Typography>{row.productGroupName ? (row.productGroupName + (row.productName ? '/' + row.productName : '')) : ''}</Typography>
            },
            {
                title: '供方',
                field: 'serviceCompany',
                render: row => <Typography>{getCompanyName(companyList, row.serviceCompany)}</Typography>
            },
            {
                title: '供方班组',
                field: 'serviceDpts',
                render: row => <Typography>{getDptNames(row.serviceDpts, 'SERVICE')}</Typography>
            },
            {
                title: '需方',
                field: 'requireCompany',
                render: row => <Typography>{getCompanyName(companyList, row.requireCompany)}</Typography>
            },
            {
                title: '需方班组',
                field: 'serviceDpts',
                render: row => <Typography>{getDptNames(row.serviceDpts, 'REQUIRE')}</Typography>
            },
        ]
        return columns
    }

    const assemblyData = () => {
        return services;
    }

    const textFieldDefault = {
        style: {
            width: '160px',
        },
        startAdornment: (
            <InputAdornment position="start">
                <p></p>
            </InputAdornment>)
    }

    const components = {
        Toolbar: props => (
            <Grid >
                <Grid container className={classes.top}>
                    {isMainCompany && <Grid className={classes.filterEle}>
                        <Typography gutterBottom>
                            {'供方分厂'}
                        </Typography>
                        <TextField
                            fullWidth
                            select
                            name='serviceCompany'
                            InputProps={textFieldDefault}
                            onChange={onhandleChange}
                            value={serviceCompany}
                        >
                            {subCompanyList.map(e => (
                                <MenuItem key={e.companyId} value={e.companyId}>{e.companyName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>}
                    <Grid className={classes.filterEle}>
                        <Typography gutterBottom>
                            {'服务分类'}
                        </Typography>
                        <TextField
                            fullWidth
                            select
                            name='amoebaIndex'
                            InputProps={textFieldDefault}
                            onChange={onhandleChange}
                            value={amoebaIndex}
                        >
                            {businessGroupList.map(e => (
                                <MenuItem key={e.subId} value={e.subId}>{e.groupName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/* <Grid className={`${classes.filterEle} ${classes.flexgrow}`}>
                        <TextField
                            fullWidth
                            select
                            label='服务方式'
                            name='serviceType'
                            InputProps={textFieldDefault}
                            onChange={onhandleChange}
                            value={serviceType}
                        >
                            {getCodeListByType(manufactureDicts, SERVICE_TYPE).map(e => (
                                <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid> */}
                    <Grid item>
                        <Button className={classes.filterEle} variant='contained' color='primary' onClick={onhandleFilterClick} >
                            <SearchIcon />筛选
                        </Button>
                        <Button className={classes.filterEle} variant='contained' color='primary' onClick={onhandleClearClick} >
                            <ClearAllIcon />清除
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className={classes.flexgrow}>
                        {<Typography variant='h3'>{'服务列表'}</Typography>}
                    </Grid>
                    <Grid item>
                        <MTableToolbar {...props} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <div alignitems="center" className={classes.root}>
            <Grid className={classes.container}>
                <Grid container>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid>
                    <MaterialTable
                        columns={assemblycolumns()}
                        data={assemblyData()}
                        options={tableOptions}
                        localization={tableLocalization}
                        style={tableStyle}
                        title=''
                        components={components}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: '新增',
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                    onhandleAdd(rowData)
                                }
                            },
                            {
                                icon: 'edit',
                                tooltip: '服务配置',
                                onClick: (event, rowData) => {
                                    onhandleUpdate(rowData)
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: '删除',
                                onClick: (event, rowData) => {
                                    onhandleOpenDelete(rowData)
                                }
                            },
                        ]}
                    >
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

MServiceListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    services: PropTypes.array,
    companyList: PropTypes.array,
    departmentList: PropTypes.array,
    onhandleUpdate: PropTypes.func,
    onhandleDelete: PropTypes.func,
    onhandleAdd: PropTypes.func,
};

MServiceListComponent.defaultProps = {
    isMainCompany: false,
    subCompanyList: []
};

export default withStyles(MServiceListComponent);