import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    itemGrid: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
});


export default withStyles(styles);