import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import {getBusinessObject} from 'bpmn-js/lib/util/ModelUtil';
import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { getStore } from '../../../../../redux/store';
import {setDiagramChange} from '../../../../../redux/modules/process';

function getCheckBox(element, node) {
    const bo = getBusinessObject(element);
    const selectedOption = bo.get(node);
    return selectedOption;
}

function setCheckBox(value, name) {
    const obj = {};
    obj[`${name}`] = value[name]? 'true': 'false';
    return obj;
}

export default function (group, element, bpmnFactory, translate, roleGroups) {
    const store = getStore();

    if (roleGroups) {
        roleGroups.forEach(roleGroup => {
            group.entries.push(entryFactory.label({
                id: 'group_' + roleGroup.groupId,
                labelText: roleGroup.groupName,
                divider: true
            }));
            if (roleGroup.roleList) {
                roleGroup.roleList.forEach(role => {
                    let roleId = 'role_' + role.roleId;
                    group.entries.push(entryFactory.checkbox({
                        id: roleId,
                        label: role.roleName,
                        modelProperty: roleId,
                        get(el) {
                            let r = {};
                            r[roleId] = getCheckBox(el,roleId) === 'true';
                            return r;
                        },
                        set(el, value) {
                            const bo = getBusinessObject(el);
                            const props = setCheckBox(value,roleId);
                            store.dispatch(setDiagramChange(true));
                            return cmdHelper.updateBusinessObject(element, bo, props);
                        }
                        
                    }));
                });
            }
        });
    }

}
