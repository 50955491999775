import { createAction, handleActions } from 'redux-actions';
import { createhttpMessage, createMessage } from '@/redux/modules/message'


const porxyApi = '/api'

const initialState = {
    reports: [], //阿米巴指标列表
    reportFixeds: [], //阿米巴修正列表
    installReports:[] //阿米巴装置报表烈儿
};

export const setAmoebaReports = createAction('@@xcloud/amoeba/setAmoebaReports');

export const setReportFixeds = createAction('@@xcloud/amoeba/setReportFixeds');

export const setAmoebaInstallReports = createAction('@@xcloud/amoeba/setAmoebaInstallReports');

export const getAmoebaReports = createAction('@@xcloud/amoeba/getAmoebaReports',
    (cate, year, month, day, subIds, companyIds, dptIds, amoebaLevels) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/report'
            let strs = []
            if (cate || year || month || day || subIds || companyIds || dptIds || amoebaLevels) {
                if (cate) strs.push('cate=' + cate)
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                if (day) strs.push('day=' + day)
                if (subIds) strs.push('subIds=' + subIds)
                if (companyIds) strs.push('companyIds=' + companyIds)
                if (dptIds) strs.push('dptIds=' + dptIds)
                if (amoebaLevels) strs.push('amoebaLevels=' + amoebaLevels)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setAmoebaReports(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const generateAmoebaReport = createAction('@@xcloud/amoeba/generateAmoebaReport',
    (param, callback) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/report'
            return httpClient.post(url, param).then(
                (res) => {
                    if (res.status === 200) {
                        if (callback) {
                            callback();
                        }
                        dispatch(createhttpMessage(res, "生成阿米巴报表成功"));
                    }
                    return res.data;
                }).catch(err => {
                    if (!err || !err.response || err.response.status === 502) {
                        dispatch(createMessage('success', '报表核算处理中,请5分钟后刷新页面。'));
                        return {}
                    }
                    let msg = `生成阿米巴核算报表失败`;
                    dispatch(createhttpMessage(err.response ? err.response : '', msg));
                    return []
                })
        });

export const getReportFixeds = createAction('@@xcloud/amoeba/getReportFixeds',
    (year, month, day, subIds, companyIds, dptIds, amoebaLevels) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/reportfixeds'
            let strs = []
            if (year || month || day || subIds || companyIds || dptIds || amoebaLevels) {
                if (year) strs.push('year=' + year)
                if (month) strs.push('month=' + month)
                if (day) strs.push('day=' + day)
                if (subIds) strs.push('subIds=' + subIds)
                if (companyIds) strs.push('companyIds=' + companyIds)
                if (dptIds) strs.push('dptIds=' + dptIds)
                if (amoebaLevels) strs.push('amoebaLevels=' + amoebaLevels)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setReportFixeds(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴偏差报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const addReportFixed = createAction('@@xcloud/manufacture/addReportFixed',
    (fixDto) => (dispatch, getState, httpClient) => {
        return httpClient.post(porxyApi + '/amoeba/reportfixeds', fixDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "增加阿米巴偏差报表成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `增加阿米巴偏差报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const updateReportFixed = createAction('@@xcloud/manufacture/updateTask',
    (fixDto) => (dispatch, getState, httpClient) => {
        return httpClient.patch(porxyApi + `/amoeba/reportfixeds/${fixDto.fixedId}`, fixDto)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "更新阿米巴偏差报表成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `更新阿米巴偏差报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const delReportFixed = createAction('@@xcloud/manufacture/deleteTask',
    (fixedId) => (dispatch, getState, httpClient) => {
        return httpClient.delete(porxyApi + `/amoeba/reportfixeds/${fixedId}`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(createhttpMessage(res, "删除阿米巴偏差报表成功"));
                }
                return res.status;
            }).catch(err => {
                let msg = `删除阿米巴偏差报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
    });

export const getInstallReports = createAction('@@xcloud/amoeba/getInstallReports',
    (installId, startDate, endDate) =>
        (dispatch, getState, httpClient) => {
            let url = porxyApi + '/amoeba/reports/installs'
            let strs = []
            if (installId || startDate || endDate) {
                if (installId) strs.push('installId=' + installId)
                if (startDate) strs.push('startDate=' + startDate)
                if (endDate) strs.push('endDate=' + endDate)
                url = url + '?' + strs.join('&')
            }

            return httpClient.get(url, { headers: { need_loading: true } }).then((res) => {
                if (res.status === 200) {
                    dispatch(setAmoebaInstallReports(res.data))
                }
                return res.data;
            }).catch(err => {
                let msg = `获取阿米巴装置报表失败`;
                dispatch(createhttpMessage(err.response ? err.response : '', msg));
                return []
            })
        });

export const amoebaReportReducer = handleActions(
    {
        [setAmoebaReports]: (state, { payload: data }) => {
            return {
                ...state,
                reports: data
            }
        },
        [setReportFixeds]: (state, { payload: data }) => {
            return {
                ...state,
                reportFixeds: data
            }
        },
        [setAmoebaInstallReports]: (state, { payload: data }) => {
            return {
                ...state,
                installReports: data
            }
        },
    },
    initialState
)

export default amoebaReportReducer
