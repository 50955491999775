import React, { Component } from 'react';
import Signup from '../components/Signup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cellphoneRegx, emailRegx } from '../utils/regex';
import { updateSignupInfo, signup, sendSmsCode, setSmsClock } from '../redux/modules/auth';
import * as _ from 'lodash';

class SignupContainer extends Component {

    static propTypes = {
        signupInfo: PropTypes.object,
        smsClock: PropTypes.number,
        onhandleChange: PropTypes.func,
        onhandleUpdateSignupInfo: PropTypes.func,
        onhandleSignup: PropTypes.func,
        onhandleSmsCode: PropTypes.func
    }

    componentDidMount() {
        this.props.onhandleUpdateSignupInfo({
            errorMessage: '',
            tenantAlias: '',
            lastName:'',
            firstName:'',
            userName:'',
            phone:'',
            smsCode: '',
            email:'',
            password:''
        });
        this.props.onhandleSetSmsClock();
    }

    handleCheck = (event, field) => {
        if ((!field || field === 'tenantAlias') && this.props.signupInfo.tenantAlias === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入租户代码'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'lastName') && this.props.signupInfo.lastName === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入姓'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'firstName') && this.props.signupInfo.firstName === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入名'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'userName') && this.props.signupInfo.userName === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入用户名'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'phone') && this.props.signupInfo.phone === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入手机号码'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'phone') &&
            (this.props.signupInfo.phone !== '' && !cellphoneRegx.test(_.trim(this.props.signupInfo.phone)))) {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入有效的手机号码'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'smsCode') && this.props.signupInfo.smsCode === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入手机验证码'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'email')
            && this.props.signupInfo.email !== ''
            && !emailRegx.test(_.trim(this.props.signupInfo.email))) {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入有效的邮箱地址'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'password') && this.props.signupInfo.password === '') {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '请输入密码'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if ((!field || field === 'password')
            && (this.props.signupInfo.password !== '' && this.props.signupInfo.password.length < 8)) {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: '密码不能少于8位字符'
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else {
            const newInfo = {
                ...this.props.signupInfo,
                errorMessage: ''
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        }
    };

    handleSignup = async (event) => {
        await this.handleCheck(event, null);
        if (this.props.signupInfo.errorMessage === '') {
            this.props.onhandleSignup();
        }
    };

    handleSmsCode = () => {
        if (this.props.signupInfo.phone !== '') {
            this.props.onhandleSmsCode(this.props.signupInfo.phone);
        }
    };

    handleChange = (event, field) => {
        if (field === 'tenantAlias') {
            const newInfo = {
                ...this.props.signupInfo,
                tenantAlias: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'lastName') {
            const newInfo = {
                ...this.props.signupInfo,
                lastName: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'firstName') {
            const newInfo = {
                ...this.props.signupInfo,
                firstName: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'userName') {
            const newInfo = {
                ...this.props.signupInfo,
                userName: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'email') {
            const newInfo = {
                ...this.props.signupInfo,
                email: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'phone') {
            const newInfo = {
                ...this.props.signupInfo,
                phone: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'smsCode') {
            const newInfo = {
                ...this.props.signupInfo,
                smsCode: _.trim(event.target.value)
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        } else if (field === 'password') {
            const newInfo = {
                ...this.props.signupInfo,
                password: event.target.value
            };
            this.props.onhandleUpdateSignupInfo(newInfo);
        }
    };

    render() {
        return (
            <Signup onhandleSignup={this.handleSignup.bind(this)}
                onhandleChange={this.handleChange.bind(this)}
                onhandleCheck={this.handleCheck.bind(this)}
                onhandleSmsCode={this.handleSmsCode.bind(this)}
                signupInfo={this.props.signupInfo}
                smsClock={this.props.smsClock}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        signupInfo: state.auth.signupInfo,
        smsClock: state.auth.smsClock
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onhandleUpdateSignupInfo: (info) => {
            dispatch(updateSignupInfo(info));
        },
        onhandleSignup: () => {
            dispatch(signup());
        },
        onhandleSmsCode: (phoneNum) => {
            dispatch(sendSmsCode(phoneNum, 'xcloud_signup'));
        },
        onhandleSetSmsClock: () => {
            dispatch(setSmsClock(180));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupContainer);