import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    zIndex: 9999
  },
  success: {
    backgroundColor: theme.palette.success.main,
    margin: '5px 0px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    margin: '5px 0px'
  },
  info: {
    backgroundColor: theme.palette.info.main,
    margin: '5px 0px'
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    margin: '5px 0px'
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles);
